import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import { Icon, mdSize } from "../../../../../common/icon";
import { useModelPopup } from "../../../../../common/hooks";
import { getPlantByType, resetPlantByType } from "../../../../../common/actions/common.action";
import mainStyles from "modules/ideasWrapper/ideasWrapper.module.scss";
import styles from './fordPlants.module.scss';
import IdeaPlantsAndAreasModal
  from "modules/ideasWrapper/ideaProfileWrapper/ideaProfileOverview/ideaFordPlants/ideaPlantsAndAreasModal.component";

const IdeaFordPlants = ({ canEdit }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    councilReducer: { selectedCouncil },
    adminPlantsAndAreasReducer: { plantsAndAreas },
    ideaReducer: { selectedIdea, ideaPlant },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!plantsAndAreas.length) {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        })
      );
    }
  }, [plantsAndAreas, selectedCouncil]);

  useEffect(() => {
    if (!ideaPlant) {
      dispatch(getPlantByType({
        resourceId: selectedIdea.id,
        enqueueSnackbar,
        type: 'Idea',
      }));
    }
  }, [ideaPlant]);

  useEffect(() => {
    return () => {
      dispatch(resetPlantByType({
        type: 'Idea',
      }))
    }
  }, []);

  const handleEditPlants = () => {
    popup.show({
      title: "Edit Plant + Area",
      component: <IdeaPlantsAndAreasModal />,
    });
  }

  return (
    <div className="d-flex flex-column mb-4">
      <div
        className={`d-flex align-items-center justify-content-between ${mainStyles.blockHeader}`}
      >
        <span className={mainStyles.blockTitle}>Plant + Area</span>
        {canEdit && (
          <Icon
            {...mdSize}
            icon="icn-edit-button"
            className={mainStyles.messageIcon}
            onClick={handleEditPlants}
          />
        )}
      </div>
      <div className={`${styles.content} ${mainStyles.blockContent}`}>
        {
          ideaPlant?.id ? (
            <div className="d-flex flex-column">
              <span className="font-weight-bold">
                Plant
              </span>
              <span>{ideaPlant.name}</span>
              {
                ideaPlant?.areas?.length > 0 ? (
                  <div className="mt-3">
                    <span className="font-weight-bold">
                      Area{ideaPlant.areas.length === 1 ? '' : 's'}
                    </span>
                    <div className="mt-2">
                      <ul className="ml-3">
                        {
                          ideaPlant.areas.map((area, i) => (
                            <li key={`${area.attributes.name}-${i}`}>
                              {area.attributes.name}
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                ) : null
              }
            </div>
          ) : (
            <div>
              No added plants yet.
            </div>
          )
        }
      </div>
    </div>
  )
};

export default IdeaFordPlants;

import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { BtnType, Button } from "../../../../primitives";
import { ModalFooter } from "../../../../../application/modal";
import styles from "./comments.module.scss";
import { appModelSettingSet } from "../../../../../application/app.action";
import { addIdeaCommentAttempt } from "../../../idea.action";

const AddIdeaComment = ({ selectedIdea }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    appReducer: { modalSetting },
    authReducer: { session },
  } = useSelector((state) => state);
  const [comment, setComment] = useState("");

  const handleCancelClick = () => {
    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
  };

  const handleCommentSave = () => {
    if (!comment.length) {
      enqueueSnackbar("Comment is required!", {
        variant: "error",
      });
      return;
    }

    const payload = {
      comment: {
        comment: comment.trim(),
        author_id: session.id,
        idea_id: selectedIdea.id,
      },
      cb: () => {
        enqueueSnackbar("Successfully added comment", {
          variant: "success",
        });

        dispatch(appModelSettingSet({ ...modalSetting, show: false }));
      },
      enqueueSnackbar,
    };

    dispatch(addIdeaCommentAttempt(payload));
  };

  return (
    <div>
      <div className={styles.customPadding}>
        <textarea
          className="w-100 border-secondary"
          defaultValue={comment}
          onChange={(evt) => setComment(evt.target.value)}
          rows="5"
        />
      </div>
      <ModalFooter>
        <Button
          className="mr-3"
          btn={BtnType.FRAME_LESS}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button
          disabled={!comment || !comment.length}
          onClick={handleCommentSave}
          btn={BtnType.REGULAR}
        >
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default memo(AddIdeaComment);

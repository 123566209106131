import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import { Icon, smSize } from "../../../../../common/icon";
import RequestResearchComponent from "modules/companies/company/popups/requestResearch";
import ShareWithComponent from "modules/companies/company/popups/shareWith/index";
import AddToListComponent from "modules/lists/components/addToList/index";
import AddCompanyComponent from "modules/companies/companyMain/addToProject/index";
import styles from "./patientOrgTopActions.module.scss";
import { BtnType, Button, MoreOptions } from "../../../../primitives";
import { useModelPopup } from "../../../../../common/hooks";
import { appModelSettingSet } from "application/app.action";
import { useWindowSize } from "../../../../../common/hooks/useWindowSize";
import { patientOrgMobileMoreOptions } from "./patientOrgTopActions.constant";
import NewEventComponent from "../../../../companies/company/activity/popups";
import {
  moreOptionTypes,
  patientOrganizationMoreOptions,
} from "../../patientOrganization.constant";
import CompanyDownload from "modules/companies/company/companyDownload";
import { patientOrgSetDownloadParams } from "modules/patientOrganizations/patientOrganization/patientOrganization.action";
import TractionDownload from "../../../../../common/components/tractionDownload";
import NewProjectV3 from "modules/projects/newProjectv3";

const PatientOrgTopActions = ({ isAdmin, blockFunctionality }) => {
  const {
    appReducer: { modalSetting },
    patientOrganizationsReducer: {
      patientOrganizationReducer: {
        selectedPatientOrganization,
        patientOrgIsDownloading,
        organizationLoading,
      },
    },
  } = useSelector((state) => state);

  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const { isMobile } = useWindowSize();

  const [patientOrgOptions, setPatientOrgOptions] = useState(
    patientOrganizationMoreOptions
  );
  const [shouldOpenAddNewProjectModal, setShouldOpenAddNewProjectModal] = useState(false);

  useEffect(() => {
    if (isMobile) {
      let options = patientOrgMobileMoreOptions;

      if (blockFunctionality) {
        options = options.filter(
          (option) => option.val !== "newProject" && option.val !== "engagement"
        );
      }

      if (isAdmin) {
        options = [{ val: "edit", text: "Edit Organization" }, ...options];
      }

      setPatientOrgOptions(options);

      return;
    }

    setPatientOrgOptions(patientOrganizationMoreOptions);
  }, [blockFunctionality, isMobile, isAdmin]);

  const handleNewProjectClick = () => {
    setShouldOpenAddNewProjectModal(true);
  };

  const handleRequestResearch = () => {
    popup.show({
      title: "Request Information",
      component: (
        <RequestResearchComponent
          company={selectedPatientOrganization}
          isPatientOrganization
        />
      ),
    });
  };

  const handleEditClick = () => {
    history.push(
      `/admin/organizations/edit/${selectedPatientOrganization.id}`,
      { gsk: true }
    );
  };

  const handleAddNoteClick = () => {
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        title: "Capture New Note",
        show: true,
        height: String(window.innerHeight),
        width: String(window.innerWidth / 1.5),
        component: (
          <NewEventComponent
            company={selectedPatientOrganization}
            companyId={selectedPatientOrganization.id}
            type="note"
            customTitle="Capture New Note"
            orgType="patient"
            shouldRedirectToActivity
          />
        ),
      })
    );
  };

  const handleMoreOptionClick = (val) => {
    let obj = {};

    switch (val) {
      case "edit": {
        handleEditClick();
        return;
      }

      case "requestResearch": {
        handleRequestResearch();
        return;
      }

      case "engagement": {
        handleAddNoteClick();
        return;
      }

      case "newProject": {
        handleNewProjectClick();
        return;
      }

      case moreOptionTypes.share:
        obj = {
          title: "Share With",
          show: true,
          height: "300",
          width: "540",
          component: (
            <ShareWithComponent
              actionType="sharePatientOrganization"
              selectedCompany={selectedPatientOrganization}
            />
          ),
        };
        break;

      case moreOptionTypes.downloadReport:
        obj = {
          title: "Request Information",
          show: true,
          height: "1000",
          width: "540",
          component: (
            <RequestResearchComponent
              isPatientOrganization
              company={selectedPatientOrganization}
            />
          ),
        };
        break;

      case moreOptionTypes.addToList:
        obj = {
          title: "Add To List",
          show: true,
          height: "1000",
          width: "540",
          component: (
            <AddToListComponent
              companyId={selectedPatientOrganization.id}
              enqueueSnackbar={enqueueSnackbar}
            />
          ),
        };
        break;

      case moreOptionTypes.addToProject:
        obj = {
          title: "Add Organization To Project",
          show: true,
          height: "1000",
          width: "540",
          component: (
            <AddCompanyComponent
              companyId={selectedPatientOrganization.id}
              type="patient_organizations"
              enqueueSnackbar={enqueueSnackbar}
            />
          ),
        };
        break;

      default:
        break;
    }
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...obj,
      })
    );
  };

  const handleDownload = () => {
    popup.show({
      title: "Select patient organization tabs to download",
      component: (
        <CompanyDownload
          selectedCompany={selectedPatientOrganization}
          handleDownload={handleDownloadClose}
          patientOrgInstance
        />
      ),
    });
  };

  const handleDownloadClose = (tabs) => {
    if (tabs?.length) {
      dispatch(patientOrgSetDownloadParams(tabs));
    }

    popup.hide();
  };

  const handleDownloadState = (status) => {
    if (!status) {
      dispatch(patientOrgSetDownloadParams([]));
    }
  };

  return (
    <div className={styles.patientOrgActionsWrapper}>
      {isMobile && !patientOrgIsDownloading ? (
        <div style={styles.mobileWrapper}>
          <MoreOptions
            options={patientOrgOptions}
            className={styles.moreOptWrp}
            onClick={handleMoreOptionClick}
            customSvgIcon
          />
          <NewProjectV3
            isAdmin={isAdmin}
            wizardType="patient_organization"
            additionalData={selectedPatientOrganization}
            btnName="New Mapping"
            shouldOpen={shouldOpenAddNewProjectModal}
            handleDismiss={() => setShouldOpenAddNewProjectModal(false)}
            hideBtn
            blockFunctionality={blockFunctionality}
          />
        </div>
      ) : !patientOrgIsDownloading ? (
        <div className={styles.firstRow}>
          <NewProjectV3
            isAdmin={isAdmin}
            wizardType="patient_organization"
            additionalData={selectedPatientOrganization}
            btnName="New Mapping"
            blockFunctionality={blockFunctionality}
          />
          <Button
            btn={BtnType.REGULAR}
            className={`${styles.button} ml-2`}
            onClick={handleRequestResearch}
          >
            Request Info
          </Button>
          {!blockFunctionality ? (
            <Button
              btn={BtnType.REGULAR}
              className={styles.button}
              onClick={handleAddNoteClick}
            >
              Engagement
            </Button>
          ) : (
            <Tooltip title="You have View-Only access. To create an Engagement, please ask your Admin to upgrade your account to Standard access.">
              <div>
                <Button btn={BtnType.DISABLED} className={styles.button}>
                  Engagement
                </Button>
              </div>
            </Tooltip>
          )}
          <div className={styles.colMore}>
            <MoreOptions
              options={patientOrgOptions}
              className={styles.moreOptWrp}
              onClick={handleMoreOptionClick}
              customSvgIcon
            />
          </div>
        </div>
      ) : null}
      {!isMobile && !organizationLoading && (
        <div className={styles.editBtnContainer}>
          <div className="invisible">
            <TractionDownload
              handleDownloadState={handleDownloadState}
              timeoutTime={2000}
              hidePng
              shouldDownloadPdf={patientOrgIsDownloading}
            />
          </div>
          <Button
            btn={BtnType.FRAME_LESS}
            onClick={handleDownload}
            className={patientOrgIsDownloading ? "invisible" : ""}
          >
            <div className="d-flex align-items-center">
              <Icon
                {...smSize}
                icon="icn-export-button"
                className={styles.download}
              />
              Download
            </div>
          </Button>
          {isAdmin && !patientOrgIsDownloading && (
            <Button
              btn={BtnType.FRAME_LESS}
              className={styles.button}
              onClick={handleEditClick}
              icon="icn-edit"
            >
              Edit Organization
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(PatientOrgTopActions);

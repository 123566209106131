import * as Yup from "yup";

export const autoPopulateRelationalTypes = [
  "profile_field",
  "contact_field",
  "competitor_field",
  "customer_field",
  "technology_field",
  "industry_field",
];

export const additionalFieldsRelationTypes = ["custom_field"];

export const fieldToRemoveOnSyncOff = ["Company Logo"];

export const fieldToRemoveOnSyncOn = [
  "Company LinkedIn",
  "Customers",
  "Competitors",
];

export const formInitialValues = {
  form_title: "",
  form_instruction_text: "Please fill out the information below",
  add_applicant_to_company_database: true,
  sync_with_crunchbase: true,
  auto_populate_fields: [],
  additional_fields: [],
  required_field_ids: [],
  documents_attributes: [],
};

export const formValidation = [
  Yup.object().shape({
    form_title: Yup.string().required("Title is required"),
  }),
];

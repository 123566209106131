import React from "react";

import DropDownNestedElements from "common/components/dropdownNestedElements";
import styles from "../areaOfFocus.module.scss";
import { Label } from "../../../../../primitives";
import { Icon, smSize } from "../../../../../../common/icon";

const IndustryModal = ({
  selectedCouncilName,
  handleIndustryChange,
  prevIndustries,
  handleIndustryRemove,
  industries,
  industry,
}) => (
  <>
    <Label>
      {selectedCouncilName === "Freddie Mac" ? "Housing Cycle" : "Industries"}
    </Label>

    <DropDownNestedElements
      tags={industries}
      selectedTags={industry}
      childKey="children_industries"
      tagKeyName="id"
      parentKeyName="parent_resource_industry_id"
      handleTagSelect={handleIndustryChange}
      label="Select industries"
      // handleTagRemove={handleIndustryRemove}
      showPrevSeparate
    />

    <div>
      {prevIndustries &&
        prevIndustries.map((item) => (
          <div key={item.industry.id} className={styles.techItem}>
            {item.industry.name}{" "}
            <span
              onClick={(e) => {
                handleIndustryRemove(item.industry.id);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Icon {...smSize} icon="icn-button-delete" />
            </span>
          </div>
        ))}{" "}
    </div>
  </>
);
export default IndustryModal;

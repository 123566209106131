import {
  AI_SELECT_SCREEN,
  AI_SET_SCOUT_DATA,
  AI_SET_SWOT_DATA,
  AI_SET_TREND_DATA,
  AI_SET_COMPANY_SNAPSHOT_DATA,
  AI_UPDATE_SCOUT_COMPANY,
  AI_LOAD_MORE_SCOUT_DATA,
  AI_SET_RECOMMEND_DATA,
  WS_AI_SET_CONNECTION_STATUS,
} from "modules/tractionAI/store/tractionAI.actions";

const initState = {
  wsConnectionStatus: null,
  activeScreen: null, // 'insight',
  activeSubScreen: null, // 'swot',
  scout: {
    data: [],
    fetching: false,
  },
  insight: {
    swot: {
      fetching: false,
      data: [],
    },
    trend: {
      fetching: false,
      data: [],
    },
    companySnapshot: {
      fetching: false,
      data: [],
    },
  },
  recommend: {
    projects: {
      data: [],
      fetching: false,
    },
    ideas: {
      data: [],
      fetching: false,
    },
  },
  populate: {
    data: [],
    fetching: false,
  },
};

const removeEmptyItems = (items) => Object.keys(items)
  .filter(k => items[k].length)
  .reduce((acc, k) => ({...acc, [k]: items[k]}), {})

function isObject(value) {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

const updateRecommendWithData = (screenData, newData) => {
  if (newData.createNewSearchItem) {
    return [
      ...screenData,
      {
        projectId: newData.projectId,
        isProjectSearch: newData.isProjectSearch,
        searchByQuery: newData.searchByQuery,
        fetched: newData.fetched,
        data: [],
      }
    ];
  }

  const length = screenData.length;

  return screenData.map((el, index) => {
    if (length === index + 1) {
      if (newData.hasError) {
        return {
          ...el,
          ...newData,
        }
      }

      return {
        ...el,
        fetched: newData.fetched,
        data: !isObject(newData.data) ? el.data : [...el.data, newData.data],
      }
    }

    return el;
  });
};

const updateScoutWithData = (screenData, newData) => {
  if (newData.createNewSearchItem) {
    return [
      ...screenData,
      {
        companyId: newData.companyId,
        isCompanySearch: newData.isCompanySearch,
        searchByQuery: newData.searchByQuery,
        fetched: newData.fetched,
        data: [],
      }
    ];
  }

  const length = screenData.length;

  return screenData.map((el, index) => {
    if (length === index + 1) {
      if (newData.hasError) {
        return {
          ...el,
          ...newData,
        }
      }

      return {
        ...el,
        fetched: newData.fetched,
        data: !isObject(newData.data) ? el.data : [...el.data, newData.data],
      }
    }

    return el;
  });
};

const updateSWOTWithData = (screenData, newData) => {
  if (newData.createNewSearchItem) {
    return [
      ...screenData,
      {
        companyId: newData.companyId,
        isCompanySearch: newData.isCompanySearch,
        searchByQuery: newData.searchByQuery,
        fetched: newData.fetched,
        data: {},
      }
    ];
  }

  const length = screenData.length;

  return screenData.map((el, index) => {
    if (length === index + 1) {
      return {
        ...el,
        fetched: newData.fetched,
        data: removeEmptyItems(newData.data)
      }
    }

    return el;
  });
};

const updateCompanySnapshotWithData = (screenData, newData) => {
  if (newData.createNewSearchItem) {
    return [
      ...screenData,
      {
        searchByQuery: newData.searchByQuery,
        fetched: newData.fetched,
        data: {},
      }
    ];
  }

  const length = screenData.length;

  return screenData.map((el, index) => {
    if (length === index + 1) {
      return {
        ...el,
        fetched: newData.fetched,
        data: {
          ...el.data,
          ...newData.data,
        },
      };
    }

    return el;
  });
};

const updateTrendWithData = (screenData, newData) => {
  if (newData.createNewSearchItem) {
    return [
      ...screenData,
      {
        searchByQuery: newData.searchByQuery,
        fetched: newData.fetched,
        data: '',
      }
    ];
  }

  const length = screenData.length;

  return screenData.map((el, index) => {
    if (length === index + 1) {
      return {
        ...el,
        fetched: newData.fetched,
        data: `${newData.data.Response || newData.data}`
      }
    }

    return el;
  });
}

const applyNoAnimationBoolean = (state, payload) => {
  if (!payload.applyNoAnimationLogic) {
    return {
      ...state,
      ...payload
    }
  }

  return {
    ...state,
    ...payload,
    scout: {
      data: state.scout.data.map(el => ({...el, doNotAnimate: true })),
      fetching: false,
    },
    insight: {
      ...state.insight,
      swot: {
        fetching: false,
        data: state.insight.swot.data.map(el => ({...el, doNotAnimate: true })),
      },
      trend: {
        fetching: false,
        data: state.insight.trend.data.map(el => ({...el, doNotAnimate: true })),
      },
      companySnapshot: {
        fetching: false,
        data: state.insight.companySnapshot.data.map(el => ({...el, doNotAnimate: true })),
      },
    }
  }
}

const tractionAiReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case WS_AI_SET_CONNECTION_STATUS: {
      if (payload === 'disconnected' && state.wsConnectionStatus === 'connected') {
        const updateStateObject = (firstLevelProp, secondLevelProp) => {
          const useData = secondLevelProp ? state[firstLevelProp][secondLevelProp] : state[firstLevelProp];
          return {
            data: useData.data.map(el => {
              if (!el.fetched) {
                return {
                  ...el,
                  fetched: true,
                  hasError: true,
                  errorType: 'disconnection'
                }
              }

              return el;
            }),
            fetching: false,
          }
        };

        return {
          ...state,
          scout: updateStateObject('scout'),
          insight: {
            swot: updateStateObject('insight', 'swot'),
            trend: updateStateObject('insight', 'trend'),
            companySnapshot: updateStateObject('insight', 'companySnapshot'),
          },
          recommend: {
            projects: updateStateObject('recommend', 'projects'),
            ideas: updateStateObject('recommend', 'ideas'),
          },
          wsConnectionStatus: payload,
        }
      }

      return {
        ...state,
        wsConnectionStatus: payload,
      }
    }

    case AI_LOAD_MORE_SCOUT_DATA: {
      return {
        ...state,
        scout: {
          ...state.scout,
          data: state.scout.data.map((obj, index) => {
            if (index === payload.loadMoreIndex) {
              return {
                ...obj,
                fetching: true,
                fetched: false,
                isLoadingMore: true,
              }
            }

            return obj;
          }),
        },
      }
    }

    case AI_UPDATE_SCOUT_COMPANY: {
      return {
        ...state,
        scout: {
          ...state.scout,
          data: state.scout.data.map((obj, index) => {
            if (index === payload.parentIndex) {
              return {
                ...obj,
                data: obj.data.map((c, idx) => {
                  if (idx === payload.index) {
                    return {
                      ...c,
                      company_id: payload.company_id,
                      company_slug: payload.company_slug,
                      in_traction: true,
                      in_council_companies: true,
                    }
                  }

                  return c;
                }),
              }
            }

            return obj;
          })
        }
      }
    }

    case AI_SET_RECOMMEND_DATA: {
      const activeSubScreen = payload.activeSubScreen || state.activeSubScreen;
      if (!activeSubScreen) return state;
      return {
        ...state,
        recommend: {
          ...state.recommend,
          [activeSubScreen]: {
            ...state.recommend[activeSubScreen],
            fetching: payload.fetching,
            data: updateRecommendWithData(state.recommend[activeSubScreen]?.data, payload)
          }
        }
      }
    }

    case AI_SET_SCOUT_DATA: {
      return {
        ...state,
        scout: {
          ...state.scout,
          fetching: payload.fetching,
          data: updateScoutWithData(state.scout.data, payload)
        }
      }
    }

    case AI_SET_TREND_DATA: {
      return {
        ...state,
        insight: {
          ...state.insight,
          trend: {
            fetching: payload.fetching,
            data: updateTrendWithData(state.insight.trend.data, payload)
          }
        }
      }
    }

    case AI_SET_SWOT_DATA: {
      return {
        ...state,
        insight: {
          ...state.insight,
          swot: {
            fetching: payload.fetching,
            data: updateSWOTWithData(state.insight.swot.data, payload),
          }
        }
      }
    }

    case AI_SET_COMPANY_SNAPSHOT_DATA: {
      return {
        ...state,
        insight: {
          ...state.insight,
          companySnapshot: {
            fetching: payload.fetching,
            data: updateCompanySnapshotWithData(state.insight.companySnapshot.data, payload),
          }
        }
      }
    }

    case AI_SELECT_SCREEN: {
      return {
        ...state,
        ...applyNoAnimationBoolean(state, payload),
      }
    }

    default:
      return state;
  }
};

export default tractionAiReducer;

import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  ADD_RELATED_IDEA_ATTEMPT,
  addRelatedIdeaSuccess,
  DELETE_RELATED_IDEA_ATTEMPT,
  deleteRelatedIdeatSuccess,
  DELETE_RELATED_THEME_ATTEMPT,
  deleteRelatedThemestSuccess,
  GET_RELATED_SOLUTIONS_ATTEMPT,
  getRelatedSolutionsSuccess,
  ADD_RELATED_SOLUTION_ATTEMPT,
  addRelatedSolutionSuccess,
  DELETE_RELATED_SOLUTION_ATTEMPT,
  deleteRelatedSolutionSuccess, GET_UPCOMING_TASKS, getUpcomingTasksSuccess,
} from "./overview.action";
import {
  httpGet,
  httpPost,
  httpDelete,
  errorHandler,
} from "../../../../common/httpCall";

export const epicAddRelatedIdea = (action$, state$) =>
  action$.pipe(
    ofType(ADD_RELATED_IDEA_ATTEMPT),
    switchMap(({ payload: { project_idea, enqueueSnackbar } }) =>
      httpPost({
        call: "project_ideas",
        data: {
          project_idea,
        },
      }).pipe(
        map((response) => addRelatedIdeaSuccess(response)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicRemoveRelatedIdea = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_RELATED_IDEA_ATTEMPT),
    switchMap(({ payload: { project_id, idea_id, enqueueSnackbar } }) =>
      httpDelete({
        call: `/project_ideas?project_id=${project_id}&idea_id=${idea_id}`,
      }).pipe(
        map((response) => deleteRelatedIdeatSuccess(response)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicRemoveRelatedTheme = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_RELATED_THEME_ATTEMPT),
    switchMap(({ payload: { id, projectId, enqueueSnackbar } }) =>
      httpDelete({
        apiVersion: "v1",
        call: `themes/${id}/projects/${projectId}`,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Theme removed with success", {
              variant: "success",
            });
          }
          return deleteRelatedThemestSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetRelatedSolutions = (action$, state$) =>
  action$.pipe(
    ofType(GET_RELATED_SOLUTIONS_ATTEMPT),
    switchMap(({ payload: { projectId, enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v1",
        call: `/projects/${projectId}/solutions`,
      }).pipe(
        map((result) => {
          return getRelatedSolutionsSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicAddRelatedSolution = (action$, state$) =>
  action$.pipe(
    ofType(ADD_RELATED_SOLUTION_ATTEMPT),
    switchMap(({ payload: { projectId, data, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `/projects/${projectId}/solutions`,
        data,
      }).pipe(
        map((response) => addRelatedSolutionSuccess(response)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicRemoveRelatedSolution = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_RELATED_SOLUTION_ATTEMPT),
    switchMap(({ payload: { solutionId, projectId, enqueueSnackbar } }) =>
      httpDelete({
        apiVersion: "v1",
        call: `solutions/${solutionId}/projects/${projectId}`,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Solution removed with success", {
              variant: "success",
            });
          }
          return deleteRelatedSolutionSuccess(solutionId);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetUpcomingTasks = (action$, state$) =>
  action$.pipe(
    ofType(GET_UPCOMING_TASKS),
    switchMap(({ payload: { projectId, userId, enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v3",
        call: `projects/${projectId}/tasks/upcoming_tasks${userId ? `?user_id=${userId}` : ''}`,
      }).pipe(
        map((result) => getUpcomingTasksSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const overviewEpic = combineEpics(
  epicAddRelatedIdea,
  epicRemoveRelatedIdea,
  epicRemoveRelatedTheme,
  epicGetRelatedSolutions,
  epicAddRelatedSolution,
  epicRemoveRelatedSolution,
  epicGetUpcomingTasks,
);

export default overviewEpic;

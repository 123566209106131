import React from "react";

import classnames from "classnames";
import {
  Button,
  BtnType,
  UserInlineTypes,
  UserInline,
  CheckBox,
  CheckType,
  Label,
} from "../../../modules/primitives";
import styles from "../../../modules/home/infoContainer/infoContainer.module.scss";
import { useModelPopup } from "../../hooks/index";
import InviteMemberModal from "../../../modules/companies/company/overview/companyContact/inviteMember/index";

const CompanyContactsSection = (props) => {
  const {
    companyContacts,
    handleSelectCompanyContact,
    selectedCompanyContacts,
  } = props;
  const popup = useModelPopup();

  const handleInvite = (contact) => {
    popup.show({
      title: "Invite Contact",
      show: true,
      height: "300",
      width: "540",
      component: (
        <InviteMemberModal
          contact={contact}
          preselectedAccessType={{ val: 4, text: "Company" }}
          contactCompany={{
            id: contact.company_id,
            name: contact.company.name,
          }}
          from="people"
        />
      ),
    });
  };

  const actionButton = (contact) => {
    if (contact.invited_at && !contact?.claimed_profile) {
      return (
        <div
          className={styles.invitePending}
          onClick={() => handleInvite(contact)}
        >
          <Button btn={BtnType.FRAME_LESS} disabled>
            Invite Pending
          </Button>
        </div>
      );
    }
    if (contact.claimed_profile) {
      return (
        <div className={styles.companyAccess}>
          <span>&#x2713; ACCESS</span>
        </div>
      );
    }
    return (
      <div onClick={() => handleInvite(contact)} className={styles.invite}>
        <Button btn={BtnType.FRAME_LESS} disabled>
          Invite
        </Button>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.btnWrp}>Select Contacts</div>
      <div className={styles.contactsContaier}>
        {companyContacts.map((contact) => (
          <div key={contact.id} className={classnames(styles.contactContaier, "row")}>
            <div
              className={styles.userColumn}
              onClick={(e) => {
                e.preventDefault();
                handleSelectCompanyContact(contact.id);
              }}
            >
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={selectedCompanyContacts.includes(contact.id)}
                onChange={() => {
                  handleSelectCompanyContact(contact.id);
                }}
              />
              <UserInline
                userId={contact.id}
                first={contact.first_name}
                last={contact.last_name}
                avatar={contact.avatar}
                local
                type={UserInlineTypes.WITH_NAME}
                noNavigation
              />
            </div>
            <div className={styles.statusColumn}>{actionButton(contact)}</div>
          </div>
        ))}
        {companyContacts && companyContacts.length === 0 && (
          <Label> This company does not have contacts yet! </Label>
        )}
      </div>
    </div>
  );
};

export default React.memo(CompanyContactsSection);

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import {
  BtnType, Button, Switch, Loading,
} from "modules/primitives/index";
import { useSnackbar } from "notistack";
import styles from "./ratings.module.scss";
import AccordionComponent from "./accordion.component";
import { projectRatingsAllGet, projectTemplateRatingsAllGet } from "./ratings.actions";

const HeaderOptions = [
  {
    value: "project_score",
    text: "Project Ratings",
  },
  {
    value: "score",
    text: "Project Company Ratings",
  },
];

const RatingsComponent = () => {
  const [csvData, setCsvData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("score");
  const [cols, setColNames] = useState([]);

  const dispatch = useDispatch();
  const csvLinkRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const {
    projectsReducer: {
      projectReducer: { selectedProject },
      projectRatingsReducer: { projectRatings, projectsRatingLoading, projectTemplateRatings },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedProject) {
      dispatch(
        projectRatingsAllGet({
          projectId: selectedProject.id,
          enqueueSnackbar,
        }),
      );
      dispatch(
        projectTemplateRatingsAllGet({
          projectId: selectedProject.id,
          enqueueSnackbar,
        }),
      );
    }
  }, [selectedProject]);

  useEffect(() => {
    if (projectRatings && projectRatings.project_company_ratings && selectedTab === "score") {
      const csvDataCont = [
        [
          "Company",
          "Average Company Rating",
          "Company Ratings",
          "Notes",
        ],
      ];
      setCsvData(() => {
        projectRatings.project_company_ratings.map((el) => {
          csvDataCont.push([
            el.company_name,
            el.overall_project_score,
            el.users_company_ratings
              .map(
                (userRating) => `${userRating.full_name} - ${
                  userRating.company_total_score
                } (${el.categories
                  .map((category) => {
                    if (category.users[0]?.user_id === userRating?.user_id) {
                      return `${category.name} - ${category.users[0].score}; `;
                    }
                  })
                  .join("")})`,
              )
              .join("\n"),
            el.users_company_ratings
              .map((user) => user.notes.map((note) => `${note.description}`))
              .join("\n"),
          ]);
        });

        return csvDataCont;
      });
    }

    if (projectTemplateRatings && selectedTab !== "score") {
      const csvDataCont = [
        [
          "Members",
          "Average Project Rating",
          "Project Ratings",
          "Notes",
        ],
      ];
      setCsvData(() => {
        projectTemplateRatings.data.map((el) => {
          csvDataCont.push([
            el.attributes.user.full_name,
            el.attributes.total_rating,
            el.attributes.categories.map((category) => {
              return `${category.name} - ${category.value}; `;
            }).join("\n"),
            el.attributes.notes
              .map((note) => `${note.description}`)
              .join("\n"),
          ]);
        });
        return csvDataCont;
      });
    }
    if (projectRatings.users) {
      const colNames = projectRatings.users.sort((a, b) => a.email.localeCompare(b.email));
      setColNames(colNames);
    }
  }, [projectRatings, projectTemplateRatings, selectedTab]);

  const handleExportList = () => {
    enqueueSnackbar("List succesfully exported.", {
      variant: "success",
    });
    csvLinkRef.current.link.click();
  };
  if (projectsRatingLoading) {
    return (
      <div className={styles.ratingsWrp}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={styles.ratingsWrp}>
      {projectRatings?.project_company_ratings?.length || projectTemplateRatings?.data?.length > 0 ? (
        <div className={styles.wrpContainer}>
          <div className={styles.buttonWrp}>
            <Button onClick={handleExportList} btn={BtnType.OUTLINE}>
              EXPORT
            </Button>
            <CSVLink
              data={csvData}
              filename={`Traction Project - ${selectedProject?.name} (Ratings).csv`}
              style={{ display: "none" }}
              ref={csvLinkRef}
            />
          </div>
          <div className={styles.tableContainer}>
            <div className={styles.overflowXscroll + " col-12"}>
              <div className="row justify-content-start">
                <div className={`col-6 ${styles.tabsContainer}`}>
                  <Switch
                    tabs={HeaderOptions}
                    onChange={(value) => setSelectedTab(value)}
                    selected={selectedTab}
                  />
                </div>
              </div>
              <div className={`row justify-content-between ${styles.padding0}`}>
                <div className={`col-3 ${styles.padding10}`}>
                  <div className="row">
                    <div className="col-7 border font-weight-bold">{ selectedTab === "score" ? "Company" : "Members"}</div>
                    <div className="col-5 border font-weight-bold">
                      { selectedTab === "score" ? "Average Score" : "Rating" }
                    </div>
                  </div>
                </div>
                {
                  selectedTab === "score" ? (cols?.map((user) => (
                    <div className="col border font-weight-bold" key={user.email}>
                      {user.full_name}
                    </div>
                  )))
                    : (
                      <div className="col border font-weight-bold">
                        Notes
                      </div>
                    )
                }
              </div>
              { selectedTab === "score" ? (
                projectRatings.project_company_ratings
                  && projectRatings.project_company_ratings.map((prc) => (
                    <div
                      className="row justify-content-between"
                      key={prc.company_id}
                    >
                      <div className="col-12">
                        <AccordionComponent
                          prc={prc}
                          cols={cols}
                          selectedTab={selectedTab}
                          // users={projectRatings?.users}
                        />
                      </div>
                    </div>
                  ))
              ) : (
                projectTemplateRatings?.data?.length > 0
                  && projectTemplateRatings.data.map((ptr) => (
                    <div
                      className="row justify-content-between"
                      key={ptr.id}
                    >
                      <div className="col-12">
                        <AccordionComponent
                          prc={ptr.attributes}
                          cols={cols}
                          selectedTab={selectedTab}
                          // users={projectRatings?.users}
                        />
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.placeholder}>
          {" "}
          No Ratings Yet. <br /> Ask Project Members To Rate Companies. Scores
          Will Appear Here.
        </div>
      )}
    </div>
  );
};

export default React.memo(RatingsComponent);

import React from "react";
import PostsBlock from "../../themesIndividualPage/themesOverviewTab/postsBlock/postsBlock.component";

import styles from "./styles.module.scss";

const ThemesPosts = () => (
  <div>
    <div className={styles.postsBlock}>
      <PostsBlock homePageUse />
    </div>
  </div>
);

export default React.memo(ThemesPosts);

import React from "react";
import classNames from "classnames";

import styles from './styles.module.scss';

const FordScoreTableHeader = ({ highLightColumn }) => {
  return (
    <div className={styles.tableDataHeader}>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Project
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Pillar
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Plant
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Original Plant
      </div>
      {/*<div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>*/}
      {/*    Area*/}
      {/*</div>*/}
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Description
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, highLightColumn === 'projectStage' && styles.highLightedColumn)}>
        Project Stage
      </div>
      {/*<div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>*/}
      {/*  Project Owner*/}
      {/*</div>*/}
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Investment $
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, highLightColumn === 'savings' && styles.highLightedColumn)}>
        Savings $
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, highLightColumn === 'tarr' && styles.highLightedColumn)}>
        TARR %
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Funded By (Prototype)
      </div>
      <div className={classNames(styles.tableDataCellBigger, styles.tableDataHeaderCell)}>
        Funded By (Implementation)
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Project Start Date
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Prototype Date
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Implementation Date
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Expected Job 1 Date
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, highLightColumn === 'job1Date' && styles.highLightedColumn)}>
        Job 1 Date
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, highLightColumn === 'ideaGeneration' && styles.highLightedColumn)}>
        Idea Generation (Days)
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, highLightColumn === 'implementation' && styles.highLightedColumn)}>
        Implementation (Days)
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, highLightColumn === 'job1Accomplishment' && styles.highLightedColumn)}>
        Job 1 Accomplished
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Next Stage Change
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, highLightColumn === 'approvedReplications' && styles.highLightedColumn)}>
        Replication (Y/N)
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Original Project
      </div>
    </div>
  );
};

export default React.memo(FordScoreTableHeader);

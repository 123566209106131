import React from "react";
import Skeleton from "@mui/material/Skeleton";
import styles from "../styles.module.scss";

const Loader = () => (
  <div className={styles.loadBlockContainer}>
    <Skeleton
      className={styles.loadBlock}
      variant="rectangular"
      width={420}
      height={365}
    />
    <Skeleton
      className={styles.loadBlock}
      variant="rectangular"
      width={420}
      height={365}
    />
    <Skeleton
      className={styles.loadBlock}
      variant="rectangular"
      width={420}
      height={365}
    />
  </div>
);

export default React.memo(Loader);

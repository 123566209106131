import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import { TabComponent, Loading, Button, BtnType } from "../../../primitives";
import { useModelPopup } from "../../../../common/hooks";
import styles from "./styles.module.scss";
import { themesGetIndividualAttempt } from "../../themesMainPage/themesMain.action";
import ThemeModal from "../../themesMainPage/themeMainModal";
import EditCompanies from "./companies/index";
import EditMembers from "./members/index";
import ConfirmDeleteComponent from "../../../../common/components/confirmDelete/index";

const ManageThemes = () => {
  const history = useHistory();
  const popup = useModelPopup();
  const {
    location: { pathname },
  } = history;
  const [selectedTab, setSelectedTab] = useState(0);

  const splittedPathname = pathname.split("/");
  const id = splittedPathname[splittedPathname.length - 2];

  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { currentThemeData, loadingIndividualTheme },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const tabList = [
    {
      tab: "Overview",
      name: "Overview",
      component: (
        <ThemeModal
          width={600}
          height={600}
          mode="edit"
          data={currentThemeData}
          popup={popup}
        />
      ),
    },
    {
      tab: "Followers",
      name: "Followers",
      component: (
        <EditMembers
          themeId={currentThemeData?.id}
          memberList={currentThemeData?.members}
        />
      ),
    },
    {
      tab: "Companies",
      name: "Companies",
      component: <EditCompanies />,
    },
  ];

  const clikBack = () => {
    const splittedPathnameOnSlash = history.location.pathname.split("/");
    splittedPathnameOnSlash.splice(
      splittedPathnameOnSlash.length - 1,
      1,
      "overview"
    );
    const pushURL = history.location.state?.pathname
      ? history.location.state?.pathname
      : splittedPathnameOnSlash.join("/");
    history.push(pushURL);
  };

  const handleDeleteTheme = () => {
    popup.show({
      title: "Confirmation Delete",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={id}
          enqueueSnackbar={enqueueSnackbar}
          councilId={selectedCouncil?.id}
          actionType="deleteTheme"
        />
      ),
    });
  };

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(themesGetIndividualAttempt({ id, enqueueSnackbar }));
    }
  }, [selectedCouncil]);

  const handleOnTabSelect = (index) => {
    setSelectedTab(index);
    history.push(`/themes/individual_page/${id}/editTheme`);
  };
  if (loadingIndividualTheme || !selectedCouncil) {
    return <Loading />;
  }
  return (
    <div className={styles.mainOverview}>
      <div className={styles.headerContainer}>
        <div className={styles.titlesBlock}>
          <p className={styles.title}>
            {" "}
            Edit{" "}
            {selectedCouncil?.settings?.themes_display_name_singular || "Theme"}
          </p>
          <span className={styles.headerTitle}>{currentThemeData?.name}</span>
        </div>
        <div className={styles.headerImage} />
        <div className={styles.headerActions}>
          <div className={styles.containerBackToThemeBtn}>
            <Button
              onClick={() => clikBack()}
              btn={BtnType.FRAME_LESS}
              className={styles.backToThemeBtn}
              icon="icn-left-prev"
            >
              BACK TO{" "}
              {selectedCouncil?.settings?.themes_display_name_singular?.toUpperCase() ||
                "THEME"}
            </Button>
          </div>
          <div className={styles.containerDeleteButton}>
            <Button
              btn={BtnType.FRAME_LESS}
              rounded
              className={styles.deleteButton}
              icon="icn-button-delete"
              onClick={() => handleDeleteTheme()}
            >
              DELETE{" "}
              {selectedCouncil?.settings?.themes_display_name_singular?.toUpperCase() ||
                "THEME"}
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.tabsWrp}>
        <div className={styles.tabsContainer}>
          <TabComponent
            tabList={tabList}
            defaultIndex={selectedTab}
            onSelect={(index) => handleOnTabSelect(index)}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ManageThemes);

import { apiStatus, pageMeta } from "../httpCall";
import {
  PRIVATE_DISCUSSION_SAVE_SUCCESS,
  PRIVATE_DISCUSSIONS_GET_ATTEMPT,
  PRIVATE_DISCUSSIONS_GET_SUCCESS,
  PROJECT_DISCUSSIONS_GET_SUCCESS,
  PUBLIC_DISCUSSION_SAVE_SUCCESS,
  PUBLIC_DISCUSSIONS_GET_SUCCESS,
  PUBLIC_DISCUSSIONS_GET_ATTEMPT,
  SEND_REPLY_ATTEMPT,
  SEND_REPLY_SUCCESS,
  PROJECT_DISCUSSION_SAVE_ATTEMPT,
  PROJECT_DISCUSSIONS_RESET,
  USER_TEAMS_GET_SUCCESS,
  COMPANY_DISCUSSIONS_GET_SUCCESS,
  COMPANY_DISCUSSION_SAVE_ATTEMPT,
  EVENT_DISCUSSIONS_GET_SUCCESS,
  EVENT_DISCUSSION_SAVE_ATTEMPT,
  EVENT_DISCUSSIONS_CLEAR,
  COMPANY_DISCUSSIONS_GET_ATTEMPT,
  PATIENT_ORG_DISCUSSIONS_GET_SUCCESS,
  PATIENT_ORG_DISCUSSION_SAVE_SUCCESS,
  ADD_DISCUSSION_MEMBERS_SUCCESS,
  ADD_DISCUSSION_MEMBERS_ATTEMPT,
  ERROR_CATCH_SUCCESS,
  SET_SELECTED_DISCUSSION,
  WS_NEW_PROJECT_DISCUSSION,
  WS_NEW_DISCUSSION,
  WS_NEW_EVENT_DISCUSSION,
  WS_NEW_DISCUSSION_MSG,
  RESET_MESSAGES_COUNT,
  DISCUSSION_MSG_DELETE_SUCCESS,
  DISCUSSION_DELETE_SUCCESS,
  DISCUSSION_EDIT_SUCCESS,
  NOTIFICATIONS_GET_ATTEMPT,
  NOTIFICATIONS_GET_SUCCESS,
  MY_TASKS_NOTIFICATIONS_GET_ATTEMPT,
  MY_TASKS_NOTIFICATIONS_GET_SUCCESS,
  MY_TASKS_NOTIFICATIONS_DELETE_ATTEMPT,
  MY_TASKS_NOTIFICATIONS_DELETE_SUCCESS,
} from "../actions/discussions.action";
import { CREATE_PROJECT_DISCUSSION_SUCCESS } from "modules/projects/project/project.action";

const initState = {
  publicDiscussions: null,
  privateDiscussions: null,
  projectDiscussions: null,
  notifications: [],
  myTasksNotifications: [],
  userTeams: null,
  companyDiscussions: [],
  patientOrgDiscussions: [],
  eventDiscussions: null,
  updateCompany: false,
  loading: false,
  sendReplyPending: false,
  updateDiscussions: false,
  loadDiscussions: false,
  loadNotifications: false,
  selectedDiscussion: {},
  newMessagesCount: 0,
  pageMeta: {},
  shouldUpdateList: false,
};

const getDiscussionPropState = (newPayload, state, prop) => {
  const checkExistsHere =
    state[prop]?.some((d) => d.id === newPayload.id) || false;

  if (checkExistsHere) {
    return {
      [prop]: state[prop].map((d) => {
        if (d.id === newPayload.id) {
          return {
            ...d,
            ...newPayload,
          };
        }

        return d;
      }),
    };
  } else {
    return {
      [prop]: [newPayload, ...(state[prop] || [])],
    };
  }
};

const updateDiscussionMsgState = (msg, state, prop) => {
  if (!state[prop]) {
    return null;
  }

  return state[prop].map((d) => {
    if (d.id === msg.discussion_id) {
      return {
        ...d,
        messages: d.messages.map((m) => {
          if (m.message_id === msg.message_id) {
            return {
              ...m,
              ...msg,
            };
          }

          return m;
        }),
      };
    }

    return d;
  });
};

const updateDiscussionState = (discussion, state, prop, action) => {
  if (!state[prop]) {
    return null;
  }

  if (action === "delete") {
    return state[prop].filter((d) => d.id !== discussion.id);
  }

  return state[prop].map((d) => {
    if (d.id === discussion.id) {
      return {
        ...d,
        ...discussion,
      };
    }

    return d;
  });
};

const getDiscussionMsgState = (newPayload, state, prop) => {
  if (!state[prop]) {
    return null;
  }

  return state[prop].map((d) => {
    if (d.id === newPayload.discussion_id) {
      return {
        ...d,
        messages: [...(d.messages || []), newPayload],
      };
    }

    return d;
  });
};

const discussionsReducer = (state = initState, action) => {
  const { type, payload } = action;

  const getCurrentUpdatedDiscussion = (response) => {
    const discussion = response.filter(
      (disc) => disc.id === state.selectedDiscussion?.id
    );

    if (discussion && discussion[0]) {
      return discussion[0];
    }
    return false;
  };

  switch (type) {
    case DISCUSSION_EDIT_SUCCESS: {
      const { response, action } = payload;

      return {
        ...state,
        publicDiscussions: updateDiscussionState(
          response,
          state,
          "publicDiscussions",
          action
        ),
        privateDiscussions: updateDiscussionState(
          response,
          state,
          "privateDiscussions",
          action
        ),
        projectDiscussions: updateDiscussionState(
          response,
          state,
          "projectDiscussions",
          action
        ),
        eventDiscussions: updateDiscussionState(
          response,
          state,
          "eventDiscussions",
          action
        ),
      };
    }

    case DISCUSSION_DELETE_SUCCESS: {
      const { discussion, action } = payload;

      let selectedDiscussion = state.selectedDiscussion;

      if (action === "delete" && selectedDiscussion.id === discussion.id) {
        selectedDiscussion = null;
      }

      return {
        ...state,
        publicDiscussions: updateDiscussionState(
          discussion,
          state,
          "publicDiscussions",
          action
        ),
        privateDiscussions: updateDiscussionState(
          discussion,
          state,
          "privateDiscussions",
          action
        ),
        projectDiscussions: updateDiscussionState(
          discussion,
          state,
          "projectDiscussions",
          action
        ),
        eventDiscussions: updateDiscussionState(
          discussion,
          state,
          "eventDiscussions",
          action
        ),
        selectedDiscussion,
      };
    }

    case DISCUSSION_MSG_DELETE_SUCCESS: {
      let msg = {};
      if (payload.action === "delete") {
        msg = {
          message_id: payload.message_id,
          discussion_id: payload.discussion_id,
          deleted: true,
        };
      }

      if (payload.action === "edit") {
        msg = {
          content: payload.content,
          message_id: payload.message_id,
          discussion_id: payload.discussion_id,
        };
      }

      return {
        ...state,
        publicDiscussions: updateDiscussionMsgState(
          msg,
          state,
          "publicDiscussions"
        ),
        privateDiscussions: updateDiscussionMsgState(
          msg,
          state,
          "privateDiscussions"
        ),
        projectDiscussions: updateDiscussionMsgState(
          msg,
          state,
          "projectDiscussions"
        ),
        eventDiscussions: updateDiscussionMsgState(
          msg,
          state,
          "eventDiscussions"
        ),
        selectedDiscussion: {
          ...state.selectedDiscussion,
          messages: state.selectedDiscussion.messages.map((m) => {
            if (m.message_id === msg.message_id) {
              return {
                ...m,
                ...msg,
              };
            }

            return m;
          }),
        },
      };
    }

    case RESET_MESSAGES_COUNT: {
      return {
        ...state,
        newMessagesCount: 0,
      };
    }

    case WS_NEW_DISCUSSION_MSG: {
      let selectedDiscussion = {};

      if (state.selectedDiscussion.id === payload.discussion_id) {
        selectedDiscussion = {
          messages: [...(state.selectedDiscussion.messages || []), payload],
        };
      }

      const newMessagesCount =
        window.location.pathname === "/discussions"
          ? state.newMessagesCount
          : state.newMessagesCount + 1;

      return {
        ...state,
        publicDiscussions: getDiscussionMsgState(
          payload,
          state,
          "publicDiscussions"
        ),
        privateDiscussions: getDiscussionMsgState(
          payload,
          state,
          "privateDiscussions"
        ),
        projectDiscussions: getDiscussionMsgState(
          payload,
          state,
          "projectDiscussions"
        ),
        eventDiscussions: getDiscussionMsgState(
          payload,
          state,
          "eventDiscussions"
        ),
        selectedDiscussion: {
          ...state.selectedDiscussion,
          ...selectedDiscussion,
        },
        newMessagesCount,
      };
    }

    case WS_NEW_DISCUSSION: {
      const newPayload = {
        ...payload,
        messages: payload.messages.map((mObj) => mObj.attributes),
      };
      let tempDiscussionState = {};
      let selectedDiscussion = {};

      if (newPayload.public && state.publicDiscussions?.length) {
        tempDiscussionState = getDiscussionPropState(
          newPayload,
          state,
          "publicDiscussions"
        );
      }

      if (!newPayload.public && state.privateDiscussions?.length) {
        tempDiscussionState = getDiscussionPropState(
          newPayload,
          state,
          "privateDiscussions"
        );
      }

      const isSelectedDiscussion =
        state.selectedDiscussion?.id === newPayload.id;

      if (isSelectedDiscussion) {
        selectedDiscussion = {
          ...state.selectedDiscussion,
          messages: newPayload.messages,
        };
      }

      const newMessagesCount =
        window.location.pathname === "/discussions"
          ? state.newMessagesCount
          : state.newMessagesCount + 1;

      return {
        ...state,
        ...tempDiscussionState,
        selectedDiscussion,
        newMessagesCount,
      };
    }

    case WS_NEW_EVENT_DISCUSSION: {
      const newPayload = {
        ...payload,
        messages: payload.messages.map((mObj) => mObj.attributes),
      };
      let tempDiscussionState = {};
      let selectedDiscussion = {};

      if (newPayload.public && state.publicDiscussions?.length) {
        tempDiscussionState = getDiscussionPropState(
          newPayload,
          state,
          "publicDiscussions"
        );
      }

      if (!newPayload.public && state.privateDiscussions?.length) {
        tempDiscussionState = getDiscussionPropState(
          newPayload,
          state,
          "privateDiscussions"
        );
      }

      const isSelectedDiscussion =
        state.selectedDiscussion?.id === newPayload.id;

      if (isSelectedDiscussion) {
        selectedDiscussion = {
          ...state.selectedDiscussion,
          messages: newPayload.messages,
        };
      }

      const checkExistsHere = state.eventDiscussions?.some(
        (d) => d.id === newPayload.id
      );
      const newMessagesCount =
        window.location.pathname === "/discussions"
          ? state.newMessagesCount
          : state.newMessagesCount + 1;

      if (checkExistsHere) {
        return {
          ...state,
          ...tempDiscussionState,
          selectedDiscussion,
          eventDiscussions: state.eventDiscussions?.map((d) => {
            if (d.id === newPayload.id) {
              return {
                ...d,
                ...newPayload,
              };
            }

            return d;
          }),
          newMessagesCount,
        };
      }

      return {
        ...state,
        ...tempDiscussionState,
        selectedDiscussion,
        eventDiscussions: [newPayload, ...(state.eventDiscussions || [])],
        newMessagesCount,
      };
    }

    case WS_NEW_PROJECT_DISCUSSION: {
      let tempDiscussionState = {};
      let selectedDiscussion = {};
      const newPayload = {
        ...payload,
        messages: payload.messages.map((mObj) => mObj.attributes),
      };

      if (newPayload.public && state.publicDiscussions?.length) {
        tempDiscussionState = getDiscussionPropState(
          newPayload,
          state,
          "publicDiscussions"
        );
      }

      if (!newPayload.public && state.privateDiscussions?.length) {
        tempDiscussionState = getDiscussionPropState(
          newPayload,
          state,
          "privateDiscussions"
        );
      }

      const checkExistsHere = state.projectDiscussions?.some(
        (d) => d.id === newPayload.id
      );
      const isSelectedDiscussion =
        state.selectedDiscussion?.id === newPayload.id;
      const newMessagesCount =
        window.location.pathname === "/discussions"
          ? state.newMessagesCount
          : state.newMessagesCount + 1;

      if (isSelectedDiscussion) {
        selectedDiscussion = {
          ...state.selectedDiscussion,
          messages: newPayload.messages,
        };
      }

      if (checkExistsHere) {
        return {
          ...state,
          ...tempDiscussionState,
          selectedDiscussion,
          projectDiscussions: state.projectDiscussions?.map((d) => {
            if (d.id === newPayload.id) {
              return {
                ...d,
                ...newPayload,
              };
            }

            return d;
          }),
          newMessagesCount,
        };
      }

      return {
        ...state,
        ...tempDiscussionState,
        selectedDiscussion,
        projectDiscussions: [newPayload, ...(state.projectDiscussions || [])],
        newMessagesCount,
      };
    }

    case EVENT_DISCUSSIONS_GET_SUCCESS:
      {
        const { response, status } = payload;
        const updatedDisc = getCurrentUpdatedDiscussion(response);
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            eventDiscussions: response,
            selectedDiscussion:
              state.selectedDiscussion?.id && updatedDisc
                ? updatedDisc
                : state.selectedDiscussion,
          };
        }
      }
      break;

    case EVENT_DISCUSSIONS_CLEAR: {
      return {
        ...state,
        eventDiscussions: null,
      };
    }

    case EVENT_DISCUSSION_SAVE_ATTEMPT:
      {
        const { response, status } = payload;
        if (status === apiStatus.CREATED) {
          return {
            ...state,
            eventDiscussions: [response, ...state.eventDiscussions],
            // updateDiscussions: true,
          };
        }
      }
      break;

    case COMPANY_DISCUSSIONS_GET_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case PATIENT_ORG_DISCUSSIONS_GET_SUCCESS:
      {
        const { response, status } = payload;
        const updatedDisc = getCurrentUpdatedDiscussion(response);
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            patientOrgDiscussions: response,
            selectedDiscussion:
              state.selectedDiscussion?.id && updatedDisc
                ? updatedDisc
                : state.selectedDiscussion,
          };
        }
      }
      break;

    case PATIENT_ORG_DISCUSSION_SAVE_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.CREATED) {
          return {
            ...state,
            patientOrgDiscussions: [...state.patientOrgDiscussions, response],
          };
        }
      }
      break;

    case COMPANY_DISCUSSIONS_GET_SUCCESS:
      {
        const { response, status } = payload;
        const updatedDisc = getCurrentUpdatedDiscussion(response);
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            companyDiscussions: response,
            loading: false,
            selectedDiscussion:
              state.selectedDiscussion?.id && updatedDisc
                ? updatedDisc
                : state.selectedDiscussion,
          };
        }
      }
      break;

    case COMPANY_DISCUSSION_SAVE_ATTEMPT:
      {
        const { response, status } = payload;
        if (status === apiStatus.CREATED) {
          return {
            ...state,
            companyDiscussions: [response, ...state.companyDiscussions],
            updateCompany: false,
          };
        }
      }
      break;

    case CREATE_PROJECT_DISCUSSION_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.CREATED) {
          return {
            ...state,
            projectDiscussions: [response, ...state.projectDiscussions],
          };
        }
      }
      break;

    case USER_TEAMS_GET_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            userTeams: [{ id: "AllId", name: "All" }, ...response],
            updateCompany: true,
          };
        }
      }
      break;

    case PROJECT_DISCUSSIONS_RESET: {
      return {
        ...state,
        projectDiscussions: null,
      };
    }

    case PROJECT_DISCUSSION_SAVE_ATTEMPT:
      {
        const { response, status } = payload;
        if (status === apiStatus.CREATED) {
          return {
            ...state,
            projectDiscussions: [...state.projectDiscussions, response],
          };
        }
      }
      break;

    case PROJECT_DISCUSSIONS_GET_SUCCESS:
      {
        const { response, status } = payload;
        const updatedDisc = getCurrentUpdatedDiscussion(response);
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            projectDiscussions: response,
            selectedDiscussion:
              state.selectedDiscussion?.id && updatedDisc
                ? updatedDisc
                : state.selectedDiscussion,
          };
        }
      }
      break;

    case SEND_REPLY_ATTEMPT: {
      return {
        ...state,
        sendReplyPending: true,
        updateDiscussions: false,
      };
    }

    case SEND_REPLY_SUCCESS:
      {
        const { response, status, prop } = payload;
        if (status === apiStatus.CREATED) {
          return {
            ...state,
            sendReplyPending: false,
            updateDiscussions: true,
            eventDiscussions: state.eventDiscussions.map((d) => {
              if (d.id === response.discussion_id) {
                return {
                  ...d,
                  messages: [...d.messages, response],
                };
              }
              return d;
            }),
            selectedDiscussion: {
              ...state.selectedDiscussion,
              messages: state.selectedDiscussion?.messages
                ? [...state.selectedDiscussion?.messages, response]
                : [],
            },
          };
        }
      }
      break;

    case PUBLIC_DISCUSSION_SAVE_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.CREATED) {
          if (state.publicDiscussions) {
            return {
              ...state,
              publicDiscussions: [response, ...state.publicDiscussions],
            };
          }

          return state;
        }
      }
      break;

    case PRIVATE_DISCUSSION_SAVE_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.CREATED) {
          return {
            ...state,
            privateDiscussions: state.privateDiscussions
              ? [response, ...state.privateDiscussions]
              : [response],
          };
        }
      }
      break;
    case PUBLIC_DISCUSSIONS_GET_ATTEMPT: {
      return {
        ...state,
        loadDiscussions: true,
      };
    }

    case PUBLIC_DISCUSSIONS_GET_SUCCESS:
      {
        const { response, status } = payload;
        const updatedDisc = getCurrentUpdatedDiscussion(response);
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            publicDiscussions: response,
            loadDiscussions: false,
            pageMeta: pageMeta(payload),
            selectedDiscussion:
              state.selectedDiscussion?.id && updatedDisc
                ? updatedDisc
                : state.selectedDiscussion,
          };
        }
      }
      break;

    case PRIVATE_DISCUSSIONS_GET_ATTEMPT: {
      return {
        ...state,
        loadDiscussions: true,
      };
    }

    case PRIVATE_DISCUSSIONS_GET_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          const updatedDisc = getCurrentUpdatedDiscussion(response);

          return {
            ...state,
            privateDiscussions: response,
            loadDiscussions: false,
            updateDiscussions: false,
            pageMeta: pageMeta(payload),
            selectedDiscussion:
              state.selectedDiscussion?.id && updatedDisc
                ? updatedDisc
                : state.selectedDiscussion,
          };
        }
      }
      break;

    case NOTIFICATIONS_GET_ATTEMPT: {
      return {
        ...state,
        loadNotifications: true,
        notifications: [],
      };
    }

    case NOTIFICATIONS_GET_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          notifications: response,
          loadNotifications: false,
          pageMeta: pageMeta(payload),
        };
      }
    }

    case MY_TASKS_NOTIFICATIONS_GET_ATTEMPT: {
      return {
        ...state,
        loadNotifications: true,
        myTasksNotifications: [],
      };
    }

    case MY_TASKS_NOTIFICATIONS_GET_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          myTasksNotifications: response,
          loadNotifications: false,
          shouldUpdateList: false,

          pageMeta: pageMeta(payload),
        };
      }
    }
    case MY_TASKS_NOTIFICATIONS_DELETE_ATTEMPT: {
      return {
        ...state,
        loadNotifications: true,
        myTasksNotifications: [],
        shouldUpdateList: false,
      };
    }

    case MY_TASKS_NOTIFICATIONS_DELETE_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          loadNotifications: false,
          shouldUpdateList: true,
        };
      }
    }
    case ADD_DISCUSSION_MEMBERS_ATTEMPT: {
      return {
        ...state,
        updateDiscussions: false,
      };
    }

    case ADD_DISCUSSION_MEMBERS_SUCCESS:
      const { response } = payload;
      {
        return {
          ...state,
          selectedDiscussion: {
            ...state.selectedDiscussion,
            participants: [...response.participants],
          },
          updateDiscussions: true,
        };
      }

    case SET_SELECTED_DISCUSSION: {
      return {
        ...state,
        selectedDiscussion: payload,
      };
    }

    case ERROR_CATCH_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }

  return state;
};

export default discussionsReducer;

import {
  APP_MODEL_SETTING_SET,
  APP_SLIDER_SETTING_SET,
  APP_SELECTED_SET,
  APP_MOBILE_NAVI_SET,
  APP_MODAL_MAKE_RERENDER,
  APP_SET_CHARTS_DOWNLOAD_STATUS,
  GET_RELATIONSHIP_STATUSES_SUCCESS,
  HIGHLIGHT_ITEM_WITH_ID,
} from "./app.action";
import { modalSetting, sliderSetting } from "./app.constants";
import {
  RELATIONSHIP_OPTIONS_FALLBACK,
} from "../common/constants";
import {
  TAG_RELATIONSHIP_ADD_SUCCESS,
  TAG_RELATIONSHIP_DELETE_SUCCESS,
  TAG_RELATIONSHIP_EDIT_SUCCESS,
} from "modules/admin/adminTags/adminTags.actions";

const initState = {
  modalSetting,
  sliderSetting,
  selectedItem: null,
  showMobileNavi: false,
  makeModalRerender: false,
  chartsDownloadIsActive: false,
  relationShipStatuses: [],
  highlightItemWithId: null,
};

const makeColors = (arr) => arr.map(({ id, name, ...rest }) => ({
  ...rest,
  customId: id,
  name,
}));

const appReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case HIGHLIGHT_ITEM_WITH_ID: {
      return {
        ...state,
        highlightItemWithId: payload,
      };
    }

    case TAG_RELATIONSHIP_DELETE_SUCCESS: {
      const newStatuses = state.relationShipStatuses.filter(
        (s) => s.customId !== payload.tagId,
      );
      const relationShipStatuses = makeColors(newStatuses);

      return {
        ...state,
        relationShipStatuses,
      };
    }

    case TAG_RELATIONSHIP_EDIT_SUCCESS: {
      const newStatuses = state.relationShipStatuses.map((s) => {
        if (s.customId === payload.response?.id) {
          return {
            ...s,
            ...payload.response,
          };
        }

        return s;
      });
      const relationShipStatuses = makeColors(newStatuses);

      return {
        ...state,
        relationShipStatuses,
      };
    }

    case TAG_RELATIONSHIP_ADD_SUCCESS: {
      const relationShipStatuses = makeColors([
        ...state.relationShipStatuses,
        payload.response,
      ]);

      return {
        ...state,
        relationShipStatuses,
      };
    }

    case GET_RELATIONSHIP_STATUSES_SUCCESS: {
      const newStatuses = makeColors(payload?.response || []);

      return {
        ...state,
        relationShipStatuses: newStatuses || RELATIONSHIP_OPTIONS_FALLBACK,
      };
    }
    case APP_SET_CHARTS_DOWNLOAD_STATUS:
      return { ...state, chartsDownloadIsActive: payload };
    case APP_MODEL_SETTING_SET:
      return { ...state, modalSetting: payload };
    case APP_SLIDER_SETTING_SET:
      return { ...state, sliderSetting: payload };
    case APP_SELECTED_SET:
      return { ...state, selectedItem: payload };
    case APP_MOBILE_NAVI_SET:
      return { ...state, showMobileNavi: payload };
    case APP_MODAL_MAKE_RERENDER:
      return { ...state, makeModalRerender: payload };
    default:
      break;
  }

  return state;
};

export default appReducer;

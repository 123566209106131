const getTimezoneOffset = () => {
  const now = new Date();

  const timezoneOffset = now.getTimezoneOffset();

  const hoursOffset = Math.abs(Math.floor(timezoneOffset / 60));
  const minutesOffset = Math.abs(timezoneOffset % 60);

  const sign = timezoneOffset > 0 ? "-" : "+";

  const timezoneOffsetString =
    "(GMT" +
    sign +
    hoursOffset.toString().padStart(2, "0") +
    ":" +
    minutesOffset.toString().padStart(2, "0") +
    ")";
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const currentTimeString = timezoneOffsetString + " " + userTimezone;
  return currentTimeString;
};
export default getTimezoneOffset;

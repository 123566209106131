import React, { useEffect, useState } from "react";
import { FieldArray, Formik } from "formik";
import classNames from "classnames";
import { BtnType, Button, CheckBox, CheckType, Label, TextBox } from "../../../../../../primitives";
import styles from "./styles.module.scss";
import { model, validation } from "./constant";
import { Icon, smSize } from "../../../../../../../common/icon";

const NewCheckListComponent = (props) => {
  const {
    formRef, handleOnSubmit, data, mode, fromAdmin = false, adminLocation,
  } = props;
  const [error, setError] = useState("");
  const [initValues, setInitValues] = useState({});
  const [multiselect, setMultiSelect] = useState(
    mode === "edit" ? data?.multiselect : model?.multiselect,
  );
  const [deletedOptions, setDeletedOptions] = useState([]);

  useEffect(() => {
    if (data) {
      setInitValues({
        field_value: data.field_value,
        title: data.field_name,
        options: data.option_values,
        option_selected: data.option_selected,
        multiselect: data.multiselect,
        field_placeholder: data.field_placeholder || "",
      });
    }
  }, [data]);

  const onSubmit = (values) => {
    values.multiselect = multiselect;
    if (mode === "edit") {
      values.option_selected = values?.option_selected
        ?.map((selectedOption) => {
          const foundedOption = deletedOptions.find(
            (option) => option === selectedOption,
          );
          if (!foundedOption) {
            return selectedOption;
          }
        })
        .filter((e) => e !== undefined);
    }

    values.options = values.options.filter((option) => option !== "");

    if (values.options[0]) {
      handleOnSubmit(values);
    } else {
      setError("Required at least one option");
    }
  };

  const handleMultiCheckClick = (value) => {
    setMultiSelect(value);
  };

  return (
    <div className={styles.wrapper}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={data ? initValues : model}
        validationSchema={validation && validation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          onSubmit(values);
          resetForm();
        }}
      >
        {({
          handleSubmit, values, setFieldValue, ...formikprops
        }) => (
          <form onSubmit={handleSubmit}>
            <Label>Title</Label>
            <TextBox
              type="text"
              name="title"
              placeholder="Checklist Title"
              formProps={{ ...formikprops, values, setFieldValue }}
              className={styles.textBoxTitle}
            />
            {!adminLocation && (
              <div className="d-flex flex-column align-items-start">
                <Label>Instructions (Optional)</Label>
                <TextBox
                  type="text"
                  name="field_placeholder"
                  placeholder="Add instructions"
                  formProps={{ ...formikprops, values, setFieldValue }}
                  className={styles.textBox}
                />
              </div>
            )}
            <Label>Checklist Options</Label>
            <FieldArray name="options">
              {({ remove, push }) => (
                <div>
                  {values?.options?.map((item, index) => (
                    <div className={styles.inputContainer}>
                      <TextBox
                        formProps={{ ...formikprops, values, setFieldValue }}
                        key={index}
                        type="text"
                        name={`options.${index}`}
                        value={item}
                        placeholder="Option"
                        className={styles.textBox}
                      />
                      <div
                        onClick={() => {
                          setDeletedOptions((prevState) => [
                            ...prevState,
                            item,
                          ]);
                          return remove(index);
                        }}
                        className={styles.deleteButton}
                      >
                        <Icon {...smSize} icon="icn-button-delete" />
                      </div>
                    </div>
                  ))}
                  <div className={styles.formElement}>
                    <Button
                      btn={BtnType.FRAME_LESS}
                      onClick={(e) => {
                        e.preventDefault();
                        return push("");
                      }}
                    >
                      Add another item
                    </Button>
                  </div>
                </div>
              )}
            </FieldArray>
          </form>
        )}
      </Formik>
      <div className={classNames(styles.formElement, styles.multiSelectWrp)}>
        <CheckBox
          checkType={CheckType.BLUE}
          isChecked={
            mode === "edit" ? initValues.multiselect : model?.multiselect
          }
          onChange={(value) => handleMultiCheckClick(value)}
          disabled={fromAdmin && mode === "edit"}
        />
        <Label>Allow Multiselect</Label>
      </div>
      <div>
        <span
          style={{
            color: "red",
          }}
        >
          {error}
        </span>
      </div>
    </div>
  );
};

export default React.memo(NewCheckListComponent);

import { apiStatus, pageMeta } from "../httpCall";

import {
  ADD_DOCUMENT_ATTEMPT,
  ADD_DOCUMENT_SUCCESS,
  COMPANY_DOCUMENTS_GET_ATTEMPT,
  COMPANY_DOCUMENTS_GET_SUCCESS,
  DELETE_DOCUMENT_ATTEMPT,
  DELETE_DOCUMENT_SUCCESS,
  DOCUMENT_DELETE,
} from "../actions/common.action";

const initState = {
  documents: [],
  updateDocuments: false,
  loadingDocs: false,
  docListMeta: {},
  shouldUpdateDocuments: false,
};

const documentsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_DOCUMENT_ATTEMPT: {
      return {
        ...state,
      };
    }
    case ADD_DOCUMENT_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS || status === apiStatus.CREATED) {
          return {
            ...state,
            shouldUpdateDocuments: true,
            updateDocuments: true,
            documents: [...state.documents, response],
          };
        }
      }
      break;

    case COMPANY_DOCUMENTS_GET_ATTEMPT: {
      return {
        ...state,
        loadingDocs: true,
      };
    }

    case COMPANY_DOCUMENTS_GET_SUCCESS:
      {
        const pagemeta = pageMeta(payload);
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            documents: response,
            loadingDocs: false,
            docListMeta: pagemeta,
            updateDocuments: false,
            shouldUpdateDocuments: false,
          };
        }
      }
      break;

    case DELETE_DOCUMENT_ATTEMPT: {
      return {
        ...state,
        updateDocuments: false,
      };
    }

    case DELETE_DOCUMENT_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          // documents: response,
          updateDocuments: true,
        };
      }
      break;
    }

    case DOCUMENT_DELETE: {
      return {
        ...state,
        shouldUpdateDocuments: true,
        documents: state.documents.filter((d) => d.id !== payload),
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default documentsReducer;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { format, isBefore } from "date-fns";
import { useSnackbar } from "notistack";
import Select from "react-dropdown-select";
import DropDownNestedElements from "common/components/dropdownNestedElements";
import { gskOptionCountries } from "common/constants";
import { sortCountries } from "common/helper";
import {
  companyGetFilters,
  companyGetTopics,
} from "modules/companies/companyMain/companyMain.action";
import { patientOrganizationGetDiseases } from "modules/patientOrganizations/patientOrganizationMain/patientOrganizationMain.action";
import { ratingsTemplateGetAttempt } from "modules/admin/adminRating/adminRating.action";
import { getUserTeams } from "modules/teams/teams.action";
import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import ProjectStage from "modules/projects/project/projectStage";
import {
  BtnType,
  Button,
  CheckBox,
  CheckType,
  DatePiker,
  DropSelect,
  Label,
} from "../../../../primitives";
import styles from "./details.module.scss";
import { projectGet, projectPut } from "../../project.action";
import { useModelPopup } from "../../../../../common/hooks";
import RatingTemplateModal from "./ratingTemplateModal.component";
import { optionStatus, priorityOptions } from "../../project.constant";
import RichEditor from "../../../../../common/components/richEditor";

import {
  getPlantByType,
  updatePlant,
} from "../../../../../common/actions/common.action";

// TODO refactor this component to get rid of all below useState(), and use instead formik with a single state object
const DetailsComponent = ({ handleOpenModal, isPatientOrg }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: { topics, industries },
    },
    projectsReducer: {
      projectReducer: { selectedProject, tempMembers, projectPlant },
    },
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: { diseases },
    },
    adminRatingTemplatesReducer: { ratingTemplates },
    teamsReducer: { userTeams },
    adminPlantsAndAreasReducer: { plantsAndAreas },
  } = useSelector((state) => state);

  const [stage, setStage] = useState(selectedProject?.stage || null);
  const [enableProjectRatings, setEnableProjectRatings] = useState(
    selectedProject?.rating_enabled
  );
  const [automatedStatus, setAutomatedStatus] = useState(true);
  const [name, handleChangeName] = useState(selectedProject?.name || null);
  const [goal, handleChangeGoal] = useState(
    selectedProject?.description || null
  );
  const [team_id, handleChangeTeamId] = useState(
    selectedProject?.team?.team_id || null
  );
  const [nextSteps, setNextSteps] = useState(null);
  const [showComponent, setShowComponent] = useState(true);

  const popup = useModelPopup();

  const isAdmin = useMemo(() => {
    return ["ttp_administrator", "council_administrator"].includes(
      session?.council_role
    )
  }, [session?.council_role]);

  useEffect(() => {
    if (selectedCouncil?.name !== "Ford") {
      return;
    }

    if (!plantsAndAreas.length && selectedCouncil?.name === "Ford") {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        })
      );
    }
  }, [plantsAndAreas, selectedCouncil]);

  useEffect(() => {
    if (selectedCouncil?.name !== "Ford") {
      return;
    }

    if (!projectPlant && selectedProject?.id) {
      dispatch(
        getPlantByType({
          resourceId: selectedProject.id,
          enqueueSnackbar,
          type: "Project",
        })
      );
    }
  }, [projectPlant, selectedCouncil]);

  useEffect(() => {
    if (projectPlant && plantsAndAreas.length) {
      const plant = {
        id: projectPlant.id,
        name: projectPlant.name,
        selectedAreas:
          projectPlant?.areas.map((area) => ({
            area_id: area.id,
            area_name: area.attributes.name,
          })) || [],
        areas:
          plantsAndAreas.find((p) => p.id === projectPlant.id)?.attributes
            ?.areas || [],
      };

      setSelectedPlant(plant);
    }
  }, [projectPlant, plantsAndAreas, selectedProject]);

  useEffect(() => {
    if (selectedProject?.next_steps) {
      setNextSteps(selectedProject.next_steps);
    } else {
      setNextSteps("");
    }
  }, [selectedProject?.next_steps]);

  const [selectedPlant, setSelectedPlant] = useState({});
  const [fordDates, setFordDates] = useState({
    prototype_date: selectedProject?.prototype_date || "",
    implementation_date: selectedProject?.implementation_date || "",
    expected_job_1_date: selectedProject?.expected_job_1_date || "",
    prototype_job_1_date: selectedProject?.prototype_job_1_date || "",
    proposal_for_prototype_expected_job_1_date: selectedProject?.proposal_for_prototype_expected_job_1_date || "",
  });
  const [visibility, setVisibility] = useState("");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [priority, setPriority] = useState(
    selectedProject.priority || "medium"
  );
  const [status, setStatus] = useState(selectedProject.status || "");
  const [country, setCountry] = useState(selectedProject.country || "");
  const [selectedRatingsTemplate, setSelectedRatingsTemplate] = useState({});
  const [selectedCompanyTemplate, setSelectedCompanyTemplate] = useState({});
  const [startDate, handleChangeStartDate] = useState(
    (selectedProject && selectedProject.start_date) ||
      (selectedProject && selectedProject.created_at)
  );
  const [endDate, handleChangeEndDate] = useState(selectedProject?.end_date);
  const [tags, setTags] = useState(
    selectedProject.topics.map((t) => t.topic_id)
  );
  const [selectedIndustries, setSelectedIndustries] = useState(
    selectedProject.industries.map((t) => t.industry_id)
  );
  const [selectedDiseases, setSelectedDiseases] = useState([
    ...selectedProject.diseases.map((el) => el.disease_id),
  ]);
  const [flattenDiseases, setFlattenDisease] = useState([]);
  const [error, setError] = useState(false);
  const [errorTeamId, setErrorTeamId] = useState(false);
  const [selectedTeamOption, setSelectedTeamOption] = useState([]);
  const [priorityError, setPriorityError] = useState(false);

  const [optionVisibility, setOptionVisibility] = useState([
    {
      text: "All (visible to everyone)",
      value: "all",
    },
    {
      text: "Project Members",
      value: "project_members",
    },
  ]);

  useEffect(() => {
    if (priority && priorityError) {
      setPriorityError(false);
    }
  }, [priority]);
  useEffect(() => {
    if (selectedProject) {
      setAutomatedStatus(selectedProject.automatic_status);
      dispatch(
        getUserTeams({
          enqueueSnackbar,
          query: {
            items: 1000,
          },
        })
      );
    }

    return () => null;
  }, [selectedProject]);

  const handleCancelClick = () => {
    handleOpenModal();
  };

  const handleVisibilityChange = ({ val }) => {
    setVisibility(val);
    val === "team"
      ? setSelectedTeam(selectedProject?.team_id)
      : setSelectedTeam(null);
  };

  const handlePlantChange = (value) => {
    const selected = value[0] || value.obj[0];

    if (selected.id === selectedPlant.id) {
      return;
    }

    setSelectedPlant(selected);
  };

  const handleAreaChange = (value) => {
    setSelectedPlant({
      ...selectedPlant,
      selectedAreas: value,
    });
  };

  const handleDiseasesSelect = (evt) => {
    const getIds = evt.map((elem) => elem.disease_id);
    setSelectedDiseases(getIds);
  };

  const handleSelectStatus = (val, changeEndDate = false) => {
    // if (val?.val === selectedProject.status) {
      // return;
    // }

    if (["open", "at_risk", "off_track"].includes(val.val)) {
      setAutomatedStatus(true);
      setStatus(val);
    }

    setShowComponent(false);
    setStatus(val);

    if (val.val === "closed") {
      setAutomatedStatus(false);
      handleChangeEndDate(format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
    }

    if (val.val === "on_hold") {
      setAutomatedStatus(false);
      setStatus(val);
    }

    if (val.val !== "closed") {
      handleChangeEndDate("");
    }

    if (status?.val === "open" && val.val === "closed") {
      handleChangeEndDate(format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
    }

    const timeout = setTimeout(() => {
      setShowComponent(true);
      clearTimeout(timeout);
    });
  };

  const handleSelectCountry = (val) => {
    setCountry(val);
  };

  const handleSubmit = async () => {
    const disease_ids = isPatientOrg ? selectedDiseases : [];

    if (!name?.length) {
      setError(name?.length === 0);
      return;
    }

    if (!priority) {
      setPriorityError(true);
      return;
    }

    if (!team_id?.length && visibility === "team") {
      setErrorTeamId(!team_id || team_id?.length === 0);
      return;
    }

    if (selectedCouncil?.name === "Ford" && selectedPlant?.id) {
      const payload = {
        data: {
          resource_id: selectedProject.id,
          resource_type: "Project",
          plant_id: selectedPlant.id,
          area_ids:
            selectedPlant?.selectedAreas?.map((area) => area.area_id) || [],
        },
        type: "Project",
        response: {
          plant: {
            id: selectedPlant.id,
            attributes: {
              name: selectedPlant.name,
            },
          },
          areas:
            selectedPlant?.selectedAreas?.map((area) => ({
              id: area.area_id,
              attributes: {
                name: area.area_name,
              },
            })) || [],
        },
        cb: () => {
          popup.hide();
        },
        enqueueSnackbar,
      };

      dispatch(updatePlant(payload));
    }

    const payload = {
      ...fordDates,
      userId: session.id,
      projectId: selectedProject.id,
      councilId: selectedCouncil.id,
      name,
      description: goal,
      visibility,
      team_id: selectedTeam,
      industrys: [...new Set(selectedIndustries)] || [],
      topics: isPatientOrg ? [] : [...new Set(tags)],
      disease_ids,
      members: [],
      companies: [],
      rating_template_id: selectedRatingsTemplate?.rating_template_id,
      project_company_rating_template_id:
        selectedCompanyTemplate?.rating_template_id,
      status: status.val,
      country: country.val,
      startDate,
      endDate,
      project_members: tempMembers,
      enqueueSnackbar,
      automatic_status: automatedStatus,
      priority,
      next_steps: nextSteps,
      next_steps_created_at: new Date(),
      next_steps_created_by_id: session.id,
      rating_enabled: enableProjectRatings,
      stage_id: stage?.id,
    };

    await dispatch(projectPut(payload));
    await dispatch(projectGet({ id: selectedProject.id, enqueueSnackbar }));

    handleOpenModal();
  };

  const handleSelectRatingsTemplate = (e) => {
    setSelectedRatingsTemplate(e.obj[0]);
  };

  const handleSelectCompanyRatingsTemplate = (e) => {
    setSelectedCompanyTemplate(e.obj[0]);
  };

  useEffect(() => {
    if (session && selectedCouncil?.id) {
      if (selectedCouncil?.name === "GSK") {
        dispatch(
          patientOrganizationGetDiseases({
            filter: true,
            enqueueSnackbar,
          })
        );
      }

      dispatch(ratingsTemplateGetAttempt({ enqueueSnackbar }));

      dispatch(
        companyGetFilters({
          userId: session.id,
          councilId: selectedCouncil?.id,
          enqueueSnackbar,
        })
      );
      dispatch(
        companyGetTopics({
          userId: session.id,
          councilId: selectedCouncil?.id,
          enqueueSnackbar,
        })
      );
    }
  }, [session, selectedCouncil]);

  useEffect(() => {
    if (diseases) {
      const newDiseases = diseases.reduce((acc, elem) => {
        const child = elem.children_diseases.map((e) => ({
          disease_id: e.id,
          name: e.name,
        }));
        return [
          ...acc,
          {
            disease_id: elem.id,
            name: elem.name,
          },
          ...child,
        ];
      }, []);
      setFlattenDisease(newDiseases);
    }
  }, [diseases]);

  useEffect(() => {
    if (selectedProject?.team?.team_id) {
      setSelectedTeamOption([
        {
          label: selectedProject.team.name,
          value: selectedProject.team.team_id,
        },
      ]);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (ratingTemplates[0] && selectedProject) {
      setSelectedRatingsTemplate(
        ratingTemplates?.find(
          (el) => selectedProject?.rating_template_id === el.rating_template_id
        )
      );
      setSelectedCompanyTemplate(
        ratingTemplates?.find(
          (el) =>
            selectedProject?.project_company_rating_template_id ===
            el.rating_template_id
        )
      );
    }
  }, [ratingTemplates, selectedProject]);

  useEffect(() => {
    if (userTeams.length > 0 && optionVisibility.length === 2) {
      setOptionVisibility([
        ...optionVisibility,
        {
          text: "Team (visible to your team)",
          value: "team",
        },
      ]);
    }
  }, [userTeams]);

  const handleViewTemplate = (source) => {
    popup.show({
      title: source.name,
      height: "300",
      width: "1000",
      component: (
        <RatingTemplateModal
          selectedTemplateCategories={source?.rating_categories}
        />
      ),
    });
  };

  const handleTagRemove = (updatedArr, childKey) => {
    if (childKey.includes("topic")) {
      setTags(updatedArr.map((el) => el.id));
    }
    if (childKey.includes("industri")) {
      setSelectedIndustries(updatedArr.map((el) => el.id));
    }
  };

  const handleTagSelect = (topicsId) => {
    setTags([...tags, topicsId]);
  };

  const handleIndustrySelect = (val) => {
    setSelectedIndustries((prev) => [...prev, val]);
  };

  const getPlantsAsOptions = useMemo(() => {
    return plantsAndAreas.map((plant) => ({
      id: plant.id,
      name: plant.attributes.name,
      areas: plant.attributes.areas,
    }));
  }, [plantsAndAreas]);

  const enableProjectRatingsCallBack = () => {
    setEnableProjectRatings(!enableProjectRatings);
  };

  const isFordSupplyChainAutomation = useMemo(() => {
    return (
      selectedCouncil?.name === "Ford" &&
      selectedProject?.stage_template?.name === "Supply Chain Automation"
    );
  }, [selectedCouncil?.name, selectedProject?.stage_template?.name]);

  const isNot3DPrintingTemplate = useMemo(() => {
    if (selectedCouncil?.name !== "Ford") return true;

    return (
      selectedProject?.stage_template?.name !== "3D Printing Replication" &&
      selectedProject?.stage_template?.name !== "3D Printing Savings"
    );
  }, [selectedProject?.stage_template, selectedCouncil?.name]);

  return (
    <div className={styles.detailsWrp}>
      <div className={styles.formContainer}>
        <div className="row">
          <div className="col-12">
            <Label>Project name</Label>
            <textarea
              name="name"
              defaultValue={selectedProject?.name}
              placeholder=""
              className={classNames(styles.textAreaBox, styles.textBox)}
              onChange={(e) => {
                handleChangeName(e.target.value);
                setError(e?.target?.value?.length === 0);
              }}
            />
            {error && (
              <span className={styles.errorMsg}>Name is required </span>
            )}
          </div>
          <div className="col-12">
            <Label>{isFordSupplyChainAutomation ? 'Elevator Pitch' : 'Goal / Description'}</Label>
            <RichEditor
              initialValue={goal}
              updateValue={(e) => handleChangeGoal(e)}
            />
          </div>
          {nextSteps?.length ? (
            <div className="col-12">
              <Label>Next Steps</Label>
              <RichEditor
                initialValue={nextSteps}
                updateValue={(e) => setNextSteps(e)}
              />
            </div>
          ) : null}
          {selectedCouncil?.name === "Ford" && plantsAndAreas.length > 0 && !isFordSupplyChainAutomation && isNot3DPrintingTemplate ? (
            <div className="col-12 mb-3 mt-3">
              <div className="d-flex">
                <div className="mr-1 w-50">
                  <Label>Prototype Date</Label>
                  <DatePiker
                    disabled={!isAdmin}
                    value={fordDates.prototype_date}
                    name="prototype_date"
                    formProps={{ values: fordDates }}
                    onChange={(e) => {
                      setFordDates({
                        ...fordDates,
                        prototype_date: format(new Date(e), "yyyy-MM-dd'T'HH:mm"),
                      });
                    }}
                    className={styles.textBox}
                    withClear
                  />
                </div>
                <div className="mr-1 w-50">
                  <Label>Implementation Date</Label>
                  <DatePiker
                    disabled={!isAdmin}
                    value={fordDates.implementation_date}
                    name="implementation_date"
                    formProps={{ values: fordDates }}
                    onChange={(e) => {
                      setFordDates({
                        ...fordDates,
                        implementation_date: format(new Date(e), "yyyy-MM-dd'T'HH:mm"),
                      });
                    }}
                    className={styles.textBox}
                    withClear
                  />
                </div>
              </div>
              <div className="d-flex align-items-end mt-3">
                <div className="mr-1 w-50">
                  <Label>Expected Job 1 Date</Label>
                  <DatePiker
                    disabled={!isAdmin}
                    value={fordDates.expected_job_1_date}
                    name="expected_job_1_date"
                    formProps={{ values: fordDates }}
                    onChange={(e) => {
                      setFordDates({
                        ...fordDates,
                        expected_job_1_date: format(new Date(e), "yyyy-MM-dd'T'HH:mm"),
                      });
                    }}
                    className={styles.textBox}
                    withClear
                  />
                </div>
                <div className="ml-1 w-50">
                  <Label>Job 1 Date (Prototype)</Label>
                  <DatePiker
                    disabled={!isAdmin}
                    value={fordDates.prototype_job_1_date}
                    name="prototype_job_1_date"
                    formProps={{ values: fordDates }}
                    withClear
                    onChange={(e) => {
                      setFordDates({
                        ...fordDates,
                        prototype_job_1_date: format(new Date(e), "yyyy-MM-dd'T'HH:mm"),
                      });
                    }}
                    className={styles.textBox}
                  />
                </div>
              </div>
              <div className="d-flex align-items-end mt-3">
                <div className="w-100">
                  <Label>Expected Job 1 Date (Proposal for Prototype)</Label>
                  <DatePiker
                    disabled={!isAdmin}
                    value={fordDates.proposal_for_prototype_expected_job_1_date}
                    name="proposal_for_prototype_expected_job_1_date"
                    formProps={{ values: fordDates }}
                    withClear
                    onChange={(e) => {
                      setFordDates({
                        ...fordDates,
                        proposal_for_prototype_expected_job_1_date: format(new Date(e), "yyyy-MM-dd'T'HH:mm"),
                      });
                    }}
                    className={styles.textBox}
                  />
                </div>
              </div>
              <Label>Plant</Label>
              <Select
                multi={false}
                closeOnSelect
                values={[selectedPlant]}
                onChange={handlePlantChange}
                options={getPlantsAsOptions}
                labelField="name"
                valueField="id"
                placeholder="Plants"
                searchBy="name"
              />
              {selectedPlant?.areas?.length > 0 ? (
                <div className="mt-3">
                  <Label>Areas</Label>
                  <Select
                    multi={false}
                    closeOnSelect
                    values={selectedPlant.selectedAreas || []}
                    onChange={handleAreaChange}
                    options={selectedPlant.areas}
                    labelField="area_name"
                    valueField="area_id"
                    placeholder="Areas"
                    searchBy="area_name"
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="col-12">
            <Label>Priority</Label>
            <DropSelect
              SelecedValues={selectedProject && selectedProject.priority}
              onChange={(v) => setPriority(v.val)}
              placeholder="Priority"
              labelField="text"
              valueField="value"
              searchBy="text"
              options={priorityOptions}
              classNames={styles.visibilitySelect}
            />
            {priorityError && (
              <span className={styles.errorMsg}>Priority is required </span>
            )}
          </div>
          <div className="col-12">
            <Label>Access</Label>
            <DropSelect
              SelecedValues={selectedProject && selectedProject.visibility}
              onChange={handleVisibilityChange}
              placeholder="Access"
              labelField="text"
              valueField="value"
              searchBy="text"
              options={optionVisibility}
              classNames={styles.visibilitySelect}
            />
          </div>

          {visibility === "team" && (
            <div className="col-12">
              <Label>Select a team</Label>
              <Select
                name="team_id"
                onChange={([selected]) => {
                  setSelectedTeam(selected?.value);
                  setSelectedTeamOption([selected]);
                  handleChangeTeamId(selected?.value);
                  setErrorTeamId(selected?.value?.length === 0);
                }}
                values={selectedTeamOption}
                options={userTeams.map(({ id: value, name: label }) => ({
                  label,
                  value,
                }))}
              />
              {errorTeamId && (
                <span className={styles.errorMsg}>
                  {" "}
                  Team selection is required{" "}
                </span>
              )}
            </div>
          )}

          <div className="col-12">
            <Label>{isPatientOrg ? "Disease" : "Technologies"}</Label>
            {isPatientOrg && flattenDiseases && (
              <Select
                multi
                closeOnSelect
                values={selectedProject.diseases}
                className="mb-3"
                onChange={handleDiseasesSelect}
                options={flattenDiseases}
                labelField="name"
                valueField="disease_id"
                placeholder="Add diseases"
                searchBy="name"
              />
            )}
            {!isPatientOrg && topics?.length ? (
              <>
                <DropDownNestedElements
                  tags={topics}
                  selectedTags={tags}
                  childKey="children_topics"
                  tagKeyName="id"
                  parentKeyName="parent_resource_topic_id"
                  handleTagSelect={handleTagSelect}
                  label="Select technologies"
                  handleTagRemove={handleTagRemove}
                />
              </>
            ) : null}
          </div>

          <div className="col-12">
            <Label>Industries</Label>
            <DropDownNestedElements
              tags={industries}
              selectedTags={selectedIndustries}
              childKey="children_industries"
              tagKeyName="id"
              parentKeyName="parent_resource_industry_id"
              handleTagSelect={handleIndustrySelect}
              label="Select industries"
              handleTagRemove={handleTagRemove}
            />
          </div>

          {selectedProject?.stage_template?.patient_organization && (
            <div className="col-12">
              <Label>Country</Label>
              <DropSelect
                SelecedValues={
                  selectedProject && selectedProject.country
                    ? gskOptionCountries.find(
                        (el) => selectedProject.country === el.name
                      )?.name
                    : null
                }
                onChange={handleSelectCountry}
                labelField="name"
                valueField="id"
                options={gskOptionCountries.sort(sortCountries)}
              />
            </div>
          )}
          {!selectedProject?.stage_template.patient_organization && (
            <div className="col-12">
              <div className="d-flex align-items-center mb-3">
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={enableProjectRatings}
                  onChange={enableProjectRatingsCallBack}
                />
                <span
                  className="cursor-pointer"
                  onClick={enableProjectRatingsCallBack}
                >
                  Enable Project Ratings
                </span>
              </div>
              {enableProjectRatings && (
                <div>
                  <Label>Project Ratings Template</Label>
                  <DropSelect
                    SelecedValues={selectedRatingsTemplate?.name}
                    onChange={handleSelectRatingsTemplate}
                    placeholder="Template"
                    labelField="name"
                    valueField="name"
                    options={ratingTemplates.filter(
                      (template) => template.template_type === "project"
                    )}
                  />
                  {selectedRatingsTemplate?.name ? (
                    <div className={styles.viewTemplateLink}>
                      <span
                        onClick={() => {
                          handleViewTemplate(selectedRatingsTemplate);
                        }}
                      >
                        View Template
                      </span>
                    </div>
                  ) : null}
                </div>
              )}
              <Label>Project (Company) Ratings Template</Label>
              <DropSelect
                SelecedValues={selectedCompanyTemplate?.name}
                onChange={handleSelectCompanyRatingsTemplate}
                placeholder="Template"
                labelField="name"
                valueField="name"
                options={ratingTemplates.filter(
                  (template) => template.template_type === "project_company"
                )}
              />
              {selectedCompanyTemplate?.name ? (
                <div className={styles.viewTemplateLink}>
                  <span
                    onClick={() => {
                      handleViewTemplate(selectedCompanyTemplate);
                    }}
                  >
                    View Template
                  </span>
                </div>
              ) : null}
            </div>
          )}
          <div className="col-12">
            <Label>Status</Label>
            <DropSelect
              SelecedValues={
                status?.val ||
                (selectedProject && selectedProject.status
                  ? optionStatus.find(
                      (el) => selectedProject.status === el.value
                    )?.value
                  : null)
              }
              onChange={handleSelectStatus}
              labelField="text"
              valueField="value"
              options={
                automatedStatus
                  ? optionStatus.filter(
                      (el) =>
                        el.value === selectedProject.status ||
                        el.value === "on_track" ||
                        el.value === "on_hold" ||
                        el.value === "closed"
                    )
                  : optionStatus
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex flex-grow-1 align-items-center justify-content-end mt-2">
            <span
              role="button"
              className="mr-2"
              onClick={() => setAutomatedStatus(false)}
            >
              Manual
            </span>
            <label className={styles.switch}>
              <input
                type="checkbox"
                onChange={() => setAutomatedStatus(!automatedStatus)}
                checked={automatedStatus}
              />
              <span className={styles.slider} />
            </label>
            <span
              role="button"
              className="ml-2"
              onClick={() => setAutomatedStatus(true)}
            >
              Automated
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Label>Start date</Label>
            <DatePiker
              value={startDate}
              name="startDate"
              formProps={{ values: { startDate } }}
              onChange={(e) => {
                handleChangeStartDate(e);
              }}
              className={styles.textBox}
              withClear={false}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {showComponent && (
              <>
                <Label>End date</Label>
                <DatePiker
                  value={endDate}
                  name="endDate"
                  formProps={{ values: { endDate } }}
                  onChange={(e) => {
                    if (isBefore(new Date(e), new Date())) {
                      handleSelectStatus({
                        val: "closed",
                        obj: [
                          {
                            text: "Closed",
                            value: "closed",
                          },
                        ],
                      });
                    }
                    handleChangeEndDate(e);
                  }}
                  className={styles.textBox}
                />
                <span
                  className={styles.clearButton}
                  onClick={() => {
                    handleChangeEndDate("");
                    setStatus("open");
                    handleSelectStatus({
                      val: "open",
                      obj: [
                        {
                          text: "On Track",
                          value: "open",
                        },
                      ],
                    });
                  }}
                >
                  clear
                </span>
              </>
            )}
          </div>
          <div className="col-12 mt-3">
            <ProjectStage
              selectedProject={selectedProject}
              editProjectInstance
              updateStage={setStage}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            className={styles.cancelButton}
            btn={BtnType.FRAME_LESS}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>

          <>
            <Button type="submit" btn={BtnType.REGULAR} onClick={handleSubmit}>
              Update Project
            </Button>
          </>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DetailsComponent);

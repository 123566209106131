import React, { useState } from "react";

import styles from "modules/reporting/customReportsStyles.module.scss";
import DialogTitle from "@mui/material/DialogTitle";
import { customStyles } from "modules/reporting/components/generateReport/constants";
import DialogContent from "@mui/material/DialogContent";
import { Button } from "modules/primitives";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const CustomFieldsTagsComponent = ({ rowDetails, config, renderWithoutTagStyles }) => {
  const [open, setOpen] = useState(false);

  const hasShowMore = rowDetails[config.id]?.length > 2;

  const handleShowMore = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  if (!rowDetails[config.id]?.length) {
    return '-';
  }

  return (
    <div className="d-flex flex-wrap">
      {
        rowDetails[config.id]
          .slice(0, hasShowMore ? 2 : rowDetails[config.id]?.length)
          .map(t => (
            <span className={renderWithoutTagStyles ? '' : styles.tagStyles} key={t?.name || t}>
              {t?.name || t}{renderWithoutTagStyles ? ',' : ''}{renderWithoutTagStyles ? <span>&nbsp;</span> : ''}
            </span>
          ))
      }
      {
        hasShowMore ? (
          <span
            onClick={handleShowMore}
            className={`${styles.showMoreData} d-block mt-3`}
          >+ {rowDetails[config.id].length - 2}</span>
        ) : null
      }
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle style={customStyles.title}>{rowDetails.name} - {config.name}</DialogTitle>
        <DialogContent>
          <div className="d-flex flex-wrap">
            {
              rowDetails[config.id]
                .map((t, idx) => (
                  <span
                    className={renderWithoutTagStyles ? '' : styles.tagStyles}
                    key={`modal_${t?.name || t}_${idx}`}
                  >
                    {t?.name || t}{renderWithoutTagStyles ? ',' : ''}{renderWithoutTagStyles ? <span>&nbsp;</span> : ''}
                  </span>
                ))
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(CustomFieldsTagsComponent);

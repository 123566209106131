import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { useSliderField } from "common/hooks";
import { useSelector } from "react-redux";
import { isCreator } from "common/checkers/isCreator";
import { MoreOptions, Loading, Button, BtnType } from "modules/primitives";
import { Link } from "react-router-dom";
import useS3FileUpload from "common/hooks/s3FileUplodar.hook";
import { useSnackbar } from "notistack";
import DragAndDrop from "common/components/dragAndDrop";
import { allowedExtensions } from "modules/events/viewEvent/viewEvent.constant";
import { ACTIVITY_OPTIONS } from "../../../constants";
import styles from "./styles.module.scss";
import LinearProgressWithLabel from "../../linearProgressWithLabel";

const SlidesComponent = ({
  item,
  handleSubmitDetail,
  handleEditClick,
  blockFunctionality,
  handleDeleteClick,
}) => {
  const {
    authReducer: { session },
    companiesReducer: {
      companyReducer: { customFieldLoadingId, selectedCompany },
    },
    councilReducer: { selectedCouncil },
    patientOrganizationsReducer: {
      patientOrganizationReducer: {
        selectedPatientOrganization,
        customFieldLoadingIdOrg,
      },
    },
  } = useSelector((state) => state);
  const [loadingId, setLoadingId] = useState("");
  const [fieldId, setFieldId] = useState("");
  const sliderField = useSliderField(item);
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);
  const [uploadValues, setUploadValues] = useState([]);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const { fileUpload } = useS3FileUpload();
  const [newDocuments, setNewDocuments] = useState([]);
  const [documents, setDocuments] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [docLoading, setDocLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [changes, setChanges] = useState(false);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    if (item) {
      if (isCreator(item?.creator?.user_id)) {
        setHasAccessToEdit(true);
      }
    }
  }, [item]);

  useEffect(() => {
    if (customFieldLoadingId || customFieldLoadingIdOrg) {
      if (selectedPatientOrganization.id) {
        setLoadingId(customFieldLoadingIdOrg);
      } else {
        setLoadingId(customFieldLoadingId);
      }
    }
  }, [customFieldLoadingId, customFieldLoadingIdOrg]);

  useEffect(() => {
    if (selectedPatientOrganization.id) {
      setFieldId(item.patient_organization_custom_field_id);
    } else {
      setFieldId(item.company_custom_field);
    }
  }, [item]);

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }

    if (value === 1) {
      handleDeleteClick(item);
    }
  };

  const getFilename = (url) => {
    if (url) {
      return url.split("/").pop();
    }
    return "";
  };

  const saveModifications = () => {
    const docs = [
      ...item?.documents,
      ...newDocuments.map((slide) => ({
        added_by_id: session.id,
        name: getFilename(slide),
        uri: slide,
        council_id: selectedCouncil.id,
        document_resource_type: "CompanyCustomField",
        document_resource_id: item?.company_custom_field,
      })),
    ];
    const data = { ...item, documents_attributes: docs };
    handleSubmitDetail(data, item);
  };

  const getExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  useEffect(() => {
    if (item.field_values) {
      setSubmitting(false);
    }
  }, [item.field_values]);

  useEffect(() => {
    if (newDocuments.length) saveModifications();
  }, [newDocuments]);

  useEffect(() => {
    if (item.documents) {
      setDocuments(item.documents);
    }
  }, [item.documents]);

  useEffect(() => {
    setSlides(item.documents?.map((doc) => doc.uri));
  }, [item]);

  const uploadHandler = async (e, action) => {
    if (forbiddenFiles.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }
    setDocLoading(true);
    const fileList = [];
    let dismissedFiles = 0;

    setUploadValues((items) => {
      const currentFiles = [...items];
      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name,
      }));
      return [...currentFiles, ...newFiles];
    });

    files.forEach(async (file, index, array) => {
      const isLastIndex = Boolean(index === array?.length - 1);
      let fileCopy = file;
      // This regular expression pattern that matches any character that is not in the range of a-z, A-Z, or 0-9.
      const regex = /[^a-zA-Z0-9]/g;

      if (regex.test(file.name)) {
        fileCopy = new File([file], file.name.replace(/[^a-zA-Z0-9. ]/g, ""), {
          type: file.type,
        });
      }

      if (
        fileCopy.size <= 500000000 &&
        allowedExtensions.indexOf(getExtension(fileCopy.name)) !== -1
      ) {
        const pathname = `srm/${selectedCouncil.id}/${
          selectedPatientOrganization?.id
            ? "patient_organizations"
            : "companies"
        }/${selectedCompany.id}/`;
        await fileUpload(
          fileCopy,
          pathname,
          setUploadValues,
          fileCopy.name
        ).then((res) => {
          if (res && res.location) {
            if (slides.indexOf(res.location) === -1) {
              fileList.push(res.location);
              isLastIndex && setDocLoading(false);
              if (fileList.length === files.length - dismissedFiles) {
                setNewDocuments([...fileList]);
              }
            } else {
              enqueueSnackbar("This document was already added.", {
                variant: "error",
              });
              isLastIndex && setDocLoading(false);
              dismissedFiles++;
              setForbiddenFiles((prevFiles) => {
                const localFiles = [...prevFiles];
                localFiles.push({
                  name: fileCopy.name,
                  error: "Document already added.",
                });
                return localFiles;
              });
              setUploadValues((prevState) => {
                const currentProgress = [...prevState];
                const index = currentProgress
                  .map((item) => item.name)
                  .lastIndexOf(fileCopy.name);
                currentProgress[index] = {
                  progress: "NOT_ALLOWED",
                  name: fileCopy.name,
                };
                return currentProgress;
              });
            }
          }
        });
      } else {
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === fileCopy.name
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: fileCopy.name,
          };
          return currentProgress;
        });
        isLastIndex && setDocLoading(false);
        if (fileCopy.size > 500000000) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: fileCopy.name,
              error: "Your file is too large. File size limit: 100MB",
            });
            return localFiles;
          });
          dismissedFiles++;
          enqueueSnackbar(
            "We could not upload your image because your file size is too big. Please make sure the file is less than 20 MB",
            {
              variant: "error",
            }
          );
        }
        if (allowedExtensions.indexOf(getExtension(fileCopy.name)) === -1) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: fileCopy.name,
              error:
                "Extenstion is not allowed, the accepted file formats are: pdf, ppt, pptx, pps.",
            });
            return localFiles;
          });
          isLastIndex && setDocLoading(false);
          dismissedFiles++;
          enqueueSnackbar(
            "Extenstion is not allowed, the accepted file formats are: pdf, ppt, pptx, pps.",
            {
              variant: "error",
            }
          );
        }
      }
    });

    e.target.value = "";
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{item?.field_name || "Slides"}</h5>
          {item?.field_placeholder?.length ? (
            <span className="text-muted small">{item.field_placeholder}</span>
          ) : null}
        </div>
        <div className={styles.rightWrp}>
          <div className={styles.colMore}>
            {!blockFunctionality && hasAccessToEdit ? (
              <MoreOptions
                options={ACTIVITY_OPTIONS}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            ) : null}
          </div>
        </div>
      </div>
      {item.from_claimed_profile && (
        <span className="d-block small font-weight-bold mb-3">
          Company Response
        </span>
      )}
      <div>
        {changes ? (
          <div>
            <Button
              btn={BtnType.FRAME_LESS}
              className={
                docLoading ? styles.disabledSaveButton : styles.saveButton
              }
              onClick={saveModifications}
              disabled={docLoading}
            >
              Save Changes
            </Button>
          </div>
        ) : null}

        <div>
          {" "}
          {uploadValues?.length
            ? uploadValues.map(
                (bar, index) =>
                  bar &&
                  bar?.progress !== "NOT_ALLOWED" && (
                    <div className={styles.loaderContainer}>
                      <div className={styles.loaderNamingWrp}>
                        {" "}
                        <div className={styles.fileName}>
                          {uploadValues[index]?.name}
                        </div>
                        <LinearProgressWithLabel
                          mode={
                            uploadValues[index]?.progress === "FAILED" &&
                            "error"
                          }
                          value={
                            uploadValues[index]?.progress === "FAILED"
                              ? 100
                              : uploadValues[index]?.progress || 0
                          }
                        />
                      </div>
                    </div>
                  )
              )
            : null}
        </div>

        <DragAndDrop
          uploadHandler={uploadHandler}
          loading={docLoading}
          customText="drag and drop slides here"
          unicumKey={item?.custom_field_template_id}
        />

        {forbiddenFiles?.length ? (
          <div className={styles.forbiddenfileContainer}>
            Files that weren't allowed:{" "}
            {forbiddenFiles.map((file) => (
              <span className={styles.forbiddenfileName}>
                {`${file.name}: `}
                <span className={styles.errorText}> {file.error}</span>
              </span>
            ))}
          </div>
        ) : null}
      </div>

      {loadingId === fieldId ? <Loading /> : sliderField.displayField()}

      <div className={styles.infoWrp}>
        <div className={styles.dataRow}>
          <div className={styles.label}>
            {item.from_submission
              ? "Submitted by"
              : item.updated_at !== item.created_at
              ? "Updated by"
              : "Created by"}
          </div>
          <div className={styles.value}>
            {item.from_submission ? (
              <span>{item?.creator?.full_name || "-"}</span>
            ) : (
              <Link to={`/people/internal/profile/${item.user_id}`}>
                {item && item.creator && item.creator.full_name}
                {item.from_claimed_profile && (
                  <span>, {selectedCompany?.name}</span>
                )}
              </Link>
            )}
          </div>
        </div>
        {item.from_claimed_profile && (
          <div className="d-flex align-items-center mr-5">
            <div className={styles.companyAccess}>
              <span>&#x2713; Access</span>
            </div>
          </div>
        )}
        <div className={styles.date}>
          {item.updated_at !== item.created_at
            ? format(new Date(item.updated_at), "eeee, MMM do, yyyy hh:mm a")
            : format(new Date(item.created_at), "eeee, MMM do, yyyy hh:mm a")}
        </div>
        {item.from_submission ? (
          <div
            className={`d-flex flex-grow-1 align-items-center justify-content-center ${styles.companyResponse}`}
          >
            Company Response
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default SlidesComponent;

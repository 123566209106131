import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import adminFuseHoc from "common/HOC/adminFuseHoc";
import styles from "./styles.module.scss";
import {
  formInitialValues,
  formValidation,
} from "../adminApplicantForms.constants";
import {
  StepOneComponent,
  StepTwoComponent,
  StepThreeComponent,
  StepFourComponent,
} from "../formSteps";

import { BtnType, Button, Loading } from "../../../../primitives";
import {
  createApplicantForm,
  getFormCustomFields,
} from "../adminApplicantForms.action";

const steps = [
  {
    title: "Create Form",
    component: StepOneComponent,
  },
  {
    title: "Auto populate Fields",
    component: StepTwoComponent,
  },
  {
    title: "Additional Fields",
    component: StepThreeComponent,
  },
  {
    title: "",
    component: StepFourComponent,
  },
];

const AddForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const formRef = useRef();
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [currentStep, setCurrentStep] = useState(0);
  const [createdForm, setCreatedForm] = useState();
  const currentValidationSchema = formValidation[currentStep];

  const isLastStep = currentStep === steps.length - 1;
  const isFirstStep = currentStep === 0;
  const isSubmitStep = currentStep === 2;

  useEffect(() => {
    dispatch(
      getFormCustomFields({
        enqueueSnackbar,
        query: {
          items: 10000,
        },
      }),
    );
  }, []);

  const handleSubmit = async (values, actions) => {
    if (isSubmitStep) {
      const callback = (status, form) => {
        if (status === "success") {
          enqueueSnackbar("Form created with success", {
            variant: "success",
          });
          setCreatedForm(form);
          setCurrentStep(currentStep + 1);
        } else {
          enqueueSnackbar("Something went wrong.", {
            variant: "error",
          });
        }
      };

      const data = {
        council_id: selectedCouncil.id,
        form_title: values.form_title,
        form_instruction_text: values.form_instruction_text,
        add_applicant_to_company_database:
          values.add_applicant_to_company_database,
        sync_with_crunchbase: values.sync_with_crunchbase,
        selected_available_field_ids: [
          ...values.auto_populate_fields,
          ...values.additional_fields,
        ],
        required_field_ids: values.required_field_ids,
        documents_attributes: values.documents_attributes || []
      };

      dispatch(createApplicantForm({ data, callback }));
    } else {
      setCurrentStep(currentStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleBackClick = () => {
    if (!isFirstStep) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const renderStepContent = (step, formikprops) => {
    if (steps[step]) {
      const CurrentStepComponent = steps[step].component;
      if (isLastStep) {
        if (!createdForm) {
          return <Loading />;
        }
        return (
          <CurrentStepComponent
            formikprops={formikprops}
            action="create"
            form={createdForm}
          />
        );
      }

      return <CurrentStepComponent formikprops={formikprops} />;
    }
    throw new Error("Component not found");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrp} />
      <div className={styles.container}>
        <div className="col-6">
          <div className="row">
            <div className="col-12">
              <div className={styles.captionStyle}>
                {`Step ${currentStep + 1} of ${steps.length}`}
              </div>
              <h4 className="bold">{steps[currentStep].title}</h4>
            </div>
            <div className="col-12">
              <Formik
                innerRef={formRef}
                initialValues={formInitialValues}
                validationSchema={currentValidationSchema}
                onSubmit={handleSubmit}
              >
                {(formikprops) => (
                  <Form id="addApplicantForm">
                    {renderStepContent(currentStep, formikprops)}
                  </Form>
                )}
              </Formik>
            </div>
            {!isLastStep && (
              <div className="mb-5 col-12">
                <div className="row justify-content-between">
                  <div className="d-flex justify-content-start">
                    {!isFirstStep && (
                      <Button
                        btn={BtnType.FRAME_LESS}
                        type="submit"
                        onClick={handleBackClick}
                      >
                        Back
                      </Button>
                    )}
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      btn={BtnType.REGULAR}
                      type="submit"
                      onClick={handleNextClick}
                    >
                      {isSubmitStep ? "Save" : "Next"}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(adminFuseHoc(AddForm));

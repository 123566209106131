import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { DropSelect, Label } from "modules/primitives";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { httpGet } from "../../../httpCall";

const SimilarProjects = ({ formikprops, isAdminWizardInstance }) => {
  const [projects, setProjects] = useState([]);

  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (isAdminWizardInstance) return;

    if (selectedCouncil?.id) {
      httpGet({
        call: 'projects/related_projects/dropdown'
      }).subscribe(res => {
        const data = res.response || [];
        setProjects(data);
      });
    }
  }, [selectedCouncil]);

  return (
    <div className="d-flex flex-column w-100 mb-3">
      {
        !projects ? (
          <LoadingComponent customText="Getting Projects..." />
        ) : (
          <>
            <Label>Similar Projects</Label>
            <DropSelect
              multi
              onChange={(value) => {
                formikprops.setFieldValue("similar_projects", value?.obj || []);
              }}
              placeholder="Select Original Project"
              labelField="name"
              valueField="id"
              searchBy="name"
              options={projects || []}
              values={formikprops.values?.similar_projects || []}
              dropdownPosition="auto"
            />
            <div className="caption small text-danger">
              {formikprops.touched['similar_projects'] && formikprops.errors['similar_projects']
                ? formikprops.errors['similar_projects']
                : ""}
            </div>
          </>
        )
      }
    </div>
  );
};

export default React.memo(SimilarProjects);

import * as Yup from "yup";

export const initModel = {
  first: "",
  last: "",
  email: "",
  title: "",
  permission: 2,
  teamId: [],
  msftQuery: "",
  department: "",
};

export const permission = [
  { val: 1, text: "Administrator", role: "council_administrator" },
  { val: 4, text: "Company", role: "company" },
  { val: 2, text: "Standard", role: "standard" },
  { val: 3, text: "View-Only", role: "viewer" },
  // { val: 5, text: "Traction Admin", role: "ttp_administrator" },
  // { val: 'company', text: 'Company' },
];
export const validation = Yup.object().shape({
  first: Yup.string().required("First name is required"),
  last: Yup.string().required("Last name is required"),
  // email: Yup.string().required('Email is required').email('Invalid email address'),
  // title: Yup.string().required('Title is required'),
  email: Yup.string()
    .matches(/^\S*$/, "Email cannot include leading and trailing spaces")
    .min(1, "Email needs to be at least 1 char")
    .max(512, "Email cannot exceed 512 char")
    .required("Email is required"),
});

import React from "react";
import { Link } from "react-router-dom";

import styles from "./member.module.scss";
import { UserInlineTypes, UserInline } from "../../primitives";
import { Icon, lgSize } from "../../../common/icon";

const MemberComponent = ({ user, handleShow }) => (
  <div className={styles.wrapper}>
    <Link className={styles.linkWrapper} to={`/people/internal/profile/${user?.id}`}>
      <div className={styles.leftWrp}>
        <UserInline
          userId={user.id || user.user_id}
          first={user.first_name}
          last={user.last_name}
          avatar={user.avatar}
          local
          type={UserInlineTypes.WITH_NAME}
        />
        <div className={styles.title}>{user.title}</div>
        <div className={styles.company}>{user.organization}</div>
        {user.department && <div className={styles.company}>Department: {user.department}</div>}
      </div>
      <div className={styles.rightWrp}>
        <Icon
          {...lgSize}
          icon="icn-table-message"
          className={styles.messageIcon}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleShow({
              ...user,
              isPrivate: true,
              showNewModal: !user.discussion,
              prop: "privateDiscussions",
            });
          }}
        />
      </div>
    </Link>
  </div>
);

export default MemberComponent;

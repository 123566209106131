// export const APP_ACTION_SHOWNAVI = 'APP_ACTION_SHOWNAVI';
// export const appActionShowNavi =  payload =>({type:APP_ACTION_SHOWNAVI, payload});

export const APP_MODEL_SETTING_SET = "APP_MODEL_SETTING_SET";
export const appModelSettingSet = (payload) => ({
  type: APP_MODEL_SETTING_SET,
  payload,
});

export const APP_MODAL_MAKE_RERENDER = "APP_MODAL_MAKE_RERENDER";
export const appModalMakeRender = (payload) => ({
  type: APP_MODAL_MAKE_RERENDER,
  payload,
});

export const APP_SLIDER_SETTING_SET = "APP_SLIDER_SETTING_SET";
export const appSliderSettingSet = (payload) => ({
  type: APP_SLIDER_SETTING_SET,
  payload,
});

export const APP_SELECTED_SET = "APP_SELECTED_SET";
export const appSelectedSet = (payload) => ({
  type: APP_SELECTED_SET,
  payload,
});

export const APP_MOBILE_NAVI_SET = "APP_MOBILE_NAVI_SET";
export const appMobileNaviSet = (payload) => ({
  type: APP_MOBILE_NAVI_SET,
  payload,
});

export const APP_SET_CHARTS_DOWNLOAD_STATUS = "APP_SET_CHARTS_DOWNLOAD_STATUS";
export const appSetChartsDownloadStatus = (payload) => ({
  type: APP_SET_CHARTS_DOWNLOAD_STATUS,
  payload,
});

export const GET_RELATIONSHIP_STATUSES = "GET_RELATIONSHIP_STATUSES";
export const getRelationshipStatuses = (payload) => ({
  type: GET_RELATIONSHIP_STATUSES,
  payload,
});

export const GET_RELATIONSHIP_STATUSES_SUCCESS = "GET_RELATIONSHIP_STATUSES_SUCCESS";
export const getRelationshipStatusesSuccess = (payload) => ({
  type: GET_RELATIONSHIP_STATUSES_SUCCESS,
  payload,
});

export const HIGHLIGHT_ITEM_WITH_ID = "HIGHLIGHT_ITEM_WITH_ID";
export const highlightItemWithIdAction = (payload) => ({
  type: HIGHLIGHT_ITEM_WITH_ID,
  payload,
});

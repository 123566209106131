export const AIInsightsOptions = [
  {
    id: 'swot',
    name: 'Create a SWOT Analysis',
  },
  {
    id: 'trendReport',
    name: 'Create a Trend Report',
  },
  {
    id: 'companySnapshot',
    name: 'Create a Company Snapshot',
  },
];

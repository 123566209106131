import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LoadingComponent from "../../modules/primitives/loading/loading.component";

import FormGenerator from "./form-generator";
import FormGeneratorWrap from "./form-generator-wrp/form-generator-wrp";

const UrlFormGenerator = () => {
  const API_HOST = process.env.REACT_APP_URL;
  const { councilSlug, formSlug } = useParams();
  const history = useHistory();
  const [ideaSections, setIdeaSections] = useState([]);
  const [form, setForm] = useState();
  useEffect(() => {
    async function fetchData() {
      const res = await fetch(
        `${API_HOST}/api/v1/ideas_templates/idea-forms/${councilSlug}/${formSlug}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const data = await res.json();

      if (!data || !res.ok) {
        history.push("/submission-form/error");
        return;
      }

      setForm(data);
      for (const stepObj in data.idea_template) {
        const templateStepNr = Number(stepObj.split("_")[1]);
        if (templateStepNr && data?.idea_template[stepObj].length > 0) {
          setIdeaSections((prevArray) => [
            ...prevArray,
            ...data?.idea_template[stepObj],
          ]);
        }
      }
    }

    if (councilSlug && formSlug) {
      fetchData();
    }
  }, [councilSlug, formSlug]);
  useEffect(() => {
    setForm((prevState) => ({
      topics: prevState?.topics,
      industries: prevState?.industries,
      idea_template: {
        ...prevState?.idea_template,
        ideas_sections: ideaSections,
      },
    }));
  }, [ideaSections]);

  if (!form) {
    return "";
  }
  if (!form?.idea_template?.ideas_sections?.length) {
    return <LoadingComponent />;
  }
  return (
    <FormGeneratorWrap council={form.idea_template.council}>
      <FormGenerator form={form} />
    </FormGeneratorWrap>
  );
};

export default UrlFormGenerator;

import React, { createContext, useContext, useMemo } from "react";

import { useCompanyData } from "./useCompanyData";
import { useCompanySelect } from "modules/companies/companyMain/providers/useCompanySelect";
import { useCompanyPagination } from "modules/companies/companyMain/providers/useCompanyPagination";

const CompanyDataContext = createContext(null);

export const CompanyDataProvider = ({ children }) => {
  const companyData = useCompanyData();
  const companySelect = useCompanySelect();
  const companyPagination = useCompanyPagination();

  const value = useMemo(() => ({
    ...companyData,
    ...companySelect,
    ...companyPagination,
  }), [companyData, companySelect, companyPagination])

  return (
    <CompanyDataContext.Provider value={value}>
      {children}
    </CompanyDataContext.Provider>
  );
};

export const useCompanyContext = () => {
  const context = useContext(CompanyDataContext);

  if (!context) {
    throw new Error(
      "useCompanyContext must be used within a CompanyDataProvider"
    );
  }
  return context;
};

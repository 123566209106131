import { combineEpics, ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  COMPANY_UNFOLLOW_ATTEMPT,
  companyUnFollowSuccess,
} from "../actions/common.action";
import { apiStatus, errorHandler, httpDelete } from "../httpCall";

const epicCompanyUnFollow = (action$) => action$.pipe(
  ofType(COMPANY_UNFOLLOW_ATTEMPT),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpDelete({
    call: `follow_relationships/${
      data.follow_relationship_id || data.following_id
    }`,
  }).pipe(
    map((result) => {
      if (result.status === apiStatus.SUCCESS) {
        enqueueSnackbar(
          `You don't follow ${data.following?.name || data.name} anymore`,
          {
            variant: "success",
          },
        );
      }
      return companyUnFollowSuccess({ result, data, id: data.companyId });
    }),
    catchError((error) => errorHandler(error, enqueueSnackbar)),
  )),
);

const myCompanyEpic = combineEpics(epicCompanyUnFollow);

export default myCompanyEpic;

import * as Yup from "yup";

const initModel = {
  taskName: "",
  position: 0,
  description: "",
  stage_id: "",
  task_type: "organization",
  days_to_complete: 0,
  apply_only_to_new: true,
  apply_only_to_existing: false,
  approval_task_options: {
    review: false,
    approval: true,
    lockStages: true,
  },
  teams: [],
  isInvalidTask: false,
  notify_after_previous_completed: false,
};

const validation = Yup.object().shape({
  taskName: Yup.string().required("Task name is required"),
  position: Yup.number()
    .min(1, "should be greater than 0")
    .required("This field is required"),
  // stage_id: Yup.string().required('Task stage is required'),
  // stage_id: Yup.string().required('Task stage is required'),
  // task_type: Yup.string().required('This field is required'),
});

export { validation, initModel };

import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./editCompany.module.scss";
import { Switch } from "../../../primitives/index";
import { HeaderOptions } from "./editCompany.constants";
import { getCookie } from "common/helper";

const EditCompanyHeader = (props) => {
  const {
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: { selectedPatientOrganization },
    },
  } = useSelector((state) => state);
  const council_role = getCookie("council_role_");
  const history = useHistory();

  return (
    <>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          {council_role &&
            (council_role === "ttp_administrator" ||
              council_role === "council_administrator") &&
            !history?.location?.state?.fromTractionAdmin && (
              <span>
                <span
                  className={styles.rootText}
                  onClick={() => {
                    history.push("/admin");
                  }}
                >
                  ADMIN
                </span>
                <span className={styles.separationBar}> / </span>
                <span
                  className={styles.rootText}
                  onClick={() => {
                    history.push("/admin/companies");
                  }}
                >
                  {history.location?.state?.gsk
                    ? "PATIENT ORGANIZATIONS"
                    : "COMPANIES"}
                </span>
                <span className={styles.separationBar}> / </span>
              </span>
            )}
          <span className={styles.companyName}>
            {" "}
            {props.location.state?.gsk
              ? selectedPatientOrganization.name
              : selectedCompany.name}{" "}
          </span>
        </h4>
      </div>
      <div style={{ marginBottom: 30 }}>
        {!props.location.state?.gsk && (
          <Switch
            tabs={HeaderOptions}
            onChange={(value) => props.setSelectedTab(value)}
            selected={props.selectedTab}
          />
        )}
      </div>
    </>
  );
};

export default EditCompanyHeader;

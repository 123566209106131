import React, { useRef } from "react";
import {
  FieldArray, Form, Formik,
} from "formik";
import { useSelector } from "react-redux";
import {
  BtnType,
  Button,
  CheckBox,
  DropSelect,
  Label,
  TextBox,
} from "../../../../../primitives";
import { ModalBody, ModalFooter } from "../../../../../../application/modal";
import { useModelPopup } from "../../../../../../common/hooks";
import {
  createCustomFieldInitialValidationSchema,
  createCustomFieldInitialValues,
  customFieldsTypeOptions,
} from "./createNewCustomField.constant";
import { Icon, smSize } from "../../../../../../common/icon";
import styles from "./style.module.scss";

const CreateNewCustomFieldModal = ({ onSave }) => {
  const formRef = useRef();
  const popup = useModelPopup();

  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const handleSaveClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSubmit = async (values, actions) => {
    onSave({
      council_id: selectedCouncil.id,
      relation_field: "custom_field",
      ...values,
    });
    popup.hide();
  };

  const handleTypeChange = async (value) => {
    if (formRef.current) {
      const { setFieldValue } = formRef.current;
      setFieldValue("field_type", value.val);
      if (["dropdown", "checklist"].includes(value.val)) {
        setFieldValue("option_values", [""]);
        setFieldValue("allow_multiselect", false);
      } else {
        setFieldValue("option_values", undefined);
        setFieldValue("allow_multiselect", undefined);
      }
    }
  };

  const renderOptions = (remove, push) => {
    if (!formRef.current) {
      return;
    }

    const { errors, touched, values } = formRef.current;
    return (
      <>
        {values?.option_values?.map((item, index) => {
          const optionError = errors.option_values?.length && errors.option_values[index];
          const optionTouched = touched.option_values?.length && touched.option_values[index];
          return (
            <div
              key={`dropdown-option-${index}`}
              className={styles.dynamicTextFieldBlock}
            >
              <input
                name={`option_values.${index}`}
                type="text"
              />
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ minWidth: "30px" }}
              >
                {index > 0 && (
                  <div onClick={() => remove(index)}>
                    <Icon {...smSize} icon="icn-button-delete" />
                  </div>
                )}
              </div>
              <span className={styles.invalidFeedback}>
                {optionTouched && optionError}
              </span>
            </div>
          );
        })}
        <div className="form-row">
          <Button
            type="button"
            btn={BtnType.FRAME_LESS}
            onClick={() => push("")}
          >
            Add another option
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <ModalBody noFixedHeight>
        <div className="row">
          <div className="col-12">
            <Formik
              innerRef={formRef}
              initialValues={createCustomFieldInitialValues}
              validationSchema={createCustomFieldInitialValidationSchema}
              onSubmit={handleSubmit}
            >
              {(formikprops) => (
                <Form id="addApplicantForm">
                  <div>
                    <label>Type</label>
                    <DropSelect
                      name="field_type"
                      placeholder="Select type"
                      labelField="text"
                      valueField="value"
                      searchBy="text"
                      options={customFieldsTypeOptions}
                      formProps={formikprops}
                      onChange={handleTypeChange}
                    />
                  </div>

                  {["text"].includes(
                    formikprops?.values?.field_type,
                  ) && (
                    <div className="form-row d-flex align-items-center ml-1 mb-2">
                      <CheckBox
                        onChange={(value) => {
                          formikprops.setFieldValue(
                            "big_box_text",
                            value,
                          );
                        }}
                      />
                      <Label>Big Box Text</Label>
                    </div>
                  )}

                  {["text"].includes(
                    formikprops?.values?.field_type,
                  ) && formikprops.values.big_box_text && (
                    <div>
                      <label>Max Word Limit</label>
                      <TextBox
                        name="max_words"
                        placeholder="Max Word Limit"
                        formProps={formikprops}
                        className="w-100"
                      />
                    </div>
                  )}

                  <div>
                    <label>Title</label>
                    <TextBox
                      name="field_name"
                      placeholder="Field Title"
                      formProps={formikprops}
                      className="w-100"
                    />
                  </div>

                  <div>
                    <label>Label</label>
                    <TextBox
                      name="field_label"
                      placeholder="Field Label"
                      formProps={formikprops}
                      className="w-100"
                    />
                  </div>

                  {["text", "number", "dropdown"].includes(
                    formikprops?.values?.field_type,
                  ) && (
                    <div>
                      <label>Placeholder</label>
                      <TextBox
                        name="field_placeholder"
                        placeholder="Field Title"
                        formProps={formikprops}
                        className="w-100"
                      />
                    </div>
                  )}

                  {["dropdown", "checklist"].includes(
                    formikprops?.values?.field_type,
                  ) && (
                    <div>
                      <label>
                        {formikprops.values.field_type === "dropdown"
                          && "Dropdown Options"}
                        {formikprops.values.field_type === "checklist"
                          && "Checklist Options"}
                      </label>
                      <FieldArray name="option_values">
                        {({ remove, push }) => renderOptions(remove, push)}
                      </FieldArray>
                      <div className="form-row d-flex align-items-center mt-3">
                        <CheckBox
                          isChecked={false}
                          onChange={(value) => {
                            formikprops.setFieldValue(
                              "allow_multiselect",
                              value,
                            );
                          }}
                        />
                        <Label>Allow Multiselect</Label>
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="mr-3">
          <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
            Cancel
          </Button>
        </div>
        <Button type="submit" btn={BtnType.REGULAR} onClick={handleSaveClick}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
};

export default React.memo(CreateNewCustomFieldModal);

import { useDispatch, useSelector } from "react-redux";
import React, { useRef, useState } from "react";
import { Formik } from "formik";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import { useModelPopup } from "../../../../common/hooks/index";
import { ModalBody, ModalFooter } from "../../../../application/modal";
import {
  BtnType, Button, Label, TextBox,
} from "../../../primitives";
import {
  initCrunchbaseModel,
  crunchbaseValidation,
} from "../../../companies/crunchbase/crunchbase.constants";
import styles from "../../../companies/crunchbase/crunchbase.module.scss";
import { crunchbaseCompanyAttempt } from "../../../companies/crunchbase/crunchbase.action";

const AddNewCompetitors = ({ enqueueSnackbar, setDisabledCompetitors }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const formRef = useRef();
  const [model, setModel] = useState(initCrunchbaseModel);
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const handleCancelClick = (e) => {
    setDisabledCompetitors(false);
    popup.hide();
  };

  const handleOnSubmit = (values) => {
    setDisabledCompetitors(false);
    dispatch(
      crunchbaseCompanyAttempt({ values, selectedCouncil, enqueueSnackbar }),
    );

    popup.hide();
  };

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={model}
        validationSchema={crunchbaseValidation}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleOnSubmit(values);
        }}
      >
        {({ handleSubmit, handleReset, ...formikprops }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <ModalBody>
              <div className={styles.crunchBaseFormWrp}>
                <div className={styles.row}>
                  <div className={classNames(styles.col12)}>
                    <Label>Company Name</Label>
                    <TextBox
                      type="text"
                      name="name"
                      className={styles.textBox}
                      formProps={formikprops}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={classNames(styles.col12)}>
                    <Label>Company Website</Label>
                    <TextBox
                      type="text"
                      name="website"
                      placeholder="https://example.com"
                      className={styles.textBox}
                      formProps={formikprops}
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button type="submit" btn={BtnType.REGULAR}>
                Add
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default AddNewCompetitors;

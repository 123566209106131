import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Search, Button, BtnType } from "../../../../primitives";
import styles from "./crunchbaseSearch.module.scss";
import { searchCrunchBaseClear } from "../../companyMain.action";
import { getTitleByValue, layoutType } from "../../companyMain.constant";
import { queryStringUrlReplacement } from "../../../../../common/helper";
import classnames from "classnames";

const CrunchBaseSearchComponent = (props) => {
  const {
    handleCrunchBase,
    selectLayout,
    isFullWidth,
    selectedIndustries,
    selectedTopics,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const [val, setVal] = useState(history.location?.state?.query || "");

  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const handleCrunchBaseSearch = useCallback(() => {
    const title = getTitleByValue(selectLayout);

    const search1 = queryStringUrlReplacement(
      history.location.search,
      "query",
      val,
    );
    const search = queryStringUrlReplacement(search1, "page", 1);

    history.push({
      pathname:
        selectLayout === layoutType.card ? "/companies" : `/companies/${title}`,
      search,
      state: {
        ...history.location?.state,
        query: val,
        page: 1,
      },
    });
  }, [val, selectedCouncil, selectLayout]);

  const handleSearchCrunchBaseClear = useCallback(() => {
    dispatch(searchCrunchBaseClear({}));

    const combined = [...selectedTopics, ...selectedIndustries];

    if (combined.length) {
      handleCrunchBase(false, true);
    } else {
      handleCrunchBase(false);
    }
  }, [selectedTopics, selectedIndustries]);

  const handleBlur = () => {};

  const handleSearch = (e) => {
    setVal(e);

    if (!e || !e.length) {
      dispatch(searchCrunchBaseClear({}));

      const title = getTitleByValue(selectLayout);

      const search = queryStringUrlReplacement(
        history.location.search,
        "query",
        "",
      );

      history.push({
        pathname:
          selectLayout === layoutType.card
            ? "/companies"
            : `/companies/${title}`,
        search,
        state: {
          ...history.location?.state,
          query: "",
        },
      });
    }
  };

  return (
    <div
      className={`${
        isFullWidth ? styles.bigLeftPadding : styles.normalLeftPadding
      } ${styles.Wrapper}`}
    >
      <div className={styles.searchRow}>
        <div className={styles.searchContainer}>
          <div>Searching Crunchbase</div>
          <Search
            onChange={handleSearch}
            handleBlur={handleBlur}
            onKeyUp
            deafultValue={val}
            className={styles.searchCon}
            placeholder="Search Companies, Technologies, and Industries"
            type="crunch"
            mode="crunch"
            handleEnterPress={handleCrunchBaseSearch}
          />
        </div>
        <Button
          onClick={handleCrunchBaseSearch}
          btn={BtnType.REGULAR}
          className={styles.search}
        >
          Search Crunchbase
        </Button>
        <Button
          btn={BtnType.FRAME_LESS}
          className={styles.search}
          onClick={handleSearchCrunchBaseClear}
        >
          Search Traction
        </Button>
      </div>

      <div className={classnames(styles.filterWrp, "row")} />
    </div>
  );
};

export default React.memo(CrunchBaseSearchComponent);

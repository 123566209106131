import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useSnackbar } from "notistack";
import { checkViewerRole } from "common/checkers/viewerChecker";
import { useWindowSize } from "common/hooks/useWindowSize";
import CustomGroupComponent from "modules/companies/company/details/customGroup";
import styles from "./details.module.scss";
import { useModelPopup } from "../../../../common/hooks";
import {
  addCustomFields,
  companyPutDetailsFields,
  companyDeleteDetailsFields,
  companyAgreementShouldOpen,
  companyDeleteDetailsTemplateFields,
  setDetailsCustomGroups,
  getCompanyDetailsFieldsSuccess,
  savePrevUrl,
} from "../company.action";
import NewItemComponent from "../../../../common/popups/newItem";
import ConfirmActionComponent from "../../../../common/components/confirmAction/confirmAction.component";

const PresentationComponent = ({ companyId }) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const history = useHistory();
  const popup = useModelPopup();
  const [stageNameFromInvestment, setStageNameFromInvestment] = useState("");
  const [expandedCF, setExpandedCF] = useState(false);
  const investmentRef = useRef();
  const {
    authReducer: { session },
    companiesReducer: {
      companyReducer: {
        companyDetails,
        newCustomFieldGroups,
        shouldOpenUnusedAgreement,
      },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (newCustomFieldGroups.length) {
      const allGroupsAreLoaded =
        newCustomFieldGroups.filter((g) => g.loading).length === 0;

      if (allGroupsAreLoaded) {
        const allCustomFields = newCustomFieldGroups.reduce(
          (acc, elem) => [...acc, ...(elem?.customFields || [])],
          []
        );
        dispatch(
          getCompanyDetailsFieldsSuccess({
            response: allCustomFields,
          })
        );
      }
    }
  }, [newCustomFieldGroups]);

  useEffect(() => {
    if (session && selectedCouncil) {
      const customFieldGroupsData = selectedCouncil.custom_field_groups.map(
        (group) => ({
          loading: true,
          groupDetails: group,
          customFields: null,
          viewType: group.view_type,
          any_values: false,
          description: group.description,
        })
      );

      dispatch(setDetailsCustomGroups(customFieldGroupsData));
    }
  }, [session, selectedCouncil]);

  useEffect(() => {
    if (history.location.state) {
      if (history.location.state.isFromInvestmentTheme) {
        setStageNameFromInvestment(history.location.state.targetStage);
      }
    }
  }, [history.location.state]);

  useEffect(() => {
    if (session?.council_role && checkViewerRole(session.council_role)) {
      setBlockFunctionality(true);
    }
  }, [session]);

  const checkAvailableAgreementType = (selectedAgreementType) =>
    companyDetails.filter(
      (elem) => elem.agreement_type_id === selectedAgreementType
    );

  useEffect(() => {
    if (shouldOpenUnusedAgreement.type && companyDetails.length) {
      const selectedType = companyDetails.find((detail) => {
        if (detail.field_type === "agreement") {
          /// // option selectefto change
          return detail.option_selected[0] === shouldOpenUnusedAgreement.type;
        }
        return false;
      });
      if (!selectedType && shouldOpenUnusedAgreement?.type) {
        dispatch(
          companyAgreementShouldOpen({
            type: shouldOpenUnusedAgreement.type,
            id: shouldOpenUnusedAgreement.id,
            item: shouldOpenUnusedAgreement.item,
            mode:
              checkAvailableAgreementType(shouldOpenUnusedAgreement.id).length >
              0
                ? "edit"
                : "add",
          })
        );
      }
    }
  }, [companyDetails]);

  useEffect(() => {
    if (shouldOpenUnusedAgreement.accessFlag && newCustomFieldGroups.length) {
      if (shouldOpenUnusedAgreement.mode === "edit") {
        handleEditClick(
          checkAvailableAgreementType(shouldOpenUnusedAgreement.id)[0],
          true,
          "agreement",
          shouldOpenUnusedAgreement.id
        );
      } else {
        handleAddClick(
          newCustomFieldGroups[0]?.groupDetails.id,
          "agreement",
          shouldOpenUnusedAgreement.id
        );
      }
    }
  }, [shouldOpenUnusedAgreement?.accessFlag, newCustomFieldGroups]);

  const handleAddClick = (
    groupId,
    preselectType = "text",
    id,
    indexOfCfGroup
  ) => {
    popup.show({
      title: `Add  ${preselectType || "Text"}`,
      height: isMobile ? null : String(window.innerHeight),
      width: isMobile ? null : String(window.innerWidth / 1.5),
      component: (
        <NewItemComponent
          handleSubmitDetail={handleSubmitDetail}
          uniqNameValidator={uniqNameValidator}
          custom_field_group_id={groupId}
          preselectType={preselectType}
          agreementIdFromOverview={id}
          indexOfCfGroup={indexOfCfGroup}
        />
      ),
    });
  };

  const handleEditClick = (
    item,
    checkboxClicked = false,
    preselectType = "text",
    id,
    indexOfCfGroup
  ) => {
    popup.show({
      title: `Edit ${preselectType || "Item"}`,
      height: isMobile ? null : String(window.innerHeight),
      width: isMobile ? null : String(window.innerWidth / 1.5),
      component: (
        <NewItemComponent
          mode="edit"
          data={item}
          preselectType={preselectType}
          handleSubmitDetail={handleSubmitDetail}
          checkboxFromDetails={checkboxClicked}
          agreementIdFromOverview={id}
          indexOfCfGroup={indexOfCfGroup}
        />
      ),
    });
  };

  const handleDeleteConfirm = (item, group) => {
    if (!item.company_custom_field_id) {
      dispatch(
        companyDeleteDetailsTemplateFields({
          company_id: companyId,
          council_id: selectedCouncil.id,
          custom_field_template_id: item.custom_field_template_id,
          groupId: group.groupDetails.id,
          data: {},
          enqueueSnackbar,
        })
      );

      popup.hide();
      return;
    }

    dispatch(
      companyDeleteDetailsFields({
        company_id: companyId,
        council_id: selectedCouncil.id,
        company_custom_field_id: item.company_custom_field_id,
        groupId: group.groupDetails.id,
        data: {},
        enqueueSnackbar,
      })
    );

    popup.hide();
  };

  const handleDeleteClick = (item, group) => {
    popup.show({
      title: "You are about to permanently delete this item",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmActionComponent
          alertText="Are you sure?"
          confirmBtnName="Delete"
          onConfirm={() => handleDeleteConfirm(item, group)}
        />
      ),
    });
  };

  const uniqNameValidator = (values) => {
    const uniqName = { passed: true, text: "" };
    companyDetails.forEach((cf) => {
      if (
        cf.field_name?.toLowerCase() === values?.title?.toLowerCase().trim() &&
        values?.title?.toLowerCase().trim().length
      ) {
        uniqName.text =
          "This field title already exists. Please use a different title!";
        uniqName.passed = false;
      }
    });
    return uniqName;
  };

  const handleSubmitDetail = (values, initObject, custom_field_group_id) => {
    setExpandedCF(false);
    if (
      (initObject &&
        !initObject.company_custom_field_id &&
        !initObject.company_custom_field) ||
      !initObject
    ) {
      const sort_numberArr = companyDetails
        .filter((cf) => custom_field_group_id === cf.custom_field_group_id)
        .sort((a, b) => a.sort_number > b.sort_number);
      const payload = {
        company_id: companyId,
        council_id: selectedCouncil.id,
        enqueueSnackbar,
        data: {
          user_id: session.id,
          display_as: values.display_as,
          field_value: values.field_value,
          field_name: values?.title?.trim() || values?.field_name?.trim(),
          name: values.name,
          field_placeholder: values.instruction || values.field_placeholder,
          field_type: values.type || values.field_type,
          option_selected: values.option_selected || [],
          field_values: values.field_values || [],
          multiselect: values.multiselect,
          option_values: values.option_values || values.options,
          document_date: values.document_date,
          expiration_date: values.expiration_date,
          documents_attributes: values?.documents_attributes,
          agreement_type_id: values.agreement_type_id,
          agreement_type_selected: values.agreement_type_selected?.length > 0,
          custom_field_group_id:
            initObject?.custom_field_group_id || custom_field_group_id,
          custom_field_template_id: initObject?.custom_field_template_id,
          sort_number:
            initObject?.sort_number ||
            sort_numberArr[sort_numberArr.length - 1]?.sort_number + 1,
        },
      };
      dispatch(addCustomFields(payload));
      return;
    }

    const payload = {
      company_custom_field_id:
        initObject.company_custom_field || initObject.company_custom_field_id,
      enqueueSnackbar,
      company_id: companyId,
      data: {
        company_id: companyId,
        council_id: selectedCouncil.id,
        user_id: session.id,
        field_value: values.field_value,
        field_name: values?.title?.trim(),
        field_placeholder: values.instruction || values.field_placeholder,
        field_type: values.type,
        field_values: values.field_values || [],
        option_selected: values.option_selected || [],
        multiselect: values.multiselect,
        option_values: values.option_values || values.options,
        custom_field_group_id:
          initObject.custom_field_group_id || custom_field_group_id,
        document_date: values.document_date,
        expiration_date: values.expiration_date,
        documents_attributes: values?.documents_attributes,
        agreement_type_id: values.agreement_type_id,
        agreement_type_selected: values.agreement_type_selected?.length > 0,
        sort_number: initObject?.sort_number,
        display_as: values.display_as,
      },
    };
    // dispatch(setCustomFieldLoading(initObject.company_custom_field_id));
    dispatch(companyPutDetailsFields(payload));
  };

  useEffect(() => {
    dispatch(savePrevUrl({ prevPath: history.location.pathname }));
    return () => {
      if (history.location.pathname.indexOf("/pipeline") === -1) {
        dispatch(savePrevUrl({ prevPath: "" }));
      }
    };
  }, [history]);

  return (
    <section className={styles.wrapper}>
      {newCustomFieldGroups?.map((group, idx) => (
        <CustomGroupComponent
          key={`${group.groupDetails.id}-${idx}`}
          group={group}
          handleEditClick={handleEditClick}
          handleDeleteClick={(item) => handleDeleteClick(item, group)}
          handleAddClick={handleAddClick}
          handleSubmitDetail={handleSubmitDetail}
          blockFunctionality={blockFunctionality}
          companyId={companyId}
          idx={idx}
          stageNameFromInvestment={stageNameFromInvestment}
          investmentRef={investmentRef}
          expandedCF={expandedCF}
          setExpandedCF={setExpandedCF}
        />
      ))}
    </section>
  );
};

export default React.memo(PresentationComponent);

import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import classNames from "classnames";
import html2pdf from "html2pdf.js/src";

import { Button, BtnType } from "../../../modules/primitives";
import styles from "./downloadReport.module.scss";
import downloadTable from "./generateDocHelper";
import Loading from "../../../modules/primitives/loading/loading.component";

const DownloadReport = ({
  className,
  location,
  label,
  countryFilters,
  endpoint,
  payload,
  setDownloadPdfIsActive,
  filename,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLoading = (loadingState) => {
    setLoading(loadingState);
  };

  const makeSimpleDownload = () => {
    document.body.style.cursor = "wait";
    setDownloadPdfIsActive(true);

    const timeout1 = setTimeout(() => {
      const elem = document.getElementById("downloadableSection");
      const pdfWidth = elem.offsetWidth + 15 * 2;
      const height = pdfWidth * 1.5 + 15 * 2;
      const pdfHeight = height < 2287 ? 2287 : height;
      const opt = {
        margin: [20, 20, 20, 20],
        filename: filename || "traction-chart.pdf",
        image: { type: "png" },
        html2canvas: { useCORS: true, scale: 1 },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        jsPDF: {
          orientation: "p",
          unit: "pt",
          // format: 'a4',
          format: [pdfWidth, pdfHeight],
          putOnlyUsedFonts: true,
          compress: true,
        },
      };

      html2pdf()
        .set(opt)
        .from(elem)
        .save()
        .then(() => {
          setDownloadPdfIsActive(false);
          document.body.style.cursor = "default";
          clearTimeout(timeout1);
        });
    }, 2000);
  };

  const download = (type) => {
    if (type === "pdf") {
      makeSimpleDownload();

      return;
    }

    downloadTable(
      type,
      location,
      countryFilters,
      handleClose,
      endpoint,
      payload,
      handleLoading
    );
  };

  return (
    <span className={classNames(className, styles.downloadBtnWrapper)}>
      {loading ? (
        <Loading />
      ) : (
        <Button
          btn={BtnType.FRAME_LESS}
          className={styles.download}
          icon="icn-export-button"
          onClick={handleClick}
          id="demo-positioned-button"
          aria-controls={openMenu ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? "true" : undefined}
        >
          {label || "Download Report"}
        </Button>
      )}

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => download("csv")}>DOWNLOAD CSV</MenuItem>
        <MenuItem onClick={() => download("xlsx")}>DOWNLOAD EXCEL</MenuItem>
        <MenuItem onClick={() => download("pdf")}>DOWNLOAD PDF</MenuItem>
      </Menu>
    </span>
  );
};

export default React.memo(DownloadReport);

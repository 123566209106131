import React, { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import { httpGet } from "../../httpCall";
import NewContactForm from "./NewContactForm";

const CompanyContactMultiSelectSearch = (props) => {
  const [showAddContactForm, setShowAddContactForm] = useState(false);
  const [companyContacts, setCompanyContacts] = useState([]);
  const {
    placeholder,
    onChange,
    otherAttendees,
    tempAttendees,
    selectedValue,
  } = props;

  useEffect(() => {
    if (showAddContactForm) return;

    const res = httpGet({
      call: "companies/contacts",
    })
      .pipe()
      .subscribe((res) => setCompanyContacts(res.response));
  }, [showAddContactForm]);

  const showAddCompanyContactForm = () => setShowAddContactForm(!showAddContactForm);

  const handleSubmit = (values) => {
    setShowAddContactForm(false);
    onChange([...otherAttendees, values]);
  };

  const displayAddContactForm = ({ props, state, method }) => (
    <span
      onClick={showAddCompanyContactForm}
      style={{ padding: 10, textAlign: "center" }}
    >
      {" "}
      + Add contact
    </span>
  );

  const handleAddContactCancel = () => {
    setShowAddContactForm(false);
  };

  return (
    <>
      <Select
        placeholder={placeholder}
        multi
        values={selectedValue || otherAttendees}
        options={companyContacts.filter(
          (e) => !tempAttendees.map((v) => v.email).includes(e.email),
        )}
        labelField="full_name"
        valueField="id"
        onChange={onChange}
        closeOnSelect
        searchBy="full_name"
        noDataRenderer={displayAddContactForm}
      />
      {/* <span className={styles.addFieldsButton}>
        <span onClick={showAddCompanyContactForm}>
          {showAddContactForm ? '-' : '+ Add Contact'}
        </span>
      </span> */}

      {showAddContactForm && (
        <NewContactForm
          handleSubmit={handleSubmit}
          handleAddContactCancel={handleAddContactCancel}
        />
      )}
    </>
  );
};

export default React.memo(CompanyContactMultiSelectSearch);

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import classnames from "classnames";

import { checkViewerRole } from "common/checkers/viewerChecker";
import styles from "./events.module.scss";
import {
  Search, Switch, Button, BtnType,
} from "../primitives";
import { HeaderOptions, EventTypes } from "./events.constant";
import EventListComponent from "./eventList";
import { councilUsersListGet } from "../../common/actions/common.action";

const EventsComponent = (props) => {
  const {
    location: { pathname },
  } = props;
  const path = pathname.split("/")[2];
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(
    path
      ? HeaderOptions.find((typ) => typ.path === path)?.value
      : EventTypes.UPCOMING,
  );
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [checkEmptyEvents, setCheckEmptyEvents] = useState(false);
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  const {
    councilReducer: { selectedCouncil },
    eventsReducer: {
      viewEventReducer: {
        eventsList,
      },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (selectedTab) handleSwitchChange(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(councilUsersListGet(selectedCouncil.id, enqueueSnackbar));
    }
  }, [selectedCouncil]);

  const handleSearch = (value) => {
    // setSelectedTab(value);
  };

  const handleSwitchChange = (value) => {
    if (!value) {
      setCheckEmptyEvents(true);
    }
    setSelectedTab(value);
    const tab = HeaderOptions.find((typ) => typ.value === value);
    window.history.replaceState(
      null,
      tab.path,
      `/events/${tab.path.toLowerCase()}`,
    );
  };

  useEffect(() => {
    const events = eventsList[1];
    if (eventsList[1]) {
      if (!events?.length && !selectedTab && !checkEmptyEvents) {
        setSelectedTab(1);
      }
    }
  }, [eventsList]);

  const handleAddNewEvent = () => {
    history.push("/events/new", { mode: "add" });
  };

  return (
    <div className={styles.mainWrp}>
      <div className={styles.searchWrapper}>
        <Search
          onChange={handleSearch}
          onKeyUp
          placeholder="Search Companies, Technologies, and Industries"
          from="events"
          handleCrunchBase="handleCrunchBase"
        />
      </div>
      <div className={styles.headerWrp}>
        <div className="row">
          <div className={classnames(styles.tabsCol, "col-12 col-md-6")}>
            <h4 className={styles.headerText}>Events</h4>
            <Switch
              tabs={HeaderOptions}
              onChange={handleSwitchChange}
              selected={selectedTab}
              className={styles.headerTabs}
            />
          </div>
          <div className={classnames(styles.addEventButonContainer, "col-12 col-md-6")}>
            {!blockFunctionality ? (
              <Button
                className={styles.addNewEventMobile}
                btn={BtnType.REGULAR}
                icon="icn-add"
                onClick={handleAddNewEvent}
              >
                Add an event
              </Button>
            ) : (
              <Tooltip
                placement="top"
                title="You have View-Only access. To Add an Event, please ask your Admin yo upgrade your account to Standard access."
              >
                <div>
                  <Button
                    className={styles.addNewEventMobile}
                    btn={BtnType.DISABLED}
                    icon="icn-add"
                  >
                    Add an event
                  </Button>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={classNames(styles.tabContainer)}>
          <EventListComponent eventType={selectedTab} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(EventsComponent);

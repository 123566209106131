import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FaLinkedin,
  FaTwitter,
  FaGoogle,
  FaMicrosoft,
  FaSalesforce,
} from "react-icons/fa";
import { useSnackbar } from "notistack";
import { useMsal } from "@azure/msal-react";
import { getCookie } from "common/helper";

import ConfirmationModal from "modules/admin/adminIntegrations/confirmationModal/confirmationModal";
import MsftSuccessfulModal from "modules/admin/adminIntegrations/msftSuccessfulModal/msftSuccessfulModal";
import {
  postCouncilIntegration,
  postUserIntegration,
  putCouncilIntegration,
  putUserIntegration,
} from "modules/admin/adminIntegrations/adminIntegrations.actions";
import { loginRequest } from "api/microsoft/authConfig";
import { httpGet } from "common/httpCall";
import useModelPopup from "./modelPopup.hook";
import {
  sfClientId,
  sfAuthURL,
  sfRedirectURI,
  sfResponseType,
} from "../constants";

const useIntegrationHelper = (props) => {
  const { iconStyles, name } = props;

  const {
    adminIntegrationsReducer: { integrations },
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const { instance } = useMsal();
  const councilRole = getCookie("council_role_");

  const handleSwitchForMicrosoft = async (service, check) => {
    const msftToken = integrations
      ?.find((elem) => elem.id === service.id)
      ?.users_integrations?.find(
        (elem) => elem.integration_id === service.id
      )?.token;

    const paramsData = {
      integration_id: service.id,
      status: check,
      admin_connected: !!msftToken || false,
    };

    if (!service?.councils_integrations[0]?.id) {
      dispatch(postCouncilIntegration({ enqueueSnackbar, ...paramsData }));
    } else {
      dispatch(
        putCouncilIntegration({
          enqueueSnackbar,
          ...paramsData,
          councils_integration_id: service?.councils_integrations[0]?.id,
        })
      );
    }
  };

  const handleSwitchForSalesforce = async (service, check) => {
    const sfToken = integrations
      ?.find((elem) => elem.id === service.id)
      ?.users_integrations?.find(
        (elem) => elem.integration_id === service.id
      )?.token;

    const paramsData = {
      integration_id: service.id,
      status: check,
      admin_connected: !!sfToken || false,
    };

    if (!service?.councils_integrations[0]?.id) {
      dispatch(postCouncilIntegration({ enqueueSnackbar, ...paramsData }));
    } else {
      dispatch(
        putCouncilIntegration({
          enqueueSnackbar,
          ...paramsData,
          councils_integration_id: service?.councils_integrations[0]?.id,
        })
      );
    }
  };

  const successfullyLoggedIn = () => {
    popup.show({
      title: "Successfully Logged In!",
      show: true,
      height: "300",
      width: "540",
      component: <MsftSuccessfulModal />,
    });
  };

  const handleLogin = useCallback(
    async (service) => {
      let keys = [];
      const token = {};
      await instance
        .loginPopup(loginRequest)
        .then((res) => {
          keys = Object.keys(localStorage).filter((key) =>
            key.includes(res.uniqueId)
          );
          keys.forEach((element) => {
            token[element] = localStorage.getItem(element);
          });
          if (res) {
            const params = {
              integration_id: service.id,
              user_id: session.id,
              token,
            };

            if (service?.users_integrations[0]) {
              dispatch(
                putUserIntegration({
                  enqueueSnackbar,
                  ...params,
                  id: service.users_integrations[0].id,
                })
              );
            } else {
              dispatch(postUserIntegration({ enqueueSnackbar, ...params }));
            }

            if (
              ["ttp_administrator", "council_administrator"].includes(
                councilRole
              )
            ) {
              const councils_integration = service.councils_integrations.find(
                (elem) => elem.integration_id === service.id
              );
              const adminParams = {
                integration_id: service.id,
                status: councils_integration.status,
                admin_connected: true,
                councils_integration_id: councils_integration.id,
              };
              dispatch(
                putCouncilIntegration({ enqueueSnackbar, ...adminParams })
              );
            }
          }
          successfullyLoggedIn();
        })
        .catch((e) => console.error(e));
    },
    [instance, dispatch, successfullyLoggedIn, enqueueSnackbar]
  );

  const promptUser = (service) => {
    httpGet({
      call: "sf_integrations/fetch_sf_integrations",
    })
      .pipe()
      .subscribe((res) => {
        const params = {
          integration_id: service.id,
          user_id: session.id,
          council_id: selectedCouncil.id,
          token: res.response.access_token,
        };
        let userObject;
        if (
          service?.users_integrations?.some((value) => {
            if (
              value?.council_id === selectedCouncil?.id &&
              value?.user_id === session?.id &&
              !value?.token
            ) {
              userObject = value;
            }
            return (
              value?.council_id === selectedCouncil?.id &&
              value?.user_id === session?.id &&
              !value?.token
            );
          })
        ) {
          dispatch(
            putUserIntegration({
              enqueueSnackbar,
              ...params,
              id: userObject?.id,
            })
          );
        } else {
          dispatch(postUserIntegration({ enqueueSnackbar, ...params }));
        }
      });
  };

  const handleProceedClick = useCallback((service) => {
    popup.show({
      title: "Alert!",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmationModal
          handleIntegrate={() => handleLogin(service)}
          service={service}
        />
      ),
    });
  }, []);

  const handleLoginSalesforce = (service, setLoader) => {
    setLoader(true);
    const sfWindow = window.open(
      `${sfAuthURL}?client_id=${sfClientId}&redirect_uri=${sfRedirectURI}&response_type=${sfResponseType}&state=${selectedCouncil?.id}|${session?.id}`,
      "_blank",
      "top=100, left=500, height=700, width=500, location=no"
    );
    const winClosed = setInterval(() => {
      if (sfWindow.closed) {
        clearInterval(winClosed);
        promptUser(service);
        setLoader(false);
      }
    }, 250);
  };

  const handleDisconnectMsft = useCallback(
    async (service) => {
      const msftUserData = service?.users_integrations[0];

      if (msftUserData?.token) {
        Object.keys(msftUserData.token).forEach((key) =>
          localStorage.removeItem(key)
        );
        const params = {
          id: msftUserData.id,
          integration_id: service.id,
          user_id: session.id,
          token: null,
        };
        dispatch(putUserIntegration({ enqueueSnackbar, ...params }));
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const handleDisconnectSalesforce = useCallback(
    async (service) => {
      const sfUserData = service?.users_integrations;
      if (sfUserData) {
        let params;
        if (
          service?.users_integrations?.some((value) => {
            if (
              value?.council_id === selectedCouncil?.id &&
              value?.user_id === session?.id &&
              value?.token
            ) {
              params = {
                id: value.id,
                integration_id: service.id,
                user_id: session?.id,
                token: null,
                council_id: selectedCouncil?.id,
              };
            }
            return (
              value?.council_id === selectedCouncil?.id &&
              value?.user_id === session?.id &&
              value?.token
            );
          })
        )
          dispatch(putUserIntegration({ enqueueSnackbar, ...params }));
      }
    },
    [dispatch, enqueueSnackbar, selectedCouncil, session]
  );

  const helper = {
    LinkedIn: { onClick: null, icon: <FaLinkedin className={iconStyles} /> },
    Twitter: { onClick: null, icon: <FaTwitter className={iconStyles} /> },
    Google: { onClick: null, icon: <FaGoogle className={iconStyles} /> },
    Microsoft: {
      onToggle: handleSwitchForMicrosoft,
      icon: <FaMicrosoft className={iconStyles} />,
      onClick: ["ttp_administrator", "council_administrator"].includes(
        councilRole
      )
        ? handleProceedClick
        : handleLogin,
      onDisconnect: handleDisconnectMsft,
    },
    Salesforce: {
      onToggle: handleSwitchForSalesforce,
      icon: <FaSalesforce className={iconStyles} />,
      onClick: handleLoginSalesforce,
      onDisconnect: handleDisconnectSalesforce,
    },
  };

  return helper[name];
};

export default useIntegrationHelper;

export function generateArrayOfYears(startWith = 1900) {
  const max = new Date().getFullYear();
  const min = max - (max - startWith);
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push({
      year: i,
    });
  }

  return years;
}

export const IS_FEED_OPENED = "IS_FEED_OPENED";
export const setIsFeedOpened = (payload) => ({
  type: IS_FEED_OPENED,
  payload,
});
export const GET_FEED_ATTEMPT = "GET_FEED_ATTEMPT";
export const getFeedAttempt = (payload) => ({
  type: GET_FEED_ATTEMPT,
  payload,
});
export const GET_FEED_SUCCESS = "GET_FEED_SUCCESS";
export const getFeedSuccess = (payload) => ({
  type: GET_FEED_SUCCESS,
  payload,
});
export const PUT_FEED_ATTEMPT = "PUT_FEED_ATTEMPT";
export const putFeedAttempt = (payload) => ({
  type: PUT_FEED_ATTEMPT,
  payload,
});
export const PUT_FEED_SUCCESS = "PUT_FEED_SUCCESS";
export const putFeedSuccess = (payload) => ({
  type: PUT_FEED_SUCCESS,
  payload,
});

export const SELECT_REPLY_AS_FAVORITE_ATTEMPT = "SELECT_REPLY_AS_FAVORITE_ATTEMPT";
export const selectReplyAsFavoriteAttempt = (payload) => ({
  type: SELECT_REPLY_AS_FAVORITE_ATTEMPT,
  payload,
});

export const SELECT_REPLY_AS_FAVORITE_SUCCESS = "SELECT_REPLY_AS_FAVORITE_SUCCESS";
export const selectReplyAsFavoriteSuccess = (payload) => ({
  type: SELECT_REPLY_AS_FAVORITE_SUCCESS,
  payload,
});

export const DESELECT_REPLY_AS_FAVORITE_ATTEMPT = "DESELECT_REPLY_AS_FAVORITE_ATTEMPT";
export const deselectReplyAsFavoriteAttempt = (payload) => ({
  type: DESELECT_REPLY_AS_FAVORITE_ATTEMPT,
  payload,
});

export const DESELECT_REPLY_AS_FAVORITE_SUCCESS = "DESELECT_REPLY_AS_FAVORITE_SUCCESS";
export const deselectReplyAsFavoriteSuccess = (payload) => ({
  type: DESELECT_REPLY_AS_FAVORITE_SUCCESS,
  payload,
});

export const POST_REPLY_GET_ATTEMPT = "POST_REPLY_GET_ATTEMPT";
export const postsReplyGetAttempt = (payload) => ({
  type: POST_REPLY_GET_ATTEMPT,
  payload,
});
export const POST_REPLY_GET_SUCCESS = "POST_REPLY_GET_SUCCESS";
export const postsReplyGetSuccess = (payload) => ({
  type: POST_REPLY_GET_SUCCESS,
  payload,
});

export const POST_REPLY_ADD_ATTEMPT = "POST_REPLY_ADD_ATTEMPT";
export const postsReplyAddAttempt = (payload) => ({
  type: POST_REPLY_ADD_ATTEMPT,
  payload,
});
export const POST_REPLY_ADD_SUCCESS = "POST_REPLY_ADD_SUCCESS";
export const postsReplyAddSuccess = (payload) => ({
  type: POST_REPLY_ADD_SUCCESS,
  payload,
});

export const POST_REPLY_EDIT_ATTEMPT = "POST_REPLY_EDIT_ATTEMPT";
export const postsReplyEditAttempt = (payload) => ({
  type: POST_REPLY_EDIT_ATTEMPT,
  payload,
});
export const POST_REPLY_EDIT_SUCCESS = "POST_REPLY_EDIT_SUCCESS";
export const postsReplyEditSuccess = (payload) => ({
  type: POST_REPLY_EDIT_SUCCESS,
  payload,
});

export const POST_REPLY_DELETE_ATTEMPT = "POST_REPLY_DELETE_ATTEMPT";
export const postsReplyDeleteAttempt = (payload) => ({
  type: POST_REPLY_DELETE_ATTEMPT,
  payload,
});
export const POST_REPLY_DELETE_SUCCESS = "POST_REPLY_DELETE_SUCCESS";
export const postsReplyDeleteSuccess = (payload) => ({
  type: POST_REPLY_DELETE_SUCCESS,
  payload,
});

export const LIKE_REPLY_ATTEMPT = "LIKE_REPLY_ATTEMPT";
export const likeReplyAttempt = (payload) => ({
  type: LIKE_REPLY_ATTEMPT,
  payload,
});

export const LIKE_REPLY_SUCCESS = "LIKE_REPLY_SUCCESS";
export const likeReplySuccess = (payload) => ({
  type: LIKE_REPLY_SUCCESS,
  payload,
});

export const DISLIKE_REPLY_ATTEMPT = "DISLIKE_REPLY_ATTEMPT";
export const dislikeReplyAttempt = (payload) => ({
  type: DISLIKE_REPLY_ATTEMPT,
  payload,
});

export const DISLIKE_REPLY_SUCCESS = "DISLIKE_REPLY_SUCCESS";
export const dislikeReplySuccess = (payload) => ({
  type: DISLIKE_REPLY_SUCCESS,
  payload,
});

export const MENTION_REPLY_ATTEMPT = "MENTION_REPLY_ATTEMPT";
export const mentionReplyAttempt = (payload) => ({
  type: MENTION_REPLY_ATTEMPT,
  payload,
});

export const MENTION_REPLY_SUCCESS = "MENTION_REPLY_SUCCESS";
export const mentionReplySuccess = (payload) => ({
  type: MENTION_REPLY_SUCCESS,
  payload,
});

export const MENTION_POST_ATTEMPT = "MENTION_POST_ATTEMPT";
export const mentionPostAttempt = (payload) => ({
  type: MENTION_POST_ATTEMPT,
  payload,
});

export const MENTION_POST_SUCCESS = "MENTION_POST_SUCCESS";
export const mentionPostSuccess = (payload) => ({
  type: MENTION_POST_SUCCESS,
  payload,
});
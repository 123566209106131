import React from "react";
import styles from "./styles.module.scss";

const ListNotFound = () => (
  <div className={`container ${styles.errorBoundaryWrapper}`}>
    <h2 className="text-center">
      No list has been found with this ID. It was probably deleted.
    </h2>
    <br />
    <p>
      You may also contact us and report this issue at&nbsp;
      <a href="mailto:support@tractiontechnology.com">
        support@tractiontechnology.com
      </a>
      . We will fix it asap!
    </p>
  </div>
);
export default ListNotFound;

import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";

import styles from "./summaryBlock.module.scss";
import { ProgressBar, Pannel } from "../../../../primitives";
import { projectGet } from "../../project.action";
import ProjectStage from "modules/projects/project/projectStage";
import ProjectStateGauge from "modules/projects/project/overview/summaryBlock/projectStateGauge";
import ProjectBudgetVsSpend from "modules/projects/project/overview/summaryBlock/projectBudgetVsSpend";

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#2d83ae",
    boxShadow: "5px 5px 4px 0 rgba(0, 0, 0, 0.14)",
    color: "white",
    border: "1px solid #2d83ae",
    padding: "15px 20px 10px",
  },
}))(Tooltip);

const SummeryBlock = ({ isNot3DPrintingTemplate, selectedCouncil, fordInstance, isAdmin, metrics }) => {
  const dispatch = useDispatch();
  const [patientOrg, setPatientOrg] = useState(false);
  const [textOrg, setTextOrg] = useState("Company");
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const {
    projectsReducer: {
      projectReducer: { selectedProject },
      companyReducer: { overviewCompanies },
    },
  } = useSelector((state) => state);

  const isFordSupplyChainAutomation = useMemo(() => {
    return selectedCouncil?.name === 'Ford' && selectedProject?.stage_template?.name === 'Supply Chain Automation';
  }, [selectedCouncil, selectedProject]);

  useEffect(() => {
    if (id) {
      dispatch(projectGet({ id, enqueueSnackbar }));
    }
  }, [id]);

  useEffect(() => {
    if (
      selectedProject &&
      selectedProject.stage_template &&
      selectedProject.stage_template.patient_organization
    ) {
      setPatientOrg(true);
      setTextOrg("Organization");
    }
  }, [selectedProject]);

  const handleThemeNavigation = (theme) => {
    history.push(`/themes/individual_page/${theme.theme_id}/overview`);
  };

  const getCompaniesNr = useMemo(() => overviewCompanies.filter(c => c.state !== 'removed')?.length, [overviewCompanies]);

  return (
    <Pannel title="Project Overview" padding="0" overflow="hidden">
      <div className={styles.wrapper}>
        <div className={styles.summaryRowHeader}>
          <div className={classNames(styles.taskcol, styles.colNumCompany, 'mr-4')}>
            {
              selectedCouncil?.name === 'Ford'
                ? isFordSupplyChainAutomation ? 'Status' : 'Replications'
                : `Number of ${patientOrg ? textOrg : "Companie"}s`
            }
          </div>
          <div className={classNames(styles.taskcol, styles.colProjProgress)}>
            Project <br />
            Progress
          </div>
          <div className={classNames(styles.taskcol, styles.colTaskProgress)}>
            My task <br />
            Progress
          </div>
          <div className={classNames(styles.taskcol, styles.colRatedCompany)}>
            {
              selectedCouncil?.name === 'Ford'
                ? isFordSupplyChainAutomation ? 'Budget vs. Spend' : 'Pillars'
                : (
                  <>
                    {patientOrg ? `${textOrg}s` : "Companies"} <br />
                    Rated
                  </>
                )
            }
          </div>
          <div className={classNames(styles.taskcol, styles.colfinalStage)}>
            Project Stage
          </div>
        </div>
        <div className={styles.summaryRow}>
          <div className={classNames(styles.taskcol, styles.colNumCompany, 'mr-4')}>
            {
              isFordSupplyChainAutomation && selectedProject?.status ? (
                <ProjectStateGauge status={selectedProject?.status} />
              ) : (
                <>
                  {
                    selectedCouncil?.name === 'Ford'
                      ? isNot3DPrintingTemplate
                        ? `${selectedProject?.replications?.length + (selectedProject?.original_project ? 1 : 0)}`
                        : selectedProject?.threed_printing_replications ? selectedProject?.threed_printing_replications : 0
                      : selectedProject?.total_companies !== getCompaniesNr ? getCompaniesNr : selectedProject?.total_companies
                  }
                </>
              )
            }
          </div>
          <div className={classNames(styles.taskcol, styles.colProjProgress)}>
            <ProgressBar
              width="100%"
              tot={100}
              fill={selectedProject && selectedProject.project_progress}
              wrpClass={styles.progWrp}
              innerClass={styles.progInner}
              overViewTab
              conClass={styles.progCon}
              showPrecentage
              showTot={false}
            />
          </div>
          <div className={classNames(styles.taskcol, styles.colTaskProgress)}>
            <ProgressBar
              width="100%"
              tot={100}
              fill={selectedProject && selectedProject.my_task_progress}
              wrpClass={styles.progWrp}
              innerClass={styles.progInner}
              conClass={styles.progCon}
              showPrecentage
              showTot={false}
              overViewTab
            />
          </div>
          <div className={classNames(styles.taskcol, styles.colRatedCompany)}>
            {
              selectedCouncil?.name === 'Ford'
                ? (
                  <>
                    {
                      isFordSupplyChainAutomation && (
                        <div className="pr-4">
                          <ProjectBudgetVsSpend budget={metrics.investment_usd} spend={metrics.current_spending_usd} />
                        </div>
                      )
                    }
                    {
                      !isFordSupplyChainAutomation && selectedProject?.themes?.length ? (
                        <>
                          {
                            selectedProject?.themes
                              .slice(0, 3)
                              .map(theme => (
                                <div onClick={() => handleThemeNavigation(theme)} className={styles.fordTheme} key={theme.theme_id}>
                                  {theme.name}
                                </div>
                              ))
                          }
                          {
                            selectedProject.themes.length > 3 ? (
                              <HtmlTooltip
                                interactive
                                title={(
                                  <>
                                    {
                                      selectedProject?.themes
                                        .map(theme => (
                                          <div onClick={() => handleThemeNavigation(theme)} className={styles.fordTheme} key={`${theme.theme_id}_popup`}>
                                            {theme.name}
                                          </div>
                                        ))
                                    }
                                  </>
                                )}
                                placement="top"
                              >
                                <div className={styles.showMore}>see more</div>
                              </HtmlTooltip>
                            ) : null
                          }
                        </>
                      ) : isFordSupplyChainAutomation ? null : '-'
                    }
                  </>
                )
                : (
                  <>
                    {selectedProject && selectedProject.companies_rated} of&nbsp;
                    {selectedProject && selectedProject.total_companies}
                  </>
                )
            }
          </div>
          <div className={classNames(styles.taskcol, styles.colfinalStage)}>
            <ProjectStage
              selectedProject={selectedProject}
              updateDirectly
              disabled={fordInstance ? !isAdmin : false}
            />
          </div>
        </div>
      </div>
    </Pannel>
  );
};

export default React.memo(SummeryBlock);

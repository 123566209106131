import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import styles from "./attendees.module.scss";
import { UserInline, UserInlineTypes, Pannel } from "../../../primitives";
import { useScrollPagination } from "../../../../common/hooks";

const AttendeesComponent = () => {
  const scrollPagination = useScrollPagination({ pageSize: 7 });

  const {
    eventsReducer: {
      viewEventReducer: { selectedEvent },
    },
  } = useSelector((state) => state);

  return (
    <>
      {selectedEvent.event_attendees &&
      selectedEvent.event_attendees?.length ? (
        <Pannel title="Attendees" padding="0px" className={styles.card}>
          <div className={styles.wrapper} onScroll={scrollPagination.onScroll}>
            {selectedEvent.event_attendees?.length &&
              selectedEvent.event_attendees.map((user, index) => (
                <div
                  className={styles.speaker}
                  key={index}
                >
                  <UserInline
                    local
                    first={user.user_full_name}
                    type={UserInlineTypes.WITH_NAME}
                    className={styles.name}
                    userId={user.user_id}
                    avatar={user.user_avatar}
                  />
                  <div className={classNames("caption", styles.title)}>
                    {user.title}
                  </div>
                </div>
              ))}
            {false && selectedEvent.event_attendees?.length >= 7 && (
              <div className={styles.seeAll}>
                {scrollPagination.getLoading("See all Attendees")}
              </div>
            )}
          </div>
        </Pannel>
      ) : null}
    </>
  );
};

export default AttendeesComponent;

import * as Yup from "yup";
import { objectType } from "../../../../modules/companies/company/details/details.constant";

export const validation = Yup.object().shape({
  // field_value: Yup.date(),
});

export const model = {
  type: objectType.date,
  field_values: "",
  title: "",
};

import React, { useEffect, useState } from "react";

import styles from './styles.module.scss'

const loaderTexts = ['Thinking', 'Gathering Data', 'Analyzing Results', 'Almost There']

const RandomAILoader = ({ size = 'small' }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(prev => {
        if (prev === 3) {
          return 0
        }

        return prev + 1;
      });
    }, 5000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  return (
    <div className="d-flex">
      <span className={`${styles.loaderText} ${styles[size]}`}>{loaderTexts[activeIndex]}</span>
      <div className={styles.loaderDots}>
        <span />
        <span />
        <span />
      </div>
    </div>
  )
}

export default React.memo(RandomAILoader);

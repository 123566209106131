import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useModelPopup } from "../../../../common/hooks";
import IdeaListSearch from "./ideaListSearch";
import styles from "./ideaListMainContent.module.scss";
import IdeaListViewLayout from "./ideaListViewLayout";
import IdeaGridViewLayout from "./ideaGridViewLayout";
import IdeaReportBlock from "./ideaReportBlock";
import AddNewIdea from "../../../../common/components/addIdea";
import { ideasListGet } from "../../idea.action";
import { BtnType, Button, Pagination } from "../../../primitives";
import { PAGINATION_DEFAULTS } from "./constants";
import IdeaLayoutSwitcher from "./ideaLayoutSwitcher";
import downloadTable from "../../../../common/components/downloadReport/generateDocHelper";

function IdeaListMainContent() {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [ideaLayout, setIdeaLayout] = useState("list");
  const [pageSetting, setPageSetting] = useState(PAGINATION_DEFAULTS);
  const [downloadIsActive, setDownloadIsActive] = useState(false);
  // const [openMenu, handleOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (downloadIsActive) {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }
  }, [downloadIsActive]);

  const {
    ideaReducer: { ideas, getIdeasListLoading, ideasPageMeta },
  } = useSelector((state) => state);

  const handleOnNewIdeaClick = () => {
    popup.show({
      show: true,
      width: "800",
      component: <AddNewIdea />,
    });
  };

  const handlePagingChange = (selectedPage) => {
    if (pageSetting.current === selectedPage) return;

    prepareRequestParams([["page", selectedPage]], false);
  };

  const handleReportFilter = (value) => {
    if (location.search) {
      const existingObjSearch = Object.fromEntries(
        new URLSearchParams(location.search)
      );

      if (existingObjSearch?.filter_value === value) {
        prepareRequestParams(
          [
            ["filter_by", ""],
            ["filter_value", ""],
          ],
          true
        );

        return;
      }
    }

    prepareRequestParams(
      [
        ["filter_by", "status"],
        ["filter_value", value],
      ],
      true
    );
  };

  const handleSortOptionSelect = ({ val }) => {
    prepareRequestParams(
      [
        ["sort_by", val],
        ["sort_order", "desc"],
      ],
      true
    );
  };

  const handleSortOptionChange = ({ sort_by, sort_order }) => {
    prepareRequestParams(
      [
        ["sort_by", sort_by],
        ["sort_order", sort_order],
      ],
      true
    );
  };

  const handleSearch = (value) => {
    prepareRequestParams([["query", value]], true);
  };

  const handleLayoutChange = (value) => {
    setIdeaLayout(value);
    prepareRequestParams([["layout", value]], true);
  };

  const prepareRequestParams = (params = [], resetPagination = false) => {
    if (params && params.length > 0) {
      const paramsAsObj = {
        ...Object.fromEntries(params),
      };

      history.push({
        pathname: "/ideas",
        search: new URLSearchParams({
          ...Object.fromEntries(new URLSearchParams(location.search)),
          ...paramsAsObj,
          page: resetPagination ? 1 : paramsAsObj.page || 1,
        }).toString(),
      });
    }
  };

  useEffect(() => {
    if (ideasPageMeta) {
      setPageSetting({
        ...pageSetting,
        current: ideasPageMeta.page,
        total: ideasPageMeta.total,
      });
    }
  }, [ideasPageMeta]);

  useEffect(() => {
    let reqQuery = {
      items: PAGINATION_DEFAULTS.pageSize,
      page: PAGINATION_DEFAULTS.current,
    };

    if (location.search) {
      reqQuery = {
        ...reqQuery,
        ...Object.fromEntries(new URLSearchParams(location.search)),
      };
    }

    if (reqQuery.layout) {
      setIdeaLayout(reqQuery.layout);
      delete reqQuery.layout;
    }

    const payload = {
      ...reqQuery,
      enqueueSnackbar,
    };

    if (reqQuery.filter_by) {
      payload[`${reqQuery.filter_by}`] = reqQuery.filter_value;
    }

    if (reqQuery.sort_by) {
      payload["sort_attribute"] = reqQuery.sort_by;
    }

    dispatch(ideasListGet(payload));
  }, [location.search]);

  const downloadDoc = (type) => {
    handleClose();
    let reqQuery = {
      sort_attribute: "",
      sort_by: "",
      sort_order: "",
      status: "",
    };
    if (location.search) {
      reqQuery = {
        ...reqQuery,
        ...Object.fromEntries(new URLSearchParams(location.search)),
      };
    }

    if (reqQuery.sort_by) {
      reqQuery.sort_attribute = reqQuery.sort_by;
    }
    if (reqQuery.filter_value) {
      reqQuery.status = reqQuery.filter_value;
    }
    downloadTable(
      type,
      false,
      false,
      handleClose,
      "ideas",
      { search: reqQuery },
      setDownloadIsActive
    );
  };

  return (
    <div
      className={`${styles.mainContainer} ${downloadIsActive ? "px-3" : ""}`}
      id="ideasListWrapper"
    >
      <div className={styles.searchContainer}>
        {!downloadIsActive && <IdeaListSearch onChange={handleSearch} />}
      </div>

      <div className="row py-4">
        <div className="col d-flex align-items-center">
          <h4 className="m-0">Ideas</h4>
        </div>
        <div className="col d-flex align-items-center justify-content-center">
          {downloadIsActive ? (
            <div className={styles.chartDownloadBadge}>
              <span className={styles.bolder}>Traction</span>
              <span className={styles.light}>Report</span>
            </div>
          ) : (
            <Button btn={BtnType.REGULAR} onClick={handleOnNewIdeaClick}>
              + Submit Idea
            </Button>
          )}
        </div>
        <div className="col d-flex justify-content-end">
          {!downloadIsActive && (
            <Button
              btn={BtnType.FRAME_LESS}
              className={styles.download}
              icon="icn-export-button"
              onClick={handleClick}
              id="demo-positioned-button"
              aria-controls={openMenu ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              disabled={downloadIsActive}
            >
              Download Report
            </Button>
          )}
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={() => downloadDoc("csv")}>DOWNLOAD CSV</MenuItem>
            <MenuItem onClick={() => downloadDoc("xlsx")}>
              DOWNLOAD EXCEL
            </MenuItem>
            <MenuItem onClick={() => downloadDoc("pdf")}>DOWNLOAD PDF</MenuItem>
          </Menu>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <IdeaReportBlock onItemClick={handleReportFilter} />
        </div>
      </div>

      <div className="row pt-4">
        <div className="col-6 d-flex align-items-center justify-content-start">
          <IdeaLayoutSwitcher
            activeLayout={ideaLayout}
            onLayoutChange={handleLayoutChange}
          />
        </div>
        {/*<div
          className="col-6 d-flex align-items-center justify-content-end"
        >
          <div className="d-flex align-items-center">
            <div className={styles.sortOptionsSelect}>
              <DropSelect
                onChange={handleSortOptionSelect}
                labelField="label"
                valueField="value"
                options={SORT_OPTIONS}
              />
            </div>
            <div className="ml-3">
              {ideasPageMeta?.total}
              {' '}
              Ideas
            </div>
          </div>
        </div>*/}
      </div>

      <div className="row pt-4">
        <div className="col-12">
          {ideaLayout === "grid" && <IdeaGridViewLayout />}

          {ideaLayout === "list" && (
            <IdeaListViewLayout onSortChange={handleSortOptionChange} />
          )}
        </div>
      </div>

      <div className="row pt-4">
        <div className="col d-flex justify-content-center align-items-center">
          {!getIdeasListLoading &&
            pageSetting?.total >= pageSetting.pageSize &&
            ideas.length > 0 && (
              <Pagination {...pageSetting} onChange={handlePagingChange} />
            )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(IdeaListMainContent);

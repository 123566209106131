import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { ModalBody } from "application/modal/index";
import Button from "@mui/material/Button";
import {
  setSfCustomFieldPayload,
  setTtpCustomFieldPayload,
} from "modules/admin/adminIntegrations/adminIntegrations.actions";
import { Formik, Form, FieldArray } from "formik";
import { httpGet } from "common/httpCall";
import Chip from "@mui/material/Chip";
import { DropSelect, BtnType, Button as Btn } from "../../primitives";
import styles from "./mapFieldValues.module.scss";
import { getCustomFieldsAttempt } from "../../admin/adminCustomFieldsGroups/adminCustomField.action";

const MapCustomFields = (props) => {
  const { preSelectedSfCustomFieldValues, preSelectedTtpCustomFieldValues } =
    props;
  const [selectedSfCustomFieldValues, setSelectedSfCustomFieldValues] =
    useState([]);
  const [selectedTtpCustomFieldValues, setSelectedTtpCustomFieldValues] =
    useState([]);
  const [isSelectedSfCustomFieldValue, setIsSelectedSfCustomFieldValue] =
    useState(true);
  const [isSelectedTtpCustomFieldValue, setIsSelectedTtpCustomFieldValue] =
    useState(true);
  const [selectedSfCustomFieldValue, setSelectedSfCustomFieldValue] = useState(
    {},
  );
  const [selectedTtpCustomFieldValue, setSelectedTtpCustomFieldValue] =
    useState({});
  const [loading, setLoading] = useState(false);

  const [isDropOpened, setIsDropOpened] = useState(true);
  const [typeMatch, setTypeMatch] = useState(null);
  const [selected] = useState(false);
  const [sfCustomFields, setSfCustomFields] = useState([]);
  const [ttpCustomFields, setTtpCustomFields] = useState([]);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    adminCustomFieldsReducer: { customFields },
  } = useSelector((state) => state);

  let boundArrayHelpers;

  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  useEffect(() => {
    httpGet({
      call: "sf_integrations/get_sf_custom_fields",
    })
      .pipe()
      .subscribe((res) => {
        setSfCustomFields(
          res.response.account_custom_fields.map((value) => ({
            text: value.name,
            value: (() => {
              switch (value.type) {
                case "picklist":
                  return ["dropdown", "checklist"];
                case "currency":
                case "double":
                case "string":
                case "date":
                case "phone":
                  return ["text"];
                default:
                  return [value.type];
              }
            })(),
          })),
        );
      });
  }, []);

  useEffect(() => {
    dispatch(
      getCustomFieldsAttempt({
        enqueueSnackbar,
        query: "",
        page: 1,
        pageSize: 150,
      }),
    );
  }, []);

  useEffect(() => {
    if (customFields) {
      setTtpCustomFields(
        customFields.map((value) => ({
          text: value.field_name,
          fieldType: value.field_type,
          value: value.custom_field_template_id,
        })),
      );
    }
  }, [customFields]);

  useEffect(() => {
    if (preSelectedSfCustomFieldValues) {
      setSelectedSfCustomFieldValues(preSelectedSfCustomFieldValues);
      setSelectedTtpCustomFieldValues(preSelectedTtpCustomFieldValues);
    }
    setLoading(true);
  }, []);

  useEffect(() => {
    dispatch(setSfCustomFieldPayload(selectedSfCustomFieldValues));
    dispatch(setTtpCustomFieldPayload(selectedTtpCustomFieldValues));
  }, [selectedSfCustomFieldValues, selectedTtpCustomFieldValues]);

  const initialValues = () =>
    Array(selectedSfCustomFieldValues.length).fill("");

  return (
    loading && (
      <>
        <ModalBody>
          <div className={styles.popupContainer}>
            <Formik
              enableReinitialize
              initialValues={{ friends: initialValues() }}
              render={({ values }) => (
                <Form>
                  <FieldArray
                    name="friends"
                    render={(arrayHelpers) => {
                      bindArrayHelpers(arrayHelpers);
                      return (
                        <div>
                          <div className={styles.submitContainer}>
                            {!selected && (
                              <div className={styles.submitBtn}>
                                <h3>Custom Field Mapping</h3>
                              </div>
                            )}
                          </div>
                          <div className={styles.fieldsConatiner}>
                            <>
                              <div className={styles.headTitle}>
                                <div className={styles.headerContainer}>
                                  <div className={styles.mapsTextHead}>
                                    Salesforce
                                  </div>
                                  {/* <Button className={styles.mapsButton}></Button> */}
                                  <div className={styles.mapsTextHead}>
                                    Traction
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                          <div className={styles.fieldsConatiner}>
                            {values.friends.map((friend, index) => (
                              <div>
                                {values.friends.length && (
                                  <>
                                    <div
                                      className={styles.headTitle}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "1rem",
                                        marginTop: "1rem",
                                      }}
                                    >
                                      <>
                                        <div className={styles.mapsText}>
                                          {
                                            selectedSfCustomFieldValues[index]
                                              ?.text
                                          }
                                        </div>
                                        <Button className={styles.mapsButton}>
                                          Maps
                                        </Button>
                                        <div className={styles.mapsText}>
                                          {
                                            selectedTtpCustomFieldValues[index]
                                              ?.text
                                          }
                                        </div>
                                        <Chip
                                          className={styles.chipButton}
                                          onDelete={() => {
                                            setSelectedTtpCustomFieldValues(
                                              selectedTtpCustomFieldValues.filter(
                                                (_, selectedIndex) =>
                                                  selectedIndex !== index,
                                              ),
                                            );
                                            setSelectedSfCustomFieldValues(
                                              selectedSfCustomFieldValues.filter(
                                                (_, selectedIndex) =>
                                                  selectedIndex !== index,
                                              ),
                                            );
                                          }}
                                        />
                                      </>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    }}
                  />
                  <div className={styles.fieldsConatiner}>
                    <div className={styles.headTitle}>
                      <>
                        {!selected && (
                          <div className={`row ${styles.dropDownContainer}`}>
                            {isDropOpened && (
                              <div className="col-5">
                                <DropSelect
                                  disabled={isSelectedTtpCustomFieldValue}
                                  placeholder="Select Salesforce Custom Field"
                                  labelField="text"
                                  valueField="value"
                                  searchBy="text"
                                  onChange={(event) => {
                                    setSelectedSfCustomFieldValue({
                                      text: event.obj[0].text,
                                      value: event.obj[0].value,
                                    });
                                    setIsSelectedSfCustomFieldValue(false);
                                  }}
                                  options={sfCustomFields.filter(
                                    (item) =>
                                      !selectedSfCustomFieldValues
                                        .map((value) => value.text)
                                        .includes(item.text) &&
                                      item.value.includes(typeMatch),
                                  )}
                                />
                              </div>
                            )}
                            {!isDropOpened && <div className="col-5" />}
                            <div className="col-5">
                              <DropSelect
                                placeholder="Select Traction Custom Field"
                                labelField="text"
                                valueField="value"
                                searchBy="text"
                                onDropdownOpen={() => setIsDropOpened(true)}
                                onChange={(event) => {
                                  setTypeMatch(event.obj[0].fieldType);
                                  setSelectedTtpCustomFieldValue({
                                    text: event.obj[0].text,
                                    value: event.obj[0].value,
                                  });
                                  setIsSelectedTtpCustomFieldValue(false);
                                }}
                                options={ttpCustomFields.filter(
                                  (item) =>
                                    !selectedTtpCustomFieldValues
                                      .map((value) => value.text)
                                      .includes(item.text),
                                )}
                              />
                            </div>
                            <div>
                              <Btn
                                btn={BtnType.REGULAR}
                                className={styles.addButton}
                                disabled={
                                  values.friends.length ===
                                    ttpCustomFields?.length ||
                                  isSelectedSfCustomFieldValue ||
                                  isSelectedTtpCustomFieldValue
                                }
                                onClick={() => {
                                  setSelectedTtpCustomFieldValues([
                                    ...selectedTtpCustomFieldValues,
                                    selectedTtpCustomFieldValue,
                                  ]);
                                  setSelectedSfCustomFieldValues([
                                    ...selectedSfCustomFieldValues,
                                    selectedSfCustomFieldValue,
                                  ]);
                                  setIsSelectedSfCustomFieldValue(true);
                                  setIsSelectedTtpCustomFieldValue(true);
                                  setIsDropOpened(false);
                                  boundArrayHelpers.insert(
                                    values.friends.length - 1,
                                    "",
                                  );
                                }}
                              >
                                +Add
                              </Btn>
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </ModalBody>
      </>
    )
  );
};

export default React.memo(MapCustomFields);

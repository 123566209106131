import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import {
  Button,
  BtnType,
  Label,
  TextBox,
  MultiSelect,
} from "../../../../primitives/index";
import { councilUsersListGet } from "../../../../../common/actions/common.action";
import { addRelationShip } from "../../company.action";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../common/hooks";
import {
  addCompanyContact,
  editCompanyContact,
} from "modules/people/externalPeople/companyContacts.action";
import { ModalFooter, ModalBody } from "../../../../../application/modal";
import { initModel, validation } from "./constants";
import { addProductContact, editProductContact } from "modules/companies/product/store/product.actions";

const NewItemComponent = ({
  data, type, cb, isClaimProfileUse, product, productInstance
}) => {
  const popup = useModelPopup();
  const [selectedItems, setSelectedItem] = useState([]);
  const [model, setModel] = useState(initModel);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();
  const [emailError, setEmailError] = useState(false);

  const {
    authReducer: { session },
    councilReducer: { usersList, selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (data) {
      setModel({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        title: data.title,
        mobile: data.mobile,
        department: data.department_or_organization,
      });
    }
  }, [data]);

  const handleCancelClick = () => {
    if (cb) {
      cb(true);
    }

    popup.hide();
  };

  const handleSaveClick = () => {
    const { values } = formRef.current;
    const noValuesBool = Object.keys(values).filter((k) => values[k]?.length)?.length === 0;

    if (!values.email) {
      setEmailError(true);
      return;
    }

    if (noValuesBool) {
      enqueueSnackbar("Please complete required fields.", {
        variant: "error",
      });
      return;
    }

    const company_id = isClaimProfileUse
      ? session?.invited_company?.id
      : selectedCompany.id;

    if (type === "relationship") {
      dispatch(
        addRelationShip({
          data: {
            user_ids: selectedItems.map((user) => user.id),
          },
          council_id: selectedCouncil.id,
          company_id,
          enqueueSnackbar,
        }),
      );
    }
    if (type === "contact") {
      // use just for product contacts
      if (productInstance) {
        if (data) {
          const payload = {
            data: {
              first_name: values.first_name,
              last_name: values.last_name,
              title: values.title,
              email: values.email,
              mobile: values.mobile,
              department_or_organization: values.department,
              avatar: values.avatar,
              council_id: selectedCouncil.id,
              company_id: product.company.id,
              council_product_id: product.id,
              id: data.id,
            },
            council_id: selectedCouncil.id,
            enqueueSnackbar,
            company_id: product.company.id,
          };

          dispatch(editProductContact(payload));
        } else {
          const payload = {
            data: {
              ...values,
              company_id: product.company.id,
              council_product_id: product.id,
              council_id: selectedCouncil.id,
            },
            council_id: selectedCouncil.id,
            enqueueSnackbar,
            company_id: product.company.id,
            cb: () => {
              if (cb) {
                cb(true);
              }
            },
          };

          dispatch(addProductContact(payload));
        }

        popup.hide();
        return;
      }
      // end product contacts

      if (data) {
        const payload = {
          data: {
            first_name: values.first_name,
            last_name: values.last_name,
            title: values.title,
            email: values.email,
            mobile: values.mobile,
            department_or_organization: values.department,
            avatar: values.avatar,
            // role: 'company',
            council_id: selectedCouncil.id,
            company_id,
          },
          enqueueSnackbar,
          company_contact_id: data.id,
          company_id,
          isClaimProfileUse,
        };

        dispatch(editCompanyContact(payload));
      } else {
        const payload = {
          data: {
            ...values,
            company_id,
            role: "company",
            council_id: selectedCouncil.id,
          },
          council_id: selectedCouncil.id,
          enqueueSnackbar,
          company_id,
          isClaimProfileUse,
          cb: () => {
            if (cb) {
              cb(true);
            }
          },
        };

        dispatch(addCompanyContact(payload));
      }
    }

    if (cb) {
      cb(true);
    }

    popup.hide();
  };

  const handleItemSelect = (val, obj) => {
    setSelectedItem((prev) => [...val]);
  };

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(councilUsersListGet(selectedCouncil.id, enqueueSnackbar));
    }
  }, [selectedCouncil]);

  return (
    <>
      <ModalBody>
        <div className={styles.wrapper}>
          <Formik
            innerRef={formRef}
            initialValues={model}
            enableReinitialize
            validationSchema={validation}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);

              handleSaveClick();
              resetForm();
            }}
          >
            {({ handleSubmit, handleChange, ...formikprops }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    {type === "relationship" && usersList && usersList[0] && (
                      <>
                        <Label>Contact</Label>
                        <MultiSelect
                          onChange={handleItemSelect}
                          placeholder="Add user"
                          labelField="name"
                          valueField="id"
                          searchBy="name"
                          options={usersList.map((user) => ({
                            id: user.id,
                            name: `${user.first_name} ${user.last_name}`,
                          }))}
                        />
                      </>
                    )}
                    {type !== "relationship" && (
                      <>
                        {" "}
                        <>
                          <Label>First Name</Label>
                          <TextBox
                            type="text"
                            name="first_name"
                            placeholder="Enter first name"
                            className={styles.textBox}
                            formProps={formikprops}
                            onChange={handleChange}
                          />
                        </>
                        <>
                          <Label>Last Name</Label>
                          <TextBox
                            type="text"
                            name="last_name"
                            placeholder="Enter last name"
                            className={styles.textBox}
                            formProps={formikprops}
                            onChange={handleChange}
                          />
                        </>
                        <>
                          <Label>
                            Email<span className={styles.errorText}>*</span>{" "}
                          </Label>
                          <TextBox
                            type="text"
                            name="email"
                            placeholder="Enter email address"
                            className={styles.textBox}
                            formProps={formikprops}
                            onChange={handleChange}
                            disabled={data?.invited_at || data?.claimed_profile}
                            required
                          />
                          {emailError && (
                            <span className={styles.error}>
                              Email is required
                            </span>
                          )}
                        </>
                        <>
                          <Label>Phone</Label>
                          <TextBox
                            type="text"
                            name="mobile"
                            placeholder="Optional"
                            className={styles.textBox}
                            formProps={formikprops}
                            onChange={handleChange}
                          />
                        </>
                        <>
                          <Label>Department</Label>
                          <TextBox
                            type="text"
                            name="department"
                            placeholder="Enter department"
                            className={styles.textBox}
                            formProps={formikprops}
                            onChange={handleChange}
                          />
                        </>
                        <>
                          <Label>Title</Label>
                          <TextBox
                            type="text"
                            name="title"
                            placeholder="Enter title"
                            className={styles.textBox}
                            formProps={formikprops}
                            onChange={handleChange}
                          />
                        </>
                      </>
                    )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button onClick={handleSaveClick} btn={BtnType.REGULAR}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
};

export default React.memo(NewItemComponent);

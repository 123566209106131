export const GET_CUSTOM_FIELDS_ATTEMPT = "GET_CUSTOM_FIELDS_ATTEMPT";
export const getCustomFieldsAttempt = (payload) => ({
  type: GET_CUSTOM_FIELDS_ATTEMPT,
  payload,
});

export const GET_CUSTOM_FIELDS_SUCCESS = "GET_CUSTOM_FIELDS_SUCCESS";
export const getCustomFieldsSuccess = (payload) => ({
  type: GET_CUSTOM_FIELDS_SUCCESS,
  payload,
});

export const GET_SEARCH_CUSTOM_FIELDS_ATTEMPT = "GET_SEARCH_CUSTOM_FIELDS_ATTEMPT";
export const getSearchCustomFieldsAttempt = (payload) => ({
  type: GET_SEARCH_CUSTOM_FIELDS_ATTEMPT,
  payload,
});

export const GET_SEARCH_CUSTOM_FIELDS_SUCCESS = "GET_SEARCH_CUSTOM_FIELDS_SUCCESS";
export const getSearchCustomFieldsSuccess = (payload) => ({
  type: GET_SEARCH_CUSTOM_FIELDS_SUCCESS,
  payload,
});
export const GET_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT =
  "GET_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT";
export const getCustomFieldAgreementType = (payload) => ({
  type: GET_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT,
  payload,
});

export const GET_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS =
  "GET_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS";
export const getCustomFieldAgreementTypeSuccess = (payload) => ({
  type: GET_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS,
  payload,
});

export const POST_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT =
  "POST_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT";
export const postCustomFieldAgreementType = (payload) => ({
  type: POST_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT,
  payload,
});

export const POST_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS =
  "POST_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS";
export const postCustomFieldAgreementTypeSuccess = (payload) => ({
  type: POST_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS,
  payload,
});

export const PUT_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT =
  "PUT_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT";
export const putCustomFieldAgreementType = (payload) => ({
  type: PUT_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT,
  payload,
});

export const PUT_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS =
  "PUT_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS";
export const putCustomFieldAgreementTypeSuccess = (payload) => ({
  type: PUT_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS,
  payload,
});

export const DELETE_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT =
  "DELETE_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT";
export const deleteCustomFieldAgreementType = (payload) => ({
  type: DELETE_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT,
  payload,
});

export const DELETE_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS =
  "DELETE_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS";
export const deleteCustomFieldAgreementTypeSuccess = (payload) => ({
  type: DELETE_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS,
  payload,
});


export const POST_CUSTOM_FIELD_SUCCESS = "POST_CUSTOM_FIELD_SUCCESS";
export const postCustomFieldsSuccess = (payload) => ({
  type: POST_CUSTOM_FIELD_SUCCESS,
  payload,
});

export const POST_CUSTOM_FIELD_ATTEMPT = "POST_CUSTOM_FIELD_ATTEMPT";
export const postCustomFieldsAttempt = (payload) => ({
  type: POST_CUSTOM_FIELD_ATTEMPT,
  payload,
});

export const PUT_CUSTOM_FIELD_SUCCESS = "PUT_CUSTOM_FIELD_SUCCESS";
export const putCustomFieldsSuccess = (payload) => ({
  type: PUT_CUSTOM_FIELD_SUCCESS,
  payload,
});

export const PUT_CUSTOM_FIELD_ATTEMPT = "PUT_CUSTOM_FIELD_ATTEMPT";
export const putCustomFieldsAttempt = (payload) => ({
  type: PUT_CUSTOM_FIELD_ATTEMPT,
  payload,
});

export const DELETE_CUSTOM_FIELD_SUCCESS = "DELETE_CUSTOM_FIELD_SUCCESS";
export const deleteCustomFieldsSuccess = (payload) => ({
  type: DELETE_CUSTOM_FIELD_SUCCESS,
  payload,
});

export const DELETE_CUSTOM_FIELD_ATTEMPT = "DELETE_CUSTOM_FIELD_ATTEMPT";
export const deleteCustomFieldsAttempt = (payload) => ({
  type: DELETE_CUSTOM_FIELD_ATTEMPT,
  payload,
});

// groups==========>>>>

export const GET_CUSTOM_FIELDS_GROUPS_SUCCESS = "GET_CUSTOM_FIELDS_GROUPS_SUCCESS";
export const getCustomFieldsGroupsSuccess = (payload) => ({
  type: GET_CUSTOM_FIELDS_GROUPS_SUCCESS,
  payload,
});

export const GET_CUSTOM_FIELDS_GROUPS_ATTEMPT = "GET_CUSTOM_FIELDS_GROUPS_ATTEMPT";
export const getCustomFieldsGroupsAttempt = (payload) => ({
  type: GET_CUSTOM_FIELDS_GROUPS_ATTEMPT,
  payload,
});

export const POST_CUSTOM_FIELD_GROUPS_SUCCESS = "POST_CUSTOM_FIELD_GROUPS_SUCCESS";
export const postCustomFieldsGroupsSuccess = (payload) => ({
  type: POST_CUSTOM_FIELD_GROUPS_SUCCESS,
  payload,
});

export const POST_CUSTOM_FIELD_GROUPS_ATTEMPT = "POST_CUSTOM_FIELD_GROUPS_ATTEMPT";
export const postCustomFieldsGroupsAttempt = (payload) => ({
  type: POST_CUSTOM_FIELD_GROUPS_ATTEMPT,
  payload,
});

export const PUT_CUSTOM_FIELD_GROUPS_SUCCESS = "PUT_CUSTOM_FIELD_GROUPS_SUCCESS";
export const putCustomFieldsGroupsSuccess = (payload) => ({
  type: PUT_CUSTOM_FIELD_GROUPS_SUCCESS,
  payload,
});

export const PUT_CUSTOM_FIELD_GROUPS_ATTEMPT = "PUT_CUSTOM_FIELD_GROUPS_ATTEMPT";
export const putCustomFieldsGroupsAttempt = (payload) => ({
  type: PUT_CUSTOM_FIELD_GROUPS_ATTEMPT,
  payload,
});

export const DELETE_CUSTOM_FIELD_GROUPS_SUCCESS = "DELETE_CUSTOM_FIELD_GROUPS_SUCCESS";
export const deleteCustomFieldsGroupsSuccess = (payload) => ({
  type: DELETE_CUSTOM_FIELD_GROUPS_SUCCESS,
  payload,
});

export const DELETE_CUSTOM_FIELD_GROUPS_ATTEMPT = "DELETE_CUSTOM_FIELD_GROUPS_ATTEMPT";
export const deleteCustomFieldsGroupsAttempt = (payload) => ({
  type: DELETE_CUSTOM_FIELD_GROUPS_ATTEMPT,
  payload,
});

export const GET_CUSTOM_FIELD_GROUP_TEMPLATES_ATTEMPT = "GET_CUSTOM_FIELD_GROUP_TEMPLATES_ATTEMPT";
export const getCustomFieldGroupTemplatesAttempt = (payload) => ({
  type: GET_CUSTOM_FIELD_GROUP_TEMPLATES_ATTEMPT,
  payload,
});

export const GET_CUSTOM_FIELD_GROUP_TEMPLATES_SUCCESS = "GET_CUSTOM_FIELD_GROUP_TEMPLATES_SUCCESS";
export const getCustomFieldGroupTemplatesSuccess = (payload) => ({
  type: GET_CUSTOM_FIELD_GROUP_TEMPLATES_SUCCESS,
  payload,
});

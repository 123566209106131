import { combineReducers } from "redux";

/* eslint-disable import/no-named-as-default,import/no-named-as-default-member */
import ideaReducer from "../modules/ideasWrapper/idea.reducer";
import tractionCompaniesReducer from "../modules/tractionAdmin/companies/tractionCompanies.reducer";
import adminCustomFieldsReducer from "../modules/admin/adminCustomFieldsGroups/adminCustomFields.reducer";
import adminMembersReducer from "../modules/admin/adminMembers/adminMembers.reducer";
import adminIntegrationsReducer from "../modules/admin/adminIntegrations/adminIntegrations.reducer";
import appReducer from "../application/app.reducer";
import authReducer from "../modules/auth/auth.reducer";
import councilReducer from "../modules/council/council.reducer";
import projectsReducer from "../modules/projects/projects.reducer";
import peopleReducer from "../modules/people/people.reducer";
import companiesReducer from "../modules/companies/companies.reducer";
import eventsReducer from "../modules/events/events.reducer";
import settingsReducer from "../modules/settings/settings.reducer";
import suggestionsReducer from "../common/reducers/suggestions.reducer";
import searchReducer from "../common/reducers/search.reducer";
import homeReducer from "../modules/home/home.reducer";
import discussionsReducer from "../common/reducers/discussions.reducer";
import membersReducer from "../modules/members/members.reducer";
import patientOrganizationsReducer from "../modules/patientOrganizations/patientOrganizations.reducer";
import documentsReducer from "../common/reducers/documents.reducer";
import adminRatingTemplatesReducer from "../modules/admin/adminRating/adminRatingTemplates.reducer";
import adminProjectsReducer from "../modules/admin/adminProjects/adminProjects.reducer";
import adminTopicsReducer from "../modules/admin/adminTags/adminTopics.reducer";
import adminApplicantsReducer from "../modules/admin/adminApplicants/adminApplicants.reducer";
import adminApplicantsFormsReducer from "../modules/admin/adminApplicants/forms/adminApplicantForms.reducer";
import adminIdeasReducer from "../modules/admin/adminIdeas/adminIdeas.reducer";
import teamsReducer from "../modules/teams/teams.reducer";
import claimProfileReducer from "../modules/claimProfile/store/claimProfileReducer";
import listsMainReducer from "../modules/lists/store/lists.reducer";
import adminAgreementsReducer from "../modules/admin/adminAgreements/adminAgreement.reducer";
import reportingReducer from "../modules/reporting/store/reporting.reducer";
import themesReducer from "../modules/themes/themes.reducer";
import councilsReducer from "../modules/tractionAdmin/councils/councils.reducer";
import productReducer from "modules/companies/product/store/product.reducer";
import solutionsReducer from "modules/solutions/solutions.reducer";
import adminPlantsAndAreasReducer from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.reducer";
import fordDashboardReducer from "modules/reporting/fordReports/store/fordReports.reducer";
import tractionAiReducer from "modules/tractionAI/store/tractionAI.reducer";
import newProjectV3Reducer from "modules/projects/newProjectv3/newProjectV3.reducer";

const rootReducer = combineReducers({
  appReducer,
  authReducer,
  councilReducer,
  projectsReducer,
  peopleReducer,
  companiesReducer,
  eventsReducer,
  settingsReducer,
  suggestionsReducer,
  homeReducer,
  discussionsReducer,
  membersReducer,
  patientOrganizationsReducer,
  searchReducer,
  documentsReducer,
  adminRatingTemplatesReducer,
  adminProjectsReducer,
  adminTopicsReducer,
  adminApplicantsReducer,
  adminApplicantsFormsReducer,
  adminIntegrationsReducer,
  ideaReducer,
  adminIdeasReducer,
  teamsReducer,
  tractionCompaniesReducer,
  adminCustomFieldsReducer,
  adminMembersReducer,
  claimProfileReducer,
  listsMainReducer,
  adminAgreementsReducer,
  reportingReducer,
  themesReducer,
  councilsReducer,
  productReducer,
  solutionsReducer,
  adminPlantsAndAreasReducer,
  fordDashboardReducer,
  tractionAiReducer,
  newProjectV3Reducer,
});

export default rootReducer;

import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  BtnType,
  Button,
  Loading,
  MoreOptions,
  OptionType,
  TextBox,
} from "modules/primitives/index";
import ReactTable from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { CSVLink } from "react-csv";
import styles from "./adminCustomfields.module.scss";
import ConfirmDeleteComponent from "../../../common/components/confirmDelete";
import { httpGet } from "../../../common/httpCall";
import { useModelPopup } from "../../../common/hooks";
import AdminCustomFieldGroupsModal from "./adminCustomFieldGroupModal/adminCustomFieldGroupsModal.component";
import { getAllTeamsAttempt } from "../../teams/teams.action";
import { getCustomFieldsGroupsAttempt } from "./adminCustomField.action";

let timeDelaySearchGroups;

const AdminCustomFieldsGroup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [exportLoading, setExportLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [tableKey, setTableKey] = useState(Math.random());
  const [searchValue, setSearchValue] = useState("");
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    adminApplicantsReducer: { applicantList, searchResults, listLoading },
    adminCustomFieldsReducer: {
      customFieldGroups,
      loadingGroups,
      customFieldGroupsPageNr,
      shouldUpdate,
    },
  } = useSelector((state) => state);

  const pageSizeOptions = [pageSize, 5, 10, 20, 50, 100];

  const handleAddGroupModal = (e) => {
    popup.show({
      title: "Add item",
      show: true,
      height: "300",
      width: "540",
      component: <AdminCustomFieldGroupsModal mode="add" />,
    });
  };

  const moreOptions = [
    { val: "edit", text: "Edit" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const handleMoreOptionClick = (event, row) => {
    if (event === "edit") {
      popup.show({
        title: "Add item",
        show: true,
        height: "300",
        width: "540",
        component: <AdminCustomFieldGroupsModal data={row} mode="edit" />,
      });
    }

    if (event === "delete") {
      popup.show({
        title: "Confirmation Delete",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={row?.custom_field_group_id}
            enqueueSnackbar={enqueueSnackbar}
            councilId={selectedCouncil?.id}
            actionType="deleteCustomFieldGroup"
            customData={session?.integrated_tokens}
          />
        ),
      });
    }
  };

  const handleExportList = () => {
    setExportLoading(true);
    httpGet({
      call: `custom_field_groups?items=${customFieldGroupsPageNr?.total}`,
      data: {
        query: "*",
      },
    }).subscribe(
      async (res) => {
        setExportLoading(false);
        setCsvData(() => {
          const csvDataCont = [
            ["Group name", "Description", "Position", "Access"],
          ];

          res.response.map((item) => {
            csvDataCont.push([
              item?.name,
              item?.description,
              item?.position,
              item?.access || "-",
            ]);
          });
          csvDataCont.push(applicantList);

          return csvDataCont;
        });
        csvLinkRef.current.link.click();
      },
      (err) => {
        setExportLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      },

      () => {
        setExportLoading(false);
        enqueueSnackbar("List succesfully exported.", {
          variant: "success",
        });
      }
    );
  };

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
  };

  const handlePageSelect = (e) => {
    setPage(e.target.value);
  };

  const handleNextClick = () => {
    setPage(page + 1);
  };

  const handlePrevClick = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const isNextDisabled = () => {
    if (page + 1 > customFieldGroupsPageNr?.pages) {
      return styles.disabledButton;
    }
    return styles.paginationButton;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Group Name",
        accessor: "name",
        sortable: true,
      },
      {
        Header: "Access",
        id: "access",
        accessor: (row) => (
          <span>
            {row?.teams?.length || (row?.public && row?.teams?.length)
              ? "Team"
              : Boolean(row?.public) && "All"}
            {Boolean(row?.users?.length) && "Individual"}
          </span>
        ),
        style: {
          display: "flex",
          justifyContent: "center",
        },
        sortable: false,
      },
      {
        Header: "Format",
        id: "view_type",
        accessor: (row) => (
          <span>{row.view_type === "table" ? "Table" : "Full-Width"}</span>
        ),
        style: {
          display: "flex",
          justifyContent: "center",
        },
        sortable: false,
      },
      {
        Header: "Position",
        accessor: "position",
        sortable: true,
        sortMethod: (a, b) => {
          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        },
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Options",
        id: "options",
        width: 100,
        sortable: false,
        accessor: (row) => (
          <MoreOptions
            options={
              row.name === "Requested Information"
                ? moreOptions.filter((o) => o.val !== "delete")
                : moreOptions
            }
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row);
            }}
          />
        ),
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [customFieldGroups]
  );

  useEffect(() => {
    dispatch(getAllTeamsAttempt({ enqueueSnackbar }));
  }, [session]);

  useEffect(() => {
    dispatch(
      getCustomFieldsGroupsAttempt({
        enqueueSnackbar,
        query: searchValue,
        page,
        pageSize: 20,
      })
    );
  }, [pageSize, page, shouldUpdate]);

  useEffect(() => {
    const data =
      searchResults && searchResults[0] ? searchResults : applicantList;
    setTableData(data);
    setTableKey(Math.random());
  }, [applicantList, searchResults, JSON.stringify(applicantList)]);

  useEffect(() => {
    dispatch(
      getCustomFieldsGroupsAttempt({
        enqueueSnackbar,
        query: "",
        page: 1,
        pageSize: 20,
      })
    );
  }, []);

  const searchWithDelay = (value, withoutDelay = false) => {
    window.clearTimeout(timeDelaySearchGroups);
    if (!withoutDelay) {
      timeDelaySearchGroups = setTimeout(() => {
        dispatch(
          getCustomFieldsGroupsAttempt({
            enqueueSnackbar,
            query: value,
            page: 1,
            pageSize: 20,
          })
        );
      }, 1000);
    } else {
      dispatch(
        getCustomFieldsGroupsAttempt({
          enqueueSnackbar,
          query: value,
          page: 1,
          pageSize: 20,
        })
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <a href="/admin" className={styles.rootText}>
            ADMIN
          </a>
          <span className={styles.separationBar}> / </span>
          <a href="/admin/custom-fields" className={styles.rootText}>
            CUSTOM FIELDS
          </a>
          <span className={styles.separationBar}> / </span>
          <span className={`${styles.rootText} ${styles.activeBreadcrumbs}`}>
            CUSTOM FIELD GROUPS
          </span>
        </h4>
      </div>
      <div className={styles.flexWrapper}>
        <div className={styles.headerBlock}>
          <div className={styles.createFormButton}>
            <Button
              btn={BtnType.REGULAR}
              icon="icn-add"
              className={styles.buttonForm}
              onClick={() => handleAddGroupModal()}
            >
              Group
            </Button>
          </div>

          <div className={styles.searchBar}>
            <TextBox
              placeholder="Search"
              className={styles.searchField}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e?.target?.value);
                searchWithDelay(e?.target?.value);
              }}
            />
            <Button
              btn={BtnType.REGULAR}
              className={styles.searchButton}
              onClick={() => searchWithDelay(searchValue, true)}
            >
              Search
            </Button>
          </div>
        </div>

        <div className={styles.infoRow}>
          <div className={styles.infoBlock}>
            <span>
              Display Custom Fields together in different Groups. Custom Field
              Groups will appear in all Company profiles under the Details tab.
            </span>
          </div>
        </div>

        <div className={styles.applicantsWrp}>
          <div className={styles.buttonWrp}>
            {exportLoading && <Loading />}

            {!exportLoading && (
              <div>
                <Button
                  className={
                    customFieldGroupsPageNr.total <= 0 && styles.disabledBtn
                  }
                  onClick={() =>
                    customFieldGroupsPageNr.total > 0 && handleExportList()
                  }
                  btn={
                    customFieldGroupsPageNr.total
                      ? BtnType.OUTLINE
                      : BtnType.DISABLED
                  }
                  disabled={customFieldGroupsPageNr.total <= 0}
                >
                  EXPORT
                </Button>
                <CSVLink
                  data={csvData}
                  filename="Custom field groups.csv"
                  style={{ display: "none" }}
                  ref={csvLinkRef}
                />
              </div>
            )}
            <div className={styles.totalCount}>
              {customFieldGroupsPageNr.total || "0"} Groups
            </div>
          </div>
          <ReactTable
            // key={tableKey}
            data={customFieldGroups}
            columns={columns}
            loading={loadingGroups}
            className={styles.table}
            showPagination={false}
            pageSize={pageSize}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: () => {
                    history.push(
                      `/admin/custom-fields/custom-field-groups/${rowInfo?.original?.name}/${rowInfo?.original?.custom_field_group_id}`
                    );
                  },
                  onMouseEnter: () => {
                    setHoveredRow(rowInfo.index);
                  },
                  onMouseLeave: () => {
                    setHoveredRow(null);
                  },
                  style: {
                    cursor: "pointer",
                    background:
                      rowInfo.index === hoveredRow ? "#efefef" : "#efefef00",
                  },
                };
              }
              return {};
            }}
          />
          <div className={styles.pagination}>
            <div
              className={
                page - 1 === 0 ? styles.disabledButton : styles.paginationButton
              }
              onClick={handlePrevClick}
            >
              Previous
            </div>
            <div className={styles.paginationSettings}>
              <span style={styles.pageNr}>
                <span>
                  <input
                    onChange={(e) => handlePageSelect(e)}
                    value={page}
                    className={styles.inputBox}
                    type="number"
                    min="1"
                  />
                </span>
                <span>of</span>
                <span> {customFieldGroupsPageNr?.pages}</span>
              </span>

              <span>
                <select onChange={handleChangePageSize}>
                  {pageSizeOptions.map((option, i) => (
                    <option
                      value={option}
                      key={`applicant-page-option-${option}-${i}`}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </span>
            </div>
            <button
              className={isNextDisabled()}
              onClick={handleNextClick}
              disabled={page + 1 > customFieldGroupsPageNr?.pages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCustomFieldsGroup;

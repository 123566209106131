import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { ModalFooter, ModalBody } from "../../../../../application/modal";
import {
  Button,
  BtnType,
  Label,
  MultiSelect,
  Loading,
} from "../../../../primitives";
import styles from "./styles.module.scss";
import {
  themesIdeasCreateAttempt,
  themesIdeasGetAttempt,
} from "../overviewTab.action";

import { useModelPopup } from "../../../../../common/hooks";

const NewRelatedIdeaComponent = () => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [options, setOptions] = useState([]);
  const formRef = useRef();
  const [ideasFromTheme, setIdeasFromTheme] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState("");
  const [currentQuery, setQuery] = useState("");

  const {
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { currentThemeData },
      themesOverviewReducer: {
        loadingModalIdeas,
        modalThemeIdeas,
        themeIdeasList,
      },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil && debouncedValue === "") {
      dispatch(
        themesIdeasGetAttempt({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
          data: {
            items: 20,
            page: 1,
            query: "",
          },
        })
      );
    }
  }, [selectedCouncil]);

  useEffect(() => {
    dispatch(
      themesIdeasGetAttempt({
        councilId: selectedCouncil.id,
        enqueueSnackbar,
        data: {
          items: 20,
          page: 1,
          query: debouncedValue,
        },
      })
    );
  }, [debouncedValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(currentQuery);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [currentQuery]);

  useEffect(() => {
    if (themeIdeasList.length) {
      setIdeasFromTheme(themeIdeasList);
    } else {
      setIdeasFromTheme(currentThemeData?.ideas);
    }
  }, [themeIdeasList]);
  useEffect(() => {
    if (!loadingModalIdeas && modalThemeIdeas.length) {
      const filtredOptions = modalThemeIdeas?.filter(
        (modalIdea) =>
          !ideasFromTheme?.find(
            (themeIdea) => modalIdea?.id === themeIdea?.idea_id
          )
      );
      setOptions(filtredOptions);
    }
  }, [loadingModalIdeas, modalThemeIdeas]);

  const handleCancelClick = (e) => {
    popup.hide();
  };

  const handleSaveClick = (e) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleOnSubmit = (value) => {
    if (value.idea === undefined) {
      setError(true);
      return;
    }
    dispatch(
      themesIdeasCreateAttempt({
        themeId: currentThemeData.id,
        data: {
          theme_idea: [
            {
              idea_id: value.idea[0].id,
            },
          ],
        },
        enqueueSnackbar,
      })
    );
    setError(false);
    popup.hide();
  };

  return (
    <>
      <ModalBody>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{ id: "" }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleOnSubmit(values);
          }}
        >
          {({ handleSubmit, setFieldValue, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.customPadding}>
                <Label>Ideas</Label>
                <div className={styles.selectorWrp}>
                  <MultiSelect
                    onChange={(val) => {
                      setFieldValue("idea", val);
                      setError(false);
                    }}
                    placeholder="Choose idea"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    options={options}
                    // formProps={formikprops}
                    searchFn={(args) => {
                      if (args.state.search === currentQuery) {
                        return;
                      }
                      setQuery(args.state.search);
                    }}
                    multi={false}
                  />
                  <div className={styles.loaderWrp}>
                    {loadingModalIdeas && <Loading hideString />}
                  </div>
                </div>

                <span className={styles.error}>
                  {error && "Select at least one idea!"}
                </span>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button btn={BtnType.REGULAR} onClick={handleSaveClick}>
          Add ideas
        </Button>
      </ModalFooter>
    </>
  );
};

export default NewRelatedIdeaComponent;

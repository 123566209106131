import React, { useMemo } from "react";
import DropDownNestedElements from "./dropdownNestedElements";
import Select from "react-dropdown-select";
import styles from "./form-generator.module.scss";

const SelectInput = ({
  field,
  formikprops,
  dropdownPosition,
  isAreaOfFocusField,
}) => {
  const {
    id,
    section_name: label,
    placeholder,
    section_option_values: optionValues,
    multiselect: allowMultiselect,
    childKey,
    tagKeyName,
    parentKeyName,
  } = field;

  const { setFieldValue, errors, touched } = formikprops;
  const isInvalid = errors[id] && touched[id];

  const formattedOptions = isAreaOfFocusField
    ? optionValues
    : optionValues.map((option) => ({
        label: option,
        value: option,
      }));

  const handleChange = (values) => {
    if (isAreaOfFocusField) {
      const prevValues = (formikprops && formikprops.values[id]) || [];
      setFieldValue(id, [...prevValues, values]);
      return;
    }
    setFieldValue(
      id,
      values.map(({ value }) => value)
    );
  };

  const tags = useMemo(() => {
    if (formikprops && formikprops.values && formikprops.values[id]) {
      return [...new Set(formikprops.values[id] || [])];
    }

    return []
  }, [formikprops, id])

  return (
    <div className={styles.formGroup} key={`field-${id}`}>
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      {isAreaOfFocusField ? (
        <DropDownNestedElements
          tags={formattedOptions}
          selectedTags={tags}
          childKey={childKey}
          label={placeholder}
          tagKeyName={tagKeyName}
          parentKeyName={parentKeyName}
          handleTagSelect={(arg) => {
            handleChange(arg);
          }}
          handleTagRemove={(arg) => {
            setFieldValue(id, [...new Set(arg.map((tag) => tag.id))]);
          }}
          // selectedIsObject
        />
      ) : (
        <Select
          id={id}
          name={id}
          multi={allowMultiselect}
          options={formattedOptions}
          onChange={handleChange}
          values={[]}
          placeholder={placeholder}
          dropdownPosition={dropdownPosition || "auto"}
        />
      )}
      {isInvalid && <div className={styles.invalidFeedback}>{errors[id]}</div>}
    </div>
  );
};

export default SelectInput;

import React from "react";
import styles from "modules/terms/terms.module.scss";

const HomeXTerms = () => (
  <div className={styles.termsContainer}>
    <div className="row">
      <h2 className={styles.header}>HOMEX PORTAL TERMS AND CONDITIONS</h2>
    </div>
    <div>
      PLEASE READ THE FOLLOWING HOMEX PORTAL TERMS (“Terms and Conditions”) AND CONDITIONS CAREFULLY AS THEY GOVERN YOUR
      (“Company”, “you”, “your”) USE OF THE FREDDIE MAC HOMEX PORTAL (“HOMEX PORTAL”). BY USING THE HOMEX PORTAL,
      YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS,
      YOU ARE NOT AUTHORIZED TO ACCESS OR OTHERWISE USE THE HOMEX PORTAL.
    </div>

    <div className={styles.sectionWrp}>
      By agreeing to these Terms and Conditions you represent and warrant that you are authorized to do so by
      your employer and that Company agrees to be bound by these Terms and Conditions.
      We may modify these Terms and Conditions from time to time.
      We will notify you of such changes by posting the updated Terms and Conditions on the HOMEX PORTAL.
      You can determine when we last changed these Terms and Conditions by referring to the “LAST UPDATED”
      legend at the end of this document. Your use of a HOMEX PORTAL following the posting of the modified
      Terms and Conditions will constitute your acceptance of those changes.
    </div>

    <div className={styles.sectionWrp}>
      Each user of the HOMEX PORTAL agrees as follows:
    </div>

    <div className={styles.sectionWrp}>
      No Confidentiality; Suggestion
    </div>

    <div className={styles.sectionWrp}>
      You have requested to access and use the Freddie Mac HOMEX PORTAL, which may include providing information,
      documents, data and/or other materials to the Federal Home Loan Mortgage Corporation (“Freddie Mac”)
      and may also include discussions with Freddie Mac personnel regarding the foregoing
      and other matters (“Discussions”).
    </div>

    <div className={styles.sectionWrp}>
      In consideration of Freddie Mac’s agreement to receive information from Company through the HOMEX PORTAL and
      potentially engage in Discussions with Company, Company agrees that, except as otherwise agreed by Freddie Mac
      and Company in writing, (i) Freddie Mac will not be obligated to treat any information, ideas, data,
      documentation or material that is provided to or discussed with Freddie Mac by or on behalf of Company
      (the “Information”) as proprietary to Company or confidential, even if the Information is specifically marked
      or otherwise designated as proprietary or confidential, and (ii) Freddie Mac will have the right to use the
      Information in such manner as it sees fit, without compensation or other); obligation to Company.
      By transmitting any Information to Freddie Mac, you represent and warrant that you have all rights necessary
      to convey the Information to Freddie Mac and to grant Freddie Mac the use rights granted above.
    </div>

    <div className={styles.sectionWrp}>
      To the extent you or your Company provide comments, suggestions, information and other feedback to
      Freddie Mac concerning Freddie Mac’s products or services, or ideas for new products or services, (“Suggestions”),
      all Suggestions are provided and received on a purely voluntary basis and will not create any confidentiality or
      other obligation on the part of Freddie Mac. Freddie Mac will have the right to use and disclose Suggestions in
      such manner as it elects in its sole discretion, without obligation of any kind to you or your Company.
    </div>

    <div className={styles.sectionWrp}>
      No Business Relationship; No Exclusivity
    </div>

    <div className={styles.sectionWrp}>
      Access and use of the HOMEX PORTAL does not create any business relationship between Freddie Mac and you or your
      Company (other than your agreement to these Terms and Conditions). Nothing herein will restrict the right of
      Freddie Mac, as a recipient of the Information, to develop, use or market products or services similar to or
      competitive with those contained in or contemplated by the Information. You acknowledge that Freddie Mac may
      already possess information about or have developed products or services similar to or competitive with those
      developed, marketed or sold by you or your Company, or as otherwise disclosed in the Information.
    </div>

    <div className={styles.sectionWrp}>
      By using the HOMEX PORTAL, engaging in Discussions and/or providing Suggestions, you acknowledge that Freddie Mac
      is under no obligation to engage in Discussions, review the Information provided, or otherwise engage with users
      of the HOMEX PORTAL. Users of the HOMEX PORTAL have no expectation or guarantee of any further business
      relationship with Freddie Mac.
    </div>

    <div className={styles.sectionWrp}>
      User Registration and Account Creation, User Name ID and Password Security
      If the HOMEX PORTAL provides for registration or creation of a user account and you wish to register or create a
      user account, you agree that you will provide Freddie Mac with accurate, truthful, and current registration
      information and that you will maintain and update such information regularly. Freddie Mac may reject, or require
      that you change, any user
      name or password that you provide to us when registering. Freddie Mac has no obligation to verify the authenticity
      of any registration information, but reserves the right to refuse to create your account for any reason. You are
      solely responsible for maintaining the confidentiality of your registration information and passwords. Freddie Mac
      has the right to rely upon any information received from any person using a password assigned to you and will
      incur no liability arising out of such reliance. You agree to notify Freddie Mac immediately in the event of any
      loss or theft of your user name or password. Where applicable, Freddie Mac will handle any information that you
      submit to a Freddie Mac web site in accordance with the web site's Privacy Policy.
    </div>

    <div className="row mt-5">
      <h2 className={styles.header}>Use of HOMEX PORTAL</h2>
    </div>

    <div className={styles.sectionWrp}>
      The HOMEX PORTAL contains [functionality and other interactive areas], that allow users to post or upload
      Information. You agree that you will not post, upload, or transmit any communications or Information of any type
      to the HOMEX PORTAL that infringes or violates any rights of any party. By submitting Information to the HOMEX
      PORTAL, you agree to comply with these Terms and Conditions and any other applicable policies. Freddie Mac
      reserves the right to remove Information for any reason, at any time, without notice, in Freddie Mac’s sole
      discretion, including Information that we believe violates these Terms and Conditions or our other policies.
    </div>

    <div className={styles.sectionWrp}>
      You agree to only post or upload Information to the HOMEX PORTAL that you have all rights to transmit and license
      and which do not violate trademark, copyright, privacy, or any other rights of any other person. Except as maybe
      required for HOMEX PORTAL registration purposes, you agree that you will not submit any Information that contains
      Personally Identifiable Information (like name, phone number, email address, social security number) of you or of
      anyone else. Any personal information submitted to the HOMEX PORTAL is subject to Freddie Mac’s Privacy Notice (or
      enter the following URL in Your browser: https://www.freddiemac.com/terms/privacy). It is strictly prohibited to
      upload Information that contain expressions of hate, abuse, offensive images or conduct, obscenity, pornography,
      sexually explicit or any material that could give rise to any civil or criminal liability under applicable law or
      regulations or that otherwise may be in conflict with these Terms and Conditions.
    </div>

    <div className={styles.sectionWrp}>
      You agree that you will not upload any material that contains software viruses or any other computer code, files
      or programs designed to interrupt, destroy or limit the functionality of any computer software or the HOMEX
      PORTAL.
    </div>

    <div className={styles.sectionWrp}>
      No use of Freddie Mac’s Name or Marks
    </div>

    <div className={styles.sectionWrp}>
      Without Freddie Mac’s prior written consent in each instance, you may not publicly disclose, either directly or
      indirectly, and whether in any press release, advertising, publicity or any other materials, any details
      concerning the HOMEX PORTAL, your use of the HOMEX PORTAL, Discussions with Freddie Mac, or any other public
      disclosure that might imply a relationship between you or your Company and Freddie Mac. You and your Company may
      not publicly use or display Freddie Mac’s trademarks or service marks.
    </div>

    <div className={styles.sectionWrp}>
      Termination
      Freddie Mac reserves the right to terminate your access to all or any portion of the HOMEX PORTAL at any time
      without notice and for any reason. If we terminate your access to the HOMEX PORTAL, you will not have the right to
      bring claims against us with respect to such termination.
    </div>

    <div className={styles.sectionWrp}>
      No Warranties; Limitation of Liability
      Freddie Mac does not guarantee that the HOMEX PORTAL is suitable for any particular purpose. Freddie Mac assumes
      no obligation to update or correct any HOMEX PORTAL errors or deficiencies. Freddie Mac does not guarantee that
      access to or operation of the HOMEX PORTAL will be uninterrupted or free of errors or omissions, or that the HOMEX
      PORTAL cannot be tampered with by third parties or that any files or information that you download from the HOMEX
      PORTAL will be free of viruses or other destructive code. While Freddie Mac has implemented security measures
      designed to protect most information sent to the Freddie Mac, Freddie Mac does not guarantee that information sent
      to the HOMEX PORTAL will not be obtained, reviewed, disclosed or tampered with by third parties. THE HOMEX PORTAL,
      THE INFORMATION POSTED OR LINKED THERETO AND ANY SOFTWARE RELATED THERETO ARE PROVIDED ON AN "AS IS" BASIS, WITH
      NO WARRANTIES OF ANY KIND WHATSOEVER, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF
      TITLE OR ACCURACY OR ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. USE OF THE
      HOMEX PORTAL, SUCH INFORMATION AND SUCH SOFTWARE IS AT THE USER'S SOLE RISK. IN NO EVENT WILL FREDDIE MAC BE
      LIABLE FOR ANY DAMAGES WHATSOEVER ARISING OUT OF OR RELATED TO THE HOMEX PORTAL (INCLUDING, BUT NOT LIMITED TO ANY
      SOFTWARE RELATED THERETO), OR ANY THIRD PARTY SERVICES OR ANY INFORMATION CONTAINED IN THE HOMEX PORTAL,
      INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, WHETHER
      UNDER A CONTRACT, TORT OR ANY OTHER THEORY OF LIABILITY, EVEN IF FREDDIE MAC IS AWARE OF THE POSSIBILITY OF SUCH
      DAMAGES.
    </div>

    <div className={styles.sectionWrp}>
      Choice of Law; Access Restrictions
      These Terms and Conditions will be construed in accordance with the substantive laws of the Commonwealth of
      Virginia, United States of America, excluding provisions of Virginia law concerning choice-of-law that would
      result in the laws of any state or country other than Virginia being applied. However, the Uniform Computer
      Information Transactions Act (or any substantially similar law enacted by Virginia) will not apply to these Terms
      and Conditions, and instead the law of Virginia as it exists without reference to the Uniform Computer Information
      Transactions Act will apply. Any claims, actions or proceedings related to the HOMEX PORTAL or these Terms and
      Conditions must be brought in the Federal District Court for the Eastern District of Virginia, Alexandria
      Division. By using the HOMEX PORTAL, you agree to submit to the jurisdiction of said court for such purposes.
      FREDDIE MAC OPERATES THE HOMEX PORTAL FROM ITS OFFICES IN THE COMMONWEALTH OF VIRGINIA OF THE UNITED STATES OF
      AMERICA. FREDDIE MAC MAKES NO REPRESENTATION OR WARRANTY THAT THE INFORMATION CONTAINED IN OR LINKED TO THE HOMEX
      PORTAL IS APPROPRIATE OR LEGALLY AVAILABLE FOR DISTRIBUTION, DISPLAY OR USE IN LOCATIONS OTHER THAN THE UNITED
      STATES OF AMERICA, AND ACCESS TO SUCH INFORMATION FROM JURISDICTIONS WHERE SUCH ACTIVITIES ARE NOT LEGAL IS
      STRICTLY PROHIBITED. IF YOU ACCESS OR USE ANY INFORMATION FROM OR THROUGH THE HOMEX PORTAL, YOU ARE SOLELY
      RESPONSIBLE FOR ENSURING THAT SUCH ACCESS AND USE IS PERMISSIBLE UNDER THE LAWS OF THE JURISDICTION IN WHICH YOU
      ARE LOCATED.
    </div>

    <div className={styles.sectionWrp}>
      Severability
      The provisions of these Terms and Conditions are severable. If any such provision is determined to be
      unenforceable, such unenforceability will not affect any other provision of these Terms and Conditions, and the
      Terms and Conditions will be construed as if such unenforceable provision had not been contained herein. No waiver
      by Freddie Mac of any right under or provision of these Terms and Conditions will be deemed to be either a waiver
      of any other such right or provision or a waiver of that same right or provision at any other time.
    </div>

    <div className={styles.sectionWrp}>
      Indemnification
      You agree to indemnify and hold harmless Freddie Mac and each of its directors, officers, employees and agents
      from and against any and all injuries, damages, losses, liabilities, claims, judgments and settlements, including
      all reasonable costs, expenses and attorneys' fees, arising out of any action or claim related to any negligent or
      willful act or omission by you while accessing or using the HOMEX PORTAL or your failure to comply with any of
      these Terms and Conditions.
    </div>

  </div>
);

export default HomeXTerms;

import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Loading,
  DropSelect,
  Label,
  MultiSelect,
  TextBox,
} from "modules/primitives/index";
import { useSnackbar } from "notistack";
import SCReview from "common/handleSCReview";
import DropDownNestedElements from "common/components/dropdownNestedElements";

import styles from "./editCompany.module.scss";
import LocationSelectSearchAndAdd from "./locationSearchAndAdd";
import {
  partnerOptions,
  revenuOptions,
  businessUnitOptions,
} from "./editCompany.constants";
import {
  EMPLOYEES_VALUES,
  PATIENT_ORG_RELATIONSHIP_STAGE,
} from "../../../../common/constants";
import { Icon, smSize } from "../../../../common/icon";
import {
  handleLocationChange,
  handleAddNewLocation,
  getCustomers,
  getCompetitors,
  handleIdeasSelect,
  handleAddNewCompany,
  handleBusinessRemove,
} from "./editCompany.helper.js";

const EditCompanyFormEnd = (props) => {
  const {
    popup,
    setRelatedIdeas,
    relatedIdeas,
    setSelectedPatnerType,
    setBusinessUnitsValues,
    businessUnitsOptionsFirmenich,
    businessUnitsValues,
    selectedTopics,
    prevTopics,
    prevBusiness,
    setPrevBusiness,
    industry,
    setIndustry,

    prevIndustries,
    setSelectedRevenue,
    setCompetitors,
    competitors,
    isDisabledCompetitors,
    shouldClearSelect,
    competitorOptions,
    setCustomerQuery,
    currentCompetitorQuery,
    setDisabledCompetitors,
    prevCompetitors,
    setPrevCompetitors,
    setSelectedCustomers,
    customerOptions,
    customerQuery,
    customerLoading,
    prevCustomers,
    setPrevCustomers,
    formikprops,
    setFieldValue,
    blockAsCB,
    businessContentRenderer,
    businessDropdownRenderer,
    selectedBusinessUnitsValues,
    setSelectedCustomTagObj,
    setSelectedTopics,
    selectedCustomTagsObj,
  } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    appReducer: { relationShipStatuses },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany },
      companyMainReducer: { loading, topics: allTopics, industries },
    },
    ideaReducer: { ideas },
  } = useSelector((state) => state);
  const history = useHistory();
  const [userInteractionDropdown, setUserInteractionDropown] = useState({
    isActivated: false,
    val: "",
  });
  const [prevDropVal, setPrevDropVal] = useState(false);
  const [cancelPressed, setCancelPressed] = useState(false);

  const handleDropdownSelect = (val) => {
    setFieldValue(
      history.location?.state?.gsk
        ? "relationship_stage"
        : "council_relationship_stage_id",
      history.location?.state?.gsk
        ? val.val
        : relationShipStatuses.filter((status) => status.name === val.val)[0]
            ?.customId
    );
  };
  const handleChangeStage = useCallback(
    (e, fromUserInteractionUseEffect) => {
      if (
        selectedCouncil?.name === "Kyndryl" &&
        e.val === "SC Review" &&
        (userInteractionDropdown.isActivated || fromUserInteractionUseEffect)
      ) {
        popup.show({
          title: " ",
          show: true,
          height: "300",
          width: "540",
          component: (
            <SCReview
              handleStageSelect={handleDropdownSelect}
              e={e}
              popup={popup}
              fromEditCompany
              prevDropVal={prevDropVal}
              setCancelPressed={setCancelPressed}
              companySlug={selectedCompany?.slug}
            />
          ),
        });
      } else {
        handleDropdownSelect(e);
      }
    },
    [userInteractionDropdown.isActivated]
  );
  useEffect(() => {
    if (userInteractionDropdown.isActivated) {
      handleChangeStage(userInteractionDropdown, true);
    }
  }, [userInteractionDropdown.isActivated]);

  const fitlerRelationShipStatuses = () => {
    if (selectedCouncil?.name === "Kyndryl") {
      return [...relationShipStatuses];
    }
    return [
      {
        name: "None",
        slug: "none",
      },
      ...relationShipStatuses,
    ];
  };

  const handleTagSelect = (topicsId) => {
    setSelectedTopics([...new Set([...selectedTopics, topicsId])]);
  };

  const handleIndustrySelect = (industryId) => {
    setIndustry([...new Set([...industry, industryId])]);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <Label>Headquarters Location</Label>
          <LocationSelectSearchAndAdd
            disabled={blockAsCB}
            formValues={formikprops.values}
            onAddNew={(locObj) => handleAddNewLocation(locObj, setFieldValue)}
            onChange={(value) => handleLocationChange(value, setFieldValue)}
          />
          <Label>Employees</Label>
          <DropSelect
            placeholder=""
            disabled={blockAsCB}
            labelField="value"
            valueField="id"
            searchBy="value"
            options={EMPLOYEES_VALUES.filter((e) => e.id !== "Any")}
            dropdownPosition="auto"
            name="num_employees_enum"
            formProps={formikprops}
            onChange={(value) => setFieldValue("num_employees_enum", value.val)}
          />
          {selectedCouncil ? (
            <>
              <Label>Relationship Stage</Label>
              <DropSelect
                type="text"
                disabled={blockAsCB}
                name={
                  history.location?.state?.gsk
                    ? "relationship_stage"
                    : "council_relationship_stage_id"
                }
                placeholder="Relationship"
                className={styles.textBox}
                formProps={formikprops}
                options={
                  history.location?.state?.gsk
                    ? PATIENT_ORG_RELATIONSHIP_STAGE
                    : fitlerRelationShipStatuses()
                }
                onChange={(e) => {
                  handleChangeStage(e);
                }}
                valueField={history.location?.state?.gsk ? "slug" : "name"}
                labelField="name"
                setUserInteractionDropown={setUserInteractionDropown}
                setPrevDropVal={setPrevDropVal}
                prevDropVal={prevDropVal}
                cancelPressed={cancelPressed}
                setCancelPressed={setCancelPressed}
              />
            </>
          ) : null}

          {selectedCouncil && selectedCouncil?.name === "Kyndryl" ? (
            <>
              <Label>Partner Type</Label>
              <DropSelect
                SelecedValues={selectedCompany.partner_type}
                labelField="text"
                valueField="value"
                searchBy="text"
                placeholder="Partner Type"
                className={styles.textBox}
                options={partnerOptions}
                onChange={(value) => setSelectedPatnerType(value.val)}
              />
            </>
          ) : null}
          {selectedCouncil?.name === "Freddie Mac" && (
            <>
              <Label>Business Unit</Label>
              <MultiSelect
                onChange={(e) => setBusinessUnitsValues(e)}
                placeholder="Business Unit"
                labelField="name"
                valueField="id"
                searchBy="name"
                options={businessUnitOptions}
                multi
                className={styles.unitSelect}
                values={businessUnitsValues}
              />
            </>
          )}
          {/* selectedCouncil?.name === "Firmenich" || */}
          {selectedCouncil?.name === "Kyndryl" && (
            <>
              <div>
                <Label>
                  {" "}
                  {selectedCouncil.name === "Kyndryl"
                    ? "Practice"
                    : "Business Unit"}
                </Label>
                <DropSelect
                  multi
                  disabled={blockAsCB}
                  className={styles.dropWrapper}
                  labelField="name"
                  valueField="id"
                  values={selectedBusinessUnitsValues}
                  options={businessUnitsOptionsFirmenich}
                  contentRenderer={(innerProps, innerState, innerMethods) =>
                    businessContentRenderer(
                      innerProps,
                      innerState,
                      innerMethods
                    )
                  }
                  dropdownRenderer={(innerProps, innerState, innerMethods) =>
                    businessDropdownRenderer(
                      innerProps,
                      innerState,
                      innerMethods
                    )
                  }
                />
                <div className={styles.delete}>
                  {prevBusiness &&
                    prevBusiness.map((item) => (
                      <div key={item?.id} className={styles.techItem}>
                        {item?.name}
                        <span
                          onClick={() => {
                            if (!blockAsCB) {
                              handleBusinessRemove(
                                item?.id,
                                setPrevBusiness,
                                prevBusiness
                              );
                            }
                          }}
                        >
                          <Icon {...smSize} icon="icn-button-delete" />
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}
          {selectedCouncil?.council_custom_tag_groups[0]?.custom_tags && (
            <>
              {selectedCouncil?.council_custom_tag_groups?.map((group) => {
                return (
                  <>
                    <Label>{group?.name}</Label>

                    <DropDownNestedElements
                      tags={group.custom_tags}
                      selectedTags={[
                        ...selectedCustomTagsObj.map((t) => t.custom_tag_id),
                      ]}
                      childKey="children"
                      tagKeyName="custom_tag_id"
                      parentKeyName="custom_tag_id"
                      handleTagSelect={(val, e) => {
                        setSelectedCustomTagObj((prev) => {
                          return [...prev, val[0]];
                        });
                      }}
                      label="Select Custom Tags"
                      handleTagRemove={(e, f) => {
                        if (e) {
                          setSelectedCustomTagObj((prev) => {
                            return [...prev].filter(
                              (el) => el.custom_tag_id !== e
                            );
                          });
                        }
                      }}
                      selectedIsObject
                      useCustomOptionRenderer
                    />
                  </>
                );
              })}
            </>
          )}
          {!history.location?.state?.gsk &&
            !history?.location?.state?.fromTractionAdmin && (
              <div className="row">
                <div className="col-12">
                  <Label>Technology</Label>
                  <DropDownNestedElements
                    tags={allTopics}
                    selectedTags={prevTopics
                      ?.map((e) => e?.topic?.id)
                      .concat(selectedTopics)}
                    handleTagSelect={handleTagSelect}
                    handleTagRemove={(e, f) => {
                      if (e) {
                        setSelectedTopics(e.map((tag) => tag.id));
                      }
                    }}
                    childKey="children_topics"
                    tagKeyName="id"
                    parentKeyName="parent_resource_topic_id"
                    label="Select technologies"
                    showPrevSeparate={false}
                  />
                </div>
                <div className="col-12">
                  <Label>
                    {selectedCouncil?.name === "Freddie Mac"
                      ? "Housing Cycle"
                      : "Industries"}
                  </Label>
                  <DropDownNestedElements
                    tags={industries}
                    selectedTags={prevIndustries
                      ?.map((e) => e?.industry?.id)
                      .concat(industry)}
                    handleTagSelect={handleIndustrySelect}
                    handleTagRemove={(e, f) => {
                      if (e) {
                        setIndustry(e.map((tag) => tag.id));
                      }
                    }}
                    childKey="children_industries"
                    tagKeyName="id"
                    parentKeyName="parent_resource_industry_id"
                    label="Select industries"
                    showPrevSeparate={false}
                  />
                </div>
              </div>
            )}
          <Label>Founded (Date)</Label>
          <TextBox
            type="date"
            name="founded"
            disabled={blockAsCB}
            className={styles.textBox}
            formProps={formikprops}
          />
          {ideas.length &&
          !history?.location?.state?.fromTractionAdmin &&
          selectedCouncil?.settings?.ideas_connected !== false ? (
            <>
              <Label>Related Ideas</Label>
              <DropSelect
                multi
                className={styles.dropWrapper}
                labelField="name"
                disabled={blockAsCB}
                valueField="id"
                searchBy="name"
                name="ideas_related_companies"
                options={ideas}
                onChange={(e) =>
                  handleIdeasSelect(
                    e,
                    selectedCouncil.id,
                    selectedCompany,
                    setRelatedIdeas
                  )
                }
                values={ideas.filter((i) =>
                  relatedIdeas.some((i1) => i1.idea.idea_id === i.id)
                )}
              />
            </>
          ) : null}
        </div>

        <div className="col-12">
          {!history.location?.state?.gsk && (
            <>
              <Label>Estimated Revenue</Label>
              <DropSelect
                SelecedValues={selectedCompany.revenue_range}
                onChange={(e) => setSelectedRevenue(e.val)}
                placeholder="Revenue Range"
                labelField="text"
                disabled={blockAsCB}
                valueField="value"
                searchBy="text"
                options={revenuOptions}
              />
            </>
          )}

          <Label>Contact Email</Label>
          <TextBox
            type="text"
            name="contact_email"
            disabled={blockAsCB}
            placeholder="Contact Email"
            className={styles.textBox}
            formProps={formikprops}
          />
          <Label>Linkedin</Label>
          <TextBox
            type="text"
            name="linkedin"
            disabled={blockAsCB}
            placeholder="Linkedin"
            className={styles.textBox}
            formProps={formikprops}
          />
          <Label>Twitter</Label>
          <TextBox
            type="text"
            name="twitter"
            disabled={blockAsCB}
            placeholder="Twitter"
            className={styles.textBox}
            formProps={formikprops}
          />

          {!history.location?.state?.gsk && (
            <div>
              <Label>Competitors</Label>
              <DropSelect
                onChange={(payload) => {
                  if (payload.val !== selectedCompany.id) {
                    setCompetitors(payload.obj);
                  }
                }}
                SelecedValues={competitors}
                disabled={isDisabledCompetitors || blockAsCB}
                placeholder={`Add ${
                  history.location?.state?.gsk ? "Organizations" : "Companies"
                } (type to search or select from below)`}
                labelField="name"
                valueField="id"
                searchBy="name"
                shouldClear={shouldClearSelect}
                options={competitorOptions}
                onFocus={() =>
                  getCompetitors(
                    "",
                    dispatch,
                    selectedCouncil.id,
                    enqueueSnackbar
                  )
                }
                multi
                searchFn={(args) => {
                  setCustomerQuery(args.state.search);
                  if (currentCompetitorQuery !== args.state.search) {
                    getCompetitors(
                      args.state.search,
                      dispatch,
                      selectedCouncil.id,
                      enqueueSnackbar
                    );
                  }
                }}
                noDataRenderer={() => (
                  <div className={styles.noDataContainer}>
                    <p>Not found in database</p>
                    <p
                      className={styles.newCompetitorLink}
                      onClick={(event) =>
                        handleAddNewCompany({
                          event,
                          setDisabledCompetitors,
                          popup,
                          enqueueSnackbar,
                          orgId: props?.match?.params?.id,
                        })
                      }
                    >
                      + ADD NEW COMPETITOR
                    </p>
                  </div>
                )}
              />
              {loading && <Loading />}
              <div className={styles.companyListWrp}>
                <div>
                  <div className={styles.companyList}>
                    {prevCompetitors?.map((competitor) => (
                      <div key={competitor.id} className={styles.techItem}>
                        <a
                          target="_blank"
                          href={competitor.url}
                          rel="noreferrer"
                        >
                          {competitor.name}{" "}
                          <span
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              if (!blockAsCB) {
                                setPrevCompetitors(
                                  prevCompetitors.filter(
                                    (item) =>
                                      item.company_id !== competitor.company_id
                                  )
                                );
                              }
                            }}
                            className={styles.delete}
                          >
                            <Icon {...smSize} icon="icn-button-delete" />
                          </span>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!history.location?.state?.gsk && (
            <div className={styles.customersWrp}>
              <Label>Customers</Label>
              <DropSelect
                disabled={blockAsCB}
                onChange={(e) => setSelectedCustomers(e.obj)}
                placeholder={`Add ${
                  history.location?.state?.gsk ? "Organizations" : "Companies"
                } (type to search or select from below)`}
                labelField="name"
                valueField="id"
                searchBy="name"
                options={customerOptions}
                multi
                onFocus={() => getCustomers(null, enqueueSnackbar, dispatch)}
                searchFn={(args) => {
                  setCustomerQuery(args.state.search);
                  if (customerQuery !== args.state.search) {
                    getCustomers(args.state.search, enqueueSnackbar, dispatch);
                  }
                }}
              />

              <div className={styles.companyListWrp}>
                <div>
                  {" "}
                  {customerLoading ? (
                    <Loading />
                  ) : (
                    <div className={styles.companyList}>
                      {prevCustomers && prevCustomers.length
                        ? prevCustomers.map((customer) => (
                            <div className={styles.techItem}>
                              <span>{customer.name}</span>
                              <span
                                onClick={() => {
                                  if (!blockAsCB) {
                                    setPrevCustomers(
                                      prevCustomers.filter(
                                        ({ id }) => id !== customer?.id
                                      )
                                    );
                                  }
                                }}
                              >
                                <Icon
                                  {...smSize}
                                  className={styles.trash}
                                  icon="icn-button-delete"
                                />
                              </span>
                            </div>
                          ))
                        : null}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditCompanyFormEnd;

import * as Yup from "yup";

export const tagModel = {
  name: "",
  private: false,
};

export const tagValidation = Yup.object().shape({
  name: Yup.string().required("Template title is required"),
});

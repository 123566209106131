import {
  PROJECT_LAST_ACTIVITY_GET_SUCCESS,
  PROJECT_TEAMS_GET_SUCCESS,
  PROJECTS_SEARCH_ATTEMPT,
  PROJECTS_SEARCH_SUCCESS,
} from "./projectsMain.action";
import { apiStatus, pageMeta } from "../../../common/httpCall";

const initState = {
  lastActivity: {},
  teams: {},
  projectList: [],
  projectLoading: false,
  projectListMeta: {},
};
const projectsMainReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PROJECTS_SEARCH_ATTEMPT: {
      return {
        ...state,
        projectLoading: true,
      };
    }

    case PROJECTS_SEARCH_SUCCESS: {
      const projectListMeta = pageMeta(payload);

      return {
        ...state,
        projectList: payload?.response || [],
        projectLoading: false,
        projectListMeta,
      };
    }

    case PROJECT_LAST_ACTIVITY_GET_SUCCESS:
      const { projectId, data } = payload;
      const { response, status } = data;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          lastActivity: { ...state.lastActivity, [projectId]: response },
        };
      }
      break;

    case PROJECT_TEAMS_GET_SUCCESS:
      return {
        ...state,
        teams: { ...state.teams, [payload.id]: payload },
      };

    default:
      return state;
  }
  return state;
};

export default projectsMainReducer;

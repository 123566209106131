import React, { useState } from "react";

import styles from "modules/reporting/customReportsStyles.module.scss";
import DialogContent from "@mui/material/DialogContent";
import { Button } from "modules/primitives";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const StageComponent = ({ data, config }) => {
  const companiesByState = data?.companies_by_project_stage?.[config.propToUse] || [];
  const [open, setOpen] = useState(false);

  const hasShowMore = companiesByState?.length > 5;

  const handleShowMore = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  if (!companiesByState?.length) {
    return '-';
  }

  return (
    <div className="d-flex flex-wrap">
      {/*<span>Total: {companiesByState?.length};</span>*/}
      {
        companiesByState
          .slice(0, hasShowMore ? 5 : companiesByState?.length)
          .map((t, idx) => (
            <span key={`${companiesByState}-${idx}`}>
              {t}<span>&nbsp;</span>
            </span>
          ))
      }
      {
        hasShowMore ? (
          <span
            onClick={handleShowMore}
            className={`${styles.showMoreData} d-block mt-3`}
          >+ {companiesByState.length - 5}</span>
        ) : null
      }
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <span>Total: {companiesByState?.length};</span>
          <div className="d-flex flex-wrap">
            {
              companiesByState
                .map((t, idx) => (
                  <span
                    key={`modal_${t}_${idx}`}
                  >
                    {t}<span>,&nbsp;</span>
                  </span>
                ))
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(StageComponent);

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import { useModelPopup } from "../../../../../common/hooks";
import {
  projectCompanyDeletePermanently,
  projectCompanyChangeStatus,
  projectPatientOrgNewMultiple,
  projectCompanyNewMultiple,
  projectCompanyListGet,
  projectOrganizationsGet,
} from "../../companies/companies.action";
import { Icon } from "../../../../../common/icon";
import { Button, BtnType, CompanyAvatar } from "../../../../primitives";
import styles from "./companies.module.scss";
import MixedAddCompanyOrProducts from "../../../../../common/components/mixedSearch/mixedAddCompanyOrProducts";

const CompaniesComponent = ({
  companyList,
  isGsk,
  isPatientOrg,
  patientOrganizations,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    authReducer: { session },
    projectsReducer: {
      stagesReducer: { stagesList },
      projectReducer: { selectedProject },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const [organizations, setOrganizations] = useState([]);
  const [removed, setRemoved] = useState([]);

  const isFordSupplyChainAutomation = useMemo(() => {
    return selectedCouncil?.name === 'Ford' && selectedProject?.stage_template?.name === 'Supply Chain Automation';
  }, [selectedCouncil, selectedProject]);

  useEffect(() => {
    if (selectedProject && !isPatientOrg) {
      dispatch(
        projectCompanyListGet({
          projectId: selectedProject.id,
          items: 999,
          page: 1,
          enqueueSnackbar,
        })
      );
    } else if (selectedProject && isPatientOrg) {
      dispatch(
        projectOrganizationsGet({
          projectId: selectedProject.id,
          enqueueSnackbar,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (isPatientOrg) {
      setOrganizations(patientOrganizations);
      setRemoved(patientOrganizations.filter((el) => el.state === "removed"));
    } else {
      setOrganizations(companyList);
      setRemoved(companyList.filter((el) => el.state === "removed"));
    }
  }, [isPatientOrg, companyList, patientOrganizations]);

  const handleAddCallback = (selected) => {
    const stage = stagesList[0];

    if (selected.length > 0) {
      if (isPatientOrg) {
        const multiPatientOrgPayload = {
          project_patient_organizations: selected.map((s) => ({
            project_id: selectedProject?.id,
            gsk_patient_organization_id: s.id,
            added_by_id: session.id,
            stage_id: stage.id,
          })),
          project_id: selectedProject?.id,
          council_id: selectedCouncil.id,
          patientOrg: true,
          stage_id: stage.id,
          enqueueSnackbar,
        };

        dispatch(projectPatientOrgNewMultiple(multiPatientOrgPayload));
      } else {
        const multiCompaniesPayload = {
          project_companies: selected.map((s) => {
            if (s.is_product) {
              return {
                project_id: selectedProject.id,
                council_product_id: s.id,
                added_by_id: session.id,
                stage_id: stage.id,
                is_product: true,
              };
            }
            return {
              project_id: selectedProject.id,
              company_id: s.id,
              added_by_id: session.id,
              stage_id: stage.id,
            };
          }),
          project_id: selectedProject.id,
          council_id: selectedCouncil.id,
          patientOrg: false,
          stage_id: stage.id,
          enqueueSnackbar,
        };

        dispatch(projectCompanyNewMultiple(multiCompaniesPayload));
      }

      popup.hide();
    }
  };

  const handleAddCompanyClick = () => {
    let title;

    if (isFordSupplyChainAutomation) {
      title = 'Add Partners to Project'
    } else {
      title = `Add ${isPatientOrg ? "an Organization" : "a Company"} to Project`;
    }

    popup.show({
      title,
      component: (
        <MixedAddCompanyOrProducts
          patientOrg={isPatientOrg}
          handleSave={handleAddCallback}
          placeholder="Type to search or select from below"
          label={isPatientOrg ? "Organizations" : "Companies or Products"}
          existingItems={organizations}
          // hideProducts
        />
      ),
    });
  };

  const organization = (org) => {
    if (org.is_product) {
      return {
        ...org,
        logo: org.company.logo,
      };
    }
    if (isPatientOrg) {
      return org.gsk_patient_organization;
    }
    return org.company;
  };

  const deleteCompany = async (elem) => {
    const resp = window.confirm("Are you sure you want to do that?");
    if (resp) {
      dispatch(
        projectCompanyChangeStatus({
          is_product: elem.is_product,
          companyId: elem.id,
          projectId: selectedProject.id,
          patientOrg: isPatientOrg,
          gsk: isGsk,
          state: "remove",
          enqueueSnackbar,
        })
      );
      const newOrganizations = organizations.map((o) => {
        if (o.id === elem.id) {
          return {
            ...o,
            state: "removed",
          };
        }
        return o;
      });
      setOrganizations(newOrganizations);
    }
  };

  const deleteCompanyPermanently = async (elem) => {
    const resp = window.confirm(
      "You are about to permanently delete this company from this project. Are you sure you want to do that?"
    );

    if (resp) {
      let url;

      if (isPatientOrg) {
        url = `projects/${selectedProject.id}/organizations/${elem.id}`;
      } else {
        url = `projects/${selectedProject.id}/companies/${elem.id}`;
      }

      const payload = {
        is_product: elem.is_product,
        companyId: elem.id,
        patientOrg: isPatientOrg,
        url,
        deletePermanently: true,
        manageProject: true,
        enqueueSnackbar,
      };

      dispatch(projectCompanyDeletePermanently(payload));

      let newOrganizations;

      if (elem.is_product) {
        newOrganizations = removed.filter((o) => o.id !== elem.id);
      } else {
        newOrganizations = removed.filter((o) =>
          isPatientOrg
            ? o.gsk_patient_organization.id
            : o.company.id !== elem.id
        );
      }

      setRemoved(newOrganizations);
    }
  };

  const handleAddCompanyBack = (company) => {
    dispatch(
      projectCompanyChangeStatus({
        projectId: selectedProject.id,
        companyId: isPatientOrg
          ? company.gsk_patient_organization.id
          : company.company.id,
        state: "activate",
        patientOrg: isPatientOrg,
        gsk: isGsk,
        enqueueSnackbar,
      })
    );
  };
  const handleCompanyClick = (el) => {
    localStorage.setItem("company", JSON.stringify(el.company));
    if (el.gsk_patient_organization) {
      history.push(
        `/patient_organizations/${
          el.gsk_patient_organization?.slug || el.gsk_patient_organization?.id
        }`
      );
    } else {
      history.push(`/companies/${el.company?.slug || el.company?.id}`);
    }
  };
  return (
    <div className={styles.membersWrp}>
      <div className={styles.formContainer}>
        <div className="row">
          <div className="col-12">
            <div className={styles.labelesContainer}>
              <p className={styles.label}>
                {isFordSupplyChainAutomation ? 'Project Partners' : isPatientOrg ? "Project Organizations" : "Project Companies"}
              </p>
              <p className={styles.label}> </p>
            </div>
            <div className={styles.companyListWrp}>
              {organizations &&
                organizations
                  .filter((el) => el.state !== "removed")
                  .map((el) => (
                    <div
                      className={styles.companyItem}
                      key={organization(el).name}
                    >
                      <div
                        className={styles.companydataWrp}
                        onClick={() => handleCompanyClick(el)}
                      >
                        <div className={styles.companyImageWrp}>
                          <CompanyAvatar
                            imgSrc={organization(el).logo}
                            name={organization(el).name}
                          />
                        </div>
                        <div className="d-flex flex-column align-start">
                          {organization(el).name}
                          {el.is_product && (
                            <span className="small text-muted">Product</span>
                          )}
                        </div>
                      </div>
                      <span
                        className={styles.companyDelete}
                        onClick={() => deleteCompany(organization(el))}
                      >
                        REMOVE
                      </span>
                    </div>
                  ))}
            </div>
            <p className={`${styles.label} mt-3`}>
              {isFordSupplyChainAutomation ? 'Removed Partners' : isPatientOrg ? "Removed Organizations" : "Removed Companies"}
            </p>
            <div className={styles.companyListWrp}>
              {removed &&
                removed.map((el) => (
                  <div
                    className={styles.companyItem}
                    key={organization(el).name}
                  >
                    <div className={styles.companydataWrp}>
                      <div className={styles.companyImageWrp}>
                        <CompanyAvatar
                          imgSrc={organization(el).logo}
                          name={organization(el).name}
                        />
                      </div>
                      <div className={styles.companyNameWrp}>
                        {organization(el).name}
                      </div>
                    </div>
                    <Button
                      icon="icn-add"
                      onClick={() => handleAddCompanyBack(el)}
                      btn={BtnType.FRAME_LESS}
                    >
                      Add back
                    </Button>
                    <Icon
                      size={22}
                      icon="icn-button-delete"
                      className={styles.deleteIcon}
                      onClick={() => deleteCompanyPermanently(organization(el))}
                    />
                  </div>
                ))}
            </div>
            <div className={styles.addMemberButtonContainer}>
              <Button
                icon="icn-add"
                onClick={handleAddCompanyClick}
                btn={BtnType.FRAME_LESS}
                className={styles.addCompanyButton}
              >
                {isFordSupplyChainAutomation ? 'Add Partner' : isPatientOrg ? "Add organization" : "Add A company"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CompaniesComponent);

export const ADD_RELATED_IDEA_ATTEMPT = "ADD_RELATED_IDEA_ATTEMPT";
export const addRelatedIdeaAttempt = (payload) => ({
  type: ADD_RELATED_IDEA_ATTEMPT,
  payload,
});

export const ADD_RELATED_IDEA_SUCCESS = "ADD_RELATED_IDEA_SUCCESS";
export const addRelatedIdeaSuccess = (payload) => ({
  type: ADD_RELATED_IDEA_SUCCESS,
  payload,
});
export const DELETE_RELATED_IDEA_ATTEMPT = "DELETE_RELATED_IDEA_ATTEMPT";
export const deleteRelatedIdeaAttempt = (payload) => ({
  type: DELETE_RELATED_IDEA_ATTEMPT,
  payload,
});

export const DELETE_RELATED_IDEA_SUCCESS = "DELETE_RELATED_IDEA_SUCCESS";
export const deleteRelatedIdeatSuccess = (payload) => ({
  type: DELETE_RELATED_IDEA_SUCCESS,
  payload,
});

export const DELETE_RELATED_THEME_ATTEMPT = "DELETE_RELATED_THEME_ATTEMPT";
export const deleteRelatedThemeAttempt = (payload) => ({
  type: DELETE_RELATED_THEME_ATTEMPT,
  payload,
});

export const DELETE_RELATED_THEME_SUCCESS = "DELETE_RELATED_THEME_SUCCESS";
export const deleteRelatedThemestSuccess = (payload) => ({
  type: DELETE_RELATED_THEME_SUCCESS,
  payload,
});

export const GET_RELATED_SOLUTIONS_ATTEMPT = "GET_RELATED_SOLUTIONS_ATTEMPT";
export const getRelatedSolutionsAttempt = (payload) => ({
  type: GET_RELATED_SOLUTIONS_ATTEMPT,
  payload,
});

export const GET_RELATED_SOLUTIONS_SUCCESS = "GET_RELATED_SOLUTIONS_SUCCESS";
export const getRelatedSolutionsSuccess = (payload) => ({
  type: GET_RELATED_SOLUTIONS_SUCCESS,
  payload,
});

export const ADD_RELATED_SOLUTION_ATTEMPT = "ADD_RELATED_SOLUTION_ATTEMPT";
export const addRelatedSolutionAttempt = (payload) => ({
  type: ADD_RELATED_SOLUTION_ATTEMPT,
  payload,
});

export const ADD_RELATED_SOLUTION_SUCCESS = "ADD_RELATED_SOLUTION_SUCCESS";
export const addRelatedSolutionSuccess = (payload) => ({
  type: ADD_RELATED_SOLUTION_SUCCESS,
  payload,
});

export const DELETE_RELATED_SOLUTION_ATTEMPT =
  "DELETE_RELATED_SOLUTION_ATTEMPT";
export const deleteRelatedSolutionAttempt = (payload) => ({
  type: DELETE_RELATED_SOLUTION_ATTEMPT,
  payload,
});

export const DELETE_RELATED_SOLUTION_SUCCESS =
  "DELETE_RELATED_SOLUTION_SUCCESS";
export const deleteRelatedSolutionSuccess = (payload) => ({
  type: DELETE_RELATED_SOLUTION_SUCCESS,
  payload,
});

export const GET_UPCOMING_TASKS = "GET_UPCOMING_TASKS";
export const getUpcomingTasks = (payload) => ({
  type: GET_UPCOMING_TASKS,
  payload,
});

export const GET_UPCOMING_TASKS_SUCCESS = "GET_UPCOMING_TASKS_SUCCESS";
export const getUpcomingTasksSuccess = (payload) => ({
  type: GET_UPCOMING_TASKS_SUCCESS,
  payload,
});

import { MEMBER_SEARCH_ATTEMPT, MEMBER_SEARCH_SUCCESS } from "./members.action";
import { apiStatus, pageMeta } from "../../common/httpCall";

const initState = {
  membersList: [],
  membersPageNr: {},
  loading: false,
};

const membersReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MEMBER_SEARCH_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case MEMBER_SEARCH_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          membersList: response,
          loading: false,
          companiesPageNr: pageMeta(payload),
        };
      }
    }
  }
  return state;
};

export default membersReducer;

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { ModalFooter, ModalBody } from "../../../../../application/modal";
import {
  Button,
  BtnType,
  Label,
  DropSelect,
  MultiSelect,
} from "../../../../primitives";
import { initModel, validation, roles } from "./members.constant";
import styles from "./styles.module.scss";
import {
  themesMemberAddAttempt,
  themesSpocExpertAddAttempt,
  fordSpocExpertGetAttempt,
} from "../overviewTab.action";
import { useModelPopup } from "../../../../../common/hooks";
import CheckboxComponent from "../../../../primitives/checkbox/checkbox.component";
import isUserAdmin from "../../../themesCommon/isUserAdmin/index";
import UserMultiSelectSearch from "../../../../../common/components/usersSearch";

const NewThemeMemberComponent = ({ itemList, smeMode }) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [expert, setExpert] = useState(false);
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { currentThemeData },
      themesOverviewReducer: { smeSpocExperts },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil && smeMode) {
      dispatch(
        fordSpocExpertGetAttempt({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleOnSubmit = (value) => {
    const data = {
      theme_member: value.memberId.map((user) => ({
        user_id: user.id,
        expert,
      })),
      user_role: value.role,
    };
    if (smeMode) {
      dispatch(
        themesSpocExpertAddAttempt({
          data: {
            resource_id: currentThemeData.id,
            resource_type: "Theme",
            user_ids: [
              ...value.memberId.map((user) => user.id),
              ...itemList.map((item) => item.user_id),
            ],
          },
          enqueueSnackbar,
        })
      );
      // setSearchVal("");
    } else {
      dispatch(
        themesMemberAddAttempt({
          themeId: currentThemeData.id,
          data,
          enqueueSnackbar,
        })
      );
    }

    popup.hide();
  };

  const filterUserRole = () => {
    if (
      isUserAdmin(session?.council_role) ||
      currentThemeData?.current_user_role === "manager" ||
      currentThemeData?.current_user_role === "owner"
    ) {
      return roles.map((el) => ({
        val: el.val,
        text: `${
          selectedCouncil?.settings?.themes_display_name_singular || "Theme"
        } ${el.text}`,
      }));
    }
    return [
      {
        val: roles[0].val,
        text: `${
          selectedCouncil?.settings?.themes_display_name_singular || "Theme"
        } ${roles[0].text}`,
      },
    ];
  };

  const filteredSmeSpocExperts = useMemo(() => {
    return smeSpocExperts.filter((smeExp) => {
      const isAlreadySelected = itemList?.find((themeAddedSmeExp) => {
        return themeAddedSmeExp?.user_id === smeExp?.id;
      });
      return !isAlreadySelected;
    });
  }, [smeSpocExperts, itemList]);

  return (
    <>
      <ModalBody>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initModel}
          validationSchema={validation}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleOnSubmit(values);
          }}
        >
          {({ handleSubmit, setFieldValue, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.editStageFormWrp}>
                <Label>Member</Label>
                {smeMode ? (
                  <MultiSelect
                    onChange={(val) => {
                      setFieldValue("memberId", val);
                    }}
                    placeholder="Choose member"
                    labelField="full_name"
                    valueField="id"
                    searchBy="full_name"
                    options={filteredSmeSpocExperts}
                    formProps={formikprops}
                  />
                ) : (
                  <UserMultiSelectSearch
                    placeholder="Choose member"
                    labelField="full_name"
                    valueField="id"
                    searchBy="full_name"
                    onChange={(val) => setFieldValue("memberId", val)}
                    formProps={formikprops}
                    filterBy={itemList}
                  />
                )}
                {!smeMode && (
                  <>
                    <div>
                      <Label>Role</Label>
                      <DropSelect
                        placeholder="Choose a role"
                        name="role"
                        labelField="text"
                        valueField="val"
                        searchBy="text"
                        options={filterUserRole()}
                        formProps={formikprops}
                        onChange={(e) => {
                          setFieldValue("role", e.val);
                        }}
                      />
                    </div>
                    <CheckboxComponent
                      className={styles.expertCheckbox}
                      label="Expert"
                      formProps={formikprops}
                      onChange={() => {
                        setExpert(!expert);
                      }}
                    >
                      {" "}
                    </CheckboxComponent>
                  </>
                )}
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button btn={BtnType.REGULAR} onClick={handleSaveClick}>
          Add member
        </Button>
      </ModalFooter>
    </>
  );
};

export default NewThemeMemberComponent;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik } from "formik";

import { useModelPopup } from '../../../../common/hooks/index';
import { Button, BtnType, Label, TextBox } from "modules/primitives";
import { ModalBody, ModalFooter } from "application/modal";
import { httpPost, httpPut } from "../../../../common/httpCall";
import LoadingComponent from "modules/primitives/loading/loading.component";
import {
  getProjectReplicationStatus,
  projectSet,
  updateReplicationStatus,
} from "modules/projects/project/project.action";
import ProjectsMultiSelectSearch from "../../../../common/components/projectsMultiSelectSearch";

const ReplicationStatusModal = ({ project }) => {
  const [model, setModel] = useState({
    replicated: project.original,
    original_project: null,
    newProjectName: '',
  });
  const [wrongProjectName, setWrongProjectName] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleDismiss = () => {
    popup.hide();
  };

  const submit = (values) => {
    setIsFetching(true);
    let payload;

    if (project.original) {
      payload = {
        convert_to_replication_id: project.id,
        original_project_id: values.original_project.id,
        project_name: values.newProjectName,
      };

      httpPost({
        call: 'projects/convert_to_replication',
        data: payload
      }).subscribe((res) => {
        setIsFetching(false);
        if (res.response?.id) {
          dispatch(projectSet(res.response));
          dispatch(updateReplicationStatus(null));
          dispatch(getProjectReplicationStatus({
            id: project.id
          }));
          enqueueSnackbar('Successfully applied update!', {
            variant: 'success',
          });
        }
        popup.hide();
      });

      return;
    }

    if (!values.replicated) {
      payload = {
        replication_id: project?.id,
        project_name: values.newProjectName
      }
    } else {
      payload = {
        replication_id: project.id,
        original_project_id: values.original_project.id,
        project_name: values.newProjectName
      }
    }

    httpPut({
      call: `replications/${project.id}`,
      data: payload
    }).subscribe(res => {
      setIsFetching(false);
      if (res.response?.id) {
        dispatch(projectSet(res.response));
        popup.hide();
        dispatch(updateReplicationStatus(null));
        dispatch(getProjectReplicationStatus({
          id: project.id
        }));
        enqueueSnackbar('Successfully applied update!', {
          variant: 'success',
        });
      }
    });
  };

  const handleOptionSelect = (values) => {
    if (values?.length) {
      const splitName = values[0].name.replace(/\(Replication \d+(\.\d+)*\)/, '');
      const newModel = {
        ...model,
        replicated: true,
        newProjectName: splitName,
        original_project: values[0],
      };

      setModel(newModel);
    } else {
      if (project.original) {
        setModel({
          replicated: project.original,
          original_project: null,
          newProjectName: '',
        });
      }
    }
  };

  const handleRenameProjectChange = evt => {
    const value = evt.target.value;

    setWrongProjectName(value === project.name.replace(/\s+/g, ' '));
  }

  const handleRadioChange = (setFieldValue, value) => {
    setFieldValue('replicated', value);
  }

  return (
    <>
      <Formik
        initialValues={model}
        enableReinitialize
        onSubmit={submit}
      >
        {({ handleSubmit, setFieldValue, values, ...formProps }) => (
          <>
            <ModalBody>
              {
                project.original ? (
                  <span className="small d-block mb-2">
                    Change this project from an <strong>Original</strong> to a <strong>Replication</strong> of another existing project.
                  </span>
                ) : (
                  <span className="small d-block mb-2">
                    Unlink this project and make it a new <strong>Original</strong> project or link it as a <strong>Replication</strong> to another existing project.
                  </span>
                )
              }
              <fieldset id="replicationStatus" className="d-flex flex-column mb-3">
                {
                  !project.original && (
                    <div className="d-flex">
                      <input
                        type="radio"
                        id="Original"
                        name="Original"
                        value="Original"
                        checked={!values.replicated}
                        onClick={() => handleRadioChange(setFieldValue, false)}
                      />
                      <label className="ml-1" htmlFor="Original">Original</label>
                    </div>
                  )
                }
                {
                  !values.replicated && (
                    <div className="d-flex flex-column align-items-start mt-3 mb-3 ml-3 w-100">
                      <Label>Rename Project</Label>
                      <TextBox
                        type="text"
                        name="newProjectName"
                        placeholder="Rename Project"
                        formProps={{ setFieldValue, values, ...formProps}}
                        className="w-100"
                        onChange={handleRenameProjectChange}
                      />
                      {
                        wrongProjectName && (
                          <span className="text-danger">Please rename Project.</span>
                        )
                      }
                    </div>
                  )
                }
                <div className="d-flex">
                  <input
                    type="radio"
                    id="Replication"
                    name="Replication"
                    value="Replication"
                    checked={values.replicated}
                    onClick={() => handleRadioChange(setFieldValue, true)}
                  />
                  <label className="ml-1" htmlFor="Replication">Replication</label>
                </div>
              </fieldset>
              {
                values.replicated && (
                  <>
                    <Label>Link to New Project</Label>
                    <div className="d-flex flex-column">
                      <ProjectsMultiSelectSearch
                        onChange={handleOptionSelect}
                        placeholder="Select project (or type to search)"
                        labelField="name"
                        searchBy="name"
                        valueField="id"
                        dropdownPosition="top"
                        multi={false}
                        filterBy={project.original_project?.id ? [project, project.original_project] : [project]}
                      />
                      {
                        !values.original_project && (
                          <span className="text-danger small">Project selection is required</span>
                        )
                      }
                      {
                        values.original_project && (
                          <>
                            <div className="d-flex flex-column align-items-start w-100">
                              <Label>Rename Project</Label>
                              <div className="d-flex align-items-start w-100">
                                <TextBox
                                  type="text"
                                  name="newProjectName"
                                  placeholder="Rename Project"
                                  formProps={{ setFieldValue, values, ...formProps }}
                                  className="w-100 align-items-baseline mr-3"
                                  onChange={handleRenameProjectChange}
                                />
                                <div className="w-50">( Replication )</div>
                              </div>
                            </div>
                            <span className="small">Please note: Your Project will be renamed.</span>
                          </>
                        )
                      }
                    </div>
                  </>
                )
              }
            </ModalBody>
            <ModalFooter>
              {
                isFetching ? (
                  <LoadingComponent customText="Applying changes..." />
                ) : (
                  <>
                    <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      btn={BtnType.REGULAR}
                      className="ml-2"
                      type="submit"
                      disabled={values.replicated ? !values.original_project : wrongProjectName}
                    >
                      Save
                    </Button>
                  </>
                )
              }
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  );
};

export default ReplicationStatusModal;

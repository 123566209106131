import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "throttle-debounce";

import styles from './firmenichChart.module.scss';
import { waitForElm } from "../../../../common/helper";
import { getChart, initializeChart } from "modules/companies/companyMain/chartsWrapper/chartsView/charts/utils";
import FirmenichPieChart from "modules/home/firmenichFunnel/firmenichChart/firmenichPieChart";
import { makePieFirmenichConfig } from "modules/home/firmenichFunnel/firmenichChart/pieUtils";
import usePrevious from "../../../../common/hooks/usePrevious";

const FirmenichPieChartWrapper = ({
  chartData,
  id,
  instance,
  handleSegmentClick,
  hasError,
  selectedTags = [],
  handleResetAll,
}) => {
  const ref = useRef();

  const [chart, setChart] = useState(null);
  const [wrapperWidth, setWrapperWidth] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", debounceResize);

    return () => {
      window.removeEventListener("resize", null);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      setWrapperWidth(ref.current.clientWidth);
    }
  }, [ref.current]);

  useEffect(() => {
    if (chart) {
      const chartElem = document.getElementById(`pie${id}`);

      if (!chartElem) return;

      chartElem.onclick = (evt) => {
        const points = chart.getElementsAtEventForMode(
          evt,
          "nearest",
          { intersect: true },
          true,
        );

        if (!points.length) return;

        const firstPoint = points[0];
        const value = chart.data.datasets[firstPoint.datasetIndex].customDataPerIndex[firstPoint.index];

        if (handleSegmentClick && value) {
          handleSegmentClick(value);
        }
      };
    }

    return () => null;
  }, [chart, id, handleSegmentClick]);

  const previousSelectedTags = usePrevious(selectedTags);

  useEffect(() => {
    if ((selectedTags.length) || (!selectedTags.length && previousSelectedTags?.length)) {
      makeChartConfig();
    }
  }, [selectedTags, previousSelectedTags]);

  useEffect(() => {
    if (wrapperWidth && !chart) {
      makeChartConfig();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [wrapperWidth, chart]);

  const debounceResize = debounce(1000, false, () => {
    if (ref?.current) {
      setWrapperWidth(ref?.current?.clientWidth);
    }
  });

  const makeChartConfig = useCallback(
    () => {
      if (!chartData?.chart_data?.data_sets) return;

      const chartConfig = makePieFirmenichConfig(chartData?.chart_data);

      waitForElm(`#pie${id}`).then(() => {
        const chartInstanceById = getChart(`pie${id}`);

        if (chartInstanceById) chartInstanceById.destroy();

        const newChart = initializeChart(`pie${id}`, "pie", chartConfig);

        setChart(newChart);
      });
    },
    [chart, id, instance, chartData],
  );

  const returnChartNode = useCallback(
    (width) => {
      if (chartData) {
        if (chart && chart?.width !== width) {
          chart.width = width;
          chart.update();
        }

        return (
          <FirmenichPieChart
            width={width}
            chartHeight={300}
            id={id}
          />
        );
      }
    },
    [chartData, chart, id],
  );

  if (chart && !chart.config?._config?.data?.datasets?.length) {
    return null;
  }

  return (
    <div ref={ref} className={styles.reportingPieChart}>
      {!hasError && wrapperWidth && returnChartNode(wrapperWidth - 20)}
      {hasError && 'There is no data for this chart'}
      {wrapperWidth && chart && selectedTags?.length ? (
        <span className={styles.reset} onClick={handleResetAll}>
          Reset
        </span>
      ) : null}
    </div>
  );
};

export default React.memo(FirmenichPieChartWrapper);

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import SimpleSearchComponent from "../../primitives/simpleSearch/simpleSearch.component";
import ThemesTopActions from "./themesMainTopActions";
import {
  themesCompaniesSearchAttempt,
  themesSearchAttempt,
} from "./themesMain.action";
import ThemeCard from "./themMainCard/index";
import { Pagination } from "../../primitives/index";
import PipelineKanban from "../themesIndividualPage/themesPipelineTab/pipelineKanban/index";
import PipelineTabTopActions from "../themesIndividualPage/themesPipelineTab/pipelineTabTopActions";
import TabComponent from "../../primitives/tabs/tabs.component";
import ThemeMainCardLoader from "./themeMainCardLoader";

const PAGINATION_DEFAULTS = {
  pageSize: 18,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
};

const indexByPath = {
  themes: 0,
  pipeline: 1,
};

const ThemesComponent = (props) => {
  const { location } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const [investMode, setInvestMode] = useState(false);

  const {
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: {
        themesData,
        shouldUpdateThemes,
        loadingThemes,
        shouldRedirectToTheme,
        currentThemeData,
        themeMeta,
        deletingTheme,
      },
    },
    authReducer: { session },
  } = useSelector((state) => state);

  const [activeLayout, setActiveLayout] = useState("card");
  const [pageSetting, setPageSetting] = useState(PAGINATION_DEFAULTS);
  const [sortOrder, setSortOrder] = useState("desc");
  const [currentQuery, setQuery] = useState("");
  const [filterOption, setFilterOption] = useState("All");
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const getView = location.pathname.split("/");
    handleOnTabSelect(indexByPath[getView[getView.length - 1]]);
  }, [location.pathname]);

  const handleSearchUpdate = (query, page, customMode) => {
    setQuery(query);
    const data = {
      sort_order: sortOrder,
      sort_attribute: "created_at",
      search: {
        query,
      },
      investment: customMode || investMode,
    };
    if (activeLayout === "card") {
      dispatch(themesSearchAttempt({ page: page || 1, data, fromMain: true }));
    } else {
      dispatch(themesCompaniesSearchAttempt({ page: page || 1, data }));
    }
  };

  const checkForThemeType = (opt) => {
    if (opt === "market") {
      return "market_segment";
    }
    if (opt === "technology") {
      return "technology";
    }
    return false;
  };

  const changeInvestment = (mode) => {
    handleSearchUpdate("*", false, mode);
    setInvestMode(mode);
  };

  useEffect(() => {
    if (sortOrder && filterOption) {
      const data = {
        sort_order: filterOption === "asc" ? "asc" : "desc",
        sort_attribute: "created_at",
        search: {
          query: currentQuery,
          current_option: checkForThemeType(filterOption),
          favorite: filterOption === "favorites",
          owner: filterOption === "myThemes",
        },
      };
      if (filterOption === "all") {
        data.search = {
          query: "*",
          current_option: false,
          favorite: false,
          owner: false,
        };
      }
      dispatch(
        themesSearchAttempt({
          page: pageSetting.current || 1,
          data,
          fromMain: true,
        })
      );
    }
  }, [sortOrder, filterOption]);

  // useEffect(() => {
  //   if (selectedCouncil) {
  //     handleSearchUpdate("*");
  //     // dispatch(themesGetAttempt({ enqueueSnackbar }));
  //   }
  // }, [selectedCouncil, location.pathname]);

  useEffect(() => {
    if (shouldUpdateThemes) {
      handleSearchUpdate("*");

      // dispatch(themesGetAttempt({ enqueueSnackbar }));
    }
  }, [shouldUpdateThemes]);

  useEffect(() => {
    if (shouldRedirectToTheme) {
      history.push({
        pathname: `/themes/individual_page/${currentThemeData.id}/overview`,
        state: { currentThemeData },
      });
    }
  }, [shouldRedirectToTheme]);

  const requestNewThemes = (page) => {
    setPageSetting({
      ...pageSetting,
      current: page,
    });
    handleSearchUpdate("*", page);

    // dispatch(themesGetAttempt({ enqueueSnackbar, page }));
  };

  const handlePageChange = (page) => {
    if (page === themeMeta.current) {
      return;
    }
    requestNewThemes(page);
  };

  useEffect(() => {
    if (themeMeta && themeMeta?.total !== 0) {
      setPageSetting({
        ...pageSetting,
        total: themeMeta?.total,
      });
    }
  }, [themeMeta]);

  const tabList = [
    {
      tab: "Overview",
      name: "Overview",
      icon: '<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 3.5a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H3zm0 10a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H3z"  fill="#CBD2D9" /></svg>',
      tabIndex: 0,
      component: deletingTheme ? (
        <div className={styles.centeredCardGrid}>
          <ThemeMainCardLoader />
        </div>
      ) : (
        <div
          className={loadingThemes ? styles.centeredCardGrid : styles.cardGrid}
        >
          {!loadingThemes && themesData?.themes?.length < 1 && (
            <p className={styles.placeholderCont}> There are no items yet...</p>
          )}
          {loadingThemes && <ThemeMainCardLoader />}
          {!loadingThemes &&
            themesData?.themes?.map((theme) => (
              <ThemeCard key={theme.id} cardData={theme} />
            ))}
        </div>
      ),
    },
    {
      tab: "Pipeline",
      name: "Pipeline",
      icon: '<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 22.5a1 1 0 0 1-1-1v-18a1 1 0 1 1 2 0v18a1 1 0 0 1-1 1zm4 0a1 1 0 0 1-1-1v-18a1 1 0 1 1 2 0v18a1 1 0 0 1-1 1zm3-1a1 1 0 1 0 2 0v-18a1 1 0 1 0-2 0v18zm5 1a1 1 0 0 1-1-1v-18a1 1 0 1 1 2 0v18a1 1 0 0 1-1 1zm3-1a1 1 0 1 0 2 0v-18a1 1 0 1 0-2 0v18z" fill="#CBD2D9"/></svg>',
      tabIndex: 1,
      component: (
        <div className={styles.pipelineContainer}>
          <PipelineTabTopActions
            activeLayout={activeLayout}
            investMode={investMode}
            setInvestMode={changeInvestment}
            fromThemes={true}
          />
          <PipelineKanban fromThemes investMode={investMode} />
        </div>
      ),
    },
  ];

  const handleOnTabSelect = (index) => {
    setSelectedTab(index);
    setActiveLayout(index === 0 ? "card" : "list");

    if (index === 0) {
      history.push(`/themes`);
    }

    if (index === 1) {
      history.push(`/themes/pipeline`);
    }
  };

  const checkConnectedTabs = () => {
    if (session?.council_role === "company") {
      return tabList.filter((tab) => tab.tab !== "Pipeline");
    }

    return tabList.filter((tab) => {
      return !(
        selectedCouncil?.settings.pipeline_theme_enabled === false &&
        tab.tab === "Pipeline"
      );
    });
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerActions}>
        <div className={styles.searchWrapper}>
          <SimpleSearchComponent
            withoutMargin
            //   searchValue={debouncedValue}
            onSearchValueUpdate={handleSearchUpdate}
            placeholder={
              activeLayout === "card"
                ? `Search ${
                    selectedCouncil?.settings?.themes_display_name || "Themes"
                  }`
                : `Search ${
                    selectedCouncil?.settings?.themes_display_name_singular ||
                    "Themes"
                  } Companies`
            }
            customIcon="search"
          />
        </div>
        <ThemesTopActions
          activeLayout={activeLayout}
          total_companies={themesData?.total_companies || 0}
          total_members={themesData?.total_members || 0}
          total_themes={themesData?.total_themes || 0}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          setFilterOption={setFilterOption}
          filterOption={filterOption}
          userRole={session?.council_role}
        />
      </div>
      <TabComponent
        tabList={checkConnectedTabs()}
        defaultIndex={selectedTab}
        onSelect={(index) => handleOnTabSelect(index)}
      />

      {!loadingThemes && !deletingTheme && activeLayout === "card" && (
        <div className={styles.paginationBlock}>
          {themesData?.themes?.length && themeMeta?.total ? (
            <Pagination {...pageSetting} onChange={handlePageChange} />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ThemesComponent;

import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  patientOrganizationProjectAddMany,
} from "modules/patientOrganizations/patientOrganization/patientOrganization.action";
import {
  projectOrganizationsGet,
  projectCompanyNewMultiple,
  projectPatientOrgNewMultiple,
} from "modules/projects/project/companies/companies.action";
import {
  Loading,
  Button,
  BtnType,
  Label,
  DropSelect,
} from "modules/primitives/index";
import { ModalFooter, ModalBody } from "../../../../application/modal";
import { projectListGet } from "modules/projects/project/project.action";
import styles from "./addCompany.module.scss";
import { projectCompanyNew } from "../companyMain.action";
import { useModelPopup } from "../../../../common/hooks";

const AddCompanyComponent = ({
  enqueueSnackbar,
  companyId,
  type = "companies",
  multipleAdd,
  companyIds,
  fromCompany,
  isProduct,
}) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const history = useHistory();
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isInProject, setIsInProject] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    patientOrganizationsReducer: {
      patientOrganizationReducer: { selectedPatientOrganization },
    },
    projectsReducer: {
      companyReducer: { patientOrganizations },
      projectReducer: { projectList },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil?.id) {
      setLoading(true);

      dispatch(
        projectListGet({
          councilId: selectedCouncil.id,
          pageSize: 999,
          currentPage: 1,
          userId: session.id,
          enqueueSnackbar,
          items: fromCompany && 100,
          isDropdown: true,
          cb: () => {
            setLoading(false);
          }
        })
      );
    }
  }, [selectedCouncil]);

  useEffect(() => {
    if (selected && selected.val && type === "patient_organizations") {
      setLoading(true);
      dispatch(projectOrganizationsGet({ projectId: selected?.val, enqueueSnackbar }));
    }
  }, [selected]);

  useEffect(() => {
    if (
      patientOrganizations &&
      patientOrganizations.filter(
        (org) => org.gsk_patient_organization.id === selectedPatientOrganization.id
      ).length
    ) {
      setLoading(false);
      setIsInProject(true);
    } else {
      setLoading(false);
      setIsInProject(false);
    }
  }, [patientOrganizations]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = () => {
    if (!selected) {
      enqueueSnackbar("Please select a project", {
        variant: "error",
      });

      return;
    }

    if (isInProject) {
      return;
    }

    if (multipleAdd) {
      if (type === "patient_organizations") {
        const existingProjectOrgIds = selected?.obj[0]?.project_patient_organizations?.map(o => o.gsk_patient_organization.id) || [];
        const filteredIds = companyIds
          .filter(id => !existingProjectOrgIds.includes(id));

        if (filteredIds?.length === 0) {
          popup.hide();
          enqueueSnackbar('All of your selections are already part of this project.', {
            variant: 'warning'
          })
          return;
        }

        const multiOrganizationsPayload = {
          project_patient_organizations: companyIds
            .filter(id => !existingProjectOrgIds.includes(id))
            .map((id) => ({
            project_id: selected.val,
            gsk_patient_organization_id: id,
            added_by_id: session.id,
            stage_id: null,
          })),
          project_id: selected.val,
          council_id: selectedCouncil.id,
          patientOrg: false,
          stage_id: null,
          enqueueSnackbar,
          multipleAdd: true,
          history,
        };

        dispatch(projectPatientOrgNewMultiple(multiOrganizationsPayload));
        popup.hide();

        return;
      }

      const existingProjectCompaniesIds = selected?.obj[0]?.project_companies?.map(o => o.company.id) || [];
      const filteredIds = companyIds
        .filter(id => !existingProjectCompaniesIds.includes(id));

      if (filteredIds?.length === 0) {
        popup.hide();
        enqueueSnackbar('All of your selections are already part of this project.', {
          variant: 'warning'
        })
        return;
      }

      const multiCompaniesPayload = {
        project_companies: companyIds
          .filter(id => !existingProjectCompaniesIds.includes(id))
          .map((id) => ({
          project_id: selected.val,
          company_id: id,
          added_by_id: session.id,
          stage_id: null,
        })),
        project_id: selected.val,
        council_id: selectedCouncil.id,
        patientOrg: false,
        stage_id: null,
        enqueueSnackbar,
        multipleAdd: true,
        history,
      };

      dispatch(projectCompanyNewMultiple(multiCompaniesPayload));
      popup.hide();

      return;
    }

    if (type === "patient_organizations") {
      const patientOrgData = {
        project_id: selected.val,
        gsk_patient_organization_id: companyId,
        added_by_id: session.id,
        stage_id: undefined,
        enqueueSnackbar,
        history,
      };

      dispatch(patientOrganizationProjectAddMany(patientOrgData));
    } else {
      const data = {
        projectId: selected.val,
        companyId,
        stageId: undefined,
        userId: session.id,
        enqueueSnackbar,
        type,
        history,
        isProduct,
      };

      dispatch(projectCompanyNew(data));
    }

    popup.hide();
  };

  const handleOptionSelect = (value) => {
    setSelected(value);
  };

  const dropOptions = useMemo(() => {
    if (!projectList instanceof Array) return [];

    // if (multipleAdd) {
    //   return projectList;
    // }

    return projectList || [];

    // const topPropSource = type === "patient_organizations" ? 'project_patient_organizations' : 'project_companies';
    // const lowLevelPropSource = type === "patient_organizations" ? 'gsk_patient_organization' : 'company';

    // return projectList?.filter(
    //   (proj) =>
    //     !proj[topPropSource].some((el) => el[lowLevelPropSource].id === companyId) &&
    //     (type === "patient_organizations"
    //       ? !proj.stage_template.default_template
    //       : !proj.stage_template.patient_organization)
    // ) || [];
  }, [projectList, multipleAdd]);

  return (
    <div className={styles.addCompanyWrp}>
      <ModalBody>
        {
          projectList.length === 0 ? (
            <div className={styles.loaderWrp}>
              <Loading customText="Getting projects list..." />
            </div>
          ) : (
            <>
              <Label>Projects</Label>
              <DropSelect
                onChange={handleOptionSelect}
                placeholder={
                  type === "patient_organization" ? `Add Organization${multipleAdd ? 's' : ''} to project` : "Add to project"
                }
                labelField="name"
                valueField="id"
                searchBy="name"
                options={dropOptions || []}
              />
            </>
          )
        }
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        {loading ? (
          <div className={styles.loaderWrp}>
            <Loading />
          </div>
        ) : (
          <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
            {showTooltip && type === "patient_organizations" && isInProject && (
              <div className={styles.modalTooltip}>
                <p>This organization is already part of this project</p>
              </div>
            )}
            <Button
              disabled={type === "patient_organizations" && isInProject}
              type="submit"
              btn={
                type === "patient_organizations" && isInProject ? BtnType.OUTLINE : BtnType.REGULAR
              }
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </div>
        )}
      </ModalFooter>
    </div>
  );
};

export default React.memo(AddCompanyComponent);

export const moreOptionTypes = {
  edit: 5,
  delete: 6,
};

export const moreOptions = [
  {
    val: moreOptionTypes.edit,
    text: "Change Role",
  },
  {
    val: moreOptionTypes.delete,
    text: "Remove From Project",
  },
];

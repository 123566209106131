import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import styles from "./styles.module.scss";
import {
  CheckBox,
  Label,
  MoreOptions,
  UserInline,
  UserInlineTypes,
} from "../../../../primitives";
import { moreOptions } from "./constant";
import { Icon, lgSize } from "../../../../../common/icon";
import { parceVisibility } from "../activity.constant";
import AccessToggle from "../../../../../common/components/customFields/accessToggle";
import { tConvert, convertDateTimezone } from "../../../../../common/helper";

const EventComponent = (props) => {
  const {
    event,
    teamList,
    handleEditClick,
    handleDeleteClick,
    orgType,
    hasAccess,
    blockFunctionality = false,
    isLastElem,
    isAddedByMe,
    isMobile,
  } = props;

  const handleMoreOptionClick = (props) => {
    if (props === 5) {
      handleEditClick(event);
    }
    if (props === 6) {
      handleDeleteClick(event);
    }
  };

  return (
    <div className={`${styles.wrapper} ${isLastElem ? styles.noBorder : ""}`}>
      <div className={styles.container}>
        <div className={styles.titleRow}>
          {isMobile ? null : (
            <div className={styles.iconWrp}>
              <Icon
                {...lgSize}
                size={24}
                icon="icn-calendar"
                className={styles.icon}
              />
            </div>
          )}
          <h5 className={styles.title}>
            {isMobile ? (
              <Icon
                {...lgSize}
                size={24}
                icon="icn-calendar"
                className={styles.icon}
              />
            ) : null}
            Event
          </h5>
          <div className={styles.rightWrp}>
            {isAddedByMe ? (
              <div className={styles.toggle}>
                <AccessToggle item={event} orgType={orgType} />
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <span className={styles.date}>
                  {event?.updated_at
                    ? format(new Date(event.updated_at), "EEEE, MMMM dd, yyyy ")
                    : null}
                </span>
                <div className={styles.colMore}>
                  {hasAccess && (
                    <MoreOptions
                      options={moreOptions}
                      className={styles.moreOptWrp}
                      onClick={handleMoreOptionClick}
                      customSvgIcon
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex align-items-start justify-content-between mt-4">
          <div className="d-flex flex-column">
            {event?.title && <div className={styles.name}>{event.title}</div>}
            {event?.location && (
              <div className={styles.location}>{event.location}</div>
            )}
            {event?.date && (
              <div className={styles.date}>
                {format(
                  convertDateTimezone(event?.date),
                  "EEEE, MMMM dd, yyyy ",
                )}
              </div>
            )}
            {event?.event_time && (
              <div className={styles.time}>{tConvert(event.event_time)}</div>
            )}
          </div>
          {isAddedByMe && (
            <div className="d-flex align-items-center">
              <span className={styles.date}>
                {event?.updated_at
                  ? format(new Date(event.updated_at), "EEEE, MMMM dd, yyyy ")
                  : null}
              </span>
              <div className={styles.colMore}>
                {hasAccess && (
                  <MoreOptions
                    options={moreOptions}
                    className={styles.moreOptWrp}
                    onClick={handleMoreOptionClick}
                    customSvgIcon
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className={classNames(styles.detail, styles.note)}
          dangerouslySetInnerHTML={{ __html: event?.description || "" }}
        />
        <div className={styles.attendeesWrp}>
          {event.attendees.length !== 0 && (
            <>
              <div className={styles.label}>Attendees: </div>
              <div className={styles.listWrp}>
                {event?.attendees?.map((attendee) => (
                  <div key={attendee.user_id} className={styles.itemWrp}>
                    <UserInline
                      className={styles.userIcon}
                      classObj={{ name: styles.userName }}
                      userId={attendee.user_id}
                      type={UserInlineTypes.WITH_NAME}
                      showMine={false}
                      first={attendee.first_name}
                      last={attendee.last_name}
                      avatar={attendee.avatar}
                      local
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className={styles.infoWrp}>
          <div className="w-50 d-flex">
            {event?.projects && event?.projects[0] && (
              <div className={styles.dataRow}>
                <div className={styles.label}>Project</div>
                {event?.projects.map((project) => (
                  <Link key={project.id} to={`/projects/${project.id}`}>
                    <div className={styles.value}>{project.name}</div>
                  </Link>
                ))}
              </div>
            )}
            <div className={styles.dataRow}>
              <div className={styles.label}>
                {event.updated_at !== event.created_at
                  ? "Updated by"
                  : "Created by"}
              </div>
              <div className={styles.value}>
                <Link to={`/people/internal/${event.creator_id}`}>
                  {event && event.created_by}
                </Link>
              </div>
            </div>

            {event.visibility && (
              <div className={styles.dataRow}>
                <div className={styles.label}>Visible</div>
                <div className={styles.value}>
                  {event
                    && parceVisibility(event.visibility, teamList, event.team_ids)}
                </div>
              </div>
            )}
          </div>
          <div className="w-50">
            {orgType === "patient" ? (
              <div className={styles.centerWrp}>
                <div className={classNames(styles.infoWrpColumn, "mb-3")}>
                  <div className={styles.label}>Discussion Topic:</div>
                  <div className={styles.detail}>
                    {event && event.discussion_topics
                      ? event.discussion_topics.map((d) => `${d}, `)
                      : "-"}
                  </div>
                </div>
                <div className={classNames(styles.infoWrpColumn, "mb-3")}>
                  <div className={styles.label}>Roles Of Key Attendees:</div>
                  <div className={styles.detail}>
                    {event && event.roles_of_attendees}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Label>
                    {orgType === "patient"
                      ? "Initial engagement"
                      : "Initial introductory event"}
                    :{" "}
                  </Label>
                  <CheckBox
                    className="ml-2"
                    isChecked={event && event.initial_introductory_meeting}
                    disabled={blockFunctionality}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default EventComponent;

export const PLANT_STATUSES = [
  {
    id: 'under_review',
    name: 'Under Review'
  },
  {
    id: 'possible_replication',
    name: 'Possible Replication'
  },
  {
    id: 'different_solution',
    name: 'Different Solution'
  },
  {
    id: 'not_applicable',
    name: 'Not Applicable for this Plant'
  },
]

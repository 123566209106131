import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { BtnType, Button } from "../../../../primitives";
import { ModalFooter } from "../../../../../application/modal";
import UserMultiSelectSearch from "../../../../../common/components/usersSearch";
import styles from "./editSubmitter.module.scss";
import { appModelSettingSet } from "../../../../../application/app.action";
import { editIdeaAttempt } from "../../../idea.action";

const EditSubmitter = ({ selectedIdea }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    appReducer: { modalSetting },
  } = useSelector((state) => state);
  const [selectedSubmitter, setSelectedSubmitter] = useState([]);

  useEffect(() => {
    if (selectedIdea?.submitter) {
      setSelectedSubmitter([
        {
          ...selectedIdea.submitter,
          id: selectedIdea.submitter.user_id,
        },
      ]);
    }

    return () => null;
  }, [selectedIdea]);

  const handleCancelClick = () => {
    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
  };

  const handleSubmitterSave = () => {
    const payload = {
      idea: {
        id: selectedIdea.id,
        ideas_template_id: selectedIdea.ideas_template_id,
        submitter_id: selectedSubmitter[0].id,
      },
      cb: () => {
        enqueueSnackbar("Successfully updated submitter", {
          variant: "success",
        });

        dispatch(appModelSettingSet({ ...modalSetting, show: false }));
      },
      enqueueSnackbar,
    };

    dispatch(editIdeaAttempt(payload));
  };

  return (
    <div className={styles.editSubmitter}>
      <div className={styles.customPadding}>
        <UserMultiSelectSearch
          multi={false}
          placeholder="Choose another submitter"
          labelField="full_name"
          valueField="id"
          searchBy="full_name"
          hasAll={false}
          onChange={setSelectedSubmitter}
          selectedValues={selectedSubmitter}
        />
      </div>
      <ModalFooter>
        <Button
          className="mr-3"
          btn={BtnType.FRAME_LESS}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button onClick={handleSubmitterSave} btn={BtnType.REGULAR}>
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default memo(EditSubmitter);

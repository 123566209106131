import React, { useState } from "react";

import styles from "../rightWindow.module.scss";
import { Button, BtnType } from "../../../../primitives";
import LoadingComponent from "modules/primitives/loading/loading.component";

const checkForRole = (
  addToTraction,
  item,
  setLoading
) => {
  return (
    <Button
      icon="icn-add"
      onClick={() => {
        setLoading(true);
        addToTraction({
          permalink: item.properties.identifier.permalink,
          name: item.properties.name,
          website: item.properties.website_url,
        })
      }}
      btn={BtnType.FRAME_LESS}
      className={styles.addCompanyButton}
    >
      Add company
    </Button>
  );
};

const CrunchItemResultComponent = ({
  addToTraction,
  item,
  history,
  selectedCouncil,
  session,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="d-flex w-100">
      <div className={styles.itemWrp}>
        <div className={styles.itemDescContainer}>
          {" "}
          {item.properties.short_description || item.properties.description}{" "}
        </div>
        <div className={styles.buttonContainer}>
          {!item["already_exists?"] ? (
            <>
              {
                loading ? (
                  <LoadingComponent customText="Adding Company..." />
                ) : (
                  checkForRole(addToTraction, item, setLoading)
                )
              }
            </>
          ) : (
            <Button
              icon="icn-checkbox-button"
              onClick={() => history.push(`/companies/${item.slug || item.company_id}`)}
              btn={BtnType.FRAME_LESS}
              className={styles.addCompanyButton}
            >
              Go To Profile
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(CrunchItemResultComponent);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlantsAreas,
  getThemes,
  getTopicFilter,
} from "common/actions/common.action";
import { useHistory } from "react-router-dom";
import { httpGet } from "common/httpCall";
import {
  numberWithCommas,
  queryParamStringToArray,
  queryStringUrlReplacement,
  useQuery,
} from "common/helper";
import CloseIcon from "common/icon/closeIcon.component";
import Icon from "common/icon/icon.component";
import { flat } from "common/helper";

import { defaultPayload } from "../../projectsMain.constant";
import TechnologiesSelector from "./technologiesSelctor";

import { DropSelect } from "../../../../primitives";
import SelectedFilterTagsRow from "./selectedFilterTagsRow/selectedFilterTagsRow";
import { arrToString, getProjectsFields } from "./projFiltersUtils";
import styles from "./styles.module.scss";

export const FiltersRow = (props) => {
  const {
    options,
    filterOptionSearchResult,
    setFilterOptionSearchResult,
    specicStyles,
    checkIfClearAllNeeded,
    selectedFilter,
    setSelectedFilter,
    requestTheData,
    selectedTab,
    clearFilters,
  } = props;
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const history = useHistory();
  const area_param = useQuery().get("area_ids");
  const rating_param = useQuery().get("ratings");
  const savings_param = useQuery().get("savings");
  const themes_param = useQuery().get("theme_ids");
  const skills_team = useQuery().get("skills_team");
  const founded_by = useQuery().get("founded_by");
  const fps_category = useQuery().get("fps_category");
  const program = useQuery().get("program");
  const plant_param = useQuery().get("plant_ids");
  const replicated_param = useQuery().get("original");
  const searchKey = useQuery().get("searchKey");
  const topic_param = useQuery().get("topic_ids");
  const on_filt_param = useQuery().get("on_filt");
  const stage_param = useQuery().get("stage_ids");
  const plant_region_ids_param = useQuery().get("plant_region_ids");
  const project_owner_ids_param = useQuery().get("project_owner_ids");
  const stage_template_ids_param = useQuery().get("stage_template_ids");

  const [showDropdown, setStateShowDropdown] = useState(
    on_filt_param ? true : false
  );
  const [loadedTopicFilterOpt, setLoadingTopicFilterOpt] = useState(false);
  const [loadedThemesFilterOpt, setLoadingThemesFilterOpt] = useState(false);
  const [loadedProjFieldsFilterOpt, setLoadingProjFieldsFilterOpt] =
    useState(false);
  const [loadedAreasPlantsFilterOpt, setLoadingAreasPlantsFilterOpt] =
    useState(false);
  const [loadedRegionsOpt, setLoadingRegionsFilterOpt] = useState(false);
  const [loadingProjOwnersFilterOpt, setLoadingProjOwnersFilterOpt] =
    useState(false);
  const [loadingProjTemplatesFilterOpt, setLoadingProjTemplatesFilterOpt] =
    useState(false);

  const setShowDropdown = (value) => {
    const search = queryStringUrlReplacement(
      history.location.search,
      "on_filt",
      value ? true : ""
    );

    history.push({
      pathname: "/projects",
      search,
      state: {
        ...history.location?.state,
        on_filt: value ? true : "",
      },
    });
    setStateShowDropdown(value);
  };

  const searchFn = (type, query = "*") => {
    if (type === "topic_ids" || type === "all") {
      dispatch(
        getTopicFilter({
          cb: (res) => {
            setFilterOptionSearchResult((prev) => {
              setLoadingTopicFilterOpt(true);
              return { ...prev, topic_ids: res.response };
            });
          },
          selectedCouncilId: selectedCouncil?.id,
        })
      );
    }
    if (type === "theme_ids" || type === "all") {
      dispatch(
        getThemes({
          query,
          cb: (res) => {
            setFilterOptionSearchResult((prev) => {
              setTimeout(() => {
                setLoadingThemesFilterOpt(true);
              }, 100);

              return { ...prev, theme_ids: res.response?.themes };
            });
          },
          selectedCouncilId: selectedCouncil?.id,
        })
      );
    }

    if (type === "area_ids" || type === "plants_ids" || type === "all") {
      dispatch(
        getPlantsAreas({
          cb: (res) => {
            setFilterOptionSearchResult((prev) => {
              setLoadingAreasPlantsFilterOpt(true);
              return {
                ...prev,
                area_ids: res.response.areas,
                plant_ids: res.response.plants,
              };
            });
          },
        })
      );
    }
  };

  useEffect(() => {
    if (selectedCouncil) {
      if (selectedCouncil?.traction_tag === "ford") {
        getProjectsFields(
          dispatch,
          setFilterOptionSearchResult,
          setLoadingProjFieldsFilterOpt
        );
        searchFn("all");
      }
    }
  }, [selectedCouncil]);

  const getInitialData = () => {
    searchFn("all");
    httpGet({
      call: "plants/regions",
    }).subscribe((res) => {
      setFilterOptionSearchResult((prev) => {
        setLoadingRegionsFilterOpt(true);
        return {
          ...prev,
          plant_region_ids: res.response,
        };
      });
    });
    httpGet({
      call: "projects/project_owners/dropdown",
    }).subscribe((res) => {
      setFilterOptionSearchResult((prev) => {
        setLoadingProjOwnersFilterOpt(true);
        return {
          ...prev,
          project_owner_ids: res.response.map((el) => ({
            id: el.user_id,
            name: el.full_name,
          })),
        };
      });
    });
    httpGet({
      call: "projects/stage_templates/stages",
    }).subscribe((res) => {
      setFilterOptionSearchResult((prev) => {
        setLoadingProjTemplatesFilterOpt(true);

        return {
          ...prev,
          stage_template_ids: res.response.map((el) => ({
            id: el.id,
            name: el.name,
          })),
        };
      });
    });
  };

  useEffect(() => {
    if (
      loadedAreasPlantsFilterOpt &&
      loadedTopicFilterOpt &&
      loadedProjFieldsFilterOpt &&
      loadedThemesFilterOpt &&
      loadingProjOwnersFilterOpt &&
      loadingProjTemplatesFilterOpt
    ) {
      const setReplicated = () => {
        if (replicated_param !== "") {
          if (replicated_param === "true") {
            return true;
          }
          if (replicated_param === "false") {
            return false;
          }
        }
        return null;
      };
      const skills_team_param = queryParamStringToArray(skills_team || "");
      const founded_by_param = queryParamStringToArray(founded_by || "");
      const fps_category_param = queryParamStringToArray(fps_category || "");
      const program_param = queryParamStringToArray(program || "");
      const themes_values_Arr = queryParamStringToArray(themes_param || "");

      const stages_values_Arr = queryParamStringToArray(stage_param || "");
      const plant_region_ids_Arr = queryParamStringToArray(
        plant_region_ids_param || ""
      );
      const project_owner_ids_Arr = queryParamStringToArray(
        project_owner_ids_param || ""
      );
      const stage_template_ids_Arr = queryParamStringToArray(
        stage_template_ids_param || ""
      );

      const areas_values_Arr = queryParamStringToArray(area_param || "");
      const rating_values_Arr = queryParamStringToArray(rating_param || "");
      const savings_values_Arr = queryParamStringToArray(savings_param || "");
      const topic_values_Arr = queryParamStringToArray(topic_param || "");
      const filt_modal_state = on_filt_param && true;
      const replicated_value = setReplicated();
      const plant_values_Arr = queryParamStringToArray(plant_param || "");
      const flatTags = flat(
        filterOptionSearchResult?.topic_ids,
        "children_topics"
      );

      setStateShowDropdown(filt_modal_state);
      setSelectedFilter({
        ...selectedFilter,
        ratings: rating_values_Arr.map((el) => ({
          gte: Number(el.split("-")[0]),
          lte: Number(el.split("-")[1]),
        })),
        savings: savings_values_Arr.map((el) => ({
          gte: Number(el.split("-")[0]),
          lte: Number(el.split("-")[1]),
        })),
        original: replicated_value,
        area_ids: filterOptionSearchResult.area_ids.filter((area) => {
          return !!areas_values_Arr.includes(area.id);
        }),
        theme_ids: filterOptionSearchResult.theme_ids.filter((theme) => {
          return !!themes_values_Arr.includes(theme.id);
        }),
        stage_ids: filterOptionSearchResult.stage_ids.filter((stage) => {
          return !!stages_values_Arr.includes(stage.id);
        }),
        topic_ids: flatTags.filter((topic) => {
          return !!topic_values_Arr.includes(topic.id);
        }),
        plant_region_ids: filterOptionSearchResult.plant_region_ids.filter(
          (region) => {
            return !!plant_region_ids_Arr.includes(region.id);
          }
        ),
        project_owner_ids: filterOptionSearchResult.project_owner_ids.filter(
          (mem) => {
            return !!project_owner_ids_Arr.includes(mem.id);
          }
        ),
        stage_template_ids: filterOptionSearchResult.stage_template_ids.filter(
          (stage) => {
            return !!stage_template_ids_Arr.includes(stage.id);
          }
        ),

        project_field_values: [
          ...program_param,
          ...fps_category_param,
          ...skills_team_param,
          ...founded_by_param,
        ],
        plant_ids: filterOptionSearchResult.plant_ids.filter((plant) => {
          return !!plant_values_Arr.includes(plant.id);
        }),
        skills_team: skills_team_param,
        founded_by: founded_by_param,
        fps_category: fps_category_param,
        program: program_param,
      });
    }
  }, [
    loadedAreasPlantsFilterOpt,
    loadedTopicFilterOpt,
    loadedProjFieldsFilterOpt,
    loadedThemesFilterOpt,
    loadedRegionsOpt,
    loadingProjOwnersFilterOpt,
    loadingProjTemplatesFilterOpt,
  ]);
  useEffect(() => {
    if (showDropdown) {
      getInitialData();
    }
  }, [showDropdown]);

  const getProjFieldValues = (group) => {
    const subgroup = group === "project_field_values" ? "skills_team" : group;

    return selectedFilter[subgroup].map((item) => ({
      value: item,
      name: item,
    }));
  };

  const getValues = (option) => {
    return selectedFilter[option?.serverKeys[0]]?.map((elem) => ({
      value: [elem.gte, elem.lte],
      name: `${option.value === "savings" ? "$" : ""} ${
        numberWithCommas(elem.gte) || "0"
      } - ${option.value === "savings" ? "$" : ""}${
        numberWithCommas(elem.lte) || "Max."
      }`,
    }));
  };

  const setFilterOption = (type, value, form) => {
    const ids = {
      theme_ids: selectedFilter?.theme_ids?.map((opt) => opt.id) || [],
      plant_ids: selectedFilter?.plant_ids?.map((opt) => opt.id) || [],
      area_ids: selectedFilter?.area_ids?.map((opt) => opt.id) || [],
      stage_ids: selectedFilter?.stage_ids?.map((opt) => opt.id) || [],
      plant_region_ids:
        selectedFilter?.plant_region_ids?.map((opt) => opt.id) || [],
      project_owner_ids:
        selectedFilter?.project_owner_ids?.map((opt) => opt.id) || [],
      stage_template_ids:
        selectedFilter?.stage_template_ids?.map((opt) => opt.id) || [],
    };
    if (
      [
        "project_field_values",
        "fps_category",
        "founded_by",
        "program",
      ].includes(type)
    ) {
      const projFilterSubCategory =
        type === "project_field_values" ? "skills_team" : type;
      const filtersStrings = value.map((el) => el.name);
      let project_field_values;

      setSelectedFilter((prev) => {
        project_field_values = [
          "skills_team",
          "fps_category",
          "founded_by",
          "program",
        ]
          .map((group) => {
            if (group === projFilterSubCategory) {
              return filtersStrings;
            }
            return prev[group];
          })
          .flat();
        return {
          ...prev,
          project_field_values,
          [projFilterSubCategory]: filtersStrings,
        };
      });

      const search = queryStringUrlReplacement(
        history.location.search,
        projFilterSubCategory,
        filtersStrings
      );

      history.push({
        pathname: "/projects",
        search,
        state: {
          ...history.location?.state,
          [projFilterSubCategory]: filtersStrings,
          project_field_values,
        },
      });
      requestTheData(searchKey, 1, {
        ...selectedFilter,
        ...ids,
        project_field_values,
      });
    } else {
      if (form === "array") {
        setSelectedFilter((prev) => {
          const filtered = {
            ...prev,
            [type]: value,
          };
          return filtered;
        });
        const search = queryStringUrlReplacement(
          history.location.search,
          type,
          value.map((opt) => opt.id)
        );

        history.push({
          pathname: "/projects",
          search,
          state: {
            ...history.location?.state,
            [type]: arrToString([...value.map((opt) => opt.id)]),
          },
        });
        requestTheData(searchKey, 1, {
          ...selectedFilter,
          ...ids,
          [type]: [...value.map((opt) => opt.id)],
        });
        return;
      }
      if (selectedFilter[type]?.find((element) => element.id === value?.id)) {
        setSelectedFilter((prev) => {
          const filtered = {
            ...prev,
            [type]: [...prev[type]].filter((elem) => elem?.id !== value?.id),
          };
          return filtered;
        });

        // return;
      } else {
        setSelectedFilter((prev) => {
          return {
            ...prev,
            [type]: [...prev[type], value].filter((elem) => elem),
          };
        });
      }

      requestTheData(searchKey, 1, {
        ...selectedFilter,
        ...ids,
        [type]: [...selectedFilter[type].map((opt) => opt.id), value?.id],
      });

      const search = queryStringUrlReplacement(history.location.search, type, [
        ...selectedFilter[type].map((opt) => opt.id),
        value?.id,
      ]);

      history.push({
        pathname: "/projects",
        search,
        state: {
          ...history.location?.state,
          [type]: arrToString([
            ...selectedFilter[type].map((opt) => opt.id),
            value?.id,
          ]),
        },
      });
    }
  };
  const handleRangeFilterValueSelect = (value, range) => {
    const ids = {
      theme_ids: selectedFilter?.theme_ids?.map((opt) => opt.id) || [],
      plant_ids: selectedFilter?.plant_ids?.map((opt) => opt.id) || [],
      area_ids: selectedFilter?.area_ids?.map((opt) => opt.id) || [],
      stage_ids: selectedFilter?.stage_ids?.map((opt) => opt.id) || [],
      plant_region_ids:
        selectedFilter?.plant_region_ids?.map((opt) => opt.id) || [],
      project_owner_ids:
        selectedFilter?.project_owner_ids?.map((opt) => opt.id) || [],
      stage_template_ids:
        selectedFilter?.stage_template_ids?.map((opt) => opt.id) || [],
    };

    if (value === "ratings" || value === "savings") {
      setSelectedFilter((prev) => {
        const filtersStrings = [
          ...range.map((el) => `${el.value[0]}-${el.value[1]}`),
        ];

        const search = queryStringUrlReplacement(
          history.location.search,
          value === "ratings" ? "ratings" : "savings",
          filtersStrings
        );

        history.push({
          pathname: "/projects",
          search,
          state: {
            ...history.location?.state,
            [value]: filtersStrings,
          },
        });

        requestTheData(searchKey, 1, {
          ...prev,
          ...ids,
          project_field_values: [
            ...prev["project_field_values"].map((opt) => opt),
          ],
          [value]: range.map((el) => ({ gte: el.value[0], lte: el.value[1] })),
        });

        return {
          ...prev,
          [value]: range.map((el) => ({ gte: el.value[0], lte: el.value[1] })),
        };
      });
    }

    if (value === "replicated") {
      setSelectedFilter((prev) => {
        return {
          ...prev,
          original: range,
        };
      });

      const filtersStrings = range;
      const search = queryStringUrlReplacement(
        history.location.search,
        "original",
        filtersStrings
      );

      history.push({
        pathname: "/projects",
        search,
        state: {
          ...history.location?.state,
          original: filtersStrings,
        },
      });

      requestTheData(searchKey, 1, {
        ...selectedFilter,
        ...ids,
        project_field_values: [
          ...selectedFilter["project_field_values"].map((opt) => opt),
        ],
        original: range,
      });
    }
  };

  const removeFilterTag = (type, id, project_field_values_sub_cat) => {
    const ids = {
      theme_ids: selectedFilter?.theme_ids?.map((opt) => opt.id) || [],
      plant_ids: selectedFilter?.plant_ids?.map((opt) => opt.id) || [],
      area_ids: selectedFilter?.area_ids?.map((opt) => opt.id) || [],
      stage_ids: selectedFilter?.stage_ids?.map((opt) => opt.id) || [],
      plant_region_ids:
        selectedFilter?.plant_region_ids?.map((opt) => opt.id) || [],
      project_owner_ids:
        selectedFilter?.project_owner_ids?.map((opt) => opt.id) || [],
      stage_template_ids:
        selectedFilter?.stage_template_ids?.map((opt) => opt.id) || [],
    };

    if (type === "savings") {
      setSelectedFilter((prev) => {
        return {
          ...prev,
          [type]: [...prev[type].filter((el) => el.gte !== id.gte)],
        };
      });

      const search = queryStringUrlReplacement(history.location.search, type, [
        ...selectedFilter[type]
          .filter((el) => el.gte !== id.gte)
          .map((el) => `${el.gte}-${el.lte}`),
      ]);

      history.push({
        pathname: "/projects",
        search,
        state: {
          ...history.location?.state,

          [type]: arrToString([
            ...selectedFilter[type]
              .filter((el) => el.id !== id)
              .map((el) => `${el.gte}-${el.lte}`),
          ]),
        },
      });

      requestTheData(searchKey, 1, {
        ...selectedFilter,
        ...ids,
        [type]: [...selectedFilter[type].filter((el) => el.gte !== id.gte)],
      });
    } else if (type === "project_field_values") {
      const categoryType =
        project_field_values_sub_cat === "project_field_values"
          ? "skills_team"
          : project_field_values_sub_cat;
      setSelectedFilter((prev) => {
        // const updateValue =
        const search = queryStringUrlReplacement(
          history.location.search,
          categoryType,
          [...prev[categoryType].filter((field) => field !== id)]
        );

        history.push({
          pathname: "/projects",
          search,
          state: {
            ...history.location?.state,
            [categoryType]: arrToString([
              [...prev[categoryType].filter((field) => field !== id)],
            ]),
          },
        });
        return {
          ...prev,
          [categoryType]: [
            ...prev[categoryType].filter((field) => field !== id),
          ],
          project_field_values: [
            ...prev.project_field_values.filter((field) => field !== id),
          ],
        };
      });

      requestTheData(searchKey, 1, {
        ...selectedFilter,
        ...ids,
        project_field_values: [
          ...selectedFilter.project_field_values.filter(
            (field) => field !== id
          ),
        ],
      });
    } else if (type === "ratings") {
      setSelectedFilter((prev) => {
        const filtered = [
          ...prev.ratings.map((field) => {
            if (field.gte !== id.gte) {
              return field;
            }
          }),
        ].filter((el) => el);
        return {
          ...prev,
          ratings: filtered,
        };
      });
      const strings = [
        ...selectedFilter["ratings"]
          .filter((field) => field.gte !== id.gte)
          .map((el) => `${el.gte}-${el.lte}`),
      ];

      const search = queryStringUrlReplacement(
        history.location.search,
        "ratings",
        strings
      );

      history.push({
        pathname: "/projects",
        search,
        state: {
          ...history.location?.state,
          ratings: strings,
        },
      });
      requestTheData(searchKey, 1, {
        ...selectedFilter,
        ...ids,
        ratings: [
          ...selectedFilter["ratings"].filter((field) => field.gte !== id.gte),
        ],
      });
    } else if (type === "replicated") {
      setSelectedFilter((prev) => {
        return {
          ...prev,
          original: null,
        };
      });
      const search = queryStringUrlReplacement(
        history.location.search,
        "original",
        ""
      );

      history.push({
        pathname: "/projects",
        search,
        state: {
          ...history.location?.state,
          original: "",
        },
      });
      requestTheData(searchKey, 1, {
        ...selectedFilter,
        ...ids,
        original: null,
      });
    } else if (type === "all") {
      clearFilters();
      requestTheData(
        searchKey,
        1,
        defaultPayload(selectedCouncil?.traction_tag, selectedTab)
      );
    } else {
      setSelectedFilter((prev) => {
        return {
          ...prev,
          [type]: [...prev[type].filter((el) => el.id !== id)],
        };
      });

      const search = queryStringUrlReplacement(history.location.search, type, [
        ...selectedFilter[type]
          .filter((el) => el.id !== id)
          .map((opt) => opt.id),
      ]);

      history.push({
        pathname: "/projects",
        search,
        state: {
          ...history.location?.state,

          [type]: arrToString([
            ...selectedFilter[type]
              .filter((el) => el.id !== id)
              .map((opt) => opt.id),
          ]),
        },
      });

      requestTheData(searchKey, 1, {
        ...selectedFilter,
        ...ids,
        [type]: [...selectedFilter[type].filter((el) => el.id !== id)],
      });
    }
  };

  const removeCategory = (categoryToRemove) => {
    const ids = {
      theme_ids: selectedFilter?.theme_ids.map((opt) => opt.id),
      plant_ids: selectedFilter?.plant_ids.map((opt) => opt.id),
      area_ids: selectedFilter?.area_ids.map((opt) => opt.id),
      stage_ids: selectedFilter?.stage_ids.map((opt) => opt.id),
      plant_region_ids:
        selectedFilter?.plant_region_ids?.map((opt) => opt.id) || [],
      project_owner_ids:
        selectedFilter?.project_owner_ids?.map((opt) => opt.id) || [],
      stage_template_ids:
        selectedFilter?.stage_template_ids?.map((opt) => opt.id) || [],
    };
    if (
      [
        "skills_team",
        "project_field_values",
        "fps_category",
        "founded_by",
        "program",
      ].includes(categoryToRemove)
    ) {
      const projFilterSubCategory =
        categoryToRemove === "project_field_values"
          ? "skills_team"
          : categoryToRemove;
      setSelectedFilter((prev) => {
        return {
          ...prev,
          [projFilterSubCategory]: [],
          project_field_values: prev.project_field_values.filter((item) => {
            return !filterOptionSearchResult[categoryToRemove].includes(item);
          }),
        };
      });
      requestTheData(searchKey, 1, {
        ...selectedFilter,
        ...ids,
        [projFilterSubCategory]: [],
        project_field_values: selectedFilter.project_field_values.filter(
          (item) => {
            return !filterOptionSearchResult[categoryToRemove].includes(item);
          }
        ),
      });
    } else {
      setSelectedFilter((prev) => {
        return {
          ...prev,
          [categoryToRemove]: [],
        };
      });
      requestTheData(searchKey, 1, {
        ...selectedFilter,
        ...ids,
        [categoryToRemove]: [],
      });
    }
    const projFilterSubCategory =
      categoryToRemove === "project_field_values"
        ? "skills_team"
        : categoryToRemove;
    const search = queryStringUrlReplacement(
      history.location.search,
      projFilterSubCategory,
      []
    );
    history.push({
      pathname: "/projects",
      search,
      state: {
        ...history.location?.state,
        [projFilterSubCategory]: [],
        [categoryToRemove]: [],
      },
    });
  };
  const getClearLabel = () => (
    <span className="text-muted small cursor-pointer">clear</span>
  );

  return (
    <>
      <div className={`${styles.inputContainer}`} style={{ ...specicStyles }}>
        <div
          className={styles.inputBox}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <div>Filters</div>{" "}
          <div className={styles.filterButton}>
            <Icon
              style={
                showDropdown
                  ? { transform: "rotate(0deg)", color: "#2d83ae" }
                  : { transform: "rotate(-90deg)" }
              }
              size={10}
              icon="icn-table-down-chevron"
              className={styles.iconChevron}
            />
          </div>
        </div>
        {showDropdown && (
          <div
            className={styles.filterOptionsRow}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {options.map((option, i) => {
              return (
                <div
                  key={`${option?.value}_${option.text}_${i}`}
                  className={styles.filterOption}
                >
                  {option.text}
                  {option.searchable && option.value !== "topic_ids" && (
                    <div className={styles.inputBlock}>
                      <DropSelect
                        multi
                        className={styles.dropWrapper}
                        labelField="name"
                        // disabled={false}
                        valueField="id"
                        searchBy="name"
                        name="e"
                        disabled={
                          selectedTab === "project_stage_idea" &&
                          option.text === "Stage"
                        }
                        options={filterOptionSearchResult[option?.value]
                          .map((item) => {
                            return {
                              value: item?.id || item,
                              name: item,
                              ...item,
                            };
                          })
                          .filter((item) => {
                            if (
                              selectedTab === "all_projects" &&
                              option.text === "Stage" &&
                              item.name === "Idea"
                            ) {
                              return false;
                            }
                            return item;
                          })}
                        onChange={(e) => {
                          setFilterOption(option.value, e.obj, "array");
                        }}
                        values={selectedFilter[option.value]}
                        clearable
                        optionRenderer={({ item, props, state, methods }) => {
                          return (
                            <span className={styles.selectedItem}>
                              <span>{item.name} </span>
                              <span
                                className={styles.closeIcon}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  removeFilterTag(
                                    option?.value,
                                    item?.value?.id || item?.id
                                  );
                                  methods.removeItem(null, item);
                                }}
                              >
                                {" "}
                                <CloseIcon />{" "}
                              </span>
                            </span>
                          );
                        }}
                        clearRenderer={({ props, state, methods }) => (
                          <div
                            className={styles.clearDropdownButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              methods.clearAll();
                              removeCategory(option.value);
                            }}
                          >
                            {getClearLabel()}
                          </div>
                        )}
                      />
                    </div>
                  )}
                  {option.value === "topic_ids" && (
                    <>
                      <TechnologiesSelector
                        topics={filterOptionSearchResult[option.value]}
                        selectedTopics={
                          selectedFilter[option.value]?.map((el) => {
                            return el?.topic_id;
                          }) || []
                        }
                        handleTagSelect={(e) => {
                          setFilterOption(
                            option.value,
                            [...selectedFilter[option.value], ...e],
                            "array"
                          );
                        }}
                        handleTagRemove={(e) => {
                          setFilterOption(option.value, e, "array");
                        }}
                        removeCategory={() => removeCategory(option.value)}
                        getClearLabel={getClearLabel}
                      />
                    </>
                  )}

                  {option.namesOnly && (
                    <DropSelect
                      multi
                      className={styles.dropWrapper}
                      labelField="name"
                      disabled={false}
                      valueField="value"
                      searchBy="name"
                      optionRenderer={({ item, props, state, methods }) => {
                        return (
                          <span className={styles.selectedItem}>
                            <span>{item.value} </span>
                            <span
                              className={styles.closeIcon}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                removeFilterTag(
                                  "project_field_values",
                                  item.value,
                                  option.value
                                );
                                methods.removeItem(null, item);
                              }}
                            >
                              {" "}
                              <CloseIcon />{" "}
                            </span>
                          </span>
                        );
                      }}
                      options={filterOptionSearchResult[option.value]?.map(
                        (item) => {
                          return {
                            value: item,
                            name: item,
                          };
                        }
                      )}
                      onChange={(e) => {
                        if (e.obj?.length) {
                          setFilterOption(option.value, e.obj, "array");
                        }
                      }}
                      values={getProjFieldValues(option.value)}
                      clearable
                      clearRenderer={({ props, state, methods }) => (
                        <div
                          className={styles.clearDropdownButton}
                          onClick={(e) => {
                            e.stopPropagation();
                            methods.clearAll();
                            removeCategory(option.value);
                          }}
                        >
                          {getClearLabel()}
                        </div>
                      )}
                    />
                  )}
                  {option.range && (
                    <DropSelect
                      multi
                      className={styles.dropWrapper}
                      labelField="name"
                      disabled={false}
                      valueField="value"
                      searchBy="name"
                      options={option.ranges.map((range) => {
                        return {
                          value: range,
                          name: `${option.value === "savings" ? "$" : ""} ${
                            numberWithCommas(range[0]) || "0"
                          } - ${option.value === "savings" ? "$" : ""}${
                            numberWithCommas(range[1]) || "Max."
                          }`,
                        };
                      })}
                      onChange={(e) => {
                        if (e.val === "") {
                          removeCategory(option.value);

                          return;
                        }

                        handleRangeFilterValueSelect(
                          option.serverKeys[0],
                          e.obj
                        );
                      }}
                      values={selectedFilter[option?.serverKeys[0]]?.map(
                        (elem) => ({
                          value: [elem.gte, elem.lte],
                          name: `${option.value === "savings" ? "$" : ""} ${
                            numberWithCommas(elem.gte) || "0"
                          } - ${option.value === "savings" ? "$" : ""}${
                            numberWithCommas(elem.lte) || "Max."
                          }`,
                        })
                      )}
                      clearable
                      clearRenderer={({ methods }) => (
                        <div
                          className={`d-flex justify-content-end ${styles.clearDropdownButton}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            methods.clearAll();
                            removeCategory(option.value);
                          }}
                        >
                          {getClearLabel()}
                        </div>
                      )}
                    />
                  )}
                  {option.boolean && (
                    <DropSelect
                      multi={false}
                      className={styles.dropWrapper}
                      labelField="name"
                      disabled={false}
                      valueField="value"
                      searchBy="name"
                      name="e"
                      options={option.options}
                      onChange={(e) => {
                        if (e.val === "") {
                          return;
                        }
                        handleRangeFilterValueSelect(option.value, e.val);
                      }}
                      values={
                        selectedFilter.original === null
                          ? []
                          : selectedFilter.original
                          ? [option.options[0]]
                          : [option.options[1]]
                      }
                      clearable
                      clearRenderer={({ props, state, methods }) => (
                        <div
                          className={styles.clearDropdownButton}
                          onClick={(e) => {
                            e.stopPropagation();
                            methods.clearAll();
                            removeFilterTag(option.value);
                          }}
                        >
                          {getClearLabel()}
                        </div>
                      )}
                    />
                  )}
                </div>
              );
            })}
            <span
              className={styles.iconClose}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              {checkIfClearAllNeeded() ? (
                <div className={styles.inputBox}>
                  <span
                    className={styles.tagDelete}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeFilterTag("all");
                      setShowDropdown(!showDropdown);
                    }}
                  >
                    Clear All
                  </span>
                </div>
              ) : null}
            </span>
          </div>
        )}
      </div>
      <SelectedFilterTagsRow
        selectedFilter={selectedFilter}
        removeFilterTag={removeFilterTag}
      />
    </>
  );
};

export default React.memo(FiltersRow);

import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useSnackbar } from "notistack";

import styles from "./styles.module.scss";
import {
  companyGetFilters,
  setFilters,
} from "modules/companies/companyMain/companyMain.action";
import { CheckBox, CheckType, Label } from "modules/primitives";

const ProjectIndustries = ({ isAdminWizardInstance, formikprops }) => {
  const [select, setSelect] = useState([]);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    companiesReducer: {
      companyMainReducer: { industries },
    },
    newProjectV3Reducer: { stageTemplates },
  } = useSelector((state) => state);

  useEffect(() => {
    if (isAdminWizardInstance) return;

    dispatch(
      companyGetFilters({
        userId: session.id,
        councilId: selectedCouncil.id,
        enqueueSnackbar,
      })
    );
    const new_project = localStorage.getItem("new_project");
    const obj = JSON.parse(new_project);
    setSelect(obj?.industrys || []);
  }, []);

  const onCheckChange = (val, item) => {
    if (val) {
      if (!select.find((obj) => obj.id === item.id)) {
        setSelect((prev) => [...prev, item.id]);
      }

      dispatch(
        setFilters({
          industry_ids: select,
        })
      );
    } else {
      setSelect((prev) => prev.filter((id) => id !== item.id));
      dispatch(
        setFilters({
          industry_ids: select,
        })
      );
    }
  };

  useEffect(() => {
    if (isAdminWizardInstance) return;

    const new_project = localStorage.getItem("new_project");
    let obj = JSON.parse(new_project);
    obj = { ...obj, industrys: select?.filter(s => s) || [] };
    localStorage.setItem("new_project", JSON.stringify(obj));
  }, [Object.keys(select)]);

  const getSelected = useCallback(
    (id) => select.find((key) => key === id),
    [select]
  );

  const filteredTags = useMemo(() => {
    return industries.sort((a, b) => a.name.localeCompare(b.name));
  }, [industries]);

  if (isAdminWizardInstance) {
    return (
      <div className="mb-2 d-flex flex-column align-items-baseline">
        <Label>Project Industries</Label>
        <p>Industries list component</p>
      </div>
    )
  }

  return (
    <div className={styles.inner}>
      <h4 className={classNames("bold", styles.innerTitle)}>
        Choose Industries
      </h4>
      <div className={classNames("bold", styles.infoText)}>
        Use these industry tags to generate a list of companies for your project to address.
      </div>
      {filteredTags.length ? (
        <div className={styles.listWrp}>
          {filteredTags.map((item) => (
            <div key={item.id} className={styles.formElement}>
              <div className="d-flex align-items-center">
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={getSelected(item.id)}
                  onChange={(val) => onCheckChange(val, item)}
                />
                <div className={classNames(styles.tagStyle)}>
                  <Label>{item.name}</Label>
                </div>
              </div>
              {item.children_industries?.length
                ? item.children_industries.map((child) => (
                  <div
                    key={child.id}
                    className="pl-3 mt-2 d-flex align-items-center"
                  >
                    <CheckBox
                      checkType={CheckType.BLUE}
                      isChecked={getSelected(child.id)}
                      onChange={(val) => onCheckChange(val, child)}
                    />
                    <Label>
                      <div className={classNames(styles.tagStyle)}>
                        {child.name}
                      </div>
                    </Label>
                  </div>
                ))
                : null}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.noOptions}>
          There are no industries in this council to choose from.
        </div>
      )}

      <div className="caption small text-danger">
        {formikprops.touched['project_industries'] && formikprops.errors['project_industries']
          ? formikprops.errors['project_industries']
          : ""}
      </div>
    </div>
  );
};

export default React.memo(ProjectIndustries);

import React from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import classNames from "classnames";

import { ProgressBar, ProjectState } from "../../../../primitives";
import styles from "./projects.module.scss";

const MobileProjectRow = ({ project }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleRedirect = () => {
    if (!project) {
      return;
    }

    if (project.current_user_has_access) {
      history.push(`/projects/${project.id || project.project?.id}`);
    } else {
      enqueueSnackbar("You don't have access to this project.", {
        variant: "error",
      });
    }
  };

  return (
    <div
      className={
        project.current_user_has_access
          ? styles.mobileGridRow
          : styles.disabledGridRow
      }
      onClick={handleRedirect}
    >
      <span className={styles.projectName}>
        {project.project?.name || project.name}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <g fill="none" fillRule="evenodd">
            <g fill="#2D83AE">
              <g>
                <g>
                  <path
                    d="M7.326 5.456L7.326 12.96 8.642 12.96 8.642 5.456 12.046 8.768 12.984 7.872 7.984 3.008 2.984 7.872 3.921 8.768z"
                    transform="translate(-208 -230) translate(16 206) translate(192 24) matrix(1 0 0 -1 0 15.599)"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </span>
      <div className={styles.mobileRow}>
        <span>Last Activity</span>
        <div>
          {project.project_last_activity?.details}
        </div>
      </div>
      <div className={classNames(styles.mobileRow, "align-items-center")}>
        <span>Progress</span>
        <div className="d-flex align-items-center">
          <ProgressBar
            width="120px"
            tot={100}
            fill={project.total_task_progress || 0}
            showTot={false}
            progressValueStyle={styles.progressValueStyle}
            showPrecentage
          />
        </div>
      </div>
      <div className={styles.mobileRow}>
        <span>Status</span>
        <div>
          <ProjectState
            key={project.project?.status || project?.status}
            state={project.project?.status || project?.status}
          />
        </div>
      </div>
    </div>
  );
};
export default MobileProjectRow;

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import { from } from "rxjs";
import { concatMap, map, toArray } from "rxjs/operators";
import classNames from "classnames";

import TableCustomField from "modules/companies/company/details/tableCustomField";
import { Button, BtnType, Loading, Pagination } from "modules/primitives/index";
import styles from "../details.module.scss";
import {
  deleteCustomFieldTableRow,
  editCustomFieldTableRowCellsSuccess,
  getCustomGroupFields,
  saveCustomFieldTableRow,
  companyCustomFieldScrollTo,
  getCustomFieldHistoryAttempt,
} from "../../company.action";
import TextComponent from "../../../../../common/components/customFields/text";
import CheckListComponent from "../checkList";
import DocumentComponent from "../../../../../common/components/customFields/document";
import VideoComponent from "../../../../../common/components/customFields/videos";
import ImageComponent from "../../../../../common/components/customFields/images";
import DropDownComponent from "../../../../../common/components/customFields/dropDown";
import LinkComponent from "../../../../../common/components/customFields/link";
import SlidesComponent from "../../../../../common/components/customFields/slides";
import DateComponent from "../../../../../common/components/customFields/date";
import NumberComponent from "../../../../../common/components/customFields/number";
import AgreementComponent from "../../../../../common/components/customFields/agreement";
import { httpPut } from "../../../../../common/httpCall";
import { getDocuments } from "../../../../../common/actions/common.action";
import { Icon, smSize } from "../../../../../common/icon";
import CustomFieldHistoryComponent from "../customFieldHistory";

const CustomGroupComponent = ({
  group,
  companyId,
  blockFunctionality,
  handleEditClick,
  handleAddClick,
  handleDeleteClick,
  handleSubmitDetail,
  stageNameFromInvestment,
  investmentRef,
  idx,
  setExpandedCF,
  expandedCF,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [pageSettingCFHistory, setPageSettingCFHistory] = useState({
    pageSize: 10,
    current: 1,
    hideOnSinglePage: true,
    showTitle: false,
  });
  const [loadingRowId, setLoadingRowId] = useState(null);
  const [selectedDocumentPage, setSelectedDocumentPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [groupNameForInvestment, setGroupNameForInvestment] = useState();
  const dispatch = useDispatch();
  const agreementRef = useRef(null);
  const {
    documentsReducer: { documents, docListMeta },
    companiesReducer: {
      companyReducer: {
        companyIsDownloading,
        customScrollToId,
        customFieldHistory,
        customFieldHistoryLoading,
        customFieldHistoryMeta,
      },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (customFieldHistory && customFieldHistory.length) {
      setPageSettingCFHistory({
        ...pageSettingCFHistory,
        total: customFieldHistoryMeta.total,
        pages: 1,
        page: customFieldHistoryMeta.page,
      });
    }
  }, [customFieldHistory]);

  useEffect(() => {
    if (idx === 0) {
      setExpanded(group?.groupDetails.id);
      return;
    }

    if (group?.any_values) {
      setExpanded(group?.groupDetails.id);
    }
  }, [group?.any_values, idx]);

  useEffect(() => {
    dispatch(
      getCustomGroupFields({
        groupId: group.groupDetails.id,
        isTableGroup: group.viewType === "table",
        companyId,
      })
    );
  }, []);

  useEffect(() => {
    if (group.groupDetails.name) {
      const splittedGroupName = group.groupDetails.name?.split(" ");
      const lastWordInSplittedName = splittedGroupName.splice(-1, 1) || "";
      if (lastWordInSplittedName.join("").trim() === "Notes") {
        setGroupNameForInvestment(splittedGroupName.join(" "));
      }
    }
  }, [group.groupDetails.name]);

  useEffect(() => {
    if (investmentRef.current && !group.loading) {
      if (stageNameFromInvestment?.trim() === groupNameForInvestment?.trim()) {
        window.scrollTo({
          left: 0,
          top: investmentRef.current.offsetTop - 100,
          behaviour: "smooth",
        });
        setExpanded(group.groupDetails.id);
      }
    }
  }, [investmentRef.current?.offsetTop, group.loading]);

  useEffect(() => {
    if (group?.groupDetails?.name === "Documents + Presentations") {
      dispatch(
        getDocuments({
          document_resource_id: companyId,
          document_resource_type: "Company",
          patientOrg: false,
          customUrl: `documents?company_id=${companyId}&items=10&page=${
            selectedDocumentPage || 1
          }`,
          enqueueSnackbar,
        })
      );
    }
  }, [group?.groupDetails?.name]);

  useEffect(() => {
    if (customScrollToId && agreementRef.current) {
      window.scrollTo({
        left: 0,
        top: agreementRef.current.offsetTop,
        behaviour: "smooth",
      });
      dispatch(
        companyCustomFieldScrollTo({
          customScrollToId: false,
        })
      );
    }
  }, [customScrollToId, agreementRef.current]);

  const drawCriteria = (type, item, groupName = "") => {
    switch (type) {
      case "text":
        return (
          <TextComponent
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            item={item}
            blockFunctionality={blockFunctionality}
            handleSubmitDetail={handleSubmitDetail}
            focus={false}
          />
        );
      case "dropdown":
        const isFirmenichDropdown =
          selectedCouncil?.traction_tag === "firmenich" &&
          item.field_name === "Technology Readiness Level";

        return (
          <DropDownComponent
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            item={item}
            handleSubmitDetail={handleSubmitDetail}
            blockFunctionality={blockFunctionality}
            hasHoverInDropdown={isFirmenichDropdown}
          />
        );
      case "link":
        return (
          <LinkComponent
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            item={item}
            blockFunctionality={blockFunctionality}
            handleSubmitDetail={handleSubmitDetail}
          />
        );
      case "checklist":
        return (
          <CheckListComponent
            handleSubmitDetail={handleSubmitDetail}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            item={item}
            blockFunctionality={blockFunctionality}
            indexOfCfGroup={idx}
          />
        );
      case "document":
        if (groupName === "Documents + Presentations") {
          const newItemWithDocs = {
            ...item,
            company_id: companyId,
            council_id: selectedCouncil.id,
            additionalDocs: [...documents.map((doc) => doc.uri)],

            lengthOfDocuments: item.documents.length,
          };
          if (
            item.field_type === "document" &&
            item.custom_field_template?.field_type === "document" &&
            item.custom_field_template?.field_name === "Documents"
          ) {
            newItemWithDocs.allDocsItem = true;
            newItemWithDocs.docListMeta = docListMeta;
            newItemWithDocs.documents = [
              ...documents
                .filter(
                  (doc) =>
                    doc.document_resource_type !== "Projects::TaskAssignment"
                )
                .map((doc) => ({
                  ...doc,
                  isAdd: true,
                })),
            ];
          }
          return (
            <DocumentComponent
              item={newItemWithDocs}
              handleEditClick={handleEditClick}
              handleDeleteClick={handleDeleteClick}
              handleSubmitDetail={handleSubmitDetail}
              blockFunctionality={blockFunctionality}
              withPagination
              setSelectedDocumentPage={setSelectedDocumentPage}
              selectedDocumentPage={selectedDocumentPage}
            />
          );
        }

        return (
          <DocumentComponent
            item={{
              ...item,
              company_id: companyId,
              council_id: selectedCouncil.id,
            }}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            handleSubmitDetail={handleSubmitDetail}
            blockFunctionality={blockFunctionality}
          />
        );
      case "video":
        return (
          <VideoComponent
            item={item}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            blockFunctionality={blockFunctionality}
            handleSubmitDetail={handleSubmitDetail}
          />
        );
      case "image":
        return (
          <ImageComponent
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            item={item}
            handleSubmitDetail={handleSubmitDetail}
            blockFunctionality={blockFunctionality}
          />
        );
      case "date":
        return (
          <DateComponent
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            item={item}
            handleSubmitDetail={handleSubmitDetail}
            blockFunctionality={blockFunctionality}
          />
        );
      case "number":
        return (
          <NumberComponent
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            item={item}
            handleSubmitDetail={handleSubmitDetail}
            blockFunctionality={blockFunctionality}
          />
        );
      case "slide": {
        return (
          <SlidesComponent
            item={{
              ...item,
              option_values: item.documents?.length
                ? item.documents.map((doc) => doc.uri)
                : item.option_values,
            }}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            blockFunctionality={blockFunctionality}
            handleSubmitDetail={handleSubmitDetail}
          />
        );
      }
      case "agreement": {
        return (
          <AgreementComponent
            item={item}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            blockFunctionality={blockFunctionality}
          />
        );
      }
      default:
        break;
    }
  };

  const handleSaveCustomTableRow = (row, idx) => {
    const atLeastCellHasValue = row.cells.some(
      (cell) => cell.field_value?.length || cell.option_selected?.length
    );

    getCustomFieldHistory(idx, pageSettingCFHistory.current);
    if (!atLeastCellHasValue) {
      enqueueSnackbar("Please add at least one value in the row!", {
        variant: "error",
      });
      return;
    }

    setLoadingRowId(idx);
    const cells = row.cells.map((cell) => ({
      custom_field_template_id: cell.custom_field_template_id,
      position: idx + 1,
      field_value: cell.field_value,
      option_selected: cell.option_selected,
    }));
    setExpandedCF(false);
    const payload = {
      url: `companies/${companyId}/custom_field_groups/${group.groupDetails.id}/custom_field_tables`,
      data: {
        custom_field_table: cells,
      },
      groupId: group.groupDetails.id,
      cb: () => {
        setLoadingRowId(null);
        enqueueSnackbar("Successfully added a new row", {
          variant: "success",
        });
      },
      row,
    };

    dispatch(saveCustomFieldTableRow(payload));
  };

  const handleDeleteRow = (index) => {
    const payload = {
      url: `companies/${companyId}/custom_field_groups/${group.groupDetails.id}/custom_field_tables`,
      data: {
        custom_field_table: {
          position: index + 1,
        },
      },
      groupId: group.groupDetails.id,
      index,
      cb: () => {
        enqueueSnackbar("Successfully deleted row", {
          variant: "success",
        });
      },
    };

    dispatch(deleteCustomFieldTableRow(payload));
  };

  const makeConcurrentReq = (payload) =>
    httpPut({
      apiVersion: "v2",
      call: payload.url,
      data: payload.data,
    });

  const updateRowCells = (payload) => {
    setExpandedCF(false);
    setLoadingRowId(payload.idx);
    const payloadFromTouchedCells = payload.cells.map((cell) => {
      return {
        url: `companies/${companyId}/custom_field_groups/${group.groupDetails.id}/custom_field_tables/${cell.id}`,
        data: {
          custom_field_table: {
            field_value: cell.field_value || "",
            option_selected: cell.option_selected || [],
          },
        },
      };
    });
    const array$ = from(payloadFromTouchedCells);

    array$
      .pipe(
        concatMap((value) => from(makeConcurrentReq(value))),
        map((res) => res.response),
        toArray()
      )
      .subscribe((responses) => {
        const isLastIteration =
          responses.length === payloadFromTouchedCells.length;

        if (isLastIteration) {
          const payloadFromRes = {
            cells: responses,
            row: payload.row,
            index: payload.idx,
            groupId: group.groupDetails.id,
          };
          dispatch(editCustomFieldTableRowCellsSuccess(payloadFromRes));
          enqueueSnackbar("Changes where successfully applied!", {
            variant: "success",
          });
          setLoadingRowId(null);
        }
      });
  };
  const getCustomFieldHistory = (CFid, page) => {
    dispatch(
      getCustomFieldHistoryAttempt({
        customFieldId: CFid,
        companyID: companyId,
        enqueueSnackbar,
        page,
      })
    );
  };

  const handlePagingChange = (page) => {
    setPageSettingCFHistory((prev) => ({
      ...prev,
      current: page,
    }));
    getCustomFieldHistory(expandedCF, page);
  };
  const showHistory = (id) => {
    if (expandedCF === id) {
      setExpandedCF(false);
      return;
    }
    setExpandedCF(id);
    getCustomFieldHistory(id, 1);
  };

  return (
    <div
      key={group.groupDetails.id}
      ref={
        stageNameFromInvestment?.trim() === groupNameForInvestment?.trim()
          ? investmentRef
          : React.createRef()
      }
      className={`${companyIsDownloading ? "p-4" : "container"} mt-3 p-0`}
    >
      <div className={styles.listWrp}>
        <div
          className={styles.titleWrp}
          onClick={(e) => {
            e.stopPropagation();
            setExpanded((prev) => {
              if (prev) {
                return false;
              }
              return group.groupDetails.id;
            });
          }}
        >
          <span>
            <h4>{group.groupDetails.name}</h4>
            <span className={styles.description}>
              {group.groupDetails.description}
            </span>
          </span>
          <Icon
            {...smSize}
            icon="icn-table-down-chevron"
            className={classNames(
              styles.icon,
              expanded && expanded === group.groupDetails.id
                ? ""
                : styles.collapse
            )}
          />
        </div>
        {expanded && expanded === group.groupDetails.id && (
          <>
            <div className="d-flex justify-content-center mt-3">
              {group.loading || group.viewType === "table" ? null : (
                <>
                  {!blockFunctionality ? (
                    <Button
                      btn={BtnType.OUTLINE_LIGHT}
                      className={styles.button}
                      icon="icn-add"
                      onClick={() =>
                        handleAddClick(
                          group.groupDetails.id,
                          undefined,
                          false,
                          idx
                        )
                      }
                    >
                      Add item
                    </Button>
                  ) : (
                    <div className={styles.button}>
                      <Tooltip
                        title="You have View-Only access. To add fields, please ask your Admin to upgrade your account to Standard access."
                        placement="top"
                      >
                        <div>
                          <Button btn={BtnType.DISABLED} icon="icn-add">
                            Add item
                          </Button>
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
            </div>
            {group.loading ? (
              <Loading
                customText={`Loading ${group.groupDetails.name} fields...`}
              />
            ) : (
              <>
                {group.viewType === "table" ? (
                  <TableCustomField
                    group={group}
                    handleSave={handleSaveCustomTableRow}
                    handleDeleteRow={handleDeleteRow}
                    updateRowCells={updateRowCells}
                    loadingRowId={loadingRowId}
                    blockFunctionality={blockFunctionality}
                  />
                ) : (
                  <>
                    {group.customFields?.length ? (
                      <>
                        {group.customFields.map((field, index) => {
                          return (
                            <div
                              ref={
                                customScrollToId ===
                                field.company_custom_field_id
                                  ? agreementRef
                                  : null
                              }
                              key={`${group.groupDetails.name}-${field.company_custom_field_id}_${index}`}
                              className={`${
                                field.from_submission ||
                                field.from_claimed_profile
                                  ? styles.fromApplicants
                                  : ""
                              } ${styles.itemContainer}`}
                            >
                              {drawCriteria(
                                field.field_type,
                                field,
                                group.groupDetails.name
                              )}
                              {field.company_custom_field_id &&
                                ![
                                  "slide",
                                  "document",
                                  "image",
                                  "video",
                                ].includes(field.field_type) && (
                                  <div
                                    className={styles.showMoreTags}
                                    onClick={() => {
                                      showHistory(
                                        field.company_custom_field_id
                                      );
                                    }}
                                  >
                                    {expandedCF ===
                                    field.company_custom_field_id
                                      ? "hide"
                                      : "show"}{" "}
                                    history
                                  </div>
                                )}
                              {expandedCF === field.company_custom_field_id && (
                                <>
                                  <CustomFieldHistoryComponent
                                    customFieldHistory={customFieldHistory}
                                    customFieldHistoryLoading={
                                      customFieldHistoryLoading
                                    }
                                  />
                                  {customFieldHistoryMeta?.total > 10 && (
                                    <div className={styles.paginationCon}>
                                      <Pagination
                                        {...pageSettingCFHistory}
                                        onChange={handlePagingChange}
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          );
                        })}
                        {group.customFields.length > 5 ? (
                          <div
                            className="d-flex justify-content-center py-3 cursor-pointer bg-light"
                            onClick={() => setExpanded(false)}
                          >
                            <Icon
                              {...smSize}
                              icon="icn-table-down-chevron"
                              className={classNames(
                                styles.icon,
                                styles.collapseUp
                              )}
                            />
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center">
                        No fields yet.
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(CustomGroupComponent);

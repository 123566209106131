import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import ReactTable from "react-table";
import "react-table/react-table.css";
import classnames from "classnames";

import adminFuseHoc from "common/HOC/adminFuseHoc";
import { BtnType, Button, Loading } from "../../../primitives";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../common/hooks";
import { deleteWizardStep, getProjectWizardAttempt } from "modules/admin/adminProjects/adminProjects.actions";
import { WIZARD_SETUP_TYPES } from "modules/admin/adminProjects/adminProjects.constants";
import AddEditWizardStepModal from "modules/admin/adminProjects/adminProjectWizard/addEditWizardStep";
import { Icon, smSize } from "../../../../common/icon";
import ConfirmActionComponent from "../../../../common/components/confirmAction/confirmAction.component";

const AdminProjectWizardSteps = () => {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const {
    adminProjectsReducer: {
      stageTemplate,
      projectWizards,
    },
  } = useSelector((state) => state);

  const { enqueueSnackbar } = useSnackbar();
  const { id, wizardId } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getProjectWizardAttempt({
        stageID: id,
        enqueueSnackbar,
      }))
    }
  }, [id]);

  const onRowClick = (row) => {
    setModalData(row);

    const timeout = setTimeout(() => {
      setOpen(true);
      clearTimeout(timeout);
    }, 100)
  };

  const getWizardData = useMemo(() => {
    const getWizard = projectWizards.data.find(wizard => wizard.id === wizardId);

    if (getWizard) {
      return {
        ...getWizard,
        projects_wizard_steps: getWizard.projects_wizard_steps.map((wizard, index) => ({ ...wizard, index })),
      }
    }

    return {};
  }, [wizardId, projectWizards.data]);

  const handleDeleteStep = (step) => {
    popup.show({
      title: "Confirm action",
      component: (
        <ConfirmActionComponent
          alertText="Are you sure you want to delete this step?"
          onConfirm={() => {
            dispatch(deleteWizardStep({...step, wizardId}));
          }}
        />
      ),
    });
  };

  const columns = React.useMemo(() => [
    {
      Header: "Steps",
      id: "name",
      position: 1,
      accessor: (row) => <div className="cursor-pointer" onClick={() => onRowClick(row)}>{row.name}</div>,
      width: 200,
    },
    {
      Header: "Description",
      id: "description",
      accessor: (row) => (
        <div className={styles.alignedCenter}>{row?.description}</div>
      ),
      width: "70%",
    },
    {
      Header: "Actions",
      id: "actions",
      accessor: (row) => row.index === 0 ? null :  (
        <div className={styles.alignedCenter}>
          <div
            className="cursor-pointer"
            onClick={() => handleDeleteStep(row)}
          >
            <Icon
              {...smSize}
              icon="icn-button-delete"
            />
          </div>
        </div>
      ),
      width: 100,
    },
  ]);

  const onTabClick = (tab) => {
    history.push(`/admin/projects/${tab}/${id}`);
  };

  const addNewWizardStep = () => {
    setOpen(true);
  };

  return (
    <div className={styles.container}>
      <div className="row">
        <div className="col-10" style={{ margin: "0 auto" }}>
          <div className={styles.headerWrp}>
            <h4 className={styles.taskHeaderText}>
              <span
                className={styles.rootText}
                onClick={() => {
                  history.push("/admin");
                }}
              >
                ADMIN
              </span>
              <span className={styles.separationBar}> / </span>
              <span
                className={styles.rootText}
                onClick={() => {
                  history.push("/admin/projects");
                }}
              >
                PROJECTS
              </span>
              <span className={styles.separationBar}> / </span>
              <span className={styles.companyName}>{stageTemplate?.name}</span>
              <span className={styles.separationBar}> / </span>
              <span className={styles.rootText} onClick={() => onTabClick("setup")}>PROJECT SET-UP</span>
            </h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className={classnames(styles.tabsContainer, "col-10")}
          style={{ margin: "0 auto" }}
        >
          <h5 className="mb-3">
            <span
              onClick={() => onTabClick("stages")}
              className={styles.tabsText}
            >
              {" "}
              STAGES{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span
              onClick={() => onTabClick("tasks")}
              className={styles.tabsText}
            >
              {" "}
              TASKS{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span className={styles.tabsText} onClick={() => onTabClick("fields")}>
              {" "}
              FIELDS{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span className={styles.tabsText} onClick={() => onTabClick("groups")}>
              {" "}
              FIELD GROUPS{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span className={styles.tabsText} onClick={() => onTabClick("setup")}>
              {" "}
              PROJECT SET-UP{" "}
            </span>
            {
              getWizardData && (
                <>
                  <span className={styles.separationBar}> / </span>
                  <span className={styles.selectedVar}>
                    {" "}
                    {WIZARD_SETUP_TYPES[getWizardData?.setup_type]}
                  </span>
                </>
              )
            }
          </h5>
          <div className={styles.buttons}>
            <Button
              btn={BtnType.FRAME_LESS}
              onClick={() => {
                history.push(`/admin/projects/setup/${id}`);
              }}
            >
              {"< "} Go Back
            </Button>
            <Button
              className="ml-3"
              btn={BtnType.REGULAR}
              onClick={addNewWizardStep}
              icon="icn-add"
            >
              Add New Step
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        {" "}
        <div className="col-10" style={{ margin: "0 auto" }}>
          <ReactTable
            data={getWizardData?.projects_wizard_steps || []}
            columns={columns}
            className={styles.table}
            loading={!getWizardData ? <Loading /> : null}
            getTheadThProps={() => ({
              onClick: () => {
              },
            })}
            defaultSortDesc
            showPagination={false}
          />
        </div>
      </div>
      {
        open && (
          <AddEditWizardStepModal
            data={modalData}
            wizardId={wizardId}
            open={open}
            handleDismiss={() => {
              setOpen(false);
              setModalData(null);
            }}
            existingWizardsNr={getWizardData?.projects_wizard_steps?.length}
          />
        )
      }
    </div>
  );
};

export default adminFuseHoc(AdminProjectWizardSteps);

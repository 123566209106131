export const moreOptionTypes = {
  edit: 1,
  assign: 2,
  history: 3,
};

export const moreOptions = [
  {
    val: moreOptionTypes.edit,
    text: "Edit",
  },
  // {
  //   val: moreOptionTypes.assign,
  //   text: 'Assign',
  // },
  // {
  //   val: moreOptionTypes.history,
  //   text: 'History',
  // },
];

export const publicUrl = `${window.location.protocol}//${window.location.host}`;

export const formCodeTemplate = `
<div id="$formId"></div>
<script charset="utf-8" type="application/javascript" src="${publicUrl}/applicant-form/main.js"></script>
<script>window.renderForm($formData)</script>
`;

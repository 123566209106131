import React, { useEffect, useState } from "react";
import { pageSettings } from "../components/customFields/videos/constant";
import { Pagination } from "../../modules/primitives";

const VideoField = (field) => {
  const videoExtensions = [
    "mp4",
    "mov",
    "wmv",
    "flv",
    "avi",
    "avi",
    "webm",
    "mkv",
  ];
const [videoUrl, setVideoUrl] = useState("");
const [isEmbedded, setIsEmbedded] = useState();
const [videoIndex, setCurrentVideoIndex] = useState(0);
const [pageSetting, setPageSetting] = useState(pageSettings);

const getUrlExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

const checkPlatform = (url) => {
  if (url) {
    const regExpYoutube = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      const regExpVimeo = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
      const regExpFacebook = /^https?:\/\/www\.facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;

      if (url.includes("iframe")) {
        return "iframe";
      }
      if (url.match(regExpYoutube)) {
        return "youtube";
      }
      if (url.match(regExpVimeo)) {
        return "vimeo";
      }
      if (url.match(regExpFacebook)) {
        return "facebook";
      }
    }
    return url;
  };

  const getYoutubeId = (url) => {
    let ID = url;
    const urlSplit = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (urlSplit[2] !== undefined) {
      const [newID] = urlSplit[2].split(/[^0-9a-z_\-]/i);
      ID = newID;
    }

    return ID;
  };
  
  const getVimeoId = (url) => {
    const match = /vimeo.*\/(\d+)/i.exec(url);

    if (match) {
      return match[1];
    }
    return url;
  };

  const getFacebookId = (url) => {
    const match = /facebook.com.*\/(\d+)/i.exec(url);
    if (match) {
      return match[1];
    }
    return url;
  };

  const getIframeSrc = (url) => {
    const match = /src=("([^"]*)")/i.exec(url);
    if (match) {
      return match[2];
    }
    return url;
  };

  const handleIsEmbedded = () => {
    if (
      videoExtensions.indexOf(
        getUrlExtension(field.documents[videoIndex].uri),
      ) === -1
    ) {
      setIsEmbedded(true);
      if (checkPlatform(field.documents[videoIndex].uri) === "youtube") {
        setVideoUrl(
          `https://www.youtube.com/embed/${getYoutubeId(
            field.documents[videoIndex].uri,
          )}`,
        );
      }
      if (checkPlatform(field.documents[videoIndex].uri) === "vimeo") {
        setVideoUrl(
          `https://player.vimeo.com/video/${getVimeoId(
            field.documents[videoIndex].uri,
            )}`,
        );
      }
      if (checkPlatform(field.documents[videoIndex].uri) === "facebook") {
        setVideoUrl(
          `https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTMNTfilm%2Fvideos%2Fvb.1398909827041343%2F${getFacebookId(
            field.documents[videoIndex].uri,
            )}%2F%3Ftype%3D3&show_text=0&width=889&height=500`,
        );
      }
      if (checkPlatform(field.documents[videoIndex].uri) === "iframe") {
        setVideoUrl(getIframeSrc(field.documents[videoIndex].uri));
      }
    } else {
      setIsEmbedded(false);
    }
  };
  
  const handlePagingChange = (page) => {
    setCurrentVideoIndex(page - 1);
    setPageSetting((prev) => ({
      ...prev,
      current: page,
    }));
  };

  useEffect(() => {
    if (field?.documents && field?.documents[videoIndex]?.uri) {
      handleIsEmbedded();
    }
  }, [videoIndex, field]);

  useEffect(() => {
    if (field && field.documents) {
      setPageSetting({
        ...pageSetting,
        total: field.documents.length,
        pageCount: 1,
      });
    }
  }, [field.documents]);

  const displayField = () => {
    if (field.field_type !== "video") {
      return "";
    }
    return (
      <div>
        <div>
          {field && field.documents && !isEmbedded ? (
            <div>
              <video
                width="100%"
                controls
                loop
                autoPlay=""
                key={field.documents[videoIndex].uri}
              >
                <source
                  src={
                    field
                    && field.documents && field.documents[0]
                    && field.documents[videoIndex].uri
                  }
                />
                Your browser does not support video.
              </video>
            </div>
          ) : (
            <div>
              {field.documents
              && checkPlatform(field.documents[videoIndex]?.uri) === "facebook" ? (
                <iframe
                  title="Embedded videos"
                  src={videoUrl}
                  width="100%"
                  height="500"
                />
                ) : (
                  <iframe
                    width="100%"
                    height="480"
                    src={videoUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded videos"
                  />
                )}
            </div>
          )}
        </div>
        <div>
          {field.documents && field.documents.length > 1 && (
            <Pagination {...pageSetting} onChange={handlePagingChange} />
          )}
        </div>
      </div>
    );
  };

  return { displayField };
};

export default VideoField;

import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { Formik, Form, FieldArray } from "formik";
import { httpGet, httpPost } from "common/httpCall";
import Switch from "react-switch";
import { Select } from "react-dropdown-select";
import {
  companyGetFilters,
  companyGetTopics,
} from "modules/companies/companyMain/companyMain.action";
import { DropSelect, BtnType, Button as Btn } from "../../../primitives";
import styles from "./configureExportAccounts.module.scss";
import MapExportFieldValues from "./mapExportFieldValues";
import MapExportCustomFields from "./mapExportCustomFields";
import {
  preSelectedCompanyValues,
  selectedSalesForceValues,
  companyFieldsToSubtract as comFieldsToSubtract,
} from "../preSelectedValues/accountsPreSelectedValues";

const ConfigureExportAccounts = (props) => {
  const { checked, handleSwitchChange, syncToggleStatus } = props;
  const [selectedSfValues, setSelectedSfValues] = useState([]);
  const [selectedCompanyValues, setSelectedCompanyValues] = useState([]);
  const [isSelectedSfValue, setIsSelectedSfValue] = useState(true);
  const [isSelectedCompanyValue, setIsSelectedCompanyValue] = useState(true);
  const [selectedSfValue, setSelectedSfValue] = useState({});
  const [selectedCompanyValue, setSelectedCompanyValue] = useState({});
  const [sfFields, setSfFields] = useState([]);
  const [coFields, setCoFields] = useState([]);
  const [typeMatch, setTypeMatch] = useState([]);
  const [, setIsDropOpened] = useState(false);
  const [isChildDropOpened, setIsChildDropOpened] = useState(false);
  const [childDropDownOptions, setChildDropDownOptions] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [, setTypeOptions] = useState([]);
  const [ownerFilter, setOwnerFilter] = useState([]);
  const [, setOwnerOptions] = useState([]);
  const [opportunityFilter, setOpportunityFilter] = useState([]);
  const [opportunityOptions, setOpportunityOptions] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [relationshipStage, setRelationshipStage] = useState([]);
  const [filterOption, setFilterOption] = useState(null);
  const [subtractedCompanyFields, setSubtractedCompanyFields] = useState([]);
  const [mapToggle, setMapToggle] = useState(false);
  const [mapCustomFieldToggle, setMapCustomFieldToggle] = useState(false);
  const [companyFieldsToSubtract, setCompanyFieldsToSubtract] = useState([]);
  const [technologyFields, setTechnologyFields] = useState([]);
  const [industryFields, setIndustryFields] = useState([]);
  const [friendsArray, setFriendsArray] = useState([]);
  const [
    selectedRelationshipStatusValues,
    setSelectedRelationshipStatusValues,
  ] = useState([]);
  const [selectedOpportunityStagePayload, setSelectedOpportunityStagePayload] =
    useState([]);
  const [selectedSfCustomFieldValues, setSelectedSfCustomFieldValues] =
    useState([]);
  const [selectedTtpCustomFieldValues, setSelectedTtpCustomFieldValues] =
    useState([]);

  const [disabled, setDisabled] = useState(true);
  const [responseFilter, setResponseFilter] = useState();
  const [responseFilterValue, setResponseFilterValue] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const exportFilterRef = useRef("");
  const exportFilterValueRef = useRef("");

  const {
    appReducer: { relationShipStatuses },
    authReducer: { session },
    councilReducer: { selectedCouncil },
    adminIntegrationsReducer: {
      exportOpportunityStagePayload,
      exportRelationshipStatusPayload,
      sfExportCustomFieldPayload,
      ttpExportCustomFieldPayload,
    },
    companiesReducer: {
      companyMainReducer: { industries, topics },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    httpGet({
      call: "sf_integrations/get_fields",
    })
      .pipe()
      .subscribe((res) => {
        setSfFields(
          res.response.account_fields.map((item) => ({
            text: item.name,
            value: (() => {
              switch (item.type) {
                case "id":
                  return "uuid";
                case "textarea":
                  return "text";
                case "date":
                  return "datetime";
                case "picklist":
                  return "json";
                default:
                  return item.type;
              }
            })(),
          })),
        );
      });
    setSelectedSfValues(selectedSalesForceValues);

    httpGet({
      call: "sf_integrations/get_company_fields",
    })
      .pipe()
      .subscribe((res) =>
        setCoFields(
          res.response.company_fields.map((item, index) => ({
            text: item,
            value: res.response.types[index],
          })),
        ),
      );
    setSelectedCompanyValues(preSelectedCompanyValues);
    setCompanyFieldsToSubtract(comFieldsToSubtract);
  }, []);

  useEffect(() => {
    dispatch(
      companyGetTopics({
        userId: session?.id,
        councilId: selectedCouncil?.id,
      }),
    );

    dispatch(
      companyGetFilters({
        userId: session?.id,
        councilId: selectedCouncil?.id,
      }),
    );
  }, [session, selectedCouncil]);

  useEffect(() => {
    const subtractedFields = coFields.filter(
      (i) => !companyFieldsToSubtract.map((j) => j.text).includes(i.text),
    );
    setSubtractedCompanyFields(subtractedFields);
  }, [coFields]);

  const handleSaveMappings = (state) => {
    httpPost({
      call: "sf_integrations/save_mappings",
      data: {
        user_id: session.id,
        council_id: selectedCouncil.id,
        mappings_payload: state,
        attribute: "account",
        flow: "export",
      },
    })
      .pipe()
      .subscribe((res) => {
        console.log(res.response);
      });
  };

  const handleSubmit = () => {
    const payloadArray = [];

    for (let i = 0; i < selectedCompanyValues.length; i += 1) {
      payloadArray.push({
        [selectedCompanyValues[i].text]: selectedSfValues[i].text,
      });
    }

    const state = {
      currentAttribute: "account",
      mappedTractionFields: selectedCompanyValues,
      mappedSalesforceFields: selectedSfValues,
      mappedFields: payloadArray,
      selectedFilterOption: filterOption,
      selectedFilter: filterValue,
      opportunityStagePayload: exportOpportunityStagePayload || [],
      relationshipStatusPayload: exportRelationshipStatusPayload || [],
      mappedTractionCustomFields: ttpExportCustomFieldPayload || [],
      mappedSalesforceCustomFields: sfExportCustomFieldPayload || [],
      syncToggle: syncToggleStatus,
    };

    handleSaveMappings(state);
    history.push({
      pathname: "/salesforce/exportData",
      state,
    });
  };

  const handleFilterOption = (response) =>
    [
      ...new Set(
        response?.map((item) => item.Type || item.Name || item.StageName),
      ),
    ].filter((item) => ![undefined, null].includes(item));

  useEffect(() => {
    httpGet({
      call: "sf_integrations/fetch_filters",
    })
      .pipe()
      .subscribe((res) => {
        setTypeFilter(handleFilterOption(res?.response?.type_options?.records));
        setOwnerFilter(
          handleFilterOption(res?.response?.owner_options?.records),
        );
        setOpportunityFilter(
          handleFilterOption(res?.response?.opportunity_options?.records),
        );
      });
  }, []);

  useEffect(() => {
    const data = topics.map((item) => ({
      text: item.name,
      value: item.id,
    }));
    setTechnologyFields(data);
  }, [topics]);

  useEffect(() => {
    if (session && selectedCouncil) {
      httpPost({
        call: "sf_integrations/get_mappings",
        data: {
          user_id: session.id,
          council_id: selectedCouncil.id,
          attribute: "account",
          flow: "export",
        },
      })
        .pipe()
        .subscribe((res) => {
          if (res.response.length) {
            setSelectedSfValues(
              res.response[0].mappedSalesforceFields ??
                selectedSalesForceValues(),
            );
            setSelectedCompanyValues(
              res.response[0].mappedTractionFields ??
                preSelectedCompanyValues(),
            );
            setFriendsArray(
              res?.response[0]?.mappedTractionFields ??
                preSelectedCompanyValues(),
            );
            setSelectedRelationshipStatusValues(
              res.response[0]?.relationshipStatusPayload ?? [],
            );
            setSelectedOpportunityStagePayload(
              res.response[0]?.opportunityStagePayload ?? [],
            );
            setSelectedSfCustomFieldValues(
              res.response[0]?.mappedSalesforceCustomFields ?? [],
            );
            setSelectedTtpCustomFieldValues(
              res.response[0]?.mappedTractionCustomFields ?? [],
            );
            setMapCustomFieldToggle(
              res.response[0]?.mappedSalesforceCustomFields?.length > 0,
            );
            setMapToggle(res.response[0]?.opportunityStagePayload?.length > 0);
            setResponseFilter(res?.response[0]?.selectedFilterOption);
            setResponseFilterValue(
              res?.response[0]?.selectedFilter.map((val) => val.text),
            );
          } else {
            setSelectedSfValues(selectedSalesForceValues());
            setSelectedCompanyValues(preSelectedCompanyValues());
            setFriendsArray(preSelectedCompanyValues());
          }
        });
    }
  }, [session, selectedCouncil]);

  useEffect(() => {
    const data = industries.map((item) => ({
      text: item.name,
      value: item.id,
    }));
    setIndustryFields(data);
  }, [industries]);

  useEffect(() => {
    setTypeOptions(typeFilter.map((value) => ({ text: value, value })));
  }, [typeFilter]);

  useEffect(() => {
    setOwnerOptions(ownerFilter.map((value) => ({ text: value, value })));
  }, [ownerFilter]);

  useEffect(() => {
    setOpportunityOptions(
      opportunityFilter.map((value) => ({ text: value, value })),
    );
  }, [opportunityFilter]);

  const handleBack = () => {
    history.push({
      pathname: "/salesforce/configureData",
    });
  };

  const handleDropClose = () => {
    setIsDropOpened(false);
  };

  const handleDropOpen = () => {
    setIsDropOpened(true);
    setIsChildDropOpened(false);
  };

  const handleChildDropOpen = () => {
    setIsChildDropOpened(true);
  };

  const childDropOptionsData = [
    relationshipStage,
    technologyFields,
    industryFields,
  ];

  const onDropDownValueChange = (values) => {
    setDisabled(false);
    exportFilterValueRef.current.state.values = [];
    setFilterOption(values?.[0].text);
    setChildDropDownOptions(childDropOptionsData[values[0].value]);
    setIsChildDropOpened(true);
  };
  useEffect(() => {
    if (responseFilterValue) {
      const temp = responseFilterValue?.map((val) => ({
        text: val,
        value: val,
      }));
      exportFilterValueRef.current.state.values = [...temp];
      setFilterValue(temp);
    }
  }, [responseFilterValue]);

  useEffect(() => {
    if (responseFilter) {
      exportFilterRef.current.state.values = [{ text: responseFilter }];
      setFilterOption(responseFilter);
    }
  }, [responseFilter]);

  let boundArrayHelpers;

  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  const dropOptions = [
    { id: 1, text: "Relationship Status", value: 0 },
    { id: 2, text: "Technology", value: 1 },
    { id: 3, text: "Industry", value: 2 },
  ];

  const handleChangeFilter = (e) => {
    setFilterValue(e);
  };

  const handleMapFields = () => {
    setMapToggle(true);
  };

  useEffect(() => {
    setRelationshipStage(
      relationShipStatuses.map((obj) => ({
        text: obj?.name,
        value: obj?.customId,
      })),
    );
  }, [selectedCouncil]);

  const handleCustomFields = () => {
    setMapCustomFieldToggle(true);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ friends: Array(friendsArray.length).fill("") }}
        onSubmit={handleSubmit}
        render={({ values }) => (
          <Form>
            <FieldArray
              name="friends"
              render={(arrayHelpers) => {
                bindArrayHelpers(arrayHelpers);
                return (
                  <div>
                    <div className={styles.backBtn}>
                      <Btn
                        btn={BtnType.FRAME_LESS}
                        onClick={() => handleBack()}
                      >
                        Back
                      </Btn>
                    </div>
                    <div className={styles.submitBtn}>
                      <div />
                      <Btn btn={BtnType.REGULAR}>Submit</Btn>
                      <div className={styles.switchBtn}>
                        <Switch
                          checked={checked}
                          onChange={handleSwitchChange}
                          offColor="#76B947"
                          offHandleColor="#2F5233"
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={48}
                          className="react-switch"
                          id="material-switch"
                        />
                        {checked ? (
                          <div className={styles.toggleText}>Import</div>
                        ) : (
                          <div className={styles.toggleText}>Export</div>
                        )}
                      </div>
                    </div>
                    <div
                      className={`row ${styles.dropDownSelectItems} ${styles.dropDownFieldsConatiner}`}
                    >
                      <div className="col">
                        <h4>Filter Accounts</h4>
                      </div>
                      <div className={`col-5 ${styles.dropSelectItems}`}>
                        <Select
                          placeholder="Select Filter Type"
                          name="text"
                          labelField="text"
                          valueField="value"
                          ref={exportFilterRef}
                          backspaceDelete
                          options={dropOptions}
                          onDropdownOpen={handleDropOpen}
                          onDropdownClose={handleDropClose}
                          onChange={onDropDownValueChange}
                        />
                      </div>
                      <div className={`col-5 ${styles.dropSelectItems}`}>
                        <Select
                          id="child-drop-list"
                          placeholder="Select Filter Value"
                          name="text"
                          ref={exportFilterValueRef}
                          multi
                          labelField="text"
                          valueField="value"
                          deselected
                          backspaceDelete
                          options={childDropDownOptions}
                          onDropdownOpen={handleChildDropOpen}
                          onChange={(e) => handleChangeFilter(e)}
                          disabled={disabled}
                        />
                      </div>
                      {!isChildDropOpened && <div className="col-5" />}
                    </div>
                    <div className={styles.fieldsConatiner}>
                      <>
                        <div className={styles.headTitle}>
                          <div className={styles.headerContainer}>
                            <div className={styles.mapsTextHead}>Traction</div>
                            <div className={styles.mapsTextHead}>
                              Salesforce
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                    <div className={styles.fieldsConatiner}>
                      {values.friends.map((_, index) => (
                        <div>
                          {values.friends.length && (
                            <>
                              <div
                                className={styles.headTitle}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginLeft: "1rem",
                                  marginTop: "1rem",
                                }}
                              >
                                <>
                                  <div className={styles.mapsText}>
                                    {selectedCompanyValues[index]?.text}
                                  </div>
                                  <Button className={styles.mapsButton}>
                                    Maps
                                  </Button>
                                  <div className={styles.mapsText}>
                                    {selectedSfValues[index]?.text}
                                  </div>
                                  <Chip
                                    className={styles.chipButton}
                                    onDelete={() => {
                                      setSelectedSfValues([
                                        ...selectedSfValues.filter(
                                          (value, selectedIndex) =>
                                            selectedIndex !== index,
                                        ),
                                      ]);
                                      setSelectedCompanyValues([
                                        ...selectedCompanyValues.filter(
                                          (value, selectedIndex) =>
                                            selectedIndex !== index,
                                        ),
                                      ]);
                                      arrayHelpers.remove(index);
                                      setIsSelectedSfValue(false);
                                    }}
                                  />
                                </>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              }}
            />

            <div className={styles.fieldsConatiner}>
              <div className={styles.headTitle}>
                <>
                  <DropSelect
                    placeholder="Select fields from Traction"
                    labelField="text"
                    valueField="text"
                    searchBy="text"
                    options={subtractedCompanyFields.filter(
                      (item) =>
                        !selectedCompanyValues
                          .map((value) => value.text)
                          .includes(item.text),
                    )}
                    onChange={(event) => {
                      setTypeMatch(event.obj[0].value);
                      setSelectedCompanyValue({
                        text: event.obj[0].text,
                        value: event.obj[0].text,
                      });
                      setIsSelectedCompanyValue(false);
                    }}
                  />
                  <DropSelect
                    disabled={isSelectedCompanyValue}
                    placeholder="Select fields from Salesforce"
                    labelField="text"
                    valueField="text"
                    searchBy="text"
                    onChange={(event) => {
                      setSelectedSfValue({
                        text: event.obj[0].text,
                        value: event.obj[0].text,
                      });
                      setIsSelectedSfValue(false);
                    }}
                    options={sfFields.filter(
                      (item) =>
                        !selectedSfValues
                          .map((value) => value.text)
                          .includes(item.text) && item.value === typeMatch,
                    )}
                  />
                  <Btn
                    btn={BtnType.REGULAR}
                    className={styles.addButton}
                    disabled={
                      values.friends.length ===
                        subtractedCompanyFields?.length ||
                      isSelectedSfValue ||
                      isSelectedCompanyValue
                    }
                    onClick={() => {
                      setSelectedCompanyValues([
                        ...selectedCompanyValues,
                        selectedCompanyValue,
                      ]);
                      setSelectedSfValues([
                        ...selectedSfValues,
                        selectedSfValue,
                      ]);
                      setIsSelectedSfValue(true);
                      setIsSelectedCompanyValue(true);
                      boundArrayHelpers.insert(values.friends.length - 1, "");
                    }}
                  >
                    +Add
                  </Btn>
                </>
              </div>
            </div>
            <div className={styles.fieldsConatiner}>
              {!mapToggle ? (
                <div className={styles.mapContainer}>
                  <Button
                    className={styles.mapsButton}
                    onClick={handleMapFields}
                  >
                    Map Opportunity Stages
                  </Button>
                </div>
              ) : (
                <MapExportFieldValues
                  opportunityOptions={opportunityOptions}
                  relationshipStage={relationshipStage}
                  preSelectedOpportunityStagePayload={
                    selectedOpportunityStagePayload
                  }
                  preSelectedRelationshipStatusValues={
                    selectedRelationshipStatusValues
                  }
                />
              )}
            </div>
            <div className={styles.fieldsConatiner}>
              {!mapCustomFieldToggle ? (
                <div className={styles.mapContainer}>
                  <Button
                    className={styles.mapsButton}
                    onClick={handleCustomFields}
                  >
                    Map Custom Fields
                  </Button>
                </div>
              ) : (
                <MapExportCustomFields
                  opportunityOptions={opportunityOptions}
                  relationshipStage={relationshipStage}
                  preSelectedSfCustomFieldValues={selectedSfCustomFieldValues}
                  preSelectedTtpCustomFieldValues={selectedTtpCustomFieldValues}
                />
              )}
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default React.memo(ConfigureExportAccounts);

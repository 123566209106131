import React, { useEffect, useState } from "react";

import { Label, MoreOptions } from "modules/primitives";
import { ACTIVITY_OPTIONS_WITHOUT_DELETE } from "common/constants.js";
import styles from "./styles.module.scss";
import { Icon, smSize } from "../../../../../../common/icon";

const LinkComponent = ({ item, handleEditClick, handleUpdateForm, disabled }) => {
  const [fieldValue, setFieldValue] = useState('');
  const [showField, setShowField] = useState(false);
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (item?.field_name) {
      setTitle(item.field_name);
    }
    if (item.field_value?.length) {
      setFieldValue(item.field_value);
    } else {
      setShowField(true);
    }
  }, [item]);

  const handleSaveClick = () => {
    // setShowField(false);
    handleUpdateForm({
      ...item,
      field_value: fieldValue,
      field_name: title,
    })
  }

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex">
          <h5 className={styles.title}>
            {" "}
            {item.field_name ? (
              <div className={styles.container}>
                {" "}
                <a
                  className={`${styles.link} ${
                    item?.field_value ? styles.capitalizedLink : ""
                  }`}
                  target="_blank"
                  href={item && item.field_value}
                  rel="noreferrer"
                >
                  {item.field_name}
                </a>
              </div>
            ) : (
              "Link"
            )}
          </h5>
          {
            !showField ? (
              <div className="cursor-pointer ml-2" onClick={() => setShowField(true)}>
                <Icon
                  {...smSize}
                  icon="icn-edit"
                />
              </div>
            ) : null
          }
        </div>

        {!disabled && (
          <div className={styles.rightWrp}>
            <div className={styles.colMore}>
              <MoreOptions
                options={ACTIVITY_OPTIONS_WITHOUT_DELETE}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            </div>
          </div>
        )}
      </div>

      {item && !item.field_name && !showField ? (
        <div className="d-flex">
          <h5 className={styles.container}>
            <a
              className={styles.link}
              target="_blank"
              href={item && item.field_value}
              rel="noreferrer"
            >
              {item && !item.field_name && item.field_value}
            </a>
          </h5>
          <div className="cursor-pointer ml-2" onClick={() => setShowField(true)}>
            <Icon
              {...smSize}
              icon="icn-edit"
            />
          </div>
        </div>
      ) : null}

      {
        showField ? (
          <div className="w-75 d-flex align-items-end">
            <div className="d-flex flex-column mr-2">
              <Label>Text (optional)</Label>
              <input
                className="w-100 border small p-2 mb-3"
                value={title}
                placeholder="Add title"
                onChange={(evt) => setTitle(evt.target.value)}
              />
              <Label>Link</Label>
              <input
                className="w-100 border small p-2"
                value={fieldValue}
                placeholder="Enter a URL"
                onChange={(evt) => setFieldValue(evt.target.value)}
                onBlur={handleSaveClick}
              />
            </div>
          </div>
        ) : null
      }
    </div>
  );
};
export default React.memo(LinkComponent);

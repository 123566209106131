import React  from "react";

const statusByKey = {
  'at_risk': 'At Risk',
  'open': 'On Track',
  'closed': 'Closed',
  'off_track': 'Off Track',
  'on_hold': 'On Hold'
}

const ProjectStatus = ({ data, config }) => {
  return (
    <div>
      {
        data[config.propToUse] ? statusByKey[data[config.propToUse].toLowerCase()] : '-'
      }
    </div>
  )
}

export default React.memo(ProjectStatus);

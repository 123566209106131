import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import classNames from "classnames";
import { withSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import isKyndrylViewOnly from "common/isKyndrylViewOnly";
import { useModelPopup } from "../../../common/hooks/index";
import { ModalBody, ModalFooter } from "../../../application/modal";
import { BtnType, Button, Label, TextBox, CheckBox, CheckType } from "../../primitives";
import {
  initCrunchbaseModel,
  crunchbaseValidation,
  kyndrylCrunchbaseValidation,
} from "./crunchbase.constants";
import { crunchbaseCompanyAttempt } from "./crunchbase.action";
import styles from "./crunchbase.module.scss";
import UserMultiSelectSearch from "../../../common/components/usersSearch";
import CompaniesSelectSearch from "modules/companies/crunchbase/companiesSearch";

const CrunchbaseComponent = ({ enqueueSnackbar, fromHome = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const formRef = useRef();
  const [validation, setValidation] = useState({});
  const [noWebsiteValidation, seNoValidation] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil?.name === "Kyndryl") {
      setValidation(kyndrylCrunchbaseValidation);
    }

    setValidation(crunchbaseValidation);
  }, [selectedCouncil]);

  useEffect(() => {
    if(isKyndrylViewOnly(selectedCouncil?.name, session?.council_role)){
      setSelectedValues([
        {
          id: session?.id,
          full_name: session?.full_name,
        },
      ])
    }
  }, [session]);

  const handleCancelClick = (e) => {
    popup.hide();
  };

  const handleOnSubmit = (values) => {
    dispatch(
      crunchbaseCompanyAttempt({
        values: { ...values, website: values.website?.trim() },
        selectedCouncil,
        enqueueSnackbar,
      }),
    );
    if (fromHome) {
      history.push({
        pathname: "/companies",
      });
    }
    popup.hide();
  };

  const setNoValidationClick = (formProps) => {
    const val = !noWebsiteValidation
    seNoValidation(val);

    if (!val) {
      if (selectedCouncil?.name === "Kyndryl") {
        setValidation(kyndrylCrunchbaseValidation);
      }

      setValidation(crunchbaseValidation);
    } else {
      if (selectedCouncil?.name === "Kyndryl") {
        setValidation(
          Yup.object().shape({
            name: Yup.string().required("Company name is required"),
            relationship_owner_id: Yup.string().required(
              "Relationship Owner is required",
            ),
          })
        );
      }

      setValidation(
        Yup.object().shape({
          name: Yup.string().required("Company name is required"),
        })
      );

      formProps.setErrors({
        ...formRef?.current?.errors,
        website: null,
      });
    }
  };

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initCrunchbaseModel}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleOnSubmit(values);
        }}
      >
        {({
          handleSubmit, handleReset, setFieldValue, ...formikprops
        }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <ModalBody>
              <div className={styles.crunchBaseFormWrp}>
                <div className={styles.row}>
                  <div className={classNames(styles.col12)}>
                    <Label>Company Name</Label>
                    <CompaniesSelectSearch
                      formProps={{ handleSubmit, handleReset, setFieldValue, ...formikprops }}
                      labelField="name"
                      valueField="id"
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={classNames(styles.col12)}>
                    <Label>Company Website</Label>
                    <TextBox
                      type="text"
                      name="website"
                      placeholder="https://example.com"
                      className={styles.textBox}
                      formProps={formikprops}
                      disabled={formikprops.values.selectedCompany}
                    />
                    {
                      formikprops.values?.name?.length && !formikprops.values?.selectedCompany ? (
                        <div className="d-flex align-items-center">
                          <CheckBox
                            checkType={CheckType.BLUE}
                            isChecked={noWebsiteValidation}
                            onChange={() => setNoValidationClick(formikprops)}
                          />
                          <span
                            className="small cursor-pointer"
                            onClick={() => setNoValidationClick(formikprops)}
                            style={{marginLeft: '-10px'}}
                          >
                            Add without website
                          </span>
                        </div>
                      ) : null
                    }
                  </div>
                </div>
                {selectedCouncil.name === "Kyndryl" ? (
                  <div className={styles.row}>
                    <div className={classNames(styles.col12)}>
                      <Label>Relationship Owner</Label>
                      <UserMultiSelectSearch
                        name="relationship_owner_id"
                        placeholder="Choose member"
                        labelField="full_name"
                        valueField="id"
                        searchBy="full_name"
                        formProps={formikprops}
                        mode="singular"
                        sharedUsers={[]}
                        onChange={(val) => setFieldValue(
                          "relationship_owner_id",
                          val && val[0] ? val[0].id : null,
                        )}
                        selectedValues={selectedValues }
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button type="submit" btn={BtnType.REGULAR}>
                Add
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default React.memo(withSnackbar(CrunchbaseComponent));

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Select from "react-dropdown-select";
import { useMsal } from "@azure/msal-react";
import { getCookie } from "common/helper";
import { appModelSettingSet } from "application/app.action";
import { searchInCouncil } from "modules/companies/companyMain/companyMain.action";
import {
  getCompanyContactsAttempt,
  companyContactInvite,
} from "modules/companies/company/company.action";
import { ModalFooter, ModalBody } from "../../../../application/modal";
import { userTeamsGet } from "../../../../common/actions/discussions.action";
import { peopleListTeamsGet } from "../../../../common/actions/common.action";
import {
  Button,
  BtnType,
  TextBox,
  Label,
  DropSelect,
} from "../../../primitives";
import { initModel, validation, permission } from "./memberNew.constants";
import { peopleMembersAdd } from "../internalPeople.action";
import styles from "./memberNew.module.scss";
import { searchUsers } from "../../../../api/microsoft/graphApi/outlookOffice";

import UserMultiSelectSearch from "../../../../common/components/usersSearch";
import { useDebounce } from "../../../../common/hooks/useDebounce.hook";

const getRole = (val) => {
  if (val === 1) {
    return "council_administrator";
  }
  if (val === 2) {
    return "standard";
  }
  if (val === 4) {
    return "company";
  }

  return "viewer";
};

const MemberNewComponent = (props) => {
  const {
    defaultModel = {},
    preselectedAccessType = { text: "Standard", val: 2 },
    preselectedData = null,
    excludeCompanyRole = false,
  } = props;
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [model, setmodel] = useState(initModel);
  const [options, setOptions] = useState([]);
  const [applyToOptions, setApplyToOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [accessOptions, setAccessOptions] = useState(permission);
  const [companySelectChanged, setCompanySelectChanged] = useState(true);
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAccessLevel, setSelectedAccessLevel] = useState(
    preselectedAccessType
  );
  const [isCompanyAccessSelected, setIsCompanyAccessSelected] = useState(false);
  const [selectedContact, setSelectedContact] = useState([]);
  const [selected, setSelected] = useState([]);
  const [companyOptions, setCompanyOptionst] = useState([]);
  const [elasticQueryy, setElasticQuery] = useState("");
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [teamsError, setTeamsError] = useState("");
  const history = useHistory();
  const patientOrg = history.location.pathname.indexOf("organizations") !== -1;
  const dropDownRef = useRef();
  const multiselectRef = useRef();
  const [preselectedModel, setPreselectedModel] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const { instance, accounts } = useMsal();
  const [msftContacts, setMsftContacts] = useState([]);
  const [msftSearch, setMsftSearch] = useState("");
  const debouncedValue = useDebounce(msftSearch, 500);
  const microsoftLogin =
    JSON.parse(localStorage.getItem("microsoft_login") || false) || false;

  const {
    authReducer: { session },
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil, usersList },
    projectsReducer: {
      stagesReducer: { stagesList },
      companyReducer: { companyList },
    },
    companiesReducer: {
      companyMainReducer: { searchResults },
      companyReducer: { companyContacts, companyContactsLoading },
    },
    peopleReducer: {
      internalPeopleReducer: { teamList },
    },
    discussionsReducer: { userTeams },
  } = useSelector((state) => state);
  const council_role = getCookie("council_role_");

  useEffect(() => {
    if (preselectedData?.id) {
      setPreselectedModel({
        first: preselectedData?.first_name,
        last: preselectedData?.last_name,
        email: preselectedData?.email,
        title: preselectedData?.title,
        teamId: [],
      });
    }
  }, [preselectedData]);
  useEffect(() => {
    if (dropDownRef.current) {
      dropDownRef.current.select.current.firstElementChild.lastElementChild.setAttribute(
        "autocomplete",
        "nope"
      );
    }
  }, [dropDownRef]);

  useEffect(() => {
    if (multiselectRef.current) {
      multiselectRef.current.select.current.firstElementChild.firstChild.setAttribute(
        "autocomplete",
        "nope"
      );
    }
  }, [multiselectRef, selectedAccessLevel]);

  useEffect(() => {
    if (["ttp_administrator", "council_administrator"].includes(council_role)) {
      dispatch(
        peopleListTeamsGet({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(userTeamsGet({ enqueueSnackbar }));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(defaultModel).length > 0 && options && options.length > 0) {
      setmodel({
        ...initModel,
        ...defaultModel,
      });
    }
  }, [defaultModel, options, applyToOptions]);

  useEffect(() => {
    if (stagesList && stagesList.length !== 0) {
      setOptions(stagesList);
    }
  }, [stagesList]);

  useEffect(() => {
    if (companyList && companyList.length !== 0) {
      const newList = [...companyList, { id: "all", name: "All Companies" }];
      setApplyToOptions(newList);
    }
  }, [companyList]);

  useEffect(() => {
    if (
      teamList &&
      teamList.length > 0 &&
      ["ttp_administrator", "council_administrator"].includes(council_role)
    ) {
      setTeamOptions(teamList);
    } else if (userTeams && userTeams.length > 1) {
      userTeams.shift();
      setTeamOptions(userTeams);
    }
  }, [teamList, userTeams]);

  useEffect(() => {
    if (!companySelectChanged) {
      setCompanySelectChanged(true);
    }
  }, [companySelectChanged]);

  useEffect(() => {
    if (msftContacts?.length === 0) {
      searchUsers(instance, accounts, "", 50, session?.users_integrations).then(
        (res) => {
          setMsftContacts(res?.value);
        }
      );
    }
  }, [msftContacts]);

  useEffect(() => {
    searchUsers(
      instance,
      accounts,
      msftSearch,
      50,
      session?.users_integrations
    ).then((res) => {
      setMsftContacts(res?.value);
    });
  }, [debouncedValue]);

  const handleMSFTSearch = (val) => {
    setMsftSearch(val);
  };

  const getEmail = (person) => {
    const email = person.mail || person.email || person.imAddresses[0];
    const fromEmails = person.emailAddresses[0];
    const resultEmail = email || fromEmails?.address;
    return resultEmail === undefined || resultEmail === {} ? null : resultEmail;
  };

  const handleProfileSelect = (val) => {
    const person = val.obj[0];
    setmodel({
      first: person.givenName,
      last: person.surname,
      email: person.mail,
      title: person.jobTitle,
      department: person.department,
    });
  };
  const handleCancelClick = (e) => {
    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
  };

  const handleSaveClick = (e) => {
    const role = getRole(selectedAccessLevel.val);

    if (role === "company" && !selectedCompany.id) {
      setError("company");
      enqueueSnackbar("Please select company", {
        variant: "error",
      });
      setError("company");

      return;
    }

    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  const handleOnSubmit = (value, resetForm, setErrors) => {
    const role = getRole(selectedAccessLevel.val);

    const obj = {
      ...value,
      enqueueSnackbar,
      accountId: "id",
      councilId: selectedCouncil.id,
      permission: role,
      company_id: selectedCompany?.id,
    };

    if (
      usersList.some((user) => user.email === value.email) &&
      !selectedContact.length
    ) {
      enqueueSnackbar("This user is already a member.", {
        variant: "error",
      });
      setErrors({ email: "Choose an unique email address" });
    } else {
      if (obj.company_id) {
        dispatch(
          companyContactInvite({
            companyId: obj.company_id,
            data: {
              first_name: obj.first,
              last_name: obj.last,
              email: obj.email,
              title: obj.title,
              mobile: obj.mobile,
              company_id: obj.company_id,
            },
            enqueueSnackbar,
            cb: () => {
              enqueueSnackbar("User added successfully!", {
                variant: "success",
              });
            },
          })
        );
      } else {
        dispatch(peopleMembersAdd(obj));
      }

      dispatch(appModelSettingSet({ ...modalSetting, show: false }));
      setErrors({});
      resetForm();
    }
  };

  const handleMemberSelect = (val, setFieldValue) => {
    setSelectedContact(val);

    const team = [];
    if (val[0]?.teams_ids?.length) {
      team.push({
        id: val[0]?.teams_ids[0],
        name: val[0]?.teams_names[0],
      });
    }

    setFieldValue("email", val[0]?.email);
    setFieldValue("first", val[0]?.first_name);
    setFieldValue("last", val[0]?.last_name);
    setFieldValue("title", val[0]?.title);
    setFieldValue("teamId", team);
    setSelectedTeam(team);
  };

  const handleOptionSelect = (values) => {
    if (values) {
      setError("");
    }
    // setSelected(values);
    setSelectedCompany(values[0]);
  };

  const handleTypeSearch = (val) => {
    setElasticQuery(val.search);
  };

  useEffect(() => {
    if (selectedAccessLevel?.val === 4) {
      setIsCompanyAccessSelected(true);
    } else {
      setIsCompanyAccessSelected(false);
    }
  }, [selectedAccessLevel]);

  useEffect(() => {
    if (searchResults?.length) {
      setCompanyOptionst(searchResults);
    }
  }, [searchResults]);

  useEffect(() => {
    dispatch(
      searchInCouncil({
        data: elasticQueryy,
        council_id: selectedCouncil.id,
        // project_id: selectedProject?.id,
        mode:
          patientOrg && patientOrg ? "gsk/patient_organizations" : "companies",
        enqueueSnackbar,
        cb: () => {},
      })
    );
  }, [elasticQueryy]);

  useEffect(() => {
    if (isCompanyAccessSelected && selected[0]) {
      dispatch(
        getCompanyContactsAttempt({
          companyId: selected[0].id,
        })
      );
    }
  }, [selected[0]?.id]);

  return (
    <>
      <ModalBody>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={preselectedModel || model}
          validationSchema={validation && validation}
          onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
            setSubmitting(true);
            handleOnSubmit(values, resetForm, setErrors);
          }}
        >
          {({ handleSubmit, values, setFieldValue, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.instructionText}>
                An email invite will be sent to the person below with signup
                instructions!
              </div>
              {isCompanyAccessSelected && (
                <div className={styles.selectContact}>
                  <div className={styles.contactDropDown}>
                    <Label>Select Contact</Label>
                    {selected[0]?.id ? (
                      <Select
                        placeholder="Select Contact"
                        name="contact_id"
                        onChange={(selected) => {
                          if (!selected) {
                            return;
                          }
                          return handleMemberSelect(selected, setFieldValue);
                        }}
                        values={selectedContact}
                        options={companyContacts.map(
                          ({ id: value, full_name: label, ...rest }) => ({
                            label,
                            value,
                            ...rest,
                          })
                        )}
                      />
                    ) : (
                      <UserMultiSelectSearch
                        placeholder="Select Contact"
                        labelField="full_name"
                        valueField="id"
                        searchBy="full_name"
                        onChange={(e) => {
                          setSelectedCompany({
                            id: e[0].company_id,
                            name: e[0].company.name,
                          });

                          return handleMemberSelect(e, setFieldValue);
                        }}
                        multi={false}
                        endpoint="external_contacts"
                      />
                    )}

                    {companyContactsLoading && "Loading contacts..."}
                  </div>
                  <div>OR</div>
                </div>
              )}

              <div className={styles.editStageFormWrp}>
                <Label>Email</Label>
                <TextBox
                  type="text"
                  name="email"
                  placeholder="Email"
                  className={styles.textBox}
                  formProps={{ setFieldValue, values, ...formikprops }}
                />
                <div className={styles.name}>
                  <div className={styles.firstName}>
                    <Label>First Name</Label>
                    <TextBox
                      type="text"
                      name="first"
                      placeholder="First name"
                      className={styles.textBox}
                      formProps={{ setFieldValue, values, ...formikprops }}
                    />
                  </div>
                  <div className={styles.lastName}>
                    <Label>Last Name</Label>
                    <TextBox
                      type="text"
                      name="last"
                      placeholder="Last name"
                      className={styles.textBox}
                      formProps={{ setFieldValue, values, ...formikprops }}
                    />
                  </div>
                </div>
                <Label>Title</Label>
                <TextBox
                  type="text"
                  name="title"
                  placeholder="Title"
                  className={styles.textBox}
                  formProps={{ setFieldValue, values, ...formikprops }}
                />

                <Label>Access Level</Label>
                <DropSelect
                  placeholder="Choose a access level"
                  dropRef={dropDownRef}
                  name="permission"
                  labelField="text"
                  valueField="val"
                  searchBy="text"
                  disabled={preselectedAccessType.val === 4}
                  values={[selectedAccessLevel]}
                  options={
                    accessOptions.length
                      ? accessOptions?.filter((option) => {
                          if (excludeCompanyRole && option.text === "Company") {
                            return false;
                          }
                          if (
                            council_role === "ttp_administrator" ||
                            council_role === "council_administrator"
                          ) {
                            return option;
                          }
                          return option.val !== 1;
                        })
                      : []
                  }
                  onChange={(e) => {
                    setFieldValue("permission", e);
                    setSelectedAccessLevel(e);
                  }}
                  formProps={{ setFieldValue, values, ...formikprops }}
                />
                {/* !invitedContact &&  */}
                {teamOptions.length > 0 && selectedAccessLevel?.val !== 4 && (
                  <>
                    <Label>Team</Label>
                    <DropSelect
                      placeholder="Choose a team"
                      name="teamId"
                      labelField="name"
                      valueField="id"
                      searchBy="name"
                      multi={false}
                      onChange={(e) => {
                        setFieldValue("teamId", e.obj);
                        setSelectedTeam(e.obj);
                        setTeamsError("");
                      }}
                      options={teamOptions}
                      values={selectedTeam}
                    />
                    {teamsError && (
                      <span className={styles.errorText}>{teamsError}</span>
                    )}
                  </>
                )}

                {microsoftLogin && (
                  <>
                    <Label>Department</Label>
                    <TextBox
                      type="text"
                      name="department"
                      placeholder="Department"
                      className={styles.textBox}
                      formProps={{ setFieldValue, values, ...formikprops }}
                    />

                    <br />
                    <Label>Search Employee Directory</Label>
                    <DropSelect
                      placeholder="Search employee directory"
                      name="id"
                      labelField="displayName"
                      valueField="id"
                      searchBy="displayName"
                      multi={false}
                      onChange={(e) => {
                        handleProfileSelect(e);
                      }}
                      options={msftContacts}
                      searchFn={(evt) => {
                        handleMSFTSearch(evt.state.search);
                      }}
                    />
                  </>
                )}

                <div
                  className={
                    isCompanyAccessSelected || preselectedAccessType.val === 4
                      ? styles.companyDropVisible
                      : styles.companyDropHide
                  }
                >
                  <Label>Company</Label>
                  <DropSelect
                    dropRef={multiselectRef}
                    onChange={(values) => {
                      setSelected(values?.obj);
                      if (values?.obj[0]?.id !== selectedCompany.id) {
                        handleOptionSelect(values?.obj);
                      }
                    }}
                    placeholder="Select Company"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    options={companyOptions}
                    values={selectedCompany?.name ? [selectedCompany] : []}
                    multi={false}
                    searchFn={(args) => {
                      handleTypeSearch(args.state);
                    }}
                  />
                  {error === "company" && (
                    <div className={styles.errorText}>Company required</div>
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button type="submit" onClick={handleSaveClick}>
          Invite Member
        </Button>
      </ModalFooter>
    </>
  );
};

export default MemberNewComponent;

import React from "react";
import { UnderConstruction } from "../primitives";

const MediaComponent = (props) => (
  <div>
    <UnderConstruction text="Media Page" />
  </div>
);

export default React.memo(MediaComponent);

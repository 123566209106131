export const SET_SELECTED_ORGANIZATIONS = "SET_SELECTED_ORGANIZATIONS";
export const setSelectedOrganizations = (payload) => ({
  type: SET_SELECTED_ORGANIZATIONS,
  payload,
});

export const PATIENT_ORGANIZATIONS_GET_ATTEMPT = "PATIENT_ORGANIZATIONS_GET_ATTEMPT";
export const patientOrganizationsGet = (payload) => ({
  type: PATIENT_ORGANIZATIONS_GET_ATTEMPT,
  payload,
});
export const PATIENT_ORGANIZATIONS_GET_SUCCESS = "PATIENT_ORGANIZATIONS_GET_SUCCESS";
export const patientOrganizationsGetSuccess = (payload) => ({
  type: PATIENT_ORGANIZATIONS_GET_SUCCESS,
  payload,
});

export const ADD_PATIENT_ORGANIZATION_TO_PROJECT_ATTEMPT = "ADD_PATIENT_ORGANIZATION_TO_PROJECT_ATTEMPT";
export const projectPatientOrganizationNew = (payload) => ({
  type: ADD_PATIENT_ORGANIZATION_TO_PROJECT_ATTEMPT,
  payload,
});
export const ADD_PATIENT_ORGANIZATION_TO_PROJECT_SUCCESS = "ADD_PATIENT_ORGANIZATION_TO_PROJECT_SUCCESS";
export const projectPatientOrganizationNewSuccess = (payload) => ({
  type: ADD_PATIENT_ORGANIZATION_TO_PROJECT_SUCCESS,
  payload,
});

export const PATIENT_ORGANIZATION_DISEASES_RESET = "PATIENT_ORGANIZATION_DISEASES_RESET";
export const patientOrganizationDiseasesReset = (payload) => ({
  type: PATIENT_ORGANIZATION_DISEASES_RESET,
});

export const PATIENT_ORGANIZATION_SELECTED_DISEASES_RESET = "PATIENT_ORGANIZATION_SELECTED_DISEASES_RESET";
export const patientOrganizationSelectedDiseasesReset = () => ({
  type: PATIENT_ORGANIZATION_SELECTED_DISEASES_RESET,
});

export const PATIENT_ORGANIZATION_GET_DIVERSITY_GROUPS_ATTEMPT = "PATIENT_ORGANIZATION_GET_DIVERSITY_GROUPS_ATTEMPT";
export const patientOrganizationGetDiversityGroups = (payload) => ({
  type: PATIENT_ORGANIZATION_GET_DIVERSITY_GROUPS_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_GET_DIVERSITY_GROUPS_SUCCESS = "PATIENT_ORGANIZATION_GET_DIVERSITY_GROUPS_SUCCESS";
export const patientOrganizationGetDiversityGroupsSuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_GET_DIVERSITY_GROUPS_SUCCESS,
  payload,
});

export const PATIENT_ORGANIZATION_GET_DISEASES_ATTEMPT = "PATIENT_ORGANIZATION_GET_DISEASES_ATTEMPT";
export const patientOrganizationGetDiseases = (payload) => ({
  type: PATIENT_ORGANIZATION_GET_DISEASES_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_GET_DISEASES_SUCCESS = "PATIENT_ORGANIZATION_GET_DISEASES_SUCCESS";
export const patientOrganizationGetDiseasesSuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_GET_DISEASES_SUCCESS,
  payload,
});

export const PATIENT_SEARCH_SUCCESS = "PATIENT_SEARCH_SUCCESS";
export const patientOrgSearchSuccess = (payload) => ({
  type: PATIENT_SEARCH_SUCCESS,
  payload,
});

export const DESEASE_CHECK_ATTEMPT = "DESEASE_CHECK_ATTEMPT";
export const deseasCheck = (payload) => ({
  type: DESEASE_CHECK_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_DELETE = "PATIENT_ORGANIZATION_DELETE";
export const patientOrganizationDelete = (payload) => ({
  type: PATIENT_ORGANIZATION_DELETE,
  payload,
});

export const PATIENT_ORGANIZATION_DELETE_SUCCESS = "PATIENT_ORGANIZATION_DELETE_SUCCESS";
export const patientOrganizationDeleteSuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_DELETE_SUCCESS,
  payload,
});

export const COUNTRY_CHECK_ATTEMPT = "COUNTRY_CHECK_ATTEMPT";
export const countryCheck = (payload) => ({
  type: COUNTRY_CHECK_ATTEMPT,
  payload,
});

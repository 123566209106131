import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilePicker } from "react-file-picker";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";

import { checkViewerRole } from "common/checkers/viewerChecker";
import { getCookie, shouldDownload } from "common/helper";
import { isProjectAllowedEditor } from "common/checkers/isProjectAllowedEditor";
import { ModalBody, ModalFooter } from "application/modal/index";
import { Icon, smSize, mdSize } from "common/icon";
import { useS3FileUpload, useModelPopup } from "common/hooks";
import { addDocument, getDocuments } from "common/actions/common.action";
import DragAndDrop from "common/components/dragAndDrop";
import { OptionSize } from "modules/primitives/moreOptions/moreOptions.constant";
import {
  Button,
  BtnType,
  Pannel,
  MoreOptions,
  Loading,
  Pagination,
  pageSettings,
} from "../../../../primitives";
import { addDocOptions } from "./document.constatnt";
import styles from "./document.module.scss";

import { dropOptions } from "../../tasks/taskInfo/documentList/documentList.constant";

import { projectTaskDocumentDelete } from "../../tasks/tasks.action";

import UploadedByInfo from "../../../../../common/components/uploadedByInfo";
import { ALLOWED_FILE_EXTENSIONS } from "../../../../../common/constants";

const DocumentComponent = () => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    projectsReducer: {
      projectReducer: { selectedProject },
    },
    documentsReducer: {
      documents,
      docListMeta,
      shouldUpdateDocuments,
      loadingDocs,
    },
  } = useSelector((state) => state);
  const councilRole = getCookie("council_role_");
  const popup = useModelPopup();

  const { fileUpload, fileDelete } = useS3FileUpload();
  const [docList, setDocList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [patientOrg, setPatientOrg] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);
  const [firstRender, setIsFirstRender] = useState(true);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);

  const [pageSetting, setPageSetting] = useState(pageSettings);
  const [currentUserProjRole, setCurrentUserProjRole] = useState(false);

  const handlePagingChange = (page) => {
    setSelectedPage(page);
    setPageSetting((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const getInfoAboutDocuments = () => {
    if (selectedProject?.stage_template?.patient_organization) {
      setPatientOrg(true);
    }
    dispatch(
      getDocuments({
        document_resource_id: selectedProject.id,
        document_resource_type: "Project",
        customUrl: `/projects/${selectedProject.id}/documents?items=10&page=${
          selectedPage || 1
        }`,
        enqueueSnackbar,
      })
    );
  };

  useEffect(() => {
    setPageSetting((prev) => ({
      ...prev,
      total: docListMeta.total,
      pageCount: 1,
    }));
  }, [docListMeta, documents]);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (selectedProject?.id) {
      const currentUserRole = selectedProject?.project_members?.find(
        (m) => m.user.id === session?.id
      );
      setCurrentUserProjRole(currentUserRole?.user_role);
    }
    if (selectedProject && (shouldUpdateDocuments || firstRender)) {
      setIsFirstRender(false);
      getInfoAboutDocuments();
    }

    return () => null;
  }, [selectedProject, shouldUpdateDocuments]);

  useEffect(() => {
    if (selectedPage && !firstRender) {
      getInfoAboutDocuments();
    }
  }, [selectedPage]);

  useEffect(() => {
    if (docList.length === 0 && selectedPage > 1) {
      setSelectedPage((prevPage) => prevPage - 1);
    }
  }, [docList]);

  useEffect(() => {
    setDocList(documents);

    return () => null;
  }, [documents]);

  const handleAddDocumentClick = async (FileObject) => {
    const file = FileObject;
    const { name } = file;

    const extension = name.slice(((name.lastIndexOf(".") - 1) >>> 0) + 2);
    const isRightExtension = ALLOWED_FILE_EXTENSIONS.includes(extension);

    if (!isRightExtension) {
      const message = `Files with the extension "${extension}" are not allowed.`;
      enqueueSnackbar(message, {
        variant: "error",
        key: "extensionNotAllowed",
        persist: true,
      });

      const timeout = setTimeout(() => {
        closeSnackbar("extensionNotAllowed");
        clearTimeout(timeout);
      }, 5000);

      return;
    }

    setLoading(true);
    const pathname = "documents/";
    if (documents.findIndex((doc) => doc.name === name) === -1) {
      await fileUpload(file, pathname).then((res) => {
        if (res && res.location) {
          savedocument(res.location, name);
          enqueueSnackbar("File successfully uploaded.", {
            variant: "success",
          });
        }
        setLoading(false);
      });
    } else {
      setLoading(false);

      if (documents.findIndex((doc) => doc.name === name) !== -1) {
        enqueueSnackbar("This document was already added.", {
          variant: "error",
        });
      }
    }
  };

  const savedocument = (s3url, name) => {
    const obj = {
      description: "",
      name,
      s3url,
      councilId: selectedCouncil.id,
      document_resource_type: "Project",
      document_resource_id: selectedProject.id,
      added_by_id: session.id,
      enqueueSnackbar,
    };
    dispatch(addDocument(obj));
  };
  const uploadHandler = async (files) => {
    setForbiddenFiles((prevFiles) => {
      const localFiles = [...prevFiles];
      // localFiles.push({
      //   name: file.name,
      //   error: "Your file is too large. File size limit: 500MB",
      // });
      return localFiles;
    });
  };
  const handleDocMoreOptionClick = (val) => {
    if (val === "addLink") {
      // handleAddLinkClick();
      popup.show({
        title: "Confirm",
        show: true,
        height: "300",
        width: "540",
        component: (
          <div>
            <ModalBody>
              <div>++++++++++++</div>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  popup.hide();
                }}
                btn={BtnType.FRAME_LESS}
              >
                No
              </Button>
              <Button
                onClick={() => {
                  popup.hide();
                }}
              >
                Yes
              </Button>
            </ModalFooter>
          </div>
        ),
      });
    }
    if (val === "upload") {
      popup.show({
        title: "Confirm",
        show: true,
        height: "300",
        width: "540",
        component: (
          <div>
            <ModalBody>
              <div>
                {" "}
                <div className={styles.dropBoxContainer}>
                  <div className={styles.dragAndDropContainer}>
                    <DragAndDrop
                      uploadHandler={uploadHandler}
                      loading={loadingDocs}
                      customText="drag and drop documents here"
                    />
                  </div>

                  {forbiddenFiles?.length ? (
                    <div className={styles.forbiddenfileContainer}>
                      Files that weren't allowed:{" "}
                      {forbiddenFiles.map((file) => (
                        <span className={styles.forbiddenfileName}>
                          {`${file.name}: `}
                          <span className={styles.errorText}>
                            {" "}
                            {file.error}
                          </span>
                        </span>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  popup.hide();
                }}
                btn={BtnType.FRAME_LESS}
              >
                No
              </Button>
              <Button
                onClick={() => {
                  popup.hide();
                }}
              >
                Yes
              </Button>
            </ModalFooter>
          </div>
        ),
      });
    }
  };
  const getRightPanel = useCallback(
    (func) => (
      <div className={styles.newTask}>
        {/* selectedCouncil?.name !== "Ford" && */}
        {selectedProject && selectedProject.id && !func && (
          <FilePicker
            // extensions={['pdf']}
            maxSize={500}
            onChange={(FileObject) => {
              handleAddDocumentClick(FileObject);
            }}
            onError={(errMsg) =>
              enqueueSnackbar(
                "We could not upload your document because your file size is too big. Please make sure the file is less than 500 MB",
                {
                  variant: "error",
                }
              )
            }
          >
            {loading ? (
              <Loading />
            ) : (
              <Button btn={BtnType.REGULAR} icon="icn-add">
                Add a document
              </Button>
            )}
          </FilePicker>
        )}
        {func && (
          <Tooltip
            title="You have View-Only access. To add a Document to this Project, please ask your Admin to upgrade your account to Standard access and invite you to this Project."
            placement="top"
          >
            <div>
              <Button btn={BtnType.DISABLED} icon="icn-add">
                Add a document
              </Button>
            </div>
          </Tooltip>
        )}
        {/* {selectedCouncil?.name === "Ford" && (
          <MoreOptions
            options={addDocOptions}
            className={styles.addNewWrapper}
            onClick={handleDocMoreOptionClick}
            {...mdSize}
            iconName="icn-add"
          />
        )} */}
      </div>
    ),
    [selectedProject, loading]
  );

  const handleMoreOptionClick = async (value, id, name) => {
    if (value === "remove") {
      const pathname = `documents/${name}`;
      dispatch(projectTaskDocumentDelete({ documentId: id, enqueueSnackbar }));
      setDocList(documents.filter((d) => d.id !== id));
      await fileDelete(pathname);
    }
  };
  if (loadingDocs) {
    return <Loading />;
  }
  return (
    <Pannel
      title="Documents"
      padding="0"
      rightContent={getRightPanel(blockFunctionality)}
    >
      <div className={styles.wrapper}>
        <div className={styles.grid}>
          <div className={styles.gridHeader}>
            <div className={styles.document}>Document</div>
            <div className={styles.company}>
              {patientOrg ? "Organization" : "Company"}
            </div>
            <div className={styles.task}>Task</div>
            <div className={styles.company}>Uploaded By</div>
          </div>
          <div className={styles.gridBody}>
            {docList && docList.length === 0 ? (
              <h4 className="d-flex flex-grow-1 justify-content-center mt-4">
                No documents yet.
              </h4>
            ) : null}
            {docList &&
              docList.map((document) => (
                <div
                  key={document.id}
                  className={`${styles.gridRow} ${
                    document?.council_task_template !== null && styles.template
                  }`}
                >
                  <div className={styles.document}>{document.name}</div>
                  <div className={styles.company}>
                    {document.projects_task_assignment
                      ?.projects_task_organization?.organization?.name ||
                      "Project Wide"}
                  </div>
                  <div className={styles.task}>
                    {document.projects_task_assignment?.projects_task?.name ||
                      document?.council_task_template?.name ||
                      "Project Wide"}
                  </div>
                  <div
                    className={classNames("d-flex flex-grow-1", styles.company)}
                  >
                    <UploadedByInfo document={document} />
                  </div>
                  {document?.council_task_template && (
                    <div
                      className={classNames(
                        "d-flex flex-grow-1",
                        styles.docTemplate
                      )}
                    >
                      Template
                    </div>
                  )}

                  <div
                    className={classNames(
                      "d-flex align-items-center justify-content-between",
                      styles.docActions
                    )}
                  >
                    <div className="d-flex align-items-center">
                      <a
                        href={document.uri}
                        target="_blank"
                        download
                        rel="noreferrer"
                      >
                        <Icon
                          {...smSize}
                          icon="icn-export-button"
                          className={styles.download}
                        />
                      </a>
                      <a
                        className={styles.docView}
                        target="blank"
                        href={document.uri}
                      >
                        {shouldDownload(document.name) ? "download" : "view"}
                      </a>
                    </div>
                    <div className={classNames("ml-5", styles.iconMore)}>
                      {(isProjectAllowedEditor(
                        councilRole,
                        currentUserProjRole
                      ) ||
                        document.added_by.id === session?.id) && (
                        <MoreOptions
                          options={dropOptions}
                          className={styles.moreOptWrp}
                          onClick={(value) =>
                            handleMoreOptionClick(
                              value,
                              document.id,
                              document.name
                            )
                          }
                          size={OptionSize.SMALL}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {docListMeta?.total > 5 ? (
        <div className={styles.paginationCon}>
          <Pagination {...pageSetting} onChange={handlePagingChange} />
        </div>
      ) : null}
    </Pannel>
  );
};

export default DocumentComponent;

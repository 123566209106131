import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { nameToColour } from "../../../common/helper";
import styles from "./userInline.module.scss";
import { UserInlineTypes } from "./userInline.constants";
import { councilUserGet } from "../../council/council.action";

const UserInlineComponent = (props) => {
  const {
    userId = null,
    type = UserInlineTypes.WITH_NAME,
    className = null,
    classObj = {},
    showMine = true,
    first = "",
    last = "",
    local = false,
    avatar = null,
    noNavigation,
    fromExternal = false,
    dontRequest = false,
    customTitleClassName = null,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [userName, setUserName] = useState(`${first} ${last}`);
  const [userImg, setUserImg] = useState(null);
  const [defaultImage, setDefaultImage] = useState(null);
  const history = useHistory();
  const [defaultAvatar, setDefaultAvatar] = useState(null);
  const dispatch = useDispatch();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil, usersListById },
  } = useSelector((state) => state);

  useEffect(() => {
    if (userId && selectedCouncil) {
      const user = usersListById[userId];

      if (user) {
        generateUser({
          first: user.first_name,
          last: user.last_name,
          userId: user.id,
          avatar: user.avatar,
        });
      } else if (local && !user) {
        generateUser({
          first,
          last,
          userId,
          avatar,
        });
      } else if (
        !localStorage.getItem(`userGetApi_${userId}`)
        && userId !== "Any"
        && !dontRequest
      ) {
        localStorage.setItem(`userGetApi_${userId}`, userId);
        dispatch(councilUserGet({ userId, enqueueSnackbar }));
      }
    }
  }, [userId, selectedCouncil, props, dontRequest, first, last]);

  useEffect(() => {
    const user = usersListById[userId];

    if (user) {
      generateUser({
        first: user.first_name,
        last: user.last_name,
        userId: user.id,
        avatar: user.avatar,
      });
    }
  }, [usersListById, userId]);

  useEffect(()=>{
    setUserName(`${first} ${last}`)
  },[first, last])

  const generateUser = (user) => {
    setUserName(`${user.first} ${user.last}`);
    if (user.avatar) {
      setUserImg(user.avatar);
    } else {
      setUserImg(null);
      generateAvatar(user);
    }
  };

  const generateAvatar = ({ first, last }) => {
    const name = `${first && first.charAt(0)}${last && last.charAt(0)}`;
    setDefaultImage({ name, bgColor: nameToColour(first + last), userId });
  };

  useEffect(() => {
    if (!avatar) {
      generateAvatar({
        first,
        last,
      });
    }
  }, [userId, first, last]);

  const handleUserRedirect = (e) => {
    if (noNavigation || fromExternal) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    if (userId) {
      history.push(`/people/internal/profile/${userId}`);
    }
  };

  const handleImgLoadError = () => {
    setUserImg(null);
    setDefaultAvatar(null);

    generateAvatar({
      first,
      last,
    });
  };

  return (
    <div
      className={classNames(userId && styles.pointer, styles.userInlineWrp)}
      onClick={(e) => handleUserRedirect(e)}
    >
      <div className={classNames(styles.userinlineImg, className && className)}>
        {avatar ? (
          <img onError={handleImgLoadError} src={avatar} alt={userName} />
        ) : (
          defaultImage &&
          defaultImage.name && (
            <div className={styles.defaultImage} style={{ backgroundColor: defaultImage.bgColor }}>
              {defaultImage.name}
            </div>
          )
        )}
      </div>
      {type === UserInlineTypes.WITH_NAME && (
        <div
          className={classNames(
            styles.userInlineName,
            "caption",
            classObj.name && classObj.name,
            styles[className],
            customTitleClassName
          )}
        >
          {session && session.id === userId && showMine ? "You" : userName}
        </div>
      )}
    </div>
  );
};

export default React.memo(UserInlineComponent);

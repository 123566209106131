import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { format } from "date-fns";
import { useSnackbar } from "notistack";

import styles from "./founded.module.scss";

import { Icon, smSize } from "../../../../../../common/icon";
import {
  queryStringUrlReplacement,
  useQuery,
} from "../../../../../../common/helper";
import { generateArrayOfYears } from "./founded-utils";
import { DropSelect } from "../../../../../primitives";

const years = generateArrayOfYears(1900);

const FoundedFilter = ({ expanded, handleExpand, disabled }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const foundedFrom = useQuery().get("foundedFrom");
  const foundedTo = useQuery().get("foundedTo");

  const [collapsed, setCollapsed] = useState(false);
  const [foundedValues, setFoundedValues] = useState({
    foundedFrom: "Select",
    foundedTo: format(new Date(), "yyyy"),
  });

  useEffect(() => {
    if (disabled) {
      setCollapsed(false);
      return
    }

    setCollapsed(expanded);
  }, [expanded, disabled]);

  useEffect(() => {
    setFoundedValues({
      ...foundedValues,
      foundedFrom: foundedFrom || "Select",
      foundedTo: foundedTo || format(new Date(), "yyyy"),
    });
  }, [foundedFrom, foundedTo]);

  useEffect(() => {
    if (!collapsed) {
      return;
    }

    const getFoundedFromInput = document.querySelector(
      "#foundedFrom .react-dropdown-select input",
    );
    const getFoundedToInput = document.querySelector(
      "#foundedTo .react-dropdown-select input",
    );

    if (getFoundedFromInput) {
      const getCurrSelectedValue = document.querySelector(
        "#foundedFrom .react-dropdown-select-content > span",
      );

      getFoundedFromInput.addEventListener("keyup", (evt) => {
        // handle delete press
        if (evt.keyCode === 8 || evt.keyCode === 46) {
          clearByProp("foundedFrom");
          return;
        }

        const { value } = evt.target;

        // handle enter press
        if (evt.keyCode === 13) {
          if (value && value.length === 4) {
            if (Number(value) > Number(foundedValues.foundedTo)) {
              enqueueSnackbar(
                "Founded from year should not be after Founded to year",
                {
                  variant: "error",
                },
              );

              return;
            }
            handleOnChange(value, "foundedFrom");
          }

          return;
        }

        if (value && typeof Number(value) === "number") {
          getCurrSelectedValue.textContent = "";
        }
      });
    }

    if (getFoundedToInput) {
      const getCurrSelectedValue = document.querySelector(
        "#foundedTo .react-dropdown-select-content > span",
      );

      getFoundedToInput.addEventListener("keyup", (evt) => {
        // handle delete press
        if (evt.keyCode === 8 || evt.keyCode === 46) {
          clearByProp("foundedTo");
          return;
        }

        const { value } = evt.target;

        // handle enter press
        if (evt.keyCode === 13) {
          if (value && value.length === 4) {
            if (Number(value) < Number(foundedValues.foundedFrom)) {
              enqueueSnackbar(
                "Founded to year should not be before Founded from year",
                {
                  variant: "error",
                },
              );

              return;
            }
            handleOnChange(value, "foundedTo");
          }

          return;
        }

        if (value && typeof Number(value) === "number") {
          getCurrSelectedValue.textContent = "";
        }
      });
    }

    return () => {
      if (getFoundedFromInput) {
        getFoundedFromInput.removeEventListener("keyup", null);
      }
      if (getFoundedToInput) {
        getFoundedToInput.removeEventListener("keyup", null);
      }
    };
  }, [collapsed]);

  const clearByProp = (prop) => {
    setFoundedValues({
      ...foundedValues,
      [prop]: "",
    });
  };

  const handleCollapseClick = () => {
    if (disabled) {
      return;
    }

    if (!collapsed) {
      handleExpand("founded");
    }
    setCollapsed(!collapsed);
  };

  const handleOnChange = (value, prop) => {
    const yearExist = years.some((y) => y.year === Number(value));

    if (!yearExist && value !== "Select") {
      return;
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      prop,
      value === "Select" ? "" : value,
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        [prop]: value === "Select" ? "" : value,
        page: 1,
      },
    });
  };

  const handleDropOpen = (prop) => {
    const elementToGo = document.querySelector(
      `#${prop} .react-dropdown-select-dropdown [aria-label="${foundedValues[prop]}"]`,
    );

    if (elementToGo) {
      elementToGo.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <div className={`${styles.itemWrapper} ${disabled ? styles.disabled : ''}`} id="founded">
      <div className={styles.itemTitle} onClick={handleCollapseClick}>
        <span>Year Founded</span>
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(styles.icon, !collapsed && styles.collapse)}
          onClick={handleCollapseClick}
        />
      </div>
      {collapsed ? (
        <div
          className={classNames(
            "d-flex align-items-center",
            styles.withPaddingLeft,
          )}
        >
          <div
            id="foundedFrom"
            className={classNames("d-flex flex-column", styles.scoreField)}
          >
            <label htmlFor="from">From</label>
            <DropSelect
              placeholder=""
              name="from"
              options={[
                {
                  year: "Select",
                },
                ...years.filter(
                  (y) => y.year <= Number(foundedValues.foundedTo),
                ),
              ]}
              labelField="year"
              valueField="year"
              searchBy="year"
              values={
                foundedValues.foundedFrom
                && foundedValues.foundedFrom !== "Select"
                  ? [
                    {
                      year: Number(foundedValues.foundedFrom),
                    },
                  ]
                  : [{ year: "Select" }]
              }
              onChange={(e) => handleOnChange(e.val, "foundedFrom")}
              dropdownPosition="auto"
              clearable
              onClearAll={() => handleOnChange("", "foundedFrom")}
              onDropdownOpen={() => handleDropOpen("foundedFrom")}
            />
          </div>
          <span className={styles.scoreDelimiter}>-</span>
          <div
            id="foundedTo"
            className={classNames("d-flex flex-column", styles.scoreField)}
          >
            <label htmlFor="to">To</label>
            <DropSelect
              placeholder=""
              name="to"
              options={
                foundedValues.foundedFrom === "Select"
                  ? years
                  : [
                    ...years.filter(
                      (y) => y.year >= Number(foundedValues.foundedFrom),
                    ),
                  ]
              }
              labelField="year"
              valueField="year"
              searchBy="year"
              values={[
                {
                  year: Number(foundedValues.foundedTo),
                },
              ]}
              onChange={(e) => handleOnChange(e.val, "foundedTo")}
              dropdownPosition="auto"
              clearable
              onClearAll={() => handleOnChange("", "foundedTo")}
              onDropdownOpen={() => handleDropOpen("foundedTo")}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(FoundedFilter);

import React, { useRef, useState, useEffect } from "react";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import {
  Label,
  TextBox,
  Button,
  BtnType,
  DropSelect,
} from "../../../../primitives";
import { initModel, validation } from "./addEditProjectsField.constants";
import { ModalBody, ModalFooter } from "../../../../../application/modal";
import { useModelPopup } from "../../../../../common/hooks";
import { updateGroupFieldData } from "modules/admin/adminProjects/adminProjects.actions";
import { httpGet, httpPut } from "../../../../../common/httpCall";

const AddEditProjectsGroupFieldModal = ({ data, groupId }) => {
  const formRef = useRef();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [model, setModel] = useState(initModel);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (data) {
      setModel({
        ...initModel,
        position: data.position_in_group,
        id: data.id,
      });
    }
  }, [data]);

  const handleDismiss = () => {
    popup.hide();
  };

  useEffect(() => {
    httpGet({
      call: `project_field_templates?dropdown=true`
    }).subscribe(res => {
      if (res.response) {
        setFields(res.response)
      }
    })
  }, []);

  const onSubmitForm = (values, resetForm) => {
    httpPut({
      call: `project_field_groups/${groupId}/project_field_templates/${data.id}`,
      data: {
        position: values.position,
        project_field_template_id: values.projectField.id,
      }
    }).subscribe(res => {
      if (res.response) {
        resetForm();
        enqueueSnackbar('Successfully updated field in the group', {
          variant: "success",
        });
        handleDismiss();
        dispatch(updateGroupFieldData({
          response: res.response,
        }))
      }
    });
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={model}
      validationSchema={validation}
      onSubmit={(values, { resetForm }) => {
        onSubmitForm(values, resetForm);
      }}
    >
      {({ handleSubmit, setFieldValue, values, ...formikprops }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="mb-3">
              <label>Select Project Field</label>
              <DropSelect
                name="projectField"
                placeholder="Select Project Field"
                labelField="field_name"
                valueField="id"
                options={fields}
                onChange={(val) => {
                  setFieldValue('projectField', val.obj[0]);
                }}
                searchable
                searchBy="field_name"
                SelecedValues={fields.filter(el => el.id === data.id)}
              />
            </div>
            <div className="w-100">
              <Label>Position</Label>
              <TextBox
                type="Number"
                name="position"
                formProps={{ ...formikprops, values }}
                className="w-100"
                min={values.position}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
              Cancel
            </Button>
            <Button
              type="submit"
              btn={BtnType.HIGHLIGHT}
              className="ml-3"
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default AddEditProjectsGroupFieldModal;

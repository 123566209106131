import { combineReducers } from "redux";
import companyMainReducer from "./companyMain/companyMain.reducer";
import companyReducer from "./company/company.reducer";

const companiesReducer = combineReducers({
  companyMainReducer,
  companyReducer,
});

export default companiesReducer;

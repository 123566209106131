export const SET_DATA_FOR_PDF = "SET_DATA_FOR_PDF";
export const setDataForPdf = (payload) => ({
  type: SET_DATA_FOR_PDF,
  payload,
});

export const FAKE_ACTION = "FAKE_ACTION";
export const makeFakeAction = (payload) => ({
  type: FAKE_ACTION,
  payload,
});

export const COMPANIES_BUILDER_GET_THEMES = "COMPANIES_BUILDER_GET_THEMES";
export const companiesBuilderGetThemes = (payload) => ({
  type: COMPANIES_BUILDER_GET_THEMES,
  payload
});

export const COMPANIES_BUILDER_GET_THEMES_SUCCESS = "COMPANIES_BUILDER_GET_THEMES_SUCCESS";
export const companiesBuilderGetThemesSuccess = (payload) => ({
  type: COMPANIES_BUILDER_GET_THEMES_SUCCESS,
  payload,
});

export const PROJECTS_BUILDER_RESET_SELECTED = "PROJECTS_BUILDER_RESET_SELECTED";
export const projectsBuilderResetSelected = () => ({
  type: PROJECTS_BUILDER_RESET_SELECTED,
});

export const PROJECTS_BUILDER_UPDATE_ITEM = "PROJECTS_BUILDER_UPDATE_ITEM";
export const projectsBuilderUpdateItem = (payload) => ({
  type: PROJECTS_BUILDER_UPDATE_ITEM,
  payload,
});

export const PROJECTS_BUILDER_SELECT_ALL = "PROJECTS_BUILDER_SELECT_ALL";
export const projectsBuilderSelectAll = (payload) => ({
  type: PROJECTS_BUILDER_SELECT_ALL,
  payload,
});

export const GET_ADDITIONAL_DATA_FOR_PROJECTS_BUILDER = "GET_ADDITIONAL_DATA_FOR_PROJECTS_BUILDER";
export const getAdditionalDataForProjectsBuilder = (payload) => ({
  type: GET_ADDITIONAL_DATA_FOR_PROJECTS_BUILDER,
  payload,
});

export const GET_ADDITIONAL_DATA_FOR_PROJECTS_BUILDER_SUCCESS = "GET_ADDITIONAL_DATA_FOR_PROJECTS_BUILDER_SUCCESS";
export const getAdditionalDataForProjectsBuilderSuccess = (payload) => ({
  type: GET_ADDITIONAL_DATA_FOR_PROJECTS_BUILDER_SUCCESS,
  payload,
});

export const GET_REPORTS_COMPANIES_CUSTOM_FIELDS = "GET_REPORTS_COMPANIES_CUSTOM_FIELDS";
export const getReportsCompaniesCustomFields = (payload) => ({
  type: GET_REPORTS_COMPANIES_CUSTOM_FIELDS,
  payload,
});

export const GET_REPORTS_COMPANIES_CUSTOM_FIELDS_SUCCESS = "GET_REPORTS_COMPANIES_CUSTOM_FIELDS_SUCCESS";
export const getReportsCompaniesCustomFieldsSuccess = (payload) => ({
  type: GET_REPORTS_COMPANIES_CUSTOM_FIELDS_SUCCESS,
  payload,
});

export const GET_REPORTS_PROJECT_FIELDS = "GET_REPORTS_PROJECT_FIELDS";
export const getReportsProjectFields = (payload) => ({
  type: GET_REPORTS_PROJECT_FIELDS,
  payload,
});

export const GET_REPORTS_PROJECT_FIELDS_SUCCESS = "GET_REPORTS_PROJECT_FIELDS_SUCCESS";
export const getReportsProjectFieldsSuccess = (payload) => ({
  type: GET_REPORTS_PROJECT_FIELDS_SUCCESS,
  payload,
});

export const GET_BUILDER_STAGES = "GET_BUILDER_STAGES";
export const getBuilderStages = (payload) => ({
  type: GET_BUILDER_STAGES,
  payload,
});

export const GET_BUILDER_STAGES_SUCCESS = "GET_BUILDER_STAGES_SUCCESS";
export const getBuilderStagesSuccess = (payload) => ({
  type: GET_BUILDER_STAGES_SUCCESS,
  payload,
});

export const GET_CUSTOM_SECTION_TABLE_DATA = "GET_CUSTOM_SECTION_TABLE_DATA";
export const getCustomSectionTableData = (payload) => ({
  type: GET_CUSTOM_SECTION_TABLE_DATA,
  payload,
});

export const GET_CUSTOM_SECTION_TABLE_DATA_SUCCESS = "GET_CUSTOM_SECTION_TABLE_DATA_SUCCESS";
export const getCustomSectionTableDataSuccess = (payload) => ({
  type: GET_CUSTOM_SECTION_TABLE_DATA_SUCCESS,
  payload,
});

export const UPDATE_SECTION_DATA = "UPDATE_SECTION_DATA";
export const updatedSectionData = (payload) => ({
  type: UPDATE_SECTION_DATA,
  payload,
});

export const HANDLE_DOWNLOAD_SOURCE_DATA = "HANDLE_DOWNLOAD_SOURCE_DATA";
export const handleDownloadSourceData = (payload) => ({
  type: HANDLE_DOWNLOAD_SOURCE_DATA,
  payload,
});

export const HANDLE_DELETE_SECTION = "HANDLE_DELETE_SECTION";
export const handleDeleteSection = (payload) => ({
  type: HANDLE_DELETE_SECTION,
  payload,
});

export const HANDLE_DELETE_SECTION_SUCCESS = "HANDLE_DELETE_SECTION_SUCCESS";
export const handleDeleteSectionSuccess = (payload) => ({
  type: HANDLE_DELETE_SECTION_SUCCESS,
  payload,
});

export const TOGGLE_SOURCE_DATA = "TOGGLE_SOURCE_DATA";
export const toggleSourceData = (payload) => ({
  type: TOGGLE_SOURCE_DATA,
  payload,
});

export const SET_GENERATE_REPORT_DATA = "SET_GENERATE_REPORT_DATA";
export const setGenerateReportData = (payload) => ({
  type: SET_GENERATE_REPORT_DATA,
  payload,
});

export const GET_FUNNEL_DATA = "GET_FUNNEL_DATA";
export const getFunnelData = (payload) => ({
  type: GET_FUNNEL_DATA,
  payload,
});

export const GET_FUNNEL_DATA_SUCCESS = "GET_FUNNEL_DATA_SUCCESS";
export const getFunnelDataSuccess = (payload) => ({
  type: GET_FUNNEL_DATA_SUCCESS,
  payload,
});

export const GET_COMPANIES_BY_TIME_IN_STAGE = "GET_COMPANIES_BY_TIME_IN_STAGE";
export const getCompaniesByTimeInStage = (payload) => ({
  type: GET_COMPANIES_BY_TIME_IN_STAGE,
  payload,
});

export const GET_COMPANIES_BY_TIME_IN_STAGE_SUCCESS = "GET_COMPANIES_BY_TIME_IN_STAGE_SUCCESS";
export const getCompaniesByTimeInStageSuccess = (payload) => ({
  type: GET_COMPANIES_BY_TIME_IN_STAGE_SUCCESS,
  payload,
});

export const GET_COMPANIES_FUNNEL_OVERVIEW_DATA = "GET_COMPANIES_FUNNEL_OVERVIEW_DATA";
export const getCompaniesFunnelOverviewData = (payload) => ({
  type: GET_COMPANIES_FUNNEL_OVERVIEW_DATA,
  payload,
});

export const GET_COMPANIES_FUNNEL_OVERVIEW_DATA_SUCCESS = "GET_COMPANIES_FUNNEL_OVERVIEW_DATA_SUCCESS";
export const getCompaniesFunnelOverviewDataSuccess = (payload) => ({
  type: GET_COMPANIES_FUNNEL_OVERVIEW_DATA_SUCCESS,
  payload,
});

export const GET_COMPANIES_FUNNEL_TECHNOLOGY_DATA = "GET_COMPANIES_FUNNEL_TECHNOLOGY_DATA";
export const getCompaniesFunnelTechData = (payload) => ({
  type: GET_COMPANIES_FUNNEL_TECHNOLOGY_DATA,
  payload,
});

export const GET_COMPANIES_FUNNEL_TECHNOLOGY_DATA_SUCCESS = "GET_COMPANIES_FUNNEL_TECHNOLOGY_DATA_SUCCESS";
export const getCompaniesFunnelTechDataSuccess = (payload) => ({
  type: GET_COMPANIES_FUNNEL_TECHNOLOGY_DATA_SUCCESS,
  payload,
});

export const GET_IDEAS_OVERVIEW_DATA = "GET_IDEAS_OVERVIEW_DATA";
export const getIdeasOverviewData = (payload) => ({
  type: GET_IDEAS_OVERVIEW_DATA,
  payload,
});

export const GET_IDEAS_OVERVIEW_DATA_SUCCESS = "GET_IDEAS_OVERVIEW_DATA_SUCCESS";
export const getIdeasOverviewDataSuccess = (payload) => ({
  type: GET_IDEAS_OVERVIEW_DATA_SUCCESS,
  payload,
});

export const GET_IDEAS_TECHNOLOGY_DATA = "GET_IDEAS_TECHNOLOGY_DATA";
export const getIdeasTechnologyData = (payload) => ({
  type: GET_IDEAS_TECHNOLOGY_DATA,
  payload,
});

export const GET_IDEAS_TECHNOLOGY_DATA_SUCCESS = "GET_IDEAS_TECHNOLOGY_DATA_SUCCESS";
export const getIdeasTechnologyDataSuccess = (payload) => ({
  type: GET_IDEAS_TECHNOLOGY_DATA_SUCCESS,
  payload,
});

export const GET_CUSTOM_BUILDERS_BY_SECTION = "GET_CUSTOM_BUILDERS_BY_SECTION";
export const getCustomBuildersBySection = (payload) => ({
  type: GET_CUSTOM_BUILDERS_BY_SECTION,
  payload,
});

export const GET_CUSTOM_BUILDERS_BY_SECTION_SUCCESS = "GET_CUSTOM_BUILDERS_BY_SECTION_SUCCESS";
export const getCustomBuildersBySectionSuccess = (payload) => ({
  type: GET_CUSTOM_BUILDERS_BY_SECTION_SUCCESS,
  payload,
});

export const SAVE_CUSTOM_BUILDER = "SAVE_CUSTOM_BUILDER";
export const saveCustomBuilder = (payload) => ({
  type: SAVE_CUSTOM_BUILDER,
  payload,
});

export const SAVE_CUSTOM_BUILDER_SUCCESS = "SAVE_CUSTOM_BUILDER_SUCCESS";
export const saveCustomBuilderSuccess = (payload) => ({
  type: SAVE_CUSTOM_BUILDER_SUCCESS,
  payload,
});

export const GET_PROJECTS_OVERVIEW_DATA = "GET_PROJECTS_OVERVIEW_DATA";
export const getProjectsOverviewData = (payload) => ({
  type: GET_PROJECTS_OVERVIEW_DATA,
  payload,
});

export const GET_PROJECTS_OVERVIEW_DATA_SUCCESS = "GET_PROJECTS_OVERVIEW_DATA_SUCCESS";
export const getProjectsOverviewDataSuccess = (payload) => ({
  type: GET_PROJECTS_OVERVIEW_DATA_SUCCESS,
  payload,
});

export const GET_PROJECTS_BY_STATUS = "GET_PROJECTS_BY_STATUS";
export const getProjectsByStatus = (payload) => ({
  type: GET_PROJECTS_BY_STATUS,
  payload,
});

export const GET_PROJECTS_BY_STATUS_SUCCESS = "GET_PROJECTS_BY_STATUS_SUCCESS";
export const getProjectsByStatusSuccess = (payload) => ({
  type: GET_PROJECTS_BY_STATUS_SUCCESS,
  payload,
});

export const SET_SECTION_VISIBILITY = "SET_SECTION_VISIBILITY";
export const setSectionVisibility = (payload) => ({
  type: SET_SECTION_VISIBILITY,
  payload,
});

export const SET_SECTION_VISIBILITY_SUCCESS = "SET_SECTION_VISIBILITY_SUCCESS";
export const setSectionVisibilitySuccess = (payload) => ({
  type: SET_SECTION_VISIBILITY_SUCCESS,
  payload,
});

import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { debounce } from "throttle-debounce";

import ChartCompaniesList from "../../../companies/companyMain/chartsWrapper/chartCompaniesList";
import styles from "./innovationListWrapper.module.scss";

const InnovationListWrapper = ({
  companies,
  selectedCouncil,
  tags,
  handleSelectTags,
  handleRefHeight,
  isDownloadActive,
  selectedTags,
}) => {
  const ref = useRef();

  const [height, setHeight] = useState(28);

  useEffect(() => {
    window.addEventListener("resize", debounceResize);

    return () => {
      window.removeEventListener("resize", null);
    };
  }, []);

  useEffect(() => {
    if (ref?.current) {
      handleRefHeight(`${ref.current.clientHeight}px`);
      setHeight(ref.current.clientHeight);
    }
  }, [ref, tags, handleRefHeight]);

  const debounceResize = debounce(1000, false, () => {
    if (ref?.current) {
      handleRefHeight(`${ref.current.clientHeight}px`);
      setHeight(ref.current.clientHeight);
    }
  });

  const handleTagClick = (tag) => {
    const newTags = tags.map((t) => {
      if (t.id === tag.id) {
        return {
          ...t,
          selected: !t.selected,
        };
      }
      return t;
    });

    handleSelectTags(newTags);
  };

  const getTagBackgroundColor = useCallback(
    (tag) => {
      if (!selectedTags.length) return tag.color;
      const tagIsSelected = selectedTags.some((t) => t.id === tag.id);
      return tagIsSelected ? tag.color : "#999999";
    },
    [selectedTags, tags]
  );

  const makeTitle = useMemo(() => {
    if (selectedCouncil?.traction_tag === 'cke') {
      return 'Vendor Status'
    }

    if (selectedCouncil?.traction_tag === 'kyndryl') {
      return 'Relationship Stage'
    }

    return 'Innovation Funnel';
  }, [selectedCouncil?.traction_tag]);

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between mb-2"
        ref={ref}
      >
        <h4 style={{ marginBottom: "5px" }}>{makeTitle}</h4>
        {tags?.length ? (
          <div className="d-flex align-items-center flex-wrap flex-grow-1 ml-3">
            {tags.map((tag) => (
              <div
                key={tag.id}
                className={styles.tag}
                style={{
                  backgroundColor: getTagBackgroundColor(tag),
                  color: tag.colors.text,
                  marginBottom: height > 28 ? "5px" : 0,
                }}
                onClick={() => handleTagClick(tag)}
                role="img text"
                title={`There are ${tag.count} companies in current search with this tag.`}
                aria-label={`There are ${tag.count} companies in current search with this tag.`}
              >
                {tag?.name}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className={styles.innovationListWrapper}>
        <ChartCompaniesList
          companies={companies}
          selectedTags={selectedTags}
          selectedCouncil={selectedCouncil}
          isDownloadActive={isDownloadActive}
          homeUse
        />
      </div>
    </>
  );
};

export default memo(InnovationListWrapper);

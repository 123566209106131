import React, { useEffect, useState } from "react";
import CheckBox from "modules/admin/adminIdeas/addForm/checkBox";

import styles from "./styles.module.scss";
import { CompanyAvatar } from "modules/primitives";

const CompaniesItem = (props) => {
  const { storeCompanies, company, selectAllCompanies, selected } = props;
  const [companySelected, setCompanySelected] = useState(selected);

  useEffect(() => {
    if (selected) {
      setCompanySelected(true);
      return;
    }
    setCompanySelected(selectAllCompanies);
  }, [selectAllCompanies, selected]);

  return (
    <div className={styles.companiesBlockItem}>
      <CheckBox
        onChange={() => {
          storeCompanies(company.company_id);
          setCompanySelected(!companySelected);
        }}
        isChecked={companySelected}
      />
      <div className={styles.companyIcon}>
        <div className={styles.iconCon}>
          <CompanyAvatar imgSrc={company.logo} name={company.name} />
        </div>
      </div>
      <div>{company.name}</div>
    </div>
  );
};
export default React.memo(CompaniesItem);

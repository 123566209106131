import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useSelector } from "react-redux";

import styles from "./location.module.scss";
import { Icon, smSize } from "../../../../../../common/icon";
import {
  queryParamStringToArray,
  queryStringUrlReplacement,
  useQuery,
} from "../../../../../../common/helper";
import LocationSelectSearch from "./locationSearch";
import FilterRowComponent from "../../filterRow/filterRow.component";
import { IDB_COUNTRIES } from "../../leftWindow.constant";

const LocationFilter = ({ expanded, handleExpand, disabled }) => {
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const history = useHistory();
  const location = useQuery().get("location");
  const idbCountry = useQuery().get("k_country");
  const cleanLocation = location?.length
    ? location === "#city" || location === "#country" || location === "#region"
    : true;

  const [collapsed, setCollapsed] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedIDBCountries, setSelectedIDBCountries] = useState([]);

  useEffect(() => {
    const arr = queryParamStringToArray(idbCountry || "");
    setSelectedIDBCountries(arr);
  }, [idbCountry]);

  useEffect(() => {
    if (location) {
      const arr = queryParamStringToArray(location).map((str) => str.replace(/-/g, ", "));

      setSelectedLocations([
        {
          name: arr[0],
        },
      ]);
    } else {
      setSelectedLocations([]);
    }

    return () => null;
  }, [location]);

  useEffect(() => {
    if (disabled) {
      setCollapsed(false);
      return
    }

    setCollapsed(expanded);
  }, [expanded, disabled]);

  const handleCollapseClick = () => {
    if (disabled) {
      return;
    }

    if (!collapsed) {
      handleExpand("location");
    }
    setCollapsed(!collapsed);
  };

  const handleOnChange = (value, shouldntNavigate) => {
    if (shouldntNavigate) {
      setSelectedLocations([]);
      return;
    }

    const location = value.length
      ? value.reduce(
        (acc, elem) => `${acc}${acc.length ? "," : ""}${elem.name.replace(/, /g, "-")}#${
          elem.type
        }`,
        "",
      )
      : "";

    let search = queryStringUrlReplacement(
      history.location.search,
      "location",
      location,
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        location,
        page: 1,
      },
    });
  };

  const handleAllCountriesCheck = (val, item, category, isParent) => {
    let value = "";

    const newItems = item.items
      .map((i) => ({
        ...i,
        selected: val,
      }))
      .filter((i) => i.selected);

    if (newItems?.length) {
      value = newItems.reduce(
        (acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`,
        "",
      );
    } else {
      value = "";
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      "k_country",
      value,
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        k_country: value,
        page: 1,
      },
    });
  };

  return (
    <div className={`${styles.itemWrapper} ${disabled ? styles.disabled : ''}`} id="location">
      <div className={styles.itemTitle} onClick={handleCollapseClick}>
        <span>Location</span>
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(styles.icon, !collapsed && styles.collapse)}
          onClick={handleCollapseClick}
        />
      </div>
      {collapsed ? (
        <div
          className={classNames("d-flex flex-column", styles.withPaddingLeft)}
        >
          <div
            className={classNames("d-flex flex-column", styles.locationField)}
          >
            <LocationSelectSearch
              selectedLocation={selectedLocations}
              onChange={handleOnChange}
              disabled={idbCountry?.length}
            />
          </div>
          {selectedCouncil?.name === "IDB" && cleanLocation ? (
            <div className={styles.kyndrylCountrySelect}>
              <FilterRowComponent
                item={IDB_COUNTRIES}
                items={IDB_COUNTRIES.items}
                type="k_country"
                level={1}
                expan={idbCountry?.length}
                handleExpand={() => {}}
                history={history}
                kyndrylCountry={idbCountry}
                noPadding
                handleCheck={handleAllCountriesCheck}
                isChecked={
                  selectedIDBCountries?.length === IDB_COUNTRIES.items.length
                }
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(LocationFilter);

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useModelPopup } from "../../../../../common/hooks";
import { Button, BtnType, Loading } from "../../../../primitives";
import styles from "./styles.module.scss";
import NewThemeMemberComponent from "../../themesOverviewTab/membersBlock/addMemberModal";
import MemberItem from "./memberItem";

const EditMembers = () => {
  const popup = useModelPopup();
  const [members, setMembers] = useState([]);
  const {
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { currentThemeData },
      themesOverviewReducer: { loadingMember, memberLists },
    },
  } = useSelector((state) => state);

  const handleAddMemberClick = () => {
    popup.show({
      title: `Add a Member to this ${
        selectedCouncil?.settings?.themes_display_name_singular || "Theme"
      }`,
      component: <NewThemeMemberComponent itemList={members} />,
    });
  };

  useEffect(() => {
    if (memberLists?.length) {
      setMembers(memberLists);
    } else {
      setMembers(currentThemeData.members);
    }
  }, [memberLists]);

  if (loadingMember) {
    return <Loading />;
  }
  return (
    <section className={styles.memberContainer}>
      <div className={styles.membersWrp}>
        <div className={styles.addMemberButtonContainer}>
          <Button
            icon="icn-add"
            onClick={() => handleAddMemberClick({})}
            btn={BtnType.FRAME_LESS}
            className={styles.addCompanyButton}
          >
            Add A Member
          </Button>
        </div>
        <div className={styles.formContainer}>
          <div className="row">
            <div className="col-12">
              <div className={styles.labelesContainer}>
                <div className={styles.label}>
                  {selectedCouncil?.settings?.themes_display_name_singular ||
                    "Theme"}{" "}
                  Followers
                </div>
                <div className={styles.statusRoleBlock}>
                  <div className={styles.label}>Status</div>
                  <div className={styles.label}>Role</div>
                </div>
              </div>
              {members?.length > 0 ? (
                <div className={styles.membersContainer}>
                  {members.map((member) => (
                    <MemberItem
                      member={member}
                      currentThemeDataId={currentThemeData.id}
                      selectedCouncil={selectedCouncil}
                    />
                  ))}
                </div>
              ) : (
                <div className={styles.noMembers}>No members found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(EditMembers);

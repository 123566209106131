import { addMonths, endOfQuarter, format, startOfQuarter } from "date-fns";

const makeDateProps = (filters) => {
  if (filters?.dateType === "byYear" && filters?.year) {
    return {
      by_year: filters?.year,
      all_time: false,
    };
  }

  if (
    filters?.dateType === "byQuarter" &&
    filters?.year &&
    filters?.quarter?.index
  ) {
    return {
      by_year: filters?.year,
      by_quarter: filters.quarter.index,
      all_time: false,
    };
  }

  if (filters?.dateType === "byMonth" && filters?.year && filters?.month) {
    return {
      by_year: filters?.year,
      by_month: filters?.month,
      all_time: false,
    };
  }

  if (filters?.dateType === "custom" && filters?.dateRange?.length) {
    return {
      start_date: filters.dateRange[0],
      end_date: filters.dateRange[1],
      all_time: false,
    };
  }

  return {
    all_time: true,
  };
};

const makeProjectFields = (filters) => {
  if (Object.keys(filters.projectFieldValues || {}).length === 0) return [];

  return Object.keys(filters.projectFieldValues)
    .filter((key) => filters.projectFieldValues[key])
    .map((key) => {
      const getElem = filters.projectFieldValues[key];
      const elementHasParent = getElem?.some((elem) => elem.parent);

      if (elementHasParent) {
        return {
          option_selected:
            filters.projectFieldValues[key]?.map((f) => f.id) || [],
        };
      }

      return {
        project_field_template_id: key,
        option_selected:
          filters.projectFieldValues[key]?.map((f) => f.id) || [],
      };
    });
};

const makeProjectMetrics = (filters) => {
  if (Object.keys(filters.projectMetrics || {}).length === 0) return null;

  return Object.keys(filters.projectMetrics).reduce((acc, key) => {
    if (
      (key === "job1_date" ||
        (key === "expected_job1_date" && filters.projectMetrics[key])) &&
      filters.projectMetrics[key]?.length
    ) {
      const useDateRangePicker = filters.projectMetrics[key] || [];
      const payload = {
        min: useDateRangePicker.length ? useDateRangePicker[0] : null,
        max: useDateRangePicker.length ? useDateRangePicker[1] : null,
      };

      return {
        ...acc,
        [key]: payload,
      };
    }

    if (
      (key === "savings_usd" ||
        key === "investment_usd" ||
        key === "tarr_percentage") &&
      filters.projectMetrics[key]?.length
    ) {
      return {
        ...acc,
        [key]: {
          min: filters.projectMetrics[key][0].min,
          max: filters.projectMetrics[key][0].max,
        },
      };
    }

    if (
      key === "next_stage_start_on" &&
      filters.projectMetrics["next_stage_start_on"]
    ) {
      return {
        ...acc,
        next_stage_start_on: filters.projectMetrics["next_stage_start_on"].map(
          (q) => ({
            quarter: q.index,
            year: q.year,
          })
        ),
      };
    }

    return acc;
  }, {});
};

export const makeActualScoreCardFilterData = (filters) => {
  return {
    order_attribute: "name",
    order_direction: "asc",
    search: {
      project_metrics: makeProjectMetrics(filters),
      project_fields: makeProjectFields(filters),
      date_range: makeDateProps(filters),
      theme_ids: filters?.pillars?.map((t) => t.id) || [],
      stage_template_ids: filters?.stage_template_ids?.map((s) => s.id) || [],
      stage_ids:
        filters?.stages?.reduce(
          (acc, stage) => [...acc, ...(stage?.stage_ids || [stage.id])],
          []
        ) || [],
      plants: {
        plant_ids: filters?.plants?.map((p) => p.id) || [],
        region_ids: filters?.regions?.map((r) => r.id) || [],
      },
    },
  };
};

export const makeFordDashboardQuarters = (startYear) => {
  const quarters = [];

  let start = startOfQuarter(addMonths(startYear, 1));

  for (let i = 0; i < 4; i++) {
    const end = endOfQuarter(addMonths(start, 2));
    const quarterLabel = `Q${
      Math.floor(start.getMonth() / 3) + 1
    } ${start.getFullYear()}`;
    const dateString = `${quarterLabel}`;

    quarters.push({
      id: dateString,
      name: dateString,
      year: start.getFullYear(),
      date: format(end, "yyyy-MM-dd"),
      index: i + 1,
    });
    start = startOfQuarter(addMonths(start, 3));
  }

  return quarters;
};

const thisYear = new Date().getFullYear() + 2;
const stagesArray = new Array(thisYear)
  .fill(thisYear)
  .map((year, index) => year - index)
  .filter((year) => year >= 2020)
  .sort((a, b) => b - a)
  .reduce(
    (acc, year) => [
      ...acc,
      ...makeFordDashboardQuarters(new Date(String(year))).sort(
        (a, b) => b.index - a.index
      ),
    ],
    []
  );

export const projectMetricFilters = [
  {
    name: "Job 1 Date",
    id: "job1_date",
    datePicker: true,
    dateRangePicker: true,
  },
  {
    name: "Expected Job 1 Date",
    id: "expected_job1_date",
    datePicker: true,
    dateRangePicker: true,
  },
  {
    name: "Next Stage Change Quarter",
    id: "next_stage_start_on",
    options: stagesArray,
  },
  {
    name: "Savings",
    id: "savings_usd",
    options: [
      {
        name: "$1-$50,000",
        id: "$0-$50,000",
        min: 1,
        max: 50000,
      },
      {
        name: "$50,001-$200,000",
        id: "$50,001-$200,000",
        min: 50001,
        max: 200000,
      },
      {
        name: "$200,001-$500,000",
        id: "$200,001-$500,000",
        min: 200001,
        max: 500000,
      },
      {
        name: "$500,001-$1,000,000",
        id: "$500,001-$1,000,000",
        min: 500001,
        max: 1000000,
      },
      {
        name: "$1,000,001 +",
        id: "$1,000,001 +",
        min: 1000001,
        max: null,
      },
    ],
  },
  {
    name: "Investment",
    id: "investment_usd",
    options: [
      {
        name: "$1-$50,000",
        id: "$0-$50,000",
        min: 1,
        max: 50000,
      },
      {
        name: "$50,001-$200,000",
        id: "$50,001-$200,000",
        min: 50001,
        max: 200000,
      },
      {
        name: "$200,001-$500,000",
        id: "$200,001-$500,000",
        min: 200001,
        max: 500000,
      },
      {
        name: "$500,001-$1,000,000",
        id: "$500,001-$1,000,000",
        min: 500001,
        max: 1000000,
      },
      {
        name: "$1,000,001 +",
        id: "$1,000,001 +",
        min: 1000001,
        max: null,
      },
    ],
  },
  {
    name: "TARR %",
    id: "tarr_percentage",
    options: [
      {
        name: "0%-30%",
        id: "0%-30%",
        min: 0,
        max: 30,
      },
      {
        name: "31%-60%",
        id: "31%-60%",
        min: 31,
        max: 60,
      },
      {
        name: "61%-100%",
        id: "61%-100%",
        min: 61,
        max: 100,
      },
      {
        name: ">100%",
        id: ">100%",
        min: 100,
        max: null,
      },
    ],
  },
];

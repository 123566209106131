import React, { useCallback, useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import { Button, BtnType } from "../../../primitives";
import { ModalFooter, ModalBody } from "../../../../application/modal";
import { useModelPopup } from "../../../../common/hooks";
import { COMPANY_DOWNLOAD, PATIENT_ORG_DOWNLOAD, patient_org_tabs, tabs } from "./constants";
import LoadingComponent from "modules/primitives/loading/loading.component";
import {
  companyGetActivities,
  companyGetProjectTasks,
  getCompanyDetailsFieldsSuccess,
  getCompanyProducts,
  getCustomGroupFields,
  setDetailsCustomGroups,
} from "modules/companies/company/company.action";
import { getDocuments, peopleListTeamsGet } from "../../../../common/actions/common.action";
import { companyDiscussionsGet, patientOrgDiscussionsGet } from "../../../../common/actions/discussions.action";
import {
  getPatientOrganizationGroupFields,
  patientOrganizationDetailsGet,
} from "modules/patientOrganizations/patientOrganization/patientOrganization.action";

const cb = () => true;

const CompanyDownload = ({ handleDownload, patientOrgInstance, selectedCompany }) => {
  const [model, setModel] = useState({tabs: [], isAllSelected: true});
  const [inProgressSections, setInProgressSections] = useState([]);

  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    companiesReducer: {
      companyReducer: { newCustomFieldGroups },
    },
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);

  const checkInProgressSections = (sections) => {
    if (sections.length === 0) {
      handleDownload(model.tabs);
    }
  }

  useEffect(() => {
    if (newCustomFieldGroups?.length && !patientOrgInstance) {
      makeCustomGroups();
    }
  }, [newCustomFieldGroups]);

  const makeCustomGroups = () => {
    if (inProgressSections.includes('Details')) {
      newCustomFieldGroups.forEach(g => {
        dispatch(
          getCustomGroupFields({
            groupId: g.groupDetails.id,
            isTableGroup: g.viewType === "table",
            companyId: selectedCompany.id,
          })
        );
      });
    }
  }

  useEffect(() => {
    if (session && selectedCouncil && !newCustomFieldGroups?.length && !patientOrgInstance) {
      const customFieldGroupsData = selectedCouncil.custom_field_groups.map((group) => ({
        loading: true,
        groupDetails: group,
        customFields: null,
        viewType: group.view_type,
      }));

      dispatch(setDetailsCustomGroups(customFieldGroupsData));
    }
  }, [session, selectedCouncil]);

  useEffect(() => {
    if (patientOrgInstance) {
      setModel(PATIENT_ORG_DOWNLOAD);
      return
    }

    setModel(COMPANY_DOWNLOAD);
  }, [patientOrgInstance]);

  const getDetailsData = (cb) => {
    if (patientOrgInstance) {
      dispatch(
        getPatientOrganizationGroupFields({
          id: selectedCouncil.id,
          enqueueSnackbar,
        }),
      );
      dispatch(
        patientOrganizationDetailsGet({
          patientOrganizationId: selectedCompany.id,
          council_id: selectedCouncil.id,
          items: 50,
          page: 1,
          enqueueSnackbar,
          cb,
        }),
      );

      return;
    }

    if (newCustomFieldGroups.length) {
      const allGroupsAreLoaded = newCustomFieldGroups.filter((g) => g.loading).length === 0;

      if (allGroupsAreLoaded) {
        const allCustomFields = newCustomFieldGroups.reduce(
          (acc, elem) => [...acc, ...(elem?.customFields || [])],
          []
        );
        dispatch(
          getCompanyDetailsFieldsSuccess({
            response: allCustomFields,
          })
        );
      }

      const timeout = setTimeout(() => {
        cb();
        clearTimeout(timeout);
      }, 3000);
    }
  }

  const getNotesData = (cb) => {
    if (selectedCouncil && selectedCompany?.id && session) {
      dispatch(
        peopleListTeamsGet({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
      dispatch(
        companyGetActivities({
          company_id: selectedCompany.id,
          gsk_patient_organization_id: selectedCompany.id,
          gsk: selectedCouncil.traction_tag === "gsk",
          patient_org: patientOrgInstance,
          userId: session.id,
          items: 50,
          page: 1,
          enqueueSnackbar,
          cb,
        })
      );
    }
  }

  const getProductsData = (cb) => {
    dispatch(
      getCompanyProducts({
        company_id: selectedCompany.id,
        page: 1,
        enqueueSnackbar,
        cb,
      }),
    );
  }

  const getProjectsData = useCallback((cb) => {
    dispatch(
      companyGetProjectTasks({
        company_id: selectedCompany.id,
        enqueueSnackbar,
        isPatientOrganization: patientOrgInstance,
        cb,
      }),
    );
  }, [selectedCompany, patientOrgInstance, enqueueSnackbar])

  const getDocsData = useCallback((cb) => {
    if (patientOrgInstance) {
      dispatch(
        getDocuments({
          document_resource_id: selectedCompany.id,
          document_resource_type: "Gsk::PatientOrganization",
          patientOrg: true,
          enqueueSnackbar,
          cb,
        })
      );

      return;
    }

    dispatch(
      getDocuments({
        document_resource_id: selectedCompany.id,
        document_resource_type: "Company",
        patientOrg: false,
        enqueueSnackbar,
        customUrl: `documents?company_id=${selectedCompany.id}&items=10&page=1`,
        cb,
      })
    );
  }, [selectedCompany])

  const getDiscussionsData = useCallback((cb) => {
    if (patientOrgInstance) {
      dispatch(
        patientOrgDiscussionsGet({
          patientOrgId: selectedCompany.id,
          enqueueSnackbar,
          cb,
        }),
      );

      return;
    }

    dispatch(
      companyDiscussionsGet({
        councilId: selectedCouncil.id,
        companyId: selectedCompany.id,
        enqueueSnackbar,
        cb,
      }),
    );
  }, [selectedCompany, selectedCouncil])

  const getPromise = (time, func, section) => {
    return new Promise((resolve) => {
      const timeout = setTimeout(() => {
        func(() => {
          clearTimeout(timeout);
          resolve(section);
        });
      }, time);
    })
  }

  const handleCancelClick = () => popup.hide();

  async function executePromises(arrayOfPromises, inProgressSections) {
    let newInProgressSections = inProgressSections;
    for (const element of arrayOfPromises) {
      const resolvedSection = await element;
      newInProgressSections = newInProgressSections.filter(s => s !== resolvedSection);
      setInProgressSections(newInProgressSections);
      checkInProgressSections(newInProgressSections);
    }
  }

  const handleCloseDownload = async () => {
    if (model.tabs.length === 1 && model.tabs[0] === 'Overview') {
      handleDownload(model.tabs);
      return;
    }

    if (model.tabs.length === 0) {
      handleCancelClick();
      enqueueSnackbar('Please select at least one section', {
        variant: 'error',
      });
      return;
    }

    const newInProgressSections = model.tabs.filter(t => t !== 'Overview');
    setInProgressSections(newInProgressSections);

    const callbacksBasedOnSections = patientOrgInstance ? {
      'Details': getDetailsData,
      'Engagement': getNotesData,
      'Projects': getProjectsData,
      'Documents': getDocsData,
    } : {
      'Details': getDetailsData,
      'Notes': getNotesData,
      'Products': getProductsData,
      'Projects': getProjectsData,
      'Documents': getDocsData,
      'Discussions': getDiscussionsData,
    }

    const arrayOfPromises = model.tabs
      .filter(t => t !== 'Overview')
      .map(t => getPromise(2000, callbacksBasedOnSections[t], t));

    await executePromises(arrayOfPromises, newInProgressSections);
  };

  const handleOnChange = (evt) => {
    if (evt.target.name === "isAllSelected") {
      // eslint-disable-next-line no-use-before-define
      handleSelectAll();
      return;
    }

    const { value } = evt.target;
    const valueExists = model.tabs.some((id) => id === value);

    if (valueExists) {
      setModel({
        ...model,
        tabs: model.tabs.filter((id) => id !== value),
        isAllSelected: false,
      });
    } else {
      const tabsArr = [...model.tabs, value];
      const isAllSelected = tabsArr.length === tabs.length;

      setModel({
        ...model,
        tabs: tabsArr,
        isAllSelected,
      });
    }
  };

  const handleSelectAll = () => {
    if (model.isAllSelected) {
      setModel({
        ...model,
        tabs: [],
        isAllSelected: false,
      });
    } else {
      setModel({
        ...model,
        tabs,
        isAllSelected: true,
      });
    }
  };

  return (
    <div>
      <ModalBody>
        <div>
          <Formik enableReinitialize initialValues={model}>
            {() => (
              <form onChange={handleOnChange}>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="d-flex align-items-center">
                        <Field type="checkbox" name="isAllSelected" />
                        <span className="ml-2">
                          {model.isAllSelected
                            ? "Deselect All Tabs"
                            : "Select All Tabs"}
                        </span>
                      </label>
                    </div>
                    <div role="group" className="d-flex flex-column">
                      {
                        patientOrgInstance ? (
                          patient_org_tabs.map((t) => (
                            // eslint-disable-next-line jsx-a11y/label-has-associated-control
                            <label key={t} className="d-flex align-items-center">
                              <Field type="checkbox" name="tabs" value={t} />
                              <span className="ml-2">{t}</span>
                              {
                                inProgressSections.includes(t) && <LoadingComponent customText={`Preparing data for ${t}...`} />
                              }
                            </label>
                          ))
                        ) : (
                          tabs.map((t) => (
                            // eslint-disable-next-line jsx-a11y/label-has-associated-control
                            <label key={t} className="d-flex align-items-center">
                              <Field type="checkbox" name="tabs" value={t} />
                              <span className="ml-2">{t}</span>
                              {
                                inProgressSections.includes(t) && <LoadingComponent customText={`Preparing data for ${t}...`} />
                              }
                            </label>
                          ))
                        )
                      }
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center">
          {
            inProgressSections.length ? (
              <LoadingComponent customText="Preparing data for PDF..." />
            ) : (
              <>
                <Button
                  className="mr-3"
                  btn={BtnType.FRAME_LESS}
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  btn={BtnType.REGULAR}
                  onClick={handleCloseDownload}
                >
                  Download
                </Button>
              </>
            )
          }
        </div>
      </ModalFooter>
    </div>
  );
};

export default CompanyDownload;

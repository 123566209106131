export const DELETE_CUSTOM_FIELD_TABLE_ROW = "DELETE_CUSTOM_FIELD_TABLE_ROW";
export const deleteCustomFieldTableRow = (payload) => ({
  type: DELETE_CUSTOM_FIELD_TABLE_ROW,
  payload,
});

export const DELETE_CUSTOM_FIELD_TABLE_ROW_SUCCESS =
  "DELETE_CUSTOM_FIELD_TABLE_ROW_SUCCESS";
export const deleteCustomFieldTableRowSuccess = (payload) => ({
  type: DELETE_CUSTOM_FIELD_TABLE_ROW_SUCCESS,
  payload,
});

export const EDIT_CUSTOM_FIELD_TABLE_ROW_CELLS_SUCCESS =
  "EDIT_CUSTOM_FIELD_TABLE_ROW_CELLS_SUCCESS";
export const editCustomFieldTableRowCellsSuccess = (payload) => ({
  type: EDIT_CUSTOM_FIELD_TABLE_ROW_CELLS_SUCCESS,
  payload,
});

export const SAVE_CUSTOM_FIELD_TABLE_ROW = "SAVE_CUSTOM_FIELD_TABLE_ROW";
export const saveCustomFieldTableRow = (payload) => ({
  type: SAVE_CUSTOM_FIELD_TABLE_ROW,
  payload,
});

export const SAVE_CUSTOM_FIELD_TABLE_ROW_SUCCESS =
  "SAVE_CUSTOM_FIELD_TABLE_ROW_SUCCESS";
export const saveCustomFieldTableRowSuccess = (payload) => ({
  type: SAVE_CUSTOM_FIELD_TABLE_ROW_SUCCESS,
  payload,
});

export const SET_DETAILS_CUSTOM_GROUPS = "SET_DETAILS_CUSTOM_GROUPS";
export const setDetailsCustomGroups = (payload) => ({
  type: SET_DETAILS_CUSTOM_GROUPS,
  payload,
});

export const GET_CUSTOM_GROUP_FIELDS = "GET_CUSTOM_GROUP_FIELDS";
export const getCustomGroupFields = (payload) => ({
  type: GET_CUSTOM_GROUP_FIELDS,
  payload,
});

export const GET_CUSTOM_GROUP_FIELDS_SUCCESS =
  "GET_CUSTOM_GROUP_FIELDS_SUCCESS";
export const getCustomGroupFieldsSuccess = (payload) => ({
  type: GET_CUSTOM_GROUP_FIELDS_SUCCESS,
  payload,
});

export const COMPANY_SET_DOWNLOAD_PARAMS = "COMPANY_SET_DOWNLOAD_PARAMS";
export const companySetDownloadParams = (payload) => ({
  type: COMPANY_SET_DOWNLOAD_PARAMS,
  payload,
});

export const COMPANY_PRODUCTS_GET_ATTEMPT = "COMPANY_PRODUCTS_GET_ATTEMPT";
export const getCompanyProducts = (payload) => ({
  type: COMPANY_PRODUCTS_GET_ATTEMPT,
  payload,
});
export const COMPANY_PRODUCTS_GET_ATTEMPT_SUCCESS =
  "COMPANY_PRODUCTS_GET_ATTEMPT_SUCCESS";
export const getCompanyProductsSuccess = (payload) => ({
  type: COMPANY_PRODUCTS_GET_ATTEMPT_SUCCESS,
  payload,
});

export const COMPANY_PRODUCT_ADD_ATTEMPT_SUCCESS =
  "COMPANY_PRODUCT_ADD_ATTEMPT_SUCCESS";
export const getCompanyProductAddSuccess = (payload) => ({
  type: COMPANY_PRODUCT_ADD_ATTEMPT_SUCCESS,
  payload,
});

export const COMPANY_PRODUCT_DELETE = "COMPANY_PRODUCT_DELETE";
export const getCompanyProductDelete = (payload) => ({
  type: COMPANY_PRODUCT_DELETE,
  payload,
});

export const COMPANY_PRODUCT_DELETE_SUCCESS = "COMPANY_PRODUCT_DELETE_SUCCESS";
export const getCompanyProductDeleteSuccess = (payload) => ({
  type: COMPANY_PRODUCT_DELETE_SUCCESS,
  payload,
});

export const COMPANY_GET_ATTEMPT = "COMPANY_GET_ATTEMPT";
export const companyGet = (payload) => ({ type: COMPANY_GET_ATTEMPT, payload });

export const COMPANY_GET_SUCCESS = "COMPANY_GET_SUCCESS";
export const companyGetSuccess = (payload) => ({
  type: COMPANY_GET_SUCCESS,
  payload,
});

export const COMPANY_SET = "COMPANY_SET";
export const companySet = (payload) => ({ type: COMPANY_SET, payload });

export const COMPANY_ADD_ACTIVITY = "COMPANY_ADD_ACTIVITY";
export const companyAddActivity = (payload) => ({
  type: COMPANY_ADD_ACTIVITY,
  payload,
});

export const COMPANY_GET_ACTIVITIES_ATTEMPT = "COMPANY_GET_ACTIVITIES_ATTEMPT";
export const companyGetActivities = (payload) => ({
  type: COMPANY_GET_ACTIVITIES_ATTEMPT,
  payload,
});
export const COMPANY_GET_ACTIVITIES_SUCCESS = "COMPANY_GET_ACTIVITIES_SUCCESS";
export const companyGetActivitiesSuccess = (payload) => ({
  type: COMPANY_GET_ACTIVITIES_SUCCESS,
  payload,
});

export const COMPANY_ADD_ACTIVITY_SUCCESS = "COMPANY_ADD_ACTIVITY_SUCCESS";
export const companyAddActivitySuccess = (payload) => ({
  type: COMPANY_ADD_ACTIVITY_SUCCESS,
  payload,
});

export const GET_COMPANY_GROUP_FIELDS = "GET_COMPANY_GROUP_FIELDS";
export const getCompanyGroupFields = (payload) => ({
  type: GET_COMPANY_GROUP_FIELDS,
  payload,
});
export const GET_COMPANY_GROUP_FIELDS_SUCCESS =
  "GET_COMPANY_GROUP_FIELDS_SUCCESS";
export const getCompanyGroupFieldsSuccess = (payload) => ({
  type: GET_COMPANY_GROUP_FIELDS_SUCCESS,
  payload,
});

export const GET_COMPANY_DETAILS_FIELDS = "GET_COMPANY_DETAILS_FIELDS";
export const getCompanyDetailsFields = (payload) => ({
  type: GET_COMPANY_DETAILS_FIELDS,
  payload,
});
export const GET_COMPANY_DETAILS_FIELDS_SUCCESS =
  "GET_COMPANY_DETAILS_FIELDS_SUCCESS";
export const getCompanyDetailsFieldsSuccess = (payload) => ({
  type: GET_COMPANY_DETAILS_FIELDS_SUCCESS,
  payload,
});

export const POST_CUSTOM_FIELDS = "POST_CUSTOM_FIELDS";
export const addCustomFields = (payload) => ({
  type: POST_CUSTOM_FIELDS,
  payload,
});

export const POST_CUSTOM_FIELDS_SUCCESS = "POST_CUSTOM_FIELDS_SUCCESS";
export const addCustomFieldsSuccess = (payload) => ({
  type: POST_CUSTOM_FIELDS_SUCCESS,
  payload,
});

export const COMPANY_PUT_DETAILS_FIELDS_ATTEMPT =
  "COMPANY_PUT_DETAILS_FIELDS_ATTEMPT";
export const companyPutDetailsFields = (payload) => ({
  type: COMPANY_PUT_DETAILS_FIELDS_ATTEMPT,
  payload,
});

export const COMPANY_PUT_DETAILS_FIELDS_SUCCESS =
  "COMPANY_PUT_DETAILS_FIELDS_SUCCESS";
export const companyPutDetailsFieldsSuccess = (payload) => ({
  type: COMPANY_PUT_DETAILS_FIELDS_SUCCESS,
  payload,
});

export const COMPANY_DELETE_DETAILS_FIELDS_ATTEMPT =
  "COMPANY_DELETE_DETAILS_FIELDS_ATTEMPT";
export const companyDeleteDetailsFields = (payload) => ({
  type: COMPANY_DELETE_DETAILS_FIELDS_ATTEMPT,
  payload,
});

export const COMPANY_DELETE_DETAILS_FIELDS_SUCCESS =
  "COMPANY_DELETE_DETAILS_FIELDS_SUCCESS";
export const companyDeleteDetailsFieldsSuccess = (payload) => ({
  type: COMPANY_DELETE_DETAILS_FIELDS_SUCCESS,
  payload,
});

export const COMPANY_DELETE_DETAILS_TEMPLATE_FIELDS_ATTEMPT =
  "COMPANY_DELETE_DETAILS_TEMPLATE_FIELDS_ATTEMPT";
export const companyDeleteDetailsTemplateFields = (payload) => ({
  type: COMPANY_DELETE_DETAILS_TEMPLATE_FIELDS_ATTEMPT,
  payload,
});

export const COMPANY_DELETE_DETAILS_TEMPLATE_FIELDS_SUCCESS =
  "COMPANY_DELETE_DETAILS_TEMPLATE_FIELDS_SUCCESS";
export const companyDeleteDetailsTemplateFieldsSuccess = (payload) => ({
  type: COMPANY_DELETE_DETAILS_TEMPLATE_FIELDS_SUCCESS,
  payload,
});

export const COMPANY_DELETE_ACTIVITY_ATTEMPT =
  "COMPANY_DELETE_ACTIVITY_ATTEMPT";
export const companyDeleteActivity = (payload) => ({
  type: COMPANY_DELETE_ACTIVITY_ATTEMPT,
  payload,
});

export const COMPANY_DELETE_ACTIVITY_SUCCESS =
  "COMPANY_DELETE_ACTIVITY_SUCCESS";
export const companyDeleteActivitySuccess = (payload) => ({
  type: COMPANY_DELETE_ACTIVITY_SUCCESS,
  payload,
});

export const COMPANY_PUT_ACTIVITY_ATTEMPT = "COMPANY_PUT_ACTIVITY_ATTEMPT";
export const companyPutActivity = (payload) => ({
  type: COMPANY_PUT_ACTIVITY_ATTEMPT,
  payload,
});

export const COMPANY_PUT_ACTIVITY_SUCCESS = "COMPANY_PUT_ACTIVITY_SUCCESS";
export const companyPutActivitySuccess = (payload) => ({
  type: COMPANY_PUT_ACTIVITY_SUCCESS,
  payload,
});

export const COMPANY_RATING_SAVE_ATTEMPT = "COMPANY_RATING_SAVE_ATTEMPT";
export const companyRatingSaveAttempt = (payload) => ({
  type: COMPANY_RATING_SAVE_ATTEMPT,
  payload,
});
export const COMPANY_RATING_SAVE_SUCCESS = "COMPANY_RATING_SAVE_SUCCESS";
export const companyRatingSaveSuccess = (payload) => ({
  type: COMPANY_RATING_SAVE_SUCCESS,
  payload,
});

export const COMPANY_RATING_EDIT_ATTEMPT = "COMPANY_RATING_EDIT_ATTEMPT";
export const companyRatingEditAttempt = (payload) => ({
  type: COMPANY_RATING_EDIT_ATTEMPT,
  payload,
});
export const COMPANY_RATING_EDIT_SUCCESS = "COMPANY_RATING_EDIT_SUCCESS";
export const companyRatingEditSuccess = (payload) => ({
  type: COMPANY_RATING_EDIT_SUCCESS,
  payload,
});

export const COMPANY_ADD_RELATIONSHIP_ATTEMPT =
  "COMPANY_ADD_RELATIONSHIP_ATTEMPT";
export const addRelationShip = (payload) => ({
  type: COMPANY_ADD_RELATIONSHIP_ATTEMPT,
  payload,
});

export const COMPANY_ADD_RELATIONSHIP_SUCCESS =
  "COMPANY_ADD_RELATIONSHIP_SUCCESS";
export const addRelationShipSuccess = (payload) => ({
  type: COMPANY_ADD_RELATIONSHIP_SUCCESS,
  payload,
});

export const COMPANY_DELETE_RELATIONSHIP_ATTEMPT =
  "COMPANY_DELETE_RELATIONSHIP_ATTEMPT";
export const deleteRelationShip = (payload) => ({
  type: COMPANY_DELETE_RELATIONSHIP_ATTEMPT,
  payload,
});

export const COMPANY_DELETE_RELATIONSHIP_SUCCESS =
  "COMPANY_DELETE_RELATIONSHIP_SUCCESS";
export const deleteRelationSuccess = (payload) => ({
  type: COMPANY_DELETE_RELATIONSHIP_SUCCESS,
  payload,
});

export const COMPANY_DELETE_CONTACT_ATTEMPT = "COMPANY_DELETE_CONTACT_ATTEMPT";
export const deleteContact = (payload) => ({
  type: COMPANY_DELETE_CONTACT_ATTEMPT,
  payload,
});

export const COMPANY_DELETE_CONTACT_SUCCESS = "COMPANY_DELETE_CONTACT_SUCCESS";
export const deleteContactSuccess = (payload) => ({
  type: COMPANY_DELETE_CONTACT_SUCCESS,
  payload,
});

export const COMPANY_EDIT_DETAILS_ATTEMPT = "COMPANY_EDIT_DETAILS_ATTEMPT";
export const editDetails = (payload) => ({
  type: COMPANY_EDIT_DETAILS_ATTEMPT,
  payload,
});

export const COMPANY_EDIT_DETAILS_SUCCESS = "COMPANY_EDIT_DETAILS_SUCCESS";
export const editDetailsSuccess = (payload) => ({
  type: COMPANY_EDIT_DETAILS_SUCCESS,
  payload,
});

export const COMPANY_DELETE_ATTEMPT = "COMPANY_DELETE_ATTEMPT";
export const deleteCompany = (payload) => ({
  type: COMPANY_DELETE_ATTEMPT,
  payload,
});

export const COMPANY_DELETE_SUCCESS = "COMPANY_DELETE_SUCCESS";
export const deleteCompanySuccess = (payload) => ({
  type: COMPANY_DELETE_SUCCESS,
  payload,
});

export const COMPANY_SHARE_ATTEMPT = "COMPANY_SHARE_ATTEMPT";
export const shareCompany = (payload) => ({
  type: COMPANY_SHARE_ATTEMPT,
  payload,
});

export const COMPANY_SHARE_SUCCESS = "COMPANY_SHARE_SUCCESS";
export const shareCompanySuccess = (payload) => ({
  type: COMPANY_SHARE_SUCCESS,
  payload,
});

export const COMPANY_REQUEST_RESEARCH_ATTEMPT =
  "COMPANY_REQUEST_RESEARCH_ATTEMPT";
export const companyRequestResearch = (payload) => ({
  type: COMPANY_REQUEST_RESEARCH_ATTEMPT,
  payload,
});

export const COMPANY_HOME_REQUEST_RESEARCH_ATTEMPT =
  "COMPANY_HOME_REQUEST_RESEARCH_ATTEMPT";
export const companyHomeRequestResearch = (payload) => ({
  type: COMPANY_HOME_REQUEST_RESEARCH_ATTEMPT,
  payload,
});

export const COMPANY_REQUEST_RESEARCH_SUCCESS =
  "COMPANY_REQUEST_RESEARCH_SUCCESS";
export const companyRequestResearchSuccess = (payload) => ({
  type: COMPANY_REQUEST_RESEARCH_SUCCESS,
  payload,
});

export const COMPANY_EDIT_RELATIONSHIP_ATTEMPT =
  "COMPANY_EDIT_RELATIONSHIP_ATTEMPT";
export const companyEditRelationship = (payload) => ({
  type: COMPANY_EDIT_RELATIONSHIP_ATTEMPT,
  payload,
});

export const COMPANY_EDIT_RELATIONSHIP_SUCCESS =
  "COMPANY_EDIT_RELATIONSHIP_SUCCESS";
export const companyEditRelationshipSuccess = (payload) => ({
  type: COMPANY_EDIT_RELATIONSHIP_SUCCESS,
  payload,
});

export const COMPANY_GET_PROJECT_TASKS_ATTEMPT =
  "COMPANY_GET_PROJECT_TASKS_ATTEMPT";
export const companyGetProjectTasks = (payload) => ({
  type: COMPANY_GET_PROJECT_TASKS_ATTEMPT,
  payload,
});

export const COMPANY_GET_PROJECT_TASKS_SUCCESS =
  "COMPANY_GET_PROJECT_TASKS_SUCCESS";
export const companyGetProjectTasksSuccess = (payload) => ({
  type: COMPANY_GET_PROJECT_TASKS_SUCCESS,
  payload,
});

export const COMPANY_CLEAR_REMOVED_TAG = "COMPANY_CLEAR_REMOVED_TAG";
export const companyClearRemovedTeag = (payload) => ({
  type: COMPANY_CLEAR_REMOVED_TAG,
  payload,
});

export const SELECTED_COMPANY_CLEAR = "SELECTED_COMPANY_CLEAR";
export const selectedCompanyClear = (payload) => ({
  type: SELECTED_COMPANY_CLEAR,
  payload,
});

export const SET_CUSTOM_FIELD_LOADING = "SET_CUSTOM_FIELD_LOADING";
export const setCustomFieldLoading = (payload) => ({
  type: SET_CUSTOM_FIELD_LOADING,
  payload,
});

export const CLEAR_COMPANY_LOADING = "CLEAR_COMPANY_LOADING";
export const clearCompanyLoading = (payload) => ({
  type: CLEAR_COMPANY_LOADING,
  payload,
});

export const COMPANY_GET_CUSTOMERS_ATTEMPT = "COMPANY_GET_CUSTOMERS_ATTEMPT";
export const companyGetCustomers = (payload) => ({
  type: COMPANY_GET_CUSTOMERS_ATTEMPT,
  payload,
});

export const COMPANY_GET_CUSTOMERS_SUCCESS = "COMPANY_GET_CUSTOMERS_SUCCESS";
export const companyGetCustomersSuccess = (payload) => ({
  type: COMPANY_GET_CUSTOMERS_SUCCESS,
  payload,
});

export const COMPANY_DETAILS_UPDATE_CF = "COMPANY_DETAILS_UPDATE_CF";

export const companyUpdateDetails = (payload) => ({
  type: COMPANY_DETAILS_UPDATE_CF,
  payload,
});

export const CATCH_ERROR_SUCCESS = "CATCH_ERROR_SUCCESS";
export const catchErrorSuccess = (payload) => ({
  type: CATCH_ERROR_SUCCESS,
  payload,
});

export const COMPANY_ADD_RATING_NOTES_ATTEMPT =
  "COMPANY_ADD_RATING_NOTES_ATTEMPT";
export const companyAddRatingNotes = (payload) => ({
  type: COMPANY_ADD_RATING_NOTES_ATTEMPT,
  payload,
});

export const COMPANY_ADD_RATING_NOTES_SUCCESS =
  "COMPANY_ADD_RATING_NOTES_SUCCESS";
export const companyAddRatingNotesSuccess = (payload) => ({
  type: COMPANY_ADD_RATING_NOTES_SUCCESS,
  payload,
});

export const COMPANY_GET_RATING_NOTES_ATTEMPT =
  "COMPANY_GET_RATING_NOTES_ATTEMPT";
export const companyGetRatingNotes = (payload) => ({
  type: COMPANY_GET_RATING_NOTES_ATTEMPT,
  payload,
});

export const COMPANY_GET_RATING_NOTES_SUCCESS =
  "COMPANY_GET_RATING_NOTES_SUCCESS";
export const companyGetRatingNotesSuccess = (payload) => ({
  type: COMPANY_GET_RATING_NOTES_SUCCESS,
  payload,
});

export const COMPANY_DELETE_RATING_NOTES_ATTEMPT =
  "COMPANY_DELETE_RATING_NOTES_ATTEMPT";
export const companyDeleteRatingNotes = (payload) => ({
  type: COMPANY_DELETE_RATING_NOTES_ATTEMPT,
  payload,
});

export const COMPANY_DELETE_RATING_NOTES_SUCCESS =
  "COMPANY_DELETE_RATING_NOTES_SUCCESS";
export const companyDeleteRatingNotesSuccess = (payload) => ({
  type: COMPANY_DELETE_RATING_NOTES_SUCCESS,
  payload,
});

export const COMPANY_EDIT_RATING_NOTES_ATTEMPT =
  "COMPANY_EDIT_RATING_NOTES_ATTEMPT";
export const companyEditRatingNotes = (payload) => ({
  type: COMPANY_EDIT_RATING_NOTES_ATTEMPT,
  payload,
});

export const COMPANY_EDIT_RATING_NOTES_SUCCESS =
  "COMPANY_EDIT_RATING_NOTES_SUCCESS";
export const companyEditRatingNotesSuccess = (payload) => ({
  type: COMPANY_EDIT_RATING_NOTES_SUCCESS,
  payload,
});

export const COMPANY_AGREEMENT_SHOULD_OPEN_ATTEMPT =
  "COMPANY_AGREEMENT_SHOULD_OPEN_ATTEMPT";
export const companyAgreementShouldOpen = (payload) => ({
  type: COMPANY_AGREEMENT_SHOULD_OPEN_ATTEMPT,
  payload,
});

export const COMPANY_AGREEMENT_SHOULD_OPEN_SUCCESS =
  "COMPANY_AGREEMENT_SHOULD_OPEN_SUCCESS";
export const companyAgreementShouldOpenSuccess = (payload) => ({
  type: COMPANY_AGREEMENT_SHOULD_OPEN_SUCCESS,
  payload,
});

export const COMPANY_AGREEMENT_SHOULD_REDIRECT =
  "COMPANY_AGREEMENT_SHOULD_REDIRECT";
export const companyAgreementShouldRedirect = (payload) => ({
  type: COMPANY_AGREEMENT_SHOULD_REDIRECT,
  payload,
});

export const COMPANY_AGREEMENT_SHOULD_CLEAR = "COMPANY_AGREEMENT_SHOULD_CLEAR";
export const companyAgreementActionClear = (payload) => ({
  type: COMPANY_AGREEMENT_SHOULD_CLEAR,
  payload,
});

export const COMPANY_CLEAR_POPULATED_COMPETITOR_ATTEMPT =
  "COMPANY_CLEAR_POPULATED_COMPETITOR_ATTEMPT";
export const clearAddedCompetitor = (payload) => ({
  type: COMPANY_CLEAR_POPULATED_COMPETITOR_ATTEMPT,
  payload,
});

export const SHOULD_UPDATE_COMPANY_PROJECT_TASKS =
  "SHOULD_UPDATE_COMPANY_PROJECT_TASKS";
export const shouldUpdateCompanyProjectTasks = (payload) => ({
  type: SHOULD_UPDATE_COMPANY_PROJECT_TASKS,
  payload,
});

export const GET_COMPANY_CONTACTS_ATTEMPT = "GET_COMPANY_CONTACTS_ATTEMPT";
export const getCompanyContactsAttempt = (payload) => ({
  type: GET_COMPANY_CONTACTS_ATTEMPT,
  payload,
});

export const GET_COMPANY_CONTACTS_SUCCESS = "GET_COMPANY_CONTACTS_SUCCESS";
export const getCompanyContactsSuccess = (payload) => ({
  type: GET_COMPANY_CONTACTS_SUCCESS,
  payload,
});

export const COMPANY_CONTACT_INVITE = "COMPANY_CONTACT_INVITE";
export const companyContactInvite = (payload) => ({
  type: COMPANY_CONTACT_INVITE,
  payload,
});

export const COMPANY_CONTACT_INVITE_SUCCESS = "COMPANY_CONTACT_INVITE_SUCCESS";
export const companyContactInviteSuccess = (payload) => ({
  type: COMPANY_CONTACT_INVITE_SUCCESS,
  payload,
});

export const COMPANY_CUSTOM_FIELD_SCROLL_TO = "COMPANY_CUSTOM_FIELD_SCROLL_TO";
export const companyCustomFieldScrollTo = (payload) => ({
  type: COMPANY_CUSTOM_FIELD_SCROLL_TO,
  payload,
});
export const SAVE_PREV_URL = "SAVE_PREV_URL";
export const savePrevUrl = (payload) => ({
  type: SAVE_PREV_URL,
  payload,
});

export const GET_COMPANY_TAGS_ATTEMPT = "GET_COMPANY_TAGS_ATTEMPT";
export const getCompanyTagsAttempt = (payload) => ({
  type: GET_COMPANY_TAGS_ATTEMPT,
  payload,
});

export const GET_COMPANY_TAGS_SUCCESS = "GET_COMPANY_TAGS_SUCCESS";
export const getCompanyTagsSuccess = (payload) => ({
  type: GET_COMPANY_TAGS_SUCCESS,
  payload,
});

export const GET_COMPANY_ACTIVITIES_ATTEMPT = "GET_COMPANY_ACTIVITIES_ATTEMPT";
export const getCompanyActivitiesAttempt = (payload) => ({
  type: GET_COMPANY_ACTIVITIES_ATTEMPT,
  payload,
});

export const GET_COMPANY_ACTIVITIES_SUCCESS = "GET_COMPANY_ACTIVITIES_SUCCESS";
export const getCompanyActivitiesSuccess = (payload) => ({
  type: GET_COMPANY_ACTIVITIES_SUCCESS,
  payload,
});

export const GET_CUSTOM_FIELD_HISTORY_ATTEMPT =
  "GET_CUSTOM_FIELD_HISTORY_ATTEMPT";
export const getCustomFieldHistoryAttempt = (payload) => ({
  type: GET_CUSTOM_FIELD_HISTORY_ATTEMPT,
  payload,
});

export const GET_CUSTOM_FIELD_HISTORY_SUCCESS =
  "GET_CUSTOM_FIELD_HISTORY_SUCCESS";
export const getCustomFieldHistorySuccess = (payload) => ({
  type: GET_CUSTOM_FIELD_HISTORY_SUCCESS,
  payload,
});

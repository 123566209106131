import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from './styles.module.scss';
import LoadingComponent from "modules/primitives/loading/loading.component";
import {
  toggleKPIsTable,
  getKPIsTableData
} from "modules/reporting/fordReports/kpis/store/kpis.actions";
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import { tableHeadersByKey } from "../constants";
import FordKpisTable from "modules/reporting/fordReports/kpis/table/fordkpisTable";
import { httpPost } from "../../../../../common/httpCall";

const FordKpisTableWrapper = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();

  const {
    fordDashboardReducer: { kpisReducer }
  } = useSelector((state) => state);

  const getExpandedTableKey = useMemo(() => {
    return kpisReducer.tableData.expandedKey;
  }, [kpisReducer.tableData]);

  const getActiveExpandedTable = useMemo(() => {
    return kpisReducer.tableData[getExpandedTableKey];
  }, [getExpandedTableKey, kpisReducer.tableData]);

  const hideSourceData = () => {
    dispatch(toggleKPIsTable({
      reducerKey: kpisReducer.tableData.expandedKey,
    }));
  }

  const handlePageChange = (page) => {
    const filtersData = makeActualScoreCardFilterData(kpisReducer.filters);
    dispatch(getKPIsTableData({
      reducerKey: kpisReducer.tableData.expandedKey,
      highLightColumn: kpisReducer.tableData.highLightColumn,
      data: {
        ...filtersData,
        section: kpisReducer.tableData.expandedKey,
      },
      page,
    }));
  };

  useEffect(() => {
    if (tableRef.current && !getActiveExpandedTable?.fetching) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [tableRef, getActiveExpandedTable?.fetching]);

  const handleDownload = (payload) => {
    const filtersData = makeActualScoreCardFilterData(kpisReducer.filters);

    const promiseReq = httpPost({
      customServerPath: true,
      call: 'ford/dashboards/exports',
      data: {
        ...filtersData,
        ...payload,
      },
      respType: "blob",
    }).subscribe(res => {
      if (payload.cb) {
        payload.cb();
      }

      const url = window.URL.createObjectURL(new Blob([res.response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `KPIS-${payload.section}.${payload.type}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      promiseReq.unsubscribe();
    })
  }

  if (!getExpandedTableKey) {
    return null;
  }

  return (
    <div className="mt-5">
      <div className="d-flex align-items-center justify-content-center mb-3">
        <h5 className="font-weight-bold">{tableHeadersByKey[getExpandedTableKey]}</h5>
      </div>
      {
        getActiveExpandedTable.fetching ? (
          <LoadingComponent customText="Fetching data..." />
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-center mb-3">
              <span className={styles.hideSourceData} onClick={hideSourceData}>HIDE SOURCE DATA</span>
            </div>
            <div className={styles.tableDataWrapper} ref={tableRef}>
              <FordKpisTable
                tableData={getActiveExpandedTable}
                handlePageChange={handlePageChange}
                expandedTableKey={getExpandedTableKey}
                handleDownload={handleDownload}
              />
            </div>
          </>
        )
      }
    </div>
  );
};

export default React.memo(FordKpisTableWrapper);

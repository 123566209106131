export const companyOptions = [
  { text: "All", val: "all" },
  { text: "Relationship Stage", val: "relationship_stage" },
  { text: "Technology Tag", val: "technology_tag" },
  { text: "Industry Tag", val: "industry_tag" },
];
export const projectOptions = [
  { text: "All", val: "all" },
  { text: "Project Status,", val: "projects_status" },
  { text: "Priority", val: "priority" },
  { text: "Technology Tag", val: "technology_tag" },
  { text: "Industry Tag", val: "industry_tag" },
];

export const themesOptions = [
  { text: "All", val: "all" },
  { text: "Type", val: "type" },
  { text: "Technology Tag", val: "technology_tag" },
  { text: "Industry Tag", val: "industry_tag" },
];

export const ideasOptions = [
  { text: "All", val: "all" },
  { text: "Status", val: "status" },
  { text: "Technology Tag", val: "technology_tag" },
  { text: "Industry Tag", val: "industry_tag" },
];
export const documentsOptions = [
  { text: "All", val: "all" },
  { text: "Company Documents", val: "company_documents" },
  { text: "Theme Documents", val: "theme_documents" },
  { text: "Ideas Documents", val: "ideas_documents" },
  { text: "Event Documents", val: "event_documents" },
];

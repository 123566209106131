import React, { useCallback } from "react";
import classNames from "classnames";

import styles from './styles.module.scss';

const FordReplicationsTableHeader = ({ highLightColumn = [] }) => {
  const makeHighLightColumnClass = useCallback((props) => {
    const hasProp = highLightColumn.some(p => props.includes(p));
    return hasProp ? styles.highLightedColumn : null
  }, [highLightColumn]);

  return (
    <div className={styles.tableDataHeader}>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Project
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Pillar
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, makeHighLightColumnClass(['plant']))}>
        Plant
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, makeHighLightColumnClass(['area']))}>
        Area
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, makeHighLightColumnClass(['reference_plant']))}>
        Reference Plant
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Original Plant
      </div>
      {/*<div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>*/}
      {/*    Area*/}
      {/*</div>*/}
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Description
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, makeHighLightColumnClass(['projectStage']))}>
        Project Stage
      </div>
      {/*<div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>*/}
      {/*  Project Owner*/}
      {/*</div>*/}
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, makeHighLightColumnClass(['investment_usd']))}>
        Investment $
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, makeHighLightColumnClass(['savings']))}>
        Savings $
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, makeHighLightColumnClass(['tarr']))}>
        TARR %
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Funded By (Prototype)
      </div>
      <div className={classNames(styles.tableDataCellBigger, styles.tableDataHeaderCell)}>
        Funded By (Implementation)
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Project Start Date
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Prototype Date
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, makeHighLightColumnClass(['implementation_date', 'planned_replication']))}>
        Implementation Date
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Expected Job 1 Date
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, makeHighLightColumnClass(['job1Date']))}>
        Job 1 Date
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, makeHighLightColumnClass(['ideaGeneration']))}>
        Idea Generation (Days)
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, makeHighLightColumnClass(['implementation']))}>
        Implementation (Days)
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, makeHighLightColumnClass(['job1Accomplishment']))}>
        Job 1 Accomplished
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, makeHighLightColumnClass(['planned_replication']))}>
        Next Stage Change
      </div>
      <div
        className={classNames(styles.tableDataCell, styles.tableDataHeaderCell, makeHighLightColumnClass(['approvedReplications']))}>
        Replication (Y/N)
      </div>
      <div className={classNames(styles.tableDataCell, styles.tableDataHeaderCell)}>
        Original Project
      </div>
    </div>
  );
};

export default React.memo(FordReplicationsTableHeader);

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import classNames from "classnames";
import { format, isBefore, isToday, isValid } from "date-fns";
import { useSnackbar } from "notistack";
import { FilePicker } from "react-file-picker";

import CompanyInline from "modules/primitives/companyInline/companyInline.component";
import { useModelPopup, useS3FileUpload } from "../../../common/hooks";
import { initModel, validation, videoExtensions } from "./newEvent.constants";
import {
  eventsCreateNewEvent,
  eventsEditEvent,
  eventsGet,
} from "../viewEvent/viewEvent.action";
import AddSpeaker from "./addSpeaker";
import {
  BtnType,
  Button,
  Label,
  Loading,
  Search,
  TextArea,
  TextBox,
  UserInline,
  UserInlineTypes,
  MultiSelect,
} from "../../primitives";
import ConfirmDeleteComponent from "../../../common/components/confirmDelete";
import styles from "./newEvent.module.scss";
import { Icon, smSize } from "../../../common/icon";
import { themesSearchAttempt } from "../../themes/themesMainPage/themesMain.action";

const EventNewComponent = (props) => {
  const { defaultModel = {} } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [model, setmodel] = useState(initModel);
  const [options, setOptions] = useState([]);
  const [videos, setVideos] = useState([]);
  const [video, setVideo] = useState("");
  const { fileUpload, fileDelete } = useS3FileUpload();
  const [slides, setSlides] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [slide, setSlide] = useState("");
  const [speakers, setSpeakers] = useState([]);
  const [companySelectChanged, setCompanySelectChanged] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingSliders, setLoadingSliders] = useState(false);
  const [relatedThemes, setRelatedThemes] = useState([]);
  const [elasticQueryy, setElasticQuery] = useState("");
  const [themesOptions, setThemesOptions] = useState([]);

  const formRef = useRef();
  const history = useHistory();
  const popup = useModelPopup();

  const {
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: { companyList },
    },
    eventsReducer: {
      viewEventReducer: { selectedEvent, shouldRedirecToEvents },
    },
    themesReducer: {
      themesMainReducer: { themesData, loadingThemes },
    },
  } = useSelector((state) => state);

  const handleTypeSearch = (val) => {
    setElasticQuery(val.search);
  };

  useEffect(() => {
    if (elasticQueryy) {
      const data = {
        sort_order: "asc",
        sort_attribute: "created_at",
        search: {
          query: elasticQueryy,
        },
      };

      dispatch(themesSearchAttempt({ page: 1, data }));
    }
  }, [elasticQueryy]);

  useEffect(() => {
    if (selectedCouncil && elasticQueryy === "") {
      const data = {
        sort_order: "asc",
        sort_attribute: "created_at",
        search: {
          query: "*",
        },
      };

      dispatch(themesSearchAttempt({ page: 1, data }));
    }
  }, [selectedCouncil, elasticQueryy]);

  useEffect(() => {
    if (themesData && themesData?.themes?.length > 0) {
      const filteredOptions = themesData?.themes.filter((option) => {
        const isAlreadySelected = relatedThemes?.find((item) => {
          return item?.theme_id === option.id;
        });
        return !isAlreadySelected;
      });

      setThemesOptions(filteredOptions);
    }
  }, [themesData?.themes]);

  useEffect(() => {
    if (Object.keys(defaultModel).length > 0) {
      setmodel({
        ...initModel,
        ...defaultModel,
      });
    }
  }, [defaultModel, options]);

  React.useMemo(() => {
    if (companyList && companyList.length !== 0) {
      setOptions(companyList);
    }
  }, [companyList]);

  useEffect(() => {
    if (shouldRedirecToEvents) {
      history.push("/events");
    }
  }, [shouldRedirecToEvents]);

  useEffect(() => {
    if (!companySelectChanged) {
      setCompanySelectChanged(true);
    }
  }, [companySelectChanged]);

  const handleRemoveCompany = (index) => {
    setCompanies((companies) =>
      companies.filter((item, itemIndex) => itemIndex !== index)
    );
  };

  const handleSetSpeaker = (speaker) => {
    let newSpeakers = [];
    if (speaker.length > 1) {
      newSpeakers = speaker;
      setSpeakers((prevSpeakers) => [
        ...new Set([...prevSpeakers, ...newSpeakers]),
      ]);
    } else {
      newSpeakers = speaker.id ? { user_id: speaker.id } : speaker[0];
      setSpeakers((prevSpeakers) => [
        ...new Set([...prevSpeakers, newSpeakers]),
      ]);
    }
  };

  const handleAddSpeaker = () => {
    popup.show({
      title: "Add a Speaker",
      component: (
        <AddSpeaker
          handleSetSpeaker={handleSetSpeaker}
          speakers={speakers}
          id={selectedEvent && selectedEvent.id}
        />
      ),
    });
  };

  const handleSetSlide = (e) => {
    setSlide(e.target.value);
  };

  const handleSetVideo = (e) => {
    setVideo(e.target.value);
  };

  const handleAddSlides = () => {
    if (slides) {
      if (slides.indexOf(slide) === -1) {
        if (getSlidePlatform(slide) && !isEmbedded(slide)) {
          setSlides((prevSlides) => [...prevSlides, slide.trim()]);
        } else {
          enqueueSnackbar(
            "External slide links should be from Google Slides, Slideshare or embedded with iFrame",
            {
              variant: "error",
            }
          );
        }
      } else {
        enqueueSnackbar("Slide already added.", {
          variant: "error",
        });
      }
    }
    setSlide("");
  };

  const checkIfS3 = (url) => {
    const match = /(s3-|s3\.)?(.*)\.amazonaws\.com/g.exec(url);

    if (match) {
      return match[0];
    }
  };

  const getFilename = (url) => {
    if (url) {
      return url.split("/").pop();
    }
    return "";
  };

  const handleDeleteSlide = async (e, index) => {
    e.stopPropagation();
    if (checkIfS3(slides[index])) {
      const pathname = `srm/${selectedCouncil.id}/events/${
        selectedEvent.id
      }/slides/${getFilename(slides[index])}`;
      await fileDelete(pathname).then(() => {
        setSlides(() => slides.filter((slide, idx) => idx !== index));
      });
    } else {
      setSlides(() => slides.filter((slide, idx) => idx !== index));
    }
  };

  const checkPlatform = (url) => {
    const regExpYoutube =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const regExpVimeo =
      /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    const regExpFacebook =
      /^https?:\/\/www\.facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;

    if (url.includes("iframe")) {
      return "iframe";
    }
    if (url.match(regExpYoutube)) {
      return "youtube";
    }
    if (url.match(regExpVimeo)) {
      return "vimeo";
    }
    if (url.match(regExpFacebook)) {
      return "facebook";
    }
  };

  const getSlidePlatform = (url) => {
    if (url.includes("iframe")) {
      return "iframe";
    }
    if (
      url.includes("https://www.slideshare.net/slideshow/embed_code/") ||
      url.includes("https://docs.google.com/presentation/")
    ) {
      return "link";
    }
  };

  const getUrlExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  const isEmbedded = (url) => {
    return videoExtensions.indexOf(getUrlExtension(url)) !== -1;
  };

  const handleAddVideo = () => {
    if (videos) {
      if (videos.indexOf(video) === -1) {
        if (checkPlatform(video) && !isEmbedded(video)) {
          setVideos((prevVideos) => [...prevVideos, video.trim()]);
        } else {
          enqueueSnackbar(
            "External video links should be from Youtube, Facebook or Vimeo",
            {
              variant: "error",
            }
          );
        }
      } else {
        enqueueSnackbar("Video already added.", {
          variant: "error",
        });
      }
    }
    setVideo("");
  };

  const handleDeleteVideo = async (e, index) => {
    e.stopPropagation();
    if (checkIfS3(videos[index])) {
      const pathname = `srm/${selectedCouncil.id}/events/${
        selectedEvent.id
      }/videos/${getFilename(videos[index])}`;
      await fileDelete(pathname).then(() => {
        setVideos(() => videos.filter((slide, idx) => idx !== index));
      });
    } else {
      setVideos(() => videos.filter((slide, idx) => idx !== index));
    }
  };

  const handleRemoveSpeaker = (index) => {
    setSpeakers((prevSpeakers) =>
      prevSpeakers.map((speaker, itemIndex) => {
        const { avatar, id, email, company, name, title, user_id } = speaker;
        if (index === itemIndex) {
          return {
            avatar,
            id,
            email,
            organization: company || null,
            name,
            title,
            user_id,
            _destroy: true,
          };
        }
        return speaker;
      })
    );
  };

  const handleRemoveTheme = (index) => {
    setRelatedThemes((prevTheme) =>
      prevTheme.map((theme, itemIndex) => {
        const { user, added_by_id } = theme;
        if (index === itemIndex) {
          return {
            ...theme,
            id: theme.theme_event_id,
            added_by_id: added_by_id || user?.id,
            _destroy: true,
          };
        }
        return theme;
      })
    );
  };

  useEffect(() => {
    if (
      props.location.state?.eventId &&
      props.location.state?.mode === "edit"
    ) {
      dispatch(
        eventsGet({ eventId: props.location.state?.eventId, enqueueSnackbar })
      );
    }
  }, [props.location.state?.eventId, props.location.state?.mode]);

  useEffect(() => {
    if (
      selectedEvent &&
      selectedEvent.id &&
      props.location.state?.mode === "edit"
    ) {
      const event = {
        description: selectedEvent.description,
        starts_on: format(
          new Date(selectedEvent.starts_on),
          "yyyy-MM-dd'T'HH:mm"
        ),
        ends_on: format(new Date(selectedEvent.ends_on), "yyyy-MM-dd'T'HH:mm"),
        location: selectedEvent.location,
        title: selectedEvent.name,
        website_uri: selectedEvent.website_uri,
      };
      setCompanies(selectedEvent.companies);
      setSpeakers(selectedEvent.event_speakers);
      setVideos(selectedEvent.video_uri);
      setSlides(selectedEvent.slides_uri);
      setmodel(event);
      setRelatedThemes(selectedEvent.theme_events);
    }
  }, [selectedEvent?.id]);

  const handleDeleteEvent = (e) => {
    e.preventDefault();
    popup.show({
      title: "Confirmation Prompt",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={props.location.state?.eventId}
          enqueueSnackbar={enqueueSnackbar}
          actionType="deleteEvent"
          eventName={selectedEvent.name}
        />
      ),
    });
  };

  const handleAddDocument = async (FileObject, type) => {
    const file = FileObject;
    if (type === "videos") {
      setLoading(true);
    } else {
      setLoadingSliders(true);
    }
    const pathname = `srm/${selectedCouncil.id}/events/${selectedEvent.id}/${type}`;
    await fileUpload(file, pathname).then((res) => {
      if (type === "videos") {
        setLoading(false);
      } else {
        setLoadingSliders(false);
      }

      if (type === "videos" && res && res.location) {
        setVideos((prevVideos) => {
          if (prevVideos) {
            return [...prevVideos, res.location];
          }
          return [res.location];
        });
        setVideo("");
      } else {
        setSlides((prevSlides) => {
          if (prevSlides) {
            return [...prevSlides, res.location];
          }
          return [res.location];
        });
        setSlide("");
      }
    });
  };

  const isValidDate = (d) => {
    const date = new Date(d);
    if (date) {
      return date instanceof Date && !isNaN(date);
    }
    return true;
  };

  const handleOnSubmit = (values, setFieldError, resetForm) => {
    const prepareThemesAttr = (theme) => {
      if (theme._destroy) {
        return {
          added_by_id: theme.added_by_id,
          theme_event_id: theme.theme_event_id,
          id: theme.id,
          council_id: selectedCouncil.id,
          _destroy: theme._destroy ? true : undefined,
        };
      }
      if (theme?.user?.id) {
        return {
          added_by_id: theme?.user?.id,
          theme_id: theme.id,
          council_id: selectedCouncil.id,
        };
      }
      return false;
    };

    const data = {
      ...values,
      speakers,
      themes_attributes: relatedThemes
        .map((theme) => {
          return prepareThemesAttr(theme);
        })
        .filter((item) => item),
      slides_uri: slides,
      video_uri: videos,
      company_ids: companies.map((company) => company.id),
      enqueueSnackbar,
    };
    if (isValidDate(values.starts_on) && isValidDate(values.ends_on)) {
      if (!isBefore(new Date(values.starts_on), new Date(values.ends_on))) {
        setFieldError("ends_on", "End date cannot be before the start date.");
      } else if (props.location.state?.mode === "add") {
        dispatch(eventsCreateNewEvent(data));
        if (
          isToday(new Date(data.starts_on)) ||
          isBefore(new Date(), new Date(data.starts_on))
        ) {
          history.push("/events/upcoming");
        } else {
          history.push("/events/past");
        }
        resetForm();
      } else {
        dispatch(
          eventsEditEvent({
            ...data,
            eventId: props.location.state?.eventId,
            enqueueSnackbar,
          })
        );
        history.push(`/events/id/${props.location.state?.eventId}`);
        resetForm();
      }
    } else {
      if (!isValid(values.starts_on)) {
        setFieldError(
          "starts_on",
          "Date is invalid, please enter a valid date."
        );
      }
      if (!isValid(values.ends_on)) {
        setFieldError("ends_on", "Date is invalid, please enter a valid date");
      }
    }
  };

  const getIframeSrc = (url) => {
    const match = /src\s*=\s*"([^"]+)"/i.exec(url);
    if (match) {
      return match[1];
    }
  };

  const handleOpenLink = (link) => {
    if (link.includes("iframe")) {
      window.open(getIframeSrc(link));
    } else {
      window.open(link);
    }
  };

  return (
    <div className={styles.eventWrp}>
      <div className="row">
        <div className="col-6" style={{ margin: "0 auto" }}>
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={model && model}
            validationSchema={validation && validation}
            onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
              setSubmitting(true);
              handleOnSubmit(values, setFieldError, resetForm);
            }}
          >
            {({ handleSubmit, ...formikprops }) => (
              <form onSubmit={handleSubmit}>
                <h4 className={styles.headerText}>
                  {props.location.state?.mode === "edit"
                    ? "Edit Event"
                    : "Create An Event"}
                </h4>
                <div className={styles.container}>
                  <div className={styles.title}>
                    <Label>Title</Label>
                    <TextBox
                      type="text"
                      name="title"
                      placeholder="Title"
                      className={styles.textBox}
                      formProps={formikprops}
                    />
                  </div>
                  <div className={styles.lastName}>
                    <Label>Description</Label>
                    <TextArea
                      type="text"
                      name="description"
                      placeholder="Description"
                      className={styles.textBox}
                      formProps={formikprops}
                    />
                  </div>

                  <Label>Starts on</Label>
                  <TextBox
                    type="datetime-local"
                    name="starts_on"
                    placeholder="Starts on"
                    className={styles.textBox}
                    formProps={formikprops}
                  />

                  <Label>Ends on</Label>
                  <TextBox
                    type="datetime-local"
                    name="ends_on"
                    placeholder="Ends on"
                    className={styles.textBox}
                    formProps={formikprops}
                  />
                  <Label>Location</Label>
                  <TextBox
                    type="text"
                    name="location"
                    placeholder="Location"
                    className={styles.textBox}
                    formProps={formikprops}
                  />

                  <Label>Website</Label>
                  <TextBox
                    type="text"
                    name="website_uri"
                    placeholder="Website"
                    className={styles.textBox}
                    formProps={formikprops}
                  />
                </div>

                <div className={styles.slideWrp}>
                  <Label>Add Companies</Label>
                  <Search
                    responses={companies}
                    setSearchResponses={setCompanies}
                    onKeyUp
                    showCrunchBaseHolder={false}
                    className={styles.searchCon}
                    placeholder="Search Companies, Technologies, and Industries"
                    from="new_event"
                  />

                  <div className={styles.listWrp}>
                    {companies &&
                      companies.map((company, index) => (
                        <div className={styles.companyItem} key={index}>
                          <div className={styles.companies}>
                            <CompanyInline
                              avatar={company.logo}
                              companyName={company.name}
                              company={company}
                              companyId={company.company_id}
                              shoulNotBeClickable
                            />
                          </div>
                          <div
                            onClick={() => handleRemoveCompany(index)}
                            className={styles.delete}
                          >
                            <Icon {...smSize} icon="icn-button-delete" />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {selectedCouncil?.themes_connected &&
                  (selectedCouncil?.settings.theme_events_enabled ||
                    selectedCouncil?.settings.theme_events_enabled ===
                      undefined) && (
                    <div className={styles.slideWrp}>
                      <Label>
                        Related{" "}
                        {selectedCouncil?.settings?.themes_display_name ||
                          "Themes"}
                      </Label>
                      <div className={styles.selectorWrp}>
                        <MultiSelect
                          onChange={(val) => {
                            setRelatedThemes((prevThemes) => [
                              ...new Set([...prevThemes, ...val]),
                            ]);
                          }}
                          placeholder={`Select a ${
                            selectedCouncil?.settings
                              ?.themes_display_name_singular || "Theme"
                          }`}
                          labelField="name"
                          valueField="id"
                          searchBy="name"
                          options={themesOptions}
                          loading={loadingThemes}
                          formProps={formikprops}
                          multi={false}
                          loadingRenderer={() => (
                            <div className={styles.loader}>
                              <Loading
                                hideString
                                style={{
                                  height: "26px",
                                  width: "26px",
                                }}
                              />
                            </div>
                          )}
                          searchFn={(args) => {
                            if (elasticQueryy === args.state.search) {
                              return;
                            }
                            if (args.state.search.length) {
                              handleTypeSearch(args.state);
                            } else {
                              setElasticQuery("");
                            }
                          }}
                        />
                      </div>
                      <div className={styles.listWrp}>
                        {relatedThemes &&
                          relatedThemes.map((theme, themeIndex) => {
                            if (theme._destroy) {
                              return null;
                            }
                            return (
                              <div
                                className={styles.companyItem}
                                key={theme.id}
                              >
                                <div className={styles.companies}>
                                  <UserInline
                                    local
                                    first={theme.name}
                                    // last={user.last_name}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                    type={UserInlineTypes.WITH_NAME}
                                    className={styles.name}
                                    userId={theme.theme_id}
                                    avatar={null}
                                    noNavigation
                                  />
                                </div>
                                <div className={styles.delete}>
                                  <Icon
                                    {...smSize}
                                    icon="icn-button-delete"
                                    onClick={(e) =>
                                      handleRemoveTheme(themeIndex)
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}

                <div className={styles.slideWrp}>
                  <Label>Upload Videos</Label>
                  {loading ? (
                    <div className={styles.loaderWrp}>
                      <Loading />
                    </div>
                  ) : (
                    <div className={styles.vedioBtnWrp}>
                      <FilePicker
                        extensions={[
                          "mp4",
                          "mov",
                          "wmv",
                          "flv",
                          "avi",
                          "avi",
                          "webm",
                          "mkv",
                        ]}
                        className={styles.filePicker}
                        maxSize={500}
                        onChange={(FileObject) => {
                          handleAddDocument(FileObject, "videos");
                        }}
                        onError={(errMsg) =>
                          enqueueSnackbar(errMsg, {
                            variant: "error",
                          })
                        }
                      >
                        <Button
                          btn={BtnType.OUTLINE_LIGHT}
                          className={styles.selectFile}
                          rounded
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          Choose video file
                        </Button>
                      </FilePicker>
                    </div>
                  )}

                  <div className={styles.orSeparator}>
                    <div className={styles.separatorText}>OR</div>
                  </div>

                  <div className={styles.slideAddWrp}>
                    <TextBox
                      type="text"
                      placeholder="Paste embed code or an embed link (e.g., https://www.youtube.com/embed/P3R5OiW33sE )"
                      className={styles.textBox}
                      value={video}
                      key={video}
                      onChange={handleSetVideo}
                    />
                    <Button
                      onClick={() => handleAddVideo()}
                      btn={BtnType.REGULAR}
                      type="button"
                      className={styles.addSlide}
                    >
                      Add
                    </Button>
                  </div>
                  <p className={styles.instructionText}>
                    The accepted file formats are: mp4, mov, wmv, flv, avi,
                    webm, mkv{" "}
                  </p>
                  <div className={styles.listWrp}>
                    {videos &&
                      videos.map((item, index) => (
                        <div
                          key={item.id}
                          className={styles.item}
                          onClick={() => handleOpenLink(item)}
                        >
                          <div className={styles.name}> {item}</div>
                          <div className={styles.delete}>
                            <Icon
                              {...smSize}
                              icon="icn-button-delete"
                              onClick={(e) => handleDeleteVideo(e, index)}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                {/* ends here */}
                <div className={styles.slideWrp}>
                  <Label>Add Slides</Label>
                  {loadingSliders ? (
                    <div className={styles.loaderWrp}>
                      <Loading />
                    </div>
                  ) : (
                    <div className={styles.vedioBtnWrp}>
                      <FilePicker
                        extensions={["pdf", "ppt", "pptx", "pps"]}
                        className={styles.filePicker}
                        maxSize={500}
                        onChange={(FileObject) => {
                          handleAddDocument(FileObject, "slides");
                        }}
                        onError={(errMsg) =>
                          enqueueSnackbar(errMsg, {
                            variant: "error",
                          })
                        }
                      >
                        <Button
                          btn={BtnType.OUTLINE_LIGHT}
                          className={styles.selectFile}
                          rounded
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          Choose Slide file
                        </Button>
                      </FilePicker>
                      {/* <Button btn={BtnType.REGULAR} className={styles.upload}>
                    Upload
                  </Button> */}
                    </div>
                  )}

                  <div className={styles.orSeparator}>
                    <div className={styles.separatorText}>OR</div>
                  </div>

                  <div className={styles.slideAddWrp}>
                    <TextBox
                      type="text"
                      placeholder="Paste embed code here to add slides"
                      key={slide}
                      className={styles.textBox}
                      value={slide}
                      onChange={handleSetSlide}
                    />
                    <Button
                      onClick={() => handleAddSlides()}
                      btn={BtnType.REGULAR}
                      type="button"
                      className={styles.addSlide}
                    >
                      Add
                    </Button>
                  </div>
                  <p className={styles.instructionText}>
                    The accepted file formats are: pdf, ppt, pptx, pps{" "}
                  </p>
                  <div className={styles.listWrp}>
                    {slides &&
                      slides[0] &&
                      slides.map((item, index) => (
                        <div
                          className={styles.item}
                          onClick={() => handleOpenLink(item)}
                        >
                          <div className={styles.name}> {item}</div>
                          <div className={styles.delete}>
                            <Icon
                              {...smSize}
                              icon="icn-button-delete"
                              onClick={(e) => handleDeleteSlide(e, index)}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className={styles.speakerWrp}>
                  <Label>Add Speakers</Label>

                  <div>
                    <Button
                      onClick={handleAddSpeaker}
                      btn={BtnType.REGULAR}
                      type="button"
                      className={styles.addSpeaker}
                    >
                      Add a speaker
                    </Button>
                  </div>

                  <div className={styles.listWrp}>
                    {speakers &&
                      speakers[0] &&
                      speakers.map((user, index) =>
                        !user._destroy ? (
                          <div className={styles.item} key={index}>
                            <div className={styles.speaker}>
                              <UserInline
                                local
                                first={user.name || user.full_name}
                                last={user.title || ""}
                                type={UserInlineTypes.WITH_NAME}
                                className={styles.name}
                                userId={user.id}
                                avatar={user.avatar}
                              />
                              <div
                                className={classNames("caption", styles.title)}
                              >
                                {user.company}
                              </div>
                            </div>
                            <div
                              onClick={() => handleRemoveSpeaker(index)}
                              className={styles.delete}
                            >
                              <Icon {...smSize} icon="icn-button-delete" />
                            </div>
                          </div>
                        ) : null
                      )}
                  </div>
                </div>

                {/* </div> */}

                <div className={styles.buttonWrp}>
                  <Button
                    type="submit"
                    btn={BtnType.REGULAR}
                    className={styles.add}
                  >
                    {props.location.state?.mode === "add"
                      ? "Create Event"
                      : "Update"}
                  </Button>

                  {props.location.state?.mode === "edit" && (
                    <Button
                      btn={BtnType.FRAME_LESS}
                      className={styles.delete}
                      onClick={handleDeleteEvent}
                    >
                      Delete Event
                    </Button>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EventNewComponent);

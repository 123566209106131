export const PATIENT_ORG_SET_DOWNLOAD_PARAMS = "PATIENT_ORG_SET_DOWNLOAD_PARAMS";
export const patientOrgSetDownloadParams = (payload) => ({
  type: PATIENT_ORG_SET_DOWNLOAD_PARAMS,
  payload,
});

export const PATIENT_ORGANIZATION_GET_ATTEMPT = "PATIENT_ORGANIZATION_GET";
export const patientOrganizationGet = (payload) => ({
  type: PATIENT_ORGANIZATION_GET_ATTEMPT,
  payload,
});
export const PATIENT_ORGANIZATION_GET_SUCCESS = "PATIENT_ORGANIZATION_GET_SUCCESS";
export const patientOrganizationGetSuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_GET_SUCCESS,
  payload,
});

export const PATIENT_ORGANIZATION_SET = "PATIENT_ORGANIZATION_SET";
export const patientOrganizationSet = (payload) => ({
  type: PATIENT_ORGANIZATION_SET,
  payload,
});

export const GET_PATIENT_ORGANIZATION_GROUP_FIELDS = "GET_PATIENT_ORGANIZATION_GROUP_FIELDS";
export const getPatientOrganizationGroupFields = (payload) => ({
  type: GET_PATIENT_ORGANIZATION_GROUP_FIELDS,
  payload,
});
export const GET_PATIENT_ORGANIZATION_GROUP_FIELDS_SUCCESS = "GET_PATIENT_ORGANIZATION_GROUP_FIELDS_SUCCESS";
export const getPatientOrganizationGroupFieldsSuccess = (payload) => ({
  type: GET_PATIENT_ORGANIZATION_GROUP_FIELDS_SUCCESS,
  payload,
});

export const PATIENT_ORGANIZATION_DETAILS_GET_ATTEMPT = "PATIENT_ORGANIZATION_DETAILS_GET_ATTEMPT";
export const patientOrganizationDetailsGet = (payload) => ({
  type: PATIENT_ORGANIZATION_DETAILS_GET_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_DETAILS_GET_SUCCESS = "PATIENT_ORGANIZATION_DETAILS_GET_SUCCESS";
export const patientOrganizationDetailsGetSuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_DETAILS_GET_SUCCESS,
  payload,
});

export const PATIENT_ORGANIZATION_EDIT_RELATIONSHIP_ATTEMPT = "PATIENT_ORGANIZATION_EDIT_RELATIONSHIP_ATTEMPT";
export const editRelationShipPatientOrg = (payload) => ({
  type: PATIENT_ORGANIZATION_EDIT_RELATIONSHIP_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_ADD_RELATIONSHIP_ATTEMPT = "PATIENT_ORGANIZATION_ADD_RELATIONSHIP_ATTEMPT";
export const addRelationShip = (payload) => ({
  type: PATIENT_ORGANIZATION_ADD_RELATIONSHIP_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_RESET_UPDATE_PROP = "PATIENT_ORGANIZATION_RESET_UPDATE_PROP";
export const resetUpdatePatientOrgProp = () => ({
  type: PATIENT_ORGANIZATION_RESET_UPDATE_PROP,
});

export const PATIENT_ORGANIZATION_ADD_RELATIONSHIP_SUCCESS = "PATIENT_ORGANIZATION_ADD_RELATIONSHIP_SUCCESS";
export const addRelationShipSuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_ADD_RELATIONSHIP_SUCCESS,
  payload,
});

export const PATIENT_ORGANIZATION_DELETE_RELATIONSHIP_ATTEMPT = "PATIENT_ORGANIZATION_DELETE_RELATIONSHIP_ATTEMPT";
export const deleteRelationShip = (payload) => ({
  type: PATIENT_ORGANIZATION_DELETE_RELATIONSHIP_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_DELETE_RELATIONSHIP_SUCCESS = "PATIENT_ORGANIZATION_DELETE_RELATIONSHIP_SUCCESS";
export const deleteRelationSuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_DELETE_RELATIONSHIP_SUCCESS,
  payload,
});

export const PATIENT_ORGANIZATION_ADD_CONTACT_ATTEMPT = "PATIENT_ORGANIZATION_ADD_CONTACT_ATTEMPT";
export const addContact = (payload) => ({
  type: PATIENT_ORGANIZATION_ADD_CONTACT_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_ADD_CONTACT_SUCCESS = "PATIENT_ORGANIZATION_ADD_CONTACT_SUCCESS";
export const addContactSuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_ADD_CONTACT_SUCCESS,
  payload,
});

export const PATIENT_ORGANIZATION_DELETE_CONTACT_ATTEMPT = "PATIENT_ORGANIZATION_DELETE_CONTACT_ATTEMPT";
export const deleteContact = (payload) => ({
  type: PATIENT_ORGANIZATION_DELETE_CONTACT_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_DELETE_CONTACT_SUCCESS = "PATIENT_ORGANIZATION_DELETE_CONTACT_SUCCESS";
export const deleteContactSuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_DELETE_CONTACT_SUCCESS,
  payload,
});

export const PATIENT_ORGANIZATION_EDIT_CONTACT_ATTEMPT = "PATIENT_ORGANIZATION_EDIT_CONTACT_ATTEMPT";
export const editOrganizationContact = (payload) => ({
  type: PATIENT_ORGANIZATION_EDIT_CONTACT_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_EDIT_CONTACT_SUCCESS = "PATIENT_ORGANIZATION_EDIT_CONTACT_SUCCESS";
export const editOrganizationContactSuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_EDIT_CONTACT_SUCCESS,
  payload,
});

export const GSK_POST_CUSTOM_FIELDS = "GSK_POST_CUSTOM_FIELDS";
export const addCustomFields = (payload) => ({
  type: GSK_POST_CUSTOM_FIELDS,
  payload,
});

export const GSK_POST_CUSTOM_FIELDS_SUCCESS = "GSK_POST_CUSTOM_FIELDS_SUCCESS";
export const addCustomFieldsSuccess = (payload) => ({
  type: GSK_POST_CUSTOM_FIELDS_SUCCESS,
  payload,
});

export const PATIENT_ORGANIZATION_PUT_DETAILS_FIELDS_ATTEMPT = "PATIENT_ORGANIZATION_PUT_DETAILS_FIELDS_ATTEMPT";
export const patientOrganizationPutDetailsFields = (payload) => ({
  type: PATIENT_ORGANIZATION_PUT_DETAILS_FIELDS_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_PUT_DETAILS_FIELDS_SUCCESS = "PATIENT_ORGANIZATION_PUT_DETAILS_FIELDS_SUCCESS";
export const patientOrganizationPutDetailsFieldsSuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_PUT_DETAILS_FIELDS_SUCCESS,
  payload,
});

export const PATIENT_ORGANIZATION_DELETE_DETAILS_FIELDS_ATTEMPT = "PATIENT_ORGANIZATION_DELETE_DETAILS_FIELDS_ATTEMPT";
export const patientOrganizationDeleteDetailsFields = (payload) => ({
  type: PATIENT_ORGANIZATION_DELETE_DETAILS_FIELDS_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_DELETE_DETAILS_FIELDS_SUCCESS = "PATIENT_ORGANIZATION_DELETE_DETAILS_FIELDS_SUCCESS";
export const patientOrganizationDeleteDetailsFieldsSuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_DELETE_DETAILS_FIELDS_SUCCESS,
  payload,
});

export const PATIENT_ORGANIZATION_CLEAR = "PATIENT_ORGANIZATION_CLEAR";
export const patientOrganizationClear = (payload) => ({
  type: PATIENT_ORGANIZATION_CLEAR,
  payload,
});

export const PATIENT_ORGANIZATION_EDIT_ATTEMPT = "PATIENT_ORGANIZATION_EDIT_ATTEMPT";
export const patientOrganizationEdit = (payload) => ({
  type: PATIENT_ORGANIZATION_EDIT_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_EDIT_SUCCESS = "PATIENT_ORGANIZATION_EDIT_SUCCESS";
export const patientOrganizationEditSuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_EDIT_SUCCESS,
  payload,
});
export const PATIENT_ORGANIZATION_SHARE_ATTEMPT = "PATIENT_ORGANIZATION_SHARE_ATTEMPT";
export const patientOrganizationShare = (payload) => ({
  type: PATIENT_ORGANIZATION_SHARE_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_SHARE_SUCCESS = "PATIENT_ORGANIZATION_SHARE_SUCCESS";
export const patientOrganizationShareSuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_SHARE_SUCCESS,
  payload,
});

export const PATIENT_ORGANIZATION_ADD_MANY_TO_PROJECT_ATTEMPT = "PATIENT_ORGANIZATION_ADD_MANY_TO_PROJECT_ATTEMPT";
export const patientOrganizationProjectAddMany = (payload) => ({
  type: PATIENT_ORGANIZATION_ADD_MANY_TO_PROJECT_ATTEMPT,
  payload,
});

export const PATIENT_ORGANIZATION_ADD_MANY_TO_PROJECT_SUCCESS = "PATIENT_ORGANIZATION_ADD_MANY_TO_PROJECT_SUCCESS";
export const patientOrganizationProjectAddManySuccess = (payload) => ({
  type: PATIENT_ORGANIZATION_ADD_MANY_TO_PROJECT_SUCCESS,
  payload,
});
export const SET_CUSTOM_FIELD_LOADING = "SET_CUSTOM_FIELD_LOADING";
export const setCustomFieldLoadingOrg = (payload) => ({
  type: SET_CUSTOM_FIELD_LOADING,
  payload,
});

export const ERROR_HANDLE_SUCCESS = "ERROR_HANDLE_SUCCESS";
export const errorHandleSuccess = (payload) => ({
  type: ERROR_HANDLE_SUCCESS,
  payload,
});

import React from "react";
import { ModalFooter, ModalBody } from "../application/modal";
import { Button, BtnType } from "../modules/primitives";

const InvestmentCFDetailsRedirect = ({
  handleInvestmentRedirect,
  targetStage,
  popup,
  companySlug,
}) => {
  const handleSaveClick = () => {
    handleInvestmentRedirect(targetStage, companySlug);
    popup.hide();
  };
  return (
    <div>
      {" "}
      <ModalBody>
        <div>
          Please make sure all the fields are filled out in the {targetStage.name} Notes section in
          the Details tab of the Company profile.
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.REGULAR} onClick={handleSaveClick}>
          OK
        </Button>
      </ModalFooter>
    </div>
  );
};

export default InvestmentCFDetailsRedirect;

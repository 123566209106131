import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BtnType, Button, Label } from "modules/primitives";
import { httpPost } from "../../../../common/httpCall";
import styles from '../../tractionAI.module.scss';
import LoadingComponent from "modules/primitives/loading/loading.component";
import { AISetScoutData } from "modules/tractionAI/store/tractionAI.actions";
import CompaniesMultiSelectSearch from "../../../../common/components/companiesMultiSelectSearch";
import LoaderWithDots from "../../../../common/components/loaderWithDots";
import RandomAILoader from "modules/tractionAI/components/randomAILoader";

const TractionAIScoutFooter = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();

  const {
    tractionAiReducer: { scout }
  } = useSelector((state) => state);

  const handleSubmit = () => {
    dispatch(AISetScoutData({
      fetching: true,
      searchByQuery: searchQuery.length ? searchQuery : selected[0]?.name,
      companyId: searchQuery.length ? null : selected[0]?.id,
      isCompanySearch: searchQuery.length === 0,
      createNewSearchItem: true,
      fetched: false,
    }));

    httpPost({
      call: "ai/proxy/streaming",
      data: { prompt: searchQuery, endpoint: "recommend", company_id: selected[0]?.id },
    })
      .subscribe(
        () => {
          setSearchQuery('');
        },
        (error) => {
          dispatch(AISetScoutData({
            hasError: true,
            errorMsg: error.response.error,
            errorCode: error.response.status,
            errorType: 'serverError',
            fetched: true,
            fetching: false,
          }));
        }
      )
  };

  const onEnterPress = (evt) => {
    if(evt.keyCode === 13 && !evt.shiftKey) {
      evt.preventDefault();
      handleSubmit()
    }
  };

  const handleCompaniesSelect = (companies) => {
    setSearchQuery('');
    setSelected(companies);
  }

  const getLoadingTemplate = useCallback((data) => {
    if (!data || data?.length === 0) {
      return <LoaderWithDots text="Thinking" />
    }

    const lastItem = data[data.length - 1];
    const hasData = lastItem.data.length > 0;

    if (lastItem.isLoadingMore) {
      return <RandomAILoader size="big" />
    }

    if (hasData) {
      return <LoadingComponent customText="Fetching Scout details..." />
    }

    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <RandomAILoader size="big" />
        <h5>Please wait. Results can take up to 30 seconds.</h5>
      </div>
    )
  }, [scout.data]);

  return (
    <div className="d-flex flex-row w-100 p-2">
      {
        scout.fetching ? (
          <div className="d-flex align-items-center w-100 justify-content-center">
            {getLoadingTemplate(scout.data)}
          </div>
        ) : (
          <>
            <div className="d-flex flex-column w-100">
              <>
                <Label>Recommend Companies That Solve a Problem</Label>
                <textarea
                  className={styles.AITextArea}
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setSelected([]);
                  }}
                  name="prompt"
                  id="prompt"
                  rows="3"
                  placeholder="What is the problem you are trying to solve?"
                  disabled={scout.fetching}
                  onKeyDown={onEnterPress}
                />
              </>
              <div className="d-flex justify-content-center text-muted">OR</div>
              <>
                <Label>Recommend Companies Similar To</Label>
                <CompaniesMultiSelectSearch
                  onChange={handleCompaniesSelect}
                  shouldReset={searchQuery?.length > 0}
                  placeholder="Select Companies"
                  labelField="name"
                  valueField="id"
                  dropdownPosition="top"
                  multi={false}
                  endpoint="companies/traction_db_search"
                />
              </>
            </div>
            <div className="d-flex align-items-end ml-5">
              <Button
                btn={BtnType.REGULAR}
                onClick={handleSubmit}
                disabled={selected.length === 0 && searchQuery?.length === 0}
              >SUBMIT</Button>
            </div>
          </>
        )
      }
    </div>
  );
};

export default React.memo(TractionAIScoutFooter);

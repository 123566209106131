import React, { memo, useEffect, useState } from "react";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import { useDispatch, useSelector } from "react-redux";

import { setChartsIntroActive } from "../../companyMain.action";
import {
  BAR_CHART_INTRO_STEPS,
  PIE_CHART_INTRO_STEPS,
  BUBBLE_CHART_INTRO_STEPS,
} from "./intro-steps";
import { httpPut } from "../../../../../common/httpCall";
import { updateSessionDetails } from "../../../../auth/auth.action";

const ChartsIntro = () => {
  const {
    authReducer: { session },
    companiesReducer: {
      companyMainReducer: {
        charts: { selectedChartType },
      },
    },
  } = useSelector((state) => state);

  const [count, setCount] = useState(0);
  const [steps, setSteps] = useState(null);

  useEffect(() => {
    if (selectedChartType === "pie") {
      setSteps(PIE_CHART_INTRO_STEPS);
    }
    if (selectedChartType === "bar") {
      setSteps(BAR_CHART_INTRO_STEPS);
    }
    if (selectedChartType === "bubble") {
      setSteps(BUBBLE_CHART_INTRO_STEPS);
    }
  }, [selectedChartType]);

  useEffect(() => {
    if (count > 1) return;

    const interval = setInterval(() => {
      if (count > 1) {
        clearInterval(interval);
        return;
      }

      setCount(count + 1);
    }, 1000);
  }, [count]);

  const dispatch = useDispatch();

  const handleStart = () => {
    document.body.style.overflow = "hidden";
  };

  const handleExit = () => {
    document.body.style.overflow = "auto";
    const rightWindow = document.getElementById("rightWindow");
    rightWindow.style.overflow = "auto";
    const listOfCompanies = document.querySelector(".chart-companies-wrapper");

    if (listOfCompanies) {
      listOfCompanies.style.height = "auto";
      listOfCompanies.style.overflow = "auto";
      listOfCompanies.style.border = "2px solid #d6d6d6";
    }

    setCount(0);
    dispatch(setChartsIntroActive(false));

    httpPut({
      call: `user/settings/general/increment_charts_shown?${selectedChartType}=true`,
      data: {},
    }).subscribe((res) => {
      const newSession = {
        ...session,
        charts_intro_shown: res?.response?.charts_intro_shown || {},
      };

      dispatch(
        updateSessionDetails({
          session: newSession,
        })
      );

      const timeout = setTimeout(() => {
        const findSearch = document.getElementById("searchWrapper");
        findSearch.scrollIntoView();
        clearTimeout(timeout);
      });
    });
  };

  const handleBeforeChange = (step) => {
    if (step === 4 && selectedChartType === "bubble") {
      applyStylesPerLastStep();
    }
    if (step === 3 && selectedChartType !== "bubble") {
      applyStylesPerLastStep();
    }
  };

  const applyStylesPerLastStep = () => {
    const listOfCompanies = document.querySelector(".chart-companies-wrapper");
    listOfCompanies.style.height = `${
      listOfCompanies.offsetHeight - listOfCompanies.offsetTop - 50
    }px`;
    listOfCompanies.style.overflow = "hidden";
    listOfCompanies.style.border = "none";
    const timeout = setTimeout(() => {
      const rightWindow = document.getElementById("rightWindow");
      rightWindow.style.overflow = "hidden";
      clearTimeout(timeout);
    }, 500);
  };

  // hack
  if (count < 1) {
    return null;
  }

  return (
    <Steps
      enabled
      steps={steps}
      initialStep={0}
      onBeforeChange={handleBeforeChange}
      onStart={handleStart}
      onExit={handleExit}
      options={{
        showBullets: false,
        doneLabel: "DONE",
        nextLabel: "NEXT",
      }}
    />
  );
};

export default memo(ChartsIntro);

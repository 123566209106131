import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  BtnType,
  Button,
  Label,
  MultiSelect,
  Loading,
} from "../../../../primitives";
import { ModalFooter } from "../../../../../application/modal";
import styles from "./styles.module.scss";
import { themesIdeasCreateAttempt } from "../../../../themes/themesIndividualPage/themesOverviewTab/overviewTab.action";
import { themesSearchAttempt } from "../../../../themes/themesMainPage/themesMain.action";
import { useModelPopup } from "../../../../../common/hooks";

const AddIdeaThemes = ({ selectedIdea }) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();

  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { themesData, loadingThemes },
    },
  } = useSelector((state) => state);
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [options, setOptions] = useState([]);
  const [currentQuery, setQuery] = useState("");
  const [error, setError] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState("");

  useEffect(() => {
    if (themesData?.themes) {
      if (themesData?.themes?.length > 0) {
        const filteredThemes = themesData.themes.filter((option) => {
          const isAlreadySelected = selectedIdea?.themes?.find((item) => {
            return item?.theme_id === option.id;
          });
          return !isAlreadySelected;
        });
        setOptions(filteredThemes);
      }
    }
  }, [themesData]);

  useEffect(() => {
    if (selectedCouncil) {
      const data = {
        sort_order: "asc",
        sort_attribute: "name",
        search: {
          query: "",
        },
      };
      dispatch(themesSearchAttempt({ page: 1, data, fromMain: true }));
    }
  }, [selectedCouncil]);

  const handleOptionSelect = (values) => {
    setError(false);

    setSelectedThemes(values[0]);
  };

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleCompaniesSave = () => {
    if (!selectedThemes.id) {
      setError(true);
      return;
    }
    dispatch(
      themesIdeasCreateAttempt({
        themeId: selectedThemes.id,
        data: {
          theme_idea: [
            {
              idea_id: selectedIdea.id,
            },
          ],
        },
        enqueueSnackbar,
      })
    );
    setError(false);
    popup.hide();
  };

  useEffect(() => {
    const data = {
      sort_order: "asc",
      sort_attribute: "name",
      search: {
        query: debouncedValue,
      },
    };
    dispatch(themesSearchAttempt({ page: 1, data, fromMain: true }));
  }, [debouncedValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(currentQuery);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [currentQuery]);
  return (
    <div>
      <div className={styles.customPadding}>
        <Label>
          {selectedCouncil?.settings?.themes_display_name || "Themes"}
        </Label>
        <div className={styles.selectorWrp}>
          <MultiSelect
            onChange={handleOptionSelect}
            placeholder={`Add ${
              selectedCouncil?.settings?.themes_display_name || "themes"
            } (type to search or select from below)`}
            labelField="name"
            valueField="id"
            searchBy="name"
            options={options}
            searchFn={(args) => {
              if (args.state.search === currentQuery) {
                return;
              }
              setQuery(args.state.search);
            }}
            multi={false}
          />
          <div className={styles.loaderWrp}>
            {loadingThemes && <Loading hideString />}
          </div>
        </div>
        {error && (
          <span className={styles.errorText}>
            At least one{" "}
            {`${
              selectedCouncil?.settings?.themes_display_name_singular || "Theme"
            }`}{" "}
            should be selected
          </span>
        )}
      </div>
      <ModalFooter>
        <Button
          className="mr-3"
          btn={BtnType.FRAME_LESS}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button onClick={handleCompaniesSave} btn={BtnType.REGULAR}>
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default memo(AddIdeaThemes);

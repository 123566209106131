import React from "react";
import classNames from "classnames";

import { UserInline, UserInlineTypes, Pannel } from "../../../primitives";
import styles from "./speakers.module.scss";
import { useScrollPagination } from "../../../../common/hooks";

const SpeakersComponent = (props) => {
  const { speakers } = props;
  const scrollPagination = useScrollPagination({ pageSize: 5 });

  return (
    <>
      {speakers && speakers[0] && (
        <div className={styles.card}>
          <Pannel title="Speakers" padding="0px">
            <div
              className={styles.wrapper}
              onScroll={scrollPagination.onScroll}
            >
              {speakers.map((item, index) => (
                <div key={index} className={styles.speaker}>
                  <UserInline
                    local
                    first={item.name}
                    type={UserInlineTypes.WITH_NAME}
                    className={styles.name}
                    userId={item.user_id}
                    avatar={item.avatar}
                  />
                  <div className={classNames("caption", styles.title)}>
                    {item.title}
                    {item.company && `, ${item.company}`}
                  </div>
                  <div className={classNames("caption", styles.title)} />
                </div>
              ))}
            </div>
          </Pannel>
        </div>
      )}
    </>
  );
};

export default React.memo(SpeakersComponent);

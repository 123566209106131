import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { differenceInHours, format } from "date-fns";
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc";
import { useSnackbar } from "notistack";

import { checkViewerRole } from "common/checkers/viewerChecker";
import { Button, BtnType } from "../../primitives";
import styles from "./viewEvent.module.scss";
import { getBackgroundImage } from "./viewEvent.constant";
import { eventsGet, eventsPutAttending } from "./viewEvent.action";
import HeaderInfoComponent from "./headerInfo";
import EventDetailsComponent from "./eventDetails";
import SpeakersComponent from "./speakers";
import CompaniesComponent from "./companies";
import VideosComponent from "./videos";
import AttendeesComponent from "./Attendees";
import SlidesComponent from "./slides";
import { Discussion } from "../../shared";
import { CalendarDrop, CalendarBtn } from "../../primitives/addToCalender";
import ShareWithComponent from "../../companies/company/popups/shareWith";
import { useModelPopup } from "../../../common/hooks";
import ThemesComponent from "./themes/themes.component";

const AddToCalendarDropdown = AddToCalendarHOC(CalendarBtn, CalendarDrop);

const ViewEventComponent = () => {
  const dispatch = useDispatch();

  const [bgImage] = useState(getBackgroundImage());
  const [eventForCalendar, setEventForCalendar] = useState();
  const [isCurrentUserAttended, setCurrentAttended] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const { id } = useParams();
  const history = useHistory();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  const {
    authReducer: { session },
    eventsReducer: {
      viewEventReducer: { selectedEvent, eventsShouldUpdate },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(eventsGet({ eventId: id, enqueueSnackbar }));
    }
  }, [id]);

  const isMobile = width <= 720;

  useEffect(() => {
    if (eventsShouldUpdate) {
      dispatch(eventsGet({ eventId: id, enqueueSnackbar }));
    }
  }, [eventsShouldUpdate]);

  useEffect(() => {
    if (selectedEvent && selectedEvent.id) {
      const startDatetime = format(
        new Date(selectedEvent.starts_on),
        "iii LLL dd yyyy OOOO"
      );
      const endDatetime = format(
        new Date(selectedEvent.ends_on),
        "iii LLL dd yyyy OOOO"
      );
      const duration = differenceInHours(endDatetime, startDatetime);
      const event = {
        description: selectedEvent.description,
        duration,
        endDatetime:
          endDatetime && format(new Date(endDatetime), "yyyyMMdd'T'HHmmssxxxx"),
        location: window.location.href, // selectedEvent.location,
        startDatetime: format(
          new Date(startDatetime),
          "yyyyMMdd'T'HHmmssxxxxx"
        ),
        title: selectedEvent.name,
        video_uri: selectedEvent.video_uri,
        slides_uri: selectedEvent.slides_uri,
      };
      setEventForCalendar(event);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (session && selectedEvent && selectedEvent.event_attendees) {
      setCurrentAttended(() => {
        const isAttended = selectedEvent?.event_attendees?.find(
          (user) => user?.user_id === session?.id
        );
        return !!isAttended;
      });
    }
  }, [selectedEvent, session]);

  const handleDeleteAttendClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      eventsPutAttending({
        event_attendee_id: session.id,
        notes: "string",
        attending: false,
        // rating: 0,
        eventId: selectedEvent.id,
        enqueueSnackbar,
      })
    );
  };

  const handleShareWith = (e) => {
    e.preventDefault();
    e.stopPropagation();

    popup.show({
      title: "Share With",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ShareWithComponent
          actionType="shareEvent"
          selectedEvent={selectedEvent}
        />
      ),
    });
  };

  const handleAttendClick = () => {
    dispatch(
      eventsPutAttending({
        event_attendee_id: session.id,
        notes: "string",
        attending: true,
        eventId: selectedEvent?.id,
        enqueueSnackbar,
      })
    );
  };

  const handleEditEvent = () => {
    history.push("/events/new", { eventId: id, mode: "edit" });
  };

  const slidesOrVideoPresent = () => {
    return !!(
      (selectedEvent.video_uri && selectedEvent.video_uri[0]) ||
      (selectedEvent.slides_uri && selectedEvent.slides_uri[0])
    );
  };

  const checkThemesEnabled = () => {
    return !!(
      selectedCouncil?.themes_connected &&
      selectedEvent.theme_events?.length > 0 &&
      (selectedCouncil?.settings.theme_events_enabled ||
        selectedCouncil?.settings.theme_events_enabled === undefined)
    );
  };

  return (
    <div className={styles.contentWrp}>
      <div className={classNames(styles.headerWrp, styles[`img${bgImage}`])}>
        <HeaderInfoComponent
          blockFunctionality={blockFunctionality}
          event={selectedEvent}
        />
      </div>
      <div className={styles.container}>
        <div className={classNames(styles.btnWrp, "row")}>
          <div className="col-12 grid-item">
            {isCurrentUserAttended ? (
              <Button
                btn={BtnType.OUTLINE_LIGHT}
                className={styles.attending}
                icon="icn-checkbox-button"
                onClick={(e) => handleDeleteAttendClick(e)}
              >
                I'm attending
              </Button>
            ) : (
              <Button
                btn={BtnType.TAG_OUTLINE_BLUE}
                onClick={(e) => handleAttendClick(e)}
                className={styles.attend}
              >
                Attend
              </Button>
            )}
            {eventForCalendar && (
              <div className={styles.calender}>
                <AddToCalendarDropdown
                  event={eventForCalendar}
                  items={[
                    SHARE_SITES.GOOGLE,
                    SHARE_SITES.ICAL,
                    SHARE_SITES.OUTLOOK,
                  ]}
                />
              </div>
            )}
            <Button
              btn={BtnType.OUTLINE_LIGHT}
              onClick={handleShareWith}
              className={styles.share}
            >
              Share
            </Button>
            <div className={styles.editEvent}>
              {!blockFunctionality && (
                <Button
                  btn={BtnType.FRAME_LESS}
                  onClick={handleEditEvent}
                  icon="icn-edit-button"
                >
                  Edit Event
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className={styles.mobileLayout}>
          <EventDetailsComponent event={selectedEvent} />
          <div className={styles.mobileCard}>
            {selectedEvent.video_uri && selectedEvent.video_uri[0] && (
              <VideosComponent event={selectedEvent} />
            )}
          </div>
          <div className={styles.mobileCard}>
            {selectedEvent.slides_uri && selectedEvent.slides_uri[0] && (
              <SlidesComponent event={selectedEvent} />
            )}
          </div>
          <SpeakersComponent
            eventId={id}
            speakers={selectedEvent.event_speakers}
          />
          <CompaniesComponent
            event={selectedEvent}
            className={styles.mobileCard}
          />
          <AttendeesComponent eventId={id} />

          {checkThemesEnabled() && <ThemesComponent eventId={id} />}
          {isMobile && (
            <Discussion
              eventId={id}
              type="Event"
              reducerProp="eventDiscussions"
              isCurrentUserAttended={isCurrentUserAttended}
            />
          )}
        </div>
        <div className={styles.contentBox}>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className={styles.card}>
                <EventDetailsComponent event={selectedEvent} />
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-4">
              <div className={styles.card}>
                {!isMobile && (
                  <Discussion
                    eventId={id}
                    type="Event"
                    reducerProp="eventDiscussions"
                    isCurrentUserAttended={isCurrentUserAttended}
                  />
                )}
              </div>
            </div>
            {selectedEvent.event_speakers &&
            selectedEvent.event_speakers.length ? (
              <div className="col-12 col-md-6 col-lg-4">
                <div className={styles.card}>
                  <SpeakersComponent
                    eventId={id}
                    speakers={selectedEvent.event_speakers}
                  />
                </div>
              </div>
            ) : null}
            {!slidesOrVideoPresent(selectedEvent) && (
              <>
                {selectedEvent &&
                selectedEvent.companies &&
                selectedEvent.companies.length ? (
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className={styles.card}>
                      <CompaniesComponent
                        event={selectedEvent}
                        className={styles.card}
                      />
                    </div>
                  </div>
                ) : null}
                <div className="col-12 col-md-6 col-lg-4">
                  <div className={styles.card}>
                    <AttendeesComponent eventId={id} />
                  </div>
                </div>

                {checkThemesEnabled() && (
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className={styles.card}>
                      <ThemesComponent eventId={id} />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className={styles.lgCard}>
                {selectedEvent.video_uri && selectedEvent.video_uri[0] && (
                  <VideosComponent event={selectedEvent} />
                )}
              </div>
              <div className={styles.lgCard}>
                {selectedEvent.slides_uri && selectedEvent.slides_uri[0] && (
                  <SlidesComponent event={selectedEvent} />
                )}
              </div>
            </div>
            <div className="col-12 col-md-4">
              {slidesOrVideoPresent(selectedEvent) && (
                <>
                  {selectedEvent &&
                  selectedEvent.companies &&
                  selectedEvent.companies.length ? (
                    <>
                      <div className={styles.card}>
                        <CompaniesComponent
                          event={selectedEvent}
                          className={styles.card}
                        />
                      </div>
                    </>
                  ) : null}

                  {selectedEvent &&
                  selectedEvent.event_attendees &&
                  selectedEvent.event_attendees.length ? (
                    <div className={styles.card}>
                      <AttendeesComponent eventId={id} />
                    </div>
                  ) : null}
                  {checkThemesEnabled() && <ThemesComponent eventId={id} />}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEventComponent;

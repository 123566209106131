import React, { useState } from "react";
import { Link } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button } from "modules/primitives";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import { customStyles } from "modules/reporting/components/generateReport/constants";
import styles from "modules/reporting/customReportsStyles.module.scss";

const FunnelListThemes = ({ themes, companyName }) => {
  const [open, setOpen] = useState(false);

  const hasShowMore = themes?.length > 3;

  const handleShowMore = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  if (!themes?.length) {
    return '-';
  }

  return (
    <div className="d-flex flex-wrap">
      {
        themes
          .slice(0, hasShowMore ? 3 : themes?.length)
          .map(t => (
            <div key={`modal_theme_${t?.name || t}_${t.id}`} className="mr-2">
              <Link
                to={`themes/individual_page/${t.id}/overview`}
                className={styles.link}
                target="_blank"
              >
                {t?.name}
              </Link>,
            </div>
          ))
      }
      {
        hasShowMore ? (
          <span
            onClick={handleShowMore}
            className={`${styles.showMoreData} d-block`}
          >+ {themes.length - 3}</span>
        ) : null
      }
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle style={customStyles.title}>Themes related with: {companyName}</DialogTitle>
        <DialogContent>
          <div className="d-flex flex-column">
            {
              themes.map((t, idx) => (
                <Link
                  to={`themes/individual_page/${t.id}/overview`}
                  className={styles.link}
                  key={`modal_theme_${t?.name || t}_${idx}`}
                  target="_blank"
                >
                  {t?.name}
                </Link>
              ))
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(FunnelListThemes);

import React, { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import styles from "./styles.module.scss";
import Icon from "../../icon/icon.component";
import { smSize } from "../../icon";
import useS3FileUpload from "../../hooks/s3FileUplodar.hook";
import { sanitizeName } from "common/helper";
import LinearProgressWithLabel from "../linearProgressWithLabel";
import { BtnType, Button } from "modules/primitives";

const AttachFileComponent = (props) => {
  const {
    customText = "",
    disabled,
    files,
    replaceImage,
    uploadDirName,
    onChange,
    image,
    triggerFromParrent,
    droppedFilesFromParent,
    customFormats = false,
    isBtnInstance,
    customFormatError,
  } = props;

  const inputRef = useRef(null);
  const uploadBlockRef = useRef(null);
  const { fileUpload, fileDelete } = useS3FileUpload();
  const [uploadValues, setUploadValues] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const allowedExtensions = customFormats || [
    "png",
    "jpg",
    "jpeg",
    "gif",
    "tiff",
    "bmp",
    "eps",
    "raw",
    "cr2",
    "nef",
    "orf",
    "sr2",
    "doc",
    "docx",
    "dotx",
    "dot",
    "rtf",
    "txt",
    "xlxs",
    "xlsx",
    "xls",
    "csv",
    "ppt",
    "pptx",
    "pdf",
  ];

  const getExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  const onUploadClick = () => {
    inputRef.current.click();
  };

  const removeFile = async (url, indx) => {
    try {
      await fileDelete(url).then(() => {
        onChange(null);
        replaceImage(indx);
      });
    } catch (error) {
      console.log("---------error on file delete from aws", error);
    }
  };

  const uploadHandler = async (e, action) => {
    // e.preventDefault();
    if (action === "drag" && uploadBlockRef?.current) {
      uploadBlockRef.current.style.border = "1px solid #7b8794";
      return;
    }

    let file;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      file = e.target.files[0];
    }

    if (action === "drop") {
      // on drop we just go to previous border styles
      uploadBlockRef.current.style.border = "1px solid #e4e7eb";

      if (!e || !e.dataTransfer?.items?.length) {
        return;
      }

      file = e.dataTransfer.items[0].getAsFile();
    }

    if (action === "parent") {
      // on drop we just go to previous border styles
      uploadBlockRef.current.style.border = "1px solid #e4e7eb";

      if (!e || !droppedFilesFromParent?.length) {
        return;
      }

      file = droppedFilesFromParent[0];
    }

    if (!file) {
      return;
    }
    if (
      file.size <= 500000000 &&
      allowedExtensions.indexOf(getExtension(file.name.toLowerCase())) !== -1
    ) {
      setLoading(true);
      const sanitizedFileName = sanitizeName(file.name);
      Object.defineProperty(file, "name", {
        writable: true,
        value: sanitizeName(file.name),
      });
      await fileUpload(
        file,
        uploadDirName,
        setUploadValues,
        sanitizedFileName,
        0
      )
        .then((res) => {
          if (res && res.location) {
            setLoading(false);
            onChange(res.location, file.name);
            setUploadValues([]);
          }

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      if (allowedExtensions.indexOf(getExtension(file.name)) === -1) {
        enqueueSnackbar(customFormatError || "Extenstion is not allowed", {
          variant: "error",
        });
      }
      if (file.size > 500000000) {
        enqueueSnackbar(
          "We could not upload your image because your file size is too big. Please make sure the file is less than 500 MB",
          {
            variant: "error",
          }
        );
      }
      setLoading(false);
      e.target.value = "";
    }
  };
  useEffect(() => {
    if (triggerFromParrent) {
      uploadHandler(droppedFilesFromParent, "parent");
    }
  }, [triggerFromParrent]);
  const prepareFileName = (name) => {
    if (name.length > 30) {
      return name.slice(0, 12) + " ... " + name.slice(-12);
    }
    return name;
  };
  return (
    <div className={styles.dashedContainer}>
      <div className={styles.contentWrapper}>
        <input
          ref={inputRef}
          type="file"
          id="imgUpload"
          style={{ display: "none" }}
          onChange={(e) => uploadHandler(e, "click")}
        />
        <>
          {isBtnInstance ? (
            <div
              className={styles.attachBlock}
              ref={uploadBlockRef}
              onDrop={(e) => uploadHandler(e, "drop")}
              onDragOver={(e) => uploadHandler(e, "drag")}
            >
              <Button
                btn={BtnType.REGULAR}
                icon="arrow-up2"
                onClick={onUploadClick}
              >
                {customText || "Attach File"}
              </Button>
            </div>
          ) : (
            <div
              className={styles.attachBlock}
              ref={uploadBlockRef}
              onClick={onUploadClick}
              onDrop={(e) => uploadHandler(e, "drop")}
              onDragOver={(e) => uploadHandler(e, "drag")}
            >
              <label className={styles.attachLabel}>
                <Icon {...smSize} icon="attachment" />
                <div
                  style={files.length ? { color: "#2d83ae" } : null}
                  className={styles.attachText}
                >
                  {customText || "Attach File"}
                </div>
              </label>
            </div>
          )}
          {loading && (
            <LinearProgressWithLabel value={uploadValues[0]?.progress || 0} />
          )}
          <div
            style={files.length ? { color: "#2d83ae" } : null}
            className={styles.filesList}
          >
            {files.map((file, index) => {
              if (file._destroy === true) {
                return null;
              }
              return (
                <div className={styles.fileNameCont} key={file.uri}>
                  <div>{prepareFileName(file?.name)}</div>
                  <Icon
                    {...smSize}
                    icon="icn-button-delete"
                    className={styles.deleteIcon}
                    onClick={() => removeFile(file.uri, index)}
                  />
                </div>
              );
            })}
          </div>
        </>
      </div>
    </div>
  );
};

export default AttachFileComponent;

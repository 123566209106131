import React, { useState } from "react";
import { format } from "date-fns";

import {
  BtnType,
  Button,
  MoreOptions,
  UserInline,
  UserInlineTypes,
} from "../../../modules/primitives";
import styles from "./discussionMsg.module.scss";
import RichEditor from "../richEditor";
import { ACTIVITY_OPTIONS } from "../../constants";

const DiscussionMsg = ({
  details,
  anonymous,
  myMessage,
  handleMoreOptionClick,
  setEditActive,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState(details.content);

  const handleOptionsClick = (val) => {
    if (val === 0) {
      setEditMode(true);
      setEditActive(true);
      return;
    }

    handleMoreOptionClick(val, details);
  };

  const confirmEdit = () => {
    handleMoreOptionClick(0, {
      ...details,
      subject: message,
    });
    setEditActive(false);
    setEditMode(false);
  };

  return (
    <div className={styles.msgWrp}>
      {details.deleted ? (
        <div className="text-muted">This message has been deleted</div>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between pr-5">
            <div className="d-flex align-items-center">
              {anonymous ? (
                <div className={styles.anonymous}>Anonymous</div>
              ) : (
                <UserInline
                  userId={details.sender_id}
                  avatar={details.sender.avatar}
                  type={UserInlineTypes.WITH_NAME}
                  first={details.sender.full_name.split(" ")[0]}
                  last={details.sender.full_name.split(" ")[1]}
                  showMine={false}
                  local
                />
              )}
            </div>
            <div className="d-flex align-items-center">
              <span className={styles.date}>
                {format(new Date(details.sent_at), "eeee, MMM do, yyyy h:mm a")}
              </span>
              {myMessage && (
                <div className="ml-3">
                  <MoreOptions
                    options={ACTIVITY_OPTIONS}
                    onClick={handleOptionsClick}
                  />
                </div>
              )}
            </div>
          </div>
          {editMode ? (
            <>
              <div className="mt-3">
                <RichEditor
                  initialValue={message}
                  discussionId={details.discussion_id}
                  updateValue={setMessage}
                />
              </div>
              <div className="d-flex justify-content-end mt-2">
                <Button btn={BtnType.REGULAR} onClick={confirmEdit}>
                  SAVE
                </Button>
                <Button
                  className="ml-2"
                  btn={BtnType.FRAME_LESS}
                  onClick={() => {
                    setEditMode(false);
                    setEditActive(false);
                  }}
                >
                  CANCEL
                </Button>
              </div>
            </>
          ) : (
            <div
              className={styles.msg}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(DiscussionMsg);

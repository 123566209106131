const checkForDeleteMention = (mode, themeMentionsAtributes, inputText) => {
  let elementToSearch;
  const filtredThemeMentnion = themeMentionsAtributes.filter((mention) => {
    elementToSearch = `id="${mention.mention_id}">`;
    if (inputText.indexOf(elementToSearch) !== -1) {
      return true;
    }
    return false;
  });

  return filtredThemeMentnion;
};
export default checkForDeleteMention;

const createProjOptions = [
  {
    val: "relatedProject",
    text: "Link to Related Project",
  },
  {
    val: "newProject",
    text: "Create New Project ",
  },
];
export default createProjOptions;

import React, { useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import checkUniqName from "common/checkUniqName";
import checkDuplicateName from "common/checkDuplicateName";

import findCurrentCustomFieldGroup from "../../../findCustomFieldGroup";
import { Label, TextBox } from "../../../../modules/primitives";
import styles from "./styles.module.scss";
import { model, validation } from "./constant";

const NewLinkComponent = (props) => {
  const {
    formRef,
    handleOnSubmit,
    data,
    fromAdmin = false,
    rfiInstance,
    uniqName,
    selectedCustomFieldGroup,
    indexOfCfGroup,
  } = props;
  const {
    companiesReducer: {
      companyReducer: { newCustomFieldGroups },
    },
    adminCustomFieldsReducer: { customFieldGroups },
  } = useSelector((state) => state);

  const [titleError, setTitleError] = useState("");
  const [initModel, setInitModel] = useState({ ...model });
  const initValues = data
    ? {
        field_type: data.field_type,
        link: data.field_value,
        title: data.field_name,
        field_placeholder: data.field_placeholder,
      }
    : null;

  const onSubmit = (values) => {
    const cfGroup =
      findCurrentCustomFieldGroup(
        selectedCustomFieldGroup,
        customFieldGroups,
        newCustomFieldGroups,
        indexOfCfGroup,
        data
      ) || [];
    if (!values.title.length) {
      const field_name = checkDuplicateName(cfGroup, "link");
      values.title = field_name;
    } else if (
      !checkUniqName(values.title, cfGroup) &&
      values.title?.trim()?.toLowerCase() !== data?.field_name?.trim()?.toLowerCase()
    ) {
      setTitleError("Field name must be unique. Please rename this field");
      return;
    }
    setTitleError("");

    values.field_value = values.link;
    handleOnSubmit(values);
    values.title = "";
  };

  return (
    <div className={styles.wrapper}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initValues || initModel}
        validationSchema={!fromAdmin && validation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          onSubmit(values);
        }}
      >
        {({ handleSubmit, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <Label>Title (optional)</Label>
            <TextBox
              disabled={rfiInstance}
              type="text"
              name="title"
              placeholder="Add title"
              formProps={formikprops}
              className={styles.textBox}
            />
            {titleError.length > 1 && (
              <div className={styles.errorBox}>
                <sup>*</sup>
                {titleError}
              </div>
            )}
            <>
              <Label>Instructions (Optional)</Label>
              <TextBox
                type="text"
                name="field_placeholder"
                placeholder="Add instructions"
                // formProps={{ ...formikprops }}
                formProps={formikprops}
                className={styles.textBox}
              />
            </>
            {!fromAdmin && (
              <>
                <Label>Link</Label>
                <TextBox
                  type="text"
                  name="link"
                  placeholder="Enter a URL"
                  formProps={formikprops}
                  className={styles.textBox}
                />
              </>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(NewLinkComponent);

export const PEOPLE_GET_MEMBERS_ATTEMPT = "PEOPLE_GET_MEMBERS_ATTEMPT";
export const peopleMembersGet = (payload) => ({
  type: PEOPLE_GET_MEMBERS_ATTEMPT,
  payload,
});
export const PEOPLE_GET_MEMBERS_SUCCESS = "PEOPLE_GET_MEMBERS_SUCCESS";
export const peopleMembersGetSuccess = (payload) => ({
  type: PEOPLE_GET_MEMBERS_SUCCESS,
  payload,
});

export const PEOPLE_ADD_MEMBERS_ATTEMPT = "PEOPLE_ADD_MEMBERS_ATTEMPT";
export const peopleMembersAdd = (payload) => ({
  type: PEOPLE_ADD_MEMBERS_ATTEMPT,
  payload,
});
export const PEOPLE_ADD_MEMBERS_SUCCESS = "PEOPLE_ADD_MEMBERS_SUCCESS";
export const peopleMembersAddSuccess = (payload) => ({
  type: PEOPLE_ADD_MEMBERS_SUCCESS,
  payload,
});

export const PEOPLE_ADD_MEMBERS_FAIL = "PEOPLE_ADD_MEMBERS_FAIL";

export const PEOPLE_EDIT_MEMBERS_ATTEMPT = "PEOPLE_EDIT_MEMBERS_ATTEMPT";
export const peopleMembersEdit = (payload) => ({
  type: PEOPLE_EDIT_MEMBERS_ATTEMPT,
  payload,
});
export const PEOPLE_EDIT_MEMBERS_SUCCESS = "PEOPLE_EDIT_MEMBERS_SUCCESS";
export const peopleMembersEditSuccess = (payload) => ({
  type: PEOPLE_EDIT_MEMBERS_SUCCESS,
  payload,
});

export const RESET_PEOPLE_LOADING = "RESET_PEOPLE_LOADING";
export const resetPeopleLoading = (payload) => ({
  type: RESET_PEOPLE_LOADING,
  payload,
});

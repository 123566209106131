import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import { apiStatus, errorHandler, httpPost } from "../../../common/httpCall";
import { CRUNCHBASE_COMPANY_ATTEMPT } from "./crunchbase.action";
import { addCrunchCompanyToState } from "../companyMain/companyMain.action";

const epicCrunchbaseCompanyAttempt = (action$) =>
  action$.pipe(
    ofType(CRUNCHBASE_COMPANY_ATTEMPT),
    switchMap(({ payload: { values, selectedCouncil, enqueueSnackbar } }) =>
      httpPost({
        call: "council_companies",
        apiVersion: "v2",
        data: values.company_id ? {
          council_id: selectedCouncil.id,
          relationship_owner_id: values?.relationship_owner_id,
          company_name: values.name,
          company_website: values.website,
          company_id: values.company_id,
        } : {
          council_id: selectedCouncil.id,
          relationship_owner_id: values?.relationship_owner_id,
          company_name: values.name,
          company_website: values.website,
        },
      }).pipe(
        map((result) => {
          if (result.status === apiStatus.SUCCESS) {
            enqueueSnackbar("Successfully added company.", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Something wrong happened", {
              variant: "error",
            });
          }

          return addCrunchCompanyToState(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const crunchbaseEpic = combineEpics(epicCrunchbaseCompanyAttempt);

export default crunchbaseEpic;

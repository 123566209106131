export const GET_USER_TEAMS_ATTEMPT = "GET_USER_TEAMS_ATTEMPT";
export const getUserTeams = (payload) => ({
  type: GET_USER_TEAMS_ATTEMPT,
  payload,
});
export const GET_USER_TEAMS_SUCCESS = "GET_USER_TEAMS_SUCCESS";
export const getUserTeamsSuccess = (payload) => ({
  type: GET_USER_TEAMS_SUCCESS,
  payload,
});

export const GET_ALL_TEAMS_ATTEMPT = "GET_ALL_TEAMS_ATTEMPT";
export const getAllTeamsAttempt = (payload) => ({
  type: GET_ALL_TEAMS_ATTEMPT,
  payload,
});

export const GET_ALL_TEAMS_SUCCESS = "GET_ALL_TEAMS_SUCCESS";
export const getAllTeamsSuccess = (payload) => ({
  type: GET_ALL_TEAMS_SUCCESS,
  payload,
});

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import Select from "react-dropdown-select";

import { BtnType, Button, CheckBox, CheckType, DatePiker, Label, MultiSelect, TextBox } from "modules/primitives";
import styles from "modules/projects/project/manageProject/details/details.module.scss";
import { useModelPopup } from "../../../../../../common/hooks";
import ProjectFieldsEdit from "modules/projects/project/overview/projectDetails/projectFieldsEdit.component";
import { httpPut } from "../../../../../../common/httpCall";
import { projectTaskUpdateSuccess } from "modules/projects/project/tasks/tasks.action";
import { makeFormikObj, makeProjectField, makeStages } from "../../../../../../common/helper";
import { getProjectFieldGroups } from "modules/projects/project/project.action";
import { METRICS_NAMES_ENUM } from "application/app.constants";
import { currencySymbolObj } from "../../../../../../common/constants";

const TaskGroupFields = ({ group, task, selectedProject, isProjectWideFromStage, getUpdatedGroupData, blockFunctionality }) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const [model, setModel] = useState(null);

  const handleSaveFields = (values) => {
    httpPut({
      call: `projects/${selectedProject.id}/tasks/${task.id}/project_fields`,
      data: values,
    })
      .subscribe((res) => {
        if (res.response) {
          dispatch(projectTaskUpdateSuccess({...res.response, directUpdate: true, isProjectWideFromStage}));
          enqueueSnackbar("Success", {
            variant: 'success',
          });
          const timeout = setTimeout(() => {
            getUpdatedGroupData();
            dispatch(getProjectFieldGroups({
              projectId: selectedProject.id,
            }))
            clearTimeout(timeout);
          }, 300)
        }
      })
  }

  useEffect(() => {
    if (!group.id && group.type !== 'project_metric') {
      const fields = [...group.project_fields]
        .map(makeFormikObj)
        .reduce((acc, elem) => ({ ...acc, [elem.project_field_template_id || elem.id]: elem.values }), {});

      setModel(fields);
      return
    }

    if (group.type === 'project_metric') {
      setModel(group.attributes);
      return;
    }

    setModel({});
  }, [group]);

  const renderField = useCallback((formProps) => {
    if (!formProps) {
      return null;
    }

    const field = group.project_fields[0];
    const { values, setFieldValue } = formProps;

    if (field.field_type === 'dropdown') {
      const newField = makeFormikObj(field);

      return (
        <>
          <Label>{newField.field_name}</Label>
          <MultiSelect
            name={newField.project_field_template_id || newField.id}
            values={values[newField.project_field_template_id || newField.id]}
            labelField="name"
            valueField="id"
            onChange={val => setFieldValue(newField.project_field_template_id || newField.id, val)}
            onClear={() => setFieldValue(newField.project_field_template_id || newField.id, [])}
            options={newField.option_values}
            multi={newField.multiselect}
            placeholder={newField.field_placeholder}
            disabled={blockFunctionality}
            withClear
          />
        </>
      )
    }

    if (field.field_type === "date") {
      return (
        <>
          <Label>{field.field_name}</Label>
          <div className="d-flex align-items-center">
            <DatePiker
              value={values[field.project_field_template_id || field.id]}
              name={field.project_field_template_id || field.id}
              formProps={{ ...formProps, values }}
              className="w-100"
              disabled={blockFunctionality}
              withClear
              onChange={(value) => setFieldValue(field.project_field_template_id || field.id, value)}
            />
          </div>
        </>
      )
    }

    if (field.field_type === "number" || field.field_type === "currency") {
      return (
        <>
          <Label>{field.field_name}</Label>
          <div className="d-flex align-items-center">
            <TextBox
              type="number"
              name={field.project_field_template_id || field.id}
              placeholder={field.field_placeholder}
              formProps={{ ...formProps, values }}
              className="w-100"
              value={values[field.project_field_template_id || field.id]}
              disabled={blockFunctionality}
              withClear
            />
            <div className="d-flex ml-2">
              {
                field.option_values
                  ? field.option_values[0]?.name ? currencySymbolObj[field.option_selected[0].name] : currencySymbolObj[field.option_selected[0]]
                  : null
              }
            </div>
          </div>
        </>
      )
    }

    return (
      <>
        {
          field.field_type === "text" && (
            <>
              <Label>{field.field_name}</Label>
              {
                field.big_box_text ? (
                  <div className="d-flex flex-column justify-content-end">
                    <textarea
                      className="w-100 border small p-2"
                      value={values[field.project_field_template_id || field.id]}
                      placeholder={field.field_placeholder}
                      onChange={(evt) => setFieldValue(field.project_field_template_id || field.id, evt.target.value)}
                      rows="5"
                      disabled={blockFunctionality}
                    ></textarea>
                    <span
                      style={{ color: "#2d83ae" }}
                      className="cursor-pointer small"
                      onClick={() => {
                        setFieldValue(field.project_field_template_id || field.id, '')
                      }}
                    >clear</span>
                  </div>
                ) : (
                  <TextBox
                    name={field.project_field_template_id || field.id}
                    placeholder={field.field_placeholder}
                    formProps={{ ...formProps, values }}
                    className="w-100"
                    value={values[field.project_field_template_id || field.id]}
                    disabled={blockFunctionality}
                    withClear
                  />
                )
              }
            </>
          )
        }
      </>
    )
  }, [group, formRef, blockFunctionality]);

  const showGroupModal = useCallback(() => {
    if (blockFunctionality) return;
    const newGroup = {
      ...group,
      project_fields: group.project_fields.map(makeProjectField),
    }

    popup.show({
      title: "Related Project Field Group",
      component: <ProjectFieldsEdit selectedGroup={newGroup} taskInstance handleSave={handleSaveFields} />,
    });
  }, [group, handleSaveFields, blockFunctionality]);

  const handleSubmit = (values) => {
    let props;

    if (group.type === 'project_metric') {
      const value = values[group.project_metric_attribute] ?? '';
      const field_value = String(value).length
        ? value
        : null;

      props = {
        project_metric_value: {
          project_attribute_name: group.project_metric_attribute,
          field_value,
        }
      }
    } else {
      const getField = group.project_fields[0];
      let otherProps;

      if (getField.project_field_template_id) {
        otherProps = {
          project_field_template_id: getField.project_field_template_id
        }
      } else {
        otherProps = {
          id: getField.id
        }
      }

      const key = getField.project_field_template_id || getField.id

      if (getField.field_type === 'text') {
        otherProps = {
          ...otherProps,
          field_value: String(values[key] || '').length ? values[key] : null,
        }
      }

      if (getField.field_type === 'number' || getField.field_type === 'currency') {
        otherProps = {
          ...otherProps,
          field_number: values[key],
          option_selected: getField.option_values[0]
            ? [getField.option_values[0]]
            : [],
        }
      }

      if (getField.field_type === 'date') {
        otherProps = {
          ...otherProps,
          field_date: values[key],
        }
      }

      if (getField.field_type === 'dropdown') {
        otherProps = {
          ...otherProps,
          option_selected: values[key].map(el => el.id),
        }
      }

      props = {
        project_field_template_values: [otherProps]
      }
    }

    handleSaveFields(props)
  };

  const renderMetrics = useCallback((formProps) => {
    switch (group.project_metric_attribute) {
      case 'tarr_percentage':
      case 'prototype_investment_usd':
      case 'savings_usd':
      case 'investment_usd':
      case 'annual_incremental_investment_usd':
      case 'current_spending_usd':
      case 'headcount_op_crew':
      case 'threed_printing_replications':
        return (
          <div className="ml-1 flex-grow-1">
            <Label>{METRICS_NAMES_ENUM[group.project_metric_attribute]}</Label>
            <TextBox
              type="number"
              placeholder={METRICS_NAMES_ENUM[group.project_metric_attribute]}
              className="w-100"
              name={group.project_metric_attribute}
              formProps={formProps}
              min={0}
              disabled={blockFunctionality}
              withClear
            />
          </div>
        )

      case "job_1_date":
      case "expected_job_1_date":
      case "implementation_date":
      case "next_stage_start_on":
      case "prototype_job_1_date":
      case "proposal_for_prototype_expected_job_1_date":
      case "proposal_for_implementation_date":
      case "ready_for_replication_date":
      case "prototype_date":
        return (
          <div className="ml-1 flex-grow-1">
            <Label>{METRICS_NAMES_ENUM[group.project_metric_attribute]}</Label>
            <DatePiker
              value={formProps.values[group.project_metric_attribute]}
              name={group.project_metric_attribute}
              formProps={formProps}
              onChange={(e) => {
                formProps.setFieldValue(group.project_metric_attribute, e || null);
              }}
              className={styles.textBox}
              disabled={blockFunctionality}
              withClear
            />
          </div>
        )
      case 'next_stage_end_on':
        const stages = makeStages(new Date().getFullYear());
        const value = formProps.values.next_stage_quarter
          ? stages.filter(s => s.id === formProps.values.next_stage_quarter)
          : []

        return (
          <div className="ml-1 flex-grow-1">
            <Label>Next Stage Change</Label>
            <Select
              label="Next Stage Change"
              placeholder="Next Stage Change"
              labelField="name"
              valueField="id"
              name="next_stage_end_on"
              multi={false}
              options={stages}
              onChange={(val) => {
                formProps.setFieldValue("next_stage_end_on", val[0]?.date || null);
              }}
              values={value || []}
              disabled={blockFunctionality}
              clearable
            />
          </div>
        )
      default:
        return null;
    }
  }, [group, blockFunctionality]);

  const makeCheckBoxProps = useCallback(() => {
    if (group.status !== 'empty') {
      return {
        isChecked: true,
        checkType: group.status === 'partial' ? CheckType.HALF_BLUE : CheckType.GREEN,
        readOnly: blockFunctionality,
      };
    }

    return { isChecked: false, readOnly: blockFunctionality };
  }, [group, blockFunctionality]);

  if (!model) {
    return null;
  }

  return (
    <div className="ml-3 mr-3 w-100">
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={model}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, setFieldValue, values, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column">
              {
                group.type !== 'project_metric' && group.id && (
                  <span className="small">Related Project Field Group</span>
                )
              }
              {
                group.type !== 'project_metric' && !group.id && (
                  <span className="small mb-2">Related Project Field</span>
                )
              }
              {
                group.type === 'project_metric' ? (
                  renderMetrics({setFieldValue, values, ...formikprops})
                ) : !group.id ? renderField({ setFieldValue, values, ...formikprops })
                  : (
                    <div className="d-flex justify-content-between">
                      <span
                        onClick={showGroupModal}
                        className="small cursor-pointer text-info mt-2"
                      >Click to complete all fields in <strong>"{group.name}"</strong></span>
                      <div>
                        <CheckBox {...makeCheckBoxProps()} />
                      </div>
                    </div>
                  )
              }
            </div>
            {
              (group.type === "project_metric" || !group.id) && (
                <div className="d-flex justify-content-center mt-2">
                  <Button
                    btn={BtnType.REGULAR}
                    type="submit"
                    disabled={blockFunctionality}
                  >
                    SAVE
                  </Button>
                </div>
              )
            }
          </form>
        )}
      </Formik>
    </div>
  )
};

export default React.memo(TaskGroupFields);

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useSnackbar } from "notistack";

import styles from "./projectRow.module.scss";
import {
  UserInline,
  UserInlineTypes,
  ProgressBar,
  ProjectState,
  MultiUsers,
  CompanyAvatar,
} from "../../../primitives";
import { projectDiscussionsReset } from "../../../../common/actions/discussions.action";

const ProjectRowComponent = ({ project, tab, selectedCouncil, history }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [seeAll, setSeeAll] = useState(false);
  const listLenght = useMemo(() => seeAll ? project.project_companies.length : 4, [
    project.project_companies,
    seeAll,
  ]);

  const handleProjectClick = (evt) => {
    if (!project.current_user_has_access) {
      evt.preventDefault();
      enqueueSnackbar("Sorry, you don't have access to that project", {
        variant: "error",
      });
      return;
    }

    dispatch(projectDiscussionsReset());
  };

  const owners = useMemo(() => {
    if (project?.project_members?.length) {
      return project?.project_members
        .filter((m) => m.user_role === "owner")
        .map((m) => ({ ...m, ...m.user }));
    }

    return [];
  }, [project]);

  const handleCompanyClick = (evt, company) => {
    evt.preventDefault();
    evt.stopPropagation();

    history.push(`/companies/${company.slug || company.id}`);
  };

  return (
    <Link
      to={`/projects/${project?.id}`}
      onClick={(evt) => handleProjectClick(evt)}
    >
      <div
        className={`${styles.gridRow} ${
          !project.current_user_has_access && tab === "all_projects"
            ? styles.membersOnly
            : ""
        } `}
      >
        <div
          className={`${
            selectedCouncil?.traction_tag === "firmenich"
              ? styles.projectFull
              : styles.project
          }`}
        >
          {project.name}
        </div>
        {selectedCouncil?.traction_tag !== "firmenich" ? (
          <div className={styles.activity}>
            {project.last_activity?.details || "No activity yet."}
          </div>
        ) : null}
        {selectedCouncil?.traction_tag === "ford" ? (
          <>
            <div className={`${styles.plantName} `}>{project?.plant?.name}</div>
            <div className={`${styles.area} `}>
              {project.areas?.map((area) => {
                return <div>{area?.name}</div>;
              })}
            </div>
          </>
        ) : null}

        <div className={styles.activityDate}>
          {project.last_activity?.created_at || "-"}
        </div>
        <div className={styles.owner}>
          {owners?.length > 1 ? (
            <MultiUsers
              users={owners}
              total={owners.length}
              dontShowRemaining
            />
          ) : owners?.length ? (
            <UserInline
              userId={owners[0].user.id}
              avatar={owners[0].user.avatar}
              first={owners[0].user.first_name}
              last={owners[0].user.last_name}
              type={UserInlineTypes.WITH_NAME}
              dontRequest
              className="userNameWrap"
            />
          ) : null}
        </div>
        <div className={styles.company}>
          {selectedCouncil?.traction_tag === "firmenich" ? (
            <>
              {project.project_companies?.slice(0, listLenght).map((elem) => (
                <div
                  key={elem.company.id}
                  className={`d-flex align-items-center ${styles.companyLink}`}
                  onClick={(evt) => handleCompanyClick(evt, elem.company)}
                >
                  <div className={styles.logoCon}>
                    <CompanyAvatar
                      imgSrc={elem.company?.logo}
                      name={elem.company?.name}
                      hasBorderRadius
                    />
                  </div>
                  <div className="ml-2 text-left small">
                    {elem.company.name}
                  </div>
                </div>
              ))}
              {project.project_companies.length > 6 && (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSeeAll(!seeAll);
                  }}
                  className={styles.showAll}
                >
                  {seeAll
                    ? "HIDE"
                    : `+${project.project_companies?.length - listLenght} More`}
                </div>
              )}
            </>
          ) : (
            project.companies_count || 0
          )}
        </div>
        <div
          className={classNames(
            styles.access,
            tab === "my_projects" && "hide"
          )}
        >
          {project?.access}
        </div>
        {/* <div */}
        {/*  className={classNames( */}
        {/*    styles.myTask, */}
        {/*    tab === 'all_projects' && 'hide', */}
        {/*  )} */}
        {/* > */}
        {/*  {project.my_tasks_total} */}
        {/* </div> */}
        <div className={styles.progress}>
          <ProgressBar
            width="100%"
            tot={100}
            fill={project.total_task_progress}
            showTot={false}
            showPrecentage
          />
        </div>
        <div className={classNames(styles.progress, styles.priority)}>
          {project.priority}
        </div>
        <div className={styles.status}>
          <ProjectState state={project.status} />
        </div>
      </div>
    </Link>
  );
};

export default ProjectRowComponent;

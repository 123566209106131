import React, { useState } from "react";

import styles from "modules/reporting/customReportsStyles.module.scss";
import DialogTitle from "@mui/material/DialogTitle";
import { customStyles } from "modules/reporting/components/generateReport/constants";
import DialogContent from "@mui/material/DialogContent";
import { Button } from "modules/primitives";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const DocumentsListComponent = ({ documents = [], rowDetails, config }) => {
  const [open, setOpen] = useState(false);

  const hasShowMore = documents?.length > 2;

  const handleShowMore = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  if (!documents?.length) {
    return '-';
  }

  return (
    <div className="d-flex flex-wrap w-100">
      {
        documents
          .slice(0, hasShowMore ? 2 : documents?.length)
          .map(d => (
            <a href={d.uri} target="_blank" title={d.name} className={styles.docStyles} key={d.id}>
              {d?.name}
            </a>
          ))
      }
      {
        hasShowMore ? (
          <span
            onClick={handleShowMore}
            className={`${styles.showMoreData} d-block mt-2`}
          >+ {documents.length - 2}</span>
        ) : null
      }
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle style={customStyles.title}>{rowDetails.name} - {config.name}</DialogTitle>
        <DialogContent>
          <div className="d-flex flex-column flex-wrap">
            {
              documents
                .map((d) => (
                  <a
                    href={d.uri}
                    target="_blank"
                    className={styles.docStyles}
                    key={`modal_${d.id}`}
                  >
                    {d?.name}
                  </a>
                ))
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(DocumentsListComponent);

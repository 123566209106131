import * as Yup from "yup";

const initModel = {
  field_type: "text",
  field_name: "",
  instructions: "",
  projectTemplate: [],
  options: [""],
  position: 1,
  access: "",
  id: "",
  multiselect: false,
  teams: [],
  members: [],
  existingPositions: [],
  big_box_text: false,
};

const validation = Yup.object().shape({
  field_name: Yup.string().required("Field name is required"),
  position: Yup.number().required("This field is required"),
});

const dropdownValidation = Yup.object().shape({
  field_name: Yup.string().required("Field name is required"),
  position: Yup.number().required("This field is required"),
  options: Yup.array()
    .of(Yup.string().required("Required at least one option"))
    .min(1, "At least one option is required"),
});

const projectFieldTypesAsObj = {
  text: {
    id: "text",
    name: "Text",
  },
  dropdown: {
    id: "dropdown",
    name: "Dropdown",
  },
  date: {
    id: "date",
    name: "Date",
  },
  number: {
    id: "number",
    name: "Number",
  },
  currency: {
    id: "number",
    name: "Number",
  },
};

const projectFieldTypes = [
  projectFieldTypesAsObj.text,
  projectFieldTypesAsObj.dropdown,
  projectFieldTypesAsObj.date,
  projectFieldTypesAsObj.number,
];

export {
  validation,
  initModel,
  projectFieldTypes,
  projectFieldTypesAsObj,
  dropdownValidation,
};

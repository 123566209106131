import React, { useCallback, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";

import styles from '../../../tractionAI.module.scss';
import RecommendProjects from "modules/tractionAI/screens/recommend/projects/recommendProjects";
import { copyToClipBoardFromRef } from "modules/tractionAI/utils";
import { BtnType, Button } from "modules/primitives";
import RecommendSlidesModal from "modules/tractionAI/screens/recommend/projects/recommendSlidesModal";
import ItemErrorHandler from "modules/tractionAI/components/itemErrorHandler";

const RecommendItemResponse = ({ item, index, enqueueSnackbar, loadMore, handleTryAgain }) => {
  const ref = useRef(null);

  const handleLoadMore = () => {
    loadMore(item, index);
  }

  const handleCopy = useCallback(() => {
    if (!ref?.current) return;

    copyToClipBoardFromRef(ref, enqueueSnackbar);
  }, [ref, enqueueSnackbar]);

  return (
    <div className={`d-flex flex-column justify-content-end pr-2 ${index !== 0 ? 'mt-5' : ''}`}>
      <div className="d-flex aling-items-start justify-content-start mb-2">
        <div className={styles.searchByQuery}>
          Recommended Projects {item.isCompanySearch ? 'similar to:' : 'based on the problem:'}
          <b>{item.searchByQuery}</b>
        </div>
      </div>
      <div ref={ref} id={`recommend_${index}`}>
        {
          item.data?.length > 0 ? (
            <RecommendProjects item={item} index={index} />
          ) : item.fetched && (
            <div className="d-flex align-items-center justify-content-center mt-3">
              No recommendations available for your search.
            </div>
          )
        }
      </div>
      <ItemErrorHandler item={item} />
      {
        (item.fetched && item.data?.length > 0 && !item.hasError) ? (
          <div className="d-flex align-items-center justify-content-center mt-3">
            <Button btn={BtnType.FRAME_LESS} onClick={handleLoadMore}>Load more</Button>
          </div>
        ) : null
      }
      {(item.fetched || item.doNotAnimate) && !item.hasError && item.data?.length > 0 ? (
        <div className="d-flex mt-2 align-items-center">
          <Tooltip
            title="Copy"
            placement="top"
          >
            <div className="cursor-pointer" onClick={handleCopy}>
              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                  stroke="#1C274C" strokeWidth="1.5" />
                <path
                  d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                  stroke="#1C274C" strokeWidth="1.5" />
              </svg>
            </div>
          </Tooltip>
          <RecommendSlidesModal item={item} index={index} />
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(RecommendItemResponse);

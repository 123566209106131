import React, { useCallback, useMemo } from "react";
import { useSnackbar } from "notistack";

import styles from "./styles.module.scss";
import FordScoreCardDateFilter from "modules/reporting/fordReports/filters/date";
import FordScoreCardPillarFilter from "modules/reporting/fordReports/filters/pillar";
import FordScoreCardPlantFilter from "modules/reporting/fordReports/filters/plant";
import FordScoreCardRegionFilter from "modules/reporting/fordReports/filters/region";
import FordScoreCardStagesFilter from "modules/reporting/fordReports/filters/stages";
import FordProjectFieldsFilter from "modules/reporting/fordReports/filters/projectFields";
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import FordProjectMetricsFilter from "modules/reporting/fordReports/filters/projectMetrics";
import ProjectTemplateFilter from "modules/reporting/fordReports/filters/projectTemplates";

import Icon from "../../../../common/icon/icon.component";

const FordScoreCardFilters = ({
  reducer,
  handleReset,
  handleExpandClick,
  getNewData,
  getNewTableData,
  setNewFilters,
  filterBy,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const shouldRenderResetFilters = useMemo(() => {
    const objKeysLength = Object.keys(reducer.filters).length;
    return objKeysLength > 1;
  }, [reducer.filters]);

  const getNewDataWhenFiltersUpdate = useCallback(
    (newFilter, reducerKey) => {
      const makeFilters = makeActualScoreCardFilterData({
        ...reducer.filters,
        ...newFilter,
      });
      const payload = {
        enqueueSnackbar,
        data: makeFilters,
      };

      getNewData(payload);

      if (reducerKey?.length > 0) {
        getNewTableData({
          reducerKey,
          highLightColumn: reducer.tableData.highLightColumn,
          data: {
            ...makeFilters,
            section: reducerKey,
          },
        });
      }
    },
    [reducer.filters, reducer.tableData]
  );

  return (
    <div className={styles.filtersWrapper}>
      <div className="d-flex align-items-center justify-content-between">
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={handleExpandClick}
        >
          <span className="font-weight-bold">Filters</span>
          <div className="ml-2">
            <Icon
              style={
                reducer.expandedFilters
                  ? { transform: "rotate(0deg)", color: "#2d83ae" }
                  : { transform: "rotate(-90deg)" }
              }
              size={10}
              icon="icn-table-down-chevron"
            />
          </div>
        </div>
        {shouldRenderResetFilters && (
          <span className={styles.reset} onClick={handleReset}>
            Clear
          </span>
        )}
      </div>
      {reducer.expandedFilters && (
        <div className="d-flex flex-row flex-wrap mt-3 mb-3">
          <FordScoreCardDateFilter
            reducer={reducer}
            handleFilterUpdate={getNewDataWhenFiltersUpdate}
            setNewFilters={setNewFilters}
          />
          <FordScoreCardPillarFilter
            reducer={reducer}
            handleFilterUpdate={getNewDataWhenFiltersUpdate}
            setNewFilters={setNewFilters}
          />
          <FordScoreCardRegionFilter
            reducer={reducer}
            handleFilterUpdate={getNewDataWhenFiltersUpdate}
            setNewFilters={setNewFilters}
          />
          <FordScoreCardPlantFilter
            reducer={reducer}
            handleFilterUpdate={getNewDataWhenFiltersUpdate}
            setNewFilters={setNewFilters}
          />
          <FordScoreCardStagesFilter
            reducer={reducer}
            handleFilterUpdate={getNewDataWhenFiltersUpdate}
            setNewFilters={setNewFilters}
            filterBy={filterBy}
          />
          <FordProjectFieldsFilter
            reducer={reducer}
            handleFilterUpdate={getNewDataWhenFiltersUpdate}
            setNewFilters={setNewFilters}
          />
          <FordProjectMetricsFilter
            reducer={reducer}
            handleFilterUpdate={getNewDataWhenFiltersUpdate}
            setNewFilters={setNewFilters}
          />
          <ProjectTemplateFilter
            reducer={reducer}
            handleFilterUpdate={getNewDataWhenFiltersUpdate}
            setNewFilters={setNewFilters}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(FordScoreCardFilters);

import { combineEpics, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";

import {
  IDEA_LIST_GET_ATTEMPT,
  ideasListGetSuccess,
  CREATE_IDEA_ATTEMPT,
  createIdeaSuccess,
  GET_IDEA_ATTEMPT,
  getIdeaSuccess,
  VOTE_IDEA_ATTEMPT,
  RE_VOTE_IDEA_ATTEMPT,
  voteIdeaSuccess,
  GET_IDEAS_STATUSES_COUNTER_ATTEMPT,
  getIdeasStatusesCounterSuccess,
  EDIT_IDEA_ATTEMPT,
  editIdeaSuccess,
  ADD_IDEA_TEAM_MEMBER_ATTEMPT,
  addIdeaTeamMemberSuccess,
  DELETE_IDEA_TEAM_MEMBER_ATTEMPT,
  deleteIdeaTeamMemberSuccess,
  IDEA_TEMPLATE_GET_ATTEMPT,
  ideasTemplateGetSuccess,
  IDEA_SECTIONS_GET_ATTEMPT,
  ideasSectionGetSuccess,
  ADD_IDEA_COMMENT_ATTEMPT,
  addIdeaCommentSuccess,
  DELETE_IDEA_COMMENT_ATTEMPT,
  deleteIdeaCommentSuccess,
  IDEA_COMPANIES_ADD_ATTEMPT,
  ideaCompaniesAddSuccess,
  IDEA_COMPANY_DELETE_ATTEMPT,
  ideaCompanyDeleteSuccess,
  ADD_RELATED_PROJECT_ATTEMPT,
  addRelatedProjectSuccess,
  DELETE_RELATED_PROJECT_ATTEMPT,
  deleteRelatedProjectSuccess,
  IDEA_PRODUCT_DELETE,
  ideaProductDeleteSuccess,
  IDEA_RATING_SET, ideaRatingSetSuccess
} from "./idea.action";

import {
  errorHandler,
  httpGet,
  httpPatch,
  httpPost,
  httpDelete,
  queryString,
} from "../../common/httpCall";
import { makeFakeAction } from "modules/reporting/store/reporting.actions";

export const epicSetIdeaRating = (action$) =>
  action$.pipe(
    ofType(IDEA_RATING_SET),
    switchMap(
      ({ payload }) =>
        httpPost({
          call: `ideas/${payload.idea_id}/ratings`,
          data: payload.data,
        }).pipe(
          map((result) => ideaRatingSetSuccess(result)),
          catchError((err) => errorHandler(err, payload?.enqueueSnackbar))
        )
    )
  );

export const epicDeleteIdeaCompany = (action$) =>
  action$.pipe(
    ofType(IDEA_COMPANY_DELETE_ATTEMPT),
    switchMap(({ payload: { id, company_id, enqueueSnackbar, cb } }) =>
      httpDelete({
        call: `idea_companies/${id}`,
      }).pipe(
        map((result) => {
          if (cb && result.response) cb();

          return ideaCompanyDeleteSuccess({
            ...result,
            company_id,
          });
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicAddIdeaCompanies = (action$) =>
  action$.pipe(
    ofType(IDEA_COMPANIES_ADD_ATTEMPT),
    switchMap(({ payload: { related_companies, enqueueSnackbar, cb } }) =>
      httpPost({
        call: "idea_companies",
        data: {
          related_companies,
        },
      }).pipe(
        map((result) => {
          if (cb && result.response) cb();

          return ideaCompaniesAddSuccess(result);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicDeleteIdeaComment = (action$) =>
  action$.pipe(
    ofType(DELETE_IDEA_COMMENT_ATTEMPT),
    switchMap(({ payload: { comment, enqueueSnackbar, cb } }) =>
      httpDelete({
        call: `ideas/${comment.idea_id}/ideas_comments/${comment.ideas_comment_id}`,
      }).pipe(
        map((result) => {
          if (cb && result.response) cb();

          return deleteIdeaCommentSuccess({
            ...result,
            comment,
          });
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicAddIdeaComment = (action$) =>
  action$.pipe(
    ofType(ADD_IDEA_COMMENT_ATTEMPT),
    switchMap(({ payload: { comment, enqueueSnackbar, cb } }) =>
      httpPost({
        call: `ideas/${comment.idea_id}/ideas_comments`,
        data: {
          comment,
        },
      }).pipe(
        map((result) => {
          if (cb && result.response) cb();

          return addIdeaCommentSuccess(result);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicDeleteIdeaMember = (action$) =>
  action$.pipe(
    ofType(DELETE_IDEA_TEAM_MEMBER_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar, cb } }) =>
      httpDelete({
        call: `idea_members/${id}`,
      }).pipe(
        map((result) => {
          if (cb && result.response) cb();

          return deleteIdeaTeamMemberSuccess({
            ...result,
            id,
          });
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicAddIdeaMember = (action$) =>
  action$.pipe(
    ofType(ADD_IDEA_TEAM_MEMBER_ATTEMPT),
    switchMap(({ payload: { members, enqueueSnackbar, cb } }) =>
      httpPost({
        call: "idea_members",
        data: {
          members,
        },
      }).pipe(
        map((result) => {
          if (cb && result.response) cb();

          return addIdeaTeamMemberSuccess(result);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicIdeaEdit = (action$) =>
  action$.pipe(
    ofType(EDIT_IDEA_ATTEMPT),
    switchMap(({ payload: { idea, enqueueSnackbar, cb } }) =>
      httpPatch({
        call: `ideas/${idea.id}`,
        data: {
          idea,
        },
      }).pipe(
        map((result) => {
          if (cb && result.response) cb();

          return editIdeaSuccess(result);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicGetIdeaDetails = (action$) =>
  action$.pipe(
    ofType(GET_IDEA_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, id } }) =>
      httpGet({
        call: `ideas/${id}`,
      }).pipe(
        map((result) => getIdeaSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicGetIdeasList = (action$) =>
  action$.pipe(
    ofType(IDEA_LIST_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, ...rest } }) =>
      httpPost({
        call: `ideas/search${rest.isDropdown ? `?is_dropdown=${rest.isDropdown}` : ''}`,
        data: rest,
      }).pipe(
        map((result) => {
          if (rest.isDropdown) {
            if (rest.cb) {
              rest.cb(result.response || [])
            }

            return makeFakeAction(result);
          }

          return ideasListGetSuccess(result)
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicCreateIdea = (action$) =>
  action$.pipe(
    ofType(CREATE_IDEA_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar, cb } }) =>
      httpPost({
        call: "ideas",
        data: {
          idea: {
            ...data,
          },
        },
      }).pipe(
        map((result) => {
          if (cb && result.response) cb(null, result.response);

          return createIdeaSuccess(result);
        }),
        catchError((error) => {
          if (cb) cb(error);

          return errorHandler(error, enqueueSnackbar, []);
        })
      )
    )
  );

export const epicVoteIdea = (action$) =>
  action$.pipe(
    ofType(VOTE_IDEA_ATTEMPT),
    switchMap(({ payload: { vote, enqueueSnackbar, cb, isProfileUse } }) =>
      httpPost({
        call: "idea_votes",
        data: vote,
      }).pipe(
        map((result) => {
          if (cb && result.response) cb(null, result.response);

          return voteIdeaSuccess({ ...result, isProfileUse });
        }),
        catchError((error) => {
          if (cb) cb(error);

          return errorHandler(error, enqueueSnackbar, []);
        })
      )
    )
  );

export const epicReVoteIdea = (action$) =>
  action$.pipe(
    ofType(RE_VOTE_IDEA_ATTEMPT),
    switchMap(({ payload: { id, vote, enqueueSnackbar, cb, isProfileUse } }) =>
      httpPatch({
        call: `idea_votes/${id}`,
        data: vote,
      }).pipe(
        map((result) => {
          if (cb && result.response) cb(null, result.response);

          return voteIdeaSuccess({ ...result, isProfileUse });
        }),
        catchError((error) => {
          if (cb) cb(error);

          return errorHandler(error, enqueueSnackbar, []);
        })
      )
    )
  );

export const epicGetIdeasStatusesCounter = (action$) =>
  action$.pipe(
    ofType(GET_IDEAS_STATUSES_COUNTER_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, query } }) =>
      httpGet({
        call: `/ideas/ideas_statuses${
          query ? `?${queryString({ ...query })}` : ""
        }`,
      }).pipe(
        map((result) => getIdeasStatusesCounterSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicGetIdeasTemplates = (action$) =>
  action$.pipe(
    ofType(IDEA_TEMPLATE_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, query } }) =>
      httpGet({
        call: `ideas_templates${query ? `?${queryString({ ...query })}` : ""}`,
      }).pipe(
        map((result) => ideasTemplateGetSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicGetIdeasSections = (action$) =>
  action$.pipe(
    ofType(IDEA_SECTIONS_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, query } }) =>
      httpGet({
        call: `ideas_sections${query ? `?${queryString({ ...query })}` : ""}`,
      }).pipe(
        map((result) => ideasSectionGetSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicAddIdeaRelatedProject = (action$) =>
  action$.pipe(
    ofType(ADD_RELATED_PROJECT_ATTEMPT),
    switchMap(({ payload: { project_idea, enqueueSnackbar } }) =>
      httpPost({
        call: "project_ideas",
        data: {
          project_idea,
        },
      }).pipe(
        map((result) => {
          return addRelatedProjectSuccess(result);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicDeleteIdeaProject = (action$) =>
  action$.pipe(
    ofType(DELETE_RELATED_PROJECT_ATTEMPT),
    switchMap(({ payload: { project_id, idea_id, enqueueSnackbar, cb } }) =>
      httpDelete({
        call: `/project_ideas?project_id=${project_id}&idea_id=${idea_id}`,
      }).pipe(
        map((result) => {
          if (cb && result.response) cb();

          return deleteRelatedProjectSuccess({
            ...result,
          });
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicDeleteIdeaProduct = (action$) =>
  action$.pipe(
    ofType(IDEA_PRODUCT_DELETE),
    switchMap(({ payload: { product_id, idea_id, enqueueSnackbar, cb } }) =>
      httpDelete({
        call: `ideas/${idea_id}/products/${product_id}`,
      }).pipe(
        map((result) => {
          if (cb) cb();

          return ideaProductDeleteSuccess(result);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

const ideasEpic = combineEpics(
  epicDeleteIdeaCompany,
  epicAddIdeaCompanies,
  epicDeleteIdeaComment,
  epicAddIdeaComment,
  epicGetIdeasList,
  epicCreateIdea,
  epicGetIdeaDetails,
  epicVoteIdea,
  epicReVoteIdea,
  epicGetIdeasStatusesCounter,
  epicIdeaEdit,
  epicAddIdeaMember,
  epicDeleteIdeaMember,
  epicGetIdeasTemplates,
  epicGetIdeasSections,
  epicAddIdeaRelatedProject,
  epicDeleteIdeaProject,
  epicDeleteIdeaProduct,
  epicSetIdeaRating,
);

export default ideasEpic;

import { combineEpics, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";

import { errorHandler, httpPost } from "../../../../../common/httpCall";
import {
  GET_REPLICATIONS_TABLE_DATA,
  getReplicationsTableDataSuccess,
  GET_REPLICATIONS_STATISTICS_DATA,
  getReplicationsStatisticsDataSuccess,
  GET_REPLICATIONS_BY_STAGE_DATA,
  getReplicationsByStageDataSuccess,
  GET_PROJECTS_EXPORTED_DATA,
  getProjectsExportedDataSuccess,
  GET_PROJECTS_IMPORTED_DATA,
  getProjectsImportedDataSuccess,
} from "modules/reporting/fordReports/replicationsCard/store/replicationsCard.actions";

export const epicGetReplicationsStatisticData = (action$) => action$.pipe(
  ofType(GET_REPLICATIONS_STATISTICS_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: 'ford/replication_dashboard/statistics',
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getReplicationsStatisticsDataSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetReplicationsByStageData = (action$) => action$.pipe(
  ofType(GET_REPLICATIONS_BY_STAGE_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: 'ford/replication_dashboard/replications_by_stage',
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getReplicationsByStageDataSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetProjectsExportedData = (action$) => action$.pipe(
  ofType(GET_PROJECTS_EXPORTED_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: 'ford/replication_dashboard/projects_exported',
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getProjectsExportedDataSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetProjectsImportedData = (action$) => action$.pipe(
  ofType(GET_PROJECTS_IMPORTED_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: 'ford/replication_dashboard/projects_imported',
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getProjectsImportedDataSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetCustomSectionTableData = (action$) => action$.pipe(
  ofType(GET_REPLICATIONS_TABLE_DATA),
  switchMap(({ payload: { data, enqueueSnackbar, reducerKey, page = 1, highLightColumn } }) => httpPost({
    call: `ford/replication_dashboard/table?page=${page}&items=20`,
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getReplicationsTableDataSuccess({
      ...result,
      reducerKey,
      highLightColumn,
    })),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const fordReplicationsEpic = combineEpics(
  epicGetCustomSectionTableData,
  epicGetReplicationsStatisticData,
  epicGetReplicationsByStageData,
  epicGetProjectsExportedData,
  epicGetProjectsImportedData,
);

export default fordReplicationsEpic;

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { projectTeamsGet } from "../../modules/projects/projectsMain/projectsMain.action";

const useTeam = (props) => {
  const { team_id } = props;
  const [teamName, setTeamName] = useState("");
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    projectsReducer: {
      projectsMainReducer: { teams },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (team_id) {
      const team = teams[team_id];
      if (team) {
        setTeamName(team.name);
      } else {
        dispatch(projectTeamsGet({ id: team_id, enqueueSnackbar }));
      }
    }
  }, [team_id, teams]);

  const getName = () => teamName;
  return { teamName, getName };
};

export default useTeam;

import React, {
  memo, useEffect, useMemo, useRef, useState,
} from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Field, FieldArray, Form, Formik,
} from "formik";

import { BtnType, Button } from "../../../modules/primitives";
import { ModalFooter } from "../../../application/modal";
import styles from "./leadershipEdit.module.scss";
import { useModelPopup } from "../../hooks";
import { mdSize } from "../../icon";
import Icon from "../../icon/icon.component";
import {
  addLeadershipItem,
  deleteLeadershipItem,
  editLeadershipItem,
} from "../../actions/common.action";
import UploadMemberAvatar from "./uploadAvatar/uploadAvatar.component";

const defaultRow = {
  isNew: true,
  avatar: "",
  title: "",
  name: "",
  touched: true,
  is_current: true,
};

const generateUID = () => {
  const length = 10;
  let result = "";

  for (let i = 0; i < length; i++) {
    result += String.fromCharCode(97 + Math.floor(Math.random() * 26));
  }

  return result;
};

const LeadershipEditModal = ({ profile, members = [] }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormValues({
      fields: members,
      originalFields: members,
    });
  }, [members]);

  const hasNewLeadership = useMemo(
    () => formValues.fields?.some((f) => f.isNew),
    [formValues.fields],
  );

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSave = (evt, leader, idx) => {
    const payload = {
      companyId: profile.id,
      leadership_member: {
        is_current: true,
        ...leader,
      },
      cb: (res) => {
        if (leader.isNew) {
          const fields = formValues.fields.map((f) => {
            if (f.isNew) {
              return {
                ...res,
                touched: false,
              };
            }

            return f;
          });

          setFormValues({
            ...formValues,
            fields,
          });

          enqueueSnackbar("Successfully added new Leadership member row", {
            variant: "success",
          });
        } else {
          const fields = formValues.fields.map((f) => {
            if (f.id === leader.id) {
              return {
                ...f,
                touched: false,
              };
            }

            return f;
          });

          setFormValues({
            ...formValues,
            fields,
          });

          enqueueSnackbar("Successfully updated Leadership member row", {
            variant: "success",
          });
        }
      },
    };

    if (leader.isNew) {
      dispatch(addLeadershipItem(payload));
    } else {
      dispatch(editLeadershipItem(payload));
    }
  };

  const handleCancel = (evt, elem, setFieldValue, idx1) => {
    evt.preventDefault();

    if (errors[idx1]) {
      const newErrorsObj = errors;
      delete newErrorsObj[idx1];

      setErrors(newErrorsObj);
    }

    const fields = formValues.fields.map((l, idx) => {
      if (l.isNew) {
        return Object.keys(defaultRow).reduce((acc, key) => {
          if (key === "isNew") {
            return {
              ...acc,
              isNew: true,
            };
          }

          setFieldValue(key, "");

          return {
            ...acc,
            [key]: "",
          };
        }, {});
      }

      if (elem.id === l.id) {
        const getField = formValues.originalFields.find(
          (orL) => orL.id === elem.id,
        );

        return Object.keys(elem).reduce((acc, key) => {
          const val = getField[key];
          setFieldValue(`fields.${idx}.${key}`, val);

          return {
            ...acc,
            [key]: val,
          };
        }, {});
      }

      return l;
    });

    setFormValues({
      ...formValues,
      fields,
    });
  };

  const handleDelete = (elem, idx) => {
    if (errors[idx]) {
      const newErrorsObj = errors;
      delete newErrorsObj[idx];

      setErrors(newErrorsObj);
    }

    if (elem.isNew) {
      const fields = formValues.fields.filter((f) => !f.isNew);

      setFormValues({
        ...formValues,
        fields,
      });

      return;
    }

    const payload = {
      companyId: profile.id,
      id: elem.id,
      cb: () => {
        const fields = formValues.fields.filter((f) => f.id !== elem.id);

        setFormValues({
          ...formValues,
          fields,
        });

        enqueueSnackbar("Successfully deleted funding row", {
          variant: "success",
        });
      },
    };

    dispatch(deleteLeadershipItem(payload));
  };

  const handleAddNewLeadership = () => {
    const fields = [defaultRow, ...formValues.fields];

    setFormValues({
      ...formValues,
      fields,
    });
  };

  const handleFieldBlur = (elem, evt, prop) => {
    const fields = formValues.fields.map((l) => {
      if (elem.id === l.id) {
        return {
          ...l,
          [prop]: evt.target.value,
          touched: true,
        };
      }

      return l;
    });

    setFormValues({
      ...formValues,
      fields,
    });
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={formValues}
      onSubmit={() => {}}
    >
      {({
        values, handleSubmit, setFieldValue, ...formikprops
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className={styles.leadershipContent}>
            <FieldArray name="fields">
              <div className="d-flex flex-column">
                <div className={styles.gridRow}>
                  <div className={styles.avatar}>Avatar</div>
                  <div className={styles.name}>Name</div>
                  <div className={styles.title}>Title</div>
                  <div className={styles.right}>Actions</div>
                </div>
                {!hasNewLeadership && (
                <div className="d-flex justify-content-end mt-3">
                  <Button
                    btn={BtnType.REGULAR}
                    onClick={handleAddNewLeadership}
                  >
                    Add New Leadership
                  </Button>
                </div>
                )}
                {values?.fields?.length
                  ? values?.fields?.map((l, idx) => (
                    <div key={l.id || idx} className={styles.gridRow}>
                      <div className={styles.avatar}>
                        <UploadMemberAvatar
                          imgURl={l.avatar}
                          uploadDirName={`leadership_members/${
                            l.id || generateUID()
                          }/key`}
                          onUpload={(evt) => {
                            setFieldValue(`fields.${idx}.avatar`, evt);
                            setFieldValue(`fields.${idx}.touched`, true);
                          }}
                        />
                      </div>
                      <div className={styles.name}>
                        <Field
                          name={`fields.${idx}.name`}
                          className={styles.field}
                          onBlur={(evt) => handleFieldBlur(l, evt, "name")}
                        />
                      </div>
                      <div className={styles.title}>
                        <Field
                          name={`fields.${idx}.title`}
                          className={styles.field}
                          onBlur={(evt) => handleFieldBlur(l, evt, "title")}
                        />
                      </div>
                      <div className={styles.right}>
                        {l.touched && (
                        <>
                          <Button
                            type="button"
                            btn={BtnType.FRAME_LESS}
                            className="mr-2"
                            onClick={(evt) => handleCancel(evt, l, setFieldValue, idx)}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="button"
                            btn={BtnType.REGULAR}
                            onClick={(evt) => handleSave(evt, l, idx)}
                          >
                            {l.isNew ? "Submit" : "Update"}
                          </Button>
                        </>
                        )}
                        <div
                          className="d-flex ml-3"
                          onClick={() => handleDelete(l, idx)}
                        >
                          <Icon {...mdSize} icon="icn-button-delete" />
                        </div>
                      </div>
                    </div>
                  ))
                  : null}
              </div>
            </FieldArray>
          </div>
          <ModalFooter>
            <Button
              className="mr-3"
              btn={BtnType.FRAME_LESS}
              onClick={handleCancelClick}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default memo(LeadershipEditModal);

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { UserInline } from "../../../../primitives";
import styles from "./companyLeadership.module.scss";
import CrunchSourceComponent from "../crunchSource";

const CompanyLeadershipComponent = ({ company, members = [] }) => {
  const [hasShowMore, setHasShowMore] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setHasShowMore(members?.length > 8);
  }, [members]);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className={styles.wrapper}>
      {members.length ? (
        members
          .slice(0, hasShowMore && showMore ? members.length : 8)
          .map((item) => (
            <div key={item.name} className={styles.itemContainer}>
              <div className={styles.leftContainer}>
                <div className={styles.userWrp}>
                  <UserInline
                    className={styles.userIcon}
                    classObj={{ name: styles.userName }}
                    first={item.name}
                    avatar={item.avatar}
                  />
                </div>
                <div className={styles.userDetails}>
                  <div className={classNames("caption", styles.position)}>
                    {item.title}
                  </div>
                </div>
              </div>
            </div>
          ))
      ) : (
        <div>No members yet.</div>
      )}
      {hasShowMore && (
        <div onClick={handleShowMore} className={styles.showMore}>
          {showMore ? "show less" : "show more"}
        </div>
      )}
      <CrunchSourceComponent company={company} hideTraction />
    </div>
  );
};

export default React.memo(CompanyLeadershipComponent);

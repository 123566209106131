import React from "react";
import classNames from "classnames";

import BoldIcon from "../../../../assets/icn-bold.svg";
import ItalicIcon from "../../../../assets/icn-italic.svg";
import UlIcon from "../../../../assets/icn-bullets.svg";
import OlIcon from "../../../../assets/icn-numbers.svg";
import styles from "../richEditor.module.scss";

const imgs = {
  B: BoldIcon,
  I: ItalicIcon,
  UL: UlIcon,
  OL: OlIcon,
};

class StyleButton extends React.Component {
  onToggle = (e) => {
    e.preventDefault();
    this.props.onToggle(this.props.style);
  };

  render() {
    return (
      <span
        className={classNames(styles.richControl, {
          [styles.richControlActive]: this.props.active,
        })}
        id={this.props.id || ""}
        onMouseDown={this.onToggle}
      >
        <img src={imgs[this.props.label]} alt={this.props.label} />
      </span>
    );
  }
}

export default StyleButton;

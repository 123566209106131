import React from "react";
import { useHistory } from "react-router";
import styles from "./panelBlock.module.scss";
import { Icon, xxlSize } from "../../../common/icon";

const PanelBlockComponent = ({
  icon, tabName, link, mode,
}) => {
  const history = useHistory();

  const handleRedirect = () => {
    history.push(link, { mode });
  };
  return (
    <div onClick={handleRedirect} className={styles.container}>
      <Icon icon={icon} {...xxlSize} className={styles.iconColor} />
      <div className={styles.textContainer}>
        <div className={styles.text}>Manage</div>
        <div className={styles.bold}>{tabName}</div>
      </div>
    </div>
  );
};

export default PanelBlockComponent;

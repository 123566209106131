import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { Link, useHistory } from "react-router-dom";

import styles from "./listWrapper.module.scss";
import { CompanyAvatar, Loading, Pagination } from "modules/primitives";
import FunnelListThemes from "modules/home/firmenichFunnel/funnelListThemes";
import FirmenichStage from "modules/home/firmenichFunnel/firmenichStage";
import { editDetails } from "modules/companies/company/company.action";
import style from "modules/companies/companyMain/rightWindow/newCompaniesList/newCompaniesList.module.scss";
import { Icon, smSize } from "../../../../common/icon";

const PAGINATION_DEFAULTS = {
  pageSize: 14,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
  total: 0,
};

const FirmenichListWrapper = ({
  data = [],
  handlePageChange,
  pagination,
  loading,
  view,
  toggleView,
  getDataAfterStageChange,
  sortingConfig,
  handleConfigChange,
  selectedTags,
  tags,
}) => {
  const dispatch = useDispatch();
  const {
    councilReducer: { selectedCouncil },
    appReducer: { relationShipStatuses },
    authReducer: { session },
  } = useSelector((state) => state);

  const history = useHistory();
  const [pageSettings, setPageSettings] = useState(PAGINATION_DEFAULTS);

  useEffect(() => {
    if (pagination?.total) {
      setPageSettings({
        ...PAGINATION_DEFAULTS,
        ...pagination,
        current: pagination.page,
      });
    }
  }, [pagination]);

  const handleStageSelect = useCallback((company, data) => {
    dispatch(
      editDetails({
        companyId: company.company_id,
        councilId: selectedCouncil.id,
        data,
        callBack: () => {
          const timeout = setTimeout(() => {
            getDataAfterStageChange(view);
            clearTimeout(timeout);
          }, 1000);
        },
        council: selectedCouncil?.name,
      })
    );
  }, []);

  const handleRowClick = (c) => {
    history.push(`/companies/${c.slug || c.id || c.company_id}/overview`);
  };

  const drawSortingIcon = useCallback(
    (col) => {
      if (!sortingConfig?.sortOrder?.length) return null;

      if (sortingConfig?.sortAttribute === col) {
        return (
          <>
            <div
              className={classnames(
                style.sortIcon,
                sortingConfig.sortOrder === "desc"
                  ? style.sortAsc
                  : style.sortDesc
              )}
            >
              <Icon {...smSize} icon="icn-export-button" />
            </div>
          </>
        );
      }
    },
    [sortingConfig]
  );

  const makeSortingConfig = useCallback(
    (sortAttribute) => {
      let config;

      if (sortingConfig && sortingConfig?.sortAttribute === sortAttribute) {
        if (sortingConfig.sortOrder === "desc") {
          config = {
            sortAttribute,
            sortOrder: "asc",
          };
        } else {
          config = {
            sortAttribute: "",
            sortOrder: "",
          };
        }
      } else {
        config = {
          sortAttribute,
          sortOrder: "desc",
        };
      }

      handleConfigChange(config, view);
    },
    [sortingConfig, view]
  );

  const handleSeeAll = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();

    const source = selectedTags.length ? selectedTags : tags;
    const statusesAsString = source
      .map((t) => t.custom_id || t.id)
      .reduce((acc, id) => `${acc}${acc.length ? "," : ""}${id}`, "");
    const search = `relationShipStatus=${statusesAsString}&page=1&layout=1`;

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        relationShipStatus: statusesAsString,
        page: 1,
        layout: 1,
      },
    });
  };

  return (
    <div className={styles.innovationListWrapper}>
      <div className={styles.listHeader}>
        <div
          className={` ${styles.companyHeader} ${
            window.screen.width > 550 && "position-relative"
          } `}
          onClick={() => makeSortingConfig("Company")}
        >
          Company
          {drawSortingIcon("Company")}
        </div>
        {toggleView === "investment" ? (
          <div className="position-relative">Track</div>
        ) : null}
        <div
          className={` ${styles.themes} position-relative`}
          onClick={() => makeSortingConfig("Themes")}
        >
          {selectedCouncil?.settings?.themes_display_name || "Themes"}
          {drawSortingIcon("Themes")}
        </div>
        <div
          className="position-relative"
          onClick={() => makeSortingConfig("Country")}
        >
          Country
          {drawSortingIcon("Country")}
        </div>
        <div
          className="justify-content-center position-relative"
          onClick={() => makeSortingConfig("Stage")}
        >
          Stage
          {drawSortingIcon("Stage")}
        </div>
      </div>
      {loading ? (
        <Loading customText="Please stand by ..." />
      ) : (
        <>
          <div className={styles.list}>
            <div className={styles.whiteWalpOnScroll} />

            {data.map((c) => (
              <div
                key={c.id || c.company_id}
                className="d-flex justify-content-between"
              >
                <div
                  className={classnames(
                    "d-flex align-items-center text-capitalize cursor-pointer",
                    styles.company
                  )}
                  onClick={() => handleRowClick(c)}
                >
                  <div className={styles.logoCon}>
                    <CompanyAvatar
                      className={styles.logo}
                      imgSrc={c.logo}
                      name={c?.name}
                    />
                  </div>
                  <span>{c?.name}</span>
                </div>
                {toggleView === "investment" ? (
                  <div className={styles.investmentTag}>Investment</div>
                ) : null}
                <div className={styles.themesColumn}>
                  <FunnelListThemes
                    themes={c.themes || []}
                    companyName={c?.name}
                  />
                </div>
                <div>{c.country || "-"}</div>
                <div className="d-flex justify-content-center">
                  <FirmenichStage
                    company={c}
                    relationShipStatuses={relationShipStatuses}
                    handleStageSelect={handleStageSelect}
                    session={session}
                    selectedCouncil={selectedCouncil}
                  />
                </div>
              </div>
            ))}
          </div>
          <div
            className={`mt-3 mb-2 ${
              window.screen.width < 550 && "flex-column"
            } d-flex justify-content-between align-items-center`}
          >
            <div>
              <Link
                to="/companies"
                className={`${styles.link} mr-4`}
                onClick={handleSeeAll}
              >
                SEE ALL
              </Link>
              <Link to="/themes/pipeline" className={styles.link}>
                PIPELINE VIEW
              </Link>
            </div>
            {data?.length && pagination?.total ? (
              <Pagination {...pageSettings} onChange={handlePageChange} />
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default memo(FirmenichListWrapper);

import { apiStatus, pageMeta } from "../../../common/httpCall";
import {
  SET_COMPANY_SAVED_LIST_ATTEMPT,
  CLEAR_CREATED_LIST,
  COMPANY_LIST_SHARE_REMOVE_MANY_ATTEMPT,
  COMPANY_LIST_SHARE_REMOVE_MEMBER_ATTEMPT,
  COMPANY_LIST_SHARE_REMOVE_MEMBER_SUCCESS,
  COMPANY_LIST_SHARE_WITH_MEMBER_ATTEMPT,
  COMPANY_LIST_SHARE_WITH_MEMBER_SUCCESS,
  COMPANY_SAVED_LIST_DELETE_SUCCESS,
  COMPANY_SAVED_LIST_GET_ATTEMPT,
  COMPANY_SAVED_LIST_GET_SUCCESS,
  REMOVE_COMPANY_FROM_SAVED_LIST_SUCCESS,
  SET_COMPANY_SAVED_LIST_SUCCESS,
  SET_SELECTED_LIST,
  SEARCH_COMPANIES_LISTS_BY_QUERY_ATTEMPT,
  SEARCH_LISTS_BY_QUERY_ATTEMPT,
  SEARCH_LISTS_BY_QUERY_SUCCESS,
  SEARCH_COMPANIES_LISTS_BY_QUERY_SUCCESS,
  GET_FOLLOWING_LIST_COMPANIES_ATTEMPT,
  GET_FOLLOWING_LIST_COMPANIES_SUCCESS,
  COMPANY_SAVED_LIST_ADD_ATTEMPT,
  COMPANY_SAVED_LIST_ADD_SUCCESS,
  REMOVE_COMPANY_FROM_SAVED_LIST_ATTEMPT,
  CHANGE_SELECTED_LIST_NAME,
  COMPANY_SAVED_LIST_DELETE_ATTEMPT,
  COMPANY_SAVED_LIST_GET_BY_ID_ATTEMPT,
  COMPANY_SAVED_LIST_GET_BY_ID_SUCCESS,
  SHOULD_UPDATE_LIST_ITEMS,
} from "./lists.actions";
import {
  HANDLE_ERROR_SUCCESS,
  SET_ERROR_HANDLED_SUCCESS,
  SET_SCROLL_TOP,
  UPDATE_COMPANY_RATING_VALUE,
} from "../../companies/companyMain/companyMain.action";
import { COMPANY_UNFOLLOW_SUCCESS } from "../../../common/actions/common.action";

const initialState = {
  selectedList: "",
  createdListId: "",
  shouldSavedListUpdate: false,
  loadingSavedList: false,
  savedCompaniesLists: [],
  loadingSearchList: false,
  noListFound: false,
  listPageNr: 0,
  listTotalCount: 0,
  listLoading: false,
  updateSavedList: false,
  savedList: {},
  listCompanies: [],
  loadingListCompaniesSearch: false,
  loadingListCompanies: false,
  listCompaniesPageNr: 0,
  listCompaniesPageMeta: {},
  companiesPageNr: {},
  loading: false,
  errorHandled: false,
  isScrolled: false,
};

const listsMainReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case COMPANY_UNFOLLOW_SUCCESS: {
      return {
        ...state,
        listCompanies: state.listCompanies.filter(
          (c) => c.company_id !== payload.id
        ),
      };
    }

    case UPDATE_COMPANY_RATING_VALUE: {
      const updatedCompanies = state.listCompanies.map((company) => {
        if (
          company.following_id === payload.companyId ||
          company.id === payload.companyId
        ) {
          return {
            ...company,
            following: {
              ...company.following,
              council_company_score: payload.council_company_score,
            },
            council_company_score: payload.council_company_score,
          };
        }

        return company;
      });

      return {
        ...state,
        listCompanies: updatedCompanies,
      };
    }

    case SET_COMPANY_SAVED_LIST_ATTEMPT: {
      return {
        ...state,
        loadingSavedList: false,
        shouldSavedListUpdate: false,
        selectedList: "",
      };
    }

    case COMPANY_SAVED_LIST_DELETE_SUCCESS: {
      const { response, status } = payload;
      return {
        ...state,
        savedList: response,
        selectedList:
          state.savedCompaniesLists[
            state.savedCompaniesLists
              .map((list) => list.id)
              .indexOf(response.id) - 1
          ]?.id || "following",
        shouldSavedListUpdate: true,
        loadingSavedList: false,
      };
    }

    case SET_SELECTED_LIST: {
      const { id } = payload;
      return {
        ...state,
        selectedList: id,
      };
    }

    case CLEAR_CREATED_LIST: {
      return {
        ...state,
        createdListId: "",
      };
    }

    case SET_COMPANY_SAVED_LIST_SUCCESS: {
      const { response, status } = payload;

      if (
        (status === apiStatus.SUCCESS || status === apiStatus.CREATED) &&
        response
      ) {
        return {
          ...state,
          loadingSavedList: false,
          // shouldSavedListUpdate: true,
          shouldSavedListUpdate: true,
          savedList: response,
          createdListId: response.id,
        };
      }

      return state;
    }

    case COMPANY_SAVED_LIST_GET_ATTEMPT: {
      return {
        ...state,
        shouldSavedListUpdate: false,
        listLoading: true,
      };
    }

    case COMPANY_SAVED_LIST_GET_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          savedCompaniesLists: response,
          listPageNr: payload.xhr.getResponseHeader("total-pages"),
          listLoading: false,
        };
      }

      return state;
    }

    case REMOVE_COMPANY_FROM_SAVED_LIST_ATTEMPT: {
      if (payload.isProduct && state.savedList) {
        return {
          ...state,
          listCompanies: state.listCompanies.filter(
            (c) => c.id !== payload.productId
          ),
          savedList: {
            ...state.savedList,
            products:
              state.savedList.products?.filter(
                (p) => p.id !== payload.productId
              ) || [],
          },
        };
      }

      return {
        ...state,
        listCompanies: state.listCompanies.filter(
          (c) => c.id !== payload.companyId
        ),
        savedList: {
          ...state.savedList,
          products:
            state.savedList.products?.filter(
              (p) => p.id !== payload.companyId
            ) || [],
        },
      };
    }

    case REMOVE_COMPANY_FROM_SAVED_LIST_SUCCESS: {
      return {
        ...state,
        listCompaniesPageMeta: {
          ...state.listCompaniesPageMeta,
          total: state?.listCompaniesPageMeta?.total - 1,
        },
        // shouldSavedListUpdate: true,
      };
    }

    case COMPANY_LIST_SHARE_WITH_MEMBER_ATTEMPT: {
      return {
        ...state,
        updateSavedList: false,
        // loadingSavedList: true,
      };
    }

    case COMPANY_LIST_SHARE_WITH_MEMBER_SUCCESS: {
      return {
        ...state,
        updateSavedList: true,
        shouldSavedListUpdate: true,
        // loadingSavedList: false,
      };
    }

    case COMPANY_LIST_SHARE_REMOVE_MEMBER_ATTEMPT: {
      return {
        ...state,
        updateSavedList: false,
        // loadingSavedList: true,
        // shouldSavedListUpdate: true,
      };
    }

    case COMPANY_LIST_SHARE_REMOVE_MEMBER_SUCCESS: {
      return {
        ...state,
        updateSavedList: true,
        // loadingSavedList: false,
        shouldSavedListUpdate: true,
      };
    }

    case COMPANY_LIST_SHARE_REMOVE_MANY_ATTEMPT: {
      return {
        ...state,
        // loading: true,
        shouldSavedListUpdate: false,
      };
    }

    case SEARCH_LISTS_BY_QUERY_ATTEMPT: {
      return {
        ...state,
        loadingSearchList: true,
      };
    }

    case SEARCH_LISTS_BY_QUERY_SUCCESS: {
      const { response, status } = payload;
      return {
        ...state,
        loadingSearchList: false,
        listPageNr: payload.xhr.getResponseHeader("total-pages"),
        savedCompaniesLists: response,
      };
    }

    case SEARCH_COMPANIES_LISTS_BY_QUERY_ATTEMPT: {
      return {
        ...state,
        loadingListCompaniesSearch: true,
      };
    }

    case SEARCH_COMPANIES_LISTS_BY_QUERY_SUCCESS: {
      const { response, status } = payload;
      return {
        ...state,
        loadingListCompaniesSearch: false,
        listCompaniesPageNr: payload.xhr.getResponseHeader("total-pages"),
        listCompanies: response,
        listCompaniesPageMeta: pageMeta(payload),
      };
    }

    case GET_FOLLOWING_LIST_COMPANIES_ATTEMPT: {
      return {
        ...state,
        loadingListCompaniesSearch: true,
      };
    }

    case GET_FOLLOWING_LIST_COMPANIES_SUCCESS: {
      const { response, status } = payload;
      return {
        ...state,
        loadingListCompaniesSearch: false,
        listCompaniesPageNr: payload.xhr.getResponseHeader("total-pages"),
        listCompanies: response,
        listCompaniesPageMeta: pageMeta(payload),
      };
    }

    case COMPANY_SAVED_LIST_ADD_ATTEMPT: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          savedCompaniesLists: response,
        };
      }

      return state;
    }

    case COMPANY_SAVED_LIST_ADD_SUCCESS: {
      return {
        ...state,
      };
    }

    // case REMOVE_COMPANY_FROM_SAVED_LIST_ATTEMPT: {
    //   return {
    //     ...state,
    //     loadingSavedList: true,
    //   };
    // }

    case COMPANY_SAVED_LIST_GET_BY_ID_ATTEMPT: {
      return {
        ...state,
        loadingSavedList: true,
        noListFound: false,
        // to avoid infinite loop
      };
    }

    case COMPANY_SAVED_LIST_GET_BY_ID_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          loadingSavedList: false,
          savedList: response,
          noListFound: false,
        };
      }

      return state;
    }

    case COMPANY_SAVED_LIST_DELETE_ATTEMPT: {
      return {
        ...state,
        loadingSavedList: true,
      };
    }

    case CHANGE_SELECTED_LIST_NAME: {
      return {
        ...state,
        savedList: { ...state.savedList, name: payload },
      };
    }

    case SHOULD_UPDATE_LIST_ITEMS: {
      const { type, shouldUpdate } = payload;

      return {
        ...state,
        shouldUpdateListItems: {
          type,
          shouldUpdate,
        },
      };
    }

    case HANDLE_ERROR_SUCCESS: {
      let isListErr = false;
      if (
        payload?.status === 404 &&
        payload?.response?.errors?.messages === "Record Not Found"
      ) {
        isListErr = true;
      }
      return {
        ...state,
        loading: false,
        loadingSavedList: false,
        listLoading: false,
        loadingSearchList: false,
        noListFound: isListErr,
      };
    }

    case SET_ERROR_HANDLED_SUCCESS: {
      return {
        ...state,
        errorHandled: true,
      };
    }

    case SET_SCROLL_TOP: {
      return {
        ...state,
        isScrolled: payload,
      };
    }

    default:
      return state;
  }
};

export default listsMainReducer;

import {
  TAG_TOPIC_GET_ATTEMPT,
  TAG_TOPIC_GET_SUCCESS,
  TAG_TOPIC_POST_ATTEMPT,
  TAG_TOPIC_POST_SUCCESS,
  TAG_TOPIC_DELETE_ATTEMPT,
  TAG_TOPIC_DELETE_SUCCESS,
  TAG_INDUSTRY_GET_ATTEMPT,
  TAG_INDUSTRY_GET_SUCCESS,
  CATCH_ERROR_SUCCESS,
  TAG_INDUSTRY_POST_SUCCESS,
  TAG_INDUSTRY_PUT_ATTEMPT,
  TAG_TOPIC_PUT_SUCCESS,
  TAG_INDUSTRY_PUT_SUCCESS,
  TAG_INDUSTRY_DELETE_SUCCESS,
  TAG_RELATIONSHIP_GET_ATTEMPT,
  TAG_RELATIONSHIP_GET_SUCCESS,
  TAG_RELATIONSHIP_DELETE_ATTEMPT,
  TAG_RELATIONSHIP_DELETE_SUCCESS,
  TAG_RELATIONSHIP_ADD_ATTEMPT,
  TAG_RELATIONSHIP_ADD_SUCCESS,
  TAG_RELATIONSHIP_EDIT_ATTEMPT,
  TAG_RELATIONSHIP_EDIT_SUCCESS,
  TAG_CUSTOM_ADD_SUCCESS,
  TAG_CUSTOM_GROUP_GET_ATTEMPT,
  TAG_CUSTOM_GROUP_GET_SUCCESS,
  TAG_CUSTOM_GROUP_ADD_SUCCESS,
  TAG_CUSTOM_GROUP_PUT_SUCCESS,
  TAG_CUSTOM_GROUP_DELETE_SUCCESS,
  TAG_CUSTOM_DELETE_SUCCESS,
} from "./adminTags.actions";

const initState = {
  topicTags: [],
  industryTags: [],
  relationshipTag: [],
  customTagGroups: [],
  loading: false,
  changed: false,
  shouldUpdate: false,
};

const adminTopicsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TAG_RELATIONSHIP_GET_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case TAG_RELATIONSHIP_GET_SUCCESS: {
      return {
        ...state,
        relationshipTag: payload.response.sort(
          (a, b) => a.position - b.position
        ),
        loading: false,
      };
    }

    case TAG_RELATIONSHIP_DELETE_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case TAG_RELATIONSHIP_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
        shouldUpdate: true,
      };
    }
    case TAG_RELATIONSHIP_ADD_ATTEMPT: {
      return {
        ...state,
        loading: false,
        shouldUpdate: false,
      };
    }
    case TAG_RELATIONSHIP_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
        shouldUpdate: true,
      };
    }
    case TAG_RELATIONSHIP_EDIT_ATTEMPT: {
      return {
        ...state,
        loading: false,
        shouldUpdate: false,
      };
    }
    case TAG_RELATIONSHIP_EDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        shouldUpdate: true,
      };
    }
    case TAG_TOPIC_GET_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case TAG_TOPIC_GET_SUCCESS: {
      return {
        ...state,
        topicTags: payload.response,
        loading: false,
        shouldUpdate: false,
      };
    }
    case TAG_INDUSTRY_PUT_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case TAG_INDUSTRY_PUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        shouldUpdate: true,
      };
    }
    case TAG_TOPIC_PUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        shouldUpdate: true,
      };
    }

    case TAG_INDUSTRY_POST_SUCCESS: {
      return {
        ...state,
        industryTags: [...state.industryTags, payload.response],
        loading: false,
        shouldUpdate: true,
      };
    }

    case TAG_TOPIC_POST_ATTEMPT: {
      return { ...state, loading: true };
    }

    case TAG_TOPIC_POST_SUCCESS: {
      return {
        ...state,
        topicTags: [...state.topicTags, payload.response],
        loading: false,
        shouldUpdate: true,
      };
    }
    case TAG_TOPIC_DELETE_ATTEMPT: {
      return { ...state, loading: true };
    }

    case TAG_TOPIC_DELETE_SUCCESS: {
      return {
        ...state,
        shouldUpdate: true,
      };
    }

    case TAG_INDUSTRY_GET_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case TAG_INDUSTRY_GET_SUCCESS: {
      return {
        ...state,
        industryTags: payload.response,
        loading: false,
        shouldUpdate: false,
      };
    }

    case TAG_INDUSTRY_DELETE_SUCCESS: {
      return {
        ...state,
        shouldUpdate: true,
      };
    }

    case TAG_CUSTOM_GROUP_GET_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case TAG_CUSTOM_GROUP_GET_SUCCESS: {
      return {
        ...state,
        customTagGroups: payload.response.data.map((group) => {
          return {
            ...group,
            custom_tags:
              group.attributes.council_custom_tag_group_custom_tags?.data,
            name: group.attributes.name,
            description: group.attributes.description,
            id: group.id,
            filter: group.attributes.filter,
            bg_color: group?.attributes?.bg_color,
            text_color: group?.attributes?.text_color,
          };
        }),
        shouldUpdate: true,
      };
    }

    case TAG_CUSTOM_ADD_SUCCESS: {
      return {
        ...state,
        customTagGroups: [
          ...state.customTagGroups.map((group) => {
            if (group.id === payload.response.data.id) {
              return {
                custom_tags:
                  payload.response.data.attributes
                    .council_custom_tag_group_custom_tags.data,
                id: payload.response.data.id,
                ...payload.response.data.attributes,
              };
            }
            return group;
          }),
        ],
        shouldUpdate: true,
      };
    }

    case TAG_CUSTOM_GROUP_ADD_SUCCESS: {
      return {
        ...state,
        custom_tags: [],
        customTagGroups: [
          ...state.customTagGroups,
          {
            id: payload.response.data.id,
            ...payload.response.data.attributes,
          },
        ],
      };
    }
    case TAG_CUSTOM_GROUP_PUT_SUCCESS: {
      return {
        ...state,
        custom_tags: [],
        // shouldUpdate: true,
        customTagGroups: [
          ...state.customTagGroups.map((group) => {
            if (group.id === payload.response.data.id) {
              return {
                custom_tags: group.custom_tags,
                id: payload.response.data.id,
                bg_color: payload.response.data?.attributes?.bg_color,
                ...payload.response.data.attributes,
              };
            }
            return group;
          }),
        ],
      };
    }
    case TAG_CUSTOM_GROUP_DELETE_SUCCESS: {
      return {
        ...state,
        customTagGroups: [
          ...state.customTagGroups.filter((group) => {
            if (group.id === payload.id) {
              return false;
            }
            return true;
          }),
        ],
      };
    }

    case TAG_CUSTOM_DELETE_SUCCESS: {
      return {
        ...state,
        customTagGroups: [
          ...state.customTagGroups.map((group) => {
            if (group.id === payload.data.id) {
              return {
                ...payload.data,
                name: payload.data.attributes.name,
                custom_tags:
                  payload.data.attributes.council_custom_tag_group_custom_tags
                    .data,
              };
            }
            return group;
          }),
        ],
      };
    }

    case CATCH_ERROR_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      break;
    }
  }
  return state;
};

export default adminTopicsReducer;

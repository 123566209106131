import { combineEpics, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";

import {
  POST_AGREEMENT_TYPE_ATTEMPT,
  postAgreementTypeSuccess,
  GET_AGREEMENT_TYPE_ATTEMPT,
  getAgreementTypeSuccess,
  PUT_AGREEMENT_TYPE_ATTEMPT,
  putAgreementTypeSuccess,
  DELETE_AGREEMENT_TYPE_ATTEMPT,
  deleteAgreementTypeSuccess,
} from "./adminAgreements.action";

import {
  errorHandler,
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../../../common/httpCall";

export const epicAddAdminAgreementType = (action$, state$) =>
  action$.pipe(
    ofType(POST_AGREEMENT_TYPE_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        call: "agreement_types",
        data: { ...data },
      }).pipe(
        map((result) => postAgreementTypeSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicAdminGetAgreementTypes = (action$, state$) =>
  action$.pipe(
    ofType(GET_AGREEMENT_TYPE_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v1",
        call: `agreement_types`,
      }).pipe(
        map((result) => getAgreementTypeSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicPutAdminAgreementType = (action$, state$) =>
  action$.pipe(
    ofType(PUT_AGREEMENT_TYPE_ATTEMPT),
    switchMap(({ payload: { data, agreement_type_id, enqueueSnackbar } }) =>
      httpPut({
        apiVersion: "v1",
        call: `agreement_types/${agreement_type_id}`,
        data: { ...data },
      }).pipe(
        map((result) => putAgreementTypeSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );
export const epicDeleteAdminAgreementType = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_AGREEMENT_TYPE_ATTEMPT),
    switchMap(({ payload: { agreement_type_id, enqueueSnackbar } }) =>
      httpDelete({
        apiVersion: "v1",
        call: `agreement_types/${agreement_type_id}`,
      }).pipe(
        map((result) => deleteAgreementTypeSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

const adminAgreementsEpic = combineEpics(
  epicAddAdminAgreementType,
  epicAdminGetAgreementTypes,
  epicPutAdminAgreementType,
  epicDeleteAdminAgreementType
);

export default adminAgreementsEpic;

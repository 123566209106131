import React from "react";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import styles from "./companyNews.module.scss";

const CompanyNewsComponent = ({ news }) => {
  const goToNews = (item) => {
    window.open(item.url.value);
  };
  return (
    <div className={styles.wrapper}>
      {news.map((item) => (
        <div
          key={item.identifier.uuid}
          className={styles.itemContainer}
          onClick={() => goToNews(item)}
        >
          <div className={styles.firstRow}>
            <div className={styles.titleWrp}>{item.publisher}</div>
            <div className={classNames("caption", styles.time)}>
              {item.posted_on
                ? format(parseISO(item.posted_on), "MMMM d, yyyy")
                : "-"}
            </div>
          </div>
          <div>{item.identifier.value}</div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(CompanyNewsComponent);

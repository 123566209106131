/* eslint-disable no-trailing-spaces */
import React, { useRef, useState, useEffect } from "react";
import { withSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { companyAgreementShouldOpenSuccess } from "modules/companies/company/company.action";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../hooks";
import { ModalFooter, ModalBody } from "../../../application/modal";
import {
  Button,
  BtnType,
  Label,
  DropSelect,
} from "../../../modules/primitives";
import { optionItem } from "./constant";
import { objectType } from "modules/companies/company/details/details.constant";
import NewTextComponent from "./newText";
import NewCheckListComponent from "../../../modules/companies/company/details/newCheckList";
import NewCheckListComponentOrg from "../../../modules/patientOrganizations/patientOrganization/details/popups/newItem/newCheckList";
import NewDropdownComponent from "./newDropDown";
import NewDocumentComponent from "./newDocument";
import NewLinkComponent from "./newLink";
import NewImageComponent from "./newImage";
import NewSlidesComponent from "./newSlides";
import NewVideosComponent from "./newVideos";
import NewDateComponent from "./newDate";
import NewNumberComponent from "./newNumber";
import NewAgreementComponent from "./newAgreement";

const NewItemComponent = ({
  data,
  agreementIdFromOverview,
  handleSubmitDetail,
  custom_field_group_id,
  uniqNameValidator,
  mode,
  enqueueSnackbar,
  preselectType = "text",
  companyId,
  rfiInstance,
  checkboxFromDetails,
  indexOfCfGroup,
}) => {
  const popup = useModelPopup();
  const [selectedItem, setSelectedItem] = useState(
    data ? data.field_type : objectType[preselectType]
  );
  const [uniqName, setUniqName] = useState(true);

  const [showFooterButton, setShowFooterButtons] = useState(true);
  const [dataForValidation, setDataForValidation] = useState({});
  const dispatch = useDispatch();
  const formRef = useRef();
  const [isEditeble, setIsEditeble] = useState(false);

  const {
    councilReducer: { selectedCouncil },
    patientOrganizationsReducer: {
      patientOrganizationReducer: { selectedPatientOrganization },
    },
  } = useSelector((state) => state);

  const handleCancelClick = (e) => {
    dispatch(companyAgreementShouldOpenSuccess());
    popup.hide();
  };

  useEffect(() => {
    setIsEditeble(mode === "edit");
  }, [mode]);

  const handleSaveClick = () => {
    if (
      !rfiInstance &&
      formRef.current.values.type === "agreement" &&
      !formRef.current.values.agreement_type_id
    ) {
      formRef.current.setErrors({
        agreement_type_id: "Please select a Document type",
      });
      enqueueSnackbar("Please select a Document type", {
        type: "error",
      });
      return;
    }

    dispatch(companyAgreementShouldOpenSuccess());

    if (
      (formRef.current.values.type === "video" ||
        formRef.current.values.type === "slide") &&
      formRef.current.values.embedCode
    ) {
      enqueueSnackbar("Embed is not validated! Please press validate button!", {
        variant: "error",
      });
      return;
    }
    if (uniqNameValidator) {
      let validationObject;
      if (dataForValidation.title?.length) {
        validationObject = dataForValidation;
      } else {
        validationObject = formRef.current?.value;
      }
      const Validation = uniqNameValidator(validationObject);

      setUniqName(Validation);
      if (!Validation.passed && formRef.current.values.type !== "link") {
        return;
      }
    }
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleItemSelect = ({ val }) => {
    popup.setTitle({
      title: `${data ? "Edit" : "Add"} ${
        val.charAt(0).toUpperCase() + val.slice(1)
      }`,
    });
    setUniqName({ passed: true, title: "" });
    setSelectedItem(val);
  };

  const filteredOptions = optionItem.filter((item) => {
    if (selectedPatientOrganization.id) {
      return item.text !== "Agreement";
    }
    return item;
  });

  const handleOnSubmit = (val) => {
    handleSubmitDetail(
      {
        ...val,
        id: data?.id,
        field_type: val.field_type || data?.field_type,
      },
      data,
      custom_field_group_id
    );
    popup.hide();
  };

  const checkListFilter = () => {
    if (selectedPatientOrganization.id) {
      return (
        <NewCheckListComponentOrg
          data={data}
          formRef={formRef}
          mode={mode}
          uniqName={uniqName}
          handleOnSubmit={handleOnSubmit}
        />
      );
    }
    return (
      <NewCheckListComponent
        data={data}
        mode={mode}
        formRef={formRef}
        uniqName={uniqName}
        handleOnSubmit={handleOnSubmit}
        rfiInstance={rfiInstance}
        indexOfCfGroup={indexOfCfGroup}
      />
    );
  };

  const renderBody = () => {
    switch (selectedItem) {
      case objectType.text:
        return (
          <NewTextComponent
            data={data}
            formRef={formRef}
            uniqName={uniqName}
            handleOnSubmit={handleOnSubmit}
            indexOfCfGroup={indexOfCfGroup}
          />
        );
      case objectType.date:
        return (
          <NewDateComponent
            formRef={formRef}
            data={data}
            mode={mode}
            handleOnSubmit={handleOnSubmit}
            uniqName={uniqName}
            setShowFooterButtons={setShowFooterButtons}
            rfiInstance={rfiInstance}
            indexOfCfGroup={indexOfCfGroup}
          />
        );
      case objectType.number:
        return (
          <NewNumberComponent
            formRef={formRef}
            data={data}
            mode={mode}
            handleOnSubmit={handleOnSubmit}
            uniqName={uniqName}
            setShowFooterButtons={setShowFooterButtons}
            rfiInstance={rfiInstance}
            indexOfCfGroup={indexOfCfGroup}
          />
        );
      case objectType.checkList:
        return checkListFilter();
      case objectType.dropDown:
        return (
          <NewDropdownComponent
            formRef={formRef}
            mode={mode}
            data={data}
            uniqName={uniqName}
            handleOnSubmit={handleOnSubmit}
            rfiInstance={rfiInstance}
            indexOfCfGroup={indexOfCfGroup}
          />
        );
      case objectType.document:
        return (
          <NewDocumentComponent
            formRef={formRef}
            data={data}
            mode={mode}
            uniqName={uniqName}
            handleOnSubmit={handleOnSubmit}
            rfiInstance={rfiInstance}
            companyId={companyId}
            indexOfCfGroup={indexOfCfGroup}
          />
        );
      case objectType.link:
        return (
          <NewLinkComponent
            data={data}
            formRef={formRef}
            uniqName={uniqName}
            handleOnSubmit={handleOnSubmit}
            rfiInstance={rfiInstance}
            indexOfCfGroup={indexOfCfGroup}
          />
        );
      case objectType.image:
        return (
          <NewImageComponent
            data={data}
            formRef={formRef}
            uniqName={uniqName}
            handleOnSubmit={handleOnSubmit}
            rfiInstance={rfiInstance}
            companyId={companyId}
            indexOfCfGroup={indexOfCfGroup}
          />
        );
      case objectType.slides:
        return (
          <NewSlidesComponent
            formRef={formRef}
            data={data}
            mode={mode}
            uniqName={uniqName}
            handleOnSubmit={handleOnSubmit}
            setShowFooterButtons={setShowFooterButtons}
            rfiInstance={rfiInstance}
            companyId={companyId}
            indexOfCfGroup={indexOfCfGroup}
          />
        );
      case objectType.video:
        return (
          <NewVideosComponent
            formRef={formRef}
            data={data}
            mode={mode}
            uniqName={uniqName}
            handleOnSubmit={handleOnSubmit}
            setShowFooterButtons={setShowFooterButtons}
            rfiInstance={rfiInstance}
            companyId={companyId}
            indexOfCfGroup={indexOfCfGroup}
          />
        );
      case objectType.agreement:
        if (!selectedPatientOrganization.id) {
          return (
            <NewAgreementComponent
              formRef={formRef}
              data={data}
              agreementIdFromOverview={agreementIdFromOverview}
              mode={mode}
              uniqName={uniqName}
              setDataForValidation={setDataForValidation}
              handleOnSubmit={handleOnSubmit}
              setShowFooterButtons={setShowFooterButtons}
              companyId={companyId}
              rfiInstance={rfiInstance}
              checkboxFromDetails={checkboxFromDetails}
            />
          );
        }
      default:
        break;
    }
  };

  return (
    <div className={styles.wrapper}>
      <ModalBody className={styles.modalWrapper}>
        {selectedCouncil?.traction_tag === "kyndryl" &&
          selectedItem.toLowerCase() === "agreement" && (
            <span className={styles.instructionText}>
              All contracts should be generated through Kyndryl’s Icertis Tool.
              Please contact Jim Reedy (
              <a
                target="_blank"
                className={styles.link}
                href="mailto:Jim.Reedy@Kyndryl.com"
              >
                Jim.Reedy@Kyndryl.com
              </a>{" "}
              ) for more information
            </span>
          )}
        <div className="row">
          <div className="col-12">
            <Label>Item</Label>
            <DropSelect
              SelecedValues={selectedItem}
              onChange={handleItemSelect}
              placeholder="Select item to add"
              labelField="text"
              valueField="value"
              disabled={isEditeble}
              searchBy="text"
              options={filteredOptions}
              keepSelectedInList={false}
            />
          </div>
        </div>
        <div className={styles.modalWrapper}>{renderBody()}</div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button type="submit" btn={BtnType.REGULAR} onClick={handleSaveClick}>
          Save {selectedItem || ""}
        </Button>
      </ModalFooter>
    </div>
  );
};

export default withSnackbar(NewItemComponent);

import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select/Select";
import styles from "../listWrapper/listWrapper.module.scss";
import checkThemePipelinePermissions from "../../../../common/checkThemePipelinePermissions";
import { useSnackbar } from "notistack";

const FirmenichStage = ({
  company,
  relationShipStatuses,
  handleStageSelect,
  session,
  selectedCouncil,
}) => {
  const [stage, setStage] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (
      company?.council_relationship_stage_id &&
      relationShipStatuses?.length
    ) {
      const getStageDetails = relationShipStatuses.find(
        (s) => s.customId === company?.council_relationship_stage_id
      );

      setStage(getStageDetails);
    }
  }, [company, relationShipStatuses]);

  const onStageSelect = (evt, compa) => {
    const getStage = relationShipStatuses.find(
      (stage) => stage.slug === evt.target.value
    );
    if (
      checkThemePipelinePermissions(
        getStage?.name || "none",
        session?.council_role,
        selectedCouncil?.name,
        session?.decision_maker_access,
        company?.status ? company?.status : null
      )
    ) {
      setStage(getStage);
      const data = {
        council_relationship_stage_id: getStage?.customId || null,
      };
      handleStageSelect(company, data);
    } else {
      enqueueSnackbar("You are not allowed to move companies in this stage ", {
        variant: "error",
      });
    }
  };

  return (
    <Select
      value={stage?.slug || "none"}
      onChange={(e) => onStageSelect(e, stage)}
      style={{
        backgroundColor: stage?.colors?.background || "",
        color: stage?.colors?.text || "",
        fontSize: "14px",
      }}
      className={
        !stage?.slug || stage?.slug === "none"
          ? styles.tractionMatch
          : styles.detailsRelationship
      }
      disableUnderline
    >
      <MenuItem
        style={
          !checkThemePipelinePermissions(
            "none",
            session?.council_role,
            selectedCouncil?.name,
            session?.decision_maker_access,
            company.council_relationship_stage_id ? company.status : null
          )
            ? { background: "#929ba4", color: "white" }
            : {}
        }
        key="none"
        value="none"
      >
        Traction Match
      </MenuItem>

      {relationShipStatuses.map((item) => (
        <MenuItem
          style={
            !checkThemePipelinePermissions(
              item?.name,
              session?.council_role,
              selectedCouncil?.name,
              session?.decision_maker_access,
              company.council_relationship_stage_id ? company.status : null
            )
              ? { background: "#929ba4", color: "white" }
              : {}
          }
          key={item.slug}
          value={item.slug}
        >
          {item?.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default React.memo(FirmenichStage);

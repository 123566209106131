import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Loading, Label, TextBox } from "modules/primitives/index";
import { useHistory } from "react-router-dom";
import DragAndDrop from "common/components/dragAndDrop";
import checkUniqName from "common/checkUniqName";
import checkDuplicateName from "common/checkDuplicateName";

import SimpleDialog from "../../../../modules/companies/company/details/confirmationModal";
import { Icon, mdSize } from "../../../icon";
import useS3FileUpload from "../../../hooks/s3FileUplodar.hook";
import { model, validation } from "./constant";
import styles from "./styles.module.scss";
import LinearProgressWithLabel from "../../../components/linearProgressWithLabel";
import UniqName from "../uniqName/uniqName";
import findCurrentCustomFieldGroup from "../../../findCustomFieldGroup";

const NewDocumentComponent = ({
  handleOnSubmit,
  formRef,
  mode,
  data,
  uniqName,
  fromAdmin = false,
  rfiInstance,
  companyId,
  adminLocation,
  selectedCustomFieldGroup,
  indexOfCfGroup,
}) => {
  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: { selectedPatientOrganization },
    },
    companiesReducer: {
      companyReducer: { newCustomFieldGroups },
    },
    adminCustomFieldsReducer: { customFieldGroups },
    authReducer: { session },
  } = useSelector((state) => state);
  const [initModel, setInitModel] = useState({ ...model });
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmCompanyModal, setOpenConfirmCompanyModal] = useState(false);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const [index, setIndex] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [titleError, setTitleError] = useState("");
  const [uploadValues, setUploadValues] = useState([]);
  const { fileUpload, fileDelete } = useS3FileUpload();
  const [documentDeleted, setDocumentDeleted] = useState(false);
  const [companyDeletingDoc, setCompanyDeletingDoc] = useState({});
  const history = useHistory();
  const initValues = data
    ? {
        field_type: data.field_type,
        // field_value: data.documents,
        title: data.field_name,
        multiselect: data.multiselect,
        expiration_date: data.expiration_date,
        field_placeholder: data.field_placeholder || "",
      }
    : null;
  const isPatientOrg =
    history.location.pathname.indexOf("organizations") !== -1;

  if (data) {
    data.option_values.map((el, index) => {
      initValues[index] = el;
    });
  }

  const handleOpenModal = (e, index) => {
    setOpenConfirmModal(true);
    setIndex(index);
    e.stopPropagation();
  };

  const handleDismiss = () => {
    setOpenConfirmModal(false);
    setOpenConfirmCompanyModal(false);
  };

  const getFilename = (url) => {
    if (url) {
      return url.split("/").pop();
    }
    return "";
  };

  const handleDeleteCompanyDocument = async (e) => {
    e.stopPropagation();
    setLoading(true);
    setOpenConfirmCompanyModal(false);
    const pathname = `/documents/${selectedCompany?.id || companyId}/${
      companyDeletingDoc.name
    }`;

    await fileDelete(pathname).then(() => {
      setLoading(false);
      const filteredDocuments = documents.filter(
        (doc) => doc.id !== companyDeletingDoc.id
      );
      setDocuments(filteredDocuments);
    });
    setCompanyDeletingDoc({});
  };

  const handleOpenCompanyModal = (e, doc) => {
    e.stopPropagation();
    setCompanyDeletingDoc(doc);
    setOpenConfirmCompanyModal(true);
  };

  const handleDeleteDocument = async (e) => {
    e.stopPropagation();
    setOpenConfirmModal(false);
    setIsDeleting("");
    setDocuments(() =>
      documents.map((doc, idx) => {
        if (idx === index) {
          return {
            ...doc,
            _destroy: true,
          };
        }
        return doc;
      })
    );

    setIndex(null);
    setDocumentDeleted(true);
  };

  const uploadHandler = async (e, action) => {
    if (forbiddenFiles.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }

    const fileList = [];
    let dismissedFiles = 0;

    setLoading(true);
    setUploadValues((items) => {
      const currentFiles = [...items];

      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name,
      }));
      return [...currentFiles, ...newFiles];
    });

    files.forEach(async (file) => {
      if (file.size <= 20971520) {
        const pathname = `srm/${selectedCouncil.id}/${
          isPatientOrg ? "patient_organizations" : "companies"
        }/${
          isPatientOrg
            ? selectedPatientOrganization?.id
            : selectedCompany?.id || companyId
        }/slides`;
        await fileUpload(file, pathname, setUploadValues, file.name).then(
          (res) => {
            if (res && res.location) {
              if (
                documents?.indexOf(res.location) === -1 ||
                documents?.indexOf(res.location) === undefined
              ) {
                fileList.push(res.location);
                setLoading(false);
                if (fileList.length === files.length - dismissedFiles) {
                  setDocuments([
                    ...documents,
                    ...fileList.map((uri) => ({
                      uri,
                      name: getFilename(uri),
                      council_id: selectedCouncil.id,
                      added_by_id: session.id,
                      rfi: rfiInstance,
                    })),
                  ]);
                }
              } else {
                enqueueSnackbar("This document was already added.", {
                  variant: "error",
                });
                setLoading(false);
                dismissedFiles++;
                setForbiddenFiles((prevFiles) => {
                  const localFiles = [...prevFiles];
                  localFiles.push({
                    name: file.name,
                    error: "Document already added.",
                  });
                  return localFiles;
                });
                setUploadValues((prevState) => {
                  const currentProgress = [...prevState];
                  const index = currentProgress
                    .map((item) => item.name)
                    .lastIndexOf(file.name);
                  currentProgress[index] = {
                    progress: "NOT_ALLOWED",
                    name: file.name,
                  };
                  return currentProgress;
                });
              }
            }
          }
        );
      } else {
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === file.name
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: file.name,
          };
          return currentProgress;
        });
        setLoading(false);
        if (file.size > 10000000) {
          dismissedFiles++;
          setLoading(false);
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error: "Your file is too large. File size limit: 100MB",
            });
            return localFiles;
          });
          enqueueSnackbar(
            "We could not upload your document because your file size is too big. Please make sure the file is less than 20 MB",
            {
              variant: "error",
            }
          );
        }
      }
    });

    e.target.value = "";
  };

  const onSubmit = (values) => {
    const cfGroup =
      findCurrentCustomFieldGroup(
        selectedCustomFieldGroup,
        customFieldGroups,
        newCustomFieldGroups,
        indexOfCfGroup,
        data
      ) || [];

    if (!values.title.length) {
      const field_name = checkDuplicateName(cfGroup, "document");
      values.title = field_name;
    } else if (
      !checkUniqName(values.title, cfGroup) &&
      values.title?.trim()?.toLowerCase() !==
        data?.field_name?.trim()?.toLowerCase()
    ) {
      setTitleError("Field name must be unique. Please rename this field");
      return;
    }
    setTitleError("");

    if (data) {
      const optionsContainer = [];
      let i = 0;

      do {
        optionsContainer.push(values[i]);
        i += 1;
      } while (values.hasOwnProperty(i));
      values.options = optionsContainer;
    } else {
      values.options = [];
    }

    const submitData = {
      ...values,
      documents_attributes: documents.filter((doc) => !doc.isAdd),
      expiration_date: initModel.expiration_date || data.expiration_date,
    };
    handleOnSubmit(submitData);
    values.title = "";
    // } else {
    //   enqueueSnackbar("Please add a document before procceeding.", {
    //     variant: "error",
    //   });
    // }
  };
  const handleOpenLink = (link) => {
    window.open(link);
  };

  useEffect(() => {
    if (mode === "edit") {
      const editModel = {
        title: data.field_name,
      };
      setDocuments(data?.documents?.length ? data.documents : []);
      setInitModel({ ...initModel, ...editModel });
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <SimpleDialog
        open={openConfirmModal}
        handleDismiss={() => handleDismiss()}
        handleDelete={(e) => handleDeleteDocument(e)}
        openConfirmModal={openConfirmModal}
      />
      <SimpleDialog
        open={openConfirmCompanyModal}
        handleDismiss={() => handleDismiss()}
        handleDelete={(e) => handleDeleteCompanyDocument(e)}
        openConfirmModal={openConfirmCompanyModal}
      />
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={data ? initValues : initModel}
        validationSchema={validation && validation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          onSubmit(values);
        }}
      >
        {({ handleSubmit, values, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <Label>Title (optional)</Label>
            <TextBox
              disabled={rfiInstance}
              type="text"
              name="title"
              placeholder="Enter document title here"
              formProps={{ ...formikprops, values }}
              className={styles.textBox}
            />
            {titleError.length > 1 && (
              <div className={styles.errorBox}>
                <sup>*</sup>
                {titleError}
              </div>
            )}
            <UniqName uniqName={uniqName} />

            {!adminLocation && (
              <div className="d-flex flex-column align-items-start">
                <Label>Instructions (Optional)</Label>
                <TextBox
                  type="text"
                  name="field_placeholder"
                  placeholder="Add instructions"
                  formProps={{ ...formikprops, values }}
                  className={styles.textBox}
                />
              </div>
            )}
            {!fromAdmin && (
              <>
                <Label>Upload document</Label>
                <p className={styles.instructionText}>
                  Documents accept all file formats (.pdf, .xls, .pptx, .doc,
                  etc.)
                </p>

                {uploadValues?.length
                  ? uploadValues.map(
                      (bar, index) =>
                        bar &&
                        bar?.progress !== "NOT_ALLOWED" && (
                          <div className={styles.loaderContainer}>
                            <div className={styles.loaderNamingWrp}>
                              {" "}
                              <div className={styles.fileName}>
                                {uploadValues[index]?.name}
                              </div>
                              <LinearProgressWithLabel
                                mode={
                                  uploadValues[index]?.progress === "FAILED" &&
                                  "error"
                                }
                                value={
                                  uploadValues[index]?.progress === "FAILED"
                                    ? 100
                                    : uploadValues[index]?.progress || 0
                                }
                              />
                            </div>
                          </div>
                        )
                    )
                  : null}

                <DragAndDrop
                  uploadHandler={uploadHandler}
                  loading={loading}
                  customText="drag and drop documents here"
                />
              </>
            )}

            {forbiddenFiles?.length ? (
              <div className={styles.forbiddenfileContainer}>
                Files that weren't allowed:{" "}
                {forbiddenFiles.map((file) => (
                  <span className={styles.forbiddenfileName}>
                    {`${file.name}: `}
                    <span className={styles.errorText}> {file.error}</span>
                  </span>
                ))}
              </div>
            ) : null}

            {rfiInstance ? (
              <div className={styles.inputWrapper}>
                {documents && documents.length
                  ? documents.map((doc, index) =>
                      doc.id ? null : (
                        <div
                          className={styles.item}
                          onClick={() => handleOpenLink(doc.uri)}
                        >
                          <div className={styles.name}> {doc.uri}</div>
                          <div className={styles.delete}>
                            <Icon
                              {...mdSize}
                              icon="icn-button-delete"
                              onClick={(e) =>
                                doc.document_resource_type ===
                                  "CompanyCustomField" || rfiInstance
                                  ? handleOpenModal(e, index)
                                  : handleOpenCompanyModal(e, doc)
                              }
                            />
                          </div>
                        </div>
                      )
                    )
                  : null}
              </div>
            ) : (
              <div className={styles.inputWrapper}>
                {documents && documents.length
                  ? documents.map((doc, index) =>
                      isDeleting === index ? (
                        <Loading />
                      ) : !doc._destroy ? (
                        <div
                          className={styles.item}
                          onClick={() => handleOpenLink(doc.uri)}
                        >
                          <div className={styles.name}> {doc.uri}</div>
                          <div className={styles.delete}>
                            <Icon
                              {...mdSize}
                              icon="icn-button-delete"
                              onClick={(e) =>
                                doc.document_resource_type ===
                                  "CompanyCustomField" || rfiInstance
                                  ? handleOpenModal(e, index)
                                  : handleOpenCompanyModal(e, doc)
                              }
                            />
                          </div>
                        </div>
                      ) : null
                    )
                  : null}
              </div>
            )}
          </form>
        )}
      </Formik>
      <div />
    </div>
  );
};

export default NewDocumentComponent;

import { pageMeta } from "../../../common/httpCall";

import {
  COUNCILS_GET_ATTEMPT,
  COUNCILS_GET_SUCCESS,
  COUNCILS_POST_ATTEMPT,
  COUNCILS_POST_SUCCESS,
  COUNCILS_DELETE_ATTEMPT,
  COUNCILS_DELETE_SUCCESS,
  COUNCILS_EDIT_ATTEMPT,
  COUNCILS_EDIT_SUCCESS,
  COUNCILS_GET_INDUSTRIES_ACROSS_ATTEMPT,
  COUNCILS_GET_INDUSTRIES_ACROSS_SUCCESS,
  COUNCILS_GET_TECNOLOGIES_ACROSS_ATTEMPT,
  COUNCILS_GET_TECNOLOGIES_ACROSS_SUCCESS,
  COUNCILS_GET_BY_ID_ATTEMPT,
  COUNCILS_GET_BY_ID_SUCCESS,
  RESET_LOADING,
  COUNCILS_SPECIFIC_STAGES_GET_BY_ID_SUCCESS,
  COUNCILS_SPECIFIC_STAGES_GET_BY_ID_ATTEMPT,
} from "./councils.action";

const initState = {
  loadingCouncils: false,
  loadingTecnologies: false,
  loadingIndustries: false,
  loadingCouncilData: false,
  pagination: {},
  councilData: {},
  topics: [],
  industries: [],
  councils: [],
  councilSpecificRelStages: [],
};
const councilsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COUNCILS_GET_ATTEMPT:
      return {
        ...state,
        loadingCouncils: true,
      };
    case COUNCILS_GET_SUCCESS:
      const pagination = pageMeta(payload);
      return {
        ...state,
        councils: payload?.response,
        loadingCouncils: false,
        pageSetting: pagination,
      };
    case COUNCILS_POST_ATTEMPT:
      return {
        ...state,
        loadingCouncils: true,
      };
    case COUNCILS_POST_SUCCESS:
      return {
        ...state,
        councils: [...state.councils, payload?.response],
        loadingCouncils: false,
      };
    case COUNCILS_DELETE_ATTEMPT:
      return {
        ...state,
        loadingCouncils: true,
      };
    case COUNCILS_DELETE_SUCCESS:
      return {
        ...state,
        councils: payload?.response,
        loadingCouncils: false,
      };
    case COUNCILS_EDIT_ATTEMPT:
      return {
        ...state,
        loadingCouncils: true,
        loadingCouncilData: true,
      };
    case COUNCILS_EDIT_SUCCESS:
      return {
        ...state,
        councilData: payload.response,
        loadingCouncilData: false,
        loadingCouncils: false,
      };
    case COUNCILS_GET_INDUSTRIES_ACROSS_ATTEMPT:
      return {
        ...state,
        loadingIndustries: true,
      };
    case COUNCILS_GET_INDUSTRIES_ACROSS_SUCCESS:
      return {
        ...state,
        industries: payload.response,
        loadingIndustries: false,
      };
    case COUNCILS_GET_TECNOLOGIES_ACROSS_ATTEMPT:
      return {
        ...state,
        loadingTecnologies: true,
      };
    case COUNCILS_GET_TECNOLOGIES_ACROSS_SUCCESS:
      return {
        ...state,
        topics: payload.response,
        loadingTecnologies: false,
      };
    case COUNCILS_GET_BY_ID_ATTEMPT:
      return {
        ...state,
        loadingCouncilData: true,
      };
    case COUNCILS_GET_BY_ID_SUCCESS:
      return {
        ...state,
        councilData: payload.response,
        loadingCouncilData: false,
      };
    case RESET_LOADING:
      return {
        ...state,
        loadingCouncils: false,
      };

    case COUNCILS_SPECIFIC_STAGES_GET_BY_ID_ATTEMPT:
      return {
        ...state,
        loadingCouncilSpecificRelStages: true,
      };
    case COUNCILS_SPECIFIC_STAGES_GET_BY_ID_SUCCESS:
      return {
        ...state,
        councilSpecificRelStages: payload.response,
        loadingCouncilSpecificRelStages: false,
      };

    default:
      return state;
  }
};
export default councilsReducer;

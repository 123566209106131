import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useSnackbar } from "notistack";
import styles from "./companyDiscussions.module.scss";
import {
  companyDiscussionsGet,
  patientOrgDiscussionsGet,
  setSelectedDiscussion,
} from "../../../../common/actions/discussions.action";
import NewDiscussion from "../../../../common/components/newDiscussion/newDiscussion.component";
import { appModelSettingSet } from "application/app.action";
import DiscussionItem from "./discussionItem/discussionItem.component";
import DiscussionMobileItem from "./discussionMobileItem/discussionMobileItem.component";
import DiscussionDetails from "../../../../common/components/discussionDetails";
import { BtnType, Button, Loading } from "../../../primitives";
import { useWindowSize } from "../../../../common/hooks/useWindowSize";

const CompanyDiscussions = ({ isPatientOrg, companyId }) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const {
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
    discussionsReducer: {
      companyDiscussions,
      patientOrgDiscussions,
      updateCompany,
      loading,
      selectedDiscussion,
    },
    companiesReducer: {
      companyReducer: { companyIsDownloading },
    },
  } = useSelector((state) => state);
  const [showDetailsWindow, setShowDetailsWindow] = useState(false);
  const [discussions, setDiscussions] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const getData = () => {
    if (isPatientOrg) {
      dispatch(
        patientOrgDiscussionsGet({
          patientOrgId: companyId,
          enqueueSnackbar,
        }),
      );

      return;
    }

    if (!selectedCouncil) {
      return;
    }

    dispatch(
      companyDiscussionsGet({
        councilId: selectedCouncil.id,
        companyId,
        enqueueSnackbar,
      }),
    );
  };

  useEffect(() => {
    getData();

    const interval = setInterval(() => {
      getData();
    }, 10000);

    return () => clearInterval(interval);
  }, [companyId]);

  useEffect(() => {
    if (companyDiscussions && !isPatientOrg) {
      setDiscussions(companyDiscussions);
    }
    if (patientOrgDiscussions && isPatientOrg) {
      setDiscussions(patientOrgDiscussions);
    }
  }, [companyDiscussions, isPatientOrg, patientOrgDiscussions]);

  useEffect(() => {
    if (updateCompany && selectedCouncil && !isPatientOrg && companyId) {
      dispatch(
        companyDiscussionsGet({
          councilId: selectedCouncil.id,
          companyId,
          enqueueSnackbar,
        }),
      );
    }
  }, [updateCompany, selectedCouncil, isPatientOrg, companyId]);

  const startNewDiscussion = () => {
    const customSettings = {
      title: "Start a New Discussion",
      show: true,
      height: isMobile ? null : "550",
      width: isMobile ? null : "540",
      component: (
        <NewDiscussion
          type={isPatientOrg ? "PatientOrganization" : "Company"}
          isPrivate={false}
          companyId={companyId}
        />
      ),
    };
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      }),
    );
  };

  const handleShow = (details) => {
    setShowDetailsWindow(true);
    dispatch(setSelectedDiscussion(details));
  };

  const handleClose = () => {
    setShowDetailsWindow(false);
    dispatch(setSelectedDiscussion({}));
  };

  return (
    <section
      className={`${styles.companyDiscussionsWrp} ${
        companyIsDownloading ? "mx-4" : ""
      }`}
    >
      {showDetailsWindow && (
        <DiscussionDetails
          handleClose={handleClose}
          details={selectedDiscussion}
        />
      )}
      <div className={styles.headerWrp}>
        <span className={styles.title}>Discussions</span>
        <Button
          className={styles.newTask}
          btn={BtnType.FRAME_LESS}
          icon="icn-add"
          onClick={startNewDiscussion}
        >
          New Discussion
        </Button>
      </div>
      <div className={styles.discussionsList}>
        {discussions?.length ? (
          discussions.map((discussion) => (isMobile ? (
            <DiscussionMobileItem
              key={discussion.id}
              discussion={discussion}
              handleShow={() => handleShow({
                ...discussion,
                prop: isPatientOrg
                  ? "patientOrgDiscussions"
                  : "companyDiscussions",
              })}
            />
          ) : (
            <DiscussionItem
              key={discussion.id}
              discussion={discussion}
              handleShow={() => handleShow({
                ...discussion,
                prop: isPatientOrg
                  ? "patientOrgDiscussions"
                  : "companyDiscussions",
              })}
            />
          )))
        ) : (
          <div className={styles.noData}>
            {loading ? <Loading /> : "Start a Discussion"}
          </div>
        )}
      </div>
    </section>
  );
};

export default React.memo(CompanyDiscussions);

import { useDispatch, useSelector } from "react-redux";
import { appModelSettingSet } from "application/app.action";

const useModelPopup = () => {
  const dispatch = useDispatch();
  const {
    appReducer: { modalSetting },
  } = useSelector((state) => state);

  const show = ({
    component,
    title = "",
    width = "540",
    height = "400",
    fullScreen,
  }) => {
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        title,
        show: true,
        height,
        width,
        component,
        fullScreen,
      }),
    );
  };
  const hide = () => {
    dispatch(
      appModelSettingSet({
        show: false,
        component: null,
      }),
    );
  };

  const setTitle = ({ title }) => {
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        title,
      }),
    );
  };

  return { show, hide, setTitle };
};

export default useModelPopup;

import React  from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import styles from "./productOwners.module.scss";
import { BtnType, Button, MoreOptions, UserInline, UserInlineTypes } from "modules/primitives";
import useModelPopup from "common/hooks/modelPopup.hook";
import { moreOptionTypes } from "modules/companies/company/overview/companyContact/companyContact.constant";
import EditRelationship from "../../../../../../common/components/editRelationship";
import { deleteProductOwner } from "modules/companies/product/store/product.actions";
import { Icon, lgSize } from "../../../../../../common/icon";
import NewDiscussion from "../../../../../../common/components/newDiscussion/newDiscussion.component";

const ProductOwners = ({ product, canEdit }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();

  const handleAddOwners = () => {
    popup.show({
      show: true,
      title: "Add New Relationship Owner",
      component: (
        <EditRelationship
          cb={() => {}}
          mode="add"
          isProductInstance
          product={product}
        />
      ),
    });
  };

  const handleMoreOptionClick = (options, owner) => {
    if (options === 6) {
      dispatch(deleteProductOwner({
        company_id: product.company.id,
        product_id: product.id,
        owner_id: owner.id,
        enqueueSnackbar,
      }))
    }
  };

  const handleShow = (details) => {
    if (details.showNewModal) {
      popup.show({
        show: true,
        title: "Start a New Private Discussion",
        component: (
          <NewDiscussion type="Council" isPrivate selectedUser={details} />
        ),
      });
    }
  };

  return (
    <div className={styles.productOwners}>
      {
        product.product_owners?.length ? (
          <div className="mb-4">
            {
              product.product_owners.map((owner) => (
                <div key={owner.id} className="mb-2">
                  <div className="d-flex justify-content-between">
                    <div className={styles.profileContainer}>
                      <UserInline
                        classObj={{ name: styles.userName }}
                        type={UserInlineTypes.WITH_NAME}
                        id={owner.user_id}
                        first={owner.user.first_name || ""}
                        last={owner.user.last_name || ""}
                        avatar={owner.user.avatar}
                        noNavigation
                        local
                      />
                      <div className={classNames("caption small", styles.position)}>
                        {owner.user?.title}
                      </div>
                      <div className={classNames("caption small", styles.position)}>
                        {owner.user?.company}
                      </div>
                    </div>
                    <div className={styles.rightContainer}>
                      <div className={styles.iconContainer}>
                        <Icon
                          {...lgSize}
                          icon="icn-table-message"
                          onClick={() => {
                            handleShow({
                              full_name: owner.user.full_name,
                              id: owner.user_id,
                              isPrivate: true,
                              showNewModal: !owner.user.discussion,
                              prop: "privateDiscussions",
                            });
                          }}
                        />
                      </div>
                      <MoreOptions
                        options={[{
                          val: moreOptionTypes.delete,
                          text: "Delete",
                        }]}
                        onClick={(val) => handleMoreOptionClick(val, owner)}
                      />
                    </div>
                  </div>
                </div>
              ))
            }
            {
              canEdit ? (
                <div className="d-flex justify-content-end mt-3">
                  <Button
                    className="mt-3"
                    onClick={handleAddOwners}
                    btn={BtnType.REGULAR}
                  >
                    ADD OWNER
                  </Button>
                </div>
              ) : null
            }
          </div>
        ) : null
      }
      {
        !product.product_owners?.length && canEdit ? (
          <div className="d-flex align-items-center flex-column">
            <span>No owners yet.</span>
            <Button
              className="mt-3"
              onClick={handleAddOwners}
              btn={BtnType.REGULAR}
            >
              ADD OWNERS
            </Button>
          </div>
        ) : null
      }
    </div>
  );
};

export default ProductOwners;

/* eslint-disable camelcase */
import * as Yup from "yup";

export const initModel = {
  name: "",
  web_url: "",
  promo_video: "",
  description: "",
  employees: "",
  contact_email: "",
  council_relationship_stage_name: "",
  location: "",
  founded: "",
  logo: "",
  twitter: "",
  linkedin: "",
  membership_size: "",
  geographic_reach: "",
  country: "",
  disease_ids: [],
  diversity_groups_ids: [],
};
export const validation = Yup.object().shape({
  name: Yup.string().required("Title is required"),
  web_url: Yup.string().required("Website is required"),
  founded: Yup.date().max(new Date(), "Date cannot be in the future"),
});
export const validationPO = Yup.object().shape({
  name: Yup.string().required("Title is required"),
  founded: Yup.date().max(new Date(), "Date cannot be in the future"),
});

export const geographicReach = [
  { name: "regional" },
  { name: "national" },
  { name: "multi_national" },
  { name: "global" },
];

export const videoExtensions = [
  "mp4",
  "mov",
  "wmv",
  "flv",
  "avi",
  "avi",
  "webm",
  "mkv",
];

export const moreOptions = [
  { val: "profile", text: "Profile" },
  { val: "delete", text: "Delete" },
];

export const revenuOptions = [
  {
    text: "Less than $1M",
    value: "r_00000000",
  },
  {
    text: "$1M to $10M",
    value: "r_00001000",
  },
  {
    text: "$10M to $50M",
    value: "r_00010000",
  },
  {
    text: "$50M to $100M",
    value: "r_00050000",
  },
  {
    text: "$100M to $500M",
    value: "r_00100000",
  },
  {
    text: "$500M to $1B",
    value: "r_00500000",
  },
  {
    text: "$1B to $10B",
    value: "r_01000000",
  },
  {
    text: "$10B+",
    value: "r_10000000",
  },
];

export const partnerOptions = [
  {
    text: "-",
    value: "none",
  },
  {
    text: "Alliance",
    value: "alliance",
  },
  {
    text: "Innovation",
    value: "innovation",
  },
  {
    text: "Vendor",
    value: "vendor",
  },
];

export const HeaderOptions = [
  {
    value: "profile",
    text: "Company Profile",
  },
  {
    value: "ratings",
    text: "Company Ratings",
  },
];

export const businessUnitOptions = [
  { id: "791f47d6-e80d-4a40-8aa4-6cb3569a0204", name: "Single Family" },
  { id: "4f28de92-19d5-4812-a190-0a545de7d9c8", name: "Multifamily" },
];

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import classnames from "classnames";

import { MoreOptions, Loading, BtnType, Button } from "modules/primitives";
import { isCreator } from "../../../checkers/isCreator";
import styles from "./styles.module.scss";
import { ACTIVITY_OPTIONS } from "../../../constants";
import { Icon, smSize } from "../../../icon";
import RichEditor from "../../richEditor";

function extractContent(html) {
  return new DOMParser().parseFromString(html, "text/html").documentElement
    .textContent;
}

const TextComponent = ({
  item,
  handleEditClick,
  blockFunctionality = false,
  handleDeleteClick,
  handleSubmitDetail,
  focus = true,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    companiesReducer: {
      companyReducer: { customFieldLoadingId, selectedCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: {
        selectedPatientOrganization,
        customFieldLoadingIdOrg,
      },
    },
  } = useSelector((state) => state);
  const [loadingId, setLoadingId] = useState("");
  const [fieldId, setFieldId] = useState("");
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);
  const [fieldValue, setFieldValue] = useState("");
  const [showField, setShowField] = useState(false);
  const [wordsLength, setWordsLength] = useState(0);

  useEffect(() => {
    const value = extractContent(item?.field_value || "");

    if (item.big_box_text) {
      setFieldValue(item.field_value);
      const wordsNr = item.field_value?.trim().split(/\s+/).length;
      setWordsLength(wordsNr || 0);
    } else {
      setFieldValue(value);
    }

    setShowField(!value?.length);
  }, [item]);

  useEffect(() => {
    if (isCreator(item?.creator?.user_id)) {
      setHasAccessToEdit(true);
    }
  }, [item]);

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }

    if (value === 1) {
      handleDeleteClick(item);
    }
  };

  useEffect(() => {
    if (customFieldLoadingId || customFieldLoadingIdOrg) {
      if (selectedPatientOrganization.id) {
        setLoadingId(customFieldLoadingIdOrg);
      } else {
        setLoadingId(customFieldLoadingId);
      }
    }
  }, [customFieldLoadingId, customFieldLoadingIdOrg]);

  useEffect(() => {
    if (selectedPatientOrganization.id) {
      setFieldId(item.patient_organization_custom_field_id);
    } else {
      setFieldId(item.company_custom_field);
    }
  }, [selectedPatientOrganization, item]);

  const handleSaveClick = () => {
    if (!handleSubmitDetail) {
      return;
    }

    if (item.big_box_text && wordsLength > (item.max_words || 10000)) {
      enqueueSnackbar(
        `Too many words (${wordsLength}), maximum allowed words are ${
          item.max_words || 10000
        }`,
        {
          variant: "error",
        }
      );
      return;
    }

    const payload = {
      ...item,
      field_value: fieldValue,
    };

    handleSubmitDetail(payload, item);

    setShowField(false);
  };

  const handleFieldChange = (value) => {
    if (!value || !value?.length) {
      setWordsLength(0);
    } else {
      const wordsNr = value.trim().split(/\s+/).length;
      setWordsLength(wordsNr);
    }

    setFieldValue(value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{item?.field_name || "Text"}</h5>
          {item?.field_placeholder?.length ? (
            <span
              dangerouslySetInnerHTML={{
                __html: item?.field_placeholder || "",
              }}
              className="text-muted small"
            ></span>
          ) : null}
        </div>
        <div className={styles.rightWrp}>
          <div className={styles.colMore}>
            {!blockFunctionality && hasAccessToEdit ? (
              <MoreOptions
                options={ACTIVITY_OPTIONS}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            ) : null}
          </div>
        </div>
      </div>
      {item.from_claimed_profile && (
        <span className="d-block small font-weight-bold mb-3">
          Company Response
        </span>
      )}
      {loadingId === fieldId ? (
        <Loading />
      ) : showField ? null : (
        <div className="d-inline-flex w-100 overflow-hidden">
          <p
            className={classNames("small overflow-hidden", styles.description)}
            dangerouslySetInnerHTML={{ __html: fieldValue }}
          />
          <div
            className="cursor-pointer ml-2"
            onClick={() => setShowField(true)}
          >
            <Icon {...smSize} icon="icn-edit" />
          </div>
        </div>
      )}

      {showField ? (
        <div className="w-75 d-flex align-items-center">
          {item.big_box_text ? (
            <div className="w-100 mr-3">
              <RichEditor
                updateValue={handleFieldChange}
                initialValue={fieldValue}
                placeholder={item?.field_placeholder || "Enter text"}
                showAditionalButtons={false}
                dontFocus={!focus}
              />
              {item.max_words ? (
                <span
                  className={classnames(
                    wordsLength > item.max_words ? "text-danger" : ""
                  )}
                >
                  {wordsLength}/{item.max_words}
                </span>
              ) : null}
            </div>
          ) : (
            <input
              className="w-100 border mr-3 small p-2"
              value={fieldValue}
              // placeholder={item?.field_placeholder}s
              onChange={(evt) => handleFieldChange(evt.target.value)}
            />
          )}
          <Button btn={BtnType.FRAME_LESS} onClick={handleSaveClick}>
            Save
          </Button>
        </div>
      ) : null}

      {item && item.creator ? (
        <div className={styles.infoWrp}>
          <div className={styles.dataRow}>
            <div className={styles.label}>
              {item.from_submission
                ? "Submitted by"
                : item.updated_at !== item.created_at
                ? "Updated by"
                : "Created by"}
            </div>
            <div className={styles.value}>
              {item.from_submission ? (
                <span>{item?.creator?.full_name || "-"}</span>
              ) : (
                <Link to={`/people/internal/profile/${item.user_id}`}>
                  {item && item.creator && item.creator.full_name}
                  {item.from_claimed_profile && (
                    <span>, {selectedCompany?.name}</span>
                  )}
                </Link>
              )}
            </div>
          </div>
          {item.from_claimed_profile && (
            <div className="d-flex align-items-center mr-5">
              <div className={styles.companyAccess}>
                <span>&#x2713; Access</span>
              </div>
            </div>
          )}
          <div className={styles.date}>
            {item.updated_at !== item.created_at
              ? format(new Date(item.updated_at), "eeee, MMM do, yyyy hh:mm a")
              : format(new Date(item.created_at), "eeee, MMM do, yyyy hh:mm a")}
          </div>
          {item.from_submission ? (
            <div
              className={`d-flex flex-grow-1 align-items-center justify-content-center ${styles.companyResponse}`}
            >
              Company Response
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
export default React.memo(TextComponent);

import * as Yup from "yup";

export const moreOptionTypes = {
  setExpert: 0,
  leaveTheme: 1,
};

export const membersOptions = [
  {
    val: moreOptionTypes.setExpert,
    text: "Expert",
  },
  {
    val: moreOptionTypes.leaveTheme,
    text: "Leave Theme",
  },
];

export const initModel = {
  memberId: "",
  role: "member",
};

export const roles = [
  { text: "Member", val: "member" },
  { text: "Manager", val: "manager" },
];

export const validation = Yup.object().shape({
  memberId: Yup.string().required("member is required"),
  role: Yup.string().required("permission is required"),
});

import React, { useEffect, useState } from "react";
import { CheckBox, CheckType } from "../../../../../primitives";

const RowCheckBoxesListComponent = ({ data }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    if (data && !list.length) {
      const makeList = data.options_score_values.map((option) => {
        const selected = data.options_score_selected.some(
          (s) => s.id === option.id,
        );
        return {
          ...option,
          selected,
        };
      });

      setList(makeList);
    }

    return () => null;
  }, [data]);

  return (
    <div className="w-100 pl-5 pb-3 pt-3">
      {list.map((item, i) => (
        <div key={item.id + i} className="d-flex align-items-center">
          <CheckBox
            checkType={CheckType.BLUE}
            isChecked={item.selected}
            disabled
          />
          <span>{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default React.memo(RowCheckBoxesListComponent);

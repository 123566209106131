import {
  PEOPLE_LIST_CONTACT_SUCCESS,
  PEOPLE_DELETE_CONTACT_SUCCESS,
  PEOPLE_ADD_ORGANIZATION_CONTACT_SUCCESS,
  PEOPLE_ADD_ORGANIZATION_CONTACT_ATTEMPT,
  COMPANY_ADD_CONTACT_SUCCESS,
  COMPANY_ADD_CONTACT_ATTEMPT,
  COMPANY_EDIT_CONTACT_ATTEMPT,
  COMPANY_EDIT_CONTACT_SUCCESS,
  COMPANY_CONTACT_SUCCESS,
} from "./companyContacts.action";

import { COMPANY_CONTACT_INVITE_SUCCESS } from "../../companies/company/company.action";

import { apiStatus } from "../../../common/httpCall";

const initState = {
  contactList: [],
  contactListMeta: {},
  updateOrgContacts: false,
};
const companyContactsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PEOPLE_LIST_CONTACT_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          contactList: response,
          updateContacts: false,
          contactListMeta: {
            total: payload.xhr.getResponseHeader("total-count"),
            pageItems: payload.xhr.getResponseHeader("page-items"),
          },
        };
      }
    }
    case PEOPLE_DELETE_CONTACT_SUCCESS: {
      const { contactId, data } = payload;
      return {
        ...state,
        contactList: state.contactList.filter(
          (contact) => contact.id !== contactId,
        ),
        contactListMeta: {
          ...state.contactListMeta,
          count: state.contactListMeta.count - 1,
        },
      };
    }
    case PEOPLE_ADD_ORGANIZATION_CONTACT_ATTEMPT: {
      return {
        ...state,
        updateOrgContacts: false,
      };
    }

    case PEOPLE_ADD_ORGANIZATION_CONTACT_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.CREATED) {
        return {
          ...state,
          contactList: [...state.contactList, response],
          updateOrgContacts: true,
        };
      }
    }

    case PEOPLE_ADD_ORGANIZATION_CONTACT_SUCCESS: {
      const { response, status } = payload;
      return {
        ...state,
        contactList: response,
      };
    }
    case COMPANY_ADD_CONTACT_ATTEMPT: {
      return {
        ...state,
        updateContacts: false,
      };
    }
    case COMPANY_ADD_CONTACT_SUCCESS: {
      const { response, status } = payload;
      return {
        ...state,
        contactList: [response, ...state.contactList],
        updateContacts: true,
      };
    }
    case COMPANY_EDIT_CONTACT_ATTEMPT: {
      return {
        ...state,
        updateContacts: false,
      };
    }
    case COMPANY_EDIT_CONTACT_SUCCESS: {
      const { response, status } = payload;
      const changedList = state.contactList.map((contact) => {
        if (contact.id === response.id) {
          return response;
        }
        return contact;
      });
      return {
        ...state,
        contactList: changedList,
        updateContacts: true,
      };
    }

    case COMPANY_CONTACT_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          contactList: response,
          contactListMeta: {
            total: payload.xhr.getResponseHeader("total-count"),
            pageItems: payload.xhr.getResponseHeader("page-items"),
          },
        };
      }
    }

    case COMPANY_CONTACT_INVITE_SUCCESS: {
      return {
        ...state,
        updateOrgContacts: true,
      };
    }
  }
  return state;
};

export default companyContactsReducer;

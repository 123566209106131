import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { debounce } from "throttle-debounce";
import { useSelector } from "react-redux";

import {
  CheckBox,
  CheckType,
  UserInline,
  UserInlineTypes,
  SimpleSearchComponent,
  Loading,
  Pagination, Label,
} from "modules/primitives";
import styles from "./styles.module.scss";
import { USERS_WIZARD_SEARCH, PAGINATION_DEFAULTS } from "./constant";
import MemberTagsComponent from "./member-tags";
import { httpPost, pageMeta } from "../../../httpCall";

const ProjectMembers = ({ selectedMembers = [], setSelectedMembers, isAdminWizardInstance }) => {
  const {
    authReducer: { session },
  } = useSelector((state) => state);
  const [list, setList] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [pageSetting, setPageSetting] = useState(PAGINATION_DEFAULTS);
  const [initResWasEmpty, setInitResWasEmpty] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [selectedItems, setSelectedItems] = useState(selectedMembers);

  useEffect(() => {
    if (isAdminWizardInstance) return;
    if (!list) {
      requestNewData(1, "*", false, true);
    }

    return () => null;
  }, [list]);

  useEffect(() => {
    if (isAdminWizardInstance) return;
    if (
      list
      && list.length === 0
      && initResWasEmpty
      && (!searchValue || !searchValue.length)
    ) {
      requestNewData(1, "", true, false);
    }

    return () => null;
  }, [list, searchValue]);

  const requestNewData = (page, query = "*", noTags = false, isInitRequest) => {
    setShowLoading(true);

    const currProject = JSON.parse(localStorage.getItem("new_project"));
    const url = `${USERS_WIZARD_SEARCH}?page=${page || 1}&items=20`;
    const data = {
      query,
      search: {
        query,
        topic_ids: query?.length || noTags ? [] : (currProject.topics || []),
      },
    };

    httpPost({
      call: url,
      data,
    })
      .pipe()
      .subscribe((res) => {
        const pagemeta = pageMeta(res);

        setPageSetting((prev) => ({
          ...prev,
          total: pagemeta.total,
          current: pagemeta.page,
        }));

        if (isInitRequest && (!res.response || res.response.length === 0)) {
          setInitResWasEmpty(true);
        }

        const finalList = res.response
          .filter((i) => i.user_id !== session.id && i.council_role !== "viewer",)
          .sort((a, b) => (a.topics_names?.length > b.topics_names?.length ? -1 : 0))
          .map((item) => ({
            ...item,
            selected: currProject.members?.some((i) => i.id === item.id),
          }));

        setList(finalList);
        setShowLoading(false);
      });
  };

  const handlePagingChange = (page) => {
    if (page !== pageSetting.current) {
      requestNewData(page);
    }
  };

  const onCheckChange = (item) => {
    const newList = list.map((i) => {
      if (i.id === item.id) {
        return {
          ...i,
          selected: !item.selected,
        };
      }
      return i;
    });

    const memberIsAdded = selectedItems.find((i) => item.id === i.id);
    const selectedMembers = memberIsAdded
      ? selectedItems?.filter((i) => item.id !== i.id)
      : [...selectedItems, { ...item, selected: true }];

    if (setSelectedMembers) {
      setSelectedMembers(selectedMembers || []);
    }
    setSelectedItems(selectedMembers || []);

    const currProject = JSON.parse(localStorage.getItem("new_project"));
    const updatedProject = {
      ...currProject,
      members: selectedMembers?.map((elem) => ({ id: elem.id })) || [],
    };

    localStorage.setItem("new_project", JSON.stringify(updatedProject));

    setList(newList);
  };

  const withDebounce = debounce(500, false, (value) => {
    setSearchValue(value);

    if ((!value || !value.length) && !list.length && initResWasEmpty) {
      requestNewData(1, "*", false, false);
      return;
    }

    requestNewData(1, value);
  });

  if (isAdminWizardInstance) {
    return (
      <div className="mb-2 d-flex flex-column align-items-baseline">
        <Label>Project Members</Label>
        <p>Members list component</p>
      </div>
    )
  }

  return (
    <div className={styles.inner}>
      <h4 className={classNames("bold", styles.innerTitle)}>
        Add team members
      </h4>
      <div className={classNames("bold", styles.infoText)}>
        Add individual members by typing their name
      </div>
      <SimpleSearchComponent onSearchValueUpdate={withDebounce} withoutMargin />
      {selectedItems.length > 0 ? (
        <>
          <h4 className={classNames("bold mt-4", styles.smallTitle)}>
            Selected members
          </h4>
          <div className={styles.listWrp}>
            {selectedItems.map((item, i) => (
              <div
                key={item.id + i}
                className={styles.formElement}
                onClick={() => onCheckChange(item)}
              >
                <div className="d-flex">
                  <CheckBox
                    isChecked={item.selected}
                    checkType={CheckType.BLUE}
                    onChange={() => onCheckChange(item)}
                  />
                  <UserInline
                    className={styles.userIcon}
                    userId={item.id}
                    type={UserInlineTypes.WITH_NAME}
                    showMine={false}
                    first={item.first_name}
                    last={item.last_name}
                    local
                    avatar={item.avatar}
                    noNavigation
                  />
                </div>
                <MemberTagsComponent item={item} />
              </div>
            ))}
          </div>
        </>
      ) : null}
      <div className={classNames("bold", styles.infoText)}>
        Select a member to invite to the project
      </div>
      <div className={styles.listWrp}>
        {showLoading ? <Loading /> : null}
        {!showLoading && list && list.length > 0
          ? list.map((item) => (
            <div
              key={item.id}
              className={styles.formElement}
              onClick={() => onCheckChange(item)}
            >
              <div className="d-flex">
                <CheckBox
                  isChecked={item.selected}
                  checkType={CheckType.BLUE}
                  onChange={() => onCheckChange(item)}
                />
                <UserInline
                  className={styles.userIcon}
                  userId={item.id}
                  type={UserInlineTypes.WITH_NAME}
                  showMine={false}
                  first={item.first_name}
                  last={item.last_name}
                  local
                  avatar={item.avatar}
                  noNavigation
                />
              </div>
              <MemberTagsComponent item={item} />
            </div>
          ))
          : null}
        {!showLoading && list && list.length === 0 ? (
          <h3 className="text-center">
            Sorry we didn't find any results for you.
          </h3>
        ) : null}
      </div>
      <div className="w-100 d-flex justify-content-center mb-4">
        <Pagination {...pageSetting} onChange={handlePagingChange} />
      </div>
    </div>
  );
};

export default React.memo(ProjectMembers);

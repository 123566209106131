export const tractionAIMainStyles =  {
  title: {
    color: '#0b4f71',
    fontFamily: 'lato-Bold'
  }
}
export const AI_MAIN_OPTIONS = [
  {
    id: 'scout',
    name: '<div class="d-flex justify-content-center">Recommend Companies<br />(Technology Scouting)</div>',
    settingsKey: 'traction_ai_connected_companies'
  },
  {
    id: 'insight',
    name: '<div class="d-flex justify-content-center">Generate Insights<br />(SWOT, Trend, Snapshot Reports)</div>',
    settingsKey: 'traction_ai_connected_reports'
  },
  {
    id: 'recommend',
    name: 'Recommend Projects or Ideas',
    settingsKey: 'traction_ai_connected_recommend'
  },
];

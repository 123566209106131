import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const adminFuseHoc = (WrappedComponent, data = {}) => (props) => {
  const {
    authReducer: { session },
  } = useSelector((state) => state);

  const history = useHistory();

  useEffect(() => {
    if (session) {
      if (
        session?.council_role !== "ttp_administrator"
          && session?.council_role !== "council_administrator"
      ) {
        history.push("/home");
      }
    }
  }, [session]);

  return <WrappedComponent {...props} />;
};

export default adminFuseHoc;

import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  apiStatus,
  errorHandler,
  httpGet,
  httpPatch,
  httpPost,
} from "../../common/httpCall";
import {
  SETTINGS_TECHNOLOGIES_ATTEMPT,
  settingsTechnologiesSuccess,
  SETTINGS_INDUSTRIES_ATTEMPT,
  settingsIndustriesSuccess,
  GENERAL_SETTINGS_ATTEMPT,
  generalSettingsSuccess,
  SETTINGS_EDIT_ATTEMPT,
  PASSWORD_EDIT_ATTEMPT,
  passwordEditSuccess,
  GET_NOTIFICATIONS_ATTEMPT,
  getNotificationsSuccess,
  SAVE_NOTIFICATIONS_ATTEMPT,
  saveNotificationsSuccess,
  GET_FOLLOWERS_ATTEMPT,
  getFollowersSuccess,
} from "./settings.action";
import { settingsEditSuccess } from "../../common/actions/common.action";
import { makeNotificationsResponse } from "./settings.utils";

const epicGetFollowers = (action$) =>
  action$.pipe(
    ofType(GET_FOLLOWERS_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v2",
        // call: 'following/companies?items=50&page=1',
        call: `follow_relationships/search?items=50&page=${1 || 1}`,
        data: {
          sort_attribute: "",
          sort_order: "",
          search: {
            query: "",
          },
        },
      }).pipe(
        map((result) => getFollowersSuccess(result)),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], () => ({
            type: "GET_FOLLOWERS_ERROR",
            payload: error.xhr.response,
            error: true,
          }))
        )
      )
    )
  );

const epicGetNotificationsSettings = (action$) =>
  action$.pipe(
    ofType(GET_NOTIFICATIONS_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        call: "user/settings/notifications",
      }).pipe(
        map((result) => {
          const newResult = makeNotificationsResponse(result);
          return getNotificationsSuccess(newResult);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const epicNotificationsSettingsSave = (action$) =>
  action$.pipe(
    ofType(SAVE_NOTIFICATIONS_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPatch({
        call: `user/settings/notifications/${data.id}`,
        data: data.dataForServer,
      }).pipe(
        map((result) => {
          if (result.status === apiStatus.SUCCESS) {
            enqueueSnackbar("Successfully saved notifications settings.", {
              variant: "success",
            });
          }

          return saveNotificationsSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const epicGetGeneralSettings = (action$) =>
  action$.pipe(
    ofType(GENERAL_SETTINGS_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        call: "user/settings/general",
      }).pipe(
        map((result) => generalSettingsSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const epicGetTechnologies = (action$) =>
  action$.pipe(
    ofType(SETTINGS_TECHNOLOGIES_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        call: "topics",
      }).pipe(
        map((result) => settingsTechnologiesSuccess(result || [])),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const epicGetIndustries = (action$) =>
  action$.pipe(
    ofType(SETTINGS_INDUSTRIES_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        call: "industries",
      }).pipe(
        map((result) => settingsIndustriesSuccess(result || [])),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const epicGeneralSettingsSave = (action$) =>
  action$.pipe(
    ofType(SETTINGS_EDIT_ATTEMPT),
    switchMap(({ payload: { settings, enqueueSnackbar, session } }) =>
      httpPatch({
        call: "user/settings/general",
        data: {
          approved_board_member: settings.approved_board_member,
          user: settings,
        },
      }).pipe(
        map((result) => {
          if (result.status === apiStatus.SUCCESS) {
            enqueueSnackbar("Successfully saved general settings.", {
              variant: "success",
            });

            // if (session) {
            //   const {
            //     response: { avatar },
            //   } = result;
            //   const newSessionData = {
            //     ...session,
            //     avatar,
            //   };
            //   return settingsEditSuccess(newSessionData);
            // }
          } else {
            enqueueSnackbar("Something wrong happened", {
              variant: "error",
            });
          }
          return settingsEditSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const epicPasswordSave = (action$) =>
  action$.pipe(
    ofType(PASSWORD_EDIT_ATTEMPT),
    switchMap(({ payload: { values, enqueueSnackbar } }) =>
      httpPatch({
        call: "user/settings/password",
        data: {
          user: values,
        },
      }).pipe(
        map((result) => {
          if (result.status === apiStatus.SUCCESS) {
            enqueueSnackbar(
              "Congratulations, you successfully changed your password.",
              {
                variant: "success",
              }
            );
          }

          return passwordEditSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const settingsEpic = combineEpics(
  epicGetGeneralSettings,
  epicGetTechnologies,
  epicGetIndustries,
  epicGeneralSettingsSave,
  epicPasswordSave,
  epicGetNotificationsSettings,
  epicNotificationsSettingsSave,
  epicGetFollowers
);

export default settingsEpic;

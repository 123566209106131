import React, {
  memo, useEffect, useRef, useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import { FilePicker } from "react-file-picker";

import {
  BtnType, Button, Loading, TextBox,
} from "../../../../../primitives";
import { ModalFooter } from "../../../../../../application/modal";
import styles from "./areasOfFocus.module.scss";
import { useModelPopup, useS3FileUpload } from "../../../../../../common/hooks";
import { updateClaimProfileAttempt } from "../../../../store/claimProfileActions";
import {
  handleAddDocument,
  handleAddVideo,
} from "../../../../../admin/adminCompanies/editCompany/editCompany.helper";

const AddEditVideo = ({ profile }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { fileDelete, fileUpload } = useS3FileUpload();
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const [formValues, setFormValues] = useState({ promo_video: "" });
  const [showLoading, setShowLoading] = useState(false);
  const [loadingState, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    if (profile) {
      setFormValues({
        promo_video: profile?.promo_video !== "[]" ? profile.promo_video : "",
      });
    }
  }, [profile]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleAreasOfFocusSave = (values) => {
    setShowLoading(true);

    dispatch(
      updateClaimProfileAttempt({
        companyId: profile.id,
        councilId: selectedCouncil.id,
        data: {
          promo_video: values?.promo_video || videos,
        },
        enqueueSnackbar,
        callBack: () => {
          setShowLoading(false);

          popup.hide();
        },
      }),
    );
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={formValues}
      onSubmit={handleAreasOfFocusSave}
    >
      {({
        values, handleSubmit, setFieldValue, ...formikprops
      }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.customPadding}>
            <div className="mt-3">
              {!showLoading && (
              <>
                <div className="d-flex">
                  <TextBox
                    type="text"
                    name="promo_video"
                    placeholder="Paste embed code or an embed link (e.g., https://www.youtube.com/embed/P3R5OiW33sE)"
                    className="w-100 pr-3"
                    formProps={{
                      ...formikprops,
                      values,
                      setFieldValue,
                    }}
                  />
                  <div className={styles.uploadVideoWrp}>
                    <Button
                      onClick={() => handleAddVideo({
                        setFieldValue,
                        setLoading,
                        videos,
                        setVideos,
                        enqueueSnackbar,
                        formRef,
                      })}
                      btn={BtnType.REGULAR}
                      type="button"
                      className={styles.addVideoBtn}
                    >
                      Add
                    </Button>
                    <div className={styles.separator}> OR</div>
                    <div className={styles.vedioBtnWrp}>
                      <FilePicker
                        extensions={[
                          "mp4",
                          "mov",
                          "wmv",
                          "flv",
                          "avi",
                          "avi",
                          "webm",
                          "mkv",
                        ]}
                        className={styles.filePicker}
                        maxSize={500}
                        onChange={(FileObject) => {
                          handleAddDocument({
                            FileObject,
                            setFieldValue,
                            setLoading,
                            videos,
                            setVideos,
                            enqueueSnackbar,
                            councilId: selectedCouncil.id,
                            companyId: profile.id,
                            fileUpload,
                          });
                        }}
                        onError={(errMsg) => enqueueSnackbar(errMsg, {
                          variant: "error",
                        })}
                      >
                        {loadingState ? (
                          <div className={styles.loadingWrp}>
                            <Loading />
                          </div>
                        ) : (
                          <Button
                            btn={BtnType.OUTLINE_LIGHT}
                            className={styles.selectFile}
                            rounded
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            Choose video file
                          </Button>
                        )}
                      </FilePicker>
                    </div>
                  </div>
                </div>
              </>
              )}
              {showLoading && <Loading customText="Updating your form..." />}
            </div>
          </div>
          <ModalFooter>
            <Button
              className="mr-3"
              btn={BtnType.FRAME_LESS}
              onClick={handleCancelClick}
              disabled={showLoading}
            >
              Cancel
            </Button>
            <Button
              disabled={showLoading}
              type="submit"
              onClick={handleSubmit}
              btn={BtnType.REGULAR}
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default memo(AddEditVideo);

import { apiStatus, pageMeta } from "../../common/httpCall";
import {
  GET_ALL_COMPANIES_BY_STATUS_SUCCESS,
  MY_COMPANIES_GET_SUCCESS,
  RESET_HOME_FUNNEL,
  HOME_FUNNEL_CHART_SHOULD_RE_RENDER,
  HOME_FUNNEL_CHART_SET_SELECTED_TAGS,
  GET_HOME_FUNNEL_DATA_SUCCESS,
  GET_HOME_FUNNEL_THEMES_DATA_SUCCESS,
  GET_HOME_FUNNEL_COUNTRY_DATA_SUCCESS,
  GET_HOME_FUNNEL_DATA,
  GET_HOME_FUNNEL_COUNTRY_DATA,
  GET_HOME_FUNNEL_THEMES_DATA,
} from "./home.actions";
import { UPDATE_CHART_LIST_COMPANY_DETAILS } from "../companies/companyMain/companyMain.action";
import { COMPANY_EDIT_DETAILS_SUCCESS } from "../companies/company/company.action";

const DEFAULT_FIRMENICH = {
  loading: true,
  loadingList: false,
  sectionData: null,
  pagination: {},
  selected_keys_segments: [],
  sortingConfig: {
    sortAttribute: null,
    sortOrder: null,
  },
};
const initState = {
  myCompanies: null,
  innovationFunnelCompanies: null,
  funnelChartShouldRerender: false,
  selectedTags: [],
  firmenichFunnel: {
    stage: DEFAULT_FIRMENICH,
    theme: DEFAULT_FIRMENICH,
    country: DEFAULT_FIRMENICH,
    toggleView: 'all',
  }
};

const makeNewChartColors = (colors, tags, selectedTags) => {
  if (selectedTags.length) {
    return colors.map((c, idx) => {
      const getTagByIdx = tags[idx];
      const tagIsSelected = selectedTags.find(t => (t.id || t.custom_id) === (getTagByIdx.id || getTagByIdx._custom_id));
      return tagIsSelected ? getTagByIdx.color : '#999999';
    });
  }

  return tags.map(t => t.color);
};

const updateFirmenichFunnel = (state, payload) => {
  let sectionData = state.firmenichFunnel[payload.key].sectionData;

  if (payload.selected_keys_segments?.length) {
    sectionData = {
      ...sectionData,
      ...payload.response,
      chart_data: {
        ...sectionData.chart_data,
        data_sets: {
          ...sectionData.chart_data.data_sets,
          background_color: makeNewChartColors(
            sectionData.chart_data.data_sets.background_color,
            sectionData.tags,
            payload.selected_keys_segments
          )
        }
      }
    }
  } else {
    sectionData = payload.response;
  }

  return {
    ...state,
    firmenichFunnel: {
      ...state.firmenichFunnel,
      [payload.key]: {
        ...state.firmenichFunnel[payload.key],
        loading: false,
        loadingList: false,
        sectionData,
        hasError: !payload.response,
        pagination: pageMeta(payload),
        selected_keys_segments: payload.selected_keys_segments || [],
        sortingConfig: payload.sortingConfig || {},
      },
      toggleView: payload.toggleView || 'all',
    }
  }
}

const homeReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_HOME_FUNNEL_DATA:
    case GET_HOME_FUNNEL_COUNTRY_DATA:
    case GET_HOME_FUNNEL_THEMES_DATA: {
      if (!payload.data?.reducerKey) {
        return state;
      }

      return {
        ...state,
        firmenichFunnel: {
          ...state.firmenichFunnel,
          [payload.data.reducerKey]: {
            ...state.firmenichFunnel[payload.data.reducerKey],
            loadingList: payload.data.filters.loadingList,
          },
        }
      }
    }

    case GET_HOME_FUNNEL_DATA_SUCCESS:
    case GET_HOME_FUNNEL_COUNTRY_DATA_SUCCESS:
    case GET_HOME_FUNNEL_THEMES_DATA_SUCCESS: {
      return updateFirmenichFunnel(state, payload);
    }

    case HOME_FUNNEL_CHART_SET_SELECTED_TAGS: {
      return {
        ...state,
        selectedTags: payload,
      };
    }

    case MY_COMPANIES_GET_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            myCompanies: response,
          };
        }
      }
      break;

    case GET_ALL_COMPANIES_BY_STATUS_SUCCESS: {
      return {
        ...state,
        innovationFunnelCompanies: payload.response,
      };
    }

    case UPDATE_CHART_LIST_COMPANY_DETAILS: {
      if (!state.innovationFunnelCompanies) return state;

      return {
        ...state,
        innovationFunnelCompanies: state.innovationFunnelCompanies.map((c) => {
          if (c.id === payload.companyId) {
            return {
              ...c,
              ...payload.newCompanyData,
            };
          }
          return c;
        }),
      };
    }

    case HOME_FUNNEL_CHART_SHOULD_RE_RENDER: {
      return {
        ...state,
        funnelChartShouldRerender: payload,
      };
    }

    case RESET_HOME_FUNNEL: {
      return {
        ...state,
        innovationFunnelCompanies: null,
      };
    }

    case COMPANY_EDIT_DETAILS_SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        innovationFunnelCompanies: state.innovationFunnelCompanies?.map(
          (compa) => {
            if (compa.id === payload.response.id) {
              return {
                ...compa,
                council_relationship_stage_id:
                  response.council_relationship_stage_id,
                council_relationship_stage_name:
                  response.council_relationship_stage_name,
              };
            }
            return compa;
          },
        ),
      };
    }

    default: {
      return state;
    }
  }
};

export default homeReducer;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";

import { ModalBody, ModalFooter } from "../../../../../application/modal";
import {
  BtnType,
  Button,
  DatePiker,
  DropSelect,
  Label,
  MultiSelect,
  TextBox,
  CheckBox,
  CheckType,
} from "../../../../primitives";
import {
  initModel,
  mainTaskEditConfirmationText,
  validation,
  validationInGSK,
} from "../tasks.constants";
import styles from "./taskNew.module.scss";
import "./taskNew.scss";
import {
  projectTaskAdd,
  projectTaskListGet,
  projectTasksByUserGet,
  projectTaskUpdate,
} from "../tasks.action";
import {
  projectCompanyListGet,
  projectOrganizationsGet,
} from "../../companies/companies.action";
import { useModelPopup } from "../../../../../common/hooks";
import ConfirmActionComponent from "../../../../../common/components/confirmAction/confirmAction.component";
import { isFerminichCouncil } from "../../isFerminichCouncil";
import { httpGet } from "../../../../../common/httpCall";
import UserMultiSelectSearch from "../../../../../common/components/usersSearch";
import TractionRichEditor from "../../../../../common/components/TractionRichEditor";
import { getUpcomingTasks } from "modules/projects/project/overview/overview.action";

const TaskNewComponent = ({
  defaultModel,
  activeTab = "all_task",
  currentPage,
  mode,
  upcomingTaskInstance,
}) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();

  const [linkTaskToFields, setLinkTaskToFields] = useState(false);
  const [projectFields, setProjectFields] = useState(null);
  const [fieldGroups, setFieldGroups] = useState(null);
  const [stageOptions, setStageOptions] = useState([]);
  const [assignOptions, setAssignOptions] = useState([]);
  const [applyToOptions, setApplyToOptions] = useState([]);
  const [patientOrg, setPatientOrg] = useState(false);
  const [occupied_task_positions, setOccupiedTaskPositions] = useState([]);

  const [stageIsRequiredError, setStageIsRequiredError] = useState(false);

  const [error, setError] = useState("");
  const [model, setModel] = useState({
    ...initModel,
    ...defaultModel,
  });

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    projectsReducer: {
      projectReducer: { selectedProject },
      stagesReducer: { stagesList },
      companyReducer: { companyList, patientOrganizations },
      usersReducer: { projectUsersList },
    },
  } = useSelector((state) => state);

  const setTaskDefaultPosition = (val, arr) => {
    const occupied_stage_positions_arr = arr?.find(
      (stage) => stage.stage_id === val
    );
    if (val && occupied_stage_positions_arr) {
      for (let index = 1; index < 10000; index++) {
        if (
          !occupied_stage_positions_arr?.occupied_positions?.includes(index)
        ) {
          formRef.current.setFieldValue("position", index);
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (selectedProject?.id) {
      // get field groups
      httpGet({
        call: `stage_templates/${selectedProject.stage_template_id}/project_field_groups/dropdown`,
      }).subscribe((res) => {
        if (res.response) {
          setFieldGroups(res.response);
        }
      });

      // get project fields
      httpGet({
        call: `stage_templates/${selectedProject.stage_template_id}/project_fields/dropdown`,
      }).subscribe((res) => {
        if (res.response) {
          const newResponse = res.response.map((obj) => ({
            ...obj,
            project_field_template_id:
              obj.project_field_template_id || obj.field_name,
            field_name_humanized: obj.field_name_humanized || obj.field_name,
          }));
          setProjectFields(newResponse);
        }
      });
    }
  }, [selectedProject]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = () => {
    if (formRef.current?.values?.isInvalidTask) return;
    if (!formRef.current?.values?.stage_id) {
      setStageIsRequiredError(true);
      return;
    }

    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const isValidDate = (d) => {
    const date = new Date(d);
    return date instanceof Date && !isNaN(date);
  };

  const makeProjectFieldProps = (values) => {
    const { relatedProjectField, relatedProjectFieldGroup } = values;

    if (typeof relatedProjectField === "string") {
      const getProjectFieldObj = projectFields.find(
        (field) => field.field_name === relatedProjectField
      );
      const isProjectMetric =
        getProjectFieldObj?.resource_type === "project_metric";

      return {
        project_field_group_id: null,
        project_metric_attribute:
          linkTaskToFields && getProjectFieldObj && isProjectMetric
            ? getProjectFieldObj.field_name
            : null,
        project_field_template_id:
          linkTaskToFields && getProjectFieldObj && !isProjectMetric
            ? getProjectFieldObj.project_field_template_id
            : null,
      };
    }

    const isProjectMetric =
      relatedProjectField?.resource_type === "project_metric";

    return {
      project_field_group_id: !linkTaskToFields
        ? null
        : relatedProjectFieldGroup,
      project_metric_attribute:
        linkTaskToFields && relatedProjectField && isProjectMetric
          ? relatedProjectField.field_name
          : null,
      project_field_template_id:
        linkTaskToFields && relatedProjectField && !isProjectMetric
          ? relatedProjectField.project_field_template_id
          : null,
    };
  };

  const handleOnSubmit = (values) => {
    const {
      id,
      name,
      description,
      stage_id,
      position,
      due_date,
      assigned_to,
      apply_to,
      task_type,
      approval_task_options,
      mark_on_document_upload,
      disable_manual_completion,
    } = values;
    const isApprovalTask = task_type === "approval_gate";
    const projectFieldProps = makeProjectFieldProps(values);

    let payload = {
      enqueueSnackbar,
      projectId: selectedProject.id,
      project_task: {
        name,
        description,
        stage_id,
        due_date,
        type: task_type,
        ...projectFieldProps,
      },
    };

    if (position) {
      payload.project_task.position = parseInt(position, 10);
    }

    if (!isApprovalTask) {
      if (assigned_to) {
        payload.project_task.task_assignments = {
          assigned_to_id: assigned_to,
        };
      } else if (defaultModel?.assigned_users.length) {
        payload.project_task.task_assignments = {
          assigned_to_id: defaultModel?.assigned_users[0]?.user_id,
        };
      }
    }

    if (apply_to && apply_to.length) {
      const originalObjects = applyToOptions.filter((elem) =>
        apply_to.includes(elem.id)
      );

      payload.project_task.task_organizations = {
        organization_type: patientOrg ? "Gsk::PatientOrganization" : "Company",
        apply_to_all: apply_to[0] === "all",
        organization_ids: originalObjects
          .filter((item) => item !== "all" && !item.is_product)
          .map((item) => item.id),
      };

      payload.project_task.task_products = {
        type: "Product",
        apply_to_all: apply_to[0] === "all",
        product_ids: originalObjects
          .filter((item) => item !== "all" && item.is_product)
          .map((item) => item.id),
      };
    }

    if (task_type === "project") {
      delete payload.project_task.task_organizations;
    }

    if (isApprovalTask) {
      delete payload.project_task.task_products;
      delete payload.project_task.task_organizations;

      if (approval_task_options.approval) {
        payload = {
          ...payload,
          project_task: {
            ...payload.project_task,
            approval_task_options,
            assigned_approvers:
              values.assigned_approvers?.map((user) => user.id) || [],
            approve_in_order: values.approve_in_order,
          },
        };
      }

      if (approval_task_options.review) {
        payload = {
          ...payload,
          project_task: {
            ...payload.project_task,
            approval_task_options,
            assigned_reviewers:
              values?.assigned_reviewers?.map((user) => user.id) || [],
            approve_in_order: values.approve_in_order,
          },
        };
      }
    } else {
      payload = {
        ...payload,
        project_task: {
          ...payload.project_task,
          assigned_users: values.assigned_users?.map((user) => user.id) || [],
          mark_on_document_upload,
          disable_manual_completion,
        },
      };
    }

    if (id) {
      payload.taskId = id;
      if (
        !isApprovalTask &&
        defaultModel.task_type === "project" &&
        defaultModel.projects_task_assignments.length > 0
      ) {
        payload.queryParams = {
          task_assignment_id: defaultModel.projects_task_assignments[0]?.id,
        };
      }

      if (model.due_date === due_date) {
        delete payload.project_task.due_date;
      }

      if (due_date === "" || isValidDate(due_date)) {
        popup.show({
          title: "Confirm Edit",
          component: (
            <ConfirmActionComponent
              alertText={mainTaskEditConfirmationText}
              onConfirm={() => {
                dispatch(projectTaskUpdate(payload));
                getAllTasks();
                popup.hide();
              }}
            />
          ),
        });
      } else {
        setError("Due date is in an invalid format.");
      }
    } else {
      dispatch(projectTaskAdd(payload));
      getAllTasks();
      popup.hide();
    }
  };

  const getAllTasks = () => {
    if (activeTab) {
      let obj = {
        projectId: selectedProject.id,
        councilId: selectedCouncil.id,
        enqueueSnackbar,
        page: currentPage || 1, // put page dynamically
        apiVersion: "v3",
      };

      const timeout = setTimeout(() => {
        if (upcomingTaskInstance) {
          dispatch(getUpcomingTasks({
            userId: activeTab === "my_task" ? session.id : null,
            enqueueSnackbar,
            projectId: selectedProject.id,
          }));

          clearTimeout(timeout);
          return;
        }

        if (activeTab === "my_task") {
          dispatch(projectTasksByUserGet({ ...obj, userId: session.id }));
        }

        if (activeTab === "all_task") {
          dispatch(projectTaskListGet(obj));
        }

        clearTimeout(timeout);
      }, 1000);
    }
  };

  useEffect(() => {
    if (selectedProject) {
      if (
        selectedProject.stage_template &&
        selectedProject.stage_template.patient_organization
      ) {
        setPatientOrg(true);
        dispatch(
          projectOrganizationsGet({
            projectId: selectedProject.id,
            enqueueSnackbar,
          })
        );
      } else {
        dispatch(
          projectCompanyListGet({
            projectId: selectedProject.id,
            items: 1000,
            enqueueSnackbar,
          })
        );
      }
    }
  }, [selectedProject, patientOrg]);

  useEffect(() => {
    if (stagesList && stagesList.length !== 0) {
      setStageOptions(stagesList.map((item) => item));
      setModel({
        ...model,
        stage_id: stagesList[0].id,
      });
    }
    if (mode !== "edit") {
      httpGet({
        call: `projects/${selectedProject.id}/task_free_positions`,
      }).subscribe((res) => {
        setOccupiedTaskPositions(res.response);
        setTaskDefaultPosition(stagesList[0].id, res.response);
      });
    }
  }, [stagesList]);

  useEffect(() => {
    if (projectUsersList && projectUsersList.length !== 0) {
      const list = projectUsersList.map((item) => ({
        id: item.user.id,
        fullName: item.user.full_name,
      }));
      setAssignOptions(list);
    }
  }, [projectUsersList]);

  useEffect(() => {
    const items = patientOrg ? patientOrganizations : companyList;
    if (items && items.length !== 0) {
      const newList = [
        ...items.reduce((acc, elem) => {
          if (elem.state !== "removed") {
            if (elem.is_product) {
              acc.push({
                ...elem,
                name: `${elem.name} (product)`,
              });
            } else {
              const { company, gsk_patient_organization } = elem;
              acc.push(patientOrg ? gsk_patient_organization : company);
            }
          }
          return acc;
        }, []),
        { id: "all", name: "All Companies" },
      ];
      setApplyToOptions(newList);
    }
  }, [companyList, patientOrganizations, patientOrg]);

  const getRelatedField = (model) => {
    return (
      model.project_field_template?.project_field_template_id ||
      model.project_metric_attribute?.field_name
    );
  };

  useEffect(() => {
    if (defaultModel) {
      if (
        defaultModel.project_field_template?.project_field_template_id ||
        defaultModel.project_metric_attribute?.field_name ||
        defaultModel.project_field_group?.id
      ) {
        setLinkTaskToFields(true);
      }

      const getUsers = (prop) => {
        if (!defaultModel[prop]) {
          return [];
        }
        return defaultModel[prop].map((obj) => ({
          ...obj,
          ...(obj.user || {}),
          id: obj.user?.user_id || obj?.user_id,
          full_name: obj.user
            ? `${obj.user.first_name} ${obj.user.last_name}`
            : `${obj.first_name} ${obj.last_name}`,
        }));
      };

      const getTaskType = () => {
        if (defaultModel?.task_type === "project_organization_specific") {
          return "organization";
        }

        if (defaultModel?.task_type === "project_specific") {
          return "project";
        }

        return defaultModel?.task_type;
      };

      const model = {
        id: defaultModel.id,
        name: defaultModel.name,
        description: defaultModel.description || "",
        stage_id: defaultModel.stage_id || "",
        position: defaultModel.position || "",
        due_date: defaultModel.due_date || "",
        task_type: getTaskType(),
        assigned_to: "",
        apply_to: [],
        relatedProjectField: getRelatedField(defaultModel),
        relatedProjectFieldGroup: defaultModel.project_field_group?.id,
        approval_task_options: {
          review: defaultModel.approval_type === "review",
          approval: defaultModel.approval_type === "approval",
          lockStages: defaultModel.locked,
        },
        approve_in_order: defaultModel.approve_in_order,
        assigned_approvers: getUsers("assigned_approvers"),
        assigned_reviewers: getUsers("assigned_reviewers"),
        assigned_users: getUsers("assigned_users"),
        mark_on_document_upload: defaultModel.mark_on_document_upload,
        disable_manual_completion: defaultModel.disable_manual_completion,
      };

      setModel(model);
    }
  }, [defaultModel]);

  useEffect(() => {
    if (defaultModel && applyToOptions.length > 0) {
      setModel({
        ...model,
        apply_to:
          defaultModel.organizations?.map(
            ({ organization_id }) => organization_id
          ) || [],
      });
    }
  }, [applyToOptions]);

  useEffect(() => {
    if (
      selectedCouncil?.name === "Firmenich" &&
      selectedProject?.stage_template?.default_template
    ) {
      setModel({
        ...model,
        task_type: "project",
      });
    }
  }, [selectedCouncil, selectedProject]);

  const getUsersData = useCallback((values) => {
    if (values.task_type === "approval_gate") {
      return values.approval_task_options.approval
        ? values?.assigned_approvers || []
        : values?.assigned_reviewers || [];
    }

    return values.assigned_users || [];
  }, []);

  const setUsersData = useCallback((value, values, setFieldValue) => {
    if (values.task_type === "approval_gate") {
      if (values.approval_task_options.approval) {
        setFieldValue("assigned_approvers", value);
      }

      if (values.approval_task_options.review) {
        setFieldValue("assigned_reviewers", value);
      }

      return;
    }

    setFieldValue("assigned_users", value);
  }, []);

  const checkIfStageHasApprovalGateTask = useCallback(
    (dropdownValue) => {
      if (!formRef?.current || defaultModel?.stage_id === dropdownValue?.val)
        return;

      formRef.current.setFieldValue("isInvalidTask", false);
      const {
        task_type,
        approval_task_options: { review, approval },
        stage_id,
      } = formRef.current.values;

      if (task_type !== "approval_gate") {
        formRef.current.setFieldValue("isInvalidTask", false);
        return;
      }

      httpGet({
        call: `check_task_type?stage_id=${
          dropdownValue?.val || stage_id
        }&review=${review}&approval=${approval}&in_project=true&project_id=${
          selectedProject.id
        }`,
      }).subscribe((res) => {
        if (res.response) {
          formRef.current.setFieldValue(
            "isInvalidTask",
            res.response.approval_tasks || res.response.review_tasks
          );
        } else {
          formRef.current.setFieldValue("isInvalidTask", false);
        }
      });
    },
    [formRef?.current, defaultModel]
  );
  return (
    <>
      <ModalBody>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={model}
          validationSchema={
            selectedCouncil?.name === "GSK" ? validationInGSK : validation
          }
          onSubmit={(values) => {
            handleOnSubmit(values);
          }}
        >
          {({ handleSubmit, setFieldValue, values, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.editStageFormWrp}>
                <Label>Task</Label>
                <TextBox
                  type="text"
                  name="name"
                  placeholder="Enter a task name"
                  className={styles.textBox}
                  formProps={{ setFieldValue, values, ...formikprops }}
                />

                <Label>Description</Label>
                <div className="mb-5">
                  <TractionRichEditor
                    handleChange={(val) => setFieldValue("description", val)}
                    initialValue={values.description || ""}
                    minHeight={150}
                    placeholder="Enter a task description"
                  />
                </div>

                <Label>Complete by stage </Label>
                <DropSelect
                  placeholder="Stage"
                  name="stage_id"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  options={stageOptions}
                  formProps={{ setFieldValue, values, ...formikprops }}
                  onChange={(val) => {
                    if (val.val === "") {
                      setStageIsRequiredError(true);
                    } else {
                      setStageIsRequiredError(false);
                    }

                    setTaskDefaultPosition(val.val, occupied_task_positions);
                    checkIfStageHasApprovalGateTask(val);
                  }}
                  SelecedValues={
                    formRef?.current?.values?.stage_id || stageOptions[0]?.id
                  }
                />
                {stageIsRequiredError && (
                  <span className="text-danger">Stage is required.</span>
                )}
                {values.isInvalidTask && (
                  <span className="text-danger">
                    An Approval Task already exists for the selected Stage.
                    Please select a different Stage.
                  </span>
                )}

                <div className="mt-3">
                  <Label>Position within Stage</Label>
                  <TextBox
                    type="number"
                    name="position"
                    min="1"
                    placeholder="Enter a task position"
                    className={styles.textBox}
                    formProps={{ setFieldValue, values, ...formikprops }}
                  />
                </div>

                {values?.task_type !== "approval_gate" && (
                  <>
                    <div className="mb-1">
                      <label className="d-flex align-items-center">
                        <CheckBox
                          checkType={CheckType.BLUE}
                          name="mark_on_document_upload"
                          onChange={(evt) => {
                            setFieldValue("mark_on_document_upload", evt);
                          }}
                          isChecked={values.mark_on_document_upload}
                        />
                        <span>Mark Complete with Document Upload</span>
                      </label>
                    </div>
                    <div
                      className={`${
                        values.mark_on_document_upload || linkTaskToFields
                          ? "mb-1"
                          : "mb-3"
                      }`}
                    >
                      <label className="d-flex align-items-center">
                        <CheckBox
                          checkType={CheckType.BLUE}
                          name="linkTaskToFields"
                          onChange={(evt) => {
                            setLinkTaskToFields(evt);
                            setFieldValue("disable_manual_completion", evt);
                            setFieldValue("relatedProjectField", null);
                            setFieldValue("relatedProjectFieldGroup", null);
                          }}
                          isChecked={linkTaskToFields}
                        />
                        <span>Link Task to Field(s)</span>
                      </label>
                    </div>
                    {(values.mark_on_document_upload || linkTaskToFields) && (
                      <div className="mb-3">
                        <label className="d-flex align-items-center">
                          <CheckBox
                            checkType={CheckType.BLUE}
                            name="disable_manual_completion"
                            onChange={(evt) => {
                              setFieldValue("disable_manual_completion", evt);
                            }}
                            disabled={linkTaskToFields}
                            isChecked={values.disable_manual_completion}
                          />
                          <span>Disable Manual Task Completion</span>
                        </label>
                      </div>
                    )}
                  </>
                )}

                {linkTaskToFields && values?.task_type !== "approval_gate" && (
                  <>
                    {projectFields?.length > 0 &&
                    !values?.relatedProjectFieldGroup ? (
                      <div className="mb-3">
                        <Label>Related Project Field</Label>
                        <span className="small text-muted mb-2">
                          When selected field is filled out, Task will be marked
                          Complete
                        </span>
                        <DropSelect
                          placeholder="Related Project Field"
                          name="relatedProjectField"
                          labelField="field_name_humanized"
                          valueField="project_field_template_id"
                          searchBy="field_name_humanized"
                          options={projectFields}
                          formProps={{ setFieldValue, values, ...formikprops }}
                          onChange={(value) => {
                            setFieldValue("relatedProjectField", value.obj[0]);
                            setFieldValue("relatedProjectFieldGroup", null);
                          }}
                          SelecedValues={values.relatedProjectField}
                        />
                        {values.relatedProjectField && (
                          <div className="d-flex justify-content-end">
                            <span
                              style={{ color: "#2d83ae" }}
                              onClick={() => {
                                setFieldValue(
                                  "relatedProjectField",
                                  null
                                ).then();
                                setFieldValue(
                                  "relatedProjectFieldGroup",
                                  "clear"
                                );
                                const timeout = setTimeout(() => {
                                  setFieldValue(
                                    "relatedProjectFieldGroup",
                                    null
                                  );
                                  clearTimeout(timeout);
                                }, 50);
                              }}
                              className="cursor-pointer small"
                            >
                              clear
                            </span>
                          </div>
                        )}
                      </div>
                    ) : null}

                    {fieldGroups?.length > 0 && !values?.relatedProjectField ? (
                      <div className="mb-3">
                        <Label>Related Project Field Group</Label>
                        <span className="small text-muted mb-2">
                          When all fields in selected Group are filled out, Task
                          will be marked Complete
                        </span>
                        <DropSelect
                          placeholder="Related Project Field Group"
                          name="relatedProjectFieldGroup"
                          labelField="name"
                          valueField="id"
                          searchBy="name"
                          options={fieldGroups}
                          formProps={{ setFieldValue, values, ...formikprops }}
                          onChange={(value) => {
                            setFieldValue(
                              "relatedProjectFieldGroup",
                              value.obj[0]
                            );
                            setFieldValue("relatedProjectField", null);
                          }}
                          SelecedValues={
                            values.relatedProjectFieldGroup || "clear"
                          }
                        />
                        {values.relatedProjectFieldGroup && (
                          <div className="d-flex justify-content-end">
                            <span
                              style={{ color: "#2d83ae" }}
                              onClick={() => {
                                setFieldValue("relatedProjectFieldGroup", null);
                              }}
                              className="cursor-pointer small"
                            >
                              clear
                            </span>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </>
                )}

                <Label>Due Date</Label>
                <DatePiker
                  name="due_date"
                  value={isValidDate(model.due_date) ? model.due_date : ""}
                  formProps={{ setFieldValue, values, ...formikprops }}
                  className={error ? styles.calendarError : styles.calendarBox}
                  withClear
                  onChange={(date) => setFieldValue("due_date", date)}
                />

                {error && <div className={styles.error}>{error}</div>}

                <div className="mb-2 mt-3">
                  <div className="form-check">
                    <label className="form-check-label d-flex align-items-center">
                      <Field
                        disabled={defaultModel}
                        className="form-check-input"
                        type="radio"
                        name="task_type"
                        value="organization"
                        onChange={() => {
                          setFieldValue("task_type", "organization");
                          setFieldValue("isInvalidTask", false);
                        }}
                      />
                      {selectedCouncil?.name === "GSK" &&
                      selectedProject.stage_template.patient_organization
                        ? "Organization Task"
                        : "Company Task"}
                      <Tooltip
                        key="task_type"
                        title="This Task will be applied to all selected companies, and create separate Tasks for each one."
                        placement="top"
                      >
                        <span className={styles.questionMark}>?</span>
                      </Tooltip>
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label d-flex align-items-center">
                      <Field
                        disabled={defaultModel}
                        className="form-check-input"
                        type="radio"
                        name="task_type"
                        value="project"
                        onChange={() => {
                          setFieldValue("task_type", "project");
                          setFieldValue("isInvalidTask", false);
                        }}
                      />
                      {isFerminichCouncil(
                        "Project Task",
                        "Milestone",
                        selectedCouncil
                      )}
                      <Tooltip
                        key="task_type"
                        title="This Task is not specific to any company. It will appear in the selected Stage."
                        placement="top"
                      >
                        <span className={styles.questionMark}>?</span>
                      </Tooltip>
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label d-flex align-items-center">
                      <Field
                        disabled={defaultModel}
                        className="form-check-input"
                        type="radio"
                        name="task_type"
                        value="approval_gate"
                        onChange={() => {
                          setFieldValue("task_type", "approval_gate").then(
                            () => {
                              checkIfStageHasApprovalGateTask();
                            }
                          );
                        }}
                      />
                      Approval Gate
                      <Tooltip
                        key="task_type"
                        title="This Task will be applied to all selected companies, and create separate Tasks for each one."
                        placement="top"
                      >
                        <span className={styles.questionMark}>?</span>
                      </Tooltip>
                    </label>
                  </div>
                </div>
                {values?.task_type === "approval_gate" && (
                  <div className="ml-4">
                    <label className="d-flex align-items-center">
                      <CheckBox
                        disabled={
                          defaultModel || !values.approval_task_options.approval
                        }
                        checkType={CheckType.BLUE}
                        name="review"
                        onChange={() => {
                          const newValues = {
                            ...values.approval_task_options,
                            review: !values.approval_task_options.review,
                            lockStages: false,
                            approval: false,
                          };
                          setFieldValue(
                            "approval_task_options",
                            newValues
                          ).then(() => {
                            checkIfStageHasApprovalGateTask();
                          });
                        }}
                        isChecked={values.approval_task_options.review}
                      />
                      <span>Review</span>
                    </label>
                    <label className="d-flex align-items-center">
                      <CheckBox
                        disabled={
                          defaultModel || !values.approval_task_options.review
                        }
                        checkType={CheckType.BLUE}
                        name="approval"
                        onChange={() => {
                          const newValues = {
                            ...values.approval_task_options,
                            approval: !values.approval_task_options.approval,
                            review: false,
                            lockStages: true,
                          };
                          setFieldValue(
                            "approval_task_options",
                            newValues
                          ).then(() => {
                            checkIfStageHasApprovalGateTask();
                          });
                        }}
                        isChecked={values.approval_task_options.approval}
                      />
                      <span>Approval</span>
                    </label>
                    {values.approval_task_options.approval && (
                      <label className="d-flex align-items-center">
                        <CheckBox
                          checkType={CheckType.BLUE}
                          name="lockStages"
                          onChange={() => {
                            setFieldValue("approval_task_options", {
                              ...values.approval_task_options,
                              lockStages:
                                !values.approval_task_options.lockStages,
                            });
                          }}
                          isChecked={values.approval_task_options.lockStages}
                        />
                        <span>Lock Stages</span>
                      </label>
                    )}
                  </div>
                )}
                <div className="mt-3">
                  <Label>Assign to</Label>
                  <UserMultiSelectSearch
                    multi={values?.task_type === "approval_gate"}
                    dropdownPosition="top"
                    placeholder="Search for users"
                    labelField="full_name"
                    valueField="id"
                    searchBy="full_name"
                    hasAll={false}
                    selectedValues={getUsersData(values)}
                    onChange={(value) =>
                      setUsersData(value, values, setFieldValue)
                    }
                  />
                </div>
                {values?.task_type === "approval_gate" && (
                  <label className="d-flex align-items-center mt-1">
                    <CheckBox
                      checkType={CheckType.BLUE}
                      name="approve_in_order"
                      onChange={(evt) => {
                        setFieldValue("approve_in_order", evt);
                      }}
                      isChecked={values.approve_in_order}
                    />
                    <span>Approve in order</span>
                  </label>
                )}
                {values?.task_type === "organization" && (
                  <>
                    <Label>Apply to</Label>
                    <MultiSelect
                      placeholder={
                        selectedCouncil?.name === "GSK"
                          ? "Select organizations"
                          : "Select companies and products"
                      }
                      name="apply_to"
                      labelField="name"
                      valueField="id"
                      searchBy="name"
                      selectAll
                      selectAllItem={
                        patientOrg ? "All Organizations" : "All companies"
                      }
                      options={applyToOptions}
                      formProps={{ setFieldValue, values, ...formikprops }}
                    />
                  </>
                )}
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          disabled={
            formRef?.current?.values?.isInvalidTask || stageIsRequiredError
          }
          type="submit"
          btn={BtnType.REGULAR}
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </ModalFooter>
    </>
  );
};

export default React.memo(TaskNewComponent);

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { format, parseISO } from "date-fns";
import { shouldDownload } from "common/helper";

import {
  likeReplyAttempt,
  dislikeReplyAttempt,
  deselectReplyAsFavoriteAttempt,
  selectReplyAsFavoriteAttempt,
} from "../feed.action";
import {
  MoreOptions,
  UserInline,
  UserInlineTypes,
} from "../../../../../../primitives/index";
import { moreOptions } from "./feedItem.constant";
import styles from "./styles.module.scss";
import Icon from "../../../../../../../common/icon/icon.component";
import ConfirmDeleteComponent from "../../../../../../../common/components/confirmDelete/index";
import { useModelPopup } from "../../../../../../../common/hooks";
import TextAreaFeed from "../textAreaFeed";

const FeedItem = (props) => {
  const {
    replyData,
    replies,
    selectedReply,
    level,
    handleSelectReply,
    topBlock,
    themeId,
    setEditableReply,
    setMode,
    mode,
    homePageUse,
  } = props;
  // const enqueueSnackbar = useSnackbar();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const feedRef = useRef();
  const [isFavorite, setIsFavorite] = useState(replyData.current_user_favorite);
  const [isLiked, setIsLiked] = useState(replyData.current_user_like);
  const [timeAgo, setTimeAgo] = useState("");
  const [seeAllReplies, setSeeAllReplies] = useState(false);
  const repliesCount = replies?.length || replyData?.theme_post_replies?.length;

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesFeedReducer: { likePending, favPending },
    },
  } = useSelector((state) => state);

  const handleEditClick = () => {
    setMode("edit");
    setEditableReply(replyData);
  };

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick();
    }
    if (value === 1) {
      popup.show({
        title: "Delete Reply",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={themeId}
            templateId={replyData.theme_post_id}
            categoryId={replyData.theme_post_reply_id}
            enqueueSnackbar={enqueueSnackbar.enqueueSnackbar}
            councilId={selectedCouncil?.id}
            actionType="deletePostReply"
          />
        ),
      });
    }
  };

  const handleLikePost = () => {
    if (likePending) {
      return;
    }
    setIsLiked(!isLiked);
    if (replyData.current_user_like) {
      dispatch(
        dislikeReplyAttempt({
          theme_id: themeId,
          post_id: replyData.theme_post_id,
          reply_id: replyData.theme_post_reply_id,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(
        likeReplyAttempt({
          theme_id: themeId,
          post_id: replyData.theme_post_id,
          reply_id: replyData.theme_post_reply_id,
          enqueueSnackbar,
        })
      );
    }
  };

  const handleFavoritePost = () => {
    if (favPending) {
      return;
    }
    setIsFavorite(!isFavorite);
    if (replyData.current_user_favorite) {
      dispatch(
        deselectReplyAsFavoriteAttempt({
          theme_id: themeId,
          post_id: replyData.theme_post_id,
          reply_id: replyData.theme_post_reply_id,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(
        selectReplyAsFavoriteAttempt({
          theme_id: themeId,
          post_id: replyData.theme_post_id,
          reply_id: replyData.theme_post_reply_id,
          enqueueSnackbar,
        })
      );
    }
  };

  useEffect(() => {
    if (replyData.created_at) {
      const timeSince = (date) => {
        const seconds = Math.floor((new Date() - date) / 1000);

        let interval = seconds / 31536000;

        if (interval > 1) {
          return Math.floor(interval) + " years ago";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
          return Math.floor(interval) + " months ago";
        }
        interval = seconds / 86400;
        if (interval > 1) {
          return Math.floor(interval) + " days ago";
        }
        interval = seconds / 3600;
        if (interval > 1) {
          return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
          return Math.floor(interval) + " minutes ago";
        }
        return Math.floor(seconds) + " seconds ago";
      };
      const formatedDate = format(
        parseISO(replyData.created_at),
        "MM,dd,y,hh,mm,ss"
      );

      const year = formatedDate.split(",")[2];
      const months = formatedDate.split(",")[0];
      const day = formatedDate.split(",")[1];
      const hours = formatedDate.split(",")[3];
      const minutes = formatedDate.split(",")[4];
      const sec = formatedDate.split(",")[5];

      const feedDate = new Date(
        `${year}-${months}-${day}T${hours}:${minutes}:${sec}`
      );
      setTimeAgo(timeSince(feedDate));
    }
  }, [replyData.created_at]);

  useEffect(() => {
    feedRef.current.focus();
  }, []);
  return (
    <div className={styles.mainFeed}>
      <div
        ref={feedRef}
        style={
          topBlock
            ? { marginTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0 }
            : level === 2
            ? { marginLeft: level * 20, backgroundColor: "#F7F7F7" }
            : level === 3
            ? { marginLeft: level * 20, backgroundColor: "#EAEAEA" }
            : null
        }
        className={
          selectedReply.theme_post_reply_id === replyData.theme_post_reply_id ||
          topBlock
            ? styles.feedItemContainerSelectedBlock
            : styles.feedItemContainer
        }
      >
        <div className={styles.avatarBlock}>
          <UserInline
            className={styles.avatar}
            userId={replyData.user?.user_id}
            type={UserInlineTypes.IMAGE_ONLY}
            showMine={false}
            first={replyData.user.name}
            last={replyData.user.name}
            avatar={replyData.user?.avatar}
            local
          />
        </div>
        <div className={styles.aboutFeedBlock}>
          <div className={styles.firstRow}>
            <div className={styles.avatarNameBlock}>
              <div className={styles.avatarName}>
                {replyData.user ? replyData.user.name : replyData.avatar.name}
              </div>
              <div className={styles.feedDate}>{timeAgo}</div>
            </div>
            {!topBlock && replyData.user.user_id === session.id && (
              <div className={styles.moreOptions}>
                <MoreOptions
                  options={moreOptions}
                  className={styles.moreOptWrp}
                  onClick={handleMoreOptionClick}
                />
              </div>
            )}
          </div>

          <div
            className={styles.msg}
            dangerouslySetInnerHTML={{ __html: replyData.content }}
          />
          {replyData.documents?.length > 0 && (
            <div className={styles.aboutImages}>
              {replyData.documents.map((document, index) => {
                if (shouldDownload(document.name)) {
                  return (
                    <a
                      key={replyData.theme_post_reply_id}
                      className={styles.doc}
                      target="blank"
                      href={document.uri}
                    >
                      {document.name}
                    </a>
                  );
                }
                if (index < 2) {
                  return (
                    <img
                      style={
                        replyData.documents.length < 2
                          ? { width: "70%" }
                          : { width: "50%" }
                      }
                      key={document.id}
                      src={document.uri}
                      className={styles.descriptionImage}
                    />
                  );
                }
              })}
            </div>
          )}
          <div className={styles.aboutStats}>
            {level <= 2 || topBlock ? (
              <div
                className={styles.repliesBlock}
                onClick={() => {
                  if (handleSelectReply) {
                    handleSelectReply(replyData);
                  }
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 7.5H5.833v1.667H7.5V7.5zM9.167 7.5h1.666v1.667H9.167V7.5zM14.167 7.5H12.5v1.667h1.667V7.5z"
                    fill="#2D83AE"
                  />
                  <path
                    d="M2.5 4.167V17.5l4-3c.288-.217.64-.334 1-.333h8.333c.92 0 1.667-.746 1.667-1.667V4.167c0-.92-.746-1.667-1.667-1.667H4.167c-.92 0-1.667.746-1.667 1.667zm1.667 10v-10h11.666V12.5H6.945a1.653 1.653 0 0 0-1 .333l-1.778 1.334z"
                    fill="#2D83AE"
                  />
                </svg>

                <div className={styles.repliesText}>
                  {repliesCount ? repliesCount + " Replies" : " Reply"}
                </div>
              </div>
            ) : null}

            <div onClick={() => handleLikePost()} className={styles.likesBlock}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={isLiked ? styles.likeEnable : styles.likeDissable}
              >
                <path
                  d="M3.598 5.265a3.75 3.75 0 0 0 0 5.303L10 16.97l6.402-6.402a3.75 3.75 0 0 0-5.304-5.303L10 6.363 8.902 5.265a3.75 3.75 0 0 0-5.304 0z"
                  fill="#CBD2D9"
                />
              </svg>
              <div className={styles.likesText}>
                {replyData.likes_count || 0} Likes{" "}
              </div>
            </div>
            <div className={styles.favoritesBlock}>
              <Icon
                className={
                  isFavorite ? styles.favoriteEnable : styles.favoriteDissable
                }
                onClick={() => handleFavoritePost()}
                icon="icn-subnav-ratings-fill"
              />{" "}
              <div className={styles.favoriteText}>
                {replyData.favorite} Favorite
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedReply.theme_post_reply_id &&
        selectedReply.theme_post_reply_id === replyData.theme_post_reply_id && (
          <TextAreaFeed
            height={50}
            mode={mode}
            replyData={replyData}
            handleSelectReply={handleSelectReply}
            selectedReply={selectedReply}
            themePostId={replyData.theme_post_id}
            themeId={themeId}
            homePageUse={homePageUse}
          />
        )}
      {level <= 2 &&
        replies?.map((reply, index) => {
          if (index < 1 || seeAllReplies) {
            return (
              <div
                id={replyData.theme_post_reply_id}
                key={reply.theme_post_reply_id + "-" + reply.theme_post_id}
              >
                <FeedItem
                  replyData={reply}
                  replies={reply.replies}
                  selectedReply={selectedReply}
                  replied
                  level={level + 1}
                  handleSelectReply={handleSelectReply}
                  themeId={themeId}
                  homePageUse={homePageUse}
                  setMode={setMode}
                  setEditableReply={setEditableReply}
                />
                {seeAllReplies && index === replies?.length - 1 && (
                  <div
                    className={styles.moreReplies}
                    onClick={() => setSeeAllReplies(false)}
                  >
                    {level === 1
                      ? "Hide Replies"
                      : level === 2
                      ? "Hide Replies to Replies"
                      : null}{" "}
                  </div>
                )}
              </div>
            );
          }
          if (index === 1) {
            return (
              <div
                key={reply.theme_post_reply_id + "-" + reply.theme_post_id}
                className={styles.moreReplies}
                onClick={() => setSeeAllReplies(true)}
              >
                {level === 1
                  ? "See More Replies"
                  : level === 2
                  ? "See More Replies to Replies"
                  : null}
              </div>
            );
          }
        })}
    </div>
  );
};
export default FeedItem;

import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import checkUniqName from "common/checkUniqName";
import checkDuplicateName from "common/checkDuplicateName";
import { companyUpdateDetails } from "modules/companies/company/company.action";
import {
  Label,
  TextBox,
  UploadImgComponent,
} from "../../../../modules/primitives";
import findCurrentCustomFieldGroup from "../../../findCustomFieldGroup";

import styles from "./styles.module.scss";
import { model, validation } from "./constant";
import useS3FileUpload from "../../../hooks/s3FileUplodar.hook";
import UniqName from "../uniqName/uniqName";

const NewImageComponent = (props) => {
  const {
    formRef,
    handleOnSubmit,
    data,
    fromAdmin = false,
    rfiInstance,
    companyId,
    adminLocation,
    uniqName,
    selectedCustomFieldGroup,
    indexOfCfGroup,
  } = props;
  const [imageUrl, setImageUrl] = useState("");
  const { fileDelete } = useS3FileUpload();
  const [currentImg, setCurrentImg] = useState("");
  const [titleError, setTitleError] = useState("");
  const [unsavedImage, setUnsavedImage] = useState(data?.unsavedImage || "");
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.field_value) {
      setCurrentImg(data.field_value);
    }
  }, [data?.field_value]);

  const {
    companiesReducer: {
      companyReducer: { selectedCompany, newCustomFieldGroups },
    },
    adminCustomFieldsReducer: { customFieldGroups },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const initValues = data
    ? {
        field_placeholder: data.field_placeholder,
        field_type: data.field_type,
        link: data.field_value,
        title: data.field_name,
      }
    : null;

  const onSubmit = (values) => {
    const cfGroup =
      findCurrentCustomFieldGroup(
        selectedCustomFieldGroup,
        customFieldGroups,
        newCustomFieldGroups,
        indexOfCfGroup,
        data
      ) || [];

    if (!values.title.length) {
      const field_name = checkDuplicateName(cfGroup, "image");
      values.title = field_name;
    } else if (
      !checkUniqName(values.title, cfGroup) &&
      values.title?.trim()?.toLowerCase() !== data?.field_name?.trim()?.toLowerCase()
    ) {
      setTitleError("Field name must be unique. Please rename this field");
      return;
    }
    setTitleError("");
    values.field_value = imageUrl;
    handleOnSubmit({
      ...data,
      ...values,
      unsavedImage: imageUrl,
    });
    values.title = "";
  };

  useEffect(() => {
    if (data && data.field_value) {
      setImageUrl(data.field_value);
    }
  }, [data]);

  const onImgUpdate = (url) => {
    setImageUrl(url);
  };

  const removeImg = async (setFieldValue) => {
    const url = currentImg?.split("/").slice(3, 11).join("/");
    setCurrentImg("");
    await fileDelete(url).then(() => {
      setImageUrl(null);
      setCurrentImg(null);
      setUnsavedImage("");
      dispatch(companyUpdateDetails(data?.company_custom_field || data.company_custom_field_id));
    });
  };

  return (
    <div className={styles.wrapper}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={data ? initValues : model}
        validationSchema={validation && validation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          onSubmit(values);
        }}
      >
        {({ handleSubmit, values, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            {!fromAdmin && (
              <>
                {currentImg && (
                  <div className={styles.photoContainer}>
                    <img
                      className={styles.imgContainer}
                      src={currentImg}
                      onError={() => setCurrentImg(null)}
                    />
                  </div>
                )}
                {unsavedImage?.length > 0 && rfiInstance && (
                  <div className={styles.removeCtn}>
                    <div className={styles.removeButton} onClick={(e) => removeImg()}>
                      <span className={[styles.imgChooser]}>Remove image</span>
                    </div>
                  </div>
                )}
                {currentImg && !rfiInstance && (
                  <div className={styles.removeCtn}>
                    <div className={styles.removeButton} onClick={(e) => removeImg()}>
                      <span className={[styles.imgChooser]}>Remove image</span>
                    </div>
                  </div>
                )}
                {!currentImg && (
                  <>
                    <Label>Upload Image</Label>
                    <div className={styles.formElement}>
                      <UploadImgComponent
                        label=""
                        name="link"
                        uploadDirName={`srm/${selectedCouncil.id}/companies/${
                          selectedCompany?.id || companyId
                        }/custom_fields/images/key`}
                        onChange={onImgUpdate}
                        formProps={formikprops}
                        imgSize={styles.imgSize}
                        align="center"
                      />
                    </div>
                  </>
                )}
              </>
            )}

            <Label>Title (optional)</Label>
            <TextBox
              disabled={rfiInstance}
              type="text"
              name="title"
              placeholder="Add image title"
              formProps={{ ...formikprops, values }}
              className={styles.textBox}
            />
            <UniqName uniqName={uniqName} />
            {titleError.length > 1 && (
              <div className={styles.errorBox}>
                <sup>*</sup>
                {titleError}
              </div>
            )}
            {!adminLocation && (
              <div className="d-flex flex-column align-items-start">
                <Label>Instructions (Optional)</Label>
                <TextBox
                  type="text"
                  name="field_placeholder"
                  placeholder="Add instructions"
                  formProps={{ ...formikprops, values }}
                  className={styles.textBox}
                />
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};
export default React.memo(NewImageComponent);

import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-dropdown-select";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import styles from "../../../../scss/multiselect/multiselect.module.scss";
import { httpPost, pageMeta } from "../../../../common/httpCall";
import { BtnType, Button } from "modules/primitives";
import { useModelPopup } from "../../../../common/hooks";

const CompaniesSelectSearch = (props) => {
  const {
    className,
    name,
    labelField,
    valueField,
    formProps,
    ...rest
  } = props;
  const history = useHistory();
  const ref = useRef();
  const popup = useModelPopup();
  const [dropValues, setDropValues] = useState([]);
  const [onDropChange, setOnDropChange] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [pageSettings, setPageSettings] = useState({
    total: null,
    current: 1,
  });
  const [debouncedValue, setDebouncedValue] = useState("");

  useEffect(() => {
    if (onDropChange) {
      setOnDropChange(false);
      if (formProps?.setFieldTouched) {
        formProps.setFieldTouched(name);
      }
      if (formProps && name && valueField) {
        formProps.setFieldValue(name, dropValues[0][valueField]);
        formProps.setFieldTouched(name);
        formProps.values[name] = dropValues[0][valueField];
      }
    }
  }, [formProps, onDropChange]);

  const handleSearch = (evt) => {
    if (evt.search) {
      setQuery(evt.search);
    }
  };

  const requestNewData = (page, query) => {
    setShowLoading(true);

    const data = {
      query,
      search: {
        query,
      },
    };

    httpPost({
      apiVersion: 'v2',
      call: `companies/suggestions?page=${page || 1}&items=50`,
      data,
    })
      .pipe()
      .subscribe((res) => {
        const pagemeta = pageMeta(res);

        setPageSettings({
          ...pageSettings,
          total: pagemeta.total,
          current: page,
        });

        const newResults = res.response?.data?.map(obj => ({
          name: obj.attributes.name,
          id: obj.id,
          otherData: obj.attributes,
        }));
        const combinedResults = [...results, ...newResults || []].filter((value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
        );

        setResults(combinedResults);
        setShowLoading(false);
      });
  };

  useEffect(() => {
    const trimmedQuerry = debouncedValue.trim().toLowerCase();
    if (trimmedQuerry.length > 1) {
      requestNewData(1, trimmedQuerry);
    }
  }, [debouncedValue]);

  const loadMore = () => {
    requestNewData(pageSettings.current + 1, query.trim().toLowerCase());
  };

  const goToProfile = (company) => {
    popup.hide();
    history.push(`/companies/${company.otherData.slug}`);
  }

  const dropdownRenderer = useCallback(
    ({ props, state, methods }) => {
      const regexp = new RegExp(state?.search.trim() || "", "i");
      const options = props.options.filter((item) =>
        regexp.test(item[props.searchBy] || item[props.labelField])
      );

      return (
        <div className="d-flex flex-column react-dropdown-select-dropdown">
          {showLoading ? (
            <div className="p-4 d-flex justify-content-center">
              Searching companies...
            </div>
          ) : (
            <>
              {options.length
                ? (
                  <>
                    {
                      options.map((item, idx) => {
                        if (methods.isSelected(item)) {
                          return null;
                        }
                        const isSelected = state.values.indexOf(item) !== -1;

                        return (
                          <div
                            key={`${item.name}-${idx}`}
                            className={`d-flex justify-content-between react-dropdown-select-item align-items-center ${
                              isSelected
                                ? "react-dropdown-select-item-selected"
                                : ""
                            }`}
                            onClick={() => item.otherData.already_added ? goToProfile(item) : methods.addItem(item)}
                          >
                            <div className="d-flex flex-column">
                              <span>{item[props.labelField]}</span>
                              <div className="small text-muted" style={{ lineHeight: 1}}>{item.otherData.web_url}</div>
                            </div>
                            <div>
                              {
                                item.otherData.already_added ? (
                                  <Button btn={BtnType.FRAME_LESS} onClick={() => goToProfile(item)}>
                                    Go to profile
                                  </Button>
                                ) : (
                                  <Button btn={BtnType.FRAME_LESS} onClick={() => methods.addItem(item)}>
                                    + Add Company
                                  </Button>
                                )
                              }
                            </div>
                          </div>
                        );
                      })
                    }
                    <div className="d-flex flex-column mt-3 px-2">
                      <hr className="mb-3" />
                      <span>If your company did not appear above, you may add it as a new company</span>
                      <hr className="mt-3" />
                      <div
                        className="d-flex react-dropdown-select-item justify-content-between mt-3 mb-3 align-items-center"
                        onClick={() => methods.addItem({
                          name: query,
                          id: query,
                        })}
                      >
                        <span>
                          {query}
                        </span>
                        <Button btn={BtnType.FRAME_LESS} onClick={() => methods.addItem({
                          name: query,
                          id: query,
                        })}>
                          + Add Company
                        </Button>
                      </div>
                    </div>
                  </>
                )
                : (
                  <div className="p-4 d-flex justify-content-center">
                    {
                      query.length ? (
                        <div className="d-flex flex-column">
                          <span>No companies found for that query, use below option to add it as new company.</span>
                          <hr className="mt-3" />
                          <div
                            className="d-flex react-dropdown-select-item justify-content-between mt-3 align-items-center"
                            onClick={() => methods.addItem({
                              name: query,
                              id: query,
                            })}
                          >
                            <span>
                              {query}
                            </span>
                            <Button btn={BtnType.FRAME_LESS} onClick={() => methods.addItem({
                              name: query,
                              id: query,
                            })}>
                              + Add Company
                            </Button>
                          </div>
                        </div>
                      ) : 'Search companies...'
                    }
                  </div>
                )}
              {pageSettings &&
              pageSettings.total > 50 &&
              pageSettings.total !== results?.length ? (
                <div className={styles.loadMore} onClick={loadMore}>
                  Load more
                </div>
              ) : null}
            </>
          )}
        </div>
      );
    },

    [showLoading, results]
  );

  const onNewSelection = (value) => {
    if (!value?.length) return;

    setOnDropChange(true);
    setDropValues(value);

    if (!value[0]?.otherData) {
      formProps.setFieldValue('selectedCompany', null);
      formProps.setFieldValue('website', '');
      formProps.setFieldValue('name', value[0].name);
      return;
    }

    const otherData = value[0]?.otherData;

    if (otherData?.already_added) {
      return;
    }

    const webSite = `${otherData.web_url || ''}`;

    formProps.setFieldValue('selectedCompany', value);
    formProps.setFieldValue('website', webSite);
    formProps.setFieldValue('name', value[0].name);
    formProps.setFieldValue('company_id', value[0].id);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(query);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [query]);

  return (
    <div className={styles.multiWrp}>
      <div
        className={classNames(
          styles.container,
          className,
          formProps?.errors[name] ? styles.error : ""
        )}
        ref={ref}
        id="customCompaniesSearch"
      >
        {!onDropChange && (
          <Select
            multi={false}
            closeOnSelect
            values={dropValues}
            className={styles.dorpWrp}
            options={results}
            labelField="name"
            valueField="id"
            searchFn={(args) => {
              handleSearch(args.state);
            }}
            dropdownRenderer={(innerProps, innerState, innerMethods) =>
              dropdownRenderer(innerProps, innerState, innerMethods)
            }
            onChange={onNewSelection}
            {...rest}
          />
        )}
      </div>
      <div className={classNames(styles.errorMsg, "caption small")}>
        {name && formProps.errors[name] ? formProps.errors[name] : ""}
      </div>
    </div>
  );
};

export default React.memo(CompaniesSelectSearch);

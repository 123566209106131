import * as Yup from "yup";

const initModel = {
  note: "",
};

const validation = Yup.object().shape({
  note: Yup.string().required("Note is required"),
});

export { initModel, validation };

import React, { useState, useEffect } from "react";
import Select from "react-dropdown-select";
import classNames from "classnames";
import styles from "./dropSelect.module.scss";
import "./dropSelect.scss";

const DropSelectComponent = (props) => {
  const {
    className,
    name,
    SelecedValues = null,
    formProps: {
      errors = {},
      touched = {},
      values = [],
      handleBlur = null,
      handleChange = null,
      setFieldValue = null,
      setFieldTouched = null,
    } = {},
    options,
    selectAll = false,
    selectAllItem = "",
    labelField,
    valueField,
    placeholder,
    onChange,
    createProp,
    addPlaceholder,
    disabled,
    onCreateNew,
    createNewLabel,
    errorStyle,
    searchFn,
    defaultValue,
    deselected,
    shouldClear = false,
    onFocus,
    dropRef,
    handleConfirm,
    userConfirm,
    keepOpenDropdown,
    containerRef,
    setKeepOpenDropdown,
    setUserInteractionDropown,
    setPrevDropVal,
    prevDropVal,
    cancelPressed,
    setCancelPressed,
    ...rest
  } = props;

  const [dropValues, setDropValues] = useState([]);
  const [onDropChange, setOnDropChange] = useState(false);

  useEffect(() => {
    if (shouldClear) {
      setDropValues([]);
    }
  }, [shouldClear]);

  useEffect(() => {
    if (deselected) {
      setDropValues((prevVal) => {
        if (prevVal[0]?.name === deselected?.name) {
          return [];
        }
        return deselected;
      });
    }
  }, [deselected]);

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  useEffect(() => {
    if (onDropChange) {
      setOnDropChange(false);
      if (setFieldTouched) setFieldTouched(name);
      if (setFieldValue && dropValues.length) {
        setFieldValue(name, dropValues[0][valueField]);
        setFieldTouched(name);
        values[name] = dropValues[0][valueField];
      }
    }
  }, [onDropChange]);

  useEffect(() => {
    if ((SelecedValues || values[name]) && options && options.length > 0) {
      const selVal = (SelecedValues && SelecedValues) || (values[name] && values[name]);
      const selectedFullVal = options.find((opt) => opt[valueField] === selVal);
      if (Array.isArray(SelecedValues)) {
        setDropValues(SelecedValues);
      }
      if (selectedFullVal) {
        setDropValues([selectedFullVal]);
      }
      if (SelecedValues === "clear") {
        setDropValues([]);
        setOnDropChange(true);
      }
    }
  }, [SelecedValues, options, values[name]]);

  useEffect(() => {
    if (handleConfirm && userConfirm === false) {
      containerRef.current.click();
    }
  }, [userConfirm]);

  useEffect(() => {
    if (cancelPressed) {
      setDropValues(prevDropVal);
      setCancelPressed(false);
    }
  }, [cancelPressed]);

  const handleOnChange = (val) => {
    // val[0] - why did we need this inside if condition?
    if (onChange) onChange({ val: val[0] ? val[0][valueField] : "", obj: val });
    setOnDropChange(true);
    if (isDropDownOpen || deselected) {
      if (setUserInteractionDropown) {
        setUserInteractionDropown({ isActivated: true, val: val[0] ? val[0][valueField] : "" });
      }
      setDropValues((prevVal) => {
        if (setPrevDropVal) {
          setPrevDropVal(prevVal);
        }
        if (prevVal[0]?.name === val[0]?.name && onChange) {
          return [];
        }
        return val;
      });
    }
  };

  const onDropClose = () => {
    setIsDropDownOpen(false);
  };

  const onDropOpen = () => {
    onFocus && onFocus();
    if (handleConfirm) {
      handleConfirm();
    }
    setIsDropDownOpen(true);
  };

  return (
    <div className={styles.multiWrp}>
      <div className={[styles.container]}>
        <Select
          ref={dropRef}
          closeOnSelect
          disabled={disabled}
          values={dropValues}
          className={[styles.dorpWrp, touched[name] && errors[name] ? styles.error : '']}
          onChange={handleOnChange}
          onDropdownOpen={onDropOpen}
          keepOpen={handleConfirm && keepOpenDropdown}
          onDropdownClose={onDropClose}
          options={options}
          create={createProp}
          addPlaceholder={addPlaceholder}
          createNewLabel={createNewLabel}
          onCreateNew={onCreateNew}
          labelField={labelField}
          defaultValue={defaultValue}
          searchFn={searchFn}
          valueField={valueField}
          placeholder={placeholder}
          {...rest}
        />
      </div>
      <div className={classNames(styles.errorMsg, "caption small")}>
        {touched[name] && errors[name] ? errors[name] : ""}
      </div>
    </div>
  );
};

export default React.memo(DropSelectComponent);

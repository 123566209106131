import * as Yup from "yup";

export const formInitialValues = {
  price: '',
  reviews: [],
  originalReviews: [],
};

const urlRegex = /^(https?:\/\/)?([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})([\/\w \.-]*)*\/?$/;

export const validation = Yup.object().shape({
  reviews: Yup.array()
    .of(Yup.object().shape({
      url: Yup.string()
        .matches(urlRegex, "Please, enter a valid url")
        .required("Field is required")
    }))
});

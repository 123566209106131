import { combineEpics, ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import { errorHandler, httpGet, httpPost } from "../../common/httpCall";
import {
  MY_COMPANIES_GET_ATTEMPT,
  myCompaniesGetSuccess,
  GET_ALL_COMPANIES_BY_STATUS_ATTEMPT,
  getAllCompaniesByStatusSuccess,
  GET_HOME_FUNNEL_DATA,
  getHomeFunnelDataSuccess,
  GET_HOME_FUNNEL_THEMES_DATA,
  getHomeFunnelThemesDataSuccess,
  GET_HOME_FUNNEL_COUNTRY_DATA,
  getHomeFunnelCountryDataSuccess,
} from "./home.actions";

const epicGetMyCompanies = (action$) => action$.pipe(
  ofType(MY_COMPANIES_GET_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar } }) => httpGet({
    call: "user/my_companies",
  }).pipe(
    map((result) => myCompaniesGetSuccess(result)),
    catchError((err) => errorHandler((err, enqueueSnackbar))),
  )),
);

export const epicGetAllCompaniesByStatus = (action$, state$) => action$.pipe(
  ofType(GET_ALL_COMPANIES_BY_STATUS_ATTEMPT),
  switchMap(({ payload: { call, data, enqueueSnackbar } }) => httpPost({
    call,
    data,
  }).pipe(
    map((result) => getAllCompaniesByStatusSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const makeFirmenichUrl = (data, key) => {
  const page = `page=${data.filters?.page || 1}`;
  const chart = `chart_type=${key}`;
  const investment = `investment_track=${data.filters?.toggleView || 'all'}`;
  let sort = ''

  if (data.filters?.sortingConfig?.sortAttribute && data.filters?.sortingConfig?.sortOrder) {
    sort = `&sort_by_attribute=${data.filters?.sortingConfig.sortAttribute}&sort_direction=${data.filters?.sortingConfig.sortOrder}`
  }

  return `themes/charts?${page}&${chart}&${investment}${sort}`;
}

const formatFirmenichResponse = (res, data, key) => {
  return {
    ...res,
    key,
    toggleView: data.filters?.toggleView || 'all',
    selected_keys_segments: data.filters?.selected_keys_segments || [],
    sortingConfig: data.filters?.sortingConfig || {},
  }
}

export const epicGetHomeFunnelData = (action$) => action$.pipe(
  ofType(GET_HOME_FUNNEL_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: makeFirmenichUrl(data, 'stage'),
    data: {
      ...data.data,
      selected_keys_segments: data.filters?.selected_keys_segments || []
    },
  }).pipe(
    map((result) => getHomeFunnelDataSuccess(formatFirmenichResponse(result, data, 'stage'))),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetHomeFunnelThemesData = (action$) => action$.pipe(
  ofType(GET_HOME_FUNNEL_THEMES_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: makeFirmenichUrl(data, 'theme'),
    data: {
      ...data.data,
      selected_keys_segments: data.filters?.selected_keys_segments || []
    },
  }).pipe(
    map((result) => getHomeFunnelThemesDataSuccess(formatFirmenichResponse(result, data, 'theme'))),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetHomeFunnelContryData = (action$) => action$.pipe(
  ofType(GET_HOME_FUNNEL_COUNTRY_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: makeFirmenichUrl(data, 'country'),
    data: {
      ...data.data,
      selected_keys_segments: data.filters?.selected_keys_segments || []
    },
  }).pipe(
    map((result) => getHomeFunnelCountryDataSuccess(formatFirmenichResponse(result, data, 'country'))),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const homeEpic = combineEpics(
  epicGetMyCompanies,
  epicGetAllCompaniesByStatus,
  epicGetHomeFunnelData,
  epicGetHomeFunnelThemesData,
  epicGetHomeFunnelContryData,
);

export default homeEpic;

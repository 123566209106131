import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Formik } from "formik";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { withSnackbar } from "notistack";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { councilGet } from "modules/council/council.action";
import styles from "./login.module.scss";
import { TextBox, Button } from "../../primitives";
import { loginAttempt } from "../auth.action";
import { LoginStatus } from "../auth.constants";
import { initModel, validation } from "./login.constants";

const LoginComponent = ({ enqueueSnackbar }) => {
  const dispatch = useDispatch();
  const [getEarlyAccess, setGetEarlyAccess] = useState("");
  const [showBecauseInactive, setShowBecauseInactive] = useState(false);
  const {
    authReducer: { loginStatus, session },
  } = useSelector((state) => state);
  const history = useHistory();
  const params = useLocation().search;
  const inactivityStatus = new URLSearchParams(params).get("inactivity");
  const [formRef, setFormRef] = useState();

  useEffect(() => {
    setShowBecauseInactive(inactivityStatus?.length > 0);
  }, [inactivityStatus]);

  useEffect(() => {
    if (
      loginStatus === LoginStatus.LOGIN_SUCCESS &&
      session?.password_updated_at
    ) {
      history.push("/");
      dispatch(
        councilGet({
          id: session.default_council.id,
          enqueueSnackbar,
          integrated_tokens: session?.integrated_tokens,
        })
      );
    } else if (
      loginStatus === LoginStatus.LOGIN_SUCCESS &&
      !session?.password_updated_at
    ) {
      history.push("/setup/profile", { showPopup: true });
    }
  }, [loginStatus, session]);

  useEffect(() => {
    if (formRef) {
      window.jQuery =
        window.jQuery ||
        (() => ({
          // these are all methods required by HubSpot
          change: () => {},
          trigger: () => {},
        }));
    }
  }, [formRef]);

  useEffect(() => {
    if (getEarlyAccess) {
      setFormRef(document.querySelector(".hbspt-form"));
    }
  }, [getEarlyAccess]);

  const handleRequestAccessClick = (e) => {
    setGetEarlyAccess(true);
    const windowWithHubspot = window;

    if (windowWithHubspot.hbspt) {
      windowWithHubspot.hbspt.forms.create({
        region: "na1",
        portalId: "2131317",
        formId: "30294aea-7d15-471b-b225-9204a7dfb34d",
        onFormSubmitted($form, ctx) {
          if (formRef) {
            formRef.style.display = "none";
            setGetEarlyAccess(false);
          }
        },
      });
    }
  };

  const handleCloseForm = () => {
    setGetEarlyAccess(false);
    if (formRef) {
      formRef.style.display = "none";
      setGetEarlyAccess(false);
    }
  };

  const handleOnSubmit = (data) => {
    dispatch(loginAttempt({ data, enqueueSnackbar }));
  };

  const handleClose = () => {
    setShowBecauseInactive(false);
    history.push("/login");
  };

  return (
    <div className={styles.loginWrp}>
      {showBecauseInactive && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open
          onClose={handleClose}
          message="Note archived"
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            You have been logged out due to inactivity
          </Alert>
        </Snackbar>
      )}
      <div className={styles.loginCon}>
        <h1 className={styles.loginTitle}>Log in</h1>
        <Formik
          initialValues={initModel}
          validationSchema={validation}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleOnSubmit(values);
          }}
        >
          {({ handleSubmit, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.loginFormWrp}>
                <TextBox
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  className={styles.textBox}
                  formProps={formikprops}
                />
                <TextBox
                  type="password"
                  name="password"
                  placeholder="Password"
                  className={styles.textBox}
                  formProps={formikprops}
                />

                <div className={styles.btnWrp}>
                  {loginStatus === LoginStatus.LOGIN_FAILED && (
                    <div>Invalid login credentials. Please try again.</div>
                  )}
                  <Button
                    onClick={() => {
                      if (formRef) {
                        formRef.style.display = "none";
                        setGetEarlyAccess(false);
                      }
                    }}
                    className={styles.loginButton}
                    type="submit"
                  >
                    Login
                  </Button>
                  <Link to="/forgot-password">
                    <div
                      onClick={() => {
                        if (formRef) {
                          formRef.style.display = "none";
                          setGetEarlyAccess(false);
                        }
                      }}
                      className={classNames("caption", styles.forgetPassword)}
                    >
                      Forgot password?
                    </div>
                  </Link>
                </div>
              </div>
            </form>
          )}
        </Formik>
        <div className={styles.requestWrp}>
          <Link
            onClick={() => {
              if (formRef) {
                formRef.style.display = "none";
                setGetEarlyAccess(false);
              }
            }}
            to="/sso-login"
          >
            <div className={classNames("caption mb-4", styles.ssoLogin)}>
              SSO Login
            </div>
          </Link>
          <h5>Don't have a login yet?</h5>
          {getEarlyAccess ? (
            <Button className={styles.requestButton} onClick={handleCloseForm}>
              Close Form
            </Button>
          ) : (
            <Button
              className={styles.requestButton}
              onClick={handleRequestAccessClick}
            >
              Request Early Access
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(withSnackbar(LoginComponent));

import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

import NewProjectStepsWrapper from "modules/projects/newProjectv3/newProjectStepsWrapper";
import { BtnType, Button } from "modules/primitives";

const NewProjectMainModal = ({ wizardType, open, handleDismiss, selectedCouncil, additionalData, modalTitle }) => {
  const [projectIsCreating, setProjectIsCreating] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={handleDismiss}
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown
    >
      <DialogContent sx={{ paddingBottom: 0 }}>
        {
          !projectIsCreating && (
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h3>{modalTitle || 'New Project'}</h3>
              <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>X</Button>
            </div>
          )
        }
        <div style={{ minHeight: "40vh" }}>
          <NewProjectStepsWrapper
            wizardType={wizardType}
            selectedCouncil={selectedCouncil}
            modalInstance
            handleDismiss={handleDismiss}
            additionalData={additionalData}
            setProjectIsCreating={setProjectIsCreating}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
};

export default React.memo(NewProjectMainModal);

import React, { useState } from "react";
import { useModelPopup } from "../../hooks";
import { ModalBody, ModalFooter } from "../../../application/modal";
import { BtnType, Button } from "../../../modules/primitives";

const ConfirmActionComponent = ({
  alertText,
  onConfirm,
  showConfirmationMsg,
  confirmBtnName,
}) => {
  const [message, setMessage] = useState(null);
  const popup = useModelPopup();

  const handleConfirm = () => {
    if (showConfirmationMsg) {
      if (message?.length === 0 || !message) {
        return;
      }

      onConfirm(message);
      popup.hide();
      return;
    }

    if (onConfirm) onConfirm();
    popup.hide();
  };

  const handleDismiss = () => {
    popup.hide();
  };

  return (
    <>
      <ModalBody>
        <div className={`${showConfirmationMsg ? "mb-4" : "mb-3"}`}>
          {alertText}
        </div>
        {showConfirmationMsg && (
          <>
            <textarea
              className="w-100 border-secondary"
              defaultValue={message}
              onChange={(evt) => setMessage(evt.target.value)}
            />
            {
              (message?.length === 0 || !message) ? (
                <span className="small mt-2 text-danger">Required</span>
              ) : null
            }
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          btn={confirmBtnName === "Delete" ? BtnType.DELETE : BtnType.REGULAR}
          className={`${confirmBtnName === 'DELETE' ? 'bg-danger' : ''} ml-2`}
        >
          {confirmBtnName || "Yes"}
        </Button>
      </ModalFooter>
    </>
  );
};

export default React.memo(ConfirmActionComponent);

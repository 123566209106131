import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  AXIS_DATA,
  CHART_COLORS,
  CUSTOM_PIE_TAGS_BY_BREAKDOWN,
  EMPLOYEES_ENUM_AS_NUMBER,
  PROP_BY_COLOR,
  propsUsedFoCompaniesSelect,
  RELATIONSHIP_TAGS_COLORS,
  REVENUE_RANGE_ENUM_AS_NUMBER,
  X_AXIS_BAR,
} from "../../charts-constants";
import {
  lightenDarkenColor,
  rndInt,
  numberToUnits,
} from "../../../../../../../common/helper";
import { externalTooltipHandler } from "../customTooltip";
import { makeArrayBasedOnYears, makeFlattenTags } from "../utils";

const barChartOptions = (chartOptions) => {
  const yAxis = AXIS_DATA.find((option) => option.type === chartOptions.y);
  const xAxis = X_AXIS_BAR.find((option) => option.type === chartOptions.x);

  return {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { x, y } = chartOptions;
            const customData = context.dataset.customDataPerIndex[context.dataIndex];

            if (x === "companies") {
              if (y === "totalFunding" || y === "last_funding_amount_usd") {
                const value = customData[propsUsedFoCompaniesSelect[yAxis.type]];

                if (value) {
                  const isBillion = value > 999999999;
                  const valueAsArray = value.toLocaleString("en-US").split(",");
                  return `${context.label}//${yAxis.name} (in Millions): ${
                    valueAsArray.length
                      ? `$${valueAsArray[0]}${
                        isBillion ? `,${valueAsArray[1]}` : ""
                      }`
                      : "0"
                  }`;
                }

                return `${context.label}//${yAxis.name} (in Millions): 0}`;
              }

              return `${context.label}//${yAxis.name}: ${
                customData[propsUsedFoCompaniesSelect[yAxis.type]]
              }`;
            }

            if (y === "totalFunding" || y === "last_funding_amount_usd") {
              const value = customData[yAxis.type];

              if (value) {
                const isBillion = value > 999999999;
                const valueAsArray = value.toLocaleString("en-US").split(",");
                return `${context.label}//Number of Companies: ${
                  customData.count
                }//${yAxis.name} (in Millions): ${
                  valueAsArray.length
                    ? `$${valueAsArray[0]}${
                      isBillion ? `,${valueAsArray[1]}` : ""
                    }`
                    : "0"
                }`;
              }

              return `${context.label}//Number of Companies: ${customData.count}//${yAxis.name} (in Millions): 0`;
            }

            if (y === "numberOfCompanies") {
              return `${context.label}//${yAxis.name}: ${
                customData[yAxis.type]
              }`;
            }

            return `${context.label}//Number of Companies: ${
              customData.count
            }//${yAxis.name}: ${customData[yAxis.type]}`;
          },
        },
        enabled: false,
        position: "nearest",
        external: externalTooltipHandler,
      },
      datalabels: {
        color: "#000000",
        font: {
          weight: "bold",
        },
        display: "auto",
        clamp: true,
        align: "end",
        anchor: "end",
        formatter: (value, ctx) => {
          if (chartOptions.x === "companies") {
            if (
              value
              && (chartOptions.y === "totalFunding"
                || chartOptions.y === "last_funding_amount_usd")
            ) {
              const isBillion = value > 999999999;
              const valueAsArray = value.toLocaleString("en-US").split(",");

              return valueAsArray.length
                ? `$${valueAsArray[0]}${
                  valueAsArray[1]
                    ? `${isBillion ? `,${valueAsArray[1]}` : ""}`
                    : ""
                }`
                : "0";
            }

            return value || "";
          }

          if (
            chartOptions.y === "totalFunding"
            || chartOptions.y === "last_funding_amount_usd"
          ) {
            if (value) {
              const isBillion = value > 999999999;
              const valueAsArray = value.toLocaleString("en-US").split(",");

              return valueAsArray.length
                ? `$${valueAsArray[0]}${
                  valueAsArray[1]
                    ? `${isBillion ? `,${valueAsArray[1]}` : ""}`
                    : ""
                }`
                : "0";
            }

            return "";
          }

          return value || "";
        },
      },
    },
    title: {
      display: false,
    },
    scales: {
      y: {
        stacked: true,
        title: {
          display: true,
          text: yAxis.inBarChart,
          font: {
            weight: "bold",
            size: 16,
          },
          color: "#2d83ae",
        },
        ticks: {
          callback: (label, index, labels) => {
            if (
              chartOptions.y === "totalFunding"
              || chartOptions.y === "last_funding_amount_usd"
            ) {
              return `$${numberToUnits(label)}`;
            }

            return label;
          },
        },
        beginAtZero: true,
      },
      x: {
        stacked: true,
        title: {
          display: true,
          text: xAxis.inChart,
          font: {
            weight: "bold",
            size: 16,
          },
          color: "#2d83ae",
        },
      },
    },
    layout: {
      padding: {
        bottom: 20,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
};

export const makeFlattenRelationShipTags = (companies, relationShipTags) => relationShipTags.reduce((acc, tag) => {
  const filteredCompanies = companies.filter(
    (c) => c.council_relationship_stage_id === tag.customId,
  );

  return [
    ...acc,
    {
      ...tag,
      id: tag.slug,
      companiesIdThatBelongsTo: filteredCompanies.map((c) => c.id),
      count: filteredCompanies.length,
      color: tag?.colors?.background || RELATIONSHIP_TAGS_COLORS[tag.slug],
    },
  ];
}, []);

const getNumberOfEmployees = (total, company) => {
  const employeeRange = EMPLOYEES_ENUM_AS_NUMBER[company.num_employees_enum];
  const randomNumber = employeeRange
    ? rndInt(employeeRange.min, employeeRange.max)
    : 0;

  return total + randomNumber;
};

const getRevenueRange = (total, company) => {
  const revenueRange = REVENUE_RANGE_ENUM_AS_NUMBER[company.revenue_range];
  const randomNumber = revenueRange
    ? rndInt(revenueRange.min, revenueRange.max)
    : 0;

  return total + randomNumber;
};

const makeBarSectorsData = (
  companies,
  selectedChartOptions,
  filterBy,
  relationTags,
  councilTags,
) => {
  const { color: colorAsOption } = selectedChartOptions;
  const selectedTags = filterBy.some((t) => t.selected);
  let data = [];

  if (colorAsOption === "relationShipStatus") {
    data = relationTags
      .reduce((acc, tag) => {
        const isInFilter = selectedTags
          ? filterBy.some((t) => tag.slug === t.slug && t.selected)
          : true;
        const color = isInFilter
          ? tag?.colors?.background || RELATIONSHIP_TAGS_COLORS[tag.slug]
          : "#999999";
        const borderColor = lightenDarkenColor(color, -40);
        const companiesDataByTag = companies
          .filter((c) => c.council_relationship_stage_id === tag.customId)
          .reduce(
            (acc1, company) => ({
              totalFunding: acc1.totalFunding + company.total_fundings_sum || 0,
              count: acc1.count + 1,
              numberOfCompanies: acc1.numberOfCompanies + 1,
              last_funding_amount_usd:
                acc1.last_funding_amount_usd
                  + company.last_funding_amount_usd || 0,
              numberOfEmployees: getNumberOfEmployees(
                acc1.numberOfEmployees,
                company,
              ),
              revenue_range: getRevenueRange(acc1.revenue_range, company),
            }),
            {
              totalFunding: 0,
              count: 0,
              numberOfCompanies: 0,
              last_funding_amount_usd: 0,
              numberOfEmployees: 0,
            },
          );

        return [
          ...acc,
          {
            ...companiesDataByTag,
            ...tag,
            id: tag.slug,
            color,
            borderColor,
          },
        ];
      }, [])
      .filter((tag) => tag.count > 0);
  } else {
    const filteredCompaniesByTags = selectedTags?.length
      ? companies.filter((company) => {
        const items = company[PROP_BY_COLOR[colorAsOption].top];

        return (
          items.filter((item) => selectedTags.some(
            (tag) => tag.id === item[PROP_BY_COLOR[colorAsOption].bottom],
          )).length > 0
        );
      })
      : companies;

    data = filteredCompaniesByTags
      .reduce((acc, company) => {
        let newAcc = acc;
        const items = company[PROP_BY_COLOR[colorAsOption].top];

        if (!items) return newAcc;

        items.forEach((item) => {
          const itemId = item[PROP_BY_COLOR[colorAsOption].bottom];
          const itemExist = newAcc.some((i) => itemId === i.id);

          if (itemExist) {
            newAcc = newAcc.map((i) => {
              if (i.id === itemId) {
                return {
                  ...i,
                  count: i.count + 1,
                  numberOfCompanies: i.numberOfCompanies + 1,
                  companiesIdThatBelongsTo: [
                    ...i.companiesIdThatBelongsTo,
                    company.id,
                  ],
                  totalFunding:
                    i.totalFunding + company.total_fundings_sum || 0,
                  total_fundings_sum:
                    i.total_fundings_sum + company.total_fundings_sum || 0,
                  last_funding_amount_usd:
                    i.last_funding_amount_usd
                      + company.last_funding_amount_usd || 0,
                  numberOfEmployees: getNumberOfEmployees(
                    i.numberOfEmployees,
                    company,
                  ),
                  revenue_range: getRevenueRange(i.revenue_range, company),
                };
              }

              return i;
            });
          } else {
            const { name } = item;
            const filteredTags = filterBy
              ? filterBy.filter((t) => t.selected)
              : [];
            const isInFilter = filteredTags.length
              ? filteredTags.some((tag) => itemId === tag.id)
              : true;
            const color = isInFilter ? null : "#999999";

            newAcc = [
              ...newAcc,
              {
                name,
                id: itemId,
                count: 1,
                numberOfCompanies: 1,
                color,
                companiesIdThatBelongsTo: [company.id],
                totalFunding: company.total_fundings_sum || 0,
                total_fundings_sum: company.total_fundings_sum || 0,
                last_funding_amount_usd: company.last_funding_amount_usd || 0,
                numberOfEmployees: 1,
              },
            ];
          }
        });

        return newAcc;
      }, [])
      .filter((item) => councilTags.some((t) => t.id === item.id))
      .sort(
        (a, b) => b[propsUsedFoCompaniesSelect[selectedChartOptions.y]]
          - a[propsUsedFoCompaniesSelect[selectedChartOptions.y]],
      )
      .map((item, idx) => {
        const color = item.color === "#999999"
          ? item.color
          : CHART_COLORS[idx % CHART_COLORS.length];
        const borderColor = lightenDarkenColor(color, -40);

        return {
          ...item,
          color,
          borderColor,
        };
      });
  }

  return data;
};

const makeBarCompaniesData = (
  companies,
  selectedChartOptions,
  filterBy,
  relationShipStatuses,
  councilTags,
) => {
  const isRelationshipColor = selectedChartOptions.color === "relationShipStatus";
  const hasNumberOfEmployeesSelect = selectedChartOptions.y === "numberOfEmployees";
  const filteredTags = filterBy.filter((t) => t.selected);
  const filteredCompaniesByTags = filteredTags?.length
    ? companies.filter((company) => {
      if (isRelationshipColor) {
        return filteredTags.some(
          (tag) => tag.customId === company.council_relationship_stage_id,
        );
      }

      const items = company[PROP_BY_COLOR[selectedChartOptions.color].top];

      return (
        items.filter((item) => filteredTags.some(
          (tag) => tag.id
                === item[PROP_BY_COLOR[selectedChartOptions.color].bottom],
        )).length > 0
      );
    })
    : companies;
  const filteredByCriteria = hasNumberOfEmployeesSelect
    ? filteredCompaniesByTags
    : filteredCompaniesByTags.filter(
      (company) => company[propsUsedFoCompaniesSelect[selectedChartOptions.y]]
          && company[propsUsedFoCompaniesSelect[selectedChartOptions.y]] > 0,
    );

  let flattenTags;

  if (filteredTags.length) {
    flattenTags = null;
  } else {
    flattenTags = isRelationshipColor
      ? makeFlattenRelationShipTags(companies, councilTags)
      : makeFlattenTags(companies, selectedChartOptions, "bar", councilTags);
  }

  return filteredByCriteria
    .sort(
      (a, b) => b[propsUsedFoCompaniesSelect[selectedChartOptions.y]]
        - a[propsUsedFoCompaniesSelect[selectedChartOptions.y]],
    )
    .map((company, idx) => {
      let color;
      const getTagDetailsBasedOnCompany = flattenTags
        ? flattenTags.find(
          (t) => t.customId === company.council_relationship_stage_id,
        )
        : null;
      const tag = getTagDetailsBasedOnCompany
        || RELATIONSHIP_TAGS_COLORS[company.council_relationship_stage_name];

      if (flattenTags) {
        const tagContainsThatCompany = flattenTags.find((tag) => tag.companiesIdThatBelongsTo.some((id) => id === company.id));
        color = isRelationshipColor
          ? tag?.colors?.background
          : tagContainsThatCompany?.color
            || CHART_COLORS[idx % CHART_COLORS.length];
      } else if (filteredTags?.length === 1) {
        color = isRelationshipColor
          ? tag?.colors?.background
          : filteredTags[0].color;
      } else {
        color = isRelationshipColor
          ? tag?.colors?.background
          : CHART_COLORS[idx % CHART_COLORS.length];
      }

      const borderColor = lightenDarkenColor(color, -40);

      return {
        ...company,
        color,
        borderColor,
        label: company.name,
        totalFunding: company.total_fundings_sum || 0,
        yearsInOperation: company.years_in_operation,
      };
    });
};

const makeBarCustomData = (
  companies,
  selectedChartOptions,
  filterBy = [],
  useColorInsteadOfXAxis,
) => {
  const { x, years, color } = selectedChartOptions;
  let replacedX = x;

  if (useColorInsteadOfXAxis) {
    replacedX = color;
  }

  const { tags } = CUSTOM_PIE_TAGS_BY_BREAKDOWN[replacedX];
  const selectedTags = filterBy.some((t) => t.selected);
  let data = [];

  if (replacedX === "numberOfEmployees" || replacedX === "revenue_range") {
    data = tags
      .map((item) => {
        const getCompanies = companies.filter(
          (c) => c[
            replacedX === "numberOfEmployees"
              ? "num_employees_enum"
              : "revenue_range"
          ] === item.value,
        );
        const tagIsSelected = selectedTags
          ? filterBy.find((t) => t.id === item.id && t.selected)
          : true;
        const color = tagIsSelected ? "#00a7fa" : "#999999";
        const borderColor = lightenDarkenColor(color, -40);

        return {
          ...item,
          companiesIdThatBelongsTo: getCompanies.map((c) => c.id),
          totalFunding: getCompanies.reduce(
            (acc, c) => (acc += c.total_fundings_sum || 0),
            0,
          ),
          last_funding_amount_usd: getCompanies.reduce(
            (acc, c) => (acc += c.last_funding_amount_usd || 0),
            0,
          ),
          yearsInOperation: getCompanies.reduce(
            (acc, c) => (acc += c.years_in_operation || 0),
            0,
          ),
          council_company_score: getCompanies.reduce(
            (acc, c) => (acc += c.council_company_score || 0),
            0,
          ),
          numberOfEmployees: getCompanies.reduce(
            (acc, c) => (acc += getNumberOfEmployees(0, c)),
            0,
          ),
          revenue_range: getCompanies.reduce(
            (acc, c) => (acc += getRevenueRange(0, c)),
            0,
          ),
          count: getCompanies.length,
          numberOfCompanies: getCompanies.length,
          color,
          borderColor,
        };
      })
      .filter(
        (tag) => tag.count !== 0
          && tag[selectedChartOptions.y]
          && tag[selectedChartOptions.y] !== 0,
      );
    console.log("data", data);
  }

  if (x === "yearFounded") {
    const currYear = new Date().getFullYear();
    const listOFYears = makeArrayBasedOnYears(years, currYear);

    data = listOFYears.map((year) => {
      const tagIsSelected = selectedTags
        ? filterBy.find((t) => t.id === year && t.selected)
        : true;
      const color = tagIsSelected
        ? year === currYear
          ? "#00611d"
          : "#00a7fa"
        : "#999999";
      const borderColor = lightenDarkenColor(color, -40);
      const getCompaniesByYear = companies.filter(
        (c) => c.funding_rounds_charts && c.funding_rounds_charts[year],
      );

      return {
        name: year === currYear ? `${year} (YTD)` : year,
        id: year,
        color,
        borderColor,
        count: getCompaniesByYear.length,
        numberOfCompanies: getCompaniesByYear.length,
        companiesIdThatBelongsTo: getCompaniesByYear.map((c) => c.id),
        totalFunding: getCompaniesByYear.reduce(
          (acc, c) => (acc += c.funding_rounds_charts[year].total_fundings_sum || 0),
          0,
        ),
        last_funding_amount_usd: getCompaniesByYear.reduce(
          (acc, c) => (acc += c.funding_rounds_charts[year].last_funding_amount_usd || 0),
          0,
        ),
        council_company_score: getCompaniesByYear.reduce(
          (acc, c) => (acc += c.council_company_score || 0),
          0,
        ),
      };
    });
  }

  return data;
};

const makeBarData = (
  companies,
  selectedChartOptions,
  filterBy,
  relationShipStatuses,
  councilTags,
) => {
  let data;

  const filteredCompanies = selectedChartOptions.color === "relationShipStatus"
    ? companies.filter((c) => c.council_relationship_stage_id)
    : companies;

  if (selectedChartOptions.color === "revenue_range") {
    return makeBarCustomData(
      filteredCompanies,
      selectedChartOptions,
      filterBy,
      true,
    );
  }

  switch (selectedChartOptions.x) {
    case "sectors":
      {
        data = makeBarSectorsData(
          filteredCompanies,
          selectedChartOptions,
          filterBy,
          relationShipStatuses,
          councilTags,
        );
      }
      break;

    case "companies":
      {
        data = makeBarCompaniesData(
          filteredCompanies,
          selectedChartOptions,
          filterBy,
          relationShipStatuses,
          councilTags,
        );
      }
      break;

    case "numberOfEmployees":
    case "revenue_range":
    case "yearFounded":
      {
        data = makeBarCustomData(
          filteredCompanies,
          selectedChartOptions,
          filterBy,
        );
      }
      break;

    default: {
      data = [];
    }
  }

  return data;
};

const makeBarDataSets = (data, selectedChartOptions) => [
  {
    data: data.map((i) => i[selectedChartOptions.y]),
    backgroundColor: data.map((i) => i.color),
    borderColor: data.map((i) => i.borderColor),
    borderWidth: 1,
    customDataPerIndex: data,
  },
];

export const makeBarChartConfig = (
  companies,
  selectedChartOptions,
  filterBy,
  relationShipStatuses,
  councilTags,
) => {
  const data = makeBarData(
    companies,
    selectedChartOptions,
    filterBy,
    relationShipStatuses,
    councilTags,
  );
  const datasets = makeBarDataSets(data, selectedChartOptions);

  return {
    data: {
      labels: data.map((i) => i.name || i.label),
      datasets,
    },
    options: barChartOptions(selectedChartOptions),
    plugins: [ChartDataLabels],
    hasNoData: data.length === 0,
  };
};

import React, {useCallback, useEffect} from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SnackbarProvider } from "notistack";
import { IntercomProvider } from "react-use-intercom";
import Appsignal from "@appsignal/javascript";
import { ErrorBoundary } from "@appsignal/react";
import { useDispatch, useSelector } from "react-redux";
import { differenceInHours } from "date-fns";

import SalesforceIntegration from "./modules/settings/integrationsSettings/salesforceIntegration";
import MainComponent from "./application/main";
import LoginComponent from "./modules/auth/login";
import SSOLoginComponent from "./modules/auth/sso-login";
import SuccessSsoLoginComponent from "./modules/auth/success-sso-login";
import ForgetPasswordComponent from "./modules/auth/forgetPassword";
import PrivateRoute from "./common/privateRoute";
import "./scss/global.scss";
import UrlFormGenerator from "./applicantForm/modules/url-form-generator";
import IdeaUrlFormGenerator from "./ideaForm/modules/url-form-generator";
import ThankYou from "./applicantForm/modules/thank-you";
import Error from "./applicantForm/modules/error";
import HomeXTerms from "modules/homeXTerms";
import IdleComponent from "application/idleComponent";
import { getCookie } from "./common/helper";
import { getNewToken } from "modules/auth/auth.action";

const INTERCOM_APP_ID = "vrh5mv0c";

const appSignal =
  process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "prod"
    ? new Appsignal({
        key:
          process.env.REACT_APP_ENV === "dev"
            ? process.env.REACT_APP_APPSIGNAL_DEV
            : process.env.REACT_APP_APPSIGNAL,
        name:
          process.env.REACT_APP_ENV === "prod"
            ? "Traction Frontend Production"
            : "Traction Frontend Staging",
        environment:
          process.env.REACT_APP_ENV === "prod" ? "production" : "staging",
      })
    : undefined;

const makeAppSignalTags = () => {
  const UID = getCookie("uid");

  const council = localStorage.getItem("council");
  const path = window.location.href;

  if (!UID) {
    return {
      path,
    };
  }

  // const userSession = JSON.parse(sessionOnInit);
  const selectedCouncil = council ? JSON.parse(council) : null;

  return {
    path,
    council: selectedCouncil?.name || "",
    user_name: UID || "",
    user_email: UID || "",
  };
};

function App() {
  const dispatch = useDispatch();
  const {
    authReducer: { session },
  } = useSelector((state) => state);

  const checkTokenExpire = (expireTime) => {
    const getExpirationDate = new Date(expireTime * 1000);
    const hoursDiff = differenceInHours(getExpirationDate, new Date());

    if (hoursDiff <= 1) {
      // get new token
      dispatch(getNewToken());
    }
  };

  useEffect(() => {
    if (session?.id) {
      const getExpireTime = getCookie("expire");

      if (!getExpireTime) {
        return;
      }

      const timeout = setTimeout(() => {
        checkTokenExpire(getExpireTime);
        clearTimeout(timeout);
      }, 5000);

      const expireTokenInterval = setInterval(() => {
        // check every 15 minutes;
        checkTokenExpire(getExpireTime);
      }, 900000);

      return () => clearInterval(expireTokenInterval);
    }
  }, [session?.id]);

  const council = localStorage.getItem("council");
  const selectedCouncil = council ? JSON.parse(council) : null;
  const UID = getCookie("uid");

  const override = useCallback((span, error) => {
    span.setParams({
      path: window.location.href, // Capture the current URL
      council: selectedCouncil?.name || "",
      user_name: UID || "",
      user_email: UID || "",
      error_message: error.message || 'Unknown error', // Capture the error message
      error_stack: error.stack || 'No stack trace', // Optionally, capture the stack trace
    });
    return span
  }, []);

  return (
    <ErrorBoundary
      instance={appSignal}
      action="App"
      tags={makeAppSignalTags()}
      override={override}
    >
      <BrowserRouter>
        <Helmet>
          <title>Traction</title>
          <script
            charset="utf-8"
            type="text/javascript"
            async
            defer
            id="hbspt"
            src="//js.hsforms.net/forms/shell.js"
          />

          <style type="text/css">
            {`
					.hbspt-form{
						width: 60%;
						position: absolute;
						margin-top: 50px;
						margin-left: 20%;
					}
			`}
          </style>
        </Helmet>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <SnackbarProvider
            autoHideDuration={3000}
            maxSnack={3}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {session?.id && <IdleComponent />}
            <Switch>
              <Route exact path="/homex-terms" component={HomeXTerms} />
              <Route exact path="/login" component={LoginComponent} />
              <Route exact path="/sso-login" component={SSOLoginComponent} />
              <Route
                exact
                path="/sso-login-kyndryl"
                component={() => <SSOLoginComponent kyndrylInstance />}
              />
              <Route
                exact
                path="/success-sso-login"
                component={SuccessSsoLoginComponent}
              />
              <Route
                exact
                path="/salesforce/integration"
                component={SalesforceIntegration}
              />
              <Route
                exact
                path="/forgot-password"
                component={ForgetPasswordComponent}
              />
              <Route
                exact
                path="/submission-form/:councilSlug/:formSlug"
                component={UrlFormGenerator}
              />
              <Route
                exact
                path="/submission-idea-form/:councilSlug/:formSlug"
                component={IdeaUrlFormGenerator}
              />
              <Route
                exact
                path="/submission-form/submit-success"
                component={ThankYou}
              />
              <Route exact path="/submission-form/error" component={Error} />
              <PrivateRoute path="/" component={MainComponent} />
            </Switch>
          </SnackbarProvider>
        </IntercomProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;

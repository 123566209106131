import React, { useCallback, useRef, useState } from "react";
import Tooltip from "@mui/material/Tooltip";

import TypingList from "modules/tractionAI/components/typingList";
import styles from "../../../tractionAI.module.scss";
import SWOTSlidesModal from "modules/tractionAI/screens/insights/swot/swotSlidesModal";
import { copyToClipBoardFromRef } from "modules/tractionAI/utils";
import { AnimationProvider } from "modules/tractionAI/components/typingList/animationContext";
import ItemErrorHandler from "modules/tractionAI/components/itemErrorHandler";
import { BtnType, Button } from "modules/primitives";

const SWOTItemResponse = ({ item, enqueueSnackbar, index, onAnimationComplete, goToNewScreen }) => {
  const [animationIsReady, setAnimationIsReady] = useState(false);

  const ref = useRef(null);

  const handleCopy = useCallback(() => {
    if (!ref?.current) return;

    copyToClipBoardFromRef(ref, enqueueSnackbar);
  }, [ref, enqueueSnackbar]);

  const handleOnAnimationComplete = () => {
    onAnimationComplete();
    setAnimationIsReady(true);
  }

  return (
    <div className="d-flex flex-column justify-content-end pr-2">
      <div className="d-flex aling-items-end justify-content-end">
        <div className={styles.searchByQuery}>{item.searchByQuery}</div>
      </div>
      <div ref={ref}>
        <AnimationProvider >
          <TypingList
            chunk={item.data}
            doNotAnimate={item.doNotAnimate || item.hasError}
            fetched={item.fetched}
            onAnimationComplete={handleOnAnimationComplete}
          />
        </AnimationProvider>
      </div>
      <ItemErrorHandler item={item} />
      {(animationIsReady || item.doNotAnimate) && !item.hasError && (
        <div className="d-flex mt-2">
          <div className="d-flex">
            <Tooltip
              title="Copy"
              placement="top"
            >
              <div className="cursor-pointer" onClick={handleCopy}>
                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                    stroke="#1C274C" strokeWidth="1.5" />
                  <path
                    d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                    stroke="#1C274C" strokeWidth="1.5" />
                </svg>
              </div>
            </Tooltip>
            <SWOTSlidesModal item={item} index={index} />
          </div>
          <div className="d-flex align-items-center ml-5">
            <Button
              btn={BtnType.LINK}
              onClick={() => goToNewScreen('scout', '', item.searchByQuery)}
            >Recommend Companies</Button>
            {
              item.isCompanySearch ? (
                <Button
                  btn={BtnType.LINK}
                  onClick={() => goToNewScreen('insight', 'companySnapshot', item.searchByQuery, item.companyId)}
                  className="ml-4"
                >Company Snapshot</Button>
              ) : (
                <Button
                  btn={BtnType.LINK}
                  onClick={() => goToNewScreen('insight', 'trend', item.searchByQuery)}
                  className="ml-4"
                >Trend Report</Button>
              )
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(SWOTItemResponse);

import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { themesSearchAttempt } from "modules/themes/themesMainPage/themesMain.action";
import { themesProjectAddAttempt } from "modules/themes/themesIndividualPage/themesOverviewTab/overviewTab.action";
import { deleteRelatedThemeAttempt } from "../overview.action";
import {
  BtnType,
  Button,
  Label,
  MultiSelect,
  Loading,
} from "../../../../primitives";
import { ModalFooter } from "../../../../../application/modal";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../common/hooks";

const AddProjectThemes = ({ selectedProject }) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();

  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { themesData, loadingThemes },
    },
  } = useSelector((state) => state);
  const [selectedThemes, setSelectedThemes] = useState(null);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(false);
  const [elasticQuery, setElasticQuery] = useState("");
  const [shouldUpdateApprovers, setShouldUpdateApprovers] = useState(true);
  const [otherData, setOtherData] = useState({
    skip_completed_tasks: false,
    update_all_tasks: true,
  });

  useEffect(() => {
    if (!themesData?.themes?.length) return;

    const filteredThemes = themesData.themes.filter((option) => {
      const isAlreadySelected = selectedProject?.themes?.find((item) => {
        return item?.theme_id === option.id;
      });
      return !isAlreadySelected;
    });

    setOptions(filteredThemes);
  }, [themesData]);

  useEffect(() => {
    if (selectedCouncil) {
      const data = {
        sort_order: "asc",
        sort_attribute: "name",
        search: {
          query: "",
        },
      };
      dispatch(themesSearchAttempt({ page: 1, data, fromMain: true }));
    }
  }, [selectedCouncil]);

  const handleOptionSelect = (values) => {
    setError(false);

    setSelectedThemes(values[0]);
  };

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleCompaniesSave = () => {
    const data = {
      ...otherData,
      theme_project: [{ project_id: selectedProject.id }],
    };

    dispatch(
      themesProjectAddAttempt({
        themeId: selectedThemes.id,
        data,
        enqueueSnackbar,
        fromProjects: true,
      })
    );

    if (selectedCouncil.traction_tag === "ford") {
      const res = selectedProject?.themes.forEach((theme) => {
        dispatch(
          deleteRelatedThemeAttempt({
            id: theme.theme_id,
            projectId: selectedProject.id,
            enqueueSnackbar: () => {},
          })
        );
      });
    }

    setError(false);
    popup.hide();
  };

  useEffect(() => {
    const data = {
      sort_order: "asc",
      sort_attribute: "name",
      search: {
        query: elasticQuery,
      },
    };
    dispatch(themesSearchAttempt({ page: 1, data, fromMain: true }));
  }, [debouncedValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(elasticQuery);
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [elasticQuery]);

  return (
    <div>
      <div className={styles.customPadding}>
        <Label>
          {selectedCouncil?.settings?.themes_display_name || "Themes"}
        </Label>
        <div className={styles.selectorWrp}>
          <MultiSelect
            SelecedValues={[]}
            onChange={handleOptionSelect}
            placeholder={`Add ${
              selectedCouncil?.settings?.themes_display_name || "themes"
            } (type to search or select from below)`}
            labelField="name"
            valueField="id"
            searchBy="name"
            options={options}
            searchFn={(args) => {
              setElasticQuery(args.state.search);
            }}
          />
          <div className={styles.loaderWrp}>
            {loadingThemes && <Loading hideString />}
          </div>
        </div>
        {error && (
          <span className={styles.errorText}>
            At least on should be selected
          </span>
        )}
        <label className="d-flex align-items-center mt-3 mb-1">
          <input
            type="checkbox"
            checked={shouldUpdateApprovers}
            onChange={() => setShouldUpdateApprovers(!shouldUpdateApprovers)}
          />
          <span className="d-block ml-3">Update Reviewers + Approvers</span>
        </label>
        <>
          {shouldUpdateApprovers ? (
            <>
              {loadingThemes ? (
                <Loading />
              ) : (
                <div className="d-flex flex-column mb-3 ml-3">
                  <div className="d-flex align-items-center">
                    <input
                      id="yes"
                      value={true}
                      checked={otherData?.skip_completed_tasks || false}
                      name="type"
                      type="radio"
                      onChange={() => {
                        setOtherData({
                          skip_completed_tasks: true,
                          update_all_tasks: false,
                        })
                      }}
                    />
                    <label className="pl-2" htmlFor="yes">
                      Skip completed Tasks
                    </label>
                  </div>

                  <div className="d-flex align-items-center">
                    <input
                      id="no"
                      value={false}
                      name="type"
                      checked={otherData?.update_all_tasks || false}
                      type="radio"
                      onChange={() => {
                        setOtherData({
                          skip_completed_tasks: false,
                          update_all_tasks: true,
                        })
                      }}
                    />
                    <label className="pl-2" htmlFor="no">
                      Update All Tasks
                    </label>
                  </div>
                </div>
              )}
            </>
          ) : null}
        </>
      </div>
      <ModalFooter>
        <Button
          className="mr-3"
          btn={BtnType.FRAME_LESS}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button disabled={!selectedThemes?.id} onClick={handleCompaniesSave} btn={BtnType.REGULAR}>
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default memo(AddProjectThemes);

import React, {
  memo, useCallback, useEffect, useRef, useState,
} from "react";
import { debounce } from "throttle-debounce";

import PieChart from "./pieChart";
import {
  getChart,
  initializeChart,
} from "modules/companies/companyMain/chartsWrapper/chartsView/charts/utils";
import { waitForElm } from "../../../../common/helper";
import { makePieChartConfig } from "modules/companies/companyMain/chartsWrapper/chartsView/charts/pieChart/pieUtils";
import styles from "./innovationChartWrapper.module.scss";

const InnovationChartWrapper = ({
  companies,
  selectedTags,
  handleSelectTags,
  chartOptions,
  tags,
  relationShipStatuses,
}) => {
  const ref = useRef();

  const [chart, setChart] = useState(null);
  const [wrapperWidth, setWrapperWidth] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", debounceResize);

    return () => {
      window.removeEventListener("resize", null);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      setWrapperWidth(ref.current.clientWidth);
    }
  }, [ref.current]);

  useEffect(() => {
    if (chart) {
      const chartElem = document.getElementById("pieChart");

      if (!chartElem) return;

      chartElem.onclick = (evt) => {
        const points = chart.getElementsAtEventForMode(
          evt,
          "nearest",
          { intersect: true },
          true,
        );

        if (!points.length) return;

        const firstPoint = points[0];
        const dataSet = chart.data.datasets[0];
        const value = chart.data.datasets[firstPoint.datasetIndex].customDataPerIndex[
          firstPoint.index
        ];
        const newTags = tags.map((t) => {
          if (t.id === value.id) {
            const isAlreadySelected = selectedTags.some(
              (t1) => t1.id === value.id,
            );

            return {
              ...t,
              selected: !isAlreadySelected,
            };
          }

          const isAlreadySelected = selectedTags.some((t1) => t1.id === t.id);

          return {
            ...t,
            selected: isAlreadySelected,
          };
        });

        const filterSelectedTags = newTags.filter((t) => t.selected);

        dataSet.customDataPerIndex.forEach((el, idx) => {
          if (!filterSelectedTags.length) {
            dataSet.backgroundColor[idx] = dataSet.customDataPerIndex[idx].originalColor;
          } else {
            const getTag = newTags.find((tag) => el.id === tag.id);

            if (getTag.selected) {
              dataSet.backgroundColor[idx] = getTag.color;
            } else {
              dataSet.backgroundColor[idx] = "#999999";
            }
          }
        });

        chart.update();

        handleSelectTags(newTags);
      };
    }

    return () => null;
  }, [chart, tags, selectedTags, handleSelectTags, companies]);

  useEffect(() => {
    if (wrapperWidth && !chart && companies && chartOptions) {
      makeChartConfig(
        {
          ...chartOptions,
          toggleView: "pie",
        },
        selectedTags,
      );
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [wrapperWidth, chart, companies, chartOptions, selectedTags]);

  useEffect(() => {
    makeChartConfig(chartOptions, selectedTags);
  }, [selectedTags, companies, chartOptions]);

  const debounceResize = debounce(1000, false, () => {
    if (ref?.current) {
      setWrapperWidth(ref?.current?.clientWidth);
    }
  });

  const makeChartConfig = useCallback(
    (options, filterBy) => {
      if (!companies || !options || !relationShipStatuses.length) return;

      const chartConfig = makePieChartConfig(
        companies,
        options,
        filterBy || tags,
        relationShipStatuses,
        [],
        true,
      );

      waitForElm("#pieChart").then(() => {
        const chartInstanceById = getChart("pieChart");

        if (chartInstanceById) chartInstanceById.destroy();

        const newChart = initializeChart("pieChart", "pie", chartConfig);

        setChart(newChart);
      });
    },
    [companies, chartOptions, relationShipStatuses, tags, chart],
  );

  const askForChartRedraw = useCallback(() => {
    makeChartConfig({
      ...chartOptions,
      toggleView: "pie",
    });
  }, [chartOptions, companies, selectedTags]);

  const returnChartNode = useCallback(
    (width) => {
      if (companies) {
        if (chart && chart?.width !== width) {
          chart.width = width;
          chart.update();
        }

        return (
          <PieChart
            width={width}
            chartHeight={300}
            askForRedraw={askForChartRedraw}
          />
        );
      }
    },
    [companies, chartOptions, chart, selectedTags],
  );

  const handleResetAll = () => {
    const newTags = tags.map((t) => ({
      ...t,
      selected: false,
    }));
    handleSelectTags(newTags);
  };

  if (chart && !chart.config?._config?.data?.datasets?.length) {
    return null;
  }

  return (
    <div ref={ref} className={styles.innovationChartWrapper}>
      {wrapperWidth && returnChartNode(wrapperWidth - 20)}
      {wrapperWidth && chart && selectedTags?.length ? (
        <span className={styles.reset} onClick={handleResetAll}>
          Reset
        </span>
      ) : null}
    </div>
  );
};

export default memo(InnovationChartWrapper);

import { createSelector } from "reselect";

const getIdCompanyId = (state, props) => props.companyId;
const getIsUsedInStages = (state, props) => props.stageUse;
const getCompanies = (state) => state.projectsReducer.companyReducer.companyList;

export const getCompanyDetails = createSelector(
  getCompanies,
  getIdCompanyId,
  getIsUsedInStages,
  (companies, id, stageUse) => {
    if (stageUse) {
      return companies.find((c) => c.company.id === id);
    }

    return companies.find((c) => c.project_company_id === id);
  },
);

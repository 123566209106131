import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError, mergeMap, delay } from "rxjs/operators";

import {
  httpGet,
  httpPost,
  httpPatch,
  httpDelete,
  httpPut,
  apiStatus,
  errorHandler,
} from "../../../common/httpCall";
import {
  COMPANY_GET_ATTEMPT,
  companyGetSuccess,
  COMPANY_ADD_ACTIVITY,
  companyAddActivitySuccess,
  COMPANY_GET_ACTIVITIES_ATTEMPT,
  companyGetActivitiesSuccess,
  GET_COMPANY_DETAILS_FIELDS,
  getCompanyDetailsFieldsSuccess,
  COMPANY_PUT_DETAILS_FIELDS_ATTEMPT,
  companyPutDetailsFieldsSuccess,
  POST_CUSTOM_FIELDS,
  addCustomFieldsSuccess,
  GET_COMPANY_GROUP_FIELDS,
  getCompanyGroupFieldsSuccess,
  companyDeleteDetailsFieldsSuccess,
  COMPANY_DELETE_DETAILS_FIELDS_ATTEMPT,
  COMPANY_DELETE_ACTIVITY_ATTEMPT,
  companyDeleteActivitySuccess,
  companyPutActivitySuccess,
  COMPANY_PUT_ACTIVITY_ATTEMPT,
  COMPANY_RATING_SAVE_ATTEMPT,
  companyRatingSaveSuccess,
  COMPANY_RATING_EDIT_ATTEMPT,
  companyRatingEditSuccess,
  COMPANY_ADD_RELATIONSHIP_ATTEMPT,
  addRelationShipSuccess,
  COMPANY_DELETE_RELATIONSHIP_ATTEMPT,
  deleteRelationSuccess,
  COMPANY_DELETE_CONTACT_ATTEMPT,
  deleteContactSuccess,
  COMPANY_EDIT_DETAILS_ATTEMPT,
  editDetailsSuccess,
  deleteCompanySuccess,
  COMPANY_DELETE_ATTEMPT,
  COMPANY_SHARE_ATTEMPT,
  shareCompanySuccess,
  companyRequestResearchSuccess,
  COMPANY_REQUEST_RESEARCH_ATTEMPT,
  COMPANY_EDIT_RELATIONSHIP_ATTEMPT,
  companyEditRelationshipSuccess,
  COMPANY_GET_PROJECT_TASKS_ATTEMPT,
  companyGetProjectTasksSuccess,
  COMPANY_GET_CUSTOMERS_ATTEMPT,
  companyGetCustomersSuccess,
  catchErrorSuccess,
  COMPANY_ADD_RATING_NOTES_ATTEMPT,
  companyAddRatingNotesSuccess,
  COMPANY_GET_RATING_NOTES_ATTEMPT,
  companyGetRatingNotesSuccess,
  COMPANY_DELETE_RATING_NOTES_ATTEMPT,
  companyDeleteRatingNotesSuccess,
  COMPANY_EDIT_RATING_NOTES_ATTEMPT,
  companyEditRatingNotesSuccess,
  COMPANY_HOME_REQUEST_RESEARCH_ATTEMPT,
  GET_COMPANY_CONTACTS_ATTEMPT,
  getCompanyContactsSuccess,
  COMPANY_CONTACT_INVITE,
  companyContactInviteSuccess,
  COMPANY_DELETE_DETAILS_TEMPLATE_FIELDS_ATTEMPT,
  companyDeleteDetailsTemplateFieldsSuccess,
  COMPANY_PRODUCTS_GET_ATTEMPT,
  getCompanyProductsSuccess,
  COMPANY_PRODUCT_DELETE,
  getCompanyProductDeleteSuccess,
  GET_CUSTOM_GROUP_FIELDS,
  getCustomGroupFieldsSuccess,
  SAVE_CUSTOM_FIELD_TABLE_ROW,
  saveCustomFieldTableRowSuccess,
  DELETE_CUSTOM_FIELD_TABLE_ROW,
  deleteCustomFieldTableRowSuccess,
  GET_COMPANY_TAGS_ATTEMPT,
  getCompanyTagsSuccess,
  GET_COMPANY_ACTIVITIES_ATTEMPT,
  getCompanyActivitiesSuccess,
  GET_CUSTOM_FIELD_HISTORY_ATTEMPT,
  getCustomFieldHistorySuccess,
} from "./company.action";
import {
  peopleAddMemberNoteSuccess,
  peopleEditMemberNoteSuccess,
} from "modules/people/Profile/profile.action";

const makeCustomFieldsUrl = (payload) =>
  `companies/${payload.companyId}/custom_field_groups/${payload.groupId}/${
    payload.isTableGroup ? "custom_field_tables" : "custom_fields"
  }`;

export const epicGetCompanyFieldsByGroup = (action$) =>
  action$.pipe(
    ofType(GET_CUSTOM_GROUP_FIELDS),
    mergeMap(({ payload }) =>
      httpGet({
        apiVersion: "v2",
        call: makeCustomFieldsUrl(payload),
      }).pipe(
        delay(200),
        map((result) =>
          getCustomGroupFieldsSuccess({
            ...result,
            groupId: payload.groupId,
            isTableGroup: payload.isTableGroup,
          })
        ),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const epicGetCompanyProducts = (action$) =>
  action$.pipe(
    ofType(COMPANY_PRODUCTS_GET_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `companies/${payload.company_id}/products?page=${
          payload.page || 1
        }${payload.sort_order ? `&sort_order=${payload.sort_order}` : ""}`,
      }).pipe(
        map((result) => {
          if (payload.cb) {
            payload.cb();
          }

          return getCompanyProductsSuccess({
            ...result,
            sort_order: payload.sort_order,
            sort_attribute: payload.sort_attribute,
          });
        }),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const epicDeleteCompanyProduct = (action$) =>
  action$.pipe(
    ofType(COMPANY_PRODUCT_DELETE),
    switchMap(({ payload, enqueueSnackbar }) =>
      httpDelete({
        call: `companies/${payload.company_id}/products/${payload.product_id}`,
      }).pipe(
        map(() => getCompanyProductDeleteSuccess(payload.product_id)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicCompanyRatingSave = (action$) =>
  action$.pipe(
    ofType(COMPANY_RATING_SAVE_ATTEMPT),
    switchMap(({ payload }) =>
      httpPost({
        call: `ratings/universal/?council_id=${payload.council_id}&company_id=${payload.company_id}`,
        data: {
          universal_rating: { ...payload },
        },
      }).pipe(
        map((result) => {
          try {
            if (
              result.status === apiStatus.CREATED ||
              result.status === apiStatus.SUCCESS
            ) {
              payload.enqueueSnackbar("Successfully added", {
                variant: "success",
              });
              return companyRatingSaveSuccess(result);
            }
          } catch (error) {
            console.log("-----????  catch ?????-----", error);
          }
        }),
        catchError((error) =>
          errorHandler(error, payload.enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const epicCompanyRatingEdit = (action$) =>
  action$.pipe(
    ofType(COMPANY_RATING_EDIT_ATTEMPT),
    switchMap(({ payload }) =>
      httpPatch({
        call: `ratings/universal/${payload.universal_rating_id}?council_id=${payload.council_id}&company_id=${payload.company_id}`,
        data: {
          universal_rating: { ...payload },
        },
      }).pipe(
        map((result) => {
          {
            try {
              if (
                result.status === apiStatus.CREATED ||
                result.status === apiStatus.SUCCESS
              ) {
                payload.enqueueSnackbar("Successfully added", {
                  variant: "success",
                });
                return companyRatingEditSuccess(result);
              }
            } catch (error) {
              console.log("-----????  catch ?????-----", error);
            }
          }
        }),

        catchError((error) =>
          errorHandler(error, payload.enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const epicGetCompany = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_GET_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        apiVersion: `${payload.fromFirmenich ? "v2" : "v1"}`,
        call: payload.fromFirmenich
          ? `firmenich/companies/${payload.id}?council_id=${payload.council_id}`
          : `companies/${payload.id}?council_id=${payload.council_id}`,
      }).pipe(
        map((result) => companyGetSuccess(result)),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const epicCompanyAddActivity = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_ADD_ACTIVITY),
    switchMap(
      ({ payload: { data, company_custom_activity_id, enqueueSnackbar } }) =>
        httpPost({
          call: "custom_activities",
          data: {
            ...data,
            company_custom_activity_id,
          },
        }).pipe(
          map((result) => {
            if (result.status === 200 || result.status === 201) {
              if (data.notable_type === "User") {
                enqueueSnackbar("Note saved in profile.", {
                  variant: "success",
                });
                data.history.push(
                  `/people/internal/profile/${data.notable_id}`
                );
                return peopleAddMemberNoteSuccess(result);
              }
              enqueueSnackbar("Note saved in Activity tab.", {
                variant: "success",
              });

              if (data.shouldRedirectToActivity && data.history) {
                if (data.patient_org) {
                  data.history.push(
                    `/patient_organizations/${
                      data.slug || data.gsk_patient_organization_id
                    }/activity`
                  );
                } else {
                  data.history.push(
                    `/companies/${data.slug || data.company_id}/activity`
                  );
                }
              }
            }
            return companyAddActivitySuccess(result);
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicGetCompanieActivities = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_GET_ACTIVITIES_ATTEMPT),
    switchMap(
      ({
        payload: {
          company_id,
          userId,
          items,
          page,
          gsk_patient_organization_id,
          gsk,
          patient_org,
          enqueueSnackbar,
          cb,
        },
      }) =>
        httpGet({
          call: `custom_activities?company_id=${company_id}&gsk_patient_organization_id=${gsk_patient_organization_id}&gsk=${gsk}&patient_org=${patient_org}
      &items=${items || 1000}&page=${page || 1}`,
        }).pipe(
          map((result) => {
            if (cb) {
              cb();
            }

            return companyGetActivitiesSuccess(result);
          }),
          catchError((err) =>
            errorHandler(err, enqueueSnackbar, [], catchErrorSuccess)
          )
        )
    )
  );

export const epicGetCompanyDetailsGroupFields = (action$, state$) =>
  action$.pipe(
    ofType(GET_COMPANY_GROUP_FIELDS),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpGet({
        call: `custom_field_groups?company_id=${id}`,
      }).pipe(
        map((result) => getCompanyGroupFieldsSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetCompanyDetailsFields = (action$, state$) =>
  action$.pipe(
    ofType(GET_COMPANY_DETAILS_FIELDS),
    switchMap(
      ({ payload: { company_id, council_id, items, page, enqueueSnackbar } }) =>
        httpGet({
          // call: `companies/${company_id}/custom_fields?council_id=${council_id}`,
          call: `companies/${company_id}/custom_fields`,
          apiVersion: "v2",
        }).pipe(
          map((result) => getCompanyDetailsFieldsSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicPostCustomFields = (action$, state$) =>
  action$.pipe(
    ofType(POST_CUSTOM_FIELDS),
    switchMap(
      ({ payload: { council_id, company_id, data, enqueueSnackbar, cb } }) =>
        httpPost({
          call: `companies/${company_id}/custom_fields?council_id=${council_id}`,
          data,
        }).pipe(
          map((result) => {
            enqueueSnackbar("Custom field was successfully added.", {
              variant: "success",
            });
            if (cb) {
              cb();
            }
            return addCustomFieldsSuccess(result);
          }),
          catchError((e) => errorHandler(e, enqueueSnackbar))
        )
    )
  );

export const epicPutCompanydetailsFields = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_PUT_DETAILS_FIELDS_ATTEMPT),
    switchMap(
      ({
        payload: {
          company_id,
          council_id,
          company_custom_field_id,
          enqueueSnackbar,
          data,
          cb,
        },
      }) =>
        httpPut({
          call: `companies/${company_id}/custom_fields/${company_custom_field_id}?council_id=${council_id}`,
          data: {
            company_custom_field_id,
            custom_field: data,
          },
        }).pipe(
          map((result) => {
            enqueueSnackbar("Custom fields were successfully modified.", {
              variant: "success",
            });

            if (cb) {
              cb();
            }

            return companyPutDetailsFieldsSuccess({
              ...result,
              company_custom_field_id,
            });
          }),
          catchError((e) => errorHandler(e, enqueueSnackbar))
        )
    )
  );

export const epicDeleteCompanydetailsFields = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_DELETE_DETAILS_FIELDS_ATTEMPT),
    switchMap(
      ({
        payload: {
          company_id,
          council_id,
          company_custom_field_id,
          data,
          enqueueSnackbar,
          groupId,
        },
      }) =>
        httpDelete({
          call: `companies/${company_id}/custom_fields/${company_custom_field_id}?council_id=${council_id}`,
          data,
        }).pipe(
          map((result) => {
            if (result.status === apiStatus.SUCCESS) {
              enqueueSnackbar("Custom field successfully deleted.", {
                variant: "success",
              });
            }
            return companyDeleteDetailsFieldsSuccess({
              ...result,
              groupId,
              company_custom_field_id,
            });
          }),
          catchError((e) =>
            errorHandler(e, enqueueSnackbar, [], catchErrorSuccess)
          )
        )
    )
  );

export const epicDeleteCompanyDetailsTemplateFields = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_DELETE_DETAILS_TEMPLATE_FIELDS_ATTEMPT),
    switchMap(
      ({
        payload: {
          company_id,
          council_id,
          custom_field_template_id,
          data,
          enqueueSnackbar,
          groupId,
        },
      }) =>
        httpDelete({
          call: `companies/${company_id}/custom_field_templates/${custom_field_template_id}?council_id=${council_id}`,
          data,
          apiVersion: "v2",
        }).pipe(
          map((result) => {
            if (result.status === apiStatus.SUCCESS) {
              enqueueSnackbar("Custom field successfully deleted.", {
                variant: "success",
              });
            }
            return companyDeleteDetailsTemplateFieldsSuccess({
              ...result,
              groupId,
              custom_field_template_id,
            });
          }),
          catchError((e) =>
            errorHandler(e, enqueueSnackbar, [], catchErrorSuccess)
          )
        )
    )
  );

export const epicDeleteCompany = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_DELETE_ATTEMPT),
    switchMap(
      ({ payload: { company_id, council_id, enqueueSnackbar, name } }) =>
        httpDelete({
          call: `/councils/${council_id}/companies/${company_id}`,
        }).pipe(
          map((result) => {
            enqueueSnackbar(`You deleted the Company: ${name}`, {
              variant: "success",
            });
            return deleteCompanySuccess(result);
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicDeleteCompanyActivity = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_DELETE_ACTIVITY_ATTEMPT),
    switchMap(
      ({
        payload: {
          company_id,
          custom_activity_id,
          data,
          gsk_patient_organization_id,
          gsk,
          patient_org,
          enqueueSnackbar,
        },
      }) =>
        httpDelete({
          call: `custom_activities/${custom_activity_id}?company_id=${company_id}&gsk_patient_organization_id=${gsk_patient_organization_id}&gsk=${gsk}&patient_org=${patient_org}`,
          data,
        }).pipe(
          map((result) => companyDeleteActivitySuccess(result)),
          catchError((error) =>
            errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
          )
        )
    )
  );

export const epicPutCompanyActivitiy = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_PUT_ACTIVITY_ATTEMPT),
    switchMap(
      ({
        payload: { company_custom_activity_id, data, enqueueSnackbar, orgType },
      }) =>
        httpPatch({
          call: `custom_activities/${company_custom_activity_id}?gsk=${
            orgType === "patient"
          }&patient_org=${orgType === "patient"}`,
          data,
        }).pipe(
          map((result) => {
            if (data.notable_type === "User") {
              enqueueSnackbar("You updated the note", {
                variant: "success",
              });
              data.history.push(`/people/internal/profile/${data.notable_id}`);
              return peopleEditMemberNoteSuccess(result);
            }
            enqueueSnackbar("You updated the note", {
              variant: "success",
            });
            return companyPutActivitySuccess(result);
          }),
          catchError((error) =>
            errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
          )
        )
    )
  );

export const epicPostRelationship = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_ADD_RELATIONSHIP_ATTEMPT),
    switchMap(
      ({ payload: { council_id, company_id, data, enqueueSnackbar } }) =>
        httpPost({
          call: `companies/${company_id}/relationship_owners/batch`,
          data: {
            relationship_owners: data.user_ids?.map((userId) => ({
              user_id: userId,
            })) || [{ external_team_id: data?.external_team_id }],
          },
        }).pipe(
          map((result) => addRelationShipSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );
//  data: { "relationship_owners": data.map((user) =>({"user_id": user.id}) )   },
//        data: {
//  "relationship_owners": selectedItems.map((user) =>({"user_id": user.id}) )
// },
export const epicDeleteRelationship = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_DELETE_RELATIONSHIP_ATTEMPT),
    switchMap(
      ({
        payload: {
          company_relationship_owner_id,
          company_id,
          data,
          enqueueSnackbar,
        },
      }) =>
        httpDelete({
          call: `companies/${company_id}/relationship_owners/${company_relationship_owner_id}`,
          data,
        }).pipe(
          map((result) => deleteRelationSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicEditRelationship = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_EDIT_RELATIONSHIP_ATTEMPT),
    switchMap(
      ({ payload: { company_relationship_owner_id, data, enqueueSnackbar } }) =>
        httpPatch({
          call: `companies/relationship_owners/${company_relationship_owner_id}`,
          data,
        }).pipe(
          map((result) =>
            companyEditRelationshipSuccess({
              ...result,
              company_relationship_owner_id,
            })
          ),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicDeleteCompanyContact = (action$) =>
  action$.pipe(
    ofType(COMPANY_DELETE_CONTACT_ATTEMPT),
    switchMap(({ payload: { company_contact_id, enqueueSnackbar } }) =>
      httpDelete({
        call: `companies/contacts/${company_contact_id}`,
      }).pipe(
        map((result) => {
          enqueueSnackbar("Contact successfully removed.", {
            variant: "success",
          });
          return deleteContactSuccess(result);
        }),
        catchError((e) => errorHandler(e, enqueueSnackbar))
      )
    )
  );

export const epicPutCompanyDetails = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_EDIT_DETAILS_ATTEMPT),
    switchMap(
      ({
        payload: {
          councilId,
          companyId,
          data,
          enqueueSnackbar,
          listId,
          callBack,
          fromFirmenich,
          updateWithoutRequest,
        },
      }) =>
        httpPut({
          apiVersion: `${fromFirmenich ? "v2" : "v1"}`,
          call: fromFirmenich
            ? `firmenich/companies/${companyId}`
            : `councils/${councilId}/companies/${companyId}`,
          data: fromFirmenich
            ? {
                company: {
                  ...data,
                },
              }
            : {
                council_company: {
                  ...data,
                },
              },
        }).pipe(
          map((res) => {
            if (callBack) {
              callBack(res);
            }
            const result = {
              ...res,
              listId,
              updateWithoutRequest,
            };
            return editDetailsSuccess(result);
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicCompanyShare = (action$) =>
  action$.pipe(
    ofType(COMPANY_SHARE_ATTEMPT),
    switchMap(({ payload: { id, council_id, emails, enqueueSnackbar } }) =>
      httpPost({
        call: `/councils/${council_id}/companies/${id}/share`,
        data: {
          emails,
        },
      }).pipe(
        map((result) => {
          enqueueSnackbar("Profile was successfully shared.", {
            variant: "success",
          });
          return shareCompanySuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicCompanyHomeRequestResearch = (action$) =>
  action$.pipe(
    ofType(COMPANY_HOME_REQUEST_RESEARCH_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        call: "request_researches",
        data,
      }).pipe(
        map((result) => {
          enqueueSnackbar("Request research was sent!", {
            variant: "success",
          });
          return companyRequestResearchSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicCompanyRequestResearch = (action$) =>
  action$.pipe(
    ofType(COMPANY_REQUEST_RESEARCH_ATTEMPT),
    switchMap(
      ({
        payload: {
          company_id,
          information,
          assign_to_traction,
          company_custom_field_ids,
          custom_field_template_ids = [],
          requested_by_id,
          assigned_to_id,
          isPatientOrganization = false,
          enqueueSnackbar,
          team_id,
          company_contact_id,
        },
      }) =>
        httpPost({
          call: `${
            isPatientOrganization ? "gsk/patient_organizations" : "companies"
          }/${company_id}/${
            isPatientOrganization
              ? "request_researches"
              : "company_request_researches"
          }`,
          data: {
            [isPatientOrganization
              ? "patient_organization_custom_field_ids"
              : "company_custom_field_ids"]: company_custom_field_ids,
            custom_field_template_ids,
            information,
            assign_to_traction,
            requested_by_id,
            assigned_to_id,
            team_id,
            company_contact_id,
          },
        }).pipe(
          map((result) => {
            enqueueSnackbar("Request research was sent!", {
              variant: "success",
            });
            return companyRequestResearchSuccess(result);
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );
export const epicGetCompanyProjects = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_GET_PROJECT_TASKS_ATTEMPT),
    mergeMap(
      ({
        payload: { company_id, isPatientOrganization, enqueueSnackbar, cb },
      }) =>
        httpGet({
          call: `${
            isPatientOrganization ? "gsk/patient_organizations" : "companies"
          }/${company_id}/projects`,
        }).pipe(
          map((result) => {
            if (cb) {
              cb();
            }

            return companyGetProjectTasksSuccess(result);
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicGetCustomers = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_GET_CUSTOMERS_ATTEMPT),
    switchMap(({ payload: { query, enqueueSnackbar } }) =>
      httpGet({
        call: `customers?query=${query}`,
      }).pipe(
        map((result) => companyGetCustomersSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicAddRatingNotes = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_ADD_RATING_NOTES_ATTEMPT),
    switchMap(({ payload: { companyId, data, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v2",
        call: `companies/${companyId}/notes`,
        data,
      }).pipe(
        map((result) => companyAddRatingNotesSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

export const epicGetRatingNotes = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_GET_RATING_NOTES_ATTEMPT),
    switchMap(({ payload: { companyId, enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v2",
        call: `companies/${companyId}/notes`,
      }).pipe(
        map((result) => companyGetRatingNotesSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

export const epicDeleteRatingNotes = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_DELETE_RATING_NOTES_ATTEMPT),
    switchMap(({ payload: { companyId, enqueueSnackbar, noteId } }) =>
      httpDelete({
        apiVersion: "v2",
        call: `companies/${companyId}/notes/${noteId}`,
      }).pipe(
        map((result) => companyDeleteRatingNotesSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );
export const epicEditRatingNotes = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_EDIT_RATING_NOTES_ATTEMPT),
    switchMap(({ payload: { companyId, enqueueSnackbar, noteId, data } }) =>
      httpPut({
        apiVersion: "v2",
        call: `companies/${companyId}/notes/${noteId}`,
        data,
      }).pipe(
        map((result) => companyEditRatingNotesSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

export const epicGetCompanyContacts = (action$, state$) =>
  action$.pipe(
    ofType(GET_COMPANY_CONTACTS_ATTEMPT),
    switchMap(({ payload: { companyId, enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v2",
        call: `companies/${companyId}/contacts`,
      }).pipe(
        map((result) => getCompanyContactsSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

export const epicContactInvite = (action$) =>
  action$.pipe(
    ofType(COMPANY_CONTACT_INVITE),
    switchMap(({ payload: { companyId, enqueueSnackbar, data, cb } }) =>
      httpPost({
        apiVersion: "v2",
        call: `companies/${companyId}/contacts/invite`,
        data,
      }).pipe(
        map((result) => {
          if (cb) {
            cb();
          }
          return companyContactInviteSuccess({
            ...result,
            data,
          });
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

export const epicTableCustomFieldRowSave = (action$) =>
  action$.pipe(
    ofType(SAVE_CUSTOM_FIELD_TABLE_ROW),
    switchMap(({ payload }) =>
      httpPost({
        apiVersion: "v2",
        call: payload.url,
        data: payload.data,
      }).pipe(
        map((result) => {
          if (payload.cb) {
            payload.cb();
          }

          return saveCustomFieldTableRowSuccess({
            ...result,
            groupId: payload.groupId,
            row: payload.row,
          });
        }),
        catchError((error) =>
          errorHandler(error, payload.enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const epicTableCustomFieldRowDelete = (action$) =>
  action$.pipe(
    ofType(DELETE_CUSTOM_FIELD_TABLE_ROW),
    switchMap(({ payload }) =>
      httpDelete({
        apiVersion: "v2",
        call: payload.url,
        data: payload.data,
      }).pipe(
        map((result) => {
          if (payload.cb) {
            payload.cb();
          }

          return deleteCustomFieldTableRowSuccess({
            ...result,
            groupId: payload.groupId,
            index: payload.index,
          });
        }),
        catchError((error) =>
          errorHandler(error, payload.enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const epicGetCompanyTags = (action$) =>
  action$.pipe(
    ofType(GET_COMPANY_TAGS_ATTEMPT),
    switchMap(({ payload: { companyId, enqueueSnackbar, cb } }) =>
      httpGet({
        apiVersion: "v1",
        call: `council_resource_custom_tags?taggable_type=Company&taggable_id=${companyId}`,
      }).pipe(
        map((response) => {
          if (cb) {
            cb();
          }
          return getCompanyTagsSuccess(response);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

export const epicGetCompanyActivities = (action$) =>
  action$.pipe(
    ofType(GET_COMPANY_ACTIVITIES_ATTEMPT),
    switchMap(({ payload: { companyId, enqueueSnackbar, cb, page, total } }) =>
      httpGet({
        apiVersion: "v1",
        call: `/companies/${companyId}/aggregated_activities?items=${
          total || 5
        }&page=${page || 1}`,
      }).pipe(
        map((response) => {
          if (cb) {
            cb();
          }
          return getCompanyActivitiesSuccess(response);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );
export const epicGetCompanyCustomFieldHistory = (action$) =>
  action$.pipe(
    ofType(GET_CUSTOM_FIELD_HISTORY_ATTEMPT),
    switchMap(
      ({
        payload: { customFieldId, enqueueSnackbar, cb, companyID, page, total },
      }) =>
        httpGet({
          apiVersion: "v1",
          call: `/companies/${companyID}/custom_field_versions/${customFieldId}?items=${
            total || 10
          }&page=${page || 1}`,
        }).pipe(
          map((response) => {
            if (cb) {
              cb();
            }
            return getCustomFieldHistorySuccess(response);
          }),
          catchError((error) => errorHandler(error, enqueueSnackbar))
        )
    )
  );

const companyEpic = combineEpics(
  epicGetCompany,
  epicCompanyAddActivity,
  epicGetCompanieActivities,
  epicPostCustomFields,
  epicGetCompanyDetailsFields,
  epicPutCompanydetailsFields,
  epicGetCompanyDetailsGroupFields,
  epicDeleteCompanydetailsFields,
  epicDeleteCompanyActivity,
  epicPutCompanyActivitiy,
  epicCompanyRatingSave,
  epicCompanyRatingEdit,
  epicPostRelationship,
  epicDeleteRelationship,
  epicDeleteCompanyContact,
  epicPutCompanyDetails,
  epicDeleteCompany,
  epicCompanyShare,
  epicCompanyRequestResearch,
  epicEditRelationship,
  epicGetCompanyProjects,
  epicGetCustomers,
  epicAddRatingNotes,
  epicGetRatingNotes,
  epicDeleteRatingNotes,
  epicEditRatingNotes,
  epicCompanyHomeRequestResearch,
  epicGetCompanyContacts,
  epicContactInvite,
  epicDeleteCompanyDetailsTemplateFields,
  epicGetCompanyProducts,
  epicDeleteCompanyProduct,
  epicGetCompanyFieldsByGroup,
  epicTableCustomFieldRowSave,
  epicTableCustomFieldRowDelete,
  epicGetCompanyTags,
  epicGetCompanyCustomFieldHistory
);

export default companyEpic;

import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import adminFuseHoc from "common/HOC/adminFuseHoc";
import { useSnackbar } from "notistack";
import Switch from "react-switch";
import WarningMessage from "common/components/warningMessage/index";
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import classnames from "classnames";
import "react-table/react-table.css";
import { getCookie } from "common/helper";

import {
  getStageTemplateByIdAttempt,
  getStageAttempt,
  patchStageTemplateByIdAttempt,
} from "../adminProjects.actions";
import ConfirmDeleteComponent from "../../../../common/components/confirmDelete";
import { Button, BtnType, MoreOptions, Loading } from "../../../primitives";
import styles from "./adminProjectTemplate.module.scss";
import { useModelPopup } from "../../../../common/hooks";
import AddNewStageModal from "./addNewStageModal/index";
import AddNewTaskModal from "./addNewTaskModal/addNewTaskModal.component";
import DocumentModal from "./documentModal/index";
import { Icon, mdSize } from "../../../../common/icon";
import { isFerminichCouncil } from "modules/projects/project/isFerminichCouncil";

const AdminProjectTemplate = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const {
    councilReducer: { selectedCouncil },
    adminProjectsReducer: {
      stageTemplate,
      stages,
      tasks,
      loading,
      shouldReloadTasks,
    },
  } = useSelector((state) => state);
  const tab = history.location.pathname.slice(
    16,
    history.location.pathname.lastIndexOf("/")
  );
  const [searchVal, setSearchVal] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef();
  const [selectedVariant, setSelectedVarian] = useState(tab);
  const [tableDataStages, setTableDataStages] = useState(stages);
  const [tableDataTasks, setTableDataTasks] = useState(tasks);
  const [isClickSort, setIsClickSort] = useState("");
  const [sortType, setSortType] = useState("");
  const [wasTasksreversed, setWasTasksReversed] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isFirstPositionUsed, setIsFirstPositionUsed] = useState(true);

  const onTabClick = (tab) => {
    setSelectedVarian(tab);
    history.push(`/admin/projects/${tab}/${props.match.params.id}`);
  };

  useEffect(() => {
    if (stages?.length) {
      const firstStage = stages?.find((stage) => stage?.sort_order === 1);

      setIsFirstPositionUsed(firstStage);
    }
  }, [stages]);

  const handleAutomateDates = () => {
    const data = {
      id: stageTemplate?.id,
      name: stageTemplate?.name,
      description: stageTemplate?.description,
      // sort_order: stagePosition,
      automate_due_dates: !stageTemplate?.automate_due_dates,
      enqueueSnackbar,
    };

    dispatch(patchStageTemplateByIdAttempt(data));
  };

  useEffect(() => {
    setSelectedVarian(tab);
  }, [tab]);

  useEffect(() => {
    if (stages) {
      setTableDataStages(stages);
    }
  }, [stages]);

  useEffect(() => {
    const combinedArr = tasks.map((task) => {
      const foundStage = stages.find(
        (stage) => stage.id === task.stage.stage_id
      );

      return {
        ...task,
        stage: foundStage,
      };
    });
    setTableDataTasks(tasksSortByPositionAndStage(combinedArr));
  }, [tasks, stages]);

  useEffect(() => {
    setSortType("");
  }, [selectedVariant]);

  useEffect(() => {
    dispatch(getStageTemplateByIdAttempt({ id, enqueueSnackbar }));
  }, []);

  useEffect(() => {
    if (shouldReloadTasks) {
      dispatch(getStageTemplateByIdAttempt({ id, enqueueSnackbar }));
    }
  }, [shouldReloadTasks]);

  useEffect(() => {
    if (stageTemplate) {
      dispatch(getStageAttempt({ id: stageTemplate.id, enqueueSnackbar }));
    }
  }, [stageTemplate]);

  const handleExportList = () => {
    csvLinkRef.current.link.click();
  };

  useEffect(() => {
    if (stages || tasks) {
      if (selectedVariant === "stages") {
        setCsvData(() => {
          const csvDataCont = [["Stage Name", "Description", "Position"]];
          stages.map((stage) => {
            csvDataCont.push([stage.name, stage.description, stage.sort_order]);
          });

          return csvDataCont;
        });
      } else if (selectedVariant === "tasks") {
        setCsvData(() => {
          const csvDataCont = [
            ["Task Name", "Description", "Stage", "Position"],
          ];
          tasks.map((task) => {
            csvDataCont.push([
              task.name,
              task.description,
              task.stage.name,
              task.position,
            ]);
          });

          return csvDataCont;
        });
      }
    }
  }, [stages, selectedVariant, tasks]);

  const typeVerification = (row) => {
    if (selectedCouncil?.name === "GSK" && stageTemplate.patient_organization) {
      return "Organization Task";
    }
    if (
      row.task_type === "project_organization_specific" ||
      row.task_type === "organization"
    ) {
      return "Company Task";
    }
    if (row.task_type === "approval_gate") {
      return "Approval Task";
    }
    return isFerminichCouncil("Project Task", "Milestone", selectedCouncil);
  };

  const DisabledButton = ({ showTooltip, setShowTooltip }) => (
    <>
      {showTooltip && (
        <div className={styles.exportModalTooltip}>
          <p>
            {searchVal
              ? "Search cannot be exported "
              : "To be able to export list it must contain at least one element."}
          </p>
        </div>
      )}
      <Button
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
        className={styles.deleteSubmitButton}
        btn={BtnType.DISABLED}
      >
        EXPORT
      </Button>
    </>
  );

  const handleDocumentModalClick = (task) => {
    popup.show({
      title: "Upload task document",
      show: true,
      height: "500",
      width: "540",
      component: <DocumentModal task={task} mode="edit" />,
    });
  };

  const requestNewDataCb = (mode) => {
    if (mode === "stages") {
      if (stageTemplate) {
        const timeout = setTimeout(() => {
          dispatch(getStageAttempt({ id: stageTemplate.id, enqueueSnackbar }));
          clearTimeout(timeout);
        }, 400);
      }
    }

    if (mode === "tasks") {
      const timeout = setTimeout(() => {
        dispatch(getStageTemplateByIdAttempt({ id, enqueueSnackbar }));
        clearTimeout(timeout);
      }, 400);
    }
  };

  const handleMoreOptionClick = (e, stageProp) => {
    if (e === "delete") {
      if (selectedVariant === "stages") {
        popup.show({
          title: "Confirmation Prompt",
          show: true,
          height: "300",
          width: "540",
          component: (
            <ConfirmDeleteComponent
              id={stageProp.id}
              enqueueSnackbar={enqueueSnackbar}
              actionType="deleteStage"
            />
          ),
        });
      } else {
        popup.show({
          title: "Confirmation Prompt",
          show: true,
          height: "300",
          width: "540",
          component: (
            <ConfirmDeleteComponent
              id={stageProp.council_task_template_id}
              councilId={selectedCouncil.id}
              enqueueSnackbar={enqueueSnackbar}
              actionType="deleteTask"
            />
          ),
        });
      }
    } else if (e === "edit") {
      if (selectedVariant === "stages") {
        popup.show({
          title: "Edit Stage",
          show: true,
          height: "300",
          width: "540",
          component: (
            <AddNewStageModal
              stages={stages}
              placeHolderData={stageProp}
              mode="edit"
              requestNewDataCb={() => requestNewDataCb("stages")}
            />
          ),
        });
      } else {
        popup.show({
          title: "Edit Task",
          show: true,
          height: "400",
          width: "540",
          component: (
            <AddNewTaskModal
              placeHolderData={stageProp}
              mode="edit"
              stageId={props.match.params.id}
              requestNewDataCb={() => requestNewDataCb("tasks")}
            />
          ),
        });
      }
    }
  };

  const moreOptions = [
    { val: "edit", text: "Edit" },
    { val: "delete", text: "Delete" },
  ];
  const tasksColoms = React.useMemo(() => [
    {
      Header: () => (
        <div
          style={{
            borderBottom:
              isClickSort === "Task Name" && sortType === "sort"
                ? "2px solid black"
                : "",
            borderTop:
              isClickSort === "Task Name" && sortType === "reverse"
                ? "2px solid black"
                : "",
          }}
        >
          Task Name
        </div>
      ),
      id: "name",
      accessor: (row) => <div>{row?.name}</div>,
      width: "30%",
    },
    {
      Header: "Description",
      id: "description",
      accessor: (row) => (
        <div dangerouslySetInnerHTML={{ __html: row?.description || "" }}></div>
      ),
      width: "30%",
    },
    {
      Header: "Stage",
      id: "stage",
      accessor: (row) => <div>{row?.stage?.name}</div>,
      width: "30%",
    },
    {
      Header: () => (
        <div
          style={{
            borderBottom:
              isClickSort === "Position" && sortType === "sort"
                ? "2px solid black"
                : "",
            borderTop:
              isClickSort === "Position" && sortType === "reverse"
                ? "2px solid black"
                : "",
          }}
        >
          Position
        </div>
      ),
      id: "position",
      accessor: (row) => (
        <div className={styles.alignedCenter}>{row.position}</div>
      ),
      width: "30%",
    },
    {
      Header: () => (
        <div
          style={{
            borderBottom:
              isClickSort === "Days to complete" && sortType === "sort"
                ? "2px solid black"
                : "",
            borderTop:
              isClickSort === "Days to complete" && sortType === "reverse"
                ? "2px solid black"
                : "",
          }}
        >
          Days to complete
        </div>
      ),
      id: "days_to_complete",
      accessor: (row) => (
        <div className={styles.alignedCenter}>{row.days_to_complete}</div>
      ),
      width: "30%",
    },
    {
      Header: "Type",
      id: "task_type",
      accessor: (row) => <div>{typeVerification(row)}</div>,
      width: "30%",
    },
    {
      Header: "Documents",
      id: "documents",
      sortable: false,
      accessor: (row) => (
        <div>
          {row.documents?.length ? (
            <Icon
              onClick={() => handleDocumentModalClick(row)}
              {...mdSize}
              icon="icn-subnav-documents"
              className={styles.tileIcon}
            />
          ) : (
            <Button
              // disabled={!listTotalCount}
              onClick={() => handleDocumentModalClick(row)}
              btn={BtnType.LINK}
            >
              Upload
            </Button>
          )}
        </div>
      ),
      width: 150,
      style: {
        overflow: "visible",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
      },
    },
    {
      Header: "Options",
      id: "options",
      sortable: false,
      accessor: (row) => (
        <MoreOptions
          options={moreOptions}
          className={styles.moreOptWrp}
          onClick={(e) => {
            handleMoreOptionClick(e, row);
          }}
        />
      ),
      width: 150,
      style: {
        overflow: "visible",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
      },
    },
  ]);
  const stagesColoms = React.useMemo(() => [
    {
      Header: () => (
        <div
          style={{
            borderBottom:
              isClickSort === "Stage Name" && sortType === "sort"
                ? "2px solid black"
                : "",
            borderTop:
              isClickSort === "Stage Name" && sortType === "reverse"
                ? "2px solid black"
                : "",
          }}
        >
          Stage Name
        </div>
      ),
      id: "name",
      position: 1,
      accessor: (row) => <div>{row?.name}</div>,
      width: "30%",
    },
    {
      Header: "Description",
      id: "description",
      accessor: (row) => <div>{row?.description}</div>,
      width: "30%",
    },
    {
      Header: () => (
        <div
          style={{
            borderBottom:
              isClickSort === "Position" && sortType === "sort"
                ? "2px solid black"
                : "",
            borderTop:
              isClickSort === "Position" && sortType === "reverse"
                ? "2px solid black"
                : "",
          }}
        >
          Position
        </div>
      ),
      id: "sort_order",
      accessor: (row) => (
        <div className={styles.alignedCenter}>{row?.sort_order}</div>
      ),
      width: "30%",
    },
    {
      Header: "Options",
      id: "options",
      sortable: false,
      accessor: (row) => (
        <MoreOptions
          options={moreOptions}
          className={styles.moreOptWrp}
          onClick={(e) => {
            handleMoreOptionClick(e, row);
          }}
        />
      ),
      width: 150,
      style: {
        overflow: "visible",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
      },
    },
  ]);

  const handleNewButtonClick = () => {
    if (selectedVariant === "stages") {
      popup.show({
        title: "Add Stage",
        show: true,
        height: "300",
        width: "540",
        component: <AddNewStageModal stages={stages} />,
      });
    } else if (selectedVariant === "tasks") {
      popup.show({
        title: " Add Task",
        show: true,
        height: "300",
        width: "540",
        noFixedHeight: false,
        component: <AddNewTaskModal stageId={props.match.params.id} />,
      });
    }
  };

  const handleHeaderTableClick = (column) => {
    let header = null;

    if (selectedVariant === "stages") {
      // stages handle sorting
      if (typeof column?.Header === "function") {
        header = column?.Header()?.props?.children;
      } else {
        header = column?.Header;
      }
      if (header === "Stage Name" || header === "Position") {
        if (!sortType || sortType === "reverse") {
          setSortType("sort");
        } else if (sortType === "sort") {
          setSortType("reverse");
        }
        setIsClickSort(column.Header().props.children);
      }
    } else if (selectedVariant === "tasks") {
      // tasks handle sorting
      if (typeof column?.Header === "function") {
        header = column?.Header()?.props?.children;
      } else {
        header = column?.Header;
      }
      if (header === "Position" || header === "Task Name") {
        if (!sortType || sortType === "reverse") {
          setSortType("sort");
        } else if (sortType === "sort") {
          setSortType("reverse");
        }
        setIsClickSort(column.Header().props.children);
      }
    }
  };

  const sortTableDataByPosition = () => {
    if (sortType === "sort") {
      const sortedByPosition = tableDataStages.sort((a, b) => {
        if (a.sort_order > b.sort_order) {
          return 1;
        }
        if (a.sort_order < b.sort_order) {
          return -1;
        }
        return 0;
      });
      setTableDataStages([...sortedByPosition]);
    } else if (sortType === "reverse") {
      setTableDataStages((prevState) => [...prevState].reverse());
    }
  };

  const tasksSortByPositionAndStage = (data) =>
    data.sort((a, b) => {
      if (a?.stage?.sort_order > b?.stage?.sort_order) {
        if (a?.stage?.id === b?.stage?.id) {
          if (a.position > b.position) {
            return 1;
          }
          if (a?.position < b?.position) {
            return -1;
          }
        }
        return 1;
      }
      if (a?.stage?.sort_order < b?.stage?.sort_order) {
        if (a?.stage?.id === b?.stage?.id) {
          if (a?.position > b?.position) {
            return 1;
          }
          if (a?.position < b?.position) {
            return -1;
          }
        }
        return -1;
      }
      if (a?.stage?.sort_order === b?.stage?.sort_order) {
        if (a?.stage?.id === b?.stage?.id) {
          if (a?.position > b?.position) {
            return 1;
          }
          if (a?.position < b?.position) {
            return -1;
          }
        }
        return 0;
      }
    });

  const sortTableDataTasksByPositions = () => {
    if (sortType === "sort") {
      if (wasTasksreversed) {
        setTableDataTasks((prevState) => [...prevState].reverse());
        return;
      }
      const sortedByPosition = tasksSortByPositionAndStage(tableDataTasks);
      setTableDataTasks([...sortedByPosition]);
    } else if (sortType === "reverse") {
      setTableDataTasks((prevState) => [...prevState].reverse());
      setWasTasksReversed(true);
    }
  };

  useEffect(() => {
    if (sortType) {
      if (selectedVariant === "stages") {
        sortTableDataByPosition();
      } else if (selectedVariant === "tasks") {
        sortTableDataTasksByPositions();
      }
    }
  }, [sortType]);

  const current_role = getCookie("council_role_");
  return (
    <div className={styles.container}>
      <div className="row">
        <div className="col-10" style={{ margin: "0 auto" }}>
          <div className={styles.headerWrp}>
            <h4 className={styles.taskHeaderText}>
              {current_role &&
                (current_role === "ttp_administrator" ||
                  current_role === "council_administrator") && (
                  <span>
                    <span
                      className={styles.rootText}
                      onClick={() => {
                        history.push("/admin");
                      }}
                    >
                      ADMIN
                    </span>
                    <span className={styles.separationBar}> / </span>
                    <span
                      className={styles.rootText}
                      onClick={() => {
                        history.push("/admin/projects");
                      }}
                    >
                      PROJECTS
                    </span>
                    <span className={styles.separationBar}> / </span>
                  </span>
                )}
              <span className={styles.companyName}>{stageTemplate?.name}</span>
              <span className={styles.separationBar}> / </span>
              <span className={styles.rootText}>
                {selectedVariant === "stages" ? "STAGES" : "TASKS"}{" "}
              </span>
            </h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className={classnames(styles.tabsContainer, "col-10")}
          style={{ margin: "0 auto" }}
        >
          <h5>
            <span
              onClick={() => onTabClick("stages")}
              className={
                selectedVariant === "stages"
                  ? styles.selectedVar
                  : styles.tabsText
              }
            >
              {" "}
              STAGES{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span
              onClick={() => onTabClick("tasks")}
              className={
                selectedVariant === "tasks"
                  ? styles.selectedVar
                  : styles.tabsText
              }
            >
              {" "}
              TASKS{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span
              onClick={() => onTabClick("fields")}
              className={
                selectedVariant === "fields"
                  ? styles.selectedVar
                  : styles.tabsText
              }
            >
              {" "}
              FIELDS{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span
              onClick={() => onTabClick("groups")}
              className={styles.tabsText}
            >
              {" "}
              FIELD GROUPS{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span className={styles.tabsText} onClick={() => onTabClick("setup")}>
              {" "}
              PROJECT SET-UP{" "}
            </span>
          </h5>
          <div className={styles.buttons}>
            <Button
              className={styles.newTask}
              btn={BtnType.REGULAR}
              icon="icn-add"
              onClick={handleNewButtonClick}
            >
              {selectedVariant === "stages" ? "Add Stage" : "Add Task"}
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        {" "}
        <div className="col-10" style={{ margin: "0 auto" }}>
          <div className={styles.buttonWrp}>
            <div className={styles.automateContainer}>
              <h5 className="m-0">Automate Due Dates</h5>
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                checked={stageTemplate?.automate_due_dates}
                onColor="#24a0ed"
                onChange={handleAutomateDates}
              />
            </div>
            <div className={styles.wrpIn}>
              {stages?.length > 0 && !searchVal ? (
                <div>
                  <Button
                    // disabled={!listTotalCount}
                    onClick={handleExportList}
                    btn={BtnType.OUTLINE}
                  >
                    EXPORT
                  </Button>
                  <CSVLink
                    data={csvData}
                    filename={`${stageTemplate?.name} Project stages.csv`}
                    style={{ display: "none" }}
                    ref={csvLinkRef}
                  />
                </div>
              ) : (
                <DisabledButton
                  setShowTooltip={setShowTooltip}
                  showTooltip={showTooltip}
                />
              )}
              {selectedVariant === "stages" ? (
                <div className={styles.totalCount}>{stages?.length} stages</div>
              ) : (
                <div className={styles.totalCount}>{tasks?.length} tasks</div>
              )}
            </div>
          </div>
          {!isFirstPositionUsed && !loading && tab === "stages" && (
            <div className={styles.warningTab}>
              <WarningMessage
                message={
                  "This template will not be available because it doesn't contain a Stage with Position 1."
                }
              />
            </div>
          )}
          <ReactTable
            data={tab === "stages" ? tableDataStages : tableDataTasks}
            // data={selectedData}
            loading={loading ? <Loading /> : null}
            columns={selectedVariant === "stages" ? stagesColoms : tasksColoms}
            className={styles.table}
            getTheadThProps={(state, rowInfo, column, instance) => ({
              onClick: (e, handleOriginal) => handleHeaderTableClick(column),
            })}
            defaultSortDesc
          />
        </div>
      </div>
    </div>
  );
};

export default adminFuseHoc(AdminProjectTemplate);

import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import { Pagination, Loading } from "../../../primitives";
import styles from "../projectsMain.module.scss";
import "rc-pagination/assets/index.css";
import TableHeaderComponent from "./tableHeader.component";
import CatalogProjectRowComponent from "../projectRow/catalogProjectRow.component";
import IdeaReportBlock from "./ideaReportBlock/index";

const IdeasTabComponent = ({
  selectedTab,
  makeSortingConfig,
  drawSortingIcon,
  pageSetting,
  handlePagingChange,
  customStub,
  setSelectedFilter,
  setPageSetting,
  selectedFilter,
}) => {
  const history = useHistory();
  const {
    projectsReducer: {
      projectsMainReducer: { projectList, projectLoading, projectListMeta },
    },
  } = useSelector((state) => state);
  return (
    <div className={styles.container}>
      {projectLoading ? (
        <Loading />
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <IdeaReportBlock
                total={projectListMeta?.total}
                selectedFilter={selectedFilter}
                onItemClick={(val) => {
                  setPageSetting((prev) => ({ ...prev, current: 1 }));
                  setSelectedFilter((prev) => {
                    if (
                      prev.approval_status?.length === 1 &&
                      prev.approval_status?.includes(val)
                    ) {
                      return {
                        ...prev,
                        approval_status: ["paused", "rejected", "submitted"],
                      };
                    }
                    return { ...prev, approval_status: [val] };
                  });
                }}
              />
            </div>
          </div>

          <div className={classNames(styles.grid, styles[`${selectedTab}`])}>
            <TableHeaderComponent
              makeSortingConfig={makeSortingConfig}
              drawSortingIcon={drawSortingIcon}
              selectedTab={"project_stage_idea"}
            />
            <div className={styles.gridBody}>
              {projectList[0] ? (
                projectList.map((project) => (
                  <CatalogProjectRowComponent
                    key={project.id}
                    project={project}
                    tab={"project_stage_idea"}
                    history={history}
                  />
                ))
              ) : (
                <div className={styles.noProjects}>
                  <p>
                    {customStub
                      ? "There are no projects that match the selected filters"
                      : "There are no projects"}
                    {". "}
                  </p>
                </div>
              )}
            </div>
            <div
              className={`${styles.paginWrp} ${
                projectList.length < 10 && pageSetting?.current === 1
                  ? "d-none"
                  : ""
              }`}
            >
              <div className={styles.paginCon}>
                <Pagination {...pageSetting} onChange={handlePagingChange} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(IdeasTabComponent);

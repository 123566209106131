import React from "react";
import { useSelector, useDispatch } from "react-redux";

import styles from "modules/tractionAI/tractionAI.module.scss";
import { AISelectScreen } from "modules/tractionAI/store/tractionAI.actions";
import { AIRecommendOptions } from "modules/tractionAI/screens/recommend/constants";
import TractionAIRecommendProjects from "modules/tractionAI/screens/recommend/projects";
import TractionAIRecommendIdeas from "modules/tractionAI/screens/recommend/ideas";

const TractionAIRecommend = () => {
  const dispatch = useDispatch();

  const {
    tractionAiReducer: { activeScreen, activeSubScreen }
  } = useSelector((state) => state);

  const handleOptionSelect = (option) => {
    dispatch(AISelectScreen({
      activeScreen,
      activeSubScreen: option.id,
    }));
  }

  return (
    <>
      {
        activeSubScreen ? (
          <>
            {
              activeSubScreen === 'projects' && (
                <TractionAIRecommendProjects />
              )
            }
            {
              activeSubScreen === 'ideas' && (
                <TractionAIRecommendIdeas />
              )
            }
          </>
        ) : (
          <div className="d-flex flex-row align-items-start">
            {
              AIRecommendOptions.map(option => (
                <div
                  key={option.id}
                  className={styles.recommendationItem}
                  onClick={() => handleOptionSelect(option)}
                >{option.name}</div>
              ))
            }
          </div>
        )
      }
    </>
  );
};

export default React.memo(TractionAIRecommend);

import React, { useEffect, useState } from "react";
import classnames from "classnames";

const WebsiteComponent = ({ value, config, data }) => {
  const [clearString, setClearString] = useState('');

  useEffect(() => {
    if (value) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(value, 'text/html');
      const clearText = doc.body.textContent;

      setClearString(clearText);
    }
  }, [value]);

  const handleClick = () => {
    if (!config.withNavigation) {
      return;
    }
    
    const url = `${data?.website}`;
    window.open(url, "_blank");
  }

  return (
    <div
      className={classnames('d-flex align-items-center text-break', config.withNavigation ? 'cursor-pointer' : '')}
      onClick={handleClick}
    >
      <div className="d-flex flex-column">
        {
          !config.withNavigation && clearString ? (
            <span>
              {
                clearString?.length > 60 ? `${clearString.slice(0, 60)}...` : clearString
              }
            </span>
          ) : config.withNavigation ? (clearString || '-') : '-'
        }
      </div>
    </div>
  )
}

export default React.memo(WebsiteComponent);

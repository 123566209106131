import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import RichEditor from "common/components/richEditor";
import { checkViewerRole } from "common/checkers/viewerChecker";
import styles from "./addNotes.module.scss";
import { BtnType, Button } from "../../../../../primitives";

import {
  projectTaskNotesAdd,
  projectTaskNotesUpdate,
} from "../../tasks.action";

const AddNotesComponent = (props) => {
  const { assignmentId, selectedNote } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [note, setNote] = useState("");
  const [resetReply, setResetReply] = useState(false);

  const editorRef = useRef(null);
  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);
  const {
    authReducer: { session },
    projectsReducer: {
      projectReducer: { selectedProject },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const focus = () => {
    editorRef.current.focus();
  };

  const handleOnSubmit = () => {
    try {
      const obj = {
        projectId: selectedProject.id,
        assignmentId,
        enqueueSnackbar,
        note: {
          added_by_id: session.id,
          description: note,
          id: selectedNote?.id,
        },
      };
      if (selectedNote?.id) {
        dispatch(projectTaskNotesUpdate(obj));
      } else {
        dispatch(projectTaskNotesAdd(obj));
      }
      setResetReply(true);
      const timeout = setTimeout(() => {
        setResetReply(false);
        setNote("");
        clearTimeout(timeout);
      });
    } catch (error) {
      console.log("---------errrror", error);
    }
  };

  return (
    <div className={styles.noteCon}>
      <div className={styles.editorCon}>
        <RichEditor
          updateValue={(val) => setNote(val)}
          initialValue={selectedNote?.description || ""}
          placeholder="Summary"
          ref={editorRef}
          shouldReset={resetReply}
          customFocus
          dontFocus
        />
      </div>
      <div className="d-flex justify-content-center">
        {!blockFunctionality ? (
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleOnSubmit();
            }}
            btn={BtnType.REGULAR}
            className={styles.btnSubmit}
          >
            Submit Note
          </Button>
        ) : (
          <Tooltip
            title="You have View-Only access. To Add a Note, please ask your Admin to upgrade your account to Standard access."
            placement="top"
          >
            <div>
              <Button
                className={styles.btnSubmit}
                onClick={(e) => {
                  e.preventDefault();
                }}
                btn={BtnType.DISABLED}
              >
                Submit Note
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default React.memo(AddNotesComponent);

import React, { useRef, useState } from "react";
import { useSnackbar } from "notistack";

import styles from "./uploadAvatar.module.scss";
import useS3FileUpload from "../../../hooks/s3FileUplodar.hook";
import { Icon, mdSize } from "../../../icon";
import LinearProgressWithLabel from "../../linearProgressWithLabel";

const allowedExtensions = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "tiff",
  "bmp",
  "eps",
  "raw",
  "cr2",
  "nef",
  "orf",
  "sr2",
];

const UploadMemberAvatar = ({ imgURl, uploadDirName, onUpload }) => {
  const inputRef = useRef(null);
  const uploadBlockRef = useRef(null);
  const [img, setImg] = useState(imgURl);
  const { fileUpload, fileDelete } = useS3FileUpload();
  const [uploadValues, setUploadValues] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const getExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  const onUploadClick = () => {
    inputRef.current.click();
  };

  const removeImg = async () => {
    const url = imgURl?.split("/").slice(3, 6).join("/");
    await fileDelete(url).then(() => {
      setImg(null);
    });
  };

  const uploadHandler = async (e, action) => {
    e.preventDefault();
    // we want to let user know that he is dragging, so we change the border color for the block
    if (action === "drag" && uploadBlockRef?.current) {
      uploadBlockRef.current.style.border = "1px solid #7b8794";
      return;
    }

    let file;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      file = e.target.files[0];
    }

    if (action === "drop") {
      // on drop we just go to previous border styles
      uploadBlockRef.current.style.border = "1px solid #e4e7eb";

      if (!e || !e.dataTransfer.items.length) {
        return;
      }

      file = e.dataTransfer.items[0].getAsFile();
    }

    if (!file) {
      return;
    }
    if (
      file.size <= 100000000 &&
      allowedExtensions.indexOf(getExtension(file.name)) !== -1
    ) {
      await fileUpload(file, uploadDirName, setUploadValues, file.name, 0).then(
        (res) => {
          if (res && res.location) {
            setLoading(false);
            setImg(res.location);
            onUpload(res.location);
            setUploadValues([]);
          }
        }
      );
    } else {
      if (allowedExtensions.indexOf(getExtension(file.name)) === -1) {
        enqueueSnackbar("Extension is not allowed", {
          variant: "error",
        });
      }
      if (file.size > 100000000) {
        enqueueSnackbar(
          "We could not upload your image because your file size is too big. Please make sure the file is less than 100 MB",
          {
            variant: "error",
          }
        );
      }
      setLoading(false);
      e.target.value = "";
    }
  };

  return (
    <div className={[styles.uploadImgWrp].join(" ")}>
      <input
        ref={inputRef}
        type="file"
        id="imgUpload"
        style={{ display: "none" }}
        onChange={(e) => uploadHandler(e, "click")}
      />
      <div>
        {" "}
        {loading ? (
          <div className={styles.loaderContainer}>
            <span className={styles.loaderWrp}>
              <LinearProgressWithLabel value={uploadValues[0]?.progress || 0} />
            </span>
            <span className={styles.progressValue}>
              {uploadValues[0]?.progress || 0}%
            </span>
          </div>
        ) : (
          <div className={styles.UploadBlockWrp}>
            {" "}
            <div
              ref={uploadBlockRef}
              className={styles.uploadBlock}
              onClick={onUploadClick}
              onDrop={(e) => uploadHandler(e, "drop")}
              onDragOver={(e) => uploadHandler(e, "drag")}
            >
              {img ? (
                <img height="40" width="40" alt="" src={img} />
              ) : (
                <div>
                  <Icon
                    {...mdSize}
                    icon="icn-edit-button"
                    className="cursor-pointer"
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(UploadMemberAvatar);

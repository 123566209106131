import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useModelPopup } from "../../../../../common/hooks";
import { ModalBody, ModalFooter } from "../../../../../application/modal";
import {
  companyGetFilters,
  companyGetTopics,
} from "../../../companyMain/companyMain.action";
import { putCouncilResourceCustomTagAttempt } from "../../../../../common/actions/common.action";
import { BtnType, Button } from "../../../../primitives";
import { editDetails } from "../../company.action";
import BusinessModal from "./tagModals/business";
import TechModal from "./tagModals/tech";
import IndustryModal from "./tagModals/industry";
import CustomTagModal from "./tagModals/custom";

const AddTagComponent = ({ type, company, group }) => {
  const popup = useModelPopup();
  const [prevTopics, setPrevTopics] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [selectedTopics, setSelectedTopic] = useState([]);
  const [business, setBusiness] = useState([]);
  const [businessOptions, setBusinessOptions] = useState([]);
  const [selectedCustomTagsIds, setSelectedCustomTagsIds] = useState(
    group?.attributes?.custom_tags.map((tag) => tag.id)
  );
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [customTagsOptions, setCustomTagsOptions] = useState([]);
  const [prevIndustries, setPrevIndustries] = useState([]);
  const [prevBusiness, setPrevBusiness] = useState([]);
  const [flatTags, setFlatTags] = useState([]);
  const [allSelected, handleSelectAll] = useState(false);

  const selectAllClick = (val) => {
    if (!val) {
      setSelectedCustomTagsIds([]);
    }
    handleSelectAll(val);
  };

  const history = useHistory();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { customCompanyTags },
      companyMainReducer: { topics, industries },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (customCompanyTags.length) {
      let marketsCT;
      let filteredWithChilds;
      if (group?.attributes?.name === "Markets Served") {
        marketsCT = selectedCouncil?.council_custom_tag_groups?.filter(
          (gr) => gr.name === "Markets Served"
        )[0].custom_tags;

        filteredWithChilds = marketsCT
          .map((parent) => parent.children)
          .flat().length;
      }

      if (
        group?.attributes?.custom_tags.length ===
        filteredWithChilds + marketsCT?.length
      ) {
        handleSelectAll(true);
      }
    }
  }, [customCompanyTags]);

  useEffect(() => {
    if (selectedCouncil?.business_units?.length) {
      const flatSelected = prevBusiness?.map((el) => el.id);
      const filtered = selectedCouncil?.business_units?.filter((el) => {
        const alreadyExist = flatSelected?.find((prev) => prev === el.id);
        return !alreadyExist;
      });

      setBusinessOptions(filtered);
    }
  }, [selectedCouncil?.business_units?.length, prevBusiness]);

  useEffect(() => {
    if (selectedCouncil && selectedCouncil?.council_custom_tag_groups[0]) {
      const customTagOpt = selectedCouncil?.council_custom_tag_groups
        .find((el) => el.name === group?.attributes?.name)
        ?.custom_tags?.map((tag) => {
          return { ...tag, id: tag.custom_tag_id };
        });
      setCustomTagsOptions(customTagOpt);
      if (customTagOpt) {
        setFlatTags(
          customTagOpt
            .map((tag) => {
              if (tag.children.length) {
                return [tag, ...tag.children];
              }
              return tag;
            })
            .flat()
        );
      }
    }
    if (session) {
      dispatch(
        companyGetFilters({
          userId: session.id,
          councilId: JSON.parse(localStorage.getItem("council")).id,
          enqueueSnackbar,
        })
      );

      dispatch(
        companyGetTopics({
          userId: session.id,
          councilId: JSON.parse(localStorage.getItem("council")).id,
          enqueueSnackbar,
        })
      );

      if (!history.location?.state?.gsk) {
        setPrevIndustries(company.resource_industries);
        setPrevTopics(company.resource_topics);

        setSelectedTopic(company.resource_topics?.map((tag) => tag.topic.id));
        setIndustry(company.resource_industries?.map((tag) => tag.industry.id));
        setPrevBusiness(company.business_units);
      }
    }
  }, [session, selectedCouncil]);

  const handleTopicChange = (val) => {
    setSelectedTopic((prevState) => [...prevState, val]);
  };

  const handleIndustryChange = (val) => {
    setIndustry((prevState) => [...prevState, val]);
  };

  const handleBusinessChange = (val) => {
    setBusiness((prevState) => [...prevState, ...val]);
  };

  const handleCustomTagChange = (val) => {
    setSelectedCustomTagsIds((prev) => [...prev, ...val.map((tag) => tag.id)]);
  };

  const handleTopicRemove = (arg) => {
    if (Array.isArray(arg)) {
      setSelectedTopic((prev) => {
        return arg.map((tag) => tag.id);
      });
      const flatIds = arg.map((tag) => tag.id);
      setPrevTopics((prev) =>
        prev.filter((item) => flatIds.includes(item.topic.id))
      );
    } else {
      setSelectedTopic((prev) => {
        return prev.filter((selectedTag) => selectedTag !== arg);
      });
      setPrevTopics((prev) => prev.filter((item) => item.topic.id !== arg));
    }
  };

  const handleIndustryRemove = (arg) => {
    if (Array.isArray(arg)) {
      setIndustry((prev) => {
        return arg.map((tag) => tag.id);
      });
      const flatIds = arg.map((tag) => tag.id);
      setPrevIndustries((prev) => {
        return prev.filter((item) => {
          return flatIds.includes(item.industry.id);
        });
      });
    } else {
      setIndustry((prev) => prev.filter((selectedTag) => selectedTag !== arg));
      setPrevIndustries((prev) => {
        return prev.filter((item) => item.industry.id !== arg);
      });
    }
  };

  const handleBusinessRemove = (id) => {
    setPrevBusiness(prevBusiness.filter((item) => item.id !== id));
  };

  const handleCustomTagRemove = (val, arrIds) => {
    handleSelectAll(false);
    if (arrIds) {
      setSelectedCustomTagsIds(val);
    } else {
      setSelectedCustomTagsIds(val?.map((item) => item.id) || []);
    }
  };

  const renderTagModal = () => {
    switch (type) {
      case "tech":
        return (
          <TechModal
            selectedTopics={selectedTopics}
            handleTopicChange={handleTopicChange}
            prevTopics={prevTopics}
            handleTopicRemove={handleTopicRemove}
            topics={topics}
          />
        );
      case "business":
        return (
          <BusinessModal
            businessOptions={businessOptions}
            handleBusinessChange={handleBusinessChange}
            prevBusiness={prevBusiness}
            handleBusinessRemove={handleBusinessRemove}
            selectedCouncilName={selectedCouncil?.name}
          />
        );
      case "industry":
        return (
          <IndustryModal
            selectedCouncilName={selectedCouncil?.name}
            handleIndustryChange={handleIndustryChange}
            prevIndustries={prevIndustries}
            handleIndustryRemove={handleIndustryRemove}
            industries={industries}
            industry={industry}
          />
        );
      case "custom":
        return (
          <CustomTagModal
            selectedCouncilName={selectedCouncil?.name}
            selectedCustomTagsIds={selectedCustomTagsIds}
            customTagsOptions={customTagsOptions}
            handleCustomTagChange={handleCustomTagChange}
            handleCustomTagRemove={handleCustomTagRemove}
            flatTags={flatTags}
            handleSelectAll={selectAllClick}
            allSelected={allSelected}
            group={group}
          />
        );

      default:
        break;
    }
  };
  const prepareCustomTags = () => {
    const remainingTags = [];
    if (allSelected) {
      flatTags.forEach((elem) => {
        if (selectedCustomTagsIds.includes(elem.custom_tag_id)) {
          return;
        }
        remainingTags.push(elem.custom_tag_id);
      });
    }
    customCompanyTags.forEach((gr) => {
      if (gr.id === group.id) {
        return;
      }
      gr.attributes.custom_tags.map((ct) => remainingTags.push(ct.id));
    });
    return [...remainingTags, ...selectedCustomTagsIds];
  };

  const handleConfirm = () => {
    const uniqBusiness = [
      ...new Map(business.map((item) => [item.id, item])).values(),
    ];

    const payload = {
      companyId: company.id,
      councilId: selectedCouncil.id,
      data: {
        ...company,
        topic_ids: [...selectedTopics],
        industry_ids: [...industry],
        business_unit_ids: [
          ...uniqBusiness.map((el) => el.id),
          ...prevBusiness.map((el) => el.id),
        ],
      },
      enqueueSnackbar,
    };
    if (type === "custom") {
      const load = {
        data: {
          taggable_type: "Company",
          taggable_id: company.id,
          custom_tag_ids: prepareCustomTags(),
        },
        cb: () => {},
      };

      dispatch(putCouncilResourceCustomTagAttempt(load));
    } else {
      dispatch(editDetails(payload));
    }
    popup.hide();
  };

  const handleDismiss = () => {
    popup.hide();
  };

  return (
    <>
      <ModalBody>{renderTagModal()}</ModalBody>
      <ModalFooter>
        <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} btn={BtnType.REGULAR} className="ml-2">
          Confirm
        </Button>
      </ModalFooter>
    </>
  );
};

export default React.memo(AddTagComponent);

import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "./themes.module.scss";
import { UserInline, UserInlineTypes, Pannel } from "../../../primitives";

const ThemesComponent = () => {
  const history = useHistory();

  const {
    councilReducer: { selectedCouncil },
    eventsReducer: {
      viewEventReducer: { selectedEvent },
    },
  } = useSelector((state) => state);

  const handleOpenTheme = (id) => {
    history.push({
      pathname: `/themes/individual_page/${id}/overview`,
      state: { cardData: {} },
    });
  };

  return (
    <Pannel
      title={`Related ${
        selectedCouncil?.settings?.themes_display_name || "Themes"
      }`}
      padding="0px"
      className={styles.card}
    >
      <div className={styles.wrapper}>
        {selectedEvent.theme_events?.length &&
          selectedEvent.theme_events.map((theme) => (
            <div
              className={styles.speaker}
              key={theme.theme_id}
              onClick={() => handleOpenTheme(theme.theme_id)}
            >
              <UserInline
                local
                first={theme.name}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                type={UserInlineTypes.WITH_NAME}
                className={styles.name}
                userId={theme.theme_id}
                avatar={null}
                noNavigation
              />
            </div>
          ))}
      </div>
    </Pannel>
  );
};

export default ThemesComponent;

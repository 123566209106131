import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { useHistory } from "react-router-dom";
import { Formik, Form, FieldArray } from "formik";
import { httpGet, httpPost } from "common/httpCall";
import Switch from "react-switch";
import { DropSelect, BtnType, Button as Btn } from "../../../primitives";
import styles from "./configureExportContacts.module.scss";
import {
  preSelectedCompanyValues,
  preSelectedSalesForceValues,
} from "../preSelectedValues/contactsPreSelectedValues";

const ConfigureExportContacts = (props) => {
  const { checked, handleSwitchChange, syncToggleStatus } = props;
  const [selectedSfValues, setSelectedSfValues] = useState([]);
  const [selectedContactValues, setSelectedContactValues] = useState([]);
  const [isSelectedSfValue, setIsSelectedSfValue] = useState(true);
  const [isSelectedContactValue, setIsSelectedContactValue] = useState(true);
  const [selectedSfValue, setSelectedSfValue] = useState({});
  const [selectedContactValue, setSelectedContactValue] = useState({});
  const [sfFields, setSfFields] = useState([]);
  const [coFields, setCoFields] = useState([]);
  const [typeMatch, setTypeMatch] = useState([]);
  const [subtractedCompanyFields, setSubtractedCompanyFields] = useState([]);
  const [subtractedSfFields, setSubtractedSfFields] = useState([]);
  const [friendsArray, setFriendsArray] = useState([]);
  const [companyFieldsToSubtract] = useState([
    { text: "company_id", value: "uuid" },
  ]);
  const [sfFieldsToSubtract] = useState([
    { text: "AccountId", value: "string" },
  ]);

  const history = useHistory();
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const getMappedType = (type) => {
    switch (type) {
      case "id":
        return "uuid";
      case "textarea":
        return "text";
      case "date":
        return "datetime";
      case "picklist":
        return "json";
      case "reference":
        return "string";
      case "phone":
        return "string";
      case "email":
        return "string";
      default:
        return type;
    }
  };

  useEffect(() => {
    httpGet({
      call: "sf_integrations/get_fields",
    })
      .pipe()
      .subscribe((res) =>
        setSfFields(
          res.response.contact_fields.map((item) => ({
            text: item.name,
            value: getMappedType(item.type),
          })),
        ),
      );
    httpGet({
      call: "sf_integrations/get_contact_fields",
    })
      .pipe()
      .subscribe((res) =>
        setCoFields(
          res.response.contact_fields.map((item, index) => ({
            text: item,
            value: res.response.types[index],
          })),
        ),
      );
  }, []);

  useEffect(() => {
    const subtractedCoFields = coFields.filter(
      (i) => !companyFieldsToSubtract.map((j) => j.text).includes(i.text),
    );
    setSubtractedCompanyFields(subtractedCoFields);
    const subtractedSalesforceFields = sfFields.filter(
      (i) => !sfFieldsToSubtract.map((j) => j.text).includes(i.text),
    );
    setSubtractedSfFields(subtractedSalesforceFields);
  }, [coFields, sfFields]);

  useEffect(() => {
    if (session && selectedCouncil) {
      httpPost({
        call: "sf_integrations/get_mappings",
        data: {
          user_id: session.id,
          council_id: selectedCouncil.id,
          attribute: "contact",
          flow: "export",
        },
      })
        .pipe()
        .subscribe((res) => {
          if (res.response.length) {
            setSelectedSfValues(
              res?.response[0]?.mappedSalesforceFields ??
                preSelectedSalesForceValues,
            );
            setSelectedContactValues(
              res?.response[0]?.mappedTractionFields ??
                preSelectedCompanyValues,
            );
            setFriendsArray(res?.response[0]?.mappedTractionFields);
          } else {
            setFriendsArray(preSelectedSalesForceValues());
            setSelectedSfValues(preSelectedSalesForceValues());
            setSelectedContactValues(preSelectedCompanyValues());
          }
        });
    }
  }, [session, selectedCouncil]);

  const handleSaveMappings = (state) => {
    httpPost({
      call: "sf_integrations/save_mappings",
      data: {
        user_id: session.id,
        council_id: selectedCouncil.id,
        mappings_payload: state,
        attribute: "contact",
        flow: "export",
      },
    })
      .pipe()
      .subscribe((res) => {
        console.log(res.response);
      });
  };

  const handleSelectedFields = () => {
    const payloadArray = [];
    for (let i = 0; i < selectedContactValues.length; i += 1) {
      payloadArray.push({
        [selectedContactValues[i].text]: selectedSfValues[i].text,
      });
    }
    const state = {
      currentAttribute: "contact",
      mappedTractionFields: [...selectedContactValues],
      mappedSalesforceFields: [...selectedSfValues],
      selectedFilterOption: "",
      selectedFilter: [],
      mappedTractionCustomFields: [],
      mappedSalesforceCustomFields: [],
      syncToggle: syncToggleStatus,
    };
    handleSaveMappings(state);

    history.push({
      pathname: "/salesforce/exportData",
      state,
    });
  };

  const handleBack = () => {
    history.push({
      pathname: "/salesforce/configureData",
    });
  };

  let boundArrayHelpers;
  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ friends: Array(friendsArray.length).fill("") }}
        onSubmit={() => handleSelectedFields()}
        render={({ values }) => (
          <Form>
            <FieldArray
              name="friends"
              render={(arrayHelpers) => {
                bindArrayHelpers(arrayHelpers);
                return (
                  <div>
                    <div className={styles.backBtn}>
                      <Btn
                        className={styles.backBtn}
                        btn={BtnType.FRAME_LESS}
                        onClick={() => handleBack()}
                      >
                        Back
                      </Btn>
                    </div>
                    <div className={styles.submitBtn}>
                      <div />
                      <Btn
                        className={styles.submitBtn}
                        btn={BtnType.REGULAR}
                        type="submit"
                      >
                        Submit
                      </Btn>
                      <div className={styles.switchBtn}>
                        <Switch
                          checked={checked}
                          onChange={handleSwitchChange}
                          offColor="#76B947"
                          offHandleColor="#2F5233"
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={48}
                          className="react-switch"
                          id="material-switch"
                        />
                        {checked ? (
                          <div className={styles.toggleText}>Import</div>
                        ) : (
                          <div className={styles.toggleText}>Export</div>
                        )}
                      </div>
                    </div>
                    <div className={styles.fieldsContainer}>
                      <>
                        <div className={styles.headTitle}>
                          <div className={styles.headerContainer}>
                            <div className={styles.mapsTextHead}>Traction</div>
                            <div className={styles.mapsTextHead}>
                              Salesforce
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                    <div className={styles.fieldsContainer}>
                      {values.friends.map((friend, index) => (
                        <div>
                          {values.friends.length && (
                            <>
                              <div
                                className={styles.headTitle}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginLeft: "1rem",
                                  marginTop: "1rem",
                                }}
                              >
                                <>
                                  <div className={styles.mapsText}>
                                    {selectedContactValues[index]?.text}
                                  </div>
                                  <Button className={styles.mapsButton}>
                                    Maps
                                  </Button>
                                  <div className={styles.mapsText}>
                                    {selectedSfValues[index]?.text}
                                  </div>
                                  <Chip
                                    className={styles.chipButton}
                                    onDelete={() => {
                                      setSelectedSfValues([
                                        ...selectedSfValues.filter(
                                          (_, selectedIndex) =>
                                            selectedIndex !== index,
                                        ),
                                      ]);
                                      setSelectedContactValues([
                                        ...selectedContactValues.filter(
                                          (_, selectedIndex) =>
                                            selectedIndex !== index,
                                        ),
                                      ]);
                                      arrayHelpers.remove(index);
                                      setIsSelectedSfValue(false);
                                    }}
                                  />
                                </>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              }}
            />
            <div className={styles.fieldsContainer}>
              <div className={styles.headTitle}>
                <>
                  <DropSelect
                    selectedValues=""
                    placeholder="Select fields from Traction"
                    labelField="text"
                    valueField="text"
                    searchBy="text"
                    options={subtractedCompanyFields.filter(
                      (item) =>
                        !selectedContactValues
                          .map((value) => value.text)
                          .includes(item.text),
                    )}
                    onChange={(event) => {
                      setTypeMatch(event.obj[0].value);
                      setSelectedContactValue({
                        text: event.obj[0].text,
                        value: event.obj[0].text,
                      });
                      setIsSelectedContactValue(false);
                    }}
                  />
                  <DropSelect
                    selectedValues=""
                    disabled={isSelectedContactValue}
                    placeholder="Select fields from Salesforce"
                    labelField="text"
                    valueField="text"
                    searchBy="text"
                    onChange={(event) => {
                      setSelectedSfValue({
                        text: event.obj[0].text,
                        value: event.obj[0].text,
                      });
                      setIsSelectedSfValue(false);
                    }}
                    options={subtractedSfFields.filter(
                      (item) =>
                        !selectedSfValues
                          .map((value) => value.text)
                          .includes(item.text) && item.value === typeMatch,
                    )}
                  />
                  <Btn
                    className={styles.addButton}
                    disabled={
                      values.friends.length ===
                        subtractedCompanyFields.length ||
                      isSelectedSfValue ||
                      isSelectedContactValue
                    }
                    onClick={() => {
                      setSelectedContactValues([
                        ...selectedContactValues,
                        selectedContactValue,
                      ]);
                      setSelectedSfValues([
                        ...selectedSfValues,
                        selectedSfValue,
                      ]);
                      setIsSelectedSfValue(true);
                      setIsSelectedContactValue(true);
                      boundArrayHelpers.insert(values.friends.length - 1, "");
                    }}
                  >
                    +Add
                  </Btn>
                </>
              </div>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default React.memo(ConfigureExportContacts);

import React, { useEffect, useRef, useState } from "react";
import Select from "react-dropdown-select";
import classnames from "classnames";

import styles from "../../../../../../common/components/customFields/dropDown/styles.module.scss";

const DropdownTableCustomField = ({ row, field, fieldCoreDetails, updateCellCallback }) => {
  const [selected, setSelected] = useState([]);
  const [editActive, setEditActive] = useState(false);

  useEffect(() => {
    if (row.editMode || !field.option_selected?.length || row.isTouched) {
      setEditActive(true);
      return;
    }

    setEditActive(false);
  }, [row, field]);

  useEffect(() => {
    if (field.option_selected) {
      const options = field.option_selected.map((item) => ({ text: item, value: item }));

      setSelected(options);
    }
  }, [field.option_selected]);

  const inputRef = useRef("");

  const itemRenderer = ({ item, methods }) => {
    return (
      <div
        className={classnames(methods.isSelected(item) ? styles.selectedItem : '', styles.customDropdownItem)}
        onClick={() => methods.addItem(item)}
      >
        <span>{item.value}</span>
      </div>
    )
  }

  const handleChange = values => {
    setSelected(values);

    if (updateCellCallback) {
      updateCellCallback({ ...field, option_selected: values.map(val => val.text), });
    }
  }

  return (
    <div>
      {
        editActive ? (
          <Select
            closeOnSelect
            values={selected}
            options={fieldCoreDetails.option_values.map((item) => ({ text: item, value: item }))}
            itemRenderer={itemRenderer}
            ref={inputRef}
            className={styles.dropWrapper}
            placeholder={fieldCoreDetails.field_placeholder || 'Select'}
            labelField="text"
            valueField="value"
            searchBy="text"
            searchable
            multi={fieldCoreDetails.multiselect}
            onChange={handleChange}
          />
        ) : (
          <div className="d-flex flex-column">
            {
              selected.map(item => (
                <span key={item.value}>{item.text}</span>
              ))
            }
          </div>
        )
      }
    </div>
  );
};

export default React.memo(DropdownTableCustomField);

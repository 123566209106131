import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, groupBy, mergeMap, catchError } from "rxjs/operators";
import { httpGet, errorHandler, httpPost } from "../../../common/httpCall";
import {
  PROJECT_LAST_ACTIVITY_GET_ATTEMPT,
  projectLastActivityGetSuccess,
  PROJECT_TEAMS_GET_ATTEMPT,
  projectTeamsGetSuccess,
  PROJECTS_SEARCH_ATTEMPT,
  projectsSearchSuccess,
} from "./projectsMain.action";

export const epicGetProjectLatestActivity = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_LAST_ACTIVITY_GET_ATTEMPT),
    groupBy(
      (action) => action.payload,
      (action) => action
    ),
    mergeMap((group) =>
      group.pipe(
        switchMap(({ payload }) =>
          httpGet({
            call: `projects/${payload.id}/last_activity`, // NEW_API
          }).pipe(
            map((result) =>
              projectLastActivityGetSuccess({
                projectId: payload.id,
                data: result,
              })
            ),
            catchError((error) => errorHandler(error, payload.enqueueSnackbar))
          )
        )
      )
    )
  );

export const epicGetProjectTeams = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_TEAMS_GET_ATTEMPT),
    groupBy(
      (action) => action.payload,
      (action) => action
    ),
    mergeMap((group) =>
      group.pipe(
        switchMap(({ payload }) =>
          httpGet({
            call: `teams/${payload.id}`, // NEW_API
          }).pipe(
            map((result) => projectTeamsGetSuccess(result.response)),
            catchError((err) => errorHandler(err, payload.enqueueSnackbar))
          )
        )
      )
    )
  );

export const epicProjectsSearch = (action$) =>
  action$.pipe(
    ofType(PROJECTS_SEARCH_ATTEMPT),
    switchMap(({ payload: { ford, data, enqueueSnackbar, original } }) =>
      httpPost({
        apiVersion: "v2",
        call: `projects/search?my_projects=${
          data.selectedTab === "my_projects"
        }&page=${data.page || 1}&user_id=${data.userId}${
          data.selectedTab === "my_projects"
            ? `&created_by_id=${data.userId}`
            : ""
        }&original=${original}`,
        data,
      }).pipe(
        map((result) => projectsSearchSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const projectsMainEpic = combineEpics(
  epicGetProjectLatestActivity,
  epicGetProjectTeams,
  epicProjectsSearch
);

export default projectsMainEpic;

import React from "react";
import { Search } from "../../../../primitives";
import styles from "./simpleSearch.module.scss";

const SimpleSearchComponent = (props) => {
  const {
    handleAdvance,
    showAdvance,
    handleCrunchBase,
    handleSaveSearch,
    showMyList,
    gsk,
    patientOrg,
    mode,
  } = props;

  const handleBlur = () => {};

  return (
    <div className={styles.simpleSearchWrapper}>
      <Search
        handleBlur={handleBlur}
        onKeyUp
        className={styles.searchCon}
        handleCrunchBase={handleCrunchBase}
        placeholder="Search Organizations, Diseases"
        type="gsk"
        gsk={gsk}
        patient_org={patientOrg}
        mode="companies"
      />
    </div>
  );
};

export default React.memo(SimpleSearchComponent);

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useSnackbar, withSnackbar } from "notistack";
import Select from "react-dropdown-select";
import { format, isBefore } from "date-fns";
import classNames from "classnames";

import {
  BtnType,
  Button,
  Label,
  DropSelect,
  DatePiker,
} from "modules/primitives";
import { useModelPopup } from "common/hooks";
import {
  optionStatus,
  priorityOptions,
} from "modules/projects/project/project.constant";
import DropDownNestedElements from "common/components/dropdownNestedElements";
import styles from "modules/projects/project/manageProject/details/details.module.scss";
import RichEditor from "common/components/richEditor";
import { getUserTeams } from "modules/teams/teams.action";
import {
  projectGet,
  projectPut,
} from "modules/projects/project/project.action";
import { patientOrganizationGetDiseases } from "modules/patientOrganizations/patientOrganizationMain/patientOrganizationMain.action";
import { ratingsTemplateGetAttempt } from "modules/admin/adminRating/adminRating.action";
import {
  companyGetFilters,
  companyGetTopics,
} from "modules/companies/companyMain/companyMain.action";
import { ModalBody, ModalFooter } from "../../../../../application/modal";
import { gskOptionCountries } from "../../../../../common/constants";
import { sortCountries } from "../../../../../common/helper";

const ProjectDetailsEdit = ({ isPatientOrg, isFordSupplyChainAutomation, isNot3DPrintingTemplate }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: { topics, industries },
    },
    projectsReducer: {
      projectReducer: { selectedProject, tempMembers },
    },
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: { diseases },
    },
    adminRatingTemplatesReducer: { ratingTemplates },
    teamsReducer: { userTeams },
  } = useSelector((state) => state);

  const [automatedStatus, setAutomatedStatus] = useState(true);
  const [name, handleChangeName] = useState(selectedProject?.name || null);
  const [goal, handleChangeGoal] = useState(
    selectedProject?.description || null
  );
  const [team_id, handleChangeTeamId] = useState(
    selectedProject?.team?.team_id || null
  );
  const [nextSteps, setNextSteps] = useState(null);
  const [showComponent, setShowComponent] = useState(true);
  const [fordDates, setFordDates] = useState({
    prototype_date: selectedProject?.prototype_date || "",
    implementation_date: selectedProject?.implementation_date || "",
    expected_job_1_date: selectedProject?.expected_job_1_date || "",
    prototype_job_1_date: selectedProject?.prototype_job_1_date || "",
    proposal_for_prototype_expected_job_1_date: selectedProject?.proposal_for_prototype_expected_job_1_date || "",
  });

  useEffect(() => {
    if (selectedProject?.next_steps) {
      setNextSteps(selectedProject.next_steps);
    } else {
      setNextSteps("");
    }
  }, [selectedProject?.next_steps]);

  const [visibility, setVisibility] = useState("");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [priority, setPriority] = useState(
    selectedProject.priority || "medium"
  );
  const [status, setStatus] = useState(selectedProject.status || "");
  const [country, setCountry] = useState(selectedProject.country || "");
  const [selectedRatingsTemplate, setSelectedRatingsTemplate] = useState({});
  const [startDate, handleChangeStartDate] = useState(
    (selectedProject && selectedProject.start_date) ||
      (selectedProject && selectedProject.created_at)
  );
  const [endDate, handleChangeEndDate] = useState(selectedProject?.end_date);
  const [tags, setTags] = useState(
    selectedProject.topics.map((t) => t.topic_id)
  );
  const [selectedIndustries, setSelectedIndustries] = useState(
    selectedProject.industries.map((t) => t.industry_id)
  );
  const [selectedDiseases, setSelectedDiseases] = useState([
    ...selectedProject.diseases.map((el) => el.disease_id),
  ]);
  const [flattenDiseases, setFlattenDisease] = useState([]);
  const [error, setError] = useState(false);
  const [errorTeamId, setErrorTeamId] = useState(false);
  const [selectedTeamOption, setSelectedTeamOption] = useState([]);
  const [priorityError, setPriorityError] = useState(false);

  const [optionVisibility, setOptionVisibility] = useState([
    {
      text: "All (visible to everyone)",
      value: "all",
    },
    {
      text: "Project Members",
      value: "project_members",
    },
  ]);

  useEffect(() => {
    if (priority && priorityError) {
      setPriorityError(false);
    }
  }, [priority]);

  useEffect(() => {
    if (selectedProject) {
      setAutomatedStatus(selectedProject.automatic_status);
      dispatch(
        getUserTeams({
          enqueueSnackbar,
          query: {
            items: 1000,
          },
        })
      );
    }

    return () => null;
  }, [selectedProject]);

  const handleVisibilityChange = ({ val }) => {
    setVisibility(val);
    val === "team"
      ? setSelectedTeam(selectedProject?.team_id)
      : setSelectedTeam(null);
  };

  const handleTagRemove = (updatedArr, childKey) => {
    if (childKey.includes("topic")) {
      setTags(updatedArr.map((el) => el.id));
    }
    if (childKey.includes("industri")) {
      setSelectedIndustries(updatedArr.map((el) => el.id));
    }
  };

  const handleTagSelect = (topicsId) => {
    setTags([...tags, topicsId]);
  };

  const handleIndustrySelect = (val) => {
    setSelectedIndustries((prev) => [...prev, val]);
  };

  const handleDiseasesSelect = (evt) => {
    const getIds = evt.map((elem) => elem.disease_id);
    setSelectedDiseases(getIds);
  };

  const handleSelectStatus = (val) => {
    // if (val?.val === selectedProject.status) {
      // return;
    // }

    setShowComponent(false);
    setStatus(val);

    if (["open", "at_risk", "off_track"].includes(val.val)) {
      setAutomatedStatus(true);
      setStatus(val);
    }

    if (val.val === "closed") {
      setAutomatedStatus(false);
      handleChangeEndDate(format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
    }

    if (val.val === "on_hold") {
      setAutomatedStatus(false);
      setStatus(val);
    }

    if (val.val !== "closed") {
      handleChangeEndDate("");
    }

    if (status?.val === "open" && val.val === "closed") {
      handleChangeEndDate(format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
    }

    const timeout = setTimeout(() => {
      setShowComponent(true);
      clearTimeout(timeout);
    });
  };

  const handleSubmit = async () => {
    const disease_ids = isPatientOrg ? selectedDiseases : [];

    if (!name?.length) {
      setError(name?.length === 0);
      return;
    }

    if (!team_id?.length && visibility === "team") {
      setErrorTeamId(!team_id || team_id?.length === 0);
      return;
    }
    if (!priority) {
      setPriorityError(true);
      return;
    }
    const payload = {
      ...fordDates,
      userId: session.id,
      projectId: selectedProject.id,
      councilId: selectedCouncil.id,
      name,
      description: goal,
      visibility,
      team_id: selectedTeam,
      industrys: [...new Set(selectedIndustries)],
      topics: isPatientOrg ? [] : [...new Set(tags)],
      disease_ids,
      members: [],
      companies: [],
      rating_template_id: selectedRatingsTemplate?.rating_template_id,
      status: status.val,
      country: country.val,
      startDate,
      endDate,
      project_members: tempMembers,
      enqueueSnackbar,
      automatic_status: automatedStatus,
      priority,
      next_steps: nextSteps,
      next_steps_created_at: new Date(),
      next_steps_created_by_id: session.id,
      cb: () => {
        popup.hide();
      },
    };

    await dispatch(projectPut(payload));
    await dispatch(projectGet({ id: selectedProject.id, enqueueSnackbar }));
  };

  useEffect(() => {
    if (session && selectedCouncil?.id) {
      if (selectedCouncil?.name === "GSK") {
        dispatch(
          patientOrganizationGetDiseases({
            filter: true,
            enqueueSnackbar,
          })
        );
      }

      dispatch(ratingsTemplateGetAttempt({ enqueueSnackbar }));

      dispatch(
        companyGetFilters({
          userId: session.id,
          councilId: selectedCouncil?.id,
          enqueueSnackbar,
        })
      );
      dispatch(
        companyGetTopics({
          userId: session.id,
          councilId: selectedCouncil?.id,
          enqueueSnackbar,
        })
      );
    }
  }, [session, selectedCouncil]);

  useEffect(() => {
    if (diseases) {
      const newDiseases = diseases.reduce((acc, elem) => {
        const child = elem.children_diseases.map((e) => ({
          disease_id: e.id,
          name: e.name,
        }));
        return [
          ...acc,
          {
            disease_id: elem.id,
            name: elem.name,
          },
          ...child,
        ];
      }, []);
      setFlattenDisease(newDiseases);
    }
  }, [diseases]);

  useEffect(() => {
    if (selectedProject?.team?.team_id) {
      setSelectedTeamOption([
        {
          label: selectedProject.team.name,
          value: selectedProject.team.team_id,
        },
      ]);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (ratingTemplates[0] && selectedProject) {
      setSelectedRatingsTemplate(
        ratingTemplates?.find(
          (el) => selectedProject?.rating_template_id === el.rating_template_id
        )
      );
    }
  }, [ratingTemplates, selectedProject]);

  useEffect(() => {
    if (userTeams.length > 0 && optionVisibility.length === 2) {
      setOptionVisibility([
        ...optionVisibility,
        {
          text: "Team (visible to your team)",
          value: "team",
        },
      ]);
    }
  }, [userTeams]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSelectCountry = (val) => {
    setCountry(val);
  };
  return (
    <div>
      <ModalBody>
        <div className={styles.formContainer}>
          <div className="row">
            <div className="col-12">
              <Label>Project name</Label>
              <textarea
                name="name"
                defaultValue={selectedProject?.name}
                placeholder=""
                className={classNames(styles.textAreaBox, styles.textBox)}
                onChange={(e) => {
                  handleChangeName(e.target.value);
                  setError(e?.target?.value?.length === 0);
                }}
              />
              {error && (
                <span className={styles.errorMsg}>Name is required </span>
              )}
            </div>
            <div className="col-12">
              <Label>{isFordSupplyChainAutomation ? 'Elevator Pitch' : 'Goal / Description'}</Label>
              <RichEditor
                initialValue={goal}
                updateValue={(e) => handleChangeGoal(e)}
              />
            </div>
            {nextSteps?.length ? (
              <div className="col-12">
                <Label>Next Steps</Label>
                <RichEditor
                  initialValue={nextSteps}
                  updateValue={(e) => setNextSteps(e)}
                />
              </div>
            ) : null}
            {selectedCouncil?.name === "Ford" && !isFordSupplyChainAutomation && isNot3DPrintingTemplate && (
              <div className="col-12 mb-3 mt-3">
                <div className="d-flex">
                  <div className="mr-1 w-50 flex-grow-1">
                    <Label>Prototype Date</Label>
                    <DatePiker
                      value={fordDates.prototype_date}
                      name="prototype_date"
                      formProps={{ values: fordDates }}
                      withClear
                      onChange={(e) => {
                        setFordDates({
                          ...fordDates,
                          prototype_date: format(new Date(e), "yyyy-MM-dd'T'HH:mm"),
                        });
                      }}
                      className={styles.textBox}
                    />
                  </div>
                  <div className="ml-1 w-50 flex-grow-1">
                    <Label>Implementation Date</Label>
                    <DatePiker
                      value={fordDates.implementation_date}
                      name="implementation_date"
                      formProps={{ values: fordDates }}
                      withClear
                      onChange={(e) => {
                        setFordDates({
                          ...fordDates,
                          implementation_date: format(new Date(e), "yyyy-MM-dd'T'HH:mm"),
                        });
                      }}
                      className={styles.textBox}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-end mt-3">
                  <div className="mr-1 w-50 flex-grow-1">
                    <Label>Expected Job 1 Date</Label>
                    <DatePiker
                      value={fordDates.expected_job_1_date}
                      name="expected_job_1_date"
                      formProps={{ values: fordDates }}
                      withClear
                      onChange={(e) => {
                        setFordDates({
                          ...fordDates,
                          expected_job_1_date: format(new Date(e), "yyyy-MM-dd'T'HH:mm"),
                        });
                      }}
                      className={styles.textBox}
                    />
                  </div>
                  <div className="ml-1 w-50 flex-grow-1">
                    <Label>Job 1 Date (Prototype)</Label>
                    <DatePiker
                      value={fordDates.prototype_job_1_date}
                      name="prototype_job_1_date"
                      formProps={{ values: fordDates }}
                      withClear
                      onChange={(e) => {
                        setFordDates({
                          ...fordDates,
                          prototype_job_1_date: format(new Date(e), "yyyy-MM-dd'T'HH:mm"),
                        });
                      }}
                      className={styles.textBox}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-end mt-3">
                  <div className="w-100">
                    <Label>Expected Job 1 Date (Proposal for Prototype)</Label>
                    <DatePiker
                      value={fordDates.proposal_for_prototype_expected_job_1_date}
                      name="proposal_for_prototype_expected_job_1_date"
                      formProps={{ values: fordDates }}
                      withClear
                      onChange={(e) => {
                        setFordDates({
                          ...fordDates,
                          proposal_for_prototype_expected_job_1_date: format(new Date(e), "yyyy-MM-dd'T'HH:mm"),
                        });
                      }}
                      className={styles.textBox}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="col-12">
              <Label>Priority</Label>
              <DropSelect
                SelecedValues={selectedProject && selectedProject.priority}
                onChange={(v) => setPriority(v.val)}
                placeholder="Priority"
                labelField="text"
                valueField="value"
                searchBy="text"
                options={priorityOptions}
                classNames={styles.visibilitySelect}
              />
              {priorityError && (
                <span className={styles.errorMsg}>Priority is required </span>
              )}
            </div>
            <div className="col-12">
              <Label>Access</Label>
              <DropSelect
                SelecedValues={selectedProject && selectedProject.visibility}
                onChange={handleVisibilityChange}
                placeholder="Access"
                labelField="text"
                valueField="value"
                searchBy="text"
                options={optionVisibility}
                classNames={styles.visibilitySelect}
              />
            </div>

            {visibility === "team" && (
              <div className="col-12">
                <Label>Select a team</Label>
                <Select
                  name="team_id"
                  onChange={([selected]) => {
                    setSelectedTeam(selected?.value);
                    setSelectedTeamOption([selected]);
                    handleChangeTeamId(selected?.value);
                    setErrorTeamId(selected?.value?.length === 0);
                  }}
                  values={selectedTeamOption}
                  options={userTeams.map(({ id: value, name: label }) => ({
                    label,
                    value,
                  }))}
                />
                {errorTeamId && (
                  <span className={styles.errorMsg}>
                    {" "}
                    Team selection is required{" "}
                  </span>
                )}
              </div>
            )}

            <div className="col-12">
              <Label>{isPatientOrg ? "Disease" : "Technologies"}</Label>
              {isPatientOrg && flattenDiseases && (
                <Select
                  multi
                  closeOnSelect
                  values={selectedProject.diseases}
                  className="mb-3"
                  onChange={handleDiseasesSelect}
                  options={flattenDiseases}
                  labelField="name"
                  valueField="disease_id"
                  placeholder="Add diseases"
                  searchBy="name"
                />
              )}
              {!isPatientOrg && topics?.length ? (
                <DropDownNestedElements
                  tags={topics}
                  selectedTags={tags}
                  childKey="children_topics"
                  tagKeyName="id"
                  parentKeyName="parent_resource_topic_id"
                  handleTagSelect={handleTagSelect}
                  label="Select technologies"
                  handleTagRemove={handleTagRemove}
                />
              ) : null}
            </div>

            <div className="col-12">
              <Label>Industries</Label>
              <DropDownNestedElements
                tags={industries}
                selectedTags={selectedIndustries}
                childKey="children_industries"
                tagKeyName="id"
                parentKeyName="parent_resource_industry_id"
                handleTagSelect={handleIndustrySelect}
                label="Select industries"
                handleTagRemove={handleTagRemove}
              />
            </div>

            {selectedProject?.stage_template?.patient_organization && (
              <div className="col-12">
                <Label>Country</Label>
                <DropSelect
                  SelecedValues={
                    selectedProject && selectedProject.country
                      ? gskOptionCountries.find(
                          (el) => selectedProject.country === el.name
                        )?.name
                      : null
                  }
                  onChange={handleSelectCountry}
                  labelField="name"
                  valueField="id"
                  options={gskOptionCountries.sort(sortCountries)}
                />
              </div>
            )}

            <div className="col-12">
              <Label>Status</Label>
              <DropSelect
                SelecedValues={
                  status?.val ||
                  (selectedProject && selectedProject.status
                    ? optionStatus.find(
                        (el) => selectedProject.status === el.value
                      )?.value
                    : null)
                }
                onChange={handleSelectStatus}
                labelField="text"
                valueField="value"
                options={
                  automatedStatus
                    ? optionStatus.filter(
                        (el) =>
                          el.value === selectedProject.status ||
                          el.value === "on_track" ||
                          el.value === "on_hold" ||
                          el.value === "closed"
                      )
                    : optionStatus
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex flex-grow-1 align-items-center justify-content-end mt-2">
              <span
                role="button"
                className="mr-2"
                onClick={() => setAutomatedStatus(false)}
              >
                Manual
              </span>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  onChange={() => setAutomatedStatus(!automatedStatus)}
                  checked={automatedStatus}
                />
                <span className={styles.slider} />
              </label>
              <span
                role="button"
                className="ml-2"
                onClick={() => setAutomatedStatus(true)}
              >
                Automated
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Label>Start date</Label>
              <DatePiker
                value={startDate}
                name="startDate"
                formProps={{ values: { startDate } }}
                withClear={false}
                onChange={(e) => {
                  handleChangeStartDate(e);
                }}
                className={styles.textBox}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {showComponent && (
                <>
                  <Label>End date</Label>
                  <DatePiker
                    value={endDate}
                    name="endDate"
                    formProps={{ values: { endDate } }}
                    // withClear
                    onChange={(e) => {
                      if (isBefore(new Date(e), new Date())) {
                        handleSelectStatus({
                          val: "closed",
                          obj: [
                            {
                              text: "Closed",
                              value: "closed",
                            },
                          ],
                        });
                      }
                      handleChangeEndDate(e);
                    }}
                    className={styles.textBox}
                  />
                  <span
                    className={styles.clearButton}
                    onClick={() => {
                      handleChangeEndDate("");
                      setStatus("open");
                      handleSelectStatus({
                        val: "open",
                        obj: [
                          {
                            text: "On Track",
                            value: "open",
                          },
                        ],
                      });
                    }}
                  >
                    clear
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button type="submit" btn={BtnType.REGULAR} onClick={handleSubmit}>
          Submit
        </Button>
      </ModalFooter>
    </div>
  );
};
export default React.memo(withSnackbar(ProjectDetailsEdit));

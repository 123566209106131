import React from "react";
import Select from "react-dropdown-select";
import {
  Label, TextBox, CheckBox, CheckType,
} from "../../../../../primitives";
import styles from "../styles.module.scss";
import { gskDiscussionTopics } from "../../activity.constant";
import RichEditor from "../../../../../../common/components/richEditor";

const NewOther = (props) => {
  const {
    orgType,
    data,
    formikprops,
    values,
    handleChange,
    setFieldValue,
    selectedCategories,
    handlePatientOrgDiscussionSelect,
    patientOrgDiscussionSubTopics,
    selectedSubCategories,
    handleSubcategoryTopicSelect,
  } = props;

  return (
    <>
      {orgType === "patient" && selectedCategories ? (
        <div>
          <Label>Discussion Topic</Label>
          <Select
            multi
            closeOnSelect
            values={[...selectedCategories]}
            className={styles.customPatientOrgMultiselect}
            onChange={(vals) => handlePatientOrgDiscussionSelect(vals, setFieldValue)}
            options={gskDiscussionTopics}
            labelField="name"
            valueField="name"
            placeholder="Select Discussion Topic Category"
          />
        </div>
      ) : null}
      {orgType === "patient"
      && patientOrgDiscussionSubTopics?.length
      && selectedSubCategories ? (
        <div>
          <Select
            multi
            closeOnSelect
            values={selectedSubCategories}
            name="discussion_topic"
            className={styles.customPatientOrgMultiselect}
            onChange={handleSubcategoryTopicSelect}
            options={patientOrgDiscussionSubTopics}
            labelField="name"
            valueField="name"
            placeholder="Select Discussion Topic"
            formProps={{ ...formikprops, values }}
          />
        </div>
        ) : null}
      {orgType === "patient"
      && selectedCategories
      && selectedCategories?.some((c) => c.name === "Other") ? (
        <TextBox
          type="text"
          name="discussion_topic_other"
          placeholder="Enter Discussion Topic"
          className={styles.textBox}
          formProps={{ ...formikprops, values }}
          onChange={handleChange}
        />
        ) : null}
      {orgType === "patient" && (
        <div className="mb-4">
          <Label>Summary</Label>
          <RichEditor
            updateValue={(val) => setFieldValue("note", val)}
            initialValue={values.note || ""}
            placeholder="Summary"
          />
        </div>
      )}
      {orgType === "patient" && (
        <div>
          <Label>Initial Engagement</Label>
          <CheckBox
            checkType={CheckType.BLUE}
            isChecked={data ? data.initial_introductory_meeting : false}
            onChange={(val) => {
              setFieldValue("initial_introductory_meeting", val);
            }}
          />
        </div>
      )}

      {orgType === "patient" && (
        <>
          <Label>Role of Patient Organization Representative(s)</Label>
          <TextBox
            type="text"
            name="roles_of_attendees"
            placeholder="Role of Patient Organization Representative(s)"
            className={styles.textBox}
            formProps={{ ...formikprops, values }}
            onChange={handleChange}
          />
        </>
      )}
    </>
  );
};

export default NewOther;

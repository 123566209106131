import React, { useEffect, useState, useRef } from "react";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import Icon from "common/icon/icon.component";
import { smSize } from "common/icon/icon.constants";
import { useSelector, useDispatch } from "react-redux";
import { setCustomFieldLoading } from "modules/companies/company/company.action";
import { setCustomFieldLoadingOrg } from "modules/patientOrganizations/patientOrganization/patientOrganization.action";
import { format } from "date-fns";
import Tooltip from "@mui/material/Tooltip";
import { isCreator } from "common/checkers/isCreator";
import { MoreOptions, Loading } from "modules/primitives";
import styles from "./styles.module.scss";
import { ACTIVITY_OPTIONS } from "../../../constants";
import { FIRMENICH_HOVER_TEXTS } from "./constant";
import classnames from "classnames";

const DropDownComponent = (props) => {
  const {
    item,
    handleEditClick,
    handleSubmitDetail,
    blockFunctionality = false,
    handleDeleteClick,
    hasHoverInDropdown,
  } = props;
  const [selected, setSelected] = useState([]);
  const [isDropOpened, setIsDropOpened] = useState(false);
  const [loadingId, setLoadingId] = useState(false);
  const [focused, setFocused] = useState(false);
  const [fieldId, setFieldId] = useState("");
  const inputRef = useRef("");
  const searchRef = useRef("");
  const dispatch = useDispatch();
  const {
    companiesReducer: {
      companyReducer: { customFieldLoadingId, selectedCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: {
        selectedPatientOrganization,
        customFieldLoadingIdOrg,
      },
    },
  } = useSelector((state) => state);

  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);

  useEffect(() => {
    if (item) {
      if (isCreator(item?.creator?.user_id)) {
        setHasAccessToEdit(true);
      }
    }
  }, [item]);

  useEffect(() => {
    if (item) {
      setSelected(item.option_selected || []);
    }
    return () => null;
  }, [customFieldLoadingId, item]);

  const handleMoreOptionClick = (value) => {
    if (hasHoverInDropdown) {
      return;
    }

    if (value === 0) {
      handleEditClick(item);
    }

    if (value === 1) {
      handleDeleteClick(item);
    }
  };

  useEffect(() => {
    if (customFieldLoadingId || customFieldLoadingIdOrg) {
      if (selectedPatientOrganization.id) {
        setLoadingId(customFieldLoadingIdOrg);
      } else {
        setLoadingId(customFieldLoadingId);
      }
    }
  }, [customFieldLoadingId, customFieldLoadingIdOrg]);

  useEffect(() => {
    if (selectedPatientOrganization.id) {
      setFieldId(item.patient_organization_custom_field_id);
    } else {
      setFieldId(item.company_custom_field);
    }
  }, [item]);

  const check = (option, mode) => {
    if (isDropOpened || mode === "remove") {
      const values = {
        field_value: item.field_value,
        title: item.field_name,
        type: item.field_type,
        option_selected: option.map((val) => val.value) || [],
        multiselect: item.multiselect,
        custom_field_group_id: item.custom_field_group_id,
        option_values: item.option_values,
      };

      const isUnique = option.filter(
        (o1) => !selected.some((o2) => o1.value === o2)
      );

      if (isUnique || mode === "remove") {
        if (selectedPatientOrganization.id) {
          dispatch(
            setCustomFieldLoadingOrg(item.patient_organization_custom_field_id)
          );
        } else {
          dispatch(setCustomFieldLoading(item.company_custom_field));
        }
        handleSubmitDetail(values, item);
      }
      const selectedValues = option.map((opt) => opt.value);
      setSelected([...selectedValues]);
    }
    // handleSubmitDetail(values, item);
  };

  const removeItem = (item) => {
    setSelected(selected.filter((option) => option !== item));
    const formattedItems = selected
      .filter((option) => option !== item)
      .map((item) => ({ text: item, value: item }));
    check(formattedItems, "remove");
  };

  const handleDropOpen = () => {
    setIsDropOpened(true);
    searchRef.current.focus();
  };

  const handleDropClose = () => {
    setIsDropOpened(false);
    clearSearch();
  };

  const handleSearch = (e) => {
    inputRef.current.setSearch(e);
    if (e.target.value) {
      setFocused(false);
    } else if (!focused) {
      setFocused(true);
    }
  };

  const clearSearch = () => {
    searchRef.current.value = "";
  };

  const itemRenderer = ({ item, methods }) => {
    return (
      <div
        className={classnames(
          methods.isSelected(item) ? styles.selectedItem : "",
          styles.customDropdownItem
        )}
        onClick={() => methods.addItem(item)}
      >
        <span>{item.value}</span>
        {hasHoverInDropdown ? (
          <Tooltip title={FIRMENICH_HOVER_TEXTS[item.value]} arrow>
            <div className={styles.tooltipElem}>?</div>
          </Tooltip>
        ) : null}
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{item?.field_name || "Dropdown"}</h5>
          {item?.field_placeholder?.length ? (
            <span className="text-muted small">{item.field_placeholder}</span>
          ) : null}
        </div>
        <div className={styles.rightWrp}>
          <div className={styles.colMore}>
            {(loadingId === fieldId &&
              !item.multiselect &&
              !blockFunctionality &&
              hasAccessToEdit) ||
            (loadingId !== fieldId &&
              !blockFunctionality &&
              hasAccessToEdit) ? (
              <MoreOptions
                options={ACTIVITY_OPTIONS}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            ) : null}
          </div>
        </div>
      </div>
      {item.from_claimed_profile && (
        <span className="d-block small font-weight-bold mb-3">
          Company Response
        </span>
      )}
      <div className={styles.container}>
        <div className={styles.dropWrp}>
          {!blockFunctionality ? (
            <Select
              // onDropdownClose={onDropClose}
              // onDropdownOpen={onDropOpen}
              closeOnSelect
              values={
                selected
                  ? selected.map((item) => ({ text: item, value: item }))
                  : []
              }
              contentRenderer={({ props, state }) => (
                <div className="position-relative">
                  <input
                    className={
                      selected &&
                      selected.length &&
                      !item.multiselect &&
                      !focused
                        ? styles.filledPlaceholder
                        : styles.placeholder
                    }
                    onChange={handleSearch}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    ref={searchRef}
                    placeholder={
                      item.multiselect || !selected.length
                        ? "Select"
                        : selected && selected.length && selected[0]
                    }
                  />
                  {hasHoverInDropdown &&
                  !item.multiselect &&
                  selected?.length &&
                  selected[0] ? (
                    <div className={styles.tooltipInInput}>
                      <Tooltip title={FIRMENICH_HOVER_TEXTS[selected[0]]} arrow>
                        <div className={styles.tooltipElem}>?</div>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
              )}
              options={
                item &&
                item.option_values.map((item) => ({ text: item, value: item }))
              }
              onChange={(status) => {
                check(status);
              }}
              itemRenderer={itemRenderer}
              onDropdownOpen={handleDropOpen}
              onDropdownClose={handleDropClose}
              ref={inputRef}
              className={styles.dropWrapper}
              placeholder={"" || item.field_placeholder}
              labelField="text"
              disabled={loadingId === fieldId}
              valueField="value"
              searchBy="text"
              searchable
              multi={item.multiselect}
            />
          ) : (
            <Tooltip
              title="You have View-Only access. To Edit fields, please ask your Admin to upgrade your account to Standard access."
              placement="top"
            >
              <span>No access</span>
            </Tooltip>
          )}
        </div>
        {item.multiselect && selected.length > 0 && (
          <div>
            {loadingId === fieldId ? (
              <Loading />
            ) : (
              <div className="d-flex flex-column">
                <span className={styles.selectionTitle}>SELECTION(S)</span>
                {selected.map((item) => (
                  <div className={styles.option}>
                    <div className={styles.textContainer}>
                      <span>{item}</span>
                      {hasHoverInDropdown ? (
                        <Tooltip title={FIRMENICH_HOVER_TEXTS[item]} arrow>
                          <div className={styles.questionMark}>?</div>
                        </Tooltip>
                      ) : null}
                    </div>
                    {!blockFunctionality && (
                      <Icon
                        {...smSize}
                        className={styles.deleteIcon}
                        icon="icn-button-delete"
                        onClick={() => removeItem(item)}
                      />
                    )}{" "}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      {item && item.creator ? (
        <div className={styles.infoWrp}>
          <div className={styles.dataRow}>
            <div className={styles.label}>
              {item.from_submission
                ? "Submitted by"
                : item.updated_at !== item.created_at
                ? "Updated by"
                : "Created by"}
            </div>
            <div className={styles.value}>
              {item.from_submission ? (
                <span>{item?.creator?.full_name || "-"}</span>
              ) : (
                <Link to={`/people/internal/profile/${item.user_id}`}>
                  {item && item.creator && item.creator.full_name}
                  {item.from_claimed_profile && (
                    <span>, {selectedCompany?.name}</span>
                  )}
                </Link>
              )}
            </div>
          </div>
          {item.from_claimed_profile && (
            <div className="d-flex align-items-center mr-5">
              <div className={styles.companyAccess}>
                <span>&#x2713; Access</span>
              </div>
            </div>
          )}
          <div className={styles.date}>
            {item.updated_at !== item.created_at
              ? format(new Date(item.updated_at), "eeee, MMM do, yyyy hh:mm a")
              : format(new Date(item.created_at), "eeee, MMM do, yyyy hh:mm a")}
          </div>
          {item.from_submission ? (
            <div
              className={`d-flex flex-grow-1 align-items-center justify-content-center ${styles.companyResponse}`}
            >
              Company Response
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
export default React.memo(DropDownComponent);

import {
  PEOPLE_LIST_MEMBER_NOTES_ATTEMPT,
  PEOPLE_LIST_MEMBER_NOTES_SUCCESS,
  PEOPLE_ADD_MEMBER_NOTE_SUCCESS,
  PEOPLE_DELETE_MEMBER_NOTE_SUCCESS,
  PEOPLE_EDIT_MEMBER_NOTE_SUCCESS,
} from "modules/people/Profile/profile.action";
import { apiStatus, pageMeta } from "../../../common/httpCall";
import { GET_PLANT_BY_TYPE_SUCCESS } from "../../../common/actions/common.action";
const initState = {
  memberNotes: [],
  pageMeta: {},
  selectedMember: null,
  selectedMemberPlantsAreas: { areas: [], plant: {} },
};

const peopleProfileReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PEOPLE_LIST_MEMBER_NOTES_ATTEMPT: {
      const { currentPage } = payload;
      if (currentPage === 1) {
        return {
          ...state,
          memberNotes: [],
          pageMeta: {},
        };
      }
      break;
    }
    case PEOPLE_LIST_MEMBER_NOTES_SUCCESS: {
      const pagemeta = pageMeta(payload);
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          memberNotes: response,
          pageMeta: pagemeta,
        };
      }
      break;
    }

    case PEOPLE_ADD_MEMBER_NOTE_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.CREATED) {
          return {
            ...state,
            memberNotes: [...state.memberNotes, response],
          };
        }
      }
      break;
    case PEOPLE_DELETE_MEMBER_NOTE_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            memberNotes: state.memberNotes.filter(
              (note) => note.custom_activity_id !== response.id
            ),
          };
        }
      }
      break;
    case PEOPLE_EDIT_MEMBER_NOTE_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            memberNotes: state.memberNotes.map((note) => {
              if (note.custom_activity_id === response.custom_activity_id) {
                return response;
              }
              return note;
            }),
          };
        }
      }
      break;
    case GET_PLANT_BY_TYPE_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            selectedMemberPlantsAreas: response,
          };
        }
      }
      break;
    default:
      return {
        ...state,
      };
  }
  return state;
};

export default peopleProfileReducer;

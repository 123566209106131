import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FieldArray, Formik } from "formik";
import classNames from "classnames";
import {
  BtnType,
  Button,
  CheckBox,
  CheckType,
  Label,
  TextBox,
} from "modules/primitives";
import { arrayElementsDuplicateValidation } from "common/helper";
import styles from "./styles.module.scss";
import { model, validation } from "./constant";
import { Icon, smSize } from "../../../../../common/icon";
import UniqName from "../../../../../common/popups/newItem/uniqName/uniqName";
import checkDuplicateName from "../../../../../common/checkDuplicateName";
import checkUniqName from "../../../../../common/checkUniqName";
import findCurrentCustomFieldGroup from "../../../../../common/findCustomFieldGroup";

const NewCheckListComponent = (props) => {
  const {
    formRef,
    handleOnSubmit,
    data,
    mode,
    disabled,
    uniqName,
    errorValidation = false,
    fromAdmin = false,
    rfiInstance,
    adminLocation,
    indexOfCfGroup,
    selectedCustomFieldGroup,
  } = props;
  const [error, setError] = useState("");
  const [initValues, setInitValues] = useState({});
  const [titleError, setTitleError] = useState("");
  const [multiselect, setMultiSelect] = useState(
    mode === "edit" ? data?.multiselect : model?.multiselect
  );
  const [display_as, setDisplayMode] = useState(
    mode === "edit" ? data?.display_as : model?.display_as
  );

  const [deletedOptions, setDeletedOptions] = useState([]);

  const {
    companiesReducer: {
      companyReducer: { newCustomFieldGroups },
    },
    adminCustomFieldsReducer: { customFieldGroups },
  } = useSelector((state) => state);

  useEffect(() => {
    if (data) {
      setInitValues({
        field_type: data.field_type,
        field_value: data.field_value,
        title: data.field_name,
        options: data.option_values,
        option_selected: data.option_selected,
        multiselect: data.multiselect,
        field_placeholder: data.field_placeholder || "",
        display_as: data.display_as,
      });
    }
  }, [data]);

  const onSubmit = async (values, resetForm) => {
    const cfGroup =
      findCurrentCustomFieldGroup(
        selectedCustomFieldGroup,
        customFieldGroups,
        newCustomFieldGroups,
        indexOfCfGroup,
        data
      ) || [];

    if (!values.title.length) {
      const field_name = checkDuplicateName(cfGroup, "checklist");
      values.title = field_name;
    } else if (
      !checkUniqName(values.title, cfGroup) &&
      values.title?.trim()?.toLowerCase() !==
        data?.field_name?.trim()?.toLowerCase()
    ) {
      setTitleError("Field name must be unique. Please rename this field");
      return;
    }
    setTitleError("");
    values.multiselect = multiselect;
    values.display_as = display_as;
    const filteredSelected = values?.option_selected
      ?.map((selectedOption) => {
        const foundedOption = deletedOptions.find(
          (option) => option === selectedOption
        );
        if (!foundedOption) {
          return selectedOption;
        }
      })
      .filter((e) => e !== undefined);

    values.options = values.options.filter((option) => option !== "");
    values.option_selected = filteredSelected;

    if (!values?.options?.length) {
      setError("Required at least one option");
      return;
    }

    if (arrayElementsDuplicateValidation(values.options, [...values.options])) {
      setError("");
      if (values.options[0]) {
        handleOnSubmit(values);
        resetForm();
      }
      values.title = "";
      values.field_name = "";
    } else {
      setError("Two or more checklist options cant have same name.");
    }
  };

  const handleMultiCheckClick = (value, values) => {
    if (!disabled) {
      values.multiselect = value;
    }
    setMultiSelect(value);
  };

  const handleSetDisplayModeClick = (value, values) => {
    const val = value ? "grid" : "list";
    if (!disabled) {
      values.display_as = val;
    }
    setDisplayMode(val);
  };

  useEffect(() => {
    if (mode !== "edit") {
      model.title = "";
    }
  }, [mode]);
  return (
    <div className={styles.wrapper}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={mode === "edit" || disabled ? initValues : model}
        validationSchema={validation && validation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          onSubmit(values, resetForm);
        }}
      >
        {({ handleSubmit, values, setFieldValue, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <Label>Title</Label>
            <TextBox
              disabled={rfiInstance || disabled}
              type="text"
              name="title"
              placeholder="Checklist Title"
              formProps={{ ...formikprops, values, setFieldValue }}
              className={styles.textBoxTitle}
            />
            <UniqName uniqName={uniqName} />

            {errorValidation && (
              <div className={styles.errorBox}>
                <sup>*</sup>field is required
              </div>
            )}
            {titleError.length > 1 && (
              <div className={styles.errorBox}>
                <sup>*</sup>
                {titleError}
              </div>
            )}
            {!adminLocation && (
              <div className="d-flex flex-column align-items-start">
                <Label>Instructions (Optional)</Label>
                <TextBox
                  type="text"
                  name="field_placeholder"
                  placeholder="Add instructions"
                  formProps={{ ...formikprops, values, setFieldValue }}
                  className={styles.textBoxTitle}
                />
              </div>
            )}
            <div
              className={classNames(styles.formElement, styles.multiSelectWrp)}
            >
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={values.display_as === "grid"}
                onChange={(value) => handleSetDisplayModeClick(true, values)}
                disabled={disabled}
                className={disabled && styles.disabledBox}
              />
              <span className={disabled && styles.disabledBox}>
                <Label>Display as Grid</Label>
              </span>
            </div>
            <div
              className={classNames(styles.formElement, styles.multiSelectWrp)}
            >
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={values.display_as === "list"}
                onChange={(value) => handleSetDisplayModeClick(false, values)}
                disabled={disabled}
                className={disabled && styles.disabledBox}
              />
              <span className={disabled && styles.disabledBox}>
                <Label>Display as List</Label>
              </span>
            </div>
            <div
              className={classNames(styles.formElement, styles.multiSelectWrp)}
            >
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={values.multiselect}
                onChange={(value) => handleMultiCheckClick(value, values)}
                disabled={disabled}
                className={disabled && styles.disabledBox}
              />
              <span className={disabled && styles.disabledBox}>
                <Label>Allow Multiselect</Label>
              </span>
            </div>
            <Label>Checklist Options</Label>
            <FieldArray name="options">
              {({ remove, push }) => (
                <div>
                  {values?.options?.map((item, index) => (
                    <div
                      className={styles.inputContainer}
                      key={`options.${index}`}
                    >
                      <TextBox
                        formProps={{ ...formikprops, values, setFieldValue }}
                        key={index}
                        type="text"
                        name={`options.${index}`}
                        value={item}
                        placeholder="Option"
                        className={styles.textBox}
                        disabled={disabled}
                      />
                      <div
                        onClick={() => {
                          if (!disabled) {
                            setDeletedOptions((prevState) => [
                              ...prevState,
                              item,
                            ]);
                            return remove(index);
                          }
                        }}
                        className={styles.deleteButton}
                      >
                        {mode === "edit" && (
                          <Icon
                            {...smSize}
                            icon="icn-button-delete"
                            className={disabled ? styles.noIcon : {}}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                  <div className={styles.formElement}>
                    <Button
                      btn={disabled ? BtnType.DISABLED : BtnType.FRAME_LESS}
                      disabled={disabled}
                      onClick={(e) => {
                        e.preventDefault();
                        return push("");
                      }}
                    >
                      Add another item
                    </Button>
                  </div>
                </div>
              )}
            </FieldArray>
          </form>
        )}
      </Formik>
      <div>
        <span
          style={{
            color: "red",
          }}
        >
          {error}
        </span>
      </div>
    </div>
  );
};

export default React.memo(NewCheckListComponent);

import React from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import { Icon, smSize } from "../../../../../../common/icon";
import styles from "modules/ideasWrapper/ideaProfileWrapper/ideaProfileOverview/companies/companies.module.scss";
import { updateProductDetails } from "modules/companies/product/store/product.actions";
import { themeProductDeleteAttempt } from "modules/themes/themesIndividualPage/themesOverviewTab/overviewTab.action";

const ProductRelatedThemes = ({ product, canEdit, selectedCouncil }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteTheme = (theme) => {
    dispatch(
      themeProductDeleteAttempt({
        themeId: theme.id,
        productId: product.id,
        enqueueSnackbar,
        instance: "product",
        cb: () => {
          enqueueSnackbar("Successfully deleted theme", {
            variant: "success",
          });
          dispatch(
            updateProductDetails({
              themes: product.themes.filter((t) => t.id !== theme.id),
            })
          );
        },
      })
    );
  };

  const handleThemeRedirect = (theme) => {
    history.push(`/themes/individual_page/${theme.id}/overview`);
  };

  return (
    <div className="p-4">
      {product.themes?.length ? (
        <div>
          {product.themes.map((theme) => (
            <div
              key={theme.id}
              className="mb-2 d-flex align-items-center justify-content-between"
            >
              <span
                className="cursor-pointer"
                onClick={() => handleThemeRedirect(theme)}
              >
                {theme.name}
              </span>
              {canEdit && (
                <Icon
                  {...smSize}
                  icon="icn-button-delete"
                  className={styles.deleteIcon}
                  onClick={() => handleDeleteTheme(theme)}
                />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="d-flex align-items-center">
          No related{" "}
          {selectedCouncil?.settings?.themes_display_name || "Themes"} yet.
        </div>
      )}
    </div>
  );
};

export default React.memo(ProductRelatedThemes);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import { useModelPopup } from "../../../../../common/hooks";
import {
  Button,
  BtnType,
  CompanyAvatar,
  Loading,
} from "../../../../primitives";
import ConfirmDeleteComponent from "../../../../../common/components/confirmDelete/index";
import styles from "./styles.module.scss";
import MixedAddCompanyOrProducts from "../../../../../common/components/mixedSearch/mixedAddCompanyOrProducts";
import { themesCompanyAddAttempt } from "modules/themes/themesIndividualPage/themesOverviewTab/overviewTab.action";

const EditCompanies = () => {
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const history = useHistory();

  const {
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { currentThemeData },
      themesOverviewReducer: { companyList, loadingCompany },
    },
  } = useSelector((state) => state);

  const handleAddCallback = (selectedCompanies) => {
    const data = {
      theme_company: selectedCompanies.map((comp) => {
        return {
          company_id: comp.id,
          // "position": 1
        };
      }),
    };

    dispatch(
      themesCompanyAddAttempt({
        themeId: currentThemeData.id,
        data,
        activeLayout: "", // in prev modal this prop was undefined, not sure we need it here
        enqueueSnackbar,
        cb: () => {
          popup.hide();
        },
      })
    );
  };

  const handleAddCompanyClick = () => {
    popup.show({
      title: `Add Company to ${
        selectedCouncil?.settings?.themes_display_name_singular || "Themes"
      }`,
      component: (
        <MixedAddCompanyOrProducts
          handleSave={handleAddCallback}
          placeholder="Type to search or select from below"
          label="Companies or Products"
          existingItems={companies}
        />
      ),
    });
  };

  const handleDeleteCompany = (id) => {
    popup.show({
      title: "Confirmation Delete",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={currentThemeData.id}
          templateId={id}
          enqueueSnackbar={enqueueSnackbar}
          councilId={selectedCouncil?.id}
          actionType="deleteThemeCompany"
          customData={[
            selectedCouncil?.settings?.themes_display_name_singular || "Theme",
          ]}
        />
      ),
    });
  };

  useEffect(() => {
    if (companyList.length) {
      setCompanies(companyList);
    } else {
      setCompanies(currentThemeData.companies);
    }
  }, [companyList]);
  const handleCompanyClick = (el) => {
    localStorage.setItem("company", JSON.stringify(el));
    if (el.gsk_patient_organization) {
      history.push(
        `/patient_organizations/${
          el.gsk_patient_organization?.slug || el.gsk_patient_organization?.id
        }`
      );
    } else {
      history.push(`/companies/${el?.slug || el?.id}`);
    }
  };
  if (loadingCompany) {
    return <Loading />;
  }
  return (
    <div className={styles.membersWrp}>
      <div className={styles.formContainer}>
        <div className="row">
          <div className="col-12">
            <div className={styles.labelesContainer}>
              <p className={styles.label}>
                {selectedCouncil?.settings?.themes_display_name_singular ||
                  "Theme"}{" "}
                Companies
              </p>
              <p className={styles.label}> </p>
            </div>
            <div className={styles.companyListWrp}>
              {companies &&
                companies.map((company) => (
                  <div className={styles.companyItem} key={company?.id}>
                    <div
                      className={styles.companydataWrp}
                      onClick={() => handleCompanyClick(company)}
                    >
                      <div className={styles.companyImageWrp}>
                        <CompanyAvatar
                          imgSrc={company.logo}
                          name={company.name}
                        />
                      </div>
                      <div className={styles.companyNameWrp}>
                        {company.name}
                      </div>
                    </div>
                    {}
                    <span
                      className={styles.companyDelete}
                      onClick={() => handleDeleteCompany(company.company_id)}
                    >
                      REMOVE
                    </span>
                  </div>
                ))}
            </div>

            <div className={styles.addMemberButtonContainer}>
              <Button
                icon="icn-add"
                onClick={() => handleAddCompanyClick({})}
                btn={BtnType.FRAME_LESS}
                className={styles.addCompanyButton}
              >
                Add A company
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditCompanies;

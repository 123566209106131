import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import classNames from "classnames";
import styles from "./patientOrgTopDetails.module.scss";
import { CompanyAvatar } from "../../../../primitives";
import { patientOrganizationEdit } from "../../patientOrganization.action";
import { useWindowSize } from "../../../../../common/hooks/useWindowSize";
import PatientOrgTopActions from "../patientOrgTopActions/patientOrgTopActions.component";

const relationshipOptions = [
  { name: "No Relationship", id: "no_relationship" },
  { name: "Existing Relationship", id: "existing_relationship" },
];

const PatientOrgTopDetails = ({ blockFunctionality, isAdmin }) => {
  const {
    patientOrganizationsReducer: {
      patientOrganizationReducer: { selectedPatientOrganization },
    },
  } = useSelector((state) => state);
  const { isMobile } = useWindowSize();
  const [relationship, setRelationship] = useState(null);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setRelationship(selectedPatientOrganization.relationship_stage);
  }, [selectedPatientOrganization]);

  const handleDropdownSelect = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setRelationship(e.target.value);
    const data = {
      relationship_stage: e.target.value,
    };
    dispatch(
      patientOrganizationEdit({
        patient_organization_id: selectedPatientOrganization.id,
        data,
        enqueueSnackbar,
      }),
    );
  };

  return (
    <div className={styles.patientOrgTopDetails}>
      <div className={styles.logoWrp}>
        <div className={styles.logoCon}>
          <CompanyAvatar
            className={styles.logo}
            imgSrc={selectedPatientOrganization.logo}
            name={selectedPatientOrganization.name}
            hasBorderRadius
          />
        </div>
      </div>
      <div
        className={classNames(
          styles.detailsWrapper,
          isMobile ? styles.mobileDetailsWrapper : "",
        )}
      >
        <div
          className={`d-flex ${
            isMobile ? "flex-column" : "align-items-center"
          }`}
        >
          <span className={styles.companyName}>
            {selectedPatientOrganization.name}
          </span>
          {relationship && relationship !== "" ? (
            <Select
              value={relationship || "none"}
              onChange={handleDropdownSelect}
              disabled={blockFunctionality}
              className={classNames(
                styles.detailsRelationship,
                styles[relationship || "no_relationship"],
              )}
              disableUnderline
            >
              {relationshipOptions.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <div className={styles.tractionPlaceholder}>Traction Match</div>
          )}
        </div>
      </div>
      {isMobile && (
        <PatientOrgTopActions
          isAdmin={isAdmin}
          blockFunctionality={blockFunctionality}
        />
      )}
    </div>
  );
};

export default React.memo(PatientOrgTopDetails);

import React, { useState, useEffect } from "react";

import { withSnackbar } from "notistack";
import Select from "react-dropdown-select";

import styles from "./styles.module.scss";

const DropDownNestedElements = ({
  tags = [],
  childKey,
  tagKeyName,
  handleTagSelect,
  label,
  selectedTags = [],
  handleTagRemove = () => {},
  disabled,
  style = {},
  selectedIsObject = false,
  useCustomOptionRenderer,
}) => {
  const [mappedTags, setMappedTags] = useState([]);

  function growUpParentsChildrenTopics(nested = [], level) {
    const flat = [];
    for (let i = 0; i < nested.length; i++) {
      if (nested[i][childKey].length && level >= 2) {
        flat.push(nested[i]);
        flat.push(
          ...growUpParentsChildrenTopics(
            nested[i][childKey].map((tag) => ({
              ...tag,
              id: tag[tagKeyName],
              level,
            })),
            level - 1
          )
        );
      } else {
        flat.push(nested[i]);
      }
    }

    return flat;
  }

  const handleTagChange = (val) => {
    if (selectedIsObject) {
      handleTagSelect([val]);
    } else {
      handleTagSelect(val[tagKeyName]);
    }
  };

  useEffect(() => {
    if (tags.length) {
      const newTags = growUpParentsChildrenTopics(tags, 3);
      setMappedTags(newTags);
    }
  }, [tags]);

  const customOptionRenderer = ({ item }) => {
    return (
      <span
        className={styles.customOption}
        onClick={() => handleTagRemove(item[tagKeyName])}
      >
        <span className={styles.customOptionLabel}>{item.name}</span>
        <span className={styles.customOptionIcon}>×</span>
      </span>
    );
  };

  return (
    <div className={`${styles.container}`}>
      <Select
        style={style}
        disabled={disabled}
        values={
          mappedTags.filter((tag) => selectedTags.includes(tag[tagKeyName])) ||
          []
        }
        onChange={(modifiedArr) => {
          if (useCustomOptionRenderer) {
            return undefined;
          }
          return handleTagRemove(modifiedArr, childKey);
        }}
        placeholder={label}
        labelField="name"
        valueField={tagKeyName}
        searchBy="name"
        options={mappedTags}
        optionRenderer={
          useCustomOptionRenderer ? customOptionRenderer : undefined
        }
        multi
        itemRenderer={({ item }) => {
          const alreadyAdded = selectedTags.includes(item[tagKeyName]);
          if (item._destoy) {
            return null;
          }

          return (
            <div
              key={item[tagKeyName]}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (alreadyAdded) {
                  return;
                }
                handleTagChange(item);
                // setDropdownHandle(false);
              }}
              className={styles.tagNameContainer}
              style={{
                color: alreadyAdded ? "grey" : "black",
                cursor: alreadyAdded ? "not-allowed" : "pointer",
                marginLeft: `${(30 / (item.level ? item.level : 30)) * 3.5}px`,
              }}
            >
              {!item.level && <h4>&#x2022;</h4>}
              {item.level && <div className={styles.childTagNameIcon} />}
              <span className={styles.parentTagNameModal}>{item.name}</span>
            </div>
          );
        }}
      />
    </div>
  );
};

export default React.memo(withSnackbar(DropDownNestedElements));

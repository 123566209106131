import {
  ACTIVATE_FIELD_TOGGLE_SUCCESS,
  ADD_FIELD_TO_GROUP_SUCCESS,
  CATCH_ERROR_SUCCESS,
  DELETE_FIELD_TOGGLE_SUCCESS,
  DELETE_PROJECT_FIELD_SUCCESS,
  DELETE_PROJECT_GROUP_FIELD_SUCCESS,
  DELETE_PROJECT_GROUP_SUCCESS,
  DELETE_STAGE_BY_ID_ATTEMPT,
  DELETE_STAGE_BY_ID_SUCCESS,
  DELETE_STAGE_TEMPLATE_BY_ID_ATTEMPT,
  DELETE_STAGE_TEMPLATE_BY_ID_SUCCCESS,
  DELETE_TASK_ATTEMPT,
  DELETE_TASK_SUCCESS,
  DELETE_WIZARD_STEP_SUCCESS,
  GET_GROUP_FIELDS_SUCCESS,
  GET_PROJECT_WIZARD_ATTEMPT,
  GET_PROJECT_WIZARD_ATTEMPT_SUCCESS,
  GET_PROJECTS_FIELDS_ATTEMPT,
  GET_PROJECTS_FIELDS_ATTEMPT_SUCCESS,
  GET_PROJECTS_GROUPS_ATTEMPT_SUCCESS,
  GET_PROJECTS_STAGE_FIELDS_ATTEMPT,
  GET_PROJECTS_STAGE_FIELDS_ATTEMPT_SUCCESS,
  GET_STAGE_ATTEMPT,
  GET_STAGE_SUCCESS,
  GET_STAGE_TEMPLATE_BY_ID_ATTEMPT,
  GET_STAGE_TEMPLATE_BY_ID_SUCCESS,
  GET_STAGES_TEMPLATE_ATTEMPT,
  GET_STAGES_TEMPLATE_SUCCESS,
  PATCH_STAGE_BY_ID_ATTEMPT,
  PATCH_STAGE_BY_ID_SUCCESS,
  PATCH_STAGE_TEMPLATE_BY_ID_ATTEMPT,
  PATCH_STAGE_TEMPLATE_BY_ID_SUCCESS,
  PATCH_TASK_ATTEMPT,
  PATCH_TASK_DOC_NAME_ATTEMPT,
  PATCH_TASK_DOC_NAME_SUCCESS,
  PATCH_TASK_SUCCESS,
  POST_STAGE_ATTEMPT,
  POST_STAGE_SUCCESS,
  POST_STAGE_TEMPLATE_ATTEMPT,
  POST_STAGE_TEMPLATE_SUCCESS,
  POST_TASK_ATTEMPT,
  POST_TASK_DOCUMENT_ATTEMPT,
  POST_TASK_DOCUMENT_SUCCESS,
  POST_TASK_SUCCESS,
  PUT_STAGE_BY_ID_ATTEMPT,
  SUBMIT_PROJECT_FIELD,
  SUBMIT_PROJECT_FIELD_SUCCESS,
  SUBMIT_PROJECT_GROUP_SUCCESS,
  UPDATE_GROUP_FIELD_DATA_ADMIN,
  UPDATE_PROJECT_FIELD_SUCCESS,
  UPDATE_PROJECT_GROUP_SUCCESS,
  UPDATE_PROJECT_WIZARD_STEPS,
  UPDATE_PROJECT_FIELD,
} from "./adminProjects.actions";
import { pageMeta } from "../../../common/httpCall";

const initState = {
  stageTemplates: [],
  stageTemplate: null,
  stages: [],
  tasks: [],
  projectsFields: {
    loading: false,
    fields: [],
    projectsFieldsPages: {},
  },
  projectsStageFields: {
    loading: false,
    fields: [],
    projectsStagePages: 1,
  },
  loading: true,
  shouldReloadFields: false,
  shouldReloadTemplate: false,
  shouldReloadTemplates: false,
  shouldReloadTasks: false,
  projectGroups: {
    loading: false,
    groups: [],
    totalPages: 1,
  },
  projectWizards: {
    loading: false,
    data: [],
    totalPages: 1,
  },
  selectedGroup: null,
};

const adminProjectsReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PROJECT_WIZARD_STEPS: {
      return {
        ...state,
        projectWizards: {
          ...state.projectWizards,
          data: state.projectWizards.data.map((wizard) => {
            if (wizard.id === payload.wizardId) {
              return {
                ...wizard,
                projects_wizard_steps: payload.addNewStep
                  ? [...wizard.projects_wizard_steps, payload.response]
                  : wizard.projects_wizard_steps.map((step) => {
                      if (step.id === payload.response.id) {
                        return {
                          ...step,
                          ...payload.response,
                        };
                      }

                      return step;
                    }),
              };
            }

            return wizard;
          }),
        },
      };
    }

    case DELETE_WIZARD_STEP_SUCCESS: {
      return {
        ...state,
        projectWizards: {
          ...state.projectWizards,
          data: state.projectWizards.data.map((wizard) => {
            if (wizard.id === payload.wizardId) {
              return {
                ...wizard,
                projects_wizard_steps: wizard.projects_wizard_steps.filter(
                  (step) => step.id !== payload.id
                ),
              };
            }

            return wizard;
          }),
        },
      };
    }

    case DELETE_PROJECT_GROUP_FIELD_SUCCESS: {
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          fields: state.selectedGroup.fields.filter(
            (field) => field.id !== payload.fieldId
          ),
        },
      };
    }

    case ADD_FIELD_TO_GROUP_SUCCESS: {
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          fields: [...(state.selectedGroup.fields || []), payload.response],
        },
      };
    }

    case GET_GROUP_FIELDS_SUCCESS: {
      const pageMetaObj = pageMeta(payload);

      return {
        ...state,
        selectedGroup: {
          loading: false,
          fields: payload.response || [],
          totalPages: pageMetaObj.pages,
        },
      };
    }

    case UPDATE_GROUP_FIELD_DATA_ADMIN: {
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          fields: payload.response || state.selectedGroup.fields,
        },
      };
    }

    case DELETE_PROJECT_GROUP_SUCCESS: {
      return {
        ...state,
        projectGroups: {
          ...state.projectGroups,
          groups: state.projectGroups.groups.filter(
            (group) => group.id !== payload
          ),
        },
      };
    }

    case UPDATE_PROJECT_GROUP_SUCCESS: {
      return {
        ...state,
        projectGroups: {
          ...state.projectGroups,
          groups: state.projectGroups.groups.map((group) => {
            if (group.id === payload.response.id) {
              return {
                ...group,
                ...payload.response,
              };
            }

            return group;
          }),
        },
      };
    }

    case SUBMIT_PROJECT_GROUP_SUCCESS: {
      return {
        ...state,
        projectGroups: {
          ...state.projectGroups,
          groups: [payload.response, ...state.projectGroups.groups],
        },
      };
    }

    case GET_PROJECT_WIZARD_ATTEMPT: {
      return {
        ...state,
        projectWizards: {
          ...state.projectWizards,
          loading: true,
        },
      };
    }

    case GET_PROJECT_WIZARD_ATTEMPT_SUCCESS: {
      const pageMetaObj = pageMeta(payload);

      return {
        ...state,
        projectWizards: {
          loading: false,
          data: payload?.response.map((el, index) => ({ ...el, index })) || [],
          totalPages: pageMetaObj.pages,
        },
      };
    }

    case GET_PROJECTS_GROUPS_ATTEMPT_SUCCESS: {
      const pageMetaObj = pageMeta(payload);

      return {
        ...state,
        projectGroups: {
          loading: false,
          groups: payload.response || [],
          totalPages: pageMetaObj.pages,
        },
      };
    }

    case DELETE_FIELD_TOGGLE_SUCCESS: {
      return {
        ...state,
        projectsStageFields: {
          ...state.projectsStageFields,
          fields: state.projectsStageFields.fields.map((f) => {
            if (f.id === payload.id) {
              return {
                ...f,
                active: false,
              };
            }

            return f;
          }),
        },
      };
    }

    case ACTIVATE_FIELD_TOGGLE_SUCCESS: {
      return {
        ...state,
        projectsStageFields: {
          ...state.projectsStageFields,
          fields: state.projectsStageFields.fields.map((f) => {
            if (f.id === payload.id) {
              return {
                ...f,
                active: true,
              };
            }

            return f;
          }),
        },
      };
    }

    case GET_PROJECTS_STAGE_FIELDS_ATTEMPT: {
      return {
        ...state,
        projectsStageFields: {
          ...state.projectsStageFields,
          loading: true,
        },
      };
    }

    case GET_PROJECTS_STAGE_FIELDS_ATTEMPT_SUCCESS: {
      const pageMetaObj = pageMeta(payload);

      return {
        ...state,
        projectsStageFields: {
          loading: false,
          fields: payload.response?.project_field_templates || [],
          projectsStagePages: pageMetaObj.pages,
        },
      };
    }

    case UPDATE_PROJECT_FIELD: {
      return {
        ...state,
        shouldReloadFields: false,
      };
    }
    case UPDATE_PROJECT_FIELD_SUCCESS: {
      return {
        ...state,
        shouldReloadFields: true,
        projectsFields: {
          ...state.projectsFields,
          // fields: payload.response,
        },
      };
    }

    case DELETE_PROJECT_FIELD_SUCCESS: {
      return {
        ...state,
        projectsFields: {
          ...state.projectsFields,
          fields: state.projectsFields?.fields.filter(
            (item) => item.id !== payload.id
          ),
        },
      };
    }
    case SUBMIT_PROJECT_FIELD: {
      return {
        ...state,
        shouldReloadFields: false,
      };
    }

    case SUBMIT_PROJECT_FIELD_SUCCESS: {
      if (payload?.stageID?.length) {
        return {
          ...state,
          shouldReloadFields: true,
          projectsStageFields: {
            fields: payload.response.project_field_templates || [],
          },
        };
      }

      return {
        ...state,
        shouldReloadFields: true,

        projectsFields: {
          ...state.projectsFields,
          fields: payload.response || [],
        },
      };
    }

    case GET_PROJECTS_FIELDS_ATTEMPT: {
      return {
        ...state,
        projectsFields: {
          loading: true,
          fields: [],
        },
      };
    }

    case GET_PROJECTS_FIELDS_ATTEMPT_SUCCESS: {
      const pageMetaObj = pageMeta(payload);
      return {
        ...state,
        projectsFields: {
          loading: false,
          fields: payload.response,
          projectsFieldsPages: pageMetaObj,
        },
      };
    }

    case GET_STAGES_TEMPLATE_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_STAGES_TEMPLATE_SUCCESS: {
      return {
        ...state,
        stageTemplates: payload.response,
        loading: false,
      };
    }
    case DELETE_STAGE_TEMPLATE_BY_ID_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_STAGE_TEMPLATE_BY_ID_SUCCCESS: {
      const filteredTemplates = state.stageTemplates.filter(
        (template) => payload.id !== template.id
      );
      return {
        ...state,
        stageTemplates: filteredTemplates,
        loading: false,
      };
    }
    case POST_STAGE_TEMPLATE_ATTEMPT: {
      return {
        ...state,
        loading: true,
        shouldReloadTemplates: false,
      };
    }
    case POST_STAGE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        stageTemplates: [...state.stageTemplates, payload],
        loading: false,
        shouldReloadTemplates: true,
      };
    }
    case GET_STAGE_TEMPLATE_BY_ID_ATTEMPT: {
      return {
        ...state,
        loaging: true,
        // shouldReloadTemplate: true
      };
    }
    case GET_STAGE_TEMPLATE_BY_ID_SUCCESS: {
      return {
        ...state,
        stageTemplate: payload,
        tasks: payload.tasks,
        loading: false,
        shouldReloadTemplate: false,
      };
    }
    case PATCH_STAGE_TEMPLATE_BY_ID_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case PATCH_STAGE_TEMPLATE_BY_ID_SUCCESS: {
      const newTemplates = state.stageTemplates.map((template) => {
        if (template.id === payload.id) {
          return {
            ...template,
            ...payload,
          };
        }
        return {
          ...template,
          default_template: false,
          default_patient_organization: false,
        };
      });

      return {
        ...state,
        stageTemplates: newTemplates,
        stageTemplate: payload,
        loading: false,
      };
    }
    case PUT_STAGE_BY_ID_ATTEMPT: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_STAGE_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_STAGE_SUCCESS: {
      return {
        ...state,
        stages: payload,
        loading: false,
        shouldReloadTemplate: false,
      };
    }
    case POST_STAGE_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_STAGE_SUCCESS: {
      return {
        ...state,
        stages: [...state.stages, payload],
        loading: false,
      };
    }
    case DELETE_STAGE_BY_ID_ATTEMPT: {
      return {
        ...state,
        loading: true,
        shouldReloadTemplate: false,
      };
    }
    case DELETE_STAGE_BY_ID_SUCCESS: {
      const filteredStages = state.stages.filter(
        (stage) => payload.id !== stage.id
      );
      return {
        ...state,
        stages: filteredStages,
        loading: false,
        shouldReloadTemplate: true,
      };
    }
    case PATCH_STAGE_BY_ID_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case PATCH_STAGE_BY_ID_SUCCESS: {
      const newStages = state.stages.map((stage) => {
        if (stage.id === payload.id) {
          return payload;
        }
        return stage;
      });
      return {
        ...state,
        stages: newStages,
        loading: false,
      };
    }
    case POST_TASK_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_TASK_SUCCESS: {
      const newTask = {
        ...payload,
        council_task_template_id: payload.id,
        stage: {
          name: payload.stage.name,
          stage_id: payload.stage.id,
        },
      };
      return {
        ...state,
        tasks: [...state.tasks, newTask],
        loading: false,
      };
    }
    case DELETE_TASK_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_TASK_SUCCESS: {
      const filteredTasks = state.tasks.filter(
        (task) => payload.id !== task.council_task_template_id
      );
      return {
        ...state,
        tasks: filteredTasks,
        loading: false,
      };
    }
    case POST_TASK_DOCUMENT_ATTEMPT: {
      return {
        ...state,
        shouldReloadTasks: true,
        loading: true,
      };
    }
    case POST_TASK_DOCUMENT_SUCCESS: {
      return {
        ...state,
        shouldReloadTasks: false,
        shouldReloadTemplate: true,
      };
    }
    case PATCH_TASK_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case PATCH_TASK_SUCCESS: {
      const newTasks = state.tasks.map((task) => {
        if (task.council_task_template_id === payload.id) {
          return {
            ...payload,
            council_task_template_id: payload.id,
            stage: {
              name: payload.stage.name,
              stage_id: payload.stage.id,
            },
          };
        }

        return task;
      });
      return {
        ...state,
        loading: false,
        shouldReloadTemplate: true,
        tasks: newTasks,
      };
    }
    case PATCH_TASK_DOC_NAME_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case PATCH_TASK_DOC_NAME_SUCCESS: {
      const newTasks = state.tasks.map((task) => {
        if (task.council_task_template_id === payload.id) {
          return {
            council_task_template_id: payload.id,
            description: payload.description,
            name: payload.name,
            stage: {
              name: payload.stage.name,
              stage_id: payload.stage.id,
            },
            documents: payload.documents,
            position: payload.position,
            days_to_complete: payload.days_to_complete,
            task_type: payload.task_type,
          };
        }

        return task;
      });
      return {
        ...state,
        loading: false,
        shouldReloadTemplate: true,
        tasks: newTasks,
      };
    }
    case CATCH_ERROR_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default adminProjectsReducer;

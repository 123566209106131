export const PROJECT_RATINGS_ALL_GET_ATTEMPT = "PROJECT_RATINGS_ALL_GET_ATTEMPT";
export const projectRatingsAllGet = (payload) => ({
  type: PROJECT_RATINGS_ALL_GET_ATTEMPT,
  payload,
});

export const PROJECT_RATINGS_ALL_GET_SUCCESS = "PROJECT_RATINGS_ALL_GET_SUCCESS";
export const projectRatingsAllGetSuccess = (payload) => ({
  type: PROJECT_RATINGS_ALL_GET_SUCCESS,
  payload,
});

export const PROJECT_RATINGS_BY_CATEGORY_GET_ATTEMPT = "PROJECT_RATINGS_BY_CATEGORY_GET_ATTEMPT";
export const projectRatingsByCategoryGet = (payload) => ({
  type: PROJECT_RATINGS_BY_CATEGORY_GET_ATTEMPT,
  payload,
});

export const PROJECT_RATINGS_BY_CATEGORY_GET_SUCCESS = "PROJECT_RATINGS_BY_CATEGORY_GET_SUCCESS";
export const projectRatingsByCategoryGetSuccess = (payload) => ({
  type: PROJECT_RATINGS_BY_CATEGORY_GET_SUCCESS,
  payload,
});

export const HANDLE_ERROR_SUCCESS = "HANDLE_ERROR_SUCCESS";
export const handleErrorSuccess = (payload) => ({
  type: HANDLE_ERROR_SUCCESS,
  payload,
});

export const PROJECT_TEMPLATE_RATINGS_ALL_GET_ATTEMPT = "PROJECT_TEMPLATE_RATINGS_ALL_GET_ATTEMPT";
export const projectTemplateRatingsAllGet = (payload) => ({
  type: PROJECT_TEMPLATE_RATINGS_ALL_GET_ATTEMPT,
  payload,
});

export const PROJECT_TEMPLATE_RATINGS_ALL_GET_SUCCESS = "PROJECT_TEMPLATE_RATINGS_ALL_GET_SUCCESS";
export const projectTemplateRatingsAllGetSuccess = (payload) => ({
  type: PROJECT_TEMPLATE_RATINGS_ALL_GET_SUCCESS,
  payload,
});

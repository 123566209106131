import React, { memo, useEffect, useState } from "react";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSnackbar } from "notistack";

import style from "./style.module.scss";
import { MoreOptions } from "../../../../primitives";
import { checkViewerRole } from "../../../../../common/checkers/viewerChecker";
import { dropdownOptions, relationshipOptions } from "modules/patientOrganizations/constants";
import { patientOrganizationEdit } from "modules/patientOrganizations/patientOrganization/patientOrganization.action";
import ShareWithComponent from "modules/companies/company/popups/shareWith";
import AddCompanyComponent from "modules/companies/companyMain/addToProject";
import { useModelPopup } from "../../../../../common/hooks";

const PatientOrganizationItem = ({
  organization,
  history,
  dispatch,
}) => {
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [relationship, setRelationship] = useState([]);
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [options, setOptions] = useState(dropdownOptions);

  useEffect(() => {
    if (organization) {
      setRelationship(organization.relationship_stage);
    }
  }, [organization?.relationship_stage]);

  useEffect(() => {
    if (checkViewerRole()) {
      const filteredOptions = dropdownOptions.filter(
        (option) => option.text !== "Add to project",
      );
      setOptions(filteredOptions);
      setBlockFunctionality(true);
    }
  }, []);

  const handleCompanyClick = () => {
    localStorage.setItem("company", JSON.stringify(organization));
    history.push(`/patient_organizations/${organization.slug || organization.id}/overview`);
  };

  const humanizeText = (str) => {
    let i;
    const frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  };

  const handleDropdownSelect = (e) => {
    setRelationship(e.target.value);
    const data = {
      relationship_stage: e.target.value,
    };
    dispatch(
      patientOrganizationEdit({
        patient_organization_id: organization.id,
        data,
        enqueueSnackbar,
      }),
    );
  };

  const handleMoreOptionClick = (val) => {
    if (val === 1) {
      popup.show({
        title: "Share With",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ShareWithComponent
            actionType="sharePatientOrganization"
            selectedCompany={organization}
          />
        ),
      });
    } else if (val === 2) {
      popup.show({
        title: "Add Organization To Project",
        show: true,
        height: "1000",
        width: "540",
        component: (
          <AddCompanyComponent
            companyId={organization.id}
            type="patient_organizations"
            enqueueSnackbar={enqueueSnackbar}
          />
        ),
      });
    }
  };

  return (
    <div className={style.companyWrapper}>
      <span className={style.columnValue} onClick={handleCompanyClick}>
        {organization.country || '-'}
      </span>
      <span className={style.columnValue} onClick={handleCompanyClick}>
        {organization.match_score || '-'}
      </span>
      <div className={`${style.columnValue} d-flex flex-column`} onClick={handleCompanyClick}>
        {organization.resource_diseases?.map(({disease}) => (
          <span key={disease.id} className="text-center">
            {disease.name},
          </span>
        ))}
      </div>
      <span className={style.columnValue} onClick={handleCompanyClick}>
        {humanizeText(organization.geographic_reach || '-')}
      </span>
      <span className={`${style.columnValue} ${style.status}`}>
        <Select
          value={relationship || "no_relationship"}
          onClick={(e) => e.stopPropagation()}
          onChange={handleDropdownSelect}
          className={`${style.detailsRelationship} ${style[relationship]}`}
          disableUnderline
          hintStyle={{textAlign: "center"}}
          disabled={blockFunctionality}
        >
          {relationshipOptions.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </span>
      <div className={style.colMore}>
        <MoreOptions
          options={options}
          className={style.moreOptWrp}
          onClick={(val) => handleMoreOptionClick(val)}
        />
      </div>
    </div>
  );
};

export default memo(PatientOrganizationItem);

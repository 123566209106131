import React from "react";
import { Field } from "formik";
import classnames from "classnames";
import styles from "./form-generator.module.scss";

const Input = ({ field, formikprops }) => {
  const {
    id, section_name: label, section_type: type, placeholder,
  } = field;

  const { errors, touched } = formikprops;
  const isInvalid = errors[id] && touched[id];
  return (
    <div className={styles.formGroup} key={`field-${id}`}>
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      <Field
        id={id}
        name={field.id ? field.id : id}
        type={type}
        as={type === "textarea" ? "textarea" : "input"}
        className={classnames(
          styles.formControl,
          isInvalid && styles.invalidInput,
        )}
        placeholder={placeholder}
      />
      {isInvalid && <div className={styles.invalidFeedback}>{errors[id]}</div>}
    </div>
  );
};

export default Input;

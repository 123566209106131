import React, { useState, useEffect } from "react";
import Modal from "react-awesome-modal";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { appModelSettingSet } from "../app.action";
import styles from "./modal.module.scss";

const ModalComponent = () => {
  const dispatch = useDispatch();
  const {
    appReducer: { modalSetting },
  } = useSelector((state) => state);
  const [show, setShow] = useState(false);
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  useEffect(() => {
    modalSetting.show
      ? document.body.classList.add("slider-open")
      : document.body.classList.remove("slider-open");

    return () => {
      document.body.classList.remove("slider-open");
    }
  }, [modalSetting.show]);

  useEffect(
    () => history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          handleCloseClick();
          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          // Handle back event
          handleCloseClick();
        }
      }
    }),
    [locationKeys],
  );

  const handleCloseClick = (e) => {
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        show: false,
        component: null,
        fullScreen: false,
      }),
    );
  };

  useEffect(() => {
    setShow(modalSetting.show);
  }, [modalSetting]);

  const handleButtonClick = (key, btnObj) => {
    if (btnObj.click) {
      dispatch(appModelSettingSet({ ...modalSetting, show: false }));
      btnObj.click++;
    } else {
      handleCloseClick();
    }
  };

  return (
    <Modal
      visible={show}
      width={modalSetting.fullScreen ? "100%" : modalSetting.width}
      height={modalSetting.fullScreen ? "100%" : modalSetting.height}
      effect="fadeInUp"
      // onClickAway={() => handleCloseClick()}
    >
      <div className={styles.modalWrp} id="modalWrapper">
        <div className="d-flex justify-content-between">
          {modalSetting.title ? (
            <h4 className={styles.modalTitle}>{modalSetting.title}</h4>
          ) : <span></span>}
          <div
            onClick={handleCloseClick}
            className={`${modalSetting.title?.length ? 'mt-0' : 'mt-3'} d-flex align-items-center mr-3 cursor-pointer`}
          >
            <svg width="20px" height="20px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 21.32L21 3.32001" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M3 3.32001L21 21.32" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
        </div>

        {show && modalSetting.component}
      </div>
    </Modal>
  );
};

export default ModalComponent;

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import styles from '../styles.module.scss';
import { Label, DropSelect } from "modules/primitives";
import { getProjectFields } from "modules/reporting/fordReports/store/fordReports.actions";

const FordProjectFieldsFilter = ({ handleFilterUpdate, reducer, setNewFilters }) => {
  const dispatch = useDispatch();

  const {
    fordDashboardReducer: { fordReportsReducer: { projectFields } }
  } = useSelector((state) => state);

  const [fieldValues, setFieldValues] = useState({});

  useEffect(() => {
    setFieldValues(reducer.filters?.projectFieldValues || {});
  }, [reducer.filters?.projectFieldValues]);

  useEffect(() => {
    if (!projectFields) {
      dispatch(getProjectFields());
    }
  }, [projectFields]);

  const updateFilters = useCallback((value, prop, shouldRequest) => {
    setNewFilters({
      ...reducer.filters,
      [prop]: value,
    })

    if (shouldRequest) {
      handleFilterUpdate({[prop]: value}, reducer.tableData?.expandedKey);
    }
  }, [reducer]);

  const handleFieldValueChange = (value, field) => {
    if (!value.obj?.length) {
      if (fieldValues[field.project_field_template_id]) {
        const newValues = {
          ...fieldValues,
          [field.project_field_template_id]: null
        }
        setFieldValues(newValues);
        updateFilters(newValues, 'projectFieldValues', true);
      }

      return;
    }

    let parent = null;

    if (
      field.field_name === 'Prototype Funded By' ||
      field.field_name === 'Skills Team' ||
      field.field_name === 'Program (Prototype)'
    ) {
      parent = field
    }

    const newValues = {
      ...fieldValues,
      [field.project_field_template_id]: [{
        ...value.obj[0],
        parent,
      }]
    }
    setFieldValues(newValues);
    updateFilters(newValues, 'projectFieldValues', true);
  };

  const makeCustomNames = (field) => {
    if (field.field_name === 'Prototype Funded By') {
      return 'Funded By';
    }

    if (field.field_name === 'Program (Prototype)') {
      return 'Program';
    }

    return field.field_name;
  }

  return (
    <>
      {
        projectFields?.length > 0 ? (
          <>
            {
              projectFields.map(field => {
                const fieldName = makeCustomNames(field);
                const hasProgramSelected = fieldValues[field.project_field_template_id]?.some(el => el.name === 'Program' || el.name === 'Program (Prototype)');
                const getProgram = projectFields.find(f => f.field_name === 'Program' || f.field_name === 'Program (Prototype)');

                if (fieldName === 'Approved for Replication') {
                  return (
                    <div key={field.project_field_template_id} className={classNames(styles.filter, "mb-3")}>
                      <Label>Catalog</Label>
                      <DropSelect
                        multi={false}
                        SelecedValues={fieldValues[field.project_field_template_id] || []}
                        onChange={(val) => handleFieldValueChange(val, field)}
                        options={field.option_values.map(v => ({ id: v, name: v }))}
                        labelField="name"
                        valueField="id"
                        placeholder="Catalog"
                        searchBy="name"
                      />
                    </div>
                  )
                }

                if (fieldName === "Funded By") {
                  return (
                    <>
                      <div key={field.project_field_template_id} className={classNames(styles.filter, "mb-3")}>
                        <Label>{fieldName}</Label>
                        <DropSelect
                          multi={false}
                          SelecedValues={fieldValues[field.project_field_template_id] || []}
                          onChange={(val) => handleFieldValueChange(val, field)}
                          options={field.option_values.map(v => ({ id: v, name: v }))}
                          labelField="name"
                          valueField="id"
                          placeholder={fieldName}
                          searchBy="name"
                        />
                      </div>
                      {
                        hasProgramSelected && getProgram && (
                          <div key={getProgram.project_field_template_id} className={classNames(styles.filter, "mb-3")}>
                            <Label>Program</Label>
                            <DropSelect
                              multi={false}
                              SelecedValues={fieldValues[getProgram.project_field_template_id] || []}
                              onChange={(val) => handleFieldValueChange(val, field)}
                              options={getProgram.option_values.map(v => ({ id: v, name: v }))}
                              labelField="name"
                              valueField="id"
                              placeholder="Program"
                              searchBy="name"
                            />
                          </div>
                        )
                      }
                    </>
                  )
                }

                if (fieldName === "Program") {
                  return null;
                }

                return (
                  <div key={field.project_field_template_id} className={classNames(styles.filter, "mb-3")}>
                    <Label>{fieldName}</Label>
                    <DropSelect
                      multi={false}
                      SelecedValues={fieldValues[field.project_field_template_id] || []}
                      onChange={(val) => handleFieldValueChange(val, field)}
                      options={field.option_values.map(v => ({ id: v, name: v }))}
                      labelField="name"
                      valueField="id"
                      placeholder={field.field_name}
                      searchBy="name"
                    />
                  </div>
                )
              })
            }
          </>
        ) : null
      }
    </>
  );
};

export default React.memo(FordProjectFieldsFilter);

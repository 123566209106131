export const SET_SELECTED_COMPANIES = "SET_SELECTED_COMPANIES";
export const setSelectedCompanies = (payload) => ({
  type: SET_SELECTED_COMPANIES,
  payload,
});

export const SET_COMPANIES_TAGS_NOT_IN_COUNCIL =
  "SET_COMPANIES_TAGS_NOT_IN_COUNCIL";
export const setCompaniesTagsNotInCouncil = (payload) => ({
  type: SET_COMPANIES_TAGS_NOT_IN_COUNCIL,
  payload,
});

export const UPDATE_COMPANY_RATING_VALUE = "UPDATE_COMPANY_RATING_VALUE";
export const updateCompanyRatingValue = (payload) => ({
  type: UPDATE_COMPANY_RATING_VALUE,
  payload,
});

export const SET_CHART_TYPE = "SET_CHART_TYPE";
export const setChartType = (payload) => ({
  type: SET_CHART_TYPE,
  payload,
});

export const SET_CHARTS_SHOULD_RERENDER = "SET_CHARTS_SHOULD_RERENDER";
export const setChartsShouldReRender = (payload) => ({
  type: SET_CHARTS_SHOULD_RERENDER,
  payload,
});

export const UPDATE_CHART_LIST_COMPANY_DETAILS =
  "UPDATE_CHART_LIST_COMPANY_DETAILS";
export const setUpdateChartListCompanyDetails = (payload) => ({
  type: UPDATE_CHART_LIST_COMPANY_DETAILS,
  payload,
});

export const SET_CHARTS_INTRO_ACTIVE = "SET_CHARTS_INTRO_ACTIVE";
export const setChartsIntroActive = (payload) => ({
  type: SET_CHARTS_INTRO_ACTIVE,
  payload,
});

export const SET_CHARTS_REQ_IT_IS_IN_PROGRESS =
  "SET_CHARTS_REQ_IT_IS_IN_PROGRESS";
export const setChartsReqItIsInProgress = (payload) => ({
  type: SET_CHARTS_REQ_IT_IS_IN_PROGRESS,
  payload,
});

export const UPDATE_BUBBLE_CHART_OPTIONS = "UPDATE_BUBBLE_CHART_OPTIONS";
export const updateBubbleChartOptions = (payload) => ({
  type: UPDATE_BUBBLE_CHART_OPTIONS,
  payload,
});

export const SET_CHARTS_ARE_RE_RENDERING = "SET_CHARTS_ARE_RE_RENDERING";
export const setChartsAreReRendering = (payload) => ({
  type: SET_CHARTS_ARE_RE_RENDERING,
  payload,
});

export const SET_CHARTS_PAGE = "SET_CHARTS_PAGE";
export const setChartsPage = (payload) => ({
  type: SET_CHARTS_PAGE,
  payload,
});

export const SET_LOCAL_CHARTS_STATE = "SET_LOCAL_CHARTS_STATE";
export const setLocalChartsState = (payload) => ({
  type: SET_LOCAL_CHARTS_STATE,
  payload,
});

export const SET_CHARTS_VIEW_TYPE = "SET_CHARTS_VIEW_TYPE";
export const setChartsViewType = (payload) => ({
  type: SET_CHARTS_VIEW_TYPE,
  payload,
});

export const RESET_ALL_COMPANIES = "RESET_ALL_COMPANIES";
export const resetAllCompanies = () => ({
  type: RESET_ALL_COMPANIES,
});

export const GET_ALL_COMPANIES_ATTEMPT = "GET_ALL_COMPANIES_ATTEMPT";
export const getAllCompaniesAttempt = (payload) => {
  return {
    type: GET_ALL_COMPANIES_ATTEMPT,
    payload,
  };
};
export const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS";
export const getAllCompaniesSuccess = (payload) => ({
  type: GET_ALL_COMPANIES_SUCCESS,
  payload,
});

export const ADD_CRUNCH_COMPANY_TO_CURRENT_STATE =
  "ADD_CRUNCH_COMPANY_TO_CURRENT_STATE";
export const addCrunchCompanyToState = (payload) => ({
  type: ADD_CRUNCH_COMPANY_TO_CURRENT_STATE,
  payload,
});

export const COMPANY_SEARCH_OWNERS_GET_ATTEMPT =
  "COMPANY_SEARCH_OWNERS_GET_ATTEMPT";
export const companySearchOwnersGetAttempt = (payload) => ({
  type: COMPANY_SEARCH_OWNERS_GET_ATTEMPT,
  payload,
});

export const COMPANY_SEARCH_OWNERS_GET_SUCCESS =
  "COMPANY_SEARCH_OWNERS_ATTEMPT";
export const companySearchOwnersGetSuccess = (payload) => ({
  type: COMPANY_SEARCH_OWNERS_GET_SUCCESS,
  payload,
});

export const ADD_COMPANY_TO_PROJECT_ATTEMPT = "ADD_COMPANY_TO_PROJECT_ATTEMPT";
export const projectCompanyNew = (payload) => ({
  type: ADD_COMPANY_TO_PROJECT_ATTEMPT,
  payload,
});

export const ADD_COMPANY_TO_PROJECT_SUCCESS = "ADD_COMPANY_TO_PROJECT_SUCCESS";
export const projectCompanyNewSuccess = (payload) => ({
  type: ADD_COMPANY_TO_PROJECT_SUCCESS,
  payload,
});

export const COMPANY_SEARCH_ATTEMPT = "COMPANY_SEARCH_ATTEMPT";
export const searchInCouncil = (payload) => {
  return {
    type: COMPANY_SEARCH_ATTEMPT,
    payload,
  };
};

export const COMPANY_SEARCH_SUCCESS = "COMPANY_SEARCH_SUCCESS";
export const companySearchSuccess = (payload) => ({
  type: COMPANY_SEARCH_SUCCESS,
  payload,
});

export const SALESFORCE_COMPANY_SEARCH_ATTEMPT =
  "SALESFORCE_COMPANY_SEARCH_ATTEMPT";
export const sfSearchInCouncil = (payload) => ({
  type: SALESFORCE_COMPANY_SEARCH_ATTEMPT,
  payload,
});

export const SALESFORCE_COMPANY_SEARCH_SUCCESS =
  "SALESFORCE_COMPANY_SEARCH_SUCCESS";
export const sfCompanySearchSuccess = (payload) => ({
  type: SALESFORCE_COMPANY_SEARCH_SUCCESS,
  payload,
});

export const RESET_BEFORE_GETTING_BIG_DATA = "RESET_BEFORE_GETTING_BIG_DATA";
export const resetBeforeGettingBigData = (payload) => ({
  type: RESET_BEFORE_GETTING_BIG_DATA,
  payload,
});

export const COMPANY_SEARCH_CLEAR = "COMPANY_SEARCH_CLEAR";
export const companySearchClear = (payload) => ({
  type: COMPANY_SEARCH_CLEAR,
  payload,
});

export const COMPANY_GETFILTERS_ATTEMPT = "COMPANY_GETFILTERS_ATTEMPT";
export const companyGetFilters = (payload) => ({
  type: COMPANY_GETFILTERS_ATTEMPT,
  payload,
});

export const COMPANY_GETFILTERS_SUCCESS = "COMPANY_GETFILTERS_SUCCESS";
export const companyGetFiltersSuccess = (payload) => ({
  type: COMPANY_GETFILTERS_SUCCESS,
  payload,
});

export const COMPANY_GET_TOPICS_ATTEMPT = "COMPANY_GET_TOPICS_ATTEMPT";
export const companyGetTopics = (payload) => ({
  type: COMPANY_GET_TOPICS_ATTEMPT,
  payload,
});

export const COMPANY_GET_TOPICS_SUCCESS = "COMPANY_GET_TOPICS_SUCCESS";
export const companyGetTopicSuccess = (payload) => ({
  type: COMPANY_GET_TOPICS_SUCCESS,
  payload,
});

// industries
export const SET_FILTERS_ATTEMPT = "SET_FILTERS_ATTEMPT";
export const setFilters = (payload) => ({
  type: SET_FILTERS_ATTEMPT,
  payload,
});

export const COMPANY_SEARCH_CRUNCHBASE_ATTEMPT =
  "COMPANY_SEARCH_CRUNCHBASE_ATTEMPT";
export const companySearchCrunchBase = (payload) => ({
  type: COMPANY_SEARCH_CRUNCHBASE_ATTEMPT,
  payload,
});

export const COMPANY_SEARCH_CRUNCHBASE_SUCCESS =
  "COMPANY_SEARCH_CRUNCHBASE_SUCCESS";
export const searchCrunchBaseSuccess = (payload) => ({
  type: COMPANY_SEARCH_CRUNCHBASE_SUCCESS,
  payload,
});

export const COMPANY_SEARCH_CRUNCHBASE_CLEAR =
  "COMPANY_SEARCH_CRUNCHBASE_CLEAR";
export const searchCrunchBaseClear = (payload) => ({
  type: COMPANY_SEARCH_CRUNCHBASE_CLEAR,
  payload,
});

export const SET_ERROR_HANDLED_SUCCESS = "SET_ERROR_HANDLED_SUCCESS";
export const setErrorHandledSuccess = () => ({
  type: SET_ERROR_HANDLED_SUCCESS,
});

export const CLEAR_EXPORTED_COMPANIES = "CLEAR_EXPORTED_COMPANIES";
export const clearExportedCompanies = (payload) => ({
  type: CLEAR_EXPORTED_COMPANIES,
  payload,
});

export const SET_SCROLL_TOP = "SET_SCROLL_TOP";
export const setScrolledTop = (payload) => ({
  type: SET_SCROLL_TOP,
  payload,
});

export const HANDLE_ERROR_SUCCESS = "HANDLE_ERROR_SUCCESS";
export const handleErrorSuccess = (payload) => ({
  type: HANDLE_ERROR_SUCCESS,
  payload,
});

export const SET_SORT_TYPE_SUCCESS = "SET_SORT_TYPE_SUCCESS";
export const setSortTypeSuccess = (payload) => ({
  type: SET_SORT_TYPE_SUCCESS,
  payload,
});

export const SAVE_SEARCH_COMPANY_LIST = "SAVE_SEARCH_COMPANY_LIST";
export const saveSearchCompanyList = (payload) => ({
  type: SAVE_SEARCH_COMPANY_LIST,
  payload,
});

export const COMPANY_LIST_GET_ATTEMPT = "COMPANY_LIST_GET_ATTEMPT";
export const companyListGet = (payload) => ({
  type: COMPANY_LIST_GET_ATTEMPT,
  payload,
});

export const COMPANY_LIST_GET_SUCCESS = "COMPANY_LIST_GET_SUCCESS";
export const companyListGetSuccess = (payload) => ({
  type: COMPANY_LIST_GET_SUCCESS,
  payload,
});
export const GET_AGREEMENT_TYPES_ATTEMPT = "GET_AGREEMENT_TYPES_ATTEMPT";
export const getAgreementsTypesAttempt = (payload) => ({
  type: GET_AGREEMENT_TYPES_ATTEMPT,
  payload,
});

export const GET_AGREEMENT_TYPES_SUCCESS = "GET_AGREEMENT_TYPES_SUCCESS";
export const getAgreementTypesSuccess = (payload) => ({
  type: GET_AGREEMENT_TYPES_SUCCESS,
  payload,
});

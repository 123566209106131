import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useSnackbar } from "notistack";
import ConfirmActionComponent from "../../../common/components/confirmAction/confirmAction.component";
import { useModelPopup } from "../../../common/hooks";

import styles from "./notificationItem.module.scss";
import { resourceImages } from "../../../common/constants";
import { Icon, smSize } from "../../../common/icon";
import { myTasksNotificationsDelete } from "../../../common/actions/discussions.action";
import {
  UserInline,
  UserInlineTypes,
  CheckBox,
  CheckType,
} from "../../primitives";
import classNames from "../../../../node_modules/classnames/index";

const NotificationItem = ({ notification, handleShow, session }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const popup = useModelPopup();

  const entity_type =
    notification?.entity?.entity_type?.charAt(0).toUpperCase() +
    notification?.entity?.entity_type?.slice(1);
  const handleRedirect = (e) => {
    e.stopPropagation();
    if (entity_type === "Project") {
      history.push(`/projects/${notification?.entity?.entity_id}`);
    }

    if (entity_type === "Task") {
      if (notification?.entity.entity_url) {
        const parts = notification?.entity.entity_url?.split("/");
        const relativeUrl = "/" + parts.slice(3).join("/");
        history.push(`${relativeUrl}`);
      }
    }
    if (entity_type === "Event") {
      history.push(`/events/id/${notification?.entity.entity_id}`);
    }
    if (entity_type === "Company") {
      history.push(
        `/companies/${
          notification?.entity.slug || notification?.entity.entity_id
        }`
      );
    }

    if (entity_type === "List") {
      history.push(`/list/${notification?.entity.entity_id}`);
    }

    if (entity_type === "Theme") {
      history.push(
        `/themes/individual_page/${notification?.entity.entity_id}/overview`
      );
    }
  };

  const checkIconType = (type) => {
    if (type === "Event") {
      return styles.Event;
    }
    if (type === "Idea") {
      return styles.Idea;
    }
    if (type === "Project") {
      return styles.Project;
    }
    if (type === "Gsk::PatientOrganization") {
      return styles.Organization;
    }
    if (type === "Theme") {
      return styles.Theme;
    }
    if (type === "List") {
      return styles.List;
    }
    return styles.Company;
  };

  const handleNotifRemove = (id) => {
    popup.show({
      title: "Delete Confirmation",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmActionComponent
          alertText="You are about to delete this notification. Are you sure?"
          confirmBtnName="Delete"
          onConfirm={() =>
            dispatch(myTasksNotificationsDelete({ id, enqueueSnackbar }))
          }
        />
      ),
    });
  };

  if (!notification) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.discussionDetails,
        notification?.read ? styles.read : styles.unread
      )}
      onClick={handleShow}
    >
      <div
        className={
          notification?.completed !== undefined ? styles.projName : styles.basic
        }
      >
        <>
          {notification?.from?.replace(/<.*?>/g, "") ||
            notification.project_name}
        </>
      </div>
      {notification?.completed === undefined && (
        <div className={styles.subject}>
          {notification?.subject
            ? `${
                notification.subject.length > 30
                  ? notification.subject?.substring(0, 30) + "..."
                  : notification.subject
              }`
            : ""}
        </div>
      )}

      {notification?.completed !== undefined ? (
        <div className={styles.completed}>
          <div>
            <CheckBox
              isChecked={notification?.completed === "yes"}
              checkType={
                notification?.completed === "yes"
                  ? CheckType.GREEN
                  : CheckType.GREEN
              }
              disabled={true}
            />
          </div>
        </div>
      ) : null}

      {notification?.type === "email_messages" && (
        <div
          className={classNames(
            styles.resourceType,
            checkIconType(entity_type),
            notification?.completed !== undefined && styles.taskResourceType
          )}
          onClick={handleRedirect}
        >
          <img src={resourceImages[entity_type]} alt={entity_type} />
          <span>{notification.entity?.entity_name}</span>
        </div>
      )}
      <div className={styles.team}>
        <UserInline
          userId={notification?.user_id}
          avatar={session?.avatar}
          type={UserInlineTypes.WITH_NAME}
          first={session?.full_name.split(" ")[0]}
          last={session?.full_name.split(" ")[1]}
          showMine={false}
          local
        />
      </div>
      <div className={styles.msgsNr}>
        <span className="mr-2"></span>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g fill="none" fillRule="evenodd">
              <g fill="#3994C1">
                <g>
                  <g>
                    <path
                      d="M22.477.988c.828 0 1.5.672 1.5 1.5v15.037c0 .829-.672 1.5-1.5 1.5L11.2 19.024l-4.818 4.794c-.953.948-2.542.258-2.559-1.03l.007-.172.335-3.59h-2.65c-.769 0-1.403-.578-1.49-1.324l-.01-.175V2.488c0-.828.672-1.5 1.5-1.5zm0 1.5H1.515v15.039h3.677l.11.013c.109.025.214.085.306.158.097.122.171.27.147.416l-.431 4.641 5.134-5.107.078-.05c.082-.045.179-.073.29-.073h11.65V2.488zM12.75 11.5c.414 0 .75.336.75.75s-.336.75-.75.75h-6c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h6zm4.5-3c.414 0 .75.336.75.75s-.336.75-.75.75H6.75C6.336 10 6 9.664 6 9.25s.336-.75.75-.75h10.5zm0-3c.414 0 .75.336.75.75s-.336.75-.75.75H6.75C6.336 7 6 6.664 6 6.25s.336-.75.75-.75h10.5z"
                      transform="translate(-1320 -261) translate(72 249) translate(1248 11)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
      </div>

      <div
        className={styles.trashIcon}
        onClick={(e) => {
          e.stopPropagation();
          handleNotifRemove(notification?.id);
        }}
      >
        <span>
          <Icon {...smSize} className={styles.trash} icon="icn-button-delete" />
        </span>
      </div>
    </div>
  );
};

export default React.memo(NotificationItem);

import React  from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import styles from "./headerInfo.module.scss";
import { StarRating } from "../../../primitives";
import { useEventsDates } from "../../../../common/hooks";
import { eventsAddRating } from "../viewEvent.action";

const HeaderInfoComponent = (props) => {
  const { event } = props;

  const dispatch = useDispatch();
  const eventsDates = useEventsDates({ event });
  const { enqueueSnackbar } = useSnackbar();

  const {
    authReducer: { session },
  } = useSelector((state) => state);
  const changeRating = (starsNr) => {
    dispatch(
      eventsAddRating({
        eventId: event.id,
        eventAttendeeId: session.id,
        rating: starsNr,
        enqueueSnackbar,
      }),
    );
  };

  return (
    <div className={styles.headerCon}>
      <h4 className={styles.title}>{event.name}</h4>
      <div className={classNames("caption", styles.timeWrp)}>
        <div className={styles.date}>{eventsDates.getTimeRange("date")}</div>
        <div className={styles.time}>{eventsDates.getTimeRange("time")}</div>
      </div>
      <div className={classNames("caption", styles.location)}>
        {" "}
        {event.location}
      </div>
      <div className={styles.rating}>
        <StarRating rate={event.rating_stars} onChange={changeRating} />
      </div>

      <div className={classNames("caption", styles.yourMark)}>
        {" "}
        Your rating: {event.current_user_rating}
      </div>
    </div>
  );
};

export default React.memo(HeaderInfoComponent);

import Tooltip from "@mui/material/Tooltip/Tooltip";
import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styles from "./styles.module.scss";
import "react-tabs/style/react-tabs.css";

const NewTabs = (props) => {
  const { tabList, defaultIndex, onSelect, isNavigationHidden } = props;

  const [activeTab, setActiveTab] = useState(null);
  useEffect(() => {
    if (defaultIndex !== null && defaultIndex >= 0) {
      setActiveTab(defaultIndex);
    }
  }, [defaultIndex]);

  const handleSelect = (value) => {
    setActiveTab(value);
    onSelect(value);
  };
  if (!tabList || activeTab === null) {
    return null;
  }

  return (
    <Tabs
      className="custom-company-tabs"
      selectedIndex={activeTab || 0}
      onSelect={handleSelect}
    >
      <div>
        {!isNavigationHidden ? (
          <TabList>
            {tabList.map(({ tab, name, attr, showTooltip, text }, index) => (
              <Tab key={`tab${index}`} {...attr}>
                {name}
                {showTooltip && (
                  <Tooltip title={text}>
                    <div className={styles.interogationMark}>
                      <span> ?</span>
                    </div>
                  </Tooltip>
                )}
              </Tab>
            ))}
          </TabList>
        ) : (
          <div style={{ height: 34 }} />
        )}
      </div>
      <div>
        {tabList.map(({ component }, index) => (
          <TabPanel key={`panel${index}`}>{component}</TabPanel>
        ))}
      </div>
    </Tabs>
  );
};

export default React.memo(NewTabs);

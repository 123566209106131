import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { setAccessTokenSalesforce } from "../../admin/adminIntegrations/adminIntegrations.actions";
import { httpPost } from "../../../common/httpCall";
import styles from "./salesforceIntegration.module.scss";

const SalesforceIntegration = () => {
  const [token, setToken] = useState("");
  const [councilId, setCouncilId] = useState("");
  const [userId, setUserId] = useState("");

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [connectionStatusMessage, setConnectionStatusMessage] = useState(
    "Loading... Please Wait",
  );

  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    setToken(params.get("code"));
    const formattedData = params.get("state").split("|");
    setCouncilId(formattedData[0]);
    setUserId(formattedData[1]);
    if (token) {
      const values = {
        council_id: councilId,
        user_id: userId,
        authorization_code: token,
      };
      httpPost({
        data: { ...values },
        call: "sf_integrations/get_access_token",
      })
        .pipe()
        .subscribe(
          (res) => {
            if (res.response) {
              dispatch(setAccessTokenSalesforce({ enqueueSnackbar, ...res }));
              setConnectionStatusMessage(
                "Success! Traction is now connected to Salesforce. You can close this window.",
              );
            }
          },
          () => {
            setConnectionStatusMessage(
              "Connection request has been timed out. Please try again later.",
            );
          },
        );
    }
  }, [token]);

  return (
    <>
      <div className={styles.mainText}>
        <h2>{connectionStatusMessage}</h2>
      </div>
    </>
  );
};

export default React.memo(SalesforceIntegration);

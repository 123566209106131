import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  BtnType,
  Button,
  Loading,
  MoreOptions,
  OptionType,
} from "modules/primitives/index";
import ReactTable from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import adminFuseHoc from "common/HOC/adminFuseHoc";
import { debounce } from "throttle-debounce";
import styles from "./adminApplicantForms.module.scss";
import { useModelPopup } from "../../../../common/hooks";
import ConfirmDeleteComponent from "../../../../common/components/confirmDelete";
import { httpGet } from "../../../../common/httpCall";
import { applicantsFormsListGet } from "./adminApplicantForms.action";
import StepFourComponent from "./formSteps/stepFour";

const AdminApplicantFormsComponent = () => {
  const history = useHistory();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [exportLoading, setExportLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);

  const {
    councilReducer: { selectedCouncil },
    adminApplicantsFormsReducer: {
      formsList,
      searchResults,
      listLoading,
      applicantPageNr,
    },
  } = useSelector((state) => state);

  const pageSizeOptions = [pageSize, 5, 10, 20, 50, 100];

  const handleCreateFormClick = (e) => {
    history.push("/admin/applicants/forms/create");
  };

  const moreOptions = [
    { val: "preview", text: "Preview" },
    { val: "embedCode", text: "Share" },
    { val: "edit", text: "Edit" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const handleMoreOptionClick = (event, row) => {
    const { id } = row;
    const previewUrl = `${window.location.origin}/submission-form/${row.council_name_slug}/${row.form_name_slug}`;

    if (event === "preview") {
      window.open(previewUrl);
    }
    if (event === "embedCode") {
      popup.show({
        title: "",
        show: true,
        height: "300",
        width: "600",
        component: (
          <div style={{ padding: "35px" }}>
            <StepFourComponent
              formId={id}
              form={row}
              customActionsButtons={
                <Button
                  type="button"
                  onClick={popup.hide}
                  key="close-modal-btn"
                >
                  Close
                </Button>
              }
            />
          </div>
        ),
      });
    }
    if (event === "edit") {
      history.push(`/admin/applicants/forms/${id}/edit`);
    }
    if (event === "delete") {
      popup.show({
        title: "Confirmation Delete",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={id}
            enqueueSnackbar={enqueueSnackbar}
            actionType="deleteApplicationForm"
          />
        ),
      });
    }
  };

  const handleExportList = () => {
    setExportLoading(true);
    httpGet({
      call: `applicants/forms?items=${applicantPageNr.total}`,
      data: {
        query: "*",
      },
    }).subscribe(
      async (res) => {
        setExportLoading(false);
        setCsvData(() => {
          const csvDataCont = [
            ["Title", "Date Created", "Last Submission ", "Total Applicants"],
          ];

          res.response.map((item) => {
            csvDataCont.push([
              item.form_title,
              format(new Date(item.created_at), "yyyy-MM-dd"),
              item.last_submission,
              item.total_applicants,
            ]);
          });

          return csvDataCont;
        });
        csvLinkRef.current.link.click();
      },
      (err) => {
        setExportLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      },

      () => {
        setExportLoading(false);
        enqueueSnackbar("List successfully exported.", {
          variant: "success",
        });
      }
    );
  };

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
  };

  const handlePageSelect = (e) => {
    setPage(e.target.value);
  };

  const handleNextClick = () => {
    setPage(page + 1);
  };

  const handlePrevClick = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const isNextDisabled = () => {
    if (page + 1 > applicantPageNr?.pages) {
      return styles.disabledButton;
    }
    return styles.paginationButton;
  };

  const handleSearch = debounce(1000, false, (val) => {
    setPage(1);
    dispatch(setSearchQuery(val || null));
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "form_title",
        sortable: false,
      },
      {
        Header: "Date Created",
        id: "created_at",
        sortable: false,
        accessor: (row) => format(new Date(row.created_at), "MM/dd/yyyy"),
      },
      {
        Header: "Last Submission",
        id: "last_submission",
        sortable: false,
        accessor: (row) => {
          if (row?.last_submission) {
            return format(new Date(row.last_submission), "MM/dd/yyyy");
          }
          return "N/A";
        },
      },
      {
        Header: "Total Applicants",
        id: "total_applicants",
        sortable: false,
        accessor: "total_applicants",
      },
      {
        Header: "Options",
        id: "options",
        width: 100,
        sortable: false,
        accessor: (row) => (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row);
            }}
          />
        ),
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (selectedCouncil) {
      const query = {
        page,
        items: pageSize,
      };

      if (searchQuery) {
        query.query = searchQuery;
        query["search[query]"] = searchQuery;
      }

      dispatch(
        applicantsFormsListGet({
          enqueueSnackbar,
          query,
        })
      );
    }
  }, [selectedCouncil, pageSize, page, searchQuery]);

  useEffect(() => {
    const data = searchResults && searchResults[0] ? searchResults : formsList;
    setTableData(data);
  }, [formsList, searchResults]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <a href="/admin" className={styles.rootText}>
            ADMIN
          </a>
          <span className={styles.separationBar}> / </span>
          <a href="/admin/applicants" className={styles.rootText}>
            APPLICANTS
          </a>
          <span className={styles.separationBar}> / </span>
          <span className={`${styles.rootText} ${styles.activeBreadcrumbs}`}>
            FORMS
          </span>
        </h4>
      </div>
      <div className={styles.flexWrapper}>
        <div className={styles.createFormButton}>
          <Button
            btn={BtnType.REGULAR}
            icon="icn-add"
            onClick={() => handleCreateFormClick()}
          >
            Create Form
          </Button>
        </div>
        <div className={styles.formsWrp}>
          <div className={styles.searchBar}>
            <input
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>

          <div className={styles.buttonWrp}>
            {exportLoading && <Loading />}

            {!exportLoading && (
              <div>
                <Button
                  className={tableData.length <= 0 && styles.disabledBtn}
                  onClick={() => tableData.length > 0 && handleExportList()}
                  btn={tableData.length ? BtnType.OUTLINE : BtnType.DISABLED}
                  disabled={tableData.length <= 0}
                >
                  EXPORT
                </Button>
                {tableData.length > 0 && (
                  <CSVLink
                    data={csvData}
                    filename="forms.csv"
                    style={{ display: "none" }}
                    ref={csvLinkRef}
                  />
                )}
              </div>
            )}

            <div className="ml-2">{applicantPageNr?.total || "0"} forms</div>
          </div>

          <ReactTable
            data={tableData}
            columns={columns}
            loading={listLoading}
            className={styles.table}
            showPagination={false}
            pageSize={pageSize}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: () => {
                    history.push(
                      `/admin/applicants/forms/${rowInfo.original.id}/edit`
                    );
                  },
                  onMouseEnter: () => {
                    setHoveredRow(rowInfo.index);
                  },
                  onMouseLeave: () => {
                    setHoveredRow(null);
                  },
                  style: {
                    cursor: "pointer",
                    background:
                      rowInfo.index === hoveredRow ? "#efefef" : "#efefef00",
                  },
                };
              }
              return {};
            }}
          />
          <div className={styles.pagination}>
            <div
              className={
                page - 1 === 0 ? styles.disabledButton : styles.paginationButton
              }
              onClick={handlePrevClick}
            >
              Previous
            </div>
            <div className={styles.paginationSettings}>
              <span style={styles.pageNr}>
                <span>
                  <input
                    onChange={(e) => handlePageSelect(e)}
                    value={page}
                    className={styles.inputBox}
                    type="number"
                    min="1"
                  />
                </span>
                <span>of</span>
                <span> {applicantPageNr?.pages}</span>
              </span>

              <span>
                <select onChange={(e) => handleChangePageSize(e)}>
                  {pageSizeOptions.map((option, i) => (
                    <option
                      key={`applicant-form-page-option-${option}-${i}`}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </span>
            </div>
            <button
              className={isNextDisabled()}
              onClick={handleNextClick}
              disabled={page + 1 > applicantPageNr?.pages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(adminFuseHoc(AdminApplicantFormsComponent));

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "modules/primitives/loading/loading.component";
import {
  getProjectReplicationStatus,
  updateProjectReplications,
  updateReplicationStatus,
} from "modules/projects/project/project.action";
import { tooltipTextsByState } from "modules/projects/project/project.constant";

import { useModelPopup } from "../../../../../common/hooks";
import styles from "./replications.module.scss";
import { Pannel } from "../../../../primitives";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import ForPossibleReplicationsModal from "../../../../../common/components/FordPossibleReplications/ForPossibleReplicationsModal.component";
import {
  getNormalizedProjectReplicationsData,
  getNormalizedReplicationsData,
  getSpocPlants,
  makeNormalizedFilteredPlants,
  makeNormalizesPlantsWhenAdmin,
} from "../../../../../common/plantsUtils";

const ReplicationsStatus = ({ project, isAdmin, expandAll }) => {
  const [localProjId, setLocalProjId] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();

  const {
    authReducer: { session },
    projectsReducer: {
      projectReducer: { projectPlant, replicationData },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);

  useEffect(() => {
    if (localProjId !== project?.id) {
      setLocalProjId(project.id);

      dispatch(
        getProjectReplicationStatus({
          id: project.id,
        })
      );
    }
  }, [localProjId, project?.id]);

  const handleAddNewReplications = (data) => {
    dispatch(updateProjectReplications(data));
    dispatch(
      updateReplicationStatus({
        ...replicationData,
        possible_replication: data.map((el) => ({
          ...el,
          plant: {
            name: el.name,
            id: el.id,
          },
        })),
      })
    );
  };

  const handleEdit = () => {
    const originalProject = {
      ...projectPlant,
      areas:
        projectPlant?.areas?.map((a) => ({
          ...a,
          ...a.attributes,
          area_id: a.id || a.area_id,
        })) || [],
    };
    const originalPlant = replicationData?.original_project?.plant?.id
      ? {
          ...replicationData?.original_project?.plant,
          areas: replicationData?.original_project?.plant.area
            ? [
                {
                  ...replicationData?.original_project?.plant.area,
                  area_id: replicationData?.original_project?.plant.area.id,
                },
              ]
            : [],
        }
      : {};
    const filterBy = [
      ...makeProjectReplicationsData,
      ...replicationData?.possible_replication,
      originalProject,
      originalPlant,
    ];

    let normalizedData;
    let showAllReplicated;

    if (isAdmin) {
      normalizedData = makeNormalizesPlantsWhenAdmin(replicationData);
    } else {
      const normalizedSPOCPlants = getSpocPlants(
        [...makeReplicationsData, ...makeProjectReplicationsData],
        session
      );
      normalizedData = makeNormalizedFilteredPlants(
        normalizedSPOCPlants,
        projectPlant,
        replicationData
      );

      if (!normalizedData.length && normalizedSPOCPlants.length) {
        showAllReplicated = true;
      }
    }

    let preNormalizedData = normalizedData;

    if (!showAllReplicated) {
      preNormalizedData = normalizedData.map((plant) => ({
        ...plant,
        state: plant.state || "possible_replication",
        areas: plant.areas.map((area) => ({
          ...area,
          state: area.state || "possible_replication",
        })),
      }));
    }

    popup.show({
      title: "Possible Plants + Areas",
      component: (
        <ForPossibleReplicationsModal
          instance="Project"
          replicationsData={preNormalizedData}
          handleSave={handleAddNewReplications}
          data={project}
          filterBy={filterBy}
          combinedPlants={filterBy}
          showAllReplicatedMsg={showAllReplicated}
        />
      ),
    });
  };

  const makeReplicationsData = useMemo(() => {
    return getNormalizedReplicationsData(replicationData);
  }, [replicationData]);

  const makeProjectReplicationsData = useMemo(() => {
    return getNormalizedProjectReplicationsData(replicationData);
  }, [replicationData]);

  const handleShowMore = () => {
    history.push(`/projects/${project.id}/plants`);
  };

  const rightContent = useCallback(() => {
    return (
      <div
        className="d-flex justify-content-center cursor-pointer "
        onClick={() => setExpanded((prev) => !prev)}
      >
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(
            styles.icon,
            expanded ? styles.collapse : styles.collapseUp
          )}
        />
      </div>
    );
  }, [setExpanded, expanded]);

  const rightContentSecond = useCallback(() => {
    if (
      !replicationData ||
      (!isAdmin && !session?.spoc && !session?.innovation_leader)
    )
      return null;

    return (
      <div className="cursor-pointer" onClick={handleEdit}>
        <Icon {...mdSize} icon="icn-add" className={styles.addNew} />
      </div>
    );
  }, [
    replicationData,
    projectPlant,
    isAdmin,
    session?.spoc,
    session?.innovation_leader,
  ]);

  const renderAreas = useCallback(
    (obj, showCount) => {
      if (!obj?.areas?.length) return null;

      return (
        <div className="d-flex flex-column mt-2">
          <ul className="pl-4 mb-0">
            {obj.areas.map((area, index) => (
              <li key={`${area.id}_${index}`} className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    {area.status && showCount && (
                      <span className={styles.number}>{area.count}.</span>
                    )}
                    <Tooltip title={area.reason} placement="top">
                      <span>{area.name}</span>
                    </Tooltip>
                  </div>
                  <Tooltip
                    title={tooltipTextsByState[area.status]}
                    placement="top"
                  >
                    <div
                      className={classNames(
                        styles.plantCircle,
                        styles[area.status]
                      )}
                    ></div>
                  </Tooltip>
                </div>
                {area.project?.id && (
                  <div className="pl-3">
                    <Link to={`/projects/${area.project.id}/overview`}>
                      {area.project.name}
                    </Link>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      );
    },
    [tooltipTextsByState, replicationData]
  );

  return (
    <Pannel
      title="Replication Status"
      rightContent={rightContent()}
      padding="0"
      withCollapse
      expanded={expanded}
    >
      <div
        className={`${styles.panelHeader} align-items-center justify-content-between`}
      >
        <span>Plants</span>
        {rightContentSecond()}
      </div>
      <div className={classNames(styles.wrapper)}>
        {!replicationData ? (
          <LoadingComponent customText="Fetching data..." />
        ) : (
          <div className="d-flex flex-column p-1">
            {makeProjectReplicationsData.length ? (
              <div className="d-flex justify-content-center">
                <h5>Replications</h5>
              </div>
            ) : null}
            {makeProjectReplicationsData.length ||
            makeReplicationsData.length ? (
              <div className="d-flex justify-content-end">
                <div className="col-4 d-flex flex-column align-items-end pr-0">
                  <span>Status</span>
                </div>
              </div>
            ) : null}
            {replicationData.original_project && (
              <div className="mb-3">
                <div className="d-flex align-items-center">
                  <div className="col-8 d-flex flex-row text-center pl-0">
                    <span className={styles.number}>1. </span>
                    <Tooltip
                      title={replicationData.original_project.plant.reason}
                      placement="top"
                    >
                      <span className="font-weight-bold text-left">
                        {replicationData.original_project.plant?.name ||
                          "No Plant"}
                      </span>
                    </Tooltip>
                  </div>
                  {replicationData.original_project?.status &&
                    !replicationData.original_project?.plant?.area && (
                      <div className="col-4 d-flex flex-column align-items-end pr-0">
                        <Tooltip
                          title={
                            tooltipTextsByState[
                              replicationData.original_project?.status
                            ]
                          }
                          placement="top"
                        >
                          <div
                            className={classNames(
                              styles.plantCircle,
                              styles[replicationData.original_project?.status]
                            )}
                          ></div>
                        </Tooltip>
                      </div>
                    )}
                </div>
                {replicationData.original_project?.plant?.area ? (
                  <div className="d-flex flex-column ml-2">
                    <ul className="pl-3 mb-0">
                      <li className="d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between">
                          <Tooltip
                            title={
                              replicationData.original_project?.plant.area
                                .reason
                            }
                            placement="top"
                          >
                            <span>
                              {
                                replicationData.original_project?.plant?.area
                                  .name
                              }
                            </span>
                          </Tooltip>
                          <Tooltip
                            title={
                              tooltipTextsByState[
                                replicationData.original_project?.status
                              ]
                            }
                            placement="top"
                          >
                            <div
                              className={classNames(
                                styles.plantCircle,
                                styles[replicationData.original_project?.status]
                              )}
                            ></div>
                          </Tooltip>
                        </div>
                        <Link
                          className="font-weight-bold"
                          to={`/projects/${replicationData.original_project.id}/overview`}
                        >
                          {replicationData.original_project.name}
                        </Link>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="ml-4">
                    <Link
                      className="font-weight-bold text-left"
                      to={`/projects/${replicationData.original_project.id}/overview`}
                    >
                      {replicationData.original_project.name}
                    </Link>
                  </div>
                )}
              </div>
            )}
            {makeProjectReplicationsData.length ? (
              <>
                {makeProjectReplicationsData.map((obj) => (
                  <div key={obj.id} className="d-flex flex-column mb-2">
                    <div className="d-flex align-items-center">
                      <div className="col-8 d-flex flex-row text-center pl-0">
                        {obj.status && (
                          <span className={styles.number}>{obj.count}.</span>
                        )}
                        <Tooltip title={obj.reason} placement="top">
                          <span className="font-weight-bold text-left">
                            {obj.name}
                          </span>
                        </Tooltip>
                      </div>
                      {obj.status && (
                        <div className="col-4 d-flex flex-column align-items-end pr-0">
                          <Tooltip
                            title={tooltipTextsByState[obj.status]}
                            placement="top"
                          >
                            <div
                              className={classNames(
                                styles.plantCircle,
                                styles[obj.status]
                              )}
                            ></div>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    {obj.project?.id && (
                      <div className="ml-4">
                        <Link
                          className="font-weight-bold text-left"
                          to={`/projects/${obj.project.id}/overview`}
                        >
                          {obj.project.name}
                        </Link>
                      </div>
                    )}
                    {renderAreas(obj, true)}
                  </div>
                ))}
                <hr className="mb-2" />
              </>
            ) : null}
            {makeReplicationsData?.length ? (
              <>
                <div className="d-flex justify-content-center">
                  <h5>Possible Replications</h5>
                </div>
                {makeReplicationsData.slice(0, 9).map((obj) => (
                  <div key={obj.id} className="d-flex flex-column mb-2">
                    <div className="d-flex align-items-center">
                      <div className="col-8 d-flex flex-column text-center pl-0">
                        <Tooltip title={obj.reason} placement="top">
                          <span className="font-weight-bold text-left">
                            {obj.plant?.name || obj.name}
                          </span>
                        </Tooltip>
                      </div>
                      {obj.status && (
                        <div className="col-4 d-flex flex-column align-items-end pr-0">
                          <Tooltip
                            title={tooltipTextsByState[obj.status]}
                            placement="top"
                          >
                            <div
                              className={classNames(
                                styles.plantCircle,
                                styles[obj.status]
                              )}
                            ></div>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    {obj.project?.id && (
                      <div className="ml-4">
                        <Link
                          className="font-weight-bold text-left"
                          to={`/projects/${obj.project.id}/overview`}
                        >
                          {obj.project.name}
                        </Link>
                      </div>
                    )}
                    {renderAreas(obj)}
                  </div>
                ))}
                {makeReplicationsData.length > 10 ? (
                  <div className="d-flex justify-content-center mt-2">
                    <span
                      className="small cursor-pointer text-muted"
                      onClick={handleShowMore}
                    >
                      show more
                    </span>
                  </div>
                ) : null}
              </>
            ) : null}
            {!makeReplicationsData?.length &&
            !makeProjectReplicationsData?.length ? (
              <div className="mt-3 mb-3 ml-3">No replication data yet</div>
            ) : null}
          </div>
        )}
      </div>
    </Pannel>
  );
};

export default ReplicationsStatus;

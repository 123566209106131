import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { CheckBox, CheckType } from "modules/primitives/index";
import styles from "./styles.module.scss";

const CustomFieldAgreementTypes = (props) => {
  const {
    initialAgreementType,
    handleSelection,
    checked,
    mode,
    existingRecords,
    setFieldValue,
    selectedCompanyAgreements,
  } = props;

  const [disabledCheckbox, setDisableCheckbox] = useState(false);

  const [agreementType, setAgreementType] = useState("");
  useState("");
  useEffect(() => {
    if (initialAgreementType) {
      selectedCompanyAgreements.forEach((checkedType) => {
        if (mode === "edit" && checked === initialAgreementType.id) {
          return;
        }
        if (checkedType === initialAgreementType.id) {
          setDisableCheckbox(true);
        }
      });
      setAgreementType(initialAgreementType);
    }
  }, [initialAgreementType]);

  useEffect(() => {
    if (mode === "edit" && existingRecords > 0) {
      setDisableCheckbox(true);
    }
  }, [mode]);
  return (
    <div>
      <div className={styles.agreementTypeContainer}>
        <div className={styles.checkItem} key={agreementType?.id}>
          <CheckBox
            checkType={disabledCheckbox ? CheckType.DISABLED : CheckType.BLUE}
            isChecked={checked === agreementType.id}
            disabled={disabledCheckbox}
            readOnly={disabledCheckbox}
            onChange={() => {
              handleSelection(agreementType);
              setFieldValue(
                "agreement_type_id",
                checked === agreementType.id ? null : agreementType?.id
              );
            }}
          />

          <div
            className={classNames(
              disabledCheckbox ? styles.disabledCheckbox : styles.checkLabel
            )}
          >
            {agreementType.name}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomFieldAgreementTypes;

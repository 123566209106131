export const THEME_POST_EDIT_ATTEMPT = "THEME_POST_EDIT_ATTEMPT";
export const themesEditPostAttempt = (payload) => ({
  type: THEME_POST_EDIT_ATTEMPT,
  payload,
});

export const THEME_POST_EDIT_SUCCESS = "THEME_POST_EDIT_SUCCESS";
export const themesEditPostSuccess = (payload) => ({
  type: THEME_POST_EDIT_SUCCESS,
  payload,
});
export const THEME_POSTS_GET_ATTEMPT = "THEME_POSTS_GET_ATTEMPT";
export const themesPostsGetAttempt = (payload) => ({
  type: THEME_POSTS_GET_ATTEMPT,
  payload,
});
export const THEME_POSTS_GET_SUCCESS = "THEME_POSTS_GET_SUCCESS";
export const themesPostsGetSuccess = (payload) => ({
  type: THEME_POSTS_GET_SUCCESS,
  payload,
});

export const THEME_POST_ADD_ATTEMPT = "THEME_POST_ADD_ATTEMPT";
export const themesPostAddAttempt = (payload) => ({
  type: THEME_POST_ADD_ATTEMPT,
  payload,
});
export const THEME_POST_ADD_SUCCESS = "THEME_POST_ADD_SUCCESS";
export const themesPostAddSuccess = (payload) => ({
  type: THEME_POST_ADD_SUCCESS,
  payload,
});

export const THEME_POST_DELETE_ATTEMPT = "THEME_POST_DELETE_ATTEMPT";
export const themesPostDeleteAttempt = (payload) => ({
  type: THEME_POST_DELETE_ATTEMPT,
  payload,
});
export const THEME_POST_DELETE_SUCCESS = "THEME_POST_DELETE_SUCCESS";
export const themesPostDeleteSuccess = (payload) => ({
  type: THEME_POST_DELETE_SUCCESS,
  payload,
});

export const IS_FEED_OPENED = "IS_FEED_OPENED";
export const setIsFeedOpened = (payload) => ({
  type: IS_FEED_OPENED,
  payload,
});

export const GET_FEED_ATTEMPT = "GET_FEED_ATTEMPT";
export const getFeedAttempt = (payload) => ({
  type: GET_FEED_ATTEMPT,
  payload,
});

export const GET_FEED_SUCCESS = "GET_FEED_SUCCESS";
export const getFeedSuccess = (payload) => ({
  type: GET_FEED_SUCCESS,
  payload,
});

export const LIKE_POST_ATTEMPT = "LIKE_POST_ATTEMPT";
export const likePostAttempt = (payload) => ({
  type: LIKE_POST_ATTEMPT,
  payload,
});

export const LIKE_POST_SUCCESS = "LIKE_POST_SUCCESS";
export const likePostSuccess = (payload) => ({
  type: LIKE_POST_SUCCESS,
  payload,
});

export const DISLIKE_POST_ATTEMPT = "DISLIKE_POST_ATTEMPT";
export const dislikePostAttempt = (payload) => ({
  type: DISLIKE_POST_ATTEMPT,
  payload,
});

export const DISLIKE_POST_SUCCESS = "DISLIKE_POST_SUCCESS";
export const dislikePostSuccess = (payload) => ({
  type: DISLIKE_POST_SUCCESS,
  payload,
});

export const SELECT_POST_AS_FAVORITE_ATTEMPT =
  "SELECT_POST_AS_FAVORITE_ATTEMPT";
export const selectPostAsFavoriteAttempt = (payload) => ({
  type: SELECT_POST_AS_FAVORITE_ATTEMPT,
  payload,
});

export const SELECT_POST_AS_FAVORITE_SUCCESS =
  "SELECT_POST_AS_FAVORITE_SUCCESS";
export const selectPostAsFavoriteSuccess = (payload) => ({
  type: SELECT_POST_AS_FAVORITE_SUCCESS,
  payload,
});

export const DESELECT_POST_AS_FAVORITE_ATTEMPT =
  "DESELECT_POST_AS_FAVORITE_ATTEMPT";
export const deselectPostAsFavoriteAttempt = (payload) => ({
  type: DESELECT_POST_AS_FAVORITE_ATTEMPT,
  payload,
});

export const DESELECT_POST_AS_FAVORITE_SUCCESS =
  "DESELECT_POST_AS_FAVORITE_SUCCESS";
export const deselectPostAsFavoriteSuccess = (payload) => ({
  type: DESELECT_POST_AS_FAVORITE_SUCCESS,
  payload,
});

export const THEME_ACROSS_POSTS_GET_ATTEMPT = "THEME_ACROSS_POSTS_GET_ATTEMPT";
export const themesAcrossPostsGetAttempt = (payload) => ({
  type: THEME_ACROSS_POSTS_GET_ATTEMPT,
  payload,
});
export const THEME_ACROSS_POSTS_GET_SUCCESS = "THEME_ACROSS_POSTS_GET_SUCCESS";
export const themesAcrossPostsGetSuccess = (payload) => ({
  type: THEME_ACROSS_POSTS_GET_SUCCESS,
  payload,
});

export const THEME_POSTS_MEMBERS_GET_ATTEMPT = "THEME_POSTS_MEMBERS_GET_ATTEMPT";
export const themesMembersGetAttempt = (payload) => ({
  type: THEME_POSTS_MEMBERS_GET_ATTEMPT,
  payload,
});
export const THEME_POSTS_MEMBERS_GET_SUCCESS = "THEME_POSTS_MEMBERS_GET_SUCCESS";
export const themesPostMembersGetSuccess = (payload) => ({
  type: THEME_POSTS_MEMBERS_GET_SUCCESS,
  payload,
});

export const THEME_POSTS_ACCROSS_MEMBERS_GET_ATTEMPT = "THEME_POSTS_ACCROSS_MEMBERS_GET_ATTEMPT";
export const themesMembersGetAccrossAttempt = (payload) => ({
  type: THEME_POSTS_ACCROSS_MEMBERS_GET_ATTEMPT,
  payload,
});
export const THEME_POSTS_ACCROSS_MEMBERS_GET_SUCCESS = "THEME_POSTS_MEMBERS_GET_SUCCESS";
export const themesPostMembersGetAcrossSuccess = (payload) => ({
  type: THEME_POSTS_MEMBERS_GET_SUCCESS,
  payload,
});

export const SEARCH_MENTIONS_GET_ATTEMPT = "SEARCH_MENTIONS_GET_ATTEMPT";
export const searchMentionsGetAttempt = (payload) => ({
  type: SEARCH_MENTIONS_GET_ATTEMPT,
  payload,
});
export const SEARCH_MENTIONS_GET_SUCCESS = "SEARCH_MENTIONS_GET_SUCCESS";
export const searchMentionsGetSuccess = (payload) => ({
  type: SEARCH_MENTIONS_GET_SUCCESS,
  payload,
});


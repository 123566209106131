import {
  GET_ACTUAL_SCORE_DATA,
  GET_ACTUAL_SCORE_DATA_SUCCESS,
  SET_ACTUAL_SCORE_CARD_FILTERS,
  GET_ACTUAL_SCORE_TABLE_DATA,
  GET_ACTUAL_SCORE_TABLE_DATA_SUCCESS,
  TOGGLE_ACTUAL_SCORE_TABLE,
  GET_POC_TABLE_DATA_SUCCESS,
  TOGGLE_ACTUAL_SCORE_TAB_FILTERS,
} from "./actualScoreCard.actions";
import { pageMeta } from "../../../../../common/httpCall";

const initState = {
  filters: {
    dateType: "allTime"
  },
  charts: {
    data: null,
    fetching: false,
  },
  pocData: {
    data: null,
    fetching: false,
  },
  tableData: {
    expandedKey: null,
    highLightColumn: null,
  },
  expandedFilters: false,
};

const actualScoreCardReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_ACTUAL_SCORE_TAB_FILTERS: {
      return {
        ...state,
        expandedFilters: !state.expandedFilters,
      }
    }

    case SET_ACTUAL_SCORE_CARD_FILTERS: {
      if (payload.reset) {
        return {
          ...state,
          filters: {
            dateType: "allTime",
          },
        }
      }

      return {
        ...state,
        filters: payload,
      }
    }

    case GET_ACTUAL_SCORE_DATA: {
      return {
        ...state,
        charts: {
          ...state.charts,
          fetching: true,
        }
      }
    }

    case GET_ACTUAL_SCORE_DATA_SUCCESS: {
      return {
        ...state,
        charts: {
          ...state.charts,
          data: payload.response,
          fetching: false,
        },
      }
    }

    case GET_POC_TABLE_DATA_SUCCESS: {
      return {
        ...state,
        pocData: {
          ...state.pocData,
          data: payload.response,
          fetching: false,
        },
      }
    }

    case GET_ACTUAL_SCORE_TABLE_DATA: {
      return {
        ...state,
        tableData: {
          ...state.tableData,
          expandedKey: payload.reducerKey,
          [payload.reducerKey]: {
            fetching: true,
          },
        }
      }
    }

    case GET_ACTUAL_SCORE_TABLE_DATA_SUCCESS: {
      const getPageMeta = pageMeta(payload);

      return {
        ...state,
        tableData: {
          ...state.tableData,
          [payload.reducerKey]: {
            fetching: false,
            data: payload.response || [],
            pagination: {
              ...getPageMeta,
              current: getPageMeta.page,
            },
            highLightColumn: payload.highLightColumn,
          },
        }
      }
    }

    case TOGGLE_ACTUAL_SCORE_TABLE: {
      return {
        ...state,
        tableData: {
          ...state.tableData,
          expandedKey: state.tableData.expandedKey === payload.reducerKey ? null : payload.reducerKey,
          highLightColumn: state.tableData.expandedKey === payload.reducerKey ? null : payload.highLightColumn,
        }
      }
    }

    default:
      return state;
  }
};

export default actualScoreCardReducer;

import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import classNames from "classnames";
import html2pdf from "html2pdf.js/src";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  resetBeforeGettingBigData,
  searchInCouncil,
} from "modules/companies/companyMain/companyMain.action";

import Loading from "../../../../primitives/loading/loading.component";
import { Button, BtnType } from "../../../../primitives";
import styles from "./styles.module.scss";
import downloadTable from "../../../../../common/components/downloadReport/generateDocHelper";
import { useModelPopup } from "../../../../../common/hooks";
import ConfirmActionComponent from "../../../../../common/components/confirmAction/confirmAction.component";
import {
  convertFilterIndexToValues,
  queryParamStringToArray,
} from "../../../../../common/helper";

const CompaniesDownload = ({
  className,
  location,
  label,
  countryFilters,
  payload,
  setDownloadPdfIsActive,
  layout,
  showConfirmationPopup,
}) => {
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLoading = (loadingState) => {
    setLoading(loadingState);
  };

  const makeSimpleDownload = () => {
    document.body.style.cursor = "wait";
    setDownloadPdfIsActive(true);

    const elem = document.getElementById("downloadableSection");
    const pdfWidth = elem.offsetWidth + 15 * 2;
    const height = pdfWidth * 1.5 + 15 * 2;
    const pdfHeight = height < 2287 ? 2287 : height;
    const opt = {
      margin: [20, 20, 20, 20],
      filename: "traction-chart.pdf",
      image: { type: "png" },
      html2canvas: { useCORS: true, scale: 1 },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      jsPDF: {
        orientation: "p",
        unit: "pt",
        // format: 'a4',
        format: [pdfWidth, pdfHeight],
        putOnlyUsedFonts: true,
        compress: true,
      },
    };

    html2pdf()
      .set(opt)
      .from(elem)
      .save()
      .then(() => {
        closeSnackbar("downloadSnack");
        setDownloadPdfIsActive(false);
        document.body.style.cursor = "default";
      });
  };

  const getCompaniesWithParams = (total) => {
    dispatch(resetBeforeGettingBigData());
    enqueueSnackbar("Please wait a little bit for the data to be prepared!", {
      variant: "success",
      key: "downloadSnack",
      persist: true,
    });
    document.body.style.cursor = "wait";
    setDownloadPdfIsActive(true);

    const { state, search } = location;

    const queryParams = new URLSearchParams(search);
    const page = queryParams.get("page")
      ? Number(queryParams.get("page"))
      : state?.page
      ? Number(state?.page)
      : 1;
    const query = queryParams.get("query") || state?.query || "";
    const businessUnits = queryParamStringToArray(state?.businessUnits || "");
    const relationShipStatus = queryParamStringToArray(
      state?.relationShipStatus || ""
    );
    const investmentStages = queryParamStringToArray(
      state?.investmentStages || ""
    );
    const topics = queryParamStringToArray(state?.topic || "");
    const industries = queryParamStringToArray(state?.industry || "");
    const owners = queryParamStringToArray(state?.owners || "");
    const locationStrAsArray = queryParamStringToArray(state?.location || "");
    const agreements = queryParamStringToArray(state?.agreements || "");
    const idb_connection_options = queryParamStringToArray(
      state?.idbEcosystem || ""
    );
    const location_countries = queryParamStringToArray(state?.k_country || "");
    const custom_tags_ids = queryParamStringToArray(
      state?.custom_tags_ids || ""
    );
    const locationProps = {};

    if (locationStrAsArray.length) {
      const splitedValue = locationStrAsArray
        .map((str) => str.replace(/-/g, ","))[0]
        .split(",")[0]
        .split("#");
      const value = splitedValue[0];
      const type = splitedValue[1];

      if (splitedValue) {
        locationProps[`location_${type}`] = value;
      }
    }

    const payload = {
      cb: () => {
        const timeout = setTimeout(() => {
          makeSimpleDownload();
          clearTimeout(timeout);
        }, 2000);
      },
      data: query,
      topic_ids: topics,
      industry_ids: industries,
      relationship_stages: relationShipStatus,
      council_company_score_gte: state?.scoreFrom
        ? Number(state?.scoreFrom)
        : 0,
      council_company_score_lte: state?.scoreTo ? Number(state?.scoreTo) : 10,
      founded_gte: state?.foundedFrom ? state?.foundedFrom : null,
      founded_lte: state?.foundedTo ? state?.foundedTo : null,
      relationship_owners_ids: owners,
      num_employee_enum: state?.employees ? state?.employees : "",
      revenue_range: state?.estimatedRevenue ? state?.estimatedRevenue : "",
      lists: state?.lists ? state?.lists : "",
      total_founding_gte: state?.fundingFrom
        ? Number(state?.fundingFrom.replace(/\D/g, ""))
        : 0,
      total_founding_lte:
        state?.fundingTo && state?.fundingTo !== "Any Amount"
          ? Number(state?.fundingTo.replace(/\D/g, ""))
          : null,
      council_id: selectedCouncil.id,
      mode: "companies",
      page,
      items: total,
      withContext: true,
      sort_attribute: state?.sortAttribute || "",
      sort_order: state?.sortOrder || "",
      locationProps,
      agreements,
      enqueueSnackbar,
      location_countries,
      idb_connection_options,
      council_relationship_stage_id: relationShipStatus,
      business_unit_ids: businessUnits,
      theme_investment_stage_id: investmentStages,
      custom_tags_ids: convertFilterIndexToValues(
        custom_tags_ids,
        selectedCouncil?.council_custom_tag_groups
      ),
    };

    dispatch(searchInCouncil(payload));
  };

  const download = (type) => {
    if (type === "pdf") {
      if (showConfirmationPopup) {
        const number = layout === 0 ? 200 : 500;
        const text = `Your PDF will include the first ${number} results. If you need more than ${number}, please download as a CSV or Excel file. Do you want to proceed?`;

        popup.show({
          title: "Confirmation Prompt",
          component: (
            <ConfirmActionComponent
              confirmBtnName="DOWNLOAD"
              alertText={text}
              onConfirm={() => getCompaniesWithParams(number)}
            />
          ),
        });

        return;
      }

      makeSimpleDownload();

      return;
    }

    downloadTable(
      type,
      location,
      countryFilters,
      handleClose,
      "companies",
      payload,
      handleLoading
    );
  };

  return (
    <span className={classNames(className, styles.downloadBtnWrapper)}>
      {loading ? (
        <Loading />
      ) : (
        <Button
          btn={BtnType.FRAME_LESS}
          className={styles.download}
          icon="icn-export-button"
          onClick={handleClick}
          id="demo-positioned-button"
          aria-controls={openMenu ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? "true" : undefined}
        >
          {label || "Download Report"}
        </Button>
      )}

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => download("csv")}>DOWNLOAD CSV</MenuItem>
        <MenuItem onClick={() => download("xlsx")}>DOWNLOAD EXCEL</MenuItem>
        <MenuItem onClick={() => download("pdf")}>DOWNLOAD PDF</MenuItem>
      </Menu>
    </span>
  );
};

export default React.memo(CompaniesDownload);

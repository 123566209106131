import React, { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import styles from "modules/companies/companyMain/rightWindow/rightWindow.module.scss";
import CrunchBaseSearchComponent
  from "modules/companies/companyMain/rightWindow/crunchbaseSearch/crunchbaseSearch.component";
import SimpleSearchComponent from "modules/companies/companyMain/rightWindow/simpleSearch/simpleSearch.component";
import SaveSearchComponent from "modules/companies/companyMain/rightWindow/saveSearch";
import { useModelPopup } from "../../../../../common/hooks";
import { queryStringUrlReplacement } from "../../../../../common/helper";
import { clearCreatedList } from "modules/lists/store/lists.actions";
import { useCompanyContext } from "modules/companies/companyMain/providers/companyDataProvider";

const CompaniesSearchFieldsWrapper = ({ isFullWidth }) => {
  const {
    companiesReducer: {
      companyMainReducer: {
        selectedCompanies,
        checkedFilters,
      },
    },
  } = useSelector((state) => state);
  const {
    selectLayout,
    showCrunchBase,
    selectedTopics,
    selectedIndustries,
    handleCrunchBase,
    setSelectLayout,
    propValue,
    setDownloadPdfIsActive,
    selectModeIsActive,
  } = useCompanyContext();

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const popup = useModelPopup();

  const wrapperClass = useMemo(() => {
    return `${styles.searchWrapper} ${selectLayout === 2 ? "pt-5" : "pt-0"}`
  }, [selectLayout]);

  const handleSaveHide = () => {
    popup.hide();
    dispatch(clearCreatedList());
  };

  const handleSaveSearch = useCallback(() => {
    popup.show({
      title: "Add Company List",
      component: (
        <SaveSearchComponent
          query={location.state?.query}
          checkedFilters={checkedFilters}
          handleSaveHide={handleSaveHide}
        />
      ),
    });
  }, [checkedFilters, location.state?.query, handleSaveHide]);

  const handleLayoutClick = useCallback(
    (layout) => {
      setSelectLayout(layout);

      const search = queryStringUrlReplacement(
        history.location.search,
        "layout",
        layout
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location.state,
          layout,
        },
      });
    },
    [selectLayout]
  );

  return (
    <div className={wrapperClass}>
      {showCrunchBase ? (
        <CrunchBaseSearchComponent
          handleCrunchBase={handleCrunchBase}
          selectLayout={selectLayout}
          isFullWidth={isFullWidth}
          selectedTopics={selectedTopics}
          selectedIndustries={selectedIndustries}
        />
      ) : (
        <>
          <SimpleSearchComponent
            handleLayout={handleLayoutClick}
            layout={selectLayout}
            handleSaveSearch={handleSaveSearch}
            handleCrunchBase={handleCrunchBase}
            mode="companies"
            patientOrg={false}
            query={propValue}
            checkedFilters={checkedFilters}
            location={location}
            selectModeIsActive={selectModeIsActive}
            setDownloadPdfIsActive={setDownloadPdfIsActive}
            selectedCompanies={selectedCompanies}
          />
        </>
      )}
    </div>
  );
};

export default memo(CompaniesSearchFieldsWrapper);

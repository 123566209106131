import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import adminFuseHoc from "common/HOC/adminFuseHoc";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./styles.module.scss";
import {
  additionalFieldsRelationTypes,
  autoPopulateRelationalTypes,
  fieldToRemoveOnSyncOn,
  formInitialValues,
  formValidation,
} from "../adminApplicantForms.constants";
import {
  StepFourComponent,
  StepOneComponent,
  StepThreeComponent,
  StepTwoComponent,
} from "../formSteps";
import { BtnType, Button, Loading } from "../../../../primitives";
import {
  getApplicantForm,
  getFormCustomFields,
  updateApplicantForm,
} from "../adminApplicantForms.action";

const steps = [
  {
    title: "Edit Form",
    component: StepOneComponent,
  },
  {
    title: "Autopopulate Fields",
    component: StepTwoComponent,
  },
  {
    title: "Additional Fields",
    component: StepThreeComponent,
  },
  {
    title: "",
    component: StepFourComponent,
  },
];

const EditForm = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const formRef = useRef();
  const location = useLocation();
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);
  const currentValidationSchema = formValidation[currentStep];

  const isLastStep = currentStep === steps.length - 1;
  const isFirstStep = currentStep === 0;
  const isSubmitStep = currentStep === 2;

  const {
    councilReducer: { selectedCouncil },
    adminApplicantsFormsReducer: { form, getLoading },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(
      getApplicantForm({
        id: props.match.params.id,
        enqueueSnackbar,
      }),
    );

    dispatch(
      getFormCustomFields({
        enqueueSnackbar,
        query: {
          items: 10000,
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (form && formRef.current && selectedCouncil) {
      const formFields = form.applicant_form_fields.reduce(
        (
          acc,
          {
            is_required,
            applicant_available_form_field: field,
            applicant_available_form_field_id,
          },
        ) => {
          if (is_required) {
            acc.required_field_ids.push(applicant_available_form_field_id);
          }

          if (field.default) {
            acc.default_fields.push(applicant_available_form_field_id);
          } else {
            if (autoPopulateRelationalTypes.includes(field.relation_field)) {
              acc.auto_populate_fields.push(applicant_available_form_field_id);
            }
            if (additionalFieldsRelationTypes.includes(field.relation_field)) {
              acc.additional_fields.push(applicant_available_form_field_id);
            }
            if (
              form.sync_with_crunchbase
              && fieldToRemoveOnSyncOn.includes(field.field_name)
            ) {
              acc.additional_fields.push(applicant_available_form_field_id);
            }
          }

          return acc;
        },
        {
          default_fields: [],
          auto_populate_fields: [],
          additional_fields: [],
          required_field_ids: [],
        },
      );

      const newValues = {
        council_id: selectedCouncil.id,
        form_title: form.form_title,
        form_instruction_text:
          form.form_instruction_text || "Please fill out the information below",
        add_applicant_to_company_database:
          form.add_applicant_to_company_database,
        sync_with_crunchbase: form.sync_with_crunchbase,
        ...formFields,
        documents_attributes: form.documents || []
      };

      formRef.current.setValues(newValues);
    }
  }, [form, selectedCouncil]);

  useEffect(() => {
    if (location?.state?.step) {
      const { state } = location;
      setCurrentStep(location.state.step);
      delete state.step;
      history.replace({ ...history.location, state });
    }
  }, [location?.state]);

  const handleSubmit = async (values, actions) => {
    if (isSubmitStep) {
      const data = {
        council_id: selectedCouncil.id,
        form_title: values.form_title,
        form_instruction_text: values.form_instruction_text,
        add_applicant_to_company_database:
          values.add_applicant_to_company_database,
        sync_with_crunchbase: values.sync_with_crunchbase,
        selected_available_field_ids: [
          ...values.default_fields,
          ...values.auto_populate_fields,
          ...values.additional_fields,
        ],
        required_field_ids: values.required_field_ids || [],
        documents_attributes: values.documents_attributes || [],
      };

      dispatch(
        updateApplicantForm({
          id: form.id,
          data,
          callback: (status) => {
            if (status === "success") {
              enqueueSnackbar("Form saved with success", {
                variant: "success",
              });
              setCurrentStep(currentStep + 1);
            } else {
              enqueueSnackbar("Something went wrong.", {
                variant: "error",
              });
            }
          },
        }),
      );
    } else {
      setCurrentStep(currentStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleBackClick = () => {
    if (!isFirstStep) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const renderStepContent = (step, formikprops) => {
    if (steps[step]) {
      const CurrentStepComponent = steps[step].component;

      if (isLastStep) {
        if (!form) {
          return <Loading />;
        }
        return (
          <CurrentStepComponent
            formikprops={formikprops}
            action="create"
            form={form}
          />
        );
      }

      return <CurrentStepComponent formikprops={formikprops} />;
    }
    throw new Error("Component not found");
  };

  const renderForm = () => {
    if (getLoading) {
      return (
        <div className="col-md-12">
          <Loading />
        </div>
      );
    }
    if (!form) {
      return "";
    }

    return (
      <>
        <div className="col-12">
          <Formik
            innerRef={formRef}
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={handleSubmit}
          >
            {(formikprops) => (
              <Form id="addApplicantForm">
                {renderStepContent(currentStep, formikprops)}
              </Form>
            )}
          </Formik>
        </div>
        {!isLastStep && (
          <div className="col-12 mb-5">
            <div className="row justify-content-between">
              <div className="d-flex justify-content-start">
                {!isFirstStep && (
                  <Button
                    btn={BtnType.FRAME_LESS}
                    type="submit"
                    onClick={handleBackClick}
                  >
                    Back
                  </Button>
                )}
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  btn={BtnType.REGULAR}
                  type="submit"
                  onClick={handleNextClick}
                >
                  {isSubmitStep ? "Save" : "Next"}
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrp} />
      <div className={styles.container}>
        <div className="col-6">
          <div className="row">
            <div className="col-12">
              <div className={styles.captionStyle}>
                {`Step ${currentStep + 1} of ${steps.length}`}
              </div>
              <h4 className="bold">{steps[currentStep].title}</h4>
            </div>
            {renderForm()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(adminFuseHoc(EditForm));

import React from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import { Icon, smSize } from "../../../../../../common/icon";
import styles from "modules/ideasWrapper/ideaProfileWrapper/ideaProfileOverview/companies/companies.module.scss";
import { ideaProductDelete } from "modules/ideasWrapper/idea.action";
import { updateProductDetails } from "modules/companies/product/store/product.actions";

const ProductRelatedIdeas = ({ product, canEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteIdea = (idea) => {
    dispatch(
      updateProductDetails({
        ideas: product.ideas.filter(i => i.id !== idea.id),
      })
    );

    const payload = {
      product_id: product.id,
      idea_id: idea.id,
      enqueueSnackbar,
      cb: () => {
        enqueueSnackbar('Successfully deleted idea', {
          variant: 'success',
        });
      }
    };

    dispatch(ideaProductDelete(payload));
  };

  const handleIdeaRedirect = (idea) => {
    history.push(`/ideas/${idea.id}`);
  };

  return (
    <div className="p-4">
      {
        product.ideas.length ? (
          <div>
            {
              product.ideas.map(idea => (
                <div key={idea.id} className="mb-2 d-flex align-items-center justify-content-between">
                  <span
                    className="cursor-pointer"
                    onClick={() => handleIdeaRedirect(idea)}
                  >
                    {idea.name}
                  </span>
                  {canEdit && (
                    <Icon
                      {...smSize}
                      icon="icn-button-delete"
                      className={styles.deleteIcon}
                      onClick={() => handleDeleteIdea(idea)}
                    />
                  )}
                </div>
              ))
            }
          </div>
        ) : (
          <div className="d-flex align-items-center">
            No related Ideas yet.
          </div>
        )
      }
    </div>
  );
};

export default React.memo(ProductRelatedIdeas);

import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import styles from '../styles.module.scss';
import { Label, DropSelect, DatePiker } from "modules/primitives";
import { projectMetricFilters } from "modules/reporting/fordReports/utils";
// import TractionDateRange from "../../../../../../common/components/dateRange";

const FordProjectMetricsFilter = ({ handleFilterUpdate, reducer, setNewFilters }) => {
  const [fieldValues, setFieldValues] = useState({});

  useEffect(() => {
    setFieldValues(reducer.filters?.projectMetrics || {});
  }, [reducer.filters?.projectMetrics]);

  const updateFilters = useCallback((newValues) => {
    setNewFilters({
      ...reducer.filters,
      projectMetrics: newValues
    });

    handleFilterUpdate({projectMetrics: newValues}, reducer.tableData?.expandedKey);
  }, [reducer]);

  const handleFieldValueChange = (value, prop) => {
    if (!value.obj?.length) {
      if (fieldValues[prop]) {
        const newValues = {
          ...fieldValues,
          [prop]: null
        }

        setFieldValues(newValues);
        updateFilters(newValues);
      }

      return;
    }

    const newValues = {
      ...fieldValues,
      [prop]: value.obj,
    };

    setFieldValues(newValues);
    updateFilters(newValues);
  };

  const handleDateChange = (value, prop) => {
    const newValues = {
      ...fieldValues,
      [prop]: value,
    };

    setFieldValues(newValues);
    updateFilters(newValues);
  }

  return (
    projectMetricFilters.map(filter => (
      <div key={filter.id} className={classNames(styles.filter, 'mb-3')}>
        <Label>{filter.name}</Label>
        {
          filter.datePicker ? (
            <>
              {
                filter.dateRangePicker ? (
                  // <TractionDateRange />
                  <DateRangePicker
                    onChange={(val) => handleDateChange(val, filter.id)}
                    value={fieldValues[filter.id]}
                    calendarIcon={null}
                    clearIcon={null}
                    className={styles.dateRange}
                  />
                ) : (
                  <DatePiker
                    name={filter.id}
                    value={fieldValues[filter.id] || ''}
                    onChange={(e) => handleDateChange(e, filter.id)}
                    showTime
                    withClear
                  />
                )
              }
            </>
          ) : (
            <DropSelect
              multi={filter.id === 'next_stage_start_on'}
              values={fieldValues[filter.id] || []}
              onChange={(val) => handleFieldValueChange(val, filter.id)}
              options={filter.options}
              labelField="name"
              valueField="id"
              placeholder={filter.name}
              searchBy="name"
              clearable={false}
            />
          )
        }
      </div>
    ))
  );
};

export default React.memo(FordProjectMetricsFilter);

import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { empty } from "rxjs";

import {
  LOGIN_ATTEMPT,
  loginSuccess,
  LOGOUT_ATTEMPT,
  logOutSuccess,
  FORGOT_ATTEMPT,
  forgotSuccess,
  GET_ME_ATTEMPT,
  GET_NEW_TOKEN,
  getNewTokenSuccess,
} from "./auth.action";
import {
  httpPost,
  httpDelete,
  apiStatus,
  errorHandler,
  httpGet,
} from "../../common/httpCall";

export const epicLogin = (action$) =>
  action$.pipe(
    ofType(LOGIN_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        call: "auth/sign_in",
        data,
      }).pipe(
        map((result) => loginSuccess({ ...result, isFirstSignIn: true })),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetNewToken = (action$) =>
  action$.pipe(
    ofType(GET_NEW_TOKEN),
    switchMap(() =>
      httpPost({
        call: "users/tokens",
        data: {},
      }).pipe(
        map((result) => getNewTokenSuccess(result)),
        catchError((err) => errorHandler(err))
      )
    )
  );

export const epicGetMe = (action$, state$) =>
  action$.pipe(
    ofType(GET_ME_ATTEMPT),
    switchMap(({ payload: { sessionId, enqueueSnackbar } }) =>
      httpGet({
        // call:"users/sign_in",
        call: `users/${sessionId}`, // NEW_API
      }).pipe(
        map((result) => loginSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicLogOut = (action$, state$) =>
  action$.pipe(
    ofType(LOGOUT_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpDelete({
        // call: "users/logout",
        call: "auth/sign_out", // NEW_API
      }).pipe(
        map((result) => {
          localStorage.removeItem("council");
          localStorage.removeItem("session");
          localStorage.removeItem("council_role");
          localStorage.removeItem("workOs");
          localStorage.clear();

          return logOutSuccess(result.response);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicForgot = (action$) =>
  action$.pipe(
    ofType(FORGOT_ATTEMPT),
    switchMap(({ payload }) =>
      httpPost({
        call: "auth/password", // NEW_API
        data: payload.data,
      }).pipe(
        map((result) => {
          if (result.status === apiStatus.SUCCESS) {
            payload.enqueueSnackbar(
              "Thank you! Your password is now reset. Please check your email for instructions on how to access your account.",
              {
                variant: "success",
              }
            );
            const timeout = setTimeout(() => {
              clearTimeout(timeout);
              window.location.href = "/login";
            }, 4000);
          } else if (result.status === apiStatus.MOVED_PERMANENTLY) {
            window.location.href = "sso-login-kyndryl";
          }

          return forgotSuccess(result);
        }),
        catchError(() => {
          payload.enqueueSnackbar(
            "Thank you! Please check your email for instructions on how to access your account.",
            {
              variant: "success",
            }
          );
          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            window.location.href = "/login";
          }, 4000);
          return empty();
        })
      )
    )
  );

const authEpic = combineEpics(
  epicGetMe,
  epicLogin,
  // epicRegister,
  epicLogOut,
  epicForgot,
  epicGetNewToken
);

export default authEpic;

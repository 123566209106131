import React, { memo } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { DropSelect } from "../../../../../primitives";
import { PIE_BREAKDOWN } from "../charts-constants";
import styles from "./filters.module.scss";

const PieFilters = ({ handleSelect }) => {
  const {
    companiesReducer: {
      companyMainReducer: {
        charts: { chartOptions },
      },
    },
  } = useSelector((state) => state);

  return (
    <div className="d-flex bubble-options">
      <div
        className={classNames(
          "d-flex flex-column custom-drop-width custom-drop-position",
          styles.chartsSelect,
        )}
      >
        <label className="mb-1" htmlFor="color">
          Breakdown
        </label>
        <DropSelect
          placeholder=""
          name="color"
          options={PIE_BREAKDOWN}
          labelField="name"
          valueField="type"
          searchBy="name"
          values={PIE_BREAKDOWN.filter((c) => c.type === chartOptions.color)}
          onChange={(e) => handleSelect("color", e.val)}
          dropdownPosition="auto"
        />
      </div>
    </div>
  );
};

export default memo(PieFilters);

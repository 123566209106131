import React, { useState, useEffect } from "react";
import classNames from "classnames";

import styles from "./slides.module.scss";
import { Pagination } from "../../../primitives";
import { pageSettings } from "../../events.constant";

const SlidesComponent = ({ event }) => {
  const [pageSetting, setPageSetting] = useState(pageSettings);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isEmbed, setIsEmbed] = useState(false);
  const [isDocument, setIsDocument] = useState(false);
  const [url, setUrl] = useState("");
  const allowedExtensions = ["pdf", "ppt", "pptx", "pps", "odp"];

  const handlePagingChange = (page) => {
    setCurrentSlide(page - 1);
    setPageSetting((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const resetPaging = () => {
    setPageSetting((prev) => ({
      ...prev,
      current: 0,
    }));
  };

  useEffect(() => {
    if (event && event.slides_uri) {
      setPageSetting({
        ...pageSetting,
        total: event.slides_uri.length,
        pageCount: 1,
      });
    }
  }, [event.slides_uri]);

  useEffect(
    () => () => {
      // temporary commented cause this causes empty event page after delete last slite in edit event
      // dispatch(clearSelectedEvent());
      resetPaging();
    },
    [],
  );

  const getUrlExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  const getPlatform = () => {
    const url = event.slides_uri[currentSlide];

    if (url.includes("iframe")) {
      return "iframe";
    }
    if (
      url.includes("https://www.slideshare.net/slideshow/embed_code/")
      || url.includes("https://docs.google.com/presentation/")
    ) {
      return "link";
    }
  };

  const getIframeSrc = (url) => {
    const match = /src\s*=\s*"([^"]+)"/i.exec(url);
    if (match) {
      return match[1];
    }
  };

  const getUrl = () => {
    if (getUrlExtension(event.slides_uri[currentSlide]) !== "pdf") {
      if (getPlatform() === "link") {
        return event.slides_uri && event.slides_uri[currentSlide];
      }
      if (getPlatform() === "iframe") {
        return getIframeSrc(event.slides_uri[currentSlide]);
      }
      return `https://view.officeapps.live.com/op/embed.aspx?src=${
        event.slides_uri && event.slides_uri[currentSlide]
      }`;
    }
    return event.slides_uri && event.slides_uri[currentSlide];
  };

  useEffect(() => {
    if (
      allowedExtensions.indexOf(
        getUrlExtension(event.slides_uri[currentSlide]),
      ) !== -1
      || getPlatform() === "link"
      || getPlatform() === "iframe"
    ) {
      setIsDocument(true);
      setUrl(getUrl());
    } else {
      setIsDocument(false);
      setUrl(getUrl());
    }
  }, [currentSlide]);

  return (
    <div className={styles.panelWrp}>
      <h4 className={styles.headerText}>Slides</h4>
      <div className={classNames(styles.panel, styles.slidePanel)}>
        {isEmbed ? (
          <div dangerouslySetInnerHTML={{ __html: url }} />
        ) : (
          <div className={styles.slideCon}>
            {isDocument ? (
              <iframe
                src={url}
                width="100%"
                height="500px"
                frameBorder="0"
                scrolling="auto"
                id="fred"
                title="Inline Frame Example"
              />
            ) : (
              <img src={event.slides_uri && event.slides_uri[currentSlide]} />
            )}
          </div>
        )}
        <div className={styles.paginationCon}>
          <Pagination {...pageSetting} onChange={handlePagingChange} />
        </div>
      </div>
    </div>
  );
};

export default SlidesComponent;

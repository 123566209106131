import * as Yup from "yup";

export const validation = Yup.object().shape({
  naem: Yup.string().required("member is required"),
});
export const model = {
  name: "",
  desccription: "",
  type: "",
};

export const typeSelectOptions = [
  { text: "Market Segment", value: "market_segment" },
  { text: "Technology", value: "technology" },
];

import React from "react";
import ContactNewComponent from "modules/people/externalPeople/contactNew/index";
import NewItemComponent from "../addNew/index";
import { Button, BtnType } from "../../../../primitives/index";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../common/hooks";

import { ModalFooter, ModalBody } from "../../../../../application/modal";
import AddMSFTContacts from "../AddMSFTContacts/index";
import AddMSFTExternalContacts from "../AddMSFTExternalContacts/index";
import EditRelationship from "../../../../../common/components/editRelationship/index";
import { useWindowSize } from "common/hooks/useWindowSize";

const NewContactMSFTChoice = (props) => {
  const { isMobile } = useWindowSize();
  const popup = useModelPopup();
  const { contactType, cb, isClaimProfileUse } = props;
  const microsoftLogin =
    JSON.parse(localStorage.getItem("microsoft_login")) || false;

  const handleCancelClick = (e) => {
    if (cb) {
      cb(true);
    }
    popup.hide();
  };

  const showMicrosoftModal = () => {
    if (contactType === "internal_contact") {
      popup.show({
        title: "Add New Company Contact",
        component: <AddMSFTContacts cb={cb} type="contact" />,
      });
    } else if (contactType === "external_contact") {
      popup.show({
        title: "Add New Company Contact",
        component: <AddMSFTExternalContacts cb={cb} />,
      });
    } else if (contactType === "relationship_owner") {
      popup.show({
        title: "Add New Relationship Owner",
        component: <AddMSFTContacts cb={cb} type="relationship" />,
      });
    }
  };

  const showAddContactModal = () => {
    if (contactType === "internal_contact") {
      popup.show({
        title: "Add New Company Contact",
        component: (
          <NewItemComponent
            cb={cb}
            type="contact"
            isClaimProfileUse={isClaimProfileUse}
          />
        ),
      });
    } else if (contactType === "external_contact") {
      popup.show({
        title: "Add New Company Contact",
        component: <ContactNewComponent cb={cb} />,
      });
    } else if (contactType === "relationship_owner") {
      popup.show({
        title: "Add New Relationship Owner",
        component: <EditRelationship cb={cb} mode="add" />,
      });
    }
  };

  return (
    <div>
      <ModalBody>
        <div className={styles.wrapper}>
          {microsoftLogin ? (
            <div
              className={`${
                isMobile ? "d-flex flex-column" : "d-flex align-items-center"
              }`}
            >
              <Button onClick={showAddContactModal}>Add from Traction</Button>
              <Button className={styles.btnAdd} onClick={showMicrosoftModal}>
                Add from Microsoft
              </Button>
            </div>
          ) : (
            showAddContactModal()
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
      </ModalFooter>
    </div>
  );
};

export default React.memo(NewContactMSFTChoice);

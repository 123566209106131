import React, { useEffect, useState } from "react";
import { format } from "date-fns";

import { DatePiker } from "modules/primitives";

const defDate = format(new Date(), 'yyyy-MM-dd')

const DateTableCustomField = ({ row, field, fieldCoreDetails, updateCellCallback }) => {
  const [value, setValue] = useState(defDate);
  const [editActive, setEditActive] = useState(false);

  useEffect(() => {
    if (field.field_value?.length) {
      setValue(field.field_value);
    }
  }, [field.field_value]);

  useEffect(() => {
    if (row.editMode || !field.field_value?.length || row.isTouched) {
      setEditActive(true);
      return;
    }

    setEditActive(false);
  }, [row, field]);

  const handleChange = e => {
    setValue(e);

    if (updateCellCallback) {
      updateCellCallback({ ...field, field_value: e });
    }
  }

  return (
    <div>
      {
        editActive ? (
          <div style={{maxWidth: '200px'}}>
            <DatePiker
              name="field_value"
              value={value}
              onChange={handleChange}
              showTime
              withClear
            />
          </div>
        ) : (
          <div>
            {value.length ? format(new Date(value), "EEEE, MMMM dd, yyyy") : '-'}
          </div>
        )
      }
    </div>
  );
};

export default React.memo(DateTableCustomField);

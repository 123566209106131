import React from "react";
import styles from "./companyRatings.module.scss";
import { CompanyAvatar } from "../../../../../primitives";

const CompanyRatingsHeaderComponent = ({ company }) => {
  return (
    <div className={styles.headerWrp}>
      <div className={styles.companyCon}>
        <div className={styles.companyIcon}>
          <CompanyAvatar
            className={styles.logo}
            imgSrc={company.logo}
            name={company.name}
            hasBorderRadius
          />
        </div>
        <div className={styles.companyName}>
          <h4 className="medium">{company.name}</h4>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CompanyRatingsHeaderComponent);

import React, { useState, useEffect, useRef } from "react";
import Icon from "common/icon/icon.component";
import CloseIcon from "common/icon/closeIcon.component";

import { smSize } from "common/icon/icon.constants";
import { Label } from "modules/primitives/index";
import styles from "./styles.module.scss";

export const DropSelectWithInput = (props) => {
  const {
    selectedValue,
    setSelectedValue,
    options,
    searchValue = "",
    searchFn,
    themeMembers = [],
    diplayPosts,
    setSelectedAuthor = () => {},
    author,
    homePageUse,
    setAllMode,
    themePageUse,
  } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [authors, setAuthors] = useState([]);

  const wrapperRef = useRef(null);

  const searchAuthors = () => {
    const filtredAuthors = [];
    themeMembers.map((author) => {
      if (
        author.name.toLowerCase().indexOf(searchValue.toLowerCase().trim()) !==
        -1
      ) {
        return filtredAuthors.push(author);
      }
      return false;
    });
    return filtredAuthors;
  };

  const setStylesForFIlter = () => {
    if (homePageUse && window.screen.width < 550) {
      return { marginTop: 42 };
    }
    if (themePageUse) {
      return { marginTop: 300 };
    }
    return { marginTop: 30 };
  };
  const setStylesForInputContainer = () => {
    if (homePageUse) {
      return { display: "block" };
    }
    if (themePageUse) {
      return { justifyContent: "center" };
    }
    // return { marginTop: 30 };
  };

  const handleOptionClick = (option) => {
    if (option.value === "my_themes") {
      setAllMode(false);
    }
    setSelectedValue(option.value);
    setShowDropdown(false);
    setSelectedAuthor(false);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (searchValue.length > 0) {
      setAuthors(searchAuthors());
    } else {
      setAuthors([]);
    }
  }, [searchValue]);
  return (
    <div
      ref={wrapperRef}
      style={setStylesForInputContainer()}
      className={styles.inputContainer}
    >
      {author && (
        <div
          style={homePageUse && { height: 42 }}
          className={styles.chartTagsList}
        >
          <div className={styles.tag}>
            {author.name}
            <span
              className={styles.tagDelete}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedValue("all");
                setSelectedAuthor(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
      )}
      <div
        style={homePageUse && { marginTop: 12 }}
        className={styles.inputBox}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <div>{selectedValue?.text || selectedValue?.name || ""} </div>
        <Icon
          style={showDropdown ? { transform: "rotate(180deg)" } : null}
          {...smSize}
          icon="icn-table-down-chevron"
          className={styles.iconChevron}
        />{" "}
      </div>
      {showDropdown && (
        <ul
          style={setStylesForFIlter()}
          className={homePageUse && styles.dropdownOptions}
        >
          {options.map((option) =>
            option.type !== "search" ? (
              <li
                key={`${option.value}`}
                onClick={() => {
                  handleOptionClick(option);
                }}
              >
                {option.text}
              </li>
            ) : (
              <>
                <div className={styles.inputBlock}>
                  <Label> By Author</Label>
                  <input
                    key={`${option.value}`}
                    placeholder="Type to search"
                    onChange={(e) => searchFn(e.target.value)}
                  />
                </div>
              </>
            )
          )}
          {authors?.map((author) => (
            <li
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowDropdown(false);
                searchFn("");
                diplayPosts("author", author.id);
                setSelectedValue("author");
                setSelectedAuthor(author);
              }}
              key={`${author.id}`}
            >
              {author.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropSelectWithInput;

import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";

import { tractionAIMainStyles } from "modules/tractionAI/constants";
import { BtnType, Button } from "modules/primitives";
import styles from './styles.module.scss';
import TractionDownload from "../../../../../common/components/tractionDownload";
import { Icon, smSize } from "../../../../../common/icon";
import LoadingComponent from "modules/primitives/loading/loading.component";
import RecommendIdeas from "modules/tractionAI/screens/recommend/ideas/recommendIdeas";

const RecommendSlidesModal = ({ item, index }) => {
  const [open, setOpen] = useState(false);
  const [shouldDownload, setShouldDownload] = useState(false);

  const handleShowMore = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleDownload = () => {
    setShouldDownload(true);
  };

  const handleDownloadState = val => {
    setShouldDownload(val);
  };

  return (
    <>
      <Tooltip
        title="Download"
        placement="top"
      >
        <div className="cursor-pointer ml-2" onClick={handleShowMore}>
          <svg fill="#000000" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 512.001 512.001">
            <g>
              <g>
                <path d="M463.996,126.864L340.192,3.061C338.232,1.101,335.574,0,332.803,0H95.726C67.725,0,44.944,22.782,44.944,50.784v410.434
                    c0,28.001,22.781,50.783,50.783,50.783h320.547c28.001,0,50.783-22.781,50.783-50.783V134.253
                    C467.056,131.482,465.955,128.824,463.996,126.864z M343.254,35.678h0.001l88.126,88.126h-58.242
                    c-7.984,0-15.49-3.109-21.134-8.753c-5.643-5.643-8.752-13.148-8.751-21.131V35.678z M446.159,461.217
                    c-0.001,16.479-13.407,29.885-29.885,29.885H95.726c-16.479,0-29.885-13.406-29.885-29.885V50.784
                    c0.001-16.479,13.407-29.886,29.885-29.886h226.631v73.021c-0.002,13.565,5.28,26.318,14.872,35.909
                    c9.591,9.592,22.345,14.874,35.911,14.874h73.019V461.217z" />
              </g>
            </g>
            <g>
              <g>
                <path d="M375.123,214.719h-41.804v-41.805c0-5.771-4.678-10.449-10.449-10.449H136.877c-5.771,0-10.449,4.678-10.449,10.449
                      v185.992c0,5.771,4.678,10.449,10.449,10.449h41.804v41.805c0,5.771,4.678,10.449,10.449,10.449h185.992
                      c5.771,0,10.449-4.678,10.449-10.449V225.168C385.572,219.397,380.894,214.719,375.123,214.719z M147.326,348.457V183.363H312.42
                      v165.094H147.326z M364.674,400.711H199.58v-31.356h123.29c5.771,0,10.449-4.678,10.449-10.449V235.617h31.355V400.711z" />
              </g>
            </g>
            <g>
              <g>
                <path d="M276.851,123.804h-4.678c-5.771,0-10.449,4.678-10.449,10.449c0.001,5.771,4.679,10.449,10.449,10.449h4.678
                      c5.771,0,10.449-4.678,10.449-10.449C287.3,128.482,282.622,123.804,276.851,123.804z" />
              </g>
            </g>
            <g>
              <g>
                <path d="M238.369,123.804H136.877c-5.771,0-10.449,4.678-10.449,10.449s4.678,10.449,10.449,10.449h101.492
                      c5.771,0,10.449-4.678,10.449-10.449C248.818,128.482,244.14,123.804,238.369,123.804z" />
              </g>
            </g>
          </svg>
        </div>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        disableEscapeKeyDown
      >
        <DialogContent>
          <>
            <div className="d-flex align-items-center justify-content-end">
              <div style={{ ...tractionAIMainStyles.title, fontSize: "18px" }}>
                Traction AI
              </div>
              <Button disabled={shouldDownload} btn={BtnType.OUTLINE} className="ml-5" onClick={handleClose}>X</Button>
            </div>
            <>
              <div className="d-flex flex-column" id={`recommend_ideas_modal${index}`}>
                <div className="d-flex justify-content-center align-items-center mb-3">
                  <h4>Traction AI Recommended
                    Projects {item.isCompanySearch ? "similar to:" : "for:"} {item.searchByQuery}</h4>
                </div>
                <RecommendIdeas item={{
                  ...item,
                  doNotAnimate: true,
                }} index={index} />
              </div>
              <div className="d-flex justify-content-center">
                <div className="invisible" style={{ width: 0 }}>
                  <TractionDownload
                    hidePng
                    shouldDownloadPdf={shouldDownload}
                    handleDownloadState={handleDownloadState}
                    timeoutTime={100}
                    customId={`recommend_ideas_modal${index}`}
                    customName={`Traction-Report-Ideas_${item.isIdeasSearch ? item.searchByQuery : ''}`}
                  />
                </div>
                {
                  shouldDownload ? (
                    <LoadingComponent customText="Preparing pdf..." />
                  ) : (
                    <Button
                      btn={BtnType.FRAME_LESS}
                      onClick={handleDownload}
                    >
                      <div className="d-flex align-items-center">
                        <Icon
                          {...smSize}
                          icon="icn-export-button"
                          className={styles.download}
                        />
                        Download PDF
                      </div>
                    </Button>
                  )
                }
              </div>
            </>
          </>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default React.memo(RecommendSlidesModal);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import { checkViewerRole } from "common/checkers/viewerChecker";
import styles from "./stageCompanies.module.scss";
import {
  Button,
  BtnType,
  MoreOptions,
  CompanyAvatar,
} from "../../../../primitives";
import StageTaskComponent from "../stageTask";
import { stageCompanyOptions } from "../stages.constant";
import { sliderSetting } from "application/app.constants";
import { appSliderSettingSet } from "application/app.action";
import { projectCompanyChangeStatus } from "../../companies/companies.action";
import {
  CompanyRatings,
  CompanyRatingsHeader,
} from "../../companies/companyRatings";

const StageCompaniesComponent = (props) => {
  const { company, stage, patientOrg, handleCompanyRemove, isKyndryl } = props;
  const dispatch = useDispatch();
  const [showTask, setShowTask] = useState(false);
  const [currentCompanyId, setCurrentCompanyId] = useState(null);
  const {
    projectsReducer: {
      companyReducer: { companyList },
      projectReducer: { selectedProject },
    },
  } = useSelector((state) => state);
  const history = useHistory();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [options, setOptions] = useState(stageCompanyOptions);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
      const filteredOptions = stageCompanyOptions.filter(
        (option) => option.val !== "remove" && option.val !== "rate"
      );
      setOptions(filteredOptions);
    }
  }, []);

  useEffect(() => {
    if (company.id !== currentCompanyId) {
      setCurrentCompanyId(company.id);
      setShowTask(false);
    }
  }, [company]);

  const handleViewTaskClick = (e) => {
    setShowTask((prev) => !prev);
  };

  const showRatingWindow = () => {
    if (company.is_product) {
      return;
    }
    dispatch(
      appSliderSettingSet({
        ...sliderSetting,
        show: true,
        height: "300",
        width: "540",
        header: <CompanyRatingsHeader company={company} />,
        component: <CompanyRatings companyId={company.id} stageUse />,
      })
    );
  };

  const handleMoreOptionClick = (val) => {
    switch (val) {
      case "remove":
        dispatch(
          projectCompanyChangeStatus({
            is_product: company.is_product,
            projectId: selectedProject.id,
            companyId: company.id,
            state: "remove",
            gsk: patientOrg,
            patientOrg,
          })
        );
        handleCompanyRemove(company);
        break;
      case "rate":
        showRatingWindow();
        break;

      case "view":
        goToCompany();
        break;

      default:
    }
  };

  const goToCompany = () => {
    if (company.is_product) {
      history.push(
        `/companies/${company.company.slug || company.company.id}/products/${
          company.slug || company.id
        }`
      );
      return;
    }

    history.push(
      patientOrg
        ? `/patient_organizations/${company.slug || company.id}`
        : `/companies/${company.slug || company.id}`
    );
  };

  const organization = (obj) => {
    if (obj.gsk_patient_organization) {
      return obj.gsk_patient_organization;
    }
    return obj.company;
  };

  const gskScore = (obj) => {
    if (obj.normalized_gsk_score === 0) {
      return 0;
    }
    if (obj && obj.normalized_gsk_score) {
      return obj.normalized_gsk_score.toFixed(1);
    }
    return 0;
  };

  const currentCompanyScore =
    companyList &&
    companyList.find(
      (listCompany) => organization(listCompany).id === company.id
    )?.score;

  const currentCompanyState =
    companyList &&
    companyList.find(
      (listCompany) => organization(listCompany).id === company.id
    )?.state;

  return (
    company &&
    currentCompanyState !== "removed" && (
      <div className={styles.stageCompanyWrp}>
        <div className={styles.infoWrp}>
          <div className={styles.iconCon}>
            {company.is_product ? (
              <CompanyAvatar
                imgSrc={company.company.logo}
                name={company.company.name}
              />
            ) : (
              <CompanyAvatar imgSrc={company.logo} name={company.name} />
            )}
          </div>
          <div className={styles.title} onClick={() => goToCompany(company.id)}>
            <span>{company.name}</span>
            {company.is_product && (
              <span className="small text-muted">Product</span>
            )}
          </div>
          <div className={styles.iconMore}>
            <MoreOptions
              options={
                patientOrg && patientOrg
                  ? options.filter((e) => e.val !== "rate")
                  : options
              }
              className={styles.moreOptWrp}
              onClick={handleMoreOptionClick}
            />
          </div>
        </div>
        {!isKyndryl && !company.is_product && (
          <div className={classNames(styles.projectScore, "caption")}>
            {patientOrg && patientOrg ? (
              "Project Match: " + gskScore(company)
            ) : currentCompanyScore === 0 ? (
              <Tooltip
                title={
                  blockFunctionality
                    ? "You have View-Only access. To Rate in this Project please ask your Admin to upgrade your account to Standard access and invite you to this Project."
                    : ""
                }
                placement="top"
              >
                <div>
                  <Button
                    btn={!blockFunctionality ? BtnType.LINK : BtnType.DISABLED}
                    onClick={!blockFunctionality && showRatingWindow}
                  >
                    Rate Now
                  </Button>
                </div>
              </Tooltip>
            ) : (
              <span>
                Project Score:{" "}
                <span
                  className={styles.link}
                  onClick={!blockFunctionality && showRatingWindow}
                >
                  {currentCompanyScore}
                </span>{" "}
              </span>
            )}
          </div>
        )}
        {showTask ? (
          <StageTaskComponent
            show={showTask}
            startupId={company.id}
            stage={stage}
            company={company}
            patientOrg={patientOrg}
          />
        ) : null}
        {
          <div className={styles.veiwTask}>
            <Button btn={BtnType.FRAME_LESS} onClick={handleViewTaskClick}>
              {showTask ? "Hide Tasks" : "View Tasks"}
            </Button>
          </div>
        }
      </div>
    )
  );
};

export default React.memo(StageCompaniesComponent);

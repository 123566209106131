import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { gskOptionCountries as defaultCountries } from "../../../../common/constants";
import {
  setFilters,
} from "modules/companies/companyMain/companyMain.action";
import { sortCountries } from "../../../helper";
import { CheckBox, CheckType, Label } from "modules/primitives";

const ProjectCountries = ({ isAdminWizardInstance, formikprops }) => {
  const [select, setSelect] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAdminWizardInstance) return;

    const new_project = localStorage.getItem("new_project");
    const obj = JSON.parse(new_project);
    setSelect(obj?.countries || []);

    if (!obj.countries?.length) {
      onCheckChange(true, true);
    }
  }, []);

  const onCheckChange = (val, item) => {
    if (item) {
      if (item === "all") {
        if (val) {
          setSelect(() => [
            ...defaultCountries.map((country) => country.id),
          ]);
        } else {
          setSelect([]);
        }
      } else if (item && val) {
        setSelect((prev) => [...prev, item.id]);
        dispatch(
          setFilters({
            country_ids: select,
          })
        );
      } else {
        setIsAllSelected(false);
        setSelect((prev) => prev.filter((id) => id !== item.id));
        dispatch(
          setFilters({
            country_ids: select,
          })
        );
      }
    } else if (val) {
      if (!select.find((obj) => obj.id === item.id))
        setSelect((prev) => [...prev, item.id]);
      dispatch(
        setFilters({
          country_ids: select,
        })
      );
    } else {
      setSelect((prev) => prev.filter((id) => id !== item.id));
      dispatch(
        setFilters({
          country_ids: select,
        })
      );
    }
  };

  useEffect(() => {
    if (isAdminWizardInstance) return;

    const new_project = localStorage.getItem("new_project");
    let obj = JSON.parse(new_project);
    obj = { ...obj, countries: select };
    setIsAllSelected(select.length === defaultCountries.length);
    localStorage.setItem("new_project", JSON.stringify(obj));
  }, [Object.keys(select)]);

  const getSelected = useCallback(
    (id) => select.find((key) => key === id),
    [select]
  );

  useEffect(() => {
    if (isAdminWizardInstance) return;

    if (isAllSelected) {
      setSelect(defaultCountries.map((el) => el.id));
    }
  }, [isAllSelected]);

  const filteredTags = useMemo(() => {
    return defaultCountries.sort(sortCountries);
  }, [defaultCountries, sortCountries]);

  if (isAdminWizardInstance) {
    return (
      <div className="mb-2 d-flex flex-column align-items-baseline">
        <Label>Project Countries</Label>
        <p>Countries list component</p>
      </div>
    )
  }

  return (
    <div className={styles.inner}>
      <h4 className={classNames("bold", styles.innerTitle)}>
        Choose Country
      </h4>
      <div className={classNames("bold", styles.infoText)}>
        Use these country tags to generate a list of organizations for your project to address.
      </div>
      {filteredTags.length ? (
        <div className={styles.listWrp}>
          <div className="d-flex align-items-center">
            <CheckBox
              checkType={CheckType.BLUE}
              isChecked={isAllSelected}
              onChange={(val) => onCheckChange(val, "all")}
            />
            <Label>
              Select All
            </Label>
          </div>
          {filteredTags.map((item) => (
            <div key={item.id} className={styles.formElement}>
              <div className="d-flex align-items-center">
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={getSelected(item.id)}
                  onChange={(val) => onCheckChange(val, item)}
                />
                <div className={classNames(styles.tagStyle)}>
                  <Label>{item.name}</Label>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.noOptions}>
          There are no countries to choose from.
        </div>
      )}

      <div className="caption small text-danger">
        {formikprops.touched['gsk_project_country'] && formikprops.errors['gsk_project_country']
          ? formikprops.errors['gsk_project_country']
          : ""}
      </div>
    </div>
  );
};

export default React.memo(ProjectCountries);

import React, { memo, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import style from "./styles.module.scss";
import { Icon, smSize } from "../../../../common/icon";
import {
  queryStringUrlReplacement,
  scrollStop,
} from "../../../../common/helper";
import LoadingComponent from "../../../primitives/loading/loading.component";
import DoubleScrollbar from "../../../../common/components/horizontalDoubleSrollBar";
import PatientOrganizationItemWrapper from "modules/patientOrganizations/patientOrganizationMain/flatPatientOrgList/patientOrganizationItemWrapper";

const currentYear = new Date().getFullYear();

const FlatOrganizationsList = ({
  organizations = [],
  selectModeIsActive,
  handleOrganizationSelect,
  selectedOrganizations,
  downloadPdfIsActive,
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [sortingConfig, setSortingConfig] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [userIsScrolling, setUserIsScrolling] = useState(false);

  useEffect(() => {
    document.querySelector("#rightWindow").addEventListener(
      "scroll",
      () => {
        setUserIsScrolling(true);
      },
      false
    );

    scrollStop(
      "#rightWindow",
      () => {
        setUserIsScrolling(false);
      },
      1000
    );

    return () => {
      const elem = document.querySelector("#rightWindow");

      if (elem) {
        elem.removeEventListener("scroll", null);
      }
    };
  }, []);

  useEffect(() => {
    if (
      location.state?.sortOrder &&
      sortingConfig?.order !== location.state?.sortOrder
    ) {
      setShowLoading(true);
    } else {
      const timeout = setTimeout(() => {
        setShowLoading(false);
        clearTimeout(timeout);
      }, 1000);
    }
  }, [location.state?.sortAttribute, location.state?.sortOrder, sortingConfig]);

  useEffect(() => {
    setSortingConfig({
      activeColumn: location.state?.sortAttribute || null,
      order: location.state?.sortOrder || null,
    });
  }, [location.state?.sortAttribute, location.state?.sortOrder]);

  const drawSortingIcon = useCallback(
    (col) => {
      if (!sortingConfig) return null;

      if (sortingConfig?.activeColumn === col) {
        return (
          <>
            <div
              className={classNames(
                style.sortIcon,
                sortingConfig.order === "desc" && style.sortAsc
              )}
            >
              <Icon {...smSize} icon="icn-export-button" />
            </div>
            {showLoading && (
              <div className={style.columnStyle}>
                <LoadingComponent hideString />
              </div>
            )}
          </>
        );
      }
    },
    [sortingConfig, showLoading]
  );

  const makeSortingConfig = useCallback(
    (activeColumn) => {
      let config;

      if (sortingConfig && sortingConfig?.activeColumn === activeColumn) {
        if (sortingConfig.order === "desc") {
          config = {
            activeColumn,
            order: "asc",
          };
        } else {
          config = {
            activeColumn: "",
            order: "",
          };
        }
      } else {
        config = {
          activeColumn,
          order: "desc",
        };
      }

      let search = queryStringUrlReplacement(
        history.location.search,
        "sortAttribute",
        config.activeColumn
      );
      search = queryStringUrlReplacement(search, "sortOrder", config.order);

      history.push({
        pathname: "/patient_organizations",
        search,
        state: {
          ...history.location.state,
          sortAttribute: config.activeColumn,
          sortOrder: config.order,
        },
      });
    },
    [sortingConfig]
  );

  return (
    <div className={style.companiesListWrapper}>
      <DoubleScrollbar>
        <div className="d-flex flex-column">
          <div className={style.listHeader}>
            <span
              onClick={() => makeSortingConfig("name")}
              className={`${style.company} position-relative`}
            >
              Patient Organization
              {drawSortingIcon("name")}
            </span>
            <div className="d-flex flex-grow-1">
              <span
                onClick={() => makeSortingConfig("country")}
                className={`${style.headerCol} position-relative`}
              >
                Country
                {drawSortingIcon("country")}
              </span>
              <span
                onClick={() => makeSortingConfig("match_score")}
                className={`${style.headerCol} position-relative`}
              >
                Match
                {drawSortingIcon("match_score")}
              </span>
              <span
                onClick={() => makeSortingConfig("diseases_names")}
                className={`${style.headerCol} position-relative`}
              >
                Disease
                {drawSortingIcon("diseases_names")}
              </span>
              <span
                onClick={() => makeSortingConfig("geographic_reach")}
                className={`${style.headerCol} position-relative`}
              >
                Geographic Reach
                {drawSortingIcon("geographic_reach")}
              </span>
              <span
                onClick={() =>
                  makeSortingConfig("council_relationship_stage_position")
                }
                className={`${style.headerCol} ${style.stage} position-relative`}
              >
                Stage
                {drawSortingIcon("council_relationship_stage_position")}
              </span>
            </div>
          </div>
          {downloadPdfIsActive && selectedOrganizations?.length > 0
            ? selectedOrganizations.map((org, i, { length }) => (
                <PatientOrganizationItemWrapper
                  key={org.id}
                  currentYear={currentYear}
                  organization={org}
                  history={history}
                  dispatch={dispatch}
                  last={length - 1 === i}
                  userIsScrolling={userIsScrolling}
                  handleOrganizationSelect={() => handleOrganizationSelect(org)}
                />
              ))
            : organizations.map((org, i, { length }) => (
                <PatientOrganizationItemWrapper
                  key={org.id}
                  currentYear={currentYear}
                  organization={org}
                  history={history}
                  dispatch={dispatch}
                  last={length - 1 === i}
                  userIsScrolling={userIsScrolling}
                  selectModeIsActive={selectModeIsActive}
                  handleOrganizationSelect={() => handleOrganizationSelect(org)}
                  isSelected={selectedOrganizations.some(
                    (c) => c.id === org.id
                  )}
                />
              ))}
        </div>
      </DoubleScrollbar>
    </div>
  );
};

export default memo(FlatOrganizationsList);

import React from "react";
import { Switch, Route } from "react-router-dom";
import { withSnackbar } from "notistack";
import PatientOrganizationMainComponent from "./patientOrganizationMain/patientOrganizationMain.component";
import PatientOrganizationComponent from "./patientOrganization/patientOrganization.component";

const PatientOrganizationsComponent = () => (
  <Switch>
    <Route
      exact
      path="/patient_organizations"
      component={PatientOrganizationMainComponent}
    />
    <Route
      exact
      path="/patient_organizations/:id/*"
      component={PatientOrganizationComponent}
    />
    <Route
      exact
      path="/patient_organizations/:id"
      component={PatientOrganizationComponent}
    />
  </Switch>
);

export default React.memo(withSnackbar(PatientOrganizationsComponent));

import React from "react";
import { ModalFooter, ModalBody } from "../../../../application/modal/index";
import { useModelPopup } from "../../../../common/hooks";
import { Button, BtnType, Label } from "../../../primitives";

const ConfirmationModal = (props) => {
  const popup = useModelPopup();
  const handleCancelClick = () => {
    popup.hide();
  };
  const setIntegration = () => {
    props.handleIntegrate(props.service);
    popup.hide();
  };

  return (
    <>
      <div>
        <ModalBody>
          <Label>
            <h4>
              Please continue with your admin Microsoft Account to enable
              services for all the users.
            </h4>
          </Label>
        </ModalBody>
        <ModalFooter>
          <Button
            btn={BtnType.FRAME}
            className="mt-4 mr-1"
            onClick={setIntegration}
          >
            Connect
          </Button>
          <Button
            btn={BtnType.FRAME}
            className="mt-4 ml-1"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </ModalFooter>
      </div>
    </>
  );
};

export default React.memo(ConfirmationModal);

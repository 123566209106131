import React, { useState, useEffect } from "react";
import { Formik } from "formik";

import styles from "./ratingAddNotes.module.scss";
import { Button, BtnType, TextArea } from "../../../../../primitives";
import { initModel, validation } from "./ratingNotes.constants";

const RatingAddNotesComponent = ({ onAddEdit, selectedNote }) => {
  const [model, setmodel] = useState(initModel);

  useEffect(() => {
    if (selectedNote && selectedNote.id) setmodel(selectedNote);
  }, [selectedNote]);

  const handleOnSubmit = (value) => {
    onAddEdit(value);

    if (value.id) {
      setmodel(initModel);
    }
  };

  return (
    <div className={styles.noteCon}>
      <Formik
        enableReinitialize
        initialValues={model && model}
        validationSchema={validation && validation}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleOnSubmit(values);
          resetForm();
        }}
      >
        {({ handleSubmit, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <TextArea
              name="note"
              placeholder="Please share some pros and cons for this company"
              className={styles.textBox}
              formProps={formikprops}
            />
            <Button btn={BtnType.REGULAR}>Add Note</Button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(RatingAddNotesComponent);

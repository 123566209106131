export const TOGGLE_ACTUAL_SCORE_TAB_FILTERS = 'TOGGLE_ACTUAL_SCORE_TAB_FILTERS'
export const toggleActualScoreTabFilters = (payload) => ({
  type: TOGGLE_ACTUAL_SCORE_TAB_FILTERS,
  payload,
});

export const SET_ACTUAL_SCORE_CARD_FILTERS = 'SET_ACTUAL_SCORE_CARD_FILTERS'
export const setActualScoreCardFilters = (payload) => ({
  type: SET_ACTUAL_SCORE_CARD_FILTERS,
  payload,
});

export const GET_POC_TABLE_DATA = 'GET_POC_TABLE_DATA'
export const getPocTableData = (payload) => ({
  type: GET_POC_TABLE_DATA,
  payload,
});

export const GET_POC_TABLE_DATA_SUCCESS = 'GET_POC_TABLE_DATA_SUCCESS'
export const getPocTableDataSuccess = (payload) => ({
  type: GET_POC_TABLE_DATA_SUCCESS,
  payload,
});

export const GET_ACTUAL_SCORE_DATA = 'GET_ACTUAL_SCORE_DATA'
export const getActualScoreData = (payload) => ({
  type: GET_ACTUAL_SCORE_DATA,
  payload,
});

export const GET_ACTUAL_SCORE_DATA_SUCCESS = 'GET_ACTUAL_SCORE_DATA_SUCCESS'
export const getActualScoreDataSuccess = (payload) => ({
  type: GET_ACTUAL_SCORE_DATA_SUCCESS,
  payload,
});

export const GET_ACTUAL_SCORE_TABLE_DATA = 'GET_ACTUAL_SCORE_TABLE_DATA'
export const getActualScoreTableData = (payload) => ({
  type: GET_ACTUAL_SCORE_TABLE_DATA,
  payload,
});

export const GET_ACTUAL_SCORE_TABLE_DATA_SUCCESS = 'GET_ACTUAL_SCORE_TABLE_DATA_SUCCESS'
export const getActualScoreTableDataSuccess = (payload) => ({
  type: GET_ACTUAL_SCORE_TABLE_DATA_SUCCESS,
  payload,
});

export const TOGGLE_ACTUAL_SCORE_TABLE = 'TOGGLE_ACTUAL_SCORE_TABLE'
export const toggleActualScoreTable = (payload) => ({
  type: TOGGLE_ACTUAL_SCORE_TABLE,
  payload,
});

import * as Yup from "yup";

export const customFieldsTypeOptions = [
  {
    text: "Text",
    value: "text",
  },
  {
    text: "Number",
    value: "number",
  },
  {
    text: "Date",
    value: "date",
  },
  {
    text: "Dropdown",
    value: "dropdown",
  },
  {
    text: "Checklist",
    value: "checklist",
  },
  // {
  //   text: 'Image',
  //   value: 'image',
  // },
];

export const createCustomFieldInitialValues = {
  field_type: "text",
  field_name: "",
  field_label: "",
  field_placeholder: "",
  big_box_text: false,
  max_words: 100,
};

export const createCustomFieldInitialValidationSchema = Yup.object().shape({
  field_type: Yup.string().required("Type is required"),
  field_name: Yup.string().required("Title is required"),
  option_values: Yup.array().when("field_type", {
    is: (val) => ["dropdown", "checklist"].includes(val),
    then: Yup.array()
      .of(Yup.string().required("Option is required"))
      .min(1, "Minimum one options")
      .required("Options are required"),
  }),
});

import React, { useCallback, useState } from "react";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import SCReview from "common/handleSCReview";

import styles from "./companyTopDetails.module.scss";
import { CompanyAvatar } from "../../../../primitives";
import { setUpdateChartListCompanyDetails } from "../../../companyMain/companyMain.action";
import { editDetails } from "../../company.action";
import { useModelPopup } from "../../../../../common/hooks";
import { useWindowSize } from "../../../../../common/hooks/useWindowSize";
import CompanyTopActions from "../companyTopActions/companyTopActions.component";
import checkThemePipelinePermissions from "../../../../../common/checkThemePipelinePermissions";

const CompanyTopDetails = ({ blockFunctionality, isAdmin }) => {
  const {
    authReducer: { session },
    appReducer: { relationShipStatuses },
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const { isMobile } = useWindowSize();
  const [relationship, setRelationship] = useState(
    selectedCompany?.council_relationship_stage || null
  );
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleDropdownSelect = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { value } = e.target;
    const getStageDetails = relationShipStatuses.find(
      (s) => s.id || s.slug === value
    );
    const checkPermission = checkThemePipelinePermissions(
      getStageDetails?.name || "none",
      session?.council_role,
      selectedCouncil?.name,
      session?.decision_maker_access,
      selectedCompany?.council_relationship_stage?.name || null
    );

    if (checkPermission) {
      const data = {
        council_relationship_stage_id: getStageDetails?.customId || null,
      };
      setRelationship(getStageDetails);

      dispatch(
        setUpdateChartListCompanyDetails({
          companyId: selectedCompany.id,
          newCompanyData: {
            council_relationship_stage_id: getStageDetails?.customId || null,
          },
        })
      );

      dispatch(
        editDetails({
          companyId: selectedCompany.id,
          councilId: selectedCouncil.id,
          data,
          enqueueSnackbar,
          council: selectedCouncil?.name,
          updateWithoutRequest: true,
        })
      );
    } else {
      enqueueSnackbar("You are not allowed to move companies in this stage ", {
        variant: "error",
      });
    }
  };

  const handleChangeStage = (e) => {
    if (selectedCouncil?.name === "Kyndryl" && e.target.value === "sc_review") {
      const { value } = e.target;
      const getStageDetails = relationShipStatuses.find(
        (s) => s.id || s.slug === value
      );
      const checkPermission = checkThemePipelinePermissions(
        getStageDetails?.name || "none",
        session?.council_role,
        selectedCouncil?.name,
        session?.decision_maker_access,
        selectedCompany?.council_relationship_stage?.name ?? null
      );

      if (!checkPermission) {
        handleDropdownSelect(e);
        return;
      }

      popup.show({
        title: " ",
        show: true,
        height: "300",
        width: "540",
        component: (
          <SCReview
            handleStageSelect={handleDropdownSelect}
            e={e}
            popup={popup}
            companySlug={selectedCompany?.slug}
            fromCompanyTopDetails
          />
        ),
      });
    } else {
      handleDropdownSelect(e);
    }
  };

  const getMenuItemStyles = useCallback(
    (targetStage) => {
      const checkPermission = checkThemePipelinePermissions(
        targetStage,
        session?.council_role,
        selectedCouncil?.name,
        session?.decision_maker_access,
        null
      );

      return !checkPermission ? { background: "#929ba4", color: "white" } : {};
    },
    [session, selectedCouncil]
  );
  const checkIfTractionMatchIsAllowed = useCallback(() => {
    if (selectedCouncil?.name === "Kyndryl") {
      return false;
    }
    if (selectedCompany.related_themes?.length > 0) {
      return false;
    }

    return true;
  });
  return (
    <div className={styles.companyTopDetails}>
      <div className={styles.logoWrp}>
        <div className={styles.logoCon}>
          <CompanyAvatar
            className={styles.logo}
            imgSrc={selectedCompany.logo}
            name={selectedCompany.name}
            hasBorderRadius
          />
        </div>
      </div>
      <div
        className={classNames(
          styles.detailsWrapper,
          isMobile ? styles.mobileDetailsWrapper : ""
        )}
      >
        <div
          className={`d-flex ${
            isMobile ? "flex-column" : "align-items-center"
          }`}
        >
          <span className={styles.companyName}>{selectedCompany.name}</span>
          <Select
            value={relationship?.slug || "none"}
            onChange={handleChangeStage}
            disabled={blockFunctionality}
            style={{
              backgroundColor: relationship?.colors?.background || "",
              color: relationship?.colors?.text || "",
            }}
            className={
              !relationship?.slug || relationship === null
                ? styles.tractionMatch
                : styles.detailsRelationship
            }
            disableUnderline
          >
            {checkIfTractionMatchIsAllowed() && (
              <MenuItem
                style={getMenuItemStyles("none")}
                key="none"
                value="none"
              >
                Traction Match
              </MenuItem>
            )}

            {relationShipStatuses.map((item) => (
              <MenuItem
                style={getMenuItemStyles(item.name)}
                key={item.slug}
                value={item.slug}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        {selectedCouncil?.name !== "Kyndryl" && (
          <span className={styles.score}>
            {selectedCouncil?.name} Score:{" "}
            {selectedCompany?.council_company_score || 0}
          </span>
        )}
      </div>
      {isMobile && (
        <CompanyTopActions
          isAdmin={isAdmin}
          blockFunctionality={blockFunctionality}
          id={selectedCompany.id}
        />
      )}
    </div>
  );
};

export default React.memo(CompanyTopDetails);

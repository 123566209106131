import React, { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import * as classNames from "classnames";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import isItSmallDevice from "modules/themes/themesCommon/isItSmallDevice";
import styles from "./styles.module.scss";
import {
  Pannel,
  CompanyAvatar,
  MoreOptions,
  Pagination,
} from "../../../../primitives";
import { useModelPopup } from "../../../../../common/hooks";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import LoadingComponent from "../../../../primitives/loading/loading.component";
import createProjOptions from "./relatedProjects.constant";
import AddRelatedProjectComponent from "./addRelatedProjectModal";
import ConfirmDeleteComponent from "../../../../../common/components/confirmDelete/index";
import isItStandardViewver from "../../../themesCommon/isItStandardViewer";
import { themesProjectsGetAttempt } from "../../themesProjectsTab/projectsTab.action";
import NewProjectV3 from "modules/projects/newProjectv3";

const RelatedProjectComponent = ({ themeId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    themesReducer: {
      themesMainReducer: { currentThemeData },
      themesProjectsReducer: {
        projects: projectList,
        loadingProj,
        projectListMeta,
      },
      themesOverviewReducer: { companyList, loadingCompany },
    },
  } = useSelector((state) => state);
  const [seeAll, setSeeAll] = useState(false);
  const [pageSetting, setPageSetting] = useState({
    pageSize: 50,
    current: 1,
    hideOnSinglePage: true,
    showTitle: false,
    total: projectList?.length || 0,
  });
  const [chunkToShow, setChunkToShow] = useState([]);
  const [isProjectsOpened, setIsProjectsOpenned] = useState(true);
  const [shouldOpenAddNewProjectModal, setShouldOpenAddNewProjectModal] =
    useState(false);

  const handlePagingChange = (page) => {
    setPageSetting((prev) => ({
      ...prev,
      total: projectList?.length,
      current: page,
    }));
  };
  useEffect(() => {
    if (themeId) {
      dispatch(
        themesProjectsGetAttempt({
          themeId,
          page: 1,
          overview: true,
          pageSize: pageSetting.pageSize,
        })
      );
    }
  }, [themeId]);

  useEffect(() => {
    if (projectList?.length && !seeAll) {
      setChunkToShow(projectList.slice(0, 6));
    }
    if (pageSetting?.current && projectList?.length && seeAll) {
      setChunkToShow(
        projectList?.slice(
          (pageSetting.current - 1) * pageSetting.pageSize,
          pageSetting.current * pageSetting.pageSize
        )
      );
    }
  }, [pageSetting?.current, projectList, seeAll]);

  const handleProjectRedirect = (id) => {
    history.push(`/projects/${id}/overview`);
  };

  const handleAddProjectClick = () => {
    popup.show({
      title: "Link Theme to Related Project",
      component: (
        <AddRelatedProjectComponent
          itemList={projectList}
          currentThemeId={themeId}
        />
      ),
    });
  };

  const handleMoreOptionClick = (val) => {
    if (val === "relatedProject") {
      handleAddProjectClick();
    }
    if (val === "newProject") {
      setShouldOpenAddNewProjectModal(true);
    }
  };

  const handleDeleteProject = (id) => {
    popup.show({
      title: "Confirmation Delete",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={themeId}
          templateId={id}
          enqueueSnackbar={enqueueSnackbar}
          councilId={selectedCouncil?.id}
          actionType="deleteThemeProject"
        />
      ),
    });
  };

  const isAdmin = useMemo(
    () =>
      session?.council_role === "ttp_administrator" ||
      session?.council_role === "council_administrator",
    [session]
  );

  return (
    <Pannel
      title="Related Projects"
      padding="0"
      parentStyle={{
        width: `${window.screen.width < 550 ? "100%" : "230px"}`,
        minWidth: "230px",
        padding: `${window.screen.width < 550 && "0px"}`,
      }}
    >
      <div className={styles.panelHeader}>
        Projects
        <div className={styles.panelIcons}>
          {isItSmallDevice() && (
            <Icon
              {...mdSize}
              icon="icn-table-down-chevron"
              className={
                isProjectsOpened
                  ? styles.rotatedDownChevron
                  : styles.downChevron
              }
              onClick={() => {
                setIsProjectsOpenned(!isProjectsOpened);
              }}
            />
          )}
          {!loadingCompany && (
            <>
              <div className={styles.btnWrp}>
                {isItStandardViewver(session, currentThemeData) && (
                  <MoreOptions
                    options={
                      selectedCouncil?.name === "Firmenich" ||
                      selectedCouncil?.name === "Ford"
                        ? [createProjOptions[0]]
                        : createProjOptions
                    }
                    className={styles.moreOptWrp}
                    onClick={handleMoreOptionClick}
                    {...mdSize}
                    iconName="icn-add"
                  />
                )}
              </div>
              <NewProjectV3
                isAdmin={isAdmin}
                wizardType="theme"
                additionalData={{
                  ...currentThemeData,
                  theme_companies: companyList,
                }}
                btnName="New Project"
                shouldOpen={shouldOpenAddNewProjectModal}
                handleDismiss={() => setShouldOpenAddNewProjectModal(false)}
                hideBtn
              />
            </>
          )}
        </div>
      </div>
      {isProjectsOpened && (
        <div className={styles.wrapper}>
          {loadingProj ? <LoadingComponent /> : null}

          {projectList && projectList.length > 0 ? (
            chunkToShow?.map((item) => {
              return (
                <div key={`${item.project_id}`}>
                  <div
                    onClick={() => handleProjectRedirect(item.project_id)}
                    className={styles.projectTitle}
                  >
                    <span> {item.project_name.column_value} </span>
                    <span className={styles.deleteIcon}>
                      {isItStandardViewver(session, currentThemeData) && (
                        <Icon
                          {...smSize}
                          icon="icn-button-delete"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            return handleDeleteProject(item.project_id);
                          }}
                        />
                      )}
                    </span>
                  </div>
                  {item?.project_companies?.column_value?.map((company) => (
                    <div
                      key={`${company.id}`}
                      className={classNames(styles.Row)}
                    >
                      <div className={styles.projectIcon}>
                        <div className={styles.iconCon}>
                          <CompanyAvatar
                            imgSrc={company.logo}
                            name={company.name}
                          />
                        </div>
                      </div>
                      <div>{company.name}</div>
                    </div>
                  ))}
                </div>
              );
            })
          ) : (
            <span className="p-3 text-center">No projects yet!</span>
          )}
          {seeAll && (
            <div className={styles.paginationCon}>
              <Pagination {...pageSetting} onChange={handlePagingChange} />
            </div>
          )}
          {projectList?.length > 6 && (
            <div onClick={() => setSeeAll(!seeAll)} className={styles.showAll}>
              {seeAll ? "HIDE" : "SEE ALL"}
            </div>
          )}
        </div>
      )}
    </Pannel>
  );
};

export default React.memo(RelatedProjectComponent);

import React, { useState, useRef, useEffect } from "react";
import { numberWithCommas } from "common/helper";
import IconClose from "common/icon/closeIcon.component";
import { useSelector } from "react-redux";

import styles from "./styles.module.scss";

export const FiltersModalComponent = (props) => {
  const {
    options,
    searchFn,
    filterOptionSearchResult,
    setFilterOption,
    handleRangeFilterValueSelect,
    specicStyles,
    setShowDropdown,
    selectedCouncilTag,
  } = props;

  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [plantQuery, setPlantsQuery] = useState("");
  const [areaQuery, setAreaQuery] = useState("");
  const [topicQuery, setTopicQuery] = useState("");
  const wrapperRef = useRef(null);
  const closeFilterModal = () => {
    setShowDropdown(false);
    setPlantsQuery("");
    setAreaQuery("");
    setTopicQuery("");
    searchFn("theme_ids", "");
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeFilterModal();
          searchFn("theme_ids", "");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const showForCouncil = (option, optionsNr) => {
    if (!option.range && optionsNr === 0) {
      return false;
    }
    if (
      selectedCouncilTag !== "ford" &&
      ["plant_ids", "area_ids"].includes(option.value)
    ) {
      return false;
    }
    return true;
  };
  return (
    <div className={styles.filterModalContainer} style={{ ...specicStyles }}>
      {" "}
      <div className={styles.filterOptionsContainer} ref={wrapperRef}>
        <div className={styles.titleRow}>
          <div className={styles.title}>
            <h4>Solutions filters</h4>
          </div>
          <div
            className={styles.closeIconContainer}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              closeFilterModal();
            }}
          >
            <IconClose />
          </div>
        </div>
        <div className={styles.filterOptionsRow}>
          {options.map(
            (option) =>
              option.type !== "search" &&
              showForCouncil(
                option,
                filterOptionSearchResult[option.value]?.length
              ) && (
                <div key={`${option.value}`} className={styles.filterOption}>
                  {option.value === "theme_ids"
                    ? selectedCouncil?.settings?.themes_display_name_singular
                    : option.text}
                  {option.searchable && (
                    <div className={styles.inputBlock}>
                      <input
                        placeholder="Type to search"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        onChange={(e) => {
                          if (option.value === "area_ids") {
                            setAreaQuery(e?.target?.value.toLowerCase());
                          } else if (option.value === "plant_ids") {
                            setPlantsQuery(e?.target?.value.toLowerCase());
                          } else if (option.value === "topic_ids") {
                            setTopicQuery(e?.target?.value.toLowerCase());
                          } else {
                            searchFn(option.value, e?.target?.value);
                          }
                        }}
                      />
                      <div
                        className={`${styles.filterValues} ${styles.scrollableContainer}`}
                      >
                        {filterOptionSearchResult[option.value]
                          ?.filter((el) => {
                            if (option.value === "area_ids" && areaQuery) {
                              return el.name.toLowerCase().includes(areaQuery);
                            }
                            if (option.value === "plant_ids" && plantQuery) {
                              return el.name.toLowerCase().includes(plantQuery);
                            }
                            if (option.value === "topic_ids" && topicQuery) {
                              return el.name.toLowerCase().includes(topicQuery);
                            }
                            return el;
                          })
                          .map((filter) => (
                            <span
                              className={`${styles.filterValue}`}
                              onClick={() => {
                                setFilterOption(option.value, filter);
                                closeFilterModal();
                              }}
                              key={`${filter.id}`}
                            >
                              {filter.name}
                              <br />
                            </span>
                          ))}
                      </div>
                    </div>
                  )}
                  {option.namesOnly && (
                    <div
                      className={`${styles.filterValues} ${styles.scrollableContainer}`}
                    >
                      {[
                        ...new Set(filterOptionSearchResult[option.value]),
                      ]?.map((filter) => (
                        <span
                          className={`${styles.filterValue}`}
                          onClick={() => {
                            setFilterOption(option.value, filter);
                            closeFilterModal();
                          }}
                          key={`${filter}`}
                        >
                          {filter}
                          <br />
                        </span>
                      ))}
                    </div>
                  )}
                  {option.range && (
                    <div>
                      {option.ranges.map((range) => {
                        return (
                          <div
                            className={styles.filterValues}
                            key={`${range[0]}`}
                          >
                            <span
                              onClick={() => {
                                handleRangeFilterValueSelect(
                                  option.value,
                                  range
                                );
                                closeFilterModal();
                              }}
                              className={`${styles.filterValue}`}
                            >
                              {option.value === "savings" && "$"}
                              {numberWithCommas(range[0]) || "0"} -{" "}
                              {option.value === "savings" &&
                                range[1] !== null &&
                                "$"}
                              {range[1] === null
                                ? "Max."
                                : numberWithCommas(range[1])}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default FiltersModalComponent;

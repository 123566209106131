import React, { useState, useEffect } from "react";
import { numberWithCommas } from "common/helper";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { format } from "date-fns";
import { currencySymbol } from "common/components/customFields/number/constant";
import isItStandardViewver from "../isItStandardViewer";
import { Icon, smSize } from "../../../../common/icon";
import ConfirmDeleteComponent from "../../../../common/components/confirmDelete/index";
import { useModelPopup } from "../../../../common/hooks";

import {
  ProjectState,
  UserInline,
  UserInlineTypes,
  CompanyAvatar,
} from "../../../primitives";
import styles from "./styles.module.scss";

const ProjectRow = ({
  project,
  projectName,
  isFord,
  selectedCouncilTag,
  tableColumnsValues,
  tableColumns,
}) => {
  const history = useHistory();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [hasMore, setHasMore] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    themesReducer: {
      themesMainReducer: { currentThemeData },
    },
  } = useSelector((state) => state);
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    setHasMore(project.companies?.column_value?.length > 3);
  }, [project.companies]);

  const handleDeleteProject = (themeId, id) => {
    popup.show({
      title: "Confirmation Delete",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={themeId}
          templateId={id}
          enqueueSnackbar={enqueueSnackbar}
          councilId={selectedCouncil?.id}
          actionType="deleteThemeProject"
        />
      ),
    });
  };

  const handleProjectRedirect = (id) => {
    history.push(`/projects/${id}/overview`);
  };
  const getColumnValue = (columnType) => {
    const column = tableColumnsValues.find(
      (col) => col.column_type === columnType
    );
    return column?.display;
  };

  const getCellValue = (val, type, column) => {
    const formatValue = (cellValue) => {
      if (
        cellValue?.column_value?.length &&
        Array.isArray(cellValue?.column_value)
      ) {
        return cellValue?.column_value[0];
      }
      if (Number.isNaN(cellValue?.column_value)) {
        return cellValue?.column_value;
      }
      if (
        column.project_field_template &&
        column.project_field_template?.field_type === "currency"
      ) {
        if (!cellValue?.column_value) {
          return "-";
        }

        return `${
          currencySymbol.find(
            (el) => el.name === column.project_field_template?.option_values[0]
          ).symbol
        } ${numberWithCommas(cellValue?.column_value)}`;
      }
      if (cellValue?.column_name?.includes("tarr")) {
        return `${
          Number.isInteger(cellValue?.column_value)
            ? cellValue?.column_value.toString()
            : cellValue?.column_value.toFixed(2)
        } % `;
      }
      if (column.project_metric?.metric_type === "integer") {
        return `${
          column.project_metric.metric_attribute.indexOf("usd") &&
          cellValue?.column_value
            ? "$ "
            : ""
        }${numberWithCommas(cellValue?.column_value)}`;
      }
      return cellValue?.column_value;
    };
    if (type === "project_field" && project[type]) {
      const cellValue = project[type].find((o) => {
        if (o.column_name === column.project_field_template.field_name) {
          if (Array.isArray(o.column_value)) {
            return o.column_value.join(", ");
          }
          return o.column_value;
        }
      });

      return formatValue(cellValue);
    }
    if (type === "project_metric") {
      const cellValue = project[type]?.find((o) => {
        if (o.column_name === column.project_metric_attribute) {
          if (Array.isArray(o.column_value)) {
            return o.column_value.join(", ");
          }
          if (column.project_metric.metric_type === "integer") {
            return numberWithCommas(o.column_value);
          }

          return o.column_value;
        }
      });
      if (column.project_metric.metric_type === "datetime") {
        if (!cellValue) {
          return "-";
        }
        if (cellValue?.column_name.includes("stage_end_on")) {
          return format(new Date(cellValue?.column_value), "'Q'Q yyyy");
        }
        return format(new Date(cellValue?.column_value), "MMM do, yyyy");
      }

      return formatValue(cellValue);
    }

    if (val === null || val === undefined) {
      return "-";
    }
    if (typeof val === "string" || typeof val === "number") {
      if (type === "project_access") {
        return (
          <div className={styles.shortCell}>
            {val ? val.charAt(0).toUpperCase() + val.slice(1) : null}
          </div>
        );
      }
      if (type === "project_status") {
        return <ProjectState state={val?.column_value} />;
      }
      return val;
    }
    if (typeof val === "string" || typeof val === "number") {
      if (type === "project_access") {
        return (
          <div className={styles.shortCell}>
            {val ? val.charAt(0).toUpperCase() + val.slice(1) : null}
          </div>
        );
      }
      if (type === "project_status") {
        return <ProjectState state={val?.column_value} />;
      }
      return val;
    }
    if (Array.isArray(val)) {
      if (type === "project_owners") {
        return (
          <div className={styles.cell}>
            {val?.map((owner) => {
              return (
                <div className={styles.owner}>
                  <UserInline
                    className={styles.userIcon}
                    userId={owner?.owner_id}
                    type={UserInlineTypes.WITH_NAME}
                    showMine={false}
                    first={owner?.owner_name}
                    last={""}
                    avatar={owner?.owner_avatar}
                    local
                  />
                </div>
              );
            })}
          </div>
        );
      }
      if (type === "project_companies") {
        return (
          <div
            className={classNames(
              styles.companies,
              styles.cell,
              selectedCouncilTag === "firmenich" ? styles.firmenichCompany : ""
            )}
          >
            {val.map((o) => {
              return (
                <div key={o.company_name} className={styles.company}>
                  <div className={styles.firstCol}>
                    <div className={styles.nameContainer}>
                      <div className={styles.companyIcon}>
                        <div className={styles.iconCon}>
                          <CompanyAvatar
                            imgSrc={o.company_logo}
                            name={o.company_name}
                          />
                        </div>
                      </div>
                      <div>{o.company_name}</div>
                    </div>
                    {o.is_product ? (
                      <span className="small text-muted">Product</span>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
    }
  };
  return (
    <div className={styles.gridRow}>
      <div
        className={styles.project}
        onClick={() => handleProjectRedirect(project.project_id)}
      >
        {projectName || "-"}
      </div>

      {tableColumns.map((column) => {
        if (column.column_type === "project_name") {
          return null;
        }
        return (
          <div
            className={styles.task}
            key={`${column.column_type + column.position}_${
              column?.project_metric?.metric_attribute ||
              column?.project_field_template_id
            }`}
          >
            {getCellValue(
              project[column.column_type]?.column_value,
              column.column_type,
              column
            ) || "-"}
          </div>
        );
      })}

      <div className={styles.shortCol}>
        {isItStandardViewver(session, currentThemeData) && (
          <Icon
            style={{ cursor: "pointer" }}
            {...smSize}
            icon="icn-button-delete"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              return handleDeleteProject(
                currentThemeData.id,
                project.project_id
              );
            }}
          />
        )}
      </div>
    </div>
  );
};
export default ProjectRow;

import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import classNames from "classnames";
import { withSnackbar } from "notistack";
import Icon from "common/icon/icon.component";
import { smSize } from "common/icon";
import {
  BtnType,
  Button,
  Label,
  TextBox,
  Loading,
  DropSelect,
} from "modules/primitives/index";
import { httpGet } from "common/httpCall";

import { useModelPopup } from "../../../../common/hooks/index";
import styles from "../adminTags.module.scss";
import { ModalBody, ModalFooter } from "../../../../application/modal";
import { tagValidation, tagModel } from "./tagModal.constants";
import {
  tagTopicPostAttempt,
  tagTopicPutAttempt,
  tagIndustryPostAttempt,
  tagIndustryPutAttempt,
} from "../adminTags.actions";
import TagsSearch from "../tagsSearch";

const AdminTagModal = ({
  enqueueSnackbar,
  mode,
  council_id,
  tags,
  selectedVariant,
  tag,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const popup = useModelPopup();
  const [model, setModel] = useState(tagModel);
  const [editModel, setEditModel] = useState({});
  const [parentTag, setParentTag] = useState([]);
  const [mappedTags, setMappedTags] = useState([]);
  const [topicCreateName, setTopicCreateName] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [showCoincidences, setShowCoincidences] = useState(false);
  const [coincidenceError, setCoincidenceError] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [allCouncilsTopics, setAllCouncilsTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropdownHandle, setDropdownHandle] = useState(false);
  const [colapsedParents, setColapsedParents] = useState([]);
  const parentKeyName =
    selectedVariant === "industry"
      ? "parent_resource_industry_id"
      : "parent_resource_topic_id";
  const tagKeyName =
    selectedVariant === "industry"
      ? "resource_industry_id"
      : "resource_topic_id";

  useEffect(() => {
    if (tags?.length) {
      const newTags =
        selectedVariant === "industry"
          ? growUpParentsChildrenIndustries(tags, 3)
          : growUpParentsChildrenTopics(tags, 3);

      setMappedTags(newTags);

      if (mode === "edit") {
        const selVar = newTags.filter(
          (element) => element.id === tag?.parent_resource_topic?.id
        );
        setParentTag(selVar);
        setTopicCreateName(tag.name);
      }
    }
  }, [tags, mode]);

  function growUpParentsChildrenTopics(nested, level) {
    const flat = [];
    for (let i = 0; i < nested.length; i++) {
      if (nested[i].children_topics?.length && level > 2) {
        flat.push(nested[i]);
        flat.push(
          ...growUpParentsChildrenTopics(
            nested[i].children_topics.map((tag) => ({ ...tag, level })),
            level - 1
          )
        );
      } else {
        flat.push(nested[i]);
      }
    }

    return flat;
  }

  function growUpParentsChildrenIndustries(nested, level) {
    const flat = [];
    for (let i = 0; i < nested.length; i++) {
      if (nested[i].children_industries?.length && level > 2) {
        flat.push(nested[i]);
        flat.push(
          ...growUpParentsChildrenIndustries(
            nested[i].children_industries.map((tag) => ({ ...tag, level })),
            level - 1
          )
        );
      } else {
        flat.push(nested[i]);
      }
    }

    return flat;
  }

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleOnSubmit = () => {
    if (topicCreateName) {
      setCoincidenceError(false);

      const data =
        selectedVariant === "industry"
          ? {
              industry: {
                name: topicCreateName,
                industry_id: parentTag?.id || undefined,
                parent_resource_industry_id: parentTag?.resource_industry_id,
              },
            }
          : {
              topic: {
                name: topicCreateName,
                topic_id: parentTag?.id || undefined,
                parent_resource_topic_id: parentTag?.resource_topic_id,
              },
            };

      const variable = mappedTags.find(
        (tag) => tag.name === topicCreateName.trim()
      );

      if (!variable || topicCreateName === tag?.name) {
        if (mode === "add") {
          if (selectedVariant === "industry") {
            dispatch(
              tagIndustryPostAttempt({ data, enqueueSnackbar, council_id })
            );
          } else {
            dispatch(
              tagTopicPostAttempt({ data, enqueueSnackbar, council_id })
            );
          }
        }
        if (mode === "edit") {
          const elementId =
            selectedVariant === "industry"
              ? tag.resource_industry_id
              : tag.resource_topic_id;
          if (selectedVariant === "industry") {
            dispatch(
              tagIndustryPutAttempt({
                data,
                topic_id: elementId,
                enqueueSnackbar,
                council_id,
              })
            );
          } else {
            dispatch(
              tagTopicPutAttempt({
                data,
                topic_id: elementId,
                enqueueSnackbar,
                council_id,
              })
            );
          }
        }
        popup.hide();
      } else {
        setErrorName(true);
      }
    } else {
      setValidationError(true);
    }
  };

  const handleTopicChange = (val) => {
    setParentTag(val);
  };

  useEffect(() => {
    if (mode === "edit") {
      setEditModel({
        name: tag.name,
      });
    }
  }, [mode]);

  const handleTopicSelect = (name) => {
    if (name && validationError) {
      setValidationError(false);
    }
    setTopicCreateName(name);
  };

  useEffect(() => {
    setLoading(true);
    httpGet({
      call: `${
        selectedVariant === "industry" ? "industries" : "topics"
      }?all=true`,
    }).subscribe((res) => {
      setLoading(false);
      setAllCouncilsTopics(res.response);
    });
  }, []);
  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={mode === "edit" ? editModel : model}
        validationSchema={tagValidation}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
        }}
      >
        {({ handleSubmit, handleReset, ...formikprops }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <ModalBody>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setShowCoincidences(false);
                }}
              >
                <p className={styles.aditionalInfo}>
                  Your tag will be available for all companies.
                </p>
                <div className={styles.addRatingTempForm}>
                  <div className={styles.row}>
                    <div className={classNames(styles.col12)}>
                      <Label>Tag Name</Label>
                      <TextBox
                        autoComplete="false"
                        type="text"
                        value={topicCreateName}
                        className={styles.textBox}
                        onChange={(e) => {
                          setShowCoincidences(true);
                          handleTopicSelect(e.target.value);
                        }}
                        onFocus={(e) => {
                          setShowCoincidences(true);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        disabled={loading}
                      />
                      {loading ? <Loading /> : null}
                      {coincidenceError && (
                        <div className={styles.errorSearch}>Similar tags</div>
                      )}
                      <div className={styles.tagNameContainer}>
                        {showCoincidences &&
                          Boolean(topicCreateName.length) && (
                            <TagsSearch
                              setCoincidenceError={setCoincidenceError}
                              data={allCouncilsTopics}
                              text={topicCreateName}
                              coincidenceError={coincidenceError}
                              selectedTag={tag}
                              handleTopicSelect={handleTopicSelect}
                              setShowCoincidencesFalse={() =>
                                setShowCoincidences(false)
                              }
                            />
                          )}
                      </div>
                      {validationError && (
                        <span className={styles.errorMessage}>
                          Name is required field
                        </span>
                      )}
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.col12}>
                      <Label>Parent tag</Label>
                      <DropSelect
                        SelecedValues={parentTag.id}
                        onChange={() => undefined}
                        placeholder={`${parentTag.name || "Parent tag"}`}
                        labelField="name"
                        disabled={false}
                        valueField="id"
                        searchBy="name"
                        options={mappedTags}
                        dropdownHandle={dropdownHandle}
                        itemRenderer={({ item }) => {
                          if (colapsedParents.includes(item[parentKeyName])) {
                            return false;
                          }
                          return (
                            <div
                              key={item.id}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleTopicChange(item);
                                setDropdownHandle(false);
                              }}
                              value={item.id}
                              style={{
                                width: "90%",
                                marginTop: "10px",
                                alignContent: "center",
                                marginLeft: `${
                                  10 +
                                  ((item.level ? item.level * 2 : 1) - 1) * 5
                                }px`,
                                paddingLeft: "5px",
                              }}
                            >
                              <div>
                                {!item.level && (
                                  <Icon
                                    {...smSize}
                                    className={`${styles.iconColor} ${
                                      colapsedParents.includes(item[tagKeyName])
                                        ? {}
                                        : styles.rotateIcon
                                    }`}
                                    icon={"icn-right-chevron"}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setColapsedParents((prev) => {
                                        if (prev.includes(item[tagKeyName])) {
                                          return prev.filter(
                                            (id) => id !== item[tagKeyName]
                                          );
                                        }
                                        return [...prev, item[tagKeyName]];
                                      });
                                    }}
                                  />
                                )}{" "}
                                <span className={styles.parentTagNameModal}>
                                  {item.name}
                                </span>
                              </div>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    {errorName && (
                      <span className={styles.errorMessage}>
                        This tag already exists
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  handleOnSubmit();
                }}
                btn={BtnType.REGULAR}
              >
                {mode === "edit" ? "Submit" : "Add"}
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(withSnackbar(AdminTagModal));

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { ModalFooter, ModalBody } from "../../../../application/modal";
import {
  Button,
  BtnType,
  Label,
  MultiSelect,
  Loading,
} from "../../../primitives";

import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../common/hooks";
import {
  themesGetAttempt,
  themesSearchAttempt,
} from "../../themesMainPage/themesMain.action";
import {
  themesCompanyAddAttempt,
  themesCompanyDeleteAttempt,
} from "../../themesIndividualPage/themesOverviewTab/overviewTab.action";

const AddToThemeComponent = ({
  company,
  fromCompanyProfile,
  currentThemeId,
  deleteFromCurrentTheme,
}) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [currentQuery, setQuery] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [themesData, setThemesData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    // authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        themesGetAttempt({
          enqueueSnackbar,
          isDropdown: true,
          items: 9999,
          cb: (data) => {
            setThemesData(data);
          },
        })
      );
    }
  }, [selectedCouncil]);

  const handleCancelClick = (e) => {
    popup.hide();
  };

  const handleSaveClick = (e) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleOnSubmit = (value) => {
    let obj;

    if (company.isProduct) {
      obj = {
        council_product_id: company.id,
        is_product: true,
      };
    } else {
      obj = {
        company_id: deleteFromCurrentTheme
          ? company.company_id
          : company.id || company.company_id,
      };
    }

    const data = {
      theme_company: [obj],
    };

    setIsSubmitting(true);

    dispatch(
      themesCompanyAddAttempt({
        themeId: value.id[0].id,
        data,
        activeLayout: "modal",
        enqueueSnackbar,
      })
    );
    if (deleteFromCurrentTheme) {
      dispatch(
        themesCompanyDeleteAttempt({
          id: currentThemeId,
          companyId: company.company_id,
          enqueueSnackbar,
        })
      );
    }
    if (fromCompanyProfile) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    popup.hide();
  };
  const handleSearchUpdate = (searchObj) => {
    setQuery(searchObj.search);
    const data = {
      sort_order: "asc",
      sort_attribute: "created_at",
      search: {
        query: searchObj.search,
      },
    };

    dispatch(themesSearchAttempt({ page: 1, data }));
  };

  useEffect(() => {
    setLoading(false);
  }, [themesData]);

  useEffect(() => {
    if (themesData && themesData?.length > 0) {
      if (company.isProduct && company.themes_ids?.length) {
        const filteredThemes = themesData?.filter((option) => {
          const isAlreadySelected = company.themes_ids.includes(option.id);
          return !isAlreadySelected && option.council_role !== "viewer";
        });
        setOptions(filteredThemes);

        return;
      }

      const filteredThemes = themesData?.filter((option) => {
        const isAlreadySelected = company.related_themes?.find((item) => {
          return item?.theme_id === option.id;
        });
        return !isAlreadySelected && option.council_role !== "viewer";
      });
      setOptions(filteredThemes);
    }
  }, [themesData]);

  return (
    <>
      <ModalBody>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{ id: "" }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleOnSubmit(values);
          }}
        >
          {({ handleSubmit, setFieldValue, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.editStageFormWrp}>
                <Label>
                  {selectedCouncil?.settings?.themes_display_name_singular ||
                    "Theme"}
                </Label>
                <div className={styles.selectorWrp}>
                  <MultiSelect
                    onChange={(val) => {
                      setFieldValue("id", val);
                    }}
                    placeholder={`Choose ${
                      selectedCouncil?.settings?.themes_display_name_singular ||
                      "Theme"
                    }`}
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    options={options}
                    formProps={formikprops}
                    multi={false}
                    searchFn={(args) => {
                      if (currentQuery === args.state.search) {
                        return;
                      }
                      handleSearchUpdate(args.state);
                    }}
                  />
                  <div className={styles.loaderWrp}>
                    {loading && <Loading hideString />}
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        {isSubmitting ? (
          <LoadingComponent customText="Processing..." />
        ) : (
          <>
            <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button
              btn={BtnType.REGULAR}
              onClick={(e) => {
                e.stopPropagation();
                handleSaveClick();
              }}
            >
              Add to{" "}
              {selectedCouncil?.settings?.themes_display_name_singular ||
                "Theme"}
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default AddToThemeComponent;

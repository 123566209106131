import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";
import { PAGINATION_DEFAULTS } from "./constants";
import { checkIsStringWhenExpectArray, makeFlattenTags } from "../../../helper";
import ProjectDescription from "modules/projects/projectDescription";
import { httpGet, httpPost, pageMeta } from "../../../httpCall";
import { CheckBox, CheckType, Label, Loading, Pagination } from "modules/primitives";

const SimilarProjectRecommendations = ({ formikprops, isAdminWizardInstance, wizardData }) => {
  const {
    companiesReducer: {
      companyMainReducer: { topics },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [projects, setProjects] = useState([]);
  const [select, setSelect] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [pageSetting, setPageSetting] = useState(PAGINATION_DEFAULTS);
  const [items, setItems] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAllByPage, setSelectedAllByPage] = useState({ 1: false });

  useEffect(() => {
    if (isAdminWizardInstance) return;

    if (selectedCouncil?.id) {
      httpGet({
        call: 'projects/related_projects/dropdown'
      }).subscribe(res => {
        const data = res.response || [];
        setProjects(data);
      });
    }
  }, [selectedCouncil]);

  useEffect(() => {
    if (!items && !isAdminWizardInstance) {
      requestTheData(1, '');
    }

    return () => null;
  }, [items]);

  const getTopicNames = () => {
    const getStorageProject = JSON.parse(localStorage.getItem("new_project"));
    const topicIds = getStorageProject?.topics || []

    if (!topicIds?.length || !topics) return '';
    const flattenTopics = makeFlattenTags(topics, 'children_topics');

    return flattenTopics
      .filter(t => topicIds.includes(t.id))
      .map(t => t.name)
      .join(', ');
  }

  const requestTheData = () => {
    setShowLoading(true);

    const projectFieldsArray = wizardData.projects_wizard_steps.reduce((acc, elem) => {
      return [...acc, ...elem.projects_wizard_options.filter(option => option.isProjectField)]
    }, []);

    const getProjectFieldValue = (name) => {
      const getProjectField = projectFieldsArray.find(f => f.field_name === name);
      const fieldValue = formikprops.values.project_fields?.[getProjectField?.id];
      return checkIsStringWhenExpectArray(fieldValue).map(s => s.label).join(', ');
    }

    const data = {
      data: {
        name: formikprops.values?.project_name,
        description: formikprops.values?.description,
        topics: getTopicNames(),
        pillars: formikprops.values?.project_theme?.length ? formikprops.values?.project_theme.map(s => s.name).join(', ') : '',
        skills_team: getProjectFieldValue('Skills Team'),
        fps_category: getProjectFieldValue('FPS Category'),
      }
    }

    httpPost({
      call: `projects/similar_projects`,
      data,
    })
      .pipe()
      .subscribe((res) => {
        const pagemeta = pageMeta(res);
        const selectedAllByPageObj = [...Array(pagemeta.pages).keys()].reduce(
          (acc, idx) => {
            if (acc[idx]) {
              return acc;
            }

            acc[idx] = false;

            return acc;
          },
          selectedAllByPage
        );

        setSelectedAllByPage(selectedAllByPageObj);

        setPageSetting((prev) => ({
          ...prev,
          total: pagemeta.total,
          current: pagemeta.page,
        }));

        const similarProjects = checkIsStringWhenExpectArray(formikprops.values?.similar_projects_recommendations);
        const responseBasedOnStorage = res.response.map((item) => {
          const checkItemExistInStorage = similarProjects.some((i) => i.id === item.id);

          if (checkItemExistInStorage) {
            return {
              ...item,
              selected: true,
            };
          }

          return item;
        });
        const allItemsAreSelected = responseBasedOnStorage.filter((i) => i.selected)?.length === 5;

        setSelectedAllByPage({
          ...selectedAllByPage,
          [pagemeta.page]: allItemsAreSelected,
        });

        setItems(responseBasedOnStorage);
        setSelectedItems(similarProjects);
        setShowLoading(false);
      });
  };

  const handlePagingChange = (page) => {
    if (page !== pageSetting.current) {
      requestTheData(page, searchTerm);
    }
  };

  const onCheckChange = useCallback(
    (val, item, isFromSelectedBox) => {
      let dataForStorage;

      const newItems = val
        ? [...selectedItems, item]
        : selectedItems.filter((i) => i.id !== item.id);

      setSelectedItems(newItems);

      const newItemsOnAction = items.map((i) => {
        if (i.id === item.id) {
          return {
            ...i,
            selected: isFromSelectedBox ? false : val,
          };
        }

        return i;
      });

      setItems(newItemsOnAction);

      if (val) {
        if (!select.find((obj) => obj.id === item.id)) {
          dataForStorage = [
            ...select,
            {
              logo: item.logo,
              name: item.name,
              id: item.id,
            },
          ];
          setSelect(dataForStorage);
        }
      } else {
        dataForStorage = select.filter((obj) => obj.id !== item.id);
        setSelect(dataForStorage);
      }

      setLastDataToStorage(dataForStorage);
    },
    [items]
  );

  const setLastDataToStorage = (newData) => {
    formikprops.setFieldValue('similar_projects_recommendations', newData);
    const areAllSelected = pageSetting.current === 1
      ? formikprops.values.similar_projects_recommendations?.length === items?.length
      : formikprops.values.similar_projects_recommendations?.length / pageSetting.current === items?.length;

    setSelectedAllByPage({
      ...selectedAllByPage,
      [pageSetting.current]: areAllSelected,
    });
  };

  const selectAll = (val) => {
    if (val) {
      const itemsForStorage = items.map((i) => ({
        logo: i.logo,
        name: i.name,
        id: i.id,
      }));
      const currentItems = items.map((i) => ({
        ...i,
        selected: true,
      }));
      const all = [
        ...currentItems.filter(
          (i) => !selectedItems.some((item) => item.id === i.id)
        ),
        ...selectedItems,
      ];
      const finalDataForStorage = [
        ...itemsForStorage.filter(
          (i) => !select.some((item) => item.id === i.id)
        ),
        ...select,
      ];

      setItems(currentItems);
      setSelect(finalDataForStorage);
      setSelectedItems(all);
      setLastDataToStorage(finalDataForStorage);
    } else {
      const currentItems = items.map((i) => ({
        ...i,
        selected: false,
      }));
      const itemsForSelectedBox = selectedItems.filter(
        (i) => !currentItems.some((item) => item.id === i.id)
      );
      const itemsForStorage = select.filter(
        (i) => !currentItems.some((item) => item.id === i.company_id)
      );

      setItems(currentItems);
      setSelect(itemsForStorage);
      setSelectedItems(itemsForSelectedBox);
      setLastDataToStorage(itemsForStorage);
    }
  };

  const handleCheckBoxClick = () => {};

  if (isAdminWizardInstance) {
    return (
      <div>Similar Project Recommendations</div>
    )
  }

  return (
    <div className={styles.inner}>
      <h4 className={classNames("bold", styles.innerTitle)}>
        Add Similar Projects to review Lessons Learned
      </h4>
      {
        projects.length ? (
          <div className="mb-3">
            <Label>Select Similar Projects</Label>
            <Select
              label="Select Similar Projects"
              placeholder="Select Similar Projects"
              labelField="name"
              searchBy="name"
              valueField="id"
              name="similar_projects"
              multi={true}
              options={projects}
              onChange={(evt) => {
                formikprops.setFieldValue('similar_projects', evt)
              }}
              values={formikprops.values.similar_projects || []}
              dropdownPosition="bottom"
            />
          </div>
        ) : null
      }
      {selectedItems?.length > 0 ? (
        <h4 className={classNames("bold mt-4", styles.smallTitle)}>
          Selected Similar Project Recommendations{" "}({selectedItems?.length})
        </h4>
      ) : null}
      {selectedItems?.length > 0 ? (
        <div className={styles.listWrp}>
          {selectedItems.map((project) => (
            <div key={project.id} className={styles.formElement}>
              <div className={styles.projWrp}>
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked
                  onChange={() => onCheckChange(false, project, true)}
                />
                <div className="d-flex flex-column">
                  <Link to={`/projects/${project.slug}`} target="_blank">{project.name}</Link>
                  <ProjectDescription description={project.description} />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      {
        !showLoading && (
          <h4 className={classNames("bold mt-4", styles.smallTitle)}>
            Similar Project Recommendations
          </h4>
        )
      }
      <div className={styles.listWrp}>
        {!showLoading && items?.length ? (
          <div className="d-flex align-items-center mt-3 mb-4">
            <CheckBox
              checkType={CheckType.BLUE}
              isChecked={selectedAllByPage[pageSetting.current]}
              onChange={(val) => selectAll(val)}
            />
            <Label>
              <div className={classNames(styles.tagStyle)}>
                {pageSetting.total < 20
                  ? selectedAllByPage[pageSetting.current]
                    ? "Deselect Checked Items Below"
                    : "Select All"
                  : null}
                {pageSetting.total > 20
                  ? selectedAllByPage[pageSetting.current]
                    ? "Deselect Checked Items Below"
                    : "Select All 20 Results"
                  : null}
              </div>
            </Label>
          </div>
        ) : null}
        {showLoading ? <Loading /> : null}
        {!showLoading && items && items?.length
          ? items.map((project) => (
            <div key={project.id} className={styles.formElement}>
              <div className={styles.projWrp}>
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={project.selected}
                  onChange={(val) => onCheckChange(val, project)}
                  onClick={() => handleCheckBoxClick()}
                />
                <div className="d-flex flex-column">
                  <Link to={`/projects/${project.slug}`} target="_blank">{project.name}</Link>
                  <ProjectDescription description={project.description} />
                </div>
              </div>
            </div>
          ))
          : null}
        {!showLoading && items && items?.length === 0 ? (
          <h3 className="text-center py-4">
            Sorry we didn't find any results for you.
          </h3>
        ) : null}
      </div>
      {
        pageSetting?.total > 20 && (
          <div className="w-100 d-flex justify-content-center mb-4">
            <Pagination {...pageSetting} onChange={handlePagingChange} />
          </div>
        )
      }
    </div>
  );
};

export default React.memo(SimilarProjectRecommendations);

export const stateType = {
  FULL_TEXT: 1,
  ICON: 2,
};

export const companyState = {
  ON_TRACK: "active",
  REMOVED: "removed",
  PAUSED: "paused",
};

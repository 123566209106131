import React, { memo, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { format } from "date-fns";

import styles from "./upcomingTask.module.scss";
import {
  CheckBox,
  CheckType,
  State,
  MultiUsers,
} from "../../../../primitives";
import {
  getTaskAdditionalData,
  projectTaskUpdate,
} from "../../tasks/tasks.action";
import { appSliderSettingSet } from "application/app.action";
import { sliderSetting } from "application/app.constants";
import {
  TaskInfoComponentV2,
  TaskInfoHeaderComponentV2,
} from "../../tasks/taskInfo";
import ApprovalTaskHeader from "modules/projects/project/tasks/approvalTask/approvalTaskHeader.component";
import ApprovalTaskBody from "modules/projects/project/tasks/approvalTask/approvalTaskBody.component";
import TaskDueDate from "modules/projects/project/tasks/taskDueDate";
import LoaderWithDots from "../../../../../common/components/loaderWithDots";
import CompanyRowComponent from "modules/projects/project/tasks/companyRow";
import { getUpcomingTasks } from "modules/projects/project/overview/overview.action";

const UpcomingTaskRow = ({ task, canManageTasks, blockFunctionality, isFirmenich, projectId, activeTab, isFord, sessionId }) => {
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(false);
  const [showCompanies, setShowCompanies] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleOnChange = (value, assignmentId) => {
    dispatch(
      projectTaskUpdate({
        enqueueSnackbar,
        taskId: task.id,
        flag: value ? "completed_true" : "completed_false",
        projectId,
        project_task: {
          task_assignments: {
            completed_on: value ? format(new Date(), "yyyy-MM-dd") : null,
          },
        },
        queryParams: {
          task_assignment_id: assignmentId,
        },
        cb: () => {
          if (value) {
            dispatch(getUpcomingTasks({
              userId: activeTab === "my_task" ? sessionId : null,
              enqueueSnackbar,
              projectId,
            }));
            dispatch(appSliderSettingSet({
              show: false,
            }));
          }
        }
      })
    );
  };

  const handleShowTask = (task) => {
    if (task.task_type === "approval_gate") {
      dispatch(
        appSliderSettingSet({
          ...sliderSetting,
          show: true,
          height: "300",
          width: "540",
          header: <ApprovalTaskHeader task={task} activeTab={activeTab} upcomingTaskInstance />,
          component: (
            <ApprovalTaskBody
              taskId={task.id}
              blockFunctionality={
                isFord
                  ? canManageTasks
                  : blockFunctionality
              }
              activeTab={activeTab}
              upcomingTaskInstance
            />
          ),
        })
      );
      return;
    }
    const assignment = task.projects_task_assignments[0];

    dispatch(
      appSliderSettingSet({
        ...sliderSetting,
        show: true,
        height: "300",
        width: "540",
        header: (
          <TaskInfoHeaderComponentV2
            taskId={task.id}
            projectId={task.project_id}
            assignmentId={assignment.id}
            completed={assignment.completed}
            upcomingTaskInstance
          />
        ),
        component: (
          <TaskInfoComponentV2
            task={task}
            assignment={assignment}
            blockFunctionality={
              isFord
                ? canManageTasks
                : blockFunctionality
            }
            activeTab={activeTab}
            upcomingTaskInstance
          />
        ),
      })
    );
  };

  const handleTaskExpand = () => {
    if (task.hasAdditionalData) {
      if (task.task_type === "project_specific" || task.task_type === "approval_gate") {
        handleShowTask(task);
        return;
      }

      setShowCompanies(!showCompanies);
    } else {
      setShowLoading(true);
      dispatch(
        getTaskAdditionalData({
          projectId: projectId,
          taskId: task.id,
          activeTab,
          cb: (newTaskItem) => {
            setShowLoading(false);

            if (task.task_type === "project_specific" || task.task_type === "approval_gate") {
              handleShowTask(newTaskItem);
              return;
            }

            setShowCompanies(!showCompanies);
          },
        })
      );
    }
  };

  const makeCheckBoxProps = useMemo(() => {
    if (task.task_completion_state === "not_completed") {
      return { isChecked: false };
    }

    return {
      isChecked: true,
      checkType:
        task.task_completion_state === "half_completed"
          ? CheckType.HALF_BLUE
          : CheckType.GREEN,
    };
  }, [task.task_completion_state]);

  return (
    <>
      <div
        className={styles.gridRow}
        onClick={handleTaskExpand}
      >
        {showLoading && <LoaderWithDots hideText />}
        <div className={styles.select}>
          {!blockFunctionality && (
            <CheckBox
              {...makeCheckBoxProps}
              readOnly={
                !task.completion_enabled || task.task_type === "approval_gate" || (isFord ? !canManageTasks : false)
              }
              disabled={task.task_type === "approval_gate" || !task.completion_enabled}
              onChange={(value) =>
                handleOnChange(value, null)
              }
            />
          )}
        </div>
        <div className={styles.task}>{task.name}</div>
        {isFirmenich ? (
          <></>
        ) : (
          <div className={styles.stage}>
            {task.stage_id && (
              <State stageId={task.stage_id} />
            )}
          </div>
        )}
        <div className={classNames(styles.due)}>
          <TaskDueDate task={task} />
        </div>
        <div className={styles.assigned}>
          <MultiUsers
            users={task.assigned_users.map((u) => ({ ...u, ...u.user }))}
            total={task.assigned_users.length}
            disabled={task.locked}
          />
        </div>
      </div>
      {showCompanies && task.projects_task_assignments?.length > 0 && (
        <div>
          {task.projects_task_assignments.map((taskObj) => (
            <CompanyRowComponent
              key={taskObj.id}
              obj={taskObj}
              task={task}
              blockFunctionality={blockFunctionality}
              completionEnabled={task.completion_enabled}
              upcomingTaskInstance
            />
          ))}
        </div>
      )}
    </>
  );
};

export default memo(UpcomingTaskRow);

import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-dropdown-select";
import classNames from "classnames";

import { httpPost, pageMeta } from "../../httpCall";

import styles from "../../../scss/multiselect/multiselect.module.scss";
import "../../../scss/multiselect/multiselect.scss";

const CompaniesMultiSelectSearch = (props) => {
  const {
    className,
    name,
    selectedValues = null,
    labelField,
    valueField,
    placeholder,
    disabled = false,
    onChange,
    multi = true,
    onClear = null,
    withClear = false,
    formProps: {
      errors = {},
      values = [],
      setFieldValue = null,
      setFieldTouched = null,
    } = {},
    filterBy,
    shouldReset,
    endpoint,
    ...rest
  } = props;
  const ref = useRef();
  const [dropValues, setDropValues] = useState([]);
  const [onDropChange, setOnDropChange] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [query, setQuery] = useState("*");
  const [results, setResults] = useState([]);
  const [pageSettings, setPageSettings] = useState({
    total: null,
    current: 1,
  });
  const [debouncedValue, setDebouncedValue] = useState("");

  useEffect(() => {
    if (onDropChange) {
      setOnDropChange(false);
      if (setFieldTouched) setFieldTouched(name);
      if (setFieldValue && name && valueField) {
        setFieldValue(name, dropValues[0][valueField]);
        setFieldTouched(name);
        values[name] = dropValues[0][valueField];
      }
    }
  }, [onDropChange]);

  useEffect(() => {
    if (!dropValues.length) {
      setDropValues(selectedValues || []);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (
      selectedValues?.length &&
      dropValues &&
      !dropValues?.length
    ) {
      setDropValues([...selectedValues]);
    }
  }, []);

  const handleSearch = (evt) => {
    if (evt.search) {
      setQuery(evt.search);
    }
  };

  const requestNewData = (page, query) => {
    setShowLoading(true);
    const useEndpoint = endpoint || 'universal_search/search';

    httpPost({
      call: `${useEndpoint}?with_context=true&is_dropdown=true&page=${page || 1}&items=20`,
      data: {
        query,
        search: {
          query,
        },
      }
    })
      .pipe()
      .subscribe((res) => {
        const pagemeta = pageMeta(res);

        setPageSettings({
          ...pageSettings,
          total: pagemeta.total,
          current: page,
        });

        const options = res.response
          .filter((item) =>
            filterBy
              ? !filterBy.some((item1) => item1.id === item.id)
              : item
          )
          .map((item) => ({
            ...item,
            [labelField]: item[labelField],
            [valueField]: item[valueField],
          }));

        const newArr = [...results, ...options];
        const unique = [
          ...new Map(newArr.map((item) => [item.id, item])).values(),
        ];

        setResults(unique);
        setShowLoading(false);
      });
  };

  useEffect(() => {
    requestNewData(1, '');
  }, []);

  useEffect(() => {
    const trimmedQuerry = debouncedValue.trim().toLowerCase();
    if (trimmedQuerry.length > 0) {
      requestNewData(1, trimmedQuerry);
    }
  }, [debouncedValue]);

  const handleClearAllOnClick = () => {
    setDropValues([]);
    setOnDropChange(true);
  };

  useEffect(() => {
    if (shouldReset) {
      handleClearAllOnClick()
    }
  }, [shouldReset]);

  const loadMore = () => {
    requestNewData(pageSettings.current + 1, query.trim().toLowerCase());
  };

  const dropdownRenderer = useCallback(
    ({ props, state, methods }) => {
      const regexp = new RegExp(state?.search.trim() || "", "i");
      const options = props.options.filter((item) =>
        regexp.test(item[props.searchBy] || item[props.labelField])
      );
      return (
        <div className="d-flex flex-column react-dropdown-select-dropdown">
          {showLoading ? (
            <span className="p-3">Loading companies...</span>
          ) : (
            <>
              {options.length
                ? options.map((item, idx) => {
                    if (methods.isSelected(item)) {
                      return null;
                    }
                    const isSelected = state.values.indexOf(item) !== -1;

                    return (
                      <span
                        onClick={() =>
                          isSelected
                            ? methods.removeItem(item)
                            : methods.addItem(item)
                        }
                        key={`${item.name}-${idx}`}
                        className={`react-dropdown-select-item ${
                          isSelected
                            ? "react-dropdown-select-item-selected"
                            : ""
                        }`}
                      >
                        {item[props.labelField]}
                      </span>
                    );
                  })
                : "No data"}
              {pageSettings &&
              pageSettings.total > 20 &&
              pageSettings.total !== results?.length ? (
                <div className={styles.loadMore} onClick={loadMore}>
                  Load more
                </div>
              ) : null}
            </>
          )}
        </div>
      );
    },

    [showLoading, results]
  );

  const onNewSelection = (values) => {
    setOnDropChange(true);
    setDropValues(values);
    onChange(values);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(query);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [query]);

  return (
    <div className={styles.multiWrp}>
      <div
        className={classNames(
          styles.container,
          className,
          errors && errors[name] ? styles.error : ""
        )}
        ref={ref}
        id="customCompaniesSearch"
      >
        {!onDropChange && (
          <Select
            multi={multi}
            closeOnSelect
            disabled={disabled}
            values={dropValues}
            className={styles.dorpWrp}
            options={results}
            labelField={labelField}
            valueField={valueField}
            placeholder={placeholder}
            searchFn={(args) => {
              handleSearch(args.state);
            }}
            dropdownRenderer={(innerProps, innerState, innerMethods) =>
              dropdownRenderer(innerProps, innerState, innerMethods)
            }
            onChange={onNewSelection}
            {...rest}
          />
        )}
      </div>
      {withClear && (
        <div className={classNames(styles.multifunc, "caption small")}>
          <div
            onClick={(e) => {
              onClear && onClear();
              handleClearAllOnClick(e);
            }}
          >
            Clear
          </div>
        </div>
      )}
      <div className={classNames(styles.errorMsg, "caption small")}>
        {name && errors[name] ? errors[name] : ""}
      </div>
    </div>
  );
};

export default React.memo(CompaniesMultiSelectSearch);

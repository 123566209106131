import React, { useState } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import styles from "./upcommingEvent.module.scss";
import { Pannel } from "../../primitives";
import { eventsCurrnetListGet } from "../../events/viewEvent/viewEvent.action";
import EventComponent from "./event";
import { useScrollPagination } from "../../../common/hooks";

const UpcommingEventComponent = () => {
  const dispatch = useDispatch();
  const scrollPagination = useScrollPagination({ pageSize: 4 });
  const [page, setPage] = useState(1);
  const history = useHistory();
  const {
    councilReducer: { selectedCouncil },
    eventsReducer: {
      viewEventReducer: { eventsList, eventsShouldUpdate },
    },
  } = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (eventsShouldUpdate) {
      dispatch(
        eventsCurrnetListGet({
          councilId: selectedCouncil.id,
          currentPage: page,
          pageSize: 6,
          enqueueSnackbar,
        }),
      );
    }
  }, [eventsShouldUpdate]);

  React.useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        eventsCurrnetListGet({
          councilId: selectedCouncil.id,
          currentPage: page,
          pageSize: 30,
          enqueueSnackbar,
        }),
      );
    }
  }, [page, selectedCouncil && selectedCouncil.id]);

  const handleOpenEventPage = (event) => {
    history.push(`/events/id/${event.id}`);
  };

  const handleSeeMore = () => {
    history.push("/events");
  };

  return (
    <Pannel
      title="Upcoming Events"
      padding="0"
      parentStyle={{ background: "Transparent" }}
    >
      <div
        className={classNames(
          styles.wrapper,
          scrollPagination.pageSetting.current > 1 && styles.showScroll,
        )}
        onScroll={scrollPagination.onScroll}
      >
        {eventsList[`${page}`]
          && eventsList["1"][0]
          && eventsList[`${page}`]
            .map((event) => (
              <div key={event.id} onClick={() => handleOpenEventPage(event)}>
                <EventComponent event={event} />
              </div>
            ))
            .slice(0, 3)}

        <div onClick={handleSeeMore} className={styles.btnWrp}>
          <span>See All Events</span>
        </div>
      </div>
    </Pannel>
  );
};

export default React.memo(UpcommingEventComponent);

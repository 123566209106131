export const moreOptionTypes = {
  share: 1,
  discuss: 2,
  addToList: 3,
  downloadReport: 4,
  edit: 5,
  delete: 6,
};

export const moreOptions = [
  // {
  //   value: moreOptionTypes.share,
  //   text: 'Share',
  // },
  // {
  //   value: moreOptionTypes.discuss,
  //   text: 'Discuss',
  // },
  // {
  //   value: moreOptionTypes.addToList,
  //   text: 'Add to list',
  // },
  // {
  //   value: moreOptionTypes.downloadReport,
  //   text: 'Download Report',
  // },
  {
    val: moreOptionTypes.edit,
    text: "Edit",
  },
  {
    val: moreOptionTypes.delete,
    text: "Delete",
  },
];

export const attendeesList = [
  {
    id: "Dusana Semenov",
    first: "Dusana",
    last: "Semenov",
    position: "CEO - Carter Enterprises",
  },
  {
    id: "Homayoun Shakibaii",
    first: "Homayoun",
    last: "Shakibaii",
    position: "CEO - Carter Enterprises",
  },
  {
    id: "Jel Chibuzo",
    first: "Jel",
    last: "Chibuzo",
    position: "CEO - Carter Enterprises",
  },
  {
    id: "Amelia Edwards",
    first: "Amelia",
    last: "Edwards",
    position: "CEO - Carter Enterprises",
  },
  {
    id: "Pan Su",
    first: "Pan",
    last: "Su",
    position: "CEO - Carter Enterprises",
  },
];

import React, { useRef, useState, useEffect, useMemo } from "react";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import {
  Label,
  TextBox,
  Button,
  BtnType,
} from "../../../../primitives";
import { initModel, validation } from "./addEditProjectsField.constants";
import { ModalBody, ModalFooter } from "../../../../../application/modal";
import { useModelPopup } from "../../../../../common/hooks";
import {
  submitProjectGroup,
  updateProjectGroup,
} from "modules/admin/adminProjects/adminProjects.actions";
import styles from "modules/primitives/textbox/textbox.module.scss";

const AddEditProjectsGroupModal = ({ mode, data, existingGroups, stageID, requestNewDataCb }) => {
  const formRef = useRef();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [model, setModel] = useState(initModel);
  const [groupNameAlreadyExists, setGroupNameAlreadyExists] = useState(false);

  useEffect(() => {
    if (existingGroups?.length && !data) {
      const existingPositions = existingGroups.map(g => g.position);
      const getMax = Math.max(...existingPositions);

      setModel({
        ...initModel,
        position: getMax + 1,
        existingPositions,
      });
    }
  }, [existingGroups, data]);

  useEffect(() => {
    if (data) {
      setModel({
        ...initModel,
        name: data.name,
        position: data.position,
        description: data.description,
        id: data.id,
      });
    } else {
      const existingPositions = existingGroups.map(g => g.position);
      const getMax = existingPositions.length ? Math.max(...existingPositions) : 0;

      setModel({
        ...initModel,
        position: getMax + 1,
      });
    }
  }, [data, existingGroups]);

  const handleDismiss = () => {
    popup.hide();
  };

  const onSubmitForm = (values, resetForm) => {
    const payload = {
      name: values.name,
      description: values.description,
      position: values.position,
      active: values.active,
      stageID,
    };

    if (data?.id) {
      dispatch(updateProjectGroup({
        data: {
          ...payload,
          id: data.id,
        },
        stageID,
        enqueueSnackbar,
        cb: () => {
          resetForm();
          enqueueSnackbar('Successfully updated group', {
            variant: "success",
          });
          handleDismiss();
          if (requestNewDataCb) {
            requestNewDataCb()
          }
        },
      }));
    } else {
      dispatch(submitProjectGroup({
        data: payload,
        stageID,
        enqueueSnackbar,
        cb: () => {
          resetForm();
          enqueueSnackbar('Successfully added new group', {
            variant: "success",
          });
          handleDismiss();
          if (requestNewDataCb) {
            requestNewDataCb()
          }
        },
      }));
    }
  };

  const existingNames = useMemo(() => {
    if (data?.id) {
      return existingGroups
        .filter(g => g.id !== data.id)
        .map(g => g.name.toLowerCase());
    }

    return existingGroups.map(g => g.name.toLowerCase());
  }, [existingGroups, data]);

  const handleGroupNameChange = (evt) => {
    const value = evt.target.value || '';
    const nameAlreadyExists = existingNames.some(name => name === value.toLowerCase());

    setGroupNameAlreadyExists(nameAlreadyExists);
  }

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={model}
      validationSchema={validation}
      onSubmit={(values, { resetForm }) => {
        onSubmitForm(values, resetForm);
      }}
    >
      {({ handleSubmit, setFieldValue, values, ...formikprops }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className={classNames(groupNameAlreadyExists ? 'mb-3' : '')}>
              <label>Group Name</label>
              <TextBox
                name="name"
                placeholder="Group Name"
                formProps={{ ...formikprops, values }}
                className="w-100"
                value={values.field_name}
                onChange={handleGroupNameChange}
              />
              {
                groupNameAlreadyExists && (
                  <div className={classNames(styles.errorMsg, "caption small")}>
                    Group name already exists!
                  </div>
                )
              }
            </div>
            <div>
              <label>Description</label>
              <TextBox
                name="description"
                placeholder="Description"
                formProps={{ ...formikprops, values }}
                className="w-100"
                value={values.instructions}
              />
            </div>
            <div className="w-100">
              <Label>Position</Label>
              <TextBox
                type="Number"
                name="position"
                formProps={{ ...formikprops, values }}
                className="w-100"
                min={values.position}
              />
            </div>
            {/*<div className="form-row d-flex align-items-center ml-1 mt-2">*/}
            {/*  <CheckBox*/}
            {/*    isChecked={values.active}*/}
            {/*    onChange={(value) => setFieldValue("active", value)}*/}
            {/*  />*/}
            {/*  <Label*/}
            {/*    onClick={() => setFieldValue("active", !values.active)}*/}
            {/*    className="cursor-pointer"*/}
            {/*  >*/}
            {/*    Set Active*/}
            {/*  </Label>*/}
            {/*</div>*/}
          </ModalBody>
          <ModalFooter>
            <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
              Cancel
            </Button>
            <Button
              type="submit"
              btn={BtnType.HIGHLIGHT}
              className="ml-3"
              disabled={groupNameAlreadyExists}
            >
              {mode === "edit" ? "Save" : "Add"}
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default AddEditProjectsGroupModal;

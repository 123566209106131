import { ofType, combineEpics } from "redux-observable";

import {
  switchMap, map, catchError,
} from "rxjs/operators";

import {
  PROJECT_RATINGS_ALL_GET_ATTEMPT,
  projectRatingsAllGetSuccess,
  PROJECT_RATINGS_BY_CATEGORY_GET_ATTEMPT,
  projectRatingsByCategoryGetSuccess,
  projectTemplateRatingsAllGetSuccess,
  PROJECT_TEMPLATE_RATINGS_ALL_GET_ATTEMPT,
  handleErrorSuccess,
} from "./ratings.actions";

import { errorHandler, httpGet } from "../../../../../common/httpCall";

export const epicProjectTemplateRatingsAllGet = (action$) => action$.pipe(
  ofType(PROJECT_TEMPLATE_RATINGS_ALL_GET_ATTEMPT),
  switchMap(({ payload: { projectId, enqueueSnackbar } }) => httpGet({
    call: `projects/${projectId}/ratings/by_project`,
  }).pipe(
    map((result) => projectTemplateRatingsAllGetSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);
export const epicProjectRatingsGetAll = (action$) => action$.pipe(
  ofType(PROJECT_RATINGS_ALL_GET_ATTEMPT),
  switchMap(({ payload: { projectId, enqueueSnackbar } }) => httpGet({
    call: `projects/${projectId}/ratings?project_id=${projectId}`,
  }).pipe(
    map((result) => projectRatingsAllGetSuccess(result)),
    catchError((err) => errorHandler(
      err,
      enqueueSnackbar,
      [
        {
          message: "No data found",
          type: 400,
        },
      ],
      handleErrorSuccess,
    )),
  )),
);

export const epicProjectRatingByCategory = (action$) => action$.pipe(
  ofType(PROJECT_RATINGS_BY_CATEGORY_GET_ATTEMPT),
  switchMap(({ payload: { projectId, companyId, enqueueSnackbar } }) => httpGet({
    call: `projects/${projectId}/ratings/by_company_user_category?project_id=${projectId}&company_id=${companyId}`,
  }).pipe(
    map((result) => projectRatingsByCategoryGetSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const projectRatingsEpic = combineEpics(
  epicProjectRatingsGetAll,
  epicProjectRatingByCategory,
  epicProjectTemplateRatingsAllGet,
);

export default projectRatingsEpic;

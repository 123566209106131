import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { checkViewerRole } from "common/checkers/viewerChecker";
import { Search, Button, BtnType } from "../../../../primitives";
import { Icon, mdSize } from "../../../../../common/icon";
import styles from "./simpleSearch.module.scss";
import { layoutList } from "../../companyMain.constant";
import {
  queryStringUrlReplacement,
  queryParamStringToArray,
  useQuery,
} from "../../../../../common/helper";
import ChartsDownload from "../../chartsDownload";
import {
  setChartsIntroActive,
  setChartType,
  updateBubbleChartOptions,
} from "../../companyMain.action";
import CrunchbaseComponent from "../../../crunchbase/crunchbase.component";
import { useModelPopup } from "../../../../../common/hooks";
import CompaniesDownload from "modules/companies/companyMain/rightWindow/companiesDownload";

const SimpleSearchComponent = (props) => {
  const {
    handleLayout,
    layout: selectedLayout,
    handleCrunchBase,
    handleSaveSearch,
    showMyList,
    query,
    location,
    selectModeIsActive,
    setDownloadPdfIsActive,
    selectedCompanies,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const relationShipStatus = useQuery().get("relationShipStatus");
  const toggleFilter = useQuery().get("toggle");

  const {
    companiesReducer: {
      companyMainReducer: {
        searchResults,
        charts: { chartsIntroIsActive, chartOptions },
      },
    },
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: { countryFilters, checkedPatientFilters },
    },
    councilReducer: { selectedCouncil },
    appReducer: { relationShipStatuses },
    authReducer: { session },
  } = useSelector((state) => state);

  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [showAddToList, setShowAddToList] = useState(false);
  const [kyndrylBoxes, setKyndrylBoxes] = useState([]);

  useEffect(() => {
    if (
      selectedCouncil?.traction_tag === "kyndryl" &&
      relationShipStatuses?.length
    ) {
      const selectedItems = queryParamStringToArray(relationShipStatus || "");

      const alliance =
        relationShipStatuses.find((s) => s.slug === "alliance") || {};
      const innovation =
        relationShipStatuses.find((s) => s.slug === "innovation") || {};
      const supplier =
        relationShipStatuses.find((s) => s.slug === "supplier") || {};

      setKyndrylBoxes([
        {
          ...alliance,
          selected: selectedItems.includes(alliance.customId),
        },
        {
          ...innovation,
          selected: selectedItems.includes(innovation.customId),
        },
        {
          ...supplier,
          selected: selectedItems.includes(supplier.customId),
        },
      ]);
    }
  }, [selectedCouncil, relationShipStatuses, relationShipStatus]);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (selectModeIsActive) {
      setShowAddToList(false);
      return;
    }

    if (location?.state) {
      const state = location?.state;
      const hasQueryParams =
        (state.scoreFrom?.length && state.scoreFrom !== "0") ||
        (state.fundingFrom && state.fundingFrom !== "0") ||
        state.scoreTo?.length ||
        state.fundingTo ||
        state.foundedFrom ||
        state.foundedTo ||
        state.employees ||
        state.estimatedRevenue ||
        state.agreements ||
        state.owners?.length ||
        state.relationShipStatus?.length ||
        state.location?.length ||
        state.industry?.length ||
        state.topic?.length ||
        state.business_unit?.length ||
        state.k_country?.length ||
        state.idbEcosystem?.length ||
        state.query?.length ||
        state.council_relationship_stage_id?.length ||
        state.lists?.length ||
        state.custom_tags_ids;
      setShowAddToList(hasQueryParams && searchResults?.length);
    } else {
      setShowAddToList(false);
    }
  }, [location?.state, searchResults, selectModeIsActive]);

  const handleBlur = (val) => {
    // hack to stop navigation when user clicked item from results and needs
    // to navigate to company page
    if (history.location.pathname.includes("/companies/")) {
      return;
    }

    if (history.location?.state?.query === val) {
      return;
    }

    // const title = getTitleByValue(selectedLayout);

    let search = queryStringUrlReplacement(
      history.location.search,
      "query",
      val
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      // pathname: selectedLayout === layoutType.card ? "/companies" : `/companies/${title}`,
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        page: 1,
        query: val,
      },
    });
  };

  const handleCrunchbaseClick = () => {
    handleCrunchBase(true);
  };

  const handleIntroClick = () => {
    localStorage.removeItem("chartsIntro");
    dispatch(setChartsIntroActive(true));
  };

  const handleAddClick = () => {
    popup.show({
      title: "Add Company",
      component: <CrunchbaseComponent />,
      fullScreen: false,
    });
  };

  const handleGoToBubbleCharts = () => {
    const newOptions = {
      ...chartOptions,
      y: "totalFunding",
      size: "numberOfCompanies",
      x: "numberOfEmployees",
      color: chartOptions.color,
    };

    dispatch(updateBubbleChartOptions(newOptions));
    dispatch(setChartType("bubble"));

    const timeout = setTimeout(() => {
      handleLayout(2);
      clearTimeout(timeout);
    }, 200);
  };

  const handleKyndrylNavigation = (id, isSelected) => {
    let stageQueryValue;

    if (isSelected) {
      const selectedItems = queryParamStringToArray(relationShipStatus || "");
      stageQueryValue = selectedItems
        .filter((i) => i !== id)
        .reduce((acc, elem) => `${acc}${acc.length ? "," : ""}${elem}`, "");
    } else {
      stageQueryValue = id;
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      "relationShipStatus",
      stageQueryValue
    );

    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        relationShipStatus: stageQueryValue,
        page: 1,
      },
    });
  };

  const checkIsActive = useCallback(
    (id) => {
      const arr = queryParamStringToArray(relationShipStatus || "");

      return arr.includes(id) ? styles.activeKyndrylSection : "";
    },
    [relationShipStatus]
  );

  return (
    <div
      id="searchWrapper"
      className={`${styles.simpleSearchWrapper} ${
        selectedLayout === 2 ? styles.smallPadding : ""
      }`}
    >
      <div className="d-flex col pr-0">
        <div className={styles.buttonWrp}>
          {selectedLayout !== 2 ? (
            <div
              className={classNames(
                styles.containerFluid,
                "container-fluid",
                "pr-0"
              )}
            >
              <div className="row">
                <div className={classNames("col-7")}>
                  <Search
                    handleBlur={handleBlur}
                    onKeyUp
                    className="w-100"
                    redirectProp={query}
                    handleCrunchBase={handleCrunchBase}
                    placeholder="Search Companies, Technologies, and Industries"
                    type="simple"
                    from="companies"
                  />
                </div>
                <div
                  className={classNames(
                    "d-flex flex-row col-5  justify-content-end"
                  )}
                >
                  <Button
                    btn={BtnType.FRAME_LESS}
                    className={styles.searchBtns}
                    onClick={handleCrunchbaseClick}
                  >
                    Search Crunchbase
                  </Button>
                  <Button
                    btn={BtnType.REGULAR}
                    className={classNames(styles.searchBtns, "")}
                  >
                    Search Traction
                  </Button>
                </div>
              </div>
              <div
                className={classNames(
                  styles.lastRow,
                  "d-flex flex-row justify-content-end col-12"
                )}
              >
                {showAddToList ? (
                  <Button
                    onClick={handleSaveSearch}
                    btn={BtnType.FRAME_LESS}
                    className={styles.searchBtns}
                  >
                    Save search as list
                  </Button>
                ) : null}

                <Button
                  onClick={handleAddClick}
                  btn={BtnType.FRAME_LESS}
                  className={styles.searchBtns}
                >
                  + Add Company
                </Button>
                <CompaniesDownload
                  className={styles.reportBtns}
                  location={location}
                  countryFilters={countryFilters}
                  checkedPatientFilters={checkedPatientFilters}
                  setDownloadPdfIsActive={setDownloadPdfIsActive}
                  layout={selectedLayout}
                  showConfirmationPopup={selectedCompanies?.length === 0}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={`${styles.gridWrp} align-items-center justify-content-between`}
      >
        <div className="d-flex align-items-center">
          {layoutList
            .filter((layout) => {
              if (
                selectedCouncil?.traction_tag === "ford" &&
                layout.value === 2
              ) {
                return false;
              }
              return !(toggleFilter === "2" && layout.value === 2);
            })
            .map((layout, inx) => (
              <div key={inx} className={!showMyList && styles.gridIconWrp}>
                {!showMyList && (
                  <div className={styles.gridIconCon}>
                    {layout.value !== 2 ? (
                      <Icon
                        {...mdSize}
                        icon={layout.icon}
                        className={classNames(
                          styles.icon,
                          layout.value === selectedLayout && styles.active
                        )}
                        onClick={(e) => {
                          handleLayout(layout.value);
                        }}
                      />
                    ) : (
                      <div className="position-relative">
                        <span
                          onClick={() => handleLayout(layout.value)}
                          className={
                            selectedLayout === 2 ? "" : styles.notActiveCharts
                          }
                        >
                          <svg
                            width="16px"
                            height="15px"
                            viewBox="0 0 24 24"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title>Chart Icon</title>
                            <defs>
                              <rect
                                id="path-1"
                                x="0"
                                y="0"
                                width="24"
                                height="24"
                              />
                            </defs>
                            <g
                              id="Charts"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <g id="icons-/-Views-/-Card">
                                <g id="icons-/-Views-/-Card-(Background/Mask)" />
                                <path
                                  d="M24,0 L24,24 L0,24 L0,0 L24,0 Z"
                                  id="Chart-Icon"
                                  fillRule="nonzero"
                                  mask="url(#mask-2)"
                                />
                                <path
                                  d="M5.14285714,7.97419322 L5.14285714,24.7741935 L0,24.7741935 L0,7.97419322 L5.14285714,7.97419322 Z M14.3999993,0.774193548 L14.3999993,24.7741935 L9.60000065,24.7741935 L9.60000065,0.774193548 L14.3999993,0.774193548 Z M24,14.4884793 L24,24.7741935 L19.2000013,24.7741935 L19.2000013,14.4884793 L24,14.4884793 Z"
                                  id="Vector"
                                  fill="#000000"
                                  mask="url(#mask-2)"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                        {!chartsIntroIsActive && selectedLayout === 2 && (
                          <span
                            onClick={handleIntroClick}
                            className={styles.introQuestionMark}
                          >
                            <svg
                              width="13px"
                              height="13px"
                              viewBox="0 0 416.979 416.979"
                            >
                              <g>
                                <path
                                  d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85
                                      c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M208.554,334.794
                                      c-11.028,0-19.968-8.939-19.968-19.968s8.939-19.969,19.968-19.969c11.028,0,19.968,8.939,19.968,19.969
                                      C228.521,325.854,219.582,334.794,208.554,334.794z M241.018,214.566c-11.406,6.668-12.381,14.871-12.43,38.508
                                      c-0.003,1.563-0.008,3.14-0.017,4.726c-0.071,11.172-9.147,20.18-20.304,20.18c-0.044,0-0.088,0-0.131,0
                                      c-11.215-0.071-20.248-9.22-20.178-20.436c0.01-1.528,0.013-3.047,0.016-4.552c0.05-24.293,0.111-54.524,32.547-73.484
                                      c26.026-15.214,29.306-25.208,26.254-38.322c-3.586-15.404-17.653-19.396-28.63-18.141c-3.686,0.423-22.069,3.456-22.069,21.642
                                      c0,11.213-9.092,20.306-20.307,20.306c-11.215,0-20.306-9.093-20.306-20.306c0-32.574,23.87-58.065,58.048-61.989
                                      c35.2-4.038,65.125,16.226,72.816,49.282C297.824,181.361,256.555,205.485,241.018,214.566z"
                                />
                              </g>
                            </svg>
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          {selectedCouncil?.traction_tag === "kyndryl" ? (
            <div
              className={`d-flex align-items-center ml-5 ${styles.kyndrylCustomItems}`}
            >
              {kyndrylBoxes.map((b) => (
                <div
                  key={`kyndryl-${b.customId}`}
                  onClick={() =>
                    handleKyndrylNavigation(b.customId, b.selected)
                  }
                  className={`mr-3 ${checkIsActive(b.customId)}`}
                  style={{
                    background: b.colors?.background,
                    color: b.colors?.text,
                  }}
                >
                  {b.name}
                </div>
              ))}
              <div
                className={styles.kyndrylBubbles}
                onClick={handleGoToBubbleCharts}
              >
                <span>Charts</span>
                <div className={styles.bubble1}></div>
                <div className={styles.bubble2}></div>
                <div className={styles.bubble3}></div>
                <div className={styles.bubble4}></div>
                <div className={styles.bubble5}></div>
                <div className={styles.bubble6}></div>
              </div>
            </div>
          ) : null}
        </div>
        {selectedLayout === 2 && (
          <>
            <ChartsDownload location={location} />
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(SimpleSearchComponent);

import { combineEpics, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";

import { errorHandler, httpPost } from "../../../../../common/httpCall";
import {
  GET_KPIS_TABLE_DATA,
  getKPIsClientPlantsSuccess,
  getKPIsInnovationCategorySuccess,
  getKPIsNextPhaseChangeSuccess,
  getKPIsNrOfProjectsSuccess,
  getKPIsNrsDataSuccess,
  getKPIsReplicationsNrSuccess,
  getKPIsTableDataSuccess,
  KPIS_GET_CLIENT_PLANTS,
  KPIS_GET_INNOVATION_CATEGORY,
  KPIS_GET_NR_OF_PROJECTS,
  KPIS_GET_NRS_DATA,
  KPIS_GET_REPLICATIONS_NR,
  KPIS_NEXT_PHASE_CHANGE,
} from "modules/reporting/fordReports/kpis/store/kpis.actions";

export const epicKPIsGetNrOfProjects = (action$) => action$.pipe(
  ofType(KPIS_GET_NR_OF_PROJECTS),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: 'ford/home_page/projects_by_stage',
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getKPIsNrOfProjectsSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicKPIsGetNrsData = (action$) => action$.pipe(
  ofType(KPIS_GET_NRS_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: 'ford/home_page/statistics',
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getKPIsNrsDataSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicKPIsGetClientPlants = (action$) => action$.pipe(
  ofType(KPIS_GET_CLIENT_PLANTS),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: 'ford/home_page/projects_by_plant',
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getKPIsClientPlantsSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicKPIsGetInnovationCategory = (action$) => action$.pipe(
  ofType(KPIS_GET_INNOVATION_CATEGORY),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: 'ford/home_page/projects_by_innovation_category',
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getKPIsInnovationCategorySuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicKPIsGetNextStage = (action$) => action$.pipe(
  ofType(KPIS_NEXT_PHASE_CHANGE),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: 'ford/home_page/projects_by_next_stage_change',
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getKPIsNextPhaseChangeSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicKPIsGetNrReplications = (action$) => action$.pipe(
  ofType(KPIS_GET_REPLICATIONS_NR),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: 'ford/home_page/replications_by_plant',
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getKPIsReplicationsNrSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetKpisCustomSectionTableData = (action$) => action$.pipe(
  ofType(GET_KPIS_TABLE_DATA),
  switchMap(({ payload: { data, enqueueSnackbar, reducerKey, page = 1, highLightColumn } }) => httpPost({
    call: `ford/home_page/table?page=${page}&items=20`,
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getKPIsTableDataSuccess({
      ...result,
      reducerKey,
      highLightColumn,
    })),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const fordKPIsEpic = combineEpics(
  epicKPIsGetNrOfProjects,
  epicKPIsGetClientPlants,
  epicKPIsGetInnovationCategory,
  epicKPIsGetNrReplications,
  epicKPIsGetNrsData,
  epicKPIsGetNextStage,
  epicGetKpisCustomSectionTableData,
);

export default fordKPIsEpic;

import {
  IS_FEED_OPENED,
  POST_REPLY_GET_ATTEMPT,
  POST_REPLY_GET_SUCCESS,
  POST_REPLY_ADD_ATTEMPT,
  POST_REPLY_ADD_SUCCESS,
  LIKE_REPLY_SUCCESS,
  DISLIKE_REPLY_SUCCESS,
  SELECT_REPLY_AS_FAVORITE_SUCCESS,
  DESELECT_REPLY_AS_FAVORITE_SUCCESS,
  POST_REPLY_DELETE_SUCCESS,
  LIKE_REPLY_ATTEMPT,
  DISLIKE_REPLY_ATTEMPT,
  SELECT_REPLY_AS_FAVORITE_ATTEMPT,
  DESELECT_REPLY_AS_FAVORITE_ATTEMPT,
  POST_REPLY_EDIT_SUCCESS,
  POST_REPLY_EDIT_ATTEMPT,
} from "./feed.action";

const initState = {
  isFeedOpened: false,
  feedLoading: false,
  shouldUpdateFeed: false,
  scrollToLastMessage: false,
  postRepliesList: [],
  likePending: false,
  favPending: false,
};

const themesFeedReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case IS_FEED_OPENED:
      return {
        ...state,

        isFeedOpened: payload,
      };

    case POST_REPLY_GET_ATTEMPT:
      return {
        ...state,
        loadingPostReplies: true,
      };
    case POST_REPLY_GET_SUCCESS:
      return {
        ...state,
        postRepliesList: {
          ...payload.response,
          theme_post_replies: payload.response.theme_post_replies,
        },
        loadingPostReplies: false,
      };
    case POST_REPLY_ADD_ATTEMPT:
      return {
        ...state,
        scrollToLastMessage: false,
        loadingPostReplies: true,
      };
    case POST_REPLY_ADD_SUCCESS:
      return {
        ...state,
        scrollToLastMessage: true,
        postRepliesList: {
          theme_post_replies: payload.response,
        },
        loadingPostReplies: false,
      };

    case LIKE_REPLY_ATTEMPT:
      return {
        ...state,
        likePending: true,
      };
    case LIKE_REPLY_SUCCESS:
      return {
        ...state,
        postRepliesList: {
          ...payload.response,
          theme_post_replies: payload.response.theme_post_replies,
        },
        likePending: false,
      };

    case DISLIKE_REPLY_ATTEMPT:
      return {
        ...state,
        likePending: true,
      };

    case DISLIKE_REPLY_SUCCESS:
      return {
        ...state,
        postRepliesList: {
          ...payload.response,
          theme_post_replies: payload.response.theme_post_replies,
        },
        likePending: false,
      };
    case SELECT_REPLY_AS_FAVORITE_SUCCESS:
      return {
        ...state,
        postRepliesList: {
          ...payload.response,
          theme_post_replies: payload.response.theme_post_replies,
        },
        favPending: false,
      };
    case DESELECT_REPLY_AS_FAVORITE_SUCCESS:
      return {
        ...state,
        postRepliesList: {
          ...payload.response,
          theme_post_replies: payload.response.theme_post_replies,
        },
        favPending: false,
      };
    case POST_REPLY_DELETE_SUCCESS:
      return {
        ...state,
        postRepliesList: {
          theme_post_replies: payload.response,
        },
      };
    case SELECT_REPLY_AS_FAVORITE_ATTEMPT:
      return {
        ...state,
        favPending: true,
      };
    case DESELECT_REPLY_AS_FAVORITE_ATTEMPT:
      return {
        ...state,
        favPending: true,
      };
    case POST_REPLY_EDIT_ATTEMPT:
      return {
        ...state,
        postRepliesList: {
          theme_post_replies: [
            ...state.postRepliesList.theme_post_replies.map((el) => {
              if (el.theme_post_reply_id === payload.reply_id) {
                return { ...el, loading: true };
              }
              return el;
            }),
          ],
        },
      };
    case POST_REPLY_EDIT_SUCCESS:
      return {
        ...state,
        postRepliesList: {
          theme_post_replies: payload.response,
        },
      };

    default:
      return state;
  }
};
export default themesFeedReducer;

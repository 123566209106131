import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Draggable } from "react-smooth-dnd";
import classNames from "classnames";
import { useSnackbar } from "notistack";

import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../../common/hooks";
import KanbanCompany from "../kanbanCompanies/index";
import checkThemePipelinePermissions from "../../../../../../common/checkThemePipelinePermissions";
import InvestmentCFDetailsRedirect from "../../../../../../common/handleInvestmentPopUp";

const KanbanStageComponent = ({
  fromThemes,
  stage,
  changeCompanyStage,
  setCurrentCompany,
  companySelected,
  investStages,
  investMode,
  selectedCouncil,
  decisionMakerAccess,
  userRole,
  councilName,
  deleteCompanyStage,
  stages,
  setIsDraggingCompany,
}) => {
  const [showMoreOnHover, setShowMoreOnHover] = useState(false);
  const popup = useModelPopup();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const cleanClasses = () => {
      document.body.className = "";
    };

    document.addEventListener("touchend", cleanClasses, false);

    return () => {
      document.removeEventListener("touchend", cleanClasses, false);
    };
  }, []);

  const handleDragDrop = (dropResult, targetStage, fromOptions, company) => {
    let data = {};
    const dataFromOptions = {};

    if (
      investMode ||
      checkThemePipelinePermissions(
        targetStage.name,
        userRole,
        councilName,
        decisionMakerAccess,
        company?.council_relationship_stage_name
      )
    ) {
      if (fromOptions && !investMode) {
        dataFromOptions.council_relationship_stage_id = targetStage.val;
      } else if (fromOptions && investMode) {
        dataFromOptions.theme_investment_stage_id = targetStage.val;
      }

      if (!investMode) {
        const id =
          dataFromOptions.council_relationship_stage_id ||
          targetStage.council_relationship_stage_id;
        if (fromThemes) {
          data = {
            council_relationship_stage_id: id,
            council_company_in_stage_position: dropResult.addedIndex + 1 || 1,
            theme_investment_stage_id: stage?.theme_investment_stage_id,
            partnership: true,
            investment: investMode,
          };
        } else {
          data = {
            theme_company: {
              council_relationship_stage_id: id,
              relationship_stage_id: id,
              council_company_in_stage_position: dropResult.addedIndex + 1 || 1,
              position: dropResult.addedIndex + 1,
              // theme_investment_stage_id: investStageId,
              partnership: true,
            },
          };
        }
      } else {
        const id =
          dataFromOptions.theme_investment_stage_id ||
          targetStage.theme_investment_stage_id;
        if (fromThemes) {
          data = {
            council_relationship_stage_id: id,
            // relationship_stage_id: id,
            council_company_in_stage_position: dropResult.addedIndex + 1 || 1,
            theme_investment_stage_id: id,
            partnership: true,
          };
        } else {
          data = {
            theme_company: {
              theme_investment_stage_id: id,
              relationship_stage_id: id,
              council_company_in_stage_position: dropResult.addedIndex + 1 || 1,
              // theme_investment_stage_id: investStageId,
              // partnership: true,
            },
          };
        }
      }

      changeCompanyStage(data);
    } else {
      enqueueSnackbar("You are not allowed to move companies in this stage!", {
        variant: "error",
      });
    }
  };
  const handleInvestmentRedirect = (targetStage, slug) => {
    const stageName = targetStage.name;

    history.push({
      pathname: `/companies/${slug}/details`,
      state: { isFromInvestmentTheme: true, targetStage: stageName },
    });
  };

  const checkStage = (targetStageName) => {
    return (
      targetStageName === "Due Diligence" ||
      targetStageName === "Closing" ||
      targetStageName === "Portfolio" ||
      targetStageName === "Exit" ||
      targetStageName === "Dismiss"
    );
  };

  const handleReleaseDragNDrop = (dropResult, targetStage, fromOptions) => {
    setIsDraggingCompany(false);
    if (
      councilName === "Firmenich" &&
      investMode &&
      checkStage(targetStage?.theme_investment_stage_name)
    ) {
      popup.show({
        title: " ",
        show: true,
        height: "300",
        width: "540",
        component: (
          <InvestmentCFDetailsRedirect
            handleInvestmentRedirect={handleInvestmentRedirect}
            targetStage={{
              ...targetStage,
              name: targetStage?.theme_investment_stage_name,
            }}
            popup={popup}
            companySlug={companySelected.slug}
            fromOptions={fromOptions}
          />
        ),
      });
    }
    handleDragDrop(dropResult, targetStage, fromOptions, companySelected);
  };

  const getCompaniesOrProductsNr = useMemo(() => {
    const companiesNr =
      stage.theme_companies?.filter((c) => !c.is_product)?.length || 0;
    const productsNr =
      stage.theme_companies?.filter((c) => c.is_product)?.length || 0;
    const companiesLabel = `${companiesNr} ${
      companiesNr === 1 ? "Company" : "Companies"
    }`;

    if (productsNr > 0) {
      return `${companiesNr > 0 ? `${companiesLabel} and ` : ""}${
        productsNr === 1 ? "1 Product" : `${productsNr} Products`
      }`;
    }

    return companiesLabel;
  }, [stage]);
  return (
    <div className={styles.stageContainer}>
      <div
        className={classNames(
          investMode ? styles.investStageWrp : {},
          styles.stageWrp
        )}
      >
        <div className={styles.stageCon}>
          <div className={styles.titleWrp}>
            <div className={classNames("caption", styles.title)}>
              {stage.council_relationship_stage_name ||
                stage.name ||
                stage.theme_investment_stage_name}
              {stage.description?.length > 0 ? (
                <sup
                  onMouseLeave={() => setShowMoreOnHover(false)}
                  onMouseEnter={() => setShowMoreOnHover(true)}
                  className={styles.interrogationMark}
                >
                  ?
                </sup>
              ) : null}
            </div>
            {showMoreOnHover && (
              <div
                onMouseLeave={() => setShowMoreOnHover(false)}
                onMouseEnter={() => setShowMoreOnHover(true)}
                className={styles.moreBlockOnHover}
              >
                {stage.description}
              </div>
            )}
            <span className={styles.companiesNumber}>
              {getCompaniesOrProductsNr}
            </span>
          </div>

          <div className={styles.bodyWrp}>
            <Container
              key={stage.council_relationship_stage_id}
              dropPlaceholder={{ className: styles.dropPlaceholder }}
              onDrop={(e) => {
                if (e?.addedIndex || e?.addedIndex === 0) {
                  handleReleaseDragNDrop(e, stage);
                }
              }}
              groupName="col"
            >
              {stage?.theme_companies?.length > 0 ? (
                stage.theme_companies?.map((company) => {
                  // if (company?.attributes?.resource_type === "product") {
                  //   return null;
                  // }
                  return (
                    <Draggable
                      key={company.id}
                      className={styles.drgContainer}
                      onMouseDown={() => {
                        setIsDraggingCompany(true);
                        setCurrentCompany({
                          ...company,
                          council_relationship_stage_id:
                            stage?.council_relationship_stage_id,
                          theme_investment_stage_id:
                            stage?.theme_investment_stage_id,
                        });
                      }}
                    >
                      <KanbanCompany
                        investStages={investStages}
                        fromThemes={fromThemes}
                        company={{
                          ...company.attributes,
                          company_id: company.id,
                          ...company,
                        }}
                        stages={stages}
                        handleReleaseDragNDrop={handleReleaseDragNDrop}
                        changeCompanyStage={changeCompanyStage}
                        selectedCouncil={selectedCouncil}
                        deleteCompanyStage={deleteCompanyStage}
                      />
                    </Draggable>
                  );
                })
              ) : (
                <Draggable className={styles.drgContainer} />
              )}
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};
export default KanbanStageComponent;

import { apiStatus, pageMeta } from "../../../../common/httpCall";

import {
  APPLICANTS_FORMS_LIST_GET_ATTEMPT,
  APPLICANTS_FORMS_LIST_GET_SUCCESS,
  CREATE_APPLICANT_FORM_ATTEMPT,
  CREATE_APPLICANT_FORM_SUCCESS,
  DELETE_APPLICANT_FORM_ATTEMPT,
  DELETE_APPLICANT_FORM_SUCCESS,
  FORM_CUSTOM_FIELDS_CREATE_ATTEMPT,
  FORM_CUSTOM_FIELDS_CREATE_SUCCESS,
  FORM_CUSTOM_FIELDS_LIST_GET_ATTEMPT,
  FORM_CUSTOM_FIELDS_LIST_GET_SUCCESS,
  GET_APPLICANT_FORM_ATTEMPT,
  GET_APPLICANT_FORM_SUCCESS,
  SET_SELECTED_FORM_CUSTOM_FIELDS,
  UPDATE_APPLICANT_FORM_ATTEMPT,
  UPDATE_APPLICANT_FORM_SUCCESS,
} from "./adminApplicantForms.action";

const initState = {
  form: null,
  formsList: [],
  searchResults: [],
  listLoading: false,
  getLoading: false,
  updateLoading: false,
  createLoading: false,
  deleteLoading: false,
  customFieldsListLoading: false,
  customFieldsGetLoading: false,
  customFieldsCreateLoading: false,
  formCustomFields: [],
};
const adminApplicantFormsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case APPLICANTS_FORMS_LIST_GET_ATTEMPT: {
      return {
        ...state,
        listLoading: true,
      };
    }
    case APPLICANTS_FORMS_LIST_GET_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response) {
          return {
            ...state,
            formsList: response,
            listLoading: false,
            applicantPageNr: pageMeta(payload),
          };
        }
      }
      break;
    }
    case FORM_CUSTOM_FIELDS_LIST_GET_ATTEMPT: {
      return {
        ...state,
        customFieldsGetLoading: true,
      };
    }
    case FORM_CUSTOM_FIELDS_LIST_GET_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response) {
          return {
            ...state,
            formCustomFields: response.map((cf) => ({
              ...cf,
              isChecked: false,
            })),
            customFieldsGetLoading: false,
          };
        }
      }
      break;
    }
    case FORM_CUSTOM_FIELDS_CREATE_ATTEMPT: {
      return {
        ...state,
        customFieldsCreateLoading: true,
      };
    }
    case FORM_CUSTOM_FIELDS_CREATE_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.CREATED && response) {
          return {
            ...state,
            formCustomFields: [
              ...state.formCustomFields,
              { ...response, isChecked: true },
            ],
            customFieldsCreateLoading: false,
          };
        }
      }
      break;
    }
    case SET_SELECTED_FORM_CUSTOM_FIELDS: {
      if (payload) {
        return {
          ...state,
          formCustomFields: state.formCustomFields.map((f) => {
            const checkIsSelected = payload.find(
              (s) => s.isChecked && s.id === f.id,
            );
            return { ...f, isChecked: !!checkIsSelected };
          }),
        };
      }
      break;
    }

    case CREATE_APPLICANT_FORM_ATTEMPT: {
      return {
        ...state,
        createLoading: true,
      };
    }
    case CREATE_APPLICANT_FORM_SUCCESS: {
      if (payload) {
        const { response } = payload;
        return {
          ...state,
          formsList: [...state.formsList, response],
          createLoading: false,
        };
      }
      break;
    }
    case DELETE_APPLICANT_FORM_ATTEMPT: {
      return {
        ...state,
        deleteLoading: true,
      };
    }
    case DELETE_APPLICANT_FORM_SUCCESS: {
      if (payload) {
        const { id } = payload;
        return {
          ...state,
          formsList: state.formsList.filter((form) => form.id !== id),
          deleteLoading: false,
        };
      }
      break;
    }
    case GET_APPLICANT_FORM_ATTEMPT: {
      return {
        ...state,
        getLoading: true,
      };
    }
    case GET_APPLICANT_FORM_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response) {
          return {
            ...state,
            form: response,
            getLoading: false,
          };
        }
      }
      break;
    }

    case UPDATE_APPLICANT_FORM_ATTEMPT: {
      return {
        ...state,
        updateLoading: true,
      };
    }
    case UPDATE_APPLICANT_FORM_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response) {
          return {
            ...state,
            form: response,
            updateLoading: false,
          };
        }
      }
      break;
    }

    default:
      return state;
  }

  return state;
};

export default adminApplicantFormsReducer;

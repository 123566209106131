import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import * as classNames from "classnames";
import isItSmallDevice from "modules/themes/themesCommon/isItSmallDevice";

import ConfirmDeleteComponent from "../../../../../common/components/confirmDelete/index";
import styles from "./styles.module.scss";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import { Pannel, Pagination } from "../../../../primitives";
import { useModelPopup } from "../../../../../common/hooks";
import NewThemeEventComponent from "./addEventModal";
import isItStandardViewver from "../../../themesCommon/isItStandardViewer";

const EventsComponent = ({ themeId, themeEvents = [], currentThemeData }) => {
  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const [seeAll, setSeeAll] = useState(false);
  const [pageSetting, setPageSetting] = useState({
    pageSize: 50,
    current: 1,
    hideOnSinglePage: true,
    showTitle: false,
    total: themeEvents?.length || 0,
  });
  const [chunkToShow, setChunkToShow] = useState([]);
  const [isEventsOpened, setIsEventsOpenned] = useState(true);

  const handlePagingChange = (page) => {
    setPageSetting((prev) => ({
      ...prev,
      total: themeEvents?.length,
      current: page,
    }));
  };

  useEffect(() => {
    if (themeEvents?.length && !seeAll) {
      setChunkToShow(themeEvents.slice(0, 50));
    }
    if (pageSetting?.current && themeEvents?.length && seeAll) {
      setChunkToShow(
        themeEvents?.slice(
          (pageSetting.current - 1) * pageSetting.pageSize,
          pageSetting.current * pageSetting.pageSize
        )
      );
    }
  }, [pageSetting?.current, themeEvents, seeAll]);

  const handleEventRedirect = (id) => {
    history.push(`/events/id/${id}`);
  };

  const handleDeleteEventClick = (eventID) => {
    popup.show({
      title: "Confirmation Delete",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={themeId}
          templateId={eventID}
          enqueueSnackbar={enqueueSnackbar}
          councilId={selectedCouncil?.id}
          actionType="deleteThemeEvent"
        />
      ),
    });
  };

  const handleAddEventClick = (e) => {
    popup.show({
      title: "Add an Event to this Theme",
      component: <NewThemeEventComponent addedList={themeEvents} />,
    });
  };

  return (
    <Pannel
      title="Related Events  "
      padding="0"
      parentStyle={{
        width: `${window.screen.width < 550 ? "100%" : "230px"}`,
        minWidth: "230px",
        padding: `${window.screen.width < 550 && "0px"}`,
      }}
    >
      <div className={styles.panelHeader}>
        Event
        <div className={styles.panelIcons}>
          {isItSmallDevice() && (
            <Icon
              {...mdSize}
              icon="icn-table-down-chevron"
              className={
                isEventsOpened ? styles.rotatedDownChevron : styles.downChevron
              }
              onClick={() => {
                setIsEventsOpenned(!isEventsOpened);
              }}
            />
          )}
          {isItStandardViewver(session, currentThemeData) && (
            <Icon
              {...mdSize}
              icon="icn-add"
              className={styles.addNew}
              onClick={handleAddEventClick}
            />
          )}
        </div>
      </div>
      {isEventsOpened && (
        <div className={styles.wrapper}>
          {themeEvents.length > 0 ? (
            chunkToShow?.map((item) => (
              <>
                <div
                  className={classNames(styles.Row)}
                  key={`${item.theme_event_id}`}
                >
                  <div
                    onClick={() => handleEventRedirect(item.event_id)}
                    className={styles.title}
                  >
                    <span> {item.name} </span>
                    <span className={styles.deleteIcon}>
                      {isItStandardViewver(session, currentThemeData) && (
                        <Icon
                          {...smSize}
                          icon="icn-button-delete"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            return handleDeleteEventClick(item.event_id);
                          }}
                        />
                      )}
                    </span>
                  </div>
                  <span className={styles.dateLabel}>
                    {" "}
                    Starts On:{" "}
                    {format(
                      new Date(item.starts_on),
                      "eeee, MMM do, yyyy"
                    )}{" "}
                  </span>
                </div>
              </>
            ))
          ) : (
            <span className="p-3 text-center">No events yet!</span>
          )}
          {seeAll && (
            <div className={styles.paginationCon}>
              <Pagination {...pageSetting} onChange={handlePagingChange} />
            </div>
          )}
          {themeEvents.length > 6 && (
            <div onClick={() => setSeeAll(!seeAll)} className={styles.showAll}>
              {seeAll ? "HIDE" : "SEE ALL"}
            </div>
          )}
        </div>
      )}
    </Pannel>
  );
};

export default React.memo(EventsComponent);

import React from "react";
import { format } from "date-fns";
import styles from "./funding.module.scss";

const DesktopFundingRow = ({ fund, amount }) => (
  <div className={styles.gridRow}>
    <div className={styles.title}>{fund.series}</div>
    <div className={styles.date}>
      {format(new Date(fund.announced_on), "MMMM yyyy")}
    </div>
    <div className={styles.right}>
      <div className={styles.amount}>$ {amount}</div>
      <div className={styles.description}>{fund.investors}</div>
    </div>
  </div>
);

export default DesktopFundingRow;

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import {
  getKPIsReplicationsNr,
  getKPIsTableData,
  toggleKPIsTable,
} from "modules/reporting/fordReports/kpis/store/kpis.actions";
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import LoadingComponent from "modules/primitives/loading/loading.component";
import genericStyles from '../../styles.module.scss';
import { getChart, initializeChart } from "modules/companies/companyMain/chartsWrapper/chartsView/charts/utils";
import { makeTreeConfig } from "modules/reporting/fordReports/kpis/sections/nrOfReplications/treeUtils";
import { debounce } from "throttle-debounce";
import TreeChart from "modules/reporting/fordReports/kpis/sections/nrOfReplications/treeChart";
import { waitForElm } from "../../../../../../common/helper";

const FordNrOfReplications = ({ nrOfReplications, filters, tableData, downloadIsActive }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef();

  const [chart, setChart] = useState(null);
  const [wrapperWidth, setWrapperWidth] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", debounceResize);

    return () => {
      window.removeEventListener("resize", null);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      setWrapperWidth(ref.current.clientWidth);
    }
  }, [ref.current]);

  const debounceResize = debounce(1000, false, () => {
    if (ref?.current) {
      setWrapperWidth(ref?.current?.clientWidth);
    }
  });

  useEffect(() => {
    if (!nrOfReplications.data) {
      const makeFilters = makeActualScoreCardFilterData(filters);
      const payload = {
        enqueueSnackbar,
        data: makeFilters,
      };

      dispatch(getKPIsReplicationsNr(payload))
    }
  }, []);

  useEffect(() => {
    if (nrOfReplications.data && ref.current) {
      makeChartConfig(nrOfReplications.data);
    }
  }, [nrOfReplications.data, ref]);

  const makeChartConfig = useCallback(
    (data) => {
      waitForElm("#treeChart").then(() => {
        const chartInstanceById = getChart("treeChart");

        if (chartInstanceById) chartInstanceById.destroy();

        const config = makeTreeConfig(data);
        const newChart = initializeChart("treeChart", "treemap", config);

        setChart(newChart);
      });
    },
    [nrOfReplications, chart],
  );

  const returnChartNode = useCallback(
    (width) => {
      if (chart && chart?.width !== width) {
        chart.width = width;
        chart.update();
      }

      return (
        <TreeChart
          width={width}
          chartHeight={300}
          askForRedraw={() => {}}
        />
      );
    },
    [chart],
  );

  const cardHasExpandedTable = useMemo(() => tableData?.expandedKey === 'replications_by_plant',
    [tableData?.expandedKey]);

  const handleShowOrHideTable = useCallback(() => {
    if (tableData?.expandedKey !== 'replications_by_plant') {
      const filtersData = makeActualScoreCardFilterData(filters);
      dispatch(getKPIsTableData({
        reducerKey: 'replications_by_plant',
        data: {
          ...filtersData,
          section: 'replications_by_plant',
        },
      }));

      return;
    }

    dispatch(toggleKPIsTable({
      reducerKey: 'replications_by_plant',
    }));
  }, [tableData, filters]);

  const makeColumnCardStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      border: '1px solid #cbd2d9',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px',
      height: '100%',
      padding: '10px',
    }
  }, [downloadIsActive]);

  const makeTitleStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      color: '#616e7c',
      fontSize: '18px',
      fontFamily: 'lato-regular',
      paddingTop: '10px',
    }
  }, [downloadIsActive]);

  return (
    <div style={makeColumnCardStylesForPdf} className={genericStyles.columnCard} ref={ref}>
      <span style={makeTitleStylesForPdf} className={genericStyles.columnCardTitle}>Replications/Plant</span>
      {
        nrOfReplications.fetching ? (
          <LoadingComponent customText="Getting Replications/Plant..." />
        ) : (
          <div>
            {wrapperWidth && returnChartNode(wrapperWidth - 20)}
            {
              !downloadIsActive && (
                <div className="d-flex justify-content-center mt-3">
                  <span className={genericStyles.showHideSourceData} onClick={() => handleShowOrHideTable()}>
                    {cardHasExpandedTable ? 'HIDE SOURCE DATA' : 'SHOW SOURCE DATA'}
                  </span>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default React.memo(FordNrOfReplications);

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import useModelPopup from "common/hooks/modelPopup.hook";
import TextAreaComponent from "modules/primitives/textarea/textarea.component";
import {
  companyGetFilters,
  companyGetTopics,
} from "modules/companies/companyMain/companyMain.action";
import { editProductDetails } from "modules/companies/product/store/product.actions";
import { BtnType, Button, Label, TextBox } from "modules/primitives";
import { ModalFooter } from "application/modal";
import DropDownNestedElements from "common/components/dropdownNestedElements";

import { formInitialValues } from "./constant";

const EditProductMainInfo = ({ product }) => {
  const popup = useModelPopup();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(formInitialValues);
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: { topics, industries },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil && !topics?.length && !industries?.length) {
      dispatch(
        companyGetFilters({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );

      dispatch(
        companyGetTopics({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil, topics, industries]);

  useEffect(() => {
    if (product) {
      setFormValues({
        ...product,
        topic_ids: product.topic_ids.map((t) => t.id) || [],
        industry_ids: product.industry_ids.map((i) => i.id) || [],
      });
    }
  }, [product]);

  const handleSave = (values) => {
    setShowLoading(true);

    const payload = {
      companyId: product.company.id,
      productId: product.id,
      data: values,
    };

    dispatch(editProductDetails(payload));
    popup.hide();
  };

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleTopicSelect = (value, prop, values) => {
    setFormValues({
      ...values,
      [prop]: [...new Set(value.map((t) => t.id).concat(values[prop]))],
    });
  };

  const handleTopicRemove = (value, prop, values) => {
    setFormValues({
      ...values,
      [prop]: value.map((t) => t.id),
    });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={formValues}
        onSubmit={handleSave}
      >
        {({ values, handleSubmit, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <div className="px-4 mb-4">
              <div className="mt-3">
                <Label>Product Description</Label>
                <TextAreaComponent
                  disabled={showLoading}
                  name="description"
                  placeholder="Product Description"
                  formProps={{
                    ...formikprops,
                    values,
                  }}
                  className="w-100"
                />
              </div>
              <div>
                <Label>Product Link</Label>
                <TextBox
                  disabled={showLoading}
                  type="text"
                  name="product_link"
                  placeholder="Product Link"
                  formProps={{
                    ...formikprops,
                    values,
                  }}
                  className="w-100"
                />
              </div>
              <label htmlFor="topic_ids">Technologies</label>
              <DropDownNestedElements
                tags={topics}
                selectedTags={formValues.topic_ids}
                childKey="children_topics"
                label="Select technologies"
                tagKeyName="id"
                parentKeyName="parent_resource_topic_id"
                handleTagSelect={(arg) => {
                  handleTopicSelect(arg, "topic_ids", values);
                }}
                handleTagRemove={(arg) => {
                  handleTopicRemove(arg, "topic_ids", values);
                }}
                selectedIsObject
              />

              <label htmlFor="industry_ids">
                {selectedCouncil?.name === "Freddie Mac"
                  ? "Housing Cycle"
                  : "Industries"}
              </label>
              <DropDownNestedElements
                tags={industries}
                selectedTags={formValues.industry_ids}
                label="Select industries"
                childKey="children_industries"
                tagKeyName="id"
                parentKeyName="parent_resource_industry_id"
                handleTagSelect={(arg) => {
                  handleTopicSelect(arg, "industry_ids", values);
                }}
                handleTagRemove={(arg) => {
                  handleTopicRemove(arg, "industry_ids", values);
                }}
                selectedIsObject
              />
            </div>
            <ModalFooter>
              <Button
                className="mr-3"
                btn={BtnType.FRAME_LESS}
                onClick={handleCancelClick}
                disabled={showLoading}
              >
                Cancel
              </Button>
              <Button
                disabled={showLoading}
                type="submit"
                onClick={handleSubmit}
                btn={BtnType.REGULAR}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditProductMainInfo;

import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { empty } from "../../../../node_modules/rxjs/index";
import {
  httpGet,
  httpDelete,
  queryString,
  httpPost,
  apiStatus,
  httpPatch,
  errorHandler,
} from "../../../common/httpCall";
import {
  PEOPLE_LIST_CONTACT_ATTEMPT,
  peopleListContactSuccess,
  PEOPLE_DELETE_CONTACT_ATTEMPT,
  PEOPLE_ADD_ORGANIZATION_CONTACT_ATTEMPT,
  peopleContactDeleteSuccess,
  peopleAddOrganizationContactSuccess,
  COMPANY_ADD_CONTACT_ATTEMPT,
  addCompanyContactSuccess,
  COMPANY_EDIT_CONTACT_ATTEMPT,
  editCompanyContactSuccess,
  GET_COMPANY_CONTACT_ATTEMPT,
  companyContactsSuccess,
} from "./companyContacts.action";

export const epicGetContactList = (action$, state$) => action$.pipe(
  ofType(PEOPLE_LIST_CONTACT_ATTEMPT),
  switchMap(
    ({
      payload: {
        currentPage, pageSize, searchValue = "*", enqueueSnackbar,
      },
    }) => httpPost({
      call: `external_contacts/search?${queryString({
        items: pageSize,
        page: currentPage,
      })}`,
      data: {
        query: searchValue || "*",
      },
    }).pipe(
      map((result) => peopleListContactSuccess(result)),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

export const epicDeleteContact = (action$, state$) => action$.pipe(
  ofType(PEOPLE_DELETE_CONTACT_ATTEMPT),
  switchMap(({ payload: { contactId, contactName, enqueueSnackbar } }) => httpDelete({
    call: `/external_contacts/${contactId}`, // NEW_API
  }).pipe(
    map((result) => {
      if (result.status === apiStatus.SUCCESS) {
        enqueueSnackbar(`You deleted the Contact: ${contactName}`, {
          variant: "success",
        });
        return peopleContactDeleteSuccess({
          contactId,
          data: result.response,
        });
      }
    }),
    catchError((e) => errorHandler(e, enqueueSnackbar)),
  )),
);
export const epicAddContact = (action$, state$) => action$.pipe(
  ofType(PEOPLE_ADD_ORGANIZATION_CONTACT_ATTEMPT),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: "gsk/patient_organizations/contacts",
    data, // NEW_API
  }).pipe(
    map((result) => {
      enqueueSnackbar("Contact succesfully added.", {
        variant: "success",
      });

      return peopleAddOrganizationContactSuccess(result);
    }),
    catchError((e) => errorHandler(e, enqueueSnackbar)),
  )),
);
export const epicPostCompanyContact = (action$, state$) => action$.pipe(
  ofType(COMPANY_ADD_CONTACT_ATTEMPT),
  switchMap(
    ({
      payload: {
        enqueueSnackbar, data, company_id, isClaimProfileUse, cb,
      },
    }) => httpPost({
      call: "companies/contacts",
      data,
    }).pipe(
      map((result) => {
        enqueueSnackbar("Contact succesfully added.", {
          variant: "success",
        });
        if (cb) {
          cb();
        }
        return addCompanyContactSuccess({
          ...result,
          companyId: company_id,
          isClaimProfileUse,
        });
      }),
      catchError((e) => {
        if (e.status === 422) {
          if (e.response.errors.messages.email[0] === "must be unique") {
            enqueueSnackbar("This user already exists.", {
              variant: "error",
            });
            return empty();
          }
        }
        enqueueSnackbar("Something went wrong.", {
          variant: "error",
        });

        return empty();
      }),
    ),
  ),
);

export const epicPutCompanyContact = (action$) => action$.pipe(
  ofType(COMPANY_EDIT_CONTACT_ATTEMPT),
  switchMap(
    ({
      payload: {
        company_contact_id, data, enqueueSnackbar, company_id, cb,
      },
    }) => httpPatch({
      call: `companies/contacts/${company_contact_id}`,
      data,
    }).pipe(
      map((result) => {
        enqueueSnackbar("Contact successfully edited.", {
          variant: "success",
        });
        if (cb) {
          cb();
        }
        return editCompanyContactSuccess({
          ...result,
          company_id,
        });
      }),
      catchError((e) => {
        enqueueSnackbar("Something went wrong.", {
          variant: "error",
        });

        return empty();
      }),
    ),
  ),
);

export const epicGetCompanyContactsList = (action$, state$) => action$.pipe(
  ofType(GET_COMPANY_CONTACT_ATTEMPT),
  switchMap(
    ({
      payload: {
        currentPage, pageSize, searchValue = "*", enqueueSnackbar,
      },
    }) => httpGet({
      call: `companies/contacts?search=${searchValue || ""}&${queryString({
        items: pageSize,
        page: currentPage,
      })}`,
    }).pipe(
      map((result) => companyContactsSuccess(result)),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

const externalPeopleEpic = combineEpics(
  epicGetContactList,
  epicDeleteContact,
  epicAddContact,
  epicPostCompanyContact,
  epicPutCompanyContact,
  epicGetCompanyContactsList,
);

export default externalPeopleEpic;

import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik } from "formik";

import styles from "./fields.module.scss";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { BtnType, Button, DatePiker, Label, MultiSelect, TextBox } from "modules/primitives";
import { httpGet, httpPut } from "../../../../../common/httpCall";
import { projectSet } from "modules/projects/project/project.action";
import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import PlantMultiSelect from "../../../../../common/components/plantMultiselect";
import { makeFormikObj, makeProjectField } from "../../../../../common/helper";
import { currencySymbolObj } from "../../../../../common/constants";

const ProjectFields = () => {
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const {
    adminPlantsAndAreasReducer: { plantsAndAreas },
    authReducer: { session },
    councilReducer: { selectedCouncil },
    projectsReducer: {
      projectReducer: { selectedProject },
    },
  } = useSelector((state) => state);

  const [model, setModel] = useState({});
  const [data, setData] = useState(null);

  useEffect(() => {
    if (selectedProject?.id) {
      httpGet({
        call: `projects/${selectedProject.id}/project_field_templates`
      }).subscribe(res => {
        if (res?.response?.length) {
          const makeGroups = res.response
            .reduce((acc, elem) => {
              const newElem = makeFormikObj(elem);

              if (!newElem.project_field_group_name) {
                const groupExists = acc.find(group => group.name === 'Ungrouped');

                if (groupExists) {
                  return acc.map(group => {
                    if (group.name === 'Ungrouped') {
                      return {
                        ...group,
                        project_fields: [...group.project_fields, newElem],
                      }
                    }

                    return group;
                  });
                }

                return [
                  ...acc,
                  {
                    name: 'Ungrouped',
                    id: 'ungrouped',
                    project_fields: [newElem],
                  }
                ]
              }

              const groupExists = acc.find(group => group.name === newElem.project_field_group_name);

              if (groupExists) {
                return acc.map(group => {
                  if (group.name === newElem.project_field_group_name) {
                    return {
                      ...group,
                      project_fields: [...group.project_fields, newElem],
                    }
                  }

                  return group;
                });
              }

              return [
                ...acc,
                {
                  name: newElem.project_field_group_name,
                  id: newElem.project_field_group_id,
                  position: newElem.project_field_group_position,
                  project_fields: [newElem],
                }
              ]
            }, [])
            .map(g => ({
              ...g,
              project_fields: g.project_fields.map(makeProjectField),
            }))
            .sort((a, b) => a.position - b.position)

          setData(makeGroups);

          return;
        }

        setData([]);
      })
    }
  }, [selectedProject?.id]);

  useEffect(() => {
    if (!plantsAndAreas.length && selectedCouncil?.name === 'Ford') {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        }),
      );
    }
  }, [plantsAndAreas, selectedCouncil]);

  const getPlantsAsOptions = useMemo(() => {
    return plantsAndAreas.map(plant => ({
      id: plant.id,
      name: plant.attributes.name,
      areas: plant.attributes.areas,
    }));
  }, [plantsAndAreas]);

  const getPossiblePlantsFromSource = useCallback((data) => {
    if (!data?.possible_plants.length) {
      return [];
    }

    return plantsAndAreas
      .map(p => ({...p, ...p.attributes}))
      .filter(p => data?.possible_plants.some(plant => plant.id === p.id));
  }, [plantsAndAreas]);

  const getPossibleAreasFromSource = useCallback((data, possiblePlants) => {
    if (!data?.length) {
      return []
    }
    const getPossibleAreas = data
      .reduce((acc, elem) => [...acc, ...elem.project_fields], [])
      .find(elem => elem.field_name === 'Possible Replications (Areas)');

    if (!getPossibleAreas) {
      return [];
    }

    const flattenAreas = possiblePlants.reduce((acc, plant) => [...acc, ...plant.areas], []);

    return data?.possible_areas?.map(a => flattenAreas.find(a1 => a1.area_id === a.area_id));
  }, [plantsAndAreas]);

  useEffect(() => {
    if (!data) {
      return
    }

    const fields = data
      .reduce((acc, elem) => [...acc, ...elem.project_fields], [])
      .reduce((acc, elem) => ({ ...acc, [elem.id]: elem.values }), {});
    const possiblePlants = getPossiblePlantsFromSource(selectedProject);

    setModel({
      ...fields,
      selectedPlant1: possiblePlants,
      selectedAreas1: getPossibleAreasFromSource(data, possiblePlants),
    });
  }, [data]);

  const onSubmitForm = (values, dataSource) => {
    const isFord = selectedCouncil?.name === 'Ford';
    let fordProps = {};

    if (isFord) {
      fordProps = {
        possible_plant_ids: values.selectedPlant1?.map(p => p.id) || [],
        possible_area_ids: values.selectedAreas1?.map(a => a.area_id) || [],
      }
    }

    const flattenFields = dataSource.reduce((acc, group) => [...acc, ...group.project_fields], []);
    const dataPayload = {
      ...fordProps,
      project_fields_attributes: Object.keys(values)
        .filter(k => {
          const getFieldDetails = flattenFields.find(elem => elem.id === k);
          return getFieldDetails?.field_name !== 'Possible Replications (Plants)' &&
            getFieldDetails?.field_name !== 'Possible Replications (Areas)' &&
            k !== 'selectedPlant1' &&
            k !== 'selectedAreas1';
        })
        .map(k => {
          const getFieldDetails = flattenFields.find(elem => elem.id === k);

          let payload = {
            project_field_template_id: k,
            project_id: selectedProject.id,
            council_id: selectedCouncil.id,
            user_id: session.id,
            _destroy: !values[k]?.length,
          }

          if (getFieldDetails.field_type === 'text') {
            payload = {
              ...payload,
              field_value: values[k],
            }
          }

          if (getFieldDetails.project_field_id != null) {
            payload = {
              ...payload,
              id: getFieldDetails.project_field_id,
            }
          }

          if (getFieldDetails.field_type === 'dropdown') {
            payload = {
              ...payload,
              option_selected: values[k].map(el => el.id),
            }
          }

          return payload;
        }),
    };

    httpPut({
      call: `projects/${selectedProject.id}`,
      data: dataPayload,
    })
      .pipe()
      .subscribe((res) => {
        if (res.response) {
          enqueueSnackbar('Successfully updated project fields', {
            variant: "success",
          });

          dispatch(projectSet(res.response));
        }
      });
  };

  if (!data) {
    return <LoadingComponent customText="Loading project fields..." />
  }

  return (
    <div className={styles.projectFields}>
      <div className="d-flex flex-column w-50">
        {
          (data.length) ? (
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={model}
              onSubmit={(values) => {
                onSubmitForm(values, data);
              }}
            >
              {({ handleSubmit, setFieldValue, values, ...formikprops }) => (
                <form onSubmit={handleSubmit}>
                  {
                    data.map(group => (
                      <div key={group.id} className="mb-3">
                        {
                          group.id !== 'ungrouped' && (
                            <>
                              <hr className="mt-5 mb-5" />
                              <h5 className="font-weight-bold">{group.name}</h5>
                            </>
                          )
                        }
                        {
                          group.project_fields.map(field => {
                            if (field.field_name === 'Possible Replications (Plants)' && selectedCouncil?.name === 'Ford') {
                              return (
                                <div key={field.id} className="mb-3">
                                  <PlantMultiSelect
                                    formikprops={{values, setFieldValue, ...formikprops}}
                                    plants={getPlantsAsOptions}
                                  />
                                </div>
                              )
                            }

                            if (field.field_name === 'Possible Replications (Areas)' && selectedCouncil?.name === 'Ford') {
                              return null;
                            }

                            return (
                              <div key={field.id}>
                                {
                                  field.field_type === 'text' ? (
                                    <div>
                                      <Label>{field.field_name}</Label>
                                      {
                                        field.big_box_text ? (
                                          <textarea
                                            className="w-100 border small p-2"
                                            value={values[field.id]}
                                            placeholder={field.field_placeholder}
                                            onChange={(evt) => setFieldValue(field.id, evt.target.value)}
                                            rows="5"
                                          ></textarea>
                                        ) : (
                                          <TextBox
                                            name={field.id}
                                            placeholder={field.field_placeholder}
                                            formProps={{ ...formikprops, values }}
                                            className="w-100"
                                            value={values[field.id]}
                                          />
                                        )
                                      }
                                    </div>
                                  ) : null
                                }
                                {
                                  field.field_type === 'dropdown' ? (
                                    <div>
                                      <Label>{field.field_name}</Label>
                                      <MultiSelect
                                        name={field.id}
                                        values={values[field.id]}
                                        labelField="name"
                                        valueField="id"
                                        onChange={val => setFieldValue(field.id, val)}
                                        options={field.option_values}
                                        multi={field.multiselect}
                                        placeholder={field.field_placeholder}
                                      />
                                      {
                                        field.oldSelectedValues?.length ? (
                                          <div style={{ top: "-20px" }} className="small text-muted position-relative">
                                            <div>Saved option(s) no longer available: <span className="text-danger">
                                              {field.oldSelectedValues.map((el, index) => index < field.oldSelectedValues.length - 1 ? `${el},\t` : el,)}
                                            </span></div>
                                          </div>
                                        ) : null
                                      }
                                    </div>
                                  ) : null
                                }
                                {field.field_type === "date" ? (
                                  <div className="w-100">
                                    <Label>{field.field_name}</Label>
                                    <div className="w-100 d-flex align-items-center ml-0 mb-2">
                                      <DatePiker
                                        name={field.field_name}
                                        value={
                                          field.field_date ? field.field_date : null
                                        }
                                        formProps={{
                                          ...formikprops,
                                          setFieldValue,
                                          values,
                                        }}
                                        onChange={(val) => {
                                          setFieldValue(
                                            field.project_field_template_id || field.id,
                                            val
                                          );
                                        }}
                                        withClear
                                        className="w-100 "
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                {field.field_type === "number" ||
                                field.field_type === "currency" ? (
                                  <div className="w-100">
                                    <Label>{field.field_name}</Label>
                                    <div className="w-100 d-flex align-items-center ml-0 mb-2">
                                      <TextBox
                                        name={
                                          field.project_field_template_id || field.id
                                        }
                                        type="number"
                                        min="0"
                                        max="10000000000"
                                        placeholder={field.field_placeholder}
                                        formProps={{ ...formikprops, values }}
                                        className="w-100"
                                        value={
                                          values[
                                          field.project_field_template_id || field.id
                                            ]
                                        }
                                      />
                                      {field.field_type === "currency" && (
                                        <div className=" d-flex align-items-center ml-2 mb-3">
                                          {field.option_values
                                            ? field.option_values[0]?.name ? currencySymbolObj[field.option_values[0].name] :
                                              currencySymbolObj[field.option_selected[0]]
                                            : null}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            )
                          })
                        }
                      </div>
                    ))
                  }
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      type="submit"
                      btn={BtnType.HIGHLIGHT}
                    >
                      SAVE
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          ) : (<span>No project fields yet</span>)
        }
      </div>
    </div>
  );
};

export default React.memo(ProjectFields);

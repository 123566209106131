import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Label, MultiSelect, Loading } from "../../primitives";
import { themesSearchAttempt } from "modules/themes/themesMainPage/themesMain.action";

const ReplicationProjectThemes = ({
  handleSelect,
  themes = [],
  name = "Pillar",
  clearAll,
  placeholder,
}) => {
  const dispatch = useDispatch();

  const {
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { themesData, loadingThemes },
    },
  } = useSelector((state) => state);
  const [options, setOptions] = useState([]);
  const [elasticQuery, setElasticQuery] = useState(null);

  useEffect(() => {
    if (themesData?.themes) {
      if (themesData?.themes?.length > 0) {
        const filteredThemes = themesData.themes.filter((option) => {
          const isAlreadySelected = themes?.find((item) => {
            return item?.theme_id === option.id;
          });
          return !isAlreadySelected;
        });
        setOptions(filteredThemes);
      }
    }
  }, [themesData, themes]);

  const getNewData = useCallback((query) => {
    const data = {
      sort_order: "asc",
      sort_attribute: "name",
      search: {
        query: query,
      },
    };

    dispatch(themesSearchAttempt({ page: 1, data, fromMain: true }));
  }, [themesSearchAttempt]);

  useEffect(() => {
    getNewData('');
  }, []);

  useEffect(() => {
    if (elasticQuery === null) return;

    const timer = setTimeout(() => {
      getNewData(elasticQuery);
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [elasticQuery]);

  const makePlaceholder = useMemo(() => {
    if (placeholder) return placeholder;

    return `Add ${
      selectedCouncil?.settings?.themes_display_name || "themes"
    } (type to search or select from below)`
  }, [placeholder, selectedCouncil?.settings?.themes_display_name]);

  return (
    <div>
      <Label>{name}</Label>
      <div className="d-flex">
        <MultiSelect
          onChange={handleSelect}
          placeholder={makePlaceholder}
          labelField="name"
          valueField="id"
          searchBy="name"
          options={options}
          searchFn={(args) => {
            if (elasticQuery === null && !args.state.search) return;
            setElasticQuery(args.state.search);
          }}
          values={themes || []}
          disabled={loadingThemes}
          // withClear
        />

        {loadingThemes && (
          <div className="ml-3">
            <Loading hideString />
          </div>
        )}
      </div>
      {clearAll && themes?.length ? (
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "-16px" }}
        >
          <span
            className="text-muted small cursor-pointer"
            onClick={() => clearAll()}
          >
            clear
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default memo(ReplicationProjectThemes);

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import styles from "./styles.module.scss";
import { Button, BtnType, Loading } from "../../../../primitives/index";

const lengthForTrim = 240;

const OverviewBlock = (props) => {
  const { currentThemeData, selectedCouncil, loadingCompany } = props;
  const [admittedTags, setAdmittedTags] = useState([]);
  const [excitedLengthTags, setExcitedLengthTags] = useState(0);
  const [showMoreOnHover, setShowMoreOnHover] = useState(false);
  const [tagsArray, setTagsArray] = useState([]);
  const [tag, setTag] = useState("");
  const [descExpanded, setDescExpanded] = useState(false);
  const history = useHistory();

  const trimDescription = (description) => {
    const arrayDescription = description.split("");
    arrayDescription.splice(lengthForTrim);
    arrayDescription.push("...");
    return arrayDescription.join("");
  };

  const expand = () => {
    setDescExpanded(!descExpanded);
  };

  const checkForZeroData = (number) => {
    if (number > 0) {
      return number;
    }
    return "-";
  };

  useEffect(() => {
    setTagsArray([
      ...(currentThemeData?.topics || []),
      ...(currentThemeData?.industries || []),
    ]);
  }, [currentThemeData?.topics, currentThemeData?.industries]);

  useEffect(() => {
    if (tagsArray?.length > 0) {
      const passedTags = [];
      let accum = 0;
      let excitedTagsNum = 0;

      for (let i = 0; i < tagsArray?.length; i++) {
        if (accum <= 40) {
          passedTags.push(tagsArray[i]);
        } else {
          excitedTagsNum++;
        }

        accum += tagsArray[i]?.name?.length;
      }
      setExcitedLengthTags(excitedTagsNum);
      setAdmittedTags(passedTags);
    }
  }, [tagsArray]);

  useEffect(() => {
    if (currentThemeData?.current_option?.length) {
      const modifiedTag = [];
      for (let i = 0; i < currentThemeData.current_option.length; i++) {
        if (i === 0) {
          modifiedTag.push(currentThemeData.current_option[0].toUpperCase());
        } else if (currentThemeData.current_option[i] === "_") {
          modifiedTag.push(
            " ",
            currentThemeData.current_option[i + 1].toUpperCase()
          );
        } else if (currentThemeData.current_option[i] !== "s") {
          modifiedTag.push(currentThemeData.current_option[i]);
        }
      }
      setTag(modifiedTag.join(""));
    }
  }, [currentThemeData?.current_option]);

  const handleTagClick = (item) => {
    if (item?.topic_id?.length) {
      const search = `topic=${item.topic_id}&page=1`;

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          topic: item.topic_id,
          page: 1,
        },
      });
    } else {
      const search = `industry=${item.industry_id}&page=1`;

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          industry: item.industry_id,
          page: 1,
        },
      });
    }
  };

  return (
    <div className={styles.overviewContainer}>
      <span className={styles.overviewTitle}>
        {selectedCouncil?.settings?.themes_display_name_singular || "Theme"}{" "}
        Overview
      </span>

      <div className={styles.overviewBlock}>
        <div className={styles.descriptionBlock}>
          <span className={styles.descriptionTitle}>
            {currentThemeData?.name}
          </span>
          <div className={styles.descriptionText}>
            {!descExpanded &&
            currentThemeData?.description?.length > lengthForTrim
              ? trimDescription(currentThemeData?.description)
              : currentThemeData?.description}
          </div>
          {currentThemeData?.description?.length > lengthForTrim && (
            <span
              className={styles.expandLink}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                expand();
              }}
            >
              {descExpanded ? "Collapse" : "Read more ..."}
            </span>
          )}
        </div>
        <div className={styles.typePostRepliesBlock}>
          <div className={styles.typeBlock}>
            <span className={styles.typeTitle}>Type</span>
            <p className={styles.typeTag}>{tag}</p>
          </div>
          <div className={styles.postRepliesBlock}>
            <span className={styles.postRepliesTitle}>Posts & Replies</span>
            <p className={styles.postRepliesNumber}>
              {checkForZeroData(currentThemeData?.posts_and_replies_count)}
            </p>
          </div>
        </div>
        <div className={styles.companiesMembersBlock}>
          <div className={styles.companiesBlock}>
            <span className={styles.companiesTitle}>Companies</span>
            <p className={styles.companiesNumber}>
              {loadingCompany ? (
                <div className={styles.loader}>
                  <Loading hideString />
                </div>
              ) : (
                checkForZeroData(currentThemeData?.companies_count)
              )}
            </p>
          </div>
          <div className={styles.membersBlock}>
            <span className={styles.membersTitle}>Followers</span>
            <p className={styles.membersNumber}>
              {checkForZeroData(currentThemeData?.favorited_users_count)}
            </p>
          </div>
        </div>
        <div className={styles.tagsWrp}>
          <div className={styles.label}>Tags</div>
          <div className={styles.tagsCon}>
            {!showMoreOnHover &&
              admittedTags?.map((item) => (
                <Button
                  key={item?.topic_id || item?.industry_id}
                  btn={
                    item?.topic_id
                      ? BtnType.TAG_BLUE_LIGHT
                      : BtnType.TAG_OUTLINE_BLUE
                  }
                  className={styles.tag}
                  onClick={() => handleTagClick(item)}
                >
                  {item?.name}
                </Button>
              ))}
            {!showMoreOnHover && excitedLengthTags !== 0 && (
              <div
                onMouseLeave={() => setShowMoreOnHover(false)}
                onMouseEnter={() => setShowMoreOnHover(true)}
                className={styles.more}
              >
                + {excitedLengthTags} More
              </div>
            )}
            {excitedLengthTags !== 0 && showMoreOnHover && (
              <div
                onMouseLeave={() => setShowMoreOnHover(false)}
                onMouseEnter={() => setShowMoreOnHover(true)}
                className={styles.moreBlockOnHover}
              >
                {tagsArray?.map((item) => (
                  <Button
                    key={item?.id}
                    btn={
                      item?.topic_id
                        ? BtnType.TAG_BLUE_LIGHT
                        : BtnType.TAG_OUTLINE_BLUE
                    }
                    className={styles.tag}
                  >
                    {item?.name}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default OverviewBlock;

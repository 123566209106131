import { ofType, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import {
  switchMap, map, groupBy, mergeMap, catchError,
} from 'rxjs/operators';

import {
  PROJECT_ORGANIZATIONS_GET_ATTEMPT,
  projectOrganizationsGetSuccess,
  PROJECT_COMPANY_LIST_GET_ATTEMPT,
  PROJECT_COMPANY_CHANGE_STATUS_ATTEMPT,
  projectCompanyChangeStatusSuccess,
  getProjectCompaniesSuccess,
  projectCompanyNewSuccess,
  PROJECT_COMPANY_TASK_LIST_GET_ATTEMPT,
  projectCompanyTaskLitGetSuccess,
  RATING_SAVE_ATTEMPT,
  ratingSaveSuccess,
  RATING_EDIT_ATTEMPT,
  ratingEditSuccess,
  PROJECT_ORGANIZATION_TASK_LIST_GET_ATTEMPT,
  projectOrganizationTaskListGetSuccess,
  PROJECT_COMPANY_NEW_MULTIPLE_ATTEMPT,
  PROJECT_PATIENT_ORG_NEW_MULTIPLE_ATTEMPT,
  PROJECT_PATIENT_ORG_NEW_ATTEMPT,
  PROJECT_COMPANY_DELETE_PERMANENTLY,
  GET_COMPANY_DOCUMENTS_ATTEMPT,
  getCompanyDocumentsSuccess,
  FOLLOW_MULTIPLE_COMPANIES_ATTEMPT,
  followMultipleCompaniesSuccess,
} from './companies.action';
import { projectStageCompanyNewSuccess } from '../stages/stages.action';
import {
  httpGet,
  httpPut,
  httpPost,
  httpDelete,
  httpPatch,
  errorHandler,
} from '../../../../common/httpCall';

export const epicProjectPatientOrganizationsGet = (action$) => action$.pipe(
  ofType(PROJECT_ORGANIZATIONS_GET_ATTEMPT),
  switchMap(({ payload: { projectId, enqueueSnackbar } }) => httpGet({
    call: `projects/${projectId}/organizations`,
  }).pipe(
    map((result) => projectOrganizationsGetSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicRatingSave = (action$) => action$.pipe(
  ofType(RATING_SAVE_ATTEMPT),
  switchMap(({
    payload: {
      projectId, companyId, form, enqueueSnackbar,
    },
  }) => httpPost({
    call: `ratings/project_companies?project_id=${projectId}&company_id=${companyId}`,
    data: { rating_project_company: { ...form } },
  }).pipe(
    map((result) => ratingSaveSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicRatingEdit = (action$) => action$.pipe(
  ofType(RATING_EDIT_ATTEMPT),
  switchMap(({
    payload: {
      projectId, ratingProjectId, companyId, form, enqueueSnackbar,
    },
  }) => httpPatch({
    call: `ratings/project_companies/${ratingProjectId}?project_id=${projectId}&company_id=${companyId}`,
    data: { rating_project_company: { ...form } },
  }).pipe(
    map((result) => ratingEditSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetProjectCompanies = (action$, state$) => action$.pipe(
  ofType(PROJECT_COMPANY_LIST_GET_ATTEMPT),
  switchMap(({
    payload: {
      projectId, items, page, enqueueSnackbar, overviewInstance
    },
  }) => httpGet({
    apiVersion: 'v2',
    call: `projects/${projectId}/companies?items=${items || 20}&page=${page || 1}&overview=${overviewInstance}${overviewInstance ? `&state=active` : ''}`,
  }).pipe(
    map((result) => getProjectCompaniesSuccess({
      result,
      pageNr: result.xhr.getResponseHeader('total-pages'),
      companiesTotal: result.xhr.getResponseHeader('total-count'),
      overviewInstance,
    })),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicChangeStatusProjectPatientOrgs = (action$, state$) => action$.pipe(
  ofType(PROJECT_PATIENT_ORG_NEW_ATTEMPT),
  switchMap(({
    payload: {
      projectId, patientOrg, data, enqueueSnackbar,
    },
  }) => httpPost({
    call: `projects/${projectId}/patient_organizations/create_many?project_id=${projectId}`,
    data,
  }).pipe(
    map((result) => projectCompanyChangeStatusSuccess({ ...result, patientOrg })),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicDeleteProjectCompanyPermanently = (action$, state$) => action$.pipe(
  ofType(PROJECT_COMPANY_DELETE_PERMANENTLY),
  switchMap(
    ({
      payload: {
        url,
        patientOrg,
        deletePermanently,
        companyId,
        manageProject,
        enqueueSnackbar,
      },
    }) => httpDelete({
      call: url,
    }).pipe(
      map((result) => projectCompanyChangeStatusSuccess({
        ...result,
        patientOrg,
        deletePermanently,
        companyId,
        manageProject,
      })),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

export const epicChangeStatusProjectCompanies = (action$, state$) => action$.pipe(
  ofType(PROJECT_COMPANY_CHANGE_STATUS_ATTEMPT),
  switchMap(({
    payload: {
      projectId, companyId, state, gsk, patientOrg, enqueueSnackbar, is_product
    },
  }) => httpPut({
    call: `projects/${projectId}/companies/${companyId}/${state}?gsk=${gsk}&patient_org=${patientOrg}&is_product=${is_product}`, // NEW_API
  }).pipe(
    map((result) => projectCompanyChangeStatusSuccess({ ...result, patientOrg, is_product, companyId })),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetProjectOrganizationTasks = (action$, state$) => action$.pipe(
  ofType(PROJECT_ORGANIZATION_TASK_LIST_GET_ATTEMPT),
  groupBy(
    (action) => action.payload.stageId,
    (action) => action,
  ),
  mergeMap((group) => group.pipe(
    switchMap(
      ({
        payload: {
          projectId, gsk_patient_organization_id, stageId, enqueueSnackbar,
        },
      }) => httpGet({
        apiVersion: 'v2',
        call: `projects/${projectId}/stages/${stageId}/organizations/${gsk_patient_organization_id}/tasks`, // NEW_API
      }).pipe(
        map((result) => projectOrganizationTaskListGetSuccess({
          result,
          gsk_patient_organization_id,
          stageId,
        })),
        catchError((err) => errorHandler(err, enqueueSnackbar)),
      ),
    ),
  )),
);

export const epicGetProjectCompaniesTaks = (action$, state$) => action$.pipe(
  ofType(PROJECT_COMPANY_TASK_LIST_GET_ATTEMPT),
  groupBy(
    (action) => action.payload.stageId,
    (action) => action,
  ),
  mergeMap((group) => group.pipe(
    switchMap(({
      payload: {
        projectId, companyId, stageId, enqueueSnackbar,
      },
    }) => httpGet({
      apiVersion: 'v2',
      call: `projects/${projectId}/stages/${stageId}/organizations/${companyId}/tasks`, // NEW_API
    }).pipe(
      map((result) => projectCompanyTaskLitGetSuccess({ result, companyId, stageId })),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    )),
  )),
);

export const epicNewMultipleProjectPatientOrg = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_PATIENT_ORG_NEW_MULTIPLE_ATTEMPT),
    switchMap(({ payload }) =>
      httpPost({
        apiVersion: "v2",
        call: `projects/${payload.project_id}/patient_organizations/create_many?project_id=${payload.project_id}&HTTP_COUNCIL_ID=${payload.council_id}`,
        data: {
          project_patient_organizations: payload.project_patient_organizations,
        },
      }).pipe(
        mergeMap((result) => {
          if (payload.multipleAdd) {
            const { enqueueSnackbar } = payload;
            enqueueSnackbar('Successfully added patient organizations to project.', {
              variant: 'success',
            });
          }

          if (payload.stage_id) {
            return of(
              projectStageCompanyNewSuccess({
                result,
                stageId: payload.stage_id,
                gsk: payload.patientOrg,
                hasArrayRes: true,
              }),
              projectCompanyNewSuccess({
                ...result,
                gsk: payload.patientOrg,
                hasArrayRes: true,
              })
            )
          }
          return of(
            projectCompanyNewSuccess({
              ...result,
              gsk: payload.patientOrg,
              hasArrayRes: true,
            })
          )
        }),
        catchError((err) => {
          if (err.response.errors.gsk_patient_organization_id) {
            return errorHandler(
              {
                ...err,
                response: { message: "This patient organization is already assigned to project" },
              },
              payload.enqueueSnackbar
            )
          }
          return errorHandler(err, payload.enqueueSnackbar)
        })
      )
    )
  )

export const epicFollowMultipleCompanies = (action$) => action$.pipe(
  ofType(FOLLOW_MULTIPLE_COMPANIES_ATTEMPT),
  switchMap(({ payload }) => httpPost({
    call: 'follow_relationships/create_many',
    data: {
      follow_relationships: payload.follow_relationships,
    },
  }).pipe(
    mergeMap(() => {
      const { enqueueSnackbar } = payload;

      if (enqueueSnackbar) {
        enqueueSnackbar('Successfully follow multiple companies.', {
          variant: 'success',
        });
      }

      if (payload.cb) {
        payload.cb();
      }

      return of(followMultipleCompaniesSuccess(payload));
    }),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicNewMultipleProjectCompanies = (action$, state$) => action$.pipe(
  ofType(PROJECT_COMPANY_NEW_MULTIPLE_ATTEMPT),
  switchMap(({ payload }) => httpPost({
    apiVersion: 'v2',
    call: `projects/${payload.project_id}/companies/create_many?project_id=${payload.project_id}&HTTP_COUNCIL_ID=${payload.council_id}`,
    data: {
      project_companies: payload.project_companies,
    },
  }).pipe(
    mergeMap((result) => {
      const {
        enqueueSnackbar, multipleAdd, overviewInstance
      } = payload;

      if (enqueueSnackbar) {
        if (multipleAdd) {
          enqueueSnackbar('Successfully added companies and products to project.', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Company successfully added.', {
            variant: 'success',
          });
        }
      }

      if (payload.stage_id) {
        return of(
          projectStageCompanyNewSuccess({
            result,
            stageId: payload.stage_id,
            gsk: payload.patientOrg,
            hasArrayRes: true,
            postPayload: payload,
          }),
          projectCompanyNewSuccess({
            ...result,
            gsk: payload.patientOrg,
            hasArrayRes: true,
            overviewInstance
          }),
        );
      }
      return of(
        projectCompanyNewSuccess({
          ...result,
          gsk: payload.patientOrg,
          hasArrayRes: true,
          overviewInstance,
        }),
      );
    }),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicCompanyDocumentsGet = (action$) => action$.pipe(
  ofType(GET_COMPANY_DOCUMENTS_ATTEMPT),
  switchMap(({ payload: { projectId, objId, enqueueSnackbar } }) => httpGet({
    apiVersion: 'v2',
    call: `projects/${projectId}/organizations/${objId}/documents`,
  }).pipe(
    map((result) => getCompanyDocumentsSuccess(result.response)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const companyEpic = combineEpics(
  epicGetProjectCompanies,
  epicChangeStatusProjectCompanies,
  epicGetProjectCompaniesTaks,
  epicRatingSave,
  epicRatingEdit,
  epicProjectPatientOrganizationsGet,
  epicGetProjectOrganizationTasks,
  epicNewMultipleProjectCompanies,
  epicNewMultipleProjectPatientOrg,
  epicChangeStatusProjectPatientOrgs,
  epicDeleteProjectCompanyPermanently,
  epicCompanyDocumentsGet,
  epicFollowMultipleCompanies,
);

export default companyEpic;

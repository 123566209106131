import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { of } from "rxjs";

import {
  httpGet,
  errorHandler,
  httpPost,
  httpDelete,
  httpPut,
} from "../../../common/httpCall";
import {
  COUNCILS_GET_ATTEMPT,
  councilsGetSuccess,
  COUNCILS_POST_ATTEMPT,
  councilsPostSuccess,
  COUNCILS_DELETE_ATTEMPT,
  councilsDeleteSuccess,
  COUNCILS_EDIT_ATTEMPT,
  councilsEditSuccess,
  COUNCILS_GET_INDUSTRIES_ACROSS_ATTEMPT,
  councilsGetIndustriesAcrossSuccess,
  COUNCILS_GET_TECNOLOGIES_ACROSS_ATTEMPT,
  councilsGetTecnologiesAcrossSuccess,
  COUNCILS_GET_BY_ID_ATTEMPT,
  councilsGetByIdSuccess,
  resetLoading,
  COUNCILS_SPECIFIC_STAGES_GET_BY_ID_ATTEMPT,
  councilsRelStageGetByIdSuccess,
} from "./councils.action";
export const epicGetCouncils = (action$, state$) =>
  action$.pipe(
    ofType(COUNCILS_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, query, itemsNum, page } }) =>
      httpGet({
        call: `councils?items=${itemsNum || 20}&page=${page || 1}&query=${
          query || " "
        }`,
      }).pipe(
        map((result) => councilsGetSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

export const epicPostCouncils = (action$, state$) =>
  action$.pipe(
    ofType(COUNCILS_POST_ATTEMPT),
    switchMap(({ enqueueSnackbar, payload }) =>
      httpPost({
        call: "councils",
        data: payload,
      }).pipe(
        map((result) => councilsPostSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );
export const epicDeleteCouncils = (action$, state$) =>
  action$.pipe(
    ofType(COUNCILS_DELETE_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpDelete({
        call: `councils/${id}`,
      }).pipe(
        map((result) => councilsDeleteSuccess(result)),
        catchError((error) => {
          errorHandler(
            {
              ...error,
              response: { message: "You cannot delete this council" },
            },
            enqueueSnackbar
          );
          return of(resetLoading(error));
        })
      )
    )
  );
export const epicPutCouncils = (action$, state$) =>
  action$.pipe(
    ofType(COUNCILS_EDIT_ATTEMPT),
    switchMap(({ payload: { council, id, enqueueSnackbar } }) =>
      httpPut({
        call: `councils/${id}`,
        data: { council: { ...council } },
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Council edited with success", {
              variant: "success",
            });
          }
          window.location.reload();
          return councilsEditSuccess(result);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );
export const epicGetIndustriesCouncils = (action$, state$) =>
  action$.pipe(
    ofType(COUNCILS_GET_INDUSTRIES_ACROSS_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `industries?all=true`,
      }).pipe(
        map((result) => councilsGetIndustriesAcrossSuccess(result)),
        catchError((error) => errorHandler(error, payload.enqueueSnackbar))
      )
    )
  );
export const epicGetTecnologiesCouncils = (action$, state$) =>
  action$.pipe(
    ofType(COUNCILS_GET_TECNOLOGIES_ACROSS_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        call: `topics?all=true`,
      }).pipe(
        map((result) => councilsGetTecnologiesAcrossSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );
export const epicGetCouncilById = (action$, state$) =>
  action$.pipe(
    ofType(COUNCILS_GET_BY_ID_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpGet({
        call: `councils/${id}`,
      }).pipe(
        map((result) => councilsGetByIdSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

export const epicGetCouncilRelStageByCouncil = (action$, state$) =>
  action$.pipe(
    ofType(COUNCILS_SPECIFIC_STAGES_GET_BY_ID_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpGet({
        call: `councils/${id}/council_relationship_stages`,
      }).pipe(
        map((result) => councilsRelStageGetByIdSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

const councilsEpic = combineEpics(
  epicGetCouncils,
  epicPostCouncils,
  epicDeleteCouncils,
  epicPutCouncils,
  epicGetIndustriesCouncils,
  epicGetTecnologiesCouncils,
  epicGetCouncilById,
  epicGetCouncilRelStageByCouncil
);

export default councilsEpic;

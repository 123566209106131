import React from "react";
import styles from "./filterItem.module.scss";
import FilterRowComponent from "../filterRow/filterRow.component";

const FilterItemComponent = (props) => {
  const {
    items,
    level,
    handleCheck,
    type,
    checkedPatientFilters,
    countryFilters,
    hasHover,
  } = props;

  return (
    <div className={styles.wrapper}>
      {items.map((item) => (
        <FilterRowComponent
          key={item.id}
          item={item}
          items={item.children_diseases}
          level={level}
          handleCheck={handleCheck}
          type={type}
          checkedPatientFilters={checkedPatientFilters}
          countryFilters={countryFilters}
          hasHover={hasHover}
        />
      ))}
    </div>
  );
};

export default React.memo(FilterItemComponent);

import React, { useRef, useState, useEffect } from "react";
import { Formik } from "formik";

import {
  Button,
  BtnType,
  Label,
  TextBox, TextArea, CheckType, CheckBox, DatePiker,
} from "../../../../../primitives/index";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../../common/hooks";
import { ModalFooter, ModalBody } from "../../../../../../application/modal";
import { currencies, initModel, validation } from "./constants";
import { httpPatch, httpPost } from "../../../../../../common/httpCall";
import classnames from "classnames";

const AddNewFinancial = ({ data, project, handleAddUpdate, currencyToUse }) => {
  const popup = useModelPopup();
  const [model, setModel] = useState(initModel);
  const formRef = useRef();

  useEffect(() => {
    if (data) {
      setModel({
        price_in_currency: data.price_in_currency,
        price_in_cents: data.price_in_currency * 100,
        completed: data.completed,
        comment: data.comment,
        payment_date: data.payment_date,
        currency: currencyToUse,
      });
    }
  }, [data, currencyToUse]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = () => {
    const { values } = formRef.current;

    if (!values.price_in_currency || values.price_in_currency === 0) {
      return;
    }

    if (data?.id) {
      httpPatch({
        call: `projects/${project.id}/expected_payments/${data.id}`,
        data: {
          expected_payment: {
            ...values,
            price_in_cents: values.price_in_currency * 100,
          },
        }
      }).subscribe(res => {
        if (res.response) {
          handleAddUpdate(res.response, data.id);
        }

        popup.hide();
      });

      return;
    }

    httpPost({
      call: `projects/${project.id}/expected_payments/`,
      data: {
        expected_payment: {
          ...values,
          price_in_cents: values.price_in_currency * 100,
        },
      }
    }).subscribe(res => {
      if (res.response) {
        handleAddUpdate(res.response, null);
      }

      popup.hide();
    });
  };

  return (
    <>
      <ModalBody>
        <div className={styles.wrapper}>
          <Formik
            innerRef={formRef}
            initialValues={model}
            enableReinitialize
            validationSchema={validation}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              handleSaveClick();
              resetForm();
            }}
          >
            {({ handleSubmit, setFieldValue, values, ...formikprops }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <>
                      <Label>Expected Payment</Label>
                      <div className="d-flex position-relative align-items-baseline">
                        <span className={classnames(styles.currency, currencies[values.currency] === 'CHF' ? styles.currency1 : '')}>{currencies[values.currency]}</span>
                        <TextBox
                          type="number"
                          name="price_in_currency"
                          placeholder="Enter expected payment"
                          className={styles.textBox}
                          formProps={{ ...formikprops, setFieldValue, values }}
                          value={values.price_in_currency}
                          min="1"
                        />
                      </div>
                      {
                        !currencyToUse ? (
                          <div className="form-check pl-3 mb-3">
                            <label className="form-check-label mr-5">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="currency"
                                value="USD"
                                onChange={() => setFieldValue("currency", 'USD')}
                                checked={values?.currency === 'USD'}
                              />
                              USD
                            </label>
                            <label className="form-check-label mr-5">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="currency"
                                value="EUR"
                                onChange={() => setFieldValue("currency", 'EUR')}
                                checked={values?.currency === 'EUR'}
                              />
                              EUR
                            </label>
                            <label className="form-check-label mr-5">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="currency"
                                value="GBP"
                                onChange={() => setFieldValue("currency", 'GBP')}
                                checked={values?.currency === 'GBP'}
                              />
                              GBP
                            </label>
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="currency"
                                value="CHF"
                                onChange={() => setFieldValue("currency", 'CHF')}
                                checked={values?.currency === 'CHF'}
                              />
                              CHF
                            </label>
                          </div>
                        ) : null
                      }
                    </>
                    <>
                      <Label>Payment Date</Label>
                      <DatePiker
                        name="payment_date"
                        formProps={{
                          ...formikprops,
                          setFieldValue,
                          values,
                        }}
                        value={values.payment_date}
                        onChange={(evt) => setFieldValue('payment_date', evt)}
                        withClear
                      />
                    </>
                    <>
                      <Label>
                        Comment
                      </Label>
                      <TextArea
                        name="comment"
                        placeholder="Comment"
                        value={values.comment}
                        formProps={{ ...formikprops, setFieldValue, values }}
                        className="w-100"
                      />
                    </>
                    <div className="d-flex align-items-center">
                      <CheckBox
                        checkType={CheckType.BLUE}
                        isChecked={values.completed}
                        onChange={(evt) => setFieldValue('completed', evt)}
                      />
                      <Label className="cursor-pointer" onClick={() => setFieldValue('completed', !values.completed)}>Completed</Label>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button onClick={handleSaveClick} btn={BtnType.REGULAR}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
};

export default React.memo(AddNewFinancial);

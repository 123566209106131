import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import DialogActions from "@mui/material/DialogActions";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import styles from "./styles.module.scss";
import NewProjectStepWrapper from "modules/projects/newProjectv3/newProjectStepWrapper";
import {
  generateStepValidationSchema,
  makeProjectPayloadV3,
} from "modules/projects/newProjectv3/newProjectStepsWrapper/utils";
import NewProjectEvaluation from "../../../../common/components/newProjectSharedComponents/newProjectEvaluation";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { httpGet, httpPost } from "../../../../common/httpCall";
import { BtnType, Button } from "modules/primitives";
import { METRICS_NAMES_ENUM, PROJECT_WIZARD_FIELD_TYPES } from "application/app.constants";
import { clearNewProject } from "modules/projects/project/project.action";
import { useModelPopup } from "../../../../common/hooks";
import { makeProjectField } from "../../../../common/helper";

const NewProjectStepsWrapper = ({ wizardType, modalInstance, handleDismiss, additionalData, setProjectIsCreating }) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loaders, setLoaders] = useState({
    fetchingWizard: true,
    isFetchingProjectCreation: false,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [wizardData, setWizardData] = useState(null);
  const [stepValidations, setStepValidations] = useState({});
  const [initialValues, setInitialValues] = useState({});

  const {
    newProjectV3Reducer: { stageTemplates },
  } = useSelector((state) => state);

  useEffect(() => {
    localStorage.removeItem("new_project");
  }, []);

  const getDefaultTemplate = useMemo(() => {
    if (stageTemplates && stageTemplates?.length > 0) {
      const patientOrgTemplate = stageTemplates.find((template) => template.default_patient_organization);

      if (patientOrgTemplate) {
        return patientOrgTemplate.id
      }

      const getStage = stageTemplates.find((template) => template.default_template);
      return getStage?.id;
    }
  }, []);

  const [selectedTemplateId, setSelectedTemplateId] = useState(getDefaultTemplate);

  const makeWizardChanges = (wizard) => {
    let projectFields = [];

    if (additionalData?.replicateData?.fieldGroups) {
      const getIdeaGroup = additionalData.replicateData.fieldGroups?.project_fields_with_groups?.find(
        (g) => g.name === "Idea"
      ) || {
        fields: [],
      };
      const fields = getIdeaGroup.project_fields || getIdeaGroup.fields || [];
      projectFields = fields.map(makeProjectField);
    }

    return {
      ...wizard,
      projects_wizard_steps: wizard.projects_wizard_steps.map(step => ({
        ...step,
        projects_wizard_options: step.projects_wizard_options.map(option => {
          if (option.field_type === 'project_field') {
            const getFieldFromProjectFields = projectFields.find(f => f.project_field_template_id === option.project_field_template_id)
            return {
              ...option,
              oldSelectedValues: getFieldFromProjectFields?.oldSelectedValues || [],
              isProjectField: option.field_type === 'project_field',
            };
          }

          if (option.field_type === 'project_metric') {
            return {
              ...option,
              isMetricField: true,
              metric_attribute: option.project_metric_attribute,
              field_name: METRICS_NAMES_ENUM[option.metric_attribute],
            }
          }

          return {
            ...option,
            field_name: PROJECT_WIZARD_FIELD_TYPES[option.field_type]?.fieldTitle
          }
        }),
      })),
    }
  };

  const totalSteps = useMemo(() => wizardData?.projects_wizard_steps?.length, [
    wizardData?.projects_wizard_steps?.length
  ]);

  const requestWizardDetails = async (stageId, modelData = {}) => {
    console.log('get wizard data by stage id: ', stageId);
    setStepValidations({});
    setWizardData(null);
    setLoaders({
      ...loaders,
      fetchingWizard: true,
    });

    await httpGet({
      call: `projects_wizard_setups?stage_template_id=${stageId}`,
    }).subscribe(res => {
      if (res.response) {
        const getWizard = res.response.find(({ setup_type }) => setup_type === wizardType);
        const wizard = makeWizardChanges(getWizard);
        setWizardData(wizard);

        const newInitialValues = wizard.projects_wizard_steps.reduce((acc, step) => {
          step.projects_wizard_options.forEach((option) => {
            if (option.field_type === 'project_name' && additionalData?.name) {
              acc[option.field_type] = additionalData.name;
              return;
            }

            if (option.field_type === 'project_access') {
              acc[option.field_type] = "all";
            } else if (option.isMetricField) {
              if (acc.project_metrics) {
                acc['project_metrics'] = {
                  ...acc.project_metrics,
                  [option.metric_attribute]: "",
                };
              } else {
                acc['project_metrics'] = {
                  [option.metric_attribute]: "",
                };
              }
            } else if (option.isProjectField) {
              if (acc.project_fields) {
                acc['project_fields'] = {
                  ...acc.project_fields,
                  [option.project_field_template_id]: "",
                };
              } else {
                acc['project_fields'] = {
                  [option.project_field_template_id]: "",
                };
              }
            } else {
              acc[option.field_type] = "";
            }
          });
          return acc;
        }, {
          stage_template_id: stageId,
          project_name: wizardType === 'company' || wizardType === 'idea' ? additionalData.name : '',
        });

        setInitialValues({
          ...newInitialValues,
          ...(modelData || {}),
        });

        setLoaders({
          ...loaders,
          fetchingWizard: false,
        });
      }
    })
  }

  const makeReplicateModel = (data, stage_template_id) => {
    const splitName = data.name.replace(/\(Replication \d+(\.\d+)*\)/, '');
    const parser = new DOMParser();
    const doc = parser.parseFromString(data.description || '', 'text/html');
    const description = doc.body.textContent || '';
    let props = {
      project_name: `${splitName} (Replication ${data.next_replication_number})`,
      description,
      stage_template_id,
      original_project_id: data.id,
    };

    if (data.themes?.length) {
      props = {
        ...props,
        project_theme: data.themes.map(t => ({...t, id: t.theme_id})),
      }
    }

    if (data.replicateData.fieldGroups) {
      const getIdeaGroup = data.replicateData.fieldGroups?.project_fields_with_groups?.find(
        (g) => g.name === "Idea"
      ) || {
        fields: [],
      };
      const fields = getIdeaGroup.project_fields || getIdeaGroup.fields || [];
      const project_fields = fields
        .map(makeProjectField)
        .reduce((acc, elem) => {
          return {
            ...acc,
            [elem.project_field_template_id]: elem.field_type === "dropdown"
              ? elem.option_selected?.map((el) => ({
                name: el,
                id: el,
              })) || []
              : elem.field_value || "",
          };
      }, {});

      props = {
        ...props,
        project_fields,
      }
    }

    if (data.replicateData.selected_plant) {
      props = {
        ...props,
        project_plants: data.replicateData.selected_plant,
      };
    }

    return props;
  }

  useEffect(() => {
    if (!stageTemplates.length) return;

    if (wizardType === 'replicate') {
      const getReplicationStage = stageTemplates.find((template) => template.id === additionalData.stage_template_id);
      if (getReplicationStage) {
        const makeReplicationModel = makeReplicateModel(additionalData, getReplicationStage.id);
        requestWizardDetails(getReplicationStage.id, makeReplicationModel).then();
      }
    } else {
      const patientOrgTemplate = stageTemplates.find((template) => template.default_patient_organization);

      if (patientOrgTemplate) {
        requestWizardDetails(patientOrgTemplate.id).then();
        return;
      }

      const defStage = stageTemplates.find((template) => template.default_template);

      if (defStage) {
        requestWizardDetails(defStage.id).then();
      }
    }
  }, []);

  useEffect(() => {
    if (wizardData?.projects_wizard_steps) {
      const generateValidations = async () => {
        const validations = await generateStepValidationSchema(wizardData.projects_wizard_steps);
        setStepValidations(validations);
      };

      generateValidations().then();
    } else {
      setStepValidations({});
    }
  }, [wizardData?.projects_wizard_steps, selectedTemplateId]);

  const handleNext = async (formikProps) => {
    const currentValidationSchema = stepValidations[activeStep];

    if (!currentValidationSchema) {
      console.warn("Validation schema is not ready");
      return;
    }

    const currentStepFields = wizardData.projects_wizard_steps[activeStep]?.projects_wizard_options.map(option => option.field_type) || [];
    const touchedFields = currentStepFields.reduce((acc, field) => {
      acc[field] = true;
      return acc;
    }, {});

    formikProps.setTouched(touchedFields);

    try {
      await currentValidationSchema.validate(formikProps.values, { abortEarly: false });
      formikProps.setErrors({});

      setActiveStep((prevStep) => prevStep + 1);
    } catch (validationErrors) {
      const formErrors = validationErrors.inner.reduce((acc, error) => {
        acc[error.path] = error.message;
        return acc;
      }, {});
      formikProps.setErrors(formErrors);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const handleStageChange = (evt, formikProps) => {
    // Don't request again if the same
    const newTemplateId = evt?.val;
    if (!newTemplateId || newTemplateId === formikProps.values?.stage_template_id) return;
    setSelectedTemplateId(newTemplateId);

    let additionalModel = {}
    if (wizardType === 'replicate') {
      additionalModel = makeReplicateModel(additionalData,newTemplateId);
    }

    requestWizardDetails(newTemplateId, additionalModel).then(() => {
      formikProps.resetForm({
        values: {
          ...formikProps.values,
          stage_template_id: newTemplateId,
        },
        touched: {},
        errors: {},
      });
      formikProps.validateForm();
    });
  };

  const isEuropeMFG = useCallback((values) => {
    const getStage = stageTemplates?.find(s => s.id === values?.stage_template_id);
    return getStage?.name === 'Europe MFG Innovation' || getStage?.name === 'US MFG Innovation';
  }, [stageTemplates]);

  const handleSubmit = (values) => {
    console.log('values on submit', values);
    console.log('values in makeProjectPayloadV3', makeProjectPayloadV3(values, wizardData));

    setLoaders({
      ...loaders,
      isFetchingProjectCreation: true,
    });

    if (setProjectIsCreating) {
      setProjectIsCreating(true);
    }

    httpPost({
      call: "projects",
      apiVersion: "v3",
      data: makeProjectPayloadV3(values, wizardData, wizardType, additionalData),
    }).subscribe(res => {
      localStorage.removeItem("new_project");
      localStorage.removeItem("new_project_object");
      dispatch(clearNewProject());

      if (!res.response) return;

      const project = res.response;

      if (handleDismiss) {
        handleDismiss();
      }

      if (setProjectIsCreating) {
        setProjectIsCreating(false);
      }

      enqueueSnackbar('Successfully created project.', {
        variant: 'success',
      });

      const isMFGFordTemplate = isEuropeMFG(values);

      if (isMFGFordTemplate) {
        if (project.redirect_approval_task_id) {
          history.push(
            `/projects/${project.id}/task?tab=all_task&taskId=${project.redirect_approval_task_id}&gatewayTask=true`
          );
        } else {
          history.push(`/projects/${project.id}/task?tab=all_task`);
        }
      } else {
        history.push(`/projects/${project.id}`);
      }

      popup.hide();
    })
  }

  if (loaders.isFetchingProjectCreation) {
    return (
      <div className="mt-5 text-center">
        <h3>Please wait a moment as we pull everything together to create your project</h3>
        <LoadingComponent showBigSize hideString />
      </div>
    )
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={stepValidations[activeStep]}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <div className={styles.wrapper}>
            {
              loaders.fetchingWizard && (
                <LoadingComponent customText="Fetching creation steps..." />
              )
            }
            {
              wizardData && (
                <>
                  {totalSteps > 1 && (
                    <div className={styles.captionStyle}>
                      Step {activeStep + 1} of {totalSteps}
                    </div>
                  )}

                  {wizardData.projects_wizard_steps.map((step, index) =>
                    index === activeStep ? (
                      <div key={`step-${activeStep}`}>
                        {
                          step.description && !loaders.fetchingWizard && (
                            <p>{step.description}</p>
                          )
                        }
                        {
                          index === 0 && (
                            <>
                              {
                                stageTemplates?.length === 0 ? (
                                  <div className="mb-5 text-center">
                                    <LoadingComponent customText="Fetching stage templates..." />
                                  </div>
                                ) : (
                                  <div className="w-100">
                                    <NewProjectEvaluation
                                      formikprops={formikProps}
                                      mode="modal"
                                      handleStageSelect={(evt) => handleStageChange(evt, formikProps)}
                                      wizardType={wizardType}
                                    />
                                  </div>
                                )
                              }
                            </>
                          )
                        }
                        {
                          loaders.fetchingWizard ? (
                            <LoadingComponent customText="Fetching new data..." />
                          ) : (
                            <NewProjectStepWrapper
                              step={step}
                              formikprops={formikProps}
                              additionalData={additionalData}
                              wizardType={wizardType}
                              wizardData={wizardData}
                              selectedTemplateId={selectedTemplateId}
                            />
                          )
                        }
                      </div>
                    ) : null
                  )}

                  <DialogActions sx={{ position: "sticky", bottom: 0, background: "white" }}>
                    {
                      loaders.isFetchingProjectCreation ? (
                        <LoadingComponent customText="Creating project..." />
                      ) : (
                        <div className={`d-flex align-items-center ${modalInstance ? 'justify-content-between' : 'justify-content-end'} w-100 mt-3 mb-3`}>
                          {
                            modalInstance && (
                              <Button type="button" btn={BtnType.OUTLINE} onClick={handleDismiss}>
                                Cancel
                              </Button>
                            )
                          }
                          <div className="d-flex align-items-center">
                            {
                              activeStep > 0 && (
                                <Button
                                  type="button"
                                  btn={BtnType.OUTLINE}
                                  onClick={handleBack}
                                  className="mr-3"
                                >
                                  Previous
                                </Button>
                              )
                            }
                            {activeStep < totalSteps - 1 ? (
                              <Button
                                type="button"
                                btn={BtnType.REGULAR}
                                onClick={() => handleNext(formikProps)}
                                disabled={!stepValidations[activeStep]}
                              >
                                Next
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                btn={BtnType.REGULAR}
                                className="ml-3"
                                disabled={formikProps.isSubmitting}
                                onClick={formikProps.submitForm}
                              >
                                Submit
                              </Button>
                            )}
                          </div>
                        </div>
                      )
                    }
                  </DialogActions>
                </>
              )
            }
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(NewProjectStepsWrapper);

import ChartDataLabels from "chartjs-plugin-datalabels";

import { externalTooltipHandler } from "modules/companies/companyMain/chartsWrapper/chartsView/charts/customTooltip";

let previousYear = null;

export const makeLineChartOptions = chartData => ({
  scales: {
    y: {
      grid: {
        display: false
      },
      stacked: true,
      title: {
        display: true,
        text: chartData.y_title,
        font: {
          size: 14,
        },
        color: "#323f4b",
        letterSpacing: '1px',
      },
      ticks: {
        callback: (label) => {
          if (chartData.appendToYAxe) {
            return `${chartData.appendToYAxe}${label}`;
          }

          return label;
        },
      },
      beginAtZero: true,
    },
    x: {
      grid: {
        display: false
      },
      stacked: true,
      title: !chartData.doubleXAxe ? {
        display: true,
        text: chartData.x_title,
        font: {
          size: 14,
        },
        color: "#323f4b",
        letterSpacing: '1px',
      } : {
        display: false,
      },
      ticks: {
        callback: function(label) {
          const realLabel = this.getLabelForValue(label);

          if (!chartData.doubleXAxe) {
            return realLabel;
          }

          const [month] = realLabel.split(" ");

          if (month) {
            return month
          }
        }
      }
    },
    xAxis2: chartData.doubleXAxe ? {
      title: {
        display: true,
        text: chartData.x_title,
        font: {
          size: 14,
        },
        color: "#323f4b",
        letterSpacing: '1px',
      },
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        callback: function(label) {
          const realLabel = this.getLabelForValue(label)
          const [_, year] = realLabel.split(" ");

          if (previousYear === null || previousYear !== year) {
            previousYear = year;
            return year;
          }
        }
      }
    } : {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          if (chartData.hideSubLabel) {
            return ` ${context.dataset.data[context.dataIndex]}`;
          }

          return context.dataset?.sub_category_label
            ? `${context.dataset?.sub_category_label}: ${context.dataset.data[context.dataIndex]}`
            : 'No label'
        },
      },
      enabled: false,
      position: "nearest",
      external: externalTooltipHandler,
    },
    datalabels: {
      display: false,
    }
  },
  title: {
    display: false,
  },
  layout: {
    padding: {
      bottom: 0,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
});

export const makeLineConfig = (chartData) => {
  return {
    data: {
      labels: chartData.labels,
      datasets: chartData.data_sets.map(d => ({
        backgroundColor: chartData.background_color,
        borderColor: d.color,
        data: d.data,
        tension: 0.1,
        fill: false,
        borderWidth: d.parent_attribute || d.sub_attributes ? 6 : 3,
        sub_category_label: d.sub_category_label,
        pointBackgroundColor: d.color,
        pointHoverBackgroundColor: d.color,
      })),
    },
    options: makeLineChartOptions(chartData),
    plugins: [ChartDataLabels],
  };
}

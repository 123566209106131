import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik } from "formik";

import { BtnType, Button, CheckBox, CheckType, Label } from "modules/primitives";
import { ModalFooter } from "application/modal";
import { formInitialValues } from "./constant";
import useModelPopup from "common/hooks/modelPopup.hook";
import { editProductDetails } from "modules/companies/product/store/product.actions";

const certifications = ['SOC-2', 'ISO-9000', 'ISO 27001', 'FedRAMP']

const EditProductSecurity = ({ product }) => {
  const popup = useModelPopup();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(formInitialValues);
  const [showLoading, setShowLoading] = useState(false);
  const [selectedCerts, setSelectedCerts] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (product) {
      setFormValues({
        ...product,
        sso: product.sso ? 'yes' : 'no',
      });
      setSelectedCerts(product.certifications || []);
    }
  }, [product]);

  const handleSave = (values) => {
    setShowLoading(true);

    const payload = {
      companyId: product.company.id,
      productId: product.id,
      data: {
        sso: values.sso === 'yes',
        certifications: selectedCerts,
      },
    }

    dispatch(editProductDetails(payload));
    popup.hide();
  };

  const handleCancelClick = () => {
    popup.hide();
  }

  const securitySelect = s => {
    const isSelected = selectedCerts.find(c => c === s);

    if (isSelected) {
      setSelectedCerts(selectedCerts.filter(c => c !== s));
    } else {
      setSelectedCerts([...selectedCerts, s])
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={formValues}
        onSubmit={handleSave}
      >
        {({ values, handleSubmit, setFieldValue, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <div className="px-4 mt-3">
              <Label>Security Certifications</Label>
              {
                certifications.map(c => (
                  <div key={c} className="d-flex">
                    <label className="d-flex align-items-center">
                      <CheckBox
                        checkType={CheckType.BLUE}
                        isChecked={selectedCerts.includes(c)}
                        onChange={() => securitySelect(c)}
                      />
                      {c}
                    </label>
                  </div>
                ))
              }
              <Label>SSO</Label>
              <fieldset id="SSO" className="d-flex mb-5">
                <div className="d-flex mr-3">
                  <input
                    type="radio"
                    id="SSO-yes"
                    name="SSO"
                    value="yes"
                    checked={values.sso === 'yes'}
                    onChange={() => setFieldValue('sso', 'yes')}
                  />
                  <label className="small ml-1" htmlFor="SSO-yes">Yes</label>
                </div>
                <div className="d-flex">
                  <input
                    type="radio"
                    id="SSO-no"
                    name="SSO"
                    value="yes"
                    checked={values.sso === 'no'}
                    onChange={() => setFieldValue('sso', 'no')}
                  />
                  <label className="small ml-1" htmlFor="SSO-no">No</label>
                </div>
              </fieldset>
            </div>
            <ModalFooter>
              <Button
                className="mr-3"
                btn={BtnType.FRAME_LESS}
                onClick={handleCancelClick}
                disabled={showLoading}
              >
                Cancel
              </Button>
              <Button
                disabled={showLoading}
                type="submit"
                onClick={handleSubmit}
                btn={BtnType.REGULAR}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditProductSecurity;

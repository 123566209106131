export const layoutList = [
  { type: "icn-view-card", name: "card" },
  { type: "icn-view-list", name: "list" },
];
export const filterOptions = [
  { text: "Newest/Oldest", value: "newest/oldest" },
  { text: "Alphabetical", value: "alphabetical", optGroup: true },
  { text: "A-Z", value: "a_z" },
  { text: "Z-A", value: "z_a" },
  { text: "Track", value: "track", optGroup: true },
  { text: "Partnership", value: "partnership" },
  { text: "Investment", value: "investment" },
];

import React from "react";
import { Link } from "react-router-dom";

import { MoreOptions } from "modules/primitives/index";
import styles from "./documents.module.scss";
import { Icon, smSize } from "../../../../common/icon";
import { shouldDownload } from "../../../../common/helper";
import { moreOptions } from "./documents.constatnt";
import UploadedByInfo from "../../../../common/components/uploadedByInfo";

const MobileDocRow = ({
  document,
  documentName,
  handleMoreOptionClick,
  session,
}) => {
  return (
    <div className={styles.mobileGridRow}>
      <div className={styles.mobileRow}>
        <span />
        <div>
          <div className={styles.moreOpt}>
            <div className="mr-1">
              <a href={document.uri} target="_blank" download rel="noreferrer">
                <Icon
                  {...smSize}
                  icon="icn-export-button"
                  className={styles.download}
                />
              </a>
              <a
                className={styles.docView}
                target="blank"
                href={document.uri}
                download
              >
                {shouldDownload(document.name) ? "download" : "view"}
              </a>
            </div>
            {(document.added_by?.id === session.id ||
              session.council_role.indexOf("admin") >= 0) && (
              <MoreOptions
                options={moreOptions}
                className={styles.moreOptWrp}
                onClick={(e) => {
                  handleMoreOptionClick(e, document.id, document.name);
                }}
                customSvgIcon
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.mobileRow}>
        <span>Document</span>
        <div className={styles.docName}>{documentName || "-"}</div>
      </div>
      <div className={styles.mobileRow}>
        <span>Related Project</span>
        <div>
          {document.project?.project_id ? (
            <Link to={`/projects/${document.project?.project_id}`}>
              {document.project?.name}
            </Link>
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className={styles.mobileRow}>
        <UploadedByInfo document={document} isMobile />
      </div>
      <div className={styles.mobileRow}>
        <span>Related Task</span>
        <div>
          {document.projects_task_assignment?.projects_task?.name ? (
            <Link
              to={`/projects/${document.project?.project_id}/task?tab=all_task&taskAsignementId=${document.projects_task_assignment?.projects_task_assignment_id}&taskId=${document.projects_task_assignment?.projects_task?.projects_task_id}`}
            >
              {document.projects_task_assignment?.projects_task?.name}
            </Link>
          ) : (
            "-"
          )}
        </div>
      </div>
    </div>
  );
};
export default MobileDocRow;

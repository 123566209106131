import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import UploadImgComponent from "modules/primitives/uploadImg/uploadImg.component";
import DropDownNestedElements from "common/components/dropdownNestedElements";
import isItSmallDevice from "modules/themes/themesCommon/isItSmallDevice";

import {
  Label,
  DropSelect,
  Button,
  TextArea,
  BtnType,
  Loading,
} from "../../../primitives";
import styles from "./styles.module.scss";
import {
  themesGetTopicAttempt,
  themesGetIndustriesAttempt,
  themesPostAttempt,
  themesEditAttempt,
} from "../themesMain.action";

import { model, typeSelectOptions } from "./themesModal.constant";
import { ModalFooter, ModalBody } from "../../../../application/modal/index";

const ThemeModal = (props) => {
  const { popup, formRef, width, mode, data } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [imageURL, setImageURL] = useState(data?.logo ? data?.logo : "");
  const [type, setType] = useState("");
  const [editModel, setEditModel] = useState({});
  const [typeError, setTypeError] = useState(false);
  const [nameError, setNameError] = useState(false);

  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);

  const {
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: {
        topics,
        industries,
        shouldRedirectToTheme,
        loadingIndividualTheme,
        currentThemeData,
      },
    },
  } = useSelector((state) => state);

  const handleImgUpdate = (url) => {
    setImageURL(url);
  };

  const handleCancelClick = () => {
    popup.hide();
  };

  const onSubmitValue = (values) => {
    if (!values.name) {
      setNameError(true);
      return;
    }
    if (!type) {
      setTypeError(true);
      return;
    }

    const submitData = {
      name: values.name,
      logo: imageURL,
      description: values.description,
      current_option: type,
      topic_ids: [...new Set(selectedTopics)] || [],
      industry_ids: [...new Set(selectedIndustries)] || [],
    };
    if (mode === "edit") {
      dispatch(
        themesEditAttempt({
          data: submitData,
          id: data.id,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(
        themesPostAttempt({
          data: submitData,
          enqueueSnackbar,
        })
      );
    }
    setTypeError(false);
    setNameError(false);

    popup.hide();
  };

  useEffect(() => {
    if (data?.id) {
      setEditModel({
        name: data.name,
        description: data.description,
        logo: data.logo,
      });

      setSelectedTopics(data.topics.map((t) => t.id));
      setSelectedIndustries(data.industries.map((i) => i.id));

      setType(data.current_option);
    }
  }, [data]);

  useEffect(() => {
    if (selectedCouncil?.id) {
      dispatch(
        themesGetTopicAttempt({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
      dispatch(
        themesGetIndustriesAttempt({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil]);

  useEffect(() => {
    if (shouldRedirectToTheme) {
      history.push({
        pathname: `/themes/individual_page/${currentThemeData.id}/overview`,
        state: { currentThemeData },
      });
    }
  }, [shouldRedirectToTheme]);

  const handleTagSelect = (topicsId) => {
    setSelectedTopics([...selectedTopics, topicsId]);
  };

  const handleIndustrySelect = (val) => {
    setSelectedIndustries((prev) => [...prev, val]);
  };

  const handleTagRemove = (updatedArr, childKey) => {
    if (childKey.includes("topic")) {
      setSelectedTopics(updatedArr.map((el) => el.id));
    }
    if (childKey.includes("industri")) {
      setSelectedIndustries(updatedArr.map((el) => el.id));
    }
  };
  console.log("---------");
  return (
    <div style={width ? { maxwidth: width } : null} className={styles.inner}>
      <Formik
        className={styles.form}
        enableReinitialize
        innnerRef={formRef}
        initialValues={mode === "edit" ? editModel : model}
        // validationSchema={validation && validation}
        onSubmit={(values, { setSubmitting }) => {
          if (values.name === undefined) {
            return;
          }
          setSubmitting(true);
          onSubmitValue(values);
        }}
      >
        {({ handleSubmit, setFieldValue, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody className={styles.modalBody}>
              <div className="row">
                <div className="col-12">
                  <Label>
                    {selectedCouncil?.settings?.themes_display_name_singular ||
                      "Theme"}{" "}
                    Name (Required)
                  </Label>

                  <TextArea
                    type="text"
                    name="name"
                    placeholder="Name"
                    className={styles.inputBox}
                    formProps={{ ...formikprops }}
                    rows={0}
                  />
                  <span className={styles.error}>
                    {nameError &&
                      `${
                        selectedCouncil?.settings
                          ?.themes_display_name_singular || "Theme"
                      } name is required!`}
                  </span>
                </div>
                <div className="col-12">
                  <Label>Type (Required)</Label>
                  <DropSelect
                    onChange={(val) => {
                      setTypeError(false);
                      setType(val.obj[0]?.value);
                    }}
                    name="type"
                    placeholder="Select Type"
                    labelField="text"
                    searchBy="text"
                    valueField="value"
                    SelecedValues={type}
                    options={typeSelectOptions}
                    formProps={formikprops}
                  />
                  <span className={styles.error}>
                    {typeError &&
                      `${
                        selectedCouncil?.settings
                          ?.themes_display_name_singular || "Theme"
                      } type is required!`}
                  </span>
                </div>
                {/* <div className={styles.formElement}> */}
                <div className={styles.addImageBlock}>
                  {loadingIndividualTheme ? (
                    <Loading />
                  ) : (
                    <UploadImgComponent
                      name="logo"
                      customText={isItSmallDevice() && "Select an image"}
                      imgSize={styles.imgSize}
                      imgStyle={styles.imageStyle}
                      uploadDirName={`users/${currentThemeData?.id}/key`}
                      onChange={handleImgUpdate}
                      image={data?.logo}
                      formProps={{
                        ...formikprops,
                      }}
                    />
                  )}
                </div>
                <div className="col-12">
                  <Label>Description</Label>
                  <TextArea
                    type="text"
                    name="description"
                    // onChange={(e) => {
                    //   setDescription(e.target.value);
                    // }}
                    placeholder={`${
                      selectedCouncil?.settings?.themes_display_name_singular ||
                      "Theme"
                    } description`}
                    className={styles.textBox}
                    formProps={{ ...formikprops }}
                    rows={8}
                  />
                </div>
                <div className={styles.tagsBlock}>
                  <div className={styles.tagsTitle}>Tags:</div>
                  <div className={styles.selectDrops}>
                    <div className="col-12">
                      <Label>Technology</Label>
                      <DropDownNestedElements
                        tags={topics}
                        selectedTags={selectedTopics}
                        childKey="children_topics"
                        tagKeyName="id"
                        parentKeyName="parent_resource_topic_id"
                        handleTagSelect={handleTagSelect}
                        label="Select technologies"
                        handleTagRemove={handleTagRemove}
                        // disabled={showLoading}
                      />
                    </div>

                    <div className="col-12">
                      <Label>Industries</Label>
                      <DropDownNestedElements
                        tags={industries}
                        selectedTags={selectedIndustries}
                        childKey="children_industries"
                        tagKeyName="id"
                        parentKeyName="parent_resource_industry_id"
                        handleTagSelect={handleIndustrySelect}
                        label="Select industries"
                        handleTagRemove={handleTagRemove}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <br />
              </div>
            </ModalBody>
            <div className={styles.footerActions}>
              <ModalFooter>
                <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                  Cancel
                </Button>
                <Button type="submit" btn={BtnType.REGULAR}>
                  {mode === "edit" ? "Save" : "Create"}
                </Button>
              </ModalFooter>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(ThemeModal);

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getProductDetails } from "modules/companies/product/store/product.actions";
import { Search } from "modules/primitives";
import ProductTopDetails from "modules/companies/product/components/productTopDetails";
import LoadingComponent from "modules/primitives/loading/loading.component";
import ProductTopActions from "modules/companies/product/components/productTopActions";
import { getCookie } from "common/helper";
import ProductOverview from "modules/companies/product/components/overview";
import styles from "./product.module.scss";
import { checkViewerRole } from "../../../common/checkers/viewerChecker";
// import NewTabs from "../../../common/components/newTabs/newTabs.component";

const Product = () => {
  const {
    authReducer: { session },
    productReducer: { productDetails, loading, productIsDownloading },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const history = useHistory();
  const dispatch = useDispatch();
  const { id, productId } = useParams();

  const [query, setQuery] = useState();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  // const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const council_role = getCookie("council_role_");

    setIsAdmin(
      council_role === "ttp_administrator" ||
        council_role === "council_administrator"
    );

    return () => null;
  }, []);

  useEffect(() => {
    if (session?.council_role && checkViewerRole(session.council_role)) {
      setBlockFunctionality(true);
    }
  }, [session]);

  useEffect(() => {
    if (id && productId) {
      dispatch(
        getProductDetails({
          id,
          productId,
        })
      );
    }
  }, [id, productId]);

  const handleEnterPress = () => {
    history.push("/companies", { query });
  };

  // const tabList = [
  //   {
  //     tab: "Overview",
  //     name: "Overview",
  //     component: (
  //       <ProductOverview
  //         product={productDetails}
  //         selectedCouncil={selectedCouncil}
  //         canEdit={isAdmin}
  //       />
  //     )
  //   },
  //   {
  //     tab: "Projects",
  //     name: "Projects",
  //     component: (
  //       <div>Projects</div>
  //     )
  //   }
  // ]

  return (
    <div className={styles.wrapper} id="downloadableSection">
      <div className={styles.headerWrapper}>
        {!productIsDownloading ? (
          <div className={styles.searchWrapper}>
            <div className={styles.searchContainer}>
              <Search
                handleEnterPress={handleEnterPress}
                onChange={setQuery}
                onKeyUp
                className={styles.searchCon}
                placeholder="Search Companies, Technologies, and Industries"
                from="company"
              />
            </div>
          </div>
        ) : null}
        {loading ? (
          <LoadingComponent customText="Loading product details..." />
        ) : (
          <div className={styles.infoWrapper}>
            <div className={styles.preWrapper}>
              <ProductTopDetails
                product={productDetails}
                isAdmin={isAdmin}
                blockFunctionality={blockFunctionality}
              />
              <ProductTopActions
                isAdmin={isAdmin}
                blockFunctionality={blockFunctionality}
                product={productDetails}
              />
            </div>
          </div>
        )}
      </div>
      {!loading && (
        <div
          className="mb-5 w-100"
          style={{ maxWidth: "1400px", margin: "auto" }}
        >
          <ProductOverview
            product={productDetails}
            selectedCouncil={selectedCouncil}
            canEdit={isAdmin || !blockFunctionality}
          />
        </div>
        // <NewTabs
        //   tabList={tabList}
        //   defaultIndex={selectedIndex}
        //   onSelect={setSelectedIndex}
        //   companyUse
        // />
      )}
    </div>
  );
};

export default Product;

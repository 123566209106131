import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styles from '../styles.module.scss';
import { BtnType, Button } from '../../../../primitives';
import { formCodeTemplate } from '../constant';
import LoadingComponent from '../../../../../modules/primitives/loading/loading.component';

const EmdedCodeStep = ({ customActionsButtons, onBackClick, mode, form }) => {
  const history = useHistory();
  const API_HOST = process.env.REACT_APP_REDIRECT_URI_APP;
  const [iframeCode, setIframeCode] = useState(
    `<iframe src="${API_HOST}/submission-idea-form/${form.council_name_slug}/${form.form_name_slug}" style="position: absolute; display: block; width: 100%; height: 100%;"></iframe>`
  );

  const [formMode, setFormMode] = useState(false);
  const {
    adminIdeasReducer: { ideaIframeData },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!form.council_name_slug && !form.form_name_slug) {
      setIframeCode(
        `<iframe src="${API_HOST}/submission-idea-form/${ideaIframeData.council_name_slug}/${ideaIframeData.form_name_slug}" style="position: absolute; display: block; width: 100%; height: 100%;" ></iframe>`
      );
    }
  }, [ideaIframeData]);

  const templateVariables = {
    formId: form.id,
    formData: {
      formId: form.id,
    },
  };

  const embedCode = formCodeTemplate.replace(/\$(\w+)/g, (match, p1) => {
    if (templateVariables[p1]) {
      if (typeof templateVariables[p1] === 'object') {
        return JSON.stringify(templateVariables[p1]);
      }
      return templateVariables[p1];
    }
    return `:${p1}`;
  });

  const handleCopyButtonClick = (text) => navigator.clipboard.writeText(text);

  const handleDoneButtonClick = () => {
    history.push('/admin/ideas?tab=forms');
  };
  const handlePreviewButtonClick = () => {
    let shareUrl
    if (!ideaIframeData.council_name_slug || !ideaIframeData.form_name_slug) {
      return <LoadingComponent />
    }

    if (mode === "add") {
      shareUrl = `${window.location.origin}/submission-idea-form/${ideaIframeData.council_name_slug}/${ideaIframeData.form_name_slug}`
    } else {
      shareUrl = `${window.location.origin}/submission-idea-form/${form.council_name_slug}/${form.form_name_slug}`
    }

    window.open(shareUrl)
  };

  const renderActions = () => {
    const backBtn = (
      <Button
        btn={BtnType.FRAME_LESS}
        type="button"
        onClick={() => {
          onBackClick();
        }}
        key="back-btn"
        className="mr-3"
      >
        Prev
      </Button>
    );

    const copyBtn = (
      <Button
        btn={BtnType.HIGLIGHT}
        type="button"
        onClick={() => handleCopyButtonClick(formMode ? iframeCode : embedCode)}
        key="copy-btn"
      >
        Copy
      </Button>
    );

    const previewBtn = (
      <Button
        type="button"
        onClick={handlePreviewButtonClick}
        key="preview-btn"
      >
        Preview
      </Button>
    );

    const doneBtn = (
      <Button type="button" onClick={handleDoneButtonClick} key="done-btn">
        Done
      </Button>
    );

    let buttons = [
      backBtn,
      <div className="d-flex" key="btn-block-wrap">
        {copyBtn}
        <div className="mr-1 ml-1" />
        {previewBtn}
      </div>,
      doneBtn,
    ];

    if (customActionsButtons) {
      buttons = [copyBtn, previewBtn];
    }

    return buttons;
  };
  return (
    <div>
      <div className="row">
        <div className="col">
          <p>
            This form will appear on the ideas page when a member clicks
            &quot;Submit Idea&quot;:
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className={styles.switchFormTypeBlock}>
            <Button
              onClick={() => setFormMode(false)}
              className={formMode ? styles.defaultButton : styles.pressedButton}
            >
              Embed code
            </Button>
            <Button
              onClick={() => setFormMode(true)}
              className={formMode ? styles.pressedButton : styles.defaultButton}
            >
              Iframe
            </Button>
          </div>
          <p>
            {`
                Publish this form by simply copying and pasting the ${
                  formMode ? 'iframe code ' : 'embed code'
                }
                below into the HTML code on your website`}
          </p>
          {formMode ? (
            <div className={styles.iframeForm}>
              <div className={styles.iframeBlock}>
                <div className={styles.embedCodeBlock}>
                  <code>{iframeCode}</code>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className={styles.embedCodeBlock}>
                <code>{embedCode}</code>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="pt-3 pb-3 d-flex justify-content-between">
            {renderActions()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EmdedCodeStep);

import React, { useEffect } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";

import ClaimProfileHeader from "./components/header";
import styles from "../main/main.module.scss";
import ErrorBoundary from "../../common/components/errorBoundary";
import ClaimProfileOverView from "../../modules/claimProfile/overview";
import ClaimProfileInfoRequests from "../../modules/claimProfile/informationRequests";
import SettingsComponent from "../../modules/settings/settings.component";
import { councilGet } from "modules/council/council.action";
import { ModalComponent } from "../modal";
import SliderWindowComponent from "../siderWindow";
import SideNavigationComponent from "../sideNavigation";
import { appMobileNaviSet } from "../app.action";
import PrivacyPolicyComponent from "../../modules/privacyPolicy/privacyPolicy.component";
import ThemesComponent from "modules/themes/themesMainPage/themesMain.component";
import IdeasWrapper from "modules/ideasWrapper";
import ThemesIndividualPageComponent from "modules/themes/themesIndividualPage";
import ManageThemes from "modules/themes/themesIndividualPage/themeEdit";

const ClaimProfileWrapper = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const {
    appReducer: { showMobileNavi },
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  // useEffect(() => {
  //   const user = localStorage.getItem("session");
  //   if (!session) {
  //     console.log('user', JSON.parse(user));
  //     if (user) {
  //       dispatch(setUser(JSON.parse(user)));
  //     } else {
  //       history.push("/login");
  //     }
  //   }
  // }, [session, dispatch]);

  useEffect(() => {
    const council = localStorage.getItem("council");
    const id = council ? JSON.parse(council)?.id : null;

    if (id && !selectedCouncil) {
      dispatch(
        councilGet({
          id,
          enqueueSnackbar,
          integrated_tokens: session?.integrated_tokens,
        })
      );
    }

    if (selectedCouncil) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
      }, 100);
    }
  }, [selectedCouncil]);

  useEffect(() => {
    const council = localStorage.getItem("council");

    // get default council details
    if (!council && session) {
      dispatch(
        councilGet({
          id: session.default_council.id,
          enqueueSnackbar,
          integrated_tokens: session?.integrated_tokens,
        })
      );
    }
  }, [session]);

  const handleBackClick = () => {
    dispatch(appMobileNaviSet(false));
  };

  return (
    <section className={styles.mainWrp}>
      <BrowserRouter>
        {session && <ClaimProfileHeader {...props} />}
        <div className={styles.mainCon}>
          <ErrorBoundary>
            <Switch>
              <Route path="/overview" component={ClaimProfileOverView} />
              <Route
                path="/info-requests"
                component={ClaimProfileInfoRequests}
              />
              <Route path="/settings" component={SettingsComponent} />
              <Route path="/setup" component={SettingsComponent} />
              <Route
                path="/privacy_policy"
                component={PrivacyPolicyComponent}
              />
              {selectedCouncil?.traction_tag === "home_x" && (
                <>
                  <Route path="/themes">
                    <Switch>
                      <Route exact path="/themes" component={ThemesComponent} />
                      <Route
                        exact
                        path="/themes/:id"
                        component={ThemesComponent}
                      />
                      <Route
                        exact
                        path="/themes/individual_page/:id/overview"
                        component={ThemesIndividualPageComponent}
                      />
                      <Route
                        exact
                        path="/themes/individual_page/:id/editTheme"
                        component={ManageThemes}
                      />
                      <Route
                        exact
                        path="/themes/individual_page/:id/documents"
                        component={ThemesIndividualPageComponent}
                      />
                    </Switch>
                  </Route>
                  <Route path="/ideas" render={() => <IdeasWrapper />} />
                </>
              )}
              <Redirect from="/" to="/overview" />
            </Switch>
          </ErrorBoundary>
        </div>
        <div className={styles.modelWrp}>
          <ModalComponent />
        </div>
        <SliderWindowComponent />
        <SideNavigationComponent {...props} />
      </BrowserRouter>
      <div
        className={classNames(
          styles.transBackground,
          showMobileNavi && styles.show
        )}
        onClick={handleBackClick}
      />
    </section>
  );
};

export default React.memo(ClaimProfileWrapper);

import styles from "./leaderboard.module.scss";

export const topProjectsColumns = [
  {
    columnName: "",
    columnSortKey: "",
    columnStyles: [styles.nrHeaderCell],
    sortable: true,
  },
  {
    columnName: "Project",
    columnSortKey: "project_name",
    columnStyles: [styles.projNameCol],
    sortable: true,
  },
  {
    columnName: "Plant",
    columnSortKey: "plant_name",
    columnStyles: [],
    sortable: true,
  },
  {
    columnName: "Savings",
    columnSortKey: "project_savings_usd",
    columnStyles: [],
    sortable: true,
  },
  {
    columnName: "Rating",
    columnSortKey: "project_rating",
    columnStyles: [],
    sortable: true,
  },
  {
    columnName: "Replications",
    columnSortKey: "project_replications_count",
    columnStyles: [],
    sortable: true,
  },

  {
    columnName: "Technology",
    columnSortKey: "project_topics",
    columnStyles: [],
    sortable: true,
  },
  {
    columnName: "Score",
    columnSortKey: "project_overall_score",
    columnStyles: [],
    sortable: true,
  },
];
export const trendindTechColumns = [
  {
    columnName: "",
    columnSortKey: "",
    columnStyles: [styles.nrHeaderCell],
    sortable: true,
  },
  {
    columnName: "Technology",
    columnSortKey: "topic_name",
    columnStyles: [styles.techHeaderCell],
    sortable: true,
  },
  {
    columnName: "Projects",
    columnSortKey: "topic_projects_count",
    columnStyles: [styles.projHeaderCell],
    sortable: true,
  },
  {
    columnName: "Total Savings",
    columnSortKey: "topic_projects_savings",
    columnStyles: [styles.totalHeaderCell],
    sortable: true,
  },
];

export const highestSavingsColumns = [
  {
    columnName: "",
    columnSortKey: "",
    columnStyles: [styles.nrHeaderCell],
    sortable: true,
  },
  {
    columnName: "Project",
    columnSortKey: "project_name",
    columnStyles: [styles.trendProjNameCol],
    sortable: true,
  },
  {
    columnName: "Plant",
    columnSortKey: "plant_name",
    columnStyles: [styles.plantHeaderCell],
    sortable: true,
  },
  {
    columnName: "Savings",
    columnSortKey: "savings",
    columnStyles: [styles.totalProjSavingsHeaderCell],
    sortable: true,
  },
];
export const trendingProjectsColumns = [
  {
    columnName: "",
    columnSortKey: "",
    columnStyles: [styles.nrHeaderCell],
    sortable: true,
  },
  {
    columnName: "Project",
    columnSortKey: "project_name",
    columnStyles: [styles.trendProjNameCol],
    sortable: true,
  },
  {
    columnName: "Plant",
    columnSortKey: "plant_name",
    columnStyles: [styles.plantHeaderCell],
    sortable: true,
  },
  {
    columnName: "Rating",
    columnSortKey: "project_rating",
    columnStyles: [styles.totalProjSavingsHeaderCell],
    sortable: true,
  },
];

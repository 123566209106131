import {
  THEME_PROJECTS_GET_ATTEMPT,
  THEME_PROJECTS_GET_SUCCESS,
} from "./projectsTab.action";
import { pageMeta } from "../../../../common/httpCall";

const initState = {
  projects: [],
  loadingProj: false,
  projectListMeta: {},
};

const themesProjectsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case THEME_PROJECTS_GET_ATTEMPT:
      return {
        ...state,
        loadingProj: true,
      };
    case THEME_PROJECTS_GET_SUCCESS: {
      const pagemeta = pageMeta(payload);
      return {
        ...state,
        projects: payload.response,
        projectListMeta: pagemeta,
        loadingProj: false,
      };
    }

    default:
      return state;
  }
};
export default themesProjectsReducer;

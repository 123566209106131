import React from "react";
import classNames from "classnames";

import styles from "./textbox.module.scss";

const TextBoxcomponent = (props) => {
  const {
    className,
    name,
    helperText,
    formProps = {},
    onChange,
    onBlur = () => {},
    id,
    value = "",
    defaultErr = true,
    selectedValue = "",
    withClear,
    ...rest
  } = props;
  const {
    errors,
    touched,
    values,
    handleBlur = () => {},
    handleChange,
  } = formProps;

  const handleClear = (evt) => {
    if (handleChange) {
      formProps.setFieldValue(name, '');
    }

    if (onChange) {
      const event = {
        ...evt,
        target: {
          value: ''
        }
      };

      onChange(event);
    }
  }

  return (
    <div
      className={classNames(
        styles.textBoxWrp,
        className,
        name && touched && touched[name]
          ? errors[name]
            ? styles.error
            : ""
          : "",
        withClear && "justify-content-end align-items-end",
      )}
    >
      <input
        {...rest}
        value={
          (name && values[name]) ?? value ?? (selectedValue ?? selectedValue)
        }
        onBlur={(e) => {
          handleBlur(e);
          onBlur(e);
        }}
        onChange={(evt) => {
          if (handleChange) {
            handleChange(evt);
          }

          if (onChange) {
            onChange(evt);
          }
        }}
        id={id || name}
        className={styles.textBox}
      />
      {
        withClear && (
          <span
            style={{ color: '#2d83ae' }}
            className="cursor-pointer small"
            onClick={handleClear}
          >clear</span>
        )
      }
      {helperText ? (
        <div className="form-text text-muted small text-left w-100">
          {helperText}
        </div>
      ) : null}
      <div className={classNames(styles.errorMsg, "caption small")}>
        {defaultErr && name && touched[name] && errors[name]
          ? errors[name]
          : ""}
      </div>
    </div>
  );
};

export default React.memo(TextBoxcomponent);

import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  httpGet,
  httpPut,
  httpPost,
  queryString,
  errorHandler,
} from "../../../common/httpCall";

import {
  EVENTS_GET_CURRENT_LIST_ATTEMPT,
  eventsCurrentListGetSuccess,
  EVENTS_GET_PAST_LIST_ATTEMPT,
  eventsPastListGetSuccess,
  EVENTS_GET_CURRENT_ATTENDEES_ATTEMPT,
  eventsAttendeesGetSuccess,
  EVENTS_CREATE_NEW_EVENT_ATTEMPT,
  eventsCreateNewEventSuccess,
  EVENTS_EDIT_EVENT_ATTEMPT,
  eventsEditEventSuccess,
} from "../viewEvent/viewEvent.action";

export const epicGetCurrentEventList = (action$, state$) =>
  action$.pipe(
    ofType(EVENTS_GET_CURRENT_LIST_ATTEMPT),
    switchMap(
      ({ payload: { councilId, currentPage, pageSize, enqueueSnackbar } }) =>
        httpGet({
          // call: `events`
          call: `events/current?${queryString({
            council_id: councilId,
            items: pageSize,
            page: currentPage,
          })}`, // NEW_API
          // call: `events/current/all?council=${councilId}&limit=${limit}&&event_page=${page}`,
        }).pipe(
          map((result) => eventsCurrentListGetSuccess(result)),
          catchError((error) => errorHandler(error, enqueueSnackbar))
        )
    )
  );
export const epicGetPastEventList = (action$, state$) =>
  action$.pipe(
    ofType(EVENTS_GET_PAST_LIST_ATTEMPT),
    switchMap(
      ({ payload: { councilId, currentPage, pageSize, enqueueSnackbar } }) =>
        httpGet({
          call: `events/past?${queryString({
            council_id: councilId,
            items: pageSize,
            page: currentPage,
          })}`, // NEW_API
          // call: `events/past/all?council=${councilId}&limit=${limit}&&event_page=${page}`,
        }).pipe(
          map((result) => eventsPastListGetSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicGetEventAttendees = (action$, state$) =>
  action$.pipe(
    ofType(EVENTS_GET_CURRENT_ATTENDEES_ATTEMPT),
    switchMap(({ payload: { eventId, enqueueSnackbar } }) =>
      httpGet({
        call: `events/${eventId}/attendees`, // OLD_API
      }).pipe(
        map((result) => eventsAttendeesGetSuccess(result.response)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicCreateNewEvent = (action$, state$) =>
  action$.pipe(
    ofType(EVENTS_CREATE_NEW_EVENT_ATTEMPT),
    switchMap(
      ({
        payload: {
          title,
          description,
          starts_on,
          ends_on,
          location,
          website_uri,
          company_ids,
          live,
          speakers,
          slides_uri,
          video_uri,
          enqueueSnackbar,
          themes_attributes,
        },
      }) =>
        httpPost({
          call: "events",
          data: {
            event: {
              name: title,
              description,
              location,
              starts_on,
              ends_on,
              website_uri,
              company_ids,
              live_feed_uri: live,
              speakers_attributes: speakers,
              theme_events_attributes: themes_attributes,
              slides_uri,
              video_uri,
            },
          },
        }).pipe(
          map((result) => eventsCreateNewEventSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicEditEvent = (action$, state$) =>
  action$.pipe(
    ofType(EVENTS_EDIT_EVENT_ATTEMPT),
    switchMap(
      ({
        payload: {
          eventId,
          title,
          description,
          starts_on,
          ends_on,
          location,
          website_uri,
          company_ids,
          speakers,
          slides_uri,
          video_uri,
          enqueueSnackbar,
          themes_attributes,
        },
      }) =>
        httpPut({
          call: `events/${eventId}`,
          data: {
            event: {
              name: title,
              description,
              location,
              starts_on,
              ends_on,
              website_uri,
              video_uri,
              company_ids,
              speakers_attributes: speakers,
              slides_uri,
              theme_events_attributes: themes_attributes,
            },
          },
        }).pipe(
          map((result) => eventsEditEventSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

const eventsListEpic = combineEpics(
  epicGetCurrentEventList,
  epicGetPastEventList,
  epicGetEventAttendees,
  epicCreateNewEvent,
  epicEditEvent
);

export default eventsListEpic;

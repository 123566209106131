import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Checkbox from "@mui/material/Checkbox";

import { Button, BtnType } from "../../../primitives";
import { useModelPopup } from "../../../../common/hooks";
import styles from "./myLists.module.scss";
import { companyListShareRemoveMember } from "../../store/lists.actions";
import { ModalFooter } from "../../../../application/modal";

const SubmitButton = ({ popup, deleteMember }) => (
  <Button
    type="submit"
    btn={BtnType.REGULAR}
    onClick={() => {
      deleteMember();
      popup.hide();
    }}
    className={styles.deleteSubmitButton}
  >
    Remove
  </Button>
);

const DisabledButton = ({ showTooltip, setShowTooltip }) => (
  <>
    {showTooltip && (
      <div className={styles.modalTooltip}>
        <p>To submit this form you need to have at least one checked user</p>
      </div>
    )}
    <Button
      onMouseEnter={() => {
        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
      className={styles.deleteSubmitButton}
      btn={BtnType.DISABLED}
    >
      Remove
    </Button>
  </>
);

const ManageSharing = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [showTooltip, setShowTooltip] = useState(false);
  const [deletingUsers, setDelitingUsers] = useState([]);
  const [deletingTeams, setDelitingTeams] = useState([]);

  const {
    listsMainReducer: { savedList },
  } = useSelector((state) => state);

  const deleteMember = (arr) => {
    dispatch(
      companyListShareRemoveMember({
        listId: savedList.id,
        memberId: deletingUsers,
        team_ids: deletingTeams,
        enqueueSnackbar,
      })
    );
    popup.hide();
  };

  const handleCheck = (event, user) => {
    const existingDeletingUser = deletingUsers.find((item) => item === user.id);
    if (existingDeletingUser && !event.target.checked) {
      const newArr = deletingUsers.filter((el) => el !== existingDeletingUser);
      setDelitingUsers(newArr);
    } else setDelitingUsers([...deletingUsers, user.id]);
  };

  const listMember = savedList?.list_members
    .map((member) => {
      const memberObject = member.member;
      memberObject.role = member.user_role;
      memberObject.dispayName = `${`${memberObject.full_name} - ${
        memberObject.role.charAt(0).toUpperCase() + memberObject.role.slice(1)
      }`}`;
      return memberObject;
    })
    .filter((member) => member.role !== "owner");

  const handleCheckTeam = (event, team) => {
    if (deletingTeams.includes(team.id)) {
      setDelitingTeams((prev) => [...prev.filter((id) => id !== team.id)]);
      return;
    }
    setDelitingTeams((prev) => [...prev, team.id]);
  };
  const handleAllCheck = (event) => {
    if (event.target.checked) {
      setDelitingUsers([
        ...savedList?.list_members.map((member) => member.member.id),
      ]);
    } else {
      setDelitingUsers([]);
    }
  };

  return (
    <div>
      {savedList.teams?.length ? (
        <div>
          <span className={styles.label}>Teams: </span>
          <div className={styles.membersListWrp}>
            {savedList.teams.map((team, index) => (
              <div className={styles.user} key={index}>
                <div className={styles.name}>
                  <div>{team.name}</div>
                </div>
                <div className={styles.delete}>
                  <Checkbox
                    color="primary"
                    onChange={(event) => handleCheckTeam(event, team)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      <div>
        <div className={styles.labelCont}>
          <div className={styles.label}> Members:</div>
          <div className={styles.selectAllCont}>
            <div className={styles.name}>
              <div>Select All</div>
            </div>
            <div className={styles.delete}>
              <Checkbox
                color="primary"
                onChange={(event) => handleAllCheck(event)}
                inputProps={{ "aria-label": "primary checkbox" }}
                checked={deletingUsers.length === listMember.length - 1}
              />
            </div>
          </div>
        </div>
        <div className={styles.membersListWrp}>
          {listMember.map((user, index) => {
            return (
              <div className={styles.user} key={index}>
                <div className={styles.name}>
                  <div>{user.dispayName}</div>
                </div>
                <div className={styles.delete}>
                  <Checkbox
                    color="primary"
                    onChange={(event) => handleCheck(event, user)}
                    inputProps={{ "aria-label": "controlled" }}
                    checked={Boolean(
                      deletingUsers.find((item) => item === user.id)
                    )}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ModalFooter>
        <Button btn={BtnType.OUTLINE} onClick={() => popup.hide()}>
          Cancel
        </Button>
        {deletingUsers?.length > 0 || deletingTeams?.length > 0 ? (
          <SubmitButton deleteMember={deleteMember} popup={popup} />
        ) : (
          <DisabledButton
            setShowTooltip={setShowTooltip}
            showTooltip={showTooltip}
          />
        )}
      </ModalFooter>
    </div>
  );
};

export default ManageSharing;

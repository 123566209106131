import React, { useEffect, useMemo, useState } from "react";
import { Field, Formik } from "formik";
import { useSnackbar } from "notistack";

import { Button, BtnType } from "../../../primitives";
import { ModalFooter, ModalBody } from "../../../../application/modal";
import { useModelPopup } from "../../../../common/hooks";
import { PROJECT_DOWNLOAD, tabs, fordTabs, FORD_PROJECT_DOWNLOAD, fordSupplyChainTabs } from "./constants";

const ProjectDownload = ({ handleDownload, patientOrg, fordInstance, isFordSupplyChainAutomation }) => {
  const [model, setModel] = useState({tabs: [], isAllSelected: true});

  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (fordInstance) {
      setModel(FORD_PROJECT_DOWNLOAD(isFordSupplyChainAutomation));
      return;
    }

    if (patientOrg) {
      setModel({
        isAllSelected: true,
        tabs: PROJECT_DOWNLOAD.tabs
          .map(t => t === 'Companies' ? 'Organizations' : t)
          .filter((t) => t !== "Tasks" && t !== "Stages"),
      });
      return
    }

    setModel(PROJECT_DOWNLOAD);
  }, []);

  const handleCancelClick = () => popup.hide();

  const handleCloseDownload = async () => {
    if (model.tabs.length === 0) {
      handleCancelClick();
      enqueueSnackbar('Please select at least one section', {
        variant: 'error',
      });
      return;
    }

    handleDownload(model.tabs);
  };

  const handleOnChange = (evt) => {
    if (evt.target.name === "isAllSelected") {
      // eslint-disable-next-line no-use-before-define
      handleSelectAll();
      return;
    }

    const { value } = evt.target;
    const valueExists = model.tabs.some((id) => id === value);

    if (valueExists) {
      setModel({
        ...model,
        tabs: model.tabs.filter((id) => id !== value),
        isAllSelected: false,
      });
    } else {
      const tabsArr = [...model.tabs, value];
      const isAllSelected = tabsArr.length === tabs.length;

      setModel({
        ...model,
        tabs: tabsArr,
        isAllSelected,
      });
    }
  };

  const handleSelectAll = () => {
    if (model.isAllSelected) {
      setModel({
        ...model,
        tabs: [],
        isAllSelected: false,
      });
    } else {
      setModel({
        ...model,
        tabs,
        isAllSelected: true,
      });
    }
  };

  const source = useMemo(() => {
    if (patientOrg) {
      return tabs.filter((t) => t !== "Tasks" && t !== "Stages")
    }

    if (isFordSupplyChainAutomation) {
      return fordSupplyChainTabs;
    }

    return fordInstance ? fordTabs : tabs
  }, [fordInstance, isFordSupplyChainAutomation, patientOrg]);

  return (
    <div>
      <ModalBody>
        <div>
          <Formik enableReinitialize initialValues={model}>
            {() => (
              <form onChange={handleOnChange}>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className="d-flex align-items-center">
                        <Field type="checkbox" name="isAllSelected" />
                        <span className="ml-2">
                          {model.isAllSelected
                            ? "Deselect All Tabs"
                            : "Select All Tabs"}
                        </span>
                      </label>
                    </div>
                    <div role="group" className="d-flex flex-column">
                      {
                        source
                          .map(t => patientOrg && t === 'Companies' ? 'Organizations' : t)
                          .map((t) => (
                          // eslint-disable-next-line jsx-a11y/label-has-associated-control
                          <label key={t} className="d-flex align-items-center">
                            <Field type="checkbox" name="tabs" value={t} />
                            <span className="ml-2">{t}</span>
                          </label>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center">
          <Button
            className="mr-3"
            btn={BtnType.FRAME_LESS}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            btn={BtnType.REGULAR}
            onClick={handleCloseDownload}
          >
            Download
          </Button>
        </div>
      </ModalFooter>
    </div>
  );
};

export default ProjectDownload;

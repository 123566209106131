import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import styles from "modules/projects/project/tasks/taskInfo/taskInfo.module.scss";
import { BtnType, Button, CheckBox, CheckType, UserInline } from "modules/primitives";
import { userActionsEnum } from "modules/projects/project/tasks/approvalTask/constants";
import UserMultiSelectSearch from "../../../../../common/components/usersSearch";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { Icon, mdSize } from "../../../../../common/icon";
import { gatewayTaskUpdate } from "modules/projects/project/tasks/tasks.action";

export default function ApprovalUsers({ task, blockFunctionality, isReviewalTask, isProjectWideFromStage }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [showUsers, setShowUsers] = useState(false);
  const [usersSaveProgress, setUsersSaveProgress] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [approveInOrder, setApproveInOrder] = useState(false);

  useEffect(() => {
    setApproveInOrder(task.approve_in_order);
  }, [task]);

  const makeOriginalUsers = (t) => {
    const propToUser = isReviewalTask ? 'assigned_reviewers' : 'assigned_approvers';
    return t[propToUser]?.filter(obj => obj.assigned_to_id).map(obj => ({
      ...obj,
      ...obj.assigned_to,
      id: obj.assigned_to?.user_id,
      full_name: `${obj.assigned_to?.first_name} ${obj.assigned_to?.last_name}`
    })) || [];
  }

  useEffect(() => {
    if (task) {
      const users = makeOriginalUsers(task);
      setAssignedUsers(users);
    }
  }, [task]);

  const handleSaveNewUsers = useCallback(() => {
    setUsersSaveProgress(true);
    const userIds = assignedUsers.map(user => user.id);
    let props;

    if (isReviewalTask) {
      props = {
        assigned_reviewers: userIds,
      };
    } else {
      props = {
        assigned_approvers: userIds
      };
    }

    const payload = {
      project_task: {
        ...props,
        approve_in_order: approveInOrder,
      },
      taskId: task.id,
      projectId: task.project_id,
      enqueueSnackbar,
      cb: () => {
        enqueueSnackbar(`Successfully updated ${isReviewalTask ? 'reviewers' : 'approvers'} list`, {
          variant: 'success'
        });
        setShowUsers(false);
        setUsersSaveProgress(false);
      },
      isProjectWideFromStage,
    };

    dispatch(gatewayTaskUpdate(payload));
  }, [assignedUsers, task, isReviewalTask, approveInOrder]);

  const handleCancelUsersSelect = useCallback(() => {
    setShowUsers(false);
    const users = makeOriginalUsers(task);
    setAssignedUsers(users);
  }, [task]);

  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex justify-content-between mb-3">
        <label className={styles.label}>Assigned to</label>
        {
          !blockFunctionality && !showUsers && (
            <div className="d-flex justify-content-end ml-3 mr-3">
              <Icon
                {...mdSize}
                icon="icn-right-chevron"
                className={styles.icon}
                onClick={() => {
                  setShowUsers(true)
                }}
              />
            </div>
          )
        }
      </div>
      <div className="d-flex flex-column flex-grow-1 pr-2">
        {
          !showUsers ? assignedUsers?.map((u, index) => (
            <div
              key={u?.user_id}
              className={classNames(styles.values, styles.assign, "mb-1 justify-content-between")}
            >
              <div className="col-4">
                <UserInline
                  userId={u?.user_id}
                  avatar={u.avatar}
                  first={u.first_name}
                  last={u.last_name}
                  noNavigation
                />
              </div>
              <div className="col-3">
                <span className="small">{isReviewalTask ? "Reviewer" : "Approver"} {index + 1}</span>
              </div>
              <div className="col-3">
                <span
                  className={classNames(
                    'small',
                    u.status === 'completed' || u.status === 'approved' || u.status === 'approve' ? 'text-success' : '',
                    u.status === 'denied' ? 'text-danger' : ''
                  )}
                >
                  {userActionsEnum[u.status] || ''}
                </span>
              </div>
            </div>
          )) : null
        }
        {
          showUsers && (
            <div className="d-flex flex-column pl-3">
              <UserMultiSelectSearch
                multi
                placeholder="Search for users"
                labelField="full_name"
                valueField="id"
                searchBy="full_name"
                hasAll={false}
                selectedValues={assignedUsers}
                onChange={setAssignedUsers}
                projectId={task.project_id}
              />
              <div className="d-flex align-items-center mb-2">
                <CheckBox
                  checkType={CheckType.BLUE}
                  name="approveInOrder"
                  isChecked={approveInOrder}
                  onChange={(value) => setApproveInOrder(value)}
                />
                <span className="cursor-pointer small" onClick={() => setApproveInOrder(!approveInOrder)}>Approve in order</span>
              </div>
              <div className="mt-2">
                {
                  usersSaveProgress ? (
                    <LoadingComponent customText="Saving users..." />
                  ) : (
                    <>
                      <Button
                        btn={BtnType.FRAME_LESS}
                        onClick={() => handleCancelUsersSelect()}
                      >Cancel</Button>
                      <Button
                        className="ml-3"
                        onClick={() => handleSaveNewUsers()}
                      >Save</Button>
                    </>
                  )
                }
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

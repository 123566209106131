import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import * as classNames from "classnames";
import isItSmallDevice from "modules/themes/themesCommon/isItSmallDevice";

import ConfirmDeleteComponent from "../../../../../common/components/confirmDelete/index";
import styles from "./styles.module.scss";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import { Pannel, Loading, Pagination } from "../../../../primitives";
import { useModelPopup } from "../../../../../common/hooks";
import NewRelatedIdeaComponent from "./addIdeaModal";
import isItStandardViewver from "../../../themesCommon/isItStandardViewer";

const RelatedIdeasComponent = ({ themeId, ideasFromTheme = [] }) => {
  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },

    themesReducer: {
      themesMainReducer: { currentThemeData },

      themesOverviewReducer: { loadingIdeas, themeIdeasList },
    },
  } = useSelector((state) => state);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const [themeIdeas, setThemeIdeas] = useState(ideasFromTheme);
  const [isIdeasOpened, setIsIdeasOpenned] = useState(true);
  const [seeAll, setSeeAll] = useState(false);
  const [pageSetting, setPageSetting] = useState({
    pageSize: 20,
    current: 1,
    hideOnSinglePage: true,
    showTitle: false,
    total: themeIdeas?.length || 0,
  });
  const [chunkToShow, setChunkToShow] = useState([]);
  // const listLenght = seeAll ? themeIdeas.length : 6;

  const handlePagingChange = (page) => {
    setPageSetting((prev) => ({
      ...prev,
      total: themeIdeas?.length,
      current: page,
    }));
  };
  useEffect(() => {
    if (themeIdeas?.length && !seeAll) {
      setChunkToShow(themeIdeas.slice(0, 6));
    }
    if (pageSetting?.current && themeIdeas?.length && seeAll) {
      setChunkToShow(
        themeIdeas?.slice(
          (pageSetting.current - 1) * pageSetting.pageSize,
          pageSetting.current * pageSetting.pageSize
        )
      );
    }
  }, [pageSetting?.current, themeIdeas, seeAll]);

  const handleIdeaRedirect = (id) => {
    history.push(`/ideas/${id}`);
  };

  const handleDeleteIdeasClick = (ideaId) => {
    popup.show({
      title: "Confirmation Delete",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={themeId}
          templateId={ideaId}
          enqueueSnackbar={enqueueSnackbar}
          councilId={selectedCouncil?.id}
          actionType="deleteThemeIdea"
        />
      ),
    });
  };

  const handleAddIdeasClick = () => {
    popup.show({
      title: "Add an Idea to this Theme",
      component: <NewRelatedIdeaComponent addedList={ideasFromTheme} />,
    });
  };

  useEffect(() => {
    // if (themeIdeasList.length) {
    setThemeIdeas(themeIdeasList);
    // }
  }, [themeIdeasList]);
  useEffect(() => {
    if (currentThemeData.id) {
      setThemeIdeas(ideasFromTheme);
    }
  }, [selectedCouncil]);

  return (
    <Pannel
      title="Related Ideas"
      padding="0"
      parentStyle={{
        width: `${window.screen.width < 550 ? "100%" : "230px"}`,
        minWidth: "230px",
        padding: `${window.screen.width < 550 && "0px"}`,
      }}
    >
      <div className={styles.panelHeader}>
        Ideas
        <div className={styles.panelIcons}>
          {isItSmallDevice() && (
            <Icon
              {...mdSize}
              icon="icn-table-down-chevron"
              className={
                isIdeasOpened ? styles.rotatedDownChevron : styles.downChevron
              }
              onClick={() => {
                setIsIdeasOpenned(!isIdeasOpened);
              }}
            />
          )}
          {isItStandardViewver(session, currentThemeData) && (
            <Icon
              {...mdSize}
              icon="icn-add"
              className={styles.addNew}
              onClick={handleAddIdeasClick}
            />
          )}
        </div>
      </div>
      {isIdeasOpened && (
        <div>
          {loadingIdeas ? (
            <Loading />
          ) : (
            <div className={styles.wrapper}>
              {themeIdeas?.length > 0 ? (
                chunkToShow?.map((item) => (
                  <div
                    className={classNames(styles.Row)}
                    key={`${item.idea_id}`}
                  >
                    <div
                      onClick={() => handleIdeaRedirect(item.idea_id)}
                      className={styles.title}
                    >
                      <span> {item.name} </span>
                      <span className={styles.deleteIcon}>
                        {isItStandardViewver(session, currentThemeData) && (
                          <Icon
                            {...smSize}
                            icon="icn-button-delete"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              return handleDeleteIdeasClick(item.idea_id);
                            }}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <span className="p-3 text-center">No ideas yet!</span>
              )}
              {seeAll && (
                <div className={styles.paginationCon}>
                  <Pagination {...pageSetting} onChange={handlePagingChange} />
                </div>
              )}
              {themeIdeas?.length > 6 && (
                <div
                  onClick={() => setSeeAll(!seeAll)}
                  className={styles.showAll}
                >
                  {seeAll ? "HIDE" : "SEE ALL"}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Pannel>
  );
};

export default React.memo(RelatedIdeasComponent);

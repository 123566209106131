import React, { useMemo } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import styles from '../styles.module.scss';
import LoadingComponent from "modules/primitives/loading/loading.component";
import PocTableDataCardRow from "modules/reporting/fordReports/actualScorecard/sections/pocTableCardRow";

const PocTableDataCard = () => {
  const {
    fordDashboardReducer: { actualScoreCardReducer }
  } = useSelector((state) => state);

  const data = useMemo(() => actualScoreCardReducer.pocData.data, [actualScoreCardReducer.pocData.data]);
  const fetching = useMemo(() => actualScoreCardReducer.pocData.fetching, [actualScoreCardReducer.pocData.fetching]);

  if (fetching || !data) {
    return <LoadingComponent customText="Preparing chart..." />
  }

  return (
    <div className={classNames(styles.columnCard, styles.pocTableWrapper)}>
      <div className="d-flex flex-column">
        <div className="d-flex py-2 border-bottom">
          <div className="w-75 pl-4">Pillar</div>
          <div className="w-25 text-right">Projects</div>
        </div>
        <div>
          {
            data?.map(row => (
              <PocTableDataCardRow key={row.theme} rowData={row} />
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default React.memo(PocTableDataCard);

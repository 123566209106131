import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Field, Formik } from "formik";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { withSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import { setUser } from "modules/auth/auth.action";
import DropDownNestedElements from "common/components/dropdownNestedElements";
import { themesSearchAttempt } from "modules/themes/themesMainPage/themesMain.action";
import ErrorBoundary from "common/components/errorBoundary";
import {
  BtnType,
  Button,
  DropSelect,
  Label,
  TextBox,
  UploadImgComponent,
  Loading,
} from "../../primitives";
import styles from "./generalSettings.module.scss";
import {
  initGeneralSettingsModel,
  generalSettingsValidation,
  generalSettingsTimeZones,
} from "./generalSettings.constants";
import { generalSettingsGet, settingsEditAttempt } from "../settings.action";
import {
  companyGetFilters,
  companyGetTopics,
} from "../../companies/companyMain/companyMain.action";

const GeneralSettings = ({ enqueueSnackbar }) => {
  const [model, setModel] = useState(initGeneralSettingsModel);
  const formRef = useRef();
  const dispatch = useDispatch();

  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [firstLogin, setFirstLogin] = useState(false);
  const [showComponent, setShowComponent] = useState(true);
  const [elasticQuery, setElasticQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(false);
  const [themesOptions, setThemesOptions] = useState([]);

  const {
    authReducer: { loginStatus, session },
    adminPlantsAndAreasReducer: { plantsAndAreas },
    settingsReducer: { generalSettings },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: { industries, topics },
    },
    themesReducer: {
      themesMainReducer: { themesData, loadingThemes },
    },
  } = useSelector((state) => state);
  const history = useHistory();

  useEffect(() => {
    if (themesData && themesData.themes) {
      setThemesOptions(themesData.themes);
    }
  }, [themesData]);

  useEffect(() => {
    if (selectedCouncil && selectedCouncil.traction_tag === "ford") {
      const data = {
        sort_order: "asc",
        sort_attribute: "name",
        search: {
          query: elasticQuery,
        },
      };
      dispatch(themesSearchAttempt({ page: 1, data, fromMain: true }));
    }
  }, [selectedCouncil, debouncedValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(elasticQuery);
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [elasticQuery]);

  useEffect(() => {
    if (loginStatus === 1 && !generalSettings) {
      dispatch(generalSettingsGet({ enqueueSnackbar }));
    }
    return () => null;
  }, [loginStatus]);

  useEffect(() => {
    if (session && selectedCouncil && loginStatus === 1) {
      dispatch(
        companyGetFilters({
          userId: session.id,
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );

      dispatch(
        companyGetTopics({
          userId: session.id,
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [session, selectedCouncil, loginStatus]);

  useEffect(() => {
    if (generalSettings && session) {
      const newModel = {
        ...model,
        ...generalSettings,
        email: session.uid,
        selectedIndustries: generalSettings.industries,
        // technologies: generalSettings.resource_topics
      };

      setModel(newModel);
    }

    if (session && !session.password_updated_at && !session?.isSSOLogin) {
      setFirstLogin(true);
    }

    return () => null;
  }, [generalSettings, session]);

  const handleTagRemove = (updatedArr, childKey) => {
    if (childKey.includes("topic")) {
      setSelectedTopics(updatedArr.map((el) => el.id));
    }
    if (childKey.includes("industri")) {
      setSelectedIndustries(updatedArr.map((el) => el.id));
    }
  };

  const handleTagSelect = (topicsId) => {
    setSelectedTopics([...selectedTopics, topicsId]);
  };

  const handleIndustrySelect = (val) => {
    setSelectedIndustries((prev) => [...prev, val]);
  };

  const handleOnSubmit = (values) => {
    const submitIsDisabled = checkSubmitBtnIsDisabled(values);

    if (submitIsDisabled) {
      enqueueSnackbar("Please select areas for selected plants.", {
        variant: "error",
      });
      return;
    }

    setShowComponent(false);
    const { id, ...settings } = values;
    console.log("---------settings", settings);
    const modifiedSettings = {
      ...settings,
      topics: [...new Set(selectedTopics)],
      industries: [...new Set(selectedIndustries)],
      plants: values?.plants || [],
      theme_ids:
        selectedCouncil.traction_tag === "ford"
          ? [...settings?.themes?.map((el) => el.id)] || null
          : undefined,
    };
    dispatch(
      settingsEditAttempt({
        settings: modifiedSettings,
        enqueueSnackbar,
        session,
      })
    );

    const timeout = setTimeout(() => {
      setShowComponent(true);
      clearTimeout(timeout);
    });

    const isSSO = localStorage.getItem("workOs");

    if (isSSO) {
      dispatch(
        setUser({ ...session, ...generalSettings, password_updated_at: true })
      );

      history.push("/");
      return;
    }

    if (firstLogin) {
      localStorage.setItem("is_firt_step_passsed", true);
      history.push("/setup/password");
    }
  };

  const onImgUpdate = (avatar) => {
    setModel({
      ...model,
      avatar,
    });
  };

  useEffect(() => {
    setSelectedIndustries(model?.industries.map((i) => i.id));
    setSelectedTopics(model?.topics.map((t) => t.id));
  }, [model]);

  const handleResetClick = (handleReset) => {
    handleReset();
    history.push(session.council_role === "company" ? "/overview" : "/home");
  };
  useEffect(() => {
    if (selectedCouncil?.name === "Ford") {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil]);

  const makePlants = useMemo(() => {
    return (
      plantsAndAreas?.map((item) => ({
        id: item.id,
        name: item.attributes.name,
        areas: item.attributes.areas?.map((a) => ({ ...a, plant_id: item.id })),
      })) || []
    );
  }, [plantsAndAreas]);

  const handleAreasSelect = useCallback(
    (plants, plant, areas, setFieldValues) => {
      const newPlants = plants.map((p) => {
        if (p.id === plant.id) {
          return {
            ...plant,
            selected_areas: areas,
          };
        }

        return p;
      });

      setFieldValues("plants", newPlants);
    },
    []
  );

  const makePlantsAsValue = useCallback(
    (plants) => {
      if (!plants?.length) return [];

      return plants.map((p) => {
        const getPlantById = makePlants.find((plant) => plant.id === p.id);

        if (getPlantById) {
          return {
            ...p,
            areas: getPlantById.areas || [],
          };
        }

        return p;
      });
    },
    [makePlants]
  );

  const checkSubmitBtnIsDisabled = useCallback(
    (values) => {
      if (selectedCouncil?.traction_tag !== "ford" || !values) return false;

      if (!values?.plants || values?.plants?.length === 0) return false;

      return values?.plants?.some(
        (p) => p.areas?.length > 0 && p.selected_areas?.length === 0
      );
    },
    [formRef, selectedCouncil]
  );

  const makePlantAreas = useCallback(
    (plant) => {
      if (plant.areas) return plant.areas;
      const getPlant = makePlants.find((p) => p.id === plant.id);
      return getPlant?.areas || [];
    },
    [makePlants]
  );

  return (
    <div className={styles.pageWrapper}>
      <ErrorBoundary component="Settings">
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={model}
          validationSchema={generalSettingsValidation}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleOnSubmit(values);
          }}
        >
          {({ handleSubmit, handleReset, setFieldValue, ...formikprops }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.generalSettingsFormWrp}>
                <UploadImgComponent
                  label="Profile Photo"
                  name="avatar"
                  imgSize={styles.imageSize}
                  imgStyle={styles.imageStyle}
                  formProps={{ setFieldValue, ...formikprops }}
                  uploadDirName={`users/${model.id}/key`}
                  onChange={onImgUpdate}
                />
                <Label>Email</Label>
                <TextBox
                  type="text"
                  name="email"
                  className={styles.textBox}
                  formProps={{ setFieldValue, ...formikprops }}
                  readOnly
                  disabled
                />
                <div className={styles.row}>
                  <div
                    className={classNames(styles.col6, styles.withMarginRight)}
                  >
                    <Label>First Name</Label>
                    <TextBox
                      type="text"
                      name="first_name"
                      placeholder="First name"
                      className={styles.textBox}
                      formProps={{ setFieldValue, ...formikprops }}
                    />
                  </div>
                  <div className={styles.col6}>
                    <Label>Last Name</Label>
                    <TextBox
                      type="text"
                      name="last_name"
                      placeholder="Last name"
                      className={styles.textBox}
                      formProps={{ setFieldValue, ...formikprops }}
                    />
                  </div>
                </div>
                <Label>Title / Role</Label>
                <TextBox
                  type="text"
                  name="title"
                  placeholder="Title"
                  className={styles.textBox}
                  formProps={{ setFieldValue, ...formikprops }}
                />
                <Label>Department</Label>
                <TextBox
                  type="text"
                  name="department"
                  placeholder="Department"
                  className={styles.textBox}
                  formProps={{ setFieldValue, ...formikprops }}
                />
                <Label>Company</Label>
                <TextBox
                  type="text"
                  name="company"
                  placeholder="Company"
                  className={styles.textBox}
                  formProps={{ setFieldValue, ...formikprops }}
                />
                <Label>Location</Label>
                <TextBox
                  type="text"
                  name="location"
                  placeholder="Location"
                  className={styles.textBox}
                  formProps={{ setFieldValue, ...formikprops }}
                />
                <Label>Time Zone</Label>
                <DropSelect
                  placeholder="Select Time Zone"
                  name="time_zone"
                  labelField="text"
                  valueField="utc"
                  searchBy="text"
                  options={generalSettingsTimeZones}
                  formProps={{ setFieldValue, ...formikprops }}
                  onChange={(e) => {
                    setFieldValue("time_zone", e.val);
                  }}
                />
                {selectedCouncil?.name === "Kyndryl" && (
                  <>
                    <Label>Approved Board Member</Label>
                    <Field
                      name="approved_board_member"
                      render={({ field }) => (
                        <div className="d-flex flex-column mb-3">
                          <div className="d-flex align-items-center">
                            <input
                              {...field}
                              id="yes"
                              value={true}
                              checked={field.value}
                              name="type"
                              type="radio"
                              onChange={() =>
                                setFieldValue("approved_board_member", true)
                              }
                            />
                            <label className="pl-2" htmlFor="yes">
                              Yes
                            </label>
                          </div>

                          <div className="d-flex align-items-center">
                            <input
                              {...field}
                              id="no"
                              value={false}
                              name="type"
                              checked={!field.value}
                              type="radio"
                              onChange={() =>
                                setFieldValue("approved_board_member", false)
                              }
                            />
                            <label className="pl-2" htmlFor="no">
                              No
                            </label>
                          </div>
                        </div>
                      )}
                    />
                  </>
                )}
                <Label>Bio</Label>
                <TextBox
                  type="text"
                  name="bio"
                  placeholder="Bio"
                  className={styles.textBox}
                  formProps={{ setFieldValue, ...formikprops }}
                />
                {selectedCouncil?.traction_tag === "ford" ? (
                  <div>
                    {" "}
                    <Label>
                      {` ${
                        selectedCouncil?.settings
                          ?.themes_display_name_singular || "Theme"
                      }`}
                    </Label>
                    <div className={styles.selectorWrp}>
                      <DropSelect
                        onChange={(value) => {
                          setFieldValue("themes", value.obj);
                        }}
                        placeholder={`Add ${
                          selectedCouncil?.settings?.themes_display_name ||
                          "themes"
                        } (type to search or select from below)`}
                        labelField="name"
                        valueField="id"
                        searchBy="name"
                        name="themes"
                        options={themesOptions}
                        searchFn={(args) => {
                          setElasticQuery(args.state.search);
                        }}
                        values={model?.themes ? model?.themes : []}
                        multi
                        clearable
                        clearRenderer={({ props, state, methods }) => {
                          return (
                            <div
                              className="d-flex justify-content-end"
                              style={{
                                position: "absolute",
                                bottom: -18,
                                right: 0,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                methods.clearAll();
                                setFieldValue("theme", []);
                              }}
                            >
                              <span className="text-muted small cursor-pointer">
                                clear
                              </span>
                            </div>
                          );
                        }}
                      />

                      <div className={styles.loaderWrp}>
                        {loadingThemes && <Loading hideString />}
                      </div>
                    </div>
                  </div>
                ) : null}
                {selectedCouncil?.traction_tag === "ford" ? (
                  <>
                    <Label>Plants</Label>
                    <DropSelect
                      className={styles.dropSelectBox}
                      multi
                      placeholder="Select Plants"
                      name="plants"
                      labelField="name"
                      valueField="id"
                      searchBy="name"
                      options={makePlants}
                      values={makePlantsAsValue(formikprops.values?.plants)}
                      onChange={(e) => {
                        setFieldValue("plants", e.obj);
                      }}
                      clearable
                      clearRenderer={({ props, state, methods }) => (
                        <div
                          className="d-flex justify-content-end"
                          style={{
                            position: "absolute",
                            bottom: -18,
                            right: 0,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            methods.clearAll();
                            setFieldValue("plants", []);
                          }}
                        >
                          <span className="text-muted small cursor-pointer">
                            clear
                          </span>
                        </div>
                      )}
                    />
                    {formikprops.values?.plants?.length ? (
                      <div className="d-flex flex-column mb-5">
                        {formikprops.values?.plants.map((plant, index) => {
                          const plantAreas = makePlantAreas(plant);
                          return (
                            <div key={plant.id} className="d-flex flex-column">
                              <span className="font-weight-bold d-block mt-3">
                                {index + 1}. {plant.name}
                              </span>
                              <span className="d-block ml-3">
                                Region: {plant.region_name}
                              </span>
                              {plantAreas?.length ? (
                                <div className="mt-3">
                                  <Label>Areas</Label>
                                  <DropSelect
                                    className={styles.dropSelectBox}
                                    multi
                                    placeholder="Select Areas"
                                    name="selected_areas"
                                    labelField="area_name"
                                    valueField="area_id"
                                    searchBy="area_name"
                                    options={plantAreas}
                                    values={plant.selected_areas || []}
                                    onChange={(e) => {
                                      handleAreasSelect(
                                        formikprops.values.plants,
                                        plant,
                                        e.obj,
                                        setFieldValue
                                      );
                                    }}
                                    clearable
                                    clearRenderer={({
                                      props,
                                      state,
                                      methods,
                                    }) => {
                                      return (
                                        <div
                                          className="d-flex justify-content-end"
                                          style={{
                                            position: "absolute",
                                            bottom: -18,
                                            right: 0,
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            methods.clearAll();
                                            setFieldValue("areas", []);
                                          }}
                                        >
                                          <span className="text-muted small cursor-pointer">
                                            clear
                                          </span>
                                        </div>
                                      );
                                    }}
                                  />
                                  {!plant.selected_areas?.length ? (
                                    <span className="text-danger d-block mt-2">
                                      Please select an Area
                                    </span>
                                  ) : null}
                                </div>
                              ) : (
                                <span className="text-muted d-block mt-3">
                                  This plant doesn't have any areas.
                                </span>
                              )}
                            </div>
                          );
                        })}
                        <hr className="mt-5" />
                      </div>
                    ) : null}
                  </>
                ) : null}

                <h5 className={styles.subTitle}>Areas Of Interest</h5>

                <div className={styles.row}>
                  <div
                    className={classNames(styles.col6, styles.withMarginRight)}
                  >
                    {showComponent && (
                      <>
                        <Label>Technologies</Label>
                        <DropDownNestedElements
                          tags={topics}
                          selectedTags={selectedTopics}
                          childKey="children_topics"
                          tagKeyName="id"
                          parentKeyName="parent_resource_topic_id"
                          handleTagSelect={handleTagSelect}
                          label="Select technologies"
                          handleTagRemove={handleTagRemove}
                        />
                      </>
                    )}
                  </div>

                  <div className={styles.col6}>
                    {showComponent && (
                      <>
                        <Label>Industries</Label>
                        <DropDownNestedElements
                          tags={industries}
                          selectedTags={selectedIndustries}
                          childKey="children_industries"
                          tagKeyName="id"
                          parentKeyName="parent_resource_industry_id"
                          handleTagSelect={handleIndustrySelect}
                          label="Select industries"
                          handleTagRemove={handleTagRemove}
                        />
                      </>
                    )}
                  </div>
                </div>
                <h5 className={classNames(styles.subTitle, "mt-4")}>
                  Optional
                </h5>
                <Label>Mobile Phone Number</Label>
                <TextBox
                  type="text"
                  name="phone_number"
                  placeholder="+1 999 999 999"
                  className={styles.textBox}
                  formProps={{ setFieldValue, ...formikprops }}
                  helperText="Optional: Enter your mobile number to enable SMS notifications"
                />
                <div className={styles.submitWrp}>
                  {!firstLogin && (
                    <Button
                      btn={BtnType.FRAME_LESS}
                      onClick={() => handleResetClick(handleReset)}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button type="submit" btn={BtnType.REGULAR}>
                    {firstLogin ? "Next" : "Save Changes"}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </ErrorBoundary>
    </div>
  );
};

export default React.memo(withSnackbar(GeneralSettings));

export const NOTIFICATIONS_MODEL_INIT = {
  project_notifications: "",
  follow_notifications: "",
  company_spotlight: "",
  group_discussion: "",
  list_notifications: "",
  idea_notifications: "",
  company_notifications: "",
  all: "",
};

export const RADIO_OPTIONS = [
  "", // immediately
  "", // daily
  "", // weekly
  "off/on",
];

export const NOTIFICATIONS_ENUM = {
  project_notifications: "Project Notifications",
  follow_notifications: "Follow Notifications",
  company_spotlight: "Company Spotlight",
  group_discussions: "Public Discussions",
  list_notifications: "List Notifications",
  idea_notifications: "Idea Notifications",
  company_notifications: "Company Notifications",
};

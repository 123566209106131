import React from "react";

import styles from "./companyCard.module.scss";

const CompanyCard = ({
  title, children, className = "", rightContent,
}) => (
  <div className={`${styles.companyCard} ${className}`}>
    {rightContent ? (
      <div className="d-flex align-items-center justify-content-between pr-3">
        {title && <span className={styles.cardTitle}>{title}</span>}
        {rightContent}
      </div>
    ) : (
      title && <span className={styles.cardTitle}>{title}</span>
    )}
    {children}
  </div>
);

export default React.memo(CompanyCard);

import React, { useMemo } from "react";

import styles from "./styles.module.scss";
import { PROJECT_WIZARD_FIELD_TYPES } from "application/app.constants";
import { useSelector } from "react-redux";

const NewProjectStepWrapper = ({ step, formikprops, additionalData, wizardType, wizardData, selectedTemplateId }) => {
  const {
    councilReducer: { selectedCouncil },
    newProjectV3Reducer: { stageTemplates },
  } = useSelector((state) => state);

  const isFordSupplyChainAutomation = useMemo(() => {
    const getStage = stageTemplates.find(s => s.id === selectedTemplateId);

    return (
      selectedCouncil?.name === "Ford" && getStage?.name === 'Supply Chain Automation'
    );
  }, [selectedCouncil?.name, selectedTemplateId, stageTemplates]);

  return (
    <div className={styles.wrapper}>
      {
        step.projects_wizard_options.map((option, index) => (
          <div key={`${option.field_type}_${index}`}>
            {PROJECT_WIZARD_FIELD_TYPES[option.field_type]?.component({
              formikprops,
              fieldDetails: option,
              additionalData,
              wizardType,
              wizardData,
              isFordSupplyChainAutomation,
              selectedTemplateId,
            })}
          </div>
        ))
      }
    </div>
  )
};

export default React.memo(NewProjectStepWrapper);

import {
  PROJECT_GET_SUCCESS,
  PROJECT_SELECTED_SET,
  PROJECT_LIST_GET_SUCCESS,
  PROJECT_ACTIVITIES_GET_ATTEMPT,
  PROJECT_ACTIVITIES_GET_SUCCESS,
  PROJECT_DELETE_SUCCESS,
  PROJECT_DELETE_MEMBER_SUCCESS,
  PROJECT_DELETE_MEMBER_ATTEMPT,
  PROJECT_MAKE_MEMBER_OWNER_ATTEMPT,
  PROJECT_MAKE_MEMBER_OWNER_SUCCESS,
  PROJECT_DELETE_ATTEMPT,
  CLEAR_NEW_PROJECT,
  CREATE_PROJECT_DISCUSSION_SUCCESS,
  CREATE_PROJECT_DISCUSSION,
  CLEAR_SELECTED_PROJECT,
  PROJECT_PUT_SUCCESS,
  RESET_PROJECTS_LOADING,
  CLEAR_PROJECT_LIST,
  ERROR_CATCH,
  PROJECT_LIST_GET_ATTEMPT,
  PROJECT_PUT_ATTEMPT,
  PROJECT_SET_DOWNLOAD_PARAMS,
  PROJECT_RATING_SET_SUCCESS,
  GET_PROJECT_FIELD_GROUPS_SUCCESS,
  UPDATE_PROJECT_FIELD_GROUPS,
  GET_PROJECT_TAB_PLANTS_SUCCESS,
  GET_PROJECT_REPLICATIONS_SUCCESS,
  UPDATE_PROJECT_REPLICATIONS,
  UPDATE_REPLICATION_STATUS,
  GET_PROJECT_REPLICATION_STATUS_SUCCESS,
  PROJECT_SETTINGS_GET_ATTEMPT,
  PROJECT_SETTINGS_GET_SUCCESS,
  PROJECT_SETTINGS_PUT_ATTEMPT,
  PROJECT_SETTINGS_PUT_SUCCESS,
} from "./project.action";
import {
  ADD_RELATED_IDEA_SUCCESS,
  DELETE_RELATED_IDEA_SUCCESS,
} from "./overview/overview.action";
import { apiStatus, pageMeta } from "../../../common/httpCall";
import { PPROJECT_TASK_UPDATE_SUCCESS } from "./tasks/tasks.action";
import { DELETE_RELATED_THEME_SUCCESS } from "./overview/overview.action";
import {
  GET_PLANT_BY_TYPE_SUCCESS,
  RESET_PLANT_BY_TYPE,
  UPDATE_PLANT_SUCCESS,
} from "../../../common/actions/common.action";

const initState = {
  projectList: [],
  projectListMeta: {},
  selectedProject: null,
  activities: [],
  activitiesMeta: {},
  documents: [],
  newProjectObject: null,
  tempMembers: [],
  shouldMembersUpdate: false,
  deletedSuccesfully: false,
  userProjectList: [],
  projectLoading: true,
  activitiesLoading: false,
  shouldUpdateIdeas: false,
  projectDownloadDetails: [],
  projectIsDownloading: false,
  projectPlant: null,
  projectSolutions: [],
  fieldGroups: null,
  plantsTab: {
    isFetching: true,
    data: {},
  },
  overviewPossibleReplications: null,
  replicationData: null,
  settingsLoading: false,
  projectSettings: null,
};

const projectReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_REPLICATION_STATUS: {
      return {
        ...state,
        replicationData: payload,
      };
    }

    case GET_PROJECT_REPLICATION_STATUS_SUCCESS: {
      return {
        ...state,
        replicationData: payload.response,
      };
    }

    case UPDATE_PROJECT_REPLICATIONS: {
      return {
        ...state,
        overviewPossibleReplications: payload,
      };
    }

    case GET_PROJECT_REPLICATIONS_SUCCESS: {
      return {
        ...state,
        overviewPossibleReplications: payload.response,
      };
    }

    case GET_PROJECT_TAB_PLANTS_SUCCESS: {
      return {
        ...state,
        plantsTab: {
          isFetching: false,
          data: payload.response,
        },
      };
    }

    case UPDATE_PROJECT_FIELD_GROUPS: {
      if (!payload.group?.id) {
        return {
          ...state,
          fieldGroups: {
            ...state.fieldGroups,
            project_fields_without_groups:
              state.fieldGroups.project_fields_without_groups.map((field) => {
                const getFieldFromResponse = payload.response.find(
                  (f) =>
                    f.project_field_template_id ===
                      field.project_field_template_id || f.id === field.id
                );

                if (getFieldFromResponse) {
                  return {
                    ...field,
                    ...getFieldFromResponse,
                  };
                }

                return field;
              }),
          },
        };
      }

      return {
        ...state,
        fieldGroups: {
          ...state.fieldGroups,
          project_fields_with_groups:
            state.fieldGroups.project_fields_with_groups.map((group) => {
              if (group.id === payload.group.id) {
                return {
                  ...group,
                  project_fields: group.project_fields.map((field) => {
                    const getFieldFromResponse = payload.response.find(
                      (f) =>
                        f.project_field_template_id ===
                          field.project_field_template_id || f.id === field.id
                    );

                    if (getFieldFromResponse) {
                      return {
                        ...field,
                        ...getFieldFromResponse,
                      };
                    }

                    return field;
                  }),
                };
              }

              return group;
            }),
        },
      };
    }

    case GET_PROJECT_FIELD_GROUPS_SUCCESS: {
      return {
        ...state,
        fieldGroups: payload.response,
      };
    }

    case PROJECT_RATING_SET_SUCCESS: {
      if (payload.response?.data?.attributes?.rating_template) {
        return {
          ...state,
          selectedProject: {
            ...state.selectedProject,
            rating_template: {
              ...state.selectedProject.rating_template,
              ...payload.response.data.attributes.rating_template,
            },
            rating_id:
              payload.response.data.attributes.rating_id ||
              state.selectedProject.rating_id,
          },
        };
      }

      return state;
    }

    case RESET_PLANT_BY_TYPE: {
      if (payload.type === "Project") {
        return {
          ...state,
          projectPlant: null,
        };
      }

      return state;
    }

    case GET_PLANT_BY_TYPE_SUCCESS: {
      if (payload.response && payload.type === "Project") {
        const { areas, plant, status } = payload.response;

        return {
          ...state,
          projectPlant: {
            areas,
            id: plant.id,
            name: plant.attributes.name,
            status,
          },
        };
      }

      return state;
    }

    case UPDATE_PLANT_SUCCESS: {
      if (payload.response && payload.type === "Project") {
        const { areas, plant, status } = payload.response;
        return {
          ...state,
          projectPlant: {
            areas,
            id: plant.id,
            name: plant.attributes.name,
            status,
          },
        };
      }

      return state;
    }

    case PROJECT_SET_DOWNLOAD_PARAMS: {
      return {
        ...state,
        projectDownloadDetails: payload,
        projectIsDownloading: !!payload.length,
      };
    }

    case PPROJECT_TASK_UPDATE_SUCCESS: {
      if (state.selectedProject) {
        return {
          ...state,
          selectedProject: {
            ...state.selectedProject,
            status: payload?.response?.due_date_status,
          },
        };
      }

      return state;
    }
    case PROJECT_GET_SUCCESS:
    case PROJECT_SELECTED_SET:
      return {
        ...state,
        selectedProject: payload,
      };
    case CLEAR_SELECTED_PROJECT: {
      return {
        ...state,
        selectedProject: null,
      };
    }
    case PROJECT_LIST_GET_ATTEMPT: {
      return {
        ...state,
        projectLoading: true,
      };
    }
    // TODO to be removed. Everything that is related to project list, shouldn't stay in that reducer
    case PROJECT_LIST_GET_SUCCESS: {
      const pagemeta = pageMeta(payload);
      const { status, response } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          projectList: response,
          pageMeta,
          projectListMeta: pagemeta,
          projectLoading: false,
        };
      }

      return state;
    }

    case PROJECT_ACTIVITIES_GET_ATTEMPT:
      const { currentPage } = payload;
      let obj = {};
      if (currentPage === 1) {
        obj = { activities: [] };
      }
      return {
        ...state,
        ...obj,
        activitiesLoading: true,
      };

    case PROJECT_ACTIVITIES_GET_SUCCESS: {
      const pagemeta = pageMeta(payload);
      const { status, response } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          activities: [...state.activities, ...response],
          activitiesMeta: pagemeta,
        };
      }
      break;
    }

    case PROJECT_DELETE_ATTEMPT: {
      return {
        ...state,
        deletedSuccesfully: false,
      };
    }
    case PROJECT_DELETE_SUCCESS: {
      const { id } = payload;
      return {
        ...state,
        projectList: state.projectList?.filter((project) => project.id !== id),
        deletedSuccesfully: true,
      };
    }

    case CREATE_PROJECT_DISCUSSION: {
      return {
        ...state,
      };
    }

    case CREATE_PROJECT_DISCUSSION_SUCCESS:
      {
        const { status } = payload;
        if (status === apiStatus.CREATED) {
          return {
            ...state,
          };
        }
      }
      break;

    case CLEAR_NEW_PROJECT: {
      return {
        ...state,
        newProjectObject: null,
      };
    }

    case PROJECT_DELETE_MEMBER_ATTEMPT: {
      return {
        ...state,
        shouldMembersUpdate: false,
      };
    }

    case PROJECT_DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        shouldMembersUpdate: true,
      };

    case PROJECT_MAKE_MEMBER_OWNER_ATTEMPT:
      return {
        ...state,
        shouldMembersUpdate: false,
      };

    case PROJECT_MAKE_MEMBER_OWNER_SUCCESS:
      return {
        ...state,
        shouldMembersUpdate: true,
      };

    case RESET_PROJECTS_LOADING:
      return {
        ...state,
        projectLoading: true,
      };

    case CLEAR_PROJECT_LIST: {
      return {
        ...state,
        projectList: [],
      };
    }
    case PROJECT_PUT_ATTEMPT:
      return {
        ...state,
        shouldUpdateIdeas: false,
      };

    case PROJECT_PUT_SUCCESS:
      return {
        ...state,
        projectList: state.projectList?.map((p) => {
          if (p.id === payload.response.id) {
            return {
              ...p,
              ...payload.response,
            };
          }

          return p;
        }),
        selectedProject:
          payload.response.id === state.selectedProject?.id
            ? {
                ...state.selectedProject,
                ...payload.response,
              }
            : state.selectedProject,
        shouldUpdateIdeas: true,
      };
    case ADD_RELATED_IDEA_SUCCESS: {
      return {
        ...state,
        selectedProject: {
          ...state.selectedProject,
          ideas: [
            ...state.selectedProject.ideas,
            {
              ...payload.response[0].idea,
              idea_id: payload.response[0].idea.id,
            },
          ],
        },
      };
    }
    case DELETE_RELATED_IDEA_SUCCESS: {
      return {
        ...state,
        selectedProject: {
          ...state.selectedProject,
          ideas: [
            ...state.selectedProject.ideas.filter(
              (idea) => idea.idea_id !== payload.response.idea.id
            ),
          ],
        },
      };
    }
    case DELETE_RELATED_THEME_SUCCESS: {
      return {
        ...state,
        selectedProject: null,
      };
    }
    case PROJECT_SETTINGS_GET_ATTEMPT: {
      return {
        ...state,
        settingsLoading: true,
        projectSettings: null,
      };
    }
    case PROJECT_SETTINGS_GET_SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        settingsLoading: false,
        projectSettings: response,
      };
    }

    case PROJECT_SETTINGS_PUT_ATTEMPT: {
      return {
        ...state,
        settingsLoading: true,
        projectSettings: null,
      };
    }

    case PROJECT_SETTINGS_PUT_SUCCESS: {
      const { response } = payload;
      return {
        ...state,
        settingsLoading: false,
        projectSettings: response,
      };
    }

    case ERROR_CATCH: {
      return {
        ...state,
        projectLoading: false,
        activitiesLoading: false,
      };
    }
    default:
      return state;
  }
  return state;
};

export default projectReducer;

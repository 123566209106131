import {
  PROJECT_USERS_GET_SUCCESS,
  PROJECT_USERS_ADD_SUCCESS,
  PROJECT_MULTIPLE_USERS_ADD_SUCCESS,
} from "./user.action";
import { apiStatus } from "../../../../common/httpCall";

const initState = {
  projectUsersList: [],
};
const usersReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PROJECT_USERS_GET_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          projectUsersList: response,
        };
      }
      break;
    }
    case PROJECT_USERS_ADD_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.CREATED) {
        return {
          ...state,
          projectUsersList: [...state.projectUsersList, response],
        };
      }
      break;
    }
    case PROJECT_MULTIPLE_USERS_ADD_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.CREATED) {
        return {
          ...state,
          projectUsersList: [...state.projectUsersList, ...response],
        };
      }
      break;
    }
  }
  return state;
};

export default usersReducer;

export const RATINGS_TEMPLATE_POST_ATTEMPT = "RATINGS_TEMPLATE_POST_ATTEMPT";
export const ratingsTemplatePostAttempt = (payload) => ({
  type: RATINGS_TEMPLATE_POST_ATTEMPT,
  payload,
});

export const RATINGS_TEMPLATE_GET_ATTEMPT = "RATINGS_TEMPLATE_GET_ATTEMPT";
export const ratingsTemplateGetAttempt = (payload) => ({
  type: RATINGS_TEMPLATE_GET_ATTEMPT,
  payload,
});

export const RATINGS_TEMPLATE_GET_SUCCESS = "RATINGS_TEMPLATE_GET_SUCCESS";
export const ratingsTemplateGetSuccess = (payload) => ({
  type: RATINGS_TEMPLATE_GET_SUCCESS,
  payload,
});

export const RATINGS_TEMPLATE_DELETE_ATTEMPT = "RATINGS_TEMPLATE_DELETE_ATTEMPT";
export const ratingsTemplateDeleteAttempt = (payload) => ({
  type: RATINGS_TEMPLATE_DELETE_ATTEMPT,
  payload,
});

export const RATINGS_TEMPLATE_DELETE_SUCCESS = "RATINGS_TEMPLATE_DELETE_SUCCESS";
export const ratingsTemplateDeleteSuccess = () => ({
  type: RATINGS_TEMPLATE_DELETE_SUCCESS,
});

export const RATINGS_TEMPLATE_PATCH_ATTEMPT = "RATINGS_TEMPLATE_PATCH_ATTEMPT";
export const ratingsTemplatePatchAttempt = (payload) => ({
  type: RATINGS_TEMPLATE_PATCH_ATTEMPT,
  payload,
});

export const RATINGS_TEMPLATE_REPLACE_PATCH_ATTEMPT = "RATINGS_TEMPLATE_REPLACE_PATCH_ATTEMPT";
export const ratingsTemplateReplacePatchAttempt = (payload) => ({
  type: RATINGS_TEMPLATE_REPLACE_PATCH_ATTEMPT,
  payload,
});

export const RATINGS_TEMPLATE_PATCH_SUCCESS = "RATINGS_TEMPLATE_PATCH_SUCCESS";
export const ratingsTemplatePatchSuccess = () => ({
  type: RATINGS_TEMPLATE_PATCH_SUCCESS,
});

export const RATINGS_TEMPLAE_CATEGORIES_GET_ATTEMPT = "RATINGS_TEMPLAE_CATEGORIES_GET_ATTEMPT";
export const ratingsTemplateCategoriesGetAttempt = (payload) => ({
  type: RATINGS_TEMPLAE_CATEGORIES_GET_ATTEMPT,
  id: payload.id,
  enqueueSnackbar: payload.enqueueSnackbar,
});

export const RATINGS_TEMPLAE_CATEGORIES_GET_SUCCESS = "RATINGS_TEMPLAE_CATEGORIES_GET_SUCCESS";
export const ratingsTemplateCategoriesGetSuccess = (payload) => ({
  type: RATINGS_TEMPLAE_CATEGORIES_GET_SUCCESS,
  payload,
});

export const RATINGS_TEMPLAE_CATEGORIES_POST_ATTEMPT = "RATINGS_TEMPLAE_CATEGORIES_POST_ATTEMPT";
export const ratingsTemplateCategoriesPostAttempt = (payload) => ({
  type: RATINGS_TEMPLAE_CATEGORIES_POST_ATTEMPT,
  payload,
});

export const RATINGS_TEMPLAE_CATEGORIES_POST_SUCCESS = "RATINGS_TEMPLAE_CATEGORIES_POST_SUCCESS";
export const ratingsTemplateCategoriesPostSuccess = (payload) => ({
  type: RATINGS_TEMPLAE_CATEGORIES_POST_SUCCESS,
  payload,
});

export const RATINGS_TEMPLAE_CATEGORIES_PATCH_ATTEMPT = "RATINGS_TEMPLAE_CATEGORIES_PATCH_ATTEMPT";
export const ratingsTemplateCategoriesPatchAttempt = (payload) => ({
  type: RATINGS_TEMPLAE_CATEGORIES_PATCH_ATTEMPT,
  payload,
});

export const RATINGS_TEMPLAE_CATEGORIES_PATCH_SUCCESS = "RATINGS_TEMPLAE_CATEGORIES_PATCH_SUCCESS";
export const ratingsTemplateCategoriesPatchSuccess = (payload) => ({
  type: RATINGS_TEMPLAE_CATEGORIES_PATCH_SUCCESS,
  payload,
});

export const RATINGS_TEMPLAE_CATEGORIES_DELETE_ATTEMPT = "RATINGS_TEMPLAE_CATEGORIES_DELETE_ATTEMPT";
export const ratingsTemplateCategoriesDeleteAttempt = (payload) => ({
  type: RATINGS_TEMPLAE_CATEGORIES_DELETE_ATTEMPT,
  payload,
});

export const RATINGS_TEMPLAE_CATEGORIES_DELETE_SUCCESS = "RATINGS_TEMPLAE_CATEGORIES_DELETE_SUCCESS";
export const ratingsTemplateCategoriesDeleteSuccess = (payload) => ({
  type: RATINGS_TEMPLAE_CATEGORIES_DELETE_SUCCESS,
  payload,
});

export const RATINGS_TEMPLAE_SINGLE_GET_ATTEMPT = "RATINGS_TEMPLAE_SINGLE_GET_ATTEMPT";
export const ratingsTemplateSingleGetAttempt = (payload) => ({
  type: RATINGS_TEMPLAE_SINGLE_GET_ATTEMPT,
  payload,
});

export const RATINGS_TEMPLAE_SINGLE_GET_SUCCESS = "RATINGS_TEMPLAE_SINGLE_GET_SUCCESS";
export const ratingsTemplateSingleGetSuccess = (payload) => ({
  type: RATINGS_TEMPLAE_SINGLE_GET_SUCCESS,
  payload,
});

export const CATCH_ERROR_SUCCESS = "CATCH_ERROR_SUCCESS";
export const catchErrorSuccess = (payload) => ({
  type: CATCH_ERROR_SUCCESS,
  payload,
});

import React, { useEffect, useMemo, useRef } from "react";
import Typical from 'react-typical';

import { useAnimation } from "modules/tractionAI/components/typingList/animationContext";

const animationSpeed = 5;

const TypingList = ({ chunk, doNotAnimate, onAnimationComplete, fetched }) => {
  const { currentSectionIndex, setCurrentSectionIndex, currentItemIndex, setCurrentItemIndex } = useAnimation();

  const sections = useMemo(() => Object.keys(chunk), [chunk]);
  const sectionRefs = useRef([]);
  const itemRefs = useRef({});

  useEffect(() => {
    if (sections.length > 0 && currentSectionIndex < 0) {
      const timer = setTimeout(() => {
        setCurrentSectionIndex(0);
        setCurrentItemIndex(0);
      }, sections[0].length * animationSpeed + 500);
      return () => clearTimeout(timer);
    }
  }, [sections]);

  useEffect(() => {
    if (currentSectionIndex < 0 || currentSectionIndex >= sections.length) {
      return;
    }

    const currentSectionItems = chunk[sections[currentSectionIndex]];

    if (currentItemIndex < currentSectionItems.length) {
      const timer = setTimeout(() => {
        setCurrentItemIndex((prevIndex) => prevIndex + 1);
      }, currentSectionItems[currentItemIndex]?.length * animationSpeed + 500);
      return () => clearTimeout(timer);
    } else if (currentSectionIndex < sections.length - 1) {
      const nextSection = sections[currentSectionIndex + 1];
      const timer = setTimeout(() => {
        setCurrentItemIndex(0);
        setCurrentSectionIndex((prevIndex) => prevIndex + 1);
      }, nextSection.length * animationSpeed + 500);
      return () => clearTimeout(timer);
    } else if (currentSectionIndex === sections.length - 1 && currentItemIndex === currentSectionItems.length) {
      onAnimationComplete();
    }
  }, [currentItemIndex, currentSectionIndex, sections, chunk, onAnimationComplete]);

  useEffect(() => {
    const sectionRef = sectionRefs.current[currentSectionIndex];
    const itemRef = itemRefs.current[`${currentSectionIndex}-${currentItemIndex}`];

    if (itemRef) {
      itemRef.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    } else if (sectionRef) {
      sectionRef.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [currentSectionIndex, currentItemIndex]);

  if (doNotAnimate) {
    return (
      <>
        {Object.keys(chunk).map((key, secIndex) => (
          <div key={key}>
            <h5>{key}</h5>
            <ul className="ml-4">
              {chunk[key].map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </>
    )
  }

  return (
    <div>
      {sections.map((section, secIndex) => (
        <div key={secIndex} ref={el => sectionRefs.current[secIndex] = el}>
          {currentSectionIndex >= secIndex && (
            <>
              {currentSectionIndex === secIndex ? (
                <Typical
                  steps={[section]}
                  loop={1}
                  wrapper="h5"
                  className={fetched || currentItemIndex < chunk[section].length || currentItemIndex !== secIndex ? "no-cursor" : ""}
                />
              ) : (
                <h5>{section}</h5>
              )}
              <ul className="ml-4">
                {chunk[section].map((item, itemIndex) => (
                  (currentSectionIndex > secIndex || (currentSectionIndex === secIndex && currentItemIndex >= itemIndex)) ? (
                    <li key={itemIndex} ref={el => {
                      if (!itemRefs.current[`${secIndex}-${itemIndex}`]) {
                        itemRefs.current[`${secIndex}-${itemIndex}`] = el;
                      }
                    }}>
                      {currentSectionIndex === secIndex && currentItemIndex === itemIndex ? (
                        <Typical
                          steps={[item]}
                          loop={1}
                          wrapper="span"
                          className={(currentItemIndex > itemIndex || currentSectionIndex > secIndex) ? "no-cursor" : ""}
                        />
                      ) : (
                        <span>{item}</span>
                      )}
                    </li>
                  ) : null
                ))}
              </ul>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default React.memo(TypingList);

import {
  PEOPLE_GET_MEMBERS_SUCCESS,
  PEOPLE_GET_MEMBERS_ATTEMPT,
  PEOPLE_EDIT_MEMBERS_SUCCESS,
  RESET_PEOPLE_LOADING,
} from "./internalPeople.action";
import { apiStatus } from "../../../common/httpCall";
import { PEOPLE_LIST_TEAMS_SUCCESS } from "../../../common/actions/common.action";
import { PRIVATE_DISCUSSION_SAVE_SUCCESS } from "../../../common/actions/discussions.action";

const initState = {
  teamList: [],
  selectTeamsTeamMembers: [],
  pageMeta: {},
  selectedMember: null,
  invitingMember: false,
  refreshTeams: false,
  peopleLoading: true,
  shouldRedirectToMain: false,
};

const updateMembers = (selectTeamsTeamMembers, response) => {
  if (!selectTeamsTeamMembers || !selectTeamsTeamMembers?.length) {
    return selectTeamsTeamMembers;
  }

  return selectTeamsTeamMembers.map((u) => {
    if (u.id === response.discussion_resource_id) {
      return {
        ...u,
        discussion: response,
      };
    }

    return u;
  });
};

const internalPeopleReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    // TODO do we need that? because selectTeamsTeamMembers is not used
    case PRIVATE_DISCUSSION_SAVE_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.CREATED) {
          return {
            ...state,
            selectTeamsTeamMembers: updateMembers(
              state.selectTeamsTeamMembers,
              response
            ),
            selectedMember: state.selectedMember
              ? {
                  ...state.selectedMember,
                  discussion: response,
                }
              : null,
          };
        }
      }
      break;

    case PEOPLE_LIST_TEAMS_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            shouldRedirectToMain: false,
            teamList: response,
          };
        }
      }
      break;

    case PEOPLE_EDIT_MEMBERS_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          selectedMember: response,
          shouldRedirectToMain: true,
        };
      }
      break;
    }

    case PEOPLE_GET_MEMBERS_ATTEMPT: {
      return {
        ...state,
        peopleLoading: true,
      };
    }

    case PEOPLE_GET_MEMBERS_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            selectedMember: response,
            peopleLoading: false,
          };
        }
      }
      break;

    case RESET_PEOPLE_LOADING: {
      return {
        ...state,
        peopleLoading: true,
      };
    }

    default:
      return {
        ...state,
      };
  }

  return state;
};

export default internalPeopleReducer;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Label, Loading, MultiSelect } from "modules/primitives";
import { themesSearchAttempt } from "modules/themes/themesMainPage/themesMain.action";

const ProjectThemes = ({ isAdminWizardInstance, themes, formikprops, isFordSupplyChainAutomation, fieldDetails }) => {
  const dispatch = useDispatch();

  const {
    themesReducer: {
      themesMainReducer: { themesData, loadingThemes },
    },
  } = useSelector((state) => state);
  const [options, setOptions] = useState([]);
  const [elasticQuery, setElasticQuery] = useState(null);

  useEffect(() => {
    if (isAdminWizardInstance) return;
    if (themesData?.themes) {
      if (themesData?.themes?.length > 0) {
        const filteredThemes = themesData.themes.filter((option) => {
          const isAlreadySelected = themes?.find((item) => {
            return item?.theme_id === option.id;
          });
          return !isAlreadySelected;
        });
        setOptions(filteredThemes);
      }
    }
  }, [themesData, themes]);

  const getNewData = useCallback((query) => {
    const data = {
      sort_order: "asc",
      sort_attribute: "name",
      search: {
        query: query,
      },
    };

    dispatch(themesSearchAttempt({ page: 1, data, fromMain: true }));
  }, [themesSearchAttempt]);

  useEffect(() => {
    if (isAdminWizardInstance) return;
    getNewData('');
  }, []);

  useEffect(() => {
    if (elasticQuery === null) return;

    const timer = setTimeout(() => {
      getNewData(elasticQuery);
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [elasticQuery]);

  const handleSelect = (evt) => {
    formikprops.setFieldValue('project_theme', evt);
  }

  const label = useMemo(() => {
    return isFordSupplyChainAutomation ? 'Innovation Pillar' : fieldDetails?.field_label ||  'Project Theme';
  }, [isFordSupplyChainAutomation, fieldDetails?.field_label])

  return (
    <>
      <Label>{label}</Label>
      <div className="d-flex">
        <MultiSelect
          onChange={handleSelect}
          placeholder={label}
          labelField="name"
          valueField="id"
          searchBy="name"
          options={options}
          searchFn={(args) => {
            if (elasticQuery === null && !args.state.search) return;
            setElasticQuery(args.state.search);
          }}
          values={formikprops.values?.project_theme || []}
          disabled={loadingThemes || isAdminWizardInstance}
        />

        {loadingThemes && (
          <div className="ml-3">
            <Loading hideString />
          </div>
        )}
      </div>
      {themes?.length ? (
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "-16px" }}
        >
          <span
            className="text-muted small cursor-pointer"
            onClick={() => handleSelect([])}
          >
            clear
          </span>
        </div>
      ) : null}
      <div className="caption small text-danger">
        {formikprops.touched['project_theme'] && formikprops.errors['project_theme']
          ? formikprops.errors['project_theme']
          : ""}
      </div>
    </>
  );
};

export default React.memo(ProjectThemes);

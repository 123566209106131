import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { DropSelect, Label } from "modules/primitives";

const NewProjectEvaluation = ({
  formikprops,
  isDisabled,
  hideGskFromOptions,
  isPatientOrganization,
  mode,
  handleStageSelect,
  wizardType,
}) => {
  const history = useHistory();

  const {
    councilReducer: { selectedCouncil },
    newProjectV3Reducer: { stageTemplates },
  } = useSelector((state) => state);

  const isCompany = history.location.pathname.indexOf("companies") !== -1;
  const isPatientOrg =
    history.location.pathname.indexOf("organizations") !== -1;

  const evaluationTemplateOptions = useMemo(() => {
    if (wizardType === 'replicate') {
      return stageTemplates.filter(template =>
        template.name === 'Europe MFG Innovation' || template.name === 'US MFG Innovation'
      )
    }

    return (
      stageTemplates?.filter((template) => {
        if (
          selectedCouncil.name === "GSK" &&
          history.location?.state?.from &&
          (history.location?.state?.from === "/projects" ||
            history.location?.state?.from === "/home")
        ) {
          return template;
        }
        if (selectedCouncil?.name === "GSK" && !isPatientOrg && isCompany) {
          return !template.patient_organization;
        }
        if (mode === "modal" && hideGskFromOptions) {
          return !template.patient_organization;
        }
        if (mode === "modal" && isPatientOrganization) {
          return template.patient_organization;
        }
        return template;
      }) || []
    );
  }, [
    stageTemplates,
    selectedCouncil,
    history.location?.state?.from,
    isPatientOrganization,
    hideGskFromOptions,
    isPatientOrg,
    isCompany,
    wizardType,
  ]);

  const handleEvaluationSelect = useCallback((val) => {
    const newProject = JSON.parse(localStorage.getItem("new_project"));

    if (handleStageSelect) {
      handleStageSelect(val);
    }

    if (newProject) {
      localStorage.setItem(
        "new_project",
        JSON.stringify({ ...newProject, stage_template_id: val })
      );
    }

    formikprops.setFieldValue("stage_template_id", val.val);
  }, [formikprops]);

  return (
    <>
      <Label>Project Template</Label>
      <DropSelect
        onChange={(val) => {
          handleEvaluationSelect(val);
        }}
        placeholder="Project Template"
        labelField="name"
        valueField="id"
        searchBy="name"
        name="stage_template_id"
        options={evaluationTemplateOptions}
        formProps={formikprops}
        disabled={isDisabled}
      />
      <p className="caption small">
        Select your Team Evaluation Template to set up the stages and tasks for
        your project
      </p>
      {!evaluationTemplateOptions?.length ? (
        <p className="caption small text-danger">
          Please ask an Admin to set a Project template as the default.
        </p>
      ) : null}
      {formikprops?.errors["stage_template_id"] && (
        <p className="caption small text-danger">
          {formikprops?.errors["stage_template_id"]}
        </p>
      )}
    </>
  );
};

export default NewProjectEvaluation;

import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Select from "react-dropdown-select";
import { ModalBody } from "application/modal/index";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import {
  setOpportunityStagePayload,
  setRelationshipStatusPayload,
} from "modules/admin/adminIntegrations/adminIntegrations.actions";
import { Formik, Form, FieldArray } from "formik";
import { BtnType, MultiSelect, Button as Btn } from "../../primitives";
import styles from "./mapFieldValues.module.scss";

const MapFieldValues = (props) => {
  const { opportunityOptions, relationshipStage } = props;
  const {
    preSelectedOpportunityStagePayload,
    preSelectedRelationshipStatusValues,
  } = props;
  const [
    selectedRelationshipStatusValues,
    setSelectedRelationshipStatusValues,
  ] = useState([]);
  const [isSelectedOpportunityStageValue, setIsSelectedOpportunityStageValue] =
    useState(true);
  const [
    isSelectedRelationshipStatusValue,
    setIsSelectedRelationshipStatusValue,
  ] = useState(true);
  const [selectedOpportunityStagePayload, setSelectedOpportunityStagePayload] =
    useState([]);
  const [selectedRelationshipStatusValue, setSelectedRelationshipStatusValue] =
    useState({});
  const [selectedOpportunityStageArray, setSelectedOpportunityStageArray] =
    useState([]);
  const [optionFilterValue, setOptionFilterValue] = useState([]);
  const [isDropOpened, setIsDropOpened] = useState(true);
  const [selected] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const selectRef = useRef("");

  let boundArrayHelpers;

  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  const handleOpportunityStageArray = (e) => {
    setSelectedOpportunityStageArray(e.map((i) => i.text));
  };

  const clearRelationshipValue = () => {
    selectRef.current.state.values = [];
  };

  useEffect(() => {
    if (preSelectedOpportunityStagePayload?.length) {
      setSelectedOpportunityStagePayload(preSelectedOpportunityStagePayload);
      setSelectedRelationshipStatusValues(preSelectedRelationshipStatusValues);
    }
    setLoading(true);
  }, []);

  useEffect(() => {
    dispatch(setOpportunityStagePayload(selectedOpportunityStagePayload));
    dispatch(setRelationshipStatusPayload(selectedRelationshipStatusValues));
  }, [selectedOpportunityStagePayload, selectedRelationshipStatusValues]);

  const initialValues = () =>
    Array(selectedOpportunityStagePayload?.length).fill("");
  return (
    loading && (
      <>
        <ModalBody>
          <div className={styles.popupContainer}>
            <Formik
              enableReinitialize
              initialValues={{ friends: initialValues() }}
              render={({ values }) => (
                <Form>
                  <FieldArray
                    name="friends"
                    render={(arrayHelpers) => {
                      bindArrayHelpers(arrayHelpers);
                      return (
                        <div>
                          <div className={styles.submitContainer}>
                            {!selected && (
                              <div className={styles.submitBtn}>
                                <h3>Opportunity Stage Value Mapping</h3>
                              </div>
                            )}
                          </div>
                          <div className={styles.fieldsConatiner}>
                            <>
                              <div className={styles.headTitle}>
                                <div className={styles.headerContainer}>
                                  <div className={styles.mapsTextHead}>
                                    Salesforce
                                  </div>
                                  <div className={styles.mapsTextHead}>
                                    Traction
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                          <div className={styles.fieldsConatiner}>
                            {values.friends.map((friend, index) => (
                              <div>
                                {values.friends.length && (
                                  <>
                                    <div
                                      className={styles.headTitle}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "1rem",
                                        marginTop: "1rem",
                                      }}
                                    >
                                      <>
                                        <div className={styles.mapsText}>
                                          {selectedOpportunityStagePayload[
                                            index
                                          ]?.join(" , ")}
                                        </div>
                                        <Button className={styles.mapsButton}>
                                          Maps
                                        </Button>
                                        <div className={styles.mapsText}>
                                          {
                                            selectedRelationshipStatusValues[
                                              index
                                            ]?.text
                                          }
                                        </div>
                                        <Chip
                                          className={styles.chipButton}
                                          onDelete={() => {
                                            setSelectedRelationshipStatusValues(
                                              selectedRelationshipStatusValues.filter(
                                                (_, selectedIndex) =>
                                                  selectedIndex !== index,
                                              ),
                                            );
                                            setSelectedOpportunityStagePayload(
                                              selectedOpportunityStagePayload.filter(
                                                (_, selectedIndex) =>
                                                  selectedIndex !== index,
                                              ),
                                            );
                                          }}
                                        />
                                      </>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    }}
                  />
                  <div className={styles.fieldsConatiner}>
                    <div className={styles.headTitle}>
                      <>
                        <div className={`row ${styles.dropDownContainer}`}>
                          {isDropOpened && (
                            <div className="col-5">
                              <MultiSelect
                                disabled={isSelectedRelationshipStatusValue}
                                placeholder="Select OPPORTUNITY STAGE value"
                                labelField="text"
                                valueField="text"
                                searchBy="text"
                                multi
                                onChange={(event) => {
                                  handleOpportunityStageArray(event);
                                  setIsSelectedOpportunityStageValue(false);
                                }}
                                options={opportunityOptions.filter(
                                  (item) =>
                                    !selectedOpportunityStagePayload
                                      ?.map((arr) => arr?.join(" , "))
                                      ?.join(",")
                                      ?.includes(item.text),
                                )}
                              />
                            </div>
                          )}
                          {!isDropOpened && <div className="col-5" />}
                          <div className="col-5">
                            <Select
                              placeholder="Select RELATIONSHIP STATUS value"
                              ref={selectRef}
                              labelField="text"
                              valueField="text"
                              searchBy="text"
                              onDropdownOpen={() => setIsDropOpened(true)}
                              options={relationshipStage.filter(
                                (item) =>
                                  !selectedRelationshipStatusValues
                                    .map((value) => value.text)
                                    .includes(item.text),
                              )}
                              onChange={(event) => {
                                setSelectedRelationshipStatusValue({
                                  text: event[0]?.text,
                                  value: event[0]?.value,
                                });
                                setIsSelectedRelationshipStatusValue(false);
                              }}
                            />
                          </div>
                          <div>
                            <Btn
                              btn={BtnType.REGULAR}
                              className={styles.addButton}
                              disabled={
                                // values.friends.length ===
                                //   relationshipStage.length ||
                                isSelectedOpportunityStageValue ||
                                isSelectedRelationshipStatusValue
                              }
                              onClick={() => {
                                clearRelationshipValue();
                                setSelectedRelationshipStatusValues([
                                  ...selectedRelationshipStatusValues,
                                  selectedRelationshipStatusValue,
                                ]);
                                setIsSelectedOpportunityStageValue(true);
                                setIsSelectedRelationshipStatusValue(true);
                                boundArrayHelpers.insert(
                                  values.friends.length - 1,
                                  "",
                                );
                                setSelectedOpportunityStagePayload([
                                  ...selectedOpportunityStagePayload,
                                  selectedOpportunityStageArray,
                                ]);
                                setIsDropOpened(false);
                                setOptionFilterValue([
                                  ...optionFilterValue,
                                  ...selectedOpportunityStageArray,
                                ]);
                              }}
                            >
                              +Add
                            </Btn>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </ModalBody>
      </>
    )
  );
};

export default React.memo(MapFieldValues);

import React, { useEffect, useState, useMemo } from "react";
import { Formik } from "formik";

import { DatePiker, Loading, Label, TextBox } from "modules/primitives/index";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import Icon from "common/icon/icon.component";
import { mdSize } from "common/icon/icon.constants";
import SimpleDialog from "modules/companies/company/details/confirmationModal/index";
import { addYears, format } from "date-fns";
import DragAndDrop from "common/components/dragAndDrop/index";
import useS3FileUpload from "../../../hooks/s3FileUplodar.hook";
import { model } from "./constant";
import styles from "./styles.module.scss";
import LinearProgressWithLabel from "../../../components/linearProgressWithLabel";
import UniqName from "../uniqName/uniqName";
import AgreementTypes from "./agreementTypes/index";
import { getCustomFieldAgreementType } from "modules/admin/adminCustomFieldsGroups/adminCustomField.action";

const NewAgreementComponent = (props) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany, companyDetails },
    },
    adminCustomFieldsReducer: {
      loadingAgreementType,
      customFieldAgreementTypes,
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: { selectedPatientOrganization },
    },
  } = useSelector((state) => state);
  const {
    formRef,
    handleOnSubmit,
    data,
    agreementIdFromOverview,
    mode,
    uniqName,
    setDataForValidation,
    companyId,
    rfiInstance,
    adminLocation,
    checkboxFromDetails,
  } = props;
  const dispatch = useDispatch();

  const [initModel, setInitModel] = useState(model);
  const [checked, setChecked] = useState("");
  const [uploadValues, setUploadValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const [agreement, setAgreement] = useState([]);
  const { fileUpload, fileDelete } = useS3FileUpload();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleting, setIsDeleting] = useState("");
  const [docDate, setDocDate] = useState(
    data?.document_date || format(new Date(), "yyyy-MM-dd")
  );
  const [expDate, setExpDate] = useState(data?.expiration_date || "");
  const [agreementType, setAgreementType] = useState({});

  const [fileToDelete, setFileToDelete] = useState(0);
  const [title, setTitle] = useState(data?.field_name ? data.field_name : "");
  const [isCustomTitle, setIsCustomTitle] = useState(false);

  useEffect(() => {
    if (data) {
      setAgreement(data?.documents || []);
      if (!agreementIdFromOverview) {
        setChecked(data?.agreement_type_id ? data?.agreement_type_id : "");
      }
    }
  }, [data]);

  useEffect(() => {
    if (agreementIdFromOverview) {
      setChecked(agreementIdFromOverview);
      formRef.current.values.agreement_type_id = agreementIdFromOverview;
    }
  }, [agreementIdFromOverview]);

  useEffect(() => {
    dispatch(getCustomFieldAgreementType({ enqueueSnackbar }));

    if (rfiInstance && selectedCouncil) {
      selectedCouncil.agreement_types.forEach((a) => {
        if (data?.option_selected[0] === a.name) {
          setAgreementType(a);
        }
      });
    }
  }, [selectedCouncil, rfiInstance]);

  const filterSelectedAgreementTypes = () => {
    const selectedAgreementTypesArray = [];
    companyDetails.forEach((companyDetail) => {
      if (companyDetail.field_type === "agreement") {
        customFieldAgreementTypes.forEach((CFAgreementType) => {
          if (CFAgreementType?.id === companyDetail.agreement_type_id) {
            selectedAgreementTypesArray.push(companyDetail.agreement_type_id);
          }
        });
      }
    });
    return selectedAgreementTypesArray;
  };
  const selectedCompanyAgreements = useMemo(
    () => filterSelectedAgreementTypes(),
    [customFieldAgreementTypes]
  );
  useEffect(() => {
    if (data?.field_name?.length) {
      setIsCustomTitle(true);
    }
    if (data) {
      const newModel = {
        field_value: data.field_value,
        title: data.field_name,
        signed_at: data.signed_at,
        expiration_date: data.expiration_date,
        document_date: data.document_date,
        agreement_type_id: data.agreement_type_id || agreementType?.id,
        field_placeholder: data.field_placeholder || "",
      };
      setInitModel(newModel);
    } else if (agreementType?.id?.length) {
      setInitModel({
        agreement_type_id: agreementType?.id,
      });
    }
  }, [data, agreementType]);

  const handleOpenLink = (link) => {
    window.open(link);
  };
  const handleOpenModal = (e, index) => {
    setOpenConfirmModal(true, index);
    e.stopPropagation();
  };

  const handleDismiss = () => {
    setOpenConfirmModal(!openConfirmModal);
  };

  const getFilename = (url) => {
    if (url) {
      return url.split("/").pop();
    }
    return "";
  };

  const uploadHandler = async (e, action) => {
    if (forbiddenFiles.length) {
      setForbiddenFiles([]);
    }
    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }
    setLoading(true);

    const fileList = [];
    const dismissedFiles = 0;

    setUploadValues((items) => {
      const currentFiles = [...items];
      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name,
      }));
      return [...currentFiles, ...newFiles];
    });
    files.forEach(async (file, index, array) => {
      const isLastIndex = Boolean(index === array.length - 1);
      if (file.size <= 20971520) {
        const pathname = `srm/${selectedCouncil.id}/${
          selectedPatientOrganization?.id
            ? "patient_organizations"
            : "companies"
        }/${
          selectedPatientOrganization?.id || selectedCompany.id || companyId
        }/`;
        await fileUpload(file, pathname, setUploadValues, file.name, 0).then(
          (res) => {
            if (res && res.location) {
              isLastIndex && setLoading(false);
              fileList.push({
                added_by_id: session.id,
                name: getFilename(res.location),
                uri: res.location,
                council_id: selectedCouncil.id,
                document_resource_type: rfiInstance
                  ? "CompanyRequestForInformationCustomField"
                  : "CompanyCustomField",
                document_resource_id:
                  props?.data?.company_custom_field_id ||
                  props?.data?.company_custom_field,
                rfi: rfiInstance,
              });
              isLastIndex && setLoading(false);
              if (fileList.length === files.length - dismissedFiles) {
                setAgreement([...agreement, ...fileList]);
              }
            }
          }
        );
        setUploadValues([]);
      } else {
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === file.name
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: file.name,
          };
          return currentProgress;
        });
        isLastIndex && setLoading(false);
        if (file.size > 10000000) {
          isLastIndex && setLoading(false);
          setForbiddenFiles(() => [
            {
              name: file.name,
              error: "Your file is too large. File size limit: 100MB",
            },
          ]);
          enqueueSnackbar(
            "We could not upload your document because your file size is too big. Please make sure the file is less than 20 MB",
            {
              variant: "error",
            }
          );
        }
      }
    });

    e.target.value = "";
  };

  const handleSetDate = (e, setFieldValue, type) => {
    setFieldValue(type, e);
  };

  const handleDeleteDocument = async (e, index) => {
    e.stopPropagation();

    setOpenConfirmModal(false);
    setIsDeleting(true);
    const pathname = `srm/${selectedCouncil.id}/${
      selectedPatientOrganization?.id ? "patient_organizations" : "companies"
    }/${
      selectedPatientOrganization?.id || selectedCompany.id || companyId
    }/documents/${getFilename(agreement[fileToDelete].uri)}`;
    await fileDelete(pathname).then(() => {
      setIsDeleting("");

      if (agreement[fileToDelete].id) {
        setAgreement([
          ...agreement.map((doc, idx) => {
            if (fileToDelete === idx && doc.id) {
              return {
                ...doc,
                _destroy: true,
              };
            }
            return doc;
          }),
        ]);
      } else {
        setAgreement((prev) => [
          ...prev.filter((index) => index !== fileToDelete),
        ]);
      }
    });
  };
  const documentLengthValidate = (value) => {
    let roleCheckValidation;
    if (mode !== "edit") {
      if (!rfiInstance) {
        roleCheckValidation = true;
      }
      if (roleCheckValidation && !value?.length) {
        return true;
      }
    }
    return false;
  };
  const focusExpiryDate = (e, setFieldValue) => {
    if (formRef.current.values.document_date) {
      handleSetDate(
        addYears(new Date(formRef.current.values.document_date), 1),
        setFieldValue,
        "expiration_date"
      );
    }
  };

  const onSubmit = (val) => {
    // if (mode !== "edit") {
    //   setDocumentLengthValidation(documentLengthValidate(checked.name));
    // }

    // if (mode !== "edit") {
    //   return;
    // }

    const payload = {
      ...val,
      field_type: model.type || "agreement",
      document_date: docDate,
      expiration_date: expDate,
      agreement_type_id: val?.agreement_type_id || agreementType?.id,
      documents_attributes: [...agreement],
      agreement_type_selected: checked,
      title,
    };

    handleOnSubmit(payload);
  };

  const handleSelection = (val) => {
    if (checked === val.id) {
      setChecked("");
      // setDocumentLengthValidation(documentLengthValidate(""));
    } else {
      if (!isCustomTitle) {
        setTitle(val.name);
      }
      // setDocumentLengthValidation(documentLengthValidate(val.name));

      setChecked(val.id);
    }
  };

  return (
    <div className={styles.wrapper}>
      <SimpleDialog
        open={openConfirmModal}
        handleDismiss={() => handleDismiss()}
        handleDelete={(e) => handleDeleteDocument(e)}
        openConfirmModal={openConfirmModal}
      />
      <Formik
        innerRef={formRef}
        enableReinitialize
        // validationSchema={rfiInstance ? null : agreementValidation}
        initialValues={initModel}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          onSubmit(values);
          resetForm();
        }}
      >
        {({ handleSubmit, setFieldValue, values, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <Label>Title</Label>
            <input
              type="text"
              name="title"
              placeholder="Edit Agreement Type"
              value={title}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setIsCustomTitle(true);
                } else {
                  setIsCustomTitle(false);
                }
                setTitle(e.target.value);
                setDataForValidation({ title: e.target.value });
              }}
              className={classNames(styles.agreementNameTextBox)}
            />
            <UniqName uniqName={uniqName} />
            {!adminLocation && (
              <div className="d-flex flex-column align-items-start">
                <Label>Instructions (Optional)</Label>
                <TextBox
                  type="text"
                  name="field_placeholder"
                  placeholder="Add instructions"
                  formProps={{ ...formikprops, values, setFieldValue }}
                  className={styles.textBox}
                />
              </div>
            )}
            <>
              <div className={styles.editDefautOptionsContainer}>
                <Label>Document</Label>

                {/* Will be used in future versions */}
                {/* {fromAdmin && (
                  <span
                    className={styles.selectAllButton}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      checked.length ? deselectAll() : selectAll();
                    }}
                  >
                    {" "}
                    {checked.length ? "Deselect All" : "Select All"}
                  </span>
                )} */}
              </div>

              {loadingAgreementType ? (
                <Loading />
              ) : (
                <div className={styles.checkWrapper}>
                  {customFieldAgreementTypes?.map((agreementTypeElement) => {
                    if (
                      mode === "edit" &&
                      data?.agreement_type_id !== agreementTypeElement.id &&
                      agreementIdFromOverview !== agreementTypeElement.id
                    ) {
                      return null;
                    }
                    return (
                      <AgreementTypes
                        initialAgreementType={agreementTypeElement}
                        handleSelection={handleSelection}
                        checked={checked}
                        mode={mode}
                        setFieldValue={setFieldValue}
                        existingRecords={data?.existingRecords}
                        optionsSelected={data?.option_selected}
                        selectedCompanyAgreements={selectedCompanyAgreements}
                        agreementTypeSelected={data?.agreement_type_selected}
                        checkboxFromDetails={checkboxFromDetails}
                        agreementIdFromOverview={agreementIdFromOverview}
                      />
                    );
                  })}
                  {/* {documentLengthValidation ||
                  formikprops?.errors?.agreement_type_id ? (
                    <div className={styles.errorText}>
                      Document is required!
                    </div>
                  ) : null} */}
                </div>
              )}
            </>
            <>
              <Label>Signed On</Label>
              <DatePiker
                name="document_date"
                value={data ? initModel.document_date : docDate}
                formProps={{ ...formikprops, setFieldValue, values }}
                styleProp={styles.calendarStyle}
                className={styles.calendarBox}
                onChange={(e) => {
                  setDocDate(e);
                  setFieldValue("document_date", e);
                }}
                showTime
                withClear
              />
              <Label>Expires On</Label>
              <DatePiker
                name="expiration_date"
                value={data && initModel.expiration_date}
                formProps={{ ...formikprops, setFieldValue, values }}
                styleProp={styles.calendarStyle}
                className={styles.calendarBox}
                onCalendarOpen={(e) => focusExpiryDate(e, setFieldValue)}
                onChange={(e) => {
                  setExpDate(e);
                  setFieldValue("expiration_date", e);
                }}
                showTime
                withClear
              />
              <Label>Upload document</Label>
              <p className={styles.instructionText}>
                Agreement accept all file formats (.pdf, .xls, .pptx, .doc,
                etc.)
              </p>

              {uploadValues?.length
                ? uploadValues.map(
                    (bar, index) =>
                      bar &&
                      bar?.progress !== "NOT_ALLOWED" && (
                        <div className={styles.loaderContainer}>
                          <div className={styles.loaderNamingWrp}>
                            {" "}
                            <div className={styles.fileName}>
                              {uploadValues[index]?.name}
                            </div>
                            <LinearProgressWithLabel
                              mode={
                                uploadValues[index]?.progress === "FAILED" &&
                                "error"
                              }
                              value={
                                uploadValues[index]?.progress === "FAILED"
                                  ? 100
                                  : uploadValues[index]?.progress || 0
                              }
                            />
                          </div>
                        </div>
                      )
                  )
                : null}

              <DragAndDrop
                uploadHandler={uploadHandler}
                loading={loading}
                customText="drag and drop document here"
              />
            </>

            {forbiddenFiles?.length ? (
              <div className={styles.forbiddenfileContainer}>
                Files that weren't allowed:{" "}
                {forbiddenFiles.map((file) => (
                  <span className={styles.forbiddenfileName}>
                    {`${file.name}: `}
                    <span className={styles.errorText}> {file.error}</span>
                  </span>
                ))}
              </div>
            ) : null}
            {isDeleting && <Loading />}
            <div className={styles.inputWrapper}>
              {Array.isArray(agreement)
                ? agreement.map((doc, i) => {
                    if (doc._destroy) return null;
                    return (
                      <div
                        className={styles.item}
                        onClick={() => handleOpenLink(doc.uri)}
                      >
                        <div className={styles.name}> {doc.name}</div>
                        {rfiInstance && doc.created_at ? null : (
                          <div className={styles.delete}>
                            <Icon
                              {...mdSize}
                              icon="icn-button-delete"
                              onClick={(e) => {
                                setFileToDelete(i);
                                handleOpenModal(e, i);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })
                : null}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default React.memo(NewAgreementComponent);

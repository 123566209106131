import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "./companies.module.scss";
import { Button, BtnType, Pannel, CompanyAvatar } from "../../../primitives";

const CompaniesComponent = (props) => {
  const [companyNr, setCompanyNr] = useState(7);
  const [showAll, setShowAll] = useState(false);
  const {
    eventsReducer: {
      viewEventReducer: { selectedEvent },
    },
  } = useSelector((state) => state);
  const history = useHistory();
  if (selectedEvent && selectedEvent.companies?.length === 0) {
    return null;
  }

  const handleSeeAll = () => {
    if (showAll) {
      setCompanyNr(7);
    } else {
      setCompanyNr(selectedEvent.companies?.length);
    }
  };

  const handleCompanyProfileRedirect = (company) => {
    history.push(`/companies/${company.slug || company.id}`);
  };

  return (
    <div className={styles.card}>
      <Pannel title="Featured Companies" padding="0px">
        <div className={styles.wrapper}>
          {selectedEvent.companies
            ?.slice(0, companyNr)
            .map((company, index) => (
              <div
                className={styles.company}
                key={index}
                onClick={() => handleCompanyProfileRedirect(company)}
              >
                <div className={styles.icon}>
                  <CompanyAvatar
                    hasBorderRadius
                    className={styles.icon}
                    imgSrc={company.logo || company.company_logo}
                    name={company.name}
                  />
                </div>
                <div className={styles.name}>{company.name}</div>
              </div>
            ))}
          {selectedEvent.companies?.length >= 7 && (
            <Button
              btn={BtnType.FRAME_LESS}
              className={styles.SeeAllSpeakers}
              onClick={() => {
                handleSeeAll();
                setShowAll(!showAll);
              }}
            >
              {showAll ? "Show Less" : "See all Companies"}
            </Button>
          )}
        </div>
      </Pannel>
    </div>
  );
};
export default React.memo(CompaniesComponent);

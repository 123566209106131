export const COUNCILS_GET_ATTEMPT = "COUNCILS_GET_ATTEMPT";
export const councilsGetAttempt = (payload) => ({
  type: COUNCILS_GET_ATTEMPT,
  payload,
});
export const COUNCILS_GET_SUCCESS = "COUNCILS_GET_SUCCESS";
export const councilsGetSuccess = (payload) => ({
  type: COUNCILS_GET_SUCCESS,
  payload,
});

export const COUNCILS_POST_ATTEMPT = "COUNCILS_POST_ATTEMPT";
export const councilsPostAttempt = (payload) => ({
  type: COUNCILS_POST_ATTEMPT,
  payload,
});
export const COUNCILS_POST_SUCCESS = "COUNCILS_POST_SUCCESS";
export const councilsPostSuccess = (payload) => ({
  type: COUNCILS_POST_SUCCESS,
  payload,
});

export const COUNCILS_DELETE_ATTEMPT = "COUNCILS_DELETE_ATTEMPT";
export const councilsDeleteAttempt = (payload) => ({
  type: COUNCILS_DELETE_ATTEMPT,
  payload,
});
export const COUNCILS_DELETE_SUCCESS = "COUNCILS_DELETE_SUCCESS";
export const councilsDeleteSuccess = (payload) => ({
  type: COUNCILS_DELETE_SUCCESS,
  payload,
});

export const COUNCILS_EDIT_ATTEMPT = "COUNCILS_EDIT_ATTEMPT";
export const councilsEditAttempt = (payload) => ({
  type: COUNCILS_EDIT_ATTEMPT,
  payload,
});
export const COUNCILS_EDIT_SUCCESS = "COUNCILS_EDIT_SUCCESS";
export const councilsEditSuccess = (payload) => ({
  type: COUNCILS_EDIT_SUCCESS,
  payload,
});

export const COUNCILS_GET_INDUSTRIES_ACROSS_ATTEMPT =
  "COUNCILS_GET_INDUSTRIES_ACROSS_ATTEMPT";
export const councilsGetIndustriesAcrossAttempt = (payload) => ({
  type: COUNCILS_GET_INDUSTRIES_ACROSS_ATTEMPT,
  payload,
});
export const COUNCILS_GET_INDUSTRIES_ACROSS_SUCCESS =
  "COUNCILS_GET_INDUSTRIES_ACROSS_SUCCESS";
export const councilsGetIndustriesAcrossSuccess = (payload) => ({
  type: COUNCILS_GET_INDUSTRIES_ACROSS_SUCCESS,
  payload,
});

export const COUNCILS_GET_TECNOLOGIES_ACROSS_ATTEMPT =
  "COUNCILS_GET_TECNOLOGIES_ACROSS_ATTEMPT";
export const councilsGetTecnologiesAcrossAttempt = (payload) => ({
  type: COUNCILS_GET_TECNOLOGIES_ACROSS_ATTEMPT,
  payload,
});
export const COUNCILS_GET_TECNOLOGIES_ACROSS_SUCCESS =
  "COUNCILS_GET_TECNOLOGIES_ACROSS_SUCCESS";
export const councilsGetTecnologiesAcrossSuccess = (payload) => ({
  type: COUNCILS_GET_TECNOLOGIES_ACROSS_SUCCESS,
  payload,
});

export const COUNCILS_GET_BY_ID_ATTEMPT = "COUNCILS_GET_BY_ID_ATTEMPT";
export const councilsGetByIdAttempt = (payload) => ({
  type: COUNCILS_GET_BY_ID_ATTEMPT,
  payload,
});
export const COUNCILS_GET_BY_ID_SUCCESS = "COUNCILS_GET_BY_ID_SUCCESS";
export const councilsGetByIdSuccess = (payload) => ({
  type: COUNCILS_GET_BY_ID_SUCCESS,
  payload,
});
export const RESET_LOADING = "RESET_LOADING";
export const resetLoading = (payload) => ({
  type: RESET_LOADING,
  payload,
});

export const COUNCILS_SPECIFIC_STAGES_GET_BY_ID_ATTEMPT =
  "COUNCILS_SPECIFIC_STAGES_GET_BY_ID_ATTEMPT";
export const councilsRelStageGetByIdAttempt = (payload) => ({
  type: COUNCILS_SPECIFIC_STAGES_GET_BY_ID_ATTEMPT,
  payload,
});
export const COUNCILS_SPECIFIC_STAGES_GET_BY_ID_SUCCESS =
  "COUNCILS_SPECIFIC_STAGES_GET_BY_ID_SUCCESS";
export const councilsRelStageGetByIdSuccess = (payload) => ({
  type: COUNCILS_SPECIFIC_STAGES_GET_BY_ID_SUCCESS,
  payload,
});

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Pannel, Loading } from "../../primitives";
import styles from "./leaderboard.module.scss";
import { queryStringUrlReplacement } from "../../../common/helper";
import { trendingProjectsColumns } from "./leaderboard.constants";

const TopSavingsTableComponent = ({
  getSortDirectionStytles,
  getSortStyles,
  prepareDataName,
  topProjectsByRating,
  trendProjLoading,
}) => {
  const history = useHistory();
  const [topProjects, setProjectTranding] = useState(topProjectsByRating);

  const [highestRatingSort, setHighestRatingSort] = useState({
    sort_attribute: "rating",
    sort_order: "desc",
  });

  useEffect(() => {
    if (topProjectsByRating.length > 0) {
      setProjectTranding(topProjectsByRating);
    }
  }, [topProjectsByRating]);
  const sortRedirect = () => {
    const config = {
      activeColumn: "rating",
      order: "desc",
    };
    let search = queryStringUrlReplacement(
      history.location.search,
      "sortAttribute",
      config.activeColumn
    );
    search = queryStringUrlReplacement(search, "sortOrder", config.order);
    search = queryStringUrlReplacement(search, "page", 1);
    search = queryStringUrlReplacement(search, "mode", "all_projects");

    history.push({
      pathname: "/projects",
      search,
      state: {
        ...history.location.state,
        sortAttribute: config.activeColumn,
        sortOrder: config.order,
        page: 1,
      },
    });
  };

  const setSortAttr = (sortAttr) => {
    const sortingFunction = (techologies) => {
      const newArr = [...techologies];

      const sorted = newArr.sort((a, b) => {
        if (a[sortAttr] === null) {
          return -1;
        }
        if (a[sortAttr] === "") {
          return -1;
        }
        if (a[sortAttr] < b[sortAttr]) {
          return -1;
        } else if (a[sortAttr] > b[sortAttr]) {
          return 1;
        }
        return 0;
      });
      return highestRatingSort.sort_order === "asc" ? sorted : sorted.reverse();
    };

    setHighestRatingSort((prev) => {
      if (prev.sort_order === "desc") {
        return {
          sort_attribute: sortAttr,
          sort_order: "asc",
        };
      } else {
        return {
          sort_attribute: sortAttr,
          sort_order: "desc",
        };
      }
    });
    setProjectTranding(sortingFunction);
  };

  return (
    <div className="col-md-12 col-lg-6">
      <Pannel
        title=""
        padding="0"
        parentStyle={{
          background: "Transparent",
          // marginRight: window.screen.width > 550 ? 0 : 0,
          padding: window.screen.width > 550 ? 7 : 0,
        }}
        position="relative"
        overflow="hidden"
      >
        <div className={styles.wrapper}>
          <div className={`row ${styles.grid}`}>
            <div className={`col-12 ${styles.dataTableContainer}`}>
              <div className={styles.gridHeader}>
                <div className={styles.headerTitle}>
                  {" "}
                  Top 25 Projects by <b>Rating</b>
                </div>
              </div>
              <div className={styles.dataTable}>
                <div
                  className={`${styles.tableHeaderRow} ${styles.techHeader}`}
                >
                  {trendingProjectsColumns.map((header) => {
                    return (
                      <div
                        key={header.columnName}
                        className={` ${
                          styles.dataTableHeader
                        } ${header.columnStyles.map(
                          (customClass) => customClass
                        )} ${getSortStyles(
                          header.columnSortKey,
                          "trendProj",
                          highestRatingSort.sort_attribute
                        )} ${header.sortable && styles.cursorpointer}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (header.sortable) {
                            setSortAttr(header.columnSortKey);
                          }
                        }}
                      >
                        <span
                          className={`${getSortDirectionStytles(
                            header.columnSortKey,
                            "trendProj",
                            highestRatingSort.sort_attribute,
                            highestRatingSort.sort_order
                          )} `}
                        >
                          {header.columnName}{" "}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className={`${styles.projSavingsTableBodyContainer}`}>
                  {topProjects?.map((project, i) => {
                    return (
                      <div
                        className={`${styles.techRow}`}
                        key={project.project_id}
                      >
                        <div className={styles.rowNumber}>
                          {" "}
                          <span>{i + 1}</span>{" "}
                        </div>

                        <div
                          className={`${styles.cellItem} ${styles.techName}`}
                        >
                          {" "}
                          <Link
                            className={styles.url}
                            to={`/projects/${project.project_id}/overview`}
                          >
                            {prepareDataName(project.project_name)}
                          </Link>
                        </div>

                        <div className={`${styles.cellItem}`}>
                          {project.plant_name}
                        </div>
                        <div className={`${styles.cellItem} ${styles.lastCol}`}>
                          {project.project_rating}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {trendProjLoading && <Loading />}
              </div>
            </div>
            <div
              className={`row ${styles.seeMoreLink}`}
              onClick={(e) => {
                e.stopPropagation();
                sortRedirect();
              }}
            >
              SEE MORE
            </div>
          </div>
        </div>
      </Pannel>
    </div>
  );
};

export default React.memo(TopSavingsTableComponent);

import React, { useState } from "react";

import styles from "./followingSearch.module.scss";
import { BtnType, Button } from "../../../primitives";

const FollowingSearch = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleInputOnChange = (evt) => {
    const { value } = evt.target;
    setSearchValue(value);
    if (!value.length) {
      onSearch(null);
    }
  };

  const searchCompanies = (evt) => {
    evt.preventDefault();
    onSearch(searchValue);
  };

  const clickPress = (evt) => {
    if (evt.charCode === 13 || evt.which === 13) {
      onSearch(searchValue);
    }
  };

  return (
    <div className={styles.searchWrp}>
      <div className="d-flex flex-grow-1 align-items-center position-relative">
        <span className={styles.searchIco}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="20"
            viewBox="0 0 19 20"
          >
            <g fill="none" fillRule="evenodd">
              <g fill="#9AA5B1">
                <g>
                  <g>
                    <path
                      d="M13.403 14.5l-1.19-1.19c-1.287 1.047-2.93 1.675-4.72 1.675C3.355 14.985 0 11.63 0 7.493 0 3.355 3.355 0 7.493 0s7.492 3.355 7.492 7.493c0 1.79-.627 3.433-1.675 4.72l1.19 1.19.366-.366L19 17.171 17.171 19l-4.134-4.134.366-.365zm-5.91-1.065c3.281 0 5.942-2.66 5.942-5.942S10.775 1.55 7.492 1.55C4.21 1.55 1.55 4.21 1.55 7.493c0 3.282 2.66 5.942 5.942 5.942z"
                      transform="translate(-408 -265) translate(400 253) translate(8 12.375)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
        <input
          className={styles.searchInput}
          type="text"
          placeholder="Find companies to follow"
          onChange={handleInputOnChange}
          onKeyPress={clickPress}
        />
      </div>
      <Button
        disabled={!searchValue || !searchValue?.length}
        onClick={searchCompanies}
        className={styles.btn}
        btn={BtnType.REGULAR}
      >
        Search
      </Button>
    </div>
  );
};

export default React.memo(FollowingSearch);

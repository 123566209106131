import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { queryStringUrlReplacement } from "../../../../../common/helper";
import { Pagination } from "modules/primitives";
import { layoutType } from "modules/companies/companyMain/companyMain.constant";
import { useCompanyContext } from "modules/companies/companyMain/providers/companyDataProvider";

const CompaniesSearchPagination = () => {
  const {
    companiesReducer: {
      companyMainReducer: {
        companiesPageNr,
        searchResults,
        searchResultNr
      },
    },
  } = useSelector((state) => state);
  const {
    genericResultsNr,
    selectLayout,
    showCrunchBase,
    memoizedCompanies,
    memoizedLoading,
    showProductsCount,
    pageSetting,
    setPageSetting,
    getCompaniesWithParams,
  } = useCompanyContext();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      location.state &&
      location.state.page &&
      location.state.page !== pageSetting.current
    ) {
      setPageSetting({
        ...pageSetting,
        current: Number(location.state.page),
      });
    }

    return () => null;
  }, [pageSetting, location]);

  useEffect(() => {
    if (searchResults || companiesPageNr) {
      const pageSize =
        selectLayout === 1
          ? genericResultsNr > pageSetting.pageSize ||
          companiesPageNr?.total > pageSetting.pageSize
            ? 50
            : 20
          : 20;

      setPageSetting({
        ...pageSetting,
        total: !showProductsCount ? searchResultNr : genericResultsNr,
        pageSize,
      });
    }

    return () => null;
  }, [searchResults, companiesPageNr, selectLayout, genericResultsNr, showProductsCount]);

  const handlePagingChange = useCallback(
    (selectedPage) => {
      if (
        selectedPage === Number(location.state?.page || 1) ||
        showCrunchBase ||
        pageSetting.current === selectedPage
      ) {
        return;
      }

      const search = queryStringUrlReplacement(
        history.location.search,
        "page",
        selectedPage
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          page: selectedPage,
        },
      })

      getCompaniesWithParams(selectedPage);
    },
    [location, showCrunchBase, pageSetting, selectLayout, layoutType]
  );

  const showPagination = useMemo(() => {
    return !memoizedLoading &&
      !showCrunchBase &&
      memoizedCompanies?.length > 0 &&
      (genericResultsNr > pageSetting.pageSize ||
        companiesPageNr?.total > pageSetting.pageSize);
  }, [memoizedLoading, showCrunchBase, memoizedCompanies, genericResultsNr, pageSetting, companiesPageNr]);

  const wrapperClass = useMemo(() => {
    const display = showPagination ? "d-flex" : "d-none";
    return `w-100 ${display} justify-content-center mb-5 ${selectLayout === 1 ? "mt-4" : ""}`
  }, [showPagination, selectLayout]);

  if (selectLayout === 2) return null;

  return (
    <div className={wrapperClass}>
      <Pagination
        {...pageSetting}
        onChange={(p) => handlePagingChange(p)}
      />
    </div>
  );
};

export default memo(CompaniesSearchPagination);

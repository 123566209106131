import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';

const TypingText = ({ text, speed = 40 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [isTypingComplete, setIsTypingComplete] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex < text.length) {
        setDisplayedText((prev) => prev + text[currentIndex]);
        currentIndex += 1;
      } else {
        clearInterval(intervalId);
        setIsTypingComplete(true);
      }
    }, speed);

    return () => clearInterval(intervalId);
  }, [text]);

  return <div className={styles.typingText}>{displayedText}{!isTypingComplete && <span className={styles.cursor}></span>}</div>
};

export default TypingText;

import React from "react";
import { BtnType, Button, CompanyAvatar } from "../../../../../primitives";
import styles from "./newCompanyItemResultHover.module.scss";
import {
  makeWebSite,
  queryParamStringToArray,
  queryStringUrlReplacement,
  useQuery,
} from "../../../../../../common/helper";

const width = window.innerWidth > 1200 ? 800 : 600;

const NewCompanyItemResultHoverComponent = ({
  company,
  history,
  currentYear = new Date().getFullYear(),
}) => {
  const topicQuery = useQuery().get("topic");
  const industryQuery = useQuery().get("industry");

  const onTagClick = (tag) => {
    if (tag.type === "Industry") {
      const arr = industryQuery
        ? [...queryParamStringToArray(industryQuery), tag.industry_id]
        : [tag.industry_id];
      const value = arr.reduce(
        (acc, elem) => `${acc}${acc.length ? "," : ""}${elem}`,
        ""
      );

      let search = queryStringUrlReplacement(
        history.location.search,
        "industry",
        value
      );
      search = queryStringUrlReplacement(search, "page", 1);
      search = queryStringUrlReplacement(search, "query", "");

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          industry: value,
          page: 1,
          query: "",
        },
      });

      return;
    }

    const arr = topicQuery
      ? [...queryParamStringToArray(topicQuery), tag.topic_id]
      : [tag.topic_id];
    const value = arr.reduce(
      (acc, elem) => `${acc}${acc.length ? "," : ""}${elem}`,
      ""
    );

    let search = queryStringUrlReplacement(
      history.location.search,
      "topic",
      value
    );
    search = queryStringUrlReplacement(search, "page", 1);
    search = queryStringUrlReplacement(search, "query", "");

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        topic: value,
        page: 1,
        query: "",
      },
    });
  };

  const gotToProfile = () => {
    if (company.is_product) {
      history.push(
        `/companies/${company.company_slug || company.company.id}/products/${
          company.productSlug || company.productId || company.slug
        }`
      );
      return;
    }

    history.push(
      `/companies/${company?.slug || company?.id || company?.company_id}`
    );
  };

  return (
    <div className={styles.itemHoverWrapper}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className={styles.logoCon}>
            <CompanyAvatar
              className={styles.logo}
              imgSrc={company?.logo || company?.company_logo}
              name={company?.name}
              hasBorderRadius
            />
          </div>
          <div className="d-flex flex-column pl-3">
            <h4 className={styles.name}>{company?.name}</h4>
            {company?.web_url && (
              <a
                className={styles.url}
                href={company?.web_url}
                target="_blank"
                rel="noreferrer"
              >
                {makeWebSite(company?.web_url)}
              </a>
            )}
          </div>
        </div>
        <div>
          <Button
            icon="icn-checkbox-button"
            onClick={gotToProfile}
            btn={BtnType.REGULAR}
            className={styles.addCompanyButton}
          >
            Go To {company.is_product ? "Product" : "Profile"}
          </Button>
        </div>
      </div>
      <div className="d-flex align-items-center mt-3">
        {company?.location ? (
          <span className={styles.customColor}>
            HQ Location:&nbsp;
            <strong>{company?.location}</strong>
          </span>
        ) : null}
        {company?.total_fundings_sum && company?.total_fundings_sum !== 0 && (
          <span
            className={`${company?.location ? "ml-4" : ""} ${
              styles.customColor
            }`}
          >
            Total Funding:{" "}
            <strong>
              {`$${company?.total_fundings_sum?.toLocaleString("en-US")}`}
            </strong>
          </span>
        )}
        {width === 800 && company?.years_in_operation && (
          <span
            className={`${
              company?.total_fundings_sum !== 0 || company?.location
                ? "ml-4"
                : ""
            } ${styles.customColor}`}
          >
            Year Founded:{" "}
            <strong>{currentYear - company?.years_in_operation}</strong>
          </span>
        )}
      </div>
      {width === 600 &&
        company?.years_in_operation !== 0 &&
        company?.years_in_operation && (
          <span className={`${styles.customColor}`}>
            Year Founded:{" "}
            <strong>{currentYear - company?.years_in_operation}</strong>
          </span>
        )}
      {(company?.description || company?.short_description) && (
        <span className={`d-block mt-3 ${styles.customColor}`}>
          {company?.description || company?.short_description}
        </span>
      )}
      {(company?.industries || company?.topics) && (
        <div className="mt-3 d-flex flex-wrap">
          {[
            ...company?.industries?.map((i) => ({ ...i, type: "Industry" })),
            ...company?.topics?.map((i) => ({ ...i, type: "Technology" })),
          ].map((c) => (
            <span
              className={`${styles.tag} ${styles[c.type]}`}
              key={c.type === "Industry" ? c.industry_id : c.topic_id}
              onClick={() => onTagClick(c)}
            >
              {c?.name}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(NewCompanyItemResultHoverComponent);

import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { format } from "date-fns";

import styles from "../details.module.scss";
import { BtnType, Button } from "modules/primitives";
import { Icon, smSize } from "../../../../../common/icon";
import TextTableCustomField from "modules/companies/company/details/tableCustomField/tableCustomFields/tableTextField";
import DropdownTableCustomField from "modules/companies/company/details/tableCustomField/tableCustomFields/tableDropdownField";
import NumberTableCustomField from "modules/companies/company/details/tableCustomField/tableCustomFields/tableNumberField";
import DateTableCustomField from "modules/companies/company/details/tableCustomField/tableCustomFields/tableDateField";
import ConfirmActionComponent from "../../../../../common/components/confirmAction/confirmAction.component";
import { useModelPopup } from "../../../../../common/hooks";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { currencySymbol } from "../../../../../common/components/customFields/number/constant";

const compare = (a, b) => new Date(b.updated_at) - new Date(a.updated_at);

const TableCustomField = ({
  group,
  handleSave,
  handleDeleteRow,
  updateRowCells,
  loadingRowId,
  blockFunctionality,
}) => {
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();

  const [tableRows, setTableRows] = useState([]);
  const [lastUpdatedBy, setLastUpdatedBy] = useState(null);

  useEffect(() => {
    if (group?.tableData?.table_values) {
      setTableRows(group?.tableData?.table_values);

      const sortedFlattenObjs = group?.tableData?.table_values
        .reduce((acc, elem) => [...acc, ...(elem.cells || [])], [])
        .filter((elem) => elem.updated_at?.length)
        .sort(compare);

      if (sortedFlattenObjs?.length) {
        const mostRecentUpdate = sortedFlattenObjs[0];
        setLastUpdatedBy({
          date: format(
            new Date(mostRecentUpdate.updated_at),
            "eeee, MMM do, yyyy h:mm a"
          ),
          by: mostRecentUpdate.user_name,
        });
      }
    }
  }, [group]);

  const updateCellCallback = useCallback(
    (row, newCell, idx) => {
      const newRows = tableRows.map((r) => {
        if (row.id === r.id) {
          const cells = r.cells.map((c, index) => {
            if (idx === index) {
              const isTouchedCell =
                c.field_value !== newCell.field_value ||
                c.option_selected.length !== newCell.option_selected.length;

              return {
                ...c,
                ...newCell,
                isTouched: isTouchedCell,
              };
            }

            return c;
          });
          const isTouched =
            JSON.stringify(cells) !== JSON.stringify(r.originalCells);

          return {
            ...r,
            cells,
            isTouched,
          };
        }

        return r;
      });

      setTableRows(newRows);
    },
    [tableRows]
  );

  const drawCustomField = useCallback(
    (row, cell, idx) => {
      const getMainCustomFieldByPosition = group.tableData.table_headers[idx];

      if (!getMainCustomFieldByPosition) {
        return "";
      }

      switch (getMainCustomFieldByPosition.field_type) {
        case "text": {
          return (
            <TextTableCustomField
              row={row}
              fieldCoreDetails={getMainCustomFieldByPosition}
              field={cell}
              updateCellCallback={(newCell) =>
                updateCellCallback(row, newCell, idx)
              }
            />
          );
        }

        case "dropdown": {
          return (
            <DropdownTableCustomField
              row={row}
              fieldCoreDetails={getMainCustomFieldByPosition}
              field={cell}
              updateCellCallback={(newCell) =>
                updateCellCallback(row, newCell, idx)
              }
            />
          );
        }

        case "number": {
          return (
            <NumberTableCustomField
              row={row}
              fieldCoreDetails={getMainCustomFieldByPosition}
              field={cell}
              updateCellCallback={(newCell) =>
                updateCellCallback(row, newCell, idx)
              }
            />
          );
        }

        case "date": {
          return (
            <DateTableCustomField
              row={row}
              fieldCoreDetails={getMainCustomFieldByPosition}
              field={cell}
              updateCellCallback={(newCell) =>
                updateCellCallback(row, newCell, idx)
              }
            />
          );
        }

        default:
          break;
      }
    },
    [group, updateCellCallback]
  );

  const handleAddClick = useCallback(() => {
    const hasNewRows = tableRows.some((row) => row.isNew);
    const isSingleRowAndHasUnsavedValues =
      tableRows.length === 1 &&
      tableRows[0]?.cells.filter((cell) => !cell.id?.length).length ===
        tableRows[0]?.cells.length;

    if (hasNewRows || isSingleRowAndHasUnsavedValues) {
      enqueueSnackbar("Please save existing row before adding a new one.", {
        variant: "warning",
        autoHideDuration: 5000,
      });

      return;
    }

    const cells = new Array(group.tableData.table_headers.length)
      .fill(null)
      .map((_, idx) => {
        const getHeaderCustomFieldData = group.tableData.table_headers[idx];

        return {
          id: null,
          custom_field_template_id: getHeaderCustomFieldData.id,
          custom_field_group_id: group.groupDetails.id,
          user_id: null,
          council_id: null,
          company_id: null,
          field_value: null,
          option_selected: [],
          position: idx + 1,
        };
      });
    const newTableRows = [
      ...tableRows,
      {
        isNew: true,
        id: tableRows.length + 1,
        editMode: true,
        cells,
        originalCells: cells,
      },
    ];

    setTableRows(newTableRows);
  }, [group, tableRows]);

  const handleDeleteAskConfirmation = (index) => {
    popup.show({
      title: "Confirmation Delete",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmActionComponent
          alertText="You are about to delete whole row with all info inside. Are you sure?"
          confirmBtnName="Delete"
          onConfirm={() => handleDeleteRow(index)}
        />
      ),
    });
  };

  const deleteRow = useCallback(
    (index, row) => {
      const rowIsFullyUnsaved =
        row.cells.filter((cell) => !cell.id?.length).length ===
        row.cells.length;

      if (rowIsFullyUnsaved) {
        const newTableRows = tableRows.filter((_, idx) => index !== idx);
        setTableRows(newTableRows);
      } else {
        handleDeleteAskConfirmation(index);
      }
    },
    [tableRows, handleDeleteRow]
  );

  const handleEditClick = useCallback(
    (row, idx) => {
      const newRows = tableRows.map((row, index) => {
        if (index === idx) {
          return {
            ...row,
            editMode: !row.editMode,
          };
        }

        return row;
      });

      setTableRows(newRows);
    },
    [tableRows]
  );

  const handleUpdate = useCallback(
    (row, idx) => {
      const allCellIdsAreNull =
        row.cells.filter((cell) => !cell.id?.length).length ===
        row.cells.length;

      if (allCellIdsAreNull) {
        handleSave(row, idx);
        return;
      }

      const touchedCells = row.cells.filter((cell) => cell.isTouched);

      updateRowCells({
        cells: touchedCells,
        row,
        idx,
      });
    },
    [updateRowCells]
  );

  const handleCancel = useCallback(
    (row, idx) => {
      const newRows = tableRows.map((r, index) => {
        if (idx === index) {
          return {
            ...r,
            cells: r.originalCells,
            editMode: false,
            isTouched: false,
          };
        }

        return r;
      });

      setTableRows(newRows);
    },
    [tableRows]
  );

  const getColumnTotal = useCallback(
    (column, index) => {
      const total = tableRows.reduce((acc, row) => {
        const cellValuePerIndex = Number(row.cells[index].field_value);
        return acc + cellValuePerIndex;
      }, 0);
      const currency =
        currencySymbol?.find(({ name }) => name === column.option_values[0])
          ?.symbol || "";

      return `Total: ${currency}${total}`;
    },
    [tableRows, group, currencySymbol]
  );

  if (!group.tableData?.table_headers?.length) {
    return (
      <div className={styles.customFieldTable}>
        No custom fields found for current group.
      </div>
    );
  }

  return (
    <div className={`${styles.customFieldTable} table-responsive`}>
      <table className="table table-bordered">
        <thead>
          <tr>
            {group.tableData.table_headers.map((column, index) => (
              <th
                className={`${styles.column} ${styles.customFieldName}`}
                key={column.id}
              >
                <span>{column.field_name}</span>
                {column.field_type === "number" &&
                group.groupDetails.number_totals ? (
                  <div className="mt-2">{getColumnTotal(column, index)}</div>
                ) : null}
              </th>
            ))}
            {tableRows.length && !blockFunctionality ? (
              <th style={{ width: "30px !important" }}></th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {tableRows.length ? (
            tableRows.map((row, idx) => (
              <tr key={`row-${group.groupDetails.id}-${idx}`}>
                {row.cells.map((cell, idx1) => (
                  <td
                    className={styles.column}
                    key={`cell-${cell.id}-${idx + idx1}-${
                      group.groupDetails.id
                    }`}
                  >
                    {drawCustomField(row, cell, idx1)}
                  </td>
                ))}
                {!blockFunctionality ? (
                  <td style={{ width: "30px !important" }}>
                    <div className="d-flex align-items-center">
                      {loadingRowId === idx ? (
                        <LoadingComponent hideString />
                      ) : (
                        <>
                          {!row.editMode && !row.isNew && !row.isTouched ? (
                            <div className="mr-3">
                              <Icon
                                {...smSize}
                                icon="icn-edit"
                                className="cursor-pointer"
                                onClick={() => handleEditClick(row, idx)}
                              />
                            </div>
                          ) : null}
                          {row.isTouched && !row.isNew ? (
                            <div className="d-flex flex-column mr-3">
                              <Button
                                btn={BtnType.FRAME_LESS}
                                className={styles.button}
                                onClick={() => handleUpdate(row, idx)}
                              >
                                save
                              </Button>
                              <Button
                                btn={BtnType.FRAME_LESS}
                                className={styles.button}
                                onClick={() => handleCancel(row, idx)}
                              >
                                cancel
                              </Button>
                            </div>
                          ) : null}
                          {row.isNew ? (
                            <div className="mr-3">
                              <Button
                                btn={BtnType.FRAME_LESS}
                                className={styles.button}
                                onClick={() => handleSave(row, idx)}
                              >
                                save
                              </Button>
                            </div>
                          ) : null}
                          <Icon
                            {...smSize}
                            icon="icn-button-delete"
                            className={styles.deleteIcon}
                            onClick={() => deleteRow(idx, row)}
                          />
                        </>
                      )}
                    </div>
                  </td>
                ) : null}
              </tr>
            ))
          ) : !blockFunctionality ? (
            <tr>
              <td colSpan={group.tableData.table_headers.length}>
                <div className="d-flex align-items-start px-3">
                  Please use bellow button to add a new row.
                </div>
              </td>
            </tr>
          ) : null}
          {!blockFunctionality ? (
            <tr>
              <td
                style={{ height: "150px", verticalAlign: "middle" }}
                colSpan={group.tableData.table_headers.length + 1}
              >
                <div className="ml-5 d-flex">
                  <Button
                    btn={BtnType.REGULAR}
                    className={styles.button}
                    icon="icn-add"
                    onClick={handleAddClick}
                  >
                    ADD ROW
                  </Button>
                </div>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
      {lastUpdatedBy ? (
        <div className="d-flex align-items-end">
          <div className="d-flex flex-column mr-5">
            <span className={styles.updatedByLabel}>Updated by</span>
            <span>{lastUpdatedBy.by}</span>
          </div>
          <span className={styles.dateLabel}>{lastUpdatedBy.date}</span>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(TableCustomField);

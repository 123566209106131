import * as Yup from "yup";

import { OptionType } from "modules/primitives";

export const users = (length) => {
  const array = [];
  for (let index = 0; index < length; index++) {
    array[index] = {
      name: "Mary Smith",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_-b-ygbKGYUM9v7hU2ONHAOM9qGlLawSkqovSn1R3MNLNPwM&s",
    };
  }
  return array;
};

export const initModel = {
  mark_on_document_upload: false,
  name: "",
  description: "",
  stage_id: "",
  position: "",
  due_on: "",
  assigned_to: "",
  task_type: "organization",
  apply_to: ["all"],
  approval_task_options: {
    review: false,
    approval: true,
    lockStages: true,
  },
  isInvalidTask: false
};

export const validation = Yup.object().shape({
  name: Yup.string().required("Task name is required"),
  position: Yup.number().min(1, "Position must be greater than or equal to 1"),
  apply_to: Yup.array().when("stage_task", {
    is: "false",
    then: Yup.array().min(
      1,
      "Apply to must have at least one company selected",
    ),
  }),
});

export const validationInGSK = Yup.object().shape({
  name: Yup.string().required("Task name is required"),
  position: Yup.number().min(1, "Position must be greater than or equal to 1"),
  apply_to: Yup.array().when("stage_task", {
    is: "false",
    then: Yup.array().min(1, "Apply to must have at least one organization selected"),
  }),
});
export const taskOptions = [
  { val: "edit", text: "Edit task", id: "edit" },
  {
    val: "mark_all_complete",
    text: "Mark all as completed",
    id: "complete",
    markAllAsComplete: true,
  },
  {
    val: "delete_task",
    text: "Delete task",
    type: OptionType.DANGER,
    id: "delete",
  },
];

export const mainTaskEditConfirmationText = "You are about to edit a set of company tasks. Your changes may be applied to multiple company tasks and will override any previous selections or assignments. Do you want to proceed?";

export const industries = [
  {
    id: "1",
    name: "BY TECHNOLOGY",
  },
  {
    id: "2",
    name: "BY INDUSTRY",
  },
];

export const CLEAR_ITEMS = [
  {
    name: "All",
    id: "all",
  },
  {
    name: "Level 1",
    id: "level1",
  },
  {
    name: "Level 2",
    id: "level2",
  },
  {
    name: "Level 3",
    id: "level3",
  },
];

export const IDB_COUNTRIES = {
  id: "latinAmerica",
  name: "LAC Region",
  items: [
    {
      id: "Argentina",
      name: "Argentina",
    },
    {
      id: "Barbados",
      name: "Barbados",
    },
    {
      id: "Belize",
      name: "Belize",
    },
    {
      id: "Bolivia",
      name: "Bolivia",
    },
    {
      id: "Brazil",
      name: "Brazil",
    },
    {
      id: "Chile",
      name: "Chile",
    },
    {
      id: "Colombia",
      name: "Colombia",
    },
    {
      id: "Costa Rica",
      name: "Costa Rica",
    },
    {
      id: "Ecuador",
      name: "Ecuador",
    },
    {
      id: "El Salvador",
      name: "El Salvador",
    },
    {
      id: "Guatemala",
      name: "Guatemala",
    },
    {
      id: "Guyana",
      name: "Guyana",
    },
    {
      id: "Haiti",
      name: "Haiti",
    },
    {
      id: "Honduras",
      name: "Honduras",
    },
    {
      id: "Jamaica",
      name: "Jamaica",
    },
    {
      id: "Mexico",
      name: "Mexico",
    },
    {
      id: "Nicaragua",
      name: "Nicaragua",
    },
    {
      id: "Panama",
      name: "Panama",
    },
    {
      id: "Paraguay",
      name: "Paraguay",
    },
    {
      id: "Peru",
      name: "Peru",
    },
    {
      id: "Dominican Republic",
      name: "Dominican Republic",
    },
    {
      id: "Suriname",
      name: "Suriname",
    },
    {
      id: "Trinidad and Tobago",
      name: "Trinidad and Tobago",
    },
    {
      id: "Uruguay",
      name: "Uruguay",
    },
    {
      id: "Venezuela",
      name: "Venezuela",
    },
  ],
};

import React, { useEffect, useRef, useState } from "react";
import { format, parseISO } from "date-fns";

import styles from "./projectReports.module.scss";
import LoadingComponent from "../../../primitives/loading/loading.component";
import { CompanyAvatar } from "../../../primitives";
import TractionDownload from "../../../../common/components/tractionDownload";
import { RELATIONSHIP_COLORS } from "../../../../common/constants";

const chunk = function (array, size) {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);

  return [head, ...chunk(tail, size)];
};

const ProjectReportStep4 = ({
  model,
  tasksList,
  selectedProject,
  companyList,
}) => {
  const [finalData, setFinalData] = useState(null);
  const [companiesWithNotes, setCompaniesWithNotes] = useState(null);
  const [downloadIsActive, setDownloadIsActive] = useState(false);
  const [isRowBiggerThanWrapper, setIsRowBiggerThanWrapper] = useState(false);
  const [isRowBiggerThanWrapper1, setIsRowBiggerThanWrapper1] = useState(false);
  const [downloadableSectionHeight, setDownloadableSectionHeight] =
    useState(null);
  const wrapperRef = useRef(null);
  const wrapperRef1 = useRef(null);

  useEffect(() => {
    if (finalData && companiesWithNotes && !downloadableSectionHeight) {
      const wrapper = document.getElementById("downloadableSection");

      if (wrapper) {
        let totalHeight = 0;

        wrapper.childNodes.forEach((child) => {
          totalHeight += child.clientHeight;
        });

        setDownloadableSectionHeight(totalHeight);
      }
    }
  }, [finalData, companiesWithNotes, downloadableSectionHeight]);

  useEffect(() => {
    const wrapper = document.getElementById("downloadableSection");

    if (!wrapper) {
      return;
    }

    if (downloadIsActive) {
      wrapper.style.height = "100%";
    } else {
      wrapper.style.height = "calc(100vh - 230px)";
    }
  }, [downloadIsActive]);

  useEffect(() => {
    if (downloadableSectionHeight) {
      const wrapper = document.getElementById("downloadableSection");

      if (downloadableSectionHeight > window.innerHeight - 230) {
        // wrapper.style.height = '100%';
      } else {
        wrapper.style.height = "calc(100vh - 230px)";
      }
    }
  }, [downloadableSectionHeight, downloadIsActive]);

  useEffect(() => {
    if (wrapperRef?.current && finalData) {
      const parent = document.getElementById("reportWrapper");
      const elem = document.querySelector(".report-row");
      let totalElemWidth = 0;

      elem.childNodes.forEach((child) => {
        totalElemWidth += child.clientWidth;
      });

      setIsRowBiggerThanWrapper(totalElemWidth > parent.offsetWidth);
    }
  }, [wrapperRef, finalData]);

  useEffect(() => {
    if (wrapperRef1?.current && companiesWithNotes) {
      const parent = document.getElementById("reportWrapper1");
      const elem = document.querySelector(".report-row1");
      let totalElemWidth = 0;

      elem.childNodes.forEach((child) => {
        totalElemWidth += child.clientWidth;
      });

      setIsRowBiggerThanWrapper1(totalElemWidth > parent.offsetWidth);
    }
  }, [wrapperRef1, companiesWithNotes]);

  useEffect(() => {
    if (selectedProject && tasksList && model) {
      setDownloadIsActive(true);
      const selectedStages = selectedProject.project_stages
        .filter((s) => model.step2.stages.includes(s.stage.id))
        .map((s, idx) => ({
          ...s,
          isStage: true,
          colors:
            s.name === "No-Go"
              ? {
                  background: "#FF9B9B",
                  text: "#8A041A",
                }
              : RELATIONSHIP_COLORS[idx % RELATIONSHIP_COLORS.length],
        }));
      const selectedTasks = tasksList
        .filter((t) => model.step3.tasks.includes(t.id))
        .map((t) => ({ ...t, isTask: true }));
      const stagesAndTasksTogether = [...selectedStages, ...selectedTasks];
      const wrapper = document.getElementById("modalBody");
      const getModalWidth = wrapper.clientWidth;
      const colNumbersPerTable = Math.floor((getModalWidth - 220) / 150) - 1;
      const wrapperComputedStyle = window.getComputedStyle(wrapper, null);
      let wrapperWidth = getModalWidth;
      wrapperWidth -=
        parseFloat(wrapperComputedStyle.paddingLeft) +
        parseFloat(wrapperComputedStyle.paddingRight);

      const colWidth = (wrapperWidth - 220) / colNumbersPerTable;
      const selectedCompanies = companyList
        .filter((c) => model.step1.companies.includes(c.company.id))
        .map((c) => {
          const columns = new Array(
            model.step2.stages.length + model.step3.tasks.length
          )
            .fill(0)
            .map((item, idx) => {
              const itemByIdx = stagesAndTasksTogether[idx];

              if (itemByIdx.isStage) {
                const checkCompanyHasStage = c.stage?.id === itemByIdx?.stage?.id;
                const stage_changes =
                  c.stage_changes?.find(
                    (stc) => stc.stage_id === itemByIdx.stage.id
                  ) || {};

                return checkCompanyHasStage
                  ? {
                      ...itemByIdx,
                      stage_changes,
                      belongsToThisStage: true,
                    }
                  : {
                      ...itemByIdx,
                      stage_changes,
                    };
              }

              if (itemByIdx.isTask) {
                const getCompanyTask = itemByIdx.organizations.find(
                  (o) => o.organization_id === c.company.id
                );
                const taskAssignmentDetails =
                  itemByIdx.projects_task_assignments.find(
                    (t) =>
                      t.projects_task_organization?.organization_id ===
                      c.company.id
                  );

                return getCompanyTask
                  ? {
                      ...itemByIdx,
                      getCompanyTask,
                      taskAssignmentDetails,
                    }
                  : null;
              }

              return itemByIdx;
            });

          return {
            companyDetails: c,
            columns,
          };
        });
      const makeFullArray = new Array(
        model.step2.stages.length + model.step3.tasks.length
      ).fill(0);
      const chunksFromArray = chunk(makeFullArray, colNumbersPerTable);
      const tables = new Array(chunksFromArray.length)
        .fill(0)
        .map((item1, idx1) => {
          const rows = companyList
            .filter((c) => model.step1.companies.includes(c.company.id))
            .map((c) => {
              const columns = new Array(
                model.step2.stages.length + model.step3.tasks.length
              )
                .fill(0)
                .map((item, idx) => {
                  const itemByIdx = stagesAndTasksTogether[idx];

                  if (itemByIdx.isStage) {
                    const checkCompanyHasStage =
                      c.stage?.id === itemByIdx?.stage?.id;
                    const stage_changes =
                      c.stage_changes?.find(
                        (stc) => stc.stage_id === itemByIdx.stage.id
                      ) || {};

                    return checkCompanyHasStage
                      ? {
                          ...itemByIdx,
                          stage_changes,
                          belongsToThisStage: true,
                        }
                      : {
                          ...itemByIdx,
                          stage_changes,
                        };
                  }

                  if (itemByIdx.isTask) {
                    const getCompanyTask = itemByIdx.organizations.find(
                      (o) => o.organization_id === c.company.id
                    );
                    const taskAssignmentDetails =
                      itemByIdx.projects_task_assignments.find(
                        (t) =>
                          t.projects_task_organization?.organization_id ===
                          c.company.id
                      );

                    return getCompanyTask
                      ? {
                          ...itemByIdx,
                          getCompanyTask,
                          taskAssignmentDetails,
                        }
                      : null;
                  }

                  return itemByIdx;
                });

              return {
                companyDetails: c,
                columns: chunk(columns, colNumbersPerTable)[idx1],
              };
            });

          return {
            tableIdx: `table-${idx1}`,
            index: idx1,
            rows,
            colWidth,
          };
        });

      setFinalData({
        tables,
        selectedCompanies,
        selectedStages,
        selectedTasks,
      });
    }
  }, [model, tasksList, selectedProject, companyList]);

  useEffect(() => {
    if (model && tasksList && companyList) {
      const selectedTasksWithNotes = tasksList
        .filter((t) => model.step3.tasks.includes(t.id))
        .filter((t) => {
          const emptyAssignments = t.projects_task_assignments?.filter(
            (ass) => !ass.notes?.length
          );

          return emptyAssignments.length !== t.projects_task_assignments.length;
        });

      if (!selectedTasksWithNotes.length) {
        return;
      }

      const selectedCompanies = companyList
        .filter((c) => model.step1.companies.includes(c.company.id))
        .map((c) => {
          const columns = new Array(selectedTasksWithNotes.length)
            .fill(0)
            .map((item, idx) => {
              const itemByIdx = selectedTasksWithNotes[idx];

              const getCompanyTask = itemByIdx.organizations.find(
                (o) => o.organization_id === c.company.id
              );
              const taskAssignmentDetails =
                itemByIdx.projects_task_assignments.find(
                  (t) =>
                    t.projects_task_organization?.organization_id ===
                    c.company.id
                );

              return getCompanyTask
                ? {
                    ...itemByIdx,
                    getCompanyTask,
                    taskAssignmentDetails,
                  }
                : null;
            });

          return {
            companyDetails: c,
            columns,
          };
        });

      setCompaniesWithNotes({
        header: selectedTasksWithNotes,
        rows: selectedCompanies,
      });
    }
  }, [model, tasksList, companyList]);

  if (!finalData) {
    return (
      <div className={styles.finalStep}>
        <LoadingComponent customText="Preparing report..." />
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex flex-grow-1 justify-content-end">
        <TractionDownload
          handleDownloadState={setDownloadIsActive}
          timeoutTime={2000}
          hidePng
        />
      </div>
      <div
        className={`${styles.finalStep} ${downloadIsActive ? "p-3" : ""}`}
        id="downloadableSection"
      >
        {downloadIsActive && (
          <div className="d-flex flex-grow-1 justify-content-between align-items-center mb-2">
            <img
              height="50"
              src="https://res.cloudinary.com/ttp/image/upload/v1643207838/councils/aaf9d6e2-d79f-4d4e-8cce-26b361f58557_logo.png"
              alt="Kyndryl"
            />
            <h3 className="font-weight-bold">{model.step1.reportTitle}</h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="79"
              height="35"
              viewBox="0 0 79 35"
            >
              <g fill="none" fillRule="evenodd" fontSize="16">
                <g fill="#1F2933">
                  <g>
                    <text
                      fontFamily="HelveticaNeue-CondensedBlack, Helvetica Neue"
                      fontStyle="condensed"
                      fontWeight="700"
                      transform="translate(-1290 -12) translate(1291 12)"
                    >
                      <tspan x="0" y="16">
                        Traction
                      </tspan>
                    </text>
                    <text
                      fontFamily="HelveticaNeue-Light, Helvetica Neue"
                      fontWeight="300"
                      letterSpacing="-.08"
                      transform="translate(-1290 -12) translate(1291 12)"
                    >
                      <tspan x="0" y="31">
                        Technology
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        )}
        {!downloadIsActive && (
          <div className="d-flex flex-grow-1 justify-content-center">
            <h3 className="font-weight-bold">{model.step1.reportTitle}</h3>
          </div>
        )}
        {downloadIsActive ? (
          <div
            className={styles.scrollableWrapperWhenDownload}
            id="reportWrapper"
          >
            {finalData.tables.map((t) => (
              <div className="mb-3" key={t.tableIdx}>
                <div className={`${styles.header}`}>
                  <span className={`${styles.company}`}>Company</span>
                  {t.rows[0].columns?.filter((c) => c?.isStage)?.length ? (
                    <div>
                      <div className={styles.headerFlexItem}>
                        <span className="font-weight-bold">Stages</span>
                      </div>
                      <div className="d-flex">
                        {t.rows[0].columns
                          .filter((c) => c?.isStage)
                          .map((s) => (
                            <span
                              className={`${styles.item}`}
                              key={s.stage.id}
                              style={{
                                color: s.colors.text,
                                backgroundColor: s.colors.background,
                                minWidth: `${t.colWidth}px`,
                                width: `${t.colWidth}px`,
                              }}
                            >
                              {s.stage.name}
                            </span>
                          ))}
                      </div>
                    </div>
                  ) : null}
                  {t.rows[0].columns?.filter((c) => c?.isTask)?.length ? (
                    <div>
                      <div className={styles.headerFlexItem}>
                        <span className="font-weight-bold">Tasks</span>
                      </div>
                      <div className="d-flex">
                        {t.rows[0].columns
                          .filter((c) => c?.isTask)
                          .map((t1) => (
                            <span
                              className={`${styles.item}`}
                              key={t1.id}
                              style={{
                                minWidth: `${t.colWidth}px`,
                                width: `${t.colWidth}px`,
                              }}
                            >
                              {t1.name}
                            </span>
                          ))}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={styles.reportBody} ref={wrapperRef}>
                  {t.rows.map((r) => (
                    <div
                      key={r.companyDetails.company.id}
                      className={`${styles.reportRow} ${
                        isRowBiggerThanWrapper
                          ? styles.cellBorder
                          : styles.rowBorder
                      } report-row`}
                    >
                      <span className={styles.company}>
                        <div className={styles.companyIcon}>
                          <div className={styles.iconCon}>
                            <CompanyAvatar
                              imgSrc={r.companyDetails.company.logo}
                              name={r.companyDetails.company.name}
                            />
                          </div>
                        </div>
                        {r.companyDetails.company.name}
                      </span>
                      <>
                        {r.columns.map((c1, idx) =>
                          !c1 ? (
                            <span
                              key={`${r.companyDetails?.company?.id}-${idx}`}
                              className={`${styles.item}`}
                              style={{
                                minWidth: `${t.colWidth}px`,
                                width: `${t.colWidth}px`,
                              }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{
                                minWidth: `${t.colWidth}px`,
                                width: `${t.colWidth}px`,
                              }}
                              key={`${r.companyDetails?.company?.id}-${idx}`}
                              className={`${styles.item} ${
                                c1.isStage && c1.belongsToThisStage
                                  ? styles.highlightItem
                                  : ""
                              }`}
                            >
                              {c1.isStage ? (
                                c1.stage_changes?.changed_at ? (
                                  format(
                                    parseISO(c1.stage_changes?.changed_at),
                                    "eeee, MMM do, yyyy"
                                  )
                                ) : (
                                  "-"
                                )
                              ) : c1.taskAssignmentDetails.completed ? (
                                <span
                                  className="d-flex flex-column"
                                  style={{
                                    minWidth: `${t.colWidth}px`,
                                    width: `${t.colWidth}px`,
                                  }}
                                >
                                  <span className={styles.labelInsideGrid}>
                                    Completed
                                  </span>
                                  <span>
                                    {c1.taskAssignmentDetails.completed_on
                                      ? format(
                                          parseISO(
                                            c1.taskAssignmentDetails
                                              .completed_on
                                          ),
                                          "eeee, MMM do, yyyy"
                                        )
                                      : "-"}
                                  </span>
                                </span>
                              ) : (
                                <span
                                  className="d-flex flex-column"
                                  style={{
                                    minWidth: `${t.colWidth}px`,
                                    width: `${t.colWidth}px`,
                                  }}
                                >
                                  <span className={styles.labelInsideGrid}>
                                    {c1.taskAssignmentDetails.due_date
                                      ? "Due Date"
                                      : "Assigned"}
                                  </span>
                                  <span>
                                    {c1.taskAssignmentDetails.due_date
                                      ? format(
                                          parseISO(
                                            c1.taskAssignmentDetails.due_date
                                          ),
                                          "eeee, MMM do, yyyy"
                                        )
                                      : c1.taskAssignmentDetails.assigned_on
                                      ? format(
                                          parseISO(
                                            c1.taskAssignmentDetails.assigned_on
                                          ),
                                          "eeee, MMM do, yyyy"
                                        )
                                      : "-"}
                                  </span>
                                </span>
                              )}
                            </span>
                          )
                        )}
                      </>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.scrollableWrapper} id="reportWrapper">
            <div className={`${styles.header}`}>
              <span className={`${styles.company}`}>Company</span>
              {finalData?.selectedStages?.length ? (
                <div>
                  <div className={styles.headerFlexItem}>
                    <span className="font-weight-bold">Stages</span>
                  </div>
                  <div className="d-flex">
                    {finalData.selectedStages.map((s) => (
                      <span
                        className={`${styles.item}`}
                        key={s.stage.id}
                        style={{
                          color: s.colors.text,
                          backgroundColor: s.colors.background,
                        }}
                      >
                        {s.stage.name}
                      </span>
                    ))}
                  </div>
                </div>
              ) : null}
              {finalData?.selectedTasks?.length ? (
                <div>
                  <div className={styles.headerFlexItem}>
                    <span className="font-weight-bold">Tasks</span>
                  </div>
                  <div className="d-flex">
                    {finalData.selectedTasks.map((t) => (
                      <span className={`${styles.item}`} key={t.id}>
                        {t.name}
                      </span>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.reportBody} ref={wrapperRef}>
              {finalData.selectedCompanies.map((c) => (
                <div
                  key={c.companyDetails.company.id}
                  className={`${styles.reportRow} ${
                    isRowBiggerThanWrapper
                      ? styles.cellBorder
                      : styles.rowBorder
                  } report-row`}
                >
                  <span className={styles.company}>
                    <div className={styles.companyIcon}>
                      <div className={styles.iconCon}>
                        <CompanyAvatar
                          imgSrc={c.companyDetails.company.logo}
                          name={c.companyDetails.company.name}
                        />
                      </div>
                    </div>
                    {c.companyDetails.company.name}
                  </span>
                  <>
                    {c.columns.map((c1, idx) =>
                      !c1 ? (
                        <span
                          key={`${c.companyDetails?.company?.id}-${idx}`}
                          className={`${styles.item}`}
                        >
                          -
                        </span>
                      ) : (
                        <span
                          key={`${c.companyDetails?.company?.id}-${idx}`}
                          className={`${styles.item} ${
                            c1.isStage && c1.belongsToThisStage
                              ? styles.highlightItem
                              : ""
                          }`}
                        >
                          {c1.isStage ? (
                            c1.stage_changes?.changed_at ? (
                              format(
                                parseISO(c1.stage_changes?.changed_at),
                                "eeee, MMM do, yyyy"
                              )
                            ) : (
                              "-"
                            )
                          ) : c1.taskAssignmentDetails.completed ? (
                            <span className="d-flex flex-column">
                              <span className={styles.labelInsideGrid}>
                                Completed
                              </span>
                              <span>
                                {c1.taskAssignmentDetails.completed_on
                                  ? format(
                                      parseISO(
                                        c1.taskAssignmentDetails.completed_on
                                      ),
                                      "eeee, MMM do, yyyy"
                                    )
                                  : "-"}
                              </span>
                            </span>
                          ) : (
                            <span className="d-flex flex-column">
                              <span className={styles.labelInsideGrid}>
                                {c1.taskAssignmentDetails.due_date
                                  ? "Due Date"
                                  : "Assigned"}
                              </span>
                              <span>
                                {c1.taskAssignmentDetails.due_date
                                  ? format(
                                      parseISO(
                                        c1.taskAssignmentDetails.due_date
                                      ),
                                      "eeee, MMM do, yyyy"
                                    )
                                  : c1.taskAssignmentDetails.assigned_on
                                  ? format(
                                      parseISO(
                                        c1.taskAssignmentDetails.assigned_on
                                      ),
                                      "eeee, MMM do, yyyy"
                                    )
                                  : "-"}
                              </span>
                            </span>
                          )}
                        </span>
                      )
                    )}
                  </>
                </div>
              ))}
            </div>
          </div>
        )}
        {selectedProject?.next_steps && (
          <div className="d-flex flex-column justify-content-center mt-4">
            <h3 className="text-center">Next Steps</h3>
            <div className={styles.scrollableWrapper}>
              <div
                className="py-3 px-5"
                dangerouslySetInnerHTML={{ __html: selectedProject.next_steps }}
              />
            </div>
          </div>
        )}
        {companiesWithNotes && (
          <div className="d-flex flex-column justify-content-center mt-4">
            <h3 className="text-center">Notes</h3>
            <div className={styles.scrollableWrapper} id="reportWrapper1">
              <div className={styles.headerForNotes}>
                <span className={styles.company}>Company</span>
                {companiesWithNotes.header.map((t, idx) => (
                  <span className={styles.item} key={`${t.id}-${idx}`}>
                    {t.name}
                  </span>
                ))}
              </div>
              <div className={styles.reportBody} ref={wrapperRef1}>
                {companiesWithNotes.rows.map((c) => (
                  <div
                    key={c.companyDetails.company.id}
                    className={`${styles.reportRow} ${
                      isRowBiggerThanWrapper1
                        ? styles.cellBorder
                        : styles.rowBorder
                    } report-row1`}
                  >
                    <span className={styles.company}>
                      <div className={styles.companyIcon}>
                        <div className={styles.iconCon}>
                          <CompanyAvatar
                            imgSrc={c.companyDetails.company.logo}
                            name={c.companyDetails.company.name}
                          />
                        </div>
                      </div>
                      {c.companyDetails.company.name}
                    </span>
                    <>
                      {c.columns.map((c1, idx) =>
                        !c1 ? (
                          <span
                            key={`${c.companyDetails?.company?.id}-${idx}-1`}
                            className={styles.item}
                          >
                            -
                          </span>
                        ) : (
                          <span
                            key={`${c.companyDetails?.company?.id}-${idx}-1`}
                            className={styles.item}
                          >
                            {c1?.taskAssignmentDetails?.notes?.length ? (
                              <span className="d-flex flex-column">
                                {c1.taskAssignmentDetails.notes.map((note) => (
                                  <span key={note.id} className={styles.note}>
                                    - {note.description}
                                  </span>
                                ))}
                              </span>
                            ) : (
                              "-"
                            )}
                          </span>
                        )
                      )}
                    </>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ProjectReportStep4);

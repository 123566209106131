import { pageMeta } from "common/httpCall";
import {
  THEME_COMPANY_ADD_ATTEMPT,
  THEME_COMPANY_ADD_SUCCESS,
  THEME_MEMBER_ADD_ATTEMPT,
  THEME_MEMBER_ADD_SUCCESS,
  THEME_COMPANY_DELETE_ATTEMPT,
  THEME_COMPANY_DELETE_SUCCESS,
  THEME_COMPANY_GET_ATTEMPT,
  THEME_COMPANY_GET_SUCCESS,
  THEME_MEMBER_DELETE_ATTEMPT,
  THEME_MEMBER_DELETE_SUCCESS,
  THEME_MEMBER_EDIT_ATTEMPT,
  THEME_MEMBER_EDIT_SUCCESS,
  ALL_EVENTS_GET_ATTEMPT,
  ALL_EVENTS_GET_SUCCESS,
  THEME_EVENT_ADD_ATTEMPT,
  THEME_EVENT_ADD_SUCCESS,
  THEME_PROJECT_CREATE_ATTEMPT,
  THEME_PROJECT_CREATE_SUCCESS,
  THEME_IDEAS_CREATE_ATTEMPT,
  THEME_IDEAS_CREATE_SUCCESS,
  THEME_IDEAS_GET_ATTEMPT,
  THEME_IDEAS_GET_SUCCESS,
  THEME_IDEAS_DELETE_ATTEMPT,
  THEME_IDEAS_DELETE_SUCCESS,
  THEME_RELATED_IDEAS_GET_ATTEMPT,
  THEME_RELATED_IDEAS_GET_SUCCESS,
  RESET_SHOULD_UPDATE,
  THEME_PROJECT_ADD_ATTEMPT,
  THEME_PRODUCT_DELETE_ATTEMPT,
  FORD_SPOC_EXPERTS_GET_SUCCESS,
  THEME_EXPERT_ADD_SUCCESS,
  THEME_EXPERT_DELETE_SUCCESS,
  SPOC_THEME_EXPERTS_GET_SUCCESS,
  THEME_METRICS_GET_ATTEMPT,
  THEME_METRICS_GET_SUCCESS,
} from "./overviewTab.action";

import { THEME_ADD_SUCCESS } from "../../themesMainPage/themesMain.action";

import {
  DELETE_RELATED_THEME_SUCCESS,
  DELETE_RELATED_THEME_ATTEMPT,
} from "modules/projects/project/overview/overview.action";

const initState = {
  companyList: [],
  memberLists: [],
  favorite: false,
  loadingPosts: false,
  loadingCompany: false,
  shouldUpdatePosts: false,
  loadingMember: false,
  allEventsList: [],
  themeEventsList: [],
  updateKanbanCompanies: false,
  loadingIdeas: false,
  modalThemeIdeas: [],
  themeIdeasList: [],
  loadingModalIdeas: false,
  shouldUpdate: false,
  smeSpocExperts: [],
  themeSpocSmeExperts: [],
  companiesPageMeta: {},
  metricsData: {},
  loadingMetricsData: false,
};

const themesOverviewReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case THEME_PRODUCT_DELETE_ATTEMPT: {
      return {
        ...state,
        companyList: state.companyList.filter(
          (c) => c.product_id !== payload.productId
        ),
      };
    }

    case THEME_COMPANY_GET_ATTEMPT:
      return {
        ...state,
        loadingCompany: true,
      };
    case THEME_COMPANY_GET_SUCCESS:
      return {
        ...state,
        companyList: payload?.response,
        loadingCompany: false,
        companiesPageMeta: pageMeta(payload),
        memberLists: [],
      };
    case THEME_COMPANY_ADD_ATTEMPT:
      return {
        ...state,
        loadingCompany: true,
        updateKanbanCompanies: false,
      };
    case THEME_COMPANY_ADD_SUCCESS:
      return {
        ...state,
        companyList: payload.response.companies,
        memberLists: payload.response.members,
        loadingCompany: false,
        updateKanbanCompanies: payload.activeLayout?.name === "card",
      };

    case THEME_ADD_SUCCESS:
      return {
        ...state,
        memberLists: payload.response.members,
      };
    case THEME_COMPANY_DELETE_ATTEMPT:
      return {
        ...state,
        loadingCompany: true,
        updateKanbanCompanies: true,
      };
    case THEME_COMPANY_DELETE_SUCCESS:
      return {
        ...state,
        companyList: payload.response.companies,
        memberLists: payload.response.members,
        loadingCompany: false,
        updateKanbanCompanies: false,
      };
    case THEME_MEMBER_ADD_ATTEMPT:
      return {
        ...state,
        loadingMember: true,
      };
    case THEME_MEMBER_ADD_SUCCESS:
      return {
        ...state,
        memberLists: payload.response?.members,
        loadingMember: false,
      };
    case THEME_MEMBER_DELETE_ATTEMPT:
      return {
        ...state,
        loadingMember: true,
      };
    case THEME_MEMBER_DELETE_SUCCESS:
      return {
        ...state,
        memberLists: payload.response?.members,
        loadingMember: false,
      };
    case THEME_MEMBER_EDIT_ATTEMPT:
      return {
        ...state,
        loadingMember: true,
      };
    case THEME_MEMBER_EDIT_SUCCESS:
      return {
        ...state,
        memberLists: payload.response?.members,
        loadingMember: false,
      };
    case ALL_EVENTS_GET_ATTEMPT:
      return {
        ...state,
        loadingEvents: true,
      };
    case ALL_EVENTS_GET_SUCCESS:
      return {
        ...state,
        allEventsList: payload.response,
        loadingEvents: false,
      };
    case THEME_EVENT_ADD_ATTEMPT:
      return {
        ...state,
        // loadingMember: true,
      };
    case THEME_EVENT_ADD_SUCCESS:
      return {
        ...state,
        themeEventsList: payload.response?.events,
        loadingMember: false,
      };
    case THEME_PROJECT_CREATE_ATTEMPT:
      return {
        ...state,
        // loadingMember: true,
      };
    case THEME_PROJECT_CREATE_SUCCESS:
      return {
        ...state,
        // themeEventsList: payload.response?.events,
        // loadingMember: false,
      };
    case THEME_IDEAS_CREATE_ATTEMPT:
      return {
        ...state,
        loadingIdeas: true,
        shouldUpdate: false,
      };
    case THEME_IDEAS_CREATE_SUCCESS:
      return {
        ...state,
        themeIdeasList: payload.response?.ideas,
        loadingIdeas: false,
        shouldUpdate: true,
      };
    case THEME_IDEAS_GET_ATTEMPT:
      return {
        ...state,
        loadingModalIdeas: true,
      };
    case THEME_IDEAS_GET_SUCCESS:
      return {
        ...state,
        modalThemeIdeas: payload.response,
        loadingModalIdeas: false,
      };
    case THEME_IDEAS_DELETE_ATTEMPT:
      return {
        ...state,
        loadingIdeas: true,
      };
    case THEME_IDEAS_DELETE_SUCCESS:
      return {
        ...state,
        themeIdeasList: payload.response?.ideas,
        loadingIdeas: false,
      };
    case THEME_RELATED_IDEAS_GET_ATTEMPT:
      return {
        ...state,
        loadingIdeas: true,
      };
    case THEME_RELATED_IDEAS_GET_SUCCESS:
      return {
        ...state,
        themeIdeasList: payload.response,
        loadingIdeas: false,
      };
    case THEME_PROJECT_ADD_ATTEMPT:
      return {
        ...state,
        shouldUpdateProjects: false,
      };

    case RESET_SHOULD_UPDATE:
      return {
        ...state,
        shouldUpdateProjects: true,
      };
    case DELETE_RELATED_THEME_ATTEMPT:
      return {
        ...state,
        shouldUpdateProjects: false,
      };

    case DELETE_RELATED_THEME_SUCCESS:
      return {
        ...state,
        shouldUpdateProjects: true,
      };

    case FORD_SPOC_EXPERTS_GET_SUCCESS:
      return {
        ...state,
        smeSpocExperts: payload.response.map((exp) => ({
          ...exp,
          full_name: exp.attributes.name,
          id: exp.attributes.user_id,
        })),
      };

    case SPOC_THEME_EXPERTS_GET_SUCCESS:
      return {
        ...state,
        themeSpocSmeExperts: payload?.response.map((exp) => {
          return {
            expertRelationId: exp.id,
            user_id: exp.user.id,
            ...exp?.user,
          };
        }),
      };
    case THEME_EXPERT_ADD_SUCCESS:
      return {
        ...state,
        themeSpocSmeExperts: payload?.response.map((exp) => {
          return {
            expertRelationId: exp.id,
            user_id: exp?.user.id,
            ...exp?.user,
          };
        }),
      };
    case THEME_EXPERT_DELETE_SUCCESS:
      return {
        ...state,
        themeSpocSmeExperts: state.themeSpocSmeExperts.filter(
          (exp) => payload !== exp.expertRelationId
        ),
      };

    case THEME_METRICS_GET_ATTEMPT:
      return {
        ...state,
        loadingMetricsData: true,
      };

    case THEME_METRICS_GET_SUCCESS:
      return {
        ...state,
        loadingMetricsData: false,
        metricsData: payload.response,
      };

    default:
      return state;
  }
  //   return state;
};

export default themesOverviewReducer;

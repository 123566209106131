import React, { useEffect } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { savePrevUrl } from "modules/companies/company/company.action";
import { useModelPopup } from "../../../../common/hooks";
import { layoutList } from "./pipelineTab.constant";
import styles from "./styles.module.scss";
import Icon from "../../../../common/icon/icon.component";
import { mdSize } from "../../../../common/icon";
import { Button, BtnType } from "../../../primitives/index";
import MixedAddCompanyOrProducts from "../../../../common/components/mixedSearch/mixedAddCompanyOrProducts";
import { themesCompanyAddAttempt } from "modules/themes/themesIndividualPage/themesOverviewTab/overviewTab.action";

const PipelineTabTopActions = (props) => {
  const {
    handleChangeLayout,
    activeLayout,
    setInvestMode,
    investMode,
    fromThemes,
  } = props;
  const popup = useModelPopup();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    themesReducer: {
      themesMainReducer: { currentThemeData },
    },
    companiesReducer: {
      companyReducer: { prevPagePath },
    },
  } = useSelector((state) => state);

  const handleAddCallback = (selectedCompanies) => {
    const data = {
      theme_company: selectedCompanies.map((comp) => {
        if (comp.is_product) {
          return {
            council_product_id: comp.id,
            is_product: true,
          }
        }
        return {
          company_id: comp.id,
          is_product: false,
        };
      }),
    };

    dispatch(
      themesCompanyAddAttempt({
        themeId: currentThemeData.id,
        data,
        activeLayout,
        enqueueSnackbar,
        cb: () => {
          popup.hide();
        }
      })
    );
  }

  const handleAddCompanyClick = () => {
    popup.show({
      title: "Add Company to Stage",
      component: (
        <MixedAddCompanyOrProducts
          handleSave={handleAddCallback}
          placeholder="Type to search or select from below"
          label="Companies or Products"
          existingItems={currentThemeData.companies}
        />
      ),
    });
  };
  useEffect(() => {
    if (prevPagePath.indexOf("details") !== -1) {
      setInvestMode(true);
    } else {
      setInvestMode(false);
    }
  }, [prevPagePath, fromThemes]);

  useEffect(
    () => () => {
      if (history.location.pathname.indexOf("details") === -1) {
        dispatch(savePrevUrl({ prevPath: "" }));
      }
    },
    [history]
  );
  return (
    <div className={styles.topActionsContainer}>
      <div className={styles.topActionsSettingsContainer}>
        <div className={styles.settingsStats}>
          {!fromThemes &&
            layoutList.map((layout) => (
              <div key={layout.type} className={styles.statsItem}>
                {" "}
                <Icon
                  {...mdSize}
                  icon={layout.type}
                  className={classNames(
                    styles.icon,
                    activeLayout?.name === layout?.name ? styles.active : null
                  )}
                  onClick={() => handleChangeLayout({ name: layout.name })}
                />
              </div>
            ))}

          {session?.theme_investment_access && selectedCouncil?.traction_tag === "firmenich" && (
            <div className={styles.switchFormTypeBlock}>
              <Button
                onClick={() =>
                  setInvestMode(() => {
                    if (history.location.pathname.indexOf("details") === -1) {
                      dispatch(savePrevUrl({ prevPath: "" }));
                    }
                    return false;
                  })
                }
                className={investMode ? styles.defaultButton : styles.pressedButton}
              >
                PARTNERSHIP
              </Button>
              <Button
                onClick={() => setInvestMode(true)}
                className={investMode ? styles.pressedButton : styles.defaultButton}
              >
                INVESTMENT
              </Button>
            </div>
          )}
        </div>
        {!investMode && (
          <div className={styles.filterAddCompanyBlock}>
            {/* filter will be added soon */}
            {/* <select
              onChange={(e) => setFilterOption(e.target.value)}
              className={styles.customSelect}
            >
              {filterOptions.map((option, index) => {
                if (option.optGroup) {
                  return <optgroup key={`key-${option.text + index}`} label={option.text} />;
                }
                return (
                  <option
                    key={`option-${option.value + index}`}
                    className={option.optGroupChild && styles.optGroupChild}
                    value={option.value}
                  >
                    {option.text}
                  </option>
                );
              })}
            </select> */}

            {!fromThemes && (
              <div className={styles.addCompanyBtn}>
                <Button
                  icon="icn-add"
                  type="button"
                  btn={BtnType.REGULAR}
                  onClick={handleAddCompanyClick}
                >
                  ADD COMPANY
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default PipelineTabTopActions;

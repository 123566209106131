import React from "react";
import LinearProgress from '@mui/material/LinearProgress';
import classnames from "classnames";

import styles from "./linearProgressWithLabel.module.scss";

const LinearProgressWithLabel = props => {
  return (
    <div className={styles.loaderContainer}>
      <div className={
        classnames(styles.loaderWrp, props.mode === "error" ? "error-progress" : "")
      }>
        <LinearProgress
          variant={props.mode === "error" ? "indeterminate" : "determinate"}
          {...props}
        />
      </div>
      <div
        className={classnames(styles.progressValue, props.mode === "error" ? styles.errorLabel : "")}
      >
        {props.mode === "error" ? "Error" : `${Math.round(props.value)}%`}
      </div>
    </div>
  )
}

export default LinearProgressWithLabel;

import React from "react";
import { Loading } from "modules/primitives/index";
import styles from "../details.module.scss";

const CustomFieldHistoryComponent = ({
  customFieldHistory,
  customFieldHistoryLoading,
}) => {
  return (
    <div className={styles.historyContainer}>
      <>
        {customFieldHistoryLoading ? (
          <Loading customText={"Loading history ..."} />
        ) : (
          <>
            {customFieldHistory && customFieldHistory.length ? (
              customFieldHistory.map((history, index) => {
                return (
                  <div key={history.updated_at} className={styles.historyItem}>
                    <div className={styles.updatedBy}>{history.updated_by}</div>
                    <div className={styles.updatedAt}>
                      {history.updated_at ? history.updated_at : null}
                    </div>
                    {history.field_type === "text" && (
                      <div
                        className={styles.changedTo}
                        dangerouslySetInnerHTML={{
                          __html: history.changed_to.field_value || "",
                        }}
                      ></div>
                    )}
                    {history.field_type === "dropdown" && (
                      <div className={styles.changedTo}>
                        <div key={history.changed_to.option_selected}>
                          {history.changed_to.option_selected.join(", ")}
                        </div>
                      </div>
                    )}
                    {history.field_type === "checklist" && (
                      <div className={styles.changedTo}>
                        <div key={history.changed_to.updated_at}>
                          <ul className={styles.ul}>
                            {history.changed_to?.option_selected?.map((opt) => {
                              return <li key={opt}>{opt}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                    {(history.field_type === "date" ||
                      history.field_type === "link" ||
                      history.field_type === "number") && (
                        <div className={styles.changedTo}>
                          <div key={history.changed_to.updated_at}>
                            <ul className={styles.ul}>{history.changed_to?.field_value}</ul>
                          </div>
                        </div>
                      )}
                    {history.field_type === "agreement" && (
                      <div className={styles.changedTo}>
                        <div key={history.changed_to.updated_at}>
                          {history.changed_to?.expiration_date && (
                            <>Expiration date: {history.changed_to?.expiration_date}</>
                          )}
                          {history.changed_to.agreement_type_selected !== undefined && (
                            <>
                              Selected:{" "}
                              {history.changed_to?.agreement_type_selected ? "Yes" : "No"}
                            </>
                          )}
                          {history.changed_to.field_placeholder !== undefined && (
                            <>
                              Instruction changed to:{" "}
                              {history.changed_to?.field_placeholder}
                            </>
                          )}
                          {history.changed_to.field_name !== undefined && (
                            <>Name changed to: {history.changed_to?.field_name}</>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <>This field was not edited</>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default React.memo(CustomFieldHistoryComponent);

import React, { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";

import { Pannel } from "../../../../primitives";
import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import { Icon, smSize } from "../../../../../common/icon";
import { useModelPopup } from "../../../../../common/hooks";
import ProjectPlantsAndAreasModal
  from "modules/projects/project/overview/fordPlants/projectPlantsAndAreasModal.component";
import styles from "modules/projects/project/overview/companies/projectCompanies.module.scss";
import { getPlantByType, resetPlantByType } from "../../../../../common/actions/common.action";
import fordPlantStyles from './fordPlants.module.scss';
import { tooltipTextsByState } from "modules/projects/project/project.constant";

const FordPlants = ({ selectedCouncil, selectedProject, isAdmin }) => {
  const [localProjId, setLocalProjId] = useState(null);
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    authReducer: { session },
    projectsReducer: {
      projectReducer: { projectPlant },
    },
    adminPlantsAndAreasReducer: { plantsAndAreas }
  } = useSelector((state) => state);

  const ownersOrManagers = useMemo(
    () =>
      selectedProject?.project_members?.filter((m) => m.user_role === "owner" || m.user_role === "manager"),
    [selectedProject]
  );

  const isProjectOwnerOrManager = useMemo(() => {
    return ownersOrManagers?.some(o => o.user.id === session?.id);
  }, [session, ownersOrManagers]);

  useEffect(() => {
    if (selectedCouncil?.name !== 'Ford') {
      return;
    }

    if (!plantsAndAreas.length) {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        })
      );
    }
  }, [plantsAndAreas, selectedCouncil]);

  useEffect(() => {
    if (localProjId !== selectedProject?.id) {
      setLocalProjId(selectedProject.id);
      dispatch(getPlantByType({
        resourceId: selectedProject.id,
        enqueueSnackbar,
        type: 'Project',
      }));
    }

    return () => {
      dispatch(resetPlantByType({
        type: 'Project'
      }))
    }
  }, [localProjId, selectedProject?.id]);

  const handleEditPlants = () => {
    popup.show({
      title: "Edit Plants + Areas",
      component: <ProjectPlantsAndAreasModal />,
    });
  }

  return (
    <Pannel title="Plant + Area" padding="0">
      <div className={`${styles.panelHeader} justify-content-between`}>
        <span>Plant</span>
        {
          (isAdmin || isProjectOwnerOrManager) && (
            <div className="d-flex pr-3">
              <div
                className="cursor-pointer"
                onClick={handleEditPlants}
              >
                <Icon {...smSize} icon="icn-edit" />
              </div>
            </div>
          )
        }
      </div>
      <div className="p-3">
        {
          projectPlant?.id ? (
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center justify-content-between">
                <span>{projectPlant.name}</span>
                {
                  !projectPlant?.areas?.length ? (
                    <Tooltip
                      title={tooltipTextsByState[projectPlant?.status]}
                      placement="top"
                    >
                      <div
                        className={classNames(
                          fordPlantStyles.plantCircle,
                          fordPlantStyles[projectPlant?.status],
                        )}
                      ></div>
                    </Tooltip>
                  ) : null
                }
              </div>
              {
                projectPlant?.areas?.length > 0 ? (
                  <div className="mt-3">
                  <span className="font-weight-bold">
                    Area{projectPlant.areas.length === 1 ? '' : 's'}
                  </span>
                    <div className="mt-2">
                      <ul className="ml-3">
                        {
                          projectPlant.areas.map((area, i) => (
                            <li className="d-flex align-items-center justify-content-between"
                                key={`${area.attributes.name}-${i}`}>
                              <span>{area.attributes.name}</span>
                              <Tooltip
                                title={tooltipTextsByState[projectPlant?.status]}
                                placement="top"
                              >
                                <div
                                  className={classNames(
                                    fordPlantStyles.plantCircle,
                                    fordPlantStyles[projectPlant?.status],
                                  )}
                                ></div>
                              </Tooltip>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                ) : null
              }
            </div>
          ) : (
            <div>
              No added plants yet.
            </div>
          )
        }
      </div>
    </Pannel>
  );
};

export default FordPlants;

export const PEOPLE_LIST_MEMBER_NOTES_ATTEMPT = "PEOPLE_LIST_MEMBER_NOTES_ATTEMPT";
export const peopleListMemberNotesGet = (payload) => ({
  type: PEOPLE_LIST_MEMBER_NOTES_ATTEMPT,
  payload,
});
export const PEOPLE_LIST_MEMBER_NOTES_SUCCESS = "PEOPLE_LIST_MEMBER_NOTES_SUCCESS";
export const peopleListMemberNotesSuccess = (payload) => ({
  type: PEOPLE_LIST_MEMBER_NOTES_SUCCESS,
  payload,
});

export const PEOPLE_ADD_MEMBER_NOTE_SUCCESS = "PEOPLE_ADD_MEMBER_NOTE_SUCCESS";

// To be able to call it from the company epic
export const peopleAddMemberNoteSuccess = (payload) => ({
  type: PEOPLE_ADD_MEMBER_NOTE_SUCCESS,
  payload,
});

export const PEOPLE_DELETE_MEMBER_NOTE_ATTEMPT = "PEOPLE_DELETE_MEMBER_NOTE_ATTEMPT";
export const peopleDeleteMemberNote = (payload) => ({
  type: PEOPLE_DELETE_MEMBER_NOTE_ATTEMPT,
  payload,
});
export const PEOPLE_DELETE_MEMBER_NOTE_SUCCESS = "PEOPLE_DELETE_MEMBER_NOTE_SUCCESS";
export const peopleDeleteMemberNoteSuccess = (payload) => ({
  type: PEOPLE_DELETE_MEMBER_NOTE_SUCCESS,
  payload,
});

export const PEOPLE_EDIT_MEMBER_NOTE_SUCCESS = "PEOPLE_EDIT_MEMBER_NOTE_SUCCESS";
export const peopleEditMemberNoteSuccess = (payload) => ({
  type: PEOPLE_EDIT_MEMBER_NOTE_SUCCESS,
  payload,
});

import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { ModalFooter, ModalBody } from "../../../../application/modal";
import { Button, BtnType } from "../../../primitives";
import { useModelPopup } from "../../../../common/hooks";
import ProjectReportStep1 from "./projectReportsStep1";
import ProjectReportStep2 from "./projectReportsStep2";
import ProjectReportStep3 from "./projectReportsStep3";
import ProjectReportStep4 from "./projectReportsStep4";
import { PROJECT_REPORT } from "./constants";

const ProjectReports = () => {
  const [model, setModel] = useState(PROJECT_REPORT);
  const [activeStep, setActiveStep] = useState(1);
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();

  const {
    projectsReducer: {
      projectReducer: { selectedProject },
      taskReducer: { tasksList },
      companyReducer: {
        overviewCompanies = [],
      },
    },
  } = useSelector((state) => state);

  const companyList = useMemo(() => {
    if (!overviewCompanies) return [];
    return overviewCompanies
      .filter((c) => c.state !== "removed")
  }, [overviewCompanies]);

  useEffect(() => {
    if (selectedProject && companyList) {
      setModel({
        ...PROJECT_REPORT,
        step1: {
          ...PROJECT_REPORT.step1,
          reportTitle: `${selectedProject.name} Project Report`,
          companies: companyList.map(c => c.company.id)
        },
      });
    }
  }, [selectedProject, companyList]);

  const handleCancelClick = () => popup.hide();

  const handleCreateReportClick = () => {
    setActiveStep(4);

    // popup.hide();
  };

  const handleNextStep = () => {
    if (activeStep === 1) {
      if (!model.step1.companies.length) {
        enqueueSnackbar("Please select at least one company", {
          variant: "error",
        });

        return;
      }

      // preselect by default all stages as per ticket
      if (!model.step2.stages.length) {
        setModel({
          ...model,
          step2: {
            ...model.step2,
            stages: selectedProject.project_stages.map((s) => s.stage.id),
            isAllSelected: true,
          },
        });
      }
    }

    if (activeStep === 2) {
      // preselect by default all tasks as per ticket
      if (!model.step3.tasks.length) {
        setModel({
          ...model,
          step3: {
            ...model.step3,
            tasks: tasksList.map((t) => t.id),
            isAllSelected: true,
          },
        });
      }
    }

    setActiveStep(activeStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const getStep = () => {
    switch (activeStep) {
      case 1: {
        return (
          <ProjectReportStep1
            selectedProject={selectedProject}
            model={model}
            setModel={setModel}
            companyList={companyList}
          />
        );
      }

      case 2: {
        return (
          <ProjectReportStep2
            selectedProject={selectedProject}
            model={model}
            setModel={setModel}
          />
        );
      }

      case 3: {
        return (
          <ProjectReportStep3
            model={model}
            setModel={setModel}
            tasksList={tasksList}
          />
        );
      }

      case 4: {
        return (
          <ProjectReportStep4
            model={model}
            selectedProject={selectedProject}
            tasksList={tasksList}
            companyList={companyList}
          />
        );
      }
    }
  };

  return (
    <div>
      <ModalBody>
        <div>
          {activeStep !== 4 && (
            <h5>
              STEP
              <b>{activeStep}</b> of 3
            </h5>
          )}
          {getStep()}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-center">
          <Button
            className="mr-3"
            btn={BtnType.FRAME_LESS}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          {activeStep !== 1 && (
            <Button
              className="mr-3"
              btn={BtnType.FRAME_LESS}
              onClick={handlePrevStep}
            >
              Previous Step
            </Button>
          )}
          {activeStep === 3 && (
            <Button
              type="submit"
              btn={BtnType.REGULAR}
              onClick={handleCreateReportClick}
            >
              Create Report
            </Button>
          )}
          {activeStep < 3 && (
            <Button
              type="submit"
              btn={BtnType.REGULAR}
              onClick={handleNextStep}
            >
              Next Step
            </Button>
          )}
          {activeStep === 4 && (
            <Button
              type="submit"
              btn={BtnType.REGULAR}
              onClick={handleCancelClick}
            >
              Close
            </Button>
          )}
        </div>
      </ModalFooter>
    </div>
  );
};

export default ProjectReports;

import React from "react";
import classNames from "classnames";
import styles from "./pannel.module.scss";

const PannelComponent = (props) => {
  const {
    children,
    title,
    padding,
    rightContent = "",
    rightContentColumn,
    parentStyle,
    withCollapse,
    expanded,
    ...rest
  } = props;
  return (
    <div
      className={classNames("card", styles.wrapper)}
      style={{ ...parentStyle }}
    >
      <div
        className={`${styles.headerWrp} ${
          rightContentColumn ? "flex-column" : ""
        }`}
        style={withCollapse ? { marginTop: "0px" } : {}}
      >
        <h4
          className={classNames(
            withCollapse ? styles.w100 : null,
            styles.title
          )}
        >
          {title}{" "}
        </h4>
        <div
          className={`${styles.rightPanel} ${rightContentColumn ? "mb-1" : ""}`}
        >
          {rightContent}
        </div>
      </div>

      <div
        className={classNames(
          styles.container,
          withCollapse ? (expanded ? styles.expanded : styles.expandable) : ""
        )}
        style={{ padding: padding || "16px", ...rest }}
      >
        {children}
      </div>
    </div>
  );
};

export default PannelComponent;

import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import Appsignal from "@appsignal/javascript";

import Input from "./input";
import SelectInput from "./select-input";
import CheckBoxInput from "./checkbox-input";
import styles from "./form-generator.module.scss";

const appSignal =
  process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "prod"
    ? new Appsignal({
        key:
          process.env.REACT_APP_ENV === "dev"
            ? process.env.REACT_APP_APPSIGNAL_DEV
            : process.env.REACT_APP_APPSIGNAL,
      })
    : undefined;

const FormGenerator = ({ form }) => {
  const formRef = useRef();
  const history = useHistory();

  const [formKey, setFormKey] = useState(Math.random());

  const API_HOST = process.env.REACT_APP_URL;

  const [formInitialValues, setFormInitialValues] = useState();
  const [formValidation, setFormValidation] = useState();
  const [homeXAgreement, setHomeXAgreement] = useState(false);

  useEffect(() => {
    if (form && form.idea_template.ideas_sections) {
      const newInitFormValues = {};
      const newFormValidation = {};

      form.idea_template.ideas_sections.forEach((field) => {
        if (field.section_type === "image") {
          return;
        }

        const { id, section_type, field_required } = field;

        if (["checklist", "dropdown"].includes(section_type)) {
          newInitFormValues[id] = [];

          if (field_required) {
            newFormValidation[id] = Yup.array().required("Field is required");
          } else {
            newFormValidation[id] = Yup.array();
          }
        } else {
          newInitFormValues[id] = "";

          if (field_required) {
            newFormValidation[id] = Yup.string().required("Field is required");
          } else {
            newFormValidation[id] = Yup.string();
          }
        }

        if (section_type === "url") {
          const urlRegex =
            /^(https?:\/\/)?([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})([\/\w \.-]*)*\/?$/;

          newFormValidation[id] = newFormValidation[id]
            .matches(urlRegex, "Please, enter a valid url")
            .required("Field is required");
        }

        if (field.default) {
          newFormValidation[id] =
            newFormValidation[field.id].required("Field is required");
        }

        if (field_required && !newFormValidation[id]) {
          newFormValidation[id] =
            newFormValidation[id].required("Field is required");
        }
      });

      setFormInitialValues(newInitFormValues);
      setFormValidation(Yup.object().shape(newFormValidation));
    }
  }, [form]);

  const handleSubmit = async (values, actions) => {
    if (
      !homeXAgreement &&
      form &&
      (form.idea_template.council_name_slug === "home_x" ||
        form.idea_template.council_name_slug === "firmenich")
    ) {
      window.alert(
        "Please acknowledge that you have read and agree to the HomeX terms and conditions"
      );
      return;
    }

    const payload = {
      submitter: {
        full_name: values.submitter_full_name,
        company: values.submitter_company,
        title: values.submitter_title,
        email: values.submitter_email,
      },
      idea: {
        ideas_template_id: form.idea_template.id,
        council_id: form.idea_template.council.council_id,
        name: values.name,
        topic_ids: values.topics,
        industry_ids: values.industries,
        descriptions: Object.entries(values)
          .filter(
            ([key]) =>
              key !== "name" &&
              key !== "topics" &&
              key !== "industries" &&
              key !== "submitter_full_name" &&
              key !== "submitter_company" &&
              key !== "submitter_title" &&
              key !== "submitter_email"
          )
          .map(([key, value]) => {
            if (Array.isArray(value)) {
              return {
                ideas_section_id: key,
                section_values: value,
              };
            }

            return {
              ideas_section_id: key,
              section_value: String(value),
            };
          }),
      },
    };

    try {
      await fetch(`${API_HOST}/api/v1/ideas_submissions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          council_id: form.idea_template.council.council_id,
        },
        body: JSON.stringify(payload),
      }).then((res) => {
        if (!res.ok) {
          const error = new Error(res.error_messages[0]);
          appSignal.sendError(error);
          history.push("/submission-form/error");
          return;
        }
        actions.resetForm();
        setFormKey(Math.random());
        history.push("/submission-form/submit-success");
      });
    } catch (e) {
      appSignal.sendError(e);
      history.push("/submission-form/error");
    }
  };

  const renderFields = (formikprops) => {
    if (
      !form.idea_template.ideas_sections ||
      form.idea_template.ideas_sections.length <= 0 ||
      !formInitialValues
    ) {
      return "";
    }

    return form.idea_template.ideas_sections.map((field) => {
      if (field.section_type === "checklist") {
        return (
          <CheckBoxInput
            field={field}
            formikprops={formikprops}
            key={field.id}
          />
        );
      }
      if (field.section_type === "dropdown") {
        return (
          <SelectInput field={field} formikprops={formikprops} key={field.id} />
        );
      }
      return <Input field={field} formikprops={formikprops} key={field.id} />;
    });
  };

  if (!form || !formInitialValues) {
    return "";
  }

  try {
    return (
      <div className={styles.form}>
        <div className={classnames(styles.row, styles.py3)}>
          <div className={styles.col}>
            <h3 className={styles.formTitle}>{form.name}</h3>
          </div>
        </div>

        <div className={classnames(styles.row, styles.pt0, styles.pb3)}>
          <div className={styles.col}>
            <h5 className={styles.formInstruction}>
              {form.description || "Please fill out the information below"}
            </h5>
          </div>
        </div>

        <div className={classnames(styles.row, styles.pt0, styles.pb3)}>
          <div className={styles.col}>
            <Formik
              innerRef={formRef}
              initialValues={formInitialValues}
              validationSchema={formValidation}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {(formikprops) => (
                <Form key={formKey} noValidate>
                  {renderFields(formikprops)}
                  {form.topics.length > 0 ? (
                    <SelectInput
                      field={{
                        id: "topics",
                        section_name: "Technologies",
                        placeholder: "Select Technologies",
                        section_option_values: form.topics,
                        multiselect: true,
                        childKey: "children_topics",
                        label: "Select technologies",
                        tagKeyName: "id",
                        parentKeyName: "parent_resource_topic_id",
                      }}
                      dropdownPosition="top"
                      formikprops={formikprops}
                      isAreaOfFocusField
                    />
                  ) : null}
                  {form.industries.length > 0 ? (
                    <SelectInput
                      field={{
                        id: "industries",
                        section_name: "Industries",
                        placeholder: "Select Industries",
                        section_option_values: form.industries,
                        multiselect: true,
                        label: "Select industries",
                        childKey: "children_industries",
                        tagKeyName: "id",
                        parentKeyName: "parent_resource_industry_id",
                      }}
                      dropdownPosition="top"
                      formikprops={formikprops}
                      isAreaOfFocusField
                    />
                  ) : null}
                  {(form.idea_template.council_name_slug === "home_x" ||
                    form.idea_template.council_name_slug === "firmenich") && (
                    <div className="mt-4">
                      <label
                        className={`${styles.formCheckLabel} d-flex align-items-center`}
                        htmlFor="homeXAgreement"
                      >
                        <Field
                          id="homeXAgreement"
                          name="homeXAgreement"
                          type="checkbox"
                          className={styles.formCheckInput}
                          value={homeXAgreement}
                          checked={homeXAgreement}
                          onChange={() => setHomeXAgreement(!homeXAgreement)}
                        />
                        <div className="ml-3">
                          Please acknowledge that you have read and agree to the{" "}
                          <a href="/homex-terms" target="_blank">
                            <b>HomeX terms and conditions</b>
                          </a>
                        </div>
                      </label>
                    </div>
                  )}
                  <div className={styles.submitBtnWrp}>
                    {form.idea_template.council_name_slug === "home_x" ||
                    form.idea_template.council_name_slug === "firmenich" ? (
                      <button
                        type="submit"
                        className={`${styles.submitBtn} ${
                          !homeXAgreement
                            ? styles.disabledBtn
                            : styles.activeBtn
                        }`}
                        disabled={!homeXAgreement}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className={`${styles.submitBtn} ${styles.activeBtn}`}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  } catch (e) {
    return "";
  }
};

export default FormGenerator;

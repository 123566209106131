import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar, withSnackbar } from "notistack";
import { useHistory, useLocation } from 'react-router-dom';
import classNames from "classnames";

import { Loading } from 'modules/primitives/index';
import styles from './rightWindow.module.scss';
import { Pagination } from '../../../primitives';
import SimpleSearchComponent from './simpleSearch/simpleSearch.component';
import { PAGINATION_DEFAULTS, patientOrgLayoutList } from "../../constants";
import { searchInCouncil } from "modules/companies/companyMain/companyMain.action";
import { queryStringUrlReplacement } from '../../../../common/helper';
import { Icon, mdSize } from "../../../../common/icon";
import FlatOrganizationsList from "modules/patientOrganizations/patientOrganizationMain/flatPatientOrgList";
import style from "modules/companies/companyMain/rightWindow/newCompaniesList/newCompaniesList.module.scss";
import {
  setSelectedOrganizations
} from "modules/patientOrganizations/patientOrganizationMain/patientOrganizationMain.action";
import OrganizationBlock from "../patientOrgBlock";
import PatientOrgSelectedFilters from "modules/patientOrganizations/patientOrganizationMain/selectedFiltersList";
import { appModelSettingSet } from "application/app.action";
import AddCompanyComponent from "modules/companies/companyMain/addToProject/addCompany.component";
import PatientOrgDownload from "modules/patientOrganizations/patientOrganizationMain/patientOrgDownload";

const RightWindowComponent = ({ fullScreen }) => {
  const {
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: {
        patientOrganizationList,
        loading,
        patientOrganizationPageNr,
        checkedPatientFilters,
        countryFilters,
        selectedOrganizations,
      },
    },
  } = useSelector((state) => state);

  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selectLayout, setSelectLayout] = useState(location.state?.layout || 0);
  const [pageSetting, setPageSetting] = useState(PAGINATION_DEFAULTS);
  const [selectModeIsActive, setSelectModeIsActive] = useState(false);
  const [downloadPdfIsActive, setDownloadPdfIsActive] = useState(false);
  const history = useHistory();
  const redirectProp = history.location?.state?.query;

  useEffect(() => {
    if (
      location?.state?.page &&
      pageSetting.current !== location?.state?.page
    ) {
      setPageSetting({
        ...pageSetting,
        current: location?.state?.page,
      });
    }
  }, [location, pageSetting]);

  useEffect(() => {
    if (patientOrganizationPageNr) {
      setPageSetting({
        ...pageSetting,
        total: patientOrganizationPageNr.total,
        pageSize: selectLayout === 1 ? 50 : 10,
      });
    }

    return () => null;
  }, [patientOrganizationPageNr, selectLayout]);

  useEffect(() => {
    if (selectedCouncil && !redirectProp) {
      dispatch(
        searchInCouncil({
          data: '',
          topic_ids: [],
          industry_ids: [],
          council_id: selectedCouncil.id,
          disease_ids: checkedPatientFilters.disease_ids,
          diversity_group_ids: checkedPatientFilters.diversity_group_ids,
          patient_organization_ids: selectedOrganizations.map((c) => c.id),
          mode: 'companies',
          gsk: true,
          patient_org: true,
          countries: countryFilters,
          page: location?.state?.page || 1,
          sort_attribute: location.state?.sortAttribute || "",
          sort_order: location.state?.sortOrder || "",
          items: selectLayout === 1 ? 50 : 10,
          enqueueSnackbar,
          cb: () => {},
        })
      );
    }

    return () => null;
  }, [selectedCouncil, checkedPatientFilters, countryFilters, location]);

  const handlePagingChange = (page) => {
    const search = queryStringUrlReplacement(
      history.location.search,
      'page',
      page
    );

    history.push({
      pathname: '/patient_organizations',
      search,
      state: {
        ...history.location?.state,
        page: page,
      },
    });
  };

  const handleCrunchBase = () => {};

  const handleLayoutClick = useCallback(
    (layout) => {
      setSelectLayout(layout);

      const search = queryStringUrlReplacement(
        history.location.search,
        "layout",
        layout
      );

      history.push({
        pathname: "/patient_organizations",
        search,
        state: {
          ...history.location.state,
          layout,
        },
      });
    },
    [selectLayout]
  );

  const selectAll = () => {
    if (selectedOrganizations.length) {
      dispatch(
        setSelectedOrganizations({
          shouldReset: true,
          organizations: [],
        })
      );
    } else {
      dispatch(
        setSelectedOrganizations({
          organizations: patientOrganizationList,
        })
      );
    }
  };

  const handleMoreOptionClick = (value) => {
    switch (value) {
      case 1: {
        //do something
        break;
      }
      case 2: {
        //do something
        break;
      }
      case 3: {
        const payload = {
          title: "Add Patient Organizations To Project",
          show: true,
          height: "1000",
          width: "540",
          component: (
            <AddCompanyComponent
              companyIds={selectedOrganizations.map((c) => c.id)}
              enqueueSnackbar={enqueueSnackbar}
              multipleAdd
              type="patient_organizations"
            />
          ),
        };

        dispatch(
          appModelSettingSet({
            ...modalSetting,
            ...payload,
          })
        );
        break;
      }
      default:
        return;
    }
  };

  const handleSelectMode = () => {
    setSelectModeIsActive(!selectModeIsActive);

    if (!selectModeIsActive) {
      dispatch(
        setSelectedOrganizations({
          organizations: [],
        })
      );
    }
  };

  const handleOrganizationSelect = (org) => {
    const orgIsSelected = selectedOrganizations.some((c) => c.id === org.id);

    if (orgIsSelected) {
      const selected = selectedOrganizations.filter((c) => c.id !== org.id);
      dispatch(
        setSelectedOrganizations({
          organizations: selected,
        })
      );
    } else {
      dispatch(
        setSelectedOrganizations({
          organizations: [...selectedOrganizations, org],
        })
      );
    }
  };

  return (
    <div className={`${styles.wrapper} ${fullScreen ? 'w-100' : ''}`} id="rightWindow">
      <div className={styles.searchWrapper}>
        <SimpleSearchComponent
          layout={selectLayout}
          handleAdvance={'handleAdvanceSearch'}
          handleCrunchBase={handleCrunchBase}
          gsk={true}
          patientOrg={true}
        />
        <PatientOrgDownload
          className={styles.reportBtns}
          location={location}
          countryFilters={countryFilters}
          checkedPatientFilters={checkedPatientFilters}
          setDownloadPdfIsActive={setDownloadPdfIsActive}
          selectedOrganizations={selectedOrganizations}
        />
      </div>
      {loading ? (
        <div className={styles.container}>
          <Loading />
        </div>
      ) : (
        <div id="downloadableSection" className={styles.container}>
          {
            patientOrganizationList.length === 0 ? (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <span className={styles.noResults}>No results found</span>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="d-flex align-items-center">
                    {downloadPdfIsActive ? (
                      <div className="d-flex flex-row align-items-center mt-2 flex-grow-1">
                        <div className={styles.logoWrp}>
                          <img
                            width="100"
                            height="100"
                            alt={selectedCouncil.name}
                            src={selectedCouncil.logo}
                          />
                        </div>
                        <h2 className="mb-0 bold mt-2 d-flex justify-content-center w-100">
                          Traction Report
                        </h2>
                      </div>
                    ) : (
                      patientOrgLayoutList.map(layout => (
                        <div key={layout.value} className={styles.gridIconWrp}>
                          <div className={styles.gridIconCon}>
                            <Icon
                              {...mdSize}
                              icon={layout.icon}
                              className={classNames(
                                styles.icon,
                                layout.value === selectLayout && styles.active
                              )}
                              onClick={() => {
                                handleLayoutClick(layout.value);
                              }}
                            />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                  {patientOrganizationPageNr &&
                    patientOrganizationPageNr.total !== 0 && (
                      <div className={styles.resultCon}>
                        {
                          selectModeIsActive && selectedOrganizations.length > 0
                            ? `${selectedOrganizations.length} Selected`
                            : `${patientOrganizationPageNr.total} Results`
                        }
                      </div>
                    )}
                </div>
                <PatientOrgSelectedFilters />
                <div className="d-flex align-items-center mb-3">
                  <span
                    className={style.selectMode}
                    onClick={handleSelectMode}
                  >
                    Select
                  </span>
                  {selectModeIsActive && (
                    <span
                      onClick={selectAll}
                      className={`${style.selectMode} ml-3`}
                    >
                      {selectedOrganizations.length
                        ? "Deselect All"
                        : "Select All"}
                    </span>
                  )}
                  {selectModeIsActive && selectedOrganizations.length > 0 ? (
                    <div className="d-flex align-items-center ml-5">
                      {/*<span*/}
                      {/*  className={`${style.selectMode} font-weight-bold`}*/}
                      {/*  onClick={() => handleMoreOptionClick(2)}*/}
                      {/*>*/}
                      {/*  Add to List*/}
                      {/*</span>*/}
                      <span
                        className={`${style.selectMode} font-weight-bold ml-3`}
                        onClick={() => handleMoreOptionClick(3)}
                      >
                        Add to Project
                      </span>
                      {/*<span*/}
                      {/*  className={`${style.selectMode} font-weight-bold ml-3`}*/}
                      {/*  onClick={() => handleMoreOptionClick(1)}*/}
                      {/*>*/}
                      {/*  Follow All*/}
                      {/*</span>*/}
                    </div>
                  ) : null}
                </div>
                <>
                  {
                    selectLayout === 0 ? (
                      selectModeIsActive && downloadPdfIsActive ? (
                        selectedOrganizations.map((organization) =>
                          <OrganizationBlock
                            key={organization.id}
                            organization={organization}
                            enqueueSnackbar={enqueueSnackbar}
                            selectModeIsActive={selectModeIsActive}
                            isSelected={selectedOrganizations.some(
                              (c) => c.id === organization.id
                            )}
                            handleOrganizationSelect={() =>
                              handleOrganizationSelect(organization)
                            }
                          />
                        )
                      ) : (
                        patientOrganizationList.map((organization) =>
                          <OrganizationBlock
                            key={organization.id}
                            organization={organization}
                            enqueueSnackbar={enqueueSnackbar}
                            selectModeIsActive={selectModeIsActive}
                            isSelected={selectedOrganizations.some(
                              (c) => c.id === organization.id
                            )}
                            handleOrganizationSelect={() =>
                              handleOrganizationSelect(organization)
                            }
                          />
                        )
                      )
                    ) : (
                      <FlatOrganizationsList
                        organizations={patientOrganizationList}
                        selectedOrganizations={selectedOrganizations}
                        selectModeIsActive={selectModeIsActive}
                        handleOrganizationSelect={handleOrganizationSelect}
                      />
                    )
                  }
                </>
              </>
            )
          }
          {pageSetting.total > 10 && (
            <div
              className={`w-100 d-flex justify-content-center mb-4 ${
                selectLayout === 1 ? 'mt-4' : ''
              }`}
            >
              <Pagination {...pageSetting} onChange={handlePagingChange} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(withSnackbar(RightWindowComponent));

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Switch from "react-switch";
import { useSnackbar } from "notistack";

import { Button, BtnType, Loading, Pagination } from "modules/primitives/index";
import { useSelector } from "react-redux";
import Icon from "common/icon/icon.component";
import UpdateStepPosition from "modules/admin/adminProjects/adminProjectWizard/addEditWizardStep/updateStepPosition";

import { httpPut, httpGet } from "../../../common/httpCall";
import styles from "./adminThemes.module.scss";
import { metricsArr } from "./adminThemes.constants";
import ProjectTableRow from "./tableRow/tableRow.component";

const AdminThemesProjectsTable = () => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const type = urlParams.get("type");
  const columnsNumberLimit = type === "list" ? 7 : 5;
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [tableColumnsValues, setTableColumnsValues] = useState([]);
  const [showMetricsDropdown, setShowMetricsDropdown] = useState(false);
  const [showProjFieldsDropdown, setShowProjFieldsDropdown] = useState(false);
  const [projMetricsArrValues, setProjMetricsArrValues] = useState({});
  const [projFieldsArrValues, setProjFieldsArrValues] = useState({});
  const [enabledFields, setEnabledFields] = useState([]);
  const [chunkToShow, setChunkToShow] = useState([]);
  const [pageSetting, setPageSetting] = useState({
    pageSize: 20,
    current: 1,
    hideOnSinglePage: true,
    showTitle: false,
    total: 0,
  });
  function findFirstEmptyPosition(columns) {
    const positions = columns.map((col) => col.position).sort((a, b) => a - b);

    let pos;
    for (let i = 1; i <= positions.length; i++) {
      if (!positions.includes(i)) {
        pos = i;
        break;
      }
    }
    if (pos) {
      return pos;
    }
    return Math.max(...positions) + 1;
  }

  useEffect(() => {
    setPageSetting((prev) => ({
      ...prev,
      total: Object.keys(projFieldsArrValues).length,
    }));
    setChunkToShow(
      Object.keys(projFieldsArrValues).slice(
        (pageSetting.current - 1) * pageSetting.pageSize,
        pageSetting.current * pageSetting.pageSize
      )
    );
  }, [projFieldsArrValues]);

  const handlePagingChange = (page) => {
    if (page !== pageSetting.current) {
      setChunkToShow(
        Object.keys(projFieldsArrValues).slice(
          (page - 1) * pageSetting.pageSize,
          page * pageSetting.pageSize
        )
      );
      setPageSetting((prev) => ({
        ...prev,
        current: page,
      }));
    }
  };

  const handleGetTableRows = async () => {
    setLoading(true);
    await httpGet({
      call: `theme_project_options?view_type=${type}`,
    })
      .pipe()
      .subscribe(
        (res) => {
          setLoading(false);
          setTableColumnsValues(
            res.response.filter((item) => {
              if (
                item.column_type !== "project_metric" &&
                item.column_type !== "project_field"
              ) {
                if (item.display) {
                  setEnabledFields((prev) => [
                    ...prev,
                    {
                      column_type: item?.column_type,
                      position: item?.position,
                    },
                  ]);
                }
              }
              return (
                item.column_type !== "project_metric" &&
                item.column_type !== "project_field"
              );
            })
          );

          setProjFieldsArrValues((prev) => {
            return {
              ...prev,
              ...res.response
                .filter((item) => item.column_type === "project_field")
                .reduce((acc, item) => {
                  if (item.display) {
                    setShowProjFieldsDropdown(true);
                    setEnabledFields((prev) => [
                      ...prev,
                      {
                        column_type: item?.project_field_template?.field_name,
                        position: item?.position,
                      },
                    ]);
                  }
                  return {
                    ...acc,
                    [item.project_field_template_id]: {
                      display: item.display,
                      field_name: item?.project_field_template?.field_name,
                      position: item?.position,
                      view_type: type,
                      project_field_template_id:
                        item?.project_field_template_id,
                    },
                  };
                }, {}),
            };
          });
          setProjMetricsArrValues((prev) => {
            return {
              ...prev,
              ...res.response
                .filter((item) => item.column_type === "project_metric")
                .reduce((acc, item) => {
                  if (item.display) {
                    setShowMetricsDropdown(true);
                    setEnabledFields((prev) => [
                      ...prev,
                      {
                        column_type: item.project_metric_attribute,
                        position: item?.position,
                      },
                    ]);
                  }
                  return {
                    ...acc,
                    [item.project_metric_attribute]: {
                      display: item.display,
                      view_type: type,
                      position: item?.position,
                    },
                  };
                }, {}),
            };
          });
          return res;
        },
        () => {
          setLoading(false);
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        }
      );
  };

  useEffect(() => {
    try {
      handleGetTableRows();
      metricsArr.map((item) => {
        setProjMetricsArrValues((prev) => {
          return {
            ...prev,
            [item.key]: { display: false },
          };
        });
      });
    } catch (err) {
      console.log("error on get stages", err);
    }
  }, []);

  const handleEditTable = async () => {
    setLoading(true);
    const projMetricsArrValuesArr = Object.keys(projMetricsArrValues).map(
      (item) => {
        return {
          column_type: "project_metric",
          display: projMetricsArrValues[item]?.display,
          project_metric_attribute: item,
          view_type: type,
          position: !projMetricsArrValues[item]?.position
            ? null
            : projMetricsArrValues[item]?.position,
        };
      }
    );
    const projFieldsArrValuesArr = Object.keys(projFieldsArrValues).map(
      (item) => {
        return {
          column_type: "project_field",
          display: projFieldsArrValues[item]?.display,
          project_field_template_id: item,
          view_type: type,
          position: !projFieldsArrValues[item]?.position
            ? null
            : projFieldsArrValues[item]?.position,
        };
      }
    );
    const nullablePosition = tableColumnsValues.map((item) => {
      return { ...item, position: item?.position || null };
    });

    await httpPut({
      call: `theme_project_options/create_or_update`,
      data: {
        theme_project_options: [
          ...nullablePosition,
          ...projMetricsArrValuesArr,
          ...projFieldsArrValuesArr,
        ],
      },
    })
      .pipe()
      .subscribe((res) => {
        setLoading(false);
        enqueueSnackbar("Themes projects table columns edited with success", {
          variant: "success",
        });
        setTableColumnsValues(res.response);

        return res;
      });
  };

  const changeEnableLimit = (el) => {
    if (enabledFields.length >= columnsNumberLimit && !el.display) {
      enqueueSnackbar(
        "You've hit the limit for columns in this table. Please turn another field Off in order to add a new one.",
        {
          variant: "error",
        }
      );
      return false;
    }
    if (el.display) {
      setEnabledFields((prev) => {
        return prev.filter((item) => {
          if (el.project_field_template_id) {
            return item.column_type !== el.field_name;
          }
          return item.column_type !== el.column_type;
        });
      });
    } else {
      setEnabledFields((prev) => {
        if (prev.length) {
          return [
            ...prev,
            {
              column_type: el.field_name || el.column_type,
              position: el?.position || findFirstEmptyPosition(prev),
            },
          ];
        }
        return { column_type: el.field_name || el.column_type, position: 1 };
      });
    }
    return true;
  };

  const setRowPropValue = (value, el, prop, columntype) => {
    if (
      prop === "display" &&
      columntype !== "project_metric" &&
      !changeEnableLimit(el)
    ) {
      return;
    }

    if (columntype === "project_field") {
      if (prop === "display") {
        setProjFieldsArrValues((prev) => {
          return {
            ...prev,
            [el.project_field_template_id]: {
              ...prev[el.project_field_template_id],
              position: value ? findFirstEmptyPosition(enabledFields) : null,
              display: !prev[el.project_field_template_id].display,
            },
          };
        });
      }
      if (prop === "position") {
        setProjFieldsArrValues((prev) => {
          return {
            ...prev,
            [el.project_field_template_id]: {
              ...prev[el.project_field_template_id],
              position: Number(value),
            },
          };
        });
      }

      return;
    }

    if (columntype === "project_metric") {
      if (prop === "display") {
        setProjMetricsArrValues((prev) => {
          return {
            ...prev,
            [el.key]: {
              ...prev[el.key],
              position: value ? findFirstEmptyPosition(enabledFields) : null,
              display: !prev[el.key].display,
            },
          };
        });
      }
      if (prop === "position") {
        setProjMetricsArrValues((prev) => {
          return {
            ...prev,
            [el.key]: {
              ...prev[el.key],
              position:
                prop === "position" ? Number(value) : prev[el.key].position,
            },
          };
        });
      }

      return;
    }
    setTableColumnsValues((prev) => {
      return prev.map((item) => {
        if (item.column_type === el.column_type) {
          if (prop === "display") {
            return {
              ...item,
              display: !item.display,
              position: value ? findFirstEmptyPosition(enabledFields) : null,
            };
          }
          if (prop === "position") {
            return {
              ...item,
              position: value,
            };
          }
        }
        return item;
      });
    });
  };
  const handlePositionUpdate = async (item) => {
    const projMetricsArrValuesArr = Object.keys(projMetricsArrValues).map(
      (item, i) => {
        return {
          column_type: "project_metric",
          display: projMetricsArrValues[item]?.display,
          project_metric_attribute: item,
          view_type: type,
          position: projMetricsArrValues[item]?.position,
        };
      }
    );

    const projFieldsArrValuesArr = Object.keys(projFieldsArrValues).map(
      (item, i) => {
        return {
          column_type: "project_field",
          display: projFieldsArrValues[item]?.display,
          project_field_template_id: item,
          view_type: type,
          position: projFieldsArrValues[item]?.position,
        };
      }
    );

    const nullablePosition = tableColumnsValues.map((item) => {
      return { ...item, position: item?.position || null };
    });

    await httpPut({
      call: `theme_project_options/create_or_update`,
      data: {
        theme_project_options: [
          ...nullablePosition,
          ...projMetricsArrValuesArr,
          ...projFieldsArrValuesArr,
        ],
      },
    })
      .pipe()
      .subscribe((res) => {
        setLoading(false);
        enqueueSnackbar("Themes projects table columns edited with success", {
          variant: "success",
        });
        setTableColumnsValues(res.response);

        return res;
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <Link to="/admin" className={styles.rootText}>
            ADMIN
          </Link>
          <span className={styles.separationBar}> / </span>

          <Link to="/admin/themes" className={styles.rootText}>
            THEMES
          </Link>

          <span className={styles.separationBar}> / </span>
          {/*<span className={styles.activeBreadcrumbs}>GROUPS</span> 
          <span className={styles.separationBar}> / </span>*/}
          <Link to="/admin/themes/projectsTable" className={styles.rootText}>
            PROJECT TABLE
          </Link>
        </h4>
      </div>
      <div className={styles.bodyWrp}>
        <div className={styles.firstRow}>
          <div className={styles.titleWrp}>
            Select up to {columnsNumberLimit} additional columns
          </div>
        </div>
        <div className={styles.controlsTable}>
          <div className={styles.headerRow}>
            <div className={styles.firstColumn}>Project Table Columns</div>
            <div className={styles.secondColumn}>Hide/Show</div>
            <div className={styles.secondColumn}>Position</div>
          </div>
          {tableColumnsValues.length
            ? tableColumnsValues.map((item) => {
                if (
                  item?.column_type === "project_metric" ||
                  item?.column_type === "project_field"
                ) {
                  return null;
                }
                return (
                  <ProjectTableRow
                    item={item}
                    handlePositionUpdate={handlePositionUpdate}
                    setRowPropValue={setRowPropValue}
                    key={item.column_type}
                  />
                );
              })
            : null}
          {selectedCouncil?.traction_tag === "ford" ? (
            <div className={styles.tableRow}>
              <div className={styles.projectThemes}>
                <div className={styles.columnsContainer}>
                  <div className={styles.firstColumn}>Project Metrics</div>
                  <div
                    className={styles.secondColumn}
                    onClick={() => setShowMetricsDropdown((prev) => !prev)}
                  >
                    {" "}
                    <div className={styles.filterButton}>
                      <Icon
                        style={
                          showMetricsDropdown
                            ? { transform: "rotate(0deg)", color: "#2d83ae" }
                            : { transform: "rotate(-90deg)" }
                        }
                        size={10}
                        icon="icn-table-down-chevron"
                        className={styles.iconChevron}
                      />
                    </div>
                  </div>
                </div>
                {showMetricsDropdown && (
                  <>
                    {metricsArr?.map((item) => {
                      return (
                        <div className={styles.tableRow} key={item.name}>
                          <div className={styles.firstColumn}>{item.name}</div>
                          <div className={styles.secondColumn}>
                            {" "}
                            <Switch
                              uncheckedIcon={false}
                              checkedIcon={false}
                              checked={projMetricsArrValues[item.key]?.display}
                              onColor="#24a0ed"
                              onChange={(e) => {
                                if (
                                  !changeEnableLimit({
                                    ...projMetricsArrValues[item.key],
                                    column_type: item.key,
                                    position:
                                      findFirstEmptyPosition(enabledFields),
                                  })
                                ) {
                                  return;
                                }
                                // switchProjMetricValue(item.key);
                                setRowPropValue(
                                  e,
                                  item,
                                  "display",
                                  "project_metric"
                                );
                              }}
                            />
                          </div>
                          <div className={styles.thirdColumn}>
                            <div>
                              <input
                                id="from"
                                type="number"
                                min="1"
                                max="15"
                                value={
                                  projMetricsArrValues[item.key]?.position || ""
                                }
                                onChange={(e) => {
                                  setRowPropValue(
                                    e?.target?.value,
                                    item,
                                    "position",
                                    "project_metric"
                                  );
                                }}
                              />
                            </div>
                            <UpdateStepPosition
                              item={item}
                              handleUpdate={handlePositionUpdate}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          ) : null}
          <div className={styles.tableRow}>
            <div className={styles.projectThemes}>
              <div className={styles.columnsContainer}>
                <div className={styles.firstColumn}>Project Fields</div>
                <div
                  className={styles.secondColumn}
                  onClick={() => setShowProjFieldsDropdown((prev) => !prev)}
                >
                  {" "}
                  <div className={styles.filterButton}>
                    <Icon
                      style={
                        showProjFieldsDropdown
                          ? { transform: "rotate(0deg)", color: "#2d83ae" }
                          : { transform: "rotate(-90deg)" }
                      }
                      size={10}
                      icon="icn-table-down-chevron"
                      className={styles.iconChevron}
                    />
                  </div>
                </div>
              </div>
              {showProjFieldsDropdown && (
                <>
                  {chunkToShow?.map((item) => {
                    return (
                      <div
                        className={styles.tableRow}
                        key={projFieldsArrValues[item].field_name}
                      >
                        <div className={styles.firstColumn}>
                          {projFieldsArrValues[item].field_name}
                        </div>
                        <div className={styles.secondColumn}>
                          {" "}
                          <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={projFieldsArrValues[item].display}
                            onColor="#24a0ed"
                            onChange={(e) => {
                              setRowPropValue(
                                e,
                                projFieldsArrValues[item],
                                "display",
                                "project_field"
                              );
                              // switchProjFieldValue(item, value);
                            }}
                          />
                        </div>
                        <div className={styles.thirdColumn}>
                          <div>
                            <input
                              id="from"
                              type="number"
                              min="1"
                              max="15"
                              value={projFieldsArrValues[item]?.position || ""}
                              onChange={(e) => {
                                setRowPropValue(
                                  e?.target?.value,
                                  item,
                                  "position",
                                  "project_field"
                                );
                              }}
                            />
                          </div>
                          <UpdateStepPosition
                            item={item}
                            handleUpdate={handlePositionUpdate}
                          />
                        </div>
                      </div>
                    );
                  })}
                  {pageSetting.total > 20 ? (
                    <div className={styles.paginationCon}>
                      <Pagination
                        {...pageSetting}
                        onChange={handlePagingChange}
                      />
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.updateCouncilContainer}>
          {loading ? (
            <Loading />
          ) : (
            <Button
              className={styles.addNew}
              btn={BtnType.REGULAR}
              icon="icn-add"
              onClick={() => handleEditTable()}
            >
              Update Table
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default AdminThemesProjectsTable;

import React, { useState } from "react";
import Icon from "common/icon/icon.component";
import { smSize } from "common/icon/icon.constants";
import FiltersModalComponent from "./filtersModalComponent";
import styles from "./styles.module.scss";

export const FiltersRow = (props) => {
  const {
    options,
    searchFn,
    filterOptionSearchResult,
    setFilterOption,
    handleRangeFilterValueSelect,
    specicStyles,
    selectedCouncilTag,
  } = props;
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className={`${styles.inputContainer}`} style={{ ...specicStyles }}>
      {showDropdown ? (
        <FiltersModalComponent
          options={options}
          searchFn={searchFn}
          filterOptionSearchResult={filterOptionSearchResult}
          setFilterOption={setFilterOption}
          handleRangeFilterValueSelect={handleRangeFilterValueSelect}
          setShowDropdown={setShowDropdown}
          selectedCouncilTag={selectedCouncilTag}
        />
      ) : null}
      <div
        className={styles.inputBox}
        onClick={() => {
          setShowDropdown(true);
        }}
      >
        <div>{"Filters"} </div>
        <Icon
          style={showDropdown ? { transform: "rotate(180deg)" } : null}
          {...smSize}
          icon="icn-table-down-chevron"
          className={styles.iconChevron}
        />{" "}
      </div>
    </div>
  );
};

export default FiltersRow;

import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Formik } from "formik";
import checkUniqName from "common/checkUniqName";
import checkDuplicateName from "common/checkDuplicateName";

import { Loading, Label, TextBox } from "modules/primitives/index";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { model } from "./constant";
import styles from "./styles.module.scss";
import UniqName from "../../../../common/popups/newItem/uniqName/uniqName";
import CustomFieldAgreementTypes from "./adminCustomFieldsAgreementTypes/adminCustomFieldsAgreementTypes.component";
import { getCustomFieldAgreementType } from "../adminCustomField.action";

const AdminCustomFieldsAgreement = (props) => {
  const {
    councilReducer: { selectedCouncil },
    adminCustomFieldsReducer: {
      customFields,
      customFieldGroups,
      loadingAgreementType,
      customFieldAgreementTypes,
    },
  } = useSelector((state) => state);
  const { formRef, handleOnSubmit, data, mode, uniqName, adminLocation, selectedCustomFieldGroup } =
    props;
  const dispatch = useDispatch();

  const [titleError, setTitleError] = useState("");
  const [initModel, setInitModel] = useState(model);
  const [checked, setChecked] = useState(data?.agreement_type_id ? data?.agreement_type_id : "");

  const { enqueueSnackbar } = useSnackbar();

  const [title, setTitle] = useState(data?.field_name ? data.field_name : "");
  const [isCustomTitle, setIsCustomTitle] = useState(false);

  useEffect(() => {
    dispatch(getCustomFieldAgreementType({ enqueueSnackbar }));
  }, [selectedCouncil]);

  useEffect(() => {
    if (data) {
      const newModel = {
        title: data.field_name,
        agreement_type_id: data.agreement_type_id,
        field_placeholder: data.field_placeholder || "",
      };
      if (data?.field_name.length) {
        setIsCustomTitle(true);
      }
      setInitModel(newModel);
    }
  }, [data]);

  const filterSelectedAgreementTypes = () => {
    const selectedAgreementTypesArray = [];
    customFields.forEach((customField) => {
      if (customField.field_type === "agreement") {
        customFieldAgreementTypes.forEach((CFAgreementType) => {
          if (CFAgreementType?.id === customField.agreement_type_id) {
            selectedAgreementTypesArray.push(customField.agreement_type_id);
          }
        });
      }
    });
    return selectedAgreementTypesArray;
  };
  const selectedCompanyAgreements = useMemo(
    () => filterSelectedAgreementTypes(),
    [customFieldAgreementTypes]
  );
  const onSubmit = (val) => {
    let cfGroup = [];
    let field_name = "";
    if (selectedCustomFieldGroup && selectedCustomFieldGroup[0]) {
      const currentCFGroup = customFieldGroups.find((elem) => {
        if (elem.custom_field_group_id === selectedCustomFieldGroup[0].custom_field_group_id) {
          return elem;
        }
      });
      cfGroup = currentCFGroup.custom_field_templates;
    }
    if (!title.length) {
      field_name = checkDuplicateName(cfGroup, "agreement");
    } else if (
      !checkUniqName(title, cfGroup) &&
      title?.trim()?.toLowerCase() !== data?.field_name?.trim()?.toLowerCase()
    ) {
      setTitleError("Field name must be unique. Please rename this field");
      return;
    }
    setTitleError("");
    const payload = {
      ...val,
      field_type: model.type || "agreement",
      agreement_type_id: val?.agreement_type_id || checked?.id,
      title: field_name || title,
    };

    handleOnSubmit(payload);
  };

  const handleSelection = useCallback(
    (val) => {
      if (checked === val.id) {
        setChecked("");
      } else {
        if (!isCustomTitle) {
          setTitle(val.name);
        }
        setChecked(val.id);
      }
    },
    [checked, isCustomTitle]
  );
  // const selectAll = () => {
  //   const agreementNames = [];
  //   customFieldAgreementTypes.map((val) => {
  //     agreementNames.push(val.name);
  //   });
  //   setChecked(agreementNames);
  //   setTitle("Agreement");
  //   if (!fromAdmin) {
  //     setDataForValidation({ title: "Agreement" });
  //   }
  // };

  // const deselectAll = () => {
  //   setChecked([]);
  //   if (!fromAdmin) {
  //     setDataForValidation({ title: "" });
  //   }
  // };
  return (
    <div className={styles.wrapper}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        validationSchema={null}
        initialValues={initModel}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          onSubmit(values);
          resetForm();
        }}
      >
        {({ handleSubmit, setFieldValue, values, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <Label>Title</Label>
            <input
              type="text"
              name="title"
              placeholder="Edit Agreement Type"
              value={title}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setIsCustomTitle(true);
                } else {
                  setIsCustomTitle(false);
                }
                setTitle(e.target.value);
              }}
              className={classNames(styles.agreementNameTextBox)}
            />
            {titleError.length > 1 && (
              <div className={styles.errorBox}>
                <sup>*</sup>
                {titleError}
              </div>
            )}
            <UniqName uniqName={uniqName} />
            {!adminLocation && (
              <div className="d-flex flex-column align-items-start">
                <Label>Instructions (Optional)</Label>
                <TextBox
                  type="text"
                  name="field_placeholder"
                  placeholder="Add instructions"
                  formProps={{ ...formikprops, values, setFieldValue }}
                  className={styles.textBox}
                />
              </div>
            )}
            <>
              <div className={styles.editDefautOptionsContainer}>
                <Label>
                  Select the document checkbox options that should appear in this field on Company
                  Details pages.
                </Label>

                {/* Will be used in future versions */}
                {/* {fromAdmin && (
                  <span
                    className={styles.selectAllButton}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      checked.length ? deselectAll() : selectAll();
                    }}
                  >
                    {" "}
                    {checked.length ? "Deselect All" : "Select All"}
                  </span>
                )} */}
              </div>
              {loadingAgreementType ? (
                <Loading />
              ) : (
                <div className={styles.checkWrapper}>
                  {customFieldAgreementTypes?.map((agreementTypeElement) => (
                    <CustomFieldAgreementTypes
                      initialAgreementType={agreementTypeElement}
                      handleSelection={handleSelection}
                      checked={checked}
                      mode={mode}
                      existingRecords={data?.existing_records}
                      setFieldValue={setFieldValue}
                      key={agreementTypeElement.id}
                      selectedCompanyAgreements={selectedCompanyAgreements}
                    />
                  ))}
                </div>
              )}
            </>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default React.memo(AdminCustomFieldsAgreement);

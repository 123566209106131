import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { Icon, smSize } from "../../../../../common/icon";
import { CheckBox, CheckType } from "../../../../primitives";
import FilterItemComponent from "../filerItem/filterItem.component";
import styles from "./filterRow.module.scss";
import { CLEAR_ITEMS } from "../leftWindow.constant";
import {
  queryParamStringToArray,
  queryStringUrlReplacement,
} from "../../../../../common/helper";

const propToUse = {
  topic: "children_topics",
  industry: "children_industries",
  k_country: "items",
};

const FilterRowComponent = ({
  item,
  level,
  expan,
  items,
  type,
  handleCheck,
  isChecked,
  handleExpand,
  topicQuery,
  industryQuery,
  businessUnitQuery,
  history,
  noPadding,
  kyndrylCountry,
}) => {
  const [clearSettings, setClearSettings] = useState(null);
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    if (level === 1 && type === "k_country") {
      if (kyndrylCountry?.length) {
        setClearSettings(CLEAR_ITEMS.filter((item) => item.id === "all"));
      } else {
        setClearSettings(null);
      }

      return;
    }

    if (level === 0 && items) {
      if (
        (type === "business_unit" && !businessUnitQuery)
        || (type === "topic" && !topicQuery)
        || (type === "industry" && !industryQuery)
      ) {
        setClearSettings(null);

        return;
      }

      const hasChildren = items.some((item) => item[propToUse[type]]?.length);

      if (hasChildren) {
        const hasGrandChildren = items.some((item) => item[propToUse[type]]?.some((i) => i[propToUse[type]]?.length));

        if (hasGrandChildren) {
          setClearSettings(CLEAR_ITEMS);
        } else {
          const filteredSettings = CLEAR_ITEMS.filter(
            (item) => item.id !== "level3",
          );
          setClearSettings(filteredSettings);
        }
      } else {
        const filteredSettings = CLEAR_ITEMS.filter(
          (item) => item.id === "all",
        );
        setClearSettings(filteredSettings);
      }
    }

    return () => null;
  }, [level, items, type, topicQuery, industryQuery, businessUnitQuery]);

  useEffect(() => {
    setCollapse(expan);
  }, [expan]);

  useEffect(() => {
    if (level > 0 && type !== "k_country") {
      setCollapse(false);
    }
  }, [level, type]);

  const handleCollapseClick = () => setCollapse(!collapse);

  const handleOnChange = (val, item, category) => {
    if (!handleCheck) return;

    handleExpand(category);

    let isParent;

    if (category === "k_country") {
      isParent = level === 1;
    } else {
      isParent = category === "topic"
        ? item.children_topics?.length > 0
        : item.children_industries?.length > 0;
    }

    handleCheck(val, item, category, isParent);
  };

  // FUCKING HELL
  const handleClearSetting = (setting) => {
    let value = "";
    const categoryByType = {
      industry: industryQuery,
      topic: topicQuery,
      business_unit: businessUnitQuery,
      k_country: kyndrylCountry,
    };
    const arr = queryParamStringToArray(categoryByType[type] || "");

    if (type === "k_country") {
      value = "";
    }

    if (setting.id === "level1") {
      const idsByLevel = items.map((i) => i.id);

      value = arr
        .filter((id) => !idsByLevel.includes(id))
        .reduce((acc, id) => `${acc}${acc.length ? "," : ""}${id}`, "");
    }

    if (setting.id === "level2") {
      const idsByLevel = items.reduce((acc, item) => {
        if (!item[propToUse[type]]) {
          return acc;
        }

        return [...acc, ...item[propToUse[type]].map((i) => i.id)];
      }, []);

      value = arr
        .filter((id) => !idsByLevel.includes(id))
        .reduce((acc, id) => `${acc}${acc.length ? "," : ""}${id}`, "");
    }

    if (setting.id === "level3") {
      const idsByLevel = items.reduce((acc, item) => {
        if (!item[propToUse[type]]) {
          return acc;
        }

        const lowerLevelIds = item[propToUse[type]].reduce(
          (acc1, item1) => [
            ...acc1,
            ...item1[propToUse[type]].map((i) => i.id),
          ],
          [],
        );

        return [...acc, ...lowerLevelIds];
      }, []);

      value = arr
        .filter((id) => !idsByLevel.includes(id))
        .reduce((acc, id) => `${acc}${acc.length ? "," : ""}${id}`, "");
    }

    const split = value ? value.split(",") : [];

    if (arr?.length && split?.length === arr?.length) {
      return;
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      type,
      value,
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        [type]: value,
        page: 1,
      },
    });
  };

  return (
    <div
      id={level === 0 ? type : ""}
      className={`${styles.itemWrapper} ${
        level === 0 ? styles.withBorder : ""
      }`}
    >
      {level === 1 && type === "k_country" && clearSettings && (
        <div className={styles.filterSettings}>
          <span className={styles.filtersLabel}>Clear countries</span>
          <div className="d-flex align-items-center">
            {[{ name: "All", id: "all" }].map((setting) => (
              <span
                className={styles.clearOption}
                key={`${type}-${setting.id}`}
                onClick={() => handleClearSetting(setting)}
              >
                {setting.name}
              </span>
            ))}
          </div>
        </div>
      )}
      <div
        className={styles.itemTitle}
        style={{
          paddingLeft: noPadding
            ? 0
            : type === "k_country" && level !== 1
              ? "30px"
              : `${30 * level}px`,
          height: noPadding ? "auto" : "48px",
        }}
      >
        {level > 0 && (
          <CheckBox
            checkType={CheckType.BLUE}
            isChecked={isChecked}
            onChange={(val) => handleOnChange(val, item, type)}
          />
        )}
        <span onClick={handleCollapseClick}>{item.name}</span>
        {items && items.length > 0 && (
          <Icon
            {...smSize}
            icon="icn-table-down-chevron"
            className={classNames(
              styles.icon,
              !collapse ? styles.collapse : "",
            )}
            onClick={handleCollapseClick}
          />
        )}
      </div>
      {items && collapse ? (
        <>
          {level === 0 && clearSettings && (
            <div className={styles.filterSettings}>
              {
                clearSettings?.length === 1 && clearSettings[0]?.id === 'all' ? (
                  <span
                    className={styles.clearOption}
                    onClick={() => handleClearSetting({id: 'all'})}
                  >
                    Clear tags
                  </span>
                ) : (
                  <>
                    <span className={styles.filtersLabel}>Clear tags</span>
                    <div className="d-flex align-items-center">
                      {clearSettings.map((setting) => (
                        <span
                          className={styles.clearOption}
                          key={`${type}-${setting.id}`}
                          onClick={() => handleClearSetting(setting)}
                        >
                          {setting.name}
                        </span>
                        ))}
                      </div>
                  </>
                )
              }
            </div>
          )}
          <FilterItemComponent
            items={items}
            level={level + 1}
            type={type}
            handleExpand={handleExpand}
            item={item}
            topicQuery={topicQuery}
            industryQuery={industryQuery}
            businessUnitQuery={businessUnitQuery}
            kyndrylCountry={kyndrylCountry}
            history={history}
          />
        </>
      ) : null}
    </div>
  );
};

export default React.memo(FilterRowComponent);

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import Skeleton from "@mui/material/Skeleton";
import { getCookie } from "../../../../common/helper";

import styles from "./leftWindow.module.scss";
import { Button, BtnType, TextBox } from "../../../primitives";
import { useModelPopup } from "../../../../common/hooks/index";
import { Icon, smSize } from "../../../../common/icon";
import groupIcon from "../../../../assets/icn-group.svg";
import groupIconWhite from "../../../../assets/icn-group-white.svg";
import lockIcon from "../../../../assets/icn-lock.svg";
import lockIconWhite from "../../../../assets/icn-lock-white.svg";
import {
  companySavedListGetAttempt,
  setSelectedList,
  clearCreatedList,
  searchCrunchBaseClear,
  setScrolledTop,
  searchListsByQueryAttempt,
} from "../../store/lists.actions";
import SaveSearchComponent from "../../../companies/companyMain/rightWindow/saveSearch";

const LeftWindowComponent = () => {
  const history = useHistory();
  const popup = useModelPopup();
  const [myListCollapse, setMyListCollapse] = useState(true);
  const [sharedListCollapse, setSharedListCollapse] = useState(true);
  const dispatch = useDispatch();
  const [listPage, setListPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [listSearchValue, setLIstSearchValue] = useState("");
  const user_id = getCookie("user_id_");

  const handleMyListCollapseClick = () => {
    setMyListCollapse((prev) => !prev);
  };

  const handleSharedListCollapseClick = () => {
    setSharedListCollapse((prev) => !prev);
  };

  const handleSearchLists = (text) => {
    const delayTimer = setTimeout(() => {
      if (text) {
        dispatch(
          searchListsByQueryAttempt({
            query: text,
            page: listPage,
            enqueueSnackbar,
          })
        );
      } else {
        dispatch(
          companySavedListGetAttempt({
            page: listPage,
            enqueueSnackbar,
          })
        );
      }

      clearTimeout(delayTimer);
    }, 1000);
  };

  const {
    authReducer: { session },
    listsMainReducer: {
      loading,
      savedCompaniesLists,
      shouldSavedListUpdate,
      selectedList,
      listPageNr,
      listLoading,
      errorHandled,
      isScrolled,
      loadingSearchList,
    },
  } = useSelector((state) => state);

  const onScrollEventFunc = (event) => {
    const scrolled = event.target.scrollTop;
    if (scrolled > 0 && !isScrolled) {
      dispatch(setScrolledTop(true));
    } else if (scrolled === 0 && isScrolled) {
      dispatch(setScrolledTop(false));
    }
  };

  const handleSelectList = (id) => {
    if (id !== selectedList) {
      dispatch(searchCrunchBaseClear({}));
      dispatch(setSelectedList({ id }));
      history.push(`/list/${id}`);
    }
  };

  useEffect(() => {
    if (errorHandled) {
      popup.hide();
      setTimeout(() => {
        history.go("list/following");
      }, 3000);
    }
  }, [errorHandled]);

  const handleSaveHide = () => {
    popup.hide();
    dispatch(clearCreatedList());
  };

  const handleCreateNewList = () => {
    popup.show({
      title: "Create New List",
      component: (
        <SaveSearchComponent handleSaveHide={handleSaveHide} mode="empty" />
      ),
    });
  };

  const handleOpenFollowingList = () => {
    history.push("/list/following");
  };

  const handleNextPageClick = () => {
    if (listPage + 1 <= listPageNr) {
      setListPage(listPage + 1);
    } else {
      alert("You have reached the last page");
    }
  };

  const handlePrevPageClick = () => {
    if (listPage - 1 > 0) {
      setListPage(listPage - 1);
    } else {
      alert("This is the first page");
    }
  };

  useEffect(() => {
    if (listPage && listSearchValue) {
      dispatch(
        searchListsByQueryAttempt({
          query: listSearchValue,
          page: listPage,
          enqueueSnackbar,
        })
      );
    }

    if (!listSearchValue) {
      dispatch(
        companySavedListGetAttempt({
          page: listPage,
          enqueueSnackbar,
        })
      );
    }

    if (listPage > 1 && !listSearchValue) {
      dispatch(
        companySavedListGetAttempt({
          page: listPage,
          enqueueSnackbar,
        })
      );
    }
  }, [listPage, listSearchValue]);

  useEffect(() => {
    if (shouldSavedListUpdate) {
      dispatch(
        companySavedListGetAttempt({
          page: listPage,
          enqueueSnackbar,
        })
      );
    }
  }, [shouldSavedListUpdate, listPage]);

  return (
    <div
      className={`${styles.wrapper} ${styles.withHeight}`}
      onScroll={onScrollEventFunc}
    >
      <div className={styles.container}>
        {!loading && (
          <div className={styles.listContainerWrapper}>
            <div className={styles.searchList}>
              <TextBox
                value={listSearchValue}
                onChange={(e) => {
                  setLIstSearchValue(e.target.value);
                  handleSearchLists(e.target.value);
                }}
                className={styles.searchCon}
                placeholder="Search Lists"
              />
            </div>
            <hr className={styles.sectionLine} />
            <div className={styles.myListHeader}>
              <span className={styles.myListTitle}>My Lists</span>
              <div className="d-flex align-items-center">
                <Button
                  icon="icn-add"
                  className="mr-2"
                  onClick={handleCreateNewList}
                  btn={BtnType.LINK}
                >
                  New List
                </Button>
                <Icon
                  {...smSize}
                  icon="icn-table-down-chevron"
                  className={classNames(
                    styles.icon,
                    !myListCollapse && styles.collapse
                  )}
                  onClick={handleMyListCollapseClick}
                />
              </div>
            </div>
            {myListCollapse && (
              <div className={styles.listWrapper}>
                <Button
                  onClick={handleOpenFollowingList}
                  className={[
                    styles.itemTitle,
                    styles.followingList,
                    selectedList === "following" && styles.selectedList,
                  ]}
                  btn={
                    selectedList === "following"
                      ? BtnType.REGULAR
                      : BtnType.FRAME_LESS
                  }
                >
                  Following
                </Button>
                {listLoading || loadingSearchList ? (
                  <div className={styles.listLoader}>
                    <Skeleton animation="wave" height={20} width="60%" />
                    <Skeleton animation="wave" height={20} width="60%" />
                  </div>
                ) : savedCompaniesLists ? (
                  savedCompaniesLists.map(
                    (list) =>
                      list.creator?.id === user_id && (
                        <div
                          key={list.id}
                          className={classNames(
                            styles.itemTitle,
                            selectedList === list?.id && styles.selectedList
                          )}
                          onClick={() => handleSelectList(list?.id)}
                        >
                          <span>{list?.name}</span>
                          {!list.shared ? (
                            <Tooltip title="Private">
                              <div>
                                <img
                                  className={styles.groupIcon}
                                  src={
                                    selectedList === list.id
                                      ? lockIconWhite
                                      : lockIcon
                                  }
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={`Created by: ${list.creator.full_name}`}
                            >
                              <div>
                                <img
                                  className={styles.groupIcon}
                                  src={
                                    selectedList === list.id
                                      ? groupIconWhite
                                      : groupIcon
                                  }
                                />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      )
                  )
                ) : null}
              </div>
            )}
            <hr className={styles.sectionLine} />

            <div className={styles.sharedListHeaderBlock}>
              <h4 className={styles.sharedListHeader}>Shared With Me</h4>
              <Icon
                {...smSize}
                icon="icn-table-down-chevron"
                className={classNames(
                  styles.icon,
                  !sharedListCollapse && styles.collapse
                )}
                onClick={handleSharedListCollapseClick}
              />
            </div>
            {listLoading || loadingSearchList ? (
              <div className={styles.listWrapper}>
                <div className={styles.listLoader}>
                  <Skeleton animation="wave" height={20} width="60%" />
                  <Skeleton animation="wave" height={20} width="60%" />
                </div>
              </div>
            ) : (
              <div className={styles.listWrapper}>
                {savedCompaniesLists &&
                  sharedListCollapse &&
                  savedCompaniesLists.map(
                    (list) =>
                      list.creator?.id !== user_id && (
                        <div
                          key={list.id}
                          className={classNames(
                            styles.itemTitle,
                            selectedList === list.id && styles.selectedList
                          )}
                          onClick={() => handleSelectList(list.id)}
                        >
                          <span>{list.name}</span>
                          {!list.shared ? (
                            <Tooltip title="Private">
                              <div>
                                <img
                                  alt="Private"
                                  className={styles.groupIcon}
                                  src={
                                    selectedList === list.id
                                      ? lockIconWhite
                                      : lockIcon
                                  }
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={`Created by: ${list.creator.full_name}`}
                            >
                              <div>
                                <img
                                  alt={`Created by: ${list.creator.full_name}`}
                                  className={styles.groupIcon}
                                  src={
                                    selectedList === list.id
                                      ? groupIconWhite
                                      : groupIcon
                                  }
                                />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      )
                  )}
              </div>
            )}
            <hr className={styles.sectionLine} />
            {listPageNr && listPageNr > 1 ? (
              <div className={styles.listPagination}>
                <Icon
                  key={listPage}
                  icon="icn-left-prev"
                  className={
                    listPage - 1 > 0 ? styles.activeButton : styles.disabled
                  }
                  onClick={handlePrevPageClick}
                />
                <Icon
                  icon="icn-right-next"
                  className={
                    listPage + 1 <= listPageNr
                      ? styles.activeButton
                      : styles.disabled
                  }
                  onClick={handleNextPageClick}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(LeftWindowComponent);

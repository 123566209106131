import React, { memo, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import NewCompanyItemWrapperComponent from "./newCompanyItemWrapper";
import style from "./newCompaniesList.module.scss";
import { Icon, smSize } from "../../../../../common/icon";
import {
  queryStringUrlReplacement,
  scrollStop,
} from "../../../../../common/helper";
import LoadingComponent from "../../../../primitives/loading/loading.component";
import DoubleScrollbar from "../../../../../common/components/horizontalDoubleSrollBar";

const NewCompaniesList = ({
  companies = [],
  handleRelationShipChange,
  selectModeIsActive,
  handleCompanySelect,
  selectedCompanies,
  downloadPdfIsActive,
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);

  const isHPCouncil = selectedCouncil.name === "HP";
  const isIDBCouncil = selectedCouncil.name === "IDB";
  const currentYear = new Date().getFullYear();
  const [sortingConfig, setSortingConfig] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [userIsScrolling, setUserIsScrolling] = useState(false);

  useEffect(() => {
    document.querySelector("#rightWindow").addEventListener(
      "scroll",
      () => {
        setUserIsScrolling(true);
      },
      false
    );

    scrollStop(
      "#rightWindow",
      () => {
        setUserIsScrolling(false);
      },
      1000
    );

    return () => {
      const elem = document.querySelector("#rightWindow");

      if (elem) {
        elem.removeEventListener("scroll", null);
      }
    };
  }, []);

  useEffect(() => {
    if (
      location.state?.sortOrder &&
      sortingConfig?.order !== location.state?.sortOrder
    ) {
      setShowLoading(true);
    } else {
      const timeout = setTimeout(() => {
        setShowLoading(false);
        clearTimeout(timeout);
      }, 1000);
    }
  }, [location.state?.sortAttribute, location.state?.sortOrder, sortingConfig]);

  useEffect(() => {
    setSortingConfig({
      activeColumn: location.state?.sortAttribute || null,
      order: location.state?.sortOrder || null,
    });
  }, [location.state?.sortAttribute, location.state?.sortOrder]);

  const drawSortingIcon = useCallback(
    (col) => {
      if (!sortingConfig) return null;

      if (sortingConfig?.activeColumn === col) {
        return (
          <>
            <div
              className={classNames(
                style.sortIcon,
                sortingConfig.order === "desc" && style.sortAsc
              )}
            >
              <Icon {...smSize} icon="icn-export-button" />
            </div>
            {showLoading && (
              <div className={style.columnStyle}>
                <LoadingComponent hideString />
              </div>
            )}
          </>
        );
      }
    },
    [sortingConfig, showLoading]
  );
  const setStylesForCol = () => {
    if (
      selectedCouncil?.name === "Firmenich" &&
      session?.theme_investment_access
    ) {
      return style.firmenichCol;
    }
    return style.headerCol;
  };

  const makeSortingConfig = useCallback(
    (activeColumn) => {
      let config;

      if (sortingConfig && sortingConfig?.activeColumn === activeColumn) {
        if (sortingConfig.order === "desc") {
          config = {
            activeColumn,
            order: "asc",
          };
        } else {
          config = {
            activeColumn: "",
            order: "",
          };
        }
      } else {
        config = {
          activeColumn,
          order: "desc",
        };
      }

      let search = queryStringUrlReplacement(
        history.location.search,
        "sortAttribute",
        config.activeColumn
      );
      search = queryStringUrlReplacement(search, "sortOrder", config.order);

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location.state,
          sortAttribute: config.activeColumn,
          sortOrder: config.order,
        },
      });
    },
    [sortingConfig]
  );

  return (
    <div className={style.companiesListWrapper}>
      <DoubleScrollbar hideScroll={!isHPCouncil}>
        <div className="d-flex flex-column">
          <div className={style.listHeader}>
            <span
              onClick={() => makeSortingConfig("name_downcase")}
              className={`${style.company} position-relative`}
            >
              Company
              {drawSortingIcon("name_downcase")}
            </span>
            <div className="d-flex flex-grow-1">
              {isIDBCouncil ? (
                <span
                  onClick={() => makeSortingConfig("location_country")}
                  className={`${setStylesForCol()} position-relative`}
                >
                  Country
                  {drawSortingIcon("location_country")}
                </span>
              ) : (
                <span
                  onClick={() => makeSortingConfig("total_fundings_sum")}
                  className={`${setStylesForCol()} position-relative`}
                >
                  Total Funding
                  {drawSortingIcon("total_fundings_sum")}
                </span>
              )}
              {selectedCouncil?.name !== "Kyndryl" && (
                <span
                  onClick={() => makeSortingConfig("founded")}
                  className={`${setStylesForCol()} position-relative`}
                >
                  Year Founded
                  {drawSortingIcon("founded")}
                </span>
              )}
              <span
                onClick={() => makeSortingConfig("num_employees_enum")}
                className={`${setStylesForCol()} position-relative`}
              >
                Employees
                {drawSortingIcon("num_employees_enum")}
              </span>
              {selectedCouncil?.name !== "Kyndryl" ? (
                <span
                  onClick={() => makeSortingConfig("council_company_score")}
                  className={`${setStylesForCol()} position-relative`}
                >
                  Score
                  {drawSortingIcon("council_company_score")}
                </span>
              ) : (
                <span
                  onClick={() => makeSortingConfig("partner_type")}
                  className={`${style.headerCol} position-relative`}
                >
                  Partner type
                  {drawSortingIcon("partner_type")}
                </span>
              )}
              {session?.theme_investment_access &&
                selectedCouncil?.name === "Firmenich" && (
                  <span
                    onClick={() =>
                      makeSortingConfig("theme_investment_stage_name")
                    }
                    className={`${setStylesForCol()} position-relative`}
                  >
                    Investment
                    {drawSortingIcon("theme_investment_stage_name")}{" "}
                  </span>
                )}
              <span
                onClick={() =>
                  makeSortingConfig("council_relationship_stage_position")
                }
                className={`${setStylesForCol()} position-relative`}
              >
                Stage
                {drawSortingIcon("council_relationship_stage_position")}
              </span>
              {selectedCouncil?.name === "Kyndryl" && (
                <span
                  onClick={() => makeSortingConfig("stage_order")}
                  className={`${
                    style.founded
                  } ${setStylesForCol()} position-relative`}
                >
                  Project Stage
                  {drawSortingIcon("stage_order")}
                </span>
              )}
              {isHPCouncil && (
                <>
                  <span
                    onClick={() => makeSortingConfig("priority")}
                    className={`${setStylesForCol()} position-relative`}
                  >
                    Priority (Qualify)
                  </span>
                  <span
                    onClick={() => makeSortingConfig("priority_engagement")}
                    className={`${setStylesForCol()} position-relative`}
                  >
                    Priority (Post-Engagement)
                  </span>
                  <span
                    onClick={() => makeSortingConfig("next_steps")}
                    className={`${setStylesForCol()} position-relative`}
                  >
                    Next Steps
                  </span>
                  <span
                    onClick={() => makeSortingConfig("area")}
                    className={`${setStylesForCol()} position-relative`}
                  >
                    Area
                  </span>
                  <span
                    onClick={() => makeSortingConfig("type")}
                    className={`${setStylesForCol()} position-relative`}
                  >
                    Type
                  </span>
                  <span
                    onClick={() => makeSortingConfig("materials")}
                    className={`${setStylesForCol()} position-relative`}
                  >
                    Materials
                  </span>
                  <span
                    onClick={() => makeSortingConfig("source")}
                    className={`${setStylesForCol()} position-relative`}
                  >
                    Source
                  </span>
                </>
              )}
            </div>
          </div>
          {downloadPdfIsActive && selectedCompanies?.length > 0
            ? selectedCompanies.map((company, i, { length }) => (
                <NewCompanyItemWrapperComponent
                  key={company.id}
                  currentYear={currentYear}
                  company={company}
                  history={history}
                  dispatch={dispatch}
                  last={length - 1 === i}
                  handleRelationShipChange={handleRelationShipChange}
                  isHPCouncil={isHPCouncil}
                  isIDBCouncil={isIDBCouncil}
                  userIsScrolling={userIsScrolling}
                  handleCompanySelect={() => handleCompanySelect(company)}
                />
              ))
            : companies.map((company, i, { length }) => (
                <NewCompanyItemWrapperComponent
                  key={company.id}
                  currentYear={currentYear}
                  company={company}
                  history={history}
                  dispatch={dispatch}
                  last={length - 1 === i}
                  handleRelationShipChange={handleRelationShipChange}
                  isHPCouncil={isHPCouncil}
                  isIDBCouncil={isIDBCouncil}
                  userIsScrolling={userIsScrolling}
                  selectModeIsActive={selectModeIsActive}
                  handleCompanySelect={() => handleCompanySelect(company)}
                  isSelected={selectedCompanies.some(
                    (c) => c.id === company.id
                  )}
                />
              ))}
        </div>
      </DoubleScrollbar>
    </div>
  );
};

export default memo(NewCompaniesList);

import React, { useEffect } from "react";
import { ModalBody, ModalFooter } from "../../../application/modal/index";
import { useModelPopup } from "../../../common/hooks";
import { Label } from "../../primitives";

const RecordsAddedModal = (props) => {
  const { description, timeout } = props;
  const popup = useModelPopup();

  const handleOkayClick = () => {
    popup.hide();
  };

  useEffect(() => {
    setTimeout(handleOkayClick, Number(timeout));
  });

  return (
    <>
      <div>
        <ModalBody>
          <Label>
            <h4>{description}</h4>
          </Label>
        </ModalBody>
        <ModalFooter />
      </div>
    </>
  );
};

export default React.memo(RecordsAddedModal);

import React, { lazy, useEffect, Suspense, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import styles from './generatedReports.module.scss';
import { BtnType, Button } from "modules/primitives";
import TractionDownload from "../../../../common/components/tractionDownload";

const CompaniesWrapper = lazy(() => import("../../modules/Companies"));
const IdeasWrapper = lazy(() => import("../../modules/Ideas"));
const ProjectsWrapper = lazy(() => import("../../modules/Projects"));

const GeneratedReport = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const {
    reportingReducer: { generatedReportData },
  } = useSelector((state) => state);

  const [downloadIsActive, setDownloadIsActive] = useState(false);

  useEffect(() => {
    console.log('generatedReportData', generatedReportData)
    if (!generatedReportData) {
      enqueueSnackbar("Please select a template to be able to generate the reports", {
        variant: "error",
      });

      history.push({
        pathname: '/reporting',
      });
    }
  }, [generatedReportData])

  const handleBack = () => {
    history.push({
      pathname: '/reporting',
    });
  };

  if (!generatedReportData) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className="d-flex">
        <Button btn={BtnType.FRAME_LESS} onClick={handleBack}>{'<'} Back to reports</Button>
      </div>
      <div className="mt-5 d-flex flex-column" id="downloadableSection">
        {
          !generatedReportData?.hideTitle?.length ? (
            <h1>{generatedReportData.title || 'Traction Report'}</h1>
          ) : null
        }
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex flex-column pr-5">
            <h4 className="mt-3">Executive Summary</h4>
            <p className="mb-4">
              {
                generatedReportData.summary || 'This is an example of an executive summary. Its purpose is to provide context to stakeholders, leadership and colleagues before they jump into the report.'
              }
            </p>
          </div>
          <div className={`${downloadIsActive ? 'd-none' : 'd-flex'} justify-content-end w-25`}>
            <TractionDownload
              handleDownloadState={setDownloadIsActive}
              timeoutTime={2000}
              hidePng
              customBtnText="Download PDF"
            />
          </div>
        </div>
        {
          (generatedReportData.companies?.length || generatedReportData.customCompaniesSections?.length) ? (
            <Suspense fallback="Loading Companies report...">
              <CompaniesWrapper generatedReportsInstance />
            </Suspense>
          ) : null
        }
        {
          (generatedReportData.projects?.length || generatedReportData.customProjectsSections?.length) ? (
            <Suspense fallback="Loading Projects report...">
              <ProjectsWrapper generatedReportsInstance />
            </Suspense>
          ) : null
        }
        {
          generatedReportData.ideas?.length ? (
            <Suspense fallback="Loading Ideas report...">
              <IdeasWrapper generatedReportsInstance />
            </Suspense>
          ) : null
        }
      </div>
    </div>
  );
};

export default React.memo(GeneratedReport);

import React, { memo, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Popover from "@mui/material/Popover/Popover";

import styles from "./member-tags.module.scss";

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "15px",
  },
  tagElement: {
    backgroundColor: "#f5f7fa",
    fontSize: "14px",
    padding: "5px 16px",
    borderRadius: "18px",
  },
}));

const MemberTagsComponent = ({
  item: { topics_names = [], industries_names = [] },
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={styles.tagsWrapper}>
      {topics_names.length
        ? topics_names.slice(0, 2).map((i) => (
          <div key={i} className={styles.tagElement}>
            {i}
          </div>
        ))
        : null}
      {!topics_names.length && industries_names.length
        ? industries_names.slice(0, 2).map((i) => (
          <div key={i} className={styles.tagElement}>
            {i}
          </div>
        ))
        : null}
      {!topics_names.length && industries_names.length > 2 ? (
        <div
          className="d-flex align-items-end"
          aria-owns={open ? "mouse-over-tech" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Popover
            id="mouse-over-tech"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            {industries_names.map((t, i, { length }) => (
              <div
                className={classNames(
                  classes.tagElement,
                  i + 1 !== length ? "mb-2" : "",
                )}
                key={t}
              >
                {t}
              </div>
            ))}
          </Popover>
          <span className={styles.showMore}>more...</span>
        </div>
      ) : null}
      {topics_names.length > 2 ? (
        <div
          className="d-flex align-items-end"
          aria-owns={open ? "mouse-over-tech" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Popover
            id="mouse-over-tech"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            {topics_names.map((t, i, { length }) => (
              <div
                className={classNames(
                  classes.tagElement,
                  i + 1 !== length ? "mb-2" : "",
                )}
                key={t}
              >
                {t}
              </div>
            ))}
          </Popover>
          <span className={styles.showMore}>more...</span>
        </div>
      ) : null}
    </div>
  );
};

export default memo(MemberTagsComponent);

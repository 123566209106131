import { combineEpics, ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  apiStatus,
  errorHandler,
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
  queryString,
} from "../httpCall";
import {
  ADD_FUNDING_ITEM,
  ADD_LEADERSHIP_ITEM,
  addFundingItemSuccess,
  addLeadershipSuccessItem,
  COUNCIL_USERS_LIST_GET_ATTEMPT,
  councilUsersListGetSuccess,
  DELETE_LEADERSHIP_ITEM,
  DELETE_TOTAL_FUNDING_ITEM,
  deleteLeadershipSuccessItem,
  deleteTotalFundingItemSuccess,
  EDIT_FUNDING_ITEM,
  EDIT_LEADERSHIP_ITEM,
  editFundingItemSuccess,
  editLeadershipSuccessItem,
  PEOPLE_LIST_TEAMS_ATTEMPT,
  peopleListTeamsSuccess,
  GET_LEADERSHIP_ITEMS,
  getLeaderShipItemsSuccess,
  GET_FUNDING_ITEMS,
  getFundingItemsSuccess,
  PUT_COUNCIL_RESOURCE_CUSTOM_TAG_ATTEMPT,
  putCouncilResourceCustomTagSuccess,
  UPDATE_PLANT,
  updatePlantSuccess,
  GET_PLANT_BY_TYPE,
  getPlantByTypeSuccess,
  getTopicFilterSuccess,
  GET_TOPICS,
  GET_PLANTS_AREAS,
  getPlantsAreasSuccess,
  GET_THEMES,
  GET_FILTERABLE,
  getFilterableSuccess,
} from "../actions/common.action";
import {
  GET_RELATIONSHIP_STATUSES,
  getRelationshipStatusesSuccess,
} from "application/app.action";

import { getCompanyTagsSuccess } from "modules/companies/company/company.action";

export const epicUpdatePlant = (action$) =>
  action$.pipe(
    ofType(UPDATE_PLANT),
    switchMap(({ payload }) =>
      httpPut({
        call: "plants/resource_areas",
        data: payload.data,
      }).pipe(
        map((result) => {
          if (payload.cb) {
            payload.cb();
          }

          return updatePlantSuccess({
            ...result,
            type: payload.type,
            response: {
              ...payload.response,
              status: result.response?.status,
            },
          });
        }),
        catchError((err) => errorHandler(err, payload?.enqueueSnackbar))
      )
    )
  );

export const epicGetPlantByType = (action$) =>
  action$.pipe(
    ofType(GET_PLANT_BY_TYPE),
    switchMap(({ payload: { resourceId, enqueueSnackbar, type } }) =>
      httpGet({
        call: `plants/resource_areas?resource_id=${resourceId}&resource_type=${type}`,
      }).pipe(
        map((result) =>
          getPlantByTypeSuccess({
            ...result,
            type,
          })
        ),
        catchError((error) => {
          const customError = [
            {
              message: "Your session has expired",
              type: apiStatus.UNAUTHORIZED,
            },
          ];
          return errorHandler(error, enqueueSnackbar, customError);
        })
      )
    )
  );

export const epicLeaderShipMembers = (action$) =>
  action$.pipe(
    ofType(GET_LEADERSHIP_ITEMS),
    switchMap(({ payload: { companyId } }) =>
      httpGet({
        call: `companies/${companyId}/leadership_members`,
      }).pipe(
        map((result) => getLeaderShipItemsSuccess(result)),
        catchError((err) => errorHandler(err))
      )
    )
  );

export const epicGetRelationShipStatuses = (action$) =>
  action$.pipe(
    ofType(GET_RELATIONSHIP_STATUSES),
    switchMap(() =>
      httpGet({
        call: "council_relationship_stages",
      }).pipe(
        map((result) => getRelationshipStatusesSuccess(result)),
        catchError((err) => errorHandler(err))
      )
    )
  );

export const epicGetTeamList = (action$) =>
  action$.pipe(
    ofType(PEOPLE_LIST_TEAMS_ATTEMPT),
    switchMap(({ payload: { councilId, enqueueSnackbar } }) =>
      httpGet({
        call: `teams?${queryString({
          council_id: councilId,
        })}`,
      }).pipe(
        map((result) => peopleListTeamsSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetCouncilUsers = (action$) =>
  action$.pipe(
    ofType(COUNCIL_USERS_LIST_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, page, pageSize } }) =>
      httpPost({
        call: `councils/users/search?page=${page || 1}&items=${pageSize || 20}`,
        data: {
          query: "*",
          search: {
            topic_ids: [],
            industry_ids: [],
            teams_ids: [],
            council_roles: [
              "ttp_administrator",
              "council_administrator",
              "standard",
              "viewer",
            ],
          },
        },
      }).pipe(
        map((result) => councilUsersListGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicDeleteTotalFunding = (action$) =>
  action$.pipe(
    ofType(DELETE_TOTAL_FUNDING_ITEM),
    switchMap(({ payload: { companyId, fundingId, cb, source } }) =>
      httpDelete({
        call: `companies/${companyId}/funding_rounds/${fundingId}?source=${source}`,
      }).pipe(
        map((result) => {
          if (cb) {
            cb();
          }

          return deleteTotalFundingItemSuccess({
            ...result,
            fundingId,
          });
        }),
        catchError((err) => errorHandler(err))
      )
    )
  );

export const epicAddFunding = (action$) =>
  action$.pipe(
    ofType(ADD_FUNDING_ITEM),
    // eslint-disable-next-line camelcase
    switchMap(({ payload: { companyId, funding_round, cb } }) =>
      httpPost({
        call: `companies/${companyId}/funding_rounds`,
        data: funding_round,
      }).pipe(
        map((result) => {
          if (cb) {
            cb(result.response);
          }

          return addFundingItemSuccess({
            ...result,
            oldSource: funding_round.source,
            oldId: funding_round.id,
          });
        }),
        catchError((err) => errorHandler(err))
      )
    )
  );

export const epicEditFunding = (action$) =>
  action$.pipe(
    ofType(EDIT_FUNDING_ITEM),
    // eslint-disable-next-line camelcase
    switchMap(({ payload: { companyId, funding_round, cb } }) =>
      httpPut({
        call: `companies/${companyId}/funding_rounds/${funding_round.id}`,
        data: {
          funding_round: {
            // eslint-disable-next-line camelcase
            ...funding_round,
          },
        },
      }).pipe(
        map((result) => {
          if (cb) {
            cb(result.response);
          }

          return editFundingItemSuccess(result);
        }),
        catchError((err) => errorHandler(err))
      )
    )
  );

export const epicEditLeadership = (action$) =>
  action$.pipe(
    ofType(EDIT_LEADERSHIP_ITEM),
    // eslint-disable-next-line camelcase
    switchMap(({ payload: { companyId, leadership_member, cb } }) =>
      httpPut({
        call: `companies/${companyId}/leadership_members/${leadership_member.id}`,
        data: leadership_member,
      }).pipe(
        map((result) => {
          if (cb) {
            cb(result.response);
          }

          return editLeadershipSuccessItem(result);
        }),
        catchError((err) => errorHandler(err))
      )
    )
  );

export const epicGetFundingItems = (action$) =>
  action$.pipe(
    ofType(GET_FUNDING_ITEMS),
    // eslint-disable-next-line camelcase
    switchMap(({ payload: { companyId, cb } }) =>
      httpGet({
        call: `companies/${companyId}/funding_rounds`,
      }).pipe(
        map((result) => {
          if (cb) {
            cb(result.response);
          }

          return getFundingItemsSuccess(result);
        }),
        catchError((err) => errorHandler(err))
      )
    )
  );

export const epicAddLeadership = (action$) =>
  action$.pipe(
    ofType(ADD_LEADERSHIP_ITEM),
    // eslint-disable-next-line camelcase
    switchMap(({ payload: { companyId, leadership_member, cb } }) =>
      httpPost({
        call: `companies/${companyId}/leadership_members`,
        data: leadership_member,
      }).pipe(
        map((result) => {
          if (cb) {
            cb(result.response);
          }

          return addLeadershipSuccessItem(result);
        }),
        catchError((err) => errorHandler(err))
      )
    )
  );

export const epicDeleteLeadership = (action$) =>
  action$.pipe(
    ofType(DELETE_LEADERSHIP_ITEM),
    switchMap(({ payload: { companyId, id, cb } }) =>
      httpDelete({
        call: `companies/${companyId}/leadership_members/${id}`,
      }).pipe(
        map((result) => {
          if (cb) {
            cb();
          }

          return deleteLeadershipSuccessItem({
            ...result,
            id,
          });
        }),
        catchError((err) => errorHandler(err))
      )
    )
  );

export const epicEditResourceTag = (action$) =>
  action$.pipe(
    ofType(PUT_COUNCIL_RESOURCE_CUSTOM_TAG_ATTEMPT),
    // eslint-disable-next-line camelcase
    switchMap(({ payload: { data, cb } }) =>
      httpPut({
        call: `council_resource_custom_tags/update_many`,
        data,
      }).pipe(
        map((result) => {
          if (cb) {
            cb(result.response);
          }
          if (data.taggable_type === "Company") {
            return getCompanyTagsSuccess(result);
          }
          return putCouncilResourceCustomTagSuccess(result);
        }),
        catchError((err) => errorHandler(err))
      )
    )
  );

export const epicGetTopicFilters = (action$) =>
  action$.pipe(
    ofType(GET_TOPICS),
    // eslint-disable-next-line camelcase
    switchMap(({ payload: { selectedCouncilId, cb } }) =>
      httpGet({
        call: `councils/${selectedCouncilId}/topics/resource_topics`,
      }).pipe(
        map((result) => {
          if (cb) {
            cb(result);
          }

          return getTopicFilterSuccess(result);
        }),
        catchError((err) => errorHandler(err))
      )
    )
  );

export const epicGetThemes = (action$) =>
  action$.pipe(
    ofType(GET_THEMES),
    // eslint-disable-next-line camelcase
    switchMap(({ payload: { query, cb } }) =>
      httpPost({
        call: "themes/search?items=100&page=1&view=main",
        data: {
          sort_order: "asc",
          sort_attribute: "name",
          search: {
            query,
          },
        },
      }).pipe(
        map((result) => {
          if (cb) {
            cb(result);
          }

          return addLeadershipSuccessItem(result);
        }),
        catchError((err) => errorHandler(err))
      )
    )
  );

export const epicGetFilterableResource = (action$) =>
  action$.pipe(
    ofType(GET_FILTERABLE),
    // eslint-disable-next-line camelcase
    switchMap(({ payload: { cb } }) =>
      httpGet({
        call: "solutions/project_field_templates/filterable",
      }).pipe(
        map((result) => {
          if (cb) {
            cb(result);
          }

          return getFilterableSuccess(result);
        }),
        catchError((err) => errorHandler(err))
      )
    )
  );

export const epicGetPlantsAreas = (action$) =>
  action$.pipe(
    ofType(GET_PLANTS_AREAS),
    // eslint-disable-next-line camelcase
    switchMap(({ payload: { cb } }) =>
      httpGet({
        call: "plants/resource_areas/dropdown",
      }).pipe(
        map((result) => {
          if (cb) {
            cb(result);
          }

          return getPlantsAreasSuccess(result);
        }),
        catchError((err) => errorHandler(err))
      )
    )
  );

const genericEpic = combineEpics(
  epicUpdatePlant,
  epicGetPlantByType,
  epicGetTeamList,
  epicGetCouncilUsers,
  epicGetRelationShipStatuses,
  epicDeleteTotalFunding,
  epicAddFunding,
  epicEditFunding,
  epicEditLeadership,
  epicAddLeadership,
  epicDeleteLeadership,
  epicLeaderShipMembers,
  epicGetFundingItems,
  epicEditResourceTag,
  epicGetTopicFilters,
  epicGetThemes,
  epicGetFilterableResource,
  epicGetPlantsAreas
);

export default genericEpic;

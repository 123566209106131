import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import ListItem from "./ideaListItem";
import styles from "./ideaListViewLayout.module.scss";
import LoadingComponent from "../../../../primitives/loading/loading.component";
import IdeaListItemHeader from "./ideaListItemHeader";

const IdeaListViewLayout = ({ onSortChange }) => {
  const handleHeaderItemClick = (value) => {
    onSortChange(value);
  };

  const {
    ideaReducer: { ideas, getIdeasListLoading },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [hasRatingTemplate, setHasRatingTemplate] = useState(false);

  useEffect(() => {
    const noTemplate = ideas.find((idea) => !idea?.rating_template_id);

    setHasRatingTemplate(!noTemplate);
  }, [ideas]);

  return (
    <div className={styles.listContainer}>
      <div>
        <div className={styles.listHeader}>
          <IdeaListItemHeader
            id="name"
            title="IDEA"
            sortable
            onClick={handleHeaderItemClick}
          />
          {selectedCouncil?.traction_tag === "home_x" && (
            <IdeaListItemHeader
              id="companies"
              title="COMPANIES"
              sortable
              onClick={handleHeaderItemClick}
            />
          )}
          <IdeaListItemHeader
            id="description"
            title="DESCRIPTION"
            sortable
            className="justify-content-start"
            onClick={handleHeaderItemClick}
          />
          {
            hasRatingTemplate && (
              <IdeaListItemHeader
                id="total_rating"
                title="RATING"
                sortable
                onClick={handleHeaderItemClick}
              />
            )
          }
          <IdeaListItemHeader
            id="status"
            title="STATUS"
            sortable
            onClick={handleHeaderItemClick}
          />
          <IdeaListItemHeader
            id="submitter_full_name"
            title="SUBMITTER"
            sortable
            className="justify-content-start"
            onClick={handleHeaderItemClick}
          />
          <IdeaListItemHeader
            id="created_at"
            title="DATE"
            sortable
            onClick={handleHeaderItemClick}
          />
          <IdeaListItemHeader
            id="total_up_votes"
            title="VOTE"
            sortable
            onClick={handleHeaderItemClick}
          />
        </div>

        {getIdeasListLoading && <LoadingComponent />}

        {!getIdeasListLoading && ideas.length <= 0 && (
          <h5 className="d-flex align-items-center justify-content-center">
            No results.
          </h5>
        )}

        <div>
          {!getIdeasListLoading
            && ideas.length > 0
            && ideas.map((idea) => <ListItem idea={idea} hasRatingTemplate={hasRatingTemplate} total_rating={idea?.total_rating} key={idea.id} />)}
        </div>
      </div>
    </div>
  );
};

export default React.memo(IdeaListViewLayout);

import React, { useRef, useState, useEffect } from "react";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import {
  Label,
  TextBox,
  Button,
  BtnType, DropSelect,
} from "../../../../primitives";
import { initModel, validation } from "./addFieldToGroup.constants";
import { ModalBody, ModalFooter } from "../../../../../application/modal";
import { useModelPopup } from "../../../../../common/hooks";
import { addFieldToGroup } from "modules/admin/adminProjects/adminProjects.actions";
import { httpGet } from "../../../../../common/httpCall";

const AddFieldToGroupModal = ({ mode, existingFields, groupId }) => {
  const formRef = useRef();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [model, setModel] = useState(initModel);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    httpGet({
      call: `project_field_groups/${groupId}/project_field_templates/dropdown`
    }).subscribe(res => {
      if (res.response) {
        if (existingFields?.length) {
          const fieldsIds = existingFields.map(f => f.id)
          const newResponse = res.response.filter(field => !fieldsIds.includes(field.id));
          const getPositions = existingFields.map(f => f.position_in_group);
          const getMaxPosition = Math.max(...getPositions) || 0;

          setModel({
            ...initModel,
            position: getMaxPosition + 1
          });
          setFields(newResponse);
          return;
        }

        setFields(res.response)
      }
    })
  }, []);

  const handleDismiss = () => {
    popup.hide();
  };

  const onSubmitForm = (values, resetForm) => {
    const payload = {
      project_field_template_id: values.projectField.id,
      position: values.position,
    }

    dispatch(addFieldToGroup({
      data: payload,
      groupId,
      enqueueSnackbar,
      cb: () => {
        resetForm();
        enqueueSnackbar('Successfully added new field to the group', {
          variant: "success",
        });
        handleDismiss();
      },
    }));
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={model}
      validationSchema={validation}
      onSubmit={(values, { resetForm }) => {
        onSubmitForm(values, resetForm);
      }}
    >
      {({ handleSubmit, setFieldValue, values, ...formikprops }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="mb-3">
              <label>Select Project Field</label>
              <DropSelect
                name="projectField"
                placeholder="Select Project Field"
                labelField="field_name"
                valueField="id"
                options={fields}
                onChange={(val) => {
                  setFieldValue('projectField', val.obj[0]);
                }}
                searchable
                searchBy="field_name"
              />
            </div>
            <div className="w-100">
              <Label>Position</Label>
              <TextBox
                type="Number"
                name="position"
                formProps={{ ...formikprops, values, setFieldValue }}
                className="w-100"
                min="1"
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
              Cancel
            </Button>
            <Button
              type="submit"
              btn={BtnType.HIGHLIGHT}
              className="ml-3"
            >
              {mode === "edit" ? "Save" : "Add"}
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default AddFieldToGroupModal;

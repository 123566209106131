import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReactTable from "react-table";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import {
  Loading,
  MoreOptions,
  OptionType,
  Button,
  BtnType,
  TextBox,
} from "modules/primitives/index";
import styles from "./adminTeams.module.scss";
import AddTeamComponent from "./addTeam";
import "react-table/react-table.css";
import { useModelPopup } from "../../../common/hooks";
import ConfirmDeleteComponent from "../../../common/components/confirmDelete";
import MemberNewComponent from "../../people/internalPeople/memberNew";
import {
  councilGetTeams,
} from "../../council/council.action";
import { httpGet } from "common/httpCall";

const AdminMembersComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [csvData, setCsvData] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [searching, setSearching] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [filteredTeams, setFilteredTeams] = useState([]);

  const csvLinkRef = useRef();

  const {
    councilReducer: { selectedCouncil, updateTeams, teamList, loading },
    peopleReducer: {
      internalPeopleReducer: { refreshTeams },
    },
  } = useSelector((state) => state);

  const DisabledButton = ({ showTooltip, setShowTooltip }) => (
    <>
      {showTooltip && (
        <div className={styles.exportModalTooltip}>
          <p>
            {searching
              ? "Search cannot be exported"
              : "To be able to export list it must contain at least one element"}
          </p>
        </div>
      )}
      <Button
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
        className={styles.deleteSubmitButton}
        btn={BtnType.DISABLED}
      >
        EXPORT
      </Button>
    </>
  );

  const handleAddNewTeam = () => {
    popup.show({
      title: "Add Team",
      show: true,
      height: "300",
      width: "540",
      component: <AddTeamComponent />,
    });
  };

  const handleExportList = () => {
    setExportLoading(true);
    httpGet({
      call: "teams",
    }).subscribe(
      async (res) => {
        setExportLoading(false);
        setCsvData(() => {
          const csvDataCont = [
            [
              "Team Name",
              "Description",
              "Idea Management",
              "Request Research",
              "Members",
            ],
          ];
          res.response.map((team) => {
            csvDataCont.push([
              team.name,
              team.description,
              team.idea_management ? "YES" : "NO",
              team.request_research ? "YES" : "NO",
              team.members.length,
            ]);
          });

          return csvDataCont;
        });
        csvLinkRef.current.link.click();
      },
      (err) => {
        setExportLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      },

      () => {
        setExportLoading(false);
        enqueueSnackbar("List succesfully exported.", {
          variant: "success",
        });
      }
    );
  };

  const handleAddNewMember = () => {
    popup.show({
      title: "Add Member",
      show: true,
      height: "300",
      width: "540",
      component: <MemberNewComponent />,
    });
  };

  const moreOptions = [
    { val: "view", text: "View Team" },
    { val: "edit", text: "Edit Team" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const handleMoreOptionClick = (event, team, name) => {
    switch (event) {
      case "view": {
        history.push(`teams/edit/${team.id}`);
        break;
      }
      case "delete": {
        popup.show({
          title: "Confirmation Prompt",
          show: true,
          height: "300",
          width: "540",
          component: (
            <ConfirmDeleteComponent
              id={team.id}
              enqueueSnackbar={enqueueSnackbar}
              actionType="deleteTeam"
              teamName={name}
            />
          ),
        });
        break;
      }
      case "edit": {
        popup.show({
          title: "Edit Team",
          show: true,
          height: "300",
          width: "540",
          component: <AddTeamComponent mode="edit" team={team} />,
        });
        break;
      }
      default:
    }
  };

  const onRowClick = (rowInfo) => {
    history.push(`/admin/teams/edit/${rowInfo.original.id}`);
  };

  const handleSearchTeams = (e) => {
    setQuery(e.target.value);
    if (e.target.value === "") {
      setFilteredTeams([]);
      setSearching(false);
      dispatch(councilGetTeams({ enqueueSnackbar }));
    } else {
      // dispatch(councilSearchTeams({ query: e.target.value, enqueueSnackbar }));
      // setSearching(true);
      const filtered = teamList.filter((team) => {
        if (team.name?.toLowerCase().includes(e.target.value.toLowerCase())) {
          return true;
        }
        return false;
      });
      setFilteredTeams(filtered);
    }
  };
  useEffect(() => {
    if (query) {
      handleSearchTeams({ target: { value: query } });
    }
    return () => null;
  }, [teamList]);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(councilGetTeams({ enqueueSnackbar }));
    }
    return () => null;
  }, [selectedCouncil]);

  useEffect(() => {
    if (updateTeams || refreshTeams) {
      dispatch(councilGetTeams({ enqueueSnackbar }));
    }
  }, [updateTeams, refreshTeams]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Team Name",
        accessor: "name",
        maxWidth: 400,
        minWidth: 140,
        width: 200,
        style: {
          cursor: "pointer",
        },
      },
      {
        Header: "Description",
        accessor: "description",
        maxWidth: 400,
        minWidth: 140,
        width: 400,
      },
      {
        Header: "Idea Management",
        id: "idea_management",
        accessor: (row) => {
          if (row.idea_management) {
            return <div style={{ color: "green" }}>Yes</div>;
          }
          return <div style={{ color: "red" }}>No</div>;
        },
        style: {
          // overflow: 'visible',
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        },
        maxWidth: 400,
        minWidth: 100,
        width: 140,
      },
      {
        Header: "Request Research",
        id: "request_research",
        accessor: (row) => {
          if (row.request_research) {
            return <div style={{ color: "green" }}>Yes</div>;
          }
          return <div style={{ color: "red" }}>No</div>;
        },
        style: {
          // overflow: 'visible',
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        },
        maxWidth: 400,
        minWidth: 100,
        width: 140,
      },
      {
        Header: "Members",
        id: "members",
        accessor: (row) => row.members.length,
        maxWidth: 400,
        minWidth: 100,
        width: 140,
        style: {
          // overflow: 'visible',
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Options",
        id: "options",
        sortable: false,
        accessor: (row) => (
          <MoreOptions
            options={
              row.untouchable
                ? [{ val: "view", text: "View Team" }]
                : moreOptions
            }
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row, row.name);
            }}
          />
        ),
        width: 100,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    []
  );

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <span
            className={styles.rootText}
            onClick={() => {
              history.push("/admin");
            }}
          >
            ADMIN
          </span>
          <span className={styles.separationBar}> / </span>
          <span className={styles.rootText}>TEAMS</span>
        </h4>
      </div>

      <div className={styles.bodyWrp}>
        <div>
          <TextBox
            className={styles.search}
            onChange={handleSearchTeams}
            placeholder="Search"
            value={query}
          />

          <div className={styles.buttonWrp}>
            <Button
              className={styles.addNew}
              btn={BtnType.REGULAR}
              icon="icn-add"
              onClick={handleAddNewTeam}
            >
              Add Team
            </Button>

            {exportLoading ? (
              <Loading />
            ) : teamList.length > 0 && !searching ? (
              <div>
                <Button
                  // disabled={!listTotalCount}
                  onClick={handleExportList}
                  btn={BtnType.OUTLINE}
                >
                  EXPORT
                </Button>
                <CSVLink
                  data={csvData}
                  filename="Traction Teams.csv"
                  style={{ display: "none" }}
                  ref={csvLinkRef}
                />
              </div>
            ) : (
              <DisabledButton
                setShowTooltip={setShowTooltip}
                showTooltip={showTooltip}
              />
            )}

            <div className={styles.totalCount}>
              {teamList.length || "0"} teams
            </div>
          </div>
          <ReactTable
            data={filteredTeams[0] ? filteredTeams : teamList}
            loading={loading}
            columns={columns}
            className={styles.table}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: () => onRowClick(rowInfo),
                  onMouseEnter: (e) => {
                    setHoveredRow(rowInfo.index);
                  },
                  onMouseLeave: (e) => {
                    setHoveredRow(null);
                  },
                  style: {
                    cursor: "pointer",
                    background:
                      rowInfo.index === hoveredRow ? "#efefef" : "transparent",
                  },
                };
              }
              return {};
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default AdminMembersComponent;

// export const validation = Yup.object().shape({
//   text: Yup.string().required('text is required'),
// });

export const model = {
  type: "text",
  title: "",
  big_box_text: false,
  max_words: 100,
};

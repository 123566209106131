export const filterOptions = [
  { text: "All", value: "All" },

  { text: "Newest", value: "desc" },
  { text: "Oldest", value: "asc" },
  { text: "Favorites", value: "favorites" },
  { text: "My Themes", value: "myThemes" },
  // { text: "Type", value: "type", optGroup: true },
  { text: "Market Segment", value: "market" },
  { text: "Technology", value: "technology" },
];

export const GroupOptions = [
  { text: "Market Segment", value: "market_segment" },
  { text: "Technology", value: "technology" },
];
export const layoutList = [
  { type: "icn-view-card", name: "card" },
  { type: "icn-view-list", name: "list" },
];

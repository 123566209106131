import {
  APPLICATION_GET_ATTEMPT,
  APPLICATION_GET_SUCCESS,
  APPLICANTS_LIST_GET_ATTEMPT,
  APPLICANTS_LIST_GET_SUCCESS,
  APPLICATION_DELETE_ATTEMPT,
  APPLICATION_DELETE_SUCCESS,
  APPLICATION_UPDATE_ATTEMPT,
  APPLICATION_UPDATE_SUCCESS,
} from "./adminApplicants.action";
import { apiStatus, pageMeta } from "../../../common/httpCall";

const initState = {
  application: null,
  applicantList: [],
  searchResults: [],
  listLoading: false,
  getLoading: false,
  updateLoading: false,
  deleteLoading: false,
  applicantPageNr: {
    total: 0,
    pageSize: 20,
    pages: 1,
    page: 1,
  },
};
const adminApplicantsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case APPLICATION_GET_ATTEMPT: {
      return {
        ...state,
        getLoading: true,
      };
    }
    case APPLICATION_GET_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response) {
          return {
            ...state,
            application: response,
            getLoading: false,
          };
        }
      }
      break;
    }

    case APPLICANTS_LIST_GET_ATTEMPT: {
      return {
        ...state,
        listLoading: true,
      };
    }
    case APPLICANTS_LIST_GET_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response) {
          return {
            ...state,
            applicantList: response,
            listLoading: false,
            applicantPageNr: pageMeta(payload),
          };
        }
      }
      break;
    }

    case APPLICATION_UPDATE_ATTEMPT: {
      return {
        ...state,
        updateLoading: true,
      };
    }
    case APPLICATION_UPDATE_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response.id) {
          const { applicantList } = state;
          const index = applicantList.findIndex(({ id }) => id === response.id);
          if (applicantList[index]) {
            applicantList[index] = response;
          }
          return {
            ...state,
            application: response,
            applicantList,
            updateLoading: false,
          };
        }
      }
      break;
    }

    case APPLICATION_DELETE_ATTEMPT: {
      return {
        ...state,
        deleteLoading: true,
      };
    }
    case APPLICATION_DELETE_SUCCESS: {
      const { id } = payload;
      if (id) {
        return {
          ...state,
          applicantList: state.applicantList.filter(
            (application) => application.id !== id,
          ),
          deleteLoading: false,
          applicantPageNr: state.applicantPageNr.total
            ? {
              total: state.applicantPageNr?.total - 1,
              pageSize: 20,
              pages: 1,
              page: 1,
            }
            : {
              total: 0,
              pageSize: 20,
              pages: 1,
              page: 1,
            },
        };
      }
      break;
    }

    default:
      return state;
  }

  return state;
};

export default adminApplicantsReducer;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import styles from './styles.module.scss';
import FordScoreCardFilters from "modules/reporting/fordReports/filters";
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import TractionDownload from "../../../../common/components/tractionDownload";
import PDFTitleAndTractionLogo from "modules/reporting/fordReports/pdfTitleAndTractionLogo";
import {
  getReplicationsTableData,
  setReplicationsCardFilters,
  toggleReplicationsTabFilters,
  getReplicationsStatisticsData,
  getReplicationsByStageData,
  getProjectsExportedData,
  getProjectsImportedData,
} from "modules/reporting/fordReports/replicationsCard/store/replicationsCard.actions";
import FordReplicationsTableWrapper from "modules/reporting/fordReports/replicationsCard/table";
import ReplicationsCards from "modules/reporting/fordReports/replicationsCard/sections";

const FordReplicationsCard = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [downloadIsActive, setDownloadIsActive] = useState(false);

  const {
    fordDashboardReducer: { replicationsCardReducer }
  } = useSelector((state) => state);

  const handleReset = () => {
    dispatch(setReplicationsCardFilters({
      reset: true
    }));

    const makeFilters = makeActualScoreCardFilterData({
      dateType: 'allTime'
    });
    const payload = {
      enqueueSnackbar,
      data: makeFilters,
    };

    dispatch(getReplicationsStatisticsData(payload));
    dispatch(getReplicationsByStageData(payload));
    dispatch(getProjectsExportedData(payload));
    dispatch(getProjectsImportedData(payload));

    if (replicationsCardReducer.tableData?.expandedKey?.length > 0) {
      dispatch(getReplicationsTableData({
        reducerKey: replicationsCardReducer.tableData.expandedKey,
        highLightColumn: replicationsCardReducer.tableData.highLightColumn,
        data: {
          ...makeFilters,
          section: replicationsCardReducer.tableData.expandedKey,
        },
      }));
    }
  };

  const handleExpandClick = () => {
    dispatch(toggleReplicationsTabFilters());
  }

  const getNewData = (payload) => {
    dispatch(getReplicationsStatisticsData(payload));
    dispatch(getReplicationsByStageData(payload));
    dispatch(getProjectsExportedData(payload));
    dispatch(getProjectsImportedData(payload));
  }

  const getNewTableData = payload => {
    dispatch(getReplicationsTableData(payload));
  }

  const setNewFilters = (payload) => {
    dispatch(setReplicationsCardFilters(payload));
  }

  return (
    <section className={styles.planScoreCardWrapper}>
      <div className="d-flex justify-content-end">
        <TractionDownload
          handleDownloadState={setDownloadIsActive}
          timeoutTime={2000}
          hidePng
        />
      </div>
      <div id="downloadableSection">
        <PDFTitleAndTractionLogo downloadIsActive={downloadIsActive} title="Replications Card" />
        <FordScoreCardFilters
          reducer={replicationsCardReducer}
          handleReset={handleReset}
          handleExpandClick={handleExpandClick}
          getNewData={getNewData}
          getNewTableData={getNewTableData}
          setNewFilters={setNewFilters}
          filterBy={replicationsCardReducer.replicationsByStage?.data || []}
        />
        <ReplicationsCards filters={replicationsCardReducer.filters} downloadIsActive={downloadIsActive} />
        <FordReplicationsTableWrapper />
      </div>
    </section>
  );
};

export default React.memo(FordReplicationsCard);

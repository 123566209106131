import React, { useState } from "react";
import classnames from "classnames";

import styles from "./form-generator.module.scss";
import DragAndDrop from "../../../common/components/dragAndDrop";
import { Icon, smSize } from "../../../common/icon";
import LoadingComponent from "../loading/loading.component";

const S3_UPLOADER_TOKEN = process.env.REACT_APP_S3_UPLOADER_TOKEN;
const S3_UPLOADER_URL = process.env.REACT_APP_S3_UPLOADER_URL;

const File = ({ field, formikprops, placeholder, councilId, formId }) => {
  const [docs, setDocs] = useState([]);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const {
    id,
    field_name: fieldName,
    field_label: fieldLabel,
    field_type: type,
  } = field;
  const label = fieldLabel || fieldName;
  const { errors, touched, setFieldValue, values } = formikprops;
  const isInvalid = errors[id] && touched[id];

  const uploadHandler = async (e, action) => {
    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }

    const filteredFiles = [];

    for (const file of files) {
      if (file.size > 50000000) { // 50000000 -> 50 MB
        alert('Maximum allowed size for a file is 50 MB!');
      } else {
        filteredFiles.push(file);
      }
    }

    const formData = new FormData();

    if (filteredFiles.length > 0) {
      Array.from(filteredFiles).forEach((file) => {
        formData.append('files[]', file);
      });
    }

    setUploadInProgress(true);

    await fetch(`${S3_UPLOADER_URL}/api/v2/documents/uploads?form_id=${formId}`, {
      method: "POST",
      headers: {
        council_id: councilId,
        Authorization: `Bearer ${S3_UPLOADER_TOKEN}`,
      },
      body: formData,
    }).then(res => {
      res.json().then(response => {
        if (response.files) {
          setFieldValue(id, [...values[id] || [], ...response.files.map(file => ({...file, formId}))]);
          setUploadInProgress(false);
        }
      });
    });

    setDocs([...docs, ...filteredFiles]);
  }

  const handleDelete = (doc, idx) => {
    const newDocs = docs.filter((_, id) => id !== idx);
    const newValue = values[id].filter((_, id) => id !== idx);

    setDocs(newDocs);
    setFieldValue(id, newValue);
  }

  return (
    <div className={classnames(styles.formGroup, styles.dFlex, styles.flexDirection)} key={`field-${id}`}>
      {
        id === 'f055a365-4a03-4f3d-a181-99049367a3c4' ? (
          <div className={styles.customMerckStyles}>
            <h5>Finances</h5>
            <hr />
          </div>
        ) : null
      }
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      {
        uploadInProgress ? (
          <LoadingComponent customText="Uploading Documents..." />
        ) : (
          <>
            {docs.map((document, index) => (
              <div
                key={index}
                className={classnames(styles.dFlex, styles.justifyBetween)}
              >
                <div className={classnames(styles.dFlex, styles.alignCenter, styles.w50)}>
                  <Icon
                    {...smSize}
                    icon="icn-subnav-documents"
                    className={styles.tileIcon}
                  />
                  <span>{document.name}</span>
                </div>
                <div style={{cursor: 'pointer'}} onClick={() => handleDelete(document, index)}>
                  <span>x</span>
                </div>
              </div>
            ))}
            <DragAndDrop
              uploadHandler={uploadHandler}
              customText={placeholder || 'drag and drop documents here'}
              unicumKey={id}
            />
            {isInvalid && <div className={styles.invalidFeedback}>{errors[id]}</div>}
          </>
        )
      }
    </div>
  );
};

export default File;

import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import styles from "./projectCompanies.module.scss";
import { UserInline, UserInlineTypes } from "../../../primitives";
import { projectStates } from "./projectCompanies.constant";

const MobileProjectCompanyRow = ({
  project,
  handleProjectClick,
  redirectToProfile,
}) => (
  <div
    className={
      project.current_user_has_access
        ? styles.mobileGridRow
        : styles.disabledGridRow
    }
    onClick={(evt) => handleProjectClick(evt, project)}
  >
    <div className={styles.mobileRow}>
      <span>Project</span>
      <div className={styles.companyValue}>{project.project?.name || "-"}</div>
    </div>
    <div className={styles.mobileRow}>
      <span>Stage</span>
      <div className={styles.stage}>
        <div className={styles[project.stage?.name?.toLowerCase()]}>
          {project.stage?.name || "-"}
        </div>
      </div>
    </div>
    <div className={styles.mobileRow}>
      <span>Owner</span>
      <div>
        {project.project?.created_by?.name ? (
          <div
            onClick={(e) => redirectToProfile(e, project.project?.created_by?.avatar)}
          >
            <UserInline
              local
              userId={project.project?.created_by?.id}
              first={project.project?.created_by?.name.split(" ")[0]}
              last={project.project?.created_by?.name.split(" ")[1]}
              type={UserInlineTypes.WITH_NAME}
              className={styles.name}
              avatar={project.project?.created_by?.avatar}
              noNavigation
            />
          </div>
        ) : (
          "-"
        )}
      </div>
    </div>
    <div className={styles.mobileRow}>
      <span>Access</span>
      <div>
        {project.project?.visibility?.charAt(0).toUpperCase()
          + project.project?.visibility?.slice(1).split("_").join(" ") || "-"}
      </div>
    </div>
    <div className={styles.mobileRow}>
      <span>Next Task</span>
      <div>{project.project_next_task?.name || "-"}</div>
    </div>
    <div className={styles.mobileRow}>
      <span>Assigned</span>
      <div>{project.project_next_task?.assigned_to_name || "-"}</div>
    </div>
    <div className={styles.mobileRow}>
      <span>Due</span>
      <div>
        {project.project_next_task?.due_date
          ? format(
            new Date(project.project_next_task?.due_date),
            "MMM dd, yyyy",
          )
          : "-"}
      </div>
    </div>
    <div className={styles.mobileRow}>
      <span>Status</span>
      <div
        className={classNames(styles[project.project.status || ""], styles.due)}
      >
        {project.project.status ? projectStates[project.project.status] : "-"}
      </div>
    </div>
  </div>
);
export default MobileProjectCompanyRow;

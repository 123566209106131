import React from "react";
import classNames from "classnames";
import styles from "../projectsMain.module.scss";

const TableHeaderComponent = ({
  makeSortingConfig,
  drawSortingIcon,
  selectedTab,
}) => {
  return (
    <div className={classNames(styles.gridHeader)}>
      {/* col-1 */}
      <div
        className={`${styles.projectName} `}
        onClick={() => makeSortingConfig("name")}
      >
        Project
        {drawSortingIcon("name")}
      </div>

      <div
        className={`${styles.plantName} `}
        onClick={() => makeSortingConfig("plant_name")}
      >
        Plant
        {drawSortingIcon("plant_name")}
      </div>
      <div
        className={`${styles.area} `}
        onClick={() => makeSortingConfig("areas_names")}
      >
        {"Area"}
        {drawSortingIcon("areas_names")}
      </div>
      <div
        className={`${styles.savings} `}
        onClick={() => makeSortingConfig("savings")}
      >
        Savings
        {drawSortingIcon("savings")}
      </div>
      <div
        className={`${styles.rating} `}
        onClick={() => makeSortingConfig("rating")}
      >
        Rating
        {drawSortingIcon("rating")}
      </div>
      <div
        className={`${styles.theme} `}
        onClick={() => makeSortingConfig("theme_names")}
      >
        Pillar
        {drawSortingIcon("theme_names")}
      </div>
      <div
        className={`${styles.replications} `}
        onClick={() => makeSortingConfig("replications")}
      >
        Replications
        {drawSortingIcon("replications")}
      </div>
      <div
        className={`${styles.stages} `}
        onClick={() => makeSortingConfig("stage_name")}
      >
        Stage
        {drawSortingIcon("stage_name")}
      </div>
      {selectedTab === "project_stage_idea" ? (
        <div
          className={`${styles.progress} `}
          onClick={() => makeSortingConfig("approval_status")}
        >
          Approval Status
          {drawSortingIcon("approval_status")}
        </div>
      ) : (
        <div
          className={`${styles.progress} `}
          onClick={() => makeSortingConfig("progress")}
        >
          Progress
          {drawSortingIcon("progress")}
        </div>
      )}

      <div
        className={`${styles.catalogOwner} `}
        onClick={() => makeSortingConfig("owner")}
      >
        Owner
        {drawSortingIcon("owner")}
      </div>
      <div
        className={`${styles.catalogOwner} `}
        onClick={() => makeSortingConfig("access")}
      >
        Access
        {drawSortingIcon("access")}
      </div>

      <div
        className={`${styles.status} `}
        onClick={() => makeSortingConfig("status")}
      >
        Status
        {drawSortingIcon("status")}
      </div>
    </div>
  );
};

export default React.memo(TableHeaderComponent);

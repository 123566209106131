import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useSnackbar } from "notistack";
import styles from "./styles.module.scss";
import { Loading } from "../../../../primitives";
import { numberWithCommas } from "../../../../../common/helper";
import { themesMetricsGetAttempt } from "../overviewTab.action";

const FordMetricsBlock = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { currentThemeData },
      themesOverviewReducer: { metricsData, loadingMetricsData },
    },
  } = useSelector((state) => state);
  const [metrics, setMetrics] = useState([]);
  const dispatch = useDispatch();

  const getMetrics = () => {
    dispatch(
      themesMetricsGetAttempt({ id: currentThemeData.id, enqueueSnackbar })
    );
  };

  useEffect(() => {
    if (currentThemeData.id) {
      getMetrics({
        id: currentThemeData.id,
        enqueueSnackbar,
      });
    }
  }, [selectedCouncil]);

  useEffect(() => {
    if (metricsData.resource_id) {
      setMetrics(metricsData);
    }
  }, [metricsData]);

  if (loadingMetricsData) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <span className={styles.title}>Metrics</span>

      <div className={styles.overviewBlock}>
        <div className={styles.row}>
          <div className={styles.descriptionBlock}>
            <span className={styles.descriptionTitle}>Average Rating:</span>
            <span className={styles.descriptionText}>
              {Number(metrics.average_rating) || "No data"}
            </span>
          </div>

          {/*  */}
          <div className={styles.descriptionBlock}>
            <span className={styles.descriptionTitle}>Total Projects:</span>
            <span className={styles.descriptionText}>
              {metrics.total_projects || "No data"}
            </span>
          </div>
          <div className={styles.descriptionBlock}>
            <span className={styles.descriptionTitle}>Total Replications:</span>
            <span className={styles.descriptionText}>
              {metrics.total_replications?.value ||
                metrics.total_replications ||
                "No data"}
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.descriptionBlock}>
            <span className={styles.descriptionTitle}>Total Savings:</span>
            <span className={styles.descriptionText}>
              ${numberWithCommas(metrics.total_savings) || "No data"}
            </span>
          </div>

          {/*  */}
          <div className={styles.descriptionBlock}>
            <span className={styles.descriptionTitle}>Total Investment:</span>
            <span className={styles.descriptionText}>
              ${numberWithCommas(metrics.total_investment) || "No data"}
            </span>
          </div>
          <div className={styles.descriptionBlock}>
            <span className={styles.descriptionTitle}>Average TARR:</span>
            <span className={styles.descriptionText}>
              {metrics.average_tarr || "No data"} {" %"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FordMetricsBlock);

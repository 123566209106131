import React, { useState } from "react";
import { Field } from "formik";
import classnames from "classnames";

import styles from "./form-generator.module.scss";

const TextArea = ({ field, formikprops }) => {
  const {
    id,
    field_name: fieldName,
    field_label: fieldLabel,
    field_type: type,
    field_placeholder: placeholder,
    max_words,
  } = field;
  const [wordsLength, setWordsLength] = useState(0);
  const label = fieldLabel || fieldName;
  const { errors, touched, setFieldValue } = formikprops;
  const isInvalid = errors[id] && touched[id];

  const handleTextAreChange = evt => {
    const value = evt.target.value || '';

    if (!value || !value.length) {
      setWordsLength(0);
    } else {
      const wordsNr = value.trim().split(/\s+/).length;
      setWordsLength(wordsNr);
    }

    setFieldValue(id, value);
  }

  return (
    <div className={styles.formGroup} key={`field-${id}`}>
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      <Field
        id={id}
        name={id}
        type={type}
        as="textarea"
        className={classnames(
          styles.formControl,
          isInvalid && styles.invalidInput,
        )}
        placeholder={placeholder}
        onChange={handleTextAreChange}
        rows="7"
      />
      {
        max_words ? (
          <span className={classnames(isInvalid ? 'text-danger' : '')}>
            {wordsLength}/{max_words}
          </span>
        ) : null
      }
      {isInvalid && <div className={styles.invalidFeedback}>{errors[id]}</div>}
    </div>
  );
};

export default TextArea;

import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Icon, smSize, mdSize } from "common/icon";
import { useDocumentName } from "common/hooks";
import useS3FileUpload from "common/hooks/s3FileUplodar.hook";
import SimpleDialog from "modules/companies/company/details/confirmationModal";
import { shouldDownload } from "common/helper";
import { Loading } from "modules/primitives";
import DragAndDrop from "common/components/dragAndDrop";
import styles from "./styles.module.scss";
import LinearProgressWithLabel from "../../../../../../common/components/linearProgressWithLabel";

const DocumentComponent = ({ item, handleUpdateForm, disabled, companyId }) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const [newDocuments, setNewDocuments] = useState(item?.newDocs || []);
  const [documents, setDocuments] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [docLoading, setDocLoading] = useState(false);
  const [origin, setOrigin] = useState("");
  const [index, setIndex] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { fileDelete, fileUpload } = useS3FileUpload();
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [uploadValues, setUploadValues] = useState([]);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const documentName = useDocumentName();

  const handleOpenModal = (e, index, origin) => {
    e.stopPropagation();
    setOpenConfirmModal(true);
    setOrigin(origin);
    setIndex(index);
  };

  const handleDismiss = () => {
    setOpenConfirmModal(!openConfirmModal);
  };

  const getFilename = (url) => {
    if (url) {
      return url.split("/").pop();
    }
    return "";
  };

  const uploadHandler = async (e, action) => {
    if (forbiddenFiles.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }
    setDocLoading(true);

    const fileList = [];
    let dismissedFiles = 0;

    setUploadValues((items) => {
      const currentFiles = [...items];

      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name,
      }));
      return [...currentFiles, ...newFiles];
    });

    files.forEach(async (file) => {
      if (file.size <= 500000000) {
        const pathname = `srm/${selectedCouncil.id}/companies/${companyId}/`;
        await fileUpload(file, pathname, setUploadValues, file.name).then(
          (res) => {
            if (res && res.location) {
              if (
                [...newDocuments, ...documents].indexOf(res.location) === -1
              ) {
                handleUpdateForm({
                  ...item,
                  documents_attributes: [
                    ...(item?.documents_attributes || []),
                    {
                      added_by_id: session.id,
                      council_id: selectedCouncil.id,
                      name: file.name,
                      uri: res.location,
                      rfi: true,
                    },
                  ],
                });
                fileList.push(res.location);
                setDocLoading(false);
              } else {
                dismissedFiles++;
                setForbiddenFiles((prevFiles) => {
                  const localFiles = [...prevFiles];
                  localFiles.push({
                    name: file.name,
                    error: "Document already added.",
                  });
                  return localFiles;
                });
                setUploadValues((prevState) => {
                  const currentProgress = [...prevState];
                  const index = currentProgress
                    .map((item) => item.name)
                    .lastIndexOf(file.name);
                  currentProgress[index] = {
                    progress: "NOT_ALLOWED",
                    name: file.name,
                  };
                  return currentProgress;
                });
                enqueueSnackbar("This document was already added.", {
                  variant: "error",
                });
                setDocLoading(false);
              }
            }
          }
        );
      } else {
        dismissedFiles++;
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === file.name
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: file.name,
          };
          return currentProgress;
        });
        if (file.size > 100000000) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error: "Your file is too large. File size limit: 100MB",
            });
            return localFiles;
          });
          setDocLoading(false);
          enqueueSnackbar(
            "We could not upload your image because your file size is too big. Please make sure the file is less than 20 MB",
            {
              variant: "error",
            }
          );
        }
      }
      if (fileList.length === files.length - dismissedFiles) {
        setNewDocuments([...newDocuments, ...fileList]);
        setDocLoading(false);
      }
    });

    e.target.value = "";
  };

  const handleDeleteDocument = async (e) => {
    e.stopPropagation();
    setOpenConfirmModal(false);
    setIsDeleting(index);
    const pathname = `srm/${
      selectedCouncil.id
    }/companies/${companyId}/${getFilename(
      origin === "oldDocs" ? documents[index] : newDocuments[index]
    )}`;
    await fileDelete(pathname).then(() => {
      setIsDeleting("");
      if (origin === "oldDocs") {
        setDocuments([
          ...documents.map((doc, idx) => {
            if (index === idx && doc.id) {
              return {
                ...doc,
                _destroy: true,
              };
            }
            return doc;
          }),
        ]);
      } else {
        const deleteDoc = newDocuments.find((doc, idx) => idx === index);
        const filteredNewDocs = newDocuments.filter(
          (doc, idx) => idx !== index
        );

        setNewDocuments(filteredNewDocs);

        handleUpdateForm({
          ...item,
          documents_attributes: item.documents_attributes.filter(
            (d) => d.uri !== deleteDoc
          ),
        });
      }
    });

    setIndex(null);
  };

  useEffect(() => {
    if (item?.field_values) {
      setSubmitting(false);
    }

    if (item?.documents) {
      const itemsWithDestroy = item.documents_attributes?.filter(
        (d) => d._destroy
      );

      if (!itemsWithDestroy?.length) {
        setDocuments(item.documents);
      }
    }

    if (item.resetNewDocs) {
      setNewDocuments([]);
    }

    return () => null;
  }, [item]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <h5 className={styles.title}>{item.field_name || "Document"}</h5>
        {item?.field_placeholder?.length ? (
          <span className="text-muted small">{item.field_placeholder}</span>
        ) : null}
      </div>
      <div className={styles.container}>
        <div className={styles.documentListWrp}>
          {documents?.map((document, index) => (
            <div key={index} className={styles.documnetContainer}>
              <Icon
                {...smSize}
                icon="icn-subnav-documents"
                className={styles.tileIcon}
              />
              <div>{documentName.getName(getFilename(document.uri))}</div>
              <div className={styles.rightWrp}>
                <Link to={document.uri} target="_blank" download>
                  <Icon
                    {...smSize}
                    icon="icn-export-button"
                    className={styles.download}
                  />
                </Link>

                <a
                  className={styles.docView}
                  target="blank"
                  href={document.uri}
                >
                  {shouldDownload(getFilename(document.uri))
                    ? "download"
                    : "view"}
                </a>
              </div>
            </div>
          ))}
          {submitting ? (
            <Loading />
          ) : newDocuments && newDocuments.length ? (
            newDocuments.map((document, index) =>
              isDeleting === index ? (
                <Loading />
              ) : (
                <div key={index} className={styles.documnetContainer}>
                  <Icon
                    {...smSize}
                    icon="icn-subnav-documents"
                    className={styles.tileIcon}
                  />
                  <div>{documentName.getName(getFilename(document))}</div>
                  <div className={styles.rightWrp}>
                    <Icon
                      {...mdSize}
                      icon="icn-button-delete"
                      className={styles.delete}
                      onClick={(e) => handleOpenModal(e, index, "newDocs")}
                    />
                    <a
                      className={styles.docView}
                      target="blank"
                      href={document}
                    >
                      {shouldDownload(getFilename(document))
                        ? "download"
                        : "view"}
                    </a>
                  </div>
                </div>
              )
            )
          ) : null}
        </div>

        <div>
          {" "}
          {uploadValues?.length
            ? uploadValues.map(
                (bar, index) =>
                  bar &&
                  bar?.progress !== "NOT_ALLOWED" && (
                    <div className={styles.loaderContainer}>
                      <div className={styles.loaderNamingWrp}>
                        {" "}
                        <div className={styles.fileName}>
                          {uploadValues[index]?.name}
                        </div>
                        <LinearProgressWithLabel
                          mode={
                            uploadValues[index]?.progress === "FAILED" &&
                            "error"
                          }
                          value={
                            uploadValues[index]?.progress === "FAILED"
                              ? 100
                              : uploadValues[index]?.progress || 0
                          }
                        />
                      </div>
                    </div>
                  )
              )
            : null}
        </div>

        <DragAndDrop
          uploadHandler={uploadHandler}
          loading={docLoading}
          customText="drag and drop documents here"
          unicumKey={item?.custom_field_template_id}
          disabled={disabled}
        />
      </div>

      {forbiddenFiles?.length ? (
        <div className={styles.forbiddenfileContainer}>
          Files that weren't allowed:{" "}
          {forbiddenFiles.map((file) => (
            <span className={styles.forbiddenfileName}>
              {`${file.name}: `}
              <span className={styles.errorText}> {file.error}</span>
            </span>
          ))}
        </div>
      ) : null}

      <SimpleDialog
        open={openConfirmModal}
        handleDismiss={() => handleDismiss()}
        handleDelete={(e) => handleDeleteDocument(e)}
        openConfirmModal={openConfirmModal}
      />
    </div>
  );
};

export default React.memo(DocumentComponent);

export const checkAvailableFilters = (
  councilName,
  availableFilters,
  currentFilter
) => {
  if (councilName === "Kyndryl") {
    if (availableFilters && availableFilters[0]) {
      return availableFilters && availableFilters[0][currentFilter];
    }
    return false;
  } else {
    return true;
  }
};

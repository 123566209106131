export const THEME_DOCUMENT_GET_ATTEMPT = "THEME_DOCUMENT_GET_ATTEMPT";
export const themesDocumentGetAttempt = (payload) => ({
  type: THEME_DOCUMENT_GET_ATTEMPT,
  payload,
});

export const THEME_DOCUMENT_GET_SUCCESS = "THEME_DOCUMENT_GET_SUCCESS";
export const themesDocumentGetSuccess = (payload) => ({
  type: THEME_DOCUMENT_GET_SUCCESS,
  payload,
});
export const THEME_DOCUMENT_POST_ATTEMPT = "THEME_DOCUMENT_POST_ATTEMPT";
export const themesDocumentPostAttempt = (payload) => ({
  type: THEME_DOCUMENT_POST_ATTEMPT,
  payload,
});

export const THEME_DOCUMENT_POST_SUCCESS = "THEME_DOCUMENT_POST_SUCCESS";
export const themesDocumentPostSuccess = (payload) => ({
  type: THEME_DOCUMENT_POST_SUCCESS,
  payload,
});

export const THEME_DOCUMENT_DELETE_ATTEMPT = "THEME_DOCUMENT_DELETE_ATTEMPT";
export const themesDocumentDeleteAttempt = (payload) => {
  return {
    type: THEME_DOCUMENT_DELETE_ATTEMPT,
    payload,
  }
};

export const THEME_DOCUMENT_DELETE_SUCCESS = "THEME_DOCUMENT_DELETE_SUCCESS";
export const themesDocumentDeleteSuccess = (payload) => ({
  type: THEME_DOCUMENT_DELETE_SUCCESS,
  payload,
});
import checkForThemes from "./checkForThemes";

const gskFilter = (menu, session, council_role, selectedCouncil) => {
  if (
    session &&
    (council_role === "ttp_administrator" ||
      council_role === "council_administrator")
  ) {
    return menu.filter((menuItem) => {
      if (menuItem.mobileOnly) {
        return false;
      }
      if (menuItem.path === "/ideas") {
        return false;
      }
      if (menuItem.path === "/reporting") {
        if (
          selectedCouncil?.settings?.reports_allowed_roles?.includes(
            council_role
          ) ||
          selectedCouncil?.settings?.reports_allowed_roles?.includes("all") ||
          council_role === "ttp_administrator"
        ) {
          return true;
        }

        return false;
      }
      if (menuItem.claimProfileOnly) {
        return false;
      }

      return checkForThemes(menuItem.path, selectedCouncil);
    });
  }
  return menu.filter((menuItem) => {
    if (menuItem.mobileOnly) {
      return false;
    }
    if (menuItem.path === "/ideas") {
      return false;
    }
    if (menuItem.path === "/reporting") {
      return false;
    }
    if (menuItem.path === "/admin") {
      return false;
    }
    if (menuItem.claimProfileOnly) {
      return false;
    }

    return checkForThemes(menuItem.path, selectedCouncil);
  });
};
export default gskFilter;

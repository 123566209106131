import React, {  useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { ModalBody, ModalFooter } from "../../../application/modal";
import { useModelPopup } from "../../../common/hooks";
import { BtnType, Button, Label, TextBox } from "../../primitives/index";
import { postAgreementType, putAgreementType } from "./adminAgreements.action";
import styles from "./adminAgreements.module.scss";

const AgreementModal = (props) => {
  const {
    agreementTypesLength,
    mode,
    uniqTypeValidation,
    agreementTypeOnEdit,
  } = props;
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [agreementTypeName, setAgreementTypeName] = useState(
    mode === "edit" ? agreementTypeOnEdit.name : ""
  );
  const [position, setPosition] = useState(
    mode === "edit" ? agreementTypeOnEdit.position : agreementTypesLength + 1
  );
  const [validationError, setValidationError] = useState({});

  const handleAgreementType = () => {
    if (!agreementTypeName?.length) {
      setValidationError({
        text: "Name is required field",
        type: "emptyTitle",
      });
      return;
    }
    if (uniqTypeValidation(agreementTypeName)) {
      if (mode === "add") {
        dispatch(
          postAgreementType({
            data: {
              position: position > 0 ? position : null,
              name: agreementTypeName,
            },
            enqueueSnackbar,
          })
        );
      } else if (mode === "edit") {
        dispatch(
          putAgreementType({
            data: {
              position,
              name: agreementTypeName,
            },
            agreement_type_id: agreementTypeOnEdit.id,
            enqueueSnackbar,
          })
        );
      }
      popup.hide();
    } else {
      setValidationError({
        text: "This agreement type is already being used",
        type: "uniqTitle",
      });
    }
  };

  return (
    <div>
      <ModalBody>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={styles.addAgreementTypeContainer}>
            <div className={styles.row}>
              <div className={styles.agreementName}>
                <Label>Agreement Name</Label>
                <TextBox
                  autoComplete="false"
                  type="text"
                  value={agreementTypeName}
                  className={styles.textBox}
                  onChange={(e) => {
                    setAgreementTypeName(e.target.value);
                  }}
                  disabled={agreementTypeOnEdit?.existing_fields > 0}
                />
                {validationError.type === "emptyTitle" && !agreementTypeName?.length && (
                  <span className={styles.errorMessage}>{validationError.text}</span>
                )}
                {validationError.type === "uniqTitle" && (
                  <span className={styles.errorMessage}>{validationError.text}</span>
                )}
                {agreementTypeOnEdit?.existing_fields > 0 && (
                  <span className={styles.errorMessage}>
                    This field cannot be edited because there are related existing records
                  </span>
                )}
              </div>
              <div className={styles.agreementPosition}>
                <Label>Position</Label>
                <TextBox
                  autoComplete="false"
                  type="number"
                  value={position}
                  className={styles.textBox}
                  onChange={(e) => {
                    setPosition(Number(e.target.value));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={popup.hide}>
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleAgreementType();
          }}
          btn={BtnType.REGULAR}
        >
          {mode === "edit" ? "Save" : "Add"}
        </Button>
      </ModalFooter>
    </div>
  );
};
export default AgreementModal;

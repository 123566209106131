export const tableHeadersByKey = {
  'total_poc': 'Total PoC',
  'total_approved': 'Total Approved',
  'total_executed': 'Total Executed',
  'poc': 'PoC',
  'idea_generation': 'Idea Generation',
  'implementation': 'Implementation',
  'approved_replications': 'Approved Replications',
  'cib_savings': 'CIB Savings ($)',
  'cib_avarage_tarr': 'CIB Avg TARR',
  'global_tarr': 'Global TARR',
  'job1_accomplishment': 'Job1 Accomplishment',
  'global_savings': 'Global Savings ($)',
}

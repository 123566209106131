import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import { Link } from "react-router-dom";
import { getCookie } from "common/helper";

import mainStyles from "modules/ideasWrapper/ideasWrapper.module.scss";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { updateProjectReplications } from "modules/projects/project/project.action";
import { Icon, mdSize } from "../../icon";
import { useModelPopup } from "../../../common/hooks";
import styles from "./fordPlants.module.scss";
import { httpGet, httpPut } from "../../httpCall";
import ForPossibleReplicationsModal from "./ForPossibleReplicationsModal.component";
import { updateSessionDetails } from "modules/auth/auth.action";

const tooltipTextsByState = {
  different_solution: "Different Solution in Place",
  possible_replication: "Possible Replication",
  under_review: "Under Review",
  not_applicable: "Not Applicable for this Plant",
};
const steps = [
  {
    element: ".replication-icon-edit-ideas",
    intro:
      "Click the Edit icon to select your Plant and Area, and indicate whether this Idea is a “Possible Replication” for your Plant or select another status.",
    position: "left",
  },
];
const role = getCookie("council_role_");

const FordPossibleReplications = ({ canEdit, data, instance }) => {
  const dispatch = useDispatch();
  const [replicationsData, setReplicationsData] = useState(null);
  const popup = useModelPopup();
  const contRef = useRef();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    projectsReducer: {
      projectReducer: { overviewPossibleReplications },
    },
  } = useSelector((state) => state);
  const viewedIdeas = Array.isArray(session?.ideas_intro_status?.ideas)
    ? session?.ideas_intro_status?.ideas
    : [];
  const fordIdeasUserIntroStatusPassed = viewedIdeas?.includes(data?.id);

  useEffect(() => {
    if (instance === "Project") {
      setReplicationsData(overviewPossibleReplications || []);
    }
  }, [overviewPossibleReplications]);

  useEffect(() => {
    if (instance === "Project") {
      return;
    }

    httpGet({
      call: `possible_replications/plants_and_areas?resource_id=${data.id}&resource_type=${instance}`,
    }).subscribe((res) => {
      setReplicationsData(res.response || []);
    });
  }, []);

  const handleAddNewReplications = (data) => {
    dispatch(updateProjectReplications(data));
    setReplicationsData(data);
  };

  const handleEditPlants = () => {
    popup.show({
      title: "Possible Plants + Areas",
      component: (
        <ForPossibleReplicationsModal
          instance={instance}
          replicationsData={replicationsData}
          handleSave={handleAddNewReplications}
          data={data}
        />
      ),
    });
  };

  const makeUserAndTime = useCallback((plant) => {
    const makeTime = format(
      new Date(plant.created_at || plant.created_at),
      "eeee, MMM do, yyyy"
    );

    if (!plant.user?.name) {
      return `Updated on ${makeTime}`;
    }

    return `Updated by ${plant.user.name} on ${makeTime}`;
  }, []);

  const makeReplicationsData = useMemo(() => {
    return replicationsData?.filter((obj) => !obj.discarded_at);
  }, [replicationsData]);

  const handleStart = () => {
    const close = document.querySelector(".introjs-skipbutton");
    if (close) {
      close.style.display = "none";
    }

    setTimeout(() => {
      if (contRef.current) {
        const { top } = contRef.current.getBoundingClientRect();
        // Calculate distance from the top of the element to the top of the viewport
        const distance = top + window.scrollY;
        document.body.style.overflow = "hidden";
        const tooltipHelper = document.querySelector(".introjs-helperLayer");
        if (tooltipHelper) {
          tooltipHelper.style.top = `${distance}px`;
          tooltipHelper.style.marginLeft = "2px";
          tooltipHelper.style.width = `${tooltipHelper.style.width + 35}px`;
        }
      }
    }, 1000);
  };

  const handleExit = () => {
    document.body.style.overflow = "auto";
    const getCurrentSession = session;
    httpPut({
      call: "user/settings/general",
      data: {
        approved_board_member: getCurrentSession.approved_board_member,
        user: {
          ...getCurrentSession,
          ideas_intro_status: {
            ideas: [...viewedIdeas, data.id],
          },
        },
      },
    }).subscribe((res) => {
      const newSession = {
        ...getCurrentSession,
        ideas_intro_status: res?.response?.ideas_intro_status || {},
      };

      dispatch(
        updateSessionDetails({
          session: newSession,
        })
      );
    });

    const tooltipHelper = document.querySelector(".introjs-helperLayer");
    if (tooltipHelper) {
      tooltipHelper.style.display = "none";
    }
  };

  const showTooltip = useMemo(() => {
    const currentUserIsReviewer = data?.ideas_members?.some(
      (m) => m.user_id === session?.id && m.user_id !== data.submitter.user_id
    );
    if (
      selectedCouncil?.traction_tag === "ford" &&
      instance === "Idea" &&
      role !== "viewer" &&
      currentUserIsReviewer &&
      !fordIdeasUserIntroStatusPassed
    ) {
      return true;
    }
    return false;
  }, [
    data,
    session,
    selectedCouncil,
    instance,
    role,
    fordIdeasUserIntroStatusPassed,
  ]);

  return (
    <div className="d-flex flex-column mb-4">
      <div
        className={`d-flex align-items-center justify-content-between replication-icon-edit-ideas ${mainStyles.blockHeader}`}
        ref={contRef}
      >
        <span className={mainStyles.blockTitle}>Possible Replications</span>
        {(canEdit || data?.possible_plants_edit_access) && (
          <Icon
            {...mdSize}
            icon="icn-edit-button"
            className={` ${mainStyles.messageIcon}`}
            onClick={handleEditPlants}
          />
        )}
      </div>
      <div className={`${styles.content} ${mainStyles.blockContent}`}>
        {makeReplicationsData?.length > 0 ? (
          <>
            {makeReplicationsData.map((obj) => (
              <div key={obj.id} className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <div className="col-8 d-flex flex-column text-center pl-0">
                    <span className="text-left">Plant</span>
                    {obj.project &&
                    obj.state === "different_solution" &&
                    obj.project.id ? (
                      <Tooltip title={obj.reason} placement="top">
                        <Link
                          className="font-weight-bold text-left"
                          to={`/projects/${obj.project.id}/overview`}
                        >
                          {obj.name}
                        </Link>
                      </Tooltip>
                    ) : (
                      <Tooltip title={obj.reason} placement="top">
                        <span className="font-weight-bold text-left">
                          {obj.name}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="col-4 d-flex flex-column align-items-end pr-0">
                    <span>Status</span>
                    <Tooltip
                      title={tooltipTextsByState[obj.state]}
                      placement="top"
                    >
                      <div
                        className={classNames(
                          styles.plantCircle,
                          styles[obj.state]
                        )}
                      ></div>
                    </Tooltip>
                  </div>
                </div>
                {obj.areas?.length > 0 ? (
                  <div className="d-flex flex-column mt-3">
                    <span>Areas:</span>
                    <ul className="pl-4 mb-0">
                      {obj.areas.map((area) => (
                        <li
                          key={area.id}
                          className="d-flex align-items-center justify-content-between"
                        >
                          <Tooltip title={area.reason} placement="top">
                            {area.project?.id ? (
                              <Link
                                to={`/projects/${area.project.id}/overview`}
                              >
                                {area.name}
                              </Link>
                            ) : (
                              <span>{area.name}</span>
                            )}
                          </Tooltip>
                          <Tooltip
                            title={tooltipTextsByState[area.state]}
                            placement="top"
                          >
                            <div
                              className={classNames(
                                styles.plantCircle,
                                styles[area.state]
                              )}
                            ></div>
                          </Tooltip>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
                <span className="small text-muted mt-2">
                  {makeUserAndTime(obj)}
                </span>
                {makeReplicationsData.length > 1 ? (
                  <hr className="mt-3 mb-3" />
                ) : null}
              </div>
            ))}
          </>
        ) : !makeReplicationsData ? (
          <LoadingComponent customText="Getting replications..." />
        ) : (
          "No replications"
        )}
      </div>
      {showTooltip && (
        <Steps
          tooltipPosition="left"
          enabled
          doneLabel={"OK"}
          steps={steps}
          initialStep={0}
          onStart={handleStart}
          onExit={handleExit}
          options={{
            showBullets: false,
            doneLabel: "OK",
          }}
        />
      )}
    </div>
  );
};

export default FordPossibleReplications;

export const tabs = [
  "Overview",
  "Details",
  "Notes",
  "Products",
  "Projects",
  "Documents",
  "Discussions",
];

export const patient_org_tabs = [
  "Overview",
  "Details",
  "Engagement",
  "Projects",
  "Documents",
];

export const COMPANY_DOWNLOAD = {
  tabs,
  isAllSelected: true,
};

export const PATIENT_ORG_DOWNLOAD = {
  tabs: patient_org_tabs,
  isAllSelected: true,
};

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { format } from "date-fns";

import { MoreOptions, DatePiker } from "modules/primitives";
import { ACTIVITY_OPTIONS_WITHOUT_DELETE } from "common/constants.js";
import styles from "./styles.module.scss";
import { Icon, smSize } from "../../../../../../common/icon";

const DateComponent = ({ item, handleEditClick, handleUpdateForm, disabled }) => {
  const [fieldValue, setFieldValue] = useState('');
  const [showField, setShowField] = useState(false);

  useEffect(() => {
    if (item.field_value?.length) {
      setFieldValue(item.field_value);
    } else {
      setShowField(true);
    }
  }, [item]);

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }
  };

  const handleSaveClick = (val) => {
    handleUpdateForm({
      ...item,
      field_value: val,
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{item?.field_name || "Date"}</h5>
          {
            item?.field_placeholder?.length ? (
              <span className="text-muted small">{item.field_placeholder}</span>
            ) : null
          }
        </div>
        {!disabled && (
          <div className={styles.rightWrp}>
            <div className={styles.colMore}>
              <MoreOptions
                options={ACTIVITY_OPTIONS_WITHOUT_DELETE}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            </div>
          </div>
        )}
      </div>
      {
        item.field_value && !showField ? (
          <div className="d-flex">
            <p
              className={classNames("small", styles.description)}
              dangerouslySetInnerHTML={{
                __html: format(new Date(item.field_value), "EEEE, MMMM dd, yyyy"),
              }}
            />
            <div className="cursor-pointer ml-2" onClick={() => setShowField(true)}>
              <Icon
                {...smSize}
                icon="icn-edit"
              />
            </div>
          </div>
        ) : null
      }

      {
        showField ? (
          <div className="w-75 d-flex">
            <DatePiker
              name="field_value"
              value={fieldValue}
              onChange={handleSaveClick}
              showTime
              withClear
            />
          </div>
        ) : null
      }
    </div>
  );
};
export default React.memo(DateComponent);

export const filterOptions = [
  // { text: "All", value: "all" },
  { text: "Plants", value: "plant_ids", searchable: true },
  { text: "Area", value: "area_ids", searchable: true },
  { text: "Pillar", value: "theme_ids", searchable: true },
  { text: "Technology", value: "topic_ids", searchable: true },
  {
    text: "Rating",
    value: "rating",
    range: true,
    ranges: [
      [0, 1],
      [1, 2],
      [2, 3],
      [4, 5],
    ],
  },
  {
    text: "Average Savings",
    value: "savings",
    range: true,
    ranges: [
      [0, 50000],
      [50000, 200000],
      [200000, 500000],
      [500000, 1000000],
      [1000000, null],
    ],
  },
  { text: "Skills Team", value: "project_field_values", namesOnly: true },
  { text: "FPS Category", value: "fps_category", namesOnly: true },
  { text: "Funded By", value: "founded_by", namesOnly: true },
  { text: "Program", value: "program", namesOnly: true },
];

export default filterOptions;

/* eslint-disable camelcase */
import * as Yup from "yup";
import { format } from "date-fns";

export const initModel = {
  title: "",
  description: "",
  starts_on: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  ends_on: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  location: "",
  website_uri: "",
  live: "",
  videos: [],
  slides: [],
  speakers: [],
  company_ids: [],
  video_text: "",
};

export const videoExtensions = [
  "mp4",
  "mov",
  "wmv",
  "flv",
  "avi",
  "avi",
  "webm",
  "mkv",
];

export const initModelSpeaker = {
  title: "",
  name: "",
  organization: "",
  avatar: "",
  council_speaker: "",
};

export const validation = Yup.object().shape({
  title: Yup.string().required("Title is required"),
});

export const speakerValidation = Yup.object().shape({
  name: Yup.string()
    .strict()
    .trim("Input must contain characters")
    .required("Speaker name is required"),
});

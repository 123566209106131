import React, { useState, useEffect } from "react";
import Select from "react-dropdown-select";
import classNames from "classnames";

import styles from "../../../scss/multiselect/multiselect.module.scss";
import "../../../scss/multiselect/multiselect.scss";

const MultiSelectComponent = (props) => {
  const {
    className,
    name,
    SelecedValues = null,
    withClear = false,
    onClear = null,
    formProps: {
      errors = {},
      touched = {},
      values = [],
      handleBlur = null,
      handleChange = null,
      setFieldValue = null,
      setFieldTouched = null,
    } = {},
    options = [],
    selectAll = false,
    selectAllItem = "",
    labelField,
    valueField,
    placeholder,
    disabled = false,
    onChange,
    multi = true,
    ...rest
  } = props;

  const [dropValues, setDropValues] = useState([]);
  const [onDropChange, setOnDropChange] = useState(false);

  useEffect(() => {
    if (onDropChange) {
      setOnDropChange(false);
      if (setFieldTouched) setFieldTouched(name);
      if (setFieldValue) {
        setFieldValue(
          name,
          dropValues.map((obj) => obj[valueField])
        );
        values[name] = dropValues.map((obj) => obj[valueField]);
      }
    }
  }, [onDropChange]);

  useEffect(() => {
    if ((SelecedValues || values[name]) && options && options.length > 0) {
      const selVal =
        SelecedValues && SelecedValues.length > 0
          ? SelecedValues
          : values[name] && values[name].length > 0
          ? values[name]
          : [];

      const selectedFullVal = selVal.map((val) => {
        if (val === "all") {
          return { [valueField]: "all", [labelField]: selectAllItem };
        }
        return options.find((opt) => opt[valueField] === val);
      });
      if (selectedFullVal && selectedFullVal.length > 0) {
        setDropValues(selectedFullVal.filter((item) => !!item));
      }
    }
  }, [SelecedValues, options, values[name]]);

  const handleOnChange = (val) => {
    if (onChange) onChange(val);
    let newlist = val;
    if (val && val.length > 1) {
      newlist = val.filter((item) => item[valueField] !== "all");
    }
    setDropValues(newlist);
    setOnDropChange(true);
  };

  const handleSelectAllOnClick = () => {
    setDropValues([{ id: "all", name: selectAllItem }]);
    setOnDropChange(true);
  };

  const handleClearAllOnClick = () => {
    setDropValues([]);
    setOnDropChange(true);
  };

  return (
    <div
      className={classNames(
        styles.multiWrp,
        className,
        name && touched[name] && errors[name] && styles.error
      )}
    >
      <div className={styles.container}>
        {!onDropChange && (
          <Select
            multi={multi}
            closeOnSelect
            disabled={disabled}
            values={dropValues}
            className={styles.dorpWrp}
            onChange={handleOnChange}
            options={options}
            labelField={labelField}
            searchBy={labelField}
            valueField={valueField}
            placeholder={placeholder}
            {...rest}
          />
        )}
      </div>
      {selectAll && (
        <div className={classNames(styles.multifunc, "caption small")}>
          <div onClick={(e) => handleSelectAllOnClick(e)}>Select all</div>{" "}
          <div onClick={(e) => handleClearAllOnClick(e)}>Clear all</div>
        </div>
      )}
      {withClear && (
        <div className={classNames(styles.multifunc, "caption small d-flex justify-content-end")}>
          <div
            onClick={(e) => {
              onClear && onClear();
              handleClearAllOnClick(e);
            }}
          >
            Clear
          </div>
        </div>
      )}
      <div className={classNames(styles.errorMsg, "caption small")}>
        {touched[name] && errors[name] ? errors[name] : ""}
      </div>
    </div>
  );
};

export default React.memo(MultiSelectComponent);

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";

import { checkViewerRole } from "common/checkers/viewerChecker";
import { isProjectAllowedEditor } from "common/checkers/isProjectAllowedEditor";

import ApprovalTaskHeader from "modules/projects/project/tasks/approvalTask/approvalTaskHeader.component";
import ApprovalTaskBody from "modules/projects/project/tasks/approvalTask/approvalTaskBody.component";
import {
  appSelectedSet,
  appModelSettingSet,
  appSliderSettingSet,
} from "application/app.action";

import {
  State,
  MultiUsers,
  CheckBox,
  CheckType,
  MoreOptions,
} from "../../../../primitives";
import { Icon, mdSize } from "../../../../../common/icon";
import styles from "./taskRow.module.scss";
import { taskOptions } from "../tasks.constants";
import {
  customProjectTaskUpdate,
  getTaskAdditionalData,
  projectTaskDelete,
  taskCompanyDocumentsSave,
} from "../tasks.action";
import CompanyRowComponent from "../companyRow";
import { useModelPopup } from "../../../../../common/hooks";
import TaskNewComponent from "../taskNew";
import TaskDueDate from "../taskDueDate";
import { TaskInfoComponentV2, TaskInfoHeaderComponentV2 } from "../taskInfo";
import TaskDocumentsModal from "./taskDocumentsModal/index";
import { checkIfFirmenich } from "../../isFerminichCouncil";
import LoaderWithDots from "../../../../../common/components/loaderWithDots";

const TaskRowComponent = (props) => {
  const {
    item,
    patientOrg,
    activeTab = "all_task",
    shouldOpen,
    activePage,
    blockFordOnly,
    councilRole,
    currentUserProjRole,
  } = props;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showCompanies, setShowCompanies] = useState(false);
  const [sortCompany, setSortCompany] = useState([]);
  const [options, setOptions] = useState([]);
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [hasDocuments, setHasDocuments] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const history = useHistory();
  const popup = useModelPopup();

  const {
    appReducer: { selectedItem, modalSetting, sliderSetting },
    councilReducer: { selectedCouncil },
    projectsReducer: {
      projectReducer: { selectedProject },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    const simpleDocs =
      item?.projects_task_assignments?.length &&
      item?.projects_task_assignments?.find(
        (company) => company?.documents?.length
      );
    const templateDocs =
      item?.council_task_template &&
      Boolean(item?.council_task_template?.documents?.length);

    setHasDocuments(templateDocs || simpleDocs);
  }, [item]);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    const checkProps = makeCheckBoxProps();

    if (item.task_type === "approval_gate") {
      setOptions(taskOptions.filter((o) => o.id !== "complete"));
      return;
    }

    if (checkProps.isChecked && checkProps.checkType === CheckType.GREEN) {
      const newOptions = taskOptions.map((o) => {
        if (o.id === "complete") {
          return {
            ...o,
            text: "Mark all as not completed",
            markAllAsComplete: false,
          };
        }

        return o;
      });
      setOptions(newOptions);
    } else {
      setOptions(taskOptions);
    }
  }, [item]);

  const handleDownloadDocument = (event, documents, companies) => {
    event.stopPropagation();
    popup.show({
      title: "Task Documents",
      component: (
        <TaskDocumentsModal
          popup={popup}
          documents={documents}
          companies={companies}
        />
      ),
    });
  };

  const showTaskDetails = (task) => {
    // const urlParams = new URLSearchParams(window.location.search);
    // const taskIdQuery = urlParams.get('taskId');

    // bellow code is to check if there is already an opened task window,
    // so we want to avoid quick opening of another one,
    // so first we close it and next click will open again task window;
    // if (taskIdQuery?.length && item.id !== taskIdQuery) {
    //   const currPageQuery = urlParams.get('page');
    //   const currTabQuery = urlParams.get('tab');
    //   const tabParam = currTabQuery ? `?tab=${currTabQuery}` : '';
    //   const pageParam = currPageQuery ? `&page=${currPageQuery}` : '';
    //
    //   history.push({
    //     pathname: `/projects/${item.project_id}/task${tabParam}${pageParam}`,
    //   });
    //
    //   return;
    // }
    //
    // let search = queryStringUrlReplacement(
    //   queryStringUrlReplacement(
    //     history.location.search,
    //     "taskAsignementId",
    //     ""
    //   ),
    //   "taskId",
    //   item.id
    // );
    // search = queryStringUrlReplacement(
    //   search,
    //   "page",
    //   activePage
    // );
    // search = queryStringUrlReplacement(
    //   search,
    //   "tab",
    //   activeTab
    // );
    // history.push({
    //   pathname: `/projects/${item.project_id}/task`,
    //   search,
    //   state: {
    //     ...history.location?.state,
    //     taskId: item.id,
    //     page: activePage,
    //     tab: activeTab
    //   },
    // });

    dispatch(appSelectedSet(`task${item.id}`));

    if (item.task_type === "project_specific" || isGateWayTask) {
      showTask(task);

      return;
    }

    setShowCompanies(!showCompanies);
  };

  const handleTaskExpand = () => {
    if (item.locked || sliderSetting.show) {
      return;
    }

    if (item.hasAdditionalData) {
      showTaskDetails(item);
    } else {
      setShowLoading(true);
      dispatch(
        getTaskAdditionalData({
          projectId: selectedProject.id,
          taskId: item.id,
          activeTab,
          cb: (newTaskItem) => {
            setShowLoading(false);
            showTaskDetails(newTaskItem);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (shouldOpen === item?.id) {
      handleTaskExpand();
    }
  }, [shouldOpen]);

  const showTask = (task) => {
    if (isGateWayTask) {
      dispatch(
        appSliderSettingSet({
          ...sliderSetting,
          show: true,
          height: "300",
          width: "540",
          header: (
            <ApprovalTaskHeader
              task={task}
              activeTab={activeTab}
              activePage={activePage}
            />
          ),
          component: (
            <ApprovalTaskBody
              taskId={task.id}
              blockFunctionality={blockFunctionality}
              activeTab={activeTab}
              blockFordOnly={blockFordOnly}
            />
          ),
          taskWindowDetails: {
            projectId: task.project_id,
            activeTab,
          },
        })
      );
      return;
    }

    if (task.projects_task_assignments.length) {
      dispatch(
        taskCompanyDocumentsSave({
          documents: task.projects_task_assignments[0].documents,
        })
      );
      dispatch(
        appSliderSettingSet({
          ...sliderSetting,
          show: true,
          height: "300",
          width: "540",
          header: (
            <TaskInfoHeaderComponentV2
              taskId={task.id}
              projectId={task.project_id}
              assignmentId={task.projects_task_assignments[0].id}
              activeTab={activeTab}
              completionEnabled={task.completion_enabled}
              task={task}
            />
          ),
          component: (
            <TaskInfoComponentV2
              task={task}
              assignment={task.projects_task_assignments[0]}
              blockFordOnly={blockFordOnly}
            />
          ),
          taskWindowDetails: {
            projectId: task.project_id,
            activeTab,
          },
        })
      );
    }
  };

  const handleMoreOptionClick = (value) => {
    switch (value) {
      case "mark_all_complete":
        const getCheckValue = options.find((o) => o.id === "complete");
        handleOnChange(getCheckValue.markAllAsComplete);
        break;
      case "delete_task":
        dispatch(
          projectTaskDelete({
            projectId: selectedProject.id,
            taskId: item.id,
            enqueueSnackbar,
          })
        );
        break;
      case "edit":
        dispatch(
          appModelSettingSet({
            ...modalSetting,
            title: "Edit task",
            show: true,
            height: "640",
            width: "540",
            component: (
              <TaskNewComponent
                currentPage={activePage}
                activeTab={activeTab}
                defaultModel={item}
                mode="edit"
              />
            ),
          })
        );
        break;
      default:
        alert(value);
        break;
    }
  };

  const handleOnChange = (value) => {
    if (item.disable_manual_completion) {
      return;
    }

    const payload = {
      enqueueSnackbar,
      taskId: item.id,
      projectId: item.project_id,
      flag: value ? "completed_true" : "completed_false",
      project_task: {
        completed_on: value ? format(new Date(), "yyyy-MM-dd") : null,
      },
      queryParams: {
        all_assignments: true,
      },
    };

    dispatch(customProjectTaskUpdate(payload));
  };

  const makeCheckBoxProps = useCallback(() => {
    if (item.task_completion_state === "not_completed") {
      return { isChecked: false };
    }

    return {
      isChecked: true,
      checkType:
        item.task_completion_state === "half_completed"
          ? CheckType.HALF_BLUE
          : CheckType.GREEN,
    };
  }, [item]);

  const isGateWayTask = useMemo(
    () => item.task_type === "approval_gate",
    [item.task_type]
  );

  const getGatewayTaskLabel = useMemo(() => {
    if (item.approval_type === "review") {
      return "Review Task";
    }

    return "Approval Task";
  }, [item.approval_type]);

  const isFirmenich = useMemo(() => {
    return checkIfFirmenich(selectedCouncil, selectedProject);
  }, [selectedCouncil, selectedProject]);

  return (
    <>
      <div
        key={item.id}
        className={classNames(
          styles.taskRow,
          selectedItem === `task${item.id}` && styles.selected,
          item.locked ? styles.lockedTask : ""
        )}
      >
        <div className={styles.colSorting}>
          {showLoading && <LoaderWithDots hideText />}
          {/* <Icon {...mdSize} icon="icn-form-move" className={styles.icon} /> */}
        </div>
        <div className={styles.colCheck}>
          {!blockFunctionality && (
            <Tooltip
              title={
                !item.completion_enabled
                  ? item.mark_on_document_upload
                    ? "Upload the required document to complete this Task"
                    : "Fill out the required linked fields to complete this Task"
                  : ""
              }
            >
              <div>
                <CheckBox
                  {...makeCheckBoxProps()}
                  onChange={handleOnChange}
                  disabled={
                    isGateWayTask || item.locked || !item.completion_enabled
                  }
                  readOnly={
                    isGateWayTask || item.locked || !item.completion_enabled
                  }
                />
              </div>
            </Tooltip>
          )}
        </div>
        <div className={styles.colTask} onClick={handleTaskExpand}>
          <span>{item.name}</span>
          {isGateWayTask && item.approval_type && (
            <span className="small text-muted">{getGatewayTaskLabel}</span>
          )}
        </div>
        {isFirmenich && (
          <div className={styles.colTask} onClick={handleTaskExpand}>
            {item.task_type === "project_specific" ? "Milestone" : "Company"}
          </div>
        )}
        <div
          className={classNames(styles.colActivity, "px-3")}
          onClick={handleTaskExpand}
        >
          {item?.last_activity?.details || "No activity yet"}
        </div>
        <div
          className={classNames(styles.colLastActivity)}
          onClick={handleTaskExpand}
        >
          {item.last_activity?.created_at
            ? `${item.last_activity.created_at} ago`
            : "-"}
        </div>

        {isFirmenich ? (
          <></>
        ) : (
          <div
            className={classNames(styles.taskcol, styles.colStage)}
            onClick={handleTaskExpand}
          >
            {item.stage_id && <State stageId={item.stage_id} />}
          </div>
        )}

        <div
          className={classNames(styles.taskcol, styles.colAssign)}
          onClick={handleTaskExpand}
        >
          {item.assigned_users?.length ? (
            <div className={styles.assignInner}>
              <MultiUsers
                users={item.assigned_users.map((u) => ({ ...u, ...u.user }))}
                total={item.assigned_users.length}
                disabled={item.locked}
              />
            </div>
          ) : null}
        </div>
        <div
          className={classNames(styles.taskcol, styles.colDue)}
          onClick={handleTaskExpand}
        >
          <TaskDueDate task={item} />
        </div>

        <div className={classNames(styles.taskcol, styles.colmore)}>
          {hasDocuments && !item.locked ? (
            <div>
              <Icon
                {...mdSize}
                icon="icn-subnav-documents"
                onClick={(e) =>
                  handleDownloadDocument(
                    e,
                    item?.council_task_template?.documents,
                    item?.projects_task_assignments
                  )
                }
                className={styles.docIcon}
              />
            </div>
          ) : null}
        </div>

        <div className={classNames(styles.taskcol, styles.colmore)}>
          {isProjectAllowedEditor(councilRole, currentUserProjRole) &&
            !item.locked &&
            !blockFordOnly && (
              <MoreOptions
                options={options}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
              />
            )}
        </div>
        <div
          className={classNames(
            styles.taskcol,
            styles.colOther,
            sortCompany.length === 0 && styles.disabled
          )}
        >
          <Icon {...mdSize} icon="icn-table-sub-task" className={styles.icon} />
        </div>
      </div>
      {showCompanies && item.projects_task_assignments?.length > 0 && (
        <div>
          {item.projects_task_assignments.map((taskObj) => (
            <CompanyRowComponent
              key={taskObj.id}
              obj={taskObj}
              isGSK={patientOrg}
              task={item}
              blockFunctionality={blockFunctionality}
              completionEnabled={item.completion_enabled}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default React.memo(TaskRowComponent);

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useSnackbar } from "notistack";
import styles from "../../companies/companyMain/companyMain.module.scss";
import LeftWindowComponent from "./leftWindow";
import RightWindowComponent from "./rightWindow";
import { countryCheck, deseasCheck } from "./patientOrganizationMain.action";
import { searchInCouncil } from "../../companies/companyMain/companyMain.action";
import { useWindowSize } from "../../../common/hooks/useWindowSize";
import PatientOrgMobileFilter from "modules/patientOrganizations/patientOrganizationMain/patientOrgMobileFilters";

const PatientOrganizationMainComponent = () => {
  const {
    councilReducer: { selectedCouncil },
    searchReducer: { query },
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: { checkedPatientFilters, countryFilters },
    },
  } = useSelector((state) => state);

  const { isMobile, isTablet } = useWindowSize();
  const location = useLocation();
  const dispatch = useDispatch();
  const [checkedDiversityGroups, setCheckedDiversityGroups] = useState([]);
  const [checkedDiseasesFilters, setCheckedDiseasesFilters] = useState([]);
  const [checkedCountryFilters, setCheckedCountryFilters] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setCheckedDiversityGroups(checkedPatientFilters.diversity_group_ids || []);
    setCheckedDiseasesFilters(checkedPatientFilters.disease_ids || []);
    setCheckedCountryFilters(countryFilters || []);
  }, [checkedPatientFilters, countryFilters]);

  const handleCheck = useCallback(
    (val, item, type, child) => {
      if (type === "diversityGroups") {
        setCheckedDiversityGroups((prev) => {
          const arrayWithUniqId = [...prev];
          const i = arrayWithUniqId.indexOf(item);
          if (i === -1) arrayWithUniqId.push(item);
          else arrayWithUniqId.splice(i, 1);

          dispatch(
            deseasCheck({
              diversity_group_ids: [...arrayWithUniqId],
            }),
          );
          // TODO refactor and use endpoint suggested by Darek
          dispatch(
            searchInCouncil({
              data: query,
              topic_ids: [],
              industry_ids: [],
              council_id: selectedCouncil.id,
              disease_ids: checkedDiseasesFilters,
              mode: "companies",
              gsk: true,
              patient_org: true,
              sort_attribute: location.state?.sortAttribute || "",
              sort_order: location.state?.sortOrder || "",
              countries: checkedCountryFilters,
              diversity_group_ids: arrayWithUniqId,
              enqueueSnackbar,
              cb: () => {},
            }),
          );
          return [...arrayWithUniqId];
        });
      }

      if (type === "disease") {
        setCheckedDiseasesFilters((prev) => {
          let arrayWithUniqId = [...prev];

          if (child && child?.length) {
            const childIds = child.map((i) => i.id);
            if (val) {
              arrayWithUniqId = [...arrayWithUniqId, item, ...childIds];
            } else {
              arrayWithUniqId = arrayWithUniqId.filter(
                (id) => id !== item && !childIds.includes(id),
              );
            }
          } else {
            const i = arrayWithUniqId.indexOf(item);
            if (i === -1) arrayWithUniqId.push(item);
            else arrayWithUniqId.splice(i, 1);
          }

          dispatch(
            deseasCheck({
              disease_ids: [...arrayWithUniqId],
            }),
          );
          // TODO refactor and use endpoint suggested by Darek
          dispatch(
            searchInCouncil({
              data: query,
              topic_ids: [],
              industry_ids: [],
              council_id: selectedCouncil.id,
              disease_ids: [...arrayWithUniqId],
              mode: "companies",
              gsk: true,
              patient_org: true,
              sort_attribute: location.state?.sortAttribute || "",
              sort_order: location.state?.sortOrder || "",
              countries: [...checkedCountryFilters],
              diversity_group_ids: checkedDiversityGroups,
              enqueueSnackbar,
              cb: () => {},
            }),
          );
          return [...arrayWithUniqId];
        });
      }

      if (type === "country") {
        setCheckedCountryFilters((prev) => {
          const arrayWithUniqCountry = [...prev];
          const i = arrayWithUniqCountry.indexOf(item);
          if (i === -1) arrayWithUniqCountry.push(item);
          else arrayWithUniqCountry.splice(i, 1);

          dispatch(countryCheck([...arrayWithUniqCountry]));
          // TODO refactor and use endpoint suggested by Darek
          dispatch(
            searchInCouncil({
              data: query,
              topic_ids: [],
              industry_ids: [],
              council_id: selectedCouncil.id,
              disease_ids: checkedDiseasesFilters,
              mode: "companies",
              gsk: true,
              patient_org: true,
              sort_attribute: location.state?.sortAttribute || "",
              sort_order: location.state?.sortOrder || "",
              countries: [...arrayWithUniqCountry],
              diversity_group_ids: checkedDiversityGroups,
              enqueueSnackbar,
              cb: () => {},
            }),
          );
          return [...arrayWithUniqCountry];
        });
      }
    },
    [query, selectedCouncil, checkedDiseasesFilters, checkedCountryFilters],
  );

  const handleReset = () => {
    setCheckedDiseasesFilters([]);
    setCheckedCountryFilters([]);
    dispatch(
      deseasCheck({
        disease_ids: [],
        diversity_group_ids: [],
      }),
    );
    dispatch(countryCheck([]));
    dispatch(
      searchInCouncil({
        data: query,
        topic_ids: [],
        industry_ids: [],
        council_id: selectedCouncil.id,
        disease_ids: [],
        mode: "companies",
        gsk: true,
        patient_org: true,
        countries: [],
        diversity_group_ids: [],
        sort_attribute: location.state?.sortAttribute || "",
        sort_order: location.state?.sortOrder || "",
        enqueueSnackbar,
        cb: () => {},
      }),
    );
  };

  return (
    <div className={styles.companyWrapper}>
      {
        !isMobile && !isTablet && (
          <LeftWindowComponent
            handleCheck={handleCheck}
            checkedDiseasesFilters={checkedDiseasesFilters}
            checkedCountryFilters={checkedCountryFilters}
            checkedDiversityGroups={checkedDiversityGroups}
            handleReset={handleReset}
          />
        )
      }
      {
        (isMobile || isTablet) && (
          <PatientOrgMobileFilter
            handleCheck={handleCheck}
            checkedDiseasesFilters={checkedDiseasesFilters}
            checkedCountryFilters={checkedCountryFilters}
            checkedDiversityGroups={checkedDiversityGroups}
            handleReset={handleReset}
          />
        )
      }
      <RightWindowComponent fullScreen={isMobile || isTablet} />
    </div>
  );
};

export default React.memo(PatientOrganizationMainComponent);

import * as Yup from "yup";

export const initModel = {
  first_name: "",
  last_name: "",
  avatar: "",
  email: "",
  mobile: "",
  title: "",
  name: "",
};

export const validation = Yup.object().shape({
  email: Yup.string().email().required("Email is required."),
  first_name: Yup.string().required("First Name is required."),
  last_name: Yup.string().required("Last Name is required."),
  mobile: Yup.number()
    .typeError("Phone must be a number type.")
    .required("Mobile is required"),
});

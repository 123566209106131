import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import styles from "modules/reporting/customReportsStyles.module.scss";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "modules/primitives";
import Dialog from "@mui/material/Dialog";

const IdeasList = ({ data, config }) => {
  const dataToUse = data[config.propToUse] || [];
  const [open, setOpen] = useState(false);
  const hasShowMore = dataToUse.length > 5;

  const handleShowMore = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  if (!dataToUse?.length) {
    return '-';
  }

  return (
    <div>
      <span>Total: {dataToUse?.length};</span>
      <div className="d-flex flex-column">
        {
          dataToUse.slice(0, hasShowMore ? 5 : dataToUse.length).map(idea => (
            <a
              href={`/ideas/${idea.id}`}
              className="cursor-pointer"
              key={idea.id}
              target="_blank"
            >
              {idea.name || '-'}
            </a>
          ))
        }
        {
          hasShowMore ? (
            <span
              onClick={handleShowMore}
              className={`${styles.showMoreData} d-block mt-3`}
            >+ {dataToUse.length - 5}</span>
          ) : null
        }
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <span>Total: {dataToUse?.length};</span>
          <div className="d-flex flex-column">
            {
              dataToUse.map(idea => (
                <a
                  href={`/ideas/${idea.id}`}
                  className="cursor-pointer"
                  key={idea.id}
                  target="_blank"
                >
                  {idea.name || '-'}
                </a>
              ))
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(IdeasList);

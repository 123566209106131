import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "../rightWindow.module.scss";
import { Pagination } from "../../../../primitives";
import { PAGINATION_DEFAULTS } from "../../../constants";
import { getTitleByValue, layoutType } from "../../companyMain.constant";
import {
  queryStringUrlReplacement,
  scrollStop,
} from "../../../../../common/helper";
import CrunchItemWrapperComponent from "../crunchItemWrapper";
import { useCompanyContext } from "modules/companies/companyMain/providers/companyDataProvider";

const CrunchResultsComponent = ({
  query,
  page,
  isFullWidth,
}) => {
  const history = useHistory();
  const {
    addToTraction,
    selectLayout,
  } = useCompanyContext();

  const [pageSetting, setPageSetting] = useState({
    ...PAGINATION_DEFAULTS,
    pageSize: 50,
    hideLastAndFirst: true,
  });
  const [userIsScrolling, setUserIsScrolling] = useState(false);

  const {
    companiesReducer: {
      companyMainReducer: { crunchCompaniesSearched },
    },
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    document.querySelector("#rightWindow").addEventListener(
      "scroll",
      () => {
        setUserIsScrolling(true);
      },
      false
    );

    scrollStop(
      "#rightWindow",
      () => {
        setUserIsScrolling(false);
      },
      1000
    );

    return () => {
      const elem = document.querySelector("#rightWindow");

      if (elem) {
        elem.removeEventListener("scroll", null);
      }
    };
  }, []);

  useEffect(() => {
    if (
      crunchCompaniesSearched &&
      crunchCompaniesSearched.count &&
      page !== pageSetting.current &&
      page
    ) {
      setPageSetting({
        ...pageSetting,
        current: Number(page),
        total: crunchCompaniesSearched.count,
      });

      return;
    }

    if (crunchCompaniesSearched && crunchCompaniesSearched.count) {
      setPageSetting({
        ...pageSetting,
        total: crunchCompaniesSearched.count,
      });
    }

    return () => null;
  }, [crunchCompaniesSearched, page]);

  const handleCrunchBaseSearch = (isNextPage, page) => {
    const title = getTitleByValue(selectLayout);

    const value = isNextPage
      ? crunchCompaniesSearched.entities[
          crunchCompaniesSearched.entities.length - 1
        ].uuid
      : crunchCompaniesSearched.entities[0].uuid;
    const search1 = queryStringUrlReplacement(
      history.location.search,
      "afterId",
      isNextPage ? value : ""
    );
    const search2 = queryStringUrlReplacement(
      search1,
      "beforeId",
      isNextPage ? "" : value
    );
    const search = queryStringUrlReplacement(search2, "page", page);

    history.push({
      pathname:
        selectLayout === layoutType.card ? "/companies" : `/companies/${title}`,
      search,
      state: {
        ...history.location?.state,
        afterId: isNextPage ? value : "",
        beforeId: isNextPage ? "" : value,
        page,
      },
    });
  };

  const handlePagingChange = (page) => {
    if (pageSetting.current === page) {
      return;
    }

    const isNextPage = pageSetting.current < page;

    handleCrunchBaseSearch(isNextPage, page);
  };

  if (!crunchCompaniesSearched || !crunchCompaniesSearched.entities) {
    return (
      <h3 className="d-flex justify-content-center w-100">
        {query && query.length > 0 ? "Loading..." : "Search above."}
      </h3>
    );
  }

  return (
    <>
      <div className={`${isFullWidth ? styles.fullWidthCrunchResults : ""}`}>
        <div>
          <p>
            <span className={styles.text24px}>
              Results from Crunchbase for "{query}" :{" "}
              {crunchCompaniesSearched.count}{" "}
            </span>
            <span className={styles.text14rightpx}>
              {" "}
              Click + Add Company to add to Traction
            </span>
          </p>
        </div>
        <div className={styles.completedCompaniesStyles}>
          {crunchCompaniesSearched.entities[0] &&
            crunchCompaniesSearched.entities.map((item) => (
              <CrunchItemWrapperComponent
                key={item.uuid}
                item={item}
                addToTraction={addToTraction}
                history={history}
                userIsScrolling={userIsScrolling}
                session={session}
                selectedCouncil={selectedCouncil}
              />
            ))}
        </div>
        <div className="w-100 d-flex justify-content-center mt-4 mb-4">
          <Pagination {...pageSetting} onChange={handlePagingChange} />
        </div>
      </div>
    </>
  );
};

export default React.memo(withSnackbar(CrunchResultsComponent));

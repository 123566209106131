import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  httpGet,
  httpPost,
  httpPut,
  httpDelete,
  errorHandler,
} from "../../../../common/httpCall";

import {
  PIPELINE_GET_STAGES_ATTEMPT,
  pipelineGetStagesSuccess,
  PIPELINE_ADD_STAGE_ATTEMPT,
  pipelineAddStageSuccess,
  PIPELINE_EDIT_STAGE_ATTEMPT,
  PIPELINE_DELETE_STAGE_ATTEMPT,
  pipelineDeleteStageSuccess,
  PIPELINE_GET_INVEST_STAGES_ATTEMPT,
  pipelineGetInvestStagesSuccess,
  THEME_PIPELINE_COMPANIES_SEARCH_ATTEMPT,
  pipelineSearchCompaniesSuccess,
  PIPELINE_EDIT_STAGE_ACROSS_ATTEMPT,
  pipelineGetStagesAttempt,
  pipelineGetInvestStagesAttempt,
  pipelineEditStageAcrossSuccess,
} from "./pipelineTab.action";

export const epicGetPipelineStages = (action$, state$) =>
  action$.pipe(
    ofType(PIPELINE_GET_STAGES_ATTEMPT),
    switchMap(({ payload: { themeId, enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v1",
        call: `themes/${themeId}/council_relationship_stages`,
      }).pipe(
        map((result) => pipelineGetStagesSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetPipelineInvestStages = (action$, state$) =>
  action$.pipe(
    ofType(PIPELINE_GET_INVEST_STAGES_ATTEMPT),
    switchMap(({ payload: { themeId, enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v1",
        call: `themes/${themeId}/theme_investment_stages`,
      }).pipe(
        map((result) => pipelineGetInvestStagesSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicAddPipelineStage = (action$, state$) =>
  action$.pipe(
    ofType(PIPELINE_ADD_STAGE_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        // call: `stages`,
      }).pipe(
        map((result) => pipelineAddStageSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const getPipelineEdit = (themeId, companyId, productId, investMode) => {
  if (productId) {
    return `themes/${themeId}/companies/${productId}?is_product=true`;
  }

  return `themes/${themeId}/companies/${companyId}${
    investMode ? "?view=investment" : ""
  }`;
};
// v2/themes/pipeline/relationship_stages/theme_companies/3d120347-00e7-40c7-8e45-9149014ad70a

export const epicEditPipelineStage = (action$, state$) =>
  action$.pipe(
    ofType(PIPELINE_EDIT_STAGE_ATTEMPT),
    switchMap(
      ({
        payload: {
          themeId,
          companyId,
          productId,
          data,
          enqueueSnackbar,
          investMode,
        },
      }) =>
        httpPut({
          apiVersion: "v3",
          call: getPipelineEdit(themeId, companyId, productId, investMode),
          data,
        }).pipe(
          map((result) => {
            return investMode
              ? pipelineGetInvestStagesAttempt({ themeId })
              : pipelineGetStagesAttempt({ themeId });
            // return pipelineEditStageSuccess(result);
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );
export const epicDeletePipelineStage = (action$, state$) =>
  action$.pipe(
    ofType(PIPELINE_DELETE_STAGE_ATTEMPT),
    switchMap(({ payload: { stageId, enqueueSnackbar } }) =>
      httpDelete({
        apiVersion: "v1",
        // call: `stages/stageId`,
      }).pipe(
        map((result) => pipelineDeleteStageSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicSearchPipelineCompanies = (action$, state$) =>
  action$.pipe(
    ofType(THEME_PIPELINE_COMPANIES_SEARCH_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, data } }) =>
      httpPost({
        apiVersion: "v2",
        data,
        call: `themes/companies/search?view=list&page=${data.page}&items=20&pageSize=20`,
      }).pipe(
        map((result) => pipelineSearchCompaniesSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const getPipelineEditAcross = (companyId, productId, investMode, isProduct) => {
  if (isProduct) {
    return `themes/pipeline/theme_products/${productId}`;
  }
  // ${
  //   investMode ? "?view=investment" : ""
  // }
  return `themes/pipeline/theme_companies/${companyId}`;
};

export const epicEditPipelineStageAcross = (action$, state$) =>
  action$.pipe(
    ofType(PIPELINE_EDIT_STAGE_ACROSS_ATTEMPT),
    switchMap(
      ({
        payload: {
          companyId,
          data,
          enqueueSnackbar,
          investMode,
          productId,
          isProduct,
        },
      }) =>
        httpPut({
          apiVersion: "v2",
          call: getPipelineEditAcross(
            companyId,
            productId,
            investMode,
            isProduct
          ),
          data,
        }).pipe(
          map((result) => {
            return pipelineEditStageAcrossSuccess(result);
            // themesCompaniesSearchAttempt({
            //   page: 1,
            //   data: {
            //     sort_order: "desc",
            //     sort_attribute: "created_at",
            //     search: {
            //       query: "*",
            //     },
            //     investment: false,
            //   },
            // });
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

const themesPipelineEpic = combineEpics(
  epicGetPipelineStages,
  epicAddPipelineStage,
  epicEditPipelineStage,
  epicDeletePipelineStage,
  epicGetPipelineInvestStages,
  epicSearchPipelineCompanies,
  epicEditPipelineStageAcross
);

export default themesPipelineEpic;

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import styles from "./projectMembers.module.scss";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import { UserInline, UserInlineTypes, Pannel } from "../../../../primitives";
import { NewProjectMember } from "../../../../shared";
import { useModelPopup } from "../../../../../common/hooks";
import NewDiscussion from "../../../../../common/components/newDiscussion";
import { checkViewerRole } from "../../../../../common/checkers/viewerChecker";
import { httpPost } from "common/httpCall";
import { projectUsersGet } from "../../users/user.action";

const ProjectMembersComponent = ({ expandAll }) => {
  const {
    projectsReducer: {
      usersReducer: { projectUsersList },
      projectReducer: { selectedProject },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const history = useHistory();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expandAll) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [expandAll]);
  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  const handleAddCompanyClick = (e) => {
    e.stopPropagation();
    popup.show({
      title: "Add a Member to this project",
      component: (
        <NewProjectMember multiple projectUsersList={projectUsersList} />
      ),
    });
  };

  const handleMailClick = (mail) => {
    window.open(`mailto:${mail}`, "_blank");
  };

  const handlePrivateMessageClick = (e, user) => {
    e.stopPropagation();
    popup.show({
      title: "Start Private Discussion",
      component: (
        <NewDiscussion
          type="Project"
          isPrivate
          hideTeam
          projectId={selectedProject.id}
          selectedUser={user}
        />
      ),
    });
  };

  const handleInvite = (member) => {
    const data = {
      ...member,
      project_id: selectedProject.id,
      council_id: selectedCouncil?.id,
    };
    httpPost({
      call: `projects/${selectedProject.id}/members/invite`,
      data,
    })
      .pipe()
      .subscribe(() => {
        dispatch(
          projectUsersGet({
            projectId: selectedProject.id,
            councilId: selectedCouncil.id,
            enqueueSnackbar,
          })
        );
        enqueueSnackbar("User has been added successfully", {
          variant: "success",
        });
      });
  };

  const handleMemberProfileRedirect = (e, id) => {
    // e.stopPropagation();
    // e.preventDefault();
    // e.nativeEvent.stopImmediatePropagation();
    const url = window.location.origin;
    window.history.replaceState({}, "", `${url}/people/internal/profile/${id}`);
    history.go(0);
  };

  const rightContent = useCallback(() => {
    return (
      <>
        <div
          className="d-flex justify-content-center cursor-pointer "
          onClick={() => setExpanded((prev) => !prev)}
        >
          <Icon
            {...smSize}
            icon="icn-table-down-chevron"
            className={classNames(
              styles.icon,
              expanded ? styles.collapse : styles.collapseUp
            )}
          />
        </div>
      </>
    );
  }, [expanded]);
  return (
    <Pannel
      title="Project Members"
      padding="0"
      rightContent={rightContent()}
      withCollapse
      expanded={expanded}
    >
      <div className={styles.panelHeader}>
        Members
        {!blockFunctionality ? (
          <Icon
            {...mdSize}
            icon="icn-add"
            className={styles.addNew}
            onClick={handleAddCompanyClick}
          />
        ) : (
          <Tooltip
            title="You have View-Only access. To add to this Project, please ask your Admin to upgrade your account to Standard access and invite you to this Project."
            placement="top"
          >
            <div className={styles.addNewWrapper}>
              <Icon {...mdSize} icon="icn-add" className={styles.addNew} />
            </div>
          </Tooltip>
        )}
      </div>
      <div className={styles.wrapper}>
        {projectUsersList.map((item, index) => (
          <div key={item?.id} className={classNames(styles.Row)}>
            {item?.user ? (
              <div
                onClick={(e) => {
                  item.user && handleMemberProfileRedirect(e, item?.user?.id);
                }}
              >
                <UserInline
                  userId={item?.user?.id}
                  first={item?.user?.first_name}
                  last={item?.user?.last_name}
                  avatar={item?.user?.avatar}
                  local
                  type={UserInlineTypes.WITH_NAME}
                />
              </div>
            ) : (
              <Tooltip
                placement="top"
                title={
                  <div className={styles.externalMemberTooltip}>
                    <div className={styles.tooltipValues}>
                      {item.external_project_member &&
                        item?.external_project_member?.full_name}
                    </div>
                    <div className={styles.tooltipValues}>
                      {item.external_project_member &&
                        item?.external_project_member?.title}
                    </div>
                    <div className={styles.tooltipValues}>
                      {item.external_project_member &&
                        item?.external_project_member?.email}
                    </div>
                    <div className={styles.tooltipValues}>
                      {item.external_project_member &&
                        item?.external_project_member?.organization}
                    </div>
                  </div>
                }
              >
                <div>
                  <span className={styles.externalMember}>
                    <UserInline
                      userId={item?.external_project_member?.id}
                      first={item?.external_project_member?.first_name}
                      last={item?.external_project_member?.last_name}
                      avatar={item?.external_project_member?.avatar}
                      local
                      type={UserInlineTypes.WITH_NAME}
                      fromExternal
                    />
                  </span>
                </div>
              </Tooltip>
            )}
            {item.external_project_member && (
              <>
                <div className={styles.externalText}>External</div>
                <div
                  className={styles.inviteBtn}
                  onClick={() => handleInvite(item.external_project_member)}
                >
                  {" "}
                  INVITE
                </div>
              </>
            )}

            <div className={styles.btnWrp}>
              {item?.external_project_member ? (
                <Icon
                  {...mdSize}
                  icon="icn-table-message"
                  className={styles.message}
                  onClick={() =>
                    handleMailClick(item?.external_project_member?.email)
                  }
                />
              ) : (
                <Icon
                  {...mdSize}
                  icon="icn-table-message"
                  className={styles.message}
                  onClick={(e) => handlePrivateMessageClick(e, item.user)}
                />
              )}
              {!blockFunctionality ? (
                <Icon
                  {...mdSize}
                  icon="icn-table-follow"
                  className={styles.follow}
                  onClick={handleAddCompanyClick}
                />
              ) : (
                <Tooltip
                  placement="top"
                  title="You have View-Only access. To add to this Project, please ask your Admin to upgrade your account to Standard access and invite you to this Project."
                >
                  <span className={styles.follow}>
                    <Icon
                      {...mdSize}
                      icon="icn-table-follow"
                      className={styles.follow}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
        ))}
      </div>
    </Pannel>
  );
};

export default ProjectMembersComponent;

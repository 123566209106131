import React from "react";
import { Field } from "formik";
import classnames from "classnames";
import styles from "./form-generator.module.scss";

const Input = ({ field, formikprops }) => {
  const {
    id,
    field_name: fieldName,
    field_label: fieldLabel,
    field_type: type,
    field_placeholder: placeholder,
  } = field;
  const label = fieldLabel || fieldName;
  const { errors, touched } = formikprops;
  const isInvalid = errors[id] && touched[id];

  return (
    <div className={styles.formGroup} key={`field-${id}`}>
      {
        id === 'e7d24f23-01ea-4ef0-8748-3f3a634908ad' ? (
          <div className={styles.customMerckStyles}>
            <h5>Founder Details</h5>
            <hr />
          </div>
        ) : null
      }
      {
        id === '637195b4-1a1a-4867-abd5-cf3c6bc57a76' ? (
          <div className={styles.customMerckStyles}>
            <h5>Company Details</h5>
            <hr />
          </div>
        ) : null
      }
      {
        id === '3893064a-97b8-4df2-bca2-0b0ace3252f1' ? (
          <div className={styles.customMerckStyles}>
            <h5>Program Information</h5>
            <hr />
          </div>
        ) : null
      }
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      <Field
        id={id}
        name={id}
        type={type}
        as="input"
        className={classnames(
          styles.formControl,
          isInvalid && styles.invalidInput,
        )}
        placeholder={placeholder}
      />
      {isInvalid && <div className={styles.invalidFeedback}>{errors[id]}</div>}
    </div>
  );
};

export default Input;

import React, { useState } from "react";

import UserComponent from "modules/reporting/components/genericReportsList/userComponent";
import styles from "modules/reporting/components/reportingChartTags/reportingChartTags.module.scss";

const UserComponentWrapper = ({ data, config }) => {
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => setShowMore(!showMore);

  if (data[config.propToUse] instanceof Array) {
    const hasShowMore = data[config.propToUse]?.length > 2;

    return (
      <div>
        {
          data[config.propToUse]
            .slice(0, hasShowMore && !showMore ? 2 : data[config.propToUse]?.length)
            .map((user, idx) => (
              <div key={`${idx}_user`} className="mb-1">
                <UserComponent
                  source={user}
                  propToUse={config.propToUse}
                  avatarProp={`${config.propToUse}_avatar`}
                  idProp={`${config.propToUse}_id`}
                  nameProp={`${config.propToUse}_name`}
                />
              </div>
          ))
        }
        {
          hasShowMore ? (
            <span
              className={styles.showMore}
              onClick={handleShowMore}
            >{showMore ? 'show less' : 'show more'}</span>
          ) : null
        }
      </div>
    )
  }

  return (
    <UserComponent
      source={data}
      propToUse={config.propToUse}
      avatarProp={`${config.propToUse}_avatar`}
      idProp={`${config.propToUse}_id`}
      nameProp={`${config.propToUse}_name`}
    />
  )
}

export default React.memo(UserComponentWrapper);

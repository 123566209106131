import React, { useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { Label, TextBox, DatePiker } from "../../../../modules/primitives";
import checkUniqName from "common/checkUniqName";
import checkDuplicateName from "common/checkDuplicateName";

import findCurrentCustomFieldGroup from "../../../findCustomFieldGroup";
import styles from "./styles.module.scss";
import { model } from "./constant";

const NewDateComponent = (props) => {
  const {
    formRef,
    handleOnSubmit,
    data,
    mode,
    uniqName,
    fromAdmin = false,
    rfiInstance,
    adminLocation,
    selectedCustomFieldGroup,
    indexOfCfGroup,
  } = props;
  const [error, setError] = useState("");
  const [date, setDate] = useState(data?.field_value ? data.field_value : "");
  const [titleError, setTitleError] = useState("");
  const {
    companiesReducer: {
      companyReducer: { newCustomFieldGroups },
    },
    adminCustomFieldsReducer: { customFieldGroups },
  } = useSelector((state) => state);
  const initValues = data
    ? {
        field_type: data.field_type,
        field_value: data.field_value,
        title: data.field_name,
        field_placeholder: data.field_placeholder || "",
      }
    : null;

  const isValidDate = (d) => {
    const date = new Date(d);
    if (date) {
      return date instanceof Date && !isNaN(date);
    }
    return true;
  };

  const handleSetDate = (e, setFieldValue) => {
    if (isValidDate(e)) {
      setError("");
    }
    setDate(e);
    setFieldValue("field_value", e);
  };

  const onSubmit = (values, resetForm, setFieldError) => {
    const cfGroup =
      findCurrentCustomFieldGroup(
        selectedCustomFieldGroup,
        customFieldGroups,
        newCustomFieldGroups,
        indexOfCfGroup,
        data
      ) || [];

    if (!values.title.length) {
      const field_name = checkDuplicateName(cfGroup, "date");
      values.title = field_name;
    } else if (
      !checkUniqName(values.title, cfGroup) &&
      values.title?.trim()?.toLowerCase() !== data?.field_name?.trim()?.toLowerCase()
    ) {
      setTitleError("Field name must be unique. Please rename this field");
      return;
    }
    setTitleError("");

    if (
      isValidDate(values.field_value) ||
      values.field_value === undefined ||
      values.field_value === ""
    ) {
      values.field_value = date;
      handleOnSubmit(values);
      resetForm();
      values.title = "";
    } else if (!("field_value" in values)) {
      values.field_value = undefined;
      handleOnSubmit(values);
      resetForm();
      values.title = "";
    } else {
      setError("Date is invalid, please enter a valid date.");
      setFieldError("field_value", "Date is invalid, please enter a valid date.");
    }
  };
  return (
    <div className={styles.wrapper}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={data && data ? initValues : model}
        onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
          setSubmitting(true);
          onSubmit(values, resetForm, setFieldError);
        }}
      >
        {({ handleSubmit, setFieldValue, setFieldError, values, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <Label>Title</Label>
            <TextBox
              disabled={rfiInstance}
              type="text"
              name="title"
              placeholder="Choose a title"
              formProps={{
                ...formikprops,
                setFieldValue,
                setFieldError,
                values,
              }}
              className={styles.textBox}
            />
            {titleError.length > 1 && (
              <div className={styles.errorBox}>
                <sup>*</sup>
                {titleError}
              </div>
            )}
            {!adminLocation && (
              <div className="d-flex flex-column align-items-start">
                <Label>Instructions (Optional)</Label>
                <TextBox
                  type="text"
                  name="field_placeholder"
                  placeholder="Add instructions"
                  formProps={{
                    ...formikprops,
                    setFieldValue,
                    setFieldError,
                    values,
                  }}
                  className={styles.textBox}
                />
              </div>
            )}
            {!fromAdmin && (
              <>
                <Label>Date</Label>
                <DatePiker
                  name="field_value"
                  formProps={{
                    ...formikprops,
                    setFieldValue,
                    setFieldError,
                    values,
                  }}
                  value={initValues && initValues.field_value}
                  styleProp={styles.calendarStyle}
                  className={error ? styles.calendarError : styles.calendarBox}
                  onChange={(e) => handleSetDate(e, setFieldValue)}
                  showTime
                  withClear
                />
              </>
            )}
            {error && (
              <div className={styles.error}>Date is invalid, please enter a valid date.</div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};
export default React.memo(NewDateComponent);

import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { convertDateTimezone, tConvert } from "common/helper";
import styles from "./styles.module.scss";
import {
  CheckBox,
  Label,
  MoreOptions,
  UserInline,
  UserInlineTypes,
} from "../../../../primitives";
import { moreOptions } from "./constant";
import { Icon, lgSize, smSize } from "../../../../../common/icon";
import { parceVisibility } from "../activity.constant";
import AccessToggle from "../../../../../common/components/customFields/accessToggle";

const EmailComponent = (props) => {
  const {
    email,
    teamList,
    handleEditClick,
    handleDeleteClick,
    orgType,
    hasAccess,
    blockFunctionality = false,
    isAddedByMe,
    isLastElem,
    isMobile,
  } = props;

  const handleMoreOptionClick = (props) => {
    if (props === 5) {
      handleEditClick(email);
    }
    if (props === 6) {
      handleDeleteClick(email);
    }
  };
  const isMerck = () => JSON.parse(localStorage.getItem("council"))?.name === "Merck";

  return (
    <div className={`${styles.wrapper} ${isLastElem ? styles.noBorder : ""}`}>
      <div className={styles.container}>
        <div className={styles.titleRow}>
          {isMobile ? null : (
            <div className={styles.iconWrp}>
              <Icon {...lgSize} icon="icn-table-mail" className={styles.icon} />
            </div>
          )}
          <h5 className={styles.title}>
            {isMobile ? (
              <Icon {...lgSize} icon="icn-table-mail" className={styles.icon} />
            ) : null}
            Email
          </h5>
          <div className={styles.rightWrp}>
            {isAddedByMe ? (
              <div className={styles.toggle}>
                <AccessToggle item={email} orgType={orgType} />
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <span className={styles.date}>
                  {email?.updated_at
                    ? format(new Date(email.updated_at), "EEEE, MMMM dd, yyyy ")
                    : null}
                </span>
                <div className={styles.colMore}>
                  {hasAccess && (
                    <MoreOptions
                      options={moreOptions}
                      className={styles.moreOptWrp}
                      onClick={handleMoreOptionClick}
                      customSvgIcon
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex align-items-start justify-content-between mt-4">
          <div className="d-flex flex-column">
            {email?.subject && (
              <span className={styles.subject}>{email?.subject}</span>
            )}
            {isMerck() && (
              <div className="mb-3">
                {email?.from_email && (
                  <div>
                    <b> From:&nbsp; </b> {email?.from_email}
                  </div>
                )}
                {email?.to_email && (
                  <div>
                    <b> To:&nbsp; </b> {email?.to_email}
                  </div>
                )}
              </div>
            )}

            {email?.date ? (
              <>
                <span className={styles.email_date}>
                  {format(
                    convertDateTimezone(email?.date),
                    "EEEE, MMMM dd, yyyy ",
                  )}
                </span>
                {email?.event_time && (
                  <div className={styles.time}>
                    {tConvert(email.event_time)}
                  </div>
                )}
              </>
            ) : null}
          </div>
          {isAddedByMe && (
            <div className="d-flex align-items-center">
              <span className={styles.date}>
                {email?.updated_at
                  ? format(new Date(email.updated_at), "EEEE, MMMM dd, yyyy ")
                  : null}
              </span>
              <div className={styles.colMore}>
                {hasAccess && (
                  <MoreOptions
                    options={moreOptions}
                    className={styles.moreOptWrp}
                    onClick={handleMoreOptionClick}
                    customSvgIcon
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className={classNames(styles.detail, styles.note)}
          dangerouslySetInnerHTML={{
            __html:
              `<div class=${styles.emailBody}>${email.emailed_about}</div>`
              || "",
          }}
        />
        <div>
          {isMerck() && email.images && (
            <div className={styles.emailContainer}>
              <div className={styles.emailImageHeader}>Email Images</div>
              <div className={styles.emailImages}>
                <div>
                  <b>Name</b>
                </div>
                <div>
                  <b>Download Link</b>
                </div>
              </div>
              {email.images.map((image, i) => (
                <div className={styles.emailImages}>
                  <div>{`image ${i + 1}`}</div>
                  <div className="mr-4 d-flex align-items-center">
                    <a href={image} target="_blank" download rel="noreferrer">
                      <Icon
                        {...smSize}
                        icon="icn-export-button"
                        className={styles.download}
                      />
                    </a>
                    <a
                      className={styles.docView}
                      target="blank"
                      href={image}
                      download={`image ${i + 1}`}
                    >
                      download
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}

          {isMerck() && email.attachments && (
            <div className={styles.emailContainer}>
              <div className={styles.emailImageHeader}>Email Attachments</div>
              <div className={styles.emailAttachments}>
                <div>
                  <b>Name</b>
                </div>
                <div>
                  <b>Download Link</b>
                </div>
              </div>
              {email.attachments.map((attachment, i) => (
                <div className={styles.emailAttachments}>
                  <div>{`attachment ${i + 1}`}</div>

                  <div className="mr-4 d-flex align-items-center">
                    <a
                      href={attachment}
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      <Icon
                        {...smSize}
                        icon="icn-export-button"
                        className={styles.download}
                      />
                    </a>
                    <a
                      className={styles.docView}
                      target="blank"
                      href={attachment}
                      download={`attachment ${i + 1}`}
                    >
                      download
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles.attendeesWrp}>
          {email?.attendees?.length !== 0 && (
            <>
              <div className={styles.label}>Contacts: </div>
              <div className={styles.listWrp}>
                {email?.attendees.map((attendee) => (
                  <div key={attendee.user_id} className={styles.itemWrp}>
                    <UserInline
                      className={styles.userIcon}
                      classObj={{ name: styles.userName }}
                      userId={attendee.user_id}
                      type={UserInlineTypes.WITH_NAME}
                      showMine={false}
                      first={attendee.first_name}
                      last={attendee.last_name}
                      avatar={attendee.avatar}
                      local
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className={styles.infoWrp}>
          <div className="w-50 d-flex">
            {email?.projects && email?.projects[0] && (
              <div className={styles.dataRow}>
                <div className={styles.label}>Project</div>
                {email?.projects.map((project) => (
                  <Link key={project.id} to={`/projects/${project.id}`}>
                    <div className={styles.value}>{project.name}</div>
                  </Link>
                ))}
              </div>
            )}
            <div className={styles.dataRow}>
              <div className={styles.label}>
                {email.updated_at !== email.created_at
                  ? "Updated by"
                  : "Created by"}
              </div>
              <div className={styles.value}>
                <Link to={`/people/internal/${email.creator_id}`}>
                  {email && email.created_by}
                </Link>
              </div>
            </div>
            {email.visibility && (
              <div className={styles.dataRow}>
                <div className={styles.label}>Visible</div>
                <div className={styles.value}>
                  {email
                    && parceVisibility(email.visibility, teamList, email.team_ids)}
                </div>
              </div>
            )}
          </div>
          <div className="w-50">
            {orgType === "patient" ? (
              <div className={styles.centerWrp}>
                <div className={classNames(styles.infoWrpColumn, "mb-3")}>
                  <div className={styles.label}>Discussion Topic:</div>
                  <div className={styles.detail}>
                    {email && email.discussion_topics
                      ? email.discussion_topics.map((d) => `${d}, `)
                      : "-"}
                  </div>
                </div>
                <div className={classNames(styles.infoWrpColumn, "mb-3")}>
                  <div className={styles.label}>
                    {orgType === "patient"
                      ? "Role of Patient Organization Representative(s)"
                      : "Roles Of Key Attendees"}
                    :
                  </div>
                  <div className={styles.detail}>
                    {(email && email.roles_of_attendees) || "-"}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Label>
                    {orgType === "patient"
                      ? "Initial engagement"
                      : "Initial introductory email"}
                    :{" "}
                  </Label>
                  <CheckBox
                    className="ml-2"
                    isChecked={email && email.initial_introductory_meeting}
                    disabled={blockFunctionality}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailComponent;

export const PROJECT_REPORT = {
  step1: {
    reportTitle: "",
    companies: [],
    isAllSelected: true,
  },
  step2: {
    stages: [],
    isAllSelected: true,
  },
  step3: {
    tasks: [],
    isAllSelected: true,
  },
};

import React from "react";

import StyleButton from "../blockStyles/StyleButton";
import styles from "../richEditor.module.scss";

export const INLINE_HEADINGS = [
  { label: "B", style: "BOLD" },
  { label: "I", style: "ITALIC" },
];

export const InlineStyles = (props) => {
  const { editorState } = props;
  const sty = editorState.getCurrentInlineStyle();

  return (
    <span className={styles.firstControls}>
      {INLINE_HEADINGS.map((type) => (
        <StyleButton
          key={type.label}
          active={sty.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          className="inline styleButton"
          id={type.style.toLowerCase()}
        />
      ))}
    </span>
  );
};

const selectMention = (
  mentionItem,
  inputText,
  setInputText,
  set_theme_mentions_attributes,
  theme_mentions_attributes,
  sessionId,
  mentionId,
  mentionResoruceType,
  mentionResourceId = "",
  mode
) => {
  const mentionName = mentionItem.name;
  const mentionEntity = mentionItem.type;
  const mentionedEntitityId = mentionItem.id;

  set_theme_mentions_attributes([
    ...theme_mentions_attributes,
    {
      theme_mention_resource_type: mentionResoruceType,
      theme_mentioned_resource_type: mentionEntity,
      theme_mentioned_resource_id: mentionedEntitityId,
      mention_id:
        new Date().toString().split(" ").join("") +
        (Math.random() * 1000).toString(),
      theme_mention_name: mentionName,
      user_id: sessionId,
      slug: mentionItem.slug,
    },
  ]);
};
export default selectMention;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import styles from './styles.module.scss';
import FordScoreCards from "modules/reporting/fordReports/planScorecard/sections";
import FordPlanScoreTableWrapper from "modules/reporting/fordReports/planScorecard/table";
import FordScoreCardFilters from "modules/reporting/fordReports/filters";
import {
  getPlanPocTableData,
  getPlanScoreData,
  getPlanScoreTableData,
  setPlanScoreCardFilters,
  togglePlanScoreTabFilters,
} from "modules/reporting/fordReports/planScorecard/store/planScoreCard.actions";
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import TractionDownload from "../../../../common/components/tractionDownload";
import PDFTitleAndTractionLogo from "modules/reporting/fordReports/pdfTitleAndTractionLogo";

const FordPlanScoreCard = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [downloadIsActive, setDownloadIsActive] = useState(false);

  const {
    fordDashboardReducer: { planScoreCardReducer }
  } = useSelector((state) => state);

  const handleReset = () => {
    dispatch(setPlanScoreCardFilters({
      reset: true
    }));

    const makeFilters = makeActualScoreCardFilterData({
      dateType: 'allTime'
    });
    const payload = {
      enqueueSnackbar,
      data: makeFilters,
    };

    dispatch(getPlanScoreData(payload));
    dispatch(getPlanPocTableData(payload));

    if (planScoreCardReducer.tableData?.expandedKey?.length > 0) {
      dispatch(getPlanScoreTableData({
        reducerKey: planScoreCardReducer.tableData.expandedKey,
        highLightColumn: planScoreCardReducer.tableData.highLightColumn,
        data: {
          ...makeFilters,
          section: planScoreCardReducer.tableData.expandedKey,
        },
      }));
    }
  };

  const handleExpandClick = () => {
    dispatch(togglePlanScoreTabFilters());
  }

  const getNewData = (payload) => {
    dispatch(getPlanScoreData(payload));
    dispatch(getPlanPocTableData(payload));
  }

  const getNewTableData = payload => {
    dispatch(getPlanScoreTableData(payload));
  }

  const setNewFilters = (payload) => {
    dispatch(setPlanScoreCardFilters(payload));
  }

  return (
    <section className={styles.planScoreCardWrapper}>
      <div className="d-flex justify-content-end">
        <TractionDownload
          handleDownloadState={setDownloadIsActive}
          timeoutTime={2000}
          hidePng
        />
      </div>
      <div id="downloadableSection">
        <PDFTitleAndTractionLogo downloadIsActive={downloadIsActive} title="Plan Score Card" />
        <FordScoreCardFilters
          reducer={planScoreCardReducer}
          handleReset={handleReset}
          handleExpandClick={handleExpandClick}
          getNewData={getNewData}
          getNewTableData={getNewTableData}
          setNewFilters={setNewFilters}
        />
        <FordScoreCards filters={planScoreCardReducer.filters} downloadIsActive={downloadIsActive} />
        <FordPlanScoreTableWrapper />
      </div>
    </section>
  );
};

export default React.memo(FordPlanScoreCard);

import { combineEpics, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";

import fordActualScoreEpic from "modules/reporting/fordReports/actualScorecard/store/actualScoreCard.epic";
import {
  GET_FORD_DASHBOARD_STAGES,
  GET_PROJECT_FIELDS,
  GET_REGIONS,
  getFordDashboardStagesSuccess,
  getProjectFieldsSuccess,
  getRegionsSuccess,
} from "modules/reporting/fordReports/store/fordReports.actions";
import { errorHandler, httpGet } from "../../../../common/httpCall";
import fordKPIsEpic from "modules/reporting/fordReports/kpis/store/kpis.epic";
import fordPlanScoreEpic from "modules/reporting/fordReports/planScorecard/store/planScoreCard.epic";
import fordReplicationsEpic from "modules/reporting/fordReports/replicationsCard/store/replicationsCard.epic";

export const epicGetRegions = (action$) => action$.pipe(
  ofType(GET_REGIONS),
  switchMap(() => httpGet({
    call: 'plants/regions',
  }).pipe(
    map((result) => getRegionsSuccess(result)),
    catchError((err) => errorHandler(err)),
  )),
);

export const epicGetStages = (action$) => action$.pipe(
  ofType(GET_FORD_DASHBOARD_STAGES),
  switchMap(() => httpGet({
    call: 'stages/dropdown?resource_type=project',
  }).pipe(
    map((result) => getFordDashboardStagesSuccess(result)),
    catchError((err) => errorHandler(err)),
  )),
);

export const epicGetProjectFields = (action$) => action$.pipe(
  ofType(GET_PROJECT_FIELDS),
  switchMap(() => httpGet({
    call: 'ford/project_field_templates/filters',
  }).pipe(
    map((result) => getProjectFieldsSuccess(result)),
    catchError((err) => errorHandler(err)),
  )),
);

const fordDashboardEpic = combineEpics(
  fordKPIsEpic,
  fordActualScoreEpic,
  fordPlanScoreEpic,
  fordReplicationsEpic,
  epicGetRegions,
  epicGetStages,
  epicGetProjectFields,
);

export default fordDashboardEpic;

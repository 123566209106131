import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import html2pdf from "html2pdf.js/src";

import downloadTable from "../../../../common/components/downloadReport/generateDocHelper";
import Loading from "../../../primitives/loading/loading.component";
import { Button, BtnType } from "../../../primitives";
import styles from "./chartsDownload.module.scss";
import { sectionToPng } from "../../../../common/download-utils";
import { appSetChartsDownloadStatus } from "application/app.action";

const ChartsDownload = ({ homeUse, location, chartType, toggleView, endpoint, payload }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    appReducer: { chartsDownloadIsActive },
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: { countryFilters, checkedPatientFilters },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (chartsDownloadIsActive) {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }
  }, [chartsDownloadIsActive]);

  const handleDownload = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopUp = () => {
    setAnchorEl(null);
  };
  const handleLoading = (loadingState) => {
    setLoading(loadingState);
  };
  const handleClose = (type) => {
    if (typeof type !== "number") {
      closePopUp();
      return;
    }

    dispatch(appSetChartsDownloadStatus(true));

    const timeout = setTimeout(() => {
      if (type === 0) {
        sectionToPng("downloadableSection")
          .then((dataUrl) => {
            const link = document.createElement("a");
            link.download = "traction-chart.png";
            link.href = dataUrl;
            link.click();
            dispatch(appSetChartsDownloadStatus(false));
          })
          .catch(() => {
            dispatch(appSetChartsDownloadStatus(false));
          });
      }

      if (type === 1) {
        dispatch(appSetChartsDownloadStatus(true));

        const timeout1 = setTimeout(() => {
          const elem = document.getElementById("downloadableSection");
          const pdfWidth = homeUse ? elem.offsetWidth - 100 : elem.offsetWidth + 30;
          const height = (pdfWidth * 1.5) + 30;
          const pdfHeight = height < 2287 ? 2287 : height;
          const opt = {
            margin: [1, 1, 1, 15],
            filename: 'traction-chart.pdf',
            image: { type: 'jpeg' },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'px', precision: 1, format: [pdfWidth, pdfHeight], putOnlyUsedFonts: true }
          };

          html2pdf().set(opt).from(elem).save().then(() => {
            dispatch(appSetChartsDownloadStatus(false));
            clearTimeout(timeout);
            clearTimeout(timeout1);
          })
        }, 2000);
      }

      setAnchorEl(null);
      clearTimeout(timeout);
    }, 2000);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      className={`${homeUse && chartsDownloadIsActive ? styles.hidden : ""}`}
    >
      {loading ? (
        <Loading />
      ) : (
        <Button
          btn={BtnType.FRAME_LESS}
          className={`${styles.download} ${
            chartsDownloadIsActive ? styles.disabled : ""
          } ${homeUse ? styles.homeUse : ""}`}
          icon="icn-export-button"
          onClick={handleDownload}
          id="download-button"
          aria-controls="download-button"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          disabled={chartsDownloadIsActive}
        >
          <div className="d-flex align-items-center">
            Download Report
            {chartsDownloadIsActive && <div className={styles.loading} />}
          </div>
        </Button>
      )}
      <Menu
        id="downloadChartOptions"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "download-button",
        }}
      >
        <MenuItem onClick={() => handleClose(0)}>DOWNLOAD PNG</MenuItem>
        <MenuItem onClick={() => handleClose(1)}>DOWNLOAD PDF</MenuItem>
        {/* <MenuItem onClick={() => handleClose(2)}>DOWNLOAD PPT</MenuItem> */}
        <MenuItem
          onClick={() =>
            downloadTable(
              "xlsx",
              location,
              countryFilters,
              closePopUp,
              endpoint || "companies",
              payload,
              handleLoading,
              "",
              chartType,
              toggleView
            )
          }
        >
          DOWNLOAD EXCEL
        </MenuItem>
        <MenuItem
          onClick={() =>
            downloadTable(
              "csv",
              location,
              countryFilters,
              closePopUp,
              endpoint || "companies",
              payload,
              handleLoading,
              "",
              chartType,
              toggleView
            )
          }
        >
          DOWNLOAD CSV
        </MenuItem>
      </Menu>
    </div>
  );
};

export default React.memo(ChartsDownload);

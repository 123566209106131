import * as Yup from "yup";

export const initTemplateModel = {
  name: "",
  description: "",
};

export const ratingTemplateValidation = Yup.object().shape({
  name: Yup.string().required("Template title is required"),
});

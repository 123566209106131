import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Icon, xsSize } from "../../../../../common/icon";
import style from "./ratings.module.scss";

const AccordionComponent = ({
  prc, cols, selectedTab, users
}) => {
  const [expanded, setExpanded] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (prc?.categories?.length) {
      const sameCategories = [];
      const filteredCategories = prc.categories.filter((cat, parentIndex) => {
        const sameCat = prc.categories.find(
          (c, index) => c.name === cat.name
            && index !== parentIndex
            && !sameCategories.find((s) => s.name === c.name),
        );

        if (sameCat?.name) {
          sameCategories.push({
            ...sameCat,
            users: [...sameCat.users, ...cat.users],
          });
          return false;
        }
        return true;
      });

      const filteredCopy = filteredCategories.map((cat) => {
        const same = sameCategories.find((c) => c.name === cat.name);

        if (same) {
          return same;
        }
        return cat;
      });

      setCategories(filteredCopy);
    }
  }, [prc]);

  return (
    <div className={style.MuiAccordionSummaryRoot}>
      <Accordion
        onClick={(e) => {
          if (!prc.categories[0]) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        className={style.accordion}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={
            prc.categories[0] ? style.accordionSummary : style.summaryWithoutCat
          }
          onClick={(e) => {
            if (!prc.categories[0]) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          {prc.categories[0] ? (
            <div>
              {" "}
              {!expanded ? (
                <Icon
                  icon="icn-right-chevron"
                  {...xsSize}
                  className={style.iconColor}
                  onClick={() => setExpanded(true)}
                />
              ) : (
                <Icon
                  icon="icn-table-down-chevron"
                  {...xsSize}
                  className={style.iconColor}
                  onClick={() => setExpanded(false)}
                />
              )}
            </div>
          ) : (
            <></>
          )}
          <div
            onClick={(e) => {
              if (!prc.categories[0]) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            className="col-3"
          >
            <div className="row">
              { selectedTab === "score" ? (
                <>
                  <div className="col-7 border">{prc.company_name}</div>
                  <div className="col-5 border">
                    {prc.overall_project_score === 0
                      ? "-"
                      : prc.overall_project_score}
                  </div>
                </>
              ) : (
                <>
                  <div className="col-7 border">{prc.user.full_name}</div>
                  <div className="col-5 border">
                    {prc.total_rating || "-"}
                  </div>
                </>
              )}
            </div>
          </div>

          { selectedTab === "score" ? (
            <div className="col-12 border" />
          ) : (
            <div className="col pl-0">
              {prc.notes.map((note) => {
                return (
                  <div className={style.noteDescription} key={note.id}>
                    {note.description}
                  </div>
                );
              })}
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails>
          { selectedTab === "score" ? (
            <div className="row">
              <div className="col-3 ml-4 pl-1 border d-flex align-items-center justify-content-between">
                Notes
              </div>
              {
                prc.users_company_ratings
                && prc.users_company_ratings
                  .sort((a, b) => a.email.localeCompare(b.email))
                  .map((user) => {
                    if (selectedTab === "score") {
                      return (
                        <div className="col border d-flex align-items-center" key={user.email}>
                          {user.notes.map((note) => (
                            <span>{note.description}, &nbsp;</span>
                          )) || "-"}
                        </div>
                      );
                    }
                  })
              }
            </div>
          ) : null}
          <div className={style.accordionDetails}>
            {categories?.map((category) => (
              <div className={`row  ${style.grey}`}>
                <div className="col-3">
                  <div className="row">
                    <div className="col-6 border d-flex align-items-center justify-content-between">
                      {category?.name}
                    </div>
                    <div className="col-6 border d-flex align-items-center justify-content-between">
                      {category?.average_score}
                    </div>
                  </div>
                  <div className="row">
                  </div>
                </div>
                {selectedTab === "score" ? (
                  cols?.map((user) => {
                    let value = "-";
                    category?.users
                    && category.users.map((score) => {
                      if (score?.user_id === user.id) {
                        value = score.score;
                      }
                      return score;
                    });
                    return <div className="col border">{value}</div>;
                  })
                ) : (
                  <div className="col border">{category.value}</div>
                )}
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default React.memo(AccordionComponent);

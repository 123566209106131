import React from "react";

import styles from "./discussionMobileItem.module.scss";
import { MultiUsers } from "../../../../primitives";

const DiscussionMobileItem = ({ discussion, handleShow }) => (
  <div className={styles.discussionDetails} onClick={handleShow}>
    <div className={styles.mobileRow}>
      <span>Creator</span>
      <div>{discussion.creator.full_name}</div>
    </div>
    <div className={styles.mobileRow}>
      <span>Subject</span>
      <div>{discussion.subject ? `"${discussion.subject}"` : "-"}</div>
    </div>
    <div className={styles.mobileRow}>
      <span>Team</span>
      <div>
        {discussion.team_id
          ? discussion.team.name
          : discussion.participants
            && discussion.participants.length
            && (discussion.anonymous ? (
              <div> Anonymous</div>
            ) : (
              <MultiUsers
                users={discussion.participants}
                total={discussion.participants.length}
              />
            ))}
      </div>
    </div>
    <div className={styles.mobileRow}>
      <span>Messages</span>
      <div className="d-flex align-items-center">
        <span className="mr-2">{discussion.messages.length}</span>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g fill="none" fillRule="evenodd">
              <g fill="#3994C1">
                <g>
                  <g>
                    <path
                      d="M22.477.988c.828 0 1.5.672 1.5 1.5v15.037c0 .829-.672 1.5-1.5 1.5L11.2 19.024l-4.818 4.794c-.953.948-2.542.258-2.559-1.03l.007-.172.335-3.59h-2.65c-.769 0-1.403-.578-1.49-1.324l-.01-.175V2.488c0-.828.672-1.5 1.5-1.5zm0 1.5H1.515v15.039h3.677l.11.013c.109.025.214.085.306.158.097.122.171.27.147.416l-.431 4.641 5.134-5.107.078-.05c.082-.045.179-.073.29-.073h11.65V2.488zM12.75 11.5c.414 0 .75.336.75.75s-.336.75-.75.75h-6c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h6zm4.5-3c.414 0 .75.336.75.75s-.336.75-.75.75H6.75C6.336 10 6 9.664 6 9.25s.336-.75.75-.75h10.5zm0-3c.414 0 .75.336.75.75s-.336.75-.75.75H6.75C6.336 7 6 6.664 6 6.25s.336-.75.75-.75h10.5z"
                      transform="translate(-1320 -261) translate(72 249) translate(1248 11)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
      </div>
    </div>
  </div>
);

export default React.memo(DiscussionMobileItem);

import CompanyIco from "../assets/icn-subnav-company.svg";
import EventIco from "../assets/icn-calendar.svg";
import TasksIco from "../assets/icn-subnav-tasks.svg";
import ListIco from "../assets/icn-bullets.svg";
import IdeaIco from "../assets/icn-idea.svg";
import ThemeIco from "../assets/icn-theme.svg";

export const ALLOWED_FILE_EXTENSIONS = [
  "txt",
  "doc",
  "docx",
  "pptx",
  "ppt",
  "pdf",
  "PDF",
  "xlsx",
  "csv",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "mp3",
  "png",
  "jpg",
  "jpeg",
  "gif",
  "key",
  "stl",
  "stp",
];

export const ACTIVITY_OPTIONS = [
  {
    val: 0,
    text: "Edit",
  },
  {
    val: 1,
    text: "Delete",
  },
];

export const ACTIVITY_OPTIONS_WITHOUT_DELETE = [
  {
    val: 0,
    text: "Edit",
  },
];

export const serverPath = process.env.REACT_APP_URL;
export const dashboardServerPath = process.env.REACT_APP_DASHBOARD_APP_URL;
export const version = process.env.REACT_APP_VER;
export const awsAccessKey = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
export const awsRegion = process.env.REACT_APP_AWS_REGION;
export const s3BUcketName = process.env.REACT_APP_S3_BUCKET_NAME;
export const clientIdApp = process.env.REACT_APP_CLIENT_ID_APP;
export const authorityForApp = process.env.REACT_APP_AUTHORITY_APP;
export const redirectURI = process.env.REACT_APP_REDIRECT_URI_APP;
export const sfClientId = process.env.REACT_APP_SALESFORCE_APP_CLIENT_ID;
export const sfClientIdProd =
  process.env.REACT_APP_SALESFORCE_APP_CLIENT_ID_PRODUCTION;
export const sfAuthURL = process.env.REACT_APP_SALESFORCE_APP_AUTHORIZE_URL;
export const sfRedirectURI = process.env.REACT_APP_SALESFORCE_APP_REDIRECT_URI;
export const sfResponseType =
  process.env.REACT_APP_SALESFORCE_APP_RESPONSE_TYPE;
export const workOsStagingClientId =
  process.env.REACT_APP_WORK_OS_STAGING_CLIENT_ID;
export const workOsProductionClientId =
  process.env.REACT_APP_WORK_OS_PRODUCTION_CLIENT_ID;
export const workOsTractionConnectionId =
  process.env.REACT_APP_WORK_OS_TRACTION_CONNECTION_ID;
export const workOsKyndrylConnectionId =
  process.env.REACT_APP_WORK_OS_KYNDRYL_CONNECTION_ID;
export const workOsMerckConnectionId =
  process.env.REACT_APP_WORK_OS_MERCK_CONNECTION_ID;
export const workOsFirmenichConnectionId =
  process.env.REACT_APP_WORK_OS_FIRMENICH_CONNECTION_ID;
export const workOsGskConnectionId =
  process.env.REACT_APP_WORK_OS_GSK_CONNECTION_ID;
export const workOsIdbConnectionId =
  process.env.REACT_APP_WORK_OS_IDB_CONNECTION_ID;
export const workOsFreddieMacConnectionId =
  process.env.REACT_APP_WORK_OS_FREDDYMAC_CONNECTION_ID;
export const workOsProductionRedirectUri =
  process.env.REACT_APP_WORK_OS_PRODUCTION_REDIRECT_URI;
export const workOsStagingRedirectUri =
  process.env.REACT_APP_WORK_OS_STAGING_REDIRECT_URI;
export const workOsStarkConnectionId =
  process.env.REACT_APP_WORK_OS_STARK_CONNECTION_ID;
export const workOsLocalHostRedirectUri =
  process.env.REACT_APP_WORK_OS_LOCALHOST_REDIRECT_URI;
export const workOsFordConnectionId =
  process.env.REACT_APP_WORK_OS_FORD_CONNECTION_ID;
export const resourceImages = {
  "Gsk::PatientOrganization": CompanyIco,
  Company: CompanyIco,
  Event: EventIco,
  Project: TasksIco,
  List: ListIco,
  Task: TasksIco,
  Idea: IdeaIco,
  Theme: ThemeIco,
};

export const gskOptionCountries = [
  {
    name: "Argentina",
    id: "Argentina",
  },
  {
    name: "Australia",
    id: "Australia",
  },
  {
    name: "Austria",
    id: "Austria",
  },
  {
    name: "Denmark",
    id: "Denmark",
  },
  {
    name: "Finland",
    id: "Finland",
  },
  {
    name: "Hong Kong",
    id: "Hong Kong",
  },
  {
    name: "Ireland",
    id: "Ireland",
  },
  {
    name: "Israel",
    id: "Israel",
  },
  {
    name: "Mexico",
    id: "Mexico",
  },
  {
    name: "Netherlands",
    id: "Netherlands",
  },
  {
    name: "New Zealand",
    id: "New Zealand",
  },
  {
    name: "Norway",
    id: "Norway",
  },
  {
    name: "Poland",
    id: "Poland",
  },
  {
    name: "Singapore",
    id: "Singapore",
  },
  {
    name: "South Korea",
    id: "South Korea",
  },
  {
    name: "Sweden",
    id: "Sweden",
  },
  {
    name: "Taiwan",
    id: "Taiwan",
  },
  {
    name: "United States",
    id: "United States",
  },
  {
    name: "Canada",
    id: "Canada",
  },
  {
    name: "Chile",
    id: "Chile",
  },
  {
    name: "France",
    id: "France",
  },
  {
    name: "Czech Republic",
    id: "Czech Republic",
  },
  {
    name: "Greece",
    id: "Greece",
  },
  {
    name: "Brazil",
    id: "Brazil",
  },
  {
    name: "Japan",
    id: "Japan",
  },
  {
    name: "United Kingdom",
    id: "United Kingdom",
  },
  {
    name: "Germany",
    id: "Germany",
  },
  {
    name: "Spain",
    id: "Spain",
  },
  {
    name: "Italy",
    id: "Italy",
  },
  {
    name: "Switzerland",
    id: "Switzerland",
  },
  {
    name: "Belgium",
    id: "Belgium",
  },
  {
    name: "Portugal",
    id: "Portugal",
  },
  {
    name: "Colombia",
    id: "Colombia" 
  }
];

export const RELATIONSHIP_COLORS = [
  {
    background: "#81defd",
    text: "#035388",
  },
  {
    background: "#ffb088",
    text: "#841003",
  },
  {
    background: "#88b1fc",
    text: "#061178",
  },
  {
    background: "#91e697",
    text: "#014807",
  },
  {
    background: "#c1fef6",
    text: "#05606e",
  },
  {
    background: "#f8c4fe",
    text: "#6e0560",
  },
  {
    background: "#cbd2d9",
    text: "#323f4b",
  },
  {
    background: "#ff9b9b",
    text: "#8a041a",
  },
];

export const RELATIONSHIP_OPTIONS_FALLBACK = [
  {
    name: "Discover",
    id: "discover",
    slug: "discover",
    selected: false,
    colors: {
      background: "#81defd",
      text: "#035388",
    },
  },
  {
    name: "Consider",
    id: "consider",
    slug: "consider",
    selected: false,
    colors: {
      background: "#ffb088",
      text: "#841003",
    },
  },
  {
    name: "Evaluate",
    id: "evaluate",
    slug: "evaluate",
    selected: false,
    colors: {
      background: "#88b1fc",
      text: "#061178",
    },
  },
  {
    name: "Partner",
    id: "partner",
    slug: "partner",
    selected: false,
    colors: {
      background: "#91e697",
      text: "#014807",
    },
  },
];

export const EMPLOYEES_ENUM = {
  c_00001_00010: "1-10",
  c_00011_00050: "11-50",
  c_00051_00100: "51-100",
  c_00101_00250: "101-250",
  c_00251_00500: "251-500",
  c_00501_01000: "501-1000",
  c_01001_05000: "1001-5000",
  c_05001_10000: "5001-10000",
  c_10001_max: "10001+",
};

export const EMPLOYEES_VALUES = [
  {
    value: "Any",
    id: "Any",
  },
  {
    value: "1-10",
    id: "c_00001_00010",
  },
  {
    value: "11-50",
    id: "c_00011_00050",
  },
  {
    value: "51-100",
    id: "c_00051_00100",
  },
  {
    value: "101-250",
    id: "c_00101_00250",
  },
  {
    value: "251-500",
    id: "c_00251_00500",
  },
  {
    value: "501-1000",
    id: "c_00501_01000",
  },
  {
    value: "1001-5000",
    id: "c_01001_05000",
  },
  {
    value: "5001-10000",
    id: "c_05001_10000",
  },
  {
    value: "10001+",
    id: "c_10001_max",
  },
];

export const ESTIMATED_REVENUE_ENUM = {
  r_00000000: "Less than $1M",
  r_00001000: "$1M to $10M",
  r_00010000: "$10M to $50M",
  r_00050000: "$50M to $100M",
  r_00100000: "$100M to $500M",
  r_00500000: "$500M to $1B",
  r_01000000: "$1B to $10B",
  r_10000000: "$10B+",
};

export const ESTIMATED_REVENUE_VALUES = [
  {
    value: "Any",
    id: "Any",
  },
  {
    value: "Less than $1M",
    id: "r_00000000",
  },
  {
    value: "$1M to $10M",
    id: "r_00001000",
  },
  {
    value: "$10M to $50M",
    id: "r_00010000",
  },
  {
    value: "$50M to $100M",
    id: "r_00050000",
  },
  {
    value: "$100M to $500M",
    id: "r_00100000",
  },
  {
    value: "$500M to $1B",
    id: "r_00500000",
  },
  {
    value: "$1B to $10B",
    id: "r_01000000",
  },
  {
    value: "$10B+",
    id: "r_10000000",
  },
];

export const PATIENT_ORG_RELATIONSHIP_STAGE = [
  {
    name: "No Relationship",
    slug: "no_relationship",
  },
  {
    name: "Existing Relationship",
    slug: "existing_relationship",
  },
];

export const currencySymbolObj = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  CHF: '₣',
}

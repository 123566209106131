export const CHARTS_LIST = [
  {
    name: "Pie Chart",
    type: "pie",
  },
  {
    name: "Bar Chart",
    type: "bar",
  },
  {
    name: "Bubble Chart",
    type: "bubble",
  },
];

export const AXIS_DATA = [
  {
    name: "Total Funding",
    type: "totalFunding",
    inBarChart: "$ Total Funding (in millions)",
    inChart: "$ Total Funding",
  },
  {
    name: "Last Funding",
    type: "last_funding_amount_usd",
    inBarChart: "$ Last Funding (in millions)",
    inChart: "$ Last Funding",
  },
  {
    name: "Score",
    type: "council_company_score",
    inBarChart: "Score",
    inChart: "Score",
  },
  {
    name: "Number of Companies",
    type: "numberOfCompanies",
    inBarChart: "Number of Companies",
    inChart: "Number of Companies",
  },
  {
    name: "Years in Operation",
    type: "yearsInOperation",
    inBarChart: "Years in Operation",
    inChart: "Years in Operation",
  },
  {
    name: "Number of Employees",
    type: "numberOfEmployees",
    inBarChart: "Number of Employees",
    inChart: "Number of Employees",
  },
];

export const COLORS = [
  {
    name: "Technology",
    type: "technology",
  },
  {
    name: "Industry",
    type: "industry",
  },
  {
    name: "Relationship Stage",
    type: "relationShipStatus",
  },
];

export const BAR_COLORS = [
  ...COLORS,
  {
    name: "Estimated Revenue",
    type: "revenue_range",
  },
  {
    name: "Year",
    type: "year",
  },
];

export const PIE_BREAKDOWN = [
  ...COLORS,
  {
    name: "Total Funding",
    type: "totalFunding",
  },
  {
    name: "Amount of Last Round",
    type: "amountOfLastRound",
  },
  {
    name: "Score",
    type: "score",
  },
  {
    name: "Year Founded",
    type: "yearFounded",
  },
  {
    name: "Number of Employees",
    type: "numberOfEmployees",
  },
  {
    name: "Estimated Revenue",
    type: "revenue_range",
  },
];

export const PROPS_BY_OPTIONS_ENUM = {
  revenue_range: "revenue_range",
  totalFunding: "total_fundings_sum",
  numberOfEmployees: "num_employees_enum",
  size: "council_company_score",
  council_company_score: "council_company_score",
  yearsInOperation: "years_in_operation",
  last_funding_amount_usd: "last_funding_amount_usd",
};

export const REVENUE_RANGE_ENUM_AS_NUMBER = {
  r_00000000: {
    min: 0,
    max: 1000000,
  },
  r_00001000: {
    min: 1000000,
    max: 10000000,
  },
  r_00010000: {
    min: 10000000,
    max: 50000000,
  },
  r_00050000: {
    min: 50000000,
    max: 100000000,
  },
  r_00100000: {
    min: 100000000,
    max: 500000000,
  },
  r_00500000: {
    min: 500000000,
    max: 1000000000,
  },
  r_01000000: {
    min: 1000000000,
    max: 10000000000,
  },
  r_10000000: {
    min: 10000000000,
    max: 999999999999999999999999999999,
  },
};

export const EMPLOYEES_ENUM_AS_NUMBER = {
  c_00001_00010: {
    min: 1,
    max: 10,
  },
  c_00011_00050: {
    min: 11,
    max: 50,
  },
  c_00051_00100: {
    min: 51,
    max: 100,
  },
  c_00101_00250: {
    min: 101,
    max: 250,
  },
  c_00251_00500: {
    min: 251,
    max: 500,
  },
  c_00501_01000: {
    min: 501,
    max: 1000,
  },
  c_01001_05000: {
    min: 1001,
    max: 5000,
  },
  c_05001_10000: {
    min: 5001,
    max: 10000,
  },
  c_10001_max: {
    min: 10001,
    max: 12000,
  },
};

export const PROP_BY_COLOR = {
  technology: {
    top: "topics",
    bottom: "topic_id",
  },
  industry: {
    top: "industries",
    bottom: "industry_id",
  },
  relationShipStatus: {
    top: "council_relationship_stage_id",
    bottom: "council_relationship_stage_id",
  },
  revenue_range: {
    top: "revenue_range",
    bottom: "revenue_range",
  },
};

export const DEF_BUBBLE_OPTIONS = {
  y: "totalFunding",
  x: "numberOfEmployees", // numberOfEmployees
  size: "numberOfCompanies", // numberOfCompanies
  color: "technology",
  toggleView: "aggregate", // by default it is always aggregate
  showTags: true,
  years: 10,
};

export const RELATIONSHIP_TAGS_COLORS = {
  discover: "#81defd",
  research: "#81defd",
  qualify: "#81defd",
  intake: "#81defd",
  investment: "#81defd",
  consider: "#f8c4fe",
  feature: "#f8c4fe",
  pilot: "#f8c4fe",
  evaluate: "#88b1fc",
  awareness: "#88b1fc",
  nda: "#88b1fc",
  setup: "#88b1fc",
  nda_agreement: "#88b1fc",
  partner: "#91e697",
  top_10: "#91e697",
  samples: "#91e697",
  pilot_agreement: "#91e697",
  partnership_agreement: "#91e697",
  existing_relationship: "#57ae5b",
  no_relationship: "#88b1fc",
  interview: "#ffb088",
  accelerate: "#ffb088",
  validate: "#ffb088",
  evaluation: "#ffb088",
  cw_service: "#ffb088",
  tech_ecosystem: "#c1fef6",
  prototypes: "#c1fef6",
  scale: "#c1fef6",
  contract: "#cbd2d9",
  msa_agreement: "#cbd2d9",
  cw_platform: "#ff9b9b",
  none: "#ccc",
};

const tagsByTotalFunding = [
  {
    name: "$0 (n/a)",
    range: null,
    id: "0na",
    selected: false,
    color: "#010057",
  },
  {
    name: "$1 to $1M",
    range: {
      start: 1,
      end: 999999,
    },
    id: "1to1M",
    selected: false,
    color: "#cc5719",
  },
  {
    name: "$1M to $5M",
    range: {
      start: 1000000,
      end: 4999999,
    },
    id: "1to5",
    selected: false,
    color: "#00949e",
  },
  {
    name: "$5M to $10M",
    range: {
      start: 5000000,
      end: 9999999,
    },
    id: "5to10",
    selected: false,
    color: "#ffd86c",
  },
  {
    name: "$10M to $20M",
    range: {
      start: 10000000,
      end: 19999999,
    },
    id: "10to20",
    selected: false,
    color: "#a333ff",
  },
  {
    name: "$20M to $50M",
    range: {
      start: 20000000,
      end: 49999999,
    },
    id: "20to50",
    selected: false,
    color: "#00611d",
  },
  {
    name: "$50M to $100M",
    range: {
      start: 50000000,
      end: 99999999,
    },
    id: "50to100",
    selected: false,
    color: "#b80000",
  },
  {
    name: "$100M to $500M",
    range: {
      start: 100000000,
      end: 499999999,
    },
    id: "100to500",
    selected: false,
    color: "#00a7fa",
  },
  {
    name: "$500M to $1B",
    range: {
      start: 500000000,
      end: 999999999,
    },
    id: "500to1B",
    selected: false,
    color: "#ff278f",
  },
  {
    name: "$1B to $5B",
    range: {
      start: 1000000000,
      end: 4999999999,
    },
    id: "1to5B",
    selected: false,
    color: "#6aff50",
  },
  {
    name: "$5B to $10B",
    range: {
      start: 5000000000,
      end: 9999999999,
    },
    id: "5to10B",
    selected: false,
    color: "#994113",
  },
  {
    name: "$10B+",
    range: {
      start: 10000000000,
      end: 99999999999999999999999,
    },
    id: "10bPlus",
    selected: false,
    color: "#006269",
  },
];

export const CUSTOM_PIE_TAGS_BY_BREAKDOWN = {
  totalFunding: {
    tags: tagsByTotalFunding,
  },
  amountOfLastRound: {
    tags: tagsByTotalFunding,
  },
  score: {
    tags: [
      {
        name: "1",
        score: 1,
        id: "1",
        selected: false,
        color: "#010057",
      },
      {
        name: "2",
        score: 2,
        id: "2",
        selected: false,
        color: "#cc5719",
      },
      {
        name: "3",
        score: 3,
        id: "3",
        selected: false,
        color: "#00949e",
      },
      {
        name: "4",
        score: 4,
        id: "4",
        selected: false,
        color: "#ffd86c",
      },
      {
        name: "5",
        score: 5,
        id: "5",
        selected: false,
        color: "#a333ff",
      },
      {
        name: "6",
        score: 6,
        id: "6",
        selected: false,
        color: "#00611d",
      },
      {
        name: "7",
        score: 7,
        id: "7",
        selected: false,
        color: "#b80000",
      },
      {
        name: "8",
        score: 8,
        id: "8",
        selected: false,
        color: "#00a7fa",
      },
      {
        name: "9",
        score: 9,
        id: "9",
        selected: false,
        color: "#ff278f",
      },
      {
        name: "10",
        score: 10,
        id: "10",
        selected: false,
        color: "#6aff50",
      },
    ],
  },
  yearFounded: {
    tags: [
      {
        name: "Last 12 months",
        id: "last12Months",
        value: 1,
        selected: false,
        color: "#010057",
      },
      {
        name: "Last 2 years",
        id: "last2Years",
        value: 2,
        selected: false,
        color: "#cc5719",
      },
      {
        name: "Last 5 years",
        id: "last5Years",
        value: 5,
        selected: false,
        color: "#00949e",
      },
      {
        name: "Last 10 years",
        id: "last10Years",
        value: 10,
        selected: false,
        color: "#ffd86c",
      },
      {
        name: "Last 20 years",
        id: "last20Years",
        value: 20,
        selected: false,
        color: "#a333ff",
      },
      {
        name: "20+ Years",
        id: "20YearsPlus",
        value: 21,
        selected: false,
        color: "#00611d",
      },
    ],
  },
  revenue_range: {
    tags: [
      {
        name: "Less than $1M",
        id: "r_00000000",
        value: "r_00000000",
        selected: false,
        color: "#010057",
      },
      {
        name: "$1M to $10M",
        id: "r_00001000",
        value: "r_00001000",
        selected: false,
        color: "#cc5719",
      },
      {
        name: "$10M to $50M",
        id: "r_00010000",
        value: "r_00010000",
        selected: false,
        color: "#00949e",
      },
      {
        name: "$50M to $100M",
        id: "r_00050000",
        value: "r_00050000",
        selected: false,
        color: "#ffd86c",
      },
      {
        name: "$100M to $500M",
        id: "r_00100000",
        value: "r_00100000",
        selected: false,
        color: "#a333ff",
      },
      {
        name: "$500M to $1B",
        id: "r_00500000",
        value: "r_00500000",
        selected: false,
        color: "#00611d",
      },
      {
        name: "$1B to $10B",
        id: "r_01000000",
        value: "r_01000000",
        selected: false,
        color: "#b80000",
      },
      {
        name: "$10B+",
        id: "r_10000000",
        value: "r_10000000",
        selected: false,
        color: "#00a7fa",
      },
    ],
  },
  numberOfEmployees: {
    tags: [
      {
        name: "1 - 10",
        id: "1to10",
        value: "c_00001_00010",
        selected: false,
        color: "#010057",
      },
      {
        name: "11 - 50",
        id: "11to15",
        value: "c_00011_00050",
        selected: false,
        color: "#cc5719",
      },
      {
        name: "51 - 100",
        id: "51to100",
        value: "c_00051_00100",
        selected: false,
        color: "#00949e",
      },
      {
        name: "101 - 250",
        id: "101to250",
        value: "c_00101_00250",
        selected: false,
        color: "#ffd86c",
      },
      {
        name: "251 - 500",
        id: "251to500",
        value: "c_00251_00500",
        selected: false,
        color: "#a333ff",
      },
      {
        name: "501 - 1000",
        id: "501to1000",
        value: "c_00501_01000",
        selected: false,
        color: "#00611d",
      },
      {
        name: "1001 - 5000",
        id: "1001to5000",
        value: "c_01001_05000",
        selected: false,
        color: "#b80000",
      },
      {
        name: "5001 - 10000",
        id: "5001to10000",
        value: "c_05001_10000",
        selected: false,
        color: "#00a7fa",
      },
      {
        name: "10000+",
        id: "10000plus",
        value: "c_10001_max",
        selected: false,
        color: "#ff278f",
      },
    ],
  },
};

export const X_AXIS_BAR = [
  {
    name: "Sectors",
    type: "sectors",
    inChart: "Sectors",
  },
  {
    name: "Companies",
    type: "companies",
    inChart: "Companies",
  },
  {
    name: "Years",
    type: "yearFounded",
    inChart: "Years",
  },
  {
    name: "Number of Employees",
    type: "numberOfEmployees",
    inChart: "Number of Employees",
  },
  {
    name: "Estimated Revenue",
    type: "revenue_range",
    inChart: "Estimated Revenue",
  },
];

export const propsUsedFoCompaniesSelect = {
  totalFunding: "total_fundings_sum",
  last_funding_amount_usd: "last_funding_amount_usd",
  yearsInOperation: "years_in_operation",
  numberOfEmployees: "num_employee_enum",
  council_company_score: "council_company_score",
  numberOfCompanies: "count",
  revenue_range: "revenue_range",
};

export const OLD_CHART_COLORS = [
  "#010057",
  "#cc5719",
  "#00949e",
  "#ffd86c",
  "#a333ff",
  "#00611d",
  "#b80000",
  "#00a7fa",
  "#ff278f",
  "#6aff50",
  "#994113",
  "#006269",
  "#ffce47",
  "#8d02ff",
  "#37ff73",
  "#850000",
  "#0085c7",
  "#f00074",
  "#44ff23",
  "#3633ff",
  "#003236",
  "#cca539",
  "#6b00c2",
  "#06ff51",
  "#ff5050",
  "#006394",
  "#bd005b",
  "#23eb00",
  "#0400fd",
  "#ffa270",
  "#997100",
  "#4f008f",
  "#00c73c",
  "#ff2323",
  "#58c7ff",
  "#8a0043",
  "#1bb800",
  "#0300bd",
  "#ff8a4b",
  "#1ff0ff",
  "#33005c",
  "#00942d",
  "#eb0000",
  "#2fbaff",
  "#ff54a6",
  "#148500",
  "#03008a",
  "#ff6d1f",
  "#00d5e4",
  "#ffdf89",
];

export const CHART_COLORS = [
  "#145BF2",
  "#38DCCB",
  "#A031E1",
  "#EA4D9C",
  "#F8AE58",
  "#57AE5B",
  "#4937D2",
  "#EF4E4E",
  "#B6BD2B",
  "#FF5B03",
  "#08379B",
  "#188B7F",
  "#490F6B",
  "#B81469",
  "#D77709",
  "#3C7C3F",
  "#3526A6",
  "#B81414",
  "#80851E",
  "#B84000",
  "#8BAFF9",
  "#96EDE5",
  "#CE94F0",
  "#F17EBA",
  "#F9C68A",
  "#91CA94",
  "#958BE5",
  "#F6A2A2",
  "#DCE17A",
  "#FF955C",
  "#03153A",
  "#0C4540",
  "#300A48",
  "#810E49",
  "#9D5707",
  "#214523",
  "#13095D",
  "#6E0C0C",
  "#40430F",
  "#662400",
  "#D8E4FD",
  "#CBF6F2",
  "#E6C9F8",
  "#F9C8E1",
  "#FDECD8",
  "#C8E4C9",
  "#DEDAFB",
  "#FBDADA",
  "#E9ECAC",
  "#FFD7C2",
];

export const CUSTOM_YEARS_LIST = [
  {
    name: "1 Year",
    id: "1",
    value: 1,
    selected: false,
    color: "#010057",
  },
  {
    name: "3 Years",
    id: "3",
    value: 3,
    selected: false,
    color: "#cc5719",
  },
  {
    name: "5 Years",
    id: "5",
    value: 5,
    selected: false,
    color: "#00949e",
  },
  {
    name: "10 Years",
    id: "10",
    value: 10,
    selected: false,
    color: "#ffd86c",
  },
  {
    name: "20 Years",
    id: "20",
    value: 20,
    selected: false,
    color: "#a333ff",
  },
  {
    name: "50 Years",
    id: "50",
    value: 50,
    selected: false,
    color: "#00611d",
  },
];

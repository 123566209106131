import * as Yup from "yup";

export const initModel = {
  first: "",
  last: "",
  email: "",
  title: "",
  permission: 2,
  teamId: [],
};

export const validation = Yup.object().shape({
  first: Yup.string().required("First name is required"),
  last: Yup.string().required("Last name is required"),
  email: Yup.string()
    .matches(/^\S*$/, "Email cannot include leading and trailing spaces")
    .min(1, "Email needs to be at least 1 char")
    .max(512, "Email cannot exceed 512 char")
    .required("Email is required"),
});

export const permission = [
  { val: 4, text: "Company" },
  { val: 3, text: "View-Only" },
  { val: 2, text: "Standard" },
  { val: 1, text: "Administrator" },
];

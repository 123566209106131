import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import styles from "./score.module.scss";

import { Icon, smSize } from "../../../../../../common/icon";
import {
  queryStringUrlReplacement,
  useQuery,
} from "../../../../../../common/helper";

const ScoreFilter = ({ expanded, handleExpand, disabled }) => {
  const history = useHistory();
  const scoreFrom = useQuery().get("scoreFrom");
  const scoreTo = useQuery().get("scoreTo");

  const [collapsed, setCollapsed] = useState(false);
  const [scoreValues, setScoreValues] = useState({
    scoreFrom: 0,
    scoreTo: 10,
  });

  useEffect(() => {
    if (disabled) {
      setCollapsed(false);
      return
    }

    setCollapsed(expanded);
  }, [expanded, disabled]);

  useEffect(() => {
    setScoreValues({
      ...scoreValues,
      scoreFrom: scoreFrom || 0,
      scoreTo: scoreTo || 10,
    });
  }, [scoreFrom, scoreTo]);

  const handleCollapseClick = () => {
    if (disabled) {
      return;
    }

    if (!collapsed) {
      handleExpand("score");
    }
    setCollapsed(!collapsed);
  };

  const handleOnChange = (evt, prop) => {
    const { value } = evt.target;
    setScoreValues({
      ...scoreValues,
      [prop]: value,
    });
  };

  const handleBlur = (evt, prop) => {
    const { value } = evt.target;

    if (prop === "scoreFrom" ? scoreFrom === value : scoreTo === value) {
      return;
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      prop,
      value,
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        [prop]: value,
        page: 1,
      },
    });
  };

  const handleKeyPress = (evt, prop) => {
    const key = evt.keyCode || evt.which;

    if (key === 13) {
      handleBlur(evt, prop);
    }
  };

  return (
    <div className={`${styles.itemWrapper} ${disabled ? styles.disabled : ''}`} id="score">
      <div className={styles.itemTitle} onClick={handleCollapseClick}>
        <span>Score</span>
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(styles.icon, !collapsed && styles.collapse)}
          onClick={handleCollapseClick}
        />
      </div>
      {collapsed ? (
        <div
          className={classNames(
            "d-flex align-items-center",
            styles.withPaddingLeft,
          )}
        >
          <div className={classNames("d-flex flex-column", styles.scoreField)}>
            <label htmlFor="from">From</label>
            <input
              id="from"
              min="0"
              max="10"
              type="number"
              value={scoreValues.scoreFrom}
              className={styles.filterInput}
              onChange={(evt) => handleOnChange(evt, "scoreFrom")}
              onBlur={(evt) => handleBlur(evt, "scoreFrom")}
              onKeyPress={(evt) => handleKeyPress(evt, "scoreFrom")}
            />
          </div>
          <span className={styles.scoreDelimiter}>-</span>
          <div className={classNames("d-flex flex-column", styles.scoreField)}>
            <label htmlFor="to">To</label>
            <input
              id="to"
              min="0"
              max="10"
              type="number"
              value={scoreValues.scoreTo}
              className={styles.filterInput}
              onChange={(evt) => handleOnChange(evt, "scoreTo")}
              onBlur={(evt) => handleBlur(evt, "scoreTo")}
              onKeyPress={(evt) => handleKeyPress(evt, "scoreTo")}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(ScoreFilter);

import React, { useEffect, useState } from "react";
import classnames from "classnames";

import styles from "./genericReportsList.module.scss";
import { Button, CompanyAvatar } from "modules/primitives";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const StringComponent = ({ value, showLogo, data, config }) => {
  const [clearString, setClearString] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (value) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(value, 'text/html');
      const clearText = doc.body.textContent;

      setClearString(clearText);
    }
  }, [value]);

  const handleClick = () => {
    if (!config.withNavigation) {
      return;
    }

    const url = `${config.urlPath}${data.slug || data.id || data.company_id}`;
    window.open(url, "_blank");
  }

  const handleShowMore = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <div
      className={classnames('d-flex align-items-center text-capitalize', config.withNavigation ? 'cursor-pointer' : '')}
      onClick={handleClick}
    >
      {
        showLogo ? (
          <div className={styles.logoCon}>
            <CompanyAvatar
              className={styles.logo}
              imgSrc={data.logo}
              name={value}
            />
          </div>
        ) : null
      }
      <div className="d-flex flex-column">
        {
          !config.withNavigation && clearString ? (
            <span>
              {
                clearString?.length > 60 ? `${clearString.slice(0, 60)}...` : clearString
              }
            </span>
          ) : config.withNavigation ? (clearString || '-') : '-'
        }
        {
          !config?.withNavigation && clearString && clearString.toString().length > 60 ? (
            <span className="small cursor-pointer mt-2" onClick={handleShowMore}>show more</span>
          ) : null
        }
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <div className="d-flex flex-wrap">
            {clearString || '-'}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(StringComponent);

import * as Yup from "yup";

const initModel = {
  name: '',
  description: '',
  position: 1,
  access: '',
  id: '',
  active: true,
};

const validation = Yup.object().shape({
  name: Yup.string().required("Group name is required"),
  position: Yup.number().required("Position is required"),
});

export { validation, initModel };

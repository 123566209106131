import React from "react";
import { format } from "date-fns";

import styles from "./company.module.scss";
import { ESTIMATED_REVENUE_ENUM } from "../../../../common/constants";
import CompanyBlockOwners from "modules/companies/companyMain/companyBlockOwners/companyBlockOwners";
import { useCompanyContext } from "modules/companies/companyMain/providers/companyDataProvider";

const CompanyOtherDetails = ({ company, selectedCouncil, relationshipOwners }) => {
  const { locationType } = useCompanyContext();

  return (
    <div
      className={`d-flex align-items-center flex-wrap mt-3 mb-3 ${styles.companyDetails}`}
    >
      <div className={styles.location}>
        {company.location_city ||
        company.location_region ||
        company.location_country ||
        company.location_continent ? (
          <>
            HQ Location:{" "}
            <b>{`
                  ${company.location_city ? `${company.location_city}` : ""}
                  ${
              company.location_region
                ? `${company.location_city ? ", " : ""}${
                  company.location_region
                }`
                : ""
            }
                  ${
              company.location_country
                ? `, ${company.location_country}`
                : ""
            }
                  ${
              company.location_continent && locationType === "continent"
                ? `, ${company.location_continent}`
                : ""
            }
                `}</b>
          </>
        ) : null}
      </div>
      {selectedCouncil &&
      selectedCouncil.name &&
      company.council_company_score ? (
        <div
          className={`${styles.score} ${company.founded ? "mb-0" : ""}`}
        >
          {selectedCouncil?.name === "Kyndryl"
            ? "Average Kyndryl"
            : selectedCouncil?.name}{" "}
          Score: <b>{company.council_company_score || "-"}</b>
        </div>
      ) : null}
      {company.founded ? (
        <div
          className={`${styles.score} mt-0 ${
            company.total_fundings_sum ||
            company.relationship_owners?.length ||
            company.nda ||
            company.msa ||
            company.rfp
              ? "mb-0"
              : ""
          }`}
        >
          Year founded:{" "}
          <b>
            {company.founded
              ? format(new Date(company.founded), "yyyy")
              : "-"}
          </b>
        </div>
      ) : null}
      {company.total_fundings_sum ? (
        <div
          className={`${styles.score} mt-0 ${
            company.relationship_owners?.length ? "mb-0" : ""
          }`}
        >
          Total funding:{" "}
          <b>
            {company.total_fundings_sum
              ? `$${company.total_fundings_sum.toLocaleString("en-US")}`
              : "-"}
          </b>
        </div>
      ) : null}
      {company.revenue_range ? (
        <div
          className={`${styles.score} mt-0 ${
            company.relationship_owners?.length ? "mb-0" : ""
          }`}
        >
          Estimated revenue:{" "}
          <b>
            {company.revenue_range
              ? `${ESTIMATED_REVENUE_ENUM[company.revenue_range]}`
              : "-"}
          </b>
        </div>
      ) : null}
      {relationshipOwners?.length &&
      company.relationship_owners?.length ? (
        <div
          className={`d-flex flex-wrap ${styles.score} mt-0 ${
            company.nda || company.msa || company.rfp ? "mb-0" : ""
          }`}
        >
          <span className="mr-3">Relationship Owners:</span>
          <CompanyBlockOwners
            allOwners={relationshipOwners}
            itsOwners={company.relationship_owners}
          />
        </div>
      ) : null}
      {company.nda || company.msa || company.rfp ? (
        <div className={`d-flex flex-wrap ${styles.score} mt-0`}>
          <span>Agreements:&nbsp;</span>
          <b>
            {company.nda
              ? `NDA${company.msa || company.rfp ? ", " : ""}`
              : ""}
            {company.msa ? `MSA${company.rfp ? ", " : ""}` : ""}
            {company.rfp ? "RFP" : ""}
          </b>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(CompanyOtherDetails);

export const tabs = {
  0: "Overview",
  1: "Stage",
  2: "Tasks",
  3: "Documents",
  4: "Ratings",
};

export const moreOptionTypes = {
  share: 1,
  discuss: 2,
  // downloadReport: 3,
  addToList: 3,
  addToProject: 4,
  createNewList: 5,
  addToIdea: 6,
  addToTheme: 7,
};

export const companyMoreOptions = [
  {
    val: moreOptionTypes.share,
    text: "Share",
  },
  {
    val: moreOptionTypes.discuss,
    text: "Discuss",
  },
  {
    val: moreOptionTypes.addToList,
    text: "Add to list",
  },
  {
    val: moreOptionTypes.addToIdea,
    text: "Add to Idea",
  },
  {
    val: moreOptionTypes.addToProject,
    text: "Add to project",
  },
  {
    val: moreOptionTypes.createNewList,
    text: "Create New List",
  },
  {
    val: moreOptionTypes.addToTheme,
    text: "Add to theme",
  },
  // {
  //   val: moreOptionTypes.downloadReport,
  //   text: 'Download Report',
  // },
];

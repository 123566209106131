import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  BtnType,
  MoreOptions,
  OptionType,
} from "modules/primitives/index";
import ReactTable from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useQuery } from "common/helper";
import WarningMessage from "common/components/warningMessage/index";
import styles from "./adminRating.module.scss";
import { useModelPopup } from "../../../common/hooks";
import AdminRatingModal from "./adminRatingModal/adminRatingModal.component";
import { ratingsTemplateGetAttempt } from "./adminRating.action";
import ConfirmDeleteComponent from "../../../common/components/confirmDelete";
import { companyGetTopics } from "../../companies/companyMain/companyMain.action";
import classnames from "classnames";

const AdminRating = () => {
  const history = useHistory();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [ratingTemplatesData, setRatingTemplatesData] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState("");
  const [selectedData, setSelectedData] = useState([]);

  const [ideaTemplates, setIdeaTemplates] = useState([]);
  const [companyTemplates, setCompanyTemplates] = useState([]);
  const [projectCompanyTemplatesData, setProjectCompanyTemplatesData] = useState([]);
  const [isWarning, setIsWarning] = useState({
    warning: false,
    message: "",
  });
  const [ableToDelete, setAbleToDelete] = useState(true);

  const tab = useQuery().get("tab");

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    companiesReducer: {
      companyMainReducer: { topics },
    },
    adminRatingTemplatesReducer: {
      ratingTemplates,
      loading,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedVariant === "idea" && ideaTemplates.length) {
      const anyDefault = ideaTemplates.find(
        (template) => template.default_template,
      );
      if (!anyDefault && tab === "idea") {
        setIsWarning({
          warning: true,
          message: "There should be at least one idea with default template!",
        });
      } else {
        setIsWarning({
          warning: false,
          message: "",
        });
      }
    } else {
      setIsWarning({
        warning: false,
        message: "",
      });
    }
  }, [selectedVariant, ratingTemplatesData, companyTemplates]);

  // useEffect(() => {
  //   if (selectedVariant === "project" && ratingTemplatesData.length) {
  //     const anyDefault = ratingTemplatesData.find(
  //       (template) => template.default_template,
  //     );
  //     if (!anyDefault) {
  //       setIsWarning({
  //         warning: true,
  //         message: "There should be at least one project default template!",
  //       });
  //     } else {
  //       setIsWarning({
  //         warning: false,
  //         message: "",
  //       });
  //     }
  //   } else if (selectedVariant === "company" && companyTemplates.length) {
  //     const anyDefault = companyTemplates.find(
  //       (template) => template.default_template,
  //     );
  //     if (!anyDefault) {
  //       setIsWarning({
  //         warning: true,
  //         message: "There should be at least one company default template!",
  //       });
  //     } else {
  //       setIsWarning({
  //         warning: false,
  //         message: "",
  //       });
  //     }
  //   } else if (selectedVariant === "project_company" && companyTemplates.length) {
  //     const anyDefault = projectCompanyTemplatesData.find(
  //       (template) => template.default_template,
  //     );
  //     if (!anyDefault) {
  //       setIsWarning({
  //         warning: true,
  //         message: "There should be at least one project company default template!",
  //       });
  //     } else {
  //       setIsWarning({
  //         warning: false,
  //         message: "",
  //       });
  //     }
  //   }
  // }, [selectedVariant, ratingTemplatesData, companyTemplates]);

  useEffect(() => {
    if (session) {
      dispatch(
        companyGetTopics({
          userId: session.id,
          councilId: JSON.parse(localStorage.getItem("council")).id,
          enqueueSnackbar,
        }),
      );

      dispatch(ratingsTemplateGetAttempt({ enqueueSnackbar }));
    }
  }, [session, selectedCouncil]);

  const handleAddTemplateClick = (e) => {
    popup.show({
      title: "Add Ratings Template",
      component: (
        <AdminRatingModal
          selectedVariant={selectedVariant}
          mode="add"
          enqueueSnackbar={enqueueSnackbar}
          companyTemplates={companyTemplates}
          topics={topics}
        />
      ),
    });
  };

  useEffect(() => {
    if (ratingTemplates) {
      const sortTemplates = (a, b) => {
        if (a.default_template) {
          return -1;
        }
        if (!a.default_template) {
          return 1;
        }
        return 0;
      };
      const ratingsWithoutCompany = ratingTemplates
        .filter((template) => template.template_type === "project")
        .sort(sortTemplates);
      setRatingTemplatesData(ratingsWithoutCompany);

      const ratingsWithCompany = ratingTemplates
        .filter((template) => template.template_type === "company")
        .sort(sortTemplates);
      setCompanyTemplates(ratingsWithCompany);

      const ratingsWithProjectCompany = ratingTemplates
        .filter((template) => template.template_type === "project_company")
        .sort(sortTemplates);
      setProjectCompanyTemplatesData(ratingsWithProjectCompany);

      const ratingsWithIdea = ratingTemplates
        .filter((template) => template.template_type === "idea")
        .sort(sortTemplates);
      setIdeaTemplates(ratingsWithIdea);
    }
  }, [ratingTemplates]);

  const moreOptions = [
    { val: "edit", text: "Edit" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const handleMoreOptionClick = (event, template) => {
    if (event === "edit") {
      popup.show({
        title: "Edit Rating Template",
        component: (
          <AdminRatingModal
            mode="edit"
            companyTemplates={companyTemplates}
            templateName={template.name}
            description={template.description}
            templateId={template.rating_template_id}
            enqueueSnackbar={enqueueSnackbar}
            councilId={selectedCouncil?.id}
            defaultTemplate={template.default_template}
            template_type={template.template_type}
            active={template.active}
            max_scale={template.max_scale}
            topicIds={template?.topic_ids}
            companyTopicsRelated={template?.company_topics_related}
            topics={topics}
          />
        ),
      });
    }
    if (event === "delete") {
      popup.show({
        title: "Confirmation Prompt",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={template.rating_template_id}
            enqueueSnackbar={enqueueSnackbar}
            // councilId={selectedCouncil.id}
            name={template.name}
            actionType="deleteRatingTemplate"
          />
        ),
      });
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Ratings Template",
        accessor: "name",
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "flex-start",
        },
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Default",
        id: "default_template",
        width: 60,
        accessor: (row) => <div>{row.default_template ? "Yes" : "No"}</div>,
      },

      {
        Header: "Complete",
        id: "default_templates",
        width: 100,
        accessor: (row) => {
          let subCategoriesWeight = 0;
          if (row.rating_categories) {
            row.rating_categories.map((category) => {
              subCategoriesWeight += Number(category.weight);
            });
          }
          if (subCategoriesWeight < 100) {
            return <div style={{ color: "red" }}>No</div>;
          }
          return <div>Yes</div>;
        },
      },
      {
        Header: "Options",
        id: "options",
        width: 100,
        accessor: (row) => (
          <MoreOptions
            options={
              row.default_template || (row.template_type === "idea" && ideaTemplates.length === 1)
                ? moreOptions.filter((option) => option.val !== "delete")
                : moreOptions
            }
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row);
            }}
          />
        ),
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [ratingTemplates, topics],
  );

  const onTabClick = (queryTab) => {
    setSelectedVariant(queryTab);
    history.push(`/admin/ratings?tab=${queryTab}`);
  };

  useEffect(() => {
    setSelectedVariant(tab);
    if (tab === "project") {
      setSelectedData(ratingTemplatesData);
    } else if (tab === "project_company") {
      setSelectedData(projectCompanyTemplatesData);
    }
    else if (tab === "company") {
      setSelectedData(companyTemplates);
    }
    else if (tab === "idea") {
      setSelectedData(ideaTemplates);
    }
  }, [tab, ratingTemplates, ratingTemplatesData]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <span
            className={styles.rootText}
            onClick={() => {
              history.push("/admin");
            }}
          >
            ADMIN
          </span>
          <span className={styles.separationBar}> / </span>
          <span className={styles.rootText}>RATINGS</span>
        </h4>
      </div>
      <div className="row">
        <div
          className={classnames(styles.tabsContainer, "col-md-10")}
          style={{ margin: "0 auto", paddingTop: "40px" }}
        >
          <h5>
            <span
              onClick={() => {
                onTabClick("company");
              }}
              className={
                selectedVariant === "company"
                  ? styles.selectedVar
                  : styles.tabsText
              }
            >
              {" "}
              Company{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span
              onClick={() => {
                onTabClick("project");
              }}
              className={
                selectedVariant === "project"
                  ? styles.selectedVar
                  : styles.tabsText
              }
            >
              {" "}
              Project{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span
              onClick={() => {
                onTabClick("project_company");
              }}
              className={
                selectedVariant === "project_company"
                  ? styles.selectedVar
                  : styles.tabsText
              }
            >
              {" "}
              Project Company{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span
              onClick={() => {
                onTabClick("idea");
              }}
              className={
                selectedVariant === "idea"
                  ? styles.selectedVar
                  : styles.tabsText
              }
            >
              {" "}
              Idea{" "}
            </span>
          </h5>
        </div>
      </div>
      <div className={styles.warningContainer}>
        {isWarning.warning && <WarningMessage message={isWarning.message}/>}
      </div>
      <div className={styles.flexWrapper}>
        <div className={styles.ratingTamplates}>
          <div className={styles.addTemplateButton}>
            <Button
              btn={BtnType.REGULAR}
              icon="icn-add"
              onClick={() => handleAddTemplateClick()}
            >
              Add template
            </Button>
          </div>
          <ReactTable
            columns={columns}
            // data={selectedVariant === 'projects' ? ratingTemplatesData : companyTemplates}
            data={selectedData}
            showPagination={false}
            minRows={10}
            loading={loading}
            className={styles.table}
            loadingText="Updating Ratings template across the company database. Please wait . . ."
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: () => {
                    history.push(
                      `/admin/ratings/${rowInfo.original.rating_template_id}`,
                    );
                  },
                  onMouseEnter: (e) => {
                    setHoveredRow(rowInfo.index);
                  },
                  onMouseLeave: (e) => {
                    setHoveredRow(null);
                  },
                  style: {
                    cursor: "pointer",
                    background: rowInfo?.original?.default_template
                      ? "#d1eefc"
                      : rowInfo.index === hoveredRow
                        ? "#efefef"
                        : 'transparent',
                  },
                };
              }
              return {};
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default AdminRating;

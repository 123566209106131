import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import { apiStatus, httpPost, errorHandler } from "../../../common/httpCall";
import { PATIENT_ORGANIZATION_POST_ATTEMPT } from "./newPatientOrganization.action";
import { patientOrganizationsGet } from "../patientOrganizationMain/patientOrganizationMain.action";

const epicPatientOrganizationAttempt = (action$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_POST_ATTEMPT),
  switchMap(({ payload: { values, selectedCouncil, enqueueSnackbar } }) => httpPost({
    apiVersion: "v3",
    call: "gsk/patient_organizations",
    data: {
      name: values.name,
      website: values.web_url,
      // country: values.country,
      // relationship_owners_ids: values.relationship_owners_ids,
    },
  }).pipe(
    map((result) => {
      try {
        if (
          result.status === apiStatus.CREATED
              || result.status === apiStatus.SUCCESS
        ) {
          enqueueSnackbar("Successfully added organization", {
            variant: "success",
          });
        }

        return patientOrganizationsGet({
          councilId: selectedCouncil.id,
          items: 10,
          page: 1,
          enqueueSnackbar,
        });
      } catch (error) {
        console.log("-----????  catch ?????-----", error);
      }
    }),
    catchError((error) => errorHandler(error, enqueueSnackbar)),
  )),
);

const newPatientOrganizationEpic = combineEpics(epicPatientOrganizationAttempt);

export default newPatientOrganizationEpic;

import * as Yup from "yup";

import { checkIsStringWhenExpectArray } from "../../../../common/helper";

export const generateStepValidationSchema = (wizardSteps) => {
  if (!wizardSteps) {
    return {}
  }

  return wizardSteps.reduce((acc, step, index) => {
    acc[index] = Yup.object().shape(
      step.projects_wizard_options.reduce((fields, option) => {
        if (option.required) {
          if (option.field_type === 'project_plants') {
            fields[option.field_type] = Yup.lazy((values) =>
              Yup.object().shape({
                id: Yup.mixed().required("Project plants are required"),
                selectedAreas: Array.isArray(values?.areas) && values.areas.length > 0
                  ? Yup.array().min(1, "At least one area must be selected").required("Areas are required")
                  : Yup.array(),
              })
            );
          } else {
            fields[option.field_type] = Yup.string().required(`${option.field_name || option.field_type} is required`);
          }
        }
        return fields;
      }, {})
    );
    return acc;
  }, {});
};

const possibleStorageKeys = [
  'project_companies',
  'project_industries',
  'project_members',
  'project_topics',
  'options_score_selected',
  'disease_ids',
  'organizations',
];

export const makeProjectPayloadV3 = (values, wizardData, wizardType, additionalData) => {
  const getStorageProject = JSON.parse(localStorage.getItem("new_project"));

  const makePropsBasedOnStorageKeys = () => {
    return possibleStorageKeys.reduce((acc, key) => {
      if (key === 'options_score_selected' && getStorageProject?.options_score_selected?.length) return {
        ...acc,
        gsk_project_option_scores_attributes: getStorageProject.options_score_selected?.map((obj) => ({
          ...obj,
          gsk_option_score_id: obj.id,
        })) || [],
      }

      if (key === 'organizations' && getStorageProject?.organizations) return {
        ...acc,
        patient_organization_ids: getStorageProject?.organizations.map(c => c.gsk_patient_organization_id)
      }

      if (key === 'disease_ids' && getStorageProject?.disease_ids) return {
        ...acc,
        disease_ids: getStorageProject?.disease_ids
      }

      if (key === 'project_companies' && getStorageProject?.companies?.length) return {
        ...acc,
        [key]: getStorageProject.companies.map(c => c.company_id),
      }

      if (key === 'project_industries' && getStorageProject?.industrys?.length) return {
        ...acc,
        industry_ids: getStorageProject.industrys,
      }

      if (key === 'project_members' && getStorageProject?.members?.length) return {
        ...acc,
        member_ids: getStorageProject.members.map(m => m.id),
      }

      if (key === 'project_topics' && getStorageProject?.topics) return {
        ...acc,
        topic_ids: getStorageProject?.topics
      }

      if (getStorageProject && getStorageProject[key]) return {
        ...acc,
        [key]: getStorageProject[key]
      }

      return acc
    }, {});
  };

  const makePlantsIfExists = () => {
    let props = {};

    if (values.project_plants?.id) {
      props = {
        plant_and_area_status: values.project_replication || values.original_project_id ? 'under_review' : 'original',
        status_plant_id: values.project_plants.id,
        status_area_id: values.project_plants?.selectedAreas?.length
          ? values.project_plants?.selectedAreas?.map(a => a.area_id)[0]
          : null,
        plant_id: values.project_plants.id,
        area_ids: values.project_plants?.selectedAreas?.map(a => a.area_id) || [],
      };
    }

    if (values.possible_replications_plants?.length) {
      props = {
        ...props,
        possible_plant_ids: values.possible_replications_plants.map(p => p.id),
        possible_area_ids: values.possible_replications_plants.reduce((acc, plant) => {
          return [...acc, ...(plant.selectedAreas || [])]
        }, []).map(a => a.id || a.area_id),
      }
    }

    return props;
  }

  const projectFieldsArray = wizardData.projects_wizard_steps.reduce((acc, elem) => {
    return [...acc, ...elem.projects_wizard_options.filter(option => option.isProjectField)]
  }, []);

  const {
    project_fields = {},
    project_owner = [],
    project_name,
    project_access,
    project_theme = [],
    similar_projects = [],
    similar_projects_recommendations = [],
    project_small_companies,
    project_small_members,
    project_stage,
    ...rest
  } = values;

  const propsBasedOnStorageKeys = makePropsBasedOnStorageKeys();

  let specificForCompany = {}

  if (wizardType === 'company') {
    specificForCompany = {
      project_companies: propsBasedOnStorageKeys?.project_companies?.length
        ? [...propsBasedOnStorageKeys.project_companies, additionalData.id]
        : [additionalData.id],
    }
  }

  if (wizardType === 'patient_organization') {
    specificForCompany = {
      patient_organization_ids: propsBasedOnStorageKeys?.patient_organization_ids?.length
        ? [...propsBasedOnStorageKeys.patient_organization_ids, additionalData.id]
        : [additionalData.id],
    }
  }

  return {
    ...rest,
    ...makePlantsIfExists(),
    ...propsBasedOnStorageKeys,
    ...specificForCompany,
    theme_ids: checkIsStringWhenExpectArray(project_theme).map(t => t.id) || [],
    custom_project_fields: Object.keys(project_fields).map(key => {
      const getOriginalField = projectFieldsArray.find(field => field.project_field_template_id === key);
      let props = {};

      if (getOriginalField?.field_render_type === 'dropdown') {
        props = {
          option_selected: checkIsStringWhenExpectArray(project_fields[key]).map(el => el.id) || [],
        }
      } else if (getOriginalField?.field_render_type === 'date') {
        props = {
          field_date: project_fields[key],
        }
      } else if (getOriginalField?.field_render_type === 'number') {
        props = {
          field_number: project_fields[key],
        }
      } else if (getOriginalField?.field_render_type === 'currency') {
        props = {
          field_number: project_fields[key],
          option_selected: getOriginalField.option_values[0]
            ? [getOriginalField.option_values[0]]
            : [],
        }
      } else {
        props = {
          field_value: project_fields[key],
        }
      }

      return {
        project_field_template_id: getOriginalField?.project_field_template_id,
        field_name: getOriginalField?.field_name,
        ...props,
      }
    })?.filter(el => el.project_field_template_id) || [],
    name: project_name,
    visibility: project_access || 'all',
    project_owner_ids: checkIsStringWhenExpectArray(project_owner).map(o => o.user_id || o.id),
    idea_ids: wizardType === 'idea' ? [additionalData.id] : null,
    similar_projects: checkIsStringWhenExpectArray(similar_projects).map(p => p.id),
    similar_projects_recommendations: checkIsStringWhenExpectArray(similar_projects_recommendations).map(p => p.id),
    stage_id: project_stage?.id,
  }
}

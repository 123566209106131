import React from "react";
import { Button, BtnType } from "..";

const CalendarBtn = ({ children, onClick }) => (
  <Button btn={BtnType.OUTLINE_LIGHT} onClick={onClick}>
    ADD to calendar
  </Button>
);

export default CalendarBtn;

export const PIE_CHART_INTRO_STEPS = [
  {
    title: "1. Narrow Your Search",
    element: ".left-filters",
    intro: "Select filters to reduce the number of companies being charted",
    position: "right",
  },
  {
    title: "2. Change the Chart",
    element: ".bubble-options",
    intro: "Use the controls at top to change the data plotted in the chart",
  },
  {
    title: "3. Explore the Data",
    element: ".tags-wrapper",
    intro: "Click on tags to highlight or hide different segments",
    position: "left",
  },
  {
    title: "4. See the Underlying Data",
    element: ".chart-companies-wrapper",
    intro:
      "The List view below shows you exactly which companies are being charted",
    position: "left",
  },
];

export const BAR_CHART_INTRO_STEPS = [
  {
    title: "1. Narrow Your Search",
    element: ".left-filters",
    intro: "Select filters to reduce the number of companies being charted",
    position: "right",
  },
  {
    title: "2. Change the Chart",
    element: ".bubble-options",
    intro: "Use the controls at top to change the data plotted in the chart",
  },
  {
    title: "3. Explore the Data",
    element: ".tags-wrapper",
    intro: "Click on tags to highlight or hide different segments",
    position: "left",
  },
  {
    title: "4. See the Underlying Data",
    element: ".chart-companies-wrapper",
    intro:
      "The List view below shows you exactly which companies are being charted",
    position: "left",
  },
];

export const BUBBLE_CHART_INTRO_STEPS = [
  {
    title: "1. Narrow Your Search",
    element: ".left-filters",
    intro: "Select filters to reduce the number of companies being charted",
    position: "right",
  },
  {
    title: "2. Change the Chart",
    element: ".bubble-options",
    intro: "Use the controls at top to change the data plotted in the chart",
  },
  {
    title: "3. Explore the Data",
    element: ".tags-wrapper",
    intro: "Click on tags to highlight or hide different segments",
    position: "left",
  },
  {
    title: "4. Switch Views",
    element: ".toggle-bubble-chart",
    intro: "Switch from aggregate (sector) to detailed (company) views",
  },
  {
    title: "5. See the Underlying Data",
    element: ".chart-companies-wrapper",
    intro:
      "The List view below shows you exactly which companies are being charted",
    position: "left",
  },
];

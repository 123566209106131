import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styles from "./council.module.scss";
import { useModelPopup } from "../../common/hooks";
import SelectCouncilComponent from "./selectCouncil";
import { setScrolledTop } from "../companies/companyMain/companyMain.action";

const ClaimCouncilComponent = () => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const history = useHistory();

  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: { isScrolled },
    },
  } = useSelector((state) => state);

  const onScrollEventFunc = () => {
    const isAdminRatings = history.location.pathname.includes("/admin/ratings");
    if (window.scrollY > 0 && !isScrolled && !isAdminRatings) {
      dispatch(setScrolledTop(true));
    }
    if (window.scrollY === 0 && !isAdminRatings) {
      dispatch(setScrolledTop(false));
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScrollEventFunc);

    return () => {
      window.removeEventListener("scroll", onScrollEventFunc);
    };
  }, []);

  useEffect(() => {
    if (isScrolled) {
      dispatch(setScrolledTop(false));
    }
  }, [history.location.pathname]);

  const handleChangeCouncilClick = () => {
    popup.show({
      title: "Choose Council",
      component: <SelectCouncilComponent />,
    });
  };

  return (
    <div className={styles.claimCouncilWrp} onClick={handleChangeCouncilClick}>
      {selectedCouncil && (
        <div>
          <img
            alt={selectedCouncil.name}
            src={selectedCouncil.logo}
            className={styles.logo}
          />
          <span className={styles.councilName}>
            Connected to: <b>{selectedCouncil.name}</b>
          </span>
        </div>
      )}
    </div>
  );
};

export default React.memo(ClaimCouncilComponent);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import mainStyles from "../../../ideasWrapper.module.scss";
import styles from "./team.module.scss";
import AddDiscussionIcon from "../../../../../assets/icn-table-add.svg";
import AddIdeaTeamMember from "./addTeamMember";
import { appModelSettingSet } from "application/app.action";
import { UserInline, UserInlineTypes } from "../../../../primitives";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import { deleteIdeaTeamMemberAttempt } from "../../../idea.action";
import NewDiscussion from "../../../../../common/components/newDiscussion/newDiscussion.component";

const IdeaProfileTeam = ({ selectedIdea, canEdit, isReviewersInstance }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    authReducer: { session },
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const [usersByRole, setUsersByRole] = useState([]);

  useEffect(() => {
    if (selectedIdea.ideas_members?.length) {
      if (isReviewersInstance) {
        const reviewers = selectedIdea.ideas_members.filter(
          (m) => m.user_role !== "member" && m.user_role !== "submitter"
        );

        setUsersByRole(reviewers);
      } else {
        const members = selectedIdea.ideas_members.filter(
          (m) => m.user_role === "member"
        );

        setUsersByRole(members);
      }
    } else {
      setUsersByRole([]);
    }
  }, [isReviewersInstance, selectedIdea.ideas_members]);

  const handleAddTeamMember = () => {
    const customSettings = {
      title: `Add ${isReviewersInstance ? "reviewer" : "team member"}`,
      show: true,
      height: "300",
      width: "540",
      component: (
        <AddIdeaTeamMember
          isReviewersInstance={isReviewersInstance}
          usersByRole={usersByRole}
          selectedIdea={selectedIdea}
        />
      ),
    };

    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  const deleteTeamMember = (member) => {
    dispatch(
      deleteIdeaTeamMemberAttempt({
        id: member.idea_member_id,
        enqueueSnackbar,
        cb: () => {
          enqueueSnackbar(
            `Successfully deleted team ${
              isReviewersInstance ? "reviewer" : "member"
            }`,
            {
              variant: "success",
            }
          );
        },
      })
    );
  };

  const startNewDiscussion = (member) => {
    const full_name = `${member.user_first_name} ${member.user_last_name}`;
    const customSettings = {
      title: `Start a New Private Discussion with ${full_name}`,
      show: true,
      height: "300",
      width: "540",
      component: (
        <NewDiscussion
          type="Council"
          isPrivate
          hideTeam
          selectedUser={{ ...member, id: member.user_id, full_name }}
        />
      ),
    };
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  const handleUserRedirect = (userId) => {
    if (userId) {
      history.push(`/people/internal/profile/${userId}`);
    }
  };

  return (
    <div className="d-flex flex-column mt-4">
      <div
        className={`d-flex align-items-center justify-content-between ${mainStyles.blockHeader}`}
      >
        <span className={mainStyles.blockTitle}>
          {isReviewersInstance
            ? "Reviewers"
            : selectedCouncil?.name === "Kyndryl"
            ? "Idea Team"
            : "Team"}
        </span>
        {session?.council_role !== "company" && (
          <>
            {canEdit && (
              <img
                className={mainStyles.addIcon}
                onClick={handleAddTeamMember}
                src={AddDiscussionIcon}
                alt={isReviewersInstance ? "Add new reviewer" : "Add new team"}
              />
            )}
          </>
        )}
      </div>
      <div className={`${styles.teamContent} ${mainStyles.blockContent}`}>
        {usersByRole.length === 0
          ? isReviewersInstance
            ? "No reviewers yet."
            : "No team members yet."
          : usersByRole.map((member) => (
              <div key={member.idea_member_id} className={styles.teamMember}>
                <div className="d-flex">
                  <div
                    className={styles.teamAvatar}
                    onClick={() => handleUserRedirect(member.user_id)}
                  >
                    <UserInline
                      userId={member.idea_member_id}
                      first={member.user_first_name}
                      last={member.user_last_name}
                      avatar={member.avatar}
                      local
                      noNavigation
                      type={UserInlineTypes.IMAGE_ONLY}
                    />
                  </div>
                  <div className="d-flex flex-column ml-2">
                    <span
                      onClick={() => handleUserRedirect(member.user_id)}
                      className={styles.memberName}
                    >
                      {member.user_first_name} {member.user_last_name}
                    </span>
                    <span className={styles.memberTitle}>
                      {member.user_title}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  {member.vote_casted && (
                    <span className={styles.memberCast}>
                      Vote Cast &#10003;
                    </span>
                  )}
                  <div
                    className={`d-flex flex-column align-items-center ${styles.memberActions}`}
                  >
                    <Icon
                      {...mdSize}
                      icon="icn-table-message"
                      className={styles.messageIcon}
                      onClick={() => startNewDiscussion(member)}
                    />
                    {canEdit && (
                      <Icon
                        {...smSize}
                        icon="icn-button-delete"
                        className={styles.deleteIcon}
                        onClick={() => deleteTeamMember(member)}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default React.memo(IdeaProfileTeam);

export const navElements = [
  {
    val: "councils",
    text: "Councils",
    disabled: false,
  },
  {
    val: "companies",
    text: "Companies",
    disabled: false,
  },
  {
    val: "competitors",
    text: "Competitors",
    disabled: true,
  },
  {
    val: "customers",
    text: "Customers",
    disabled: true,
  },
  {
    val: "tags",
    text: "Tags",
    disabled: true,
  },
  {
    val: "applicant-submissions",
    text: "Applicants",
    disabled: false,
  },
];

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon, mdSize } from "../../../../../common/icon";
import styles from "./ideaLayoutSwitcher.module.scss";

const layoutTypes = [
  {
    type: "list",
    icon: "icn-view-list",
  },
  {
    type: "grid",
    icon: "icn-view-card",
  },
];

const IdeaLayoutSwitcher = ({ activeLayout, onLayoutChange }) => (
  <div className="d-flex">
    {layoutTypes.map(({ type, icon }) => (
      <div
        className={styles.layoutSwitcherItem}
        key={`idea-layout-view-${type}`}
      >
        <Icon
          {...mdSize}
          icon={icon}
          className={classNames(
            styles.icon,
            activeLayout === type && styles.active,
          )}
          onClick={() => onLayoutChange(type)}
        />
      </div>
    ))}
  </div>
);

IdeaLayoutSwitcher.defaultProps = {
  onLayoutChange: () => {},
  activeLayout: "list",
};

IdeaLayoutSwitcher.propTypes = {
  onLayoutChange: PropTypes.func,
  activeLayout: PropTypes.oneOf(["list", "grid"]),
};

export default IdeaLayoutSwitcher;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

import styles from "./rightWindow.module.scss";
import MyListsComponent from "../myLists";
import { setSelectedList, setScrolledTop } from "../../store/lists.actions";
import { getFollowersAttempt } from "modules/settings/settings.action";

const RightWindowComponent = () => {
  const {
    listsMainReducer: { selectedList, isScrolled },
    councilReducer: { selectedCouncil },
    settingsReducer: { followers, updateFollowers },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { list_id } = useParams();

  const onScrollEventFunc = (event) => {
    const scrolled = event.target.scrollTop;
    if (scrolled > 0 && !isScrolled) {
      dispatch(setScrolledTop(true));
    } else if (scrolled === 0 && isScrolled) {
      dispatch(setScrolledTop(false));
    }
  };

  useEffect(() => {
    if (!selectedCouncil) {
      return;
    }

    if (!followers && selectedList !== "following") {
      dispatch(
        getFollowersAttempt({
          data: { councilId: selectedCouncil.id },
          enqueueSnackbar,
        }),
      );
    }
  }, [selectedCouncil, followers, selectedList]);

  useEffect(() => {
    if (!selectedCouncil) {
      return;
    }

    if (updateFollowers && selectedList !== "following") {
      dispatch(
        getFollowersAttempt({
          data: { councilId: selectedCouncil.id },
          enqueueSnackbar,
        }),
      );
    }

    return () => null;
  }, [updateFollowers, selectedCouncil, selectedList]);

  useEffect(() => {
    if (list_id) {
      dispatch(setSelectedList({ id: list_id }));
    }

    return () => null;
  }, [list_id]);

  return (
    <div className="w-100">
      <div
        className={styles.wrapper}
        onScroll={onScrollEventFunc}
        id="rightWindow"
      >
        <MyListsComponent />
      </div>
    </div>
  );
};

export default React.memo(RightWindowComponent);

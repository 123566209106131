import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import ReactTable from "react-table";
import "react-table/react-table.css";
import classnames from "classnames";

import adminFuseHoc from "common/HOC/adminFuseHoc";
import { Loading } from "../../../primitives";
import styles from "./styles.module.scss";
import { getProjectWizardAttempt, getStageTemplateByIdAttempt } from "modules/admin/adminProjects/adminProjects.actions";
import { WIZARD_DESCRIPTIONS, WIZARD_SETUP_TYPES } from "modules/admin/adminProjects/adminProjects.constants";

const AdminProjectWizard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    adminProjectsReducer: {
      stageTemplate,
      projectWizards,
    },
  } = useSelector((state) => state);

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getStageTemplateByIdAttempt({ id, enqueueSnackbar }));
      dispatch(getProjectWizardAttempt({
        stageID: id,
        enqueueSnackbar,
      }))
    }
  }, [id]);

  const onWizardClick = (group) => {
    history.push(`/admin/projects/setup/${id}/${group.id}`);
  };

  const columns = React.useMemo(() => [
    {
      Header: "Set-Up Wizard",
      id: "setup_type",
      position: 1,
      accessor: (row) => <div className="cursor-pointer" onClick={() => onWizardClick(row)}>
        {WIZARD_SETUP_TYPES[row?.setup_type]}
      </div>,
      minWidth: 200,
    },
    {
      Header: "Description",
      id: "description",
      position: 2,
      accessor: (row) => <div className="cursor-pointer" onClick={() => onWizardClick(row)}>
        {WIZARD_DESCRIPTIONS[row?.setup_type]}
      </div>,
      minWidth: 400,
    },
    {
      Header: "Steps",
      id: "projects_wizard_steps",
      accessor: (row) => (
        <div className={styles.alignedCenter}>{row?.projects_wizard_steps.length}</div>
      ),
    },
  ]);

  const onTabClick = (tab) => {
    history.push(`/admin/projects/${tab}/${id}`);
  };

  return (
    <div className={styles.container}>
      <div className="row">
        <div className="col-10" style={{ margin: "0 auto" }}>
          <div className={styles.headerWrp}>
            <h4 className={styles.taskHeaderText}>
              <span
                className={styles.rootText}
                onClick={() => {
                  history.push("/admin");
                }}
              >
                ADMIN
              </span>
              <span className={styles.separationBar}> / </span>
              <span
                className={styles.rootText}
                onClick={() => {
                  history.push("/admin/projects");
                }}
              >
                PROJECTS
              </span>
              <span className={styles.separationBar}> / </span>
              <span className={styles.companyName}>{stageTemplate?.name}</span>
              <span className={styles.separationBar}> / </span>
              <span className={styles.rootText} onClick={() => onTabClick("setup")}>PROJECT SET-UP</span>
            </h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className={classnames(styles.tabsContainer, "col-10")}
          style={{ margin: "0 auto" }}
        >
          <h5 className="mb-3">
            <span
              onClick={() => onTabClick("stages")}
              className={styles.tabsText}
            >
              {" "}
              STAGES{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span
              onClick={() => onTabClick("tasks")}
              className={styles.tabsText}
            >
              {" "}
              TASKS{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span className={styles.tabsText} onClick={() => onTabClick("fields")}>
              {" "}
              FIELDS{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span className={styles.tabsText} onClick={() => onTabClick("groups")}>
              {" "}
              FIELD GROUPS{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span className={styles.selectedVar} onClick={() => onTabClick("groups")}>
              {" "}
              PROJECT SET-UP{" "}
            </span>
          </h5>
        </div>
      </div>
      <div className="row">
        {" "}
        <div className="col-10" style={{ margin: "0 auto" }}>
          <ReactTable
            data={projectWizards.data || []}
            columns={columns}
            className={styles.table}
            loading={projectWizards?.loading ? <Loading /> : null}
            getTheadThProps={() => ({
              onClick: () => {},
            })}
            defaultSortDesc
            showPagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default adminFuseHoc(AdminProjectWizard);

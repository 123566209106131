import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import styles from '../styles.module.scss';
import ReplicationProjectThemes from "modules/themes/replicationProjectThemes";

const FordScoreCardPillarFilter = ({ handleFilterUpdate, reducer, setNewFilters }) => {
  const dispatch = useDispatch();

  const [selectedPillars, setSelectedPillars] = useState([]);

  useEffect(() => {
    setSelectedPillars(reducer.filters?.pillars || []);
  }, [reducer.filters?.pillars]);

  const updateFilters = useCallback((value) => {
    setNewFilters({
      ...reducer.filters,
      pillars: value,
    })

    handleFilterUpdate({pillars: value}, reducer.tableData?.expandedKey)
  }, [reducer]);

  const handlePillarsSelect = (value) => {
    setSelectedPillars(value);
    updateFilters(value);
  };

  return (
    <div className={styles.filter}>
      <ReplicationProjectThemes
        placeholder="Select Pillars"
        handleSelect={handlePillarsSelect}
        themes={selectedPillars}
      />
    </div>
  );
};

export default React.memo(FordScoreCardPillarFilter);

import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import { appSliderSettingSet, highlightItemWithIdAction } from "../app.action";
import styles from "./sliderWindow.module.scss";
import { Icon, lgSize } from "../../common/icon";
import { useOnClickOutside } from "../../common/hooks";

const SliderWindowComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    appReducer: { sliderSetting, modalSetting, highlightItemWithId },
  } = useSelector((state) => state);

  const ref = useRef();

  useOnClickOutside(ref, () => handleCloseClick());

  useEffect(() => {
    sliderSetting.show
      ? document.body.classList.add("slider-open")
      : document.body.classList.remove("slider-open");
  }, [sliderSetting.show]);

  const handleCloseClick = useCallback((buttonCloseClick) => {
    if (modalSetting?.show) {
      return;
    }

    if (sliderSetting.show) {
      const timeout = setTimeout(() => {
        if (highlightItemWithId?.length) {
          dispatch(highlightItemWithIdAction(null));
        }

        if (sliderSetting.taskWindowDetails) {
          const urlParams = new URLSearchParams(window.location.search);
          const currPageQuery = urlParams.get('page');
          const taskIdQuery = urlParams.get('taskId');

          if (taskIdQuery?.length) {
            const tabParam = `?tab=${sliderSetting.taskWindowDetails.activeTab}`;
            const pageParam = currPageQuery ? `&page=${currPageQuery}` : '';

            history.push({
              pathname: `/projects/${sliderSetting.taskWindowDetails.projectId}/task${tabParam}${pageParam}`,
            });
          }
        }

        dispatch(
          appSliderSettingSet({
            ...sliderSetting,
            show: false,
            header: "",
            component: "",
            taskWindowDetails: null
          }),
        );

        clearTimeout(timeout);
      }, buttonCloseClick ? 0 : 300);
    }
  }, [modalSetting, sliderSetting]);

  return (
    <div
      ref={ref}
      style={{
        width: `${sliderSetting.width}px`,
        right: `-${sliderSetting.width}px`,
      }}
      className={classNames(
        styles.sliderWrp,
        sliderSetting.show && styles.show,
      )}
    >
      <div>
        <div className={styles.headerWrp}>
          <div className={styles.headerBody}>{sliderSetting.header}</div>
          <div className={styles.closeWrp}>
            <Icon
              {...lgSize}
              icon="icn-add"
              className={styles.icon}
              onClick={() => handleCloseClick(true)}
            />
          </div>
        </div>
      </div>
      {sliderSetting.component}
    </div>
  );
};

export default React.memo(SliderWindowComponent);

import {
  contactsRequest,
  mailReadRequest,
  usersRequest,
} from "api/microsoft/authConfig";
import { getMsGraphCall, getTokenPopup } from "./graph";

const endpoint = {
  getContacts: "https://graph.microsoft.com/v1.0/me/contacts",
  getEmails: "https://graph.microsoft.com/v1.0/me/messages",
  getOrganizationUsers: "https://graph.microsoft.com/v1.0/users",
  getMyPeople: "https://graph.microsoft.com/v1.0/me/people",
};

export const getContacts = async (instance, accounts, users_integrations) => {
  const request = { ...contactsRequest, account: accounts[0] };

  return getTokenPopup(instance, request, users_integrations)
    .then(async (response) =>
      getMsGraphCall(response?.accessToken, endpoint.getContacts)
    )
    .catch((error) => console.error(error));
};

export const getEmails = async (
  instance,
  accounts,
  domain,
  allEmails = true,
  users_integrations
) => {
  const request = { ...mailReadRequest, account: accounts[0] };

  return getTokenPopup(instance, request, users_integrations)
    .then(async (response) =>
      allEmails
        ? getMsGraphCall(
            response?.accessToken,
            `${endpoint.getEmails}?$filter=sentDateTime ge 2015-01-01 and contains(from/emailAddress/address,` +
              `'${domain}')&orderBy=sentDateTime desc`
          )
        : getMsGraphCall(
            response?.accessToken,
            `${endpoint.getEmails}?$filter=sentDateTime ge 2015-01-01 and contains(from/emailAddress/address,` +
              `'${domain}')&orderBy=sentDateTime desc&$top=50&$count=true`
          )
    )
    .catch((error) => console.error(error));
};

export const searchEmails = async (
  instance,
  accounts,
  searchQuery,
  perSearch = 150,
  users_integrations
) => {
  const request = { ...mailReadRequest, account: accounts[0] };

  if (searchQuery.length > 2) {
    const reqUri = `${endpoint.getEmails}?$search="participants:${searchQuery}"&top=${perSearch}`;
    return getTokenPopup(instance, request, users_integrations)
      .then(async (response) => getMsGraphCall(response?.accessToken, reqUri))
      .catch((error) => console.error(error));
  }

  return getTokenPopup(instance, request, users_integrations)
    .then(async (response) =>
      getMsGraphCall(
        response?.accessToken,
        `${endpoint.getEmails}?$top=${perSearch}`
      )
    )
    .catch((error) => console.error(error));
};

export const searchUsers = async (
  instance,
  accounts,
  searchQuery,
  perPage = 50,
  users_integrations
) => {
  const request = { ...usersRequest, account: accounts[0] };

  if (searchQuery.length > 2) {
    const sanitizedQuery = `"givenName:${searchQuery}" OR "displayName:${searchQuery}" OR "mail:${searchQuery}" OR "jobTitle:${searchQuery}"`;
    const reqUri = decodeURI(
      `${endpoint.getOrganizationUsers}?$search=${sanitizedQuery}&$top=${perPage}&` +
        "$count=true&$select=id,surname,mail,givenName,displayName,jobTitle,department,businessPhones"
    );
    return getTokenPopup(instance, request, users_integrations)
      .then(async (response) =>
        getMsGraphCall(response?.accessToken, decodeURI(reqUri))
      )
      .catch((error) => console.error(error));
  }
  return getTokenPopup(instance, request, users_integrations)
    .then(async (response) =>
      getMsGraphCall(
        response?.accessToken,
        `${endpoint.getOrganizationUsers}?$top=${perPage}&$select=id,surname,mail,givenName,displayName,jobTitle,department,businessPhones`
      )
    )
    .catch((error) => console.error(error));
};

import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCookie } from "common/helper";

import styles from "./settingsHeader.module.scss";
import { SETTINGS_HEADER_MENU } from "./settingsHeader.constant";

const SettingsHeader = () => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const location = useLocation();
  const [settings, setSettings] = useState(SETTINGS_HEADER_MENU);
  const councilRole = getCookie("council_role_");

  useEffect(() => {
    if (session?.isSSOLogin || selectedCouncil?.traction_tag === "kyndryl") {
      const settingsHeader = SETTINGS_HEADER_MENU.filter(
        (elem) => elem.title !== "Password"
      );
      setSettings(settingsHeader);
      return;
    }

    if (session?.council_role === "company") {
      const settingsHeader = SETTINGS_HEADER_MENU.filter(
        (elem) => elem.onlyClaim
      );
      setSettings(settingsHeader);
      return;
    }

    const settingsHeader = SETTINGS_HEADER_MENU.filter((elem) => {
      if (councilRole.includes(["guest", "viewer"])) {
        return !(elem.title === "Integrations");
      }
      return elem;
    });

    setSettings(settingsHeader);
  }, [session, councilRole, selectedCouncil]);

  return session?.password_updated_at ? (
    <div className={styles.settingsHeader}>
      <h5 className={styles.title}>Settings</h5>
      <div className={styles.menuWrapper}>
        <ul className={styles.menu}>
          {settings.map((elem) => (
            <li key={elem.title} className={styles.menuItem}>
              <NavLink
                to={elem.url}
                className={styles.menuItem}
                activeClassName={styles.activeMenuItem}
                exact
              >
                {elem.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ) : (
    <div>
      <div className={styles.firstLoginContainer}>
        <h4 className={styles.firstLoginHeaderTitle}>Getting Started</h4>
        <h4 className={styles.separationBar}>|</h4>
        <h4 className={styles.stepNumber}>
          {location.pathname === "/setup/profile" ? "Step One" : "Step Two"}
        </h4>
      </div>
      <hr />
      <h4 className={styles.firstLoginSubtitle}>
        {location.pathname === "/setup/profile"
          ? "Complete Your Profile"
          : "Create Your Password"}
      </h4>
    </div>
  );
};

export default React.memo(SettingsHeader);

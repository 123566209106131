import {
  OPTIONS_SCORE_GET_SUCCESS,
  PROJECT_STAGES_TEMPLATES_GET_SUCCESS,
} from "modules/projects/newProjectv3/newProjectV3.actions";
import { apiStatus } from "../../../common/httpCall";

const initState = {
  stageTemplates: [],
  optionScores: [],
};

const newProjectV3Reducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PROJECT_STAGES_TEMPLATES_GET_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          stageTemplates: response || [],
        };
      }
      break;
    }
    case OPTIONS_SCORE_GET_SUCCESS:
      return {
        ...state,
        optionScores: payload,
      };
  }
  return state;
};

export default newProjectV3Reducer;

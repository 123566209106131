export const MEMBER_SEARCH_ATTEMPT = "MEMBER_SEARCH_ATTEMPT";
export const memberSearch = (payload) => ({
  type: MEMBER_SEARCH_ATTEMPT,
  payload,
});
export const MEMBER_SEARCH_SUCCESS = "MEMBER_SEARCH_SUCCESS";
export const memberSearchSuccess = (payload) => ({
  type: MEMBER_SEARCH_SUCCESS,
  payload,
});

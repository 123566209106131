import {
  ADD_AREA_SUCCESS,
  ADD_PLANT_SUCCESS,
  DELETE_AREA_SUCCESS,
  DELETE_PLANT_SUCCESS,
  EDIT_AREA_SUCCESS,
  EDIT_PLANT_SUCCESS,
  GET_PLANTS_AND_AREAS_SUCCESS,
} from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";

const initState = {
  plantsAndAreas: [],
  fetching: true,
};

const adminPlantsAndAreasReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DELETE_PLANT_SUCCESS: {
      return {
        ...state,
        plantsAndAreas: state.plantsAndAreas.filter(plant => plant.id !== payload.response.id)
      }
    }

    case DELETE_AREA_SUCCESS:
    case ADD_AREA_SUCCESS:
    case EDIT_AREA_SUCCESS: {
      return {
        ...state,
        plantsAndAreas: state.plantsAndAreas.map(plant => {
          if (plant.id === payload.response.id) {
            return payload.response
          }

          return plant;
        })
      }
    }

    case EDIT_PLANT_SUCCESS: {
      return {
        ...state,
        plantsAndAreas: state.plantsAndAreas.map(p => {
          if (p.id === payload.response.id) {
            return {
              ...p,
              ...payload.response
            }
          }

          return p
        })
      };
    }

    case ADD_PLANT_SUCCESS: {
      return {
        ...state,
        plantsAndAreas: [payload.response || {}, ...state.plantsAndAreas],
      };
    }

    case GET_PLANTS_AND_AREAS_SUCCESS: {
      return {
        ...state,
        plantsAndAreas: payload.response,
        fetching: false,
      };
    }

    default: {
      break;
    }
  }

  return state;
};

export default adminPlantsAndAreasReducer;

export const AIRecommendOptions = [
  {
    id: 'projects',
    name: 'Recommend Projects',
  },
  {
    id: 'ideas',
    name: 'Recommend Ideas',
  },
];

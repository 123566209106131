import React, {
  useCallback, useEffect, useRef, useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  Formik, FieldArray, Field, Form,
} from "formik";
import { useSnackbar } from "notistack";
import NewItemComponent from "common/popups/newItem";
import { useModelPopup } from "common/hooks";
import { BtnType, Button } from "../../../../../../primitives";
import styles from "../../rfiDetails.module.scss";
import { saveDetails } from "../../../../store/rfiActions";
import SlidesComponent from "../../../fields/slides";
import VideoComponent from "../../../fields/video";
import DropDownComponent from "../../../fields/dropDown";
import AgreementComponent from "../../../fields/agreement";
import CheckListComponent from "../../../fields/checkList";
import NumberComponent from "../../../fields/number";
import DateComponent from "../../../fields/date";
import DocumentComponent from "../../../fields/document";
import LinkComponent from "../../../fields/link";
import ImageComponent from "../../../fields/images";

const RFIDetailsForm = ({
  data, selectedCouncil, companyId, isDisabled,
}) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();

  const [formValues, setFormValues] = useState({});
  const [originalValues, setOriginalValues] = useState(null);

  useEffect(() => {
    if (
      !originalValues
      && (data?.company_request_for_information_custom_fields ||
        data?.company_request_for_information_custom_field_templates)
    ) {
      setOriginalValues({
        fields: [
          ...data?.company_request_for_information_custom_field_templates || [],
          ...data?.company_request_for_information_custom_fields || [],
        ]
      });
    }
  }, [originalValues, data]);

  useEffect(() => {
    if (
      data?.company_request_for_information_custom_fields ||
      data?.company_request_for_information_custom_field_templates
    ) {
      setFormValues({
        fields: [
          ...data?.company_request_for_information_custom_field_templates || [],
          ...data?.company_request_for_information_custom_fields || [],
        ]
      });
    }
  }, [data]);

  const handleCancel = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (originalValues?.fields) {
      setFormValues({
        fields: [],
      });

      const timeout = setTimeout(() => {
        setFormValues({
          fields: originalValues.fields,
        });

        clearTimeout(timeout);
      });
    }
  };

  const handleSave = (evt) => {
    if (isDisabled) {
      return;
    }

    const customFields = evt.fields.filter(f => f.company_custom_field_id?.length);
    const templates = evt.fields.filter(f => f.custom_field_template_id?.length);

    const payload = {
      company_request_for_information: {
        company_request_for_information_custom_fields_attributes: customFields,
        company_request_for_information_custom_field_templates_attributes: templates,
      },
      formId: data.id,
      cb: (res) => {
        enqueueSnackbar("Successfully submitted RFI", {
          variant: "success",
        });

        // reset newDocuments
        resetDocumentsAfterSubmit(res);
      },
    };

    dispatch(saveDetails(payload));
  };

  const resetDocumentsAfterSubmit = useCallback((res) => {
    const source = [
      ...res?.company_request_for_information_custom_field_templates || [],
      ...res?.company_request_for_information_custom_fields || [],
    ]
    const fields = source.map(
      (f) => {
        if (f.field_type === "document") {
          return {
            ...f,
            resetNewDocs: true,
          };
        }

        return f;
      },
    );

    setFormValues({
      fields,
    });

    const timeout = setTimeout(() => {
      const fields = source.map(
        (f) => {
          if (f.field_type === "document") {
            return {
              ...f,
              resetNewDocs: false,
            };
          }

          return f;
        },
      );

      setFormValues({
        fields,
      });

      clearTimeout(timeout);
    }, 1000);
  }, []);

  const handleEditClick = (item) => {
    popup.show({
      title: "Edit Item",
      height: String(window.innerHeight),
      width: String(window.innerWidth / 1.5),
      component: (
        <NewItemComponent
          mode="edit"
          data={item}
          handleSubmitDetail={handleUpdateForm}
          companyId={companyId}
          rfiInstance
        />
      ),
    });
  };

  const handleUpdateForm = (data) => {
    const fields = formRef.current.values.fields.map((f) => {
      if (f.id === data.id) {
        if (data.field_type === "agreement") {
          const documents_attributes = data.documents_attributes.map((d) => {
            const { added_by, ...rest } = d;
            return {
              ...rest,
              added_by_id: d.added_by?.id ? d.added_by.id : d.added_by_id,
            };
          });

          return {
            ...f,
            ...data,
            documents: documents_attributes,
            documents_attributes,
          };
        }

        if (data.field_type === "slide") {
          return {
            ...f,
            ...data,
            documents_attributes: data.documents_attributes.map((d) => {
              const { added_by, ...rest } = d;
              return {
                ...rest,
                added_by_id: d.added_by?.id ? d.added_by.id : d.added_by_id,
              };
            }),
          };
        }

        return {
          ...f,
          ...data,
        };
      }

      return f;
    });

    setFormValues({
      fields,
    });
  };

  return (
    <div style={{ opacity: isDisabled ? 0.3 : 1 }}>
      <h4 className="mb-5">Main Capabilities</h4>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={formValues}
        onSubmit={handleSave}
      >
        {({
          values, handleSubmit, setFieldValue, ...formikprops
        }) => (
          <Form onSubmit={handleSubmit}>
            <FieldArray name="fields">
              <div>
                {values?.fields?.length ? (
                  values.fields.map((f, index) => (
                    <div key={index} className="d-flex flex-column mb-4">
                      {f.field_type === "text" && (
                      <>
                        <label
                          className={styles.titleLable}
                          htmlFor={`fields.${index}.field_value`}
                        >
                          {f.field_name || "( '-' )"}
                        </label>
                        {
                          f?.field_placeholder?.length ? (
                            <span className="text-muted small">{f.field_placeholder}</span>
                          ) : null
                        }
                        <Field
                          name={`fields.${index}.field_value`}
                          as="textarea"
                          className={styles.textArea}
                          disabled={isDisabled}
                        />
                      </>
                      )}
                      {f.field_type === "document" && (
                      <DocumentComponent
                        item={f}
                        handleUpdateForm={handleUpdateForm}
                        disabled={isDisabled}
                        companyId={companyId}
                      />
                      )}
                      {f.field_type === "slide" && (
                      <SlidesComponent
                        item={{
                          ...f,
                          option_values: f.field_values,
                        }}
                        handleUpdateForm={handleUpdateForm}
                        handleEditClick={handleEditClick}
                        disabled={isDisabled}
                        companyId={companyId}
                      />
                      )}
                      {f.field_type === "video" && (
                      <VideoComponent
                        item={{
                          ...f,
                          option_values: f.field_values,
                        }}
                        handleEditClick={handleEditClick}
                        handleUpdateForm={handleUpdateForm}
                        disabled={isDisabled}
                        companyId={companyId}
                      />
                      )}
                      {f.field_type === "dropdown" && (
                      <DropDownComponent
                        item={f}
                        handleUpdateForm={handleUpdateForm}
                        disabled={isDisabled}
                      />
                      )}
                      {f.field_type === "link" && (
                      <LinkComponent
                        item={f}
                        handleEditClick={handleEditClick}
                        handleUpdateForm={handleUpdateForm}
                        disabled={isDisabled}
                      />
                      )}
                      {f.field_type === "agreement" && (
                      <AgreementComponent
                        item={{
                          ...f,
                          // documents: f.company_custom_field?.documents || []
                        }}
                        handleEditClick={handleEditClick}
                        handleUpdateForm={handleUpdateForm}
                        disabled={isDisabled}
                      />
                      )}
                      {f.field_type === "checklist" && (
                      <CheckListComponent
                        item={f}
                        handleUpdateForm={handleUpdateForm}
                        disabled={isDisabled}
                      />
                      )}
                      {f.field_type === "number" && (
                      <NumberComponent
                        item={f}
                        handleEditClick={handleEditClick}
                        handleUpdateForm={handleUpdateForm}
                        disabled={isDisabled}
                      />
                      )}
                      {f.field_type === "date" && (
                      <DateComponent
                        item={f}
                        handleEditClick={handleEditClick}
                        handleUpdateForm={handleUpdateForm}
                        disabled={isDisabled}
                      />
                      )}
                      {f.field_type === "image" && (
                      <ImageComponent
                        item={f}
                        handleEditClick={handleEditClick}
                        handleUpdateForm={handleUpdateForm}
                        disabled={isDisabled}
                        companyId={companyId}
                        selectedCouncil={selectedCouncil}
                      />
                      )}
                    </div>
                  ))
                ) : (
                  <div>- no data</div>
                )}
              </div>
            </FieldArray>
            {!isDisabled && values?.fields?.length ? (
              <>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <hr />
                <Button type="submit" btn={BtnType.REGULAR}>
                  {data?.state !== "submitted" ? "Resubmit" : "Submit"}
                </Button>
                <Button
                  btn={BtnType.FRAME_LESS}
                  className="ml-3"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </>
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(RFIDetailsForm);

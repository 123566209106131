export const PAGINATION_DEFAULTS = {
  pageSize: 20,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
};

export const PAGINATION_DEFAULTS_FIR = {
  pageSize: 14,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
};

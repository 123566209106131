export const moreOptionTypes = {
  edit: 0,
  remove: 1,
};

export const moreOptions = [
  {
    val: moreOptionTypes.edit,
    text: "Edit",
  },
  {
    val: moreOptionTypes.remove,
    text: "Delete",
    type: 1,
  },
];

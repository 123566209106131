import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useCompanySelect = () => {
  const {
    companiesReducer: {
      companyMainReducer: {
        selectedCompanies,
      },
    },
  } = useSelector((state) => state);

  const [selectModeIsActive, setSelectModeIsActive] = useState(false);

  useEffect(() => {
    if (selectedCompanies?.length) {
      setSelectModeIsActive(true);
    }

    return () => null;
  }, [selectedCompanies]);

  return {
    selectModeIsActive,
    setSelectModeIsActive,
  };
};

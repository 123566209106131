import React from "react";

import styles from "./productFeatures.module.scss";
import { BtnType, Button } from "modules/primitives";
import useModelPopup from "common/hooks/modelPopup.hook";
import EditProductFeatures from "modules/companies/product/components/overview/productFeatures/editProductFeatures";

const ProductFeatures = ({ product }) => {
  const popup = useModelPopup();

  const addNewVideo = () => {
    popup.show({
      show: true,
      title: "Edit Product Features",
      component: <EditProductFeatures product={product} />,
    });
  };

  return (
    <div className={styles.productDocs}>
      {
        product.features?.length ? (
          <div className="pl-3">
            <ul>
              {
                product.features.map((feature, index) => (
                  <li key={`${feature}_${index}`}>{feature}</li>
                ))
              }
            </ul>
          </div>
        ) : (
          <div className="d-flex align-items-center flex-column">
            <span>No added features yet.</span>
            <Button
              className="mt-3"
              onClick={addNewVideo}
              btn={BtnType.REGULAR}
            >
              ADD FEATURES
            </Button>
          </div>
        )
      }
    </div>
  );
};

export default ProductFeatures;

import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import styles from "./slider.module.scss";
import { Slider } from "../../../../../primitives";

const SliderComponent = (props) => {
  const {
    rating: {
      rating_category_id,
      name,
      weight,
      description,
      max,
      customStyles,
    },
    value = 0,
    onChange = null,
    disabled = false,
  } = props;
  const [sliderValue, setSliderValue] = useState(0);
  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  const handleRatingOnChange = (value) => {
    setSliderValue(value);
    if (onChange) {
      onChange({ id: rating_category_id, value });
    }
  };
  const CustomTooltip = withStyles({
    tooltip: {
      color: "black",
      backgroundColor: "white",
      padding: "15px",
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      letterSpacing: "2px",
      fontSize: "15px",
    },
  })(Tooltip);

  return (
    <div className={styles.ratingWrp}>
      <Slider
        value={sliderValue}
        onChange={handleRatingOnChange}
        max={max}
        customStyles={customStyles}
        disabled={disabled}
      />
      {
        sliderValue > 0 && (
          <div className="d-flex justify-content-end w-100">
            <span
              className="text-primary small cursor-pointer"
              onClick={() => handleRatingOnChange(0)}
            >reset</span>
          </div>
        )
      }
      <div className={styles.ratingTextWrp}>
        <CustomTooltip title={description || ""}>
          <div className={styles.ratingLabel}>
            {name} ({weight}
            %)
          </div>
        </CustomTooltip>
      </div>
    </div>
  );
};

export default React.memo(SliderComponent);

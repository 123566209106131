import React, { useMemo } from "react";

import styles from "./productPricingAndReviews.module.scss";
import { BtnType, Button } from "modules/primitives";
import useModelPopup from "common/hooks/modelPopup.hook";
import EditProductPricingAndReviews
  from "modules/companies/product/components/overview/productPricingAndReviews/editProductPricingAndReviews";
import { extractCurrencyAndValue } from "../../../../../../common/helper";

const ProductPricingAndReviews = ({ product, instance }) => {
  const popup = useModelPopup();

  const addNewPriceOrReviews = () => {
    popup.show({
      show: true,
      width: '70%',
      title: `Add Product ${instance === 'price' ? 'Price' : 'Reviews'}`,
      component: <EditProductPricingAndReviews product={product} instance={instance} />,
    });
  }

  const handleURlNavigate = url => {
    const absoluteUrl =
      url.startsWith("http://") ||
      url.startsWith("https://")
        ? url
        : "http://" + url;

    window.open(absoluteUrl, "_blank");
  };

  const makePriceWithCurrency = useMemo(() => {
    if (!product.price?.length) {
      return '-';
    }
    const priceAndCurrency = extractCurrencyAndValue(product.price);

    if (!priceAndCurrency) {
      return '-';
    }

    return `${priceAndCurrency?.currencySymbol}${priceAndCurrency?.numericValue?.toLocaleString()}`
  }, [product]);

  return (
    <div className={`${styles.pricingAndReviews} p-3`}>
      {
        product.price?.length && instance === 'price' ? (
          <div className="d-flex">
            <span className="mr-3">Pricing:</span>
            <span>{makePriceWithCurrency}</span>
          </div>
        ) : null
      }
      {
        product.reviews.length && instance === 'reviews' ? (
          <div className="mt-3">
            <span className="mr-3">Reviews</span>
            <div className="d-flex flex-column border-bottom">
              <div className="row d-flex border-top border-bottom py-2 ml-0 mr-0 mt-3">
                <div className="col-6 font-weight-bold">Review Site</div>
                <div className="col font-weight-bold">Rating</div>
              </div>
              {
                product.reviews.map(r => (
                  <div key={r.id} className="d-flex row py-1 ml-0 mr-0">
                    <div
                      className="col-6 text-primary cursor-pointer"
                      onClick={() => handleURlNavigate(r.url)}
                    >
                      {r.name || '-'}
                    </div>
                    <div className="col">{r.given_rating || '-'}</div>
                  </div>
                ))
              }
            </div>
          </div>
        ) : null
      }
      {
        !product.price?.length && instance === 'price' ? (
          <div className="d-flex align-items-center flex-column">
            <span>No price yet provided.</span>
            <Button
              className="mt-3"
              onClick={addNewPriceOrReviews}
              btn={BtnType.REGULAR}
            >
              ADD PRICE
            </Button>
          </div>
        ) : null
      }
      {
        !product.reviews?.length && instance === 'reviews' ? (
          <div className="d-flex align-items-center flex-column">
            <span>No reviews yet provided.</span>
            <Button
              className="mt-3"
              onClick={addNewPriceOrReviews}
              btn={BtnType.REGULAR}
            >
              ADD REVIEWS
            </Button>
          </div>
        ) : null
      }
    </div>
  );
};

export default ProductPricingAndReviews;

import React from "react";
import { Formik, Field } from "formik";
import styles from "./projectReports.module.scss";

const ProjectReportStep3 = ({ model, tasksList, setModel }) => {
  const handleOnChange = (evt) => {
    if (evt.target.name === "isAllSelected") {
      handleSelectAll();
      return;
    }

    const { value } = evt.target;
    const valueExists = model.step3.tasks.some((id) => id === value);

    if (valueExists) {
      setModel({
        ...model,
        step3: {
          ...model.step3,
          tasks: model.step3.tasks.filter((id) => id !== value),
          isAllSelected: false,
        },
      });
    } else {
      const tasks = [...model.step3.tasks, value];
      const isAllSelected = tasks.length === tasksList.length;

      setModel({
        ...model,
        step3: {
          ...model.step3,
          tasks,
          isAllSelected,
        },
      });
    }
  };

  const handleSelectAll = () => {
    if (model.step3.isAllSelected) {
      setModel({
        ...model,
        step3: {
          ...model.step3,
          tasks: [],
          isAllSelected: false,
        },
      });
    } else {
      setModel({
        ...model,
        step3: {
          ...model.step3,
          tasks: tasksList.map((t) => t.id),
          isAllSelected: true,
        },
      });
    }
  };

  return (
    <div className={styles.step}>
      <Formik
        enableReinitialize
        initialValues={model.step3}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          resetForm();
        }}
      >
        <form onChange={handleOnChange}>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className={styles.formElement}>
                <span className={styles.label}>Select Tasks</span>
                <div className={`${styles.checkboxesList} mb-2 mt-3`}>
                  <label>
                    <Field type="checkbox" name="isAllSelected" />
                    {model.step3.isAllSelected
                      ? "Deselect All Tasks"
                      : "Select All Tasks"}
                  </label>
                </div>
                <div role="group" className={styles.checkboxesList}>
                  {tasksList.map((t) => (
                    <label key={t.id}>
                      <Field type="checkbox" name="tasks" value={t.id} />
                      {t.name}
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </form>
      </Formik>
    </div>
  );
};

export default React.memo(ProjectReportStep3);

import React, { useMemo } from "react";
import { Label } from "modules/primitives";
import Select from "react-dropdown-select";

const PlantMultiSelect = ({ formikprops, plants, hideAreasSelect }) => {
  const multipleAreas = useMemo(() => {
    const flattenAreas =
      formikprops.values?.selectedPlant1?.reduce(
        (acc, plant) => [...acc, ...plant.areas],
        []
      ) || [];
    return [...new Set(flattenAreas.map((a) => a.area_id))].map((id) =>
      flattenAreas.find((a) => a.area_id === id)
    );
  }, [formikprops?.values]);

  return (
    <>
      <Label>Possible Replications (Plants)</Label>
      <Select
        multi
        closeOnSelect
        values={
          formikprops.values.selectedPlant1?.length
            ? formikprops.values.selectedPlant1
            : []
        }
        onChange={(val) => {
          formikprops.setFieldValue("selectedPlant1", val);
          if (!val?.length) {
            formikprops.setFieldValue("selectedAreas1", []);
          }
        }}
        options={plants}
        labelField="name"
        valueField="id"
        placeholder="Possible Replications (Plants)"
        searchBy="name"
        style={{ border: "1px solid #e4e7eb" }}
      />
      {formikprops.values.selectedPlant1?.length > 0 && (
        <div className="d-flex justify-content-end">
          <span
            className="text-muted small cursor-pointer"
            onClick={() => formikprops.setFieldValue("selectedPlant1", [])}
          >
            clear
          </span>
        </div>
      )}
      {multipleAreas?.length > 0 && !hideAreasSelect ? (
        <div className="mt-2">
          <Label>Possible Replications (Areas)</Label>
          <Select
            multi={false}
            closeOnSelect
            values={formikprops.values.selectedAreas1 || []}
            onChange={(val) => {
              formikprops.setFieldValue("selectedAreas1", val);
            }}
            options={multipleAreas}
            labelField="area_name"
            valueField="area_id"
            placeholder="Possible Replications (Areas)"
            searchBy="area_name"
            style={{ border: "1px solid #e4e7eb" }}
          />
          {formikprops.values.selectedAreas1?.length ? (
            <div className="d-flex justify-content-end">
              <span
                className="text-muted small cursor-pointer"
                onClick={() => formikprops.setFieldValue("selectedAreas1", [])}
              >
                clear
              </span>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default PlantMultiSelect;

import { OptionType } from "../primitives";

export const EventTypes = {
  UPCOMING: 0,
  PAST: 1,
};

export const HeaderOptions = [
  {
    value: EventTypes.UPCOMING,
    text: "Upcoming events",
    path: "upcoming",
  },
  {
    value: EventTypes.PAST,
    text: "Past events",
    path: "past",
  },
];

export const pageSettings = (pageSize = 1) => ({
  pageSize,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
});

export const externalContactOptions = [
  { val: "edit", text: "Edit" },
  { val: "delete", text: "Delete", type: OptionType.DANGER },
];

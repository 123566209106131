import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import classNames from "classnames";
import styles from "./areaOfInterest.module.scss";
import { queryStringUrlReplacement } from "../../../../common/helper";

const AreaOfInterestComponent = (props) => {
  const { member } = props;
  const history = useHistory();
  const location = useLocation();

  const handleTechnologyClick = useCallback(
    (e, technologyId) => {
      const search = queryStringUrlReplacement(
        history.location.search,
        "topic",
        technologyId,
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          topic: technologyId,
        },
      });
    },
    [location, history],
  );

  const handleIndustryClick = useCallback(
    (e, industryId) => {
      const search = queryStringUrlReplacement(
        history.location.search,
        "industry",
        industryId,
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          industry: industryId,
        },
      });
    },
    [location, history],
  );

  return (
    <div className={styles.contentWrp}>
      <div className={styles.headerWrp}>
        <h4 className={styles.headerText}>Areas of Interest</h4>
      </div>
      <div className={styles.container}>
        <div className={styles.categoryWrp}>
          <div className={styles.categoryName}>
            <h5 className="medium">Technologies</h5>
          </div>
          <div className={styles.categoryData}>
            {member && member.resource_topics && member.resource_topics[0] ? (
              member.resource_topics.map((item) => (
                <div
                  className={classNames("caption small", styles.itemName)}
                  onClick={(e) => handleTechnologyClick(e, item.topic.id)}
                >
                  {item.topic.name}
                </div>
              ))
            ) : (
              <div className={styles.noAreaOfInterest}>
                This user didn't add any technologies to their area of interest.
              </div>
            )}
          </div>
        </div>
        <div className={styles.categoryWrp}>
          <div className={styles.categoryName}>
            <h5 className="medium">Industries</h5>
          </div>
          <div className={styles.categoryData}>
            {member
            && member.resource_industries
            && member.resource_industries[0] ? (
                member.resource_industries.map((item) => (
                  <div
                    className={classNames("caption small", styles.itemName)}
                    onClick={(e) => handleIndustryClick(e, item.industry.id)}
                  >
                    {item.industry.name}
                  </div>
                ))
              ) : (
                <div className={styles.noAreaOfInterest}>
                  This user didn't add any industries to their area of interest.
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaOfInterestComponent;

import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { Container, Draggable } from "react-smooth-dnd";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";

import { checkViewerRole } from "common/checkers/viewerChecker";
import {
  projectCompanySortedListSet,
  projectCompanyListGet,
  projectOrganizationsGet,
  projectPatientOrgNewMultiple,
  projectCompanyNewMultiple,
} from "./companies.action";
import styles from "./companies.module.scss";
import { Button, BtnType, Pagination } from "../../../primitives";
import CompanyRowComponent from "./companyRow";
import { useSorting, useModelPopup } from "../../../../common/hooks";
import { Icon, smSize } from "../../../../common/icon";
import {
  moveInsideArray,
  queryStringUrlReplacement,
} from "../../../../common/helper";
import { companiesPageSettings } from "./companies.constants";
// import CompareCompaniesComponent from "./compair";
import { projectStageCompaniesListReset } from "../stages/stages.action";
import MixedAddCompanyOrProducts from "../../../../common/components/mixedSearch/mixedAddCompanyOrProducts";

const CompaniesComponent = () => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const [sortingColumn, setSortingColumn] = useState(null);
  const [dragDrop, setDragDrop] = useState(false);
  // const [compair, serCompair] = useState(false);
  const [activeCompanies, setActiveCompanies] = useState([]);
  const [sortedArray, setSortedArray] = useState([]);
  const [removedCompanies, setRemovedCompanies] = useState([]);
  const [sortedRemovedArray, setSortedRemovedArray] = useState([]);
  const [showRemoved, setShowRemoved] = useState(false);
  const [patientOrg, setPatientOrg] = useState(false);
  const [textOrg, setTextOrg] = useState("Company");
  const [pageSetting, setPageSetting] = useState(companiesPageSettings);
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const {
    authReducer: { session },
    projectsReducer: {
      projectReducer: { selectedProject },
      companyReducer: {
        overviewCompanies,
        companyList = [],
        companiesTotal,
        asc,
        updateTasks,
        patientOrganizations,
        companyProjectsLoading,
        progressBarLoader,
        modifiedCompanyId,
      },
      stagesReducer: { stagesList },
      taskReducer: { shouldUpdate },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const [total, setTotal] = useState(0);
  const sorting = useSorting([], asc, projectCompanySortedListSet);
  const sortingRemoved = useSorting([], asc, projectCompanySortedListSet);
  const [isKyndryl, setIsKyndryl] = useState(true);

  const isFordSupplyChainAutomation = useMemo(() => {
    return selectedCouncil?.name === 'Ford' && selectedProject?.stage_template?.name === 'Supply Chain Automation';
  }, [selectedCouncil, selectedProject]);

  useEffect(() => {
    if (selectedCouncil && selectedProject) {
      const isSpecificTemplate =
        selectedProject?.stage_template?.name === "Kyndryl Innovation" ||
        selectedProject?.stage_template?.name === "Kyndryl Alliance";
      setIsKyndryl(selectedCouncil.name === "Kyndryl" && isSpecificTemplate);
    }

    return () => null;
  }, [selectedCouncil, selectedProject]);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (
      location?.state?.page &&
      pageSetting?.current !== location?.state?.page
    ) {
      setPageSetting({
        ...pageSetting,
        current: location.state.page,
      });
    }
  }, [location?.state?.page, pageSetting]);

  useEffect(() => {
    if (selectedProject && selectedProject.total_companies) {
      const activeCompanies = overviewCompanies.map(
        (c) => c.state !== "removed"
      );

      if (activeCompanies.length > selectedProject.total_companies) {
        setTotal(activeCompanies.length);
        return;
      }

      setTotal(selectedProject.total_companies);
    }
  }, [companiesTotal, overviewCompanies]);

  useEffect(() => {
    dispatch(projectStageCompaniesListReset());
  }, []);

  useEffect(() => {
    if (
      selectedProject &&
      selectedProject.stage_template &&
      selectedProject.stage_template.patient_organization
    ) {
      setPatientOrg(true);
      setTextOrg("Organization");
      setActiveCompanies(
        patientOrganizations.filter((item) => item.state !== "removed")
      );
      setRemovedCompanies(
        patientOrganizations.filter((item) => item.state === "removed")
      );
    }
  }, [selectedProject, patientOrganizations]);

  useEffect(() => {
    if (selectedProject && !patientOrg) {
      dispatch(
        projectCompanyListGet({
          projectId: selectedProject.id,
          items: pageSetting.pageSize,
          page: pageSetting.current,
          enqueueSnackbar,
        })
      );
    } else if (selectedProject && patientOrg) {
      dispatch(
        projectOrganizationsGet({
          projectId: selectedProject.id,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedProject, patientOrg, pageSetting.current, shouldUpdate]);

  useEffect(() => {
    if (selectedProject && updateTasks && !patientOrg) {
      dispatch(
        projectCompanyListGet({
          projectId: selectedProject.id,
          page: pageSetting.current,
          enqueueSnackbar,
        })
      );
    } else if (selectedProject && updateTasks && patientOrg) {
      dispatch(
        projectOrganizationsGet({
          projectId: selectedProject.id,
          page: pageSetting.current,
          enqueueSnackbar,
        })
      );
    }
  }, [updateTasks, pageSetting.current]);

  useEffect(() => {
    if (selectedProject && patientOrg) {
      setActiveCompanies(
        patientOrganizations.filter((item) => item.state !== "removed")
      );
      setRemovedCompanies(
        patientOrganizations.filter((item) => item.state === "removed")
      );
    } else if (!patientOrg && companyList) {
      setActiveCompanies(
        companyList.filter((item) => item.state !== "removed")
      );
      setRemovedCompanies(
        companyList.filter((item) => item.state === "removed")
      );
    }
  }, [
    selectedProject,
    patientOrg,
    JSON.stringify(patientOrganizations),
    JSON.stringify(companyList),
  ]);

  const handleCompareCompaniesClick = () => {
    if (selectedProject) {
      history.push(`/projects/${selectedProject.id}/compare`);
    }
  };

  const handleAddCallback = (selected) => {
    const stage = stagesList[0];

    if (selected.length > 0) {
      if (patientOrg) {
        const multiPatientOrgPayload = {
          project_patient_organizations: selected.map((s) => ({
            project_id: selectedProject?.id,
            gsk_patient_organization_id: s.id,
            added_by_id: session.id,
            stage_id: stage.id,
          })),
          project_id: selectedProject?.id,
          council_id: selectedCouncil.id,
          patientOrg: true,
          stage_id: stage.id,
          enqueueSnackbar,
        };

        setTotal(selectedProject.total_companies + selected.length || 0);
        dispatch(projectPatientOrgNewMultiple(multiPatientOrgPayload));
      } else {
        const multiCompaniesPayload = {
          project_companies: selected.map((s) => {
            if (s.is_product) {
              return {
                project_id: selectedProject.id,
                council_product_id: s.id,
                added_by_id: session.id,
                stage_id: stage.id,
                is_product: true,
              };
            }
            return {
              project_id: selectedProject.id,
              company_id: s.id,
              added_by_id: session.id,
              stage_id: stage.id,
            };
          }),
          project_id: selectedProject.id,
          council_id: selectedCouncil.id,
          patientOrg: false,
          stage_id: stage.id,
          enqueueSnackbar,
        };

        setTotal(selectedProject.total_companies + selected.length || 0);
        dispatch(projectCompanyNewMultiple(multiCompaniesPayload));
      }

      popup.hide();
    }
  };

  const handleAddCompanyClick = () => {
    let title;

    if (isFordSupplyChainAutomation) {
      title = 'Add Partners to Project'
    } else {
      title = `Add ${patientOrg && patientOrg ? "an Organization" : "a Company"} to Project`;
    }

    popup.show({
      title,
      component: (
        <MixedAddCompanyOrProducts
          patientOrg={patientOrg}
          handleSave={handleAddCallback}
          placeholder="Type to search or select from below"
          label={patientOrg ? "Organizations" : "Companies or Products"}
          existingItems={sortedArray.map((obj) => ({ ...obj, ...obj.company }))}
          // hideProducts
        />
      ),
    });
  };

  useEffect(() => {
    generateCompanyListForSort();
  }, [activeCompanies, removedCompanies]);

  useEffect(() => {
    sorting.setSortingArray(sortedArray);
    sortingRemoved.setSortingArray(sortedRemovedArray);
  }, [asc, sortingColumn]);

  useEffect(() => {
    if (dragDrop) setDragDrop(false);
  }, [dragDrop, setDragDrop]);

  const generateCompanyListForSort = () => {
    const list = activeCompanies.map((item) => ({
      ...item,
      name:
        (item.is_product ? item.product.name : item.company?.name) ||
        item.gsk_patient_organization.name,
    }));

    setSortedArray(list);

    const removedList = removedCompanies.map((item) => ({
      ...item,
      name:
        (item.is_product ? item.product.name : item.company?.name) ||
        item.gsk_patient_organization.name,
    }));

    setSortedRemovedArray(removedList);
  };

  const handleRatingChange = async (companyId, score) => {
    setSortedArray((prev) =>
      prev.map((sortItem) =>
        sortItem.id === companyId ? { ...sortItem, rating: score } : sortItem
      )
    );
  };

  const handleSorting = (prop, type) => {
    setSortingColumn(prop);
    sorting.sortChange(prop, type);
    sortingRemoved.sortChange(prop, type);
  };

  const drowSortingIcon = (col) => {
    if (sortingColumn === col) {
      return (
        <div className={classNames(styles.sortIcon, asc && styles.sortAsc)}>
          <Icon {...smSize} icon="icn-export-button" />
        </div>
      );
    }
  };

  const handleDragDrop = (e) => {
    const { removedIndex, addedIndex } = e;
    const list = moveInsideArray(sortedArray, removedIndex, addedIndex);
    setSortedArray(list);
    setDragDrop(true);
  };
  const handlePagingChange = (page) => {
    if (pageSetting.current === page) return;

    const search = queryStringUrlReplacement(
      history.location.search,
      "page",
      page
    );

    history.push({
      pathname: `/projects/${selectedProject.id}/companies`,
      search,
      state: {
        ...history.location?.state,
        page,
      },
    });
  };

  const getSkeleton = () => (
    <div className={styles.skeletonContainer}>
      <div>
        {["", "", "", ""].map((el, idx) => (
          <div key={idx} className={styles.skeletonListElement}>
            <Skeleton
              animation="wave"
              variant="circle"
              width={64}
              height={60}
            />
            <div className={styles.skeletonCompanyInfo}>
              <Skeleton animation="wave" height={20} />
              <Skeleton animation="wave" height={20} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      {
        <div className={styles.companiesWrp}>
          <div className={styles.HeaderWrp}>
            <h4 className={styles.taskHeaderText}>
              Project {isFordSupplyChainAutomation ? 'Partners' : patientOrg ? `${textOrg}s` : "Companies"} (
              {total || 0})
            </h4>
            <div className={styles.headerBtnWrp}>
              <Button
                className={styles.newTask}
                btn={BtnType.FRAME_LESS}
                onClick={handleCompareCompaniesClick}
              >
                Compare {isFordSupplyChainAutomation ? 'Partners' : patientOrg ? `${textOrg}s` : "Companies"}
              </Button>
              {!blockFunctionality ? (
                <Button
                  className={styles.newTask}
                  btn={BtnType.REGULAR}
                  icon="icn-add"
                  onClick={handleAddCompanyClick}
                >
                  {isFordSupplyChainAutomation ? 'Add a Partner' : patientOrg ? `Add ${textOrg}` : "Add a Company"}
                </Button>
              ) : (
                <Tooltip
                  title="You have View-Only access. To add to this Project, please ask your Admin to upgrade your account to Standard access and invite you to this Project."
                  placement="top"
                >
                  <div>
                    <Button
                      className={styles.newTask}
                      btn={BtnType.DISABLED}
                      icon="icn-add"
                    >
                      {isFordSupplyChainAutomation ? 'Add a Partner' : patientOrg ? `Add ${textOrg}` : "Add a Company"}
                    </Button>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          {companyProjectsLoading ? (
            getSkeleton()
          ) : (
            <div className={styles.container}>
              <div className={styles.taskRowHeader}>
                <div
                  className={classNames(styles.taskcol, styles.colCompany)}
                  onClick={() => handleSorting("name", "string")}
                >
                  {isFordSupplyChainAutomation ? 'Partner' : patientOrg ? `${textOrg}` : "Company"}
                  {drowSortingIcon("name")}
                </div>
                <div
                  className={classNames(styles.taskcol, styles.colStage)}
                  onClick={() => handleSorting("stageOrder", "number")}
                >
                  Stage
                  {drowSortingIcon("stageOrder")}
                </div>
                {!isKyndryl && (
                  <div
                    className={classNames(styles.taskcol, styles.colRating)}
                    onClick={() =>
                      handleSorting(
                        patientOrg ? "normalized_gsk_score" : "score",
                        "number"
                      )
                    }
                  >
                    {patientOrg ? "MAPPING" : "Project Score"}
                    {drowSortingIcon(
                      patientOrg ? "normalized_gsk_score" : "rating"
                    )}
                  </div>
                )}
                <div
                  className={classNames(styles.taskcol, styles.colCompletion)}
                >
                  Task Progress
                </div>
                <div
                  className={classNames(styles.taskcol, styles.colStatus)}
                  onClick={() => handleSorting("state", "string")}
                >
                  Condition
                  {drowSortingIcon("state")}
                </div>
                <div
                  className={classNames(styles.taskcol, styles.colSeeTask)}
                />
                <div className={classNames(styles.taskcol, styles.colDocs)} />
                <div className={classNames(styles.taskcol, styles.colMore)} />
              </div>
              <div className={styles.databody}>
                {!dragDrop && (
                  <Container
                    dropPlaceholder={{ className: styles.dropPlaceholder }}
                    onDrop={(e) => {
                      handleDragDrop(e);
                    }}
                    onDropReady={(e) => {
                      // handleDragReady(e, stage.id);
                    }}
                    groupName="col"
                  >
                    {sortedArray.map((item, index) => (
                      <Draggable
                        key={`${item.id}_${index}`}
                        className={styles.drgContainer}
                      >
                        <CompanyRowComponent
                          key={item.id}
                          item={item}
                          patientOrg={patientOrg}
                          modifiedCompanyId={modifiedCompanyId}
                          loading={progressBarLoader}
                          normalized_gsk_score={
                            item.normalized_gsk_score &&
                            item.normalized_gsk_score
                          }
                          onRatingChange={handleRatingChange}
                          isKyndryl={isKyndryl}
                        />
                      </Draggable>
                    ))}
                  </Container>
                )}

                {removedCompanies.length > 0 && (
                  <div className={styles.showRemoveWrp}>
                    <Button
                      className={styles.newTask}
                      btn={BtnType.FRAME_LESS}
                      onClick={() => setShowRemoved((prev) => !prev)}
                    >
                      {showRemoved
                        ? `hide removed ${
                            patientOrg && patientOrg
                              ? `${textOrg}s`
                              : "companies"
                          }`
                        : `Show removed ${
                            patientOrg && patientOrg
                              ? `${textOrg}s`
                              : "companies"
                          }`}
                    </Button>
                  </div>
                )}

                {!dragDrop && showRemoved && (
                  <Container
                    dropPlaceholder={{ className: styles.dropPlaceholder }}
                    onDrop={(e) => {
                      handleDragDrop(e);
                    }}
                    onDropReady={(e) => {
                      // handleDragReady(e, stage.id);
                    }}
                    groupName="col"
                  >
                    {sortedRemovedArray.map((item, index) => (
                      <Draggable
                        key={`${item.id}_${index}`}
                        className={styles.drgContainer}
                      >
                        <CompanyRowComponent
                          key={item.id}
                          item={item}
                          modifiedCompanyId={modifiedCompanyId}
                          loading={progressBarLoader}
                          onRatingChange={handleRatingChange}
                          patientOrg={patientOrg}
                          isKyndryl={isKyndryl}
                        />
                      </Draggable>
                    ))}
                  </Container>
                )}
              </div>
            </div>
          )}
          <div
            className={
              companyProjectsLoading ||
              (total < 20 && pageSetting?.current === 1)
                ? styles.none
                : styles.paginationCon
            }
          >
            <Pagination
              total={total}
              pageSize={pageSetting.pageSize}
              current={pageSetting.current}
              onChange={(e) => handlePagingChange(e)}
            />
          </div>
        </div>
      }
    </>
  );
};

export default React.memo(CompaniesComponent);

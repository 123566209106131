import React, { useRef } from "react";

import styles from '../../tractionAI.module.scss';
import ScoutCompanies from "modules/tractionAI/screens/scout/scoutCompanies";
import Tooltip from "@mui/material/Tooltip";
import { BtnType, Button } from "modules/primitives";
import ScoutSlidesModal from "modules/tractionAI/screens/scout/scoutSlidesModal";
import { makeWebSite } from "../../../../common/helper";
import ItemErrorHandler from "modules/tractionAI/components/itemErrorHandler";

const ScoutItemResponse = ({ item, index, enqueueSnackbar, loadMore, handleTryAgain }) => {
  const ref = useRef(null);
  const copyListRef = useRef(null);

  const handleLoadMore = () => {
    loadMore(item, index);
  }

  const handleCopy = () => {
    if (!copyListRef?.current) return;

    const textElement = copyListRef.current;

    const htmlToCopy = Array.from(textElement.querySelectorAll('.copy-item'))
      .map((item) => {
        const name = item.querySelector('.name')?.textContent.trim() || '';
        const link = item.querySelector('.link a')?.href || '';
        const description = item.querySelector('.description')?.textContent.trim() || '';

        return `
      <div>
        <p><strong>${name}</strong></p>
        <a href="${link}" target="_blank">${link}</a>
        <p>${description}</p>
      </div>
    `;
      })
      .join('<br>');

    const plainTextToCopy = Array.from(textElement.querySelectorAll('.copy-item'))
      .map((item) => {
        const name = item.querySelector('.name')?.textContent.trim() || '';
        const link = item.querySelector('.link a')?.href || '';
        const description = item.querySelector('.description')?.textContent.trim() || '';

        return `${name}\n${link}\n${description}`;
      })
      .join('\n\n');

    if (typeof window.ClipboardItem !== 'undefined') {
      navigator.clipboard
        .write([
          new window.ClipboardItem({
            'text/html': new Blob([htmlToCopy], { type: 'text/html' }),
            'text/plain': new Blob([plainTextToCopy], { type: 'text/plain' }),
          }),
        ])
        .then(() => {
          enqueueSnackbar('Successfully copied!', {
            variant: 'success',
          });
        })
        .catch((err) => {
          console.error('Failed to copy:', err);
          enqueueSnackbar('Failed to copy!', {
            variant: 'error',
          });
        });
    } else {
      const tempTextarea = document.createElement('textarea');
      tempTextarea.value = plainTextToCopy;
      tempTextarea.style.position = 'absolute';
      tempTextarea.style.left = '-9999px';
      document.body.appendChild(tempTextarea);
      tempTextarea.select();
      try {
        document.execCommand('copy');
        enqueueSnackbar('Successfully copied (plain text fallback)!', {
          variant: 'info',
        });
      } catch (err) {
        console.error('Fallback copy failed:', err);
        enqueueSnackbar('Failed to copy!', {
          variant: 'error',
        });
      } finally {
        document.body.removeChild(tempTextarea);
      }
    }
  };

  return (
    <div className={`d-flex flex-column justify-content-end pr-2 ${index !== 0 ? 'mt-5' : ''}`}>
      <div className="d-flex aling-items-start justify-content-start mb-2">
        <div className={styles.searchByQuery}>
          Recommended Companies {item.isCompanySearch ? 'similar to:' : 'based on the problem:'}
          <b>{item.searchByQuery}</b>
        </div>
      </div>
      {/*hidden list approach for copy to clipboard*/}
      <div style={{ display: 'none' }} ref={copyListRef}>
        {
          item.data.map((company, index) => (
            <div className="d-flex flex-column copy-item" key={`H_${company.company_name}_${index}`}>
              <span className="name">{company.company_name}</span>
              {
                company.company_url && (
                  <div className="link">
                    <a href={company.company_url}>{makeWebSite(company.company_url)}</a>
                  </div>
                )
              }
              {
                company.complete_justification && (
                  <div>
                    <span>Why Recommended</span>
                    <p className="description">{company.complete_justification}</p>
                  </div>
                )
              }
            </div>
          ))
        }
      </div>
      <div ref={ref} id={`scout_${index}`}>
        {
          item.data?.length > 0 ? (
            <ScoutCompanies item={item} index={index} />
          ) : item.fetched && !item.hasError && (
            <div className="d-flex align-items-center justify-content-center mt-3">
              No recommendations available for your search.
            </div>
          )
        }
      </div>
      <ItemErrorHandler item={item} />
      {
        (item.fetched && item.data?.length > 0 && !item.hasError) ? (
          <div className="d-flex align-items-center justify-content-center mt-3">
            <Button btn={BtnType.FRAME_LESS} onClick={handleLoadMore}>Load more</Button>
          </div>
        ) : null
      }
      {(item.fetched || item.doNotAnimate) && !item.hasError && item.data?.length > 0 ? (
        <div className="d-flex mt-2 align-items-center">
          <Tooltip
            title="Copy"
            placement="top"
          >
            <div className="cursor-pointer" onClick={handleCopy}>
              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                  stroke="#1C274C" strokeWidth="1.5" />
                <path
                  d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                  stroke="#1C274C" strokeWidth="1.5" />
              </svg>
            </div>
          </Tooltip>
          <ScoutSlidesModal item={item} index={index} />
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(ScoutItemResponse);

import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { METRICS_NAMES_ENUM } from "application/app.constants";
import NewProjectV3 from "modules/projects/newProjectv3";

import { themesProjectsGetAttempt } from "./projectsTab.action";
import { useModelPopup } from "../../../../common/hooks";
import { mdSize } from "../../../../common/icon";
import { httpGet } from "../../../../common/httpCall";

import { Loading, Pagination, MoreOptions } from "../../../primitives";
import { useWindowSize } from "../../../../common/hooks/useWindowSize";

import ProjectRow from "../../themesCommon/themeProjects/projectRow.component";

import styles from "./styles.module.scss";
import AddRelatedProjectComponent from "../themesOverviewTab/relatedProjectsBlock/addRelatedProjectModal";
import createProjOptions from "../themesOverviewTab/relatedProjectsBlock/relatedProjects.constant";

const ProjectsTab = ({ isFord }) => {
  const {
    authReducer: { session },
    themesReducer: {
      themesMainReducer: { currentThemeData },
      themesProjectsReducer: { projects, loadingProj, projectListMeta },
      themesOverviewReducer: { companyList, loadingCompany },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [pageSetting, setPageSetting] = useState({
    pageSize: 20,
    current: 1,
    hideOnSinglePage: true,
    showTitle: false,
    total: projects?.length || 0,
  });
  const [projList, setProjList] = useState([]);
  const popup = useModelPopup();
  const [loading, setLoading] = useState(false);
  const [adminColumnSettings, setAdminColumnSettings] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  const [shouldOpenAddNewProjectModal, setShouldOpenAddNewProjectModal] =
    useState(false);

  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const handleGetTableRows = async () => {
    setLoading(true);
    await httpGet({
      call: `theme_project_options?view_type=list`,
    })
      .pipe()
      .subscribe((res) => {
        setLoading(false);
        setAdminColumnSettings(res.response);
        const columns = [];
        for (let index = 0; index < res.response.length; index++) {
          if (res.response[index].display) {
            columns.push(res.response[index]);
          }
          if (columns.length === 7) {
            break;
          }
        }
        const sortedColumns = columns.sort((a, b) => a.position - b.position);
        setTableColumns(sortedColumns);
        return res;
      });
  };
  useEffect(() => {
    try {
      handleGetTableRows();
    } catch (err) {
      console.log("error on get stages", err);
    }
  }, []);
  const handleAddProjectClick = () => {
    popup.show({
      title: "Link Theme to Related Project",
      component: (
        <AddRelatedProjectComponent
          itemList={projects}
          currentThemeId={currentThemeData.id}
          getAll
        />
      ),
    });
  };

  const handleAddClick = (val) => {
    if (val === "relatedProject") {
      handleAddProjectClick();
    }
    if (val === "newProject") {
      setShouldOpenAddNewProjectModal(true);
    }
  };
  useEffect(() => {
    setPageSetting((prev) => ({
      ...prev,
      total: projectListMeta.total || 0,
    }));
  }, [projectListMeta]);

  const handlePagingChange = (page) => {
    setPageSetting((prev) => ({
      ...prev,
      current: page,
    }));
  };
  useEffect(() => {
    if (currentThemeData) {
      dispatch(
        themesProjectsGetAttempt({
          themeId: currentThemeData.id,
          page: 1,
          pageSize: pageSetting.pageSize,
        })
      );
    }
  }, [currentThemeData]);

  useEffect(() => {
    if (pageSetting?.current >= 1) {
      dispatch(
        themesProjectsGetAttempt({
          themeId: currentThemeData.id,
          page: pageSetting?.current,
          pageSize: pageSetting.pageSize,
        })
      );
    }
  }, [pageSetting?.current]);

  useEffect(() => {
    if (projects.length !== undefined) {
      setProjList(projects);
    }
  }, [projects, selectedCouncil]);

  const isAdmin = useMemo(
    () =>
      session?.council_role === "ttp_administrator" ||
      session?.council_role === "council_administrator",
    [session]
  );

  if (loadingProj) {
    return <Loading />;
  }
  const getColumnName = (column) => {
    if (column.column_type === "project_field") {
      return column.project_field_template.field_name;
    }
    if (column.column_type === "project_metric") {
      return METRICS_NAMES_ENUM[column.project_metric_attribute];
    }
    return column.column_type
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <section className={styles.mainContainer}>
      <div className={styles.contentWrp}>
        <div className={styles.headerWrp}>
          {" "}
          {!loadingCompany && (
            <div className={styles.panelIcons}>
              <div className={styles.addNew}>
                <MoreOptions
                  options={
                    selectedCouncil?.name === "Firmenich" ||
                    selectedCouncil?.name === "Ford"
                      ? [createProjOptions[0]]
                      : createProjOptions
                  }
                  className={styles.moreWrp}
                  onClick={handleAddClick}
                  {...mdSize}
                  iconName="icn-add"
                />
              </div>
              <NewProjectV3
                isAdmin={isAdmin}
                wizardType="theme"
                additionalData={{
                  ...currentThemeData,
                  theme_companies: companyList,
                }}
                btnName="New Project"
                shouldOpen={shouldOpenAddNewProjectModal}
                handleDismiss={() => setShouldOpenAddNewProjectModal(false)}
                hideBtn
              />
            </div>
          )}
        </div>

        <div className={styles.container}>
          <div className={styles.grid}>
            {projList?.length && !isMobile ? (
              <div className={styles.gridHeader}>
                <div className={styles.project}>Project</div>
                {tableColumns.map((column, index) => {
                  if (column.column_type === "project_name") {
                    return null;
                  }
                  return (
                    <div
                      className={styles.task}
                      key={`${column.column_type + column.position}_${
                        column?.project_metric?.metric_attribute ||
                        column?.project_field_template_id
                      }`}
                    >
                      {getColumnName(column) || "-"}
                    </div>
                  );
                })}
                {/* {!isFord && getColumnValue("project_companies") ? (
                  <div className={styles.task}>Companies</div>
                ) : null}
                <>
                  {isFord ? <div className={styles.task}>Plant </div> : null}

                  {!isFord && getColumnValue("project_owner") ? (
                    <div className={styles.task}>Owner </div>
                  ) : null}
                </>

                <>
                  {isFord ? <div className={styles.task}>Rating</div> : null}
                  {!isFord && getColumnValue("project_access") ? (
                    <div className={styles.task}>Access</div>
                  ) : null}
                </>

                {selectedCouncil.traction_tag !== "firmenich" ? (
                  <>
                    {!isFord && getColumnValue("project_stage") ? (
                      <div className={styles.task}>Project Stage</div>
                    ) : null}
                    {isFord ? (
                      <div className={styles.task}>Replications</div>
                    ) : null}
                  </>
                ) : null}

                <>
                  {!isFord && getColumnValue("project_status") ? (
                    <div className={styles.task}>Status</div>
                  ) : null}
                  {isFord ? <div className={styles.task}>Savings</div> : null}
                </>

                {isFord ? <div className={styles.task}>Investment </div> : null}
                {isFord ? <div className={styles.task}>TARR </div> : null} */}
              </div>
            ) : null}
            <div className={styles.gridBody}>
              {projList?.length ? (
                projList.map((proj, i) => (
                  <ProjectRow
                    project={proj}
                    projectName={proj?.project_name?.column_value}
                    isFord={isFord}
                    selectedCouncilTag={selectedCouncil.traction_tag}
                    adminColumnSettings={adminColumnSettings}
                    key={proj.project_id}
                    tableColumns={tableColumns}
                  />
                ))
              ) : (
                <div className={styles.noProjects}>
                  <p>
                    {"There are no projects"}
                    {". "}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={styles.paginationCon}>
            <Pagination {...pageSetting} onChange={handlePagingChange} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectsTab;

import React, {
  useEffect, useRef, memo, useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";
import classNames from "classnames";
import { withSnackbar, useSnackbar } from "notistack";

import {
  RADIO_OPTIONS,
  NOTIFICATIONS_ENUM,
  NOTIFICATIONS_MODEL_INIT,
} from "./notificationsSettings.constants";
import styles from "./notificationsSettings.module.scss";
import {
  getNotificationsAttempt,
  saveNotificationsAttempt,
} from "../settings.action";
import { httpPut } from "../../../common/httpCall";

const NotificationsSettings = () => {
  const [model, setModel] = useState(NOTIFICATIONS_MODEL_INIT);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    authReducer: { loginStatus },
    settingsReducer: { notifications = [] },
  } = useSelector((state) => state);

  useEffect(() => {
    if (loginStatus === 1) {
      dispatch(getNotificationsAttempt({ enqueueSnackbar }));
    }
  }, [loginStatus]);

  useEffect(() => {
    if (notifications && notifications.length) {
      const allIsActiveLength = notifications.filter(
        (n) => n.frequency !== "never",
      ).length;
      const allIsActive = allIsActiveLength === notifications.length;

      const newModel = notifications.reduce(
        (acc, elem) => {
          if (elem.belongsToGroupDiscussions) {
            acc[elem.name] = elem.frequency;
            acc[`${elem.name}_active`] = elem.frequency !== "never";
          } else {
            acc[elem.notification_type] = elem.frequency;
            acc[`${elem.notification_type}_active`] = elem.frequency !== "never";
          }

          return acc;
        },
        {
          ALL: allIsActive,
        },
      );

      setModel(newModel);
    }
    return () => null;
  }, [notifications]);

  const handleOnChange = (option, notification) => {
    const propToUse = notification.belongsToGroupDiscussions
      ? notification.name
      : notification.notification_type;

    if (
      (notification.notification_type === "group_discussions"
        && !notification.belongsToGroupDiscussions)
      || (notification.notification_type === "group_discussions"
        && notification.name === "All")
    ) {
      const keysToChange = notifications.reduce(
        (acc, n) => {
          if (n.belongsToGroupDiscussions) {
            acc.push(`${n.name}`);
            if (notification.frequency === "never" && option !== "never") {
              acc.push(`${n.name}_active`);
            }
          }
          return acc;
        },
        [
          notification.name === "All" ? "" : "group_discussions",
          notification.frequency === "never" && option !== "never"
            ? "group_discussions_active"
            : "",
        ],
      );

      const newModel = Object.keys(model).reduce((acc, key) => {
        const keyExist = keysToChange.some((k) => k === key);
        acc[key] = keyExist ? option : model[key];
        return acc;
      }, {});

      setModel(newModel);
    } else {
      setModel({
        ...model,
        [propToUse]: option,
        [`${propToUse}_active`]:
          notification.frequency === "never" && option !== "never"
            ? true
            : model[`${propToUse}_active`],
      });
    }

    const dataForServer = {
      settings_notification: {
        frequency: option,
      },
    };

    dispatch(
      saveNotificationsAttempt({
        enqueueSnackbar,
        data: {
          dataForServer,
          id: notification.setting_notification_id,
        },
      }),
    );
  };

  const handleToggleChange = (notification) => {
    const propToUse = notification.belongsToGroupDiscussions
      ? notification.name
      : notification.notification_type;
    const newFreq = model[propToUse] !== "never" ? "never" : "weekly";
    const dataForServer = {
      settings_notification: {
        frequency: newFreq,
      },
    };

    if (
      notification.notification_type === "group_discussions"
      && !notification.belongsToGroupDiscussions
    ) {
      const keysToChange = notifications.reduce(
        (acc, n) => {
          if (n.belongsToGroupDiscussions) {
            acc.push(...[`${n.name}`, `${n.name}_active`]);
          }
          return acc;
        },
        ["group_discussions", "group_discussions_active"],
      );
      const newModel = Object.keys(model).reduce((acc, key) => {
        const keyExist = keysToChange.some((k) => k === key);
        if (keyExist) {
          if (typeof model[key] === "boolean") {
            acc[key] = newFreq !== "never";
          }
          if (typeof model[key] === "string") {
            acc[key] = newFreq;
          }
        } else {
          acc[key] = model[key];
        }

        return acc;
      }, {});
      const isAllSelected = Object.keys(newModel).filter(
        (k) => !k.includes("_active") && newModel[k] === "never",
      ).length === 0;

      setModel({
        ...newModel,
        ALL: isAllSelected,
      });
    } else {
      const newModel = {
        ...model,
        [propToUse]: newFreq,
        [`${propToUse}_active`]: newFreq !== "never",
      };
      const isAllSelected = Object.keys(newModel).filter(
        (k) => !k.includes("_active") && newModel[k] === "never",
      ).length === 0;

      setModel({
        ...newModel,
        ALL: isAllSelected,
      });
    }

    dispatch(
      saveNotificationsAttempt({
        enqueueSnackbar,
        data: {
          dataForServer,
          id: notification.setting_notification_id,
        },
      }),
    );
  };

  const handleToggleAll = () => {
    const isAlreadyAll = model.ALL;

    httpPut({
      call: "user/settings/notifications/toggle_all",
    })
      .pipe()
      .subscribe(() => {
        const newModel = notifications.reduce(
          (acc, elem) => {
            if (elem.belongsToGroupDiscussions) {
              acc[elem.name] = elem.frequency;
              acc[`${elem.name}_active`] = !isAlreadyAll;
            } else {
              acc[elem.notification_type] = elem.frequency;
              acc[`${elem.notification_type}_active`] = !isAlreadyAll;
            }

            return acc;
          },
          {
            ALL: !isAlreadyAll,
          },
        );

        setModel(newModel);
      });
  };

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.emptyBlock} />
      <div>
        <h5 className={styles.title}>
          You may control which notifications you receive via email:
        </h5>
        <div className={styles.optionsHead}>
          <div className="d-flex flex-grow-1" />
          <div className="d-flex justify-content-between">
            {RADIO_OPTIONS.map((option, idx) => (
              <span key={option + idx} className={styles.optionsHeadItem}>
                {option}
              </span>
            ))}
          </div>
        </div>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={model}
          onSubmit={() => {
            //
          }}
        >
          {({ handleSubmit, handleReset, ...formikprops }) => (
            <Form onSubmit={handleSubmit}>
              {notifications.map((item, i) => (
                <div
                  key={item.setting_notification_id}
                  className={classNames(
                    styles.notificationItem,
                    notifications.length !== i + 1
                      && item.belongsToGroupDiscussions
                      ? "border-0"
                      : "",
                  )}
                >
                  <span
                    className={classNames(
                      "d-flex flex-grow-1",
                      item.belongsToGroupDiscussions
                        ? styles.notificationTitle1
                        : styles.notificationTitle,
                    )}
                    id={item.setting_notification_id + 1}
                  >
                    {item.belongsToGroupDiscussions
                      ? item.name
                      : NOTIFICATIONS_ENUM[item.notification_type]}
                  </span>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    role="group"
                    aria-labelledby={item.setting_notification_id + 1}
                  >
                    {RADIO_OPTIONS.map((option, idx) => (!option.length ? (
                      ""
                    ) : (
                      <div
                        key={`${option || idx}-${
                          item.setting_notification_id
                        }`}
                        className={classNames("radio", styles.radioBtnWrp)}
                      >
                        {item.notification_type === "company_spotlight"
                          && (option === "immediately"
                            || option === "daily") ? null : option === "off/on" ? (
                              <label className={styles.switch}>
                                <Field
                                  type="checkbox"
                                  onChange={(evt) => handleToggleChange(item)}
                                  name={
                                  item.belongsToGroupDiscussions
                                    ? `${item.name}_active`
                                    : `${item.notification_type}_active`
                                }
                                />
                                <span
                                  className={classNames(
                                    styles.slider,
                                    styles.round,
                                  )}
                                />
                              </label>
                          ) : (
                            <Field
                              onChange={() => handleOnChange(option, item)}
                              type="radio"
                              name={
                                item.belongsToGroupDiscussions
                                  ? item.name
                                  : item.notification_type
                              }
                              value={option}
                            />
                          )}
                      </div>
                    )))}
                  </div>
                </div>
              ))}
              {notifications && notifications.length ? (
                <div className={classNames(styles.notificationItem)}>
                  <span
                    className={classNames(
                      "d-flex flex-grow-1",
                      styles.notificationTitle,
                    )}
                    id="ALL"
                  >
                    All Notifications
                  </span>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    role="group"
                    aria-labelledby="ALL"
                  >
                    <div className={classNames("radio", styles.radioBtnWrp)}>
                      <label className={styles.switch}>
                        <Field
                          type="checkbox"
                          onChange={handleToggleAll}
                          name="ALL"
                        />
                        <span
                          className={classNames(styles.slider, styles.round)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default memo(withSnackbar(NotificationsSettings));

import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import { Pannel } from "../../../../primitives";
import { useModelPopup } from "../../../../../common/hooks";
import { deleteRelatedIdeaAttempt } from "../overview.action";

import NewRelatedIdeaComponent from "./addRelatedIdea";

const RelatedIdeasComponent = ({ selectedProject, expandAll, sessionRole }) => {
  const history = useHistory();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);

  const handleIdeaRedirect = (id) => {
    history.push(`/ideas/${id}`);
  };

  const handleAddIdeasClick = () => {
    popup.show({
      title: "Add an Idea to this Project",
      component: (
        <NewRelatedIdeaComponent
          selectedProjectId={selectedProject.id}
          relatedIdeas={selectedProject.ideas}
        />
      ),
    });
  };

  const handleRemoveIdeas = (idea_id) => {
    dispatch(
      deleteRelatedIdeaAttempt({
        project_id: selectedProject.id,
        idea_id,
        enqueueSnackbar,
        cb: () => {
          enqueueSnackbar(`Successfully deleted idea!`, {
            variant: "success",
          });
        },
      })
    );
  };

  const rightContent = useCallback(() => {
    return (
      <>
        <div
          className="d-flex justify-content-center  cursor-pointer "
          onClick={() => setExpanded((prev) => !prev)}
        >
          <Icon
            {...smSize}
            icon="icn-table-down-chevron"
            className={classNames(
              styles.icon,
              expanded ? styles.collapse : styles.collapseUp
            )}
          />
        </div>
      </>
    );
  }, [expanded]);
  return (
    <Pannel
      title="Related Ideas"
      padding="0"
      parentStyle={{
        minWidth: "230px",
      }}
      rightContent={rightContent()}
      withCollapse
      expanded={expanded}
    >
      <div className={styles.panelHeader}>
        Ideas
        {sessionRole !== "viewer" && (
          <Icon
            {...mdSize}
            icon="icn-add"
            className={styles.addNew}
            onClick={handleAddIdeasClick}
          />
        )}
      </div>

      <div className={styles.wrapper}>
        {selectedProject?.ideas ? (
          selectedProject.ideas.map((item) => (
            <div className={styles.ideaContainer} key={`${item.idea_id}`}>
              <div
                onClick={() => handleIdeaRedirect(item.idea_id)}
                className={styles.title}
              >
                <span> {item.name} </span>
              </div>
              {sessionRole !== "viewer" && (
                <Icon
                  {...smSize}
                  icon="icn-button-delete"
                  className={styles.deleteIcon}
                  onClick={(e) => {
                    e.preventDefault();
                    handleRemoveIdeas(item.idea_id);
                  }}
                />
              )}
            </div>
          ))
        ) : (
          <span className="p-3 text-center">No ideas yet!</span>
        )}
      </div>
    </Pannel>
  );
};

export default React.memo(RelatedIdeasComponent);

import React from "react";
import { Link } from "react-router-dom";
import styles from "./terms.module.scss";

const TermsComponent = () => (
  <div className={styles.termsContainer}>
    <div className="row">
      <h2 className={styles.header}>Terms of Use</h2>
    </div>

    <div>
      This website{" "}
      <span className={styles.link}>
        <a href={window.location.origin}>
          <b>{window.location.origin}</b>
        </a>{" "}
      </span>
      (the “Site”) is brought to you by Traction Technology Partners (“TTP,”
      “we,” “us,” or “our”) to power our member network (the “Network”) and
      other services available through the Site (collectively, the “Service”).
      Please read these terms and conditions of use (the “Terms of Use” or “User
      Agreement”) carefully before using the Service as they constitute a
      legally binding contract between you and TTP. This User Agreement sets
      forth the terms and conditions which govern your use of the Service and
      the Website Content (as defined below). By browsing, accessing or
      otherwise using the Service in any manner, you hereby accept, without
      limitation or qualification (1) these Terms of Use, and (2) the privacy
      policy for the Site (the “Privacy Policy”). If you do not agree to these
      <span className={styles.link}>
        <Link to="/terms"> Terms Of Use</Link>
      </span>{" "}
      or the{" "}
      <span className={styles.link}>
        <Link to="/privacy_policy">Privacy Policy</Link>,{" "}
      </span>
      you may not use the Service and must exit now. Please review the Terms of
      Use periodically. We reserve the right, in our sole discretion, to update
      or revise these Terms of Use at any time. In the event of any such update
      or revision, we will change the "Last Updated" date above and describe the
      changes in a chart at the bottom of the agreement. Such updates or
      revisions shall become effective immediately upon the posting thereof.
      Your continued use of the Service following the posting of updates and/or
      revisions to the Terms of Use will constitute your acceptance of such
      update and/or revised Terms of Use. The most current version of the Terms
      of Use can be accessed at any time by selecting the Terms of Use link at
      the bottom of the home page for the Service.
    </div>

    <div className={styles.sectionWrp} id="sec1">
      <div className={styles.sectionTitle}> Website Content.</div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>A.</span> Description of Website
        Content. The Service contains a wide variety of Website Content (defined
        below), whether (1) proprietary to TTP, or (2) proprietary to third
        parties, or (3) contributed by other users of the Service. “Website
        Content” includes, but is not limited to text, data, files, documents,
        software, scripts, layout, design, function and “look and feel,”
        graphics, images, audio, videos, audiovisual combinations, interactive
        features and any other materials that you may view, access or download
        (subject to the terms herein) through the Service.
      </div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>B.</span> Our Proprietary Rights.
        You acknowledge and agree that all Website Content, whether publicly
        posted or privately transmitted, as well as all derivative works
        thereof, are owned by TTP or other parties that have licensed their
        material to TTP, and are protected by copyright, trademark and other
        intellectual property laws. Except as specifically permitted herein,
        Website Content may not be copied, reproduced, republished, uploaded,
        posted, transmitted or distributed for your commercial or business
        purposes, including by e-mail or other electronic means, without the
        prior consent of TTP or such third-party that owns the Website Content.
        All rights not expressly granted herein by TTP and/or its licensors to
        you are reserved by TTP and/or its licensors.
      </div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>C.</span> Accessing and
        Downloading Website Content. You download information from TTP at your
        own risk. TTP does not warrant that the Service or materials available
        therefrom are compatible with your equipment or that any such
        information is free from invasive or corruptive files or Harmful Code
        (as defined below). TTP will not be liable or have any responsibility of
        any kind for any loss or damage that you incur as a result of (a) use of
        the Service or the Website Content, including any errors, inaccuracies
        or omissions in such information, material or data, (b) any harmful
        features arising from the Service or the Website Content, (c) the act or
        omission of any other party involved in making the Service or the
        Website Content available to you, or (d) from any other cause relating
        to your access to, inability to access, or use of the Service or the
        Website Content, whether or not the circumstances giving rise to such
        cause may have been within the control of TTP or third parties.
      </div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>D.</span> Restrictions on Your Use
        of Website Content. You may access and use Website Content solely for
        your information and internal or personal use, as intended through the
        functionality of the Service and permitted under this User Agreement.
        Any modification of the Website Content, use of the Website Content on
        any website or networked computer environment, or use of the Website
        Content for any purpose other than personal or internal use, without the
        prior consent of TTP or its licensors, as applicable, is a violation of
        the copyright, trademark and other proprietary rights in the Website
        Content and is expressly prohibited. You may not make derivative works
        of the Website Content or distribute any Website Content to third
        parties.
      </div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>E.</span> Website Content
        Disclaimer. Website Content is provided to you AS IS. You understand
        that we do not guarantee the accuracy, safety, integrity or quality of
        Website Content (other than Traction Research, which is content gathered
        and vetted by a Traction analysts on behalf of your organization) and
        you hereby agree that you must evaluate and bear all risks associated
        with the use of any Website Content, including any reliance on the
        Website Content or the integrity and accuracy of such Website Content.
        Nothing on our Service should be deemed to constitute a recommendation
        to purchase, sell or hold, or otherwise to constitute advice regarding,
        any registered or unregistered security.
      </div>
    </div>

    <div className={styles.sectionWrp} id="sec1">
      <div className={styles.sectionTitle}> Content You Provide.</div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>A.</span> Description of Your
        Content. Certain features of the Service, such as discussions, comments,
        messaging allow you to post, submit, publish, share or manage ideas,
        opinions, recommendations, data and information (“Your Content”). By
        posting or publishing Your Content, you represent and warrant to us that
        you have all necessary rights to distribute Your Content to us, either
        because you are the author and have the right to distribute the same, or
        because you have the appropriate distribution rights, licenses,
        consents, and/or permissions to use, in writing, from the copyright or
        other owner of Your Content. You hereby grant to us and our authorized
        personnel a worldwide, royalty-free, fully-paid, non-exclusive,
        transferable (in connection with an assignment of the Agreement) and
        sublicensable license to host, copy, display, publish, distribute,
        manipulate, create derivative works of, and otherwise exploit Your
        Content as reasonably necessary for us to provide, and ensure proper
        operation of our Services and associated systems. You hereby waive all
        rights of attribution, integrity and any other rights known as moral
        rights, artist’s rights or droit moral (“Moral Rights”) in or to Your
        Content, to the extent legally permissible. To the extent waiver is not
        permitted, you hereby consent to and ratify any action that may be taken
        by TTP (and to confirm any consent or ratification upon TTP’s request
        and without charge) with respect to any Moral Rights and agree not to
        assert any Moral Rights with respect to such action.
      </div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>B.</span> Confidential or
        Sensitive Business Information. Please note that you provide
        confidential or sensitive information to us and/or other registered
        members of the Network within your organization (“Registered Members”)
        voluntarily and you do so at your own risk.
      </div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>C.</span> Derivative Works. You
        acknowledge and agree that we will own all right, title, and interest in
        and to any materials, content, or other works, including derivative
        works of Your Content, created by us or on our behalf and used in
        connection with the Service offered by our organization. Please be aware
        that any information, ideas or opinions you choose to publish on or
        through certain functions of our Service are available to and may be
        seen by all other Registered Members of the Service within your
        organization.
      </div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>D.</span> Your Proprietary Rights.
        It is highly unlikely that we will know about any potential violations
        of your rights unless you tell us. Therefore, if you believe that your
        copyrighted work has been infringed through the Service, you may send a
        written notice to TTP (see the “Contact Information” section below) and
        we will respond pursuant to the Digital Millennium Copyright Act
        (“DMCA”) procedure. TTP reserves all rights to seek damages and fees
        associated with infringement and/or fraud.
      </div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>E.</span> Feedback. You
        acknowledge and agree that any questions, comments, suggestions, ideas,
        feedback or other information provided by you to TTP (collectively,
        “Feedback”) are not confidential and you hereby grant to TTP a
        worldwide, perpetual, irrevocable, royalty-free license to reproduce,
        display, perform, distribute, publish, modify, edit or otherwise use
        such Feedback as it deems appropriate, for any and all commercial or
        non-commercial purposes, in its sole discretion.
      </div>
    </div>

    <div className={styles.sectionWrp} id="sec1">
      <div className={styles.sectionTitle}>
        {" "}
        Your Obligations and Use of the Service.
        <p>
          You agree to use the Service responsibly, as intended by the
          functionality of the Service and in accordance with these Terms.
        </p>
      </div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>A.</span> You agree:
        <ul className={styles.unorderedList}>
          <li>
            that you are more than 18 years of age and are fully able and
            competent to enter into the terms, conditions, obligations,
            affirmations, representations and warranties set forth in this User
            Agreement;
          </li>
          <li>
            that you have the right, authority, and capacity to enter into this
            Agreement on your own behalf and on behalf on any entity for whom
            you are acting;
          </li>
          <li>
            not to use a false name or email address or any name or email
            address of, owned or controlled by another person with the intent to
            impersonate that person or for any other reason;
          </li>
          <li>
            that you shall not use a User ID name that is subject to any rights
            of a person other than yourself without appropriate authorization;
          </li>
          <li>
            that you are solely responsible for maintaining the confidentiality
            of your password;
          </li>
          <li>
            to update your user profile and registration information so that it
            remains true, correct and complete;
          </li>
          <li>
            to conduct yourself in a professional manner in your use of the
            Service and your interactions with TTP and with any other Registered
            Member of the Network;
          </li>
          <li>
            and to use any personal information (such as names and email
            addresses) you may acquire through the use of the Service in
            accordance with all applicable laws, including privacy laws.
          </li>
        </ul>
      </div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>B.</span> You must not:
        <ul>
          <li>
            use the Service to market products or services (such as investment
            advisory services that could subject TTP to SEC or other
            regulation);
          </li>
          <li>
            use the Service or Website Content in any manner that is competitive
            to TTP;
          </li>
          <li>
            access or use the Service for any purpose in violation of local,
            state, national, or international laws;
          </li>
          <li>
            post or transmit via the Service information or material that is
            unlawful, libelous, defamatory, obscene, fraudulent, predatory of
            minors, harassing, threatening or hateful to any person, or that
            contains any profane, obscene, pornographic or otherwise
            objectionable content or language;
          </li>
          <li>
            upload or transmit any communication or content of any type that
            infringes or violates any rights of any individual or entity (such
            as the personal, privacy, contractual, intellectual property or
            other rights of any person or entity);
          </li>
          <li>
            post or distribute any personal information about another
            individual, including an address, phone number, email address,
            credit card number or other information that could be used to track,
            contact or impersonate that individual unless you are authorized to
            do so by that individual;
          </li>
          <li>
            modify, sell, assign or transfer the Website Content or use the
            Service to provide services to others;
          </li>
          <li>
            alter, remove or falsify any attributions or other proprietary
            designations of origin or source of any Website Content;
          </li>
          <li>copy or distribute in any medium any part of this Service;</li>
          <li>
            reverse engineer, disassemble or decompile any software included in
            the Service and/or Website Content;
          </li>
          <li>
            use this Service as a means to distribute or post advertising or
            other unsolicited material to any third party;
          </li>
          <li>
            impersonate any person or entity or falsely represent your
            identification or affiliation;
          </li>
          <li>
            trick, defraud, deceive or mislead TTP or other users (such as by
            misrepresenting the source of materials or other information you
            submit or post to the Service or using tools which anonymize your
            Internet protocol address to access the Service);
          </li>
          <li>
            interfere with the operation of this Service in any way (such as
            denial of service attacks, flooding, or spamming);
          </li>
          <li>
            transmit, distribute, introduce or otherwise make available in any
            manner through the Service any computer virus, keyloggers, spyware,
            worms, Trojan horses, time bombs or other malicious or harmful code
            (collectively, “Harmful Code”);
          </li>
          <li>
            use the Service in any manner that could damage, disable or impair
            our services or networks;
          </li>
          <li>
            attempt to gain unauthorized access to any computer systems or
            networks, through hacking, password mining or any other means; or
          </li>
          <li>
            access or search (or attempt to access or search) the Service or
            Website Content by any means (such as any robot, scraper or other
            automated system or unauthorized script or software) other than
            currently available, published interfaces provide by TTP; or
          </li>
          <li>
            interfere with or circumvent any security feature of the Service or
            any feature that restricts or enforces limitations on use of or
            access to the Service.
          </li>
        </ul>
      </div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>C.</span> You understand and
        acknowledge that:
        <ul>
          <li>
            you are representing to TTP that you have adequate rights, including
            all intellectual property rights, to Your Content in order to allow
            TTP to display Your Content and to grant to TTP the rights granted
            herein;
          </li>
          <li>
            Your Content may be republished and if you do not have the r ight to
            submit Your Content for such use, it may subject you to liability,
            and that TTP will not be responsible or liable for any use of Your
            Content by TTP in accordance with this User Agreement;
          </li>
          <li>
            any Website Content posted by other users or through TTP is the sole
            responsibility of the person who originated such Website Content;
          </li>
          <li>
            TTP is making no representations, warranties, or recommendations
            about any individual or entity for you to rely on;
          </li>
          <li>
            TTP may, but is not obligated to monitor the Service, and you are
            not relying on TTP to do so;
          </li>
          <li>
            TTP does not, endorse, support, represent or guarantee the
            completeness, truthfulness, accuracy or reliability of any Website
            Content or communications sent or posted via the Service, or any
            opinions expressed by users of the Service; and
          </li>
          <li>
            by using the Services, you may be exposed to Website Content that
            might be offensive, harmful, inaccurate or otherwise inappropriate,
            and that you have no claim against TTP for any such material.
          </li>
        </ul>
      </div>
    </div>

    <div className={styles.sectionWrp} id="sec1">
      <div className={styles.sectionTitle}>
        {" "}
        TTP's Rights.
        <p>
          You agree to use the Service responsibly, as intended by the
          functionality of the Service and in accordance with these Terms.
        </p>
      </div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>A.</span> TTP is providing the
        Service through an agreement with your organization. Accordingly, we may
        take actions that will affect your access to or use of the Service
        without any liability to you or others. We may, in our sole discretion:
        <ul>
          <li>
            modify, suspend or terminate all or a portion of the Service at any
            time without prior notice to you;
          </li>
          <li>
            revoke, terminate or suspend any privileges associated with
            accessing the Service or delete your account entirely for any reason
            or for no reason whatsoever, including improper use of the Service
            or failure to comply with these Terms of Use, and to take any other
            action we deem appropriate; and
          </li>
          <li>
            delete or decline to post or distribute all or any portion of Your
            Content for any reason or for no reason whatsoever.
          </li>
        </ul>
      </div>
      <div className={styles.sectionBlock}>
        <span className={styles.boldCategory}>B.</span> TTP is providing the
        Service as a courtesy to you. Accordingly, our obligations with respect
        to the Service are limited. Specifically:
        <ul>
          <li>
            we are not obligated to introduce you to any individual or entity;
          </li>
          <li>
            we are not an investment adviser and no part of this Service is
            intended to constitute investment advice;
          </li>
          <li>
            we do not recommend any startups for investment, endorse their
            fitness for investment;
          </li>
          <li>
            we are not responsible for doing diligence on any individual, entity
            or other users you meet through the Service or verifying any
            representations, materials or other information provided through the
            Service or by users of the Service (other than Traction Research,
            which is content gathered and vetted by a Traction analysts on
            behalf of your organization);
          </li>
          <li>
            we are not obligated to monitor or enforce any intellectual property
            rights that may be associated with Your Content;
          </li>
          <li>
            we do not control or endorse Website Content provided by third
            parties or users, messages sent through the Service or information
            found on external websites that may be linked to or from the Service
            and TTP specifically disclaims any responsibility with respect
            thereto; and
          </li>
        </ul>
        <ol>
          <li>
            Trademarks/Service Marks, Logos, Slogans and Taglines. All
            trademarks, service marks, logos, slogans and taglines displayed on
            or through the Service are the property of TTP or its respective
            licensors and nothing contained herein should be construed as
            granting any license or right to use any trademarks, service marks,
            logos, slogans or taglines displayed on or through the Service
            without the express written permission of TTP, or such third-party
            that may own the trademark, service mark, logo, slogan or tagline.
          </li>
          <li>
            Links to Other Services. Our Service contains links to other
            websites. The fact that we link to a website is not an endorsement,
            authorization or representation of our affiliation with that third
            party. We do not exercise control over third party websites. These
            other websites may place their own cookies or other files on your
            computer, collect data or solicit personal information from you.
            Other sites follow different rules regarding the use or disclosure
            of the personal information you submit to them. We encourage you to
            read the terms applicable to the use of the other websites you
            visit.
          </li>
          <li>
            Disclaimer of Warranty. Access to the site is provided “AS IS” and
            “AS AVAILABLE” without any warranty of any kind, express or implied.
            TTP does not warrant the accuracy, completeness or timeliness of the
            Service or the Website Content and, to the fullest extent
            permissible pursuant to applicable law, TTP disclaims all warranties
            of any kind, either express or implied, including but not limited to
            any implied warranties of title, merchantability, fitness for a
            particular purpose or non-infringement, with respect to any
            information obtained through the Service. Without limiting the
            foregoing, TTP does not warrant that access to the Service will be
            uninterrupted or error-free, or that defects, if any, will be
            corrected; nor does TTP make any representations about the accuracy,
            reliability, currency, quality, completeness, usefulness,
            performance, security, legality or suitability of the Service or any
            of the information contained therein. You expressly agree that your
            use of the Service and Your reliance upon any Website Content is at
            your sole risk. You shall be solely and fully responsible for any
            damage to any computer system, any loss of data, or any improper use
            or improper disclosure of information caused by you. TTP cannot and
            does not assume any responsibility for any loss, damages or
            liabilities arising from the failure of any telecommunications
            infrastructure, or the internet or for your misuse of any of the
            ideas or information accessed through the Service.
          </li>
          <li>
            Limitation of Liability. In the event of any problem with the
            Service or any Website Content, you agree that your sole remedy is
            to cease using the Service. Under no circumstances shall TTP or any
            TTP licensor, licensee, supplier or provider be liable in any way
            for your use of the Service or any Website Content including, but
            not limited to, any errors or omissions in any Website Content, any
            infringement by any Website Content of the intellectual property
            rights or other rights of third parties, or for any losses or
            damages of any kind arising directly or indirectly out of the use,
            inability to use, or the results of use of the Service or the
            materials or information contained on the Service. Under no
            circumstances shall TTP, its licensors, licensees, suppliers or
            providers be liable for any punitive, exemplary, consequential,
            incidental, indirect or special damages (including, without
            limitation, any personal injury, lost profits, business
            interruption, loss of programs or other data on your computer or
            otherwise) arising from or in connection with your use of the
            Service or Website Content, whether under a theory of breach of
            contract, negligence, strict liability, malpractice or otherwise,
            even if We or they have been advised of the possibility of such
            damages. You hereby release TTP and hold TTP and its licensors,
            licensees, suppliers and providers harmless from any and all claims,
            demands, and damages of every kind and nature (including, without
            limitation, actual, special, incidental and consequential), known
            and unknown, suspected and unsuspected, disclosed and undisclosed,
            arising out of or in any way connected with your use of the Service
            or Website Content. You waive the provisions of any state or local
            law limiting or prohibiting a general release.
          </li>
          <li>
            Indemnification. Without limiting the generality or effect of other
            provisions of this User Agreement, as a condition of use, you agree
            to indemnify, hold harmless and defend TTP and its parents,
            subsidiaries, affiliates, licensors, licensees, suppliers and
            providers and their officers, directors, affiliates, subcontractors,
            agents and employees, lenders, licensors and suppliers and their
            parents, subsidiaries, affiliates, suppliers and their officers,
            directors, affiliates, subcontractors, agents and employees
            (collectively, “Indemnified Parties” and each, individually, an
            “Indemnified Party”) against all costs, expenses, liabilities and
            damages (including reasonable attorney’s fees) incurred by any
            Indemnified Party in connection with any third party claims arising
            out of: (i) your use of the Service; (ii) your failure to comply
            with any applicable laws and regulations; and/or (iii) your breach
            of any obligations set forth in this User Agreement. You shall not
            settle any such claim without the written consent of the applicable
            Indemnified Party.
          </li>
          <li>
            Modification of These Terms of Use. We reserve the right to change
            these Terms and any terms, conditions and notices under which the
            Service is offered. We may terminate these Terms of Use at any time
            by suspending or terminating access to the Service. You can see when
            these Terms of Use were last revised by referring to the “Last
            Updated” legend above. Your continued use of the Service after we
            have posted revised Terms of Use signifies your acceptance of such
            revised Terms of Use.
          </li>
          <li>
            Miscellaneous. This User Agreement shall be governed by the laws of
            the State of New York, U.S.A. without regard to its rules on
            conflicts or choice of law. You will resolve any claim, cause of
            action or dispute (claim) you have with us arising out of or
            relating to this User Agreement exclusively in the state or federal
            courts located in New York, New York, and you agree to submit to the
            personal jurisdiction of such courts for the purpose of litigating
            all such claims. Any of the terms of this User Agreement which are
            determined to be invalid or unenforceable shall be ineffective to
            the extent of such invalidity or unenforceability, without rendering
            invalid or unenforceable any of the remaining terms of this User
            Agreement or affecting the validity or enforceability of the User
            Agreement as a whole. Failure to insist on performance of any of the
            terms of the User Agreement will not operate as a waiver of any
            subsequent default. No waiver by TTP of any right under this User
            Agreement will be deemed to be either a waiver of any other right or
            provision or a waiver of that same right or provision at any other
            time. You may not assign, transfer or delegate your rights or
            obligations hereunder, in whole or in part. This User Agreement
            shall be binding upon and inure to the benefit of each of the
            parties and the parties’ respective successors and permitted
            assigns. A printed version of this User Agreement and of any related
            notice given in electronic form shall be admissible in judicial or
            administrative proceedings based upon or relating to this User
            Agreement to the same extent and subject to the same conditions as
            other business documents and records originally generated and
            maintained in printed form. This User Agreement constitutes the
            entire agreement between you and TTP with respect to use of the
            Service and/or Website Content and it supersedes all prior or
            contemporaneous communications and proposals, whether electronic,
            oral or written, between you and TTP with respect to the Service
            and/or Website Content, and no other representations, statements or
            inducements, oral or written, shall bind either you or TTP. This
            User Agreement may only be amended or modified as set forth herein
            or in a writing signed by you and TTP specifically referencing this
            User Agreement.
          </li>
        </ol>
      </div>
    </div>

    <div className={styles.sectionWrp} id="sec1">
      <div className={styles.sectionTitle}> Contact Information.</div>
      <span>
        If you have questions or concerns with respect to TTP or the Service,
        please contact us using one of the following options: By e-mail:
      </span>{" "}
      <span className={styles.link}>
        <a href="mailto:info@tractiontechpartners.com">
          info@tractiontechpartners.com
        </a>
      </span>
      <br />
      By mail: <br />
      Attn: Erick Schonfeld <br />
      Traction Technology Partners <br />
      87 Sheather Road <br />
      Mount Kisco, NY 100549 <br />
      USA <br />
    </div>
    <div className={styles.footer}>
      {" "}
      © COPYRIGHT 2022 Traction Technology Partners, ALL RIGHTS RESERVED.
    </div>
  </div>
);

export default TermsComponent;

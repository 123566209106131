import React, { memo, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./switch.module.scss";

const SwitchComponent = (props) => {
  const {
    tabs = [],
    selected = null,
    onChange = null,
    customStyles = {},
  } = props;
  const [select, setSelect] = useState(null);

  useEffect(() => {
    setSelect(selected);
  }, [selected]);

  const handleSwitchClick = (e, value) => {
    setSelect(value);
    if (onChange) onChange(value);
  };

  return (
    <div className={styles.switchWrp}>
      {tabs.map(({ value, text }) => (
        <div
          style={select === value ? { ...customStyles } : {}}
          key={value}
          className={classNames(styles.tab, select === value && styles.active)}
          onClick={(e) => {
            handleSwitchClick(e, value);
          }}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

export default memo(SwitchComponent);

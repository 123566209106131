import React from "react";
import { ModalFooter, ModalBody } from "../../../../application/modal/index";
import { useModelPopup } from "../../../../common/hooks";
import { Button, BtnType, Label } from "../../../primitives";

const MsftSuccessfulModal = () => {
  const popup = useModelPopup();
  const handleOkayClick = () => {
    popup.hide();
  };
  return (
    <>
      <div>
        <ModalBody>
          <Label>
            <h4>
              You've successfully connected to your Microsoft account. Now you
              can add contacts and emails from Microsoft to Traction.
            </h4>
          </Label>
        </ModalBody>
        <ModalFooter>
          <Button
            btn={BtnType.FRAME}
            className="mt-4 mr-1"
            onClick={handleOkayClick}
          >
            Okay
          </Button>
        </ModalFooter>
      </div>
    </>
  );
};

export default React.memo(MsftSuccessfulModal);

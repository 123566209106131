import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  allowedExtensions,
} from "modules/events/viewEvent/viewEvent.constant";
import { useSelector } from "react-redux";
import {
  Loading,
  Label,
  TextBox,
  Button,
  BtnType,
} from "modules/primitives/index";
import DragAndDrop from "common/components/dragAndDrop/index";
import checkUniqName from "common/checkUniqName";
import checkDuplicateName from "common/checkDuplicateName";

import findCurrentCustomFieldGroup from "../../../findCustomFieldGroup";
import SimpleDialog from "../../../../modules/companies/company/details/confirmationModal";
import { Icon, mdSize } from "../../../icon";
import useS3FileUpload from "../../../hooks/s3FileUplodar.hook";
import { model, validation } from "./constant";
import styles from "./styles.module.scss";
import LinearProgressWithLabel from "../../../components/linearProgressWithLabel";
import UniqName from "../uniqName/uniqName";

const NewSlidesComponent = ({
  formRef,
  handleOnSubmit,
  data,
  mode,
  setShowFooterButtons,
  fromAdmin = false,
  uniqName,
  rfiInstance,
  companyId,
  adminLocation,
  selectedCustomFieldGroup,
  indexOfCfGroup,
}) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany, newCustomFieldGroups },
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: { selectedPatientOrganization },
    },
    adminCustomFieldsReducer: { customFieldGroups },
  } = useSelector((state) => state);
  const [initModel, setInitModel] = useState({ ...model });
  const [isDeleting, setIsDeleting] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadValues, setUploadValues] = useState([]);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { fileDelete, fileUpload } = useS3FileUpload();
  const [slides, setSlides] = useState([]);
  const [index, setIndex] = useState(null);
  const [unsavedFiles, setUnsavedFiles] = useState([]);
  const [titleError, setTitleError] = useState("");

  useEffect(() => {
    setUnsavedFiles(data?.unsavedFiles || []);
  }, [data?.unsavedFiles]);

  const getFilename = (url) => {
    if (url?.uri) {
      return url?.uri.split("/").pop();
    } else if (url) {
      return url?.split("/").pop();
    }
    return "";
  };

  const handleOpenModal = (e, index) => {
    e.stopPropagation();

    setOpenConfirmModal(true);
    setIndex(index);
  };

  const handleDismiss = () => {
    setOpenConfirmModal(!openConfirmModal);
  };

  const getIframeSrc = (url) => {
    const match = /src\s*=\s*"([^"]+)"/i.exec(url);
    if (match) {
      return match[1];
    }
  };

  const isEmbedded = (url) => {
    return allowedExtensions?.indexOf(getExtension(url)) !== -1;
  };

  const checkIfS3 = (url) => {
    const match = /(s3-|s3\.)?(.*)\.amazonaws\.com/g.exec(url);

    if (match) {
      return match[0];
    }
  };

  const getExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  const handleDeleteSlide = async (e) => {
    e.stopPropagation();
    setOpenConfirmModal(false);
    setIsDeleting(index);
    if (checkIfS3(slides[index].uri)) {
      const pathname = `srm/${selectedCouncil.id}/${
        selectedPatientOrganization?.id ? "patient_organizations" : "companies"
      }/${
        selectedPatientOrganization?.id || selectedCompany?.id || companyId
      }/${getFilename(slides[index])}`;
      await fileDelete(pathname).then(() => {
        setIsDeleting("");

        setSlides(() =>
          slides.map((slide, idx) => {
            if (idx === index) {
              return { ...slide, _destroy: true };
            }
            return slide;
          })
        );
        setUnsavedFiles(unsavedFiles.filter((s, idx) => idx !== index));
      });
    } else {
      setSlides(() =>
        slides.map((slide, idx) => {
          if (idx === index) {
            return { ...slide, _destroy: true };
          }
          return slide;
        })
      );
      setUnsavedFiles(unsavedFiles.filter((s, idx) => idx !== index));
      formRef.current.values.options = slides.filter(
        (slide, idx) => idx !== index
      );
      setIsDeleting("");
    }
    if (mode === "edit") {
      setIndex(null);
    }
  };
  const getSlidePlatform = (url) => {
    if (url.includes("iframe")) {
      return "iframe";
    }
    if (
      url.includes("https://www.slideshare.net/slideshow/embed_code/") ||
      url.includes("https://docs.google.com/presentation/")
    ) {
      return "link";
    }
  };

  const uploadHandler = async (e, action) => {
    if (forbiddenFiles?.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }

    setLoading(true);
    const fileList = [];
    let dismissedFiles = 0;

    setUploadValues((items) => {
      const currentFiles = [...items];
      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name,
      }));
      return [...currentFiles, ...newFiles];
    });

    files.forEach(async (file, index, array) => {
      const isLastIndex = Boolean(index === array?.length - 1);

      let fileCopy = file;
      // This regular expression pattern that matches any character that is not in the range of a-z, A-Z, or 0-9.
      const regex = /[^a-zA-Z0-9]/g;

      if (regex.test(file.name)) {
        fileCopy = new File([file], file.name.replace(/[^a-zA-Z0-9. ]/g, ""), {
          type: file.type,
        });
      }

      if (
        fileCopy.size <= 500000000 &&
        allowedExtensions?.indexOf(getExtension(fileCopy.name)) !== -1
      ) {
        const pathname = `srm/${selectedCouncil.id}/${
          selectedPatientOrganization?.id
            ? "patient_organizations"
            : "companies"
        }/${selectedCompany?.id || companyId}/`;
        await fileUpload(
          fileCopy,
          pathname,
          setUploadValues,
          "example.pptx"
        ).then((res) => {
          if (res && res.location) {
            if (
              slides?.indexOf(res.location) < 0 ||
              !slides?.indexOf(res.location)
            ) {
              fileList.push(res.location);
              isLastIndex && setLoading(false);
              if (fileList?.length === files?.length - dismissedFiles) {
                const newSlides = fileList.map((uri) => ({
                  uri,
                  name: getFilename(uri),
                  council_id: selectedCouncil.id,
                  added_by_id: session.id,
                }));

                setSlides([...slides, ...newSlides]);
                setUnsavedFiles([...unsavedFiles, ...newSlides]);
              }
            } else {
              enqueueSnackbar("This document was already added.", {
                variant: "error",
              });
              isLastIndex && setLoading(false);
              dismissedFiles++;
              setForbiddenFiles((prevFiles) => {
                const localFiles = [...prevFiles];
                localFiles.push({
                  name: fileCopy.name,
                  error: "Document already added.",
                });
                return localFiles;
              });
              setUploadValues((prevState) => {
                const currentProgress = [...prevState];
                const index = currentProgress
                  .map((item) => item.name)
                  .lastIndexOf(fileCopy.name);
                currentProgress[index] = {
                  progress: "NOT_ALLOWED",
                  name: fileCopy.name,
                };
                return currentProgress;
              });
            }
          }
        });
      } else {
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === fileCopy.name
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: fileCopy.name,
          };
          return currentProgress;
        });
        isLastIndex && setLoading(false);
        if (fileCopy.size > 500000000) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: fileCopy.name,
              error: "Your file is too large. File size limit: 500MB",
            });
            return localFiles;
          });
          dismissedFiles++;
          enqueueSnackbar(
            "We could not upload your image because your file size is too big. Please make sure the file is less than 50 MB",
            {
              variant: "error",
            }
          );
        }
        if (allowedExtensions?.indexOf(getExtension(fileCopy.name)) === -1) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: fileCopy.name,
              error:
                "Extenstion is not allowed, the accepted file formats are: pdf, ppt, pptx, pps.",
            });
            return localFiles;
          });
          isLastIndex && setLoading(false);
          dismissedFiles++;
        }
      }
    });

    e.target.value = "";
  };

  const onSubmit = (values, resetForm) => {
    const cfGroup =
      findCurrentCustomFieldGroup(
        selectedCustomFieldGroup,
        customFieldGroups,
        newCustomFieldGroups,
        indexOfCfGroup,
        data
      ) || [];

    if (!values.title.length) {
      const field_name = checkDuplicateName(cfGroup, "slides");
      values.title = field_name;
    } else if (
      !checkUniqName(values.title, cfGroup) &&
      values.title?.trim()?.toLowerCase() !==
        data?.field_name?.trim()?.toLowerCase()
    ) {
      setTitleError("Field name must be unique. Please rename this field");
      return;
    }
    setTitleError("");

    const documents_attributes = [
      ...slides?.map(
        (slide) => ({
          id: slide?.id,
          added_by_id: session.id,
          name: getFilename(slide?.uri),
          uri: slide?.uri,
          council_id: selectedCouncil.id,
          document_resource_type: "CompanyCustomField",
          document_resource_id:
            data?.company_custom_field || data?.company_custom_field?.id,
          _destroy: slide?._destroy,
          rfi: rfiInstance,
        }),
        ...unsavedFiles.map((s) => ({
          added_by_id: session.id,
          name: getFilename(s?.uri),
          uri: s?.uri,
          council_id: selectedCouncil.id,
          document_resource_type: "CompanyCustomField",
          document_resource_id:
            data?.company_custom_field || data?.company_custom_field?.id,
          rfi: rfiInstance,
        }))
      ),
    ];
    const urls = documents_attributes.map((s) => s.uri && !s._destroy);

    handleOnSubmit({
      ...data,
      ...values,
      option_values: urls,
      field_values: urls,
      documents_attributes,
      unsavedFiles,
    });

    resetForm();
    values.title = "";
  };

  const handleOpenLink = (e, link) => {
    e.stopPropagation();
    if (link.includes("iframe")) {
      window.open(getIframeSrc(link));
    } else {
      window.open(link);
    }
  };

  const handleAddEmbededCode = (e, formProps) => {
    e.stopPropagation();
    e.preventDefault();
    if (
      getSlidePlatform(formProps.values.embedCode) &&
      !isEmbedded(formProps.values.embedCode)
    ) {
      if (slides?.indexOf(formProps.values.embedCode) === -1) {
        const newEmbed = formProps.values.embedCode.trim();
        formProps.values.embedCode = "";

        setSlides([...slides, { uri: newEmbed, name: getFilename(newEmbed) }]);
        setUnsavedFiles([...unsavedFiles, newEmbed]);
      } else {
        enqueueSnackbar("This slide was already added.", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar(
        "External slide links should be from Google Slides, Slideshare or embedded with iFrame. Link format may be incorrect.",
        {
          variant: "error",
        }
      );
    }
  };

  useEffect(() => {
    if (mode === "edit") {
      const editModel = {
        title: data.field_name,
        field_placeholder: data.field_placeholder,
      };
      setSlides(data?.documents || []);
      setInitModel({ ...initModel, ...editModel });
    }
  }, []);

  useEffect(() => {
    setShowFooterButtons(slides?.length > 0);
  }, [slides]);

  return (
    <div>
      {" "}
      <Formik
        innerRef={formRef}
        enableReinitialize
        className={styles.wrapper}
        initialValues={initModel && initModel}
        validationSchema={validation && validation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          onSubmit(values, resetForm);
        }}
      >
        {({ handleSubmit, values, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <Label>Title (optional)</Label>
            <TextBox
              disabled={rfiInstance}
              type="text"
              name="title"
              placeholder="Paste slide name here"
              formProps={{ ...formikprops, values }}
              className={styles.textBox}
            />
            {titleError.length > 1 && (
              <div className={styles.errorBox}>
                <sup>*</sup>
                {titleError}
              </div>
            )}
            <UniqName uniqName={uniqName} />

            {!adminLocation && (
              <div className="d-flex flex-column align-items-start">
                <Label>Instructions (Optional)</Label>
                <TextBox
                  type="text"
                  name="field_placeholder"
                  placeholder="Add instructions"
                  formProps={{ ...formikprops, values }}
                  className={styles.textBox}
                />
              </div>
            )}
            {!fromAdmin && (
              <>
                <Label>Upload slide</Label>
                <p className={styles.instructionText}>
                  The accepted file formats are: pdf, ppt, pptx, pps{" "}
                </p>
                {uploadValues?.length
                  ? uploadValues.map(
                      (bar, index) =>
                        bar &&
                        bar?.progress !== "NOT_ALLOWED" && (
                          <div className={styles.loaderContainer}>
                            <div className={styles.loaderNamingWrp}>
                              {" "}
                              <div className={styles.fileName}>
                                {uploadValues[index]?.name}
                              </div>
                              <LinearProgressWithLabel
                                mode={
                                  uploadValues[index]?.progress === "FAILED" &&
                                  "error"
                                }
                                value={
                                  uploadValues[index]?.progress === "FAILED"
                                    ? 100
                                    : uploadValues[index]?.progress || 0
                                }
                              />
                            </div>
                          </div>
                        )
                    )
                  : null}

                <DragAndDrop
                  uploadHandler={uploadHandler}
                  loading={loading}
                  customText="drag and drop slides here"
                />

                {forbiddenFiles?.length ? (
                  <div className={styles.forbiddenfileContainer}>
                    Files that weren't allowed:{" "}
                    {forbiddenFiles.map((file) => (
                      <span className={styles.forbiddenfileName}>
                        {`${file.name}: `}
                        <span className={styles.errorText}> {file.error}</span>
                      </span>
                    ))}
                  </div>
                ) : null}

                <Label>Embed code</Label>
                <TextBox
                  type="text"
                  name="embedCode"
                  placeholder="Paste embed code"
                  formProps={{ ...formikprops, values }}
                  className={styles.textBox}
                />
              </>
            )}
            <div className={styles.blocks}>
              {formRef?.current?.values?.embedCode && (
                <div className={styles.validateButtonContainer}>
                  <Button
                    type="button"
                    btn={BtnType.FRAME_LESS}
                    onClick={(e) => handleAddEmbededCode(e, formRef?.current)}
                    className={styles.validateButton}
                  >
                    Validate
                  </Button>
                </div>
              )}
              {rfiInstance ? (
                unsavedFiles?.length ? (
                  <div className={`${styles.inputWrapper} mb-4`}>
                    {unsavedFiles?.map((slide, index) =>
                      slide.created_at ? null : (
                        <div
                          className={styles.item}
                          onClick={(e) => handleOpenLink(e, slide)}
                        >
                          <div className={styles.name}> {slide.uri}</div>
                          <div className={styles.delete}>
                            <Icon
                              {...mdSize}
                              icon="icn-button-delete"
                              onClick={(e) => handleOpenModal(e, index)}
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : null
              ) : slides?.length ? (
                <div className={styles.inputWrapper}>
                  {slides?.map((slide, index) =>
                    isDeleting === index ? (
                      <Loading />
                    ) : (
                      <div>
                        {!slide._destroy && (
                          <div
                            className={styles.item}
                            onClick={(e) => handleOpenLink(e, slide)}
                          >
                            <div className={styles.name}> {slide.uri}</div>
                            <div className={styles.delete}>
                              <Icon
                                {...mdSize}
                                icon="icn-button-delete"
                                onClick={(e) => handleOpenModal(e, index)}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              ) : null}
            </div>
          </form>
        )}
      </Formik>
      <SimpleDialog
        open={openConfirmModal}
        handleDismiss={() => handleDismiss()}
        handleDelete={(e) => handleDeleteSlide(e)}
        openConfirmModal={openConfirmModal}
      />
    </div>
  );
};

export default NewSlidesComponent;

import React from "react";

import styles from "./errorBoundary.module.scss";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorStack: "" };
  }

  static getDerivedStateFromError(error) {
    // TODO handle ui on error
    console.log(
      "---error getDerivedStateFromError-------",
      JSON.stringify(error, 2, 2),
    );

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // here we can send error report to third api services
    console.log("---error happened-------", JSON.stringify(errorInfo, 2, 2));
    console.log("---errorInfo happened-------", errorInfo);

    this.setState({
      errorStack: JSON.stringify(errorInfo, 2, 2),
    });
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children, component } = this.props;

    if (hasError) {
      // here we can rendere additional UI in case of error
      return (
        <div className={`container ${styles.errorBoundaryWrapper}`}>
          <h2 className="text-center">
            Please try refreshing your browser or hitting the back button. We
            are working on a fix!
          </h2>
          <p> {component && `Component: ${component}`}.</p>
          <br />
          <p>
            You may also contact us and report this issue at&nbsp;
            <a href="mailto:support@tractiontechnology.com">
              support@tractiontechnology.com
            </a>
            . We will fix it asap!
          </p>
          {/* <p>Component Stack Error Details: {this.state.errorStack}.</p> */}
        </div>
      );
    }

    return children;
  }
}
export default ErrorBoundary;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-dropdown-select";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import { BtnType, Button, Label } from "modules/primitives";
import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import { Icon, mdSize } from "../../../icon";

const ProjectReplicationsPlants = ({ formikprops, isAdminWizardInstance }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [originalPlants, setOriginalPlants] = useState([]);
  const [open, setOpen] = useState(false);

  const {
    adminPlantsAndAreasReducer: { plantsAndAreas },
  } = useSelector((state) => state);

  useEffect(() => {
    if (isAdminWizardInstance) return;

    if (!plantsAndAreas.length) {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        }),
      );
    }
  }, [plantsAndAreas]);

  const getPlantsAsOptions = useCallback((filterBy) => {
    if (isAdminWizardInstance) return [];

    const makePlants = plantsAndAreas.map(plant => ({
      id: plant.id,
      name: plant.attributes.name,
      areas: plant.attributes.areas,
    }));
    const { values } = formikprops;
    const existingValue = values[filterBy] ? [values[filterBy]] : [];

    if (existingValue?.length) {
      return makePlants.filter(plant => !existingValue.some(p => p.id === plant.id));
    }

    return makePlants;
  }, [plantsAndAreas, formikprops.values, isAdminWizardInstance]);

  const getSelectedPlants = useMemo(() => {
    if (isAdminWizardInstance) return [];
    return formikprops.values?.possible_replications_plants || [];
  }, [formikprops.values?.possible_replications_plants, isAdminWizardInstance]);

  const handleAreaSelect = (values, plant) => {
    const newPlants = getSelectedPlants.map(p => {
      if (p.id === plant.id) {
        return {
          ...p,
          selectedAreas: values,
        }
      }

      return p;
    });

    formikprops.setFieldValue("possible_replications_plants", newPlants);
  }

  const handleShowMore = () => {
    setOriginalPlants(getSelectedPlants);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleCancel = () => {
    formikprops.setFieldValue("possible_replications_plants", originalPlants);
    setOriginalPlants([]);
    setOpen(false);
  }

  const isSaveButtonDisabled = useMemo(() => {
    return getSelectedPlants
      .map(plant => plant.areas?.length > 0 && !plant.selectedAreas?.length)
      .filter(el => el).length > 0;
  }, [getSelectedPlants]);

  if (isAdminWizardInstance) {
    return (
      <>
        <Label>Possible Replications (Plants)</Label>
        <Select
          disabled
          multi
          values={formikprops.values?.possible_replications_plants || []}
          onChange={(val) => {
            formikprops.setFieldValue("possible_replications_plants", val);
          }}
          options={getPlantsAsOptions('project_plants')}
          labelField="name"
          valueField="id"
          placeholder="Possible Replications (Plants)"
          searchBy="name"
          style={{ border: "1px solid #e4e7eb" }}
        />
      </>
    )
  }

  return (
    <div className="mb-3">
      <div className="cursor-pointer d-flex align-items-center" style={{ color: '#3994c1' }} onClick={handleShowMore}>
        <Icon {...mdSize} icon="icn-edit-button" />
        <div className="ml-2">Add Possible Replications {getSelectedPlants.length ? `(${getSelectedPlants.length})` : ''}</div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Possible Replications ( Plants + Areas )</DialogTitle>
        <DialogContent>
          <div style={{minHeight: '50vh'}}>
            <Label>Possible Replications (Plants)</Label>
            <Select
              multi
              values={formikprops.values?.possible_replications_plants || []}
              onChange={(val) => {
                formikprops.setFieldValue("possible_replications_plants", val);
              }}
              options={getPlantsAsOptions('project_plants')}
              labelField="name"
              valueField="id"
              placeholder="Select Plants"
              searchBy="name"
              style={{ border: "1px solid #e4e7eb" }}
              closeOnSelect
            />
            {
              getSelectedPlants.length ? (
                <div className="d-flex flex-column mt-3">
                  {
                    getSelectedPlants.map((plant, index) => (
                      <div key={plant.id} className="mb-3">
                        <div className="d-flex align-items-center">
                          <span>{index + 1}.</span>
                          <div className="ml-2">{plant.name}</div>
                        </div>
                        {plant.areas?.length > 0 ? (
                          <div className="mt-2 pl-4">
                            <Label>Areas</Label>
                            <Select
                              multi
                              values={plant.selectedAreas || []}
                              onChange={(val) => handleAreaSelect(val, plant)}
                              options={plant.areas}
                              labelField="area_name"
                              valueField="area_id"
                              placeholder="Areas"
                              searchBy="area_name"
                              style={{ border: "1px solid #e4e7eb" }}
                            />
                            {
                              !plant.selectedAreas?.length ? (
                                <div className="caption small text-danger">
                                  Please select at least one area
                                </div>
                              ) : null
                            }
                          </div>
                        ) : <span className="small text-muted pl-4">No Areas</span>}
                      </div>
                    ))
                  }
                </div>
              ) : null
            }
            <div className="caption small text-danger">
              {formikprops.touched["possible_replications_plants"] && formikprops.errors["possible_replications_plants"]?.id
                ? formikprops.errors["possible_replications_plants"].id
                : ""}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className="mr-3" btn={BtnType.OUTLINE} onClick={handleCancel}>Cancel</Button>
          <Button btn={BtnType.REGULAR} onClick={handleClose} disabled={isSaveButtonDisabled}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(ProjectReplicationsPlants);

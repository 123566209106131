import React, { useCallback, useEffect, useMemo } from "react";

import { Label } from "modules/primitives";
import CheckBox from "modules/admin/adminIdeas/addForm/checkBox";
import styles from "./styles.module.scss";
import CompaniesItem from "../../companyItem";

const ProjectIdeaCompanies = ({ formikprops, isAdminWizardInstance, additionalData, wizardType }) => {
  const companies = useMemo(() => {
    if (!additionalData) return [];
    return additionalData[wizardType === 'theme' ? 'theme_companies' : 'ideas_related_companies'] || [];
  }, [additionalData, wizardType]);

  const selectAllCompanies = useCallback(() => {
    const newValue = !formikprops.values.select_all_companies
    formikprops.setFieldValue('select_all_companies', newValue);

    if (!newValue) {
      formikprops.setFieldValue('project_companies', []);
    } else {
      formikprops.setFieldValue('project_companies', companies.map(c => c.company?.id || c.company_id));
    }
  }, [formikprops, additionalData]);

  useEffect(() => {
    if (isAdminWizardInstance) return;
    selectAllCompanies();
  }, []);

  const storeCompanies = useCallback((companyId) => {
    const { values, setFieldValue } = formikprops;

    if (values.project_companies?.includes(companyId)) {
      const filteredArray = values.project_companies
        .filter((selectedCompanyId) => selectedCompanyId !== companyId);

      setFieldValue('project_companies', filteredArray);

      if (filteredArray.length !== companies.length) {
        setFieldValue('select_all_companies', false);
      }
    } else {
      const findCompany = companies
        .find((c) => c.company?.id || c.company_id === companyId);

      if (!findCompany) {
        return;
      }
      const ids = [...values.project_companies, (findCompany.company?.id || findCompany.company_id)];

      if (ids.length === companies.length) {
        setFieldValue('project_companies', true);
      }

      setFieldValue('project_companies', ids);
    }
  }, [formikprops, companies]);

  return (
    <div className="d-flex flex-column w-100 mb-3">
      {
        !isAdminWizardInstance && (
          <>
            <Label>Select company</Label>
            <div className={styles.selectAllCompanies}>
              <Label>Select all companies</Label>
              <CheckBox
                onChange={selectAllCompanies}
                className={styles.selectCompCheckbox}
                isChecked={formikprops.values?.select_all_companies}
              />
            </div>
          </>
        )
      }
      {
        isAdminWizardInstance ? (
          <div>
            Idea or Theme Companies
          </div>
        ) : (
          <div className={styles.companiesBlockContainer}>
            {
              companies.filter(c => !c.is_product).map((company) => (
                <div key={`${company.company?.id || company.company_id}`}>
                  <CompaniesItem
                    storeCompanies={storeCompanies}
                    company={{
                      ...(company.company || {}),
                      name: company.company?.name || company.name,
                      company_id: company.company?.id || company.company_id
                    }}
                    selectAllCompanies={formikprops.values?.select_all_companies}
                    selected={formikprops.values.project_companies?.includes(company.company?.id || company.company_id)}
                  />
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
};

export default React.memo(ProjectIdeaCompanies);

export const moreOptionTypes = {
  edit: 1,
  assign: 2,
  history: 3,
};

export const moreOptions = [
  {
    val: moreOptionTypes.edit,
    text: "Edit",
  },
];

export const currencySymbol = [
  { name: "USD", symbol: "$" },
  { name: "EUR", symbol: "€" },
  { name: "GBP", symbol: "£" },
  { name: "CHF", symbol: "CHF" },
];

import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { CompanyAvatar } from "../../../modules/primitives";

import styles from "./companyInline.module.scss";

const CompanyInline = (props) => {
  const {
    company = null,
    companyName = "",
    shoulNotBeClickable,
    customIconSize,
    customCompanyNameStyles,
    pinned,
    isProduct,
  } = props;

  const history = useHistory();

  const handleCompanyClick = () => {
    if (shoulNotBeClickable) {
      return;
    }

    if (isProduct) {
      history.push(
        `/companies/${company.company_slug || company.company_id}/products/${
          company.slug || company.id
        }`
      );
      return;
    }

    history.push(
      `/companies/${company.slug || company.id || company.company_id}`
    );
  };

  return (
    <div
      onClick={() => handleCompanyClick(company)}
      className={classNames(styles.listItemName, pinned && styles.pinned)}
    >
      <div className={styles.userInlineWrp}>
        <div
          style={customIconSize ? { width: customIconSize } : null}
          className={styles.userinlineImg}
        >
          <CompanyAvatar
            className={styles.logo}
            imgSrc={company.logo}
            name={company.name}
            hasBorderRadius
          />
        </div>
        <div className={customCompanyNameStyles || styles.userInlineName}>
          {companyName}
        </div>
      </div>
      {isProduct && <span className="small text-muted ml-4">Product</span>}
    </div>
  );
};

export default CompanyInline;

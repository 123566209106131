import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { CheckBox, CheckType } from "modules/primitives/index";
import styles from "./styles.module.scss";

const AgreementTypes = (props) => {
  const {
    initialAgreementType,
    handleSelection,
    checked,
    mode,
    setFieldValue,
    selectedCompanyAgreements,
    agreementTypeSelected,
    checkboxFromDetails,
    agreementIdFromOverview
  } = props;

  const [disabledCheckbox, setDisableCheckbox] = useState(false);
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);
  useState("");

  useEffect(() => {
    if (initialAgreementType) {
      selectedCompanyAgreements.forEach((checkedType) => {
        if (checkedType === initialAgreementType.id && mode !== "edit") {
          setDisableCheckbox(true);
        }
      });
      if (checkboxFromDetails || agreementIdFromOverview) {
        setCheckboxChecked(checked === initialAgreementType.id);
      }
    }
  }, [initialAgreementType]);

  useEffect(() => {
    if (!checkboxFromDetails && !agreementIdFromOverview) {
      setCheckboxChecked(agreementTypeSelected);
    }
  }, [agreementTypeSelected]);

  useEffect(() => {
    setCheckboxChecked(checked === initialAgreementType.id)
  }, [checked])
  return (
    <div>
      <div className={styles.agreementTypeContainer}>
        <div className={styles.checkItem} key={initialAgreementType?.id}>
          <CheckBox
            checkType={disabledCheckbox ? CheckType.DISABLED : CheckType.BLUE}
            isChecked={isCheckboxChecked}
            disabled={disabledCheckbox}
            readOnly={disabledCheckbox}
            onChange={() => {
              handleSelection(initialAgreementType);
              setFieldValue(
                "agreement_type_id",
                checked?.id === initialAgreementType.id
                  ? null
                  : initialAgreementType?.id
              );
            }}
          />

          <div
            className={classNames(
              disabledCheckbox ? styles.disabledCheckbox : styles.checkLabel
            )}
          >
            {initialAgreementType.name}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AgreementTypes;

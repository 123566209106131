import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import Icon from "common/icon/icon.component";
import MenuItem from "@mui/material/MenuItem";

import {
  model,
  validation,
  rolesInitialState,
  roles,
} from "./councils.constant";
import { ModalBody, ModalFooter } from "../../../application/modal";
import { useModelPopup } from "../../../common/hooks";
import {
  BtnType,
  Button,
  CheckBox,
  CheckType,
  DropSelect,
  Label,
  Loading,
  TextBox,
  UploadImgComponent,
} from "../../primitives/index";
import styles from "./styles.module.scss";
import {
  councilsEditAttempt,
  councilsGetByIdAttempt,
  councilsGetIndustriesAcrossAttempt,
  councilsGetTecnologiesAcrossAttempt,
  councilsRelStageGetByIdAttempt,
} from "./councils.action";
import {
  handleIndustryChange,
  handleTopicChange,
} from "../../admin/adminCompanies/editCompany/editCompany.helper";
import getTimezoneOffset from "./commons/getTimezonOffset";

const CouncilPage = () => {
  const location = useLocation();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    councilsReducer: {
      topics,
      industries,
      councilData,
      loadingCouncilData,
      councilSpecificRelStages,
    },
  } = useSelector((state) => state);

  const ref = useRef(null);
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [initModel, setInitModel] = useState(model);
  const [validationError, setValidationError] = useState({});
  const [files, setFiles] = useState([]);
  const [active, setActive] = useState(false);
  const [themesConnected, setThemesConnected] = useState(false);
  const [themesHomePageConnected, setThemesHomePageConnected] = useState(false);
  const [councilId, setCouncilId] = useState("");

  const [industryIds, setIndustryIds] = useState([]);
  const [technologyIds, setTechnoloyIds] = useState([]);
  const [selectedTechnologiesForSearch, setSelectedTechnologiesForSearch] =
    useState([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const [technologySearchValue, setTechnologySearchValue] = useState([]);
  const [topic, setTopic] = useState([]);
  const [topicOnEdit, setTopicOnEdit] = useState([]);

  const [selectedIndustriesForSearch, setSelectedIndustriesForSearch] =
    useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [industrySearchValue, setIndustrySearchValue] = useState("");
  const [industry, setIndustry] = useState([]);
  const [industryOnEdit, setIndustryOnEdit] = useState([]);
  const [defaultComRelState, setDefaultComRelState] = useState("");
  const [defaultBulkRelState, setDefaultBulkRelState] = useState("");
  const [editableDefaultComRelState, setEditableDefaultComRelState] =
    useState(false);
  const [editableDefaultBulkRelState, setEditableDefaultBulkRelState] =
    useState(false);
  const [relStatuses, setRelStatusesArr] = useState([]);

  const [tractionAiConnected, setTractionAiConnected] = useState({
    mainTractionAi: false,
    companiesAi: false,
    reportsAi: false,
    recommendAi: false,
  });
  const [innovFunnelConnected, setInnovFunnelConnected] = useState(false);
  const [themeCarouselConnected, setThemeCarouselConnected] = useState(false);
  const [themePostsConnected, setThemePostsConnected] = useState(false);
  const [homePageEventsConnected, setHomePageEventsConnected] = useState(false);
  const [homePageDiscussionsConnected, setHomePageDiscussionsConnected] =
    useState(false);
  const [homePageCompaniesConnected, setHomePageCompaniesConnected] =
    useState(false);
  const [homePageProjectsConnected, setHomePageProjectsConnected] =
    useState(false);
  const [eventsConnected, handleEventsConnected] = useState(false);
  const [reportsConnected, handleReportsConnected] = useState(false);
  const [ideasConnected, handleIdeasConnected] = useState(false);
  const [solutionsConnected, handleSolutionsConnected] = useState(false);
  const [ideasRoleConnected, handleIdeasRoleConnected] =
    useState(rolesInitialState);
  const [themesRoleConnected, handleThemesRoleConnected] =
    useState(rolesInitialState);
  const [populated, setPopulated] = useState(false);
  const selectAllRoles = (stateChangeFunc) => {
    stateChangeFunc({
      all: true,
      council_administrator: true,
      standard: true,
      viewer: true,
      company: false,
    });
  };
  const [reportsRoleConnected, handleReportsRoleConnected] =
    useState(rolesInitialState);

  const dropdownRenderer = useCallback(
    ({ props }) => (
      <div className="d-flex flex-column react-dropdown-select-dropdown">
        {selectedTechnologies?.length
          ? selectedTechnologies.map((item, idx) => {
              const isSelected = topic.find((el) => el.id === item.id);

              return (
                <span
                  onClick={() => {
                    handleTopicChange({
                      val: item,
                      topic,
                      setTopic,
                      setSearchValue: setTechnologySearchValue,
                    });
                  }}
                  key={`${item.name}-${idx}`}
                  className={`react-dropdown-select-item 
                            ${isSelected && styles.selectedItemDrop}`}
                >
                  {item[props.labelField]}
                </span>
              );
            })
          : "No data"}
      </div>
    ),
    [selectedTechnologies, topic]
  );

  const IndustryDropdownRenderer = useCallback(
    ({ props }) => (
      <div className="d-flex flex-column react-dropdown-select-dropdown">
        {selectedIndustries?.length
          ? selectedIndustries.map((item, idx) => {
              const isSelected = industry.find((el) => el.id === item.id);
              return (
                <span
                  onClick={() => {
                    handleIndustryChange({
                      val: item,
                      industry,
                      setIndustry,
                      setIndustrySearchValue,
                    });
                  }}
                  key={`${item.name}-${idx}`}
                  className={`react-dropdown-select-item 
                            ${isSelected && styles.selectedItemDrop}`}
                >
                  {item[props.labelField]}
                </span>
              );
            })
          : "No data"}
      </div>
    ),
    [selectedIndustries, industry]
  );

  const industryContentRenderer = useCallback(
    () => (
      <div className="d-flex flex-wrap  react-dropdown-select-dropdown">
        {Boolean(industry?.length) &&
          industry.map((item, idx) => (
            <div
              key={`${item?.name}-${idx}`}
              className={styles.dropdownSelected}
            >
              <span>{item?.name}</span>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleIndustryChange({
                    val: item,
                    industry,
                    setIndustry,
                    setIndustrySearchValue,
                  });
                }}
                className={styles.deleteLetter}
              >
                ✕
              </span>
            </div>
          ))}
        <input
          placeholder={!topic.length ? "Select..." : ""}
          className={styles.dropSelectInput}
          onChange={(e) => setIndustrySearchValue(e.target.value)}
          value={industrySearchValue}
        />
      </div>
    ),
    [industry, industrySearchValue]
  );
  const contentRenderer = useCallback(
    () => (
      <div className="d-flex flex-wrap  react-dropdown-select-dropdown">
        {Boolean(topic?.length) &&
          topic.map((item, idx) => (
            <div
              key={`${item?.name}-${idx}`}
              className={styles.dropdownSelected}
            >
              <span>{item?.name}</span>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  handleTopicChange({
                    val: item,
                    topic,
                    setTopic,
                    setSearchValue: setTechnologySearchValue,
                  });
                }}
                className={styles.deleteLetter}
              >
                ✕
              </span>
            </div>
          ))}
        <input
          value={technologySearchValue}
          placeholder={!topic.length ? "Select..." : ""}
          className={styles.dropSelectInput}
          onChange={(e) => {
            setTechnologySearchValue(e.target.value);
          }}
        />
      </div>
    ),
    [topic, technologySearchValue]
  );

  const searchDropDownValue = (
    dataForFilter,
    searchValue,
    type = "industry"
  ) => {
    if (dataForFilter?.length) {
      const filteredData = dataForFilter.filter((el) => {
        if (searchValue === "") {
          return el;
        }

        return el?.name?.toLowerCase().includes(searchValue?.toLowerCase());
      });
      if (type === "technology") {
        setSelectedTechnologies(filteredData);
      } else {
        setSelectedIndustries(filteredData);
      }
    }
  };

  const handleImgUpdate = (url, name) => {
    if (url) {
      setFiles((prev) => {
        return [
          ...prev,
          {
            name,
            document_resource_type: "Council",
            added_by_id: session?.id,
            council_id: selectedCouncil?.id,
            uri: url,
          },
        ];
      });
    } else if (url === null) {
      setFiles([null]);
    }
  };
  const handleDeleteTechnology = (id) => {
    const filtredTechnologies = topicOnEdit.filter(
      (topicItem) => topicItem.id !== id
    );

    setTopicOnEdit(filtredTechnologies);
  };

  const handleDeleteIndustry = (id) => {
    const filtredIndustries = industryOnEdit.filter(
      (industryItem) => industryItem.id !== id
    );

    setIndustryOnEdit(filtredIndustries);
  };

  const handleSubmitButton = (values) => {
    if (!values.name?.length) {
      setValidationError({
        text: "Name is required field",
        type: "emptyTitle",
      });
      return;
    }
    if (!files[files.length - 1]) {
      setValidationError({
        text: "Logo is required field",
        type: "emptyLogo",
      });
      return;
    }
    const ideasRoleConnectedArr = Object.keys(ideasRoleConnected).filter(
      (key) => ideasRoleConnected[key]
    );
    const reportsRoleConnectedArr = Object.keys(reportsRoleConnected).filter(
      (key) => reportsRoleConnected[key]
    );

    const themesRoleConnectedArr = Object.keys(themesRoleConnected).filter(
      (key) => themesRoleConnected[key]
    );
    if (ideasConnected && !ideasRoleConnectedArr.length) {
      setValidationError({
        text: "At least one option is required",
        type: "emptyIdeasRoles",
      });
      return;
    }

    if (reportsConnected && !reportsRoleConnectedArr.length) {
      setValidationError({
        text: "At least one option is required",
        type: "emptyReportsRoles",
      });
      return;
    }

    if (themesConnected && !themesRoleConnectedArr.length) {
      setValidationError({
        text: "At least one option is required",
        type: "emptyThemesRoles",
      });
      return;
    }

    let industry_ids = industry.map(
      (industryItem) => industryItem.id && industryItem.id
    );
    industry_ids = [
      ...industry_ids,
      ...industryOnEdit.map(
        (industryItem) => industryItem.id && industryItem.id
      ),
    ];

    let topic_ids = topic.map((topicItem) => topicItem.id && topicItem.id);
    topic_ids = [
      ...topic_ids,
      ...topicOnEdit.map((topicItem) => topicItem.id && topicItem.id),
    ];
    const council = {
      name: values.name,
      logo: files[files.length - 1]?.uri || "",
      traction_tag: values?.name?.toLowerCase().replaceAll(" ", "_"),
      themes_connected: themesConnected,
      themes_homepage_connected: themesHomePageConnected,
      settings: {
        ...councilData?.settings,
        populated,
        innov_funnel_connected: innovFunnelConnected,
        traction_ai_connected: tractionAiConnected.mainTractionAi,
        traction_ai_connected_companies: tractionAiConnected.companiesAi,
        traction_ai_connected_reports: tractionAiConnected.reportsAi,
        traction_ai_connected_recommend: tractionAiConnected.recommendAi,
        home_page_events_connected: homePageEventsConnected,
        home_page_discussions_connected: homePageDiscussionsConnected,
        home_page_companies_connected: homePageCompaniesConnected,
        home_page_projects_connected: homePageProjectsConnected,
        home_page_theme_posts_connected: themePostsConnected,
        home_page_theme_carousel_connected: themeCarouselConnected,
        reports_connected: reportsConnected,
        events_connected: eventsConnected,
        ideas_connected: ideasConnected,
        ideas_allowed_roles: ideasConnected
          ? Object.keys(ideasRoleConnected).filter(
              (key) => ideasRoleConnected[key]
            )
          : [],
        reports_allowed_roles: reportsConnected
          ? Object.keys(reportsRoleConnected).filter(
              (key) => reportsRoleConnected[key]
            )
          : [],
        solutions_connected: solutionsConnected,
        themes_connected: themesConnected,
        themes_allowed_roles: themesConnected
          ? Object.keys(themesRoleConnected).filter(
              (key) => themesRoleConnected[key]
            )
          : [],
      },
      active,
      time_zone: getTimezoneOffset(),
      topic_ids,
      industry_ids,
      default_bulk_council_relationship_stage_id:
        defaultBulkRelState !== "-" ? defaultBulkRelState : null,
      default_council_relationship_stage_id:
        defaultComRelState !== "-" ? defaultComRelState : null,
    };

    council.id = councilData?.id;
    setEditableDefaultComRelState(false);
    setEditableDefaultBulkRelState(false);
    dispatch(councilsEditAttempt({ council, id: council.id, enqueueSnackbar }));
    setTopic([]);
    setIndustry([]);

    popup.hide();
  };

  const setCompanyDefaultRelState = (opt) => {
    // setEditableDefaultComRelState(false);
    if (opt.val === "-") {
      setDefaultComRelState("-");
      return;
    }
    setDefaultComRelState(opt.val);
  };

  const setBulkDefaultRelState = (opt) => {
    if (opt.val === "-") {
      setDefaultBulkRelState("-");
      return;
    }
    setDefaultBulkRelState(opt.val);
  };

  useEffect(() => {
    if (!eventsConnected) {
      setHomePageEventsConnected(false);
    }
  }, [eventsConnected]);

  useEffect(() => {
    if (!themesConnected) {
      setThemesHomePageConnected(false);
    }
  }, [themesConnected]);

  useEffect(() => {
    if (councilData?.settings) {
      setInitModel({
        ...model,
        name: councilData.name,
        avatar: councilData.logo,
      });

      setFiles([{ uri: councilData?.logo }]);
      setActive(councilData.active);
      setThemesConnected(councilData.themes_connected);
      setThemesHomePageConnected(councilData.themes_homepage_connected);
      setTractionAiConnected({
        mainTractionAi: councilData?.settings?.traction_ai_connected,
        companiesAi: councilData?.settings?.traction_ai_connected_companies,
        reportsAi: councilData?.settings?.traction_ai_connected_reports,
        recommendAi: councilData?.settings?.traction_ai_connected_recommend,
      });
      setInnovFunnelConnected(councilData?.settings?.innov_funnel_connected);
      setPopulated(councilData?.settings?.populated);
      setThemeCarouselConnected(
        councilData?.settings?.home_page_theme_carousel_connected
      );
      setThemePostsConnected(
        councilData?.settings?.home_page_theme_posts_connected
      );
      setHomePageEventsConnected(
        councilData?.settings?.home_page_events_connected
      );
      setHomePageDiscussionsConnected(
        councilData?.settings?.home_page_discussions_connected
      );
      setHomePageCompaniesConnected(
        councilData?.settings?.home_page_companies_connected
      );
      setHomePageProjectsConnected(
        councilData?.settings?.home_page_projects_connected
      );
      handleReportsConnected(councilData?.settings?.reports_connected);
      handleEventsConnected(councilData?.settings?.events_connected);
      handleIdeasConnected(councilData?.settings?.ideas_connected);
      handleSolutionsConnected(councilData?.settings?.solutions_connected);
      const technologiesOnEdit = councilData.resource_topics.map(
        ({ topic }) => ({
          name: topic?.name,
          id: topic?.id,
        })
      );
      const industriesOnEdit = councilData.resource_industries.map(
        ({ industry }) => ({
          name: industry?.name,
          id: industry?.id,
        })
      );

      setTopicOnEdit(technologiesOnEdit.length ? technologiesOnEdit : []);
      setIndustryOnEdit(industriesOnEdit.length ? industriesOnEdit : []);

      setDefaultComRelState(
        councilData.default_council_relationship_stage_id === null
          ? "-"
          : councilData.default_council_relationship_stage_id
      );
      setDefaultBulkRelState(
        councilData.default_bulk_council_relationship_stage_id === null
          ? "-"
          : councilData.default_bulk_council_relationship_stage_id
      );
      const prepareRolesArr = (key) => {
        const arr =
          councilData?.settings[key]
            ?.map((role) => ({ [role]: true }))
            .flat() || [];
        return Object.assign({}, ...arr);
      };

      handleIdeasRoleConnected(prepareRolesArr("ideas_allowed_roles"));
      handleThemesRoleConnected(prepareRolesArr("themes_allowed_roles"));
      handleReportsRoleConnected(prepareRolesArr("reports_allowed_roles"));
    }
  }, [councilData]);

  useEffect(() => {
    const filtredTechnologyOptions = topics.filter(
      (technology) => !topicOnEdit.some((topic) => topic.id === technology.id)
    );

    setSelectedTechnologies(filtredTechnologyOptions);
  }, [topics, topicOnEdit]);

  useEffect(() => {
    const filtredIndustriesOptions = industries.filter(
      (industry) =>
        !industryOnEdit.some(
          (industryOnEdit) => industryOnEdit.id === industry.id
        )
    );
    setSelectedIndustries(filtredIndustriesOptions);
  }, [industries, industryOnEdit]);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        councilsGetIndustriesAcrossAttempt({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
      dispatch(
        councilsGetTecnologiesAcrossAttempt({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil]);
  useEffect(() => {
    if (
      ref.current &&
      validationError.type === "emptyTitle" &&
      validationError.type === "emptyLogo"
    ) {
      ref.current.scrollIntoView();
    }
  }, [validationError?.text]);

  useEffect(() => {
    if (councilId) {
      dispatch(councilsGetByIdAttempt({ id: councilId, enqueueSnackbar }));
      dispatch(
        councilsRelStageGetByIdAttempt({
          id: councilId,
          enqueueSnackbar,
        })
      );
    }
  }, [councilId]);

  useEffect(() => {
    const filtredTechnologyOptions = topics.filter(
      (technology) => !topicOnEdit.some((topic) => topic.id === technology.id)
    );

    setSelectedTechnologies(filtredTechnologyOptions);
    setSelectedTechnologiesForSearch(filtredTechnologyOptions);
  }, [topics]);

  useEffect(() => {
    const filtredIndustriesOptions = industries.filter(
      (industry) =>
        !industryOnEdit.some(
          (industryOnEdit) => industryOnEdit.id === industry.id
        )
    );
    setSelectedIndustries(filtredIndustriesOptions);
    setSelectedIndustriesForSearch(filtredIndustriesOptions);
  }, [industries]);

  useEffect(() => {
    if (selectedTechnologiesForSearch?.length) {
      searchDropDownValue(
        selectedTechnologiesForSearch,
        technologySearchValue,
        "technology"
      );
    }
  }, [technologySearchValue]);

  useEffect(() => {
    if (selectedIndustriesForSearch?.length) {
      searchDropDownValue(selectedIndustriesForSearch, industrySearchValue);
    }
  }, [industrySearchValue]);

  useEffect(() => {
    const splittedByUrl = location.pathname.split("/");
    const id = splittedByUrl[splittedByUrl.length - 1];
    setCouncilId(id);
  }, []);

  useEffect(() => {
    if (councilSpecificRelStages[0]) {
      setRelStatusesArr([
        {
          id: "-",
          name: "Traction Match",
          slug: "traction_match",
        },
        ...councilSpecificRelStages,
      ]);
    }
  }, [councilSpecificRelStages]);

  const selectedCompanyStatus = () => {
    return relStatuses.find((el) => el.id === defaultComRelState);
  };

  const selesedBulk = () => {
    return relStatuses.find((el) => el.id === defaultBulkRelState);
  };

  const handleThemesConnected = () => {
    if (!themesConnected === true && themesHomePageConnected) {
      setThemeCarouselConnected(true);
      setThemePostsConnected(true);
    } else {
      setThemeCarouselConnected(false);
      setThemePostsConnected(false);
    }

    setThemesConnected(!themesConnected);
  };

  const handleThemeHomepageConnected = () => {
    if (!themesHomePageConnected === true && themesConnected) {
      setThemeCarouselConnected(true);
      setThemePostsConnected(true);
    } else {
      setThemeCarouselConnected(false);
      setThemePostsConnected(false);
    }

    setThemesHomePageConnected(!themesHomePageConnected);
  };

  const handleThemeHomepageElementConnected = (type) => {
    if (!themePostsConnected || !themeCarouselConnected) {
      setThemesHomePageConnected(true);
      setThemesConnected(true);
    }
    if (type === "posts") {
      setThemePostsConnected(!themePostsConnected);
    } else {
      setThemeCarouselConnected(!themeCarouselConnected);

      // setThemeCarouselConnected(false);
      // setThemePostsConnected(false);
    }
  };

  if (loadingCouncilData) {
    return <Loading />;
  }
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initModel}
        validaitonSchema={validation}
        onSubmit={(values) => {
          handleSubmitButton(values);
        }}
      >
        {({ values, setFieldValue, handleSubmit, ...formikprops }) => (
          <form onSubmit={handleSubmit} className={styles.councilStyles}>
            {" "}
            <ModalBody>
              <div ref={ref} className={styles.row}>
                <Label>Council Name</Label>
                <TextBox
                  type="text"
                  name="name"
                  className={styles.textBox}
                  formProps={{ ...formikprops, values, setFieldValue }}
                />
                {validationError.type === "emptyTitle" &&
                  !values?.name?.length && (
                    <span className={styles.errorMessage}>
                      {validationError.text}
                    </span>
                  )}
              </div>
              <div className={styles.row}>
                <Label>Traction Tag</Label>
                <TextBox
                  type="text"
                  name="traction_tag"
                  className={styles.textBox}
                  disabled
                  value={values?.name?.toLowerCase().replaceAll(" ", "_")}
                  formProps={{ ...formikprops, values, setFieldValue }}
                />
              </div>
              <div className={classNames(styles.row, styles.uploadImgBlock)}>
                <input type="hidden" value="testing" name="externalMember" />
                <Label>Upload Logo</Label>
                <UploadImgComponent
                  name="avatar"
                  formProps={{ ...formikprops, values, setFieldValue }}
                  uploadDirName={`councils/${values?.name}/key`}
                  onChange={(e) => {
                    handleImgUpdate(e);
                    setFieldValue("avatar", e);
                  }}
                />
                {validationError.type === "emptyLogo" &&
                  !files[files.length - 1] && (
                    <span className={styles.errorMessage}>
                      {validationError.text}
                    </span>
                  )}
              </div>
              <div className={styles.row}>
                <div className={styles.activeCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="active"
                    isChecked={active}
                    onChange={() => setActive(!active)}
                  />
                  Active(Yes/No)
                </div>
              </div>
              <div className={styles.row}>Homepage Options</div>
              <div className={styles.row}>
                Control the modules displayed on the homepage by turning them on
                or off below:
              </div>
              <div className={styles.row}>Standard Homepage:</div>
              <br />
              <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="tractionAiConnected.mainTractionAi"
                    isChecked={tractionAiConnected.mainTractionAi}
                    onChange={() => {
                      const newVal = !tractionAiConnected.mainTractionAi;

                      setTractionAiConnected({
                        ...tractionAiConnected,
                        mainTractionAi: newVal,
                        companiesAi: newVal,
                        reportsAi: newVal,
                        recommendAi: newVal,
                      });
                    }}
                  />
                  Traction AI
                </div>
                {tractionAiConnected.mainTractionAi && (
                  <div className="ml-5">
                    <div className={styles.themesCheckbox}>
                      <CheckBox
                        checkType={CheckType.BLUE}
                        formProps={{ ...formikprops, values, setFieldValue }}
                        name="tractionAiConnected.companiesAi"
                        isChecked={tractionAiConnected.companiesAi}
                        onChange={() =>
                          setTractionAiConnected({
                            ...tractionAiConnected,
                            companiesAi: !tractionAiConnected.companiesAi,
                          })
                        }
                      />
                      Companies
                    </div>
                    <div className={styles.themesCheckbox}>
                      <CheckBox
                        checkType={CheckType.BLUE}
                        formProps={{ ...formikprops, values, setFieldValue }}
                        name="tractionAiConnected.reportsAi"
                        isChecked={tractionAiConnected.reportsAi}
                        onChange={() =>
                          setTractionAiConnected({
                            ...tractionAiConnected,
                            reportsAi: !tractionAiConnected.reportsAi,
                          })
                        }
                      />
                      Reports
                    </div>
                    <div className={styles.themesCheckbox}>
                      <CheckBox
                        checkType={CheckType.BLUE}
                        formProps={{ ...formikprops, values, setFieldValue }}
                        name="tractionAiConnected.recommendAi"
                        isChecked={tractionAiConnected.recommendAi}
                        onChange={() =>
                          setTractionAiConnected({
                            ...tractionAiConnected,
                            recommendAi: !tractionAiConnected.recommendAi,
                          })
                        }
                      />
                      Projects + Ideas
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="innovFunnelConnected"
                    isChecked={innovFunnelConnected}
                    onChange={() =>
                      setInnovFunnelConnected(!innovFunnelConnected)
                    }
                  />
                  Innovation Funnel
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="homePageProjectsConnected"
                    isChecked={homePageProjectsConnected}
                    onChange={() =>
                      setHomePageProjectsConnected(!homePageProjectsConnected)
                    }
                  />
                  My Projects
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="homePageCompaniesConnected"
                    isChecked={homePageCompaniesConnected}
                    onChange={() =>
                      setHomePageCompaniesConnected(!homePageCompaniesConnected)
                    }
                  />
                  My Companies
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="homePageDiscussionsConnected"
                    isChecked={homePageDiscussionsConnected}
                    onChange={() =>
                      setHomePageDiscussionsConnected(
                        !homePageDiscussionsConnected
                      )
                    }
                  />
                  Discussions
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="homePageEventsConnected"
                    isChecked={homePageEventsConnected}
                    onChange={() =>
                      setHomePageEventsConnected(!homePageEventsConnected)
                    }
                  />
                  Upcoming Events
                </div>
              </div>

              <div className={styles.row}>
                {selectedCouncil?.settings?.themes_display_name || "Themes"}{" "}
                Homepage:
              </div>
              <br />

              <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="active"
                    isChecked={themesHomePageConnected}
                    onChange={() => handleThemeHomepageConnected()}
                  />
                  {selectedCouncil?.settings?.themes_display_name || "Themes"}{" "}
                  Home Page Connected(On/Off)
                </div>
              </div>
              <div className={styles.row}>
                <div className={classNames(styles.themesCheckbox, "ml-5")}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="themeCarouselConnected"
                    isChecked={themeCarouselConnected}
                    onChange={() =>
                      handleThemeHomepageElementConnected("carousel")
                    }
                  />
                  {selectedCouncil?.settings?.themes_display_name || "Themes"}{" "}
                  Carousel
                </div>
              </div>

              <div className={styles.row}>
                <div className={classNames(styles.themesCheckbox, "ml-5")}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="themePostsConnected"
                    isChecked={themePostsConnected}
                    onChange={() =>
                      handleThemeHomepageElementConnected("posts")
                    }
                  />
                  {selectedCouncil?.settings?.themes_display_name || "Themes"}{" "}
                  Posts
                </div>
              </div>

              <div className={styles.row}>Navigation Options:</div>
              <br />
              <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="reports"
                    isChecked={reportsConnected}
                    onChange={() => {
                      if (reportsConnected === false) {
                        handleReportsConnected((prev) => {
                          return rolesInitialState;
                        });
                      }
                      handleReportsConnected(!reportsConnected);
                    }}
                  />
                  Reports(On/Off)
                </div>
                {validationError.type === "emptyReportsRoles" && (
                  <span className={styles.errorMessage}>
                    {validationError.text}
                  </span>
                )}
              </div>
              {reportsConnected && (
                <div className={styles.row}>
                  {roles.map((item) => {
                    return (
                      <div
                        className={classNames(styles.themesCheckbox, "ml-5")}
                      >
                        <CheckBox
                          checkType={CheckType.BLUE}
                          formProps={{ ...formikprops, values, setFieldValue }}
                          name={`reports_${item.role}`}
                          isChecked={reportsRoleConnected[item.role]}
                          onChange={(e) => {
                            setValidationError({});
                            if (item.role === "all" && e) {
                              selectAllRoles(handleReportsRoleConnected);
                            } else {
                              handleReportsRoleConnected((prev) => {
                                return {
                                  ...prev,
                                  [item.role]: !prev[item.role],
                                };
                              });
                            }
                          }}
                        />
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              )}
              <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="events"
                    isChecked={eventsConnected}
                    onChange={() => handleEventsConnected(!eventsConnected)}
                  />
                  Events(On/Off)
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="ideas"
                    isChecked={ideasConnected}
                    onChange={() => {
                      if (ideasConnected === false) {
                        handleIdeasRoleConnected((prev) => {
                          return rolesInitialState;
                        });
                      }
                      handleIdeasConnected(!ideasConnected);
                    }}
                  />
                  Ideas(On/Off)
                </div>
                {validationError.type === "emptyIdeasRoles" && (
                  <span className={styles.errorMessage}>
                    {validationError.text}
                  </span>
                )}
              </div>
              {ideasConnected && (
                <div className={styles.row}>
                  {roles.map((item) => {
                    return (
                      <div
                        className={classNames(styles.themesCheckbox, "ml-5")}
                      >
                        <CheckBox
                          checkType={CheckType.BLUE}
                          formProps={{ ...formikprops, values, setFieldValue }}
                          name={`ideas_${item.role}`}
                          isChecked={ideasRoleConnected[item.role]}
                          onChange={(e) => {
                            setValidationError({});
                            if (item.role === "all" && e) {
                              selectAllRoles(handleIdeasRoleConnected);
                            } else {
                              handleIdeasRoleConnected((prev) => {
                                return {
                                  ...prev,
                                  [item.role]: !prev[item.role],
                                };
                              });
                            }
                          }}
                        />
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              )}
              <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="themes"
                    isChecked={themesConnected}
                    onChange={() => {
                      if (themesConnected === false) {
                        handleThemesRoleConnected((prev) => {
                          return rolesInitialState;
                        });
                      }
                      handleThemesConnected(!themesConnected);
                    }}
                  />
                  {selectedCouncil?.settings?.themes_display_name || "Themes"}
                  (On/Off)
                </div>

                {validationError.type === "emptyThemesRoles" && (
                  <span className={styles.errorMessage}>
                    {validationError.text}
                  </span>
                )}
              </div>
              {themesConnected && (
                <div className={styles.row}>
                  {roles.map((item) => {
                    return (
                      <div
                        className={classNames(styles.themesCheckbox, "ml-5")}
                      >
                        <CheckBox
                          checkType={CheckType.BLUE}
                          formProps={{ ...formikprops, values, setFieldValue }}
                          name={`themes_${item.role}`}
                          isChecked={themesRoleConnected[item.role]}
                          onChange={(e) => {
                            setValidationError({});
                            if (item.role === "all" && e) {
                              selectAllRoles(handleThemesRoleConnected);
                            } else {
                              handleThemesRoleConnected((prev) => {
                                return {
                                  ...prev,
                                  all:
                                    !e && item.role !== "company"
                                      ? false
                                      : prev.all,
                                  [item.role]: !prev[item.role],
                                };
                              });
                            }
                          }}
                        />
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              )}
              <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values, setFieldValue }}
                    name="solutions"
                    isChecked={solutionsConnected}
                    onChange={() =>
                      handleSolutionsConnected(!solutionsConnected)
                    }
                  />
                  Solutions(On/Off)
                </div>
              </div>
              <div className={styles.relsBlock}>
                <div className={styles.tagsTitle}>
                  {" "}
                  Default Stage for Add Company:
                </div>
                <div className={styles.selectDrops}>
                  <div className="col-12">
                    {editableDefaultComRelState ? (
                      <div className={styles.dropSel}>
                        <DropSelect
                          SelecedValues={defaultComRelState || "-"}
                          onChange={(ev) => {
                            setCompanyDefaultRelState(ev);
                          }}
                          placeholder="Please select default stage"
                          name="stage"
                          labelField="name"
                          valueField="id"
                          searchBy="name"
                          options={relStatuses}
                        />
                      </div>
                    ) : (
                      <div className="row">
                        <MenuItem
                          className={styles.detailsRelationship}
                          style={{
                            backgroundColor:
                              relStatuses.find(
                                (el) => el.id === defaultComRelState
                              )?.colors?.background || "",
                          }}
                        >
                          {`${selectedCompanyStatus()?.name || " "}`}{" "}
                        </MenuItem>
                        <Button
                          btn={BtnType.FRAME_LESS}
                          onClick={() => {
                            setEditableDefaultComRelState(true);
                          }}
                        >
                          Change
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.relsBlock}>
                <div className={styles.tagsTitle}>
                  {" "}
                  Default Stage for Bulk Uploads:
                </div>
                <div className={styles.selectDrops}>
                  <div className="col-12">
                    {editableDefaultBulkRelState ? (
                      <div className={styles.dropSel}>
                        <DropSelect
                          SelecedValues={defaultBulkRelState || "-"}
                          onChange={(ev) => {
                            if (ev.val === defaultBulkRelState) {
                              return;
                            }
                            setBulkDefaultRelState(ev);
                          }}
                          placeholder="Please select default stage"
                          name="stageBulk"
                          labelField="name"
                          valueField="id"
                          searchBy="name"
                          options={relStatuses}
                          className={styles.dropSel}
                        />
                      </div>
                    ) : (
                      <div className="row">
                        <MenuItem
                          className={styles.detailsRelationship}
                          style={{
                            backgroundColor:
                              relStatuses.find(
                                (el) => el.id === defaultBulkRelState
                              )?.colors?.background || "",
                          }}
                        >
                          {`${selesedBulk()?.name || " "}`}{" "}
                        </MenuItem>
                        <Button
                          btn={BtnType.FRAME_LESS}
                          onClick={() => {
                            setEditableDefaultBulkRelState(true);
                          }}
                        >
                          Change
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.relsBlock}>
                <div className={styles.tagsTitle}>
                  Auto Populate Council with Companies:
                </div>
                <div className={styles.selectDrops}>
                  <div className="col-12 col-md-2">
                    <Button
                      type="button"
                      btn={
                        populated || councilData?.populated
                          ? BtnType.TAG_BLUE_LIGHT
                          : BtnType.REGULAR
                      }
                      disabled={populated || councilData?.populated}
                      readonly={populated || councilData?.populated}
                      onClick={() => {
                        setPopulated(true);
                      }}
                    >
                      {populated || councilData?.populated
                        ? "Auto populate Enabled"
                        : "Auto populate"}
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.tagsBlock}>
                <div className={styles.tagsTitle}>Tags:</div>
                <div className={styles.selectDrops}>
                  <div className="col-12">
                    <Label>Technology</Label>
                    <DropSelect
                      multi
                      onChange={(e) => {
                        setTechnoloyIds([
                          ...technologyIds,
                          e.obj[e.obj.length - 1]?.id,
                        ]);
                      }}
                      className={styles.dropWrapper}
                      labelField="name"
                      valueField="id"
                      name="technologies"
                      values={topic}
                      options={selectedTechnologies}
                      contentRenderer={(innerProps, innerState, innerMethods) =>
                        contentRenderer(innerProps, innerState, innerMethods)
                      }
                      dropdownRenderer={(
                        innerProps,
                        innerState,
                        innerMethods
                      ) =>
                        dropdownRenderer(innerProps, innerState, innerMethods)
                      }
                    />
                  </div>
                  {topicOnEdit.map((topicItem) => (
                    <div className={classNames("col-12", styles.tagsRow)}>
                      <div className={styles.tagLabel}>{topicItem?.name}</div>
                      <Icon
                        size={16}
                        icon="icn-button-delete"
                        onClick={() => {
                          handleDeleteTechnology(topicItem?.id);
                        }}
                        className={styles.deleteIcon}
                      />
                    </div>
                  ))}
                  <div className="col-12">
                    <Label>Industries</Label>
                    <DropSelect
                      className={styles.dropWrapper}
                      multi
                      onChange={(e) => {
                        setIndustryIds([
                          ...industryIds,
                          e.obj[e.obj.length - 1]?.id,
                        ]);
                      }}
                      labelField="name"
                      valueField="id"
                      name="industries"
                      values={industry}
                      options={selectedIndustries}
                      contentRenderer={(innerProps, innerState, innerMethods) =>
                        industryContentRenderer(
                          innerProps,
                          innerState,
                          innerMethods
                        )
                      }
                      dropdownRenderer={(
                        innerProps,
                        innerState,
                        innerMethods
                      ) =>
                        IndustryDropdownRenderer(
                          innerProps,
                          innerState,
                          innerMethods
                        )
                      }
                    />
                  </div>
                  {industryOnEdit.map((industryItem) => (
                    <div className={classNames("col-12", styles.tagsRow)}>
                      <div className={styles.tagLabel}>
                        {industryItem?.name}
                      </div>
                      <Icon
                        size={16}
                        onClick={() => {
                          handleDeleteIndustry(industryItem.id);
                        }}
                        icon="icn-button-delete"
                        className={styles.deleteIcon}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className={styles.footerButtons}>
                <Button btn={BtnType.FRAME_LESS} onClick={popup.hide}>
                  Cancel
                </Button>
                <Button type="submit" btn={BtnType.REGULAR}>
                  Save
                </Button>
              </div>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default CouncilPage;

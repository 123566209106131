import * as Yup from "yup";

const initModel = {
  name: '',
  description: '',
  position: 1,
  projects_wizard_options: [],
};

const validation = Yup.object().shape({
  name: Yup.string().required("Step name is required"),
  position: Yup.number().required("Step position is required"),
  projects_wizard_options: Yup.array()
    .min(1, "At least one project wizard option is required")
    .required("This field is required"),
});

export { validation, initModel };

import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";

import { ModalFooter, ModalBody } from "../../../../../application/modal";
import { appModelSettingSet } from "application/app.action";
import {
  Button,
  BtnType,
  MultiSelect,
  DropSelect,
  Label,
} from "../../../../primitives";
import { councilUsersListGet } from "../../../../../common/actions/common.action";
import initModel from "./shareWith.constant";
import { shareCompany } from "../../company.action";
import styles from "./shareWith.module.scss";
import { eventShare } from "modules/events/viewEvent/viewEvent.action";
import { patientOrganizationShare } from "modules/patientOrganizations/patientOrganization/patientOrganization.action";
import UserMultiSelectSearch from "../../../../../common/components/usersSearch";
import { CheckBox, CheckType } from "modules/primitives/index";
import { getUserTeams } from "modules/teams/teams.action";
import {
  companyListShareWithMember,
  companyListShareRemoveMember,
} from "modules/lists/store/lists.actions";

const ShareWithComponent = (props) => {
  const dispatch = useDispatch();
  const [members, setMembers] = useState([]);
  const [role, setRole] = useState("viewer");
  const [selectAll, setSelectAll] = useState(false);
  const [readyToSave, setReadyToSave] = useState(false);
  const [teamOptions, setTeamOptions] = useState([]);
  const [teamSelectedFlag, setTeamSelectedFlag] = useState(false);
  const [showError, setShowError] = useState(false);
  const formRef = useRef();
  const {
    selectedList,
    selectedCompany,
    actionType,
    selectedEvent,
    currentUserRole,
    // fromLists,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil },
    appReducer: { modalSetting },
    teamsReducer: { userTeams },
  } = useSelector((state) => state);

  const roleOptions = [
    { role: "viewer", optionName: "Viewer" },
    { role: "editor", optionName: "Editor" },
  ];

  const handleCancelClick = (e) => {
    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
  };

  const handleSaveClick = (e) => {
    const userIds = members.map((item) => item.user_id || item.id);
    const teamIds = teamOptions
      .map((team) => {
        if (team.selected) {
          return team.id;
        }
      })
      .filter((e) => e);

    if (members.length < 1 && !teamSelectedFlag && !selectAll) {
      setShowError(true);

      return 0;
    }
    const listData = {
      enqueueSnackbar,
      listId: selectedList,
      data: {
        role: currentUserRole === "viewer" ? "viewer" : role,
        ...(!selectAll && {
          user_ids: userIds.length ? userIds : undefined,
          team_ids: teamIds.length ? teamIds : undefined,
        }),
        ...(selectAll && { scope: "council" }),
      },
    };

    const removeFromListData = { role, ...listData };
    const shareData = {
      id:
        actionType === "shareCompany" ||
        actionType === "sharePatientOrganization"
          ? selectedCompany?.id
          : actionType === "shareList"
          ? selectedList?.id
          : selectedEvent?.id,
      council_id: selectedCouncil.id,
      emails: selectAll ? [null] : members.map((member) => member.email),
      enqueueSnackbar,
    };

    actionType === "shareList" &&
      dispatch(companyListShareWithMember(listData));
    actionType === "deleteList" &&
      dispatch(companyListShareRemoveMember(removeFromListData));
    actionType === "shareCompany" && dispatch(shareCompany(shareData));
    actionType === "shareEvent" &&
      dispatch(eventShare(shareData, enqueueSnackbar));
    actionType === "sharePatientOrganization" &&
      dispatch(patientOrganizationShare(shareData));
    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
  };

  const handleMemberSelect = (values) => {
    setMembers(values);
    if (values[0]?.id === "AllId") {
      setSelectAll(true);
      if (currentUserRole === "viewer") {
        setRole("viewer");
      }
    } else {
      setSelectAll(false);
    }
  };

  const handleRoleSelect = (values) => {
    setRole(values.obj[0].role);
  };

  const handleSelectAll = (val) => {
    setSelectAll(val);
  };

  useEffect(() => {
    setReadyToSave(
      selectAll ||
        members?.length > 0 ||
        !teamOptions?.find((team) => team.selected)
    );
  }, [selectAll, members]);

  const handleOnSubmit = () => {};

  React.useEffect(() => {
    if (selectedCouncil) {
      dispatch(councilUsersListGet(selectedCouncil.id, enqueueSnackbar));
      dispatch(
        getUserTeams({
          enqueueSnackbar,
          query: {
            items: 1000,
          },
        })
      );
    }
  }, [selectedCouncil]);

  useEffect(() => {
    setTeamOptions(userTeams);
  }, [userTeams]);

  const roleValues =
    currentUserRole !== "owner"
      ? [{ role: "viewer", optionName: "Viewer" }]
      : [
          {
            role: role,
            optionName: role.charAt(0).toUpperCase() + role.slice(1),
          },
        ];

  const handleTeamSelect = (value) => {
    const makeSelected = teamOptions.map((team) => {
      if (value[0]?.id === team?.id) {
        return {
          ...team,
          selected: true,
        };
      }

      return {
        ...team,
        selected: false,
      };
    });
    setShowError(false);
    setTeamSelectedFlag(true);
    setTeamOptions(makeSelected);
  };

  return (
    <div className={styles.wrapper}>
      <ModalBody>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initModel}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            handleOnSubmit(values);
            resetForm();
          }}
        >
          {({ handleSubmit, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div>
                    <UserMultiSelectSearch
                      placeholder="Select member or type search"
                      labelField="full_name"
                      valueField="id"
                      searchBy="full_name"
                      name="user_ids"
                      withoutOwner={true}
                      disabled={selectAll}
                      onChange={handleMemberSelect}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <MultiSelect
                    onChange={handleTeamSelect}
                    placeholder="Select Team"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    options={teamOptions}
                    values={[...teamOptions.filter((team) => team?.selected)]}
                    disabled={selectAll}
                    multi={false}
                    withClear={teamOptions?.find((team) => team.selected)}
                    onClear={() => {
                      setTeamSelectedFlag(false);
                      const makeSelected = teamOptions.map((team) => ({
                        ...team,
                        selected: false,
                      }));
                      setTeamOptions(makeSelected);
                    }}
                  />
                </div>
              </div>
              {members.length < 1 && !teamSelectedFlag && showError && (
                <span className={styles.selectFieldWarn}>
                  Select at least one field
                </span>
              )}
              {actionType === "shareList" && (
                <div className="row">
                  <div className="col-md-12">
                    <DropSelect
                      onChange={handleRoleSelect}
                      placeholder="Choose member role"
                      labelField="optionName"
                      valueField="role"
                      values={roleValues}
                      searchBy="optionName"
                      options={roleOptions}
                    />
                  </div>
                </div>
              )}
              <div className={styles.checkBoxWrp}>
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={selectAll}
                  onChange={(value) => handleSelectAll(value)}
                  disabled={members.length || teamSelectedFlag}
                />
                <Label
                  className={
                    members.length || teamSelectedFlag ? styles.disabled : {}
                  }
                >
                  Share with all {selectedCouncil.name} Traction members
                </Label>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        {actionType === "deleteList" ? (
          <Button type="submit" btn={BtnType.REGULAR} onClick={handleSaveClick}>
            Remove
          </Button>
        ) : readyToSave ? (
          <Button type="submit" btn={BtnType.REGULAR} onClick={handleSaveClick}>
            Share
          </Button>
        ) : (
          <Tooltip
            title="You have to select at least one member or select all"
            placement="top"
          >
            <Button btn={BtnType.DISABLED}>Share</Button>
          </Tooltip>
        )}
      </ModalFooter>
    </div>
  );
};

export default React.memo(ShareWithComponent);

import React, { useState, useEffect } from "react";
import DatePicker from "react-date-picker";
import classNames from "classnames";
import { format } from "date-fns";
import { Icon, smSize } from "../../../common/icon";
import styles from "./datepiker.module.scss";

const DatePikerComponent = (props) => {
  const {
    className,
    name,
    value,
    styleProp,
    onCalendarOpen,
    withClear,
    disabled = false,
    emailDateValue,
    formProps: {
      values = {},
      setFieldValue = () => {},
      setFieldTouched = () => {},
    } = {},
    onChange = null,
    view,
  } = props;

  const [dateValue, setDateValue] = useState(null);
  const [displayValue, setDisplayValue] = useState();
  const isDate = (input) => {
    const timestamp = Date.parse(input);
    if (Number.isNaN(timestamp)) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (name && values && values[name]) {
      if (isDate(values) || isDate(values[name] || isDate(value))) {
        setDateValue(values && values[name] ? values[name] : value);
      }
    }
  }, [values && name && values[name]]);

  useEffect(() => {
    if (value && isDate(value)) {
      setDisplayValue(new Date(value));
    }

    if (emailDateValue) {
      const emailDateParts = emailDateValue.split("-");
      setDisplayValue(
        new Date(
          new Date(emailDateValue).setDate(
            Number(emailDateParts[2].slice(0, 2))
          )
        )
      );
    }
    if (value === "") {
      setDisplayValue("");
    }
  }, [value, emailDateValue]);

  useEffect(() => {
    if (dateValue) {
      if (isDate(dateValue)) {
        const stillUtc = new Date(dateValue);
        setDisplayValue(stillUtc);
      } else {
        const dateParts = dateValue.split("-");
        const stillUtc = new Date(dateValue).setDate(dateParts[2]);
        setDisplayValue(stillUtc);
      }
    }
  }, [dateValue]);

  const handleOnChange = (e) => {
    if (e === "clear") {
      onChange && onChange("");
      setFieldValue(name, "");
      setFieldTouched(name);
      setDisplayValue("");
      values[name] = "";
      return;
    }
    if (isDate(e)) {
      // const formated = format(new Date(e), "yyyy-MM-dd'T'HH:mm:ssxxxxx");
      const formated = format(new Date(e), "MMM dd, yyyy");
      if (onChange) {
        onChange(formated);
        setDisplayValue(e);
        return;
      }

      setDisplayValue(e);
      if (setFieldValue) {
        setFieldValue(name, value);
        setFieldTouched(name);
        values[name] = value;
      }
    }
  };
  const handleDateReadOnly = (e) => {
    if (e.target?.tagName?.toUpperCase() === "INPUT") {
      e.target.setAttribute("readonly", true);
    }
  };

  const handleYearOrMonthClick = (value) => {
    setDisplayValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div
      className={classNames(styles.dateWrapper, className)}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.container}>
        <DatePicker
          onCalendarOpen={onCalendarOpen}
          openCalendarOnFocus
          onChange={(e) => handleOnChange(e)}
          onFocus={(e) => handleDateReadOnly(e)}
          value={displayValue}
          className={styleProp || styles.dateInner}
          clearIcon={null}
          format="MM/dd/yyyy"
          isOpen={false}
          calendarIcon={<Icon {...smSize} icon="icn-calendar" />}
          dayPlaceholder="DD"
          monthPlaceholder="MM"
          yearPlaceholder="YYYY"
          disabled={disabled}
          view={view}
          onClickYear={handleYearOrMonthClick}
          onClickMonth={handleYearOrMonthClick}
          locale="en-US"
        />
      </div>
      {withClear && !disabled && (
        <span
          className={styles.clearButton}
          onClick={() => {
            handleOnChange("clear");
          }}
        >
          <span> clear</span>
        </span>
      )}
    </div>
  );
};

export default React.memo(DatePikerComponent);

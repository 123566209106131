import React, { useState, useEffect, useMemo, useCallback } from "react";
import ReactTable from "react-table";
import { useSnackbar } from "notistack";

import { Button, BtnType, Pannel, CheckType, CheckBox, MoreOptions } from "../../../../primitives";
import styles from "./financialsBox.module.scss";
import { useModelPopup } from "../../../../../common/hooks";
import { httpDelete, httpGet, httpPatch } from "../../../../../common/httpCall";
import LoadingComponent from "modules/primitives/loading/loading.component";
import AddNewFinancial from "modules/projects/project/overview/financialsBox/addNewFinancial";
import { currencies } from "modules/projects/project/overview/financialsBox/addNewFinancial/constants";
import { ACTIVITY_OPTIONS } from "../../../../../common/constants";

const FinancialsBox = ({ project, haveActions }) => {
  const [paymentsList, setPaymentsList] = useState(null);

  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    httpGet({
      call: `projects/${project.id}/expected_payments`,
    }).subscribe(res => {
      setPaymentsList(res.response);
    })
  }, []);

  const handleAddUpdate = (paymentRes, id) => {
    if (id?.length) {
      const newPayments = paymentsList.map(p => {
        if (p.id === id) {
          return {
            ...p,
            ...paymentRes,
          }
        }

        return p;
      });

      setPaymentsList(newPayments);
      enqueueSnackbar('Successfully updated expected payment', {
        variant: 'success',
      });
      return;
    }

    enqueueSnackbar('Successfully added expected payment', {
      variant: 'success',
    })
    setPaymentsList([...paymentsList, paymentRes]);
  }

  const handleAddClick = (item) => {
    popup.show({
      title: "Add Payment",
      component: <AddNewFinancial
        data={item}
        project={project}
        handleAddUpdate={handleAddUpdate}
        currencyToUse={paymentsList.length ? paymentsList[0].currency : null}
      />,
    });
  };

  const handleDeleteClick = (item, cb) => {
    httpDelete({
      call: `projects/${project.id}/expected_payments/${item.id}`,
    }).subscribe(() => {
      cb();
    });
  }

  const handleMoreOptionClick = (value, item) => {
    if (value === 0) {
      handleAddClick(item);
    }

    if (value === 1) {
      handleDeleteClick(item, () => {
        const newPayments = paymentsList.filter(p => p.id !== item.id);
        setPaymentsList(newPayments);
        enqueueSnackbar('Successfully deleted expected payment', {
          variant: 'success',
        })
      });
    }
  }

  const handleCompletedChange = (completed, row) => {
    httpPatch({
      call: `projects/${project.id}/expected_payments/${row.id}`,
      data: {
        expected_payment: {
          ...row,
          completed,
        },
      }
    }).subscribe(res => {
      if (res.response) {
        handleAddUpdate(res.response, row.id);
      }
    });
  }

  const formatWithCommas = (val) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  const columns = useMemo(() => {
    return [
      {
        Header: "Expected Payment",
        id: "price_in_currency",
        accessor: (row) => <div className="d-flex justify-content-center">
          {currencies[row.currency]}{formatWithCommas(row?.price_in_currency)}
        </div>,
        width: "25%",
      },
      {
        Header: "Payment Date",
        id: "payment_date",
        accessor: (row) => <div className="d-flex justify-content-center">{row?.payment_date || '-'}</div>,
        width: "20%",
      },
      {
        Header: "Comment",
        id: "comment",
        accessor: (row) => <div className="d-flex justify-content-center">{row?.comment || '-'}</div>,
        width: "30%",
      },
      {
        Header: "Completed",
        id: "completed",
        accessor: (row) => (
          <div className="d-flex justify-content-center">
            <CheckBox
              checkType={CheckType.BLUE}
              isChecked={row.completed}
              onChange={(evt) => handleCompletedChange(evt, row)}
            />
          </div>
        ),
        width: "20%",
      },
      {
        Header: "",
        id: "options",
        accessor: (row) => (
          <div className="d-flex">
            <MoreOptions
              options={ACTIVITY_OPTIONS}
              className={styles.moreOptWrp}
              onClick={(evt) => handleMoreOptionClick(evt, row)}
              customSvgIcon
            />
          </div>
        ),
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
        maxWidth: 40,
      },
    ]
  }, [paymentsList, haveActions]);

  const makeTotal = useCallback(() => {
    return paymentsList.reduce((acc, p) => {
      const accHasCurrency = acc.find(el => el.currency === p.currency);

      if (accHasCurrency) {
        return acc.map(el => {
          if (el.currency === p.currency) {
            return {
              currency: p.currency,
              total: (el.total || 0) + p.price_in_currency
            }
          }

          return el;
        });
      } else {
        return [
          ...acc,
          {
            currency: p.currency,
            total: p.price_in_currency,
          }
        ]
      }
    }, []).map(el => ({
      ...el,
      total: formatWithCommas(el.total),
    }))
  }, [paymentsList]);

  return (
    <Pannel title="Financials Box">
      <div className={styles.wrapper}>
        {
          !paymentsList ? (
            <LoadingComponent customText="Getting payments" />
          ) : (
            <>
              {
                !paymentsList?.length ? (
                  <div className="d-flex justify-content-center flex-column flex-grow-1 align-items-center">
                    <span>No payments yet.</span>
                    {
                      haveActions ? (
                        <Button className="mt-3" onClick={() => handleAddClick()} btn={BtnType.REGULAR}>
                          Add new Payment
                        </Button>
                      ) : null
                    }
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <ReactTable
                      data={paymentsList}
                      columns={haveActions ? columns : columns.filter(c => c.id !== 'options')}
                      className={styles.table}
                      showPaginationBottom={false}
                      minRows={3}
                    />
                    <div className="d-flex w-100 mt-2 font-weight-bold justify-content-start">
                      <span>Total:</span>
                      <div className="d-flex align-items-center ml-2">
                        {
                          makeTotal().map(curObj => (
                            <span key={curObj.currency} className="mr-2">
                              {currencies[curObj.currency]}{curObj.total}
                            </span>
                          ))
                        }
                      </div>
                    </div>
                    {
                      haveActions ? (
                        <Button className="mt-3" onClick={handleAddClick} btn={BtnType.REGULAR}>
                          Add new Payment
                        </Button>
                      ) : null
                    }
                  </div>
                )
              }
            </>
          )
        }
      </div>
    </Pannel>
  );
};

export default React.memo(FinancialsBox);

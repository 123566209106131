import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import LoadingComponent from "modules/primitives/loading/loading.component";
import genericStyles from '../../styles.module.scss';
import {
  getReplicationsByStageData,
  getReplicationsTableData,
  toggleReplicationsTable,
} from "modules/reporting/fordReports/replicationsCard/store/replicationsCard.actions";
import { CHART_COLORS } from "modules/companies/companyMain/chartsWrapper/chartsView/charts-constants";
import ReportingVerticalBarChart from "modules/reporting/components/reportingVerticalBarChart";

const ReplicationsByStage = ({ replicationsByStage, filters, tableData, downloadIsActive }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!replicationsByStage.data) {
      const makeFilters = makeActualScoreCardFilterData(filters);
      const payload = {
        enqueueSnackbar,
        data: makeFilters,
      };

      dispatch(getReplicationsByStageData(payload))
    }
  }, []);

  const cardHasExpandedTable = useMemo(() => tableData?.expandedKey === 'replications_by_stage',
    [tableData?.expandedKey]);

  const handleShowOrHideTable = useCallback(() => {
    if (tableData?.expandedKey !== 'replications_by_stage') {
      const filtersData = makeActualScoreCardFilterData(filters);
      dispatch(getReplicationsTableData({
        reducerKey: 'replications_by_stage',
        data: {
          ...filtersData,
          section: 'replications_by_stage',
        },
      }));

      return;
    }

    dispatch(toggleReplicationsTable({
      reducerKey: 'replications_by_stage',
    }));
  }, [tableData, filters]);

  const makeColumnCardStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {
      width: '100%'
    };

    return {
      border: '1px solid #cbd2d9',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px',
      height: '100%',
      padding: '10px',
    }
  }, [downloadIsActive]);

  const makeTitleStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      color: '#616e7c',
      fontSize: '18px',
      fontFamily: 'lato-regular',
      paddingTop: '10px',
    }
  }, [downloadIsActive]);

  const makeData = useMemo(() => {
    if (replicationsByStage.fetching) return {};

    return {
      labels: replicationsByStage.data?.map(elem => elem.name),
      data_sets: replicationsByStage.data?.map((elem, index) => ({
        color: CHART_COLORS[index % CHART_COLORS.length],
        data: elem.value,
      })),
      y_title: 'Nr. Replications',
      x_title: 'Project Status',
    }
  }, [replicationsByStage]);

  return (
    <div style={makeColumnCardStylesForPdf} className={genericStyles.columnCard}>
      <span style={makeTitleStylesForPdf} className={genericStyles.columnCardTitle}>Replications By Stage</span>
      {
        replicationsByStage.fetching ? (
          <LoadingComponent customText="Getting Replications By Stage..." />
        ) : replicationsByStage.data && (
          <div className="d-flex flex-row w-100">
            <ReportingVerticalBarChart
              chartData={makeData}
              id="projects_by_stage"
            />
          </div>
        )
      }
      {
        !downloadIsActive && (
          <div className="d-flex justify-content-center mt-3">
            <span className={genericStyles.showHideSourceData} onClick={() => handleShowOrHideTable()}>
              {cardHasExpandedTable ? 'HIDE SOURCE DATA' : 'SHOW SOURCE DATA'}
            </span>
          </div>
        )
      }
    </div>
  );
};

export default React.memo(ReplicationsByStage);

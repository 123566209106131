import React from "react";
import styles from "./externalPeople.module.scss";
import { Button, BtnType } from "../../primitives";

const ContactState = ({ user, handleInvite }) => {
  if (user?.claimed_profile) {
    return (
      <div className={styles.companyAccess}>
        <span>&#x2713; ACCESS</span>
      </div>
    );
  }

  if (!user?.claimed_profile && !user.invited_at) {
    return (
      <div
        onClick={() => {
          handleInvite();
        }}
        className={styles.invite}
      >
        <Button btn={BtnType.FRAME_LESS} className={styles.button}>
          Invite
        </Button>
      </div>
    );
  }

  if (!user?.claimed_profile && user.invited_at) {
    return (
      <div onClick={() => handleInvite()} className={styles.invitePending}>
        <Button btn={BtnType.FRAME_LESS} className={styles.button} disabled>
          Invite Pending
        </Button>
      </div>
    );
  }

  return "";
};

export default ContactState;

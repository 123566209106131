export const IDEA_RATING_SET = "IDEA_RATING_SET";
export const ideaRatingSet = (payload) => ({
  type: IDEA_RATING_SET,
  payload,
});

export const IDEA_RATING_SET_SUCCESS = "IDEA_RATING_SET_SUCCESS";
export const ideaRatingSetSuccess = (payload) => ({
  type: IDEA_RATING_SET_SUCCESS,
  payload,
});

export const IDEA_RATINGS_GET_ATTEMPT = "IDEA_RATINGS_GET_ATTEMPT";
export const ideaRatingGet = (payload) => ({
  type: IDEA_RATINGS_GET_ATTEMPT,
  payload,
});
export const IDEA_RATINGS_GET_SUCCESS = "IDEA_RATINGS_GET_SUCCESS";
export const ideaRatingGetSuccess = (payload) => ({
  type: IDEA_RATINGS_GET_SUCCESS,
  payload,
});

export const IDEA_PRODUCT_DELETE = "IDEA_PRODUCT_DELETE";
export const ideaProductDelete = (payload) => ({
  type: IDEA_PRODUCT_DELETE,
  payload,
});

export const IDEA_PRODUCT_DELETE_SUCCESS = "IDEA_PRODUCT_DELETE_SUCCESS";
export const ideaProductDeleteSuccess = (payload) => ({
  type: IDEA_PRODUCT_DELETE_SUCCESS,
  payload,
});

export const IDEA_COMPANY_DELETE_ATTEMPT = "IDEA_COMPANY_DELETE_ATTEMPT";
export const ideaCompanyDeleteAttempt = (payload) => ({
  type: IDEA_COMPANY_DELETE_ATTEMPT,
  payload,
});

export const IDEA_COMPANY_DELETE_SUCCESS = "IDEA_COMPANY_DELETE_SUCCESS";
export const ideaCompanyDeleteSuccess = (payload) => ({
  type: IDEA_COMPANY_DELETE_SUCCESS,
  payload,
});

export const IDEA_COMPANIES_ADD_ATTEMPT = "IDEA_COMPANIES_ADD_ATTEMPT";
export const ideaCompaniesAddAttempt = (payload) => ({
  type: IDEA_COMPANIES_ADD_ATTEMPT,
  payload,
});

export const IDEA_COMPANIES_ADD_SUCCESS = "IDEA_COMPANIES_ADD_SUCCESS";
export const ideaCompaniesAddSuccess = (payload) => ({
  type: IDEA_COMPANIES_ADD_SUCCESS,
  payload,
});

export const IDEA_DELETE_DOCUMENT_ATTEMPT = "IDEA_DELETE_DOCUMENT_ATTEMPT";
export const ideaDeleteDocumentAttempt = (payload) => ({
  type: IDEA_DELETE_DOCUMENT_ATTEMPT,
  payload,
});

export const IDEA_LIST_GET_ATTEMPT = "IDEA_LIST_GET_ATTEMPT";
export const ideasListGet = (payload) => ({
  type: IDEA_LIST_GET_ATTEMPT,
  payload,
});

export const IDEA_LIST_GET_SUCCESS = "IDEA_LIST_GET_SUCCESS";
export const ideasListGetSuccess = (payload) => ({
  type: IDEA_LIST_GET_SUCCESS,
  payload,
});

export const CREATE_IDEA_ATTEMPT = "CREATE_IDEA_ATTEMPT";
export const createIdea = (payload) => ({
  type: CREATE_IDEA_ATTEMPT,
  payload,
});

export const CREATE_IDEA_SUCCESS = "CREATE_IDEA_SUCCESS";
export const createIdeaSuccess = (payload) => ({
  type: CREATE_IDEA_SUCCESS,
  payload,
});

export const RESET_ACTIVE_IDEA = "RESET_ACTIVE_IDEA";
export const resetActiveIdea = (payload) => ({
  type: RESET_ACTIVE_IDEA,
  payload,
});

export const GET_IDEA_ATTEMPT = "GET_IDEA_ATTEMPT";
export const getIdeaAttempt = (payload) => ({
  type: GET_IDEA_ATTEMPT,
  payload,
});

export const GET_IDEA_SUCCESS = "GET_IDEA_SUCCESS";
export const getIdeaSuccess = (payload) => ({
  type: GET_IDEA_SUCCESS,
  payload,
});

export const VOTE_IDEA_ATTEMPT = "VOTE_IDEA_ATTEMPT";
export const voteIdea = (payload) => ({
  type: VOTE_IDEA_ATTEMPT,
  payload,
});

export const RE_VOTE_IDEA_ATTEMPT = "RE_VOTE_IDEA_ATTEMPT";
export const revoteIdea = (payload) => ({
  type: RE_VOTE_IDEA_ATTEMPT,
  payload,
});

export const VOTE_IDEA_SUCCESS = "VOTE_IDEA_SUCCESS";
export const voteIdeaSuccess = (payload) => ({
  type: VOTE_IDEA_SUCCESS,
  payload,
});

export const GET_IDEAS_STATUSES_COUNTER_ATTEMPT =
  "GET_IDEAS_STATUSES_COUNTER_ATTEMPT";
export const getIdeasStatusesCounter = (payload) => ({
  type: GET_IDEAS_STATUSES_COUNTER_ATTEMPT,
  payload,
});

export const GET_IDEAS_STATUSES_COUNTER_SUCCESS =
  "GET_IDEAS_STATUSES_COUNTER_SUCCESS";
export const getIdeasStatusesCounterSuccess = (payload) => ({
  type: GET_IDEAS_STATUSES_COUNTER_SUCCESS,
  payload,
});

export const EDIT_IDEA_ATTEMPT = "EDIT_IDEA_ATTEMPT";
export const editIdeaAttempt = (payload) => ({
  type: EDIT_IDEA_ATTEMPT,
  payload,
});

export const EDIT_IDEA_SUCCESS = "EDIT_IDEA_SUCCESS";
export const editIdeaSuccess = (payload) => ({
  type: EDIT_IDEA_SUCCESS,
  payload,
});

export const DELETE_IDEA_COMMENT_ATTEMPT = "DELETE_IDEA_COMMENT_ATTEMPT";
export const deleteIdeaCommentAttempt = (payload) => ({
  type: DELETE_IDEA_COMMENT_ATTEMPT,
  payload,
});

export const DELETE_IDEA_COMMENT_SUCCESS = "DELETE_IDEA_COMMENT_SUCCESS";
export const deleteIdeaCommentSuccess = (payload) => ({
  type: DELETE_IDEA_COMMENT_SUCCESS,
  payload,
});

export const ADD_IDEA_COMMENT_ATTEMPT = "ADD_IDEA_COMMENT_ATTEMPT";
export const addIdeaCommentAttempt = (payload) => ({
  type: ADD_IDEA_COMMENT_ATTEMPT,
  payload,
});

export const ADD_IDEA_COMMENT_SUCCESS = "ADD_IDEA_COMMENT_SUCCESS";
export const addIdeaCommentSuccess = (payload) => ({
  type: ADD_IDEA_COMMENT_SUCCESS,
  payload,
});

export const ADD_IDEA_TEAM_MEMBER_ATTEMPT = "ADD_IDEA_TEAM_MEMBER_ATTEMPT";
export const addIdeaTeamMemberAttempt = (payload) => ({
  type: ADD_IDEA_TEAM_MEMBER_ATTEMPT,
  payload,
});

export const ADD_IDEA_TEAM_MEMBER_SUCCESS = "ADD_IDEA_TEAM_MEMBER_SUCCESS";
export const addIdeaTeamMemberSuccess = (payload) => ({
  type: ADD_IDEA_TEAM_MEMBER_SUCCESS,
  payload,
});

export const DELETE_IDEA_TEAM_MEMBER_ATTEMPT =
  "DELETE_IDEA_TEAM_MEMBER_ATTEMPT";
export const deleteIdeaTeamMemberAttempt = (payload) => ({
  type: DELETE_IDEA_TEAM_MEMBER_ATTEMPT,
  payload,
});

export const DELETE_IDEA_TEAM_MEMBER_SUCCESS =
  "DELETE_IDEA_TEAM_MEMBER_SUCCESS";
export const deleteIdeaTeamMemberSuccess = (payload) => ({
  type: DELETE_IDEA_TEAM_MEMBER_SUCCESS,
  payload,
});

export const IDEA_TEMPLATE_GET_ATTEMPT = "IDEA_TEMPLATE_GET_ATTEMPT";
export const ideasTemplateGet = (payload) => ({
  type: IDEA_TEMPLATE_GET_ATTEMPT,
  payload,
});

export const IDEA_TEMPLATE_GET_SUCCESS = "IDEA_TEMPLATE_GET_SUCCESS";
export const ideasTemplateGetSuccess = (payload) => ({
  type: IDEA_TEMPLATE_GET_SUCCESS,
  payload,
});

export const IDEA_SECTIONS_GET_ATTEMPT = "IDEA_SECTIONS_GET_ATTEMPT";
export const ideasSectionsGet = (payload) => ({
  type: IDEA_SECTIONS_GET_ATTEMPT,
  payload,
});

export const IDEA_SECTIONS_GET_SUCCESS = "IDEA_SECTIONS_GET_SUCCESS";
export const ideasSectionGetSuccess = (payload) => ({
  type: IDEA_SECTIONS_GET_SUCCESS,
  payload,
});

export const ADD_RELATED_PROJECT_ATTEMPT = "ADD_RELATED_PROJECT_ATTEMPT";
export const addRelatedProjectAttempt = (payload) => ({
  type: ADD_RELATED_PROJECT_ATTEMPT,
  payload,
});

export const ADD_RELATED_PROJECT_SUCCESS = "ADD_RELATED_PROJECT_SUCCESS";
export const addRelatedProjectSuccess = (payload) => ({
  type: ADD_RELATED_PROJECT_SUCCESS,
  payload,
});

export const DELETE_RELATED_PROJECT_ATTEMPT = "DELETE_RELATED_PROJECT_ATTEMPT";
export const deleteRelatedProjectAttempt = (payload) => ({
  type: DELETE_RELATED_PROJECT_ATTEMPT,
  payload,
});

export const DELETE_RELATED_PROJECT_SUCCESS = "DELETE_RELATED_PROJECT_SUCCESS";
export const deleteRelatedProjectSuccess = (payload) => ({
  type: DELETE_RELATED_PROJECT_SUCCESS,
  payload,
});

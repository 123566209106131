export const PROJECT_WIDE_TASK_SET_COMPLETE = "PROJECT_WIDE_TASK_SET_COMPLETE";
export const projectWideTaskSetComplete = (payload) => ({
  type: PROJECT_WIDE_TASK_SET_COMPLETE,
  payload,
});

export const PROJECT_STAGE_ORGANIZATIONS_GET_ATTEMPT =
  "PROJECT_STAGE_ORGANIZATIONS_GET_ATTEMPT";
export const projectStageOrganizationsGet = (payload) => ({
  type: PROJECT_STAGE_ORGANIZATIONS_GET_ATTEMPT,
  payload,
});
export const PROJECT_STAGE_ORGANIZATIONS_GET_SUCCESS =
  "PROJECT_STAGE_ORGANIZATIONS_GET_SUCCESS";
export const projectStageOrganizationsGetSuccess = (payload) => ({
  type: PROJECT_STAGE_ORGANIZATIONS_GET_SUCCESS,
  payload,
});

export const PROJECT_STAGES_LIST_GET_ATTEMPT =
  "PROJECT_STAGES_LIST_GET_ATTEMPT";
export const projectStagesListGet = (payload) => ({
  type: PROJECT_STAGES_LIST_GET_ATTEMPT,
  payload,
});
export const PROJECT_STAGES_LIST_GET_SUCCESS =
  "PROJECT_STAGES_LIST_GET_SUCCESS";
export const projectStagesListGetSuccess = (payload) => ({
  type: PROJECT_STAGES_LIST_GET_SUCCESS,
  payload,
});

export const ALL_PROJECT_STAGES_LIST_GET_ATTEMPT =
  "ALL_PROJECT_STAGES_LIST_GET_ATTEMPT";
export const getAllProjectStages = (payload) => ({
  type: ALL_PROJECT_STAGES_LIST_GET_ATTEMPT,
  payload,
});
export const ALL_PROJECT_STAGES_LIST_GET_SUCCESS =
  "ALL_PROJECT_STAGES_LIST_GET_SUCCESS";
export const getALLprojectStagesListSuccess = (payload) => ({
  type: ALL_PROJECT_STAGES_LIST_GET_SUCCESS,
  payload,
});

export const ALL_TEMPLATES_PROJECT_STAGES_LIST_GET_ATTEMPT =
  "ALL_TEMPLATES_PROJECT_STAGES_LIST_GET_ATTEMPT";
export const getAllTemplatesProjectStages = (payload) => ({
  type: ALL_TEMPLATES_PROJECT_STAGES_LIST_GET_ATTEMPT,
  payload,
});
export const ALL_TEMPLATES_PROJECT_STAGES_LIST_GET_SUCCESS =
  "ALL_TEMPLATES_PROJECT_STAGES_LIST_GET_SUCCESS";
export const getAllTemplatesProjectStagesSuccess = (payload) => ({
  type: ALL_TEMPLATES_PROJECT_STAGES_LIST_GET_SUCCESS,
  payload,
});

export const PROJECT_WIDE_TASKS_GET_ATTEMPT = "PROJECT_WIDE_TASKS_GET_ATTEMPT";
export const projectWideTasksListGet = (payload) => ({
  type: PROJECT_WIDE_TASKS_GET_ATTEMPT,
  payload,
});
export const PROJECT_WIDE_TASKS_GET_SUCCESS = "PROJECT_WIDE_TASKS_GET_SUCCESS";
export const projectWideTasksGetSuccess = (payload) => ({
  type: PROJECT_WIDE_TASKS_GET_SUCCESS,
  payload,
});

export const PROJECT_WIDE_TASKS_UPDATE = "PROJECT_WIDE_TASKS_UPDATE";
export const projectWideTasksUpdate = (payload) => ({
  type: PROJECT_WIDE_TASKS_UPDATE,
  payload,
});

export const PROJECT_STAGES_RESET = "PROJECT_STAGES_RESET";
export const projectStagesReset = () => ({ type: PROJECT_STAGES_RESET });

export const PROJECT_STAGE_PUT_ATTEMPT = "PROJECT_STAGE_PUT_ATTEMPT";
export const projectStagePut = (payload) => ({
  type: PROJECT_STAGE_PUT_ATTEMPT,
  payload,
});
export const PROJECT_STAGE_PUT_SUCCESS = "PROJECT_STAGE_PUT_SUCCESS";
export const projectStagePutSuccess = (payload) => ({
  type: PROJECT_STAGE_PUT_SUCCESS,
  payload,
});

export const PROJECT_STAGE_NEW_ATTEMPT = "PROJECT_STAGE_NEW_ATTEMPT";
export const projectStageNew = (payload) => ({
  type: PROJECT_STAGE_NEW_ATTEMPT,
  payload,
});
export const PROJECT_STAGE_NEW_SUCCESS = "PROJECT_STAGE_NEW_SUCCESS";
export const projectStageNewSuccess = (payload) => ({
  type: PROJECT_STAGE_NEW_SUCCESS,
  payload,
});

export const PROJECT_STAGE_REMOVE_ATTEMPT = "PROJECT_STAGE_REMOVE_ATTEMPT";
export const projectStageRemove = (payload) => ({
  type: PROJECT_STAGE_REMOVE_ATTEMPT,
  payload,
});
export const PROJECT_STAGE_REMOVE_SUCCESS = "PROJECT_STAGE_REMOVE_SUCCESS";
export const projectStageRemoveSuccess = (payload) => ({
  type: PROJECT_STAGE_REMOVE_SUCCESS,
  payload,
});

export const PROJECT_STAGE_OBJ_MOVE = "PROJECT_STAGE_OBJ_MOVE";
export const projectStageObjMove = (payload) => ({
  type: PROJECT_STAGE_OBJ_MOVE,
  payload,
});

export const PROJECT_STAGE_COMPANY_NEW = "PROJECT_STAGE_COMPANY_NEW";
export const projectStageCompanyNewSuccess = (payload) => ({
  type: PROJECT_STAGE_COMPANY_NEW,
  payload,
});

export const PROJECT_STAGE_COMPANY_REMOVE = "PROJECT_STAGE_COMPANY_REMOVE";
export const projectStageCompanyRemoveSuccess = (payload) => ({
  type: PROJECT_STAGE_COMPANY_REMOVE,
  payload,
});

export const PROJECT_STAGE_COMPANIES_LIST_RESET =
  "PROJECT_STAGE_COMPANIES_LIST_RESET";
export const projectStageCompaniesListReset = () => ({
  type: PROJECT_STAGE_COMPANIES_LIST_RESET,
});

export const PROJECT_STAGE_COMPANIES_LIST_ATTEMPT =
  "PROJECT_STAGE_COMPANIES_LIST_ATTEMPT";
export const projectStageCompaniesGet = (payload) => ({
  type: PROJECT_STAGE_COMPANIES_LIST_ATTEMPT,
  payload,
});
export const PROJECT_STAGE_COMPANIES_LIST_SUCCESS =
  "PROJECT_STAGE_COMPANIES_LIST_SUCCESS";
export const projectStageCompaniesGetSuccess = (payload) => ({
  type: PROJECT_STAGE_COMPANIES_LIST_SUCCESS,
  payload,
});

export const PROJECT_STAGE_COMPANY_UPDATE_ATTEMPT =
  "PROJECT_STAGE_COMPANY_UPDATE_ATTEMPT";
export const projectStageCompanyUpdate = (payload) => ({
  type: PROJECT_STAGE_COMPANY_UPDATE_ATTEMPT,
  payload,
});
export const PROJECT_STAGE_COMPANY_UPDATE_SUCCESS =
  "PROJECT_STAGE_COMPANY_UPDATE_SUCCESS";
export const projectStageCompanyUpdateSuccess = (payload) => ({
  type: PROJECT_STAGE_COMPANY_UPDATE_SUCCESS,
  payload,
});

export const PROJECT_COMPANY_TASK_STATUS_GET_ATTEMPT =
  "PROJECT_COMPANY_TASK_STATUS_GET_ATTEMPT";
export const projectCompanyTaskStatusGet = (payload) => ({
  type: PROJECT_COMPANY_TASK_STATUS_GET_ATTEMPT,
  payload,
});
export const PROJECT_COMPANT_TASK_STATUS_GET_SUCCESS =
  "PROJECT_COMPANT_TASK_STATUS_GET_SUCCESS";
export const projectCompanyTaskStatusSuccess = (payload) => ({
  type: PROJECT_COMPANT_TASK_STATUS_GET_SUCCESS,
  payload,
});

export const PROJECT_COMPANY_TASK_STATUS_NEW_ATTEMPT =
  "PROJECT_COMPANY_TASK_STATUS_NEW_ATTEMPT";
export const projectCompanyTaskStatusNew = (payload) => ({
  type: PROJECT_COMPANY_TASK_STATUS_NEW_ATTEMPT,
  payload,
});
export const PROJECT_COMPANT_TASK_STATUS_NEW_SUCCESS =
  "PROJECT_COMPANT_TASK_STATUS_NEW_SUCCESS";
export const projectCompanyTaskStatusNewSuccess = (payload) => ({
  type: PROJECT_COMPANT_TASK_STATUS_NEW_SUCCESS,
  payload,
});

export const PROJECT_COMPANY_TASK_STATUS_REMOVE_ATTEMPT =
  "PROJECT_COMPANY_TASK_STATUS_REMOVE_ATTEMPT";
export const projectCompanyTaskStatusRemove = (payload) => ({
  type: PROJECT_COMPANY_TASK_STATUS_REMOVE_ATTEMPT,
  payload,
});
export const PROJECT_COMPANY_TASK_STATUS_REMOVE_SUCCESS =
  "PROJECT_COMPANY_TASK_STATUS_REMOVE_SUCCESS";
export const projectCompanyTaskStatusRemoveSuccess = (payload) => ({
  type: PROJECT_COMPANY_TASK_STATUS_REMOVE_SUCCESS,
  payload,
});

export const PROJECT_COMPANY_TASK_STATUS_SAVE_ATTEMPT =
  "PROJECT_COMPANY_TASK_STATUS_SAVE_ATTEMPT";
export const projectCompanyTaskStatusSave = (payload) => ({
  type: PROJECT_COMPANY_TASK_STATUS_SAVE_ATTEMPT,
  payload,
});
export const PROJECT_COMPANT_TASK_STATUS_SAVE_SUCCESS =
  "PROJECT_COMPANT_TASK_STATUS_SAVE_SUCCESS";
export const projectCompanyTaskStatusSaveSuccess = (payload) => ({
  type: PROJECT_COMPANT_TASK_STATUS_SAVE_SUCCESS,
  payload,
});

/* eslint-disable camelcase */
import * as Yup from "yup";

export const initModelRelationship = {
  externalMember: false,
  title: "",
  first_name: "",
  last_name: "",
  organization: "",
  email: "",
  avatar: "",
  external_team_id: null,
  user_ids: [],
};

export const validation = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  // location: Yup.string().required("Location is required"),
  // description: Yup.string().required("Description is required"),
  // starts_on: Yup.string().required("Start date is required"),
  // ends_on: Yup.string().required("End date is required"),
});

export const speakerValidation = Yup.object().shape({
  externalMember: Yup.boolean(),
  first_name: Yup.string().when("externalMember", {
    is: true,
    then: Yup.string().required("First name is required"),
  }),
  last_name: Yup.string().when("externalMember", {
    is: true,
    then: Yup.string().required("Last name is required"),
  }),
  email: Yup.string().when("externalMember", {
    is: true,
    then: Yup.string().required("Email is required"),
  }),
  // first_name: Yup.string().required("First name is required"),
  // email: Yup.string().required("Email is required"),
  // last_name: Yup.string().required("Last name is required"),
  // title: Yup.string().required("Title is required"),

  // email: Yup.string().required("Email is required"),
  // council_speaker: Yup.string().required('Choose a speaker from your council')
  // name: Yup.string().required("Speaker name is required"),
  // title: Yup.string().required("Title is required"),
  // organization: Yup.string().required("Company name is required"),
});

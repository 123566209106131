export const headerMenu = [
  {
    path: "/home",
    text: "home",
    gskOnly: false,
    hideForViewer: true,
    showInFordNavBar: true,
  },
  { path: "/themes", text: "themes", gskOnly: false, showInFordNavBar: true },
  {
    path: "/solutions",
    text: "solutions",
    gskOnly: false,
    hideForViewer: true,
    showInFordNavBar: true,
  },
  {
    path: "/ideas",
    text: "Ideas",
    gskOnly: false,
    hideForViewer: true,
    showInFordNavBar: true,
  },
  {
    path: "/companies",
    text: "companies",
    gskOnly: false,
    showInFordNavBar: true,
    councilsToHideInNav: ["ford"],
  },
  {
    path: "/list/following",
    text: "lists",
    gskOnly: false,
    showInFordNavBar: true,
    councilsToHideInNav: ["ford"],
  },
  {
    path: "/patient_organizations",
    text: "Patient Organizations",
    gskOnly: true,
  },
  {
    path: "/projects",
    text: "projects",
    gskOnly: false,
    hideForViewer: true,
    showInFordNavBar: true,
  },
  {
    path: "/people/internal/all",
    text: "People",
    gskOnly: false,
    hideForViewer: true,
    councilsToHideInNav: ["ford"],
  },
  { path: "/events", text: "events", gskOnly: false },
  { path: "/reporting", text: "Reports", gskOnly: false, hideForViewer: true },
  { path: "/admin", text: "Admin", gskOnly: false, hideForViewer: true },
  {
    path: "/discussions",
    text: "Discussions",
    gskOnly: false,
    mobileOnly: true,
  },
  {
    path: "/settings",
    text: "Settings",
    gskOnly: false,
    mobileOnly: true,
    claimProfileOnly: true,
  },
  { path: "/privacy_policy", text: "Privacy Policy", claimProfileOnly: true },
  { path: "/terms", text: "Terms of Use", claimProfileOnly: true },
];

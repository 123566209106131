import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import styles from "./styles.module.scss";
import CheckBoxesFieldsBlock from "../checkBoxesFieldsBlock";
import { BtnType, Button } from "../../../../../primitives";
import { useModelPopup } from "../../../../../../common/hooks";
import CreateNewCustomFieldModal from "../createNewCustomFieldModal";
import { createFormCustomField } from "../../adminApplicantForms.action";
import {
  additionalFieldsRelationTypes,
  fieldToRemoveOnSyncOn,
} from "../../adminApplicantForms.constants";
import { httpDelete } from "../../../../../../common/httpCall";

const StepThreeComponent = ({ formikprops }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const popup = useModelPopup();

  const { values, setFieldValue } = formikprops;
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [customFieldsOptions, setCustomFieldsOptions] = useState([]);

  const {
    adminApplicantsFormsReducer: { formCustomFields },
  } = useSelector((state) => state);

  const setSelectedAvailableFields = (name, val) => {
    const optionIndex = values.additional_fields.indexOf(name);
    if (optionIndex > -1 && !val) {
      values.additional_fields.splice(optionIndex, 1);
      setCheckboxOptions(
        checkboxOptions.map((option) => {
          if (option.name === name) {
            return { ...option, isChecked: false };
          }
          return option;
        }),
      );
    } else {
      values.additional_fields.push(name);
      setCheckboxOptions(
        checkboxOptions.map((option) => {
          if (option.name === name) {
            return { ...option, isChecked: true };
          }
          return option;
        }),
      );
    }
    setFieldValue("additional_fields", values.additional_fields);
  };

  useEffect(() => {
    if (formCustomFields.length > 0) {
      const customFieldsCheckboxOptions = formCustomFields
        .filter(el => el.field_type !== 'video')
        .reduce(
        (acc, field) => {
          const option = {
            label: field.field_name,
            name: field.id,
            isChecked: false,
            disabled: false,
            is_required: field.is_required,
            isAdditionalCustomField: !field.custom_field_template_id && !field.default && field.relation_field === 'custom_field',
          };

          if (
            values.sync_with_crunchbase
            && fieldToRemoveOnSyncOn.includes(field.field_name)
          ) {
            acc.push(option);
          }

          if (
            !field.default
            && additionalFieldsRelationTypes.includes(field.relation_field)
          ) {
            acc.push(option);
          }

          return acc;
        },
        [],
      )

      const customFields = customFieldsCheckboxOptions.filter(f => !f.isAdditionalCustomField)
      const additionalCustomFields = customFieldsCheckboxOptions.filter(f => f.isAdditionalCustomField);
      const mutatedList = [
        {
          name: 'Custom Fields',
          id: 'customFields',
          readOnly: true,
        },
        ...customFields,
        {
          name: 'Additional Fields',
          id: 'additionalFields',
          readOnly: true,
          showDivider: true,
        },
        ...additionalCustomFields,
      ];

      setCustomFieldsOptions(mutatedList);
      setCheckboxOptions(customFieldsCheckboxOptions);
    }
  }, [formCustomFields]);

  useEffect(() => {
    if (customFieldsOptions.length > 0) {
      const newOptions = customFieldsOptions.map((option) => {
        const isChecked = values.additional_fields.includes(option.name);
        const isRequired = values.required_field_ids.includes(option.name);

        return {
          ...option,
          isChecked,
          is_required: isRequired,
        }
      });

      setCheckboxOptions(newOptions);
    }
  }, [customFieldsOptions, values]);

  const handleNewCustomFieldSave = (field) => {
    dispatch(
      createFormCustomField({
        data: field,
        enqueueSnackbar,
        cb: (err, newField) => {
          if (newField) {
            const newCheckboxOptions = [
              ...checkboxOptions,
              {
                ...newField,
                label: newField.field_name,
                name: newField.id,
                isChecked: true,
                disabled: false,
              },
            ];

            setFieldValue("additional_fields", [
              ...values.additional_fields,
              newField.id,
            ]);
            setCheckboxOptions(newCheckboxOptions);
          }
        },
      }),
    );
  };

  const handleAddNewFieldClick = () => {
    popup.show({
      title: "Create new custom field",
      show: true,
      height: "300",
      width: "540",
      component: (
        <CreateNewCustomFieldModal onSave={handleNewCustomFieldSave} />
      ),
    });
  };

  const handleDelete = (option) => {
    httpDelete({
      call: `applicants/available_form_fields/${option.name}`,
    }).subscribe(() => {
      const newList = customFieldsOptions.filter(f => f.name !== option.name);
      setCustomFieldsOptions(newList);
    })
  }

  const updateRequire = option => {
    const newCheckboxes = checkboxOptions.map(c => {
      if (c.name === option.name) {
        return {
          ...c,
          is_required: !c.is_required,
        }
      }

      return c;
    });
    const requiredIds = newCheckboxes
      .filter(c => c.is_required)
      .map(c => c.name)

    setCheckboxOptions(newCheckboxes);
    setFieldValue("required_field_ids", requiredIds);
  }

  return (
    <div className={styles.createFormStepThree}>
      <div className={styles.formElement}>
        <span>Select more fields below to add them to your form.</span>
      </div>
      <div className={styles.formElement}>
        <CheckBoxesFieldsBlock
          options={checkboxOptions}
          setFieldValue={setSelectedAvailableFields}
          handleDelete={handleDelete}
          showRequiredBlock
          updateRequire={updateRequire}
        />
      </div>

      <div className="d-flex justify-content-center mt-3">
        <Button
          type="button"
          className="max-w-xs"
          btn={BtnType.REGULAR}
          icon="icn-add"
          onClick={handleAddNewFieldClick}
        >
          Add more
        </Button>
      </div>
    </div>
  );
};

export default React.memo(StepThreeComponent);

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";

import { Icon, smSize } from "../../../../../common/icon";
import { CheckBox, CheckType } from "../../../../primitives";
import FilterItemComponent from "../filerItem/filterItem.component";
import styles from "./filterRow.module.scss";

const HOVER_CUSTOM_TEXT_BY_NAME = {
  Academic: "Founded and/or managed by an academic institution.",
  "Alliance / Advocacy":
    "Exists to improve a specific population's experience by connecting resources.",
  "Community / Religious":
    "Community often provides local level support; a religious organization is recognized/sponsored by a church.",
  "Professional Societies / Associations":
    "Unites and empowers professionals to bring their expertise to help a designated group.",
};

const FilterRowComponent = (props) => {
  const {
    item,
    level,
    expan,
    items,
    type,
    handleCheck,
    checkedPatientFilters,
    countryFilters,
    hasHover,
  } = props;
  const [collapse, setCollapse] = useState(expan ? !expan : true);

  useEffect(() => {
    if (checkedPatientFilters?.disease_ids && item.children_diseases) {
      const childIsSelected = item.children_diseases.some((d) => checkedPatientFilters.disease_ids.includes(d.id));
      setCollapse(!childIsSelected);
    }
  }, [checkedPatientFilters, item]);

  const handleCollapseClick = () => {
    setCollapse((prev) => !prev);
  };

  const handleOnChange = (val, item, category) => {
    handleCheck(val, item.id, category, item.children_diseases);
  };

  const isChecked = (item) => {
    if (type === "diversityGroups") {
      if (
        !checkedPatientFilters
        || !checkedPatientFilters?.diversity_group_ids?.length
      ) return false;

      return checkedPatientFilters.diversity_group_ids.some((id) => id === item.id);
    }

    if (type === "disease") {
      if (!checkedPatientFilters || !checkedPatientFilters?.disease_ids?.length) return false;

      return checkedPatientFilters.disease_ids.some((id) => id === item.id);
    }

    if (!countryFilters || !countryFilters?.length) return false;

    return countryFilters.some((country) => country === item.name);
  };

  return (
    <div className={styles.itemWrapper}>
      <div
        className={styles.itemTitle}
        style={{ paddingLeft: `${30 * (level - 1)}px` }}
      >
        {level > 0 && (
          <CheckBox
            checkType={CheckType.BLUE}
            isChecked={isChecked(item)}
            onChange={(val) => handleOnChange(val, item, type)}
          />
        )}
        {hasHover ? (
          <Tooltip key={item.name} title={HOVER_CUSTOM_TEXT_BY_NAME[item.name]}>
            <span
              onClick={() => (items?.length > 0
                ? handleCollapseClick()
                : handleOnChange(isChecked(item), item, type))}
            >
              {item.name}
            </span>
          </Tooltip>
        ) : (
          <span
            onClick={() => (items?.length > 0
              ? handleCollapseClick()
              : handleOnChange(isChecked(item), item, type))}
          >
            {item.name}
          </span>
        )}
        {level === 0 && (
          <Icon
            {...smSize}
            icon="icn-table-down-chevron"
            className={classNames(styles.icon, collapse && styles.collapse)}
            onClick={handleCollapseClick}
          />
        )}
        {items && level !== 0 && items.length > 0 && (
          <Icon
            {...smSize}
            icon="icn-table-down-chevron"
            className={classNames(styles.icon, collapse && styles.collapse)}
            onClick={handleCollapseClick}
          />
        )}
      </div>
      {items && !collapse && (
        <FilterItemComponent
          hasHover={hasHover}
          items={items}
          level={level + 1}
          handleCheck={handleCheck}
          type={type}
          checkedPatientFilters={checkedPatientFilters}
          countryFilters={countryFilters}
        />
      )}
    </div>
  );
};

export default React.memo(FilterRowComponent);

export const THEME_PRODUCT_DELETE_ATTEMPT = "THEME_PRODUCT_DELETE_ATTEMPT";
export const themeProductDeleteAttempt = (payload) => ({
  type: THEME_PRODUCT_DELETE_ATTEMPT,
  payload,
});

export const THEME_PRODUCT_DELETE_SUCCESS = "THEME_PRODUCT_DELETE_SUCCESS";
export const themeProductDeleteSuccess = (payload) => ({
  type: THEME_PRODUCT_DELETE_SUCCESS,
  payload,
});

export const THEME_COMPANY_GET_ATTEMPT = "THEME_COMPANY_GET_ATTEMPT";
export const themesCompanyGetAttempt = (payload) => ({
  type: THEME_COMPANY_GET_ATTEMPT,
  payload,
});
export const THEME_COMPANY_GET_SUCCESS = "THEME_COMPANY_GET_SUCCESS";
export const themesCompanyGetSuccess = (payload) => ({
  type: THEME_COMPANY_GET_SUCCESS,
  payload,
});
export const THEME_COMPANY_DELETE_ATTEMPT = "THEME_COMPANY_DELETE_ATTEMPT";
export const themesCompanyDeleteAttempt = (payload) => ({
  type: THEME_COMPANY_DELETE_ATTEMPT,
  payload,
});
export const THEME_COMPANY_DELETE_SUCCESS = "THEME_COMPANY_DELETE_SUCCESS";
export const themesCompanyDeleteSuccess = (payload) => ({
  type: THEME_COMPANY_DELETE_SUCCESS,
  payload,
});

export const THEME_COMPANY_ADD_ATTEMPT = "THEME_COMPANY_ADD_ATTEMPT";
export const themesCompanyAddAttempt = (payload) => ({
  type: THEME_COMPANY_ADD_ATTEMPT,
  payload,
});
export const THEME_COMPANY_ADD_SUCCESS = "THEME_COMPANY_ADD_SUCCESS";
export const themesCompanyAddSuccess = (payload) => ({
  type: THEME_COMPANY_ADD_SUCCESS,
  payload,
});
export const THEME_MEMBER_ADD_ATTEMPT = "THEME_MEMBER_ADD_ATTEMPT";
export const themesMemberAddAttempt = (payload) => ({
  type: THEME_MEMBER_ADD_ATTEMPT,
  payload,
});
export const THEME_MEMBER_ADD_SUCCESS = "THEME_MEMBER_ADD_SUCCESS";
export const themesMemberAddSuccess = (payload) => ({
  type: THEME_MEMBER_ADD_SUCCESS,
  payload,
});

export const THEME_MEMBER_DELETE_ATTEMPT = "THEME_MEMBER_DELETE_ATTEMPT";
export const themesMemberDeleteAttempt = (payload) => ({
  type: THEME_MEMBER_DELETE_ATTEMPT,
  payload,
});
export const THEME_MEMBER_DELETE_SUCCESS = "THEME_MEMBER_DELETE_SUCCESS";
export const themesMemberDeleteSuccess = (payload) => ({
  type: THEME_MEMBER_DELETE_SUCCESS,
  payload,
});

export const THEME_MEMBER_EDIT_ATTEMPT = "THEME_MEMBER_EDIT_ATTEMPT";
export const themesMemberEditAttempt = (payload) => ({
  type: THEME_MEMBER_EDIT_ATTEMPT,
  payload,
});
export const THEME_MEMBER_EDIT_SUCCESS = "THEME_MEMBER_EDIT_SUCCESS";
export const themesMemberEditSuccess = (payload) => ({
  type: THEME_MEMBER_EDIT_SUCCESS,
  payload,
});

export const THEME_PROJECT_ADD_ATTEMPT = "THEME_PROJECT_ADD_ATTEMPT";
export const themesProjectAddAttempt = (payload) => ({
  type: THEME_PROJECT_ADD_ATTEMPT,
  payload,
});
export const THEME_PROJECT_ADD_SUCCESS = "THEME_PROJECT_ADD_SUCCESS";
export const themesProjectAddSuccess = (payload) => ({
  type: THEME_PROJECT_ADD_SUCCESS,
  payload,
});

export const THEME_PROJECT_DELETE_ATTEMPT = "THEME_PROJECT_DELETE_ATTEMPT";
export const themesProjectDeleteAttempt = (payload) => ({
  type: THEME_PROJECT_DELETE_ATTEMPT,
  payload,
});
export const THEME_PROJECT_DELETE_SUCCESS = "THEME_PROJECT_DELETE_SUCCESS";
export const themesProjectDeleteSuccess = (payload) => ({
  type: THEME_PROJECT_DELETE_SUCCESS,
  payload,
});

export const ALL_EVENTS_GET_ATTEMPT = "ALL_EVENTS_GET_ATTEMPT";
export const allEventsGetAttempt = (payload) => ({
  type: ALL_EVENTS_GET_ATTEMPT,
  payload,
});
export const ALL_EVENTS_GET_SUCCESS = "ALL_EVENTS_GET_SUCCESS";
export const allEventsGetSuccess = (payload) => ({
  type: ALL_EVENTS_GET_SUCCESS,
  payload,
});

export const THEME_EVENT_ADD_ATTEMPT = "THEME_EVENT_ADD_ATTEMPT";
export const themesEventAddAttempt = (payload) => ({
  type: THEME_EVENT_ADD_ATTEMPT,
  payload,
});
export const THEME_EVENT_ADD_SUCCESS = "THEME_EVENT_ADD_SUCCESS";
export const themesEventAddSuccess = (payload) => ({
  type: THEME_EVENT_ADD_SUCCESS,
  payload,
});
export const THEME_EVENT_DELETE_ATTEMPT = "THEME_EVENT_DELETE_ATTEMPT";
export const themesEventDeleteAttempt = (payload) => ({
  type: THEME_EVENT_DELETE_ATTEMPT,
  payload,
});

export const THEME_PROJECT_CREATE_ATTEMPT = "THEME_PROJECT_CREATE_ATTEMPT";
export const themesProjectCreateAttempt = (payload) => ({
  type: THEME_PROJECT_CREATE_ATTEMPT,
  payload,
});

export const THEME_PROJECT_CREATE_SUCCESS = "THEME_PROJECT_CREATE_SUCCESS";
export const themesProjectCreateSuccess = (payload) => ({
  type: THEME_PROJECT_CREATE_SUCCESS,
  payload,
});

export const THEME_IDEAS_CREATE_ATTEMPT = "THEME_IDEAS_CREATE_ATTEMPT";
export const themesIdeasCreateAttempt = (payload) => ({
  type: THEME_IDEAS_CREATE_ATTEMPT,
  payload,
});

export const THEME_IDEAS_CREATE_SUCCESS = "THEME_IDEAS_CREATE_SUCCESS";
export const themesIdeasCreateSuccess = (payload) => ({
  type: THEME_IDEAS_CREATE_SUCCESS,
  payload,
});

export const THEME_IDEAS_GET_ATTEMPT = "THEME_IDEAS_GET_ATTEMPT";
export const themesIdeasGetAttempt = (payload) => ({
  type: THEME_IDEAS_GET_ATTEMPT,
  payload,
});
export const THEME_IDEAS_GET_SUCCESS = "THEME_IDEAS_GET_SUCCESS";
export const themesIdeasGetSuccess = (payload) => ({
  type: THEME_IDEAS_GET_SUCCESS,
  payload,
});

export const THEME_IDEAS_DELETE_ATTEMPT = "THEME_IDEAS_DELETE_ATTEMPT";
export const themesIdeasDeleteAttempt = (payload) => ({
  type: THEME_IDEAS_DELETE_ATTEMPT,
  payload,
});
export const THEME_IDEAS_DELETE_SUCCESS = "THEME_IDEAS_DELETE_SUCCESS";
export const themesIdeasDeleteSuccess = (payload) => ({
  type: THEME_IDEAS_DELETE_SUCCESS,
  payload,
});

export const THEME_RELATED_IDEAS_GET_ATTEMPT =
  "THEME_RELATED_IDEAS_GET_ATTEMPT";
export const themesRelatedIdeasGetAttempt = (payload) => ({
  type: THEME_RELATED_IDEAS_GET_ATTEMPT,
  payload,
});
export const THEME_RELATED_IDEAS_GET_SUCCESS =
  "THEME_RELATED_IDEAS_GET_SUCCESS";
export const themesRelatedIdeasGetSuccess = (payload) => ({
  type: THEME_RELATED_IDEAS_GET_SUCCESS,
  payload,
});
export const RESET_SHOULD_UPDATE = "RESET_SHOULD_UPDATE";
export const resetShouldUpdate = (payload) => ({
  type: RESET_SHOULD_UPDATE,
  payload,
});

export const FORD_SPOC_EXPERTS_GET_ATTEMPT = "FORD_SPOC_EXPERTS_GET_ATTEMPT";
export const fordSpocExpertGetAttempt = (payload) => ({
  type: FORD_SPOC_EXPERTS_GET_ATTEMPT,
  payload,
});

export const FORD_SPOC_EXPERTS_GET_SUCCESS = "FORD_SPOC_EXPERTS_GET_SUCCESS";
export const fordSpocExpertGetSuccess = (payload) => ({
  type: FORD_SPOC_EXPERTS_GET_SUCCESS,
  payload,
});

export const SPOC_THEME_EXPERTS_GET_ATTEMPT =
  "FORD_SPOC_THEME_EXPERTS_GET_ATTEMPT";
export const themeSpocExpertGetAttempt = (payload) => ({
  type: SPOC_THEME_EXPERTS_GET_ATTEMPT,
  payload,
});

export const SPOC_THEME_EXPERTS_GET_SUCCESS = "SPOC_THEME_EXPERTS_GET_SUCCESS";
export const themeSpocExpertGetSuccess = (payload) => ({
  type: SPOC_THEME_EXPERTS_GET_SUCCESS,
  payload,
});

export const THEME_EXPERT_ADD_ATTEMPT = "THEME_EXPERT_ADD_ATTEMPT";
export const themesSpocExpertAddAttempt = (payload) => ({
  type: THEME_EXPERT_ADD_ATTEMPT,
  payload,
});
export const THEME_EXPERT_ADD_SUCCESS = "THEME_EXPERT_ADD_SUCCESS";
export const themesExpertSpocAddSuccess = (payload) => ({
  type: THEME_EXPERT_ADD_SUCCESS,
  payload,
});

export const THEME_EXPERT_DELETE_ATTEMPT = "THEME_EXPERT_DELETE_ATTEMPT";
export const themesSpocExpertDeleteAttempt = (payload) => ({
  type: THEME_EXPERT_DELETE_ATTEMPT,
  payload,
});
export const THEME_EXPERT_DELETE_SUCCESS = "THEME_EXPERT_DELETE_SUCCESS";
export const themesExpertSpocDeleteSuccess = (payload) => ({
  type: THEME_EXPERT_DELETE_SUCCESS,
  payload,
});

export const THEME_METRICS_GET_ATTEMPT = "THEME_METRICS_GET_ATTEMPT";
export const themesMetricsGetAttempt = (payload) => ({
  type: THEME_METRICS_GET_ATTEMPT,
  payload,
});
export const THEME_METRICS_GET_SUCCESS = "THEME_METRICS_GET_SUCCESS";
export const themesMetricsGetSuccess = (payload) => ({
  type: THEME_METRICS_GET_SUCCESS,
  payload,
});

import { combineEpics, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";

import {
  GET_CUSTOM_FIELDS_ATTEMPT,
  getCustomFieldsSuccess,
  POST_CUSTOM_FIELD_ATTEMPT,
  postCustomFieldsSuccess,
  GET_SEARCH_CUSTOM_FIELDS_ATTEMPT,
  getSearchCustomFieldsSuccess,
  GET_CUSTOM_FIELDS_GROUPS_ATTEMPT,
  getCustomFieldsGroupsSuccess,
  POST_CUSTOM_FIELD_GROUPS_ATTEMPT,
  postCustomFieldsGroupsSuccess,
  POST_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT,
  postCustomFieldAgreementTypeSuccess,
  PUT_CUSTOM_FIELD_GROUPS_ATTEMPT,
  putCustomFieldsGroupsSuccess,
  DELETE_CUSTOM_FIELD_ATTEMPT,
  deleteCustomFieldsSuccess,
  PUT_CUSTOM_FIELD_ATTEMPT,
  putCustomFieldsSuccess,
  DELETE_CUSTOM_FIELD_GROUPS_ATTEMPT,
  deleteCustomFieldsGroupsSuccess,
  GET_CUSTOM_FIELD_GROUP_TEMPLATES_ATTEMPT,
  getCustomFieldGroupTemplatesSuccess,
  GET_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT,
  getCustomFieldAgreementTypeSuccess,
  PUT_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT,
  putCustomFieldAgreementTypeSuccess,
  DELETE_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT,
  deleteCustomFieldAgreementTypeSuccess,
} from "./adminCustomField.action";

import {
  errorHandler,
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../../../common/httpCall";

export const epicGetAdminCustomFields = (action$, state$) =>
  action$.pipe(
    ofType(GET_CUSTOM_FIELDS_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, query, page, pageSize } }) =>
      httpGet({
        call: `custom_field_templates?page=${page}&size=${pageSize}${
          query ? `&query=${query}` : ""
        }`,
      }).pipe(
        map((result) => getCustomFieldsSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicAdminSearchCustomFields = (action$, state$) =>
  action$.pipe(
    ofType(GET_SEARCH_CUSTOM_FIELDS_ATTEMPT),
    switchMap(({ payload: { query, enqueueSnackbar } }) =>
      httpGet({
        call: `custom_field_templates?query=${query}`,
      }).pipe(
        map((result) => getSearchCustomFieldsSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicAdminSearchCustomFieldsGroups = (action$, state$) =>
  action$.pipe(
    ofType(GET_CUSTOM_FIELDS_GROUPS_ATTEMPT),
    switchMap(
      ({ payload: { enqueueSnackbar, query = "", page = 1, pageSize = 20 } }) =>
        httpGet({
          apiVersion: "v2",
          call: `custom_field_groups?size=${pageSize}&page=${page}&query=${query}`,
        }).pipe(
          map((result) => getCustomFieldsGroupsSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicDeleteCustomField = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_CUSTOM_FIELD_ATTEMPT),
    switchMap(({ payload: { customFieldId, enqueueSnackbar } }) =>
      httpDelete({
        call: `custom_field_templates/${customFieldId}`,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Custom field deleted with success", {
              variant: "success",
            });
          }
          return deleteCustomFieldsSuccess({ customFieldId });
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicDeleteCustomFieldGroup = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_CUSTOM_FIELD_GROUPS_ATTEMPT),
    switchMap(({ payload: { customFieldGroupId, enqueueSnackbar, cb } }) =>
      httpDelete({
        call: `custom_field_groups/${customFieldGroupId}`,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Custom field group deleted with success", {
              variant: "success",
            });
          }
          if (cb) {
            cb()
          }
          return deleteCustomFieldsGroupsSuccess({ customFieldGroupId });
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicAddAdminCustomFields = (action$, state$) =>
  action$.pipe(
    ofType(POST_CUSTOM_FIELD_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        call: "custom_field_templates",
        data: {
          custom_field_template: {
            ...data,
          },
        },
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Custom field Added", {
              variant: "success",
            });
          }

          return postCustomFieldsSuccess(result);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );
export const epicAddAdminAgreementType = (action$, state$) =>
  action$.pipe(
    ofType(POST_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        call: "agreement_types",
        data: { ...data },
      }).pipe(
        map((result) => postCustomFieldAgreementTypeSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicAdminGetAgreementTypes = (action$, state$) =>
  action$.pipe(
    ofType(GET_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v1",
        call: `agreement_types`,
      }).pipe(
        map((result) => getCustomFieldAgreementTypeSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicPutAdminAgreementType = (action$, state$) =>
  action$.pipe(
    ofType(PUT_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT),
    switchMap(({ payload: { data, agreement_type_id, enqueueSnackbar } }) =>
      httpPut({
        apiVersion: "v1",
        call: `agreement_types/${agreement_type_id}`,
        data: { ...data },
      }).pipe(
        map((result) => putCustomFieldAgreementTypeSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );
export const epicDeleteAdminAgreementType = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT),
    switchMap(({ payload: { agreement_type_id, enqueueSnackbar } }) =>
      httpDelete({
        apiVersion: "v1",
        call: `agreement_types/${agreement_type_id}`,
      }).pipe(
        map((result) => deleteCustomFieldAgreementTypeSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );
export const epicAddAdminCustomFieldsGroup = (action$, state$) =>
  action$.pipe(
    ofType(POST_CUSTOM_FIELD_GROUPS_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar, cb } }) =>
      httpPost({
        call: "custom_field_groups",
        data: {
          custom_field_group: {
            ...data,
          },
        },
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Custom field group Added", {
              variant: "success",
            });
          }

          if (cb) {
            cb(result);
          }

          return postCustomFieldsGroupsSuccess(result);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicEditAdminCustomFieldsGroup = (action$, state$) =>
  action$.pipe(
    ofType(PUT_CUSTOM_FIELD_GROUPS_ATTEMPT),
    switchMap(({ payload: { data, groupId, enqueueSnackbar } }) =>
      httpPut({
        call: `custom_field_groups/${groupId}`,
        data: {
          custom_field_group: {
            ...data,
          },
        },
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Custom field group edited", {
              variant: "success",
            });
          }
          return putCustomFieldsGroupsSuccess(result);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicEditAdminCustomFieldsTemplate = (action$, state$) =>
  action$.pipe(
    ofType(PUT_CUSTOM_FIELD_ATTEMPT),
    switchMap(({ payload: { data, templateId, enqueueSnackbar } }) =>
      httpPut({
        call: `custom_field_templates/${templateId}`,
        data: {
          custom_field_template: {
            ...data,
          },
        },
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Custom field group edited", {
              variant: "success",
            });
          }
          return putCustomFieldsSuccess(result);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar, []))
      )
    )
  );

export const epicAdminCustomFieldGroupTemplate = (action$, state$) =>
  action$.pipe(
    ofType(GET_CUSTOM_FIELD_GROUP_TEMPLATES_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, groupId } }) =>
      httpGet({
        call: `custom_field_groups/${groupId}`,
      }).pipe(
        map((result) => getCustomFieldGroupTemplatesSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const adminCustomFieldsEpic = combineEpics(
  epicGetAdminCustomFields,
  epicAddAdminCustomFields,
  epicAdminSearchCustomFields,
  epicAdminSearchCustomFieldsGroups,
  epicAddAdminCustomFieldsGroup,
  epicEditAdminCustomFieldsGroup,
  epicDeleteCustomField,
  epicEditAdminCustomFieldsTemplate,
  epicDeleteCustomFieldGroup,
  epicAdminCustomFieldGroupTemplate,
  epicAddAdminAgreementType,
  epicAdminGetAgreementTypes,
  epicPutAdminAgreementType,
  epicDeleteAdminAgreementType
);

export default adminCustomFieldsEpic;

export const GET_AGREEMENT_TYPE_ATTEMPT = "GET_AGREEMENT_TYPE_ATTEMPT";
export const getAgreementType = (payload) => ({
  type: GET_AGREEMENT_TYPE_ATTEMPT,
  payload,
});

export const GET_AGREEMENT_TYPE_SUCCESS = "GET_AGREEMENT_TYPE_SUCCESS";
export const getAgreementTypeSuccess = (payload) => ({
  type: GET_AGREEMENT_TYPE_SUCCESS,
  payload,
});

export const POST_AGREEMENT_TYPE_ATTEMPT = "POST_AGREEMENT_TYPE_ATTEMPT";
export const postAgreementType = (payload) => ({
  type: POST_AGREEMENT_TYPE_ATTEMPT,
  payload,
});

export const POST_AGREEMENT_TYPE_SUCCESS = "POST_AGREEMENT_TYPE_SUCCESS";
export const postAgreementTypeSuccess = (payload) => ({
  type: POST_AGREEMENT_TYPE_SUCCESS,
  payload,
});

export const PUT_AGREEMENT_TYPE_ATTEMPT = "PUT_AGREEMENT_TYPE_ATTEMPT";
export const putAgreementType = (payload) => ({
  type: PUT_AGREEMENT_TYPE_ATTEMPT,
  payload,
});

export const PUT_AGREEMENT_TYPE_SUCCESS = "PUT_AGREEMENT_TYPE_SUCCESS";
export const putAgreementTypeSuccess = (payload) => ({
  type: PUT_AGREEMENT_TYPE_SUCCESS,
  payload,
});

export const DELETE_AGREEMENT_TYPE_ATTEMPT = "DELETE_AGREEMENT_TYPE_ATTEMPT";
export const deleteAgreementType = (payload) => ({
  type: DELETE_AGREEMENT_TYPE_ATTEMPT,
  payload,
});

export const DELETE_AGREEMENT_TYPE_SUCCESS = "DELETE_AGREEMENT_TYPE_SUCCESS";
export const deleteAgreementTypeSuccess = (payload) => ({
  type: DELETE_AGREEMENT_TYPE_SUCCESS,
  payload,
});

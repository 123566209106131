import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BtnType, Button } from "modules/primitives";
import { httpPost } from "../../../../../common/httpCall";
import styles from '../../../tractionAI.module.scss';
import LoadingComponent from "modules/primitives/loading/loading.component";
import { AISetTrendData } from "modules/tractionAI/store/tractionAI.actions";
import RandomAILoader from "modules/tractionAI/components/randomAILoader";

const TractionAITrendFooter = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();

  const {
    tractionAiReducer: { insight: { trend } }
  } = useSelector((state) => state);

  const handleSubmit = () => {
    dispatch(AISetTrendData({
      fetching: true,
      searchByQuery: searchQuery,
      createNewSearchItem: true,
      fetched: false,
    }));

    httpPost({ call: "ai/proxy/streaming?formatted=true", data: { prompt: searchQuery, endpoint: "trend_analysis" } })
      .subscribe(() => {
        setSearchQuery('')
      });
  };

  const onEnterPress = (evt) => {
    if(evt.keyCode === 13 && !evt.shiftKey) {
      evt.preventDefault();
      handleSubmit()
    }
  };

  const getLoadingTemplate = useCallback((data) => {
    if (!data || data?.length === 0) {
      return <RandomAILoader size="big" />
    }

    const lastItem = data[data.length - 1];
    const hasData = lastItem.data.length > 0;

    if (hasData) {
      return <LoadingComponent customText="Fetching Trend details..." />
    }

    return <RandomAILoader size="big" />
  }, [trend.data]);

  return (
    <div className="d-flex flex-row align-items-center w-100 p-2">
      {
        trend.fetching ? (
          <div className="d-flex align-items-center w-100 justify-content-center">
            {getLoadingTemplate(trend.data)}
          </div>
        ) : (
          <>
            <textarea
              className={`border-secondary ${styles.AITextArea}`}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              name="prompt"
              id="prompt"
              rows="3"
              placeholder="Enter details about trend you would like me to research."
              disabled={trend.fetching}
              onKeyDown={onEnterPress}
            />
            <div className="ml-5">
              <Button
                btn={BtnType.REGULAR}
                onClick={handleSubmit}
                disabled={!searchQuery.length}
              >SUBMIT</Button>
            </div>
          </>
        )
      }
    </div>
  );
};

export default React.memo(TractionAITrendFooter);

import { getFilterableResource } from "common/actions/common.action";

const getProjectsFields = (
  dispatch,
  setFilterOptionSearchResult,
  setLoadingProjFieldsFilterOpt
) => {
  dispatch(
    getFilterableResource({
      cb: (res) => {
        setFilterOptionSearchResult((prev) => {
          setLoadingProjFieldsFilterOpt(true);

          return {
            ...prev,
            project_field_values: [
              ...res.response?.map((projField) => {
                if (
                  projField.field_name === "Skills Team" ||
                  projField.field_name ===
                    "Support Skills Team (Implementation)"
                ) {
                  return projField.option_values;
                }
                return [];
              }),
            ].flat(),
            fps_category: [
              ...res.response?.map((projField) => {
                if (projField.field_name === "FPS Category") {
                  return projField.option_values;
                }

                return [];
              }),
            ].flat(),

            founded_by: [
              ...res.response?.map((projField) => {
                if (projField.field_name.includes("Funded By")) {
                  return projField.option_values;
                }
                return [];
              }),
            ].flat(),
            program: [
              ...res.response?.map((projField) => {
                if (projField.field_name.includes("Program")) {
                  return projField.option_values;
                }

                return [];
              }),
            ].flat(),
          };
        });
      },
    })
  );
};
const arrToString = (arr) => {
  return arr.reduce((acc, elem) => {
    return `${acc}${acc.length ? "," : ""}${elem?.index}`;
  }, "");
};
export { getProjectsFields, arrToString };

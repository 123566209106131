import React from "react";
import CloseIcon from "common/icon/closeIcon.component";
import { numberWithCommas } from "common/helper";
import styles from "../../../projectsMain.module.scss";
import { filterOptions } from "../../../projectsMain.constant";

const SelectedFilterTagsRow = ({ selectedFilter, removeFilterTag }) => {
  const findRangeLabel = (filterIndex, type, value) => {
    const rangeArr = filterOptions[filterIndex].ranges?.find(
      (opt) => opt[0] === Number(value)
    );

    if (rangeArr === undefined) {
      return "";
    }
    return `${numberWithCommas(rangeArr[0]) || "0"} -${numberWithCommas(
      rangeArr[1] || "Max."
    )}`;
  };

  return (
    <div className={styles.tagsList}>
      {selectedFilter?.plant_ids?.map((plant) => (
        <div className={styles.tag} key={plant.id}>
          <span>{plant.name}</span>
          <span
            onClick={() => {
              removeFilterTag("plant_ids", plant.id);
            }}
          >
            <CloseIcon />
          </span>
        </div>
      ))}

      {selectedFilter?.area_ids?.map((area) => (
        <div className={styles.tag} key={area.id}>
          <span>{area.name}</span>
          <span
            onClick={() => {
              removeFilterTag("area_ids", area.id);
            }}
          >
            <CloseIcon />
          </span>
        </div>
      ))}

      {selectedFilter?.plant_region_ids?.map((area) => (
        <div className={styles.tag} key={area.id}>
          <span>{area.name}</span>
          <span
            onClick={() => {
              removeFilterTag("plant_region_ids", area.id);
            }}
          >
            <CloseIcon />
          </span>
        </div>
      ))}
      {selectedFilter?.project_owner_ids?.map((mem) => (
        <div className={styles.tag} key={mem.id}>
          <span>{mem.name}</span>
          <span
            onClick={() => {
              removeFilterTag("project_owner_ids", mem.id);
            }}
          >
            <CloseIcon />
          </span>
        </div>
      ))}
      {selectedFilter?.stage_template_ids?.map((mem) => (
        <div className={styles.tag} key={mem.id}>
          <span>{mem.name}</span>
          <span
            onClick={() => {
              removeFilterTag("stage_template_ids", mem.id);
            }}
          >
            <CloseIcon />
          </span>
        </div>
      ))}
      {selectedFilter?.theme_ids?.map((theme) => (
        <div className={styles.tag} key={theme.id}>
          <span>{theme.name}</span>
          <span
            className={styles.tagDelete}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeFilterTag("theme_ids", theme.id);
            }}
          >
            <CloseIcon />
          </span>
        </div>
      ))}
      {selectedFilter?.stage_ids?.map((stage) => (
        <div className={styles.tag} key={stage.id}>
          <span>{stage.name}</span>
          <span
            className={styles.tagDelete}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeFilterTag("stage_ids", stage.id);
            }}
          >
            <CloseIcon />
          </span>
        </div>
      ))}
      {selectedFilter?.topic_ids?.map((topic) => (
        <div className={styles.tag} key={topic.id}>
          <span>{topic.name}</span>

          <span
            className={styles.tagDelete}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeFilterTag("topic_ids", topic.id);
            }}
          >
            <CloseIcon />
          </span>
        </div>
      ))}
      {selectedFilter?.savings?.map((range) => (
        <div className={styles.tag} key={range.gte}>
          <span>
            ${findRangeLabel(3, "savings", range.gte)?.replace("-", "- $")}
          </span>

          <span
            className={styles.tagDelete}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeFilterTag("savings", range);
            }}
          >
            <CloseIcon />
          </span>
        </div>
      ))}
      {selectedFilter?.ratings.map((range) => (
        <div className={styles.tag} key={range.gte}>
          <span>{findRangeLabel(4, "ratings", range.gte)}</span>
          <span
            className={styles.tagDelete}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeFilterTag("ratings", range);
            }}
          >
            <CloseIcon />
          </span>
        </div>
      ))}
      {selectedFilter?.project_field_values.map((field) => (
        <div className={styles.tag} key={`${field + Math.random()}`}>
          <span>{field}</span>
          <span
            className={styles.tagDelete}
            onClick={(e) => {
              const findGroup = () => {
                let group = "";
                Object.keys(selectedFilter).map((key) => {
                  if (
                    Array.isArray(selectedFilter[key]) &&
                    selectedFilter[key].includes(field)
                  ) {
                    group = key;
                  }
                });
                return group;
              };
              e.preventDefault();
              e.stopPropagation();
              removeFilterTag("project_field_values", field, findGroup());
            }}
          >
            <CloseIcon />
          </span>
        </div>
      ))}
      {selectedFilter?.original !== null &&
      selectedFilter?.original !== undefined ? (
        <div className={styles.tag}>
          <span>
            {selectedFilter.original ? "Original Project" : "Replications"}{" "}
          </span>
          <span
            className={styles.tagDelete}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeFilterTag("replicated");
            }}
          >
            <CloseIcon />
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(SelectedFilterTagsRow);

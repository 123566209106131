import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { AIInsightsOptions } from "modules/tractionAI/screens/insights/constants";
import styles from "modules/tractionAI/tractionAI.module.scss";
import { AISelectScreen } from "modules/tractionAI/store/tractionAI.actions";
import TractionAISwot from "modules/tractionAI/screens/insights/swot";
import TractionAITrendReport from "modules/tractionAI/screens/insights/trendReport";
import TractionAICompanySnapshot from "modules/tractionAI/screens/insights/companySnapshot";

const TractionAIInsights = () => {
  const dispatch = useDispatch();

  const {
    tractionAiReducer: { activeScreen, activeSubScreen }
  } = useSelector((state) => state);

  const handleOptionSelect = (option) => {
    dispatch(AISelectScreen({
      activeScreen,
      activeSubScreen: option.id,
    }));
  }

  return (
    <>
      {
        activeSubScreen ? (
          <>
            {
              activeSubScreen === 'swot' && (
                <TractionAISwot />
              )
            }
            {
              activeSubScreen === 'trendReport' && (
                <TractionAITrendReport />
              )
            }
            {
              activeSubScreen === 'companySnapshot' && (
                <TractionAICompanySnapshot />
              )
            }
          </>
        ) : (
          <>
            <h4 className="mb-5">Provide Insights about a trend or technology</h4>
            <div className="d-flex flex-row align-items-start">
              {
                AIInsightsOptions.map(option => (
                  <div
                    key={option.id}
                    className={styles.recommendationItem}
                    onClick={() => handleOptionSelect(option)}
                  >{option.name}</div>
                ))
              }
            </div>
          </>
        )
      }
    </>
  );
};

export default React.memo(TractionAIInsights);

import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { CheckBox, Label } from "../../../../../primitives";

const CheckBoxesFieldsBlock = ({
  options = [],
  setFieldValue,
  isBlockDisabled,
  handleDelete,
  showRequiredBlock,
  updateRequire,
}) => {
  if (options.length <= 0) {
    return "";
  }

  const onChange = (name, val) => {
    if (setFieldValue) {
      setFieldValue(name, val);
    }
  };

  const onRequirementChange = (option) => {
    if (updateRequire) {
      updateRequire(option);
    }
  }

  const isSomethingChecked = options?.some(c => c.isChecked);

  return (
    <div
      className={classNames(styles.checkboxesBlock, {
        [styles.disabled]: isBlockDisabled,
      })}
    >
      {options.map((option, idx) => (
        <div>
          {
            option.readOnly ? (
              <div className="d-flex flex-column justify-content-center align-items-center position-relative">
                {
                  option.showDivider ? (
                    <hr className="d-block w-100 mt-3 mb-3" />
                  ) : null
                }
                <strong>{option.name}</strong>
                {
                  showRequiredBlock && idx === 0 && isSomethingChecked ? (
                    <div className="position-absolute small" style={{right: 0}}>Required Field</div>
                  ) : null
                }
              </div>
            ) : (
              <div className={styles.checkboxWrap} key={`checkbox-field-${option.name}`}>
                <div className="d-flex">
                  <CheckBox
                    isChecked={option.isChecked}
                    onChange={(val) => onChange(option.name, val)}
                    disabled={option.disabled}
                  />
                  <Label>
                    <div
                      className={styles.tagStyle}
                      onClick={() => onChange(option.name, !option.isChecked)}
                    >
                      {option.label}
                    </div>
                  </Label>
                </div>
                <div className="d-flex align-items-center ml-3">
                  {
                    showRequiredBlock && option.isChecked ? (
                      <fieldset id={option.name} className="d-flex">
                        <div className="d-flex mr-3">
                          <input
                            type="radio"
                            id={`${option.name}-yes`}
                            name={option.name}
                            value="yes"
                            checked={option.is_required}
                            onClick={() => onRequirementChange(option)}
                          />
                          <label className="small ml-1" htmlFor={`${option.name}-yes`}>Yes</label>
                        </div>
                        <div className="d-flex">
                          <input
                            type="radio"
                            id={`${option.name}-no`}
                            name={option.name}
                            value="no"
                            checked={!option.is_required}
                            onClick={() => onRequirementChange(option)}
                          />
                          <label className="small ml-1" htmlFor={`${option.name}-no`}>No</label>
                        </div>
                      </fieldset>
                    ) : null
                  }
                  {
                    option.isAdditionalCustomField ? (
                      <span className="cursor-pointer d-block ml-3" onClick={() => handleDelete(option)}>x</span>
                    ) : null
                  }
                </div>
              </div>
            )
          }
        </div>
      ))}
    </div>
  );
};

export default CheckBoxesFieldsBlock;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Loading, Button, BtnType } from "modules/primitives/index";
import actionCable from "actioncable";
import { httpPost, httpGet } from "../../../common/httpCall";
import styles from "./fetchData.module.scss";
import { useModelPopup } from "../../../common/hooks";
import RecordsAddedModal from "./recordsAddedModal";

const FetchData = () => {
  const [response, setResponse] = useState([]);
  const [selectedSfFields, setSelectedSfFields] = useState([]);
  const [selectedTractionFields, setSelectedTractionFields] = useState([]);
  const [selectedSfCustomFields, setSelectedSfCustomFields] = useState([]);
  const [selectedTractionCustomFields, setSelectedTractionCustomFields] =
    useState([]);
  const [, setMappedFields] = useState([]);
  const [payload, setPayload] = useState([]);
  const [attribute, setAttribute] = useState([]);
  const [, setImportBtn] = useState(true);
  const [columnFields, setColumnFields] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterOption, setFilterOption] = useState(null);
  const [opportunityStagePayload, setOpportunityStagePayload] = useState([]);
  const [relationshipStatusPayload, setRelationshipStatusPayload] = useState(
    []
  );
  const [search, setSearch] = useState("");
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [combinedSfFields, setCombinedSfFields] = useState([]);
  const [, setCombinedTractionFields] = useState([]);
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [syncStatus, setSyncSatus] = useState(false);

  const location = useLocation();
  const popup = useModelPopup();
  const history = useHistory();
  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },

    adminIntegrationsReducer: { sync_toggle },
  } = useSelector((state) => state);

  useEffect(() => {
    setAttribute(location.state.currentAttribute);
    setSelectedSfFields(location.state.mappedSalesforceFields);
    setSelectedTractionFields(location.state.mappedTractionFields);
    setMappedFields(location.state.mappedFields);
    setSelectedFilter(location.state.selectedFilter);
    setFilterOption(location.state.selectedFilterOption);
    setOpportunityStagePayload(location.state.opportunityStagePayload);
    setRelationshipStatusPayload(location.state.relationshipStatusPayload);
    setSelectedSfCustomFields(location.state.mappedSalesforceCustomFields);
    setSelectedTractionCustomFields(location.state.mappedTractionCustomFields);
    setSyncSatus(location.state.syncToggle);
  }, []);

  useEffect(() => {
    setCombinedSfFields([...selectedSfFields, ...selectedSfCustomFields]);
    setCombinedTractionFields([
      ...selectedTractionFields,
      ...selectedTractionCustomFields,
    ]);
  }, [
    selectedSfCustomFields,
    selectedTractionCustomFields,
    selectedSfFields,
    selectedTractionFields,
  ]);

  const addOpportunityStageValue = (records) => {
    const opportunityStage = [];
    records.map((value, index) => {
      const currentValue = value;
      opportunityStage.push(
        currentValue?.Opportunities?.records[0].StageName
          ? currentValue?.Opportunities?.records[0].StageName
          : null
      );
      delete currentValue?.Opportunities;
      currentValue.OpportunityStage = opportunityStage[index];
      return true;
    });
  };

  const handleFilterField = (opportunityAdded) => {
    if (filterOption === "Opportunity Stage" || filterOption === null) {
      opportunityAdded.push({ text: "OpportunityStage", value: "string" });
    } else if (filterOption === "Account Owner") {
      opportunityAdded.push({ text: "AccountOwner", value: "string" });
    } else if (filterOption === "Type") {
      opportunityAdded.push({ text: "Type", value: "string" });
    } else return opportunityAdded;
  };

  const setLocalStorage = (count, time, size) => {
    localStorage.setItem(time, new Date().toUTCString());
    localStorage.setItem(size, JSON.stringify(count));
  };

  const recordsAdded = (count) => {
    if (count?.attribute === "account_import") {
      setLocalStorage(
        count.total_count,
        "importAccountTime",
        "importAccountSize"
      );
    } else if (count?.attribute === "contact_import") {
      setLocalStorage(
        count.total_count,
        "importContactTime",
        "importContactSize"
      );
    }
  };

  const addOwnerValue = (records) => {
    const accountOwner = [];
    records.map((value, index) => {
      const currentValue = value;
      accountOwner.push(currentValue?.Owner ? currentValue?.Owner?.Name : null);
      delete currentValue?.Owner;
      currentValue.AccountOwner = accountOwner[index];
      return true;
    });
  };

  const addAccountValue = (records) => {
    const account = [];
    records.map((value, index) => {
      const currentValue = value;
      account.push(
        currentValue?.Account
          ? currentValue?.Account?.Name
          : currentValue?.Account
      );
      currentValue.website = currentValue?.Account?.Website;
      delete currentValue?.Account;
      delete currentValue?.AccountId;
      currentValue.AccountId = account[index];
      return true;
    });
  };

  localStorage.setItem("toggle_status", false);
  useEffect(() => {
    const user_id = session?.id;
    const cable = actionCable.createConsumer(
      "wss://api.traction.network/cable"
    );
    cable.subscriptions.create(
      {
        channel: "NotificationChannel",
        user_id,
      },
      {
        connected: () => {
          console.log("**Connected**");
        },
        received: (response_data) => {
          setData(response_data);
          recordsAdded(response_data);
          popup.show({
            title: "Salesforce Alert!",
            show: true,
            height: "300",
            width: "540",
            component: (
              <RecordsAddedModal
                description="Data has been saved successfully!"
                timeout="1000"
              />
            ),
          });
        },
        rejected: () => {
          console.log("Action cable connection is rejected");
        },
      }
    );
  }, [data]);

  useEffect(() => {
    let opportunityAdded = [];
    opportunityAdded = [...combinedSfFields];
    handleFilterField(opportunityAdded);
    const columnArray = opportunityAdded.map((i) => ({
      name: <h5 className={styles.tableHeader}>{i.text}</h5>,
      selector: (row) => row[`${i.text}`],
    }));
    setColumnFields(columnArray);
    if (combinedSfFields.length > 0 && attribute && selectedCouncil) {
      setLoader(true);
      httpPost({
        call: "sf_integrations/get_data",
        data: {
          selectedSfFields: combinedSfFields,
          currentAttribute: attribute,
          selectedFilter,
          selectedFilterOption: filterOption,
          currentCouncil: selectedCouncil,
        },
      })
        .pipe()
        .subscribe((res) => {
          setImportBtn(false);
          setResponse(res.response);
          if (res?.response?.accounts?.records?.length > 0) {
            if (filterOption === "Account Owner") {
              addOwnerValue(res?.response?.accounts?.records);
            }
            if (filterOption === "Opportunity Stage" || filterOption === null) {
              addOpportunityStageValue(res?.response?.accounts?.records);
            }
            setFilteredRecords(res.response);
          }
          if (res?.response?.contacts?.records?.length > 0) {
            addAccountValue(res?.response?.contacts?.records);
          }
          setLoader(false);
        });
    }
  }, [combinedSfFields, attribute, selectedCouncil]);

  const handleOpportunityStagePayload = () => {
    const selectedOpportunityMap = [];
    payload.map((record) => {
      const temp = opportunityStagePayload?.some((value, index) => {
        if (value.includes(record?.OpportunityStage)) {
          selectedOpportunityMap.push(relationshipStatusPayload[index]?.value);
        }
        return value.includes(record?.OpportunityStage);
      });
      if (!temp) selectedOpportunityMap.push(null);
      return true;
    });
    return selectedOpportunityMap;
  };

  const handleSaveRecords = (selectedAttribute) => {
    if (selectedAttribute === "account") {
      const relationshipMappingPayload = handleOpportunityStagePayload();
      const accountPayloadArray = payload.map((record) => {
        let payloadObject = {};
        const payloadItem = [];
        selectedTractionFields.forEach((field, j) => {
          payloadObject = {
            ...payloadObject,
            [field.text]: record[selectedSfFields[j].text],
          };
          return true;
        });
        payloadItem.push(payloadObject);
        let customFieldObject = {};
        selectedTractionCustomFields.map((val, index) => {
          customFieldObject = {
            ...customFieldObject,
            [val.value]: record[selectedSfCustomFields[index].text],
          };
          return true;
        });
        payloadItem.push(customFieldObject);
        return payloadItem;
      });
      httpPost({
        call: "sf_integrations/save_records",
        data: {
          current_council: selectedCouncil.id,
          accountsPayload: accountPayloadArray,
          relationshipMapPayload: relationshipMappingPayload,
        },
      })
        .pipe()
        .subscribe((res) => {
          if (res.status === 200) {
            popup.show({
              title: "Salesforce Alert!",
              show: true,
              height: "300",
              width: "540",
              component: (
                <RecordsAddedModal
                  description="Saving in Progress!"
                  timeout="1000"
                />
              ),
            });
          }
        });
    } else if (attribute === "contact") {
      const contactPayloadArray = payload.map((record) => {
        let payloadObject = {};
        selectedTractionFields.forEach((field, j) => {
          payloadObject = {
            ...payloadObject,
            [field.text]: record[selectedSfFields[j].text],
          };
          return true;
        });
        payloadObject = {
          ...payloadObject,
          website: record?.website,
          origin: "salesforce",
        };
        return payloadObject;
      });
      httpPost({
        call: "sf_integrations/save_records",
        data: {
          current_council: selectedCouncil.id,
          contactsPayload: contactPayloadArray,
        },
      })
        .pipe()
        .subscribe((res) => {
          if (res.status === 200) {
            popup.show({
              title: "Salesforce Alert!",
              show: true,
              height: "300",
              width: "540",
              component: (
                <RecordsAddedModal
                  description="Saving in Progress!"
                  timeout="1000"
                />
              ),
            });
          }
        });
    }
  };

  const handleBack = () => {
    if (attribute === "account") {
      history.push({
        pathname: "/salesforce/configureAccounts",
        state: { checked: true },
      });
    } else {
      history.push({
        pathname: "/salesforce/configureContacts",
        state: { checked: true },
      });
    }
  };

  const handleClose = () => {
    history.push({
      pathname: "/salesforce/configureData",
    });
  };

  const customStyles = {
    pagination: {
      style: {
        justifyContent: "center",
        select: {
          paddingRight: "20px",
        },
      },
    },
    headCells: {
      style: {
        justifyContent: "center",
        paddingLeft: "50px",
        paddingRight: "50px",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        width: "5rem",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.exported,
      style: {
        backgroundColor: "#D3D3D3",
        "&:hover": {
          cursor: "not-allowed",
        },
      },
    },
  ];

  useEffect(() => {
    if (response) {
      if (response?.accounts?.records) {
        const accounts_result = response?.accounts?.records.filter((item) =>
          item?.Name?.toLowerCase().match(search?.toLowerCase())
        );
        setFilteredRecords({
          ...response,
          accounts: { ...response.accounts, records: accounts_result },
        });
      } else if (response?.contacts?.records) {
        const contacts_result = response?.contacts?.records.filter((item) =>
          item?.FirstName?.toLowerCase().match(search?.toLowerCase())
        );
        setFilteredRecords({
          ...response,
          contacts: { ...response.contacts, records: contacts_result },
        });
      }
    }
  }, [search, response]);

  if (sync_toggle) {
    httpGet({
      call: "sf_integrations/sync_salesforce_accounts",
    })
      .pipe()
      .subscribe((res) => console.log(res.response));
  }

  const handleRecordChange = ({ selectedRows }) => {
    setPayload(selectedRows);
  };

  return (
    <>
      <div>
        <div className={styles.header}>
          <h2>Salesforce Data</h2>
        </div>
        {(response?.accounts?.records?.length > 0 ||
          response?.contacts?.records?.length > 0) && (
          <div className={styles.header}>
            <h2>
              Number of Records {syncStatus ? "Syncing" : "Imported"}:{" "}
              {response?.accounts?.records.length ||
                response?.contacts?.totalSize}
            </h2>
          </div>
        )}
        <div className={styles.topHeading}>
          <Button
            className={styles.backBtn}
            btn={BtnType.FRAME_LESS}
            onClick={() => handleBack()}
          >
            Back
          </Button>
          {payload.length > 0 && !syncStatus && (
            <Button
              className={styles.backBtn}
              btn={BtnType.REGULAR}
              onClick={() => handleSaveRecords(attribute)}
            >
              IMPORT
            </Button>
          )}
          {syncStatus && (
            <h4>
              <b>Sync is on</b>
            </h4>
          )}
          <Button
            className={styles.closeBtn}
            btn={BtnType.FRAME_LESS}
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </div>
      </div>
      {!loader ? (
        <DataTable
          columns={columnFields}
          data={
            filteredRecords?.accounts?.records ||
            filteredRecords?.contacts?.records
          }
          pagination
          paginationPerPage={50}
          paginationRowsPerPageOptions={[50, 100]}
          customStyles={customStyles}
          fixedHeader
          fixedHeaderScrollHeight="450px"
          selectableRows
          selectableRowSelected={(row) => !row.exported}
          selectableRowDisabled={(row) => row.exported}
          selectableRowsHighlight
          highlightOnHover
          onSelectedRowsChange={(e) => handleRecordChange(e)}
          conditionalRowStyles={conditionalRowStyles}
          actions={<div />}
          subHeader
          subHeaderComponent={
            <input
              type="text"
              placeholder="Search record"
              className="w-25 form-control"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          }
        />
      ) : (
        <div className={styles.loader}>
          <Loading />
        </div>
      )}
    </>
  );
};

export default React.memo(FetchData);

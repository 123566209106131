import { useState } from "react";

import { PAGINATION_DEFAULTS } from "modules/companies/constants";

export const useCompanyPagination = () => {
  const [pageSetting, setPageSetting] = useState(PAGINATION_DEFAULTS);

  return {
    setPageSetting,
    pageSetting,
  };
};

import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import classNames from "classnames";
import { withSnackbar } from "notistack";
import {
  initPatientOrganizationModel,
  patientOrganizationValidation,
} from "./newPatientOrganization.constants";
import { useModelPopup } from "../../../common/hooks/index";
import styles from "../../companies/crunchbase/crunchbase.module.scss";
import { ModalBody, ModalFooter } from "../../../application/modal";
import {
  BtnType,
  Button,
  CheckBox,
  CheckType, DropSelect,
  Label,
  TextBox,
} from "../../primitives";
import { patientOrganizationPostAttempt } from "./newPatientOrganization.action";
import {sortCountries} from "../../../common/helper";
import { gskOptionCountries as defaultCountries } from "../../../common/constants";
import UserMultiSelectSearch from "../../../common/components/usersSearch";

const NewPatientOrganizationComponent = ({ enqueueSnackbar }) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const popup = useModelPopup();
  const [isWebSiteRequired, setIsWebSiteRequired] = useState(true);
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const handleCancelClick = (e) => {
    popup.hide();
  };

  const handleOnSubmit = (values) => {
    dispatch(
      patientOrganizationPostAttempt({
        values,
        selectedCouncil,
        enqueueSnackbar,
      }),
    );
    popup.hide();
  };

  const handleNaChange = (value) => {
    setIsWebSiteRequired(value);
  };

  const [selectedValues, setSelectedValues] = useState([]);

    return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initPatientOrganizationModel}
        validationSchema={patientOrganizationValidation(isWebSiteRequired)}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleOnSubmit(values);
        }}
      >
        {({ handleSubmit, handleReset, setFieldValue, ...formikprops }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <ModalBody>
              <div className={styles.crunchBaseFormWrp}>
                <div className={styles.row}>
                  <div className={classNames(styles.col12)}>
                    <Label>Organization Name</Label>
                    <TextBox
                      type="text"
                      name="name"
                      className={styles.textBox}
                      formProps={formikprops}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={classNames(styles.col12)}>
                    <Label>Organization Website</Label>
                    <TextBox
                      type="text"
                      name="web_url"
                      placeholder="Website"
                      className={styles.textBox}
                      formProps={formikprops}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={!isWebSiteRequired}
                    onChange={(val) => handleNaChange(!val)}
                  />
                  <Label onClick={() => handleNaChange(!isWebSiteRequired)}>
                    Website n/a
                  </Label>
                </div>
                <div className={styles.row}>
                  <div className={classNames(styles.col12)}>
                    <Label>Country</Label>
                    <DropSelect
                      type="text"
                      name="country"
                      placeholder="Country"
                      labelField="name"
                      valueField="name"
                      onChange={(val) => setFieldValue(
                        "country",
                        val.obj[0].name
                      )}
                      options={defaultCountries.sort(sortCountries)}
                      className={[styles.textBox, styles.description]}
                      formProps={formikprops}
                    />
                </div>
                </div>
                <div className={styles.row}>
                  <div className={classNames(styles.col12)}>
                    <Label>Relationship Manager</Label>
                    <UserMultiSelectSearch
                      name="relationship_owners_ids"
                      placeholder="Choose relationship member"
                      labelField="full_name"
                      valueField="id"
                      searchBy="full_name"
                      formProps={formikprops}
                      sharedUsers={[]}
                      onChange={(val) => setFieldValue(
                        "relationship_owners_ids",
                        val.map((v) => v.id),
                      )}
                      selectedValues={selectedValues}
                    />
                </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button type="submit" btn={BtnType.REGULAR}>
                Add
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(withSnackbar(NewPatientOrganizationComponent));

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik } from "formik";

import { BtnType, Button, Label, TextBox } from "modules/primitives";
import { ModalFooter } from "application/modal";
import { formInitialValues } from './constant';
import useModelPopup from "common/hooks/modelPopup.hook";
import styles from "../../../../../../common/popups/newItem/newVideos/styles.module.scss";
import LinearProgressWithLabel from "../../../../../../common/components/linearProgressWithLabel";
import DragAndDrop from "../../../../../../common/components/dragAndDrop";
import { videoExtensions } from "modules/events/viewEvent/viewEvent.constant";
import useS3FileUpload from "common/hooks/s3FileUplodar.hook";
import { Icon, mdSize } from "../../../../../../common/icon";
import { editProductDetails } from "modules/companies/product/store/product.actions";

const EditProductVideo = ({ product }) => {
  const popup = useModelPopup();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [uploadValues, setUploadValues] = useState([]);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { fileUpload, fileDelete } = useS3FileUpload();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (product?.video?.length) {
      setVideos([{uri: product.video}]);
      setUploadedVideos([{uri: product.video}]);
    }
  }, [product]);

  const handleCancelClick = () => {
    popup.hide();
  }

  const handleSave = () => {
    const lastVideo = videos[videos.length - 1];

    const payload = {
      companyId: product.company.id,
      productId: product.id,
      data: {
        video: lastVideo?.uri || '',
      },
    };

    dispatch(editProductDetails(payload));
    popup.hide();
  };

  const getFilename = (url) => {
    if (url) {
      return url.split("/").pop();
    }
    return "";
  };

  const getExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  const uploadHandler = async (e, action) => {
    if (forbiddenFiles.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }

    setShowLoading(true);

    setUploadValues((items) => {
      const currentFiles = [...items];

      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name,
      }));
      return [...currentFiles, ...newFiles];
    });

    const fileList = [];
    let dismissedFiles = 0;

    files.forEach(async (file, index, array) => {
      const isLastIndex = Boolean(index === array?.length - 1);
      if (
        file.size <= 500000000
        && videoExtensions.indexOf(getExtension(file.name)) !== -1
      ) {
        const pathname = `srm/${selectedCouncil.id}/companies/${product.company.id}/products/${product.id}/videos/`;
        await fileUpload(file, pathname, setUploadValues, file.name).then(
          (res) => {
            if (res && res.location) {
              if (videos.map(vid => vid.uri).indexOf(res.location) === -1) {
                fileList.push(res.location);
                isLastIndex && setShowLoading(false);
                if (fileList.length === files.length - dismissedFiles) {
                  // setVideos([...videos, ...fileList.map((uri) => ({
                  //   uri,
                  //   name: getFilename(uri),
                  //   council_id: selectedCouncil.id,
                  //   added_by_id: session.id,
                  // }))]);
                  // setUploadedVideos([...uploadedVideos, ...fileList.map((uri) => ({
                  //   uri,
                  //   name: getFilename(uri),
                  //   council_id: selectedCouncil.id,
                  //   added_by_id: session.id,
                  // }))]);

                  setVideos([...fileList.map((uri) => ({
                    uri,
                    name: getFilename(uri),
                    council_id: selectedCouncil.id,
                    added_by_id: session.id,
                  }))]);
                  setUploadedVideos([...fileList.map((uri) => ({
                    uri,
                    name: getFilename(uri),
                    council_id: selectedCouncil.id,
                    added_by_id: session.id,
                  }))]);
                }
              } else {
                enqueueSnackbar("This document was already added.", {
                  variant: "error",
                });
                isLastIndex && setShowLoading(false);
                dismissedFiles++;
                setForbiddenFiles((prevFiles) => {
                  const localFiles = [...prevFiles];
                  localFiles.push({
                    name: file.name,
                    error: "Document already added",
                  });
                  return localFiles;
                });
                setUploadValues((prevState) => {
                  const currentProgress = [...prevState];
                  const index = currentProgress
                    .map((item) => item.name)
                    .lastIndexOf(file.name);
                  currentProgress[index] = {
                    progress: "NOT_ALLOWED",
                    name: file.name,
                  };
                  return currentProgress;
                });
              }
            }
          },
        );
      } else {
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === file.name,
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: file.name,
          };
          return currentProgress;
        });
        isLastIndex && setShowLoading(false);
        if (file.size > 500000000) {
          dismissedFiles++;
          enqueueSnackbar(
            "We could not upload your image because your file size is too big. Please make sure the file is less than 500 MB",
            {
              variant: "error",
            },
          );
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error: "Your file is too large. File size limit: 500MB",
            });
            return localFiles;
          });
        }
        if (videoExtensions.indexOf(getExtension(file.name)) === -1) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error:
                "Extension is not allowed, the accepted file formats are: mp4, mov, wmw, flv, avi, webm, mkw.",
            });
            return localFiles;
          });
          isLastIndex && setShowLoading(false);
          dismissedFiles++;
        }
      }
    });

    e.target.value = "";
  };

  const isEmbedded = (url) => {
    return videoExtensions.indexOf(getExtension(url)) !== -1;
  };

  const checkPlatform = (url) => {
    const regExpYoutube = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const regExpVimeo = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    const regExpFacebook = /^https?:\/\/www\.facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;

    if (url.includes("iframe")) {
      return "iframe";
    }
    if (url.match(regExpYoutube)) {
      return "youtube";
    }
    if (url.match(regExpVimeo)) {
      return "vimeo";
    }
    if (url.match(regExpFacebook)) {
      return "facebook";
    }
  };

  const handleAddEmbededCode = (e, formProps) => {
    if (videos) {
      if (
        checkPlatform(formRef?.current?.values.embedCode)
        && !isEmbedded(formRef?.current?.values.embedCode)
      ) {
        if (videos.map(el => el.uri).indexOf(formRef?.current?.values.embedCode) === -1) {
          const newEmbed = formRef?.current?.values.embedCode.trim();
          formRef.current.values.embedCode = "";
          // setVideos([...videos, {
          //   uri: newEmbed,
          //   name: getFilename(newEmbed),
          //   council_id: selectedCouncil.id,
          //   added_by_id: session.id,
          // }]);
          // setUploadedVideos([...uploadedVideos, newEmbed]);

          setVideos([{
            uri: newEmbed,
            name: getFilename(newEmbed),
            council_id: selectedCouncil.id,
            added_by_id: session.id,
          }]);
          setUploadedVideos([newEmbed]);
        } else {
          enqueueSnackbar("This video was already added.", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar(
          "External video links should be from Youtube, Facebook or Vimeo",
          {
            variant: "error",
          },
        );
      }
    }
  };

  const checkIfS3 = (url) => {
    const match = /(s3-|s3\.)?(.*)\.amazonaws\.com/g.exec(url);

    if (match) {
      return match[0];
    }
  };

  const handleDeleteVideo = async (e, index) => {
    e.stopPropagation();

    if (checkIfS3(videos[index].uri)) {
      const pathname = `srm/${selectedCouncil.id}/companies/${product.company.id}/products/${product.id}/videos/${getFilename(videos[index].uri)}`;

      await fileDelete(pathname).then(() => {
        // const newVideos = videos.filter((_, idx) => idx !== index);
        setVideos([]);
        setUploadedVideos([]);
      });
    } else {
      setVideos([]);
      setUploadedVideos([]);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={formInitialValues}
        onSubmit={handleSave}
      >
        {({ values, handleSubmit, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <div className="px-4">
              {uploadValues?.length
                ? uploadValues.map(
                  (bar, index) =>
                    bar &&
                    bar?.progress !== "NOT_ALLOWED" && (
                      <div className={styles.loaderContainer}>
                        <div className={styles.loaderNamingWrp}>
                          {" "}
                          <div className={styles.fileName}>{uploadValues[index]?.name}</div>
                          <LinearProgressWithLabel
                            mode={uploadValues[index]?.progress === "FAILED" && "error"}
                            value={
                              uploadValues[index]?.progress === "FAILED"
                                ? 100
                                : uploadValues[index]?.progress || 0
                            }
                          />
                        </div>
                      </div>
                    )
                )
                : null}

              <DragAndDrop
                uploadHandler={uploadHandler}
                loading={showLoading}
                customText="drag and drop videos here"
              />

              {forbiddenFiles?.length ? (
                <div className={styles.forbiddenfileContainer}>
                  Files that weren't allowed:{" "}
                  {forbiddenFiles.map((file) => (
                    <span className={styles.forbiddenfileName}>
                        {`${file.name}: `}
                      <span className={styles.errorText}> {file.error}</span>
                    </span>
                  ))}
                </div>
              ) : null}

              <div className="d-flex align-items-center">
                <div className="d-flex flex-grow-1 flex-column mr-2">
                  <Label>Embed code</Label>
                  <TextBox
                    type="text"
                    name="embedCode"
                    placeholder="Paste embed code"
                    formProps={{ ...formikprops, values }}
                    className={styles.textBox}
                  />
                </div>
                {formRef?.current?.values?.embedCode && (
                  <Button
                    type="button"
                    btn={BtnType.FRAME_LESS}
                    onClick={(e) => handleAddEmbededCode(e, formikprops)}
                  >
                    Validate
                  </Button>
                )}
              </div>

              {
                videos?.length ? (
                  <div className="mt-3 mb-4">
                    <Label>Uploaded Videos</Label>
                    {
                      videos.map((video, index) => (
                        <div className="small mt-2" key={video.uri}>
                          {video.uri}
                          <Icon
                            {...mdSize}
                            className="ml-3"
                            icon="icn-button-delete"
                            onClick={(e) => handleDeleteVideo(e, index)}
                          />
                        </div>
                      ))
                    }
                  </div>
                ) : null
              }
            </div>
            <ModalFooter>
              <Button
                className="mr-3"
                btn={BtnType.FRAME_LESS}
                onClick={handleCancelClick}
                disabled={showLoading}
              >
                Cancel
              </Button>
              <Button
                disabled={showLoading}
                type="submit"
                onClick={handleSubmit}
                btn={BtnType.REGULAR}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditProductVideo;

import React, { memo, useCallback, useEffect, useState } from "react";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import SCReview from "common/handleSCReview";
import {
  appSliderSettingSet,
  highlightItemWithIdAction,
} from "application/app.action";
import { sliderSetting } from "application/app.constants";
import style from "./newCompanyItem.module.scss";
import { MoreOptions } from "../../../../../primitives";
import { EMPLOYEES_ENUM } from "../../../../../../common/constants";
import {
  editDetails,
  editDetailsSuccess,
} from "../../../../company/company.action";
import { companyMoreOptions } from "../../../companyMain.constant";
import {
  companyUnFollow,
  suggestionFollow,
} from "../../../../../../common/actions/common.action";
import AddToListComponent from "../../../../../lists/components/addToList";
import { useModelPopup } from "../../../../../../common/hooks";
import AddCompanyComponent from "../../../addToProject";
import { httpGet } from "../../../../../../common/httpCall";
import { CompanyRatings, CompanyRatingsHeader } from "../newCompanyRatings";
import { checkViewerRole } from "../../../../../../common/checkers/viewerChecker";
import AddToIdeaComponent from "../../../addToIdea/addToIdea.component";
import AddToThemeComponent from "../../../../../themes/themesCommon/addCompanyToTheme";
import checkThemePipelinePermissions from "../../../../../../common/checkThemePipelinePermissions";
import checkUserThemeCompanyPermission from "../../../../../../common/checkUserThemeCompanyPermissions";
import AddProductToIdeaComponent from "modules/companies/product/components/addProductToIdea/addProductToIdea.component";
import AddProductToTheme from "modules/companies/product/components/addProductToTheme";
import AddProductToList from "modules/companies/product/components/addProductToList";
import { editProductDetails } from "modules/companies/product/store/product.actions";

const NewCompanyItem = ({
  company,
  currentYear,
  history,
  dispatch,
  handleRelationShipChange,
  isHPCouncil,
  isIDBCouncil,
}) => {
  const {
    authReducer: { session },
    appReducer: { relationShipStatuses },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const { enqueueSnackbar } = useSnackbar();
  const [stage, setStage] = useState(null);
  const [inestmentStage, setInvestmentStage] = useState(null);

  const [followingId, setFollowingId] = useState(
    company.current_user_follow_relationship_id
  );
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [optionsList, setOptionsList] = useState(companyMoreOptions);
  const popup = useModelPopup();

  useEffect(() => {
    if (blockFunctionality) {
      const filteredOptions = companyMoreOptions.filter(
        (option) => option.text !== "Add to project"
      );
      setOptionsList(filteredOptions);
    }
  }, [blockFunctionality]);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (company?.council_relationship_stage_id && relationShipStatuses) {
      const getStageDetails = relationShipStatuses.find(
        (s) => s.id || s.customId === company?.council_relationship_stage_id
      );
      setStage(getStageDetails);
    }
  }, [company, relationShipStatuses]);

  useEffect(() => {
    if (
      company?.theme_investment_stage ||
      (company?.theme_investment_stage_id &&
        selectedCouncil?.theme_investment_stages)
    ) {
      const getInvestmentStages = selectedCouncil?.theme_investment_stages.find(
        (s) =>
          s.id === company?.theme_investment_stage?.id ||
          s.id === company?.theme_investment_stage_id
      );
      setInvestmentStage(getInvestmentStages);
    }
  }, [company, selectedCouncil?.theme_investment_stages]);

  const handleCompanyClick = () => {
    if (company.is_product) {
      history.push(
        `/companies/${company.company_slug || company.company_id}/products/${
          company.slug
        }`
      );
      return;
    }

    localStorage.setItem("company", JSON.stringify(company));
    history.push(`/companies/${company.slug || company.id}/overview`);
  };

  const handleStageSelect = useCallback(
    (evt) => {
      const { value } = evt.target;
      const getStageDetails = relationShipStatuses.find(
        (s) => s.id || s.slug === value
      );
      if (
        checkThemePipelinePermissions(
          getStageDetails?.name || "none",
          session?.council_role,
          selectedCouncil?.name,
          session?.decision_maker_access,
          company.council_relationship_stage_name
        )
      ) {
        setStage(evt.target.value);
        const data = {
          council_relationship_stage_id:
            relationShipStatuses.filter(
              (stage) => stage.slug === evt.target.value
            )[0]?.customId || null,
        };

        if (company.is_product) {
          const payload = {
            companyId: company.company_id,
            productId: company.id,
            data: {
              relationship_stage_id: data.council_relationship_stage_id,
            },
            cb: () => {
              const newCompanyPayload = {
                ...company,
                council_relationship_stage_id:
                  data.council_relationship_stage_id,
              };

              dispatch(editDetailsSuccess(newCompanyPayload));
            },
          };

          dispatch(editProductDetails(payload));

          return;
        }

        dispatch(
          editDetails({
            companyId: company.id,
            councilId: selectedCouncil.id,
            data,
            enqueueSnackbar,
            council: selectedCouncil?.name,
          })
        );

        const timeout = setTimeout(() => {
          handleRelationShipChange(true);
          clearTimeout(timeout);
        }, 1000);
      } else {
        enqueueSnackbar(
          "You are not allowed to move companies in this stage ",
          {
            variant: "error",
          }
        );
      }
    },
    [company, selectedCouncil, dispatch]
  );
  // FUNCTION TO CHANGE INVESTMENT CHANGE
  // const handleChangeInvestmentStage = useCallback(
  //   (evt) => {
  //     const data = {
  //       theme_investment_stage_id:
  //         selectedCouncil?.theme_investment_stages.filter(
  //           (stage) => stage.slug === evt.target.value
  //         )[0]?.id || null,
  //     };
  //     setInvestmentStage(evt.target.value);

  //     dispatch(
  //       editDetails({
  //         companyId: company.id,
  //         councilId: selectedCouncil.id,
  //         data,
  //         enqueueSnackbar,
  //         council: selectedCouncil?.name,
  //         fromFirmenich: selectedCouncil?.name === "Firmenich",
  //       })
  //     );
  //   },
  //   [company, selectedCouncil, dispatch]
  // );

  const handleChangeStage = (e) => {
    if (selectedCouncil?.name === "Kyndryl" && e.target.value === "sc_review") {
      const { value } = e.target;
      const getStageDetails = relationShipStatuses.find(
        (s) => s.id || s.slug === value
      );
      if (
        !checkThemePipelinePermissions(
          getStageDetails?.name || "none",
          session?.council_role,
          selectedCouncil?.name,
          session?.decision_maker_access,
          company?.council_relationship_stage_name
        )
      ) {
        handleStageSelect(e);
        return;
      }
      popup.show({
        title: " ",
        show: true,
        height: "300",
        width: "540",
        component: (
          <SCReview
            handleStageSelect={handleStageSelect}
            e={e}
            popup={popup}
            companySlug={company?.slug}
          />
        ),
      });
    } else {
      handleStageSelect(e);
    }
  };
  const handleMoreOptionClick = (val, company) => {
    if (val === 1) {
      if (!followingId?.length) {
        makeFollow(company);
      }
    }
    if (val === 2) {
      handleAddCompanyToList();
    }
    if (val === 3) {
      handleAddCompanyClick();
    }
    if (val === 5) {
      if (followingId?.length) {
        makeUnfollow();
      }
    }
    if (val === 6) {
      if (company.is_product) {
        const productObj = {
          ...company,
          ideas: company.ideas_ids?.map((id) => ({ id })) || [],
        };

        popup.show({
          title: "Add To Idea",
          component: (
            <AddProductToIdeaComponent
              product={productObj}
              enqueueSnackbar={enqueueSnackbar}
            />
          ),
        });

        return;
      }

      popup.show({
        title: "Add To Idea",
        component: (
          <AddToIdeaComponent
            company={company}
            selectedCouncil={selectedCouncil}
            enqueueSnackbar={enqueueSnackbar}
          />
        ),
      });
    }

    if (val === 7) {
      if (company.is_product) {
        const productObj = {
          ...company,
          ideas: company.themes_ids?.map((id) => ({ id })) || [],
        };

        popup.show({
          title: `Add To ${
            selectedCouncil?.settings?.themes_display_name_singular || "Theme"
          }`,
          component: <AddProductToTheme product={productObj} />,
        });

        return;
      }

      popup.show({
        title: `Add To ${
          selectedCouncil?.settings?.themes_display_name_singular || "Theme"
        }`,
        component: (
          <AddToThemeComponent
            company={company}
            selectedCouncil={selectedCouncil}
            enqueueSnackbar={enqueueSnackbar}
          />
        ),
      });
    }
  };

  const makeUnfollow = () => {
    dispatch(
      companyUnFollow({
        data: {
          following_id: followingId,
        },
        enqueueSnackbar,
      })
    );

    setFollowingId(null);
  };

  const handleAddCompanyClick = (e) => {
    popup.show({
      title: "Add a Company to Project",
      component: (
        <AddCompanyComponent
          companyId={company.id}
          enqueueSnackbar={enqueueSnackbar}
          isProduct={company.is_product}
        />
      ),
    });
  };

  const handleAddCompanyToList = () => {
    if (company.is_product) {
      const productObj = {
        ...company,
        lists: company.lists_ids?.map((id) => ({ id })) || [],
      };

      popup.show({
        title: "Add To List",
        component: (
          <AddProductToList
            product={productObj}
            enqueueSnackbar={enqueueSnackbar}
          />
        ),
      });

      return;
    }

    popup.show({
      title: "Add To List",
      component: (
        <AddToListComponent
          companyId={company.id}
          enqueueSnackbar={enqueueSnackbar}
          getMyOnlyLists
        />
      ),
    });
  };

  const makeFollow = (company) => {
    dispatch(
      suggestionFollow({
        data: {
          ...company,
          company_id: company.id,
          name: company.name,
        },
        enqueueSnackbar,
        cb: (res) => {
          if (res.response) {
            setFollowingId(
              res.response.follow_relationship_id || res.response.following_id
            );
          }
        },
      })
    );
  };

  const handleRateClick = useCallback(() => {
    httpGet({
      apiVersion: "v2",
      call: `companies/${company.id}/ratings`,
    })
      .pipe()
      .subscribe((res) => {
        if (res.response) {
          dispatch(highlightItemWithIdAction(company.id));
          openRatingsWindow(res.response);
        }
      });
  }, [company]);

  const setStylesForCol = () => {
    if (
      selectedCouncil?.name === "Firmenich" &&
      session?.theme_investment_access
    ) {
      return style.firmenichColValue;
    }
    return style.columnValue;
  };

  const openRatingsWindow = useCallback(
    (ratingsData) => {
      dispatch(
        appSliderSettingSet({
          ...sliderSetting,
          show: true,
          height: "300",
          width: "540",
          header: <CompanyRatingsHeader company={company} />,
          component: (
            <CompanyRatings company={company} ratingsData={ratingsData} />
          ),
        })
      );
    },
    [company]
  );
  const checkIfTractionMatchIsAllowed = useCallback(() => {
    if (selectedCouncil?.name === "Kyndryl") {
      return false;
    }
    if (company.themes_ids?.length > 0) {
      return false;
    }

    return true;
  });
  return (
    <div className={`${style.companyWrapper}`}>
      {isIDBCouncil ? (
        <span className={setStylesForCol()} onClick={handleCompanyClick}>
          {company.location_country ? `${company.location_country}` : "-"}
        </span>
      ) : (
        <span className={setStylesForCol()} onClick={handleCompanyClick}>
          {company.total_fundings_sum
            ? `$${company.total_fundings_sum.toLocaleString("en-US")}`
            : "-"}
        </span>
      )}
      {selectedCouncil?.name !== "Kyndryl" && (
        <span className={setStylesForCol()} onClick={handleCompanyClick}>
          {company.years_in_operation
            ? `${currentYear - company.years_in_operation}`
            : "-"}
        </span>
      )}
      <span className={setStylesForCol()} onClick={handleCompanyClick}>
        {company.num_employees_enum
          ? `${EMPLOYEES_ENUM[company.num_employees_enum]}`
          : "-"}
      </span>
      {selectedCouncil?.name !== "Kyndryl" ? (
        <>
          {!company.is_product ? (
            <span
              className={`${setStylesForCol()} ${style.score}`}
              onClick={handleRateClick}
            >
              {company.council_company_score || "RATE"}
            </span>
          ) : (
            <span className={`${setStylesForCol()} ${style.score}`}>-</span>
          )}
        </>
      ) : (
        <span className={`${setStylesForCol()}`} onClick={handleCompanyClick}>
          {company?.partner_type
            ? company?.partner_type.slice(0, 1).toUpperCase() +
              company?.partner_type.slice(1, company?.partner_type.length)
            : "-"}
        </span>
      )}
      {session?.theme_investment_access &&
        selectedCouncil?.name === "Firmenich" && (
          <span className={`${setStylesForCol()} ${style.investmentStageCol}`}>
            {company.theme_investment_stage_name || "-"}
          </span>
        )}
      <span className={`${setStylesForCol()} ${style.status}`}>
        <Select
          value={stage?.slug || "none"}
          onChange={handleChangeStage}
          disabled={blockFunctionality}
          style={{
            backgroundColor: stage?.colors?.background || "",
            color: stage?.colors?.text || "",
          }}
          className={
            !stage?.slug || stage?.slug === "none"
              ? style.tractionMatch
              : style.detailsRelationship
          }
          disableUnderline
        >
          {checkIfTractionMatchIsAllowed() && (
            <MenuItem
              style={
                !checkThemePipelinePermissions(
                  "none",
                  session?.council_role,
                  selectedCouncil?.name,
                  session?.decision_maker_access,
                  company.council_relationship_stage_name
                )
                  ? { background: "#929ba4", color: "white" }
                  : {}
              }
              key="none"
              value="none"
            >
              Traction Match
            </MenuItem>
          )}

          {relationShipStatuses.map((item) => (
            <MenuItem
              style={
                !checkThemePipelinePermissions(
                  item.name,
                  session?.council_role,
                  selectedCouncil?.name,
                  session?.decision_maker_access,
                  company.council_relationship_stage_name
                )
                  ? { background: "#929ba4", color: "white" }
                  : {}
              }
              key={item.slug}
              value={item.slug}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </span>
      {selectedCouncil?.name === "Kyndryl" && (
        <span
          className={setStylesForCol()}
          // onClick={handleCompanyClick}
        >
          {company?.project_stage?.name || "-"}
        </span>
      )}
      {isHPCouncil && (
        <>
          <span className={setStylesForCol()} onClick={handleCompanyClick}>
            {company.priority ? company.priority : "-"}
          </span>
          <span className={setStylesForCol()} onClick={handleCompanyClick}>
            {company.priority_engagement ? company.priority_engagement : "-"}
          </span>
          <span className={setStylesForCol()} onClick={handleCompanyClick}>
            {company.next_steps ? company.next_steps : "-"}
          </span>
          <span className={setStylesForCol()} onClick={handleCompanyClick}>
            {company.area ? company.area : "-"}
          </span>
          <span className={setStylesForCol()} onClick={handleCompanyClick}>
            {company.type ? company.type : "-"}
          </span>
          <span className={setStylesForCol()} onClick={handleCompanyClick}>
            {company.materials ? company.materials : "-"}
          </span>
          <span className={setStylesForCol()} onClick={handleCompanyClick}>
            {company.source ? company.source : "-"}
          </span>
        </>
      )}
      <div className={style.colMore}>
        <MoreOptions
          options={optionsList
            .filter((option) => {
              if (
                (selectedCouncil?.name === "GSK" &&
                  option.text === "Add to Idea") ||
                checkUserThemeCompanyPermission(
                  session?.council_role,
                  option.text,
                  selectedCouncil?.themes_connected
                ) ||
                (selectedCouncil?.settings?.ideas_connected === false &&
                  option.text === "Add to Idea")
              ) {
                return false;
              }

              if (company.is_product) {
                return option.text !== "Follow" && option.text !== "Unfollow";
              }

              return (
                option.text !== (followingId?.length ? "Follow" : "Unfollow")
              );
            })
            .map((opt) => {
              if (opt.text === "Add to theme") {
                return {
                  ...opt,
                  text: `Add to ${
                    selectedCouncil?.settings?.themes_display_name_singular ||
                    "theme"
                  }`,
                };
              }
              return opt;
            })}
          className={style.moreOptWrp}
          onClick={(val) => handleMoreOptionClick(val, company)}
        />
      </div>
    </div>
  );
};

export default memo(NewCompanyItem);

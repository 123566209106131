import {
  COMPANY_GET_SUCCESS,
  COMPANY_GET_ATTEMPT,
  COMPANY_SET,
  COMPANY_GET_ACTIVITIES_SUCCESS,
  COMPANY_GET_ACTIVITIES_ATTEMPT,
  COMPANY_ADD_ACTIVITY_SUCCESS,
  GET_COMPANY_DETAILS_FIELDS,
  GET_COMPANY_DETAILS_FIELDS_SUCCESS,
  POST_CUSTOM_FIELDS,
  POST_CUSTOM_FIELDS_SUCCESS,
  GET_COMPANY_GROUP_FIELDS,
  GET_COMPANY_GROUP_FIELDS_SUCCESS,
  COMPANY_PUT_DETAILS_FIELDS_SUCCESS,
  COMPANY_PUT_DETAILS_FIELDS_ATTEMPT,
  COMPANY_DELETE_DETAILS_FIELDS_ATTEMPT,
  COMPANY_DELETE_DETAILS_FIELDS_SUCCESS,
  COMPANY_DELETE_ACTIVITY_ATTEMPT,
  COMPANY_DELETE_ACTIVITY_SUCCESS,
  COMPANY_PUT_ACTIVITY_ATTEMPT,
  COMPANY_PUT_ACTIVITY_SUCCESS,
  COMPANY_RATING_SAVE_SUCCESS,
  COMPANY_RATING_EDIT_SUCCESS,
  COMPANY_DELETE_RELATIONSHIP_SUCCESS,
  COMPANY_ADD_RELATIONSHIP_SUCCESS,
  COMPANY_DELETE_CONTACT_SUCCESS,
  COMPANY_EDIT_DETAILS_ATTEMPT,
  COMPANY_EDIT_DETAILS_SUCCESS,
  COMPANY_SHARE_ATTEMPT,
  COMPANY_SHARE_SUCCESS,
  COMPANY_REQUEST_RESEARCH_ATTEMPT,
  COMPANY_REQUEST_RESEARCH_SUCCESS,
  COMPANY_EDIT_RELATIONSHIP_ATTEMPT,
  COMPANY_EDIT_RELATIONSHIP_SUCCESS,
  COMPANY_GET_PROJECT_TASKS_SUCCESS,
  COMPANY_GET_PROJECT_TASKS_ATTEMPT,
  SELECTED_COMPANY_CLEAR,
  COMPANY_DELETE_CONTACT_ATTEMPT,
  SET_CUSTOM_FIELD_LOADING,
  COMPANY_ADD_RELATIONSHIP_ATTEMPT,
  COMPANY_DELETE_RELATIONSHIP_ATTEMPT,
  CLEAR_COMPANY_LOADING,
  COMPANY_GET_CUSTOMERS_ATTEMPT,
  COMPANY_GET_CUSTOMERS_SUCCESS,
  COMPANY_DETAILS_UPDATE_CF,
  CATCH_ERROR_SUCCESS,
  COMPANY_GET_RATING_NOTES_ATTEMPT,
  COMPANY_GET_RATING_NOTES_SUCCESS,
  COMPANY_ADD_RATING_NOTES_ATTEMPT,
  COMPANY_ADD_RATING_NOTES_SUCCESS,
  COMPANY_DELETE_RATING_NOTES_ATTEMPT,
  COMPANY_DELETE_RATING_NOTES_SUCCESS,
  COMPANY_EDIT_RATING_NOTES_ATTEMPT,
  COMPANY_EDIT_RATING_NOTES_SUCCESS,
  COMPANY_AGREEMENT_SHOULD_OPEN_ATTEMPT,
  COMPANY_AGREEMENT_SHOULD_OPEN_SUCCESS,
  COMPANY_AGREEMENT_SHOULD_REDIRECT,
  COMPANY_AGREEMENT_SHOULD_CLEAR,
  COMPANY_SET_DOWNLOAD_PARAMS,
  SHOULD_UPDATE_COMPANY_PROJECT_TASKS,
  GET_COMPANY_CONTACTS_SUCCESS,
  GET_COMPANY_CONTACTS_ATTEMPT,
  COMPANY_CONTACT_INVITE_SUCCESS,
  COMPANY_DELETE_DETAILS_TEMPLATE_FIELDS_SUCCESS,
  COMPANY_DELETE_DETAILS_TEMPLATE_FIELDS_ATTEMPT,
  COMPANY_PRODUCTS_GET_ATTEMPT_SUCCESS,
  COMPANY_PRODUCT_ADD_ATTEMPT_SUCCESS,
  COMPANY_PRODUCT_DELETE_SUCCESS,
  COMPANY_PRODUCTS_GET_ATTEMPT,
  SET_DETAILS_CUSTOM_GROUPS,
  GET_CUSTOM_GROUP_FIELDS_SUCCESS,
  SAVE_CUSTOM_FIELD_TABLE_ROW_SUCCESS,
  DELETE_CUSTOM_FIELD_TABLE_ROW_SUCCESS,
  EDIT_CUSTOM_FIELD_TABLE_ROW_CELLS_SUCCESS,
  COMPANY_CUSTOM_FIELD_SCROLL_TO,
  SAVE_PREV_URL,
  GET_COMPANY_TAGS_SUCCESS,
  GET_COMPANY_ACTIVITIES_ATTEMPT,
  GET_COMPANY_ACTIVITIES_SUCCESS,
  GET_CUSTOM_FIELD_HISTORY_SUCCESS,
  GET_CUSTOM_FIELD_HISTORY_ATTEMPT,
} from "./company.action";
import { apiStatus, pageMeta } from "../../../common/httpCall";
import { GET_TRACTION_COMPANY_BY_ID_SUCCESS } from "../../tractionAdmin/companies/tractionCompanies.actions";
import {
  COMPANY_ADD_CONTACT_SUCCESS,
  COMPANY_EDIT_CONTACT_SUCCESS,
} from "../../people/externalPeople/companyContacts.action";
import {
  ADD_FUNDING_ITEM_SUCCESS,
  ADD_LEADERSHIP_ITEM_SUCCESS,
  COMPANY_UNFOLLOW_SUCCESS,
  DELETE_LEADERSHIP_ITEM_SUCCESS,
  DELETE_TOTAL_FUNDING_ITEM_SUCCESS,
  EDIT_FUNDING_ITEM_SUCCESS,
  EDIT_LEADERSHIP_ITEM_SUCCESS,
  GET_FUNDING_ITEMS_SUCCESS,
  GET_LEADERSHIP_ITEMS_SUCCESS,
  SUGGESTION_FOLLOW_SUCCESS,
} from "../../../common/actions/common.action";

const initState = {
  companyById: {},
  selectedCompany: {},
  companyActivities: [],
  loading: false,
  activityAdded: false,
  activitiesPageNr: {},
  companyDetails: [],
  customFieldAdded: false,
  customFieldGroups: [],
  updateCompany: false,
  companyProjectTasks: [],
  customFieldLoadingId: "",
  companyLoading: true,
  companyDetailsLoading: false,
  activityLoading: false,
  companyCustomers: [],
  loadingNotes: false,
  companyRatingNotes: [],
  shouldUpdateRatingNotes: false,
  shouldOpenUnusedAgreement: {
    accessFlag: false,
    type: undefined,
    mode: "add",
  },
  listId: "",
  listItemToUpdate: {},
  companyDownloadDetails: [],
  companyIsDownloading: false,
  shouldUpdateProjectTasks: false,
  companyContacts: [],
  shouldUpdate: false,
  leadership_members: [],
  funding_rounds: [],
  loadingId: "",
  products: {
    loading: true,
    data: [],
    pagination: {
      pageSize: 20,
      current: 1,
      hideOnSinglePage: true,
      showTitle: false,
      total: 0,
    },
    sort_order: null,
    sort_attribute: null,
  },
  newCustomFieldGroups: [],
  customScrollToId: "",
  prevPagePath: "",
  customCompanyTags: [],
  companyActivity: [],
  activitiesPagination: {},
  activitiesLoading: false,
  customFieldHistory: [],
  customFieldHistoryMeta: {},

  customFieldHistoryLoading: false,
};

const companyReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case COMPANY_PRODUCT_DELETE_SUCCESS: {
      return {
        ...state,
        products: {
          ...state.products,
          data: state.products.data.filter((p) => p.id !== payload),
          pagination: {
            ...state.products.pagination,
            total: state.products.pagination.total - 1,
          },
        },
      };
    }
    case COMPANY_PRODUCT_ADD_ATTEMPT_SUCCESS: {
      let data;

      if (payload.shouldUpdate) {
        data = state.products.data.map((p) => {
          if (p.id === payload.id) {
            return {
              ...p,
              ...payload,
            };
          }

          return p;
        });
      } else {
        data = [...state.products.data, payload];
      }

      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          data,
          pagination: {
            ...state.products.pagination,
            total: !payload.shouldUpdate
              ? state.products.pagination.total + 1
              : state.products.pagination.total,
          },
        },
      };
    }

    case COMPANY_PRODUCTS_GET_ATTEMPT: {
      return {
        ...state,
        products: {
          ...state.products,
          loading: true,
        },
      };
    }

    case COMPANY_PRODUCTS_GET_ATTEMPT_SUCCESS: {
      const pagination = pageMeta(payload);

      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          data: payload.response || [],
          pagination: {
            ...state.products.pagination,
            total: pagination.total,
            current: pagination.page,
          },
          sort_order: payload.sort_order,
          sort_attribute: payload.sort_attribute,
        },
      };
    }

    case COMPANY_UNFOLLOW_SUCCESS: {
      if (state.selectedCompany) {
        return {
          ...state,
          selectedCompany: {
            ...state.selectedCompany,
            current_user_follow_relationship_id: null,
          },
        };
      }

      return state;
    }

    case SUGGESTION_FOLLOW_SUCCESS: {
      if (
        state.selectedCompany &&
        payload?.response?.following?.company_id === state.selectedCompany?.id
      ) {
        return {
          ...state,
          selectedCompany: {
            ...state.selectedCompany,
            current_user_follow_relationship_id:
              payload.response.follow_relationship_id,
          },
        };
      }

      return state;
    }

    case GET_LEADERSHIP_ITEMS_SUCCESS: {
      if (state.selectedCompany?.id) {
        return {
          ...state,
          leadership_members: payload.response,
        };
      }

      return state;
    }

    case ADD_LEADERSHIP_ITEM_SUCCESS: {
      if (state.selectedCompany?.id) {
        return {
          ...state,
          leadership_members: [
            payload.response,
            ...(state.leadership_members || []),
          ],
        };
      }

      return state;
    }

    case DELETE_LEADERSHIP_ITEM_SUCCESS: {
      if (state.selectedCompany?.id) {
        return {
          ...state,
          leadership_members: state.leadership_members.filter(
            (f) => f.id !== payload.id
          ),
        };
      }

      return state;
    }

    case EDIT_LEADERSHIP_ITEM_SUCCESS: {
      if (state.selectedCompany?.id) {
        return {
          ...state,
          leadership_members: state.leadership_members.map((f) => {
            if (f.id === payload.response.id) {
              return {
                ...f,
                ...payload.response,
              };
            }

            return f;
          }),
        };
      }

      return state;
    }

    case GET_FUNDING_ITEMS_SUCCESS: {
      if (state.selectedCompany?.id) {
        return {
          ...state,
          funding_rounds: payload.response,
        };
      }

      return state;
    }

    case EDIT_FUNDING_ITEM_SUCCESS: {
      if (state.selectedCompany?.id) {
        return {
          ...state,
          funding_rounds: state.funding_rounds.map((f) => {
            if (
              f.id ===
              (payload.response.funding_round_id || payload.response.id)
            ) {
              return {
                ...f,
                ...payload.response,
              };
            }

            return f;
          }),
        };
      }

      return state;
    }

    case ADD_FUNDING_ITEM_SUCCESS: {
      if (state.selectedCompany?.id) {
        if (payload.oldSource === "crunchbase") {
          return {
            ...state,
            funding_rounds: [
              payload.response,
              ...state.funding_rounds.filter((f) => f.id !== payload.oldId),
            ],
          };
        }

        return {
          ...state,
          funding_rounds: [payload.response, ...(state.funding_rounds || [])],
        };
      }

      return state;
    }

    case DELETE_TOTAL_FUNDING_ITEM_SUCCESS: {
      if (state.selectedCompany?.id) {
        return {
          ...state,
          funding_rounds: state.funding_rounds.filter(
            (f) => f.id !== payload.fundingId
          ),
        };
      }

      return state;
    }

    case COMPANY_CONTACT_INVITE_SUCCESS: {
      return {
        ...state,
        shouldUpdate: true,
        companyContacts: state.companyContacts.map((c) => {
          if (c.id === payload.response.id) {
            return {
              ...c,
              ...payload.response,
            };
          }
          return c;
        }),
      };
    }

    case COMPANY_ADD_CONTACT_SUCCESS: {
      if (
        payload.companyId === state.selectedCompany?.id ||
        payload.isClaimProfileUse
      ) {
        return {
          ...state,
          companyContacts: [...state.companyContacts, payload.response],
        };
      }

      return state;
    }

    case COMPANY_EDIT_CONTACT_SUCCESS: {
      if (payload.companyId === state.selectedCompany?.id) {
        return {
          ...state,
          companyContacts: state.companyContacts.map((c) => {
            if (c.id === payload.response.id) {
              return {
                ...c,
                ...payload.response,
              };
            }
            return c;
          }),
        };
      }

      return state;
    }

    case GET_COMPANY_CONTACTS_ATTEMPT: {
      return {
        ...state,
        companyContacts: [],
        companyContactsLoading: true,
      };
    }

    case GET_COMPANY_CONTACTS_SUCCESS: {
      return {
        ...state,
        companyContacts: payload?.response || [],
        companyContactsLoading: false,
      };
    }

    case SHOULD_UPDATE_COMPANY_PROJECT_TASKS: {
      return {
        ...state,
        shouldUpdateProjectTasks: true,
      };
    }

    case COMPANY_SET_DOWNLOAD_PARAMS: {
      return {
        ...state,
        companyDownloadDetails: payload,
        companyIsDownloading: !!payload.length,
      };
    }

    case COMPANY_RATING_SAVE_SUCCESS:
    case COMPANY_RATING_EDIT_SUCCESS:
      {
        const { response, status } = payload;
        try {
          if (status === apiStatus.SUCCESS || status === apiStatus.CREATED) {
            return {
              ...state,
              selectedCompany: {
                ...state.selectedCompany,
                universal_rating: {
                  ...state.selectedCompany.universal_rating,
                  value: response.council_company_score,
                },
                rating_template: {
                  ...state.selectedCompany.rating_template,
                  rating_categories:
                    state.selectedCompany.rating_template.rating_categories.map(
                      (element, i) => {
                        if (response.parent_category) {
                          if (
                            element.rating_category_id ===
                            response.parent_category?.rating_category_id
                          ) {
                            element.sub_categories.map((subCat) => {
                              if (
                                subCat.rating_category_id ===
                                response.rating_category.id
                              ) {
                                subCat.user_universal_rating =
                                  response.rating_category?.user_universal_rating;
                              }
                            });
                            if (element.user_universal_rating?.value) {
                              element.user_universal_rating.value =
                                response.parent_category.value;
                            } else {
                              element.user_universal_rating = {
                                universal_rating_id:
                                  response.parent_category.id,
                                value: response.parent_category.value,
                              };
                            }
                          }
                        }
                        if (!response.parent_category) {
                          if (
                            element.rating_category_id ===
                            response.rating_category.id
                          ) {
                            element.sub_categories =
                              response.rating_category.sub_categories;
                            element.user_universal_rating =
                              response.rating_category.user_universal_rating;
                          }
                        }

                        return element;
                      }
                    ),
                },
                council_company_score: response.council_company_score,
                user_total_rating: response.user_total_rating,
              },
            };
          }
        } catch (error) {
          console.log("----------error in update rating", error);
          alert(error);
        }
      }
      break;

    case COMPANY_GET_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case COMPANY_DETAILS_UPDATE_CF: {
      const newCompanyDetails = state.companyDetails.map((field) => {
        if (field.company_custom_field === payload) {
          field.field_value = null;
          return field;
        }
        return field;
      });
      return {
        ...state,
        companyDetails: newCompanyDetails,
      };
    }

    case COMPANY_GET_SUCCESS:
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          loading: false,
          companyLoading: false,
          selectedCompany: response,
        };
      }
      break;

    case GET_TRACTION_COMPANY_BY_ID_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          loading: false,
          companyLoading: false,
          selectedCompany: response,
        };
      }
      break;
    }

    case COMPANY_SET:
      return {
        ...state,
        selectedCompany: payload,
      };

    case COMPANY_GET_ACTIVITIES_ATTEMPT:
      return {
        ...state,
        activityLoading: true,
      };

    case COMPANY_GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activityLoading: false,
        activityAdded: false,
        activitiesPageNr: pageMeta(payload),
        companyActivities: payload.response,
      };

    case COMPANY_ADD_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityAdded: true,
      };

    case GET_COMPANY_DETAILS_FIELDS:
      return {
        ...state,
        customFieldAdded: false,
        companyDetailsLoading: true,
      };

    case GET_COMPANY_DETAILS_FIELDS_SUCCESS:
      return {
        ...state,
        companyDetailsLoading: false,
        companyDetails: payload.response,
        loadingId: "",
      };

    case GET_COMPANY_GROUP_FIELDS:
      return {
        ...state,
        customFieldAdded: false,
      };

    case GET_COMPANY_GROUP_FIELDS_SUCCESS:
      return {
        ...state,
        companyDetailsLoading: false,
        customFieldGroups: payload.response,
      };

    case DELETE_CUSTOM_FIELD_TABLE_ROW_SUCCESS: {
      return {
        ...state,
        newCustomFieldGroups: state.newCustomFieldGroups.map((group) => {
          if (group.groupDetails.id === payload.groupId) {
            const table_values = group.tableData.table_values.filter(
              (_, idx) => idx !== payload.index
            );

            return {
              ...group,
              tableData: {
                ...group.tableData,
                table_values,
              },
            };
          }

          return group;
        }),
      };
    }

    case SAVE_CUSTOM_FIELD_TABLE_ROW_SUCCESS: {
      return {
        ...state,
        newCustomFieldGroups: state.newCustomFieldGroups.map((group) => {
          if (group.groupDetails.id === payload.groupId) {
            if (payload.row.id === 0) {
              const row = {
                cells: payload.response,
                originalCells: payload.response,
                id: payload.row.id,
                editMode: false,
                isNew: false,
                isTouched: false,
              };

              return {
                ...group,
                tableData: {
                  ...group.tableData,
                  table_values: [row],
                },
              };
            }

            const row = {
              cells: payload.response,
              originalCells: payload.response,
              id: group.tableData.table_values.length + 1,
              editMode: false,
              isNew: false,
              isTouched: false,
            };

            return {
              ...group,
              tableData: {
                ...group.tableData,
                table_values: [...group.tableData.table_values, row],
              },
            };
          }

          return group;
        }),
      };
    }

    case SET_DETAILS_CUSTOM_GROUPS:
      return {
        ...state,
        newCustomFieldGroups: payload,
      };

    case EDIT_CUSTOM_FIELD_TABLE_ROW_CELLS_SUCCESS: {
      // what the hell is going on bellow... :D
      const newCustomFieldGroups = state.newCustomFieldGroups.map((group) => {
        if (group.groupDetails.id === payload.groupId) {
          const tableValues = group.tableData.table_values.map((row, index) => {
            if (index === payload.index) {
              const newCells = row.cells.map((cell) => {
                const getCellFromRes = payload.cells.find(
                  (c) => c.id === cell.id
                );

                if (cell?.id === getCellFromRes?.id) {
                  return getCellFromRes;
                }

                return cell;
              });
              const newRow = {
                cells: newCells,
                originalCells: newCells,
                editMode: false,
                isNew: false,
                isTouched: false,
              };

              return {
                ...row,
                ...newRow,
              };
            }

            return row;
          });

          return {
            ...group,
            tableData: {
              ...group.tableData,
              table_values: tableValues,
            },
          };
        }

        return group;
      });

      return {
        ...state,
        newCustomFieldGroups,
      };
    }

    case GET_CUSTOM_GROUP_FIELDS_SUCCESS: {
      const newCustomFieldGroups = state.newCustomFieldGroups.map((group) => {
        if (payload.isTableGroup && group.groupDetails.id === payload.groupId) {
          const tableHeaders = payload.response.table_headers;
          let tableValues = payload.response.table_values;

          if (!payload.response.table_values?.length && tableHeaders.length) {
            tableValues = [
              new Array(tableHeaders.length).fill(null).map((_, idx) => {
                const getHeaderCustomFieldData = tableHeaders[idx];

                return {
                  id: null,
                  custom_field_template_id: getHeaderCustomFieldData.id,
                  custom_field_group_id: group.groupDetails.id,
                  user_id: null,
                  council_id: null,
                  company_id: null,
                  field_value: null,
                  option_selected: [],
                  position: idx + 1,
                };
              }),
            ];
          }

          const rows = tableValues.map((cells, idx) => ({
            id: idx,
            cells,
            originalCells: cells,
            editMode: false,
          }));
          return {
            ...group,
            loading: false,
            any_values: payload.response?.any_values,
            tableData: {
              ...payload.response,
              table_values: rows,
            },
          };
        }

        if (group.groupDetails.id === payload.groupId) {
          return {
            ...group,
            loading: false,
            any_values: payload.response?.any_values,
            customFields: payload.response?.custom_fields || [],
          };
        }
        return group;
      });

      return {
        ...state,
        newCustomFieldGroups,
      };
    }

    case POST_CUSTOM_FIELDS:
      return {
        ...state,
        companyDetailsLoading: !payload.data.custom_field_template_id,
        customFieldAdded: false,
        loadingId: payload.data.custom_field_template_id || "",
      };

    case POST_CUSTOM_FIELDS_SUCCESS:
      const newCustomFieldGroups = state.newCustomFieldGroups.map((group) => {
        if (group.groupDetails.id === payload.response.custom_field_group_id) {
          const hasField = group.customFields.some(
            (f) =>
              f.custom_field_template_id ===
                payload.response.custom_field_template_id &&
              payload.response.custom_field_template_id !== null
          );

          if (hasField) {
            return {
              ...group,
              customFields: group.customFields.map((f) => {
                if (
                  f.custom_field_template_id ===
                  payload.response.custom_field_template_id
                ) {
                  return {
                    ...f,
                    ...payload.response,
                  };
                }

                return f;
              }),
            };
          }

          return {
            ...group,
            customFields: [...(group.customFields || []), payload.response],
          };
        }
        return group;
      });
      return {
        ...state,
        newCustomFieldGroups,
      };

    case COMPANY_PUT_DETAILS_FIELDS_ATTEMPT:
      return {
        ...state,
        updateCompany: false,
        loadingId:
          payload.custom_field_template_id || payload.company_custom_field_id,
      };

    case SET_CUSTOM_FIELD_LOADING: {
      return {
        ...state,
        customFieldLoadingId: payload,
      };
    }
    case COMPANY_PUT_DETAILS_FIELDS_SUCCESS: {
      const { response, status } = payload;

      if (status === apiStatus.SUCCESS || status === apiStatus.CREATED) {
        const newCustomFieldGroups = state.newCustomFieldGroups.map((group) => {
          if (
            group.groupDetails.id === payload.response.custom_field_group_id
          ) {
            return {
              ...group,
              customFields:
                group.customFields?.map((field) => {
                  if (
                    field.company_custom_field_id ===
                    payload.company_custom_field_id
                  ) {
                    return {
                      ...field,
                      ...(response || {}),
                    };
                  }
                  return field;
                }) || [],
            };
          }

          return group;
        });

        return {
          ...state,
          newCustomFieldGroups,
        };
      }

      return state;
    }

    case COMPANY_DELETE_DETAILS_FIELDS_ATTEMPT:
      return {
        ...state,
        companyDetailsLoading: true,
        updateCompany: false,
      };

    case COMPANY_DELETE_DETAILS_FIELDS_SUCCESS: {
      return {
        ...state,
        companyDetailsLoading: false,
        newCustomFieldGroups: state.newCustomFieldGroups.map((group) => {
          if (group.groupDetails.id === payload.groupId) {
            return {
              ...group,
              customFields:
                group.customFields?.filter(
                  (field) =>
                    field.company_custom_field_id !==
                    payload.company_custom_field_id
                ) || [],
            };
          }

          return group;
        }),
      };
    }

    case COMPANY_DELETE_DETAILS_TEMPLATE_FIELDS_ATTEMPT:
      return {
        ...state,
        companyDetailsLoading: true,
        updateCompany: false,
      };

    case COMPANY_DELETE_DETAILS_TEMPLATE_FIELDS_SUCCESS: {
      return {
        ...state,
        companyDetailsLoading: false,
        newCustomFieldGroups: state.newCustomFieldGroups.map((group) => {
          if (group.groupDetails.id === payload.groupId) {
            return {
              ...group,
              customFields:
                group.customFields?.filter(
                  (field) =>
                    field.custom_field_template_id !==
                    payload.custom_field_template_id
                ) || [],
            };
          }

          return group;
        }),
      };
    }

    case COMPANY_DELETE_ACTIVITY_ATTEMPT:
      return {
        ...state,
        activityLoading: true,
      };

    case COMPANY_DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityLoading: false,
        activityAdded: true,
      };

    case COMPANY_PUT_ACTIVITY_ATTEMPT:
      return {
        ...state,
        activityLoading: true,
      };

    case COMPANY_PUT_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityLoading: false,
        activityAdded: true,
      };

    case COMPANY_ADD_RELATIONSHIP_ATTEMPT:
      return {
        ...state,
        loading: true,
        updateContactsCompany: false,
      };

    case COMPANY_ADD_RELATIONSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        updateContactsCompany: true,
      };

    case COMPANY_DELETE_RELATIONSHIP_ATTEMPT:
      return {
        ...state,
        loading: true,
        updateContactsCompany: false,
      };

    case COMPANY_DELETE_RELATIONSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        updateContactsCompany: true,
      };

    case COMPANY_DELETE_CONTACT_ATTEMPT:
      return {
        ...state,
        loading: true,
        updateContactsCompany: false,
      };

    case COMPANY_DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        updateContactsCompany: true,
      };

    case COMPANY_EDIT_DETAILS_ATTEMPT: {
      return {
        ...state,
        updateCompany: false,
      };
    }

    case COMPANY_EDIT_DETAILS_SUCCESS: {
      const { response, status, listId, updateWithoutRequest } = payload;
      if (status === apiStatus.SUCCESS && !updateWithoutRequest) {
        return {
          ...state,
          updateCompany: true,
          listId,
          listItemToUpdate: response,
        };
      }
      break;
    }

    case COMPANY_SHARE_ATTEMPT:
    case COMPANY_SHARE_SUCCESS:
    case COMPANY_REQUEST_RESEARCH_ATTEMPT:
    case COMPANY_REQUEST_RESEARCH_SUCCESS:
    case COMPANY_EDIT_RELATIONSHIP_ATTEMPT:
    case COMPANY_GET_PROJECT_TASKS_ATTEMPT:
    case COMPANY_GET_CUSTOMERS_ATTEMPT:
      return {
        ...state,
      };

    case COMPANY_EDIT_RELATIONSHIP_SUCCESS: {
      if (payload.status === apiStatus.SUCCESS) {
        const { response, company_relationship_owner_id } = payload;

        const newOwners = [
          ...state.selectedCompany.company_relationship_owners.filter(
            (o) =>
              o.company_relationship_owner_id !== company_relationship_owner_id
          ),
          {
            company_relationship_owner_id: response.id,
            user: response.user,
            user_id: response.user_id,
          },
        ];

        return {
          ...state,
          selectedCompany: {
            ...state.selectedCompany,
            company_relationship_owners: newOwners,
          },
        };
      }

      return state;
    }

    case COMPANY_GET_PROJECT_TASKS_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS || status === apiStatus.CREATED) {
        return {
          ...state,
          companyProjectTasks: response,
          shouldUpdateProjectTasks: false,
        };
      }

      return state;
    }

    case SELECTED_COMPANY_CLEAR: {
      return {
        ...state,
        selectedCompany: {},
      };
    }

    case CLEAR_COMPANY_LOADING: {
      return {
        ...state,
        companyLoading: true,
      };
    }

    case COMPANY_GET_CUSTOMERS_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          companyCustomers: response,
        };
      }

      return state;
    }

    case CATCH_ERROR_SUCCESS: {
      return {
        ...state,
        activityLoading: false,
        companyDetailsLoading: false,
        companyLoading: false,
        loading: false,
      };
    }

    case COMPANY_GET_RATING_NOTES_ATTEMPT:
    case COMPANY_ADD_RATING_NOTES_ATTEMPT: {
      return {
        ...state,
        loadingNotes: true,
      };
    }
    case COMPANY_GET_RATING_NOTES_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          loadingNotes: false,
          shouldUpdateRatingNotes: false,
          companyRatingNotes: response,
        };
      }

      return state;
    }

    case COMPANY_ADD_RATING_NOTES_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS || status === apiStatus.CREATED) {
        return {
          ...state,
          loadingNotes: false,
          companyRatingNotes: [...state.companyRatingNotes, response],
        };
      }

      return state;
    }

    case COMPANY_DELETE_RATING_NOTES_ATTEMPT: {
      return {
        ...state,
        loadingNotes: true,
      };
    }

    case COMPANY_DELETE_RATING_NOTES_SUCCESS: {
      const { status } = payload;
      if (status === apiStatus.SUCCESS || status === apiStatus.CREATED) {
        return {
          ...state,
          loadingNotes: false,
          shouldUpdateRatingNotes: true,
        };
      }

      return state;
    }

    case COMPANY_EDIT_RATING_NOTES_ATTEMPT: {
      return {
        ...state,
        loadingNotes: true,
      };
    }

    case COMPANY_EDIT_RATING_NOTES_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS || status === apiStatus.CREATED) {
        return {
          ...state,
          loadingNotes: false,
          companyRatingNotes: [
            ...state.companyRatingNotes.map((note) => {
              if (note.id === response.id) {
                return response;
              }
              return note;
            }),
          ],
        };
      }

      return state;
    }

    case COMPANY_AGREEMENT_SHOULD_OPEN_ATTEMPT: {
      return {
        ...state,
        shouldOpenUnusedAgreement: {
          accessFlag: true,
          ...payload,
        },
      };
    }

    case COMPANY_AGREEMENT_SHOULD_OPEN_SUCCESS: {
      return {
        ...state,
        shouldOpenUnusedAgreement: {
          accessFlag: false,
          type: undefined,
        },
      };
    }

    case COMPANY_AGREEMENT_SHOULD_REDIRECT: {
      return {
        ...state,
        shouldOpenUnusedAgreement: {
          accessFlag: false,
          ...payload,
        },
      };
    }

    case COMPANY_AGREEMENT_SHOULD_CLEAR: {
      return {
        ...state,
        shouldOpenUnusedAgreement: {
          accessFlag: false,
          type: undefined,
        },
      };
    }
    case COMPANY_CUSTOM_FIELD_SCROLL_TO: {
    }
    case GET_COMPANY_TAGS_SUCCESS: {
      return {
        ...state,
        customCompanyTags: payload.response.data,
      };
    }

    case GET_COMPANY_ACTIVITIES_ATTEMPT: {
      return {
        ...state,
        activitiesLoading: true,
      };
    }
    case GET_COMPANY_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        activitiesLoading: false,
        activitiesPagination: pageMeta(payload),
        companyActivity: payload.response,
      };
    }
    case GET_CUSTOM_FIELD_HISTORY_SUCCESS: {
      return {
        ...state,
        customFieldHistoryLoading: false,
        customFieldHistory: payload.response,
        customFieldHistoryMeta: pageMeta(payload),
      };
    }
    case GET_CUSTOM_FIELD_HISTORY_ATTEMPT: {
      return {
        ...state,
        customFieldHistoryLoading: true,
      };
    }

    case SAVE_PREV_URL: {
      return {
        ...state,
        prevPagePath: payload.prevPath,
      };
    }
  }

  return state;
};

export default companyReducer;

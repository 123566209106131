import React from "react";
import { Formik, Field } from "formik";
import { TextBox } from "../../../primitives";
import styles from "./projectReports.module.scss";

const ProjectReportStep1 = ({ selectedProject, model, setModel, companyList }) => {
  const handleOnChange = (evt) => {
    if (evt.target.id === "reportTitle") {
      setModel({
        ...model,
        step1: {
          ...model.step1,
          reportTitle: evt.target.value,
        },
      });
      return;
    }

    if (evt.target.name === "isAllSelected") {
      handleSelectAll();
      return;
    }

    const { value } = evt.target;
    const valueExists = model.step1.companies.some((id) => id === value);

    if (valueExists) {
      setModel({
        ...model,
        step1: {
          ...model.step1,
          companies: model.step1.companies.filter((id) => id !== value),
          isAllSelected: false,
        },
      });
    } else {
      const companies = [...model.step1.companies, value];
      // const isAllSelected = companies.length === selectedProject.project_companies?.length;
      const isAllSelected = companies.length === companyList?.length;

      setModel({
        ...model,
        step1: {
          ...model.step1,
          companies,
          isAllSelected,
        },
      });
    }
  };

  const handleSelectAll = () => {
    if (model.step1.isAllSelected) {
      setModel({
        ...model,
        step1: {
          ...model.step1,
          companies: [],
          isAllSelected: false,
        },
      });
    } else {
      setModel({
        ...model,
        step1: {
          ...model.step1,
          companies: companyList.map(c => c.company.id), // selectedProject.project_companies.map((c) => c.company.id),
          isAllSelected: true,
        },
      });
    }
  };

  return (
    <div className={styles.step}>
      <Formik
        enableReinitialize
        initialValues={model.step1}
        onSubmit={() => {}}
      >
        {({ handleSubmit, setFieldValue, ...formikprops }) => (
          <form onChange={handleOnChange}>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className={styles.formElement}>
                  <span className={styles.label}>Report Title</span>
                  <TextBox
                    type="text"
                    name="reportTitle"
                    placeholder="Report Title"
                    formProps={formikprops}
                    className={styles.textBox}
                  />
                </div>
                <div className={styles.formElement}>
                  <span className={styles.label}>Select Companies</span>
                  <span className={styles.description}>
                    Select Project Companies that should appear in the report
                  </span>
                  <div className={`${styles.checkboxesList} mb-2 mt-3`}>
                    <label>
                      <Field type="checkbox" name="isAllSelected" />
                      {model.step1.isAllSelected
                        ? "Deselect All Companies"
                        : "Select All Companies"}
                    </label>
                  </div>
                  <div role="group" className={styles.checkboxesList}>
                    {companyList.map((c) => (
                      <label key={c.company.id}>
                        <Field
                          type="checkbox"
                          name="companies"
                          value={c.company.id}
                        />
                        {c.company.name}
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(ProjectReportStep1);

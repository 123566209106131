import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import closeIcon from "assets/icn-close.svg";
import MultiUsersComponent from "modules/primitives/multiUsers/multiUsers.component";
import styles from "./discussionDetails.module.scss";
import DiscussionMsg from "../discussionMsg/discussionMsg.component";
import { BtnType, Button, Loading } from "../../../modules/primitives";
import RichEditor from "../richEditor";

import { resourceImages } from "../../constants";
import { useModelPopup, useOnClickOutside } from "../../hooks";
import {
  discussionMsgDelete,
  discussionMsgEdit,
  sendReplyAttempt,
} from "../../actions/discussions.action";
import AddDiscussionMemberComponent from "./addMemberModal/addDiscussionMember.component";
import { httpGet } from "../../httpCall";

const DiscussionDetails = ({
  handleClose,
  details,
  discussionDetails,
  sendReplyPending,
}) => {
  const {
    authReducer: { session },
  } = useSelector((state) => state);

  const [editMode, setEditMode] = useState(false);
  const [reply, setReply] = useState("");
  const [resetReply, setResetReply] = useState(false);
  const [pendingReply, setPendingReply] = useState();
  const [msgWrapperHeight, setMsgWrapperHeight] = useState(0);
  const msgWrapperRef = useRef();
  const msgContainerrRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const popup = useModelPopup();
  const [storageMessageObj, setStorageMessageObj] = useState({});
  const [validationValue, setValidationValue] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [notificationsLoading, setShowLoadingNotifications] = useState(false);
  const [notificationResults, setResults] = useState([]);
  const [messageInnerHtml, setInnerHtml] = useState("");

  useOnClickOutside(msgWrapperRef, handleClose);

  useEffect(() => {
    if (notificationResults && notificationResults.body_text) {
      const bodyText = notificationResults.body_text.substring(
        notificationResults.body_text.indexOf("<body>"),
        notificationResults.body_text.indexOf("</body>")
      );

      const msg = bodyText
        .replace("\n", "")
        .replace('"', '"')
        .replace(/{{.*?}}/g, "")
        .replace(/<img.*?>/g, "");

      const extractDataTypeTextTags = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        const tables = doc.querySelectorAll('table[data-type="text"]');
        let divs = [];
        tables.forEach((table) => {
          divs = divs.concat(Array.from(table.querySelectorAll("div")));
        });
        return divs.slice(2, 3).map((div) => div.outerHTML);
      };

      setInnerHtml(extractDataTypeTextTags(msg));
    }
  }, [notificationResults]);

  useEffect(() => {
    if (msgWrapperRef && msgWrapperRef.current) {
      setMsgWrapperHeight(window.innerHeight - 78 - 250);
    }
  }, [msgWrapperRef]);

  useEffect(() => {
    const val = JSON.parse(localStorage.getItem("temporary_message"));

    if (val) {
      setStorageMessageObj(val);
    }
  }, []);

  useEffect(() => {
    if (msgContainerrRef && msgContainerrRef.current) {
      msgContainerrRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [details?.messages, sendReplyPending]);

  useEffect(() => {
    if (!sendReplyPending) {
      setPendingReply({});
    }
  }, [sendReplyPending]);

  const makeTheReply = () => {
    if (
      !validationValue ||
      !validationValue.trim() ||
      !validationValue.length
    ) {
      enqueueSnackbar("Please enter some text before hitting reply.", {
        variant: "error",
      });
      return;
    }
    setValidationValue("");
    const localObj = { ...storageMessageObj };
    localObj[details?.id] = "";
    localStorage.removeItem("temporary_message");

    // localStorage.setItem("temporary_message", JSON.stringify(localObj));

    const payload = {
      message: {
        content: reply,
        replied_message_id: null, // discussionDetails.messages[discussionDetails.messages.length - 1].message_id,
      },
      id: details?.id,
      isPrivate: details?.isPrivate,
      prop: details?.prop,
    };
    setPendingReply(payload);
    dispatch(
      sendReplyAttempt({
        data: payload,
        enqueueSnackbar,
      })
    );

    setResetReply(true);

    const timeout = setTimeout(() => {
      setResetReply(false);
      setReply("");
      clearTimeout(timeout);
    });
  };

  const handleRedirect = () => {
    document.querySelector("body").style.overflow = "auto";
    if (details?.discussion_resource_type === "Project") {
      history.push(`/projects/${details?.discussion_resource_id}`);
    }

    if (details?.discussion_resource_type === "Theme") {
      history.push(
        `/themes/individual_page/${details?.entity?.entity_id}/overview`
      );
    }
    if (details?.discussion_resource_type === "List") {
      history.push(`/list/${details?.entity?.entity_id}`);
    }
    if (details?.discussion_resource_type === "Event") {
      history.push(`/events/id/${details?.discussion_resource_id}`);
    }
    if (details?.discussion_resource_type === "Company") {
      history.push(
        `/companies/${
          details?.company?.slug ||
          details?.discussion_resource_id ||
          details?.entity?.entity_id
        }`
      );
    }
    if (details?.discussion_resource_type === "Gsk::PatientOrganization") {
      history.push(
        `/patient_organizations/${
          details?.slug || details?.discussion_resource_id
        }`
      );
    }
    if (details?.discussion_resource_type === "Task") {
      if (details?.entity?.entity_url) {
        const parts = details?.entity.entity_url?.split("/");
        const relativeUrl = "/" + parts.slice(3).join("/");
        history.push(`${relativeUrl}`);
      }
    }
  };

  const handleAddDiscussionMember = () => {
    popup.show({
      title: "Add Discussion Member",
      component: (
        <AddDiscussionMemberComponent
          discussionId={details?.id}
          discussionParticipants={details?.participants}
        />
      ),
    });
  };

  const checkIconType = (type) => {
    if (type === "Event") {
      return styles.Event;
    }
    if (type === "Project") {
      return styles.Project;
    }
    if (type === "Theme") {
      return styles.Theme;
    }
    if (type === "Event") {
      return styles.Event;
    }
    if (type === "List") {
      return styles.List;
    }
    return styles.Company;
  };

  const getTemporaryMsg = useMemo(() => {
    // return JSON.parse(localStorage.getItem("temporary_message")) || '';
    return "";
  }, []);

  const handleMoreOptionClick = (evt, msg) => {
    if (evt === 1) {
      dispatch(discussionMsgDelete(msg));
    }

    if (evt === 0) {
      dispatch(discussionMsgEdit(msg));
    }
  };

  const requestNewData = useCallback(() => {
    setShowLoadingNotifications(true);
    if (session?.id) {
      const url = `${details?.type}/${details?.id}`;

      httpGet({
        call: url,
      })
        .pipe()
        .subscribe((res) => {
          setResults(res.response);

          setShowLoadingNotifications(false);
        });
    }
  }, [session]);

  useEffect(() => {
    if (details?.prop === "notification") {
      requestNewData();
    }
  }, [details]);
  if (details?.prop === "notification") {
    return (
      <div className={styles.discussionWrp} ref={msgWrapperRef}>
        <div>
          <div className={styles.detailsHeader}>
            <div className="d-flex align-items-center">
              <span className={styles.title}>Notification</span>

              <div
                className={classNames(
                  styles.resourceType,
                  checkIconType(details?.discussion_resource_type)
                )}
                onClick={handleRedirect}
              >
                <img
                  src={
                    resourceImages[
                      details?.entity?.entity_type?.charAt(0).toUpperCase() +
                        details?.entity?.entity_type?.slice(1)
                    ]
                  }
                  alt={
                    details?.entity?.entity_type?.charAt(0).toUpperCase() +
                    details?.entity?.entity_type?.slice(1)
                  }
                />
                <span>{details?.entity?.entity_name}</span>
              </div>
            </div>
            <div>
              <span onClick={handleClose} className={styles.closeBtn}>
                <img alt={"close icon"} src={closeIcon} />
              </span>
            </div>
          </div>
        </div>
        <div
          className={styles.notifBodyWrp}
          style={{ height: `${msgWrapperHeight}px` }}
        >
          {details?.subject && (
            <div className={styles.subject}>{details?.subject}</div>
          )}
          <span className={styles.date}>
            {format(
              new Date(details?.sent_at || details?.created_at),
              "MMM d h:mm a"
            )}
          </span>
          {notificationsLoading && (
            <Loading customText="Loading... This may take a moment." />
          )}
          <div
            className={styles.msg}
            dangerouslySetInnerHTML={{ __html: messageInnerHtml }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.discussionWrp} ref={msgWrapperRef}>
      <div>
        <div className={styles.detailsHeader}>
          <div className="d-flex align-items-center">
            <span className={styles.title}>Discussion</span>
            {details?.discussion_resource_type !== "User" &&
              details?.discussion_resource_type !== "Council" && (
                <div
                  className={classNames(
                    styles.resourceType,
                    checkIconType(details?.discussion_resource_type)
                  )}
                  onClick={handleRedirect}
                >
                  <img
                    src={resourceImages[details?.discussion_resource_type]}
                    alt={details?.discussion_resource_type}
                  />
                  <span>{details?.discussion_resource?.name}</span>
                </div>
              )}
          </div>
          <div>
            <span className={styles.msgsNr}>
              {details?.messages?.length || 0}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g fill="none" fillRule="evenodd">
                  <g fill="#3994C1">
                    <g>
                      <path
                        d="M22.477.988c.828 0 1.5.672 1.5 1.5v15.037c0 .829-.672 1.5-1.5 1.5L11.2 19.024l-4.818 4.794c-.953.948-2.542.258-2.559-1.03l.007-.172.335-3.59h-2.65c-.769 0-1.403-.578-1.49-1.324l-.01-.175V2.488c0-.828.672-1.5 1.5-1.5zm0 1.5H1.515v15.039h3.677l.11.013c.109.025.214.085.306.158.097.122.171.27.147.416l-.431 4.641 5.134-5.107.078-.05c.082-.045.179-.073.29-.073h11.65V2.488zM12.75 11.5c.414 0 .75.336.75.75s-.336.75-.75.75h-6c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h6zm4.5-3c.414 0 .75.336.75.75s-.336.75-.75.75H6.75C6.336 10 6 9.664 6 9.25s.336-.75.75-.75h10.5zm0-3c.414 0 .75.336.75.75s-.336.75-.75.75H6.75C6.336 7 6 6.664 6 6.25s.336-.75.75-.75h10.5z"
                        transform="translate(-1352 -89) translate(1352 88)"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            <span onClick={handleClose} className={styles.closeBtn}>
              <img alt={"close icon"} src={closeIcon} />
            </span>
          </div>
        </div>
        {details?.public === true ? (
          <div className={styles.groupAttendees}>Recipients : All</div>
        ) : (
          <div className={styles.multiUserWrp}>
            <MultiUsersComponent
              className={styles.userIcon}
              users={details?.participants}
              total={details?.participants?.length}
            />
            <Button
              className={styles.newTask}
              btn={BtnType.LINK}
              onClick={handleAddDiscussionMember}
              icon="icn-add"
            >
              Add More
            </Button>
          </div>
        )}
      </div>
      <div
        className={styles.bodyWrp}
        style={{ height: `${msgWrapperHeight}px` }}
      >
        {details?.subject && (
          <div className={styles.subject}>{details?.subject}</div>
        )}
        {details?.messages
          .sort((a, b) => new Date(a.sent_at) - new Date(b.sent_at))
          .map((msgDetails, index) => (
            <DiscussionMsg
              key={`${msgDetails.message_id}_${index}`}
              details={msgDetails}
              anonymous={details?.anonymous}
              myMessage={msgDetails.sender_id === session?.id}
              handleMoreOptionClick={handleMoreOptionClick}
              setEditActive={setEditMode}
            />
          ))}
        {!details?.messages ||
          (!details?.messages.length && (
            <span className="text-muted pt-5 text-center">
              No messages yet.
            </span>
          ))}
        {sendReplyPending && (
          <div className={styles.pendingContainer}>
            <div className={styles.icon} />
            <div
              className={styles.msg}
              dangerouslySetInnerHTML={{ __html: pendingReply.message.content }}
            />
          </div>
        )}
        <div ref={msgContainerrRef} />
      </div>
      {!editMode && (
        <div
          className={styles.footer}
          style={{
            minHeight: details?.hasCompanyDetails ? "180" : "250",
            height: "340px",
          }}
        >
          {!details?.hasCompanyDetails ? (
            <RichEditor
              settedValue={getTemporaryMsg}
              discussionId={details?.attributes?.id}
              updateValue={setReply}
              setValid={setValidationValue}
              shouldReset={resetReply}
            />
          ) : (
            <textarea
              placeholder="Type your reply"
              value={reply}
              onChange={(e) => {
                setReply(e.target.value);
              }}
            />
          )}
          <div className="d-flex align-items-center justify-content-between mt-3">
            {details?.hasCompanyDetails && <span />}
            <div className={styles.replyButtonContainer}>
              <Button
                className={styles.replyButton}
                btn={BtnType.REGULAR}
                onClick={makeTheReply}
              >
                REPLY
              </Button>
              {!details?.hasCompanyDetails &&
              details?.participants &&
              details?.participants.length &&
              details?.participants.length > 1 ? (
                <span className="small">
                  {details?.participants.length - 1}{" "}
                  {details?.participants.length > 2 ? "people" : "person"} will
                  be notified of your response
                </span>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiscussionDetails;

export const ROW_OPTIONS = [
  {
    val: 0,
    text: "MOVE",
  },
  {
    val: 1,
    text: "Delete",
  },
];

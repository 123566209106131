import React from "react";
import DropDownNestedElements from "common/components/dropdownNestedElements";
import styles from "../areaOfFocus.module.scss";
import { Label, CheckBox, CheckType } from "../../../../../primitives";
import { Icon, smSize } from "../../../../../../common/icon";

const CustomTagModal = ({
  customTagsOptions,
  handleCustomTagChange,
  handleCustomTagRemove,
  flatTags,
  handleSelectAll,
  allSelected,
  group,
  selectedCustomTagsIds,
}) => {
  return (
    <>
      <Label>{group.attributes.name}</Label>
      <DropDownNestedElements
        tags={customTagsOptions}
        selectedTags={selectedCustomTagsIds}
        childKey="children"
        tagKeyName="custom_tag_id"
        parentKeyName="custom_tag_id"
        handleTagSelect={handleCustomTagChange}
        label={`Select ${group.attributes.name}`}
        // handleTagRemove={handleCustomTagRemove}
        showPrevSeparate
        selectedIsObject
      />
      {group.attributes.name === "Markets Served" && (
        <div className="d-flex align-items-center">
          <CheckBox
            checkType={CheckType.BLUE}
            isChecked={allSelected}
            onChange={() => handleSelectAll(!allSelected)}
          />
          <span
            className={styles.checkboxName}
            onClick={() => handleSelectAll(!allSelected)}
          >
            Global
          </span>
        </div>
      )}
      <div className={styles.customTagsList}>
        {flatTags &&
          flatTags.map((item) => {
            if (selectedCustomTagsIds.includes(item.custom_tag_id)) {
              return (
                <div key={item.custom_tag_id} className={styles.techItem}>
                  {item.name}{" "}
                  <span
                    onClick={(e) => {
                      handleCustomTagRemove(
                        selectedCustomTagsIds.filter(
                          (tag) => tag !== item.custom_tag_id
                        ),
                        true
                      );
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Icon {...smSize} icon="icn-button-delete" />
                  </span>
                </div>
              );
            }
            return null;
          })}{" "}
      </div>
    </>
  );
};
export default CustomTagModal;

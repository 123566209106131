import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import DiscussionsFactory from "modules/shared/discussion/discussionsFactory";
import {
  eventDiscussionClear,
  eventDiscussionsGet,
  privateDiscussionsGet,
  projectDiscussionsGet,
  publicDiscussionsGet,
} from "../../../common/actions/discussions.action";

const DiscussionComponent = ({
  type,
  reducerProp,
  projectId,
  eventId,
  isCurrentUserAttended,
  isCurrentUserProjectMember,
  homePageUse,
  withCollapse,
  expandAll,
}) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    councilReducer: { selectedCouncil },
    discussionsReducer,
  } = useSelector((state) => state);

  const [discussions, setDiscussions] = useState(null);
  const [query, setQuery] = useState("GROUP");

  useEffect(() => {
    if (reducerProp) {
      let data;

      if (query === "PRIVATE" && reducerProp === "publicDiscussions") {
        data = discussionsReducer.privateDiscussions;
      } else if (query === "PRIVATE") {
        if (
          reducerProp === "eventDiscussions" ||
          reducerProp === "projectDiscussions"
        ) {
          data = discussionsReducer.privateDiscussions?.filter(
            (d) => d.discussion_resource_id === id
          );
        }
      } else {
        if (
          reducerProp === "eventDiscussions" ||
          reducerProp === "projectDiscussions"
        ) {
          data = discussionsReducer[reducerProp]?.filter((d) => d.public);
        } else {
          data = discussionsReducer[reducerProp];
        }
      }

      setDiscussions(data);
    }
  }, [reducerProp, query, discussionsReducer]);

  const getData = () => {
    if (query === "GROUP" && reducerProp === "publicDiscussions") {
      dispatch(
        publicDiscussionsGet({
          council_id: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }

    if (query === "PRIVATE") {
      dispatch(
        privateDiscussionsGet({
          id: selectedCouncil.id,
          items: 6,
          enqueueSnackbar,
        })
      );
    } else {
      if (reducerProp === "projectDiscussions") {
        dispatch(
          projectDiscussionsGet({
            councilId: selectedCouncil.id,
            projectId: id,
            items: 20,
            enqueueSnackbar,
          })
        );
      }

      if (reducerProp === "eventDiscussions") {
        dispatch(
          eventDiscussionsGet({
            councilId: selectedCouncil.id,
            eventId: id,
            items: 20,
            enqueueSnackbar,
          })
        );
      }
    }
    return () => null;
  };

  useEffect(() => {
    if (selectedCouncil) {
      getData();
    }
  }, [selectedCouncil, query]);

  useEffect(
    () => () => {
      setDiscussions(null);
      dispatch(eventDiscussionClear());
    },
    []
  );

  return (
    <DiscussionsFactory
      type={type}
      query={query}
      setQuery={setQuery}
      homePageUse={homePageUse}
      discussions={discussions}
      ids={{
        projectId,
        eventId,
      }}
      isCurrentUserAttended={isCurrentUserAttended}
      isCurrentUserProjectMember={isCurrentUserProjectMember}
      reducerProp={reducerProp}
      withCollapse={withCollapse}
      expandAll={expandAll}
    />
  );
};

export default React.memo(DiscussionComponent);

import React, { useEffect, useState } from "react";

import { currencySymbol } from "../../../../../../common/components/customFields/number/constant";
import { numberWithCommas } from "../../../../../../common/helper";

const NumberTableCustomField = ({ row, field, fieldCoreDetails, updateCellCallback }) => {
  const [value, setValue] = useState('');
  const [editActive, setEditActive] = useState(false);

  const currency = currencySymbol?.find(({ name }) => name === fieldCoreDetails.option_values[0])?.symbol;

  useEffect(() => {
    setValue(field.field_value || '');
  }, [field.field_value]);

  useEffect(() => {
    if (row.editMode || !field.field_value?.length || row.isTouched) {
      setEditActive(true);
      return;
    }

    setEditActive(false);
  }, [row, field]);

  const handleChange = evt => {
    const val = evt.target.value;
    setValue(val);

    if (updateCellCallback) {
      updateCellCallback({ ...field, field_value: val });
    }
  }

  return (
    <div>
      {
        editActive ? (
          <div>
            {currency}
            <input
              className="border p-1"
              placeholder={fieldCoreDetails.field_placeholder || 'Enter a number'}
              type="number"
              min="0"
              value={value}
              onChange={handleChange}
            />
          </div>
        ) : (
          <div>
            {currency} {fieldCoreDetails.option_values[0] ? numberWithCommas(value) : value}
          </div>
        )
      }
    </div>
  );
};

export default React.memo(NumberTableCustomField);

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import { Pannel, UserInlineTypes } from "../../../../primitives";
import { useModelPopup } from "../../../../../common/hooks";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import UserInline from "../../../../primitives/userInline/userInline.component";
import NewThemeMemberComponent from "../membersBlock/addMemberModal";
import {
  themeSpocExpertGetAttempt,
  themesSpocExpertDeleteAttempt,
} from "../overviewTab.action";

import isItStandardViewver from "../../../themesCommon/isItStandardViewer";

const FordSMeBlock = ({}) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },

    themesReducer: {
      themesMainReducer: { currentThemeData },
      themesOverviewReducer: { themeSpocSmeExperts },
    },
  } = useSelector((state) => state);

  const handleAddTeamMember = () => {
    popup.show({
      title: "Add a SPOC",
      component: (
        <NewThemeMemberComponent smeMode itemList={themeSpocSmeExperts} />
      ),
    });
  };

  const handleDeleteSmeMember = (expertRelationId) => {
    dispatch(themesSpocExpertDeleteAttempt({ memberId: expertRelationId }));
  };

  useEffect(() => {
    if (currentThemeData.id) {
      dispatch(themeSpocExpertGetAttempt({ themeId: currentThemeData.id }));
    }
  }, [selectedCouncil]);

  return (
    <Pannel
      title="SPOCs"
      padding="0"
      parentStyle={{
        marginTop: `${window.screen.width < 950 ? "0px" : "-10px"}`,
        width: `${window.screen.width < 550 ? "100%" : "230px"}`,
        minWidth: "230px",
        padding: `${window.screen.width < 550 && "0px"}`,
      }}
    >
      {" "}
      <div className={styles.panelHeader}>
        SPOCs
        <div className={styles.panelIcons}>
          {isItStandardViewver(session, currentThemeData) && (
            <Icon
              {...mdSize}
              icon={"icn-add"}
              className={styles.addNew}
              onClick={(e) => {
                e.stopPropagation();
                handleAddTeamMember();
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.wrapper}>
        {themeSpocSmeExperts?.map((item) => {
          return (
            <div className={styles.Row} key={item.id}>
              <div className={styles.rowWrapper}>
                <UserInline
                  userId={item.user_id}
                  first={item.name}
                  avatar={item.avatar || ""}
                  type={UserInlineTypes.WITH_NAME}
                  local
                />
                {item.title ? (
                  <>
                    <div className={styles.titleText}>{item.title}</div>
                    {item.plant?.name ? (
                      <div className={styles.titleText}>
                        Plant: {item.plant?.name}
                      </div>
                    ) : null}
                  </>
                ) : null}

                <div className={styles.deleteIcon}>
                  {isItStandardViewver(session, currentThemeData) && (
                    <Icon
                      {...smSize}
                      icon="icn-button-delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        return handleDeleteSmeMember(item.expertRelationId);
                      }}
                    />
                  )}
                </div>
                <div className={styles.expertText}>Expert</div>
              </div>
            </div>
          );
        })}
      </div>
    </Pannel>
  );
};

export default React.memo(FordSMeBlock);

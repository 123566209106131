import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./adminThemes.module.scss";

const AdminThemesFieldsComponent = () => {
  const {
    councilReducer: {},
  } = useSelector((state) => state);
  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <Link to="/admin" className={styles.rootText}>
            ADMIN
          </Link>
          <span className={styles.separationBar}> / </span>

          <Link to="/admin/themes" className={styles.rootText}>
            THEMES
          </Link>

          <span className={styles.separationBar}> / </span>
          <Link to="/admin/themes/groups" className={styles.rootText}>
            GROUPS
          </Link>
          <span className={styles.separationBar}> / </span>
          <span className={styles.activeBreadcrumbs}>FIELDS</span>
        </h4>
      </div>
      <div className={styles.bodyWrp}>Fields</div>
    </div>
  );
};
export default AdminThemesFieldsComponent;

import React from "react";

import { Label } from "modules/primitives";
import UserMultiSelectSearch from "../../usersSearch";

const ProjectOwner = ({ formikprops, isAdminWizardInstance }) => {
  return (
    <div className="mb-2">
      <Label>Project Owner</Label>
      <UserMultiSelectSearch
        disabled={isAdminWizardInstance}
        multi={false}
        placeholder="Search for project owner"
        labelField="full_name"
        valueField="id"
        searchBy="full_name"
        hasAll={false}
        onChange={(value) => {
          if (isAdminWizardInstance) return;
          formikprops.setFieldValue("project_owner", value);
        }}
        preselectCurrentUser={!isAdminWizardInstance}
        dropdownPosition="top"
      />
      <div className="caption small text-danger">
        {formikprops.touched['project_owner'] && formikprops.errors['project_owner']
          ? formikprops.errors['project_owner']
          : ""}
      </div>
    </div>
  );
};

export default React.memo(ProjectOwner);

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { ModalFooter, ModalBody } from "../../../application/modal";
import { councilListGet, councilSet } from "../council.action";
import { useModelPopup } from "../../../common/hooks";
import {
  Loading,
  Button,
  BtnType,
  Label,
  DropSelect,
} from "../../primitives/index";
import {
  companyGetFilters,
  companyGetTopics,
  resetAllCompanies,
} from "../../companies/companyMain/companyMain.action";
import styles from "./selectCouncil.module.scss";
import { setSelectedList } from "../../lists/store/lists.actions";

const SelectCouncilComponent = () => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const history = useHistory();
  const [selected, setSelected] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const {
    authReducer: { session },
    councilReducer: { councilList, selectedCouncil, loading },
    listsMainReducer: { selectedList },
  } = useSelector((state) => state);

  const handleCancelClick = (e) => {
    popup.hide();
  };

  useEffect(() => {
    if (session?.id) {
      dispatch(
        councilListGet({ active: true, userId: session.id, enqueueSnackbar })
      );
    }
  }, [session]);

  const redirectAndRefresh = () => {
    history.push("/home");
    window.location.reload();
  };

  const handleSaveClick = (e) => {
    dispatch(resetAllCompanies());
    dispatch(
      councilSet({ ...selected, integrated_tokens: session?.integrated_tokens })
    );
    dispatch(
      companyGetFilters({
        userId: session.id,
        councilId: selectedCouncil.id,
        enqueueSnackbar,
      })
    );
    dispatch(
      companyGetTopics({
        userId: session.id,
        councilId: selectedCouncil.id,
        enqueueSnackbar,
      })
    );
    if (selectedList) {
      dispatch(setSelectedList({ id: "following" }));
    }
    redirectAndRefresh();
    popup.hide();
  };

  const handleOptionSelect = ({ val, obj }) => {
    if (val && obj.length) {
      setSelected(...obj);
    }
  };

  return (
    <div className={styles.selectCouncilWrp}>
      <ModalBody>
        <Label>Councils</Label>
        {loading ? (
          <Loading />
        ) : (
          <DropSelect
            onChange={handleOptionSelect}
            placeholder="Council"
            labelField="name"
            valueField="id"
            searchBy="name"
            options={councilList}
            multi={null}
            className={null}
            SelecedValues={selectedCouncil && selectedCouncil.id}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          disable={loading}
          btn={loading ? BtnType.DISABLED : BtnType.REGULAR}
          onClick={!loading && handleSaveClick}
        >
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default React.memo(SelectCouncilComponent);

import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import RichEditor from "common/components/richEditor";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import selectMention from "../../../../themesCommon/mention/selectMention";

import { Label, Button, BtnType, MultiSelect } from "../../../../../primitives";
import styles from "./styles.module.scss";
import { themesGetAttempt } from "../../../../themesMainPage/themesMain.action";
import {
  themesPostAddAttempt,
  themesEditPostAttempt,
  searchMentionsGetAttempt,
} from "../postsBlock.action";

import {
  ModalFooter,
  ModalBody,
} from "../../../../../../application/modal/index";
import AttachFileComponent from "../../../../../../common/components/attachFile/index";
import createMentionEntities from "../../../../themesCommon/mention/mentionsOnEdit";
import checkForDeleteMention from "../../../../themesCommon/mention/handleDeleteMention";

const model = {
  subject: "",
};
const AddPostModal = (props) => {
  const { popup, themeId, mode, postData, homePageUse } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [postContent, setPostContent] = useState("");
  const [files, setFiles] = useState(
    postData?.documents ? postData.documents : []
  );
  const [theme_mentions_attributes, set_theme_mentions_attributes] = useState(
    []
  );
  const [dragActive, setDragActive] = useState(false);
  const [droppedFilesFromParent, setDroppedFilesFromParent] = useState([]);
  const [themeError, setThemeError] = useState(false);
  const [editorState, setEditorState] = useState({});
  const [triggerFromParrent, setTriggerFromParrent] = useState(false);
  const [query, setQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { currentThemeData, themesData },
      themesPostsReducer: { loadingMentions, mentions },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const initModel = {
    content: postData?.content,
    files: postData?.documents,
  };
  const handleCancelClick = () => {
    popup.hide();
  };

  const onChange = (_editorState, focus) => {
    setEditorState({
      editorState: _editorState,
    });
    if (focus) {
      focus();
    }
  };
  const linkRegex = /((http|https):\/\/[^\s]+)/g;

  const handleDrag = (e) => {
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setDroppedFilesFromParent(e.dataTransfer.files);
      setTriggerFromParrent(true);
    }
  };
  function replacer(matched) {
    let withProtocol = matched;
    if (!withProtocol.startsWith("http")) {
      withProtocol = "http://" + matched + " ";
    }
    const newStr = `<a style="color:#3994c1 !important ;text-align:center;" href="${withProtocol}"  target="_blank" >
      ${matched}
    </a>`;

    return newStr;
  }
  function findLinks(text) {
    const regex = /((http|https):\/\/[^\s]+)/g;
    return text.match(regex);
  }
  function findAnchors(text) {
    const matches = [];
    const regex = text.replace(
      /[^<]*(<a href="([^"]+)">([^<]+)<\/a>)/g,
      function () {
        matches.push(Array.prototype.slice.call(arguments, 1, 4));
      }
    );
    return matches;
  }
  const modifyMentionColor = () => {
    let copyOfText = postContent;

    theme_mentions_attributes.forEach((themeMention) => {
      let mentionLink = "";
      let navigationId = "";

      if (themeMention.theme_mentioned_resource_type === "Company") {
        mentionLink = "/companies/";
        navigationId =
          themeMention.slug || themeMention.theme_mentioned_resource_id;
      } else {
        mentionLink = "/people/internal/profile/";
        navigationId = themeMention.theme_mentioned_resource_id;
      }
      const mentionName =
        themeMention.theme_mention_name ||
        themeMention.theme_mentioned_resource_name;
      copyOfText = copyOfText.replaceAll("&nbsp;", " ");
      if (postData?.theme_post_id) {
        const elementToSearch = `@${mentionName} `;
        const elementToChangeOn = `<a href="${process.env.REACT_APP_REDIRECT_URI_APP}${mentionLink}${navigationId}" id="${themeMention.mention_id}">@${mentionName}</a> `;
        copyOfText = copyOfText.replace(elementToSearch, elementToChangeOn);
      } else {
        const elementToSearch = `@${mentionName} `;
        const elementToChangeOn = `<a href="${process.env.REACT_APP_REDIRECT_URI_APP}${mentionLink}${navigationId}" id="${themeMention.mention_id}">@${mentionName}</a> `;
        copyOfText = copyOfText.replace(elementToSearch, elementToChangeOn);
      }
    });
    return copyOfText;
  };
  const searchFunction = (val) => {
    setQuery(val);
  };
  const changeEditorState = (contentArg, intialRender) => {
    let editorStateText = contentArg;
    const themeMentions = [];
    if (postData?.theme_post_id) {
      set_theme_mentions_attributes([]);

      postData.theme_mentions.forEach((mention) => {
        let mentionLink = "";
        let navigationId = "";
        const mentionType = mention.theme_mentioned_resource_type;
        const mentionName = mention.theme_mentioned_resource_name;
        if (mention.theme_mentioned_resource_type === "Company") {
          mentionLink = "/companies/";
          navigationId = mention.slug || mention.theme_mentioned_resource_id;
        } else {
          mentionLink = "/people/internal/profile/";
          navigationId = mention.theme_mentioned_resource_id;
        }
        const elementToSearch = `<span><a style="${
          mentionType === "Company"
            ? "color:black !important; font-weight:bold"
            : "color:#3994c1; font-weight:bold"
        } " href="${
          process.env.REACT_APP_REDIRECT_URI_APP
        }${mentionLink}${navigationId}">@${mentionName}</a></span> `;
        if (intialRender) {
          const elementToChangeOn = `@${mention.theme_mentioned_resource_name} `;
          editorStateText = editorStateText.replace(
            elementToSearch,
            elementToChangeOn
          );
          themeMentions.push({
            ...mention,
            name: `@${mention.theme_mentioned_resource_name}`,
            id: mention.theme_mention_id,
            mention_id:
              new Date().toString().split(" ").join(" ") +
              (Math.random() * 1000).toString(),
          });
        }
      });
      const blocksFromHTML = convertFromHTML(editorStateText);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const tempEditorState = EditorState.createWithContent(content);
      const plainText = tempEditorState
        .getCurrentContent()
        .getPlainText("\u0001");
      const editorStateWithMention = EditorState.createWithContent(
        createMentionEntities(plainText, themeMentions)
      );
      set_theme_mentions_attributes(themeMentions);

      setEditorState({
        editorState: editorStateWithMention,
      });
    } else {
      const blocksFromHTML = convertFromHTML(contentArg);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState({
        editorState: EditorState.createWithContent(content),
      });
    }
  };

  const removeIdsFromHTML = (textWithId) => {
    let textWithoutId = textWithId;
    theme_mentions_attributes.forEach((themeMention) => {
      let mentionLink = "";
      let navigationId = "";
      const mentionType = themeMention.theme_mentioned_resource_type;
      if (themeMention.theme_mentioned_resource_type === "Company") {
        mentionLink = "/companies/";
        navigationId =
          themeMention.slug || themeMention.theme_mentioned_resource_id;
      } else {
        mentionLink = "/people/internal/profile/";
        navigationId = themeMention.theme_mentioned_resource_id;
      }
      const mentionName =
        themeMention.theme_mention_name ||
        themeMention.theme_mentioned_resource_name;
      const elementToSearch = `<a href="${process.env.REACT_APP_REDIRECT_URI_APP}${mentionLink}${themeMention.navigationId}" id="${themeMention.mention_id}">@${mentionName}</a> `;
      const elementToChangeOn = `<span><a style="${
        mentionType === "Company"
          ? "color:black !important; font-weight:bold"
          : "color:#3994c1; font-weight:bold"
      } " href="${
        process.env.REACT_APP_REDIRECT_URI_APP
      }${mentionLink}${navigationId}">@${mentionName}</a></span> `;
      textWithoutId = textWithoutId.replace(elementToSearch, elementToChangeOn);
    });
    return textWithoutId;
  };

  const onSubmitValue = (value, e) => {
    let themeMentionsAttributes = theme_mentions_attributes;
    let postInputText = postContent;
    postInputText = modifyMentionColor();
    themeMentionsAttributes = checkForDeleteMention(
      mode,
      theme_mentions_attributes,
      postInputText
    );
    postInputText = removeIdsFromHTML(postInputText);
    if (homePageUse && value.id === undefined) {
      setThemeError(true);
      return;
    }
    let data = {
      theme_post: {
        content: postInputText,
        documents_attributes: files,
      },
    };
    if (theme_mentions_attributes.length) {
      data = {
        theme_post: {
          ...data.theme_post,
          theme_mentions_attributes: themeMentionsAttributes,
        },
      };
    }
    if (postData?.theme_post_id) {
      dispatch(
        themesEditPostAttempt({
          themeId: themeId || postData.theme_id,
          postId: postData.theme_post_id,
          data,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(
        themesPostAddAttempt({
          themeId: themeId || value.id[0].id,
          data,
          enqueueSnackbar,
        })
      );
    }
    popup.hide();
    setThemeError(false);
  };

  const replaceImage = (index) => {
    setFiles((prev) => {
      return prev.map((el, i) => {
        if (index === i) {
          return { ...el, _destroy: true };
        }
        return el;
      });
    });
  };
  const handleImgUpdate = (url, name, third) => {
    if (url) {
      setFiles((prev) => {
        return [
          ...prev,
          {
            name,
            document_resource_type: "ThemePost",
            added_by_id: session.id,
            council_id: selectedCouncil?.id,
            uri: url,
          },
        ];
      });
    }
  };
  useEffect(() => {
    if (selectedCouncil && homePageUse) {
      dispatch(
        themesGetAttempt({
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil]);

  useEffect(() => {
    if (mode === "edit" && postData?.theme_post_id) {
      setTimeout(() => {
        changeEditorState(postData.content, true);
      });
    }
  }, [postData, mode]);

  const handleAddMention = (e) => {
    selectMention(
      e,
      postContent,
      setPostContent,
      set_theme_mentions_attributes,
      theme_mentions_attributes,
      session.id,
      e.id,
      "ThemePost",
      postData?.theme_post_id,
      mode
    );
  };

  useEffect(() => {
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      setDebouncedValue(query);
    }, delay);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [query]);

  const handleRequest = (inputValue) => {
    let tempQuery = "*";
    if (inputValue.length) {
      tempQuery = inputValue;
    }
    dispatch(
      searchMentionsGetAttempt({
        search: { query: tempQuery },
        enqueueSnackbar,
      })
    );
  };

  useEffect(() => {
    handleRequest(debouncedValue);
  }, [debouncedValue]);

  return (
    <div className={styles.inner}>
      <Formik
        initialValues={mode === "edit" ? initModel : model}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          onSubmitValue(values);
          resetForm();
        }}
      >
        {({ handleSubmit, setFieldValue, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <div className="row">
                <div className="col-12">
                  {homePageUse && (
                    <div>
                      <Label>Theme</Label>
                      <MultiSelect
                        onChange={(val) => {
                          setFieldValue("id", val);
                        }}
                        placeholder={`Choose ${
                          selectedCouncil?.settings
                            ?.themes_display_name_singular || "Theme"
                        }`}
                        labelField="name"
                        valueField="id"
                        searchBy="name"
                        options={themesData}
                        formProps={formikprops}
                        multi={false}
                      />
                      {themeError && (
                        <span className={styles.error}>Required field </span>
                      )}
                    </div>
                  )}
                  <Label>Post Content</Label>
                  <div
                    className={dragActive ? styles.draggBox : styles.textBoxWrp}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  >
                    <RichEditor
                      initialValue={postData?.content || ""}
                      settedValue={postContent}
                      discussionId={postData?.theme_post_id || ""}
                      mentionsList={mentions}
                      homePageUse={homePageUse}
                      loadingMentions={loadingMentions}
                      handleAddMention={handleAddMention}
                      customOnChange={onChange}
                      searchFunction={searchFunction}
                      updateValue={(e) => {
                        if (findLinks(e)) {
                          if (findAnchors(e)[0]) {
                            setPostContent(e);
                            return;
                          }
                          const str = e?.replace(/<\/?[^>]+(>|$)/g, "");
                          setPostContent(str.replace(linkRegex, replacer));
                        } else {
                          setPostContent(e);
                        }
                      }}
                      shouldReset={false}
                      // className={styles.textBox}
                      placeholder="What's on your mind?"
                      newEditorState={editorState}
                    />
                  </div>
                </div>

                <div className={styles.footerActions}>
                  <AttachFileComponent
                    files={files}
                    onChange={handleImgUpdate}
                    droppedFilesFromParent={droppedFilesFromParent}
                    triggerFromParrent={triggerFromParrent}
                    uploadDirName={`themes/${currentThemeData?.id}/key`}
                    replaceImage={replaceImage}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button type="submit" btn={BtnType.REGULAR}>
                {mode === "edit" ? "Save" : "Create"}
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(AddPostModal);

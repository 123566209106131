import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { Icon, smSize } from "../../../../../common/icon";
import { useModelPopup } from "../../../../../common/hooks";
import mainStyles from "../../../ideasWrapper.module.scss";
import styles from "./ideaProjects.module.scss";
import AddDiscussionIcon from "../../../../../assets/icn-table-add.svg";
import AddRelatedProjectComponent from "./addRelatedProject";
import { deleteRelatedProjectAttempt } from "../../../idea.action";
import { BtnType, Button, CheckBox, CheckType } from "modules/primitives";
import { httpPut } from "../../../../../common/httpCall";

const IdeaProfileProjects = ({ selectedIdea, canEdit }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil },
    ideaReducer: { projectsLoading },
  } = useSelector((state) => state);
  const [projects, setProjects] = useState(null);
  const [activeProjectEdit, setActiveProjectEdit] = useState(null);

  useEffect(() => {
    setProjects(selectedIdea.projects);

    return () => {
      setProjects(null);
    };
  }, [selectedIdea]);

  const handleAddProjects = () => {
    popup.show({
      title: "Add a Project to this Idea",
      component: (
        <AddRelatedProjectComponent selectedIdea={selectedIdea} popup={popup} />
      ),
    });
  };
  const handleRemoveProjects = (elem) => {
    dispatch(
      deleteRelatedProjectAttempt({
        project_id: elem.project_id,
        idea_id: selectedIdea.id,
        enqueueSnackbar,
        cb: () => {
          enqueueSnackbar(`Successfully deleted project!`, {
            variant: "success",
          });
        },
      })
    );
  };

  const handleEdit = (project) => {
    if (activeProjectEdit === project.project_id) {
      setActiveProjectEdit(null);
      return;
    }
    setActiveProjectEdit(project.project_id);
  };

  const handleSave = (project) => {
    setActiveProjectEdit(null);

    httpPut({
      call: `project_ideas?project_id=${project.project_id}&idea_id=${selectedIdea.id}`,
      data: {
        similar: project.similar,
      },
    }).subscribe(() => {
      enqueueSnackbar("Success", {
        variant: "success",
      });
    });
  };

  const handleSimilarChange = (newValue, project) => {
    const newProjectsArray = projects.map((p) => {
      if (p.project_id === project.project_id) {
        return {
          ...p,
          similar: newValue,
        };
      }

      return p;
    });

    setProjects(newProjectsArray);
  };

  return (
    <div className="d-flex flex-column mt-4">
      <div
        className={`d-flex align-items-center justify-content-between ${mainStyles.blockHeader}`}
      >
        <span className={mainStyles.blockTitle}>Related Projects</span>
        {canEdit && (
          <img
            className={mainStyles.addIcon}
            onClick={handleAddProjects}
            src={AddDiscussionIcon}
            alt="Add new Project"
          />
        )}
      </div>
      <div className={`${styles.projectsContent} ${mainStyles.blockContent}`}>
        {projectsLoading && "Loading"}
        {!projects?.length ? (
          <span className={mainStyles.stub}>No related projects yet.</span>
        ) : (
          projects?.map((p) => (
            <div className={styles.projectContainer}>
              <div className="d-flex flex-column">
                <Link
                  key={p.project_id}
                  className={styles.projectUrl}
                  to={`/projects/${p?.project_id}/overview`}
                >
                  {p.name}
                </Link>
                {p.project_id === selectedIdea?.original_project?.id && (
                  <span className="small muted">Original Project</span>
                )}
                {p.similar && (
                  <span className="small muted">Similar Project</span>
                )}
                {p.project_id === activeProjectEdit && (
                  <div>
                    <label className="d-flex align-items-center">
                      <CheckBox
                        checkType={CheckType.BLUE}
                        name="isSimilarProject"
                        onChange={(val) => handleSimilarChange(val, p)}
                        isChecked={p.similar}
                      />
                      <span>Similar Project</span>
                    </label>
                    <div className="mt-2">
                      <Button
                        btn={BtnType.FRAME_LESS}
                        onClick={() => handleSave(p)}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {canEdit && (
                <div>
                  {selectedCouncil?.name === "Ford" && (
                    <Icon
                      {...smSize}
                      icon="icn-edit"
                      className={styles.deleteIcon}
                      onClick={() => handleEdit(p)}
                    />
                  )}
                  <Icon
                    {...smSize}
                    icon="icn-button-delete"
                    className={styles.deleteIcon}
                    onClick={() => handleRemoveProjects(p)}
                  />
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default React.memo(IdeaProfileProjects);

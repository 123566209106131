import React from "react";
import Select from "react-dropdown-select";
import {
  Label,
  TextBox,
} from "../../../../../primitives";
import styles from "../styles.module.scss";
import RichEditor from "../../../../../../common/components/richEditor";

const NewProject = (props) => {
  const {
    initialValues,
    orgType,
    activityType,
    data,
    formikprops,
    values,
    handleChange,
    setFieldValue,
    selectedProjectTopic,
    handleProjectTopicSelect,
    handleStatusSelect,
    projectTopics,
    selectedStatus,
  } = props;

  const setStatus = () => {
    if (initialValues?.status) {
      return initialValues?.status;
    }
    if (selectedStatus.length > 0) {
      return selectedStatus;
    }

    return [{ name: "Open", value: "open" }];
  };

  return (
    <>
      {orgType === "patient" && (
        <div className="mt-2">
          <Label>Project Title</Label>
          <TextBox
            type="text"
            name="title"
            placeholder="Project Title"
            className={styles.textBox}
            formProps={{ ...formikprops, values }}
            onChange={handleChange}
          />
        </div>
      )}
      {orgType === "patient" && selectedProjectTopic ? (
        <div>
          <Label>Project Topic</Label>
          <Select
            multi
            closeOnSelect
            name="project_topics"
            values={initialValues?.project_topics || selectedProjectTopic}
            className={styles.customPatientOrgMultiselect}
            onChange={(vals) => handleProjectTopicSelect(vals, setFieldValue)}
            options={projectTopics}
            labelField="name"
            valueField="name"
            placeholder="Select Project Topics"
            formProps={{ ...formikprops, values }}
          />
        </div>
      ) : null}
      {orgType === "patient" && (
        <div>
          <Label>Status</Label>
          <Select
            closeOnSelect
            name="status"
            values={setStatus()}
            className={styles.customPatientOrgMultiselect}
            onChange={(vals) => handleStatusSelect(vals, setFieldValue)}
            options={[{ name: "Open", value: "open" }, { name: "Closed", value: "closed" }]}
            labelField="name"
            valueField="value"
            placeholder="Select Status"
            formProps={{ ...formikprops, values }}
          />
        </div>
      )}
      {orgType === "patient"
        && (activityType === "Project" || data?.type === "Project") && (
          <div className="mb-4">
            <Label>Summary</Label>
            <RichEditor
              updateValue={(val) => setFieldValue("summary", val)}
              initialValue={values?.summary || ""}
              placeholder="Summary"
            />
          </div>
      )}
    </>
  );
};

export default NewProject;

export const projectStateType = {
  FULL_TEXT: 1,
  ICON: 2,
};

export const projectStates = {
  OPEN: "open",
  CLOSED: "closed",
  PAUSED: "paused",
  AT_RISK: "at_risk",
  OFF_TRACK: "off_track",
  ON_HOLD: "on_hold"
};

export const projectStatesText = {
  open: "On Track",
  closed: "Closed",
  paused: "Paused",
  at_risk: "At Risk",
  off_track: "Off Track",
  on_hold: "On Hold"
};

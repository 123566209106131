import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { FaLongArrowAltRight, FaSalesforce } from "react-icons/fa";
import { IconContext } from "react-icons";
import { setPageAttribute } from "modules/admin/adminIntegrations/adminIntegrations.actions";
import { Button, BtnType } from "../../primitives";
import styles from "./configureData.module.scss";
import { useModelPopup } from "../../../common/hooks";
import SyncDetailsModal from "./syncDetailsModal";
import { httpPost } from "../../../common/httpCall";

const ConfigureData = () => {
  const location = useLocation();
  const history = useHistory();
  const [attribute, setAttribute] = useState(null);
  const {
    adminIntegrationsReducer: { pageAttribute },
    authReducer: { session },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const [contactSyncDetails, setContactSyncDetails] = useState([]);
  const [accountSyncDetails, setAccountSyncDetails] = useState([]);

  useEffect(() => {
    setAttribute(location?.state?.attribute);
  }, []);

  useEffect(() => {
    if (["admin", "user"].includes(attribute)) {
      dispatch(setPageAttribute(attribute));
    }
  }, [attribute]);

  const handleConfigureAccount = () => {
    history.push("/salesforce/configureAccounts");
  };

  const handleConfigureContacts = () => {
    history.push("/salesforce/configureContacts");
  };
  const handleBack = () => {
    if (pageAttribute === "admin") {
      history.push({ pathname: "/admin/integrations" });
    } else if (pageAttribute === "user") {
      history.push({ pathname: "/settings/integrations" });
    }
  };

  useEffect(() => {
    const user_id = session?.id || null;
    const council_id = JSON.parse(localStorage.getItem("council"))?.id || null;
    httpPost({
      call: "sf_sync_details/get_sync_details",
      data: { user_id, council_id },
    })
      .pipe()
      .subscribe((res) => {
        res.response.map((record) => {
          if (record.job_attribute === "Contact") {
            setContactSyncDetails((contactSyncDetails) => [
              ...contactSyncDetails,
              record,
            ]);
          } else {
            setAccountSyncDetails((accountSyncDetails) => [
              ...accountSyncDetails,
              record,
            ]);
          }
          return true;
        });
      });
  }, []);

  const popup = useModelPopup();

  const showPopup = (sync_attribute) => {
    const record =
      sync_attribute === "Contact" ? contactSyncDetails : accountSyncDetails;
    popup.show({
      title: "Sync Details",
      show: true,
      height: "300",
      width: "540",
      component: (
        <SyncDetailsModal record={record} attribute={sync_attribute} />
      ),
    });
  };

  return (
    <div className={styles.main}>
      <div className={styles.mainHead}>
        <div className={styles.backBtn}>
          <Button btn={BtnType.FRAME_LESS} onClick={() => handleBack()}>
            Back
          </Button>
        </div>
      </div>
      <div>
        <div className={`row ${styles.headerRow}`}>
          <div className="col-3">
            <h3>
              <strong>Salesforce Object</strong>
            </h3>
          </div>
          <div className="col-3">
            <h3>
              <strong>Traction Field</strong>
            </h3>
          </div>
          <div className="col-1" />
          <div className="col-3" />
        </div>

        <div className={`row ${styles.rowStyle}`}>
          <div className={`col ${styles.rowItem}`}>
            <IconContext.Provider
              value={{ className: "shared-class", size: 50 }}
            >
              <FaSalesforce />
            </IconContext.Provider>
          </div>
          <div className={`col ${styles.rowItem}`} />
          <div className={`col ${styles.rowItem} ${styles.backgroundLogo}`}>
            <div className={styles.logoWrp} />
          </div>
          <div className="col">
            <h5 className={styles.rowItem}>
              <b> Last Import </b>
            </h5>
          </div>
          <div className="col">
            <h5 className={styles.rowItem}>
              <b> Records Imported </b>
            </h5>
          </div>
          <div className="col">
            <h5 className={styles.rowItem}>
              <b> Last Export </b>
            </h5>
          </div>
          <div className="col">
            <h5 className={styles.rowItem}>
              <b> Records Exported </b>
            </h5>
          </div>
          <div className="col">
            <h5 className={styles.rowItem}>
              <b>Action </b>
            </h5>
          </div>
          {(JSON.parse(localStorage.getItem("account_token")) ||
            JSON.parse(localStorage.getItem("contact_token"))) && (
            <div className="col" />
          )}
          <div className="col">
            <h5 className={styles.rowItem}>
              <b>Sync Staus</b>
            </h5>
          </div>
        </div>

        <div className={`row ${styles.rowStyle}`}>
          <div className="col">
            <h3 className={styles.rowItem}>
              <b>Accounts </b>
            </h3>
          </div>
          <div className="col">
            <h4 className={styles.rowItem}>
              <b>
                <i>maps with</i>
                <br />
                <IconContext.Provider
                  value={{ className: "shared-class", size: 50 }}
                >
                  <FaLongArrowAltRight />
                </IconContext.Provider>
              </b>
            </h4>
          </div>
          <div className="col">
            <h3 className={styles.rowItem}>
              <b> Company </b>
            </h3>
          </div>
          <div className="col">
            <h5 className={styles.rowItem}>
              {localStorage.getItem("importAccountTime") || "NA"}
            </h5>
          </div>
          <div className="col">
            <h5 className={styles.rowItem}>
              {localStorage.getItem("importAccountSize") || "NA"}
            </h5>
          </div>
          <div className="col">
            <h5 className={styles.rowItem}>
              {localStorage.getItem("exportAccountTime") || "NA"}
            </h5>
          </div>
          <div className="col">
            <h5 className={styles.rowItem}>
              {localStorage.getItem("exportAccountSize") || "NA"}
            </h5>
          </div>
          <div className={`col ${styles.btnCol}`}>
            <Button
              className={`btn btn-info ${styles.btnConfigure} ${styles.rowItem}`}
              onClick={handleConfigureAccount}
            >
              {" "}
              Configure Accounts{" "}
            </Button>
          </div>
          {JSON.parse(localStorage.getItem("account_token")) ? (
            <div className={`col ${styles.btnCol}`}>
              <Button
                className={`btn btn-info ${styles.btnConfigure} ${styles.rowItem}`}
                onClick={() => showPopup("Account")}
              >
                {" "}
                Sync Details{" "}
              </Button>
            </div>
          ) : (
            JSON.parse(localStorage.getItem("contact_token")) && (
              <div className={`col ${styles.btnCol}`} />
            )
          )}
          <div className="col">
            <h5 className={styles.rowItem}>
              {JSON.parse(localStorage.getItem("account_token")) ? "ON" : "OFF"}
            </h5>
          </div>
        </div>

        <div className={`row ${styles.rowStyle}`}>
          <div className="col">
            <h3 className={styles.rowItem}>
              <b> Contacts </b>
            </h3>
          </div>
          <div className="col">
            <h4 className={styles.rowItem}>
              <b>
                <i>maps with</i>
                <br />
                <IconContext.Provider
                  value={{ className: "shared-class", size: 50 }}
                >
                  <FaLongArrowAltRight />
                </IconContext.Provider>
              </b>
            </h4>
          </div>
          <div className="col">
            <h3 className={styles.rowItem}>
              <b> Contacts </b>
            </h3>
          </div>
          <div className="col">
            <h5 className={styles.rowItem}>
              {localStorage.getItem("importContactTime") || "NA"}
            </h5>
          </div>
          <div className="col">
            <h5 className={styles.rowItem}>
              {localStorage.getItem("importContactSize") || "NA"}
            </h5>
          </div>
          <div className="col">
            <h5 className={styles.rowItem}>
              {localStorage.getItem("exportContactTime") || "NA"}
            </h5>
          </div>
          <div className="col">
            <h5 className={styles.rowItem}>
              {localStorage.getItem("exportContactSize") || "NA"}
            </h5>
          </div>
          <div className={`col ${styles.btnCol}`}>
            <Button
              className={`btn btn-info ${styles.btnConfigure}`}
              onClick={handleConfigureContacts}
            >
              {" "}
              Configure Contacts{" "}
            </Button>
          </div>
          {JSON.parse(localStorage.getItem("contact_token")) ? (
            <div className={`col ${styles.btnCol}`}>
              <Button
                className={`btn btn-info ${styles.btnConfigure}`}
                onClick={() => showPopup("Contact")}
              >
                {" "}
                Sync Details{" "}
              </Button>
            </div>
          ) : (
            JSON.parse(localStorage.getItem("account_token")) && (
              <div className={`col ${styles.btnCol}`} />
            )
          )}
          <div className="col">
            <h5 className={styles.rowItem}>
              {JSON.parse(localStorage.getItem("contact_token")) ? "ON" : "OFF"}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ConfigureData);

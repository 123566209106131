import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import styles from './styles.module.scss';
import {
  Button,
  BtnType,
  Label,
  CheckBox,
  CheckType,
} from 'modules/primitives';
import { addLastStepWorkedOn, setIdeaFormFields } from '../adminIdeas.action';
import { useModelPopup } from '../../../../common/hooks';
import NewCheckListComponent from '../../../companies/company/details/newCheckList';
import NewDropdownComponent from '../../../../common/popups/newItem/newDropDown';
import classnames from "classnames";

const SectionBlock = ({
  item,
  handleEditClick,
  handleDeleteField,
  ideas_template_id,
  curStep,
}) => {
  const {
    councilReducer: { selectedCouncil },
    adminIdeasReducer: { currentIdeaFormFields },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [sectionType, handleDropdownSelect] = useState('text');
  const [categoryType, handleDropdownSelectCategory] = useState('description');
  const [required, setRequired] = useState(false);
  const [name, handleAddName] = useState('');
  const [promptText, handleAddPromt] = useState('');
  const [placeholder, handleAddPlaceholder] = useState('');
  const [sectionOptionValues, handleAddSectionOptionValue] = useState([]);
  const [multiselect, handleAddMultiselect] = useState(false);
  const [errorValidation, setErrorValdation] = useState(false);

  const popup = useModelPopup();
  const formRef = React.createRef();

  const deleteSection = (position, step_nr) => {
    let flagForFieldsPosition = false;
    const modifiedArray = currentIdeaFormFields.map((field) => {
      if (field.position === position && field.step_nr === step_nr) {
        field._destroy = true;
        flagForFieldsPosition = true;
      }
      if (field.step_nr === step_nr && flagForFieldsPosition) {
        field.position--;
      }
      return field;
    });
    handleDeleteField(modifiedArray);
    dispatch(setIdeaFormFields(modifiedArray));
  };

  useEffect(() => {
    handleDropdownSelect(item?.section_type);
    handleDropdownSelectCategory(item.category_type);
    setRequired(item.field_required);
    handleAddName(item.section_name);
    handleAddPromt(item.prompt_text);
    handleAddPlaceholder(item.placeholder);
    handleAddSectionOptionValue(item.section_option_values);
    handleAddMultiselect(item.multiselect);
    setEditable(false);
  }, [item]);

  const onSubmit = async (position, step_nr) => {
    let options;
    let multiselect;
    let title;
    let instruction;

    if (sectionType === 'checklist' || sectionType === 'dropdown') {
      options = formRef.current.values.options;
      multiselect = formRef.current.values.multiselect;
      title = formRef.current.values.title;
      instruction = formRef.current.values.instruction;
    }
    const submitData = {
      category_type: categoryType,
      council_id: selectedCouncil.id,
      created_at: item.created_at,
      field_required: required,
      id: item.id,
      ideas_template_id,
      multiselect: multiselect,
      placeholder: placeholder,
      position,
      prompt_text: instruction || promptText,
      section_name: title || name,
      section_option_values: options ? options : null,
      section_type: sectionType,
      step_nr: curStep,
    };

    if (editable && !errorValidation) {
      handleEditClick(position, step_nr, submitData);
      setEditable(false);
    }
    dispatch(
      addLastStepWorkedOn({ mostRecentStepWorkedOn: submitData.step_nr })
    );

    popup.hide();
  };
  const selectOptions = [
    {
      val: 'text',
      text: 'Text',
    },
    {
      val: 'checklist',
      text: 'Checklist',
    },
    {
      val: 'dropdown',
      text: 'Dropdown',
    },
  ];

  const renderBody = () => {
    switch (sectionType) {
      case 'checklist':
        return (
          <NewCheckListComponent
            data={{
              field_values: "",
              field_name: name,
              field_placeholder: promptText,
              option_selected: [],
              option_values: sectionOptionValues,
              multiselect: multiselect,
            }}
            mode={editable ? "edit" : "add"}
            formRef={formRef}
            disabled={!editable}
            handleOnSubmit={onSubmit}
            errorValidation={errorValidation}
          />
        );
      case 'dropdown':
        return (
          <NewDropdownComponent
            adminLocation={true}
            errorValidation={errorValidation}
            formRef={formRef}
            mode={editable ? "edit" : "add"}
            disabled={!editable}
            data={{
              field_values: "",
              field_name: name,
              field_placeholder: promptText,
              option_selected: [],
              option_values: sectionOptionValues,
              multiselect: multiselect,
            }}
            handleOnSubmit={onSubmit}
          />
        );

      default:
        break;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className="row">
        <div className="col-12">
          <div className={classnames(styles.selectContainer, "row")}>
            <Label>Field type</Label>
            <div>
              <select
                onChange={(val) => {
                  handleDropdownSelect(val.target.value);
                }}
                value={sectionType}
                className={styles.dropWrapper}
                disabled={!editable}
              >
                {selectOptions.map((item) => (
                  <option key={item.val} value={item.val}>
                    {item.text}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {curStep === 2 && (
            <div className={classNames(styles.selectContainer, "row")}>
              <Label>Add more fields to</Label>
              <div className={styles.categoryTypeRadioBlock}>
                <div className={styles.categoryTypeRadioButton}>
                  <input
                    onChange={() => handleDropdownSelectCategory('description')}
                    type="radio"
                    disabled={!editable}
                    checked={categoryType === 'description'}
                  />
                  <label className={styles.radioButtonText}>Description</label>
                </div>
                <div className={styles.categoryTypeRadioButton}>
                  <input
                    onChange={() =>
                      handleDropdownSelectCategory('area_of_focus')
                    }
                    type="radio"
                    disabled={!editable}
                    checked={categoryType === 'area_of_focus'}
                  />
                  <label className={styles.radioButtonText}>
                    Area of focus
                  </label>
                </div>
              </div>
            </div>
          )}
          {sectionType === 'checklist' || sectionType === 'dropdown' ? (
            renderBody()
          ) : (
            <>
              <Label>Title or Question</Label>
              <div className={styles.textBoxWrp}>
                <input
                  type="text"
                  name="name"
                  placeholder="Add title name"
                  onChange={(val) => {
                    if (val.target.value.length === 0) {
                      setErrorValdation(true);
                    } else {
                      setErrorValdation(false);
                    }
                    handleAddName(val.target.value);
                  }}
                  value={name}
                  className={styles.textBox}
                  disabled={!editable}
                />
              </div>
              {errorValidation && (
                <div className={styles.errorBox}>
                  <sup>*</sup>field is required
                </div>
              )}
            </>
          )}
          {sectionType !== 'dropdown' && sectionType !== 'checklist' && (
            <>
              <Label>Prompt text</Label>
              <div className={styles.textBoxWrp}>
                <input
                  type="text"
                  name="prompt_text"
                  placeholder="Add prompt text"
                  onChange={(val) => handleAddPromt(val.target.value)}
                  className={styles.textBox}
                  value={promptText}
                  disabled={!editable}
                />
              </div>

              <Label>Placeholder (optional)</Label>
              <div className={styles.textBoxWrp}>
                <input
                  type="text"
                  name="placeholder"
                  placeholder="Add placeholder text"
                  value={placeholder}
                  onChange={(val) => handleAddPlaceholder(val.target.value)}
                  className={styles.textBox}
                  disabled={!editable}
                />
              </div>
            </>
          )}
          <div
            className={classNames(styles.formElement, styles.multiSelectWrp)}
          >
            <CheckBox
              checkType={CheckType.BLUE}
              isChecked={item?.section_name_slug === "idea_name" ||
              item?.section_name_slug === "idea_description"
                ? true
                : required
              }
              onChange={(value) => setRequired(value)}
              disabled={item?.section_name_slug === "idea_name" ||
                item?.section_name_slug === "idea_description"
                ? true
                : !editable
              }
            />
            <Label>Required</Label>
          </div>
        </div>
      </div>

      <div className={styles.titleRow}>
        <div className={styles.rightWrp}>
          {editable ? (
            <div className={styles.colMore}>
              <Button
                btn={BtnType.OUTLINE}
                className={styles.button}
                icon="icn-edit"
                onClick={() => setEditable(false)}
              >
                Cancel
              </Button>
              <Button
                btn={BtnType.OUTLINE}
                className={styles.button}
                icon="icn-edit"
                onClick={() => onSubmit(item.position, item.step_nr)}
              >
                Save
              </Button>
            </div>
          ) : (
            <div className={styles.colMore}>
              <Button
                btn={BtnType.OUTLINE}
                className={styles.button}
                icon="icn-edit"
                onClick={() => setEditable(true)}
              >
                Edit
              </Button>
              {
                item.section_name_slug !== "idea_name" &&
                item.section_name_slug !== "idea_description" ? (
                  <Button
                    btn={BtnType.OUTLINE}
                    className={styles.button}
                    icon="icn-button-delete"
                    onClick={() =>
                      deleteSection(item.position, item.step_nr, item.id)
                    }
                  >
                    Delete
                  </Button>
                ) : null
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default React.memo(SectionBlock);

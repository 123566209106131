import * as Yup from "yup";
import { objectType } from "../../../../modules/companies/company/details/details.constant";

export const validation = Yup.object().shape({});

export const model = {
  type: objectType.slides,
  title: "",
  embedCode: "",
  options: [],
};

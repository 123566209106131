import React from "react";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import styles from "./productContacts.module.scss";
import { BtnType, Button, MoreOptions, UserInline, UserInlineTypes } from "modules/primitives";
import useModelPopup from "common/hooks/modelPopup.hook";
import NewItemComponent from "modules/companies/company/overview/addNew";
import { moreOptions } from "modules/companies/company/overview/companyContact/companyContact.constant";
import { deleteProductContact } from "modules/companies/product/store/product.actions";

const ProductContacts = ({ product, canEdit }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();

  const handleAddContacts = () => {
    popup.show({
      show: true,
      title: "Add Product Contact",
      component: (
        <NewItemComponent
          type="contact"
          product={product}
          productInstance
        />
      ),
    });
  };

  const handleEditClick = (contact) => {
    popup.show({
      show: true,
      title: "Edit Product Contact",
      component: (
        <NewItemComponent
          type="contact"
          product={product}
          productInstance
          data={contact}
        />
      ),
    });
  }

  const handleMoreOptionClick = (options, contact) => {
    if (options === 5) {
      handleEditClick(contact);
    }
    if (options === 6) {
      dispatch(
        deleteProductContact({
          company_id: product.company.id,
          id: contact.id,
          product_id: product.id,
          enqueueSnackbar,
          cb: () => {},
        }),
      );
    }
  };

  return (
    <div className={styles.productContacts}>
      {
        product.product_contacts?.length ? (
          <div className="mb-4">
            {
              product.product_contacts.map((contact) => (
                <div key={contact.id} className="mb-2">
                  <div className="d-flex justify-content-between">
                    <UserInline
                      classObj={{ name: styles.userName }}
                      type={UserInlineTypes.WITH_NAME}
                      id={contact.id}
                      first={contact.first_name || ""}
                      last={contact.last_name || ""}
                      avatar={contact.avatar}
                      noNavigation
                      local
                    />
                    <MoreOptions
                      options={moreOptions}
                      onClick={(val) => handleMoreOptionClick(val, contact)}
                    />
                  </div>
                  <div className={styles.userDetails}>
                    <div className={classNames("caption small", styles.position)}>
                      {contact.email}
                    </div>
                    <div className="caption">
                      {contact.title}
                      {contact && contact.department_or_organization
                        ? `, ${contact.department_or_organization}`
                        : ""}
                    </div>
                    <div className="caption">{contact.mobile}</div>
                  </div>
                </div>
              ))
            }
            {
              canEdit ? (
                <div className="d-flex justify-content-end mt-3">
                  <Button
                    className="mt-3"
                    onClick={handleAddContacts}
                    btn={BtnType.REGULAR}
                  >
                    ADD CONTACT
                  </Button>
                </div>
              ) : null
            }
          </div>
        ) : null
      }
      {
        !product.product_contacts?.length && canEdit ? (
          <div className="d-flex align-items-center flex-column">
            <span>No contacts yet.</span>
            <Button
              className="mt-3"
              onClick={handleAddContacts}
              btn={BtnType.REGULAR}
            >
              ADD CONTACTS
            </Button>
          </div>
        ) : null
      }
    </div>
  );
};

export default ProductContacts;

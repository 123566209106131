import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { solutionsSearchAttempt } from "modules/solutions/solutionsMainPage/solutionsMain.actions";
import { addRelatedSolutionAttempt } from "../overview.action";
import {
  BtnType,
  Button,
  Label,
  MultiSelect,
  Loading,
} from "../../../../primitives";
import { ModalFooter } from "../../../../../application/modal";

import styles from "../themes/styles.module.scss";

import { useModelPopup } from "../../../../../common/hooks";

const AddProjectSolutions = () => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil },
    projectsReducer: {
      projectReducer: { selectedProject },
      overviewReducer: { projectSolutions },
    },
    solutionsReducer: {
      solutionsMainReducer: { solutionsExtended, loadingSolutions },
    },
  } = useSelector((state) => state);
  const [selectedSolutions, setSelectedSolutions] = useState({});
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(false);
  const [elasticQuery, setElasticQuery] = useState("");

  useEffect(() => {
    if (solutionsExtended?.length > 0) {
      const filteredSolutions = solutionsExtended
        .map((el) => ({ ...el, ...el.attributes }))
        .filter((option) => {
          const isAlreadySelected = projectSolutions?.find((item) => {
            return item?.id === option.id;
          });
          return !isAlreadySelected;
        });

      setOptions(filteredSolutions);
    }
  }, [solutionsExtended]);

  const handleOptionSelect = (values) => {
    setError(false);

    setSelectedSolutions(values[0]);
  };

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleRelateSolutions = () => {
    if (!selectedSolutions.id) {
      setError(true);
      return;
    }
    const data = {
      solutions: [
        {
          solution_id: selectedSolutions.id,
        },
      ],
    };
    dispatch(
      addRelatedSolutionAttempt({
        projectId: selectedProject.id,
        data,
        enqueueSnackbar,
      })
    );
    setError(false);
    popup.hide();
  };

  useEffect(() => {
    const data = {
      sort_order: "desc",
      sort_attribute: "created_at",
      search: {
        query: elasticQuery,
      },
    };
    dispatch(solutionsSearchAttempt({ page: 1, data, fromMain: true }));
  }, [debouncedValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(elasticQuery);
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [elasticQuery]);

  return (
    <div>
      <div className={styles.customPadding}>
        <Label>
          {selectedCouncil?.settings?.themes_display_name || "Themes"}
        </Label>
        <div className={styles.selectorWrp}>
          <MultiSelect
            onChange={handleOptionSelect}
            placeholder={"Add solutions (type to search or select from below)"}
            labelField="name"
            valueField="id"
            searchBy="name"
            options={options}
            searchFn={(args) => {
              setElasticQuery(args.state.search);
            }}
            multi={false}
          />
          <div className={styles.loaderWrp}>
            {loadingSolutions && <Loading hideString />}
          </div>
        </div>
        {error && (
          <span className={styles.errorText}>
            At least one solution should be selected!
          </span>
        )}
      </div>
      <ModalFooter>
        <Button
          className="mr-3"
          btn={BtnType.FRAME_LESS}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button onClick={handleRelateSolutions} btn={BtnType.REGULAR}>
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default memo(AddProjectSolutions);

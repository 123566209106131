import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import styles from './styles.module.scss';
import { Button, Pagination } from "modules/primitives";
import ListDownload from "modules/reporting/components/genericReportsList/listDownload";
import FordReplicationsTableHeader
  from "modules/reporting/fordReports/replicationsCard/table/fordReplicationsTableHeader";
import FordReplicationsTableRow from "modules/reporting/fordReports/replicationsCard/table/fordReplicationsTableRow";

const FordReplicationsTable = ({ tableData, handlePageChange, expandedTableKey, handleDownload }) => {
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [downloadIsActive, setDownloadIsActive] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setDescription('')
  }

  const showMoreDescription = (descr) => {
    setDescription(descr);
    setOpen(true);
  }

  const handleLocalDownload = (type) => {
    if (type !== 'pdf') {
      setDownloadIsActive(true);
    }

    const payload = {
      dashboard_type: 'replication_dashboard',
      type,
      section: expandedTableKey,
      cb: () => {
        setDownloadIsActive(false);
      },
    };

    handleDownload(payload);
  };

  return (
    <div className={styles.tableData}>
      <FordReplicationsTableHeader highLightColumn={tableData?.highLightColumn} />
      <div>
        {
          !tableData.data?.length ? (
            <div className="d-flex align-items-center mt-5">No data.</div>
          ) : null
        }
        {
          tableData.data.map(row => (
            <FordReplicationsTableRow
              key={row.id}
              row={row}
              showMoreDescription={showMoreDescription}
              highLightColumn={tableData?.highLightColumn}
            />
          ))
        }
      </div>
      <div className="align-items-center d-flex justify-content-between p-3">
        <ListDownload
          downloadIsActive={downloadIsActive}
          handleDownloadClick={handleLocalDownload}
        />
        <Pagination {...tableData.pagination} onChange={handlePageChange} />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <div className="d-flex flex-wrap">
            {description}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(FordReplicationsTable);

import { combineEpics, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";

import {
  APPLICANTS_LIST_GET_ATTEMPT,
  applicantsListGetSuccess,
  APPLICATION_DELETE_ATTEMPT,
  APPLICATION_GET_ATTEMPT,
  APPLICATION_UPDATE_ATTEMPT,
  applicationGetSuccess,
  applicationUpdateSuccess,
  deleteApplicationSuccess,
} from "./adminApplicants.action";

import {
  errorHandler,
  httpDelete,
  httpGet,
  httpPatch,
  queryString,
} from "../../../common/httpCall";

export const epicGetApplication = (action$, state$) => action$.pipe(
  ofType(APPLICATION_GET_ATTEMPT),
  switchMap(({ payload: { id, enqueueSnackbar } }) => httpGet({
    call: `applicants/submissions/${id}`,
  }).pipe(
    map((result) => applicationGetSuccess(result)),
    catchError((error) => errorHandler(error, enqueueSnackbar, [])),
  )),
);

export const epicGetApplicantsList = (action$, state$) => action$.pipe(
  ofType(APPLICANTS_LIST_GET_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar, query } }) => httpGet({
    call: `applicants/submissions?${queryString({ ...query })}`,
  }).pipe(
    map((result) => applicantsListGetSuccess(result)),
    catchError((error) => errorHandler(error, enqueueSnackbar, [])),
  )),
);

export const epicDeleteApplication = (action$, state$) => action$.pipe(
  ofType(APPLICATION_DELETE_ATTEMPT),
  switchMap(({ payload: { id, enqueueSnackbar } }) => httpDelete({
    call: `applicants/submissions/${id}`,
  }).pipe(
    map((result) => {
      if (enqueueSnackbar) {
        enqueueSnackbar("Application deleted with success", {
          variant: "success",
        });
      }
      return deleteApplicationSuccess({ id });
    }),
    catchError((error) => errorHandler(error, enqueueSnackbar, [])),
  )),
);

export const epicEditApplication = (action$, state$) => action$.pipe(
  ofType(APPLICATION_UPDATE_ATTEMPT),
  switchMap(({ payload: { id, data, enqueueSnackbar } }) => httpPatch({
    call: `applicants/submissions/${id}`,
    data: {
      submission: data,
    },
  }).pipe(
    map((result) => {
      if (enqueueSnackbar) {
        enqueueSnackbar("Application updated.", {
          variant: "success",
        });
      }

      return applicationUpdateSuccess(result);
    }),
    catchError((error) => errorHandler(error, enqueueSnackbar, [])),
  )),
);

const adminApplicantsEpic = combineEpics(
  epicGetApplication,
  epicGetApplicantsList,
  epicDeleteApplication,
  epicEditApplication,
);

export default adminApplicantsEpic;

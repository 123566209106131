import React, { useRef } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { withSnackbar } from "notistack";

import { useHistory } from "react-router-dom";
import { setUser } from "modules/auth/auth.action";
import { councilGet } from "modules/council/council.action";
import { BtnType, Button, Label, TextBox } from "../../primitives";
import styles from "./passwordSettings.module.scss";
import {
  initPasswordSettingsModel,
  passwordSettingsValidation,
} from "./passwordSettings.constants";
import { passwordEditAttempt } from "../settings.action";

const PasswordSettings = ({ enqueueSnackbar }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const {
    authReducer: { session },
    settingsReducer: { generalSettings },
  } = useSelector((state) => state);
  const history = useHistory();

  const handleOnSubmit = (values) => {
    dispatch(passwordEditAttempt({ values, enqueueSnackbar }));
    if (!session.password_updated_at) {
      history.push("/");
      dispatch(
        setUser({ ...session, ...generalSettings, password_updated_at: true })
      );
      dispatch(
        councilGet({
          id: session.default_council.id,
          enqueueSnackbar,
          integrated_tokens: session?.integrated_tokens,
        })
      );
    }
  };

  return (
    <div className={styles.pageWrapper}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initPasswordSettingsModel}
        validationSchema={passwordSettingsValidation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleOnSubmit(values);
          resetForm();
        }}
      >
        {({ handleSubmit, handleReset, ...formikprops }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.passwordSettingsFormWrp}>
              <Label>New Password</Label>
              <TextBox
                type="password"
                name="password"
                placeholder="Must be at least 8 characters"
                className={styles.textBox}
                formProps={formikprops}
              />
              <Label>Confirm New Password</Label>
              <TextBox
                type="password"
                name="password_confirmation"
                placeholder="Must match new password"
                className={styles.textBox}
                formProps={formikprops}
              />
              <div className={styles.submitWrp}>
                <Button btn={BtnType.FRAME_LESS} onClick={handleReset}>
                  Cancel
                </Button>
                <Button type="submit" btn={BtnType.REGULAR}>
                  {session?.password_updated_at ? "Save" : "Update"}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(withSnackbar(PasswordSettings));

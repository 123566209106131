import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import classNames from "classnames";
import { useSnackbar } from "notistack";
import { checkViewerRole } from "common/checkers/viewerChecker";
import Tooltip from "@mui/material/Tooltip";
import { httpGet, httpPost } from "common/httpCall";
import {
  UserInline,
  UserInlineTypes,
  MoreOptions,
  Button,
  BtnType,
} from "../../../../primitives";
import styles from "./relationshipOwner.module.scss";
import { moreOptions } from "./relationshipOwner.constant";
import { deleteRelationShip } from "../../company.action";
import { useModelPopup } from "../../../../../common/hooks";
// import EditRelationship from "../../../../../common/components/editRelationship";
import { Icon, lgSize } from "../../../../../common/icon";
import { appModelSettingSet } from "application/app.action";
import NewDiscussion from "../../../../../common/components/newDiscussion/newDiscussion.component";
import NewContactMSFTChoice from "../newContactMSFTChoice/index";
import isKyndrylViewOnly from "../../../../../common/isKyndrylViewOnly";

const RelationshipOwnerComponent = ({
  handleUpdateRelationshipOwners,
  relationships,
  companyId,
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  const {
    authReducer: { session },
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (location?.state?.showAddRelationShip) {
      handleAddClick();
    }
  }, [location]);

  useEffect(() => {
    httpGet({
      call: `companies/${selectedCompany.id}/external_team`,
    })
      .pipe()
      .subscribe((res) => res);
  }, []);

  useEffect(() => {
    if (
      checkViewerRole() &&
      !isKyndrylViewOnly(selectedCouncil?.name, session?.council_role)
    ) {
      setBlockFunctionality(true);
    }
  }, []);

  const removeQueryParamFromUrl = () => {
    history.push({
      pathname: `/companies/${selectedCompany.slug || selectedCompany.id}`,
      state: {
        ...history.location?.state,
        showAddRelationShip: null,
      },
    });
  };

  const handleAddClick = () => {
    popup.show({
      title: "Add Relationship Owner",
      component: (
        <NewContactMSFTChoice
          cb={removeQueryParamFromUrl}
          contactType="relationship_owner"
        />
      ),
    });
  };

  // const handleEditClick = (relationship) => {
  //   popup.show({
  //     title: "Edit Relationship Owner",
  //     component: (
  //       <EditRelationship
  //         isCompanyUse
  //         relationship={relationship}
  //         mode="edit"
  //       />
  //     ),
  //   });
  // };

  const handleMoreOptionClick = (options, relationship) => {
    // if (options === 5) {
    //   handleEditClick(relationship);
    // }
    if (options === 6) {
      dispatch(
        deleteRelationShip({
          company_id: companyId,
          company_relationship_owner_id:
            relationship.company_relationship_owner_id,
          enqueueSnackbar,
        })
      );
    }
  };

  const startNewDiscussion = (user) => {
    const customSettings = {
      title: "Start a New Private Discussion",
      show: true,
      height: "300",
      width: "540",
      component: <NewDiscussion type="Council" isPrivate selectedUser={user} />,
    };
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  const handleShow = (details) => {
    if (details.showNewModal) {
      startNewDiscussion(details);
    }
  };

  const handleInvite = (member) => {
    httpPost({
      call: `companies/${selectedCompany.id}/relationshipowners/invite`,
      data: { ...member, council_id: selectedCouncil.id },
    })
      .pipe()
      .subscribe((res) => handleUpdateRelationshipOwners());
  };

  return (
    <div className="position-relative">
      <div className={styles.addButtonWrapper}>
        {!blockFunctionality ? (
          <Button
            btn={BtnType.FRAME_LESS}
            icon="icn-add"
            onClick={() => handleAddClick()}
          >
            Add
          </Button>
        ) : (
          <Tooltip
            title="You have View-Only access. To Add a Relationship Owner, please ask your Admin to upgrade your account to Standard access"
            placement="top"
          >
            <div>
              <Button btn={BtnType.DISABLED} icon="icn-add">
                Add
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
      {!relationships[0] && (
        <div className={styles.wrapper}>No relationship found</div>
      )}
      {relationships[0] && (
        <div className={styles.wrapper}>
          {relationships.map((el) => (
            <div
              className={styles.itemContainer}
              key={el.company_relationship_owner_id}
            >
              <div className={styles.leftContainer}>
                <div className={styles.userWrp}>
                  <UserInline
                    className={styles.userIcon}
                    classObj={{ name: styles.userName }}
                    userId={el.user_id}
                    type={UserInlineTypes.WITH_NAME}
                    showMine={false}
                    first={el.user?.full_name || el.external_team?.full_name}
                    avatar={el.user?.avatar || el.external_team?.avatar}
                    local
                  />
                </div>
                <div className={styles.userDetails}>
                  <div className={classNames("caption small", styles.position)}>
                    {el.user?.title || el.external_team?.title}
                  </div>
                  <div className={classNames("caption small", styles.position)}>
                    {el.user?.company || el.external_team?.company}
                  </div>
                  {el?.council_products && 
                    el.council_products.map(product => 
                      <div className={classNames("caption small", styles.position)}>
                      Product Owner: {product?.name}
                  </div>)
                    
                  }
                  {selectedCouncil?.name === "Kyndryl" &&
                    el.approved_board_member && (
                      <span className={styles.approvedMember}>
                        Approved Board Member &#10003;
                      </span>
                    )}
                </div>
              </div>
              <div>
                {el?.external_team && (
                  <div className={styles.externalContainer}>
                    <div className={styles.externalText}>External</div>
                    <div
                      className={styles.inviteBtn}
                      onClick={() => handleInvite(el?.external_team)}
                    >
                      {" "}
                      INVITE{" "}
                    </div>
                  </div>
                )}
              </div>
              {el?.user_id ? (
                <div className={styles.rightContainer}>
                  <div className={styles.iconContainer}>
                    <Icon
                      {...lgSize}
                      icon="icn-table-message"
                      className={styles.messageIcon}
                      onClick={() => {
                        handleShow({
                          full_name: el.user.full_name,
                          id: el.user_id,
                          isPrivate: true,
                          showNewModal: !el.user.discussion,
                          prop: "privateDiscussions",
                        });
                      }}
                    />
                  </div>
                  <div className={styles.colMore}>
                    {!blockFunctionality && (
                      <MoreOptions
                        options={moreOptions}
                        className={styles.moreOptWrp}
                        onClick={(val) => handleMoreOptionClick(val, el)}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.rightContainer}>
                  <div className={styles.iconContainer}>
                    <a
                      onClick={() =>
                        window.open(
                          `mailto:${el?.external_team?.email}`,
                          "_blank"
                        )
                      }
                    >
                      <Icon
                        {...lgSize}
                        icon="icn-table-message"
                        className={styles.messageIcon}
                      />
                    </a>
                  </div>
                  <div className={styles.colMore}>
                    {!blockFunctionality && (
                      <MoreOptions
                        options={moreOptions}
                        className={styles.moreOptWrp}
                        onClick={(val) => handleMoreOptionClick(val, el)}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(RelationshipOwnerComponent);

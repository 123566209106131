import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  AXIS_DATA,
  CHART_COLORS,
  EMPLOYEES_ENUM_AS_NUMBER,
  PROP_BY_COLOR,
  PROPS_BY_OPTIONS_ENUM,
  RELATIONSHIP_TAGS_COLORS,
} from "../../charts-constants";
import {
  rndInt,
  scaleBetween,
  lightenDarkenColor,
} from "../../../../../../../common/helper";
import { makeFlattenTags } from "../utils";
import { externalTooltipHandler } from "../customTooltip";

const PROPS_AS_LABEL = {
  totalFunding: "Total Funding",
  numberOfEmployees: "Number of Employees",
  council_company_score: "Score",
  count: "Number of Companies",
  yearsInOperation: "Years in Operation",
  last_funding_amount_usd: "Last Funding",
};

const PROPS_FOR_SIZE = {
  council_company_score: "council_company_score",
  yearsInOperation: "years_in_operation",
  numberOfEmployees: "num_employees_enum",
  totalFunding: "total_fundings_sum",
  last_funding_amount_usd: "last_funding_amount_usd",
};

const EMPLOYEES_NUMBER_AS_RANGE = {
  0: "-",
  1: "1-10",
  11: "11-50",
  51: "51-100",
  101: "101-250",
  251: "251-500",
  501: "501-1000",
  1001: "1001-5000",
  5001: "5001-10000",
  10001: "10001+",
};

export const filterCompaniesBySelectedTechIds = (
  companies,
  selectedChartOptions,
  ids,
) => companies
  .filter((company) => {
    const items = company[PROP_BY_COLOR[selectedChartOptions.color].top];

    if (!items) return true;

    return items.some((item) => ids.includes(item[PROP_BY_COLOR[selectedChartOptions.color].bottom]));
  })
  .map((company) => {
    const items = company[PROP_BY_COLOR[selectedChartOptions.color].top];

    return {
      ...company,
      [PROP_BY_COLOR[selectedChartOptions.color].top]: items.filter((item) => ids.includes(item[PROP_BY_COLOR[selectedChartOptions.color].bottom])),
    };
  });

const makeLabelString = (options, data) => {
  const newOptions = options.toggleView === "aggregate"
    ? {
      count: "count",
      ...options,
    }
    : options;

  return Object.keys(newOptions).reduce((acc, key) => {
    if (acc.includes(PROPS_AS_LABEL[newOptions[key]])) return acc;

    if (key === "count") {
      return `${acc}${PROPS_AS_LABEL[newOptions[key]]}: ${data[key]}//`;
    }

    if (
      (key === "x" || key === "y")
      && newOptions[key] !== "numberOfCompanies"
    ) {
      if (
        newOptions[key] === "totalFunding"
        || newOptions[key] === "last_funding_amount_usd"
      ) {
        return `${acc}${PROPS_AS_LABEL[newOptions[key]]}: $${data[
          key
        ].toLocaleString("en-US")}//`;
      }

      if (newOptions[key] === "numberOfEmployees") {
        return `${acc}${PROPS_AS_LABEL[newOptions[key]]}: ${
          newOptions.toggleView === "aggregate" ? data[key] : data.employeeRange
        }//`;
      }

      // do not show any hover for this prop
      if (
        newOptions[key] === "yearsInOperation"
        && newOptions.toggleView === "aggregate"
      ) return acc;

      return `${acc}${PROPS_AS_LABEL[newOptions[key]]}: ${data[key]}//`;
    }

    if (
      key === "size"
      && newOptions[key] !== "numberOfCompanies"
      && PROPS_AS_LABEL[newOptions.size] !== PROPS_AS_LABEL[newOptions.x]
      && PROPS_AS_LABEL[newOptions.size] !== PROPS_AS_LABEL[newOptions.y]
    ) {
      if (newOptions[key] === "numberOfEmployees") {
        return `${acc}${PROPS_AS_LABEL[newOptions[key]]}: ${
          data.employeeRange
        }//`;
      }

      if (
        (newOptions[key] === "last_funding_amount_usd"
          || newOptions[key] === "totalFunding")
        && data.totalSize
      ) {
        return `${acc}${
          PROPS_AS_LABEL[newOptions[key]]
        }: $${data.totalSize.toLocaleString("en-US")}//`;
      }

      return `${acc}${PROPS_AS_LABEL[newOptions[key]]}: ${data.totalSize}//`;
    }

    return acc;
  }, "");
};

const makeBubbleOptions = (options, companies) => {
  const xAxis = AXIS_DATA.find((option) => option.type === options.x);
  const yAxis = AXIS_DATA.find((option) => option.type === options.y);

  return {
    animation: companies.length < 500,
    scales: {
      y: {
        title: {
          display: true,
          text: yAxis.inChart,
          font: {
            weight: "bold",
            size: 16,
          },
          padding: 10,
          color: "#2d83ae",
        },
        beginAtZero: true,
      },
      x: {
        title: {
          display: true,
          text: xAxis.inChart,
          font: {
            weight: "bold",
            size: 16,
          },
          padding: 20,
          color: "#2d83ae",
        },
        beginAtZero: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: "#000000",
        display: "auto",
        clamp: true,
        align: "end",
        anchor: "end",
        formatter: (value, context) => value.name,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || "";
            const additionalString = makeLabelString(
              options,
              context.dataset.data[0],
            );

            if (label) {
              label += `//${additionalString}`;
            }

            return label;
          },
        },
        enabled: false,
        position: "nearest",
        external: externalTooltipHandler,
      },
    },
  };
};

const makeDataSetAxe = (company, prop) => {
  if (prop === "num_employees_enum") {
    const employeeRange = EMPLOYEES_ENUM_AS_NUMBER[company[prop]];
    return employeeRange ? rndInt(employeeRange.min, employeeRange.max) : 0;
  }

  if (prop === "council_company_score" || prop === "last_funding_amount_usd") {
    return company[prop] && company[prop] !== 0 ? company[prop] : 1;
  }

  return company[prop] || 1;
};

const makeBubbleDetailedData = (
  companies,
  selectedChartOptions,
  filterBy = [],
) => {
  const filteredTags = filterBy.filter((t) => t.selected);
  const filteredCompaniesByTags = filteredTags?.length
    ? companies.filter((company) => {
      if (selectedChartOptions.color === "relationShipStatus") {
        return filteredTags.some(
          (tag) => tag.customId === company.council_relationship_stage_id,
        );
      }

      const items = company[PROP_BY_COLOR[selectedChartOptions.color].top];

      return (
        items.filter((item) => filteredTags.some(
          (tag) => tag.id
                === item[PROP_BY_COLOR[selectedChartOptions.color].bottom],
        )).length > 0
      );
    })
    : companies;
  const mappedVal = filteredCompaniesByTags
    .map((o) => {
      if (PROPS_FOR_SIZE[selectedChartOptions.size] === "num_employees_enum") {
        return makeDataSetAxe(o, "num_employees_enum");
      }

      return o[PROPS_FOR_SIZE[selectedChartOptions.size]];
    })
    .filter((val) => val);
  const maxRange = Math.max.apply(Math, mappedVal) || 10;

  return {
    datasets: filteredCompaniesByTags.map((company, idx) => {
      let employeeRange;
      const generatedColor = CHART_COLORS[idx % CHART_COLORS.length];
      const radius = makeDataSetAxe(
        company,
        PROPS_FOR_SIZE[selectedChartOptions.size],
      );
      const r = PROPS_FOR_SIZE[selectedChartOptions.size] === "council_company_score"
        ? radius
        : scaleBetween(radius, 5, 50, 1, maxRange);

      if (
        PROPS_FOR_SIZE[selectedChartOptions.x] === "num_employees_enum"
        || PROPS_FOR_SIZE[selectedChartOptions.y] === "num_employees_enum"
        || PROPS_FOR_SIZE[selectedChartOptions.size] === "num_employees_enum"
      ) {
        const employeeRangeValue = EMPLOYEES_ENUM_AS_NUMBER[company.num_employees_enum];
        employeeRange = employeeRangeValue
          ? EMPLOYEES_NUMBER_AS_RANGE[employeeRangeValue.min]
          : "-";
      }

      return {
        label: [company.name],
        backgroundColor: generatedColor,
        borderColor: lightenDarkenColor(generatedColor, -30),
        data: [
          {
            name: company.name,
            x: makeDataSetAxe(
              company,
              PROPS_BY_OPTIONS_ENUM[selectedChartOptions.x],
            ),
            y: makeDataSetAxe(
              company,
              PROPS_BY_OPTIONS_ENUM[selectedChartOptions.y],
            ),
            r,
            id: company.id,
            totalSize: radius,
            employeeRange,
          },
        ],
      };
    }),
  };
};

export const makeFlattenRelationShipTags = (companies, relationShipTags) => relationShipTags.reduce((acc, tag) => {
  const filteredCompanies = companies.filter(
    (c) => c.council_relationship_stage_id === tag.customId,
  );

  return [
    ...acc,
    {
      ...tag,
      id: tag.slug,
      companiesIdThatBelongsTo: filteredCompanies.map((c) => c.id),
      count: filteredCompanies.length,
      color: tag?.colors?.background || RELATIONSHIP_TAGS_COLORS[tag.slug],
    },
  ];
}, []);

const makeBubbleAggregatedData = (
  companies,
  selectedChartOptions,
  filterBy = [],
  relationTags,
) => {
  let aggregatedData;

  const flattenTags = selectedChartOptions.color === "relationShipStatus"
    ? makeFlattenRelationShipTags(companies, relationTags)
    : makeFlattenTags(companies, selectedChartOptions);
  const filteredTags = filterBy ? filterBy.filter((t) => t.selected) : [];

  if (selectedChartOptions.color === "relationShipStatus") {
    aggregatedData = relationTags.reduce((acc, tag) => {
      const companiesDataByTag = companies
        .filter((c) => c.council_relationship_stage_id === tag.customId)
        .reduce(
          (acc1, company) => {
            const employeeRange = EMPLOYEES_ENUM_AS_NUMBER[company.num_employees_enum];
            const makeRadius = selectedChartOptions.size === "numberOfEmployees"
              ? employeeRange
                ? rndInt(employeeRange.min, employeeRange.max)
                : 5
              : company[PROPS_FOR_SIZE[selectedChartOptions.size]] || 5;
            const makeAxe = (toCount = 1, axe) => {
              if (selectedChartOptions[axe] === "score") return toCount;
              return (
                toCount
                + makeDataSetAxe(
                  company,
                  PROPS_BY_OPTIONS_ENUM[selectedChartOptions[axe]],
                )
              );
            };

            return {
              r: acc1.r + makeRadius,
              totalSize: Number((acc1.totalSize + makeRadius).toFixed()),
              x:
                selectedChartOptions.x === "yearsInOperation"
                  ? acc1.x > company.years_in_operation
                    ? acc1.x
                    : company.years_in_operation
                  : makeAxe(acc1.x, "x"),
              y:
                selectedChartOptions.y === "yearsInOperation"
                  ? acc1.y > company.years_in_operation
                    ? acc1.y
                    : company.years_in_operation
                  : makeAxe(acc1.y, "y"),
            };
          },
          {
            r: 0,
            totalSize: 0,
            x: 0,
            y: 0,
          },
        );

      return [
        ...acc,
        {
          ...companiesDataByTag,
          ...tag,
          id: tag.slug,
          color: tag?.colors?.background || RELATIONSHIP_TAGS_COLORS[tag.slug],
        },
      ];
    }, []);
  } else {
    const filteredCompaniesByTags = filteredTags?.length
      ? companies.filter((company) => {
        const items = company[PROP_BY_COLOR[selectedChartOptions.color].top];

        return (
          items.filter((item) => filteredTags.some(
            (tag) => tag.id
                  === item[PROP_BY_COLOR[selectedChartOptions.color].bottom],
          )).length > 0
        );
      })
      : companies;

    aggregatedData = filteredCompaniesByTags.reduce((acc, company) => {
      let newAcc = acc;
      const items = company[PROP_BY_COLOR[selectedChartOptions.color].top];

      if (!items) return newAcc;

      const employeeRange = EMPLOYEES_ENUM_AS_NUMBER[company.num_employees_enum];
      const makeRadius = selectedChartOptions.size === "numberOfEmployees"
        ? employeeRange
          ? rndInt(employeeRange.min, employeeRange.max)
          : 5
        : company[PROPS_FOR_SIZE[selectedChartOptions.size]] || 5;

      items.forEach((item) => {
        const itemId = item[PROP_BY_COLOR[selectedChartOptions.color].bottom];
        const itemExist = newAcc.some((i) => itemId === i.id);
        const makeAxe = (toCount = 1, axe) => {
          if (selectedChartOptions[axe] === "score") return toCount;
          return (
            toCount
            + makeDataSetAxe(
              company,
              PROPS_BY_OPTIONS_ENUM[selectedChartOptions[axe]],
            )
          );
        };

        if (itemExist) {
          newAcc = newAcc.map((i) => {
            if (i.id === itemId) {
              return {
                ...i,
                r: i.r + makeRadius,
                x:
                  selectedChartOptions.x === "yearsInOperation"
                    ? i.x > company.years_in_operation
                      ? i.x
                      : company.years_in_operation
                    : makeAxe(i.x, "x"),
                y:
                  selectedChartOptions.y === "yearsInOperation"
                    ? i.y > company.years_in_operation
                      ? i.y
                      : company.years_in_operation
                    : makeAxe(i.y, "y"),
                totalSize: Number((i.totalSize + makeRadius).toFixed()),
                employeeRange: makeRadius,
              };
            }

            return i;
          });
        } else {
          newAcc = [
            ...newAcc,
            {
              name: item.name,
              id: itemId,
              r: makeRadius,
              x: makeDataSetAxe(
                company,
                PROPS_BY_OPTIONS_ENUM[selectedChartOptions.x],
              ),
              y: makeDataSetAxe(
                company,
                PROPS_BY_OPTIONS_ENUM[selectedChartOptions.y],
              ),
              totalSize: makeRadius || 0,
              employeeRange: makeRadius,
            },
          ];
        }
      });

      return newAcc;
    }, []);

    if (filteredTags?.length) {
      aggregatedData = aggregatedData.filter((tag) => filteredTags.some((t) => t.id === tag.id));
    }
  }

  const mappedValue = selectedChartOptions.size === "numberOfCompanies"
    ? flattenTags.map((tag) => tag.count)
    : aggregatedData.map((o) => o.r);

  const maxRange = Math.max.apply(Math, mappedValue);

  return {
    datasets: aggregatedData.map((item, idx) => {
      const isInFilter = filteredTags.length
        ? filteredTags.some((tag) => item.id === tag.id)
        : true;
      // const generatedColor = selectedChartOptions.color === 'relationShipStatus'
      //   ? item.color
      //   : nameToColour(`${item.name}-0`);
      const generatedColor = selectedChartOptions.color === "relationShipStatus"
        ? item.color
        : CHART_COLORS[idx % CHART_COLORS.length];
      // const colorIsToLighter = selectedChartOptions.color === 'relationShipStatus'
      //   ? false
      //   : colorIsTooLight(generatedColor);
      // const adjustedColor = colorIsToLighter
      //   ? lightenDarkenColor(generatedColor, -40)
      //   : generatedColor;
      const color = isInFilter
        ? generatedColor // adjustedColor
        : "#999999";
      const getCompaniesCount = flattenTags.find((tag) => tag.id === item.id);
      const r = selectedChartOptions.size === "numberOfCompanies"
        ? getCompaniesCount.count
        : item.r;

      return {
        label: [item.name],
        backgroundColor: color,
        borderColor: lightenDarkenColor(color, -30),
        data: [
          {
            ...item,
            y:
              selectedChartOptions.y === "numberOfCompanies"
                ? getCompaniesCount.count
                : item.y,
            x:
              selectedChartOptions.x === "numberOfCompanies"
                ? getCompaniesCount.count
                : item.x,
            r: scaleBetween(r, 5, 50, 1, maxRange),
            count: getCompaniesCount.count,
          },
        ],
      };
    }),
  };
};

export const makeBubbleChartConfig = (
  companies,
  selectedChartOptions,
  filterBy,
  relationShipStatuses,
) => {
  const filteredCompanies = selectedChartOptions.color === "relationShipStatus"
    ? companies.filter((c) => c.council_relationship_stage_id !== null)
    : companies;

  const data = selectedChartOptions.toggleView === "aggregate"
    ? makeBubbleAggregatedData(
      filteredCompanies,
      selectedChartOptions,
      filterBy,
      relationShipStatuses,
    )
    : makeBubbleDetailedData(
      filteredCompanies,
      selectedChartOptions,
      filterBy,
    );

  return {
    data,
    options: makeBubbleOptions(selectedChartOptions, filteredCompanies),
    plugins: [ChartDataLabels],
  };
};

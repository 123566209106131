import React from "react";
import Select from "react-dropdown-select";
import {
  Label,
  TextBox,
  DropSelect,
  CheckBox,
  CheckType,
} from "../../../../../primitives";
import { initModel } from "../constant";
import styles from "../styles.module.scss";
import { gskDiscussionTopics } from "../../activity.constant";
import RichEditor from "../../../../../../common/components/richEditor";

const NewNote = (props) => {
  const {
    selectedModel,
    orgType,
    activityType,
    data,
    formikprops,
    values,
    handleChange,
    setFieldValue,
    selectedCategories,
    handlePatientOrgDiscussionSelect,
    patientOrgDiscussionSubTopics,
    selectedSubCategories,
    handleSubcategoryTopicSelect,
    company,
    selectedProj,
    setSelectedProj,
    handleClear,
    handleVisibilityChange,
    optionVisibility,
    visibility,
    setSelectedTeamOption,
    selectedTeamOption,
    userTeams,
  } = props;

  return (
    <>
      {orgType === "patient" && selectedCategories ? (
        <div>
          <Label>Discussion Topic</Label>
          <Select
            multi
            closeOnSelect
            values={[...selectedCategories]}
            className={styles.customPatientOrgMultiselect}
            onChange={(vals) => handlePatientOrgDiscussionSelect(vals, setFieldValue)}
            options={gskDiscussionTopics}
            labelField="name"
            valueField="name"
            placeholder="Select Discussion Topic Category"
          />
        </div>
      ) : null}
      {orgType === "patient"
      && patientOrgDiscussionSubTopics?.length
      && selectedSubCategories ? (
        <div>
          <Select
            multi
            closeOnSelect
            values={selectedSubCategories}
            name="discussion_topic"
            className={styles.customPatientOrgMultiselect}
            onChange={handleSubcategoryTopicSelect}
            options={patientOrgDiscussionSubTopics}
            labelField="name"
            valueField="name"
            placeholder="Select Discussion Topic"
            formProps={{ ...formikprops, values }}
          />
        </div>
        ) : null}
      {orgType === "patient"
      && selectedCategories
      && selectedCategories?.some((c) => c.name === "Other") ? (
        <TextBox
          type="text"
          name="discussion_topic_other"
          placeholder="Enter Discussion Topic"
          className={styles.textBox}
          formProps={{ ...formikprops, values }}
          onChange={handleChange}
        />
        ) : null}
      {selectedModel?.note && orgType !== "patient" && (
        <div className="mb-4">
          <Label>{selectedModel?.note.label}</Label>
          <RichEditor
            updateValue={(val) => setFieldValue("note", val)}
            initialValue={values?.note || ""}
            placeholder={selectedModel?.note.placeholder}
          />
        </div>
      )}
      {orgType === "patient"
        && (activityType === "Other" || data?.type === "Note") && (
          <div className="mb-4">
            <Label>Summary</Label>
            <RichEditor
              updateValue={(val) => setFieldValue("note", val)}
              initialValue={values?.note || ""}
              placeholder="Summary"
            />
          </div>
      )}
      {orgType === "patient" && (
        <div>
          <Label>Initial Engagement</Label>
          <CheckBox
            checkType={CheckType.BLUE}
            isChecked={data ? data.initial_introductory_meeting : false}
            onChange={(val) => {
              setFieldValue("initial_introductory_meeting", val);
            }}
          />
        </div>
      )}
      {orgType === "patient" && (
        <>
          <Label>
            {activityType === "Other"
              ? "Role of Patient Organization Representative(s)"
              : "Role of Key Patient Organization Attendees"}
          </Label>
          <TextBox
            type="text"
            name="roles_of_attendees"
            placeholder={
              activityType === "Other"
                ? "Role of Patient Organization Representative(s)"
                : "Role of Key Patient Organization Attendees"
            }
            className={styles.textBox}
            formProps={{ ...formikprops, values }}
            onChange={handleChange}
          />
        </>
      )}
      {company && company?.projects?.length > 0 && company?.projects[0] ? (
        <>
          <Label>Related Projects (optional)</Label>
          <DropSelect
            SelecedValues={selectedProj}
            // onChange={handleOptionSelect}
            onChange={(proj) => {
              setSelectedProj(proj.val);
              // handleVisibilityChange();
            }}
            placeholder="Select a project or type to search"
            labelField="text"
            valueField="value"
            searchBy="text"
            options={company?.projects.map((el) => ({
              text: el.name,
              value: el.project_id,
            }))}
          />
          <span
            className={styles.clearButton}
            onClick={() => {
              handleClear();
            }}
          >
            <span> clear</span>
          </span>
        </>
      ) : null}
      {orgType !== "patient" ? (
        <>
          <Label>Visiblity</Label>
          <DropSelect
            SelecedValues={data ? data.visibility : initModel.visibility}
            onChange={handleVisibilityChange}
            placeholder="Please select who can see this note"
            name="for_all"
            labelField="text"
            valueField="value"
            searchBy="text"
            options={optionVisibility}
          />
        </>
      ) : null}
      {visibility === "for_teams" && (
        <>
          <Label>Select a team</Label>
          <Select
            name="team_ids"
            onChange={(selected) => {
              setFieldValue(
                "team_ids",
                selected.map(({ value }) => value),
              );
              setSelectedTeamOption(selected);
            }}
            values={selectedTeamOption}
            options={userTeams.map(({ id: value, name: label }) => ({
              label,
              value,
            }))}
            multi
            required
          />
        </>
      )}
    </>
  );
};

export default NewNote;

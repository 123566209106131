import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";

import { httpDelete, httpPost, httpPut } from "common/httpCall";
import { useModelPopup } from "../../../../../common/hooks";
import { NewProjectMember } from "../../../../shared";
import { projectUsersGet } from "../../users/user.action";
import {
  Button,
  BtnType,
  MoreOptions,
  UserInline,
  UserInlineTypes,
  Loading,
} from "../../../../primitives";
import { councilUsersListGet } from "../../../../../common/actions/common.action";
import {
  projectDeleteMember,
  projectGet,
  projectSettingsGet,
  projectSettingsPut,
} from "../../project.action";
import styles from "./members.module.scss";
import { moreOptions } from "./constants";
import ChooseRoleComponent from "./chooseRoleModal";

const MembersComponent = () => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    projectsReducer: {
      projectReducer: {
        settingsLoading,
        projectSettings,
        selectedProject,
        shouldMembersUpdate,
      },
      usersReducer: { projectUsersList },
    },
  } = useSelector((state) => state);

  const handleMoreOptionClick = (val, id, role, externalMember) => {
    if (val === 5) {
      handleEditClick(id, role, externalMember);
    }
    if (val === 6) {
      handleDeleteClick(id, externalMember);
    }
  };

  const handleEditClick = (id, role, externalMember) => {
    popup.show({
      title: "Choose Member Role",
      component: (
        <ChooseRoleComponent
          projectId={selectedProject.id}
          userId={id}
          externalMember={externalMember}
        />
      ),
    });
  };

  const handleDeleteClick = (id, externalMember) => {
    const data = {
      projectId: selectedProject.id,
      userId: id,
      enqueueSnackbar,
      externalMember,
    };
    if (externalMember) {
      httpDelete({
        call: `projects/${selectedProject.id}/external_project_member/${id}`,
      })
        .pipe()
        .subscribe(() => dispatch(projectDeleteMember(data)));
    } else {
      dispatch(projectDeleteMember(data));
    }
  };

  const handleAddUserClick = () => {
    popup.show({
      title: "Add a Member to this project",
      component: (
        <NewProjectMember multiple projectUsersList={projectUsersList} />
      ),
    });
  };

  const handleGetRole = (role) => {
    if (role === "manager") {
      return "MANAGER";
    }
    if (role === "owner") {
      return "OWNER";
    }
    if (role === "member") {
      return "MEMBER";
    }
    return "MEMBER";
  };

  const isOwner = () => {
    const currentRole = selectedProject.project_members.find(
      (item) => item?.user?.id === session.id
    )?.user_role;
    return currentRole === "owner";
  };

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(councilUsersListGet(selectedCouncil.id, enqueueSnackbar));
    }
  }, [selectedCouncil, shouldMembersUpdate]);

  useEffect(() => {
    dispatch(projectGet({ id: selectedProject.id, enqueueSnackbar }));
    dispatch(projectSettingsGet({ id: selectedProject.id, enqueueSnackbar }));
  }, [selectedCouncil, shouldMembersUpdate, projectUsersList]);

  const handleInvite = (member) => {
    const data = {
      ...member,
      project_id: selectedProject.id,
      council_id: selectedCouncil?.id,
    };
    httpPost({
      call: `projects/${selectedProject.id}/members/invite`,
      data,
    })
      .pipe()
      .subscribe(() => {
        dispatch(
          projectUsersGet({
            projectId: selectedProject.id,
            councilId: selectedCouncil.id,
            enqueueSnackbar,
          })
        );
        enqueueSnackbar("User has been added successfully", {
          variant: "success",
        });
      });
  };

  const updateApprovers = () => {
    httpPut({
      call: `projects/${selectedProject.id}/update_approval_users`,
      data: null,
    }).subscribe((res) => {
      if (res.response.id) {
        enqueueSnackbar("Successfully updated Reviewers + Approvers", {
          variant: "success",
        });
      }
    });
  };

  const updateSettings = (type) => {
    dispatch(
      projectSettingsPut({
        data: {
          skip_completed_tasks: type === "skip_completed_tasks",
          update_all_tasks: type === "update_all_tasks",
          id: selectedProject.id,
          enqueueSnackbar,
        },
      })
    );
  };

  useEffect(() => {
    updateSettings('skip_completed_tasks');
  }, []);

  return (
    <div className={styles.membersWrp}>
      <div className={styles.formContainer}>
        <div className="row">
          <div className="col-12">
            <div className={styles.labelesContainer}>
              <p className={styles.label}>Project members</p>
              <p className={styles.label}>Role</p>
            </div>
            {selectedProject.project_members.length > 0 && (
              <div className={styles.membersContainer}>
                {selectedProject.project_members.map((member, index) => (
                  <>
                    <div
                      className={styles.memberRow}
                      key={`${member?.id}_${index + 1}`}
                    >
                      {member?.user ? (
                        <div>
                          <UserInline
                            userId={member?.user?.id}
                            first={member?.user?.first_name}
                            last={member?.user?.last_name}
                            avatar={member?.user?.avatar}
                            local
                            type={UserInlineTypes.WITH_NAME}
                          />
                        </div>
                      ) : (
                        <Tooltip
                          placement="top"
                          title={
                            <div className={styles.externalMemberTooltip}>
                              <div className={styles.tooltipValues}>
                                {member.external_project_member &&
                                  member?.external_project_member?.full_name}
                              </div>
                              <div className={styles.tooltipValues}>
                                {member.external_project_member &&
                                  member?.external_project_member?.title}
                              </div>
                              <div className={styles.tooltipValues}>
                                {member.external_project_member &&
                                  member?.external_project_member?.email}
                              </div>
                              <div className={styles.tooltipValues}>
                                {member.external_project_member &&
                                  member?.external_project_member?.organization}
                              </div>
                            </div>
                          }
                        >
                          <div>
                            <span className={styles.externalMember}>
                              <UserInline
                                userId={member?.external_project_member?.id}
                                first={
                                  member?.external_project_member?.first_name
                                }
                                last={
                                  member?.external_project_member?.last_name
                                }
                                avatar={member?.external_project_member?.avatar}
                                local
                                type={UserInlineTypes.WITH_NAME}
                                fromExternal
                              />
                            </span>
                          </div>
                        </Tooltip>
                      )}

                      {member?.external_project_member && (
                        <>
                          <div className={styles.externalText}>External</div>
                          <div
                            className={styles.inviteBtn}
                            onClick={() =>
                              handleInvite(member.external_project_member)
                            }
                          >
                            {" "}
                            INVITE
                          </div>
                        </>
                      )}

                      <div className={styles.colMore}>
                        <div className={styles.role}>
                          {handleGetRole(member.user_role)}
                        </div>
                        <MoreOptions
                          options={
                            isOwner
                              ? moreOptions
                              : moreOptions.filter(
                                  (option) => option.text !== "Change Role"
                                )
                          }
                          className={styles.moreOptWrp}
                          onClick={(val) =>
                            handleMoreOptionClick(
                              val,
                              member?.user?.id ||
                                member?.external_project_member?.id,
                              member?.user_role,
                              Boolean(member?.external_project_member)
                            )
                          }
                        />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            )}
            <div className={styles.addMemberButtonContainer}>
              <Button
                icon="icn-add"
                onClick={() => handleAddUserClick({})}
                btn={BtnType.FRAME_LESS}
                className={styles.addCompanyButton}
              >
                Add A Member
              </Button>
            </div>
            {selectedCouncil?.name === "Ford" && selectedProject && (
              <div>
                <div className="d-flex align-items-center mt-4">
                  <span>Update Reviewers + Approvers </span>
                  <Button
                    onClick={updateApprovers}
                    btn={BtnType.REGULAR}
                    className="ml-5"
                  >
                    UPDATE
                  </Button>
                </div>
                {/* <br /> */}
                {settingsLoading ? (
                  <Loading />
                ) : (
                  <div className="d-flex flex-column mb-3">
                    <div className="d-flex align-items-center">
                      <input
                        id="yes"
                        value={true}
                        checked={projectSettings?.skip_completed_tasks || false}
                        name="type"
                        type="radio"
                        onChange={() => {
                          updateSettings("skip_completed_tasks");
                        }}
                      />
                      <label className="pl-2" htmlFor="yes">
                        Skip completed Tasks
                      </label>
                    </div>

                    <div className="d-flex align-items-center">
                      <input
                        id="no"
                        value={false}
                        name="type"
                        checked={projectSettings?.update_all_tasks || false}
                        type="radio"
                        onChange={() => {
                          updateSettings("update_all_tasks");
                        }}
                      />
                      <label className="pl-2" htmlFor="no">
                        Update All Tasks
                      </label>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MembersComponent);

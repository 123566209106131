import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { httpGet, errorHandler, httpPost, httpDelete } from "../../../../common/httpCall";
import {
  THEME_DOCUMENT_GET_ATTEMPT,
  themesDocumentGetSuccess,
  THEME_DOCUMENT_POST_ATTEMPT,
  themesDocumentPostSuccess,
  THEME_DOCUMENT_DELETE_ATTEMPT,
  themesDocumentDeleteSuccess
} from "./documentsTab.action";

export const epicAddThemeDocument = (action$, state$) =>
  action$.pipe(
    ofType(THEME_DOCUMENT_POST_ATTEMPT),
    switchMap(({ payload: { themeId, data, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/${themeId}/documents`,
        data,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Document added", {
              variant: "success",
            });
          }
          return themesDocumentPostSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetThemeDocuments = (action$, state$) =>
  action$.pipe(
    ofType(THEME_DOCUMENT_GET_ATTEMPT),
    switchMap(({ payload: { themeId, enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v1",
        call: `themes/${themeId}/documents`,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Document edited", {
              variant: "success",
            });
          }
          return themesDocumentGetSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

  export const epicDeleteThemeDocuments = (action$, state$) =>
  action$.pipe(
    ofType(THEME_DOCUMENT_DELETE_ATTEMPT),
    switchMap(({ payload: { document_id, enqueueSnackbar } }) =>
    httpDelete({
        apiVersion: "v1",
        call: `documents/${document_id}`,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Document edited", {
              variant: "success",
            });
          }
          return themesDocumentDeleteSuccess({document_id});
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const themesDocumentsEpic = combineEpics(epicAddThemeDocument, epicGetThemeDocuments, epicDeleteThemeDocuments);

export default themesDocumentsEpic;

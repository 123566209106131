import React from "react";

import { Icon, mdSize } from "../../../../../common/icon";

const styles = {
  zIndex: 9,
  position: "absolute",
  right: "22px",
  padding: "5px",
  cursor: "pointer",
};

const HighOrderClaimEdit = ({ handleClick }) => (
  <div onClick={handleClick} style={styles}>
    <Icon {...mdSize} icon="icn-edit-button" className="cursor-pointer" />
  </div>
);

export default React.memo(HighOrderClaimEdit);

import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import adminFuseHoc from "common/HOC/adminFuseHoc";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { Loading, Button, BtnType } from "modules/primitives/index";
import { getAvailableIntegrations } from "./adminIntegrations.actions";
import styles from "./adminIntegrations.module.scss";
import useIntegrationHelper from "../../../common/hooks/useIntegrationHelper.hook";
import { httpGet } from "../../../common/httpCall";

const AdminIntegrationsComponent = () => {
  const { accounts } = useMsal();
  const {
    adminIntegrationsReducer: { integrations },
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [, setToggle] = useState(false);
  const [, setSfConnected] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isAccessToken, setIsAccessToken] = useState(true);
  const msftHelper = useIntegrationHelper({
    name: "Microsoft",
    iconStyles: styles.icon,
  });
  const salesforceHelper = useIntegrationHelper({
    name: "Salesforce",
    iconStyles: styles.icon,
  });
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setToggle(localStorage.getItem("sfToggle"));
    setSfConnected(localStorage.getItem("SfConnected"));
    try {
      dispatch(getAvailableIntegrations({ enqueueSnackbar }));
    } catch (err) {
      console.log("error on get stages", err);
    }
  }, []);

  const helper = {
    Microsoft: msftHelper,
    Salesforce: salesforceHelper,
  };

  const handleConfigureSalesforce = () => {
    history.push({
      pathname: "/salesforce/configureData",
      state: {
        attribute: "admin",
      },
    });
  };

  useEffect(() => {
    if (session && selectedCouncil) {
      httpGet({
        call: "/sf_integrations/fetch_access_token",
      })
        .pipe()
        .subscribe((res) => {
          const userIntegration = res?.response?.find(
            (integration) =>
              integration.user_id === session.id &&
              integration.council_id === selectedCouncil.id &&
              integration.token,
          );
          if (userIntegration) {
            const dateNow = new Date();
            const tokenUpdatedDate = userIntegration.updated_at;
            const hourDiff = Math.floor(
              (Date.parse(dateNow) - Date.parse(tokenUpdatedDate)) / 3600000,
            );
            setIsAccessToken(hourDiff <= 23);
          } else {
            setIsAccessToken(false);
          }
        });
    }
  }, [integrations]);

  const salesforceIntegrated = (service) =>
    service?.users_integrations?.some(
      (value) =>
        value?.council_id === selectedCouncil?.id &&
        value?.token &&
        value?.user_id === session?.id,
    );

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <a href="/admin" className={styles.rootText}>
            ADMIN
          </a>
          <span className={styles.separationBar}> / </span>
          <span className={styles.rootText}>INTEGRATIONS</span>
        </h4>
      </div>
      <div className="inline-flex justify-content-center">
        <h5 className="d-flex justify-content-center">
          Please select the service you want to enable for all Traction members.
        </h5>
        <h5 className="d-flex justify-content-center">
          After selecting, please visit integrations tab on settings page to
          connect to the services.
        </h5>
      </div>
      {integrations.map((service) => (
        <>
          <div className="d-flex justify-content-center" id={service.id}>
            <div className={styles.box}>
              <div className="d-flex">
                <h3 className="m-0">{service.name}</h3>
              </div>
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                checked={
                  helper[service.name].onClick
                    ? service.councils_integrations[0]?.status
                    : false
                }
                onColor="#24a0ed"
                onChange={(checked) =>
                  helper[service.name].onClick
                    ? helper[service.name].onToggle(service, checked)
                    : null
                }
              />
            </div>
          </div>
          {service.name === "Salesforce" ? (
            <div className="d-flex justify-content-center" id={service.id}>
              <div className={styles.box}>
                <div className="d-flex">
                  {helper[service.name].icon}
                  <h3 className="m-0">{service.name}</h3>
                </div>
                {salesforceIntegrated(service) && isAccessToken ? (
                  <div className="d-flex row" style={{ justifyContent: "end" }}>
                    <Button
                      btn={BtnType.REGULAR}
                      onClick={handleConfigureSalesforce}
                    >
                      CONFIGURE
                    </Button>
                    <Button
                      btn={BtnType.DISABLED}
                      onClick={() => helper[service.name].onDisconnect(service)}
                      className={`float-right ${styles.configureBtn}`}
                    >
                      DISCONNECT
                    </Button>
                  </div>
                ) : (
                  <div>
                    {loader ? (
                      <Loading />
                    ) : (
                      <Button
                        btn={BtnType.REGULAR}
                        onClick={() =>
                          helper[service.name].onClick(service, setLoader)
                        }
                      >
                        CONNECT
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center" id={service.id}>
              <div className={styles.box}>
                <div className="d-flex">
                  {helper[service.name].icon}
                  <h3 className="m-0">{service.name}</h3>
                </div>
                {service?.users_integrations[0]?.token ? (
                  <div className="d-flex row" style={{ justifyContent: "end" }}>
                    <Button
                      btn={BtnType.DISABLED}
                      onClick={() => helper[service.name].onDisconnect(service)}
                      className={`float-right ${styles.configureBtn}`}
                    >
                      DISCONNECT
                    </Button>
                    {service.name === "Microsoft" && (
                      <p>[{accounts[0]?.username}]</p>
                    )}
                  </div>
                ) : (
                  <div mr-2>
                    <Button
                      btn={BtnType.REGULAR}
                      onClick={() => helper[service.name].onClick(service)}
                    >
                      CONNECT
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default React.memo(adminFuseHoc(AdminIntegrationsComponent));

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import { checkViewerRole } from "common/checkers/viewerChecker";
import styles from "./stageRow.module.scss";
import { CheckBox, CheckType, MultiUsers } from "../../../../primitives";
import { Icon, mdSize } from "../../../../../common/icon";
import {
  appSelectedSet,
  appSliderSettingSet,
} from "application/app.action";
import { sliderSetting } from "application/app.constants";
import {
  TaskInfoHeaderComponentV2,
  TaskInfoComponentV2,
} from "../../tasks/taskInfo";
import {
  TaskAllInfoComponent,
  TaskAllInfoHeaderComponent,
} from "../taskAllInfo";
import {
  projectCompanyTaskLitGet,
  projectOrganizationTaskListGet,
  setCompanyTaskAssignmentCompleted,
} from "../companies.action";
import {
  projectTaskCompanyListGet,
  projectTaskUpdate,
} from "../../tasks/tasks.action";

const StageRowComponent = (props) => {
  const { stage, company, patientOrg } = props;
  const {
    projectsReducer: {
      companyReducer: { taskList_companyId },
      projectReducer: { selectedProject },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    setTasks([]);
    if (
      selectedProject.stage_template &&
      selectedProject.stage_template.patient_organization
    ) {
      dispatch(
        projectOrganizationTaskListGet({
          projectId: selectedProject.id,
          gsk_patient_organization_id: company.id,
          stageId: stage.id,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(
        projectCompanyTaskLitGet({
          projectId: selectedProject.id,
          companyId: company.id,
          stageId: stage.id,
          enqueueSnackbar,
        })
      );
    }
  }, [company.id]);

  useEffect(() => {
    if (taskList_companyId[`${company.id}_${stage.id}`]) {
      const list = taskList_companyId[`${company.id}_${stage.id}`];
      setTasks(list);
    }
  }, [taskList_companyId]);

  const handleOnChange = (value, task) => {
    const assignment = task.projects_task_assignments.find(
      (a) => a.projects_task_organization?.organization_id === company.id
    );

    dispatch(
      projectTaskUpdate({
        enqueueSnackbar,
        taskId: task.id,
        projectId: selectedProject.id,
        flag: value ? "completed_true" : "completed_false",
        project_task: {
          task_assignments: {
            completed_on: value ? format(new Date(), "yyyy-MM-dd") : null,
          },
        },
        queryParams: {
          task_assignment_id: assignment.id,
        },
        companyId: company.id,
        patientOrg,
      })
    );
    dispatch(
      setCompanyTaskAssignmentCompleted({
        companyId: company.id,
        stageId: stage.id,
        dueOn: task.due_on,
        patient_org: patientOrg,
        gsk: patientOrg,
        assignmentId: assignment.id,
        taskId: task.id,
        value,
        flag: value ? "completed_true" : "completed_false",
        enqueueSnackbar,
      })
    );
  };

  const handleBack = () => {
    dispatch(
      appSliderSettingSet({
        ...sliderSetting,
        show: true,
        height: "300",
        width: "540",
        header: <TaskAllInfoHeaderComponent company={company} />,
        component: (
          <TaskAllInfoComponent company={company} patientOrg={patientOrg} />
        ),
      })
    );
  };

  const handleShowTask = (obj) => {
    const assignId = `stage${obj.id}_${company.id}`;

    if (selectedProject) {
      dispatch(
        projectTaskCompanyListGet({
          projectId: selectedProject.id,
          taskId: obj.id,
          enqueueSnackbar,
        })
      );
    }

    const assignment = obj.projects_task_assignments.find(
      (a) => a.projects_task_organization?.organization_id === company.id
    );

    dispatch(appSelectedSet(assignId));
    dispatch(
      appSliderSettingSet({
        ...sliderSetting,
        show: true,
        height: "300",
        width: "540",
        header: (
          <TaskInfoHeaderComponentV2
            taskId={obj.id}
            projectId={obj.project_id}
            assignmentId={assignment.id}
            completed={assignment.completed}
            stage={stage}
            company={company}
            back={handleBack}
            patientOrg={patientOrg}
            isCompanyTask
            completionEnabled={obj.completion_enabled}
            task={obj}
          />
        ),
        component: (
          <TaskInfoComponentV2
            task={obj}
            company={company}
            assignment={assignment}
          />
        ),
      })
    );
  };

  const checkIsChecked = useCallback(
    (task) =>
      task.projects_task_assignments.find(
        (a) => a.projects_task_organization?.organization_id === company.id
      )?.completed,
    [company]
  );

  return (
    <div className={styles.stageRowWrp}>
      <div className={styles.headerWrp}>
        <div className={styles.title}>{stage.name ? stage.name : "-"}</div>
        <div className={styles.colDue}>Due</div>
        <div className={styles.colAssign}>Assigned</div>
        <div className={styles.colOther} />
      </div>
      <div className={styles.container}>
        {tasks.length ? (
          tasks.map((task, inx) => (
            <>
              <div
                key={inx}
                className={styles.taskRow}
                onClick={() => handleShowTask(task)}
              >
                <div className={styles.title}>
                  <div className={styles.colCheck}>
                    {!blockFunctionality ? (
                      <Tooltip
                        title={!task.completion_enabled
                          ? task.mark_on_document_upload ? 'Upload the required document to complete this Task' : 'Fill out the required linked fields to complete this Task'
                          : ''}
                      >
                        <div>
                          <CheckBox
                            checkType={CheckType.BLUE}
                            isChecked={checkIsChecked(task)}
                            onChange={(val) => handleOnChange(val, task)}
                            disabled={!task.completion_enabled}
                            readOnly={!task.completion_enabled}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="You have View-Only access. To edit this Project, please ask your Admin to upgrade your account to Standard access and invite you to this Project."
                        placement="top"
                      >
                        <div>
                          <CheckBox
                            checkType={CheckType.BLUE}
                            isChecked={checkIsChecked(task)}
                            disabled
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <div>{task && task.name ? task.name : "-"}</div>
                </div>
                <div className={classNames(styles.taskcol, styles.colDue)}>
                  {task.due_date && format(parseISO(task.due_date), "MMM dd")}
                </div>
                <div className={classNames(styles.taskcol, styles.colAssign)}>
                  <MultiUsers
                    users={task.assigned_users}
                    total={task.assigned_users.length}
                  />
                </div>
                <div className={classNames(styles.taskcol, styles.colOther)}>
                  <Icon
                    {...mdSize}
                    icon="icn-right-chevron"
                    className={styles.icon}
                  />
                </div>
              </div>
            </>
          ))
        ) : (
          <div
            className={`d-flex p-2 justify-content-center ${styles.noTasks}`}
          >
            No Tasks
          </div>
        )}
        {tasks.length ? <div className={styles.taskRow} /> : null}
      </div>
    </div>
  );
};

export default React.memo(StageRowComponent);

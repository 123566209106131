import { combineEpics, ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  apiStatus,
  errorHandler,
  httpGet,
  httpPost,
  httpDelete,
  httpPut,
} from "../httpCall";
import {
  DISCUSSION_SAVE_ATTEMPT,
  PRIVATE_DISCUSSIONS_GET_ATTEMPT,
  privateDiscussionSaveSuccess,
  privateDiscussionsGetSuccess,
  PROJECT_DISCUSSIONS_GET_ATTEMPT,
  projectDiscussionsGetSuccess,
  PUBLIC_DISCUSSIONS_GET_ATTEMPT,
  publicDiscussionSaveSuccess,
  publicDiscussionsGetSuccess,
  SEND_REPLY_ATTEMPT,
  sendReplySuccess,
  projectDiscussionSave,
  USER_TEAMS_GET_ATTEMPT,
  userTeamsGetSuccess,
  COMPANY_DISCUSSIONS_GET_ATTEMPT,
  companyDiscussionsGetSuccess,
  companyDiscussionSave,
  eventDiscussionSave,
  EVENT_DISCUSSIONS_GET_ATTEMPT,
  eventDiscussionsGetSuccess,
  PATIENT_ORG_DISCUSSIONS_GET_ATTEMPT,
  patientOrgDiscussionsGetSuccess,
  PATIENT_ORG_DISCUSSION_SAVE_ATTEMPT,
  patientOrgDiscussionSaveSuccess,
  ADD_DISCUSSION_MEMBERS_ATTEMPT,
  addDiscussionMembersSuccess,
  errorCatchSuccess,
  DISCUSSION_MSG_DELETE,
  discussionMsgDeleteSuccess,
  DISCUSSION_MSG_EDIT,
  discussionMsgEditSuccess,
  DISCUSSION_DELETE,
  discussionDeleteSuccess,
  DISCUSSION_EDIT,
  discussionEditSuccess,
  NOTIFICATIONS_GET_ATTEMPT,
  notificationsGetSuccess,
  MY_TASKS_NOTIFICATIONS_GET_ATTEMPT,
  myTasksNotificationsSuccess,
  MY_TASKS_NOTIFICATIONS_DELETE_ATTEMPT,
  myTasksNotificationsDeleteSuccess,
} from "../actions/discussions.action";

const epicDiscussionEdit = (action$) =>
  action$.pipe(
    ofType(DISCUSSION_EDIT),
    switchMap(({ payload }) =>
      httpPut({
        call: `discussions/${payload.data.id}`,
        data: payload.data,
      }).pipe(
        map((result) =>
          discussionEditSuccess({
            ...result,
            action: "edit",
          })
        ),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

const epicDiscussionDelete = (action$) =>
  action$.pipe(
    ofType(DISCUSSION_DELETE),
    switchMap(({ payload }) =>
      httpDelete({
        call: `discussions/${payload.id}`,
      }).pipe(
        map((result) =>
          discussionDeleteSuccess({
            ...result,
            action: "delete",
            discussion: payload,
          })
        ),
        catchError((err) => errorHandler(err))
      )
    )
  );

const epicDiscussionMsgDelete = (action$) =>
  action$.pipe(
    ofType(DISCUSSION_MSG_DELETE),
    switchMap(({ payload }) =>
      httpDelete({
        call: `discussions/${payload.discussion_id}/messages/${payload.message_id}`,
      }).pipe(
        map((result) =>
          discussionMsgDeleteSuccess({
            ...result,
            action: "delete",
            discussion_id: payload.discussion_id,
            message_id: payload.message_id,
          })
        ),
        catchError((err) => errorHandler(err))
      )
    )
  );

const epicDiscussionMsgEdit = (action$) =>
  action$.pipe(
    ofType(DISCUSSION_MSG_EDIT),
    switchMap(({ payload }) =>
      httpPut({
        call: `discussions/${payload.discussion_id}/messages/${payload.message_id}`,
        data: {
          message: {
            subject: payload.subject,
          },
        },
      }).pipe(
        map((result) =>
          discussionMsgEditSuccess({
            ...result,
            action: "edit",
            discussion_id: payload.discussion_id,
            message_id: payload.message_id,
            content: payload.subject,
          })
        ),
        catchError((err) => errorHandler(err))
      )
    )
  );

const epicGetEventDiscussions = (action$) =>
  action$.pipe(
    ofType(EVENT_DISCUSSIONS_GET_ATTEMPT),
    switchMap(({ payload: { councilId, eventId, items, enqueueSnackbar } }) =>
      httpGet({
        call: `discussions/events?council_id=${councilId}&event_id=${eventId}&items=${
          items || 6
        }`,
      }).pipe(
        map((result) => eventDiscussionsGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const epicGetCompanyDiscussions = (action$) =>
  action$.pipe(
    ofType(COMPANY_DISCUSSIONS_GET_ATTEMPT),
    switchMap(({ payload: { councilId, companyId, enqueueSnackbar, cb } }) =>
      httpGet({
        call: `discussions/companies?council_id=${councilId}&company_id=${companyId}`,
      }).pipe(
        map((result) => {
          if (cb) {
            cb();
          }

          return companyDiscussionsGetSuccess(result);
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], errorCatchSuccess)
        )
      )
    )
  );

const epicGetPatientOrgDiscussions = (action$) =>
  action$.pipe(
    ofType(PATIENT_ORG_DISCUSSIONS_GET_ATTEMPT),
    switchMap(({ payload: { patientOrgId, enqueueSnackbar, cb } }) =>
      httpGet({
        call: `discussions/patient_organizations?patient_organization_id=${patientOrgId}`,
      }).pipe(
        map((result) => {
          if (cb) {
            cb();
          }
          return patientOrgDiscussionsGetSuccess(result);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

const epicGetUserTeams = (action$) =>
  action$.pipe(
    ofType(USER_TEAMS_GET_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: "user/teams",
      }).pipe(
        map((result) => userTeamsGetSuccess(result)),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

const epicGetPublicDiscussions = (action$) =>
  action$.pipe(
    ofType(PUBLIC_DISCUSSIONS_GET_ATTEMPT),
    switchMap(({ payload: { council_id, items, page, enqueueSnackbar } }) =>
      httpGet({
        call: `discussions/public?council_id=${council_id}&type=GROUP${
          items ? `&items=${items}` : ""
        }&page=${page || 1}`,
      }).pipe(
        map((result) => publicDiscussionsGetSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

const epicGetPrivateDiscussions = (action$) =>
  action$.pipe(
    ofType(PRIVATE_DISCUSSIONS_GET_ATTEMPT),
    switchMap(({ payload: { id, page, items = 50, enqueueSnackbar } }) =>
      httpGet({
        call: `discussions/private?council_id=${id}&page=${page || 1}${
          items ? `&items=${items}` : ""
        }`,
      }).pipe(
        map((result) => privateDiscussionsGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const epicGetNotifications = (action$) =>
  action$.pipe(
    ofType(NOTIFICATIONS_GET_ATTEMPT),
    switchMap(
      ({ payload: { id, page, items, tasks_and_projects, enqueueSnackbar } }) =>
        httpGet({
          call: `email_messages?page=${page}&items=${items}&tasks_and_projects=${tasks_and_projects}`,
        }).pipe(
          map((result) => notificationsGetSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

const epicGetMyTasksNotifications = (action$) =>
  action$.pipe(
    ofType(MY_TASKS_NOTIFICATIONS_GET_ATTEMPT),
    switchMap(({ payload: { page, items, data, enqueueSnackbar } }) =>
      httpPost({
        call: `my_tasks?page=${page}&items=${items}`,
        data,
      }).pipe(
        map((result) => myTasksNotificationsSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
export const epicGetProjectDiscussions = (action$) =>
  action$.pipe(
    ofType(PROJECT_DISCUSSIONS_GET_ATTEMPT),
    switchMap(
      ({
        payload: {
          projectId,
          councilId,
          items,
          enqueueSnackbar,
          publicDiscussions = true,
        },
      }) =>
        httpGet({
          call: `discussions/projects?project_id=${projectId}&public=${publicDiscussions}&council_id=${councilId}&items=${
            items || 6
          }`,
        }).pipe(
          map((result) => projectDiscussionsGetSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicAddNewPatientOrgDiscussion = (action$) =>
  action$.pipe(
    ofType(PATIENT_ORG_DISCUSSION_SAVE_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        call: `discussions/patient_organizations?patient_organization_id=${data.patient_organization_id}`,
        data,
      }).pipe(
        map((result) => {
          if (data.cb) {
            data.cb();
          }

          if (result.status === apiStatus.CREATED) {
            enqueueSnackbar("Successfully added discussion.", {
              variant: "success",
            });

            return patientOrgDiscussionSaveSuccess(result);
          }
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicAddNewDiscussion = (action$) =>
  action$.pipe(
    ofType(DISCUSSION_SAVE_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        call: `discussions?council_id=${data.discussion.council_id}`,
        data,
      }).pipe(
        map((result) => {
          if (result.status === apiStatus.CREATED) {
            enqueueSnackbar("Successfully added discussion.", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Something wrong happened", {
              variant: "error",
            });
          }

          if (data.cb) {
            data.cb(result);
          }

          if (data.type === "Council") {
            return !data.discussion.public
              ? privateDiscussionSaveSuccess(result)
              : publicDiscussionSaveSuccess(result);
          }

          if (data.type === "Project") {
            return projectDiscussionSave(result);
          }

          if (data.type === "Company") {
            return companyDiscussionSave(result);
          }

          if (data.type === "Event") {
            return eventDiscussionSave(result);
          }
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicSendReplyEpic = (action$) =>
  action$.pipe(
    ofType(SEND_REPLY_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        call: `discussions/messages?discussion_id=${data.id}`,
        data: {
          message: data.message,
        },
      }).pipe(
        map((result) =>
          sendReplySuccess({
            ...result,
            prop: data.isPrivate ? "privateDiscussions" : data.prop,
          })
        ),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicAddDiscussionMembers = (action$) =>
  action$.pipe(
    ofType(ADD_DISCUSSION_MEMBERS_ATTEMPT),
    switchMap(({ payload: { discussionId, data, enqueueSnackbar } }) =>
      httpPost({
        call: `discussions/${discussionId}/users/batch`,
        data,
      }).pipe(
        map((result) => addDiscussionMembersSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const epicTaskNotificationDelete = (action$) =>
  action$.pipe(
    ofType(MY_TASKS_NOTIFICATIONS_DELETE_ATTEMPT),
    switchMap(({ payload }) => {
      return httpDelete({
        call: `email_messages/${payload.id}`,
      }).pipe(
        map((result) => {
          payload.enqueueSnackbar(
            "Notification has been deleted successfully",
            {
              variant: "success",
            }
          );

          return myTasksNotificationsDeleteSuccess(result);
        }),
        catchError((err) => {
          errorHandler(err, payload.enqueueSnackbar);
        })
      );
    })
  );

const discussionsEpic = combineEpics(
  epicDiscussionEdit,
  epicDiscussionDelete,
  epicDiscussionMsgDelete,
  epicDiscussionMsgEdit,
  epicGetPublicDiscussions,
  epicGetPrivateDiscussions,
  epicGetProjectDiscussions,
  epicAddNewDiscussion,
  epicSendReplyEpic,
  epicGetUserTeams,
  epicGetCompanyDiscussions,
  epicGetEventDiscussions,
  epicGetPatientOrgDiscussions,
  epicAddNewPatientOrgDiscussion,
  epicAddDiscussionMembers,
  epicGetNotifications,
  epicGetMyTasksNotifications,
  epicTaskNotificationDelete
);

export default discussionsEpic;

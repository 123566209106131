export const LoginStatus = {
  LOGIN_NEW: 0,
  LOGIN_SUCCESS: 1,
  LOGOUT: 2,
  LOGIN_FAILED: 3,
};

export const RegisterStatus = {
  REGISTER_NEW: 0,
  REGISTER_SUCCESS: 1,
};

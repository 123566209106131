import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ModalFooter, ModalBody } from "../../../../../../application/modal";
import {
  Button,
  BtnType,
  Label,
  DropSelect,
} from "../../../../../primitives";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../../common/hooks";
import UserMultiSelectSearch from "../../../../../../common/components/usersSearch";

const ManageTaskUsers = ({ callBack, model, multiple }) => {
  const [addAsAProjectMember, setAddAsAProjectMember] = useState(true);
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const popup = useModelPopup();

  const {
    projectsReducer: {
      usersReducer: { projectUsersList },
      projectReducer: { selectedProject },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (model.assigned_to?.id && selectedProject.project_members?.length) {
      const preselectedIsMember = selectedProject.project_members.some(m => m.user.id === model.assigned_to.id);
      if (preselectedIsMember) {
        setAddAsAProjectMember(false);
      }
    }
  }, [model, selectedProject]);

  const handleCancelClick = () => {
    popup.hide();
  };

  useEffect(() => {
    if (model?.assigned_to?.id) {
      setSelected([
        {
          user_id: model?.assigned_to?.id,
          id: model?.assigned_to?.id,
          full_name: model?.assigned_to?.full_name,
          fullName: model?.assigned_to?.full_name,
        },
      ]);
    }
  }, [model]);

  useEffect(() => {
    if (projectUsersList.length !== 0) {
      const list = projectUsersList.map(({ user }) => ({
        ...user,
        user_id: user.id,
        fullName: user.full_name,
      }));
      setOptions(list);
    }
  }, [projectUsersList]);

  const handleSaveClick = () => {
    callBack({
      user: selected[0],
      addAsAProjectMember,
    });
  };

  const handleOptionSelect = (values) => {
    if (!multiple && values?.length) {
      const selected = values[0];

      if (!selected.project_role && model.assigned_to?.id !== selected.id) {
        setAddAsAProjectMember(true);
      } else if (selected.project_role && model.assigned_to?.id !== selected.id) {
        setAddAsAProjectMember(false);
      }
    }

    setSelected(values?.obj || values || []);
  };

  return (
    <div className={styles.selectCouncilWrp}>
      <ModalBody>
        {
          (selectedProject.visibility === 'all' || selectedProject.visibility === 'team') && (
            <div className="d-flex flex-column">
              <UserMultiSelectSearch
                multi={multiple}
                placeholder="Choose project member"
                labelField="full_name"
                valueField="id"
                searchBy="full_name"
                hasAll={false}
                onChange={handleOptionSelect}
                formProps={{
                  values: selected,
                }}
                values={selected}
                teams_ids={selectedProject.visibility === "team" ? [selectedProject.team.team_id] : []}
                projectId={selectedProject.id}
              />
              <label className="d-flex align-items-center mt-3 mb-4">
                <input
                  type="checkbox"
                  checked={addAsAProjectMember}
                  onChange={() => setAddAsAProjectMember(!addAsAProjectMember)}
                />
                <span className="d-block ml-3">Add to Project as a Project Member</span>
              </label>
            </div>
          )
        }
        {
          selectedProject.visibility === "project_members" && (
            <>
              <Label>Members</Label>
              <DropSelect
                onChange={handleOptionSelect}
                placeholder="Select"
                labelField="fullName"
                valueField="id"
                options={options}
                SelecedValues={selected}
                className={null}
              />
            </>
          )
        }
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button type="submit" btn={BtnType.REGULAR} onClick={handleSaveClick}>
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default React.memo(ManageTaskUsers);

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import isItSmallDevice from "modules/themes/themesCommon/isItSmallDevice";
import ConfirmDeleteComponent from "../../../../../common/components/confirmDelete/index";
import styles from "./styles.module.scss";
import { Pannel, Pagination, Loading } from "../../../../primitives";
import { useModelPopup } from "../../../../../common/hooks";
import { Icon, mdSize } from "../../../../../common/icon";
import MemberComponent from "./member.component";
import {
  themesMemberEditAttempt,
  themesMemberAddAttempt,
  themesMemberDeleteAttempt,
} from "../overviewTab.action";

const MembersComponent = ({ themeId, memberListFromOverview = [] }) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesOverviewReducer: { memberLists, loadingMember },
      themesMainReducer: { currentThemeData },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const [seeAll, setSeeAll] = useState(false);
  const [expert, setExpert] = useState(false);
  const [members, setMembers] = useState([]);
  const [isThemeMember, setIsThemeMember] = useState(
    currentThemeData?.current_user_member
  );
  const [isMembersOpened, setIsMembersOpened] = useState(true);
  const [pageSetting, setPageSetting] = useState({
    pageSize: 10,
    current: 1,
    hideOnSinglePage: true,
    showTitle: false,
    total: memberListFromOverview?.length || members?.length || 0,
  });
  const [chunkToShow, setChunkToShow] = useState([]);

  const handlePagingChange = (page) => {
    setPageSetting((prev) => ({
      ...prev,
      total: members?.length,
      current: page,
    }));
  };

  useEffect(() => {
    if (members?.length && !seeAll) {
      setChunkToShow(members.slice(0, 4));
      setPageSetting({
        pageSize: 10,
        current: 1,
        hideOnSinglePage: true,
        showTitle: false,
        total: memberListFromOverview?.length || members?.length || 0,
      });
    }
    if (pageSetting?.current && members?.length && seeAll) {
      setChunkToShow(
        members?.slice(
          (pageSetting.current - 1) * pageSetting.pageSize,
          pageSetting.current * pageSetting.pageSize
        )
      );
    }
  }, [pageSetting?.current, members, seeAll]);

  const deselectExpert = (member) => {
    const data = {
      theme_member: {
        user_role: member.user_role,
        expert: !expert,
      },
    };
    setExpert(!expert);
    dispatch(
      themesMemberEditAttempt({ data, id: themeId, userId: member.user_id })
    );
  };

  const handleLeaveThemeClick = (id) => {
    popup.show({
      title: "Confirmation Delete",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={themeId}
          templateId={id}
          enqueueSnackbar={enqueueSnackbar}
          councilId={selectedCouncil?.id}
          actionType="deleteThemeMember"
        />
      ),
    });
  };

  const handleAddMemberClick = () => {
    const data = {
      theme_member: [
        {
          user_id: session.id,
          expert: false,
        },
      ],
      user_role: "member",
    };
    if (isThemeMember) {
      dispatch(
        themesMemberDeleteAttempt({
          themeId: currentThemeData.id,
          memberId: session.id,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(
        themesMemberAddAttempt({
          themeId: currentThemeData.id,
          data,
          enqueueSnackbar,
        })
      );
    }
  };

  useEffect(() => {
    if (selectedCouncil) {
      setIsThemeMember(currentThemeData?.current_user_member);
    }
  }, [currentThemeData?.current_user_member]);

  const compareFn = (a, b) => b.expert - a.expert;

  useEffect(() => {
    const sortedMembers = (
      memberLists?.length ? memberLists : memberListFromOverview
    ).sort(compareFn);
    setMembers(sortedMembers);
  }, [memberLists, memberListFromOverview]);

  if (loadingMember) {
    return <Loading />;
  }

  return (
    <Pannel
      title={`${
        selectedCouncil?.settings?.themes_display_name_singular || "Theme"
      } Followers`}
      padding="0"
      parentStyle={{
        width: `${window.screen.width < 550 ? "100%" : "230px"}`,
        minWidth: "230px",
        padding: `${window.screen.width < 550 && "0px"}`,
      }}
    >
      <div className={styles.panelHeader}>
        Followers
        <div className={styles.panelIcons}>
          {isItSmallDevice() && (
            <Icon
              {...mdSize}
              icon="icn-table-down-chevron"
              className={
                isMembersOpened ? styles.rotatedDownChevron : styles.downChevron
              }
              onClick={() => {
                setIsMembersOpened(!isMembersOpened);
              }}
            />
          )}
          {isThemeMember ? (
            <Icon
              {...mdSize}
              icon={"on-track"}
              className={styles.addNew}
              onClick={(e) => {
                e.stopPropagation();
                setIsThemeMember(!isThemeMember);
                handleAddMemberClick();
              }}
            />
          ) : (
            <Icon
              {...mdSize}
              icon="icn-add"
              className={styles.addNew}
              onClick={(e) => {
                e.stopPropagation();
                setIsThemeMember(!isThemeMember);
                handleAddMemberClick();
              }}
            />
          )}
        </div>
      </div>
      {isMembersOpened && (
        <div className={styles.wrapper}>
          {members.length > 0 ? (
            chunkToShow?.map((item) => (
              <MemberComponent
                key={`${item.user_id}`}
                item={item}
                handleLeaveThemeClick={handleLeaveThemeClick}
                setExpert={deselectExpert}
                userThemeRole={currentThemeData?.current_user_role}
              />
            ))
          ) : (
            <span className="p-3 text-center">No members yet!</span>
          )}
          {seeAll && (
            <div className={styles.paginationCon}>
              <Pagination {...pageSetting} onChange={handlePagingChange} />
            </div>
          )}
          {members.length > 6 && (
            <div onClick={() => setSeeAll(!seeAll)} className={styles.showAll}>
              {seeAll ? "HIDE" : "SEE ALL"}
            </div>
          )}
        </div>
      )}
    </Pannel>
  );
};

export default React.memo(MembersComponent);

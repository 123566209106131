import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

import adminFuseHoc from "common/HOC/adminFuseHoc";
import styles from "../adminTags/adminTags.module.scss";
import {
  deleteArea,
  deletePlant,
  getPlantsAndAreas,
} from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { Icon, mdSize, xsSize } from "../../../common/icon";
import { useModelPopup } from "common/hooks/index";
import ConfirmActionComponent from "../../../common/components/confirmAction/confirmAction.component";
import { BtnType, Button } from "modules/primitives";
import AddAdminPlant from "modules/admin/adminPlantsAndAreas/adminAddPlant";
import AddAdminArea from "modules/admin/adminPlantsAndAreas/adminAddArea";
import { httpGet } from "../../../common/httpCall";
import AddAdminRegion from "modules/admin/adminPlantsAndAreas/adminAddRegion";

const AdminPlantsAndAreas = () => {
  const popup = useModelPopup();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [expandedAll, setExpandedAll] = useState(false);
  const [plantsToExpand, setPlantsToExpand] = useState({});
  const [regions, setRegions] = useState([]);

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    adminPlantsAndAreasReducer: { fetching, plantsAndAreas }
  } = useSelector((state) => state);

  useEffect(() => {
    httpGet({
      call: 'plants/regions',
    }).subscribe(res => setRegions(res.response || []));
  }, []);

  useEffect(() => {
    if (session && selectedCouncil?.id) {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        })
      );
    }
  }, [session, selectedCouncil]);

  const handleExpand = useCallback((elem) => {
    setPlantsToExpand(prevPlantsToExpand => {
      if (prevPlantsToExpand[elem.id]) {
        const { [elem.id]: omitted, ...rest } = prevPlantsToExpand;
        return rest;
      } else {
        return {
          ...prevPlantsToExpand,
          [elem.id]: true,
        };
      }
    });
  }, [setPlantsToExpand]);

  const handExpandAll = () => {
    if (expandedAll) {
      setPlantsToExpand({});
      setExpandedAll(false);
      return;
    }

    const allIds = plantsAndAreas.reduce((acc, elem) => {
      if (!elem.attributes?.areas?.length) {
        return acc;
      }

      return {
        ...acc,
        [elem.id]: true,
      }
    }, {});

    setExpandedAll(true);
    setPlantsToExpand(allIds);
  }

  const handleEditPlant = (plant) => {
    let existingPlants = [];

    if (plant?.id) {
      existingPlants = plantsAndAreas
        .filter(p => p.id !== plant.id)
        .map(p => p.attributes?.name?.toLowerCase() || '');
    } else {
      existingPlants = plantsAndAreas.map(p => p.attributes?.name?.toLowerCase() || '');
    }

    popup.show(({
      title: `${plant ? 'Edit' : 'Add'} Plant`,
      component: (
        <AddAdminPlant plant={plant} existingPlants={existingPlants} regions={regions} />
      )
    }))
  };

  const handleDeleteConfirmPlant = (plant) => {
    dispatch(deletePlant({
      plantId: plant.id
    }))
  }

  const handleDeletePlant = (plant) => {
    popup.show({
      title: "Delete Plant",
      show: true,
      component: (
        <ConfirmActionComponent
          confirmBtnName="DELETE"
          alertText="Deleting this Plant will remove it from all Projects, Ideas, and Solutions. Do you want to proceed?"
          onConfirm={() => handleDeleteConfirmPlant(plant)}
        />
      ),
    });
  };

  const handleEditArea = (plant, area) => {
    popup.show(({
      title: `${area ? 'Edit' : 'Add'} Area`,
      component: (
        <AddAdminArea plant={plant} area={area} />
      )
    }))
  };

  const addRegion = (region) => {
    setRegions([...regions, region]);
  }

  const handleAddRegion = () => {
    popup.show(({
      title: 'Add Region',
      component: (
        <AddAdminRegion regions={regions} handleRegionAdd={addRegion} />
      )
    }))
  };

  const handleDeleteConfirmArea = (area, plant) => {
    dispatch(deleteArea({
      plantId: plant.id,
      areaId: area.area_id,
    }));
  }

  const handleDeleteArea = (area, plant) => {
    popup.show({
      title: "Delete Area",
      show: true,
      component: (
        <ConfirmActionComponent
          confirmBtnName="DELETE"
          alertText="Deleting this Area will remove it from all Projects, Ideas, and Solutions. Do you want to proceed?"
          onConfirm={() => handleDeleteConfirmArea(area, plant)}
        />
      ),
    });
  };

  const showChild = useCallback((plant) => {
    if (!plantsToExpand[plant.id] || !plant.attributes?.areas?.length) {
      return null;
    }

    return plant.attributes.areas.map((area) => (
      <div className="d-flex justify-content-between pr-4" key={area.area_id}>
        <div className={styles.rowNameChild}>
          <div style={{ marginLeft: 20 }}>
            <span style={{ marginLeft: 15 }}>
              {area.area_name}
            </span>
          </div>
        </div>
        <div className={styles.rowChildren}></div>
        <div className={styles.rowOptions}>
          <Icon
            {...mdSize}
            icon="icn-edit-button"
            className="cursor-pointer mr-2"
            onClick={() => handleEditArea(plant, area)}
          />
          <Icon
            {...mdSize}
            icon="icn-button-delete"
            className="cursor-pointer"
            onClick={() => handleDeleteArea(area, plant)}
          />
        </div>
      </div>
    ))
  }, [plantsToExpand, plantsAndAreas, handleDeleteArea, handleEditArea]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <span
            className={styles.rootText}
            onClick={() => {
              history.push("/admin");
            }}
          >
            ADMIN
          </span>
          <span className={styles.separationBar}> / </span>
          <span className={styles.rootText}>PLANTS</span>
        </h4>
      </div>
      <div className={styles.flexWrapper}>
        <div className={styles.tagsContainer}>
          <div className={styles.buttonsRow}>
            <div>
              {
                plantsAndAreas.length > 0 ? (
                  <span onClick={handExpandAll} className="cursor-pointer traction-blue">
                    {expandedAll ? 'COLLAPSE' : 'EXPAND'} ALL
                  </span>
                ) : null
              }
            </div>
            <div className="d-flex">
              <Button
                onClick={() => handleEditPlant(null)}
                btn={BtnType.REGULAR}
              >
                Add plant
              </Button>
              {
                plantsAndAreas.length > 0 ? (
                  <Button
                    onClick={() => handleEditArea()}
                    btn={BtnType.REGULAR}
                    className="ml-2"
                  >
                    Add Area
                  </Button>
                ) : null
              }
              <Button
                onClick={handleAddRegion}
                btn={BtnType.REGULAR}
                className="ml-2"
              >
                Add Region
              </Button>
            </div>
            <div className={styles.addTagButtonContainer}>
              <span className={styles.totalCount}>{plantsAndAreas.length} plants</span>
            </div>
          </div>
          {
            fetching && (
              <LoadingComponent customText="Getting plants and areas" />
            )
          }
          <>
            <div className={styles.tableHeader}>
              <div className={`${styles.nameTab} flex-grow-1`}>
                Plants and Areas
              </div>
              <div className={styles.childTab}>
                <span>Region</span>
              </div>
              <div className={styles.childTab}>
                <span>Areas Nr</span>
              </div>
              <div className={styles.optionTab}>
                <span>Options</span>
              </div>
            </div>
            {plantsAndAreas.map((elem) => (
              <div key={elem.id} className={styles.tableContainer}>
                <Accordion
                  className={styles.accordion}
                  expanded={Boolean(plantsToExpand[elem.id])}
                >
                  <AccordionSummary
                    className={styles.accordionRow}
                    aria-controls={elem.id}
                    id={elem.id}
                  >
                    <div className={styles.tableRow}>
                      <div className={`${styles.rowName} flex-grow-1`}>
                        <div className={styles.rowNameBlock}>
                          {elem.attributes.areas?.length > 0 ? (
                            <Icon
                              icon="icn-right-chevron"
                              {...xsSize}
                              onClick={() => handleExpand(elem)}
                              className={`${styles.iconColor} ${plantsToExpand[elem.id] && styles.rotateIcon}`}
                            />
                          ) : (
                            <Icon
                              icon="icn-right-chevron"
                              {...xsSize}
                              className={`${styles.iconColor} ${styles.spacing}`}
                            />
                          )}
                          <span>{elem.attributes.name}</span>
                        </div>
                      </div>
                      <div className={styles.rowChildren}>
                        <span>{elem.attributes?.plant_region?.plant_region_name || ''}</span>
                      </div>
                      <div className={styles.rowChildren}>
                        <span>{elem.attributes?.areas?.length}</span>
                      </div>
                      <div className={styles.rowOptions}>
                        <Icon
                          {...mdSize}
                          icon="icn-edit-button"
                          className="cursor-pointer mr-2"
                          onClick={() => handleEditPlant(elem)}
                        />
                        <Icon
                          {...mdSize}
                          icon="icn-button-delete"
                          className="cursor-pointer"
                          onClick={() => handleDeletePlant(elem)}
                        />
                      </div>
                    </div>
                  </AccordionSummary>
                  {showChild(elem)}
                </Accordion>
              </div>
            ))}
          </>
        </div>
      </div>
    </div>
  );
};

export default adminFuseHoc(AdminPlantsAndAreas);

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Container } from "react-smooth-dnd";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import ApprovalTaskHeader from "modules/projects/project/tasks/approvalTask/approvalTaskHeader.component";
import ApprovalTaskBody from "modules/projects/project/tasks/approvalTask/approvalTaskBody.component";
import { checkViewerRole } from "common/checkers/viewerChecker";
import { appSelectedSet, appSliderSettingSet } from "application/app.action";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { sliderSetting } from "application/app.constants";
import { Button, BtnType, Pagination } from "../../../primitives";
import styles from "./tasks.module.scss";
import "./tasks.scss";
import {
  projectTaskSortedListSet,
  projectTaskListGet,
  projectTasksByUserGet,
  projectTaskUpdate,
  getTaskAdditionalData,
} from "./tasks.action";
import TaskRowComponent from "./taskRow";
import TaskNewComponent from "./taskNew";
import {
  moveInsideArray,
  queryStringUrlReplacement,
  getCookie,
} from "../../../../common/helper";
import { useSorting, useModelPopup } from "../../../../common/hooks";
import { Icon, smSize } from "../../../../common/icon";
import { TaskInfoComponentV2, TaskInfoHeaderComponentV2 } from "./taskInfo";
import { isFerminichCouncil, checkIfFirmenich } from "../isFerminichCouncil";
import { isCreator } from "../../../../common/checkers/isCreator";

const PAGINATION_DEFAULTS = {
  pageSize: 20,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
};

const TasksComponent = ({
  canManageTasks,
  userId,
  selectedProject,
  selectedCouncil,
  councilData,
  isGatewayTaskSelected,
  selectedTaskId,
}) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [sortedArray, setSortedArray] = useState([]);
  const [sortingColumn, setSortingColumn] = useState(null);
  const [dragDrop, setDragDrop] = useState(false);
  const [tasksByTab, setTasksByTab] = useState([]);
  const [patientOrg, setPatientOrg] = useState(false);
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [shouldOpen, setShouldOpen] = useState("");
  const [blockFordOnly, setBlockFordOnly] = useState(false);
  const councilRole = getCookie("council_role_");
  const [currentUserProjRole, setCurrentUserProjRole] = useState(false);

  const history = useHistory();
  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const taskAsignementId = urlParams.get("taskAsignementId");
  const tabQuery = urlParams.get("tab");
  const pageQuery = urlParams.get("page");

  const [activeTask, setActiveTask] = useState("all_task");
  const [pageSetting, setPageSetting] = useState({
    ...PAGINATION_DEFAULTS,
    current: pageQuery ? Number(pageQuery) : 1,
  });

  useEffect(() => {
    setActiveTask(tabQuery || "all_task");
  }, [tabQuery]);

  useEffect(() => {
    if (selectedProject?.id) {
      const currentUserRole = selectedProject?.project_members?.find(
        (m) => m.user.id === userId
      );
      setCurrentUserProjRole(currentUserRole?.user_role);
    }
    // do once on init
    requestNewTasks('all_task');
  }, [selectedProject?.id]);

  const { enqueueSnackbar } = useSnackbar();
  const {
    authReducer: { session },
    projectsReducer: {
      stagesReducer: { stagesList },
      taskReducer: {
        tasksList,
        sortedList,
        asc,
        userTasks,
        tasksTotal,
        fetchingTasks,
      },
    },
  } = useSelector((state) => state);

  const sorting = useSorting([], asc, projectTaskSortedListSet);
  const owners = useMemo(
    () =>
      selectedProject?.project_members?.filter((m) => m.user_role === "owner"),
    [selectedProject]
  );

  useEffect(() => {
    if (selectedCouncil?.traction_tag === "ford") {
      const isAdmin = isCreator();

      if (isAdmin) {
        setBlockFordOnly(false);
        return;
      }

      const myUserIsProjOwner = owners?.some((o) => o.user.id === session.id);
      const isViewer = checkViewerRole();
      setBlockFordOnly(myUserIsProjOwner || isViewer);
    }
  }, [selectedCouncil, owners, session]);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (
      selectedProject &&
      selectedProject.stage_template &&
      selectedProject.stage_template.patient_organization
    ) {
      setPatientOrg(true);
    }
  }, [selectedProject]);

  useEffect(() => {
    setPageSetting({
      ...pageSetting,
      total: tasksTotal,
    });
  }, [tasksTotal]);

  useEffect(() => {
    if (activeTask === "my_task" && userTasks) {
      setTasksByTab(userTasks);
    } else {
      if (tasksList) {
        setTasksByTab(tasksList);
      }
    }
  }, [activeTask, userTasks, tasksList]);

  const generateCompanyListForSort = () => {
    const list = tasksByTab.map((task) => ({
      ...task,
      stageOrder: stagesList.findIndex((stage) => stage.id === task.stage_id),
    }));
    setSortedArray(list);
  };

  useEffect(() => {
    if (tasksByTab && tasksByTab.length > 0) {
      setSortedArray(tasksByTab);
    } else {
      generateCompanyListForSort();
    }
  }, [tasksByTab, sortedList, tasksList]);

  useEffect(() => {
    sorting.setSortingArray(sortedArray);
  }, [asc, sortingColumn]);

  useEffect(() => {
    if (dragDrop) setDragDrop(false);
  }, [dragDrop, setDragDrop]);

  const handleSwitchClick = (e, tab) => {
    setActiveTask(tab);

    let search = queryStringUrlReplacement(history.location.search, "tab", tab);
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: `/projects/${selectedProject.id}/task`,
      search,
      state: {
        ...history.location?.state,
        tab,
        page: 1,
      },
    });

    const timeout = setTimeout(() => {
      requestNewTasks(1, tab);
      setPageSetting({
        ...pageSetting,
        current: 1,
      });
      clearTimeout(timeout);
    });
  };

  const handleAddNewTaskClick = () => {
    popup.show({
      title: "Add a task",
      component: (
        <TaskNewComponent
          currentPage={pageSetting.current}
          activeTab={activeTask}
        />
      ),
    });
  };

  const handleDragDrop = (e) => {
    const { removedIndex, addedIndex } = e;
    const list = moveInsideArray(sortedArray, removedIndex, addedIndex);
    setSortedArray(list);
    setDragDrop(true);

    const payload = {
      projectId: selectedProject.id,
      taskId: list.find((elem, idx) => idx === addedIndex)?.id,
      project_task: {
        position: parseInt(addedIndex, 10),
      },
    };

    dispatch(projectTaskUpdate(payload));
  };

  const handleSorting = (prop, type) => {
    setSortingColumn(prop);
    sorting.sortChange(prop, type);
  };

  const drowSortingIcon = (col) => {
    if (sortingColumn === col) {
      return (
        <div className={classNames(styles.sortIcon, asc && styles.sortAsc)}>
          <Icon {...smSize} icon="icn-export-button" />
        </div>
      );
    }
  };

  const handlePageChange = (page) => {
    if (page === pageSetting.current) {
      return;
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      "page",
      page
    );

    search = queryStringUrlReplacement(search, "tab", activeTask);

    history.push({
      pathname: `/projects/${selectedProject.id}/task`,
      search,
      state: {
        ...history.location?.state,
        page,
        tab: activeTask,
      },
    });

    const timeout = setTimeout(() => {
      requestNewTasks(page, activeTask);
      setPageSetting({
        ...pageSetting,
        current: page,
      });
      clearTimeout(timeout);
    });
  };

  const requestNewTasks = useCallback(
    (currentPage, currentTab) => {
      const urlParams = new URLSearchParams(window.location.search);
      const currPageQuery = urlParams.get("page");
      const page = currentPage || currPageQuery ? Number(currPageQuery) : 1;
      const obj = {
        projectId: selectedProject.id,
        councilId: selectedCouncil.id,
        enqueueSnackbar,
        page,
        apiVersion: "v3",
      };
      const currTabQuery = urlParams.get("tab");
      const activeTabValue = currentTab || currTabQuery || "all_task";

      if (activeTabValue === "my_task") {
        dispatch(projectTasksByUserGet({ ...obj, userId }));
      }

      if (activeTabValue === "all_task") {
        dispatch(projectTaskListGet(obj));
      }
    },
    [userId, selectedCouncil, selectedProject, pageSetting, councilData]
  );

  const showTaskDetails = useCallback(() => {
    if (isGatewayTaskSelected) {
      const url = new URL(window.location.href);

      if (!url.searchParams.has('gatewayTask')) return;

      const selectedTask = sortedArray.filter((item) => item.id === selectedTaskId)[0];

      if (!selectedTask?.hasAdditionalData && selectedTask?.id) {
        dispatch(getTaskAdditionalData({
          projectId: selectedProject.id,
          taskId: selectedTask.id,
          activeTab: activeTask,
          cb: (newTaskItem) => {}
        }));

        return;
      }

      if (selectedTask) {
        dispatch(
          appSliderSettingSet({
            ...sliderSetting,
            show: true,
            height: "300",
            width: "540",
            header: (
              <ApprovalTaskHeader
                task={selectedTask}
                activeTab={activeTask}
                activePage={pageSetting.current}
              />
            ),
            component: (
              <ApprovalTaskBody
                taskId={selectedTaskId}
                blockFunctionality={
                  selectedCouncil?.name === "Ford"
                    ? canManageTasks
                    : blockFunctionality
                }
              />
            ),
            taskWindowDetails: {
              projectId: selectedProject.id,
              activeTab: activeTask,
            },
          })
        );
      }

      return;
    }

    if (selectedTaskId) {
      const selectedTask = sortedArray.filter((item) => item.id === selectedTaskId)[0];

      if (!selectedTask?.hasAdditionalData && selectedTask?.id) {
        dispatch(getTaskAdditionalData({
          projectId: selectedProject.id,
          taskId: selectedTask.id,
          activeTab: activeTask,
          cb: (newTaskItem) => {}
        }));

        return;
      }

      if (
        selectedTask &&
        selectedTask.projects_task_assignments.length &&
        activeTask === "all_task"
      ) {
        const obj = selectedTask.projects_task_assignments.filter(
          (asign) => asign.id === taskAsignementId
        )[0];

        if (obj) {
          dispatch(appSelectedSet(`task${obj.id}`));
          dispatch(
            appSliderSettingSet({
              ...sliderSetting,
              show: true,
              height: "300",
              width: "540",
              header: (
                <TaskInfoHeaderComponentV2
                  taskId={obj.projects_task_id}
                  projectId={selectedTask.project_id}
                  assignmentId={obj.id}
                  completed={obj.completed}
                />
              ),
              component: (
                <TaskInfoComponentV2
                  task={selectedTask}
                  assignment={obj}
                  blockFunctionality={
                    selectedCouncil?.name === "Ford"
                      ? canManageTasks
                      : blockFunctionality
                  }
                />
              ),
              taskWindowDetails: {
                projectId: selectedTask.project_id,
                activeTab: activeTask,
              },
            })
          );
        }
        setShouldOpen(selectedTask?.id);
      }
    }
  }, [sortedArray, isGatewayTaskSelected, selectedTaskId, selectedProject, activeTask]);

  useEffect(() => {
    showTaskDetails();
  }, [sortedArray]);

  if (fetchingTasks) {
    return (
      <div>
        <LoadingComponent customText="Fetching project tasks..." />
      </div>
    );
  }

  return (
    <div className={styles.tasksWrp}>
      <div className={styles.HeaderWrp}>
        <h4 className={styles.taskHeaderText}>
          {" "}
          {isFerminichCouncil("Project Tasks", "Milestones", selectedCouncil)}
        </h4>
        {selectedCouncil?.name === "Firmenich" &&
        selectedProject?.stage_template?.default_template ? (
          <></>
        ) : (
          <div className={styles.taskSwitchWrp}>
            <div
              className={classNames(
                styles.taskTab,
                activeTask === "my_task" && styles.active
              )}
              onClick={(e) => {
                handleSwitchClick(e, "my_task");
              }}
            >
              MY TASKS
            </div>
            <div
              className={classNames(
                styles.taskTab,
                activeTask === "all_task" && styles.active
              )}
              onClick={(e) => {
                handleSwitchClick(e, "all_task");
              }}
            >
              All tasks
            </div>
          </div>
        )}
        {canManageTasks && !blockFunctionality && (
          <Button
            className={styles.newTask}
            btn={BtnType.REGULAR}
            icon="icn-add"
            onClick={handleAddNewTaskClick}
          >
            Add new task
          </Button>
        )}
      </div>
      <div className={styles.container}>
        <div className={styles.taskRowHeader}>
          <div className={classNames(styles.taskcol, styles.colSorting)} />
          <div className={classNames(styles.taskcol, styles.colCheck)} />
          <div
            className={classNames(styles.colTask)}
            onClick={() => handleSorting("name", "string")}
          >
            Task
            {drowSortingIcon("item")}
          </div>
          {checkIfFirmenich(selectedCouncil, selectedProject) && (
            <div className={classNames(styles.colTask)}>Type</div>
          )}
          <div className={classNames(styles.colActivity)}>Activity</div>
          <div className={classNames(styles.colLastActivity)} />
          {checkIfFirmenich(selectedCouncil, selectedProject) ? (
            <></>
          ) : (
            <div
              className={classNames(styles.taskcol, styles.colStage)}
              onClick={() => handleSorting("stageOrder", "number")}
            >
              Stage
              {drowSortingIcon("stageOrder")}
            </div>
          )}

          <div className={classNames(styles.taskcol, styles.colAssign)}>
            Assigned
          </div>
          <div className={classNames(styles.taskcol, styles.colDue)}>Due</div>
          <div className={classNames(styles.taskcol, styles.colmore)} />
          <div className={classNames(styles.taskcol, styles.colmore)} />
          <div className={classNames(styles.taskcol, styles.colOther)} />
        </div>
        {!dragDrop && (
          <Container
            dropPlaceholder={{ className: styles.dropPlaceholder }}
            onDrop={(e) => {
              handleDragDrop(e);
            }}
            groupName="col"
          >
            {sortedArray.length > 0 &&
              sortedArray.map((item) => (
                <TaskRowComponent
                  activeTab={activeTask}
                  patientOrg={patientOrg}
                  key={item.id}
                  item={item}
                  shouldOpen={shouldOpen}
                  activePage={pageSetting.current}
                  blockFordOnly={blockFordOnly}
                  councilRole={councilRole}
                  currentUserProjRole={currentUserProjRole}
                />
              ))}
          </Container>
        )}
        {tasksTotal && tasksTotal > 20 ? (
          <div className="w-100 d-flex justify-content-center mt-4 mb-5">
            <Pagination {...pageSetting} onChange={handlePageChange} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(TasksComponent);

import React from "react";
import { useSnackbar } from "notistack";
import { BtnType, Button } from "../../../../primitives";
import { useModelPopup } from "../../../../../common/hooks";
import ThemeRowComponent from "./themeRow.component";
import AddToThemeComponent from "../../../../themes/themesCommon/addCompanyToTheme";
import styles from "./themes.module.scss";

const ThemesComponent = ({ company, selectedCouncil, sessionRole }) => {
  const { enqueueSnackbar } = useSnackbar();
  const themes = company?.related_themes;
  const popup = useModelPopup();
  const isMobile = false;
  const handleAddTheme = () => {
    popup.show({
      title: `Add To ${
        selectedCouncil?.settings?.themes_display_name_singular || "Theme"
      }`,
      component: (
        <AddToThemeComponent
          company={company}
          selectedCouncil={selectedCouncil}
          enqueueSnackbar={enqueueSnackbar}
          fromCompanyProfile
        />
      ),
    });
  };

  return (
    <>
      <div className={styles.wrapper}>
        {sessionRole !== "viewer" && (
          <Button
            btn={BtnType.FRAME_LESS}
            className={styles.addNewTheme}
            onClick={handleAddTheme}
            icon="icn-add"
          >
            ADD TO{" "}
            {selectedCouncil?.settings?.themes_display_name_singular || "THEME"}
          </Button>
        )}

        <div className={styles.grid}>
          <div className={styles.gridBody}>
            {themes?.length ? (
              themes
                .slice(0, 5)
                .map((theme) => (
                  <ThemeRowComponent key={theme.theme_id} theme={theme} />
                ))
            ) : (
              <div className="text-center p-4">
                This company is not yet part of a{" "}
                {selectedCouncil?.settings?.themes_display_name_singular ||
                  "Theme"}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ThemesComponent;

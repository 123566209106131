import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { Pagination, pageSettings } from "../../../../primitives";
import styles from "./projects.module.scss";
import { clearProjectList } from "modules/projects/project/project.action";
import { companyGetProjectTasks } from "../../company.action";
// import NewProjectModal from "../../../../../common/components/newProjectModal";
import { useWindowSize } from "../../../../../common/hooks/useWindowSize";
import DesktopProjectRow from "./desktopProjectRow.component";
import MobileProjectRow from "./mobileProjectRow.component";
import NewProjectV3 from "modules/projects/newProjectv3";

const ProjectsComponent = ({ company, isAdmin, sessionRole }) => {
  const dispatch = useDispatch();
  const [flag] = useState(true);
  const [pageSetting, setPageSetting] = useState(pageSettings(5));
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { isMobile } = useWindowSize();

  const handlePagingChange = (page) => {
    if (page > pageSetting.current) {
      setStartIndex(endIndex);
      setEndIndex(5 * page);
    } else {
      setStartIndex(page > 1 ? 5 * (page - 1) : 0);
      setEndIndex(5 * page);
    }
    setPageSetting((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const {
    projectsReducer: {
      projectReducer: { projectListMeta },
    },
    companiesReducer: {
      companyReducer: { companyProjectTasks },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    setPageSetting((prev) => ({
      ...prev,
      total: company.projects ? company.projects.length : 0,
    }));
  }, [projectListMeta]);

  useEffect(() => {
    if (company?.id) {
      dispatch(
        companyGetProjectTasks({
          company_id: company.id,
          enqueueSnackbar,
          isPatientOrganization:
            history.location.pathname.indexOf("patient_organizations") > 0,
        })
      );
    }
  }, []);

  useEffect(
    () => () => {
      dispatch(clearProjectList());
    },
    []
  );

  return (
    <>
      {flag && (
        <div className={styles.wrapper}>
          {sessionRole !== "viewer" && (
            <NewProjectV3
              isAdmin={isAdmin}
              wizardType="company"
              additionalData={company}
              btnName="New Project"
              className={styles.addNewProject}
            />
          )}

          <div className={styles.grid}>
            {companyProjectTasks?.length && !isMobile ? (
              <div className={styles.gridHeader}>
                <span className={styles.progress}>PROGRESS</span>
                <span className={styles.status}>STATUS</span>
              </div>
            ) : null}
            <div className={styles.gridBody}>
              {companyProjectTasks?.length ? (
                companyProjectTasks
                  .slice(startIndex, endIndex)
                  .map((project) =>
                    isMobile ? (
                      <MobileProjectRow
                        key={project.id || project.project?.id}
                        project={project}
                      />
                    ) : (
                      <DesktopProjectRow
                        key={project.id || project.project?.id}
                        project={project}
                      />
                    )
                  )
              ) : (
                <div className="text-center p-4">Create a Project</div>
              )}
            </div>
          </div>
          {companyProjectTasks?.length > 5 ? (
            <div className={styles.paginationCon}>
              <Pagination {...pageSetting} onChange={handlePagingChange} />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};
export default ProjectsComponent;

import { pageMeta } from "../../../common/httpCall";
import {
  GET_COMPANY_INVITED_CONTACTS_ATTEMPT,
  GET_COMPANY_INVITED_CONTACTS_SUCCESS,
  POST_COMPANY_CONTACTS_SUCCESS,
  EDIT_COMPANY_CONTACTS_ATTEMPT,
  EDIT_COMPANY_CONTACTS_SUCCESS,
  DELETE_COMPANY_CONTACTS_SUCCESS,
} from "./adminMembers.action";

const initState = {
  companyInvitedContacts: [],
  companyInvitedContactsNr: {},
  loading: false,
  shouldUpdate: false,
  memberData: {},
  shouldRedirectToMainFromContacts: false,
};
const adminMembersReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_COMPANY_INVITED_CONTACTS_ATTEMPT: {
      return {
        ...state,
        loading: true,
        shouldRedirectToMainFromContacts: false,
      };
    }

    case GET_COMPANY_INVITED_CONTACTS_SUCCESS: {
      return {
        ...state,
        companyInvitedContacts: payload.response,
        companyInvitedContactsNr: pageMeta(payload),
        loading: false,
        shouldUpdate: false,
      };
    }

    case POST_COMPANY_CONTACTS_SUCCESS: {
      return {
        ...state,
        shouldUpdate: true,
      };
    }
    case EDIT_COMPANY_CONTACTS_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case EDIT_COMPANY_CONTACTS_SUCCESS: {
      return {
        ...state,
        memberData: payload.response,
        shouldUpdate: true,
        loading: false,
        shouldRedirectToMainFromContacts: true,
      };
    }

    case DELETE_COMPANY_CONTACTS_SUCCESS: {
      return {
        ...state,
        shouldUpdate: true,
      };
    }
    default:
      return state;
  }
};

export default adminMembersReducer;

import React, { memo, useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";

import { checkViewerRole } from "common/checkers/viewerChecker";
import styles from "./upcomingTask.module.scss";
import {
  Switch,
  Button,
  BtnType,
  Pannel,
} from "../../../../primitives";
import { HeaderOptions } from "./upcomingTask.constatnt";
import { useModelPopup } from "../../../../../common/hooks";
import TaskNewComponent from "../../tasks/taskNew";
import { checkIfFirmenich, isFerminichCouncil } from "../../isFerminichCouncil";
import LoadingComponent from "modules/primitives/loading/loading.component";
import UpcomingTaskRow from "modules/projects/project/overview/upcommingTasks/upcomingTaskRow";
import { getUpcomingTasks } from "modules/projects/project/overview/overview.action";

const UpcomingTasksComponent = ({ canManageTasks, sessionId, selectedProject }) => {
  const {
    councilReducer: { selectedCouncil },
    projectsReducer: {
      overviewReducer: { upcomingTasks: { fetching, data, hasLoadMore }},
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(HeaderOptions[0].value);
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    dispatch(getUpcomingTasks({
      userId: sessionId,
      enqueueSnackbar,
      projectId: selectedProject.id,
    }));
  }, []);

  const isFirmenich = useMemo(() => checkIfFirmenich(selectedCouncil, selectedProject), [selectedCouncil, selectedProject]);

  const handleSwitchChange = (value) => {
    dispatch(getUpcomingTasks({
      userId: value === "my_task" ? sessionId : null,
      enqueueSnackbar,
      projectId: selectedProject.id,
    }));

    setSelectedTab(value);
  };

  const handleAddNewTaskClick = () => {
    popup.show({
      title: "Add a task",
      component: <TaskNewComponent currentPage={1} activeTab={selectedTab} upcomingTaskInstance />,
    });
  };

  const getRightPanel = useCallback(
    () => (
      <>
        {isFirmenich ? null : (
          <Switch
            tabs={HeaderOptions}
            onChange={handleSwitchChange}
            selected={selectedTab}
          />
        )}
        {canManageTasks && !blockFunctionality && (
          <Button
            className={styles.newTask}
            btn={BtnType.REGULAR}
            icon="icn-add"
            onClick={handleAddNewTaskClick}
          >
            {isFerminichCouncil("New task", "New Milestone", selectedCouncil)}
          </Button>
        )}
      </>
    ),
    [selectedTab, isFirmenich]
  );

  const renderTasks = useCallback(() => {
    if (!data?.length) {
      return (
        <p className="text-center mt-4">No upcoming tasks.</p>
      )
    }

    return (
      <>
        {
          data.map((task, index) => (
            <UpcomingTaskRow
              key={`${task.id}-${index}`}
              task={task}
              isFirmenich={isFirmenich}
              isFord={selectedCouncil?.name === "Ford"}
              canManageTasks={canManageTasks}
              projectId={selectedProject.id}
              activeTab={selectedTab}
              blockFunctionality={blockFunctionality}
              sessionId={sessionId}
            />
          ))
        }
      </>
    )
  }, [data, selectedCouncil?.name, isFirmenich, selectedProject?.id, selectedTab, blockFunctionality, sessionId]);

  const title = useMemo(() => isFerminichCouncil(
    "Upcoming Tasks",
    "Upcoming Milestones",
    selectedCouncil
  ), [selectedCouncil]);

  return (
    <Pannel
      title={title}
      padding="0"
      rightContent={getRightPanel()}
    >
      <div className={styles.wrapper}>
        <div className={styles.grid}>
          <div className={styles.gridHeader}>
            <div className={styles.select} />
            <div className={styles.task}>
              {isFirmenich
                ? "NEXT MILESTONE"
                : "Task"}
            </div>
            {isFirmenich ? (
              <></>
            ) : (
              <div className={styles.stage}>Stage</div>
            )}
            <div className={styles.due}>Due</div>
            <div className={styles.assigned}>Assigned</div>
          </div>
          <div className={styles.gridBody}>
            {fetching ? <LoadingComponent customText="Fetching tasks..." /> : renderTasks()}
            {hasLoadMore && (
              <div className={styles.showAll}>
                <Link to={`./task${selectedTab === 'my_task' ? '?tab=my_task' : ''}`}>
                  Show all tasks
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </Pannel>
  );
};

export default memo(UpcomingTasksComponent);

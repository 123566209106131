import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import styles from "./projectCompanies.module.scss";
import { UserInline, UserInlineTypes } from "../../../primitives";
import { projectStates } from "./projectCompanies.constant";

const DesktopProjectCompanyRow = ({
  project,
  handleProjectClick,
  redirectToProfile,
}) => (
  <div
    className={
      project.current_user_has_access ? styles.gridRow : styles.disabledGridRow
    }
    onClick={(evt) => handleProjectClick(evt, project)}
  >
    <div className={classNames(styles.companyValue, styles.company)}>
      {project.project?.name || "-"}
    </div>
    <div className={styles.stage}>
      <div className={styles[project.stage?.name?.toLowerCase()]}>
        {project.stage?.name || "-"}
      </div>
    </div>
    <div className={styles.owner}>
      {project.project?.created_by?.name ? (
        <div>
          <UserInline
            local
            userId={project.project?.created_by?.id}
            first={project.project?.created_by?.name.split(" ")[0]}
            last={project.project?.created_by?.name.split(" ")[1]}
            type={UserInlineTypes.WITH_NAME}
            className={styles.name}
            avatar={project.project?.created_by?.avatar}
            noNavigation
          />
        </div>
      ) : (
        "-"
      )}
    </div>
    <div className={classNames(styles.access)}>
      {project.project?.visibility?.charAt(0).toUpperCase()
        + project.project?.visibility?.slice(1).split("_").join(" ") || "-"}
    </div>
    <div className={styles.task}>{project.project_next_task?.name || "-"}</div>
    <div className={styles.assigned}>
      {project.project_next_task?.assigned_to_name || "-"}
    </div>
    <div className={styles.due}>
      {project.project_next_task?.due_date
        ? format(new Date(project.project_next_task?.due_date), "MMM dd, yyyy")
        : "-"}
    </div>
    <span
      className={classNames(styles[project.project.status || ""], styles.due)}
    >
      {project.project.status ? projectStates[project.project.status] : "-"}
    </span>
  </div>
);

export default DesktopProjectCompanyRow;

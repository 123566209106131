import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import Alert from "@mui/material/Alert";

import { ModalFooter, ModalBody } from "../../../../../application/modal";
import {
  projectStagePut,
  projectStageNew,
  projectStageRemove,
} from "../stages.action";
import {
  Button,
  BtnType,
  TextBox,
  Label,
  TextArea,
} from "../../../../primitives";
import { initModel, validation } from "../stages.constant";
import styles from "./editStage.module.scss";
import { useModelPopup } from "../../../../../common/hooks";

const EditStageComponent = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { stage, stagesList, canBeRemoved = true } = props;
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [model, setmodel] = useState(initModel);
  const [showCantRemoveAlert, setShowCantRemoveAlert] = useState(false);
  const formRef = useRef();
  const {
    projectsReducer: {
      projectReducer: { selectedProject },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (stage) {
      setmodel((state) => ({
        ...state,
        name: stage.name,
        description: stage.description ? stage.description : "",
      }));
    }
  }, [stage]);

  const handleCancelClick = (e) => {
    popup.hide();
  };

  const handleSaveClick = (e) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleRemoveClick = () => {
    if (canBeRemoved) {
      dispatch(
        projectStageRemove({
          projectId: selectedProject.id,
          stageId: stage.id,
          enqueueSnackbar,
        }),
      );
      popup.hide();
    } else {
      setShowCantRemoveAlert(true);
    }
  };

  const handleOnSubmit = (value) => {
    if (stage && stage.id) {
      const stageAlreadyExists = stagesList.find(
        (stage) => stage.name.toLowerCase() === value.name.toLowerCase(),
      );

      if (
        stageAlreadyExists
        && stageAlreadyExists.description !== value.description
      ) {
        dispatch(
          projectStagePut({
            projectId: selectedProject.id,
            stageId: stage.id,
            stage: value,
            enqueueSnackbar,
          }),
        );

        popup.hide();

        return;
      }

      if (stageAlreadyExists) {
        enqueueSnackbar("Stage with this name already exists.", {
          variant: "error",
        });

        return;
      }

      dispatch(
        projectStagePut({
          projectId: selectedProject.id,
          stageId: stage.id,
          stage: value,
          enqueueSnackbar,
        }),
      );
    } else {
      const stageAlreadyExists = stagesList.some(
        (stage) => stage.name.toLowerCase() === value.name.toLowerCase(),
      );

      if (stageAlreadyExists) {
        enqueueSnackbar("Stage with this name already exists.", {
          variant: "error",
        });

        return;
      }

      dispatch(
        projectStageNew({
          projectId: selectedProject.id,
          stage: value,
          enqueueSnackbar,
        }),
      );
    }
    // dispatch(projectStagesListGet({ projectId: selectedProject.id, enqueueSnackbar }));
    popup.hide();
  };

  return (
    <>
      <ModalBody>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={model && model}
          validationSchema={validation && validation}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            handleOnSubmit(values);
            resetForm();
          }}
        >
          {({ handleSubmit, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.editStageFormWrp}>
                <Label>Stage Name</Label>
                <TextBox
                  type="text"
                  name="name"
                  placeholder="Enter a stage name"
                  className={styles.textBox}
                  formProps={formikprops}
                />
                <Label>Description</Label>

                <TextArea
                  type="text"
                  name="description"
                  placeholder="Enter a stage description"
                  className={styles.textBox}
                  formProps={formikprops}
                  rows={6}
                />
              </div>
            </form>
          )}
        </Formik>
        {showCantRemoveAlert && (
          <Alert severity="warning">
            This Stage cannot be removed because it contains Companies or Tasks.
            Only empty Stages can be removed from a Project. Please move
            Companies and Tasks to a different Stage before trying to remove the
            Stage from the Project.
          </Alert>
        )}
      </ModalBody>
      <ModalFooter>
        {stage && stage.id && (
          <div className={styles.removeWrp}>
            <Button btn={BtnType.FRAME_LESS} onClick={handleRemoveClick}>
              Remove
            </Button>
          </div>
        )}
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button type="submit" btn={BtnType.REGULAR} onClick={handleSaveClick}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
};

export default React.memo(EditStageComponent);

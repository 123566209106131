import React from 'react';
import GaugeChart from 'react-gauge-chart';

const projectStatesText = {
  open: "On Track",
  closed: "Closed",
  at_risk: "At Risk",
  off_track: "Off Track",
  on_hold: "On Hold"
};

const ProjectStateGauge = ({ status }) => {
  // Map status to a normalized needle position (0 to 1)
  const getNeedlePosition = () => {
    switch (projectStatesText[status]) {
      case "On Track":
        return 0.833;
      case "At Risk":
        return 0.5;
      case "Off Track":
        return 0.166;
      default:
        return 0.5;
    }
  };

  if (status === 'closed') {
    return (
      <div style={{ color: "white", fontSize: 13 }}>
        {projectStatesText[status]}
      </div>
    )
  }

  return (
    <div className="d-flex align-items-center justify-content-center flex-column">
      <div style={{ color: 'white', fontSize: 12 }}>
        {projectStatesText[status]}
      </div>
      <GaugeChart
        id="dial"
        nrOfLevels={3}
        colors={["rgb(214 0 28)", "rgb(255 157 65)", "rgb(0 207 54)"]}
        arcWidth={0.25}
        percent={getNeedlePosition()}
        arcPadding={0.02}
        hideText
        animate={false}
        style={{ maxWidth: '100px' }}
        needleColor="white"
        needleBaseColor="white"
      />
    </div>
  );
};

export default React.memo(ProjectStateGauge);

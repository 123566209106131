import React, { useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { isCreator } from "common/checkers/isCreator";
import { Icon, smSize } from "../../../../../common/icon";
import styles from "./styles.module.scss";
import {
  CheckBox,
  CheckType,
  Label,
  Loading,
  MoreOptions,
} from "../../../../primitives";
import { toolTips } from "./constant";
import { ACTIVITY_OPTIONS } from "../../../../../common/constants";

const CheckListComponent = (props) => {
  const {
    item,
    handleEditClick,
    handleSubmitDetail,
    blockFunctionality = false,
    handleDeleteClick,
  } = props;
  const {
    companiesReducer: {
      companyReducer: { loading },
    },
  } = useSelector((state) => state);
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);

  useEffect(() => {
    if (item) {
      if (isCreator(item?.creator?.user_id)) {
        setHasAccessToEdit(true);
      }
    }
  }, [item]);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  const handleMoreOptionClick = (option) => {
    if (option === 0) {
      handleEditClick(item);
    }

    if (option === 1) {
      handleDeleteClick(item);
    }
  };

  const check = (checked, value) => {
    let optionSelected = item.option_selected;
    let optionScoreSelected = item.options_score_selected;
    if (checked) {
      if (item.multiselect) {
        optionSelected.push(value);
        optionScoreSelected.push(value);
      } else {
        optionSelected = [value];
        optionScoreSelected = [value];
      }
    }
    if (!checked) {
      optionSelected = item.option_selected.filter((e) => e !== value);
    }

    const values = {
      field_value: item.field_value,
      title: item.field_name,
      type: item.field_type,
      option_selected: optionSelected,
      multiselect: item.multiselect,
      custom_field_group_id: item.custom_field_group_id,
      option_values: item.option_values,
      options_score_values: item.options_score_values,
      options_score_selected: optionScoreSelected,
    };

    handleSubmitDetail(values, item, null, { value, checked });
  };

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [item?.field_type]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <h5 className={styles.title}>{item?.field_name || "Checklist"}</h5>
        <div className={styles.rightWrp}>
          <div className={styles.colMore}>
            {!blockFunctionality && hasAccessToEdit ? (
              <MoreOptions
                options={ACTIVITY_OPTIONS}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            ) : (
              <Tooltip
                title={
                  !hasAccessToEdit && !blockFunctionality
                    ? "You have not access to edit this field."
                    : "You have View-Only access. To Edit Custom fields, please ask your Admin to upgrade your account to Standard access."
                }
                placement="top"
              >
                <MoreOptions
                  options={ACTIVITY_OPTIONS}
                  className={styles.moreOptWrp}
                  onClick={handleMoreOptionClick}
                  customSvgIcon
                />
              </Tooltip>
            )}
          </div>
        </div>
      </div>

      <div ref={ref} className={styles.checkWrp}>
        {loading && (
          <div style={{ height: `${height}px` }}>
            <Loading />
          </div>
        )}
        {!loading
          && item
          && item.option_values.map((box, idx) => (
            <div key={`${box}_${idx}`} className={styles.formElement}>
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={item.option_selected.includes(box)}
                onChange={(checked) => {
                  check(checked, box);
                }}
                disabled={blockFunctionality}
              />
              <Label>
                {box}
                {toolTips[box] ? (
                  <Tooltip title={toolTips[box]} arrow placement="top">
                    <span className={styles.iconQuestion}>
                      <Icon {...smSize} icon="icn-table-message" />
                    </span>
                  </Tooltip>
                ) : null}
              </Label>
            </div>
          ))}
      </div>

      <div className={styles.infoWrp}>
        <div className={styles.dataRow}>
          <div className={styles.label}>Created by</div>
          <div className={styles.value}>
            <Link to={`/people/internal/profile/${item?.user_id}`}>
              {item && item?.creator && item?.creator.full_name}
            </Link>
          </div>
        </div>
        <div className={styles.date}>
          {" "}
          {item && format(new Date(item.created_at), "MM/dd/yyyy")}
        </div>
      </div>
    </div>
  );
};
export default React.memo(CheckListComponent);

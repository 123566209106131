import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useSnackbar } from "notistack";

import styles from "./totalFunding.module.scss";

import { Icon, smSize } from "../../../../../../common/icon";
import {
  queryStringUrlReplacement,
  useQuery,
} from "../../../../../../common/helper";

const TotalFundingFilter = ({ expanded, handleExpand, disabled }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const fundingFrom = useQuery().get("fundingFrom");
  const fundingTo = useQuery().get("fundingTo");

  const [showMore, setShowMore] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [fundingValues, setFundingValues] = useState({
    fundingFrom: "$0",
    fundingTo: "$Any Amount",
  });

  useEffect(() => {
    if (disabled) {
      setCollapsed(false);
      return;
    }
    setCollapsed(expanded);
  }, [expanded, disabled]);

  useEffect(() => {
    setFundingValues({
      ...fundingValues,
      fundingFrom: fundingFrom ? `$${fundingFrom}` : "$0",
      fundingTo: fundingTo ? `$${fundingTo}` : "$Any Amount",
    });

    return () => null;
  }, [fundingFrom, fundingTo]);

  useEffect(() => {
    if (fundingFrom) {
      const value = Number(fundingFrom.replace(/\D/g, ""));
      setShowMore(value > 5000000);
    } else {
      setShowMore(false);
    }

    return () => null;
  }, [fundingFrom]);

  const handleCollapseClick = () => {
    if (disabled) {
      return;
    }

    if (!collapsed) {
      handleExpand("totalFunding");
    }
    setCollapsed(!collapsed);
  };

  const handleOnChange = (evt, prop) => {
    const { value } = evt.target;
    const cleanValue = value.replace(/\D/g, "");

    setFundingValues({
      ...fundingValues,
      [prop]: `$${Number(cleanValue).toLocaleString("en-US")}`,
    });
  };

  const handleBlur = (evt, prop) => {
    if (
      (evt.target.value === "$Any Amount"
        && fundingValues.fundingTo === "$Any Amount")
      || (prop === "fundingFrom" && `$${fundingFrom}` === evt.target.value)
      || (prop === "fundingTo" && `$${fundingTo}` === evt.target.value)
    ) {
      return;
    }

    if (prop === "fundingFrom" && fundingValues.fundingTo !== "$Any Amount") {
      const value = Number(evt.target.value.replace(/\D/g, ""));
      const fundingToAsNumber = Number(
        fundingValues.fundingTo.replace(/\D/g, ""),
      );

      if (value > fundingToAsNumber) {
        enqueueSnackbar(
          "Funding From value should be less than Funding To value",
          {
            variant: "error",
          },
        );
        return;
      }
    }

    if (prop === "fundingTo" && fundingValues.fundingTo !== "$Any Amount") {
      const value = Number(evt.target.value.replace(/\D/g, ""));
      const fundingFromAsNumber = Number(
        fundingValues.fundingFrom.replace(/\D/g, ""),
      );

      if (value < fundingFromAsNumber) {
        enqueueSnackbar(
          "Funding To value should be bigger than Funding From value",
          {
            variant: "error",
          },
        );

        return;
      }
    }

    const valueWithout$ = evt.target.value.replace("$", "");
    let search = queryStringUrlReplacement(
      history.location.search,
      prop,
      valueWithout$,
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        [prop]: valueWithout$,
        page: 1,
      },
    });
  };

  const handleKeyPress = (evt, prop) => {
    const key = evt.keyCode || evt.which;

    if (key === 13) {
      handleBlur(evt, prop);
    }
  };

  const handleFundingValueSelect = (prop, fundingValue) => {
    if (
      (fundingValue === "Any Amount"
        && fundingValues.fundingTo === "$Any Amount")
      || (prop === "fundingFrom"
        && Number(fundingValue.replace(/\D/g, ""))
          === Number(fundingValues.fundingFrom.replace(/\D/g, "")))
      || (prop === "fundingTo"
        && Number(fundingValue.replace(/\D/g, ""))
          === Number(fundingValues.fundingTo.replace(/\D/g, "")))
    ) {
      return;
    }

    if (prop === "fundingFrom" && fundingValues.fundingTo !== "$Any Amount") {
      const currValueAsNumber = Number(fundingValue.replace(/\D/g, ""));
      const fundingToAsNumber = Number(
        fundingValues.fundingTo.replace(/\D/g, ""),
      );

      if (currValueAsNumber > fundingToAsNumber) {
        return;
      }
    }

    if (prop === "fundingTo") {
      const currValueAsNumber = Number(fundingValue.replace(/\D/g, ""));
      const fundingFromAsNumber = Number(
        fundingValues.fundingFrom.replace(/\D/g, ""),
      );

      if (currValueAsNumber < fundingFromAsNumber && currValueAsNumber !== 0) {
        return;
      }
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      prop,
      fundingValue,
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        [prop]: fundingValue,
        page: 1,
      },
    });
  };

  const shouldBeDisabledFrom = useCallback(
    (value) => {
      const currValueAsNumber = Number(value.replace(/\D/g, ""));

      if (fundingValues.fundingTo === "$Any Amount") {
        return false;
      }

      return (
        currValueAsNumber > Number(fundingValues.fundingTo.replace(/\D/g, ""))
      );
    },
    [fundingValues],
  );

  const shouldBeDisabledTo = useCallback(
    (value) => {
      const fundingFrom = Number(fundingValues.fundingFrom.replace(/\D/g, ""));
      const currValueAsNumber = Number(value.replace(/\D/g, ""));

      if (fundingFrom > 1000000) {
        return currValueAsNumber < fundingFrom;
      }

      return false;
    },
    [fundingValues],
  );

  const handleShowMoreOptions = useCallback(() => {
    setShowMore(!showMore);
  }, [showMore]);

  return (
    <div className={`${styles.itemWrapper} ${disabled ? styles.disabled : ''}`} id="totalFunding">
      <div className={styles.itemTitle} onClick={handleCollapseClick}>
        <span>Total Funding</span>
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(styles.icon, !collapsed && styles.collapse)}
          onClick={handleCollapseClick}
        />
      </div>
      {collapsed ? (
        <div className={classNames("d-flex", styles.withPaddingLeft)}>
          <div
            className={classNames("d-flex flex-column", styles.fundingField)}
          >
            <div className="d-flex flex-column">
              <label htmlFor="fundingFrom">From</label>
              <input
                id="fundingFrom"
                value={fundingValues.fundingFrom}
                onChange={(evt) => handleOnChange(evt, "fundingFrom")}
                onBlur={(evt) => handleBlur(evt, "fundingFrom")}
                onKeyPress={(evt) => handleKeyPress(evt, "fundingFrom")}
                pattern="[0-9.]+"
              />
            </div>
            <div className={`d-flex flex-column ${styles.fundingValues}`}>
              <span
                onClick={() => handleFundingValueSelect("fundingFrom", "0")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingFrom === "$0"
                        ? styles.activeFunding
                        : ""
                    }
                    ${shouldBeDisabledFrom("0") ? styles.disabledFunding : ""}
                  `}
              >
                $0
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingFrom", "100,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingFrom === "$100,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledFrom("100,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $100,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingFrom", "200,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingFrom === "$200,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledFrom("200,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $200,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingFrom", "500,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingFrom === "$500,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledFrom("500,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $500,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingFrom", "1,000,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingFrom === "$1,000,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledFrom("1,000,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $1,000,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingFrom", "2,000,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingFrom === "$2,000,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledFrom("2,000,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $2,000,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingFrom", "5,000,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingFrom === "$5,000,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledFrom("5,000,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $5,000,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingFrom", "10,000,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingFrom === "$10,000,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledFrom("10,000,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $10,000,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingFrom", "50,000,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingFrom === "$50,000,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledFrom("50,000,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $50,000,000
              </span>
              {showMore && (
                <>
                  <span
                    onClick={() => handleFundingValueSelect("fundingFrom", "100,000,000")}
                    className={`
                          ${styles.fundingValue}
                          ${
                            fundingValues.fundingFrom === "$100,000,000"
                              ? styles.activeFunding
                              : ""
                          }
                          ${
                            shouldBeDisabledFrom("100,000,000")
                              ? styles.disabledFunding
                              : ""
                          }
                      `}
                  >
                    $100,000,000
                  </span>
                  <span
                    onClick={() => handleFundingValueSelect("fundingFrom", "200,000,000")}
                    className={`
                          ${styles.fundingValue}
                          ${
                            fundingValues.fundingFrom === "$200,000,000"
                              ? styles.activeFunding
                              : ""
                          }
                          ${
                            shouldBeDisabledFrom("200,000,000")
                              ? styles.disabledFunding
                              : ""
                          }
                      `}
                  >
                    $200,000,000
                  </span>
                  <span
                    onClick={() => handleFundingValueSelect("fundingFrom", "500,000,000")}
                    className={`
                          ${styles.fundingValue}
                          ${
                            fundingValues.fundingFrom === "$500,000,000"
                              ? styles.activeFunding
                              : ""
                          }
                          ${
                            shouldBeDisabledFrom("500,000,000")
                              ? styles.disabledFunding
                              : ""
                          }
                      `}
                  >
                    $500,000,000
                  </span>
                  <span
                    onClick={() => handleFundingValueSelect("fundingFrom", "1,000,000,000")}
                    className={`
                          ${styles.fundingValue}
                          ${
                            fundingValues.fundingFrom === "$1,000,000,000"
                              ? styles.activeFunding
                              : ""
                          }
                          ${
                            shouldBeDisabledFrom("1,000,000,000")
                              ? styles.disabledFunding
                              : ""
                          }
                      `}
                  >
                    $1,000,000,000
                  </span>
                </>
              )}
              <span
                onClick={handleShowMoreOptions}
                className={`${styles.fundingValue} ${styles.lowerCase}`}
              >
                + show {showMore ? "less" : "more"}
              </span>
            </div>
          </div>
          <span className={styles.fundingDelimiter}>-</span>
          <div
            className={classNames("d-flex flex-column", styles.fundingField)}
          >
            <div className="d-flex flex-column">
              <label htmlFor="fundingTo">To</label>
              <input
                id="fundingTo"
                value={fundingValues.fundingTo}
                onChange={(evt) => handleOnChange(evt, "fundingTo")}
                onBlur={(evt) => handleBlur(evt, "fundingTo")}
                onKeyPress={(evt) => handleKeyPress(evt, "fundingTo")}
                pattern="[0-9.]+"
              />
            </div>
            <div className={`d-flex flex-column ${styles.fundingValues}`}>
              <span
                onClick={() => handleFundingValueSelect("fundingTo", "1,000,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingTo === "$1,000,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledTo("1,000,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $1,000,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingTo", "2,000,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingTo === "$2,000,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledTo("2,000,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $2,000,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingTo", "5,000,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingTo === "$5,000,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledTo("5,000,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $5,000,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingTo", "10,000,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingTo === "$10,000,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledTo("10,000,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $10,000,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingTo", "50,000,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingTo === "$50,000,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledTo("50,000,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $50,000,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingTo", "100,000,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingTo === "$100,000,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledTo("100,000,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $100,000,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingTo", "200,000,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingTo === "$200,000,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledTo("200,000,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $200,000,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingTo", "500,000,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingTo === "$500,000,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledTo("500,000,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $500,000,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingTo", "1,000,000,000")}
                className={`
                    ${styles.fundingValue}
                    ${
                      fundingValues.fundingTo === "$1,000,000,000"
                        ? styles.activeFunding
                        : ""
                    }
                    ${
                      shouldBeDisabledTo("1,000,000,000")
                        ? styles.disabledFunding
                        : ""
                    }
                  `}
              >
                $1,000,000,000
              </span>
              <span
                onClick={() => handleFundingValueSelect("fundingTo", "Any Amount")}
                className={`${styles.fundingValue} ${
                  fundingValues.fundingTo === "$Any Amount"
                    ? styles.activeFunding
                    : ""
                }`}
              >
                Any Amount
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(TotalFundingFilter);

export const PRODUCT_SET_DOWNLOAD_PARAMS = "PRODUCT_SET_DOWNLOAD_PARAMS";
export const productSetDownloadParams = (payload) => ({
  type: PRODUCT_SET_DOWNLOAD_PARAMS,
  payload,
});

export const ADD_PRODUCT_TO_THEME = "ADD_PRODUCT_TO_THEME";
export const addProductToTheme = (payload) => ({
  type: ADD_PRODUCT_TO_THEME,
  payload,
});

export const ADD_PRODUCT_TO_THEME_SUCCESS = "ADD_PRODUCT_TO_THEME_SUCCESS";
export const addProductToThemeSuccess = (payload) => ({
  type: ADD_PRODUCT_TO_THEME_SUCCESS,
  payload,
});

export const ADD_PRODUCT_TO_LIST = "ADD_PRODUCT_TO_LIST";
export const addProductToList = (payload) => ({
  type: ADD_PRODUCT_TO_LIST,
  payload,
});

export const ADD_PRODUCT_TO_LIST_SUCCESS = "ADD_PRODUCT_TO_LIST_SUCCESS";
export const addProductToListSuccess = (payload) => ({
  type: ADD_PRODUCT_TO_LIST_SUCCESS,
  payload,
});

export const ADD_PRODUCT_TO_IDEA = "ADD_PRODUCT_TO_IDEA";
export const addProductToIdea = (payload) => ({
  type: ADD_PRODUCT_TO_IDEA,
  payload,
});

export const ADD_PRODUCT_TO_IDEA_SUCCESS = "ADD_PRODUCT_TO_IDEA_SUCCESS";
export const addProductToIdeaSuccess = (payload) => ({
  type: ADD_PRODUCT_TO_IDEA_SUCCESS,
  payload,
});

export const ADD_PRODUCT_TO_PROJECT = "ADD_PRODUCT_TO_PROJECT";
export const addProductToProject = (payload) => ({
  type: ADD_PRODUCT_TO_PROJECT,
  payload,
});

export const ADD_PRODUCT_TO_PROJECT_SUCCESS = "ADD_PRODUCT_TO_PROJECT_SUCCESS";
export const addProductToProjectSuccess = (payload) => ({
  type: ADD_PRODUCT_TO_PROJECT_SUCCESS,
  payload,
});

export const ADD_PRODUCT_OWNER = "ADD_PRODUCT_OWNER";
export const addProductOwner = (payload) => ({
  type: ADD_PRODUCT_OWNER,
  payload,
});

export const ADD_PRODUCT_OWNER_SUCCESS = "ADD_PRODUCT_OWNER_SUCCESS";
export const addProductOwnerSuccess = (payload) => ({
  type: ADD_PRODUCT_OWNER_SUCCESS,
  payload,
});

export const DELETE_PRODUCT_OWNER = "DELETE_PRODUCT_OWNER";
export const deleteProductOwner = (payload) => ({
  type: DELETE_PRODUCT_OWNER,
  payload,
});

export const DELETE_PRODUCT_OWNER_SUCCESS = "DELETE_PRODUCT_OWNER_SUCCESS";
export const deleteProductOwnerSuccess = (payload) => ({
  type: DELETE_PRODUCT_OWNER_SUCCESS,
  payload,
});

export const ADD_PRODUCT_CONTACT = "ADD_PRODUCT_CONTACT";
export const addProductContact = (payload) => ({
  type: ADD_PRODUCT_CONTACT,
  payload,
});

export const ADD_PRODUCT_CONTACT_SUCCESS = "ADD_PRODUCT_CONTACT_SUCCESS";
export const addProductContactSuccess = (payload) => ({
  type: ADD_PRODUCT_CONTACT_SUCCESS,
  payload,
});

export const EDIT_PRODUCT_CONTACT = "EDIT_PRODUCT_CONTACT";
export const editProductContact = (payload) => ({
  type: EDIT_PRODUCT_CONTACT,
  payload,
});

export const EDIT_PRODUCT_CONTACT_SUCCESS = "EDIT_PRODUCT_CONTACT_SUCCESS";
export const editProductContactSuccess = (payload) => ({
  type: EDIT_PRODUCT_CONTACT_SUCCESS,
  payload,
});

export const DELETE_PRODUCT_CONTACT = "DELETE_PRODUCT_CONTACT";
export const deleteProductContact = (payload) => ({
  type: DELETE_PRODUCT_CONTACT,
  payload,
});

export const DELETE_PRODUCT_CONTACT_SUCCESS = "DELETE_PRODUCT_CONTACT_SUCCESS";
export const deleteProductContactSuccess = (payload) => ({
  type: DELETE_PRODUCT_CONTACT_SUCCESS,
  payload,
});

export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const getProductDetails = (payload) => ({
  type: GET_PRODUCT_DETAILS,
  payload,
});

export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const getProductDetailsSuccess = (payload) => ({
  type: GET_PRODUCT_DETAILS_SUCCESS,
  payload,
});

export const EDIT_PRODUCT_DETAILS = "EDIT_PRODUCT_DETAILS";
export const editProductDetails = (payload) => ({
  type: EDIT_PRODUCT_DETAILS,
  payload,
});

export const EDIT_PRODUCT_DETAILS_SUCCESS = "EDIT_PRODUCT_DETAILS_SUCCESS";
export const editProductDetailsSuccess = (payload) => ({
  type: EDIT_PRODUCT_DETAILS_SUCCESS,
  payload,
});

export const UPDATE_PRODUCT_DETAILS = "UPDATE_PRODUCT_DETAILS";
export const updateProductDetails = (payload) => ({
  type: UPDATE_PRODUCT_DETAILS,
  payload,
});

export const EDIT_PRODUCT_DOCS = "EDIT_PRODUCT_DOCS";
export const editProductDocs = (payload) => ({
  type: EDIT_PRODUCT_DOCS,
  payload,
});

export const EDIT_PRODUCT_DOCS_SUCCESS = "EDIT_PRODUCT_DOCS_SUCCESS";
export const editProductDocsSuccess = (payload) => ({
  type: EDIT_PRODUCT_DOCS_SUCCESS,
  payload,
});

import React from "react";

import mainStyles from "../../../ideasWrapper.module.scss";
import styles from "./areasOfFocus.module.scss";
import { Icon, mdSize } from "../../../../../common/icon";
import { useModelPopup } from "../../../../../common/hooks";
import EditIdeaAreaOfFocus from "./editIdeaAreaOfFocus";

const IdeaProfileAreasOfFocus = ({
  selectedIdea,
  canEdit,
  selectedCouncil,
}) => {
  const popup = useModelPopup();

  const handleTheAreasOfFocusEdit = () => {
    popup.show({
      show: true,
      title: "Edit",
      component: <EditIdeaAreaOfFocus selectedIdea={selectedIdea} />,
    });
  };

  return (
    <div className="d-flex flex-column mt-4">
      <div
        className={`d-flex align-items-center justify-content-between ${mainStyles.blockHeader}`}
      >
        <span className={mainStyles.blockTitle}>Areas of Focus</span>
        {canEdit && (
          <Icon
            {...mdSize}
            icon="icn-edit-button"
            className={mainStyles.messageIcon}
            onClick={handleTheAreasOfFocusEdit}
          />
        )}
      </div>
      <div
        className={`${styles.areasOfFocusContent} ${mainStyles.blockContent}`}
      >
        {selectedIdea?.ideas_descriptions &&
          selectedIdea?.ideas_descriptions
            .filter((t) => t.category_type === "area_of_focus")
            .map((t) => (
              <div
                key={t.id}
                className={`${styles.category} ${styles.areasOfFocusCategories}`}
              >
                <span className={styles.name}>{t.section_name}</span>
                <span className={styles.content}>
                  {t.section_value?.length
                    ? t.section_value
                    : t.section_values?.length
                    ? t.section_values?.join(", ")
                    : "-"}
                </span>
              </div>
            ))}
        {selectedIdea?.resource_topics?.length ? (
          <div className={`mt-3 ${styles.category}`}>
            <span className={styles.name}>Technologies</span>
            <div className="d-flex align-items-center flex-wrap mt-2">
              {selectedIdea.resource_topics.map((t) => (
                <span key={t.topic_id} className={styles.topic}>
                  {t.name}
                </span>
              ))}
            </div>
          </div>
        ) : null}
        {selectedCouncil?.name !== "Industries" &&
        selectedIdea?.resource_industries?.length ? (
          <div className={`mt-3 ${styles.category}`}>
            <span className={styles.name}>
              {selectedCouncil?.name === "Freddie Mac"
                ? "Housing Cycle"
                : "Industries"}
            </span>
            <div className="d-flex align-items-center flex-wrap mt-2">
              {selectedIdea.resource_industries.map((t) => (
                <span key={t.industry_id} className={styles.industry}>
                  {t.name}
                </span>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(IdeaProfileAreasOfFocus);

import { addHours, getUnixTime } from "date-fns";

import { getCookie } from "common/helper";
import {
  LOGIN_ATTEMPT,
  LOGIN_SUCCESS,
  REGISTER_ATTEMPT,
  REGISTER_SUCCESS,
  LOGOUT_SUCCESS,
  SET_USER,
  UPDATE_SESSION_DETAILS,
  GET_NEW_TOKEN_SUCCESS,
} from "./auth.action";
import { LoginStatus, RegisterStatus } from "./auth.constants";
import { apiStatus } from "../../common/httpCall";
import { SETTINGS_EDIT_SUCCESS } from "../../common/actions/common.action";
// import { setOrUpdateCookieWithClear } from "../../common/helper";

// import { setOrUpdateCookieWithClear } from "../../common/helper";

const initState = {
  loginStatus: LoginStatus.LOGIN_NEW,
  session: null,
  registerStatus: RegisterStatus.REGISTER_NEW,
};
const authReducer = (state = initState, action) => {
  const { type, payload } = action;
  // const council_role = payload?.xhr?.getResponseHeader("user-council-role");
  switch (type) {
    case UPDATE_SESSION_DETAILS: {
      return {
        ...state,
        ...payload,
      };
    }
    case LOGIN_ATTEMPT:
      break;
    case GET_NEW_TOKEN_SUCCESS: {
      if (payload.response?.access_token) {
        const expireHours = addHours(new Date(), 8);
        const makeExpire = getUnixTime(expireHours);
        document.cookie = `expire=${makeExpire};expires=${expireHours};path=/;secure;samesite=Strict;`;

        const session = {
          ...state.session,
          accessToken: payload.response?.access_token, // set new token :)
        };
        return {
          ...state,
          session,
        };
      }

      return state;
    }
    case LOGIN_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          if (payload.isFirstSignIn) {
            const expireHours = addHours(new Date(), 8);
            const makeExpire = getUnixTime(expireHours);
            document.cookie = `expire=${makeExpire};expires=${expireHours};path=/;secure;samesite=Strict;`;
          }

          const isSSOLogin = localStorage.getItem("workOs");
          const session = {
            ...response,
            isSSOLogin: isSSOLogin ? JSON.parse(isSSOLogin) : false,
            password_updated_at: isSSOLogin
              ? true
              : response.password_updated_at,
            accessToken: payload.xhr.getResponseHeader("access-token"),
            client: payload.xhr.getResponseHeader("client"),
            council_id: payload.xhr.getResponseHeader("council_id"),
            user_id: getCookie("user_id"),
          };

          document.cookie = `access_token=${session?.accessToken};path=/;secure;samesite=Strict;`;
          document.cookie = `uid=${session?.uid};path=/;secure;samesite=Strict;`;
          document.cookie = `client=${session?.client};path=/;secure;samesite=Strict;`;
          document.cookie = `user_id_=${session?.id};secure;samesite=Strict;`;
          document.cookie = `council_id_=${session?.default_council?.id};secure;samesite=Strict;`;
          document.cookie = `council_role_=${session?.council_role};path=/;secure;samesite=Strict;`;

          const microsoftTokens = response.integrated_tokens.Microsoft;

          if (microsoftTokens?.token) {
            Object.entries(microsoftTokens.token).forEach((value) =>
              localStorage.setItem(value[0], value[1])
            );
          }

          return {
            ...state,
            session,
            loginStatus: LoginStatus.LOGIN_SUCCESS,
          };
        }
        if (status === apiStatus.UNAUTHORIZED) {
          return {
            ...state,
            loginStatus: LoginStatus.LOGIN_FAILED,
          };
        }
      }
      break;

    case SET_USER: {
      const isSSOLogin = localStorage.getItem("workOs");
      return {
        ...state,
        session: {
          ...payload,
          isSSOLogin: isSSOLogin ? JSON.parse(isSSOLogin) : false,
          password_updated_at: isSSOLogin ? true : payload.password_updated_at,
        },
        loginStatus: LoginStatus.LOGIN_SUCCESS,
      };
    }
    case REGISTER_ATTEMPT:
      break;
    case REGISTER_SUCCESS:
      break;
    case LOGOUT_SUCCESS:
      return {
        ...state,
        session: null,
        loginStatus: LoginStatus.LOGOUT,
      };
    case SETTINGS_EDIT_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            session: {
              ...state.session,
              avatar: response.avatar,
            },
          };
        }
      }
      break;
    default:
      break;
  }
  return state;
};

export default authReducer;

import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  deleteStageByIdAttempt,
  deleteTaskAttempt,
  deleteStageTemplateByIdAttempt,
} from "modules/admin/adminProjects/adminProjects.actions";
import { deleteTractionCompaniesAttempt } from "modules/tractionAdmin/companies/tractionCompanies.actions";
import {
  deleteCustomFieldsAttempt,
  deleteCustomFieldsGroupsAttempt,
} from "modules/admin/adminCustomFieldsGroups/adminCustomField.action";
import { ModalFooter, ModalBody } from "../../../application/modal";
import { Button, BtnType } from "../../../modules/primitives";
import styles from "./confirmDelete.module.scss";
import { useModelPopup } from "../../hooks";
import {
  councilRemoveMember,
  councilRemoveTeam,
  councilRemoveTeamMember,
  councilSet,
} from "modules/council/council.action";
import { eventsDeleteEvent } from "modules/events/viewEvent/viewEvent.action";
import { deleteCompany } from "modules/companies/company/company.action";
import { patientOrganizationDelete } from "modules/patientOrganizations/patientOrganizationMain/patientOrganizationMain.action";
import {
  ratingsTemplateDeleteAttempt,
  ratingsTemplateCategoriesDeleteAttempt,
} from "modules/admin/adminRating/adminRating.action";
import {
  tagTopicDeleteAttempt,
  tagIndustryDeleteAttempt,
  tagRelationshipDeleteAttempt,
  tagCustomGroupDeleteAttempt,
  tagCustomDeleteAttempt,
} from "modules/admin/adminTags/adminTags.actions";
import { deleteApplication } from "modules/admin/adminApplicants/adminApplicants.action";
import { deleteApplicantForm } from "modules/admin/adminApplicants/forms/adminApplicantForms.action";
import {
  ideasDeleteAttempt,
  formsDeleteAttempt,
} from "modules/admin/adminIdeas/adminIdeas.action";
import { deleteCompanyContactsAttempt } from "modules/admin/adminMembers/adminMembers.action";
import {
  companySavedListDelete,
  setSelectedList,
} from "modules/lists/store/lists.actions";
import { deleteAgreementType } from "modules/admin/adminAgreements/adminAgreements.action";
import {
  themesDeleteAttempt,
  themesGetIndividualAttempt,
} from "modules/themes/themesMainPage/themesMain.action";
import {
  themesCompanyDeleteAttempt,
  themesMemberDeleteAttempt,
  themesProjectDeleteAttempt,
  themesEventDeleteAttempt,
  themesIdeasDeleteAttempt,
  themeProductDeleteAttempt,
  themesMetricsGetAttempt,
} from "modules/themes/themesIndividualPage/themesOverviewTab/overviewTab.action";
import { themesProjectsGetAttempt } from "modules/themes/themesIndividualPage/themesProjectsTab/projectsTab.action";
import { themesPostDeleteAttempt } from "modules/themes/themesIndividualPage/themesOverviewTab/postsBlock/postsBlock.action";
import { postsReplyDeleteAttempt } from "modules/themes/themesIndividualPage/themesOverviewTab/postsBlock/feed/feed.action";
import { councilsDeleteAttempt } from "modules/tractionAdmin/councils/councils.action";
import { solutionsDeleteAttempt } from "modules/solutions/solutionsMainPage/solutionsMain.actions";

const warningMessage = (type, customData) => {
  const warningText = {
    deleteList:
      "You are about to delete this entire list. This action cannot be undone. Do you want to proceed?",
    deleteEvent:
      "You are about to delete this event. This action cannot be undone. Do you want to proceed?",
    deleteCompany:
      "You are about to delete this company from your council. This action cannot be undone. Do you want to proceed?",
    deleteOrganization:
      "You are about to delete this patient organization from your council. This action cannot be undone. Do you want to proceed?",
    deleteMember:
      "You are about to delete this member from your council. This action cannot be undone. Do you want to proceed?",
    deleteTeam:
      "You are about to delete this team and all its access privileges. Do you want to proceed?",
    deleteTeamMember:
      "You are about to remove this team member and all their access privileges. Do you want to proceed? ",
    deleteRatingTemplate:
      "You are about to remove this rating template. Do you want to proceed?",
    delteRatingTemplateCategory:
      "You are about to remove this rating template category. Do you want to proceed?",
    deleteStage:
      "You are about to delete this Stage and all Tasks that are part of it. Are you sure?",
    deleteTask: "You are about to remove this task. Do you want to proceed?",
    deleteStageTemplate:
      "You are about to remove this stage template. Do you want to proceed?",
    tagTopicDeleteAttempt:
      "You are about to remove this tag. Do you want to proceed?",
    tagIndustryDeleteAttempt:
      "You are about to remove this tag. Do you want to proceed?",
    deleteApplication:
      "You are about to delete this Applicant’s submission from your council. This action cannot be undone. Do you want to proceed?",
    deleteApplicationForm:
      "You are about to delete this Form from your council. This action cannot be undone. Do you want to proceed?",
    deleteIdea:
      "You are about to delete this Idea from your council. This action cannot be undone. Do you want to proceed?",
    deleteForm:
      "You are about to delete this Form from your council. This action cannot be undone. Do you want to proceed?",
    deleteTractionAdmin:
      "You are about to delete this Form from your council. This action cannot be undone. Do you want to proceed?",
    deleteAdminCustomField:
      "You are about to delete this custom field for all companies in your database, along with any related data. Are you sure?",
    deleteCustomFieldGroup:
      "You are about to delete this custom field group for all companies in your database, along with any related data. Are you sure?",
    deleteContact:
      "You are about to remove this contact. Do you want to proceed?",
    tagRelationshipStatusDelete:
      "You are about to delete this relationship status tag. This action cannot be undone. Do you want to proceed?",
    deleteCustomAgreementWithRecords: `You are about to delete an custom field agreement with existing records in ${
      customData ? customData[0] : null
    } companies. (You can find the companies by searching in the Companies tab and selecting the appropriate Agreement filter). Are you sure you want to delete?`,
    deleteAgreementWithRecords: `You are about to delete an Agreement type with existing records in ${
      customData ? customData[0] : null
    } companies. (You can find the companies by searching in the Companies tab and selecting the appropriate Agreement filter). Are you sure you want to delete?`,
    deleteAgreement: "You are about to delete an Agreement custom field",
    deleteTheme: "You are about to remove this theme. Do you want to proceed?",
    deleteThemeCompany: `Are you sure you want to remove this company from the ${
      customData ? customData[0] : "Theme"
    }?`,
    deleteThemeMember: `You are about to remove this member from ${
      customData ? customData[0] : "theme"
    }. Do you want to proceed?`,
    deleteThemeProject:
      "You are about to remove this project from theme. Do you want to proceed?",
    deleteThemePost:
      "You are about to delete this Post and all its Replies. Do you want to proceed?",
    deletePostReply:
      "You are about to delete this Reply. Do you want to proceed?",
    deleteThemeEvent:
      "You are about to remove this event from theme. Do you want to proceed?",
    deleteThemeIdea:
      "You are about to remove this idea from theme. Do you want to proceed?",
    deleteDocument:
      "You are about to permanently delete this document. Do you want to proceed?",
    deleteCFDocument:
      "You are about to permanently delete this document. Do you want to proceed?",
    tagCustomGroupDeleteAttempt:
      "You are about to remove this custom group tag. Do you want to proceed?",
    tagCustomDeleteAttempt:
      "You are about to remove this custom tag. Do you want to proceed?",
    deleteSolution:
      "You are about to remove this solution. Do you want to proceed?",
  };

  return warningText[type];
};

const ConfirmDeleteComponent = ({
  enqueueSnackbar,
  id,
  actionType,
  councilId,
  teamId,
  eventName,
  memberName,
  name,
  teamName,
  onCompanyDelete,
  onOrganisationDelete,
  categoryId,
  templateId,
  customData,
  fromCouncils,
  fileDelete,
  setDocumentUpload,
  pathname,
  deleteDocument,
  deleteCFDocumnent,
}) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const history = useHistory();

  const handleDelete = () => {
    const selectedCouncil = JSON.parse(localStorage.getItem("council"));

    if (actionType === "deleteThemeProduct") {
      dispatch(
        themeProductDeleteAttempt({
          themeId: id,
          productId: templateId,
          enqueueSnackbar,
        })
      );
    }

    if (actionType === "deleteContact") {
      dispatch(
        deleteCompanyContactsAttempt({ contactId: id, enqueueSnackbar })
      );
    }
    if (actionType === "deleteCustomFieldGroup") {
      dispatch(
        deleteCustomFieldsGroupsAttempt({
          customFieldGroupId: id,
          enqueueSnackbar,
          cb: () => {
            const newCouncilData = {
              ...selectedCouncil,
              custom_field_groups: selectedCouncil.custom_field_groups.filter(
                (group) => group.id !== id
              ),
              integrated_tokens: customData,
            };
            dispatch(councilSet(newCouncilData));
          },
        })
      );
    }
    if (
      actionType === "deleteAdminCustomField" ||
      actionType === "deleteCustomAgreementWithRecords"
    ) {
      dispatch(
        deleteCustomFieldsAttempt({ customFieldId: id, enqueueSnackbar })
      );
    }
    if (actionType === "deleteTractionAdmin") {
      dispatch(
        deleteTractionCompaniesAttempt({ companyId: id, enqueueSnackbar })
      );
    }
    if (actionType === "tagRelationshipStatusDelete") {
      dispatch(tagRelationshipDeleteAttempt({ tagId: id, enqueueSnackbar }));
    }
    if (actionType === "deleteList") {
      dispatch(companySavedListDelete({ id, enqueueSnackbar }));
      dispatch(setSelectedList({ id: "" }));
      history.push("/list/following");
    }
    if (actionType === "deleteEvent") {
      dispatch(eventsDeleteEvent({ eventId: id, eventName, enqueueSnackbar }));
    }
    if (actionType === "deleteCompany") {
      dispatch(
        deleteCompany({
          council_id: councilId || selectedCouncil.id,
          company_id: id,
          name,
          enqueueSnackbar,
        })
      );
      if (onCompanyDelete) {
        onCompanyDelete();
      }
    }
    if (actionType === "deleteOrganization") {
      dispatch(
        patientOrganizationDelete({
          patient_organization_id: id,
          enqueueSnackbar,
          name,
        })
      );
      if (onOrganisationDelete) {
        onOrganisationDelete();
      }
    }
    if (actionType === "deleteMember") {
      dispatch(councilRemoveMember({ id, enqueueSnackbar, memberName }));
    }
    if (actionType === "deleteTeam") {
      dispatch(councilRemoveTeam({ id, enqueueSnackbar, teamName }));
    }
    if (actionType === "deleteTeamMember") {
      dispatch(
        councilRemoveTeamMember({
          user_id: id,
          team_id: teamId,
          enqueueSnackbar,
        })
      );
    }
    if (actionType === "deleteRatingTemplate") {
      dispatch(ratingsTemplateDeleteAttempt({ id, enqueueSnackbar }));
    }
    if (actionType === "delteRatingTemplateCategory") {
      dispatch(
        ratingsTemplateCategoriesDeleteAttempt({
          templateId,
          categoryId,
          enqueueSnackbar,
        })
      );
    }
    if (actionType === "deleteStage") {
      dispatch(deleteStageByIdAttempt({ stageId: id, enqueueSnackbar }));
    }
    if (actionType === "deleteTask") {
      dispatch(deleteTaskAttempt({ councilId, taskId: id, enqueueSnackbar }));
    }
    if (actionType === "deleteStageTemplate") {
      dispatch(deleteStageTemplateByIdAttempt({ id, enqueueSnackbar }));
    }
    if (actionType === "tagTopicDeleteAttempt") {
      dispatch(
        tagTopicDeleteAttempt({
          topic_id: id,
          council_id: councilId,
          enqueueSnackbar,
        })
      );
    }
    if (actionType === "tagIndustryDeleteAttempt") {
      dispatch(
        tagIndustryDeleteAttempt({
          topic_id: id,
          council_id: councilId,
          enqueueSnackbar,
        })
      );
    }
    if (actionType === "deleteApplication") {
      dispatch(deleteApplication({ id, enqueueSnackbar }));
    }
    if (actionType === "deleteApplicationForm") {
      dispatch(deleteApplicantForm({ id, enqueueSnackbar }));
    }
    if (actionType === "deleteIdea") {
      dispatch(
        ideasDeleteAttempt({
          id,
          enqueueSnackbar,
          name,
          cb: () => {
            if (onOrganisationDelete === "refresh") {
              setTimeout(() => {
                history.push("/ideas");
              }, 1000);
            }
          },
        })
      );
    }
    if (actionType === "deleteForm") {
      dispatch(formsDeleteAttempt({ id, enqueueSnackbar, name }));
    }
    if (
      actionType === "deleteAgreement" ||
      actionType === "deleteAgreementWithRecords"
    ) {
      dispatch(
        deleteAgreementType({
          agreement_type_id: id,
          enqueueSnackbar,
        })
      );
    }
    if (actionType === "deleteTheme") {
      dispatch(themesDeleteAttempt({ id, enqueueSnackbar }));
      history.push("/themes");
    }
    if (actionType === "deleteThemeCompany") {
      dispatch(
        themesCompanyDeleteAttempt({
          id,
          companyId: templateId,
          enqueueSnackbar,
          cb: () => {
            dispatch(
              themesGetIndividualAttempt({
                id,
                enqueueSnackbar,
              })
            );
          },
        })
      );
    }
    if (actionType === "deleteThemeMember") {
      dispatch(
        themesMemberDeleteAttempt({
          themeId: id,
          memberId: templateId,
          enqueueSnackbar,
        })
      );
    }
    if (actionType === "deleteThemeProject") {
      dispatch(
        themesProjectDeleteAttempt({
          id,
          projectId: templateId,
          enqueueSnackbar,
          cb: () => {
            dispatch(themesMetricsGetAttempt({ id, enqueueSnackbar }));
            dispatch(
              themesProjectsGetAttempt({
                id,
                enqueueSnackbar,
                themeId: id,
                page: 1,
                overview: true,
                pageSize: 20,
              })
            );
          },
        })
      );
    }
    if (actionType === "deleteThemePost") {
      dispatch(
        themesPostDeleteAttempt({ id, postId: templateId, enqueueSnackbar })
      );
    }
    if (actionType === "deletePostReply") {
      dispatch(
        postsReplyDeleteAttempt({
          themeId: id,
          postId: templateId,
          replyId: categoryId,
          enqueueSnackbar,
        })
      );
    }
    if (actionType === "deleteThemeEvent") {
      dispatch(
        themesEventDeleteAttempt({
          id,
          eventId: templateId,
          enqueueSnackbar,
        })
      );
    }
    if (actionType === "deleteThemeIdea") {
      dispatch(
        themesIdeasDeleteAttempt({
          id,
          ideaId: templateId,
          enqueueSnackbar,
        })
      );
    }
    if (actionType === "deleteCouncil") {
      dispatch(
        councilsDeleteAttempt({
          id,
          enqueueSnackbar,
        })
      );
    }
    if (actionType === "deleteDocument") {
      dispatch(
        deleteDocument({
          document_id: id,
          enqueueSnackbar,
        })
      );
      fileDelete(pathname).then(() => {
        setDocumentUpload(false);
      });
    }
    if (actionType === "deleteCFDocument") {
      deleteCFDocumnent(
        customData?.e,
        customData?.fromDeleteDocument,
        customData?.customIndex
      );
    }
    if (actionType === "tagCustomGroupDeleteAttempt") {
      dispatch(tagCustomGroupDeleteAttempt({ id, enqueueSnackbar }));
    }
    if (actionType === "tagCustomDeleteAttempt") {
      dispatch(
        tagCustomDeleteAttempt({ id, groupId: categoryId, enqueueSnackbar })
      );
    }
    if (actionType === "deleteSolution") {
      dispatch(solutionsDeleteAttempt({ id, enqueueSnackbar }));
    }

    popup.hide();
  };

  const handleDismiss = () => {
    popup.hide();
  };

  return (
    <div>
      <ModalBody>
        {fromCouncils ? (
          <div className={styles.warningTxt}>
            You are about to remove <strong>{customData?.councilName}</strong>{" "}
            council. Do you want to proceed?
          </div>
        ) : (
          <div className={styles.warningTxt}>
            {warningMessage(actionType, customData)}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          btn={BtnType.HIGHLIGHT}
          className={styles.button}
        >
          {actionType === "deleteTeamMember" ? "Remove" : "Delete"}
        </Button>
      </ModalFooter>
    </div>
  );
};

export default React.memo(ConfirmDeleteComponent);

export const gskDiscussionTopics = [
  { name: "Government Affairs and Advocacy" },
  { name: "Medical Affairs" },
  { name: "R&D" },
  { name: "Marketing" },
  { name: "Other" },
];
export const gskDiscussionSubTopics = [
  {
    name: "Government Affairs and Advocacy",
    subTopics: [
      { name: "Patient Access issues" },
      { name: "Disease awareness" },
      { name: "Improving the standard of care of a disease/condition" },
      { name: "Meaningful patient engagement in healthcare policy" },
      { name: "Reaching underserved communities" },
      { name: "Burden of disease" },
    ],
  },
  {
    name: "Medical Affairs",
    subTopics: [
      { name: "Patient Support Program" },
      { name: "Patient Education" },
      { name: "Unmet patient needs" },
      { name: "Patient experience" },
      { name: "Advisory Board" },
      { name: "Patient safety" },
      { name: "Interest in SIRF" },
    ],
  },
  {
    name: "R&D",
    subTopics: [
      { name: "Clinical trial collaboration" },
      { name: "Unmet patient needs" },
    ],
  },
  {
    name: "Marketing",
    subTopics: [],
  },
  {
    name: "Other",
    subTopics: [],
  },
];

// export const gskDiscussionTopics = {
//   'Government Affairs and Advocacy': [
//     'Patient Access issues',
//     'Disease awareness',
//     'Improving the standard of care of a disease/condition in the health system',
//     'Meaningful patient engagement in heathcare policy, HTA edtc',
//     'Reaching underserved communities',
//     'Burden of disease',
//   ],
//   'Medical Affairs': [
//     'Patient Support Program',
//     'Patient Education',
//     'Unmet patient needs',
//     'Patient experience',
//     'Advisory Board',
//     'Patient safety',
//     'Interest in SIRF',
//   ],
//   'R&D': ['Clinical trial collaboration', 'Unmet patient needs'],
//   Marketing: '',
//   Other: '',
// };

export const activityTypes = {
  call: 0,
  email: 1,
  event: 2,
  meeting: 3,
  note: 4,
  merck_email: 5,
  project: 6,
};

export const activityList = [
  {
    id: "1",
    type: activityTypes.call,
  },
  {
    id: "2",
    type: activityTypes.email,
  },
  {
    id: "3",
    type: activityTypes.event,
  },
  {
    id: "4",
    type: activityTypes.meeting,
  },
  {
    id: "5",
    type: activityTypes.note,
  },
];

const parceTeam = (teams, ids) => {
  let teamName = "";
  teams.map((team) => {
    if (ids.includes(team.id)) {
      teamName += team.name;
    }
  });
  return teamName;
};

export const parceVisibility = (param, teams, ids) => {
  if (param === "for_teams" && teams) {
    return `Team: ${parceTeam(teams, ids)}`;
  }
  if (param === "for_all") {
    return "All";
  }
  if (param === "self_private") {
    return "Private";
  }
  return "";
};

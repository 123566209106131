import React, { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import style from "./styles.module.scss";
import { flat } from "../../../../common/helper";
import {
  countryCheck,
  deseasCheck,
} from "modules/patientOrganizations/patientOrganizationMain/patientOrganizationMain.action";
import { searchInCouncil } from "modules/companies/companyMain/companyMain.action";

const PatientOrgSelectedFilters = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [otherTags, setOtherTags] = useState([]);

  const {
    councilReducer: { selectedCouncil },
    searchReducer: { query },
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: {
        diseases,
        checkedPatientFilters,
        countryFilters,
        diversityGroups,
      },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    const flattenDiversityGroups = flat(diversityGroups || [], "children_diversity_groups");
    const flattenDiseases = flat(diseases || [], "children_diseases");
    const filteredDiseases = flattenDiseases
      .filter(d => checkedPatientFilters.disease_ids?.includes(d.id))
      .map(d => ({...d, type: 'disease'}));
    const filteredDiversityGroups = flattenDiversityGroups
      .filter(d => checkedPatientFilters.diversity_group_ids?.includes(d.id))
      .map(d => ({...d, type: 'diversityGroups'}));

    setOtherTags([...filteredDiseases, ...filteredDiversityGroups]);
  }, [diseases, checkedPatientFilters, diversityGroups]);

  const onTagDelete = (tag) => {
    if (tag.type === "diversityGroups") {
      const diversityIds = checkedPatientFilters.diversity_group_ids?.filter(id => id !== tag.id);
      dispatch(
        deseasCheck({
          diversity_group_ids: diversityIds,
        }),
      );

      dispatch(
        searchInCouncil({
          data: query,
          topic_ids: [],
          industry_ids: [],
          council_id: selectedCouncil.id,
          disease_ids: checkedPatientFilters.disease_ids,
          mode: "companies",
          gsk: true,
          patient_org: true,
          sort_attribute: location.state?.sortAttribute || "",
          sort_order: location.state?.sortOrder || "",
          countries: countryFilters,
          diversity_group_ids: diversityIds,
          enqueueSnackbar,
          cb: () => {},
        }),
      );
    }

    if (tag.type === "disease") {
      const diseaseIds = checkedPatientFilters.disease_ids.filter(id => id !== tag.id);
      dispatch(
        deseasCheck({
          disease_ids: diseaseIds,
        }),
      );

      dispatch(
        searchInCouncil({
          data: query,
          topic_ids: [],
          industry_ids: [],
          council_id: selectedCouncil.id,
          disease_ids: diseaseIds,
          mode: "companies",
          gsk: true,
          patient_org: true,
          sort_attribute: location.state?.sortAttribute || "",
          sort_order: location.state?.sortOrder || "",
          countries: countryFilters,
          diversity_group_ids: checkedPatientFilters.diversity_group_ids,
          enqueueSnackbar,
          cb: () => {},
        }),
      );
    }
  };

  const handleCountryDelete = (c) => {
    const newCountries = countryFilters.filter(country => country !== c);

    dispatch(countryCheck(newCountries));

    dispatch(
      searchInCouncil({
        data: query,
        topic_ids: [],
        industry_ids: [],
        council_id: selectedCouncil.id,
        disease_ids: checkedPatientFilters.disease_ids,
        mode: "companies",
        gsk: true,
        patient_org: true,
        sort_attribute: location.state?.sortAttribute || "",
        sort_order: location.state?.sortOrder || "",
        countries: newCountries,
        diversity_group_ids: checkedPatientFilters.diversity_group_ids,
        enqueueSnackbar,
        cb: () => {},
      }),
    );
  };

  const handleDeleteAll = () => {
    dispatch(countryCheck([]));
    dispatch(
      deseasCheck({
        disease_ids: [],
        diversity_group_ids: []
      }),
    );
    dispatch(
      searchInCouncil({
        data: query,
        topic_ids: [],
        industry_ids: [],
        council_id: selectedCouncil.id,
        disease_ids: [],
        mode: "companies",
        gsk: true,
        patient_org: true,
        sort_attribute: location.state?.sortAttribute || "",
        sort_order: location.state?.sortOrder || "",
        countries: [],
        diversity_group_ids: [],
        enqueueSnackbar,
        cb: () => {},
      })
    );
  };

  return (
    <div className={style.filtersList}>
      {countryFilters.length ? (
        countryFilters.map(c => (
          <div key={c} className={style.tag}>
            {c}
            <span
              className={style.tagDelete}
              onClick={() => handleCountryDelete(c)}
            >
            <svg
              width="15px"
              height="14px"
              viewBox="0 0 72 72"
              id="emoji"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="color" />
              <g id="hair" />
              <g id="skin" />
              <g id="skin-shadow" />
              <g id="line">
                <line
                  x1="17.5"
                  x2="54.5"
                  y1="17.5"
                  y2="54.5"
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
                <line
                  x1="54.5"
                  x2="17.5"
                  y1="17.5"
                  y2="54.5"
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </span>
          </div>
        ))
      ) : null}
      {otherTags.map((tag) => (
        <div key={tag.id || tag.slug} className={style.tag}>
          {tag.name}
          <span className={style.tagDelete} onClick={() => onTagDelete(tag)}>
            <svg
              width="15px"
              height="14px"
              viewBox="0 0 72 72"
              id="emoji"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="color" />
              <g id="hair" />
              <g id="skin" />
              <g id="skin-shadow" />
              <g id="line">
                <line
                  x1="17.5"
                  x2="54.5"
                  y1="17.5"
                  y2="54.5"
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
                <line
                  x1="54.5"
                  x2="17.5"
                  y1="17.5"
                  y2="54.5"
                  fill="none"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </span>
        </div>
      ))}
      {
        (otherTags?.length || countryFilters.length) ? (
          <span className="cursor-pointer ml-3 mt-1 small text-primary" onClick={handleDeleteAll}>Clear All</span>
        ) : null
      }
    </div>
  );
};

export default memo(PatientOrgSelectedFilters);

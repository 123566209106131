import React from 'react';
import { withStyles } from '@mui/styles';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { BtnType, Button } from 'modules/primitives/index';

const StyledDialog = withStyles({
  root: {
    position: 'fixed',
    zIndex: '99999 !important',
    right: '0px',
    bottom: '0px',
    top: '0px',
    left: '0px',
  },
})(Dialog);

const SimpleDialog = ({ handleDismiss, openConfirmModal, handleDelete }) => {
  return (
    <StyledDialog
      open={openConfirmModal}
      onClose={handleDismiss}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Confirm delete'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You are about to delete this item. This action cannot be undone.
          Proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" btn={BtnType.OUTLINE}  onClick={handleDismiss}>
          Cancel
        </Button>
        <Button color="primary"autoFocus onClick={handleDelete}>
          Remove
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default SimpleDialog;

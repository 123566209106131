import React from "react";

import { Label, TextBox } from "modules/primitives";

const ProjectName = ({ formikprops, disabled }) => {
  return (
    <div className="mb-2 d-flex flex-column align-items-baseline">
      <Label>Project Name</Label>
      <TextBox
        className="w-100"
        disabled={disabled}
        type="text"
        name="project_name"
        placeholder="Project Name"
        formProps={{
          ...formikprops,
          values: {...formikprops.values, project_name: formikprops.values?.project_name || ''} // make sure it will not crash if in above model project_name is undefined
        }}
      />
    </div>
  );
};

export default React.memo(ProjectName);

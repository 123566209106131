import React from "react";

import ProjectName from "../common/components/newProjectSharedComponents/projectName";
import NewProjectAccess from "../common/components/newProjectSharedComponents/newProjectAccess";
import ProjectDescription from "../common/components/newProjectSharedComponents/projectDescription";
import ProjectFields from "../common/components/newProjectSharedComponents/projectFields";
import ProjectTopics from "../common/components/newProjectSharedComponents/projectTopics";
import ProjectIndustries from "../common/components/newProjectSharedComponents/projectIndustries";
import ProjectCompanies from "../common/components/newProjectSharedComponents/projectCompanies";
import ProjectMembers from "../common/components/newProjectSharedComponents/projectMembers";
import ProjectMetrics from "../common/components/newProjectSharedComponents/projectMetrics";
import ProjectReplication from "../common/components/newProjectSharedComponents/projectReplication";
import ProjectOwner from "../common/components/newProjectSharedComponents/projectOwner";
import ProjectPlants from "../common/components/newProjectSharedComponents/projectPlants";
import ProjectThemes from "../common/components/newProjectSharedComponents/projectThemes";
import ProjectIdeaCompanies from "../common/components/newProjectSharedComponents/projectIdeaCompanies";
import ProjectIdeaMembers from "../common/components/newProjectSharedComponents/projectIdeaMembers";
import SimilarProjects from "../common/components/newProjectSharedComponents/similarProjects";
import SimilarProjectRecommendations from "../common/components/newProjectSharedComponents/similarProjectRecommendations";
import ProjectReplicationsPlants from "../common/components/newProjectSharedComponents/projectReplicationsPlants";
import ProjectDiseases from "../common/components/newProjectSharedComponents/projectDiseases";
import ProjectCountries from "../common/components/newProjectSharedComponents/projectCountries";
import ProjectScoring from "../common/components/newProjectSharedComponents/projectScoring";
import ProjectOrganizations from "../common/components/newProjectSharedComponents/projectOrganizations";
import ProjectStages from "../common/components/newProjectSharedComponents/projectStages";

export const modalSetting = {
  component: null,
  width: "400",
  height: "300",
  show: false,
  title: null,
};

export const sliderSetting = {
  component: null,
  header: null,
  width: "400",
  height: "300",
  show: false,
  doNotHide: false,
};

export const METRICS_NAMES_ENUM = {
  prototype_date: "Prototype Date",
  prototype_job_1_date: "Prototype Job 1 Date",
  job_1_date: "Job 1 Date",
  proposal_for_prototype_expected_job_1_date: "Expected Job 1 Date",
  proposal_for_implementation_date: "Proposal for Implementation Date",
  expected_job_1_date: "Expected Job 1 Date",
  implementation_date: "Implementation Date",
  next_stage_start_on: "Next Stage Start On",
  next_stage_end_on: "Next Stage Change",
  ready_for_replication_date: "Ready for Replication Date",
  // integers
  tarr_percentage: "TARR (%)",
  savings_usd: "Savings ($)",
  investment_usd: "Investment ($)",
  prototype_investment_usd: "Prototype Investment ($)",
  threed_printing_replications: "3D Print Replications",
  annual_incremental_investment_usd: "Annual Incremental Investment",
  current_spending_usd: "Current Spending",
  headcount_op_crew: "HeadCount Op Crew",
};

export const PROJECT_WIZARD_FIELD_TYPES = {
  project_name: {
    fieldTitle: "Project Name",
    component: (props) => <ProjectName {...props} />,
  },
  project_access: {
    fieldTitle: "Project Access",
    component: (props) => <NewProjectAccess {...props} />,
  },
  project_description: {
    fieldTitle: "Project Description",
    component: (props) => <ProjectDescription {...props} />,
  },
  project_stage: {
    fieldTitle: "Project Stage",
    component: (props) => <ProjectStages {...props} />,
  },
  project_theme: {
    fieldTitle: "Project Pillar",
    component: (props) => <ProjectThemes {...props} />,
  },
  project_field: {
    fieldTitle: "Project Fields",
    component: (props) => <ProjectFields {...props} />,
  },
  project_metric: {
    fieldTitle: "Project Metrics",
    component: (props) => <ProjectMetrics {...props} />,
  },
  project_owner: {
    fieldTitle: "Project Owner",
    component: (props) => <ProjectOwner {...props} />,
  },
  project_topics: {
    fieldTitle: "Project Technologies",
    component: (props) => <ProjectTopics {...props} />,
  },
  gsk_project_disease: {
    fieldTitle: "Project Diseases",
    component: (props) => <ProjectDiseases {...props} />,
  },
  gsk_project_country: {
    fieldTitle: "Project Countries",
    component: (props) => <ProjectCountries {...props} />,
  },
  gsk_project_scoring: {
    fieldTitle: "Project Scoring",
    component: (props) => <ProjectScoring {...props} />,
  },
  gsk_patient_organizations: {
    fieldTitle: "Patient Organizations",
    component: (props) => <ProjectOrganizations {...props} />,
  },
  project_industries: {
    fieldTitle: "Project Industries",
    component: (props) => <ProjectIndustries {...props} />,
  },
  project_companies: {
    fieldTitle: "Project Companies",
    component: (props) => <ProjectCompanies {...props} />,
  },
  project_members: {
    fieldTitle: "Project Members",
    component: (props) => <ProjectMembers {...props} />,
  },
  project_plants: {
    fieldTitle: "Project Plants",
    component: (props) => <ProjectPlants {...props} />,
  },
  possible_replications_plants: {
    fieldTitle: "Possible Replications (Plants)",
    component: (props) => <ProjectReplicationsPlants {...props} />,
  },
  project_replication: {
    fieldTitle: "Is this a Replication?",
    component: (props) => <ProjectReplication {...props} />,
  },
  project_small_companies: {
    fieldTitle: "Project Companies (Small)",
    component: (props) => <ProjectIdeaCompanies {...props} />,
  },
  project_small_members: {
    fieldTitle: "Project Members (Small)",
    component: (props) => <ProjectIdeaMembers {...props} />,
  },
  project_similar_projects: {
    fieldTitle: "Similar Projects",
    component: (props) => <SimilarProjects {...props} />,
  },
  similar_projects_recommendations: {
    fieldTitle: "Similar Projects Recommendations",
    component: (props) => <SimilarProjectRecommendations {...props} />,
  },
};

import React, { useState } from "react";

import { useModelPopup } from "common/hooks";
import { ModalBody, ModalFooter } from "../../../application/modal";
import { BtnType, Button, Label, TextBox } from "../../../modules/primitives";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { httpPost } from "../../../common/httpCall";

const AddAdminRegion = ({ regions, region, handleRegionAdd }) => {
  const [regionName, setRegionName] = useState(region?.name || '');
  const [fetching, setFetching] = useState(false);
  const [invalidRegionName, setInvalidRegionName] = useState(false);

  const popup = useModelPopup();

  const handleSave = () => {
    setFetching(true);

    httpPost({
      call: "plants/regions",
      data: {
        name: regionName,
      },
    }).subscribe((res) => {
      handleRegionAdd(res.response);
      setFetching(false);
      popup.hide();
    });
  };

  const handleDismiss = () => {
    popup.hide();
  };

  const handleChangeRegionName = (evt) => {
    const value = evt.target.value;
    setRegionName(value);

    const existingNames = regions.map(a => a.name.toLowerCase());
    const isInvalidRegionName = existingNames.includes(value.toLowerCase());
    setInvalidRegionName(isInvalidRegionName);
  };

  return (
    <>
      <ModalBody>
        <div>
          <Label>Region Name</Label>
          <TextBox
            type="text"
            placeholder="Type new Region name"
            className="w-100"
            value={regionName}
            onChange={handleChangeRegionName}
          />
          {
            invalidRegionName && (
              <span className="small text-danger">Region already exists, please choose another one!</span>
            )
          }
        </div>
      </ModalBody>
      <ModalFooter>
        {
          fetching ? (
            <LoadingComponent customText="Saving region..." />
          ) : (
            <>
              <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                btn={BtnType.REGULAR}
                className="ml-2"
                disabled={invalidRegionName}
              >
                Save
              </Button>
            </>
          )
        }
      </ModalFooter>
    </>
  );
};

export default React.memo(AddAdminRegion);

import React from "react";
import Tooltip from "@mui/material/Tooltip";

import { isEmptyHtmlElement, truncateHtml } from "../../../common/helper";

const ProjectDescription = ({ description, howManyChars = 150 }) => {
  if (!description || isEmptyHtmlElement(description)) return null;

  return (
    <>
      {description?.length > howManyChars ? (
        <Tooltip
          title={
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          }
          placement="right"
          arrow
        >
          <div
            className="cursor-pointer"
            dangerouslySetInnerHTML={{
              __html: `${truncateHtml(description, howManyChars)}...`,
            }}
          />
        </Tooltip>
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
    </>
  );
};

export default React.memo(ProjectDescription);

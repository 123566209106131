import React from "react";
import { Switch, Route } from "react-router-dom";

import ProjectsMainComponent from "./projectsMain";
import ProjectComponent from "./project";
// import NewProjectComponent from "./newProject";
import ErrorBoundary from "../../common/components/errorBoundary";
import NewProjectMain from "modules/projects/newProjectv3/newProjectMain";

const ProjectsComponent = () => (
  <ErrorBoundary component="Projects">
    <Switch>
      <Route exact path="/projects/new" component={NewProjectMain} />
      <Route path="/projects/:id" component={ProjectComponent} />
      <Route exact path="/projects" component={ProjectsMainComponent} />
    </Switch>
  </ErrorBoundary>
)

export default ProjectsComponent;

import mapIcon from "../../../assets/maker.svg";

export const moreOptionTypes = {
  follow: 1,
  addToList: 2,
  addToProject: 3,
  delete: 4,
  unfollow: 5,
  addToIdea: 6,
  addToTheme: 7,
};

export const layoutType = {
  card: 0,
  list: 1,
  charts: 2,
};

export const layoutList = [
  {
    value: layoutType.card,
    icon: "icn-view-card",
    title: "",
  },
  {
    value: layoutType.list,
    icon: "icn-view-list",
    title: "list",
  },
  {
    value: layoutType.charts,
    icon: "icn-view-map",
    title: "charts",
  },
];

export const getValueByTitle = (title = "") => {
  const obj = layoutList.find((item) => item.title === title);
  return obj.value;
};

export const getTitleByValue = (value) => {
  const obj = layoutList.find((item) => item.value === value);
  return obj.title;
};

export const config = {
  zoomLevel: 12,
  useDefaultUI: true,
  googleMapsMarkerIcon: mapIcon,
  apiKey: "AIzaSyAjmXiD-nVEaLyalBEB8mUDtLkvCtjID6I",
};

export const companyMoreOptions = [
  {
    val: moreOptionTypes.follow,
    text: "Follow",
  },
  {
    val: moreOptionTypes.unfollow,
    text: "Unfollow",
  },
  {
    val: moreOptionTypes.addToList,
    text: "Add to list",
  },
  {
    val: moreOptionTypes.addToIdea,
    text: "Add to Idea",
  },
  {
    val: moreOptionTypes.addToProject,
    text: "Add to project",
  },
  {
    val: moreOptionTypes.addToTheme,
    text: "Add to theme",
  },
];

export const myListMoreOptions = [
  {
    val: moreOptionTypes.follow,
    text: "Follow",
  },
  {
    val: moreOptionTypes.follow,
    text: "Unfollow",
  },
  {
    val: moreOptionTypes.delete,
    text: "Remove",
  },
  {
    val: moreOptionTypes.addToProject,
    text: "Add To Project",
  },
  {
    val: moreOptionTypes.addToIdea,
    text: "Add to Idea",
  },
  {
    val: moreOptionTypes.addToTheme,
    text: "Add to theme",
  },
];

export const followListMoreOptions = [
  {
    val: moreOptionTypes.follow,
    text: "Unfollow",
  },
  {
    val: moreOptionTypes.addToProject,
    text: "Add To Project",
  },
  {
    val: moreOptionTypes.addToIdea,
    text: "Add to Idea",
  },
  {
    val: moreOptionTypes.addToTheme,
    text: "Add to theme",
  },
];

export const ADVANCED_PARAMS = [
  "toggle",
  "employees",
  "estimatedRevenue",
  "fundingFrom",
  "fundingTo",
  "foundedFrom",
  "foundedTo",
  "scoreFrom",
  "scoreTo",
  "relationShipStatus",
  "topic",
  "industry",
  "business_unit",
  "owners",
  "location",
  "agreements",
  "k_country",
  "idbEcosystem",
  "lists",
];

export const PRODUCT_ONLY_PARAMS = [
  "custom_tags_ids",
  "toggle",
  "employees",
  "estimatedRevenue",
  "fundingFrom",
  "fundingTo",
  "foundedFrom",
  "foundedTo",
  "scoreFrom",
  "scoreTo",
  "topic",
  "industry",
  "business_unit",
  "location",
  "agreements",
  "k_country",
  "idbEcosystem",
];

export const FREDDIE_MAC_BU_OPTIONS = [
  {
    id: "4f28de92-19d5-4812-a190-0a545de7d9c8",
    name: "Multifamily",
    child: [],
  },
  {
    id: "791f47d6-e80d-4a40-8aa4-6cb3569a0204",
    name: "Single Family",
    child: [],
  },
];

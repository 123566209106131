import React, { useRef } from "react";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { MoreOptions } from "modules/primitives";
import ConfirmDeleteComponent from "common/components/confirmDelete";
import { Icon, smSize } from "common/icon";
import { useHistory } from "react-router-dom";

import { solutionsProjectDeleteSuccess } from "../solutionsMain.actions";

import SolutionAddEditModal from "../solutionAddEditModal/index";
import styles from "./styles.module.scss";
import defaultSolutionImage from "../../../../assets/defaultThemeImage.png";
import { useModelPopup } from "../../../../common/hooks";

export const ACTIVITY_OPTIONS = [
  {
    val: 0,
    text: "Edit",
  },
  {
    val: 1,
    text: "Delete",
  },
];

const SolutionCard = (props) => {
  const { cardData, userId, setSelectedCardModal } = props;
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const ref = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);

  const handleMoreOptionClick = (val) => {
    if (val === 0) {
      popup.show({
        title: "Edit Solution",
        show: true,
        component: (
          <SolutionAddEditModal
            data={{ ...cardData, ...cardData.attributes }}
            popup={popup}
            formRef={ref}
            mode={"edit"}
            userId={userId}
          />
        ),
      });
      dispatch(
        solutionsProjectDeleteSuccess({
          response: cardData.attributes?.projects,
          solutionId: cardData.id,
          projectId: null,
        })
      );
      return;
    }

    popup.show({
      title: "Confirmation Prompt",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={cardData.id}
          enqueueSnackbar={enqueueSnackbar}
          actionType="deleteSolution"
        />
      ),
    });
  };

  return (
    <div
      className={styles.cardContainer}
      onClick={(e) => {
        e.stopPropagation();
        setSelectedCardModal(cardData.id);
        history.push("/solutions", { solutionId: cardData.id });
      }}
    >
      <div className={styles.cardImageContainer}>
        {" "}
        <img
          src={
            cardData?.attributes?.image_url
              ? cardData?.attributes?.image_url
              : defaultSolutionImage
          }
          className={styles.cardImage}
        />
      </div>
      <div className={styles.aboutCardBlock}>
        <div className={styles.textBlock}>
          <div className={styles.titleBlock}>
            <p className={styles.title}>{cardData?.attributes?.name}</p>

            <>
              {(session?.council_role === "ttp_administrator" ||
                session?.council_role === "council_administrator") && (
                <MoreOptions
                  options={ACTIVITY_OPTIONS}
                  className={styles.moreOptWrp}
                  onClick={(e) => {
                    handleMoreOptionClick(e);
                  }}
                  customSvgIcon
                />
              )}
            </>
          </div>
        </div>

        <>
          <div className={styles.dataBlock}>
            <div className={styles.cardStats}>
              <div className={styles.row}>
                <div className={styles.cardStatItem}>
                  {cardData?.attributes?.projects_count}{" "}
                  {cardData?.attributes?.projects_count === 1
                    ? "Project"
                    : "Projects"}
                </div>
                {selectedCouncil.traction_tag === "ford" ? (
                  <div className={styles.cardStatItem}>
                    {cardData?.attributes?.plants_count}{" "}
                    {cardData?.attributes?.plants_count === 1
                      ? "Plant"
                      : "Plants"}
                  </div>
                ) : null}
                <div className={styles.cardStatItem}>
                  {cardData?.attributes?.themes_count}{" "}
                  {cardData?.attributes?.themes_count === 1
                    ? selectedCouncil?.settings?.themes_display_name_singular
                    : selectedCouncil?.settings?.themes_display_name ||
                      "Pillars"}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.cardStatItem}>
                  {cardData?.attributes?.topics_count}{" "}
                  {cardData?.attributes?.topics_count === 1
                    ? "Technology"
                    : "Technologies"}
                </div>
                {selectedCouncil.traction_tag === "ford" ? (
                  <div className={styles.cardStatItem}>
                    Avg. TARR: {cardData?.attributes?.avarage_tarr || "0"} %
                  </div>
                ) : null}

                <div className={styles.cardStatItem}>
                  Avg. Rating{" "}
                  {Number(cardData?.attributes?.avarage_rating) || "0"}
                </div>
              </div>
            </div>
          </div>
        </>
        <div className={styles.iconContainer}>
          <span>MORE</span>
          <Icon
            {...smSize}
            icon="icn-table-down-chevron"
            className={classnames(styles.icon)}
          />
        </div>
      </div>
    </div>
  );
};

export default SolutionCard;

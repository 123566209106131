import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Loading } from "modules/primitives/index";
import styles from "./rightWindow.module.scss";
import { setScrolledTop } from "../companyMain.action";
import CrunchResults from "./crunchResults/crunchResults.component";
import ChartsWrapper from "../chartsWrapper";
import ChartsIntro from "../chartsWrapper/chartsIntro";
import CompaniesSearchTop from "modules/companies/companyMain/rightWindow/companiesSearchTop";
import CompaniesSearchFieldsWrapper from "modules/companies/companyMain/rightWindow/companiesSearchFieldsWrapper";
import CompaniesSearchPagination from "modules/companies/companyMain/rightWindow/companiesSearchPagination";
import CompaniesLayout0 from "modules/companies/companyMain/rightWindow/companiesLayout0";
import CompaniesLayout1 from "modules/companies/companyMain/rightWindow/companiesLayout1";
import { useCompanyContext } from "modules/companies/companyMain/providers/companyDataProvider";

const RightWindowComponent = ({ isFullWidth }) => {
  const {
    appReducer: { chartsDownloadIsActive },
    companiesReducer: {
      companyMainReducer: {
        searchResults,
        isScrolled,
        charts: { chartsIntroIsActive },
        crunchLoading,
      },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const location = useLocation();
  const dispatch = useDispatch();

  const {
    selectLayout,
    showCrunchBase,
    memoizedCompanies,
    memoizedLoading,
    pageSetting,
  } = useCompanyContext();

  const onScrollEventFunc = (event) => {
    const scrolled = event.target.scrollTop;
    if (scrolled > 0 && !isScrolled) {
      dispatch(setScrolledTop(true));
    } else if (scrolled === 0 && isScrolled) {
      dispatch(setScrolledTop(false));
    }
  };

  const mainWrapperClasses = useMemo(() => {
    return `${styles.wrapper} ${
      chartsDownloadIsActive
        ? styles.fullHeightWrapper
        : styles.lessHeightWrapper
    } ${isFullWidth ? styles.fullWidthWrapper : styles.partialWrapper}`
  }, [chartsDownloadIsActive, isFullWidth]);

  return (
    <div className="w-100">
      <div
        className={mainWrapperClasses}
        onScroll={onScrollEventFunc}
        id="rightWindow"
      >
        <CompaniesSearchFieldsWrapper isFullWidth={isFullWidth} />
        {crunchLoading || memoizedLoading ? (
          <div className={styles.container}>
            <Loading />
          </div>
        ) : (
          <>
            {selectLayout !== 2 && (
              <div id="downloadableSection" className={styles.container}>
                {showCrunchBase ? (
                  <CrunchResults
                    query={location.state?.query}
                    page={location.state?.page}
                    isFullWidth={isFullWidth}
                  />
                ) : (
                  <>
                    <CompaniesSearchTop
                      pageSetting={pageSetting}
                      memoizedCompanies={memoizedCompanies}
                    />
                    <CompaniesLayout0 />
                    <CompaniesLayout1 />
                    {location.state?.query?.length && !searchResults?.length ? (
                      <div className="d-flex justify-content-center">
                        We didn't find any results for the key word&nbsp;
                        <b>{location.state?.query}</b>&nbsp; in Traction
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            )}
          </>
        )}
        <CompaniesSearchPagination />
        {!memoizedLoading && selectLayout === 2 && (
          <div className={styles.chartsContainer}>
            <ChartsWrapper totalCompaniesInDB={pageSetting?.total} />
          </div>
        )}
        {selectLayout === 2 &&
          (selectedCouncil?.name === "Donaldson" ||
            selectedCouncil?.name === "Ofi") &&
          chartsIntroIsActive && <ChartsIntro />}
      </div>
    </div>
  );
};

export default React.memo(RightWindowComponent);

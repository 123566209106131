import { apiStatus, pageMeta } from "../../common/httpCall";
import {
  IDEA_LIST_GET_ATTEMPT,
  IDEA_LIST_GET_SUCCESS,
  CREATE_IDEA_ATTEMPT,
  CREATE_IDEA_SUCCESS,
  RESET_ACTIVE_IDEA,
  GET_IDEA_SUCCESS,
  VOTE_IDEA_ATTEMPT,
  RE_VOTE_IDEA_ATTEMPT,
  VOTE_IDEA_SUCCESS,
  GET_IDEAS_STATUSES_COUNTER_ATTEMPT,
  GET_IDEAS_STATUSES_COUNTER_SUCCESS,
  EDIT_IDEA_SUCCESS,
  ADD_IDEA_TEAM_MEMBER_SUCCESS,
  DELETE_IDEA_TEAM_MEMBER_SUCCESS,
  IDEA_TEMPLATE_GET_ATTEMPT,
  IDEA_TEMPLATE_GET_SUCCESS,
  IDEA_SECTIONS_GET_ATTEMPT,
  IDEA_SECTIONS_GET_SUCCESS,
  ADD_IDEA_COMMENT_SUCCESS,
  DELETE_IDEA_COMMENT_SUCCESS,
  IDEA_DELETE_DOCUMENT_ATTEMPT,
  IDEA_COMPANIES_ADD_SUCCESS,
  IDEA_COMPANY_DELETE_SUCCESS,
  ADD_RELATED_PROJECT_SUCCESS,
  DELETE_RELATED_PROJECT_ATTEMPT,
  DELETE_RELATED_PROJECT_SUCCESS,
  IDEA_PRODUCT_DELETE,
  IDEA_RATING_SET_SUCCESS,
} from "./idea.action";
import {
  ADD_DOCUMENT_SUCCESS,
  GET_PLANT_BY_TYPE_SUCCESS,
  RESET_PLANT_BY_TYPE,
  UPDATE_PLANT_SUCCESS,
} from "../../common/actions/common.action";
import { THEME_IDEAS_DELETE_SUCCESS } from "../themes/themesIndividualPage/themesOverviewTab/overviewTab.action";

const initState = {
  ideas: [],
  getIdeasListLoading: false,
  createIdeaLoading: false,
  voteIdeaLoading: false,
  selectedIdea: null,
  ideasPageMeta: null,
  ideasStatusesCounter: {
    total_new_ideas: 0,
    total_approved_ideas: 0,
    total_paused_ideas: 0,
    total_stopped_ideas: 0,
  },
  ideasStatusesCounterLoading: false,
  ideasTemplate: null,
  ideasTemplateLoading: false,
  ideasSections: [],
  ideasSectionsLoading: false,
  ideasSectionsPageMeta: null,
  projectsLoading: false,
  ideaPlant: null,
};

const ideaReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESET_PLANT_BY_TYPE: {
      if (payload.type === 'Idea') {
        return {
          ...state,
          ideaPlant: null,
        }
      }

      return state;
    }

    case GET_PLANT_BY_TYPE_SUCCESS: {
      if (payload.response && payload.type === 'Idea') {
        const { areas, plant } = payload.response;

        return {
          ...state,
          ideaPlant: {
            areas,
            id: plant.id,
            name: plant.attributes.name,
          },
        }
      }

      return state;
    }

    case UPDATE_PLANT_SUCCESS: {
      if (payload.response && payload.type === 'Idea') {
        const { areas, plant } = payload.response
        return {
          ...state,
          ideaPlant: {
            areas,
            id: plant.id,
            name: plant.attributes.name,
          },
        }
      }

      return state;
    }

    case IDEA_PRODUCT_DELETE: {
      if (!state?.selectedIdea) {
        return state;
      }

      return {
        ...state,
        selectedIdea: {
          ...state.selectedIdea,
          products: state.selectedIdea.products.filter(p => p.id !== payload.product_id),
        }
      }
    }

    case IDEA_COMPANY_DELETE_SUCCESS: {
      return {
        ...state,
        selectedIdea: {
          ...state.selectedIdea,
          ideas_related_companies:
            state.selectedIdea.ideas_related_companies.filter(
              (c) => c.company.id !== payload.company_id
            ),
        },
      };
    }

    case IDEA_COMPANIES_ADD_SUCCESS: {
      if (!state?.selectedIdea) {
        return state;
      }

      const { status, response } = payload;
      if ([apiStatus.SUCCESS, apiStatus.CREATED].includes(status) && response) {
        const products = response?.filter(c => c.is_product) || [];
        const companies = response?.filter(c => !c.is_product) || [];

        return {
          ...state,
          selectedIdea: {
            ...state.selectedIdea,
            ideas_related_companies: [
              ...(state.selectedIdea.ideas_related_companies || []),
              ...companies,
            ],
            products: [
              ...(state.selectedIdea.products || []),
              ...products,
            ]
          },
        };
      }
      break;
    }

    case IDEA_DELETE_DOCUMENT_ATTEMPT: {
      return {
        ...state,
        selectedIdea: {
          ...state.selectedIdea,
          documents: state.selectedIdea.documents.filter(
            (d) => d.id !== payload.id
          ),
        },
      };
    }

    case ADD_DOCUMENT_SUCCESS: {
      if (!state.selectedIdea) {
        return state;
      }

      const { response, status } = payload;

      if (status === apiStatus.SUCCESS || status === apiStatus.CREATED) {
        return {
          ...state,
          selectedIdea: {
            ...state.selectedIdea,
            documents: [response, ...state.selectedIdea.documents],
          },
        };
      }

      break;
    }

    case DELETE_IDEA_COMMENT_SUCCESS: {
      const { status, response, comment } = payload;
      if ([apiStatus.SUCCESS].includes(status) && response) {
        return {
          ...state,
          selectedIdea: {
            ...state.selectedIdea,
            ideas_comments: state.selectedIdea.ideas_comments.filter(
              (c) => c.ideas_comment_id !== comment.ideas_comment_id
            ),
          },
        };
      }
      break;
    }

    case ADD_IDEA_COMMENT_SUCCESS: {
      const { status, response } = payload;
      if ([apiStatus.SUCCESS, apiStatus.CREATED].includes(status) && response) {
        return {
          ...state,
          selectedIdea: {
            ...state.selectedIdea,
            ideas_comments: [
              response,
              ...(state.selectedIdea.ideas_comments || []),
            ],
          },
        };
      }
      break;
    }

    case DELETE_IDEA_TEAM_MEMBER_SUCCESS: {
      const { status, response, id } = payload;
      if ([apiStatus.SUCCESS].includes(status) && response) {
        return {
          ...state,
          selectedIdea: {
            ...state.selectedIdea,
            ideas_members: state.selectedIdea.ideas_members.filter(
              (m) => m.idea_member_id !== id
            ),
          },
        };
      }
      break;
    }

    case ADD_IDEA_TEAM_MEMBER_SUCCESS: {
      const { status, response } = payload;
      if ([apiStatus.SUCCESS, apiStatus.CREATED].includes(status) && response) {
        return {
          ...state,
          selectedIdea: {
            ...state.selectedIdea,
            ideas_members: response,
          },
        };
      }
      break;
    }

    case EDIT_IDEA_SUCCESS: {
      const { status, response } = payload;
      if ([apiStatus.SUCCESS, apiStatus.CREATED].includes(status) && response) {
        return {
          ...state,
          selectedIdea: {
            ...state.selectedIdea,
            ...response,
          },
        };
      }
      break;
    }

    case RESET_ACTIVE_IDEA: {
      return {
        ...state,
        selectedIdea: null,
      };
    }

    case GET_IDEA_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response) {
          return {
            ...state,
            selectedIdea: response,
          };
        }
      }
      break;
    }
    case THEME_IDEAS_DELETE_SUCCESS: {
      return {
        ...state,
        selectedIdea: null,
      };
    }

    case IDEA_LIST_GET_ATTEMPT: {
      return {
        ...state,
        getIdeasListLoading: true,
      };
    }

    case IDEA_LIST_GET_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response) {
          return {
            ...state,
            ideas: response,
            getIdeasListLoading: false,
            ideasPageMeta: pageMeta(payload),
          };
        }
      }
      break;
    }

    case CREATE_IDEA_ATTEMPT: {
      return {
        ...state,
        createIdeaLoading: true,
      };
    }

    case CREATE_IDEA_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.CREATED && response.id) {
          return {
            ...state,
            ideas: [response, ...state.ideas],
            createIdeaLoading: false,
          };
        }
      }
      break;
    }

    case RE_VOTE_IDEA_ATTEMPT:
    case VOTE_IDEA_ATTEMPT: {
      return {
        ...state,
        voteIdeaLoading: true,
      };
    }

    case VOTE_IDEA_SUCCESS: {
      if (payload) {
        const { status, response, isProfileUse } = payload;
        if (
          [apiStatus.SUCCESS, apiStatus.CREATED].includes(status) &&
          response
        ) {
          const { total_up_votes, total_down_votes } = response;

          if (isProfileUse) {
            return {
              ...state,
              selectedIdea: {
                ...state.selectedIdea,
                total_up_votes,
                total_down_votes,
                user_vote: response,
              },
            };
          }

          const { ideas } = state;
          const index = ideas.findIndex(
            ({ id }) => id === response.idea.idea_id
          );
          if (ideas[index]) {
            // eslint-disable-next-line camelcase
            ideas[index] = {
              ...ideas[index],
              total_up_votes,
              total_down_votes,
              user_vote: response,
            };
          }

          return {
            ...state,
            ideas: ideas.slice(),
            voteIdeaLoading: false,
          };
        }
      }
      break;
    }

    case GET_IDEAS_STATUSES_COUNTER_ATTEMPT: {
      return {
        ...state,
        ideasStatusesCounterLoading: true,
      };
    }

    case GET_IDEAS_STATUSES_COUNTER_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response) {
          return {
            ...state,
            ideasStatusesCounter: response,
            ideasStatusesCounterLoading: false,
          };
        }
      }
      break;
    }

    case IDEA_TEMPLATE_GET_ATTEMPT: {
      return {
        ...state,
        ideasTemplateLoading: true,
      };
    }

    case IDEA_TEMPLATE_GET_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response) {
          return {
            ...state,
            ideasTemplate: response,
            ideasTemplateLoading: false,
          };
        }
      }
      break;
    }

    case IDEA_SECTIONS_GET_ATTEMPT: {
      return {
        ...state,
        ideasSectionsLoading: true,
      };
    }

    case IDEA_SECTIONS_GET_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response) {
          return {
            ...state,
            ideasSections: response,
            ideasSectionsLoading: false,
            ideasSectionsPageMeta: pageMeta(payload),
          };
        }
      }
      break;
    }

    case ADD_RELATED_PROJECT_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response) {
          return {
            ...state,
            selectedIdea: {
              ...state.selectedIdea,
              projects: [
                ...state.selectedIdea.projects,
                { ...response[0], name: response[0].project.name },
              ],
            },
          };
        }
      }
      break;
    }
    case DELETE_RELATED_PROJECT_ATTEMPT: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response) {
          return {
            ...state,
            projectsLoading: true,
          };
        }
      }
      break;
    }

    case DELETE_RELATED_PROJECT_SUCCESS: {
      if (payload) {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS && response) {
          return {
            ...state,
            projectsLoading: false,

            selectedIdea: {
              ...state.selectedIdea,
              projects: [
                ...state.selectedIdea.projects.filter(
                  (proj) => proj.project_id !== response.project.id
                ),
              ],
            },
          };
        }
      }
      break;
    }

    case IDEA_RATING_SET_SUCCESS: {
      if (payload.response?.data?.attributes?.rating_template) {
        return {
          ...state,
          selectedIdea: {
            ...state.selectedIdea,
            rating_template: {
              ...state.selectedIdea.rating_template,
              ...payload.response.data.attributes.rating_template,
            },
            rating_id:
              payload.response.data.attributes.rating_id ||
              state.selectedIdea.rating_id,
          },
        };
      }

      return state;
    }

    default:
      return state;
  }

  return state;
};

export default ideaReducer;

import checkForThemes from "./checkForThemes";

const kyndrylFilter = (menuItem, selectedCouncil) => {
  if (menuItem.hideForViewer && menuItem.path !== "/home") {
    return false;
  }
  if (menuItem.gskOnly) {
    return false;
  }
  if (menuItem.mobileOnly) {
    return false;
  }
  if (menuItem.claimProfileOnly) {
    return false;
  }

  return checkForThemes(menuItem.path, selectedCouncil);
};
export default kyndrylFilter;

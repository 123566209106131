import React, { useState, useEffect } from "react";

import { Label, MoreOptions, UploadImgComponent } from "modules/primitives";
import { ACTIVITY_OPTIONS_WITHOUT_DELETE } from "common/constants.js";
import styles from "./styles.module.scss";
import { Icon, smSize } from "../../../../../../common/icon";

const ImageComponent = ({ item, handleEditClick, handleUpdateForm, companyId, selectedCouncil, disabled }) => {
  const [isImgValid, setIsImgValid] = useState(true);
  const [showField, setShowField] = useState(false);

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }
  };

  useEffect(() => {
    if (item?.field_value) {
      setIsImgValid(true);
    }
  }, [item]);

  const onImgUpdate = (img) => {
    handleUpdateForm({
      ...item,
      field_value: img,
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{item?.field_name || "Image"}</h5>
          {
            item?.field_placeholder?.length ? (
              <span className="text-muted small">{item.field_placeholder}</span>
            ) : null
          }
        </div>
        {!disabled && (
          <div className={styles.rightWrp}>
            <div className={styles.colMore}>
              <MoreOptions
                options={ACTIVITY_OPTIONS_WITHOUT_DELETE}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles.photoContainer}>
        <div className={{ display: "flex", justifyContent: "center" }}>
          {item.field_value && isImgValid ? (
            <div className="d-flex flex-row align-items-start justify-content-center">
              <img
                className={styles.imgContainer}
                key={item.field_value}
                src={item.field_value}
                onError={() => setIsImgValid(false)}
              />
              {
                showField ? (
                  <div className="d-flex flex-column justify-content-center cursor-pointer ml-3">
                    <Label>Upload New Image</Label>
                    <UploadImgComponent
                      label=""
                      name="link"
                      uploadDirName={`srm/${selectedCouncil.id}/companies/${companyId}/custom_fields/images/key`}
                      onChange={onImgUpdate}
                      imgSize={styles.imgSize}
                      align="center"
                    />
                  </div>
                ) : (
                  <div className="cursor-pointer ml-2" onClick={() => setShowField(true)}>
                    <Icon
                      {...smSize}
                      icon="icn-edit"
                    />
                  </div>
                )
              }
            </div>
          ) : (
            <div className="d-flex flex-column">
              <Label>Upload Image</Label>
              <UploadImgComponent
                label=""
                name="link"
                uploadDirName={`srm/${selectedCouncil.id}/companies/${companyId}/custom_fields/images/key`}
                onChange={onImgUpdate}
                imgSize={styles.imgSize}
                align="center"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default React.memo(ImageComponent);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import styles from "./companyRatings.module.scss";
import SliderComponent from "./slider/slider.component";
import { Note } from "../../../../../primitives";
import {
  apiStatus,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPut,
} from "../../../../../../common/httpCall";
import { Icon, mdSize } from "../../../../../../common/icon";
import {
  setUpdateChartListCompanyDetails,
  updateCompanyRatingValue,
  setChartsShouldReRender,
} from "../../../companyMain.action";
import RatingAddNotesComponent from "../../../../company/overview/ratings/ratingAddNotes/ratingAddNotes.component";
import { homeFunnelShouldReRender } from "modules/home/home.actions";

const CompanyRatingsComponent = ({
  company,
  ratingsData,
  type = "",
  homeUse,
  chartUse,
}) => {
  const [selectedNote, setSelectedNote] = useState();
  const [ratings, setRatings] = useState(null);
  const [notes, setNotes] = useState(null);
  const [showLoadingNotes, setShowLoadingNotes] = useState(false);
  const [companyId, setCompanyId] = useState(null);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (company.id !== companyId) {
      setRatings(null);
      getCompanyNotes();
      setCompanyId(company.id);
    }
  }, [company]);

  useEffect(() => {
    if (companyId) {
      const timeout = setTimeout(() => {
        setRatings(ratingsData);
        clearTimeout(timeout);
      });
    }
  }, [companyId, ratingsData]);

  const handleOnChange = async (obj, category) => {
    const { value } = obj;

    // not sure about
    if (value === ratings.user_total_rating) {
      return;
    }

    if (!value || value === 0) {
      enqueueSnackbar("Please select a value bigger than 0.", {
        variant: "warning",
      });
      return;
    }

    const payload = {
      company_id: company.id,
      council_id: selectedCouncil.id,
      user_id: session.id,
      rating_template_id: ratings.rating_template?.rating_template_id,
      rating_category_id: category.rating_category_id,
      universal_rating_id: category.user_universal_rating?.universal_rating_id,
      value,
      enqueueSnackbar,
    };

    dispatch(
      setUpdateChartListCompanyDetails({
        companyId: company.id,
        newCompanyData: {
          council_company_score: value,
        },
      })
    );

    if (homeUse) {
      dispatch(homeFunnelShouldReRender(true));
    }

    if (!category.user_universal_rating) {
      httpPost({
        call: `ratings/universal/?council_id=${payload.council_id}&company_id=${payload.company_id}`,
        data: {
          universal_rating: { ...payload },
        },
      })
        .pipe()
        .subscribe((result) => {
          if (
            result.status === apiStatus.CREATED ||
            result.status === apiStatus.SUCCESS
          ) {
            payload.enqueueSnackbar("Successfully added", {
              variant: "success",
            });

            const { response } = result;

            const newRatings = {
              ...ratings,
              user_total_rating: response.user_total_rating,
            };

            setRatings(newRatings);

            dispatch(
              updateCompanyRatingValue({
                companyId: company.id,
                council_company_score: response.council_company_score,
              })
            );

            if (chartUse) {
              dispatch(setChartsShouldReRender(true));
            }

            if (type) {
              // dispatch(setShouldUpdateListItems({
              //   type: type,
              //   shouldUpdate: true,
              // }))
            }
          }
        });
    } else {
      httpPatch({
        call: `ratings/universal/${payload.universal_rating_id}?council_id=${payload.council_id}&company_id=${payload.company_id}`,
        data: {
          universal_rating: { ...payload },
        },
      })
        .pipe()
        .subscribe((result) => {
          if (
            result.status === apiStatus.CREATED ||
            result.status === apiStatus.SUCCESS
          ) {
            payload.enqueueSnackbar("Successfully added", {
              variant: "success",
            });

            const { response } = result;

            const newRatings = {
              ...ratings,
              user_total_rating: response.user_total_rating,
            };

            setRatings(newRatings);

            dispatch(
              updateCompanyRatingValue({
                companyId: company.id,
                council_company_score: response.council_company_score,
              })
            );

            if (chartUse) {
              dispatch(setChartsShouldReRender(true));
            }

            if (type) {
              // dispatch(setShouldUpdateListItems({
              //   type: type,
              //   shouldUpdate: true,
              // }))
            }
          }
        });
    }
  };

  const getCompanyNotes = () => {
    setShowLoadingNotes(true);

    httpGet({
      apiVersion: "v2",
      call: `companies/${company.id}/notes`,
    })
      .pipe()
      .subscribe((res) => {
        if (res?.response) {
          setNotes(res.response);
          setShowLoadingNotes(false);
        }

        setShowLoadingNotes(false);
      });
  };

  const handleAddEdit = (value) => {
    // edit mode
    if (value.id) {
      const data = {
        note: {
          description: value.note,
        },
      };

      httpPut({
        apiVersion: "v2",
        call: `companies/${company.id}/notes/${value.id}`,
        data,
      })
        .pipe()
        .subscribe((res) => {
          if (res.response) {
            const newNotes = notes.map((note) => {
              if (note.id === res.response.id) {
                return {
                  ...note,
                  ...res.response,
                };
              }

              return note;
            });

            setNotes(newNotes);
          }
        });

      return;
    }

    // add new
    addNewNote(value);
  };

  const addNewNote = (value) => {
    const data = {
      note: {
        description: value.note,
      },
    };

    httpPost({
      apiVersion: "v2",
      call: `companies/${company.id}/notes`,
      data,
    })
      .pipe()
      .subscribe((res) => {
        if (res.response) {
          setNotes([...notes, res.response]);
        }
      });
  };

  const handleMoreOptionClick = (value, note) => {
    switch (value) {
      case "remove":
        deleteNote(note.id);
        break;
      case "edit":
        setSelectedNote({
          id: note.id,
          note: note.description.replace(/(<br>)/g, "\n"),
        });
        break;
      default:
        break;
    }
  };

  const deleteNote = (noteId) => {
    httpDelete({
      apiVersion: "v2",
      call: `companies/${company.id}/notes/${noteId}`,
    })
      .pipe()
      .subscribe((res) => {
        if (res.response) {
          const newNotes = notes.filter((note) => note.id !== noteId);
          setNotes(newNotes);
        }
      });
  };

  if (!ratings) {
    return (
      <div className="p-3 d-flex align-items-center justify-content-center">
        Loading ratings...
      </div>
    );
  }

  return (
    <div className={styles.taskInfoWrp}>
      <div className={styles.companyWrp}>
        <h4 className={styles.title}>Rate Company</h4>
      </div>

      <div className={styles.scoreWrp}>
        <div className={styles.title}>Your total score</div>
        <div className={styles.value}>
          {ratings?.user_total_rating.toFixed(2) || 0}
        </div>
      </div>
      {ratings?.rating_template?.rating_categories?.length > 0 && (
        <div className={styles.container}>
          {ratings.rating_template.rating_categories.map((category) => {
            if (!category.parent_id) {
              return (
                <Accordion
                  className={styles.accordion}
                  key={category.rating_category_id}
                >
                  <AccordionSummary
                    expandIcon={
                      category.sub_categories[0] ? (
                        <Icon
                          icon="icn-table-down-chevron"
                          {...mdSize}
                          className={styles.iconColor}
                        />
                      ) : (
                        <div className={styles.stubIcon} />
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={
                      category.sub_categories[0]
                        ? styles.accordionSummary
                        : styles.colll
                    }
                  >
                    <div
                      className={styles.sliderContainer}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <SliderComponent
                        className={styles.slider}
                        rating={{
                          ...category,
                          id: category.rating_category_id,
                          name: category.name,
                          min: 1,
                          max: ratingsData?.rating_template?.max_scale,
                        }}
                        key={category.rating_category_id}
                        value={category?.user_universal_rating?.value || ""}
                        onChange={(e) => {
                          handleOnChange(e, category);
                        }}
                      />
                    </div>
                  </AccordionSummary>
                  {category.sub_categories?.length ? (
                    <AccordionDetails>
                      <div className={styles.childRatingContainer}>
                        {category.sub_categories?.map((subCategory) => (
                          <div
                            className={styles.sliderContainer}
                            key={subCategory.rating_category_id}
                          >
                            <SliderComponent
                              rating={{
                                ...subCategory,
                                id: subCategory.rating_category_id,
                                name: subCategory.name,
                                max: ratingsData?.rating_template?.max_scale,
                                customStyles: {
                                  trackStyle: {
                                    backgroundColor: "#7cc1e4",
                                  },
                                  handleStyle: {
                                    backgroundColor: "lightgray",
                                  },
                                },
                              }}
                              value={subCategory?.value || 0}
                              onChange={(e) => handleOnChange(e, subCategory)}
                            />
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>
                  ) : null}
                </Accordion>
              );
            }
            return null;
          })}
        </div>
      )}
      <div className={styles.notesWrp}>
        <label className={styles.label}>Notes</label>
        <div className={styles.notesCon}>
          {showLoadingNotes ? (
            <span className="d-flex flex-1 justify-content-center">
              Loading notes...
            </span>
          ) : null}
          {notes && notes.length > 0
            ? notes.map((note) => (
                <Note
                  key={note.id}
                  note={note}
                  handleMoreOptionClick={(val) =>
                    handleMoreOptionClick(val, note)
                  }
                  companyName={company.name}
                  currentUser={session}
                />
              ))
            : null}
        </div>
      </div>
      <div className={styles.addNoteWrp}>
        <RatingAddNotesComponent
          selectedNote={selectedNote}
          onAddEdit={handleAddEdit}
        />
      </div>
    </div>
  );
};

export default React.memo(CompanyRatingsComponent);

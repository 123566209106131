import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { useSelector, useDispatch } from "react-redux";
import adminFuseHoc from "common/HOC/adminFuseHoc";
import { useSnackbar } from "notistack";
import { CSVLink } from "react-csv";
import {
  Loading,
  MoreOptions,
  OptionType,
  Button,
  BtnType,
} from "modules/primitives/index";
import { useModelPopup } from "../../../common/hooks";
import ConfirmDeleteComponent from "../../../common/components/confirmDelete";
import {
  councilGetTeams,
  councilSearchTeams,
} from "../../council/council.action";
import { getStagesTemplateAttempt } from "./adminProjects.actions";
import styles from "./adminProjects.module.scss";
import AddProjectTemplateComponent from "./addProjects/addProjectTemplate.component";

const AdminProjectsComponent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [csvData, setCsvData] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [searching, setSearching] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [stageProjects, setStageProjects] = useState([]);
  const csvLinkRef = useRef();

  const {
    adminProjectsReducer: { stageTemplates, loading, shouldReloadTemplates },
  } = useSelector((state) => state);

  useEffect(() => {
    if (stageTemplates) {
      const sortedDefault = stageTemplates.sort((a, b) => {
        if (a.default_template) {
          return -1;
        }
        if (!b.default_template) {
          return 1;
        }
        return 0;
      });
      setStageProjects(sortedDefault);
    }
  }, [stageTemplates]);

  const DisabledButton = ({ showTooltip, setShowTooltip }) => (
    <>
      {showTooltip && (
        <div className={styles.exportModalTooltip}>
          <p>
            {searching
              ? "Search cannot be exported"
              : "To be able to export list it must contain at least one element"}
          </p>
        </div>
      )}
      <Button
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
        className={styles.deleteSubmitButton}
        btn={BtnType.DISABLED}
      >
        EXPORT
      </Button>
    </>
  );

  const handleAddNewTeam = () => {
    popup.show({
      title: "Add Project Template",
      show: true,
      height: "300",
      width: "540",
      component: <AddProjectTemplateComponent />,
    });
  };

  useEffect(() => {
    try {
      dispatch(getStagesTemplateAttempt({ enqueueSnackbar }));
    } catch (err) {
      console.log("error on get stages", err);
    }
  }, []);

  useEffect(() => {
    try {
      if (shouldReloadTemplates) {
        dispatch(getStagesTemplateAttempt({ enqueueSnackbar }));
      }
    } catch (err) {
      console.log("error on get stages", err);
    }
  }, [shouldReloadTemplates]);
  useEffect(() => {
    if (stageTemplates) {
      setCsvData(() => {
        const csvDataCont = [
          ["Project Template", "Description", "Positions", "Stages", "Tasks"],
        ];
        stageTemplates.map((template) => {
          csvDataCont.push([
            template.name,
            template.description,
            template.sort_order,
            template.total_stages,
            template.total_tasks,
          ]);
        });
        return csvDataCont;
      });
    }
  }, [stageTemplates]);

  const handleExportList = () => {
    csvLinkRef.current.link.click();
  };

  const moreOptions = [
    { val: "edit", text: "Edit" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const handleMoreOptionClick = (event, projectTemplate, name) => {
    if (event === "edit") {
      popup.show({
        title: "Edit Project Template",
        show: true,
        height: "300",
        width: "540",
        component: (
          <AddProjectTemplateComponent
            data={projectTemplate}
            mode="edit"
            projects={stageProjects}
          />
        ),
      });
    }
    if (event === "delete") {
      if (projectTemplate.default_template) {
        enqueueSnackbar("Default template can not be deleted!", {
          variant: "warning",
        });
        return;
      }
      popup.show({
        title: "Confirmation Prompt",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={projectTemplate.id}
            enqueueSnackbar={enqueueSnackbar}
            actionType="deleteStageTemplate"
          />
        ),
      });
    }
  };

  const onRowClick = (rowInfo) => {
    history.push(`projects/stages/${rowInfo.original.id}`);
  };

  const handleSearchTeams = (e) => {
    if (e.target.value === "") {
      setSearching(false);
      dispatch(councilGetTeams({ enqueueSnackbar }));
    } else {
      dispatch(councilSearchTeams({ query: e.target.value, enqueueSnackbar }));
      setSearching(true);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Project Template",
        accessor: "name",
        minWidth: 300,
        style: {
          cursor: "pointer",
        },
      },
      {
        Header: "Description",
        accessor: "description",
        maxWidth: 400,
        minWidth: 140,
        width: 300,
      },
      {
        Header: "Position",
        accessor: "sort_order",
        maxWidth: 80,
        minWidth: 70,
        width: 70,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Automated Due Dates",
        id: "automate_due_dates",
        accessor: (row) => {
          if (row.automate_due_dates === true) {
            return (
              <div
                style={{
                  color: "green",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Yes
              </div>
            );
          }
          return (
            <div
              style={{
                color: "red",
                justifyContent: "center",
                display: "flex",
              }}
            >
              No
            </div>
          );
        },
        maxWidth: 400,
        minWidth: 140,
        width: 200,
      },
      {
        Header: "Stages",
        accessor: "total_stages",
        maxWidth: 40,
        minWidth: 30,
        width: 60,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Tasks",
        accessor: "total_tasks",
        maxWidth: 400,
        minWidth: 60,
        width: 60,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Fields",
        id: "project_fields_count",
        maxWidth: 400,
        minWidth: 60,
        width: 60,
        style: {
          display: "flex",
          justifyContent: "center",
        },
        accessor: (row) => row.project_fields_count,
      },
      {
        Header: "Options",
        id: "options",
        sortable: false,
        accessor: (row) => (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row, row?.name);
            }}
          />
        ),
        minWidth: 80,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [stageProjects]
  );

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <span
            className={styles.rootText}
            onClick={() => {
              history.push("/admin");
            }}
          >
            ADMIN
          </span>
          <span className={styles.separationBar}> / </span>
          <span className="d-inline-block mx-2">PROJECTS</span>
          <span className={styles.separationBar}> / </span>
          <Link to="/admin/projects/fields" className={styles.rootText}>
            FIELDS
          </Link>
        </h4>
      </div>
      <div className={styles.bodyWrp}>
        <div>
          <div className={styles.buttonWrp}>
            <div>
              <Button
                className={styles.addNew}
                btn={BtnType.REGULAR}
                icon="icn-add"
                onClick={handleAddNewTeam}
              >
                Add Template
              </Button>
            </div>

            {exportLoading ? (
              <Loading />
            ) : stageTemplates.length > 0 && !searching ? (
              <div>
                <Button onClick={handleExportList} btn={BtnType.OUTLINE}>
                  EXPORT
                </Button>
                <CSVLink
                  data={csvData}
                  filename="Traction Project Templates.csv"
                  style={{ display: "none" }}
                  ref={csvLinkRef}
                />
              </div>
            ) : (
              <DisabledButton
                setShowTooltip={setShowTooltip}
                showTooltip={showTooltip}
              />
            )}
            <div className={styles.totalCount}>
              {stageTemplates?.length || "0"} templates
            </div>
          </div>
          <ReactTable
            data={stageProjects}
            columns={columns}
            className={styles.table}
            loading={loading ? <Loading /> : null}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: () => onRowClick(rowInfo),
                  onMouseEnter: (e) => {
                    setHoveredRow(rowInfo.index);
                  },
                  onMouseLeave: (e) => {
                    setHoveredRow(null);
                  },
                  style: {
                    cursor: "pointer",
                    background: rowInfo?.original?.default_template
                      ? "#d1eefc"
                      : rowInfo.index === hoveredRow
                      ? "#efefef"
                      : "transparent",
                  },
                };
              }
              return {};
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default adminFuseHoc(AdminProjectsComponent);

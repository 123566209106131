import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import { useSnackbar } from "notistack";

import { getMeAttempt } from "modules/auth/auth.action";
import { headerMenu, homeXHeaderMenu } from "./header.constant";
import styles from "./header.module.scss";
import LoggedUser from "../../../header/loggedUser/loggedUser.component";
import { appMobileNaviSet } from "../../../app.action";
import CompanyHeaderLogo from "../companyHeaderLogo";

const ClaimProfileHeader = (props) => {
  const [pathname, setPathName] = useState(props.location.pathname);
  const [rootName, setRootName] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    appReducer: { showMobileNavi },
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (session) {
      const action = getMeAttempt({ sessionId: session.id, enqueueSnackbar });
      dispatch(action);
    }
  }, [selectedCouncil, dispatch]);

  useEffect(() => {
    setPathName(history?.location?.pathname);
  }, [history.location]);

  useEffect(
    () => history.listen((path) => {
      setPathName(path.pathname);
    }),
    [],
  );

  useEffect(() => {
    const paths = pathname.split("/");
    setRootName(paths[1]);
  }, [pathname]);

  const handlehamburgerClick = () => {
    dispatch(appMobileNaviSet(!showMobileNavi));
  };

  const filteredHeaderMenu = useMemo(() => {
    if (selectedCouncil?.traction_tag === 'home_x') {
      return homeXHeaderMenu
    }

    return headerMenu;
  }, [selectedCouncil?.traction_tag]);

  return (
    <>
      <div className={styles.headerWrp}>
        <div className={styles.headerCon}>
          <div className="d-flex">
            <CompanyHeaderLogo />
            <div
              className={`d-flex align-items-center ${styles.companyNameWrapper}`}
            >
              <span className={styles.companyName}>
                {session?.invited_company?.name}
              </span>
            </div>
          </div>
          <div className={styles.additionalWrr}>
            <ul className={classNames("hideInLap", styles.menuWrp)}>
              {filteredHeaderMenu.map((menu, index) => (
                <Link
                  key={index}
                  to={menu.path}
                  className={classNames(
                    styles.menuItem,
                    menu.path.substr(1) === rootName
                      || menu.path.split("/")[1] === rootName
                      ? styles.active
                      : "",
                  )}
                >
                  {menu.text}
                </Link>
              ))}
            </ul>

            <div className={styles.loggedUserWrp}>
              <div
                className={classNames("showInLap", styles.hambuger)}
                onClick={handlehamburgerClick}
              />
              <div className={classNames("hideInLap", styles.loggedUserCon)}>
                <LoggedUser />
              </div>
              <div className={styles.logoWrp} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ClaimProfileHeader);

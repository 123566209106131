import React, { useEffect, useState } from "react";
import {
  Tab, Tabs, TabList, TabPanel,
} from "react-tabs";

import "react-tabs/style/react-tabs.css";
import "./react-tabs.scss";

const TabComponent = (props) => {
  const {
    tabList, defaultIndex, onSelect, isNavigationHidden, rightContent,
  } = props;

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (defaultIndex >= 0) {
      setActiveTab(defaultIndex);
    }
  }, [defaultIndex]);

  const handleSelect = (value) => {
    setActiveTab(value);
    onSelect(value);
  };

  return (
    <Tabs selectedIndex={activeTab || 0} onSelect={handleSelect}>
      <div>
        {!isNavigationHidden ? (
          <TabList>
            {tabList.map(({
              tab, name, attr, icon,
            }, index) => (
              <Tab key={`tab${index}`} {...attr}>
                {icon ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      className="mr-1"
                      dangerouslySetInnerHTML={{ __html: icon }}
                    />
                    <span>{name}</span>
                  </div>
                ) : (
                  name
                )}
              </Tab>
            ))}
            {rightContent && (
              <li className="react-tabs__tab position-relative">
                {rightContent}
              </li>
            )}
          </TabList>
        ) : (
          <div style={{ height: 34 }} />
        )}
      </div>
      <div>
        {tabList.map(({ component }, index) => (
          <TabPanel key={`panel${index}`}>{component}</TabPanel>
        ))}
      </div>
    </Tabs>
  );
};

export default React.memo(TabComponent);

import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  httpGet,
  errorHandler,
  httpPost,
  httpDelete,
  httpPut,
} from "../../../common/httpCall";
import {
  THEMES_GET_ATTEMPT,
  themesGetAttemptSuccess,
  THEMES_GET_TOPICS_ATTEMPT,
  themesGetTopicSuccess,
  THEMES_GET_INDUSTRIES_ATTEMPT,
  themesGetIndustriesSuccess,
  THEME_ADD_ATTEMPT,
  themesAddSuccess,
  THEMES_GET_INDIVIDUAL_ATTEMPT,
  themesGetIndividualSuccess,
  THEME_DELETE_ATTEMPT,
  themesDeleteSuccess,
  THEME_EDIT_ATTEMPT,
  themesEditSuccess,
  THEMES_COMPANIES_GET_ATTEMPT,
  themesCompaniesGetSuccess,
  PIPELINE_GET_INVEST_STAGES_ACROSS_ATTEMPT,
  pipelineGetInvestStagesAcrossSuccess,
  THEMES_SEARCH_ATTEMPT,
  themesSearchSuccess,
  THEMES_FAV_ATTEMPT,
  themesFavSuccess,
  THEMES_FAV_DELETE_ATTEMPT,
  themesFavDeleteSuccess,
  themesCompaniesSearchSuccess,
  THEMES_COMPANIES_SEARCH_ATTEMPT,
} from "./themesMain.action";
import { makeFakeAction } from "modules/reporting/store/reporting.actions";

export const epicGetThemes = (action$, state$) =>
  action$.pipe(
    ofType(THEMES_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, items, page, isDropdown, cb } }) =>
      httpGet({
        apiVersion: "v1",
        call: `themes?items=${items || 18}&page=${
          page || 1
        }&is_dropdown=${isDropdown}`,
        data: {
          search: {
            query: "",
          },
        },
      }).pipe(
        map((result) => {
          if (isDropdown) {
            if (cb) {
              cb(result.response);
            }

            return makeFakeAction(result);
          }

          return themesGetAttemptSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetTopics = (action$, state$) =>
  action$.pipe(
    ofType(THEMES_GET_TOPICS_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `councils/${payload.councilId}/topics/resource_topics`,
      }).pipe(
        map((result) => themesGetTopicSuccess(result)),
        catchError((error) => errorHandler(error, payload.enqueueSnackbar))
      )
    )
  );

export const epicGetIndustries = (action$, state$) =>
  action$.pipe(
    ofType(THEMES_GET_INDUSTRIES_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `councils/${payload.councilId}/industries/resource_industries`,
      }).pipe(
        map((result) => themesGetIndustriesSuccess(result)),
        catchError((error) => errorHandler(error, payload.enqueueSnackbar))
      )
    )
  );

export const epicAddTheme = (action$) =>
  action$.pipe(
    ofType(THEME_ADD_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: "themes",
        data: { theme: { ...data } },
      }).pipe(
        map(
          (result) => {
            if (enqueueSnackbar) {
              enqueueSnackbar("Theme added with success", {
                variant: "success",
              });
            }
            return themesAddSuccess(result);
          },
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
      )
    )
  );

export const epicEditTheme = (action$) =>
  action$.pipe(
    ofType(THEME_EDIT_ATTEMPT),
    switchMap(({ payload: { data, id, enqueueSnackbar } }) =>
      httpPut({
        apiVersion: "v1",
        call: `themes/${id}`,
        data: { theme: { ...data } },
      }).pipe(
        map(
          (result) => {
            if (enqueueSnackbar) {
              enqueueSnackbar("Theme edited with success", {
                variant: "success",
              });
            }
            return themesEditSuccess(result);
          },
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
      )
    )
  );

export const epicGetIndividualTheme = (action$, state$) =>
  action$.pipe(
    ofType(THEMES_GET_INDIVIDUAL_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpGet({
        call: `themes/${id}`,
        apiVersion: "v3",
      }).pipe(
        map((result) => themesGetIndividualSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

export const epicDeleteTheme = (action$, state$) =>
  action$.pipe(
    ofType(THEME_DELETE_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpDelete({
        call: `themes/${id}`,
      }).pipe(
        map((result) => themesDeleteSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

export const epicGetThemesCompanies = (action$, state$) =>
  action$.pipe(
    ofType(THEMES_COMPANIES_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v1",
        call: "themes/council_relationship_stages/across",
      }).pipe(
        map((result) => themesCompaniesGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetThemesInvestCompanies = (action$, state$) =>
  action$.pipe(
    ofType(PIPELINE_GET_INVEST_STAGES_ACROSS_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v2",
        call: "themes/pipeline/investment_stages/search",
      }).pipe(
        map((result) => pipelineGetInvestStagesAcrossSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicSearchThemes = (action$) =>
  action$.pipe(
    ofType(THEMES_SEARCH_ATTEMPT),
    switchMap(({ payload: { items, page, data, enqueueSnackbar, fromMain } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/search?items=${items || 18}&page=${page || 1}${
          fromMain ? "&view=main" : ""
        }`,
        data,
      }).pipe(
        map(
          (result) => themesSearchSuccess(result),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
      )
    )
  );

export const epicSearchThemesCompanies = (action$) =>
  action$.pipe(
    ofType(THEMES_COMPANIES_SEARCH_ATTEMPT),
    switchMap(({ payload: { items, page, data, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v2",
        call: `themes/pipeline/relationship_stages/search`,
        data,
      }).pipe(
        map(
          (result) => {
            return themesCompaniesSearchSuccess(result);
          },
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
      )
    )
  );

export const epicFavTheme = (action$) =>
  action$.pipe(
    ofType(THEMES_FAV_ATTEMPT),
    switchMap(({ payload: { themeId, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `/themes/${themeId}/favorites`,
      }).pipe(
        map(
          (result) => themesFavSuccess(result),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
      )
    )
  );

export const epicDeleteFavTheme = (action$, state$) =>
  action$.pipe(
    ofType(THEMES_FAV_DELETE_ATTEMPT),
    switchMap(({ payload: { themeId, enqueueSnackbar } }) =>
      httpDelete({
        call: `/themes/${themeId}/favorites`,
      }).pipe(
        map((result) => themesFavDeleteSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

const themesMainEpic = combineEpics(
  epicGetThemes,
  epicGetTopics,
  epicGetIndustries,
  epicAddTheme,
  epicGetIndividualTheme,
  epicDeleteTheme,
  epicEditTheme,
  epicGetThemesCompanies,
  epicGetThemesInvestCompanies,
  epicSearchThemes,
  epicFavTheme,
  epicDeleteFavTheme,
  epicSearchThemesCompanies
);

export default themesMainEpic;

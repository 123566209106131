import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  BtnType,
  Button,
  Loading,
  MoreOptions,
  OptionType,
} from "modules/primitives/index";
import ReactTable from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import adminFuseHoc from "common/HOC/adminFuseHoc";
import classnames from "classnames";
import { debounce } from "throttle-debounce";
import styles from "./adminApplicants.module.scss";
import ConfirmDeleteComponent from "../../../common/components/confirmDelete";
import { httpGet } from "../../../common/httpCall";
import { applicantsListGet, updateApplication } from "./adminApplicants.action";
import { useModelPopup } from "../../../common/hooks";

const AdminApplicantsComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [exportLoading, setExportLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [tableKey, setTableKey] = useState(Math.random());
  const [searchQuery, setSearchQuery] = useState(null);

  const {
    councilReducer: { selectedCouncil },
    adminApplicantsReducer: {
      applicantList,
      searchResults,
      listLoading,
      applicantPageNr,
    },
  } = useSelector((state) => state);

  const pageSizeOptions = [pageSize, 5, 10, 20, 50, 100];

  const handleCreateFormClick = (e) => {
    history.push("/admin/applicants/forms/create");
  };

  const moreOptions = [
    { val: "view", text: "View" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const handleMoreOptionClick = (event, id) => {
    if (["view", "edit"].includes(event)) {
      history.push(`/admin/applicants/${id}/view`);
    }

    if (event === "delete") {
      popup.show({
        title: "Confirmation Delete",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={id}
            enqueueSnackbar={enqueueSnackbar}
            councilId={selectedCouncil?.id}
            actionType="deleteApplication"
          />
        ),
      });
    }
  };

  const handleExportList = () => {
    setExportLoading(true);
    httpGet({
      call: `applicants/submissions?items=${applicantPageNr?.total}`,
      data: {
        query: "*",
      },
    }).subscribe(
      async (res) => {
        setExportLoading(false);
        setCsvData(() => {
          const csvDataCont = [
            ["Applicant", "Website", "Date Submitted", "Approve"],
          ];

          res.response.map((item) => {
            csvDataCont.push([
              item.applicant_name,
              item.website,
              format(new Date(item.created_at), "yyyy-MM-dd"),
              item.state,
            ]);
          });
          csvDataCont.push(applicantList);

          return csvDataCont;
        });
        csvLinkRef.current.link.click();
      },
      (err) => {
        setExportLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      },

      () => {
        setExportLoading(false);
        enqueueSnackbar("List succesfully exported.", {
          variant: "success",
        });
      }
    );
  };

  const handleChangePageSize = (e) => {
    setPageSize(+e.target.value);
  };

  const handlePageSelect = (e) => {
    setPage(e.target.value);
  };

  const handleNextClick = () => {
    setPage(page + 1);
  };

  const handlePrevClick = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const isNextDisabled = () => {
    if (page + 1 > applicantPageNr?.pages) {
      return styles.disabledButton;
    }
    return styles.paginationButton;
  };

  const handleStatusChangeClick = (id, state) => {
    dispatch(
      updateApplication({
        id,
        data: { state },
        enqueueSnackbar,
      })
    );
  };

  const handleSearch = debounce(1000, false, (val) => {
    setPage(1);
    dispatch(setSearchQuery(val || null));
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Company",
        accessor: "company_name",
        sortable: false,
      },
      {
        Header: "Applicant",
        accessor: "applicant_name",
        sortable: false,
      },
      {
        Header: "Website",
        id: "website",
        sortable: false,
        width: 100,
        style: {
          display: "flex",
          justifyContent: "center",
        },
        accessor: (row) => {
          if (!row.website) {
            return "N/A";
          }
          const withHttp = (url) =>
            !/^https?:\/\//i.test(url) ? `http://${url}` : url;

          return (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                window.open(withHttp(row.website), "_blank");
              }}
              btn={BtnType.FRAME_LESS}
              className={styles.linkButton}
            >
              Link
            </Button>
          );
        },
      },
      {
        Header: "Date Submitted",
        id: "created_at",
        sortable: false,
        width: 200,
        style: {
          display: "flex",
          justifyContent: "center",
        },
        accessor: (row) => {
          if (!row.created_at) return "N/A";

          return format(new Date(row.created_at), "MM/dd/yyyy");
        },
      },
      {
        Header: "Approve",
        id: "state",
        sortable: false,
        width: 200,
        style: {
          display: "flex",
          justifyContent: "center",
        },
        accessor: (row) => {
          if (row.state === "pending") {
            return (
              <>
                <Button
                  btn={BtnType.FRAME_LESS}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStatusChangeClick(row.id, "approved");
                  }}
                >
                  Yes
                </Button>
                <Button
                  btn={BtnType.FRAME_LESS}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStatusChangeClick(row.id, "rejected");
                  }}
                >
                  No
                </Button>
              </>
            );
          }

          return <span>{row.state}</span>;
        },
      },
      {
        Header: "Options",
        id: "options",
        width: 100,
        sortable: false,
        accessor: (row) => (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row.id);
            }}
          />
        ),
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (selectedCouncil) {
      const query = {
        page,
        items: pageSize,
      };

      if (searchQuery) {
        query.query = searchQuery;
        query["search[query]"] = searchQuery;
      }

      dispatch(
        applicantsListGet({
          enqueueSnackbar,
          query,
        })
      );
    }
  }, [selectedCouncil, pageSize, page, searchQuery]);

  useEffect(() => {
    const data =
      searchResults && searchResults[0] ? searchResults : applicantList;
    setTableData(data);
    setTableKey(Math.random());
    window.scrollTo({
      left: 0,
      top: 0,
      behaviour: "smooth",
    });
  }, [applicantList, searchResults, JSON.stringify(applicantList)]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <Link to="/admin" className={styles.rootText}>
            ADMIN
          </Link>
          <span className={styles.separationBar}> / </span>
          <span className={`${styles.rootText} ${styles.activeBreadcrumbs}`}>
            APPLICANTS
          </span>
          <span className={styles.separationBar}> / </span>
          <Link to="/admin/applicants/forms" className={styles.rootText}>
            Forms
          </Link>
        </h4>
      </div>
      <div className={styles.flexWrapper}>
        <div className={styles.createFormButton}>
          <Button
            btn={BtnType.REGULAR}
            icon="icn-add"
            onClick={() => handleCreateFormClick()}
          >
            Create Form
          </Button>
        </div>
        <div className={styles.applicantsWrp}>
          <div className={styles.searchBar}>
            <input
              placeholder="Search"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>

          <div className={styles.buttonWrp}>
            {exportLoading && <Loading />}

            {!exportLoading && (
              <div>
                <Button
                  className={classnames(
                    tableData.length <= 0 && styles.disabledBtn,
                    "mr-2"
                  )}
                  onClick={() => tableData.length > 0 && handleExportList()}
                  btn={tableData.length ? BtnType.OUTLINE : BtnType.DISABLED}
                  disabled={tableData.length <= 0}
                >
                  EXPORT
                </Button>
                {tableData.length > 0 && (
                  <CSVLink
                    data={csvData}
                    filename="forms.csv"
                    style={{ display: "none" }}
                    ref={csvLinkRef}
                  />
                )}
              </div>
            )}
            <div className={styles.totalCount}>
              {applicantPageNr?.total || "0"} Applicants
            </div>
          </div>

          <ReactTable
            key={tableKey}
            data={tableData}
            columns={columns}
            loading={listLoading}
            className={styles.table}
            showPagination={false}
            pageSize={pageSize}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: () => {
                    history.push(
                      `/admin/applicants/${rowInfo.original.id}/view`
                    );
                  },
                  onMouseEnter: () => {
                    setHoveredRow(rowInfo.index);
                  },
                  onMouseLeave: () => {
                    setHoveredRow(null);
                  },
                  style: {
                    cursor: "pointer",
                    background:
                      rowInfo.index === hoveredRow ? "#efefef" : "#efefef00",
                  },
                };
              }
              return {};
            }}
          />
          <div className={styles.pagination}>
            <div
              className={
                page - 1 === 0 ? styles.disabledButton : styles.paginationButton
              }
              onClick={handlePrevClick}
            >
              Previous
            </div>
            <div className={styles.paginationSettings}>
              <span style={styles.pageNr}>
                <span>
                  <input
                    onChange={(e) => handlePageSelect(e)}
                    value={page}
                    className={styles.inputBox}
                    type="number"
                    min="1"
                  />
                </span>
                <span>of</span>
                <span> {applicantPageNr?.pages}</span>
              </span>

              <span>
                <select onChange={handleChangePageSize}>
                  {pageSizeOptions.map((option, i) => (
                    <option
                      value={option}
                      key={`applicant-page-option-${option}-${i}`}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </span>
            </div>
            <button
              className={isNextDisabled()}
              onClick={handleNextClick}
              disabled={page + 1 > applicantPageNr?.pages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(adminFuseHoc(AdminApplicantsComponent));

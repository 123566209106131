import React, { memo, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { checkAvailableFilters } from "common/checkers/checkAvailableFilters";
import RelationShipStatusFilter from "./relationShipStatus";
import ScoreFilter from "./score";
import FoundedFilter from "./founded";
import TotalFundingFilter from "./totalFunding";
import EmployeesFilter from "./employees";
import RelationshipOwnersFilter from "./relationshipOwners";
import LocationFilter from "./location";
import AgreementsFilter from "./agreements";
import IDBEcosystemFilter from "./idbEcosystem";
import EstimatedRevenueFilter from "./estimatedRevenue";
import BusinessUnit from "./businessUnit/index";
import InvestmentFilter from "./investments/index";
import CustomTagsFilter from "./customTags/index";
import ListsFilter from "./lists/index";

import ToggleCompaniesOrProducts from "modules/companies/companyMain/leftWindow/advancedFilters/toggleCompaniesOrProducts";
import { useQuery } from "../../../../../common/helper";

const AdvancedFilters = ({
  expandAll,
  handleExpand,
  councilId,
  councilName,
  venturing,
  availableFilters,
  customFilters,
  handleExpandAll,
}) => {
  const location = useLocation();
  const toggleFilter = useQuery().get("toggle");

  const layoutNr = useMemo(
    () => location?.state?.layout || 0,
    [location?.state?.layout]
  );
  const showScore = useMemo(
    () => checkAvailableFilters(councilName, availableFilters, "score"),
    [councilName, availableFilters]
  );
  const showYearFounded = useMemo(
    () => checkAvailableFilters(councilName, availableFilters, "year_founded"),
    [councilName, availableFilters]
  );
  const showTotalFunding = useMemo(
    () => checkAvailableFilters(councilName, availableFilters, "total_funding"),
    [councilName, availableFilters]
  );
  const showEmployees = useMemo(
    () => checkAvailableFilters(councilName, availableFilters, "employees"),
    [councilName, availableFilters]
  );
  const showEstimatedRevenue = useMemo(
    () =>
      checkAvailableFilters(councilName, availableFilters, "estimated_revenue"),
    [councilName, availableFilters]
  );
  const disabledFilter = toggleFilter === "2";
  return (
    <div id="advancedFilters">
      {councilName === "Kyndryl" && (
        <BusinessUnit
          expanded={expandAll}
          handleExpand={handleExpand}
          councilId={councilId}
        />
      )}
      {customFilters?.length
        ? customFilters
            .filter((filter) => filter?.name && filter.filter)
            .map((filter, filterIndex) => (
              <CustomTagsFilter
                expanded={expandAll}
                handleExpand={handleExpand}
                councilId={councilId}
                filter={filter}
                councilName={councilName}
                key={`${filter?.name}+${filterIndex}`}
                filterIndex={filterIndex}
                disabled={disabledFilter}
              />
            ))
        : null}
      <LocationFilter
        expanded={expandAll}
        handleExpand={handleExpand}
        disabled={disabledFilter}
      />
      {councilName === "Kyndryl" && (
        <ListsFilter
          expanded={expandAll}
          handleExpand={handleExpand}
          disabled={disabledFilter}
        />
      )}
      <RelationShipStatusFilter
        expanded={expandAll}
        handleExpand={handleExpand}
      />
      {councilName === "Firmenich" && venturing && (
        <InvestmentFilter
          expanded={expandAll}
          handleExpand={handleExpand}
          disabled={disabledFilter}
        />
      )}
      <AgreementsFilter
        expanded={expandAll}
        handleExpand={handleExpand}
        disabled={disabledFilter}
      />
      {councilName === "IDB" && (
        <IDBEcosystemFilter
          expanded={expandAll}
          handleExpand={handleExpand}
          disabled={disabledFilter}
        />
      )}
      {showScore && (
        <ScoreFilter
          expanded={expandAll}
          handleExpand={handleExpand}
          disabled={disabledFilter}
        />
      )}
      {showYearFounded && (
        <FoundedFilter
          expanded={expandAll}
          handleExpand={handleExpand}
          disabled={disabledFilter}
        />
      )}
      {showTotalFunding && (
        <TotalFundingFilter
          expanded={expandAll}
          handleExpand={handleExpand}
          disabled={disabledFilter}
        />
      )}
      {showEmployees && (
        <EmployeesFilter
          expanded={expandAll}
          handleExpand={handleExpand}
          disabled={disabledFilter}
        />
      )}
      {showEstimatedRevenue && (
        <EstimatedRevenueFilter
          expanded={expandAll}
          handleExpand={handleExpand}
          disabled={disabledFilter}
        />
      )}
      <RelationshipOwnersFilter
        expanded={expandAll}
        handleExpand={handleExpand}
      />
      {layoutNr !== 2 && (
        <ToggleCompaniesOrProducts handleExpandAll={handleExpandAll} />
      )}
    </div>
  );
};

export default memo(AdvancedFilters);

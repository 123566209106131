import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useSnackbar } from "notistack";

import styles from "./event.module.scss";
import {
  Button, BtnType, StarRating, MultiUsers,
} from "../../primitives";
import { useEventsDates } from "../../../common/hooks";
import {
  eventsAddRating,
  eventsPutAttending,
} from "../viewEvent/viewEvent.action";

const EventComponent = (props) => {
  const { user = {}, event } = props;
  const {
    authReducer: { session },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [pageMeta, setPageMeta] = useState({});
  const eventsDates = useEventsDates({ event });
  const { enqueueSnackbar } = useSnackbar();

  const changeRating = (starsNr) => {
    dispatch(
      eventsAddRating({
        eventId: event.id,
        eventAttendeeId: session.id,
        rating: starsNr,
        enqueueSnackbar,
      }),
    );
  };

  const handleDeleteAttendClick = (e, val) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      eventsPutAttending({
        event_attendee_id: session.id,
        notes: "string",
        attending: false,
        // rating: 0,
        eventId: event.id,
        enqueueSnackbar,
      }),
    );
  };

  const handleAttendClick = (e, val) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      eventsPutAttending({
        event_attendee_id: session.id,
        notes: "string",
        // rating: 0,
        eventId: event.id,
        attending: true,
        enqueueSnackbar,
      }),
    );
  };

  const handleOnClick = () => {
    localStorage.setItem("event", JSON.stringify(user));
  };

  return (
    <div className={styles.contentWrp}>
      <div className={styles.headerWrp}>
        <h4 className={styles.headerText}>
          {eventsDates.getTimeRange("date")}
        </h4>
      </div>
      <Link to={`/events/id/${event.id}`} onClick={() => handleOnClick("id")}>
        <div className={styles.container}>
          <div className={styles.rowWrp}>
            <h4 className={classNames("medium", styles.lineOne)}>
              {event.name}
            </h4>
            <div className={styles.lineTwo}>
              <div className={classNames("caption", styles.message)}>
                {event.location}
              </div>
              <div
                className={styles.rating}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <StarRating rate={event.rating_stars} onChange={changeRating} />
              </div>
            </div>
            <div className={styles.lineThree}>
              <div className={styles.time}>
                {eventsDates.getTimeRange("time")}
              </div>
            </div>
            <div className={styles.lineFour}>
              <MultiUsers
                users={event.event_attendees}
                total={pageMeta.total}
              />
              {event.event_attendees.filter(
                (user) => user.user_id === session?.id,
              )[0] ? (
                <Button
                  btn={BtnType.OUTLINE_LIGHT}
                  className={styles.attending}
                  icon="icn-checkbox-button"
                  onClick={(event) => handleDeleteAttendClick(event)}
                >
                  Attending
                </Button>
                ) : (
                  <Button
                    btn={BtnType.TAG_OUTLINE_BLUE}
                    onClick={(event) => handleAttendClick(event)}
                    className={styles.attending}
                  >
                    Attend
                  </Button>
                )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default React.memo(EventComponent);

import React, { createContext, useContext, useState } from 'react';

const AnimationContext = createContext();

export const AnimationProvider = ({ children }) => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(-1);
  const [currentItemIndex, setCurrentItemIndex] = useState(-1);

  return (
    <AnimationContext.Provider value={{ currentSectionIndex, setCurrentSectionIndex, currentItemIndex, setCurrentItemIndex }}>
      {children}
    </AnimationContext.Provider>
  );
};

export const useAnimation = () => useContext(AnimationContext);

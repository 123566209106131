import React, { useCallback } from "react";
import { Field } from "formik";
import { Link, useHistory } from "react-router-dom";

import { BtnType, Button, Label, MultiSelect, TextBox } from "modules/primitives";
import styles from "modules/projects/project/overview/relatedProjects/styles.module.scss";
import { PLANT_STATUSES } from '../constants';
import { Icon, smSize } from "../../../icon";
import { useModelPopup } from "../../../../common/hooks";

const SpocPlantsWithAreas = ({ formikprops, existingSpocPlants, setSpocPlants, handleUnderReview, options }) => {
  const history = useHistory();
  const popup = useModelPopup();

  const handleRedirectToSettings = (evt) => {
    evt.preventDefault();
    history.push("/settings");
    popup.hide();
  };

  const handleSpocPlantDelete = useCallback((plant) => {
    const newPlants = existingSpocPlants.map((p) => {
      if (p.id === plant.id) {
        return {
          ...p,
          _destroy: true,
          areas: p.areas.map(a => ({
            ...a,
            _destroy: true,
          }))
        }
      }

      return p;
    });

    setSpocPlants(newPlants);
  }, [formikprops, existingSpocPlants]);

  const handleSpocAreaDelete = useCallback(
    (plant, area) => {
      const newPlants = existingSpocPlants.map(p => {
        if (p.id === plant.id) {
          return {
            ...p,
            areas: plant.areas?.map((a) => {
              if (a.area_id === area.area_id) {
                return {
                  ...a,
                  _destroy: true,
                }
              }

              return a;
            }) || [],
          }
        }

        return p;
      });

      setSpocPlants(newPlants);
    },
    [existingSpocPlants]
  );

  const updateExistingSpocPlants = useCallback((plant, area, option) => {
    if (area) {
      const newPlants = existingSpocPlants.map(p => {
        if (p.id === plant.id) {
          return {
            ...plant,
            state: null,
            project: null,
            reason: null,
            areas: plant.areas.map(
              (a) => {
                if (a.area_id === area.area_id) {
                  if (option.id === "possible_replication" || option.id === "not_applicable") {
                    return {
                      ...a,
                      state: option.id,
                      project: null,
                    };
                  }

                  return {
                    ...a,
                    state: option.id,
                  };
                }

                return a;
              }
            ),
          }
        }

        return p;
      });

      setSpocPlants(newPlants);
      return;
    }

    const newPlants = existingSpocPlants.map(p => {
      if (p.id === plant.id) {
        return {
          ...plant,
          state: option.id,
          project: null,
          reason: null,
          areas: plant.areas.map((a) => ({
            ...a,
            reason: null,
            state: null,
            project: null,
          })),
        }
      }

      return p;
    });

    setSpocPlants(newPlants);
  }, [existingSpocPlants, setSpocPlants]);

  const updateExistingSpocPlantsProject = useCallback((plant, area, project) => {
    if (area) {
      const newPlants = existingSpocPlants.map(p => {
        if (p.id === plant.id) {
          return {
            ...plant,
            state: null,
            project: null,
            reason: null,
            areas: plant.areas.map(
              (a) => {
                if (a.area_id === area.area_id) {
                  return {
                    ...a,
                    project,
                  };
                }

                return a;
              }
            ),
          }
        }

        return p;
      });

      setSpocPlants(newPlants);
      return;
    }

    const newPlants = existingSpocPlants.map(p => {
      if (p.id === plant.id) {
        return {
          ...plant,
          project,
          reason: null,
        }
      }

      return p;
    });

    setSpocPlants(newPlants);
  }, [existingSpocPlants, setSpocPlants])

  const updateExistingSpocPlantsReason = useCallback((plant, area, reason) => {
    if (area) {
      const newPlants = existingSpocPlants.map(p => {
        if (p.id === plant.id) {
          return {
            ...p,
            areas: plant.areas.map(
              (a) => {
                if (a.area_id === area.area_id) {
                  return {
                    ...a,
                    reason,
                  };
                }

                return a;
              }
            ),
          }
        }

        return p;
      });

      setSpocPlants(newPlants);
      return;
    }

    const newPlants = existingSpocPlants.map(p => {
      if (p.id === plant.id) {
        return {
          ...plant,
          reason,
        }
      }

      return p;
    });

    setSpocPlants(newPlants);
  }, [existingSpocPlants, setSpocPlants]);

  const handleClear = (plant, area) => {
    const newPlants = existingSpocPlants.map((p) => {
      if (p.id === plant.id) {
        if (area) {
          return {
            ...p,
            areas: p.areas.map(a => {
              if (a.area_id === area.area_id) {
                return {
                  ...a,
                  project: null,
                  reason: null,
                  state: null,
                  status: null,
                }
              }

              return a;
            })
          }
        }

        return {
          ...p,
          project: null,
          reason: null,
          state: null,
          status: null,
          areas: p.areas.map(a => ({
            ...a,
            project: null,
            reason: null,
            state: null,
            status: null,
          }))
        }
      }

      return p;
    });

    setSpocPlants(newPlants);
  }

  if (!existingSpocPlants?.length) {
    return (
      <div>
        <Link to="#" onClick={handleRedirectToSettings}>
          Please add your Plant in Settings
        </Link>
      </div>
    )
  }

  return (
    <div className="d-flex flex-column">
      {
        existingSpocPlants.map(plant => {
          return (
            <div className="d-flex flex-column" key={plant.id}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <h4 className="font-weight-bold">{plant.name}</h4>
                  {plant.hasOwnProperty('_destroy') && !plant._destroy && (
                    <Icon
                      {...smSize}
                      icon="icn-button-delete"
                      className="ml-3 cursor-pointer"
                      onClick={() => handleSpocPlantDelete(plant)}
                    />
                  )}
                </div>
                {
                  plant.state && !plant.areas?.length && !plant.hasOwnProperty('_destroy') ? (
                    <span className="text-primary cursor-pointer" onClick={() => handleClear(plant)}>clear</span>
                  ) : null
                }
              </div>
              {!plant.areas?.length ? PLANT_STATUSES.map((option, idx) => {
                if (option.id === 'under_review') {
                  return (
                    <div key={option.id} className="mt-3 mb-3">
                      <Button
                        type="submit"
                        btn={BtnType.REGULAR}
                        onClick={() => handleUnderReview(plant)}
                      >
                        {option.name}
                      </Button>
                    </div>
                  )
                }

                return (
                  <div
                    key={option.id}
                    className="form-check"
                  >
                    <label className="form-check-label d-flex align-items-center">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name={`otherOptions_${plant.id}`}
                        value={option.id}
                        onChange={() => {
                          updateExistingSpocPlants(plant, null, option);
                        }}
                        checked={plant.state === option.id}
                      />
                      {option.name}
                    </label>
                  </div>
                )
              }) : null}
              {plant.state === "different_solution" && (
                <div className="mt-2">
                  <Label>Select a Project</Label>
                  <div className={styles.selectorWrp}>
                    <MultiSelect
                      values={
                        plant.project ? [plant.project] : []
                      }
                      onChange={(val) => {
                        updateExistingSpocPlantsProject(plant, null, val?.length ? val[0] : null)
                      }}
                      placeholder="Select a Project"
                      labelField="name"
                      valueField="id"
                      options={options}
                      searchBy="name"
                      multi={false}
                    />
                  </div>
                </div>
              )}
              {(plant.state === "different_solution" ||
                plant.state === "not_applicable") && (
                <div className={plant.state === "different_solution" ? "" : "mt-2"}>
                  <Label>Reason</Label>
                  <div className="w-100">
                    <TextBox
                      className="w-100"
                      placeholder="Reason"
                      onChange={(evt) => {
                        updateExistingSpocPlantsReason(plant, null, evt.target.value)
                      }}
                      value={plant.reason}
                    />
                  </div>
                </div>
              )}
              {plant.areas.length ? (
                <>
                  <span className="mb-2 d-block">
                    Areas
                  </span>
                  {plant.areas.map((area) => {
                    return (
                      <div
                        className="d-flex flex-column mb-3"
                        key={area.area_id}
                      >
                        <div className="d-flex justify-content-between align-items-center mb-2 mt-2">
                          <div className="d-flex align-items-center">
                            <span className="font-weight-bold">{area.name}</span>
                            {area.hasOwnProperty('_destroy') && !area._destroy && (
                              <Icon
                                {...smSize}
                                icon="icn-button-delete"
                                className="ml-3 cursor-pointer"
                                onClick={() =>
                                  handleSpocAreaDelete(plant, area)
                                }
                              />
                            )}
                          </div>
                          {
                            area.state && !area.hasOwnProperty('_destroy') ? (
                              <span className="text-primary cursor-pointer" onClick={() => handleClear(plant, area)}>clear</span>
                            ) : null
                          }
                        </div>
                        {PLANT_STATUSES.map((option, idx) => {
                          if (option.id === 'under_review') {
                            return (
                              <div
                                key={`${area.area_id}_${option.id}`}
                                className="mb-3 mt-3"
                              >
                                <Button
                                  type="submit"
                                  btn={BtnType.REGULAR}
                                  onClick={() => handleUnderReview(plant, area)}
                                >
                                  {option.name}
                                </Button>
                              </div>
                            )
                          }

                          return (
                            <div
                              key={`${area.area_id}_${option.id}`}
                              className="form-check"
                            >
                              <label className="form-check-label d-flex align-items-center">
                                <Field
                                  disabled={!plant}
                                  className="form-check-input"
                                  type="radio"
                                  name={`otherOptions_${idx}_${area.area_id}_${option.id}`}
                                  value={option.id}
                                  onChange={() => {
                                    updateExistingSpocPlants(plant, area, option);
                                  }}
                                  checked={area?.state === option.id}
                                />
                                {option.name}
                              </label>
                              {area?.state ===
                                option.id &&
                                area?.state ===
                                "different_solution" && (
                                  <div className="mt-2">
                                    <Label>Select a Project</Label>
                                    <div className={styles.selectorWrp}>
                                      <MultiSelect
                                        values={area?.project ? [area?.project] : []}
                                        onChange={(val) => {
                                          updateExistingSpocPlantsProject(plant, area, val?.length ? val[0] : null)
                                        }}
                                        placeholder="Select a Project"
                                        labelField="name"
                                        valueField="id"
                                        options={options}
                                        searchBy="name"
                                        multi={false}
                                      />
                                    </div>
                                  </div>
                                )}
                              {area?.state ===
                                option.id &&
                                (area?.state ===
                                  "different_solution" ||
                                  area?.state ===
                                  "not_applicable") && (
                                  <div className={area?.state === "different_solution" ? "" : "mt-2"}>
                                    <Label>Reason</Label>
                                    <div className="w-100">
                                      <TextBox
                                        className="w-100"
                                        placeholder="Reason"
                                        onChange={(evt) => {
                                          updateExistingSpocPlantsReason(plant, area, evt.target.value)
                                        }}
                                        value={area?.reason}
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </>
              ) : null}
              <hr className="mb-3 mt-2" />
            </div>
          )
        })
      }
    </div>
  );
};

export default SpocPlantsWithAreas;

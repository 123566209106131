import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import styles from "./agreements.module.scss";
import { Icon, smSize } from "../../../../../../common/icon";
import {
  queryParamStringToArray,
  queryStringUrlReplacement,
  useQuery,
} from "../../../../../../common/helper";
import { CheckBox, CheckType } from "../../../../../primitives";
import { getAgreementsTypesAttempt } from "../../../companyMain.action";
import { Loading } from "modules/primitives/index";

const AgreementsFilter = ({ expanded, handleExpand, disabled }) => {
  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: { agreementTypes, loadingAgreementTypes },
    },
  } = useSelector((state) => state);
  const history = useHistory();
  const agreements = useQuery().get("agreements");
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [agreementsValue, setAgreementsValue] = useState(null);
  const enqueueSnackbar = useSnackbar();

  useEffect(() => {
    if (disabled) {
      setCollapsed(false);
      return;
    }

    setCollapsed(expanded);
  }, [expanded, disabled]);

  useEffect(() => {
    if (!selectedCouncil) {
      return;
    }

    let newAgreements = [];

    if (agreementTypes) {
      newAgreements = agreementTypes.map((a) => ({
        ...a,
        selected: false,
      }));

      setAgreementsValue(newAgreements);
    } else {
      newAgreements = [];
      setAgreementsValue([]);
    }

    if (agreements) {
      const arr = queryParamStringToArray(agreements);
      const newItems = newAgreements.map((item) => {
        const itemIsSelected = arr.includes(item.id);

        return {
          ...item,
          selected: itemIsSelected,
        };
      });

      setAgreementsValue(newItems);
    }

    return () => null;
  }, [selectedCouncil, agreementTypes, agreements]);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(getAgreementsTypesAttempt({ enqueueSnackbar }));
    }
  }, [selectedCouncil]);

  const handleCollapseClick = () => {
    if (disabled) {
      return;
    }

    if (!collapsed) {
      handleExpand("agreements");
    }
    setCollapsed(!collapsed);
  };

  const handleOnChange = (val, agr) => {
    const value = agreementsValue
      .map((a) => {
        if (a.id === agr.id) {
          return {
            ...a,
            selected: val,
          };
        }
        return a;
      })
      .filter((item) => item.selected)
      .reduce((acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`, "");

    let search = queryStringUrlReplacement(
      history.location.search,
      "agreements",
      value
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        agreements: value,
        page: 1,
      },
    });

    const newItems = agreementsValue.map((a) => {
      if (a.id === agr.id) {
        return {
          ...a,
          selected: val,
        };
      }

      return a;
    });

    setAgreementsValue(newItems);
  };

  if (loadingAgreementTypes) {
    return <Loading />;
  }

  return (
    <div
      className={`${styles.itemWrapper} ${disabled ? styles.disabled : ""}`}
      id="agreements"
    >
      <div className={styles.itemTitle} onClick={handleCollapseClick}>
        <span>Agreements</span>
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(styles.icon, !collapsed && styles.collapse)}
          onClick={handleCollapseClick}
        />
      </div>
      {collapsed ? (
        <div
          className={classNames(
            "d-flex align-items-center",
            styles.withPaddingLeft
          )}
        >
          <div
            className={classNames("d-flex flex-column", styles.agreementsField)}
          >
            {agreementsValue?.length
              ? agreementsValue.map((a) => (
                  <div key={a.id} className="d-flex align-items-center">
                    <CheckBox
                      checkType={CheckType.BLUE}
                      isChecked={a.selected}
                      onChange={(val) => handleOnChange(val, a)}
                    />
                    <span
                      className={styles.checkboxName}
                      onClick={() => handleOnChange(!a.selected, a)}
                    >
                      {a.name}
                    </span>
                  </div>
                ))
              : "No agreement filters."}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(AgreementsFilter);

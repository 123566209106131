import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip/Tooltip";

import styles from '../styles.module.scss';
import LoadingComponent from "modules/primitives/loading/loading.component";
import {
  getActualScoreTableData,
  toggleActualScoreTable,
} from "modules/reporting/fordReports/actualScorecard/store/actualScoreCard.actions";
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";

const SectionCard = ({
  data, title, subTitle, size = 'big', fetching, className, showDollars, reducerKey, hoverText, highLightColumn, downloadIsActive
}) => {
  const dispatch = useDispatch();

  const {
    fordDashboardReducer: { actualScoreCardReducer }
  } = useSelector((state) => state);

  const cardHasExpandedTable = useMemo(() => actualScoreCardReducer.tableData.expandedKey === reducerKey,
    [actualScoreCardReducer.tableData?.expandedKey, reducerKey]);

  const handleShowOrHideTable = useCallback(() => {
    if (actualScoreCardReducer.tableData.expandedKey !== reducerKey) {
      const filtersData = makeActualScoreCardFilterData(actualScoreCardReducer.filters);
      dispatch(getActualScoreTableData({
        reducerKey,
        highLightColumn,
        data: {
          ...filtersData,
          section: reducerKey,
        },
      }));

      return;
    }

    dispatch(toggleActualScoreTable({
      reducerKey,
    }));
  }, [
    reducerKey,
    getActualScoreTableData,
    toggleActualScoreTable,
    actualScoreCardReducer,
    highLightColumn
  ]);

  const makeTextStylesForPDFDownload = useMemo(() => {
    if (!downloadIsActive) return {};

    if (size === 'big') {
      return {
        fontSize: '58px',
        fontWeight: 'lato-regular',
        color: '#166086'
      }
    }

    if (size === 'medium') {
      return {
        fontSize: '38px',
        fontWeight: 'lato-regular',
        color: '#166086'
      }
    }

    return {
      fontWeight: 'lato-regular',
      color: '#166086'
    }
  }, [size, downloadIsActive]);

  const makeWrapperStylesForPDFDownload = useMemo(() => {
    if (!downloadIsActive) return {};

    let defaultStyles = {
      position: 'relative',
      border: '1px solid #cbd2d9',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
      marginBottom: '10px',
      height: '100%'
    }

    if (cardHasExpandedTable) {
      return {
        ...defaultStyles,
        border: '1px solid #3994c1',
        backgroundColor: '#d1eefc'
      }
    }

    return defaultStyles
  }, [cardHasExpandedTable, downloadIsActive]);

  const makeTitleStylesForPDFDownload = useMemo(() => {
    if (!downloadIsActive) return {};

    return {
      color: '#616e7c',
      fontSize: '18px',
      fontFamily: 'lato-regular',
      paddingTop: '7px',
    }
  }, [downloadIsActive]);

  if (fetching) {
    return <LoadingComponent customText="Preparing chart..." />
  }

  return (
    <div
      style={makeWrapperStylesForPDFDownload}
      className={classNames(styles.columnCard, className, cardHasExpandedTable ? styles.activeCard : '')}
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        {
          !downloadIsActive && hoverText && (
            <div className={styles.cardQuestionMark}>
              <Tooltip
                title={hoverText}
                placement="top"
              >
                <span>
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="6" cy="6" r="5.5" stroke="#9AA5B1" />
                    <path
                      d="M6.149 3c-.972 0-1.602.383-2.096 1.067a.275.275 0 0 0 .063.385l.525.383a.3.3 0 0 0 .405-.048c.305-.368.531-.58 1.008-.58.374 0 .838.232.838.582 0 .264-.227.4-.597.6-.431.232-1.002.522-1.002 1.246v.115c0 .155.13.281.292.281h.883a.287.287 0 0 0 .292-.281v-.068c0-.502 1.526-.523 1.526-1.882 0-1.024-1.104-1.8-2.137-1.8zm-.122 4.376c-.466 0-.844.365-.844.812 0 .448.378.812.844.812.465 0 .843-.364.843-.812 0-.447-.378-.812-.843-.812z"
                      fill="#9AA5B1"
                    />
                  </svg>
                </span>
              </Tooltip>
            </div>
          )
        }
        <span style={makeTitleStylesForPDFDownload} className={styles.columnCardTitle}>{title}</span>
        {
          subTitle && (
            <span className="small text-muted">{subTitle}</span>
          )
        }
        <div className="d-flex flex-row align-items-center">
        <span className={styles[size]} style={makeTextStylesForPDFDownload}>
          {
            data?.main_value ? (
              <>
                {showDollars ? "$" : ""}{data?.main_value}{data.main_value_type}
              </>
            ) : "NA"
          }
        </span>
          <div className="d-flex flex-column">
            {
              data?.second_value && (
                <span className={styles.columnCardSmallVal}>
                {data?.second_value}
              </span>
              )
            }
            {
              data?.additional_value && (
                <span className="small text-muted">
                {data?.additional_value}
              </span>
              )
            }
          </div>
        </div>
      </div>
      {
        !downloadIsActive && (
          <div className="d-flex justify-content-center">
            <span className={styles.showHideSourceData} onClick={() => handleShowOrHideTable()}>
              {cardHasExpandedTable ? 'HIDE SOURCE DATA' : 'SHOW SOURCE DATA'}
            </span>
          </div>
        )
      }
    </div>
  );
};

export default React.memo(SectionCard);

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import { checkViewerRole } from "common/checkers/viewerChecker";
import { Button, BtnType } from "../../primitives";
import styles from "./infoContainer.module.scss";
import CrunchbaseComponent from "../../companies/crunchbase/crunchbase.component";
import { useModelPopup } from "../../../common/hooks/index";
import ResearchModal from "./researchModal";
import AddNewIdea from "../../../common/components/addIdea";

const InfoContainerComponent = (props) => {
  const { info, isTablet, userRole } = props;
  const history = useHistory();
  const popup = useModelPopup();
  const [blockActivity, setBlockActivity] = useState(false);

  const addNewCompany = () => {
    popup.show({
      title: "Add Company",
      component: <CrunchbaseComponent fromHome />,
    });
  };

  useEffect(() => {
    if (info?.id === "evaluate") {
      if (checkViewerRole()) {
        setBlockActivity(true);
      }
    }
  }, [info]);

  const handleInfoBtnClick = () => {
    switch (info.id) {
      case "ideas":
        popup.show({
          show: true,
          width: "800",
          component: <AddNewIdea />,
        });
        break;
      case "research":
        popup.show({
          title: "Request Research",
          component: <ResearchModal popup={popup} />,
        });
        break;
      case "evaluate":
        history.push("/projects/new", { from: "/home" });
        break;
      case "contribute":
        addNewCompany();
        break;
      case "customerVoice":
        history.push("/companies");
        break;
      case "solutions":
        history.push("/solutions", { from: "/home" });
        break;
      case "projects":
        history.push("/projects", { from: "/home" });
        break;
      default:
        break;
    }
  };

  return (
    <div className={classNames(styles.infoCon, styles[info.class])}>
      <h5 className={styles.title}>
        {info.title === "Assess" && userRole === "viewer"
          ? "Add Company"
          : info.title}
      </h5>
      {info.class === "kyndryl" && info.id === "contribute" ? (
        <>
          <p className={styles.description}>
            Assess a new company and follow the required process as mandated by
            procurement.
            {/* (
            <Link to="/home">Click here for more info</Link>) */}
          </p>
        </>
      ) : (
        <p className={styles.description}>{info.description}</p>
      )}
      {!isTablet && <div className={styles.image} />}
      <div className={styles.btnWrp}>
        {!blockActivity ? (
          <Button
            type="submit"
            btn={BtnType.REGULAR}
            onClick={handleInfoBtnClick}
          >
            {info.button}
          </Button>
        ) : (
          <Tooltip title="You have View-Only access. To create a Project, please ask your Admin to upgrade your account to Standard access">
            <div>
              <Button btn={BtnType.DISABLED}>{info.button}</Button>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default React.memo(InfoContainerComponent);

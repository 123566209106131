import React, { useMemo } from "react";

import styles from "./productVideo.module.scss";
import useVideField from "common/hooks/videoField.hook";
import { BtnType, Button } from "modules/primitives";
import useModelPopup from "common/hooks/modelPopup.hook";
import EditProductVideo from "modules/companies/product/components/overview/productVideo/editProductVideo";

const ProductVideo = ({ product }) => {
  const videoAsField = useMemo(() => {
    return {
      field_type: 'video',
      documents: product.video ? [{uri: product.video}] : []
    }
  }, [product]);
  const popup = useModelPopup();
  const videoField = useVideField(videoAsField);

  const addNewVideo = () => {
    popup.show({
      show: true,
      title: "Edit Product Video",
      component: <EditProductVideo product={product} />,
    });
  };

  return (
    <div className={styles.productVideo}>
      {
        product.video ? (
          <>
            {
              videoField.displayField()
            }
          </>
        ) : (
          <div className="d-flex align-items-center flex-column">
            <span>No videos added yet.</span>
            <Button
              className="mt-3"
              onClick={addNewVideo}
              btn={BtnType.REGULAR}
            >
              ADD VIDEO
            </Button>
          </div>
        )
      }
    </div>
  );
};

export default ProductVideo;

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import classNames from "classnames";

import {
  model,
  validation,
  rolesInitialState,
  roles,
} from "./councils.constant";
import { ModalBody, ModalFooter } from "../../../application/modal";
import { useModelPopup } from "../../../common/hooks";
import {
  BtnType,
  Button,
  Label,
  TextBox,
  UploadImgComponent,
  CheckBox,
  CheckType,
  DropSelect,
  Loading,
} from "../../primitives/index";
import styles from "./styles.module.scss";
import {
  councilsPostAttempt,
  councilsEditAttempt,
  councilsGetIndustriesAcrossAttempt,
  councilsGetTecnologiesAcrossAttempt,
  councilsGetByIdAttempt,
} from "./councils.action";
import {
  handleTopicChange,
  handleIndustryChange,
} from "../../admin/adminCompanies/editCompany/editCompany.helper";

import getTimezoneOffset from "./commons/getTimezonOffset";

const CouncilModal = (props) => {
  const { mode, formRef, councilId } = props;
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    councilsReducer: { topics, industries, councilData, loadingCouncilData },
  } = useSelector((state) => state);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [initModel, setInitModel] = useState(model && model);
  const [validationError, setValidationError] = useState({});
  const [files, setFiles] = useState([]);
  const [active, setActive] = useState(false);
  const [themesConnected, setThemesConnected] = useState(false);
  const [themesHomePageConnected, setThemesHomePageConnected] = useState(false);

  const [industryIds, setIndustryIds] = useState([]);
  const [technologyIds, setTechnoloyIds] = useState([]);
  const [selectedTechnologiesForSearch, setSelectedTechnologiesForSearch] =
    useState([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const [technologySearchValue, setTechnologySearchValue] = useState([]);
  const [topic, setTopic] = useState([]);

  const [selectedIndustriesForSearch, setSelectedIndustriesForSearch] =
    useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [industrySearchValue, setIndustrySearchValue] = useState("");
  const [industry, setIndustry] = useState([]);
  const [innovFunnelConnected, setInnovFunnelConnected] = useState(false);
  const [themesRoleConnected, handleThemesRoleConnected] =
    useState(rolesInitialState);
  const selectAllRoles = (stateChangeFunc) => {
    stateChangeFunc({
      all: true,
      council_administrator: true,
      standard: true,
      viewer: true,
      company: false,
    });
  };
  const dropdownRenderer = useCallback(
    ({ props }) => (
      <div className="d-flex flex-column react-dropdown-select-dropdown">
        {selectedTechnologies?.length
          ? selectedTechnologies.map((item, idx) => {
              const isSelected = topic.find((el) => el.id === item.id);

              return (
                <span
                  onClick={() => {
                    handleTopicChange({
                      val: item,
                      topic,
                      setTopic,
                      setSearchValue: setTechnologySearchValue,
                    });
                  }}
                  key={`${item.name}-${idx}`}
                  className={`react-dropdown-select-item 
                            ${isSelected && styles.selectedItemDrop}`}
                >
                  {item[props.labelField]}
                </span>
              );
            })
          : "No data"}
      </div>
    ),
    [selectedTechnologies, topic]
  );

  const IndustryDropdownRenderer = useCallback(
    ({ props }) => (
      <div className="d-flex flex-column react-dropdown-select-dropdown">
        {selectedIndustries?.length
          ? selectedIndustries.map((item, idx) => {
              const isSelected = industry.find((el) => el.id === item.id);
              return (
                <span
                  onClick={() => {
                    handleIndustryChange({
                      val: item,
                      industry,
                      setIndustry,
                      setIndustrySearchValue,
                    });
                  }}
                  key={`${item.name}-${idx}`}
                  className={`react-dropdown-select-item 
                            ${isSelected && styles.selectedItemDrop}`}
                >
                  {item[props.labelField]}
                </span>
              );
            })
          : "No data"}
      </div>
    ),
    [selectedIndustries, industry]
  );

  const industryContentRenderer = useCallback(
    () => (
      <div className="d-flex flex-wrap  react-dropdown-select-dropdown">
        {Boolean(industry?.length) &&
          industry.map((item, idx) => (
            <div
              key={`${item?.name}-${idx}`}
              className={styles.dropdownSelected}
            >
              <span>{item?.name}</span>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleIndustryChange({
                    val: item,
                    industry,
                    setIndustry,
                    setIndustrySearchValue,
                  });
                }}
                className={styles.deleteLetter}
              >
                ✕
              </span>
            </div>
          ))}
        <input
          placeholder={!topic.length ? "Select..." : ""}
          className={styles.dropSelectInput}
          onChange={(e) => setIndustrySearchValue(e.target.value)}
          value={industrySearchValue}
        />
      </div>
    ),
    [industry, industrySearchValue]
  );
  const contentRenderer = useCallback(
    () => (
      <div className="d-flex flex-wrap  react-dropdown-select-dropdown">
        {Boolean(topic?.length) &&
          topic.map((item, idx) => (
            <div
              key={`${item?.name}-${idx}`}
              className={styles.dropdownSelected}
            >
              <span>{item?.name}</span>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  handleTopicChange({
                    val: item,
                    topic,
                    setTopic,
                    setSearchValue: setTechnologySearchValue,
                  });
                }}
                className={styles.deleteLetter}
              >
                ✕
              </span>
            </div>
          ))}
        <input
          value={technologySearchValue}
          placeholder={!topic.length ? "Select..." : ""}
          className={styles.dropSelectInput}
          onChange={(e) => {
            setTechnologySearchValue(e.target.value);
          }}
        />
      </div>
    ),
    [topic, technologySearchValue]
  );

  const searchDropDownValue = (
    dataForFilter,
    searchValue,
    type = "industry"
  ) => {
    if (dataForFilter?.length) {
      const filteredData = dataForFilter.filter((el) => {
        if (searchValue === "") {
          return el;
        }

        return el?.name?.toLowerCase().includes(searchValue?.toLowerCase());
      });
      if (type === "technology") {
        setSelectedTechnologies(filteredData);
      } else {
        setSelectedIndustries(filteredData);
      }
    }
  };

  const handleImgUpdate = (url, name) => {
    if (url) {
      setFiles((prev) => {
        return [
          ...prev,
          {
            name,
            document_resource_type: "Council",
            added_by_id: session?.id,
            council_id: selectedCouncil?.id,
            uri: url,
          },
        ];
      });
    } else if (url === null) {
      setFiles([null]);
    }
  };

  const handleSubmitButton = (values) => {
    if (!values.name?.length) {
      setValidationError({
        text: "Name is required field",
        type: "emptyTitle",
      });
      return;
    }
    if (!files[files.length - 1]) {
      setValidationError({
        text: "Logo is required field",
        type: "emptyLogo",
      });
      return;
    }

    const themesRoleConnectedArr = Object.keys(themesRoleConnected).filter(
      (key) => themesRoleConnected[key]
    );

    if (themesConnected && !themesRoleConnectedArr.length) {
      setValidationError({
        text: "At least one option is required",
        type: "emptyThemesRoles",
      });
      return;
    }
    const industry_ids = industry.map(
      (industryItem) => industryItem.id && industryItem.id
    );
    const topic_ids = topic.map((topicItem) => topicItem.id && topicItem.id);
    const council = {
      name: values.name,
      logo: files[files.length - 1]?.uri || "",
      traction_tag: values?.name?.toLowerCase().replaceAll(" ", "_"),
      themes_connected: themesConnected,
      themes_allowed_roles: themesConnected
        ? Object.keys(themesRoleConnected).filter(
            (key) => themesRoleConnected[key]
          )
        : [],
      themes_homepage_connected: themesHomePageConnected,
      settings: {
        innovFunnelConnected,
        theme_properties: {
          theme_posts: true,
        },
      },
      active,
      time_zone: getTimezoneOffset(),
      topic_ids,
      industry_ids,
    };
    if (mode === "add") {
      dispatch(councilsPostAttempt({ council }));
    } else if (mode === "edit") {
      council.id = councilData?.id;
      dispatch(
        councilsEditAttempt({ council, id: council.id, enqueueSnackbar })
      );
    }
    popup.hide();
  };

  useEffect(() => {
    if (councilData.name && mode === "edit") {
      setInitModel({
        name: councilData.name,
        avatar: councilData.logo,
      });
      setFiles([{ uri: councilData?.logo }]);
      setActive(councilData.active);
      setThemesConnected(councilData.themes_connected);
      const prepareRolesArr = (key) => {
        const arr = councilData?.settings?.[key]
          .map((role) => {
            return { [role]: true };
          })
          .flat();
        return Object.assign({}, ...arr);
      };

      handleThemesRoleConnected(prepareRolesArr("themes_allowed_roles"));
      setThemesHomePageConnected(councilData.themes_homepage_connected);
      const technologiesOnEdit = councilData.resource_topics.map(
        ({ topic }) => ({
          name: topic?.name,
          id: topic?.id,
        })
      );
      const industriesOnEdit = councilData.resource_industries.map(
        ({ industry }) => ({
          name: industry?.name,
          id: industry?.id,
        })
      );

      setTopic(technologiesOnEdit.length ? technologiesOnEdit : []);
      setIndustry(industriesOnEdit.length ? industriesOnEdit : []);
    }
  }, [councilData]);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        councilsGetIndustriesAcrossAttempt({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
      dispatch(
        councilsGetTecnologiesAcrossAttempt({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil]);
  useEffect(() => {
    if (
      ref.current &&
      validationError.type === "emptyTitle" &&
      validationError.type === "emptyLogo"
    ) {
      ref.current.scrollIntoView();
    }
  }, [validationError?.text]);

  useEffect(() => {
    if (themesHomePageConnected) {
      setThemesConnected(true);
    }
  }, [themesHomePageConnected]);

  useEffect(() => {
    if (!themesConnected) {
      setThemesHomePageConnected(false);
    }
  }, [themesConnected]);

  useEffect(() => {
    if (mode === "edit") {
      dispatch(councilsGetByIdAttempt({ id: councilId, enqueueSnackbar }));
    }
  }, [mode]);
  useEffect(() => {
    setSelectedTechnologies(topics);
    setSelectedTechnologiesForSearch(topics);
  }, [topics]);

  useEffect(() => {
    setSelectedIndustries(industries);
    setSelectedIndustriesForSearch(industries);
  }, [industries]);

  useEffect(() => {
    if (selectedTechnologiesForSearch?.length) {
      searchDropDownValue(
        selectedTechnologiesForSearch,
        technologySearchValue,
        "technology"
      );
    }
  }, [technologySearchValue]);

  useEffect(() => {
    if (selectedIndustriesForSearch?.length) {
      searchDropDownValue(selectedIndustriesForSearch, industrySearchValue);
    }
  }, [industrySearchValue]);
  if (mode === "edit" && loadingCouncilData) {
    return <Loading />;
  }
  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initModel}
        validaitonSchema={validation}
        onSubmit={(values) => {
          handleSubmitButton(values);
        }}
      >
        {({ values, setFieldValue, handleSubmit, ...formikprops }) => (
          <form onSubmit={handleSubmit} className={styles.modalStyles}>
            {" "}
            <ModalBody>
              <div ref={ref} className={styles.row}>
                <Label>Council Name</Label>
                <TextBox
                  type="text"
                  name="name"
                  className={styles.textBox}
                  formProps={{ ...formikprops, values }}
                />
                {validationError.type === "emptyTitle" &&
                  !values?.name?.length && (
                    <span className={styles.errorMessage}>
                      {validationError.text}
                    </span>
                  )}
              </div>
              <div className={styles.row}>
                <Label>Traction Tag</Label>
                <TextBox
                  type="text"
                  name="traction_tag"
                  className={styles.textBox}
                  disabled
                  value={values?.name?.toLowerCase().replaceAll(" ", "_")}
                  formProps={{ ...formikprops, values }}
                />
              </div>
              <div className={classNames(styles.row, styles.uploadImgBlock)}>
                <input type="hidden" value="testing" name="externalMember" />
                <Label>Upload Logo</Label>
                <UploadImgComponent
                  name="avatar"
                  formProps={{ ...formikprops, values }}
                  uploadDirName={`councils/${values?.name}/key`}
                  onChange={(e) => {
                    handleImgUpdate(e);
                    setFieldValue("avatar", e);
                  }}
                />
                {validationError.type === "emptyLogo" &&
                  !files[files.length - 1] && (
                    <span className={styles.errorMessage}>
                      {validationError.text}
                    </span>
                  )}
              </div>
              <div className={styles.row}>
                <div className={styles.activeCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values }}
                    name="active"
                    isChecked={active}
                    onChange={() => setActive(!active)}
                  />
                  Active(Yes/No)
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={formikprops}
                    name="themes"
                    isChecked={themesConnected}
                    onChange={() => {
                      if (themesConnected === false) {
                        handleThemesRoleConnected(() => {
                          return rolesInitialState;
                        });
                      }
                      setThemesConnected(!themesConnected);
                    }}
                  />
                  {selectedCouncil?.settings?.themes_display_name || "Themes"}
                  (On/Off)
                </div>
                {validationError.type === "emptyThemesRoles" && (
                  <span className={styles.errorMessage}>
                    {validationError.text}
                  </span>
                )}
              </div>
              {themesConnected && (
                <div className={styles.row}>
                  {roles.map((item) => {
                    return (
                      <div
                        className={classNames(styles.themesCheckbox, "ml-5")}
                      >
                        <CheckBox
                          checkType={CheckType.BLUE}
                          formProps={{ ...formikprops, values }}
                          name={`themes_${item.role}`}
                          isChecked={themesRoleConnected[item.role]}
                          onChange={(e) => {
                            setValidationError({});

                            if (item.role === "all" && e) {
                              selectAllRoles(handleThemesRoleConnected);
                            } else {
                              handleThemesRoleConnected((prev) => {
                                return {
                                  ...prev,
                                  [item.role]: !prev[item.role],
                                };
                              });
                            }
                          }}
                        />
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              )}
              <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values }}
                    name="active"
                    isChecked={themesHomePageConnected}
                    onChange={() =>
                      setThemesHomePageConnected(!themesHomePageConnected)
                    }
                  />
                  {selectedCouncil?.settings?.themes_display_name || "Theme"}{" "}
                  Home Page Connected(On/Off)
                </div>
              </div>
              {/* <div className={styles.row}>
                <div className={styles.themesCheckbox}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    formProps={{ ...formikprops, values }}
                    name="innovFunnelConnected"
                    isChecked={innovFunnelConnected}
                    onChange={() =>
                      setInnovFunnelConnected(!innovFunnelConnected)
                    }
                  />
                  Innovation Funnel
                </div>
              </div> */}
              <div className={styles.tagsBlock}>
                <div className={styles.tagsTitle}>Tags:</div>
                <div className={styles.selectDrops}>
                  <div className="col-12">
                    <Label>Technology</Label>
                    <DropSelect
                      multi
                      onChange={(e) => {
                        setTechnoloyIds([
                          ...technologyIds,
                          e.obj[e.obj.length - 1]?.id,
                        ]);
                      }}
                      className={styles.dropWrapper}
                      labelField="name"
                      valueField="id"
                      name="technologies"
                      values={topic}
                      options={selectedTechnologies}
                      contentRenderer={(innerProps, innerState, innerMethods) =>
                        contentRenderer(innerProps, innerState, innerMethods)
                      }
                      dropdownRenderer={(
                        innerProps,
                        innerState,
                        innerMethods
                      ) =>
                        dropdownRenderer(innerProps, innerState, innerMethods)
                      }
                    />
                  </div>

                  <div className="col-12">
                    <Label>Industries</Label>
                    <DropSelect
                      className={styles.dropWrapper}
                      multi
                      onChange={(e) => {
                        setIndustryIds([
                          ...industryIds,
                          e.obj[e.obj.length - 1]?.id,
                        ]);
                      }}
                      labelField="name"
                      valueField="id"
                      name="industries"
                      values={industry}
                      options={selectedIndustries}
                      contentRenderer={(innerProps, innerState, innerMethods) =>
                        industryContentRenderer(
                          innerProps,
                          innerState,
                          innerMethods
                        )
                      }
                      dropdownRenderer={(
                        innerProps,
                        innerState,
                        innerMethods
                      ) =>
                        IndustryDropdownRenderer(
                          innerProps,
                          innerState,
                          innerMethods
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button btn={BtnType.FRAME_LESS} onClick={popup.hide}>
                Cancel
              </Button>
              <Button type="submit" btn={BtnType.REGULAR}>
                Save
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default CouncilModal;

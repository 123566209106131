import * as Yup from "yup";

export const initModel = {
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  title: "",
  company: "",
  id: "",
  patient_organization_id: "",
  organization_id: "",
};

export const validation = Yup.object().shape(
  {
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email address"),
    title: Yup.string().matches(
      /[^\s*].*[^\s*]/g,
      "Title cannot include leading and trailing spaces",
    ),
    patient_organization_id: Yup.string().when("organization_id", {
      is: (val) => !val,
      then: Yup.string().required("Company/Organization required"),
      otherwise: Yup.string(),
    }),
    organization_id: Yup.string().when("patient_organization_id", {
      is: (val) => !val,
      then: Yup.string().required("Company/Organization required."),
      otherwise: Yup.string(),
    }),
  },
  [["patient_organization_id", "organization_id"]],
);

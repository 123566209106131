import React, { useRef, useState, useEffect } from "react";
import { FieldArray, Formik, ErrorMessage } from "formik";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  currencies,
} from "common/popups/newItem/newNumber/constant";
import classNames from "classnames";

import {
  submitProjectField,
  updateProjectField,
} from "modules/admin/adminProjects/adminProjects.actions";
import {
  Label,
  TextBox,
  Button,
  BtnType,
  DropSelect,
  CheckBox,
  CheckType,
  MultiSelect,
} from "../../../../primitives";
import {
  initModel,
  validation,
  projectFieldTypes,
  projectFieldTypesAsObj,
  dropdownValidation,
} from "./addEditProjectsField.constants";
import { ModalBody, ModalFooter } from "../../../../../application/modal";
import { useModelPopup } from "../../../../../common/hooks";
import styles from "../../../../../common/popups/newItem/newDropDown/styles.module.scss";
import numberstyles from "../../../../../common/popups/newItem/newNumber/styles.module.scss";

import { Icon, smSize } from "../../../../../common/icon";
import UserMultiSelectSearch from "../../../../../common/components/usersSearch";

const AddEditProjectsFieldsModal = (props) => {
  const { mode, data, existingPositions, stageID } = props;
  const [numberType, setNumberType] = useState("Number");
  const [checkedCurrency, setCheckedCurrency] = useState("");

  const {
    teamsReducer: { allTeams },
    adminProjectsReducer: { stageTemplates },
  } = useSelector((state) => state);

  const formRef = useRef();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [model, setModel] = useState(initModel);
  const [customValidation, setCustomValidation] = useState(validation);
  const [defaultStage, setDefaultStage] = useState(null);
  const [shouldRerenderTemplateField, setShouldRerenderTemplateField] =
    useState(false);

  const handleSelection = (val) => {
    if (val === checkedCurrency && numberType === "Currency") {
      setCheckedCurrency("USD");
    } else {
      setCheckedCurrency(val);
    }
    if (numberType === "Number" && val) {
      setNumberType("Currency");
      setCheckedCurrency(val);
    }
  };

  const [changedOption, setChangedOption] = useState({
    changedValue: "",
    changedWith: "",
  });

  useEffect(() => {
    if (existingPositions?.length && !data) {
      const getMax = Math.max(...existingPositions.map((e) => e.position));

      setModel({
        ...initModel,
        position: getMax + 1,
        existingPositions,
      });
    }
  }, [existingPositions, data]);

  useEffect(() => {
    if (stageID?.length && stageTemplates.length) {
      const getStageDetails = stageTemplates.find((s) => s.id === stageID);
      setDefaultStage(getStageDetails);
    }
  }, [stageID, stageTemplates]);

  useEffect(() => {
    if (data) {
      let projectTemplate;
      if (stageID?.length) {
        if (data.stage_templates?.length) {
          projectTemplate = data.stage_templates;
        } else {
          const getStageDetails = stageTemplates.find((s) => s.id === stageID);
          projectTemplate = [getStageDetails];
        }
      } else {
        projectTemplate = data.stage_templates;
      }
      handleSelection(data.option_values?.[0]);

      setModel({
        field_type: data?.field_type,
        field_values: data.field_values,
        field_name: data.field_name,
        option_selected: data.option_selected,
        options: data.option_values,
        multiselect: data.multiselect,
        existingPositions,
        position: data.position,
        instructions: data.field_placeholder,
        big_box_text: data.big_box_text,
        projectTemplate,
        id: data.id,
      });
    } else {
      if (stageID?.length && stageTemplates.length) {
        const getStageDetails = stageTemplates.find((s) => s.id === stageID);
        const getMax = Math.max(...existingPositions.map((e) => e.position));

        setModel({
          ...initModel,
          position: getMax + 1,
          projectTemplate: [getStageDetails],
        });
      }
    }
  }, [data, existingPositions, stageID, stageTemplates]);

  const handleDismiss = () => {
    popup.hide();
  };

  const onSubmitForm = (values, resetForm) => {
    const payload = {
      field_name: values.field_name,
      stage_template_ids: values.projectTemplate?.length
        ? values.projectTemplate?.map((el) => el.id)
        : [],
      field_placeholder: values.instructions,
      field_type: (values.field_type === "number" && numberType === "Currency") ? "currency" : values.field_type,
      position: values.position,
      big_box_text: values.big_box_text,
      multiselect: values.multiselect,
      option_values:
        values.field_type === "dropdown"
          ? values.options.filter((val) => val.length).map((el) => el.trim())
          : (values.field_type === "number" && numberType === "Currency")
          ? [checkedCurrency]
          : [],
      stageID,
      cb: () => {
        resetForm();
        handleDismiss();
      },
    };

    if (data?.id) {
      dispatch(
        updateProjectField({
          data: {
            ...payload,
            id: data.id,
          },
          stageID,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(
        submitProjectField({
          data: payload,
          stageID,
          enqueueSnackbar,
        })
      );
    }
  };

  const handleFocusValue = (event) => {
    const isSelected = model?.option_selected?.find(
      (option) => option === event.target.value
    );
    if (isSelected) {
      setChangedOption((prevState) => {
        return {
          ...prevState,
          changedValue: event.target.value,
        };
      });
    }
  };

  const handleBlurValue = (event) => {
    if (changedOption.changedValue) {
      setChangedOption((prevState) => {
        return {
          ...prevState,
          changedWith: event.target.value,
        };
      });
    }
  };

  const handleFieldChange = (setFieldValue, evt) => {
    const value = evt.val;
    setFieldValue("field_type", evt.val);

    popup.setTitle({
      title: `Add ${projectFieldTypesAsObj[evt.val || "text"]?.name}`,
    });

    if (value === "dropdown") {
      setCustomValidation(dropdownValidation);
    } else {
      setCustomValidation(validation);
    }

    const timeout = setTimeout(() => {
      if (formRef.current) {
        formRef.current.validateForm();
      }
      clearTimeout(timeout);
    });
  };

  const handleTemplateChange = (val, setFieldValue) => {
    if (stageID?.length) {
      const hasSelectedStage = val.obj.some((s) => s.id === stageID);
      const value = hasSelectedStage ? val.obj : [defaultStage, ...val.obj];

      setFieldValue("projectTemplate", value);

      if (!hasSelectedStage) {
        setShouldRerenderTemplateField(true);
        enqueueSnackbar("Project Template is required", {
          variant: "error",
        });

        const timeout = setTimeout(() => {
          setShouldRerenderTemplateField(false);
          clearTimeout(timeout);
        }, 10);
      }

      return;
    }

    setFieldValue("projectTemplate", val.obj);
  };

  const handleOnCheck = (val) => {
    if (val && val === numberType) {
      if (val === "Currency") {
        setNumberType("Number");
        setCheckedCurrency("");
      } else {
        setNumberType("Currency");
        setCheckedCurrency("USD");
      }
    } else {
      setNumberType(val);
      if (val === "Currency") {
        setCheckedCurrency("USD");
      } else {
        setCheckedCurrency("");
      }
    }
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={model}
      validationSchema={customValidation}
      onSubmit={(values, { resetForm }) => {
        onSubmitForm(values, resetForm);
      }}
    >
      {({ handleSubmit, setFieldValue, values, ...formikprops }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Label>Field Type</Label>
            <DropSelect
              values={[projectFieldTypesAsObj[values.field_type || "text"]]}
              placeholder="Field Type"
              labelField="name"
              valueField="id"
              name="field_type"
              options={projectFieldTypes}
              disabled={mode === "edit"}
              onChange={(evt) => handleFieldChange(setFieldValue, evt)}
            />
            {values.field_type === "text" ? (
              <div className="form-row d-flex align-items-center ml-1 mb-2">
                <CheckBox
                  isChecked={values.big_box_text}
                  onChange={(value) => setFieldValue("big_box_text", value)}
                />
                <Label
                  onClick={() =>
                    setFieldValue("big_box_text", !values.big_box_text)
                  }
                  className="cursor-pointer"
                >
                  Big Box Text
                </Label>
              </div>
            ) : null}

            {values.field_type === "number" ? (
              <>
                <div className={numberstyles.checkItem}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={numberType === "Number"}
                    onChange={() => handleOnCheck("Number")}
                  />
                  <div className={classNames(numberstyles.checkLabel)}>
                    Number
                  </div>
                </div>
                <div className={numberstyles.checkItem}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={numberType === "Currency"}
                    onChange={() => handleOnCheck("Currency")}
                  />
                  <div className={classNames(numberstyles.checkLabel)}>
                    Currency
                  </div>
                </div>
                <div className={numberstyles.checkWrapper}>
                  {currencies.map((currency) => (
                    <div key={currency} className={numberstyles.checkItem}>
                      <CheckBox
                        checkType={CheckType.BLUE}
                        isChecked={checkedCurrency === currency}
                        disabled={
                          currency === "USD" && checkedCurrency === currency
                        }
                        onChange={() => handleSelection(currency)}
                      />
                      <div
                        className={classNames(
                          numberstyles.checkLabel,
                          mode === "edit" && numberstyles.disabledBox
                        )}
                      >
                        {currency}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
            <div className="mt-3">
              <label>Field Name</label>
              <TextBox
                name="field_name"
                placeholder="Field Name"
                formProps={{ ...formikprops, setFieldValue, values }}
                className="w-100"
                value={values.field_name}
              />
            </div>
            <div>
              <label>Instructions (Optional)</label>
              <TextBox
                name="instructions"
                placeholder="Instructions (Optional)"
                formProps={{ ...formikprops, setFieldValue, values }}
                className="w-100"
                value={values.instructions}
              />
            </div>

            {values.field_type === "dropdown" ? (
              <div className="d-flex flex-column mb-3">
                <label>Dropdown Options</label>
                <div className="d-flex align-items-center mt-3 mb-3">
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={values.multiselect}
                    onChange={(val) => setFieldValue("multiselect", val)}
                  />
                  <Label>Allow Multiselect</Label>
                </div>
                <FieldArray name="options">
                  {({ remove, push }) => (
                    <>
                      {values?.options?.map((item, index) => (
                        <div className={styles.inputContainer} key={index}>
                          <TextBox
                            type="text"
                            formProps={{
                              ...formikprops,
                              setFieldValue,
                              values,
                            }}
                            name={`options.${index}`}
                            value={item}
                            placeholder={`Option`}
                            className={styles.textBox}
                            onFocus={(e) => handleFocusValue(e)}
                            onBlur={(e) => handleBlurValue(e)}
                          />
                          <div
                            onClick={() => remove(index)}
                            className={styles.deleteButton}
                          >
                            <Icon {...smSize} icon="icn-button-delete" />
                          </div>
                        </div>
                      ))}
                      <Button
                        icon={"icn-add"}
                        type="button"
                        btn={BtnType.FRAME_LESS}
                        onClick={() => push("")}
                        className={styles.optionButton}
                      >
                        Option
                      </Button>
                    </>
                  )}
                </FieldArray>
                {values?.options.length === 0 ? (
                  <ErrorMessage
                    name="options"
                    component="div"
                    className="small text-danger"
                  />
                ) : (
                  <ErrorMessage
                    name="options.0"
                    component="div"
                    className="small text-danger"
                  />
                )}
              </div>
            ) : null}
            <div className="w-100">
              <Label>Position</Label>
              <TextBox
                type="Number"
                name="position"
                formProps={{ ...formikprops, setFieldValue, values }}
                className="w-100"
                min="1"
              />
            </div>
            {/*<div>*/}
            {/*  <Label>Access</Label>*/}
            {/*  <DropSelect*/}
            {/*    name="access"*/}
            {/*    placeholder="Access"*/}
            {/*    labelField="text"*/}
            {/*    valueField="value"*/}
            {/*    searchBy="text"*/}
            {/*    options={templatesAccessOptions}*/}
            {/*    onChange={val => setFieldValue('access', val.val)}*/}
            {/*  />*/}
            {/*</div>*/}
            {values.access === "team" ? (
              <div>
                <Label>Select Teams</Label>
                <MultiSelect
                  name="teams"
                  values={values.teams || []}
                  labelField="name"
                  valueField="id"
                  onChange={(val) => setFieldValue("teams", val)}
                  options={allTeams}
                  multi
                />
              </div>
            ) : null}
            {values.access === "individual" ? (
              <div>
                <Label>Select Member</Label>
                <UserMultiSelectSearch
                  name="members"
                  placeholder="Select Member"
                  labelField="full_name"
                  valueField="id"
                  searchBy="full_name"
                  onChange={(val) => setFieldValue("members", val)}
                  values={values.members || []}
                  multi
                />
              </div>
            ) : null}
            {!shouldRerenderTemplateField ? (
              <div>
                <Label>Project Template</Label>
                <DropSelect
                  name="projectTemplate"
                  placeholder="Project Template"
                  labelField="name"
                  valueField="id"
                  values={values.projectTemplate}
                  options={stageTemplates}
                  onChange={(val) => handleTemplateChange(val, setFieldValue)}
                  multi
                />
              </div>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
              Cancel
            </Button>
            <Button type="submit" btn={BtnType.HIGHLIGHT} className="ml-3">
              {mode === "edit" ? "Save" : "Add"}
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default AddEditProjectsFieldsModal;

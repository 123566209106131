import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select as SimpleSelect } from "react-dropdown-select";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from '@mui/material/FormControl';

import styles from "../project.module.scss";
import { projectPut } from "modules/projects/project/project.action";
import { Label } from "modules/primitives";

const ProjectStage = ({ selectedProject, updateDirectly, editProjectInstance, updateStage, disabled }) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [stage, setStage] = useState(null);

  useEffect(() => {
    setStage(selectedProject?.stage || null)
  }, [selectedProject?.stage]);

  const owners = useMemo(
    () =>
      selectedProject?.project_members?.filter((m) => m.user_role === "owner"),
    [selectedProject]
  );

  const loggedUserIsProjectOwner = useMemo(() => {
    if (selectedCouncil?.name === 'Ford' && session?.id) {
      const isAdmin = ["ttp_administrator", "council_administrator"].includes(
        session?.council_role
      );

      if (isAdmin) return false;

      return owners?.some(o => o.user.id === session?.id);
    }

    return false;
  }, [session, selectedCouncil, owners]);

  const makeStagesList = useMemo(() => {
    if (!selectedProject) {
      return []
    }

    return selectedProject.project_stages?.map(s => ({...s.stage})) || [];
  }, [selectedProject]);

  const handleProjectStage = (e) => {
    const { value } = e.target;
    const getStageObj = makeStagesList.find(stage => stage.id === value);

    if (getStageObj) {
      setStage(getStageObj);
    }

    if (updateDirectly) {
      const payload = {
        userId: session.id,
        projectId: selectedProject.id,
        councilId: selectedCouncil.id,
        stage_id: value,
      }

      dispatch(projectPut(payload));
    }
  };

  if (!stage) {
    return '-';
  }

  return (
    <div className={`${editProjectInstance ? styles.editProjectStage : styles.projectStage}`}>
      {
        editProjectInstance && !loggedUserIsProjectOwner ? (
          <div className="w-100">
            <Label>Project Stage</Label>
            <SimpleSelect
              multi={false}
              values={stage ? [stage] : []}
              className="mb-3"
              name="projectStage"
              onChange={(val) => {
                setStage(val[0]);
                if (updateStage) {
                  updateStage(val[0]);
                }
              }}
              options={makeStagesList}
              labelField="name"
              valueField="id"
              placeholder="Project STage"
              searchBy="name"
            />
          </div>
        ) : (
          <FormControl sx={{ m: 1, minWidth: '100%', marginLeft: 0 }} size="small">
            <Select
              value={stage?.id}
              onChange={handleProjectStage}
              className={styles.projectStageSelect}
              disableUnderline
              disabled={loggedUserIsProjectOwner || disabled}
              autoWidth
            >
              {makeStagesList.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      }
    </div>
  );
};

export default React.memo(ProjectStage);

import * as Yup from "yup";

const initModel = {
  email: "",
};

const GSK_CLIENT_ID = "0oa1ixpmiwgqa5w020h8";
const GSK_CLIENT_ID_PROD = "traction";

const validation = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address"),
});

export {
  initModel, validation, GSK_CLIENT_ID, GSK_CLIENT_ID_PROD,
};

import { useDispatch, useSelector } from "react-redux";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { useSnackbar, withSnackbar } from "notistack";
import { Formik } from "formik";

import { ModalBody, ModalFooter } from "../../../../../application/modal";
import {
  BtnType,
  Button,
  Label,
  MultiSelect,
  TextBox,
  DatePiker,
} from "../../../../../modules/primitives";
import { useModelPopup } from "../../../../../common/hooks";
import { httpPut } from "../../../../../common/httpCall";
import { updateProjectFieldGroups } from "modules/projects/project/project.action";
import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import PlantMultiSelect from "../../../../../common/components/plantMultiselect";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { checkIsStringWhenExpectArray, isANumber, makeFormikObj } from "../../../../../common/helper";
import { currencySymbolObj } from "../../../../../common/constants";

const ProjectFieldsEdit = ({ selectedGroup, taskInstance, handleSave }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();

  const {
    adminPlantsAndAreasReducer: { plantsAndAreas },
    councilReducer: { selectedCouncil },
    projectsReducer: {
      projectReducer: { selectedProject },
    },
  } = useSelector((state) => state);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState(null);
  const [model, setModel] = useState({});

  useEffect(() => {
    if (!plantsAndAreas.length && selectedCouncil?.name === "Ford") {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        })
      );
    }
  }, [plantsAndAreas, selectedCouncil]);

  useEffect(() => {
    if (selectedGroup) {
      const makeData = {
        ...selectedGroup,
        project_fields: selectedGroup.project_fields.map(makeFormikObj),
      };

      setData(makeData);

      const fields = makeData.project_fields.reduce(
        (acc, elem) => ({
          ...acc,
          [elem.project_field_template_id || elem.id]: elem.values,
        }),
        {}
      );
      const possiblePlants = getPossiblePlantsFromSource(selectedProject);

      setModel({
        ...fields,
        selectedPlant1: possiblePlants,
        selectedAreas1: getPossibleAreasFromSource(data, possiblePlants),
      });
    }
  }, [selectedGroup]);

  const getPlantsAsOptions = useMemo(() => {
    return plantsAndAreas.map((plant) => ({
      id: plant.id,
      name: plant.attributes.name,
      areas: plant.attributes.areas,
    }));
  }, [plantsAndAreas]);

  const getPossiblePlantsFromSource = useCallback(
    (data) => {
      if (!data?.possible_plants.length) {
        return [];
      }

      return plantsAndAreas
        .map((p) => ({ ...p, ...p.attributes }))
        .filter((p) =>
          data?.possible_plants.some((plant) => plant.id === p.id)
        );
    },
    [plantsAndAreas]
  );

  const getPossibleAreasFromSource = useCallback(
    (data, possiblePlants) => {
      if (!data?.possible_areas?.length) {
        return [];
      }

      const flattenAreas = possiblePlants.reduce(
        (acc, plant) => [...acc, ...plant.areas],
        []
      );

      return data?.possible_areas?.map((a) =>
        flattenAreas.find((a1) => a1.area_id === a.area_id)
      );
    },
    [plantsAndAreas]
  );

  const onSubmitForm = (values) => {
    setIsSubmitting(true);

    const isFord = selectedCouncil?.name === "Ford";
    const groupHasPlant = selectedGroup?.project_fields?.some(
      (f) => f.field_name === "Possible Replications (Plants)"
    );
    let fordProps = {};

    if (isFord && groupHasPlant) {
      fordProps = {
        possible_plant_ids: values.selectedPlant1?.map((p) => p.id) || [],
        possible_area_ids: values.selectedAreas1?.map((a) => a.area_id) || [],
      };
    }

    const data = {
      ...fordProps,
      project_field_group_id: selectedGroup?.id,
      project_field_template_values: Object.keys(values)
        .filter((k) => {
          const getFieldDetails = selectedGroup.project_fields.find(
            (elem) => (elem.project_field_template_id || elem.id) === k
          );
          return (
            getFieldDetails?.field_name !== "Possible Replications (Plants)" &&
            getFieldDetails?.field_name !== "Possible Replications (Areas)" &&
            k !== "selectedPlant1" &&
            k !== "selectedAreas1"
          );
        })
        .map((k) => {
          const getFieldDetails = selectedGroup.project_fields.find(
            (elem) => (elem.project_field_template_id || elem.id) === k
          );
          let otherProps;
          let payload = {};

          if (getFieldDetails?.project_field_template_id) {
            otherProps = {
              project_field_template_id:
                getFieldDetails.project_field_template_id,
            };
          } else if (getFieldDetails?.id) {
            otherProps = {
              id: getFieldDetails.id,
            };
          }

          if (getFieldDetails?.field_type === "text") {
            payload = {
              ...payload,
              ...otherProps,
              field_value: String(values[k] || "").length ? values[k] : null,
            };
          }

          if (getFieldDetails?.field_type === "dropdown") {
            payload = {
              ...payload,
              ...otherProps,
              option_selected: values[k].map((el) => el.id),
            };
          }
          if (getFieldDetails?.field_type === "date") {
            payload = {
              ...payload,
              ...otherProps,
              field_date: values[k] ? new Date(values[k]).toISOString() : null,
            };
          }

          if (
            getFieldDetails?.field_type === "number" ||
            getFieldDetails?.field_type === "currency"
          ) {
            payload = {
              ...payload,
              ...otherProps,
              option_selected: getFieldDetails.option_values[0]
                ? [getFieldDetails.option_values[0]]
                : [],
              field_number: values[k] ? Number(values[k]) : null,
            };
          }

          return payload;
        }),
    };

    if (taskInstance) {
      handleSave(data);
      popup.hide();
      return;
    }

    httpPut({
      call: `projects/${selectedProject.id}/project_fields`,
      data,
    })
      .pipe()
      .subscribe((res) => {
        setIsSubmitting(false);
        if (res.response) {
          const message = selectedGroup.name
            ? `Successfully updated ${selectedGroup.name} group fields`
            : "Successfully updated fields";

          enqueueSnackbar(message, {
            variant: "success",
          });

          dispatch(
            updateProjectFieldGroups({
              response: res.response,
              group: selectedGroup,
            })
          );

          popup.hide();
        }
      });
  };

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSubmit = () => {
    const submitForm = formRef?.current?.submitForm;
    submitForm();
  };

  const handleChangeNumber = (props) => {
    const { evt, selectedGroup, setFieldValue, values, field } = props;

    if (selectedGroup?.name === 'Impact') {
      const getHeadCountField = selectedGroup.project_fields.find(f => f.field_name === 'Headcount');

      if (field.field_name === 'Headcount (Op/Crew)' && getHeadCountField) {
        const getShiftField = selectedGroup.project_fields.find(f => f.field_name === 'Shifts');
        if (!getShiftField) return;
        const headCountNumber = evt.target.value * values[getShiftField.project_field_template_id || getShiftField.id];
        setFieldValue((getHeadCountField.project_field_template_id || getHeadCountField.id), headCountNumber);
      }
      if (field.field_name === 'Shifts' && getHeadCountField) {
        const getHeadCountOpField = selectedGroup.project_fields.find(f => f.field_name === 'Headcount (Op/Crew)');
        if (!getHeadCountOpField) return;
        const headCountNumber = evt.target.value * values[getHeadCountOpField.project_field_template_id || getHeadCountOpField.id];
        setFieldValue((getHeadCountField.project_field_template_id || getHeadCountField.id), headCountNumber);
      }
    }
  }

  if (!data) {
    return <LoadingComponent customText="Loading group fields..." />;
  }

  return (
    <div>
      <ModalBody>
        <div>
          <div className="d-flex flex-column">
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={model}
              onSubmit={(values) => {
                onSubmitForm(values, data);
              }}
            >
              {({ handleSubmit, setFieldValue, values, ...formikprops }) => (
                <form onSubmit={handleSubmit}>
                  <h5 className="font-weight-bold mb-4">
                    {selectedGroup.name || "Ungrouped fields"}
                  </h5>
                  {[
                    ...data.project_fields,
                    ...(data.project_metrics?.map(m => ({...m, field_name: m.name, field_value: m.value, isMetricField: true})) || [])
                  ].map((field) => {

                    if (
                      field.field_name === "Possible Replications (Plants)" &&
                      selectedCouncil?.name === "Ford"
                    ) {
                      return (
                        <div
                          key={field.project_field_template_id || field.id}
                          className="mb-3"
                        >
                          <PlantMultiSelect
                            formikprops={{
                              values,
                              setFieldValue,
                              ...formikprops,
                            }}
                            plants={getPlantsAsOptions}
                          />
                        </div>
                      );
                    }

                    if (
                      field.field_name === "Possible Replications (Areas)" &&
                      selectedCouncil?.name === "Ford"
                    ) {
                      return null;
                    }

                    if (
                      (field.field_name === "Implementation Funded By" ||
                        field.field_name === "Prototype Funded By") &&
                      selectedCouncil?.name === "Ford"
                    ) {
                      const hasProgramSelected = checkIsStringWhenExpectArray(values[
                        field.project_field_template_id || field.id
                      ]).some(
                        (el) =>
                          el.name === "Program" ||
                          el.name === "Program (Prototype)"
                      );
                      const getProgram = data.project_fields.find(
                        (f) =>
                          f.field_name === "Program (Implementation)" ||
                          f.field_name === "Program (Prototype)"
                      );

                      return (
                        <div key={field.project_field_template_id || field.id}>
                          <div className="mb-3">
                            <Label>{field.field_name}</Label>
                            <MultiSelect
                              name={field.project_field_template_id || field.id}
                              values={values[field.project_field_template_id || field.id] || []}
                              placeholder={field.field_placeholder || field.field_name}
                              labelField="name"
                              valueField="id"
                              onChange={(val) => {
                                setFieldValue(
                                  field.project_field_template_id || field.id,
                                  val
                                ).then(() => {
                                  if (
                                    getProgram &&
                                    !values[
                                      getProgram.project_field_template_id ||
                                        getProgram.id
                                    ]?.length
                                  ) {
                                    setFieldValue(
                                      getProgram.project_field_template_id ||
                                        getProgram.id,
                                      [getProgram.option_values[0]]
                                    );
                                  }
                                });
                              }}
                              options={field.option_values || []}
                              multi={field.multiselect}
                            />
                          </div>
                          {
                            field.oldSelectedValues?.length ? (
                              <div style={{ top: "-20px" }} className="small text-muted position-relative">
                                <div>Saved option(s) no longer available: <span className="text-danger">
                                  {field.oldSelectedValues.map((el, index) => index < field.oldSelectedValues.length - 1 ? `${el},\t` : el,)}
                                </span></div>
                              </div>
                            ) : null
                          }
                          {hasProgramSelected && getProgram && (
                            <div className="mb-3">
                              <Label>{getProgram.field_name}</Label>
                              <MultiSelect
                                name={
                                  getProgram.project_field_template_id ||
                                  getProgram.id
                                }
                                values={
                                  values[
                                    getProgram.project_field_template_id ||
                                      getProgram.id
                                  ] || []
                                }
                                labelField="name"
                                valueField="id"
                                onChange={(val) => {
                                  setFieldValue(
                                    getProgram.project_field_template_id ||
                                      getProgram.id,
                                    val
                                  );
                                }}
                                options={getProgram.option_values}
                                multi={getProgram.multiselect}
                                placeholder={getProgram.field_placeholder || getProgram.field_name}
                              />
                            </div>
                          )}
                        </div>
                      );
                    }

                    if (
                      (field.field_name === "Program (Implementation)" ||
                        field.field_name === "Program (Prototype)") &&
                      selectedCouncil?.name === "Ford"
                    ) {
                      return null;
                    }

                    return (
                      <div key={field.project_field_template_id || field.id}>
                        {
                          field.isMetricField && (
                            <div>
                              <Label>{field.field_name}</Label>
                              <div className="caption mt-2" style={{
                                backgroundColor: '#fafafa',
                                border: '1px solid #e4e7eb',
                                padding: '5px'
                              }}>
                                {
                                  field.type === '%' && field.field_value ? `${field.field_value.toLocaleString()}%` : ''
                                }
                                {
                                  field.type === '$' && (
                                    isANumber(field?.field_value)
                                      ? `${field.type}${field.field_value.toLocaleString()}`
                                      : field.field_value
                                  )
                                }
                              </div>
                            </div>
                          )
                        }
                        {field.field_type === "text" ? (
                          <div>
                            <Label>{field.field_name}</Label>
                            {field.big_box_text ? (
                              <textarea
                                className="w-100 border small p-2"
                                value={
                                  values[
                                    field.project_field_template_id || field.id
                                  ]
                                }
                                placeholder={field.field_placeholder || field.field_name}
                                onChange={(evt) =>
                                  setFieldValue(
                                    field.project_field_template_id || field.id,
                                    evt.target.value
                                  )
                                }
                                rows="5"
                              ></textarea>
                            ) : (
                              <TextBox
                                name={
                                  field.project_field_template_id || field.id
                                }
                                placeholder={field.field_placeholder || field.field_name}
                                formProps={{ ...formikprops, values }}
                                className="w-100"
                                value={
                                  values[
                                    field.project_field_template_id || field.id
                                  ]
                                }
                              />
                            )}
                          </div>
                        ) : null}
                        {field.field_type === "dropdown" ? (
                          <div>
                            <Label>{field.field_name}</Label>
                            <MultiSelect
                              name={field.project_field_template_id || field.id}
                              values={
                                values[
                                  field.project_field_template_id || field.id
                                ]
                              }
                              labelField="name"
                              valueField="id"
                              onChange={(val) =>
                                setFieldValue(
                                  field.project_field_template_id || field.id,
                                  val
                                )
                              }
                              options={field.option_values || []}
                              multi={field.multiselect}
                              placeholder={field.field_placeholder || field.field_name}
                            />
                            {
                              field.oldSelectedValues?.length ? (
                                <div style={{ top: "-20px" }} className="small text-muted position-relative">
                                  <div>Saved option(s) no longer available: <span className="text-danger">
                                    {field.oldSelectedValues.map((el, index) => index < field.oldSelectedValues.length - 1 ? `${el},\t` : el,)}
                                  </span></div>
                                </div>
                              ) : null
                            }
                          </div>
                        ) : null}
                        {field.field_type === "date" ? (
                          <div className="w-100">
                            <Label>{field.field_name}</Label>
                            <div className="w-100 d-flex align-items-center ml-0 mb-2">
                              <DatePiker
                                name={field.field_name}
                                value={
                                  field.field_date ? field.field_date : null
                                }
                                formProps={{
                                  ...formikprops,
                                  setFieldValue,
                                  values,
                                }}
                                onChange={(val) => {
                                  setFieldValue(
                                    field.project_field_template_id || field.id,
                                    val
                                  );
                                }}
                                withClear
                                className="w-100 "
                              />
                            </div>
                          </div>
                        ) : null}
                        {field.field_type === "number" ||
                        field.field_type === "currency" ? (
                          <div className="w-100">
                            <Label>{field.field_name}</Label>
                            <div className="w-100 d-flex align-items-center ml-0 mb-2">
                              <TextBox
                                name={
                                  field.project_field_template_id || field.id
                                }
                                type="number"
                                min="0"
                                max="10000000000"
                                placeholder={field.field_placeholder || field.field_name}
                                formProps={{ ...formikprops, values }}
                                className="w-100"
                                value={
                                  values[
                                    field.project_field_template_id || field.id
                                  ]
                                }
                                onChange={(evt) => handleChangeNumber({
                                  evt, selectedGroup, setFieldValue, values, field
                                })}
                              />
                              {field.field_type === "currency" && (
                                <div className=" d-flex align-items-center ml-2 mb-3">
                                  {field.option_values
                                    ? field.option_values[0]?.name ? currencySymbolObj[field.option_values[0].name] :
                                      currencySymbolObj[field.option_selected[0]]
                                    : null}
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {isSubmitting ? (
          <LoadingComponent customText="Saving data..." />
        ) : (
          <div className="d-flex justify-content-end">
            <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button type="submit" btn={BtnType.REGULAR} onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        )}
      </ModalFooter>
    </div>
  );
};
export default React.memo(withSnackbar(ProjectFieldsEdit));

import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import html2canvas from "html2canvas";
import Tooltip from "@mui/material/Tooltip";

import styles from "./ideaProfileHeader.module.scss";
import { BtnType, Button } from "../../../primitives";
import IdeaProfileHeaderActions from "../ideaProfileHeaderActions";
import { checkViewerRole } from "../../../../common/checkers/viewerChecker";
import VoteIdea from "../../voteIdea";
import { Icon, mdSize, smSize } from "../../../../common/icon";
import { editIdeaAttempt } from "../../idea.action";
import { useModelPopup } from "../../../../common/hooks";
import ConfirmDeleteComponent from "../../../../common/components/confirmDelete";
import NewProjectV3 from "modules/projects/newProjectv3";

const IdeaProfileHeader = ({ canEdit, selectedIndex }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [downloadIsActive, setDownloadIsActive] = useState(false);
  const [editIsActive, setEditIsActive] = useState(false);
  const [ideaName, setIdeaName] = useState("");
  const [canVote, setCanVote] = useState(true);
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSubmiter, setIsSubmiter] = useState(false);

  const {
    ideaReducer: { selectedIdea },
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);

  useEffect(() => {
    if (session) {
      const council_role = session?.council_role;
      setIsAdmin(
        council_role === "ttp_administrator" ||
          council_role === "council_administrator"
      );
    }

    return () => null;
  }, [session]);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (selectedCouncil && session && selectedIdea) {
      const council_role = session?.council_role;
      const isKyndryl = selectedCouncil?.name === "Kyndryl";
      setIsSubmiter(selectedIdea.submitter.user_id === session?.id);
      if (isKyndryl) {
        const currentUserIsReviewer = selectedIdea.ideas_members
          .filter((m) => m.user_role === "reviewer")
          .some((u) => u.user_id === session?.id);

        setCanVote(
          currentUserIsReviewer ||
            council_role === "ttp_administrator" ||
            council_role === "council_administrator"
        );
      } else {
        setCanVote(true);
      }
    }

    return () => null;
  }, [session, selectedCouncil, selectedIdea]);

  useEffect(() => {
    if (selectedIdea) {
      setIdeaName(selectedIdea.name);
    }

    return () => null;
  }, [selectedIdea]);

  useEffect(() => {
    if (downloadIsActive) {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }

    return () => null;
  }, [downloadIsActive]);

  const handleGenerateReportClick = () => {
    setDownloadIsActive(true);

    const timeout = setTimeout(() => {
      const id = selectedIndex === 1 ? "freddieMacLeanCanvas" : "ideaProfile";
      const elem = document.getElementById(id);
      const htmlWidth = elem.offsetWidth;
      const htmlHeight = elem.offsetHeight;
      const topLeftMargin = 15;
      const canvasImageWidth = htmlWidth;
      const canvasImageHeight = htmlHeight;
      const pdfWidth = htmlWidth + topLeftMargin * 2;
      const height = pdfWidth * 1.5 + topLeftMargin * 2;
      const pdfHeight = height < 2287 ? 2287 : height;
      const totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1;

      html2canvas(elem, {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        canvas.getContext("2d");
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        const pdf = new jsPDF("p", "pt", [pdfWidth, pdfHeight]);
        pdf.addImage(
          imgData,
          "png",
          topLeftMargin,
          topLeftMargin,
          canvasImageWidth,
          canvasImageHeight
        );

        for (let i = 1; i <= totalPDFPages; i++) {
          pdf.addPage([pdfWidth, pdfHeight], "p");
          pdf.addImage(
            imgData,
            "png",
            topLeftMargin,
            -(pdfHeight * i) + topLeftMargin * 4,
            canvasImageWidth,
            canvasImageHeight
          );
        }

        if (selectedIndex === 1) {
          pdf.save(`${selectedCouncil?.name}_Lean_Canvas_report.pdf`);
        } else {
          pdf.save(`${selectedIdea.name}_report.pdf`);
        }

        setDownloadIsActive(false);

        clearTimeout(timeout);
      });
    }, 1000);
  };

  const handleSaveIdeaName = () => {
    if (!ideaName.length) {
      enqueueSnackbar("Idea name is required!", {
        variant: "error",
      });

      return;
    }

    setEditIsActive(false);

    const payload = {
      idea: {
        id: selectedIdea.id,
        ideas_template_id: selectedIdea.ideas_template_id,
        name: ideaName,
      },
      cb: () => {
        enqueueSnackbar("Successfully changed idea name", {
          variant: "success",
        });
      },
      enqueueSnackbar,
    };

    dispatch(editIdeaAttempt(payload));
  };
  const deleteIdea = () => {
    popup.show({
      title: "Confirmation Prompt",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={selectedIdea.id}
          enqueueSnackbar={enqueueSnackbar}
          councilId={selectedCouncil?.id}
          name={ideaName}
          actionType="deleteIdea"
          onOrganisationDelete={"refresh"}
        />
      ),
    });
  };
  return (
    <div className={styles.headerWrapper}>
      {downloadIsActive && (
        <div className={styles.chartDownloadBadge}>
          <span className={styles.bolder}>Traction</span>
          <span className={styles.light}>Report</span>
        </div>
      )}
      <div className={styles.headerProfileDetails}>
        <div className="d-flex flex-column w-25">
          <div className="d-flex align-items-center mb-3">
            {canEdit ? (
              editIsActive ? (
                <input
                  value={ideaName}
                  onChange={(evt) => setIdeaName(evt.target.value)}
                />
              ) : (
                <h3 className={styles.ideaName}>{ideaName}</h3>
              )
            ) : (
              <h3 className={styles.ideaName}>{ideaName}</h3>
            )}
            {canEdit ? (
              !editIsActive ? (
                <Icon
                  {...mdSize}
                  icon="icn-edit-button"
                  className={styles.editIcon}
                  onClick={() => setEditIsActive(true)}
                />
              ) : (
                <Button
                  btn={BtnType.FRAME_LESS}
                  onClick={handleSaveIdeaName}
                  className="ml-2"
                >
                  save
                </Button>
              )
            ) : null}
          </div>
        </div>
        <div className="d-flex flex-column">
          <div
            className={`d-flex align-items-center flex-column ${styles.voteWrapper}`}
          >
            <VoteIdea idea={selectedIdea} canVote={canVote} isProfileUse />
            <span className={styles.voteDescription}>
              Cast your vote for this idea
            </span>
          </div>
          <IdeaProfileHeaderActions
            selectedIdea={selectedIdea}
            canEdit={canEdit}
          />
        </div>
        <div className="d-flex align-items-baseline">
          {session?.council_role !== "company" && (
            <div className="d-flex flex-column mr-2">
              {selectedCouncil?.name === "Kyndryl" ? (
                canEdit ? (
                  <NewProjectV3
                    isAdmin={isAdmin}
                    blockFunctionality={blockFunctionality}
                    wizardType="idea"
                    additionalData={selectedIdea}
                    btnName="New Project"
                  />
                ) : null
              ) : (
                <>
                  {!blockFunctionality ? (
                    <NewProjectV3
                      isAdmin={isAdmin}
                      blockFunctionality={blockFunctionality}
                      wizardType="idea"
                      additionalData={selectedIdea}
                      btnName="New Project"
                    />
                  ) : (
                    <Tooltip
                      title="You have View-Only access. To create a Project, please ask your Admin to upgrade your account to Standard access."
                      placement="top"
                    >
                      <Button btn={BtnType.DISABLED} icon="icn-add">
                        New Project
                      </Button>
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          )}
          <Button
            btn={BtnType.REGULAR}
            className={styles.generateReport}
            icon="icn-export-button"
            onClick={handleGenerateReportClick}
            disabled={downloadIsActive}
          >
            {selectedIndex === 1 ? "Download Canvas" : "Download Report"}
          </Button>
        </div>
      </div>
      <div className="d-flex flex-row mr-12 justify-content-end">
        {isAdmin && (
          <Button
            btn={BtnType.FRAME_LESS}
            icon="icn-edit-button"
            onClick={() => history.push(`/admin/ideas/${selectedIdea.id}`)}
          >
            See all votes
          </Button>
        )}
        {(isAdmin || isSubmiter) && (
          <Button
            style={{ color: "#DE2F3B" }}
            btn={BtnType.FRAME_LESS}
            onClick={() => deleteIdea()}
          >
            <span className="d-flex flex-row mr-12 justify-content-center align-items-center">
              <Icon
                {...smSize}
                icon="icn-button-delete"
                className="mr-2"
              />
              DELETE
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default React.memo(IdeaProfileHeader);

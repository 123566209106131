import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  councilGetTeamById,
  councilTeamAddMember,
  councilSearchTeamMember,
} from "modules/council/council.action";
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import { getCookie } from "common/helper";
import styles from "./editTeam.module.scss";
import { Icon, smSize } from "../../../../common/icon";
import { Button, BtnType, TextBox } from "../../../primitives";
import { councilUsersListGet } from "../../../../common/actions/common.action";
import "react-table/react-table.css";
import { useModelPopup } from "../../../../common/hooks";
import ConfirmDeleteComponent from "../../../../common/components/confirmDelete";

const EditTeamComponent = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [searchVal, setSearchVal] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef();
  const {
    councilReducer: {
      selectedCouncil,
      selectedTeam,
      updateTeams,
      teamMembers,
      loadTeams,
      teamMemberNr,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (id && selectedCouncil) {
      dispatch(councilGetTeamById({ id, enqueueSnackbar }));
      dispatch(councilUsersListGet(selectedCouncil.id, enqueueSnackbar));
    }
  }, [id, selectedCouncil]);

  useEffect(() => {
    if (searchVal && searchVal !== "") {
      dispatch(
        councilSearchTeamMember({
          query: searchVal,
          search: {
            topic_ids: [],
            industy_ids: [],
          },
          enqueueSnackbar,
        })
      );
    } else if (searchVal === "") {
      dispatch(councilGetTeamById({ id, enqueueSnackbar }));
    }
  }, [searchVal, updateTeams]);

  const handleDeleteTeamMember = (memberId) => {
    popup.show({
      title: "Confirmation Prompt",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={memberId}
          teamId={id}
          enqueueSnackbar={enqueueSnackbar}
          actionType="deleteTeamMember"
        />
      ),
    });
  };

  const handleExportList = () => {
    csvLinkRef.current.link.click();
  };

  useEffect(() => {
    if (selectedTeam && selectedTeam.members) {
      setCsvData(() => {
        const csvDataCont = [["Member Name"]];
        selectedTeam.members.map((member) => {
          csvDataCont.push([member.full_name]);
        });

        return csvDataCont;
      });
    }
  }, [selectedTeam]);

  const handleTeamMemberSearch = (e) => {
    setSearchVal(e.target.value);
  };

  const handleAddTeamMember = (member) => {
    dispatch(
      councilTeamAddMember({
        council_id: JSON.parse(localStorage.getItem("council")).id,
        user_id: member,
        role: "standard",
        team_id: id,
        enqueueSnackbar,
      })
    );
    setSearchVal("");
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Member Name",
        id: "full_name",
        accessor: (row) => (
          <div
            onClick={() => history.push(`/people/internal/profile/${row.id}`)}
          >
            {row.full_name}
          </div>
        ),
        width: "80%",
      },
      {
        Header: " ",
        id: "options",
        sortable: false,
        accessor: (row) =>
          searchVal ? (
            selectedTeam.members.find((member) => member.id === row.id) ? (
              <Button
                icon="icn-button-delete"
                className={styles.button}
                btn={BtnType.OUTLINE}
                onClick={() => handleDeleteTeamMember(row.id)}
              >
                Remove
              </Button>
            ) : (
              <Button
                icon="icn-add"
                className={styles.button}
                btn={BtnType.OUTLINE}
                onClick={() => handleAddTeamMember(row.id)}
              >
                Add to team
              </Button>
            )
          ) : (
            <div className={styles.deleteIcon}>
              <Icon
                {...smSize}
                icon="icn-button-delete"
                onClick={() => handleDeleteTeamMember(row.id)}
              />
            </div>
          ),
        width: 150,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
          cursor: "pointer",
        },
      },
    ],
    [searchVal, updateTeams]
  );

  const current_role = getCookie("council_role_");
  return (
    <div className={styles.container}>
      <div className="row">
        <div className="col-10" style={{ margin: "0 auto" }}>
          <div className={styles.headerWrp}>
            <h4 className={styles.taskHeaderText}>
              {current_role &&
                (current_role === "ttp_administrator" ||
                  current_role === "council_administrator") && (
                  <span>
                    <span
                      className={styles.rootText}
                      onClick={() => {
                        history.push("/admin");
                      }}
                    >
                      ADMIN
                    </span>
                    <span className={styles.separationBar}> / </span>
                    <span
                      className={styles.rootText}
                      onClick={() => {
                        history.push("/admin/teams");
                      }}
                    >
                      TEAMS
                    </span>
                    <span className={styles.separationBar}> / </span>
                  </span>
                )}
              <span className={styles.companyName}>{selectedTeam?.name}</span>
            </h4>
          </div>
        </div>
      </div>
      <div className="row">
        {" "}
        <div className="col-10" style={{ margin: "0 auto" }}>
          <TextBox
            value={searchVal}
            style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
            onChange={handleTeamMemberSearch}
            placeholder="Type to search or add members to team"
          />{" "}
          <div className={styles.infoContainer}>
            <div className={styles.buttonWrp}>
              {teamMembers?.length > 0 && (
                <div className={searchVal && styles.invisible}>
                  <Button onClick={handleExportList} btn={BtnType.OUTLINE}>
                    EXPORT
                  </Button>
                  <CSVLink
                    data={csvData}
                    filename={`${selectedTeam?.name} Team Members.csv`}
                    style={{ display: "none" }}
                    ref={csvLinkRef}
                  />
                </div>
              )}
            </div>
            <div className={styles.memberNr}>
              {searchVal ? teamMemberNr.total : selectedTeam?.members?.length}{" "}
              Members
            </div>
          </div>
          <ReactTable
            data={teamMembers}
            columns={columns}
            className={styles.table}
            loading={loadTeams}
          />
        </div>
      </div>
    </div>
  );
};

export default EditTeamComponent;

export const homeInfoTypes = [
  {
    id: "research",
    class: "research",
    title: "Research",
    description:
      "Request research from Traction analysts or Team members on companies and emerging technologies.",
    button: "Request Research",
  },
  {
    id: "contribute",
    class: "contribute",
    title: "Contribute",
    description:
      "Add a company to the database by entering its name and website link.",
    button: "Add Company",
  },
  {
    id: "evaluate",
    class: "evaluate",
    title: "Evaluate",
    description:
      "Create a project to evaluate the companies with the potential to solve your biggest challenges.",
    button: "New Project",
  },
];

export const homeInfoTypesKyndrylException = [
  {
    id: "customerVoice",
    class: "kyndryl",
    title: " Search",
    description:
      "Search for Innovation Partners, Alliances, Suppliers or companies currently under consideration for a relationship.",
    button: "Search",
  },
  {
    id: "contribute",
    class: "kyndryl",
    title: "Add Company",
    description:
      "Assess a new company and follow the required process as mandated by procurement.  (Click here for more info)",
    button: "Add Company",
  },
  {
    id: "ideas",
    class: "kyndryl",
    title: "Customer Voice",
    description:
      "Submit a customer challenge so we can solve with our Innovation or Alliance partners.",
    button: "Submit Idea",
  },
];

export const homeInfoTypesWorldWideTechnologyException = [
  {
    id: "customerVoice",
    class: "evaluate",
    title: " Search",
    description: "Search for Partners or companies currently under review.",
    button: "Search",
  },
  {
    id: "contribute",
    class: "evaluate",
    title: "Add Company",
    description: "Evaluate a new company for possible partnership.",
    button: "Add Company",
  },
  {
    id: "ideas",
    class: "evaluate",
    title: "Customer Voice",
    description: "Submit a challenge or idea for further evaluation.",
    button: "Submit Idea",
  },
];
export const FordInfoCards = [
  {
    id: "solutions",
    class: "research",
    title: "Solutions",
    description:
      "Explore Ford’s catalog of Manufacturing Innovation Solutions. See how other Ford plants have solved technology challenges and share knowledge with Subject Matter Experts across Ford.",
    button: "GO TO SOLUTIONS",
  },
  {
    id: "projects",
    class: "evaluate",
    title: "Projects",
    description:
      "Explore Ford’s catalog of Manufacturing Innovation Projects. See what projects other Ford plants have implemented that are ready to Replicate.",
    button: "GO TO PROJECTS",
  },
];

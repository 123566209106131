import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { FilePicker } from "react-file-picker";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";

import { truncateString } from "common/helper";
import styles from "./taskInfo.module.scss";
import {
  Button,
  BtnType,
  UserInline,
  Note,
  DatePiker,
  State,
  Loading,
  CompanyAvatar,
} from "../../../../primitives";
import AddNotesComponent from "./addNotes/addNotes.component";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import DocumentListComponent from "./documentList";
import { useS3FileUpload, useModelPopup } from "../../../../../common/hooks";
import {
  projectTaskNotesDelete,
  projectTaskNotesList,
  projectTaskDocumentAdd,
  projectTaskUpdate,
  clearUploadedDoc,
  markTaskAsCompleted,
} from "../tasks.action";
import { appSliderSettingSet } from "application/app.action";
import UploadedByInfo from "../../../../../common/components/uploadedByInfo";
import { ALLOWED_FILE_EXTENSIONS } from "../../../../../common/constants";
import { httpGet } from "../../../../../common/httpCall";
import TaskGroupFields from "modules/projects/project/tasks/taskInfo/taskGroupFields";
import RequestFeedback from "modules/projects/project/tasks/taskInfo/requestFeeback";
import LessonLearned from "modules/projects/project/tasks/taskInfo/lessonLearned";
import LinearProgressWithLabel from "../../../../../common/components/linearProgressWithLabel";
import ManageTaskUsers from "modules/projects/project/tasks/taskInfo/manageTaskUsers";
import { getUpcomingTasks } from "modules/projects/project/overview/overview.action";

const TaskInfoComponent = ({
  task,
  assignment,
  company,
  stage,
  isProjectWideFromStage,
  blockFordOnly,
  activeTab,
  upcomingTaskInstance
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { fileUpload } = useS3FileUpload();
  const [assignmentObj, setAssignmentObj] = useState(null);
  const [selectedNote, setSelectedNote] = useState();
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [isDateEditMode, setDateEditMode] = useState(false);
  const [assignmentDocuments, setAssignmentDocuments] = useState([]);
  const [taskFieldsGroup, setTaskFieldsGroup] = useState(null);
  const [uploadValues, setUploadValues] = useState([]);

  const [model, setModel] = useState({
    due_date: "",
    assigned_to: "",
  });

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    projectsReducer: {
      stagesReducer: { stagesList, projectWideTasks },
      projectReducer: { selectedProject },
      taskReducer: {
        documents,
        taskNotes,
        tasksList,
        uploadedDoc,
        loadingTask,
      },
    },
  } = useSelector((state) => state);

  const taskHasFieldOrGroup = (taskObj) => {
    return (
      taskObj.project_field_group?.id ||
      taskObj.project_field_template?.project_field_template_id ||
      taskObj.project_metric_attribute?.field_name
    );
  };

  const getUpdatedGroupData = () => {
    httpGet({
      call: `projects/${selectedProject.id}/tasks/${task.id}/project_fields`,
    }).subscribe((res) => {
      if (res.response) {
        if (upcomingTaskInstance) {
          dispatch(getUpcomingTasks({
            userId: activeTab === "my_task" ? session.id : null,
            enqueueSnackbar,
            projectId: selectedProject.id,
          }));
          dispatch(appSliderSettingSet({
            show: false,
          }));
        }
        setTaskFieldsGroup(res.response);
      }
    });
  };

  useEffect(() => {
    const hasFieldOrGroup = taskHasFieldOrGroup(task);

    if (!hasFieldOrGroup) {
      return;
    }

    getUpdatedGroupData();
  }, [task]);

  useEffect(() => {
    if (isProjectWideFromStage && projectWideTasks?.length) {
      const getTask = projectWideTasks.find((t) => t.id === task.id);

      if (getTask) {
        const taskAssignment = getTask.projects_task_assignments[0];
        const assignmentObj = taskAssignment || assignment;
        const docs = getTask.projects_task_assignments.reduce(
          (acc, elem) => [...acc, ...(elem.documents || [])],
          []
        );

        setAssignmentObj(assignmentObj);
        setModel({
          due_date:
            assignmentObj.due_date && isValidDate(assignmentObj.due_date)
              ? assignmentObj.due_date
              : "",
          assigned_to: assignmentObj?.assigned_to,
        });

        setAssignmentDocuments(docs || []);
      }

      return;
    }

    if (assignment) {
      const getTask = tasksList.find((t) => t.id === task.id);
      const getAssignment =
        getTask?.projects_task_assignments?.find(
          (a) => a.id === assignment.id
        ) || assignment;

      setAssignmentObj(getAssignment || assignment);
      setModel({
        due_date:
          getAssignment.due_date && isValidDate(getAssignment.due_date)
            ? getAssignment.due_date
            : "",
        assigned_to: getAssignment?.assigned_to,
      });

      setAssignmentDocuments(
        documents[assignment.id] || getAssignment?.documents || []
      );
    }
  }, [
    stagesList,
    isProjectWideFromStage,
    projectWideTasks,
    stage,
    company,
    task,
    documents,
    tasksList,
  ]);

  useEffect(() => {
    if (uploadedDoc?.id) {
      dispatch(clearUploadedDoc({}));
    }
  }, [uploadedDoc]);

  const isValidDate = (d) => {
    const date = new Date(d);
    if (date) {
      return date instanceof Date && !isNaN(date);
    }
    return true;
  };

  useEffect(() => {
    if (selectedProject?.id && assignmentObj?.id) {
      dispatch(
        projectTaskNotesList({
          projectId: selectedProject.id,
          assignmentId: assignmentObj.id,
          enqueueSnackbar,
        })
      );
    }
  }, [assignmentObj?.id]);

  const handleViewCompanyClick = () => {
    const {
      projects_task_organization: {
        organization_type,
        organization_id,
        organization,
      },
    } = assignmentObj;
    let url;

    if (organization_type === "Gsk::PatientOrganization") {
      url = `/patient_organizations/${organization?.slug || organization_id}`;
    } else {
      url = `/companies/${organization?.slug || organization_id}`;
    }

    if (organization_type === "CouncilProduct") {
      url = `/companies/${organization?.company_slug}/products/${organization?.slug}`;
    }

    dispatch(appSliderSettingSet({ show: false }));

    history.push(url);
  };

  const handleAssignUserClick = () => {
    popup.show({
      title: "Choose Member",
      // component: (
      //   <SelectProjectUser model={model} callBack={handleUserChange} />
      // ),
      component: (
        <ManageTaskUsers
          model={model}
          callBack={handleUserChange}
          multiple={false}
        />
      ),
    });
  };

  const handleUserChange = (payload) => {
    popup.hide();
    const commonProps = {
      assigned_to: payload.user,
      assigned_on: payload.user ? new Date() : null,
      add_as_project_member: payload.addAsAProjectMember,
    };
    const newModel = {
      ...model,
      ...commonProps,
    };
    setModel(newModel);
    setAssignmentObj({
      ...assignmentObj,
      ...commonProps,
    });
    updateCompanyTask(newModel, "assigned_to");
  };

  const handleDueDateChange = (date) => {
    if (!date) {
      setDateEditMode(true);
    }

    const newModel = {
      ...model,
      due_date: date || "",
    };

    setModel(newModel);
    updateCompanyTask(newModel, "due_date");

    const timeout = setTimeout(() => {
      setDateEditMode(false);
      clearTimeout(timeout);
    });
  };

  const handleAddDocumentClick = async (FileObject) => {
    const file = FileObject;
    const { name } = file;

    const extension = name.slice(((name.lastIndexOf(".") - 1) >>> 0) + 2);
    const isRightExtension = ALLOWED_FILE_EXTENSIONS.includes(extension);

    if (!isRightExtension) {
      const message = `Files with the extension "${extension}" are not allowed.`;
      enqueueSnackbar(message, {
        variant: "error",
        key: "extensionNotAllowed",
        persist: true,
      });

      const timeout = setTimeout(() => {
        closeSnackbar("extensionNotAllowed");
        clearTimeout(timeout);
      }, 5000);

      return;
    }

    setLoadingDocuments(true);
    await fileUpload(file, "documents/", setUploadValues).then((res) => {
      if (res && res.location) {
        savedocument(res.location, name);
        setLoadingDocuments(false);
        setUploadValues([]);
      }
    });
  };

  const handleDocDelete = (completed) => {
    if (!task?.mark_on_document_upload) {
      return;
    }

    let completionProps = {
      assignmentId: null,
    };

    const urlParams = new URLSearchParams(window.location.search);
    const assignmentQuery = urlParams.get("taskAsignementId");

    if (assignmentQuery === assignment.id) {
      completionProps = {
        ...completionProps,
        assignmentId: assignmentQuery,
      };
    }

    dispatch(
      markTaskAsCompleted({
        taskId: task.id,
        isProjectWideFromStage,
        completionProps,
        completed,
        task_completion_state: completed ? 'completed' : 'not_completed',
        deleteAction: true,
      })
    );
  };

  const handleMarkComplete = () => {
    if (task?.mark_on_document_upload) {
      if (upcomingTaskInstance) {
        dispatch(getUpcomingTasks({
          userId: activeTab === "my_task" ? session.id : null,
          enqueueSnackbar,
          projectId: selectedProject.id,
        }));
        dispatch(appSliderSettingSet({
          show: false,
        }));
        enqueueSnackbar(
          "Marked task as completed",
          {
            variant: "success",
          }
        );

        return;
      }

      let completionProps = {
        assignmentId: null,
        shouldCompleteAll: false,
      };

      const urlParams = new URLSearchParams(window.location.search);
      const assignmentQuery = urlParams.get("taskAsignementId");

      if (assignmentQuery === assignment.id) {
        completionProps = {
          ...completionProps,
          assignmentId: assignmentQuery,
          shouldCompleteAll: true,
        };
      }

      dispatch(
        markTaskAsCompleted({
          taskId: task.id,
          isProjectWideFromStage,
          completionProps,
          completed: true,
          task_completion_state: 'completed',
        })
      );
    }
  };

  const savedocument = (s3url, name) => {
    const obj = {
      projectId: selectedProject.id,
      userId: session.id,
      councilId: selectedCouncil.id,
      description: "",
      assignmentId: assignmentObj?.id,
      name,
      s3url,
      enqueueSnackbar,
      companyId: company?.id,
      cb: handleMarkComplete,
    };

    dispatch(projectTaskDocumentAdd(obj));
  };

  const getDocNameFromUri = (docUri) => {
    const arrayFromUri = docUri && docUri?.split("/");
    if (arrayFromUri.length) {
      return arrayFromUri[arrayFromUri.length - 1];
    }
    return "Untitled";
  };

  const updateCompanyTask = (taskAssignmentData, flag) => {
    const payload = {
      enqueueSnackbar,
      taskId: task.id,
      projectId: task.project_id,
      flag,
      project_task: {
        task_assignments: {
          assigned_to_id: taskAssignmentData.assigned_to?.id,
          due_date: taskAssignmentData.due_date,
          assigned_on: taskAssignmentData.assigned_on,
        },
        add_as_project_member: taskAssignmentData.add_as_project_member,
      },
      queryParams: {
        task_assignment_id: assignmentObj?.id,
      },
    };

    dispatch(projectTaskUpdate(payload));
  };

  const handleMoreOptionClick = (value, { id, description }) => {
    switch (value) {
      case "remove":
        dispatch(projectTaskNotesDelete({ id, enqueueSnackbar }));
        break;
      case "edit":
        setSelectedNote({ id, description });
        break;
      default:
        break;
    }
  };

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    }
    return document.execCommand("copy", true, text);
  };

  const getBtnName = useMemo(() => {
    const organizationType =
      assignmentObj?.projects_task_organization?.organization_type;

    if (organizationType === "CouncilProduct") {
      return "View Product";
    }

    return organizationType === "Company"
      ? "View company"
      : "View organization";
  }, [assignmentObj]);

  const isFeedbackRequestTask = useMemo(() => {
    const taskName = task?.name?.toLowerCase() || "";

    return (
      taskName === "gather replication feedback from spocs" ||
      taskName === "gather replication feedback from innovation leaders" ||
      taskName === "gather possible replication feedback" ||
      taskName === "ask team to rate project"
    );
  }, [task?.name]);

  const isLessonLearnedTask = useMemo(() => {
    return task.name === "Review Lessons Learned From Related Projects";
  }, [task?.name]);

  const checkStuffIsBlocked = useMemo(() => {
    if (selectedProject && session) {
      if (
        ["ttp_administrator", "council_administrator"].includes(
          session?.council_role
        )
      ) {
        return false;
      }

      const projectMember = selectedProject?.project_members.find(
        ({ user }) => user.id === session.id
      );

      if (selectedCouncil?.name === "Ford") {
        if (projectMember?.user_role === "owner" ||
          projectMember?.user_role === "member" ||
          projectMember?.user_role === "manager") {
          return false;
        }
      }

      if (projectMember?.user_role === "owner") {
        return false;
      }
    }

    if (task.assigned_users?.length) {
      return !task.assigned_users.some(
        (assigned) => assigned?.user_id === session.id
      );
    }

    return true;
  }, [selectedProject, session, task]);

  return (
    <div className={styles.taskInfoWrp}>
      {task?.task_type !== "project_specific" && (
        <div className={styles.companyWrp}>
          <div className={styles.companyCon}>
            <div className={styles.companyIcon}>
              <CompanyAvatar
                className={styles.logo}
                imgSrc={
                  assignmentObj?.projects_task_organization?.organization?.logo
                }
                name={
                  assignmentObj?.projects_task_organization?.organization?.name
                }
              />
            </div>
            <div className={styles.viewCompany}>
              <Button btn={BtnType.FRAME_LESS} onClick={handleViewCompanyClick}>
                {getBtnName}
              </Button>
            </div>
          </div>
        </div>
      )}
      {task.name && !isFeedbackRequestTask && (
        <h4 className={styles.taskName}>{task.name}</h4>
      )}
      {isFeedbackRequestTask && <RequestFeedback task={task} />}
      {isLessonLearnedTask && <LessonLearned task={task} />}
      {task.description && (
        <div
          dangerouslySetInnerHTML={{ __html: task.description || "" }}
          className="mb-2 d-block ml-3"
        ></div>
      )}
      <div className={classNames(styles.line, styles.first)}>
        <label className={styles.label}>Task Stage</label>
        <div className={styles.values}>
          {task?.stage_id && <State stageId={task?.stage_id} />}
        </div>
      </div>
      <div className={styles.line}>
        <label className={styles.label}>Assigned to</label>
        <div
          className={classNames(styles.values, styles.assign)}
          onClick={() =>
            !blockFordOnly &&
            !checkStuffIsBlocked &&
            !loadingTask &&
            handleAssignUserClick()
          }
        >
          {(assignmentObj?.assigned_to || model?.assigned_to) && (
            <UserInline
              userId={model?.assigned_to?.id}
              avatar={model?.assigned_to?.avatar}
              noNavigation
            />
          )}
          {!blockFordOnly && (
            <Icon
              {...mdSize}
              icon="icn-right-chevron"
              className={styles.icon}
            />
          )}
        </div>
      </div>
      <div className={styles.line}>
        <label className={styles.label}>Due Date</label>
        <div className={classNames(styles.values, styles.duedate)}>
          {!isDateEditMode && (
            <DatePiker
              value={model.due_date}
              onChange={handleDueDateChange}
              className={styles.duedateInner}
              disabled={checkStuffIsBlocked}
            />
          )}
          <div
            onClick={() => handleDueDateChange(null)}
            className="d-flex justify-content-center"
          >
            {!checkStuffIsBlocked && (
              <Icon
                {...mdSize}
                icon="icn-add"
                className={`${styles.icon} ${styles.rotatedIcon}`}
              />
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.line} flex-column`}>
        <label className={styles.label}>Documents</label>
        <div className={styles.documentWrp}>
          <DocumentListComponent
            assignmentId={assignmentObj?.id}
            documentList={assignmentDocuments}
            setLoadingDocuments={setLoadingDocuments}
            blockFunctionality={checkStuffIsBlocked}
            taskId={task?.id}
            isProjectWideFromStage={isProjectWideFromStage}
            handleDocDelete={handleDocDelete}
          />
          {task.council_task_template &&
            Boolean(task.council_task_template.documents.length) &&
            task.council_task_template.documents.map((document) => (
              <div
                key={document.id}
                className={`${styles.documentLine} ${styles.values} ${
                  document.council_task_template !== null && styles.templateBk
                }`}
              >
                <div className="d-flex flex-column flex-grow-1">
                  <span className={styles.docName}>
                    {truncateString(
                      document?.name || getDocNameFromUri(document.uri),
                      30
                    )}
                  </span>
                  <UploadedByInfo document={document} />
                </div>
                <span className={styles.templateText}>Template</span>
                <div className={styles.btnWrp}>
                  <a
                    href={document.uri}
                    target="_blank"
                    download
                    rel="noreferrer"
                  >
                    <Icon
                      {...smSize}
                      icon="icn-export-button"
                      className={styles.download}
                    />
                  </a>
                  <a
                    className={styles.docView}
                    target="blank"
                    href={document.uri}
                  >
                    view
                  </a>
                </div>
              </div>
            ))}
          <div className={styles.documentAdd}>
            {!checkStuffIsBlocked && (
              <FilePicker
                // extensions={['pdf']}
                maxSize={500}
                onChange={(FileObject) => {
                  handleAddDocumentClick(FileObject);
                }}
                onError={(errMsg) => {
                  enqueueSnackbar(
                    "We could not upload your document because your file size is too big. Please make sure the file is less than 500 MB",
                    {
                      variant: "error",
                    }
                  );
                }}
              >
                {loadingDocuments ? (
                  <>
                    <Loading />
                    <LinearProgressWithLabel
                      value={uploadValues[0]?.progress || 0}
                    />{" "}
                  </>
                ) : (
                  <Button btn={BtnType.FRAME_LESS}>Add Document</Button>
                )}
              </FilePicker>
            )}
            {checkStuffIsBlocked && (
              <Tooltip
                title="You have View-Only access. To edit this Project, please ask your Admin to upgrade your account to Standard access and invite you to this Project."
                placement="top"
              >
                <div>
                  <Button btn={BtnType.DISABLED}>Add Document</Button>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {taskFieldsGroup && (
        <div className={styles.line}>
          <TaskGroupFields
            group={taskFieldsGroup}
            task={task}
            selectedProject={selectedProject}
            isProjectWideFromStage={isProjectWideFromStage}
            getUpdatedGroupData={getUpdatedGroupData}
            blockFunctionality={checkStuffIsBlocked}
          />
        </div>
      )}
      {task.frontend_url && (
        <div className={styles.line}>
          <label className={styles.label}>Share</label>
          <div className={classNames(styles.values, styles.link)}>
            <div>{task.frontend_url.slice(0, 35) + "..."}</div>
            <Button
              btn={BtnType.FRAME_LESS}
              onClick={() => copyTextToClipboard(task.frontend_url)}
            >
              Copy
            </Button>
          </div>
        </div>
      )}
      <div className={classNames(styles.line, styles.notesWrp)}>
        <label className={styles.label}>Notes</label>
        <div className={styles.notesCon}>
          {taskNotes &&
            taskNotes.length > 0 &&
            taskNotes.map((note) => (
              <Note
                key={note.id}
                note={note}
                handleMoreOptionClick={(val) =>
                  handleMoreOptionClick(val, note)
                }
              />
            ))}
        </div>
      </div>
      <div className={styles.addNoteWrp}>
        <AddNotesComponent
          assignmentId={assignmentObj?.id}
          selectedNote={selectedNote}
        />
      </div>
    </div>
  );
};

export default React.memo(TaskInfoComponent);

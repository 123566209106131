import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import Select from "react-dropdown-select";
import DropDownNestedElements from "common/components/dropdownNestedElements";

import {
  BtnType,
  Button,
  Label,
  Loading,
  TextBox,
  MultiSelect,
} from "../../../../primitives";
import { ModalFooter } from "../../../../../application/modal";
import styles from "./areasOfFocus.module.scss";
import { formInitialValues } from "./constants";
import {
  companyGetFilters,
  companyGetTopics,
} from "modules/companies/companyMain/companyMain.action";
import { flat } from "../../../../../common/helper";
import { editIdeaAttempt } from "../../../idea.action";
import { useModelPopup } from "../../../../../common/hooks";

const EditIdeaAreaOfFocus = ({ selectedIdea }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: { topics, industries },
    },
  } = useSelector((state) => state);
  const [formValues, setFormValues] = useState(null);
  const [hasBUField, setHasBUFields] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  useEffect(() => {
    if (selectedCouncil && !topics?.length && !industries?.length) {
      dispatch(
        companyGetFilters({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );

      dispatch(
        companyGetTopics({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil, topics, industries]);

  useEffect(() => {
    if (selectedIdea && !formValues) {
      const businessUnitObj = selectedIdea.ideas_descriptions.find(
        (t) =>
          t.category_type === "area_of_focus" &&
          t.section_name === "Relevant Business Unit"
      );
      const dataForOtherCouncils = selectedIdea.ideas_descriptions
        .filter(
          (t) =>
            t.category_type === "area_of_focus" &&
            t.section_name !== "Relevant Business Unit"
        )
        .reduce(
          (acc, elem, idx) => ({
            ...acc,
            [`area_of_focus-${idx}`]:
              elem.section_type === "text"
                ? elem.section_value
                : elem.section_values || [],
          }),
          {}
        );

      setHasBUFields(!!businessUnitObj);

      const newFormValues = {
        relevantBusinessUnit: businessUnitObj?.section_value || "",
        resource_topics: selectedIdea.resource_topics?.map((t) => t.topic_id),
        resource_industries: selectedIdea.resource_industries?.map(
          (t) => t.industry_id
        ),
        ...dataForOtherCouncils,
      };
      setSelectedIndustries(
        selectedIdea.resource_industries?.map((t) => t.industry_id)
      );
      setSelectedTopics(selectedIdea.resource_topics?.map((t) => t.topic_id));
      setFormValues(newFormValues);
    }

    return () => null;
  }, [selectedIdea, formValues]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleAreasOfFocusSave = (values) => {
    const otherCategories = selectedIdea.ideas_descriptions
      ? selectedIdea.ideas_descriptions
          .filter(
            (t) =>
              t.category_type === "area_of_focus" &&
              t.section_name !== "Relevant Business Unit"
          )
          .map((t, idx) => {
            if (t.section_type === "text") {
              return {
                ...t,
                section_value: values[`area_of_focus-${idx}`],
              };
            }
            return {
              ...t,
              section_values: values[`area_of_focus-${idx}`],
            };
          })
      : [];
    const relevantBUCategory = selectedIdea.ideas_descriptions
      ? selectedIdea.ideas_descriptions
          .filter(
            (t) =>
              t.category_type === "area_of_focus" &&
              t.section_name === "Relevant Business Unit"
          )
          .map((t) => ({
            ...t,
            section_value: values.relevantBusinessUnit,
          }))
      : [];
    const mainCategories = selectedIdea.ideas_descriptions
      ? selectedIdea.ideas_descriptions.filter(
          (t) =>
            t.category_type !== "area_of_focus" &&
            t.section_name !== "Relevant Business Unit"
        )
      : [];

    const payload = {
      idea: {
        id: selectedIdea.id,
        ideas_template_id: selectedIdea.ideas_template_id,
        resource_topics: technologiesOption.filter((t) =>
          selectedTopics.includes(t.id)
        ),
        resource_industries: industriesOption.filter((t) =>
          selectedIndustries.includes(t.id)
        ),
        ideas_descriptions: [
          ...otherCategories,
          ...relevantBUCategory,
          ...mainCategories,
        ],
      },
      cb: () => {
        enqueueSnackbar("Successfully edited areas of focus", {
          variant: "success",
        });
        setShowLoading(false);

        popup.hide();
      },
      enqueueSnackbar,
    };

    setShowLoading(true);

    dispatch(editIdeaAttempt(payload));
  };
  const technologiesOption = useMemo(
    () => flat(topics, "children_topics"),
    [topics]
  );
  const industriesOption = useMemo(
    () => flat(industries, "children_industries"),
    [industries]
  );

  const handleTopicSelect = (value, prop) => {
    setFormValues({
      ...formValues,
      [prop]: value.map((t) => t.id),
    });
  };

  const handleTagSelect = (topicsId) => {
    setSelectedTopics([...selectedTopics, topicsId]);
  };

  const handleIndustrySelect = (val) => {
    setSelectedIndustries((prev) => [...prev, val]);
  };

  const handleTagRemove = (updatedArr, childKey) => {
    if (childKey.includes("topic")) {
      setSelectedTopics(updatedArr.map((el) => el.id));
    }
    if (childKey.includes("industri")) {
      setSelectedIndustries(updatedArr.map((el) => el.id));
    }
  };
  return (
    <div>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={formValues || formInitialValues}
        onSubmit={handleAreasOfFocusSave}
      >
        {({ values, handleSubmit, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.customPadding}>
              <div className="mt-3">
                {!topics?.length && !industries?.length ? (
                  <Loading customText="Preparing areas of focus form..." />
                ) : (
                  <>
                    {hasBUField ? (
                      <>
                        <Label>Relevant Business Unit</Label>
                        <TextBox
                          disabled={showLoading}
                          type="text"
                          name="relevantBusinessUnit"
                          placeholder="Relevant Business Unit"
                          formProps={{
                            ...formikprops,
                            values,
                          }}
                          className="w-100"
                        />
                      </>
                    ) : null}
                    <div className={styles.otherCategories}>
                      {selectedIdea.ideas_descriptions
                        .filter(
                          (t) =>
                            t.category_type === "area_of_focus" &&
                            t.section_name !== "Relevant Business Unit"
                        )
                        .map((t, idx) => {
                          const options = t.section_option_values
                            ? t.section_option_values.map((e) => ({
                                name: e,
                                id: e,
                                section_name: t.section_name,
                                idx,
                              }))
                            : [];

                          if (t.multiselect) {
                            return (
                              <div key={t.id}>
                                <label htmlFor={`area_of_focus-${idx}`}>
                                  {t.section_name}
                                </label>
                                <MultiSelect
                                  disabled={showLoading}
                                  name={`area_of_focus-${idx}`}
                                  placeholder="Select"
                                  labelField="name"
                                  valueField="id"
                                  searchBy="name"
                                  options={options}
                                  formProps={{
                                    ...formikprops,
                                    values,
                                  }}
                                  onChange={(value) =>
                                    handleTopicSelect(
                                      value,
                                      `area_of_focus-${idx}`
                                    )
                                  }
                                />
                              </div>
                            );
                          }

                          if (t.section_type === "text") {
                            return (
                              <div key={t.id}>
                                <Label>{t.section_name}</Label>
                                <TextBox
                                  disabled={showLoading}
                                  type="text"
                                  name={`area_of_focus-${idx}`}
                                  placeholder={t.section_name}
                                  formProps={{
                                    ...formikprops,
                                    values,
                                  }}
                                  className="w-100"
                                />
                              </div>
                            );
                          }

                          return (
                            <div key={t.id}>
                              <label htmlFor={`area_of_focus-${idx}`}>
                                {t.section_name}
                              </label>
                              <Select
                                disabled={showLoading}
                                name={`area_of_focus-${idx}`}
                                options={options}
                                labelField="name"
                                valueField="name"
                                onChange={(value) =>
                                  handleTopicSelect(
                                    value,
                                    `area_of_focus-${idx}`
                                  )
                                }
                                values={
                                  values[`area_of_focus-${idx}`]
                                    ? values[`area_of_focus-${idx}`].map(
                                        (e) => ({
                                          name: e,
                                          id: e,
                                        })
                                      )
                                    : []
                                }
                              />
                            </div>
                          );
                        })}
                    </div>
                    <label htmlFor="resource_topics">Technologies</label>
                    <DropDownNestedElements
                      tags={topics}
                      selectedTags={selectedTopics}
                      childKey="children_topics"
                      tagKeyName="id"
                      parentKeyName="parent_resource_topic_id"
                      handleTagSelect={handleTagSelect}
                      label="Select technologies"
                      handleTagRemove={handleTagRemove}
                      disabled={showLoading}
                    />
                    {selectedCouncil?.name !== "Ford" && (
                      <div className={styles.nestedSelect}>
                        <label htmlFor="resource_industries">
                          {selectedCouncil?.name === "Freddie Mac"
                            ? "Housing Cycle"
                            : "Industries"}
                        </label>

                        <DropDownNestedElements
                          disabled={showLoading}
                          tags={industries}
                          selectedTags={selectedIndustries}
                          childKey="children_industries"
                          tagKeyName="id"
                          parentKeyName="parent_resource_industry_id"
                          handleTagSelect={handleIndustrySelect}
                          label="Select industries"
                          handleTagRemove={handleTagRemove}
                        />
                      </div>
                    )}
                  </>
                )}
                {showLoading && <Loading customText="Updating your form..." />}
              </div>
            </div>
            <ModalFooter>
              <Button
                className="mr-3"
                btn={BtnType.FRAME_LESS}
                onClick={handleCancelClick}
                disabled={showLoading}
              >
                Cancel
              </Button>
              <Button
                disabled={showLoading}
                type="submit"
                onClick={handleSubmit}
                btn={BtnType.REGULAR}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default memo(EditIdeaAreaOfFocus);

import * as Yup from "yup";

const initModel = {
  position: 1,
  projectField: null,
};

const validation = Yup.object().shape({
  position: Yup.number().required("Position is required"),
});

export { validation, initModel };

import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import {
  BtnType,
  Button,
  CheckBox,
  CheckType,
  Pannel,
} from "../../../../primitives";
import { useModelPopup } from "../../../../../common/hooks";
import NewRelatedProjects from "./addRelatedProjects";
import { httpDelete, httpGet } from "../../../../../common/httpCall";
import LessonLearnedModal from "modules/projects/project/overview/relatedProjects/lessonLearnedModal.component";

const RelatedProjects = ({ selectedProject, expandAll }) => {
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [relatedProjects, setRelatedProjects] = useState(null);
  const [activeProjectEdit, setActiveProjectEdit] = useState(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expandAll) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [expandAll]);
  useEffect(() => {
    if (selectedProject?.id) {
      httpGet({
        call: `projects/${selectedProject.id}/related_projects`,
      }).subscribe((res) => {
        if (res.response) {
          setRelatedProjects(res.response);
        }
      });
    }

    return () => {
      setRelatedProjects(null);
    };
  }, [selectedProject?.id]);

  const handleAddProjectsClick = () => {
    popup.show({
      title: "Add Related Projects",
      component: (
        <NewRelatedProjects
          selectedProject={selectedProject}
          relatedProjects={relatedProjects || []}
          handleAdd={setRelatedProjects}
        />
      ),
    });
  };

  const handleRemoveProject = (project_id) => {
    const newProjects = relatedProjects.filter((p) => p.id !== project_id);
    setRelatedProjects(newProjects);

    httpDelete({
      call: `projects/${selectedProject.id}/related_projects/${project_id}`,
    }).subscribe(() => {
      enqueueSnackbar(`Successfully deleted project!`, {
        variant: "success",
      });
    });
  };

  const handleEdit = (project) => {
    if (activeProjectEdit === project.id) {
      setActiveProjectEdit(null);
      return;
    }
    setActiveProjectEdit(project.id);
  };

  const handleSimilarChange = (newValue, project) => {
    const newProjectsArray = relatedProjects.map((p) => {
      if (p.id === project.id) {
        return {
          ...p,
          similar: newValue,
        };
      }

      return p;
    });

    setRelatedProjects(newProjectsArray);
  };

  const handleSave = () => {
    setActiveProjectEdit(null);
  };

  const handleShowProjectLesson = (project) => {
    popup.show({
      title: project.name,
      component: (
        <LessonLearnedModal
          lessonsData={project.lessons_learned_project_fields}
        />
      ),
    });
  };
  const rightContent = useCallback(() => {
    return (
      <>
        <div
          className="d-flex justify-content-center cursor-pointer "
          onClick={() => setExpanded((prev) => !prev)}
        >
          <Icon
            {...smSize}
            icon="icn-table-down-chevron"
            className={classNames(
              styles.icon,
              expanded ? styles.collapse : styles.collapseUp
            )}
          />
        </div>
      </>
    );
  }, [expanded]);

  return (
    <Pannel
      title="Related Projects"
      padding="0"
      rightContent={rightContent()}
      parentStyle={{
        minWidth: "230px",
      }}
      withCollapse
      expanded={expanded}
    >
      <div className={styles.panelHeader}>
        Projects
        <Icon
          {...mdSize}
          icon="icn-add"
          className={styles.addNew}
          onClick={handleAddProjectsClick}
        />
      </div>

      <div className={styles.wrapper}>
        {relatedProjects?.length ? (
          relatedProjects.map((p) => (
            <div className={styles.ideaContainer} key={`${p.id}`}>
              <div className={`${styles.title} d-flex flex-column`}>
                <Link to={`/projects/${p?.id}/overview`}>{p.name}</Link>
                {p.similar && (
                  <span className="small muted">Similar Project</span>
                )}
                {selectedCouncil?.name === "Ford" &&
                p.lessons_learned_project_fields?.length ? (
                  <div
                    className="cursor-pointer small text-info"
                    onClick={() => handleShowProjectLesson(p)}
                  >
                    Lessons Learned √
                  </div>
                ) : null}
                {p.id === activeProjectEdit && (
                  <div>
                    <label className="d-flex align-items-center">
                      <CheckBox
                        checkType={CheckType.BLUE}
                        name="isSimilarProject"
                        onChange={(val) => handleSimilarChange(val, p)}
                        isChecked={p.similar}
                      />
                      <span>Similar Project</span>
                    </label>
                    <div className="mt-2">
                      <Button
                        btn={BtnType.FRAME_LESS}
                        onClick={() => handleSave(p)}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div>
                {selectedCouncil?.name === "Ford" && (
                  <Icon
                    {...smSize}
                    icon="icn-edit"
                    className={styles.deleteIcon}
                    onClick={() => handleEdit(p)}
                  />
                )}
                <Icon
                  {...smSize}
                  icon="icn-button-delete"
                  className={styles.deleteIcon}
                  onClick={() => handleRemoveProject(p.id)}
                />
              </div>
            </div>
          ))
        ) : (
          <span className="p-3 text-center">No related projects yet.</span>
        )}
      </div>
    </Pannel>
  );
};

export default React.memo(RelatedProjects);

import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import DialogContent from "@mui/material/DialogContent";

import styles from './tractionAI.module.scss';
import { AI_MAIN_OPTIONS } from "./constants";
import { AISelectScreen } from "modules/tractionAI/store/tractionAI.actions";
import TractionAIScout from "modules/tractionAI/screens/scout";
import TractionAIInsights from "modules/tractionAI/screens/insights";
import TractionAIRecommend from "modules/tractionAI/screens/recommend";

const TractionAI = ({ selectedCouncil }) => {
  const dispatch = useDispatch();

  const { tractionAiReducer: { activeScreen }} = useSelector((state) => state);

  const handleOptionSelect = option => {
    if (option.id === 'recommend' && !selectedCouncil?.settings?.ideas_connected) {
      // if ideas is disconnected by default go to projects
      dispatch(AISelectScreen({
        activeScreen: option.id,
        activeSubScreen: 'projects'
      }))

      return;
    }

    dispatch(AISelectScreen({
      activeScreen: option.id
    }))
  };

  const getOptions = useMemo(() => {
    return AI_MAIN_OPTIONS.filter(el => selectedCouncil?.settings[el.settingsKey])
  }, [selectedCouncil?.settings]);

  return (
    <DialogContent>
      {
        !activeScreen && (
          <>
            <h4 className="mb-5">How can I help you?</h4>
            <div className="d-flex flex-row align-items-start mb-3">
              {
                getOptions.length === 0 ? (
                  <h5>Looks like all AI options are disabled, please contact Traction to enable AI.</h5>
                ) : null
              }
              {
                getOptions.map(option => (
                  <div
                    key={option.name}
                    className={styles.recommendationItem}
                    onClick={() => handleOptionSelect(option)}
                    dangerouslySetInnerHTML={{ __html: option.name }}
                  />
                ))
              }
            </div>
          </>
        )
      }
      {
        activeScreen && (
          <>
            {activeScreen === "scout" && (
              <TractionAIScout />
            )}
            {activeScreen === 'insight' && (
              <TractionAIInsights />
            )}
            {activeScreen === 'recommend' && (
              <TractionAIRecommend />
            )}
          </>
        )
      }
      <div id="bottomAI" />
    </DialogContent>
  );
};

export default React.memo(TractionAI);

import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import ReactTable from "react-table";
import {
  Loading,
  MoreOptions,
  OptionType,
  Button,
  BtnType,
} from "modules/primitives/index";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import downloadTable from "common/components/downloadReport/generateDocHelper";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import EditMemberComponent from "./editMember/editMember.component";

import styles from "./adminMembers.module.scss";
// import InviteMemberComponent from "../../../common/components/inviteMember";
import "react-table/react-table.css";
import { peopleListTeamsGet } from "../../../common/actions/common.action";
import { useModelPopup } from "../../../common/hooks";
import ConfirmDeleteComponent from "../../../common/components/confirmDelete";
import MemberNewComponent from "../../people/internalPeople/memberNew";
import { getCompanyInvitedContactsAttempt } from "./adminMembers.action";

const AdminMembersInvitedComponent = () => {
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [exportLoading, setExportLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const {
    councilReducer: { selectedCouncil, invitingMember },
    companiesReducer: {
      companyMainReducer: { searchResults, searchUsersResults },
    },
    adminMembersReducer: {
      companyInvitedContacts,
      loading,
      companyInvitedContactsNr,
      shouldUpdate,
    },
  } = useSelector((state) => state);

  const pageSizeOptions = [pageSize, 5, 10, 20, 50, 100];
  useEffect(() => {
    dispatch(
      getCompanyInvitedContactsAttempt({
        enqueueSnackbar,
        page,
        pageSize,
        query: "*",
      })
    );
  }, [selectedCouncil, page]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (shouldUpdate) {
      dispatch(
        getCompanyInvitedContactsAttempt({
          enqueueSnackbar,
          page,
          pageSize,
          query: "*",
        })
      );
    }
  }, [shouldUpdate]);

  const handleAddNewMember = () => {
    popup.show({
      title: "Add Contact",
      show: true,
      height: "300",
      width: "540",
      component: (
        <MemberNewComponent
          preselectedAccessType={{ val: 4, text: "Company" }}
          invitedContact
        />
      ),
    });
  };

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
  };

  const handlePageSelect = (e) => {
    setPage(e.target.value);
  };

  const handleNextClick = () => {
    setPage(page + 1);
  };

  const handlePrevClick = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const moreOptions = [
    { val: "edit", text: "Edit" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const handleMoreOptionClick = (event, user) => {
    if (event === "edit") {
      // history.push(`/admin/members/edit/${user_id}`, {
      //   fromContacts: true,
      //   contactId: user.id,
      //   user,
      // });
      popup.show({
        title: "Edit Member",
        show: true,
        height: "400",
        width: "540",
        component: (
          <EditMemberComponent user={user} userId={user.user_id || user.id} />
        ),
      });
    }
    if (event === "delete") {
      popup.show({
        title: "Confirmation Prompt",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={user.id}
            enqueueSnackbar={enqueueSnackbar}
            actionType="deleteContact"
            memberName={user.first_name + " " + user.last_name}
          />
        ),
      });
    }
  };

  const DisabledButton = ({ showTooltip, setShowTooltip }) => (
    <>
      {showTooltip && (
        <div className={styles.exportModalTooltip}>
          <p>
            {searchUsersResults?.length
              ? "Search cannot be exported "
              : "To be able to export list it must contain at least one element."}
          </p>
        </div>
      )}
      <Button
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
        className={styles.deleteSubmitButton}
        btn={BtnType.DISABLED}
      >
        EXPORT
      </Button>
    </>
  );

  const isNextDisabled = () => {
    if (page + 1 > companyInvitedContactsNr?.pages) {
      return styles.disabledButton;
    }
    return styles.paginationButton;
  };

  const download = (type) => {
    downloadTable(
      type,
      {},
      [],
      handleClose,
      "admin_contacts",
      {
        search: "",
        // search: {
        //   sort_attribute: "name",
        //   sort_order: "asc",
        //   query: "a",
        //   search: "a",
        // },
      },
      setExportLoading,
      `&search=${searchQuery || "*"}`
    );
  };

  const handleExportList = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        peopleListTeamsGet({ councilId: selectedCouncil.id, enqueueSnackbar })
      );
    }
    return () => null;
  }, [selectedCouncil]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "full_name",
        maxWidth: 400,
        minWidth: 140,
        width: 200,
      },
      {
        Header: "Email",
        accessor: "email",
        maxWidth: 220,
        minWidth: 140,
        width: 200,
      },
      {
        Header: "Title",
        accessor: "title",
        maxWidth: 220,
        minWidth: 140,
        width: 220,
      },
      {
        Header: "Company",
        id: "organization_name",
        accessor: (row) => (
          <span
            onClick={() => {
              history.push(`/companies/${row?.slug || row?.company_id}`);
            }}
          >
            {row?.organization_name}
          </span>
        ),
        maxWidth: 400,
        minWidth: 140,
        width: 320,
        style: {
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      {
        Header: "Claimed Profile",
        id: "claimed_profile",
        accessor: (row) => <span>{row?.claimed_profile ? "Yes" : "No"}</span>,
        maxWidth: 120,
        minWidth: 140,
        width: 120,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Access Level",
        id: "company",
        accessor: (row) => {
          if (row.access_role) {
            if (row.access_role === "ttp_administrator") {
              return "TTP Administrator";
            }
            if (row.access_role === "council_administrator") {
              return "Administrator";
            }
            if (row.access_role === "standard") {
              return "Standard";
            }
            if (row.access_role === "viewer") {
              return "View-Only";
            }
            return "Company";
          }
          return "";
        },
        style: {
          display: "flex",
          justifyContent: "center",
        },
        maxWidth: 200,
        minWidth: 120,
        width: 120,
      },
      {
        Header: "Options",
        id: "options",
        sortable: false,
        accessor: (row) => (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row);
            }}
          />
        ),
        width: 100,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [companyInvitedContacts]
  );

  const handleSearch = (text) => {
    if (text) {
      dispatch(
        getCompanyInvitedContactsAttempt({
          enqueueSnackbar,
          query: text,
        })
      );
    } else {
      dispatch(getCompanyInvitedContactsAttempt({ enqueueSnackbar }));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <span
            className={styles.rootText}
            onClick={() => {
              history.push("/admin");
            }}
          >
            ADMIN
          </span>
          <span className={styles.separationBar}> / </span>
          <span className={styles.rootText}>
            <Link to="/admin/members">MEMBERS</Link>
          </span>
          <span className={styles.separationBar}> / </span>
          <span className={`${styles.rootText} ${styles.activeBreadcrumbs}`}>
            INVITED CONTACTS
          </span>
        </h4>
      </div>

      <div className={styles.bodyWrp}>
        <div>
          {/* <Search className={styles.search} mode='councils/users' /> */}
          <div className={styles.searchWrapper}>
            <input
              onChange={(e) => {
                setSearchQuery(e.target.value);
                handleSearch(e.target.value);
              }}
              placeholder="Search"
              value={searchQuery}
              className={styles.searchInput}
            />
          </div>
          <div className={styles.buttonWrp}>
            <div>
              <Button
                className={styles.addNew}
                disabled={invitingMember}
                btn={invitingMember ? BtnType.OUTLINE : BtnType.REGULAR}
                icon="icn-add"
                onClick={handleAddNewMember}
              >
                Add Contact
              </Button>
            </div>
            {exportLoading ? (
              <Loading />
            ) : companyInvitedContactsNr?.total > 0 &&
              !searchUsersResults?.length ? (
              <div>
                <Button
                  // disabled={!listTotalCount}
                  onClick={handleExportList}
                  btn={BtnType.OUTLINE}
                >
                  EXPORT
                </Button>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem onClick={() => download("csv")}>
                    DOWNLOAD CSV
                  </MenuItem>
                  <MenuItem onClick={() => download("xlsx")}>
                    DOWNLOAD EXCEL
                  </MenuItem>
                  {/* <MenuItem onClick={() => download("pdf")}>
                    DOWNLOAD PDF
                  </MenuItem> */}
                </Menu>
              </div>
            ) : (
              <DisabledButton
                setShowTooltip={setShowTooltip}
                showTooltip={showTooltip}
              />
            )}
            <div className={styles.totalCount}>
              {companyInvitedContactsNr?.total || "0"} Contacts
            </div>
          </div>
          <div>
            {" "}
            <ReactTable
              data={companyInvitedContacts}
              columns={columns}
              loading={loading ? <Loading /> : null}
              pageSize={pageSize}
              minRows={5}
              className={styles.table}
              showPagination={false}
            />
            <div className={styles.pagination}>
              <div
                className={
                  page - 1 === 0
                    ? styles.disabledButton
                    : styles.paginationButton
                }
                onClick={handlePrevClick}
                disabled={page - 1 === 0}
              >
                Previous
              </div>
              <div className={styles.paginationSettings}>
                <span style={styles.pageNr}>
                  <span>
                    <input
                      onChange={(e) => handlePageSelect(e)}
                      value={page}
                      className={styles.inputBox}
                      type="number"
                      min="1"
                    />
                  </span>
                  <span>of</span>
                  <span> {companyInvitedContactsNr?.pages}</span>
                </span>

                <span>
                  <select onChange={(e) => handleChangePageSize(e)}>
                    {pageSizeOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
              <button
                className={isNextDisabled()}
                onClick={handleNextClick}
                disabled={page + 1 > companyInvitedContactsNr?.pages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminMembersInvitedComponent;

import { ajax } from "rxjs/ajax";
import { empty, of, throwError } from "rxjs";
import { getCookie } from "common/helper";
import { catchError, map } from "rxjs/operators";
import { dashboardServerPath, serverPath } from "./constants";

const httpGet = ({ apiVersion = "v1", call, data, customServerPath, cached }) =>
  httpCall("GET", apiVersion, call, data, "json", customServerPath, cached);

const httpDelete = ({ apiVersion = "v1", call, data, customServerPath }) =>
  httpCall("DELETE", apiVersion, call, data, "json", customServerPath);

const httpPost = ({
  apiVersion = "v1",
  call,
  data,
  respType,
  customServerPath,
  cached,
}) => httpCall("POST", apiVersion, call, data, respType, customServerPath, cached);

const httpPut = ({ apiVersion = "v1", call, data, customServerPath }) =>
  httpCall("PUT", apiVersion, call, data, "json", customServerPath);

const httpPatch = ({ apiVersion = "v1", call, data, customServerPath }) =>
  httpCall("PATCH", apiVersion, call, data, "json", customServerPath);

const cache = new Map();
const CACHE_TTL = 300000;

const httpCall = (
  type,
  apiVersion,
  call,
  data = null,
  respType = "json",
  customServerPath,
  cached,
) => {
  const path = customServerPath ? dashboardServerPath : serverPath;
  const requestUrl = `${path}/api/${apiVersion}/${call}`;

  const cacheKey = type.toUpperCase() === "POST"
    ? `${requestUrl}:${JSON.stringify(data)}`
    : requestUrl;

  const isGetOrPostRequest = type.toUpperCase() === "GET" || type.toUpperCase() === "POST";

  if (isGetOrPostRequest && cache.has(cacheKey) && cached) {
    const dateNow = Date.now();
    const cachedData = cache.get(cacheKey);
    const isCacheValid = dateNow - cachedData.timestamp < CACHE_TTL;

    if (isCacheValid) {
      console.log(`Serving from cache, at time ${dateNow} : ${cacheKey}`);
      return of(cachedData.response);
    } else {
      cache.delete(cacheKey);
    }
  }

  const headers = {
    "Content-type": "application/json",
    "X-requested-with": "XMLHttpRequest",
    "X-XSS-Protection": "1; mode=block",
    "Content-Security-Policy": "default-src self",
    "X-Frame-Options": "SAMEORIGIN",
    "X-Content-Type-Options": "nosniff",
    "Referrer-Policy": "no-referrer",
    "Access-Control-Allow-Origin": path,
    "Access-Control-Allow-Credentials": "true",
  };

  const UID = getCookie("uid");
  const accessToken = getCookie("access_token");
  const client = getCookie("client");
  const user_id = getCookie("user_id_");
  const councilId = getCookie("council_id_");

  const selectedCouncil = localStorage.getItem("council");

  if (UID) {
    const council = selectedCouncil ? JSON.parse(selectedCouncil) : null;

    headers["Access-Token"] = accessToken;
    headers["Client"] = client;
    headers["UID"] = UID;
    headers["user_id"] = user_id;
    headers["COUNCIL_ID"] = council ? council?.id : councilId;
  }

  return ajax({
    url: requestUrl,
    crossDomain: true,
    withCredentials: true,
    method: type,
    responseType: respType,
    timeout: 0,
    headers,
    body: data ? JSON.stringify(data) : null,
    createXHR: () => new XMLHttpRequest(),
  }).pipe(
    map((userResponse) => {
      if (isGetOrPostRequest && cached) {
        cache.set(cacheKey, {
          response: userResponse,
          timestamp: Date.now(),
        });
      }
      return userResponse;
    }),
    catchError((e) => {
      const errorDetails = {
        status: e?.status || 500,
        response: e?.response || { message: "Unknown error occurred" },
        ...e,
      };

      if (e.status === apiStatus.UNAUTHORIZED || e.status === 401) {
        localStorage.removeItem("session");
        localStorage.removeItem("council");
        localStorage.clear();
        if (window.location.pathname !== "/login") {
          window.location.replace(`${window.location.origin}/login`);
        }
      } else if (e.status === 403) {
        alert("You are not authorized to do this action");
        errorDetails.response.message = "Unauthorized action";
      }

      return throwError(errorDetails);
    })
  );
};

const apiStatus = {
  SUCCESS: 200,
  CREATED: 201,
  NOCONTENT: 204,
  MOVED_PERMANENTLY: 301,
  MISSING_VALUE: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
};

const queryString = (paramObj) => {
  let str = "";
  Object.keys(paramObj).forEach((key) => {
    if (paramObj[key]) {
      str +=
        str === "" ? `${key}=${paramObj[key]}` : `&${key}=${paramObj[key]}`;
    }
  });
  return str;
};

const pageMeta = (response) => {
  const total = parseInt(response.xhr.getResponseHeader("total-count"));
  const pageSize = parseInt(response.xhr.getResponseHeader("page-items"));
  const pages = parseInt(response.xhr.getResponseHeader("total-pages"));
  const page = parseInt(response.xhr.getResponseHeader("current-page"));

  return {
    total: isNaN(total) ? 0 : total,
    pageSize: isNaN(pageSize) ? 0 : pageSize,
    pages: isNaN(pages) ? 0 : pages,
    page: isNaN(page) ? 0 : page,
  };
};

const errorHandler = (
  err = {},
  enqueueSnackbar = () => {},
  customErrMessages = [],
  returnedAction
) => {
  console.log("error------------->", err);
  /*
  --> custom error messages should be in this model
  customErrMessages = [
    {
      message: | Error Message String |,
      type: | Status Number |
    }
  ]

  */
  let serverErrorMessage = "";

  if (err?.response?.message) {
    serverErrorMessage = JSON.stringify(err?.response?.message);
  } else if (err?.response?.error) {
    serverErrorMessage = err?.response?.error;
  } else if (err?.response?.errors?.messages?.email) {
    serverErrorMessage = err?.response?.errors?.messages?.email[0];
  } else if (err?.response?.errors?.messages) {
    serverErrorMessage = err?.response?.errors?.messages;
  } else if (err?.response?.errors?.length && err?.response?.errors[0]) {
    serverErrorMessage = err?.response?.errors[0];
  } else {
    serverErrorMessage = "";
  }

  switch (err.status) {
    case apiStatus.UNPROCESSABLE_ENTITY: {
      const foundCustomMessage = customErrMessages.find(
        (error) => error?.type === apiStatus.UNPROCESSABLE_ENTITY
      );
      if (enqueueSnackbar) {
        enqueueSnackbar(
          `${
            foundCustomMessage?.message
              ? foundCustomMessage.message
              : serverErrorMessage[0]?.text ||
              serverErrorMessage ||
              "Something went wrong"
          }`,
          {
            variant: "error",
          }
        );
      }

      break;
    }
    case apiStatus.NOT_FOUND: {
      const foundCustomMessage = customErrMessages.find(
        (error) => error?.type === apiStatus.NOT_FOUND
      );
      if (enqueueSnackbar) {
        enqueueSnackbar(
          `${
            foundCustomMessage?.message
              ? foundCustomMessage.message
              : serverErrorMessage || "Nothing was found"
          }`,
          {
            variant: "error",
          }
        );
      }

      break;
    }
    case apiStatus.UNAUTHORIZED: {
      const foundCustomMessage = customErrMessages.find(
        (error) => error?.type === apiStatus.UNAUTHORIZED
      );
      if (enqueueSnackbar) {
        enqueueSnackbar(
          `${
            foundCustomMessage?.message
              ? foundCustomMessage.message
              : serverErrorMessage || "Unauthorized"
          }`,
          {
            variant: "error",
          }
        );
      }

      break;
    }
    case apiStatus.CONFLICT: {
      const foundCustomMessage = customErrMessages.find(
        (error) => error?.type === apiStatus.CONFLICT
      );
      if (enqueueSnackbar) {
        enqueueSnackbar(
          `${
            foundCustomMessage?.message
              ? foundCustomMessage.message
              : serverErrorMessage || "Entry already exists"
          }`,
          {
            variant: "error",
          }
        );
      }

      break;
    }
    case apiStatus.INTERNAL_SERVER_ERROR: {
      const foundCustomMessage = customErrMessages.find(
        (error) => error?.type === apiStatus.INTERNAL_SERVER_ERROR
      );
      if (enqueueSnackbar) {
        enqueueSnackbar(
          `${
            foundCustomMessage?.message
              ? foundCustomMessage.message
              : serverErrorMessage || "Internal Server Error"
          }`,
          {
            variant: "error",
          }
        );
      }
      break;
    }
    case apiStatus.MISSING_VALUE: {
      const foundCustomMessage = customErrMessages.find(
        (error) => error?.type === apiStatus.MISSING_VALUE
      );
      if (enqueueSnackbar) {
        enqueueSnackbar(
          `${
            foundCustomMessage?.message
              ? foundCustomMessage.message
              : serverErrorMessage || "Something went wrong"
          }`,
          {
            variant: "error",
          }
        );
      }
      break;
    }
    default: {
      enqueueSnackbar(serverErrorMessage || "Something went wrong", {
        variant: "error",
      });
    }
  }

  /*
  if you need to initialize another action or change store state after an err
  you should return call of() with ActionCreator as argument,
  in other cases just call empty() with no args
  it will clear rxJS call stack without any throwing errors
  */

  if (returnedAction) {
    return of(returnedAction(err));
  }
  return empty();
};

export {
  httpPost,
  httpGet,
  httpPut,
  httpPatch,
  httpDelete,
  apiStatus,
  queryString,
  pageMeta,
  errorHandler,
};

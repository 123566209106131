import React, { useState } from "react";
import {
  Button,
  BtnType,
  pageSettings,
  Loading,
} from "../../modules/primitives";

const useScrollPagination = (props) => {
  const { pageSize = 10 } = props;
  const [pageSetting, setPageSetting] = useState(pageSettings(pageSize));

  const onScroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
      && pageSetting.pages !== pageSetting.current
    ) {
      setPageSetting((prev) => ({ ...prev, current: prev.current + 1 }));
    }
  };

  const loadMore = () => {
    setPageSetting((prev) => ({ ...prev, current: prev.current + 1 }));
  };

  const setSetting = (data) => {
    setPageSetting((prev) => ({ ...prev, ...data }));
  };

  const getLoading = (text) => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {pageSetting.current < 2 ? (
        <Button
          btn={BtnType.FRAME_LESS}
          // className={styles.SeeAllSpeakers}
          onClick={loadMore}
        >
          {text}
        </Button>
      ) : (
        pageSetting.pages !== pageSetting.current && <Loading />
      )}
    </div>
  );

  return {
    onScroll,
    loadMore,
    pageSetting,
    setSetting,
    getLoading,
  };
};
export default useScrollPagination;

export const TAG_TOPIC_GET_ATTEMPT = "TAG_TOPIC_GET_ATTEMPT";
export const tagTopicGetAttempt = (payload) => ({
  type: TAG_TOPIC_GET_ATTEMPT,
  payload,
});

export const TAG_TOPIC_GET_SUCCESS = "TAG_TOPIC_GET_SUCCESS";
export const tagTopicGetSuccess = (payload) => ({
  type: TAG_TOPIC_GET_SUCCESS,
  payload,
});

export const TAG_TOPIC_POST_ATTEMPT = "TAG_TOPIC_POST_ATTEMPT";
export const tagTopicPostAttempt = (payload) => ({
  type: TAG_TOPIC_POST_ATTEMPT,
  payload,
});

export const TAG_TOPIC_POST_SUCCESS = "TAG_TOPIC_POST_SUCCESS";
export const tagTopicPostSuccess = (payload) => ({
  type: TAG_TOPIC_POST_SUCCESS,
  payload,
});

export const TAG_TOPIC_DELETE_ATTEMPT = "TAG_TOPIC_DELETE_ATTEMPT";
export const tagTopicDeleteAttempt = (payload) => ({
  type: TAG_TOPIC_DELETE_ATTEMPT,
  payload,
});

export const TAG_TOPIC_DELETE_SUCCESS = "TAG_TOPIC_DELETE_SUCCESS";
export const tagTopicDeleteSuccess = (payload) => ({
  type: TAG_TOPIC_DELETE_SUCCESS,
  payload,
});

export const CATCH_ERROR_SUCCESS = "CATCH_ERROR_SUCCESS";
export const catchErrorSuccess = (payload) => ({
  type: CATCH_ERROR_SUCCESS,
  payload,
});

export const TAG_INDUSTRY_GET_ATTEMPT = "TAG_INDUSTRY_GET_ATTEMPT";
export const tagIndustryGetAttempt = (payload) => ({
  type: TAG_INDUSTRY_GET_ATTEMPT,
  payload,
});

export const TAG_INDUSTRY_GET_SUCCESS = "TAG_INDUSTRY_GET_SUCCESS";
export const tagIndustryGetSuccess = (payload) => ({
  type: TAG_INDUSTRY_GET_SUCCESS,
  payload,
});

export const TAG_TOPIC_PUT_ATTEMPT = "TAG_TOPIC_PUT_ATTEMPT";
export const tagTopicPutAttempt = (payload) => ({
  type: TAG_TOPIC_PUT_ATTEMPT,
  payload,
});

export const TAG_TOPIC_PUT_SUCCESS = "TAG_TOPIC_PUT_SUCCESS";
export const tagTopicPutSuccess = (payload) => ({
  type: TAG_TOPIC_PUT_SUCCESS,
  payload,
});

export const TAG_INDUSTRY_PUT_ATTEMPT = "TAG_INDUSTRY_PUT_ATTEMPT";
export const tagIndustryPutAttempt = (payload) => ({
  type: TAG_INDUSTRY_PUT_ATTEMPT,
  payload,
});

export const TAG_INDUSTRY_PUT_SUCCESS = "TAG_INDUSTRY_PUT_SUCCESS";
export const tagIndustryPutSuccess = (payload) => ({
  type: TAG_INDUSTRY_PUT_SUCCESS,
  payload,
});
//
export const TAG_INDUSTRY_POST_ATTEMPT = "TAG_INDUSTRY_POST_ATTEMPT";
export const tagIndustryPostAttempt = (payload) => ({
  type: TAG_INDUSTRY_POST_ATTEMPT,
  payload,
});

export const TAG_INDUSTRY_POST_SUCCESS = "TAG_INDUSTRY_POST_SUCCESS";
export const tagIndustryPostSuccess = (payload) => ({
  type: TAG_INDUSTRY_POST_SUCCESS,
  payload,
});

export const TAG_INDUSTRY_DELETE_ATTEMPT = "TAG_INDUSTRY_DELETE_ATTEMPT";
export const tagIndustryDeleteAttempt = (payload) => ({
  type: TAG_INDUSTRY_DELETE_ATTEMPT,
  payload,
});

export const TAG_INDUSTRY_DELETE_SUCCESS = "TAG_INDUSTRY_DELETE_SUCCESS";
export const tagIndustryDeleteSuccess = (payload) => ({
  type: TAG_INDUSTRY_DELETE_SUCCESS,
  payload,
});

export const TAG_RELATIONSHIP_GET_ATTEMPT = "TAG_RELATIONSHIP_GET_ATTEMPT";
export const tagRelationshipGetAttempt = (payload) => ({
  type: TAG_RELATIONSHIP_GET_ATTEMPT,
  payload,
});

export const TAG_RELATIONSHIP_GET_SUCCESS = "TAG_RELATIONSHIP_GET_SUCCESS";
export const tagRelationshipGetSuccess = (payload) => ({
  type: TAG_RELATIONSHIP_GET_SUCCESS,
  payload,
});

export const TAG_RELATIONSHIP_DELETE_ATTEMPT =
  "TAG_RELATIONSHIP_DELETE_ATTEMPT";
export const tagRelationshipDeleteAttempt = (payload) => ({
  type: TAG_RELATIONSHIP_DELETE_ATTEMPT,
  payload,
});

export const TAG_RELATIONSHIP_DELETE_SUCCESS =
  "TAG_RELATIONSHIP_DELETE_SUCCESS";
export const tagRelationshipDeleteSuccess = (payload) => ({
  type: TAG_RELATIONSHIP_DELETE_SUCCESS,
  payload,
});

export const TAG_RELATIONSHIP_ADD_ATTEMPT = "TAG_RELATIONSHIP_ADD_ATTEMPT";
export const tagRelationshipAddAttempt = (payload) => ({
  type: TAG_RELATIONSHIP_ADD_ATTEMPT,
  payload,
});

export const TAG_RELATIONSHIP_ADD_SUCCESS = "TAG_RELATIONSHIP_ADD_SUCCESS";
export const tagRelationshipAddSuccess = (payload) => ({
  type: TAG_RELATIONSHIP_ADD_SUCCESS,
  payload,
});

export const TAG_RELATIONSHIP_EDIT_ATTEMPT = "TAG_RELATIONSHIP_EDIT_ATTEMPT";
export const tagRelationshipEditAttempt = (payload) => ({
  type: TAG_RELATIONSHIP_EDIT_ATTEMPT,
  payload,
});

export const TAG_RELATIONSHIP_EDIT_SUCCESS = "TAG_RELATIONSHIP_EDIT_SUCCESS";
export const tagRelationshipEditSuccess = (payload) => ({
  type: TAG_RELATIONSHIP_EDIT_SUCCESS,
  payload,
});
// get group
export const TAG_CUSTOM_GROUP_GET_ATTEMPT = "TAG_CUSTOM_GROUP_GET_ATTEMPT";
export const tagCustomGetAttempt = (payload) => ({
  type: TAG_CUSTOM_GROUP_GET_ATTEMPT,
  payload,
});

export const TAG_CUSTOM_GROUP_GET_SUCCESS = "TAG_CUSTOM_GROUP_GET_SUCCESS";
export const tagCustomGetSuccess = (payload) => ({
  type: TAG_CUSTOM_GROUP_GET_SUCCESS,
  payload,
});
// post group

export const TAG_CUSTOM_GROUP_ADD_ATTEMPT = "TAG_CUSTOM_GROUP_ADD_ATTEMPT";
export const tagCustomGroupAttempt = (payload) => ({
  type: TAG_CUSTOM_GROUP_ADD_ATTEMPT,
  payload,
});

export const TAG_CUSTOM_GROUP_ADD_SUCCESS = "TAG_CUSTOM_GROUP_ADD_SUCCESS";
export const tagCustomGroupSuccess = (payload) => ({
  type: TAG_CUSTOM_GROUP_ADD_SUCCESS,
  payload,
});
// PUT group
export const TAG_CUSTOM_GROUP_PUT_ATTEMPT = "TAG_CUSTOM_GROUP_PUT_ATTEMPT";
export const tagCustomGroupEditAttempt = (payload) => {
  return {
    type: TAG_CUSTOM_GROUP_PUT_ATTEMPT,
    payload,
  };
};

export const TAG_CUSTOM_GROUP_PUT_SUCCESS = "TAG_CUSTOM_GROUP_PUT_SUCCESS";
export const tagCustomGroupEditSuccess = (payload) => ({
  type: TAG_CUSTOM_GROUP_PUT_SUCCESS,
  payload,
});
// delete custom group
export const TAG_CUSTOM_GROUP_DELETE_ATTEMPT =
  "TAG_CUSTOM_GROUP_DELETE_ATTEMPT";
export const tagCustomGroupDeleteAttempt = (payload) => ({
  type: TAG_CUSTOM_GROUP_DELETE_ATTEMPT,
  payload,
});

export const TAG_CUSTOM_GROUP_DELETE_SUCCESS =
  "TAG_CUSTOM_GROUP_DELETE_SUCCESS";
export const tagCustomGroupDeleteSuccess = (payload) => ({
  type: TAG_CUSTOM_GROUP_DELETE_SUCCESS,
  payload,
});

// POST TAG

export const TAG_CUSTOM_ADD_ATTEMPT = "TAG_CUSTOM_ADD_ATTEMPT";
export const tagCustomAttempt = (payload) => ({
  type: TAG_CUSTOM_ADD_ATTEMPT,
  payload,
});

export const TAG_CUSTOM_ADD_SUCCESS = "TAG_CUSTOM_ADD_SUCCESS";
export const tagCustomSuccess = (payload) => ({
  type: TAG_CUSTOM_ADD_SUCCESS,
  payload,
});

export const TAG_CUSTOM_PUT_ATTEMPT = "TAG_CUSTOM_PUT_ATTEMPT";
export const tagCustomPutAttempt = (payload) => ({
  type: TAG_CUSTOM_PUT_ATTEMPT,
  payload,
});

export const TAG_CUSTOM_PUT_SUCCESS = "TAG_CUSTOM_PUT_SUCCESS";
export const tagCustomPutSuccess = (payload) => ({
  type: TAG_CUSTOM_PUT_SUCCESS,
  payload,
});

export const TAG_CUSTOM_DELETE_ATTEMPT = "TAG_CUSTOM_DELETE_ATTEMPT";
export const tagCustomDeleteAttempt = (payload) => ({
  type: TAG_CUSTOM_DELETE_ATTEMPT,
  payload,
});

export const TAG_CUSTOM_DELETE_SUCCESS = "TAG_CUSTOM_DELETE_SUCCESS";
export const tagCustomDeleteSuccess = (payload) => ({
  type: TAG_CUSTOM_DELETE_SUCCESS,
  payload,
});

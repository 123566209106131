import React, { useCallback } from "react";

import { DatePiker, Label, MultiSelect, TextBox } from "modules/primitives";
import styles from "modules/projects/project/manageProject/details/details.module.scss";

const ProjectFields = ({ formikprops, fieldDetails, disabled, isAdminWizardInstance }) => {
  const renderField = useCallback(() => {
    if (!fieldDetails) return null;

    const useId = fieldDetails.project_field_template_id || fieldDetails.id;
    const newValues = {
      ...formikprops.values,
      project_fields: {
        ...(formikprops.values?.project_fields || {}),
        [useId]: formikprops.values?.project_fields?.[useId]
      }
    };

    switch (fieldDetails.field_render_type) {
      case 'date':
        return (
          <div>
            <Label>{fieldDetails.field_name} {isAdminWizardInstance &&
              <span className="small text-muted ml-2">(Project Field)</span>}</Label>
            <DatePiker
              value={newValues.project_fields[useId]}
              name={useId}
              formProps={{
                ...formikprops,
                values: newValues,
              }}
              onChange={(e) => {
                const updatedFields = {
                  ...newValues.project_fields,
                  [useId]: e
                };
                formikprops.setFieldValue('project_fields', updatedFields);
              }}
              className={styles.textBox}
              disabled={disabled}
              withClear={!isAdminWizardInstance}
            />
          </div>
        )

      case 'currency':
        return (
          <div>
            <Label>{fieldDetails.field_name} {isAdminWizardInstance &&
              <span className="small text-muted ml-2">(Project Field)</span>}</Label>
            <div className="d-flex align-items-center">
              <TextBox
                type="number"
                min="0"
                name={`project_fields.${useId}`}
                placeholder={fieldDetails.field_placeholder || fieldDetails.field_name}
                formProps={{
                  ...formikprops,
                  values: newValues,
                }}
                onChange={(evt) => {
                  const updatedFields = {
                    ...newValues.project_fields,
                    [useId]: evt.target.value,
                  };
                  formikprops.setFieldValue("project_fields", updatedFields);
                }}
                className="w-100"
                value={newValues.project_fields[useId]}
                disabled={disabled || isAdminWizardInstance}
              />
              <div className="d-flex ml-2">
                {
                  fieldDetails.option_values
                    ? fieldDetails.option_values[0]?.name || fieldDetails.option_values[0]
                    : null
                }
              </div>
            </div>
          </div>
        )

      case "dropdown":
        return (
          <div>
            <Label>{fieldDetails.field_name} {isAdminWizardInstance &&
              <span className="small text-muted ml-2">(Project Field)</span>}</Label>
            <MultiSelect
              name={useId}
              values={newValues.project_fields[useId] || []}
              labelField="name"
              valueField="id"
              onChange={(val) => {
                const updatedFields = {
                  ...newValues.project_fields,
                  [useId]: val,
                };
                formikprops.setFieldValue("project_fields", updatedFields);
              }}
              options={fieldDetails.option_values?.map(el => ({ id: el, name: el })) || []}
              multi={fieldDetails.multiselect}
              placeholder={fieldDetails.field_placeholder || fieldDetails.field_name}
              disabled={isAdminWizardInstance || disabled}
              dropdownPosition="top"
            />
            {
              newValues[useId] && fieldDetails.isClearable && (
                <div className="d-flex justify-content-end" style={{ marginTop: "-10px" }}>
                  <span
                    className="text-muted small cursor-pointer"
                    onClick={() => {
                      const updatedFields = {
                        ...newValues.project_fields,
                        [useId]: null,
                      };
                      formikprops.setFieldValue(useId, updatedFields);
                    }}
                  >clear</span>
                </div>
              )
            }
            {
              fieldDetails.oldSelectedValues?.length ? (
                <div style={{ top: "-20px" }} className="small text-muted position-relative">
                  <div>Saved option(s) no longer available: <span className="text-danger">
                    {fieldDetails.oldSelectedValues.map((el, index) => index < fieldDetails.oldSelectedValues.length - 1 ? `${el},\t` : el,)}
                  </span></div>
                </div>
              ) : null
            }
          </div>
        )

      case 'text':
      case 'number':
        return (
          <div>
            <Label>{fieldDetails.field_name} {isAdminWizardInstance &&
              <span className="small text-muted ml-2">(Project Field)</span>}</Label>
            {
              fieldDetails.big_box_text ? (
                <textarea
                  className="w-100 border small p-2"
                  value={newValues.project_fields[useId]}
                  placeholder={fieldDetails.field_placeholder || fieldDetails.field_name}
                  onChange={(evt) => {
                    const updatedFields = {
                      ...newValues.project_fields,
                      [useId]: evt.target.value
                    };
                    formikprops.setFieldValue('project_fields', updatedFields);
                  }}
                  rows="4"
                  disabled={isAdminWizardInstance || disabled}
                ></textarea>
              ) : (
                <TextBox
                  type={fieldDetails.field_render_type}
                  name={`project_fields.${useId}`}
                  placeholder={fieldDetails.field_placeholder || fieldDetails.field_name}
                  formProps={{
                    ...formikprops,
                    values: newValues
                  }}
                  onChange={(evt) => {
                    const updatedFields = {
                      ...newValues.project_fields,
                      [useId]: evt.target.value
                    };
                    formikprops.setFieldValue('project_fields', updatedFields);
                  }}
                  className="w-100"
                  value={newValues.project_fields[useId]}
                  disabled={disabled || isAdminWizardInstance}
                />
              )
            }
          </div>
        )

      default:
        return null;
    }
  }, [fieldDetails, isAdminWizardInstance, formikprops, disabled]);

  return (
    <div className="mb-2">
      {renderField()}
    </div>
  );
};

export default React.memo(ProjectFields);

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ModalFooter, ModalBody } from "../../../../../application/modal";
import { Button, BtnType, Label, DropSelect } from "../../../../primitives";
import styles from "./addProductToIdea.module.scss";
import useModelPopup from "common/hooks/modelPopup.hook";
import { ideasListGet } from "modules/ideasWrapper/idea.action";
import { addProductToIdea } from "modules/companies/product/store/product.actions";
import LoadingComponent from "modules/primitives/loading/loading.component";

const AddProductToIdeaComponent = ({
  enqueueSnackbar,
  product,
}) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ideas, setIdeas] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setLoading(!ideas?.length);
  }, [ideas]);

  useEffect(() => {
    dispatch(
      ideasListGet({
        items: 9999,
        page: 1,
        enqueueSnackbar,
        isDropdown: true,
        cb: (data) => {
          setIdeas(data);
        }
      }),
    );
  }, []);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = () => {
    if (!selected) {
      enqueueSnackbar("Please select an idea", {
        variant: "error",
      });

      return;
    }

    setIsSubmitting(true);

    const payload = {
      data: {
        idea_id: selected.val,
        council_product_id: product.id
      },
      cb: () => {
        setIsSubmitting(false);
        popup.hide();
        enqueueSnackbar("Successfully added product to Idea",
          { variant: "success" },
        );
      },
      enqueueSnackbar,
    };

    dispatch(addProductToIdea(payload));
  };

  return (
    <div className={styles.addCompanyWrp}>
      <ModalBody>
        {
          loading ? (
            <LoadingComponent customText="Getting Ideas..." />
          ) : (
            <>
              <Label>Ideas</Label>
              <DropSelect
                onChange={setSelected}
                placeholder="Search for Idea"
                labelField="name"
                valueField="id"
                searchBy="name"
                options={ideas.filter(i => !product.ideas?.map(idea => idea.id).includes(i.id))}
              />
            </>
          )
        }
      </ModalBody>
      <ModalFooter>
        {
          isSubmitting ? (
            <LoadingComponent customText="Processing..." />
          ) : (
            <>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button
                disabled={!selected}
                type="submit"
                btn={BtnType.REGULAR}
                onClick={handleSaveClick}
              >
                Save
              </Button>
            </>
          )
        }
      </ModalFooter>
    </div>
  );
};

export default React.memo(AddProductToIdeaComponent);

import { combineEpics, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";

import {
  handleErrorSuccess,
  setErrorHandledSuccess,
} from "../../companies/companyMain/companyMain.action";
import {
  apiStatus,
  errorHandler,
  httpDelete,
  httpGet,
  httpPost,
} from "../../../common/httpCall";
import {
  SET_COMPANY_SAVED_LIST_ATTEMPT,
  setCompanySavedListSuccess,
  COMPANY_SAVED_LIST_GET_ATTEMPT,
  companySavedListGetSuccess,
  COMPANY_LIST_SHARE_REMOVE_MANY_ATTEMPT,
  COMPANY_LIST_SHARE_REMOVE_MEMBER_ATTEMPT,
  COMPANY_LIST_SHARE_WITH_MEMBER_ATTEMPT,
  COMPANY_SAVED_LIST_ADD_ATTEMPT,
  COMPANY_SAVED_LIST_DELETE_ATTEMPT,
  COMPANY_SAVED_LIST_GET_BY_ID_ATTEMPT,
  companyListShareRemoveManySuccess,
  companyListShareRemoveMemberSuccess,
  companyListShareWithMemberSuccess,
  companySavedListAddSuccess,
  companySavedListDeleteSuccess,
  companySavedListGetByIdSuccess,
  GET_FOLLOWING_LIST_COMPANIES_ATTEMPT,
  getFollowingListCompaniesSuccess,
  REMOVE_COMPANY_FROM_SAVED_LIST_ATTEMPT,
  removeCompanyFromSavedListSuccess,
  SEARCH_COMPANIES_LISTS_BY_QUERY_ATTEMPT,
  SEARCH_LISTS_BY_QUERY_ATTEMPT,
  searchCompaniesListsByQuerySuccess,
  searchListsByQuerySuccess,
} from "./lists.actions";

export const epicGetSavedLists = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_SAVED_LIST_GET_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `lists${payload.getMyOnlyLists ? "/editable" : ""}?items=${
          payload?.items || 20
        }&page=${payload?.page || 1}?is_dropdown=${payload?.isDropdown}`,
      }).pipe(
        map((result) => companySavedListGetSuccess(result)),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const epicGetSavedListById = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_SAVED_LIST_GET_BY_ID_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpGet({
        call: `lists/${id}`,
      }).pipe(
        map((result) => companySavedListGetByIdSuccess(result)),
        catchError((err) =>
          errorHandler(err, enqueueSnackbar, [], handleErrorSuccess)
        )
      )
    )
  );

export const epicDeleteSavedList = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_SAVED_LIST_DELETE_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpDelete({
        call: `lists/${id}`,
      }).pipe(
        map((result) => {
          if (result.status === 200 || result.status === 201) {
            enqueueSnackbar("List deleted succesfully", {
              variant: "success",
            });
          }
          return companySavedListDeleteSuccess(result);
        }),
        catchError((err) =>
          errorHandler(err, enqueueSnackbar, [], handleErrorSuccess)
        )
      )
    )
  );

export const epicRemoveCompanyFromSavedList = (action$, state$) =>
  action$.pipe(
    ofType(REMOVE_COMPANY_FROM_SAVED_LIST_ATTEMPT),
    switchMap(
      ({ payload: { listId, companyId, enqueueSnackbar, isProduct } }) =>
        httpDelete({
          call: `lists/${listId}/${
            isProduct ? "products" : "companies"
          }/${companyId}`,
        }).pipe(
          map((result) => {
            if (result.status === 200 || result.status === 201) {
              enqueueSnackbar("Company removed from list successfully", {
                variant: "success",
              });
              return removeCompanyFromSavedListSuccess({
                ...result,
                isProduct,
                productId: companyId,
              });
            }
          }),
          catchError((err) =>
            errorHandler(err, enqueueSnackbar, [], handleErrorSuccess)
          )
        )
    )
  );

export const epicAddCompanyToSavedList = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_SAVED_LIST_ADD_ATTEMPT),
    switchMap(
      ({
        payload: {
          listId,
          companyId,
          enqueueSnackbar,
          multipleAdd,
          history,
          cb,
        },
      }) =>
        httpPost({
          call: `lists/${listId}/companies`,
          data: {
            list_company: {
              list_id: listId,
              company_id: companyId,
            },
          },
        }).pipe(
          map((result) => {
            if (result.status === 200 || result.status === 201) {
              enqueueSnackbar("Successfully added company to list.", {
                variant: "success",
              });
            }

            if (cb) {
              cb();
            }

            if (multipleAdd && history) {
              history.push(`/list/${listId}`);
            }

            return companySavedListAddSuccess(result);
          }),
          catchError((error) => errorHandler(error, enqueueSnackbar))
        )
    )
  );

export const epicShareCompanyWithMember = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_LIST_SHARE_WITH_MEMBER_ATTEMPT),
    switchMap(({ payload: { listId, data, enqueueSnackbar } }) =>
      httpPost({
        call: `lists/${listId}/share`,
        data,
      }).pipe(
        map((result) => {
          if (result.status === 200 || result.status === 201) {
            enqueueSnackbar("List was succesfully shared.", {
              variant: "success",
            });
          }
          return companyListShareWithMemberSuccess(result);
        }),
        catchError((error) => {
          return errorHandler(error, enqueueSnackbar);
        })
      )
    )
  );

export const epicRemoveMemberFromSharedList = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_LIST_SHARE_REMOVE_MEMBER_ATTEMPT),
    switchMap(({ payload: { listId, memberId, team_ids, enqueueSnackbar } }) =>
      httpPost({
        call: `lists/${listId}/members/destroy_many`,
        data: {
          member_ids: memberId,
          team_ids,
        },
      }).pipe(
        map((result) => {
          if (result.status === 200 || result.status === 201) {
            enqueueSnackbar("Members removed succesfully", {
              variant: "success",
            });
          }
          return companyListShareRemoveMemberSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicRemoveManyFromSharedList = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_LIST_SHARE_REMOVE_MANY_ATTEMPT),
    switchMap(({ payload: { listId, memberId, enqueueSnackbar } }) =>
      httpDelete({
        call: `lists/${listId}/members/destroy_many`,
        data: {
          member_ids: memberId,
        },
      }).pipe(
        map((result) => {
          if (result.status === 200 || result.status === 201) {
            enqueueSnackbar("Members removed succesfully", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Something wrong happened", {
              variant: "error",
            });
          }
          return companyListShareRemoveManySuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicSearchLists = (action$, state$) =>
  action$.pipe(
    ofType(SEARCH_LISTS_BY_QUERY_ATTEMPT),
    switchMap(({ payload: { query, page, enqueueSnackbar, isDropdown } }) =>
      httpGet({
        call: `lists?${
          query ? "query=" + query : ""
        }&items=20&page=${page}&is_dropdown=${isDropdown}`,
      }).pipe(
        map((result) => {
          return searchListsByQuerySuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicSearchListCompanies = (action$, state$) =>
  action$.pipe(
    ofType(SEARCH_COMPANIES_LISTS_BY_QUERY_ATTEMPT),
    switchMap(
      ({
        payload: {
          value,
          listId,
          page,
          enqueueSnackbar,
          sort_attribute,
          sort_order,
        },
      }) =>
        httpPost({
          apiVersion: "v2",
          call: `list_companies/search?items=50&page=${page}`,
          data: {
            sort_attribute,
            sort_order,
            search: {
              query: value,
              list_id: listId,
              sort_attribute,
              sort_order,
            },
          },
        }).pipe(
          map((result) => {
            return searchCompaniesListsByQuerySuccess(result);
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicGetFollowingListCompanies = (action$, state$) =>
  action$.pipe(
    ofType(GET_FOLLOWING_LIST_COMPANIES_ATTEMPT),
    switchMap(
      ({
        payload: {
          selectedCouncil,
          page,
          enqueueSnackbar,
          sort_attribute,
          sort_order,
          value,
        },
      }) =>
        httpPost({
          apiVersion: "v2",
          // call: `following/companies?items=50&page=${page || 1}`,
          call: `follow_relationships/search?items=50&page=${page || 1}`,
          data: {
            sort_attribute,
            sort_order,
            search: {
              query: value,
            },
          },
        }).pipe(
          map((result) => getFollowingListCompaniesSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicNewSavedList = (action$, state$) =>
  action$.pipe(
    ofType(SET_COMPANY_SAVED_LIST_ATTEMPT),
    switchMap(
      ({
        payload: {
          name,
          council_id,
          privateProp,
          company_ids,
          product_ids,
          enqueueSnackbar,
          search_params,
        },
      }) =>
        httpPost({
          call: "/lists",
          data: {
            list: {
              name,
              council_id,
              private: privateProp,
              company_ids,
              product_ids,
              search_params: search_params
                ? {
                    ...search_params,
                    query: search_params?.query || "",
                  }
                : null,
            },
          },
        }).pipe(
          map((result) => {
            if (result.status === 200 || result.status === 201) {
              enqueueSnackbar("Successfully created list.", {
                variant: "success",
              });
            }
            return setCompanySavedListSuccess(result);
          }),
          catchError((error) => {
            const customMessages = [
              {
                message:
                  "A List with that name already exists, please choose another name---",
                type: apiStatus.UNPROCESSABLE_ENTITY,
              },
            ];
            return errorHandler(
              error,
              enqueueSnackbar,
              customMessages,
              setErrorHandledSuccess
            );
          })
        )
    )
  );

const listsMainEpic = combineEpics(
  epicNewSavedList,
  epicGetSavedLists,
  epicGetSavedListById,
  epicAddCompanyToSavedList,
  epicDeleteSavedList,
  epicRemoveCompanyFromSavedList,
  epicShareCompanyWithMember,
  epicRemoveMemberFromSharedList,
  epicRemoveManyFromSharedList,
  epicSearchLists,
  epicSearchListCompanies,
  epicGetFollowingListCompanies
);

export default listsMainEpic;

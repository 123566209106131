import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import Tooltip from "@mui/material/Tooltip";

import { checkViewerRole } from "common/checkers/viewerChecker";
import styles from "./stageTask.module.scss";
import { CheckBox, CheckType } from "../../../../primitives";
import {
  appSliderSettingSet,
  appSelectedSet,
} from "application/app.action";
import { sliderSetting } from "application/app.constants";
import {
  TaskInfoComponentV2,
  TaskInfoHeaderComponentV2,
} from "../../tasks/taskInfo";
import { Icon, mdSize } from "../../../../../common/icon";
import {
  projectCompanyTaskLitGet,
  projectOrganizationTaskListGet,
  setCompanyTaskAssignmentCompleted,
} from "../../companies/companies.action";
import {
  projectTaskCompanyListGet,
  projectTaskUpdate,
} from "../../tasks/tasks.action";

const StageTaskComponent = (props) => {
  const {
    show, stage, company, patientOrg,
  } = props;
  const dispatch = useDispatch();
  const [tasks, setTasks] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    appReducer: { selectedItem },
    projectsReducer: {
      companyReducer: { taskList_companyId },
      projectReducer: { selectedProject },
    },
    authReducer: { session },
  } = useSelector((state) => state);
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (!selectedProject) return;

    if (patientOrg) {
      dispatch(
        projectOrganizationTaskListGet({
          projectId: selectedProject.id,
          gsk_patient_organization_id: company.id,
          stageId: stage.id,
          enqueueSnackbar,
        }),
      );
    } else {
      dispatch(
        projectCompanyTaskLitGet({
          projectId: selectedProject.id,
          companyId: company.id,
          stageId: stage.id,
          enqueueSnackbar,
        }),
      );
    }
  }, [patientOrg]);

  useEffect(() => {
    if (taskList_companyId[`${company.id}_${stage.id}`]) {
      const list = taskList_companyId[`${company.id}_${stage.id}`];
      setTasks(list);
    }
  }, [JSON.stringify(taskList_companyId[`${company.id}_${stage.id}`])]);

  const handleTaskViewClick = (obj) => {
    if (obj.locked) {
      return;
    }

    const assignId = `stage${obj.id}_${company.id}`;

    if (selectedProject) {
      dispatch(
        projectTaskCompanyListGet({
          projectId: selectedProject.id,
          taskId: obj.id,
          enqueueSnackbar,
        }),
      );
    }

    const assignment = obj.projects_task_assignments.find(
      (a) => a.projects_task_organization.organization_id === company.id,
    );

    dispatch(appSelectedSet(assignId));
    dispatch(
      appSliderSettingSet({
        ...sliderSetting,
        show: true,
        height: "300",
        width: "540",
        header: (
          <TaskInfoHeaderComponentV2
            taskId={obj.id}
            projectId={obj.project_id}
            assignmentId={assignment?.id}
            company={company}
            stage={stage}
            completionEnabled={obj.completion_enabled}
            isCompanyTask
            task={obj}
          />
        ),
        component: (
          <TaskInfoComponentV2
            task={obj}
            assignment={assignment}
            stage={stage}
          />
        ),
      }),
    );
  };

  const handleOnChange = (value, task) => {
    if (task.locked) {
      return;
    }

    const assignment = task.projects_task_assignments.find(
      (a) => a.projects_task_organization.organization_id === company.id,
    );

    dispatch(
      projectTaskUpdate({
        enqueueSnackbar,
        taskId: task.id,
        flag: value ? "completed_true" : "completed_false",
        projectId: selectedProject.id,
        project_task: {
          task_assignments: {
            completed_on: value ? format(new Date(), "yyyy-MM-dd") : null,
          },
        },
        queryParams: {
          task_assignment_id: assignment.id,
        },
      }),
    );
    dispatch(
      setCompanyTaskAssignmentCompleted({
        companyId: company.id,
        stageId: stage.id,
        assignmentId: assignment.id,
        taskId: task.id,
        value,
      }),
    );
  };

  const checkIsChecked = useCallback(
    (task) => {
      const getAssignment = task.projects_task_assignments.find(
        (a) => a?.projects_task_organization?.organization_id === company?.id,
      );

      return getAssignment?.completed || false;
    },
    [company],
  );

  return (
    <div className={classNames(styles.stageTaskWrp, show && styles.show)}>
      <div className={classNames("caption", styles.stageTaskTitle)}>
        {company.is_product ? "PRODUCT TASKS" : "COMPANY TASKS"}
      </div>
      <div className={styles.taskListWrp}>
        {tasks.length ? (
          tasks.map((task, index) => (
            <div
              key={index}
              className={classNames(
                task.locked ? styles.lockedTask : '',
                styles.taskCon,
                selectedItem === `stage${task.id}_${company.id}`
                  && styles.selected,
              )}
              onClick={() => handleTaskViewClick(task)}
            >
              {!blockFunctionality ? (
                <Tooltip
                  title={!task.completion_enabled
                    ? task.mark_on_document_upload ? 'Upload the required document to complete this Task' : 'Fill out the required linked fields to complete this Task'
                    : ''}
                >
                  <div>
                    <CheckBox
                      label={task.name}
                      isChecked={checkIsChecked(task)}
                      checkType={CheckType.BLUE}
                      onChange={(val) => handleOnChange(val, task)}
                      className={styles.taskInner}
                      disabled={task.locked || !task.completion_enabled}
                      readOnly={!task.completion_enabled}
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip
                  title="You have View-Only access. To edit this Project, please ask your Admin to upgrade your account to Standard access and invite you to this Project."
                  placement="top"
                >
                  <div>
                    <CheckBox
                      label={task.name}
                      isChecked={checkIsChecked(task)}
                      checkType={CheckType.BLUE}
                      className={styles.taskInner}
                      disabled
                    />
                  </div>
                </Tooltip>
              )}
              <Icon
                {...mdSize}
                icon="icn-right-chevron"
                className={styles.icon}
              />
            </div>
          ))
        ) : (
          <div
            className={`d-flex p-2 justify-content-center ${styles.noTasks}`}
          >
            No Tasks
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(StageTaskComponent);

import React from "react";
import { withSnackbar } from "notistack";
import { BtnType, Button } from "modules/primitives/index";

import { useModelPopup } from "../../../../common/hooks/index";
import styles from "../adminTags.module.scss";
import { ModalBody, ModalFooter } from "../../../../application/modal";

const WarningModal = ({}) => {
  const popup = useModelPopup();

  const handleDismiss = () => {
    popup.hide();
  };
  return (
    <div>
      <ModalBody>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p className={styles.aditionalInfo}>
            You have reached the limit of 5 Custom Tag Categories. To create a
            new Custom Tag Category, please delete or edit an existing one.
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleDismiss}>
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleDismiss();
          }}
          btn={BtnType.REGULAR}
        >
          Ok
        </Button>
      </ModalFooter>
    </div>
  );
};

export default React.memo(withSnackbar(WarningModal));

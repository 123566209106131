import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModelPopup } from "../../../../../../common/hooks";

import { makeMemberOwner } from "../../../project.action";

import { ModalFooter, ModalBody } from "../../../../../../application/modal";
import {
  Button,
  BtnType,
  Label,
  CheckBox,
  CheckType,
} from "../../../../../primitives";
import styles from "./chooseRole.module.scss";
import { themesMemberEditAttempt } from "modules/themes/themesIndividualPage/themesOverviewTab/overviewTab.action";
import { useSnackbar } from "notistack";

const ChooseRoleComponent = ({
  templateId,
  fromThemes,
  userId,
  externalMember,
  themeLabel,
}) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [role, setRole] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const {
    projectsReducer: {
      projectReducer: { selectedProject },
    },
  } = useSelector((state) => state);

  const optionRoles = [
    {
      text: `${fromThemes ? themeLabel : "Project"} Member`,
      value: "member",
    },
    {
      text: `${fromThemes ? themeLabel : "Project"} Manager`,
      value: "manager",
    },
    {
      text: `${fromThemes ? themeLabel : "Project"} Owner`,
      value: "owner",
    },
  ];

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = async () => {
    if (fromThemes) {
      const data = {
        theme_member: {
          user_role: role,
          expert: fromThemes.status,
        },
      };
      dispatch(themesMemberEditAttempt({ data, id: templateId, userId }));
    } else if (role) {
      const data = {
        role,
        projectId: selectedProject.id,
        userId,
        active: true,
        externalMember,
      };
      dispatch(makeMemberOwner(data, enqueueSnackbar));
      enqueueSnackbar("Member role has been changed successfully", {
        variant: "success",
      });
    }
    popup.hide();
  };

  const handleRoleChange = (val) => {
    setRole(val);
  };

  return (
    <div className={styles.addCompanyWrp}>
      <ModalBody>
        {optionRoles.map((option) => (
          <div
            key={option.value}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <CheckBox
              checkType={CheckType.BLUE}
              isChecked={option.value === role}
              onChange={() => {
                handleRoleChange(option.value);
              }}
            />
            <Label
              onClick={() => handleRoleChange(option.value)}
              className={styles.text}
            >
              {option.text}
            </Label>
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          type="submit"
          btn={BtnType.REGULAR}
          onClick={(e) => handleSaveClick(e)}
        >
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default ChooseRoleComponent;

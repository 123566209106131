import React, { useState } from "react";
import styles from "modules/reporting/customReportsStyles.module.scss";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "modules/primitives";
import Dialog from "@mui/material/Dialog";

const DownloadViewDocs = ({ data, config }) => {
  const [open, setOpen] = useState(false);
  const hasShowMore = data[config.propToUse]?.length > 4;

  const handleShowMore = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  if (!data[config.propToUse]?.length) {
    return '-'
  }

  return (
    <div className="d-flex flex-column w-100">
      {
        data[config.propToUse].slice(0, hasShowMore ? 4 : data[config.propToUse]?.length).map(doc => (
          <a
            key={doc.uri}
            className="w-100 word-break"
            target="blank"
            href={doc.uri}
            download
          >
            {doc.name}
          </a>
        ))
      }
      {
        hasShowMore ? (
          <span
            onClick={handleShowMore}
            className={`${styles.showMoreData} d-block mt-3`}
          >+ {data[config.propToUse].length - 4}</span>
        ) : null
      }
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <div className="d-flex flex-column">
            {
              data[config.propToUse].map(doc => (
                <a
                  key={doc.uri}
                  className="w-100 word-break"
                  target="blank"
                  href={doc.uri}
                  download
                >
                  {doc.name}
                </a>
              ))
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(DownloadViewDocs);

import * as Yup from "yup";

const initModel = {
  name: "",
  description: "",
};

const validation = Yup.object().shape({
  name: Yup.string().required("Team name is required"),
});

export { validation, initModel };

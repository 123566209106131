export const moreOptionTypes = {
  edit: 1,
  assign: 2,
  history: 3,
};

export const moreOptions = [
  {
    val: moreOptionTypes.edit,
    text: "Edit",
  },
  {
    val: moreOptionTypes.assign,
    text: "Assign",
  },
  {
    val: moreOptionTypes.history,
    text: "History",
  },
];

export const FIRMENICH_HOVER_TEXTS = {
  'TRL 1': 'Basic principles observed and reported: The technology is in the conceptual stage, with no experimental proof of concept.',
  'TRL 2': 'Technology concept and/or application formulated: The technology has been formulated, and potential applications have been identified.',
  'TRL 3': 'Analytical and experimental critical function and/or characteristic proof-of-concept: The technology has undergone a proof-of-concept demonstration in a laboratory or simulated environment.',
  'TRL 4': 'Component and/or breadboard validation in laboratory environment: The technology has been tested in a laboratory or simulated environment to demonstrate its functionality and reliability.',
  'TRL 5': 'System/subsystem/component validation in relevant environment: The technology has been tested in a relevant environment, such as a prototype or simulated environment, to validate its functionality and reliability.',
  'TRL 6': 'System/subsystem model or prototype demonstration in a relevant environment: A prototype or model of the technology has been demonstrated in a relevant environment to prove its capability.',
  'TRL 7': 'System prototype demonstration in an operational environment: The technology has been demonstrated in an operational environment to prove its capability and readiness for commercialization.',
  'TRL 8': 'Actual system completed and qualified through test and demonstration: The technology has been completed and qualified through testing and demonstration, and is ready for commercialization.',
  'TRL 9': 'Actual system proven through successful mission operations: The technology has been successfully implemented in real-world operations, and its reliability and effectiveness have been demonstrated.',
}

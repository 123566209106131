import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { patientOrganizationEdit } from "modules/patientOrganizations/patientOrganization/patientOrganization.action";
import ShareWithComponent from "modules/companies/company/popups/shareWith/index";
import AddCompanyComponent from "modules/companies/companyMain/addToProject/index";
import { checkViewerRole } from "common/checkers/viewerChecker";
import { deseasCheck } from "../patientOrganizationMain.action";
import { useModelPopup } from "../../../../common/hooks";
import {
  Button,
  BtnType,
  MoreOptions,
  CompanyAvatar,
} from "../../../primitives";
import styles from "./styles.module.scss";
import { relationshipOptions, dropdownOptions } from "modules/patientOrganizations/constants";
import { useWindowSize } from "../../../../common/hooks/useWindowSize";
import { handleUrlClick, makeWebSite } from "../../../../common/helper";

const OrganizationBlock = ({ organization, enqueueSnackbar, selectModeIsActive, isSelected, handleOrganizationSelect }) => {
  const { isDesktop } = useWindowSize();
  const [relationship, setRelationship] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const popup = useModelPopup();
  const [options, setOptions] = useState(dropdownOptions);
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  useEffect(() => {
    if (checkViewerRole()) {
      const filteredOptions = dropdownOptions.filter(
        (option) => option.text !== "Add to project",
      );
      setOptions(filteredOptions);
      setBlockFunctionality(true);
    }
  }, []);

  const {
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: { checkedPatientFilters },
    },
  } = useSelector((state) => state);

  const handleCompanyClick = () => {
    localStorage.setItem("company", JSON.stringify(organization));
    history.push(`/patient_organizations/${organization.id}`);
  };

  const humanizeText = (str = "") => {
    let i;
    const frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  };

  const handleDropdownSelect = (e) => {
    setRelationship(e.target.value);
    const data = {
      relationship_stage: e.target.value,
    };
    dispatch(
      patientOrganizationEdit({
        patient_organization_id: organization.id,
        data,
        enqueueSnackbar,
      }),
    );
  };

  useEffect(() => {
    if (organization) {
      setRelationship(organization.relationship_stage);
    }
  }, [organization.relationship_stage]);

  const handleMoreOptionClick = (val) => {
    if (val === 1) {
      popup.show({
        title: "Share With",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ShareWithComponent
            actionType="sharePatientOrganization"
            selectedCompany={organization}
          />
        ),
      });
    } else if (val === 2) {
      popup.show({
        title: "Add Organization To Project",
        show: true,
        height: "1000",
        width: "540",
        component: (
          <AddCompanyComponent
            companyId={organization.id}
            type="patient_organizations"
            enqueueSnackbar={enqueueSnackbar}
          />
        ),
      });
    }
  };

  const handleDiseaseClick = (evt, disease) => {
    evt.preventDefault();
    evt.stopPropagation();

    let currentDiseases = [...checkedPatientFilters.disease_ids];

    if (currentDiseases.includes(disease.id)) {
      currentDiseases = currentDiseases.filter((id) => id !== disease.id);
    } else {
      currentDiseases.push(disease.id);
    }

    dispatch(
      deseasCheck({
        disease_ids: currentDiseases,
      }),
    );
  };

  const handleDiversityGroupClick = (evt, group) => {
    evt.preventDefault();
    evt.stopPropagation();

    let currentDiversityGroups = [...checkedPatientFilters.diversity_group_ids];

    if (currentDiversityGroups.includes(group.resource_disease_id)) {
      currentDiversityGroups = currentDiversityGroups.filter(
        (id) => id !== group.resource_disease_id,
      );
    } else {
      currentDiversityGroups.push(group.resource_disease_id);
    }

    dispatch(
      deseasCheck({
        diversity_group_ids: currentDiversityGroups,
      }),
    );
  };

  const handleCompanyUrlClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    handleUrlClick(organization.domain);
  };

  return (
    <div className="position-relative d-flex flex-grow-1 align-items-center w-100">
      {selectModeIsActive && (
        <div className={styles.selectOrganization}>
          <input
            type="checkbox"
            checked={isSelected}
            onChange={handleOrganizationSelect}
          />
        </div>
      )}
      <div className={styles.wrapper} onClick={handleCompanyClick}>
        <div className={styles.logoWrp}>
          <div className={styles.logoCon}>
            <CompanyAvatar
              className={styles.logo}
              imgSrc={organization.logo}
              name={organization.name}
            />
          </div>
        </div>
        <div className={styles.contentWrp}>
          <div className={styles.firstRow}>
            <div className="d-flex flex-column mb-3">
              <h4 className={styles.name}>{organization.name}</h4>
              {organization.domain && (
                <span className={styles.webUrl} onClick={handleCompanyUrlClick}>
                  {makeWebSite(organization.domain)}
                </span>
              )}
            </div>
            <div className={styles.rightWrp}>
              {relationship && (
                <Select
                  value={relationship || "no_relationship"}
                  onClick={(e) => e.stopPropagation()}
                  onChange={handleDropdownSelect}
                  className={`${styles.detailsRelationship} ${styles[relationship]}`}
                  disableUnderline
                  hintStyle={{ textAlign: "center" }}
                  disabled={blockFunctionality}
                >
                  {relationshipOptions.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <div className={styles.colMore}>
                <MoreOptions
                  options={options}
                  className={styles.moreOptWrp}
                  onClick={(e) => handleMoreOptionClick(e)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-auto">
              <span className={styles.poAttrs}>Geographic Reach:</span>
              <span className={styles.poAttrsVal}>
                <b>{organization.geographic_reach ? humanizeText(organization.geographic_reach) : '-'}</b>
              </span>
            </div>
            <div className="col-auto">
              <span className={styles.poAttrs}>Country:</span>
              <span className={styles.poAttrsVal}>
                <b>
                  {organization.country
                    ? organization.country
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : organization.location_country || "-"}
                </b>
            </span>
            </div>
          </div>
          <div className={styles.location}>{organization.location}</div>
          <div className={styles.descriptions}>{organization.description}</div>
          <div className={styles.tagsWrp}>
            <div className={styles.tagsCon}>
              {/* disease */}
              {organization.resource_diseases
                && organization.resource_diseases.map(({ disease }) => (
                  <Button
                    key={disease.id}
                    btn={BtnType.TAG_BLUE_LIGHT}
                    rounded
                    className={`${styles.tag} ${styles.topicTag} ${
                      checkedPatientFilters.disease_ids?.includes(disease.id)
                        ? styles.selectedDisease
                        : ""
                    }`}
                    onClick={(evt) => handleDiseaseClick(evt, disease)}
                  >
                    {disease.name}
                  </Button>
                ))}
            </div>
            <div className={styles.tagsCon}>
              {organization.resource_diversity_groups
                && organization.resource_diversity_groups.map((group) => (
                  <Button
                    key={group?.resource_disease_id}
                    btn={BtnType.TAG_BLUE}
                    rounded
                    className={`${styles.tag} ${styles.topicTag} ${
                      checkedPatientFilters.diversity_group_ids?.includes(
                        group?.resource_disease_id,
                      )
                        ? styles.selectedDisease
                        : ""
                    }`}
                    onClick={(evt) => handleDiversityGroupClick(evt, group)}
                  >
                    {group?.disease?.name}
                  </Button>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(OrganizationBlock);

import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "throttle-debounce";

import styles from './verticalBarChart.module.scss';
import { waitForElm } from "../../../../common/helper";
import { getChart, initializeChart } from "modules/companies/companyMain/chartsWrapper/chartsView/charts/utils";
import VerticalBarChart from "modules/reporting/components/reportingVerticalBarChart/verticalBarChart";
import { makeVerticalBarConfig } from "modules/reporting/components/reportingVerticalBarChart/verticalBarUtils";

const ReportingVerticalBarChart = ({
  chartData,
  id,
}) => {
  const ref = useRef();

  const [chart, setChart] = useState(null);
  const [wrapperWidth, setWrapperWidth] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", debounceResize);

    return () => {
      window.removeEventListener("resize", null);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      setWrapperWidth(ref.current.clientWidth);
    }
  }, [ref.current]);

  useEffect(() => {
    if (chart) {
      const chartElem = document.getElementById(`verticalBarChart${id}`);

      if (!chartElem) return;

      chartElem.onclick = (evt) => {
        const points = chart.getElementsAtEventForMode(
          evt,
          "nearest",
          { intersect: true },
          true,
        );

        if (!points.length) return;
      };
    }

    return () => null;
  }, [chart, id]);

  useEffect(() => {
    if (wrapperWidth && !chart) {
      makeChartConfig();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [wrapperWidth, chart]);

  const debounceResize = debounce(1000, false, () => {
    if (ref?.current && chart) {
      setWrapperWidth(ref?.current?.clientWidth);
    }
  });

  const makeChartConfig = useCallback(
    () => {
      if (!chartData?.data_sets) return;

      const chartConfig = makeVerticalBarConfig(chartData);

      waitForElm(`#verticalBarChart${id}`).then(() => {
        const chartInstanceById = getChart(`verticalBarChart${id}`);

        if (chartInstanceById) chartInstanceById.destroy();

        const newChart = initializeChart(`verticalBarChart${id}`, "bar", chartConfig);

        setChart(newChart);
      });
    },
    [chart, id, chartData],
  );

  const returnChartNode = useCallback(
    (width) => {
      if (chartData) {
        if (chart && chart?.width !== width) {
          chart.width = width;
          chart.update();
        }

        return (
          <VerticalBarChart
            width={width}
            chartHeight={300}
            id={id}
          />
        );
      }
    },
    [chartData, chart, id],
  );

  if (chart && !chart.config?._config?.data?.datasets?.length) {
    return null;
  }

  return (
    <div ref={ref} className={styles.reportingVerticalBarChart}>
      {wrapperWidth && returnChartNode(wrapperWidth)}
    </div>
  );
};

export default React.memo(ReportingVerticalBarChart);

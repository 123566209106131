import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  httpGet,
  httpPost,
  httpDelete,
  httpPut,
  apiStatus,
  errorHandler,
} from "../../../common/httpCall";
import {
  GET_TRACTION_COMPANIES_ATTEMPT,
  getTractionCompaniesSuccess,
  PUT_TRACTION_COMPANIES_ATTEMPT,
  putTractionCompaniesSuccess,
  catchErrorSuccess,
  DELETE_TRACTION_COMPANIES_ATTEMPT,
  deleteTractionCompaniesSuccess,
  PUT_TRACTION_COMPANIES_HIDE_ATTEMPT,
  putTractionCompaniesHideSuccess,
  GET_ALL_COMPANIES_EXPORT_ATTEMPT,
  getAllCompaniesExportSuccess,
  GET_TRACTION_COMPANY_BY_ID_ATTEMPT,
  getTractionCompanyByIdSuccess,
  PUT_TRACTION_COMPANIES_UNHIDE_ATTEMPT,
  putTractionCompaniesUnHideSuccess,
  POST_SEARCH_COMPANIES_BY_QUERY_ATTEMPT,
  postSearchCompaniesByQuerySuccess,
  POST_TRACTION_COMPANY_ATTEMPT,
  postTractionCompanySuccess,
  POST_SEARCH_COMPANIES_SF_EXPORT_ATTEMPT,
  postSearchCompaniesSfExportSuccess,
} from "./tractionCompanies.actions";

export const postTractionCompanyEpic = (action$) => action$.pipe(
  ofType(POST_TRACTION_COMPANY_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar, data } }) => httpPost({
    apiVersion: "v2",
    call: "",
    data,
  }).pipe(
    map((result) => {
      try {
        if (
          result.status === apiStatus.CREATED
              || result.status === apiStatus.SUCCESS
        ) {
          return postTractionCompanySuccess(result);
        }
      } catch (error) {
        console.log("-----error in PUT_COUNCIL_INTEGRATION-----", error);
      }
    }),
    catchError((error) => errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)),
  )),
);

export const postSearchCompaniesByQueryEpic = (action$) => action$.pipe(
  ofType(POST_SEARCH_COMPANIES_BY_QUERY_ATTEMPT),
  switchMap(({
    payload: {
      enqueueSnackbar, query, page, pageSize,
    },
  }) => httpPost({
    apiVersion: "v2",
    call: `companies/search?items=${pageSize || 20}&page=${
      page || 1
    }&with_context=true`,
    data: {
      search: {
        query,
        sort_attribute: "updated_at",
        sort_order: "asc",
      },
    },
  }).pipe(
    map((result) => {
      try {
        if (
          result.status === apiStatus.CREATED
              || result.status === apiStatus.SUCCESS
        ) {
          return postSearchCompaniesByQuerySuccess(result);
        }
      } catch (error) {
        console.log("-----error in PUT_COUNCIL_INTEGRATION-----", error);
      }
    }),
    catchError((error) => errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)),
  )),
);

export const postSearchCompaniesSfExportEpic = (action$) => action$.pipe(
  ofType(POST_SEARCH_COMPANIES_SF_EXPORT_ATTEMPT),
  switchMap(
    ({
      payload: {
        enqueueSnackbar,
        query,
        page,
        pageSize,
        customFieldValueList,
      },
    }) => httpPost({
      apiVersion: "v2",
      call: `companies/sf_export_companies?items=${pageSize || 20}&page=${
        page || 1
      }&with_context=true`,
      data: {
        customFieldValueList,
        search: {
          query,
          sort_attribute: "updated_at",
          sort_order: "asc",
        },
      },
    }).pipe(
      map((result) => {
        try {
          if (
            result.status === apiStatus.CREATED
                || result.status === apiStatus.SUCCESS
          ) {
            return postSearchCompaniesSfExportSuccess(result);
          }
        } catch (error) {
          console.log("-----error in PUT_COUNCIL_INTEGRATION-----", error);
        }
      }),
      catchError((error) => errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)),
    ),
  ),
);

export const epicGetTractionCompanies = (action$) => action$.pipe(
  ofType(GET_TRACTION_COMPANIES_ATTEMPT),
  switchMap(({ payload: { items, page, enqueueSnackbar } }) => httpGet({
    apiVersion: "v2",
    call: `companies?items=${items}&page=${page}`, // NEW_API
  }).pipe(
    map((result) => getTractionCompaniesSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetTractionCompanyById = (action$) => action$.pipe(
  ofType(GET_TRACTION_COMPANY_BY_ID_ATTEMPT),
  switchMap(({ payload: { companyId, enqueueSnackbar } }) => httpGet({
    apiVersion: "v2",
    call: `companies/${companyId}`, // NEW_API
  }).pipe(
    map((result) => getTractionCompanyByIdSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetAllTractionCompaniesExport = (action$) => action$.pipe(
  ofType(GET_ALL_COMPANIES_EXPORT_ATTEMPT),
  switchMap(({ payload: { items, page, enqueueSnackbar } }) => httpGet({
    apiVersion: "v2",
    call: `companies?items=${items}&page=${page}`, // NEW_API
  }).pipe(
    map((result) => getAllCompaniesExportSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const putTractionCompaniesEpic = (action$) => action$.pipe(
  ofType(PUT_TRACTION_COMPANIES_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar, companyId, data } }) => httpPut({
    apiVersion: "v2",
    call: `companies/${companyId}`,
    data: {
      company: data,
    },
  }).pipe(
    map((result) => {
      try {
        if (
          result.status === apiStatus.CREATED
              || result.status === apiStatus.SUCCESS
        ) {
          return putTractionCompaniesSuccess(result);
        }
      } catch (error) {
        console.log("-----error in PUT_COUNCIL_INTEGRATION-----", error);
      }
    }),
    catchError((error) => errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)),
  )),
);

export const putTractionCompaniesHideEpic = (action$) => action$.pipe(
  ofType(PUT_TRACTION_COMPANIES_HIDE_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar, companyId, data } }) => httpPut({
    apiVersion: "v2",
    call: `companies/${companyId}/hide`,
    data: {
      council_ids: data,
    },
  }).pipe(
    map((result) => {
      try {
        if (
          result.status === apiStatus.CREATED
              || result.status === apiStatus.SUCCESS
        ) {
          return putTractionCompaniesHideSuccess(result);
        }
      } catch (error) {
        console.log("-----error in PUT_COUNCIL_INTEGRATION-----", error);
      }
    }),
    catchError((error) => errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)),
  )),
);

export const putTractionCompaniesUnHideEpic = (action$) => action$.pipe(
  ofType(PUT_TRACTION_COMPANIES_UNHIDE_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar, companyId, data } }) => httpPut({
    apiVersion: "v2",
    call: `companies/${companyId}/unhide`,
    data: {
      council_ids: data,
    },
  }).pipe(
    map((result) => {
      try {
        if (
          result.status === apiStatus.CREATED
              || result.status === apiStatus.SUCCESS
        ) {
          return putTractionCompaniesUnHideSuccess(result);
        }
      } catch (error) {
        console.log("-----error in PUT_COUNCIL_INTEGRATION-----", error);
      }
    }),
    catchError((error) => errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)),
  )),
);

export const epicDeleteTractionCompany = (action$) => action$.pipe(
  ofType(DELETE_TRACTION_COMPANIES_ATTEMPT),
  switchMap(({ payload: { companyId, enqueueSnackbar, data } }) => httpDelete({
    apiVersion: "v2",
    call: `companies/${companyId}`,
    data: {
      ...data,
    },
  }).pipe(
    map((result) => {
      enqueueSnackbar("Company deleted successfully", {
        variant: "success",
      });
      return deleteTractionCompaniesSuccess(result);
    }),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const tractionCompaniesEpic = combineEpics(
  epicGetTractionCompanies,
  putTractionCompaniesEpic,
  epicDeleteTractionCompany,
  putTractionCompaniesHideEpic,
  epicGetAllTractionCompaniesExport,
  epicGetTractionCompanyById,
  putTractionCompaniesUnHideEpic,
  postSearchCompaniesByQueryEpic,
  postSearchCompaniesSfExportEpic,
  postTractionCompanyEpic,
);

export default tractionCompaniesEpic;

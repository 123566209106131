import { OptionType } from "modules/primitives/index";

export const HeaderOptions = [
  {
    value: "my_task",
    text: "My Task",
  },
  {
    value: "all_task",
    text: "All Task",
  },
];

export const moreOptions = [{ val: "delete", text: "Delete", type: OptionType.DANGER }];

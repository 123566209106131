export const metricsArr = [
  { key: "investment_usd", name: "Investment ($)" },
  { key: "tarr_percentage", name: "TARR (%)" },
  { key: "savings_usd", name: "Savings ($)" },
  { key: "next_stage_end_on", name: "Next Stage Change" },
  { key: "job_1_date", name: "Job 1 Date" },
  { key: "expected_job_1_date", name: "Expected Job 1 Date" },
  { key: "implementation_date", name: "Implementation Date" },
  { key: "prototype_investment_usd", name: "Prototype Investment USD" },
  { key: "prototype_date", name: "Prototype Date" },
  { key: "prototype_job_1_date", name: "Prototype Job 1 Date" },
  {
    key: "proposal_for_prototype_expected_job_1_date",
    name: "Proposal For Prototype Expected Job 1 Date",
  },
  {
    key: "proposal_for_implementation_date",
    name: "Proposal For Implementation Date",
  },
  { key: "ready_for_replication_date", name: "Ready For Replication Date" },
  {
    key: "threed_printing_replications",
    name: "3D Print Replications",
  },
];

import { combineEpics, ofType } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  ADD_DOCUMENT_ATTEMPT,
  addDocumentSuccess,
  COMPANY_DOCUMENTS_GET_ATTEMPT,
  getDocumentsSuccess,
  DELETE_DOCUMENT_ATTEMPT,
  deleteDocumentSuccess,
} from "../actions/common.action";
import {
  httpPost,
  httpGet,
  queryString,
  httpDelete,
  errorHandler,
} from "../httpCall";

const epicAddProjectDocuments = (action$, state$) => action$.pipe(
  ofType(ADD_DOCUMENT_ATTEMPT),
  switchMap(
    ({
      payload: {
        document_resource_id,
        document_resource_type,
        userId,
        councilId,
        description,
        name,
        s3url,
        added_by_id,
        enqueueSnackbar,
      },
    }) => httpPost({
      apiVersion: `${document_resource_type === "Project" ? "v2" : "v1"}`,
      call: `${
        document_resource_type === "Project"
          ? `/projects/${document_resource_id}/documents`
          : "documents"
      }`, // NEW_API
      data: {
        document: {
          name,
          description,
          added_by_id: userId || added_by_id,
          council_id: councilId,
          uri: s3url,
          document_resource_type,
          document_resource_id,
        },
      },
    }).pipe(
      map((result) => addDocumentSuccess(result)),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

const epicGetDocuments = (action$) => action$.pipe(
  ofType(COMPANY_DOCUMENTS_GET_ATTEMPT),
  switchMap(
    ({
      payload: {
        document_resource_type,
        document_resource_id,
        patientOrg,
        customUrl,
        enqueueSnackbar,
        cb,
      },
    }) => httpGet({
      apiVersion: `${document_resource_type === "Project" ? "v2" : "v1"}`,
      call:
            customUrl
            || `documents?${
              patientOrg === true
                ? queryString({
                  gsk_patient_organization_id: document_resource_id,
                })
                : queryString({
                  company_id: document_resource_id,
                })
            }`,
    }).pipe(
      map((result) => {
        if (cb) {
          cb();
        }

        return getDocumentsSuccess(result);
      }),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

const epicDeleteDocument = (action$) => action$.pipe(
  ofType(DELETE_DOCUMENT_ATTEMPT),
  switchMap(({ payload: { document_id, enqueueSnackbar } }) => httpDelete({
    call: `documents/${document_id}`,
  }).pipe(
    map((result) => deleteDocumentSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const documentsEpic = combineEpics(
  epicAddProjectDocuments,
  epicGetDocuments,
  epicDeleteDocument,
);

export default documentsEpic;

import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import styles from "./progressBar.module.scss";

const ProgressBarComponent = (props) => {
  const {
    width,
    tot,
    fill = 0,
    wrpClass = null,
    conClass = null,
    innerClass = null,
    showTot = true,
    showPrecentage = false,
    progressValueStyle = false,
    overViewTab = false,
    homePageUse,
  } = props;
  const ref = useRef(null);
  const [progress, setProgress] = useState(0);
  const [leftPosition, setLeftPosition] = useState(null);

  useEffect(() => {
    setProgress((fill / tot) * 100);
  }, [fill, tot]);

  useEffect(() => {
    if (progress > 50) {
      setLeftPosition(null);
      return;
    }

    if (ref.current) {
      setLeftPosition(ref.current.offsetWidth + 20);
    }
  }, [ref.current, progress]);

  return (
    <div
      className={classNames(styles.progWrp, wrpClass && wrpClass)}
      style={{ width: `${width}` }}
    >
      <div className={classNames(styles.progCon, conClass && conClass)}>
        <div
          className={classNames(
            styles.progInner,
            innerClass && innerClass,
            progress <= 50 && !overViewTab && styles.lessHalf,
          )}
          style={{
            width: `${progress >= 50 && progress < 60 ? '60' : progress}%`,
            top: progress === 0 ? "-4.2px" : "",
            // color: (progress >= 50 || progress === 0 ? '' : '#323f4b !important')
          }}
          ref={ref}
        >
          {fill && progress > 0 ? (
            <div
              className={classNames(
                styles.fill,
                progressValueStyle && progressValueStyle,
                progress === 100 && styles.full,
                progress === 100 && !overViewTab && styles.whiteCheckMark,
                progress <= 50 && styles.lessHalf,
                progress <= 50 && overViewTab && styles.colorWhite,
                homePageUse && styles.homePageUse,
              )}
              style={
                leftPosition
                  ? {
                    left: `${leftPosition}px`,
                  }
                  : {}
              }
            >
              {progress !== 100 && progress !== 0 && showPrecentage
                ? progress && progress.toFixed(0)
                : fill}{" "}
              {showPrecentage && "%"}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {showTot && <div className={styles.tot}>{tot}</div>}
    </div>
  );
};

export default ProgressBarComponent;

import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactToPdf from "react-to-pdf";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { resetProjectsLoading } from "modules/projects/project/project.action";
import { useSnackbar } from "notistack";
import { appModelSettingSet } from "application/app.action";
import Tooltip from "@mui/material/Tooltip";

import NoteComponent from "modules/companies/company/activity/note";
import MeetingComponent from "modules/companies/company/activity/meeting";
import EmailComponent from "modules/companies/company/activity/email";
import {
  peopleDeleteMemberNote,
  peopleListMemberNotesGet,
} from "modules/people/Profile/profile.action";
import NewEventComponent from "modules/companies/company/activity/popups";
import {
  peopleMembersGet,
  resetPeopleLoading,
} from "../internalPeople/internalPeople.action";
import styles from "./profile.module.scss";
import { UserInlineTypes, UserInline, Button, BtnType } from "../../primitives";
import AreaOfInterestComponent from "./areaOfInterest";
import { MyProjects } from "../../shared";
import { checkViewerRole } from "../../../common/checkers/viewerChecker";
import { Icon, lgSize } from "../../../common/icon";
import NewDiscussion from "../../../common/components/newDiscussion/newDiscussion.component";
import DiscussionDetails from "../../../common/components/discussionDetails";
import { getUpdatedMember } from "./selectors/people.selectors";
import {
  privateDiscussionsGet,
  setSelectedDiscussion,
} from "../../../common/actions/discussions.action";
import { useModelPopup } from "../../../common/hooks";
import { el } from "date-fns/locale";

const ProfileComponent = ({ member }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const popup = useModelPopup();
  const {
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
    authReducer: { session },
    peopleReducer: {
      internalPeopleReducer: { selectedMember, peopleLoading },
      peopleProfileReducer: { memberNotes },
    },
    discussionsReducer: {
      privateDiscussions,
      selectedDiscussion,
      updateDiscussions,
    },
  } = useSelector((state) => state);
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [showDetailsWindow, setShowDetailsWindow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(peopleMembersGet({ memberId: id, enqueueSnackbar }));
  }, [id]);

  useEffect(() => {
    if (!selectedCouncil) {
      return;
    }

    if (!privateDiscussions) {
      dispatch(
        privateDiscussionsGet({ id: selectedCouncil.id, enqueueSnackbar })
      );
    }

    return () => null;
  }, [selectedCouncil]);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (updateDiscussions) {
      dispatch(
        privateDiscussionsGet({ id: selectedCouncil.id, enqueueSnackbar })
      );
    }
  }, [updateDiscussions]);

  useEffect(() => {
    if (selectedMember) {
      dispatch(
        peopleListMemberNotesGet({
          memberId: selectedMember.id,
          enqueueSnackbar,
          currentPage: 1,
        })
      );
    }
  }, [selectedMember]);

  const startNewDiscussion = () => {
    const customSettings = {
      title: "Start a New Private Discussion",
      show: true,
      height: "300",
      width: "540",
      component: (
        <NewDiscussion
          type="Council"
          isPrivate
          hideTeam
          selectedUser={selectedMember}
        />
      ),
    };
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  const handleShow = () => {
    if (!member.discussion) {
      startNewDiscussion();
      return;
    }

    dispatch(
      setSelectedDiscussion({
        ...member.discussion,
        isPrivate: true,
        prop: "privateDiscussions",
      })
    );
    setShowDetailsWindow(true);
  };

  const handleClose = () => {
    setShowDetailsWindow(false);
    dispatch(setSelectedDiscussion({}));
  };

  useEffect(
    () => () => {
      dispatch(resetPeopleLoading());
      dispatch(resetProjectsLoading());
    },
    []
  );

  const hasAccess = (el) =>
    session?.council_role === "ttp_administrator" ||
    session?.council_role === "council_administrator" ||
    session?.id === el.creator_id;

  const handleAddClick = () => {
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        title: "Capture New Activity",
        show: true,
        height: String(window.innerHeight),
        width: String(window.innerWidth / 1.5),
        component: (
          <NewEventComponent
            notableId={selectedMember.id}
            notableEmail={selectedMember.email}
            notableType="User"
            activitiesTypes={["Note", "Meeting", "Email"]}
          />
        ),
      })
    );
  };

  const handleDeleteClick = (initObject) => {
    const resp = window.confirm(
      "You are about to delete this item and its data permanently. Are you sure? "
    );
    if (resp) {
      dispatch(
        peopleDeleteMemberNote({
          memberId: selectedMember.id,
          noteId: initObject.custom_activity_id,
        })
      );
      popup.hide();
    }
  };

  const handleEditClick = (item) => {
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        title: "Edit",
        show: true,
        height: String(window.innerHeight),
        width: String(window.innerWidth / 1.5),
        component: (
          <NewEventComponent
            data={item}
            handleDeleteClick={handleDeleteClick}
            type={item?.type}
            notableId={selectedMember.id}
            notableEmail={selectedMember.email}
            notableType="User"
            activitiesTypes={["Note", "Meeting", "Email"]}
          />
        ),
      })
    );
  };
  const getPlantAndAreasLabels = (arr) => {
    return (
      <>
        {arr.map((plant, i) => {
          return (
            <span key={plant.name}>
              {" "}
              {plant.name}
              {arr.length - 1 === i ? "" : ", "}
            </span>
          );
        })}
      </>
    );
  };

  const getPlantsRegions = useMemo(() => {
    if (!member?.plants?.length) {
      return [];
    }

    const flattenRegions = member?.plants.map((p) => p.region);

    return [...new Set(flattenRegions)];
  }, [member?.plants]);

  return (
    <div>
      {peopleLoading ? (
        <div className={styles.contentWrp}>
          <div className="row m-0">
            <div className={styles.skeletonGrid}>
              <Skeleton height={450} />
            </div>

            <Grid container className={styles.projectDetailsWrp}>
              <Grid item xs={12} md={7}>
                <div className={styles.skeletonBlock}>
                  <Skeleton height={20} />
                  <div className={styles.skeletonTextWithMargin}>
                    <Skeleton height={550} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={1} />
              {/* </div> */}
              <Grid item xs={12} md={4} className={styles.areaOfInterestWrp}>
                <div className={styles.skeletonBlock}>
                  <Skeleton height={20} />
                  <div className={styles.skeletonText}>
                    <Skeleton height={350} />
                  </div>
                </div>{" "}
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <div className={styles.contentWrp}>
          {showDetailsWindow && (
            <DiscussionDetails
              handleClose={handleClose}
              details={selectedDiscussion}
            />
          )}
          <ReactToPdf>
            {({ toPdf, targetRef }) => (
              <div ref={targetRef} className={styles.preWrapper}>
                <div className={styles.container}>
                  {selectedMember && (
                    <>
                      <div className={styles.memberImageWrp}>
                        <UserInline
                          userId={selectedMember.id}
                          type={UserInlineTypes.IMAGE_ONLY}
                          className={styles.memberImageCon}
                          first={selectedMember.first_name}
                          last={selectedMember.last_name}
                          avatar={selectedMember.avatar}
                          local
                        />
                      </div>
                      <h4 className={styles.memberName}>
                        {`${selectedMember.first_name} ${selectedMember.last_name}`}
                      </h4>
                      {selectedCouncil?.name === "Kyndryl" &&
                        selectedMember.approved_board_member && (
                          <span className={styles.approvedMember}>
                            Approved Board Member &#10003;
                          </span>
                        )}
                      <div className={classNames("caption", styles.title)}>
                        {selectedMember.title}
                      </div>
                      {selectedMember.department && (
                        <div className={classNames("caption", styles.title)}>
                          Department: {selectedMember.department}
                        </div>
                      )}
                      <div className={classNames("caption", styles.company)}>
                        {selectedMember.organization}
                      </div>
                      {selectedMember?.themes?.length ? (
                        <div className={classNames("caption", styles.title)}>
                          {selectedCouncil?.settings
                            ?.themes_display_name_singular || "Theme"}
                          :{" "}
                          {selectedMember?.themes
                            ?.map((el) => el.name)
                            .join(", ")}
                        </div>
                      ) : null}
                      {getPlantsRegions.length ? (
                        <div className={classNames("caption", styles.title)}>
                          Region{getPlantsRegions.length === 1 ? "" : "s"}:{" "}
                          {getPlantsRegions.map((region, i) => (
                            <span key={`${region}-${i}`}>
                              {" "}
                              {region}
                              {getPlantsRegions.length - 1 === i ? "" : ", "}
                            </span>
                          ))}
                        </div>
                      ) : null}
                      {member?.plants?.length ? (
                        <div className={classNames("caption", styles.title)}>
                          Plants: {getPlantAndAreasLabels(member?.plants)}
                        </div>
                      ) : null}
                      {member?.areas?.length ? (
                        <div className={classNames("caption", styles.title)}>
                          Areas: {getPlantAndAreasLabels(member?.areas)}
                        </div>
                      ) : null}

                      <div className={styles.socialMediaWrp}>
                        <Icon
                          {...lgSize}
                          icon="icn-table-message"
                          className={styles.messageIcon}
                          onClick={handleShow}
                        />
                      </div>
                      <div className={classNames("caption", styles.details)}>
                        {selectedMember.bio}
                      </div>
                    </>
                  )}
                </div>

                <Grid container className={styles.projectDetailsWrp}>
                  <Grid item xs={12} md={7}>
                    {
                      session && selectedCouncil && (
                        <MyProjects
                          user={selectedMember}
                          selectedCouncil={selectedCouncil}
                          session={session}
                          mode="user_projects"
                        />
                      )
                    }
                  </Grid>
                  <Grid item xs={12} md={1} />
                  <Grid
                    item
                    xs={12}
                    md={4}
                    className={styles.areaOfInterestWrp}
                  >
                    <AreaOfInterestComponent member={member} />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <section className={styles.myProjects}>
                      <div className={styles.titleWrp}>
                        <h4>Notes</h4>
                        {!blockFunctionality ? (
                          <div className="d-flex justify-content-end align-items-center">
                            <Button
                              btn={BtnType.FRAME_LESS}
                              icon="icn-add"
                              onClick={handleAddClick}
                            >
                              Add Note
                            </Button>
                          </div>
                        ) : (
                          <Tooltip
                            title="You have View-Only access. To Add a Note, please ask your Admin to upgrade your account to Standard access."
                            placement="top"
                          >
                            <div className={styles.button}>
                              <Button
                                btn={BtnType.DISABLED}
                                className={styles.button}
                                icon="icn-add"
                              >
                                Add Note
                              </Button>
                            </div>
                          </Tooltip>
                        )}
                      </div>
                      <div className={styles.notesWrapper}>
                        {memberNotes.length === 0 && (
                          <div className="text-center p-5">Add a Note</div>
                        )}
                        {memberNotes.map((el, i) => {
                          const isLastElem = memberNotes.length === i + 1;
                          const isAddedByMe = session?.id === el?.creator_id;

                          if (el.type === "Note") {
                            return (
                              <NoteComponent
                                key={el.custom_activity_id}
                                note={el}
                                hasAccess={hasAccess(el)}
                                handleEditClick={handleEditClick}
                                handleDeleteClick={handleDeleteClick}
                                blockFunctionality={blockFunctionality}
                                isAddedByMe={isAddedByMe}
                                isLastElem={isLastElem}
                              />
                            );
                          }
                          if (el.type === "Meeting") {
                            return (
                              <MeetingComponent
                                key={el.custom_activity_id}
                                meeting={el}
                                hasAccess={hasAccess(el)}
                                handleEditClick={handleEditClick}
                                handleDeleteClick={handleDeleteClick}
                                blockFunctionality={blockFunctionality}
                                isAddedByMe={isAddedByMe}
                                isLastElem={isLastElem}
                              />
                            );
                          }
                          if (el.type === "Email") {
                            return (
                              <EmailComponent
                                key={el.custom_activity_id}
                                email={el}
                                hasAccess={hasAccess(el)}
                                handleEditClick={handleEditClick}
                                handleDeleteClick={handleDeleteClick}
                                blockFunctionality={blockFunctionality}
                                isAddedByMe={isAddedByMe}
                                isLastElem={isLastElem}
                              />
                            );
                          }

                          return <div key={el.custom_activity_id} />;
                        })}
                      </div>
                    </section>
                  </Grid>
                </Grid>
              </div>
            )}
          </ReactToPdf>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  member: getUpdatedMember(state, props),
});

const ProfileComponentConnect = connect(mapStateToProps)(ProfileComponent);

export default React.memo(ProfileComponentConnect);

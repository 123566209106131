import {
  IS_FEED_OPENED,
  GET_FEED_ATTEMPT,
  GET_FEED_SUCCESS,
  LIKE_POST_SUCCESS,
  DISLIKE_POST_SUCCESS,
  THEME_POST_ADD_SUCCESS,
  THEME_POST_DELETE_ATTEMPT,
  THEME_POST_DELETE_SUCCESS,
  THEME_POST_ADD_ATTEMPT,
  THEME_POST_EDIT_ATTEMPT,
  THEME_POST_EDIT_SUCCESS,
  THEME_POSTS_GET_ATTEMPT,
  THEME_POSTS_GET_SUCCESS,
  SELECT_POST_AS_FAVORITE_SUCCESS,
  DESELECT_POST_AS_FAVORITE_SUCCESS,
  THEME_ACROSS_POSTS_GET_ATTEMPT,
  THEME_ACROSS_POSTS_GET_SUCCESS,
  DESELECT_POST_AS_FAVORITE_ATTEMPT,
  LIKE_POST_ATTEMPT,
  DISLIKE_POST_ATTEMPT,
  SELECT_POST_AS_FAVORITE_ATTEMPT,
  THEME_POSTS_MEMBERS_GET_ATTEMPT,
  THEME_POSTS_MEMBERS_GET_SUCCESS,
  THEME_POSTS_ACCROSS_MEMBERS_GET_SUCCESS,
  THEME_POSTS_ACCROSS_MEMBERS_GET_ATTEMPT,
  SEARCH_MENTIONS_GET_ATTEMPT,
  SEARCH_MENTIONS_GET_SUCCESS,
} from "./postsBlock.action";

const initState = {
  isFeedOpened: false,
  feedLoading: false,
  shouldUpdateFeed: false,
  postsList: [],
  postRepliesList: [],
  feed: [],
  updatePostsAcross: false,
  likePending: false,
  favPending: false,
  postsMembers: [],
  mentions: [],
  loadingMentions: false,
};

const themesPostsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case IS_FEED_OPENED:
      return {
        ...state,
        isFeedOpened: payload,
      };
    case GET_FEED_ATTEMPT:
      return {
        ...state,
        feedLoading: true,
      };
    case GET_FEED_SUCCESS:
      return {
        ...state,
        feedLoading: false,
        feed: payload?.response,
        shouldUpdateFeed: false,
      };
    case LIKE_POST_SUCCESS:
      return {
        ...state,
        likePending: false,
        postsList: state.postsList?.map((post) => {
          if (post.theme_post_id === payload.response.theme_post_id) {
            return payload.response;
          }
          return post;
        }),
      };
    case DISLIKE_POST_SUCCESS:
      return {
        ...state,
        likePending: false,
        postsList: state.postsList?.map((post) => {
          if (post.theme_post_id === payload.response.theme_post_id) {
            return payload.response;
          }
          return post;
        }),
      };
    case SELECT_POST_AS_FAVORITE_SUCCESS:
      return {
        ...state,
        favPending: false,
        postsList: state.postsList?.map((post) => {
          if (post?.theme_post_id === payload?.response?.theme_post_id) {
            return payload.response;
          }
          return post;
        }),
      };
    case DESELECT_POST_AS_FAVORITE_SUCCESS:
      return {
        ...state,
        favPending: false,
        postsList: state.postsList?.map((post) => {
          if (post?.theme_post_id === payload?.response?.theme_post_id) {
            return payload.response;
          }
          return post;
        }),
      };
    case THEME_POSTS_GET_ATTEMPT:
      return {
        ...state,
        loadingPosts: true,
      };
    case THEME_POSTS_GET_SUCCESS:
      return {
        ...state,
        postsList: payload?.response,
        loadingPosts: false,
        shouldUpdatePosts: false,
      };
    case THEME_POST_EDIT_ATTEMPT:
      return {
        ...state,
        loadingPosts: true,
      };
    case THEME_POST_EDIT_SUCCESS:
      return {
        ...state,
        postsList: payload.response,
        loadingPosts: false,
      };
    case THEME_POST_DELETE_ATTEMPT:
      return {
        ...state,
        loadingPosts: true,
      };
    case THEME_POST_DELETE_SUCCESS:
      return {
        ...state,
        loadingPosts: false,
        postsList: payload?.response,
        // shouldUpdatePosts: true,
      };
    case THEME_POST_ADD_ATTEMPT:
      return {
        ...state,
        loadingPosts: true,
      };
    case THEME_POST_ADD_SUCCESS:
      return {
        ...state,
        postsList: payload.response,
        loadingPosts: false,
        updatePostsAcross: true,
      };
    case THEME_ACROSS_POSTS_GET_ATTEMPT:
      return {
        ...state,
        loadingPosts: true,
      };
    case THEME_ACROSS_POSTS_GET_SUCCESS:
      return {
        ...state,
        postsList: payload.response,
        loadingPosts: false,
        updatePostsAcross: false,
      };
    case SELECT_POST_AS_FAVORITE_ATTEMPT:
      return {
        ...state,
        favPending: true,
      };
    case DESELECT_POST_AS_FAVORITE_ATTEMPT:
      return {
        ...state,
        favPending: true,
      };
    case LIKE_POST_ATTEMPT:
      return {
        ...state,
        likePending: true,
      };
    case DISLIKE_POST_ATTEMPT:
      return {
        ...state,
        likePending: true,
      };
    case THEME_POSTS_MEMBERS_GET_ATTEMPT:
      return {
        ...state,
      };
    case THEME_POSTS_MEMBERS_GET_SUCCESS:
      return {
        ...state,
        postsMembers: payload.response,
      };
    case THEME_POSTS_ACCROSS_MEMBERS_GET_ATTEMPT:
      return {
        ...state,
      };
    case THEME_POSTS_ACCROSS_MEMBERS_GET_SUCCESS:
      return {
        ...state,
        postsMembers: payload.response,
      };
    case SEARCH_MENTIONS_GET_ATTEMPT:
      return {
        ...state,
        loadingMentions: true,
      };
    case SEARCH_MENTIONS_GET_SUCCESS:
      return {
        ...state,
        mentions: payload.response,
        loadingMentions: false,
      };
    default:
      return state;
  }
};
export default themesPostsReducer;

export const isProjectAllowedEditor = (councilRole, currentUserProjRole) => {
  if (["owner", "manager"].includes(currentUserProjRole)) {
    return true;
  }
  if (["ttp_administrator", "council_administrator"].includes(councilRole)) {
    return true;
  }

  return false;
};

export const WS_AI_SET_CONNECTION_STATUS = "WS_AI_SET_CONNECTION_STATUS";
export const WSAISetConnectionStatus = (payload) => ({
  type: WS_AI_SET_CONNECTION_STATUS,
  payload,
});

export const AI_SELECT_SCREEN = "AI_SELECT_SCREEN";
export const AISelectScreen = (payload) => ({
  type: AI_SELECT_SCREEN,
  payload,
});

export const AI_SET_SWOT_DATA = "AI_SET_SWOT_DATA";
export const AISetSWOTData = (payload) => ({
  type: AI_SET_SWOT_DATA,
  payload,
});

export const AI_SET_TREND_DATA = "AI_SET_TREND_DATA";
export const AISetTrendData = (payload) => ({
  type: AI_SET_TREND_DATA,
  payload,
});

export const AI_SET_COMPANY_SNAPSHOT_DATA = "AI_SET_COMPANY_SNAPSHOT_DATA";
export const AISetCompanySnapshotData = (payload) => ({
  type: AI_SET_COMPANY_SNAPSHOT_DATA,
  payload,
});

export const AI_SET_RECOMMEND_DATA = "AI_SET_RECOMMEND_DATA";
export const AISetRecommendData = (payload) => ({
  type: AI_SET_RECOMMEND_DATA,
  payload,
});

export const AI_SET_SCOUT_DATA = "AI_SET_SCOUT_DATA";
export const AISetScoutData = (payload) => ({
  type: AI_SET_SCOUT_DATA,
  payload,
});

export const AI_UPDATE_SCOUT_COMPANY = "AI_UPDATE_SCOUT_COMPANY";
export const AIUpdateScoutCompany = (payload) => ({
  type: AI_UPDATE_SCOUT_COMPANY,
  payload,
});

export const AI_LOAD_MORE_SCOUT_DATA = "AI_LOAD_MORE_SCOUT_DATA";
export const AILoadMoreScoutData = (payload) => ({
  type: AI_LOAD_MORE_SCOUT_DATA,
  payload,
});

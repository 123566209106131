import React from "react";
import { useSelector } from "react-redux";
import DialogActions from "@mui/material/DialogActions";

import TractionAISwotFooter from "modules/tractionAI/screens/insights/swot/swotFooter";
import TractionAITrendFooter from "modules/tractionAI/screens/insights/trendReport/trendFooter";
import TractionAIScoutFooter from "modules/tractionAI/screens/scout/scoutFooter";
import TractionAICompanySnapshotFooter from "modules/tractionAI/screens/insights/companySnapshot/companySnapshotFooter";
import TractionAIRecommendProjectsFooter from "modules/tractionAI/screens/recommend/projects/recommendProjectsFooter";
import TractionAIRecommendIdeasFooter from "modules/tractionAI/screens/recommend/ideas/recommendIdeasFooter";

const AIModalFooter = () => {
  const {
    tractionAiReducer: { activeScreen, activeSubScreen }
  } = useSelector((state) => state);

  return (
    <DialogActions>
      {
        activeScreen === 'insight' && (
          <>
            {
              activeSubScreen === 'swot' && (
                <TractionAISwotFooter />
              )
            }
            {
              activeSubScreen === 'trendReport' && (
                <TractionAITrendFooter />
              )
            }
            {
              activeSubScreen === 'companySnapshot' && (
                <TractionAICompanySnapshotFooter />
              )
            }
          </>
        )
      }
      {
        activeScreen === 'scout' && (
          <TractionAIScoutFooter />
        )
      }
      {
        activeScreen === 'recommend' && (
          <>
            {
              activeSubScreen === 'projects' && (
                <TractionAIRecommendProjectsFooter />
              )
            }
            {
              activeSubScreen === 'ideas' && (
                <TractionAIRecommendIdeasFooter />
              )
            }
          </>
        )
      }
    </DialogActions>
  )
}

export default React.memo(AIModalFooter);

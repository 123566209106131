import React, { useRef, useState, useEffect } from "react";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckBox,
  CheckType,
  Label,
  TextBox,
  Button,
  BtnType,
  DropSelect,
} from "modules/primitives/index";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import RatingTemplateModal from "modules/projects/project/manageProject/details/ratingTemplateModal.component";
import { ratingsTemplateGetAttempt } from "modules/admin/adminRating/adminRating.action";
import styles from "./addProjectTemplate.module.scss";
import { initModel, validation } from "./addProjectTemplate.constants";
import { ModalBody, ModalFooter } from "../../../../application/modal";
import { useModelPopup } from "../../../../common/hooks";
import {
  postStageTemplateAttempt,
  patchStageTemplateByIdAttempt,
} from "../adminProjects.actions";

const AddProjectTemplateComponent = (props) => {
  const { mode, data, projects } = props;

  const {
    councilReducer: { selectedCouncil },
    adminProjectsReducer: { stageTemplates },
    adminRatingTemplatesReducer: { ratingTemplates },
  } = useSelector((state) => state);

  const formRef = useRef();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [default_template, setdefault_template] = useState(
    mode === "edit" ? data?.default_template : false
  );
  const [patient_org_task, setTempAsPO] = useState(
    mode === "edit" ? data?.patient_organization : false
  );
  const [patient_org_default_temp, setDefaultPOTemp] = useState(
    mode === "edit" ? data?.default_patient_organization : false
  );
  const [automate_due_dates, setautomate_due_dates] = useState(
    mode === "edit" ? data?.automate_due_dates : false
  );
  const [ratingEnabled, setRatingEnabled] = useState(
    mode === "edit" ? data?.rating_enabled : false
  );
  const [coincidenceError, setCoincidenceError] = useState("");
  const [allreadySelectDefault, setAllreadySelectedDefault] = useState(false);
  const [allreadySelectPODefault, setAllreadySelectedPODefault] =
    useState(false);

  const [selectedRatingsTemplate, setSelectedRatingsTemplate] = useState({});
  const [enableProjectRatings, setEnableProjectRatings] =
    useState(ratingEnabled);
  const [templatePosition, setTemplatePosition] = useState(0);

  const handleDismiss = () => {
    popup.hide();
  };
  const handleMultiCheckClick = (value) => {
    setdefault_template(value);
    setTempAsPO(false);
    setDefaultPOTemp(false);
  };
  const handleRatingEnabledClick = (value) => {
    setRatingEnabled(value);
  };
  const handleMultiCheckDueClick = (value) => {
    setautomate_due_dates(value);
  };

  const handlePoTask = (value) => {
    setTempAsPO(value);
    setdefault_template(false);
  };

  const handlePoDefaultTemplate = (value) => {
    setDefaultPOTemp(value);
    if (value) {
      setdefault_template(false);
      setTempAsPO(true);
    }
  };
  const autoSelectValidStagePosition = (usedPositions) => {
    let freePosition;
    for (let i = 0; i <= usedPositions.length; i++) {
      if (i + 1 !== usedPositions[i]) {
        // setStagePosition(i + 1);
        freePosition = i + 1;
        break;
      }
    }
    return freePosition;
  };
  const enableProjectRatingsCallBack = () => {
    setEnableProjectRatings(!enableProjectRatings);
  };

  const handleSelectRatingsTemplate = (e) => {
    setSelectedRatingsTemplate(e.obj[0]);
  };

  const handleViewTemplate = (source) => {
    popup.show({
      title: source.name,
      height: "300",
      width: "1000",
      component: (
        <RatingTemplateModal
          selectedTemplateCategories={source?.rating_categories}
        />
      ),
    });
  };

  useEffect(() => {
    if (ratingTemplates.length === 0) {
      dispatch(ratingsTemplateGetAttempt({ enqueueSnackbar }));
    }
  }, []);

  useEffect(() => {
    const selectedR = ratingTemplates.filter(
      (e) => e.rating_template_id === data?.rating_template_id
    );
    setSelectedRatingsTemplate(selectedR[0]);
  }, [ratingTemplates, data]);

  useEffect(() => {
    const positions = stageTemplates
      ?.map((templ) => templ?.sort_order)
      .sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
      });
    if (mode === "edit") {
      setTemplatePosition(data?.sort_order);
    } else {
      setTemplatePosition(autoSelectValidStagePosition(positions));
    }
  }, [stageTemplates]);

  // useEffect(() => {
  //   if (projects?.length) {
  //     const existsDefault = projects.find(
  //       (project) => project?.default_template
  //     );
  //
  //     if (existsDefault && data.id !== existsDefault.id) {
  //       setAllreadySelectedDefault(true);
  //     } else {
  //       setAllreadySelectedDefault(false);
  //     }
  //
  //     // checking for default in PO templates
  //     const existsPODefault = projects.find(
  //       (project) => project?.default_patient_organization
  //     );
  //
  //     if (existsPODefault && data.id !== existsPODefault.id) {
  //       setAllreadySelectedPODefault(true);
  //     } else {
  //       setAllreadySelectedPODefault(false);
  //     }
  //   }
  // }, [projects]);

  const validateName = (templateName) => {
    const isCoincidence = stageTemplates.find((template) => {
      if (template?.id === data?.id && mode === "edit") {
        return false;
      }
      if (template.name.toLowerCase() === templateName.toLowerCase()) {
        return true;
      }
    });
    return isCoincidence?.name;
  };

  const onSubmit = (values, resetForm) => {
    const { name, description, sort_order } = values;

    if (validateName(name)) {
      setCoincidenceError("Template with such name already exists.");
      return;
    }

    setCoincidenceError("");
    const submitData = {
      name,
      description,
      resource_type: "project",
      sort_order: sort_order ? parseInt(sort_order) : templatePosition,
      enqueueSnackbar,
      default_template,
      automate_due_dates,
      id: data?.id,
      patient_organization: patient_org_task,
      default_patient_organization: patient_org_default_temp,
      rating_enabled: ratingEnabled,
      rating_template_id: selectedRatingsTemplate?.rating_template_id,
    };

    if (mode === "edit") {
      dispatch(patchStageTemplateByIdAttempt(submitData));
    } else {
      dispatch(postStageTemplateAttempt(submitData));
    }
    resetForm();
    popup.hide();
  };

  const editModel = {
    name: data?.name,
    description: data?.description,
    default_template: data?.default_template,
    automate_due_dates: data?.automate_due_dates,
    patient_organization: data?.patient_organization,
    default_patient_organization: data?.default_patient_organization,
    rating_enabled: data?.rating_enabled,
    rating_template_id: data?.rating_template_id,
    rating_template_name: selectedRatingsTemplate?.name,
    sort_order: data?.sort_order,
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={mode === "edit" ? editModel : initModel}
      validationSchema={validation}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        onSubmit(values, resetForm);
      }}
    >
      {({ handleSubmit, ...formikprops }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className={styles.instructionText}>
              This template will be available for all projects.
            </div>
            <Label>Template Name</Label>
            <TextBox
              type="text"
              name="name"
              placeholder="Add template name"
              formProps={formikprops}
              className={styles.textBox}
            />
            {coincidenceError && (
              <span className={styles.errorMessage}>
                <sup>*</sup>
                {coincidenceError}
              </span>
            )}
            <Label>Template Description (optional)</Label>
            <TextBox
              type="text"
              name="description"
              placeholder="Add description"
              formProps={formikprops}
              className={styles.textBox}
            />
            {mode === "edit" && (
              <div className={styles.checkbox_container}>
                {allreadySelectDefault ? (
                  <Tooltip
                    title="Another Project template is already selected as the default. Please de-select that one first to select this one as the default."
                    placement="top"
                  >
                    <div style={{ display: "flex" }}>
                      <CheckBox
                        checkType={CheckType.DISABLED}
                        className={styles.disabledCheckBox}
                        disabled
                      />
                      <Label>
                        {selectedCouncil?.name === "GSK"
                          ? "Set as default for Company projects"
                          : "Set as default"}
                      </Label>
                    </div>
                  </Tooltip>
                ) : (
                  <>
                    <CheckBox
                      checkType={CheckType.BLUE}
                      isChecked={default_template}
                      onChange={(value) =>
                        handleMultiCheckClick(value, formikprops)
                      }
                    />
                    <Label>
                      {selectedCouncil?.name === "GSK"
                        ? "Set as default for Company projects"
                        : "Set as default"}
                    </Label>
                  </>
                )}
              </div>
            )}

            <div>
              <Label>Position</Label>
              <TextBox
                type="number"
                name="sort_order"
                placeholder="Set position"
                formProps={formikprops}
                className={styles.textBox}
                min={1}
                max={1000}
                value={
                  mode === "edit" ? editModel.sort_order : templatePosition
                }
              />
            </div>

            {/* will be used when multimple patient org types will be added */}
            {mode === "edit" && selectedCouncil?.name === "GSK" && (
              <div className={styles.checkbox_container}>
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={patient_org_task}
                  onChange={(value) => handlePoTask(value, formikprops)}
                />
                <Label>Patient Organization project</Label>
              </div>
            )}
            {mode === "edit" && selectedCouncil?.name === "GSK" && (
              <>
                {allreadySelectPODefault ? (
                  <Tooltip
                    title="Another Project template for Patient Organizations is already selected as the default. Please de-select that one first to select this one as the default."
                    placement="top"
                  >
                    <div className={styles.checkbox_container}>
                      <CheckBox
                        checkType={CheckType.DISABLED}
                        disabled
                        className={styles.disabledCheckBox}
                      />
                      <Label>
                        Set as default for Patient Organization projects
                      </Label>
                    </div>
                  </Tooltip>
                ) : (
                  <div className={styles.checkbox_container}>
                    <CheckBox
                      checkType={CheckType.BLUE}
                      isChecked={patient_org_default_temp}
                      onChange={(value) =>
                        handlePoDefaultTemplate(value, formikprops)
                      }
                    />
                    <Label>
                      Set as default for Patient Organization projects
                    </Label>
                  </div>
                )}
              </>
            )}
            {mode === "edit" && (
              <div className={styles.checkbox_container}>
                <div className="col-12 pl-0">
                  <div className="d-flex">
                    <CheckBox
                      checkType={CheckType.BLUE}
                      isChecked={ratingEnabled}
                      onChange={(value) =>
                        handleRatingEnabledClick(value, formikprops)
                      }
                    />
                    <span
                      className="cursor-pointer"
                      onClick={enableProjectRatingsCallBack}
                    >
                      {" "}
                      Enable Project Ratings
                    </span>
                  </div>
                  <div>
                    {ratingEnabled && (
                      <div>
                        <Label>Project Ratings Template</Label>
                        <DropSelect
                          SelecedValues={selectedRatingsTemplate?.name}
                          onChange={handleSelectRatingsTemplate}
                          placeholder="Template"
                          labelField="name"
                          valueField="name"
                          options={ratingTemplates.filter(
                            (template) => template.template_type === "project"
                          )}
                        />
                        {selectedRatingsTemplate?.name ? (
                          <div className={styles.viewTemplateLink}>
                            <span
                              onClick={() => {
                                handleViewTemplate(selectedRatingsTemplate);
                              }}
                            >
                              View Template
                            </span>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {mode === "edit" && (
              <div className={styles.checkbox_container}>
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={editModel.automate_due_dates}
                  onChange={(value) =>
                    handleMultiCheckDueClick(value, formikprops)
                  }
                />
                <Label>Automate due dates</Label>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
              Cancel
            </Button>
            <Button
              type="submit"
              btn={BtnType.HIGHLIGHT}
              className={styles.button}
            >
              {mode === "edit" ? "Save" : "Add"}
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default AddProjectTemplateComponent;

import { apiStatus, pageMeta } from "../../common/httpCall";
import {
  GET_USER_TEAMS_ATTEMPT,
  GET_USER_TEAMS_SUCCESS,
  GET_ALL_TEAMS_ATTEMPT,
  GET_ALL_TEAMS_SUCCESS,
} from "./teams.action";

const initState = {
  userTeams: [],
  userTeamsPageNr: {},
  userTeamsLoading: false,
  allTeams: [],
  allTeamsLoading: false,
};

const teamsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_TEAMS_ATTEMPT: {
      return {
        ...state,
        userTeamsLoading: true,
      };
    }

    case GET_USER_TEAMS_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          userTeams: response,
          userTeamsLoading: false,
          userTeamsPageNr: pageMeta(payload),
        };
      }
      break;
    }

    case GET_ALL_TEAMS_ATTEMPT: {
      return {
        ...state,
        allTeamsLoading: true,
      };
    }

    case GET_ALL_TEAMS_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          allTeams: response,
          allTeamsLoading: false,
        };
      }
      break;
    }

    default:
      return state;
  }
  return state;
};

export default teamsReducer;

import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";

import styles from '../../../tractionAI.module.scss';
import { convertToHTML, copyToClipBoardFromRef } from "modules/tractionAI/utils";
import TrendSlidesModal from "modules/tractionAI/screens/insights/trendReport/trendSlidesModal";
import { AISetTrendData } from "modules/tractionAI/store/tractionAI.actions";
import { httpPost } from "../../../../../common/httpCall";
import { BtnType, Button } from "modules/primitives";
import ItemErrorHandler from "modules/tractionAI/components/itemErrorHandler";

const TrendItemResponse = ({ item, enqueueSnackbar, index, goToNewScreen }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();

  const handleCopy = useCallback(() => {
    if (!ref?.current) return;

    copyToClipBoardFromRef(ref, enqueueSnackbar);
  }, [ref, enqueueSnackbar]);

  const handleHtmlConvert = useCallback(() => {
    return convertToHTML(item.data);
  }, [item.data]);

  const hasFinalAIAnswer = useMemo(() => {
    if (!item.fetched) return false;

    return item.data.includes('Current Trends');
  }, [item.fetched, item.data]);

  const handleMakeNewPrompt = (prompt) => {
    dispatch(AISetTrendData({
      fetching: true,
      searchByQuery: prompt,
      createNewSearchItem: true,
      fetched: false,
    }));

    httpPost({
      call: "ai/proxy/streaming?formatted=true",
      data: { prompt, endpoint: "trend_analysis" }
    }).subscribe();
  };

  useEffect(() => {
    if (hasFinalAIAnswer && ref?.current) {
      const elementsWithId = Array.from(ref.current.children).filter(
        (child) => child.id
      );

      elementsWithId.forEach((element) => {
        const ulElement = element.querySelector("ul");

        if (ulElement) {
          Array.from(ulElement.children).forEach((li) => {
            const liText = li.textContent;
            const span = document.createElement("span");

            span.innerHTML = "&#10148;";
            span.title = "Dive Deeper";
            span.alt = "Dive Deeper";
            span.classList.add("cursor-pointer", "ml-3", "small", "custom-color", "p-2");

            span.addEventListener("click", () => {
              console.log(`Button ${index + 1} clicked inside ${element.id}`, liText);
              if (liText) {
                handleMakeNewPrompt(`${item.searchByQuery}: ${liText}`);
              }
            });

            li.appendChild(span);
          });
        }
      });
    }
  }, [hasFinalAIAnswer, ref?.current, handleMakeNewPrompt]);

  return (
    <div className="d-flex flex-column justify-content-end pr-2">
      <style>
        {`
          .custom-color {
            color: #3994c1;
          }
        `}
      </style>
      <div className="d-flex aling-items-end justify-content-end">
        <div className={styles.searchByQuery}>{item.searchByQuery}</div>
      </div>
      <div
        ref={ref}
        dangerouslySetInnerHTML={{ __html: handleHtmlConvert() }}
      ></div>
      <ItemErrorHandler item={item} />
      {hasFinalAIAnswer && (item.fetched || item.doNotAnimate) && !item.hasError && (
        <div className="d-flex mt-2">
          <div className="d-flex">
            <Tooltip
              title="Copy"
              placement="top"
            >
              <div className="cursor-pointer" onClick={handleCopy}>
                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                    stroke="#1C274C" strokeWidth="1.5" />
                  <path
                    d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                    stroke="#1C274C" strokeWidth="1.5" />
                </svg>
              </div>
            </Tooltip>
            <TrendSlidesModal item={item} index={index} responseRef={ref} />
          </div>
          <div className="d-flex align-items-center ml-5">
            <Button
              btn={BtnType.LINK}
              onClick={() => goToNewScreen('scout', '', item.searchByQuery)}
            >Recommend Companies</Button>
            <Button
              btn={BtnType.LINK}
              onClick={() => goToNewScreen('insight', 'swot', item.searchByQuery)}
              className="ml-4"
            >SWOT Report</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(TrendItemResponse);

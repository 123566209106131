import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import { ModalFooter, ModalBody } from "../../../../application/modal";
import {
  Button, BtnType, Label, DropSelect,
} from "../../../primitives";
import styles from "./addToIdea.module.scss";
import { useModelPopup } from "../../../../common/hooks";
import {
  ideaCompaniesAddAttempt,
  ideasListGet,
} from "modules/ideasWrapper/idea.action";
import { companyGet } from "../../company/company.action";
import LoadingComponent from "modules/primitives/loading/loading.component";

const AddToIdeaComponent = ({
  enqueueSnackbar,
  company,
  selectedCouncil,
  selectedCompanies,
  fromCompany,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [selected, setSelected] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ideas, setIdeas] = useState([]);

  useEffect(() => {
    dispatch(
      ideasListGet({
        items: 1000,
        page: 1,
        enqueueSnackbar,
        isDropdown: true,
        cb: (res) => setIdeas(res),
      })
    );
  }, []);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = () => {
    if (!selected) {
      enqueueSnackbar("Please select an idea", {
        variant: "error",
      });

      return;
    }

    const related_companies = company
      ? [
          {
            company_id: company.id,
            idea_id: selected.val,
          },
        ]
      : selectedCompanies.map((c) => ({
          company_id: c.id,
          idea_id: selected.val,
        }));

    setIsSubmitting(true);

    const payload = {
      related_companies,
      cb: () => {
        enqueueSnackbar(`Successfully added ${company ? "company" : "companies"} to Idea`, {
          variant: "success",
        });

        setIsSubmitting(false);
        popup.hide();

        if (selectedCompanies) {
          history.push(`/ideas/${selected.val}`);
        }

        if (company) {
          dispatch(
            companyGet({
              id: company.id,
              council_id: selectedCouncil.id,
              enqueueSnackbar,
            })
          );
        }
      },
      enqueueSnackbar,
    };

    dispatch(ideaCompaniesAddAttempt(payload));
  };

  const dropOptions = useMemo(() => {
    if (company?.ideas_related_companies) {
      return ideas.filter(
        (i) => !company.ideas_related_companies.some((i1) => i1.idea.idea_id === i.id)
      );
    }

    return ideas;
  }, [ideas, company]);

  return (
    <div className={styles.addCompanyWrp}>
      <ModalBody>
        <Label>Ideas</Label>
        <DropSelect
          onChange={setSelected}
          placeholder="Search for Idea"
          labelField="name"
          valueField="id"
          searchBy="name"
          options={dropOptions}
        />
      </ModalBody>
      <ModalFooter>
        {
          isSubmitting ? (
            <LoadingComponent customText="Processing..." />
          ) : (
            <>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button disabled={!selected} type="submit" btn={BtnType.REGULAR} onClick={handleSaveClick}>
                Save
              </Button>
            </>
          )
        }
      </ModalFooter>
    </div>
  );
};

export default React.memo(AddToIdeaComponent);

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { useSnackbar } from "notistack";

import { isCreator } from "common/checkers/isCreator";
import { MoreOptions, Loading, Button, BtnType } from "modules/primitives";
import { currencySymbol } from "./constant";
import styles from "./styles.module.scss";
import { ACTIVITY_OPTIONS } from "../../../constants";
import { Icon, smSize } from "../../../icon";
import { numberWithCommas } from "../../../helper";

const NumberComponent = ({
  item,
  handleEditClick,
  blockFunctionality,
  handleDeleteClick,
  handleSubmitDetail,
}) => {
  const {
    companiesReducer: {
      companyReducer: { customFieldLoadingId, selectedCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: {
        selectedPatientOrganization,
        customFieldLoadingIdOrg,
      },
    },
  } = useSelector((state) => state);
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);
  const [showField, setShowField] = useState(false);
  const [loadingId, setLoadingId] = useState("");
  const [fieldId, setFieldId] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (item) {
      setFieldValue(item?.field_value || "");
      if (isCreator(item?.creator?.user_id)) {
        setHasAccessToEdit(true);
      }
    }
  }, [item]);

  useEffect(() => {
    if (customFieldLoadingId || customFieldLoadingIdOrg) {
      if (selectedPatientOrganization.id) {
        setLoadingId(customFieldLoadingIdOrg);
      } else {
        setLoadingId(customFieldLoadingId);
      }
    }
  }, [customFieldLoadingId, customFieldLoadingIdOrg]);

  useEffect(() => {
    if (selectedPatientOrganization.id) {
      setFieldId(item.patient_organization_custom_field_id);
    } else {
      setFieldId(item.company_custom_field);
    }
  }, [item]);

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }

    if (value === 1) {
      handleDeleteClick(item);
    }
  };

  const title = (item) => {
    if (item.field_name) {
      return item.field_name;
    }
    if (!item.option_values[0]) {
      return "Number";
    }
    return "Currency";
  };

  const handleSaveClick = () => {
    if (!handleSubmitDetail) {
      return;
    }

    const payload = {
      ...item,
      field_value: fieldValue,
    };

    handleSubmitDetail(payload, item);

    setShowField(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{title(item)}</h5>
          {item?.field_placeholder?.length ? (
            <span className="text-muted small">{item.field_placeholder}</span>
          ) : null}
        </div>
        <div className={styles.rightWrp}>
          <div className={styles.colMore}>
            {!blockFunctionality && hasAccessToEdit ? (
              <MoreOptions
                options={ACTIVITY_OPTIONS}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            ) : null}
          </div>
        </div>
      </div>
      {item.from_claimed_profile && (
        <span className="d-block small font-weight-bold mb-3">
          Company Response
        </span>
      )}
      {fieldId === loadingId ? (
        <Loading />
      ) : showField ? (
        true
      ) : (
        <div className="d-inline-flex w-100">
          {item?.field_value ? (
            <p className={classNames("small", styles.description)}>
              {item.option_selected &&
                item?.field_value &&
                currencySymbol.find(
                  ({ name }) => name === item.option_values[0]
                )?.symbol}
              {item.option_values[0]
                ? numberWithCommas(item?.field_value)
                : item?.field_value}
            </p>
          ) : (
            <div className="w-75 d-flex align-items-center">
              {item?.option_selected &&
                currencySymbol?.find(
                  ({ name }) => name === item.option_values[0]
                )?.symbol}
              <input
                type="number"
                min="0"
                className="w-100 border mr-3 ml-2 small p-2"
                value={fieldValue}
                placeholder={item?.field_placeholder}
                onChange={(evt) => setFieldValue(evt.target.value)}
              />
              <Button btn={BtnType.FRAME_LESS} onClick={handleSaveClick}>
                Save
              </Button>
            </div>
          )}
          {item?.field_value?.length ? (
            <div
              className="cursor-pointer ml-2"
              onClick={() => setShowField(true)}
            >
              <Icon {...smSize} icon="icn-edit" />
            </div>
          ) : null}
        </div>
      )}

      {showField ? (
        <div className="w-75 d-flex align-items-center">
          {item?.option_selected &&
            currencySymbol?.find(({ name }) => name === item.option_values[0])
              ?.symbol}
          <input
            type="number"
            min="0"
            className="w-100 border mr-3 ml-2 small p-2"
            value={fieldValue}
            placeholder={item?.field_placeholder}
            onChange={(evt) => setFieldValue(evt.target.value)}
          />
          <Button btn={BtnType.FRAME_LESS} onClick={handleSaveClick}>
            Save
          </Button>
        </div>
      ) : null}

      {item && item.creator ? (
        <div className={styles.infoWrp}>
          <div className={styles.dataRow}>
            <div className={styles.label}>
              {item.from_submission
                ? "Submitted by"
                : item.updated_at !== item.created_at
                ? "Updated by"
                : "Created by"}
            </div>
            <div className={styles.value}>
              {item.from_submission ? (
                <span>{item?.creator?.full_name || "-"}</span>
              ) : (
                <Link to={`/people/internal/profile/${item.user_id}`}>
                  {item && item.creator && item.creator.full_name}
                  {item.from_claimed_profile && (
                    <span>, {selectedCompany?.name}</span>
                  )}
                </Link>
              )}
            </div>
          </div>
          {item.from_claimed_profile && (
            <div className="d-flex align-items-center mr-5">
              <div className={styles.companyAccess}>
                <span>&#x2713; Access</span>
              </div>
            </div>
          )}
          <div className={styles.date}>
            {item.updated_at !== item.created_at
              ? format(new Date(item.updated_at), "eeee, MMM do, yyyy hh:mm a")
              : format(new Date(item.created_at), "eeee, MMM do, yyyy hh:mm a")}
          </div>
          {item.from_submission ? (
            <div
              className={`d-flex flex-grow-1 align-items-center justify-content-center ${styles.companyResponse}`}
            >
              Company Response
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
export default React.memo(NumberComponent);

export const WIZARD_SETUP_TYPES = {
  main: "Main",
  idea: "Idea",
  company: "Company",
  theme: "Theme",
  replicate: "Replication",
  patient_organization: "Patient Organization",
};

export const WIZARD_DESCRIPTIONS = {
  main: "Set-Up Wizard for New Project button on main Projects and Home pages",
  idea: "Set-Up Wizard for New Project button on Idea pages",
  company: "Set-Up Wizard for New Project button on Company pages",
  theme: "Set-Up Wizard for New Project button on Theme pages",
  replicate: "Set-Up Wizard for Replicate button on Project pages",
  patient_organization: "Set-Up Wizard for New Mapping button on Patient Organizations pages",
};

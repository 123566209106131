export const projectStages = [
  {
    label: 'Proposal for Prototype',
    value: 'proposal_for_prototype'
  },
  {
    label: 'Proposal for Implementation',
    value: 'proposal_for_implementation'
  }
];

export const modalTextsByOption = {
  'deny': {
    title: 'Reason for Denial',
    body: 'Please provide a reason or feedback.',
    actionForBE: 'deny',
    reqMessage: 'Successfully denied task.'
  },
  'request_change': {
    title: 'Reason for Request',
    body: 'Please explain what changes are needed.',
    actionForBE: 'request_change',
    reqMessage: 'Successfully requested change.'
  },
  'under_review': {
    title: 'Reason for Review',
    body: 'Please provide a reason or feedback.',
    actionForBE: 'under_review',
    reqMessage: 'Successfully set Under Review.'
  },
  'assign_for_approval': {
    title: '',
    body: '',
    actionForBE: 'assign_for_approval',
    reqMessage: 'Successfully assigned for Approval.'
  },
  'approve': {
    title: '',
    body: '',
    actionForBE: 'approve',
    reqMessage: 'Successfully approved task.'
  }
}

export const approvalOptions = [
  {
    name: 'Actions',
    id: 'action'
  },
  {
    name: 'Approve',
    id: 'approve',
    takenActionData: {
      id: 'approve',
      name: 'Approved',
    }
  },
  {
    name: 'Deny',
    id: 'deny',
    takenActionData: {
      id: 'deny',
      name: 'Denied',
    }
  },
  {
    name: 'Request Change',
    id: 'request_change',
    takenActionData: {
      id: 'request_change',
      name: 'Change Requested',
    }
  }
];

export const reviewOptions = [
  {
    name: 'Actions',
    id: 'action'
  },
  {
    name: 'Review Completed',
    id: 'assign_for_approval',
    takenActionData: {
      id: 'assign_for_approval',
      name: 'Review Completed',
    }
  },
  {
    name: 'Deny',
    id: 'deny',
    takenActionData: {
      id: 'deny',
      name: 'Denied',
    }
  },
  {
    name: 'Request Change',
    id: 'request_change',
    takenActionData: {
      id: 'request_change',
      name: 'Change Requested',
    }
  },
];

export const userActionsEnum = {
  pending: 'Pending',
  request_change: 'Change Requested',
  ready_for_review: 'Ready for Review',
  completed: 'Completed',
  not_assigned: 'Not Assigned',
  approved: 'Approved',
  denied: 'Denied',
  change_requested: 'Change Requested',
  under_review: 'Under Review',
}

export const doneActionsEnum = {
  request_change: 'Change Requested',
  under_review: 'Reason for Review',
  assign_for_approval: 'Assigned for Approval',
  deny: 'Reason for Denial'
}

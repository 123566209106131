import React, { useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";
import { isCreator } from "common/checkers/isCreator";
import { MoreOptions, CheckBox, CheckType, Loading } from "modules/primitives";
import { ACTIVITY_OPTIONS } from "../../../../../common/constants";
import styles from "./styles.module.scss";
import isKyndrylViewOnly from "../../../../../common/isKyndrylViewOnly";

const CheckListComponent = (props) => {
  const {
    item,
    handleEditClick,
    handleSubmitDetail,
    blockFunctionality = false,
    handleDeleteClick,
    indexOfCfGroup,
  } = props;
  const {
    companiesReducer: {
      companyReducer: { customFieldLoadingId, selectedCompany },
    },
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);
  const [isESGField, setIsESGField] = useState(false);

  useEffect(() => {
    if (item) {
      setIsESGField(item.field_name === "ESG (COMMITMENT TO UN GOALS)");

      if (isCreator(item?.creator?.user_id)) {
        setHasAccessToEdit(true);
      }
    }

    return () => null;
  }, [item]);

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item, false, "text", false, indexOfCfGroup);
    }

    if (value === 1) {
      handleDeleteClick(item);
    }
  };

  const check = (checked, value) => {
    let optionSelected = item.option_selected;
    if (checked) {
      if (item.multiselect) {
        optionSelected.push(value);
      } else {
        optionSelected = [value];
      }
    }
    if (!checked) {
      optionSelected = item.option_selected?.filter((e) => e !== value);
    }

    const values = {
      field_value: item.field_value,
      title: item.field_name,
      type: item.field_type,
      option_selected: optionSelected,
      multiselect: item.multiselect,
      custom_field_group_id: item.custom_field_group_id,
      option_values: item.option_values,
    };

    handleSubmitDetail(values, item);
  };

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [item?.field_type]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{item?.field_name || "Checklist"}</h5>
          {item?.field_placeholder?.length ? (
            <span className="text-muted small">{item.field_placeholder}</span>
          ) : null}
        </div>
        <div className={styles.rightWrp}>
          <div className={styles.colMore}>
            {!blockFunctionality && hasAccessToEdit ? (
              <MoreOptions
                options={ACTIVITY_OPTIONS}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            ) : null}
          </div>
        </div>
      </div>
      {item.from_claimed_profile && (
        <span className="d-block small font-weight-bold mb-3">
          Company Response
        </span>
      )}
      <div
        ref={ref}
        className={classNames(
          "row",
          item?.display_as === "list"
            ? styles.checklistHorisontalRow
            : styles.checklistRow
        )}
      >
        {item?.custom_field_template_id === customFieldLoadingId && (
          <div style={{ height: `${height}px` }}>
            <Loading />
          </div>
        )}
        {item?.custom_field_template_id !== customFieldLoadingId &&
          item &&
          item.option_values.map((box, idx) => (
            <div
              key={box}
              className={classNames(
                styles.formElement,
                "col-4",
                styles.ESGFcheckWrp
              )}
            >
              {!blockFunctionality ||
              isKyndrylViewOnly(
                selectedCouncil?.name,
                session?.council_role
              ) ? (
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={item.option_selected?.includes(box)}
                  onChange={(checked) => {
                    check(checked, box);
                  }}
                />
              ) : (
                <Tooltip
                  title="You have View-Only access. To Edit fields, please ask your Admin to upgrade your account to Standard access."
                  placement="top"
                >
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={item.option_selected?.includes(box)}
                    disabled
                  />
                </Tooltip>
              )}
              {isESGField && (
                <div
                  className={`${styles.esgImgWrapper} ${
                    styles[`esgImgWrapper-${idx + 1}`]
                  }`}
                />
              )}
              <span className={styles.label}>{box}</span>
            </div>
          ))}
      </div>
      {item && item.creator ? (
        <div className={styles.infoWrp}>
          <div className={styles.dataRow}>
            <div className={styles.label}>
              {item.from_submission
                ? "Submitted by"
                : item.updated_at !== item.created_at
                ? "Updated by"
                : "Created by"}
            </div>
            <div className={styles.value}>
              {item.from_submission ? (
                <span>{item?.creator?.full_name || "-"}</span>
              ) : (
                <Link to={`/people/internal/profile/${item.user_id}`}>
                  {item && item.creator && item.creator.full_name}
                  {item.from_claimed_profile && (
                    <span>, {selectedCompany?.name}</span>
                  )}
                </Link>
              )}
            </div>
          </div>
          {item.from_claimed_profile && (
            <div className="d-flex align-items-center mr-5">
              <div className={styles.companyAccess}>
                <span>&#x2713; Access</span>
              </div>
            </div>
          )}
          <div className={styles.date}>
            {item.updated_at !== item.created_at
              ? format(new Date(item.updated_at), "eeee, MMM do, yyyy")
              : format(new Date(item.created_at), "eeee, MMM do, yyyy")}
          </div>
          {item.from_submission ? (
            <div
              className={`d-flex flex-grow-1 align-items-center justify-content-center ${styles.companyResponse}`}
            >
              Company Response
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
export default React.memo(CheckListComponent);

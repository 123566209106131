export const PAGINATION_DEFAULTS = {
  pageSize: 20,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
  total: 0,
};

export const SORT_OPTIONS = [
  {
    label: "Most Recent",
    value: "created_at",
  },
  {
    label: " Highest Score",
    value: "total_up_votes",
  },
];

import React, { useEffect, useMemo } from "react";
import Select from "react-dropdown-select";
import { Field } from "formik";

import { BtnType, Button, Label, MultiSelect, TextBox } from "modules/primitives";
import styles from "modules/projects/project/overview/relatedProjects/styles.module.scss";
import { PLANT_STATUSES } from '../constants';

const PlantSelectWithOptions = ({ formikprops, plant, index, projectsList, filterBy = [], handleUnderReview }) => {
  useEffect(() => {
    const checkPlantModelExists = formikprops.values[plant.id];

    if (!checkPlantModelExists) {
      formikprops.setFieldValue(plant.id, {
        otherOptions: 'possible_replication'
      });
    }
  }, []);

  const makePlantAreas = useMemo(() => {
    if (!filterBy.length) return plant.areas || [];

    const getCurrPlantFromFilterBy = filterBy.filter(p => p.id === plant.id);

    // formikprops.values[plant.id]?.selectedAreas?.length -> looks like we need that condition as well
    // because otherwise after you delete one single area you are not anymore able to select it again

    if (getCurrPlantFromFilterBy?.length || (getCurrPlantFromFilterBy?.length && formikprops.values[plant.id]?.selectedAreas?.length)) {
      const flattenAreas = getCurrPlantFromFilterBy.reduce((acc, p) => [...acc, ...p.areas], []);
      return plant.areas?.filter(a => !flattenAreas.some(area => area.area_id === a.area_id)) || []
    }

    return plant.areas || [];
  }, [filterBy, plant, formikprops.values[plant.id]]);

  return (
    <div className="d-flex flex-column">
      <hr className="mt-4 mb-3" />
      <span className="font-weight-bold">{index}. {plant.name}</span>
      {
        plant.plantHasBeenReplicatedWhenNoAreas && !plant.allPlantAreasHaveBeenReplicated && (
          <span className="text-danger">This Plant has already been Replicated</span>
        )
      }
      {
        plant.allPlantAreasHaveBeenReplicated && (
          <span className="text-danger">All Areas of the Plant have already been Replicated</span>
        )
      }
      {
        (!plant.areas?.length && !plant.plantHasBeenReplicatedWhenNoAreas && !plant.allPlantAreasHaveBeenReplicated) ? PLANT_STATUSES.map((option, idx) => {
          if (option.id === 'under_review') {
            return (
              <div key={`${plant.id}_${option.id}`} className="form-check mt-2 mb-3">
                <Button
                  type="submit"
                  btn={BtnType.REGULAR}
                  onClick={() => handleUnderReview(plant)}
                >
                  {option.name}
                </Button>
              </div>
            )
          }

          return (
            <div key={`${plant.id}_${option.id}`} className={`form-check ${idx === 0 ? "mt-2" : ""}`}>
              <label className="form-check-label d-flex align-items-center">
                <Field
                  className="form-check-input"
                  type="radio"
                  name={`otherOptions_${index}_${idx}`}
                  value={option.id}
                  onChange={() => {
                    formikprops.setFieldValue(plant.id, {
                      ...formikprops.values[plant.id] || {},
                      otherOptions: option.id,
                      selectedAreas: formikprops.values[plant.id]?.selectedAreas?.map(a => ({ ...a, reason: null, state: null, project: null })) || []
                    }).then(() => {
                      if (option.id === "possible_replication" || option.id === "not_applicable") {
                        formikprops.setFieldValue(plant.id, {
                          ...formikprops.values[plant.id] || {},
                          otherOptions: option.id,
                          project: null,
                          selectedAreas: formikprops.values[plant.id]?.selectedAreas?.map(a => ({ ...a, reason: null, state: null, project: null })) || []
                        });
                      }
                    });
                  }}
                  checked={formikprops.values[plant.id]?.otherOptions === option.id}
                />
                {option.name}
              </label>
            </div>
          )
        }) : null
      }
      {
        formikprops.values[plant.id]?.otherOptions === 'different_solution' && (
          <div className="mt-3">
            <Label>Select a Project</Label>
            <div className={styles.selectorWrp}>
              <MultiSelect
                values={formikprops.values[plant.id]?.project ? [formikprops.values[plant.id]?.project] : []}
                onChange={val => {
                  formikprops.setFieldValue(plant.id, {
                    ...formikprops.values[plant.id] || {},
                    project: val[0]
                  });
                }}
                placeholder="Select a Project"
                labelField="name"
                valueField="id"
                options={projectsList}
                searchBy="name"
                multi={false}
              />
            </div>
          </div>
        )
      }
      {
        (formikprops.values[plant.id]?.otherOptions === 'different_solution' || formikprops.values[plant.id]?.otherOptions === 'not_applicable') && (
          <div className={formikprops.values[plant.id]?.otherOptions === 'different_solution' ? '' : 'mt-3'}>
            <Label>Reason</Label>
            <div className="w-100">
              <TextBox
                className="w-100"
                placeholder="Reason"
                onChange={evt => {
                  formikprops.setFieldValue(plant.id, {
                    ...formikprops.values[plant.id] || {},
                    reason: evt.target.value
                  });
                }}
                value={formikprops.values[plant.id]?.reason}
              />
            </div>
          </div>
        )
      }
      {
        (plant.areas.length > 0 && !plant.allPlantAreasHaveBeenReplicated) ? (
          <div className="mt-2">
            <Label>Areas</Label>
            <Select
              multi
              closeOnSelect
              values={formikprops.values[plant.id]?.selectedAreas || []}
              onChange={(val) => {
                formikprops.setFieldValue(plant.id, {
                  ...formikprops.values[plant.id] || {},
                  selectedAreas: val.map(a => ({...a, state: a.state || a.status || 'possible_replication'}))
                });
              }}
              options={makePlantAreas}
              labelField="area_name"
              valueField="area_id"
              placeholder="Areas"
              searchBy="area_name"
              style={{ border: "1px solid #e4e7eb" }}
            />
            {
              !formikprops.values[plant.id]?.selectedAreas?.length ? (
                <span className="text-danger d-block mt-2">Please select an Area</span>
              ) : null
            }
          </div>
        ) : null
      }
      {
        formikprops.values[plant.id]?.selectedAreas?.length > 0 ? (
          <div className="mt-3 mb-3 ml-3">
            {
              formikprops.values[plant.id]?.selectedAreas.map((area, idx) => (
                <div key={`${plant.id}_${area.area_id}`} className={`${idx !== 0 ? 'mt-3' : ''} d-flex flex-column`}>
                  <span>{idx + 1}. {area.area_name}</span>
                  {
                    PLANT_STATUSES.map((option, idx) => {
                      if (option.id === 'under_review') {
                        return (
                          <div key={`${plant.id}_${area.area_id}_${option.id}`} className="form-check mt-2 mb-3">
                            <Button
                              type="submit"
                              btn={BtnType.REGULAR}
                              onClick={() => handleUnderReview(plant, area)}
                            >
                              {option.name}
                            </Button>
                          </div>
                        )
                      }

                      return (
                        <div key={`${plant.id}_${area.area_id}_${option.id}`} className="form-check ml-4">
                          <label className="form-check-label d-flex align-items-center">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name={`otherOptions_${index}_${area.area_id}`}
                              value={option.id}
                              onChange={() => {
                                const newValue = {
                                  ...formikprops.values[plant.id] || {},
                                  otherOptions: null,
                                  reason: null,
                                  project: null,
                                  selectedAreas: formikprops.values[plant.id].selectedAreas.map(a => {
                                    if (a.area_id === area.area_id) {
                                      if (option.id === "possible_replication" || option.id === "not_applicable") {
                                        return {
                                          ...a,
                                          state: option.id,
                                          project: null,
                                        };
                                      }

                                      return {
                                        ...a,
                                        state: option.id,
                                      };
                                    }

                                    return a;
                                  }),
                                };

                                formikprops.setFieldValue(plant.id, newValue)
                              }}
                              checked={area.state === option.id}
                            />
                            {option.name}
                          </label>
                        </div>
                      )
                    })
                  }
                  {
                    area.state === 'different_solution' && (
                      <div className="mt-1">
                        <Label>Select a Project</Label>
                        <div className={styles.selectorWrp}>
                          <MultiSelect
                            values={area.project ? [area.project] : []}
                            onChange={val => {
                              formikprops.setFieldValue(plant.id, {
                                ...formikprops.values[plant.id] || {},
                                selectedAreas: formikprops.values[plant.id].selectedAreas.map(a => {
                                  if (a.area_id === area.area_id) {
                                    return {
                                      ...a,
                                      project: val[0],
                                    };
                                  }

                                  return a;
                                }),
                              });
                            }}
                            placeholder="Select a Project"
                            labelField="name"
                            valueField="id"
                            options={projectsList}
                            searchBy="name"
                            multi={false}
                          />
                        </div>
                      </div>
                    )
                  }
                  {
                    (area.state === 'different_solution' || area.state === 'not_applicable') && (
                      <div className={area.state === 'different_solution' ? '' : 'mt-1'}>
                        <Label>Reason</Label>
                        <div className="w-100">
                          <TextBox
                            className="w-100"
                            placeholder="Reason"
                            onChange={evt => {
                              formikprops.setFieldValue(plant.id, {
                                ...formikprops.values[plant.id] || {},
                                selectedAreas: formikprops.values[plant.id].selectedAreas.map(a => {
                                  if (a.area_id === area.area_id) {
                                    return {
                                      ...a,
                                      reason: evt.target.value
                                    };
                                  }

                                  return a;
                                }),
                              });
                            }}
                            value={area.reason}
                          />
                        </div>
                      </div>
                    )
                  }
                </div>
              ))
            }
          </div>
        ) : null
      }
    </div>
  );
};

export default PlantSelectWithOptions;

import React, { useMemo } from "react";

import styles from "./productDocs.module.scss";
import { BtnType, Button } from "modules/primitives";
import useModelPopup from "common/hooks/modelPopup.hook";
import EditProductDocs from "modules/companies/product/components/overview/productDocs/editProductDocs";
import { useSliderField } from "common/hooks/index";
import { Icon, smSize } from "../../../../../../common/icon";
import { shouldDownload } from "../../../../../../common/helper";

const ProductDocs = ({ product, instance }) => {
  const sliderItem = useMemo(() => {
    return {
      option_values: product.presentations || [],
      field_type: 'slide'
    }
  }, [product]);
  const sliderField = useSliderField(sliderItem);
  const popup = useModelPopup();

  const addNewVideo = () => {
    const title = instance === 'docs' ? 'Documents' : 'Presentations'
    popup.show({
      show: true,
      title: `Edit Product ${title}`,
      component: <EditProductDocs product={product} instance={instance} />,
    });
  };

  const getFilename = (url) => {
    if (url) {
      return url.split("/").pop();
    }
    return "";
  };

  return (
    <div className={styles.productDocs}>
      {
        product.documents.length && instance === 'docs' ? (
          <div className="mb-3">
            {
              product.documents.map((doc, index) => (
                <div key={`${doc.id}-${index}`} className="d-flex justify-content-between">
                  <div className="d-flex align-items-center flex-grow-1">
                    <Icon
                      {...smSize}
                      icon="icn-subnav-documents"
                      className={styles.tileIcon}
                    />
                    <span className="ml-3">{getFilename(doc.uri)}</span>
                  </div>
                  <div className={styles.rightWrp}>
                    <a
                      href={doc.uri}
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      <Icon
                        {...smSize}
                        icon="icn-export-button"
                        className={styles.download}
                      />
                    </a>

                    <a
                      className={styles.docView}
                      target="blank"
                      href={doc.uri}
                    >
                      {shouldDownload(getFilename(doc.uri))
                        ? "download"
                        : "view"}
                    </a>
                  </div>
                </div>
              ))
            }
            <div className="d-flex align-items-center flex-column">
              <Button
                className="mt-3"
                onClick={addNewVideo}
                btn={BtnType.REGULAR}
              >
                ADD DOCUMENTS
              </Button>
            </div>
          </div>
        ) : null
      }
      {
        product.presentations.length && instance === 'presentations' ? (
          <div>
            {sliderField.displayField()}
          </div>
        ) : null
      }
      {
        !product.documents?.length && instance === 'docs' ? (
          <div className="d-flex align-items-center flex-column">
            <span>No documents added yet.</span>
            <Button
              className="mt-3"
              onClick={addNewVideo}
              btn={BtnType.REGULAR}
            >
              ADD DOCUMENTS
            </Button>
          </div>
        ) : null
      }
      {
        !product.presentations?.length && instance === 'presentations' ? (
          <div className="d-flex align-items-center flex-column">
            <span>No presentations added yet.</span>
            <Button
              className="mt-3"
              onClick={addNewVideo}
              btn={BtnType.REGULAR}
            >
              ADD PRESENTATIONS
            </Button>
          </div>
        ) : null
      }
    </div>
  );
};

export default ProductDocs;

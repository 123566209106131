import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";

import styles from "./myProject.module.scss";
import { Pannel } from "../../primitives";
import ProjectRowComponent from "./projectRow";
import { projectsSearch } from "modules/projects/projectsMain/projectsMain.action";
import LoadingComponent from "modules/primitives/loading/loading.component";

const MyProjectsComponent = ({ mode = "my_projects", user = null, homePageUse, selectedCouncil, session } ) => {
  const dispatch = useDispatch();

  const {
    projectsReducer: {
      projectsMainReducer: { projectList, projectLoading },
    },
    peopleReducer: {
      internalPeopleReducer: { selectedMember },
    },
  } = useSelector((state) => state);
  const isFord = useMemo(() => selectedCouncil?.traction_tag === "ford", [
    selectedCouncil?.traction_tag
  ]);

  useEffect(() => {
    const payload = {
      data: {
        search: {
          query: "",
          user_id: homePageUse ? session.id : selectedMember?.id,
        },
        selectedTab: "",
        page: 1,
        pageSize: 4,
        sort_order: "desc",
        sort_attribute: "activity",
      },
      ford: selectedCouncil?.traction_tag === "ford",
    };

    dispatch(projectsSearch(payload));
  }, []);

  return (
    <Pannel
      title={user ? `${user?.first_name}'s Projects` : "My Projects"}
      padding="0"
      parentStyle={{
        background: "Transparent",
        marginRight: window.screen.width > 550 ? 30 : 0,
        padding: window.screen.width > 550 ? 7 : 0,
      }}
    >
      {projectLoading && <LoadingComponent customText="Fetching projects..." /> }
      {projectList.length > 0 ? (
        <div className={styles.wrapper}>
          <div className={styles.grid}>
            <div className={styles.gridHeader}>
              <div className={styles.project}> Project</div>
              <div
                className={classnames(
                  isFord ? styles.fordCol : styles.activity
                )}
              >
                Activity
              </div>
              <div
                className={classnames(isFord ? styles.fordCol : styles.myTask)}
              >
                Status
              </div>
              <div
                className={classnames(
                  isFord ? styles.fordCol : styles.progress
                )}
              >
                Progress
              </div>
              <div
                className={classnames(isFord ? styles.fordCol : styles.role)}
              >
                Role
              </div>
              {isFord && (
                <>
                  <div className={styles.fordCol}>Plant</div>
                  <div className={styles.fordCol}>Area</div>
                  <div className={styles.fordCol}>Pillar</div>
                  <div className={styles.fordRating}>Rating</div>
                </>
              )}
            </div>
            <div className={styles.gridBody}>
              {projectList &&
                projectList
                  ?.filter(({ status }) => status !== "closed")
                  ?.map((project) => (
                    <ProjectRowComponent
                      key={project?.id}
                      project={project}
                      tab="my_projects"
                      userId={
                        mode === "user_projects"
                          ? selectedMember?.id
                          : session?.id
                      }
                      homePageUse={homePageUse}
                    />
                  ))}
            </div>
            <div className={styles.gridFooter}>
              <Link
                to={
                  selectedMember?.id === session.id || mode !== "user_projects"
                    ? "/projects?mode=my_projects"
                    : "/projects?mode=all_projects"
                }
              >
                {selectedMember?.id === session.id || mode !== "user_projects"
                  ? "SEE ALL MY PROJECTS"
                  : "SEE ALL PROJECTS"}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.emptyProject}>
          <div className={styles.emptyCon}>
            You don’t have any projects yet. <br />
            <Link to="/projects/new" className={styles.newProject}>
              Create a project{" "}
            </Link>
            to get started evaluting companies and technologies.
          </div>
          <div className={styles.gridFooter}>
            <Link to="/projects?mode=all_projects">SEE ALL PROJECTS</Link>
          </div>
        </div>
      )}
    </Pannel>
  );
};

export default React.memo(MyProjectsComponent);

import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import styles from "../taskInfo/taskInfo.module.scss";
import ApprovalActions from "modules/projects/project/tasks/approvalTask/approvalActions";
import ConfirmActionComponent from "../../../../../common/components/confirmAction/confirmAction.component";
import { useModelPopup } from "../../../../../common/hooks";
import {
  gatewayTaskUpdate,
  projectTaskListGet,
  projectTasksByUserGet,
} from "modules/projects/project/tasks/tasks.action";
import { modalTextsByOption } from "modules/projects/project/tasks/approvalTask/constants";
import {
  projectStagesListGet,
  projectWideTasksListGet,
} from "modules/projects/project/stages/stages.action";
import { getUpcomingTasks } from "modules/projects/project/overview/overview.action";

const ApprovalTaskHeader = ({
  task,
  isProjectWideFromStage,
  activeTab,
  activePage,
  upcomingTaskInstance,
}) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const dispatch = useDispatch();

  const isReviewer = useMemo(
    () => task.approval_type === "review",
    [task.approval_type]
  );
  const canAction = useMemo(() => {
    const myAssignedUser = task.assigned_users?.find(
      (assign) => assign.user?.user_id === session?.id
    );

    if (!myAssignedUser?.status?.length && task?.approve_in_order) {
      return false;
    }

    return !!myAssignedUser;
  }, [task, isReviewer, session]);

  const getNewUpdatedTasks = () => {
    let obj = {
      projectId: task.project_id,
      councilId: selectedCouncil.id,
      enqueueSnackbar,
      page: activePage,
      apiVersion: "v3",
    };

    if (upcomingTaskInstance) {
      dispatch(getUpcomingTasks({
        userId: activeTab === "my_task" ? session.id : null,
        enqueueSnackbar,
        projectId: task.project_id,
      }));

      return;
    }

    if (activeTab === "my_task") {
      obj = { ...obj, userId: session.id };
      dispatch(projectTasksByUserGet(obj));
    }

    if (activeTab === "all_task") {
      dispatch(projectTaskListGet(obj));
    }
  };

  const getNewUpdatedStagesData = () => {
    dispatch(
      projectStagesListGet({
        projectId: task.project_id,
        enqueueSnackbar,
      })
    );
    dispatch(
      projectWideTasksListGet({
        projectId: task.project_id,
        enqueueSnackbar,
      })
    );
  };

  const handleActionSend = (action, reason) => {
    const getObjDetails = modalTextsByOption[action];
    const payload = {
      project_task: {
        task_action_attributes: {
          user_id: session.id,
          member_type: isReviewer ? "reviewer" : "approver",
          action: getObjDetails.actionForBE,
          reason,
        },
      },
      taskId: task.id,
      projectId: task.project_id,
      enqueueSnackbar,
      cb: () => {
        enqueueSnackbar(getObjDetails.reqMessage, {
          variant: "success",
        });

        if (
          (!isReviewer && action === "approve") ||
          (isReviewer && action === "assign_for_approval")
        ) {
          const timeout = setTimeout(() => {
            if (isProjectWideFromStage) {
              getNewUpdatedStagesData();
            } else {
              getNewUpdatedTasks();
            }

            clearTimeout(timeout);
          }, 500);
        }
      },
      isProjectWideFromStage,
    };

    dispatch(gatewayTaskUpdate(payload));
  };

  const isUserAssigned = useMemo(() => {
    if (!task.assigned_users?.length) {
      return true;
    }

    const myAssignedUser = task.assigned_users?.find(
      (assign) => assign.user?.user_id === session?.id
    );

    return myAssignedUser?.status?.length && task?.approve_in_order;
  }, [isReviewer, task]);

  const handleActionSelect = (elem) => {
    if (
      elem.id === "deny" ||
      elem.id === "under_review" ||
      elem.id === "request_change"
    ) {
      const textObj = modalTextsByOption[elem.id];
      popup.show({
        title: textObj.title,
        component: (
          <ConfirmActionComponent
            showConfirmationMsg
            confirmBtnName="Confirm"
            alertText={textObj.body}
            onConfirm={(reason) => {
              handleActionSend(elem.id, reason);
              popup.hide();
            }}
          />
        ),
      });

      return;
    }

    handleActionSend(elem.id);
  };

  return (
    <div className={styles.headerWrp}>
      <ApprovalActions
        task={task}
        handleActionSelect={handleActionSelect}
        disabled={!canAction}
        isReviewer={isReviewer}
        myUserId={session?.id}
        isUserAssigned={isUserAssigned}
      />
    </div>
  );
};

export default React.memo(ApprovalTaskHeader);

import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import styles from '../../../tractionAI.module.scss';
import TypingText from "modules/tractionAI/components/typingText";
import SWOTItemResponse from "modules/tractionAI/screens/insights/swot/swotItemResponse";
import {
  AISelectScreen,
  AISetCompanySnapshotData,
  AISetScoutData,
  AISetTrendData,
} from "modules/tractionAI/store/tractionAI.actions";
import { httpPost } from "../../../../../common/httpCall";

const TractionAISwot = () => {
  const scrollableDivRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const {
    tractionAiReducer: { insight: { swot } }
  } = useSelector((state) => state);

  useEffect(() => {
    onAnimationComplete();
  }, []);

  const scrollToBottom = useCallback((noBehavior) => {
    const timeout = setTimeout(() => {
      if (!scrollableDivRef?.current) {
        clearTimeout(timeout);
        return;
      }

      scrollableDivRef.current.scrollTo({
        top: scrollableDivRef.current.scrollHeight,
        behavior: noBehavior ? 'instant' : 'smooth'
      });
      clearTimeout(timeout);
    }, 200);
  }, [scrollableDivRef]);

  useEffect(() => {
    if (swot.data?.length && scrollableDivRef?.current) {
      scrollToBottom(true);
    }
  }, [swot.data, scrollableDivRef]);

  const onAnimationComplete = () => {
    document.getElementById('bottomAI').scrollIntoView();
  }

  const handleScoutSearch = (searchByQuery) => {
    dispatch(AISetScoutData({
      fetching: true,
      searchByQuery,
      createNewSearchItem: true,
      fetched: false,
    }));

    httpPost({
      call: "ai/proxy/streaming",
      data: { prompt: searchByQuery, endpoint: "recommend" },
    })
      .subscribe(() => {},(error) => {
          dispatch(AISetScoutData({
            hasError: true,
            errorMsg: error.response.error,
            errorCode: error.response.status,
            errorType: 'serverError',
            fetched: true,
            fetching: false,
          }));
        }
      )
  };

  const handleTrendSearch = (searchByQuery) => {
    dispatch(AISetTrendData({
      fetching: true,
      searchByQuery,
      createNewSearchItem: true,
      fetched: false,
    }));

    httpPost({
      call: "ai/proxy/streaming?formatted=true", data: { prompt: searchByQuery, endpoint: "trend_analysis" },
    })
      .subscribe(() => {},(error) => {
          dispatch(AISetTrendData({
            hasError: true,
            errorMsg: error.response.error,
            errorCode: error.response.status,
            errorType: 'serverError',
            fetched: true,
            fetching: false,
          }));
        }
      )
  };

  const handleCompanySnapshotSearch = (searchByQuery, searchById) => {
    dispatch(AISetCompanySnapshotData({
      fetching: true,
      searchByQuery,
      createNewSearchItem: true,
      fetched: false,
    }));

    httpPost({
      call: "ai/proxy/streaming", data: { endpoint: "company_snapshot", company_id: searchById },
    }).subscribe(() => {}, (error) => {
      dispatch(AISetCompanySnapshotData({
        hasError: true,
        errorMsg: error.response.error,
        errorCode: error.response.status,
        errorType: 'serverError',
        fetched: true,
        fetching: false,
      }));
    });
  };

  const goToNewScreen = (activeScreen, activeSubScreen, searchByQuery, searchById) => {
    dispatch(AISelectScreen({
      activeScreen,
      activeSubScreen,
    }));

    if (activeScreen === 'scout') {
      handleScoutSearch(searchByQuery);
    }

    if (activeScreen === 'insight' && activeSubScreen === 'trend') {
      handleTrendSearch(searchByQuery);
    }

    if (activeScreen === 'insight' && activeSubScreen === 'companySnapshot') {
      handleCompanySnapshotSearch(searchByQuery, searchById);
    }
  }

  return (
    <div ref={scrollableDivRef}>
      <h4 className="mb-3">Create a SWOT Analysis</h4>
      <div className={`d-flex w-100 flex-column mb-4 ${styles.resultsWrapper}`}>
        {
          (!swot.data?.length && !swot.fetching) ? (
            <div className="mb-5 mt-5 d-flex align-items-center justify-content-center">
              <TypingText text="Enter details about the technology trend or problem you would like me to research." />
            </div>
          ) : null
        }
        <div className="d-flex flex-column">
          {
            swot.data.map((item, index) => (
              <SWOTItemResponse
                key={`${item.searchByQuery}_${index}`}
                item={item}
                enqueueSnackbar={enqueueSnackbar}
                index={index}
                onAnimationComplete={onAnimationComplete}
                goToNewScreen={goToNewScreen}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default React.memo(TractionAISwot);

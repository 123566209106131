import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import styles from './styles.module.scss';
import FordKPIsSections from "modules/reporting/fordReports/kpis/sections";
import FordScoreCardFilters from "modules/reporting/fordReports/filters";
import {
  getKPIsClientPlants,
  getKPIsInnovationCategory,
  getKPIsNextPhaseChange,
  getKPIsNrOfProjects,
  getKPIsNrsData,
  getKPIsReplicationsNr,
  getKPIsTableData,
  setKPIsCardFilters,
  toggleKPIsTabFilters,
} from "modules/reporting/fordReports/kpis/store/kpis.actions";
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import FordKpisTableWrapper from "modules/reporting/fordReports/kpis/table";
import TractionDownload from "../../../../common/components/tractionDownload";
import PDFTitleAndTractionLogo from "modules/reporting/fordReports/pdfTitleAndTractionLogo";

const FordKPIsCard = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [downloadIsActive, setDownloadIsActive] = useState(false);

  const {
    fordDashboardReducer: { kpisReducer }
  } = useSelector((state) => state);

  const handleReset = () => {
    dispatch(setKPIsCardFilters({
      reset: true
    }));

    const makeFilters = makeActualScoreCardFilterData({
      dateType: 'allTime'
    });
    const payload = {
      enqueueSnackbar,
      data: makeFilters,
    };

    dispatch(getKPIsNrOfProjects(payload));
    dispatch(getKPIsClientPlants(payload));
    dispatch(getKPIsInnovationCategory(payload));
    dispatch(getKPIsNextPhaseChange(payload));
    dispatch(getKPIsReplicationsNr(payload));
    dispatch(getKPIsNrsData(payload));

    if (kpisReducer.tableData?.expandedKey?.length > 0) {
      dispatch(getKPIsTableData({
        reducerKey: kpisReducer.tableData.expandedKey,
        data: {
          ...makeFilters,
          section: kpisReducer.tableData.expandedKey,
        },
      }));
    }
  };

  const handleExpandClick = () => {
    dispatch(toggleKPIsTabFilters());
  }

  const getNewData = (payload) => {
    dispatch(getKPIsNrOfProjects(payload));
    dispatch(getKPIsClientPlants(payload));
    dispatch(getKPIsInnovationCategory(payload));
    dispatch(getKPIsNextPhaseChange(payload));
    dispatch(getKPIsReplicationsNr(payload));
    dispatch(getKPIsNrsData(payload));
  };

  const getNewTableData = (payload) => {
    dispatch(getKPIsTableData(payload));
  };

  const setNewFilters = (payload) => {
    dispatch(setKPIsCardFilters(payload));
  };

  return (
    <section className={styles.kpisWrapper}>
      <div className="d-flex justify-content-end">
        <TractionDownload
          handleDownloadState={setDownloadIsActive}
          timeoutTime={2000}
          hidePng
        />
      </div>
      <div id="downloadableSection">
        <PDFTitleAndTractionLogo downloadIsActive={downloadIsActive} title="KPIS" />
        <FordScoreCardFilters
          reducer={kpisReducer}
          handleReset={handleReset}
          handleExpandClick={handleExpandClick}
          getNewData={getNewData}
          getNewTableData={getNewTableData}
          setNewFilters={setNewFilters}
        />
        <FordKPIsSections downloadIsActive={downloadIsActive} />
        <FordKpisTableWrapper />
      </div>
    </section>
  );
};

export default React.memo(FordKPIsCard);

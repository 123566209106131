import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { FilePicker } from "react-file-picker";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import Select from "react-dropdown-select";

import { truncateString } from "common/helper";
import styles from "../taskInfo/taskInfo.module.scss";
import {
  Button,
  BtnType,
  Note,
  DatePiker,
  State,
  Loading,
  Label,
} from "../../../../primitives";
import AddNotesComponent from "../taskInfo/addNotes/addNotes.component";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import DocumentListComponent from "../taskInfo/documentList";
import { useS3FileUpload } from "../../../../../common/hooks";
import {
  projectTaskNotesDelete,
  projectTaskNotesList,
  projectTaskDocumentAdd,
  projectTaskUpdate,
  clearUploadedDoc,
} from "../tasks.action";
import UploadedByInfo from "../../../../../common/components/uploadedByInfo";
import { ALLOWED_FILE_EXTENSIONS } from "../../../../../common/constants";
import ApprovalReasons from "modules/projects/project/tasks/approvalTask/approvalReasons";
import ApprovalUsers from "modules/projects/project/tasks/approvalTask/approvalUsers";
import { projectStages } from "modules/projects/project/tasks/approvalTask/constants";
import LinearProgressWithLabel from "../../../../../common/components/linearProgressWithLabel";

const ApprovalTaskBody = ({
  taskId,
  company,
  isProjectWideFromStage,
  activeTab,
  blockFordOnly,
  upcomingTaskInstance
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { fileUpload } = useS3FileUpload();
  const [selectedNote, setSelectedNote] = useState();
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [isDateEditMode, setDateEditMode] = useState(false);
  const [assignmentDocuments, setAssignmentDocuments] = useState([]);
  const [uploadValues, setUploadValues] = useState([]);

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    projectsReducer: {
      overviewReducer: { upcomingTasks },
      stagesReducer: { projectWideTasks },
      projectReducer: { selectedProject },
      taskReducer: { documents, taskNotes, uploadedDoc, tasksList, userTasks },
    },
  } = useSelector((state) => state);

  const task = useMemo(() => {
    if (upcomingTaskInstance) {
      return upcomingTasks.data?.find((t) => t.id === taskId);
    }

    if (isProjectWideFromStage) {
      return projectWideTasks?.find((t) => t.id === taskId);
    }

    if (activeTab === "my_task") {
      return userTasks?.find((t) => t.id === taskId);
    }

    return tasksList?.find((t) => t.id === taskId);
  }, [tasksList, taskId, isProjectWideFromStage, projectWideTasks, userTasks, upcomingTaskInstance, upcomingTasks]);

  const isReviewalTask = useMemo(
    () => task?.approval_type === "review",
    [task?.approval_type]
  );

  const checkStuffIsBlocked = useMemo(() => {
    if (selectedProject && session) {
      if (
        ["ttp_administrator", "council_administrator"].includes(
          session?.council_role
        )
      ) {
        return false;
      }

      const projectMember = selectedProject?.project_members.find(
        ({ user }) => user.id === session.id
      );

      if (projectMember?.user_role === "owner") {
        return false;
      }
    }

    if (task?.assigned_reviewers?.length || task?.assigned_approvers?.length) {
      const source = task?.assigned_reviewers?.length
        ? task?.assigned_reviewers
        : task?.assigned_approvers;
      return !source.some((assigned) => assigned?.user?.user_id === session.id);
    }

    return true;
  }, [selectedProject, session, task, isReviewalTask]);

  const [model, setModel] = useState({
    due_date: task?.due_date || "",
    assigned_to: "",
    next_stage: task?.next_stage || "proposal_for_prototype",
  });

  useEffect(() => {
    if (uploadedDoc?.id) {
      dispatch(clearUploadedDoc({}));
    }
  }, [uploadedDoc]);

  useEffect(() => {
    if (selectedProject?.id) {
      const assignmentObj = task?.projects_task_assignments[0];

      setAssignmentDocuments(
        documents[assignmentObj?.id] || assignmentObj?.documents || []
      );

      if (!assignmentObj) {
        return;
      }

      dispatch(
        projectTaskNotesList({
          projectId: selectedProject.id,
          assignmentId: assignmentObj.id,
          enqueueSnackbar,
        })
      );
    }
  }, [task]);

  const handleDueDateChange = (date) => {
    if (!date) {
      setDateEditMode(true);
    }

    const newModel = {
      ...model,
      due_date: date || "",
    };

    setModel(newModel);
    updateCompanyTask(newModel, "due_date");

    const timeout = setTimeout(() => {
      setDateEditMode(false);
      clearTimeout(timeout);
    });
  };

  const handleAddDocumentClick = async (FileObject) => {
    const file = FileObject;
    const { name } = file;

    const extension = name.slice(((name.lastIndexOf(".") - 1) >>> 0) + 2);
    const isRightExtension = ALLOWED_FILE_EXTENSIONS.includes(extension);

    if (!isRightExtension) {
      const message = `Files with the extension "${extension}" are not allowed.`;
      enqueueSnackbar(message, {
        variant: "error",
        key: "extensionNotAllowed",
        persist: true,
      });

      const timeout = setTimeout(() => {
        closeSnackbar("extensionNotAllowed");
        clearTimeout(timeout);
      }, 5000);

      return;
    }

    setLoadingDocuments(true);
    await fileUpload(file, "documents/", setUploadValues).then((res) => {
      if (res && res.location) {
        savedocument(res.location, name);
        setLoadingDocuments(false);
        setUploadValues([]);
      }
    });
  };

  const savedocument = (s3url, name) => {
    const obj = {
      projectId: selectedProject.id,
      userId: session.id,
      councilId: selectedCouncil.id,
      description: "",
      assignmentId: task?.projects_task_assignments[0]?.id,
      name,
      s3url,
      enqueueSnackbar,
      companyId: company?.id,
    };
    dispatch(projectTaskDocumentAdd(obj));
  };

  const getDocNameFromUri = (docUri) => {
    const arrayFromUri = docUri && docUri?.split("/");
    if (arrayFromUri.length) {
      return arrayFromUri[arrayFromUri.length - 1];
    }
    return "Untitled";
  };

  const updateCompanyTask = (taskAssignmentData, flag) => {
    const payload = {
      enqueueSnackbar,
      taskId: task.id,
      projectId: task.project_id,
      flag,
      project_task: {
        task_assignments: {
          assigned_to_id: task?.projects_task_assignments[0]?.assigned_to_id,
          due_date: taskAssignmentData.due_date,
          assigned_on: taskAssignmentData.assigned_on,
        },
      },
      queryParams: {
        task_assignment_id: task?.projects_task_assignments[0]?.id,
      },
      isProjectWideFromStage,
    };

    dispatch(projectTaskUpdate(payload));
  };

  const handleMoreOptionClick = (value, { id, description }) => {
    switch (value) {
      case "remove":
        dispatch(projectTaskNotesDelete({ id, enqueueSnackbar }));
        break;
      case "edit":
        setSelectedNote({ id, description });
        break;
      default:
        break;
    }
  };

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    }
    return document.execCommand("copy", true, text);
  };

  const handleNextStageChange = (val) => {
    setModel({
      ...model,
      next_stage: val[0].value,
    });

    const payload = {
      enqueueSnackbar,
      taskId: task.id,
      projectId: task.project_id,
      project_task: {
        next_stage: val[0].value,
      },
      queryParams: {
        task_assignment_id: task?.projects_task_assignments[0]?.id,
      },
      isProjectWideFromStage,
    };

    dispatch(projectTaskUpdate(payload));
  };

  const getSelectedNextStage = useMemo(() => {
    const nextStageObj = projectStages.find(
      (stage) => stage.value === task?.next_stage
    );

    return nextStageObj?.label;
  }, [task]);

  const getUsersSource = useMemo(() => {
    if (isReviewalTask) {
      return task?.assigned_reviewers || [];
    }

    return task?.assigned_approvers || [];
  }, [task, isReviewalTask]);

  return (
    <div className={styles.taskInfoWrp}>
      {task.name && <h4 className={styles.taskName}>{task.name}</h4>}
      {task.description && (
        <div
          className="mb-2 d-block ml-3"
          dangerouslySetInnerHTML={{
            __html: task.description || "",
          }}
        />
      )}
      {task.show_next_stage_dropdown &&
      isReviewalTask &&
      task?.assigned_reviewers?.length > 0 ? (
        <div className="mb-3 px-3 w-75">
          <Label>Next Stage</Label>
          <Select
            name="next_stage"
            options={projectStages}
            onChange={handleNextStageChange}
            values={
              projectStages?.filter(
                (elem) => elem.value === model.next_stage
              ) || []
            }
          />
        </div>
      ) : null}
      {!isReviewalTask && task.next_stage && (
        <div className="mb-3 px-3 w-75">
          <Label>Selected Next Stage</Label>
          <span>{getSelectedNextStage}</span>
        </div>
      )}
      <div className={classNames(styles.line, styles.first)}>
        <label className={styles.label}>Task Stage</label>
        <div className={styles.values}>
          {task?.stage_id && <State stageId={task?.stage_id} />}
        </div>
      </div>
      <div className={classNames(styles.line, "d-flex flex-column")}>
        <ApprovalUsers
          task={task}
          isReviewalTask={isReviewalTask}
          blockFunctionality={blockFordOnly || checkStuffIsBlocked}
          isProjectWideFromStage={isProjectWideFromStage}
        />
      </div>
      <div className={styles.line}>
        <label className={styles.label}>Due Date</label>
        <div className={classNames(styles.values, styles.duedate)}>
          {!isDateEditMode && (
            <DatePiker
              value={model.due_date}
              onChange={handleDueDateChange}
              className={styles.duedateInner}
              disabled={checkStuffIsBlocked}
            />
          )}
          <div
            onClick={() => handleDueDateChange(null)}
            className="d-flex justify-content-center"
          >
            {!checkStuffIsBlocked && (
              <Icon
                {...mdSize}
                icon="icn-add"
                className={`${styles.icon} ${styles.rotatedIcon}`}
              />
            )}
          </div>
        </div>
      </div>
      {getUsersSource?.length > 0 ? (
        <div className={`${styles.line} flex-column`}>
          <label className={styles.label}>Documents</label>
          <div className={styles.documentWrp}>
            <DocumentListComponent
              assignmentId={task?.projects_task_assignments[0]?.id}
              documentList={assignmentDocuments}
              setLoadingDocuments={setLoadingDocuments}
              blockFunctionality={checkStuffIsBlocked}
            />
            {task.council_task_template &&
              Boolean(task.council_task_template.documents.length) &&
              task.council_task_template.documents.map((document) => (
                <div
                  key={document.id}
                  className={`${styles.documentLine} ${styles.values} ${
                    document.council_task_template !== null && styles.templateBk
                  }`}
                >
                  <div className="d-flex flex-column flex-grow-1">
                    <span className={styles.docName}>
                      {truncateString(
                        document?.name || getDocNameFromUri(document.uri),
                        30
                      )}
                    </span>
                    <UploadedByInfo document={document} />
                  </div>
                  <span className={styles.templateText}>Template</span>
                  <div className={styles.btnWrp}>
                    <a
                      href={document.uri}
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      <Icon
                        {...smSize}
                        icon="icn-export-button"
                        className={styles.download}
                      />
                    </a>
                    <a
                      className={styles.docView}
                      target="blank"
                      href={document.uri}
                    >
                      view
                    </a>
                  </div>
                </div>
              ))}
            <div className={styles.documentAdd}>
              {!checkStuffIsBlocked && (
                <FilePicker
                  // extensions={['pdf']}
                  maxSize={500}
                  onChange={(FileObject) => {
                    handleAddDocumentClick(FileObject);
                  }}
                  onError={(errMsg) => {
                    enqueueSnackbar(
                      "We could not upload your document because your file size is too big. Please make sure the file is less than 500 MB",
                      {
                        variant: "error",
                      }
                    );
                  }}
                >
                  {loadingDocuments ? (
                    <>
                      <Loading />
                      <LinearProgressWithLabel
                        value={uploadValues[0]?.progress || 0}
                      />{" "}
                    </>
                  ) : (
                    <Button btn={BtnType.FRAME_LESS}>Add Document</Button>
                  )}
                </FilePicker>
              )}
              {checkStuffIsBlocked && (
                <Tooltip
                  title="You have View-Only access. To edit this Project, please ask your Admin to upgrade your account to Standard access and invite you to this Project."
                  placement="top"
                >
                  <div>
                    <Button btn={BtnType.DISABLED}>Add Document</Button>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {task.frontend_url && (
        <div className={styles.line}>
          <label className={styles.label}>Share</label>
          <div className={classNames(styles.values, styles.link)}>
            <div>{task.frontend_url.slice(0, 35) + "..."}</div>
            <Button
              btn={BtnType.FRAME_LESS}
              onClick={() => copyTextToClipboard(task.frontend_url)}
            >
              Copy
            </Button>
          </div>
        </div>
      )}
      {task.change_reasons?.length > 0 ? (
        <ApprovalReasons
          task={task}
          myUserId={session.id}
          blockFunctionality={checkStuffIsBlocked}
          isProjectWideFromStage={isProjectWideFromStage}
        />
      ) : null}
      {getUsersSource?.length > 0 ? (
        <>
          <div className={classNames(styles.line, styles.notesWrp)}>
            <label className={styles.label}>Notes</label>
            <div className={styles.notesCon}>
              {taskNotes &&
                taskNotes.length > 0 &&
                taskNotes.map((note) => (
                  <Note
                    key={note.id}
                    note={note}
                    handleMoreOptionClick={(val) =>
                      handleMoreOptionClick(val, note)
                    }
                  />
                ))}
            </div>
          </div>
          <div className={styles.addNoteWrp}>
            <AddNotesComponent
              assignmentId={task?.projects_task_assignments[0]?.id}
              selectedNote={selectedNote}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default React.memo(ApprovalTaskBody);

export const HeaderOptions = [
  {
    value: "my_task",
    text: "My Task",
  },
  {
    value: "all_task",
    text: "All Task",
  },
];

export const projectStates = {
  open: "On Track",
  closed: "Closed",
  paused: "Paused",
  at_risk: "At Risk",
  off_track: "Off Track",
  on_hold: "On Hold"
};

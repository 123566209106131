import React, { useCallback, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";

import styles from '../../../tractionAI.module.scss';
import { copyToClipBoardFromRef } from "modules/tractionAI/utils";
import SnapshotCompanyResponse from "modules/tractionAI/screens/insights/companySnapshot/snapshotCompanyResponse";
import SnapshotSlidesModal from "modules/tractionAI/screens/insights/companySnapshot/snapshotSlidesModal";
import ItemErrorHandler from "modules/tractionAI/components/itemErrorHandler";
import { BtnType, Button } from "modules/primitives";

const CompanySnapshotItemResponse = ({ item, enqueueSnackbar, index, goToNewScreen }) => {
  const ref = useRef(null);

  const handleCopy = useCallback(() => {
    if (!ref?.current) return;

    copyToClipBoardFromRef(ref, enqueueSnackbar);
  }, [ref, enqueueSnackbar]);

  return (
    <div className="d-flex flex-column justify-content-end pr-2">
      <div className="d-flex aling-items-end justify-content-end">
        <div className={styles.searchByQuery}>{item.searchByQuery}</div>
      </div>
      <div ref={ref} id={`companySnapshot_${index}`}>
        <SnapshotCompanyResponse
          doNotAnimate={item.doNotAnimate || item.hasError}
          company={item.data}
          fetched={item.fetched}
        />
      </div>
      <ItemErrorHandler item={item} />
      {(item.fetched || item.doNotAnimate) && !item.hasError && (
        <div className="d-flex mt-2">
          <div className="d-flex align-items-center">
            <Tooltip
              title="Copy"
              placement="top"
            >
              <div className="cursor-pointer" onClick={handleCopy}>
                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                    stroke="#1C274C" strokeWidth="1.5" />
                  <path
                    d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                    stroke="#1C274C" strokeWidth="1.5" />
                </svg>
              </div>
            </Tooltip>
            <SnapshotSlidesModal index={index} item={item} />
          </div>
          <div className="d-flex align-items-center ml-5">
            <Button
              btn={BtnType.LINK}
              onClick={() => goToNewScreen('scout', '', item.searchByQuery)}
            >Recommend Companies</Button>
            <Button
              btn={BtnType.LINK}
              onClick={() => goToNewScreen('insight', 'swot', item.searchByQuery)}
              className="ml-4"
            >SWOT Report</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(CompanySnapshotItemResponse);

import React, { memo } from "react";
import { useSelector } from "react-redux";

import PatientOrganizationItem from "../patientOrganizationItem";
import style from "../patientOrganizationItem/style.module.scss";
import { CompanyAvatar } from "../../../../primitives";

const nameWrapperStyles = {
  cursor: "pointer",
  display: "flex",
  width: "180px",
  alignItems: "center",
};

const logoStyles = {
  minWidth: "24px",
  width: "24px",
  height: "24px",
  marginRight: "10px",
};

const CustomTooltipChild = React.forwardRef((props, ref) => {
  const {
    organization,
    history,
    dispatch,
    last,
    currentYear,
    handleRelationShipChange,
    selectModeIsActive,
    handleOrganizationSelect,
    isSelected,
    userIsScrolling,
    ...otherProps
  } = props;

  const handleCompanyClick = () => {
    history.push(`/patient_organizations/${organization.slug || organization.id}`);
  };

  return (
    <div
      {...otherProps}
      currentyear={currentYear}
      ref={ref}
      className={style.companyNameWrapper}
    >
      {selectModeIsActive && (
        <div className={style.selectCompany}>
          <input
            type="checkbox"
            checked={isSelected}
            onChange={handleOrganizationSelect}
          />
        </div>
      )}
      <div style={nameWrapperStyles} onClick={handleCompanyClick}>
        <div style={logoStyles}>
          <CompanyAvatar
            imgSrc={organization.logo}
            name={organization.name}
            hasBorderRadius
            smallSize
          />
        </div>
        <span>{organization.name}</span>
      </div>
    </div>
  );
});

const PatientOrganizationItemWrapper = (props) => {
  const {
    appReducer: { highlightItemWithId },
  } = useSelector((state) => state);

  return (
    <div
      className={`
      d-flex position-relative align-items-stretch ${
        props.last ? style.lastCompany : ""
      } 
      ${props.organization.id === highlightItemWithId ? style.flashingRow : ""}`}
    >
      <CustomTooltipChild {...props} />
      <PatientOrganizationItem {...props} />
    </div>
  );
};

export default memo(PatientOrganizationItemWrapper);

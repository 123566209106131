import {
  clientIdApp,
  authorityForApp,
  redirectURI,
} from "../../common/constants";

export const msalConfig = {
  auth: {
    clientId: clientIdApp,
    authority: authorityForApp, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: redirectURI,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read", "Contacts.Read", "Mail.Read", "Mail.ReadBasic"],
};
export const contactsRequest = {
  scopes: ["User.Read", "Contacts.Read"],
};
export const mailReadRequest = {
  scopes: ["Mail.Read", "Mail.ReadBasic"],
};

export const peopleRequest = {
  scopes: ["People.Read", "People.Read.All", "User.Read", "User.Read.All", "User.ReadBasic.All", "Directory.Read.all"],
};

export const usersRequest = {
  scopes: ["User.Read", "User.Read.All", "User.ReadBasic.All", "Directory.Read.all"],
};

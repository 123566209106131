import React, { useRef, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import { ModalFooter, ModalBody } from "../../../application/modal";
import { httpPost, errorHandler } from "../../httpCall";
import {
  Button,
  BtnType,
  Label,
  TextBox,
  UploadImgComponent,
} from "../../../modules/primitives";
import { useModelPopup } from "../../hooks";
import {
  initModelRelationship,
  speakerValidation,
} from "./editRelationship.constants";
import styles from "./editRelationship.module.scss";
import {
  companyEditRelationship,
  addRelationShip,
} from "modules/companies/company/company.action";
import UserMultiSelectSearch from "../usersSearch";
import { editRelationShipPatientOrg } from "modules/patientOrganizations/patientOrganization/patientOrganization.action";
import EditExternalRelationship from "../editExternalRelationship";
import { addProductOwner } from "modules/companies/product/store/product.actions";

const EditRelationship = ({
  mode,
  relationship,
  isCompanyUse,
  isPatientOrg,
  cb,
  isProductInstance,
  product,
}) => {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [showFields, setShowFields] = useState(true);
  const [model, setmodel] = useState(initModelRelationship);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const speakerFormRef = useRef();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: { selectedPatientOrganization },
    },
  } = useSelector((state) => state);

  const handleCancelClick = (e) => {
    if (cb) {
      cb(true);
    }

    popup.hide();
  };

  const handleSetSelectedOwner = (e, setFieldValue) => {
    if (isCompanyUse && e.length) {
      setSelectedUserId(e[0].user_id);
    } else {
      setSelectedUserId("");
    }

    const newUserIds = e.map((el) => el.id);
    let oldUserIds = [];
    if (mode === "edit") {
      if (isPatientOrg) {
        oldUserIds = selectedPatientOrganization.relationship_owners.map(
          (o) => o.user_id
        );
      } else {
        oldUserIds = selectedCompany.company_relationship_owners.map(
          (el) => el.user_id
        );
      }
    }
    setFieldValue("user_ids", [...new Set([...oldUserIds, ...newUserIds])]);
  };

  useEffect(() => {
    if (mode === "edit") {
      setShowFields(false);
      if (isPatientOrg) {
        setmodel({
          ...initModelRelationship,
          user_ids: selectedPatientOrganization.relationship_owners.map(
            (el) => el.user_id
          ),
        });
      } else {
        setmodel({
          ...initModelRelationship,
          user_ids: selectedCompany.company_relationship_owners.map(
            (el) => el.user_id
          ),
        });
      }
    }
  }, [
    mode,
    isPatientOrg,
    selectedCompany,
    selectedPatientOrganization,
    relationship,
  ]);

  const addExternalTeamMember = (values) => {
    const dataValues = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      title: values.title,
      avatar: values.avatar,
      company_id: selectedCompany.id,
    };
    if (mode === "add") {
      httpPost({
        call: `companies/${selectedCompany.id}/external_team`,
        data: dataValues,
      })
        .pipe(
          catchError((err) => {
            errorHandler(err, enqueueSnackbar);
            return of(null);
          })
        )
        .subscribe((res) => {
          if (res) {
            dispatch(
              addRelationShip({
                data: {
                  external_team_id: res?.response?.id,
                },
                council_id: selectedCouncil.id,
                company_id: selectedCompany.id,
                enqueueSnackbar,
              })
            );
          }
        });
    }
  };

  const handleOnSubmit = (values) => {
    // product stuff
    if (isProductInstance) {
      if (!values.user_ids.length) {
        enqueueSnackbar('Please select a owner first', {
          variant: 'error',
        });

        return;
      }

      if (mode === "add") {
        const payload = {
          data: {
            company_id: product.company.id,
            council_product_id: product.id,
            relationship_owners: values.user_ids.map(id => ({ user_id: id })),
          },
          enqueueSnackbar,
        };

        dispatch(addProductOwner(payload));
      }

      popup.hide();
      return;
    }
    // end product stuff

    if (!values.user_ids.length) {
      addExternalTeamMember(values);
    } else {
      if (mode === "add") {
        const user_ids = values.user_ids.filter(
          (id) =>
            !selectedCompany.company_relationship_owners
              .map((el) => el.user_id || el.external_team_id)
              .includes(id)
        );
        user_ids.length &&
          dispatch(
            addRelationShip({
              data: {
                user_ids: user_ids,
              },
              council_id: selectedCouncil.id,
              company_id: selectedCompany.id,
              enqueueSnackbar,
            })
          );
      }

      if (mode === "edit") {
        if (isPatientOrg) {
          dispatch(
            editRelationShipPatientOrg({
              patientOrgId: selectedPatientOrganization.id,
              currentUserId:
                relationship.patient_organization_relationship_owner_id,
              newUserId: selectedUserId,
              enqueueSnackbar,
            })
          );
        } else {
          dispatch(
            companyEditRelationship({
              company_id: selectedCompany.id,
              council_id: selectedCouncil.id,
              enqueueSnackbar,
              company_relationship_owner_id:
                relationship.company_relationship_owner_id,
              data:
                isCompanyUse && selectedUserId
                  ? {
                      user_id: selectedUserId,
                    }
                  : {
                      user_ids: values.user_ids,
                    },
            })
          );
        }
      }
    }

    if (cb) {
      cb(true);
    }

    popup.hide();
  };

  const handleAddMoreFieldsClick = (setFieldValue, setDropDownValue) => {
    setToggle(!toggle);
    setDropDownValue("user_ids", []);
    setFieldValue("externalMember", true);
  };

  const sharedUsers = useMemo(() => {
    if (isProductInstance) {
      return product?.product_owners?.map(o => ({
        id: o.user_id,
        full_name: o.user.full_name,
      })) || [];
    }

    if (isPatientOrg) {
      return selectedPatientOrganization?.relationship_owners?.map((el) => ({
        full_name: el.user.full_name,
        id: el.user_id,
      })) || [];
    }

    return selectedCompany?.company_relationship_owners?.map((item) =>
      item.user_id
        ? {
          full_name: item.user.full_name,
          id: item.user_id,
        }
        : {
          full_name: item.external_team.full_name,
          id: item.external_team_id,
        }
    );
  }, [isProductInstance, isPatientOrg, product, selectedPatientOrganization, selectedCompany]);

  const handleImgUpdate = (avatar, { first_name, last_name, email, title }) => {
    setmodel({
      ...model,
      avatar,
      first_name,
      last_name,
      email,
      title,
    });
  };

  return (
    <div className={styles.wrapper}>
      {!relationship?.external_team_id ? (
        <Formik
          innerRef={speakerFormRef}
          enableReinitialize
          initialValues={model && model}
          validationSchema={speakerValidation && speakerValidation}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            handleOnSubmit(values);
            resetForm();
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            ...formikprops
          }) => (
            <form onSubmit={handleSubmit}>
              <>
                <ModalBody>
                  <div className="row">
                    <div className="col-12">
                      {toggle === false && (
                        <UserMultiSelectSearch
                          placeholder="Choose member"
                          labelField="full_name"
                          valueField="id"
                          searchBy="full_name"
                          onChange={(val) =>
                            handleSetSelectedOwner(val, setFieldValue)
                          }
                          formProps={formikprops}
                          mode={mode === "edit" && "singular"}
                          sharedUsers={sharedUsers}
                          withoutOwner={false}
                        />
                      )}
                    </div>
                  </div>
                  {!isProductInstance && showFields && (
                    <>
                      <span className={styles.addFieldsButton}>
                        <span
                          onClick={() =>
                            handleAddMoreFieldsClick(
                              setFieldValue,
                              setFieldValue
                            )
                          }
                        >
                          {!toggle && (
                            <div className={styles.addRelationshipBtn}>
                              + Add New Relationship Owner
                            </div>
                          )}
                        </span>
                      </span>
                      {toggle && (
                        <>
                          <div className="row">
                            <div className="col-12">
                              <>
                                <input
                                  type="hidden"
                                  value="testing"
                                  name="externalMember"
                                />
                                <Label>Photo(PNG/JPG/GIF only)</Label>
                                <UploadImgComponent
                                  name="avatar"
                                  formProps={formikprops}
                                  uploadDirName={`users/${model.external_team_id}/key`}
                                  onChange={(e) => handleImgUpdate(e, values)}
                                />
                              </>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <Label>First Name</Label>
                              <TextBox
                                type="text"
                                name="first_name"
                                placeholder="Enter first name"
                                className={styles.textBox}
                                formProps={{ ...formikprops, values }}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-12">
                              <Label>Last Name</Label>
                              <TextBox
                                type="text"
                                name="last_name"
                                placeholder="Enter last name"
                                className={styles.textBox}
                                formProps={{ ...formikprops, values }}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <>
                                <Label>Email</Label>
                                <TextBox
                                  type="text"
                                  name="email"
                                  placeholder="Enter email"
                                  className={styles.textBox}
                                  formProps={{ ...formikprops, values }}
                                  onChange={handleChange}
                                />
                              </>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <>
                                <Label>Title</Label>
                                <TextBox
                                  type="text"
                                  name="title"
                                  placeholder="Enter title"
                                  className={styles.textBox}
                                  formProps={{ ...formikprops, values }}
                                  onChange={handleChange}
                                />
                              </>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </ModalBody>
                <ModalFooter className={styles.footer}>
                  <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                    Cancel
                  </Button>
                  <Button btn={BtnType.REGULAR}>
                    {mode === "add" ? "Add" : "Update"}
                  </Button>
                </ModalFooter>
              </>
            </form>
          )}
        </Formik>
      ) : (
        <EditExternalRelationship
          isCompanyUse
          relationship={relationship}
          mode="edit"
        />
      )}
    </div>
  );
};

export default EditRelationship;

import React, { memo, useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";

import { checkViewerRole } from "common/checkers/viewerChecker";
import styles from "./peopleMain.module.scss";
import {
  Switch,
  Button,
  BtnType,
  SimpleSearchComponent,
} from "../../primitives";
import ErrorBoundary from "../../../common/components/errorBoundary";

import { HeaderOptions, PeopleTypes } from "./peopleMain.constant";
import { appModelSettingSet } from "application/app.action";
import ExternalPeopleComponent from "../externalPeople";
import InternalPeopleComponent from "../internalPeople";
import AddContactMSFTChoice from "../../companies/company/overview/newContactMSFTChoice/index";
import MemberNewComponent from "../internalPeople/memberNew";
import { councilUsersListGet } from "../../../common/actions/common.action";
import { useDebounce } from "../../../common/hooks/useDebounce.hook";

const PeopleMainComponent = (props) => {
  const {
    location: { pathname },
  } = props;
  const path = pathname.split("/")[2];
  const [searchValue, setSearchValue] = useState("");
  const [selectedTab, setSelectedTab] = useState(
    path
      ? HeaderOptions.find((typ) => typ.path === path).value
      : PeopleTypes.INTERNAL
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil, invitingMember },
  } = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const debouncedValue = useDebounce(searchValue, 500);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(councilUsersListGet(selectedCouncil.id, enqueueSnackbar));
    }
  }, [selectedCouncil]);

  const handleSwitchChange = (value) => {
    setSelectedTab(value);
    const tab = HeaderOptions.find((typ) => typ.value === value);
    let url = `/people/${tab.path.toLowerCase()}`;

    if (tab.path.toLocaleLowerCase() === "internal") {
      url += "/all";
    }
    history.push({
      pathname: url,
      search: "",
      state: null,
    });
  };

  const handleAddNewMember = () => {
    if (selectedTab === PeopleTypes.INTERNAL) {
      dispatch(
        appModelSettingSet({
          ...modalSetting,
          title: "Add a Member",
          show: true,
          height: "640",
          width: "540",
          component: <MemberNewComponent excludeCompanyRole />,
        })
      );
    } else {
      dispatch(
        appModelSettingSet({
          ...modalSetting,
          title: "Add a Contact",
          show: true,
          height: "640",
          width: "540",
          component: <AddContactMSFTChoice contactType="external_contact" />,
        })
      );
    }
  };

  const tabHeaderOptions = () => {
    if (selectedCouncil && selectedCouncil.traction_tag === "gsk") {
      const opt = HeaderOptions.find((typ) => typ.value === 1);
      opt.text = "External Contacts";
      return [HeaderOptions[0], opt];
    }

    return HeaderOptions;
  };

  const handleSearchUpdate = (value) => {
    setSearchValue(value);
  };

  return (
    <div className={styles.projectMainWrp}>
      <ErrorBoundary component="PeopleMain">
        <div className={styles.searchWrapper}>
          <SimpleSearchComponent
            withoutMargin
            searchValue={debouncedValue}
            onSearchValueUpdate={handleSearchUpdate}
            placeholder={
              window.location.pathname.indexOf("internal") > 0
                ? "Search People"
                : "Search Contacts"
            }
          />
        </div>
        <div className={styles.headerWrp}>
          <h4 className={styles.headerText}>People</h4>
          <Switch
            tabs={tabHeaderOptions()}
            onChange={handleSwitchChange}
            selected={selectedTab}
          />

          {!blockFunctionality ? (
            <Button
              className={styles.newTask}
              disabled={invitingMember}
              btn={invitingMember ? BtnType.OUTLINE : BtnType.REGULAR}
              icon="icn-add"
              onClick={handleAddNewMember}
            >
              {selectedTab === PeopleTypes.INTERNAL
                ? "invite a team member"
                : "Add a contact"}
            </Button>
          ) : (
            <Tooltip
              placement="top"
              title="You have View-Only access. To Invite a Team Member, please ask your Admin to upgrade your account to Standard access."
            >
              <div className={styles.newTask}>
                <Button
                  className={styles.newTask}
                  disabled={invitingMember}
                  btn={BtnType.DISABLED}
                  icon="icn-add"
                >
                  {selectedTab === PeopleTypes.INTERNAL
                    ? "invite a team member"
                    : "Add a contact"}
                </Button>
              </div>
            </Tooltip>
          )}
        </div>

        <div className={styles.container}>
          {selectedTab === PeopleTypes.INTERNAL && (
            <div className={classNames(styles.tabContainer)}>
              <InternalPeopleComponent searchValue={searchValue} />
            </div>
          )}

          {selectedTab === PeopleTypes.EXTERNAL && (
            <div className={classNames(styles.tabContainer)}>
              <ExternalPeopleComponent searchValue={searchValue} />
            </div>
          )}
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default memo(PeopleMainComponent);

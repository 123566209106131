export const TOGGLE_PLAN_SCORE_TAB_FILTERS = 'TOGGLE_PLAN_SCORE_TAB_FILTERS'
export const togglePlanScoreTabFilters = (payload) => ({
  type: TOGGLE_PLAN_SCORE_TAB_FILTERS,
  payload,
});

export const SET_PLAN_SCORE_CARD_FILTERS = 'SET_PLAN_SCORE_CARD_FILTERS'
export const setPlanScoreCardFilters = (payload) => ({
  type: SET_PLAN_SCORE_CARD_FILTERS,
  payload,
});

export const GET_PLAN_POC_TABLE_DATA = 'GET_PLAN_POC_TABLE_DATA'
export const getPlanPocTableData = (payload) => ({
  type: GET_PLAN_POC_TABLE_DATA,
  payload,
});

export const GET_PLAN_POC_TABLE_DATA_SUCCESS = 'GET_PLAN_POC_TABLE_DATA_SUCCESS'
export const getPlanPocTableDataSuccess = (payload) => ({
  type: GET_PLAN_POC_TABLE_DATA_SUCCESS,
  payload,
});

export const GET_PLAN_SCORE_DATA = 'GET_PLAN_SCORE_DATA'
export const getPlanScoreData = (payload) => ({
  type: GET_PLAN_SCORE_DATA,
  payload,
});

export const GET_PLAN_SCORE_DATA_SUCCESS = 'GET_PLAN_SCORE_DATA_SUCCESS'
export const getPlanScoreDataSuccess = (payload) => ({
  type: GET_PLAN_SCORE_DATA_SUCCESS,
  payload,
});

export const GET_PLAN_SCORE_TABLE_DATA = 'GET_PLAN_SCORE_TABLE_DATA'
export const getPlanScoreTableData = (payload) => ({
  type: GET_PLAN_SCORE_TABLE_DATA,
  payload,
});

export const GET_PLAN_SCORE_TABLE_DATA_SUCCESS = 'GET_PLAN_SCORE_TABLE_DATA_SUCCESS'
export const getPlanScoreTableDataSuccess = (payload) => ({
  type: GET_PLAN_SCORE_TABLE_DATA_SUCCESS,
  payload,
});

export const TOGGLE_PLAN_SCORE_TABLE = 'TOGGLE_PLAN_SCORE_TABLE'
export const togglePlanScoreTable = (payload) => ({
  type: TOGGLE_PLAN_SCORE_TABLE,
  payload,
});

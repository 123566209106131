import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import styles from '../../../tractionAI.module.scss';
import TypingText from "modules/tractionAI/components/typingText";
import TrendItemResponse from "modules/tractionAI/screens/insights/trendReport/trendItemResponse";
import { AISelectScreen, AISetScoutData, AISetSWOTData } from "modules/tractionAI/store/tractionAI.actions";
import { httpPost } from "../../../../../common/httpCall";

const TractionAITrendReport = () => {
  const scrollableDivRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const {
    tractionAiReducer: { insight: { trend } }
  } = useSelector((state) => state);

  useEffect(() => {
    document.getElementById('bottomAI').scrollIntoView();
  }, []);

  useEffect(() => {
    if (trend.fetching && scrollableDivRef?.current) {
      document.getElementById('bottomAI').scrollIntoView({
        behavior: 'instant'
      });
    }
    if (!trend.fetching && scrollableDivRef?.current) {
      document.getElementById('bottomAI').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [trend.fetching, trend.data, scrollableDivRef]);

  const handleScoutSearch = (searchByQuery) => {
    dispatch(AISetScoutData({
      fetching: true,
      searchByQuery,
      createNewSearchItem: true,
      fetched: false,
    }));

    httpPost({
      call: "ai/proxy/streaming",
      data: { prompt: searchByQuery, endpoint: "recommend" },
    })
      .subscribe(() => {},(error) => {
          dispatch(AISetScoutData({
            hasError: true,
            errorMsg: error.response.error,
            errorCode: error.response.status,
            errorType: 'serverError',
            fetched: true,
            fetching: false,
          }));
        }
      )
  };

  const handleSwotSearch = (searchByQuery) => {
    dispatch(AISetSWOTData({
      fetching: true,
      searchByQuery,
      createNewSearchItem: true,
      fetched: false,
    }));

    httpPost({
      call: "ai/proxy/streaming", data: { prompt: searchByQuery, endpoint: "swot" },
    })
      .subscribe(() => {},(error) => {
          dispatch(AISetSWOTData({
            hasError: true,
            errorMsg: error.response.error,
            errorCode: error.response.status,
            errorType: 'serverError',
            fetched: true,
            fetching: false,
          }));
        }
      )
  };

  const goToNewScreen = (activeScreen, activeSubScreen, searchByQuery) => {
    dispatch(AISelectScreen({
      activeScreen,
      activeSubScreen,
    }));

    if (activeScreen === 'scout') {
      handleScoutSearch(searchByQuery);
    }

    if (activeScreen === 'insight' && activeSubScreen === 'swot') {
      handleSwotSearch(searchByQuery);
    }
  };

  return (
    <div ref={scrollableDivRef}>
      <h4 className="mb-3">Create a Trend Report</h4>
      <div className={`d-flex w-100 flex-column mb-4 ${styles.resultsWrapper}`}>
        {
          (!trend.data?.length && !trend.fetching) ? (
            <div className="mb-5 mt-5 d-flex align-items-center justify-content-center">
              <TypingText text="Enter details about trend you would like me to research." />
            </div>
          ) : null
        }
        <div className="d-flex flex-column">
          {
            trend.data.map((item, index) => (
              <TrendItemResponse
                key={`trend_${index}`}
                item={item}
                enqueueSnackbar={enqueueSnackbar}
                index={index}
                goToNewScreen={goToNewScreen}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default React.memo(TractionAITrendReport);


import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { checkViewerRole } from "common/checkers/viewerChecker";
import { appModelSettingSet } from "application/app.action";
import styles from "./externalPeople.module.scss";
import {
  MoreOptions,
  UserInline,
  UserInlineTypes,
  Pagination,
} from "../../primitives";
import {
  peopleListContactGet,
  peopleContactDelete,
  companyContactsGet,
} from "./companyContacts.action";
import {
  externalContactOptions,
  pageSettings,
} from "../peopleMain/peopleMain.constant";
import { useModelPopup } from "../../../common/hooks";

import ContactNewComponent from "./contactNew";
import ContactState from "./contactState";
import InviteMemberModal from "../../companies/company/overview/companyContact/inviteMember/index";

const ExternalPeopleComponent = ({ searchValue }) => {
  const {
    appReducer: { modalSetting },
    peopleReducer: {
      companyContactsReducer: {
        contactList,
        contactListMeta,
        updateOrgContacts,
      },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const popup = useModelPopup();
  const dispatch = useDispatch();
  const [pageSetting, setPageSetting] = useState(pageSettings(20));
  const [externalPeople, setExternalPeople] = useState([]);
  const [hasActiveSearch, setHasActiveSearch] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  const sortSearchedContacts = () => {
    const filteredPeople = contactList.filter((u) => {
      const fullName = `${u.first_name} ${u.last_name}`;
      return fullName.toLowerCase().includes(searchValue.toLowerCase());
    });

    setShowLoading(false);
    setHasActiveSearch(false);
    setExternalPeople(filteredPeople);
  };

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);
  useEffect(() => {
    if (contactList) {
      sortSearchedContacts();
    }
  }, [contactList]);

  useEffect(() => {
    // if search value isn't defined and we didn't applied search logic
    // we just set people data as usually
    if ((!searchValue || !searchValue.length) && !hasActiveSearch) {
      setExternalPeople(contactList);
    }

    // we do that comparison cause we know that in this case
    // we have asked server to give us all the data
    // and we can start filtering
    if (searchValue && contactList.length === Number(pageSetting.total)) {
      setShowLoading(false);
      sortSearchedContacts();
    }

    // if search logic isn't applied and these 2 arrays has the same length
    // we know that it is time to start making the filtering logic
    if (
      !hasActiveSearch &&
      contactList.length === pageSetting.total &&
      pageSetting.total > 20
    ) {
      setHasActiveSearch(true);
    }

    return () => null;
  }, [searchValue, contactList, hasActiveSearch]);

  useEffect(() => {
    if (selectedCouncil) {
      setShowLoading(true);
      if (selectedCouncil.traction_tag === "gsk") {
        dispatch(
          peopleListContactGet({
            currentPage: pageSetting.current,
            pageSize: pageSetting.pageSize,
            searchValue,
            enqueueSnackbar,
          })
        );
      } else {
        dispatch(
          companyContactsGet({
            currentPage: pageSetting.current,
            pageSize: pageSetting.pageSize,
            searchValue,
            enqueueSnackbar,
          })
        );
      }
    }
  }, [selectedCouncil, pageSetting.current, searchValue, updateOrgContacts]);

  useEffect(() => {
    if (contactListMeta && contactListMeta.total > 0) {
      setPageSetting((prev) => ({
        ...prev,
        total: contactListMeta.total,
      }));
    }
  }, [contactListMeta, contactListMeta.total]);

  const handlePagingChange = (page) => {
    setPageSetting((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const handleMoreOptionClick = (val, user) => {
    switch (val) {
      case "delete":
        dispatch(
          peopleContactDelete({
            contactId: user.id,
            contactName: user.first_name + " " + user.last_name,
            enqueueSnackbar,
          })
        );
        break;
      case "edit":
        dispatch(
          appModelSettingSet({
            ...modalSetting,
            title: "Edit Contact",
            show: true,
            height: "640",
            width: "540",
            component: <ContactNewComponent defaultModel={user} mode="edit" />,
          })
        );
        break;
      default:
        break;
    }
  };

  const handleInvite = (contact) => {
    popup.show({
      title: "Invite Contact",
      show: true,
      height: "300",
      width: "540",
      component: (
        <InviteMemberModal
          contact={contact}
          preselectedAccessType={{ val: 4, text: "Company" }}
          contactCompany={{
            id: contact.company_id,
            name: contact.company?.name || "",
          }}
          from="people"
        />
      ),
    });
  };

  const handleCompanyRedirect = (user) => {
    if (user.org_type === "patient") {
      history.push(
        `/patient_organizations/${user.company?.slug || user.organization_id}`
      );
      return;
    }

    history.push(`/companies/${user.company?.slug || user.organization_id}`);
  };

  return (
    <div className={styles.contentWrp}>
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.gridHeader}>
            <div className={styles.contact}>Contact</div>
            <div className={styles.contactState}> </div>
            <div className={styles.title}>Title</div>
            <div className={styles.company}>
              {selectedCouncil && selectedCouncil.traction_tag === "gsk"
                ? "Organization"
                : "Company"}
            </div>
            <div className={styles.contactInfo}>Contact Info</div>
            <div className={styles.mobile}>Phone</div>
          </div>
          <div className={styles.gridBody}>
            {!showLoading && externalPeople.length === 0 ? (
              <h3 className="d-flex mt-4 justify-content-center w-100">
                No results
              </h3>
            ) : null}
            {!showLoading && externalPeople.length
              ? externalPeople.map((user) => (
                  <div key={user.id} className={styles.gridRow}>
                    <div className={styles.contact}>
                      <UserInline
                        userId={user.id}
                        first={user.first_name || ""}
                        last={user.last_name || ""}
                        avatar={user.avatar}
                        local
                        type={UserInlineTypes.WITH_NAME}
                        fromExternal
                      />
                      {user?.origin ? (
                        <div className={styles.origin}>
                          Imported from {user?.origin}
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                    <div className={styles.contactState}>
                      <ContactState
                        handleInvite={() => {
                          handleInvite(user);
                        }}
                        user={user}
                      />
                    </div>
                    <div className={styles.title}>{user.title}</div>
                    <div
                      className={styles.company}
                      onClick={() => handleCompanyRedirect(user)}
                    >
                      {user.organization_name}
                    </div>
                    <div className={styles.contactInfo}>{user.email}</div>
                    <div className={styles.mobile}>
                      {user.mobile ? user.mobile : "--"}
                    </div>
                    <div className={styles.colMore}>
                      {!blockFunctionality && (
                        <MoreOptions
                          options={externalContactOptions}
                          className={styles.moreOptWrp}
                          onClick={(val) => handleMoreOptionClick(val, user)}
                        />
                      )}
                    </div>
                  </div>
                ))
              : null}
          </div>
          {!hasActiveSearch && externalPeople?.length > 0 ? (
            <div className={styles.paginWrp}>
              <div className={styles.paginCon}>
                <Pagination {...pageSetting} onChange={handlePagingChange} />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ExternalPeopleComponent;

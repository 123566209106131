import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  httpGet,
  httpPost,
  httpPatch,
  httpDelete,
  apiStatus,
  errorHandler,
} from "../../../common/httpCall";

import {
  TAG_TOPIC_GET_ATTEMPT,
  tagTopicGetSuccess,
  TAG_TOPIC_POST_ATTEMPT,
  tagTopicPostSuccess,
  TAG_TOPIC_DELETE_ATTEMPT,
  tagTopicDeleteSuccess,
  TAG_INDUSTRY_GET_ATTEMPT,
  tagIndustryGetSuccess,
  TAG_TOPIC_PUT_ATTEMPT,
  tagTopicPutSuccess,
  TAG_INDUSTRY_POST_ATTEMPT,
  tagIndustryPostSuccess,
  TAG_INDUSTRY_DELETE_ATTEMPT,
  tagIndustryDeleteSuccess,
  TAG_INDUSTRY_PUT_ATTEMPT,
  tagIndustryPutSuccess,
  catchErrorSuccess,
  tagRelationshipGetSuccess,
  TAG_RELATIONSHIP_GET_ATTEMPT,
  TAG_RELATIONSHIP_ADD_ATTEMPT,
  tagRelationshipAddSuccess,
  TAG_RELATIONSHIP_EDIT_ATTEMPT,
  tagRelationshipEditSuccess,
  TAG_RELATIONSHIP_DELETE_ATTEMPT,
  tagRelationshipDeleteSuccess,
  TAG_CUSTOM_GROUP_GET_ATTEMPT,
  tagCustomGetSuccess,
  TAG_CUSTOM_GROUP_ADD_ATTEMPT,
  tagCustomGroupSuccess,
  TAG_CUSTOM_GROUP_PUT_ATTEMPT,
  tagCustomGroupEditSuccess,
  TAG_CUSTOM_GROUP_DELETE_ATTEMPT,
  tagCustomGroupDeleteSuccess,
  TAG_CUSTOM_ADD_ATTEMPT,
  tagCustomSuccess,
  TAG_CUSTOM_DELETE_ATTEMPT,
  tagCustomDeleteSuccess,
} from "./adminTags.actions";

export const getTagRelationshipEpic = (action$) =>
  action$.pipe(
    ofType(TAG_RELATIONSHIP_GET_ATTEMPT),
    switchMap(({ payload: { council_id, enqueueSnackbar } }) =>
      httpGet({
        call: "council_relationship_stages",
      }).pipe(
        map((result) => {
          if (result.status === apiStatus.SUCCESS) {
            return tagRelationshipGetSuccess(result);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const postTagRelationshipEpic = (action$) =>
  action$.pipe(
    ofType(TAG_RELATIONSHIP_ADD_ATTEMPT),
    switchMap(({ payload: { council_id, enqueueSnackbar, data } }) =>
      httpPost({
        call: "council_relationship_stages",
        data,
      }).pipe(
        map((result) => {
          if (
            result.status === apiStatus.SUCCESS ||
            result.status === apiStatus.CREATED
          ) {
            enqueueSnackbar("Tag successefuly added", {
              variant: "success",
            });
            return tagRelationshipAddSuccess(result);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const patchTagRelationshipEpic = (action$) =>
  action$.pipe(
    ofType(TAG_RELATIONSHIP_EDIT_ATTEMPT),
    switchMap(({ payload: { tagId, enqueueSnackbar, data } }) =>
      httpPatch({
        call: `council_relationship_stages/${tagId}`,
        data,
      }).pipe(
        map((result) => {
          if (
            result.status === apiStatus.SUCCESS ||
            result.status === apiStatus.CREATED
          ) {
            enqueueSnackbar("Tag successefuly added", {
              variant: "success",
            });
            return tagRelationshipEditSuccess(result);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const deleteTagRelationshipEpic = (action$) =>
  action$.pipe(
    ofType(TAG_RELATIONSHIP_DELETE_ATTEMPT),
    switchMap(({ payload: { tagId, enqueueSnackbar } }) =>
      httpDelete({
        call: `council_relationship_stages/${tagId}`,
      }).pipe(
        map((result) => {
          if (
            result.status === apiStatus.SUCCESS ||
            result.status === apiStatus.CREATED
          ) {
            enqueueSnackbar("Tag successefuly deleted!", {
              variant: "success",
            });
            return tagRelationshipDeleteSuccess({ tagId });
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const getTagTopicEpic = (action$) =>
  action$.pipe(
    ofType(TAG_TOPIC_GET_ATTEMPT),
    switchMap(({ payload: { council_id, enqueueSnackbar } }) =>
      httpGet({
        call: `councils/${council_id}/topics/resource_topics`,
      }).pipe(
        map((result) => {
          if (result.status === apiStatus.SUCCESS) {
            return tagTopicGetSuccess(result);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const postTagTopicEpic = (action$) =>
  action$.pipe(
    ofType(TAG_TOPIC_POST_ATTEMPT),
    switchMap(({ payload: { council_id, enqueueSnackbar, data } }) =>
      httpPost({
        call: `councils/${council_id}/topics`,
        data,
      }).pipe(
        map((result) => {
          if (
            result.status === apiStatus.SUCCESS ||
            result.status === apiStatus.CREATED
          ) {
            enqueueSnackbar("Tag successefuly added", {
              variant: "success",
            });
            return tagTopicPostSuccess(result);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const putTagTopicEpic = (action$) =>
  action$.pipe(
    ofType(TAG_TOPIC_PUT_ATTEMPT),
    switchMap(({ payload: { council_id, topic_id, enqueueSnackbar, data } }) =>
      httpPatch({
        call: `councils/${council_id}/topics/${topic_id}`,
        data,
      }).pipe(
        map((result) => {
          if (
            result.status === apiStatus.SUCCESS ||
            result.status === apiStatus.CREATED
          ) {
            enqueueSnackbar("Tag successefuly added", {
              variant: "success",
            });
            return tagTopicPutSuccess(result);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const deleteTagTopicEpic = (action$) =>
  action$.pipe(
    ofType(TAG_TOPIC_DELETE_ATTEMPT),
    switchMap(({ payload: { council_id, topic_id, enqueueSnackbar, data } }) =>
      httpDelete({
        call: `councils/${council_id}/topics/${topic_id}`,
      }).pipe(
        map((result) => {
          if (
            result.status === apiStatus.SUCCESS ||
            result.status === apiStatus.CREATED
          ) {
            enqueueSnackbar("Tag successefuly deleted!", {
              variant: "success",
            });
            return tagTopicDeleteSuccess({ topic_id });
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const getTagIndustriesEpic = (action$) =>
  action$.pipe(
    ofType(TAG_INDUSTRY_GET_ATTEMPT),
    switchMap(({ payload: { council_id, enqueueSnackbar } }) =>
      httpGet({
        call: `councils/${council_id}/industries/resource_industries`,
      }).pipe(
        map((result) => {
          if (result.status === apiStatus.SUCCESS) {
            return tagIndustryGetSuccess(result);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const postIndustryTopicEpic = (action$) =>
  action$.pipe(
    ofType(TAG_INDUSTRY_POST_ATTEMPT),
    switchMap(({ payload: { council_id, enqueueSnackbar, data } }) =>
      httpPost({
        call: `councils/${council_id}/industries`,
        data,
      }).pipe(
        map((result) => {
          if (
            result.status === apiStatus.SUCCESS ||
            result.status === apiStatus.CREATED
          ) {
            enqueueSnackbar("Tag successefuly added", {
              variant: "success",
            });
            return tagIndustryPostSuccess(result);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const putIndustryTopicEpic = (action$) =>
  action$.pipe(
    ofType(TAG_INDUSTRY_PUT_ATTEMPT),
    switchMap(({ payload: { council_id, topic_id, enqueueSnackbar, data } }) =>
      httpPatch({
        call: `councils/${council_id}/industries/${topic_id}`,
        data,
      }).pipe(
        map((result) => {
          if (
            result.status === apiStatus.SUCCESS ||
            result.status === apiStatus.CREATED
          ) {
            enqueueSnackbar("Tag successefuly added", {
              variant: "success",
            });
            return tagIndustryPutSuccess(result);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const deleteIndustryTopicEpic = (action$) =>
  action$.pipe(
    ofType(TAG_INDUSTRY_DELETE_ATTEMPT),
    switchMap(({ payload: { council_id, topic_id, enqueueSnackbar, data } }) =>
      httpDelete({
        call: `councils/${council_id}/industries/${topic_id}`,
      }).pipe(
        map((result) => {
          if (
            result.status === apiStatus.SUCCESS ||
            result.status === apiStatus.CREATED
          ) {
            enqueueSnackbar("Tag successefuly deleted!", {
              variant: "success",
            });
            return tagIndustryDeleteSuccess({ topic_id });
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const getCustomTagGroupsEpic = (action$) =>
  action$.pipe(
    ofType(TAG_CUSTOM_GROUP_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        call: `council_custom_tag_groups?with_custom_tags=true`,
      }).pipe(
        map((result) => {
          if (result.status === apiStatus.SUCCESS) {
            return tagCustomGetSuccess(result);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const postCustomTagGroupEpic = (action$) =>
  action$.pipe(
    ofType(TAG_CUSTOM_GROUP_ADD_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, data } }) =>
      httpPost({
        call: `council_custom_tag_groups`,
        data,
      }).pipe(
        map((result) => {
          if (
            result.status === apiStatus.SUCCESS ||
            result.status === apiStatus.CREATED
          ) {
            enqueueSnackbar("Group successefuly added", {
              variant: "success",
            });
            return tagCustomGroupSuccess(result);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const patchCustomTagGroupEpic = (action$) =>
  action$.pipe(
    ofType(TAG_CUSTOM_GROUP_PUT_ATTEMPT),
    switchMap(({ payload: { tagId, enqueueSnackbar, data } }) =>
      httpPatch({
        call: `council_custom_tag_groups/${tagId}`,
        data,
      }).pipe(
        map((result) => {
          if (
            result.status === apiStatus.SUCCESS ||
            result.status === apiStatus.CREATED
          ) {
            enqueueSnackbar("Group was successefuly edited", {
              variant: "success",
            });
            return tagCustomGroupEditSuccess(result);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );
export const deleteCustomGroupEpic = (action$) =>
  action$.pipe(
    ofType(TAG_CUSTOM_GROUP_DELETE_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpDelete({
        call: `council_custom_tag_groups/${id}`,
      }).pipe(
        map((result) => {
          if (
            result.status === apiStatus.SUCCESS ||
            result.status === apiStatus.CREATED
          ) {
            enqueueSnackbar("Group successefuly deleted!", {
              variant: "success",
            });
            return tagCustomGroupDeleteSuccess({ id });
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const postCustomTagEpic = (action$) =>
  action$.pipe(
    ofType(TAG_CUSTOM_ADD_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, data, groupID } }) =>
      httpPost({
        call: `council_custom_tag_groups/${groupID}/custom_tags`,
        data,
      }).pipe(
        map((result) => {
          if (
            result.status === apiStatus.SUCCESS ||
            result.status === apiStatus.CREATED
          ) {
            enqueueSnackbar("Tag successefuly added", {
              variant: "success",
            });
            return tagCustomSuccess(result);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const deleteCustomTagEpic = (action$) =>
  action$.pipe(
    ofType(TAG_CUSTOM_DELETE_ATTEMPT),
    switchMap(({ payload: { id, groupId, enqueueSnackbar } }) =>
      httpDelete({
        call: `council_custom_tag_groups/${groupId}/custom_tags/${id}`,
      }).pipe(
        map((result) => {
          if (
            result.status === apiStatus.SUCCESS ||
            result.status === apiStatus.CREATED
          ) {
            enqueueSnackbar("Tag successefuly deleted!", {
              variant: "success",
            });

            return tagCustomDeleteSuccess({ id, data: result.response.data });
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

const adminTagsEpic = combineEpics(
  postTagTopicEpic,
  getTagTopicEpic,
  deleteTagTopicEpic,
  getTagIndustriesEpic,
  putTagTopicEpic,
  deleteIndustryTopicEpic,
  putIndustryTopicEpic,
  postIndustryTopicEpic,
  getTagRelationshipEpic,
  postTagRelationshipEpic,
  patchTagRelationshipEpic,
  deleteTagRelationshipEpic,
  postCustomTagGroupEpic,
  postCustomTagEpic,
  getCustomTagGroupsEpic,
  patchCustomTagGroupEpic,
  deleteCustomGroupEpic,
  deleteCustomTagEpic
);

export default adminTagsEpic;

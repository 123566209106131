import React, { useMemo } from "react";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import { ModalBody, ModalFooter } from "../../../../../application/modal";
import { BtnType, Button } from "../../../../../modules/primitives";
import { useModelPopup } from "../../../../../common/hooks";
import { customProjectTaskUpdate } from "modules/projects/project/tasks/tasks.action";

const LessonLearnedModal = ({ lessonsData, taskInstance, task }) => {
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const isAlreadyCompleted = useMemo(() => {
    if (!taskInstance) return false;

    const assignedNr = task?.projects_task_assignments?.filter(
      (a) => a.completed
    ).length;

    return assignedNr === task?.projects_task_assignments?.length;
  }, [task, taskInstance]);

  const handleClose = () => {
    if (taskInstance && !isAlreadyCompleted) {
      const payload = {
        enqueueSnackbar,
        taskId: task.id,
        projectId: task.project_id,
        flag: "completed_true",
        project_task: {
          completed_on: format(new Date(), "yyyy-MM-dd"),
        },
        queryParams: {
          all_assignments: true,
        },
      };

      dispatch(customProjectTaskUpdate(payload));
    }

    popup.hide();
  };

  return (
    <div>
      <ModalBody>
        <div className="d-flex flex-column">
          {
            taskInstance ? (
              lessonsData.map(lesson => (
                <div className="d-flex flex-column mb-2" key={lesson.project_id}>
                  <span className="font-weight-bold mb-1">{lesson.name}</span>
                  <div>
                    {
                      lesson.lessons_learned_project_fields?.map(l => (
                        <div className="d-flex flex-column">
                          <span>{l.field_name}</span>
                          <p>{l.field_value || "-"}</p>
                        </div>
                      ))
                    }
                    <hr className="mb-2" />
                  </div>
                </div>
              ))
            ) : (
              lessonsData.map((lesson, index) => (
                <div className="d-flex flex-column mb-2" key={`${lesson.field_name}_${index}`}>
                  <span>{lesson.field_name}</span>
                  <p>{lesson.field_value || '-'}</p>
                </div>
              ))
            )
          }
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" btn={BtnType.REGULAR} onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </div>
  );
};
export default LessonLearnedModal;

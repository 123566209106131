import * as Yup from "yup";

export const initModel = {
  patient_organization_id: "",
  organization_id: "",
  displayName: "",
};

export const validation = Yup.object().shape(
  {
    // displayName: Yup.string().required('Contact Required'),
    patient_organization_id: Yup.string().when("organization_id", {
      is: (val) => !val,
      then: Yup.string().required("Company/Organization required"),
      otherwise: Yup.string(),
    }),
    organization_id: Yup.string().when("patient_organization_id", {
      is: (val) => !val,
      then: Yup.string().required("Company/Organization required."),
      otherwise: Yup.string(),
    }),
  },
  [["patient_organization_id", "organization_id"]],
);

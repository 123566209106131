export const TOGGLE_KPIS_TAB_FILTERS = 'TOGGLE_KPIS_TAB_FILTERS'
export const toggleKPIsTabFilters = (payload) => ({
  type: TOGGLE_KPIS_TAB_FILTERS,
  payload,
});

export const SET_KPIS_CARD_FILTERS = 'SET_KPIS_CARD_FILTERS'
export const setKPIsCardFilters = (payload) => ({
  type: SET_KPIS_CARD_FILTERS,
  payload,
});

export const GET_KPIS_TABLE_DATA = 'GET_KPIS_TABLE_DATA'
export const getKPIsTableData = (payload) => ({
  type: GET_KPIS_TABLE_DATA,
  payload,
});

export const GET_KPIS_TABLE_DATA_SUCCESS = 'GET_KPIS_TABLE_DATA_SUCCESS'
export const getKPIsTableDataSuccess = (payload) => ({
  type: GET_KPIS_TABLE_DATA_SUCCESS,
  payload,
});

export const TOGGLE_KPIS_TABLE = 'TOGGLE_KPIS_TABLE'
export const toggleKPIsTable = (payload) => ({
  type: TOGGLE_KPIS_TABLE,
  payload,
});

export const KPIS_GET_NR_OF_PROJECTS = 'KPIS_GET_NR_OF_PROJECTS'
export const getKPIsNrOfProjects = (payload) => ({
  type: KPIS_GET_NR_OF_PROJECTS,
  payload,
});

export const KPIS_GET_NR_OF_PROJECTS_SUCCESS = 'KPIS_GET_NR_OF_PROJECTS_SUCCESS'
export const getKPIsNrOfProjectsSuccess = (payload) => ({
  type: KPIS_GET_NR_OF_PROJECTS_SUCCESS,
  payload,
});

export const KPIS_GET_NRS_DATA = 'KPIS_GET_NRS_DATA'
export const getKPIsNrsData = (payload) => ({
  type: KPIS_GET_NRS_DATA,
  payload,
});

export const KPIS_GET_NRS_DATA_SUCCESS = 'KPIS_GET_NRS_DATA_SUCCESS'
export const getKPIsNrsDataSuccess = (payload) => ({
  type: KPIS_GET_NRS_DATA_SUCCESS,
  payload,
});

export const KPIS_GET_CLIENT_PLANTS = 'KPIS_GET_CLIENT_PLANTS'
export const getKPIsClientPlants = (payload) => ({
  type: KPIS_GET_CLIENT_PLANTS,
  payload,
});

export const KPIS_GET_CLIENT_PLANTS_SUCCESS = 'KPIS_GET_CLIENT_PLANTS_SUCCESS'
export const getKPIsClientPlantsSuccess = (payload) => ({
  type: KPIS_GET_CLIENT_PLANTS_SUCCESS,
  payload,
});

export const KPIS_GET_INNOVATION_CATEGORY = 'KPIS_GET_INNOVATION_CATEGORY'
export const getKPIsInnovationCategory = (payload) => ({
  type: KPIS_GET_INNOVATION_CATEGORY,
  payload,
});

export const KPIS_GET_INNOVATION_CATEGORY_SUCCESS = 'KPIS_GET_INNOVATION_CATEGORY_SUCCESS'
export const getKPIsInnovationCategorySuccess = (payload) => ({
  type: KPIS_GET_INNOVATION_CATEGORY_SUCCESS,
  payload,
});

export const KPIS_GET_REPLICATIONS_NR = 'KPIS_GET_REPLICATIONS_NR'
export const getKPIsReplicationsNr = (payload) => ({
  type: KPIS_GET_REPLICATIONS_NR,
  payload,
});

export const KPIS_GET_REPLICATIONS_NR_SUCCESS = 'KPIS_GET_REPLICATIONS_NR_SUCCESS'
export const getKPIsReplicationsNrSuccess = (payload) => ({
  type: KPIS_GET_REPLICATIONS_NR_SUCCESS,
  payload,
});

export const KPIS_NEXT_PHASE_CHANGE = 'KPIS_NEXT_PHASE_CHANGE'
export const getKPIsNextPhaseChange = (payload) => ({
  type: KPIS_NEXT_PHASE_CHANGE,
  payload,
});

export const KPIS_NEXT_PHASE_CHANGE_SUCCESS = 'KPIS_NEXT_PHASE_CHANGE_SUCCESS'
export const getKPIsNextPhaseChangeSuccess = (payload) => ({
  type: KPIS_NEXT_PHASE_CHANGE_SUCCESS,
  payload,
});

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import styles from "./estimatedRevenue.module.scss";
import { Icon, smSize } from "../../../../../../common/icon";
import {
  queryParamStringToArray,
  queryStringUrlReplacement,
  useQuery,
} from "../../../../../../common/helper";
import { MultiSelect } from "../../../../../primitives";
import { ESTIMATED_REVENUE_VALUES } from "../../../../../../common/constants";

const EstimatedRevenueFilter = ({ expanded, handleExpand, disabled }) => {
  const history = useHistory();
  const estimatedRevenueQuery = useQuery().get("estimatedRevenue");

  const [collapsed, setCollapsed] = useState(false);
  const [estimatedRevenue, setEstimatedRevenue] = useState([]);

  useEffect(() => {
    setCollapsed(expanded);
  }, [expanded]);

  useEffect(() => {
    if (estimatedRevenueQuery) {
      const arr = queryParamStringToArray(estimatedRevenueQuery);
      const mappedValue = ESTIMATED_REVENUE_VALUES.filter((e) => arr.includes(e.id));
      setEstimatedRevenue(mappedValue);

      return;
    }

    setEstimatedRevenue([
      {
        id: "Any",
        value: "Any",
      },
    ]);

    return () => null;
  }, [estimatedRevenueQuery]);

  const handleCollapseClick = () => {
    if (disabled) {
      return;
    }

    if (!collapsed) {
      handleExpand("estimatedRevenue");
    }
    setCollapsed(!collapsed);
  };

  const handleOnChange = (value) => {
    let clearVal;
    const currentHasAny = value.some((e) => e.id === "Any");
    const hasAny = estimatedRevenue.some((e) => e.id === "Any");

    if (currentHasAny && value.length > 1) {
      clearVal = value
        .filter((elem) => elem.id !== "Any")
        .reduce((acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`, "");
    }

    if (!hasAny && currentHasAny) {
      clearVal = "Any";
    }

    if (!currentHasAny) {
      clearVal = value.reduce(
        (acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`,
        "",
      );
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      "estimatedRevenue",
      clearVal === "Any" ? "" : clearVal,
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        estimatedRevenue: clearVal === "Any" ? "" : clearVal,
        page: 1,
      },
    });
  };

  return (
    <div className={`${styles.itemWrapper} ${disabled ? styles.disabled : ''}`} id="estimatedRevenue">
      <div className={styles.itemTitle} onClick={handleCollapseClick}>
        <span>Estimated Revenue</span>
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(styles.icon, !collapsed && styles.collapse)}
          onClick={handleCollapseClick}
        />
      </div>
      {collapsed ? (
        <div
          className={classNames(
            "d-flex align-items-center",
            styles.withPaddingLeft,
          )}
        >
          <div
            id="estimatedRevenue"
            className={classNames("d-flex flex-column", styles.employeesField)}
          >
            <MultiSelect
              placeholder=""
              labelField="value"
              valueField="id"
              searchBy="value"
              options={ESTIMATED_REVENUE_VALUES}
              values={estimatedRevenue}
              onChange={(val) => handleOnChange(val)}
              dropdownPosition="auto"
              disabled={disabled}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(EstimatedRevenueFilter);

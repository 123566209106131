import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withSnackbar } from "notistack";
import CompanyMainComponent from "./companyMain";
import CompanyComponent from "./company/company.component";
import Product from "modules/companies/product";

const CompaniesComponent = () => {
  return (
    <Switch>
      <Route path="/companies/id/:id" exact render={({ match }) => (
        <Redirect to={`/companies/${match.params.id}`} />
      )} />
      <Route exact path="/companies/:id/:page" component={CompanyComponent} />
      <Route exact path="/companies/:id" component={CompanyComponent} />
      <Route exact path="/companies/:id/products/:productId" component={Product} />
      <Route exact path="/companies" component={CompanyMainComponent} />
    </Switch>
  );
};

export default React.memo(withSnackbar(CompaniesComponent));

import {
  SETTINGS_TECHNOLOGIES_SUCCESS,
  SETTINGS_INDUSTRIES_SUCCESS,
  GENERAL_SETTINGS_SUCCESS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_FOLLOWERS_SUCCESS,
} from "./settings.action";
import { apiStatus } from "../../common/httpCall";
import {
  SETTINGS_EDIT_SUCCESS,
  SUGGESTION_FOLLOW_ATTEMPT,
  SUGGESTION_FOLLOW_SUCCESS,
  COMPANY_UNFOLLOW_SUCCESS,
  COMPANY_UNFOLLOW_ATTEMPT,
  COMPANY_FOLLOW_MANY_SUCCESS,
  COMPANY_FOLLOW_MANY_ATTEMPT,
  COMPANY_UNFOLLOW_MANY_ATTEMPT,
  COMPANY_UNFOLLOW_MANY_SUCCESS,
  CHANGE_FOLLOW_RELATIONSHIP,
} from "../../common/actions/common.action";

const initState = {
  generalSettings: null,
  technologies: [],
  industries: [],
  notifications: [],
  followers: null,
  deletedFollower: [],
  updateFollowers: false,
  removedCompany: false,
  buttonLoader: false,
  replacedRelationship: false,
};

const settingsReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SUGGESTION_FOLLOW_ATTEMPT: {
      return {
        ...state,
        updateFollowers: false,
      };
    }
    case SUGGESTION_FOLLOW_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            updateFollowers: true,
            followers: state.followers
              ? [...state.followers, response]
              : [response],
          };
        }
      }
      break;

    case GET_FOLLOWERS_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            followers: response,
          };
        }
      }
      break;

    case GET_NOTIFICATIONS_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            notifications: response,
          };
        }
      }
      break;

    case GENERAL_SETTINGS_SUCCESS:
    case SETTINGS_EDIT_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            generalSettings: {
              ...response,
              time_zone: response.time_zone || "EST5EDT",
            },
          };
        }
        // if (payload.id) {
        //   return {
        //     ...state,
        //     generalSettings: {
        //       ...payload,
        //       time_zone: payload.time_zone || "EST5EDT",
        //     },
        //   };
        // }
      }
      break;

    case SETTINGS_TECHNOLOGIES_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            technologies: response,
          };
        }
      }
      break;

    case SETTINGS_INDUSTRIES_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          industries: response,
        };
      }
    }
    case COMPANY_UNFOLLOW_ATTEMPT: {
      return {
        ...state,
        updateFollowers: false,
        removedCompany: false,
      };
    }

    case COMPANY_UNFOLLOW_SUCCESS: {
      const { result, data } = payload;
      if (result.status === apiStatus.SUCCESS) {
        return {
          ...state,
          updateFollowers: true,
          removedCompany: true,
        };
      }
    }

    case COMPANY_FOLLOW_MANY_ATTEMPT: {
      return {
        ...state,
        updateFollowers: false,
        removedCompany: true,
        buttonLoader: true,
      };
    }

    case COMPANY_FOLLOW_MANY_SUCCESS: {
      const { response, status } = payload;

      return {
        ...state,
        updateFollowers: true,
        removedCompany: false,
        buttonLoader: false,
      };
    }
    case COMPANY_UNFOLLOW_MANY_ATTEMPT: {
      return {
        ...state,
        updateFollowers: false,
        buttonLoader: true,
      };
    }

    case CHANGE_FOLLOW_RELATIONSHIP: {
      const oldItem = state.followers.find(
        (item) => item.following.company_id === payload
      );

      const changedItem = {
        ...oldItem,
        following_id: state.followers[state.followers.length - 1].following_id,
        follow_relationship_id:
          state.followers[state.followers.length - 1].follow_relationship_id,
      };

      const changedItemIndex = state.followers.indexOf(oldItem);

      const updatedFollowers = state.followers
        .map((item, index) => {
          if (index === changedItemIndex) {
            return changedItem;
          }
          return item;
        })
        .slice(0, -1);
      // const filteredArray = state.followers[state.followers.length - 1].following.company_id === payload ? updatedFollowers.slice(0, -1) : updatedFollowers

      return {
        ...state,
        followers: updatedFollowers,
        replacedRelationship: true,
      };
    }

    case COMPANY_UNFOLLOW_MANY_SUCCESS:
      {
        const { response, status } = payload;

        return {
          ...state,
          updateFollowers: true,
          buttonLoader: false,
        };
      }
      break;

    default:
      return state;
  }
  return state;
};

export default settingsReducer;

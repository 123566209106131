import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  httpGet,
  errorHandler,
  httpPost,
  httpDelete,
} from "../../../common/httpCall";
import {
  THEMES_HOMEPAGE_GET_ATTEMPT,
  themesHomepageGetSuccess,
  THEMES_HOMEPAGE_POST_ATTEMPT,
  themesHomepagePostSuccess,
  THEMES_HOMEPAGE_REMOVE_ATTEMPT,
  themesHomepageRemoveSuccess,
} from "./themesHomePage.action";

export const epicHomepageGetThemes = (action$, state$) => {
  return action$.pipe(
    ofType(THEMES_HOMEPAGE_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, items, page } }) =>
      httpGet({
        apiVersion: "v2",
        call: `themes/user_homepage_themes?items=${items || 20}&page=${
          page || 1
        }`,
      }).pipe(
        map((result) => themesHomepageGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
};

export const epicHomepagePostThemes = (action$, state$) => {
  return action$.pipe(
    ofType(THEMES_HOMEPAGE_POST_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, themeId, default_theme_home } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/${themeId}/user_homepage_themes`,
        data: { default_theme_home },
      }).pipe(
        map((result) => themesHomepagePostSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
};

export const epicHomepageRemoveThemes = (action$, state$) => {
  return action$.pipe(
    ofType(THEMES_HOMEPAGE_REMOVE_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, themeId } }) =>
      httpDelete({
        apiVersion: "v1",
        call: `themes/${themeId}/user_homepage_themes`,
      }).pipe(
        map((result) => themesHomepageRemoveSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
};

const themesHomepageEpic = combineEpics(
  epicHomepageGetThemes,
  epicHomepagePostThemes,
  epicHomepageRemoveThemes
);

export default themesHomepageEpic;

import React, { useState } from 'react';
import ReactQuill from 'react-quill-new';

import 'react-quill-new/dist/quill.snow.css';

const TractionRichEditor = ({ placeholder, initialValue, handleChange, minHeight = 200 }) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (value) => {
    setValue(value);
    handleChange(value);
  }

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      style={{ height: `${minHeight}px` }}
      placeholder={placeholder}
    />
  );
};

export default React.memo(TractionRichEditor);

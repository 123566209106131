import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { Label } from "modules/primitives";
import CheckBox from "modules/admin/adminIdeas/addForm/checkBox";
import styles from "./styles.module.scss";
import MembersItem from "../../memberItem";

const ProjectIdeaMembers = ({ formikprops, isAdminWizardInstance, additionalData, wizardType }) => {
  const {
    authReducer: { session },
  } = useSelector((state) => state);

  const getMembers = useMemo(() => {
    if (!additionalData) return [];

    const prop = additionalData[wizardType === 'theme' ? 'members' : 'ideas_members'] || [];
    if (!prop?.length) {
      return [];
    }

    return prop.filter(m => m.user_id !== session.id);
  }, [session, additionalData]);

  const selectAllMembers = useCallback(() => {
    const newValue = !formikprops.values.select_all_members
    formikprops.setFieldValue('select_all_members', newValue);

    if (!newValue) {
      formikprops.setFieldValue('member_ids', []);
    } else {
      formikprops.setFieldValue('member_ids', getMembers.map(member => member.user_id));
    }
  }, [formikprops, getMembers]);

  useEffect(() => {
    if (isAdminWizardInstance) return;
    if (getMembers) {
      selectAllMembers();
    }
  }, [getMembers]);

  const storeMembers = useCallback((memberId) => {
    const { values, setFieldValue } = formikprops;

    if (values.member_ids.includes(memberId)) {
      const filteredArray = values.member_ids
        .filter((selectedMemberId) => selectedMemberId !== memberId);

      setFieldValue('member_ids', filteredArray || []);

      if (filteredArray.length !== getMembers.length) {
        setFieldValue('select_all_members', false);
      }
    } else {
      const findMember = getMembers.find((member) => member.user_id === memberId);

      if (!findMember) {
        return;
      }

      const ids = [...values.member_ids, findMember.user_id];

      if (ids.length === getMembers.length) {
        setFieldValue('select_all_members', true);
      }

      setFieldValue('member_ids', [...values.member_ids, findMember.user_id]);
    }
  }, [formikprops, getMembers]);

  return (
    <div className="d-flex flex-column w-100 mb-3">
      {
        !isAdminWizardInstance && (
          <>
            <Label>Select member</Label>
            <div className={styles.selectAllCompanies}>
              <Label>Select all members</Label>
              <CheckBox
                onChange={selectAllMembers}
                className={styles.selectMemberCheckbox}
                isChecked={formikprops.values?.select_all_members}
              />
            </div>
          </>
        )
      }
      {
        isAdminWizardInstance ? (
          <div>
            Idea Members
          </div>
        ) : (
          <div className={styles.membersBlockContainer}>
            {
              getMembers.map((member) => (
                <div
                  key={`${member.user_id}`}
                  className={styles.blockItem}
                >
                  <MembersItem
                    storeMembers={storeMembers}
                    member={{
                      ...member,
                      avatar: member.user_avatar || member.avatar,
                      name: member.user_first_name || member.name
                    }}
                    selectAllMembers={formikprops.values?.select_all_members}
                    selected={formikprops.values.member_ids?.includes(member.user_id)}
                  />
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
};

export default React.memo(ProjectIdeaMembers);

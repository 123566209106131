import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import TasksComponent from "modules/projects/project/tasks/tasks.component";

const TasksWrapperComponent = ({ canManageTasks }) => {
  const {
    authReducer: { session },
    councilsReducer: { councilData },
    councilReducer: { selectedCouncil },
    projectsReducer: {
      projectReducer: { selectedProject },
    },
  } = useSelector((state) => state);

  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const isGatewayTaskSelected = urlParams.get("gatewayTask");
  const selectedTaskId = urlParams.get("taskId");

  if (!session?.id || !selectedCouncil?.id || !selectedProject?.id || !councilData?.settings) {
    return
  }

  return (
    <TasksComponent
      councilData={councilData}
      canManageTasks={canManageTasks}
      userId={session.id}
      selectedProject={selectedProject}
      selectedCouncil={selectedCouncil}
      isGatewayTaskSelected={isGatewayTaskSelected}
      selectedTaskId={selectedTaskId}
    />
  );
};

export default React.memo(TasksWrapperComponent);

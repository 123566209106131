import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { format } from "date-fns";
import ReactTable from "react-table";

import { Loading } from "modules/primitives/index";
import styles from "./bulkUpload.module.scss";
import AttachFileComponent from "../../../../common/components/attachFile/index";
import { httpPost, errorHandler, httpGet } from "../../../../common/httpCall";
import { smSize } from "../../../../common/icon";
import Icon from "../../../../common/icon/icon.component";

const domain = `${window.location.protocol}//${window.location.host}`;
const downloadUrl =
  domain === "https://traction.network"
    ? "https://apiv2.traction.network/Traction_Upload_Template.xlsx"
    : "https://api.traction.network/Traction_Upload_Template.xlsx";

const BulkUploadComponent = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingToTraction, setLodingToTraction] = useState(false);
  const [loadingGetFiles, setLoadingGetFiles] = useState(false);

  const getBulkFiles = () => {
    setLoadingGetFiles(true);
    try {
      httpGet({
        call: `council_companies/uploads`,
        apiVersion: "v2",
      }).subscribe(
        async (res) => {
          setUploadedFiles(res.response);
          setLoadingGetFiles(false);
        },
        (err) => {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
          setLoadingGetFiles(false);
        }
      );
    } catch (error) {
      console.log("---------error", error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
      setLoadingGetFiles(false);
    }
  };

  useEffect(() => {
    getBulkFiles();
  }, []);

  const handleUpload = (url, name) => {
    // if (name.indexOf(" ") > 0) {
    //   enqueueSnackbar(
    //     "Your file name is not supported. Please make sure your file name does not contain any empty spaces (use an underscore “_” or hyphen “-” instead of spaces).",
    //     {
    //       variant: "error",
    //     }
    //   );
    //   return;
    // }
    if (url) {
      setLodingToTraction(true);

      httpPost({
        call: `council_companies/uploads`,
        apiVersion: "v2",
        data: {
          file_url: url,
        },
      })
        .pipe(
          catchError((err) => {
            setLodingToTraction(false);
            errorHandler(err, enqueueSnackbar);
            return of(null);
          })
        )
        .subscribe(
          (res) => {
            if (res) {
              setLodingToTraction(false);
              getBulkFiles();
              enqueueSnackbar(
                "Your file uploaded successfully. Depending on the number of companies uploaded, it may take up to a few hours to add them all to your Traction database. You will be notified by email when the job is complete.",
                {
                  variant: "success",
                }
              );
            }
          },
          (err) => {
            console.log("------err---", err);
            setLodingToTraction(false);
            enqueueSnackbar("Something went wrong", {
              variant: "error",
            });
          }
        );
    }
  };

  const checkForStatus = (status) => {
    if (status === "pending") {
      return "pending";
    }
    if (status === "completed") {
      return "success";
    }
    if (status === "failed") {
      return "failed";
    }
    return "pending";
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "File Name",
        id: "file_url",
        sortable: false,
        name: "file_url",
        accessor: (row) => (
          <span className={styles.fileName}>
            {row.file_url?.slice(
              row.file_url.indexOf("/uploads") + 9,
              row.file_url.length
            )}
          </span>
        ),
        width: 200,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Upload Status",
        id: "status",
        sortable: false,
        name: "status",
        accessor: (row) => (
          <span className={styles[checkForStatus(row.status)]}>
            {row.status}
          </span>
        ),
        width: 200,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Processed Companies",
        id: "companies_processed",
        sortable: false,
        name: "companies_processed",
        accessor: (row) => (
          <span className={styles.fileName}>{row.companies_processed}</span>
        ),
        width: 200,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Date Added",
        id: "created_at",
        sortable: false,
        name: "created_at",
        accessor: (row) => (
          <span className={styles.fileName}>
            {format(new Date(row.created_at), "eeee, MMM do, yyyy")}
          </span>
        ),
        width: 200,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Uploaded By",
        id: "user_name",
        sortable: false,
        name: "user_name",
        accessor: (row) => (
          <span className={styles.fileName}>{row?.user_name || "-"}</span>
        ),
        width: 200,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Message",
        id: "error_message",
        sortable: false,
        name: "error_message",
        accessor: (row) => (
          <span className={styles.errorMessage}>
            {row.error_message || "-"}
          </span>
        ),
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [uploadedFiles]
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>BULK UPLOADS</div>
      <div>
        In order to upload lists of companies to your Traction database, please
        first download and fill out the attached template. Then upload the bulk
        list below. Limit of 5,000 companies per spreadsheet. Please follow the
        required data format:
        <br />
        <br />
        <ol>
          <li>First column = "Company Name"</li>
          <li>
            Second column = "Company Website" (The company website serves as a
            unique identifier for each company).
          </li>
          <li>Supported file formats: “xlxs", "xlsx", "xls", "csv"</li>
        </ol>
        <div className={styles.uploadButtonContainer}>
          <div className="d-flex align-items-center mr-5">
            <span className="d-flex mr-3">Step 1.</span>
            <a href={downloadUrl} download>
              <Icon {...smSize} icon="arrow-down2" />
              <span>Download template</span>
            </a>
          </div>
          <div className="d-flex">
            <span className="d-flex mr-3">Step 2.</span>
            <div className={styles.attachFile}>
              <AttachFileComponent
                files={[]}
                onChange={handleUpload}
                uploadDirName={`council_companies/uploads/`}
                replaceImage={handleUpload}
                hideDeleteIcons
                customFormats={["xlxs", "xlsx", "xls", "csv"]}
                customText="UPLOAD FILE"
                isBtnInstance
                customFormatError={
                  'The file format you uploaded is not supported. Please use one of these supported file extensions:  "xlsx", "xls", “xlxs", "csv"'
                }
              />
            </div>
          </div>
          {loadingToTraction && <Loading />}
        </div>
        <ReactTable
          columns={columns}
          data={uploadedFiles}
          showPagination={uploadedFiles.length > 20}
          minRows={5}
          loading={loadingGetFiles ? <Loading /> : null}
          pageSize={20}
          className={styles.table}
        />
      </div>
    </div>
  );
};

export default BulkUploadComponent;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Label, MultiSelect, BtnType, Button,
} from "modules/primitives/index";
import Radio from "@mui/material/Radio";
import { ModalBody, ModalFooter } from "application/modal";
import styles from "../adminCompanies.module.scss";

import { deleteTractionCompaniesAttempt } from "../tractionCompanies.actions";

const CompanyDeleteModal = (props) => {
  const {
    popup, company, id, enqueueSnackbar,
  } = props;
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState(false);
  const [selectedCouncils, setSelectedCouncils] = useState([]);
  const handleSubmit = () => {
    const data = {
      remove_from_main_db: selectedValue,
    };

    if (!selectedValue) {
      data.council_ids = selectedCouncils.map((council) => council.id);
    }

    dispatch(
      deleteTractionCompaniesAttempt({ companyId: id, enqueueSnackbar, data }),
    );
    popup.hide();
  };

  const handleChange = () => {
    setSelectedValue((prevState) => !prevState);
  };

  const handleOptionSelect = (val) => {
    setSelectedCouncils(val);
  };

  return (
    <>
      <ModalBody>
        <div className={styles.radioContainer}>
          <div className={styles.radioRow}>
            <Radio
              checked={!selectedValue}
              onChange={handleChange}
              name="radio-buttons"
              className={styles.MuiRadio}
            />
            <span>Delete from council</span>
          </div>
        </div>
        {!selectedValue && (
          <div className={styles.multiSelectContainer}>
            <Label>Select Councils</Label>
            <MultiSelect
              onChange={handleOptionSelect}
              placeholder="Select Council"
              labelField="name"
              valueField="id"
              searchBy="name"
              options={company.councils}
              multi
              // className={styles.unitSelect}
              values={selectedCouncils}
              // keepOpen
              dropdownGap={2}
            />
          </div>
        )}
        <div className={styles.radioContainer}>
          <div className={styles.radioRow}>
            <Radio
              checked={selectedValue}
              onChange={handleChange}
              name="radio-buttons"
              className={styles.MuiRadio}
            />
            <span>Delete from Main Traction DB</span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={() => popup.hide()}>
          Close
        </Button>
        <Button btn={BtnType.DELETE} onClick={handleSubmit}>
          Delete
        </Button>
      </ModalFooter>
    </>
  );
};

export default CompanyDeleteModal;

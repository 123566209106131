import React, { useRef } from "react";
import { useSelector } from "react-redux";

import { filterOptions } from "../themes.constant";
import styles from "./styles.module.scss";
// import { Button, BtnType } from "../../primitives/index";
// import { mdSize } from "../../../common/icon/icon.constants";
import ThemeModal from "./themeMainModal/index";
import { useModelPopup } from "../../../common/hooks";
import DropSelectWithInput from "../themesCommon/dropSelectWithInput/index";
import CheckThemeRole from "../themesCommon/checkThemeRole/index";

const ThemesTopActions = ({
  activeLayout,
  total_themes,
  total_members,
  total_companies,
  setSortOrder,
  setFilterOption,
  filterOption,
  userRole,
}) => {
  const ref = useRef();
  const popup = useModelPopup();
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const addComma = (number) => {
    const stringNumber = number.toString();
    const arrayString = stringNumber.split("");
    arrayString.splice(-3, 0, ",");
    return arrayString.join("");
  };

  const handleAddThemeModal = () => {
    popup.show({
      title: `Add new ${
        selectedCouncil?.settings?.themes_display_name_singular || "Theme"
      }`,
      show: true,
      component: <ThemeModal popup={popup} fromRef={ref} />,
    });
  };
  return (
    <div className={styles.topActionsContainer}>
      <div className={styles.headerText}>
        {selectedCouncil?.settings?.themes_display_name || "Themes"}{" "}
      </div>
      <div className={styles.topActionsSettingsContainer}>
        <div className={styles.settingsStats}>
          <div className={styles.statsItem}>
            <p className={styles.itemNumber}>
              {total_companies.toString().length > 3
                ? addComma(total_companies)
                : total_companies}
            </p>
            <p className={styles.itemText}>Total Companies</p>
          </div>
          <div className={styles.statsItem}>
            <p className={styles.itemNumber}>
              {total_members.toString().length > 3
                ? addComma(total_members)
                : total_members}
            </p>
            <p className={styles.itemText}>Total Followers</p>
          </div>
          <div className={styles.statsItem}>
            <p className={styles.itemNumber}>
              {total_themes.toString().length > 3
                ? addComma(total_themes)
                : total_themes}
            </p>
            <p className={styles.itemText}>
              Total {selectedCouncil?.settings?.themes_display_name || "Themes"}
            </p>
          </div>
        </div>

        <div className={styles.topActionsFilterAndNewTheme}>
          {activeLayout === "card" ? (
            <>
              <DropSelectWithInput
                selectedValue={filterOptions
                  .map((opt) => {
                    if (opt.text === "My Themes") {
                      return {
                        ...opt,
                        text: `My ${
                          selectedCouncil?.settings?.themes_display_name ||
                          "Themes"
                        }`,
                      };
                    }
                    return opt;
                  })
                  .find((opt) => opt.value === filterOption)}
                themePageUse
                setSelectedValue={setFilterOption}
                searchValue={""}
                options={filterOptions.map((opt) => {
                  if (opt.text === "My Themes") {
                    return {
                      ...opt,
                      text: `My ${
                        selectedCouncil?.settings?.themes_display_name ||
                        "Themes"
                      }`,
                    };
                  }
                  return opt;
                })}
                // searchFn={handleSearch}
              />
            </>
          ) : null}

          {CheckThemeRole(
            session?.council_role,
            handleAddThemeModal,
            `New ${
              selectedCouncil?.settings?.themes_display_name_singular || "Theme"
            }`,
            `You do not have permission to add a ${
              selectedCouncil?.settings?.themes_display_name_singular || "Theme"
            }. Please contact an ${
              selectedCouncil?.traction_tag === "firmenich"
                ? '<a target="_blank" style="color: blue; text-decoration: underline;" href="mailto:paul.elowe@firmenich.com">Admin</a>'
                : "Admin"
            }  to create a new ${
              selectedCouncil?.settings?.themes_display_name_singular || "Theme"
            }`,
            selectedCouncil?.name
          )}
        </div>
      </div>
    </div>
  );
};
export default ThemesTopActions;

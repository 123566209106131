import styles from "../../../scss/theme-variables.scss";

export const CheckType = {
  BLUE: 0,
  GREEN: 1,
  HALF_BLUE: 2,
  DISABLED: 3,
};

export const CheckColor = {
  0: styles.colorLightBlue7,
  1: styles.colorGreen5,
  2: styles.colorLightBlue7,
  3: styles.colorCoolGrey5,
};

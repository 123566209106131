import React from "react";
import { format, parseISO } from "date-fns";

import styles from "./rfiFormListItem.module.scss";

const RFIFormListItem = ({ item, activeForm, handleNavigation }) => (
  <div
    className={`${styles.listItem} ${activeForm ? "font-weight-bold" : ""}`}
    onClick={() => handleNavigation(item.id)}
  >
    {activeForm && <span className={styles.arrow}>{">"}</span>}
    {item.created_at
      ? format(parseISO(item.created_at), "LL/d/yyyy, hh:mm a")
      : "no name"}
  </div>
);

export default React.memo(RFIFormListItem);

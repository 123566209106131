import React from "react";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { companyPutActivity } from "modules/companies/company/company.action";
import styles from "./accessToggle.module.scss";

const AccessToggle = ({ item, orgType }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleAccessChange = () => {
    const propName = `${
      item.company?.company_id ? "company_id" : "gsk_patient_organization_id"
    }`;
    dispatch(
      companyPutActivity({
        data: {
          [propName]:
            item.company?.company_id
            || item.gsk_patient_organization?.gsk_patient_organization_id,
          visibility:
            item.visibility === "for_all" ? "self_private" : "for_all",
        },
        company_custom_activity_id: item.custom_activity_id,
        enqueueSnackbar,
        orgType,
      }),
    );
  };

  return (
    <div className={classNames("radio", styles.radioBtnWrp)}>
      <label className={styles.switch}>
        <input
          type="checkbox"
          defaultChecked={item?.visibility === "for_all"}
          onChange={handleAccessChange}
        />
        <span className={classNames(styles.slider, styles.round)} />
      </label>
      <span
        className={`${styles.status} ${
          item?.visibility === "for_all" ? styles.public : styles.private
        }`}
      >
        {item?.visibility === "for_all" ? "Public" : "Private"}
      </span>
      <Tooltip
        title="Toggle right to make this note Public, visible to your team."
        placement="bottom"
      >
        <span className={styles.introQuestionMark}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="6" cy="6" r="5.5" stroke="#9AA5B1" />
            <path
              d="M6.149 3c-.972 0-1.602.383-2.096 1.067a.275.275 0 0 0 .063.385l.525.383a.3.3 0 0 0 .405-.048c.305-.368.531-.58 1.008-.58.374 0 .838.232.838.582 0 .264-.227.4-.597.6-.431.232-1.002.522-1.002 1.246v.115c0 .155.13.281.292.281h.883a.287.287 0 0 0 .292-.281v-.068c0-.502 1.526-.523 1.526-1.882 0-1.024-1.104-1.8-2.137-1.8zm-.122 4.376c-.466 0-.844.365-.844.812 0 .448.378.812.844.812.465 0 .843-.364.843-.812 0-.447-.378-.812-.843-.812z"
              fill="#9AA5B1"
            />
          </svg>
        </span>
      </Tooltip>
    </div>
  );
};
export default React.memo(AccessToggle);

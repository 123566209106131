export const formInitialValues = {
  title: "Traction Report",
  hideTitle: "",
  selectAll: "",
  summary: "",
  companies: [],
  customCompaniesSections: [],
  customProjectsSections: [],
  projects: [],
  ideas: [],
};

export const customStyles = {
  title: {
    fontSize: '24px',
    color: '#323f4b',
    fontWeight: 'bold',
  },
  modalDescription: {
    fontSize: '14px',
    color: '#323f4b',
  },
  customLabel: {
    color: '#323f4b',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  customOptionalLabel: {
    color: '#323f4b',
    fontSize: '14px',
    marginLeft: '10px',
  },
  selectedBlock: {
    border: '1px solid #2d83ae',
    color: '#2d83ae',
    blockTitle: {
      color: '#2d83ae',
      fontSize: '14px',
      letterSpacing: '1px',
    },
    blockLabel: {
      fontSize: '12px',
      color: '#2d83ae',
    }
  },
  selectableBlock: {
    borderRadius: '4px',
    border: '1px solid #cbd2d9',
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '155px',
    width: '155px',
    cursor: 'pointer',
    marginRight: '30px',
    marginBottom: '7px',
    blockTitle: {
      color: '#616e7c',
      fontSize: '14px',
      letterSpacing: '1px',
    },
    blockLabel: {
      fontSize: '12px',
      color: '#616e7c',
    }
  },
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { format, parseISO } from "date-fns";

import {
  getRFIDetails,
  getRFIDetailsSuccess,
  resetActiveForm,
} from "../../store/rfiActions";
import RFIDetailsForm from "./components/rfiDetailsForm";
import LoadingComponent from "../../../../primitives/loading/loading.component";
import styles from "./rfiDetails.module.scss";
import RFIFormListItem from "./components/rfiFormListItem";
import { BtnType, Button } from "../../../../primitives";

const RFIDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const requestId = new URLSearchParams(search).get("id");
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    claimProfileReducer: {
      rfiReducer: {
        details: { data, loading },
        forms: { formsData, loadingForms },
      },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (data) {
      setIsDisabled(data?.state !== "submitted");
    }
  }, [data]);

  useEffect(() => {
    if (requestId) {
      dispatch(
        getRFIDetails({
          requestId,
        }),
      );

      return;
    }

    if (formsData?.length > 0) {
      dispatch(
        getRFIDetails({
          requestId: formsData[0].id,
        }),
      );
    } else {
      dispatch(getRFIDetailsSuccess(null));
    }
  }, [formsData, requestId]);

  const handleNavigation = (id) => {
    dispatch(resetActiveForm());
    dispatch(
      getRFIDetails({
        requestId: id,
      }),
    );
    history.push(`/info-requests/details?id=${id}`);
  };

  return (
    <section className={styles.rfiDetails}>
      {loadingForms ? (
        <LoadingComponent customText="Getting requests..." />
      ) : formsData?.length > 0 ? (
        <div className={styles.listItems}>
          <div className="d-flex justify-content-center font-weight-bold mb-3">
            RFIs
          </div>
          {formsData.map((f, idx) => (
            <RFIFormListItem
              key={f.id}
              item={f}
              activeForm={requestId ? f.id === requestId : idx === 0}
              handleNavigation={handleNavigation}
            />
          ))}
        </div>
      ) : (
        <div>No requests yet</div>
      )}
      {loading ? (
        <LoadingComponent customText="Preparing request" />
      ) : data ? (
        <div className="d-flex flex-grow-1 flex-column">
          {data?.state !== "submitted" && (
            <div className="text-center mb-5 font-weight-bold">
              SUBMITTED ON{" "}
              {format(parseISO(data.updated_at), "LL/d/yyyy, kk:mm a")}
            </div>
          )}
          {/* { */}
          {/*  data && ( */}
          {/*    <div className="d-flex justify-content-center"> */}
          {/*      <RFIStepperProgress status={data.state} /> */}
          {/*    </div> */}
          {/*  ) */}
          {/* } */}
          <div className="d-flex justify-content-between align-items-center">
            <h3>
              {
                selectedCouncil?.name === "Cushman & Wakefield"
                  ? "Global Innovation Hub: Vendor Knowledgebase Fields"
                  : `Questions from ${selectedCouncil?.name}`
              }
            </h3>
            {data?.state !== "submitted" && (
              <Button
                onClick={() => setIsDisabled(!isDisabled)}
                btn={BtnType.FRAME_LESS}
                className="ml-3"
              >
                {isDisabled ? "Edit" : "Cancel"}
              </Button>
            )}
          </div>
          {
            selectedCouncil?.name === "Cushman & Wakefield"
              ? (
                <div className="mb-4">
                  <p>
                    The vendor knowledgebase enables C&W to raise awareness of technology vendors across multiple C&W
                    business units. We’ve created this functionality to enable you to manage your company messaging and
                    information as appropriate, whilst keeping our records up to date. The fields below will have
                    dropdowns, checklists, free text, and/or places to upload documents. Each field can provide detail
                    regarding what information is desired. Please note that in each field where there are three dots,
                    there are additional ways to upload videos or documents. You may wish to embed videos using an embed
                    code, this is supported by choosing “Edit” under the three dots.
                  </p>
                  <p>
                    We appreciate your taking the time to complete these fields and encourage you to visit this site to
                    provide regular updates. You are able to submit the RFI and then come back to it at any point in the
                    future to make changes by selecting edit in the top right of the RFI.
                  </p>
                  <p>
                    Disclaimer: Anything uploaded to this site should be considered public information. Having access
                    to
                    this knowledgebase and uploading information is not considered to be doing business with Cushman
                    and
                    Wakefield or our clients. This also does not permit vendor’s use of C&W name or logo in any
                    presentation materials, websites, documents, etc. Participating with this knowledgebase is purely
                    a
                    way to enable employees of C&W to have access to more information about your company, this does
                    not
                    guarantee business or make any commitments.
                  </p>
                </div>
              )
              : data?.information?.length ? (
                <div className="mb-4">{data?.information}</div>
              ) : null
          }
          <span>Please fill out each field</span>
          <hr />
          <RFIDetailsForm
            data={data}
            selectedCouncil={selectedCouncil}
            companyId={session?.invited_company?.id}
            isDisabled={isDisabled}
          />
        </div>
      ) : null}
    </section>
  );
};

export default React.memo(RFIDetails);

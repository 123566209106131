import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  apiStatus,
  httpPost,
  httpGet,
  httpDelete,
  httpPatch,
  errorHandler,
} from "../../../common/httpCall";

import {
  RATINGS_TEMPLATE_POST_ATTEMPT,
  RATINGS_TEMPLATE_GET_ATTEMPT,
  RATINGS_TEMPLATE_DELETE_ATTEMPT,
  RATINGS_TEMPLATE_PATCH_ATTEMPT,
  RATINGS_TEMPLAE_CATEGORIES_GET_ATTEMPT,
  RATINGS_TEMPLAE_CATEGORIES_POST_ATTEMPT,
  ratingsTemplateGetSuccess,
  ratingsTemplateGetAttempt,
  ratingsTemplateCategoriesGetSuccess,
  ratingsTemplateCategoriesGetAttempt,
  RATINGS_TEMPLAE_CATEGORIES_PATCH_ATTEMPT,
  RATINGS_TEMPLAE_CATEGORIES_DELETE_ATTEMPT,
  RATINGS_TEMPLAE_SINGLE_GET_ATTEMPT,
  ratingsTemplateSingleGetSuccess,
  catchErrorSuccess,
  RATINGS_TEMPLATE_REPLACE_PATCH_ATTEMPT,
  ratingsTemplatePatchAttempt,
} from "./adminRating.action";

const epicPostAdminRatingAttempt = (action$) => action$.pipe(
  ofType(RATINGS_TEMPLATE_POST_ATTEMPT),
  switchMap(({ payload: { data, selectedCouncil, enqueueSnackbar } }) => httpPost({
    call: "rating_templates",
    data: {
      rating_template: {
        council_id: selectedCouncil.id,
        name: data.name,
        description: data.description,
        template_type: data.template_type,
        max_scale: data.max_scale,
        topic_ids: data.topic_ids,
        company_topics_related: data.company_topics_related,
      },
    },
  }).pipe(
    map((result) => {
      try {
        if (
          result.status === apiStatus.CREATED
              || result.status === apiStatus.SUCCESS
        ) {
          enqueueSnackbar("Successfully added rating template", {
            variant: "success",
          });
          return ratingsTemplateGetAttempt({ enqueueSnackbar });
        }
      } catch (error) {
        console.log("-----????  catch ?????-----", error);
      }
    }),
    catchError((error) => errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)),
  )),
);

const epicGetRatingTemplates = (action$) => action$.pipe(
  ofType(RATINGS_TEMPLATE_GET_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar } }) => httpGet({
    call: "rating_templates",
  }).pipe(
    map((result) => ratingsTemplateGetSuccess(result.response)),
    catchError((error) => errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)),
  )),
);

const epicDeleteRatingTemplate = (action$) => action$.pipe(
  ofType(RATINGS_TEMPLATE_DELETE_ATTEMPT),
  switchMap((payload) => httpDelete({
    call: `rating_templates/${payload.payload.id}`,
  }).pipe(
    map((result) => {
      if (result.status === apiStatus.SUCCESS) {
        payload.payload.enqueueSnackbar("Successfully deleted template.", {
          variant: "success",
        });
        return ratingsTemplateGetAttempt({
          enqueueSnackbar: payload.payload.enqueueSnackbar,
        });
      }
    }),
    catchError((error) => errorHandler(
      error,
      payload.payload.enqueueSnackbar,
      [],
      catchErrorSuccess,
    )),
  )),
);

export const epicRatingTemplateEdit = (action$) => action$.pipe(
  ofType(RATINGS_TEMPLATE_PATCH_ATTEMPT),
  switchMap(({ payload }) => httpPatch({
    call: `rating_templates/${payload.templateId}`,
    data: {
      rating_template: {
        council_id: payload.councilId,
        name: payload.templateName,
        description: payload.templateDescription,
        default_template: payload.defaultTemplate,
        max_scale: payload.max_scale,
        template_type: payload.template_type,
        topic_ids: payload.topic_ids,
        company_topics_related: payload.company_topics_related,
      },
    },
  }).pipe(
    map((result) => {
      if (result.status === apiStatus.SUCCESS) {
        payload.enqueueSnackbar("Successfully edited template.", {
          variant: "success",
        });

        return ratingsTemplateGetAttempt({
          enqueueSnackbar: payload.enqueueSnackbar,
        });
      }
    }),
    catchError((error) => errorHandler(error, payload.enqueueSnackbar, [], catchErrorSuccess)),
  )),
);

export const epicRatingTemplateReplaceDefault = (action$) => action$.pipe(
  ofType(RATINGS_TEMPLATE_REPLACE_PATCH_ATTEMPT),
  switchMap(({ payload }) => httpPatch({
    call: `rating_templates/${payload.defaultTemplateId}`,
    data: {
      rating_template: {
        council_id: payload.councilId,
        default_template: false,
      },
    },
  }).pipe(
    map((result) => {
      if (result.status === apiStatus.SUCCESS) {
        // payload.enqueueSnackbar('Successfully edited template.', {
        //   variant: 'success',
        // });

        return ratingsTemplatePatchAttempt(payload.replacedTemplateData);
      }
    }),
    catchError((error) => errorHandler(error, payload.enqueueSnackbar, [], catchErrorSuccess)),
  )),
);

const epicGetRatingsTemplateSingle = (action$) => action$.pipe(
  ofType(RATINGS_TEMPLAE_SINGLE_GET_ATTEMPT),
  switchMap(({ payload: { historyParams, enqueueSnackbar } }) => httpGet({
    call: `rating_templates/${historyParams.id}`,
  }).pipe(
    map((result) => {
      if (result.status === apiStatus.SUCCESS) {
        return ratingsTemplateSingleGetSuccess(result.response);
      }
    }),
    catchError((error) => errorHandler(error, enqueueSnackbar)),
  )),
);

const expicGetRatingsTemplateCategories = (action$) => action$.pipe(
  ofType(RATINGS_TEMPLAE_CATEGORIES_GET_ATTEMPT),
  switchMap((payload) => httpGet({
    call: `rating_templates/${payload.id}/categories`,
  }).pipe(
    map((result) => {
      if (result.status === apiStatus.SUCCESS) {
        return ratingsTemplateCategoriesGetSuccess(result.response);
      }
    }),
    catchError((error) => errorHandler(error, payload.enqueueSnackbar)),
  )),
);

const epicPostAdminRatingCategories = (action$) => action$.pipe(
  ofType(RATINGS_TEMPLAE_CATEGORIES_POST_ATTEMPT),
  switchMap(({ payload }) => httpPost({
    call: `rating_templates/${payload.templateId}/categories`,
    data: {
      rating_category: {
        rating_template_id: payload.templateId,
        name: payload.name,
        description: payload.description,
        weight: payload.weight,
        topic_ids: payload.topic_ids,
        parent_id: payload.parent_id,
      },
    },
  }).pipe(
    map((result) => {
      try {
        if (
          result.status === apiStatus.CREATED
              || result.status === apiStatus.SUCCESS
        ) {
          payload.enqueueSnackbar("Successfully added rating category", {
            variant: "success",
          });
          return ratingsTemplateCategoriesGetAttempt({
            id: payload.templateId,
            enqueueSnackbar: payload.enqueueSnackbar,
          });
        }
      } catch (error) {
        console.log("-----????  catch ?????-----", error);
      }
    }),
    catchError((error) => errorHandler(error, payload.enqueueSnackbar)),
  )),
);

export const epicPatchAdminRatingCategories = (action$) => action$.pipe(
  ofType(RATINGS_TEMPLAE_CATEGORIES_PATCH_ATTEMPT),
  switchMap(({ payload }) => httpPatch({
    call: `rating_templates/${payload.templateId}/categories/${payload.categoryId}`,
    data: {
      rating_category: {
        council_id: payload.councilId,
        rating_template_id: payload.templateId,
        name: payload.name,
        description: payload.description,
        weight: payload.weight,
        topic_ids: payload.topic_ids,
        parent_id: payload.parent_id,
      },
    },
  }).pipe(
    map((result) => {
      if (result.status === apiStatus.SUCCESS) {
        payload.enqueueSnackbar("Successfully edited category.", {
          variant: "success",
        });

        return ratingsTemplateCategoriesGetAttempt({
          id: payload.templateId,
          enqueueSnackbar: payload.enqueueSnackbar,
        });
      }
    }),
    catchError((error) => errorHandler(error, payload.enqueueSnackbar)),
  )),
);

const epicDeleteRatingTemplateCategory = (action$) => action$.pipe(
  ofType(RATINGS_TEMPLAE_CATEGORIES_DELETE_ATTEMPT),
  switchMap(({ payload }) => httpDelete({
    call: `rating_templates/${payload.templateId}/categories/${payload.categoryId}`,
  }).pipe(
    map((result) => {
      if (result.status === apiStatus.SUCCESS) {
        payload.enqueueSnackbar("Successfully deleted category.", {
          variant: "success",
        });

        return ratingsTemplateCategoriesGetAttempt({
          id: payload.templateId,
          enqueueSnackbar: payload.enqueueSnackbar,
        });
      }
    }),
    catchError((error) => errorHandler(error, payload.enqueueSnackbar)),
  )),
);

const newRatingTemplateEpic = combineEpics(
  epicPostAdminRatingAttempt,
  epicGetRatingTemplates,
  epicDeleteRatingTemplate,
  epicRatingTemplateEdit,
  expicGetRatingsTemplateCategories,
  epicPostAdminRatingCategories,
  epicPatchAdminRatingCategories,
  epicDeleteRatingTemplateCategory,
  epicGetRatingsTemplateSingle,
  epicRatingTemplateReplaceDefault,
);

export default newRatingTemplateEpic;

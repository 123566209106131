import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { isCreator } from "common/checkers/isCreator";
import {
  MoreOptions,
  Loading,
  Label,
  UploadImgComponent,
} from "modules/primitives";
import styles from "./styles.module.scss";
import { ACTIVITY_OPTIONS } from "../../../constants";
import { Icon, smSize } from "../../../icon";

const ImageComponent = ({
  item,
  handleEditClick,
  blockFunctionality,
  handleDeleteClick,
  handleSubmitDetail,
}) => {
  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { customFieldLoadingId, selectedCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: {
        selectedPatientOrganization,
        customFieldLoadingIdOrg,
      },
    },
  } = useSelector((state) => state);
  const [loadingId, setLoadingId] = useState("");
  const [fieldId, setFieldId] = useState("");
  const [isImgValid, setIsImgValid] = useState(true);
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);
  const [showField, setShowField] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (item) {
      if (item.field_value) {
        setImageUrl(item.field_value);
      }
      if (item.field_value === null) {
        setImageUrl("");
      }

      if (isCreator(item?.creator?.user_id)) {
        setHasAccessToEdit(true);
      }
    }
  }, [item]);

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }

    if (value === 1) {
      handleDeleteClick(item);
    }
  };

  useEffect(() => {
    if (customFieldLoadingId || customFieldLoadingIdOrg) {
      if (selectedPatientOrganization.id) {
        setLoadingId(customFieldLoadingIdOrg);
      } else {
        setLoadingId(customFieldLoadingId);
      }
    }
  }, [customFieldLoadingId, customFieldLoadingIdOrg]);

  useEffect(() => {
    if (selectedPatientOrganization.id) {
      setFieldId(item.patient_organization_custom_field_id);
    } else {
      setFieldId(item.company_custom_field);
    }
  }, [item]);

  useEffect(() => {
    if (item.field_value) {
      setIsImgValid(true);
    }
  }, [item]);

  const handleSaveClick = (image) => {
    if (!handleSubmitDetail) {
      return;
    }

    if (!image) {
      enqueueSnackbar("Please provide upload a image", {
        variant: "error",
      });
      return;
    }

    const payload = {
      ...item,
      field_value: image,
    };

    handleSubmitDetail(payload, item);

    setShowField(false);
  };

  const onImgUpdate = (url) => {
    setImageUrl(url);
    handleSaveClick(url);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{item?.field_name || "Image"}</h5>
          {item?.field_placeholder?.length ? (
            <span className="text-muted small">{item.field_placeholder}</span>
          ) : null}
        </div>
        <div className={styles.rightWrp}>
          <div className={styles.colMore}>
            {!blockFunctionality && hasAccessToEdit ? (
              <MoreOptions
                options={ACTIVITY_OPTIONS}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            ) : null}
          </div>
        </div>
      </div>
      {item.from_claimed_profile && (
        <span className="d-block small font-weight-bold mb-3">
          Company Response
        </span>
      )}
      {fieldId === loadingId ? (
        <Loading />
      ) : (
        <div className={styles.photoContainer}>
          <div className="d-flex align-items-center justify-content-center">
            {imageUrl && isImgValid ? (
              <div className="d-flex flex-row align-items-start justify-content-center">
                <img
                  className={styles.imgContainer}
                  key={imageUrl}
                  src={imageUrl}
                  onError={() => setIsImgValid(false)}
                  alt=""
                />
                {showField ? (
                  <div className="d-flex flex-column justify-content-center cursor-pointer ml-3">
                    <Label>Upload New Image</Label>
                    <UploadImgComponent
                      label=""
                      name="link"
                      uploadDirName={`srm/${selectedCouncil.id}/companies/${selectedCompany?.id}/custom_fields/images/key`}
                      onChange={onImgUpdate}
                      imgSize={styles.imgSize}
                      align="center"
                    />
                  </div>
                ) : (
                  <div
                    className="cursor-pointer ml-2"
                    onClick={() => setShowField(true)}
                  >
                    <Icon {...smSize} icon="icn-edit" />
                  </div>
                )}
              </div>
            ) : (
              <div className="d-flex flex-column">
                <Label>Upload Image</Label>
                <UploadImgComponent
                  label=""
                  name="link"
                  uploadDirName={`srm/${selectedCouncil.id}/companies/${selectedCompany?.id}/custom_fields/images/key`}
                  onChange={onImgUpdate}
                  imgSize={styles.imgSize}
                  align="center"
                />
              </div>
            )}
          </div>
        </div>
      )}

      {item && item.creator ? (
        <div className={{ display: "flex" }}>
          <div className={styles.infoWrp}>
            <div className={styles.dataRow}>
              <div className={styles.label}>
                {item.from_submission
                  ? "Submitted by"
                  : item.updated_at !== item.created_at
                  ? "Updated by"
                  : "Created by"}
              </div>
              <div className={styles.value}>
                {item.from_submission ? (
                  <span>{item?.creator?.full_name || "-"}</span>
                ) : (
                  <Link to={`/people/internal/profile/${item.user_id}`}>
                    {item && item.creator && item.creator.full_name}
                    {item.from_claimed_profile && (
                      <span>, {selectedCompany?.name}</span>
                    )}
                  </Link>
                )}
              </div>
            </div>
            {item.from_claimed_profile && (
              <div className="d-flex align-items-center mr-5">
                <div className={styles.companyAccess}>
                  <span>&#x2713; Access</span>
                </div>
              </div>
            )}
            <div className={styles.date}>
              {item.updated_at !== item.created_at
                ? format(
                    new Date(item.updated_at),
                    "eeee, MMM do, yyyy hh:mm a"
                  )
                : format(
                    new Date(item.created_at),
                    "eeee, MMM do, yyyy hh:mm a"
                  )}
            </div>
            {item.from_submission ? (
              <div
                className={`d-flex flex-grow-1 align-items-center justify-content-center ${styles.companyResponse}`}
              >
                Company Response
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default React.memo(ImageComponent);

import React, { useEffect, useState } from "react";
import styles from './styles.module.scss';

const AnimatedSection = ({ children, onAnimationComplete, isVisible, doNotAnimate }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isVisible && !doNotAnimate) {
      setIsAnimating(true);
    }
  }, [isVisible, doNotAnimate]);

  const handleAnimationEnd = () => {
    if (onAnimationComplete) {
      onAnimationComplete();
    }
  };

  return (
    <div
      className={`${styles.section} ${isAnimating || doNotAnimate ? styles.animatedSection : ''}`}
      onTransitionEnd={handleAnimationEnd}
    >
      {children}
    </div>
  );
};

export default React.memo(AnimatedSection);

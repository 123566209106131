export const THEMES_HOMEPAGE_GET_ATTEMPT = "THEMES_HOMEPAGE_GET_ATTEMPT";
export const themesHomepageGetAttempt = (payload) => ({
  type: THEMES_HOMEPAGE_GET_ATTEMPT,
  payload,
});
export const THEMES_HOMEPAGE_GET_SUCCESS = "THEMES_HOMEPAGE_GET_SUCCESS";
export const themesHomepageGetSuccess = (payload) => ({
  type: THEMES_HOMEPAGE_GET_SUCCESS,
  payload,
});

export const THEMES_HOMEPAGE_POST_ATTEMPT = "THEMES_HOMEPAGE_POST_ATTEMPT";
export const themesHomepagePostAttempt = (payload) => ({
  type: THEMES_HOMEPAGE_POST_ATTEMPT,
  payload,
});
export const THEMES_HOMEPAGE_POST_SUCCESS = "THEMES_HOMEPAGE_POST_SUCCESS";
export const themesHomepagePostSuccess = (payload) => ({
  type: THEMES_HOMEPAGE_POST_SUCCESS,
  payload,
});

export const THEMES_HOMEPAGE_REMOVE_ATTEMPT = "THEMES_HOMEPAGE_REMOVE_ATTEMPT";
export const themesHomepageRemoveAttempt = (payload) => ({
  type: THEMES_HOMEPAGE_REMOVE_ATTEMPT,
  payload,
});
export const THEMES_HOMEPAGE_REMOVE_SUCCESS = "THEMES_HOMEPAGE_REMOVE_SUCCESS";
export const themesHomepageRemoveSuccess = (payload) => ({
  type: THEMES_HOMEPAGE_REMOVE_SUCCESS,
  payload,
});

import React from "react";
import IdeaListMainContent from "./ideaListMainContent";
import styles from "./ideasListWrapper.module.scss";

const IdeasListWrapper = () => (
  <div className={styles.ideaWrapContainer}>
    <IdeaListMainContent />
  </div>
);

export default React.memo(IdeasListWrapper);

import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BtnType, Button, Label } from "modules/primitives";
import { httpPost } from "../../../../../common/httpCall";
import styles from '../../../tractionAI.module.scss';
import LoadingComponent from "modules/primitives/loading/loading.component";
import { AISetRecommendData } from "modules/tractionAI/store/tractionAI.actions";
import LoaderWithDots from "../../../../../common/components/loaderWithDots";
import IdeasMultiSelectSearch from "modules/tractionAI/screens/recommend/ideas/ideasMultiSelectSearch";
import RandomAILoader from "modules/tractionAI/components/randomAILoader";

const TractionAIRecommendIdeasFooter = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();

  const {
    tractionAiReducer: { recommend: { ideas } }
  } = useSelector((state) => state);

  const handleSubmit = () => {
    dispatch(AISetRecommendData({
      fetching: true,
      searchByQuery: searchQuery.length ? searchQuery : selected[0]?.name,
      ideaId: searchQuery.length ? null : selected[0]?.id,
      isIdeasSearch: searchQuery.length === 0,
      createNewSearchItem: true,
      fetched: false,
      activeSubScreen: 'ideas'
    }));

    httpPost({
      call: "ai/proxy/streaming",
      data: { prompt: searchQuery, endpoint: "recommend_ideas", idea_id: selected[0]?.id },
    })
      .subscribe(
        () => {
          setSearchQuery('');
        },
        (error) => {
          console.error('Error occurred in recommend footer:', error);
          dispatch(AISetRecommendData({
            hasError: true,
            errorMsg: error.response.error,
            errorCode: error.response.status,
            errorType: 'serverError',
            fetched: true,
            fetching: false,
            activeSubScreen: 'ideas'
          }));
        }
      )
  };

  const onEnterPress = (evt) => {
    if(evt.keyCode === 13 && !evt.shiftKey) {
      evt.preventDefault();
      handleSubmit()
    }
  };

  const handleProjectsSelect = (projects) => {
    setSearchQuery('');
    setSelected(projects);
  }

  const getLoadingTemplate = useCallback((data) => {
    if (!data || data?.length === 0) {
      return <LoaderWithDots text="Thinking" />
    }

    const lastItem = data[data.length - 1];
    const hasData = lastItem.data.length > 0;

    if (lastItem.isLoadingMore) {
      return <RandomAILoader size="big" />
    }

    if (hasData) {
      return <LoadingComponent customText="Fetching idea recommendations..." />
    }

    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <RandomAILoader size="big" />
          <h5>Please wait. Results can take up to 30 seconds.</h5>
        </div>
      </div>
    )
  }, [ideas.data]);

  return (
    <div className="d-flex flex-row w-100 p-2">
      {
        ideas.fetching ? (
          <div className="d-flex align-items-center w-100 justify-content-center">
            {getLoadingTemplate(ideas.data)}
          </div>
        ) : (
          <>
            <div className="d-flex flex-column w-100">
              <Label>Recommend Ideas That Solve a Problem</Label>
              <textarea
                className={styles.AITextArea}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setSelected([]);
                }}
                name="prompt"
                id="prompt"
                rows="3"
                placeholder="What is the problem you are trying to solve?"
                disabled={ideas.fetching}
                onKeyDown={onEnterPress}
              />
              <div className="d-flex justify-content-center text-muted mt-3">OR</div>
              <>
                <Label>Recommend Ideas Similar To</Label>
                <IdeasMultiSelectSearch
                  onChange={handleProjectsSelect}
                  shouldReset={searchQuery?.length > 0}
                  placeholder="Select Ideas"
                  labelField="name"
                  valueField="id"
                  dropdownPosition="top"
                  multi={false}
                />
              </>
            </div>
            <div className="d-flex align-items-end ml-5 pb-3">
              <Button
                btn={BtnType.REGULAR}
                onClick={handleSubmit}
                disabled={selected.length === 0 && searchQuery?.length === 0}
              >SUBMIT</Button>
            </div>
          </>
        )
      }
    </div>
  );
};

export default React.memo(TractionAIRecommendIdeasFooter);

import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import classNames from "classnames";
import { withSnackbar } from "notistack";
import {
  MultiSelect,
  TextArea,
  CheckBox,
  CheckType,
  BtnType,
  Button,
  Label,
  TextBox,
} from "modules/primitives/index";
import Radio from "@mui/material/Radio";

import { useModelPopup } from "../../../../common/hooks/index";
import styles from "../adminRating.module.scss";
import { ModalBody, ModalFooter } from "../../../../application/modal";
import {
  ratingTemplateValidation,
  initTemplateModel,
} from "./adminRatingNewTemplate.constants";
import {
  ratingsTemplatePostAttempt,
  ratingsTemplatePatchAttempt,
  ratingsTemplateReplacePatchAttempt,
} from "../adminRating.action";

const AdminRatingModal = ({
  enqueueSnackbar,
  mode,
  councilId,
  templateName,
  templateId,
  description,
  defaultTemplate,
  template_type,
  max_scale,
  topicIds,
  companyTopicsRelated,
  topics,
  companyTemplates,
  selectedVariant,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const popup = useModelPopup();
  const [model, setModel] = useState(initTemplateModel);
  const [editModel, setEditModel] = useState({});
  const [default_template, setDefaultTemplate] = useState(defaultTemplate);
  const [selectedValue, setSelectedValue] = useState("");
  const [topic, setTopic] = useState([]);
  const [tagged, setTagged] = useState(false);
  const [usedTopics, setUsedTopics] = useState([]);
  const [companyDefaultTemplateId, setCompanyDefaultTemplateId] = useState("");
  const [projectDefaultTemplateId, setProjectDefaultTemplateId] = useState("");
  const [projectCompanyDefaultTemplateId, setProjectCompanyDefaultTemplateId] =
    useState("");
  const [ideaTemplateId, setIdeaTemplateId] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [isValidToReplaceDefault, setIsValidToReplaceDefault] = useState(false);
  const [errorSelectedTopic, setErrorSelectedTopic] = useState({
    error: false,
  });

  const handleMultiCheckClick = (value) => {
    if (
      value &&
      template_type === "project" &&
      projectDefaultTemplateId &&
      projectDefaultTemplateId !== templateId
    ) {
      setShowWarning(true);
      setIsValidToReplaceDefault(true);
    }
    if (
      value &&
      template_type === "company" &&
      companyDefaultTemplateId &&
      companyDefaultTemplateId !== templateId
    ) {
      setShowWarning(true);
      setIsValidToReplaceDefault(true);
    }
    if (
      value &&
      template_type === "project_company" &&
      projectCompanyDefaultTemplateId &&
      projectCompanyDefaultTemplateId !== templateId
    ) {
      setShowWarning(true);
      setIsValidToReplaceDefault(true);
    }
    if (
      value &&
      template_type === "idea" &&
      ideaTemplateId &&
      ideaTemplateId !== templateId
    ) {
      setShowWarning(true);
      setIsValidToReplaceDefault(true);
    }
    if (!value) {
      setShowWarning(false);
      setIsValidToReplaceDefault(false);
    }
    setDefaultTemplate(value);
  };

  const handleTemplateTypeClick = (val) => {
    // setSelectedValue((prevState) => !prevState);
    if (selectedValue) {
      setTagged(false);
    }
  };

  const handleTaggedTypeClick = () => {
    setTagged((prevState) => !prevState);
  };
  const {
    councilReducer: { selectedCouncil },
    adminRatingTemplatesReducer: { ratingTemplates },
  } = useSelector((state) => state);

  const handleCancelClick = () => {
    popup.hide();
  };

  useEffect(() => {
    if (mode === "edit" && companyTopicsRelated) {
      setTagged(true);
    }
  }, [companyTopicsRelated]);

  useEffect(() => {
    if (mode === "edit" && topicIds?.length) {
      const remadeTopics = topicIds.map((topicId) => {
        const foundTopic = topics.find((topic) => topic?.id === topicId);
        if (foundTopic) {
          return foundTopic;
        }
      });
      setUsedTopics(remadeTopics.filter((el) => el !== undefined));
    }
  }, [topicIds]);

  useEffect(() => {
    if (mode === "edit") {
      if (template_type === "project") {
        setSelectedValue("project");
      } else if (template_type === "company") {
        setSelectedValue("company");
      } else if (template_type === "project_company") {
        setSelectedValue("project_company");
      } else if (template_type === "idea") {
        setSelectedValue("idea");
      }
    }
    if (mode === "add") {
      if (selectedVariant === "project") {
        setSelectedValue("project");
      } else if (selectedVariant === "company") {
        setSelectedValue("company");
      } else if (selectedVariant === "project_company") {
        setSelectedValue("project_company");
      } else if (selectedVariant === "idea") {
        setSelectedValue("idea");
      }
    }
  }, [template_type, mode]);

  const handleOnSubmit = (values) => {
    if (errorSelectedTopic.error) {
      return;
    }
    const data = {
      ...values,
      topic_ids: topic,
      template_type: selectedValue,
      company_topics_related: tagged,
    };

    if (mode === "add") {
      dispatch(
        ratingsTemplatePostAttempt({ data, selectedCouncil, enqueueSnackbar })
      );
    }
    if (mode === "edit" && !isValidToReplaceDefault) {
      dispatch(
        ratingsTemplatePatchAttempt({
          councilId,
          templateId,
          templateName: values.name,
          template_type: data.template_type,
          templateDescription: values.description,
          enqueueSnackbar,
          defaultTemplate: default_template,
          max_scale: Number(values.max_scale),
          company_topics_related: tagged,
          topic_ids: usedTopics.map((el) => el?.id),
        })
      );
    }

    if (mode === "edit" && isValidToReplaceDefault) {
      let defaultTemplateId = "";
      if (template_type === "project") {
        defaultTemplateId = projectDefaultTemplateId;
      } else if (template_type === "company") {
        defaultTemplateId = companyDefaultTemplateId;
      } else if (template_type === "project_company") {
        defaultTemplateId = projectCompanyDefaultTemplateId;
      } else if (template_type === "idea") {
        defaultTemplateId = ideaTemplateId;
      }
      dispatch(
        ratingsTemplateReplacePatchAttempt({
          defaultTemplateId: defaultTemplateId,
          councilId,
          replacedTemplateData: {
            councilId,
            templateId,
            templateName: values.name,
            template_type: data.template_type,
            templateDescription: values.description,
            enqueueSnackbar,
            defaultTemplate: default_template,
            max_scale: Number(values.max_scale),
            company_topics_related: tagged,
            topic_ids: usedTopics.map((el) => el?.id),
          },
        })
      );
    }

    popup.hide();
  };

  useEffect(() => {
    if (mode === "edit") {
      setEditModel({
        name: templateName,
        description,
        max_scale,
      });
    }
  }, [mode]);

  useEffect(() => {
    if (ratingTemplates) {
      const sortTemplates = (a) => {
        if (a.default_template) {
          return -1;
        }
        if (!a.default_template) {
          return 1;
        }
        return 0;
      };
      const ratingsWithoutCompany = ratingTemplates
        .filter((template) => template.template_type === "project")
        .sort(sortTemplates);

      const ratingsWithProjectCompany = ratingTemplates
        .filter((template) => template.template_type === "project_company")
        .sort(sortTemplates);

      const ratingsWithCompany = ratingTemplates
        .filter((template) => template.template_type === "company")
        .sort(sortTemplates);

      const ratingsWithIdea = ratingTemplates
        .filter((template) => template.template_type === "idea")
        .sort(sortTemplates);

      if (
        ratingsWithCompany[0] ||
        ratingsWithCompany[0] ||
        ratingsWithProjectCompany[0] ||
        ratingsWithIdea[0]
      ) {
        setProjectDefaultTemplateId(
          ratingsWithoutCompany[0]?.default_template &&
            ratingsWithoutCompany[0]?.rating_template_id
        );
        setCompanyDefaultTemplateId(
          ratingsWithCompany[0]?.default_template &&
            ratingsWithCompany[0]?.rating_template_id
        );
        setProjectCompanyDefaultTemplateId(
          ratingsWithProjectCompany[0]?.default_template &&
            ratingsWithProjectCompany[0]?.rating_template_id
        );
        setIdeaTemplateId(
          ratingsWithIdea[0]?.default_template &&
            ratingsWithIdea[0]?.rating_template_id
        );
      }
    }
  }, [ratingTemplates]);

  const handleValidTopic = (selectedTopicIds) => {
    const alreadyUsedTopics = companyTemplates.reduce(
      (previousValue, template) => {
        if (mode === "edit" && template.rating_template_id === templateId) {
          return [...previousValue];
        }
        return previousValue.concat(template?.topic_ids);
      },
      []
    );
    selectedTopicIds.some((selectedTopicId) => {
      const usedTopic = alreadyUsedTopics.find(
        (t) => t === selectedTopicId?.id
      );
      if (usedTopic && usedTopic[0]) {
        setErrorSelectedTopic({
          ...selectedTopicId,
          error: true,
        });
        return {
          ...selectedTopicId,
          error: true,
        };
      }
      setErrorSelectedTopic({
        error: false,
      });
    });
  };

  const handleTopicChange = (val) => {
    handleValidTopic(val);
    const topicsIds = val.map((item) => item?.id);
    if (val.length === 0) {
      setErrorSelectedTopic({
        error: false,
      });
    }
    setTopic(topicsIds);
    setUsedTopics(val);
  };

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={mode === "edit" ? editModel : model}
        validationSchema={ratingTemplateValidation}
        onSubmit={(values, { setSubmitting }) => {
          if (!errorSelectedTopic.error) {
            setSubmitting(true);
            handleOnSubmit(values);
          }
        }}
      >
        {({ handleSubmit, handleReset, ...formikprops }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <ModalBody>
              <p className={styles.aditionalInfo}>
                {`This ratings template will be available ${
                  !selectedValue
                    ? "for all projects"
                    : selectedValue === "idea"
                    ? `on ${selectedValue} profiles`
                    : "on companies profiles"
                }.`}
              </p>
              <div className={styles.addRatingTempForm}>
                <div className={styles.row}>
                  <div className={classNames(styles.col12)}>
                    <Label>Template Name</Label>
                    <TextBox
                      type="text"
                      name="name"
                      className={styles.textBox}
                      formProps={formikprops}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={classNames(styles.col12)}>
                    <Label>Template Description (optional)</Label>
                    <TextArea
                      type="text"
                      name="description"
                      className={styles.textBox}
                      formProps={formikprops}
                    />
                  </div>
                </div>
                <div className={styles.col12}>
                  <Label>Max Value</Label>
                  <TextBox
                    type="text"
                    name="max_scale"
                    className={styles.textBox}
                    formProps={formikprops}
                  />
                </div>
                {mode === "edit" && (
                  <div className={styles.checkbox_container}>
                    <CheckBox
                      checkType={CheckType.BLUE}
                      isChecked={defaultTemplate}
                      onChange={(value) =>
                        handleMultiCheckClick(value, formikprops)
                      }
                    />
                    <Label>Set as default</Label>
                  </div>
                )}
                {showWarning && (
                  <div className={styles.weightErrMessage}>
                    <Label>
                      This will replace the existing default template
                    </Label>
                  </div>
                )}
                <div className={styles.radioWrp}>
                  <Label>Type</Label>
                  <div className={styles.radioContainer}>
                    <div className={styles.radioRow}>
                      <Radio
                        checked={selectedValue === "project_company"}
                        onChange={handleTemplateTypeClick}
                        name="radio-buttons"
                        className={
                          mode === "edit"
                            ? styles.MuiRadioDisabled
                            : styles.MuiRadio
                        }
                        disabled={mode === "edit"}
                      />
                      <span>Project(Company) Template</span>
                    </div>
                  </div>
                  <div className={styles.radioContainer}>
                    <div className={styles.radioRow}>
                      <Radio
                        checked={selectedValue === "project"}
                        onChange={handleTemplateTypeClick}
                        name="radio-buttons"
                        className={
                          mode === "edit"
                            ? styles.MuiRadioDisabled
                            : styles.MuiRadio
                        }
                        disabled={mode === "edit"}
                      />
                      <span>Project Template</span>
                    </div>
                  </div>
                  <div className={styles.radioContainer}>
                    <div className={styles.radioRow}>
                      <Radio
                        checked={selectedValue === "company"}
                        onChange={handleTemplateTypeClick}
                        name="radio-buttons"
                        className={
                          mode === "edit"
                            ? styles.MuiRadioDisabled
                            : styles.MuiRadio
                        }
                        disabled={mode === "edit"}
                      />
                      <span>Company Template</span>
                    </div>
                  </div>
                  <div className={styles.radioContainer}>
                    <div className={styles.radioRow}>
                      <Radio
                        checked={selectedValue === "idea"}
                        onChange={handleTemplateTypeClick}
                        name="radio-buttons"
                        className={
                          mode === "edit"
                            ? styles.MuiRadioDisabled
                            : styles.MuiRadio
                        }
                        disabled={mode === "edit"}
                      />
                      <span>Idea Template</span>
                    </div>
                  </div>
                  {selectedValue && selectedValue === true ? (
                    <div className={styles.checkbox_container}>
                      <CheckBox
                        isChecked={tagged}
                        onChange={handleTaggedTypeClick}
                        name="radio-buttons"
                        className={styles.MuiRadio}
                      />
                      <Label>Company specific technology</Label>
                    </div>
                  ) : (
                    ""
                  )}
                  {tagged && tagged === true ? (
                    <div className={styles.col12}>
                      <div className={styles.row}>
                        <div className={styles.col12}>
                          <Label>Technologies</Label>
                          <MultiSelect
                            className={styles.dropWrapper}
                            values={usedTopics}
                            options={topics}
                            labelField="name"
                            valueField="id"
                            searchBy="name"
                            searchable
                            onChange={handleTopicChange}
                            placeholder="Select one Technology"
                          />
                        </div>
                      </div>
                      <div>
                        {errorSelectedTopic.error && (
                          <span className={styles.errorMessage}>
                            {errorSelectedTopic.name} tag is already being used
                            by a different Company Ratings template. Please
                            select another one.
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button
                type="submit"
                btn={
                  !errorSelectedTopic.error ? BtnType.REGULAR : BtnType.DISABLED
                }
              >
                {mode === "edit" ? "Submit" : "Add"}
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(withSnackbar(AdminRatingModal));

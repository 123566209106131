import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { useSnackbar } from "notistack";
import { useMsal } from "@azure/msal-react";
import { getEmails, searchEmails } from "api/microsoft/graphApi/outlookOffice";
import { stateToHTML } from "draft-js-export-html";

import { ModalFooter, ModalBody } from "../../../../../application/modal";
import { appModalMakeRender, appModelSettingSet } from "application/app.action";
import { companyAddActivity, companyPutActivity } from "../../company.action";
import { councilUsersListGet } from "../../../../../common/actions/common.action";
import { Button, BtnType, Label, DropSelect } from "../../../../primitives";
import {
  initModel,
  optionActivities,
  modelTemplate,
  optionActivitiesGSK,
} from "./constant";
import { activityTypes, gskDiscussionSubTopics } from "../activity.constant";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../common/hooks";
import { Icon } from "../../../../../common/icon";
import { getUserTeams } from "modules/teams/teams.action";
import FieldSelector from "./fieldsSelector";

const projectTopics = [
  { name: "Defense of patients' rights" },
  { name: "Patient involvement in regulatory and HTA processes" },
  { name: "Disease awareness" },
  { name: "Improvement in standards of care for diseases/conditions" },
  { name: "Access to medicines/services" },
  { name: "Disease management" },
  { name: "GSK advice seeking activities" },
  { name: "Patient identification for GSK research activities" },
  { name: "Speaker engagement" },
  { name: "Development of educational materials" },
  { name: "Patient focused initiatives" },
];

const NewEventComponent = (props) => {
  const dispatch = useDispatch();
  const {
    companyId,
    company,
    data,
    handleDeleteClick,
    type,
    customTitle,
    orgType,
    shouldRedirectToActivity = false,
    notableId,
    notableType,
    notableEmail,
    activitiesTypes,
  } = props;

  const popup = useModelPopup();
  const history = useHistory();
  const formRef = useRef();

  const {
    councilReducer: { usersList, selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: { selectedPatientOrganization },
    },
    appReducer: { modalSetting },
    authReducer: { session },
    teamsReducer: { userTeams },
  } = useSelector((state) => state);
  const [selectedItems, setSelectedItem] = useState([]);
  const [selectedModel, setSelectedModel] = useState(
    data
      ? JSON.parse(localStorage.getItem("council"))?.name === "Merck"
        ? modelTemplate[activityTypes["merck_email"]]
        : modelTemplate[activityTypes[data.type.toLowerCase()]]
      : modelTemplate[4]
  );
  const [model, setmodel] = useState(initModel);
  const [activityType, setActivityType] = useState(type || "Note");
  const [visibility, setVisibility] = useState(
    data?.visibility ? data?.visibility : "for_all"
  );
  const [selectedProj, setSelectedProj] = useState(
    data?.projects ? data?.projects[0]?.id : null
  );

  const [selectedDate, setSelectedDate] = useState(data?.date || null);
  const [tempAttendees, setTempAttendees] = useState(
    data ? data.attendees : []
  );
  const [selectedDiscussionTopic, setSelectedDiscussionTopic] = useState("");
  const [patientOrgDiscussionSubTopics, setPatientOrgDiscussionSubTopics] =
    useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [addMoreFields, setAddMoreFields] = useState(false);
  const [otherAttendees, setOtherAttendees] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [msftEmails, setMsftEmails] = useState([]);
  const { instance, accounts } = useMsal();
  const microsoftLogin =
    JSON.parse(localStorage.getItem("microsoft_login")) || false;
  const [editorState, setEditorState] = useState({});
  const [filterContact, setFilterContact] = useState([]);
  const [selectedTeamOption, setSelectedTeamOption] = useState([]);

  const [optionVisibility, setOptionVisibility] = useState([
    {
      text: "Private (only visible to you)",
      value: "self_private",
    },
    {
      text: "All (visible to everyone)",
      value: "for_all",
    },
  ]);

  const [selectedProjectTopic, setSelectedProjectTopics] = useState([]);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState([]);

  const handleProjectTopicSelect = (vals, setFieldValue) => {
    setSelectedProjectTopics(vals);
    setFieldValue(
      "project_topics",
      vals.map((val) => val.name)
    );
  };

  const handleStatusSelect = (vals, setFieldValue) => {
    setSelectedProjectStatus(vals[0]);
    setFieldValue("status", vals[0].value.toLowerCase());
  };

  const getOtherIfItExists = (data) => {
    if (data && data.discussion_topics) {
      return data.discussion_topics.reduce((acc, elem) => {
        const topicCategory = gskDiscussionSubTopics.find((c) =>
          c.subTopics.some((subTopic) => subTopic.name === elem)
        );
        const categoryIsInAcc = acc.find((c) => c.name === topicCategory?.name);

        if (!topicCategory && !categoryIsInAcc) {
          return elem;
        }

        return acc;
      }, []);
    }

    return "";
  };

  const initialValues = data
    ? {
        activityType,
        name: data.name || data.contact_name || data.spoke_with,
        date: data.date,
        description: data.spoke_about || data.description || data.emailed_about,
        email: data.email,
        emailed_about: data.description || data.emailed_about,
        title: data.title,
        summary: data.summary,
        time: data.event_time,
        location: data.location,
        note: data.note,
        spoke_about: data.spoke_about,
        spoke_with: data.spoke_with,
        subject: data.subject,
        discussion_topic: data.discussion_topic,
        discussion_topic_other: getOtherIfItExists(data),
        key_findings: data.key_findings,
        roles_of_attendees: data.roles_of_attendees,
        initial_introductory_meeting: data.initial_introductory_meeting,
        visibility,
        team_ids: data?.team_ids,
        to_contact: data?.to_contact,
        from_contact: data?.from_contact,
        status: data?.status
          ? [{ name: data?.status, value: data?.status?.toLowerCase() }]
          : [],
        project_topics: data?.project_topics?.map((topic) => ({ name: topic })),
      }
    : null;

  const handleClear = () => {
    setSelectedProj("clear");
  };

  const handleAddMoreFieldsClick = () => setAddMoreFields(!addMoreFields);

  const checkItHasTopics = (data) => {
    if (data && data.discussion_topics) {
      return data.discussion_topics.reduce((acc, elem) => {
        const topicCategory = gskDiscussionSubTopics.find((c) =>
          c.subTopics.some((subTopic) => subTopic.name === elem)
        );
        const categoryIsInAcc = acc.find((c) => c.name === topicCategory?.name);

        if (!topicCategory && !categoryIsInAcc) {
          return [...acc, { name: "Other", subTopics: [] }];
        }

        if (topicCategory && !categoryIsInAcc) {
          return [...acc, { ...topicCategory }];
        }

        return acc;
      }, []);
    }

    return [];
  };

  const handleCancelClick = (e) => {
    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
  };

  const handleSaveClick = (e) => {
    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
  };

  const handleVisibilityChange = ({ val }) => {
    setVisibility(val);
  };

  const creteAttendees = () => {
    const attendees = selectedItems
      .map((user) => ({
        user_id: user.id,
        email: user.email,
        full_name: user.full_name,
        avatar: user.avatar,
        first_name: user.first_name,
        last_name: user.last_name,
      }))
      .concat(tempAttendees);

    return addMoreFields
      ? addOtherAttendeesToAttendeesList(attendees)
      : attendees;
  };

  const addOtherAttendeesToAttendeesList = (attendees) =>
    otherAttendees
      .map((user) => ({
        user_id: user.id,
        email: user.email,
        full_name: `${user.first_name} ${user.last_name}`,
        first_name: user.first_name,
        last_name: user.last_name,
        type: "other",
      }))
      .concat(attendees);

  const handleOnSubmit = (values) => {
    let dateTime;

    if (formRef?.current) {
      if (visibility === "for_teams" && values.team_ids.length === 0) {
        formRef.current.setFieldError(
          "team_ids",
          "At least one team should be selected!"
        );
        formRef.current.errors = {
          ...formRef?.current?.errors,
          team_ids: "Company already exists!",
        };
        formRef.current.setErrors({
          team_ids: "At least one team should be selected!",
        });
        return;
      }
    }

    if (selectedDate && typeof selectedDate === "object") {
      dateTime = new Date()
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-");
    }

    const projectTopicsToSave = values?.project_topics?.map(
      (topic) => topic?.name || topic
    );

    let status;
    if (Array.isArray(values?.status)) {
      status = values?.status?.length
        ? values?.status[0].value?.toLowerCase()
        : "open";
    } else {
      status = values?.status?.length ? values?.status?.toLowerCase() : "open";
    }
    const body = {
      activity_type:
        data && data.type
          ? data.type
          : activityType === "Other"
          ? "Note"
          : activityType,
      company_id: companyId,
      gsk_patient_organization_id: companyId,
      gsk: selectedCouncil.traction_tag === "gsk",
      patient_org: orgType === "patient",
      contact_name: values.name,
      creator_id: session.id,
      date: dateTime || selectedDate,
      description: values.description,
      email: values.email,
      emailed_about:
        values.description instanceof EditorState
          ? stateToHTML(values.description.getCurrentContent())
          : values?.description,
      title: values.title,
      summary: values.summary,
      status: status || "open",
      project_topics: projectTopicsToSave,
      event_time: values.time || data?.event_time || model?.event_time,
      location: values.location,
      note: values.note,
      related_project_ids: [selectedProj],
      spoke_about: values.spoke_about,
      spoke_with: values.name,
      attendees: creteAttendees(),
      subject: values.subject,
      team_ids: values.team_ids,
      visibility,
      discussion_topics:
        !selectedDiscussionTopic || !selectedDiscussionTopic.length
          ? []
          : values.discussion_topic_other &&
            values.discussion_topic_other.length
          ? [
              ...selectedSubCategories.map((v) => v.name),
              values.discussion_topic_other,
            ]
          : [...selectedSubCategories.map((v) => v.name)],
      key_findings: values.key_findings,
      roles_of_attendees: values.roles_of_attendees,
      initial_introductory_meeting: values.initial_introductory_meeting,
      shouldRedirectToActivity,
      history,
      to_contact: values?.to_contact,
      from_contact: values?.from_contact,
      from_email: values?.from_email,
      to_email: values?.to_email,
      slug: company?.slug || company?.id || notableId,
      notable_type: notableType,
      notable_id: notableId,
    };

    if (data) {
      dispatch(
        companyPutActivity({
          data: body,
          company_custom_activity_id: data.custom_activity_id,
          enqueueSnackbar,
          orgType,
        })
      );
    } else {
      dispatch(companyAddActivity({ data: body, enqueueSnackbar }));
    }
    handleSaveClick();
  };

  const deleteAttendee = (attendee) => {
    setTempAttendees((prev) =>
      prev.filter((el) => el.user_id !== attendee.user_id)
    );
  };

  const handleDateSelect = (value) => {
    // date without time zone
    const formattedDate = value.split(":")[0];
    setSelectedDate(formattedDate);

    if (!value?.length) {
      setmodel({
        ...model,
        event_time: null,
        date: value,
      });
    } else {
      setmodel({
        ...model,
        date: value,
      });
    }
  };

  const handleSubcategoryTopicSelect = (values) => {
    setSelectedSubCategories(values);
  };

  const handleEditorState = useCallback(
    (setFieldValue, description) => {
      let contentBlock = false;
      if (emailData[0]) {
        contentBlock = convertFromHTML(emailData[0].body?.content);
      }
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock);
        const editorState = EditorState.createWithContent(contentState);
        const oldState =
          description instanceof EditorState
            ? description?.getCurrentContent().getPlainText()
            : description;
        if (oldState !== contentState.getPlainText()) {
          setEditorState({ editorState });
          setFieldValue("description", editorState);
        }
        return { editorState };
      }
    },
    [emailData, setEditorState]
  );

  const handleActivitySelect = useCallback(
    ({ val, obj }) => {
      setAddMoreFields(false);
      setActivityType(obj[0]?.text);
      setSelectedModel(modelTemplate[val]);
      popup.setTitle({
        title: `Capture New ${
          obj[0].text === "Other" ? "Engagement" : obj[0].text
        }`,
      });
      dispatch(appModalMakeRender(true));
    },
    [modelTemplate]
  );

  const handlePatientOrgDiscussionSelect = useCallback(
    (values, setFieldValue) => {
      const topics = values.map((v) => v.name || v);
      const itHasOther = topics.filter((t) => t === "Other").length > 0;

      if (!itHasOther) {
        setFieldValue("discussion_topic_other", null);
      }

      if (!values.length) {
        setSelectedSubCategories(null);
        // hack!!! that was the only way to reset select value
        const timeout = setTimeout(() => {
          setSelectedSubCategories([]);
          setPatientOrgDiscussionSubTopics([]);
          clearTimeout(timeout);
        });
        return;
      }

      // hack!!! that was the only way to reset select value
      const timeout = setTimeout(() => {
        setSelectedCategories(topics.map((t) => ({ name: t })));
        const subTopics = gskDiscussionSubTopics.reduce((acc, topic) => {
          const topicIsAdded = topics.some((t) => t === topic.name);

          if (topicIsAdded) {
            return [...acc, ...topic.subTopics];
          }

          return acc;
        }, []);

        setPatientOrgDiscussionSubTopics(subTopics);
        setSelectedDiscussionTopic(topics);

        clearTimeout(timeout);
      });
    },
    [gskDiscussionSubTopics, selectedCategories]
  );

  const fetchEmails = useCallback(async () => {
    if (notableEmail?.length > 0) {
      const emailDataMsft = await searchEmails(
        instance,
        accounts,
        `${notableEmail} ${session.email}`,
        50,
        session?.users_integrations
      );
      setMsftEmails(emailDataMsft?.value);
    } else {
      const emailDataMsft = await getEmails(
        instance,
        accounts,
        selectedCompany.domain ||
          selectedPatientOrganization.domain ||
          notableEmail,
        true,
        session?.users_integrations
      );

      setMsftEmails(emailDataMsft?.value);
    }
    console.log(emailData);
  }, [instance, accounts, msftEmails]);

  useEffect(() => {
    if (selectedCouncil && orgType === "patient" && type === "meeting") {
      handleActivitySelect({ val: 3, obj: [{ text: "Meeting", value: 3 }] });
    }
  }, [selectedCouncil, type, orgType]);

  useEffect(() => {
    popup.setTitle({
      title:
        customTitle ||
        `${data ? "Edit" : "Capture New"} ${
          data ? data.type : activityType || "Activity"
        }`,
    });
    if (selectedCouncil) {
      dispatch(councilUsersListGet(selectedCouncil.id, enqueueSnackbar));

      dispatch(
        getUserTeams({
          enqueueSnackbar,
          query: {
            items: 1000,
          },
        })
      );
    }
  }, [selectedCouncil]);

  useEffect(() => {
    if (data && data.discussion_topics) {
      const getTopicCategories = checkItHasTopics(data);
      const otherTopic = getOtherIfItExists(data) || "";

      const categories = getTopicCategories.map((t) => ({ name: t.name }));
      const subCategories = getTopicCategories.reduce(
        (acc, elem) => [...acc, ...elem.subTopics],
        []
      );

      if (otherTopic) {
        setSelectedCategories(categories);
      } else {
        setSelectedCategories(categories.filter((c) => c.name !== "Other"));
      }

      setPatientOrgDiscussionSubTopics(subCategories);
      setSelectedSubCategories(
        data.discussion_topics
          .map((e) => ({ name: e }))
          .filter((t) => t.name !== otherTopic)
      );
      setSelectedDiscussionTopic(data.discussion_topics.map((e) => e));
    }

    if (data?.teams?.length > 0) {
      setSelectedTeamOption(
        data?.teams.map(({ team_id: value, name: label }) => ({
          label,
          value,
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    if (activityType && !initialValues) {
      const dateParts = new Date().toLocaleDateString("en-GB").split("/");
      const current = dateParts.reverse().join("-");
      setSelectedDate(current);
      model.date = current;
    }
    if (activityType && emailData.length > 0) {
      const newEmailDate = new Date(emailData[0].sentDateTime)
        .toLocaleDateString("en-GB")
        .split("/");
      const emailDate = newEmailDate.reverse().join("-");
      setSelectedDate(emailDate);
      model.date = emailDate;
    }
  }, [activityType, emailData]);

  useEffect(() => {
    fetchEmails(instance, accounts);
  }, [instance, accounts]);

  useEffect(() => {
    if (userTeams.length > 0 && optionVisibility.length === 2) {
      setOptionVisibility([
        ...optionVisibility,
        {
          text: "Team (visible to selected teams)",
          value: "for_teams",
        },
      ]);
    }
  }, [userTeams]);

  const availableActivitiesTypes = () => {
    if (orgType === "patient") {
      return optionActivitiesGSK;
    }

    if (activitiesTypes && activitiesTypes.length > 0) {
      return optionActivities.filter((activity) =>
        activitiesTypes.includes(activity.text)
      );
    }
    return optionActivities;
  };

  return (
    <div className={styles.wrapper}>
      <Formik
        innerRef={formRef}
        initialValues={data ? initialValues : model}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleOnSubmit(values);
          resetForm();
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          handleChange,
          values,
          ...formikprops
        }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  {!data && (
                    <>
                      <Label>
                        {orgType === "patient"
                          ? "Engagement Type"
                          : "Note Type"}
                      </Label>
                      <DropSelect
                        SelecedValues={orgType === "patient" ? 6 : 4} // 3 is for meeting, 4 is for note, 6 is for project
                        onChange={handleActivitySelect}
                        placeholder="Activity"
                        labelField="text"
                        valueField="value"
                        searchBy="text"
                        options={availableActivitiesTypes()}
                        keepSelectedInList={false}
                      />
                    </>
                  )}
                  <FieldSelector
                    selectedModel={selectedModel}
                    orgType={orgType}
                    activityType={activityType}
                    data={data}
                    formikprops={formikprops}
                    values={values}
                    handleChange={handleChange}
                    setAddMoreFields={setAddMoreFields}
                    addMoreFields={addMoreFields}
                    setOtherAttendees={setOtherAttendees}
                    otherAttendees={otherAttendees}
                    tempAttendees={tempAttendees}
                    microsoftLogin={microsoftLogin}
                    msftEmails={msftEmails}
                    selectedEmail={selectedEmail}
                    setEmailData={setEmailData}
                    setFieldValue={setFieldValue}
                    setSelectedEmail={setSelectedEmail}
                    setFilterContact={setFilterContact}
                    filterContact={filterContact}
                    emailData={emailData}
                    selectedCategories={selectedCategories}
                    handlePatientOrgDiscussionSelect={
                      handlePatientOrgDiscussionSelect
                    }
                    patientOrgDiscussionSubTopics={
                      patientOrgDiscussionSubTopics
                    }
                    selectedSubCategories={selectedSubCategories}
                    handleSubcategoryTopicSelect={handleSubcategoryTopicSelect}
                    initialValues={initialValues}
                    model={model}
                    handleDateSelect={handleDateSelect}
                    deleteAttendee={deleteAttendee}
                    usersList={usersList}
                    setSelectedItem={setSelectedItem}
                    handleAddMoreFieldsClick={handleAddMoreFieldsClick}
                    handleEditorState={handleEditorState}
                    company={company}
                    selectedProj={selectedProj}
                    setSelectedProj={setSelectedProj}
                    handleClear={handleClear}
                    handleVisibilityChange={handleVisibilityChange}
                    optionVisibility={optionVisibility}
                    visibility={visibility}
                    setSelectedTeamOption={setSelectedTeamOption}
                    selectedTeamOption={selectedTeamOption}
                    userTeams={userTeams}
                    selectedProjectTopic={selectedProjectTopic}
                    handleProjectTopicSelect={handleProjectTopicSelect}
                    handleStatusSelect={handleStatusSelect}
                    projectTopics={projectTopics}
                    selectedStatus={selectedProjectStatus}
                    {...props}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {data && (
                <Icon
                  size={22}
                  icon="icn-button-delete"
                  className={styles.deleteIcon}
                  onClick={(val) => handleDeleteClick(data)}
                />
              )}
              <Button
                btn={BtnType.FRAME_LESS}
                onClick={handleCancelClick}
                className="mr-2"
              >
                Close
              </Button>
              <Button type="submit" btn={BtnType.REGULAR}>
                Save
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(NewEventComponent);

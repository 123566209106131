export const THEMES_GET_ATTEMPT = "THEMES_GET_ATTEMPT";
export const themesGetAttempt = (payload) => ({
  type: THEMES_GET_ATTEMPT,
  payload,
});
export const THEMES_GET_SUCCESS = "THEMES_GET_SUCCESS";
export const themesGetAttemptSuccess = (payload) => ({
  type: THEMES_GET_SUCCESS,
  payload,
});

export const THEMES_GET_TOPICS_ATTEMPT = "THEMES_GET_TOPICS_ATTEMPT";
export const themesGetTopicAttempt = (payload) => ({
  type: THEMES_GET_TOPICS_ATTEMPT,
  payload,
});
export const THEMES_GET_TOPICS_SUCCESS = "THEMES_GET_TOPICS_SUCCESS";
export const themesGetTopicSuccess = (payload) => ({
  type: THEMES_GET_TOPICS_SUCCESS,
  payload,
});

export const THEMES_GET_INDUSTRIES_ATTEMPT = "THEMES_GET_INDUSTRIES_ATTEMPT";
export const themesGetIndustriesAttempt = (payload) => ({
  type: THEMES_GET_INDUSTRIES_ATTEMPT,
  payload,
});

export const THEMES_GET_INDUSTRIES_SUCCESS = "THEMES_GET_INDUSTRIES_SUCCESS";
export const themesGetIndustriesSuccess = (payload) => ({
  type: THEMES_GET_INDUSTRIES_SUCCESS,
  payload,
});

export const THEMES_GET_INDIVIDUAL_ATTEMPT = "THEMES_GET_INDIVIDUAL_ATTEMPT";
export const themesGetIndividualAttempt = (payload) => ({
  type: THEMES_GET_INDIVIDUAL_ATTEMPT,
  payload,
});
export const THEMES_GET_INDIVIDUAL_SUCCESS = "THEMES_GET_INDIVIDUAL_SUCCESS";
export const themesGetIndividualSuccess = (payload) => ({
  type: THEMES_GET_INDIVIDUAL_SUCCESS,
  payload,
});

export const THEME_ADD_ATTEMPT = "THEME_ADD_ATTEMPT";
export const themesPostAttempt = (payload) => ({
  type: THEME_ADD_ATTEMPT,
  payload,
});

export const THEME_ADD_SUCCESS = "THEME_ADD_SUCCESS";
export const themesAddSuccess = (payload) => ({
  type: THEME_ADD_SUCCESS,
  payload,
});
export const THEME_EDIT_ATTEMPT = "THEME_EDIT_ATTEMPT";
export const themesEditAttempt = (payload) => ({
  type: THEME_EDIT_ATTEMPT,
  payload,
});
export const THEME_EDIT_SUCCESS = "THEME_EDIT_SUCCESS";
export const themesEditSuccess = (payload) => ({
  type: THEME_EDIT_SUCCESS,
  payload,
});
export const THEME_DELETE_ATTEMPT = "THEME_DELETE_ATTEMPT";
export const themesDeleteAttempt = (payload) => ({
  type: THEME_DELETE_ATTEMPT,
  payload,
});

export const THEME_DELETE_SUCCESS = "THEME_DELETE_SUCCESS";
export const themesDeleteSuccess = (payload) => ({
  type: THEME_DELETE_SUCCESS,
  payload,
});

export const THEMES_COMPANIES_GET_ATTEMPT = "THEMES_COMPANIES_GET_ATTEMPT";
export const themesCompaniesGetAttempt = (payload) => ({
  type: THEMES_COMPANIES_GET_ATTEMPT,
  payload,
});
export const THEMES_COMPANIES_GET_SUCCESS = "THEMES_COMPANIES_GET_SUCCESS";
export const themesCompaniesGetSuccess = (payload) => ({
  type: THEMES_COMPANIES_GET_SUCCESS,
  payload,
});

export const PIPELINE_GET_INVEST_STAGES_ACROSS_ATTEMPT =
  "PIPELINE_GET_INVEST_STAGES_ACROSS_ATTEMPT";

export const pipelineGetInvestStagesAcrossAttempt = (payload) => {
  return {
    type: PIPELINE_GET_INVEST_STAGES_ACROSS_ATTEMPT,
    payload,
  };
};

export const PIPELINE_UPDATE_INVEST_COMP_LIST_FE_ACROSS_SUCCESS =
  "PIPELINE_UPDATE_INVEST_COMP_LIST_FE_ACROSS_SUCCESS";

export const pipelineUpdateInvestCompListFE = (payload) => {
  return {
    type: PIPELINE_UPDATE_INVEST_COMP_LIST_FE_ACROSS_SUCCESS,
    payload,
  };
};

export const PIPELINE_GET_INVEST_STAGES_ACROSS_SUCCESS =
  "PIPELINE_GET_INVEST_STAGES_ACROSS_SUCCESS";
export const pipelineGetInvestStagesAcrossSuccess = (payload) => ({
  type: PIPELINE_GET_INVEST_STAGES_ACROSS_SUCCESS,
  payload,
});

export const THEMES_SEARCH_ATTEMPT = "THEMES_SEARCH_ATTEMPT";
export const themesSearchAttempt = (payload) => ({
  type: THEMES_SEARCH_ATTEMPT,
  payload,
});

export const THEMES_SEARCH_SUCCESS = "THEMES_SEARCH_SUCCESS";
export const themesSearchSuccess = (payload) => ({
  type: THEMES_SEARCH_SUCCESS,
  payload,
});

export const THEMES_FAV_ATTEMPT = "THEMES_FAV_ATTEMPT";
export const themesFavAttempt = (payload) => ({
  type: THEMES_FAV_ATTEMPT,
  payload,
});

export const THEMES_FAV_SUCCESS = "THEMES_FAV_SUCCESS";
export const themesFavSuccess = (payload) => ({
  type: THEMES_FAV_SUCCESS,
  payload,
});

export const THEMES_FAV_DELETE_ATTEMPT = "THEMES_FAV_DELETE_ATTEMPT";
export const themesFavDeleteAttempt = (payload) => ({
  type: THEMES_FAV_DELETE_ATTEMPT,
  payload,
});

export const THEMES_FAV_DELETE_SUCCESS = "THEMES_FAV_DELETE_SUCCESS";
export const themesFavDeleteSuccess = (payload) => ({
  type: THEMES_FAV_DELETE_SUCCESS,
  payload,
});

export const THEMES_COMPANIES_SEARCH_ATTEMPT =
  "THEMES_COMPANIES_SEARCH_ATTEMPT";
export const themesCompaniesSearchAttempt = (payload) => {
  return {
    type: THEMES_COMPANIES_SEARCH_ATTEMPT,
    payload,
  };
};

export const THEMES_COMPANIES_SEARCH_SUCCESS =
  "THEMES_COMPANIES_SEARCH_SUCCESS";
export const themesCompaniesSearchSuccess = (payload) => ({
  type: THEMES_COMPANIES_SEARCH_SUCCESS,
  payload,
});

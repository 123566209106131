import * as Yup from "yup";
import { activityTypes } from "../activity.constant";

export const initModel = {
  recipients: [],
  groups: [],
  anonymouse: false,
  subject: "",
  message: "",
  name: "",
  email: "",
  date: "",
  location: "",
  description: "",
  discussion_topic: "",
  discussion_topic_other: "",
  key_findings: "",
  roles_of_attendees: "",
  initial_introductory_meeting: false,
  visibility: "for_all",
  event_time: null,
  team_ids: [],
};

export const validation = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  starts_on: Yup.string().required("Start date is required"),
  ends_on: Yup.string().required("End date is required"),
});

export const optionActivities = [
  {
    text: "Note",
    value: activityTypes.note,
  },
  {
    text: "Call",
    value: activityTypes.call,
  },
  {
    text: "Email",
    value: activityTypes.email,
  },
  {
    text: "Event",
    value: activityTypes.event,
  },
  {
    text: "Meeting",
    value: activityTypes.meeting,
  },
];

export const optionActivitiesGSK = [
  {
    text: "Project",
    value: activityTypes.project,
  },
  {
    text: "Meeting",
    value: activityTypes.meeting,
  },
  {
    text: "Call",
    value: activityTypes.call,
  },
  {
    text: "Email",
    value: activityTypes.email,
  },
  {
    text: "Event",
    value: activityTypes.event,
  },
  {
    text: "Other",
    value: activityTypes.note,
  },
];

export const modelTemplate = {
  [activityTypes.call]: {
    name: {
      label: "Spoke with",
      placeholder: "Name",
    },
    date: {
      label: "Date of Call",
      placeholder: "Date and Time",
    },
    spoke_about: {
      label: "Summary",
      placeholder: "Capture conversation notes",
    },
  },
  [activityTypes.email]: {
    name: {
      label: "Contact",
      placeholder: "Name",
    },
    email: {
      label: "Email address",
      placeholder: "Email address",
    },
    date: {
      label: "Date",
      placeholder: "Date and Time",
    },
    time: {
      label: "Time",
      placeholder: "Time",
    },
    description: {
      label: "Summary",
      placeholder: "Capture email summary",
    },
    subject: {
      label: "Email Subject",
      placeholder: "Subject Line",
    },
  },
  [activityTypes.merck_email]: {
    to_contact: {
      label: "Email Recipient",
      placeholder: "Name",
    },
    from_contact: {
      label: "Email Sender (Contact)",
      placeholder: "Name",
    },
    from_email: {
      label: "Email address (From)",
      placeholder: "Email address",
    },
    to_email: {
      label: "Email address (To)",
      placeholder: "Email address",
    },
    date: {
      label: "Date",
      placeholder: "Date and Time",
    },
    time: {
      label: "Time",
      placeholder: "Time",
    },
    description: {
      label: "Summary",
      placeholder: "Capture email summary",
    },
    subject: {
      label: "Email Subject",
      placeholder: "Subject Line",
    },
  },
  [activityTypes.event]: {
    title: {
      label: "Event title",
      placeholder: "Add a title of the event",
    },
    date: {
      label: "Date",
      placeholder: "Date",
    },
    time: {
      label: "Time",
      placeholder: "Time",
    },
    location: {
      label: "Location",
      placeholder: "Event Location",
    },
    description: {
      label: "Summary",
      placeholder: "Add a description of the event",
    },
    attendees: {
      placeholder: "Add attendees ",
      label: "Event attendees",
    },
  },
  [activityTypes.meeting]: {
    date: {
      label: "Date",
      placeholder: "Meeting Date",
    },
    time: {
      label: "Time",
      placeholder: "Meeting Time",
    },
    location: {
      label: "Location",
      placeholder: "Meeting Location",
    },
    description: {
      label: "Summary",
      placeholder: "Add a description of the meeting",
    },
    title: {
      placeholder: "Add a title of the meeting",
      label: "Meeting Title",
    },
    attendees: {
      placeholder: "Add attendees",
      label: "Meeting attendees",
    },
  },
  [activityTypes.note]: {
    note: {
      label: "Summary",
      placeholder: "Add your thoughts…",
    },
  },
};

export const optionVisibility = [
  {
    text: "Private (only visible to you)",
    value: "self_private",
  },
  {
    text: "Team (visible to selected teams)",
    value: "for_teams",
  },
  {
    text: "All (visible to everyone)",
    value: "for_all",
  },
];

import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import DoubleScrollbar from "react-double-scrollbar";

import { Loading } from "../../../../primitives/index";
import KanbanStageComponent from "./kanbanStage";
import styles from "./styles.module.scss";
import {
  pipelineEditStageAcrossAttempt,
  pipelineEditStageAttempt,
  pipelineGetInvestStagesAttempt,
  pipelineGetStagesAttempt,
  pipelineUpdateCompListFE,
} from "../pipelineTab.action";
import {
  pipelineGetInvestStagesAcrossAttempt,
  pipelineUpdateInvestCompListFE,
} from "../../../themesMainPage/themesMain.action";

import { themesCompanyDeleteAttempt } from "../../themesOverviewTab/overviewTab.action";

const PipelineKanban = (props) => {
  const { themeId, fromThemes, investMode } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [companySelected, setCompany] = useState("-");
  const [stages, setStages] = useState([]);
  const [isDragginCompany, setIsDraggingCompany] = useState(false);
  const stagesRef = useRef();

  const {
    authReducer: { session },
    themesReducer: {
      themesPipelineReducer: {
        companiesInStagesList,
        piplineInvestStages,
        loadingStages,
        shouldUpdateStages,
        shouldUpdateInvestStages,
        loadingInvestCompanies,
      },
      themesMainReducer: {
        themesCompaniesAcross,
        loadingThemesCompanies,
        shouldUpdateThemesCompanies,
        shouldGetThemesCompanies,
        investCompaniesListAcross,
        updateInvestStages,
      },
      themesOverviewReducer: { updateKanbanCompanies },
    },
    companiesReducer: {
      companyReducer: { prevPagePath },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil && themeId && !investMode) {
      dispatch(pipelineGetStagesAttempt({ themeId, enqueueSnackbar }));
    }
  }, [selectedCouncil, session, investMode, updateKanbanCompanies]);

  useEffect(() => {
    if (selectedCouncil && investMode) {
      if (themeId) {
        // if (session?.id) {
        //   const url = `${details?.type}/${details?.id}`;

        //   httpGet({
        //     call: url,
        //   })
        //     .pipe()
        //     .subscribe((res) => {
        //       setResults(res.response);

        //       setShowLoadingNotifications(false);
        //     });
        // }
        dispatch(pipelineGetInvestStagesAttempt({ themeId, enqueueSnackbar }));
      } else {
        dispatch(pipelineGetInvestStagesAcrossAttempt({ enqueueSnackbar }));
      }
    }
  }, [selectedCouncil, investMode]);
  const setCurrentCompany = (comp) => {
    setCompany(comp);
  };
  const changeCompanyStage = (data) => {
    if (fromThemes) {
      if (investMode) {
        dispatch(
          pipelineUpdateInvestCompListFE({
            data,
            themeId: companySelected.theme_id,
            companyId: companySelected.id,
            productId: companySelected.product_id,
            investMode,
            companySelected,
          })
        );
      } else {
        dispatch(
          pipelineUpdateCompListFE({
            data,
            themeId: companySelected.theme_id,
            companyId: companySelected.id,
            productId: companySelected.product_id,
            investMode,
            companySelected,
          })
        );
      }
      dispatch(
        pipelineEditStageAcrossAttempt({
          data:
            companySelected?.attributes?.resource_type === "product"
              ? {
                  council_relationship_stage_id:
                    data?.council_relationship_stage_id,
                }
              : data,
          themeId: companySelected.theme_id,
          companyId: companySelected.id,
          productId: companySelected.id,
          investMode,
          isProduct: companySelected?.attributes?.resource_type === "product",
        })
      );
      //
    } else {
      dispatch(
        pipelineEditStageAttempt({
          data,
          themeId: companySelected.theme_id,
          companyId: companySelected.company_id,
          productId: companySelected.product_id,
          investMode,
        })
      );
    }

    setCompany("-");
  };

  const deleteCompanyStage = (compToDelete) => {
    dispatch(
      themesCompanyDeleteAttempt({
        id: themeId,
        companyId: compToDelete.company_id,
        enqueueSnackbar,
      })
    );
    setCompany("-");
  };

  const checkPartnershipUpdateStages = () => {
    let isCouncilGenerated = false;
    let fromThemeModule = false;
    let isShouldUpdateStages = false;
    const isNotFromDetails = prevPagePath.indexOf("details") === -1;
    if (selectedCouncil) {
      isCouncilGenerated = true;
    }
    if (isCouncilGenerated && (themeId || fromThemes)) {
      fromThemeModule = true;
    }

    if (fromThemeModule && shouldUpdateStages) {
      isShouldUpdateStages = true;
    }

    return (isNotFromDetails || fromThemes) && isShouldUpdateStages;
  };

  useEffect(() => {
    if (checkPartnershipUpdateStages()) {
      setStages(companiesInStagesList);
    }
  }, [shouldUpdateStages]);

  const prepareCompanyAcross = () => {
    const tabStages = investMode
      ? investCompaniesListAcross
      : themesCompaniesAcross;
    return tabStages?.map((stage) => {
      // const compaThemes = {};
      const stageCompIds = [];
      const companies = stage.theme_companies
        .map((comp) => {
          // compaThemes[comp.name] = compaThemes[comp.name]
          //   ? [...compaThemes[comp.name], comp.theme_name]
          //   : [comp.theme_name];
          if (stageCompIds.includes(comp.id)) {
            return null;
          }
          stageCompIds.push(comp.id);
          return {
            ...comp.attributes,
            ...comp,
            company_id: comp.id,
          };
        })
        .filter((el) => el !== null);

      return { ...stage, theme_companies: companies };
    });
  };

  useEffect(() => {
    if (selectedCouncil && fromThemes && shouldUpdateThemesCompanies) {
      setStages(prepareCompanyAcross());
    }
  }, [shouldUpdateThemesCompanies, updateInvestStages]);

  useEffect(() => {
    if (selectedCouncil && prevPagePath.indexOf("details") !== -1) {
      setStages(prepareCompanyAcross());
    }
  }, [prevPagePath]);
  useEffect(() => {
    if (selectedCouncil && fromThemes && shouldGetThemesCompanies) {
      if (!investMode) {
        // dispatch(themesCompaniesGetAttempt({ enqueueSnackbar }));
      } else {
        // dispatch(pipelineGetInvestStagesAcrossAttempt({ enqueueSnackbar }));
      }
    }
  }, [selectedCouncil, shouldGetThemesCompanies]);

  useEffect(() => {
    if (selectedCouncil && investMode) {
      setStages(piplineInvestStages);
    }
  }, [shouldUpdateInvestStages]);

  useEffect(() => {
    let intervalId;

    const handleMouseMove = (e) => {
      const container = stagesRef.current?.parentElement;
      const containerWidth = container?.offsetWidth;
      const mouseX = e.clientX;

      if (mouseX > containerWidth * 0.9) {
        container.scrollLeft += 5;
      } else if (mouseX < containerWidth * 0.3) {
        container.scrollLeft -= 5;
      }
    };

    const mouseOverHandler = (e) => {
      intervalId = setInterval(() => {
        if (isDragginCompany) {
          handleMouseMove(e);
        }
      }, 10);
    };

    const mouseOutHandler = () => {
      clearInterval(intervalId);
    };

    if (stagesRef.current) {
      stagesRef.current.addEventListener("mouseover", mouseOverHandler);
      stagesRef.current.addEventListener("mouseout", mouseOutHandler);
    }

    return () => {
      clearInterval(intervalId);
      if (stagesRef.current) {
        stagesRef.current.removeEventListener("mouseover", mouseOverHandler);
        stagesRef.current.removeEventListener("mouseout", mouseOutHandler);
      }
    };
  }, [stagesRef.current, isDragginCompany, companySelected]);

  // if (loadingStages || loadingThemesCompanies || loadingInvestCompanies) {
  //   return <KanbanLoader />;
  // }
  return (
    <div className={styles.stagesWrp}>
      {(loadingThemesCompanies || loadingInvestCompanies) && (
        <div className={styles.loaderOverlay}>
          <Loading />
        </div>
      )}

      <DoubleScrollbar>
        <div ref={stagesRef} className={styles.stagesCon}>
          {stages?.map((stage) => (
            <KanbanStageComponent
              fromThemes={fromThemes}
              themeId={themeId}
              key={stage.council_relationship_stage_id}
              stage={stage}
              stages={stages}
              setCurrentCompany={setCurrentCompany}
              companySelected={companySelected}
              changeCompanyStage={changeCompanyStage}
              investStages={selectedCouncil.theme_investment_stages}
              investMode={investMode}
              selectedCouncil={selectedCouncil.traction_tag}
              decisionMakerAccess={session.decision_maker_access}
              userRole={session?.council_role}
              councilName={selectedCouncil?.name}
              deleteCompanyStage={deleteCompanyStage}
              setIsDraggingCompany={setIsDraggingCompany}
            />
          ))}
        </div>
      </DoubleScrollbar>
    </div>
  );
};

export default React.memo(PipelineKanban);

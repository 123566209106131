import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider, responsiveFontSizes, createTheme } from "@mui/material";
import configStore from "./root/store";
import ErrorBoundary from "./common/components/errorBoundary";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const themeOptions = {}
const theme = responsiveFontSizes(createTheme(themeOptions));

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={configStore()}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

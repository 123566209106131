import React from "react";
import { companyGetCustomers } from "modules/companies/company/company.action";
import { searchInCouncil } from "modules/companies/companyMain/companyMain.action";
import CompanyDeleteModal from "modules/tractionAdmin/companies/deleteModal/index";
import { videoExtensions } from "./editCompany.constants";
import ConfirmDeleteComponent from "../../../../common/components/confirmDelete";
import NewCompetitorModalComponent from "./addNewCompetitors.component";

const checkIfS3 = (url) => {
  const match = /(s3-|s3\.)?(.*)\.amazonaws\.com/g.exec(url);

  if (match) {
    return match[0];
  }
};

const getFilename = (url) => {
  if (url) {
    return url.split("/").pop();
  }
  return "";
};

const getUrlExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

const isEmbedded = (url) =>
  videoExtensions.indexOf(getUrlExtension(url)) !== -1;

const checkPlatform = (url) => {
  const regExpYoutube =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const regExpVimeo =
    /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
  const regExpFacebook =
    /^https?:\/\/www\.facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;

  if (url) {
    if (url.includes("iframe")) {
      return "iframe";
    }
    if (url.match(regExpYoutube)) {
      return "youtube";
    }
    if (url.match(regExpVimeo)) {
      return "vimeo";
    }
    if (url.match(regExpFacebook)) {
      return "facebook";
    }
  }
};

const handleAddDocument = async (props) => {
  const {
    FileObject,
    setFieldValue,
    setLoading,
    videos,
    setVideos,
    enqueueSnackbar,
    councilId,
    companyId,
    fileUpload,
  } = props;

  const file = FileObject;
  const { name } = file;
  setLoading(true);
  const pathname = `srm/${councilId}/companies/${companyId}/videos/`;
  await fileUpload(file, pathname).then((res) => {
    if (res && res.location) {
      if (videos?.indexOf(res.location) === -1) {
        setLoading(false);
        setVideos((prevVideos) => {
          if (prevVideos) {
            return [...prevVideos, res.location];
          }
          return [res.location];
        });
      } else {
        setLoading(false);
        enqueueSnackbar("Video is already added.", {
          variant: "error",
        });
      }
      setFieldValue("promo_video", "");
    }
    setLoading(false);
  });
};

const handleAddVideo = (props) => {
  const {
    setFieldValue,
    setLoading,
    videos,
    setVideos,
    enqueueSnackbar,
    formRef,
  } = props;

  const video = formRef.current.values.promo_video;
  setLoading(true);

  if (videos) {
    if (
      checkPlatform(video) &&
      !isEmbedded(video) &&
      videos.indexOf(video) === -1
    ) {
      setLoading(false);
      setVideos((prevVideos) => [...prevVideos, video.trim()]);
    } else {
      setLoading(false);

      if (videos.indexOf(video) !== -1) {
        enqueueSnackbar("Video is already added.", {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          "External video links should be from Youtube, Facebook or Vimeo",
          {
            variant: "error",
          }
        );
      }
    }
  }
  setFieldValue("promo_video", "");
};

const handleDeleteVideo = async (props) => {
  const {
    e,
    index,
    setDeletingVideo,
    councilId,
    companyId,
    videos,
    setVideos,
    fileDelete,
  } = props;

  e.stopPropagation();
  setDeletingVideo(index);
  if (checkIfS3(videos[index])) {
    const pathname = `srm/${councilId}/companies/${companyId}/videos/${getFilename(
      videos[index]
    )}`;
    await fileDelete(pathname).then(() => {
      setVideos(() => {
        setDeletingVideo("");
        return videos.filter((slide, idx) => idx !== index);
      });
    });
  } else {
    setVideos(() => videos.filter((video, idx) => idx !== index));
  }
};

const handleLocationChange = (value, setFieldValue) => {
  if (value?.length) {
    const {
      name = "",
      city = "",
      continent = "",
      country = "",
      region = "",
    } = value[0];

    setFieldValue("location", name);
    setFieldValue("location_city", city);
    setFieldValue("location_region", region);
    setFieldValue("location_country", country);
    setFieldValue("location_continent", continent);
  } else {
    setFieldValue("location", "");
    setFieldValue("location_city", "");
    setFieldValue("location_region", "");
    setFieldValue("location_country", "");
    setFieldValue("location_continent", "");
  }
};

const handleAddNewLocation = (locationObj, setFieldValue) => {
  setFieldValue("location_city", locationObj.location_city);
  setFieldValue("location_region", locationObj.location_region);
  setFieldValue("location_country", locationObj.location_country);
  setFieldValue("location_continent", locationObj.continent);
};

const handleMoreOptionClick = (props, fromTractionAdmin) => {
  const {
    event,
    companyId,
    slug,
    companyName,
    history,
    selectedPatientOrganization,
    popup,
    enqueueSnackbar,
    selectedCouncil,
    company,
  } = props;

  let id = companyId;
  let name = companyName;

  if (history.location?.state?.gsk) {
    id = selectedPatientOrganization?.id;
    name = selectedPatientOrganization?.name;
  }

  if (event === "profile") {
    if (history.location?.state?.gsk) {
      history.push(`/patient_organizations/${slug || id}`, { gsk: true });
      return;
    }
    history.push(`/companies/${slug || companyId}`);
  }
  if (event === "delete") {
    if (fromTractionAdmin) {
      popup.show({
        title: "Confirmation Prompt",
        show: true,
        height: "300",
        width: "540",
        component: (
          <CompanyDeleteModal
            id={id}
            enqueueSnackbar={enqueueSnackbar}
            popup={popup}
            company={company}
          />
        ),
      });
    } else {
      popup.show({
        title: "Confirmation Prompt",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={id}
            enqueueSnackbar={enqueueSnackbar}
            councilId={selectedCouncil.id}
            name={name}
            onCompanyDelete={() => {
              history.replace({ pathname: "/companies", state: {} });
            }}
            onOrganisationDelete={() => {
              history.replace({
                pathname: "/patient_organizations",
                state: {},
              });
            }}
            actionType={
              selectedCouncil &&
              selectedCouncil.name === "GSK" &&
              history.location?.state?.gsk
                ? "deleteOrganization"
                : "deleteCompany"
            }
          />
        ),
      });
    }
  }
};

const getCustomers = (query, enqueueSnackbar, dispatch) => {
  dispatch(companyGetCustomers({ query: query || "a", enqueueSnackbar }));
};

const getCompetitors = (query, dispatch, councilId, enqueueSnackbar) => {
  if (query) {
    dispatch(
      searchInCouncil({
        data: query,
        council_id: councilId,
        mode: "companies",
        enqueueSnackbar,
        cb: () => {},
      })
    );
  } else {
    dispatch(
      searchInCouncil({
        data: "a",
        council_id: councilId,
        mode: "companies",
        enqueueSnackbar,
        cb: () => {},
      })
    );
  }
};

const handleIdeasSelect = (value, councilId, company, setRelatedIdeas) => {
  setRelatedIdeas(
    value.obj.map((i) => ({
      council_id: councilId,
      idea: {
        name: i.name,
        idea_id: i.id,
      },
      company: {
        company_id: company.id,
        name: company.name,
      },
    }))
  );
};

const removeImg = async (props) => {
  const { setFieldValue, currentLogo, fileDelete, setCurrentLogo } = props;
  const url = currentLogo?.split("/").slice(3, 7).join("/");
  await fileDelete(url).then(() => {
    setFieldValue("logo", null);
    setCurrentLogo(false);
  });
};

const handleTopicChange = (props) => {
  const { val, selectedTopics, setTopic, setSearchValue } = props;

  try {
    const alreadySelected = selectedTopics
      ? selectedTopics.find((el) => el.id === val.id)
      : [];
    if (alreadySelected && selectedTopics) {
      const filteredTopics = selectedTopics.filter((el) => el.id !== val.id);
      setTopic(filteredTopics);
      return;
    }
    setTopic((prevState) => [...prevState, val]);
    setSearchValue("");
  } catch (error) {
    console.log("---------err", error);
  }
};

const handleTopicRemove = (id, setPrevTopics, prevTopics) => {
  setPrevTopics(prevTopics.filter((item) => item.topic.id !== id));
};

const handleBusinessRemove = (id, setPrevBusiness, prevBusiness) => {
  setPrevBusiness(prevBusiness.filter((item) => item.id !== id));
};
const handleBusinessChange = (props) => {
  const {
    val,
    selectedBusinessUnitsValues,
    setSelectedBusinessUnitsValues,
    setSearchValue,
  } = props;

  const alreadySelected = selectedBusinessUnitsValues?.find(
    (el) => el.id === val.id
  );
  if (alreadySelected) {
    const filteredTopics = selectedBusinessUnitsValues?.filter(
      (el) => el.id !== val.id
    );
    setSelectedBusinessUnitsValues(filteredTopics);
    return;
  }
  setSelectedBusinessUnitsValues((prevState) => [...prevState, val]);
  setSearchValue("");
};

const handleIndustryChange = (props) => {
  const { val, industry, setIndustry, setIndustrySearchValue } = props;

  const alreadySelected = industry.find((el) => el.id === val.id);
  if (alreadySelected) {
    const filteredTopics = industry.filter((el) => el.id !== val.id);
    setIndustry(filteredTopics);
    return;
  }
  setIndustry((prevState) => [...prevState, val]);
  setIndustrySearchValue("");
};

const handleIndustryRemove = (id, prevIndustries, setPrevIndustries) => {
  setPrevIndustries(prevIndustries.filter((item) => item.industry.id !== id));
};

const handleAddNewCompany = (props) => {
  const { event, orgId, setDisabledCompetitors, popup, enqueueSnackbar } =
    props;

  setDisabledCompetitors(true);
  event.preventDefault();
  popup.show({
    title: "Add Competitor",
    component: (
      <NewCompetitorModalComponent
        orgId={props.match?.params?.id}
        enqueueSnackbar={enqueueSnackbar}
        setDisabledCompetitors={setDisabledCompetitors}
      />
    ),
  });
};

const prepareUnits = (selectedCouncil, businessUnits) => {
  if (
    selectedCouncil.name === "Freddie Mac" ||
    selectedCouncil.name === "Firmenich" ||
    selectedCouncil?.name === "Kyndryl"
  ) {
    return businessUnits.map((unit) => unit.id);
  }
  return undefined;
};

const prepareCustomers = (selectedCustomers, prevCustomers) =>
  selectedCustomers
    ?.map(({ id }) => id)
    .concat(prevCustomers?.map(({ id }) => id));

const prepareCompetitors = (competitors, prevCompetitors) =>
  competitors
    ?.map(({ company_id }) => company_id)
    .concat(prevCompetitors?.map(({ company_id }) => company_id));

const topicValues = (prevTopics, selectedTopics) => {
  return [...new Set([...selectedTopics])];
};

const industryValues = (prevIndustries, industry) => {
  return [...new Set([...industry])];
};

export {
  checkIfS3,
  getFilename,
  getUrlExtension,
  isEmbedded,
  checkPlatform,
  handleAddDocument,
  handleAddVideo,
  handleDeleteVideo,
  handleLocationChange,
  handleAddNewLocation,
  handleMoreOptionClick,
  getCustomers,
  getCompetitors,
  handleIdeasSelect,
  removeImg,
  handleTopicChange,
  handleTopicRemove,
  handleIndustryChange,
  handleIndustryRemove,
  handleAddNewCompany,
  prepareUnits,
  prepareCustomers,
  prepareCompetitors,
  topicValues,
  industryValues,
  handleBusinessRemove,
  handleBusinessChange,
};

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import styles from "./businessUnit.module.scss";

import { CheckBox, CheckType } from "../../../../../primitives";
import { Icon, smSize } from "../../../../../../common/icon";
import {
  queryStringUrlReplacement,
  queryParamStringToArray,
  useQuery,
} from "../../../../../../common/helper";

const BusinessUnitFilter = ({ expanded, handleExpand, councilId }) => {
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const history = useHistory();
  const useQuerryBusinessUnits = useQuery().get("businessUnits");
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setCollapsed(expanded);

    return () => null;
  }, [expanded]);
  useEffect(() => {
    let newBusiness = [];

    if (selectedCouncil?.business_units) {
      newBusiness = selectedCouncil.business_units.map((s) => ({
        ...s,
        selected: false,
      }));

      setItems(newBusiness);
    }

    if (useQuerryBusinessUnits) {
      const arr = queryParamStringToArray(useQuerryBusinessUnits);
      const newItems = newBusiness.map((item) => {
        const itemIsSelected = arr.includes(item.id);

        return {
          ...item,
          selected: itemIsSelected,
        };
      });

      setItems(newItems);
    }

    return () => null;
  }, [selectedCouncil?.business_units, useQuerryBusinessUnits]);

  const handleCollapseClick = () => {
    if (!collapsed) {
      handleExpand("business_unit");
    }
    setCollapsed(!collapsed);
  };

  const handleOnChange = (val, business) => {
    const relStatusIds = items
      .map((s) => {
        if (s.id === business.id) {
          return {
            ...s,
            selected: val,
          };
        }
        return s;
      })
      .filter((item) => item.selected)
      .reduce((acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`, "");

    let search = queryStringUrlReplacement(
      history.location.search,
      "businessUnits",
      relStatusIds
    );
    search = queryStringUrlReplacement(search, "page", 1);
    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        businessUnits: relStatusIds,
        page: 1,
      },
    });

    const newItems = items.map((s) => {
      if (s.id === business.id) {
        return {
          ...s,
          selected: val,
        };
      }

      return s;
    });

    setItems(newItems);
  };

  return (
    <div className={styles.itemWrapper} id="business_unit">
      <div className={styles.itemTitle} onClick={handleCollapseClick}>
        <span>
          {selectedCouncil.name === "Kyndryl" ? "Practice" : "Business Unit"}{" "}
        </span>
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(styles.icon, !collapsed && styles.collapse)}
          onClick={handleCollapseClick}
        />
      </div>
      {collapsed ? (
        <>
          {items.map((s) => (
            <div key={s.name} className={styles.filterRow}>
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={s.selected}
                onChange={(val) => handleOnChange(val, s)}
              />
              <span onClick={() => handleOnChange(!s.selected, s)}>
                {s.name}
              </span>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};

export default React.memo(BusinessUnitFilter);

import ChartDataLabels from "chartjs-plugin-datalabels";

import { externalTooltipHandler } from "modules/companies/companyMain/chartsWrapper/chartsView/charts/customTooltip";

export const makeLineChartOptions = chartData => ({
  scales: {
    y: {
      grid: {
        display: false
      },
      stacked: true,
      title: {
        display: true,
        text: chartData.y_title,
        font: {
          size: 14,
        },
        color: "#323f4b",
        letterSpacing: '1px',
      },
      ticks: {
        callback: (label) => {
          if (chartData.appendToYAxe) {
            return `${chartData.appendToYAxe}${label}`;
          }

          return label;
        },
      },
      beginAtZero: true,
    },
    x: {
      grid: {
        display: false
      },
      stacked: true,
      title: !chartData.doubleXAxe ? {
        display: true,
        text: chartData.x_title,
        font: {
          size: 14,
        },
        color: "#323f4b",
        letterSpacing: '1px',
      } : {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          if (chartData.hideSubLabel) {
            return ` ${context.dataset.data[context.dataIndex]}`;
          }

          return context.label
            ? `${context.label}: ${context.formattedValue}`
            : 'No label'
        },
      },
      enabled: false,
      position: "nearest",
      external: externalTooltipHandler,
    },
    datalabels: {
      display: false,
    }
  },
  title: {
    display: false,
  },
  layout: {
    padding: {
      bottom: 0,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
});

export const makeVerticalBarConfig = (chartData) => {
  return {
    data: {
      labels: chartData.labels,
      datasets: [{
        data: chartData.data_sets.map(el => el.data),
        backgroundColor: chartData.data_sets.map(el => el.color),
        borderColor: chartData.data_sets.map(el => el.color),
        sub_category_label: chartData.data_sets.map(el => el.name),
      }],
    },
    options: makeLineChartOptions(chartData),
    plugins: [ChartDataLabels],
  };
}

import React from "react";
import { UserInline, UserInlineTypes } from "modules/primitives";

const UserComponent = ({ source, propToUse, idProp, avatarProp, nameProp }) => {
  if (source || source[propToUse]) {
    return (
      <UserInline
        userId={source?.id || source[idProp] || ''}
        avatar={source?.avatar || source[propToUse]?.avatar || ''}
        first={source?.full_name || source[propToUse]?.full_name || ''}
        type={UserInlineTypes.WITH_NAME}
        dontRequest
        className="userNameWrap"
      />
    )
  }

  return (
    <div>
      {
        source && source[idProp] ? (
          <UserInline
            userId={source[idProp] || source?.id || ''}
            avatar={source[avatarProp] || source?.avatar || ''}
            first={source[nameProp] || source?.full_name || ''}
            type={UserInlineTypes.WITH_NAME}
            dontRequest
            className="userNameWrap"
          />
        ) : 'Traction'
      }
    </div>
  )
}

export default React.memo(UserComponent);

import React from "react";
import { format } from "date-fns";

import styles from "./funding.module.scss";

const MobileFundingRow = ({ fund, amount }) => (
  <div className={styles.mobileGridRow}>
    <div className={styles.mobileRow}>
      <span>Title</span>
      <div>{fund.series}</div>
    </div>
    <div className={styles.mobileRow}>
      <span>Announced On</span>
      <div>
        {format(new Date(fund.announced_on_month), "eeee, MMM do, yyyy")}
      </div>
    </div>
    <div className={styles.mobileRow}>
      <span>Amount</span>
      <div>${amount}</div>
    </div>
    <div className={styles.mobileRow}>
      <span>Investors</span>
      <div>{fund.investors}</div>
    </div>
  </div>
);

export default MobileFundingRow;

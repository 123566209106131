import React, { useCallback, useEffect, useState } from "react";
import ReactTable from "react-table"
import { format } from "date-fns";
import { useSnackbar } from "notistack";

import { httpGet } from "../../../common/httpCall";
import styles from "modules/tractionAdmin/councils/styles.module.scss";
import { BtnType, Button } from "modules/primitives";

const ApplicantSubmissions = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [submissions, setSubmissions] = useState(null);

  useEffect(() => {
    httpGet({
      apiVersion: 'v1',
      call: 'applicants/requests',
    }).subscribe(res => {
      if (res.response) {
        setSubmissions(res.response || []);
      }
    });
  }, []);

  const copyToClipBoard = useCallback((id, row, prop) => {
    if (!row[prop]) {
      httpGet({
        apiVersion: 'v1',
        call: `applicants/requests/${id}`,
      }).subscribe(res => {
        if (res.response) {
          const asString = JSON.stringify(res.response[prop]);
          navigator.clipboard.writeText(asString).then(() => {
            enqueueSnackbar('Copied!', {variant: 'success'});
          });

          const newRows = submissions.map(s => {
            if (s.id === res.response.id) {
              return {
                ...s,
                ...res.response,
              }
            }

            return s;
          });

          setSubmissions(newRows);
        }
      });

      return;
    }

    const asString = JSON.stringify(row[prop]);
    navigator.clipboard.writeText(asString).then(() => {
      enqueueSnackbar('Copied!', {variant: 'success'});
    });
  }, [submissions]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Form Name",
        accessor: "applicant_form_name",
        sortable: true,
        Cell: (props) => (
          <div>
            {
              props.original.applicant_form_name || '-'
            }
          </div>
        ),
      },
      {
        Header: "Website",
        accessor: "applicant_website",
        sortable: true,
        Cell: (props) => (
          <div>
            {
              props.original.applicant_website?.length ? props.original.applicant_website : '-'
            }
          </div>
        ),
      },
      {
        Header: "Response Code",
        accessor: "response_code",
        sortable: true,
        Cell: (props) => (
          <div>
            {props.original.response_code}
          </div>
        ),
      },
      {
        Header: "Council Name",
        accessor: "council_name",
        sortable: true,
        Cell: (props) => (
          <div>
            {props.original.council_name}
          </div>
        ),
      },
      {
        Header: "Created At",
        accessor: "created_at",
        sortable: true,
        Cell: (props) => (
          <div>
            {props.original.created_at ? format(new Date(props.original.created_at), "yyyy-MM-dd HH:mm") : '-'}
          </div>
        ),
      },
      {
        Header: "Request Payload",
        accessor: "request_payload",
        sortable: true,
        Cell: (props) => (
          <div>
            <Button
              btn={BtnType.FRAME_LESS}
              onClick={() => copyToClipBoard(props.original.id, props.original, 'request_payload')}
            >
              Copy to clipboard
            </Button>
          </div>
        ),
      },
      {
        Header: "Response",
        accessor: "response_body",
        sortable: true,
        Cell: (props) => (
          <div>
            <Button
              btn={BtnType.FRAME_LESS}
              onClick={() => copyToClipBoard(props.original.id, props.original, 'response_body')}
            >
              Copy to clipboard
            </Button>
          </div>
        ),
      },
      {
        Header: "Id",
        accessor: "id",
        sortable: true,
        Cell: (props) => (
          <div>
            {props.original.id}
          </div>
        ),
      },
    ],
    [submissions]
  )

  return (
    <div className="container px-5 mt-5">
      <ReactTable
        className={styles.table}
        data={submissions || []}
        showPagination={false}
        columns={columns}
        loading={!submissions}
      />
    </div>
  );
};
export default ApplicantSubmissions;

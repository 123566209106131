import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { addDiscussionMembers } from "common/actions/discussions.action";
import { Button, BtnType, Label } from "modules/primitives";
import UserMultiSelectSearch from "common/components/usersSearch";
import { ModalFooter, ModalBody } from "../../../../application/modal";
import { initModel, validation } from "./addDiscussionMember.constants";
import styles from "./addDiscussionMember.scss";
import { useModelPopup } from "../../../hooks";

const AddDiscussionMemberComponent = (props) => {
  const { defaultModel = {}, discussionParticipants, discussionId } = props;
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [model, setmodel] = useState(initModel);
  const [existingUsers, setExistingUsers] = useState([]);
  const formRef = useRef();
  useEffect(() => {
    if (Object.keys(defaultModel).length > 0) {
      setmodel({
        ...initModel,
        ...defaultModel,
      });
    }
  }, [defaultModel]);

  const handleCancelClick = (e) => {
    popup.hide();
  };

  useEffect(() => {
    setExistingUsers(
      discussionParticipants.map(({ user_id, user_full_name }) => ({
        name: user_full_name,
        id: user_id,
      }))
    );
  }, [discussionParticipants.length]);

  const handleSaveClick = (e) => {
    e.preventDefault();
    const data = {
      discussion_users: formRef.current.values.memberId.map((member) => ({
        user_id: member.id,
        discussion_id: discussionId,
      })),
    };
    dispatch(
      addDiscussionMembers({
        discussionId,
        data,
      })
    );
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
    popup.hide();
  };

  return (
    <>
      <form>
        <ModalBody>
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={model && model}
            validationSchema={validation && validation}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              resetForm();
            }}
          >
            {({ handleSubmit, setFieldValue, ...formikprops }) => (
              <div className={styles.editStageFormWrp}>
                <Label>Member</Label>
                <UserMultiSelectSearch
                  placeholder="Choose discussion member"
                  labelField="full_name"
                  valueField="id"
                  searchBy="full_name"
                  sharedUsers={existingUsers}
                  onChange={(val) => {
                    setFieldValue("memberId", val);
                  }}
                  formProps={formikprops}
                />
              </div>
            )}
          </Formik>
        </ModalBody>
        <ModalFooter>
          <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button btn={BtnType.REGULAR} onClick={handleSaveClick}>
            Add member
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default AddDiscussionMemberComponent;

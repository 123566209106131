import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Icon, xsSize } from "common/icon";
import styles from "../adminTags.module.scss";
import { MoreOptions, Loading } from "../../../primitives";

const AdminTagsTable = (props) => {
  const {
    data,
    arrayKey,
    secondExpandedChildren,
    defaultExpandedRows,
    extendRow,
    extendChildRow,
    handleMoreOptionClick,
    moreOptions,
    selectedVariant,
    loading,
  } = props;

  return (
    <>
      <div className={styles.tableHeader}>
        <div className={styles.nameTab}>
          <span>
            {selectedVariant === "topics" ? "Technology Tags" : "Industry Tags"}
          </span>
        </div>
        <div className={styles.childTab}>
          <span>Child Tags</span>
        </div>
        <div className={styles.optionTab}>
          <span>Options</span>
        </div>
      </div>
      {loading && (
        <div className={styles.loadingContainer}>
          <Loading customText="Updating tags database. Please wait . . ." />
        </div>
      )}
      {!loading &&
        data.map((tagElement) => {
          const shouldExtend = defaultExpandedRows?.find(
            (rowId) => rowId === tagElement.id
          );
          return (
            <div key={tagElement.id} className={styles.tableContainer}>
              <Accordion
                className={styles.accordion}
                expanded={Boolean(shouldExtend)}
              >
                <AccordionSummary
                  className={styles.accordionRow}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={styles.tableRow}>
                    <div
                      onClick={() => {
                        extendRow(tagElement.id);
                      }}
                      className={styles.rowName}
                    >
                      <div className={styles.rowNameBlock}>
                        {tagElement[arrayKey]?.length ? (
                          <Icon
                            icon="icn-right-chevron"
                            {...xsSize}
                            className={`${styles.iconColor} ${
                              shouldExtend && styles.rotateIcon
                            }`}
                          />
                        ) : (
                          <Icon
                            icon="icn-right-chevron"
                            {...xsSize}
                            className={`${styles.iconColor} ${styles.spacing}`}
                          />
                        )}
                        <span>{tagElement.name}</span>
                      </div>
                    </div>
                    <div className={styles.rowChildren}>
                      <span>{tagElement[arrayKey]?.length}</span>
                    </div>
                    <div className={styles.rowOptions}>
                      <MoreOptions
                        options={moreOptions}
                        className={styles.moreOptWrp}
                        onClick={(e) => {
                          handleMoreOptionClick(e, tagElement);
                        }}
                      />
                    </div>
                  </div>
                </AccordionSummary>
                {Boolean(tagElement[arrayKey]?.length) &&
                  tagElement[arrayKey].map((childEl) => {
                    const shouldExtend = secondExpandedChildren?.find(
                      (rowId) => rowId === childEl.id
                    );
                    return (
                      <Accordion
                        expanded={Boolean(shouldExtend)}
                        key={childEl.id}
                      >
                        <AccordionSummary
                          className={styles.accordionRow}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div className={styles.tableRow}>
                            <div
                              onClick={() => extendChildRow(childEl.id)}
                              className={styles.rowNameChild}
                            >
                              <div style={{ marginLeft: 20 }}>
                                {Boolean(childEl[arrayKey]?.length) && (
                                  <Icon
                                    icon="icn-right-chevron"
                                    {...xsSize}
                                    className={`${styles.iconColor} ${
                                      shouldExtend && styles.rotateIcon
                                    }`}
                                  />
                                )}
                                <span style={{ marginLeft: 15 }}>
                                  {childEl.name}
                                </span>
                              </div>
                            </div>
                            <div className={styles.rowChildren}>
                              <span>{childEl[arrayKey]?.length}</span>
                            </div>
                            <div className={styles.rowOptions}>
                              <MoreOptions
                                options={moreOptions}
                                className={styles.moreOptWrp}
                                onClick={(e) => {
                                  handleMoreOptionClick(e, childEl);
                                }}
                              />
                            </div>
                          </div>
                        </AccordionSummary>
                        {Boolean(childEl[arrayKey]?.length) &&
                          childEl[arrayKey].map((childElLast) => (
                            <Accordion expanded={false} key={childElLast.id}>
                              <AccordionSummary
                                className={styles.accordionRow}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <div className={styles.tableRow}>
                                  <div className={styles.thirdNameChild}>
                                    <div style={{ marginLeft: 50 }}>
                                      <span>{childElLast.name}</span>
                                    </div>
                                  </div>
                                  <div className={styles.rowChildren}>
                                    <span>{childElLast[arrayKey]?.length}</span>
                                  </div>
                                  <div className={styles.rowOptions}>
                                    <MoreOptions
                                      options={moreOptions}
                                      className={styles.moreOptWrp}
                                      onClick={(e) => {
                                        handleMoreOptionClick(e, childElLast);
                                      }}
                                    />
                                  </div>
                                </div>
                              </AccordionSummary>
                            </Accordion>
                          ))}
                      </Accordion>
                    );
                  })}
              </Accordion>
            </div>
          );
        })}
    </>
  );
};

export default AdminTagsTable;

import React from "react";
import classNames from "classnames";

import styles from "./esgCheckboxes.module.scss";
import { CheckBox, CheckType } from "../../../../primitives";
import { esgOptions } from "./esgConstant";

const ESGCheckboxes = ({ esgs = [] }) => (
  <div className={styles.esgWrapperFull}>
    {esgs.map((field) => (
      <div key={field} className={classNames(styles.esgItemWrapper)}>
        <CheckBox
          checkType={CheckType.BLUE}
          isChecked
          className={styles.checkbox}
          disabled
        />
        <div
          className={`${styles.esgImgWrapper} ${
            styles[`esgImgWrapper-${esgOptions.indexOf(field) + 1}`]
          }`}
        />
        <div className={styles.label}>{field}</div>
      </div>
    ))}
  </div>
);

export default React.memo(ESGCheckboxes);

import {
  POST_AGREEMENT_TYPE_ATTEMPT,
  POST_AGREEMENT_TYPE_SUCCESS,
  PUT_AGREEMENT_TYPE_ATTEMPT,
  PUT_AGREEMENT_TYPE_SUCCESS,
  DELETE_AGREEMENT_TYPE_ATTEMPT,
  DELETE_AGREEMENT_TYPE_SUCCESS,
  GET_AGREEMENT_TYPE_ATTEMPT,
  GET_AGREEMENT_TYPE_SUCCESS,
} from "./adminAgreements.action";

const initState = {
  shouldUpdateAgreementType: false,
  loadingAgreementType: false,
  agreementTypes: [],
};
const adminAgreementsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_AGREEMENT_TYPE_ATTEMPT: {
      return {
        ...state,
        loadingAgreementType: true,
      };
    }
    case GET_AGREEMENT_TYPE_SUCCESS: {
      return {
        ...state,
        loadingAgreementType: false,
        shouldUpdateAgreementType: false,
        agreementTypes: payload.response,
      };
    }
    case POST_AGREEMENT_TYPE_ATTEMPT: {
      return {
        ...state,
        loadingAgreementType: true,
      };
    }
    case POST_AGREEMENT_TYPE_SUCCESS: {
      return {
        ...state,
        loadingAgreementType: false,
        shouldUpdateAgreementType: true,
      };
    }
    case DELETE_AGREEMENT_TYPE_ATTEMPT: {
      return {
        ...state,
        loadingAgreementType: true,
      };
    }
    case DELETE_AGREEMENT_TYPE_SUCCESS: {
      return {
        ...state,
        loadingAgreementType: false,
        shouldUpdateAgreementType: true,
      };
    }
    case PUT_AGREEMENT_TYPE_ATTEMPT: {
      return {
        ...state,
        loadingAgreementType: true,
      };
    }
    case PUT_AGREEMENT_TYPE_SUCCESS: {
      return {
        ...state,
        loadingAgreementType: false,
        shouldUpdateAgreementType: true,
      };
    }

    default:
      return state;
  }
};

export default adminAgreementsReducer;

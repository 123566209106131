import {
  THEMES_GET_ATTEMPT,
  THEMES_GET_SUCCESS,
  THEMES_GET_TOPICS_ATTEMPT,
  THEMES_GET_TOPICS_SUCCESS,
  THEMES_GET_INDUSTRIES_SUCCESS,
  THEME_ADD_ATTEMPT,
  THEME_ADD_SUCCESS,
  THEMES_GET_INDIVIDUAL_ATTEMPT,
  THEMES_GET_INDIVIDUAL_SUCCESS,
  THEME_DELETE_ATTEMPT,
  THEME_DELETE_SUCCESS,
  THEME_EDIT_ATTEMPT,
  THEME_EDIT_SUCCESS,
  THEMES_COMPANIES_GET_SUCCESS,
  THEMES_COMPANIES_GET_ATTEMPT,
  PIPELINE_GET_INVEST_STAGES_ACROSS_ATTEMPT,
  PIPELINE_GET_INVEST_STAGES_ACROSS_SUCCESS,
  THEMES_SEARCH_SUCCESS,
  THEMES_SEARCH_ATTEMPT,
  THEMES_FAV_ATTEMPT,
  THEMES_FAV_SUCCESS,
  THEMES_FAV_DELETE_ATTEMPT,
  THEMES_FAV_DELETE_SUCCESS,
  PIPELINE_UPDATE_INVEST_COMP_LIST_FE_ACROSS_SUCCESS,
} from "./themesMain.action";

import { PIPELINE_EDIT_STAGE_SUCCESS } from "../themesIndividualPage/themesPipelineTab/pipelineTab.action";

import {
  THEME_COMPANY_ADD_SUCCESS,
  THEME_MEMBER_ADD_SUCCESS,
  THEME_MEMBER_EDIT_SUCCESS,
  THEME_COMPANY_DELETE_SUCCESS,
} from "../themesIndividualPage/themesOverviewTab/overviewTab.action";
import { POST_REPLY_ADD_SUCCESS } from "../themesIndividualPage/themesOverviewTab/postsBlock/feed/feed.action";

import {
  THEME_POST_EDIT_SUCCESS,
  THEME_POST_DELETE_SUCCESS,
  THEME_POST_ADD_SUCCESS,
} from "../themesIndividualPage/themesOverviewTab/postsBlock/postsBlock.action";

import { apiStatus, pageMeta } from "../../../common/httpCall";

const initState = {
  lastActivity: {},
  themes: {},
  themesData: [],
  themesLoading: false,
  themeMeta: {},
  topics: [],
  industries: [],
  currentThemeData: {},
  loadingThemes: false,
  shouldUpdateThemes: false,
  loadingIndividualTheme: false,
  shouldUpdateIndividualTheme: false,
  shouldRedirectToTheme: false,
  themesCompaniesAcross: [],
  loadingThemesCompanies: false,
  shouldUpdateThemesCompanies: false,
  shouldGetThemesCompanies: false,
  investCompaniesListAcross: [],
  favPeding: false,
  updateInvestStages: "",
  deletingTheme: false,
};

const themesMainReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case THEMES_GET_ATTEMPT:
      return {
        ...state,
        loadingThemes: true,
      };
    case THEMES_GET_SUCCESS:
      return {
        ...state,
        loadingThemes: false,
        shouldUpdateThemes: false,
        themesData: payload.response,
        shouldRedirectToTheme: false,
        themeMeta: pageMeta(payload),
      };
    case THEMES_GET_TOPICS_ATTEMPT: {
      return {
        ...state,
      };
    }
    case THEMES_GET_TOPICS_SUCCESS: {
      const { response, status } = payload;

      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          loading: false,
          topics: response.sort((a, b) =>
            a.name < b.name ? -1 : a.name > b.name ? 1 : 0
          ),
          addedCompetitor: {},
        };
      }
      return state;
    }
    case THEMES_GET_INDIVIDUAL_ATTEMPT:
      return {
        ...state,
        loadingIndividualTheme: true,
      };
    case THEMES_GET_INDIVIDUAL_SUCCESS:
      return {
        ...state,
        loadingIndividualTheme: false,
        shouldUpdateThemes: false,
        currentThemeData: payload.response,
        shouldRedirectToTheme: false,
      };
    case THEMES_GET_INDUSTRIES_SUCCESS: {
      const { response, status } = payload;
      localStorage.setItem(
        "council_role",
        payload.xhr.getResponseHeader("user-council-role")
      );
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          industries: response,
          addedCompetitor: {},
        };
      }

      return state;
    }

    case THEME_ADD_ATTEMPT: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          loadingThemes: true,
          shouldRedirectToTheme: false,
        };
      }
      return state;
    }
    case THEME_ADD_SUCCESS: {
      const { response, status } = payload;
      if ([apiStatus.CREATED, apiStatus.SUCCESS].includes(status) && response) {
        return {
          ...state,
          shouldRedirectToTheme: true,
          currentThemeData: response,
        };
      }
      return state;
    }

    case THEME_DELETE_ATTEMPT:
      return {
        ...state,
        deletingTheme: true,
      };

    case THEME_DELETE_SUCCESS:
      return {
        ...state,
        deletingTheme: false,
        shouldUpdateThemes: true,
        currentThemeData: {},
      };
    case THEME_EDIT_ATTEMPT:
      return {
        ...state,
        loadingIndividualTheme: true,
        shouldRedirectToTheme: false,
      };
    case THEME_EDIT_SUCCESS:
      return {
        ...state,
        currentThemeData: payload?.response,
        loadingIndividualTheme: false,
        shouldUpdateIndividualTheme: false,
        shouldRedirectToTheme: true,
      };
    case THEMES_COMPANIES_GET_ATTEMPT:
      return {
        ...state,
        loadingThemesCompanies: true,
        shouldUpdateThemesCompanies: false,
      };
    case THEMES_COMPANIES_GET_SUCCESS:
      return {
        ...state,
        themesCompaniesAcross: payload?.response,
        loadingThemesCompanies: false,
        shouldUpdateThemesCompanies: true,
        shouldGetThemesCompanies: false,
      };
    case PIPELINE_EDIT_STAGE_SUCCESS:
      return {
        ...state,
        shouldGetThemesCompanies: true,
      };

    case PIPELINE_GET_INVEST_STAGES_ACROSS_ATTEMPT:
      return {
        ...state,
        loadingThemesCompanies: true,
        shouldUpdateThemesCompanies: false,
      };

    case PIPELINE_GET_INVEST_STAGES_ACROSS_SUCCESS:
      return {
        ...state,
        investCompaniesListAcross: payload?.response,
        loadingThemesCompanies: false,
        shouldUpdateThemesCompanies: true,
        shouldGetThemesCompanies: false,
        updateInvestStages: "",
      };

    case PIPELINE_UPDATE_INVEST_COMP_LIST_FE_ACROSS_SUCCESS:
      const updateStages = () => {
        let updatedCompInStages = state?.investCompaniesListAcross?.map(
          (stage) => {
            if (
              stage.theme_companies &&
              stage?.theme_investment_stage_id ===
                payload.companySelected?.theme_investment_stage_id
            ) {
              stage.theme_companies.map((company, i) => {
                if (company.id === payload.companyId) {
                  stage.theme_companies.splice(i, 1);
                }
              });
              return stage;
            }

            ////inserting
            if (
              stage?.theme_investment_stage_id ===
              payload.data?.theme_investment_stage_id
            ) {
              stage.theme_companies.splice(
                payload.data.council_company_in_stage_position - 1,
                0,
                payload.companySelected
              );
            }
            ////end of inserting
            return stage;
          }
        );
        // return updatedCompInStages;
        return state.investCompaniesListAcross;
      };

      if (payload.status === 200) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        investCompaniesListAcross: updateStages() || [],
        // loadingStages: false,
        shouldUpdateThemesCompanies: true,
        updateInvestStages:
          payload.companySelected?.id + payload.data?.theme_investment_stage_id,
      };

    case THEMES_SEARCH_ATTEMPT:
      return {
        ...state,
        loadingThemes: true,
      };
    case THEMES_SEARCH_SUCCESS:
      return {
        ...state,
        loadingThemes: false,
        shouldUpdateThemes: false,
        themesData: payload.response.themes
          ? payload.response
          : { themes: payload.response },
        shouldRedirectToTheme: false,
        themeMeta: pageMeta(payload),
      };
    case THEMES_FAV_ATTEMPT:
      return {
        ...state,
        favPeding: true,
        shouldUpdateThemes: false,
      };
    case THEMES_FAV_DELETE_ATTEMPT:
      return {
        ...state,
        favPeding: true,
        shouldUpdateThemes: false,
      };
    case THEMES_FAV_SUCCESS:
      return {
        ...state,
        favPeding: false,

        themesData: {
          ...state.themesData,
          themes: state.themesData.themes.map((theme) => {
            if (payload.response.id === theme.id) {
              return payload.response;
            }
            return theme;
          }),
        },
      };

    case THEMES_FAV_DELETE_SUCCESS:
      return {
        ...state,
        favPeding: false,
        themesData: {
          ...state.themesData,
          themes: state.themesData.themes.map((theme) => {
            if (payload.response.id === theme.id) {
              return payload.response;
            }
            return theme;
          }),
        },
      };
    case THEME_COMPANY_ADD_SUCCESS:
      return {
        ...state,
        // currentThemeData: payload?.response,
        loadingIndividualTheme: false,
      };
    case THEME_COMPANY_DELETE_SUCCESS:
      return {
        ...state,
        currentThemeData: payload?.response,
        loadingIndividualTheme: false,
      };
    case THEME_MEMBER_ADD_SUCCESS:
      return {
        ...state,
        currentThemeData: payload?.response,
        loadingIndividualTheme: false,
      };
    case THEME_MEMBER_EDIT_SUCCESS:
      return {
        ...state,
        currentThemeData: payload?.response,
        loadingIndividualTheme: false,
      };
    case POST_REPLY_ADD_SUCCESS:
      return {
        ...state,
        currentThemeData: {
          ...state.currentThemeData,
          posts_and_replies_count: payload?.response[0].theme_posts_count,
        },
        loadingIndividualTheme: false,
      };
    case THEME_POST_EDIT_SUCCESS:
      return {
        ...state,
        currentThemeData: {
          ...state.currentThemeData,
          posts_and_replies_count: payload?.response[0].theme_posts_count,
        },
        loadingIndividualTheme: false,
      };
    case THEME_POST_DELETE_SUCCESS:
      return {
        ...state,
        currentThemeData: {
          ...state.currentThemeData,
          posts_and_replies_count: payload?.response[0].theme_posts_count,
        },
        loadingIndividualTheme: false,
      };
    case THEME_POST_ADD_SUCCESS:
      return {
        ...state,
        currentThemeData: {
          ...state.currentThemeData,
          posts_and_replies_count: payload?.response[0].theme_posts_count,
        },
        loadingIndividualTheme: false,
      };
    default:
      return state;
  }
  //   return state;
};

export default themesMainReducer;

import React, { useState } from "react";

import RecommendProject from "modules/tractionAI/screens/recommend/projects/recommendProject";
import AnimatedSection from "modules/tractionAI/components/animatedSection";

const RecommendProjects = ({ item, index }) => {
  const [currentSection, setCurrentSection] = useState(0);

  const handleAnimationComplete = () => {
    setCurrentSection((prev) => prev + 1);
  };

  return (
    <div className="d-flex flex-column ">
      {
        item.data?.map((project, idx) => (
          <AnimatedSection
            doNotAnimate={item.doNotAnimate || item.hasError}
            key={`${project.project_name}_${idx}_${index}`}
            onAnimationComplete={idx === currentSection ? handleAnimationComplete : null}
            isVisible={idx <= currentSection}
          >
            <RecommendProject
              parentIndex={index}
              index={idx}
              project={project}
            />
          </AnimatedSection>
        ))
      }
    </div>
  );
}

export default React.memo(RecommendProjects);

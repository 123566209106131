import React from "react";
import { debounce } from "throttle-debounce";

import styles from "./projectsSearch.module.scss";

const ProjectsSearch = ({ onChange, defValue }) => {
  const debounceFunc = debounce(1000, false, (value) => {
    onChange(value);
  });

  const handleChange = (e) => {
    debounceFunc(e.target.value);
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        defaultValue={defValue}
        type="text"
        className={styles.searchInput}
        placeholder="Search Projects"
        onChange={handleChange}
      />
    </div>
  );
};

export default React.memo(ProjectsSearch);

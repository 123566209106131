import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import EditCompanyComponent from "../admin/adminCompanies/editCompany";
import styles from "./styles.module.scss";
import TractionCompanies from "./companies/companies";
import { navElements } from "modules/tractionAdmin/tractionAdmin.contans";
// import AdminCompanyEdit from './companies/adminCompanyEdit/index';
import TractiomCouncils from "./councils/councils.component";
import CouncilPage from "./councils/councilPage";
import ApplicantSubmissions from "modules/tractionAdmin/applicantSubmissions";

const TractionAdmin = () => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.sidePanelBlock}>
        <div className={styles.sidePanel}>
          <div className={styles.buttonWrapper}>
            {navElements.map((el) => {
              if (el.disabled) {
                return (
                  <div
                    style={{
                      cursor: "no-drop",
                      fontSize: 14,
                    }}
                    className={`${styles.buttonLink} ${
                      history.location.pathname.split("/")[2] === el.val
                        ? styles.selectedButton
                        : ""
                    }`}
                  >
                    {el.text}
                  </div>
                );
              }
              return (
                <div
                  onClick={() => history.push(`/tractionadmin/${el.val}`)}
                  className={`${styles.buttonLink} ${
                    history.location.pathname.split("/")[2] === el.val
                      ? styles.selectedButton
                      : ""
                  }`}
                >
                  {el.text}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.contentBlock}>
        <div className={styles.navTitle}>
          <span>TRACTION ADMIN</span>
        </div>
        <Switch>
          <Route
            exact
            path="/tractionadmin/applicant-submissions"
            component={ApplicantSubmissions}
          />
          <Route
            exact
            path="/tractionadmin/councils"
            component={TractiomCouncils}
          />
          <Route
            exact
            path="/tractionadmin/councils/edit/:id"
            component={CouncilPage}
          />
          <Route
            exact
            path="/tractionadmin/companies"
            component={TractionCompanies}
          />
          <Route
            exact
            path="/tractionadmin/companies/edit/:id"
            component={EditCompanyComponent}
          />

          {/* <Route */}
          {/*  exact */}
          {/*  path="/tractionadmin/companies/edit/:id" */}
          {/*  component={AdminCompanyEdit} */}
          {/* /> */}
          <Route
            exact
            path="/tractionadmin/companies/edit/:id"
            component={EditCompanyComponent}
          />
          <Redirect from="/tractionadmin/" to="/tractionadmin/councils" />
        </Switch>
      </div>
    </div>
  );
};

export default TractionAdmin;

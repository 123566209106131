import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getCookie } from "common/helper";

import styles from "./loggedUser.module.scss";
import { Icon, lgSize } from "../../../common/icon";
import { OptionType, UserMenuOptions } from "./loggedUser.constant";
import { useOnClickOutside } from "../../../common/hooks";
import { UserInline, UserInlineTypes } from "../../../modules/primitives";
import { logOut } from "modules/auth/auth.action";

const LoggedUser = ({ className }) => {
  const ref = useRef();
  const [isModalOpen, setModalOpen] = useState(false);
  useOnClickOutside(ref, () => setModalOpen(false));
  const {
    authReducer: { session },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const [firstStepPassed, setFirstPassed] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const councilRole = getCookie("council_role_");
  const user_id = getCookie("user_id_");
  const [userMenu, setUserMenu] = useState(UserMenuOptions);
  useEffect(() => {
    if (councilRole === "company") {
      const filteredMenu = UserMenuOptions.filter(
        (option) => option.val !== "tractionadmin" && option.val !== "view"
      );
      setUserMenu(filteredMenu);
      return;
    }

    if (councilRole !== "ttp_administrator") {
      // UserMenuOptions.push({ val: 'tractionadmin', text: 'Traction Admin' })
      const filteredMenu = UserMenuOptions.filter(
        (option) => option.val !== "tractionadmin"
      );

      setUserMenu(filteredMenu);
    } else if (councilRole === "ttp_administrator") {
      setUserMenu(UserMenuOptions);
    }
  }, [councilRole]);

  useEffect(() => {
    if (session?.isSSOLogin) {
      setFirstPassed(true);
      return;
    }

    const asyncVar = localStorage.getItem("is_firt_step_passsed");
    setFirstPassed(asyncVar);
  }, [session]);

  const handleOptionClick = (e, val) => {
    if (session && !session.password_updated_at && !session?.isSSOLogin) {
      if (val !== "logout" && val !== "policy") {
        if (!firstStepPassed) {
          history.push("/setup/profile");
        } else {
          history.push("/setup/password");
        }
        return;
      }
    }
    switch (val) {
      case "logout":
        dispatch(logOut({ enqueueSnackbar }));
        document.cookie =
          "expire= ; expires= Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
        document.cookie =
          "access_token= ; expires= Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
        document.cookie =
          "uid= ; expires= Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
        document.cookie =
          "client= ; expires= Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
        window.location.assign(`${window.location.origin}/login`);
        localStorage.clear();
        break;
      case "view":
        history.push(`/people/internal/profile/${user_id}`);
        break;
      case "settings":
        history.push("/settings");
        break;
      case "policy":
        history.push("/privacy_policy");
        break;
      case "terms":
        history.push("/terms");
        break;
      case "tractionadmin":
        history.push("/tractionadmin");
        break;
      default:
        break;
    }
    setModalOpen(false);
  };

  const handleMoreClick = (e) => {
    setModalOpen(true);
  };
  return (
    <div className={styles.moreWrp}>
      {isModalOpen && (
        <div ref={ref} className={classNames(styles.optionWrp, className)}>
          <div className={styles.userWrp}>
            {session && (
              <UserInline
                className={styles.userIcon}
                userId={session?.id}
                type={UserInlineTypes.WITH_NAME}
                showMine={false}
                first={session?.first_name}
                last={session?.last_name}
                avatar={session?.avatar}
                local
              />
            )}
          </div>
          {userMenu.map(
            ({ val, className, text, type = OptionType.REGULAR }) => (
              <div
                key={val}
                className={classNames(
                  styles.opt,
                  type === OptionType.DANGER && styles.danger,
                  className && className
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOptionClick(e, val);
                }}
              >
                {text}
              </div>
            )
          )}
        </div>
      )}
      {session && session?.avatar ? (
        <img
          src={session.avatar}
          alt={`${session.first_name} ${session.last_name}`}
          className={styles.userImg}
          onClick={(e) => {
            e.stopPropagation();
            handleMoreClick();
          }}
        />
      ) : (
        <Icon
          {...lgSize}
          icon="icn-profile"
          className={styles.icon}
          onClick={(e) => {
            e.stopPropagation();
            handleMoreClick();
          }}
        />
      )}
    </div>
  );
};

export default React.memo(LoggedUser);

import React, { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import classNames from "classnames";

import { useDispatch, useSelector } from "react-redux";
import { useModelPopup } from "../../../../../common/hooks";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import AddRelatedSolutions from "./addRelatedSolutions";
import styles from "../themes/styles.module.scss";
import { Pannel } from "../../../../primitives";
import {
  getRelatedSolutionsAttempt,
  deleteRelatedSolutionAttempt,
} from "../overview.action";

const ProjectsSolutions = ({ expandAll, sessionRole }) => {
  const {
    projectsReducer: {
      projectReducer: { selectedProject },
      overviewReducer: { projectSolutions },
    },
  } = useSelector((state) => state);
  const popup = useModelPopup();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (expandAll) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [expandAll]);
  const handleAddSolution = () => {
    popup.show({
      title: "Add Solutions",
      show: true,
      height: "300",
      width: "540",
      component: (
        <AddRelatedSolutions
          selectedProject={selectedProject}
          patientOrg={false}
        />
      ),
    });
  };

  const handleSolutionRedirect = (sol_id) => {
    history.push("/solutions", { solutionId: sol_id });
  };

  const handleRemoveSolution = (sol_id) => {
    dispatch(
      deleteRelatedSolutionAttempt({
        solutionId: sol_id,
        projectId: selectedProject.id,
        enqueueSnackbar,
      })
    );
  };
  useEffect(() => {
    if (selectedProject?.id) {
      dispatch(getRelatedSolutionsAttempt({ projectId: selectedProject.id }));
    }
  }, [selectedProject]);

  const rightContent = useCallback(() => {
    return (
      <>
        <div
          className="d-flex justify-content-center cursor-pointer "
          onClick={() => {
            setExpanded((prev) => !prev);
          }}
        >
          <Icon
            {...smSize}
            icon="icn-table-down-chevron"
            className={classNames(
              styles.icon,
              expanded ? styles.collapse : styles.collapseUp
            )}
          />
        </div>
      </>
    );
  }, [expanded]);

  return (
    <Pannel
      title={"Related Solutions"}
      padding="0"
      rightContent={rightContent()}
      withCollapse
      expanded={expanded}
    >
      <div className={styles.panelHeader}>
        Solutions
        {sessionRole !== "viewer" && (
          <Icon
            {...mdSize}
            icon="icn-add"
            className={styles.addNew}
            onClick={handleAddSolution}
          />
        )}
      </div>
      <div className={styles.wrapper}>
        {!projectSolutions?.length ? (
          <span className="p-3 text-center">No related solutions yet.</span>
        ) : (
          projectSolutions?.map((sol) => (
            <div
              onClick={() => handleSolutionRedirect(sol.id)}
              key={sol.id}
              className={styles.title}
            >
              <span> {sol?.attributes?.name} </span>
              {sessionRole !== "viewer" && (
                <Icon
                  {...smSize}
                  icon="icn-button-delete"
                  className={styles.deleteIcon}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleRemoveSolution(sol.id);
                  }}
                />
              )}
            </div>
          ))
        )}
      </div>
    </Pannel>
  );
};

export default React.memo(ProjectsSolutions);

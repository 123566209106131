import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { Icon, xsSize } from "../../../../common/icon";

const HeaderWithSubCat = ({ category }) => {
  const [haveSubCat, setHaveSubCat] = useState(false);

  useEffect(() => {
    if (category.sub_categories.length) {
      setHaveSubCat(true);
    }
  }, [category]);

  return (
    <div
      style={{
        padding: 5,
        backgroundColor: haveSubCat ? "#dcdde1" : "",
      }}
    >
      <span style={{ marginRight: 5 }}>{category?.name}</span>
      {haveSubCat && <Icon icon="icn-right-chevron" {...xsSize} />}
    </div>
  );
};

const HeaderSubCat = (props) => (
  <div style={{ backgroundColor: "#ced6e0" }}>
    <span>{props.category.name}</span>
  </div>
);

const CompanyRatings = (props) => {
  const [columns, setColumns] = useState([]);
  const [initialColumns, setInitialColumns] = useState([]);
  const { ratingTemplate } = props;

  useEffect(() => {
    if (ratingTemplate?.rating_categories?.length) {
      const firstItems = [
        {
          Header: "Users",
          style: {
            overflow: "visible",
            display: "flex",
            justifyContent: "flex-start",
          },
        },
        {
          Header: "Overall Company Score",
          style: {
            overflow: "visible",
            display: "flex",
            justifyContent: "flex-start",
          },
        },
      ];

      let ind = 0;
      const newColumns = ratingTemplate.rating_categories
        .map((category, index) => {
          if (!category.parent_id) {
            ind += 1;
            return {
              Header: () => <HeaderWithSubCat category={category} />,
              style: {
                overflow: "visible",
                display: "flex",
                justifyContent: "flex-start",
              },
              category,
              index: ind + 1,
            };
          }
        })
        .filter((cat) => cat !== undefined);

      setColumns([
        ...firstItems.concat(newColumns),
        {
          Header: "Notes",
          style: {
            overflow: "visible",
            display: "flex",
            justifyContent: "flex-start",
          },
        },
      ]);
      setInitialColumns([
        ...firstItems.concat(newColumns),
        {
          Header: "Notes",
          style: {
            overflow: "visible",
            display: "flex",
            justifyContent: "flex-start",
          },
        },
      ]);
    }
  }, [ratingTemplate]);

  const openSubChildren = (column) => {
    const subCols = column.category.sub_categories.map((subCat) => ({
      Header: () => <HeaderSubCat category={subCat} />,
    }));
    const newColumns = [...columns];
    const t = newColumns.splice(column.index + 1, 0, ...subCols);
    setColumns(newColumns);
  };

  return (
    <div>
      <ReactTable
        columns={columns}
        data={[]}
        showPagination={false}
        minRows={10}
        getTheadThProps={(state, rowInfo, column, instance) => ({
          onClick: (e, handleOriginal) => {
            if (column?.category?.sub_categories.length) {
              if (columns.length === initialColumns.length) {
                openSubChildren(column);
              } else {
                setColumns(initialColumns);
              }
            }
          },
        })}
      />
    </div>
  );
};

export default CompanyRatings;

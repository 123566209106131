import React from "react";
import { format, isEqual } from "date-fns";

const useEventsDates = (props) => {
  const { event } = props;
  const getTimeRange = (type) => {
    const startDate = event && new Date(event.starts_on);
    const endDate = event && new Date(event.ends_on);
    if (event && event.starts_on && event.ends_on) {
      if (startDate && endDate && isEqual(startDate, endDate)) {
        return (
          <>
            {format(
              new Date(event.starts_on),
              type === "date" ? "MMM dd, yyyy" : "p",
            )}
            {type !== "date" && (
              <>
                {" - "}
                {format(
                  new Date(event.ends_on),
                  type === "date" ? "MMM dd" : "p",
                )}
              </>
            )}
          </>
        );
      }
      return (
        <>
          {format(new Date(event.starts_on), type === "date" ? "MMM dd" : "p")}
          {" - "}
          {format(
            new Date(event.ends_on),
            type === "date" ? "MMM dd, yyyy" : "p",
          )}
        </>
      );
    }
  };

  return { getTimeRange };
};

export default useEventsDates;

import React from "react";
import { useSelector } from "react-redux";
import GridItem from "./ideaGridItem";
import LoadingComponent from "../../../../primitives/loading/loading.component";

const IdeaGridViewLayout = () => {
  const {
    ideaReducer: { ideas, getIdeasListLoading },
  } = useSelector((state) => state);

  return (
    <>
      {getIdeasListLoading && <LoadingComponent />}

      {!getIdeasListLoading && ideas.length <= 0 && (
        <h5 className="d-flex align-items-center justify-content-center">
          No results.
        </h5>
      )}
      {!getIdeasListLoading && ideas.length > 0 && (
        <div className="row">
          {ideas.map((idea) => (
            <div className="col-sm-12 col-md-4" key={idea.id}>
              <GridItem idea={idea} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default React.memo(IdeaGridViewLayout);

import React, { useEffect, useState } from "react";

import classnames from "classnames";
import styles from "./listReportBlock.module.scss";
import { httpGet } from "../../../../../common/httpCall";

const IdeaReportBlock = ({ onItemClick, total, selectedFilter }) => {
  const [statsData, setStatsData] = useState({});

  const [activeStatus, setActiveStatus] = useState(null);
  useEffect(() => {
    httpGet({
      call: `projects/ideas_statuses`,
    }).subscribe((res) => {
      setStatsData(res.response || []);
    });
  }, []);

  const handleItemCLick = (filter) => {
    if (onItemClick) {
      onItemClick(filter);
      setActiveStatus(filter);
    }
  };

  useEffect(() => {
    if (selectedFilter?.length === 1) {
      setActiveStatus(selectedFilter[0]);
    } else {
      setActiveStatus(null);
    }
  }, [selectedFilter]);

  const getTotals = (status) => {
    if (status === "submitted") {
      return statsData?.total_submitted_ideas;
    }
    if (status === "paused") {
      return statsData?.total_paused_ideas;
    }
    if (status === "rejected") {
      return statsData?.total_rejected_ideas;
    }
    return 0;
  };

  return (
    <div className={classnames(styles.container, "row")}>
      <div className="col-12 col-md-3 d-flex justify-content-center p-0">
        <div className={styles.item}>
          <div className={styles.itemLabel} />
          {statsData?.total_ideas ? (
            <div className={classnames(styles.itemValue, "border-0", "h3 m-0")}>
              <span>
                {activeStatus
                  ? getTotals(activeStatus)
                  : statsData?.total_ideas || 0}
              </span>
              <span className="font-weight-normal">&nbsp;Ideas</span>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12 col-md-9 d-flex justify-content-center flex-column flex-md-row justify-content-md-start p-0">
        <div
          className={`${styles.item} ${
            activeStatus
              ? activeStatus === "submitted"
                ? ""
                : styles.unselected
              : ""
          }`}
          onClick={() => handleItemCLick("submitted")}
          role="button"
          aria-hidden="true"
          title="SUBMITTED"
        >
          <div className={styles.itemLabel}>SUBMITTED</div>
          <div className={styles.itemValue}>
            {statsData?.total_submitted_ideas}
          </div>
        </div>

        <div
          className={`${styles.item} ${
            activeStatus
              ? activeStatus === "paused"
                ? ""
                : styles.unselected
              : ""
          } `}
          onClick={() => handleItemCLick("paused")}
          role="button"
          aria-hidden="true"
          title={"PAUSED"}
        >
          <div className={styles.itemLabel}>{"PAUSED"} </div>
          <div className={`${styles.itemValue} ${styles.paused}`}>
            {statsData?.total_paused_ideas}
          </div>
        </div>

        <div
          className={`${styles.item} ${
            activeStatus
              ? activeStatus === "rejected"
                ? ""
                : styles.unselected
              : ""
          }`}
          onClick={() => handleItemCLick("rejected")}
          role="button"
          aria-hidden="true"
          title="REJECTED"
        >
          <div className={styles.itemLabel}>REJECTED</div>
          <div className={`${styles.itemValue} ${styles.rejected}`}>
            {statsData?.total_rejected_ideas}
          </div>
        </div>
        <div
          className={`${styles.item} ${styles.approved}`}
          // onClick={() => handleItemCLick("approved")}
          role="button"
          aria-hidden="true"
          title="APPROVED IDEAS"
        >
          <div className={styles.itemLabel}>APPROVED</div>
          <div className={`${styles.itemValue} ${styles.approved}`}>
            {statsData?.total_approved_ideas}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(IdeaReportBlock);

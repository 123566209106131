import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';

import styles from './event.module.scss';
import {
  Button,
  BtnType,
  StarRating,
  MultiUsers,
} from '../../../primitives';
import {
  eventsAddRating,
  eventsPutAttending,
} from "modules/events/viewEvent/viewEvent.action";

const EventComponent = ({ event }) => {
  const {
    authReducer: { session },
    eventsReducer: {
      viewEventReducer: { attendings },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [pageMeta, setPageMeta] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const changeRating = (starsNr) => {
    dispatch(
      eventsAddRating({
        eventId: event.id,
        eventAttendeeId: session.id,
        rating: starsNr,
        enqueueSnackbar,
      }),
    );
  };

  const handleDeleteAttendClick = (e, val) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      eventsPutAttending({
        event_attendee_id: session.id,
        notes: 'string',
        attending: false,
        // rating: 0,
        eventId: event.id,
        enqueueSnackbar,
      }),
    );
  };

  const handleAttendClick = (e, val) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      eventsPutAttending({
        event_attendee_id: session.id,
        notes: 'string',
        attending: true,
        // rating: 0,
        eventId: event.id,
        enqueueSnackbar,
      }),
    );
  };

  const formattedDate = (date) => format(new Date(date), 'MMM do');

  const formattedTime = (date) => format(new Date(date), 'p');

  useEffect(() => {
    if (attendings[event.id] && attendings[event.id].list.length > 0) {
      setPageMeta(attendings[event.id].pageMeta);
    }
  }, [event && event.id, attendings]);

  const isCurrentUserAttending = useMemo(() => {
    return event?.event_attendees?.filter(
      (user) => user.user_id === session?.id,
    )[0];
  }, [event, session?.id]);

  return (
    <div className={styles.rowWrp}>
      <h4 className={classNames('medium', styles.lineOne)}>
        {' '}
        {event.name}
      </h4>
      <div className={styles.lineTwo}>
        <div className={classNames('caption', styles.message)}>
          {event.location}
        </div>
        <div
          className={styles.rating}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <StarRating rate={event.rating_stars} onChange={changeRating} />
        </div>
      </div>
      <div className={classNames('caption small', styles.lineThree)}>
        <div className={styles.date}>
          {`${formattedDate(event.starts_on)} - ${formattedDate(
            event.ends_on,
          )}`}
          {' '}
          {`${formattedTime(event.starts_on)} - ${formattedTime(
            event.ends_on,
          )}`}
        </div>
        <div className={styles.time} />
      </div>
      <div className={styles.lineFour}>
        <div className={styles.usersWrp}>
          <MultiUsers
            users={event.event_attendees.map((user) => ({
              avatar: user.user_avatar,
              first_name: '',
              last_name: '',
              user_full_name: user.user_full_name,
              id: user?.user_id,
            }))}
            total={pageMeta.total}
          />
        </div>
        {isCurrentUserAttending ? (
          <Button
            btn={BtnType.REGULAR}
            className={styles.attending}
            icon="icn-checkbox-button"
            onClick={(event) => handleDeleteAttendClick(event)}
          >
            Attending
          </Button>
          ) : (
            <Button
              btn={BtnType.TAG_OUTLINE_BLUE}
              onClick={(event) => handleAttendClick(event)}
              className={styles.attend}
            >
              Attend
            </Button>
          )}
      </div>
    </div>
  );
};

export default React.memo(EventComponent);

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import styles from "./modal.module.scss";
import { useWindowSize } from "common/hooks/useWindowSize";

const ModalBodyComponent = (props) => {
  const { children, noFixedHeight = false, className } = props;

  const {
    appReducer: { makeModalRerender },
  } = useSelector((state) => state);

  const [height, setHeight] = useState();
  const [overflow, setOverflow] = useState("inherit");
  const { isMobile } = useWindowSize();

  useEffect(() => {
    const parentHeight =
      document.getElementById("modalWrapper")?.parentElement?.offsetHeight ||
      72;
    const { childNodes } = document.getElementById("modalBody");
    let modalBodyChildHeight = 0;
    childNodes.forEach((elem) => (modalBodyChildHeight += elem.offsetHeight));

    if (isMobile) {
      const titleHeight =
        document.querySelector("#modalWrapper > h4")?.offsetHeight || 72;
      const totalHeight = window.innerHeight - titleHeight - 8 - 87;

      setHeight(totalHeight);
      setOverflow("auto");

      return;
    }

    if (
      window.innerHeight - parentHeight < 200 ||
      window.innerHeight - parentHeight < modalBodyChildHeight
    ) {
      // 72 px is the height of the title
      // 87 px is the height of the footer
      const totalHeight = parentHeight - 72 - 87;

      // 70 because that's the padding of the body
      if (totalHeight - 70 > modalBodyChildHeight) {
        setHeight(modalBodyChildHeight + 70);
        setOverflow("inherit");
      } else {
        setHeight(totalHeight);
        setOverflow("auto");
      }
    }

    return () => null;
  }, [children, makeModalRerender, isMobile]);

  return (
    <div
      style={{
        height: height && !noFixedHeight ? `${height}px` : "auto",
        overflowY: overflow,
      }}
      className={classNames(styles.modalBody, className)}
      id="modalBody"
    >
      {children}
    </div>
  );
};

export default React.memo(ModalBodyComponent);

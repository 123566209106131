import { combineEpics, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";

import { errorHandler, httpPost } from "../../../../../common/httpCall";
import {
  GET_PLAN_SCORE_DATA,
  GET_PLAN_SCORE_TABLE_DATA,
  GET_PLAN_POC_TABLE_DATA,
  getPlanScoreDataSuccess,
  getPlanScoreTableDataSuccess,
  getPlanPocTableDataSuccess,
} from "modules/reporting/fordReports/planScorecard/store/planScoreCard.actions";

export const epicGetCustomSectionData = (action$) => action$.pipe(
  ofType(GET_PLAN_SCORE_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: 'ford/plan_scoreboard/dashboard',
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getPlanScoreDataSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetPocTableData = (action$) => action$.pipe(
  ofType(GET_PLAN_POC_TABLE_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    call: 'ford/actual_scoreboard/input_metrics/theme_poc',
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getPlanPocTableDataSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetCustomSectionTableData = (action$) => action$.pipe(
  ofType(GET_PLAN_SCORE_TABLE_DATA),
  switchMap(({ payload: { data, enqueueSnackbar, reducerKey, page = 1, highLightColumn } }) => httpPost({
    call: `ford/plan_scoreboard/table?page=${page}&items=20`,
    customServerPath: true,
    data,
  }).pipe(
    map((result) => getPlanScoreTableDataSuccess({
      ...result,
      reducerKey,
      highLightColumn,
    })),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const fordPlanScoreEpic = combineEpics(
  epicGetCustomSectionData,
  epicGetPocTableData,
  epicGetCustomSectionTableData,
);

export default fordPlanScoreEpic;

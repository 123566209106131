import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import Tooltip from "@mui/material/Tooltip";
import classNames from 'classnames';

import styles from "./stageProjectWide.module.scss";
import { CheckBox, CheckType } from "../../../../primitives";
import {
  appSelectedSet,
  appSliderSettingSet,
} from "application/app.action";
import { sliderSetting } from "application/app.constants";
import {
  TaskInfoComponentV2,
  TaskInfoHeaderComponentV2,
} from "../../tasks/taskInfo";
import { getTaskAdditionalData, projectTaskUpdate } from "../../tasks/tasks.action";
import { projectWideTaskSetComplete } from "../stages.action";
import { checkViewerRole } from "../../../../../common/checkers/viewerChecker";
import ApprovalTaskHeader from "modules/projects/project/tasks/approvalTask/approvalTaskHeader.component";
import ApprovalTaskBody from "modules/projects/project/tasks/approvalTask/approvalTaskBody.component";
import LoaderWithDots from "../../../../../common/components/loaderWithDots";

const StageProjectWide = ({ task, stage }) => {
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(false);
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  const isGateWayTask = useMemo(() => task.task_type === 'approval_gate', [task.task_type]);

  const getTaskLabel = useMemo(() => {
    if (!isGateWayTask) {
      return 'Project Task'
    }

    if (task?.approval_type === 'review') {
      return 'Review Task';
    }

    return 'Approval Task';
  },[task?.approval_type, isGateWayTask]);

  const handleOnChange = (value) => {
    if (task.locked) {
      return;
    }

    dispatch(
      projectTaskUpdate({
        taskId: task.id,
        projectId: task.project_id,
        flag: value ? "completed_true" : "completed_false",
        project_task: {
          task_assignments: {
            completed_on: value ? format(new Date(), "yyyy-MM-dd") : null,
          },
        },
        queryParams: {
          task_assignment_id: task.projects_task_assignments[0]?.id,
        },
      }),
    );
    dispatch(
      projectWideTaskSetComplete({
        taskId: task?.id,
        assignmentId: task.projects_task_assignments[0]?.id,
        value,
      }),
    );
  };

  const showTaskDetails = (item) => {
    if (isGateWayTask) {
      dispatch(
        appSliderSettingSet({
          ...sliderSetting,
          show: true,
          height: "300",
          width: "540",
          header: (
            <ApprovalTaskHeader task={item} isProjectWideFromStage />
          ),
          component: (
            <ApprovalTaskBody
              taskId={item.id}
              blockFunctionality={blockFunctionality}
              isProjectWideFromStage
            />
          ),
        })
      );
      return
    }

    const assign = item?.projects_task_assignments[0];

    if (!assign) {
      return;
    }

    dispatch(appSelectedSet(assign?.id));
    dispatch(
      appSliderSettingSet({
        ...sliderSetting,
        show: true,
        height: "300",
        width: "540",
        header: (
          <TaskInfoHeaderComponentV2
            taskId={item?.id}
            projectId={item?.project_id}
            assignmentId={assign?.id}
            isProjectWideFromStage
            task={item}
            completionEnabled={item.completion_enabled}
          />
        ),
        component: (
          <TaskInfoComponentV2
            task={item}
            assignment={assign}
            stage={stage}
            isProjectWideFromStage
          />
        ),
      }),
    );
  }

  const showTask = () => {
    if (task.locked || sliderSetting.show) {
      return;
    }

    if (task.hasAdditionalData) {
      showTaskDetails(task);
    } else {
      setShowLoading(true);
      dispatch(getTaskAdditionalData({
        projectId: task.project_id,
        taskId: task.id,
        activeTab: 'all_task',
        isProjectWideFromStage: true,
        cb: (newTaskItem) => {
          setShowLoading(false);
          showTaskDetails(newTaskItem);
        }
      }));
    }
  };

  const makeCheckBoxProps = useCallback(() => {
    const howManyAreAssigned = task.projects_task_assignments?.filter(
      (a) => a.completed
    ).length;

    if (howManyAreAssigned === 0) {
      return { isChecked: false };
    }

    const everythingItIsAssigned =
      howManyAreAssigned === task.projects_task_assignments?.length;

    return {
      isChecked: true,
      checkType: everythingItIsAssigned ? CheckType.GREEN : CheckType.HALF_BLUE,
    };
  }, [task?.projects_task_assignments]);

  return (
    <div className={classNames(styles.stageProjectWrp, task.locked ? styles.lockedTask : '')}>
      {showLoading && <LoaderWithDots hideText />}
      <div
        className={`d-flex align-items-center justify-content-between ${styles.infoWrp}`}
      >
        {!blockFunctionality ? (
          <Tooltip
            title={!task.completion_enabled
              ? task.mark_on_document_upload ? 'Upload the required document to complete this Task' : 'Fill out the required linked fields to complete this Task'
              : ''}
          >
            <div>
              <CheckBox
                {...makeCheckBoxProps()}
                disabled={task.locked || !task.completion_enabled}
                onChange={handleOnChange}
                readOnly={!task.completion_enabled}
              />
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            title="You have View-Only access. To edit this Project, please ask your Admin to upgrade your account to Standard access and invite you to this Project."
            placement="top"
          >
            <CheckBox
              isChecked={
                task.projects_task_assignments.length > 0
                && task.projects_task_assignments[0].completed
              }
              checkType={
                task.projects_task_assignments.length > 0
                && task.projects_task_assignments[0].completed
                  ? CheckType.GREEN
                  : CheckType.BLUE
              }
              disabled
            />
          </Tooltip>
        )}
        <span className={styles.cursorPointer} onClick={showTask}>
          {task.name}
        </span>
        <span className={styles.arrowGo} onClick={showTask}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
          </svg>
        </span>
      </div>
      <div
        className={`d-flex align-items-center justify-content-between font-weight-bold ${styles.cursorPointer}`}
        onClick={showTask}
      >
        {getTaskLabel}
      </div>
    </div>
  );
};

export default React.memo(StageProjectWide);

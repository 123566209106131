export const makeNotificationsResponse = (result) => ({
  ...result,
  response: result.response.map((n) => ({
    ...n,
    belongsToGroupDiscussions: !!n.parent_id,
    ...n.resource,
    name:
      !!n.parent_id && n.name !== "All"
        ? n.resource.name.replace(" ", "_")
        : n.name,
  })),
});

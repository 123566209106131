export const GET_FORD_DASHBOARD_STAGES = 'GET_FORD_DASHBOARD_STAGES'
export const getFordDashboardStages = (payload) => ({
  type: GET_FORD_DASHBOARD_STAGES,
  payload,
});

export const GET_FORD_DASHBOARD_STAGES_SUCCESS = 'GET_FORD_DASHBOARD_STAGES_SUCCESS'
export const getFordDashboardStagesSuccess = (payload) => ({
  type: GET_FORD_DASHBOARD_STAGES_SUCCESS,
  payload,
});

export const GET_REGIONS = 'GET_REGIONS'
export const getRegions = (payload) => ({
  type: GET_REGIONS,
  payload,
});

export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS'
export const getRegionsSuccess = (payload) => ({
  type: GET_REGIONS_SUCCESS,
  payload,
});

export const GET_PROJECT_FIELDS = 'GET_PROJECT_FIELDS'
export const getProjectFields = (payload) => ({
  type: GET_PROJECT_FIELDS,
  payload,
});

export const GET_PROJECT_FIELDS_SUCCESS = 'GET_PROJECT_FIELDS_SUCCESS'
export const getProjectFieldsSuccess = (payload) => ({
  type: GET_PROJECT_FIELDS_SUCCESS,
  payload,
});

import {
  EVENTS_GET_SUCCESS,
  EVENTS_GET_SPEAKERS_SUCCESS,
  EVENTS_GET_ATTENDING_ATTEMPT,
  EVENTS_GET_ATTENDING_SUCCESS,
  EVENTS_REMOVE_ATTENDING_SUCCESS,
  EVENTS_GET_ALL_ATTENDING_SUCCESS,
  EVENTS_POST_ATTENDING_SUCCESS,
  EVENTS_DELETE_ATTENDING_SUCCESS,
  EVENTS_CREATE_NEW_EVENT_ATTEMPT,
  EVENTS_CREATE_NEW_EVENT_SUCCESS,
  EVENTS_GET_CURRENT_LIST_SUCCESS,
  EVENTS_GET_PAST_LIST_SUCCESS,
  EVENTS_EDIT_EVENT_ATTEMPT,
  EVENTS_EDIT_EVENT_SUCCESS,
  EVENTS_DELETE_EVENT_SUCCESS,
  EVENTS_POST_ATTENDING_ATTEMPT,
  EVENTS_ADD_SPEAKER_SUCCESS,
  EVENTS_REMOVE_SPEAKER_SUCCESS,
  EVENTS_DELETE_EVENT_ATTEMPT,
  EVENT_SHARE_ATTEMPT,
  EVENT_SHARE_SUCCESS,
  EVENTS_ADD_RATING_SUCCESS,
  EVENTS_ADD_RATING_ATTEMPT,
  CLEAR_SELECTED_EVENT,
  SET_CURRENT_EVENT_PAGE,
} from "./viewEvent.action";
import { apiStatus, pageMeta } from "../../../common/httpCall";

const initState = {
  speakers: {},
  attendings: {},
  eventsShouldUpdate: false,
  eventsList: [],
  eventsPageMeta: {},
  selectedEvent: {},
  speakersShouldUpdate: false,
  currentEventPage: 1,
  shouldRedirecToEvents: false,
};
const viewEventReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case EVENTS_GET_CURRENT_LIST_SUCCESS:
    case EVENTS_GET_PAST_LIST_SUCCESS: {
      const pagemeta = pageMeta(payload);
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          eventsShouldUpdate: false,
          shouldRedirecToEvents: false,
          eventsList: { ...state.eventsList, [pagemeta.page]: response },
          eventsPageMeta: pagemeta,
        };
      }
    }
    case EVENTS_CREATE_NEW_EVENT_ATTEMPT:
      return {
        ...state,
        eventsShouldUpdate: false,
      };
    case EVENTS_CREATE_NEW_EVENT_SUCCESS: {
      const { status, response } = payload;
      if (status === apiStatus.SUCCESS || status === 201) {
        return {
          ...state,
          eventsList: response,
          eventsShouldUpdate: true,
        };
      }
    }

    case EVENTS_EDIT_EVENT_ATTEMPT:
      return {
        ...state,
      };

    case EVENTS_EDIT_EVENT_SUCCESS: {
      const { status, response } = payload;
      if (status === apiStatus.SUCCESS || status === 201) {
        return {
          ...state,
          eventsList: response,
        };
      }
    }

    case EVENTS_GET_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          selectedEvent: response,
          eventsShouldUpdate: false,
        };
      }
    }
    case EVENTS_GET_SPEAKERS_SUCCESS: {
      const { eventId, data } = payload;
      const pagemeta = pageMeta(data);
      const { response, status } = data;
      if (status === apiStatus.SUCCESS) {
        const list = state.speakers[eventId] && state.speakers[eventId].list
          ? state.speakers[eventId].list
          : [];
        return {
          ...state,
          speakers: {
            ...state.speakers,
            [eventId]: {
              list: [...list, ...response],
              pageMeta: pagemeta,
            },
          },
        };
      }
    }
    case EVENTS_GET_ATTENDING_ATTEMPT: {
      const { eventId, currentPage } = payload;
      if (currentPage === 1) {
        return {
          ...state,
          attendings: {
            ...state.attendings,
            [eventId]: {
              list: [],
            },
          },
        };
      }
      return state;
    }
    case EVENTS_GET_ATTENDING_SUCCESS: {
      const { eventId, data } = payload;
      const pagemeta = pageMeta(data);
      const { response, status } = data;
      if (status === apiStatus.SUCCESS) {
        const list = state.attendings[eventId] && state.attendings[eventId].list
          ? state.attendings[eventId].list
          : [];
        return {
          ...state,
          eventsShouldUpdate: false,
          attendings: {
            ...state.attendings,
            [eventId]: {
              list: [...list, ...response],
              pageMeta: pagemeta,
            },
          },
        };
      }
    }
    case EVENTS_REMOVE_ATTENDING_SUCCESS: {
      const aa = payload;
      return {
        ...state,
      };
    }
    case EVENTS_GET_ALL_ATTENDING_SUCCESS:
      const aa = payload;
      return {
        ...state,
      };

    case EVENTS_POST_ATTENDING_ATTEMPT: {
      return {
        ...state,
        eventsShouldUpdate: false,
      };
    }

    case EVENTS_POST_ATTENDING_SUCCESS: {
      const { response, status } = payload;
      return {
        ...state,
        eventsShouldUpdate: true,
      };
    }

    case EVENTS_DELETE_ATTENDING_SUCCESS: {
      const { response, status } = payload;
      return {
        ...state,
        eventsShouldUpdate: true,
      };
    }

    case EVENTS_DELETE_EVENT_ATTEMPT: {
      return {
        ...state,
        eventsShouldUpdate: false,
        shouldRedirecToEvents: false,
      };
    }

    case EVENTS_DELETE_EVENT_SUCCESS: {
      const { result, deletedEvent } = payload;
      return {
        ...state,
        eventsShouldUpdate: true,
        shouldRedirecToEvents: true,
      };
    }

    case EVENTS_ADD_SPEAKER_SUCCESS: {
      const { response, status } = payload;
      return {
        ...state,
        speakers: {
          ...state.speakers,
          [response.event_id]: {
            [state.speakers[response.event_id]?.list]: [
              ...state.speakers[response.event_id]?.list,
              response,
            ],
          },
        },
        speakersShouldUpdate: true,
      };
    }

    case EVENTS_REMOVE_SPEAKER_SUCCESS: {
      const { response, status } = payload;
      return {
        ...state,
        speakersShouldUpdate: true,
      };
    }

    case EVENT_SHARE_ATTEMPT: {
      return {
        ...state,
      };
    }

    case EVENT_SHARE_SUCCESS: {
      return {
        ...state,
      };
    }

    case EVENTS_ADD_RATING_ATTEMPT: {
      return {
        ...state,
        eventsShouldUpdate: false,
      };
    }

    case EVENTS_ADD_RATING_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          selectedEvent: {
            ...state.selectedEvent,
            current_user_rating: response.rating,
          },
          eventsShouldUpdate: true,
        };
      }
    }

    case CLEAR_SELECTED_EVENT: {
      return {
        ...state,
        selectedEvent: {},
      };
    }
    case SET_CURRENT_EVENT_PAGE: {
      return {
        ...state,
        currentEventPage: payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default viewEventReducer;

import * as Yup from "yup";

const initModel = {
  stageName: "",
  position: 1,
  description: "",
};

const validation = Yup.object().shape({
  stageName: Yup.string().required("Stage name is required"),
});

export { validation, initModel };

import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import styles from "modules/companies/companyMain/rightWindow/rightWindow.module.scss";
import ChartTagsList from "modules/companies/companyMain/chartsWrapper/chartTagsList";
import CompaniesSelect from "modules/companies/companyMain/rightWindow/companiesSelect";
import { useCompanyContext } from "modules/companies/companyMain/providers/companyDataProvider";

const CompaniesSearchTop = ({ pageSetting, memoizedCompanies }) => {
  const {
    companiesReducer: {
      companyMainReducer: {
        selectedCompanies,
        searchResultNr,
        productsResultNr,
      },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const {
    downloadPdfIsActive,
    showProductsCount,
    genericResultsNr,
    selectLayout,
    toggleFilter,
    selectModeIsActive,
  } = useCompanyContext();

  const location = useLocation();

  return (
    <>
      {(!location.state?.query ||
        location.state?.query?.length === 0) &&
      pageSetting?.current === 1 &&
      genericResultsNr ? (
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex flex-column flex-grow-1">
            <ChartTagsList usedOutsideCharts />
            {downloadPdfIsActive ? (
              <div className="d-flex flex-row align-items-center mt-2 flex-grow-1">
                <div className={styles.logoWrp}>
                  <img
                    width="100"
                    height="100"
                    alt={selectedCouncil.name}
                    src={selectedCouncil.logo}
                  />
                </div>
                <h2 className="mb-0 bold mt-2 d-flex justify-content-center w-100">
                  Traction Report
                </h2>
              </div>
            ) : null}
            {selectLayout !== 2 && !downloadPdfIsActive && (
              <CompaniesSelect />
            )}
          </div>
          <div className="d-flex align-items-center">
            {downloadPdfIsActive ? (
              <div className={styles.resultCon}>
                {selectModeIsActive && selectedCompanies.length
                  ? `${selectedCompanies.length} Selected`
                  : `1-${memoizedCompanies.length} of ${genericResultsNr} Results`}
              </div>
            ) : (
              <div className={styles.resultCon}>
                {selectModeIsActive && selectedCompanies.length
                  ? `${selectedCompanies.length} Selected`
                  : (
                    <div className="d-flex flex-column">
                      {
                        toggleFilter !== '2' && (
                          <span>{`${searchResultNr} Compan${searchResultNr > 1 ? 'ies' : 'y'}`}</span>
                        )
                      }
                      {
                        showProductsCount && (
                          <span>{`${productsResultNr} Product${productsResultNr > 1 ? 's' : ''}`}</span>
                        )
                      }
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      ) : null}
      {(location.state?.query ||
        location.state?.query?.length > 0 ||
        pageSetting?.current !== 1) &&
      memoizedCompanies?.length &&
      genericResultsNr ? (
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex flex-column flex-grow-1">
            <ChartTagsList usedOutsideCharts />
            {downloadPdfIsActive ? (
              <div className="d-flex flex-row align-items-center mt-2 flex-grow-1">
                <div className={styles.logoWrp}>
                  <img
                    alt={selectedCouncil.name}
                    src={selectedCouncil.logo}
                  />
                </div>
                <h2 className="mb-0 bold mt-2 d-flex justify-content-center w-100">
                  Traction Report
                </h2>
              </div>
            ) : null}
            {selectLayout !== 2 && !downloadPdfIsActive && (
              <CompaniesSelect />
            )}
          </div>
          <div className="d-flex align-items-center">
            <div className={styles.resultCon}>
              {selectModeIsActive && selectedCompanies.length
                ? `${selectedCompanies.length} Selected`
                : pageSetting.pageSize > genericResultsNr
                  ? `${genericResultsNr} of ${genericResultsNr} Results`
                  : `${
                    pageSetting.pageSize * pageSetting.current -
                    (selectLayout === 0 ? 19 : 49)
                  } - ${
                    pageSetting.pageSize * pageSetting.current
                  } of ${genericResultsNr} Results`}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default memo(CompaniesSearchTop);

import { combineEpics, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  GET_COMPANY_INVITED_CONTACTS_ATTEMPT,
  getCompanyInvitedContactsSuccess,
  EDIT_COMPANY_CONTACTS_ATTEMPT,
  editCompanyContactsSuccess,
  DELETE_COMPANY_CONTACTS_ATTEMPT,
  deleteCompanyContactsSuccess,
  POST_COMPANY_CONTACTS_ATTEMPT,
  postCompanyContactsSuccess,
} from "./adminMembers.action";
import {
  errorHandler,
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../../../common/httpCall";

export const epicGetAdminCompanyContacts = (action$, state$) => action$.pipe(
  ofType(GET_COMPANY_INVITED_CONTACTS_ATTEMPT),
  switchMap(({
    payload: {
      enqueueSnackbar, query, page, pageSize,
    },
  }) => httpGet({
    call: `companies/contacts?invited=true${
      query ? `&search=${query}` : ""
    }&page=${page || 1}&items=${pageSize || 20}`,
  }).pipe(
    map((result) => getCompanyInvitedContactsSuccess(result)),
    catchError((error) => errorHandler(error, enqueueSnackbar, [])),
  )),
);

export const epicPutAdminCompanyContacts = (action$, state$) => action$.pipe(
  ofType(EDIT_COMPANY_CONTACTS_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar, contact_id, data } }) => httpPut({
    call: `companies/contacts/${contact_id}`,
    data: {
      ...data,
      mobile: "-",
    },
  }).pipe(
    map((result) => editCompanyContactsSuccess(result)),
    catchError((error) => errorHandler(error, enqueueSnackbar, [])),
  )),
);

export const epicPostAdminCompanyContacts = (action$, state$) => action$.pipe(
  ofType(POST_COMPANY_CONTACTS_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar, data } }) => httpPost({
    call: "companies/contacts",
    data: {
      ...data,
      mobile: "-",
    },
  }).pipe(
    map((result) => postCompanyContactsSuccess(result)),
    catchError((error) => errorHandler(error, enqueueSnackbar, [])),
  )),
);

export const epicDeleteAdminCompanyContacts = (action$, state$) => action$.pipe(
  ofType(DELETE_COMPANY_CONTACTS_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar, contactId } }) => httpDelete({
    call: `companies/contacts/${contactId}`,
  }).pipe(
    map((result) => deleteCompanyContactsSuccess(result)),
    catchError((error) => errorHandler(error, enqueueSnackbar, [])),
  )),
);

const adminMembersEpic = combineEpics(
  epicGetAdminCompanyContacts,
  epicPutAdminCompanyContacts,
  epicDeleteAdminCompanyContacts,
  epicPostAdminCompanyContacts,
);

export default adminMembersEpic;

export const GET_COMPANY_INVITED_CONTACTS_ATTEMPT = "GET_COMPANY_INVITED_CONTACTS_ATTEMPT";
export const getCompanyInvitedContactsAttempt = (payload) => ({
  type: GET_COMPANY_INVITED_CONTACTS_ATTEMPT,
  payload,
});

export const GET_COMPANY_INVITED_CONTACTS_SUCCESS = "GET_COMPANY_INVITED_CONTACTS_SUCCESS";
export const getCompanyInvitedContactsSuccess = (payload) => ({
  type: GET_COMPANY_INVITED_CONTACTS_SUCCESS,
  payload,
});

export const POST_COMPANY_CONTACTS_ATTEMPT = "POST_COMPANY_CONTACTS_ATTEMPT";
export const postCompanyContactsAttempt = (payload) => ({
  type: POST_COMPANY_CONTACTS_ATTEMPT,
  payload,
});

export const POST_COMPANY_CONTACTS_SUCCESS = "POST_COMPANY_CONTACTS_SUCCESS";
export const postCompanyContactsSuccess = (payload) => ({
  type: POST_COMPANY_CONTACTS_SUCCESS,
  payload,
});

export const EDIT_COMPANY_CONTACTS_ATTEMPT = "EDIT_COMPANY_CONTACTS_ATTEMPT";
export const editCompanyContactsAttempt = (payload) => ({
  type: EDIT_COMPANY_CONTACTS_ATTEMPT,
  payload,
});

export const EDIT_COMPANY_CONTACTS_SUCCESS = "EDIT_COMPANY_CONTACTS_SUCCESS";
export const editCompanyContactsSuccess = (payload) => ({
  type: EDIT_COMPANY_CONTACTS_SUCCESS,
  payload,
});

export const DELETE_COMPANY_CONTACTS_ATTEMPT = "DELETE_COMPANY_CONTACTS_ATTEMPT";
export const deleteCompanyContactsAttempt = (payload) => ({
  type: DELETE_COMPANY_CONTACTS_ATTEMPT,
  payload,
});

export const DELETE_COMPANY_CONTACTS_SUCCESS = "DELETE_COMPANY_CONTACTS_SUCCESS";
export const deleteCompanyContactsSuccess = (payload) => ({
  type: DELETE_COMPANY_CONTACTS_SUCCESS,
  payload,
});

export const PEOPLE_ADD_MEMBERS_SUCCESS = "PEOPLE_ADD_MEMBERS_SUCCESS";
export const peopleMembersAddSuccess = (payload) => ({
  type: PEOPLE_ADD_MEMBERS_SUCCESS,
  payload,
});

export const GET_TRACTION_COMPANIES_ATTEMPT = "GET_TRACTION_COMPANIES_ATTEMPT";
export const getTractionCompaniesAttempt = (payload) => ({
  type: GET_TRACTION_COMPANIES_ATTEMPT,
  payload,
});

export const GET_TRACTION_COMPANIES_SUCCESS = "GET_TRACTION_COMPANIES_SUCCESS";
export const getTractionCompaniesSuccess = (payload) => ({
  type: GET_TRACTION_COMPANIES_SUCCESS,
  payload,
});

export const PUT_TRACTION_COMPANIES_ATTEMPT = "PUT_TRACTION_COMPANIES_ATTEMPT";
export const putTractionCompaniesAttempt = (payload) => ({
  type: PUT_TRACTION_COMPANIES_ATTEMPT,
  payload,
});

export const PUT_TRACTION_COMPANIES_SUCCESS = "PUT_TRACTION_COMPANIES_SUCCESS";
export const putTractionCompaniesSuccess = (payload) => ({
  type: PUT_TRACTION_COMPANIES_SUCCESS,
  payload,
});

export const PUT_TRACTION_COMPANIES_HIDE_ATTEMPT = "PUT_TRACTION_COMPANIES_HIDE_ATTEMPT";
export const putTractionCompaniesHideAttempt = (payload) => ({
  type: PUT_TRACTION_COMPANIES_HIDE_ATTEMPT,
  payload,
});

export const PUT_TRACTION_COMPANIES_HIDE_SUCCESS = "PUT_TRACTION_COMPANIES_HIDE_SUCCESS";
export const putTractionCompaniesHideSuccess = (payload) => ({
  type: PUT_TRACTION_COMPANIES_HIDE_SUCCESS,
  payload,
});

export const PUT_TRACTION_COMPANIES_UNHIDE_ATTEMPT = "PUT_TRACTION_COMPANIES_UNHIDE_ATTEMPT";
export const putTractionCompaniesUnHideAttempt = (payload) => ({
  type: PUT_TRACTION_COMPANIES_UNHIDE_ATTEMPT,
  payload,
});

export const PUT_TRACTION_COMPANIES_UNHIDE_SUCCESS = "PUT_TRACTION_COMPANIES_UNHIDE_SUCCESS";
export const putTractionCompaniesUnHideSuccess = (payload) => ({
  type: PUT_TRACTION_COMPANIES_UNHIDE_SUCCESS,
  payload,
});

export const DELETE_TRACTION_COMPANIES_ATTEMPT = "DELETE_TRACTION_COMPANIES_ATTEMPT";
export const deleteTractionCompaniesAttempt = (payload) => ({
  type: DELETE_TRACTION_COMPANIES_ATTEMPT,
  payload,
});

export const DELETE_TRACTION_COMPANIES_SUCCESS = "DELETE_TRACTION_COMPANIES_SUCCESS";
export const deleteTractionCompaniesSuccess = (payload) => ({
  type: DELETE_TRACTION_COMPANIES_SUCCESS,
  payload,
});

export const CATCH_ERROR_SUCCESS = "CATCH_ERROR_SUCCESS";
export const catchErrorSuccess = (payload) => ({
  type: CATCH_ERROR_SUCCESS,
  payload,
});

export const GET_ALL_COMPANIES_EXPORT_ATTEMPT = "GET_ALL_COMPANIES_EXPORT_ATTEMPT";
export const getAllCompaniesExportAttempt = (payload) => ({
  type: GET_ALL_COMPANIES_EXPORT_ATTEMPT,
  payload,
});

export const GET_ALL_COMPANIES_EXPORT_SUCCESS = "GET_ALL_COMPANIES_EXPORT_SUCCESS";
export const getAllCompaniesExportSuccess = (payload) => ({
  type: GET_ALL_COMPANIES_EXPORT_SUCCESS,
  payload,
});

export const GET_TRACTION_COMPANY_BY_ID_ATTEMPT = "GET_TRACTION_COMPANY_BY_ID_ATTEMPT";
export const getTractionCompanyByIdAttempt = (payload) => ({
  type: GET_TRACTION_COMPANY_BY_ID_ATTEMPT,
  payload,
});

export const GET_TRACTION_COMPANY_BY_ID_SUCCESS = "GET_TRACTION_COMPANY_BY_ID_SUCCESS";
export const getTractionCompanyByIdSuccess = (payload) => ({
  type: GET_TRACTION_COMPANY_BY_ID_SUCCESS,
  payload,
});

export const POST_SEARCH_COMPANIES_BY_QUERY_ATTEMPT = "POST_SEARCH_COMPANIES_BY_QUERY_ATTEMPT";
export const postSearchCompaniesByQueryAttempt = (payload) => ({
  type: POST_SEARCH_COMPANIES_BY_QUERY_ATTEMPT,
  payload,
});

export const POST_SEARCH_COMPANIES_BY_QUERY_SUCCESS = "POST_SEARCH_COMPANIES_BY_QUERY_SUCCESS";
export const postSearchCompaniesByQuerySuccess = (payload) => ({
  type: POST_SEARCH_COMPANIES_BY_QUERY_SUCCESS,
  payload,
});

export const POST_SEARCH_COMPANIES_SF_EXPORT_ATTEMPT = "POST_SEARCH_COMPANIES_SF_EXPORT_ATTEMPT";
export const postSearchCompaniesSfExportAttempt = (payload) => ({
  type: POST_SEARCH_COMPANIES_SF_EXPORT_ATTEMPT,
  payload,
});

export const POST_SEARCH_COMPANIES_SF_EXPORT_SUCCESS = "POST_SEARCH_COMPANIES_SF_EXPORT_SUCCESS";
export const postSearchCompaniesSfExportSuccess = (payload) => ({
  type: POST_SEARCH_COMPANIES_SF_EXPORT_SUCCESS,
  payload,
});

export const POST_TRACTION_COMPANY_ATTEMPT = "POST_TRACTION_COMPANY_ATTEMPT";
export const postTractionCompanyAttempt = (payload) => ({
  type: POST_TRACTION_COMPANY_ATTEMPT,
  payload,
});

export const POST_TRACTION_COMPANY_SUCCESS = "POST_TRACTION_COMPANY_SUCCESS";
export const postTractionCompanySuccess = (payload) => ({
  type: POST_TRACTION_COMPANY_SUCCESS,
  payload,
});

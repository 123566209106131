import React, { useState, useEffect } from "react";
import { MoreOptions } from "modules/primitives/index";
import { CompanyAvatar } from "modules/primitives";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { Icon, smSize } from "../../../../common/icon";
import { shouldDownload } from "../../../../common/helper";
import { moreOptions } from "./documentsTab.constant";
import UploadedByInfo from "../../../../common/components/uploadedByInfo";

const DocumentRow = ({
  document,
  documentName,
  handleMoreOptionClick,
  setSelectedTab,
}) => {
  const {
    themesReducer: {
      themesMainReducer: { currentThemeData },
    },
  } = useSelector((state) => state);
  const history = useHistory();
  const [relatedItem, setRelatedItem] = useState("");

  useEffect(() => {
    const documentType = document?.document_resource_type;

    if (documentType === "ThemePost") {
      setRelatedItem({
        name: "Theme Post",
        url: `/themes/individual_page/${currentThemeData.id}/overview?post_id=${document.theme_post_id}`,
        label: document?.date || "date",
      });
    }
    if (documentType === "ThemePostReply") {
      setRelatedItem({
        name: "Theme Reply",
        url: `/themes/individual_page/${currentThemeData.id}/overview?post_id=${document.theme_post_id}`,
        label: document?.date || "date",
      });
    }
    if (documentType === "Theme") {
      setRelatedItem({
        name: "Theme",
        url: false,
        label: document?.date || "date",
      });
    }
    if (documentType === "Company") {
      setRelatedItem({
        name: "Company",
        url: `/companies/${
          document?.slug || document?.document_resource_id
        }/documents`,
        label: document?.company_name || "company",
      });
    }
    if (documentType === "Event") {
      setRelatedItem({
        name: "Event:",
        // url: `/events/id/${documentResourceId}`,
        label: document?.event_name || "event",
      });
    }
  }, [document?.document_resource_type]);

  const handleRedirect = () => {
    if (document.is_product) {
      history.push(
        `/companies/${document.company.company_slug}/products/${document.company.slug}`,
        {}
      );
    } else {
      history.push(relatedItem.url, {});
    }
    console.log("relatedItem", relatedItem);
    setSelectedTab(0);
  };

  return (
    <div className={styles.gridRow}>
      <div className={styles.document}>{documentName || "-"}</div>
      <div className={styles.project}>
        <div>
          {document?.id ? (
            <div className={styles.relatedItem}>
              <div>
                {document.is_product && (
                  <div className={styles.Row} onClick={handleRedirect}>
                    <div className={styles.companyIcon}>
                      <div className={styles.iconCon}>
                        <CompanyAvatar
                          imgSrc={document.company.logo}
                          name={document.company?.name}
                        />
                      </div>
                    </div>
                    <div className={styles.link}>{document.company?.name}</div>{" "}
                    <span className="small text-muted ml-4">Product</span>
                  </div>
                )}
                {relatedItem?.name !== "Company" && !relatedItem?.url && (
                  <span>{relatedItem?.name}</span>
                )}
                {relatedItem?.name !== "Company" && relatedItem?.url && (
                  <span className={styles.link} onClick={handleRedirect}>
                    {relatedItem?.name}
                  </span>
                )}
                {relatedItem?.name === "Company" && (
                  <div className={styles.Row} onClick={handleRedirect}>
                    <div className={styles.companyIcon}>
                      <div className={styles.iconCon}>
                        <CompanyAvatar
                          imgSrc={document.company.logo}
                          name={document.company.name}
                        />
                      </div>
                    </div>
                    <div className={styles.link}>{document.company.name}</div>{" "}
                  </div>
                )}
              </div>
            </div>
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className={styles.task}>
        <UploadedByInfo document={document} />
      </div>
      <div className={styles.moreOpt}>
        <div className="mr-4 d-flex align-items-center">
          <a href={document.uri} target="_blank" download rel="noreferrer">
            <Icon
              {...smSize}
              icon="icn-export-button"
              className={styles.download}
            />
          </a>
          <a
            className={styles.docView}
            target="blank"
            href={document.uri}
            download
          >
            {shouldDownload(document.name) ? "download" : "view"}
          </a>
        </div>
        {relatedItem?.name?.indexOf("Theme") > -1 && (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, document.id, document.name);
            }}
            customSvgIcon
          />
        )}
      </div>
    </div>
  );
};
export default DocumentRow;

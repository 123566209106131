export const PIPELINE_GET_STAGES_ATTEMPT = "PIPELINE_GET_STAGES_ATTEMPT";
export const pipelineGetStagesAttempt = (payload) => ({
  type: PIPELINE_GET_STAGES_ATTEMPT,
  payload,
});
export const PIPELINE_GET_STAGES_SUCCESS = "PIPELINE_GET_STAGES_SUCCESS";
export const pipelineGetStagesSuccess = (payload) => ({
  type: PIPELINE_GET_STAGES_SUCCESS,
  payload,
});

export const PIPELINE_ADD_STAGE_ATTEMPT = "PIPELINE_ADD_STAGE_ATTEMPT";
export const pipelineAddStageAttempt = (payload) => ({
  type: PIPELINE_ADD_STAGE_ATTEMPT,
  payload,
});
export const PIPELINE_ADD_STAGE_SUCCESS = "PIPELINE_ADD_STAGE_SUCCESS";
export const pipelineAddStageSuccess = (payload) => ({
  type: PIPELINE_ADD_STAGE_SUCCESS,
  payload,
});
export const PIPELINE_EDIT_STAGE_ATTEMPT = "PIPELINE_EDIT_STAGE_ATTEMPT";
export const pipelineEditStageAttempt = (payload) => ({
  type: PIPELINE_EDIT_STAGE_ATTEMPT,
  payload,
});
export const PIPELINE_EDIT_STAGE_SUCCESS = "PIPELINE_EDIT_STAGE_SUCCESS";
export const pipelineEditStageSuccess = (payload) => ({
  type: PIPELINE_EDIT_STAGE_SUCCESS,
  payload,
});
export const PIPELINE_DELETE_STAGE_ATTEMPT = "PIPELINE_DELETE_STAGE_ATTEMPT";
export const pipelineDeleteStageAttempt = (payload) => ({
  type: PIPELINE_DELETE_STAGE_ATTEMPT,
  payload,
});
export const PIPELINE_DELETE_STAGE_SUCCESS = "PIPELINE_DELETE_STAGE_SUCCESS";
export const pipelineDeleteStageSuccess = (payload) => ({
  type: PIPELINE_DELETE_STAGE_SUCCESS,
  payload,
});

export const PIPELINE_GET_INVEST_STAGES_ATTEMPT =
  "PIPELINE_GET_INVEST_STAGES_ATTEMPT";
export const pipelineGetInvestStagesAttempt = (payload) => ({
  type: PIPELINE_GET_INVEST_STAGES_ATTEMPT,
  payload,
});

export const PIPELINE_GET_INVEST_STAGES_SUCCESS =
  "PIPELINE_GET_INVEST_STAGES_SUCCESS";
export const pipelineGetInvestStagesSuccess = (payload) => ({
  type: PIPELINE_GET_INVEST_STAGES_SUCCESS,
  payload,
});

export const THEME_PIPELINE_COMPANIES_SEARCH_ATTEMPT =
  "THEME_PIPELINE_COMPANIES_SEARCH_ATTEMPT";
export const pipelineSearchCompaniesAttempt = (payload) => ({
  type: THEME_PIPELINE_COMPANIES_SEARCH_ATTEMPT,
  payload,
});
export const PIPELINE_SEARCH_COMPANIES_SUCCESS =
  "PIPELINE_SEARCH_COMPANIES_SUCCESS";
export const pipelineSearchCompaniesSuccess = (payload) => ({
  type: PIPELINE_SEARCH_COMPANIES_SUCCESS,
  payload,
});

export const PIPELINE_EDIT_STAGE_ACROSS_ATTEMPT =
  "PIPELINE_EDIT_STAGE_ACROSS_ATTEMPT";
export const pipelineEditStageAcrossAttempt = (payload) => ({
  type: PIPELINE_EDIT_STAGE_ACROSS_ATTEMPT,
  payload,
});
export const PIPELINE_EDIT_STAGE_ACROSS_SUCCESS =
  "PIPELINE_EDIT_STAGE_ACROSS_SUCCESS";
export const pipelineEditStageAcrossSuccess = (payload) => ({
  type: PIPELINE_EDIT_STAGE_ACROSS_SUCCESS,
  payload,
});

export const PIPELINE_UPDATE_COMP_LIST_FE_ACROSS_SUCCESS =
  "PIPELINE_UPDATE_COMP_LIST_FE_ACROSS_SUCCESS";
export const pipelineUpdateCompListFE = (payload) => {
  return {
    type: PIPELINE_UPDATE_COMP_LIST_FE_ACROSS_SUCCESS,
    payload,
  };
};

import React, { useRef, useState, useEffect } from "react";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import {
  Button,
  BtnType,
  Label,
  TextBox,
  TextArea,
} from "../../../../primitives/index";
import { useModelPopup } from "../../../../../common/hooks";
import { ModalFooter, ModalBody } from "../../../../../application/modal";
import { initModel, validation } from "./constants";
import { httpPost, httpPatch } from "../../../../../common/httpCall";
import { getCompanyProductAddSuccess } from "modules/companies/company/company.action";
import UploadProductImages from "modules/companies/company/products/uploadImg/uploadImg.component";
import useS3FileUpload from "../../../../../common/hooks/s3FileUplodar.hook";
import LoadingComponent from "modules/primitives/loading/loading.component";

const AddNewProduct = ({ data, company }) => {
  const popup = useModelPopup();
  const [model, setModel] = useState(initModel);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { fileDelete } = useS3FileUpload();

  useEffect(() => {
    if (data) {
      setModel({
        name: data.name,
        description: data.description,
        product_link: data.product_link,
        // company_link: data.company_link,
        images: data.images,
      });
    }
  }, [data]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = () => {
    setHasSubmitError(false);
    const { values } = formRef.current;

    if (!values.name?.length) {
      enqueueSnackbar('Product name is required', {
        variant: 'error',
      });

      return;
    }

    setIsSubmitting(true);

    // update
    if (data?.id) {
      httpPatch({
        call: `companies/${company.id}/products/${data.id}`,
        data: values
      }).subscribe(res => {
        if (res.response) {
          dispatch(getCompanyProductAddSuccess({
            ...res.response,
            shouldUpdate: true,
          }));
          enqueueSnackbar('Successfully updated product', {
            variant: 'success',
          });
        }

        popup.hide();
      });

      return
    }

    httpPost({
      call: `companies/${company.id}/products/`,
      data: values
    }).subscribe(res => {
      if (res.response) {
        dispatch(getCompanyProductAddSuccess(res.response));
        enqueueSnackbar('Successfully added new product', {
          variant: 'success',
        });
      }

      popup.hide();
    }, error => {
      setIsSubmitting(false);
      if (error.status === 422) {
        setHasSubmitError(true);
        enqueueSnackbar('Product name must be unique', {
          variant: 'Error',
        });
      }}
    );
  };

  const removeImg = async (img) => {
    await fileDelete(img).then(() => {
      const newImages = formRef.current.values.images.filter(imgUrl => imgUrl !== img);
      formRef.current.setFieldValue('images', newImages);
    });
  };

  const handleImageUpload = (allImages) => {
    formRef.current.setFieldValue('images', allImages);
  }

  return (
    <>
      <ModalBody>
        <div>
          <Formik
            innerRef={formRef}
            initialValues={model}
            enableReinitialize
            validationSchema={validation}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              handleSaveClick();
              resetForm();
            }}
          >
            {({ handleSubmit, setFieldValue, values, ...formikprops }) => (
              <form onSubmit={handleSubmit} onChange={() => {
                if (hasSubmitError) {
                  setHasSubmitError(false);
                }
              }}>
                <div className="row">
                  <div className="col-12">
                    <>
                      <Label>Product Name</Label>
                      <div className="d-flex align-items-baseline">
                        <TextBox
                          name="name"
                          placeholder="Product Name"
                          className="w-100"
                          formProps={{ ...formikprops, setFieldValue, values }}
                          value={values.name}
                        />
                      </div>
                      {
                        hasSubmitError ? (
                          <span className="d-block mb-2 small text-danger text-error">
                            This product name already exists. Please try again.
                          </span>
                        ) : null
                      }
                    </>
                    <>
                      <Label>
                        Description
                      </Label>
                      <TextArea
                        name="description"
                        placeholder="Description"
                        value={values.description}
                        formProps={{ ...formikprops, setFieldValue, values }}
                        className="w-100"
                      />
                    </>
                    <>
                      <Label>
                        Product Link
                      </Label>
                      <TextBox
                        name="product_link"
                        placeholder="Product Link"
                        className="w-100"
                        formProps={{ ...formikprops, setFieldValue, values }}
                        value={values.product_link}
                      />
                    </>
                    <>
                      <Label>
                        Product Image
                      </Label>
                      <div className="d-flex flex-wrap">
                        {
                          values.images.map((i, idx) => (
                            <div key={`${data?.id}-image-${idx}`} className="mr-1 position-relative d-flex flex-column">
                              <span
                                onClick={() => removeImg(i)}
                                className="text-danger d-flex justify-content-center cursor-pointer"
                              >x</span>
                              <img width="100" src={i} alt={values.name} />
                            </div>
                          ))
                        }
                      </div>
                      <div className="mt-3">
                        <UploadProductImages
                          uploadDirName={`companies/${company.id}/products${data?.id ? `/${data?.id}/` : ''}`}
                          company={company}
                          product={data}
                          handleUpload={handleImageUpload}
                        />
                      </div>
                    </>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </ModalBody>
      <ModalFooter>
        {
          isSubmitting ? (
            <LoadingComponent customText={data?.id ? 'Updating product...' : 'Saving product...'} />
          ) : (
            <>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick} className="mr-3">
                Cancel
              </Button>
              <Button onClick={handleSaveClick} btn={BtnType.REGULAR}>
                Save
              </Button>
            </>
          )
        }
      </ModalFooter>
    </>
  );
};

export default React.memo(AddNewProduct);

import * as Yup from "yup";

export const initPatientOrganizationModel = {
  name: "",
  web_url: "",
  // country: "",
  // relationship_owners_ids: [],
};

export const patientOrganizationValidation = (isWebSiteRequired) => {
  if (isWebSiteRequired) {
    return Yup.object().shape({
      name: Yup.string().required("Organization name is required"),
      web_url: Yup.string().required("Organization website is required"),
      // country: Yup.string().required("Organization country is required"),
      // relationship_owners_ids: Yup.string().required("Organization relation manager is required"),
    });
  }

  return Yup.object().shape({
    name: Yup.string().required("Organization name is required"),
    // country: Yup.string().required("Organization country is required"),
    // relationship_owners_ids: Yup.string().required("Organization relation manager is required"),
  });
};

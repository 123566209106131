import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import {
  Search,
  Button,
  BtnType,
  MoreOptions,
  OptionType,
  Loading,
} from "modules/primitives/index";
import DownloadReport from "common/components/downloadReport";
import { useQuery } from "common/helper";
import { CSVLink } from "react-csv";
import styles from "./adminCompanies.module.scss";
import { useModelPopup } from "../../../common/hooks";
import {
  companyListGet,
  companySearchClear,
  setSortTypeSuccess,
} from "../../companies/companyMain/companyMain.action";
import CrunchbaseComponent from "../../companies/crunchbase/crunchbase.component";
import "react-table/react-table.css";
import BulkUploadComponent from "./bulkUpload";
import ConfirmDeleteComponent from "../../../common/components/confirmDelete";
import { patientOrganizationsGet } from "../../patientOrganizations/patientOrganizationMain/patientOrganizationMain.action";
import NewPatientOrganizationComponent from "../../patientOrganizations/newPatientOrganization/newPatientOrganization.component";
import DataUploadComponent from "./dataUpload";

const HeaderComponent = (props) => {
  const { name = "", typeName, handleSort = () => {}, sortInstance } = props;
  const dispatch = useDispatch();

  const onClickHandle = () => {
    if (sortInstance.sortId === typeName || sortInstance.sortId === "") {
      handleSort(typeName, sortInstance.sortOrder ? "asc" : "desc");
      dispatch(
        setSortTypeSuccess({ typeName, reversedSort: !sortInstance.sortOrder })
      );
    } else if (sortInstance.sortId !== typeName) {
      handleSort(typeName, "asc");
      dispatch(setSortTypeSuccess({ typeName, reversedSort: false }));
    }
  };
  return (
    <span style={{ cursor: "pointer" }} onClick={onClickHandle}>
      {name}
    </span>
  );
};

const AdminCompaniesComponent = () => {
  const tab = useQuery().get("tab");
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(tab || "companies");

  const onTabClick = (tab) => {
    setSelectedVariant(tab);
    history.push(`/admin/companies?tab=${tab}`);
  };
  const csvLinkRef = useRef();

  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: {
        companyList,
        searchResults,
        loading,
        companiesPageNr,
        searchResultNr,
        sortInstance,
      },
      companyReducer: { updateCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: {
        patientOrganizationList,
        updatePatientOrganizationsList,
        patientOrganizationPageNr,
        pOloading,
      },
    },
  } = useSelector((state) => state);
  const pageSizeOptions = [pageSize, 5, 10, 20, 50, 100];
  useEffect(() => {
    if (
      selectedCouncil &&
      history.location?.state?.mode !== "organization" &&
      page
    ) {
      dispatch(
        companyListGet({
          councilId: selectedCouncil.id,
          items: pageSize,
          page,
          enqueueSnackbar,
          sort_attribute: sortInstance.sortId,
          sort_order: sortInstance ? "asc" : "desc",
        })
      );
    } else if (
      selectedCouncil &&
      history.location?.state?.mode === "organization"
    ) {
      dispatch(
        patientOrganizationsGet({
          councilId: selectedCouncil?.id,
          items: pageSize,
          page,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil, page, pageSize]);

  useEffect(() => {
    if (selectedCouncil && selectedCouncil.name !== "GSK" && updateCompany) {
      dispatch(
        companyListGet({
          councilId: selectedCouncil.id,
          items: pageSize,
          page,
          enqueueSnackbar,
          sort_attribute: sortInstance.sortId,
          sort_order: sortInstance ? "asc" : "desc",
        })
      );
    }
  }, [updateCompany, page]);
  useEffect(() => {
    if (selectedCouncil && selectedCouncil.name !== "GSK" && page > 0) {
      dispatch(
        companyListGet({
          councilId: selectedCouncil.id,
          items: pageSize,
          page,
          enqueueSnackbar,
          sort_attribute: sortInstance.sortId,
          sort_order: sortInstance ? "asc" : "desc",
        })
      );
    }
  }, [selectedCouncil, page, pageSize]);

  useEffect(() => {
    if (updatePatientOrganizationsList) {
      dispatch(
        patientOrganizationsGet({
          councilId: selectedCouncil?.id,
          items: pageSize,
          page,
          enqueueSnackbar,
        })
      );
    }
  }, [updatePatientOrganizationsList, page, pageSize]);

  useEffect(() => {
    if (searchResults && searchResults[0]) {
      setData(searchResults);
    } else if (history.location?.state?.mode === "organization") {
      setData(patientOrganizationList);
    } else {
      setData(companyList);
    }
  }, [searchResults]);

  useEffect(() => {
    history.location?.state?.mode === "organization"
      ? setData(patientOrganizationList)
      : setData(companyList);
  }, [companyList, patientOrganizationList]);

  useEffect(
    () => () => {
      dispatch(companySearchClear());
    },
    []
  );

  const handleAddCompanyClick = (e) => {
    if (
      selectedCouncil.name === "GSK" &&
      history.location?.state?.mode === "organization"
    ) {
      popup.show({
        title: "Add Organization",
        component: <NewPatientOrganizationComponent />,
      });
    } else {
      popup.show({
        title: "Add Company",
        type: "contact",
        component: <CrunchbaseComponent />,
      });
    }
  };

  const DisabledButton = ({ showTooltip, setShowTooltip }) => (
    <>
      {showTooltip && (
        <div className={styles.exportModalTooltip}>
          <p>
            {searchResults?.length
              ? "Search cannot be exported "
              : "To be able to export list it must contain at least one element."}
          </p>
        </div>
      )}
      <Button
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
        className={styles.deleteSubmitButton}
        btn={BtnType.DISABLED}
      >
        EXPORT
      </Button>
    </>
  );

  const handleSortBy = (sortingFlag = "", sortingType = "asc") => {
    dispatch(
      companyListGet({
        councilId: selectedCouncil.id,
        items: pageSize,
        page,
        enqueueSnackbar,
        sort_attribute: sortingFlag,
        sort_order: sortingType,
      })
    );
  };

  const moreOptions = [
    { val: "edit", text: "Edit" },
    { val: "profile", text: "Profile" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const checkIfIsTractionMember = (email) => {
    if (!email) {
      return false;
    }
    const afterAron = email.split("@")[1];

    switch (afterAron) {
      case "tractiontechpartners.com": {
        return true;
      }
      case "tractiontechnologypartners.com": {
        return true;
      }
      case "tractiontechnology.com": {
        return true;
      }
      default:
        return false;
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (columnRef) => (
          <HeaderComponent
            handleSort={handleSortBy}
            typeName={columnRef.column.id}
            sortInstance={sortInstance}
            name={
              selectedCouncil &&
              selectedCouncil.name === "GSK" &&
              history.location?.state?.mode === "organization"
                ? "Patient Organization"
                : "Company"
            }
          />
        ),
        sortable: false,
        accessor: "name",
        width: 200,
      },
      {
        Header: "Website",
        id: "web_url",
        sortable: false,
        name: "web",
        accessor: (row) => (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              if (row.web_url) {
                window.open(row.web_url, "_blank");
              }
            }}
            btn={BtnType.FRAME_LESS}
            className={
              row.web_url ? styles.linkButton : styles.disabledLinkButton
            }
          >
            Link
          </Button>
        ),
        width: 100,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: (columnRef) => (
          <HeaderComponent
            handleSort={handleSortBy}
            typeName={columnRef.column.id}
            sortInstance={sortInstance}
            name={
              selectedCouncil &&
              selectedCouncil.name === "GSK" &&
              history.location?.state?.mode === "organization"
                ? "Country"
                : "Headquarters"
            }
          />
        ),
        sortable: false,
        accessor:
          selectedCouncil &&
          selectedCouncil.name === "GSK" &&
          history.location?.state?.mode === "organization"
            ? "country"
            : "location",
        maxWidth: 400,
        minWidth: 140,
        width: 300,
      },
      {
        Header: (columnRef) => (
          <HeaderComponent
            handleSort={handleSortBy}
            typeName={columnRef.column.id}
            sortInstance={sortInstance}
            name="Date Added"
          />
        ),
        id: "created_at",
        sortable: false,
        accessor: (row) => format(new Date(row.created_at), "MM/dd/yyyy"),
      },
      {
        Header: (columnRef) => (
          <HeaderComponent
            handleSort={handleSortBy}
            typeName={columnRef.column.id}
            sortInstance={sortInstance}
            name="Who Added"
          />
        ),
        style: {
          display: "flex",
          justifyContent: "center",
        },
        id: "added_by_name",
        sortable: false,
        accessor: (row) => {
          if (row.is_applicant) {
            return "Applicant";
          }
          return (
            <>
              {checkIfIsTractionMember(row?.added_by_email) ? (
                <span className={styles.tractionMember}>Traction</span>
              ) : (
                row?.added_by_name || "-"
              )}
            </>
          );
        },
      },
      {
        Header: "Options",
        id: "options",

        accessor: (row) => (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row);
            }}
          />
        ),
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [companyList]
  );

  const handleNextClick = () => {
    setPage(page + 1);
  };

  const handlePrevClick = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const handleMoreOptionClick = (event, row) => {
    if (event === "edit") {
      if (
        selectedCouncil &&
        selectedCouncil.name === "GSK" &&
        history.location?.state?.mode === "organization"
      ) {
        history.push(`/admin/organizations/edit/${row.id}`, { gsk: true });
      } else {
        history.push(`/admin/companies/edit/${row.id}`);
      }
    }
    if (event === "delete") {
      const council = JSON.parse(localStorage.getItem("council"));
      popup.show({
        title: "Confirmation Prompt",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={row.id}
            enqueueSnackbar={enqueueSnackbar}
            councilId={council?.id}
            name={row.name}
            actionType={
              council &&
              council.name === "GSK" &&
              history.location?.state?.mode === "organization"
                ? "deleteOrganization"
                : "deleteCompany"
            }
          />
        ),
      });
    }
    if (event === "profile") {
      if (history.location?.state?.mode === "organization") {
        history.push(`/patient_organizations/${row.slug || row.id}`, {
          gsk: true,
        });
      } else if (history.location?.pathname.indexOf("companies") > 0) {
        history.push(`/companies/${row.slug || row.id}`);
      } else {
        console.log("-------no council ---");
      }
    }
  };

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
  };

  const handlePageSelect = (e) => {
    setPage(e.target.value);
  };

  const isNextDisabled = () => {
    if (page + 1 > 3) {
      return styles.disabledButton;
    }
    return styles.paginationButton;
  };

  const resultNr =
    history.location.pathname.indexOf("organizations") !== -1
      ? patientOrganizationPageNr?.total || searchResultNr
      : companiesPageNr?.total || searchResultNr;

  const detectLoading = () => {
    if (selectedCouncil?.name === "GSK") {
      return pOloading;
    }

    return loading;
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.companiesWrp}>
          <div className={styles.headerWrp}>
            <h4 className={styles.taskHeaderText}>
              <span
                className={styles.rootText}
                onClick={() => {
                  history.push("/admin");
                }}
              >
                ADMIN
              </span>
              <span className={styles.separationBar}> / </span>
              <span
                className={styles.rootText}
                onClick={() => {
                  if (history.location?.state?.mode !== "organization") {
                    onTabClick("companies");
                  }
                }}
              >
                {" "}
                {selectedCouncil &&
                selectedCouncil.name === "GSK" &&
                history.location?.state?.mode === "organization"
                  ? "PATIENT ORGANIZATIONS"
                  : "COMPANIES"}
              </span>
              <span className={styles.separationBar}> / </span>
              <span
                className={styles.rootText}
                onClick={() => {
                  onTabClick("uploads");
                }}
              >
                {" "}
                {selectedCouncil &&
                selectedCouncil.name === "GSK" &&
                history.location?.state?.mode === "organization"
                  ? ""
                  : " BULK UPLOADS"}
              </span>
              <span className={styles.separationBar}> / </span>
              <span
                className={styles.rootText}
                onClick={() => {
                  onTabClick("dataUploads");
                }}
              >
                DATA UPLOADS
              </span>
            </h4>
          </div>
          {selectedVariant !== "companies" ? (
            <>
              {selectedVariant === "uploads" ? (
                <BulkUploadComponent />
              ) : (
                <DataUploadComponent onTabClick={onTabClick} />
              )}
            </>
          ) : (
            <div className={styles.flexWrapper}>
              <div className={styles.companies}>
                <div className={styles.searchBar}>
                  <Search
                    showDropdown={false}
                    type="gsk"
                    gsk={history.location?.state?.mode === "organization"}
                    patient_org={
                      history.location?.state?.mode === "organization"
                    }
                    from="admin_companies"
                  />
                </div>

                <div className={styles.buttonWrp}>
                  <div className={styles.addCompanyBtnWrp}>
                    <Button
                      btn={BtnType.REGULAR}
                      icon="icn-add"
                      onClick={handleAddCompanyClick}
                    >
                      {selectedCouncil &&
                      selectedCouncil.name === "GSK" &&
                      history.location?.state?.mode === "organization"
                        ? "Add Organization"
                        : "Add Company"}
                    </Button>
                  </div>
                  <div className={styles.addCompanyBtnWrp}>
                    {selectedCouncil &&
                    history.location?.state?.mode !== "organization" ? (
                      <Button
                        btn={BtnType.FRAME_LESS}
                        icon="arrow-up2"
                        onClick={() => {
                          onTabClick("uploads");
                        }}
                      >
                        Bulk Uploads
                      </Button>
                    ) : null}
                  </div>
                  {exportLoading ? (
                    <Loading />
                  ) : resultNr > 0 && !searchResultNr ? (
                    <div>
                      <DownloadReport
                        className={styles.reportBtns}
                        label="EXPORT"
                        endpoint="admin_companies"
                        payload={{
                          search: {
                            sort_attribute: sortInstance.sortId,
                            sort_order: sortInstance ? "asc" : "desc",
                          },
                        }}
                      />
                      <CSVLink
                        data={csvData}
                        filename={`Traction ${
                          history.location.pathname.indexOf("organizations") !==
                          -1
                            ? "Organizations"
                            : "Companies"
                        }.csv`}
                        style={{ display: "none" }}
                        ref={csvLinkRef}
                      />
                    </div>
                  ) : (
                    <DisabledButton
                      setShowTooltip={setShowTooltip}
                      showTooltip={showTooltip}
                    />
                  )}

                  <div className={styles.totalCount}>
                    {history.location.pathname.indexOf("organizations") < 0 ? (
                      <p>
                        {" "}
                        {companiesPageNr?.total && !searchResultNr
                          ? companiesPageNr?.total + " " + "Results" ||
                            "0 companies"
                          : searchResultNr + " Results"}{" "}
                      </p>
                    ) : (
                      <p>
                        {" "}
                        {patientOrganizationPageNr?.total && !searchResultNr
                          ? patientOrganizationPageNr?.total +
                              " " +
                              "Results" || "0 organizations"
                          : searchResultNr + " Results"}{" "}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.tableContainer}>
                  <ReactTable
                    columns={columns}
                    data={data}
                    showPagination={false}
                    minRows={5}
                    loading={detectLoading() ? <Loading /> : null}
                    pageSize={pageSize}
                    // onPageSizeChange={(e) => handleChangePageSize(e)}
                    className={styles.table}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: () => {
                            selectedCouncil &&
                            selectedCouncil.name === "GSK" &&
                            history.location?.state?.mode === "organization"
                              ? history.push(
                                  `/admin/organizations/edit/${rowInfo.original.id}`,
                                  {
                                    gsk: true,
                                  }
                                )
                              : history.push(
                                  `/admin/companies/edit/${rowInfo.original.id}`
                                );
                          },
                          onMouseEnter: (e) => {
                            setHoveredRow(rowInfo.index);
                          },
                          onMouseLeave: (e) => {
                            setHoveredRow(null);
                          },
                          style: {
                            cursor: "pointer",
                            background:
                              rowInfo.index === hoveredRow
                                ? "#efefef"
                                : "transparent",
                          },
                        };
                      }
                      return {};
                    }}
                  />
                </div>
                <div className={styles.pagination}>
                  <div
                    className={
                      page - 1 === 0
                        ? styles.disabledButton
                        : styles.paginationButton
                    }
                    onClick={handlePrevClick}
                  >
                    Previous
                  </div>
                  <div className={styles.paginationSettings}>
                    <span style={styles.pageNr}>
                      <span>
                        <input
                          onChange={(e) => handlePageSelect(e)}
                          value={page}
                          className={styles.inputBox}
                          type="number"
                          min="1"
                        />
                      </span>
                      <span>of</span>
                      <span>
                        {" "}
                        {history.location?.state?.mode === "organization"
                          ? patientOrganizationPageNr?.pages
                          : companiesPageNr?.pages}
                      </span>
                    </span>

                    <span>
                      <select onChange={(e) => handleChangePageSize(e)}>
                        {pageSizeOptions.map((option) => (
                          <option value={option}>{option}</option>
                        ))}
                      </select>
                    </span>
                  </div>
                  <button
                    className={isNextDisabled()}
                    onClick={handleNextClick}
                    disabled={
                      history.location?.state?.mode === "organization"
                        ? page + 1 > patientOrganizationPageNr?.pages
                        : page + 1 > companiesPageNr?.pages
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminCompaniesComponent;

import React, { memo, useState } from "react";
import { withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";

import CrunchItemResultComponent from "../crunchItemResult/crunchItemResult.component";
import CrunchItemResultHoverComponent from "../crunchItemResultHover/crunchItemResultHover.component";
import { CompanyAvatar } from "../../../../primitives";
import styles from "../rightWindow.module.scss";

const width = window.innerWidth > 1200 ? 800 : 600;

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fafafa",
    boxShadow: "5px 5px 4px 0 rgba(0, 0, 0, 0.14)",
    color: "rgba(0, 0, 0, 0.87)",
    width,
    minWidth: width,
    fontSize: theme.typography.pxToRem(15),
    border: "1px solid #cccccc",
    padding: "15px 20px 10px",
  },
}))(Tooltip);

const companyNameWrapperStyles = {
  cursor: "pointer",
  display: "flex",
  width: "180px",
  alignItems: "center",
};

const logoStyles = {
  minWidth: "24px",
  width: "24px",
  height: "24px",
  marginRight: "10px",
};

const CustomTooltipChild = React.forwardRef((props, ref) => {
  const {
    addToTraction, item, userIsScrolling, ...otherProps
  } = props;

  return (
    <div style={companyNameWrapperStyles} {...otherProps} ref={ref}>
      <div style={logoStyles}>
        <CompanyAvatar
          imgSrc={item.properties.image_url}
          name={item.properties.name}
          hasBorderRadius
          smallSize
        />
      </div>
      <span>{item.properties.name}</span>
    </div>
  );
});

const CrunchItemWrapperComponent = (props) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`d-flex align-items-stretch ${styles.item}`}
      onMouseOut={() => setHovered(false)}
      onMouseLeave={() => setHovered(false)}
    >
      <div onMouseOver={() => setHovered(true)}>
        <HtmlTooltip
          interactive
          title={(
            <>
              <CrunchItemResultHoverComponent {...props} />
            </>
          )}
          enterDelay={1000}
          open={props.userIsScrolling ? false : hovered}
          placement="top"
        >
          <CustomTooltipChild {...props} />
        </HtmlTooltip>
      </div>
      <CrunchItemResultComponent {...props} />
    </div>
  );
};

export default memo(CrunchItemWrapperComponent);

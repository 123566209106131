export const DISCUSSION_EDIT = "DISCUSSION_EDIT";
export const discussionEdit = (payload) => ({
  type: DISCUSSION_EDIT,
  payload,
});

export const DISCUSSION_EDIT_SUCCESS = "DISCUSSION_EDIT_SUCCESS";
export const discussionEditSuccess = (payload) => ({
  type: DISCUSSION_EDIT_SUCCESS,
  payload,
});

export const DISCUSSION_DELETE = "DISCUSSION_DELETE";
export const discussionDelete = (payload) => ({
  type: DISCUSSION_DELETE,
  payload,
});

export const DISCUSSION_DELETE_SUCCESS = "DISCUSSION_DELETE_SUCCESS";
export const discussionDeleteSuccess = (payload) => ({
  type: DISCUSSION_DELETE_SUCCESS,
  payload,
});

export const DISCUSSION_MSG_DELETE = "DISCUSSION_MSG_DELETE";
export const discussionMsgDelete = (payload) => ({
  type: DISCUSSION_MSG_DELETE,
  payload,
});

export const DISCUSSION_MSG_DELETE_SUCCESS = "DISCUSSION_MSG_DELETE_SUCCESS";
export const discussionMsgDeleteSuccess = (payload) => ({
  type: DISCUSSION_MSG_DELETE_SUCCESS,
  payload,
});

export const DISCUSSION_MSG_EDIT = "DISCUSSION_MSG_EDIT";
export const discussionMsgEdit = (payload) => ({
  type: DISCUSSION_MSG_EDIT,
  payload,
});

export const DISCUSSION_MSG_EDIT_SUCCESS = "DISCUSSION_MSG_EDIT_SUCCESS";
export const discussionMsgEditSuccess = (payload) => ({
  type: DISCUSSION_MSG_EDIT_SUCCESS,
  payload,
});

export const PUBLIC_DISCUSSIONS_GET_ATTEMPT = "PUBLIC_DISCUSSIONS_GET_ATTEMPT";
export const publicDiscussionsGet = (payload) => ({
  type: PUBLIC_DISCUSSIONS_GET_ATTEMPT,
  payload,
});
export const PUBLIC_DISCUSSIONS_GET_SUCCESS = "PUBLIC_DISCUSSIONS_GET_SUCCESS";
export const publicDiscussionsGetSuccess = (payload) => ({
  type: PUBLIC_DISCUSSIONS_GET_SUCCESS,
  payload,
});

export const PRIVATE_DISCUSSIONS_GET_ATTEMPT =
  "PRIVATE_DISCUSSIONS_GET_ATTEMPT";
export const privateDiscussionsGet = (payload) => ({
  type: PRIVATE_DISCUSSIONS_GET_ATTEMPT,
  payload,
});
export const PRIVATE_DISCUSSIONS_GET_SUCCESS =
  "PRIVATE_DISCUSSIONS_GET_SUCCESS";
export const privateDiscussionsGetSuccess = (payload) => ({
  type: PRIVATE_DISCUSSIONS_GET_SUCCESS,
  payload,
});

export const NOTIFICATIONS_GET_ATTEMPT = "NOTIFICATIONS_GET_ATTEMPT";
export const notificationsGet = (payload) => ({
  type: NOTIFICATIONS_GET_ATTEMPT,
  payload,
});
export const NOTIFICATIONS_GET_SUCCESS = "NOTIFICATIONS_GET_SUCCESS";
export const notificationsGetSuccess = (payload) => ({
  type: NOTIFICATIONS_GET_SUCCESS,
  payload,
});

export const MY_TASKS_NOTIFICATIONS_GET_ATTEMPT =
  "MY_TASKS_NOTIFICATIONS_GET_ATTEMPT";
export const myTasksNotificationsGet = (payload) => ({
  type: MY_TASKS_NOTIFICATIONS_GET_ATTEMPT,
  payload,
});
export const MY_TASKS_NOTIFICATIONS_GET_SUCCESS =
  "MY_TASKS_NOTIFICATIONS_GET_SUCCESS";
export const myTasksNotificationsSuccess = (payload) => ({
  type: MY_TASKS_NOTIFICATIONS_GET_SUCCESS,
  payload,
});

export const MY_TASKS_NOTIFICATIONS_DELETE_ATTEMPT =
  "MY_TASKS_NOTIFICATIONS_DELETE_ATTEMPT";
export const myTasksNotificationsDelete = (payload) => ({
  type: MY_TASKS_NOTIFICATIONS_DELETE_ATTEMPT,
  payload,
});

export const MY_TASKS_NOTIFICATIONS_DELETE_SUCCESS =
  "MY_TASKS_NOTIFICATIONS_DELETE_SUCCESS";
export const myTasksNotificationsDeleteSuccess = (payload) => ({
  type: MY_TASKS_NOTIFICATIONS_DELETE_SUCCESS,
  payload,
});

export const DISCUSSION_SAVE_ATTEMPT = "DISCUSSION_SAVE_ATTEMPT";
export const discussionSave = (payload) => ({
  type: DISCUSSION_SAVE_ATTEMPT,
  payload,
});

export const PUBLIC_DISCUSSION_SAVE_SUCCESS = "PUBLIC_DISCUSSION_SAVE_SUCCESS";
export const publicDiscussionSaveSuccess = (payload) => ({
  type: PUBLIC_DISCUSSION_SAVE_SUCCESS,
  payload,
});
export const PRIVATE_DISCUSSION_SAVE_SUCCESS =
  "PRIVATE_DISCUSSION_SAVE_SUCCESS";
export const privateDiscussionSaveSuccess = (payload) => ({
  type: PRIVATE_DISCUSSION_SAVE_SUCCESS,
  payload,
});

export const SEND_REPLY_ATTEMPT = "SEND_REPLY_ATTEMPT";
export const sendReplyAttempt = (payload) => ({
  type: SEND_REPLY_ATTEMPT,
  payload,
});
export const SEND_REPLY_SUCCESS = "SEND_REPLY_SUCCESS";
export const sendReplySuccess = (payload) => ({
  type: SEND_REPLY_SUCCESS,
  payload,
});
export const SEND_REPLY_ERROR = "SEND_REPLY_ERROR";
export const sendReplyError = (payload) => ({
  type: SEND_REPLY_ERROR,
  payload,
});

export const PROJECT_DISCUSSIONS_GET_ATTEMPT =
  "PROJECT_DISCUSSIONS_GET_ATTEMPT";
export const projectDiscussionsGet = (payload) => ({
  type: PROJECT_DISCUSSIONS_GET_ATTEMPT,
  payload,
});
export const PROJECT_DISCUSSIONS_GET_SUCCESS =
  "PROJECT_DISCUSSIONS_GET_SUCCESS";
export const projectDiscussionsGetSuccess = (payload) => ({
  type: PROJECT_DISCUSSIONS_GET_SUCCESS,
  payload,
});

export const PROJECT_DISCUSSION_SAVE_ATTEMPT =
  "PROJECT_DISCUSSION_SAVE_ATTEMPT";
export const projectDiscussionSave = (payload) => ({
  type: PROJECT_DISCUSSION_SAVE_ATTEMPT,
  payload,
});

export const PROJECT_DISCUSSIONS_RESET = "PROJECT_DISCUSSIONS_RESET";
export const projectDiscussionsReset = () => ({
  type: PROJECT_DISCUSSIONS_RESET,
});

export const USER_TEAMS_GET_ATTEMPT = "USER_TEAMS_GET_ATTEMPT";
export const userTeamsGet = (payload) => ({
  type: USER_TEAMS_GET_ATTEMPT,
  payload,
});
export const USER_TEAMS_GET_SUCCESS = "USER_TEAMS_GET_SUCCESS";
export const userTeamsGetSuccess = (payload) => ({
  type: USER_TEAMS_GET_SUCCESS,
  payload,
});

export const PATIENT_ORG_DISCUSSIONS_GET_ATTEMPT =
  "PATIENT_ORG_DISCUSSIONS_GET_ATTEMPT";
export const patientOrgDiscussionsGet = (payload) => ({
  type: PATIENT_ORG_DISCUSSIONS_GET_ATTEMPT,
  payload,
});
export const PATIENT_ORG_DISCUSSIONS_GET_SUCCESS =
  "PATIENT_ORG_DISCUSSIONS_GET_SUCCESS";
export const patientOrgDiscussionsGetSuccess = (payload) => ({
  type: PATIENT_ORG_DISCUSSIONS_GET_SUCCESS,
  payload,
});

export const PATIENT_ORG_DISCUSSION_SAVE_ATTEMPT =
  "PATIENT_ORG_DISCUSSION_SAVE_ATTEMPT";
export const patientOrgDiscussionSaveAttempt = (payload) => ({
  type: PATIENT_ORG_DISCUSSION_SAVE_ATTEMPT,
  payload,
});
export const PATIENT_ORG_DISCUSSION_SAVE_SUCCESS =
  "PATIENT_ORG_DISCUSSION_SAVE_SUCCESS";
export const patientOrgDiscussionSaveSuccess = (payload) => ({
  type: PATIENT_ORG_DISCUSSION_SAVE_SUCCESS,
  payload,
});

export const COMPANY_DISCUSSIONS_GET_ATTEMPT =
  "COMPANY_DISCUSSIONS_GET_ATTEMPT";
export const companyDiscussionsGet = (payload) => ({
  type: COMPANY_DISCUSSIONS_GET_ATTEMPT,
  payload,
});
export const COMPANY_DISCUSSIONS_GET_SUCCESS =
  "COMPANY_DISCUSSIONS_GET_SUCCESS";
export const companyDiscussionsGetSuccess = (payload) => ({
  type: COMPANY_DISCUSSIONS_GET_SUCCESS,
  payload,
});

export const COMPANY_DISCUSSION_SAVE_ATTEMPT =
  "COMPANY_DISCUSSION_SAVE_ATTEMPT";
export const companyDiscussionSave = (payload) => ({
  type: COMPANY_DISCUSSION_SAVE_ATTEMPT,
  payload,
});

export const EVENT_DISCUSSIONS_GET_ATTEMPT = "EVENT_DISCUSSIONS_GET_ATTEMPT";
export const eventDiscussionsGet = (payload) => ({
  type: EVENT_DISCUSSIONS_GET_ATTEMPT,
  payload,
});
export const EVENT_DISCUSSIONS_GET_SUCCESS = "EVENT_DISCUSSIONS_GET_SUCCESS";
export const eventDiscussionsGetSuccess = (payload) => ({
  type: EVENT_DISCUSSIONS_GET_SUCCESS,
  payload,
});

export const EVENT_DISCUSSION_SAVE_ATTEMPT = "EVENT_DISCUSSION_SAVE_ATTEMPT";
export const eventDiscussionSave = (payload) => ({
  type: EVENT_DISCUSSION_SAVE_ATTEMPT,
  payload,
});

export const EVENT_DISCUSSIONS_CLEAR = "EVENT_DISCUSSIONS_CLEAR";
export const eventDiscussionClear = (payload) => ({
  type: EVENT_DISCUSSIONS_CLEAR,
  payload,
});

export const ADD_DISCUSSION_MEMBERS_ATTEMPT = "ADD_DISCUSSION_MEMBERS_ATTEMPT";
export const addDiscussionMembers = (payload) => ({
  type: ADD_DISCUSSION_MEMBERS_ATTEMPT,
  payload,
});

export const ADD_DISCUSSION_MEMBERS_SUCCESS = "ADD_DISCUSSION_MEMBERS_SUCCESS";
export const addDiscussionMembersSuccess = (payload) => ({
  type: ADD_DISCUSSION_MEMBERS_SUCCESS,
  payload,
});

export const ERROR_CATCH_SUCCESS = "ERROR_CATCH_SUCCESS";
export const errorCatchSuccess = (payload) => ({
  type: ERROR_CATCH_SUCCESS,
  payload,
});

export const SET_SELECTED_DISCUSSION = "SET_SELECTED_DISCUSSION";
export const setSelectedDiscussion = (payload) => ({
  type: SET_SELECTED_DISCUSSION,
  payload,
});

export const WS_NEW_PROJECT_DISCUSSION = "WS_NEW_PROJECT_DISCUSSION";
export const setNewWSProjectDiscussion = (payload) => ({
  type: WS_NEW_PROJECT_DISCUSSION,
  payload,
});

export const WS_NEW_EVENT_DISCUSSION = "WS_NEW_EVENT_DISCUSSION";
export const setNewWSEventDiscussion = (payload) => ({
  type: WS_NEW_EVENT_DISCUSSION,
  payload,
});

export const WS_NEW_DISCUSSION = "WS_NEW_DISCUSSION";
export const setNewWSDiscussion = (payload) => ({
  type: WS_NEW_DISCUSSION,
  payload,
});

export const WS_NEW_DISCUSSION_MSG = "WS_NEW_DISCUSSION_MSG";
export const setNewWSDiscussionMsg = (payload) => ({
  type: WS_NEW_DISCUSSION_MSG,
  payload,
});

export const RESET_MESSAGES_COUNT = "RESET_MESSAGES_COUNT";
export const resetMsgsCount = () => ({
  type: RESET_MESSAGES_COUNT,
});

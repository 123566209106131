import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loading } from "modules/primitives/index";
import classNames from "classnames";
import { useSnackbar } from "notistack";

import OverviewBlock from "./overviewBlock/index";
import MainProjectsBlock from "./mainProjectsBlock/index";

import PostsBlock from "./postsBlock/postsBlock.component";
import CompaniesBlock from "./companiesBlock/index";
import KyndrylCompaniesBlock from "./kyndrylCompaniesBlock/index";
import { themesGetIndividualAttempt } from "../../themesMainPage/themesMain.action";
import MembersBlock from "./membersBlock/index";
import styles from "./styles.module.scss";
import RelatedProjectsBlock from "./relatedProjectsBlock/index";
import EventsComponent from "./eventsBlock";
import RelatedIdeasComponent from "./relatedIdeas/relateIdeas.component";
import FordMetricsBlock from "./themesFordMetrics/index";
import FordSMeBlock from "./fordSMeBlock/index";
import RelatedSolutionsComponent from "./relatedSolutions/relatedSolutions.component";

const OverviewTab = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesPostsReducer: { updatePostsAcross },
      themesMainReducer: { currentThemeData, loadingIndividualTheme },
      themesOverviewReducer: { loadingCompany },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (updatePostsAcross) {
      dispatch(
        themesGetIndividualAttempt({
          id: currentThemeData?.id,
          enqueueSnackbar,
        })
      );
    }
  }, [updatePostsAcross]);

  const isItMediumDevice = () => {
    return window.screen.width <= 950;
  };

  const checkIfEventsConnected = () => {
    if (selectedCouncil?.settings?.events_connected === false) {
      return false;
    }

    return (
      selectedCouncil?.settings.theme_events_enabled ||
      selectedCouncil?.settings.theme_events_enabled === undefined
    );
  };

  const checkIfIdeasConnected = () => {
    if (selectedCouncil?.settings?.ideas_connected === false) {
      return false;
    }

    return (
      selectedCouncil?.settings.theme_ideas_enabled ||
      selectedCouncil?.settings.theme_ideas_enabled === undefined
    );
  };

  return (
    <div>
      {loadingIndividualTheme ? (
        <Loading />
      ) : (
        <div className={styles.overviewContainer}>
          {isItMediumDevice() ? (
            <div className={classNames("row")}>
              <OverviewBlock
                currentThemeData={currentThemeData}
                selectedCouncil={selectedCouncil}
                loadingCompany={loadingCompany}
              />
              <div className={styles.themeSettings}>
                {/*  */}
                {(selectedCouncil?.traction_tag !== "kyndryl" &&
                  selectedCouncil?.settings.theme_companies_enabled) ||
                selectedCouncil?.settings.theme_companies_enabled ===
                  undefined ? (
                  <CompaniesBlock themeId={currentThemeData?.id} />
                ) : (
                  <></>
                )}
                {selectedCouncil?.traction_tag === "ford" ? (
                  <FordSMeBlock
                    themeId={currentThemeData?.id}
                    companyListFromOverview={currentThemeData?.companies}
                  />
                ) : (
                  <></>
                )}
                {selectedCouncil?.settings.theme_followers_enabled ||
                selectedCouncil?.settings.theme_followers_enabled ===
                  undefined ? (
                  <MembersBlock
                    memberListFromOverview={currentThemeData?.members}
                    themeId={currentThemeData?.id}
                    className={styles.smallBlock}
                  />
                ) : (
                  <></>
                )}
                {session?.council_role === "company" ? null : selectedCouncil
                    ?.settings.theme_projects_enabled ||
                  selectedCouncil?.settings.theme_projects_enabled ===
                    undefined ? (
                  <RelatedProjectsBlock themeId={currentThemeData?.id} />
                ) : (
                  <></>
                )}
                {selectedCouncil?.traction_tag === "ford" ? (
                  <RelatedSolutionsComponent
                    themeId={currentThemeData?.id}
                    companyListFromOverview={currentThemeData?.companies}
                  />
                ) : (
                  <></>
                )}
                {checkIfEventsConnected() ? (
                  <EventsComponent
                    themeId={currentThemeData?.id}
                    themeEvents={currentThemeData?.events}
                    className={styles.smallBlock}
                    currentThemeData={currentThemeData}
                  />
                ) : (
                  <></>
                )}
                {selectedCouncil?.traction_tag !== "gsk" &&
                  checkIfIdeasConnected() && (
                    <RelatedIdeasComponent
                      themeId={currentThemeData?.id}
                      ideasFromTheme={currentThemeData?.ideas}
                      className={styles.smallBlock}
                    />
                  )}
              </div>
              {selectedCouncil?.traction_tag === "ford" ? (
                <FordMetricsBlock
                  themeId={currentThemeData?.id}
                  companyListFromOverview={currentThemeData?.companies}
                />
              ) : (
                <></>
              )}
              {selectedCouncil?.settings.theme_posts_enabled ||
              selectedCouncil?.settings.theme_posts_enabled === undefined ? (
                <PostsBlock themeId={currentThemeData?.id} />
              ) : (
                <></>
              )}
            </div>
          ) : (
            // </div>
            <div className={classNames("row")}>
              <div
                className={classNames(
                  "col-sm-12 col-md-9",
                  styles.leftOverviewWindow
                )}
              >
                <OverviewBlock
                  currentThemeData={currentThemeData}
                  selectedCouncil={selectedCouncil}
                  loadingCompany={loadingCompany}
                />
                {selectedCouncil?.traction_tag === "ford" ? (
                  <FordMetricsBlock
                    themeId={currentThemeData?.id}
                    companyListFromOverview={currentThemeData?.companies}
                  />
                ) : (
                  <></>
                )}
                {selectedCouncil?.traction_tag === "kyndryl" ? (
                  <KyndrylCompaniesBlock
                    themeId={currentThemeData?.id}
                    companyListFromOverview={currentThemeData?.companies}
                  />
                ) : (
                  <></>
                )}
                {selectedCouncil?.settings.project_view_type === "main" ? (
                  <MainProjectsBlock currentThemeData={currentThemeData} />
                ) : null}
                {selectedCouncil?.settings.theme_posts_enabled ||
                selectedCouncil?.settings.theme_posts_enabled === undefined ? (
                  <PostsBlock themeId={currentThemeData?.id} />
                ) : (
                  <></>
                )}
              </div>
              <div
                className={classNames(
                  "col-sm-12 col-md-3",
                  styles.leftOverviewWindow
                )}
              >
                <div className={styles.rightWindow}>
                  {(selectedCouncil?.traction_tag !== "kyndryl" &&
                    selectedCouncil?.settings.theme_companies_enabled) ||
                  selectedCouncil?.settings.theme_companies_enabled ===
                    undefined ? (
                    <CompaniesBlock
                      themeId={currentThemeData?.id}
                      companyListFromOverview={currentThemeData?.companies}
                    />
                  ) : (
                    <></>
                  )}

                  {selectedCouncil?.traction_tag === "ford" ? (
                    <FordSMeBlock
                      themeId={currentThemeData?.id}
                      companyListFromOverview={currentThemeData?.companies}
                    />
                  ) : (
                    <></>
                  )}
                  {selectedCouncil?.settings.theme_followers_enabled ||
                  selectedCouncil?.settings.theme_followers_enabled ===
                    undefined ? (
                    <MembersBlock
                      memberListFromOverview={currentThemeData?.members}
                      themeId={currentThemeData?.id}
                      className={styles.smallBlock}
                    />
                  ) : (
                    <></>
                  )}
                  {session?.council_role === "company" ? null : selectedCouncil
                      ?.settings.theme_projects_enabled ||
                    selectedCouncil?.settings.theme_projects_enabled ===
                      undefined ? (
                    <>
                      {selectedCouncil?.settings.project_view_type ===
                      "side" ? (
                        <RelatedProjectsBlock themeId={currentThemeData?.id} />
                      ) : null}
                    </>
                  ) : (
                    <></>
                  )}
                  {selectedCouncil?.traction_tag === "ford" ? (
                    <RelatedSolutionsComponent
                      themeId={currentThemeData?.id}
                      companyListFromOverview={currentThemeData?.companies}
                    />
                  ) : (
                    <></>
                  )}
                  {checkIfEventsConnected() ? (
                    <EventsComponent
                      themeId={currentThemeData?.id}
                      themeEvents={currentThemeData?.events}
                      className={styles.smallBlock}
                      currentThemeData={currentThemeData}
                    />
                  ) : (
                    <></>
                  )}
                  {selectedCouncil?.traction_tag !== "gsk" &&
                    checkIfIdeasConnected() && (
                      <RelatedIdeasComponent
                        themeId={currentThemeData?.id}
                        ideasFromTheme={currentThemeData?.ideas}
                        className={styles.smallBlock}
                      />
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default OverviewTab;

import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-dropdown-select";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { httpPost, pageMeta } from "../../httpCall";

import styles from "../../../scss/multiselect/multiselect.module.scss";
import "../../../scss/multiselect/multiselect.scss";

const projectRoles = {
  member: 'Project Member',
  manager: 'Project Manager',
  owner: 'Project Owner',
}

const UserMultiSelectSearch = (props) => {
  const {
    className,
    name,
    selectedValues = null,
    selectAll = false,
    selectAllItem = "",
    labelField,
    valueField,
    placeholder,
    disabled = false,
    onChange,
    hasAll = false,
    type = "",
    multi = true,
    sharedUsers,
    withoutOwner,
    onClear = null,
    mode,
    withClear = false,
    formProps: {
      errors = {},
      values = [],
      setFieldValue = null,
      setFieldTouched = null,
    } = {},
    filterBy,
    endpoint = "councils/users",
    preselectCurrentUser,
    teams_ids = [],
    projectId,
    ...rest
  } = props;
  const ref = useRef();
  const [dropValues, setDropValues] = useState([]);
  const [onDropChange, setOnDropChange] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [query, setQuery] = useState("*");
  const [results, setResults] = useState([]);
  const [pageSettings, setPageSettings] = useState({
    total: null,
    current: 1,
  });
  const [debouncedValue, setDebouncedValue] = useState("");

  const {
    authReducer: { session },
  } = useSelector((state) => state);

  useEffect(() => {
    if (onDropChange) {
      setOnDropChange(false);
      if (setFieldTouched) setFieldTouched(name);
      if (setFieldValue && name && valueField) {
        setFieldValue(name, dropValues[0][valueField]);
        setFieldTouched(name);
        values[name] = dropValues[0][valueField];
      }
    }
  }, [onDropChange]);

  useEffect(() => {
    if (hasAll) {
      const allObj = {
        id: "AllId",
        full_name:
          type === "Project" || type === "Event"
            ? `All ${type} ${type === "Event" ? "Attendees" : "Members"}`
            : "All",
      };

      if (selectedValues) {
        setDropValues([...selectedValues, allObj]);
      } else {
        setDropValues([allObj]);
      }
    } else if (!dropValues.length) {
      setDropValues(selectedValues || []);
    }
  }, [hasAll, selectedValues, type]);

  useEffect(() => {
    if (
      !hasAll &&
      selectedValues?.length &&
      dropValues &&
      !dropValues?.length
    ) {
      setDropValues([...selectedValues]);
    }
  }, []);

  const handleSearch = (evt) => {
    if (evt.search) {
      setQuery(evt.search);
    }
  };

  const requestNewData = (page, query, hasUserPreselect) => {
    setShowLoading(true);

    const url = `${endpoint}/search?page=${page || 1}&items=20`;
    let props = {};

    if (projectId) {
      props = {
        project_id: projectId,
        sort_attribute: "_score",
        sort_order: "desc",
      };
    }

    const data = {
      query,
      ...props,
      search: {
        projects_ids: projectId ? [projectId] : [],
        query,
        topic_ids: [],
        industry_ids: [],
        teams_ids,
        council_roles: [
          "ttp_administrator",
          "council_administrator",
          "standard",
          "viewer",
        ],
      },
    };

    httpPost({
      call: url,
      data,
    })
      .pipe()
      .subscribe((res) => {
        const pagemeta = pageMeta(res);

        setPageSettings({
          ...pageSettings,
          total: pagemeta.total,
          current: page,
        });

        let options = res.response
          .filter((item) =>
            filterBy
              ? !filterBy.some((item1) => item1.user_id === item.user_id)
              : item
          )
          .map((item) => ({
            ...item,
            [labelField]: item[labelField],
            [valueField]: item[valueField],
          }));

        if (preselectCurrentUser && hasUserPreselect) {
          const getCurrUser = options.find(user => user.id === session.id);
          setDropValues([getCurrUser || session]);
        }

        if (hasAll) {
          const newArr = [
            ...results,
            ...options,
            {
              id: "AllId",
              full_name: `All ${type} ${
                type === "Project" ? "Members" : "Attendees"
              }`,
            },
          ];
          const unique = [
            ...new Map(newArr.map((item) => [item.id, item])).values(),
          ];
          const newResults = unique.filter(
            (el) =>
              !sharedUsers?.find(
                (member) => member.id || member.user_id === el.id
              )
          );

          setResults(newResults);
        } else {
          const newArr = [...results, ...options];
          const unique = [
            ...new Map(newArr.map((item) => [item.id, item])).values(),
          ];
          const newResults = unique?.filter(
            (el) => !sharedUsers?.find((user) => user.id === el.id)
          );

          if (withoutOwner) {
            const newResultsWithOutOwner = newResults?.filter(
              (el) => session.id !== el.id
            );

            setResults(newResultsWithOutOwner);
          } else {
            setResults(newResults);
          }
        }

        setShowLoading(false);
      });
  };

  useEffect(() => {
    if (preselectCurrentUser) {
      requestNewData(1, '', true);
    }
  }, [preselectCurrentUser]);

  useEffect(() => {
    const trimmedQuerry = debouncedValue.trim().toLowerCase();
    if (trimmedQuerry.length > 0) {
      requestNewData(1, trimmedQuerry);
    }
  }, [debouncedValue, sharedUsers]);

  const handleSelectAllOnClick = () => {
    setDropValues([{ id: "all", name: selectAllItem }]);
    setOnDropChange(true);
  };

  const handleClearAllOnClick = () => {
    setDropValues([]);
    setOnDropChange(true);
  };

  const loadMore = () => {
    requestNewData(pageSettings.current + 1, query.trim().toLowerCase());
  };

  const dropdownRenderer = useCallback(
    ({ props, state, methods }) => {
      const regexp = new RegExp(state?.search.trim() || "", "i");
      const options = props.options.filter((item) =>
        regexp.test(item[props.searchBy] || item[props.labelField])
      );
      return (
        <div className="d-flex flex-column react-dropdown-select-dropdown">
          {showLoading ? (
            <span className="p-3">Loading results.</span>
          ) : (
            <>
              {options.length
                ? options.map((item, idx) => {
                    if (methods.isSelected(item)) {
                      return null;
                    }
                    const isSelected = state.values.indexOf(item) !== -1;

                    return (
                      <div
                        onClick={() =>
                          isSelected
                            ? methods.removeItem(item)
                            : methods.addItem(item)
                        }
                        key={`${item.name}-${idx}`}
                        className={`react-dropdown-select-item d-flex justify-content-between ${
                          isSelected
                            ? "react-dropdown-select-item-selected"
                            : ""
                        }`}
                      >
                        <span>
                          {item[props.labelField]}
                        </span>
                        {
                          item.project_role && (
                            <span style={{textTransform: 'capitalize'}}>
                              {projectRoles[item.project_role]}
                            </span>
                          )
                        }
                      </div>
                    );
                  })
                : "No data"}
              {pageSettings &&
              pageSettings.total > 20 &&
              pageSettings.total !== results?.length ? (
                <div className={styles.loadMore} onClick={loadMore}>
                  Load more
                </div>
              ) : null}
            </>
          )}
        </div>
      );
    },

    [showLoading, results]
  );

  const onNewSelection = (value) => {
    const hasAllValue = value.some((v) => v.id === "AllId");
    const hasAllDropValue = dropValues.some((v) => v.id === "AllId");
    const hasOtherValues = value.length > 1;
    let newValue;

    if (hasAllValue && hasOtherValues) {
      newValue = value.filter((v) => v.id !== "AllId");
    }

    if (hasAllValue && !hasAllDropValue) {
      newValue = value.filter((v) => v.id === "AllId");
    }

    if ((!hasAllValue && !hasAllDropValue) || (!hasAllValue && value.length >= 1)) {
      newValue = value;
    }

    if (!hasAllValue && hasOtherValues) {
      if (mode === "singular") {
        newValue = [value[value.length - 1]];
      }
    }

    if (!newValue) {
      return;
    }

    setOnDropChange(true);
    setDropValues(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(query);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [query]);

  return (
    <div className={styles.multiWrp}>
      <div
        className={classNames(
          styles.container,
          className,
          errors && errors[name] ? styles.error : ""
        )}
        ref={ref}
        id="customUserSearch"
      >
        {!onDropChange && (
          <Select
            multi={multi}
            closeOnSelect
            disabled={disabled}
            values={dropValues}
            className={styles.dorpWrp}
            options={results}
            labelField={labelField}
            valueField={valueField}
            placeholder={placeholder}
            searchFn={(args) => {
              handleSearch(args.state);
            }}
            dropdownRenderer={(innerProps, innerState, innerMethods) =>
              dropdownRenderer(innerProps, innerState, innerMethods)
            }
            onChange={onNewSelection}
            {...rest}
          />
        )}
      </div>
      {selectAll && (
        <div className={classNames(styles.multifunc, "caption small")}>
          <div onClick={(e) => handleSelectAllOnClick(e)}>Select all</div>{" "}
          <div onClick={(e) => handleClearAllOnClick(e)}>Clear all</div>
        </div>
      )}
      {withClear && (
        <div className={classNames(styles.multifunc, "caption small")}>
          <div
            onClick={(e) => {
              onClear && onClear();
              handleClearAllOnClick(e);
            }}
          >
            Clear
          </div>
        </div>
      )}
      <div className={classNames(styles.errorMsg, "caption small")}>
        {name && errors[name] ? errors[name] : ""}
      </div>
    </div>
  );
};

export default React.memo(UserMultiSelectSearch);

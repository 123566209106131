import { useLocation } from "react-router-dom";
import {
  addMonths,
  endOfQuarter,
  format,
  startOfQuarter,
  isBefore,
  startOfMonth,
} from "date-fns";

export const makeProjectField = field => {
  if (field.option_selected?.length && field.option_values?.length) {
    const oldSelectedValues = field.option_selected.filter(item => !field.option_values.some(item1 => item === (item1?.id || item1)));
    return {
      ...field,
      option_selected: field.option_selected.filter(item => field.option_values.some(item1 => item === (item1?.id || item1))),
      oldSelectedValues,
    }
  }

  return field;
}

export function getCookie(cName) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie);
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

export const makeFormikObj = (f) => ({
  ...f,
  values:
    f.field_type === "dropdown"
      ? f?.option_selected?.map((el) => ({
          name: el,
          id: el,
        })) || []
      : f?.field_value || f?.field_date || f?.field_number || "",
  field_value_id: f.project_field_template_id || f.id,
  option_values:
    f.option_values?.map((el) => ({
      name: el,
      id: el,
    })) || [],
});

const moveInsideArray = (input, from, to) => {
  let numberOfDeletedElm = 1;

  const elm = input.splice(from, numberOfDeletedElm)[0];

  numberOfDeletedElm = 0;

  input.splice(to, numberOfDeletedElm, elm);
  return input;
};

const nameToColour = (str = "") => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += `00${value.toString(16)}`.substr(-2);
  }
  return colour;
};

const lightenDarkenColor = (col, amt) => {
  col = col.replace(/^#/, "");
  if (col.length === 3)
    col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  let [r, g, b] = col.match(/.{2}/g);
  [r, g, b] = [
    parseInt(r, 16) + amt,
    parseInt(g, 16) + amt,
    parseInt(b, 16) + amt,
  ];

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? "0" : "") + r;
  const gg = (g.length < 2 ? "0" : "") + g;
  const bb = (b.length < 2 ? "0" : "") + b;

  return `#${rr}${gg}${bb}`;
};

const queryStringUrlReplacement = (url, param, value) => {
  const re = new RegExp(`[\\?&]${param}=([^&#]*)`);
  const match = re.exec(url);
  let delimiter;
  let newString;

  if (!match) {
    const hasQuestionMark = /\?/.test(url);
    delimiter = hasQuestionMark ? "&" : "?";
    newString = `${url + delimiter + param}=${value}`;
  } else {
    delimiter = match[0].charAt(0);
    newString = url.replace(re, `${delimiter + param}=${value}`);
  }

  return newString;
};

const useQuery = () => new URLSearchParams(useLocation().search);

const sortCountries = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const shouldDownload = (name = "") => {
  const nameToLower = name.toLowerCase();

  return (
    nameToLower.includes(".doc") ||
    nameToLower.includes(".docx") ||
    nameToLower.includes(".dotx") ||
    nameToLower.includes(".dot") ||
    nameToLower.includes(".rtf") ||
    nameToLower.includes(".txt") ||
    nameToLower.includes(".xlxs") ||
    nameToLower.includes(".xlsx") ||
    nameToLower.includes(".xls") ||
    nameToLower.includes(".csv") ||
    nameToLower.includes(".ppt") ||
    nameToLower.includes(".pdf")
  );
};

const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};

const queryParamStringToArray = (str) =>
  str.split(",").filter((s) => s !== "," && s.length);

export const handleUrlClick = (url) => {
  const absoluteUrl =
    url.startsWith("http://") || url.startsWith("https://")
      ? url
      : `http://${url}`;

  window.open(absoluteUrl, "_blank");
};

const makeWebSite = (url) => {
  const useless = ["http", "https", "//", "://"];
  const expStr = useless.join("|");

  return url
    .replace(new RegExp(`\\b(${expStr})\\b`, "gi"), " ")
    .replace(/\s{2,}/g, " ");
};

const scaleBetween = (unscaledNum, minAllowed, maxAllowed, min, max) =>
  ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) + minAllowed;

const rndInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

function waitForElm(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

function numberToUnits(labelValue) {
  const value = Math.abs(Number(labelValue));

  if (value >= 1.0e9) {
    return `${value / 1.0e9}B`;
  }

  if (value >= 1.0e6) {
    return `${value / 1.0e6}M`;
  }

  if (value >= 1.0e3) {
    return `${value / 1.0e3}K`;
  }

  return value;
}

const flat = (arr, prop) =>
  arr.reduce((prev, item) => {
    const isArray = Array.isArray(item[prop]);

    if (isArray) {
      return [...prev, item, ...flat(item[prop], prop)];
    }

    return [...prev, item];
  }, []);

function scrollStop(selector, callback, refresh = 66) {
  if (!callback || typeof callback !== "function") return;

  let isScrolling;

  if (selector) {
    document.querySelector(selector).addEventListener(
      "scroll",
      () => {
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(callback, refresh);
      },
      false
    );
  } else {
    window.addEventListener(
      "scroll",
      () => {
        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(callback, refresh);
      },
      false
    );
  }
}

const generateRandomIdByDate = () =>
  Number(String(Date.now() + Math.random()).split(".")[0]);

function truncateString(str, num) {
  if (str.length > num) {
    return `${str.slice(0, num)}...`;
  }

  return str;
}

const arrayElementsDuplicateValidation = (array, arrayCopy) => {
  let errorMessage = false;
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < arrayCopy.length; j++) {
      if (array[i] === arrayCopy[j] && i !== j) {
        errorMessage = true;
      }
    }
  }

  return !errorMessage;
};

function tConvert(time) {
  return new Date(`1970-01-01T${time}Z`).toLocaleTimeString("en-US", {
    timeZone: "UTC",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
}

function convertDateTimezone(date) {
  const dt = new Date(date);
  return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
}

function numberWithCommas(x = "") {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
}

function sanitizeName(name = "") {
  return name.replace(/ /g, "");
}

const pattern = /^([^\d]+)([\d,]+)$/;

function extractCurrencyAndValue(inputString) {
  const matches = inputString.match(pattern);
  if (matches) {
    const currencySymbol = matches[1];
    const numericValue = parseFloat(matches[2].replace(/,/g, ""));
    return { currencySymbol, numericValue };
  }
  return null;
}
function convertFilterIndexToValues(inputString, custTagsGroups = []) {
  if (custTagsGroups.length === 0) {
    return [];
  }
  const arrIds = [];
  if (inputString[0] && inputString[0].includes("all")) {
    const indexGroup = Number(inputString[0].split("_")[1]);
    custTagsGroups[indexGroup].custom_tags.map((tag) =>
      arrIds.push(tag.custom_tag_id)
    );
    return arrIds;
  }

  if (custTagsGroups && custTagsGroups.length) {
    inputString.map((ind) => {
      if (ind && ind.indexOf("_")) {
        const groupIndex = Number(ind.split("_")[0]);
        const tagIndex = Number(ind.split("_")[1]);
        const childIndex = Number(ind.split("_")[2]);

        if (childIndex || childIndex === 0) {
          arrIds.push(
            custTagsGroups[groupIndex].custom_tags[tagIndex].children[
              childIndex
            ].custom_tag_id
          );
        } else {
          arrIds.push(
            custTagsGroups[groupIndex].custom_tags[tagIndex].custom_tag_id
          );
        }
      }
    });
    return arrIds;
  }
  return [];
}

function convertFilterIndexCoordsToTags(inputString, custTagsGroups = []) {
  if (custTagsGroups.length === 0) {
    return [];
  }
  const arrIds = [];

  if (custTagsGroups && custTagsGroups.length) {
    inputString.map((ind) => {
      if (ind && ind.indexOf("_") && ind.indexOf("all") < 0) {
        const groupIndex = Number(ind.split("_")[0]);
        const tagIndex = Number(ind.split("_")[1]);
        const childIndex = Number(ind.split("_")[2]);
        if (childIndex || childIndex === 0) {
          arrIds.push({
            ...custTagsGroups[groupIndex].custom_tags[tagIndex].children[
              childIndex
            ],
            index: ind,
          });
        } else {
          arrIds.push({
            ...custTagsGroups[groupIndex].custom_tags[tagIndex],
            index: ind,
          });
        }
      }
    });

    return arrIds;
  }
  return [];
}

function convertTagIdToIndex(
  tagId,
  councilCustTagsGroups = [],
  groupId,
  parrentId
) {
  let groupIndex = 0;
  let tagIndex = 0;
  let childIndex = 0;

  councilCustTagsGroups.map((group, i) => {
    if (group.council_custom_tag_group_id === groupId) {
      groupIndex = i;
    }
  });
  for (
    let index = 0;
    index < councilCustTagsGroups[groupIndex].custom_tags.length;
    index++
  ) {
    if (
      councilCustTagsGroups[groupIndex].custom_tags[index].custom_tag_id ===
      (parrentId || tagId)
    ) {
      tagIndex = index;
    }
  }
  if (parrentId) {
    for (
      let index = 0;
      index <
      councilCustTagsGroups[groupIndex].custom_tags[tagIndex].children.length;
      index++
    ) {
      if (
        councilCustTagsGroups[groupIndex].custom_tags[tagIndex].children[index]
          .custom_tag_id === tagId
      ) {
        childIndex = index;
      }
    }
    return `${groupIndex}_${tagIndex}_${childIndex}`;
  }
  return `${groupIndex}_${tagIndex}`;
}

const makeStages = (startYear, numberOfQuartersPerYear = 4) => {
  const quarters = [];
  const endYear = new Date().getFullYear() + 1;
  for (let year = startYear; year <= endYear; year++) {
    for (let quarter = 1; quarter <= numberOfQuartersPerYear; quarter++) {
      const start = startOfQuarter(new Date(year, (quarter - 1) * 3, 1));
      const end = endOfQuarter(addMonths(start, 2));
      const quarterLabel = `Q${quarter} ${year}`;
      const dateString = `${quarterLabel}`;

      quarters.push({
        id: dateString,
        name: dateString,
        date: format(end, "yyyy-MM-dd"),
      });
    }
  }

  return quarters;
};

export const isANumber = (value) => {
  if (value === null || value === undefined || value === "") {
    return false;
  }
  const asNumber = Number(value);
  return !isNaN(asNumber) && typeof asNumber === "number";
};

const make4NextQuartersFromToday = () => {
  const currentDateObj = new Date();
  const quarters = [];
  const currentDate = startOfMonth(currentDateObj);

  let start = startOfQuarter(currentDateObj);
  const getYear = start.getFullYear();

  for (let i = 0; i < 4; i++) {
    const end = endOfQuarter(addMonths(start, 2));
    const quarterLabel = `Q${Math.floor(start.getMonth() / 3) + 1} ${getYear}`;
    const dateString = `${quarterLabel}`;

    if (isBefore(start, currentDate) || quarters.length < 3) {
      quarters.push({
        id: dateString,
        name: dateString,
        year: getYear,
        date: format(end, "yyyy-MM-dd"),
        index: i + 1,
      });
    } else {
      const nextYear = getYear + 1;
      const futureQuarterLabel = `Q${
        Math.floor(start.getMonth() / 3) + 1
      } ${nextYear}`;
      quarters.push({
        id: futureQuarterLabel,
        name: futureQuarterLabel,
        year: nextYear,
        date: format(endOfQuarter(addMonths(start, 2)), "yyyy-MM-dd"),
        index: i + 1,
      });
    }
    start = startOfQuarter(addMonths(start, 3));
  }

  return quarters;
};

const checkFirmenichUseCase = (councilTag, userRole) => {
  return !!(
    councilTag?.includes("firmenich") &&
    ["ttp_administrator", "council_administrator", "standard"].includes(
      userRole
    )
  );
};

function truncateHtml(html, maxLength) {
  let truncatedHtml = html;

  if (html.length > maxLength) {
    // Convert the HTML string to a DOM element
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;

    // Truncate the content preserving the HTML structure and inline styles
    let textContent = "";
    let charCount = 0;
    const walker = document.createTreeWalker(
      tempElement,
      NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT,
      null,
      false
    );

    while (walker.nextNode()) {
      const node = walker.currentNode;

      if (node.nodeType === Node.ELEMENT_NODE) {
        // Handle element nodes
        const tagName = node.tagName.toLowerCase();

        if (tagName === "br") {
          // Preserve line breaks
          textContent += "<br>";
        } else {
          // Preserve other elements and inline styles
          const outerHtml = node.outerHTML;
          const outerHtmlLength = outerHtml.length;

          if (charCount + outerHtmlLength > maxLength) {
            textContent += outerHtml.substring(0, maxLength - charCount);
            break;
          } else {
            textContent += outerHtml;
            charCount += outerHtmlLength;
          }
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        // Handle text nodes
        const nodeLength = node.textContent.length;

        if (charCount + nodeLength > maxLength) {
          textContent += node.textContent.substring(0, maxLength - charCount);
          break;
        } else {
          textContent += node.textContent;
          charCount += nodeLength;
        }
      }
    }

    truncatedHtml = textContent;
  }

  return truncatedHtml;
}

export const isEmptyHtmlElement = (htmlString) => {
  const hasHTMLTemplate = /<\/?[^>]+>/g.test(htmlString);

  if (!hasHTMLTemplate) {
    return htmlString?.length === 0;
  }

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;

  const element = tempDiv.firstChild;

  if (element) {
    const fragment = document.createDocumentFragment();
    while (element.firstChild) {
      fragment.appendChild(element.firstChild);
    }

    const brs = fragment.querySelectorAll("br");
    brs.forEach((br) => br.remove());

    return !fragment.textContent.trim() && !fragment.hasChildNodes();
  }

  return true;
};

export const makeFlattenTags = (tags, prop) => {
  let flatList = [];

  function recurse(topic) {
    flatList.push(topic);
    if (topic[prop]) {
      topic[prop].forEach((child) => recurse(child));
    }
  }

  tags.forEach((topic) => recurse(topic));

  return flatList;
};

export const checkIsStringWhenExpectArray = (value) => typeof value === 'string' || !value ? [] : value;

export {
  numberWithCommas,
  moveInsideArray,
  nameToColour,
  queryStringUrlReplacement,
  useQuery,
  sortCountries,
  shouldDownload,
  debounce,
  queryParamStringToArray,
  makeWebSite,
  scaleBetween,
  rndInt,
  lightenDarkenColor,
  waitForElm,
  numberToUnits,
  flat,
  scrollStop,
  generateRandomIdByDate,
  truncateString,
  arrayElementsDuplicateValidation,
  tConvert,
  convertDateTimezone,
  sanitizeName,
  extractCurrencyAndValue,
  convertFilterIndexToValues,
  convertTagIdToIndex,
  convertFilterIndexCoordsToTags,
  makeStages,
  checkFirmenichUseCase,
  make4NextQuartersFromToday,
  truncateHtml,
};

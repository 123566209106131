export const OptionType = {
  REGULAR: 0,
  DANGER: 1,
};

export const OptionSize = {
  SMALL: 0,
  MEDIUM: 1,
};

export const UserMenuOptions = [
  { val: "view", text: "View profile" },
  { val: "settings", text: "Settings" },
  { val: "tractionadmin", text: "Traction Admin" },
  { val: "policy", text: "Privacy Policy" },
  // { val: 'about', text: 'About' },
  { val: "terms", text: "Terms of Use" },
  { val: "logout", text: "Logout", type: OptionType.DANGER },
];

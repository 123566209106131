import React from "react";
import { CheckBox, CheckType } from "../../../../../primitives";
import styles from "./customTags.module.scss";

const CustomTagsChildsFilter = ({ handleOnChange, child, a, parentIndex }) => {
  return (
    <>
      <div key={child.custom_tag_id} className="d-flex align-items-center">
        <CheckBox
          checkType={CheckType.BLUE}
          isChecked={child.selected}
          onChange={(val) => {
            handleOnChange(val, child, a.custom_tag_id, parentIndex);
          }}
        />
        <span
          className={styles.checkboxName}
          onClick={(e) => {
            e.stopPropagation();
            handleOnChange(
              !child.selected,
              child,
              a.custom_tag_id,
              parentIndex
            );
          }}
        >
          {child.name}
        </span>
      </div>
    </>
  );
};

export default React.memo(CustomTagsChildsFilter);

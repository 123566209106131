import React, { useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter,
  useHistory,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { useIntercom } from "react-use-intercom";
import PrivacyPolicyComponent from "modules/privacyPolicy/";
import AdminRatingCategories from "modules/admin/adminRating/adminRatingCategories/adminRatingCategories.component";
import AdminProjectsComponent from "modules/admin/adminProjects";
import TractionAdmin from "modules/tractionAdmin/index";
import TermsComponent from "modules/terms/terms.component";
import ConfigureData from "modules/settings/integrationsSettings/configureData";
import ConfigureAccounts from "modules/settings/integrationsSettings/configureAccounts";
import ConfigureContacts from "modules/settings/integrationsSettings/configureContacts";
import { msalConfig } from "api/microsoft/authConfig";
import AdminCustomFieldsGroup from "modules/admin/adminCustomFieldsGroups/adminCustomFieldsGroup.component";
import AdminCustomFields from "modules/admin/adminCustomFieldsGroups/adminCustomFields/adminCustomFields.component";
import AdminCustomFieldsGroupTemplates from "modules/admin/adminCustomFieldsGroups/adminCustomFieldsGroupTemplates/adminCustomFieldsGroupTemplates.component";
import {
  getAllCompaniesAttempt,
  searchInCouncil,
  setChartsReqItIsInProgress,
} from "modules/companies/companyMain/companyMain.action";
import { councilGet } from "modules/council/council.action";
import ListsWrapper from "modules/lists";
import ReportingWrapper from "modules/reporting";
import GeneratedReport from "modules/reporting/pages/generatedReports";
import AdminProjectFields from "modules/admin/adminProjects/adminProjectFields";
import AdminDataExports from "modules/admin/adminDataExports/adminDataExports.component";
import RootSocketProvider from "application/rootSocketProvider";
import SolutionsComponent from "modules/solutions/solutionsMainPage/solutionsMain.component";
import AdminPlantsAndAreas from "modules/admin/adminPlantsAndAreas";
import AdminProjectGroups from "modules/admin/adminProjects/adminProjectGroups";
import { councilsGetByIdAttempt } from "modules/tractionAdmin/councils/councils.action";
import AdminProjectWizard from "modules/admin/adminProjects/adminProjectWizard";
import AdminProjectWizardSteps from "modules/admin/adminProjects/adminProjectWizard/wizardSteps";
import { projectStagesTemplatesGet } from "modules/projects/newProjectv3/newProjectV3.actions";
import EditCompanyComponent from "modules/admin/adminCompanies/editCompany/index";
import EditTeamComponent from "../../modules/admin/adminTeams/editTeam";
import HomeComponent from "../../modules/home";
import EventsComponent from "../../modules/events";
import ViewEventComponent from "../../modules/events/viewEvent";
import EventNewComponent from "../../modules/events/addEvent";
import CompaniesComponent from "../../modules/companies";
import IdeasWrapper from "../../modules/ideasWrapper";
import AdminTeamsComponent from "../../modules/admin/adminTeams";
import ContactsComponent from "../../modules/contacts";
import MediaComponent from "../../modules/media";
import AdminComponent from "../../modules/admin";
import AdminMembersComponent from "../../modules/admin/adminMembers";
import AdminMembersInvitedComponent from "../../modules/admin/adminMembers/adminMembersInvited.component";
import AdminCompaniesComponent from "../../modules/admin/adminCompanies";
import DiscussionsComponent from "../../modules/discussions";
import PeopleMainComponent from "../../modules/people/peopleMain";
import ProfileComponent from "../../modules/people/Profile";
import ProjectsComponent from "../../modules/projects";
import ErrorBoundary from "../../common/components/errorBoundary";
import styles from "./main.module.scss";
import Header from "../header";
import { ModalComponent } from "../modal";
import SliderWindowComponent from "../siderWindow";
import SideNavigationComponent from "../sideNavigation";
import { appMobileNaviSet, getRelationshipStatuses } from "../app.action";
import SettingsComponent from "../../modules/settings/settings.component";
import PatientOrganizationsComponent from "../../modules/patientOrganizations/patientOrganizations.component";
import AdminRating from "../../modules/admin/adminRating";
import AdminProjectTemplateComponent from "../../modules/admin/adminProjects/adminProjectTemplate/adminProjectTemplate.component";
import AdminTags from "../../modules/admin/adminTags";
import AdminApplicantsComponent from "../../modules/admin/adminApplicants";
import AdminApplicantsFormsComponent from "../../modules/admin/adminApplicants/forms";
import AdminApplicantsFormsCreateComponent from "../../modules/admin/adminApplicants/forms/addForm";
import AdminApplicantsFormsEditComponent from "../../modules/admin/adminApplicants/forms/editForm";
import AdminIntegrationsComponent from "../../modules/admin/adminIntegrations/index";
import AdminAgreementsComponent from "../../modules/admin/adminAgreements/adminAgreements.component";
import ViewApplication from "../../modules/admin/adminApplicants/viewApplication";
import AdminIdeas from "../../modules/admin/adminIdeas/adminIdeas.component";
import EditFormComponenet from "../../modules/admin/adminIdeas/form.component";
import IdeaProfileVotes from "../../modules/admin/adminIdeas/ideaProfileVotes";
import FetchData from "../../modules/settings/integrationsSettings/fetchData";
import ExportData from "../../modules/settings/integrationsSettings/configureExports/exportData";
import ThemesComponent from "../../modules/themes/themesMainPage/themesMain.component";
import ThemesIndividualPageComponent from "../../modules/themes/themesIndividualPage/index";
import ManageThemes from "../../modules/themes/themesIndividualPage/themeEdit";
import AdminThemes from "../../modules/admin/adminThemes/adminThemes.component";
import AdminThemesFields from "../../modules/admin/adminThemes/adminThemeFields.component";
import AdminThemesGroups from "../../modules/admin/adminThemes/adminThemeGroups.component";
import AdminThemesProjectsTable from "../../modules/admin/adminThemes/adminThemeProjectsTable.component";
import { getCookie } from "../../common/helper";

const msalInstance = new PublicClientApplication(msalConfig);

const INTERCOM_APP_ID = "vrh5mv0c";

const MainComponent = (props) => {
  // const [isFirstLogin, setIsFirstLogin] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const council = localStorage.getItem("council");
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { boot } = useIntercom();
  const {
    appReducer: { showMobileNavi, modalSetting },
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil?.id) {
      // try just for some testing to see if companies page will be faster
      if (window.location.pathname === "/home") {
        dispatch(
          searchInCouncil({
            data: '',
            council_id: selectedCouncil.id,
            mode: "companies",
            enqueueSnackbar,
            cb: () => {},
          })
        );
      }

      dispatch(
        councilsGetByIdAttempt({ id: selectedCouncil.id, enqueueSnackbar })
      );
      // stage templates will be used in all the places where is project creation
      // that\s why we need to have these from the start
      dispatch(
        projectStagesTemplatesGet({
          council_id: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil?.id]);

  useEffect(() => {
    if (history.location.pathname.indexOf("themes") === -1) {
      dispatch(getRelationshipStatuses());
    }
  }, []);

  useEffect(() => {
    const council = localStorage.getItem("council");
    const id = council ? JSON.parse(council)?.id : null;

    if (id && !selectedCouncil) {
      dispatch(
        councilGet({
          id,
          enqueueSnackbar,
          integrated_tokens: session?.integrated_tokens,
        })
      );
    }

    // if (selectedCouncil) {
    //   const timeout = setTimeout(() => {
    //     setIsLoading(false);
    //     clearTimeout(timeout);
    //   }, 100);
    // }
  }, [selectedCouncil]);

  useEffect(() => {
    const council = localStorage.getItem("council");

    // get default council details
    if (!council && session) {
      dispatch(
        councilGet({
          id: session.default_council.id,
          enqueueSnackbar,
          integrated_tokens: session?.integrated_tokens,
        })
      );
    }
  }, [session]);

  // we do this request as soon as we open the application to take all the companies
  // cause this is a huge request, and that helps us when open charts page to not wait
  // so much time for all companies response
  useEffect(() => {
    // don't make this request if we open the app directly from that page
    // if (!selectedCouncil) {
    //   return;
    // }
    const c = JSON.parse(council);
    if (c && (c.name === "Ford" || c.traction_tag === "ford")) {
      return;
    }

    if (
      window.location.pathname === "/companies" &&
      window.location.search?.includes("layout=2")
    )
      return;

    const data = {
      query: "",
      search: {
        query: "",
      },
    };

    dispatch(setChartsReqItIsInProgress(true));
    dispatch(
      getAllCompaniesAttempt({
        call: "companies/charts?ultrafast=true",
        data,
        enqueueSnackbar,
      })
    );
  }, []);

  const handleGetVisitorId = (userString) => {
    // const user = JSON.parse(userString);
    boot({
      app_id: INTERCOM_APP_ID,
      user_id: session?.id || userString,
      email: session?.email,
      name: session?.first_name + session?.last_name,
    });
  };

  useEffect(() => {
    const user_id = getCookie("user_id_");
    if (!session) {
      if (user_id) {
        handleGetVisitorId(user_id);
        // dispatch(setUser(JSON.parse(user)));
      } else {
        history.push("/login");
      }
    } else {
      handleGetVisitorId(user_id);
    }
  }, [session]);

  const handleBackClick = () => {
    dispatch(appMobileNaviSet(false));
  };

  // useEffect(() => {
  //   if (session) {
  //     setIsFirstLogin(session.password_updated_at);
  //   }
  // }, [session]);

  // if (isLoading) {
  //   return (
  //     <div className={styles.mainWrp}>
  //       <div className={`d-flex flex-grow-1 w-100 justify-content-center align-items-center ${styles.heightFull}`}>
  //         <LoadingComponent customText="Preparing your view..." showBigSize/>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <div className={styles.mainWrp}>
      {session && selectedCouncil?.id && (
        <RootSocketProvider
          session={session}
          selectedCouncil={selectedCouncil}
        />
      )}
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          {/* {isFirstLogin && <Header {...props} />} */}
          <Header {...props} />
          <div className={styles.mainCon}>
            <ErrorBoundary>
              <Switch>
                {/* <Route exact path={"/projects"} component={ProjectComponent} /> */}
                <Route path="/home" component={HomeComponent} />
                <Route path="/solutions">
                  <Switch>
                    <Route
                      exact
                      path="/solutions"
                      component={SolutionsComponent}
                    />
                  </Switch>
                </Route>
                <Route path="/themes">
                  <Switch>
                    <Route exact path="/themes" component={ThemesComponent} />
                    <Route
                      exact
                      path="/themes/:id"
                      component={ThemesComponent}
                    />
                    <Route
                      exact
                      path="/themes/individual_page/:id/overview"
                      component={ThemesIndividualPageComponent}
                    />
                    <Route
                      exact
                      path="/themes/individual_page/:id/pipeline"
                      component={ThemesIndividualPageComponent}
                    />
                    <Route
                      exact
                      path="/themes/individual_page/:id/editTheme"
                      component={ManageThemes}
                    />
                    <Route
                      exact
                      path="/themes/individual_page/:id/documents"
                      component={ThemesIndividualPageComponent}
                    />
                    <Route
                      exact
                      path="/themes/individual_page/:id/projects"
                      component={ThemesIndividualPageComponent}
                    />
                  </Switch>
                </Route>
                <Route path="/events">
                  <Switch>
                    <Route
                      exact
                      path="/events/new"
                      component={EventNewComponent}
                    />
                    <Route
                      exact
                      path="/events/id/:id"
                      component={ViewEventComponent}
                    />
                    <Route path="/events" component={EventsComponent} />
                  </Switch>
                </Route>
                <Route
                  path="/companies"
                  render={(props) => <CompaniesComponent {...props} />}
                />
                <Route path="/reporting" component={ReportingWrapper} />
                <Route path="/generated-report" component={GeneratedReport} />
                <Route path="/list/:list_id" component={ListsWrapper} />
                <Route path="/ideas" render={() => <IdeasWrapper />} />
                <Route
                  path="/list/:list_id"
                  render={(props) => <CompaniesComponent {...props} />}
                />
                <Route
                  path="/patient_organizations"
                  component={PatientOrganizationsComponent}
                />
                <Route path="/projects" component={ProjectsComponent} />
                <Route path="/people">
                  <Switch>
                    <Route
                      exact
                      path="/people/internal/profile/:id"
                      component={ProfileComponent}
                    />
                    <Route path="/people" component={PeopleMainComponent} />
                  </Switch>
                </Route>
                <Route path="/contacts" component={ContactsComponent} />
                <Route path="/media" component={MediaComponent} />
                <Route path="/admin" component={AdminComponent}>
                  <Switch>
                    <Route
                      exact
                      path="/admin/companies"
                      component={AdminCompaniesComponent}
                    />
                    <Route
                      exact
                      path="/admin/companies/edit/:id"
                      component={EditCompanyComponent}
                    />
                    <Route
                      exact
                      path="/admin/organizations/edit/:id"
                      component={EditCompanyComponent}
                    />
                    <Route
                      exact
                      path="/admin/organizations"
                      component={AdminCompaniesComponent}
                    />
                    <Route
                      exact
                      path="/admin/members"
                      component={AdminMembersComponent}
                    />
                    <Route
                      exact
                      path="/admin/members/invited"
                      component={AdminMembersInvitedComponent}
                    />
                    {/* <Route
                      exact
                      path="/admin/members/edit/:id"
                      component={EditMemberComponent}
                    /> */}
                    <Route
                      exact
                      path="/admin/teams"
                      component={AdminTeamsComponent}
                    />
                    <Route
                      exact
                      path="/admin/teams/edit/:id"
                      component={EditTeamComponent}
                    />
                    <Route
                      exact
                      path="/admin/ratings"
                      component={AdminRating}
                    />
                    <Route
                      exact
                      path="/admin/ratings/:id"
                      component={AdminRatingCategories}
                    />
                    <Route
                      exact
                      path="/admin/projects"
                      component={AdminProjectsComponent}
                    />
                    <Route
                      exact
                      path="/admin/projects/fields"
                      children={
                        <AdminProjectFields instance="topProjectsFields" />
                      }
                    />
                    <Route
                      exact
                      path="/admin/projects/fields/:id"
                      children={
                        <AdminProjectFields instance="lowLevelProjectsFields" />
                      }
                    />
                    <Route
                      exact
                      path="/admin/projects/stages/:id"
                      component={AdminProjectTemplateComponent}
                    />
                    <Route
                      exact
                      path="/admin/projects/tasks/:id"
                      component={AdminProjectTemplateComponent}
                    />
                    <Route
                      exact
                      path="/admin/projects/setup/:id"
                      component={AdminProjectWizard}
                    />
                    <Route
                      exact
                      path="/admin/projects/setup/:id/:wizardId"
                      children={<AdminProjectWizardSteps />}
                    />
                    <Route
                      exact
                      path="/admin/projects/groups/:id"
                      component={AdminProjectGroups}
                    />
                    <Route
                      exact
                      path="/admin/projects/groups/:id/:groupId"
                      children={<AdminProjectGroups instance="lowLevel" />}
                    />
                    <Route exact path="/admin/tags" component={AdminTags} />
                    <Route
                      exact
                      path="/admin/applicants"
                      component={AdminApplicantsComponent}
                    />
                    <Route
                      exact
                      path="/admin/applicants/:id/view"
                      component={ViewApplication}
                    />
                    <Route
                      exact
                      path="/admin/applicants/forms"
                      component={AdminApplicantsFormsComponent}
                    />
                    <Route
                      exact
                      path="/admin/applicants/forms/create"
                      component={AdminApplicantsFormsCreateComponent}
                    />
                    <Route
                      exact
                      path="/admin/applicants/forms/:id/edit"
                      component={AdminApplicantsFormsEditComponent}
                    />
                    <Route
                      exact
                      path="/admin/integrations"
                      component={AdminIntegrationsComponent}
                    />
                    <Route
                      exact
                      path="/admin/custom-fields"
                      component={AdminCustomFields}
                    />
                    <Route
                      exact
                      path="/admin/custom-fields/groups"
                      component={AdminCustomFieldsGroup}
                    />
                    <Route
                      exact
                      path="/admin/custom-fields/custom-field-groups/:groupName/:groupId"
                      component={AdminCustomFieldsGroupTemplates}
                    />
                    <Route exact path="/admin/ideas" component={AdminIdeas} />
                    <Route
                      exact
                      path="/admin/ideas/:id"
                      component={IdeaProfileVotes}
                    />
                    <Route
                      exact
                      path="/admin/forms/edit/:id"
                      component={EditFormComponenet}
                    />
                    <Route exact path="/admin" component={AdminComponent} />
                    <Route
                      exact
                      path="/admin/agreements"
                      component={AdminAgreementsComponent}
                    />
                    <Route exact path="/admin" component={AdminComponent} />
                    <Route
                      exact
                      path="/admin/data_exports"
                      component={AdminDataExports}
                    />
                    <Route exact path="/admin/themes" component={AdminThemes} />
                    <Route
                      exact
                      path="/admin/themes/groups"
                      component={AdminThemesGroups}
                    />
                    <Route
                      exact
                      path="/admin/themes/fields"
                      component={AdminThemesFields}
                    />
                    <Route
                      exact
                      path="/admin/themes/projectsTable"
                      component={AdminThemesProjectsTable}
                    />
                    <Route
                      exact
                      path="/admin/plants-and-areas"
                      component={AdminPlantsAndAreas}
                    />
                  </Switch>
                </Route>
                <Route path="/tractionadmin" component={TractionAdmin} />
                <Route
                  exact
                  path="/tractionadmin/companies/edit/:id"
                  component={EditCompanyComponent}
                />
                <Redirect from="/tractionadmin/" to="/tractionadmin/councils" />
                <Route path="/discussions" component={DiscussionsComponent} />
                <Route path="/settings" component={SettingsComponent} />
                <Route path="/setup" component={SettingsComponent} />
                <Route
                  path="/privacy_policy"
                  component={PrivacyPolicyComponent}
                />
                <Route
                  exact
                  path="/salesforce/configureData"
                  component={ConfigureData}
                />
                <Route
                  exact
                  path="/salesforce/configureAccounts"
                  component={ConfigureAccounts}
                />
                <Route
                  exact
                  path="/salesforce/configureContacts"
                  component={ConfigureContacts}
                />
                <Route
                  exact
                  path="/salesforce/fetchData"
                  component={FetchData}
                />
                <Route
                  exact
                  path="/salesforce/exportData"
                  component={ExportData}
                />
                <Route path="/terms" component={TermsComponent} />
                <Redirect from="/" to="/home" />
              </Switch>
            </ErrorBoundary>
          </div>
          <div
            className={`${styles.modelWrp} ${
              modalSetting.fullScreen
                ? `${styles.fullScreenModal} traction-full-screen-modal`
                : ""
            }`}
          >
            {modalSetting.show && (<ModalComponent />)}
          </div>
          <SliderWindowComponent />
          <SideNavigationComponent {...props} />
        </BrowserRouter>
      </MsalProvider>
      {/* <IntercomProvider appId={INTERCOM_APP_ID} autoBoot></IntercomProvider> */}
      <div
        className={classNames(
          styles.transBackground,
          showMobileNavi && styles.show
        )}
        onClick={handleBackClick}
      />
    </div>
  );
};

export default React.memo(MainComponent);

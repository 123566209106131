import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { themesIdeasDeleteAttempt } from "modules/themes/themesIndividualPage/themesOverviewTab/overviewTab.action";

import { useModelPopup } from "../../../../../common/hooks";
import { Icon, smSize } from "../../../../../common/icon";
import AddIdeaTheme from "./addThemeModal";
import AddCompanyIcon from "../../../../../assets/icn-table-add.svg";
import mainStyles from "../../../ideasWrapper.module.scss";
import styles from "./styles.module.scss";

const IdeaProfileThemes = ({ selectedIdea, canEdit, selectedCouncil }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    authReducer: { session },
  } = useSelector((state) => state);

  const handleAddTheme = () => {
    popup.show({
      title: `Add ${
        selectedCouncil?.settings?.themes_display_name_singular || "Theme"
      }`,
      show: true,
      height: "300",
      width: "540",
      component: (
        <AddIdeaTheme selectedIdea={selectedIdea} patientOrg={false} />
      ),
    });
  };

  const handleRemoveTheme = (elem) => {
    dispatch(
      themesIdeasDeleteAttempt({
        id: elem.theme_id,
        ideaId: selectedIdea.id,
        enqueueSnackbar,
      })
    );
  };

  return (
    <div className={`d-flex flex-column mt-4 mb-4 ${mainStyles.themesBlock}`}>
      <div
        className={`d-flex align-items-center justify-content-between ${mainStyles.blockHeader}`}
      >
        <span className={mainStyles.blockTitle}>
          Related {selectedCouncil?.settings?.themes_display_name || "Themes"}
        </span>
        {canEdit && (
          <img
            className={mainStyles.addIcon}
            onClick={handleAddTheme}
            src={AddCompanyIcon}
            alt="Add new theme"
          />
        )}
      </div>
      <div className={`${styles.themesContent} ${mainStyles.blockContent}`}>
        {!selectedIdea?.themes?.length ? (
          <div className={mainStyles.stub}>
            No related{" "}
            {selectedCouncil?.settings?.themes_display_name || "themes"} yet.
          </div>
        ) : (
          selectedIdea?.themes?.map((theme) => (
            <div className={styles.themeContainer}>
              <Link
                key={theme.theme_id}
                className={styles.themesUrl}
                to={`/themes/individual_page/${theme.theme_id}/overview`}
              >
                {theme.name}
              </Link>
              {canEdit && (
                <Icon
                  {...smSize}
                  icon="icn-button-delete"
                  className={styles.deleteIcon}
                  onClick={() => handleRemoveTheme(theme)}
                />
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default React.memo(IdeaProfileThemes);

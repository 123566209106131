import React from "react";
import styles from "./error.module.scss";

const Error = () => (
  <div className={styles.mainContainer}>
    <h1>Ooops!!</h1>
    <p>SOMETHING WENT WRONG.</p>
    <p>If you are seeing this message, please contact <a href="mailto:help@tractiontechpartners.com">help@tractiontechpartners.com</a></p>
  </div>
);

export default Error;

import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Select from "react-dropdown-select";

import { useModelPopup } from "common/hooks";
import { ModalBody, ModalFooter } from "../../../application/modal";
import { BtnType, Button, Label, TextBox } from "../../../modules/primitives";
import { addArea, editArea } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import LoadingComponent from "modules/primitives/loading/loading.component";

const AddAdminArea = ({ plant, area }) => {
  const {
    adminPlantsAndAreasReducer: { plantsAndAreas }
  } = useSelector((state) => state);

  const [areaName, setAreaName] = useState(area?.area_name || '');
  const [fetching, setFetching] = useState(false);
  const [invalidAreaName, setInvalidAreaName] = useState(false);
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [showNewArea, setShowNewArea] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const dispatch = useDispatch();

  const handleSave = () => {
    setFetching(true);

    if (showNewArea) {
      const payload = {
        area: {
          name: areaName,
        },
        plantId: selectedPlant.id,
        cb: () => {
          popup.hide();
          enqueueSnackbar('Successfully added new area', {
            variant: 'success'
          });
        }
      };

      dispatch(addArea(payload));
    } else {
      let payload;

      if (area?.area_id) {
        payload = {
          data: {
            area: {
              name: areaName,
            }
          },
          cb: () => {
            popup.hide();
            enqueueSnackbar('Successfully updated area', {
              variant: 'success'
            });
          },
          url: `plants/${plant.id}/areas/${area.area_id}`,
        };
      } else {
        payload = {
          data: {
            plant: {
              name: selectedPlant.name,
              area_ids: selectedAreas.map(a => a.area_id),
            }
          },
          cb: () => {
            popup.hide();
            enqueueSnackbar('Successfully updated areas', {
              variant: 'success'
            });
          },
          url: `plants/${selectedPlant.id}`,
        };
      }

      dispatch(editArea(payload));
    }
  };

  const handleDismiss = () => {
    popup.hide();
  };

  const makePlantsArray = useMemo(() => {
    if (!plantsAndAreas) {
      return [];
    }

    return plantsAndAreas.map(plant => ({id: plant.id, ...plant.attributes}));
  }, [plantsAndAreas]);

  const getAllFlatAreas = useMemo(() => {
    const allAreas = makePlantsArray.reduce((acc, plant) => [...acc, ...plant.areas || []], []);
    return [...new Set(allAreas.map(a => a.area_id))]
      .map(id => allAreas.find(a => a.area_id === id));
  }, [makePlantsArray]);

  const handleChangeAreaName = (evt) => {
    const value = evt.target.value;
    setAreaName(value);

    const existingNames = getAllFlatAreas.map(a => a.area_name.toLowerCase());
    const isInvalidAreaName = existingNames.includes(value.toLowerCase());
    setInvalidAreaName(isInvalidAreaName);
  };

  const isSaveDisabled = useMemo(() => {
    if (showNewArea || area?.area_id?.length) {
      return areaName?.length === 0
    }

    return !selectedPlant;
  }, [showNewArea, selectedPlant, areaName, area]);

  const handlePlantSelect = useCallback((val) => {
    const plant = val[0];

    setSelectedPlant(plant);
    setSelectedAreas(plant?.areas || [])
  }, [setSelectedPlant, setSelectedAreas])

  return (
    <>
      <ModalBody>
        <div>
          <h5 className="mb-4">Areas can be added to Projects, Solutions, or Ideas</h5>
          {
            !plant && (
              <div className="mb-3">
                <Label>Select Plant</Label>
                <Select
                  label="Select Plant"
                  placeholder="Select Plant"
                  labelField="name"
                  valueField="id"
                  multi={false}
                  options={makePlantsArray}
                  onChange={handlePlantSelect}
                />
              </div>
            )
          }
          {
            (plant?.id || selectedPlant?.id) && !area?.area_id && (
              <>
                {
                  showNewArea ? (
                    <>
                      <Label>Area Name</Label>
                      <TextBox
                        type="text"
                        placeholder="Type new Area name"
                        className="w-100"
                        value={areaName}
                        onChange={handleChangeAreaName}
                      />
                      {
                        invalidAreaName && (
                          <span className="small text-danger">Area name already exists, please choose another one!</span>
                        )
                      }
                    </>
                  ) : (
                    <>
                      <Label>Select Areas</Label>
                      <Select
                        multi
                        closeOnSelect
                        values={selectedAreas}
                        onChange={setSelectedAreas}
                        options={getAllFlatAreas}
                        labelField="area_name"
                        valueField="area_id"
                        placeholder="Select Areas"
                        searchBy="area_name"
                        style={{ border: "1px solid #e4e7eb" }}
                      />
                    </>
                  )
                }
                <div className="py-3 mb-3">
                  <Button
                    btn={BtnType.FRAME_LESS}
                    icon="icn-add"
                    onClick={() => {
                      setShowNewArea(!showNewArea)
                    }}
                  >
                    {showNewArea ? 'Multiselect' : 'Create'}
                  </Button>
                </div>
              </>
            )
          }
          {
            area?.area_id && (
              <>
                <Label>Area Name</Label>
                <TextBox
                  type="text"
                  placeholder="Type new Area name"
                  className="w-100"
                  value={areaName}
                  onChange={handleChangeAreaName}
                />
                {
                  invalidAreaName && (
                    <span className="small text-danger">Area name already exists for that plant, please choose another one!</span>
                  )
                }
              </>
            )
          }
        </div>
      </ModalBody>
      <ModalFooter>
        {
          fetching ? (
            <LoadingComponent customText="Saving area..." />
          ) : (
            <>
              <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                btn={BtnType.REGULAR}
                className="ml-2"
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </>
          )
        }
      </ModalFooter>
    </>
  );
};

export default React.memo(AddAdminArea);

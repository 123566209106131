import {
  PROJECT_RATINGS_ALL_GET_SUCCESS,
  PROJECT_RATINGS_BY_CATEGORY_GET_SUCCESS,
  PROJECT_RATINGS_ALL_GET_ATTEMPT,
  HANDLE_ERROR_SUCCESS,
  PROJECT_TEMPLATE_RATINGS_ALL_GET_SUCCESS, PROJECT_TEMPLATE_RATINGS_ALL_GET_ATTEMPT,
} from "./ratings.actions";

import { apiStatus } from "../../../../../common/httpCall";

const initState = {
  projectRatings: [],
  projectRatingsCategories: [],
  projectsRatingLoading: false,
  projectTemplateRatings: [],
};

const projectRatingsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PROJECT_RATINGS_ALL_GET_ATTEMPT: {
      return {
        ...state,
        projectsRatingLoading: true,
      };

      break;
    }
    case PROJECT_RATINGS_ALL_GET_SUCCESS: {
      const { response, status } = payload;

      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          projectRatings: response,
          projectsRatingLoading: false,
        };
      }
      break;
    }
    case PROJECT_TEMPLATE_RATINGS_ALL_GET_ATTEMPT: {
      return {
        ...state,
        projectsRatingLoading: true,
      };

      break;
    }

    case PROJECT_TEMPLATE_RATINGS_ALL_GET_SUCCESS: {
      const { response, status } = payload;

      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          projectTemplateRatings: response,
        };
      }
      break;
    }
    case PROJECT_RATINGS_BY_CATEGORY_GET_SUCCESS: {
      const { response, status } = payload;

      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          projectRatingsCategories: response,
        };
      }
      break;
    }
    case HANDLE_ERROR_SUCCESS: {
      return {
        ...state,
        projectsRatingLoading: false,
      };
    }
    default: {
      return state;
    }
  }
  return state;
};

export default projectRatingsReducer;

import React from "react";
import { UnderConstruction } from "../primitives";

const ContactsComponent = (props) => (
  <div>
    <UnderConstruction text="Contacts Page" />
  </div>
);

export default React.memo(ContactsComponent);

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import * as classNames from "classnames";

import styles from "./styles.module.scss";
import { UserInlineTypes, MoreOptions } from "../../../../primitives";
import UserInline from "../../../../primitives/userInline/userInline.component";
import { membersOptions } from "./members.constant";

const MemberComponent = ({
  item,
  handleLeaveThemeClick,
  setExpert,
  userThemeRole,
}) => {
  const {
    authReducer: { session },
  } = useSelector((state) => state);

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      setExpert(item);
    }

    if (value === 1) {
      handleLeaveThemeClick(item.user_id);
    }
  };

  const getMemberOptions = useMemo(() => {
    return membersOptions.filter((opt) => !(item?.user_id !== session?.id && opt.val))
  }, [item, session, membersOptions]);

  return (
    <div
      className={classNames(styles.Row)}
      // onClick={(e) => handleMemberProfileRedirect(e, item.user.id)}
    >
      <div className={styles.userInlineBlock}>
        <UserInline
          userId={item.user_id}
          first={item.name}
          last={""}
          avatar={item.avatar}
          local
          type={UserInlineTypes.WITH_NAME}
        />
        {
          // Admin or Project Manager
          (item?.user_id === session?.id ||
            session?.council_role === "ttp_administrator" ||
            userThemeRole === "manager" ||
            userThemeRole === "owner") && (
            <div className={styles.btnWrp}>
              <MoreOptions
                options={getMemberOptions}
                className={classNames(
                  styles.moreOptWrp,
                  item.expert && styles.expert
                )}
                onClick={handleMoreOptionClick}
                iconName="icn-edit"
              />
            </div>
          )
        }
      </div>
      {item.expert ? <div className={styles.expertText}>Expert</div> : null}
    </div>
  );
};

export default React.memo(MemberComponent);

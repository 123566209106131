import {
  PATIENT_ORGANIZATION_GET_SUCCESS,
  PATIENT_ORGANIZATION_SET,
  PATIENT_ORGANIZATION_DETAILS_GET_SUCCESS,
  PATIENT_ORGANIZATION_DETAILS_GET_ATTEMPT,
  PATIENT_ORGANIZATION_ADD_RELATIONSHIP_SUCCESS,
  PATIENT_ORGANIZATION_DELETE_RELATIONSHIP_SUCCESS,
  PATIENT_ORGANIZATION_ADD_CONTACT_SUCCESS,
  PATIENT_ORGANIZATION_ADD_CONTACT_ATTEMPT,
  PATIENT_ORGANIZATION_DELETE_CONTACT_SUCCESS,
  PATIENT_ORGANIZATION_EDIT_CONTACT_SUCCESS,
  PATIENT_ORGANIZATION_EDIT_CONTACT_ATTEMPT,
  PATIENT_ORGANIZATION_EDIT_ATTEMPT,
  PATIENT_ORGANIZATION_EDIT_SUCCESS,
  GET_PATIENT_ORGANIZATION_GROUP_FIELDS_SUCCESS,
  GSK_POST_CUSTOM_FIELDS_SUCCESS,
  PATIENT_ORGANIZATION_DELETE_DETAILS_FIELDS_SUCCESS,
  PATIENT_ORGANIZATION_ADD_MANY_TO_PROJECT_ATTEMPT,
  PATIENT_ORGANIZATION_ADD_MANY_TO_PROJECT_SUCCESS,
  PATIENT_ORGANIZATION_CLEAR,
  PATIENT_ORGANIZATION_GET_ATTEMPT,
  PATIENT_ORGANIZATION_DELETE_CONTACT_ATTEMPT,
  PATIENT_ORGANIZATION_ADD_RELATIONSHIP_ATTEMPT,
  GSK_POST_CUSTOM_FIELDS,
  PATIENT_ORGANIZATION_DELETE_DETAILS_FIELDS_ATTEMPT,
  PATIENT_ORGANIZATION_DELETE_RELATIONSHIP_ATTEMPT,
  PATIENT_ORGANIZATION_PUT_DETAILS_FIELDS_ATTEMPT,
  PATIENT_ORGANIZATION_PUT_DETAILS_FIELDS_SUCCESS,
  SET_CUSTOM_FIELD_LOADING,
  PATIENT_ORGANIZATION_RESET_UPDATE_PROP,
  ERROR_HANDLE_SUCCESS,
  PATIENT_ORG_SET_DOWNLOAD_PARAMS,
} from "./patientOrganization.action";

import { apiStatus } from "../../../common/httpCall";

const initialState = {
  selectedPatientOrganization: {},
  patientOrganizationDetails: [],
  customFieldGroups: [],
  loading: false,
  updatePatientOrganization: false,
  customFieldAdded: false,
  customFieldRemoved: false,
  editedOrganizationContact: false,
  customFieldLoadingIdOrg: "",
  updateCustomFields: false,
  loadingPoDetails: false,
  patientOrgIsDownloading: false,
  patientOrgDownloadDetails: [],
};

const patientOrganizationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PATIENT_ORG_SET_DOWNLOAD_PARAMS: {
      return {
        ...state,
        patientOrgDownloadDetails: payload,
        patientOrgIsDownloading: !!payload.length,
      };
    }

    case PATIENT_ORGANIZATION_GET_ATTEMPT:
      {
        return {
          ...state,
          // loading: true,
          organizationLoading: true,
        };
      }
      break;
    case PATIENT_ORGANIZATION_GET_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            loading: false,
            organizationLoading: false,
            selectedPatientOrganization: response,
          };
        }
      }
      break;

    case PATIENT_ORGANIZATION_ADD_CONTACT_ATTEMPT:
      {
        return {
          ...state,
          // organizationLoading: true,
          updatePatientOrganization: false,
        };
      }
      break;

    case PATIENT_ORGANIZATION_ADD_CONTACT_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.CREATED) {
        return {
          ...state,
          // organizationLoading: false,
          updatePatientOrganization: true,
        };
      }
    }

    case PATIENT_ORGANIZATION_SET: {
      return {
        ...state,
        selectedPatientOrganization: payload,
      };
    }
    case PATIENT_ORGANIZATION_DELETE_DETAILS_FIELDS_ATTEMPT:
      return {
        ...state,
        loading: true,
        customFieldRemoved: false,
      };
    case PATIENT_ORGANIZATION_DELETE_DETAILS_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        customFieldRemoved: true,
      };
    case GSK_POST_CUSTOM_FIELDS:
      return {
        ...state,
        loading: true,
        customFieldAdded: false,
        updatePatientOrganization: false,
      };
    case GSK_POST_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        customFieldAdded: true,
        updatePatientOrganization: true,
      };
    case GET_PATIENT_ORGANIZATION_GROUP_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        customFieldGroups: payload.response,
      };
    case PATIENT_ORGANIZATION_DETAILS_GET_ATTEMPT: {
      return {
        ...state,
        loading: true,
        loadingPoDetails: true,
      };
    }
    case PATIENT_ORGANIZATION_CLEAR: {
      return {
        ...state,
        selectedPatientOrganization: {},
      };
    }
    case PATIENT_ORGANIZATION_DETAILS_GET_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            loading: false,
            patientOrganizationDetails: response,
            loadingPoDetails: false,
          };
        }
      }
      break;

    case PATIENT_ORGANIZATION_ADD_RELATIONSHIP_ATTEMPT: {
      return {
        ...state,
        loading: true,
        updatePatientOrganization: false,
      };
    }

    case PATIENT_ORGANIZATION_RESET_UPDATE_PROP: {
      return {
        ...state,
        updatePatientOrganization: false,
      };
    }

    case PATIENT_ORGANIZATION_ADD_RELATIONSHIP_SUCCESS: {
      return {
        ...state,
        // loading: false,
        updatePatientOrganization: true,
      };
    }

    case PATIENT_ORGANIZATION_DELETE_RELATIONSHIP_ATTEMPT: {
      return {
        ...state,
        updatePatientOrganization: false,
      };
    }

    case PATIENT_ORGANIZATION_DELETE_RELATIONSHIP_SUCCESS: {
      return {
        ...state,
        updatePatientOrganization: true,
      };
    }
    case PATIENT_ORGANIZATION_DELETE_CONTACT_ATTEMPT:
      return {
        ...state,
        // loading: true,
        updatePatientOrganization: false,
      };

    case PATIENT_ORGANIZATION_DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        // loading: true,
        updatePatientOrganization: true,
      };

    // case PATIENT_ORGANIZATION_ADD_RELATIONSHIP_SUCCESS: {
    //   return {
    //     ...state,
    //     // loading: false,
    //     updatePatientOrganization: true,
    //   };
    // }
    // case PATIENT_ORGANIZATION_DELETE_RELATIONSHIP_ATTEMPT:
    //   return {
    //     ...state,
    //     // loading: true,
    //     updatePatientOrganization: false,
    //   };
    // case PATIENT_ORGANIZATION_DELETE_RELATIONSHIP_SUCCESS:
    //   return {
    //     ...state,
    //     // loading: false,
    //     updatePatientOrganization: true,
    //   };
    case PATIENT_ORGANIZATION_EDIT_CONTACT_ATTEMPT:
      return {
        ...state,
        loading: true,
        updatePatientOrganization: false,
        editedOrganizationContact: false,
      };
    case PATIENT_ORGANIZATION_EDIT_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        updatePatientOrganization: true,
        editedOrganizationContact: true,
      };
    case PATIENT_ORGANIZATION_EDIT_ATTEMPT:
      return {
        ...state,
        updatePatientOrganization: false,
      };
    case PATIENT_ORGANIZATION_EDIT_SUCCESS:
      return {
        ...state,
        updatePatientOrganization: true,
      };

    case PATIENT_ORGANIZATION_ADD_MANY_TO_PROJECT_ATTEMPT:
      return {
        ...state,
      };
    case PATIENT_ORGANIZATION_ADD_MANY_TO_PROJECT_SUCCESS:
      return {
        ...state,
      };
    case SET_CUSTOM_FIELD_LOADING: {
      console.log("Set Custom Field Payload", payload);
      return {
        ...state,
        customFieldLoadingIdOrg: payload,
      };
    }
    case PATIENT_ORGANIZATION_PUT_DETAILS_FIELDS_ATTEMPT: {
      return {
        ...state,
        organizationDetailsLoading: true,
        updateCustomFields: false,
      };
    }
    case PATIENT_ORGANIZATION_PUT_DETAILS_FIELDS_SUCCESS: {
      return {
        ...state,
        organizationDetailsLoading: false,
        updateCustomFields: true,
        customFieldLoadingIdOrg: "",
      };
    }
    case ERROR_HANDLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        customFieldLoadingIdOrg: "",
      };
    }
  }
  return state;
};

export default patientOrganizationReducer;

import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import styles from "modules/projects/project/tasks/taskInfo/taskInfo.module.scss";
import reasonStyles from "./approvalReason.module.scss";
import { doneActionsEnum } from "modules/projects/project/tasks/approvalTask/constants";
import { Icon, smSize } from "../../../../../common/icon";
import { BtnType, Button } from "modules/primitives";
import { httpDelete, httpPut } from "../../../../../common/httpCall";
import { staticGatewayTaskUpdate } from "modules/projects/project/tasks/tasks.action";
import ConfirmActionComponent from "../../../../../common/components/confirmAction/confirmAction.component";
import { useModelPopup } from "../../../../../common/hooks";

export default function ApprovalReasons({
  task,
  blockFunctionality,
  myUserId,
  isProjectWideFromStage,
}) {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [reasons, setReasons] = useState([]);

  useEffect(() => {
    if (task?.change_reasons) {
      const newReasons = task?.change_reasons.map((r) => ({
        ...r,
        originalReason: r.reason,
      }));
      setReasons(newReasons);
    }
  }, [task]);

  const makeReviewerAndTime = useCallback((reasonObj) => {
    const makeTime = format(
      new Date(reasonObj.created_at),
      "eeee, MMM do, yyyy"
    );
    const makeFullName = `${reasonObj.created_by.first_name} ${reasonObj.created_by.last_name}`;

    return `Updated by ${makeFullName} on ${makeTime}`;
  }, []);

  const handleEdit = useCallback(
    (reason) => {
      const newReasons = reasons.map((r) => {
        if (r.reason_id === reason.reason_id) {
          if (r.activeEdit) {
            return {
              ...r,
              reason: r.originalReason,
              activeEdit: false,
            };
          }

          return {
            ...r,
            activeEdit: true,
          };
        }

        return r;
      });

      setReasons(newReasons);
    },
    [task, reasons]
  );

  const handleDeleteConfirm = useCallback(
    (reason) => {
      const newReasons = reasons.filter(
        (r) => r.reason_id !== reason.reason_id
      );

      setReasons(newReasons);

      httpDelete({
        call: `tasks/${task.id}/reasons/${reason.reason_id}`,
      }).subscribe((res) => {
        if (res.response) {
          enqueueSnackbar("Successfully deleted reason!", {
            variant: "success",
          });

          dispatch(
            staticGatewayTaskUpdate({
              ...res.response,
              isProjectWideFromStage,
            })
          );
        }
      });
    },
    [task, reasons, isProjectWideFromStage, dispatch]
  );

  const handleDelete = useCallback(
    (reason) => {
      popup.show({
        title: "Confirm action",
        component: (
          <ConfirmActionComponent
            confirmBtnName="Confirm"
            alertText="You are about to permanently delete this Reason. Do you want to proceed?"
            onConfirm={() => {
              handleDeleteConfirm(reason);
              popup.hide();
            }}
          />
        ),
      });
    },
    [popup, task, reasons, isProjectWideFromStage, dispatch]
  );

  const handleReasonChangeEvt = useCallback(
    (reason, value) => {
      const newReasons = reasons.map((r) => {
        if (r.reason_id === reason.reason_id) {
          return {
            ...r,
            reason: value,
          };
        }

        return r;
      });

      setReasons(newReasons);
    },
    [reasons]
  );

  const handleSaveUpdatedReason = useCallback(
    (reason) => {
      if (!reason?.reason?.length) {
        enqueueSnackbar("Please provide a reason!", {
          variant: "error",
        });
        return;
      }

      httpPut({
        call: `tasks/${task.id}/reasons/${reason.reason_id}`,
        data: {
          change_reason: {
            reason: reason.reason,
          },
        },
      }).subscribe((res) => {
        if (res.response) {
          enqueueSnackbar("Successfully updated reason!", {
            variant: "success",
          });

          dispatch(
            staticGatewayTaskUpdate({
              ...res.response,
              isProjectWideFromStage,
            })
          );
        }
      });

      const newReasons = reasons.map((r) => {
        if (r.reason_id === reason.reason_id) {
          return {
            ...r,
            originalReason: r.reason,
            activeEdit: false,
          };
        }

        return r;
      });

      setReasons(newReasons);
    },
    [task, reasons, isProjectWideFromStage]
  );

  return (
    <div className={classNames(styles.line, "p-0")}>
      <div
        className={classNames(
          "d-flex flex-column w-100",
          reasonStyles.reasonScrollWrapper
        )}
      >
        {reasons.map((reason) => (
          <div
            className={classNames(
              reasonStyles.reasonContent,
              reasonStyles[reason.action],
              "pr-3 pl-3"
            )}
            key={reason.reason_id}
          >
            <div className="d-flex flex-column">
              <span className={reasonStyles.title}>
                {doneActionsEnum[reason.action]}
              </span>
              <p className={reasonStyles.message}>
                {reason.activeEdit ? (
                  <div className="d-flex flex-column">
                    <textarea
                      className="w-100 border-secondary"
                      defaultValue={reason.reason}
                      onChange={(evt) =>
                        handleReasonChangeEvt(reason, evt.target.value)
                      }
                    />
                    <div className="d-flex mt-2 mb-2">
                      <Button
                        btn={BtnType.FRAME_LESS}
                        onClick={() => handleEdit(reason)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="ml-2"
                        onClick={() => handleSaveUpdatedReason(reason)}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                ) : (
                  reason.reason || "-"
                )}
              </p>
              <span className={reasonStyles.whoUpdated}>
                {makeReviewerAndTime(reason)}
              </span>
            </div>
            {(myUserId || !blockFunctionality) && !reason.activeEdit && (
              <div>
                <Icon
                  {...smSize}
                  className="cursor-pointer"
                  icon="icn-edit"
                  onClick={() => handleEdit(reason)}
                />
                <Icon
                  {...smSize}
                  className="cursor-pointer"
                  icon="icn-button-delete"
                  onClick={() => handleDelete(reason)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

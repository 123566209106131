import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import { headerMenu } from "../header/header.constant";
import styles from "./sideNavigation.module.scss";
import { appMobileNaviSet } from "../app.action";
import { UserInline, UserInlineTypes } from "../../modules/primitives";
import { logOut } from "modules/auth/auth.action";

const SideNavigationComponent = (props) => {
  const [pathname, setPathName] = useState(props.location.pathname);
  const [rootName, setRootName] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const {
    authReducer: { session },
    appReducer: { showMobileNavi },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    const menuItemsClone = [...headerMenu];

    if (session?.council_role === "company") {
      const newItems = menuItemsClone.filter((menu) => menu.claimProfileOnly);
      setMenuItems(newItems);
      return;
    }

    if (selectedCouncil && selectedCouncil.traction_tag === "gsk") {
      setMenuItems(menuItemsClone);
    } else {
      const newItems = menuItemsClone.filter((menu) => !menu.gskOnly);
      setMenuItems(newItems);
    }

    setPathName(history?.location?.pathname);
  }, [history.location, selectedCouncil, session]);

  useEffect(() => {
    const paths = pathname.split("/");
    setRootName(paths[1]);
  }, [pathname]);

  const handleMenuClick = () => {
    dispatch(appMobileNaviSet(false));
  };

  const handleLogoutClick = () => {
    dispatch(logOut({ enqueueSnackbar }));
    dispatch(appMobileNaviSet(false));
    document.cookie =
      "expire= ; expires= Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
    document.cookie =
      "access_token= ; expires= Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
    document.cookie =
      "uid= ; expires= Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
    document.cookie =
      "client= ; expires= Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
    window.location.assign(`${window.location.origin}/login`);
    localStorage.clear();
  };

  return (
    <div className={classNames(styles.wrapper, showMobileNavi && styles.show)}>
      <div className={styles.userWrp}>
        {session && (
          <UserInline
            className={styles.userIcon}
            userId={session && session.id}
            type={UserInlineTypes.WITH_NAME}
            showMine={false}
            first={session && session.first_name}
            last={session && session.last_name}
            avatar={session && session.avatar}
            local
          />
        )}
      </div>

      <ul className={classNames(styles.menuWrp)}>
        {menuItems.map((menu) => {
          if (menu.path === "/themes") {
            return (
              <Link
                key={menu.path}
                to={menu.path}
                className={classNames(
                  styles.menuItem,
                  menu.path.substr(1).includes(rootName) && styles.active
                )}
                onClick={handleMenuClick}
              >
                {selectedCouncil?.settings?.themes_display_name || "Themes"}
              </Link>
            );
          }

          return (
            <Link
              key={menu.path}
              to={menu.path}
              className={classNames(
                styles.menuItem,
                menu.path.substr(1).includes(rootName) && styles.active
              )}
              onClick={handleMenuClick}
            >
              {menu.text}
            </Link>
          );
        })}
        <li className={styles.menuItem} onClick={handleLogoutClick}>
          Log Out
        </li>
      </ul>
    </div>
  );
};

export default SideNavigationComponent;

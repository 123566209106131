import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "modules/home/innovationFunnel/innovationListWrapper/innovationListWrapper.module.scss";
import styles1 from "modules/home/firmenichFunnel/listWrapper/listWrapper.module.scss";
import FirmenichListWrapper from "modules/home/firmenichFunnel/listWrapper";
import {
  getHomeFunnelCountryData,
  getHomeFunnelData,
  getHomeFunnelThemesData,
} from "modules/home/home.actions";
import { Loading } from "modules/primitives";
import ChartsDownload from "modules/companies/companyMain/chartsDownload";
import FirmenichPieChartWrapper from "modules/home/firmenichFunnel/firmenichChart";
import FirmenichChartTags from "modules/home/firmenichFunnel/chartTags";
import isItMediumDevice from "common/isItMediumDevice";
import isItSmallDevice from "modules/themes/themesCommon/isItSmallDevice";

const FirmenichFunnel = () => {
  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    homeReducer: { firmenichFunnel },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [activeChartView, setActiveChartView] = useState("stage");

  useEffect(() => {
    if (!firmenichFunnel[activeChartView]?.sectionData) {
      if (activeChartView === "stage") {
        dispatch(
          getHomeFunnelData({
            data: {
              data: {},
            },
          })
        );
      }
      if (activeChartView === "theme") {
        dispatch(
          getHomeFunnelThemesData({
            data: {
              data: {},
            },
          })
        );
      }
      if (activeChartView === "country") {
        dispatch(
          getHomeFunnelCountryData({
            data: {
              data: {},
            },
          })
        );
      }
    }
  }, [activeChartView, firmenichFunnel]);

  const handleTabClick = (view) => {
    setActiveChartView(view);
  };

  const handleToggleChange = (toggleView) => {
    if (toggleView === firmenichFunnel.toggleView) {
      return;
    }

    const payload = {
      data: {
        data: {},
        filters: {
          page: firmenichFunnel[activeChartView]?.pagination?.page || 1,
          toggleView,
        },
      },
    };

    if (activeChartView === "stage") {
      dispatch(getHomeFunnelData(payload));
    }
    if (activeChartView === "theme") {
      dispatch(getHomeFunnelThemesData(payload));
    }
    if (activeChartView === "country") {
      dispatch(getHomeFunnelCountryData(payload));
    }
  };

  const handlePageChange = (page, reducerKey) => {
    const payload = {
      data: {
        data: {},
        filters: {
          page,
          toggleView: firmenichFunnel.toggleView,
          loadingList: true,
          sortingConfig: firmenichFunnel[activeChartView].sortingConfig,
        },
        reducerKey,
      },
    };

    if (reducerKey === "stage") {
      dispatch(getHomeFunnelData(payload));
    }
    if (reducerKey === "theme") {
      dispatch(getHomeFunnelThemesData(payload));
    }
    if (reducerKey === "country") {
      dispatch(getHomeFunnelCountryData(payload));
    }
  };

  const handleSelectTags = (t, reducerKey) => {
    const reducerCurrKeys =
      firmenichFunnel[reducerKey]?.selected_keys_segments || [];
    let selectedKeys;

    if (!reducerCurrKeys.length) {
      selectedKeys = [t];
    } else {
      const isSelected = reducerCurrKeys.some(
        (tag) => (tag.id || tag.custom_id) === (t.id || t.custom_id)
      );

      if (isSelected) {
        selectedKeys = reducerCurrKeys.filter(
          (tag) => (tag.id || tag.custom_id) !== (t.id || t.custom_id)
        );
      } else {
        selectedKeys = [...reducerCurrKeys, t];
      }
    }

    const payload = {
      data: {
        data: {},
        filters: {
          page: 1,
          toggleView: firmenichFunnel.toggleView,
          loadingList: true,
          selected_keys_segments: selectedKeys,
          sortingConfig: firmenichFunnel[activeChartView].sortingConfig,
        },
        reducerKey,
      },
    };

    if (reducerKey === "stage") {
      dispatch(getHomeFunnelData(payload));
    }
    if (reducerKey === "theme") {
      dispatch(getHomeFunnelThemesData(payload));
    }
    if (reducerKey === "country") {
      dispatch(getHomeFunnelCountryData(payload));
    }
  };

  const getDataAfterStageChange = (view) => {
    const payload = {
      data: {
        data: {},
        filters: {
          page: firmenichFunnel[view].pagination?.page || 1,
          toggleView: firmenichFunnel.toggleView,
          selected_keys_segments:
            firmenichFunnel[view].selected_keys_segments || [],
          sortingConfig: firmenichFunnel[view].sortingConfig,
        },
        reducerKey: view,
      },
    };

    if (view === "stage") {
      dispatch(getHomeFunnelData(payload));
    }
    if (view === "theme") {
      dispatch(getHomeFunnelThemesData(payload));
    }
    if (view === "country") {
      dispatch(getHomeFunnelCountryData(payload));
    }
  };

  const handleSortChange = (sortingConfig, view) => {
    const payload = {
      data: {
        data: {},
        filters: {
          page: firmenichFunnel[view].pagination?.page || 1,
          toggleView: firmenichFunnel.toggleView,
          selected_keys_segments:
            firmenichFunnel[view].selected_keys_segments || [],
          sortingConfig,
        },
        reducerKey: view,
      },
    };

    if (view === "stage") {
      dispatch(getHomeFunnelData(payload));
    }
    if (view === "theme") {
      dispatch(getHomeFunnelThemesData(payload));
    }
    if (view === "country") {
      dispatch(getHomeFunnelCountryData(payload));
    }
  };

  const handleResetAll = () => {
    const payload = {
      data: {
        data: {},
        filters: {
          page: 1,
          toggleView: firmenichFunnel.toggleView,
          loadingList: true,
          selected_keys_segments: [],
          sortingConfig: firmenichFunnel[activeChartView].sortingConfig,
        },
        reducerKey: activeChartView,
      },
    };

    if (activeChartView === "stage") {
      dispatch(getHomeFunnelData(payload));
    }
    if (activeChartView === "theme") {
      dispatch(getHomeFunnelThemesData(payload));
    }
    if (activeChartView === "country") {
      dispatch(getHomeFunnelCountryData(payload));
    }
  };

  return (
    <div className="row">
      <div
        className={`d-flex align-items-center justify-content-between mb-2 w-100 ${
          window.screen.width < 550 && "flex-column"
        }`}
      >
        <div
          className={`d-flex align-items-center ${
            isItSmallDevice() && "flex-column w-100 "
          } `}
        >
          <h4
            style={{
              marginBottom: "5px",
              width: isItSmallDevice() && "100%",
              marginLeft: isItSmallDevice() && "11%",
            }}
          >
            Innovation Funnel
          </h4>
          {session?.theme_investment_access ? (
            <div className={styles1.toggle}>
              <div
                className={`${
                  firmenichFunnel.toggleView === "all"
                    ? styles1.activeToggleView
                    : ""
                }`}
                onClick={() => handleToggleChange("all")}
              >
                ALL
              </div>
              <div
                onClick={() => handleToggleChange("investment")}
                className={`${
                  firmenichFunnel.toggleView === "investment"
                    ? styles1.activeToggleView
                    : ""
                }`}
              >
                INVESTMENT
              </div>
            </div>
          ) : null}
        </div>
        <ChartsDownload
          homeUse
          chartType={activeChartView}
          toggleView={firmenichFunnel.toggleView}
          endpoint="firmenich/companies"
          payload={{
            search: {
              selected_keys_segments: firmenichFunnel[
                activeChartView
              ].selected_keys_segments.map((s) => s.id || s.customId || s.slug),
            },
          }}
        />
      </div>
      <div
        className={`${styles.innovationListWrapper} d-flex ${
          isItMediumDevice() ? "flex-col overflow-auto" : "flex-row"
        }`}
      >
        {firmenichFunnel[activeChartView]?.loading ? (
          <Loading customText="Preparing chart, please stand by ..." />
        ) : (
          <>
            <div
              className={`d-flex  ${!isItMediumDevice() ? "col-8" : ""} ${
                styles1.funnelTable
              } `}
            >
              {firmenichFunnel[activeChartView]?.sectionData?.companies_list
                ?.length ? (
                <FirmenichListWrapper
                  sortingConfig={firmenichFunnel[activeChartView].sortingConfig}
                  handleConfigChange={handleSortChange}
                  data={
                    firmenichFunnel[activeChartView]?.sectionData.companies_list
                  }
                  pagination={firmenichFunnel[activeChartView]?.pagination}
                  loading={
                    firmenichFunnel[activeChartView]?.loading ||
                    firmenichFunnel[activeChartView]?.loadingList
                  }
                  view={activeChartView}
                  toggleView={firmenichFunnel.toggleView}
                  handlePageChange={(p) => handlePageChange(p, activeChartView)}
                  getDataAfterStageChange={getDataAfterStageChange}
                  selectedTags={
                    firmenichFunnel[activeChartView]?.selected_keys_segments
                  }
                  tags={
                    firmenichFunnel[activeChartView]?.sectionData?.tags || []
                  }
                />
              ) : (
                <div className="w-100 d-flex justify-content-center align-items-center">
                  No Data Exists For These Selections
                </div>
              )}
            </div>
            <div className={` ${!isItMediumDevice() && "col-4"} `}>
              <div className={styles1.tabs}>
                <div
                  onClick={() => handleTabClick("stage")}
                  className={`${
                    activeChartView === "stage"
                      ? styles1.activeTab
                      : styles1.tab
                  }`}
                >
                  STAGE
                </div>
                <div
                  onClick={() => handleTabClick("theme")}
                  className={`${
                    activeChartView === "theme"
                      ? styles1.activeTab
                      : styles1.tab
                  }`}
                >
                  {`${
                    selectedCouncil?.settings?.themes_display_name_singular?.toUpperCase() ||
                    "THEME"
                  }`}
                </div>
                <div
                  onClick={() => handleTabClick("country")}
                  className={`${
                    activeChartView === "country"
                      ? styles1.activeTab
                      : styles1.tab
                  }`}
                >
                  COUNTRY
                </div>
              </div>
              {activeChartView === "stage" ? (
                <div>
                  <span className="justify-content-center d-flex font-weight-bold mb-3">
                    {firmenichFunnel.stage?.pagination.total || 0} COMPANIES
                  </span>
                  <FirmenichPieChartWrapper
                    selectedTags={firmenichFunnel.stage?.selected_keys_segments}
                    chartData={firmenichFunnel.stage?.sectionData}
                    id="stage"
                    instance="stage"
                    hasError={firmenichFunnel.stage?.hasError}
                    handleSegmentClick={(t) => handleSelectTags(t, "stage")}
                    handleResetAll={handleResetAll}
                  />
                  <FirmenichChartTags
                    tags={firmenichFunnel.stage?.sectionData?.tags || []}
                    selectedTags={firmenichFunnel.stage?.selected_keys_segments}
                    handleTagSelect={(t) => handleSelectTags(t, "stage")}
                    instance="stage"
                  />
                </div>
              ) : null}
              {activeChartView === "theme" ? (
                <div>
                  <span className="justify-content-center d-flex font-weight-bold mb-3">
                    {firmenichFunnel.theme?.pagination.total || 0} COMPANIES
                  </span>
                  <FirmenichPieChartWrapper
                    selectedTags={firmenichFunnel.theme?.selected_keys_segments}
                    chartData={firmenichFunnel.theme?.sectionData}
                    id="theme"
                    instance="theme"
                    hasError={firmenichFunnel.theme?.hasError}
                    handleSegmentClick={(t) => handleSelectTags(t, "theme")}
                    handleResetAll={handleResetAll}
                  />
                  <FirmenichChartTags
                    tags={firmenichFunnel.theme?.sectionData?.tags || []}
                    handleTagSelect={(t) => handleSelectTags(t, "theme")}
                    instance="theme"
                    selectedTags={firmenichFunnel.theme?.selected_keys_segments}
                  />
                </div>
              ) : null}
              {activeChartView === "country" ? (
                <div>
                  <span className="justify-content-center d-flex font-weight-bold mb-3">
                    {firmenichFunnel.country?.pagination.total || 0} COMPANIES
                  </span>
                  <FirmenichPieChartWrapper
                    selectedTags={
                      firmenichFunnel.country?.selected_keys_segments
                    }
                    chartData={firmenichFunnel.country?.sectionData}
                    id="country"
                    instance="country"
                    hasError={firmenichFunnel.country?.hasError}
                    handleSegmentClick={(t) => handleSelectTags(t, "country")}
                    handleResetAll={handleResetAll}
                  />
                  <FirmenichChartTags
                    tags={firmenichFunnel.country?.sectionData?.tags || []}
                    handleTagSelect={(t) => handleSelectTags(t, "country")}
                    instance="country"
                    selectedTags={
                      firmenichFunnel.country?.selected_keys_segments
                    }
                  />
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(FirmenichFunnel);

import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Loading, Search } from "modules/primitives/index";
import Skeleton from "@mui/material/Skeleton";
import { checkViewerRole } from "common/checkers/viewerChecker";
import classNames from "classnames";
import isKyndrylViewOnly from "common/isKyndrylViewOnly";
import { useWindowSize } from "common/hooks/useWindowSize";
import { getCookie } from "common/helper";

import Products from "modules/companies/company/products";
import styles from "./company.module.scss";
import DetailsComponent from "./details";
import OverviewComponent from "./overview";
import ActivityComponent from "./activity";
import ProjectCompanies from "./projectCompanies/projectCompanies.component";
import DocumentsComponent from "./documents/documents.component";
import CompanyDiscussions from "./companyDiscussions/companyDiscussions.component";
import {
  companySearchClear,
  setFilters,
} from "../companyMain/companyMain.action";
import {
  clearCompanyLoading,
  companyGet,
  companyAgreementActionClear,
} from "./company.action";
import NewTabs from "../../../common/components/newTabs/newTabs.component";
import CompanyTopActions from "./components/companyTopActions/companyTopActions.component";
import CompanyTopDetails from "./components/companyTopDetails/companyTopDetails.component";

const CompanyComponent = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const {
    location: { pathname },
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const {
    peopleReducer: {
      companyContactsReducer: { updateContacts },
    },
    companiesReducer: {
      companyReducer: {
        selectedCompany,
        loading,
        updateCompany,
        updateContactsCompany,
        companyLoading,
        companyDownloadDetails,
        companyIsDownloading,
      },
    },
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);
  const [selectedTab, setSelectedTab] = useState(pathname.split("/")[3]);
  const [query, setQuery] = useState();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    const council_role = getCookie("council_role_");

    setIsAdmin(
      council_role === "ttp_administrator" ||
        council_role === "council_administrator"
    );
    return () => null;
  }, []);

  const tabList = useMemo(() => {
    if (
      !session ||
      !selectedCouncil ||
      !selectedCompany ||
      selectedIndex === null
    ) {
      return [];
    }

    return [
      {
        tab: "Overview",
        name: "Overview",
        component: (
          <OverviewComponent
            pathname={pathname}
            company={selectedCompany}
            loading={companyLoading}
            selectedCouncil={selectedCouncil}
            isAdmin={isAdmin}
          />
        ),
      },
      {
        tab: "Details",
        name: "Details",
        showTooltip: isKyndrylViewOnly(
          selectedCouncil?.name,
          session?.council_role
        ),
        text: "Click here to fill out required details for this company",
        component: (
          <DetailsComponent
            pathname={pathname}
            companyId={selectedCompany.id}
            currentUserId={session?.id}
          />
        ),
      },
      {
        tab: "Notes",
        name: "Notes",
        component: (
          <ActivityComponent
            pathname={pathname}
            companyId={selectedCompany.id}
            session={session}
            selectedCouncil={selectedCouncil}
          />
        ),
      },
      {
        tab: "Products",
        name: "Products",
        component: <Products company={selectedCompany} />,
      },
      {
        tab: "Projects",
        name: "Projects",
        component: (
          <ProjectCompanies
            usedInCompany
            isAdmin={isAdmin}
            pathname={pathname}
            sessionRole={session?.council_role}
          />
        ),
      },
      {
        tab: "Documents",
        name: "Documents",
        component: <DocumentsComponent companyId={selectedCompany.id} />,
      },
      {
        tab: "Discussions",
        name: "Discussions",
        component: <CompanyDiscussions companyId={selectedCompany.id} />,
      },
    ];
  }, [selectedCompany, session, selectedCouncil, selectedIndex]);

  const clearTabList = useMemo(() => {
    const isKyndryl = isKyndrylViewOnly(
      selectedCouncil?.name,
      session?.council_role
    );
    return isKyndryl ? tabList.splice(0, 3) : tabList;
  }, [selectedCouncil, session, tabList]);

  useEffect(() => {
    if (
      selectedCouncil?.name &&
      session?.council_role &&
      checkViewerRole(session.council_role, selectedCouncil?.name)
    ) {
      setBlockFunctionality(true);
    }
  }, [session?.council_role, selectedCouncil?.name]);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(companySearchClear({}));
      dispatch(
        setFilters({
          topic_ids: [],
          industry_ids: [],
        })
      );
      dispatch(companyAgreementActionClear());
      const payload = {
        id,
        council_id: selectedCouncil.id,
        enqueueSnackbar,
        companyId: history.location?.state?.companyId,
        fromFirmenich: selectedCouncil?.name === "Firmenich",
      };
      dispatch(companyGet(payload));
    }
  }, [
    selectedCouncil,
    id,
    updateCompany,
    updateContacts,
    updateContactsCompany,
  ]);

  useEffect(() => {
    history.listen(({ pathname }) => {
      setSelectedTab(pathname?.split("/")[3]);
    });
  }, [history]);

  useEffect(() => {
    if (selectedTab) {
      setSelectedIndex(
        clearTabList.findIndex(({ tab }) => tab?.toLowerCase() === selectedTab)
      );
    } else {
      setSelectedIndex(0);
    }
  }, [selectedTab, clearTabList]);

  const handleOnTabSelect = (index) => {
    if (selectedCompany && selectedCompany?.id) {
      if (index === 0) {
        const payload = {
          id,
          council_id: selectedCouncil.id,
          enqueueSnackbar,
          companyId: history.location?.state?.companyId,
          fromFirmenich: selectedCouncil?.name === "Firmenich",
        };

        dispatch(companyGet(payload));
      }

      const objTab = clearTabList.find((tab, tabIndex) => index === tabIndex);
      history.push(
        `/companies/${
          selectedCompany.slug || selectedCompany.id
        }/${objTab.tab.toLowerCase()}`
      );
      setSelectedIndex(index);
    }
  };

  const handleEnterPress = () => {
    history.push("/companies", { query });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(
    () => () => {
      dispatch(clearCompanyLoading());
    },
    []
  );

  if (!selectedCompany?.id || (loading && !companyLoading)) {
    return <Loading customText="Preparing company details..." />;
  }

  return (
    <div id="downloadableSection">
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          {!companyIsDownloading ? (
            <div className={styles.searchWrapper}>
              <div className={styles.searchContainer}>
                <Search
                  handleEnterPress={handleEnterPress}
                  onChange={setQuery}
                  onKeyUp
                  className={styles.searchCon}
                  placeholder="Search Companies, Technologies, and Industries"
                  from="company"
                />
              </div>
            </div>
          ) : (
            <div className="d-flex flex-1 justify-content-between align-items-center px-4">
              <img
                height="50"
                alt={selectedCouncil.name}
                src={selectedCouncil.logo}
              />
              <h2 className="font-weight-bold mb-0">
                {selectedCompany.name} Profile
              </h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="79"
                height="35"
                viewBox="0 0 79 35"
              >
                <g fill="none" fillRule="evenodd" fontSize="16">
                  <g fill="#1F2933">
                    <g>
                      <text
                        fontFamily="HelveticaNeue-CondensedBlack, Helvetica Neue"
                        fontStyle="condensed"
                        fontWeight="700"
                        transform="translate(-1290 -12) translate(1291 12)"
                      >
                        <tspan x="0" y="16">
                          Traction
                        </tspan>
                      </text>
                      <text
                        fontFamily="HelveticaNeue-Light, Helvetica Neue"
                        fontWeight="300"
                        letterSpacing="-.08"
                        transform="translate(-1290 -12) translate(1291 12)"
                      >
                        <tspan x="0" y="31">
                          Technology
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          )}
          {selectedCompany && (
            <div className={styles.infoWrapper}>
              <div
                className={classNames(
                  styles.preWrapper,
                  isMobile ? "flex-column" : "flex-row"
                )}
              >
                {companyLoading ? (
                  <div className={styles.skeletonBlock}>
                    <Skeleton
                      animation="wave"
                      variant="circle"
                      width={60}
                      height={60}
                    />
                    <div className={styles.skeletonText}>
                      {" "}
                      <Skeleton animation="wave" height={23} width="50%" />
                      <Skeleton animation="wave" height={23} width="70%" />
                    </div>
                  </div>
                ) : (
                  <CompanyTopDetails
                    blockFunctionality={blockFunctionality}
                    isAdmin={isAdmin}
                  />
                )}
                {!companyLoading && !isMobile && (
                  <CompanyTopActions
                    isAdmin={isAdmin}
                    blockFunctionality={blockFunctionality}
                    id={selectedCompany.id}
                  />
                )}
              </div>
            </div>
          )}
        </div>

        <div className={styles.container}>
          {companyIsDownloading && companyDownloadDetails?.length ? (
            clearTabList
              .filter((t) => companyDownloadDetails.includes(t.name))
              .map((t) => (
                <div key={t.name} className="mb-3">
                  <h5 className="mb-3 pl-4 font-weight-bold mt-5">{t.name}</h5>
                  {t.component}
                </div>
              ))
          ) : (
            <NewTabs
              tabList={clearTabList}
              defaultIndex={selectedIndex}
              onSelect={(index) => handleOnTabSelect(index)}
              companyUse
              isNavigationHidden={companyLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(CompanyComponent);

import React, { useState } from "react";
import { Button, CheckBox, CheckType } from "modules/primitives/index";
import * as classNames from "classnames";
import styles from "./styles.module.scss";
import { httpPost } from "../../../common/httpCall";

import {
  companyOptions,
} from "./adminDataExports.constant";

const AdminDataExports = () => {
  const [companyOptionsChecked, setCompanyOptionsChecked] = useState({
    all: false,
    relationship_stage: false,
    technology_tag: false,
    industry_tag: false,
  });
  const [projectsOptionsChecked, setProjectsOptionsChecked] = useState({
    all: false,
    projects_status: false,
    priority: false,
    technology_tag: false,
    industry_tag: false,
  });
  const [themesOptionsChecked, setThemesOptionsChecked] = useState({
    all: false,
    type: false,
    technology_tag: false,
    industry_tag: false,
  });
  const [ideasOptionsChecked, setIdeasOptionsChecked] = useState({
    all: false,
    status: false,
    technology_tag: false,
    industry_tag: false,
  });
  const [documentsOptionsChecked, setDocumentsOptionsChecked] = useState({
    all: false,
    company_documents: false,
    theme_documents: false,
    ideas_documents: false,
    event_documents: false,
  });

  const handleCheckbox = (val, type, arrToCheck) => {
    let valueOnCheck;
    if (!arrToCheck[val]) {
      valueOnCheck = true;
    } else {
      valueOnCheck = false;
    }
    if (type === "companies") {
      setCompanyOptionsChecked({
        ...companyOptionsChecked,
        [val]: valueOnCheck,
      });
    }
    if (type === "projects") {
      setProjectsOptionsChecked({
        ...projectsOptionsChecked,
        [val]: valueOnCheck,
      });
    }
    if (type === "themes") {
      setThemesOptionsChecked({ ...themesOptionsChecked, [val]: valueOnCheck });
    }
    if (type === "ideas") {
      setIdeasOptionsChecked({ ...ideasOptionsChecked, [val]: valueOnCheck });
    }
    if (type === "documents") {
      setDocumentsOptionsChecked({
        ...documentsOptionsChecked,
        [val]: valueOnCheck,
      });
    }
  };

  const handleUpdate = (type) => {
    if (type === "companies") {
      console.log("companyOptionsChecked", companyOptionsChecked);
      httpPost({
        call: "councils/reports/companies",
        apiVersion: "v2",
      })
        .pipe()
        .subscribe((res) => {});
    }
    if (type === "projects") {
      console.log("projectsOptionsChecked", projectsOptionsChecked);
    }
    if (type === "themes") {
      console.log("themesOptionsChecked", themesOptionsChecked);
    }
    if (type === "ideas") {
      console.log("ideasOptionsChecked", ideasOptionsChecked);
    }
    if (type === "documents") {
      console.log("documentsOptionsChecked", documentsOptionsChecked);
    }
  };

  return (
    <div className={styles.adminExportsContainer}>
      <div className={styles.exportBlock}>
        <h4>Export Company Data</h4>
        <div className={styles.checkBlock}>
          {companyOptions.map((companyOpt) => (
            <div className={styles.checkItem}>
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={companyOptionsChecked[companyOpt.val]}
                onChange={(val) =>
                  handleCheckbox(
                    companyOpt.val,
                    "companies",
                    companyOptionsChecked
                  )
                }
              />
              <div className={classNames(styles.checkLabel)}>
                {companyOpt.text}
              </div>
            </div>
          ))}
        </div>
        <Button
          onClick={() => handleUpdate("companies")}
          className={styles.updateButton}
        >
          Export
        </Button>
      </div>
    </div>
  );
};
export default AdminDataExports;

export const UPDATE_PLANT = "UPDATE_PLANT";
export const updatePlant = (payload) => ({
  type: UPDATE_PLANT,
  payload,
});

export const UPDATE_PLANT_SUCCESS = "UPDATE_PLANT_SUCCESS";
export const updatePlantSuccess = (payload) => ({
  type: UPDATE_PLANT_SUCCESS,
  payload,
});

export const GET_PLANT_BY_TYPE = "GET_PLANT_BY_TYPE";
export const getPlantByType = (payload) => ({
  type: GET_PLANT_BY_TYPE,
  payload,
});

export const GET_PLANT_BY_TYPE_SUCCESS = "GET_PLANT_BY_TYPE_SUCCESS";
export const getPlantByTypeSuccess = (payload) => ({
  type: GET_PLANT_BY_TYPE_SUCCESS,
  payload,
});

export const RESET_PLANT_BY_TYPE = "RESET_PLANT_BY_TYPE";
export const resetPlantByType = (payload) => ({
  type: RESET_PLANT_BY_TYPE,
  payload,
});

export const SETTINGS_EDIT_SUCCESS = "SETTINGS_EDIT_SUCCESS";
export const settingsEditSuccess = (payload) => ({
  type: SETTINGS_EDIT_SUCCESS,
  payload,
});

export const SUGGESTIONS_ATTEMPT = "SUGGESTIONS_ATTEMPT";
export const suggestionsGet = (payload) => ({
  type: SUGGESTIONS_ATTEMPT,
  payload,
});
export const SUGGESTIONS_SUCCESS = "SUGGESTIONS_SUCCESS";
export const suggestionsSuccess = (payload) => ({
  type: SUGGESTIONS_SUCCESS,
  payload,
});

export const SUGGESTION_FOLLOW_ATTEMPT = "SUGGESTION_FOLLOW_ATTEMPT";
export const suggestionFollow = (payload) => ({
  type: SUGGESTION_FOLLOW_ATTEMPT,
  payload,
});
export const SUGGESTION_FOLLOW_SUCCESS = "SUGGESTION_FOLLOW_SUCCESS";
export const suggestionFollowSuccess = (payload) => ({
  type: SUGGESTION_FOLLOW_SUCCESS,
  payload,
});

export const COMPANY_UNFOLLOW_ATTEMPT = "COMPANY_UNFOLLOW_ATTEMPT";
export const companyUnFollow = (payload) => ({
  type: COMPANY_UNFOLLOW_ATTEMPT,
  payload,
});
export const COMPANY_UNFOLLOW_SUCCESS = "COMPANY_UNFOLLOW_SUCCESS";
export const companyUnFollowSuccess = (payload) => ({
  type: COMPANY_UNFOLLOW_SUCCESS,
  payload,
});

export const COMPANY_FOLLOW_MANY_ATTEMPT = "COMPANY_FOLLOW_MANY_ATTEMPT";
export const companyFollowManyAttempt = (payload) => ({
  type: COMPANY_FOLLOW_MANY_ATTEMPT,
  payload,
});

export const COMPANY_FOLLOW_MANY_SUCCESS = "COMPANY_FOLLOW_MANY_SUCCESS";
export const companyFollowManySuccess = (payload) => ({
  type: COMPANY_FOLLOW_MANY_SUCCESS,
  payload,
});

export const COMPANY_UNFOLLOW_MANY_ATTEMPT = "COMPANY_UNFOLLOW_MANY_ATTEMPT";
export const companyUnfollowManyAttempt = (payload) => ({
  type: COMPANY_UNFOLLOW_MANY_ATTEMPT,
  payload,
});

export const COMPANY_UNFOLLOW_MANY_SUCCESS = "COMPANY_UNFOLLOW_MANY_SUCCESS";
export const companyUnfollowManySuccess = (payload) => ({
  type: COMPANY_UNFOLLOW_MANY_SUCCESS,
  payload,
});

export const PEOPLE_LIST_TEAMS_ATTEMPT = "PEOPLE_LIST_TEAMS_ATTEMPT";
export const peopleListTeamsGet = (payload) => ({
  type: PEOPLE_LIST_TEAMS_ATTEMPT,
  payload,
});
export const PEOPLE_LIST_TEAMS_SUCCESS = "PEOPLE_LIST_TEAMS_SUCCESS";
export const peopleListTeamsSuccess = (payload) => ({
  type: PEOPLE_LIST_TEAMS_SUCCESS,
  payload,
});

export const COUNCIL_USERS_LIST_GET_ATTEMPT = "COUNCIL_USERS_LIST_GET_ATTEMPT";
export const councilUsersListGet = (payload) => ({
  type: COUNCIL_USERS_LIST_GET_ATTEMPT,
  payload,
});
export const COUNCIL_USERS_LIST_GET_SUCCESS = "COUNCIL_USERS_LIST_GET_SUCCESS";
export const councilUsersListGetSuccess = (payload) => ({
  type: COUNCIL_USERS_LIST_GET_SUCCESS,
  payload,
});

export const ADD_DOCUMENT_ATTEMPT = "ADD_DOCUMENT_ATTEMPT";
export const addDocument = (payload) => ({
  type: ADD_DOCUMENT_ATTEMPT,
  payload,
});
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const addDocumentSuccess = (payload) => ({
  type: ADD_DOCUMENT_SUCCESS,
  payload,
});

export const DELETE_DOCUMENT_ATTEMPT = "DELETE_DOCUMENT_ATTEMPT";
export const deleteDocument = (payload) => ({
  type: DELETE_DOCUMENT_ATTEMPT,
  payload,
});
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const deleteDocumentSuccess = (payload) => ({
  type: DELETE_DOCUMENT_SUCCESS,
  payload,
});

export const COMPANY_DOCUMENTS_GET_ATTEMPT = "COMPANY_DOCUMENTS_GET_ATTEMPT";
export const getDocuments = (payload) => ({
  type: COMPANY_DOCUMENTS_GET_ATTEMPT,
  payload,
});

export const COMPANY_DOCUMENTS_GET_SUCCESS = "COMPANY_DOCUMENTS_GET_SUCCESS";
export const getDocumentsSuccess = (payload) => ({
  type: COMPANY_DOCUMENTS_GET_SUCCESS,
  payload,
});

export const DOCUMENT_DELETE = "DOCUMENT_DELETE";
export const documentDelete = (payload) => ({
  type: DOCUMENT_DELETE,
  payload,
});

export const CHANGE_QUERY = "CHANGE_QUERY";
export const changeQuery = (payload) => ({
  type: CHANGE_QUERY,
  payload,
});

export const CHANGE_FOLLOW_RELATIONSHIP = "CHANGE_FOLLOW_RELATIONSHIP";
export const changeFollowRelationship = (payload) => ({
  type: CHANGE_FOLLOW_RELATIONSHIP,
  payload,
});

export const DELETE_TOTAL_FUNDING_ITEM = "DELETE_TOTAL_FUNDING_ITEM";
export const deleteTotalFundingItem = (payload) => ({
  type: DELETE_TOTAL_FUNDING_ITEM,
  payload,
});

export const DELETE_TOTAL_FUNDING_ITEM_SUCCESS =
  "DELETE_TOTAL_FUNDING_ITEM_SUCCESS";
export const deleteTotalFundingItemSuccess = (payload) => ({
  type: DELETE_TOTAL_FUNDING_ITEM_SUCCESS,
  payload,
});

export const GET_FUNDING_ITEMS = "GET_FUNDING_ITEMS";
export const getFundingItems = (payload) => ({
  type: GET_FUNDING_ITEMS,
  payload,
});

export const GET_FUNDING_ITEMS_SUCCESS = "GET_FUNDING_ITEMS_SUCCESS";
export const getFundingItemsSuccess = (payload) => ({
  type: GET_FUNDING_ITEMS_SUCCESS,
  payload,
});

export const ADD_FUNDING_ITEM = "ADD_FUNDING_ITEM";
export const addFundingItem = (payload) => ({
  type: ADD_FUNDING_ITEM,
  payload,
});

export const ADD_FUNDING_ITEM_SUCCESS = "ADD_FUNDING_ITEM_SUCCESS";
export const addFundingItemSuccess = (payload) => ({
  type: ADD_FUNDING_ITEM_SUCCESS,
  payload,
});

export const EDIT_FUNDING_ITEM = "EDIT_FUNDING_ITEM";
export const editFundingItem = (payload) => ({
  type: EDIT_FUNDING_ITEM,
  payload,
});

export const EDIT_FUNDING_ITEM_SUCCESS = "EDIT_FUNDING_ITEM_SUCCESS";
export const editFundingItemSuccess = (payload) => ({
  type: EDIT_FUNDING_ITEM_SUCCESS,
  payload,
});

export const EDIT_LEADERSHIP_ITEM = "EDIT_LEADERSHIP_ITEM";
export const editLeadershipItem = (payload) => ({
  type: EDIT_LEADERSHIP_ITEM,
  payload,
});

export const EDIT_LEADERSHIP_ITEM_SUCCESS = "EDIT_LEADERSHIP_ITEM_SUCCESS";
export const editLeadershipSuccessItem = (payload) => ({
  type: EDIT_LEADERSHIP_ITEM_SUCCESS,
  payload,
});

export const ADD_LEADERSHIP_ITEM = "ADD_LEADERSHIP_ITEM";
export const addLeadershipItem = (payload) => ({
  type: ADD_LEADERSHIP_ITEM,
  payload,
});

export const ADD_LEADERSHIP_ITEM_SUCCESS = "ADD_LEADERSHIP_ITEM_SUCCESS";
export const addLeadershipSuccessItem = (payload) => ({
  type: ADD_LEADERSHIP_ITEM_SUCCESS,
  payload,
});

export const DELETE_LEADERSHIP_ITEM = "DELETE_LEADERSHIP_ITEM";
export const deleteLeadershipItem = (payload) => ({
  type: DELETE_LEADERSHIP_ITEM,
  payload,
});

export const DELETE_LEADERSHIP_ITEM_SUCCESS = "DELETE_LEADERSHIP_ITEM_SUCCESS";
export const deleteLeadershipSuccessItem = (payload) => ({
  type: DELETE_LEADERSHIP_ITEM_SUCCESS,
  payload,
});

export const GET_LEADERSHIP_ITEMS = "GET_LEADERSHIP_ITEMS";
export const getLeaderShipItems = (payload) => ({
  type: GET_LEADERSHIP_ITEMS,
  payload,
});

export const GET_LEADERSHIP_ITEMS_SUCCESS = "GET_LEADERSHIP_ITEMS_SUCCESS";
export const getLeaderShipItemsSuccess = (payload) => ({
  type: GET_LEADERSHIP_ITEMS_SUCCESS,
  payload,
});

export const PUT_COUNCIL_RESOURCE_CUSTOM_TAG_ATTEMPT =
  "PUT_COUNCIL_RESOURCE_CUSTOM_TAG_ATTEMPT";
export const putCouncilResourceCustomTagAttempt = (payload) => {
  return {
    type: PUT_COUNCIL_RESOURCE_CUSTOM_TAG_ATTEMPT,
    payload,
  };
};

export const PUT_COUNCIL_RESOURCE_CUSTOM_TAG_SUCCESS =
  "PUT_COUNCIL_RESOURCE_CUSTOM_TAG_SUCCESS";
export const putCouncilResourceCustomTagSuccess = (payload) => ({
  type: PUT_COUNCIL_RESOURCE_CUSTOM_TAG_SUCCESS,
  payload,
});
export const GET_TOPICS = "GET_TOPICS";
export const getTopicFilter = (payload) => ({
  type: GET_TOPICS,
  payload,
});

export const GET_TOPICS_SUCCESS = "GET_TOPICS_SUCCESS";
export const getTopicFilterSuccess = (payload) => ({
  type: GET_TOPICS_SUCCESS,
  payload,
});

export const GET_PLANTS_AREAS = "GET_PLANTS_AREAS";
export const getPlantsAreas = (payload) => ({
  type: GET_PLANTS_AREAS,
  payload,
});

export const GET_PLANTS_AREAS_SUCCESS = "GET_PLANTS_AREAS_SUCCESS";
export const getPlantsAreasSuccess = (payload) => ({
  type: GET_PLANTS_AREAS_SUCCESS,
  payload,
});

export const GET_THEMES = "GET_THEMES";
export const getThemes = (payload) => ({
  type: GET_THEMES,
  payload,
});

export const GET_THEMES_SUCCESS = "GET_THEMES_SUCCESS";
export const getThemesSuccess = (payload) => ({
  type: GET_THEMES_SUCCESS,
  payload,
});

// filterable
export const GET_FILTERABLE = "GET_FILTERABLE";
export const getFilterableResource = (payload) => ({
  type: GET_FILTERABLE,
  payload,
});

export const GET_FILTERABLE_SUCCESS = "GET_FILTERABLE_SUCCESS";
export const getFilterableSuccess = (payload) => ({
  type: GET_FILTERABLE_SUCCESS,
  payload,
});
// {
//   "query":"",
//   "search":{
//        "query":"",
//        "user_id":"3a9a3fad-60e3-4b05-bbb2-0042148418c9",
//        "original": false,
//        "savings_lte": null,
//        "savings_gte": null,
//        "rating_gte": null,
//        "rating_lte": null,
//        "program_prototype": null,
//        "program_implementation": null,
//        "funded_by_prototype": null,
//        "funded_by_implementation": null,
//        "skills_team": null,
//        "skills_team_implementation": null,
//        "fps_category": null,
//        "topic_ids": [],
//        "industry_ids": [],
//        "plant_ids": [],
//        "area_ids": [],
//        "theme_ids": [],
//        "solution_ids": []
//   },
// }

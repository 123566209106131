import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import ReactTable from "react-table";
import "react-table/react-table.css";
import classnames from "classnames";

import adminFuseHoc from "common/HOC/adminFuseHoc";
import { Button, BtnType, MoreOptions, Loading } from "../../../primitives";
import styles from "./adminProjectGroups.module.scss";
import { useModelPopup } from "../../../../common/hooks";
import {
  deleteProjectGroup,
  deleteProjectGroupField,
  getGroupFields,
  getProjectsGroupsAttempt,
  getProjectsStageFieldsAttempt,
  getStageTemplateByIdAttempt,
} from "modules/admin/adminProjects/adminProjects.actions";
import AddEditProjectsGroupModal from "modules/admin/adminProjects/adminProjectGroups/addEditProjectsGroup";
import AddFieldToGroupModal from "modules/admin/adminProjects/adminProjectGroups/addFieldToGroup";
import AddEditProjectsGroupFieldModal from "modules/admin/adminProjects/adminProjectGroups/addEditProjectsGroupField";

const moreOptions = [
  { val: "edit", text: "Edit" },
  { val: "delete", text: "Delete" },
];

const AdminProjectGroups = ({ instance }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const {
    adminProjectsReducer: {
      stageTemplate,
      projectGroups,
      selectedGroup,
    },
  } = useSelector((state) => state);

  const { enqueueSnackbar } = useSnackbar();
  const [sortType, setSortType] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { id, groupId } = useParams();
  const pageSizeOptions = [pageSize, 5, 10, 20, 50, 100];
  const isLowLevelInstance = instance === 'lowLevel';

  useEffect(() => {
    if (id) {
      dispatch(getStageTemplateByIdAttempt({ id, enqueueSnackbar }));
      dispatch(getProjectsGroupsAttempt({
        page: 1,
        pageSize: 20,
        stageID: id,
        enqueueSnackbar,
      }))
    }
  }, [id]);

  useEffect(() => {
    if (groupId) {
      // get group fields
      dispatch(
        getGroupFields({
          page: 1,
          pageSize: 20,
          groupId,
          enqueueSnackbar,
        })
      )
    }
  }, [groupId]);

  const handleMoreOptionClick = (e, field) => {
    if (e === "delete") {
      dispatch(
        deleteProjectGroup({
          enqueueSnackbar,
          stageID: id,
          groupId: field.id,
        })
      )
    } else if (e === "edit") {
      handleNewButtonClick(field);
    }
  };

  const handleMoreOptionClickLowLevel = (e, field) => {
    if (e === "delete") {
      dispatch(
        deleteProjectGroupField({
          enqueueSnackbar,
          fieldId: field.id,
          groupId,
        })
      )
    } else if (e === "edit") {
      handleNewButtonClick(field);
    }
  };

  const onGroupNameClick = (group) => {
    history.push(`/admin/projects/groups/${id}/${group.id}`);
  };

  const columns = React.useMemo(() => [
    {
      Header: () => (
        <div
          style={{
            borderBottom: sortType === "sort"
                ? "2px solid black"
                : "",
            borderTop:
              sortType === "reverse"
                ? "2px solid black"
                : "",
          }}
        >
          Group Name
        </div>
      ),
      id: "name",
      position: 1,
      accessor: (row) => <div className="cursor-pointer" onClick={() => onGroupNameClick(row)}>{row?.name}</div>,
      width: "30%",
    },
    {
      Header: "Fields",
      id: "type",
      accessor: (row) => (
        <div className={styles.alignedCenter}>{row?.existing_fields_records}</div>
      ),
      width: "30%",
    },
    {
      Header: () => (
        <div
          style={{
            borderBottom:
              sortType === "sort"
                ? "2px solid black"
                : "",
            borderTop:
              sortType === "reverse"
                ? "2px solid black"
                : "",
          }}
        >
          Position
        </div>
      ),
      id: "position",
      accessor: (row) => (
        <div className={styles.alignedCenter}>{row?.position}</div>
      ),
      width: "30%",
    },
    {
      Header: "Options",
      id: "options",
      sortable: false,
      accessor: (row) => {
        return (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row);
            }}
          />
        )
      },
      width: 150,
      style: {
        overflow: "visible",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
      },
    },
  ]);
  const lowLevelColumns = React.useMemo(() => [
    {
      Header: () => (
        <div
          style={{
            borderBottom: sortType === "sort"
                ? "2px solid black"
                : "",
            borderTop:
              sortType === "reverse"
                ? "2px solid black"
                : "",
          }}
        >
          Field Name
        </div>
      ),
      id: "name",
      position: 1,
      accessor: (row) => <div>{row?.field_name}</div>,
      width: "30%",
    },
    {
      Header: "Field Type",
      id: "type",
      accessor: (row) => <div>{row?.field_type}</div>,
      width: "30%",
    },
    {
      Header: 'Existing Records',
      id: "existing_records",
      accessor: (row) => (
        <div className={styles.alignedCenter}>{row?.existing_records || 0}</div>
      ),
      width: "30%",
    },
    {
      Header: () => (
        <div
          style={{
            borderBottom:
              sortType === "sort"
                ? "2px solid black"
                : "",
            borderTop:
              sortType === "reverse"
                ? "2px solid black"
                : "",
          }}
        >
          Position
        </div>
      ),
      id: "position",
      accessor: (row) => (
        <div className={styles.alignedCenter}>{row?.position_in_group}</div>
      ),
      width: "30%",
    },
    {
      Header: "Options",
      id: "options",
      sortable: false,
      accessor: (row) => {
        return (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClickLowLevel(e, row);
            }}
          />
        )
      },
      width: 150,
      style: {
        overflow: "visible",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
      },
    },
  ]);

  const requestNewDataCb = () => {
    if (groupId) {
      // get group fields
      dispatch(
        getGroupFields({
          page: page || 1,
          pageSize: pageSize || 20,
          groupId,
          enqueueSnackbar,
        })
      );

      return;
    }

    if (id) {
      dispatch(getProjectsGroupsAttempt({
        page: page || 1,
        pageSize: pageSize || 20,
        stageID: id,
        enqueueSnackbar,
      }))
    }
  };

  const handleNewButtonClick = (data) => {
    if (isLowLevelInstance) {
      popup.show({
        title: 'Edit Field in Group',
        show: true,
        height: "300",
        width: "540",
        component: (
          <AddEditProjectsGroupFieldModal
            data={data}
            stageID={id}
            groupId={groupId}
            requestNewDataCb={requestNewDataCb}
          />),
      });

      return;
    }

    popup.show({
      title: data ? `Edit Field Group` : "Add Field Group",
      show: true,
      height: "300",
      width: "540",
      component: (
        <AddEditProjectsGroupModal
          mode={data ? 'edit' : 'add'}
          data={data}
          stageID={id}
          existingGroups={projectGroups.groups}
          requestNewDataCb={requestNewDataCb}
        />),
    });
  };

  const addNewFieldToGroup = () => {
    popup.show({
      title: "Add Field to Group",
      show: true,
      height: "300",
      width: "540",
      component: (
        <AddFieldToGroupModal
          mode="add"
          groupId={groupId}
          existingFields={selectedGroup.fields}
        />),
    });
  };

  const handleHeaderTableClick = (column) => {};

  const onTabClick = (tab) => {
    history.push(`/admin/projects/${tab}/${id}`);
  };

  const getDataWithPaginationParams = (currentPage, size) => {
    const arr = history.location.pathname.split('/');
    const id = arr[arr.length - 1];
    dispatch(getStageTemplateByIdAttempt({ id, enqueueSnackbar }));
    dispatch(getProjectsStageFieldsAttempt({
      stageId: id,
      page: currentPage,
      pageSize: size,
    }));
  }

  const handleNextClick = () => {
    setPage(page + 1);
    getDataWithPaginationParams(page + 1, pageSize);
  };

  const handlePrevClick = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
      getDataWithPaginationParams(page - 1, pageSize);
    }
  };

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    getDataWithPaginationParams(page, e.target.value);
  };

  const isNextDisabled = () => {
    if (page + 1 > 3) {
      return styles.disabledButton;
    }
    return styles.paginationButton;
  };

  const handlePageSelect = (e) => {
    setPage(e.target.value);
  };

  const getSelectedGroupName = useMemo(() => {
    if (!isLowLevelInstance && !projectGroups?.groups?.length) {
      return null
    }

    const getGroup = projectGroups?.groups?.find(group => group.id === groupId);

    return getGroup?.name;
  }, [isLowLevelInstance, groupId, projectGroups]);

  return (
    <div className={styles.container}>
      <div className="row">
        <div className="col-10" style={{ margin: "0 auto" }}>
          <div className={styles.headerWrp}>
            <h4 className={styles.taskHeaderText}>
              <span
                className={styles.rootText}
                onClick={() => {
                  history.push("/admin");
                }}
              >
                ADMIN
              </span>
              <span className={styles.separationBar}> / </span>
              <span
                className={styles.rootText}
                onClick={() => {
                  history.push("/admin/projects");
                }}
              >
                PROJECTS
              </span>
              <span className={styles.separationBar}> / </span>
              <span className={styles.companyName}>{stageTemplate?.name}</span>
              <span className={styles.separationBar}> / </span>
              <span className={styles.rootText} onClick={() => onTabClick("groups")}>FIELD GROUPS</span>
              {
                isLowLevelInstance && selectedGroup && (
                  <>
                    <span className={styles.separationBar}> / </span>
                    <span className={styles.selectedVar}>
                      {" "}
                      {getSelectedGroupName}
                    </span>
                  </>
                )
              }
            </h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className={classnames(styles.tabsContainer, "col-10")}
          style={{ margin: "0 auto" }}
        >
          <h5 className="mb-3">
            <span
              onClick={() => onTabClick("stages")}
              className={styles.tabsText}
            >
              {" "}
              STAGES{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span
              onClick={() => onTabClick("tasks")}
              className={styles.tabsText}
            >
              {" "}
              TASKS{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span className={styles.tabsText} onClick={() => onTabClick("fields")}>
              {" "}
              FIELDS{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span className={styles.selectedVar} onClick={() => onTabClick("groups")}>
              {" "}
              FIELD GROUPS{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span className={styles.tabsText} onClick={() => onTabClick("setup")}>
              {" "}
              PROJECT SET-UP{" "}
            </span>
          </h5>
          {
            isLowLevelInstance ? (
              <div className={styles.buttons}>
                <Button
                  className={styles.newTask}
                  btn={BtnType.FRAME_LESS}
                  onClick={() => {
                    history.push(`/admin/projects/groups/${id}`);
                  }}
                >
                  {'< '} Go Back
                </Button>
                <Button
                  className="ml-3"
                  btn={BtnType.REGULAR}
                  onClick={addNewFieldToGroup}
                  icon="icn-add"
                >
                  Add Field to Group
                </Button>
              </div>
            ) : (
              <>
                <div className={styles.buttons}>
                  <Button
                    className={styles.newTask}
                    btn={BtnType.REGULAR}
                    icon="icn-add"
                    onClick={() => handleNewButtonClick()}
                  >
                    Add Group
                  </Button>
                </div>
                <span className="small mt-2">Display Project Fields together in different Groups. Project Field Groups will appear in the Project Overview Tab in the Project Details section.</span>
              </>
            )
          }
        </div>
      </div>
      <div className="row">
        {" "}
        <div className="col-10" style={{ margin: "0 auto" }}>
          <div className={styles.buttonWrp}>
            <div className={styles.wrpIn}>
              {
                isLowLevelInstance ? (
                  <div className={styles.totalCount}>{selectedGroup?.fields?.length} fields in Group</div>
                ) : (
                  <div className={styles.totalCount}>{projectGroups?.groups?.length} Groups</div>
                )
              }
            </div>
          </div>
          <ReactTable
            data={isLowLevelInstance ? selectedGroup?.fields : projectGroups?.groups || []}
            columns={isLowLevelInstance ? lowLevelColumns : columns}
            className={styles.table}
            loading={(isLowLevelInstance ? selectedGroup?.loading : projectGroups?.loading) ? <Loading /> : null}
            getTheadThProps={(state, rowInfo, column) => ({
              onClick: () => handleHeaderTableClick(column),
            })}
            defaultSortDesc
            pageSize={pageSize}
            showPagination={false}
          />
          <div className={styles.pagination}>
            <div
              className={
                page - 1 === 0
                  ? styles.disabledButton
                  : styles.paginationButton
              }
              onClick={handlePrevClick}
            >
              Previous
            </div>
            <div className={styles.paginationSettings}>
              <span style={styles.pageNr}>
                <span>
                  <input
                    onChange={(e) => handlePageSelect(e)}
                    value={page}
                    className={styles.inputBox}
                    type="number"
                    min="1"
                  />
                </span>
                <span>of</span>
                <span>
                  {" "}
                  {projectGroups?.totalPages}
                </span>
              </span>
              <span>
                <select onChange={(e) => handleChangePageSize(e)}>
                  {pageSizeOptions.map((option) => (
                    <option value={option}>{option}</option>
                  ))}
                </select>
              </span>
            </div>
            <button
              className={isNextDisabled()}
              onClick={handleNextClick}
              disabled={page + 1 > projectGroups?.totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default adminFuseHoc(AdminProjectGroups);

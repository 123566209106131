import { combineEpics, ofType } from "redux-observable";
import { map, switchMap, catchError } from "rxjs/operators";
import { errorHandler, httpGet, queryString } from "../../common/httpCall";
import {
  GET_USER_TEAMS_ATTEMPT,
  getUserTeamsSuccess,
  GET_ALL_TEAMS_ATTEMPT,
  getAllTeamsSuccess,
} from "./teams.action";

export const getUserTeams = (action$) => action$.pipe(
  ofType(GET_USER_TEAMS_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar, query } }) => httpGet({
    call: `user/teams${query ? `?${queryString({ ...query })}` : ""}`,
  }).pipe(
    map((result) => getUserTeamsSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const getAllTeams = (action$) => action$.pipe(
  ofType(GET_ALL_TEAMS_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar } }) => httpGet({
    call: "teams",
  }).pipe(
    map((result) => getAllTeamsSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const teamsEpic = combineEpics(getUserTeams, getAllTeams);

export default teamsEpic;

import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";

import { checkViewerRole } from "common/checkers/viewerChecker";
import styles from "./projectCompanies.module.scss";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import {
  CompanyAvatar,
  CompanyState,
  Pannel,
  stateType,
} from "../../../../primitives";
import { useModelPopup } from "../../../../../common/hooks";
import { projectGet } from "../../project.action";
import {
  projectCompanyListGet,
  projectCompanyNewMultiple,
  projectOrganizationsGet,
  projectPatientOrgNewMultiple,
} from "../../companies/companies.action";
import LoadingComponent from "../../../../primitives/loading/loading.component";
import MixedAddCompanyOrProducts from "../../../../../common/components/mixedSearch/mixedAddCompanyOrProducts";

const ProjectCompaniesComponent = ({
  patientOrg,
  selectedProject,
  expandAll,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const popup = useModelPopup();
  const [itemlist, setItemList] = useState(null);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [textOrg, setTextOrg] = useState("Companies");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    projectsReducer: {
      companyReducer: { overviewCompanies, patientOrganizations },
      stagesReducer: { stagesList },
    },
  } = useSelector((state) => state);
  useEffect(() => {
    if (expandAll) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [expandAll]);
  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(projectGet({ id, enqueueSnackbar }));
    }

    return () => null;
  }, [id]);

  useEffect(() => {
    if (patientOrg) {
      if (currentProjectId !== id && patientOrganizations?.length) {
        dispatch(
          projectOrganizationsGet({
            projectId: selectedProject.id,
            enqueueSnackbar,
          })
        );
        setCurrentProjectId(id);

        return;
      }

      if (!patientOrganizations?.length) {
        dispatch(
          projectOrganizationsGet({
            projectId: selectedProject.id,
            enqueueSnackbar,
          })
        );
      }
    }

    return () => null;
  }, [patientOrg, id]);

  useEffect(() => {
    if (patientOrg || !selectedProject) return;

    dispatch(
      projectCompanyListGet({
        projectId: selectedProject.id,
        enqueueSnackbar,
        overviewInstance: true,
      })
    );
  }, [patientOrg]);

  useEffect(() => {
    if (patientOrg) {
      setTextOrg("Organizations");

      if (patientOrganizations) {
        if (patientOrganizations.some(({ project }) => project.id === id)) {
          setItemList(
            patientOrganizations.filter((item) => item.state !== "removed")
          );
        } else {
          setItemList([]);
        }

        return;
      }

      return;
    }

    if (overviewCompanies) {
      const items = overviewCompanies?.filter(
        (item) => item.state !== "removed"
      );
      setItemList(items);
    }

    return () => null;
  }, [overviewCompanies, patientOrganizations, patientOrg]);

  const handleAddCallback = (selectedCompanies) => {
    const stage = stagesList[0];

    if (patientOrg) {
      const multiPatientOrgPayload = {
        project_patient_organizations: selectedCompanies.map((s) => ({
          project_id: selectedProject?.id,
          gsk_patient_organization_id: s.id,
          added_by_id: session.id,
          stage_id: stage.id,
        })),
        project_id: selectedProject?.id,
        council_id: selectedCouncil.id,
        patientOrg: true,
        stage_id: stage.id,
        enqueueSnackbar,
      };

      dispatch(projectPatientOrgNewMultiple(multiPatientOrgPayload));
    } else {
      const multiCompaniesPayload = {
        project_companies: selectedCompanies.map((s) => {
          if (s.is_product) {
            return {
              project_id: selectedProject.id,
              council_product_id: s.id,
              added_by_id: session.id,
              stage_id: stage.id,
              is_product: true,
            };
          }
          return {
            project_id: selectedProject.id,
            company_id: s.id,
            added_by_id: session.id,
            stage_id: stage.id,
          };
        }),
        project_id: selectedProject.id,
        council_id: selectedCouncil.id,
        patientOrg: false,
        stage_id: stage.id,
        enqueueSnackbar,
        overviewInstance: true,
      };

      dispatch(projectCompanyNewMultiple(multiCompaniesPayload));
    }

    popup.hide();
  };

  const handleAddCompanyClick = () => {
    let title;

    if (isFordSupplyChainAutomation) {
      title = "Add Partners to Project";
    } else {
      title = `Add ${
        patientOrg && patientOrg ? "an Organization" : "a Company"
      } to Project`;
    }

    popup.show({
      title,
      component: (
        <MixedAddCompanyOrProducts
          patientOrg={patientOrg}
          handleSave={handleAddCallback}
          placeholder="Type to search or select from below"
          label={patientOrg ? "Organizations" : "Companies or Products"}
          existingItems={itemlist.map((i) => ({
            ...i,
            id: i.company?.id || i.id,
          }))}
          // hideProducts
        />
      ),
    });
  };

  const byOrgType = (item) => {
    if (patientOrg) {
      return item.gsk_patient_organization
        ? item.gsk_patient_organization
        : item.company;
    }
    return item.company;
  };

  const handleCompanyRedirect = (item) => {
    if (item.is_product) {
      if (patientOrg) {
        history.push(
          `/patient_organizations/${
            item.product.slug || item.company.id
          }/products/${item.product.slug || item.product.id}`
        );
      } else {
        history.push(
          `/companies/${item.company.slug || item.company.id}/products/${
            item.product.slug || item.product.id
          }`
        );
      }

      return;
    }

    if (patientOrg) {
      history.push(`/patient_organizations/${item.slug || item.id}`);
    } else {
      history.push(`/companies/${item.slug || item.id}`);
    }
  };

  const goToAll = () => {
    if (selectedProject && selectedProject.id) {
      window.history.replaceState(
        null,
        patientOrg ? "Organizations" : "Companies",
        `/projects/${selectedProject.id}/companies`
      );
      window.location.reload();
    }
  };

  const isFordSupplyChainAutomation = useMemo(() => {
    return (
      selectedCouncil?.name === "Ford" &&
      selectedProject?.stage_template?.name === "Supply Chain Automation"
    );
  }, [selectedCouncil, selectedProject]);
  const rightContent = useCallback(() => {
    return (
      <>
        <div
          className="d-flex justify-content-center cursor-pointer "
          onClick={() => setExpanded((prev) => !prev)}
        >
          <Icon
            {...smSize}
            icon="icn-table-down-chevron"
            className={classNames(
              styles.icon,
              expanded ? styles.collapse : styles.collapseUp
            )}
          />
        </div>
      </>
    );
  }, [expanded]);

  return (
    <Pannel
      rightContent={rightContent()}
      title={
        isFordSupplyChainAutomation ? "Project Partners" : `Project ${textOrg}`
      }
      padding="0"
      withCollapse
      expanded={expanded}
    >
      <div className={styles.panelHeader}>
        {isFordSupplyChainAutomation ? "Partners" : `${textOrg}`}
        {!blockFunctionality ? (
          <Icon
            {...mdSize}
            icon="icn-add"
            className={styles.addNew}
            onClick={handleAddCompanyClick}
          />
        ) : (
          <Tooltip
            title={`You have View-Only access. To add ${
              isFordSupplyChainAutomation ? "Partners" : "Companies"
            } in this Project please ask your Admin to upgrade your account to Standard access and invite you to this Project`}
            placement="top"
          >
            <div className={styles.addNewBlock}>
              <Icon {...mdSize} icon="icn-add" className={styles.addNew} />
            </div>
          </Tooltip>
        )}
      </div>
      <div className={styles.wrapper}>
        {!itemlist ? <LoadingComponent /> : null}
        {itemlist && itemlist.length > 0 ? (
          itemlist.slice(0, 6).map((item, index) => (
            <div
              key={item.is_product ? item.id : `${byOrgType(item).id}_${index}`}
              className={classNames(styles.Row)}
              onClick={() =>
                handleCompanyRedirect(item.is_product ? item : byOrgType(item))
              }
            >
              {item.is_product ? (
                <>
                  <div className={styles.companyIcon}>
                    <div className={styles.iconCon}>
                      <CompanyAvatar
                        imgSrc={item.product.logo}
                        name={item.product.name}
                      />
                    </div>
                  </div>
                  <div>{item.product.name}</div>
                  <span className="small text-muted ml-4">Product</span>
                </>
              ) : (
                <>
                  <div className={styles.companyIcon}>
                    <div className={styles.iconCon}>
                      <CompanyAvatar
                        imgSrc={byOrgType(item).logo}
                        name={byOrgType(item).name}
                      />
                    </div>
                  </div>
                  <div>{byOrgType(item).name}</div>
                  <div className={styles.statusWrp}>
                    <CompanyState state={item.state} type={stateType.ICON} />
                  </div>
                </>
              )}
            </div>
          ))
        ) : (
          <span className="p-3 text-center">
            No {isFordSupplyChainAutomation ? "partners" : "companies"} yet!
          </span>
        )}
        {itemlist && itemlist.length > 6 ? (
          <div onClick={goToAll} className={styles.showAll}>
            Show All {patientOrg ? "Organizations" : "Companies"}
          </div>
        ) : null}
      </div>
    </Pannel>
  );
};

export default ProjectCompaniesComponent;

import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  Search,
  Button,
  BtnType,
  Label,
  TextBox,
  UploadImgComponent,
} from "modules/primitives/index";
import UserMultiSelectSearch from "common/components/usersSearch";
import { ModalFooter, ModalBody } from "../../../../application/modal";
import { councilUsersListGet } from "../../../../common/actions/common.action";
import { useModelPopup } from "../../../../common/hooks";
import { initModelSpeaker, speakerValidation } from "../newEvent.constants";
import styles from "./addSpeaker.module.scss";

const AddSpeaker = ({ handleSetSpeaker, speakers }) => {
  const dispatch = useDispatch();
  const speakerFormRef = useRef();
  const [imageUrl, setImageUrl] = useState("");
  const [company, setCompany] = useState("");
  const [editable, setEditable] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil, usersList },
  } = useSelector((state) => state);

  const onImgUpdate = (url) => {
    setImageUrl(url);
  };

  const handleCancelClick = (e) => {
    popup.hide();
  };

  const handleSetSelectedSpeaker = (setFieldValue, e) => {
    e.forEach((element) => {
      element.id = "";
    });

    if (e?.length > 1) {
      setSelectedOptions(e);
    } else if (e?.length === 1) {
      setFieldValue("council_speaker", e[0]);
      setFieldValue("avatar", e[0].avatar);
      setFieldValue("name", e[0].full_name);
      setFieldValue("title", e[0].title);
      setFieldValue("company", e[0].company);
      setSelectedOptions(e);
      setEditable(true);
    } else {
      setFieldValue("council_speaker", {});
      setFieldValue("avatar", "");
      setFieldValue("name", "");
      setFieldValue("title", "");
      setFieldValue("company", "");
      setSelectedOptions(e);
      setEditable(false);
    }
  };

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(councilUsersListGet(selectedCouncil.id, enqueueSnackbar));
    }
  }, [selectedCouncil]);

  const handleOnSubmit = (values) => {
    if (company && company.name) {
      values.company = company.name;
      values.organization = company.name;
    }
    if (values.council_speaker) {
      values.user_id = values.council_speaker.id;
      values.council_speaker.id = "";
      handleSetSpeaker([...selectedOptions]);
    } else {
      values.user_id = null;
      handleSetSpeaker([values]);
    }
    popup.hide();
  };

  return (
    <div className={styles.wrapper}>
      <Formik
        innerRef={speakerFormRef}
        enableReinitialize
        initialValues={initModelSpeaker}
        validationSchema={speakerValidation && speakerValidation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleOnSubmit(values);
          resetForm();
        }}
      >
        {({ handleSubmit, setFieldValue, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody noFixedHeight={!(selectedOptions.length < 2)}>
              <div className="row">
                <div className="col-12">
                  <UserMultiSelectSearch
                    placeholder="Choose speaker"
                    labelField="full_name"
                    valueField="user_id"
                    name="council_speaker"
                    searchBy="full_name"
                    onChange={(e) => handleSetSelectedSpeaker(setFieldValue, e)}
                    formProps={formikprops}
                    sharedUsers={speakers.map((el) => ({ id: el.user_id }))}
                  />
                  {selectedOptions.length < 2 && (
                    <div>
                      <div className={styles.addImage}>
                        <UploadImgComponent
                          label=""
                          name="avatar"
                          uploadDirName={
                            imageUrl
                              ? imageUrl.substring(
                                  imageUrl.indexOf(".com/") + 5,
                                  imageUrl.indexOf("_") + 1
                                )
                              : `speakers/key/${(+new Date()).toString(36)}_`
                          }
                          image={formikprops.values.avatar || imageUrl}
                          uploadBlockStyle={styles.uploadBlockStyle}
                          imgStyle={styles.imgStyle}
                          imgSize={styles.imgSize}
                          choosePhoto={styles.choosePhoto}
                          allowRemovePhoto={!editable}
                          onChange={(e) => {
                            onImgUpdate(e);
                            setFieldValue("avatar", e);
                          }}
                          formProps={formikprops}
                          speaker
                        />
                      </div>
                      <Label>
                        Name{" "}
                        <span style={{ color: "red", marginTop: "-5px" }}>
                          *
                        </span>
                      </Label>
                      <TextBox
                        type="text"
                        name="name"
                        className={styles.textBox}
                        placeholder="Enter speaker's name"
                        disabled={editable}
                        formProps={formikprops}
                      />
                      <Label>Title</Label>
                      <TextBox
                        type="text"
                        name="title"
                        disabled={editable}
                        className={styles.textBox}
                        placeholder="Enter speaker's title"
                        formProps={formikprops}
                      />
                      {(speakerFormRef.current?.values.company ||
                        !formikprops.values.council_speaker) && (
                        <Label>Company</Label>
                      )}

                      {!speakerFormRef.current?.values.company &&
                        !formikprops.values.council_speaker && (
                          <div>
                            <Search
                              responses={company}
                              setSearchResponses={setCompany}
                              className={styles.searchBox}
                              placeholder={
                                company ? company.name : "Type to search..."
                              }
                              from="speakers"
                            />
                          </div>
                        )}

                      {speakerFormRef.current?.values.company && (
                        <TextBox
                          type="text"
                          name="company"
                          disabled={editable}
                          className={styles.textBox}
                          placeholder="Speaker's company"
                          formProps={formikprops}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter className={styles.footer}>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button type="submit" btn={BtnType.REGULAR}>
                Add a speaker
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(AddSpeaker);

export const headerMenu = [
  { path: "/overview", text: "Overview" },
  { path: "/info-requests", text: "RFI" },
];

export const homeXHeaderMenu = [
  ...headerMenu,
  { path: "/ideas", text: "Ideas" },
  { path: "/themes", text: "Themes" },
];

import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import styles from '../../../tractionAI.module.scss';
import TypingText from "modules/tractionAI/components/typingText";
import { httpPost } from "../../../../../common/httpCall";
import { AILoadMoreScoutData, AISetScoutData } from "modules/tractionAI/store/tractionAI.actions";
import RecommendItemResponse from "modules/tractionAI/screens/recommend/ideas/recommendItemResponse";

const TractionAIRecommendIdeas = () => {
  const dispatch = useDispatch();
  const scrollableDivRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const {
    tractionAiReducer: { recommend: { ideas } }
  } = useSelector((state) => state);

  useEffect(() => {
    document.getElementById('bottomAI').scrollIntoView();
  }, []);

  useEffect(() => {
    if (ideas.fetching && scrollableDivRef?.current) {
      document.getElementById('bottomAI').scrollIntoView({
        behavior: 'instant'
      });
    }
    if (!ideas.fetching && scrollableDivRef?.current) {
      document.getElementById('bottomAI').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [ideas.fetching, ideas.data, scrollableDivRef]);

  const loadMore = (item, index) => {
    dispatch(AILoadMoreScoutData({
      fetching: true,
      createNewSearchItem: false,
      fetched: false,
      isLoadingMore: true,
      loadMoreIndex: index,
    }));

    httpPost({
      call: "ai/proxy/streaming",
      data: {
        endpoint: "recommend_ideas",
        exclude_ideas: item.data.map(c => c.idea_id),
        prompt: item.searchByQuery,
        idea_id: item.ideaId,
      }
    }).subscribe(() => {}, (error) => {
      console.error('Error occurred in recommend:', error);
      dispatch(AISetScoutData({
        hasError: true,
        errorMsg: error.response.error,
        errorCode: error.response.status,
        errorType: 'serverError',
        fetched: true,
        fetching: false,
      }));
    });
  };

  const handleTryAgain = (item) => {
    dispatch(AISetScoutData({
      fetching: true,
      searchByQuery: item.searchQuery,
      ideaId: item.ideaId,
      isIdeasSearch: item.ideaId.length === 0,
      createNewSearchItem: false,
      fetched: false,
    }));
  };

  return (
    <div ref={scrollableDivRef}>
      <div className={`d-flex w-100 flex-column mb-4 ${styles.resultsWrapper}`}>
        {
          (!ideas.data?.length && !ideas.fetching) ? (
            <div className="mb-5 mt-5 d-flex align-items-center justify-content-center">
              <TypingText
                text="Traction AI: “Please answer the following question in as much detail as possible. The more information you provide, the better my answers will be."
              />
            </div>
          ) : null
        }
        <div className="d-flex flex-column">
          {
            ideas.data.map((item, index) => (
              <RecommendItemResponse
                key={index}
                item={item}
                index={index}
                loadMore={loadMore}
                enqueueSnackbar={enqueueSnackbar}
                handleTryAgain={handleTryAgain}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default React.memo(TractionAIRecommendIdeas);

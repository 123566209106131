import * as Yup from "yup";
import { objectType } from "../../../../modules/companies/company/details/details.constant";

export const validation = Yup.object().shape({
  // title: Yup.string().required('Title is required'),
});

export const model = {
  type: objectType.image,
  title: "",
  link: "",
};

export const postFilterOptions = [
  { text: "All", value: "all" },
  { text: "Newest", value: "newest" },
  { text: "Oldest", value: "oldest" },
  { text: "Favorites", value: "favorites" },
  { text: "Likes", value: "likes" },
  { text: "Attachments", value: "documents" },
  { text: "By Author", value: "author", type: "search" },
];
export const homeFilterOptions = [
  { text: "All", value: "all" },
  { text: "Newest", value: "newest" },
  { text: "Oldest", value: "oldest" },
  { text: "My Themes", value: "my_themes" },
  { text: "Favorites", value: "favorites" },
  { text: "Likes", value: "likes" },
  { text: "Attachments", value: "documents" },
  { text: "By Author", value: "author", type: "search" },
];

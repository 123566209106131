import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { useSelector } from "react-redux";

import { DropSelect, Label } from "modules/primitives";
import styles from "./styles.module.scss";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { httpGet } from "../../../httpCall";

const ProjectReplication = ({ formikprops, disabled, isAdminWizardInstance }) => {
  const [projects, setProjects] = useState([]);

  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (isAdminWizardInstance) return;

    if (selectedCouncil?.id) {
      httpGet({
        call: 'projects/related_projects/dropdown'
      }).subscribe(res => {
        const data = res.response || [];
        setProjects(data);
      });
    }
  }, [selectedCouncil]);

  return (
    <div className="d-flex flex-column w-100 mb-3">
      <Label>Is this a Replication?</Label>
      <div className="d-flex align-items-center ml-5">
        <div className="w-25 position-relative">
          <label className={styles.formCheckLabel} htmlFor="originalOrReplicationYes">
            <Field
              disabled={disabled}
              id="originalOrReplicationYes"
              name="originalOrReplicationYes"
              type="radio"
              className={styles.formCheckInput}
              checked={formikprops.values?.project_replication}
              onChange={() => formikprops.setFieldValue("project_replication", true)}
            />
            Yes
          </label>
        </div>
        <div className="w-25 position-relative">
          <label className={styles.formCheckLabel} htmlFor="originalOrReplicationNo">
            <Field
              disabled={disabled}
              id="originalOrReplicationNo"
              name="originalOrReplicationNo"
              type="radio"
              className={styles.formCheckInput}
              checked={!formikprops.values?.project_replication}
              onChange={() => formikprops.setFieldValue("project_replication", false)}
            />
            No
          </label>
        </div>
      </div>
      <div className="caption small text-danger">
        {formikprops.touched['project_replication'] && formikprops.errors['project_replication']
          ? formikprops.errors['project_replication']
          : ""}
      </div>
      {
        formikprops.values?.project_replication && (
          <div className="mb-3 mt-3">
            {
              !projects ? (
                <LoadingComponent customText="Getting Projects..." />
              ) : (
                <>
                  <Label>Select Original Project</Label>
                  <DropSelect
                    onChange={(value) => {
                      formikprops.setFieldValue("original_project_id", value.val);
                    }}
                    placeholder="Select Original Project"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    options={projects || []}
                    values={formikprops.values.original_project_id && projects
                      ? projects?.filter(p => p.id === formikprops.values.original_project_id)
                      : []
                    }
                  />
                </>
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default React.memo(ProjectReplication);

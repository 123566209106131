import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { CheckBox } from "../../../../../primitives";
import CheckBoxesFieldsBlock from "../checkBoxesFieldsBlock";
import styles from "./styles.module.scss";
import {
  autoPopulateRelationalTypes,
  fieldToRemoveOnSyncOff,
  fieldToRemoveOnSyncOn,
} from "../../adminApplicantForms.constants";

const defaultCheckboxOptions = [
  {
    label: "Leadership",
    name: "leadership",
    isChecked: true,
    disabled: true,
  },
  {
    label: "Total Funding Amount",
    name: "totalFundingAmount",
    isChecked: true,
    disabled: true,
  },
  {
    label: "Last Funding Amount",
    name: "lastFundingAmount",
    isChecked: true,
    disabled: true,
  },
  {
    label: "Last Funding Round",
    name: "lastFundingRound",
    isChecked: true,
    disabled: true,
  },
  {
    label: "Last Funding Date",
    name: "lastFundingDate",
    isChecked: true,
    disabled: true,
  },
  {
    label: "Investors",
    name: "investors",
    isChecked: true,
    disabled: true,
  },
];

const StepTwoComponent = ({ formikprops }) => {
  const { values, setFieldValue } = formikprops;
  const [customFieldsOptions, setCustomFieldsOptions] = useState([]);
  const [checkboxOptions, setCheckboxOptions] = useState(
    defaultCheckboxOptions,
  );
  const [selectAll, setSelectAll] = useState(false);

  const {
    adminApplicantsFormsReducer: { formCustomFields, form },
  } = useSelector((state) => state);

  const setAutoPopulateFields = (name, val) => {
    const optionIndex = values.auto_populate_fields.indexOf(name);
    if (optionIndex > -1 && !val) {
      values.auto_populate_fields.splice(optionIndex, 1);
      setCheckboxOptions(
        checkboxOptions.map((option) => {
          if (option.name === name) {
            return { ...option, isChecked: false };
          }
          return option;
        }),
      );
    } else {
      values.auto_populate_fields.push(name);
      setCheckboxOptions(
        checkboxOptions.map((option) => {
          if (option.name === name) {
            return { ...option, isChecked: true };
          }
          return option;
        }),
      );
    }
    setFieldValue("auto_populate_fields", values.auto_populate_fields);

    setSelectAll(values.auto_populate_fields.length === checkboxOptions.length);
  };

  const onSelectAllChange = (val) => {
    const newOptions = checkboxOptions.map((r) => ({
      ...r,
      isChecked: val,
    }));

    setCheckboxOptions(newOptions);

    if (!val) {
      setFieldValue("auto_populate_fields", []);
    } else {
      setFieldValue(
        "auto_populate_fields",
        newOptions.map(({ name }) => name),
      );
    }
  };

  useEffect(() => {
    if (formCustomFields.length > 0) {
      const customFieldsCheckboxOptions = formCustomFields.reduce(
        (acc, field) => {
          if (
            !field.default
            && autoPopulateRelationalTypes.includes(field.relation_field)
          ) {
            acc.push({
              label: field.field_name,
              name: field.id,
              isChecked: true,
              disabled: true,
            });
          }
          return acc;
        },
        [],
      );

      setCustomFieldsOptions(customFieldsCheckboxOptions);
      setCheckboxOptions([
        ...customFieldsCheckboxOptions,
        ...defaultCheckboxOptions,
      ]);
    }
  }, [formCustomFields]);

  useEffect(() => {
    if (values.sync_with_crunchbase) {
      setFieldValue("auto_populate_fields", []);

      const newCheckBoxOptions = [
        ...customFieldsOptions,
        ...defaultCheckboxOptions,
      ].filter((option) => !fieldToRemoveOnSyncOn.includes(option.label));

      setCheckboxOptions(newCheckBoxOptions);

      // In case Form is edited and sync_with_crunchbase is true
      // and original form contains fieldToRemoveOnSyncOn
      // we need to add this fields back  otherwise
      // this fields will not be submitted to BE
      if (form && values.additional_fields.length) {
        const fieldIdsToBeAdded = form.applicant_form_fields.reduce(
          (
            acc,
            {
              applicant_available_form_field: field,
              applicant_available_form_field_id: fieldId,
            },
          ) => {
            if (fieldToRemoveOnSyncOn.includes(field.field_name)) {
              acc.push(fieldId);
            }
            return acc;
          },
          [],
        );
        setFieldValue("additional_fields", [
          ...new Set([...values.additional_fields, ...fieldIdsToBeAdded]),
        ]);
      }
    } else {
      const newCheckBoxOptions = customFieldsOptions.reduce((acc, r) => {
        const option = {
          ...r,
          disabled: false,
          isChecked: values.auto_populate_fields.includes(r.name),
        };

        if (fieldToRemoveOnSyncOff.includes(option.label)) {
          return acc;
        }

        acc.push(option);
        return acc;
      }, []);

      setCheckboxOptions(newCheckBoxOptions);

      // In case Form is edited and sync_with_crunchbase is false
      // and original form contains fieldToRemoveOnSyncOn
      // we need to remove this fieldToRemoveOnSyncOn fields otherwise
      // this fields will be submitted to BE
      if (form && values.additional_fields.length) {
        const fieldIdsToBeRemoved = form.applicant_form_fields.reduce(
          (
            acc,
            {
              applicant_available_form_field: field,
              applicant_available_form_field_id: fieldId,
            },
          ) => {
            if (fieldToRemoveOnSyncOn.includes(field.field_name)) {
              acc.push(fieldId);
            }
            return acc;
          },
          [],
        );
        setFieldValue(
          "additional_fields",
          values.additional_fields.filter(
            (el) => !fieldIdsToBeRemoved.includes(el),
          ),
        );
      }
    }
  }, [customFieldsOptions, values.sync_with_crunchbase]);

  useEffect(() => {
    if (checkboxOptions.length === values.auto_populate_fields.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [checkboxOptions]);

  const renderSelectAllCheckbox = () => (
    <div className="d-flex align-items-center mt-3">
      <CheckBox isChecked={selectAll} onChange={onSelectAllChange} />
      <div
        className={styles.cursorPointer}
        onClick={() => onSelectAllChange(!selectAll)}
      >
        Select All
      </div>
    </div>
  );

  const renderNoCrunchBaseSync = () => (
    <>
      <div className={styles.formElement}>
        <span>
          Select fields to be added to form to be filled out by Applicants
        </span>
      </div>

      {renderSelectAllCheckbox()}
    </>
  );

  return (
    <div className={styles.createFormStepTwo}>
      <div className={styles.formElement}>
        <div className="form-check pl-0">
          <span className="mr-5">
            Populate the following fields from Crunchbase if Company has a
            Crunchbase Profile?
          </span>
          <label className="form-check-label mr-5">
            <input
              className="form-check-input"
              type="radio"
              name="sync_with_crunchbase"
              value="true"
              onChange={() => setFieldValue("sync_with_crunchbase", true)}
              checked={values?.sync_with_crunchbase === true}
            />
            Yes
          </label>

          <label className="form-check-label">
            <input
              className="form-check-input"
              type="radio"
              name="sync_with_crunchbase"
              value="false"
              onChange={() => setFieldValue("sync_with_crunchbase", false)}
              checked={values?.sync_with_crunchbase === false}
            />
            No
          </label>
        </div>
      </div>

      {values?.sync_with_crunchbase === false && renderNoCrunchBaseSync()}

      <div className={classnames(styles.formElement, "mt-0")}>
        <CheckBoxesFieldsBlock
          options={checkboxOptions}
          isBlockDisabled={values?.sync_with_crunchbase}
          setFieldValue={setAutoPopulateFields}
        />
      </div>
    </div>
  );
};

export default React.memo(StepTwoComponent);

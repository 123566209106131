import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import isKyndrylViewOnly from "common/isKyndrylViewOnly";
import { checkFirmenichUseCase } from "common/helper";
import { appModelSettingSet } from "application/app.action";
import styles from "./companyTopActions.module.scss";
import { BtnType, Button, MoreOptions } from "../../../../primitives";
import {
  companyUnFollow,
  suggestionFollow,
} from "../../../../../common/actions/common.action";
import { useModelPopup } from "../../../../../common/hooks";
import RequestResearchComponent from "../../popups/requestResearch";
import NewEventComponent from "../../activity/popups";
import { companyMoreOptions, moreOptionTypes } from "../../company.constant";
import NewDiscussion from "../../../../../common/components/newDiscussion";
import ShareWithComponent from "../../popups/shareWith";
import AddToIdeaComponent from "../../../companyMain/addToIdea/addToIdea.component";
import AddToListComponent from "../../../../lists/components/addToList";
import AddCompanyComponent from "../../../companyMain/addToProject";
import SaveSearchComponent from "../../../companyMain/rightWindow/saveSearch";
import { useWindowSize } from "../../../../../common/hooks/useWindowSize";
import { companyMobileMoreOptions } from "./companyTopActions.constant";
import TractionDownload from "../../../../../common/components/tractionDownload";
import { companySetDownloadParams } from "../../company.action";
import CompanyDownload from "../../companyDownload";
import { Icon, smSize } from "../../../../../common/icon";
import AddToThemeComponent from "../../../../themes/themesCommon/addCompanyToTheme";
import NewProjectV3 from "modules/projects/newProjectv3";

const CompanyTopActions = ({ isAdmin, blockFunctionality, id }) => {
  const {
    appReducer: { modalSetting },
    authReducer: { session },
    companiesReducer: {
      companyReducer: { selectedCompany, companyIsDownloading },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const { isMobile } = useWindowSize();
  const [companyOptions, setCompanyOptions] = useState(companyMoreOptions);
  const [councilRole, setCouncilRole] = useState("");
  const [shouldOpenAddNewProjectModal, setShouldOpenAddNewProjectModal] =
    useState(false);

  useEffect(() => {
    const council_role = session?.council_role;

    if (council_role) {
      setCouncilRole(council_role);
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      let options = companyMobileMoreOptions.filter((option) => {
        return !(
          selectedCouncil?.settings?.ideas_connected === false &&
          option.text === "Add to Idea"
        );
      });

      if (blockFunctionality) {
        options = companyMoreOptions
          .filter((option) => {
            if (
              selectedCouncil?.settings?.ideas_connected === false &&
              option.text === "Add to Idea"
            ) {
              return false;
            }
            return (
              option.text !== "Add to project" && option.text !== "Discuss"
            );
          })
          .map((opt) => {
            if (opt.text === "Add to theme") {
              return {
                ...opt,
                text: `Add to ${
                  selectedCouncil?.settings?.themes_display_name_singular ||
                  "theme"
                }`,
              };
            }
            return opt;
          });
      }

      if (selectedCompany?.current_user_follow_relationship_id?.length) {
        options = options
          .map((o) => {
            if (o.val === "follow") {
              return {
                ...o,
                text: "Following",
              };
            }

            return o;
          })
          .map((opt) => {
            if (opt.text === "Add to theme") {
              return {
                ...opt,
                text: `Add to ${
                  selectedCouncil?.settings?.themes_display_name_singular ||
                  "theme"
                }`,
              };
            }
            return opt;
          });
      }

      if (
        councilRole === "ttp_administrator" ||
        councilRole === "council_administrator"
      ) {
        options = [{ val: "edit", text: "Edit Company" }, ...options];
      }

      setCompanyOptions(options);

      return;
    }

    if (blockFunctionality) {
      const filteredOptions = companyMoreOptions
        .filter((option) => {
          if (
            selectedCouncil?.settings?.ideas_connected === false ||
            option.text === "Add to Idea"
          ) {
            return false;
          }
          return (
            option.text !== "Add to project" &&
            option.text !== "Discuss" &&
            option.text !== "Add to theme"
          );
        })
        .map((opt) => {
          if (opt.text === "Add to theme") {
            return {
              ...opt,
              text: `Add to ${
                selectedCouncil?.settings?.themes_display_name_singular ||
                "theme"
              }`,
            };
          }
          return opt;
        });

      setCompanyOptions(filteredOptions);
    }
  }, [blockFunctionality, isMobile, selectedCompany, councilRole]);

  const handleFollowClick = (e, c) => {
    if (e) {
      e.stopPropagation();
    }

    if (selectedCompany?.current_user_follow_relationship_id?.length) {
      makeUnfollow();
    } else {
      makeFollow(c);
    }
  };

  const makeUnfollow = () => {
    dispatch(
      companyUnFollow({
        data: {
          follow_relationship_id:
            selectedCompany?.current_user_follow_relationship_id,
        },
        enqueueSnackbar,
      })
    );
  };

  const makeFollow = (company) => {
    dispatch(
      suggestionFollow({
        data: {
          ...company,
          company_id: company.id,
          name: company.name,
        },
        enqueueSnackbar,
      })
    );
  };

  const handleNewProjectClick = () => {
    setShouldOpenAddNewProjectModal(true);
  };

  const handleRequestResearch = () => {
    popup.show({
      title: "Request Information",
      component: <RequestResearchComponent company={selectedCompany} />,
    });
  };

  const handleAddNoteClick = () => {
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        title: "Capture New Note",
        show: true,
        height: String(window.innerHeight),
        width: String(window.innerWidth / 1.5),
        component: (
          <NewEventComponent
            company={selectedCompany}
            companyId={id}
            type="note"
            customTitle="Capture New Note"
            shouldRedirectToActivity
          />
        ),
      })
    );
  };

  const handleEditClick = () => {
    history.push(`/admin/companies/edit/${selectedCompany.slug}`, {
      gsk: false,
    });
  };

  const handleMoreOptionClick = (val) => {
    let obj = {};

    switch (val) {
      case "edit": {
        handleEditClick();
        return;
      }

      case "requestResearch": {
        handleRequestResearch();
        return;
      }

      case "note": {
        handleAddNoteClick();
        return;
      }

      case "follow": {
        handleFollowClick(null, selectedCompany);
        return;
      }

      case "newProject": {
        handleNewProjectClick();
        return;
      }

      case moreOptionTypes.discuss:
        obj = {
          title: "Start a New Discussion",
          show: true,
          height: "300",
          width: "540",
          component: (
            <NewDiscussion companyId={selectedCompany.id} type="Company" />
          ),
        };
        break;

      case moreOptionTypes.share:
        obj = {
          title: "Share With",
          show: true,
          height: "300",
          width: "540",
          component: (
            <ShareWithComponent
              actionType="shareCompany"
              selectedCompany={selectedCompany}
            />
          ),
        };
        break;
      case moreOptionTypes.downloadReport:
        obj = {
          title: "Request Research",
          show: true,
          height: "1000",
          width: "540",
          component: <RequestResearchComponent company={selectedCompany} />,
        };
        break;
      case moreOptionTypes.addToIdea:
        obj = {
          title: "Add To Idea",
          show: true,
          height: "1000",
          width: "540",
          component: (
            <AddToIdeaComponent
              company={selectedCompany}
              selectedCouncil={selectedCouncil}
              enqueueSnackbar={enqueueSnackbar}
            />
          ),
        };
        break;
      case moreOptionTypes.addToList:
        obj = {
          title: "Add To List",
          show: true,
          height: "1000",
          width: "540",
          component: (
            <AddToListComponent
              companyId={id}
              enqueueSnackbar={enqueueSnackbar}
              setShowMyList={() => {}}
              getMyOnlyLists
            />
          ),
        };
        break;
      case moreOptionTypes.addToProject:
        obj = {
          title: "Add Company To Project",
          show: true,
          height: "1000",
          width: "540",
          component: (
            <AddCompanyComponent
              companyId={id}
              enqueueSnackbar={enqueueSnackbar}
            />
          ),
        };
        break;
      case moreOptionTypes.createNewList:
        obj = {
          title: "Create New List",
          show: true,
          height: "1000",
          width: "540",
          component: (
            <SaveSearchComponent
              handleSaveHide={() => popup.hide()}
              setShowMyList={() => {}}
              searchResults={[{ id }]}
              mode="redirect"
            />
          ),
        };
        break;
      case moreOptionTypes.addToTheme:
        obj = {
          title: `Add To ${
            selectedCouncil?.settings?.themes_display_name_singular || "Theme"
          }`,
          show: true,
          height: "1000",
          width: "540",
          component: (
            <AddToThemeComponent
              company={selectedCompany}
              selectedCouncil={selectedCouncil}
              enqueueSnackbar={enqueueSnackbar}
            />
          ),
        };
        break;
      default:
        break;
    }
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...obj,
      })
    );
  };

  const handleDownload = () => {
    popup.show({
      title: "Select company tabs to download",
      component: (
        <CompanyDownload
          selectedCompany={selectedCompany}
          handleDownload={handleDownloadClose}
        />
      ),
    });
  };

  const handleDownloadClose = (tabs) => {
    if (tabs?.length) {
      dispatch(companySetDownloadParams(tabs));
    }

    popup.hide();
  };

  const handleDownloadState = (status) => {
    if (!status) {
      dispatch(companySetDownloadParams([]));
    }
  };

  return (
    <div className={styles.companyActionsWrapper}>
      {isMobile && !companyIsDownloading ? (
        <div style={styles.mobileWrapper}>
          <MoreOptions
            options={companyOptions.map((opt) => {
              if (opt.text === "Add to theme") {
                return {
                  ...opt,
                  text: `Add to ${
                    selectedCouncil?.settings?.themes_display_name_singular ||
                    "theme"
                  }`,
                };
              }
              return opt;
            })}
            className={styles.moreOptWrp}
            onClick={handleMoreOptionClick}
            customSvgIcon
          />
          <NewProjectV3
            isAdmin={isAdmin}
            blockFunctionality={blockFunctionality}
            wizardType="company"
            additionalData={selectedCompany}
            btnName="New Project"
            className={styles.button}
            shouldOpen={shouldOpenAddNewProjectModal}
            handleDismiss={() => setShouldOpenAddNewProjectModal(false)}
            hideBtn
          />
        </div>
      ) : !companyIsDownloading ? (
        <div className={styles.firstRow}>
          <Button
            className={styles.button}
            btn={
              selectedCompany?.current_user_follow_relationship_id?.length
                ? BtnType.HIGLIGHT
                : BtnType.OUTLINE
            }
            onClick={(e) => handleFollowClick(e, selectedCompany)}
            icon={
              selectedCompany?.current_user_follow_relationship_id?.length
                ? "icn-checkbox-button"
                : "icn-add"
            }
          >
            {selectedCompany?.current_user_follow_relationship_id?.length
              ? "Following"
              : "Follow"}
          </Button>
          {selectedCouncil?.name === "Kyndryl" ? (
            isAdmin ? (
              <NewProjectV3
                isAdmin={isAdmin}
                blockFunctionality={blockFunctionality}
                wizardType="company"
                additionalData={selectedCompany}
                btnName="New Project"
                className={styles.button}
              />
            ) : null
          ) : !blockFunctionality ? (
            <NewProjectV3
              isAdmin={isAdmin}
              blockFunctionality={blockFunctionality}
              wizardType="company"
              additionalData={selectedCompany}
              btnName="New Project"
              className={styles.button}
            />
          ) : (
            <Tooltip
              title="You have View-Only access. To create a Project, please ask your Admin to upgrade your account to Standard access."
              placement="top"
            >
              <div>
                <Button btn={BtnType.DISABLED} className={styles.button}>
                  New project
                </Button>
              </div>
            </Tooltip>
          )}
          {!isKyndrylViewOnly(selectedCouncil?.name, session?.council_role) && (
            <Button
              btn={BtnType.REGULAR}
              className={styles.button}
              onClick={handleRequestResearch}
            >
              Request Info
            </Button>
          )}
          {!isKyndrylViewOnly(selectedCouncil?.name, session?.council_role) &&
            (!blockFunctionality ? (
              <Button
                btn={BtnType.REGULAR}
                className={styles.button}
                onClick={handleAddNoteClick}
              >
                Add Note
              </Button>
            ) : (
              <Tooltip title="You have View-Only access. To Add a Note, please ask your Admin to upgrade your account to Standard access.">
                <div>
                  <Button btn={BtnType.DISABLED} className={styles.button}>
                    Add Note
                  </Button>
                </div>
              </Tooltip>
            ))}
          {!isKyndrylViewOnly(selectedCouncil?.name, session?.council_role) && (
            <div className={styles.colMore}>
              <MoreOptions
                options={companyOptions
                  .filter((option) => {
                    if (
                      selectedCouncil?.name === "GSK" &&
                      option.text === "Add to Idea"
                    ) {
                      return false;
                    }
                    if (
                      !selectedCouncil?.settings?.ideas_connected &&
                      option.text === "Add to Idea"
                    ) {
                      return false;
                    }
                    return !(
                      !selectedCouncil?.themes_connected &&
                      option.text === "Add to theme"
                    );
                  })
                  .map((opt) => {
                    if (opt.text === "Add to theme") {
                      return {
                        ...opt,
                        text: `Add to ${
                          selectedCouncil?.settings
                            ?.themes_display_name_singular || "theme"
                        }`,
                      };
                    }
                    return opt;
                  })}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            </div>
          )}
        </div>
      ) : null}
      {!isMobile &&
        !isKyndrylViewOnly(selectedCouncil?.name, session?.council_role) && (
          <div className={styles.editBtnContainer}>
            <div className="d-flex mr-4">
              <div className="invisible">
                <TractionDownload
                  handleDownloadState={handleDownloadState}
                  timeoutTime={2000}
                  hidePng
                  shouldDownloadPdf={companyIsDownloading}
                />
              </div>
              <Button
                btn={BtnType.FRAME_LESS}
                onClick={handleDownload}
                className={companyIsDownloading ? "invisible" : ""}
              >
                <div className="d-flex align-items-center">
                  <Icon
                    {...smSize}
                    icon="icn-export-button"
                    className={styles.download}
                  />
                  Download
                </div>
              </Button>
            </div>
            {(isAdmin ||
              checkFirmenichUseCase(
                selectedCouncil?.traction_tag,
                session?.council_role
              )) &&
              !companyIsDownloading && (
                <span className={styles.buttonLess} onClick={handleEditClick}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="m15.879 2.51-1.582 1.582-2.357-2.358L13.522.152l2.357 2.357zM1.105 12.514l-.952 3.333 3.333-.952-2.382-2.381zM11.256 2.383 1.83 11.811l2.357 2.357 9.427-9.428-2.357-2.357z"
                      fill="#1D6F98"
                    />
                  </svg>
                  Edit Company
                </span>
              )}
          </div>
        )}
    </div>
  );
};

export default React.memo(CompanyTopActions);

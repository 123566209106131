export const GET_PLANTS_AND_AREAS = "GET_PLANTS_AND_AREAS";
export const getPlantsAndAreas = (payload) => ({
  type: GET_PLANTS_AND_AREAS,
  payload,
});

export const GET_PLANTS_AND_AREAS_SUCCESS = "GET_PLANTS_AND_AREAS_SUCCESS";
export const getPlantsAndAreasSuccess = (payload) => ({
  type: GET_PLANTS_AND_AREAS_SUCCESS,
  payload,
});

export const EDIT_PLANT = "EDIT_PLANT";
export const editPlant = (payload) => ({
  type: EDIT_PLANT,
  payload,
});

export const EDIT_PLANT_SUCCESS = "EDIT_PLANT_SUCCESS";
export const editPlantSuccess = (payload) => ({
  type: EDIT_PLANT_SUCCESS,
  payload,
});

export const ADD_PLANT = "ADD_PLANT";
export const addPlant = (payload) => ({
  type: ADD_PLANT,
  payload,
});

export const ADD_PLANT_SUCCESS = "ADD_PLANT_SUCCESS";
export const addPlantSuccess = (payload) => ({
  type: ADD_PLANT_SUCCESS,
  payload,
});

export const ADD_AREA = "ADD_AREA";
export const addArea = (payload) => ({
  type: ADD_AREA,
  payload,
});

export const ADD_AREA_SUCCESS = "ADD_AREA_SUCCESS";
export const addAreaSuccess = (payload) => ({
  type: ADD_AREA_SUCCESS,
  payload,
});

export const EDIT_AREA = "EDIT_AREA";
export const editArea = (payload) => ({
  type: EDIT_AREA,
  payload,
});

export const EDIT_AREA_SUCCESS = "EDIT_AREA_SUCCESS";
export const editAreaSuccess = (payload) => ({
  type: EDIT_AREA_SUCCESS,
  payload,
});

export const DELETE_AREA = "DELETE_AREA";
export const deleteArea = (payload) => ({
  type: DELETE_AREA,
  payload,
});

export const DELETE_AREA_SUCCESS = "DELETE_AREA_SUCCESS";
export const deleteAreaSuccess = (payload) => ({
  type: DELETE_AREA_SUCCESS,
  payload,
});

export const DELETE_PLANT = "DELETE_PLANT";
export const deletePlant = (payload) => ({
  type: DELETE_PLANT,
  payload,
});

export const DELETE_PLANT_SUCCESS = "DELETE_PLANT_SUCCESS";
export const deletePlantSuccess = (payload) => ({
  type: DELETE_PLANT_SUCCESS,
  payload,
});

import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "./informationRequests.module.scss";
import ClaimCouncilComponent from "../../council/claimCouncil.component";
import NewTabs from "../../../common/components/newTabs/newTabs.component";
import RFIDetails from "./components/details";
import LoadingComponent from "../../primitives/loading/loading.component";
import {
  getClaimProfileOverview,
  getProfileFormsAttempt,
} from "../store/claimProfileActions";

const ClaimProfileInfoRequests = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    claimProfileReducer: {
      claimReducer: {
        claimProfile: { profile, loading },
      },
      rfiReducer: {
        forms: { formsData },
      },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (
      selectedCouncil?.id
      && !loading
      && !profile
      && session?.invited_company?.id
    ) {
      dispatch(
        getClaimProfileOverview({
          id: session.invited_company.id,
          councilId: selectedCouncil.id,
        }),
      );
    }
  }, [dispatch, selectedCouncil, loading, profile, session]);

  useEffect(() => {
    if (!formsData && session) {
      dispatch(
        getProfileFormsAttempt({
          companyId: session?.invited_company?.id,
        }),
      );
    }
  }, [formsData, session]);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabList = useMemo(
    () => [
      {
        tab: "Details",
        name: "Details",
        component: <RFIDetails />,
      },
      // {
      //   tab: 'Documents',
      //   name: 'Documents',
      //   component: (
      //     <RFIDocuments />
      //   ),
      // },
      // {
      //   tab: 'Discussions',
      //   name: 'Discussions',
      //   component: (
      //     <RFIDiscussions />
      //   ),
      // },
    ],
    [],
  );

  const handleOnTabSelect = useCallback(
    (index) => {
      const objTab = tabList.find((tab, tabIndex) => index === tabIndex);
      history.push(`/info-requests/${objTab.tab.toLowerCase()}`);
      setSelectedIndex(index);
    },
    [tabList, history],
  );

  return (
    <section className={styles.rfiWrapper}>
      <div className="mb-4 d-flex">
        <ClaimCouncilComponent />
      </div>
      {!profile || loading || !selectedCouncil ? (
        <LoadingComponent customText="Loading..." />
      ) : (
        <NewTabs
          tabList={tabList}
          defaultIndex={selectedIndex}
          onSelect={handleOnTabSelect}
          companyUse
        />
      )}
    </section>
  );
};

export default React.memo(ClaimProfileInfoRequests);

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Pagination, Loading } from "modules/primitives";
import { useModelPopup } from "../../../../../common/hooks";
import isUserAdmin from "../../../themesCommon/isUserAdmin/index";
import styles from "./styles.module.scss";
import { Icon, mdSize } from "../../../../../common/icon";
import MixedAddCompanyOrProducts from "../../../../../common/components/mixedSearch/mixedAddCompanyOrProducts";
import ChartCompaniesList from "../../../../companies/companyMain/chartsWrapper/chartCompaniesList/index";
import {
  themesCompanyAddAttempt,
  themesCompanyGetAttempt,
} from "../overviewTab.action";

const KyndrilCompaniesBlock = () => {
  const dispatch = useDispatch();
  const popup = useModelPopup();

  const { enqueueSnackbar } = useSnackbar();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { currentThemeData },
      themesOverviewReducer: { companyList, loadingCompany, companiesPageMeta },
    },
  } = useSelector((state) => state);

  const [seeAll, setSeeAll] = useState(false);
  // const [isCompaniesOpened, setIsCompaniesIsOpenned] = useState(true);

  const [pageSetting, setPageSetting] = useState({
    pageSize: 10,
    current: 1,
    hideOnSinglePage: true,
    showTitle: false,
    total: companiesPageMeta.total || 0,
  });
  // const [chunkToShow, setChunkToShow] = useState([]);

  useEffect(() => {
    if (companiesPageMeta?.total) {
      setPageSetting((prev) => ({
        ...prev,
        ...companiesPageMeta,
      }));
    }
  }, [companiesPageMeta?.total]);

  const handlePagingChange = (page) => {
    setPageSetting((prev) => ({
      ...prev,
      total: companiesPageMeta.total,
      current: page,
    }));
  };

  const themesCallBack = () => {
    if (selectedCouncil && currentThemeData.id) {
      dispatch(
        themesCompanyGetAttempt({
          id: currentThemeData.id,
          enqueueSnackbar,
          page: pageSetting.current,
          items: pageSetting.pageSize,
        })
      );
    }
  };

  const handleAddCallback = (selectedCompanies) => {
    const data = {
      theme_company: selectedCompanies?.map((comp) => {
        if (comp.is_product) {
          return {
            council_product_id: comp.id,
            is_product: true,
          };
        }
        return {
          company_id: comp.id,
          is_product: false,
        };
      }),
    };

    dispatch(
      themesCompanyAddAttempt({
        themeId: currentThemeData.id,
        data,
        activeLayout: "",
        enqueueSnackbar,
        cb: () => {
          popup.hide();
          themesCallBack();
        },
      })
    );
  };
  const handleAddCompanyClick = () => {
    popup.show({
      title: `Add Companies to ${
        selectedCouncil?.settings?.themes_display_name_singular || "Theme"
      }`,
      component: (
        <MixedAddCompanyOrProducts
          handleSave={handleAddCallback}
          placeholder="Type to search or select from below"
          label="Companies or Products"
          existingItems={companyList?.map((c) => ({
            ...c,
            id: c.company_id || c.product_id,
          }))}
        />
      ),
    });
  };
  // useEffect(() => {
  //   if (companyList?.length && !seeAll) {
  //     setChunkToShow(companyList.slice(0, 6));
  //   }
  //   if (pageSetting?.current && companyList?.length && seeAll) {
  //     setChunkToShow(companyList);
  //   }
  // }, [pageSetting?.current, companyList, seeAll]);

  useEffect(() => {
    if (selectedCouncil && currentThemeData.id) {
      dispatch(
        themesCompanyGetAttempt({
          id: currentThemeData.id,
          enqueueSnackbar,
          page: pageSetting.current,
          items: pageSetting.pageSize,
        })
      );
    }
  }, [selectedCouncil, pageSetting.current, seeAll]);

  if (loadingCompany) {
    return <Loading />;
  }
  return (
    <div className={styles.overviewContainer}>
      <span className={styles.overviewTitle}>
        {selectedCouncil?.settings?.themes_display_name_singular || "Theme"}{" "}
        Companies
      </span>
      <div className={styles.panelIcons}>
        {(isUserAdmin(session?.council_role) ||
          (session?.council_role === "standard" &&
            currentThemeData?.current_user_role)) && (
          <Icon
            {...mdSize}
            className={styles.addNew}
            icon="icn-add"
            onClick={handleAddCompanyClick}
          />
        )}{" "}
      </div>
      <div className={styles.kyndrylBlock}>
        <ChartCompaniesList
          companies={companyList}
          selectedTags={[]}
          selectedCouncil={selectedCouncil}
          homeUse={false}
          isDownloadActive={false}
          firmenichInstance={false}
          customPagination
          customMoreOptions
          kyndryThemelInstance
          themesCallBack={themesCallBack}
        />
        <div className={styles.kyndrylCompPagination}>
          <Pagination
            className={styles.kyndrylBlockPagination}
            {...pageSetting}
            onChange={(p) => handlePagingChange(p)}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(KyndrilCompaniesBlock);

import {
  COMPANIES_BUILDER_GET_THEMES_SUCCESS,
  GET_ADDITIONAL_DATA_FOR_PROJECTS_BUILDER,
  GET_ADDITIONAL_DATA_FOR_PROJECTS_BUILDER_SUCCESS,
  GET_BUILDER_STAGES_SUCCESS,
  GET_COMPANIES_BY_TIME_IN_STAGE,
  GET_COMPANIES_BY_TIME_IN_STAGE_SUCCESS,
  GET_COMPANIES_FUNNEL_OVERVIEW_DATA,
  GET_COMPANIES_FUNNEL_OVERVIEW_DATA_SUCCESS,
  GET_COMPANIES_FUNNEL_TECHNOLOGY_DATA,
  GET_COMPANIES_FUNNEL_TECHNOLOGY_DATA_SUCCESS,
  GET_CUSTOM_BUILDERS_BY_SECTION,
  GET_CUSTOM_BUILDERS_BY_SECTION_SUCCESS,
  GET_CUSTOM_SECTION_TABLE_DATA,
  GET_CUSTOM_SECTION_TABLE_DATA_SUCCESS,
  GET_FUNNEL_DATA,
  GET_FUNNEL_DATA_SUCCESS,
  GET_IDEAS_OVERVIEW_DATA,
  GET_IDEAS_OVERVIEW_DATA_SUCCESS,
  GET_IDEAS_TECHNOLOGY_DATA,
  GET_IDEAS_TECHNOLOGY_DATA_SUCCESS,
  GET_PROJECTS_BY_STATUS,
  GET_PROJECTS_BY_STATUS_SUCCESS,
  GET_PROJECTS_OVERVIEW_DATA,
  GET_PROJECTS_OVERVIEW_DATA_SUCCESS,
  GET_REPORTS_COMPANIES_CUSTOM_FIELDS_SUCCESS,
  GET_REPORTS_PROJECT_FIELDS_SUCCESS,
  HANDLE_DELETE_SECTION_SUCCESS,
  PROJECTS_BUILDER_RESET_SELECTED,
  PROJECTS_BUILDER_SELECT_ALL,
  PROJECTS_BUILDER_UPDATE_ITEM,
  SAVE_CUSTOM_BUILDER_SUCCESS,
  SET_DATA_FOR_PDF,
  SET_GENERATE_REPORT_DATA,
  SET_SECTION_VISIBILITY_SUCCESS,
  TOGGLE_SOURCE_DATA,
  UPDATE_SECTION_DATA,
} from "modules/reporting/store/reporting.actions";
import { pageMeta } from "../../../common/httpCall";

const DEFAULT = {
  loading: true,
  loadingList: false,
  sectionData: null,
  pagination: {},
  timePeriod: -1,
  dateRange: [null, null],
  selected_keys_segments: [],
}

const DEFAULT_1 = {
  data: null,
  pagination: null,
  selected: [],
  selectAll: false,
};

const initState = {
  generatedReportData: null,
  toggleSourceDataState: {},
  companiesCustomBuilders: [],
  ideasCustomBuilders: [],
  projectsCustomBuilders: [],
  funnel: DEFAULT,
  companiesByTimeInStage: DEFAULT,
  companiesOverview: DEFAULT,
  companiesTechnology: DEFAULT,
  ideasOverview: DEFAULT,
  projectsOverview: DEFAULT,
  projectsByStatus: DEFAULT,
  ideasTechnology: DEFAULT,
  loadingCustomCompaniesCharts: false,
  loadingCustomProjectCharts: false,
  builderStages: [],
  themes: [],
  projectFields: [],
  customFields: [],
  dataForAdditionalStep: {
    loading: false,
    themes: DEFAULT_1,
    ideas: DEFAULT_1,
    owners: DEFAULT_1,
    all: DEFAULT_1,
  },
  pdfDataForDownload: null,
};

const makeNewChartColors = (colors, tags, selectedTags = []) => {
  if (selectedTags?.length) {
    return colors.map((c, idx) => {
      const getTagByIdx = tags[idx];
      const tagIsSelected = selectedTags
        .some(t => t.custom_id === getTagByIdx.id || getTagByIdx.id === t.id || t.custom_id === getTagByIdx.custom_id);

      return tagIsSelected
        ? getTagByIdx.color || getTagByIdx.bg_color
        : '#999999';
    });
  }

  return tags.map(t => t.color);
};

const updateState = (state, payload, key) => {
  if (!payload || !payload?.response) {
    return {
      ...state,
      [key]: {
        ...state[key],
        loading: false,
        loadingList: false,
        sectionData: {
          chart_data: {},
        },
        hasError: true,
      },
    }
  }

  return {
    ...state,
    [key]: {
      ...state[key],
      loading: false,
      loadingList: false,
      sectionData: {
        ...state[key].sectionData || {},
        ...payload?.response,
      },
      pagination: pageMeta(payload),
    },
  }
}

const reportingReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DATA_FOR_PDF: {
      return {
        ...state,
        pdfDataForDownload: payload
      }
    }

    case COMPANIES_BUILDER_GET_THEMES_SUCCESS: {
      return {
        ...state,
        themes: payload.response || [],
      }
    }

    case PROJECTS_BUILDER_UPDATE_ITEM: {
      const selected = state.dataForAdditionalStep[payload.key].selected;
      const isSelected = selected.includes(payload.element.id);
      const newSelected = isSelected
        ? selected.filter(id => id !== payload.element.id)
        : [...selected, payload.element.id]

      return {
        ...state,
        dataForAdditionalStep: {
          ...state.dataForAdditionalStep,
          [payload.key]: {
            ...state.dataForAdditionalStep[payload.key],
            selected: newSelected,
          },
        }
      }
    }

    case PROJECTS_BUILDER_SELECT_ALL: {
      return {
        ...state,
        dataForAdditionalStep: {
          ...state.dataForAdditionalStep,
          [payload.key]: {
            ...state.dataForAdditionalStep[payload.key],
            selectAll: payload.selectAll,
            selected: state.dataForAdditionalStep[payload.key].selectAll && !payload.selectAll
              ? []
              : state.dataForAdditionalStep[payload.key].selected,
          },
          loading: false,
        }
      }
    }

    case GET_ADDITIONAL_DATA_FOR_PROJECTS_BUILDER: {
      return {
        ...state,
        dataForAdditionalStep: {
          ...state.dataForAdditionalStep,
          loading: true,
        }
      }
    }

    case GET_ADDITIONAL_DATA_FOR_PROJECTS_BUILDER_SUCCESS: {
      const pagination = pageMeta(payload);
      return {
        ...state,
        dataForAdditionalStep: {
          ...state.dataForAdditionalStep,
          [payload.key]: {
            ...state.dataForAdditionalStep[payload.key],
            data: payload.response || [],
            pagination: {
              ...pagination,
              current: pagination.page,
            },
          },
          loading: false,
        }
      }
    }

    case GET_REPORTS_COMPANIES_CUSTOM_FIELDS_SUCCESS: {
      const newGroupsWithFields = payload.response.map(elem => {
        const additionalOptions = elem.custom_field_templates.map(field => {
          const propToUse = field.field_name
              .toLowerCase()
              .replace(/(^[\s]+|[\s]+$)/g, '')
              .split(' ')
              .join('_');

          return {
            id: field.id,
            name: propToUse === 'documents' ? 'Documents (Custom Fields)' : field.field_name,
            bootstrapClass: "d-flex",
            customComponent: "CustomFields",
            propToUse
          }
        });

        return {
          name: elem.name,
          id: elem.id,
          excludeFromTop: true,
          additional_options: additionalOptions,
        }
      })

      return {
        ...state,
        customFields: newGroupsWithFields || [],
      }
    }

    case GET_REPORTS_PROJECT_FIELDS_SUCCESS: {
      return {
        ...state,
        projectFields: payload.response || [],
      }
    }

    case GET_BUILDER_STAGES_SUCCESS: {
      return {
        ...state,
        builderStages: payload.response || [],
      }
    }

    case UPDATE_SECTION_DATA: {
      // used for custom charts
      if (payload.parentReducerKey?.length) {
        const newParentReducer = state[payload.parentReducerKey]
          .map(section => {
            if (section.id === payload.reducerKey) {
              if (section?.section_type === 'bar') {
                return {
                  ...section,
                  ...payload.data,
                }
              }

              if (section?.section_type === 'donut') {
                const newBackgroundColors = makeNewChartColors(
                  section.chart_data.data_sets.background_color,
                  section.keys,
                  payload.data?.selected_keys_segments || [],
                );
                const newChartData = {
                  ...section.chart_data,
                  data_sets: {
                    ...section.chart_data.data_sets,
                    background_color: newBackgroundColors,
                  }
                };

                return {
                  ...section,
                  ...payload.data,
                  chart_data: newChartData,
                }
              }

              return section;
            }

            return section;
          });

        return {
          ...state,
          [payload.parentReducerKey]: newParentReducer,
        }
      }

      const reducer = state[payload.reducerKey];

      // used for static charts
      //
      // below logic is to update horizontal chart color segments when we click on them
      if (reducer.sectionData?.chart_data?.constructor === Array) {
        return {
          ...state,
          [payload.reducerKey]: {
            ...reducer,
            ...payload.data,
          }
        }
      }

      // below logic is to update pie/donut chart color segments when we click on them
      const newBackgroundColors = makeNewChartColors(
        reducer.sectionData.chart_data.data_sets.background_color,
        reducer.sectionData.tags,
        payload.data?.selected_keys_segments
      );
      const newChartData = {
        ...reducer.sectionData.chart_data,
        data_sets: {
          ...reducer.sectionData.chart_data.data_sets,
          background_color: newBackgroundColors,
        }
      };

      return {
        ...state,
        [payload.reducerKey]: {
          ...state[payload.reducerKey],
          ...payload.data,
          sectionData: {
            ...reducer.sectionData,
            chart_data: newChartData,
          }
        }
      }
    }

    case GET_CUSTOM_SECTION_TABLE_DATA: {
      // if (!payload.data.customChart) {
      //   return {
      //     ...state,
      //     [payload.data.parentReducerKey]: {
      //       ...state[payload.data.parentReducerKey],
      //       loadingModalList: true,
      //     }
      //   }
      // }

      const builderDataList = state[payload.data.parentReducerKey].map(s => {
        if (s.id === payload.data.custom_chart_id) {
          if (payload.data.propToReadTableModalDataFrom) {
            return {
              ...s,
              loadingModalList: true,
            }
          }

          return {
            ...s,
            loadingList: true,
          }
        }

        return s;
      });

      return {
        ...state,
        [payload.data.parentReducerKey]: builderDataList,
      }
    }

    case GET_CUSTOM_SECTION_TABLE_DATA_SUCCESS: {
      // if (!payload.customChart) {
      //   return {
      //     ...state,
      //     [payload.parentReducerKey]: {
      //       ...state[payload.parentReducerKey],
      //       [payload.propToReadTableModalDataFrom]: payload.response[payload.propToReadTableDataFrom],
      //       table_columns: payload.response.table_columns || state[payload.parentReducerKey].table_columns || [],
      //       loadingModalList: false,
      //       pagination: pageMeta(payload),
      //       loadingList: false,
      //     }
      //   }
      // }

      return {
        ...state,
        [payload.parentReducerKey]: state[payload.parentReducerKey].map(s => {
          if (s.id === payload.response.id) {
            if (payload.propToReadTableModalDataFrom) {
              return {
                ...s,
                [payload.propToReadTableModalDataFrom]: payload.response[payload.propToReadTableDataFrom],
                table_columns: payload.response.table_columns || s.table_columns || [],
                loadingModalList: false,
                pagination: pageMeta(payload),
                loadingList: false,
              }
            }

            return {
              ...s,
              [payload.propToReadTableDataFrom]: payload.response[payload.propToReadTableDataFrom],
              table_columns: payload.response.table_columns || s.table_columns || [],
              pagination: pageMeta(payload),
              loadingList: false,
            }
          }

          return s;
        })
      }
    }

    case HANDLE_DELETE_SECTION_SUCCESS: {
      return {
        ...state,
        [payload.reducerKey]: state[payload.reducerKey].filter(s => s.id !== payload.id),
      }
    }

    case SAVE_CUSTOM_BUILDER_SUCCESS: {
      const newSection = {
        ...payload.response,
        pagination: pageMeta(payload),
      };

      return {
        ...state,
        [payload.reducerKey]: [...state[payload.reducerKey] || [], newSection],
        dataForAdditionalStep: {
          ...state.dataForAdditionalStep,
          themes: DEFAULT_1,
          ideas: DEFAULT_1,
          owners: DEFAULT_1,
          all: DEFAULT_1,
        }
      }
    }

    case PROJECTS_BUILDER_RESET_SELECTED: {
      return {
        ...state,
        dataForAdditionalStep: {
          ...state.dataForAdditionalStep,
          themes: DEFAULT_1,
          ideas: DEFAULT_1,
          owners: DEFAULT_1,
          all: DEFAULT_1,
        }
      }
    }

    case GET_CUSTOM_BUILDERS_BY_SECTION: {
      return {
        ...state,
        [payload.loadingKey]: true,
      }
    }

    case GET_CUSTOM_BUILDERS_BY_SECTION_SUCCESS: {
      const { customKeys, response } = payload;

      return {
        ...state,
        [customKeys.reducerKey]: response || [],
        [customKeys.loadingKey]: false,
      }
    }

    case SET_GENERATE_REPORT_DATA: {
      return {
        ...state,
        generatedReportData: payload,
      }
    }

    case TOGGLE_SOURCE_DATA: {
      return {
        ...state,
        toggleSourceDataState: {
          [payload.prop]: payload.state || null,
        }
      };
    }

    case GET_FUNNEL_DATA: {
      return {
        ...state,
        funnel: {
          ...state.funnel,
          loading: !payload.justPaginationLoading,
          loadingList: payload.justPaginationLoading
        },
      }
    }

    case GET_COMPANIES_FUNNEL_OVERVIEW_DATA: {
      return {
        ...state,
        companiesOverview: {
          ...state.companiesOverview,
          loading: !payload.justPaginationLoading,
          loadingList: payload.justPaginationLoading
        },
      }
    }

    case GET_IDEAS_OVERVIEW_DATA: {
      return {
        ...state,
        ideasOverview: {
          ...state.ideasOverview,
          loading: !payload.justPaginationLoading,
          loadingList: payload.justPaginationLoading
        },
      }
    }

    case GET_IDEAS_TECHNOLOGY_DATA: {
      return {
        ...state,
        ideasTechnology: {
          ...state.ideasTechnology,
          loading: !payload.justPaginationLoading,
          loadingList: payload.justPaginationLoading
        },
      }
    }

    case GET_PROJECTS_OVERVIEW_DATA: {
      return {
        ...state,
        projectsOverview: {
          ...state.projectsOverview,
          loading: !payload.justPaginationLoading,
          loadingList: payload.justPaginationLoading
        },
      }
    }

    case GET_PROJECTS_BY_STATUS: {
      return {
        ...state,
        projectsByStatus: {
          ...state.projectsByStatus,
          loading: !payload.justPaginationLoading,
          loadingList: payload.justPaginationLoading
        },
      }
    }

    case GET_COMPANIES_FUNNEL_TECHNOLOGY_DATA: {
      return {
        ...state,
        companiesTechnology: {
          ...state.companiesTechnology,
          loading: !payload.justPaginationLoading,
          loadingList: payload.justPaginationLoading
        },
      }
    }

    case GET_COMPANIES_BY_TIME_IN_STAGE: {
      return {
        ...state,
        companiesByTimeInStage: {
          ...state.companiesByTimeInStage,
          loading: !payload.justPaginationLoading,
          loadingList: payload.justPaginationLoading
        },
      }
    }

    case SET_SECTION_VISIBILITY_SUCCESS: {
      return {
        ...state,
        companiesCustomBuilders: state.companiesCustomBuilders.map(s => {
          if (s.id === payload.response.id) {
            return {
              ...s,
              ...payload.response,
            }
          }

          return s;
        })
      }
    }

    case GET_FUNNEL_DATA_SUCCESS: {
      return updateState(state, payload, 'funnel');
    }

    case GET_COMPANIES_BY_TIME_IN_STAGE_SUCCESS: {
      return updateState(state, payload, 'companiesByTimeInStage');
    }

    case GET_COMPANIES_FUNNEL_OVERVIEW_DATA_SUCCESS: {
      return updateState(state, payload, 'companiesOverview');
    }

    case GET_COMPANIES_FUNNEL_TECHNOLOGY_DATA_SUCCESS: {
      return updateState(state, payload, 'companiesTechnology');
    }

    case GET_IDEAS_OVERVIEW_DATA_SUCCESS: {
      return updateState(state, payload, 'ideasOverview');
    }

    case GET_IDEAS_TECHNOLOGY_DATA_SUCCESS: {
      return updateState(state, payload, 'ideasTechnology');
    }

    case GET_PROJECTS_OVERVIEW_DATA_SUCCESS: {
      return updateState(state, payload, 'projectsOverview');
    }

    case GET_PROJECTS_BY_STATUS_SUCCESS: {
      return updateState(state, payload, 'projectsByStatus');
    }

    default:
      return state;
  }
};

export default reportingReducer;

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Loading,
  Button,
  BtnType,
  Label,
  CheckBox,
  CheckType,
  MultiSelect,
} from "modules/primitives/index";
import { appModelSettingSet } from "application/app.action";
import {
  patientOrganizationDetailsGet,
  getPatientOrganizationGroupFields,
} from "modules/patientOrganizations/patientOrganization/patientOrganization.action";
import { getUserTeams, getAllTeamsAttempt } from "modules/teams/teams.action";
import { ModalFooter, ModalBody } from "../../../../../application/modal";
import styles from "./requestResearch.module.scss";
import {
  getCompanyDetailsFields,
  companyRequestResearch,
  getCompanyContactsAttempt,
  getCompanyGroupFields,
} from "../../company.action";
import { councilUsersListGet } from "../../../../../common/actions/common.action";
import UserMultiSelectSearch from "../../../../../common/components/usersSearch";
import CompanyContactsSection from "../../../../../common/components/companyContactsSectionForReqRes/companyContactsSectionForReqRes.component";
import classnames from "classnames";

const RequestResearchComponent = ({
  company,
  isPatientOrganization = false,
}) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [checked, setChecked] = useState([]);
  const [checkedTemplates, setCheckedTemplates] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [info, setInfo] = useState("");
  const [assignToTraction, setAssignToTraction] = useState(false);
  const [submitBtnPressed, setSubmitBtnPressed] = useState(false);
  const [teamOptions, setTeamOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isSelectedDefaultTeam, setIsSelectedDefaultTeam] = useState(false);
  const [defaultTeam, setDefaultTeam] = useState({});
  const [selectedMembers, setSelectedMembers] = useState("");
  const [memberError, setMemberError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customFieldGroups, setCustomFieldGroups] = useState([]);
  const [companyVariant, setCompanyVariant] = useState(false);
  const [selectedCompanyContacts, setSelectedCompanyContacts] = useState([]);
  const {
    authReducer: { session },
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
    patientOrganizationsReducer: {
      patientOrganizationReducer: {
        customFieldGroups: POCustomFieldGroup,
        selectedPatientOrganization,
        patientOrganizationDetails,
      },
    },
    companiesReducer: {
      companyReducer: {
        customFieldGroups: companyCustomFieldGroups,
        companyDetails,
        companyContacts,
      },
    },
    teamsReducer: { userTeams, userTeamsLoading, allTeams },
  } = useSelector((state) => state);

  const handleCancelClick = () => {
    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
  };

  const handleCheckbox = useCallback(
    (val, id, prop) => {
      const allData = isPatientOrganization
        ? patientOrganizationDetails
        : companyDetails;

      if (prop === "custom_field_template_id") {
        const filteredData = allData.filter(
          (f) => f.custom_field_template_id?.length && f.public
        );

        if (checkedTemplates.indexOf(id) === -1) {
          const newData = [...checkedTemplates, id];

          setSelectAll(newData.length === filteredData.length);
          setCheckedTemplates(newData);
        } else {
          const newData = checkedTemplates.filter((item) => item !== id);

          setSelectAll(newData.length === filteredData.length);
          setCheckedTemplates(newData);
        }
      } else {
        const filteredData = allData.filter(
          (f) => f.company_custom_field_id?.length && f.public
        );

        if (checked.indexOf(id) === -1) {
          const newData = [...checked, id];

          setSelectAll(newData.length === filteredData.length);
          setChecked(newData);
        } else {
          const newData = checked.filter((item) => item !== id);

          setSelectAll(newData.length === filteredData.length);
          setChecked(newData);
        }
      }
    },
    [
      patientOrganizationDetails,
      companyDetails,
      isPatientOrganization,
      checked,
      checkedTemplates,
    ]
  );

  const handleAssignToTraction = (e) => {
    setAssignToTraction(e);
    setCompanyVariant(false);
    setIsSelectedDefaultTeam(false);
    setSelectedCompanyContacts([]);
  };

  const handleAssignToCompany = (e) => {
    setCompanyVariant(e);
    setAssignToTraction(false);
    setIsSelectedDefaultTeam(false);
  };

  const handleInformation = (e) => {
    setInfo(e.target.value);
  };
  const checkCompanyErrors = () => {
    if (companyVariant) {
      if (
        (checked.length || checkedTemplates.length) &&
        selectedCompanyContacts.length
      ) {
        setError(false);
        return false;
      } else if (!selectedCompanyContacts.length) {
        setError(true);
        setErrorMessage("Select at least one contact");
        return true;
      } else if (!checkedTemplates.length) {
        setError(true);
        setErrorMessage("Select at least one company");
        return true;
      } else {
        setError(true);
        setErrorMessage("Add any other information needed");
        return true;
      }
    }
    setError(true);
    setErrorMessage("Select at least one custom field");

    return false;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitBtnPressed(true);
    if (checkCompanyErrors()) {
      return;
    }
    if (
      !selectedMembers &&
      !assignToTraction &&
      !teamOptions?.find((team) => team.selected) &&
      selectedCompanyContacts.length === 0
    ) {
      setMemberError(true);
      return;
    }

    const selectedTeam = teamOptions?.find((team) => team?.selected);
    if (
      (selectedCompanyContacts.length ||
        selectedMembers?.length ||
        assignToTraction ||
        teamOptions?.find((team) => team?.selected).id) &&
      (checked.length || checkedTemplates.length)
    ) {
      if (isPatientOrganization) {
        try {
          dispatch(
            companyRequestResearch({
              company_custom_field_ids: checked,
              information: info,
              requested_by_id: session.id,
              assigned_to_id: selectedMembers,
              assign_to_traction: assignToTraction,
              company_id: company.id,
              isPatientOrganization: true,
              team_id: selectedTeam?.id,
              enqueueSnackbar,
              company_contact_id: selectedCompanyContacts,
            })
          );
          dispatch(appModelSettingSet({ ...modalSetting, show: false }));
        } catch (e) {
          throw e;
        }
      } else {
        try {
          dispatch(
            companyRequestResearch({
              company_custom_field_ids: checked,
              custom_field_template_ids: checkedTemplates,
              information: info,
              requested_by_id: session.id,
              assigned_to_id: selectedMembers,
              assign_to_traction: assignToTraction,
              company_id: company.id,
              team_id: selectedTeam?.id,
              enqueueSnackbar,
              company_contact_id: selectedCompanyContacts,
            })
          );
          dispatch(appModelSettingSet({ ...modalSetting, show: false }));
        } catch (e) {
          throw e;
        }
      }
    }
  };
  const checkPublic = (customField) => {
    if (customField.public) {
      if (customField.custom_field_template?.public) {
        return customField.custom_field_template?.public;
      }
      if (!customField.custom_field_template) {
        return customField.public;
      }
    }
    return false;
  };
  const handleSelectAll = (e) => {
    let allCheckboxes = [];
    let allTemplateCheckboxes = [];
    if (e) {
      if (isPatientOrganization) {
        allCheckboxes = patientOrganizationDetails.map(
          (c) => c.patient_organization_custom_field_id
        );
      } else {
        allCheckboxes = companyDetails
          .filter((c) => c.company_custom_field_id?.length && checkPublic(c))
          .map((c) => c.company_custom_field_id);
        allTemplateCheckboxes = companyDetails
          .filter(
            (c) =>
              !c.company_custom_field_id?.length &&
              c.custom_field_template_id?.length &&
              c.custom_field_template.public
          )
          .map((c) => c.custom_field_template_id);
      }

      setChecked(allCheckboxes);
      setCheckedTemplates(allTemplateCheckboxes);
      setSelectAll(true);
    } else {
      setChecked([]);
      setCheckedTemplates([]);
      setSelectAll(false);
    }
  };

  const handleTeamSelect = (value) => {
    if (!value.length) {
      const makeSelected = teamOptions.map((team) => ({
        ...team,
        selected: false,
      }));

      setTeamOptions(makeSelected);
      return;
    }
    if (value[0]?.request_research) {
      setIsSelectedDefaultTeam(true);
    } else {
      setIsSelectedDefaultTeam(false);
    }
    const makeSelected = teamOptions.map((team) => {
      if (value[0]?.id === team?.id) {
        return {
          ...team,
          selected: true,
        };
      }
      return {
        ...team,
        selected: false,
      };
    });

    setTeamOptions(makeSelected);
  };

  const handleSelectCompanyContact = (value) => {
    if (selectedCompanyContacts.includes(value)) {
      setSelectedCompanyContacts((prev) => [
        ...prev.filter((id) => id !== value),
      ]);
      return;
    }
    setSelectedCompanyContacts((prev) => [...prev, value]);
  };

  const handleMemberSelect = (values) => {
    if (values.length === 0) {
      setSelectedMembers("");

      return;
    }
    if (memberError) {
      setMemberError(false);
    }
    setSelectedMembers(values[0].id);
  };

  useEffect(() => {
    if (isPatientOrganization) {
      dispatch(
        getPatientOrganizationGroupFields({
          id: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
      dispatch(
        patientOrganizationDetailsGet({
          patientOrganizationId: selectedPatientOrganization.id,
          items: 100,
          page: 1,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(getCompanyGroupFields({ id: company.id, enqueueSnackbar }));

      dispatch(
        getCompanyDetailsFields({
          company_id: company.id,
          council_id: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }

    dispatch(
      getUserTeams({
        enqueueSnackbar,
        query: {
          items: 1000,
        },
      })
    );
    dispatch(
      getAllTeamsAttempt({
        enqueueSnackbar,
      })
    );
  }, []);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(councilUsersListGet(selectedCouncil.id, enqueueSnackbar));
      dispatch(
        getCompanyContactsAttempt({
          companyId: company.id,
        })
      );
    }
  }, [selectedCouncil]);

  useEffect(() => {
    if (submitBtnPressed) {
      checkCompanyErrors();
    }
  }, [checked, selected, info, submitBtnPressed]);

  useEffect(() => {
    if (isPatientOrganization) {
      setCustomFieldGroups(POCustomFieldGroup);
    } else {
      setCustomFieldGroups(companyCustomFieldGroups);
    }
  }, [POCustomFieldGroup, companyCustomFieldGroups]);

  useEffect(() => {
    if (userTeams?.length || allTeams?.length) {
      const filteredTeamsByDefault = allTeams?.filter(
        (team) => team?.request_research
      );
      setDefaultTeam(filteredTeamsByDefault[0]);
      if (filteredTeamsByDefault?.length) {
        setIsSelectedDefaultTeam(true);
        const alreadyExistingTeam = userTeams?.find(
          (team) => team?.id === filteredTeamsByDefault[0]?.id
        );
        if (alreadyExistingTeam) {
          setTeamOptions([
            ...userTeams.map((team) => {
              if (team?.id === alreadyExistingTeam?.id) {
                return {
                  ...team,
                  selected: true,
                };
              }
              return team;
            }),
          ]);
        } else {
          filteredTeamsByDefault[0].selected = true;
          setTeamOptions([...userTeams, ...filteredTeamsByDefault]);
        }
      } else {
        setIsSelectedDefaultTeam(false);
        setTeamOptions(
          userTeams?.map((team) => {
            if (team?.request_research) {
              return {
                ...team,
                selected: true,
              };
            }
            return team;
          })
        );
      }
    }
  }, [userTeams, allTeams]);

  useEffect(() => {
    const selectedDefTeam = teamOptions?.find(
      (team) => team?.id === defaultTeam?.id && team.selected
    );
    if (isSelectedDefaultTeam) {
      setTeamOptions(
        teamOptions.map((team) => {
          if (team?.request_research) {
            return {
              ...team,
              selected: true,
            };
          }
          return {
            ...team,
            selected: false,
          };
        })
      );
    } else if (selectedDefTeam) {
      setTeamOptions(
        teamOptions.map((team) => ({
          ...team,
          selected: false,
        }))
      );
    }
  }, [isSelectedDefaultTeam]);
  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div>
                <UserMultiSelectSearch
                  placeholder="Assign to Team Member"
                  labelField="full_name"
                  valueField="id"
                  searchBy="full_name"
                  disabled={Boolean(
                    assignToTraction ||
                      teamOptions?.find((el) => el?.selected) ||
                      isSelectedDefaultTeam ||
                      companyVariant
                  )}
                  onChange={handleMemberSelect}
                  multi={false}
                  onClear={() => {
                    setSelectedMembers("");
                  }}
                  withClear={selectedMembers}
                />
              </div>
              <div>
                {userTeamsLoading && <Loading />}
                {!userTeamsLoading && userTeams.length > 0 && (
                  <MultiSelect
                    onChange={handleTeamSelect}
                    placeholder="Select Team"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    options={teamOptions}
                    values={[...teamOptions.filter((team) => team?.selected)]}
                    disabled={Boolean(
                      assignToTraction ||
                        selectedMembers.length ||
                        companyVariant
                    )}
                    multi={false}
                    withClear={teamOptions?.find((team) => team.selected)}
                    onClear={() => {
                      const makeSelected = teamOptions.map((team) => ({
                        ...team,
                        selected: false,
                      }));
                      setIsSelectedDefaultTeam(false);
                      setTeamOptions(makeSelected);
                    }}
                  />
                )}
              </div>
              {Boolean(allTeams?.length) && defaultTeam?.id && (
                <div className={styles.formElement}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={isSelectedDefaultTeam}
                    disabled={Boolean(selectedMembers.length)}
                    onChange={() => {
                      setIsSelectedDefaultTeam(!isSelectedDefaultTeam);
                      setAssignToTraction(false);
                      setCompanyVariant(false);
                      setSelectedCompanyContacts([]);
                    }}
                  />
                  <Label>Assign to {defaultTeam?.name} Team</Label>
                </div>
              )}
              {!isPatientOrganization && (
                <div className={styles.formElement}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={companyVariant}
                    disabled={Boolean(selectedMembers.length)}
                    onChange={(e) => handleAssignToCompany(e)}
                  />
                  <Label>Assign to Company</Label>
                </div>
              )}

              <div>
                {companyVariant && loading && <Loading />}
                {companyVariant && !loading && (
                  <CompanyContactsSection
                    companyContacts={companyContacts}
                    handleSelectCompanyContact={handleSelectCompanyContact}
                    selectedCompanyContacts={selectedCompanyContacts}
                  />
                )}
              </div>

              {!selectedCouncil?.traction_tag.includes("kyndryl") ? (
                <div className={styles.formElement}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={assignToTraction}
                    disabled={Boolean(selectedMembers.length)}
                    onChange={(e) => handleAssignToTraction(e)}
                  />
                  <Label>Assign to Traction Technology</Label>
                </div>
              ) : null}
            </div>
          </div>
          <div className="row">
            {(companyDetails?.length > 0 ||
              patientOrganizationDetails?.length > 0) && (
              <div className="col-12">
                <Label>
                  Please select required information to collect below…
                </Label>
                <div className={styles.formElement}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={selectAll}
                    onChange={handleSelectAll}
                  />
                  <Label>Select All</Label>
                </div>
              </div>
            )}
          </div>
          <hr />
          {customFieldGroups?.length &&
          (companyDetails?.length || patientOrganizationDetails?.length) ? (
            <div className={classnames(styles.requiredInfoWrp, "row")}>
              <div className="col-md-6">
                {customFieldGroups.map((group) => (
                  <div key={group.custom_field_group_id}>
                    {!isPatientOrganization && companyDetails[0]
                      ? companyDetails.map((c) => {
                          if (!c.custom_field_template?.teams.length) {
                            if (
                              c.company_custom_field_id?.length &&
                              c.custom_field_group_id ===
                                group.custom_field_group_id
                            ) {
                              return (
                                <div
                                  key={c.company_custom_field_id}
                                  className={styles.formElement}
                                >
                                  <CheckBox
                                    checkType={CheckType.BLUE}
                                    isChecked={
                                      checked.indexOf(
                                        c.company_custom_field_id
                                      ) !== -1
                                    }
                                    onChange={(val) =>
                                      handleCheckbox(
                                        val,
                                        c.company_custom_field_id,
                                        "company_custom_field_id"
                                      )
                                    }
                                  />
                                  <Label>
                                    {c.field_name ||
                                      c.field_type[0].toUpperCase() +
                                        c.field_type.substring(1)}
                                  </Label>
                                </div>
                              );
                            }
                            if (
                              c.custom_field_template_id?.length &&
                              c.custom_field_group_id ===
                                group.custom_field_group_id
                            ) {
                              return (
                                <div
                                  key={c.custom_field_template_id}
                                  className={styles.formElement}
                                >
                                  <CheckBox
                                    checkType={CheckType.BLUE}
                                    isChecked={
                                      checkedTemplates.indexOf(
                                        c.custom_field_template_id
                                      ) !== -1
                                    }
                                    onChange={(val) =>
                                      handleCheckbox(
                                        val,
                                        c.custom_field_template_id,
                                        "custom_field_template_id"
                                      )
                                    }
                                  />
                                  <Label>
                                    {c.field_name ||
                                      c.field_type[0].toUpperCase() +
                                        c.field_type.substring(1)}
                                  </Label>
                                </div>
                              );
                            }
                          }
                        })
                      : patientOrganizationDetails[0] &&
                        patientOrganizationDetails.map((c) => {
                          if (
                            c.custom_field_group_id ===
                            group.custom_field_group_id
                          ) {
                            return (
                              <div
                                key={c.field_name}
                                className={styles.formElement}
                              >
                                <CheckBox
                                  checkType={CheckType.BLUE}
                                  isChecked={
                                    checked.indexOf(
                                      c.patient_organization_custom_field_id
                                    ) !== -1
                                  }
                                  onChange={(val) =>
                                    handleCheckbox(
                                      val,
                                      c.patient_organization_custom_field_id
                                    )
                                  }
                                />
                                <Label>{c.field_name}</Label>
                              </div>
                            );
                          }

                          return null;
                        })}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <Loading />
          )}
          <div className="row">
            <div className="col-12">
              <Label>Other Information to Collect</Label>
              <textarea
                placeholder="Add any other information needed"
                className={error ? styles.textBoxError : styles.textBox}
                onChange={handleInformation}
              />
            </div>
          </div>
          {error && <div className={styles.errorText}>{errorMessage}</div>}
        </ModalBody>
        <ModalFooter>
          <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button type="submit" btn={BtnType.REGULAR}>
            Send
          </Button>
        </ModalFooter>
      </form>
    </div>
  );
};

export default React.memo(RequestResearchComponent);

import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import classnames from "classnames";

import { numberWithCommas } from "common/helper";
import styles from "./projectRow.module.scss";
import {
  UserInline,
  UserInlineTypes,
  ProgressBar,
  ProjectState,
  MultiUsers,
} from "../../../primitives";
import { projectDiscussionsReset } from "../../../../common/actions/discussions.action";

const CatalogProjectRowComponent = ({ project, tab, history }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleProjectClick = (evt) => {
    if (!project.current_user_has_access) {
      evt.preventDefault();
      enqueueSnackbar("Sorry, you don't have access to that project", {
        variant: "error",
      });
      return;
    }

    dispatch(projectDiscussionsReset());
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const owners = useMemo(() => {
    if (project?.project_members?.length) {
      return project?.project_members
        .filter((m) => m.user_role === "owner")
        .map((m) => ({ ...m, ...m.user }));
    }

    return [];
  }, [project]);

  const prepareLongRow = (tags) => {
    const key =
      tags[tags.length - 1].id + tags.length + tags[tags.length - 2].id;
    return (
      <Tooltip
        placement="bottom"
        title={`${tags.map((tag) => tag.name).join(", ")}`}
        key={key}
      >
        <span className={styles.moreTagslink}>{" More "}</span>
      </Tooltip>
    );
  };

  return (
    <Link
      to={`/projects/${project?.id}/overview`}
      onClick={(evt) => handleProjectClick(evt)}
    >
      {/* col-1 */}
      <div className={`${styles.gridRow} `}>
        <div className={`${styles.projectName} `}>{project?.name}</div>

        <div className={`${styles.plantName} `}>{project?.plant?.name}</div>
        {/* project.last_activity?.created_at */}
        <div className={`${styles.area} `}>
          {project.areas?.map((area, i) => {
            if (i < 5) {
              return <div key={area?.name}>{area?.name}</div>;
            }

            if (i === 5) {
              return prepareLongRow(project.areas);
            }
            return null;
          })}
        </div>
        <div className={`${styles.savings}`}>
          $ {numberWithCommas(project.savings) || "0"}
        </div>
        <div className={`${styles.rating} `}>{project.rating}</div>
        <div className={`${styles.theme}`}>
          {project?.themes?.map((theme, i) => {
            if (i < 5) {
              return <div key={theme?.name}>{theme?.name}</div>;
            }

            if (i === 5) {
              return prepareLongRow(project.themes);
            }
            return null;
          })}
        </div>
        <div className={`${styles.replications}`}>{project?.replications}</div>
        <div className={`${styles.stages}`}>{project?.stage?.name}</div>

        <div className={`${styles.progress} `}>
          {tab === "project_stage_idea" ? (
            <div className={styles.listItemCell}>
              <span
                className={classnames(
                  styles.listItemStatus,
                  styles[`listItemStatus-${project.approval_status}`]
                )}
              >
                {capitalizeFirstLetter(project.approval_status)}
              </span>
            </div>
          ) : (
            <ProgressBar
              width="100%"
              tot={100}
              fill={project?.total_task_progress}
              showTot={false}
              showPrecentage
            />
          )}
        </div>
        <div className={`${styles.catalogOwner} `}>
          {owners?.length > 1 ? (
            <MultiUsers
              users={owners}
              total={owners.length}
              dontShowRemaining
            />
          ) : owners?.length ? (
            <UserInline
              userId={owners[0].user.id}
              avatar={owners[0].user.avatar}
              first={owners[0].user.first_name}
              last={owners[0].user.last_name}
              type={UserInlineTypes.WITH_NAME}
              dontRequest
              className="userNameWrap"
            />
          ) : null}
        </div>
        <div className={`${styles.accessLevel}`}>{project?.access}</div>
        <div className={`${styles.status} `}>
          <ProjectState state={project.status} />
        </div>
      </div>
    </Link>
  );
};

export default CatalogProjectRowComponent;

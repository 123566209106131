import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import classNames from "classnames";
import { checkViewerRole } from "common/checkers/viewerChecker";
import Tooltip from "@mui/material/Tooltip";
import {
  UserInline,
  UserInlineTypes,
  MoreOptions,
  Button,
  BtnType,
} from "../../../../primitives";
import styles from "./relationshipOwner.module.scss";
import { moreOptions } from "./relationshipOwner.constant";
import { deleteRelationShip } from "../../patientOrganization.action";
import { useModelPopup } from "../../../../../common/hooks";
import NewItemComponent from "../addNew/index";
import EditRelationship from "../../../../../common/components/editRelationship";

const RelationshipOwnerComponent = ({ relationships, companyId }) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  const handleEditClick = (relationship) => {
    popup.show({
      title: "Edit Relationship Owner",
      component: (
        <EditRelationship
          isCompanyUse
          isPatientOrg
          relationship={relationship}
          mode="edit"
        />
      ),
    });
  };

  const handleMoreOptionClick = (options, relationship) => {
    if (options === 5) {
      handleEditClick(relationship);
    }

    if (options === 6) {
      dispatch(
        deleteRelationShip({
          patient_organization_id: companyId,
          patient_organization_relationship_owner_id:
            relationship.patient_organization_relationship_owner_id,
          enqueueSnackbar,
        }),
      );
    }
  };

  const handleAddClick = () => {
    popup.show({
      title: "Add Relationship Manager",
      component: (
        <NewItemComponent type="relationship" relationships={relationships} />
      ),
    });
  };

  return (
    <div className="position-relative">
      <div className={styles.addButtonWrapper}>
        {!blockFunctionality ? (
          <Button
            btn={BtnType.FRAME_LESS}
            icon="icn-add"
            onClick={() => handleAddClick()}
          >
            Add
          </Button>
        ) : (
          <Tooltip
            title="You have View-Only access. To Add a Relationship Owner, please ask your Admin to upgrade your account to Standard access"
            placement="top"
          >
            <div>
              <Button btn={BtnType.DISABLED} icon="icn-add">
                Add
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
      {!relationships[0] && (
        <div className={styles.wrapper}>No relationship found</div>
      )}
      {relationships[0] && (
        <div className={styles.wrapper}>
          {relationships.map((el) => (
            <div className={styles.itemContainer} key={el.id}>
              <div className={styles.leftContainer}>
                <div className={styles.userWrp}>
                  <UserInline
                    className={styles.userIcon}
                    classObj={{ name: styles.userName }}
                    userId={el.user_id}
                    type={UserInlineTypes.WITH_NAME}
                    showMine={false}
                    first={el.user.full_name}
                    avatar={el.user.avatar}
                    local
                  />
                </div>
                <div className={styles.userDetails}>
                  <div className={classNames("caption small", styles.position)}>
                    {el.user.title}
                  </div>
                </div>
              </div>
              <div className={styles.rightContainer}>
                {!blockFunctionality && (
                  <div className={styles.colMore}>
                    <MoreOptions
                      options={moreOptions}
                      className={styles.moreOptWrp}
                      onClick={(val) => handleMoreOptionClick(val, el)}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(RelationshipOwnerComponent);

export const THEME_PROJECTS_GET_ATTEMPT = "THEME_PROJECTS_GET_ATTEMPT";
export const themesProjectsGetAttempt = (payload) => {
  return {
    type: THEME_PROJECTS_GET_ATTEMPT,
    payload,
  };
};

export const THEME_PROJECTS_GET_SUCCESS = "THEME_PROJECTS_GET_SUCCESS";
export const themesProjectsGetSuccess = (payload) => ({
  type: THEME_PROJECTS_GET_SUCCESS,
  payload,
});

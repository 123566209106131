import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import styles from '../styles.module.scss';
import { Label, DropSelect } from "modules/primitives";
import { dateOptions, currDate, months } from "modules/reporting/fordReports/actualScorecard/constants";
import { makeFordDashboardQuarters } from "modules/reporting/fordReports/utils";
import { generateArrayOfYears } from "modules/companies/companyMain/leftWindow/advancedFilters/founded/founded-utils";

const years = generateArrayOfYears(2000).map(y => ({name: y.year, id: y.year}));

const FordScoreCardDateFilter = ({ handleFilterUpdate, reducer, setNewFilters }) => {
  const dispatch = useDispatch();

  const selectedDateFilterType = useMemo(() => {
    if (!reducer.filters?.dateType) return [];

    return dateOptions.filter(option => option.id === reducer.filters?.dateType);
  }, [reducer.filters, dateOptions]);

  const handleDateTypeChangeSelect = useCallback((value) => {
    setNewFilters({
      ...reducer.filters,
      dateType: value.val,
      year: null,
      month: null,
      dateRange: null,
      quarter: null,
    });

    // dispatch(setActualScoreCardFilters({
    //   ...reducer.filters,
    //   dateType: value.val,
    //   year: null,
    //   month: null,
    //   dateRange: null,
    //   quarter: null,
    // }));
  }, [reducer.filters]);

  const handleYearSelect = useCallback((value) => {
    setNewFilters({
      ...reducer.filters,
      year: value.val,
    });
    // dispatch(setActualScoreCardFilters({
    //   ...reducer.filters,
    //   year: value.val,
    // }));

    if (reducer.filters.dateType === 'byQuarter' || reducer.filters.dateType === 'byMonth') return;

    handleFilterUpdate({year: value.val}, reducer.tableData?.expandedKey);
  }, [reducer]);

  const makeQuartersData = useMemo(() => {
    if (!reducer.filters?.year) return [];

    return makeFordDashboardQuarters(new Date(String(reducer.filters?.year)))
      .sort((a, b) => b.index - a.index);
  }, [makeFordDashboardQuarters, reducer.filters?.year]);

  const handleQuarterChange = useCallback((value) => {
    setNewFilters({
      ...reducer.filters,
      quarter: value.obj[0],
    });
    // dispatch(setActualScoreCardFilters({
    //   ...reducer.filters,
    //   quarter: value.obj[0],
    // }));
    handleFilterUpdate({quarter: value.obj[0]}, reducer.tableData?.expandedKey);
  }, [reducer, handleFilterUpdate]);

  const handleMonthChange = useCallback((value) => {
    setNewFilters({
      ...reducer.filters,
      month: value.obj[0].index,
    });
    // dispatch(setActualScoreCardFilters({
    //   ...reducer.filters,
    //   month: value.obj[0].index,
    // }));
    handleFilterUpdate({month: value.obj[0].index}, reducer.tableData?.expandedKey);
  }, [reducer, handleFilterUpdate]);

  const handleDateRangeChange = useCallback((value) => {
    setNewFilters({
      ...reducer.filters,
      dateRange: value,
    });
    // dispatch(setActualScoreCardFilters({
    //   ...reducer.filters,
    //   dateRange: value,
    // }));
    handleFilterUpdate({dateRange: value}, reducer.tableData?.expandedKey);
  }, [reducer, handleFilterUpdate]);

  return (
    <div className="d-flex flex-row">
      <div className={styles.filter}>
        <Label>Date</Label>
        <DropSelect
          multi={false}
          closeOnSelect
          values={selectedDateFilterType}
          onChange={(val) => handleDateTypeChangeSelect(val)}
          options={dateOptions}
          labelField="name"
          valueField="id"
          placeholder="Date"
          searchBy="name"
        />
      </div>
      {
        reducer.filters?.dateType === 'byYear' && (
          <div className={classNames(styles.filter)}>
            <Label>Select Year</Label>
            <DropSelect
              multi={false}
              closeOnSelect
              values={reducer.filters?.year ? years.filter(y => y.id === reducer.filters?.year) : []}
              onChange={(val) => handleYearSelect(val)}
              options={years}
              labelField="name"
              valueField="id"
              placeholder="Select Year"
              searchBy="name"
            />
          </div>
        )
      }
      {
        reducer.filters?.dateType === 'byQuarter' && (
          <>
            <div className={classNames(styles.filter)}>
              <Label>Select Year</Label>
              <DropSelect
                multi={false}
                closeOnSelect
                values={reducer.filters?.year ? years.filter(y => y.id === reducer.filters?.year.id) : []}
                onChange={(val) => handleYearSelect(val)}
                options={years}
                labelField="name"
                valueField="id"
                placeholder="Select Year"
                searchBy="name"
              />
            </div>
            {
              reducer.filters?.year && (
                <div className={classNames(styles.filter)}>
                  <Label>Select Quarter</Label>
                  <DropSelect
                    label="Select Quarter"
                    placeholder="Select Quarter"
                    labelField="name"
                    valueField="id"
                    name="select_quarter"
                    multi={false}
                    options={makeQuartersData}
                    onChange={(val) => handleQuarterChange(val)}
                    values={reducer.filters?.quarter ? [reducer.filters?.quarter] : []}
                  />
                </div>
              )
            }
          </>
        )
      }
      {
        reducer.filters?.dateType === "byMonth" && (
          <>
            <div className={classNames(styles.filter)}>
              <Label>Select Year</Label>
              <DropSelect
                multi={false}
                closeOnSelect
                values={reducer.filters?.year ? years.filter(y => y.id === reducer.filters?.year) : []}
                onChange={(val) => handleYearSelect(val)}
                options={years}
                labelField="name"
                valueField="id"
                placeholder="Select Year"
                searchBy="name"
              />
            </div>
            {
              reducer.filters?.year && (
                <div className={classNames(styles.filter)}>
                  <Label>Select Month</Label>
                  <DropSelect
                    label="Select Month"
                    placeholder="Select Month"
                    labelField="name"
                    valueField="id"
                    name="select_month"
                    multi={false}
                    options={months}
                    onChange={(val) => handleMonthChange(val)}
                    values={reducer.filters?.month ? months.filter(m => m.index === reducer.filters?.month) : []}
                  />
                </div>
              )
            }
          </>
        )
      }
      {
        reducer.filters.dateType === "custom" && (
          <div className={classNames(styles.filter)}>
            <Label>Date Range</Label>
            <DateRangePicker
              onChange={(val) => handleDateRangeChange(val)}
              value={reducer.filters?.dateRange}
              calendarIcon={null}
              clearIcon={null}
              className={styles.dateRange}
              maxDate={currDate}
            />
          </div>
        )
      }
    </div>
  );
};

export default React.memo(FordScoreCardDateFilter);

import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { checkViewerRole } from "common/checkers/viewerChecker";

import { Button, BtnType } from "../../../../primitives";
import styles from "./areaOfFocus.module.scss";
import {
  queryStringUrlReplacement,
  convertTagIdToIndex,
} from "../../../../../common/helper";
import { useModelPopup } from "../../../../../common/hooks";
import AddTagComponent from "./newTagModal";
import CrunchSourceComponent from "../crunchSource";

// TODO: do we need to make the same navigation with query params when Patient Organizations?

const AreaOfFocusComponent = ({
  company,
  selectedCouncil,
  isClaimProfileUse,
  customCompanyTags,
}) => {
  const history = useHistory();
  const location = useLocation();
  const popup = useModelPopup();
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  const handleAddTechnologyClick = (type) => {
    if (type === "tech") {
      popup.show({
        title: "Add New Technology Tag",
        component: <AddTagComponent type="tech" company={company} />,
      });
    } else {
      popup.show({
        title:
          selectedCouncil?.name === "Freddie Mac"
            ? "Add Housing Cycle Tag"
            : "Add New Industry Tag ",
        component: <AddTagComponent type="industry" company={company} />,
      });
    }
  };

  const handleAddBusinessClick = () => {
    popup.show({
      title: `Add New ${
        selectedCouncil.name === "Kyndryl" ? "Practice" : "Business"
      } Tag`,
      component: <AddTagComponent type="business" company={company} />,
    });
  };
  const handleAddCustomTagClick = (group) => {
    popup.show({
      title: `Add Custom Tag`,
      component: (
        <AddTagComponent type="custom" company={company} group={group} />
      ),
    });
  };

  const handleTechnologyClick = useCallback(
    (e, technologyId) => {
      if (isClaimProfileUse) {
        return;
      }

      const search = queryStringUrlReplacement(
        history.location.search,
        "topic",
        technologyId
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          topic: technologyId,
        },
      });
    },
    [location, history, isClaimProfileUse]
  );

  const handleIndustryClick = useCallback(
    (e, industryId) => {
      if (isClaimProfileUse) {
        return;
      }

      const search = queryStringUrlReplacement(
        history.location.search,
        "industry",
        industryId
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          industry: industryId,
        },
      });
    },
    [location, history, isClaimProfileUse]
  );
  const handleBusinessClick = useCallback(
    (e, businessId) => {
      if (isClaimProfileUse) {
        return;
      }

      const search = queryStringUrlReplacement(
        history.location.search,
        "business",
        businessId
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          business: businessId,
        },
      });
    },
    [location, history, isClaimProfileUse]
  );
  const handleCustomTagClick = useCallback(
    (e, tagId, groupId) => {
      if (isClaimProfileUse) {
        return;
      }
      const tagIndex = convertTagIdToIndex(
        tagId,
        selectedCouncil?.council_custom_tag_groups,
        groupId
      );

      const search = queryStringUrlReplacement(
        history.location.search,
        "custom_tags_ids",
        tagIndex
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          custom_tags_ids: tagIndex,
        },
      });
    },
    [location, history, isClaimProfileUse]
  );

  return (
    <div className={styles.wrapper}>
      {selectedCouncil?.name === "Kyndryl" && (
        <div className={styles.tagsWrp}>
          <div className={styles.label}>
            {selectedCouncil.name === "Kyndryl" ? "Practice" : "Business Unit"}
          </div>
          <div className={styles.tagsCon}>
            {company?.business_units?.map((business) => (
              <Button
                key={business.id}
                btn={BtnType.REGULAR}
                rounded
                className={styles.tag}
                onClick={(e) => handleBusinessClick(e, business.id)}
              >
                {business.name}
              </Button>
            ))}

            {!blockFunctionality && !isClaimProfileUse && (
              <Button
                btn={BtnType.REGULAR}
                rounded
                className={styles.tag}
                onClick={(e) => handleAddBusinessClick()}
              >
                +
              </Button>
            )}
          </div>
        </div>
      )}

      <>
        {customCompanyTags?.map((group) => {
          if (!group?.attributes?.filter) {
            return null;
          }
          let marketsCT;
          let filteredWithChilds;

          if (group?.attributes?.name === "Markets Served") {
            marketsCT = selectedCouncil?.council_custom_tag_groups?.filter(
              (gr) => gr.name === "Markets Served"
            )[0].custom_tags;

            filteredWithChilds = marketsCT
              ?.map((parent) => parent.children)
              .flat().length;
          }

          return (
            <div className={styles.tagsWrp} key={group.attributes.name}>
              <div className={styles.label}>{group.attributes.name}</div>
              <div className={styles.tagsCon}>
                {group.attributes.name === "Markets Served" &&
                  group?.attributes?.custom_tags.length ===
                    marketsCT.length + filteredWithChilds && (
                    <Button
                      btn={BtnType.TAG_BLUE}
                      rounded
                      className={styles.tag}
                      // onClick={(e) => handleCustomTagClick(e, tag.id)}
                      buttonColor={{
                        backgroundColor: `${
                          group?.attributes?.bg_color || "#3994c1"
                        }`,
                        color: `${group?.attributes?.text_color || "#000"}`,
                      }}
                    >
                      Global
                    </Button>
                  )}
                {group.attributes?.custom_tags?.map((tag) => (
                  <Button
                    key={tag.id}
                    btn={BtnType.TAG_BLUE}
                    rounded
                    className={styles.tag}
                    buttonColor={{
                      backgroundColor: `${
                        group?.attributes?.bg_color || "#3994c1"
                      }`,
                      color: `${group?.attributes?.text_color || "#000"}`,
                    }}
                    onClick={(e) => handleCustomTagClick(e, tag.id, group.id)}
                  >
                    {tag.name}
                  </Button>
                ))}

                {!blockFunctionality && !isClaimProfileUse && (
                  <Button
                    btn={BtnType.TAG_BLUE}
                    rounded
                    className={styles.tag}
                    onClick={(e) => handleAddCustomTagClick(group)}
                    buttonColor={{
                      backgroundColor: `${
                        group?.attributes?.bg_color || "#3994c1"
                      }`,
                      color: `${group?.attributes?.text_color || "#000"}`,
                    }}
                  >
                    +
                  </Button>
                )}
              </div>
            </div>
          );
        })}
      </>

      <div className={styles.tagsWrp}>
        <div className={styles.label}>Technologies</div>
        <div className={styles.tagsCon}>
          {company.resource_topics?.map((item, index) => (
            <Button
              key={`${item.topic.id}_${index}_${item.topic.name}`}
              btn={BtnType.TAG_BLUE_LIGHT}
              rounded
              className={styles.tag}
              onClick={(e) => handleTechnologyClick(e, item.topic.id)}
            >
              {item.topic.name}
            </Button>
          ))}

          {!blockFunctionality && !isClaimProfileUse && (
            <Button
              btn={BtnType.TAG_BLUE_LIGHT}
              rounded
              className={styles.tag}
              onClick={(e) => handleAddTechnologyClick("tech")}
            >
              +
            </Button>
          )}
        </div>
      </div>
      <div className={styles.tagsWrp}>
        <div className={styles.label}>
          {selectedCouncil?.name === "Freddie Mac"
            ? "Housing Cycle + Industries"
            : "Industries"}
        </div>
        <div className={styles.tagsCon}>
          {company.resource_industries?.map((item, inx) => (
            <Button
              key={item.industry.name}
              btn={BtnType.TAG_OUTLINE_BLUE}
              rounded
              className={styles.tag}
              onClick={(e) => handleIndustryClick(e, item.industry.id)}
            >
              {item.industry.name}
            </Button>
          ))}
          {!blockFunctionality && !isClaimProfileUse && (
            <Button
              btn={BtnType.TAG_OUTLINE_BLUE}
              rounded
              className={styles.tag}
              onClick={() => handleAddTechnologyClick("industry")}
            >
              +
            </Button>
          )}
        </div>
      </div>

      {(company.name === "Novartis" || company.name === "GSK") &&
        company.traction_tag && (
          <div className={styles.tagsWrp}>
            <div className={styles.label}>Tags</div>
            <div className={styles.tagsCon}>
              <Button btn={BtnType.REGULAR} rounded className={styles.tag}>
                {company.traction_tag}
              </Button>
            </div>
          </div>
        )}
      <CrunchSourceComponent company={company} />
    </div>
  );
};

export default React.memo(AreaOfFocusComponent);

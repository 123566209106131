import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import Select from "react-dropdown-select";

import { ModalBody, ModalFooter } from "../../../../../application/modal";
import {
  BtnType,
  Button,
  Label,
} from "../../../../../modules/primitives";
import { useModelPopup } from "../../../../../common/hooks";
import styles from "modules/projects/project/manageProject/details/details.module.scss";
import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import { getPlantByType, updatePlant } from "../../../../../common/actions/common.action";

const IdeaPlantsAndAreasModal = () => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    councilReducer: { selectedCouncil },
    ideaReducer: { selectedIdea, ideaPlant },
    adminPlantsAndAreasReducer: { plantsAndAreas }
  } = useSelector((state) => state);

  const [selectedPlant, setSelectedPlant] = useState({});

  useEffect(() => {
    if (!plantsAndAreas.length && selectedCouncil?.name === 'Ford') {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        })
      );
    }
  }, [plantsAndAreas, selectedCouncil]);

  useEffect(() => {
    if (!ideaPlant) {
      dispatch(getPlantByType({
        resourceId: selectedIdea.id,
        enqueueSnackbar,
        type: 'Idea'
      }));
    }
  }, [selectedIdea, selectedCouncil]);

  useEffect(() => {
    if (ideaPlant && plantsAndAreas.length) {
      const plant = {
        id: ideaPlant.id,
        name: ideaPlant.name,
        selectedAreas: ideaPlant?.areas?.map(area => ({area_id: area.id, area_name: area.attributes.name})) || [],
        areas: plantsAndAreas.find(p => p.id === ideaPlant.id)?.attributes?.areas || [],
      }
      setSelectedPlant(plant);
    }
  }, [ideaPlant, plantsAndAreas])

  const handlePlantChange = (value) => {
    const selected = value?.length ? value[0] || value?.obj[0] : null;

    if (selected?.id === selectedPlant?.id) {
      return;
    }

    setSelectedPlant(selected);
  };

  const handleAreaChange = (value) => {
    setSelectedPlant({
      ...selectedPlant,
      selectedAreas: value,
    });
  };

  const handleCancelClick = () => {
    popup.hide();
  };

  const getPlantsAsOptions = useMemo(() => {
    return plantsAndAreas.map(plant => ({
      id: plant.id,
      name: plant.attributes.name,
      areas: plant.attributes.areas,
    }));
  }, [plantsAndAreas]);

  const handleSubmit = () => {
    const payload = {
      data: {
        resource_id: selectedIdea.id,
        resource_type: 'Idea',
        plant_id: selectedPlant?.id,
        area_ids: selectedPlant?.selectedAreas?.map(area => area.area_id) || [],
      },
      type: 'Idea',
      response: {
        plant: {
          id: selectedPlant?.id,
          attributes: {
            name: selectedPlant?.name
          }
        },
        areas: selectedPlant?.selectedAreas?.map(area => ({
          id: area.area_id,
          attributes: {
            name: area.area_name
          }
        })) || [],
      },
      cb: () => {
        popup.hide();
      },
      enqueueSnackbar
    }

    dispatch(updatePlant(payload));
  };

  return (
    <div>
      <ModalBody>
        <div className={styles.formContainer}>
          <Label>Plant</Label>
          <Select
            multi={false}
            closeOnSelect
            values={[selectedPlant]}
            onChange={handlePlantChange}
            options={getPlantsAsOptions}
            labelField="name"
            valueField="id"
            placeholder="Plants"
            searchBy="name"
          />
          {
            selectedPlant?.areas?.length > 0 ? (
              <div className="mt-3">
                <Label>Areas</Label>
                <Select
                  multi={false}
                  closeOnSelect
                  values={selectedPlant.selectedAreas || []}
                  onChange={handleAreaChange}
                  options={selectedPlant.areas}
                  labelField="area_name"
                  valueField="area_id"
                  placeholder="Areas"
                  searchBy="area_name"
                />
              </div>
            ) : null
          }
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button disabled={!selectedPlant?.id} type="submit" btn={BtnType.REGULAR} onClick={handleSubmit}>
          Submit
        </Button>
      </ModalFooter>
    </div>
  );
};
export default IdeaPlantsAndAreasModal;

import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Skeleton from "@mui/material/Skeleton";

import { httpPost } from "common/httpCall";
import DownloadReport from "common/components/downloadReport";
import styles from "./style.module.scss";
import CompanyRowComponent from "./companyRow";
import { BtnType, Button, Pagination } from "../../../../primitives";
import { useModelPopup, useSorting } from "../../../../../common/hooks";
import SelectProjectsComponent from "./selectProjects";
import SelectPropertiesComponent from "./selectProperties";
import {
  COMPAIR_ROWS,
  ORGANIZATION_ROWS,
  pageSettings,
} from "./compair-constants";
import { Icon, smSize } from "../../../../../common/icon";
import {
  getCompareCompanies,
  projectCompanyCompareSortedListSet,
  setCompareCompanies,
  setCompareNotes,
} from "./compair.actions";

import {
  projectOrganizationsGet,
  projectCompanyListGet,
} from "../companies.action";
import MobileCompareRow from "./mobileCompareRow/mobileCompareRow.component";

const parseFunding = (funding) =>
  funding ? +funding.replace(/[$,]+/g, "") : 0;

const parseFoundedData = (dateString) =>
  dateString ? new Date(dateString).getTime() : 0;

const CompareCompaniesComponent = () => {
  const popup = useModelPopup();
  const {
    projectsReducer: {
      projectReducer: { selectedProject },
      companyReducer: {
        asc,
        patientOrganizations,
        organizationsLoading,
        // companyList = [],
      },
      companyCompairReducer: {
        companiesToCompare,
        companyList = [],
        companiesTotal,
        companiesLoading,
        selectedNotes = [],
      },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const wrapperRef = useRef(null);

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedRows, setSelectedRows] = useState(
    COMPAIR_ROWS.filter((r) => r.selected)
  );
  const [isPatientOrg, setIsPatientOrg] = useState(null);
  const [sortingColumn, setSortingColumn] = useState(null);
  const [sortedArray, setSortedArray] = useState([]);
  const [companiesCustomField, setCompaniesCustomField] = useState([]);
  const [pageSetting, setPageSetting] = useState(pageSettings);
  const [total, setTotal] = useState(0);
  const [downloadIsActive, setDownloadIsActive] = useState(false);
  const [downloadView, setDownloadView] = useState(null);
  // const [notesTypeChange, handleNotesTypeChange] = useState([

  // ]);

  const [payload, setPayload] = useState({
    project_id: selectedProject?.id,
    compare_properties: {
      name: true,
      topics: true,
      project_stage: true,
      score: true,
      project_status: true,
      location: true,
      web_url: true,
      founded: true,
    },
    custom_activities: {
      calls: true,
      emails: true,
      meetings: true,
      events: true,
      notes: true,
    },
    company_custom_fields_ids: undefined,
    company_custom_field_names: undefined,
  });

  useEffect(() => {
    if (downloadIsActive) {
      const parent = document.getElementById("compareContainer");
      const elem = document.getElementById("rowLine");

      if (elem && parent) {
        const scale = Math.min(
          (parent.offsetWidth - 16) / elem.offsetWidth,
          parent.offsetHeight / elem.offsetHeight
        );

        parent.style.overflow = "hidden";

        parent.childNodes.forEach((child) => {
          const newStyles = `
              -webkit-transform: scale(${scale}, ${scale});
              transform-origin: 0% 0%;
            `;
          child.setAttribute("style", newStyles);
        });

        setDownloadView(2);
      }
    } else {
      const parent = document.getElementById("compareContainer");
      const elem = document.getElementById("rowLine");

      if (elem && parent) {
        parent.style.overflow = "auto";
        parent.childNodes.forEach((child) => {
          const newStyles = `
              -webkit-transform: inherit;
              transform-origin: 0% 0%;
            `;
          child.setAttribute("style", newStyles);
        });
        setDownloadView(null);
      }
    }
  }, [downloadIsActive]);

  useEffect(() => {
    if (
      companies?.length &&
      wrapperRef?.current &&
      (!companiesLoading || !organizationsLoading) &&
      downloadIsActive
    ) {
      const wrapper = document.getElementById("compareContainer");
      const row = document.getElementById("rowLine");

      if (wrapper && row && wrapper?.offsetWidth < row?.offsetWidth - 1500) {
        setDownloadView(1);
      }
    } else if (!downloadIsActive) {
      setDownloadView(null);
      document.body.style.cursor = "default";
    } else {
      setDownloadView(null);
      document.body.style.cursor = "default";
    }
  }, [
    downloadIsActive,
    wrapperRef,
    companiesLoading,
    organizationsLoading,
    companies,
  ]);

  const sorting = useSorting([], asc, projectCompanyCompareSortedListSet);

  const generateCompanyListForSort = () => {
    const list = companies.map((item) => {
      return {
        ...item,
        name: item.company?.name || item.gsk_patient_organization.name,
        website: item.company?.website,
        company_size: item.company?.company_size,
        location: item.company?.location,
        topics: item.company?.topics?.length,
        topicsString:
          item.company?.topics && item.company?.topics?.length
            ? item.company?.topics[0].name
            : "",
        industryTagsString:
          item.company?.industries && item.company?.industries?.length
            ? item.company?.industries[0].name
            : "",
        total_tasks_progress: item.company?.total_tasks_progress,
        funding: parseFunding(item.company?.total_funding),
        foundedTime: parseFoundedData(item.company?.founded),
        companyContactsString:
          item.company?.company_contacts &&
          item.company?.company_contacts.length
            ? item.company?.company_contacts[0].email
            : "",
        competitorsLength:
          item.company?.competitors && item.company?.competitors.length
            ? item.company?.competitors.length
            : 0,
        relationshipOwner: item.company?.company_relationship_owners?.map(
          ({ id, full_name }) => ({ id, full_name })
        ),
        relationshipOwnerString:
          item.company?.company_relationship_owners &&
          item.company?.company_relationship_owners.length
            ? `${item.company?.company_relationship_owners[0]?.user?.first_name} ${item.company?.company_relationship_owners[0]?.user?.last_name}`
            : "",
        companyDescription: item.company?.description,
      };
    });

    setSortedArray(list);
  };

  useEffect(() => {
    if (selectedProject) {
      if (companiesToCompare && companiesToCompare[selectedProject.id]) {
        setSelectedCompanies(companiesToCompare[selectedProject.id]);
      } else {
        dispatch(setCompareCompanies(null));
      }

      if (selectedProject?.stage_template?.patient_organization) {
        setIsPatientOrg(true);
        fetchData(1, true);
        setSelectedRows(ORGANIZATION_ROWS);
      } else {
        setIsPatientOrg(false);
        fetchData(1, false);
      }
    }
  }, [selectedProject]);

  useEffect(() => {
    if (companiesTotal) {
      setTotal(companiesTotal);
    }
  }, [companiesTotal]);

  useEffect(() => {
    const tmpCompaniesList = isPatientOrg ? patientOrganizations : companyList;
    let activeCompanies = tmpCompaniesList.filter(
      ({ state }) => state !== "removed"
    );

    if (selectedCompanies.length > 0) {
      const propertyName = isPatientOrg
        ? "gsk_patient_organization"
        : "company";
      activeCompanies = activeCompanies.filter(
        (item) =>
          !!selectedCompanies.find(
            (c1) => c1.id === item[propertyName]?.id && c1.selected
          )
      );
    }

    setCompanies(activeCompanies);
  }, [companyList, patientOrganizations, isPatientOrg, selectedCompanies]);

  useEffect(() => {
    generateCompanyListForSort();
    if (!isPatientOrg && companies.length) {
      // const arr = companies
      //   .reduce((acc, { company: { company_custom_fields = [] } }) => {
      //     return [...acc, ...company_custom_fields];
      //   }, [])
      //   .filter(
      //     (thing, index, self) =>
      //       thing.field_name &&
      //       index === self.findIndex((t) => t.field_name === thing.field_name)
      //   );
      // console.log("---------arrr!!!!111", arr);
      // setCompaniesCustomField(arr);
    }
  }, [companies]);

  useEffect(() => {
    sorting.setSortingArray(sortedArray);
  }, [asc, sortingColumn]);

  const fetchData = useCallback(
    (page, isPatientOrg) => {
      if (isPatientOrg) {
        dispatch(
          projectOrganizationsGet({
            projectId: selectedProject.id,
            enqueueSnackbar,
          })
        );
      } else {
        dispatch(
          getCompareCompanies({
            projectId: selectedProject.id,
            items: pageSettings.pageSize,
            page,
            enqueueSnackbar,
          })
        );
        dispatch(
          projectCompanyListGet({
            projectId: selectedProject.id,
            items: pageSetting.pageSize,
            page: pageSetting.current,
            enqueueSnackbar,
          })
        );
      }
    },
    [selectedProject]
  );

  const drowSortingIcon = (col) => {
    if (sortingColumn === col) {
      return (
        <div className={classNames(styles.sortIcon, asc && styles.sortAsc)}>
          <Icon {...smSize} icon="icn-export-button" />
        </div>
      );
    }
  };

  const handleSorting = (prop, type) => {
    setSortingColumn(prop);
    sorting.sortChange(prop, type);
  };

  const handlePagingChange = useCallback(
    (page) => {
      if (page === pageSetting.current) return;

      fetchData(page, isPatientOrg);
      setSelectedCompanies([]);
      setPageSetting((prev) => ({
        ...prev,
        current: page,
      }));
    },
    [isPatientOrg, pageSetting]
  );

  const handleOnRowChange = (rows, notes = []) => {
    const obj = { ...payload };
    const defaults = COMPAIR_ROWS.filter((r) => r.selected).map(
      (r) => r.keyName
    );
    rows.map((row) => {
      if (row.selected) {
        if (row.isCustom) {
          obj.company_custom_field_ids = obj.company_custom_field_ids
            ? [...obj.company_custom_field_ids, row.id]
            : [row.id];
          obj.company_custom_field_names = obj.company_custom_field_names
            ? [...obj.company_custom_field_names, row.name]
            : [row.name];
        } else {
          if (defaults.includes(row.keyName)) {
            defaults.splice(defaults.indexOf(row.keyName), 1);
          }
          obj.compare_properties[row.keyName] = true;
        }
      }
    });

    obj.custom_activities = {
      notes: notes.includes("notes"),
      calls: notes.includes("calls"),
      emails: notes.includes("emails"),
      meetings: notes.includes("meetings"),
      events: notes.includes("events"),
    };

    defaults.map((key) => {
      delete obj.compare_properties[key];
    });
    dispatch(setCompareNotes(notes));
    setPayload(obj);
    setSelectedRows(rows);
  };

  const handleEditCompaniesClick = () => {
    popup.show({
      title: isPatientOrg ? "Select organizations" : "Select companies",
      component: (
        <SelectProjectsComponent
          isPatientOrg={isPatientOrg}
          selectedCompanies={selectedCompanies}
          onSave={handleSelectCompanies}
        />
      ),
    });
  };

  const handleSelectCompanies = (sc) => {
    let propertyName = "company";
    let tmpCompaniesList = companyList;

    if (isPatientOrg) {
      propertyName = "gsk_patient_organization";
      tmpCompaniesList = patientOrganizations;
    }

    const updatedListOfCompanies = tmpCompaniesList.filter(
      (item) =>
        item.state !== "removed" &&
        !!sc.find((c1) => c1.id === item[propertyName]?.id && c1.selected)
    );

    const obj = { ...payload };
    if (updatedListOfCompanies?.length) {
      obj.company_ids = updatedListOfCompanies.map((c) => c.company.id);
      obj.company_names = updatedListOfCompanies.map((c) => c.company.name);
    }

    setPayload(obj);
    setSelectedCompanies(sc);
    setCompanies(updatedListOfCompanies);
    dispatch(
      setCompareCompanies({
        [selectedProject.id]: sc,
      })
    );
  };

  const handleEditPropertyClick = async () => {
    const customFields = await httpPost({
      call: "compare/company_custom_fields",
      data: {
        company_ids: companies.map((c) => c.company.id),
      },
    })
      .pipe()
      .subscribe((res) => {
        const names = [];
        const arrCustomFields = res.response
          .map((field) => {
            if (
              names.includes(
                field.company_custom_fields.field_name ||
                  field.company_custom_fields.field_type
              )
            ) {
              return null;
            }

            names.push(
              field.company_custom_fields.field_name ||
                field.company_custom_fields.field_type
            );

            return {
              ...field.company_custom_fields,
              field_name:
                field.company_custom_fields.field_name ||
                field.company_custom_fields.field_type,
            };
          })
          .filter((el) => el !== null);
        popup.show({
          title: "Select properties to compare",
          component: (
            <SelectPropertiesComponent
              // customFields={companiesCustomField}
              customFields={arrCustomFields}
              isPatientOrg={isPatientOrg}
              selected={selectedRows}
              onRowsChange={handleOnRowChange}
            />
          ),
        });
        return res.data;
      });
  };

  const getSkeleton = () => {
    return (
      <div className={styles.skeletonContainer}>
        <div>
          {["sk-1", "sk-2", "sk-3", "sk-4"].map((el) => (
            <div className={styles.skeletonListElement} key={el}>
              <Skeleton
                animation="wave"
                variant="circle"
                width={64}
                height={60}
              />
              <div className={styles.skeletonCompanyInfo}>
                <Skeleton animation="wave" height={20} />
                <Skeleton animation="wave" height={20} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getHeaderText = () => {
    const typeText = isPatientOrg ? "organizations" : "companies";
    if (companies.length > 0) {
      return `Comparing ${companies.length} ${typeText}`;
    }

    return `No ${typeText} to compare`;
  };

  return (
    <div className={styles.wrapper} id="downloadableSection">
      {companiesLoading && organizationsLoading && getSkeleton()}

      {(!companiesLoading || !organizationsLoading) && (
        <>
          <div
            className={`${styles.HeaderWrp} ${downloadIsActive ? "mx-2" : ""}`}
          >
            {!downloadIsActive && (
              <h4 className={styles.taskHeaderText}>{getHeaderText()}</h4>
            )}
            {companies?.length >= 0 && !downloadView && (
              <div className={styles.headerBtnWrp}>
                <Button
                  className={styles.newTask}
                  btn={BtnType.FRAME_LESS}
                  onClick={() => handleEditCompaniesClick()}
                >
                  Edit {isPatientOrg ? "Organizations" : "Companies"}
                </Button>
                <Button
                  className={styles.newTask}
                  btn={BtnType.FRAME_LESS}
                  onClick={handleEditPropertyClick}
                >
                  Edit properties
                </Button>
                <DownloadReport
                  className={styles.reportBtns}
                  location={false}
                  countryFilters={false}
                  checkedPatientFilters={false}
                  endpoint={"compare_companies"}
                  payload={payload}
                  setDownloadPdfIsActive={setDownloadIsActive}
                />
              </div>
            )}
          </div>
          {downloadIsActive && (
            <h3 className="mb-0 d-flex justify-content-center font-weight-bold">
              Traction Comparison Matrix
            </h3>
          )}
          {companies.length > 0 && downloadView !== 1 && (
            <div
              ref={wrapperRef}
              id="compareContainer"
              className={`${styles.container} ${
                downloadIsActive ? "mx-2" : ""
              }`}
            >
              <div className={styles.taskRowHeader} id="compairHeader">
                {selectedRows.map((row, i) => {
                  if (
                    ["EVENTS", "MEETINGS", "EMAILS", "CALLS"].includes(
                      row.name.toUpperCase()
                    )
                  ) {
                    return null;
                  }
                  return (
                    <>
                      <div
                        className={classNames(
                          styles.taskcol,
                          styles[row.className],
                          { [styles.bigCol]: row.type === "text" }
                        )}
                        onClick={() => {
                          if (!row?.isCustom) {
                            handleSorting(row.sortingIcon, row.type);
                          }
                        }}
                      >
                        {row.name}
                        {drowSortingIcon(row.sortingIcon)}
                      </div>
                      {row.name === "Notes " && (
                        <>
                          <div
                            className={classNames(
                              styles.taskcol,
                              styles[row.className],
                              { [styles.bigCol]: row.type === "text" }
                            )}
                            onClick={() => {
                              if (!row?.isCustom) {
                                handleSorting(row.sortingIcon, row.type);
                              }
                            }}
                          >
                            Note Date
                            {drowSortingIcon(row.sortingIcon)}
                          </div>
                          <div
                            className={classNames(
                              styles.taskcol,
                              styles[row.className],
                              { [styles.bigCol]: row.type === "text" }
                            )}
                            onClick={() => {
                              if (!row?.isCustom) {
                                handleSorting(row.sortingIcon, row.type);
                              }
                            }}
                          >
                            Note Author
                            {drowSortingIcon(row.sortingIcon)}
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
              </div>

              {sortedArray
                .filter(({ state: companyState }) => companyState !== "removed")
                .map((item) => (
                  <CompanyRowComponent
                    elementsShouldBeFixed={false}
                    selectedRows={selectedRows}
                    notesTypeChange={selectedNotes}
                    key={
                      isPatientOrg
                        ? item.gsk_patient_organization.id
                        : item.company.id
                    }
                    item={item}
                    isPatientOrg={isPatientOrg}
                  />
                ))}
            </div>
          )}

          {companies.length > 0 && downloadView === 1 && (
            <>
              {sortedArray
                .filter(({ state: companyState }) => companyState !== "removed")
                .map((item) => (
                  <MobileCompareRow
                    selectedRows={selectedRows}
                    key={
                      isPatientOrg
                        ? item.gsk_patient_organization.id
                        : item.company.id
                    }
                    item={item}
                    isPatientOrg={isPatientOrg}
                  />
                ))}
            </>
          )}
        </>
      )}

      <div
        className={
          companiesLoading || companies.length === 0
            ? styles.none
            : styles.paginationCon
        }
      >
        <Pagination
          total={total}
          pageSize={pageSetting.pageSize}
          current={pageSetting.current}
          onChange={handlePagingChange}
        />
      </div>
    </div>
  );
};

export default React.memo(CompareCompaniesComponent);

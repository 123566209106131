import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  httpGet,
  queryString,
  errorHandler, httpDelete,
} from "../../../common/httpCall";
import {
  PEOPLE_LIST_MEMBER_NOTES_ATTEMPT,
  peopleListMemberNotesSuccess,
  PEOPLE_DELETE_MEMBER_NOTE_ATTEMPT,
  peopleDeleteMemberNoteSuccess,
} from "./profile.action";

export const epicGetMemberNotes = (action$, state$) => action$.pipe(
  ofType(PEOPLE_LIST_MEMBER_NOTES_ATTEMPT),
  switchMap(
    ({
      payload: {
        memberId, currentPage, pageSize, enqueueSnackbar,
      },
    }) => httpGet({
      call: `users/${memberId}/notes?${queryString({
        items: pageSize,
        page: currentPage,
      })}`, // NEW_API`,
    }).pipe(
      map((result) => peopleListMemberNotesSuccess(result)),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

export const epicDeleteMemberNote = (action$, state$) => action$.pipe(
  ofType(PEOPLE_DELETE_MEMBER_NOTE_ATTEMPT),
  switchMap(
    ({
      payload: {
        memberId, noteId, enqueueSnackbar,
      },
    }) => httpDelete({
      call: `users/${memberId}/notes/${noteId}`, // NEW_API`,
      body: { is_deleted: true },
    }).pipe(
      map((result) => peopleDeleteMemberNoteSuccess(result)),
      catchError((e) => errorHandler(e, enqueueSnackbar, [])),
    ),
  ),
);

const peopleProfileEpic = combineEpics(
  epicGetMemberNotes,
  epicDeleteMemberNote,
);

export default peopleProfileEpic;

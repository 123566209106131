import * as Yup from "yup";

export const projectCreationVersions = [
  {
    name: "V2",
    id: "v2",
  },
  {
    name: "V3",
    id: "v3",
  },
];

export const validation = Yup.object().shape({
  naem: Yup.string().required("member is required"),
});

export const model = {
  council_name: "",
  traction_tag: "",
  avatar: "",
  active: false,
  themes: false,
  technologies: [],
  industries: [],
  project_version_path: [projectCreationVersions[0]],
};

export const roles = [
  {
    role: "all",
    label: "All",
  },
  {
    role: "council_administrator",
    label: "Admin",
  },
  {
    role: "standard",
    label: "Standard",
  },
  {
    role: "viewer",
    label: "View-Only",
  },
  {
    role: "company",
    label: "Company",
  },
];

export const rolesInitialState = {
  all: true,
  council_administrator: true,
  standard: true,
  viewer: true,
  company: false,
};

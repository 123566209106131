import React from "react";

class DoubleScrollbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: "auto",
    };

    this.boundCalculateWidth = this.calculateWidth.bind(this);
  }

  componentDidMount() {
    const { outerDiv } = this.refs;
    const childWrapper = this.refs.childrenWrapper;

    this.calculateWidth();

    window.addEventListener("resize", this.boundCalculateWidth);

    outerDiv.onscroll = function () {
      childWrapper.scrollLeft = outerDiv.scrollLeft;
    };

    childWrapper.onscroll = function () {
      outerDiv.scrollLeft = childWrapper.scrollLeft;
    };
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.boundCalculateWidth);
  }

  componentDidUpdate() {
    this.calculateWidth();
  }

  calculateWidth() {
    let width = this.getChildWrapperWidth();

    if (!width) {
      width = "auto";
    }

    if (width !== this.state.width) {
      this.setState({
        width,
      });
    }
  }

  getChildWrapperWidth() {
    let width = null;
    if (this.refs.childrenWrapper && this.refs.childrenWrapper.scrollWidth) {
      width = `${this.refs.childrenWrapper.scrollWidth}px`;
    }
    return width;
  }

  render() {
    const { hideScroll } = this.props;
    const outerDivStyle = hideScroll
      ? {}
      : { overflowX: "auto", overflowY: "hidden" };
    const innerDivStyle = { paddingTop: "1px", width: this.state.width };
    const childDivStyle = hideScroll
      ? {}
      : { overflowX: "auto", overflowY: "hidden" };

    return (
      <>
        <div ref="outerDiv" style={outerDivStyle}>
          <div ref="innerDiv" style={innerDivStyle}>
            &nbsp;
          </div>
        </div>
        <div ref="childrenWrapper" style={childDivStyle}>
          {this.props.children}
        </div>
      </>
    );
  }
}

export default DoubleScrollbar;

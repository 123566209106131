import React from "react";
import { useSelector } from "react-redux";
import styles from "./modal.module.scss";

const ModalFooterComponent = ({ children }) => {
  const {
    appReducer: { modalSetting },
  } = useSelector((state) => state);

  return (
    <div className={styles.btnWrp}>
      <div
        className={`${
          modalSetting.fullScreen
            ? "d-flex justify-content-center flex-grow-1"
            : styles.btnCon
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default React.memo(ModalFooterComponent);

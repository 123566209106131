import { OptionType } from "../../primitives";

export const PeopleTypes = {
  INTERNAL: 0,
  EXTERNAL: 1,
};

export const HeaderOptions = [
  {
    value: PeopleTypes.INTERNAL,
    text: "Internal Team",
    path: "internal",
  },
  {
    value: PeopleTypes.EXTERNAL,
    text: "Company Contacts",
    path: "external",
  },
];

export const filterBy = [
  {
    value: "team1",
    text: "Team One",
  },
  {
    value: "team2",
    text: "Team Two",
  },
];

export const pageSettings = (pageSize = 10) => ({
  pageSize,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
});

export const externalContactOptions = [
  { val: "edit", text: "Edit" },
  { val: "delete", text: "Delete", type: OptionType.DANGER },
];

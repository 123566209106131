import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { MoreOptions, Loading } from "../../../primitives";
import styles from "../adminTags.module.scss";
import RelationshipStatusModal from "../modals/relationshipStatusModal.component";
import ConfirmDeleteComponent from "../../../../common/components/confirmDelete";

const AdminTagsRelationshipStatusTable = (props) => {
  const {
    data,
    defaultExpandedRows,
    extendRow,
    moreOptions,
    selectedVariant,
    loading,
    popup,
    enqueueSnackbar,
    selectedCouncil,
  } = props;

  const [emptyPosErrorMessage, setEmptyPosErrorMessage] = useState("");
  const handleMoreOptionClick = (event, tag) => {
    if (event === "edit") {
      popup.show({
        title: "Edit Tag",
        component: (
          <RelationshipStatusModal
            tag={tag}
            selectedVariant={selectedVariant}
            mode="edit"
            enqueueSnackbar={enqueueSnackbar}
            council_id={selectedCouncil.id}
          />
        ),
      });
    }

    if (event === "delete") {
      popup.show({
        title: "Confirmation Prompt",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={tag.id}
            enqueueSnackbar={enqueueSnackbar}
            councilId={selectedCouncil.id}
            name={tag.name}
            actionType="tagRelationshipStatusDelete"
          />
        ),
      });
    }
  };

  useEffect(() => {
    if (data.length) {
      const positions = [];
      let thereAreEmpty = false;
      data.forEach((tag) => {
        positions[tag.position] = tag.position;
      });

      positions.shift();
      for (let index = 0; index < positions.length; index++) {
        if (positions[index] === undefined) {
          positions[index] = "_";
          thereAreEmpty = true;
        }
      }
      if (thereAreEmpty) {
        setEmptyPosErrorMessage(
          "There are empty positions in this list. Please edit tags to adjust positions in sequential order!"
        );
      } else {
        setEmptyPosErrorMessage("");
      }
    }
  }, [data]);

  return (
    <>
      <div className={styles.errorMessage}>
        {emptyPosErrorMessage && emptyPosErrorMessage}
      </div>
      <div className={styles.tableHeader}>
        <div className={styles.statusName}>
          <span>Relationship Stage</span>
        </div>
        <div className={styles.statusDescription}>
          <span>Description</span>
        </div>
        <div className={styles.childTab}>
          <span>Background</span>
        </div>
        <div className={styles.childTab}>
          <span>Position</span>
        </div>
        <div className={styles.optionTab}>
          <span>Options</span>
        </div>
      </div>
      {loading && (
        <div className={styles.loadingContainer}>
          <Loading customText="Updating tags database. Please wait . . ." />
        </div>
      )}
      {!loading &&
        data.map((tagElement) => {
          const shouldExtend = defaultExpandedRows?.find(
            (rowId) => rowId === tagElement.id
          );
          return (
            <div key={tagElement.id} className={styles.tableContainer}>
              <Accordion
                className={styles.accordion}
                expanded={Boolean(shouldExtend)}
                disabled={tagElement?.name === "Applicant"}
              >
                <AccordionSummary
                  className={styles.accordionRow}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={styles.tableRow}>
                    <div
                      onClick={() => {
                        extendRow(tagElement.id);
                      }}
                      className={styles.rowName}
                    >
                      <div className={styles.rowNameBlock}>
                        <span>{tagElement?.name}</span>
                      </div>
                    </div>
                    <div className={styles.rowStatusDescription}>
                      <span>{tagElement.description}</span>
                    </div>
                    <div className={styles.rowChildren}>
                      <span
                        style={{
                          backgroundColor: `${
                            tagElement?.colors?.background || "#000"
                          }`,
                          borderRadius: 5,
                          padding: 5,
                          width: 80,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {tagElement?.colors?.background}
                      </span>
                    </div>
                    <div className={styles.rowChildren}>
                      <span>{tagElement?.position}</span>
                    </div>
                    <div className={styles.rowOptions}>
                      <MoreOptions
                        options={
                          tagElement.name === "Applicant"
                            ? moreOptions.filter((opt) => opt.val !== "edit")
                            : moreOptions
                        }
                        className={styles.moreOptWrp}
                        onClick={(e) => {
                          handleMoreOptionClick(e, tagElement);
                        }}
                      />
                    </div>
                  </div>
                </AccordionSummary>
              </Accordion>
            </div>
          );
        })}
    </>
  );
};

export default AdminTagsRelationshipStatusTable;

import { combineEpics, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";

import {
  APPLICANTS_FORMS_LIST_GET_ATTEMPT,
  applicantsFormsListGetSuccess,
  CREATE_APPLICANT_FORM_ATTEMPT,
  createApplicantFormSuccess,
  DELETE_APPLICANT_FORM_ATTEMPT,
  deleteApplicantFormSuccess,
  FORM_CUSTOM_FIELDS_CREATE_ATTEMPT,
  FORM_CUSTOM_FIELDS_LIST_GET_ATTEMPT,
  formCustomFieldCreateSuccess,
  formCustomFieldsGetSuccess,
  GET_APPLICANT_FORM_ATTEMPT,
  getApplicantFormSuccess,
  UPDATE_APPLICANT_FORM_ATTEMPT,
  updateApplicantFormSuccess,
} from "./adminApplicantForms.action";

import {
  errorHandler,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  queryString,
} from "../../../../common/httpCall";

export const epicGetApplicantsFormsList = (action$, state$) => action$.pipe(
  ofType(APPLICANTS_FORMS_LIST_GET_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar, query } }) => httpGet({
    call: `applicants/forms?${queryString({ ...query })}`,
  }).pipe(
    map((result) => applicantsFormsListGetSuccess(result)),
    catchError((error) => errorHandler(error, enqueueSnackbar, [])),
  )),
);

export const epicCreateFormCustomField = (action$, state$) => action$.pipe(
  ofType(FORM_CUSTOM_FIELDS_CREATE_ATTEMPT),
  switchMap(({ payload: { data, enqueueSnackbar, cb } }) => httpPost({
    call: "applicants/available_form_fields",
    data: {
      available_form_field: {
        ...data,
      },
    },
  }).pipe(
    map((result) => {
      if (cb && result.response) cb(null, result.response);

      return formCustomFieldCreateSuccess(result);
    }),
    catchError((error) => {
      if (cb) cb(error);

      return errorHandler(error, enqueueSnackbar, []);
    }),
  )),
);

export const epicCreateApplicantForm = (action$, state$) => action$.pipe(
  ofType(CREATE_APPLICANT_FORM_ATTEMPT),
  switchMap(({ payload: { data, callback } }) => httpPost({
    call: "applicants/forms",
    data: {
      form: {
        ...data,
      },
    },
  }).pipe(
    map((result) => {
      if (callback) callback("success", result.response);
      return createApplicantFormSuccess(result);
    }),
    catchError((e) => !!callback && callback("error")),
  )),
);

export const epicDeleteApplicantForm = (action$, state$) => action$.pipe(
  ofType(DELETE_APPLICANT_FORM_ATTEMPT),
  switchMap(({ payload: { id, enqueueSnackbar } }) => httpDelete({
    call: `applicants/forms/${id}`,
  }).pipe(
    map((result) => {
      if (enqueueSnackbar) {
        enqueueSnackbar("Application form deleted with success", {
          variant: "success",
        });
      }

      return deleteApplicantFormSuccess({ ...result, id });
    }),
    catchError((error) => errorHandler(error, enqueueSnackbar, [])),
  )),
);

export const epicGetApplicantsForm = (action$, state$) => action$.pipe(
  ofType(GET_APPLICANT_FORM_ATTEMPT),
  switchMap(({ payload: { id, enqueueSnackbar } }) => httpGet({
    call: `applicants/forms/${id}`,
  }).pipe(
    map((result) => getApplicantFormSuccess(result)),
    catchError((error) => errorHandler(error, enqueueSnackbar, [])),
  )),
);

export const epicUpdateApplicantsForm = (action$, state$) => action$.pipe(
  ofType(UPDATE_APPLICANT_FORM_ATTEMPT),
  switchMap(({
    payload: {
      id, data, enqueueSnackbar, callback,
    },
  }) => httpPatch({
    call: `applicants/forms/${id}`,
    data: {
      form: {
        ...data,
      },
    },
  }).pipe(
    map((result) => {
      if (callback) callback("success", result.response);
      return updateApplicantFormSuccess(result);
    }),
    catchError((error) => errorHandler(error, enqueueSnackbar, [])),
  )),
);

export const epicGetApplicantsFormCustomFields = (action$, state$) => action$.pipe(
  ofType(FORM_CUSTOM_FIELDS_LIST_GET_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar, query } }) => httpGet({
    call: `applicants/available_form_fields?${queryString({ ...query })}`,
  }).pipe(
    map((result) => formCustomFieldsGetSuccess(result)),
    catchError((error) => errorHandler(error, enqueueSnackbar, [])),
  )),
);

const adminApplicantFormsEpic = combineEpics(
  epicGetApplicantsFormsList,
  epicCreateFormCustomField,
  epicCreateApplicantForm,
  epicDeleteApplicantForm,
  epicGetApplicantsForm,
  epicUpdateApplicantsForm,
  epicGetApplicantsFormCustomFields,
);

export default adminApplicantFormsEpic;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";

import {
  Loading,
  Button,
  BtnType,
  State,
  CompanyState,
  ProgressBar,
  MoreOptions,
  companyState,
  CompanyAvatar,
} from "modules/primitives/index";
import { checkViewerRole } from "common/checkers/viewerChecker";
import { Icon, mdSize } from "../../../../../common/icon";
import styles from "./companyRow.module.scss";
import {
  appSliderSettingSet,
  appSelectedSet,
} from "application/app.action";
import { sliderSetting } from "application/app.constants";
import { companyOptions } from "../companies.constants";
import {
  TaskAllInfoComponent,
  TaskAllInfoHeaderComponent,
} from "../taskAllInfo";
import { projectCompanyChangeStatus } from "../companies.action";
import { CompanyRatings, CompanyRatingsHeader } from "../companyRatings";
import { projectStageCompanyUpdate } from "../../stages/stages.action";
import { useModelPopup } from "../../../../../common/hooks";
import DocumentsModal from "./DocumentsModal.js";

const CompanyRowComponent = (props) => {
  const {
    item,
    modifiedCompanyId,
    loading,
    onRatingChange,
    patientOrg,
    isKyndryl,
  } = props;
  const {
    company,
    stage,
    state,
    score,
    project,
    gsk_patient_organization,
    normalized_gsk_score,
    user_total_rating,
    total_tasks_progress,
    any_document,
  } = item;

  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    authReducer: { session },
    appReducer: { selectedItem },
    councilReducer: { selectedCouncil },
    projectsReducer: {
      projectReducer: { selectedProject },
    },
  } = useSelector((state) => state);

  const [organization, setOrganization] = useState({});
  const [hideOpt, setHideOpt] = useState([]);
  const popup = useModelPopup();
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (props) {
      if (item.is_product) {
        setOrganization(item);
        return;
      }
      if (company) {
        setOrganization({
          ...company,
          total_tasks_progress
        });
      }
      if (gsk_patient_organization) {
        setOrganization({
          ...gsk_patient_organization,
          total_tasks_progress
        });
      }
    }
  }, [props, patientOrg]);

  useEffect(() => {
    let hideOpt = [];
    switch (state) {
      case companyState.ON_TRACK:
        hideOpt = ["activate"];
        break;
      case companyState.PAUSED:
        hideOpt = ["pause"];
        break;
      case companyState.REMOVED:
        hideOpt = ["remove"];
        break;
      default:
        break;
    }
    setHideOpt(hideOpt);
  }, [state]);

  const handleMoreOptionClick = (state) => {
    const payload = {
      is_product: item.is_product,
      projectId: selectedProject.id,
      companyId: item.is_product ? item.id : organization.id,
      state,
      patientOrg,
      gsk: selectedCouncil.name === "GSK",
      enqueueSnackbar,
    };

    dispatch(projectCompanyChangeStatus(payload));
  };

  const handleMoreDocumentClick = () => {
    popup.show({
      title: "Documents",
      component: (
        <DocumentsModal
          popup={popup}
          companyId={company?.id}
          organizationId={gsk_patient_organization?.id}
          projectId={project?.id}
          enqueueSnackbar={enqueueSnackbar}
        />
      ),
    });
  };

  const handleSeeTaskClick = () => {
    dispatch(
      appSliderSettingSet({
        ...sliderSetting,
        show: true,
        height: "300",
        width: "540",
        header: <TaskAllInfoHeaderComponent company={organization} />,
        component: (
          <TaskAllInfoComponent
            patientOrg={patientOrg}
            company={organization}
          />
        ),
      }),
    );
  };

  const handleRateClick = () => {
    if (patientOrg || item.is_product) return true;
    dispatch(
      appSliderSettingSet({
        ...sliderSetting,
        show: true,
        height: "300",
        width: "540",
        header: <CompanyRatingsHeader company={organization} />,
        component: <CompanyRatings companyId={props.item.project_company_id} />,
      }),
    );
  };

  const handleRowClick = (e) => {
    dispatch(appSelectedSet(`company${organization.id}`));
  };

  const handleViewCompanyClick = () => {
    if (item.is_product) {
      history.push(`/companies/${item.company.slug || item.company.id}/products/${item.slug || item.id}`);

      dispatch(
        appSliderSettingSet({
          show: false,
        }),
      );

      return
    }

    if (company && !patientOrg) {
      history.push(`/companies/${company.slug || company.id}`);
    }

    if (gsk_patient_organization && patientOrg) {
      history.push(`/patient_organizations/${gsk_patient_organization.slug || gsk_patient_organization.id}`);
    }

    dispatch(
      appSliderSettingSet({
        show: false,
      }),
    );
  };

  const handleStageChange = (stage) => {
    let obj;

    if (item.is_product) {
      obj = {
        is_product: true,
        projectId: selectedProject.id,
        productId: item?.id,
        gsk_patient_organization_id: gsk_patient_organization?.id,
        stageId: stage,
        gsk: patientOrg,
        patient_org: patientOrg,
        userId: session.id,
        enqueueSnackbar,
      };
    } else {
      obj = {
        projectId: selectedProject.id,
        companyId: company?.id,
        gsk_patient_organization_id: gsk_patient_organization?.id,
        stageId: stage,
        gsk: patientOrg,
        patient_org: patientOrg,
        userId: session.id,
        enqueueSnackbar,
      };
    }

    dispatch(projectStageCompanyUpdate(obj));
  };

  return (
    <div
      key={organization.id}
      className={classNames(
        styles.taskRow,
        selectedItem === `company${organization.id}` && styles.selected,
      )}
      onClick={handleRowClick}
    >
      <div className={classNames(styles.colCompany, styles.colCompanyName)}>
        <div className={styles.companyIcon}>
          <div className={styles.iconCon}>
            <CompanyAvatar
              imgSrc={organization.logo}
              name={organization.name}
            />
          </div>
        </div>
        <div
          onClick={handleViewCompanyClick}
          className={styles.companyNameHover}
        >
          <span>{item.is_product ? item.name : organization.name}</span>
          {
            item.is_product && <span className="small text-muted">Product</span>
          }
        </div>
      </div>
      <div className={classNames(styles.taskcol, styles.colStage)}>
        {stage && (
          <State
            stageId={stage.id}
            isEditable
            onStageChange={handleStageChange}
          />
        )}
      </div>
      {!isKyndryl && (
        <div className={classNames(styles.taskcol, styles.colRating)}>
          {!blockFunctionality ? (
            <Button
              btn={BtnType.LINK}
              onClick={!patientOrg ? handleRateClick : null}
            >
              {patientOrg
                ? (user_total_rating !== 0
                    && normalized_gsk_score
                    && normalized_gsk_score.toFixed(2))
                  || 0
                : (user_total_rating !== 0 && score && score) || "Rate"}
            </Button>
          ) : (
            <Tooltip
              title="You have View-Only access. To Rate in this Project, please ask your Admin to upgrade your account to Standard access and invite you to this Project."
              placement="top"
            >
              <div>
                <Button
                  btn={BtnType.LINK}
                  // onClick={!patientOrg ? handleRateClick : null}
                >
                  {patientOrg
                    ? (user_total_rating !== 0
                        && normalized_gsk_score
                        && normalized_gsk_score.toFixed(2))
                      || 0
                    : (user_total_rating !== 0 && score && score) || "Rate"}
                </Button>
              </div>
            </Tooltip>
          )}
        </div>
      )}
      <div className={classNames(styles.taskcol, styles.colCompletion)}>
        {loading && modifiedCompanyId === company?.id ? (
          <Loading />
        ) : (
          <ProgressBar
            width="120px"
            tot={100}
            fill={organization.total_tasks_progress}
            showTot={false}
            showPrecentage
          />
        )}
      </div>
      <div className={classNames(styles.taskcol, styles.colStatus)}>
        <CompanyState state={state} />
      </div>
      <div className={classNames(styles.taskcol, styles.colSeeTask)}>
        <Button btn={BtnType.FRAME_LESS} onClick={handleSeeTaskClick}>
          SEE TASKS
        </Button>
      </div>
      <div className={classNames(styles.taskcol, styles.colDocs)}>
        {any_document && (
          <Icon
            {...mdSize}
            className={styles.documentIcon}
            icon="icn-subnav-documents"
            onClick={handleMoreDocumentClick}
          />
        )}
      </div>
      <div className={classNames(styles.taskcol, styles.colMore)}>
        {!blockFunctionality && (
          <MoreOptions
            options={companyOptions}
            hide={hideOpt}
            className={styles.moreOptWrp}
            onClick={handleMoreOptionClick}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(CompanyRowComponent);

import React from "react";
import classNames from "classnames";

import styles from "modules/projects/project/plants/plants.module.scss";

const PlantAndAreasStatuses = () => (
  <div className="d-flex justify-content-center flex-column">
    <div className="mb-2 d-flex w-100 justify-content-center">Key</div>
    <div className="d-flex justify-content-center flex-row">
      <div className="d-flex align-items-center mb-1 mr-3">
        <div className={classNames(styles.plantCircle, styles["original"])}></div>
        <div className="ml-1 small">Original</div>
      </div>
      <div className="d-flex align-items-center mb-1 mr-3">
        <div className={classNames(styles.plantCircle, styles["replicated"])}></div>
        <div className="ml-1 small">Replicated</div>
      </div>
      <div className="d-flex align-items-center mb-1 mr-3">
        <div className={classNames(styles.plantCircle, styles["under_review"])}></div>
        <div className="ml-1 small">Under Review</div>
      </div>
      <div className="d-flex align-items-center mb-1 mr-3">
        <div className={classNames(styles.plantCircle, styles["possible_replication"])}></div>
        <div className="ml-1 small">Possible Replications</div>
      </div>
      <div className="d-flex align-items-center mb-1 mr-3">
        <div className={classNames(styles.plantCircle, styles["different_solution"])}></div>
        <div className="ml-1 small">Different Solution</div>
      </div>
      <div className="d-flex align-items-center mb-1">
        <div className={classNames(styles.plantCircle, styles["not_applicable"])}></div>
        <div className="ml-1 small">Not Applicable</div>
      </div>
    </div>
  </div>
)

export default React.memo(PlantAndAreasStatuses);

import React from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import styles from './styles.module.scss';
import { BtnType, Button, CompanyAvatar } from "modules/primitives";
import { makeWebSite } from "../../../../common/helper";
import { httpPost } from "../../../../common/httpCall";
import LoaderWithDots from "../../../../common/components/loaderWithDots";
import { AIUpdateScoutCompany } from "modules/tractionAI/store/tractionAI.actions";

const ScoutCompany = ({ company, index, parentIndex }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [addingCompany, setAddCompany] = React.useState(false);

  const goToProfile = () => {
    window.open(`/companies/${company.company_slug || company.company_id}`, '_blank');
  };

  const addCompany = () => {
    setAddCompany(true);
    httpPost({
      apiVersion: "v2",
      call: "council_companies",
      data: {
        company_name: company.company_name,
        company_website: company.company_url,
      },
    }).subscribe((res) => {
      setAddCompany(false);
      dispatch(AIUpdateScoutCompany({
        parentIndex,
        index,
        company_id: res.response.company_id,
        company_slug: res.response.slug,
      }));
      enqueueSnackbar("Successfully added company.", {
        variant: "success",
      });
    })
  }

  return (
    <div className="d-flex">
      <div style={{ width: '40px' }}>{index + 1}.</div>
      <div className={styles.scoutCompany}>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center mb-2">
            <div className={styles.logoWrapper}>
              <CompanyAvatar
                imgSrc={company.logo}
                name={company.company_name}
                hasBorderRadius
              />
            </div>
            <div className="ml-3 d-flex flex-column">
              <span className={styles.name}>{company.company_name}</span>
              {
                company.company_url && (
                  <a className={styles.companyUrl} href={company.company_url} target="_blank"
                     rel="noopener noreferrer">
                    {makeWebSite(company.company_url)}
                  </a>
                )
              }
            </div>
          </div>
          {
            company.complete_justification && (
              <>
                <span>Why Recommended</span>
                <p>{company.complete_justification}</p>
              </>
            )
          }
        </div>
        <div className="d-flex align-items-center justify-content-center ml-3">
          {
            company.in_traction ? (
              <Button
                btn={BtnType.REGULAR}
                onClick={goToProfile}
                className={styles.buttonWrapper}
                icon="icn-checkbox-button"
              >Go to profile</Button>
            ) : (
              <>
                {
                  addingCompany ? (
                    <LoaderWithDots text="Adding company..." />
                  ) : (
                    <Button
                      icon="icn-add"
                      btn={BtnType.REGULAR}
                      onClick={addCompany}
                      className={styles.buttonWrapper}
                    >Add Company</Button>
                  )
                }
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default React.memo(ScoutCompany);

export const spekersList = [
  "Dusana Semenov",
  "Dusana Semenov",
  "Jel Chibuzo",
  "Amelia Edwards",
  "Pan Su",
];
export const companies = [
  "Voluntis",
  "Ambler",
  "Drone Racing League",
  "Informatica",
  "HashiCorp",
  "Voluntis",
  "KnightScope",
];

export const getBackgroundImage = () => {
  let bgImage = 0;
  const bgstrImage = localStorage.getItem("eventbg");
  if (bgstrImage) {
    bgImage = parseInt(bgstrImage);
  }
  if (bgImage < 5) {
    bgImage += 1;
  } else bgImage = 1;

  localStorage.setItem("eventbg", bgImage);
  return bgImage;
};

export const videoExtensions = [
  "mp4",
  "mov",
  "wmv",
  "flv",
  "avi",
  "avi",
  "webm",
  "mkv",
];

export const allowedExtensions = [
  "gif",
  "png",
  "jpg",
  "jpeg",
  "pdf",
  "ppt",
  "pptx",
  "pps",
  "odp",
];

import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import {
  convertFilterIndexToValues,
  convertTagIdToIndex,
  queryParamStringToArray,
  queryStringUrlReplacement,
  useQuery,
} from "../../../../common/helper";
import {
  companySearchCrunchBase,
  searchCrunchBaseClear,
  searchInCouncil,
  setCompaniesTagsNotInCouncil,
  setSelectedCompanies,
} from "modules/companies/companyMain/companyMain.action";
import { flat } from "modules/companies/companyMain/chartsWrapper/chartsView/charts/utils";
import { layoutType } from "modules/companies/companyMain/companyMain.constant";
import { apiStatus, httpPost } from "../../../../common/httpCall";
import { useCompanyPagination } from "modules/companies/companyMain/providers/useCompanyPagination";

export const useCompanyData = () => {
  const {
    companiesReducer: {
      companyMainReducer: {
        productsResultNr,
        searchResultNr,
        industries,
        topics,
        companiesTagsNotInCouncil,
        searchResults,
        companyList,
        checkedFilters,
        selectedCompanies,
        loading,
      },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const toggleFilter = useQuery().get("toggle");
  const { pageSetting, setPageSetting } = useCompanyPagination();

  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedCustomTags, setSelectedCustomTags] = useState([]);
  const [selectLayout, setSelectLayout] = useState(location.state?.layout || 0);
  const [downloadPdfIsActive, setDownloadPdfIsActive] = useState(false);
  const [showCrunchBase, setShowCrunchBase] = useState(false);
  const [itemToBeAppended, setItemToBeAppended] = useState(null);
  const [propValue, setPropValue] = useState(false);
  const [locationType, setLocationType] = useState(null);
  const [showLoading, setShowLoading] = useState(null);

  useEffect(
    () => () =>
      dispatch(
        setSelectedCompanies({
          shouldReset: true,
          companies: [],
        })
      ),
    []
  );

  useEffect(() => {
    if (location?.state?.location) {
      const locationStrAsArray = queryParamStringToArray(
        location?.state?.location || ""
      );

      if (locationStrAsArray.length) {
        const splitedValue = locationStrAsArray
          .map((str) => str.replace(/-/g, ","))[0]
          .split(",")[0]
          .split("#");
        const type = splitedValue[1];

        if (type) {
          setLocationType(type);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (searchResults?.length && topics?.length && industries?.length) {
      const flattenTopics = flat(topics, "children_topics");
      const flattenIndustries = flat(industries, "children_industries");
      const tagsThatAreNotInCouncil = searchResults.reduce(
        (acc, company) => {
          const topicsThatDoesntExist = company.resource_topics
            ? company.resource_topics
              .filter(
                (tag) =>
                  !flattenTopics.some((t) => t.id === tag.resource_topic_id)
              )
              .map((item) => item.topic)
            : [];
          const industriesThatDoesntExist = company.resource_industries
            ? company.resource_industries
              .filter(
                (tag) =>
                  !flattenIndustries.some(
                    (t) => t.id === tag.resource_industry_id
                  )
              )
              .map((item) => item.industry)
            : [];
          const newTopics = [...acc.topics, ...topicsThatDoesntExist].filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
          );
          const newIndustries = [
            ...acc.industries,
            ...industriesThatDoesntExist,
          ].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);

          return {
            topics: newTopics,
            industries: newIndustries,
          };
        },
        {
          topics: [],
          industries: [],
        }
      );

      dispatch(setCompaniesTagsNotInCouncil(tagsThatAreNotInCouncil));
    }

    return () => null;
  }, [topics, industries, searchResults]);

  const addToTraction = useCallback(
    (company) => {
      httpPost({
        apiVersion: "v2",
        call: "council_companies",
        data: {
          company_name: company.name,
          crunchbase_slug: company.permalink,
          company_website: company.website,
        },
      }).subscribe((result) => {
        if (
          result.status === apiStatus.SUCCESS ||
          result.status === apiStatus.CREATED
        ) {
          if (selectedCouncil?.name === "Kyndryl") {
            history.push({
              pathname: `/companies/${
                result.response.slug || result.response.id
              }`,
              search: "?showAddRelationShip=true",
              state: {
                ...history.location?.state,
                showAddRelationShip: true,
              },
            });

            return;
          }

          setItemToBeAppended(result.response);

          enqueueSnackbar("Successfully added company.", {
            variant: "success",
          });

          handleCrunchBase(false, true);

          dispatch(searchCrunchBaseClear({}));
          history.push({
            pathname: "/companies",
          });
        } else {
          enqueueSnackbar("Something wrong happened", {
            variant: "error",
          });
        }
      });
    },
    [selectedCouncil, selectLayout]
  );

  const memoizedCompanies = useMemo(() => {
    if (searchResults?.length && itemToBeAppended) {
      return [
        {
          ...itemToBeAppended,
          appendedItem: true,
        },
        ...searchResults.filter((i) => i.id !== itemToBeAppended.id),
      ]
    }

    if (companyList?.length && itemToBeAppended) {
      return [
        {
          ...itemToBeAppended,
          appendedItem: true,
        },
        ...companyList.filter((i) => i.id !== itemToBeAppended.id),
      ]
    }

    return searchResults || companyList;
  }, [itemToBeAppended, searchResults, companyList]);

  useEffect(() => {
    if (memoizedCompanies?.length && !loading) {
      const timeout = setTimeout(() => {
        setShowLoading(false);
        clearTimeout(timeout)
      }, 200);

      return;
    } else if (memoizedCompanies?.length && loading) {
      setShowLoading(false);
      return;
    }

    setShowLoading(loading)
  }, [memoizedCompanies, loading]);

  useEffect(() => {
    if (!location.state) {
      setSelectLayout(0);
      setSelectedTopics([]);
      setSelectedIndustries([]);
      setSelectedCustomTags([]);
      return;
    }

    if (location.state.topic && topics) {
      const asArr = queryParamStringToArray(location.state.topic);
      const flattenTopics = flat(topics, "children_topics");
      const filteredTopics = [
        ...companiesTagsNotInCouncil.topics,
        ...flattenTopics,
      ].filter((t) => asArr.includes(t.id));
      setSelectedTopics(filteredTopics);
    } else {
      setSelectedTopics([]);
    }

    if (location.state.industry && industries) {
      const asArr = queryParamStringToArray(location.state.industry);
      const flattenIndustries = flat(industries, "children_industries");
      const filteredIndustries = [
        ...companiesTagsNotInCouncil.industries,
        ...flattenIndustries,
      ].filter((t) => asArr.includes(t.id));
      setSelectedIndustries(filteredIndustries);
    } else {
      setSelectedIndustries([]);
    }
    if (location.state.custom_tags_ids) {
      const arrIndexes = queryParamStringToArray(
        location.state.custom_tags_ids
      );
      const arrTagsIds = convertFilterIndexToValues(
        arrIndexes,
        selectedCouncil?.council_custom_tag_groups
      );

      setSelectedCustomTags(
        arrTagsIds.map((id) => ({
          id,
        }))
      );
    } else {
      setSelectedCustomTags([]);
    }

    return () => null;
  }, [
    location,
    industries,
    topics,
    companiesTagsNotInCouncil,
    selectedCouncil,
  ]);

  const handleCrunchBase = useCallback(
    (val, resetQuery) => {
      let search = queryStringUrlReplacement(
        history.location.search,
        "isCrunchSearch",
        val
      );
      search = queryStringUrlReplacement(search, "page", 1);

      if (memoizedCompanies?.length) {
        search = queryStringUrlReplacement(search, "topic", []);
        search = queryStringUrlReplacement(search, "industry", []);
      }

      const tagsToReset = memoizedCompanies?.length
        ? { topic: null, industry: null }
        : {};

      if (resetQuery) {
        search = queryStringUrlReplacement(search, "query", "");
      }

      let stringifyValue;

      if (memoizedCompanies?.length === 0 && val && !resetQuery) {
        const combined = [...selectedTopics, ...selectedIndustries];

        if (!history.location?.state?.query && combined.length > 0) {
          stringifyValue = combined.reduce(
            (acc, elem) => `${acc}${!acc.length ? "" : ","} ${elem.name}`,
            ""
          );

          search = queryStringUrlReplacement(search, "query", stringifyValue);
        }
      }

      const query = resetQuery
        ? ""
        : stringifyValue || history.location?.state?.query || "";

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          isCrunchSearch: val,
          page: 1,
          query,
          ...tagsToReset,
        },
      });

      setShowCrunchBase(val);
    },
    [selectLayout, layoutType, selectedTopics, selectedIndustries, memoizedCompanies]
  );

  const showProductsCount = useMemo(() => {
    if (productsResultNr > 0 && productsResultNr && toggleFilter !== '1') {
      const { state } = location;

      if (!state) return true;

      const doesItMeetCondition = state.custom_tags_ids?.length ||
        state.location?.length ||
        state.scoreFrom?.length ||
        state.scoreTo?.length ||
        state.foundedFrom ||
        state.foundedTo ||
        state.employees?.length ||
        state.estimatedRevenue?.length ||
        state.fundingFrom?.length ||
        state.fundingTo?.length ||
        state.agreements?.length;

      if (selectedCouncil?.name === "Kyndryl") {
        return !doesItMeetCondition;
      }

      return !(doesItMeetCondition || state.lists?.length);
    }

    return false;
  }, [location, productsResultNr, toggleFilter, selectedCouncil, selectedCouncil]);

  const genericResultsNr = useMemo(() => {
    if (showProductsCount) return Number(searchResultNr) + Number(productsResultNr || 0);
    return Number(searchResultNr);
  }, [productsResultNr, searchResultNr, showProductsCount]);

  const getCompaniesCrunchWithParams = useCallback(() => {
    const { state } = location;

    if (state.afterId && location.state.afterId.length > 0) {
      dispatch(
        companySearchCrunchBase({
          data: state.query,
          council_id: selectedCouncil.id,
          limit: 50,
          after_id: state.afterId,
          enqueueSnackbar,
        })
      );

      return;
    }

    if (state.beforeId && state.beforeId.length > 0) {
      dispatch(
        companySearchCrunchBase({
          data: state.query,
          council_id: selectedCouncil.id,
          limit: 50,
          before_id: state.beforeId,
          enqueueSnackbar,
        })
      );

      return;
    }

    dispatch(
      companySearchCrunchBase({
        data: state.query,
        limit: 50,
        council_id: selectedCouncil.id,
        enqueueSnackbar,
      })
    );
  }, [location, selectedCouncil]);

  useEffect(() => {
    if (!location.state) {
      setShowCrunchBase(false);

      return;
    }

    if (
      location.state?.isCrunchSearch &&
      JSON.parse(location.state?.isCrunchSearch)
    ) {
      setShowCrunchBase(true);
    }

    if (
      location.state?.query &&
      location.state?.isCrunchSearch &&
      JSON.parse(location.state?.isCrunchSearch)
    ) {
      getCompaniesCrunchWithParams();
    }

    return () => null;
  }, [location, selectedCouncil]);

  const getCompaniesWithParams = useCallback((selectedPage) => {
    // don't make other calls when we are in the charts view
    // and, we do some changes in the left filters
    if (location.state?.layout === 2) return;

    const { state, search } = location;

    const queryParams = new URLSearchParams(search);
    const makePage = () => {
      if (selectedPage) return selectedPage;
      if (queryParams.get("page")) {
        return Number(queryParams.get("page"));
      }
      if (state?.page) {
        return Number(state?.page);
      }
      return 1;
    }
    const page = makePage();
    const query = queryParams.get("query") || state?.query || "";

    setPageSetting({
      ...pageSetting,
      current: page,
    });

    const businessUnits = queryParamStringToArray(state?.businessUnits || "");
    const relationShipStatus = queryParamStringToArray(
      state?.relationShipStatus || ""
    );
    const investmentStages = queryParamStringToArray(
      state?.investmentStages || ""
    );
    const topics = queryParamStringToArray(state?.topic || "");
    const industries = queryParamStringToArray(state?.industry || "");
    const owners = queryParamStringToArray(state?.owners || "");
    const locationStrAsArray = queryParamStringToArray(state?.location || "");
    const agreements = queryParamStringToArray(state?.agreements || "");
    const idb_connection_options = queryParamStringToArray(
      state?.idbEcosystem || ""
    );
    const location_countries = queryParamStringToArray(state?.k_country || "");
    const custom_tags_ids = queryParamStringToArray(
      state?.custom_tags_ids || ""
    );
    const locationProps = {};

    if (locationStrAsArray.length) {
      const splitedValue = locationStrAsArray
        .map((str) => str.replace(/-/g, ","))[0]
        .split(",")[0]
        .split("#");
      const value = splitedValue[0];
      const type = splitedValue[1];

      if (splitedValue) {
        locationProps[`location_${type}`] = value;
      }
    }

    const payload = {
      data: query,
      topic_ids: topics,
      industry_ids: industries,
      relationship_stages: relationShipStatus,
      council_company_score_gte: state?.scoreFrom
        ? Number(state?.scoreFrom)
        : null,
      council_company_score_lte: state?.scoreTo ? Number(state?.scoreTo) : null,
      founded_gte: state?.foundedFrom ? state?.foundedFrom : null,
      founded_lte: state?.foundedTo ? state?.foundedTo : null,
      relationship_owners_ids: owners,
      num_employee_enum: state?.employees ? state?.employees : "",
      revenue_range: state?.estimatedRevenue ? state?.estimatedRevenue : "",
      lists: state?.lists ? state?.lists : "",
      total_founding_gte: state?.fundingFrom
        ? Number(state?.fundingFrom.replace(/\D/g, ""))
        : null,
      total_founding_lte:
        state?.fundingTo && state?.fundingTo !== "Any Amount"
          ? Number(state?.fundingTo.replace(/\D/g, ""))
          : null,
      council_id: selectedCouncil.id,
      mode: "companies",
      page,
      items: state?.layout && state?.layout === 1 ? 50 : 20,
      withContext: true,
      sort_attribute: state?.sortAttribute || "",
      sort_order: state?.sortOrder || "",
      locationProps,
      agreements,
      enqueueSnackbar,
      location_countries,
      idb_connection_options,
      council_relationship_stage_id: relationShipStatus,
      business_unit_ids: businessUnits,
      theme_investment_stage_id: investmentStages,
      custom_tags_ids: convertFilterIndexToValues(
        custom_tags_ids,
        selectedCouncil?.council_custom_tag_groups
      ),
      cb: () => {},
      filter: state?.toggle,
    };

    dispatch(searchInCouncil(payload));
  }, [location, selectLayout, checkedFilters]);

  useEffect(() => {
    setPropValue(location?.state?.query);

    if (!searchResults?.length || !companyList?.length) {
      getCompaniesWithParams();
    }

    return () => null;
  }, [location]);

  const handleRelationShipChange = useCallback(
    (shouldntRequest) => {
      if (shouldntRequest) return;

      const relationShipStatus = queryParamStringToArray(
        location?.state?.relationShipStatus || ""
      );

      if (!relationShipStatus?.length) return;

      getCompaniesWithParams();
    },
    [location, selectedCouncil]
  );

  const clickOnTag = (e, item, type, groupId) => {
    e.preventDefault();
    e.stopPropagation();

    let value;

    if (type === "topic") {
      const isAlreadySelected = selectedTopics.some((i) => i.id === item);

      if (isAlreadySelected) {
        const topicsArrAsString = selectedTopics
          .map((t) => t.id)
          .filter((i) => i !== item);
        value = topicsArrAsString.reduce(
          (acc, elem) => `${acc}${acc.length ? "," : ""}${elem}`,
          ""
        );
      } else {
        const topicsArrAsString = [...selectedTopics.map((t) => t.id), item];
        value = topicsArrAsString.reduce(
          (acc, elem) => `${acc}${acc.length ? "," : ""}${elem}`,
          ""
        );
      }
    }

    if (type === "industry") {
      const isAlreadySelected = selectedIndustries.some((i) => i.id === item);

      if (isAlreadySelected) {
        const industriesArrAsString = selectedIndustries
          .map((t) => t.id)
          .filter((i) => i !== item);
        value = industriesArrAsString.reduce(
          (acc, elem) => `${acc}${acc.length ? "," : ""}${elem}`,
          ""
        );
      } else {
        const industriesArrAsString = [
          ...selectedIndustries.map((t) => t.id),
          item,
        ];
        value = industriesArrAsString.reduce(
          (acc, elem) => `${acc}${acc.length ? "," : ""}${elem}`,
          ""
        );
      }
    }
    if (type === "custom_tags_ids") {
      const isAlreadySelected = selectedCustomTags.some((i) => i.id === item);
      if (isAlreadySelected) {
        const tagsArrAsString = selectedCustomTags
          .map((t) => t.id)
          .filter((i) => i !== item)
          .map((tagId) =>
            convertTagIdToIndex(
              tagId,
              selectedCouncil?.council_custom_tag_groups,
              groupId
            )
          );

        value = tagsArrAsString.reduce(
          (acc, elem) => `${acc}${acc.length ? "," : ""}${elem}`,
          ""
        );
      } else {
        value = convertTagIdToIndex(
          item,
          selectedCouncil?.council_custom_tag_groups,
          groupId
        );
      }
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      type,
      value
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        [type]: value,
        page: 1,
      },
    });
  };

  const handleCompanySelect = useCallback((company) => {
    const companyIsSelected = selectedCompanies.some(
      (c) => c.id === company.id
    );

    if (companyIsSelected) {
      const selected = selectedCompanies.filter((c) => c.id !== company.id);
      dispatch(
        setSelectedCompanies({
          companies: selected,
        })
      );
    } else {
      dispatch(
        setSelectedCompanies({
          companies: [...selectedCompanies, company],
        })
      );
    }
  }, [selectedCompanies]);

  useEffect(() => {
    if (
      downloadPdfIsActive &&
      selectLayout === 1 &&
      selectedCompanies.length === 0
    ) {
      getCompaniesWithParams();
    }
  }, [downloadPdfIsActive]);

  return {
    downloadPdfIsActive,
    setDownloadPdfIsActive,
    showProductsCount,
    genericResultsNr,
    selectLayout,
    setSelectLayout,
    toggleFilter,
    showCrunchBase,
    selectedTopics,
    selectedIndustries,
    selectedCustomTags,
    itemToBeAppended,
    handleCrunchBase,
    addToTraction,
    propValue,
    getCompaniesWithParams,
    memoizedCompanies,
    memoizedLoading: showLoading,
    locationType,
    handleRelationShipChange,
    clickOnTag,
    handleCompanySelect
  };
};

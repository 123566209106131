import * as Yup from "yup";
import { objectType } from "modules/companies/company/details/details.constant";

export const initModel = {
  recipients: [],
  groups: [],
  anonymouse: false,
  subject: "",
  message: "",
};

export const validation = Yup.object().shape({
  // title: Yup.string().required('Title is required'),
  starts_on: Yup.string().required("Start date is required"),
  ends_on: Yup.string().required("End date is required"),
});

export const optionActivities = [
  {
    text: "Note",
    value: 0,
  },
  {
    text: "Call",
    value: 1,
  },
  {
    text: "Email",
    value: 2,
  },
  {
    text: "Event",
    value: 3,
  },
  {
    text: "Meeting",
    value: 4,
  },
];

export const modelTemplate = {
  0: {
    name: {
      label: "Spoke with",
      placeholder: "Name",
    },
    date: {
      label: "Date of Call",
      placeholder: "Date and Time",
    },
    description: {
      label: "We spoke about?",
      placeholder: "Capture conversation notes",
    },
  },
};

export const optionItem = [
  {
    text: "Text",
    value: objectType.text,
  },
  {
    text: "Number",
    value: objectType.number,
  },
  {
    text: "Date",
    value: objectType.date,
  },
  {
    text: "Dropdown",
    value: objectType.dropDown,
  },
  {
    text: "Checklist",
    value: objectType.checkList,
  },
  {
    text: "Document",
    value: objectType.document,
  },
  {
    text: "Agreement",
    value: objectType.agreement,
  },
  {
    text: "Link",
    value: objectType.link,
  },
  {
    text: "Image",
    value: objectType.image,
  },
  {
    text: "Video",
    value: objectType.video,
  },
  {
    text: "Slides",
    value: objectType.slides,
  },
];

import React, { useRef, useState, useEffect } from "react";
import Slider from "rc-slider";
import styles from "./slider.module.scss";
import "rc-slider/assets/index.css";

const SliderComponent = ({
  text,
  value = 0,
  onChange,
  max = 10,
  min = 1,
  customStyles,
  disabled = false,
}) => {
  const [displayVal, setDisplayVal] = useState(0);
  const [sliderMax, setSliderMax] = useState(max);
  const [sliderMin, setSliderMin] = useState(min);
  const ref = useRef();

  useEffect(() => {
    setDisplayVal(value);
    const htmlSlider = ref.current.sliderRef.getElementsByClassName("rc-slider-handle");
    htmlSlider[0].innerHTML = value;
  }, [value]);

  useEffect(() => {
    setSliderMax(max);
  }, [max]);

  return (
    <div className={styles.sliderWrp}>
      <div className={styles.sliderMin}>{sliderMin}</div>
      <Slider
        ref={ref}
        value={displayVal}
        step={1}
        min={1}
        max={sliderMax}
        onChange={setDisplayVal}
        onAfterChange={onChange}
        railStyle={{
          height: 2,
        }}
        handleStyle={{
          height: 24,
          width: 24,
          marginTop: -11,
          backgroundColor: displayVal === 0 ? "#ccc" : "#3994c1",
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: displayVal === 0 ? "transparent" : "#ffffff",
          ...customStyles?.handleStyle,
        }}
        trackStyle={{
          backgroundColor: "#7cc1e4",
          height: 2,
          ...customStyles?.trackStyle,
        }}
        disabled={disabled}
        tipFormatter={(value) => `${value}€`}
      />
      <div className={styles.sliderMax}>{sliderMax}</div>
    </div>
  );
};

export default React.memo(SliderComponent);

export const PROJECT_COMPANY_COMPARE_SORTED_LIST_SET =
  "PROJECT_COMPANY_COMPARE_SORTED_LIST_SET";
export const projectCompanyCompareSortedListSet = (payload) => ({
  type: PROJECT_COMPANY_COMPARE_SORTED_LIST_SET,
  payload,
});

export const PROJECT_COMPANY_COMPARE_CUSTOM_FIELDS_GET =
  "PROJECT_COMPANY_COMPARE_CUSTOM_FIELDS_GET";
export const projectCompanyCompareCustomFieldsGet = (payload) => ({
  type: PROJECT_COMPANY_COMPARE_CUSTOM_FIELDS_GET,
  payload,
});

export const PROJECT_COMPANY_COMPARE_CUSTOM_FIELDS_GET_SUCCESS =
  "PROJECT_COMPANY_COMPARE_CUSTOM_FIELDS_GET_SUCCESS";
export const projectCompanyCompareCustomFieldsGetSuccess = (payload) => ({
  type: PROJECT_COMPANY_COMPARE_CUSTOM_FIELDS_GET_SUCCESS,
  payload,
});

export const SET_COMPARE_COMPANIES = "SET_COMPARE_COMPANIES";
export const setCompareCompanies = (payload) => ({
  type: SET_COMPARE_COMPANIES,
  payload,
});

export const GET_COMPARE_COMPANIES = "GET_COMPARE_COMPANIES";
export const getCompareCompanies = (payload) => ({
  type: GET_COMPARE_COMPANIES,
  payload,
});

export const GET_COMPARE_COMPANIES_SUCCESS = "GET_COMPARE_COMPANIES_SUCCESS";
export const getCompareCompaniesSuccess = (payload) => ({
  type: GET_COMPARE_COMPANIES_SUCCESS,
  payload,
});
export const SET_COMPARE_NOTES = "SET_COMPARE_NOTES";
export const setCompareNotes = (payload) => {
  return {
    type: SET_COMPARE_NOTES,
    payload,
  };
};

import React from "react";
import { MoreOptions } from "modules/primitives/index";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { companyCustomFieldScrollTo } from "../company.action";

import styles from "./documents.module.scss";
import { Icon, smSize } from "../../../../common/icon";
import { shouldDownload } from "../../../../common/helper";
import { moreOptions } from "./documents.constatnt";
import UploadedByInfo from "../../../../common/components/uploadedByInfo";

const DesktopDocRow = ({
  document,
  documentName,
  handleMoreOptionClick,
  session,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const setDocName = () => {
    if (documentName) {
      return documentName;
    }

    if (
      !documentName &&
      document.company_custom_field?.field_type === "video"
    ) {
      return "Video Embed";
    }
    if (
      !documentName &&
      document.company_custom_field?.field_type === "slide" &&
      document.uri.indexOf("<iframe") >= 0
    ) {
      return "Slide Embed";
    }
    return "-";
  };

  return (
    <div className={styles.gridRow}>
      <div className={styles.document}>{setDocName()}</div>
      <div className={styles.project}>
        {document.project?.project_id ? (
          <Link to={`/projects/${document.project?.project_id}`}>
            {document.project?.name}
          </Link>
        ) : (
          "-"
        )}
      </div>
      <div className={styles.task}>
        {document.projects_task_assignment?.projects_task?.name ? (
          <Link
            to={`/projects/${document.project?.project_id}/task?tab=all_task&taskAsignementId=${document.projects_task_assignment?.projects_task_assignment_id}&taskId=${document.projects_task_assignment?.projects_task?.projects_task_id}`}
          >
            {document.projects_task_assignment?.projects_task?.name}
          </Link>
        ) : (
          "-"
        )}
      </div>
      <div className={styles.task}>
        <UploadedByInfo document={document} />
      </div>
      <div className={styles.moreOpt}>
        <div className="mr-1s d-flex align-items-center">
          {document.uri.indexOf("<iframe") >= 0 ? (
            <>
              <div
                className={styles.docView}
                onClick={() => {
                  const timeout = setTimeout(() => {
                    history.push(
                      `/companies/${
                        document.company.slug || document.company?.company_id
                      }/details`
                    );
                    dispatch(
                      companyCustomFieldScrollTo({
                        customScrollToId:
                          document.company_custom_field.company_custom_field_id,
                      })
                    );
                    clearTimeout(timeout);
                  }, 100);
                }}
              >
                view
              </div>
            </>
          ) : (
            <>
              <a href={document.uri} target="_blank" download rel="noreferrer">
                <Icon
                  {...smSize}
                  icon="icn-export-button"
                  className={styles.download}
                />
              </a>
              <a
                className={styles.docView}
                target="blank"
                href={document.uri}
                download
              >
                {shouldDownload(document.name) ? "download" : "view"}
              </a>
            </>
          )}
        </div>
        {(document.added_by?.id === session.id ||
          session.council_role.indexOf("admin") >= 0) && (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, document.id, document.name);
            }}
            customSvgIcon
          />
        )}
      </div>
    </div>
  );
};
export default DesktopDocRow;

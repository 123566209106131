export const UPDATE_COMPANY_PROGRESS_BAR = 'SET_COMPANY_TASK_ASSIGNMENT_COMPLETED';
export const updateCompanyProgressBar = (payload) => ({
  type: UPDATE_COMPANY_PROGRESS_BAR,
  payload,
});

export const SET_COMPANY_TASK_ASSIGNMENT_COMPLETED = 'SET_COMPANY_TASK_ASSIGNMENT_COMPLETED';
export const setCompanyTaskAssignmentCompleted = (payload) => ({
  type: SET_COMPANY_TASK_ASSIGNMENT_COMPLETED,
  payload,
});

export const PROJECT_ORGANIZATIONS_GET_ATTEMPT = 'PROJECT_ORGANIZATIONS_GET_ATTEMPT';
export const projectOrganizationsGet = (payload) => ({
  type: PROJECT_ORGANIZATIONS_GET_ATTEMPT,
  payload,
});

export const PROJECT_ORGANIZATIONS_GET_SUCCESS = 'PROJECT_ORGANIZATIONS_GET_SUCCESS';
export const projectOrganizationsGetSuccess = (payload) => ({
  type: PROJECT_ORGANIZATIONS_GET_SUCCESS,
  payload,
});

export const PROJECT_COMPANY_LIST_GET_ATTEMPT = 'PROJECT_COMPANY_LIST_GET_ATTEMPT';
export const projectCompanyListGet = (payload) => ({ type: PROJECT_COMPANY_LIST_GET_ATTEMPT, payload });

export const GET_PROJECT_COMPANIES_SUCCESS = 'GET_PROJECT_COMPANIES_SUCCESS';
export const getProjectCompaniesSuccess = (payload) => ({
  type: GET_PROJECT_COMPANIES_SUCCESS,
  payload,
});

export const PROJECT_PATIENT_ORG_NEW_MULTIPLE_ATTEMPT = 'PROJECT_PATIENT_ORG_NEW_MULTIPLE_ATTEMPT';
export const projectPatientOrgNewMultiple = (payload) => ({
  type: PROJECT_PATIENT_ORG_NEW_MULTIPLE_ATTEMPT,
  payload,
});

export const FOLLOW_MULTIPLE_COMPANIES_ATTEMPT = 'FOLLOW_MULTIPLE_COMPANIES_ATTEMPT';
export const followMultipleCompaniesAttempt = (payload) => ({
  type: FOLLOW_MULTIPLE_COMPANIES_ATTEMPT,
  payload,
});

export const FOLLOW_MULTIPLE_COMPANIES_SUCCESS = 'FOLLOW_MULTIPLE_COMPANIES_SUCCESS';
export const followMultipleCompaniesSuccess = (payload) => ({
  type: FOLLOW_MULTIPLE_COMPANIES_SUCCESS,
  payload,
});

export const PROJECT_COMPANY_NEW_MULTIPLE_ATTEMPT = 'PROJECT_COMPANY_NEW_MULTIPLE_ATTEMPT';
export const projectCompanyNewMultiple = (payload) => ({
  type: PROJECT_COMPANY_NEW_MULTIPLE_ATTEMPT,
  payload,
});

export const PROJECT_PATIENT_ORG_NEW_ATTEMPT = 'PROJECT_PATIENT_ORG_NEW_ATTEMPT';
export const projectPatientOrgNew = (payload) => ({
  type: PROJECT_PATIENT_ORG_NEW_ATTEMPT,
  payload,
});

export const ROJECT_COMPANY_NEW_SUCCESS = 'ROJECT_COMPANY_NEW_SUCCESS';
export const projectCompanyNewSuccess = (payload) => ({
  type: ROJECT_COMPANY_NEW_SUCCESS,
  payload,
});

export const PROJECT_COMPANY_REMOVE_SUCCESS = 'PROJECT_COMPANY_REMOVE_SUCCESS';
export const projectCompanySuccess = (payload) => ({
  type: PROJECT_COMPANY_REMOVE_SUCCESS,
  payload,
});

export const PROJECT_COMPANY_CHANGE_STATUS_ATTEMPT = 'PROJECT_COMPANY_CHANGE_STATUS_ATTEMPT';
export const projectCompanyChangeStatus = (payload) => ({
  type: PROJECT_COMPANY_CHANGE_STATUS_ATTEMPT,
  payload,
});

export const PROJECT_COMPANY_DELETE_PERMANENTLY = 'PROJECT_COMPANY_DELETE_PERMANENTLY';
export const projectCompanyDeletePermanently = (payload) => ({
  type: PROJECT_COMPANY_DELETE_PERMANENTLY,
  payload,
});

export const PROJECT_COMPANY_CHANGE_STATUS_SUCCESS = 'PROJECT_COMPANY_CHANGE_STATUS_SUCCESS';
export const projectCompanyChangeStatusSuccess = (payload) => ({
  type: PROJECT_COMPANY_CHANGE_STATUS_SUCCESS,
  payload,
});

export const PROJECT_ORGANIZATION_TASK_LIST_GET_ATTEMPT = 'PROJECT_ORGANIZATION_TASK_LIST_GET_ATTEMPT';
export const projectOrganizationTaskListGet = (payload) => ({
  type: PROJECT_ORGANIZATION_TASK_LIST_GET_ATTEMPT,
  payload,
});
export const PROJECT_ORGANIZATION_TASK_LIST_GET_SUCCESS = 'PROJECT_ORGANIZATION_TASK_LIST_GET_SUCCESS';
export const projectOrganizationTaskListGetSuccess = (payload) => ({
  type: PROJECT_ORGANIZATION_TASK_LIST_GET_SUCCESS,
  payload,
});

export const PROJECT_ORGANIZATION_TASK_UPDATE_SUCCESS = 'PROJECT_ORGANIZATION_TASK_UPDATE_SUCCESS';
export const projectOrganizationTaskUpdateSuccess = (payload) => ({
  type: PROJECT_ORGANIZATION_TASK_UPDATE_SUCCESS,
  payload,
});

export const PROJECT_COMPANY_TASK_LIST_GET_ATTEMPT = 'PROJECT_COMPANY_TASK_LIST_GET_ATTEMPT';
export const projectCompanyTaskLitGet = (payload) => ({
  type: PROJECT_COMPANY_TASK_LIST_GET_ATTEMPT,
  payload,
});
export const PROJECT_COMPANY_TASK_LIST_GET_SUCCESS = 'PROJECT_COMPANY_TASK_LIST_GET_SUCCESS';
export const projectCompanyTaskLitGetSuccess = (payload) => ({
  type: PROJECT_COMPANY_TASK_LIST_GET_SUCCESS,
  payload,
});

export const PROJECT_COMPANY_SORTED_LIST_SET = 'PROJECT_COMPANY_SORTED_LIST_SET';
export const projectCompanySortedListSet = (payload) => ({
  type: PROJECT_COMPANY_SORTED_LIST_SET,
  payload,
});

export const RATING_SAVE_ATTEMPT = 'RATING_SAVE_ATTEMPT';
export const ratingSaveAttempt = (payload) => ({
  type: RATING_SAVE_ATTEMPT,
  payload,
});
export const RATING_SAVE_SUCCESS = 'RATING_SAVE_SUCCESS';
export const ratingSaveSuccess = (payload) => ({
  type: RATING_SAVE_SUCCESS,
  payload,
});

export const RATING_EDIT_ATTEMPT = 'RATING_EDIT_ATTEMPT';
export const ratingEditAttempt = (payload) => ({
  type: RATING_EDIT_ATTEMPT,
  payload,
});
export const RATING_EDIT_SUCCESS = 'RATING_EDIT_SUCCESS';
export const ratingEditSuccess = (payload) => ({
  type: RATING_EDIT_SUCCESS,
  payload,
});

export const GET_COMPANY_DOCUMENTS_ATTEMPT = 'GET_COMPANY_DOCUMENTS_ATTEMPT';
export const getCompanyDocumentsAttempt = (payload) => ({
  type: GET_COMPANY_DOCUMENTS_ATTEMPT,
  payload,
});

export const GET_COMPANY_DOCUMENTS_SUCCESS = 'GET_COMPANY_DOCUMENTS_SUCCESS';
export const getCompanyDocumentsSuccess = (payload) => ({
  type: GET_COMPANY_DOCUMENTS_SUCCESS,
  payload,
});

export const CHANGE_COMPANY_DOCUMENT_STATE = 'CHANGE_COMPANY_DOCUMENT_STATE';
export const setChangeCompanyDocumentState = (payload) => ({
  type: CHANGE_COMPANY_DOCUMENT_STATE,
  payload,
});

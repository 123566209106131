import {
  THEME_DOCUMENT_GET_ATTEMPT,
  THEME_DOCUMENT_GET_SUCCESS,
  THEME_DOCUMENT_POST_ATTEMPT,
  THEME_DOCUMENT_POST_SUCCESS,
  THEME_DOCUMENT_DELETE_SUCCESS,
  THEME_DOCUMENT_DELETE_ATTEMPT
} from "./documentsTab.action";

const initState = {
  documents: [],
  updateDocuments: false,
  loadingDocs: false,
};

const themesDocumentReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case THEME_DOCUMENT_GET_ATTEMPT:
      return {
        ...state,
        loadingDocument: true,
      };
    case THEME_DOCUMENT_GET_SUCCESS:
      return {
        ...state,
        documents: payload.response,
        loadingDocument: false,
      };
    case THEME_DOCUMENT_POST_ATTEMPT:
      return {
        ...state,
        loadingDocument: true,
      };
    case THEME_DOCUMENT_POST_SUCCESS:
      return {
        ...state,
        documents: [...state.documents, payload.response],
        loadingDocument: false,
      };
      case THEME_DOCUMENT_DELETE_ATTEMPT:
        return {
          ...state,
          // loadingDocument: true,
        };

      
      case THEME_DOCUMENT_DELETE_SUCCESS:
        return {
          ...state,
          documents: [...state.documents.filter((doc) => doc.id !== payload.document_id)],
          // loadingDocument: false,
        };
      

    default:
      return state;
  }
};
export default themesDocumentReducer;

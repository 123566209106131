import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { DropSelect, Label } from "modules/primitives";

const defState = [
  {
    text: "All (visible to everyone)",
    value: "all",
  },
  {
    text: "Project Members",
    value: "project_members",
  },
]

const NewProjectAccess = ({ isDisabled, formikprops }) => {
  const {
    teamsReducer: { userTeams },
  } = useSelector((state) => state);

  const [optionVisibility, setOptionVisibility] = useState(defState);

  useEffect(() => {
    if (userTeams.length > 0 && optionVisibility.length === 2) {
      setOptionVisibility([
        ...optionVisibility,
        {
          text: "Team (visible to your team)",
          value: "team",
        },
      ]);
    }
  }, [userTeams]);

  return (
    <div className="mb-3">
      <Label>Access</Label>
      <DropSelect
        onChange={(val) => {
          formikprops.setFieldValue("project_access", val.val);
          if (val.val === "team") {
            formikprops.setFieldValue("team_id", userTeams[0].id);
          }
        }}
        name="project_access"
        placeholder="Access"
        labelField="text"
        valueField="value"
        searchBy="text"
        options={optionVisibility}
        formProps={formikprops}
        disabled={isDisabled}
      />
      <div className="caption small text-danger">
        {formikprops.touched['project_access'] && formikprops.errors['project_access']
          ? formikprops.errors['project_access']
          : ""}
      </div>
    </div>
  );
};

export default NewProjectAccess;

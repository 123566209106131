import {
  COMPANY_SEARCH_SUCCESS,
  SALESFORCE_COMPANY_SEARCH_SUCCESS,
  COMPANY_SEARCH_CLEAR,
  COMPANY_GETFILTERS_SUCCESS,
  COMPANY_GET_TOPICS_ATTEMPT,
  COMPANY_GET_TOPICS_SUCCESS,
  SET_FILTERS_ATTEMPT,
  COMPANY_SEARCH_CRUNCHBASE_SUCCESS,
  COMPANY_SEARCH_CRUNCHBASE_CLEAR,
  COMPANY_SEARCH_CRUNCHBASE_ATTEMPT,
  COMPANY_SEARCH_ATTEMPT,
  ADD_CRUNCH_COMPANY_TO_CURRENT_STATE,
  CLEAR_EXPORTED_COMPANIES,
  COMPANY_SEARCH_OWNERS_GET_SUCCESS,
  SET_ERROR_HANDLED_SUCCESS,
  SET_SCROLL_TOP,
  GET_ALL_COMPANIES_SUCCESS,
  SET_CHARTS_VIEW_TYPE,
  RESET_ALL_COMPANIES,
  SET_CHARTS_PAGE,
  SET_LOCAL_CHARTS_STATE,
  SET_CHARTS_ARE_RE_RENDERING,
  SET_CHARTS_REQ_IT_IS_IN_PROGRESS,
  UPDATE_BUBBLE_CHART_OPTIONS,
  SET_CHARTS_INTRO_ACTIVE,
  UPDATE_CHART_LIST_COMPANY_DETAILS,
  SET_CHARTS_SHOULD_RERENDER,
  SET_CHART_TYPE,
  UPDATE_COMPANY_RATING_VALUE,
  HANDLE_ERROR_SUCCESS,
  SET_SORT_TYPE_SUCCESS,
  SET_COMPANIES_TAGS_NOT_IN_COUNCIL,
  SET_SELECTED_COMPANIES,
  COMPANY_LIST_GET_SUCCESS,
  SAVE_SEARCH_COMPANY_LIST,
  GET_AGREEMENT_TYPES_ATTEMPT,
  GET_AGREEMENT_TYPES_SUCCESS,
  RESET_BEFORE_GETTING_BIG_DATA,
} from "./companyMain.action";
import { CRUNCHBASE_COMPANY_ATTEMPT } from "../crunchbase/crunchbase.action";
import {
  COMPANY_DELETE_SUCCESS,
  COMPANY_CLEAR_POPULATED_COMPETITOR_ATTEMPT,
  COMPANY_EDIT_DETAILS_ATTEMPT,
  COMPANY_GET_SUCCESS,
  COMPANY_EDIT_DETAILS_SUCCESS,
} from "../company/company.action";
import { apiStatus, pageMeta } from "../../../common/httpCall";
import { DEF_BUBBLE_OPTIONS } from "./chartsWrapper/chartsView/charts-constants";
import {
  ADD_PRODUCT_TO_IDEA_SUCCESS,
  ADD_PRODUCT_TO_LIST_SUCCESS,
  ADD_PRODUCT_TO_THEME_SUCCESS,
} from "modules/companies/product/store/product.actions";

const initState = {
  isScrolled: false,
  companyList: [],
  companyById: {},
  loading: true,
  searchResults: [],
  searchUsersResults: [],
  industries: [],
  topics: [],
  countries: [],
  checkedFilters: {
    disease_ids: [],
    country_ids: [],
  },
  crunchCompaniesSearched: [],
  crunchLoading: false,
  error: false,
  searchResultNr: 0,
  productsResultNr: null,
  exportedCompanies: [],
  relationshipOwners: null,
  errorHandled: false,
  charts: {
    selectedChartType: "pie",
    reqForAllCompaniesIsInProgress: false,
    allCompanies: null,
    viewType: "aggregate",
    page: 1,
    localState: null,
    chartsAreReRendering: false,
    chartOptions: DEF_BUBBLE_OPTIONS,
    chartsIntroIsActive: false,
    chartShouldRerender: false,
    chartTags: [],
  },
  sortInstance: {
    sortOrder: true,
    sortId: "",
  },
  addedCompetitor: {},
  shouldUpdateListItemsFromCompany: {
    type: "",
    shouldUpdate: false,
  },
  companiesTagsNotInCouncil: {
    topics: [],
    industries: [],
  },
  selectedCompanies: [],
  agreementTypes: [],
  loadingAgreementTypes: false,
};

const companyMainReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_PRODUCT_TO_THEME_SUCCESS:
    case ADD_PRODUCT_TO_IDEA_SUCCESS:
    case ADD_PRODUCT_TO_LIST_SUCCESS: {
      if (!state.companyList?.length && !state.searchResults?.length) {
        return state;
      }

      let sourceData;

      if (state.searchResults?.length) {
        sourceData = {
          prop: "searchResults",
          source: state.searchResults,
        };
      }

      if (state.companyList?.length) {
        sourceData = {
          prop: "companyList",
          source: state.companyList,
        };
      }

      const newCompanies = sourceData.source.map((c) => {
        if (c.id === payload.submittedPayload.council_product_id) {
          return {
            ...c,
            [payload.propToUpdateInCompanies]: [
              ...(c[payload.propToUpdateInCompanies] || []),
              payload.submittedPayload.id,
            ],
          };
        }

        return c;
      });

      return {
        ...state,
        [sourceData.prop]: newCompanies,
      };
    }

    case SET_SELECTED_COMPANIES: {
      if (payload.shouldReset) {
        return {
          ...state,
          selectedCompanies: [],
        };
      }

      return {
        ...state,
        selectedCompanies: payload?.companies || [],
      };
    }

    case COMPANY_GET_SUCCESS: {
      return {
        ...state,
        addedCompetitor: {},
      };
    }

    case SET_COMPANIES_TAGS_NOT_IN_COUNCIL: {
      return {
        ...state,
        companiesTagsNotInCouncil: action.payload,
      };
    }

    case COMPANY_EDIT_DETAILS_ATTEMPT: {
      const updatedCompanies = state.searchResults?.map((company) => {
        if (company.company_id === action.payload.companyId) {
          return {
            ...company,
            ...(action?.payload?.data || {}),
          };
        }

        return company;
      });

      return {
        ...state,
        searchResults: updatedCompanies,
      };
    }

    case UPDATE_COMPANY_RATING_VALUE: {
      const updatedCompanies = state.searchResults?.map((company) => {
        if (company.company_id === payload.companyId) {
          return {
            ...company,
            ...payload,
          };
        }

        return company;
      });

      return {
        ...state,
        searchResults: updatedCompanies,
      };
    }

    case COMPANY_DELETE_SUCCESS: {
      const filtered = state.companyList.filter(
        (company) => company.company_id !== payload.response?.company.id
      );
      return {
        ...state,
        companyList: filtered,
      };
    }

    case SET_CHART_TYPE: {
      const currentSelectedChart = state.charts.selectedChartType;

      if (currentSelectedChart === "pie") {
        const currentBreakDownValue = state.charts.chartOptions.color;
        const isCustomBreakdownValue =
          currentBreakDownValue === "totalFunding" ||
          currentBreakDownValue === "amountOfLastRound" ||
          currentBreakDownValue === "score" ||
          currentBreakDownValue === "numberOfEmployees" ||
          currentBreakDownValue === "yearFounded";

        if (isCustomBreakdownValue) {
          return {
            ...state,
            charts: {
              ...state.charts,
              selectedChartType: payload,
              chartOptions: {
                ...state.charts.chartOptions,
                color: DEF_BUBBLE_OPTIONS.color,
              },
            },
          };
        }
      }

      return {
        ...state,
        charts: {
          ...state.charts,
          selectedChartType: payload,
        },
      };
    }

    case SET_CHARTS_SHOULD_RERENDER: {
      return {
        ...state,
        charts: {
          ...state.charts,
          chartShouldRerender: payload,
        },
      };
    }

    case UPDATE_CHART_LIST_COMPANY_DETAILS: {
      if (!state.charts.allCompanies) return state;

      return {
        ...state,
        charts: {
          ...state.charts,
          allCompanies: state.charts.allCompanies.map((c) => {
            if (c.id === payload.companyId) {
              return {
                ...c,
                ...payload.newCompanyData,
              };
            }
            return c;
          }),
        },
      };
    }

    case SET_CHARTS_INTRO_ACTIVE: {
      return {
        ...state,
        charts: {
          ...state.charts,
          chartsIntroIsActive: payload,
        },
      };
    }

    case UPDATE_BUBBLE_CHART_OPTIONS: {
      return {
        ...state,
        charts: {
          ...state.charts,
          chartOptions: payload,
        },
      };
    }

    case SET_CHARTS_REQ_IT_IS_IN_PROGRESS: {
      return {
        ...state,
        charts: {
          ...state.charts,
          reqForAllCompaniesIsInProgress: payload,
        },
      };
    }

    case SET_CHARTS_ARE_RE_RENDERING: {
      return {
        ...state,
        charts: {
          ...state.charts,
          chartsAreReRendering: payload.state,
        },
      };
    }

    case SET_LOCAL_CHARTS_STATE: {
      return {
        ...state,
        charts: {
          ...state.charts,
          localState: payload.state,
        },
      };
    }

    case SET_CHARTS_PAGE: {
      return {
        ...state,
        charts: {
          ...state.charts,
          page: payload.page,
        },
      };
    }

    case RESET_ALL_COMPANIES: {
      return {
        ...state,
        charts: {
          ...state.charts,
          allCompanies: null,
        },
      };
    }

    case SET_CHARTS_VIEW_TYPE: {
      return {
        ...state,
        charts: {
          ...state.charts,
          viewType: payload.viewType,
        },
      };
    }

    case GET_ALL_COMPANIES_SUCCESS: {
      return {
        ...state,
        charts: {
          ...state.charts,
          allCompanies: payload.response,
          chartShouldRerender: false,
        },
      };
    }

    case COMPANY_SEARCH_ATTEMPT: {
      return {
        ...state,
        loading: true,
        addedCompetitor: {},
      };
    }

    case COMPANY_SEARCH_OWNERS_GET_SUCCESS: {
      return {
        ...state,
        relationshipOwners: [
          {
            full_name: "Any",
            user_id: "Any",
            first_name: "Any",
          },
          ...payload.response.map((u) => ({ ...u, ...u.user })),
        ],
      };
    }

    case CRUNCHBASE_COMPANY_ATTEMPT: {
      return {
        ...state,
        loading: true,
        addedCompetitor: {},
      };
    }

    case ADD_CRUNCH_COMPANY_TO_CURRENT_STATE: {
      return {
        ...state,
        companyList: [
          {
            added_by_name: payload.response.added_by.name,
            ...payload.response,
          },
          ...(state?.companyList || []),
        ],
        searchResults: [
          { ...payload.response },
          ...(state?.searchResults || []),
        ],
        addedCompetitor: payload.response,
        loading: false,
      };
    }

    case COMPANY_SEARCH_SUCCESS: {
      const { response, status, mode } = payload;
      if (status === apiStatus.SUCCESS && response) {
        if (mode === "councils/users") {
          return {
            ...state,
            searchResults: response,
            searchUsersResults: response,
            searchResultNr: payload?.xhr?.getResponseHeader("total-count") || 0,
            productsResultNr: payload?.xhr?.getResponseHeader("products-count") || null,
            searchResultPageNr:
              payload?.xhr?.getResponseHeader("total-pages") || 0,
            loading: false,
          };
        }

        return {
          ...state,
          searchResults: response,
          searchResultNr: payload.xhr.getResponseHeader("total-count"),
          productsResultNr: payload?.xhr?.getResponseHeader("products-count") || null,
          searchResultPageNr: payload.xhr.getResponseHeader("total-pages"),
          loading: false,
        };
      }

      return state;
    }

    case COMPANY_EDIT_DETAILS_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          searchResults: state.searchResults?.map((comp) => {
            if (response.id === comp.id) {
              return response;
            }
            return comp;
          }),
        };
      }
      break;
    }

    case SALESFORCE_COMPANY_SEARCH_SUCCESS: {
      const { response, status, mode } = payload;
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          searchResults: response.companies,
          totalRecords: response.total_count,
          searchResultNr: payload.xhr.getResponseHeader("companies-count"),
          productsResultNr: payload?.xhr.getResponseHeader("products-count") || null,
          searchResultPageNr: payload.xhr.getResponseHeader("total-pages"),
          loading: false,
        };
      }
    }

    case RESET_BEFORE_GETTING_BIG_DATA: {
      return {
        ...state,
        searchResults: null,
        searchResultNr: 0,
        productsResultNr: null,
        loading: true,
      };
    }

    case COMPANY_SEARCH_CLEAR: {
      return {
        ...state,
        searchResults: null,
        searchResultNr: 0,
        productsResultNr: null,
      };
    }

    case COMPANY_GETFILTERS_SUCCESS: {
      const { response, status } = payload;
      localStorage.setItem(
        "council_role",
        payload.xhr.getResponseHeader("user-council-role")
      );
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          industries: response,
          addedCompetitor: {},
        };
      }

      return state;
    }

    case COMPANY_GET_TOPICS_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case COMPANY_GET_TOPICS_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          // loading: false,
          topics: response.sort((a, b) =>
            a.name < b.name ? -1 : a.name > b.name ? 1 : 0
          ),
          addedCompetitor: {},
        };
      }

      return state;
    }

    case SET_FILTERS_ATTEMPT: {
      return {
        ...state,
        checkedFilters: {
          ...state.checkedFilters,
          ...payload,
        },
      };
    }

    case COMPANY_SEARCH_CRUNCHBASE_ATTEMPT: {
      return {
        ...state,
        crunchLoading: true,
      };
    }

    case COMPANY_SEARCH_CRUNCHBASE_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          crunchCompaniesSearched: response,
          crunchLoading: false,
        };
      }

      return state;
    }

    case COMPANY_SEARCH_CRUNCHBASE_CLEAR: {
      return {
        ...state,
        crunchCompaniesSearched: null,
      };
    }

    case CLEAR_EXPORTED_COMPANIES: {
      return {
        ...state,
        exportedCompanies: [],
      };
    }

    case SET_ERROR_HANDLED_SUCCESS: {
      return {
        ...state,
        errorHandled: true,
      };
    }

    case SET_SCROLL_TOP: {
      return {
        ...state,
        isScrolled: payload,
      };
    }

    case HANDLE_ERROR_SUCCESS: {
      return {
        ...state,
        loading: false,
        crunchLoading: false,
      };
    }

    case COMPANY_CLEAR_POPULATED_COMPETITOR_ATTEMPT: {
      return {
        ...state,
        addedCompetitor: {},
      };
    }

    case SET_SORT_TYPE_SUCCESS: {
      const { typeName, reversedSort } = payload;

      return {
        ...state,
        sortInstance: {
          sortOrder: reversedSort,
          sortId: typeName,
        },
      };
    }

    case COMPANY_LIST_GET_SUCCESS: {
      const { response, status, company } = payload;
      if (status === apiStatus.SUCCESS && response) {
        let mutatedRes;
        const companyExists = !company
          ? false
          : response.some((c) => c.company_id === company.company_id);

        if (companyExists) {
          mutatedRes = [
            { ...company },
            ...response.filter((c) => c.company_id !== company.company_id),
          ];
        } else {
          mutatedRes = company ? [{ ...company }, ...response] : response;
        }

        const objById = {};

        mutatedRes.forEach((element) => {
          objById[element.id] = element;
        });

        return {
          ...state,
          companyList: mutatedRes,
          companyById: { ...state.companyById, ...objById },
          loading: false,
          companiesPageNr: pageMeta(payload),
        };
      }

      return state;
    }

    case SAVE_SEARCH_COMPANY_LIST: {
      const { response } = payload;
      return {
        ...state,
        companyList: response,
        loading: false,
      };
    }
    case GET_AGREEMENT_TYPES_ATTEMPT: {
      return {
        ...state,
        loadingAgreementTypes: true,
      };
    }

    case GET_AGREEMENT_TYPES_SUCCESS: {
      return {
        ...state,
        loadingAgreementTypes: false,
        agreementTypes: payload.response,
      };
    }
  }

  return state;
};

export default companyMainReducer;

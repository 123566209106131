import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";

import styles from "./multiUsers.module.scss";
import { UserInline, UserInlineTypes } from "..";

const MultiUsersComponent = (props) => {
  const {
    users = [], total, maxCount = 5, dontShowRemaining, disabled,
  } = props;
  const [remaining, setRemaining] = useState(null);

  useEffect(() => {
    const { length } = users;
    setRemaining(length > maxCount ? length - maxCount : null);
  }, [total, maxCount, users.length]);

  return (
    <div className={styles.wrapper}>
      {users
        .filter((user, index) => index < maxCount)
        .map((user, index) => (
          <Tooltip
            key={`MultiUsersComponent-${index}`}
            title={!disabled ? `${
              user?.user_full_name
                ? user.user_full_name
                : `${user?.first_name} ${user?.last_name}`
            }` : null}
          >
            <div
              className={styles.assingUser}
              style={{
                left: `${index * 15}px`,
                zIndex: `${maxCount - index}`,
              }}
            >
              {user && (
                <UserInline
                  local={!!user.first_name || user.user_full_name}
                  first={user.first_name || user.user_full_name}
                  last={user.last_name}
                  type={UserInlineTypes.IMAGE_ONLY}
                  className={styles.userImg}
                  userId={user.user_id || user.event_attendee_id || user.id}
                  avatar={user.avatar || user.user_avatar}
                  noNavigation={disabled}
                />
              )}
            </div>
          </Tooltip>
        ))}
      {dontShowRemaining && remaining && (
        <span className={styles.count}>{`+${remaining}`}</span>
      )}
      {!dontShowRemaining && remaining && !disabled && (
        <Tooltip
          interactive
          title={users
            .filter((user, index) => index > maxCount - 1)
            .map((user) => (
              <p>
                {user.user_full_name
                  || `${user?.first_name} ${user?.last_name}`}
              </p>
            ))}
          classes={{ popper: styles.customWidthTooltip }}
        >
          <span className={styles.count}>{`+${remaining}`}</span>
        </Tooltip>
      )}
    </div>
  );
};

export default React.memo(MultiUsersComponent);

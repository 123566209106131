import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import classnames from "classnames";
import closeIcon from "assets/icn-close.svg";
import { getCookie } from "common/helper";

import { Button, BtnType } from "../../../primitives";
import { useModelPopup } from "../../../../common/hooks";
import { ModalFooter, ModalBody } from "../../../../application/modal";

import {
  themesHomepageGetAttempt,
  themesHomepageRemoveAttempt,
} from "../themesHomePage.action";
import ThemeCard from "../../themesMainPage/themMainCard/index";
import Loader from "./Loader";
import styles from "../styles.module.scss";
import NewHomepageThemeComponent from "../newHomepageThemeModal";

const AddThemeComponent = ({
  handleAddHomePageClick,
  selectedCouncil,
  sessionRole,
}) => {
  if (
    selectedCouncil.traction_tag === "ford" &&
    sessionRole.indexOf("admin") < 0
  ) {
    return <div className={styles.addThemeBlock}></div>;
  }
  return (
    <div className={styles.addThemeBlock}>
      <Button
        icon="icn-add"
        btn={BtnType.FRAME_LESS}
        className={styles.editThemeButton}
        onClick={handleAddHomePageClick}
      >
        Add {selectedCouncil?.settings?.themes_display_name_singular || "Theme"}
      </Button>
    </div>
  );
};

const ThemeHomePageBlock = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    themesReducer: {
      themesHomepageReducer: { themesHomepageList, loading },
    },
  } = useSelector((state) => state);
  const [activeIdx, setActiveIdx] = useState(0);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const cardContRef = useRef();
  const [scrollLeft, setScrollLeft] = useState(0);
  const clW = cardContRef.current?.clientWidth;
  const cardWidth = selectedCouncil.traction_tag === "ford" ? 440 * 3 : 440;
  const council_role = getCookie("council_role_");

  let os = navigator.userAgent;
  let finalOs = "";
  if (os.search("Windows") !== -1) {
    finalOs = "Windows";
  } else if (os.search("Mac") !== -1) {
    finalOs = "MacOS";
  } else if (os.search("X11") !== -1 && !(os.search("Linux") !== -1)) {
    finalOs = "UNIX";
  } else if (os.search("Linux") !== -1 && os.search("X11") !== -1) {
    finalOs = "Linux";
  }

  if (cardContRef.current) {
    cardContRef.current.style.paddingRight = clW - cardWidth * 2 + "px";
    if (finalOs === "Windows") {
      cardContRef.current.style.overflow = "hidden";
    }
  }
  useEffect(() => {
    if ((selectedCouncil, session)) {
      dispatch(
        themesHomepageGetAttempt({ enqueueSnackbar, userId: session?.id })
      );
    }
  }, [selectedCouncil, session]);

  const handleCardClick = (toLeft, selectedCard) => {
    const scW = cardContRef.current.scrollWidth;
    const scL = Number(cardContRef.current.scrollLeft.toString());
    // const cardWidth = ((clW / 100) * 31).toFixed();
    setScrollLeft(scL);
    setShowLeftButton(true);

    if (selectedCard) {
      cardContRef.current.scrollLeft = cardWidth * selectedCard - cardWidth;
      return;
    }

    if (scW - scL - clW > cardWidth) {
      if (toLeft) {
        cardContRef.current.scrollLeft += cardWidth;
      } else {
        cardContRef.current.scrollLeft -= cardWidth;
      }
    } else {
      cardContRef.current.scrollLeft = toLeft
        ? scL + cardWidth
        : scL - cardWidth;
    }
    if (scW - clW === scrollLeft) {
      setActiveIdx(themesHomepageList.length - 1);
    }
  };

  const handleDotClick = (idx) => {
    setActiveIdx(idx);
    handleCardClick(false, idx + 1);
  };

  const handleAddHomePageClick = (e) => {
    popup.show({
      title: `Add ${
        selectedCouncil?.settings?.themes_display_name_singular || "Theme"
      } to Homepage`,
      component: <NewHomepageThemeComponent />,
    });
  };

  const handleDelete = (themeId, defaultCouncilTheme) => {
    if (defaultCouncilTheme) {
      popup.show({
        title: "",
        component: (
          <>
            <ModalBody>
              {`Removing this ${
                selectedCouncil?.settings?.themes_display_name_singular ||
                "Theme"
              } will remove it for all members. Do you want to
                proceed?`}
            </ModalBody>
            <ModalFooter>
              <Button
                btn={BtnType.FRAME_LESS}
                onClick={() => {
                  popup.hide();
                }}
              >
                Cancel
              </Button>
              <Button
                btn={BtnType.REGULAR}
                onClick={() => {
                  dispatch(themesHomepageRemoveAttempt({ themeId }));

                  popup.hide();
                }}
              >
                DELETE
              </Button>
            </ModalFooter>
          </>
        ),
      });

      return true;
    } else {
      dispatch(themesHomepageRemoveAttempt({ themeId }));
    }
  };

  useEffect(() => {
    if (scrollLeft > 1) {
      setShowLeftButton(true);
    }
    if (scrollLeft <= 1) {
      setShowLeftButton(false);
    }
    if (scrollLeft >= 40) {
      const activeDot = Number((scrollLeft / cardWidth).toFixed());

      setActiveIdx(activeDot);
    } else {
      setActiveIdx(0);
    }
  }, [scrollLeft]);
  const showDeleteIcon = (theme) => {
    const component = (
      <div
        className={styles.delete}
        onClick={(e) => {
          handleDelete(theme.id, theme.default);
        }}
        // className={styles.iconDelete}
      >
        <img alt={"close icon"} src={closeIcon} />
      </div>
    );
    if (
      selectedCouncil.traction_tag === "ford" &&
      theme.default &&
      session.council_role.indexOf("admin") < 0
    ) {
      return null;
    }

    return component;
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.themesHomepageContainer}>
      <div
        className={styles.cardContainer}
        ref={cardContRef}
        onScroll={(e) => {
          setScrollLeft(cardContRef.current.scrollLeft);
        }}
      >
        {showLeftButton && (
          <button
            className={classnames(
              styles.carousel__btn,
              styles.carousel__btn_prev
            )}
            onClick={() => handleCardClick()}
          >
            <i
              className={classnames(
                styles.carousel__btn_arrow,
                styles.carousel__btn_arrow_left
              )}
            />
          </button>
        )}
        {themesHomepageList.map((theme, i) => (
          <div className={styles.card} key={theme.id + i}>
            <ThemeCard cardData={theme} fromHome={true} />
            {showDeleteIcon(theme)}
          </div>
        ))}
        <AddThemeComponent
          handleAddHomePageClick={handleAddHomePageClick}
          selectedCouncil={selectedCouncil}
          sessionRole={council_role}
        />
        {themesHomepageList.length < 2 ? (
          <>
            <AddThemeComponent
              handleAddHomePageClick={handleAddHomePageClick}
              selectedCouncil={selectedCouncil}
              sessionRole={council_role}
            />
            <AddThemeComponent
              handleAddHomePageClick={handleAddHomePageClick}
              selectedCouncil={selectedCouncil}
              sessionRole={council_role}
            />
          </>
        ) : (
          <button
            className={classnames(
              styles.carousel__btn,
              styles.carousel__btn_next
            )}
            onClick={() => handleCardClick(true)}
          >
            <i
              className={classnames(
                styles.carousel__btn_arrow,
                styles.carousel__btn_arrow_right
              )}
            />
          </button>
        )}

        <div className={styles.carousel__dots}>
          {themesHomepageList.map((pos, i) => (
            <button
              key={i}
              onClick={() => handleDotClick(i)}
              className={i === activeIdx ? styles.dot_active : styles.dot}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ThemeHomePageBlock);

import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import Select from "react-dropdown-select";

import styles from "../../../../../../../scss/multiselect/multiselect.module.scss";
import { httpGet } from "../../../../../../../common/httpCall";

const LocationSelectSearch = (props) => {
  const {
    className,
    name,
    onChange,
    selectedLocation = [],
    disabled,
    ...rest
  } = props;

  const ref = useRef();

  const [query, setQuery] = useState(null);
  const [results, setResults] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const input = ref.current.querySelector("input");

      if (input) {
        input.addEventListener("keyup", handleSearch);
      }
    }

    return () => null;
  }, [ref]);

  useEffect(() => {
    if (selectedLocation?.length) {
      setQuery(selectedLocation[0].name);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (query) {
      requestNewData(query);
    }
  }, [query]);

  const handleSearch = (evt) => {
    setQuery(evt.target.value);
  };

  const requestNewData = (query) => {
    setShowLoading(true);

    const url = `companies/locations?query=${query}`;

    httpGet({
      call: url,
    })
      .pipe()
      .subscribe((res) => {
        setResults(res.response);
        setShowLoading(false);
      });
  };

  const dropdownRenderer = useCallback(
    ({ props, state, methods }) => {
      if (showLoading) {
        return (
          <div className="d-flex flex-column react-dropdown-select-dropdown">
            <span className="p-3">Loading locations...</span>
          </div>
        );
      }

      return (
        <div className="d-flex flex-column react-dropdown-select-dropdown">
          {props.options.length ? (
            props.options.map((item, idx) => {
              const isSelected = state.values.some(
                (val) => val.name === item.name,
              );

              return (
                <div
                  onClick={() => (isSelected ? null : methods.addItem(item))}
                  key={`${item.name}-${idx}`}
                  className={`react-dropdown-select-item d-flex flex-column ${
                    isSelected
                      ? "react-dropdown-select-item-selected selected-location"
                      : ""
                  }`}
                >
                  <span
                    className="location-main"
                    aria-label={item[props.labelField]}
                  >
                    {item[props.labelField]}
                  </span>
                  <span className="location-secondary">
                    Location • {item.description}
                  </span>
                </div>
              );
            })
          ) : !query || !query.length ? (
            <span className="p-3">Search a location</span>
          ) : (
            <span className="p-3">No data</span>
          )}
        </div>
      );
    },
    [results, query, showLoading],
  );

  const onNewSelection = (value) => {
    if (!value.length) {
      onChange([], true);
      setQuery(null);
      setResults([]);
      return;
    }

    onChange(value);
  };

  const handleDropOpen = useMemo(() => {
    if (!selectedLocation.length) {
      return;
    }

    const elementToGo = document.querySelector(
      `#locationSearch .react-dropdown-select-dropdown [aria-label="${selectedLocation[0].name}"]`,
    );

    if (elementToGo) {
      elementToGo.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedLocation]);

  return (
    <div className={styles.multiWrp}>
      <div className={styles.container} ref={ref} id="locationSearch">
        <Select
          closeOnSelect
          placeholder="Search a location"
          values={selectedLocation}
          className={styles.dorpWrp}
          options={results}
          labelField="name"
          valueField="name"
          onChange={onNewSelection}
          dropdownRenderer={(innerProps, innerState, innerMethods) => dropdownRenderer(innerProps, innerState, innerMethods)}
          dropdownPosition="auto"
          clearable
          onDropdownOpen={handleDropOpen}
          onClearAll={() => onChange([])}
          disabled={disabled}
          {...rest}
        />
      </div>
    </div>
  );
};

export default React.memo(LocationSelectSearch);

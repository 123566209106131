export const tableHeadersByKey = {
  'projects_by_innovation_category': 'Innovation Category',
  'projects_by_stage': 'Projects/Stage',
  'projects_by_next_stage_change': 'Next Stage Change Quarter',
  'projects_by_plant': 'Projects/Plant',
  'total_projects': 'Total Projects',
  'avg_tar': 'AVG TARR',
  'investments': 'Investment',
  'replications_by_plant': 'Replications/Plant',
  'savings': 'Savings',
  'total_replications': 'Relications'
}

import React, { useCallback } from "react";
import Select from "react-dropdown-select";

import { DatePiker, Label, TextBox } from "modules/primitives";
import styles from "modules/projects/project/manageProject/details/details.module.scss";
import { makeStages } from "../../../helper";
import { METRICS_NAMES_ENUM } from "application/app.constants";

const ProjectMetrics = ({ formikprops, fieldDetails, disabled, isAdminWizardInstance }) => {
  const renderMetricField = useCallback(() => {
    if (!fieldDetails) return null;

    const newValues = {
      ...formikprops.values,
      project_metrics: {
        ...(formikprops.values?.project_metrics || {}),
        [fieldDetails.metric_attribute]: formikprops.values?.project_metrics?.[fieldDetails.metric_attribute] || ''
      },
    };

    switch (fieldDetails.metric_attribute) {
      case 'tarr_percentage':
      case 'prototype_investment_usd':
      case 'savings_usd':
      case 'investment_usd':
      case 'annual_incremental_investment_usd':
      case 'current_spending_usd':
      case 'headcount_op_crew':
      case 'threed_printing_replications':
        return (
          <div className="flex-grow-1">
            <Label>{METRICS_NAMES_ENUM[fieldDetails.metric_attribute]} {isAdminWizardInstance && <div className="small text-muted ml-2">(Project Metric)</div>}</Label>
            <TextBox
              type="number"
              placeholder={METRICS_NAMES_ENUM[fieldDetails.metric_attribute]}
              className="w-100"
              name={fieldDetails.metric_attribute}
              value={newValues.project_metrics[fieldDetails.metric_attribute]}
              onChange={(evt) => {
                const updatedFields = {
                  ...newValues.project_metrics,
                  [fieldDetails.metric_attribute]: evt.target.value,
                };
                formikprops.setFieldValue('project_metrics', updatedFields);
              }}
              formProps={{
                ...formikprops,
                values: newValues,
              }}
              min={0}
              disabled={disabled}
              withClear={!isAdminWizardInstance}
            />
          </div>
        )

      case "job_1_date":
      case "expected_job_1_date":
      case "implementation_date":
      case "next_stage_start_on":
      case "prototype_job_1_date":
      case "proposal_for_prototype_expected_job_1_date":
      case "proposal_for_implementation_date":
      case "ready_for_replication_date":
      case "prototype_date":
        return (
          <div className="flex-grow-1">
            <Label>{METRICS_NAMES_ENUM[fieldDetails.metric_attribute]} {isAdminWizardInstance && <div className="small text-muted ml-2">(Project Metric)</div>}</Label>
            <DatePiker
              value={newValues.project_metrics[fieldDetails.metric_attribute]}
              name={fieldDetails.metric_attribute}
              formProps={{
                ...formikprops,
                values: newValues,
              }}
              onChange={(e) => {
                const updatedFields = {
                  ...newValues.project_metrics,
                  [fieldDetails.metric_attribute]: e
                };
                formikprops.setFieldValue('project_metrics', updatedFields);
              }}
              className={styles.textBox}
              disabled={disabled}
              withClear={!isAdminWizardInstance}
            />
          </div>
        )

      case 'next_stage_end_on':
        const stages = makeStages(new Date().getFullYear());
        const value = formikprops.values?.project_metrics?.next_stage_end_on
          ? stages.filter(s => s.id === formikprops.values.project_metrics.next_stage_end_on)
          : []

        return (
          <div className="flex-grow-1">
            <Label>Next Stage Change {isAdminWizardInstance &&
              <div className="small text-muted ml-2">(Project Metric)</div>}</Label>
            <Select
              label="Next Stage Change"
              placeholder="Next Stage Change"
              labelField="name"
              valueField="id"
              name="next_stage_end_on"
              multi={false}
              options={stages}
              onChange={(val) => {
                const updatedFields = {
                  ...newValues.project_metrics,
                  ['next_stage_end_on']: val[0]?.date || null
                };
                formikprops.setFieldValue('project_metrics', updatedFields);
              }}
              values={value || []}
              disabled={disabled}
              clearable={!isAdminWizardInstance}
            />
          </div>
        )

      default:
        return null;
    }
  }, [fieldDetails, isAdminWizardInstance, formikprops, disabled]);

  return (
    <div className="mb-3">
      {renderMetricField()}
    </div>
  );
};

export default React.memo(ProjectMetrics);

import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { convertDateTimezone } from "common/helper";
import styles from "./styles.module.scss";
import {
  CheckBox,
  Label,
  MoreOptions,
  UserInline,
  UserInlineTypes,
} from "../../../../primitives";
import { moreOptions } from "./constant";
import { Icon, lgSize } from "../../../../../common/icon";
import { parceVisibility } from "../activity.constant";
import AccessToggle from "../../../../../common/components/customFields/accessToggle";

const CallComponent = (props) => {
  const {
    call,
    teamList,
    handleEditClick,
    handleDeleteClick,
    orgType,
    hasAccess,
    blockFunctionality = false,
    isAddedByMe,
    isLastElem,
    isMobile,
  } = props;

  const handleMoreOptionClick = (props) => {
    if (props === 5) {
      handleEditClick(call);
    }
    if (props === 6) {
      handleDeleteClick(call);
    }
  };

  return (
    <div className={`${styles.wrapper} ${isLastElem ? styles.noBorder : ""}`}>
      <div className={styles.container}>
        <div className={styles.titleRow}>
          {isMobile ? null : (
            <div className={styles.iconWrp}>
              <Icon {...lgSize} icon="icn-call" className={styles.icon} />
            </div>
          )}
          <h5 className={styles.title}>
            {isMobile ? (
              <Icon {...lgSize} icon="icn-call" className={styles.icon} />
            ) : null}
            Call
          </h5>
          <div className={styles.rightWrp}>
            {isAddedByMe ? (
              <div className={styles.toggle}>
                <AccessToggle item={call} orgType={orgType} />
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <span className={styles.date}>
                  {call?.updated_at
                    ? format(new Date(call.updated_at), "EEEE, MMMM dd, yyyy ")
                    : null}
                </span>
                <div className={styles.colMore}>
                  {hasAccess && (
                    <MoreOptions
                      options={moreOptions}
                      className={styles.moreOptWrp}
                      onClick={handleMoreOptionClick}
                      customSvgIcon
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-4">
          <div className="d-flex flex-column">
            {call?.spoke_with && (
              <div className={styles.name}>{call.spoke_with}</div>
            )}
            {call?.date ? (
              <div className={styles.date}>
                {format(
                  convertDateTimezone(call?.date),
                  "EEEE, MMMM dd, yyyy "
                )}{" "}
              </div>
            ) : null}
          </div>
          {isAddedByMe && (
            <div className="d-flex align-items-center">
              <span className={styles.date}>
                {call?.updated_at
                  ? format(new Date(call.updated_at), "EEEE, MMMM dd, yyyy ")
                  : null}
              </span>
              <div className={styles.colMore}>
                {hasAccess && (
                  <MoreOptions
                    options={moreOptions}
                    className={styles.moreOptWrp}
                    onClick={handleMoreOptionClick}
                    customSvgIcon
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className={classNames(styles.detail, styles.note)}
          dangerouslySetInnerHTML={{ __html: call?.spoke_about || "" }}
        />
        <div className={styles.attendeesWrp}>
          {call.attendees.length !== 0 && (
            <>
              <div className={styles.label}>Spoke with: </div>
              <div className={styles.listWrp}>
                {call.attendees.map((attendee) => (
                  <div key={attendee.user_id} className={styles.itemWrp}>
                    <UserInline
                      className={styles.userIcon}
                      classObj={{ name: styles.userName }}
                      userId={attendee.user_id}
                      type={UserInlineTypes.WITH_NAME}
                      showMine={false}
                      first={attendee.first_name}
                      last={attendee.last_name}
                      avatar={attendee.avatar}
                      local
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className={styles.infoWrp}>
          <div className="w-50 d-flex">
            {call?.projects && call?.projects[0] && (
              <div className={styles.dataRow}>
                <div className={styles.label}>Project</div>
                {call?.projects.map((project) => (
                  <Link key={project.id} to={`/projects/${project.id}`}>
                    <div className={styles.value}>{project.name}</div>
                  </Link>
                ))}
              </div>
            )}
            <div className={styles.dataRow}>
              <div className={styles.label}>
                {call.updated_at !== call.created_at
                  ? "Updated by"
                  : "Created by"}
              </div>
              <div className={styles.value}>
                <Link to={`/people/internal/${call.creator_id}`}>
                  {call && call.created_by}
                </Link>
              </div>
            </div>
            {call.visibility && (
              <div className={styles.dataRow}>
                <div className={styles.label}>Visible </div>
                <div className={styles.value}>
                  {call &&
                    parceVisibility(call.visibility, teamList, call.team_ids)}
                </div>
              </div>
            )}
          </div>
          <div className="w-50">
            {orgType === "patient" ? (
              <div className={styles.centerWrp}>
                <div className={classNames(styles.infoWrpColumn, "mb-3")}>
                  <div className={styles.label}>Discussion Topic:</div>
                  <div className={styles.detail}>
                    {call && call.discussion_topics
                      ? call.discussion_topics.map((d) => `${d}, `)
                      : "-"}
                  </div>
                </div>
                <div className={classNames(styles.infoWrpColumn, "mb-3")}>
                  <div className={styles.label}>
                    {orgType === "patient"
                      ? "Role of Patient Organization Representative(s)"
                      : "Roles Of Key Attendees"}
                    :
                  </div>
                  <div className={styles.detail}>
                    {call && call.roles_of_attendees}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Label>
                    {orgType === "patient"
                      ? "Initial engagement"
                      : "Initial introductory call"}
                    :{" "}
                  </Label>
                  <CheckBox
                    className="ml-2"
                    isChecked={call && call.initial_introductory_meeting}
                    disabled={blockFunctionality}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CallComponent;

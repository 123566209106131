import { combineEpics, ofType } from "redux-observable";
import { map, switchMap, catchError } from "rxjs/operators";
import { httpPost, errorHandler } from "../../common/httpCall";
import { MEMBER_SEARCH_ATTEMPT, memberSearchSuccess } from "./members.action";

export const epicSearchMembers = (action$, state$) => action$.pipe(
  ofType(MEMBER_SEARCH_ATTEMPT),
  switchMap(
    ({
      payload: {
        data, councilId, topic_ids, industry_ids, enqueueSnackbar,
      },
    }) => httpPost({
      call: `councils/users/search?council_id=${councilId}`,
      data: {
        query: data,
        search: {
          query: data,
          topic_ids,
          industry_ids,
          therapeutic_area_ids: [],
        },
      },
    }).pipe(
      map((result) => memberSearchSuccess(result)),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

const membersEpic = combineEpics(epicSearchMembers);

export default membersEpic;

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, useFormikContext } from "formik";
import classnames from "classnames";
import Select from "react-dropdown-select";
import { useSnackbar } from "notistack";

import {
  DropSelect,
  Label,
  Loading,
  TextBox,
} from "../../../../../modules/primitives";
import styles from "./styles.module.scss";
import { make4NextQuartersFromToday } from "../../../../helper";
import ReplicationProjectThemes from "modules/themes/replicationProjectThemes";
import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import PlantMultiSelect from "../../../plantMultiselect";
import PlantSingleSelect from "../../../plantSingleselect";
import { projectListGet } from "modules/projects/project/project.action";

const stages = make4NextQuartersFromToday();

const SectionStepFields = ({
  step,
  setNextStage,
  selectedCouncil,
  isFirstStep,
  nextStage,
}) => {
  const [projects, setProjects] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const formikProps = useFormikContext();
  const {
    authReducer: { session },
    adminPlantsAndAreasReducer: { plantsAndAreas },
    ideaReducer: { ideasSections, ideasSectionsLoading },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil?.id && selectedCouncil?.name === "Ford") {
      dispatch(
        projectListGet({
          councilId: selectedCouncil.id,
          pageSize: 9999,
          currentPage: 1,
          userId: session.id,
          enqueueSnackbar,
          items: 9999,
          isDropdown: true,
          original: true,
          cb: (res) => {
            setProjects(res);
          },
        })
      );
    }
  }, [selectedCouncil]);

  useEffect(() => {
    if (!plantsAndAreas.length && selectedCouncil?.name === "Ford") {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        })
      );
    }
  }, [plantsAndAreas]);

  const getPlantsAsOptions = useCallback(
    (filterBy) => {
      const makePlants = plantsAndAreas.map((plant) => ({
        id: plant.id,
        name: plant.attributes.name,
        areas: plant.attributes.areas,
      }));
      const { values } = formikProps;
      const existingValue = values[filterBy];

      if (filterBy === "selectedPlant" && existingValue?.id) {
        return makePlants.filter((plant) => existingValue.id !== plant.id);
      }

      if (filterBy === "selectedPlant1" && existingValue?.length) {
        return makePlants.filter(
          (plant) => !existingValue.some((p) => p.id === plant.id)
        );
      }

      return makePlants;
    },
    [plantsAndAreas, formikProps.values]
  );

  const renderTextInput = ({
    id,
    label,
    type = "text",
    promptText = "",
    placeholder = "",
    required = false,
  }) => {
    const element = (
      <div key={`field-${id}`}>
        <Label>{label}</Label>
        <small className="text-muted">{promptText}</small>
        <TextBox
          type={type}
          name={id}
          placeholder={placeholder}
          formProps={formikProps}
          className="w-100"
          required={required}
        />
      </div>
    );

    if (label === "Idea Description" && selectedCouncil?.name === "Ford") {
      const filterTopPlants = getPlantsAsOptions("selectedPlant1");

      return (
        <>
          {element}
          <div className="mb-3">
            <PlantSingleSelect
              formikprops={formikProps}
              plants={filterTopPlants}
            />
          </div>
        </>
      );
    }

    return element;
  };

  const renderCheckboxInput = ({
    id,
    label,
    allowMultiselect,
    promptText = "",
    options = [],
  }) => {
    const { setFieldValue, errors, touched, values } = formikProps;

    const isInvalid = errors[id] && touched[id];

    const handleChange = (e) => {
      if (allowMultiselect) {
        if (!values[id].includes(e.target.value) && e.target.checked) {
          setFieldValue(id, [...values[id], e.target.value]);
        } else {
          setFieldValue(
            id,
            values[id].filter((option) => option !== e.target.value)
          );
        }
      } else {
        setFieldValue(id, [e.target.value]);
      }
    };

    return (
      <div className={classnames(styles.formGroup)} key={`field-${id}`}>
        <Label>{label}</Label>
        <small className="text-muted mb-2">{promptText}</small>
        {options
          ? options.map((option, index) => (
              <div className={styles.formCheck} key={`field-${id}-${option}`}>
                <label
                  className={styles.formCheckLabel}
                  htmlFor={`${id}-${index}`}
                >
                  <Field
                    id={`${id}-${index}`}
                    name={id}
                    type={allowMultiselect ? "checkbox" : "radio"}
                    className={styles.formCheckInput}
                    value={option}
                    checked={values[id]?.includes(option)}
                    onChange={handleChange}
                  />
                  {option}
                </label>
              </div>
            ))
          : null}
        {isInvalid && (
          <div className={styles.invalidFeedback}>{errors[id]}</div>
        )}
      </div>
    );
  };

  const renderSelectInput = ({
    id,
    label,
    allowMultiselect,
    promptText = "",
    options = [],
  }) => {
    const { setFieldValue, errors, touched, values } = formikProps;

    const isInvalid = errors[id] && touched[id];

    const formattedOptions = options.map((option) => ({
      label: option,
      value: option,
    }));

    const handleChange = (selectedValues) => {
      const value = selectedValues.map(({ value }) => value);
      const finalValue = allowMultiselect ? value : value[0];
      setFieldValue(id, finalValue);
    };

    if (
      label === "Possible Replications (Plants)" &&
      selectedCouncil?.name === "Ford"
    ) {
      const filterBottomPlants = getPlantsAsOptions("selectedPlant");

      return (
        <div className={styles.formGroup} key={`field-${id}`}>
          <div className="mb-3">
            <PlantMultiSelect
              formikprops={formikProps}
              plants={filterBottomPlants}
            />
          </div>
          {isInvalid && (
            <div className={styles.invalidFeedback}>{errors[id]}</div>
          )}
        </div>
      );
    }

    if (label === "Innovation Pillar") {
      return (
        <div className={styles.formGroup} key={`field-${id}`}>
          <ReplicationProjectThemes
            name="Innovation Pillar"
            handleSelect={(themes) => setFieldValue("themes", themes)}
            themes={values.themes || []}
            clearAll={() => setFieldValue("themes", [])}
          />
          {isInvalid && (
            <div className={styles.invalidFeedback}>{errors[id]}</div>
          )}
        </div>
      );
    }

    if (label === "Is this a Replication?") {
      const isInvalidProj = errors["project_id"];

      const handleChangeThis = (selectedValues) => {
        const value = selectedValues.map(({ value }) => value);
        const finalValue = allowMultiselect ? value : value[0];
        setFieldValue(id, finalValue || []);
        if (finalValue === "No") {
          setFieldValue("project_id", null);
        }
      };

      return (
        <div className={styles.formGroup} key={`field-${id}`}>
          <Label>{label}</Label>
          <small className="text-muted mb-2">{promptText}</small>
          <Select
            name={id}
            multi={allowMultiselect}
            options={formattedOptions}
            onChange={handleChangeThis}
            values={[]}
            style={{
              border: "1px solid #e4e7eb",
              outline: "none",
              boxShadow: "none",
            }}
            clearable={!Array.isArray(formikProps.values[id])}
            clearRenderer={({ props, state, methods }) => (
              <div
                className="d-flex justify-content-end"
                style={{
                  position: "absolute",
                  top: 35,
                  right: 0,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  methods.clearAll();
                  setFieldValue(id, []);
                }}
              >
                <span className="text-muted small cursor-pointer">clear</span>
              </div>
            )}
          />

          {isInvalid && (
            <div className={styles.invalidFeedback}>{errors[id]}</div>
          )}
          {values[id] === "Yes" && (
            <div className="mt-3">
              <Label>Select Original Project</Label>
              <DropSelect
                SelecedValues={
                  formikProps.values.project_id === undefined && "clear"
                }
                onChange={(value) => {
                  setFieldValue("project_id", value.val);
                }}
                placeholder="Select Original Project"
                labelField="name"
                valueField="id"
                searchBy="name"
                options={projects || []}
              />
              {formikProps.values.project_id ? (
                <div className="d-flex justify-content-end">
                  <span
                    className="text-muted small cursor-pointer"
                    onClick={() => setFieldValue("project_id", undefined)}
                  >
                    clear
                  </span>
                </div>
              ) : null}
              {isInvalidProj && (
                <div className={styles.invalidFeedback}>
                  {errors["project_id"]}
                </div>
              )}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={styles.formGroup} key={`field-${id}`}>
        <Label>{label}</Label>
        <small className="text-muted mb-2">{promptText}</small>
        <Select
          name={id}
          multi={allowMultiselect}
          options={formattedOptions}
          onChange={handleChange}
          values={[]}
          style={{
            border: "1px solid #e4e7eb",
            outline: "none",
            boxShadow: "none",
          }}
          clearable={formikProps?.values[id].length ? true : false}
          clearRenderer={({ props, state, methods }) => (
            <div
              className="d-flex justify-content-end"
              style={{
                position: "absolute",
                top: 35,
                right: 0,
              }}
              onClick={(e) => {
                e.stopPropagation();
                methods.clearAll();
                setFieldValue(id, []);
              }}
            >
              <span className="text-muted small cursor-pointer">clear</span>
            </div>
          )}
        />

        {isInvalid && (
          <div className={styles.invalidFeedback}>{errors[id]}</div>
        )}
      </div>
    );
  };

  const renderSections = () => {
    if (ideasSectionsLoading) {
      return <Loading />;
    }
    if (!ideasSections || ideasSections.length <= 0) {
      return "";
    }

    return ideasSections
      .filter(({ step_nr: stepNr }) => stepNr === step)
      .map((sectionField) => {
        const field = {
          id: sectionField.ideas_section_id,
          label: sectionField.section_name,
          type: sectionField.section_type,
          promptText: sectionField.prompt_text,
          placeholder: sectionField.placeholder,
          options: sectionField.section_option_values,
          allowMultiselect: sectionField.multiselect,
          required: sectionField.field_required,
        };
        if (field.type === "checklist") {
          return renderCheckboxInput(field);
        }
        if (field.type === "dropdown") {
          return renderSelectInput(field);
        }
        return renderTextInput(field);
      });
  };

  return (
    <>
      {renderSections()}
      {selectedCouncil?.name === "Ford" && isFirstStep && (
        <div className={styles.formGroup}>
          <Label>Next Stage Change</Label>
          <Select
            style={{
              border: "1px solid #e4e7eb",
              outline: "none",
              boxShadow: "none",
            }}
            label="Next Stage Change"
            placeholder="Next Stage Change"
            labelField="name"
            valueField="id"
            name="next_stage_end_on"
            multi={false}
            options={stages}
            onChange={(val) => {
              setNextStage(val[0]);
            }}
            values={formikProps.values.next_stage_end_on}
            clearable={nextStage ? true : false}
            clearRenderer={({ props, state, methods }) => (
              <div
                className="d-flex justify-content-end"
                style={{
                  position: "absolute",
                  bottom: 5,
                  right: 0,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  methods.clearAll();
                  setNextStage(null);
                }}
              >
                <span className="text-muted small cursor-pointer">clear</span>
              </div>
            )}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(SectionStepFields);

import React, { useEffect, useState } from "react";
import {
  Button,
  BtnType,
  MoreOptions,
  OptionType,
  Loading,
} from "modules/primitives/index";
import ReactTable from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import adminFuseHoc from "common/HOC/adminFuseHoc";
import styles from "../adminRating.module.scss";
import { useModelPopup } from "../../../../common/hooks";
import {
  ratingsTemplateCategoriesGetAttempt,
  ratingsTemplateSingleGetAttempt,
} from "../adminRating.action";
import ConfirmDeleteComponent from "../../../../common/components/confirmDelete";
import AdminRatingCategoriesModal from "./adminRatingCategoriesModal/adminRatingCategoriesModal.component";

const AdminRatingCategories = () => {
  const history = useHistory();
  const historyParams = useParams();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [rootName, setRootName] = useState("");

  const {
    councilReducer: { selectedCouncil },
    adminRatingTemplatesReducer: {
      loading,
      selectedTemplateCategories,
      selectedTemplate,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(
      ratingsTemplateCategoriesGetAttempt({
        id: historyParams.id,
        enqueueSnackbar,
      }),
    );
    dispatch(
      ratingsTemplateSingleGetAttempt({ historyParams, enqueueSnackbar }),
    );
  }, []);

  useEffect(() => {
    setRootName(selectedTemplate.name);
  }, [selectedTemplate]);

  useEffect(() => {
    setData(selectedTemplateCategories);
  }, [selectedTemplateCategories]);

  const handleNewCategoryClick = (event) => {
    popup.show({
      title: "Add Ratings Category",
      component: (
        <AdminRatingCategoriesModal
          mode="add"
          selectedData={data.filter((category) => !category.parent_id)}
          templateId={historyParams.id}
          enqueueSnackbar={enqueueSnackbar}
          categories={data}
          selectedTemplate={selectedTemplate}
          type="parent"
        />
      ),
    });
  };

  const handleMoreOptionClick = (event, row, type) => {
    if (event === "edit") {
      popup.show({
        title: "Edit Rating Category",
        component: (
          <AdminRatingCategoriesModal
            mode="edit"
            templateId={historyParams.id}
            categoryId={row.rating_category_id}
            councilId={selectedCouncil.id}
            enqueueSnackbar={enqueueSnackbar}
            categories={selectedTemplateCategories}
            selectedTemplate={selectedTemplate}
            row={row}
            type={type}
          />
        ),
      });
    }
    if (event === "delete") {
      popup.show({
        title: "Confirmation Prompt",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            categoryId={row.rating_category_id}
            templateId={historyParams.id}
            enqueueSnackbar={enqueueSnackbar}
            actionType="delteRatingTemplateCategory"
          />
        ),
      });
    }
  };

  const moreOptions = [
    { val: "edit", text: "Edit" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const columns = React.useMemo(
    (e) => [
      {
        Header: "Ratings Category",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Parent Category",
        id: "parent_id",
        accessor: (row) => {
          if (row.parent_id) {
            return selectedTemplateCategories.filter(
              (category) => category.rating_category_id === row.parent_id,
            )[0]?.name;
          }
          return "-";
        },
        width: 200,
      },
      {
        Header: "Weight",
        accessor: "weight",
        width: 100,
        // Cell: renderEditable,
      },
      {
        Header: "Subcategories Weight",
        id: "sub_categories",
        accessor: (row) => {
          let subCategoriesWeight = 0;
          if (row.sub_categories && row.sub_categories[0]) {
            row.sub_categories.map((category) => {
              subCategoriesWeight += Number(category.weight);
            });
            if (subCategoriesWeight < 100) {
              return <div style={{ color: "red" }}>{subCategoriesWeight}</div>;
            }
            return <div>{subCategoriesWeight}</div>;
          }
          return <div>-</div>;
        },
        width: 100,
        // Cell: renderEditable,
      },
      {
        Header: "Options",
        id: "options",
        width: 100,
        accessor: (row) => (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row, "parent");
            }}
          />
        ),
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [selectedTemplateCategories],
  );

  const subColumns = React.useMemo(
    (e) => [
      {
        id: "rating_category_id",
        width: 50,
      },
      {
        accessor: "name",
      },
      {
        accessor: "description",
      },
      {
        id: "parent_id",
        accessor: (row) => {
          if (row.parent_id) {
            return selectedTemplateCategories.filter(
              (category) => category.rating_category_id === row.parent_id,
            )[0]?.name;
          }
          return "-";
        },
        width: 200,
      },
      {
        accessor: "weight",
        width: 100,
      },
      {
        id: "options",
        width: 100,
        accessor: (row) => (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row, "child");
            }}
          />
        ),
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [selectedTemplateCategories],
  );

  const renderSubCategories = (category, index) => {
    if (category.sub_categories && category.sub_categories.length > 0) {
      return (
        <div>
          <ReactTable
            data={category.sub_categories}
            columns={subColumns}
            minRows={3}
            showPagination={false}
            // SubComponent={row => {
            //   return (
            //     <div>
            //       {renderSubCategories(row.original, index + 1)}
            //     </div>
            //   );
            // }}
          />
        </div>
      );
    }
    const subCategory = data.find(
      (cat) => cat.rating_category_id === category.rating_category_id,
    );
    if (
      subCategory.rating_category_id
      && subCategory.sub_categories.length > 0
    ) {
      return (
        <div>
          <ReactTable
            data={subCategory.sub_categories}
            columns={subColumns}
            minRows={3}
            showPagination={false}
            // SubComponent={row => {
            //   return (
            //     <div>
            //       {renderSubCategories(row.original, index + 1)}
            //     </div>
            //   );
            // }}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={styles.container}>
          <div className={styles.headerWrp}>
            <h4 className={styles.taskHeaderText}>
              <span
                className={styles.rootText}
                onClick={() => {
                  history.push("/admin");
                }}
              >
                ADMIN
              </span>
              <span className={styles.separationBar}> / </span>
              <span
                className={styles.rootText}
                onClick={() => {
                  history.push("/admin/ratings");
                }}
              >
                RATINGS
              </span>
              <span className={styles.separationBar}> / </span>
              <span className={styles.rootText}>{rootName}</span>
            </h4>
          </div>
          <div className={styles.flexWrapper}>
            <div className={styles.ratingTamplates}>
              <div className={styles.addTemplateButton}>
                <Button
                  btn={BtnType.REGULAR}
                  icon="icn-add"
                  onClick={() => handleNewCategoryClick()}
                >
                  Add Category
                </Button>
              </div>
              <div className={styles.searchBar}>
                {/* <Search showDropdown={false} from="admin_companies" /> */}
              </div>

              <ReactTable
                columns={columns}
                data={data.filter((category) => !category.parent_id)}
                showPagination={false}
                minRows={10}
                loading={loading}
                className={styles.table}
                SubComponent={(row) => renderSubCategories(row.original, 0)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default adminFuseHoc(AdminRatingCategories);

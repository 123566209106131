import React, { useState } from "react";
import classNames from "classnames";

import styles from '../styles.module.scss';

const PocTableDataCardRow = ({ rowData }) => {
  const [showMore, setShowMore] = useState(false)

  const handleShow = () => {
    setShowMore(!showMore)
  }

  return (
    <div className={classNames('d-flex flex-column', styles.pocTableRow)}>
      <div className="d-flex">
        <div className={classNames('d-flex align-items-center w-75 pl-1 pr-2 py-2', styles.pocTableRowWithBorderRight)}>
          {
            rowData.plants?.length > 0 ? (
              <div className={styles.pocTableRowExpand} onClick={handleShow}>
                <span>{showMore ? "-" : "+"}</span>
              </div>
            ) : (
              <div className={styles.emptyPocTableRowExpand}></div>
            )
          }
          <span className="ml-1">{rowData.theme}</span>
        </div>
        <div className="w-25 px-3 text-right py-2">
        {rowData.total_poc}
        </div>
      </div>
      {
        showMore && (
          <div className="border-top px-3 pb-3">
            <div className="d-flex justify-content-between mb-3 mt-2">
              <span>Plants</span>
              <span>PoC</span>
            </div>
            {
              rowData.plants.map(plant => (
                <div key={plant.plant_name} className="small d-flex justify-content-between">
                  <span>{plant.plant_name}</span>
                  <span>{plant.poc}</span>
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
};

export default React.memo(PocTableDataCardRow);

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { withSnackbar, useSnackbar } from "notistack";

import { patientOrganizationsGet } from "modules/patientOrganizations/patientOrganizationMain/patientOrganizationMain.action";
import {
  companyListGet,
  companySearchClear,
  searchInCouncil,
} from "modules/companies/companyMain/companyMain.action";
import {
  Button,
  BtnType,
  TextBox,
  Label,
  DropSelect,
} from "../../../modules/primitives";
import { initModel, validation } from "./NewContactConstants";
import styles from "../../../modules/people/externalPeople/contactNew/contactNew.module.scss";
import newContactStyles from "./styles.module.scss";
import { httpPost } from "../../httpCall";

const NewContactForm = (props) => {
  const { defaultModel = {}, mode } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [model, setmodel] = useState(initModel);
  const [options, setOptions] = useState([]);
  const [company, setCompany] = useState("");
  const [organization, setOrganization] = useState("");
  const [applyToOptions, setApplyToOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showOption, setShowOption] = useState(false);
  const [deselected, setDeselected] = useState(null);
  const [initData, setInitData] = useState(false);
  const [searchType, setSearchType] = useState("company");
  const [isDropOpened, setIsDropOpened] = useState(false);
  const [updateOrg, setUpdateOrg] = useState(false);
  const [updateCompany, setUpdateCompany] = useState(false);
  const [error, setError] = useState("");
  const formRef = useRef();

  const {
    councilReducer: { selectedCouncil },
    projectsReducer: {
      stagesReducer: { stagesList },
    },
    companiesReducer: {
      companyMainReducer: { companyList, searchResults },
      companyReducer: { selectedCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: { patientOrganizationList },
    },
    peopleReducer: {
      internalPeopleReducer: { teamList },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (Object.keys(defaultModel).length > 0) {
      setmodel(defaultModel);
      setInitData(true);
    }
  }, [defaultModel, options, applyToOptions]);

  useEffect(() => {
    if (stagesList && stagesList.length !== 0) {
      setOptions(stagesList);
    }
  }, [stagesList]);

  useEffect(() => {
    if (defaultModel.organization_id) {
      setShowOption(true);
    }
  }, [defaultModel]);

  useEffect(() => {
    if (selectedCouncil.traction_tag === "gsk") {
      dispatch(
        patientOrganizationsGet({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil, updateOrg]);

  useEffect(() => {
    dispatch(
      companyListGet({
        councilId: selectedCouncil.id,
        items: 20,
        enqueueSnackbar,
      })
    );
  }, [selectedCouncil, updateCompany]);

  useEffect(() => {
    if (companyList && companyList.length !== 0) {
      const newList = [
        ...companyList,
        { id: "all", name: "All Organizations" },
      ];
      setApplyToOptions(newList);
    }
  }, [companyList]);

  useEffect(() => {
    if (teamList && teamList.length > 0) {
      setTeamOptions(teamList);
    }
  }, [teamList]);

  useEffect(() => {
    if (!searchType) {
      dispatch(
        searchInCouncil({
          data: searchQuery,
          council_id: selectedCouncil.id,
          mode: "companies",
          enqueueSnackbar,
          cb: () => {},
        })
      );
    } else if (searchType && searchQuery) {
      dispatch(
        searchInCouncil({
          data: searchQuery,
          council_id: selectedCouncil.id,
          mode: searchType,
          gsk: searchType !== "companies" && true,
          patient_org: searchType !== "companies" && true,
          enqueueSnackbar,
          cb: () => {},
        })
      );
    }
  }, [searchQuery, searchType]);

  const handleSaveClick = (e) => {
    handleOnSubmit(formRef.current.values);
    formRef.current.values.full_name = `${formRef.current.values.first_name} ${formRef.current.values.last_name}`;
    props.handleSubmit(formRef.current.values);
  };

  const handleDropOpen = () => {
    setIsDropOpened(true);
  };

  const handleDropClose = (e) => {
    setIsDropOpened(false);
    setUpdateCompany(true);
    dispatch(companySearchClear());
    if (
      (mode !== "edit" && selectedCouncil.traction_tag === "gsk") ||
      defaultModel.org_type === "patient"
    ) {
      dispatch(
        patientOrganizationsGet({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  };

  const handleSearch = ({ state, props, methods }, type) => {
    if (state.search !== "") {
      setSearchQuery(state.search);
      setSearchType(type);
      return searchType === "companies"
        ? searchResults
        : patientOrganizationList;
    }
  };

  const handleChangeCompany = (e, setFieldValue) => {
    e.stopPropagation();
    setShowOption(false);
    if (initData) {
      if (defaultModel.org_type === "patient") {
        setFieldValue("patient_organization_id", defaultModel.organization_id);
      } else {
        setFieldValue("organization_id", defaultModel.organization_id);
      }
    }
  };

  const handleSelectCompany = (e, setFieldValue) => {
    setCompany(e.val);
    setFieldValue("organization_id", e.val);
    if (company === e.val) {
      setCompany("");
      setFieldValue("organization_id", "");
      setDeselected(e.val);
    } else if (organization && e.val && mode !== "edit") {
      setDeselected(organization);
      setOrganization("");
      setFieldValue("patient_organization_id", "");
    }
  };

  const handleSelectOrganization = (e, setFieldValue) => {
    setOrganization(e.val);
    setFieldValue("patient_organization_id", e.val);
    if (organization === e.val) {
      setOrganization("");
      setFieldValue("patient_organization_id", "");
      setDeselected(e.val);
    } else if (company && e.val && mode !== "edit") {
      setDeselected(company);
      setCompany("");
      setFieldValue("organization_id", "");
    }
  };

  const addCompany = (values, organization_id) => {
    httpPost({
      call: "companies/contacts",
      data: { ...values, company_id: organization_id || company },
    })
      .pipe()
      .subscribe(() => {});
  };

  const handleOnSubmit = (value) => {
    const data = {
      first_name: value.first_name,
      last_name: value.last_name,
      full_name: `${value.first_name} ${value.last_name}`,
      email: value.email,
      title: value.title,
      mobile: value.mobile,
    };

    addCompany(data, value.organization_id);
  };

  return (
    <div
      className={newContactStyles.contactBorder}
      style={{ paddingBottom: 20 }}
    >
      <h4> Add Company Contact </h4>
      <Formik
        innerRef={formRef}
        enableReinitialize
        validationSchema={validation && validation}
        initialValues={model && model}
        onSubmit={() => {
          handleSaveClick();
        }}
      >
        {({ setFieldValue, handleSubmit, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.editStageFormWrp}>
              <div className={styles.name}>
                <div className={styles.firstName}>
                  <Label>First Name</Label>
                  <TextBox
                    type="text"
                    name="first_name"
                    placeholder="First name"
                    className={styles.textBox}
                    formProps={formikprops}
                  />
                </div>
                <div className={styles.lastName}>
                  <Label>Last Name</Label>
                  <TextBox
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                    className={styles.textBox}
                    formProps={formikprops}
                  />
                </div>
              </div>
              <Label>Email</Label>
              <TextBox
                type="text"
                name="email"
                placeholder="Email"
                className={styles.textBox}
                formProps={formikprops}
              />
              <Label>Phone</Label>
              <TextBox
                type="text"
                name="mobile"
                placeholder="Phone"
                className={styles.textBox}
                formProps={formikprops}
              />
              <Label>Title</Label>
              <TextBox
                type="text"
                name="title"
                placeholder="Title"
                className={styles.textBox}
                formProps={formikprops}
              />
              {(selectedCouncil && formikprops.values.org_type === "company") ||
              !initData ? (
                <div>
                  <Label>Company</Label>
                  {showOption ? (
                    <div className={styles.companyName}>
                      {defaultModel.organization_name}
                      <div
                        className={styles.changeButton}
                        onClick={(e) => handleChangeCompany(e, setFieldValue)}
                      >
                        Change
                      </div>
                    </div>
                  ) : (
                    <div>
                      <DropSelect
                        placeholder="Select Company"
                        name="organization_id"
                        onDropdownOpen={handleDropOpen}
                        onDropdownClose={handleDropClose}
                        labelField="name"
                        valueField="id"
                        searchBy="text"
                        searchable
                        deselected={
                          (organization || mode === "edit") && deselected
                        }
                        backspaceDelete
                        options={
                          searchResults && searchResults[0]
                            ? searchResults
                            : companyList
                        }
                        formProps={formikprops}
                        onChange={(e) => handleSelectCompany(e, setFieldValue)}
                        searchFn={(payload) =>
                          handleSearch(payload, "companies")
                        }
                        SelecedValues={selectedCompany.id}
                      />
                      {error === "company" && (
                        <div className={styles.errorText}>
                          Company/Organization required
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}

              {((selectedCouncil &&
                formikprops.values.org_type === "patient") ||
                !initData) &&
              selectedCouncil.traction_tag === "gsk" ? (
                <div>
                  {!initData && <Label>Or</Label>}
                  <br />
                  <Label>Patient Organization</Label>
                  {showOption ? (
                    <div className={styles.companyName}>
                      {defaultModel.organization_name}
                      <div
                        className={styles.changeButton}
                        onClick={(e) => handleChangeCompany(e, setFieldValue)}
                      >
                        Change
                      </div>
                    </div>
                  ) : (
                    <div>
                      <DropSelect
                        placeholder="Select Organization"
                        name="patient_organization_id"
                        labelField="name"
                        valueField="id"
                        searchable
                        backspaceDelete
                        searchFn={(payload) =>
                          handleSearch(payload, "gsk/patient_organizations")
                        }
                        deselected={(company || mode === "edit") && deselected}
                        searchBy="name"
                        options={patientOrganizationList}
                        onDropdownOpen={handleDropOpen}
                        onDropdownClose={handleDropClose}
                        formProps={formikprops}
                        onChange={(e) =>
                          handleSelectOrganization(e, setFieldValue)
                        }
                      />
                      {error === "patient_organization_id" && (
                        <div className={styles.errorText}>
                          Company/Organization required
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            <div className={newContactStyles.contactCancelBtn}>
              <Button
                className={newContactStyles.addCompanyContact}
                type="button"
                onClick={props.handleAddContactCancel}
                btn={BtnType.FRAME_LESS}
              >
                Cancel
              </Button>
              <Button
                className={newContactStyles.addCompanyContact}
                btn={BtnType.REGULAR}
                type="submit"
              >
                Add Contact
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(withSnackbar(NewContactForm));

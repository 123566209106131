import isUserAdmin from "./isUserAdmin/index";

const isItStandardViewver = (session, currentThemeData) => {
  if (isUserAdmin(session?.council_role)) {
    return true;
  }

  return currentThemeData?.current_user_role === "manager" ||
    currentThemeData?.current_user_role === "owner";
};
export default isItStandardViewver;

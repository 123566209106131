import {
  ADD_RELATED_IDEA_ATTEMPT,
  ADD_RELATED_IDEA_SUCCESS,
  DELETE_RELATED_THEME_SUCCESS,
  DELETE_RELATED_THEME_ATTEMPT,
  GET_RELATED_SOLUTIONS_SUCCESS,
  DELETE_RELATED_SOLUTION_SUCCESS,
  ADD_RELATED_SOLUTION_SUCCESS,
  GET_UPCOMING_TASKS_SUCCESS,
  GET_UPCOMING_TASKS,
} from "./overview.action";
import {
  GATEWAY_TASK_UPDATE_SUCCESS,
  GET_TASK_ADDITIONAL_DATA_SUCCESS,
  PPROJECT_TASK_UPDATE_SUCCESS,
} from "modules/projects/project/tasks/tasks.action";

const initState = {
  documents: [],
  loading: false,
  shouldUpdateRelatedIdeas: false,
  shouldUpdateRelatedThemes: false,
  projectSolutions: [],
  upcomingTasks: {
    fetching: false,
    data: null,
    hasLoadMore: false,
  }
};

const overviewReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GATEWAY_TASK_UPDATE_SUCCESS: {
      if (!state.upcomingTasks.data?.length) {
        return state;
      }

      const data = state.upcomingTasks.data.map((item) => {
        if (item.id === payload.response.id) {
          return {
            ...item,
            ...payload.response,
          }
        }

        return item;
      });

      return {
        ...state,
        upcomingTasks: {
          ...state.upcomingTasks,
          data,
        }
      };
    }

    case GET_TASK_ADDITIONAL_DATA_SUCCESS: {
      if (state.upcomingTasks.data?.length) {
        const data = state.upcomingTasks.data.map((item) => {
          if (item.id === payload.response.id) {
            return {
              ...item,
              ...payload.response,
              hasAdditionalData: true,
            }
          }

          return item;
        });

        return {
          ...state,
          upcomingTasks: {
            ...state.upcomingTasks,
            data,
          }
        };
      }

      break;
    }
    case PPROJECT_TASK_UPDATE_SUCCESS: {
      if (payload.directUpdate && state.upcomingTasks.data?.length) {
        const data = state.upcomingTasks.data.map((item) => {
          if (item.id === payload.id) {
            return {
              ...item,
              ...payload,
            }
          }

          return item;
        });

        return {
          ...state,
          upcomingTasks: {
            ...state.upcomingTasks,
            data,
          }
        };
      }
      break;
    }
    case GET_UPCOMING_TASKS: {
      return {
        ...state,
        upcomingTasks: {
          fetching: true,
          data: null,
          hasLoadMore: false,
        }
      }
    }
    case GET_UPCOMING_TASKS_SUCCESS: {
      const total = parseInt(payload.xhr.getResponseHeader("total-count"));

      return {
        ...state,
        upcomingTasks: {
          fetching: false,
          data: payload.response || [],
          hasLoadMore: total > 5,
        }
      }
    }
    case ADD_RELATED_IDEA_ATTEMPT: {
      return {
        ...state,
        shouldUpdateProjects: false,
        loading: true,
      };
    }
    case ADD_RELATED_IDEA_SUCCESS: {
      return {
        ...state,
        shouldUpdateProjects: true,
        loading: false,
      };
    }
    case DELETE_RELATED_THEME_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_RELATED_THEME_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_RELATED_SOLUTIONS_SUCCESS: {
      return {
        ...state,
        projectSolutions: payload.response,
      };
    }
    case DELETE_RELATED_SOLUTION_SUCCESS: {
      return {
        ...state,
        projectSolutions: [
          ...state.projectSolutions.filter((sol) => {
            if (sol.id === payload) {
              return false;
            }
            return true;
          }),
        ],
      };
    }
    case ADD_RELATED_SOLUTION_SUCCESS: {
      return {
        ...state,
        projectSolutions: [...payload.response, ...state.projectSolutions],
      };
    }

    default:
      return { ...state };
  }
  return state;
};

export default overviewReducer;

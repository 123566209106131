import React, { useState, useEffect } from "react";
import CheckBox from "modules/admin/adminIdeas/addForm/checkBox";

import { UserInline, UserInlineTypes } from "modules/primitives";

import styles from "./styles.module.scss";

const MembersItem = (props) => {
  const { member, storeMembers, selectAllMembers, selected } = props;
  const [memberSelected, setMemberSelected] = useState(selected);

  useEffect(() => {
    if (selected) {
      setMemberSelected(true);
      return;
    }

    setMemberSelected(selectAllMembers);
  }, [selectAllMembers, selected]);

  return (
    <div>
      <div className={styles.membersBlockItem}>
        <CheckBox
          onChange={() => {
            storeMembers(member.user_id);
            setMemberSelected(!memberSelected);
          }}
          isChecked={memberSelected || selectAllMembers}
        />
        <UserInline
          userId={member.user_id}
          first={member.name}
          last={""}
          avatar={member.avatar}
          local
          type={UserInlineTypes.WITH_NAME}
          noNavigation
        />
      </div>
    </div>
  );
};
export default React.memo(MembersItem);

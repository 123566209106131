import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useSnackbar } from "notistack";

import { CheckBox, CheckType, Label } from "modules/primitives";
import styles from "./styles.module.scss";
import { Icon, smSize } from "../../../../common/icon";
import { toolTips } from "modules/patientOrganizations/patientOrganization/details/checkList/constant";
import { optionsScoreGet } from "modules/projects/newProjectv3/newProjectV3.actions";

const ProjectScoring = ({ isAdminWizardInstance, formikprops }) => {
  const [collapsed, setCollapsed] = useState([]);
  const [options, setOptions] = useState([]);
  const [values, setValues] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const {
    newProjectV3Reducer: { optionScores },
  } = useSelector((state) => state);

  useEffect(() => {
    if (optionScores.length === 0 && !isAdminWizardInstance) {
      dispatch(optionsScoreGet({ enqueueSnackbar }));
    }
  }, []);

  useEffect(() => {
    if (optionScores && options.length === 0) {
      let newValues = {};
      const newScores = optionScores.map((item) => {
        newValues = {
          ...newValues,
          [item.id]: Number(item.weight),
        };

        const children = item.children.map((child) => ({
          ...child,
          selected: true,
        }));

        return {
          ...item,
          selected: true,
          children,
        };
      });

      setValues(newValues);
      setOptions(newScores);
    }
  }, [optionScores]);

  const handleCollapseClick = (item) => {
    if (!collapsed.find((obj) => obj === item)) {
      setCollapsed((prev) => [...prev, item]);
    } else {
      setCollapsed((prev) => prev.filter((obj) => obj !== item));
    }
  };

  const handleChange = (selected, item) => {
    const newOptions = options.map((option) => {
      if (option.id === item.id) {
        return {
          ...option,
          children: option.children.map((child) => ({
            ...child,
            selected,
          })),
          selected,
        };
      }

      return {
        ...option,
        children: option.children.map((child) => {
          if (child.id === item.id) {
            return {
              ...child,
              selected,
            };
          }
          return child;
        }),
      };
    });

    reCalcWhenSelectsUpdate(newOptions);
  };

  const reCalcWhenSelectsUpdate = (newOptions) => {
    let newValues;
    const categoriesLength = newOptions.filter(
      (o) => o.selected && !o.touched
    ).length;
    const totalFromUsedItems = newOptions
      .filter((o) => o.touched && o.selected)
      .reduce((acc, o) => acc + Number(o.weight), 0);

    const weightForCategories = (
      (100 - totalFromUsedItems) /
      (categoriesLength === 0 ? 1 : categoriesLength)
    ).toFixed(2);

    const newCategories = newOptions.map((option) => {
      const childLength = option.children.filter((c) => c.selected).length;
      const childWeight = (Number(option.weight) / childLength).toFixed(2);

      const children = option.children.map((child) => {
        if (child.selected) {
          return {
            ...child,
            weight: childWeight,
          };
        }
        return child;
      });

      newValues = {
        ...newValues,
        [option.id]: option.touched
          ? Number(option.weight)
          : weightForCategories,
      };

      return {
        ...option,
        weight: option.touched ? Number(option.weight) : weightForCategories,
        children,
      };
    });

    setValues(newValues);
    setOptions(newCategories);
  };

  useEffect(() => {
    const new_project = localStorage.getItem("new_project");
    let obj = JSON.parse(new_project);
    obj = {
      ...obj,
      options_score_selected: options
        .filter((o) => o.selected)
        .reduce(
          (acc, elem) => [...acc, ...elem.children.filter((o) => o.selected)],
          []
        ),
    };
    localStorage.setItem("new_project", JSON.stringify(obj));
  }, [options]);

  const handleOnChange = (e, item) => {
    setValues({
      ...values,
      [item.id]: e.target.value,
    });
  };

  const handleOnBlur = (e, item) => {
    const touchedItems = options.map((o) => {
      if (o.id === item.id && o.weight !== e.target.value) {
        return {
          ...o,
          weight: e.target.value,
          touched: true,
        };
      }
      return o;
    });

    let newValues = values;

    const categoriesLength = touchedItems.filter(
      (o) => o.selected && !o.touched
    ).length;

    if (categoriesLength === 0) {
      return;
    }

    const totalFromUsedItems = touchedItems
      .filter((o) => o.touched && o.selected)
      .reduce((acc, o) => acc + Number(o.weight), 0);

    const weightForCategories = (
      (100 - totalFromUsedItems) /
      categoriesLength
    ).toFixed(2);

    const newCategories = touchedItems.map((option) => {
      if (!option.selected) {
        return option;
      }

      const childLength = option.children.filter((c) => c.selected).length;
      const childWeight =
        item.id === option.id
          ? (e.target.value / childLength).toFixed(2)
          : (weightForCategories / childLength).toFixed(2);

      const children = option.children.map((child) => {
        if (child.selected) {
          return {
            ...child,
            weight: childWeight,
          };
        }
        return child;
      });

      if (option.touched) {
        return {
          ...option,
          children,
        };
      }

      const weight =
        item.id === option.id
          ? e.target.value
          : option.touched
          ? Number(option.weight)
          : weightForCategories;

      newValues = {
        ...newValues,
        [`${option.id}`]: weight,
      };

      return {
        ...option,
        weight,
        children,
      };
    });

    setValues(newValues);
    setOptions(newCategories);
  };

  if (isAdminWizardInstance) {
    return (
      <div className="mb-2 d-flex flex-column align-items-baseline">
        <Label>Project Scoring</Label>
        <p>Scoring component</p>
      </div>
    )
  }

  return (
    <div className={styles.inner}>
      <h4 className={classNames("bold", styles.innerTitle)}>
        Create a matching template
      </h4>
      <div className={classNames("bold", styles.infoText)}>
        Modify the categories or individual attributes below that will contribute to the overall match for each Patient Organization in this project by checking or unchecking the boxes below. Additionally, you may modify the weighting given to the category or individual attributes.
      </div>
      <div className={styles.listWrp}>
        <div className="row justify-content-between">
          <div className="col-6 text-center">Attributes</div>
          <div className="col-6 text-right">Weight in %</div>
        </div>
        {options &&
          options.map((item) => (
            <div key={item.id} className={styles.vtColumn}>
              <div className={classNames(styles.hoverable)}>
                <div className="row">
                  <div className="col-1">
                    <CheckBox
                      checkType={CheckType.BLUE}
                      isChecked={item.selected}
                      onChange={(val) => handleChange(val, item)}
                    />
                  </div>
                  <div className="col-8">
                    <div className={styles.center}>
                      <div className="d-flex align-items-center">
                        <span>{item.name}</span>
                        <Icon
                          {...smSize}
                          icon="icn-table-down-chevron"
                          className={classNames(
                            styles.icon,
                            !collapsed.includes(item.id) && styles.collapse
                          )}
                          onClick={() => handleCollapseClick(item.id)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-3 align-self-end justify-content-end">
                    <div className={styles.right}>
                      <input
                        id={`weight-${item.id}`}
                        type="number"
                        min={1}
                        max={100}
                        className={classNames(styles.weightWrp1)}
                        value={values[item.id]}
                        onBlur={(evt) => handleOnBlur(evt, item)}
                        onChange={(evt) => handleOnChange(evt, item)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {item.children && collapsed.includes(item.id) && (
                <div
                  className={styles.vtColumnInner}
                  id={`childCollapse-${item.id}`}
                >
                  {item.children &&
                    item.children.map((child) => (
                      <div key={child.id} className={styles.formElement}>
                        <CheckBox
                          checkType={CheckType.BLUE}
                          isChecked={child.selected}
                          onChange={(val) => handleChange(val, child)}
                        />
                        <div className={styles.label}>
                          <div className="d-flex">
                            <div className="mr-3">{child.name}</div>
                            <span
                              className={styles.iconQuestion}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={toolTips[child.name]}
                            >
                              <Icon {...smSize} icon="icn-table-message" />
                            </span>
                          </div>
                          <div className={styles.right}>{child.weight}%</div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default React.memo(ProjectScoring);

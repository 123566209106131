import React from "react";
import Icon from "../../../../../../common/icon/icon.component";

import styles from "./styles.module.scss";

export const PostReplyFav = () => {
  return (
    <div className={styles.favoritesBlock}>
      (
      <Icon
        className={styles.favoriteEnableRepl}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        icon="icn-subnav-ratings-fill"
      />{" "}
      <div className={styles.favoriteText}> Replies </div> )
    </div>
  );
};

export const PostLiked = ({ isLiked }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={isLiked ? styles.likeEnable : styles.likeDissable}
  >
    <path
      d="M3.598 5.265a3.75 3.75 0 0 0 0 5.303L10 16.97l6.402-6.402a3.75 3.75 0 0 0-5.304-5.303L10 6.363 8.902 5.265a3.75 3.75 0 0 0-5.304 0z"
      fill="#CBD2D9"
    />
  </svg>
);

export const PostReplyIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 7.5H5.833v1.667H7.5V7.5zM9.167 7.5h1.666v1.667H9.167V7.5zM14.167 7.5H12.5v1.667h1.667V7.5z"
      fill="#2D83AE"
    />
    <path
      d="M2.5 4.167V17.5l4-3c.288-.217.64-.334 1-.333h8.333c.92 0 1.667-.746 1.667-1.667V4.167c0-.92-.746-1.667-1.667-1.667H4.167c-.92 0-1.667.746-1.667 1.667zm1.667 10v-10h11.666V12.5H6.945a1.653 1.653 0 0 0-1 .333l-1.778 1.334z"
      fill="#2D83AE"
    />
  </svg>
);

import React, { useState } from "react";
import { useSnackbar } from "notistack";

import styles from "./styles.module.scss";
import { ALLOWED_FILE_EXTENSIONS } from "../../constants";

const DragAndDrop = (props) => {
  const {
    unicumKey = Math.random(),
    onDrop = () => {},
    onDragOver = () => {},
    onDragOVerEnd = () => {},
    onClick = () => {},
    uploadHandler = () => {},
    loading = false,
    customText = "",
    disabled,
  } = props;
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const setEventSetting = (event) => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragOverEvent = (event) => {
    if (loading || disabled) {
      return;
    }
    setEventSetting(event);
    setIsDraggingOver(true);
    onDragOver(event);
  };

  const onDragOverEndEvent = (event) => {
    if (loading || disabled) {
      return;
    }
    setEventSetting(event);
    setIsDraggingOver(false);
    onDragOVerEnd();
  };

  const onDropEvent = (event) => {
    let files = Array.from(event.dataTransfer.files);
    const notAllowedExtensions = [];
    // This regular expression pattern that matches any character that is not in the range of a-z, A-Z, or 0-9.
    const regex = /[^a-zA-Z0-9]/g;

    files = files.filter((file) => {
      const extension = file.name.slice(
        ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      const isRightExtension = ALLOWED_FILE_EXTENSIONS.includes(extension);

      if (!isRightExtension) {
        notAllowedExtensions.push(extension);
      }

      if (regex.test(file.name)) {
        file.name.replace(/[^a-zA-Z0-9. ]/g, "");
      }

      return isRightExtension;
    });

    if (notAllowedExtensions && notAllowedExtensions.length > 0) {
      const message = `Files with the extensions "${notAllowedExtensions.join(
        ", "
      )}" are not allowed.`;
      enqueueSnackbar(message, {
        variant: "error",
        key: "extensionNotAllowed",
        persist: true,
      });

      const timeout = setTimeout(() => {
        closeSnackbar("extensionNotAllowed");
        clearTimeout(timeout);
      }, 5000);
    }

    if (loading || disabled) {
      return;
    }

    setEventSetting(event);
    setIsDraggingOver(false);
    onDrop(event);
    uploadHandler(
      {
        ...event,
        files,
      },
      "drop"
    );
  };

  const onClickAddEvent = (event) => {
    if (loading || disabled) {
      return;
    }
    setEventSetting(event);
    onClick(event);
    uploadHandler(event, "click");
  };

  return (
    <div
      onDragOver={onDragOverEvent}
      onDragLeave={onDragOverEndEvent}
      onDrop={onDropEvent}
      className={`${
        isDraggingOver
          ? styles.dragAndDropContainerTriggered
          : styles.dragAndDropContainer
      }`}
    >
      <div className={styles.dashedContainer}>
        <div className={styles.contentWrapper}>
          {loading ? (
            <span className={styles.contentText}>Uploading...</span>
          ) : (
            <>
              <svg
                className={styles.boxIcon}
                style={{
                  color: "#92b0b3",
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 50 43"
              >
                <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z" />
              </svg>
              <input
                type={disabled ? "" : "file"}
                id={`file-${unicumKey}`}
                onChange={onClickAddEvent}
                className={styles.fileInput}
                multiple
                accept=".txt,.doc,.docx,.pptx,.ppt,.pdf,.xlsx,.csv,.mp4,.mov,.wmv,.avi,.mp3,.png,.jpg,.jpeg,.gif,.key,.stl,.stp"
              />
              <label
                htmlFor={`file-${unicumKey}`}
                className={styles.contentText}
              >
                <strong className={styles.chooseFile}>Choose a file</strong> or{" "}
                {customText || "drag and drop"}
              </label>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DragAndDrop;

import React, { useCallback } from "react";

import styles from "./taskDueDate.module.scss";
import { Icon, mdSize } from "../../../../../common/icon";

const TaskDueDate = ({ task }) => {
  const makeDueClass = useCallback(() => {
    if (!task.due_date_status) {
      return "";
    }

    return styles[task.due_date_status];
  }, [task]);

  return (
    <div className={`${styles.dueDate} ${makeDueClass()}`}>
      <Icon {...mdSize} icon="icn-table-timer" />
    </div>
  );
};

export default React.memo(TaskDueDate);

import React from "react";
import { useFormikContext } from "formik";
import DropDownNestedElements from "common/components/dropdownNestedElements";

import { Label } from "../../../../../modules/primitives";
import SectionStepFields from "../sectionStepFields";

const StepWrapper = ({
  stepDetails,
  topics,
  industries,
  selectedCouncil,
  instructions,
  setNextStage,
  nextStage,
}) => {
  const formikProps = useFormikContext();

  return (
    <div>
      {stepDetails.isFirstStep && (
        <>
          <h4 className="bold">Submit Idea</h4>
          <h6>{instructions}</h6>
        </>
      )}
      {!stepDetails.isFirstStep && (
        <>
          <h4 className="bold">{stepDetails.name}</h4>
        </>
      )}
      <SectionStepFields
        isFirstStep={stepDetails.isFirstStep}
        step={stepDetails.step}
        setNextStage={setNextStage}
        nextStage={nextStage}
        selectedCouncil={selectedCouncil}
      />
      {stepDetails.isLastAreaOfFocus && (
        <>
          <div>
            <Label>Technology</Label>
            <DropDownNestedElements
              tags={topics}
              selectedTags={formikProps.values["topic_ids"]}
              childKey="children_topics"
              label="Select technologies"
              tagKeyName="id"
              parentKeyName="parent_resource_topic_id"
              handleTagSelect={(arg) => {
                if (formikProps) {
                  formikProps.setFieldValue("topic_ids", [
                    ...formikProps.values["topic_ids"],
                    arg,
                  ]);
                }
              }}
              handleTagRemove={(arg) => {
                if (formikProps) {
                  formikProps.setFieldValue("topic_ids", [
                    ...formikProps.values["topic_ids"].filter(
                      (el) => el !== arg
                    ),
                  ]);
                }
              }}
              useCustomOptionRenderer
            />
          </div>
          {selectedCouncil?.name !== "Ford" && (
            <div>
              <Label>
                {selectedCouncil?.name === "Freddie Mac"
                  ? "Housing Cycle"
                  : "Industries"}
              </Label>
              <DropDownNestedElements
                tags={industries}
                selectedTags={formikProps.values["industry_ids"]}
                label="Select industries"
                childKey="children_industries"
                tagKeyName="id"
                parentKeyName="parent_resource_industry_id"
                handleTagSelect={(arg) => {
                  if (formikProps) {
                    formikProps.setFieldValue("industry_ids", [
                      ...formikProps.values["industry_ids"],
                      arg,
                    ]);
                  }
                }}
                handleTagRemove={(arg) => {
                  if (formikProps) {
                    formikProps.setFieldValue("industry_ids", [
                      ...formikProps.values["industry_ids"].filter(
                        (el) => el !== arg
                      ),
                    ]);
                  }
                }}
                useCustomOptionRenderer
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(StepWrapper);

import React, { useMemo } from "react";

import styles from "./company.module.scss";
import { Button, BtnType } from "../../../primitives";
import { useCompanyContext } from "modules/companies/companyMain/providers/companyDataProvider";

const CompanyTags = ({ company, tagsForGlobal }) => {
  const {
    clickOnTag,
    selectedTopics,
    selectedIndustries,
    selectedCustomTags,
  } = useCompanyContext();

  const checkedTags = useMemo(() => [
    ...selectedTopics,
    ...selectedIndustries,
    ...selectedCustomTags,
  ], [selectedTopics, selectedIndustries, selectedCustomTags])

  return (
    <div className={styles.tagsWrp}>
      {/* business units */}
      {company?.business_unit_names?.length ? (
        <div className={styles.tagsCon}>
          {company?.business_unit_names.map((business, index) => {
            return (
              <Button
                key={business?.id || `${business}_${index}`}
                btn={BtnType.REGULAR}
                rounded
                className={styles.tag}
              >
                {business}
              </Button>
            );
          })}
        </div>
      ) : null}
      {company?.custom_tag_groups?.length ? (
        <>
          {company?.custom_tag_groups.map((group, groupIndex) => {
            return (
              <div
                className={styles.tagsCon}
                key={`${group.name}_${groupIndex}`}
              >
                <>
                  {group.name === "Markets Served" &&
                    tagsForGlobal === group?.custom_tags.length && (
                      <>
                        <Button
                          btn={BtnType.TAG_BLUE}
                          rounded
                          className={styles.tag}
                          onClick={(e) => {
                            // clickOnTag(e, topic.id, "custom_tags_ids")
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          Global
                        </Button>
                      </>
                    )}
                  {group?.custom_tags.map((tag) => {
                    const isTagSelected = checkedTags.some(
                      (t) => t.id === tag.id
                    );
                    return (
                      <Button
                        key={tag.id}
                        btn={BtnType.TAG_BLUE}
                        rounded
                        className={`${styles.tag} ${
                          isTagSelected
                            ? styles.selectedTopics
                            : [styles.topicTag, styles.topicTagWithHover]
                        }`}
                        onClick={(e) => {
                          clickOnTag(
                            e,
                            tag.id,
                            "custom_tags_ids",
                            group.id
                          );
                        }}
                        buttonColor={
                          !isTagSelected
                            ? {
                              backgroundColor: `${
                                group?.bg_color || "#0f0"
                              }`,
                              color: `${group?.text_color || "#000"}`,
                            }
                            : {}
                        }
                      >
                        {tag.name}
                      </Button>
                    );
                  })}
                </>
              </div>
            );
          })}
        </>
      ) : null}
      {/* business units */}
      {company?.resource_topics?.length ||
      company?.topics_names?.length ? (
        <div className={styles.tagsCon}>
          {/* technology */}
          {company.resource_topics &&
            company.resource_topics.map(({ topic }, inx) => (
              <Button
                key={inx}
                btn={
                  checkedTags.some((t) => t.id === topic.id)
                    ? BtnType.TAG_BLUE
                    : BtnType.TAG_BLUE_LIGHT
                }
                rounded
                className={`${styles.tag} ${
                  checkedTags.some((t) => t.id === topic.id)
                    ? styles.selectedTopics
                    : [styles.topicTag, styles.topicTagWithHover]
                }`}
                onClick={(e) => clickOnTag(e, topic.id, "topic")}
              >
                {topic.name}
              </Button>
            ))}
          {/* TODO Need to ask backend dev to make same response structure for companies search request */}
          {company.topics_names &&
            company.topics_names.map((topic, inx) => (
              <Button
                key={inx}
                btn={
                  checkedTags.some((t) => t.id === topic.id)
                    ? BtnType.TAG_BLUE
                    : BtnType.TAG_BLUE_LIGHT
                }
                rounded
                className={`${styles.tag} ${
                  checkedTags.some((t) => t.id === topic.id)
                    ? styles.selectedTopics
                    : ""
                }`}
                onClick={(e) => clickOnTag(e, topic.id, "topic")}
              >
                {topic}
              </Button>
            ))}
        </div>
      ) : null}
      {company?.resource_industries?.length ||
      company?.industries_names?.length ? (
        <div className={styles.tagsCon}>
          {/* Industry */}
          {company.resource_industries &&
            company.resource_industries?.map(({ industry }, inx) => (
              <Button
                key={inx}
                btn={
                  checkedTags.some((i) => i.id === industry.id)
                    ? BtnType.TAG_BLUE
                    : BtnType.TAG_OUTLINE_BLUE
                }
                rounded
                className={`${styles.tag} ${
                  checkedTags.some((t) => t.id === industry.id)
                    ? styles.selectedIndustries
                    : ""
                }`}
                onClick={(e) => clickOnTag(e, industry.id, "industry")}
              >
                {industry.name}
              </Button>
            ))}

          {company.industries_names &&
            company.industries_names.map((industry, inx) => (
              <Button
                key={inx}
                btn={
                  checkedTags.some((i) => i.id === industry.id)
                    ? BtnType.TAG_BLUE
                    : BtnType.TAG_OUTLINE_BLUE
                }
                rounded
                className={`${styles.tag} ${
                  checkedTags.some((t) => t.id === industry.id)
                    ? styles.selectedIndustries
                    : ""
                }`}
                onClick={(e) => clickOnTag(e, industry.id, "industry")}
              >
                {industry}
              </Button>
            ))}
        </div>
      ) : null}
      {company?.resource_therapeutic_areas?.length ? (
        <div className={styles.tagsCon}>
          {/* Custom set of tags  */}
          {company.resource_therapeutic_areas &&
            company.resource_therapeutic_areas.map(
              ({ therapeutic_area }, inx) => (
                <Button
                  key={inx}
                  btn={BtnType.TAG_BLUE}
                  rounded
                  className={[styles.tag, styles.resourceTag]}
                >
                  {therapeutic_area.name}
                </Button>
              )
            )}
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(CompanyTags);

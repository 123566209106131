import {
  BtnType,
  Button,
  pageSettings,
} from "modules/primitives/index";
import React, { useState, useEffect } from "react";
import classNames from "../../../../../../node_modules/classnames/index";
import styles from "./videos.module.scss";

const VideosComponent = ({ company, videoJSON }) => {
  const [pageSetting, setPageSetting] = useState(pageSettings(10));
  // const videos = company && company.promo_video && company.promo_video !== [] && company.promo_video !== '[]' ? JSON.parse(company.promo_video) : [];
  const videoExtensions = [
    "mp4",
    "mov",
    "wmv",
    "flv",
    "avi",
    "avi",
    "webm",
    "mkv",
  ];

  const [videoIndex, setCurentVideoIndex] = useState(0);
  const [isEmbedded, setIsEmbedded] = useState();
  const [videoUrl, setVideoUrl] = useState("");

  const handlePagingChangePrev = () => {
    if (videoIndex > 0) {
      setCurentVideoIndex((prev) => prev - 1);
    } else {
      alert("This is first video");
    }
  };

  const getUrlExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  const videos = JSON.parse(videoJSON);

  const handlePagingChangeNext = () => {
    if (videoIndex < videos.length - 1) {
      setCurentVideoIndex((prev) => prev + 1);
    } else {
      alert("This is the last page");
    }
  };

  const checkPlatform = () => {
    const url = videos[videoIndex];
    const regExpYoutube = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const regExpVimeo = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    const regExpFacebook = /^https?:\/\/www\.facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;

    if (url.includes("iframe")) {
      return "iframe";
    }
    if (url.match(regExpYoutube)) {
      return "youtube";
    }
    if (url.match(regExpVimeo)) {
      return "vimeo";
    }
    if (url.match(regExpFacebook)) {
      return "facebook";
    }
  };

  const getYoutubeId = (url) => {
    let ID = "";
    url = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    } else {
      ID = url;
    }
    return ID;
  };

  const getVimeoId = (url) => {
    const match = /vimeo.*\/(\d+)/i.exec(url);

    if (match) {
      return match[1];
    }
  };

  const getFacebookId = (url) => {
    const match = /facebook.com.*\/(\d+)/i.exec(url);
    if (match) {
      return match[1];
    }
  };

  const getIframeSrc = (url) => {
    const match = /src\s*=\s*"([^"]+)"/i.exec(url);
    if (match) {
      return match[1];
    }
  };

  const handleIsEmbedded = () => {
    if (videoExtensions.indexOf(getUrlExtension(videos[videoIndex])) === -1) {
      setIsEmbedded(true);
      if (checkPlatform() === "youtube") {
        setVideoUrl(
          `https://www.youtube.com/embed/${getYoutubeId(videos[videoIndex])}`,
        );
      }
      if (checkPlatform() === "vimeo") {
        setVideoUrl(
          `https://player.vimeo.com/video/${getVimeoId(videos[videoIndex])}`,
        );
      }
      if (checkPlatform() === "facebook") {
        setVideoUrl(
          `https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTMNTfilm%2Fvideos%2Fvb.1398909827041343%2F${getFacebookId(
            videos[videoIndex],
          )}%2F%3Ftype%3D3&show_text=0&width=889&height=500`,
        );
      }
      if (checkPlatform() === "iframe") {
        setVideoUrl(getIframeSrc(videos[videoIndex]));
      }
    } else {
      setIsEmbedded(false);
    }
  };

  useEffect(() => {
    if (videos[videoIndex]) {
      handleIsEmbedded();
    }
  }, [videoIndex, company]);

  useEffect(() => {
    setPageSetting((prev) => ({ ...prev, total: 20 }));
  }, []);
  return (
    <div className={styles.panelWrp}>
      <div className={classNames(styles.panel, styles.vedioPanel)}>
        {company && videos && videos && !isEmbedded ? (
          <div className={styles.videoCon}>
            <video
              width="100%"
              controls
              loop
              autoPlay=""
              key={videos[videoIndex]}
            >
              <source src={company && videos && videos[videoIndex]} />
              Your browser does not support video.
            </video>
          </div>
        ) : (
          <div className={styles.iframeContainer}>
            {videos && videos[videoIndex] && checkPlatform() === "facebook" ? (
              <iframe
                src={videoUrl}
                width="100%"
                height="500"
                className={styles.responsiveIframe}
              />
            ) : (
              <iframe
                width="100%"
                height="480"
                src={videoUrl}
                frameBorder="0"
                className={styles.responsiveIframe}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded videos"
              />
            )}
          </div>
        )}
        {company && videos && videos.length > 1 && (
          <div className={styles.controlsWrp}>
            <div className={styles.totals}>
              {`${videoIndex + 1} of  ${videos.length}`}{" "}
            </div>
            <div className={styles.buttonWrp}>
              <Button
                btn={BtnType.FRAME_LESS}
                className={styles.SeeAllSpeakers}
                onClick={() => {
                  handlePagingChangePrev();
                }}
              >
                Prev
              </Button>
              <Button
                btn={BtnType.FRAME_LESS}
                className={styles.SeeAllSpeakers}
                onClick={() => {
                  handlePagingChangeNext();
                }}
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(VideosComponent);

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem";

import styles from "./ideaProfileHeaderActions.module.scss";
import { editIdeaAttempt } from "../../idea.action";
import ConfirmActionComponent from "../../../../common/components/confirmAction/confirmAction.component";
import { appModelSettingSet } from "application/app.action";
import { KYNDRYL_STATUSES } from "../constants";

const userButtonsByStatus = {
  submitted: {
    name: "Submitted",
    type: "submit",
    background: "",
  },
  stopped: {
    name: "Stopped",
    type: "stop",
    background: "#CF1124",
  },
  approved: {
    name: "Approved",
    type: "approve",
    background: "#3f9142",
  },
  paused: {
    name: "Paused",
    type: "pause",
    background: "#F7C948",
  },
  scouting: {
    name: "Scouting",
    type: "scouting",
    background: "#166086",
  },
  solved: {
    name: "Solved",
    type: "solved",
    background: "#323F4B",
  },
};

const IdeaProfileHeaderActions = ({ canEdit, selectedIdea }) => {
  const { status } = selectedIdea;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const handleStatusSelect = (status) => {
    if (status === "solved" || status === "scouting") {
      handleStatusChangeConfirm(
        selectedIdea.last_status_change?.reason,
        status
      );

      return;
    }

    const customSettings = {
      title: "Reason for action",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmActionComponent
          alertText="Please state your reason below"
          showConfirmationMsg
          confirmBtnName="Save"
          onConfirm={(reason) => handleStatusChangeConfirm(reason, status)}
        />
      ),
    };
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  const handleStatusChangeConfirm = (reason, status) => {
    if (!reason || !reason.length) {
      enqueueSnackbar("Please provide a reason.", {
        variant: "warning",
      });

      return;
    }

    const payload = {
      idea: {
        id: selectedIdea.id,
        ideas_template_id: selectedIdea.ideas_template_id,
        status,
        reason,
      },
      cb: () => {
        enqueueSnackbar("Successfully changed the status.", {
          variant: "success",
        });
      },
      enqueueSnackbar,
    };

    dispatch(editIdeaAttempt(payload));
  };

  const handleKyndrylStatusChange = (s) => {
    if (s.target.value === status) {
      return;
    }

    handleStatusSelect(s.target.value);
  };

  return (
    <div
      className={`d-flex ${
        canEdit ? "justify-content-between" : "justify-content-center"
      } align-items-center mt-3`}
    >
      {selectedCouncil?.name === "Kyndryl" && status !== "submitted" ? (
        canEdit ? (
          <Select
            value={status}
            onClick={(e) => e.stopPropagation()}
            onChange={handleKyndrylStatusChange}
            className={styles.kyndrylDrodown}
            style={{
              backgroundColor: userButtonsByStatus[status].background,
              color: "white",
            }}
            disableUnderline
          >
            {KYNDRYL_STATUSES.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.name}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <button
            disabled
            className={`${styles.genericBtnStyles} ${styles.selected} ${
              styles[userButtonsByStatus[status].type]
            }`}
          >
            {userButtonsByStatus[status].name}
          </button>
        )
      ) : canEdit ? (
        <>
          <button
            className={`${styles.genericBtnStyles} ${
              status === "approved" ? styles.selected : ""
            } ${styles.approve}`}
            onClick={() => handleStatusSelect("approved")}
            disabled={status === "approved"}
          >
            {status === "approved" ? <>Approved &#10003;</> : "Approve"}
          </button>
          <button
            className={`${styles.genericBtnStyles} ${
              status === "paused" ? styles.selected : ""
            } ${styles.pause}`}
            onClick={() => handleStatusSelect("paused")}
            disabled={status === "paused"}
          >
            {status === "paused" ? (
              <>
                {selectedCouncil?.traction_tag === "home_x" ? (
                  <>Parked &#10003;</>
                ) : (
                  <>Paused &#10003;</>
                )}
              </>
            ) : (
              <>
                {selectedCouncil?.traction_tag === "home_x" ? "Park" : "Pause"}
              </>
            )}
          </button>
          <button
            className={`${styles.genericBtnStyles} ${
              status === "stopped" ? styles.selected : ""
            } ${styles.stop}`}
            onClick={() => handleStatusSelect("stopped")}
            disabled={status === "stopped"}
          >
            {status === "stopped" ? <>Stopped &#10003;</> : "Stop"}
          </button>
        </>
      ) : (
        <button
          disabled
          className={`${styles.genericBtnStyles} ${styles.selected} ${
            styles[userButtonsByStatus[status].type]
          }`}
        >
          {userButtonsByStatus[status].name}
        </button>
      )}
    </div>
  );
};

export default React.memo(IdeaProfileHeaderActions);

import React, { useState, useEffect } from "react";
import { Pannel, Loading, Button, BtnType } from "../../primitives";
import { numberWithCommas } from "../../../common/helper";
import styles from "./leaderboard.module.scss";
import { httpGet } from "../../../common/httpCall";
import { trendindTechColumns } from "./leaderboard.constants";

const TrandingTechTableComponent = ({
  enqueueSnackbar,
  selectedCouncil,
  getSortDirectionStytles,
  getSortStyles,
  prepareDataName,
}) => {
  const [trendingTech, setTrendingTechnologies] = useState([]);
  const [topTrendTechSort, setTopTrendTechSort] = useState({
    sort_attribute: "topic_projects_savings",
    sort_order: "desc",
  });
  const [loading, setLoading] = useState(false);

  const getStats = () => {
    setLoading(true);

    try {
      httpGet({
        call: "ford/leaderboards/trending_technologies",
        apiVersion: "v1",
      }).subscribe(
        async (res) => {
          setTrendingTechnologies(res.response);
          setLoading(false);
        },
        (err) => {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
          setLoading(false);
        }
      );
    } catch (error) {
      console.log("---------error", error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCouncil) {
      getStats({
        id: selectedCouncil,
        enqueueSnackbar,
      });
    }
  }, [selectedCouncil]);

  const setSortAttr = (sortAttr, table) => {
    const sortingFunction = (techologies) => {
      const newArr = [...techologies];

      const sorted = newArr.sort((a, b) => {
        if (a[sortAttr] < b[sortAttr]) {
          return -1;
        } else if (a[sortAttr] > b[sortAttr]) {
          return 1;
        }
        return 0;
      });
      return topTrendTechSort.sort_order === "asc" ? sorted : sorted.reverse();
    };

    setTopTrendTechSort((prev) => {
      if (prev.sort_order === "desc") {
        return {
          sort_attribute: sortAttr,
          sort_order: "asc",
        };
      } else {
        return {
          sort_attribute: sortAttr,
          sort_order: "desc",
        };
      }
    });
    setTrendingTechnologies(sortingFunction);
  };

  return (
    <div className={"col-md-12 col-lg-4"}>
      <Pannel
        title=""
        padding="0"
        parentStyle={{
          background: "Transparent",
          // marginRight: window.screen.width > 550 ? 0 : 0,
          padding: window.screen.width > 550 ? 7 : 0,
        }}
        position="relative"
        overflow="hidden"
      >
        <div className={styles.wrapper}>
          <div className={`row ${styles.grid}`}>
            <div className={`col-12 ${styles.dataTableContainer}`}>
              <div className={styles.gridHeader}>
                <div className={styles.headerTitle}>
                  Top 25 Technologies by <b>Savings</b>
                </div>
              </div>
              <div className={styles.dataTable}>
                <div
                  className={`${styles.tableHeaderRow} ${styles.techHeader}`}
                >
                  {trendindTechColumns.map((header) => {
                    return (
                      <div
                        key={header.columnName}
                        className={` ${
                          styles.dataTableHeader
                        } ${header.columnStyles.map(
                          (customClass) => customClass
                        )} ${getSortStyles(
                          header.columnSortKey,
                          "topTrendTechSort",
                          topTrendTechSort.sort_attribute
                        )} ${header.sortable && styles.cursorpointer}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (header.sortable) {
                            setSortAttr(
                              header.columnSortKey,
                              "topTrendTechSort"
                            );
                          }
                        }}
                      >
                        <span
                          className={`${getSortDirectionStytles(
                            header.columnSortKey,
                            "topTrendTechSort",
                            topTrendTechSort.sort_attribute,
                            topTrendTechSort.sort_order
                          )} `}
                        >
                          {header.columnName}{" "}
                        </span>
                      </div>
                    );
                  })}
                </div>

                <div className={` ${styles.projSavingsTableBodyContainer}`}>
                  {trendingTech?.map((tech, i) => {
                    return (
                      <div
                        // row ${styles.projectRow}
                        className={` ${styles.techRow}`}
                        key={tech.topic_id}
                      >
                        <div className={styles.rowNumber}>
                          {" "}
                          <span>{i + 1}</span>{" "}
                        </div>

                        <div
                          className={` ${styles.cellItem} ${styles.techName}`}
                        >
                          <Button
                            key={tech.id}
                            btn={BtnType.TAG_BLUE_LIGHT}
                            rounded
                            className={styles.techItemContainer}
                          >
                            {prepareDataName(tech.topic_name)}
                          </Button>
                        </div>
                        <div className={` ${styles.cellItem}`}>
                          {tech.topic_projects_count}
                        </div>
                        <div
                          className={` ${styles.cellItem} ${styles.lastCol}`}
                        >
                          ${" "}
                          {numberWithCommas(tech.topic_projects_savings) || "0"}
                        </div>
                      </div>
                    );
                  })}
                </div>

                {loading && <Loading />}
              </div>
            </div>
          </div>
        </div>
      </Pannel>
    </div>
  );
};

export default React.memo(TrandingTechTableComponent);

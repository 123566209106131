import React, {
  memo, useEffect, useState, useRef, useCallback,
} from "react";
import { debounce } from "throttle-debounce";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import classNames from "classnames";
import ChartsIntro from "../chartsIntro";
import BubbleChart from "./charts/bubbleChart";
import PieChart from "./charts/pieChart";
import BarChart from "./charts/barChart";
import BubbleFilters from "./filters/bubbleFilters";
import PieFilters from "./filters/pieFilters";
import BarFilters from "./filters/barFilters";
import BubbleToggle from "./filters/bubbleToggle";
import { DropSelect } from "../../../../primitives";
import { CHARTS_LIST, DEF_BUBBLE_OPTIONS } from "./charts-constants";
import styles from "./chartsView.module.scss";
import {
  makeBubbleChartConfig,
  makeFlattenRelationShipTags,
} from "./charts/bubbleChart/bubbleUtils";
import {
  getChart,
  initializeChart,
  makeFlattenTags,
  makeFlattenCustomTags,
  flat,
} from "./charts/utils";
import {
  setChartsAreReRendering,
  setChartsViewType,
  setChartType,
  updateBubbleChartOptions,
} from "../../companyMain.action";
import {
  queryParamStringToArray,
  waitForElm,
} from "../../../../../common/helper";
import { makePieChartConfig } from "./charts/pieChart/pieUtils";
import { makeBarChartConfig } from "./charts/barChart/barUtils";

const ChartsView = ({
  companies,
  selectedTags,
  handleSelectTags,
  dispatch,
  location,
}) => {
  const ref = useRef();
  const history = useHistory();

  const {
    appReducer: { chartsDownloadIsActive, relationShipStatuses },
    companiesReducer: {
      companyMainReducer: {
        industries,
        topics,
        charts: {
          selectedChartType,
          chartOptions,
          viewType,
          chartsIntroIsActive,
          chartShouldRerender,
        },
      },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [tags, setTags] = useState(null);
  const [chart, setChart] = useState(null);
  const [wrapperWidth, setWrapperWidth] = useState(null);
  const [showTags, setShowTags] = useState(true);
  const [showAlertMessage, setShowAlertMessage] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", debounceResize);

    return () => {
      window.removeEventListener("resize", null);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      setWrapperWidth(ref.current.clientWidth);
    }
  }, [ref.current]);

  useEffect(() => {
    if (!companies?.length || !chartOptions || !relationShipStatuses.length) return;

    const { color, x } = chartOptions;
    const isCustomPieBreakDown = color === "totalFunding"
      || color === "amountOfLastRound"
      || color === "score"
      || color === "numberOfEmployees"
      || color === "revenue_range"
      || color === "yearFounded";

    if (
      isCustomPieBreakDown
      || (x === "yearFounded" && selectedChartType === "bar")
      || (x === "numberOfEmployees" && selectedChartType === "bar")
      || (x === "revenue_ranges" && selectedChartType === "bar")
    ) {
      const flattenTags = makeFlattenCustomTags(
        companies,
        chartOptions,
        selectedChartType === "bar",
        false,
        color === "revenue_range",
      );
      setTags(flattenTags);
      return;
    }

    if (color !== "relationShipStatus") {
      let customPieData = [];

      if (selectedChartType === "pie" || selectedChartType === "bar") {
        if (color === "technology") {
          customPieData = flat(topics, "children_topics");
        }

        if (color === "industry") {
          const flattIndustries = flat(industries, "children_industries");
          customPieData = [...customPieData, ...flattIndustries];
        }
      }

      if (location.state?.topic || location.state?.industry) {
        const topicIdsFromQuery = queryParamStringToArray(
          location.state?.topic || "",
        );
        const industryIdsFromQuery = queryParamStringToArray(
          location.state?.industry || "",
        );
        const combinedIds = [...topicIdsFromQuery, ...industryIdsFromQuery];
        const flattenTags = makeFlattenTags(
          companies,
          chartOptions,
          selectedChartType,
          customPieData,
        );

        if (!combinedIds.length) {
          setTags(flattenTags);
          return;
        }

        const filteredFlattenTags = flattenTags
          .filter((t) => combinedIds.includes(t.id))
          .map((t) => ({ ...t, selected: true, unselectable: false }));

        setTags(filteredFlattenTags);
        return;
      }

      const flattenTags = makeFlattenTags(
        companies,
        chartOptions,
        selectedChartType,
        customPieData,
      );
      setTags(flattenTags);
    }

    if (color === "relationShipStatus") {
      const relationshipStagesFromQuery = queryParamStringToArray(
        location.state?.relationShipStatus || "",
      );
      const flattenTags = makeFlattenRelationShipTags(
        companies,
        relationShipStatuses,
      );
      const filteredFlattenTags = relationshipStagesFromQuery.length
        ? flattenTags.filter((tag) => relationshipStagesFromQuery.some((slug) => slug === tag.slug))
        : flattenTags;

      setTags(filteredFlattenTags);
    }

    return () => null;
  }, [
    companies,
    relationShipStatuses,
    chartOptions,
    location.state,
    selectedChartType,
    topics,
    industries,
  ]);

  useEffect(() => {
    if (chart) {
      if (selectedChartType === "pie") {
        const chartElem = document.getElementById("pieChart");

        if (!chartElem) return;

        chartElem.onclick = (evt) => {
          const points = chart.getElementsAtEventForMode(
            evt,
            "nearest",
            { intersect: true },
            true,
          );

          if (!points.length) return;

          const firstPoint = points[0];
          const dataSet = chart.data.datasets[0];
          const value = chart.data.datasets[firstPoint.datasetIndex].customDataPerIndex[
            firstPoint.index
          ];
          const newTags = tags.map((t) => {
            if (t.id === value.id) {
              const isAlreadySelected = selectedTags?.some(
                (t1) => t1.id === value.id,
              );

              return {
                ...t,
                selected: !isAlreadySelected,
              };
            }

            const isAlreadySelected = selectedTags?.some(
              (t1) => t1.id === t.id,
            );

            return {
              ...t,
              selected: isAlreadySelected,
            };
          });

          const filterSelectedTags = newTags.filter((t) => t.selected);

          dataSet.customDataPerIndex.forEach((el, idx) => {
            if (!filterSelectedTags.length) {
              dataSet.backgroundColor[idx] = dataSet.customDataPerIndex[idx].originalColor;
            } else {
              const getTag = newTags.find((tag) => el.id === tag.id);

              if (getTag.selected) {
                dataSet.backgroundColor[idx] = getTag.color;
              } else {
                dataSet.backgroundColor[idx] = "#999999";
              }
            }
          });

          chart.update();

          handleSelectTags(newTags);
        };

        return;
      }

      if (selectedChartType === "bar") {
        const chartElem = document.getElementById("barChart");

        if (!chartElem) return;

        chartElem.onclick = (evt) => {
          const points = chart.getElementsAtEventForMode(
            evt,
            "nearest",
            { intersect: true },
            true,
          );

          if (!points.length) return;

          const firstPoint = points[0];

          if (chartOptions.x === "companies") {
            const value = chart.data.datasets[firstPoint.datasetIndex].customDataPerIndex[
              firstPoint.index
            ];

            if (value) {
              localStorage.setItem("company", JSON.stringify(value));
              history.push(`/companies/${value?.slug || value?.id}/overview`);
            }

            return;
          }

          const value = chart.data.datasets[firstPoint.datasetIndex].customDataPerIndex[
            firstPoint.index
          ];
          const newTags = tags.map((t) => {
            if (t.id === value.id) {
              const isAlreadySelected = selectedTags.some(
                (t1) => t1.id === value.id,
              );
              return {
                ...t,
                selected: !isAlreadySelected,
              };
            }

            const isAlreadySelected = selectedTags.some((t1) => t1.id === t.id);

            return {
              ...t,
              selected: isAlreadySelected,
            };
          });

          handleSelectTags(newTags);
          askForChartRedraw(newTags);
        };
      }

      if (selectedChartType === "bubble") {
        const chartElem = document.getElementById("bubbleChart");

        if (!chartElem) return;

        chartElem.onclick = (evt) => {
          const points = chart.getElementsAtEventForMode(
            evt,
            "nearest",
            { intersect: true },
            true,
          );

          if (!points.length) return;

          const firstPoint = points[0];
          const value = chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];

          if (viewType === "aggregate") {
            const newTags = tags.map((t) => {
              if (t.id === value.id) {
                const isAlreadySelected = selectedTags.some(
                  (t1) => t1.id === value.id,
                );
                return {
                  ...t,
                  selected: !isAlreadySelected,
                };
              }

              const isAlreadySelected = selectedTags.some(
                (t1) => t1.id === t.id,
              );

              return {
                ...t,
                selected: isAlreadySelected,
              };
            });

            handleSelectTags(newTags);
            askForChartRedraw(newTags);

            return;
          }

          const getClickedCompany = companies.find((c) => c.id === value.id);

          if (getClickedCompany) {
            localStorage.setItem("company", JSON.stringify(getClickedCompany));
            history.push(`/companies/${getClickedCompany.slug || getClickedCompany.id}/overview`);
          }
        };
      }
    }

    return () => null;
  }, [
    chart,
    selectedChartType,
    tags,
    selectedTags,
    handleSelectTags,
    companies,
    history,
    viewType,
  ]);

  useEffect(() => {
    if (wrapperWidth && !chart && companies && chartOptions) {
      if (chartOptions.color === "technology" && topics?.length) {
        makeChartConfig({
          ...chartOptions,
          toggleView: viewType,
        });
      }

      if (chartOptions.color === "industry" && industries?.length) {
        makeChartConfig({
          ...chartOptions,
          toggleView: viewType,
        });
      }
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [
    wrapperWidth,
    chart,
    companies,
    chartOptions,
    selectedChartType,
    viewType,
    topics,
    industries,
  ]);

  const debounceResize = debounce(1000, false, () => {
    if (ref?.current) {
      setWrapperWidth(ref.current.clientWidth);
    }
  });

  const handleChartOptionsSelect = useCallback(
    (prop, value, bulk) => {
      dispatch(
        setChartsAreReRendering({
          state: true,
        }),
      );

      if (bulk) {
        dispatch(updateBubbleChartOptions(bulk));

        if (chart) {
          chart.destroy();
        }

        const tagsAreSelected = selectedTags?.length > 0;

        if (tagsAreSelected) {
          handleSelectTags([]);
          makeChartConfig(bulk, []);
          return;
        }

        makeChartConfig(bulk, selectedTags);

        return;
      }

      let newOptions;
      const isYearsSelect = prop === "x" && value === "yearFounded";

      if (isYearsSelect) {
        newOptions = {
          ...chartOptions,
          toggleView: viewType,
          [prop]: value,
          color: "year",
        };
      } else {
        const wasYearSelect = chartOptions.x === "yearFounded";

        if (wasYearSelect) {
          newOptions = {
            ...chartOptions,
            toggleView: viewType,
            [prop]: value,
            color: "technology",
          };
        } else {
          newOptions = {
            ...chartOptions,
            toggleView: viewType,
            [prop]: value,
          };
        }
      }

      dispatch(updateBubbleChartOptions(newOptions));

      if (chart) {
        chart.destroy();
      }

      const tagsAreSelected = selectedTags?.length > 0;

      if (tagsAreSelected) {
        handleSelectTags([]);
        makeChartConfig(newOptions, []);
        return;
      }

      makeChartConfig(newOptions, selectedTags);
    },
    [
      selectedChartType,
      chart,
      chartOptions,
      companies,
      selectedTags,
      dispatch,
      viewType,
    ],
  );

  const handleBubbleToggleChange = useCallback(
    (toggleView) => {
      const newOptions = {
        ...chartOptions,
        size:
          toggleView === "aggregate"
            ? "numberOfCompanies"
            : "last_funding_amount_usd",
        toggleView,
      };

      dispatch(updateBubbleChartOptions(newOptions));

      dispatch(
        setChartsViewType({
          viewType: toggleView,
        }),
      );

      if (chart) {
        chart.destroy();
      }

      makeChartConfig(newOptions, selectedTags);
    },
    [chart, chartOptions, companies, selectedTags, dispatch, selectedChartType],
  );

  const handleTagsShow = useCallback(() => {
    setShowTags(!showTags);

    const timeout = setTimeout(() => {
      dispatch(
        setChartsAreReRendering({
          state: false,
        }),
      );
      clearTimeout(timeout);
    }, 1000);
  }, [
    chart,
    chartOptions,
    companies,
    selectedTags,
    viewType,
    selectedChartType,
    showTags,
  ]);

  const askForChartRedraw = useCallback(
    (filterBy) => {
      // if (!chart) return;
      handleSelectTags(filterBy);
      makeChartConfig(
        {
          ...chartOptions,
          toggleView: viewType,
        },
        filterBy,
      );
    },
    [chartOptions, companies, selectedTags, viewType, chart],
  );

  const makeChartConfig = useCallback(
    (options, filterBy) => {
      if (!companies || !options) return;

      switch (selectedChartType) {
        case "bubble":
          {
            const chartConfig = makeBubbleChartConfig(
              companies,
              options,
              filterBy || tags || [],
              relationShipStatuses,
            );

            waitForElm("#bubbleChart").then(() => {
              const chartInstanceById = getChart("bubbleChart");

              if (chartInstanceById) chartInstanceById.destroy();

              const newChart = initializeChart(
                "bubbleChart",
                "bubble",
                chartConfig,
              );

              setChart(newChart);

              const timeout = setTimeout(() => {
                dispatch(
                  setChartsAreReRendering({
                    state: false,
                  }),
                );

                clearTimeout(timeout);
              }, 2000);
            });
          }
          break;

        case "bar":
          {
            let councilTags = [];

            if (options.color === "technology") {
              councilTags = flat(topics || [], "children_topics");
            }

            if (options.color === "industry") {
              councilTags = flat(industries || [], "children_industries");
            }

            if (options.color === "relationshipStatus") {
              councilTags = relationShipStatuses;
            }

            const chartConfig = makeBarChartConfig(
              companies,
              options,
              filterBy || tags || [],
              relationShipStatuses,
              councilTags,
            );

            waitForElm("#barChart").then(() => {
              const chartInstanceById = getChart("barChart");

              if (chartInstanceById) chartInstanceById.destroy();

              const newChart = initializeChart("barChart", "bar", chartConfig);

              setChart(newChart);

              const timeout = setTimeout(() => {
                dispatch(
                  setChartsAreReRendering({
                    state: false,
                  }),
                );

                clearTimeout(timeout);
              }, 2000);
            });
          }
          break;

        case "pie": {
          let councilTags = [];

          if (options.color === "technology") {
            councilTags = flat(topics || [], "children_topics");
          }

          if (options.color === "industry") {
            councilTags = flat(industries || [], "children_industries");
          }

          const chartConfig = makePieChartConfig(
            companies,
            options,
            filterBy || tags || [],
            relationShipStatuses,
            councilTags,
          );

          waitForElm("#pieChart").then(() => {
            const chartInstanceById = getChart("pieChart");

            if (chartInstanceById) chartInstanceById.destroy();

            const newChart = initializeChart("pieChart", "pie", chartConfig);

            setChart(newChart);

            const timeout = setTimeout(() => {
              dispatch(
                setChartsAreReRendering({
                  state: false,
                }),
              );

              clearTimeout(timeout);
            }, 2000);
          });
        }
      }
    },
    [
      selectedChartType,
      companies,
      chartOptions,
      relationShipStatuses,
      tags,
      chart,
      industries,
      topics,
      showTags,
    ],
  );

  const returnChartTypeNode = useCallback(
    (width) => {
      if (selectedChartType && companies) {
        const customWidth = showTags ? width - 170 : width;

        switch (selectedChartType) {
          case "bubble": {
            if (chart && chart.width !== customWidth) {
              chart.width = customWidth;
              chart.update();
            }

            return (
              <BubbleChart
                companies={companies}
                selectedOptions={chartOptions}
                width={customWidth}
                handleTagsShow={handleTagsShow}
                askForRedraw={askForChartRedraw}
                chartHeight={500}
                selectedTags={selectedTags}
                showTags={showTags}
              />
            );
          }

          case "bar": {
            if (chart && chart.width !== customWidth) {
              chart.width = customWidth;
              chart.update();
            }

            return (
              <BarChart
                companies={companies}
                selectedOptions={chartOptions}
                width={customWidth}
                handleTagsShow={handleTagsShow}
                askForRedraw={askForChartRedraw}
                chartHeight={500}
                selectedTags={selectedTags}
                industries={industries}
                topics={topics}
                showTags={showTags}
              />
            );
          }

          case "pie": {
            if (chart && chart.width !== customWidth) {
              chart.width = customWidth;
              chart.update();
            }

            return (
              <PieChart
                companies={companies}
                selectedOptions={chartOptions}
                width={customWidth}
                handleTagsShow={handleTagsShow}
                askForRedraw={askForChartRedraw}
                chartHeight={500}
                selectedTags={selectedTags}
                industries={industries}
                topics={topics}
                showTags={showTags}
              />
            );
          }
        }
      }
    },
    [
      companies,
      chartOptions,
      chart,
      selectedTags,
      industries,
      topics,
      showTags,
      chartShouldRerender,
    ],
  );

  const handleChartTypeChange = (evt) => {
    if (!evt?.val) return;

    if (
      (evt.val === "pie" && selectedChartType === "bar")
      || (evt.val === "pie" && selectedChartType === "bubble")
    ) {
      dispatch(updateBubbleChartOptions(DEF_BUBBLE_OPTIONS));
    }

    if (evt.val === "bar") {
      const newOptions = {
        ...chartOptions,
        size: "last_funding_amount_usd",
        x: "sectors",
      };

      dispatch(updateBubbleChartOptions(newOptions));
    }

    if (evt.val === "bubble") {
      const newOptions = {
        ...chartOptions,
        y: "totalFunding",
        size: "numberOfCompanies",
        x: "numberOfEmployees",
        color:
          (selectedChartType === "bar" || selectedChartType === "pie")
          && chartOptions.color === "revenue_range"
            ? "technology"
            : chartOptions.color,
      };

      dispatch(updateBubbleChartOptions(newOptions));
    }

    setChart(null);

    dispatch(
      setChartsAreReRendering({
        state: true,
      }),
    );

    dispatch(setChartType(evt.val));
  };

  if (chart && !chart.config?._config?.data?.datasets?.length) {
    return null;
  }

  return (
    <div ref={ref}>
      {showAlertMessage && (
        <div className={`d-flex ${styles.sameFiltersAreSelected}`}>
          This chart can only support up to 50 companies Please refine your
          search by applying some filters on the left.
        </div>
      )}
      {wrapperWidth ? (
        <>
          <div
            className={`${
              chartsDownloadIsActive ? "d-none" : "d-flex"
            } align-items-center ${
              selectedChartType === "pie" ? "justify-content-between" : ""
            }`}
          >
            <div
              className={classNames("d-flex flex-column", styles.chartsSelect)}
            >
              <label className="mb-1" htmlFor="chartType">
                Chart Type
              </label>
              <DropSelect
                placeholder=""
                name="chartType"
                options={CHARTS_LIST}
                labelField="name"
                valueField="type"
                searchBy="name"
                values={CHARTS_LIST.filter((c) => c.type === selectedChartType)}
                onChange={handleChartTypeChange}
                dropdownPosition="auto"
              />
            </div>
            {selectedChartType === "bubble" && (
              <BubbleFilters handleSelect={handleChartOptionsSelect} />
            )}
            {selectedChartType === "pie" && (
              <PieFilters handleSelect={handleChartOptionsSelect} />
            )}
            {selectedChartType === "bar" && (
              <BarFilters
                setShowAlertMessage={setShowAlertMessage}
                total={companies?.length}
                handleSelect={handleChartOptionsSelect}
              />
            )}
          </div>
          {selectedChartType !== "bar"
            && (chartOptions.x === chartOptions.y
              || chartOptions.size === chartOptions.y
              || chartOptions.x === chartOptions.size) && (
              <span className={styles.sameFiltersAreSelected}>
                You are charting the same data twice. Please select a different
                variable for each filter to generate a better chart.
              </span>
          )}
          {!chartsDownloadIsActive && selectedChartType === "bubble" && (
            <BubbleToggle handleToggleChange={handleBubbleToggleChange} />
          )}
          {selectedCouncil?.name !== "Donaldson"
            && selectedCouncil?.name !== "Ofi"
            && chart
            && chartsIntroIsActive && <ChartsIntro />}
        </>
      ) : null}
      {wrapperWidth && selectedChartType && returnChartTypeNode(wrapperWidth)}
    </div>
  );
};

export default memo(ChartsView);

import {
  COUNCIL_SELECTED_SET,
  COUNCIL_GET_SUCCESS,
  COUNCIL_LIST_GET_SUCCESS,
  COUNCIL_USER_GET_SUCCESS,
  COUNCIL_STAGES_TEMPLATE_LIST_SUCCESS,
  COUNCIL_REMOVE_MEMBER_SUCCESS,
  COUNCIL_REMOVE_MEMBER_ATTEMPT,
  COUNCIL_GET_TEAMS_ATTEMPT,
  COUNCIL_GET_TEAMS_SUCCESS,
  COUNCIL_REMOVE_TEAM_ATTEMPT,
  COUNCIL_REMOVE_TEAM_SUCCESS,
  COUNCIL_CREATE_TEAM_ATTEMPT,
  COUNCIL_CREATE_TEAM_SUCCESS,
  COUNCIL_GET_TEAM_BY_ID_ATTEMPT,
  COUNCIL_GET_TEAM_BY_ID_SUCCESS,
  COUNCIL_TEAM_ADD_MEMBER_ATTEMPT,
  COUNCIL_TEAM_ADD_MEMBER_SUCCESS,
  COUNCIL_REMOVE_TEAM_MEMBER_SUCCESS,
  COUNCIL_REMOVE_TEAM_MEMBER_ATTEMPT,
  COUNCIL_SEARCH_TEAM_MEMBER_ATTEMPT,
  COUNCIL_SEARCH_TEAM_MEMBER_SUCCESS,
  COUNCIL_SEARCH_TEAMS_ATTEMPT,
  COUNCIL_GET_ATTEMPT,
  COUNCIL_SEARCH_TEAMS_SUCCESS,
  COUNCIL_EDIT_TEAM_SUCCESS,
  COUNCIL_EDIT_TEAM_ATTEMPT,
  COUNCIL_LIST_GET_ATTEMPT,
  COUNCIL_LIST_GET_ALL_ATTEMPT,
  COUNCIL_LIST_GET_ALL_SUCCESS,
} from "./council.action";
import { apiStatus, pageMeta } from "../../common/httpCall";
import {
  COUNCIL_USERS_LIST_GET_SUCCESS,
  COUNCIL_USERS_LIST_GET_ATTEMPT,
} from "../../common/actions/common.action";
import {
  PEOPLE_ADD_MEMBERS_ATTEMPT,
  PEOPLE_ADD_MEMBERS_SUCCESS,
  PEOPLE_ADD_MEMBERS_FAIL,
  PEOPLE_EDIT_MEMBERS_SUCCESS,
} from "../people/internalPeople/internalPeople.action";

const initState = {
  selectedCouncil: null,
  councilList: [],
  allCouncilsList: [],
  usersListById: {},
  usersApiSendById: {},
  stageTemplates: [],
  usersList: [],
  updateUsers: false,
  loading: false,
  teamList: [],
  updateTeams: false,
  selectedTeam: {},
  loadTeams: false,
  councilLoading: false,
  userPageNr: {},
  teamMemberNr: {},
  invitingMember: false,
  refreshTeams: false,
};

const councilReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PEOPLE_ADD_MEMBERS_ATTEMPT: {
      return {
        ...state,
        refreshTeams: false,
        invitingMember: true,
      };
    }

    case PEOPLE_ADD_MEMBERS_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        refreshTeams: true,
        usersList: [data, ...state.usersList],
        invitingMember: false,
      };
    }

    case PEOPLE_ADD_MEMBERS_FAIL: {
      return {
        ...state,
        invitingMember: false,
      };
    }

    case COUNCIL_GET_ATTEMPT: {
      return {
        ...state,
        councilLoading: true,
      };
    }
    case COUNCIL_GET_SUCCESS: {
      const { response, status, integrated_tokens } = payload;

      if (response) {
        const msftData = response?.councils_integrations?.find(
          (elem) => elem.integration_name === "Microsoft"
        );
        const msftToken = integrated_tokens?.Microsoft;

        localStorage.setItem(
          "microsoft_login",
          Boolean(msftData?.status && msftData?.admin_connected && msftToken)
        );
      }

      if (status === apiStatus.SUCCESS) {
        localStorage.setItem("council", JSON.stringify(response));

        return {
          ...state,
          selectedCouncil: response,
          councilLoading: false,
        };
      }

      break;
    }
    case COUNCIL_SELECTED_SET: {
      localStorage.setItem("council", JSON.stringify(payload));

      const msftData = payload?.councils_integrations?.find(
        (elem) => elem.integration_name === "Microsoft"
      );
      const msftToken = payload?.integrated_tokens.Microsoft;
      localStorage.setItem(
        "microsoft_login",
        Boolean(msftData?.status && msftData?.admin_connected && msftToken)
      );

      return {
        ...state,
        selectedCouncil: payload,
      };
    }

    case COUNCIL_LIST_GET_ALL_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case COUNCIL_LIST_GET_ALL_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          allCouncilsList: response,
          loading: false,
        };
      }
    }

    case COUNCIL_LIST_GET_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case COUNCIL_LIST_GET_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          councilList: response,
          loading: false,
        };
      }
    }
    case COUNCIL_USERS_LIST_GET_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case COUNCIL_USERS_LIST_GET_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          usersList: response,
          usersListById: usersById(response),
          userPageNr: pageMeta(payload),
          loading: false,
        };
      }
    }
    case PEOPLE_EDIT_MEMBERS_SUCCESS: {
      const { response, status } = payload;

      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          usersList: state.usersList.map((user) => {
            if (user.email === response.email) {
              return {
                ...response,
                teams_ids: response.teams.map((team) => team.id),
                teams_names: response.teams.map((team) => team.name),
              };
            }
            return user;
          }),

          loading: false,
        };
      }
    }

    case COUNCIL_USER_GET_SUCCESS:
      localStorage.removeItem(`userGetApi_${payload.id}`);
      return {
        ...state,
        usersListById: { ...state.usersListById, [payload.id]: payload },
      };
      break;
    case COUNCIL_STAGES_TEMPLATE_LIST_SUCCESS:
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          stageTemplates: response,
        };
      }
    case COUNCIL_REMOVE_MEMBER_ATTEMPT:
      return {
        ...state,
        // updateUsers: false,
      };
      break;
    case COUNCIL_REMOVE_MEMBER_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          usersList: state.usersList.filter((user) => user.id !== response.id),
          // updateUsers: true,
        };
      }
    }
    case COUNCIL_GET_TEAMS_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case COUNCIL_GET_TEAMS_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          teamList: response,
          loading: false,
          updateTeams: false,
        };
      }
    }

    case COUNCIL_SEARCH_TEAMS_ATTEMPT: {
      return {
        ...state,
      };
    }
    case COUNCIL_SEARCH_TEAMS_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          teamList: response,
        };
      }
    }
    case COUNCIL_REMOVE_TEAM_ATTEMPT: {
      return {
        ...state,
        updateTeams: false,
        loading: true,
      };
    }
    case COUNCIL_REMOVE_TEAM_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          updateTeams: true,
          loading: false,
        };
      }
    }
    case COUNCIL_CREATE_TEAM_ATTEMPT: {
      return {
        ...state,
        updateTeams: false,
        loading: true,
      };
    }
    case COUNCIL_CREATE_TEAM_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.CREATED) {
        return {
          ...state,
          teamList: [...state.teamList, response],
          updateTeams: true,
          loading: false,
        };
      }
    }

    case COUNCIL_EDIT_TEAM_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case COUNCIL_EDIT_TEAM_SUCCESS: {
      // const { response, status } = payload;
      // const newTeamList = state.teamList.map((team) => {
      //   if (team.id === response.id) {
      //     return response;
      //   }
      //   return team;
      // });
      // return {
      //   ...state,
      //   teamList: newTeamList,
      //   loading: false,
      // };

      return {
        ...state,
        // teamList: newTeamList,
        updateTeams: true,
        loading: false,
      };
    }

    case COUNCIL_GET_TEAM_BY_ID_ATTEMPT: {
      return {
        ...state,
        // updateTeams: false,
      };
    }
    case COUNCIL_GET_TEAM_BY_ID_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          selectedTeam: response,
          teamMembers: [...response.members],
          // updateTeams: true,
        };
      }
    }

    case COUNCIL_TEAM_ADD_MEMBER_ATTEMPT: {
      return {
        ...state,
        updateTeams: false,
        loadTeams: true,
      };
    }
    case COUNCIL_TEAM_ADD_MEMBER_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.CREATED) {
        return {
          ...state,
          updateTeams: true,
          loadTeams: false,
          // teamMembers: [...state.selectedTeam.members, response.user],
          selectedTeam: {
            ...state.selectedTeam,
            members: [...state.selectedTeam.members, response.user],
          },
        };
      }
    }

    case COUNCIL_SEARCH_TEAM_MEMBER_ATTEMPT: {
      return {
        ...state,
        // updateTeams: false,
      };
    }
    case COUNCIL_SEARCH_TEAM_MEMBER_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          teamMembers: response,
          teamMemberNr: pageMeta(payload),
        };
      }
    }

    case COUNCIL_REMOVE_TEAM_MEMBER_ATTEMPT: {
      return {
        ...state,
        updateTeams: false,
      };
    }
    case COUNCIL_REMOVE_TEAM_MEMBER_SUCCESS: {
      const { response, status, user_id } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          updateTeams: true,
          selectedTeam: {
            ...state.selectedTeam,
            members: state.selectedTeam.members.filter(
              (member) => member.id !== user_id
            ),
          },
        };
      }
    }
  }
  return state;
};

export default councilReducer;

const usersById = (list) => {
  const obj = {};
  const lintObj = list?.forEach((user) => {
    obj[user.id] = user;
  });
  return obj;
};

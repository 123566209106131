import React from "react";
import Select from "react-dropdown-select";
import { format } from "date-fns";

import {
  DatePiker,
  Pannel,
  TextBox,
  Button,
  BtnType,
} from "../../../../primitives";
import styles from "./fordMetrics.module.scss";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { Icon, smSize } from "../../../../../common/icon";
import { isANumber, makeStages } from "../../../../../common/helper";

const stages = makeStages(2022).reverse();

const FordMetrics = ({
  haveActions,
  metrics,
  setMetrics,
  handleSave,
  isAdmin,
  isNot3DPrintingTemplate,
  isFordSupplyChainAutomation,
}) => {
  const handleChange = (val, prop) => {
    if (prop === "tarr_percentage" || prop === "investment_usd") {
      let savings_usd;

      if (!val?.length || !Number(val)) {
        savings_usd = null;
      } else {
        if (prop === "tarr_percentage") {
          if (metrics.investment_usd !== 0) {
            const calculated = (val / 100) * metrics.investment_usd;
            savings_usd = calculated.toFixed(0);
          } else {
            savings_usd = null;
          }
        } else {
          if (Number(metrics.tarr_percentage)) {
            const calculatedValue = val * (metrics.tarr_percentage / 100);
            savings_usd = calculatedValue.toFixed(0);
          } else {
            savings_usd = null;
          }
        }
      }

      setMetrics({
        ...metrics,
        savings_usd,
        [prop]: val,
      });

      return;
    }

    if (prop === "savings_usd") {
      const tarr_percentage = val / metrics.investment_usd;

      setMetrics({
        ...metrics,
        tarr_percentage: (Math.round(tarr_percentage * 100) / 100) * 100,
        [prop]: val,
      });

      return;
    }

    setMetrics({
      ...metrics,
      [prop]: val,
    });
  };

  return (
    <Pannel title="Project Metrics">
      <div className={styles.wrapper}>
        {!metrics ? (
          <LoadingComponent customText="Getting metrics" />
        ) : (
          <div className="d-flex flex-column align-items-end">
            {haveActions && metrics.id && (
              <div
                className="cursor-pointer"
                onClick={() => setMetrics({ ...metrics, editMode: true })}
              >
                <Icon {...smSize} icon="icn-edit" />
              </div>
            )}
            <div className={styles.table}>
              <div className={styles.tableHeader}>
                {!isNot3DPrintingTemplate && (
                  <div className="font-weight-bold small">
                    3D Print Replications
                  </div>
                )}
                {isNot3DPrintingTemplate && !isFordSupplyChainAutomation && (
                  <div className="font-weight-bold small">
                    Prototype Investment ($)
                  </div>
                )}
                <div className="font-weight-bold small">Investment ($)</div>
                <div className="font-weight-bold small">Savings ($)</div>
                <div className="font-weight-bold small">TARR (%)</div>
                {isFordSupplyChainAutomation && (
                  <>
                    <div className="font-weight-bold small">Current Spending ($)</div>
                    <div className="font-weight-bold small">Annual Incremental Investment ($)</div>
                    <div className="font-weight-bold small">NPV ($)</div>
                  </>
                )}
                {isNot3DPrintingTemplate && !isFordSupplyChainAutomation && (
                  <div className="font-weight-bold small">
                    Next Stage Change
                  </div>
                )}
                {
                  !isFordSupplyChainAutomation && (
                    <div className="font-weight-bold small">Job 1 Date</div>
                  )
                }
              </div>
              <div className={styles.tableBody}>
                {!isNot3DPrintingTemplate && (
                  <div className="px-2">
                    {metrics.editMode || !metrics.id ? (
                      <TextBox
                        type="number"
                        placeholder="3D Print Replications"
                        className="w-100"
                        value={metrics.threed_printing_replications}
                        onChange={(e) =>
                          handleChange(
                            e.target.value,
                            "threed_printing_replications"
                          )
                        }
                      />
                    ) : (
                      <span>
                        {isANumber(metrics.threed_printing_replications)
                          ? `${metrics.threed_printing_replications?.toLocaleString()}`
                          : "-"}
                      </span>
                    )}
                  </div>
                )}
                {isNot3DPrintingTemplate && !isFordSupplyChainAutomation && (
                  <div className="px-2">
                    {metrics.editMode || !metrics.id ? (
                      <TextBox
                        type="number"
                        placeholder="Prototype Investment"
                        className="w-100"
                        value={metrics.prototype_investment_usd}
                        onChange={(e) =>
                          handleChange(
                            e.target.value,
                            "prototype_investment_usd"
                          )
                        }
                      />
                    ) : (
                      <span>
                        {isANumber(metrics.prototype_investment_usd)
                          ? `$${metrics.prototype_investment_usd?.toLocaleString()}`
                          : "-"}
                      </span>
                    )}
                  </div>
                )}
                <div className="px-2">
                  {metrics.editMode || !metrics.id ? (
                    <TextBox
                      type="number"
                      placeholder="Investment"
                      className="w-100"
                      value={metrics.investment_usd}
                      onChange={(e) =>
                        handleChange(e.target.value, "investment_usd")
                      }
                    />
                  ) : (
                    <span>
                      {isANumber(metrics.investment_usd)
                        ? `$${metrics.investment_usd?.toLocaleString()}`
                        : "-"}
                    </span>
                  )}
                </div>
                <div className="px-2">
                  {metrics.editMode || !metrics.id ? (
                    <TextBox
                      disabled={!isAdmin}
                      type="number"
                      placeholder="Savings"
                      className="w-100"
                      value={metrics.savings_usd}
                      onChange={(e) =>
                        handleChange(e.target.value, "savings_usd")
                      }
                    />
                  ) : (
                    <span>
                      {isANumber(metrics.savings_usd)
                        ? `$${metrics.savings_usd?.toLocaleString()}`
                        : "-"}
                    </span>
                  )}
                </div>
                <div className="px-2">
                  {metrics.editMode || !metrics.id ? (
                    <TextBox
                      type="number"
                      placeholder="TARR"
                      className="w-100"
                      value={metrics.tarr_percentage}
                      onChange={(e) =>
                        handleChange(e.target.value, "tarr_percentage")
                      }
                      min={0}
                    />
                  ) : (
                    <span>
                      {isANumber(metrics.tarr_percentage)
                        ? `${metrics.tarr_percentage}%`
                        : "-"}
                    </span>
                  )}
                </div>
                {isFordSupplyChainAutomation && (
                  <>
                    <div className="px-2">
                      {metrics.editMode || !metrics.id ? (
                        <TextBox
                          type="number"
                          placeholder="Current Spending ($)"
                          className="w-100"
                          value={metrics.current_spending_usd}
                          onChange={(e) =>
                            handleChange(e.target.value, "current_spending_usd")
                          }
                          min={0}
                        />
                      ) : (
                        <span>
                          {isANumber(metrics.current_spending_usd)
                            ? `$${metrics.current_spending_usd?.toLocaleString()}`
                            : "-"}
                        </span>
                      )}
                    </div>
                    <div className="px-2">
                      {metrics.editMode || !metrics.id ? (
                        <TextBox
                          type="number"
                          placeholder="Annual Incremental Investment ($)"
                          className="w-100"
                          value={metrics.annual_incremental_investment_usd}
                          onChange={(e) =>
                            handleChange(e.target.value, "annual_incremental_investment_usd")
                          }
                          min={0}
                        />
                      ) : (
                        <span>
                          {isANumber(metrics.annual_incremental_investment_usd)
                            ? `$${metrics.annual_incremental_investment_usd?.toLocaleString()}`
                            : "-"}
                        </span>
                      )}
                    </div>
                    <div className="px-2">
                      {metrics.editMode || !metrics.id ? (
                        <TextBox
                          type="number"
                          placeholder="NPV ($)"
                          className="w-100"
                          value={metrics.npv_usd}
                          onChange={(e) =>
                            handleChange(e.target.value, "npv_usd")
                          }
                          min={0}
                        />
                      ) : (
                        <span>
                          {isANumber(metrics.npv_usd)
                            ? `$${metrics.npv_usd?.toLocaleString()}`
                            : "-"}
                        </span>
                      )}
                    </div>
                  </>
                )}
                {isNot3DPrintingTemplate && !isFordSupplyChainAutomation && (
                  <div className="px-2">
                    {metrics.editMode || !metrics.id ? (
                      <Select
                        label="Next Stage Change"
                        placeholder="Next Stage Change"
                        labelField="name"
                        valueField="id"
                        name="next_stage_end_on"
                        multi={false}
                        options={stages}
                        onChange={(val) =>
                          handleChange(val[0], "next_stage_end_on")
                        }
                        values={stages.filter(
                          (stage) => stage.name === metrics.next_stage_quarter,
                        )}
                      />
                    ) : (
                      <span>{metrics.next_stage_quarter || "-"}</span>
                    )}
                  </div>
                )}
                {
                  !isFordSupplyChainAutomation && (
                    <div className="px-2" id="job1Date">
                      {metrics.editMode || !metrics.id ? (
                        <DatePiker
                          name="job_1_date"
                          value={metrics.job_1_date}
                          onChange={(val) => handleChange(val, "job_1_date")}
                          showTime
                          withClear
                        />
                      ) : (
                        <span>
                      {metrics.job_1_date
                        ? format(new Date(metrics.job_1_date), "MMM do, yyyy")
                        : ""}
                    </span>
                      )}
                    </div>
                  )
                }
              </div>
              {metrics.editMode && (
                <div className="d-flex justify-content-center mb-3">
                  <Button btn={BtnType.OUTLINE} onClick={handleSave}>
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Pannel>
  );
};

export default React.memo(FordMetrics);

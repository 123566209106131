import React, { memo, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import randomColor from "randomcolor";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import { BtnType, Button, CompanyAvatar } from "../index";
import {
  companySearchClear,
  saveSearchCompanyList,
  searchInCouncil,
  setFilters,
} from "../../companies/companyMain/companyMain.action";
import styles from "./search.module.scss";
import { Icon, mdSize } from "../../../common/icon";
import { changeQuery } from "../../../common/actions/common.action";
import {
  debounce,
  queryParamStringToArray,
  queryStringUrlReplacement,
} from "../../../common/helper";

const SearchComponent = (props) => {
  const dispatch = useDispatch();

  const {
    placeholder = "Search",
    onChange,
    onKeyUp = false,
    value = "",
    className,
    type = "",
    handleEnterPress,
    handleCrunchBase,
    deafultValue,
    mode = "companies",
    showDropdown = true,
    gsk = false,
    patient_org = false,
    from,
    redirectProp,
    handleBlur,
    setSearchResponses,
    responses,
    showCrunchBaseHolder = true,
  } = props;

  const history = useHistory();
  const location = useLocation();

  const [searchRes, setSearchRes] = useState([]);
  const [autocompletedSearchResults, setautoCompletedSearchResults] = useState(
    []
  );
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [val, setVal] = useState("");

  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: {
        searchResults,
        searchResultNr,
        checkedFilters,
        loading,
      },
    },
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: {
        checkedPatientFilters,
        countryFilters,
        patientOrganizationList,
        patientOrganizationPageNr,
      },
    },
  } = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();

  const { disease_ids, diversity_group_ids } = checkedPatientFilters;

  useEffect(() => {
    const value =
      deafultValue || history.location?.state?.query || redirectProp || "";

    setVal(value);
  }, [deafultValue, history.location?.state?.query, redirectProp]);

  useEffect(() => {
    if (from === "new_event") {
      if (searchResults || patientOrganizationList) {
        if (patient_org) {
          setSearchRes(
            patientOrganizationList?.filter(
              (o1) => !responses.some((o2) => o1.id === o2.id)
            )
          );
        } else {
          setSearchRes(
            searchResults?.filter(
              (o1) => !responses.some((o2) => o1.id === o2.id)
            )
          );
        }
      }
    } else if (patient_org) {
      setSearchRes(patientOrganizationList);
    } else {
      setSearchRes(searchResults);
    }
  }, [searchResults, patientOrganizationList, patient_org]);

  const handleOnChange = (e) => {
    const query = e.currentTarget.value;
    setVal(query);
    if (selectedCouncil && (query?.length || gsk)) {
      if (type !== "crunch") {
        if (handleBlur) {
          optimizedFn(query);
        }
        dispatch(
          changeQuery({
            query,
            type: "simple",
          })
        );
      }
      if (type !== "simple" && type !== "crunch") {
        optimizedFn(query, true);
      }
    }
    if (query === "") {
      if (type === "simple" && handleBlur) {
        optimizedFn(query);
      }
      if (type === "crunch") {
        onChange("");
      }
      if (mode === "councils/users") {
        optimizedFn(query, true);
      }
      dispatch(companySearchClear({}));
    }
    setSearchCompleted(false);
  };

  const debounceChange = (query, shouldSearchLocally) => {
    const relationShipStatus = queryParamStringToArray(
      location.state?.relationShipStatus || ""
    );
    const topics = queryParamStringToArray(location.state?.topic || "");
    const business_unit_ids = queryParamStringToArray(
      location.state?.business_unit || ""
    );
    const custom_tags_ids = queryParamStringToArray(
      location?.custom_tags_ids || ""
    );
    const industries = queryParamStringToArray(location.state?.industry || "");
    const owners = queryParamStringToArray(location.state?.owners || "");
    const locationStrAsArray = queryParamStringToArray(
      location.state?.location || ""
    );
    const agreements = queryParamStringToArray(
      location.state?.agreements || ""
    );
    const idb_connection_options = queryParamStringToArray(
      location.state?.idbEcosystem || ""
    );
    const location_countries = queryParamStringToArray(
      location.state?.k_country || ""
    );
    const council_relationship_stage_id = queryParamStringToArray(
      location.state?.council_relationship_stage_id ||
        location.state?.relationShipStatus ||
        ""
    );
    const locationProps = {};

    if (locationStrAsArray.length) {
      const splitedValue = locationStrAsArray
        .map((str) => str.replace(/-/g, ","))[0]
        .split(",")[0]
        .split("#");
      const value = splitedValue[0];
      const type = splitedValue[1];

      if (splitedValue) {
        locationProps[`location_${type}`] = value;
      }
    }

    if (shouldSearchLocally) {
      dispatch(
        searchInCouncil({
          data: query,
          topic_ids: topics,
          industry_ids: industries,
          relationship_stages: relationShipStatus,
          council_company_score_gte: location.state?.scoreFrom
            ? Number(location.state?.scoreFrom)
            : null,
          council_company_score_lte: location.state?.scoreTo
            ? Number(location.state?.scoreTo)
            : null,
          founded_gte: location.state?.foundedFrom
            ? location.state?.foundedFrom
            : null,
          founded_lte: location.state?.foundedTo
            ? location.state?.foundedTo
            : null,
          relationship_owners_ids: owners,
          num_employee_enum: location.state?.employees
            ? location.state?.employees
            : "",
          revenue_range: location.state?.estimatedRevenue
            ? location.state?.estimatedRevenue
            : "",
          lists: location.state?.lists ? location.state?.lists : "",
          total_founding_gte: location.state?.fundingFrom
            ? Number(location.state?.fundingFrom.replace(/\D/g, ""))
            : null,
          total_founding_lte:
            location.state?.fundingTo &&
            location.state?.fundingTo !== "Any Amount"
              ? Number(location.state?.fundingTo.replace(/\D/g, ""))
              : null,
          council_id: selectedCouncil.id,
          mode,
          disease_ids,
          diversity_group_ids: diversity_group_ids,
          countries: countryFilters,
          gsk,
          patient_org,
          withContext: true,
          sort_attribute: location?.state?.sortAttribute || "",
          sort_order: location?.state?.sortOrder || "",
          locationProps,
          agreements,
          enqueueSnackbar,
          business_unit_ids,
          custom_tags_ids,
          location_countries,
          idb_connection_options,
          council_relationship_stage_id,
          cb: () => {},
        })
      );

      return;
    }

    handleBlur(query);
  };

  const optimizedFn = useCallback(debounce(debounceChange), [
    location,
    selectedCouncil,
    mode,
    disease_ids,
    diversity_group_ids,
    countryFilters,
    gsk,
    patient_org,
  ]);

  useEffect(() => {
    if (loading) return;

    const delayDebounceFn = setTimeout(() => {
      if (val?.length > 0 && !searchRes?.length) {
        setautoCompletedSearchResults([]);
        setSearchCompleted(true);
      } else if (searchRes) {
        setautoCompletedSearchResults(searchRes);
        setSearchCompleted(false);
      }
    });

    return () => clearTimeout(delayDebounceFn);
  }, [searchRes, val, loading]);

  useEffect(() => {
    if (val && onKeyUp) {
      triggerChange();
    }
  }, [val]);

  const handleOnKeyDown = (e) => {
    if (e.key !== "Enter") {
      return;
    }

    if (type === "simple") {
      if (handleBlur) {
        handleBlur(val);
      }
    }
    if (type === "crunch") {
      handleEnterPress();
    } else {
      handleEnterPress && handleEnterPress();
      setSearchCompleted(true);
      setautoCompletedSearchResults(searchResults);
      // triggerChange();
      setautoCompletedSearchResults([]);
      if (selectedCouncil) {
        if (val === "") {
          return;
        }
        dispatch(
          saveSearchCompanyList({
            response: searchResults,
          })
        );
        setSearchCompleted(true);
        setautoCompletedSearchResults(searchResults);
        if (from === "projects" || from === "events" || from === "home") {
          const search = queryStringUrlReplacement(
            history.location.search,
            "query",
            val
          );

          history.push({
            pathname: "/companies",
            search,
            state: {
              ...history.location?.state,
              query: val,
            },
          });
        }
        // triggerChange();
        // setautoCompletedSearchResults([]);
      }
      // setVal('');
    }
  };

  const triggerChange = () => {
    if (onChange) {
      onChange(val);
    }
  };

  const handleResultClick = (result) => {
    if (from === "new_event" || from === "speakers") {
      from === "new_event"
        ? setSearchResponses([...responses, result])
        : setSearchResponses(result);
    } else {
      if (result.is_product) {
        history.push(
          `/companies/${result.company_slug || result.company_id}/products/${
            result.slug
          }`
        );
        return;
      }

      history.push(
        patient_org
          ? `/patient_organizations/${result.slug || result.id}`
          : `/companies/${result.slug || result.id}`
      );
    }
    dispatch(companySearchClear({}));
    dispatch(
      setFilters({
        topic_ids: [],
        industry_ids: [],
      })
    );
    setVal("");
  };

  const handleResutOnBlur = useCallback(() => {
    if (handleBlur && history.location?.state?.query) {
      handleBlur(val);
    }

    if (mode === "councils/users" || mode === "crunch") {
      return;
    }
    // setVal('');
    // dispatch(companySearchClear({}));
    setSearchCompleted(false);
    setSearchActive(false);
  }, [val, mode]);

  const searchOnMount = async () => {
    if (!val) {
      return;
    }

    const relationShipStatus = queryParamStringToArray(
      location.state?.relationShipStatus || ""
    );
    const business_unit_ids = queryParamStringToArray(
      location.state?.business_unit || ""
    );
    const custom_tags_ids = queryParamStringToArray(
      location?.custom_tags_ids || ""
    );
    const topics = queryParamStringToArray(location.state?.topic || "");
    const industries = queryParamStringToArray(location.state?.industry || "");
    const owners = queryParamStringToArray(location.state?.owners || "");
    const locationStrAsArray = queryParamStringToArray(
      location.state?.location || ""
    );
    const agreements = queryParamStringToArray(
      location.state?.agreements || ""
    );
    const idb_connection_options = queryParamStringToArray(
      location.state?.idbEcosystem || ""
    );
    const location_countries = queryParamStringToArray(
      location.state?.k_country || ""
    );
    const council_relationship_stage_id = queryParamStringToArray(
      location.state?.council_relationship_stage_id ||
        location.state?.relationShipStatus ||
        ""
    );
    const locationProps = {};

    if (locationStrAsArray.length) {
      const splitedValue = locationStrAsArray
        .map((str) => str.replace(/-/g, ","))[0]
        .split(",")[0]
        .split("#");
      const value = splitedValue[0];
      const type = splitedValue[1];

      if (splitedValue) {
        locationProps[`location_${type}`] = value;
      }
    }

    return await dispatch(
      searchInCouncil({
        data: val,
        council_id: selectedCouncil.id,
        topic_ids: topics,
        industry_ids: industries,
        relationship_stages: relationShipStatus,
        council_company_score_gte: location.state?.scoreFrom
          ? Number(location.state?.scoreFrom)
          : null,
        council_company_score_lte: location.state?.scoreTo
          ? Number(location.state?.scoreTo)
          : null,
        founded_gte: location.state?.foundedFrom
          ? location.state?.foundedFrom
          : null,
        founded_lte: location.state?.foundedTo
          ? location.state?.foundedTo
          : null,
        relationship_owners_ids: owners,
        num_employee_enum: location.state?.employees
          ? location.state?.employees
          : "",
        revenue_range: location.state?.estimatedRevenue
          ? location.state?.estimatedRevenue
          : "",
        lists: location.state?.lists ? location.state?.lists : "",
        total_founding_gte: location.state?.fundingFrom
          ? Number(location.state?.fundingFrom.replace(/\D/g, ""))
          : null,
        total_founding_lte:
          location.state?.fundingTo &&
          location.state?.fundingTo !== "Any Amount"
            ? Number(location.state?.fundingTo.replace(/\D/g, ""))
            : null,
        mode,
        withContext: true,
        sort_attribute: location?.state?.sortAttribute || "",
        sort_order: location?.state?.sortOrder || "",
        locationProps,
        agreements,
        enqueueSnackbar,
        business_unit_ids,
        custom_tags_ids,
        location_countries,
        idb_connection_options,
        council_relationship_stage_id,
        cb: () => {},
      })
    );
  };

  const handleCrunchbaseClick = () => {
    if (
      from === "projects" ||
      from === "events" ||
      from === "home" ||
      from === "companies" ||
      from === "company"
    ) {
      const search1 = queryStringUrlReplacement(
        history.location.search,
        "query",
        val
      );
      const search = queryStringUrlReplacement(search1, "isCrunchSearch", true);

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          query: val,
          isCrunchSearch: true,
        },
      });
    } else {
      handleCrunchBase(true, val);
      companySearchClear();
    }
  };

  React.useEffect(() => {
    if (selectedCouncil && type !== "crunch") {
      searchOnMount().then((resp) => {});
    }
  }, [checkedFilters, type]);

  return (
    <div
      className={`${styles.searchContainer} ${
        from === "companies" || gsk ? "w-100" : ""
      }`}
      tabIndex="0"
      onBlur={(e) => {
        const { currentTarget } = e;
        // blur happens before click, preventing any click events in children from firing due to rerender from state change
        // so wait a tick for child component events to fire before changing open state and causing rerender
        window.setTimeout(() => {
          if (!currentTarget.contains(document.activeElement)) {
            handleResutOnBlur();
          }
        }, 100);
      }}
    >
      <div
        className={classNames(
          className,
          from === "companies" ? styles.companiesSearch : styles.searchWrp
        )}
      >
        <input
          value={val}
          onFocus={() => setSearchActive(true)}
          className={styles.searchText}
          placeholder={placeholder}
          onKeyDown={handleOnKeyDown}
          onChange={handleOnChange}
        />
      </div>
      {(mode === "companies" || mode === "patientOrg") &&
      showDropdown &&
      val?.length &&
      !searchCompleted &&
      searchRes?.length &&
      searchActive ? (
        <div className={styles.searchResultWrp}>
          <div className={styles.totals}>
            <p>
              {searchRes?.length} out of{" "}
              {patient_org ? patientOrganizationPageNr.total : searchResultNr}{" "}
              Results{" "}
            </p>
          </div>
          {searchRes
            ? searchRes.map((result) => {
                const color = randomColor({
                  luminosity: "light",
                  format: "rgb",
                });
                return (
                  <div
                    key={`${result.id}_${result.name}`}
                    className={styles.itemContentWraper}
                    onClick={() => handleResultClick(result)}
                  >
                    <div className={styles.item}>
                      {result.logo ? (
                        <div className={styles.searchLogo}>
                          <CompanyAvatar
                            imgSrc={result.logo || result.company_logo}
                            name={result.name}
                          />
                        </div>
                      ) : (
                        <div
                          className={styles.defaultImage}
                          style={{ backgroundColor: color }}
                        >
                          {`${
                            result &&
                            result.name?.charAt(0) + result.name?.charAt(1)
                          }`}
                        </div>
                      )}
                      <div className="d-flex">
                        <span className={styles.companyName}>
                          {result.name}
                        </span>
                      </div>
                    </div>
                    <div>
                      {gsk && patient_org
                        ? "PATIENT ORGANIZATION"
                        : result.is_product
                        ? "PRODUCT"
                        : "COMPANY"}
                    </div>
                  </div>
                );
              })
            : null}
          {!patient_org && showCrunchBaseHolder && (
            <div
              className={styles.gridFooter}
              onClick={() => handleCrunchbaseClick()}
            >
              Search Crunchbase
            </div>
          )}
        </div>
      ) : null}
      {mode === "companies" &&
      showDropdown &&
      searchCompleted &&
      searchResults?.length === 0 ? (
        <div className={styles.completedContainerStyles}>
          <div className={styles.completedContainerHeader}>
            <p>
              {" "}
              Search Results for: {val.toLocaleUpperCase()} (
              {autocompletedSearchResults?.length} Results)
            </p>
            <Icon
              {...mdSize}
              icon="icn-add"
              className={styles.closeCompletedContainerButton}
              onClick={() => {
                handleResutOnBlur();
              }}
            />
          </div>
          <div className={styles.completedCompaniesStyles}>
            {autocompletedSearchResults &&
              autocompletedSearchResults?.length === 0 && (
                <div className={styles.stub}>
                  <span className={styles.stubText}>
                    No items found in Traction
                  </span>
                  {!patient_org && showCrunchBaseHolder && (
                    <div>
                      <Button
                        onClick={() => handleCrunchbaseClick()}
                        btn={BtnType.LINK}
                      >
                        Search Crunchbase
                      </Button>
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default memo(SearchComponent);

import React, { useEffect, useRef, useState } from "react";
import {
  BtnType,
  Button,
  Loading,
  MoreOptions,
  OptionType,
  TextBox,
} from "modules/primitives/index";
import ReactTable from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { CSVLink } from "react-csv";
import { getAllTeamsAttempt } from "modules/teams/teams.action";
import styles from "../adminCustomfields.module.scss";
import ConfirmDeleteComponent from "../../../../common/components/confirmDelete";
import { httpGet } from "../../../../common/httpCall";
import { useModelPopup } from "../../../../common/hooks";
import AdminCustomFieldModal from "../adminCustomFieldModal/adminCustomFieldModal.component";
import {
  getCustomFieldsAttempt,
  getCustomFieldsGroupsAttempt,
} from "../adminCustomField.action";

let timeDelaySearch;

const AdminCustomFields = () => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const tableKey = Math.random();
  const [exportLoading, setExportLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    adminCustomFieldsReducer: {
      customFields,
      loading,
      customFieldPageNr,
      shouldUpdate,
    },
  } = useSelector((state) => state);

  const handleCreateFormClick = (e) => {
    popup.show({
      title: "Add item",
      show: true,
      height: "500",
      width: "600",
      component: <AdminCustomFieldModal mode="add" />,
    });
  };

  const moreOptions = [
    { val: "edit", text: "edit" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const handleMoreOptionClick = (event, data) => {
    if (event === "edit") {
      popup.show({
        title: "Edit item",
        show: true,
        height: "300",
        width: "540",
        component: <AdminCustomFieldModal mode="edit" data={data} />,
      });
    }

    if (event === "delete") {
      popup.show({
        title: "Confirmation Delete",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={data?.custom_field_template_id}
            enqueueSnackbar={enqueueSnackbar}
            councilId={selectedCouncil?.id}
            actionType={
              data?.existing_records > 0 && data?.field_type === "agreement"
                ? "deleteCustomAgreementWithRecords"
                : "deleteAdminCustomField"
            }
            customData={[data?.existing_records]}
          />
        ),
      });
    }
  };

  const handleExportList = () => {
    setExportLoading(true);
    httpGet({
      call: `custom_field_templates?items=${customFieldPageNr?.total}`,
      data: {
        query: "*",
      },
    }).subscribe(
      async (res) => {
        setExportLoading(false);
        setCsvData(() => {
          const csvDataCont = [
            [
              "Custom Field",
              "Type",
              "Position",
              "Multi-Select",
              "Group",
              "Access",
              "Existing Records",
            ],
          ];

          res.response.map((item) => {
            csvDataCont.push([
              item?.field_name,
              item?.field_type,
              item?.position,
              item?.multiselect ? "YES" : "NO",
              item?.custom_field_group?.name,
              item?.access || "-",
              item?.option_values?.length,
            ]);
          });

          return csvDataCont;
        });
        csvLinkRef.current.link.click();
      },
      (err) => {
        setExportLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      },

      () => {
        setExportLoading(false);
        enqueueSnackbar("List succesfully exported.", {
          variant: "success",
        });
      }
    );
  };

  const handlePageSelect = (e) => {
    setPage(e.target.value);
  };

  const handleNextClick = () => {
    setPage(page + 1);
  };

  const handlePrevClick = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const isNextDisabled = () => {
    if (page + 1 > customFieldPageNr?.pages) {
      return styles.disabledButton;
    }
    return styles.paginationButton;
  };

  const sortTemplatesByPositionAndGroups = (a, b) => {
    if (
      a?.props?.obj?.custom_field_group?.position >
      b?.props?.obj?.custom_field_group?.position
    ) {
      if (
        a?.props?.obj?.custom_field_group_id ===
        b?.props?.obj?.custom_field_group_id
      ) {
        if (a?.props?.obj.position > b.props?.obj.position) {
          return 1;
        }
        if (a?.props?.obj.position < b?.props?.obj.position) {
          return -1;
        }
      }
      return 1;
    }
    if (
      a?.props?.obj?.custom_field_group?.position <
      b?.props?.obj?.custom_field_group?.position
    ) {
      if (
        a?.props?.obj?.custom_field_group_id ===
        b?.props?.obj?.custom_field_group_id
      ) {
        if (a?.props?.obj.position > b?.props?.obj.position) {
          return 1;
        }
        if (a?.props?.obj.position < b?.props?.obj.position) {
          return -1;
        }
      }
      return -1;
    }
    if (
      a?.props?.obj?.custom_field_group?.position ===
      b?.props?.obj?.custom_field_group?.position
    ) {
      if (
        a?.props?.obj?.custom_field_group_id ===
        b?.props?.obj?.custom_field_group_id
      ) {
        if (a?.props?.obj.position > b?.props?.obj.position) {
          return 1;
        }
        if (a?.props?.obj.position < b?.props?.obj.position) {
          return -1;
        }
      }
      return 0;
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Custom Field",
        id: "field_name",
        accessor: "field_name",
        minWidth: 200,
        sortable: false,
      },
      {
        Header: "Type",
        id: "field_type",
        accessor: "field_type",
        sortable: false,
        width: 120,
        style: {
          textTransform: "capitalize",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Group",
        id: "custom_field_group",
        sortable: false,
        width: 200,
        style: {
          display: "flex",
          justifyContent: "center",
        },
        accessor: (row) => <span>{row?.custom_field_group?.name}</span>,
      },
      {
        Header: "Access",
        id: "access",
        sortable: false,
        width: 90,
        style: {
          display: "flex",
          justifyContent: "center",
        },
        accessor: (row) => (
          <span>
            {row?.teams?.length || (row?.public && row?.teams?.length)
              ? "Team"
              : Boolean(row?.public) && "All"}
            {Boolean(row?.users?.length) && "Individual"}
          </span>
        ),
      },
      {
        Header: "Position",
        id: "postion",
        sortable: true,
        sortMethod: sortTemplatesByPositionAndGroups,
        style: {
          display: "flex",
          justifyContent: "center",
        },
        width: 90,
        accessor: (row) => <span>{row?.position}</span>,
      },
      {
        Header: "Existing Records",
        id: "option_values",
        sortable: false,
        width: 125,
        style: {
          display: "flex",
          justifyContent: "center",
        },
        accessor: (row) => <span>{row?.existing_records}</span>,
      },
      {
        Header: "Options",
        id: "options",
        width: 90,
        sortable: false,
        accessor: (row) => (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row);
            }}
          />
        ),
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [customFields]
  );

  useEffect(() => {
    dispatch(getAllTeamsAttempt({ enqueueSnackbar }));
    dispatch(
      getCustomFieldsAttempt({
        enqueueSnackbar,
        query: "",
        page,
        pageSize,
      })
    );

    dispatch(
      getCustomFieldsGroupsAttempt({
        enqueueSnackbar,
        query: "",
        page: 1,
        pageSize: 20,
      })
    );
  }, [session, pageSize, page]);

  useEffect(() => {
    if (shouldUpdate) {
      dispatch(
        getCustomFieldsAttempt({
          enqueueSnackbar,
          query: "",
          page,
          pageSize,
        })
      );
      dispatch(
        getCustomFieldsGroupsAttempt({
          enqueueSnackbar,
          query: "",
          page: 1,
          pageSize: 20,
        })
      );
    }
  }, [shouldUpdate]);

  const searchWithDelay = (value, withoutDelay = false) => {
    window.clearTimeout(timeDelaySearch);
    if (!withoutDelay) {
      timeDelaySearch = setTimeout(() => {
        dispatch(
          getCustomFieldsAttempt({
            enqueueSnackbar,
            query: value,
            page: 1,
            pageSize: 20,
          })
        );
      }, 1000);
    } else {
      dispatch(
        getCustomFieldsAttempt({
          enqueueSnackbar,
          query: value,
          page: 1,
          pageSize: 20,
        })
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <a href="/admin" className={styles.rootText}>
            ADMIN
          </a>
          <span className={styles.separationBar}> / </span>
          <span className={`${styles.rootText} ${styles.activeBreadcrumbs}`}>
            CUSTOM FIELDS
          </span>
          <span className={styles.separationBar}> / </span>
          <a href="/admin/custom-fields/groups" className={styles.rootText}>
            CUSTOM FIELD GROUPS
          </a>
        </h4>
      </div>
      <div className={styles.flexWrapper}>
        <div className={styles.headerBlock}>
          <div className={styles.createFormButton}>
            <Button
              btn={BtnType.REGULAR}
              icon="icn-add"
              onClick={() => handleCreateFormClick()}
            >
              Default Field
            </Button>
          </div>
          <div className={styles.searchBar}>
            <TextBox
              placeholder="Search"
              className={styles.searchField}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e?.target?.value);
                searchWithDelay(e?.target?.value);
              }}
            />
            <Button
              btn={BtnType.REGULAR}
              className={styles.searchButton}
              onClick={() => searchWithDelay(searchValue, true)}
            >
              Search
            </Button>
          </div>
        </div>
        <div className={styles.infoRow}>
          <div className={styles.infoBlock}>
            <span>
              Default Custom Fields will appear in all Company profiles under
              the Details tab.
            </span>
          </div>
        </div>

        <div className={styles.applicantsWrp}>
          <div className={styles.buttonWrp}>
            {exportLoading && <Loading />}

            {!exportLoading && (
              <div>
                <Button
                  className={
                    customFieldPageNr?.total
                      ? styles.exportButton
                      : styles.disabledBtn
                  }
                  onClick={() =>
                    customFieldPageNr?.total > 0 && handleExportList()
                  }
                  btn={
                    customFieldPageNr?.total
                      ? BtnType.OUTLINE
                      : BtnType.DISABLED
                  }
                  disabled={customFieldPageNr?.total <= 0}
                >
                  EXPORT
                </Button>
                <CSVLink
                  data={csvData}
                  filename="customFields.csv"
                  style={{ display: "none" }}
                  ref={csvLinkRef}
                />
              </div>
            )}
            <div className={styles.totalCount}>
              {customFieldPageNr?.total || "0"} Custom Fields
            </div>
          </div>
          <ReactTable
            key={tableKey}
            data={customFields
              .sort(
                (a, b) =>
                  a.custom_field_group.position - b.custom_field_group.position
              )
              .sort((first, next) => {
                if (
                  first.custom_field_group.name === next.custom_field_group.name
                ) {
                  return first.position - next.position;
                }
                return 0;
              })}
            columns={columns}
            loadingText="Updating all records. This may take a moment."
            loading={loading}
            className={styles.table}
            showPagination={false}
            pageSize={pageSize}
          />
          <div className={styles.pagination}>
            <div
              className={
                page - 1 === 0 ? styles.disabledButton : styles.paginationButton
              }
              onClick={handlePrevClick}
            >
              Previous
            </div>
            <div className={styles.paginationSettings}>
              <span style={styles.pageNr}>
                <span>
                  <input
                    onChange={(e) => handlePageSelect(e)}
                    value={page}
                    className={styles.inputBox}
                    type="number"
                    min="1"
                  />
                </span>
                <span>of</span>
                <span> {customFieldPageNr?.pages}</span>
              </span>
            </div>
            <button
              className={isNextDisabled()}
              onClick={handleNextClick}
              disabled={page + 1 > customFieldPageNr?.pages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCustomFields;

import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, groupBy, mergeMap, catchError } from "rxjs/operators";
import {
  PROJECT_STAGES_LIST_GET_ATTEMPT,
  projectStagesListGetSuccess,
  PROJECT_STAGE_PUT_ATTEMPT,
  projectStagePutSuccess,
  PROJECT_STAGE_NEW_ATTEMPT,
  projectStageNewSuccess,
  PROJECT_COMPANY_TASK_STATUS_GET_ATTEMPT,
  projectCompanyTaskStatusSuccess,
  PROJECT_COMPANY_TASK_STATUS_NEW_ATTEMPT,
  projectCompanyTaskStatusNewSuccess,
  PROJECT_COMPANY_TASK_STATUS_REMOVE_ATTEMPT,
  projectCompanyTaskStatusRemoveSuccess,
  PROJECT_COMPANY_TASK_STATUS_SAVE_ATTEMPT,
  projectCompanyTaskStatusSaveSuccess,
  PROJECT_STAGE_REMOVE_ATTEMPT,
  projectStageRemoveSuccess,
  PROJECT_STAGE_COMPANIES_LIST_ATTEMPT,
  projectStageCompaniesGetSuccess,
  PROJECT_STAGE_COMPANY_UPDATE_ATTEMPT,
  projectStageCompanyUpdateSuccess,
  PROJECT_STAGE_ORGANIZATIONS_GET_ATTEMPT,
  projectStageOrganizationsGetSuccess,
  PROJECT_WIDE_TASKS_GET_ATTEMPT,
  projectWideTasksGetSuccess,
  ALL_PROJECT_STAGES_LIST_GET_ATTEMPT,
  getALLprojectStagesListSuccess,
  ALL_TEMPLATES_PROJECT_STAGES_LIST_GET_ATTEMPT,
  getAllTemplatesProjectStagesSuccess,
} from "./stages.action";
import {
  httpGet,
  httpPatch,
  httpPost,
  httpDelete,
  errorHandler,
  pageMeta,
} from "../../../../common/httpCall";

export const epicGetProjectWideTasks = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_WIDE_TASKS_GET_ATTEMPT),
    switchMap(({ payload: { projectId, enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v2",
        call: `projects/${projectId}/project_wide_tasks`,
      }).pipe(
        map((result) => projectWideTasksGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetProjectStageOrganizations = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_STAGE_ORGANIZATIONS_GET_ATTEMPT),
    groupBy(
      (action) => action.payload.stageId,
      (action) => action
    ),
    mergeMap((group) =>
      group.pipe(
        switchMap(({ payload: { projectId, stageId, enqueueSnackbar } }) =>
          httpGet({
            call: `projects/${projectId}/stages/${stageId}/organizations`, // NEW_API
          }).pipe(
            map((result) =>
              projectStageOrganizationsGetSuccess({ result, stageId })
            ),
            catchError((err) => errorHandler(err, enqueueSnackbar))
          )
        )
      )
    )
  );

export const epicGetProjectStagesList = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_STAGES_LIST_GET_ATTEMPT),
    switchMap(({ payload: { councilId, projectId, enqueueSnackbar } }) =>
      httpGet({
        // call: `projects/${payload}/stages/all`,
        call: `projects/${projectId}/stages`, // NEW_API`,
      }).pipe(
        map((result) => projectStagesListGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetALLProjectStages = (action$) =>
  action$.pipe(
    ofType(ALL_PROJECT_STAGES_LIST_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        call: "stages",
      }).pipe(
        map((result) => getALLprojectStagesListSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetAllTemplatesProjectStagesSuccess = (action$) =>
  action$.pipe(
    ofType(ALL_TEMPLATES_PROJECT_STAGES_LIST_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        call: "stages",
        apiVersion: "v2",
      }).pipe(
        map((result) => getAllTemplatesProjectStagesSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicUpdateProjectStage = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_STAGE_PUT_ATTEMPT),
    switchMap(({ payload: { projectId, stageId, stage, enqueueSnackbar } }) =>
      httpPatch({
        call: `project_stages/${stageId}?project_id=${projectId}&stage_id=${stageId}`, // NEW_API
        data: {
          project_stage: { ...stage, project_id: projectId, stage_id: stageId },
        },
      }).pipe(
        map((result) => projectStagePutSuccess(result.response)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicNewProjectStage = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_STAGE_NEW_ATTEMPT),
    switchMap(({ payload: { projectId, stage, enqueueSnackbar } }) =>
      httpPost({
        call: `projects/${projectId}/stages`, // NEW_API
        // call: `projects/${projectId}/stages/new`,
        data: stage,
      }).pipe(
        map((result) => projectStageNewSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicRemoveProjectStage = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_STAGE_REMOVE_ATTEMPT),
    switchMap(({ payload: { projectId, stageId, enqueueSnackbar } }) =>
      httpDelete({
        call: `projects/${projectId}/stages/${stageId}`, // NEW_API
      }).pipe(
        map((result) => projectStageRemoveSuccess({ result, stageId })),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetProjectStageCompanies = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_STAGE_COMPANIES_LIST_ATTEMPT),
    groupBy(
      (action) => action.payload.stageId,
      (action) => action
    ),
    mergeMap((group) =>
      group.pipe(
        switchMap(
          ({
            payload: {
              projectId,
              stageId,
              gsk,
              patient_org,
              page,
              enqueueSnackbar,
              cb,
            },
          }) =>
            httpGet({
              call: `projects/${projectId}/stages/${stageId}/companies?gsk=${gsk}&patient_org=${patient_org}&items=20&page=${
                page || 1
              }`, // NEW_API
            }).pipe(
              map((result) => {
                if (cb) {
                  const meta = pageMeta(result);
                  cb({
                    ...meta,
                    response: result.response,
                  });
                }

                return projectStageCompaniesGetSuccess({ result, stageId });
              }),
              catchError((err) => errorHandler(err, enqueueSnackbar))
            )
        )
      )
    )
  );

const getPayload = (payload) => {
  if (payload.productId) {
    return {
      project_company: {
        is_product: true,
        product_id: payload.productId,
        project_id: payload.projectId,
        added_by_id: payload.userId,
        stage_id: payload.stageId,
        position: payload.position,
      },
    };
  }

  return {
    project_company: {
      project_id: payload.projectId,
      company_id: payload.companyId,
      gsk_patient_organization_id: payload.gsk_patient_organization_id,
      added_by_id: payload.userId,
      stage_id: payload.stageId,
      position: payload.position,
    },
  };
};

const getUrl = (payload) => {
  if (payload.projectId) {
    const id = payload.isProduct ? payload.productId : payload.companyId;
    return `projects/${payload.projectId}/companies/${id}?gsk=${payload.gsk}&patient_org=${payload.patient_org}`;
  } else {
    return `projects/${payload.projectId}/companies/${
      payload.patient_org
        ? payload.gsk_patient_organization_id
        : payload.companyId
    }?gsk=${payload.gsk}&patient_org=${payload.patient_org}`;
  }
};

export const epicUpdateStageCompany = (action$) =>
  action$.pipe(
    ofType(PROJECT_STAGE_COMPANY_UPDATE_ATTEMPT),
    switchMap(({ payload }) =>
      httpPatch({
        call: getUrl(payload),
        data: getPayload(payload),
      }).pipe(
        map((result) => {
          if (payload.enqueueSnackbar) {
            payload.enqueueSnackbar("Successfully changed the stage", {
              variant: "success",
            });
          }

          return projectStageCompanyUpdateSuccess(result);
        }),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const epicCompanyTaskStatus = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_COMPANY_TASK_STATUS_GET_ATTEMPT),
    groupBy(
      (action) => action.payload.taskId + action.payload.companyId,
      (action) => action
    ),
    mergeMap((group) =>
      group.pipe(
        switchMap(
          ({ payload: { projectId, taskId, companyId, enqueueSnackbar } }) =>
            httpGet({
              call: `projects/${projectId}/tasks/${taskId}/companies/${companyId}/status`, // OLD_API
            }).pipe(
              map((result) =>
                projectCompanyTaskStatusSuccess({
                  taskId,
                  companyId,
                  data: result.response,
                })
              ),
              catchError((err) => errorHandler(err, enqueueSnackbar))
            )
        )
      )
    )
  );

export const epicNewCompanyTaskStatus = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_COMPANY_TASK_STATUS_NEW_ATTEMPT),
    groupBy(
      (action) => action.payload.taskId + action.payload.companyId,
      (action) => action
    ),
    mergeMap((group) =>
      group.pipe(
        switchMap(
          ({
            payload: { projectId, taskId, companyId, userId, enqueueSnackbar },
          }) =>
            httpPost({
              call: `projects/${projectId}/tasks/${taskId}/companies/${companyId}/new`, // OLD_API
              data: {
                task_status: {
                  project_id: projectId,
                  project_criteria_id: taskId,
                  company_id: companyId,
                  user_id: userId,
                  completed: true,
                },
              },
            }).pipe(
              map((result) =>
                projectCompanyTaskStatusNewSuccess({
                  taskId,
                  companyId,
                  data: result.response,
                })
              ),
              catchError((err) => errorHandler(err, enqueueSnackbar))
            )
        )
      )
    )
  );

export const epicRemoveCompanyTaskStatus = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_COMPANY_TASK_STATUS_REMOVE_ATTEMPT),
    groupBy(
      (action) => action.payload.taskId + action.payload.companyId,
      (action) => action
    ),
    mergeMap((group) =>
      group.pipe(
        switchMap(
          ({ payload: { projectId, taskId, companyId, enqueueSnackbar } }) =>
            httpDelete({
              call: `projects/${projectId}/tasks/${taskId}/companies/${companyId}/status`, // OLD_API
            }).pipe(
              map((result) =>
                projectCompanyTaskStatusRemoveSuccess({
                  taskId,
                  companyId,
                  data: result.response,
                })
              ),
              catchError((err) => errorHandler(err, enqueueSnackbar))
            )
        )
      )
    )
  );

export const epicSaveCompanyTaskStatus = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_COMPANY_TASK_STATUS_SAVE_ATTEMPT),
    switchMap(
      ({ payload: { projectId, taskId, companyId, enqueueSnackbar } }) =>
        httpPost({
          call: `projects/${projectId}/tasks/${taskId}/companies/${companyId}/status`, // OLD_API
        }).pipe(
          map((result) =>
            projectCompanyTaskStatusSaveSuccess({
              taskId,
              companyId,
              data: result.response,
            })
          ),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

const stagesEpic = combineEpics(
  epicGetProjectWideTasks,
  epicGetProjectStagesList,
  epicUpdateProjectStage,
  epicNewProjectStage,
  epicRemoveProjectStage,
  epicGetProjectStageCompanies,
  epicUpdateStageCompany,
  epicCompanyTaskStatus,
  epicNewCompanyTaskStatus,
  epicRemoveCompanyTaskStatus,
  epicSaveCompanyTaskStatus,
  epicGetProjectStageOrganizations,
  epicGetALLProjectStages,
  epicGetAllTemplatesProjectStagesSuccess
);

export default stagesEpic;

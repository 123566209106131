import React, { useRef } from "react";
import { Formik } from "formik";

import { useModelPopup } from "../../../../../common/hooks/index";
import { ModalBody, ModalFooter } from "../../../../../application/modal";
import {
  BtnType,
  Button,
  DropSelect,
  Label,
  TextBox,
} from "../../../../primitives";
import { initLocationModel, addLocationValidation } from "./location.constants";
import { COUNTRIES_BY_CONTINENT } from "../../../../../common/countries-by-continent";

const AddNewLocation = ({ handleAddNewLocation }) => {
  const popup = useModelPopup();
  const formRef = useRef();

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleOnSubmit = (values) => {
    const city = values.location_city || "";
    const region = values.location_region || "";
    const country = values.location_country || "";
    const { continent } = COUNTRIES_BY_CONTINENT.find(
      (c) => c.country === country,
    );
    const description = Object.keys(values).reduce((acc, key) => {
      if (!values[key].length) return acc;
      return `${acc}${values[key]}, `;
    }, "");

    if (!description.length) {
      return;
    }

    handleAddNewLocation({
      ...values,
      continent,
      name: city || region || country || continent,
      description,
    });

    const timeout = setTimeout(() => {
      popup.hide();
      clearTimeout(timeout);
    }, 200);
  };

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        validationSchema={addLocationValidation}
        initialValues={initLocationModel}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleOnSubmit(values);
        }}
      >
        {({ handleSubmit, handleReset, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <div className="row">
                <div className="col-12">
                  <Label>City</Label>
                  <TextBox
                    type="text"
                    name="location_city"
                    placeholder="City"
                    formProps={formikprops}
                    className="d-block"
                  />
                  <Label>State/Region</Label>
                  <TextBox
                    type="text"
                    name="location_region"
                    placeholder="Region"
                    formProps={formikprops}
                    className="d-block"
                  />
                </div>
                <div className="col-12">
                  <Label>Country</Label>
                  <DropSelect
                    placeholder=""
                    name="location_country"
                    options={COUNTRIES_BY_CONTINENT}
                    labelField="country"
                    valueField="country"
                    searchBy="country"
                    dropdownPosition="auto"
                    formProps={formikprops}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button type="submit" btn={BtnType.REGULAR}>
                Add
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default AddNewLocation;

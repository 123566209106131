import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  apiStatus,
  httpGet,
  httpPost,
  httpDelete,
  errorHandler,
} from "../httpCall";
import {
  SUGGESTIONS_ATTEMPT,
  suggestionsSuccess,
  SUGGESTION_FOLLOW_ATTEMPT,
  suggestionFollowSuccess,
  COMPANY_FOLLOW_MANY_ATTEMPT,
  companyFollowManySuccess,
  COMPANY_UNFOLLOW_MANY_ATTEMPT,
  companyUnfollowManySuccess,
} from "../actions/common.action";

const epicGetSuggestions = (action$) => action$.pipe(
  ofType(SUGGESTIONS_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar } }) => httpGet({
    call: "user/suggested_companies",
  }).pipe(
    map((result) => suggestionsSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const epicSuggestionFollow = (action$) => action$.pipe(
  ofType(SUGGESTION_FOLLOW_ATTEMPT),
  switchMap(({ payload: { data, enqueueSnackbar, cb } }) => httpPost({
    call: "follow_relationships",
    data: {
      follow_relationship: {
        following_id: data.company_id,
      },
    },
  }).pipe(
    map((result) => {
      if (result.status === apiStatus.SUCCESS) {
        if (cb) {
          cb(result);
        }
        enqueueSnackbar(`You now follow ${data.name}`, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(
          "There was an error during executing this action. Please try one more time.",
          {
            variant: "error",
          },
        );
      }

      return suggestionFollowSuccess(result);
    }),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const epicSuggestionFollowMany = (action$) => action$.pipe(
  ofType(COMPANY_FOLLOW_MANY_ATTEMPT),
  switchMap(({ payload: { listId, enqueueSnackbar } }) => httpPost({
    call: `lists/${listId}/follow`,
  }).pipe(
    map((result) => {
      if (result.status === apiStatus.SUCCESS) {
        enqueueSnackbar("You are now following this list", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Something wrong happened", {
          variant: "error",
        });
      }

      return companyFollowManySuccess(result);
    }),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const epicSuggestionUnfollowMany = (action$) => action$.pipe(
  ofType(COMPANY_UNFOLLOW_MANY_ATTEMPT),
  switchMap(({ payload: { listId, enqueueSnackbar } }) => httpDelete({
    call: `lists/${listId}/unfollow`,
  }).pipe(
    map((result) => {
      if (result.status === apiStatus.SUCCESS) {
        enqueueSnackbar("You are no longer following this list", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Something wrong happened", {
          variant: "error",
        });
      }

      return companyUnfollowManySuccess(result);
    }),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const suggestionsEpic = combineEpics(
  epicGetSuggestions,
  epicSuggestionFollow,
  epicSuggestionFollowMany,
  epicSuggestionUnfollowMany,
);

export default suggestionsEpic;

import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import styles from "./uploadImg.module.scss";
import { Label } from "../index";
import useS3FileUpload from "../../../common/hooks/s3FileUplodar.hook";
import LinearProgressWithLabel from "../../../common/components/linearProgressWithLabel";

const UploadImgComponent = (props) => {
  const {
    label,
    name,
    formProps = {},
    uploadDirName,
    onChange,
    imgStyle,
    uploadBlockStyle,
    choosePhoto,
    imgSize,
    allowRemovePhoto = true,
    speaker,
    image,
    align,
    setImgLoading,
    customText,
  } = props;
  const { values } = formProps;
  const inputRef = useRef(null);
  const uploadBlockRef = useRef(null);
  const [img, setImg] = useState(null);
  const { fileUpload, fileDelete } = useS3FileUpload();
  const [uploadValues, setUploadValues] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const allowedExtensions = [
    "png",
    "jpg",
    "jpeg",
    "gif",
    "tiff",
    "bmp",
    "eps",
    "raw",
    "cr2",
    "nef",
    "orf",
    "sr2",
  ];

  const getExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  useEffect(() => {
    if (values && values[name]) {
      setImg(values[name]);
    }
    return () => null;
  }, [values]);

  useEffect(() => {
    if (image) {
      setImg(image);
    }
    if (image === "") {
      setImg(null);
    }
  }, [image]);

  const onUploadClick = () => {
    inputRef.current.click();
  };

  const removeImg = async () => {
    const url = values[name]?.split("/").slice(3, 6).join("/");
    await fileDelete(url).then((res) => {
      setImg(null);
      onChange(null);
      inputRef.current.value = "";
    });
  };

  const uploadHandler = async (e, action) => {
    e.preventDefault();
    // we want to let user know that he is dragging, so we change the border color for the block
    if (action === "drag" && uploadBlockRef?.current) {
      uploadBlockRef.current.style.border = "1px solid #7b8794";
      return;
    }

    let file;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      file = e.target.files[0];
    }

    if (action === "drop") {
      // on drop we just go to previous border styles
      uploadBlockRef.current.style.border = "1px solid #e4e7eb";

      if (!e || !e.dataTransfer.items.length) {
        return;
      }

      file = e.dataTransfer.items[0].getAsFile();
    }

    if (!file) {
      return;
    }
    if (
      file.size <= 100000000 &&
      allowedExtensions.indexOf(getExtension(file.name)) !== -1
    ) {
      setLoading(true);
      if (setImgLoading) {
        setImgLoading(true);
      }
      await fileUpload(file, uploadDirName, setUploadValues, file.name, 0).then(
        (res) => {
          if (res && res.location) {
            setLoading(false);
            setImg(res.location);
            onChange(res.location);
            setUploadValues([]);
            if (setImgLoading) {
              setImgLoading(false);
            }
          }
        }
      );
    } else {
      if (allowedExtensions.indexOf(getExtension(file.name)) === -1) {
        enqueueSnackbar("Extension is not allowed", {
          variant: "error",
        });
      }
      if (file.size > 100000000) {
        enqueueSnackbar(
          "We could not upload your image because your file size is too big. Please make sure the file is less than 20 MB",
          {
            variant: "error",
          }
        );
      }
      setLoading(false);
      if (setImgLoading) {
        setImgLoading(false);
      }
      e.target.value = "";
    }
  };

  return (
    <div className={[styles.uploadImgWrp, imgStyle].join(" ")}>
      <Label>{label}</Label>
      <input
        ref={inputRef}
        type="file"
        id="imgUpload"
        style={{ display: "none" }}
        onChange={(e) => uploadHandler(e, "click")}
      />
      <div>
        {" "}
        {loading ? (
          <LinearProgressWithLabel value={uploadValues[0]?.progress || 0} />
        ) : (
          <div
            className={
              align === "center"
                ? styles.uploadBlockWrpCenter
                : styles.UploadBlockWrp
            }
          >
            {" "}
            <div
              ref={uploadBlockRef}
              className={[uploadBlockStyle, styles.uploadBlock].join(" ")}
              onClick={onUploadClick}
              onDrop={(e) => uploadHandler(e, "drop")}
              onDragOver={(e) => uploadHandler(e, "drag")}
            >
              {img ? (
                <img
                  className={imgSize}
                  // height="159"
                  // width="159"
                  alt=""
                  src={img}
                />
              ) : (
                <span>
                  {customText ? customText : "Drag and drop image here"}
                </span>
              )}
            </div>
            {allowRemovePhoto && (
              <span
                className={[styles.imgChooser, choosePhoto].join(" ")}
                onClick={img ? removeImg : onUploadClick}
              >
                {img ? "Remove image" : "Choose image"}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(UploadImgComponent);

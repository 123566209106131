export const EVENTS_GET_ATTEMPT = "EVENTS_GET_ATTEMPT";
export const eventsGet = (payload) => ({ type: EVENTS_GET_ATTEMPT, payload });
export const EVENTS_GET_SUCCESS = "EVENTS_GET_SUCCESS";
export const eventsGetSuccess = (payload) => ({
  type: EVENTS_GET_SUCCESS,
  payload,
});

export const EVENTS_GET_SPEAKERS_ATTEMPT = "EVENTS_GET_SPEAKERS_ATTEMPT";
export const eventsGetSpeakers = (payload) => ({
  type: EVENTS_GET_SPEAKERS_ATTEMPT,
  payload,
});
export const EVENTS_GET_SPEAKERS_SUCCESS = "EVENTS_GET_SPEAKERS_SUCCESS";
export const eventsGetSpeakersSuccess = (payload) => ({
  type: EVENTS_GET_SPEAKERS_SUCCESS,
  payload,
});

export const EVENTS_ATTENDING_ATTEMPT = "EVENTS_ATTENDING_ATTEMPT";
export const eventsAttending = (payload) => ({
  type: EVENTS_ATTENDING_ATTEMPT,
  payload,
});
export const EVENTS_ATTENDING_SUCCESS = "EVENTS_ATTENDING_SUCCESS";
export const eventsAttendingSuccess = (payload) => ({
  type: EVENTS_ATTENDING_SUCCESS,
  payload,
});

export const EVENTS_REMOVE_ATTENDING_ATTEMPT = "EVENTS_REMOVE_ATTENDING_ATTEMPT";
export const eventsRemoveAttending = (payload) => ({
  type: EVENTS_REMOVE_ATTENDING_ATTEMPT,
  payload,
});
export const EVENTS_REMOVE_ATTENDING_SUCCESS = "EVENTS_REMOVE_ATTENDING_SUCCESS";
export const eventsRemoveAttendingSuccess = (payload) => ({
  type: EVENTS_REMOVE_ATTENDING_SUCCESS,
  payload,
});

export const EVENTS_GET_ATTENDING_ATTEMPT = "EVENTS_GET_ATTENDING_ATTEMPT";
export const eventsGetAttending = (payload) => ({
  type: EVENTS_GET_ATTENDING_ATTEMPT,
  payload,
});
export const EVENTS_GET_ATTENDING_SUCCESS = "EVENTS_GET_ATTENDING_SUCCESS";
export const eventsGetAttendingSuccess = (payload) => ({
  type: EVENTS_GET_ATTENDING_SUCCESS,
  payload,
});

export const EVENTS_GET_ALL_ATTENDING_ATTEMPT = "EVENTS_GET_ALL_ATTENDING_ATTEMPT";
export const eventsGetAllAttending = (payload) => ({
  type: EVENTS_GET_ALL_ATTENDING_ATTEMPT,
  payload,
});
export const EVENTS_GET_ALL_ATTENDING_SUCCESS = "EVENTS_GET_ALL_ATTENDING_SUCCESS";
export const eventsGetAllAttendingSuccess = (payload) => ({
  type: EVENTS_GET_ALL_ATTENDING_SUCCESS,
  payload,
});

export const EVENTS_POST_ATTENDING_ATTEMPT = "EVENTS_POST_ATTENDING_ATTEMPT";
export const eventsPostAttending = (payload) => ({
  type: EVENTS_POST_ATTENDING_ATTEMPT,
  payload,
});

export const EVENTS_POST_ATTENDING_SUCCESS = "EVENTS_POST_ATTENDING_SUCCESS";
export const eventsPostSuccess = (payload) => ({
  type: EVENTS_POST_ATTENDING_SUCCESS,
  payload,
});

export const EVENTS_PUT_ATTENDING_ATTEMPT = "EVENTS_PUT_ATTENDING_ATTEMPT";
export const eventsPutAttending = (payload) => ({
  type: EVENTS_PUT_ATTENDING_ATTEMPT,
  payload,
});

export const EVENTS_PUT_ATTENDING_SUCCESS = "EVENTS_PUT_ATTENDING_SUCCESS";
export const eventsPutSuccess = (payload) => ({
  type: EVENTS_PUT_ATTENDING_SUCCESS,
  payload,
});

export const EVENTS_DELETE_ATTENDING_ATTEMPT = "EVENTS_DELETE_ATTENDING_ATTEMPT";
export const eventsDeleteAttending = (payload) => ({
  type: EVENTS_DELETE_ATTENDING_ATTEMPT,
  payload,
});

export const EVENTS_DELETE_ATTENDING_SUCCESS = "EVENTS_DELETE_ATTENDING_SUCCESS";
export const eventsDeleteSuccess = (payload) => ({
  type: EVENTS_DELETE_ATTENDING_SUCCESS,
  payload,
});
export const EVENTS_GET_CURRENT_LIST_ATTEMPT = "EVENTS_GET_CURRENT_LIST_ATTEMPT";
export const eventsCurrnetListGet = (payload) => ({
  type: EVENTS_GET_CURRENT_LIST_ATTEMPT,
  payload,
});
export const EVENTS_GET_CURRENT_LIST_SUCCESS = "EVENTS_GET_CURRENT_LIST_SUCCESS";
export const eventsCurrentListGetSuccess = (payload) => ({
  type: EVENTS_GET_CURRENT_LIST_SUCCESS,
  payload,
});

export const EVENTS_GET_PAST_LIST_ATTEMPT = "EVENTS_GET_PAST_LIST_ATTEMPT";
export const eventsPastListGet = (payload) => ({
  type: EVENTS_GET_PAST_LIST_ATTEMPT,
  payload,
});
export const EVENTS_GET_PAST_LIST_SUCCESS = "EVENTS_GET_PAST_LIST_SUCCESS";
export const eventsPastListGetSuccess = (payload) => ({
  type: EVENTS_GET_PAST_LIST_SUCCESS,
  payload,
});

export const EVENTS_GET_CURRENT_ATTENDEES_ATTEMPT = "EVENTS_GET_CURRENT_ATTENDEES_ATTEMPT";
export const eventsAttendeesGet = (payload) => ({
  type: EVENTS_GET_CURRENT_ATTENDEES_ATTEMPT,
  payload,
});
export const EVENTS_GET_CURRENT_ATTENDEES_SUCCESS = "EVENTS_GET_CURRENT_ATTENDEES_SUCCESS";
export const eventsAttendeesGetSuccess = (payload) => ({
  type: EVENTS_GET_CURRENT_ATTENDEES_SUCCESS,
  payload,
});

export const EVENTS_CREATE_NEW_EVENT_ATTEMPT = "EVENTS_CREATE_NEW_EVENT_ATTEMPT";
export const eventsCreateNewEvent = (payload) => ({
  type: EVENTS_CREATE_NEW_EVENT_ATTEMPT,
  payload,
});
export const EVENTS_CREATE_NEW_EVENT_SUCCESS = "EVENTS_CREATE_NEW_EVENT_SUCCESS";
export const eventsCreateNewEventSuccess = (payload) => ({
  type: EVENTS_CREATE_NEW_EVENT_SUCCESS,
  payload,
});

export const EVENTS_EDIT_EVENT_ATTEMPT = "EVENTS_EDIT_EVENT_ATTEMPT";
export const eventsEditEvent = (payload) => ({
  type: EVENTS_EDIT_EVENT_ATTEMPT,
  payload,
});

export const EVENTS_EDIT_EVENT_SUCCESS = "EVENTS_EDIT_EVENT_SUCCESS";
export const eventsEditEventSuccess = (payload) => ({
  type: EVENTS_EDIT_EVENT_SUCCESS,
  payload,
});

export const EVENTS_DELETE_EVENT_ATTEMPT = "EVENTS_DELETE_EVENT_ATTEMPT";
export const eventsDeleteEvent = (payload) => ({
  type: EVENTS_DELETE_EVENT_ATTEMPT,
  payload,
});

export const EVENTS_DELETE_EVENT_SUCCESS = "EVENTS_DELETE_EVENT_SUCCESS";
export const eventsDeleteEventSuccess = (payload) => ({
  type: EVENTS_DELETE_EVENT_SUCCESS,
  payload,
});

export const EVENTS_ADD_RATING_ATTEMPT = "EVENTS_ADD_RATING_ATTEMPT";
export const eventsAddRating = (payload) => ({
  type: EVENTS_ADD_RATING_ATTEMPT,
  payload,
});

export const EVENTS_ADD_RATING_SUCCESS = "EVENTS_ADD_RATING_SUCCESS";
export const eventsAddRatingSuccess = (payload) => ({
  type: EVENTS_ADD_RATING_SUCCESS,
  payload,
});

export const EVENTS_REMOVE_SPEAKER_ATTEMPT = "EVENTS_REMOVE_SPEAKER_ATTEMPT";
export const eventsRemoveSpeakers = (payload) => ({
  type: EVENTS_REMOVE_SPEAKER_ATTEMPT,
  payload,
});

export const EVENTS_REMOVE_SPEAKER_SUCCESS = "EVENTS_REMOVE_SPEAKER_SUCCESS";
export const eventsRemoveSpeakersSuccess = (payload) => ({
  type: EVENTS_REMOVE_SPEAKER_SUCCESS,
  payload,
});

export const EVENTS_ADD_SPEAKER_ATTEMPT = "EVENTS_ADD_SPEAKER_ATTEMPT";
export const eventsAddSpeaker = (payload) => ({
  type: EVENTS_ADD_SPEAKER_ATTEMPT,
  payload,
});

export const EVENTS_ADD_SPEAKER_SUCCESS = "EVENTS_ADD_SPEAKER_SUCCESS";
export const eventsAddSpeakerSuccess = (payload) => ({
  type: EVENTS_ADD_SPEAKER_SUCCESS,
  payload,
});

export const EVENT_SHARE_ATTEMPT = "EVENT_SHARE_ATTEMPT";
export const eventShare = (payload) => ({
  type: EVENT_SHARE_ATTEMPT,
  payload,
});

export const EVENT_SHARE_SUCCESS = "EVENT_SHARE_SUCCESS";
export const eventShareSuccess = (payload) => ({
  type: EVENT_SHARE_SUCCESS,
  payload,
});

export const CLEAR_SELECTED_EVENT = "CLEAR_SELECTED_EVENT";
export const clearSelectedEvent = (payload) => ({
  type: CLEAR_SELECTED_EVENT,
  payload,
});

export const SET_CURRENT_EVENT_PAGE = "SET_CURRENT_EVENT_PAGE";
export const setCurrentEventPage = (payload) => ({
  type: SET_CURRENT_EVENT_PAGE,
  payload,
});

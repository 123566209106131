import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { ModalBody } from "application/modal/index";
import { Formik, Form, FieldArray } from "formik";
import Select from "react-dropdown-select";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";

import {
  setExportOpportunityStagePayload,
  setExportRelationshipStatusPayload,
} from "modules/admin/adminIntegrations/adminIntegrations.actions";
import { BtnType, Button as Btn } from "../../../primitives";
import styles from "./mapExportFieldValues.module.scss";

const MapExportFieldValues = (props) => {
  const { opportunityOptions, relationshipStage } = props;
  const {
    preSelectedOpportunityStagePayload,
    preSelectedRelationshipStatusValues,
  } = props;

  const [selectedOpportunityStageValues, setSelectedOpportunityStageValues] =
    useState([]);
  const [
    isSelectedRelationshipStatusValue,
    setIsSelectedRelationshipStatusValue,
  ] = useState(true);
  const [selectedOpportunityStageValue, setSelectedOpportunityStageValue] =
    useState({});
  const [selectedRelationshipStatusArray, setSelectedRelationshipStatusArray] =
    useState([]);
  const [
    selectedRelationshipStatusValues,
    setSelectedRelationshipStatusValues,
  ] = useState([]);
  const [, setIsDropOpened] = useState(false);
  const [selected] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const relationshipRef = useRef("");
  const opportunityRef = useRef("");

  let boundArrayHelpers;

  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  const handleRelationshipStatusArray = (e) => {
    setSelectedRelationshipStatusArray(e.map((i) => i.text));
  };

  const clearRelationshipValue = () => {
    relationshipRef.current.state.values = [];
  };

  const clearOpportunityValue = () => {
    opportunityRef.current.state.values = [];
  };

  useEffect(() => {
    if (preSelectedOpportunityStagePayload?.length) {
      setSelectedOpportunityStageValues(
        preSelectedOpportunityStagePayload ?? [],
      );
      setSelectedRelationshipStatusValues(
        preSelectedRelationshipStatusValues ?? [],
      );
    }
    setLoading(true);
  }, []);

  useEffect(() => {
    dispatch(setExportOpportunityStagePayload(selectedOpportunityStageValues));
    dispatch(
      setExportRelationshipStatusPayload(selectedRelationshipStatusValues),
    );
  }, [selectedOpportunityStageValues, selectedRelationshipStatusValues]);

  const initialValues = () =>
    Array(selectedRelationshipStatusValues?.length || 0).fill("");

  return (
    loading && (
      <>
        <ModalBody>
          <div className={styles.popupContainer}>
            <Formik
              enableReinitialize
              initialValues={{ friends: initialValues() }}
              render={({ values }) => (
                <Form>
                  <FieldArray
                    name="friends"
                    render={(arrayHelpers) => {
                      bindArrayHelpers(arrayHelpers);
                      return (
                        <div>
                          <div className={styles.submitContainer}>
                            {!selected && (
                              <div className={styles.submitBtn}>
                                <h3>Opportunity Stage Value Mapping</h3>
                              </div>
                            )}
                          </div>
                          <div className={styles.fieldsContainer}>
                            <>
                              <div className={styles.headTitle}>
                                <div className={styles.headerContainer}>
                                  <div className={styles.mapsTextHead}>
                                    Traction
                                  </div>
                                  <div className={styles.mapsTextHead}>
                                    Salesforce
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                          <div className={styles.fieldsContainer}>
                            {values.friends.map((_, index) => (
                              <div>
                                {values.friends.length && (
                                  <>
                                    <div
                                      className={styles.headTitle}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "1rem",
                                        marginTop: "1rem",
                                      }}
                                    >
                                      <>
                                        <div className={styles.mapsText}>
                                          {selectedRelationshipStatusValues[
                                            index
                                          ]?.join(" , ")}
                                        </div>
                                        <Button className={styles.mapsButton}>
                                          Maps
                                        </Button>
                                        <div className={styles.mapsText}>
                                          {
                                            selectedOpportunityStageValues[
                                              index
                                            ]?.text
                                          }
                                        </div>
                                        <Chip
                                          className={styles.chipButton}
                                          onDelete={() => {
                                            setSelectedOpportunityStageValues(
                                              selectedOpportunityStageValues.filter(
                                                (unUsed, selectedIndex) =>
                                                  selectedIndex !== index,
                                              ),
                                            );

                                            setSelectedRelationshipStatusValues(
                                              selectedRelationshipStatusValues.filter(
                                                (unUsed, selectedIndex) =>
                                                  selectedIndex !== index,
                                              ),
                                            );
                                          }}
                                        />
                                      </>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    }}
                  />
                  <div className={styles.fieldsContainer}>
                    <div className={styles.headTitle}>
                      <>
                        {!selected && (
                          <div className={`row ${styles.dropDownContainer}`}>
                            <div className="col-5">
                              <Select
                                placeholder="Select RELATIONSHIP STATUS value"
                                labelField="text"
                                valueField="text"
                                ref={relationshipRef}
                                multi
                                closeOnSelect
                                searchBy="text"
                                onChange={(event) => {
                                  handleRelationshipStatusArray(event);
                                  setIsSelectedRelationshipStatusValue(false);
                                }}
                                options={relationshipStage.filter(
                                  (item) =>
                                    !selectedRelationshipStatusValues
                                      .flat()
                                      .includes(item.text),
                                )}
                              />
                            </div>
                            <div className="col-5">
                              <Select
                                disabled={isSelectedRelationshipStatusValue}
                                placeholder="Select OPPORTUNITY STAGE value"
                                ref={opportunityRef}
                                labelField="text"
                                valueField="text"
                                searchBy="text"
                                onChange={(event) => {
                                  setSelectedOpportunityStageValue({
                                    text: event[0].text,
                                    value: event[0].text,
                                  });
                                }}
                                options={opportunityOptions.filter(
                                  (item) =>
                                    !selectedOpportunityStageValues
                                      .map((v) => v.text)
                                      .includes(item.text),
                                )}
                              />
                            </div>
                            <div>
                              <Btn
                                btn={BtnType.REGULAR}
                                className={styles.addButton}
                                disabled={
                                  values.friends.length ===
                                    relationshipStage.length ||
                                  isSelectedRelationshipStatusValue
                                }
                                onClick={() => {
                                  clearOpportunityValue();
                                  clearRelationshipValue();
                                  setIsSelectedRelationshipStatusValue(true);
                                  boundArrayHelpers.insert(
                                    values.friends.length - 1,
                                    "",
                                  );
                                  setIsDropOpened(false);
                                  setSelectedOpportunityStageValues([
                                    ...selectedOpportunityStageValues,
                                    selectedOpportunityStageValue,
                                  ]);
                                  setSelectedRelationshipStatusValues([
                                    ...selectedRelationshipStatusValues,
                                    selectedRelationshipStatusArray,
                                  ]);
                                }}
                              >
                                +Add
                              </Btn>
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </ModalBody>
      </>
    )
  );
};

export default React.memo(MapExportFieldValues);

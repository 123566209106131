export const IDEAS_GET_ATTEMPT = "IDEAS_GET_ATTEMPT";
export const ideasGetAttempt = (payload) => ({
  type: IDEAS_GET_ATTEMPT,
  payload,
});

export const IDEAS_GET_SUCCESS = "IDEAS_GET_SUCCESS";
export const ideasGetSuccess = (payload) => ({
  type: IDEAS_GET_SUCCESS,
  payload,
});

export const IDEAS_DELETE_ATTEMPT = "IDEAS_DELETE_ATTEMPT";
export const ideasDeleteAttempt = (payload) => ({
  type: IDEAS_DELETE_ATTEMPT,
  payload,
});

export const IDEAS_DELETE_SUCCESS = "IDEAS_DELETE_SUCCESS";
export const ideasDeleteSuccess = (payload) => ({
  type: IDEAS_DELETE_SUCCESS,
  payload,
});

export const FORMS_GET_ATTEMPT = "FORMS_GET_ATTEMPT";
export const formsGetAttempt = (payload) => ({
  type: FORMS_GET_ATTEMPT,
  payload,
});

export const FORMS_GET_SUCCESS = "FORMS_GET_SUCCESS";
export const formsGetSuccess = (payload) => ({
  type: FORMS_GET_SUCCESS,
  payload,
});

export const FORM_GET_ATTEMPT = "FORM_GET_ATTEMPT";
export const formGetAttempt = (payload) => ({
  type: FORM_GET_ATTEMPT,
  payload,
});

export const FORM_GET_SUCCESS = "FORM_GET_SUCCESS";
export const formGetSuccess = (payload) => ({
  type: FORM_GET_SUCCESS,
  payload,
});

export const FORMS_ADD_ATTEMPT = "FORMS_ADD_ATTEMPT";
export const formsAddAttempt = (payload) => ({
  type: FORMS_ADD_ATTEMPT,
  payload,
});

export const FORMS_ADD_SUCCESS = "FORMS_ADD_SUCCESS";
export const formsAddSuccess = (payload) => ({
  type: FORMS_ADD_SUCCESS,
  payload,
});

export const FORMS_DELETE_ATTEMPT = "FORMS_DELETE_ATTEMPT";
export const formsDeleteAttempt = (payload) => ({
  type: FORMS_DELETE_ATTEMPT,
  payload,
});

export const FORMS_DELETE_SUCCESS = "FORMS_DELETE_SUCCESS";
export const formsDeleteSuccess = (payload) => ({
  type: FORMS_DELETE_SUCCESS,
  payload,
});

export const FORMS_PATCH_ATTEMPT = "FORMS_PATCH_ATTEMPT";
export const formsPatchAttempt = (payload) => ({
  type: FORMS_PATCH_ATTEMPT,
  payload,
});

export const FORMS_PATCH_SUCCESS = "FORMS_PATCH_SUCCESS";
export const formsPatchSuccess = (payload) => ({
  type: FORMS_PATCH_SUCCESS,
  payload,
});

export const SECTION_ADD_ATTEMPT = "SECTION_ADD_ATTEMPT";
export const sectionAddAttempt = (payload) => ({
  type: SECTION_ADD_ATTEMPT,
  payload,
});

export const SECTION_ADD_SUCCESS = "SECTION_ADD_SUCCESS";
export const sectionAddSuccess = (payload) => ({
  type: SECTION_ADD_SUCCESS,
  payload,
});

export const SECTION_DELETE_ATTEMPT = "SECTION_DELETE_ATTEMPT";
export const sectionDeleteAttempt = (payload) => ({
  type: SECTION_DELETE_ATTEMPT,
  payload,
});

export const SECTION_DELETE_SUCCESS = "SECTION_DELETE_SUCCESS";
export const sectionDeleteSuccess = (payload) => ({
  type: SECTION_DELETE_SUCCESS,
  payload,
});

export const SECTION_PATCH_ATTEMPT = "SECTION_PATCH_ATTEMPT";
export const sectionPatchAttempt = (payload) => ({
  type: SECTION_PATCH_ATTEMPT,
  payload,
});

export const SECTION_PATCH_SUCCESS = "SECTION_PATCH_SUCCESS";
export const sectionPatchSuccess = (payload) => ({
  type: SECTION_PATCH_SUCCESS,
  payload,
});

export const ADD_LAST_STEP_WORKED_ON = "ADD_LAST_STEP_WORKED_ON";
export const addLastStepWorkedOn = (payload) => ({
  type: ADD_LAST_STEP_WORKED_ON,
  payload,
});

export const POST_SEARCH_IDEAS_ATTEMPT = "POST_SEARCH_IDEAS_ATTEMPT";
export const postSearchIdeasAttempt = (payload) => ({
  type: POST_SEARCH_IDEAS_ATTEMPT,
  payload,
});

export const POST_SEARCH_IDEAS_SUCCESS = "POST_SEARCH_IDEAS_SUCCESS";
export const postSearchIdeasSuccess = (payload) => ({
  type: POST_SEARCH_IDEAS_SUCCESS,
  payload,
});

export const CATCH_ERROR_SUCCESS = "CATCH_ERROR_SUCCESS";
export const catchErrorSuccess = (payload) => ({
  type: CATCH_ERROR_SUCCESS,
  payload,
});

export const SET_IDEA_FORM_FIELDS = "SET_IDEA_FORM_FIELDS";
export const setIdeaFormFields = (payload) => ({
  type: SET_IDEA_FORM_FIELDS,
  payload,
});
export const CLEAR_CURRENT_FORM = "CLEAR_CURRENT_FORM";
export const clearCurrentForm = (payload) => ({
  type: CLEAR_CURRENT_FORM,
  payload,
});

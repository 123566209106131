import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover/Popover";
import { makeStyles } from "@mui/styles";
import styles from "./slider.module.scss";
import { Slider } from "../../../../../../primitives";
import { colgatePalmoliveTexts } from "./slider.constant";

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "15px",
  },
}));

const SliderComponent = (props) => {
  const {
    rating: {
      rating_category_id, name, weight, description, max,
    },
    value = 0,
    onChange = null,
  } = props;
  const [sliderValue, setSliderValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const open = Boolean(anchorEl);

  useEffect(() => {
    setSliderValue(value);
  }, [value]);

  const handleRatingOnChange = (value) => {
    setSliderValue(value);
    if (onChange) {
      onChange({ id: rating_category_id, value });
    }
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.ratingWrp}>
      <Slider value={sliderValue} onChange={handleRatingOnChange} max={max} />
      <div className={styles.ratingTextWrp}>
        <div
          className={styles.ratingLabel}
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {name === colgatePalmoliveTexts.searchText
            ? colgatePalmoliveTexts.replaceText
            : name}{" "}
          ({weight}
          %)
          {description ? (
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {description}
            </Popover>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default React.memo(SliderComponent);

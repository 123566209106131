import { useSelector } from "react-redux";
import React, { useState } from "react";
import classNames from "classnames";

import { useModelPopup } from "common/hooks/index";
import { ModalBody, ModalFooter } from "application/modal";
import {
  BtnType,
  Button,
  Label,
  TextBox,
  MultiSelect,
  Loading,
} from "modules/primitives";
import Radio from "@mui/material/Radio";
import styles from "../adminCompanies.module.scss";

const AddNewTractionCompany = () => {
  const popup = useModelPopup();
  const [isForMainDb, setIsForMainDb] = useState(true);
  const [selectedCouncils, setSelectedCouncils] = useState([]);

  const {
    councilReducer: { allCouncilsList, loading },
  } = useSelector((state) => state);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleChangeRadioState = () => {
    setIsForMainDb((prevState) => !prevState);
  };

  const handleSelectCouncils = (val) => {
    setSelectedCouncils(val);
  };

  return (
    <div>
      <ModalBody>
        <div className={styles.crunchBaseFormWrp}>
          <div className={styles.row}>
            <div className={classNames(styles.col12)}>
              <Label>Company Name</Label>
              <TextBox
                type="text"
                placeholder="Company name"
                className={styles.textBox}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={classNames(styles.col12)}>
              <Label>Company Website</Label>
              <TextBox
                type="text"
                placeholder="https://example.com"
                className={styles.textBox}
              />
            </div>
          </div>

          <div className={styles.radioContainer}>
            <div className={styles.radioRow}>
              <Radio
                checked={isForMainDb}
                onChange={handleChangeRadioState}
                name="radio-buttons"
                className={styles.MuiRadio}
              />
              <span>Treaction Main Data Base</span>
            </div>
          </div>
          <div className={styles.radioContainer}>
            <div className={styles.radioRow}>
              <Radio
                checked={!isForMainDb}
                onChange={handleChangeRadioState}
                name="radio-buttons"
                className={styles.MuiRadio}
              />
              <span>Specific Councils</span>
            </div>
          </div>

          {!isForMainDb && (
            <div className={styles.councilsSelector}>
              {loading ? (
                <Loading />
              ) : (
                <MultiSelect
                  placeholder="Councils"
                  labelField="name"
                  multi
                  valueField="id"
                  searchBy="name"
                  values={selectedCouncils}
                  options={allCouncilsList}
                  onChange={handleSelectCouncils}
                />
              )}
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button type="submit" btn={BtnType.REGULAR}>
          Add
        </Button>
      </ModalFooter>
    </div>
  );
};
export default AddNewTractionCompany;

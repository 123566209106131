import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import Select from "react-dropdown-select";

import { ModalBody, ModalFooter } from "../../../../../application/modal";
import {
  BtnType,
  Button,
  Label,
  Loading,
} from "../../../../../modules/primitives";
import { useModelPopup } from "../../../../../common/hooks";
import styles from "modules/projects/project/manageProject/details/details.module.scss";
import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import {
  getPlantByType,
  updatePlant,
} from "../../../../../common/actions/common.action";
import {
  projectSettingsGet,
  projectSettingsPut,
} from "modules/projects/project/project.action";

import { httpPut } from "../../../../../common/httpCall";

const ProjectPlantsAndAreasModal = () => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    councilReducer: { selectedCouncil },
    projectsReducer: {
      projectReducer: {
        settingsLoading,
        projectSettings,
        selectedProject,
        projectPlant,
        replicationData,
      },
    },
    adminPlantsAndAreasReducer: { plantsAndAreas },
  } = useSelector((state) => state);

  const [selectedPlant, setSelectedPlant] = useState({});
  const [shouldUpdateApprovers, setShouldUpdateApprovers] = useState(true);

  useEffect(() => {
    if (selectedCouncil?.name !== "Ford") {
      return;
    }
    dispatch(projectSettingsGet({ id: selectedProject.id, enqueueSnackbar }));
    if (!plantsAndAreas.length) {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        })
      );
    }
  }, [plantsAndAreas, selectedCouncil]);

  useEffect(() => {
    if (selectedCouncil?.name !== "Ford") {
      return;
    }

    if (!projectPlant) {
      dispatch(
        getPlantByType({
          resourceId: selectedProject.id,
          enqueueSnackbar,
          type: "Project",
        })
      );
    }
  }, [projectPlant, selectedCouncil]);

  useEffect(() => {
    if (projectPlant && plantsAndAreas.length) {
      const plant = {
        id: projectPlant.id,
        name: projectPlant.name,
        selectedAreas:
          projectPlant?.areas?.map((area) => ({
            area_id: area.id,
            area_name: area.attributes.name,
          })) || [],
        areas:
          plantsAndAreas.find((p) => p.id === projectPlant.id)?.attributes
            ?.areas || [],
      };
      setSelectedPlant(plant);
    }
  }, [projectPlant, plantsAndAreas]);

  const handlePlantChange = (value) => {
    if (!value?.length) {
      setSelectedPlant({});
      return;
    }

    const selected = value[0] || value.obj[0];

    if (selected.id === selectedPlant.id) {
      return;
    }

    setSelectedPlant(selected);
  };

  const handleAreaChange = (value) => {
    setSelectedPlant({
      ...selectedPlant,
      selectedAreas: value,
    });
  };

  const handleCancelClick = () => {
    popup.hide();
  };

  const getPlantsAsOptions = useMemo(() => {
    const plants = plantsAndAreas.map((plant) => ({
      id: plant.id,
      name: plant.attributes.name,
      areas: plant.attributes.areas,
    }));

    if (
      replicationData?.project_replications?.length ||
      replicationData?.original_project?.plant?.id
    ) {
      let originalPlant = [];

      if (replicationData?.original_project?.plant?.id) {
        originalPlant = [
          {
            ...replicationData.original_project?.plant,
            areas: [
              {
                ...replicationData.original_project?.plant.area,
                area_id: replicationData.original_project?.plant.area.id,
              },
            ],
          },
        ];
      }

      const combinedData = [
        ...originalPlant,
        ...(replicationData?.project_replications || []),
      ];

      return plants
        .map((plant) => {
          const getPlantsById = combinedData.filter((p) => p.id === plant.id);

          if (getPlantsById?.length) {
            const allAreasIds = getPlantsById
              .reduce((acc, plant) => [...acc, ...(plant.areas || [])], [])
              .map((area) => area.area_id);

            if (allAreasIds.length) {
              return {
                ...plant,
                areas: plant.areas.filter(
                  (a) => !allAreasIds.includes(a.area_id)
                ),
              };
            }

            return {
              ...plant,
              filterPlant:
                allAreasIds.length === plant.areas.length ||
                !plant.areas.length,
            };
          }

          return plant;
        })
        .filter((p) => !p.filterPlant);
    }

    return plants;
  }, [plantsAndAreas, replicationData]);

  const updateApprovers = () => {
    httpPut({
      call: `projects/${selectedProject.id}/update_approval_users`,
      data: null,
    }).subscribe((res) => {
      if (res.response.id) {
        enqueueSnackbar("Successfully updated Reviewers + Approvers", {
          variant: "success",
        });
      }
    });
  };

  const handleSubmit = () => {
    if (!selectedPlant?.id) {
      handleCancelClick();
      return;
    }

    const payload = {
      data: {
        resource_id: selectedProject.id,
        resource_type: "Project",
        plant_id: selectedPlant.id,
        area_ids:
          selectedPlant?.selectedAreas?.map((area) => area.area_id) || [],
      },
      type: "Project",
      response: {
        plant: {
          id: selectedPlant.id,
          attributes: {
            name: selectedPlant.name,
          },
        },
        areas:
          selectedPlant?.selectedAreas?.map((area) => ({
            id: area.area_id,
            attributes: {
              name: area.area_name,
            },
          })) || [],
      },
      cb: () => {
        if (shouldUpdateApprovers) {
          updateApprovers();
        }

        popup.hide();
      },
      enqueueSnackbar,
    };

    dispatch(updatePlant(payload));
  };

  const updateSettings = (type) => {
    dispatch(
      projectSettingsPut({
        data: {
          skip_completed_tasks: type === "skip_completed_tasks",
          update_all_tasks: type === "update_all_tasks",
          id: selectedProject.id,
          enqueueSnackbar,
        },
      })
    );
  };

  return (
    <>
      <ModalBody>
        <div className={styles.formContainer}>
          <Label>Plant</Label>
          <Select
            multi={false}
            closeOnSelect
            values={[selectedPlant]}
            onChange={handlePlantChange}
            options={getPlantsAsOptions}
            labelField="name"
            valueField="id"
            placeholder="Plants"
            searchBy="name"
          />
          {selectedPlant?.areas?.length > 0 ? (
            <div className="mt-3">
              <Label>Areas</Label>
              <Select
                multi={false}
                closeOnSelect
                values={selectedPlant.selectedAreas || []}
                onChange={handleAreaChange}
                options={selectedPlant.areas}
                labelField="area_name"
                valueField="area_id"
                placeholder="Areas"
                searchBy="area_name"
              />
              {selectedPlant?.areas?.length &&
              !selectedPlant.selectedAreas?.length ? (
                <span className="text-danger d-block mt-2">
                  Please select an Area
                </span>
              ) : null}
            </div>
          ) : null}
          <label className="d-flex align-items-center mt-3 mb-1">
            <input
              type="checkbox"
              checked={shouldUpdateApprovers}
              onChange={() => setShouldUpdateApprovers(!shouldUpdateApprovers)}
            />
            <span className="d-block ml-3">Update Reviewers + Approvers</span>
          </label>
          <>
            {shouldUpdateApprovers ? (
              <>
                {settingsLoading ? (
                  <Loading />
                ) : (
                  <div className="d-flex flex-column mb-3 ml-3">
                    <div className="d-flex align-items-center">
                      <input
                        id="yes"
                        value={true}
                        checked={projectSettings?.skip_completed_tasks || false}
                        name="type"
                        type="radio"
                        onChange={() => {
                          updateSettings("skip_completed_tasks");
                        }}
                      />
                      <label className="pl-2" htmlFor="yes">
                        Skip completed Tasks
                      </label>
                    </div>

                    <div className="d-flex align-items-center">
                      <input
                        id="no"
                        value={false}
                        name="type"
                        checked={projectSettings?.update_all_tasks || false}
                        type="radio"
                        onChange={() => {
                          updateSettings("update_all_tasks");
                        }}
                      />
                      <label className="pl-2" htmlFor="no">
                        Update All Tasks
                      </label>
                    </div>
                  </div>
                )}
              </>
            ) : null}
          </>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          type="submit"
          btn={BtnType.REGULAR}
          onClick={handleSubmit}
          disabled={
            selectedPlant?.areas?.length
              ? !selectedPlant?.selectedAreas?.length
              : false
          }
        >
          Submit
        </Button>
      </ModalFooter>
    </>
  );
};
export default ProjectPlantsAndAreasModal;

export const moreOptionTypes = {
  share: 1,
  // discuss: 2,
  // downloadReport: 3,
  // addToList:3,
  addToProject: 4,
};

export const patientOrganizationMoreOptions = [
  {
    val: moreOptionTypes.share,
    text: "Share",
  },
  // {
  //   val: moreOptionTypes.discuss,
  //   text: 'Discuss',
  // },
  // {
  //   val: moreOptionTypes.addToList,
  //   text: 'Add to list',
  // },
  {
    val: moreOptionTypes.addToProject,
    text: "Add to project",
  },
  // {
  //   val: moreOptionTypes.downloadReport,
  //   text: 'Download Report',
  // },
];

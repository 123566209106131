import checkForThemes from "./checkForThemes";

const adminFilter = (menu, council_role, session, selectedCouncil) => {
  if (
    session &&
    (council_role === "ttp_administrator" || council_role === "council_administrator")
  ) {
    return menu.filter((menuItem) => {
      if (menuItem.gskOnly) {
        return false;
      }
      if (menuItem.mobileOnly) {
        return false;
      }
      if (menuItem.claimProfileOnly) {
        return false;
      }

      return checkForThemes(menuItem.path, selectedCouncil);
    });
  }
  return menu.filter((menuItem) => {
    if (menuItem.gskOnly) {
      return false;
    }
    if (menuItem.mobileOnly) {
      return false;
    }
    if (menuItem.claimProfileOnly) {
      return false;
    }
    if (!checkForThemes(menuItem.path, selectedCouncil)) {
      return false;
    }

    return menuItem.path !== "/admin";
  });
};
export default adminFilter;

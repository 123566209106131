import * as Yup from "yup";

export const initModel = {
  recipients: [],
  anonymous: false,
  subject: "",
  // content: '',
  team_id: null,
};

export const newDiscussionNoValidation = Yup.object().shape({});

export const newDiscussionValidation = Yup.object().shape({
  subject: Yup.string().strict(true).required("Subject is required"),
  // content: Yup.string().strict(true).required('Idea is required'),
});

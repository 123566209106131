import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { convertDateTimezone } from "common/helper";
import styles from "./styles.module.scss";
import {
  CheckBox,
  MoreOptions,
  UserInline,
  UserInlineTypes,
  Label,
} from "../../../../primitives";
import { moreOptions } from "./constant";
import { Icon, lgSize } from "../../../../../common/icon";
import { parceVisibility } from "../activity.constant";
import AccessToggle from "../../../../../common/components/customFields/accessToggle";

const MeetingComponent = (props) => {
  const {
    meeting,
    teamList,
    handleEditClick,
    handleDeleteClick,
    orgType,
    hasAccess,
    blockFunctionality = false,
    isAddedByMe,
    isLastElem,
    isMobile,
  } = props;

  const handleMoreOptionClick = (props) => {
    if (props === 5) {
      handleEditClick(meeting);
    }
    if (props === 6) {
      handleDeleteClick(meeting);
    }
  };

  return (
    <div className={`${styles.wrapper} ${isLastElem ? styles.noBorder : ""}`}>
      <div className={styles.container}>
        <div className={styles.titleRow}>
          {isMobile ? null : (
            <div className={styles.iconWrp}>
              <Icon
                {...lgSize}
                size={24}
                icon="icn-calendar"
                className={styles.icon}
              />
            </div>
          )}
          <h5 className={styles.title}>
            {isMobile ? (
              <Icon
                {...lgSize}
                size={24}
                icon="icn-calendar"
                className={styles.icon}
              />
            ) : null}
            Meeting
          </h5>
          <div className={styles.rightWrp}>
            {isAddedByMe ? (
              <div className={styles.toggle}>
                <AccessToggle item={meeting} orgType={orgType} />
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <span className={styles.date}>
                  {meeting?.updated_at
                    ? format(
                      new Date(meeting.updated_at),
                      "EEEE, MMMM dd, yyyy ",
                    )
                    : null}
                </span>
                <div className={styles.colMore}>
                  {hasAccess && (
                    <MoreOptions
                      options={moreOptions}
                      className={styles.moreOptWrp}
                      onClick={handleMoreOptionClick}
                      customSvgIcon
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex align-items-start justify-content-between mt-4">
          <div className="d-flex flex-column">
            {meeting?.title && (
              <div className={styles.meetingTitle}>{meeting.title}</div>
            )}
            {meeting?.location && (
              <div className={styles.time}>{meeting.location}</div>
            )}
            {meeting?.date && (
              <div className={styles.date}>
                {format(
                  convertDateTimezone(meeting?.date),
                  "EEEE, MMMM dd, yyyy ",
                )}
              </div>
            )}
            {meeting?.time && <div className={styles.time}>{meeting.time}</div>}
          </div>
          {isAddedByMe && (
            <div className="d-flex align-items-center">
              <span className={styles.date}>
                {meeting?.updated_at
                  ? format(new Date(meeting.updated_at), "EEEE, MMMM dd, yyyy ")
                  : null}
              </span>
              <div className={styles.colMore}>
                {hasAccess && (
                  <MoreOptions
                    options={moreOptions}
                    className={styles.moreOptWrp}
                    onClick={handleMoreOptionClick}
                    customSvgIcon
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className={classNames(styles.detail, styles.note)}
          dangerouslySetInnerHTML={{ __html: meeting?.description || "" }}
        />
        <div className={styles.attendeesWrp}>
          {meeting.attendees.length !== 0 && (
            <>
              <div className={styles.label}>Attendees: </div>
              <div className={styles.listWrp}>
                {meeting?.attendees?.map((attendee) => (
                  <div key={attendee.user_id} className={styles.itemWrp}>
                    <UserInline
                      className={styles.userIcon}
                      classObj={{ name: styles.userName }}
                      userId={attendee.user_id}
                      type={UserInlineTypes.WITH_NAME}
                      showMine={false}
                      first={attendee.first_name}
                      last={attendee.last_name}
                      avatar={attendee.avatar}
                      local
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className={styles.infoWrp}>
          <div className="w-50 d-flex">
            {meeting?.projects && meeting?.projects[0] && (
              <div className={styles.dataRow}>
                <div className={styles.label}>Project</div>
                {meeting?.projects.map((project) => (
                  <Link key={project.id} to={`/projects/${project.id}`}>
                    <div className={styles.value}>{project.name}</div>
                  </Link>
                ))}
              </div>
            )}
            <div className={styles.dataRow}>
              <div className={styles.label}>
                {meeting.updated_at !== meeting.created_at
                  ? "Updated by"
                  : "Created by"}
              </div>
              <div className={styles.value}>
                <Link to={`/people/internal/${meeting.creator_id}`}>
                  {meeting && meeting.created_by}
                </Link>
              </div>
            </div>

            {meeting.visibility && (
              <div className={styles.dataRow}>
                <div className={styles.label}>Visible</div>
                <div className={styles.value}>
                  {meeting
                    && parceVisibility(
                      meeting.visibility,
                      teamList,
                      meeting.team_ids,
                    )}
                </div>
              </div>
            )}
          </div>
          <div className="w-50">
            {orgType === "patient" ? (
              <div className={styles.centerWrp}>
                <div className={classNames(styles.infoWrpColumn, "mb-3")}>
                  <div className={styles.label}>Discussion Topic:</div>
                  <div className={styles.detail}>
                    {meeting && meeting.discussion_topics
                      ? meeting.discussion_topics.map((d) => `${d}, `)
                      : "-"}
                  </div>
                </div>
                <div className={classNames(styles.infoWrpColumn, "mb-3")}>
                  <div className={styles.label}>Roles Of Key Attendees:</div>
                  <div className={styles.detail}>
                    {meeting && meeting.roles_of_attendees}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Label>
                    {orgType === "patient"
                      ? "Initial engagement"
                      : "Initial introductory meeting"}
                    :{" "}
                  </Label>
                  <CheckBox
                    className="ml-2"
                    isChecked={meeting && meeting.initial_introductory_meeting}
                    disabled={blockFunctionality}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MeetingComponent;

import React, { useState, useEffect } from "react";
import { useVideField } from "common/hooks";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { getFilename } from "modules/admin/adminCompanies/editCompany/editCompany.helper";
import { MoreOptions, Loading } from "modules/primitives";
import { ACTIVITY_OPTIONS_WITHOUT_DELETE } from "common/constants.js";
import useS3FileUpload from "common/hooks/s3FileUplodar.hook";
import { videoExtensions } from "modules/events/viewEvent/viewEvent.constant";
import DragAndDrop from "common/components/dragAndDrop/index";
import styles from "./styles.module.scss";
import LinearProgressWithLabel from "../../../../../../common/components/linearProgressWithLabel";

const VideoComponent = ({
  item,
  handleUpdateForm,
  handleEditClick,
  disabled,
  companyId,
}) => {
  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    companiesReducer: {
      companyReducer: { customFieldLoadingId },
    },
  } = useSelector((state) => state);

  const videField = useVideField(item);
  const [loadingId, setLoadingId] = useState("");
  const [fieldId, setFieldId] = useState("");
  const [videos, setVideos] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { fileUpload } = useS3FileUpload();
  const [loading, setLoading] = useState(false);
  const [uploadValues, setUploadValues] = useState([]);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const [unsavedFiles, setUnsavedFiles] = useState([]);

  useEffect(() => {
    if (forbiddenFiles.length > 0) {
      const timeout = setTimeout(() => {
        setForbiddenFiles([]);
        clearTimeout(timeout);
      }, 5000);
    }
  });

  useEffect(() => {
    setUnsavedFiles(item?.unsavedFiles || []);
  }, [item?.unsavedFiles]);

  useEffect(() => {
    setVideos(item?.option_values || []);
  }, [item?.option_values]);

  useEffect(() => {
    if (item) {
      setFieldId(item?.company_custom_field_id);
    }
  }, [item]);

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }
  };

  useEffect(() => {
    if (customFieldLoadingId) {
      setLoadingId(customFieldLoadingId);
    }
  }, [customFieldLoadingId]);

  const getExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  const uploadHandler = async (e, action) => {
    if (forbiddenFiles.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }
    setLoading(true);

    setUploadValues((items) => {
      const currentFiles = [...items];

      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name,
      }));
      return [...currentFiles, ...newFiles];
    });

    const fileList = [];
    let dismissedFiles = 0;

    files.forEach(async (file, index, array) => {
      const isLastIndex = Boolean(index === array?.length - 1);
      if (
        file.size <= 500000000
        && videoExtensions.indexOf(getExtension(file.name)) !== -1
      ) {
        const pathname = `srm/${selectedCouncil.id}/companies/${companyId}/videos`;
        await fileUpload(file, pathname, setUploadValues, file.name).then(
          (res) => {
            if (res && res.location) {
              if (videos.indexOf(res.location) === -1) {
                fileList.push(res.location);
                isLastIndex && setLoading(false);
                if (fileList.length === files.length - dismissedFiles) {
                  const newUnsavedFiles = [...unsavedFiles, ...fileList];
                  const newVideos = [...item.option_values, ...fileList];
                  const documents = item.documents ? item.documents : []
                  setUnsavedFiles(newUnsavedFiles);
                  setVideos(newVideos);
                  handleUpdateForm({
                    ...item,
                    documents_attributes: newVideos.map(vid => {
                      return {
                        "uri": vid,
                        "name": getFilename(vid),
                        "council_id": selectedCouncil.id,
                        "added_by_id": session.id
                    }
                    }),
                    documents: [...documents, ...newVideos.map(vid => {
                      return {
                        "uri": vid,
                        "name": getFilename(vid),
                        "council_id": selectedCouncil.id,
                        "added_by_id": session.id
                    }
                    })] ,
                    options: newVideos,
                    option_values: newVideos,
                    // field_values: newVideos,
                    unsavedFiles: newUnsavedFiles,
                  });
                }
              } else {
                enqueueSnackbar("This document was already added.", {
                  variant: "error",
                });
                isLastIndex && setLoading(false);
                dismissedFiles++;
                setForbiddenFiles((prevFiles) => {
                  const localFiles = [...prevFiles];
                  localFiles.push({
                    name: file.name,
                    error: "Document already added",
                  });
                  return localFiles;
                });
                setUploadValues((prevState) => {
                  const currentProgress = [...prevState];
                  const index = currentProgress
                    .map((item) => item.name)
                    .lastIndexOf(file.name);
                  currentProgress[index] = {
                    progress: "NOT_ALLOWED",
                    name: file.name,
                  };
                  return currentProgress;
                });
              }
            }
          },
        );
      } else {
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === file.name,
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: file.name,
          };
          return currentProgress;
        });
        isLastIndex && setLoading(false);
        if (file.size > 500000000) {
          dismissedFiles++;
          enqueueSnackbar(
            "We could not upload your image because your file size is too big. Please make sure the file is less than 500 MB",
            {
              variant: "error",
            },
          );
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error: "Your file is too large. File size limit: 500MB",
            });
            return localFiles;
          });
        }
        if (videoExtensions.indexOf(getExtension(file.name)) === -1) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error:
                "Extension is not allowed, the accepted file formats are: mp4, mov, wmw, flv, avi, webm, mkw.",
            });
            return localFiles;
          });
          isLastIndex && setLoading(false);
          dismissedFiles++;
          // enqueueSnackbar('Extension is not allowed, the accepted file formats are: mp4, mov, wmw, flv, avi, webm, mkw.', {
          //   variant: 'error',
          // });
        }
      }
    });

    e.target.value = "";
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{item?.field_name || "Video"}</h5>
          {
            item?.field_placeholder?.length ? (
              <span className="text-muted small">{item.field_placeholder}</span>
            ) : null
          }
        </div>
        {!disabled && (
          <div className={styles.rightWrp}>
            <div className={styles.colMore}>
              <MoreOptions
                options={ACTIVITY_OPTIONS_WITHOUT_DELETE}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            </div>
          </div>
        )}
      </div>

      <div>
        {uploadValues?.length
          ? uploadValues.map(
            (bar, index) => bar
              && bar?.progress !== "NOT_ALLOWED" && (
                <div className={styles.loaderContainer}>
                  <div className={styles.loaderNamingWrp}>
                    {" "}
                    <div className={styles.fileName}>
                      {uploadValues[index]?.name}
                    </div>
                    <LinearProgressWithLabel
                      mode={
                        uploadValues[index]?.progress
                        === "FAILED" && "error"
                      }
                      value={
                        uploadValues[index]?.progress === "FAILED"
                          ? 100
                          : uploadValues[index]?.progress || 0
                      }
                    />
                  </div>
                </div>
            ),
          )
          : null}

        <DragAndDrop
          disabled={disabled}
          uploadHandler={uploadHandler}
          loading={loading}
          customText="drag and drop videos here"
          unicumKey={item?.custom_field_template_id}
        />

        {forbiddenFiles?.length ? (
          <div className={styles.forbiddenfileContainer}>
            Files that weren't allowed:{" "}
            {forbiddenFiles.map((file) => (
              <span className={styles.forbiddenfileName}>
                {`${file.name}: `}
                <span className={styles.errorText}> {file.error}</span>
              </span>
            ))}
          </div>
        ) : null}
      </div>

      {loadingId === fieldId ? (
        <Loading />
      ) : (
        <div className={styles.videoWrp}>
          {item?.documents?.length > 0 ? videField.displayField() : null}
        </div>
      )}
    </div>
  );
};

export default React.memo(VideoComponent);

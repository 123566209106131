import React from "react";
import Skeleton from "@mui/material/Skeleton";

import styles from "./styles.module.scss";

const SolutionsCardLoader = () => (
  <div className={styles.skeletonBlock}>
    <Skeleton
      animation="wave"
      variant="rounded"
      width={420}
      height={345}
      className={styles.skeletonText}
    />
    <Skeleton
      animation="wave"
      variant="rounded"
      width={420}
      height={345}
      className={styles.skeletonText}
    />
    <Skeleton
      animation="wave"
      variant="rounded"
      width={420}
      height={345}
      className={styles.skeletonText}
    />
  </div>
);

export default SolutionsCardLoader;

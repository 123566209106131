import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./starRating.module.scss";

const StarRatingComponent = (props) => {
  const { rate = 0, total = 5, onChange } = props;
  const [count, setCount] = useState(0);
  const [totCount, setTotCount] = useState(total);

  useEffect(() => {
    if (rate) setCount(rate);
  }, [rate]);

  const handleOnclick = (val) => {
    if (onChange) {
      setCount(val);
      onChange(val);
    }
  };
  return (
    <div className={styles.wrapper}>
      {Array.from({ length: totCount }, (v, k) => k + 1).map((cnt, inx) => (
        <div
          key={inx}
          className={styles.starCon}
          onClick={(e) => handleOnclick(cnt)}
        >
          <div
            className={classNames(styles.star, cnt <= count && styles.selected)}
          />
        </div>
      ))}
    </div>
  );
};

export default React.memo(StarRatingComponent);

export const ORGANIZATION_ROWS = [
  {
    name: "Organization",
    sortingIcon: "name",
    hideInModal: true,
    className: "colCompany",
    selected: true,
    keyName: "name",
  },
  {
    name: "Project Match",
    sortingIcon: "",
    className: "colfunding",
    selected: true,
    keyName: "project_match",
  },
  {
    name: "General Characteristic",
    sortingIcon: "",
    className: "bigCol",
    selected: true,
    keyName: "general_characteristic",
  },
  {
    name: "Supports Clinical Research",
    sortingIcon: "",
    className: "bigCol",
    selected: true,
  },
  {
    name: "Involved in Health Policy and Equity",
    sortingIcon: "",
    className: "bigCol",
    selected: true,
  },
  {
    name: "Support for Patients",
    sortingIcon: "",
    className: "bigCol",
    selected: true,
  },
  {
    name: "Patient Education & Awareness",
    sortingIcon: "",
    className: "bigCol",
    selected: true,
  },
  {
    name: "Cultural Representation",
    sortingIcon: "",
    className: "bigCol",
    selected: true,
  },
];

export const COMPAIR_ROWS = [
  {
    name: "Company",
    sortingIcon: "name",
    hideInModal: true,
    className: "colCompany",
    selected: true,
    type: "string",
    keyName: "name",
  },
  {
    name: "Total funding",
    sortingIcon: "funding",
    className: "colfunding",
    selected: false,
    type: "number",
    keyName: "total_funding",
  },
  {
    name: "Technology",
    sortingIcon: "topicsString",
    className: "colTechnology",
    selected: true,
    type: "string",
    keyName: "topics",
  },
  {
    name: "Industry",
    sortingIcon: "industryTagsString",
    className: "colIndustry",
    selected: false,
    type: "string",
    keyName: "industries",
  },
  {
    name: "Project Stage",
    sortingIcon: "status",
    className: "colStatus",
    selected: true,
    type: "number",
    keyName: "project_stage",
  },
  {
    name: "Project Score",
    sortingIcon: "score",
    className: "colScore",
    selected: true,
    type: "rating",
    keyName: "score",
  },
  {
    name: "Project Status",
    sortingIcon: "state",
    className: "colScore",
    selected: true,
    type: "string",
    keyName: "project_status",
  },
  {
    name: "Task Progress",
    sortingIcon: "total_tasks_progress",
    className: "colScore",
    selected: false,
    type: "number",
    keyName: "total_tasks_progress",
  },
  {
    name: "HQ Location",
    sortingIcon: "location",
    className: "colLocation",
    selected: true,
    type: "string",
    keyName: "location",
  },
  {
    name: "Description",
    sortingIcon: "companyDescription",
    className: "colDev",
    selected: false,
    type: "string",
    keyName: "description",
  },
  {
    name: "Website",
    sortingIcon: "website",
    className: "colDev",
    selected: true,
    type: "string",
    keyName: "web_url",
  },
  {
    name: "Founded",
    sortingIcon: "foundedTime",
    className: "colDev",
    selected: true,
    type: "number",
    keyName: "founded",
  },
  {
    name: "Employees",
    sortingIcon: "company_size",
    className: "colDev",
    selected: false,
    type: "number",
    keyName: "company_size",
  },
  {
    name: "Competitors",
    sortingIcon: "competitorsLength",
    className: "colDev",
    selected: false,
    type: "number",
    keyName: "competitors",
  },
  {
    name: "Estimated Revenue",
    sortingIcon: "estimated_revenue",
    className: "colDev",
    selected: false,
    type: "number",
    keyName: "estimated_revenue",
  },
  {
    name: "Company Contacts",
    sortingIcon: "companyContactsString",
    className: "colDev",
    selected: false,
    type: "string",
    keyName: "company_contacts",
  },
  {
    name: "Relationship Owner",
    sortingIcon: "relationshipOwnerString",
    className: "colDev",
    selected: false,
    type: "string",
    keyName: "relationship_owner",
  },
  {
    name: "Notes ",
    sortingIcon: "notesString",
    className: "colDev",
    selected: false,
    type: "string",
    keyName: "notesGeneral",
  },
];
export const NOTES_ROWS = [
  {
    name: "Notes",
    sortingIcon: "notesString",
    className: "aditionalCol",
    selected: true,
    type: "string",
    keyName: "notes",
  },
  {
    name: "Calls",
    sortingIcon: "callsString",
    className: "aditionalCol",
    selected: true,
    type: "string",
    keyName: "calls",
  },
  {
    name: "Emails",
    sortingIcon: "emailsString",
    className: "aditionalCol",
    selected: true,
    type: "string",
    keyName: "emails",
  },
  {
    name: "Meetings",
    sortingIcon: "meetingsString",
    className: "aditionalCol",
    selected: true,
    type: "string",
    keyName: "meetings",
  },
  {
    name: "Events",
    sortingIcon: "eventsString",
    className: "aditionalCol",
    selected: true,
    type: "string",
    keyName: "events",
  },
];

export const pageSettings = {
  pageSize: 20,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
};

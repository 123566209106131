import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { httpGet, errorHandler, httpPut } from "../../../../common/httpCall";
import {
  GET_RFI_DETAILS_ATTEMPT,
  getRFIDetailsSuccess,
  SAVE_DETAILS_ATTEMPT,
  saveDetailsSuccess,
} from "./rfiActions";
import {
  GET_PROFILE_FORMS_ATTEMPT,
  getProfileFormsSuccess,
} from "../../store/claimProfileActions";

export const epicGetRFIDetails = (action$) => action$.pipe(
  ofType(GET_RFI_DETAILS_ATTEMPT),
  switchMap(({ payload: { requestId } }) => httpGet({
    call: `claimed_profiles/${requestId}`,
  }).pipe(
    map((result) => getRFIDetailsSuccess(result)),
    catchError((err) => errorHandler(err)),
  )),
);

export const epicSaveRFIDetails = (action$) => action$.pipe(
  ofType(SAVE_DETAILS_ATTEMPT),
  switchMap(({ payload }) => httpPut({
    call: `company_request_for_informations/${payload.formId}`,
    data: payload,
  }).pipe(
    map((result) => {
      if (payload.cb) {
        payload.cb(result?.response);
      }
      return saveDetailsSuccess({
        ...result,
        id: payload.formId,
      });
    }),
    catchError((err) => errorHandler(err)),
  )),
);

export const epicGetProfileForms = (action$) => action$.pipe(
  ofType(GET_PROFILE_FORMS_ATTEMPT),
  switchMap(({ payload: { companyId } }) => httpGet({
    call: `claimed_profiles?company_id=${companyId}`,
  }).pipe(
    map((result) => getProfileFormsSuccess(result)),
    catchError((err) => errorHandler(err)),
  )),
);

const rfiEpic = combineEpics(
  epicGetRFIDetails,
  epicSaveRFIDetails,
  epicGetProfileForms,
);

export default rfiEpic;

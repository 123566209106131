import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withSnackbar } from "notistack";

import { Link } from "react-router-dom";
import styles from "./suggestions.module.scss";
import { BtnType, Button } from "../../../modules/primitives";
import { suggestionFollow, suggestionsGet } from "../../actions/common.action";
import NoLogo from "../../../assets/no-logo.png";

const Suggestions = ({ enqueueSnackbar, searchString, onFollow }) => {
  const [suggestionsList, setSuggestionsList] = useState([]);
  const dispatch = useDispatch();
  const {
    suggestionsReducer: { suggestions },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!suggestions) {
      dispatch(suggestionsGet({ enqueueSnackbar }));
    } else {
      setSuggestionsList(suggestions);
    }
    return () => null;
  }, [suggestions]);

  useEffect(() => {
    if (!searchString || !searchString.length) {
      setSuggestionsList(suggestions);
      return;
    }
    if (searchString.length) {
      const filtered = suggestions.filter((s) => s.name.includes(searchString));
      setSuggestionsList(filtered);
    }
    return () => null;
  }, [searchString]);

  const followCompany = (company) => {
    dispatch(
      suggestionFollow({
        data: company,
        enqueueSnackbar,
      }),
    );

    onFollow(company);

    const newSuggestions = suggestions.filter(
      (s) => s.company_id !== company.company_id,
    );

    setSuggestionsList(newSuggestions);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles.wrp}>
      <h5>Suggestions (Based on Your Interests )</h5>
      <div className={styles.suggestionsList}>
        {searchString?.length && !suggestionsList.length ? (
          <span className="d-flex justify-content-center">No suggestions!</span>
        ) : null}
        {suggestionsList ? (
          suggestionsList.map((s, idx) => (
            <div
              key={`${idx}_${s.company_id}`}
              className={styles.suggestionItem}
            >
              <div className="d-flex align-items-center">
                <Link to={`/companies/${s.slug || s.company_id}`}>
                  <img width="24" src={s.logo ? s.logo : NoLogo} alt={s.name} />
                  <span className={styles.suggestionName}>{s.name}</span>
                </Link>
              </div>
              <div className={styles.suggestionKeys}>
                {s.tags.map((tag) => (
                  <div key={tag.id}>{tag.name}</div>
                ))}
              </div>
              <Button onClick={() => followCompany(s)} btn={BtnType.FRAME_LESS}>
                FOLLOW
              </Button>
            </div>
          ))
        ) : (
          <span>No Suggestions.</span>
        )}
      </div>
    </div>
  );
};

export default React.memo(withSnackbar(Suggestions));

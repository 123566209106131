import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetProjectsLoading } from "modules/projects/project/project.action";
import { useSnackbar } from "notistack";
import classnames from "classnames";
import { useModelPopup } from "common/hooks";
import { useWindowSize } from "common/hooks/useWindowSize";
import FirmenichFunnel from "modules/home/firmenichFunnel";
import { Search, BtnType, Button } from "../primitives";

import styles from "./home.module.scss";
import {
  FordInfoCards,
  homeInfoTypes,
  homeInfoTypesKyndrylException,
  homeInfoTypesWorldWideTechnologyException,
} from "./home.constant";
import ResearchModal from "./infoContainer/researchModal";

import InfoContainerComponent from "./infoContainer";
import { MyProjects, Discussion, UpcommingEvent } from "../shared";
import MyCompanies from "./myCompanies";
import InnovationFunnel from "./innovationFunnel";
import ThemeHomePageBlock from "../themes/themesHomePageBlock/ThemesHomePage";
import ThemesPosts from "../themes/themesHomePageBlock/ThemesPosts";
import {
  getAllCompaniesAttempt,
  setChartsReqItIsInProgress,
} from "../companies/companyMain/companyMain.action";
import { resetHomeFunnel } from "./home.actions";
import LeadershipBox from "./kyndrilHomeComponents/leadershipBox/leadershipBox.component";
import Leaderbord from "./leadearboard/leaderboard.component";
import FordSearch from "modules/home/FordSearch";
import NewProjectV3 from "modules/projects/newProjectv3";

const HomeComponent = () => {
  const {
    appReducer: { chartsDownloadIsActive },
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const popup = useModelPopup();
  const council = localStorage.getItem("council");

  const [query, setQuery] = useState();
  const size = useWindowSize();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [infoTypes, setInfoTypes] = useState([]);

  const getColsNum = () => {
    if (selectedCouncil?.settings?.solutions_connected) {
      return "col-6";
    }
    return "col-12";
  };

  const secondLevelBlock = () => {
    if (session) {
      if (
        selectedCouncil?.name === "Firmenich" &&
        selectedCouncil?.settings?.innov_funnel_connected
      ) {
        return <FirmenichFunnel />;
      }
      // if (isKyndrylViewOnly(selectedCouncil?.name, session?.council_role)) {
      //   return <LeadershipBox />;
      // }
      if (selectedCouncil?.settings?.innov_funnel_connected) {
        return <InnovationFunnel />;
      }
      return <></>;
    }
  };

  useEffect(() => {
    const c = JSON.parse(council);
    if (c && (c.name === "Ford" || c.traction_tag === "ford")) {
      return;
    }

    if (history?.location?.state?.prevPath === "/companies") {
      dispatch(resetHomeFunnel());

      const data = {
        query: "",
        search: {
          query: "",
        },
      };

      dispatch(setChartsReqItIsInProgress(true));
      dispatch(
        getAllCompaniesAttempt({
          call: "companies/charts?ultrafast=true",
          data,
          enqueueSnackbar,
        })
      );
    }

    return () => null;
  }, [history]);

  const handleSearch = (e) => {
    setQuery(e);
  };

  const handleEnterPress = () => {
    history.push(`/companies?query=${query}`);
  };

  useEffect(() => {
    if (selectedCouncil) {
      if (selectedCouncil?.name === "Kyndryl") {
        setInfoTypes(homeInfoTypesKyndrylException);
      } else if (selectedCouncil?.traction_tag === "world_wide_technology") {
        setInfoTypes(homeInfoTypesWorldWideTechnologyException);
      } else {
        setInfoTypes(homeInfoTypes);
      }
    }

    return () => null;
  }, [selectedCouncil?.name]);

  useEffect(
    () => () => {
      dispatch(resetProjectsLoading());
    },
    []
  );

  const handleGetResearchClick = () => {
    popup.show({
      title: "Request Research",
      component: <ResearchModal popup={popup} />,
    });
  };

  return (
    <div className={classnames(styles.pageWrapper, "col-md-12")}>
      <div
        className={`row ${styles[selectedCouncil?.traction_tag]} ${
          styles.headerWrp
        }`}
      >
        {selectedCouncil?.name === "Ford" ? (
          <FordSearch className={styles.searchCon} />
        ) : (
          <Search
            onChange={handleSearch}
            handleEnterPress={() => handleEnterPress()}
            onKeyUp
            from="home"
            handleCrunchBase="handleCrunchBase"
            className={styles.searchCon}
            placeholder="Search Companies, Technologies, and Industries"
          />
        )}
      </div>

      <div className={styles.container}>
        {selectedCouncil?.name === "Ford" && (
          <>
            <div className={classnames(styles.infoFordBoxes)}>
              {FordInfoCards.map((info) => (
                <div
                  style={
                    !selectedCouncil?.settings?.solutions_connected &&
                    info.id === "solutions"
                      ? { display: "none" }
                      : {}
                  }
                  className={classnames(
                    size.width < 768 ? "p-0" : "pl-2 pr-2",
                    getColsNum()
                  )}
                  key={info.id}
                >
                  <InfoContainerComponent
                    info={info}
                    type="Council"
                    reducerProp="publicDiscussions"
                    userRole={session?.council_role}
                    isTablet={size.width < 1201}
                  />
                </div>
              ))}
            </div>
            <div className="row align-items-center justify-content-center mt-3">
              <NewProjectV3
                wizardType="main"
                btnName="Submit Idea"
              />
            </div>
          </>
        )}

        {selectedCouncil?.themes_homepage_connected && (
          <div
            style={selectedCouncil?.name === "Ford" ? { marginTop: 30 } : {}}
            className={classnames(styles.infoWrapper, "row")}
          >
            {selectedCouncil?.settings?.home_page_theme_carousel_connected && (
              <ThemeHomePageBlock />
            )}

            <div className="col-12 col-md-8 p-0 mb-5">
              {selectedCouncil?.settings?.home_page_theme_posts_connected && (
                <ThemesPosts />
              )}
            </div>
            <div
              className={classnames(
                styles.eventsWrapper,
                "col-12 col-md-4 p-0"
              )}
            >
              {selectedCouncil?.settings?.home_page_events_connected && (
                <UpcommingEvent />
              )}
            </div>
          </div>
        )}

        {!selectedCouncil?.themes_homepage_connected && (
          <div className={classnames(styles.infoWrapper, "row")}>
            {infoTypes.map((info) => (
              <div
                className={classnames(size.width < 768 ? "p-0" : "", "col-4")}
                key={info.id}
              >
                <InfoContainerComponent
                  info={info}
                  type="Council"
                  reducerProp="publicDiscussions"
                  userRole={session?.council_role}
                  isTablet={size.width < 1201}
                />
              </div>
            ))}
          </div>
        )}
        {selectedCouncil?.traction_tag === "world_wide_technology" && (
          <div className="row align-items-center justify-content-center mt-3">
            <Button btn={BtnType.REGULAR} onClick={handleGetResearchClick}>
              Research Request
            </Button>
          </div>
        )}
        {selectedCouncil?.name === "Ford" ? (
          <div>
            <Leaderbord />
          </div>
        ) : null}

        <div className="row m-0">
          <div className="col-12 p-0" id="downloadableSection">
            {chartsDownloadIsActive && (
              <div className={styles.chartDownloadBadge}>
                <span className={styles.bolder}>Traction</span>
                <span className={styles.light}>Report</span>
              </div>
            )}
            {secondLevelBlock()}
          </div>
          <div
            className={
              !selectedCouncil?.themes_homepage_connected
                ? "col-12 col-md-8 p-0"
                : "col-12  p-0"
            }
          >
            <div>
              {(selectedCouncil?.name === "Kyndryl" &&
                session?.council_role === "viewer") ||
              !selectedCouncil?.settings?.home_page_projects_connected ? (
                <></>
              ) : (
                <>
                  {
                    session && selectedCouncil && (
                      <MyProjects
                        selectedCouncil={selectedCouncil}
                        session={session}
                        homePageUse
                      />
                    )
                  }
                </>
              )}
              {selectedCouncil?.settings?.home_page_companies_connected && (
                <MyCompanies />
              )}
            </div>
          </div>

          {!selectedCouncil?.themes_homepage_connected && (
            <div className="col-12 col-md-4 p-0">
              {selectedCouncil?.name !== "Kyndryl" && (
                <Discussion
                  homePageUse
                  type="Council"
                  reducerProp="publicDiscussions"
                />
              )}
              {selectedCouncil?.settings?.home_page_events_connected && (
                <UpcommingEvent />
              )}
            </div>
          )}
        </div>
        {selectedCouncil?.name === "Kyndryl" && <LeadershipBox />}
      </div>
    </div>
  );
};

export default React.memo(HomeComponent);

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilePicker } from "react-file-picker";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import { checkViewerRole } from "common/checkers/viewerChecker";
import { getFilename } from "modules/admin/adminCompanies/editCompany/editCompany.helper";
import DragAndDrop from "common/components/dragAndDrop/index";

import styles from "./styles.module.scss";
import { Button, BtnType, Loading } from "../../../primitives";
import { useDocumentName, useS3FileUpload } from "../../../../common/hooks";
import {
  themesDocumentGetAttempt,
  themesDocumentPostAttempt,
  themesDocumentDeleteAttempt,
} from "./documentsTab.action";
import { useWindowSize } from "../../../../common/hooks/useWindowSize";
import LinearProgressWithLabel from "../../../../common/components/linearProgressWithLabel";
import DocumentRow from "./documentRow";

const DocumentsTab = ({ setSelectedTab }) => {
  const {
    authReducer: { session },
    themesReducer: {
      themesMainReducer: { currentThemeData },
      themesDocumentReducer: { documents, updateDocuments, loadingDocument },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [docList, setDocList] = useState([]);
  const [documentUpload, setDocumentUpload] = useState(false);
  const documentName = useDocumentName();
  const { fileUpload, fileDelete } = useS3FileUpload();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const [uploadValues, setUploadValues] = useState([]);
  const [dropUploadValues, setDropUploadValues] = useState([]);
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const [newDocuments, setNewDocuments] = useState([]);
  const [loadingDocs, setLoadingDocs] = useState(false);

  const saveDocument = (s3url, name) => {
    const obj = {
      name,
      description: "",
      enqueueSnackbar,
      added_by_id: session.id,
      uri: s3url,
    };
    setDropUploadValues([]);
    dispatch(
      themesDocumentPostAttempt({ data: obj, themeId: currentThemeData.id })
    );
  };
  const handleAddDocument = async (FileObject) => {
    setLoadingDocs(true);
    const { name } = FileObject;
    const path = `/documents/${currentThemeData.id}/`;
    if (documents.findIndex((doc) => doc.name === name) === -1) {
      await fileUpload(FileObject, path, setUploadValues, FileObject.name).then(
        (res) => {
          if (res) {
            enqueueSnackbar("File successfully uploaded.", {
              variant: "success",
            });
            setLoadingDocs(false);
            saveDocument(res.location, res.name);
          }
        }
      );
    } else if (documents.findIndex((doc) => doc.name === name) !== -1) {
      enqueueSnackbar("This document was already added.", {
        variant: "error",
      });
      setLoadingDocs(false);
    }
  };

  const uploadHandler = async (e, action) => {
    if (forbiddenFiles.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }

    if (files?.length > 1) {
      enqueueSnackbar("Can be uploaded only one document at ones.", {
        variant: "error",
      });

      return;
    }

    const fileList = [];
    let dismissedFiles = 0;

    setLoadingDocs(true);

    setDropUploadValues((items) => {
      const currentFiles = [...items];

      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name,
      }));
      return [...currentFiles, ...newFiles];
    });
    files.forEach(async (file) => {
      if (file.size <= 500000000) {
        const pathname = `/documents/${currentThemeData.id}/`;
        await fileUpload(file, pathname, setDropUploadValues, file.name).then(
          (res) => {
            if (res && res.location) {
              if (
                [...newDocuments, ...documents].indexOf(res.location) === -1
              ) {
                fileList.push(res.location);
                setLoadingDocs(false);
              } else {
                dismissedFiles++;
                setForbiddenFiles((prevFiles) => {
                  const localFiles = [...prevFiles];
                  localFiles.push({
                    name: file.name,
                    error: "Document already added.",
                  });
                  return localFiles;
                });
                setDropUploadValues((prevState) => {
                  const currentProgress = [...prevState];
                  const index = currentProgress
                    .map((item) => item.name)
                    .lastIndexOf(file.name);
                  currentProgress[index] = {
                    progress: "NOT_ALLOWED",
                    name: file.name,
                  };
                  return currentProgress;
                });
                enqueueSnackbar("This document was already added.", {
                  variant: "error",
                });
                setLoadingDocs(false);
              }
            }
          }
        );
      } else {
        dismissedFiles++;
        setDropUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === file.name
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: file.name,
          };

          return currentProgress;
        });
        setLoadingDocs(false);
        if (file.size > 500000000) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error: "Your file is too large. File size limit: 500MB",
            });
            return localFiles;
          });
          enqueueSnackbar(
            "We could not upload your image because your file size is too big. Please make sure the file is less than 500 MB",
            {
              variant: "error",
            }
          );
          setLoadingDocs(false);
        }
      }
      if (fileList.length === files.length - dismissedFiles) {
        setNewDocuments([...fileList]);
      }
    });

    e.target.value = "";
  };

  const handleMoreOptionClick = async (props, id, name) => {
    if (!blockFunctionality) {
      setDocumentUpload(true);
      const pathname = `/documents/${currentThemeData.id}/${name}`;

      if (props === "delete") {
        await fileDelete(pathname).then(async () => {
          await dispatch(
            themesDocumentDeleteAttempt({
              document_id: id,
              enqueueSnackbar,
            })
          );
          setDocumentUpload(false);
        });
      }
    } else {
      enqueueSnackbar(
        "You have View-Only access. To Delete a Document, please ask your Admin to upgrade your account to Standard access.",
        {
          variant: "warning",
        }
      );
    }
  };

  useEffect(() => {
    if (newDocuments?.length && newDocuments[0])
      saveDocument(newDocuments[0], getFilename(newDocuments[0]));
  }, [newDocuments]);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);
  useEffect(() => {
    if (currentThemeData) {
      dispatch(themesDocumentGetAttempt({ themeId: currentThemeData.id }));
    }
  }, [currentThemeData]);

  useEffect(() => {
    if (currentThemeData && updateDocuments) {
      dispatch(themesDocumentGetAttempt({ themeId: currentThemeData.id }));
    }
  }, [updateDocuments]);

  useEffect(() => {
    if (selectedCouncil && selectedCouncil.name === "Kyndryl") {
      setDocList(documents.filter((doc) => !doc.project));
    } else {
      setDocList(documents);
    }
  }, [documents, selectedCouncil]);

  if (loadingDocument) {
    return <Loading />;
  }
  return (
    <section className={styles.mainContainer}>
      <div className={styles.contentWrp}>
        <div className={styles.headerWrp}>
          <span className={styles.headerText}>Documents</span>
          <div className={styles.dropBoxContainer}>
            <div className={styles.dragAndDropContainer}>
              <DragAndDrop
                uploadHandler={uploadHandler}
                loading={loadingDocs}
                customText="drag and drop documents here"
              />
            </div>

            {forbiddenFiles?.length ? (
              <div className={styles.forbiddenfileContainer}>
                Files that weren't allowed:{" "}
                {forbiddenFiles.map((file) => (
                  <span className={styles.forbiddenfileName}>
                    {`${file.name}: `}
                    <span className={styles.errorText}> {file.error}</span>
                  </span>
                ))}
              </div>
            ) : null}
          </div>
          <div>
            {" "}
            {!blockFunctionality ? (
              <FilePicker
                className={styles.filePicker}
                maxSize={500}
                onChange={(FileObject) => {
                  handleAddDocument(FileObject);
                }}
                onError={() =>
                  enqueueSnackbar(
                    "We could not upload your document because your file size is too big. Please make sure the file is less than 500 MB",
                    {
                      variant: "error",
                    }
                  )
                }
              >
                {documentUpload || loadingDocument ? (
                  <Loading />
                ) : (
                  <Button
                    btn={BtnType.FRAME_LESS}
                    className={styles.newDocButton}
                    icon="icn-add"
                  >
                    Add a document
                  </Button>
                )}
              </FilePicker>
            ) : (
              <Tooltip
                title="You have View-Only access. To Add a Document, please ask your Admin to upgrade your account to Standard access."
                placement="top"
              >
                <div>
                  <Button
                    className={styles.newTask}
                    btn={BtnType.DISABLED}
                    icon="icn-add"
                  >
                    Add a document
                  </Button>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <div className={styles.loadingBlock}>
          <div className={styles.loadingUploadContainer}>
            {uploadValues &&
              Boolean(uploadValues.length) &&
              uploadValues[0] !== 100 && (
                <LinearProgressWithLabel
                  value={uploadValues[0]?.progress || 0}
                />
              )}{" "}
            {dropUploadValues?.length
              ? dropUploadValues.map(
                  (bar, index) =>
                    bar &&
                    bar?.progress !== "NOT_ALLOWED" && (
                      <div className={styles.loaderContainer}>
                        <div className={styles.loaderNamingWrp}>
                          {" "}
                          <div className={styles.fileName}>
                            {dropUploadValues[index]?.name}
                          </div>
                          <LinearProgressWithLabel
                            mode={
                              dropUploadValues[index]?.progress === "FAILED" &&
                              "error"
                            }
                            value={
                              dropUploadValues[index]?.progress === "FAILED"
                                ? 100
                                : dropUploadValues[index]?.progress || 0
                            }
                          />
                        </div>
                      </div>
                    )
                )
              : null}
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.grid}>
            {docList?.length && !isMobile ? (
              <div className={styles.gridHeader}>
                <div className={styles.document}>Document</div>
                <div className={styles.project}>Uploaded To</div>
                <div className={styles.task}>Uploaded By</div>
              </div>
            ) : null}
            <div className={styles.gridBody}>
              {docList?.length ? (
                docList.map((document) => (
                  <DocumentRow
                    key={document.id}
                    document={document}
                    documentName={documentName.getName(document.name)}
                    handleMoreOptionClick={handleMoreOptionClick}
                    setSelectedTab={setSelectedTab}
                  />
                ))
              ) : (
                <div className={styles.noData}>Upload a Document</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DocumentsTab;

import React from "react";
import { Label, MultiSelect } from "../../../../../primitives";
import { Icon, smSize } from "../../../../../../common/icon";

const BusinessModal = ({
  businessOptions,
  handleBusinessChange,
  prevBusiness,
  handleBusinessRemove,
  selectedCouncilName,
}) => (
  <>
    <Label>
      {selectedCouncilName === "Kyndryl" ? "Practice" : " Business"}
    </Label>
    <MultiSelect
      name="name"
      placeholder={`Select ${
        selectedCouncilName === "Kyndryl" ? "Practice" : " Business"
      } Tag`}
      labelField="name"
      valueField="id"
      searchBy="name"
      options={businessOptions}
      onChange={(value) => handleBusinessChange(value)}
    />
    <div>
      {prevBusiness &&
        prevBusiness.map((item) => (
          <div key={item.id}>
            {item.name}{" "}
            <span onClick={() => handleBusinessRemove(item.id)}>
              <Icon {...smSize} icon="icn-button-delete" />
            </span>
          </div>
        ))}
    </div>
  </>
);
export default BusinessModal;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useModelPopup } from '../../../common/hooks';
import { useSnackbar } from 'notistack';
import styles from './adminIdeas.module.scss';
import AddSectionModal from './addSection/addSection.component';
import { Button, BtnType, Label, Loading } from '../../primitives';
import { CheckType } from "modules/primitives";
import CheckBox from './addForm/checkBox';
import {
  formGetAttempt,
  setIdeaFormFields,
  clearCurrentForm,
} from './adminIdeas.action';
import SectionBlock from './sectionBlock';
import { formsAddAttempt, formsPatchAttempt } from './adminIdeas.action';
import EmbedCodeStep from './sectionBlock/lastStep/index';
import LoadingComponent from 'modules/primitives/loading/loading.component';
import classnames from "classnames";

const EditFormComponent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    adminIdeasReducer: {
      loading,
      currentIdeaFormFields,
      changed,
      selectedTemplate,
    },
  } = useSelector((state) => state);

  const [idea_name, handleAddIdeaName] = useState('');
  const [description, handleAddDescription] = useState('Please tell us about your idea by filling out the information below. You will be notified when your Idea is reviewed.');
  const [defaultTemplate, setAsDefaultTemplate] = useState(false);
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentStepArray, setCurrentStepArray] = useState([]);
  const [stepsLength, setStepsLength] = useState(3);
  const [formHasAreaOfFocus, setFormHasAreaOfFocus] = useState(false);

  useEffect(() => {
    if (currentIdeaFormFields?.length) {
      const itHasAreaOfFocus = currentIdeaFormFields
        .some(f => f.category_type === 'area_of_focus');

      setFormHasAreaOfFocus(itHasAreaOfFocus);
    }
  }, [currentIdeaFormFields]);

  const { enqueueSnackbar } = useSnackbar();

  const path = history.location.pathname;
  const templateId = history.location.pathname.substring(
    path.lastIndexOf('/') + 1
  );

  const addFieldClick = (field) => {
    if (currentStepArray) {
      setCurrentStepArray([...currentStepArray, field]);
    } else {
      setCurrentStepArray([field]);
    }
  };

  const handleAddStepClick = () => {
    if (currentStepArray?.length > 0) {
      setStepsLength((prevStepLength) => prevStepLength + 1);
      setStep((prevStep) => prevStep + 1);
    } else {
      enqueueSnackbar('Please add field first', { variant: 'warning' });
    }
  };

  const handleAddFormClick = () => {
    popup.show({
      title: 'Add field',
      component: (
        <AddSectionModal
          mode="add"
          curStep={step}
          selectedCouncil={selectedCouncil.id}
          currentUser={session}
          ideas_template_id={templateId}
          currentStepArray={currentStepArray}
          addFieldClick={addFieldClick}
          formContainsAreOfFocus={formHasAreaOfFocus}
        />
      ),
    });
  };

  const handleNextClick = () => {
    if (currentStepArray?.length > 0 && step < stepsLength) {
      setStep((prevStep) => prevStep + 1);
    } else {
      enqueueSnackbar('Please add field first', { variant: 'warning' });
    }
  };

  const handlePrevClick = () => {
    if (step > 1) {
      setStep((prevStep) => prevStep - 1);
      if (!currentStepArray.length && step !== stepsLength && step !== 2) {
        setStepsLength((prevStep) => prevStep - 1);
      }
    }
  };

  const handleEditFormClick = (position, step_nr, submitData) => {
    let editedFieldFlag = false;
    const editedArray = currentIdeaFormFields.map((field) => {
      if (
        field.position === position &&
        field.step_nr === step_nr &&
        !editedFieldFlag
      ) {
        field = submitData;
        editedFieldFlag = true;
      }
      return field;
    });

    saveIdeaField(editedArray);

    dispatch(setIdeaFormFields(editedArray));
  };

  const handleDeleteField = (modifiedArray) => {
    saveIdeaField(modifiedArray);
  };

  useEffect(() => {
    const stepArray = currentIdeaFormFields.filter((field) => {
      if (field.step_nr === step && !field._destroy) {
        return true;
      }
    });
    setCurrentStepArray(stepArray);
  }, [currentIdeaFormFields, step, changed]);

  useEffect(() => {
    const formId = history.location.pathname.substring(
      path.lastIndexOf('/') + 1
    );
    if (formId !== 'newForm') {
      handleAddDescription(selectedTemplate?.description);
      handleAddIdeaName(selectedTemplate?.name);
      setAsDefaultTemplate(selectedTemplate?.active || false);
      if (selectedTemplate.stepsArray) {
        setCurrentStepArray(selectedTemplate.stepsArray[step - 1]);
        if (selectedTemplate.stepsArray.length > 1) {
          setStepsLength(selectedTemplate.stepsArray.length + 1);
        }
      }
    }
  }, [selectedTemplate.name]);

  useEffect(() => {
    const formId = history.location.pathname.substring(
      path.lastIndexOf('/') + 1
    );
    if (session && formId !== 'newForm') {
      dispatch(
        formGetAttempt({
          id: history.location.pathname.substring(path.lastIndexOf('/') + 1),
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(setIdeaFormFields([]));
      dispatch(clearCurrentForm([]));
    }
  }, [session]);

  if (loading) {
    return <Loading></Loading>;
  }

  const saveIdeaField = (ideas_sections_attributes) => {
    if (props.location.state?.mode === 'add') {
      const submitData = {
        name: idea_name,
        description: description,
        created_by_id: session?.id,
        selectedCouncil: selectedCouncil?.id,
        active: defaultTemplate,
        ideas_sections_attributes,
      };
      dispatch(formsAddAttempt({ data: submitData, enqueueSnackbar }));
    } else {
      const submitData = {
        name: idea_name,
        description: description,
        selectedCouncil: selectedCouncil?.id,
        created_by_id: session?.id,
        active: defaultTemplate,
        id: selectedTemplate?.idea_template_id,
        ideas_sections_attributes,
      };
      dispatch(formsPatchAttempt({ data: submitData, enqueueSnackbar }));
    }
  };

  const saveIdeaForm = () => {
    if (currentStepArray?.length > 0 || step === 2) {
      setStep(step + 1);
      saveIdeaField(currentIdeaFormFields);
      const ideaFormsWithoutDeleted = currentIdeaFormFields.filter((field) => {
        return !field._destroy;
      });
      dispatch(setIdeaFormFields(ideaFormsWithoutDeleted));
    } else {
      enqueueSnackbar('Please add field first', { variant: 'warning' });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <span
            className={styles.rootText}
            onClick={() => {
              history.push("/admin");
            }}
          >
            ADMIN
          </span>
          <span className={styles.separationBar}> / </span>
          <span className={styles.rootText}>Ideas</span>
        </h4>
      </div>
      <div className="row">
        <div className={classnames(styles.tabsContainer, "col-md-8")}>
          <h5>
            <span
              onClick={() => {
                history.push("/admin/ideas");
              }}
              className={styles.tabsText}
            >
              {" "}
              Ideas{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span
              onClick={() => {
                history.push("/admin/ideas?tab=forms");
              }}
              className={styles.selectedVar}
            >
              {" "}
              Forms{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span className={styles.selectedVar}>
              {" "}
              {selectedTemplate?.name}
            </span>
          </h5>
        </div>
      </div>
      <div className={styles.flexWrapper}>
        <div className={styles.ideasSectionContainer}>
          <div className={styles.textHeader}>
            <p className={styles.taskHeaderText}>
              Step: {step} of {stepsLength}
            </p>
            <h4 className="bold">
              {props.location.state?.mode === "add"
                ? "Create Form"
                : "Edit Form"}
            </h4>
          </div>
          {step === 1 && (
            <div className={styles.textBoxContainer}>
              <div>
                <Label>Form title</Label>
                <div className={styles.textBoxWrp}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Add title name"
                    onChange={(val) => {
                      handleAddIdeaName(val.target.value);
                    }}
                    value={idea_name}
                    className={styles.textBox}
                  />
                </div>
              </div>
              <div>
                <Label>Instructions</Label>
                <div className={styles.textBoxWrp}>
                  <textarea
                    name="description"
                    placeholder="Add description"
                    onChange={(val) => {
                      handleAddDescription(val.target.value);
                    }}
                    value={description}
                    className={styles.textAreaBox}
                    rows="4"
                    cols="50"
                  />
                </div>
              </div>
              <div className="row">
                <div className={styles.checkboxBlock}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={defaultTemplate}
                    onChange={() => {
                      if (defaultTemplate && selectedTemplate?.active) {
                        setErrorMessage(
                          "To switch Default forms, please select another one as the Default."
                        );
                      } else {
                        setAsDefaultTemplate((prevState) => !prevState);
                      }
                    }}
                  />
                  <Label>Default</Label>
                </div>
                <div>
                  {errorMessage && (
                    <span className={styles.errorMessage}>{errorMessage}</span>
                  )}
                </div>
              </div>
            </div>
          )}
          {!currentIdeaFormFields?.length &&
            props.location.state?.mode !== "add" && <LoadingComponent />}
          {step !== stepsLength ? (
            <>
              {currentStepArray?.map((section, index) => {
                if (!section._destroy) {
                  return (
                    <SectionBlock
                      key={index}
                      item={section}
                      handleEditClick={handleEditFormClick}
                      handleDeleteField={handleDeleteField}
                      create
                      mode={props.location.state?.mode}
                      currentStepArray={currentStepArray}
                      positions={[]}
                      ideas_template_id={templateId}
                      curStep={step}
                      index={index}
                    />
                  );
                }
              })}
              <div>
                <div className={styles.buttonWrapper}>
                  {step === stepsLength - 1 && (
                    <>
                      {!formHasAreaOfFocus ? null : (
                        <Button
                          btn={
                            currentStepArray?.length === 0
                              ? BtnType.DISABLED
                              : BtnType.REGULAR
                          }
                          icon="icn-add"
                          onClick={() => handleAddStepClick()}
                          className={styles.addTemplateButton}
                          disabled={currentStepArray?.length === 0}
                        >
                          Create Another Step
                        </Button>
                      )}
                    </>
                  )}
                  <Button
                    btn={BtnType.REGULAR}
                    icon="icn-add"
                    onClick={() => handleAddFormClick()}
                    className={styles.addTemplateButton}
                  >
                    Add field
                  </Button>
                </div>
                <div className={styles.footerButtonOuterBlock}>
                  <Button
                    btn={BtnType.REGULAR}
                    onClick={() => handlePrevClick()}
                    className={
                      (styles.addTemplateButton,
                      step === 1 && styles.hiddenPrevButton)
                    }
                  >
                    Prev
                  </Button>
                  {step === stepsLength - 1 ? (
                    <>
                      {/* {
                        !formHasAreaOfFocus ? ( */}
                      <Button
                        btn={
                          currentStepArray?.length === 0
                            ? BtnType.DISABLED
                            : BtnType.REGULAR
                        }
                        icon="icn-add"
                        onClick={() => handleAddStepClick()}
                        className={styles.addTemplateButton}
                        disabled={currentStepArray?.length === 0}
                      >
                        Create Another Step
                      </Button>
                      {/* ) : ( */}
                      <Button
                        btn={BtnType.REGULAR}
                        onClick={() => saveIdeaForm()}
                        className={styles.addTemplateButton}
                      >
                        Save
                      </Button>
                      {/* )
                      } */}
                    </>
                  ) : (
                    <Button
                      btn={BtnType.REGULAR}
                      onClick={() => handleNextClick()}
                      className={styles.addTemplateButton}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <EmbedCodeStep
              handleEditClick={handleEditFormClick}
              onBackClick={handlePrevClick}
              mode={props.location.state?.mode}
              form={selectedTemplate}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditFormComponent;

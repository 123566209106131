export const BtnType = {
  REGULAR: 0,
  MICRO: 1,
  FRAME_LESS: 2,
  OUTLINE: 3,
  LINK: 4,
  OUTLINE_LIGHT: 5,
  HIGLIGHT: 6,
  TAG_BLUE_LIGHT: 7,
  TAG_BLUE: 8,
  TAG_OUTLINE_BLUE: 9,
  DISABLED: 10,
  OUTLINE_BLANK: 11,
  DELETE: 12,
};

import React from "react";
import classNames from "classnames";

import styles from "./productDetails.module.scss";

const ProductDetails = ({ product, selectedCouncil }) => {
  const handleProductUrlClick = () => {
    const absoluteUrl =
      product.product_link.startsWith("http://") ||
      product.product_link.startsWith("https://")
        ? product.product_link
        : "http://" + product.product_link;

    window.open(absoluteUrl, "_blank");
  };

  return (
    <div className={styles.detailsPanel}>
      <div className={styles.webWrapper}>
        <div className="d-flex flex-column">
          <span className={styles.webUrl} onClick={handleProductUrlClick}>
            {product.product_link &&
              product.product_link.replace(/\b(http:\/\/|https:\/\/)\b/g, "")}
          </span>
        </div>
      </div>
      {product.description ? (
        <div className={classNames("caption", styles.details)}>
          {product.description || '-'}
        </div>
      ) : null}
      <div className="row">
        <div className="col-12">
          <div className={styles.dataRow}>
            {product?.topic_ids?.length ? (
              <div className={`mt-3 ${styles.category}`}>
                <span className={styles.name}>Technologies</span>
                <div className="d-flex align-items-center flex-wrap mt-2">
                  {product.topic_ids.map((t) => (
                    <span key={t.topic_id} className={styles.topic}>
                      {t.name}
                    </span>
                  ))}
                </div>
              </div>
            ) : null}
            {product?.industry_ids?.length ? (
              <div className={`mt-3 ${styles.category}`}>
                <span className={styles.name}>
                  {selectedCouncil?.name === "Freddie Mac"
                    ? "Housing Cycle"
                    : "Industries"}
                </span>
                <div className="d-flex align-items-center flex-wrap mt-2">
                  {product?.industry_ids.map((t) => (
                    <span key={t.industry_id} className={styles.industry}>
                      {t.name}
                    </span>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;

import { getOrCreateTooltip } from "./utils";

export const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b) => b.lines);
    const tableBody = document.createElement("tbody");

    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];
      const span = document.createElement("span");

      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = "2px";
      span.style.marginRight = "10px";
      span.style.height = "10px";
      span.style.width = "10px";
      span.style.display = "inline-block";

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td");
      td.style.borderWidth = 0;

      const mainWrapper = document.createElement("div");
      mainWrapper.classList.add("d-flex", "flex", "flex-column");

      const dataAsArray = body[0].split("//");
      const nameWrapper = document.createElement("div");
      mainWrapper.classList.add("d-flex", "flex", "flex-column");

      const name = document.createElement("span");
      name.style.fontWeight = "bold";
      name.innerText = dataAsArray[0];

      nameWrapper.appendChild(span);
      nameWrapper.appendChild(name);

      const div1 = document.createElement("div");
      div1.classList.add("d-flex", "flex", "flex-column");
      div1.style.fontSize = "14px";
      div1.style.lineHeight = "15px";

      const finalContent = dataAsArray
        .filter((str, idx) => str.length && idx !== 0)
        .reduce((acc, str) => `${acc}<span>${str}</span>`, "");

      div1.insertAdjacentHTML("beforeend", finalContent);
      mainWrapper.appendChild(nameWrapper);
      mainWrapper.appendChild(div1);
      td.appendChild(mainWrapper);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
  tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
  tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
};

import { OptionType } from "modules/primitives";

export const companyOptions = [
  { val: "pause", text: "Pause Evaluation" },
  { val: "activate", text: "Resume Evaluation" },
  { val: "remove", text: "Remove from project", type: OptionType.DANGER },
];

export const companiesPageSettings = {
  pageSize: 20,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
};

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";

import { BtnType, Button } from "modules/primitives";
import { ModalFooter } from "application/modal";
import { formInitialValues } from './constant';
import useModelPopup from "common/hooks/modelPopup.hook";
import { editProductDetails } from "modules/companies/product/store/product.actions";
import UploadProductImages from "./uploadProductImages/uploadProductImages.component";

const EditProductImages = ({ product }) => {
  const popup = useModelPopup();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(formInitialValues);
  const [images, setImages] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (product) {
      setFormValues(product);
      setImages(product.images || []);
    }
  }, [product]);

  const handleSave = () => {
    setShowLoading(true);

    const payload = {
      companyId: product.company.id,
      productId: product.id,
      data: {
        images,
      },
    }

    dispatch(editProductDetails(payload));
    popup.hide();
  };

  const handleCancelClick = () => {
    popup.hide();
  }

  return (
    <div>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={formValues}
        onSubmit={handleSave}
      >
        {({ values, handleSubmit, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <div className="px-4">
              <UploadProductImages
                product={product}
                uploadDirName={`companies/${product.company.id}/products/${product.id}/images/`}
                handleUpload={setImages}
              />
            </div>
            <ModalFooter>
              <Button
                className="mr-3"
                btn={BtnType.FRAME_LESS}
                onClick={handleCancelClick}
                disabled={showLoading}
              >
                Cancel
              </Button>
              <Button
                disabled={showLoading}
                type="submit"
                onClick={handleSubmit}
                btn={BtnType.REGULAR}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditProductImages;

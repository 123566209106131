import React, { useCallback, useMemo } from "react";
import { useSnackbar } from "notistack";
import { format } from "date-fns";

import styles from "./styles.module.scss";
import CloseIcon from "common/icon/closeIcon.component";
import { months } from "modules/reporting/fordReports/actualScorecard/constants";
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";

const FordActiveFilters = ({
  filters,
  expandedTableKey,
  highLightColumn,
  setFilters,
  getTableData,
  getUpdatedData,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const activeFilters = useMemo(() => {
    return Object.keys(filters).filter((key) => key !== "dateType").length > 0;
  }, [filters]);

  const removeField = useCallback(
    (prop, id) => {
      const newFilters = {
        ...filters,
        [prop]: {
          ...(filters[prop] || {}),
          [id]: null,
        },
      };

      setFilters(newFilters);

      const makeFilters = makeActualScoreCardFilterData(newFilters);
      const payload = {
        enqueueSnackbar,
        data: makeFilters,
      };

      getUpdatedData(payload);

      if (expandedTableKey?.length > 0) {
        getTableData({
          reducerKey: expandedTableKey,
          highLightColumn,
          data: {
            ...makeFilters,
            section: expandedTableKey,
          },
        });
      }
    },
    [filters, expandedTableKey, highLightColumn]
  );

  const removeFilterTag = useCallback(
    (prop, id, simpleProp) => {
      let newFilters = filters;

      if (simpleProp) {
        newFilters = {
          ...newFilters,
          [prop]: null,
        };
      } else {
        newFilters = {
          ...filters,
          [prop]: filters[prop].filter((elem) => elem.id !== id),
        };
      }

      setFilters(newFilters);

      const makeFilters = makeActualScoreCardFilterData(newFilters);
      const payload = {
        enqueueSnackbar,
        data: makeFilters,
      };

      getUpdatedData(payload);

      if (expandedTableKey?.length > 0) {
        getTableData({
          reducerKey: expandedTableKey,
          highLightColumn,
          data: {
            ...makeFilters,
            section: expandedTableKey,
          },
        });
      }
    },
    [filters, expandedTableKey, highLightColumn]
  );

  const getMonthName = useMemo(() => {
    if (!filters.month) return null;

    return months.find((m) => m.index === filters.month)?.name;
  }, [filters]);

  const makeProjectFieldsRender = useMemo(() => {
    const hasFields = Object.keys(filters.projectFieldValues || {}).length > 0;

    if (!hasFields) return null;

    return Object.keys(filters.projectFieldValues)
      .filter((key) => filters.projectFieldValues[key]?.length > 0)
      .map((key) =>
        filters.projectFieldValues[key].map((item) => (
          <div className={styles.tag} key={item.id}>
            <span>{item.name || item.field_name}</span>
            <span
              className={styles.tagDelete}
              onClick={() => {
                removeField("projectFieldValues", key);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        ))
      );
  }, [filters?.projectFieldValues, removeField]);

  const makeProjectMetricsRender = useMemo(() => {
    const hasFields = Object.keys(filters.projectMetrics || {}).length > 0;

    if (!hasFields) return null;

    return Object.keys(filters.projectMetrics)
      .filter((key) => filters.projectMetrics[key]?.length > 0)
      .map((key) => {
        if (key === "expected_job1_date" || key === "job1_date") {
          return (
            <div className={styles.tag} key={key}>
              <span>
                {`${format(
                  filters.projectMetrics[key][0],
                  "MMM dd, yyyy"
                )} - ${format(filters.projectMetrics[key][1], "MMM dd, yyyy")}`}
              </span>
              <span
                className={styles.tagDelete}
                onClick={() => {
                  removeField("projectMetrics", key);
                }}
              >
                <CloseIcon />
              </span>
            </div>
          );
        }

        return filters.projectMetrics[key].map((item) => (
          <div className={styles.tag} key={item.id}>
            <span>{item.name || item.field_name}</span>
            <span
              className={styles.tagDelete}
              onClick={() => {
                removeField("projectMetrics", key);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        ));
      });
  }, [filters?.projectMetrics, removeField]);

  if (!activeFilters) {
    return null;
  }

  return (
    <div className="d-flex align-items-center flex-wrap mt-3">
      {filters.pillars?.length
        ? filters.pillars.map((p) => (
            <div className={styles.tag} key={p.id}>
              <span>{p.name}</span>
              <span
                className={styles.tagDelete}
                onClick={() => {
                  removeFilterTag("pillars", p.id);
                }}
              >
                <CloseIcon />
              </span>
            </div>
          ))
        : null}
      {filters.plants?.length
        ? filters.plants.map((p) => (
            <div className={styles.tag} key={p.id}>
              <span>{p.name}</span>
              <span
                className={styles.tagDelete}
                onClick={() => {
                  removeFilterTag("plants", p.id);
                }}
              >
                <CloseIcon />
              </span>
            </div>
          ))
        : null}
      {filters.regions?.length
        ? filters.regions.map((r) => (
            <div className={styles.tag} key={r.id}>
              <span>{r.name}</span>
              <span
                className={styles.tagDelete}
                onClick={() => {
                  removeFilterTag("regions", r.id);
                }}
              >
                <CloseIcon />
              </span>
            </div>
          ))
        : null}
      {filters?.stage_template_ids?.length
        ? filters.stage_template_ids?.map((s) => (
            <div className={styles.tag} key={s.id}>
              <span>{s.name}</span>
              <span
                className={styles.tagDelete}
                onClick={() => {
                  removeFilterTag("stage_template_ids", s.id);
                }}
              >
                <CloseIcon />
              </span>
            </div>
          ))
        : null}
      {filters.stages?.length
        ? filters.stages.map((s) => (
            <div className={styles.tag} key={s.id}>
              <span>{s.name}</span>
              <span
                className={styles.tagDelete}
                onClick={() => {
                  removeFilterTag("stages", s.id);
                }}
              >
                <CloseIcon />
              </span>
            </div>
          ))
        : null}
      {filters.year && (
        <div className={styles.tag}>
          <span>{filters.year}</span>
          <span
            className={styles.tagDelete}
            onClick={() => {
              removeFilterTag("year", filters.year, true);
            }}
          >
            <CloseIcon />
          </span>
        </div>
      )}
      {filters.month && (
        <div className={styles.tag}>
          <span>{getMonthName}</span>
          <span
            className={styles.tagDelete}
            onClick={() => {
              removeFilterTag("month", filters.month, true);
            }}
          >
            <CloseIcon />
          </span>
        </div>
      )}
      {filters.quarter && (
        <div className={styles.tag}>
          <span>{filters.quarter.name}</span>
          <span
            className={styles.tagDelete}
            onClick={() => {
              removeFilterTag("quarter", filters.quarter, true);
            }}
          >
            <CloseIcon />
          </span>
        </div>
      )}
      {makeProjectFieldsRender}
      {makeProjectMetricsRender}
    </div>
  );
};

export default React.memo(FordActiveFilters);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { useWindowSize } from "common/hooks/useWindowSize";
import { Pagination, Loading } from "../../../../primitives";
import styles from "./activity.module.scss";
import DesktopActivityRow from "./desktopActivityRow.component";
import MobileActivityRow from "./mobileActivityRow.component";
import { getCompanyActivitiesAttempt } from "../../company.action";

const PAGINATION_DEFAULTS = {
  pageSize: 5,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
};
const ActivityComponent = ({ companyId }) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const [pageSetting, setPageSetting] = useState(PAGINATION_DEFAULTS);

  const { enqueueSnackbar } = useSnackbar();

  const {
    companiesReducer: {
      companyReducer: {
        companyActivity,
        activitiesLoading,
        activitiesPagination,
      },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(
      getCompanyActivitiesAttempt({
        enqueueSnackbar,
        companyId,
        page: 1,
        total: 5,
      })
    );
  }, []);

  const handlePagingChange = (page) => {
    if (page === pageSetting.current) {
      return;
    }

    setPageSetting((prev) => ({
      ...prev,
      current: page,
    }));

    dispatch(
      getCompanyActivitiesAttempt({
        enqueueSnackbar,
        companyId,
        page,
        total: 5,
      })
    );
  };

  useEffect(() => {
    if (activitiesPagination && activitiesPagination?.total !== 0) {
      setPageSetting({
        ...pageSetting,
        total: activitiesPagination?.total,
      });
    }
  }, [activitiesPagination]);

  if (activitiesLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.grid}>
        {isMobile ? (
          <div className={styles.gridHeaderMobile}>
            <span className={styles.title}>Activity</span>
            <span className={styles.title}>Details</span>
            <span className={styles.title}>Date</span>
          </div>
        ) : (
          <div className={styles.gridHeader}>
            <span className={styles.title}>Activity</span>
            <span className={styles.title}>Details</span>
            <span className={styles.title}>Date</span>
          </div>
        )}
        <div className={styles.gridBody}>
          {companyActivity.map((activity, index) =>
            isMobile ? (
              <MobileActivityRow
                activity={activity}
                key={`${activity.details}_${index}`}
              />
            ) : (
              <DesktopActivityRow
                key={`${activity.details}_${index}`}
                activity={activity}
              />
            )
          )}
        </div>
      </div>
      <div className="col d-flex align-items-center justify-content-center">
        {companyActivity?.length && (
          <Pagination {...pageSetting} onChange={handlePagingChange} />
        )}
      </div>
    </div>
  );
};

export default React.memo(ActivityComponent);

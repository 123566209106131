import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import "react-table/react-table.css";
import classnames from "classnames";

import adminFuseHoc from "common/HOC/adminFuseHoc";
import {
  activateFieldToggle,
  deleteFieldToggle,
  deleteProjectField,
  getProjectsFieldsAttempt,
  getProjectsStageFieldsAttempt,
  getStagesTemplateAttempt,
  getStageTemplateByIdAttempt,
} from "modules/admin/adminProjects/adminProjects.actions";
import AddEditProjectsFieldsModal from "modules/admin/adminProjects/adminProjectFields/addEditProjectsField";
import { getAllTeamsAttempt } from "modules/teams/teams.action";
import {
  Button,
  BtnType,
  MoreOptions,
  Loading,
  Pagination,
} from "../../../primitives";
import styles from "./adminProjectTemplate.module.scss";
import { useModelPopup } from "../../../../common/hooks";
import ConfirmActionComponent from "../../../../common/components/confirmAction/confirmAction.component";

const AdminProjectFields = ({ instance }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const {
    councilReducer: { selectedCouncil },
    adminProjectsReducer: {
      stageTemplate,
      projectsFields,
      projectsStageFields,
      shouldReloadFields,
    },
  } = useSelector((state) => state);

  const { enqueueSnackbar } = useSnackbar();
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef();
  const [sortType, setSortType] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [stageID, setStageId] = useState(null);
  const [pageSetting, setPageSetting] = useState({
    pageSize: 50,
    current: 1,
    hideOnSinglePage: true,
    showTitle: false,
  });

  const isTopInstance = instance === "topProjectsFields";
  const fields = isTopInstance
    ? projectsFields.fields
    : projectsStageFields.fields;
  const totalPages = isTopInstance
    ? projectsFields.projectsFieldsPages
    : projectsStageFields.projectsStagePages;

  const handlePagingChange = (pg) => {
    setPageSetting((prev) => ({
      ...prev,
      current: pg,
    }));
  };

  useEffect(() => {
    if (projectsFields.fields.length) {
      setPageSetting((prev) => ({
        ...prev,
        total: totalPages.total,
      }));
    }
  }, [projectsFields.fields]);

  useEffect(() => {
    if (isTopInstance) {
      return;
    }

    const arr = history.location.pathname.split("/");
    const id = arr[arr.length - 1];

    setStageId(id);
  }, [history, isTopInstance]);

  useEffect(() => {
    dispatch(getStagesTemplateAttempt({ enqueueSnackbar }));
    dispatch(getAllTeamsAttempt({ enqueueSnackbar }));
  }, []);

  useEffect(() => {
    if (instance === "topProjectsFields") {
      dispatch(
        getProjectsFieldsAttempt({
          page: 1,
          pageSize: 50,
        })
      );
      return;
    }

    const arr = history.location.pathname.split("/");
    const id = arr[arr.length - 1];
    dispatch(getStageTemplateByIdAttempt({ id, enqueueSnackbar }));
    dispatch(
      getProjectsStageFieldsAttempt({
        stageId: id,
        page: 1,
        pageSize: 50,
      })
    );
  }, [instance]);

  const handleExportList = () => {
    csvLinkRef.current.link.click();
  };

  const DisabledButton = ({ showTooltip, setShowTooltip }) => (
    <>
      {showTooltip && (
        <div className={styles.exportModalTooltip}>
          <p>To be able to export list it must contain at least one element.</p>
        </div>
      )}
      <Button
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
        className={styles.deleteSubmitButton}
        btn={BtnType.DISABLED}
      >
        EXPORT
      </Button>
    </>
  );

  const handleDeleteClick = (item) => {
    popup.show({
      title: "Confirmation Delete",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmActionComponent
          alertText="You are about to delete this field for all Projects in your database, along with any related data. Are you sure?"
          confirmBtnName="Delete"
          onConfirm={() => handleDeleteConfirm(item)}
        />
      ),
    });
  };

  const handleDeleteConfirm = (field) => {
    dispatch(
      deleteProjectField({
        id: field.id,
        enqueueSnackbar,
      })
    );
  };

  const handleMoreOptionClick = (e, field) => {
    if (e === "delete") {
      handleDeleteClick(field);
    } else if (e === "edit") {
      handleNewButtonClick(field);
    }
  };

  const moreOptions = [
    { val: "edit", text: "Edit" },
    { val: "delete", text: "Delete" },
  ];

  const handleHideShow = (field) => {
    if (field.active) {
      dispatch(
        deleteFieldToggle({
          stageID,
          field,
        })
      );
    } else {
      dispatch(
        activateFieldToggle({
          stageID,
          field,
        })
      );
    }
  };

  const topLevelFieldsColumns = React.useMemo(() => [
    {
      Header: () => (
        <div
          style={{
            borderBottom: sortType === "sort" ? "2px solid black" : "",
            borderTop: sortType === "reverse" ? "2px solid black" : "",
          }}
        >
          Field Name
        </div>
      ),
      id: "name",
      position: 1,
      accessor: (row) => <div>{row?.field_name}</div>,
      width: "30%",
    },
    {
      Header: "Field Type",
      id: "type",
      accessor: (row) => <div>{row?.field_type}</div>,
      width: "30%",
    },
    {
      Header: () => (
        <div
          style={{
            borderBottom: sortType === "sort" ? "2px solid black" : "",
            borderTop: sortType === "reverse" ? "2px solid black" : "",
          }}
        >
          Position
        </div>
      ),
      id: "position",
      accessor: (row) => (
        <div className={styles.alignedCenter}>{row?.position}</div>
      ),
      width: "30%",
    },
    {
      Header: "Existing Records",
      id: "existing_records",
      accessor: (row) => (
        <div className={styles.alignedCenter}>{row?.existing_records || 0}</div>
      ),
      width: "30%",
    },
    {
      Header: "Options",
      id: "options",
      sortable: false,
      accessor: (row) => {
        const hideOptions =
          (row.field_name === "Possible Replications (Plants)" ||
            row.field_name === "Approved for Replication" ||
            row.field_name === "Possible Replications (Areas)" ||
            row.field_name === "Innovation Pillar" ||
            row.field_name === "Is this a Replication?") &&
          selectedCouncil?.name === "Ford";

        if (hideOptions) {
          return null;
        }

        return (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row);
            }}
          />
        );
      },
      width: 150,
      style: {
        overflow: "visible",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
      },
    },
  ]);
  const lowLevelFieldsColumns = React.useMemo(() => [
    {
      Header: () => (
        <div
          style={{
            borderBottom: sortType === "sort" ? "2px solid black" : "",
            borderTop: sortType === "reverse" ? "2px solid black" : "",
          }}
        >
          Field Name
        </div>
      ),
      id: "name",
      position: 1,
      accessor: (row) => <div>{row?.field_name}</div>,
      width: "30%",
    },
    {
      Header: "Field Type",
      id: "type",
      accessor: (row) => <div>{row?.field_type}</div>,
      width: "30%",
    },
    {
      Header: () => (
        <div
          style={{
            borderBottom: sortType === "sort" ? "2px solid black" : "",
            borderTop: sortType === "reverse" ? "2px solid black" : "",
          }}
        >
          Position
        </div>
      ),
      id: "position",
      accessor: (row) => (
        <div className={styles.alignedCenter}>{row?.position}</div>
      ),
      width: "30%",
    },
    {
      Header: "Existing Records",
      id: "existing_records",
      accessor: (row) => (
        <div className={styles.alignedCenter}>{row?.existing_records || 0}</div>
      ),
      width: "30%",
    },
    {
      Header: "Hide/Show",
      id: "status",
      accessor: (row) => (
        <div className="d-flex align-items-center justify-content-center">
          <label className={styles.switch}>
            <input
              type="checkbox"
              onChange={() => handleHideShow(row)}
              checked={row.active}
            />
            <span className={styles.slider} />
          </label>
        </div>
      ),
      width: "30%",
    },
  ]);

  const handleNewButtonClick = (data) => {
    popup.show({
      title: data ? `Edit ${data.field_type}` : "Add Text",
      show: true,
      height: "300",
      width: "540",
      component: (
        <AddEditProjectsFieldsModal
          mode={data ? "edit" : "add"}
          data={data}
          stageID={stageID}
          existingPositions={fields.map((elem) => ({
            position: elem.position,
            id: elem.id,
          }))}
        />
      ),
    });
  };

  const handleHeaderTableClick = (column) => {};

  const onTabClick = (tab) => {
    history.push(`/admin/projects/${tab}/${stageID}`);
  };

  const getDataWithPaginationParams = (currentPage, size) => {
    if (instance === "topProjectsFields") {
      dispatch(
        getProjectsFieldsAttempt({
          page: currentPage,
          pageSize: size,
        })
      );
      return;
    }

    const arr = history.location.pathname.split("/");
    const id = arr[arr.length - 1];
    dispatch(getStageTemplateByIdAttempt({ id, enqueueSnackbar }));
    dispatch(
      getProjectsStageFieldsAttempt({
        stageId: id,
        page: currentPage,
        pageSize: size,
      })
    );
  };
  useEffect(() => {
    if (pageSetting.current) {
      getDataWithPaginationParams(pageSetting.current, 50);
    }
  }, [pageSetting.current]);

  useEffect(() => {
    if (shouldReloadFields) {
      getDataWithPaginationParams(pageSetting.current, 50);
    }
  }, [shouldReloadFields]);

  return (
    <div className={styles.container}>
      {isTopInstance ? (
        <div className="row">
          <div className="col-10" style={{ margin: "0 auto" }}>
            <div className={styles.headerWrp}>
              <h4 className={styles.taskHeaderText}>
                <span
                  className={styles.rootText}
                  onClick={() => {
                    history.push("/admin");
                  }}
                >
                  ADMIN
                </span>
                <span className={styles.separationBar}> / </span>
                <span
                  className={styles.rootText}
                  onClick={() => {
                    history.push("/admin/projects");
                  }}
                >
                  PROJECTS
                </span>
                <span className={styles.separationBar}> / </span>
                <span>FIELDS</span>
              </h4>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-10" style={{ margin: "0 auto" }}>
            <div className={styles.headerWrp}>
              <h4 className={styles.taskHeaderText}>
                <span
                  className={styles.rootText}
                  onClick={() => {
                    history.push("/admin");
                  }}
                >
                  ADMIN
                </span>
                <span className={styles.separationBar}> / </span>
                <span
                  className={styles.rootText}
                  onClick={() => {
                    history.push("/admin/projects");
                  }}
                >
                  PROJECTS
                </span>
                <span className={styles.separationBar}> / </span>
                <span className={styles.companyName}>
                  {stageTemplate?.name}
                </span>
                <span className={styles.separationBar}> / </span>
                <span className={styles.rootText}>FIELDS</span>
              </h4>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div
          className={classnames(styles.tabsContainer, "col-10")}
          style={{ margin: "0 auto" }}
        >
          {!isTopInstance ? (
            <h5>
              <span
                onClick={() => onTabClick("stages")}
                className={styles.tabsText}
              >
                {" "}
                STAGES{" "}
              </span>
              <span className={styles.separationBar}> / </span>
              <span
                onClick={() => onTabClick("tasks")}
                className={styles.tabsText}
              >
                {" "}
                TASKS{" "}
              </span>
              <span className={styles.separationBar}> / </span>
              <span className={styles.selectedVar}> FIELDS </span>
              <span className={styles.separationBar}> / </span>
              <span
                className={styles.tabsText}
                onClick={() => onTabClick("groups")}
              >
                {" "}
                FIELD GROUPS{" "}
              </span>
              <span className={styles.separationBar}> / </span>
              <span
                className={styles.tabsText}
                onClick={() => onTabClick("setup")}
              >
                {" "}
                PROJECT SET-UP{" "}
              </span>
            </h5>
          ) : null}
          <div className={styles.buttons}>
            <Button
              className={styles.newTask}
              btn={BtnType.REGULAR}
              icon="icn-add"
              onClick={() => handleNewButtonClick()}
            >
              Add Field
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        {" "}
        <div className="col-10" style={{ margin: "0 auto" }}>
          <div className={styles.buttonWrp}>
            <div className={styles.wrpIn}>
              {fields?.length > 0 ? (
                <div>
                  <Button
                    disabled={!fields.length}
                    onClick={handleExportList}
                    btn={BtnType.OUTLINE}
                  >
                    EXPORT
                  </Button>
                  <CSVLink
                    data={csvData}
                    filename="Project-Fields.csv"
                    style={{ display: "none" }}
                    ref={csvLinkRef}
                  />
                </div>
              ) : (
                <DisabledButton
                  setShowTooltip={setShowTooltip}
                  showTooltip={showTooltip}
                />
              )}
              <div className={styles.totalCount}>
                {totalPages?.total} Fields
              </div>
            </div>
          </div>
          <ReactTable
            data={fields}
            showPagination={!isTopInstance}
            columns={
              isTopInstance ? topLevelFieldsColumns : lowLevelFieldsColumns
            }
            pageSize={50}
            className={styles.table}
            loading={
              projectsFields.loading || projectsStageFields.loading ? (
                <Loading />
              ) : null
            }
            getTheadThProps={(state, rowInfo, column) => ({
              onClick: () => handleHeaderTableClick(column),
            })}
            defaultSortDesc
          />
          {totalPages?.total ? (
            <div className={styles.paginationCon}>
              <Pagination {...pageSetting} onChange={handlePagingChange} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default adminFuseHoc(AdminProjectFields);

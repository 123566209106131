import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";

import TractionAI from "modules/tractionAI/AIMainComponent";
import { tractionAIMainStyles } from "modules/tractionAI/constants";
import {
  AISelectScreen,
  AISetCompanySnapshotData,
  AISetScoutData,
  AISetSWOTData,
  AISetTrendData,
  AISetRecommendData,
  WSAISetConnectionStatus,
} from "modules/tractionAI/store/tractionAI.actions";
import AIModalHeader from "modules/tractionAI/AIModalHeader";
import AIModalFooter from "modules/tractionAI/AIModalFooter";
import { isLocalhost } from "../../serviceWorker";
import { getCookie } from "../../common/helper";

let socket;

const AIModalWrapper = ({ selectedCouncil }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const {
    tractionAiReducer: { activeSubScreen }
  } = useSelector((state) => state);

  const parseData = (data) => {
    if (!data?.message || data.type === "ping") {
      return;
    }

    const extractedData = data.message;
    console.log('extractedData', extractedData);

    switch (extractedData.screen) {
      case 'recommend_projects':
      case 'recommend_ideas': {
        dispatch(AISetRecommendData({
          fetching: extractedData.fetching,
          data: extractedData.data,
          fetched: !extractedData.fetching,
          activeSubScreen,
        }));
      }
        break;

      case 'recommend': {
        dispatch(AISetScoutData({
          fetching: extractedData.fetching,
          data: extractedData.data,
          fetched: !extractedData.fetching
        }));
      }
      break;

      case 'trend_analysis': {
        dispatch(AISetTrendData({
          fetching: extractedData.fetching,
          data: extractedData.data,
          fetched: !extractedData.fetching
        }));
      }
      break;

      case 'swot': {
        dispatch(AISetSWOTData({
          fetching: extractedData.fetching,
          data: extractedData.data,
          fetched: !extractedData.fetching
        }));
      }
      break;

      case 'company_snapshot': {
        dispatch(AISetCompanySnapshotData({
          fetching: extractedData.fetching,
          data: extractedData.data,
          fetched: !extractedData.fetching
        }));
      }
      break;

      default:
        return;
    }
  };

  const makeAIWSConnection = () => {
    if (isLocalhost) {
      const uid = getCookie("uid");
      const accessToken = getCookie("access_token");
      const client = getCookie("client");

      socket = new WebSocket(
        `${process.env.REACT_APP_WSS}/cable?access_token=${accessToken}&client=${client}&uid=${uid}`,
      );
    } else {
      socket = new WebSocket(
        `${process.env.REACT_APP_WSS}/cable`,
      );
    }

    socket.onopen = () => {
      const msg = {
        command: "subscribe",
        identifier: JSON.stringify({
          council_id: selectedCouncil.id,
          channel: "AiProxyChannel",
        }),
      };
      socket.send(JSON.stringify(msg));
      dispatch(WSAISetConnectionStatus('connected'));
    };

    try {
      socket.onmessage = (event) => {
        const parsedData = JSON.parse(event.data);
        parseData(parsedData);
      };
      socket.onclose = (e) => {
        console.log(
          "AI Socket is closed. Reconnect will be attempted in 5 second.",
          e.reason
        );
        dispatch(WSAISetConnectionStatus('disconnected'));
        const timeout = setTimeout(() => {
          makeAIWSConnection();
          clearTimeout(timeout);
        }, 5000);
      };
      socket.onerror = (error) => {
        dispatch(WSAISetConnectionStatus('disconnected'));
        console.log("Error: " + JSON.stringify(error));
      };
    } catch (error) {
      dispatch(WSAISetConnectionStatus('disconnected'));
      console.log("Error catch: " + error);
    }
  }

  useEffect(() => {
    makeAIWSConnection();

    return () => {
      socket.close();
      console.log("unmounted");
    };
  }, []);

  const handleShowMore = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    dispatch(AISelectScreen({
      activeScreen: null,
      activeSubScreen: null,
      applyNoAnimationLogic: true,
    }));

    // make sure to get rid of socket when modal is closing
    // socket.close();
  }

  return (
    <>
      <div
        className="mr-3 small cursor-pointer"
        style={tractionAIMainStyles.title}
        onClick={handleShowMore}
      >
        Traction AI
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        disableEscapeKeyDown
        scroll="paper"
      >
        <AIModalHeader handleClose={handleClose} selectedCouncil={selectedCouncil} />
        <TractionAI selectedCouncil={selectedCouncil} />
        <AIModalFooter />
      </Dialog>
    </>
  )
}

export default React.memo(AIModalWrapper);

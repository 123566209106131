import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  httpGet,
  httpPut,
  httpPost,
  apiStatus,
  errorHandler,
} from "../../../common/httpCall";

import {
  PEOPLE_GET_MEMBERS_ATTEMPT,
  peopleMembersGetSuccess,
  PEOPLE_ADD_MEMBERS_ATTEMPT,
  peopleMembersAddSuccess,
  peopleMembersEditSuccess,
  PEOPLE_EDIT_MEMBERS_ATTEMPT,
  PEOPLE_ADD_MEMBERS_FAIL,
} from "./internalPeople.action";

export const epicGetMember = (action$, state$) =>
  action$.pipe(
    ofType(PEOPLE_GET_MEMBERS_ATTEMPT),
    switchMap(({ payload: { memberId, enqueueSnackbar } }) =>
      httpGet({
        call: `users/${memberId}`, // NEW_API
        // call: `members/${memberId}/single`,
      }).pipe(
        map((result) => peopleMembersGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicEditMember = (action$, state$) =>
  action$.pipe(
    ofType(PEOPLE_EDIT_MEMBERS_ATTEMPT),
    switchMap(
      ({
        payload: {
          memberId,
          first_name,
          last_name,
          title,
          council_role,
          email,
          team_ids,
          enqueueSnackbar,
          company_id,
          plant_ids,
          area_ids,
          theme_ids,
          cb,
        },
      }) =>
        httpPut({
          apiVersion: "v2",
          call: `council_users/${memberId}`,
          data: {
            user: {
              first_name,
              last_name,
              council_role,
              email,
              team_ids,
              title,
              company_id,
              plant_ids,
              area_ids,
              theme_ids,
            },
          },
        }).pipe(
          map((result) => {
            if (result.status === apiStatus.SUCCESS) {
              enqueueSnackbar("Successfully modified member.", {
                variant: "success",
              });
              cb();
            }
            return peopleMembersEditSuccess(result);
          }),
          catchError((err) => errorHandler(errorHandler(err, enqueueSnackbar)))
        )
    )
  );

export const epicAddMember = (action$, state$) =>
  action$.pipe(
    ofType(PEOPLE_ADD_MEMBERS_ATTEMPT),
    switchMap(
      ({
        payload: {
          accountId,
          councilId,
          email,
          first,
          last,
          permission,
          teamId,
          title,
          enqueueSnackbar,
          company_id,
          department,
        },
      }) =>
        httpPost({
          call: `councils/${councilId}/users`, // OLD_API
          data: {
            council_id: councilId,
            email,
            first_name: first,
            last_name: last,
            role: permission,
            primary_team: (teamId && teamId[0]?.id) || "",
            department,
            title,
            company_id,
          },
        }).pipe(
          map((result) => {
            if (result.status === 200 || result.status === 201) {
              enqueueSnackbar("You have succesfully added a member", {
                variant: "success",
              });
            }
            return peopleMembersAddSuccess({
              accountId,
              data: result.response,
              status: result.status,
            });
          }),
          catchError((e) =>
            errorHandler(e, enqueueSnackbar, [], () => ({
              type: PEOPLE_ADD_MEMBERS_FAIL,
            }))
          )
        )
    )
  );

const internalPeopleEpic = combineEpics(
  epicGetMember,
  epicAddMember,
  epicEditMember
);

export default internalPeopleEpic;

import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Icon, smSize } from "common/icon";
import { checkViewerRole } from "common/checkers/viewerChecker";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSnackbar } from "notistack";
import { Link, useHistory } from "react-router-dom";

import { getCustomFieldAgreementType } from "modules/admin/adminCustomFieldsGroups/adminCustomField.action";
import {
  CheckBox,
  CheckType,
  MultiSelect,
  BtnType,
  Button,
} from "modules/primitives/index";
import { ModalFooter } from "../../../../../application/modal";
import { useModelPopup } from "../../../../../common/hooks";
import LinkedinIcon from "../../../../../assets/linkeding.png";
import {
  addCustomFields,
  companyAgreementShouldRedirect,
  companyGet,
  companyPutDetailsFields,
  editDetails,
} from "../../company.action";
import styles from "./details.module.scss";
import {
  businessUnitOptions,
  partnerOptions,
  parseEstRevenue,
} from "./details.constants";
import CrunchSourceComponent from "../crunchSource";
import { handleUrlClick } from "../../../../../common/helper";

const DetailsComponent = ({ company, selectedCouncil, isClaimProfileUse }) => {
  const {
    authReducer: { session },
    adminCustomFieldsReducer: { customFieldAgreementTypes },
  } = useSelector((state) => state);

  const history = useHistory();
  const dispatch = useDispatch();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [partnerType, setPartnerType] = useState("");
  const [selectedInvestment, setSelectedInvestment] = useState(false);
  const [selectedPartnership, setSelectedPartnership] = useState(false);

  const [showBusinessUnitDropDown, setShowbusinessUnitDroptdown] =
    useState(false);
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    dispatch(getCustomFieldAgreementType({ enqueueSnackbar }));
  }, [selectedCouncil]);

  const handleDropdownSelect = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (selectedCouncil.name === "Kyndryl") {
      setPartnerType(e.target.value);
    }

    dispatch(
      editDetails({
        companyId: company.id,
        councilId: selectedCouncil.id,
        data: {
          ...company,
          partner_type:
            selectedCouncil.name === "Kyndryl" ? e.target.value : undefined,
        },
        enqueueSnackbar,
        council: selectedCouncil?.name,
      })
    );
  };

  const updatePartnerStage = (val) => {
    dispatch(
      editDetails({
        companyId: company.id,
        councilId: selectedCouncil.id,
        data: {
          partnership: val,
        },
        enqueueSnackbar,
        council: selectedCouncil?.name,
        fromFirmenich: selectedCouncil?.name === "Firmenich",
      })
    );
  };

  const updateInvestStage = (val) => {
    dispatch(
      editDetails({
        companyId: company.id,
        councilId: selectedCouncil.id,
        data: {
          investment: val,
        },
        enqueueSnackbar,
        council: selectedCouncil?.name,
        fromFirmenich: selectedCouncil?.name === "Firmenich",
      })
    );
  };

  useEffect(() => {
    if (company.investment) {
      setSelectedInvestment(company.investment);
    }
    if (company.partnership) {
      setSelectedPartnership(company.partnership);
    }
  }, [company]);
  const checkboxClick = (e, el, docPresent) => {
    if (!blockFunctionality || docPresent) {
      dispatch(
        companyAgreementShouldRedirect({
          ...el,
          type: el.name,
          id: el.id,
          docPresent,
          mode: checkExistingAgreement(el.name) ? "edit" : "add",
        })
      );
      const timeout = setTimeout(() => {
        history.push(`/companies/${company.slug || company.id}/details`);
        clearTimeout(timeout);
      }, 100);
    }
  };

  const getPartnerTypeLabel = (label) => {
    if (label === "alliance") {
      return classNames(styles.parentTypeLabelAlliance, styles.parentTypeLabel);
    }
    if (label === "innovation") {
      return classNames(
        styles.parentTypeLabelInnovation,
        styles.parentTypeLabel
      );
    }
    if (label === "vendor") {
      return classNames(styles.parentTypeLabelVendor, styles.parentTypeLabel);
    }
    if (label === "none") {
      return classNames(styles.parentTypeLabelNone, styles.parentTypeLabel);
    }
    return {};
  };

  const handleOptionSelect = (values) => {
    dispatch(
      editDetails({
        companyId: company.id,
        councilId: selectedCouncil.id,
        data: {
          ...company,
          business_unit_ids: values.map((el) => el.id),
        },
        enqueueSnackbar,
      })
    );
  };
  const checkExistingAgreement = (type) =>
    company.agreement_types?.includes(type);
  const checkExistingIDBAgreement = (type) =>
    company.idb_connection?.option_selected?.includes(type);
  const idbCheckboxClick = (e, type) => {
    if (!blockFunctionality) {
      if (company?.idb_connection && !company?.idb_connection.is_template) {
        dispatch(
          companyPutDetailsFields({
            company_custom_field_id: company.idb_connection.id,
            enqueueSnackbar,
            company_id: company.id,
            data: {
              company_id: company.id,
              council_id: selectedCouncil.id,
              user_id: session.id,
              field_value: null,
              field_name: company.idb_connection.field_name,
              field_values: [],
              multiselect: true,
              option_selected: company.idb_connection.option_selected.includes(
                type
              )
                ? [
                    ...company.idb_connection.option_selected.filter(
                      (opt) => opt !== type
                    ),
                  ]
                : [...company.idb_connection.option_selected, type],
              custom_field_group_id:
                company.idb_connection?.custom_field_group_id,
              option_values: company.idb_connection?.option_values,
            },
            cb: () => {
              dispatch(
                companyGet({
                  id: company.id,
                  council_id: selectedCouncil.id,
                  enqueueSnackbar,
                })
              );
            },
          })
        );
      } else {
        const payload = {
          company_id: company.id,
          council_id: selectedCouncil.id,
          enqueueSnackbar,
          data: {
            user_id: session.id,
            field_value: null,
            field_name: selectedCouncil?.idb_connection?.field_name,
            field_type: "checklist",
            option_selected: [type],
            option_values: selectedCouncil?.idb_connection?.option_values,
            custom_field_group_id:
              selectedCouncil?.idb_connection?.custom_field_group_id,
            custom_field_template_id: selectedCouncil.idb_connection?.id,
          },
          cb: () => {
            dispatch(
              companyGet({
                id: company.id,
                council_id: selectedCouncil.id,
                enqueueSnackbar,
              })
            );
          },
        };

        dispatch(addCustomFields(payload));
      }
    }
  };
  const changeInvestmentStage = (evt) => {
    const data = {
      theme_investment_stage_id: evt.target.value || null,
    };

    dispatch(
      editDetails({
        companyId: company.id,
        councilId: selectedCouncil.id,
        data,
        enqueueSnackbar,
        council: selectedCouncil?.name,
        fromFirmenich: selectedCouncil?.name === "Firmenich",
      })
    );

    // history.push({
    //   pathname: `/themes/pipeline`,
    // });
  };

  const handleTrackCheckbox = () => {
    popup.show({
      title: " ",
      show: true,
      height: "300",
      width: "540",
      component: (
        <div className={styles.optionWrp}>
          {selectedCouncil.traction_tag === "firmenich" && (
            <div className={styles.trackCheckboxes}>
              <CheckBox
                isChecked={selectedPartnership}
                onChange={() => updatePartnerStage(!selectedPartnership)}
              />
              <div
                className={classNames(styles.optChildren)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Partnership
              </div>
            </div>
          )}
          {session?.theme_investment_access &&
            selectedCouncil.traction_tag === "firmenich" && (
              <div className={styles.trackCheckboxes}>
                <CheckBox
                  isChecked={selectedInvestment}
                  onChange={() => updateInvestStage(!selectedInvestment)}
                />
                <div
                  className={classNames(styles.optChildren)}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Investment
                </div>
              </div>
            )}
          <ModalFooter>
            <Button
              btn={BtnType.FRAME_LESS}
              onClick={() => {
                popup.hide();
              }}
            >
              Cancel
            </Button>
            <Button
              btn={BtnType.REGULAR}
              onClick={() => {
                popup.hide();
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </div>
      ),
    });
  };
  const idbSource = company?.idb_connection
    ? company?.idb_connection?.option_values
    : selectedCouncil?.idb_connection?.option_values;

  const handleCompanyUrlClick = () => {
    handleUrlClick(company.web_url);
  };

  return (
    <>
      {company && (
        <div className={styles.detailsPanel}>
          {company.description || company.short_description ? (
            <div className={classNames("caption", styles.details)}>
              {company.description
                ? company.description
                : company.short_description}
            </div>
          ) : null}
          <div className={styles.webWrapper}>
            <div className="d-flex flex-column">
              <span className={styles.webUrl} onClick={handleCompanyUrlClick}>
                {company.web_url &&
                  company.web_url.replace(/\b(http:\/\/|https:\/\/)\b/g, "")}
              </span>
              <div className={styles.email}>
                <a
                  className={styles.webUrl}
                  onClick={() => window.open(`mailto:${company.contact_email}`)}
                >
                  {company.contact_email}
                </a>{" "}
              </div>
            </div>
            {company.linkedin && (
              <div className="d-flex ml-5">
                <a href={company.linkedin} target="_blank" rel="noreferrer">
                  <img src={LinkedinIcon} width="20" alt="Linkedin" />
                </a>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-6">
              <div className={styles.dataRow}>
                <div className={styles.label}>Founded</div>
                <div className={styles.value}>
                  {company.founded &&
                    format(new Date(company.founded), "MM/dd/yyyy")}
                </div>
              </div>
              <div className={styles.dataRow}>
                <div className={styles.label}>Headquarters</div>
                <div className={styles.value}>
                  {`${company.location ? `${company.location}` : ""}`}
                  {!company.location ? "-" : ""}
                </div>
              </div>
              <div className={styles.dataRow}>
                <div className={styles.label}>Employees</div>
                <div className={styles.value}>
                  {company.employee_range || company.employees}
                </div>
              </div>
              <div className={styles.dataRow}>
                <div className={styles.label}>Estimated Revenue</div>
                <div className={styles.value}>
                  {parseEstRevenue(company.revenue_range)}
                </div>
              </div>
            </div>
            <div className="col-6">
              {!isClaimProfileUse && (
                <div className={styles.dataRow}>
                  <div className={styles.checkWrapper}>
                    {customFieldAgreementTypes?.map((el) => (
                      <div className={styles.checkBoxWrapper} key={el.name}>
                        <CheckBox
                          checkType={CheckType.BLUE}
                          isChecked={() => checkExistingAgreement(el.name)}
                          disabled
                          onClick={(e) =>
                            checkboxClick(e, el, company.agreement_tag)
                          }
                        />
                        <div className={styles.checkLabel}>{el.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {(selectedCouncil.name === "IDB Lab" ||
                selectedCouncil.name === "IDB") && (
                <div className={styles.dataRow}>
                  <div className={styles.label}>IDB Connection</div>
                  <div className={styles.checkWrapper}>
                    {idbSource?.map((el) => (
                      <div className={styles.checkBoxWrapper}>
                        <CheckBox
                          checkType={CheckType.BLUE}
                          isChecked={() => checkExistingIDBAgreement(el)}
                          disabled
                          onClick={(e) => idbCheckboxClick(e, el)}
                        />
                        <div className={styles.checkLabel}>{el}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div
                className={classNames(
                  styles.dataRow,
                  styles.competitorsWrapper
                )}
              >
                <div className={styles.label}>Competitors</div>
                <div className={styles.customerWrapper}>
                  {company?.competitors?.length ||
                  company.external_competitors?.length ? (
                    <>
                      {company.external_competitors?.map((c) => (
                        <a
                          key={c.id}
                          className={`${styles.customer} d-flex align-items-center flex-row`}
                          href={c.url}
                          rel="noreferrer"
                          target="_blank"
                          title={`External link: ${c.url}`}
                        >
                          <div className="d-flex">
                            <span>{c.name}</span>
                            {c.external ? (
                              <span className="d-flex ml-1">
                                <svg
                                  width="15px"
                                  height="15px"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="External_Link">
                                    <path
                                      id="Vector"
                                      d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11"
                                      stroke="#3994c1"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                </svg>
                              </span>
                            ) : null}
                          </div>
                          ,
                        </a>
                      ))}
                      {company.competitors?.map((item, inx) =>
                        item.web_url ? (
                          <a
                            key={`${item.name}-${inx}`}
                            className={styles.customer}
                            href={item.web_url}
                            rel="noreferrer"
                          >
                            {item.name}
                            {company.competitors.length - 1 === inx ? "" : ","}
                          </a>
                        ) : (
                          <span className={styles.customer}>
                            {item.name}
                            {company.competitors.length - 1 === inx ? "" : ","}
                          </span>
                        )
                      )}
                    </>
                  ) : (
                    <div className={styles.value}>-</div>
                  )}
                </div>
              </div>
              <div className={styles.dataRow}>
                <div className={styles.label}>Customers</div>
                <div className={styles.customerWrapper}>
                  {company.customers && company.customers.length ? (
                    company.customers.map((customer, i) => (
                      <>
                        {customer.url ? (
                          <a
                            key={`${customer.name}-${i}`}
                            className={styles.customer}
                            href={`http://${customer.url}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {customer.name}
                            {company.customers.length - 1 === i ? "" : ","}
                          </a>
                        ) : (
                          <span
                            key={`${customer.name}-${i}`}
                            className={styles.customerWithoutUrl}
                          >
                            {customer.name}
                            {company.customers.length - 1 === i ? "" : ","}
                          </span>
                        )}
                      </>
                    ))
                  ) : (
                    <div className={styles.value}> -</div>
                  )}
                </div>
              </div>
              {selectedCouncil.name === "Firmenich" ? (
                <div className={styles.trackRow}>
                  <div className={styles.dataRow}>
                    <div className={styles.label}>Track</div>
                    {company.partnership || company.investment ? (
                      <div className={styles.tagBlock}>
                        {company.partnership && (
                          <div className={styles.tagPartItem}>
                            {`Partnership${
                              company.investment && company.partnership
                                ? ","
                                : ""
                            }`}
                          </div>
                        )}

                        {company.investment &&
                          session.theme_investment_access && (
                            <div className={styles.tagInvestItem}>
                              Investment
                            </div>
                          )}
                      </div>
                    ) : (
                      <div className={styles.value}> -</div>
                    )}
                    {company.investment && session.theme_investment_access && (
                      <div>
                        <div className={styles.instementStageName}>
                          Investment Stage:
                        </div>
                        {company.theme_investment_stage?.id && (
                          <Select
                            value={company.theme_investment_stage?.id || "None"}
                            onChange={changeInvestmentStage}
                            disabled={blockFunctionality}
                            className={styles.parentTypeLabel}
                          >
                            {selectedCouncil?.theme_investment_stages.map(
                              (item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        )}
                      </div>
                    )}
                  </div>
                  <Icon
                    {...smSize}
                    icon="icn-edit-button"
                    className={classNames(styles.icon)}
                    onClick={() =>
                      handleTrackCheckbox(!showBusinessUnitDropDown)
                    }
                  />
                </div>
              ) : (
                <div />
              )}
              {selectedCouncil.name === "Kyndryl" && (
                <div className={styles.dataRow}>
                  <div className={styles.label}>Partner Type</div>
                  <div className={styles.customerWrapper}>
                    <Select
                      value={partnerType || company.partner_type || "None"}
                      onChange={handleDropdownSelect}
                      disabled={blockFunctionality}
                      className={getPartnerTypeLabel(company.partner_type)}
                    >
                      {partnerOptions.map((item) => (
                        <MenuItem key={item.text} value={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
              {selectedCouncil.name === "Freddie Mac" && (
                <div className={styles.dataRow}>
                  <div className={styles.label}>
                    <span
                      onClick={() =>
                        setShowbusinessUnitDroptdown(!showBusinessUnitDropDown)
                      }
                    >
                      Business Unit
                    </span>
                    <Icon
                      {...smSize}
                      icon="icn-table-down-chevron"
                      className={classNames(
                        styles.icon,
                        showBusinessUnitDropDown ? styles.collapse : ""
                      )}
                      onClick={() =>
                        setShowbusinessUnitDroptdown(!showBusinessUnitDropDown)
                      }
                    />
                  </div>
                  <div className={styles.customerWrapper}>
                    {!showBusinessUnitDropDown ? (
                      <>
                        {company?.business_units.length
                          ? company?.business_units.map((item) => (
                              <Button
                                key={item.id}
                                btn={BtnType.TAG_BLUE_LIGHT}
                                rounded
                                className={styles.tag}
                              >
                                {item.name}
                              </Button>
                            ))
                          : "-"}
                      </>
                    ) : (
                      <MultiSelect
                        ref={ref}
                        onChange={handleOptionSelect}
                        placeholder="Business Unit"
                        labelField="name"
                        valueField="id"
                        searchBy="name"
                        options={businessUnitOptions}
                        multi
                        className={styles.unitSelect}
                        values={company?.business_units}
                        keepOpen
                        dropdownGap={2}
                      />
                    )}
                  </div>
                </div>
              )}
              {company?.ideas_related_companies?.length &&
              selectedCouncil?.settings?.ideas_connected !== false ? (
                <div className={styles.dataRow}>
                  <div className={styles.label}>Related Ideas</div>
                  <div className="d-flex flex-row flex-wrap">
                    {company?.ideas_related_companies.map((i) => (
                      <Link to={`/ideas/${i.idea.idea_id}`}>
                        {i.idea.name},&nbsp;&nbsp;
                      </Link>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <CrunchSourceComponent company={company} />
        </div>
      )}
    </>
  );
};

export default React.memo(DetailsComponent);

import * as Yup from "yup";

export const initPasswordSettingsModel = {
  password: "",
  password_confirmation: "",
};

export const passwordSettingsValidation = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "At least 8 characters"),
  password_confirmation: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import styles from "./productTopActions.module.scss";
import { BtnType, Button, MoreOptions } from "../../../../primitives";
import { useModelPopup } from "../../../../../common/hooks";
import { appModelSettingSet } from "application/app.action";
import { useWindowSize } from "common/hooks/useWindowSize";
import { productMobileMoreOptions } from "./constant";
import {
  companyMoreOptions,
  moreOptionTypes,
} from "modules/companies/company/company.constant";
import { getCookie } from "common/helper";

import AddProductToIdeaComponent from "modules/companies/product/components/addProductToIdea/addProductToIdea.component";
import AddProductToProjectComponent from "modules/companies/product/components/addProductToProject/addProductToProject.component";
import AddProductToTheme from "modules/companies/product/components/addProductToTheme";
import AddProductToList from "modules/companies/product/components/addProductToList";
import TractionDownload from "../../../../../common/components/tractionDownload";
import { Icon, smSize } from "../../../../../common/icon";
import { productSetDownloadParams } from "modules/companies/product/store/product.actions";

const ProductTopActions = ({ isAdmin, blockFunctionality, product }) => {
  const {
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
    productReducer: { productIsDownloading, loading },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const { isMobile } = useWindowSize();
  const [companyOptions, setCompanyOptions] = useState(companyMoreOptions);
  const [councilRole, setCouncilRole] = useState("");

  useEffect(() => {
    const council_role = getCookie("council_role_");

    if (council_role) {
      setCouncilRole(council_role);
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      let options = productMobileMoreOptions;

      if (blockFunctionality) {
        options = companyMoreOptions.filter(
          (option) =>
            option.text !== "Add to project" && option.text !== "Discuss"
        );
      }

      if (product?.current_user_follow_relationship_id?.length) {
        options = options.map((o) => {
          if (o.val === "follow") {
            return {
              ...o,
              text: "Following",
            };
          }

          return o;
        });
      }

      if (
        councilRole === "ttp_administrator" ||
        councilRole === "council_administrator"
      ) {
        options = [{ val: "edit", text: "Edit Company" }, ...options];
      }

      setCompanyOptions(options);

      return;
    }

    if (blockFunctionality) {
      const filteredOptions = companyMoreOptions.filter(
        (option) =>
          option.text !== "Add to project" && option.text !== "Discuss"
      );

      setCompanyOptions(filteredOptions);
    }
  }, [blockFunctionality, isMobile, councilRole]);

  const handleFollowClick = (e, product) => {
    if (e) {
      e.stopPropagation();
    }

    if (product?.current_user_follow_relationship_id?.length) {
      makeUnfollow();
    } else {
      makeFollow(product);
    }
  };

  const makeUnfollow = () => {
    // dispatch(
    //   companyUnFollow({
    //     data: {
    //       follow_relationship_id: selectedCompany?.current_user_follow_relationship_id,
    //     },
    //     enqueueSnackbar,
    //   })
    // );
  };

  const makeFollow = (product) => {
    // dispatch(
    //   suggestionFollow({
    //     data: {
    //       ...company,
    //       company_id: company.id,
    //       name: company.name,
    //     },
    //     enqueueSnackbar,
    //   })
    // );
  };

  const handleNewProjectClick = () => {
    // to do add that later whe un hide below code
  };

  const handleRequestResearch = () => {
    popup.show({
      title: "Request Information",
      component: <div>request</div>,
    });
  };

  const handleAddNoteClick = () => {
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        title: "Capture New Note",
        show: true,
        height: String(window.innerHeight),
        width: String(window.innerWidth / 1.5),
        component: <div>new note</div>,
      })
    );
  };

  const handleMoreOptionClick = (val) => {
    let obj = {};

    switch (val) {
      case "requestResearch": {
        handleRequestResearch();
        return;
      }

      case "note": {
        handleAddNoteClick();
        return;
      }

      case "follow": {
        handleFollowClick(null, product);
        return;
      }

      case "newProject": {
        handleNewProjectClick();
        return;
      }

      case moreOptionTypes.discuss:
        obj = {
          title: "Start a New Discussion",
          show: true,
          height: "300",
          width: "540",
          component: <div>test</div>,
        };
        break;

      case moreOptionTypes.share:
        obj = {
          title: "Share With",
          show: true,
          height: "300",
          width: "540",
          component: <div>test</div>,
        };
        break;
      case moreOptionTypes.addToIdea:
        obj = {
          title: "Add To Idea",
          show: true,
          height: "1000",
          width: "540",
          component: (
            <AddProductToIdeaComponent
              product={product}
              enqueueSnackbar={enqueueSnackbar}
            />
          ),
        };
        break;
      case moreOptionTypes.addToList:
        obj = {
          title: "Add To List",
          show: true,
          height: "1000",
          width: "540",
          component: (
            <AddProductToList
              product={product}
              enqueueSnackbar={enqueueSnackbar}
            />
          ),
        };
        break;
      case moreOptionTypes.addToProject:
        obj = {
          title: "Add Product To Project",
          show: true,
          height: "1000",
          width: "540",
          component: (
            <AddProductToProjectComponent
              product={product}
              enqueueSnackbar={enqueueSnackbar}
            />
          ),
        };
        break;
      case moreOptionTypes.addToTheme:
        obj = {
          title: `Add To ${
            selectedCouncil?.settings?.themes_display_name_singular || "Theme"
          }`,
          show: true,
          height: "1000",
          width: "540",
          component: <AddProductToTheme product={product} />,
        };
        break;
      default:
        break;
    }
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...obj,
      })
    );
  };

  const handleDownload = () => {
    dispatch(productSetDownloadParams(["Overview"]));
  };

  const handleDownloadState = (status) => {
    if (!status) {
      dispatch(productSetDownloadParams([]));
    }
  };

  return (
    <div className={styles.productActionsWrapper}>
      {isMobile ? (
        <MoreOptions
          options={companyOptions.filter((option) => {
            return option.val !== 5 && option.val !== 2 && option.val !== 1;
          })}
          className={styles.moreOptWrp}
          onClick={handleMoreOptionClick}
          customSvgIcon
        />
      ) : (
        <div className={styles.firstRow}>
          {/*<Button*/}
          {/*  className={styles.button}*/}
          {/*  btn={*/}
          {/*    product?.current_user_follow_relationship_id?.length*/}
          {/*      ? BtnType.HIGLIGHT*/}
          {/*      : BtnType.OUTLINE*/}
          {/*  }*/}
          {/*  onClick={(e) => handleFollowClick(e, product)}*/}
          {/*  icon={*/}
          {/*    product?.current_user_follow_relationship_id?.length*/}
          {/*      ? "icn-checkbox-button"*/}
          {/*      : "icn-add"*/}
          {/*  }*/}
          {/*>*/}
          {/*  {product?.current_user_follow_relationship_id?.length ? "Following" : "Follow"}*/}
          {/*</Button>*/}
          {/*{selectedCouncil?.name === "Kyndryl" ? (*/}
          {/*  isAdmin ? (*/}
          {/*    <Button*/}
          {/*      btn={BtnType.REGULAR}*/}
          {/*      className={styles.button}*/}
          {/*      onClick={handleNewProjectClick}*/}
          {/*    >*/}
          {/*      New project*/}
          {/*    </Button>*/}
          {/*  ) : null*/}
          {/*) : !blockFunctionality ? (*/}
          {/*  <Button btn={BtnType.REGULAR} className={styles.button} onClick={handleNewProjectClick}>*/}
          {/*    New project*/}
          {/*  </Button>*/}
          {/*) : (*/}
          {/*  <Tooltip*/}
          {/*    title="You have View-Only access. To create a Project, please ask your Admin to upgrade your account to Standard access."*/}
          {/*    placement="top"*/}
          {/*  >*/}
          {/*    <div>*/}
          {/*      <Button btn={BtnType.DISABLED} className={styles.button}>*/}
          {/*        New project*/}
          {/*      </Button>*/}
          {/*    </div>*/}
          {/*  </Tooltip>*/}
          {/*)}*/}
          {/*{!isKyndrylViewOnly(selectedCouncil?.name, session?.council_role) && (*/}
          {/*  <Button btn={BtnType.REGULAR} className={styles.button} onClick={handleRequestResearch}>*/}
          {/*    Request Info*/}
          {/*  </Button>*/}
          {/*)}*/}
          {/*{!isKyndrylViewOnly(selectedCouncil?.name, session?.council_role) &&*/}
          {/*  (!blockFunctionality ? (*/}
          {/*    <Button btn={BtnType.REGULAR} className={styles.button} onClick={handleAddNoteClick}>*/}
          {/*      Add Note*/}
          {/*    </Button>*/}
          {/*  ) : (*/}
          {/*    <Tooltip title="You have View-Only access. To Add a Note, please ask your Admin to upgrade your account to Standard access.">*/}
          {/*      <div>*/}
          {/*        <Button btn={BtnType.DISABLED} className={styles.button}>*/}
          {/*          Add Note*/}
          {/*        </Button>*/}
          {/*      </div>*/}
          {/*    </Tooltip>*/}
          {/*  ))}*/}
          <div className="d-flex">
            <div className="invisible">
              <TractionDownload
                handleDownloadState={handleDownloadState}
                timeoutTime={2000}
                hidePng
                shouldDownloadPdf={productIsDownloading}
              />
            </div>
            <Button
              btn={BtnType.FRAME_LESS}
              onClick={handleDownload}
              className={productIsDownloading || loading ? "invisible" : ""}
            >
              <div className="d-flex align-items-center">
                <Icon
                  {...smSize}
                  icon="icn-export-button"
                  className={styles.download}
                />
                Download
              </div>
            </Button>
          </div>
          <div className={styles.colMore}>
            <MoreOptions
              options={companyOptions.filter((option) => {
                return option.val !== 5 && option.val !== 2 && option.val !== 1;
              })}
              className={styles.moreOptWrp}
              onClick={handleMoreOptionClick}
              customSvgIcon
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ProductTopActions);

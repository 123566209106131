import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import { useSliderField } from "common/hooks";
import {
  MoreOptions, Loading,
} from "modules/primitives";
import { ACTIVITY_OPTIONS_WITHOUT_DELETE } from "common/constants.js";
import useS3FileUpload from "common/hooks/s3FileUplodar.hook";
import DragAndDrop from "common/components/dragAndDrop";
import { allowedExtensions } from "modules/events/viewEvent/viewEvent.constant";
import styles from "./styles.module.scss";
import LinearProgressWithLabel from "../../../../../../common/components/linearProgressWithLabel";

const SlidesComponent = ({
  item,
  handleUpdateForm,
  handleEditClick,
  disabled,
  companyId,
}) => {
  const {
    authReducer: { session },
    companiesReducer: {
      companyReducer: { customFieldLoadingId },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const [loadingId, setLoadingId] = useState("");
  const [fieldId, setFieldId] = useState("");
  const sliderField = useSliderField(item);
  const [uploadValues, setUploadValues] = useState([]);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const { fileUpload } = useS3FileUpload();
  const [newDocuments, setNewDocuments] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [docLoading, setDocLoading] = useState(false);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    if (forbiddenFiles.length > 0) {
      const timeout = setTimeout(() => {
        setForbiddenFiles([]);
        clearTimeout(timeout);
      }, 5000);
    }
  });

  useEffect(() => {
    if (item) {
      setFieldId(item?.company_custom_field_id);
      setSlides(item?.documents ? item?.documents?.map((doc) => doc.uri) : []);
    }
  }, [item]);

  useEffect(() => {
    if (customFieldLoadingId) {
      setLoadingId(customFieldLoadingId);
    }
  }, [customFieldLoadingId]);

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }
  };

  const getFilename = (url) => {
    if (url) {
      return url.split("/").pop();
    }
    return "";
  };

  const saveModifications = () => {
    const docs = [
      ...(item?.documents || []),
      ...newDocuments.map((slide) => ({
        added_by_id: session.id,
        name: getFilename(slide),
        uri: slide,
        council_id: selectedCouncil.id,
        document_resource_type: "CompanyCustomField",
        document_resource_id: item?.company_custom_field_id,
        rfi: true,
      })),
    ];
    const allValues = docs.map((d) => d.uri);
    const data = {
      ...item,
      documents_attributes: docs,
      option_values: allValues,
      field_values: allValues,
      unsavedFiles: docs.filter((d) => !d.created_at),
    };

    handleUpdateForm(data);
  };

  const getExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  useEffect(() => {
    if (newDocuments.length) saveModifications();
  }, [newDocuments]);

  const uploadHandler = async (e, action) => {
    if (forbiddenFiles?.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }

    setDocLoading(true);
    const fileList = [];
    let dismissedFiles = 0;

    setUploadValues((items) => {
      const currentFiles = [...items];
      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name,
      }));
      return [...currentFiles, ...newFiles];
    });

    files.forEach(async (file, index, array) => {
      const isLastIndex = Boolean(index === array?.length - 1);
      let fileCopy = file;
      // This regular expression pattern that matches any character that is not in the range of a-z, A-Z, or 0-9.
      const regex = /[^a-zA-Z0-9]/g;

      if (regex.test(file.name)) {
        fileCopy = new File([file], file.name.replace(/[^a-zA-Z0-9. ]/g, ""), {
          type: file.type,
        });
      }

      if (
        fileCopy.size <= 100000000
        && allowedExtensions.indexOf(getExtension(fileCopy.name)) !== -1
      ) {
        const pathname = `srm/${selectedCouncil.id}/companies/${companyId}/`;
        await fileUpload(
          fileCopy,
          pathname,
          setUploadValues,
          fileCopy.name,
        ).then((res) => {
          if (res && res.location) {
            if (slides.indexOf(res.location) === -1) {
              fileList.push(res.location);
              isLastIndex && setDocLoading(false);
              if (fileList.length === files.length - dismissedFiles) {
                setNewDocuments([...fileList]);
              }
            } else {
              enqueueSnackbar("This document was already added.", {
                variant: "error",
              });
              isLastIndex && setDocLoading(false);
              dismissedFiles++;
              setForbiddenFiles((prevFiles) => {
                const localFiles = [...prevFiles];
                localFiles.push({
                  name: fileCopy.name,
                  error: "Document already added.",
                });
                return localFiles;
              });
              setUploadValues((prevState) => {
                const currentProgress = [...prevState];
                const index = currentProgress
                  .map((item) => item.name)
                  .lastIndexOf(fileCopy.name);
                currentProgress[index] = {
                  progress: "NOT_ALLOWED",
                  name: fileCopy.name,
                };
                return currentProgress;
              });
            }
          }
        });
      } else {
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === fileCopy.name,
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: fileCopy.name,
          };
          return currentProgress;
        });
        isLastIndex && setDocLoading(false);
        if (fileCopy.size > 100000000) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: fileCopy.name,
              error: "Your file is too large. File size limit: 100MB",
            });
            return localFiles;
          });
          dismissedFiles++;
          enqueueSnackbar(
            "We could not upload your image because your file size is too big. Please make sure the file is less than 20 MB",
            {
              variant: "error",
            },
          );
        }
        if (allowedExtensions.indexOf(getExtension(fileCopy.name)) === -1) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: fileCopy.name,
              error:
                "Extenstion is not allowed, the accepted file formats are: pdf, ppt, pptx, pps.",
            });
            return localFiles;
          });
          isLastIndex && setDocLoading(false);
          dismissedFiles++;
          enqueueSnackbar(
            "Extenstion is not allowed, the accepted file formats are: pdf, ppt, pptx, pps.",
            {
              variant: "error",
            },
          );
        }
      }
    });

    e.target.value = "";
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{item?.field_name || "Slides"}</h5>
          {
            item?.field_placeholder?.length ? (
              <span className="text-muted small">{item.field_placeholder}</span>
            ) : null
          }
        </div>
        {!disabled && (
          <div className={styles.rightWrp}>
            <div className={styles.colMore}>
              <MoreOptions
                options={ACTIVITY_OPTIONS_WITHOUT_DELETE}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            </div>
          </div>
        )}
      </div>
      <div>
        <div>
          {uploadValues?.length
            ? uploadValues.map(
              (bar, index) => bar
                && bar?.progress !== "NOT_ALLOWED" && (
                  <div className={styles.loaderContainer}>
                    <div className={styles.loaderNamingWrp}>
                      {" "}
                      <div className={styles.fileName}>
                        {uploadValues[index]?.name}
                      </div>
                      <LinearProgressWithLabel
                        mode={
                          uploadValues[index]?.progress
                          === "FAILED" && "error"
                        }
                        value={
                          uploadValues[index]?.progress === "FAILED"
                            ? 100
                            : uploadValues[index]?.progress || 0
                        }
                      />
                    </div>
                  </div>
              ),
            )
            : null}
        </div>

        <DragAndDrop
          uploadHandler={uploadHandler}
          loading={docLoading}
          customText="drag and drop slides here"
          unicumKey={item?.custom_field_template_id}
          disabled={disabled}
        />

        {forbiddenFiles?.length ? (
          <div className={styles.forbiddenfileContainer}>
            Files that weren't allowed:{" "}
            {forbiddenFiles.map((file) => (
              <span className={styles.forbiddenfileName}>
                {`${file.name}: `}
                <span className={styles.errorText}> {file.error}</span>
              </span>
            ))}
          </div>
        ) : null}
      </div>
      {loadingId === fieldId ? <Loading /> : sliderField.displayField()}
    </div>
  );
};
export default SlidesComponent;

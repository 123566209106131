import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { METRICS_NAMES_ENUM } from "application/app.constants";

import NewProjectV3 from "modules/projects/newProjectv3";
import { useModelPopup } from "../../../../../common/hooks";
import { mdSize } from "../../../../../common/icon";

import { Loading, Pagination, MoreOptions } from "../../../../primitives/index";
import { themesProjectsGetAttempt } from "../../themesProjectsTab/projectsTab.action";
import AddRelatedProjectComponent from "../relatedProjectsBlock/addRelatedProjectModal";
import createProjOptions from "../relatedProjectsBlock/relatedProjects.constant";
import { httpGet } from "../../../../../common/httpCall";
import ProjectRow from "../../../themesCommon/themeProjects/projectRow.component";

import styles from "./styles.module.scss";

const MainProjectsBlock = (props) => {
  const { currentThemeData } = props;
  const {
    authReducer: { session },
    themesReducer: {
      themesProjectsReducer: { projects, loadingProj, projectListMeta },
      themesOverviewReducer: { companyList, loadingCompany },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const isFord = selectedCouncil?.name === "Ford";
  const popup = useModelPopup();
  const [loading, setLoading] = useState(false);
  const [shouldOpenAddNewProjectModal, setShouldOpenAddNewProjectModal] =
    useState(false);
  const [adminColumnSettings, setAdminColumnSettings] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [pageSetting, setPageSetting] = useState({
    pageSize: 10,
    current: 1,
    hideOnSinglePage: true,
    showTitle: false,
    total: projects?.length || 0,
  });
  const [projList, setProjList] = useState([]);
  const dispatch = useDispatch();

  const handleGetTableRows = async () => {
    setLoading(true);
    await httpGet({
      call: `theme_project_options?view_type=overview`,
    })
      .pipe()
      .subscribe((res) => {
        setLoading(false);
        setAdminColumnSettings(res.response);
        const columns = [];
        for (let index = 0; index < res.response.length; index++) {
          if (res.response[index].display) {
            columns.push(res.response[index]);
          }
          if (columns.length === 5) {
            break;
          }
        }
        const sortedColumns = columns.sort((a, b) => a.position - b.position);
        setTableColumns(sortedColumns);
        return res;
      });
  };
  useEffect(() => {
    try {
      if (currentThemeData?.name) {
        handleGetTableRows();
      }
    } catch (err) {
      console.log("error on get stages", err);
    }
  }, []);

  useEffect(() => {
    setPageSetting((prev) => ({
      ...prev,
      total: projectListMeta.total || 0,
    }));
  }, [projectListMeta]);

  const handlePagingChange = (page) => {
    setPageSetting((prev) => ({
      ...prev,
      current: page,
    }));
  };
  useEffect(() => {
    if (currentThemeData) {
      if (currentThemeData.id === undefined) {
        return;
      }

      dispatch(
        themesProjectsGetAttempt({
          themeId: currentThemeData.id,
          page: 1,
          overview: true,
          pageSize: pageSetting.pageSize,
        })
      );
    }
  }, [currentThemeData]);

  useEffect(() => {
    if (pageSetting?.current > 1) {
      dispatch(
        themesProjectsGetAttempt({
          themeId: currentThemeData.id,
          page: pageSetting?.current,
          overview: true,
          pageSize: pageSetting.pageSize,
        })
      );
    }
  }, [pageSetting?.current]);

  useEffect(() => {
    if (projects) {
      setProjList(projects);
    }
  }, [projects, selectedCouncil]);

  const handleAddProjectClick = () => {
    popup.show({
      title: "Link Theme to Related Project",
      component: (
        <AddRelatedProjectComponent
          itemList={projects || []}
          currentThemeId={currentThemeData.id}
        />
      ),
    });
  };

  const handleMoreOptionClick = (val) => {
    if (val === "relatedProject") {
      handleAddProjectClick();
    }
    if (val === "newProject") {
      setShouldOpenAddNewProjectModal(true);
    }
  };

  const isAdmin = useMemo(
    () =>
      session?.council_role === "ttp_administrator" ||
      session?.council_role === "council_administrator",
    [session]
  );
  const getColumnName = (column) => {
    if (column.column_type === "project_field") {
      return column.project_field_template.field_name;
    }
    if (column.column_type === "project_metric") {
      return METRICS_NAMES_ENUM[column.project_metric_attribute];
    }
    return column.column_type
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  if (loadingProj || loading) {
    return <Loading />;
  }

  return (
    <div className={styles.overviewContainer}>
      <span className={styles.overviewTitle}>
        {selectedCouncil?.settings?.themes_display_name_singular || "Theme"}{" "}
        Projects
      </span>
      {!loadingCompany && (
        <div className={styles.btnWrp}>
          <MoreOptions
            options={
              selectedCouncil?.name === "Firmenich" ||
              selectedCouncil?.name === "Ford"
                ? [createProjOptions[0]]
                : createProjOptions
            }
            className={styles.moreOptWrp}
            onClick={handleMoreOptionClick}
            {...mdSize}
            iconName="icn-add"
          />
          <NewProjectV3
            isAdmin={isAdmin}
            wizardType="theme"
            additionalData={{
              ...currentThemeData,
              theme_companies: companyList,
            }}
            btnName="New Project"
            shouldOpen={shouldOpenAddNewProjectModal}
            handleDismiss={() => setShouldOpenAddNewProjectModal(false)}
            hideBtn
          />
        </div>
      )}
      <div className={styles.overviewBlock}>
        <div className={styles.container}>
          <div className={styles.grid}>
            <div className={styles.gridHeader}>
              <div className={classNames(styles.col, styles.projNameCol)}>
                Project
              </div>
              {tableColumns.map((column, index) => {
                if (column.column_type === "project_name") {
                  return null;
                }
                return (
                  <div
                    className={styles.task}
                    key={`${column.column_type + column.position}_${
                      column?.project_metric?.metric_attribute ||
                      column?.project_field_template_id
                    }`}
                  >
                    {getColumnName(column) || "-"}
                  </div>
                );
              })}
              <div className={styles.deleteCol}> </div>
            </div>

            <div className={styles.gridBody}>
              {projList?.length
                ? projList.map((proj, i) => (
                    <ProjectRow
                      project={proj}
                      projectName={proj?.project_name?.column_value}
                      isFord={isFord}
                      selectedCouncilTag={selectedCouncil.traction_tag}
                      adminColumnSettings={adminColumnSettings}
                      key={proj.project_id}
                      tableColumns={tableColumns}
                    />
                  ))
                : null}
            </div>
          </div>
          <div className={styles.paginationCon}>
            <Pagination {...pageSetting} onChange={handlePagingChange} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainProjectsBlock;

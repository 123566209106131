import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { format } from "date-fns";
import ReactTable from "react-table";
import { useHistory } from "react-router-dom";
import { Loading, Button, BtnType } from "modules/primitives/index";
import { smSize } from "../../../../common/icon";
import Icon from "../../../../common/icon/icon.component";
import styles from "../bulkUpload/bulkUpload.module.scss";
import AttachFileComponent from "../../../../common/components/attachFile/index";
import { httpPost, errorHandler, httpGet } from "../../../../common/httpCall";

const DataUploadComponent = ({ onTabClick }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingToTraction, setLodingToTraction] = useState(false);
  const [loadingGetFiles, setLoadingGetFiles] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [expandText, setExpandText] = useState(false);

  const history = useHistory();

  const getBulkFiles = () => {
    setLoadingGetFiles(true);
    try {
      httpGet({
        call: `company_data/uploads`,
        apiVersion: "v2",
      }).subscribe(
        async (res) => {
          setUploadedFiles(res.response);
          setLoadingGetFiles(false);
        },
        (err) => {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
          setLoadingGetFiles(false);
        }
      );
    } catch (error) {
      console.log("---------error", error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
      setLoadingGetFiles(false);
    }
  };

  useEffect(() => {
    getBulkFiles();
  }, []);

  const handleUpload = (url, name) => {
    if (name.indexOf(" ") > 0) {
      enqueueSnackbar(
        "Your file name is not supported. Please make sure your file name does not contain any empty spaces (use an underscore “_” or hyphen “-” instead of spaces).",
        {
          variant: "error",
        }
      );
      return;
    }
    if (url) {
      setLodingToTraction(true);
      httpPost({
        call: `company_data/uploads`,
        apiVersion: "v2",
        data: {
          file_url: url,
        },
      })
        .pipe(
          catchError((err) => {
            setLodingToTraction(false);
            errorHandler(err, enqueueSnackbar);
            return of(null);
          })
        )
        .subscribe(
          (res) => {
            if (res) {
              setLodingToTraction(false);
              getBulkFiles();
              enqueueSnackbar(
                "Your file uploaded successfully. Depending on the number of custom fields uploaded, it may take up to a few hours to add them all to your Traction database. You will be notified by email when the job is complete.",
                {
                  variant: "success",
                }
              );
            }
          },
          (err) => {
            console.log("------err---", err);
            setLodingToTraction(false);
            enqueueSnackbar("Something went wrong", {
              variant: "error",
            });
          }
        );
    }
  };

  const handleDownloadTemplate = () => {
    setLoadingFile(true);
    try {
      httpPost({
        call: "company_data/templates",
        apiVersion: "v2",
        data: {
          query: "",
          search: {
            query: "",
          },
        },
        respType: "blob",
      })
        .pipe()
        .subscribe((res) => {
          const url = window.URL.createObjectURL(new Blob([res.response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `company_data_template.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove();

          enqueueSnackbar("Template succesfully downloaded.", {
            variant: "success",
          });
          setLoadingFile(false);
        });
    } catch (error) {
      console.log("----error-----", error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    }
  };

  const checkForStatus = (status) => {
    if (status === "processing") {
      return "pending";
    }
    if (status === "completed") {
      return "success";
    }
    if (status === "failed") {
      return "failed";
    }
    return "pending";
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "File Name",
        id: "file_url",
        sortable: false,
        name: "file_url",
        accessor: (row) => (
          <span className={styles.fileName}>
            {row.file_url?.slice(
              row.file_url.indexOf("/uploads") + 9,
              row.file_url.length
            )}
          </span>
        ),
        width: 200,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Upload Status",
        id: "status",
        sortable: false,
        name: "status",
        accessor: (row) => (
          <span className={styles[checkForStatus(row.status)]}>
            {row.status}
          </span>
        ),
        width: 150,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      // {
      //   Header: "Processed Companies",
      //   id: "companies_processed",
      //   sortable: false,
      //   name: "companies_processed",
      //   accessor: (row) => (
      //     <span className={styles.fileName}>
      //       {row.companies_processed || "-"}
      //     </span>
      //   ),
      //   width: 100,
      //   style: {
      //     display: "flex",
      //     justifyContent: "center",
      //   },
      // },
      {
        Header: "Date Added",
        id: "created_at",
        sortable: false,
        name: "created_at",
        accessor: (row) => (
          <span className={styles.fileName}>
            {format(new Date(row.created_at), "eeee, MMM do, yyyy")}
          </span>
        ),
        width: 130,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Uploaded By",
        id: "user_name",
        sortable: false,
        name: "user_name",
        accessor: (row) => (
          <span className={styles.fileName}>{row?.user_name || "-"}</span>
        ),
        width: 180,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Rows",
        id: "rows",
        sortable: false,
        name: "rows",
        accessor: (row) => (
          <span className={styles.fileName}>{row?.rows_size || "-"}</span>
        ),
        width: 100,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Message",
        id: "error_message",
        sortable: false,
        name: "error_message",
        accessor: (row) => (
          <div className={styles.errorMessage}>{row.error_message || "-"}</div>
        ),
        width: 400,
      },
      {
        Header: "Standard Fields",
        id: "standard_fields_processed",
        sortable: false,
        name: "standard_fields_processed",
        accessor: (row) => (
          <span className={styles.fileName}>
            {row.standard_fields_processed || "0"}
          </span>
        ),
        width: 100,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Custom Fields",
        id: "custom_fields_processed",
        sortable: false,
        name: "custom_fields_processed",
        accessor: (row) => (
          <span className={styles.fileName}>
            {row.custom_fields_processed || "0"}
          </span>
        ),
        width: 100,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Notes",
        id: "notes_rows_processed",
        sortable: false,
        name: "notes_rows_processed",
        accessor: (row) => (
          <span className={styles.fileName}>
            {row.notes_rows_processed || "0"}
          </span>
        ),
        width: 100,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [uploadedFiles]
  );
  return (
    <div className={styles.container}>
      <div className={styles.title}>DATA UPLOADS</div>
      <div>
        You may bulk upload data to the existing default Custom Fields in the
        Company Details tab of Company profiles, Standard fields in the Company
        Overview tab (including Company Contacts and Relationship Owners), and
        Notes. In order to do this, please follow these steps. <br />
        <br />
        <ol>
          <li>
            First make sure that all the companies you want to upload data for
            are ALREADY in your Traction database. If they are not, please first
            add the companies or upload them in the{" "}
            <span
              className={styles.link}
              onClick={(e) => {
                onTabClick("uploads");
              }}
            >
              BULK UPLOADS
            </span>{" "}
            section.
          </li>
          <li>Download the spreadsheet template by clicking the link below.</li>
          <li>
            The spreadsheet is divided into 3 tabs: Custom Fields, Standard
            Fields, and Notes.{" "}
            {/* <span
              className={styles.link}
              onClick={(e) => {
                history.push(`/admin/custom-fields`);
              }}
            >
              Custom Field Admin
            </span>{" "} */}
          </li>
          <li>
            The column headers in the downloaded spreadsheet template will match
            the fields that you can import ( do not change these). The second
            row shows examples (these will not be imported). Start adding your
            data in the row under the examples. Fields may be left blank (except
            in the first two columns).
          </li>
          <li>
            For each tab where you want to upload data, make sure the first two
            columns of each tab are:
            <br />
            a. First column = “Company Name” (Spelling should match name in
            Traction);
            <br />
            b. Second column = “Company Website” (The company website serves as
            a unique identifier for each company)
          </li>
          {expandText ? (
            <>
              <li>
                In the Custom Field tab, these Custom Fields are supported:
                <br />
                a. Text
                <br />
                b. Number
                <br />
                c. Date (use date format MM/DD/YYYY)
                <br />
                d. Dropdown (Single-Select)
                <br />
                e. Checklist (Single-Select)
                <br />
                f. Link
              </li>
              <li>
                In the Standard Field tab, these Standard fields are supported
                <br />
                a. Company Name
                <br />
                b. Company Website
                <br />
                c. Description
                <br />
                d. Company Contacts (Name, Email, Title, Phone)
                <br />
                e. Relationship Stage
                <br />
                f. Relationship Owner
                <br />
                g. HQ Location (City, State, Country, Region)
                <br />
                h. Year Founded
                <br />
                i. Employees
                <br />
                j. Estimated Revenue
              </li>
              <li>In the Notes tab:</li>
              a. Indicate the Note type (Note, Call, Email, Event, or Meeting)
              <br />
              b. Enter the Note in the “Note Text” column
              <br />
              c. Visibility can be set to Public, Private, or for a specific
              Team (enter the Team name as it appears in{" "}
              <span
                className={styles.link}
                onClick={(e) => {
                  history.push(`/admin/teams`);
                }}
              >
                {" "}
                Admin/Teams
              </span>{" "}
              ).
              <br />
              d. The Author will be set to the name of the Admin uploading the
              file (i.e., your name). The Author name can be set to any other
              Member’s name by entering that Member’s email in the spreadsheet
              cell, in which case that Member’s name will appear as the Note’s
              Author.
              <br />
              e. For Date and Time use MM/DD/YYYY and 24:00 (24H time) formats
              in the Date field (eg., 03/15/2020 14:30). You can also just add a
              Date without a Time.
            </>
          ) : (
            <></>
          )}
        </ol>
        {expandText
          ? "(For your first upload do a test with only 2 to 5 rows that can be easily checked. If you run into any issues, check the error message after upload or contact us using the chatbot at lower right)."
          : ""}
        <div className={styles.expandButtonContainer}>
          <Button
            btn={BtnType.FRAME_LESS}
            onClick={() => {
              setExpandText(!expandText);
            }}
          >
            {" "}
            {expandText ? "Collapse" : "More"}
          </Button>
        </div>
        <div className={styles.uploadDataButtonContainer}>
          <div className="d-flex">
            <div className="d-flex mr-5 mb-2">
              {loadingFile ? (
                <Loading />
              ) : (
                <>
                  <span
                    className={styles.link}
                    onClick={() => {
                      handleDownloadTemplate();
                    }}
                  >
                    <Icon {...smSize} icon="arrow-down2" />
                    <span>Download template</span>
                  </span>
                </>
              )}
            </div>
            <div className={styles.attachFile}>
              <AttachFileComponent
                files={[]}
                onChange={handleUpload}
                uploadDirName={`council_companies/uploads/`}
                replaceImage={handleUpload}
                hideDeleteIcons
                customFormats={["xlxs", "xlsx", "xls", "csv"]}
                customText="UPLOAD DATA"
                isBtnInstance
                customFormatError={
                  'The file format you uploaded is not supported. Please use one of these supported file extensions:  "xlsx", "xls", “xlxs", "csv"'
                }
              />
            </div>
          </div>
          {loadingToTraction && <Loading />}
        </div>
        <ReactTable
          columns={columns}
          data={uploadedFiles}
          showPagination={uploadedFiles.length > 20}
          minRows={5}
          loading={loadingGetFiles ? <Loading /> : null}
          pageSize={20}
          className={styles.table}
        />
      </div>
    </div>
  );
};

export default DataUploadComponent;

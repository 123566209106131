import React from "react";
import styles from "./form-generator-wrp.module.scss";

const FormGeneratorWrap = ({ children, council, formSlug }) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <div className={styles.headerWrp}>
          <div className={styles.councilWrp}>
            {formSlug !== "mdss-2023-application--revised" ? (
              <div className={styles.councilLogo}>
                <img
                  alt="Council Logo"
                  src={council.logo}
                  className={styles.councilLogoImg}
                />
              </div>
            ) : null}
          </div>
          {children.props.form.council_name_slug !== "kyndryl" && (
            <div className={styles.tractionWrp}>
              <img
                alt="Traction Logo"
                src="https://traction.network/static/media/logo.9a98af8e.svg"
                className={styles.councilLogoImg}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.formWrp}>{children}</div>
      </div>
    </div>
  );
};

export default FormGeneratorWrap;

import React from "react";

import styles from "./productIntegrations.module.scss";
import { BtnType, Button } from "modules/primitives";
import useModelPopup from "common/hooks/modelPopup.hook";
import EditProductIntegrations
  from "modules/companies/product/components/overview/productIntegrations/editProductIntegrations";

const ProductIntegrations = ({ product }) => {
  const popup = useModelPopup();

  const handleAddProductIntegrations = () => {
    popup.show({
      show: true,
      title: "Add Product Integrations and API",
      component: <EditProductIntegrations product={product} />,
    });
  };

  return (
    <div className={styles.productIntegrations}>
      {
        product.integrations?.length ? (
          <div className="mb-4">
            <span>Product Integrations</span>
            <div>
              <ul className="ml-4">
                {
                  product.integrations.map(i => (
                    <li key={i}>{i}</li>
                  ))
                }
              </ul>
            </div>
          </div>
        ) : null
      }
      <div className="mb-4">
        <span>API's: {product.api ? 'Yes' : 'No'}</span>
      </div>
      {
        !product.integrations?.length ? (
          <div className="d-flex align-items-center flex-column">
            <span>No product integrations added yet.</span>
            <Button
              className="mt-3"
              onClick={handleAddProductIntegrations}
              btn={BtnType.REGULAR}
            >
              ADD PRODUCT INTEGRATIONS
            </Button>
          </div>
        ) : null
      }
    </div>
  );
};

export default ProductIntegrations;

import React from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import styles from "./documentList.module.scss";
import { MoreOptions } from "../../../../../primitives";
import { dropOptions } from "./documentList.constant";
import { OptionSize } from "modules/primitives/moreOptions/moreOptions.constant";
import { Icon, smSize } from "../../../../../../common/icon";
import {
  useDocumentName,
  useS3FileUpload,
} from "../../../../../../common/hooks";
import { projectTaskDocumentDelete } from "../../tasks.action";
import { shouldDownload } from "../../../../../../common/helper";
import UploadedByInfo from "../../../../../../common/components/uploadedByInfo";

const DocumentListComponent = ({
  documentList,
  assignmentId,
  setLoadingDocuments,
  blockFunctionality = false,
  taskId,
  isProjectWideFromStage,
  handleDocDelete,
}) => {
  const documentName = useDocumentName();
  const dispatch = useDispatch();
  const { fileDelete } = useS3FileUpload();
  const { enqueueSnackbar } = useSnackbar();

  const getDocNameFromUri = (docUri) => {
    const arrayFromUri = docUri && docUri?.split("/");
    if (arrayFromUri.length) {
      return arrayFromUri[arrayFromUri.length - 1];
    }
    return "Untitled";
  };

  const handleMoreOptionClick = async (value, id, name) => {
    if (value === "remove") {
      const pathname = `documents/${name}`;
      setLoadingDocuments(true);
      await fileDelete(pathname).then((res) => {
        setLoadingDocuments(false);
      });
      dispatch(
        projectTaskDocumentDelete({
          documentId: id,
          assignmentId,
          enqueueSnackbar,
          taskId,
          isProjectWideFromStage
        }),
      );
      if (handleDocDelete) {
        handleDocDelete(documentList?.length > 1);
      }
    }
  };

  return (
    <>
      {documentList
        && documentList.length > 0
        && documentList.map((document) => (
          <div
            key={document.id}
            className={`${styles.documentLine} ${styles.values}`}
          >
            <div className="d-flex flex-column flex-grow-1">
              <span className={styles.docName}>
                {documentName.getName(document?.name)
                  || getDocNameFromUri(document.uri)}
              </span>
              <UploadedByInfo document={document} />
            </div>
            <div className={styles.btnWrp}>
              <a href={document.uri} target="_blank" download rel="noreferrer">
                <Icon
                  {...smSize}
                  icon="icn-export-button"
                  className={styles.download}
                />
              </a>
              <a className={styles.docView} target="blank" href={document.uri}>
                {shouldDownload(document.name) ? "download" : "view"}
              </a>
            </div>

            <div className={classNames(styles.iconMore)}>
              {!blockFunctionality && (
                <MoreOptions
                  options={dropOptions}
                  className={styles.moreOptWrp}
                  onClick={(value) => handleMoreOptionClick(value, document.id, document.name)}
                  size={OptionSize.SMALL}
                />
              )}
            </div>
          </div>
        ))}
    </>
  );
};

export default React.memo(DocumentListComponent);

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import styles from "./styles.module.scss";

import {
  queryStringUrlReplacement,
  useQuery,
} from "../../../../../../common/helper";
import { PRODUCT_ONLY_PARAMS } from "modules/companies/companyMain/companyMain.constant";

const ToggleCompaniesOrProducts = ({ handleExpandAll }) => {
  const history = useHistory();
  const toggleFilter = useQuery().get("toggle");

  const [toggleValue, setToggleValue] = useState('0');

  useEffect(() => {
    setToggleValue(toggleFilter || '0')
  }, [toggleFilter])

  const handleChange = (val) => {
    let state = history.location.state;
    let value = val;

    if (
      (toggleFilter === '1' && val === '2') ||
      (toggleFilter === '2' && val === '1') ||
      (toggleFilter === '1' && val === '1') ||
      (toggleFilter === '2' && val === '2')
    ) {
      value = '0';
    }

    if (value === '2' && state) {
      PRODUCT_ONLY_PARAMS.forEach((key) => {
        if (state[key]) {
          delete state[key];
        }
      });

      const search = Object.keys(state).reduce(
        (acc, key) =>
          `${acc === '?' ? acc : `${acc}&`}${key}=${state[key]}`,
        "?"
      );

      history.push({
        pathname: "/companies",
        search: `${search}&toggle=2`,
        state: {
          ...state,
          toggle: 2,
          page: 1,
        },
      });

      handleExpandAll(false);

      return
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      'toggle',
      value,
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        toggle: value,
        page: 1,
      },
    });
  }

  return (
    <div className={styles.itemWrapper} id="toggle">
      <div className="d-flex align-items-center">
        <fieldset id="toggle" className="d-flex flex-column">
          <div className="d-flex mr-3 cursor-pointer">
            <input
              type="checkbox"
              id="0"
              name="0"
              value="0"
              checked={toggleValue === '0'}
              onClick={() => handleChange('0')}
            />
            <label className="ml-1 font-weight-bold" htmlFor="0">All</label>
          </div>
          <div className="d-flex cursor-pointer ml-4">
            <input
              type="checkbox"
              id="1"
              name="1"
              value="1"
              checked={toggleValue === '0' ? true : toggleValue === '1'}
              onClick={() => handleChange('1')}
            />
            <label className="ml-1 font-weight-bold" htmlFor="1">Companies</label>
          </div>
          <div className="d-flex cursor-pointer ml-4">
            <input
              type="checkbox"
              id="2"
              name="2"
              value="2"
              checked={toggleValue === '0' ? true : toggleValue === '2'}
              onClick={() => handleChange('2')}
            />
            <label className="ml-1 font-weight-bold" htmlFor="2">Products</label>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default React.memo(ToggleCompaniesOrProducts);

export const preSelectedCompanyValues = () => [
  {
    text: "name",
    value: "string",
  },
  {
    text: "web_url",
    value: "string",
  },
  {
    text: "description",
    value: "string",
  },
  {
    text: "employees",
    value: "integer",
  },
  {
    text: "founded",
    value: "datetime",
  },
  {
    text: "location_city",
    value: "string",
  },
  {
    text: "location_region",
    value: "string",
  },
  {
    text: "location_country",
    value: "string",
  },
];

export const selectedSalesForceValues = () => [
  {
    text: "Name",
    value: "string",
  },
  {
    text: "Website",
    value: "url",
  },
  {
    text: "Description",
    value: "string",
  },
  {
    text: "NumberOfEmployees",
    value: "string",
  },
  {
    text: "YearStarted",
    value: "string",
  },
  {
    text: "BillingCity",
    value: "string",
  },
  {
    text: "BillingState",
    value: "string",
  },
  {
    text: "BillingCountry",
    value: "string",
  },
];

export const companyFieldsToSubtract = () => [
  { text: "id", value: "uuid" },
  { text: "traction_tag", value: "string" },
  { text: "background", value: "string" },
  { text: "cb_last_updated_on", value: "datetime" },
  { text: "cb_import_profile", value: "boolean" },
  { text: "cb_profile", value: "json" },
  { text: "cb_profile_name", value: "boolean" },
  { text: "closed_in_crunchbase", value: "boolean" },
  { text: "function", value: "string" },
  { text: "approved", value: "boolean" },
  { text: "managed_by_company", value: "boolean" },
  { text: "origin_metadata", value: "json" },
  { text: "promo_video", value: "string" },
  { text: "source", value: "string" },
  { text: "startup_metadata", value: "json" },
  { text: "council_id", value: "uuid" },
  { text: "web_scheme", value: "string" },
  { text: "web_prefix", value: "string" },
  { text: "discarded_at", value: "datetime" },
  { text: "added_from_source", value: "string" },
  { text: "applicant_submission_id", value: "uuid" },
  { text: "created_by_id", value: "uuid" },
  { text: "created_at", value: "datetime" },
  { text: "updated_at", value: "datetime" },
  { text: "funding", value: "json" },
  { text: "investors", value: "json" },
  { text: "leadership", value: "json" },
  { text: "logo", value: "string" },
  { text: "active", value: "boolean" },
  { text: "social", value: "json" },
  { text: "is_active", value: "boolean" },
  { text: "is_approved", value: "boolean" },
  { text: "web_domain", value: "string" },
];

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { projectsSearch } from "modules/projects/projectsMain/projectsMain.action";

import { ModalBody, ModalFooter } from "../../../../../application/modal";
import {
  BtnType,
  Button,
  Label,
  MultiSelect,
  Loading,
} from "../../../../primitives";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../common/hooks";
import {
  themesProjectAddAttempt,
  themesMetricsGetAttempt,
} from "../overviewTab.action";

const AddRelatedProjectComponent = ({ currentThemeId, itemList, getAll }) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [elasticQueryy, setElasticQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },

    projectsReducer: {
      projectsMainReducer: { projectList, projectLoading },
    },
  } = useSelector((state) => state);

  const handleOptionSelect = (values) => {
    setSelected(values);
  };

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = useCallback(
    (values) => {
      const data = {
        theme_project: selected?.map((proj) => ({
          project_id: proj.id,
        })),
      };
      dispatch(
        themesProjectAddAttempt({
          themeId: currentThemeId,
          data,
          enqueueSnackbar,
          cb: () => {
            dispatch(
              themesMetricsGetAttempt({ id: currentThemeId, enqueueSnackbar })
            );
          },
        })
      );
      popup.hide();
    },
    [selectedCouncil, session, selected]
  );
  useEffect(() => {
    const filtredProjects = projectList.filter((project) => {
      return !itemList.some(
        (projectFromIdea) => project.id === projectFromIdea?.project_id
      );
    });
    setOptions(filtredProjects);
  }, [projectList]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(elasticQueryy);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [elasticQueryy]);

  useEffect(() => {
    if (selectedCouncil) {
      const payload = {
        data: {
          query: debouncedValue,
          search: {
            query: debouncedValue,
            user_id: null,
            exclude_theme_ids: [currentThemeId || ""],
          },
          selectedTab: "all_projects",
          page: 1,
          userId: session.id,
          sort_order: "desc",
          sort_attribute: "created_at",
        },
      };
      dispatch(projectsSearch(payload));
    }
  }, [debouncedValue, selectedCouncil]);

  return (
    <div className={styles.addCompanyWrp}>
      <ModalBody>
        <Label>Projects</Label>
        <div className={styles.selectorWrp}>
          <MultiSelect
            onChange={handleOptionSelect}
            placeholder="Select project (or type to search)"
            labelField="name"
            valueField="id"
            searchBy="name"
            options={options}
            searchFn={(args) => {
              setElasticQuery(args.state.search);
            }}
          />
          <div className={styles.loaderWrp}>
            {projectLoading && <Loading hideString />}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button type="submit" btn={BtnType.REGULAR} onClick={handleSaveClick}>
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default React.memo(AddRelatedProjectComponent);

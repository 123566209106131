import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTable from 'react-table';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import classNames from 'classnames';
import {councilListGetAllAttempt} from './../../council/council.action'
import styles from './adminCompanies.module.scss';
import {
  Button,
  BtnType,
  MoreOptions,
  OptionType,
  Loading,
} from '../../primitives';
import { useModelPopup } from '../../../common/hooks';
import {
  getTractionCompaniesAttempt,
  postSearchCompaniesByQueryAttempt,
} from './tractionCompanies.actions';
import 'react-table/react-table.css';
import { CSVLink } from 'react-csv';
import { httpGet } from 'common/httpCall';
import CompanyDeleteModal from './deleteModal/index';
import HideCompanyModal from './hideCompanyModal/index';
import UnHideCompanyModal from './unhideCompanyModal/index';
import AddNewTractionCompany from './addNewCompanyModal';

let delayTimerId;

const TractionCompanies = () => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef();
  const [searchValue, setSearchValue] = useState('');
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: {
        // tractionCompanies,
        searchResults,
        sortInstance,
      },
      companyReducer: { updateCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: {
        patientOrganizationList,
        updatePatientOrganizationsList,
        patientOrganizationPageNr,
				pOloading
      },
    },
    tractionCompaniesReducer: {
      tractionCompanies,
      loading,
      searchResultNr,
      searchResultPageNr,
    }
  } = useSelector((state) => state);
  const pageSizeOptions = [pageSize, 5, 10, 20, 50, 100];

  useEffect(() => {
    if(session?.id) {
      dispatch(councilListGetAllAttempt({ active: true, userId: session?.id, enqueueSnackbar }))
    }
  }, [session])


  useEffect(() => {
    if (selectedCouncil && updateCompany) {
      dispatch(
        getTractionCompaniesAttempt({
          items: pageSize,
          page,
          enqueueSnackbar,
        })
      );
    }
  }, [updateCompany, page]);
  useEffect(() => {
    if (page > 0) {
      if(searchValue) {
        dispatch(postSearchCompaniesByQueryAttempt({query: searchValue, enqueueSnackbar, page, pageSize}))
      }else {
        dispatch(
          getTractionCompaniesAttempt({
            items: pageSize,
            page,
            enqueueSnackbar,
          })
        );
      }
    }
  }, [page, pageSize]);

  useEffect(() => {
    if (searchResults && searchResults[0]) {
      setData(searchResults);
    } else {
      if (history.location?.state?.mode === 'organization') {
        setData(patientOrganizationList);
      } else {
        setData(tractionCompanies);
      }
    }
  }, [searchResults]);

  useEffect(() => {
    history.location?.state?.mode === 'organization'
      ? setData(patientOrganizationList)
      : setData(tractionCompanies);
  }, [tractionCompanies, patientOrganizationList]);


  const handleAddCompanyClick = (e) => {
      popup.show({
        title: 'Add Company',
        type: 'contact',
        component: <AddNewTractionCompany enqueueSnackbar={enqueueSnackbar} />,
      });
  };

  const DisabledButton = ({ showTooltip, setShowTooltip }) => (
    <>
      {showTooltip && (
        <div className={styles.exportModalTooltip}>
          <p>
            {searchResults?.length
              ? 'Search cannot be exported '
              : 'To be able to export list it must contain at least one element.'}
          </p>
        </div>
      )}
      <Button
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
        className={styles.deleteSubmitButton}
        btn={BtnType.DISABLED}
      >
        EXPORT PAGE
      </Button>
    </>
  );

  const getCompanyCouncilsInToString = (councils) => {
    const councilsNames = councils.map((council) => council.name);

    const councilsInString = councilsNames.join(',\n');

    return councilsInString;
  }

  const handleExportList = () => {
    setExportLoading(true);
    const link = `companies?items=${pageSize}&page=${page}`
    httpGet({
      apiVersion: 'v2',
      call: link,
    }).subscribe(
      async (res) => {
        setExportLoading(false);
        setCsvData(() => {
          const csvDataCont = [
            [
              'Company',
              'Website',
              'Crunchbase',
              'Headquarters',
              'Traction Score',
              'Date Added',
              'Who Added',
              'Council',
              'Acquired',
            ],
          ];
          res.response.map((el) => {
            csvDataCont.push([
              el.name,
              el.web_url,
              el.cb_profile_name ? 'YES' : 'NO',
              `${el?.location_city || '-'}, ${el?.location_country || '-'}, ${el?.location_region || '-'}, ${el?.location_continent || '-'}`,
              el.traction_score.toFixed(1),
              format(new Date(el.created_at), 'yyyy-MM-dd'),
              el?.created_by?.full_name || '-',
              getCompanyCouncilsInToString(el.councils),
              `${el.discarded_at ? 'YES' : 'NO'}`
            ]);
          });

          return csvDataCont;
        });
        csvLinkRef.current.link.click();
      },
      (err) => {
        setExportLoading(false);
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
        });
      },

      () => {
        setExportLoading(false);
        enqueueSnackbar('List succesfully exported.', {
          variant: 'success',
        });
      }
    );
  };

  const handleSortBy = (sortingFlag = '', sortingType = 'asc') => {
    dispatch(
      getTractionCompaniesAttempt({
        items: pageSize,
        page,
        enqueueSnackbar,
      })
    );
  }

  const moreOptions = [
    { val: 'edit', text: 'Edit' },
    { val: 'hide', text: 'Hide' },
    { val: 'unhide', text: 'Unhide' },
    { val: 'delete', text: 'Delete', type: OptionType.DANGER },
  ];
  const checkIfIsTractionMember = (email) => {
    if(!email) {
      return false;
    }
    const afterAron = email.split('@')[1];

    switch(afterAron) {
      case 'tractiontechpartners.com': {
        return true;
      }
      case 'tractiontechnologypartners.com': {
        return true;
      }
      case 'tractiontechnology.com': {
        return true;
      }
      default:
        return false;
    }

  }
  const columns = React.useMemo(
    () => [
      {
        Header: (columnRef) => {
          return (
            <span style={{cursor: 'pointer'}} >Company</span>
          )
        },
        sortable: false,
        accessor: 'name',
        width: 190,
      },
      {
        Header: 'Website',
        id: 'web_url',
        sortable: false,
        name: 'web',
        accessor: (row) => (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              if (row.web_url) {
                window.open(row?.web_url, '_blank');
              }
            }}
            btn={BtnType.FRAME_LESS}
            className={
              row.web_url ? styles.linkButton : styles.disabledLinkButton
            }
          >
            Link
          </Button>
        ),
        width: 100,
        style: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
      {
        Header: 'Crunchbase',
        id: 'cb_profile_name',
        sortable: false,
        name: 'web',
        accessor: (row) => (
          <div>
            {row?.cb_profile_name ? 'YES' : 'NO'}
          </div>
        ),
        width: 100,
        style: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
      {
        Header: 'Headquarterts',
        id: 'headquarterts',
        sortable: false,
        accessor: (row) => (
          <div>
            {
              Boolean(row?.location_continent || row?.location_country || row?.location_region || row?.location_city) ?
              `${row?.location_city}, ${row?.location_country}, ${row?.location_region}, ${row?.location_continent}` :
              '-'
            }
          </div>
        ),
        width: 300,
      },
      {
        Header: 'Traction Score',
        id: 'traction_score',
        sortable: false,
        accessor: (row) => (
          <div>
            {row?.traction_score?.toFixed(1)}
          </div>
        ),
        width: 150,
        style: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
      {
        Header:(columnRef) => <span style={{cursor: 'pointer'}} >Date Added</span>,
        id: 'created_at',
        sortable: false,
        accessor: (row) => format(new Date(row.created_at), 'yyyy-MM-dd'),
        width: 150,
        style: {
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'center',
        },
      },
      {
        Header: 'Who added',
        id: 'created_by',
        accessor: (row) => {
          return (
            <>
              {
                checkIfIsTractionMember(row?.added_by_email) ? (
                  <span className={styles.tractionMember}>
                    Traction
                  </span>
                )
                :
                row?.created_by?.full_name ? row?.created_by?.full_name : row?.created_by_name ? row?.created_by_name : '-'
              }
            </>
          )
        },
        width: 150,
        style: {
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'center',
        },
      },
      {
        Header: 'Council',
        id: 'councils',

        accessor: (row) => (
          <div style={{
            // overflow: ''
            overflowX: 'scroll'
          }}>
            {row?.councils?.map((council) => {
             return (
               <>
                <span>{council?.name};{'  '}</span>
               </>
             )
            })}
            {row?.councils_names?.map((councilName) => {
             return (
               <>
                <span>{councilName};{'  '}</span>
               </>
             )
            })}
          </div>
        ),
        width: 250,
        style: {
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'center',
        },
      },
      {
        Header: 'Acquired',
        id: 'discarded_at',
        width: 90,
        accessor: (row) => (
          <div>
            {row?.discarded_at ? 'YES' : 'NO'}
          </div>
        ),

        style: {
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'center',
        },
      },
      {
        Header: 'Options',
        id: 'options',

        accessor: (row) => {

          const filteredOptions = moreOptions.filter((option) => {
            if((row?.any_actions && option?.val === 'delete') || row?.any_actions && option?.val === 'hide' || row?.any_actions && option?.val === 'unhide') {
              return false;
            }
            return true;
          })

          return (
            <MoreOptions
              options={filteredOptions}
              className={styles.moreOptWrp}
              onClick={(e) => {
                handleMoreOptionClick(e, row.id, row.councils, row);
              }}
            />
          )
        },
        width: 80,
        style: {
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'center',
        },
      },
    ],
    [tractionCompanies]
  );

  const handleNextClick = () => {
    setPage(page + 1);
  };

  const handlePrevClick = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const handleMoreOptionClick = (event, companyId, councils, company) => {
    if (event === 'edit') {

        history.push(
          {
            pathname: `/tractionadmin/companies/edit/${companyId}`,
            state: { fromTractionAdmin: true }
        });
    }
    if (event === 'unhide') {
      popup.show({
        title: 'Unhide company from councils',
        show: true,
        height: '300',
        width: '540',
        component: (
          <UnHideCompanyModal
            councils={councils}
            company={company}
            enqueueSnackbar={enqueueSnackbar}
            popup={popup}
          />
        ),
      });
    }
    if (event === 'hide') {
      popup.show({
        title: 'Hide company from councils',
        show: true,
        height: '300',
        width: '540',
        component: (
          <HideCompanyModal
            councils={councils}
            company={company}
            enqueueSnackbar={enqueueSnackbar}
            popup={popup}
          />
        ),
      });
    }
    if (event === 'delete') {
			const council = JSON.parse(localStorage.getItem('council')) ;
      popup.show({
        title: 'Confirmation Prompt',
        show: true,
        height: '300',
        width: '540',
        component: (
          <CompanyDeleteModal
            id={companyId}
            enqueueSnackbar={enqueueSnackbar}
            popup={popup}
            company={company}
          />
        ),
      });
    }
  };

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
  };

  const handlePageSelect = (e) => {
    setPage(e.target.value);
  };

  const isNextDisabled = () => {
    if (page + 1 > 3) {
      return styles.disabledButton;
    } else {
      return styles.paginationButton;
    }
  };

const detectLoading = () => {
	if (selectedCouncil &&
		selectedCouncil.name === 'GSK' ) {
		return pOloading
	}
	else return loading
}


  const searchTractionCompanies = (value, withoutTimeDelay = false) => {

    window.clearTimeout(delayTimerId);
    if(!withoutTimeDelay) {
      delayTimerId = window.setTimeout(function() {
        if(value) {
          dispatch(postSearchCompaniesByQueryAttempt({query: value, enqueueSnackbar}))
          setPage(1);
        }else {
          setPage(1);
          dispatch(getTractionCompaniesAttempt({
            items: 20,
            page: 1,
            enqueueSnackbar,
          }))
        }

      }, 1000);
    }else {
      if(value) {
        dispatch(postSearchCompaniesByQueryAttempt({query: value, enqueueSnackbar}))
        setPage(1);
      }else {
        setPage(1);
        dispatch(getTractionCompaniesAttempt({
          items: 20,
          page: 1,
          enqueueSnackbar,
        }))
      }
    }


  }


  return (
    <div className={styles.container}>
      <div className={styles.containerWrapper}>
        <div className={styles.companiesWrp}>

          <div className={styles.flexWrapper}>

            <div className={styles.companies}>
              <div className={styles.searchContainer}>
              <div className={classNames(styles.searchWrp, styles.searchCon)}>
              <input
                  className={styles.searchText}
                  size="large"
                  name="searchInput"
                  value={searchValue || ""}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    searchTractionCompanies(e?.target?.value);
                  }}
                  placeholder={'Search Traction Companies'}
                />

            </div>
            <Button
              onClick={() => searchTractionCompanies(searchValue, true)}
              btn={BtnType.REGULAR}
              className={styles.searchButton}
            >
              Search Traction Companies
            </Button>
              </div>

              <div className={styles.buttonWrp}>
								<div className={styles.addCompanyBtnWrp}>
									<Button
										btn={BtnType.REGULAR}
										icon='icn-add'
										onClick={handleAddCompanyClick}
									>
											Add Company
									</Button>
								</div>
                {exportLoading ? (
                  <Loading />
                ) : data?.length ? (
                  <div>
                    <Button
                      // disabled={!listTotalCount}
                      onClick={handleExportList}
                      btn={BtnType.OUTLINE}
                    >
                      EXPORT PAGE
                    </Button>
                    <CSVLink
                      data={csvData}
                      filename={`Traction ${
                        history.location.pathname.indexOf('organizations') !==
                        -1
                          ? 'Organizations'
                          : 'Companies'
                      }.csv`}
                      style={{ display: 'none' }}
                      ref={csvLinkRef}
                    />
                  </div>
                ) : (
                  <DisabledButton
                    setShowTooltip={setShowTooltip}
                    showTooltip={showTooltip}
                  />
                )}

                <div className={styles.totalCount}>
                  {history.location.pathname.indexOf('organizations') < 0 ? (
                    <p>
                      {' '}
                      {searchResultPageNr && !searchResultNr
                        ? searchResultPageNr + ' ' + 'Results' ||
                          '0 companies'
                        : searchResultNr + ' Results'}{' '}
                    </p>
                  ) : (
                    <p>
                      {' '}
                      {patientOrganizationPageNr?.total && !searchResultNr
                        ? patientOrganizationPageNr?.total + ' ' + 'Results' ||
                          '0 organizations'
                        : searchResultNr + ' Results'}{' '}
                    </p>
                  )}
                </div>
              </div>

              <ReactTable
                columns={columns}
                data={data}
                showPagination={false}
                minRows={5}
                loading={detectLoading() ? <Loading /> : null}
                pageSize={pageSize}
                // onPageSizeChange={(e) => handleChangePageSize(e)}
                className={styles.table}
                getTrProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
											onClick: () => {
												history.push(
													{
														pathname: `/tractionadmin/companies/edit/${rowInfo.original.id}`,
														state: { fromTractionAdmin: true }
												});
									},
                      onMouseEnter: (e) => {
                        setHoveredRow(rowInfo.index);
                      },
                      onMouseLeave: (e) => {
                        setHoveredRow(null);
                      },
                      style: {
                        cursor: 'pointer',
                        background:
                          rowInfo.index === hoveredRow
                            ? '#efefef'
                            : 'transparent',
                      },
                    };
                  } else return {};
                }}
              />
              <div className={styles.pagination}>
                <div
                  className={
                    page - 1 === 0
                      ? styles.disabledButton
                      : styles.paginationButton
                  }
                  onClick={handlePrevClick}
                  disabled={page - 1 === 0}
                >
                  Previous
                </div>
                <div className={styles.paginationSettings}>
                  <span style={styles.pageNr}>
                    <span>
                      <input
                        onChange={(e) => handlePageSelect(e)}
                        value={page}
                        className={styles.inputBox}
                        type='number'
                        min='1'
                      />
                    </span>
                    <span>of</span>
                    <span>
                      {searchResultPageNr}
                    </span>
                  </span>

                  <span>
                    <select onChange={(e) => handleChangePageSize(e)}>
                      {pageSizeOptions.map((option) => (
                        <option value={option}>{option}</option>
                      ))}
                    </select>
                  </span>
                </div>
                <button
                  className={isNextDisabled()}
                  onClick={handleNextClick}
                  disabled={
                    history.location?.state?.mode === 'organization'
                      ? page + 1 > patientOrganizationPageNr?.pages
                      : page + 1 > searchResultPageNr
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TractionCompanies;

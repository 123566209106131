import React from "react";
import { useHistory } from "react-router-dom";

import { ModalFooter, ModalBody } from "../application/modal";
import { Button, BtnType } from "../modules/primitives";
import styles from "./styles.module.scss";

const SCReview = ({
  handleStageSelect,
  e,
  companyId,
  listId,
  popup,
  fromEditCompany,
  prevDropVal,
  setCancelPressed,
  companySlug,
  fromCompanyTopDetails,
  isProduct,
  company,
  stageName,
}) => {
  const history = useHistory();

  const handleSaveClick = () => {
    handleStageSelect(e, companyId, listId, isProduct, company, stageName);
    history.push(`/companies/${companySlug}/details`);

    popup.hide();
  };
  const handleRedirectToDetails = () => {
    handleSaveClick();
    history.push(`/companies/${companySlug}/details`);

    popup.hide();
  };

  const handleCancelClick = () => {
    if (fromEditCompany) {
      handleStageSelect(prevDropVal);
      setCancelPressed(true);
    }

    popup.hide();
  };

  return (
    <div>
      {" "}
      <ModalBody>
        <div>
          By changing the innovation funnel to “SC Review” you are asking to add
          this company to the monthly Selection Committee Review Board Meeting. 
          To be considered for this meeting you must have answered the questions
          in the
          <span
            className={
              !fromEditCompany && !fromCompanyTopDetails && styles.detailsLink
            }
            onClick={() => {
              if (!fromEditCompany && !fromCompanyTopDetails) {
                handleRedirectToDetails();
              }
            }}
          >
            {" "}
            Details{" "}
          </span>{" "}
          tab since this will be the pre-read for the meeting
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button btn={BtnType.REGULAR} onClick={handleSaveClick}>
          OK
        </Button>
      </ModalFooter>
    </div>
  );
};

export default SCReview;

import ChartDataLabels from "chartjs-plugin-datalabels";

export const makeFirmenichChartOptions = () => ({
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      color: "#ffffff",
      display: "auto",
      clamp: true,
      font: {
        weight: "bold",
      },
    }
  },
  title: {
    display: false,
  },
  layout: {
    padding: {
      bottom: 0,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
});

export const makePieFirmenichConfig = (chartData) => {
  const { data_sets } = chartData;
  return {
    data: {
      labels: chartData.labels,
      datasets: [{
        backgroundColor: data_sets.background_color,
        data: data_sets.data,
        customDataPerIndex: data_sets.custom_data_per_index,
        hover_offset: 4,
      }],
    },
    options: makeFirmenichChartOptions(),
    plugins: [ChartDataLabels],
  };
}

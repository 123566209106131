import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  httpGet,
  httpPost,
  httpPatch,
  httpDelete,
  httpPut,
  apiStatus,
  errorHandler,
} from "../../../common/httpCall";

import {
  GET_STAGES_TEMPLATE_ATTEMPT,
  getStagesTemplateSuccess,
  POST_STAGE_TEMPLATE_ATTEMPT,
  postStageTemplateSuccess,
  GET_STAGE_TEMPLATE_BY_ID_ATTEMPT,
  getStageTemplateByIdSuccess,
  PATCH_STAGE_TEMPLATE_BY_ID_ATTEMPT,
  patchStageTemplateByIdSuccess,
  GET_STAGE_ATTEMPT,
  getStageSuccess,
  POST_STAGE_ATTEMPT,
  postStageSuccess,
  DELETE_STAGE_BY_ID_ATTEMPT,
  deleteStageByIdSuccess,
  PATCH_STAGE_BY_ID_ATTEMPT,
  patchStageByIdSuccess,
  POST_TASK_ATTEMPT,
  postTaskSuccess,
  DELETE_TASK_ATTEMPT,
  deleteTaskSuccess,
  PATCH_TASK_ATTEMPT,
  patchTaskSuccess,
  DELETE_STAGE_TEMPLATE_BY_ID_ATTEMPT,
  deleteStageTemplateByIdSuccess,
  catchErrorSuccess,
  POST_TASK_DOCUMENT_ATTEMPT,
  postTaskDocumentSuccess,
  PATCH_TASK_DOC_NAME_ATTEMPT,
  patchTaskDocNameSuccess,
  GET_PROJECTS_FIELDS_ATTEMPT,
  getProjectsFieldsAttemptSuccess,
  SUBMIT_PROJECT_FIELD,
  submitProjectFieldSuccess,
  DELETE_PROJECT_FIELD,
  deleteProjectFieldSuccess,
  UPDATE_PROJECT_FIELD,
  updateProjectFieldSuccess,
  GET_PROJECTS_STAGE_FIELDS_ATTEMPT,
  getProjectsFieldsStageAttemptSuccess,
  DELETE_FIELD_TOGGLE,
  deleteFieldToggleSuccess,
  ACTIVATE_FIELD_TOGGLE,
  activateFieldToggleSuccess,
  GET_PROJECTS_GROUPS_ATTEMPT,
  getProjectsGroupsAttemptSuccess,
  SUBMIT_PROJECT_GROUP,
  submitProjectGroupSuccess,
  GET_GROUP_FIELDS,
  getGroupFieldsSuccess,
  ADD_FIELD_TO_GROUP,
  addFieldToGroupSuccess,
  UPDATE_PROJECT_GROUP,
  updateProjectGroupSuccess,
  DELETE_PROJECT_GROUP,
  deleteProjectGroupSuccess,
  DELETE_PROJECT_GROUP_FIELD,
  deleteProjectGroupFieldSuccess,
  GET_PROJECT_WIZARD_ATTEMPT,
  getProjectWizardAttemptSuccess,
  DELETE_WIZARD_STEP,
  deleteWizardStepSuccess,
} from "./adminProjects.actions";

export const getStagesTemplateEpic = (action$) =>
  action$.pipe(
    ofType(GET_STAGES_TEMPLATE_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: "stage_templates",
      }).pipe(
        map((result) => getStagesTemplateSuccess(result)),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const deleteStageTemplateEpic = (action$) =>
  action$.pipe(
    ofType(DELETE_STAGE_TEMPLATE_BY_ID_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpDelete({
        call: `stage_templates/${id}`,
      }).pipe(
        map((result) => {
          if (result.status === apiStatus.SUCCESS) {
            enqueueSnackbar("Deleted stage template successfully", {
              variant: "success",
            });
            return deleteStageTemplateByIdSuccess({ id });
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const postStageTemplateEpic = (action$) =>
  action$.pipe(
    ofType(POST_STAGE_TEMPLATE_ATTEMPT),
    switchMap(
      ({
        payload: {
          enqueueSnackbar,
          name,
          description,
          resource_type,
          sort_order,
          default_template,
          automate_due_dates,
          rating_enabled,
        },
      }) =>
        httpPost({
          call: "stage_templates",
          data: {
            stage_template: {
              name,
              description,
              // resource_type,
              sort_order,
              default_template,
              automate_due_dates,
            },
          },
        }).pipe(
          map((result) => {
            try {
              if (
                result.status === apiStatus.CREATED ||
                result.status === apiStatus.SUCCESS
              ) {
                enqueueSnackbar("Successfully added project template", {
                  variant: "success",
                });
                return postStageTemplateSuccess(result.response);
              }
            } catch (error) {
              console.log(
                "-----error in POST_STAGE_TEMPLATE_ATTEMPT-----",
                error
              );
            }
          }),
          catchError((error) =>
            errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
          )
        )
    )
  );

export const getStageTemplateByIdEpic = (action$) =>
  action$.pipe(
    ofType(GET_STAGE_TEMPLATE_BY_ID_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `stage_templates/${payload.id}`,
        // call: `council_task_templates?stage_template_id=${payload.id}`,
        // apiVersion: "v2",
      }).pipe(
        map((result) => getStageTemplateByIdSuccess(result.response)),
        catchError((error) =>
          errorHandler(error, payload.enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const patchStageTemplatebyIdEpic = (action$) =>
  action$.pipe(
    ofType(PATCH_STAGE_TEMPLATE_BY_ID_ATTEMPT),
    switchMap(
      ({
        payload: {
          enqueueSnackbar,
          name,
          description,
          resource_type,
          sort_order,
          id,
          default_template,
          automate_due_dates,
          patient_organization,
          default_patient_organization,
          rating_enabled,
          rating_template_id,
        },
      }) =>
        httpPatch({
          call: `stage_templates/${id}`,
          data: {
            stage_template: {
              name,
              description,
              resource_type,
              sort_order,
              default_template,
              automate_due_dates,
              patient_organization,
              default_patient_organization,
              rating_enabled,
              rating_template_id,
            },
          },
        }).pipe(
          map((result) => {
            try {
              if (
                result.status === apiStatus.CREATED ||
                result.status === apiStatus.SUCCESS
              ) {
                enqueueSnackbar("Successfully edited project template", {
                  variant: "success",
                });
                return patchStageTemplateByIdSuccess(result.response);
              }
            } catch (error) {
              console.log("-----????  catch ?????-----", error);
            }
          }),
          catchError((error) =>
            errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
          )
        )
    )
  );

export const getStagesEpic = (action$) =>
  action$.pipe(
    ofType(GET_STAGE_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `stages?stage_template_id=${payload.id}`,
      }).pipe(
        map((result) => getStageSuccess(result.response)),
        catchError((error) =>
          errorHandler(error, payload.enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const postStageEpic = (action$) =>
  action$.pipe(
    ofType(POST_STAGE_ATTEMPT),
    switchMap(
      ({
        payload: { enqueueSnackbar, templateId, name, description, sort_order },
      }) =>
        httpPost({
          call: "stages",
          data: {
            name,
            description,
            is_active: true,
            sort_order,
            stage_template_id: templateId,
          },
        }).pipe(
          map((result) => {
            try {
              if (
                result.status === apiStatus.CREATED ||
                result.status === apiStatus.SUCCESS
              ) {
                enqueueSnackbar("Successfully added stage to template", {
                  variant: "success",
                });
                return postStageSuccess(result.response);
              }
            } catch (error) {
              console.log(
                "-----error in POST_STAGE_TEMPLATE_ATTEMPT-----",
                error
              );
            }
          }),
          catchError((error) =>
            errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
          )
        )
    )
  );

export const deleteStageEpit = (action$) =>
  action$.pipe(
    ofType(DELETE_STAGE_BY_ID_ATTEMPT),
    switchMap(({ payload: { stageId, enqueueSnackbar } }) =>
      httpDelete({
        call: `stages/${stageId}`,
      }).pipe(
        map((result) => {
          if (result.status === apiStatus.SUCCESS) {
            enqueueSnackbar("Deleted stage successfully", {
              variant: "success",
            });
            return deleteStageByIdSuccess({ id: stageId });
          }
          enqueueSnackbar(
            "There was an error during executing this action. Please try one more time.",
            {
              variant: "error",
            }
          );
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const patchStageByIdEpic = (action$) =>
  action$.pipe(
    ofType(PATCH_STAGE_BY_ID_ATTEMPT),
    switchMap(
      ({
        payload: {
          enqueueSnackbar,
          name,
          description,
          isActive,
          sort_order,
          templateId,
        },
      }) =>
        httpPatch({
          call: `stages/${templateId}`,
          data: {
            name,
            description,
            is_active: true,
            sort_order,
          },
        }).pipe(
          map((result) => {
            try {
              if (
                result.status === apiStatus.CREATED ||
                result.status === apiStatus.SUCCESS
              ) {
                enqueueSnackbar("Successfully edited stage", {
                  variant: "success",
                });
                return patchStageByIdSuccess(result.response);
              }
            } catch (error) {
              console.log("-----????  catch ?????-----", error);
            }
          }),
          catchError((error) =>
            errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
          )
        )
    )
  );

export const postTaskEpic = (action$) =>
  action$.pipe(
    ofType(POST_TASK_ATTEMPT),
    switchMap(
      ({
        payload: {
          enqueueSnackbar,
          name,
          councilId,
          description,
          stage_id,
          position,
          task_type,
          approval_task_options,
          days_to_complete,
          project_field_group_id,
          project_metric_attribute,
          project_field_template_id,
          assigned_approvers,
          assigned_reviewers,
          assigned_users,
          approve_in_order,
          assign_to_project_owner,
          mark_on_document_upload,
          disable_manual_completion,
          teams,
          apply_only_to_new,
          apply_to_existing,
          notify_after_previous_completed,
        },
      }) =>
        httpPost({
          call: `council_task_templates`,
          apiVersion: "v2",
          data: {
            council_task_template: {
              name,
              description,
              task_type,
              type: task_type,
              approval_task_options,
              stage_id,
              position,
              days_to_complete,
              project_field_group_id,
              project_metric_attribute,
              project_field_template_id,
              assigned_approvers,
              assigned_reviewers,
              assigned_users,
              assign_to_project_owner,
              approve_in_order,
              mark_on_document_upload,
              disable_manual_completion,
              teams,
              apply_only_to_new,
              apply_to_existing,
              notify_after_previous_completed,
            },
          },
        }).pipe(
          map((result) => {
            try {
              if (
                result.status === apiStatus.CREATED ||
                result.status === apiStatus.SUCCESS
              ) {
                enqueueSnackbar("Successfully added task to stage", {
                  variant: "success",
                });
                return postTaskSuccess(result.response);
              }
            } catch (error) {
              console.log(
                "-----error in POST_STAGE_TEMPLATE_ATTEMPT-----",
                error
              );
            }
          }),
          catchError((error) =>
            errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
          )
        )
    )
  );

export const deleteTaskEpit = (action$) =>
  action$.pipe(
    ofType(DELETE_TASK_ATTEMPT),
    switchMap(({ payload: { taskId, councilId, enqueueSnackbar } }) =>
      httpDelete({
        call: `councils/${councilId}/task_templates/${taskId}`,
      }).pipe(
        map((result) => {
          if (result.status === apiStatus.SUCCESS) {
            enqueueSnackbar("Deleted task successfully", {
              variant: "success",
            });
            return deleteTaskSuccess({ id: taskId });
          }
          enqueueSnackbar(
            "There was an error during executing this action. Please try one more time.",
            {
              variant: "error",
            }
          );

          // return companyUnFollowSuccess({ result, data });
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const patchTaskByIdEpic = (action$) =>
  action$.pipe(
    ofType(PATCH_TASK_ATTEMPT),
    switchMap(
      ({
        payload: {
          enqueueSnackbar,
          councilId,
          name,
          description,
          stage_id,
          task_type,
          approval_task_options,
          position,
          taskId,
          days_to_complete,
          project_field_group_id,
          project_metric_attribute,
          project_field_template_id,
          assigned_approvers,
          assigned_reviewers,
          assigned_users,
          assign_to_project_owner,
          approve_in_order,
          mark_on_document_upload,
          disable_manual_completion,
          teams,
          apply_only_to_new,
          apply_to_existing,
          notify_after_previous_completed,
        },
      }) =>
        httpPut({
          call: `council_task_templates/${taskId}`,
          apiVersion: "v2",
          data: {
            council_task_template: {
              name,
              description,
              stage_id,
              position,
              task_type,
              approval_task_options,
              days_to_complete,
              project_field_group_id,
              project_metric_attribute,
              project_field_template_id,
              assigned_approvers,
              assigned_reviewers,
              assign_to_project_owner,
              approve_in_order,
              assigned_users,
              mark_on_document_upload,
              disable_manual_completion,
              teams,
              apply_only_to_new,
              apply_to_existing,
              notify_after_previous_completed,
            },
          },
        }).pipe(
          map((result) => {
            try {
              if (
                result.status === apiStatus.CREATED ||
                result.status === apiStatus.SUCCESS
              ) {
                enqueueSnackbar("Successfully edited task", {
                  variant: "success",
                });
                return patchTaskSuccess(result.response);
              }
            } catch (error) {
              console.log("-----????  catch ?????-----", error);
            }
          }),
          catchError((error) =>
            errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
          )
        )
    )
  );

export const patchTaskDocumentEpic = (action$) =>
  action$.pipe(
    ofType(POST_TASK_DOCUMENT_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, document } }) =>
      httpPost({
        call: "documents",
        data: {
          document,
        },
      }).pipe(
        map((result) => {
          try {
            if (
              result.status === apiStatus.CREATED ||
              result.status === apiStatus.SUCCESS
            ) {
              enqueueSnackbar("Successfully added document to task.", {
                variant: "success",
              });
              return postTaskDocumentSuccess(result.response);
            }
          } catch (error) {
            console.log("-----????  catch ?????-----", error);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const patchTaskDocumentName = (action$) =>
  action$.pipe(
    ofType(PATCH_TASK_DOC_NAME_ATTEMPT),
    switchMap(
      ({ payload: { enqueueSnackbar, documents, councilId, name, taskId } }) =>
        httpPut({
          call: `councils/${councilId}/task_templates/${taskId}`,
          data: {
            council_task_template: {
              name,
              documents_attributes: documents,
            },
          },
        }).pipe(
          map((result) => {
            try {
              if (
                result.status === apiStatus.CREATED ||
                result.status === apiStatus.SUCCESS
              ) {
                enqueueSnackbar("Successfully edited task", {
                  variant: "success",
                });
                return patchTaskDocNameSuccess(result.response);
              }
            } catch (error) {
              console.log("-----????  catch ?????-----", error);
            }
          }),
          catchError((error) =>
            errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
          )
        )
    )
  );

export const updateGroupEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_PROJECT_GROUP),
    switchMap(({ payload }) =>
      httpPut({
        call: `stage_templates/${payload.stageID}/project_field_groups/${payload.data.id}`,
        data: payload.data,
      }).pipe(
        map((result) => {
          if (payload.cb) {
            payload.cb();
          }

          return updateProjectGroupSuccess(result);
        }),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const addFieldToTheGroupEpic = (action$) =>
  action$.pipe(
    ofType(ADD_FIELD_TO_GROUP),
    switchMap(({ payload }) =>
      httpPost({
        call: `project_field_groups/${payload.groupId}/project_field_templates`,
        data: payload.data,
      }).pipe(
        map((result) => {
          if (payload.cb) {
            payload.cb();
          }

          return addFieldToGroupSuccess(result);
        }),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const submitProjectsGroupsEpic = (action$) =>
  action$.pipe(
    ofType(SUBMIT_PROJECT_GROUP),
    switchMap(({ payload }) =>
      httpPost({
        call: `stage_templates/${payload.stageID}/project_field_groups`,
        data: payload.data,
      }).pipe(
        map((result) => {
          if (payload.cb) {
            payload.cb();
          }

          return submitProjectGroupSuccess(result);
        }),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const getProjectsGroupsEpic = (action$) =>
  action$.pipe(
    ofType(GET_PROJECTS_GROUPS_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `stage_templates/${payload.stageID}/project_field_groups?page=${payload.page}&items=${payload.pageSize}`,
      }).pipe(
        map((result) => getProjectsGroupsAttemptSuccess(result)),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const deleteProjectWizardEpic = (action$) =>
  action$.pipe(
    ofType(DELETE_WIZARD_STEP),
    switchMap(({ payload }) =>
      httpDelete({
        call: `projects_wizard_steps/${payload.id}`,
      }).pipe(
        map((result) => deleteWizardStepSuccess(payload)),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const getProjectWizardsEpic = (action$) =>
  action$.pipe(
    ofType(GET_PROJECT_WIZARD_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `projects_wizard_setups?stage_template_id=${payload.stageID}`,
      }).pipe(
        map((result) => getProjectWizardAttemptSuccess(result)),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const getGroupFieldsEpic = (action$) =>
  action$.pipe(
    ofType(GET_GROUP_FIELDS),
    switchMap(({ payload }) =>
      httpGet({
        call: `project_field_groups/${payload.groupId}/project_field_templates`,
      }).pipe(
        map((result) => getGroupFieldsSuccess(result)),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

const deleteProjectGroup = (action$) =>
  action$.pipe(
    ofType(DELETE_PROJECT_GROUP),
    switchMap(({ payload: { enqueueSnackbar, stageID, groupId } }) =>
      httpDelete({
        call: `stage_templates/${stageID}/project_field_groups/${groupId}`,
      }).pipe(
        map(() => deleteProjectGroupSuccess(groupId)),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const getProjectsFieldsEpic = (action$) =>
  action$.pipe(
    ofType(GET_PROJECTS_FIELDS_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `project_field_templates?page=${payload.page}&items=${payload.pageSize}`,
      }).pipe(
        map((result) => getProjectsFieldsAttemptSuccess(result)),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const getProjectsStageFieldsEpic = (action$) =>
  action$.pipe(
    ofType(GET_PROJECTS_STAGE_FIELDS_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `stage_templates/${payload.stageId}?page=${payload.page}&items=${payload.pageSize}`,
      }).pipe(
        map((result) => getProjectsFieldsStageAttemptSuccess(result)),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const submitProjectField = (action$) =>
  action$.pipe(
    ofType(SUBMIT_PROJECT_FIELD),
    switchMap(({ payload: { enqueueSnackbar, data, stageID } }) =>
      httpPost({
        apiVersion: "v2",
        call: `project_field_templates?view=${
          stageID?.length ? "template" : "council"
        }${stageID?.length ? `&stage_template_id=${stageID}` : ""}`,
        data: {
          project_field_template: data,
        },
      }).pipe(
        map((result) => {
          if (data?.cb) {
            data.cb(result.response);
          }
          return submitProjectFieldSuccess({
            ...result,
            stageID: data.stageID,
          });
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

const deleteProjectField = (action$) =>
  action$.pipe(
    ofType(DELETE_PROJECT_FIELD),
    switchMap(({ payload: { enqueueSnackbar, id } }) =>
      httpDelete({
        call: `project_field_templates/${id}`,
      }).pipe(
        map((result) => deleteProjectFieldSuccess({ ...result, id })),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

const deleteProjectGroupField = (action$) =>
  action$.pipe(
    ofType(DELETE_PROJECT_GROUP_FIELD),
    switchMap(({ payload: { enqueueSnackbar, groupId, fieldId } }) =>
      httpDelete({
        call: `project_field_groups/${groupId}/project_field_templates/${fieldId}`,
      }).pipe(
        map((result) => deleteProjectGroupFieldSuccess({ groupId, fieldId })),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const updateProjectField = (action$) =>
  action$.pipe(
    ofType(UPDATE_PROJECT_FIELD),
    switchMap(({ payload: { enqueueSnackbar, data, stageID } }) =>
      httpPut({
        call: `project_field_templates/${data.id}?view=${
          stageID?.length ? "template" : "council"
        }${stageID?.length ? `&stage_template_id=${stageID}` : ""}`,
        data: {
          project_field_template: data,
        },
      }).pipe(
        map((result) => {
          if (data?.cb) {
            data.cb(result.response);
          }
          return updateProjectFieldSuccess({
            ...result,
            id: data.id,
          });
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

const deleteToggleProjectField = (action$) =>
  action$.pipe(
    ofType(DELETE_FIELD_TOGGLE),
    switchMap(({ payload: { enqueueSnackbar, stageID, field } }) =>
      httpDelete({
        call: `stage_templates/${stageID}/project_field_templates/${field.id}`,
      }).pipe(
        map(() => deleteFieldToggleSuccess(field)),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

export const activateToggleProjectField = (action$) =>
  action$.pipe(
    ofType(ACTIVATE_FIELD_TOGGLE),
    switchMap(({ payload: { enqueueSnackbar, stageID, field } }) =>
      httpPost({
        call: `stage_templates/${stageID}/project_field_templates`,
        data: {
          project_field_template_id: field.id,
        },
      }).pipe(
        map(() => activateFieldToggleSuccess(field)),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess)
        )
      )
    )
  );

const stagesEpic = combineEpics(
  getStagesTemplateEpic,
  postStageTemplateEpic,
  getStageTemplateByIdEpic,
  patchStageTemplatebyIdEpic,
  getStagesEpic,
  postStageEpic,
  deleteStageEpit,
  patchStageByIdEpic,
  postTaskEpic,
  deleteTaskEpit,
  patchTaskByIdEpic,
  deleteStageTemplateEpic,
  patchTaskDocumentEpic,
  patchTaskDocumentName,
  getProjectsFieldsEpic,
  submitProjectField,
  deleteProjectField,
  updateProjectField,
  getProjectsStageFieldsEpic,
  deleteToggleProjectField,
  activateToggleProjectField,
  getProjectsGroupsEpic,
  submitProjectsGroupsEpic,
  getGroupFieldsEpic,
  addFieldToTheGroupEpic,
  updateGroupEpic,
  deleteProjectGroup,
  deleteProjectGroupField,
  getProjectWizardsEpic,
  deleteProjectWizardEpic
);

export default stagesEpic;

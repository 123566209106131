import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  httpGet,
  errorHandler,
  httpPost,
  httpDelete,
  httpPut,
} from "../../../../../common/httpCall";
import {
  LIKE_POST_ATTEMPT,
  likePostSuccess,
  DISLIKE_POST_ATTEMPT,
  dislikePostSuccess,
  THEME_POSTS_GET_ATTEMPT,
  themesPostsGetSuccess,
  THEME_POST_EDIT_ATTEMPT,
  themesEditPostSuccess,
  THEME_POST_ADD_ATTEMPT,
  themesPostAddSuccess,
  THEME_POST_DELETE_ATTEMPT,
  themesPostDeleteSuccess,
  SELECT_POST_AS_FAVORITE_ATTEMPT,
  selectPostAsFavoriteSuccess,
  DESELECT_POST_AS_FAVORITE_ATTEMPT,
  deselectPostAsFavoriteSuccess,
  THEME_ACROSS_POSTS_GET_ATTEMPT,
  themesAcrossPostsGetSuccess,
  THEME_POSTS_MEMBERS_GET_ATTEMPT,
  themesPostMembersGetSuccess,
  THEME_POSTS_ACCROSS_MEMBERS_GET_ATTEMPT,
  themesPostMembersGetAcrossSuccess,
  SEARCH_MENTIONS_GET_ATTEMPT,
  searchMentionsGetSuccess,
} from "./postsBlock.action";

export const epicAddThemePost = (action$, state$) =>
  action$.pipe(
    ofType(THEME_POST_ADD_ATTEMPT),
    switchMap(({ payload: { themeId, data, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/${themeId}/posts`,
        data,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Post added", {
              variant: "success",
            });
          }
          return themesPostAddSuccess(result);
        }),
        catchError((err) =>
          errorHandler(
            {
              ...err,
              response: {
                ...err.response,
                message: [{ text: "Something went wrong" }],
              },
            },
            enqueueSnackbar
          )
        )
      )
    )
  );

export const epicEditThemePost = (action$, state$) =>
  action$.pipe(
    ofType(THEME_POST_EDIT_ATTEMPT),
    switchMap(({ payload: { themeId, postId, data, enqueueSnackbar } }) =>
      httpPut({
        apiVersion: "v1",
        call: `themes/${themeId}/posts/${postId}`,
        data,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Post edited", {
              variant: "success",
            });
          }
          return themesEditPostSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicDeleteThemePost = (action$, state$) =>
  action$.pipe(
    ofType(THEME_POST_DELETE_ATTEMPT),
    switchMap(({ payload: { id, postId, enqueueSnackbar } }) =>
      httpDelete({
        apiVersion: "v1",
        call: `themes/${id}/posts/${postId}`,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Post deleted with success", {
              variant: "success",
            });
          }
          return themesPostDeleteSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetThemePost = (action$, state$) =>
  action$.pipe(
    ofType(THEME_POSTS_GET_ATTEMPT),
    switchMap(({ payload: { themeId, enqueueSnackbar, filter, authorId } }) =>
      httpGet({
        apiVersion: "v1",
        call: `themes/${themeId}/posts?filter=${filter || "all"}&author_id=${
          authorId || ""
        }`,
      }).pipe(
        map((result) => themesPostsGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicLikePost = (action$, state$) =>
  action$.pipe(
    ofType(LIKE_POST_ATTEMPT),
    switchMap(({ payload: { theme_id, post_id, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/${theme_id}/posts/${post_id}/likes`,
        // data: { ...data },
      }).pipe(
        map((result) => likePostSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicDislikePost = (action$, state$) =>
  action$.pipe(
    ofType(DISLIKE_POST_ATTEMPT),
    switchMap(({ payload: { theme_id, post_id, enqueueSnackbar } }) =>
      httpDelete({
        // apiVersion: "v1",
        call: `themes/${theme_id}/posts/${post_id}/likes`,
        // data: { ...data },
      }).pipe(
        map((result) => dislikePostSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicSelectPostAsFavorite = (action$, state$) =>
  action$.pipe(
    ofType(SELECT_POST_AS_FAVORITE_ATTEMPT),
    switchMap(({ payload: { theme_id, post_id, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/${theme_id}/posts/${post_id}/favorites`,
        // data: { ...data },
      }).pipe(
        map((result) => selectPostAsFavoriteSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicDeselectPostAsFavorite = (action$, state$) =>
  action$.pipe(
    ofType(DESELECT_POST_AS_FAVORITE_ATTEMPT),
    switchMap(({ payload: { theme_id, post_id, enqueueSnackbar } }) =>
      httpDelete({
        // apiVersion: "v1",
        call: `themes/${theme_id}/posts/${post_id}/favorites`,
        // data: { ...data },
      }).pipe(
        map((result) => deselectPostAsFavoriteSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetThemesPostsAcross = (action$, state$) =>
  action$.pipe(
    ofType(THEME_ACROSS_POSTS_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, filter, authorId } }) =>
      httpGet({
        apiVersion: "v1",
        call: `themes/posts/across?filter=${filter || "all"}&author_id=${
          authorId || ""
        }`,
      }).pipe(
        map((result) => themesAcrossPostsGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetThemesPostsAuthorsByTheme = (action$, state$) =>
  action$.pipe(
    ofType(THEME_POSTS_MEMBERS_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, themeId } }) =>
      httpGet({
        apiVersion: "v1",
        call: `themes/${themeId}/posts/authors`,
      }).pipe(
        map((result) => themesPostMembersGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetThemesPostsAuthorsAcross = (action$, state$) =>
  action$.pipe(
    ofType(THEME_POSTS_ACCROSS_MEMBERS_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v1",
        call: `themes/posts/authors/across`,
      }).pipe(
        map((result) => themesPostMembersGetAcrossSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
export const epicSearchMentions = (action$, state$) =>
  action$.pipe(
    ofType(SEARCH_MENTIONS_GET_ATTEMPT),
    switchMap(({ payload: { search, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/mentions/search`,
        data: { search },
      }).pipe(
        map((result) => searchMentionsGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const themesPostsEpic = combineEpics(
  epicGetThemePost,
  epicAddThemePost,
  epicDeleteThemePost,
  epicSelectPostAsFavorite,
  epicDeselectPostAsFavorite,
  epicDislikePost,
  epicLikePost,
  epicGetThemesPostsAcross,
  epicEditThemePost,
  epicGetThemesPostsAuthorsAcross,
  epicGetThemesPostsAuthorsByTheme,
  epicSearchMentions
);

export default themesPostsEpic;

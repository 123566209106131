import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import {
  companyGetFilters,
  companyGetTopics,
  setChartsAreReRendering,
  setUpdateChartListCompanyDetails,
} from "modules/companies/companyMain/companyMain.action";
import {
  clearAddedCompetitor,
  companyGet,
  editDetails,
  selectedCompanyClear,
  getCompanyTagsAttempt,
} from "modules/companies/company/company.action";

import {
  patientOrganizationClear,
  patientOrganizationEdit,
  patientOrganizationGet,
} from "modules/patientOrganizations/patientOrganization/patientOrganization.action";
import {
  patientOrganizationGetDiseases,
  patientOrganizationGetDiversityGroups,
} from "modules/patientOrganizations/patientOrganizationMain/patientOrganizationMain.action";
import { Loading } from "modules/primitives/index";
import { useSnackbar } from "notistack";
import { ideasListGet } from "modules/ideasWrapper/idea.action";
import {
  putTractionCompaniesAttempt,
  getTractionCompanyByIdAttempt,
} from "modules/tractionAdmin/companies/tractionCompanies.actions";
import styles from "./editCompany.module.scss";
import { initModel } from "./editCompany.constants";
import useS3FileUpload from "../../../../common/hooks/s3FileUplodar.hook";
import { useModelPopup } from "../../../../common/hooks";
import { putCouncilResourceCustomTagAttempt } from "../../../../common/actions/common.action";
import CompanyRatings from "./companyRatings.component";
import {
  prepareUnits,
  prepareCustomers,
  prepareCompetitors,
  topicValues,
  industryValues,
} from "./editCompany.helper.js";
import EditCompanyHeader from "./editCompanyHeader.component";
import EditCompanyForm from "./editCompanyForm.component";

const EditCompanyComponent = (props) => {
  const [model, setModel] = useState(initModel);
  const [selectedTopics, setSelectedTopics] = useState([]);

  const [industry, setIndustry] = useState([]);
  const [loadingState, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [checked, setChecked] = useState("");
  const [geoReach, setGeoReach] = useState("");
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [prevIndustries, setPrevIndustries] = useState([]);
  const [videos, setVideos] = useState([]);
  const [prevBusiness, setPrevBusiness] = useState([]);

  const [prevTopics, setPrevTopics] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [showImageLoader, setImageLoaderVisibility] = useState(false);
  const [deletingVideo, setDeletingVideo] = useState(null);
  const [competitorOptions, setCompetitorOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [competitors, setCompetitors] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [customerQuery, setCustomerQuery] = useState("");
  const [currentLogo, setCurrentLogo] = useState("");
  const [currentCompetitorQuery, setCurrentQuery] = useState("");
  const [prevCompetitors, setPrevCompetitors] = useState([]);
  const [prevCustomers, setPrevCustomers] = useState([]);
  const [selectedTab, setSelectedTab] = useState("profile");
  const [selectedRevenue, setSelectedRevenue] = useState("");
  const [selectedPatnerType, setSelectedPatnerType] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [industrySelectedOptions, setIndustrySelectedOptions] = useState([]);
  const [copySelectedOptions, setCopySelectedOptions] = useState([]);
  const [industryCopy, setIndustryCopy] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [industrySearchValue, setIndustrySearchValue] = useState("");
  const [shouldClearSelect, setShouldClearSelect] = useState(false);
  const [isDisabledCompetitors, setDisabledCompetitors] = useState(false);
  const [relatedIdeas, setRelatedIdeas] = useState([]);
  const [businessUnitsValues, setBusinessUnitsValues] = useState([]);
  const [selectedBusinessUnitsValues, setSelectedBusinessUnitsValues] =
    useState([]);
  const [businessUnitsOptionsFirmenich, setBusinessUnitsOptionsFirmenich] =
    useState([]);
  const [selectedCustomTagsObj, setSelectedCustomTagObj] = useState([]);

  const [isCrunchbase, setIsCrunchbase] = useState(false);
  const [blockAsCB, setBlockAsCB] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: {
        selectedCompany,
        updateCompany,
        loadingCompetitors,
        companyCustomers,
        companyCompetitors,
        companyLoading,
      },
      companyMainReducer: {
        searchResults,
        topics,
        industries,
        addedCompetitor,
      },
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: {
        selectedPatientOrganization,
        updatePatientOrganization,
      },
      patientOrganizationMainReducer: { diseases },
    },
  } = useSelector((state) => state);
  const { fileDelete, fileUpload } = useS3FileUpload();
  const formRef = useRef();
  const flattenDiseaseByCat = diseases.reduce((acc, cat) => {
    const getCatChildren = cat.children_diseases.map((d) => ({
      ...d,
      cat: cat.name,
    }));
    return [...acc, ...getCatChildren];
  }, []);

  const handleOnSubmit = (values) => {
    if (!history.location?.state?.gsk) {
      values.industry_ids = industryValues(prevIndustries, industry);
      values.topic_ids = topicValues(prevTopics, selectedTopics);
    }
    values.document = checked;
    values.promo_video = videos;

    if (history.location?.state?.gsk && selectedPatientOrganization) {
      values.geographic_reach = geoReach;
    }

    const {
      web_url,
      description,
      name,
      contact_email,
      num_employees_enum,
      founded,
      location,
      promo_video,
      twitter,
      logo,
      membership_size,
      geographic_reach,
      topic_ids,
      industry_ids,
      diversity_groups_ids,
      location_city,
      location_continent,
      location_country,
      location_region,
      linkedin,
      council_relationship_stage_id,
      relationship_stage,
    } = values;

    const data = {
      web_url,
      description,
      name,
      contact_email,
      num_employees_enum,
      founded,
      location,
      promo_video,
      twitter,
      logo,
      membership_size,
      country: selectedCountry,
      disease_ids: selectedDiseases,
      geographic_reach,
      topic_ids,
      industry_ids,
      customer_ids: prepareCustomers(selectedCustomers, prevCustomers) || [],
      competitor_ids: prepareCompetitors(competitors, prevCompetitors) || [],
      diversity_group_ids: diversity_groups_ids.map((g) => g.id),
      location_city,
      location_continent,
      location_country,
      location_region,
      revenue_range: selectedRevenue || selectedCompany.revenue_range,
      partner_type: selectedPatnerType || selectedCompany.partner_type,
      business_unit_ids: prepareUnits(selectedCouncil, [
        ...selectedBusinessUnitsValues,
        ...prevBusiness,
      ]),
      ideas_related_companies: relatedIdeas || [],
      cb_profile_name: isCrunchbase ? selectedCompany.cb_profile_name : null,
      linkedin,
      council_relationship_stage_id:
        !props.location.state?.gsk && (council_relationship_stage_id || null),
      relationship_stage,
    };

    const load = {
      data: {
        taggable_type: "Company",
        taggable_id: selectedCompany.id,
        custom_tag_ids: selectedCustomTagsObj.map((el) => el.custom_tag_id),
      },
      cb: () => {},
    };

    dispatch(putCouncilResourceCustomTagAttempt(load));

    const tractionAdminData = {
      logo,
      name,
      web_url,
      promo_video,
      description,
      location_city,
      location_continent,
      location_country,
      location_region,
      num_employees_enum,
      contact_email,
      founded,
      twitter,
      linkedin,
      competitors: [...competitors, ...prevCompetitors],
      customers: [...selectedCustomers, ...prevCustomers],
      revenue_range: selectedRevenue || selectedCompany.revenue_range,
      cb_profile_name: isCrunchbase ? selectedCompany.cb_profile_name : null,
      council_relationship_stage_id,
      relationship_stage,
    };

    try {
      props.location.state?.gsk
        ? dispatch(
            patientOrganizationEdit({
              patient_organization_id: props.match.params.id,
              data,
              enqueueSnackbar,
            })
          )
        : !history?.location?.state?.fromTractionAdmin
        ? dispatch(
            editDetails({
              companyId: selectedCompany.id,
              councilId: JSON.parse(localStorage.getItem("council")).id,
              data,
              enqueueSnackbar,
            })
          )
        : dispatch(
            putTractionCompaniesAttempt({
              companyId: selectedCompany.id,
              councilId: JSON.parse(localStorage.getItem("council")).id,
              data: tractionAdminData,
              enqueueSnackbar,
            })
          );
      dispatch(clearAddedCompetitor());
      dispatch(
        setChartsAreReRendering({
          state: true,
        })
      );
      dispatch(
        setUpdateChartListCompanyDetails({
          companyId: selectedCompany.id,
          newCompanyData: {
            num_employees_enum,
            council_relationship_stage_id:
              council_relationship_stage_id || null,
            years_in_operation:
              new Date().getFullYear() - new Date(founded).getFullYear(),
          },
        })
      );

      if (props.location.state?.gsk) {
        history.push(`/patient_organizations/${props.match.params.id}`);
      } else if (history?.location?.state?.fromTractionAdmin) {
        history.goBack();
      } else {
        history.push(`/companies/${selectedCompany.slug}`);
      }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (selectedCompany.id) {
      setBlockAsCB(
        selectedCompany?.cb_profile_name &&
          history?.location?.state?.fromTractionAdmin
      );
      dispatch(
        getCompanyTagsAttempt({
          companyId: selectedCompany.id,
        })
      );
      setSelectedCustomTagObj(
        selectedCompany?.council_resource_custom_tags.map((tag) => {
          return { ...tag, id: tag.custom_tag_id };
        })
      );
    }
  }, [selectedCompany]);

  useEffect(() => {
    dispatch(patientOrganizationGetDiversityGroups({ enqueueSnackbar }));
    dispatch(
      ideasListGet({
        items: 9999,
        page: 1,
        enqueueSnackbar,
      })
    );

    if (
      props.match.params.id &&
      !props.location.state?.gsk &&
      !updateCompany &&
      JSON.parse(localStorage.getItem("council")).id
    ) {
      if (history?.location?.state?.fromTractionAdmin) {
        dispatch(
          getTractionCompanyByIdAttempt({
            companyId: props.match.params.id,
            enqueueSnackbar,
          })
        );
      } else {
        dispatch(
          companyGet({
            id: props.match.params.id,
            council_id: JSON.parse(localStorage.getItem("council")).id,
            enqueueSnackbar,
          })
        );
      }
    } else if (!updatePatientOrganization && props.location.state?.gsk) {
      dispatch(
        patientOrganizationGet({
          id: props.match.params.id,
          enqueueSnackbar,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      addedCompetitor &&
      selectedCompany.id &&
      addedCompetitor?.name &&
      addedCompetitor.id !== selectedCompany.id
    ) {
      setCompetitors((prevCompetitors) => [
        ...prevCompetitors,
        addedCompetitor,
      ]);
      setCompetitorOptions((prevCompetitors) => [
        ...prevCompetitors,
        addedCompetitor,
      ]);
    } else {
      setCompetitors((prevCompetitors) => [...prevCompetitors]);
    }
  }, [addedCompetitor]);

  useEffect(() => {
    setShouldClearSelect(false);
  }, [loadingCompetitors]);

  useEffect(() => {
    if (!prevTopics?.length) {
      setSelectedTopics([]);
    }
  }, [prevTopics]);

  useEffect(() => {
    if (!prevIndustries?.length) {
      setIndustry([]);
    }
  }, [prevIndustries]);

  useEffect(() => {
    if (companyCompetitors?.length) {
      setCompetitorOptions(companyCompetitors);
    }
  }, [companyCompetitors]);

  useEffect(() => {
    if (selectedCompany.id || selectedPatientOrganization) {
      if (selectedCompany?.cb_profile_name) {
        setIsCrunchbase(true);
      } else {
        setIsCrunchbase(false);
      }

      setCurrentLogo(
        history.location?.state?.gsk
          ? selectedPatientOrganization.logo
          : selectedCompany.logo
      );
      setBusinessUnitsValues(selectedCompany?.business_units);

      setRelatedIdeas(selectedCompany?.ideas_related_companies || []);

      const {
        name,
        description,
        employees,
        founded,
        contact_email,
        web_url,
        logo,
        location = "",
        promo_video,
        membership_size,
        geographic_reach,
        country,
        diseases: diseasesFromObj,
        twitter,
        num_employees_enum,
        diversity_groups = [],
        location_city = "",
        location_continent = "",
        location_country = "",
        location_region = "",
        linkedin = "",
        relationship_stage,
      } = props.location.state?.gsk
        ? selectedPatientOrganization
        : selectedCompany;

      const company = {
        name,
        description,
        founded: founded?.length ? format(new Date(founded), "yyyy-MM-dd") : "",
        contact_email,
        web_url,
        location,
        employees,
        membership_size,
        geographic_reach,
        country: country || "",
        diseases: diseasesFromObj ? diseasesFromObj.map((d) => d.id) : [],
        logo,
        council_relationship_stage_id:
          selectedCompany.council_relationship_stage?.name,
        twitter,
        num_employees_enum,
        diversity_groups_ids: diversity_groups,
        location_city,
        location_continent,
        location_country,
        location_region,
        linkedin,
        relationship_stage,
      };

      if (!history.location?.state?.gsk) {
        setPrevIndustries(
          selectedCompany.resource_industries || selectedCompany.industries
        );
        setPrevTopics(
          selectedCompany.resource_topics || selectedCompany.topics
        );
        setPrevBusiness(
          selectedCompany.business_units || selectedCompany.business
        );

        setPrevCompetitors(selectedCompany.competitors);
        setPrevCustomers(selectedCompany.customers);
        setVideos(
          promo_video && promo_video[0] === "[" && promo_video !== ""
            ? JSON.parse(promo_video)
            : promo_video && promo_video[0]?.length > 4
            ? promo_video
            : []
        );
      }

      if (history.location?.state?.gsk) {
        if (diseasesFromObj) {
          setSelectedDiseases(diseasesFromObj.map((d) => d.id));
        }
        setGeoReach(geographic_reach || "regional");
      }

      setModel(company);
    }
  }, [selectedCompany, selectedPatientOrganization]);

  useEffect(() => {
    if (updateCompany) {
      dispatch(
        companyGet({
          id: props.match.params.id,
          council_id: JSON.parse(localStorage.getItem("council")).id,
          enqueueSnackbar,
        })
      );
      setCustomerLoading(false);
    }
  }, [updateCompany]);

  useEffect(() => {
    if (!selectedCouncil) return;

    if (location && location.state?.gsk) {
      dispatch(
        patientOrganizationGetDiseases({
          councilId: selectedCouncil?.id,
          enqueueSnackbar,
        })
      );
    }

    return () => null;
  }, [location, selectedCouncil]);

  useEffect(() => {
    if (session) {
      dispatch(
        companyGetFilters({
          userId: session.id,
          councilId: JSON.parse(localStorage.getItem("council")).id,
          enqueueSnackbar,
        })
      );

      dispatch(
        companyGetTopics({
          userId: session.id,
          councilId: JSON.parse(localStorage.getItem("council")).id,
          enqueueSnackbar,
        })
      );
    }
  }, [session, selectedCouncil]);

  useEffect(() => {
    if (updatePatientOrganization) {
      dispatch(
        patientOrganizationGet({
          id: props.match.params.id,
          enqueueSnackbar,
        })
      );
    }
  }, [updatePatientOrganization]);

  useEffect(
    () => () => {
      props.location.state?.gsk
        ? dispatch(patientOrganizationClear())
        : dispatch(selectedCompanyClear());
    },
    []
  );

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      const filteredCompetitors = searchResults.filter((competitor) => {
        const alreadySelectedCompetitor =
          prevCompetitors &&
          prevCompetitors.find(
            (prevCompetitor) =>
              prevCompetitor.company_id === competitor.company_id ||
              competitor.company_id === selectedCompany.id
          );
        if (!alreadySelectedCompetitor) {
          return true;
        }
      });

      setCompetitorOptions(filteredCompetitors);
    }
  }, [
    searchResults,
    history.location?.state?.gsk,
    selectedCompany,
    selectedCompany.competitors,
  ]);

  useEffect(() => {
    const flatCustomersIds =
      selectedCompany?.customers
        ?.map((el) => el.id)
        .concat(selectedCustomers.map((el) => el.id)) || [];
    if (flatCustomersIds) {
      setCustomerOptions(
        companyCustomers.filter((el) => flatCustomersIds.indexOf(el.id) < 0)
      );
    }
  }, [companyCustomers, selectedCompany]);

  useEffect(() => {
    if (topics.length) {
      const flatSelected = prevTopics?.map((el) => el.topic || el);

      const topicsCopy = [...topics];
      /// temp solution before nesting view w be impleamented
      topicsCopy.forEach((tag) => {
        if (tag.children_topics[0]) {
          tag.children_topics.forEach((grandChild) => {
            if (grandChild.children_topics[0]) {
              grandChild.children_topics.forEach((lastChild) => {
                if (lastChild.children_topics && lastChild.children_topics[0]) {
                  topicsCopy.push(lastChild);
                }
                topicsCopy.push(lastChild);
              });
            }
            topicsCopy.push(grandChild);
          });
        }
      });

      const filtered = topicsCopy.filter((el) => {
        const alreadyExist = flatSelected?.find((prev) => prev?.id === el?.id);
        return !alreadyExist;
      });
      setSelectedOptions(filtered);
      setCopySelectedOptions(filtered);
    }
  }, [topics]);

  useEffect(() => {
    if (selectedCouncil?.business_units?.length) {
      const flatSelected = prevBusiness?.map((el) => el.id);

      const filtered = selectedCouncil.business_units.filter((el) => {
        const alreadyExist = flatSelected?.find((prev) => prev === el.id);
        return !alreadyExist;
      });

      setBusinessUnitsOptionsFirmenich(filtered);
    }
  }, [selectedCouncil?.business_units, prevBusiness]);
  useEffect(() => {
    if (businessUnitsOptionsFirmenich.length) {
      const filteredData = businessUnitsOptionsFirmenich.filter((el) => {
        // if no input the return the original
        if (searchValue === "") {
          return el;
        }
        // return the item which contains the user input

        return el.name?.toLowerCase().includes(searchValue.toLowerCase());
      });
      setSelectedOptions(filteredData);
    }
  }, [searchValue]);

  useEffect(() => {
    if (copySelectedOptions.length) {
      const filteredData = copySelectedOptions.filter((el) => {
        // if no input the return the original
        if (searchValue === "") {
          return el;
        }
        // return the item which contains the user input

        return el.name?.toLowerCase().includes(searchValue.toLowerCase());
      });
      setSelectedOptions(filteredData);
    }
  }, [searchValue]);

  useEffect(() => {
    if (industries.length) {
      const flatSelected = prevIndustries?.map((el) => el?.industry?.id);
      const filtered = industries.filter(
        (el) => flatSelected?.indexOf(el.id) < 0
      );

      /// temp solution before nesting view will be implemented

      filtered.forEach((tag) => {
        if (tag.children_industries[0]) {
          tag.children_industries.forEach((el) => filtered.push(el));
        }
      });

      setIndustrySelectedOptions(filtered);
      setIndustryCopy(filtered);
    }
  }, [industries]);

  useEffect(() => {
    if (industryCopy.length) {
      const filteredData = industryCopy.filter((el) => {
        // if no input the return the original
        if (industrySearchValue === "") {
          return el;
        }
        // return the item which contains the user input

        return el.name
          ?.toLowerCase()
          .includes(industrySearchValue.toLowerCase());
      });
      setIndustrySelectedOptions(filteredData);
    }
  }, [industrySearchValue, industryCopy]);

  if (companyLoading && !history.location?.state?.gsk) {
    return <Loading />;
  }
  return (
    <div className={styles.container}>
      <EditCompanyHeader
        {...props}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
      {selectedTab === "ratings" ? (
        <CompanyRatings ratingTemplate={selectedCompany?.rating_template} />
      ) : (
        <EditCompanyForm
          {...props}
          currentLogo={currentLogo}
          handleOnSubmit={handleOnSubmit}
          formRef={formRef}
          model={model}
          showImageLoader={showImageLoader}
          setCurrentLogo={setCurrentLogo}
          setImageLoaderVisibility={setImageLoaderVisibility}
          fileDelete={fileDelete}
          imgLoading={imgLoading}
          popup={popup}
          enqueueSnackbar={enqueueSnackbar}
          setImgLoading={setImgLoading}
          setLoading={setLoading}
          videos={videos}
          setVideos={setVideos}
          fileUpload={fileUpload}
          loadingState={loadingState}
          deletingVideo={deletingVideo}
          flattenDiseaseByCat={flattenDiseaseByCat}
          setSelectedDiseases={setSelectedDiseases}
          selectedDiseases={selectedDiseases}
          setGeoReach={setGeoReach}
          setSelectedCountry={setSelectedCountry}
          setRelatedIdeas={setRelatedIdeas}
          relatedIdeas={relatedIdeas}
          setSelectedPatnerType={setSelectedPatnerType}
          businessUnitsValues={businessUnitsValues}
          businessUnitsOptionsFirmenich={businessUnitsOptionsFirmenich}
          setBusinessUnitsValues={setBusinessUnitsValues}
          selectedBusinessUnitsValues={selectedBusinessUnitsValues}
          setSelectedBusinessUnitsValues={setSelectedBusinessUnitsValues}
          selectedTopics={selectedTopics}
          selectedOptions={selectedOptions}
          setDeletingVideo={setDeletingVideo}
          prevTopics={prevTopics}
          setPrevTopics={setPrevTopics}
          prevBusiness={prevBusiness}
          setPrevBusiness={setPrevBusiness}
          industry={industry}
          industrySelectedOptions={industrySelectedOptions}
          prevIndustries={prevIndustries}
          setPrevIndustries={setPrevIndustries}
          setSelectedRevenue={setSelectedRevenue}
          setCompetitors={setCompetitors}
          competitors={competitors}
          isDisabledCompetitors={isDisabledCompetitors}
          shouldClearSelect={shouldClearSelect}
          competitorOptions={competitorOptions}
          setCustomerQuery={setCustomerQuery}
          currentCompetitorQuery={currentCompetitorQuery}
          setDisabledCompetitors={setDisabledCompetitors}
          prevCompetitors={prevCompetitors}
          setPrevCompetitors={setPrevCompetitors}
          setSelectedCustomers={setSelectedCustomers}
          customerOptions={customerOptions}
          customerQuery={customerQuery}
          customerLoading={customerLoading}
          prevCustomers={prevCustomers}
          setPrevCustomers={setPrevCustomers}
          setSelectedTopics={setSelectedTopics}
          setSearchValue={setSearchValue}
          setIndustry={setIndustry}
          setIndustrySearchValue={setIndustrySearchValue}
          industrySearchValue={industrySearchValue}
          searchValue={searchValue}
          isCrunchbase={isCrunchbase}
          setIsCrunchbase={setIsCrunchbase}
          blockAsCB={blockAsCB}
          setSelectedCustomTagObj={setSelectedCustomTagObj}
          selectedCustomTagsObj={selectedCustomTagsObj}
        />
      )}
    </div>
  );
};

export default EditCompanyComponent;

import React from "react";
import styles from "./loading.module.scss";

const LoadingComponent = ({ hideString, customText, showBigSize, style }) => (
  <div className={styles.loadingWrp}>
    <div
      className={showBigSize ? styles.bigIcon : styles.icon}
      style={{ ...style }}
    />
    {hideString ? (
      <></>
    ) : (
      <div>
        {showBigSize ? (
          <h2>{customText || "Loading..."}</h2>
        ) : (
          customText || "Loading..."
        )}
      </div>
    )}
  </div>
);

export default React.memo(LoadingComponent);

import React, { useMemo } from "react";
import { format } from "date-fns";

import styles from "./companyNote.module.scss";
import { noteOptions } from "./companyNote.constant";
import { OptionSize } from "modules/primitives/moreOptions/moreOptions.constant";
import { UserInline, MoreOptions } from "../../../../primitives";

const CompanyNote = ({
  note, handleMoreOptionClick, currentUser, isLast,
}) => {
  const createMarkup = useMemo(() => {
    return { __html: note.description };
  }, [note]);

  return (
    <div className={styles.noteWrp}>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div
          className={styles.message}
          dangerouslySetInnerHTML={createMarkup}
        />
        <div className={styles.iconMore}>
          {currentUser && currentUser.id === note.added_by.id && (
            <MoreOptions
              options={noteOptions}
              className={styles.moreOptWrp}
              onClick={handleMoreOptionClick}
              size={OptionSize.SMALL}
              customSvgIcon
              customSvgIconSize="20"
            />
          )}
          {!currentUser && (
            <MoreOptions
              options={noteOptions}
              className={styles.moreOptWrp}
              onClick={handleMoreOptionClick}
              size={OptionSize.SMALL}
              customSvgIcon
              customSvgIconSize="20"
            />
          )}
        </div>
      </div>
      <div className={styles.noteinfoWrp}>
        <span className={styles.createdBy}>Created by</span>
        <div className="d-flex flex-column">
          <UserInline
            userId={note.added_by.id}
            avatar={note.added_by.avatar}
            className="boldName"
          />
          <div className="small text-muted">
            {note.created_at
              && format(new Date(note.created_at), "EEEE LLLL, d, yyyy")}
          </div>
        </div>
      </div>
      {!isLast && <hr />}
    </div>
  );
};

export default React.memo(CompanyNote);

import React, {
  useState, useRef, useCallback, useEffect,
} from "react";
import Select from "react-dropdown-select";

import styles from "../../../../../../../scss/multiselect/multiselect.module.scss";
import { UserInline, UserInlineTypes } from "../../../../../../primitives";

const OwnersMultiSelectSearch = (props) => {
  const {
    className,
    name,
    labelField,
    valueField,
    placeholder,
    onChange,
    results,
    selectedOwners = [],
    ...rest
  } = props;

  const ref = useRef();

  const [onDropChange, setOnDropChange] = useState(false);

  useEffect(() => {
    if (onDropChange) {
      setOnDropChange(false);
    }
  }, [onDropChange]);

  const dropdownRenderer = useCallback(
    ({ props, state, methods }) => {
      const regexp = new RegExp(state?.search || "", "i");

      return (
        <div className="d-flex flex-column react-dropdown-select-dropdown">
          {props.options.length
            ? props.options
              .filter((item) => regexp.test(item[props.searchBy] || item[props.labelField]))
              .map((item, idx) => {
                if (methods.isSelected(item)) {
                  return null;
                }
                const isSelected = state.values.indexOf(item) !== -1;

                return (
                  <span
                    onClick={() => (isSelected
                      ? methods.removeItem(item)
                      : methods.addItem(item))}
                    key={`${item.name}-${idx}`}
                    className={`react-dropdown-select-item ${
                      isSelected ? "react-dropdown-select-item-selected" : ""
                    }`}
                  >
                    <UserInline
                      userId={item.user_id}
                      first={item.first_name}
                      last={item.last_name}
                      avatar={item.avatar}
                      type={UserInlineTypes.WITH_NAME}
                      local
                      noNavigation
                      dontRequest
                    />
                  </span>
                );
              })
            : "No data"}
        </div>
      );
    },
    [results],
  );

  const onNewSelection = (value) => {
    const hasAllValue = value.some((v) => v.user_id === "Any");
    const hasAllDropValue = selectedOwners.some((v) => v.user_id === "Any");
    const hasOtherValues = value.length > 1;
    let newValue;

    if (hasAllValue && hasOtherValues) {
      newValue = value.filter((v) => v.user_id !== "Any");
    }

    if (hasAllValue && !hasAllDropValue) {
      newValue = value.filter((v) => v.user_id === "Any");
    }

    if (!hasAllValue && !hasAllDropValue) {
      newValue = value;
    }

    if (!hasAllValue && value.length >= 1) {
      newValue = value;
    }

    if (!newValue) {
      return;
    }

    onChange(newValue);
    setOnDropChange(true);
  };

  return (
    <div className={styles.multiWrp}>
      <div className={styles.container} ref={ref} id="ownersSearch">
        {!onDropChange && (
          <Select
            multi
            closeOnSelect
            values={selectedOwners}
            className={styles.dorpWrp}
            options={results}
            labelField={labelField}
            valueField={valueField}
            placeholder={placeholder}
            dropdownRenderer={(innerProps, innerState, innerMethods) => dropdownRenderer(innerProps, innerState, innerMethods)}
            onChange={onNewSelection}
            dropdownPosition="auto"
            {...rest}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(OwnersMultiSelectSearch);

const FREDDIE_MAC_FIELDS_SKELETON = [
  {
    className: 'd-flex',
    containsElements: 5,
    childRows: [
      {
        className: 'd-flex flex-column col-8',
        childChildRows: [
          {
            className: 'd-flex',
            sections: [
              {
                index: 0,
                className: 'col-6 mr-1',
                name: '',
                description: ''
              },
              {
                index: 1,
                className: 'col-6 ml-1',
                name: '',
                description: '',
              }
            ]
          },
          {
            className: 'd-flex mt-2',
            sections: [
              {
                index: 3,
                className: 'col-6 mr-1',
                name: '',
                description: ''
              },
              {
                index: 4,
                className: 'col-6 ml-1',
                name: '',
                description: '',
              }
            ]
          }
        ]
      },
    ],
    fifthElement: {
      index: 2,
      className: 'col-4',
      name: '',
      description: ''
    }
  },
  {
    className: 'd-flex flex-column mt-2',
    containsElements: 4,
    childRows: [
      {
        className: 'd-flex flex-row col-12 pr-2',
        sections: [
          {
            index: 5,
            className: 'col-7 pr-0 mr-1',
            name: '',
            description: ''
          },
          {
            index: 6,
            className: 'col-5 pl-0 pr-0 ml-1',
            name: '',
            description: '',
          }
        ]
      },
      {
        className: 'd-flex flex-row col-12 mt-2 pr-2',
        sections: [
          {
            index: 7,
            className: 'col-7 pr-0 mr-1 h-100',
            name: '',
            description: ''
          },
          {
            index: 8,
            className: 'col-5 pl-0 pr-0 ml-1',
            name: '',
            description: '',
          }
        ]
      }
    ],
  },
  {
    className: 'd-flex flex-column mt-2',
    containsElements: 4,
    childRows: [
      {
        className: 'd-flex flex-row col-12 pr-2',
        sections: [
          {
            index: 9,
            className: 'col-7 pr-0 mr-1',
            name: '',
            description: ''
          },
          {
            index: 10,
            className: 'col-5 pl-0 pr-0 ml-1',
            name: '',
            description: '',
          }
        ]
      },
      {
        className: 'd-flex flex-row col-12 mt-2 pr-2',
        sections: [
          {
            index: 11,
            className: 'col-7 pr-0 mr-1 h-100',
            name: '',
            description: ''
          },
          {
            index: 12,
            className: 'col-5 pl-0 pr-0 ml-1',
            name: '',
            description: '',
          }
        ]
      }
    ],
  },
  {
    className: 'd-flex flex-column mt-2',
    containsElements: 4,
    childRows: [
      {
        className: 'd-flex flex-row col-12 pr-2',
        sections: [
          {
            index: 13,
            className: 'col-7 pr-0 mr-1',
            name: '',
            description: ''
          },
          {
            index: 14,
            className: 'col-5 pl-0 pr-0 ml-1',
            name: '',
            description: '',
          }
        ]
      },
      {
        className: 'd-flex flex-row col-12 mt-2 pr-2',
        sections: [
          {
            index: 15,
            className: 'col-7 pr-0 mr-1 h-100',
            name: '',
            description: ''
          },
          {
            index: 16,
            className: 'col-5 pl-0 pr-0 ml-1',
            name: '',
            description: '',
          }
        ]
      }
    ],
  },
  {
    className: 'd-flex flex-column mt-2',
    containsElements: 4,
    childRows: [
      {
        className: 'd-flex flex-row col-12 pr-2',
        sections: [
          {
            index: 17,
            className: 'col-7 pr-0 mr-1',
            name: '',
            description: ''
          },
          {
            index: 18,
            className: 'col-5 pl-0 pr-0 ml-1',
            name: '',
            description: '',
          }
        ]
      },
      {
        className: 'd-flex flex-row col-12 mt-2 pr-2',
        sections: [
          {
            index: 19,
            className: 'col-7 pr-0 mr-1 h-100',
            name: '',
            description: ''
          },
          {
            index: 20,
            className: 'col-5 pl-0 pr-0 ml-1',
            name: '',
            description: '',
          }
        ]
      }
    ],
  },
];

const FREDDIE_MAC_FIELDS_SKELETON_1 = [
  {
    className: 'd-flex',
    containsElements: 5,
    childRows: [
      {
        className: 'd-flex flex-column col-8',
        childChildRows: [
          {
            className: 'd-flex',
            sections: [
              {
                index: 0,
                className: 'col-6 mr-1',
                name: '',
                description: ''
              },
              {
                index: 1,
                className: 'col-6 ml-1',
                name: '',
                description: '',
              }
            ]
          },
          {
            className: 'd-flex mt-2',
            sections: [
              {
                index: 3,
                className: 'col-6 mr-1',
                name: '',
                description: ''
              },
              {
                index: 4,
                className: 'col-6 ml-1',
                name: '',
                description: '',
              }
            ]
          },
          {
            className: 'd-flex mt-2',
            sections: [
              {
                index: 5,
                className: 'col-6 mr-1',
                name: '',
                description: ''
              },
              {
                index: 7,
                className: 'col-6 ml-1',
                name: '',
                description: ''
              },
            ]
          }
        ]
      },
    ],
    fifthElement: {
      index: 2,
      className: 'col-4',
      name: '',
      description: ''
    }
  },
  {
    className: 'd-flex mt-2',
    containsElements: 5,
    childRows: [
      {
        className: 'd-flex flex-column col-8',
        childChildRows: [
          {
            className: 'd-flex',
            sections: [
              {
                index: 8,
                className: 'col-6 mr-1',
                name: '',
                description: ''
              },
              {
                index: 9,
                className: 'col-6 ml-1',
                name: '',
                description: '',
              }
            ]
          },
        ]
      },
    ],
    fifthElement: {
      index: 6,
      className: 'col-4',
      name: '',
      description: ''
    }
  },
];

export const makeCustomFreddieMacData = (fields) => {
  const getFieldByIndex = (fields, index) => {
    if (index > fields.length - 1) {
      return {};
    }

    return fields[index];
  };

  return FREDDIE_MAC_FIELDS_SKELETON_1.map((element) => {
    if (element.containsElements === 5) {
      const getElementForFifthIndex = getFieldByIndex(fields, element.fifthElement.index);

      return {
        ...element,
        childRows: element.childRows.map(row => ({
          ...row,
          childChildRows: row.childChildRows.map(childRow => ({
            ...childRow,
            sections: childRow.sections.map(section => {
              const getSectionByIndex = getFieldByIndex(fields, section.index);

              return {
                ...section,
                ...getSectionByIndex,
                name: getSectionByIndex?.section_name || ''
              }
            })
          }))
        })),
        fifthElement: {
          ...element.fifthElement,
          ...getElementForFifthIndex,
          name: getElementForFifthIndex?.section_name || ''
        }
      }
    }

    return {
      ...element,
      childRows: element.childRows.map(row => {
        const sections = row.sections.map(section => {
          const getSectionByIndex = getFieldByIndex(fields, section.index);

          return {
            ...section,
            ...getSectionByIndex,
            name: getSectionByIndex?.section_name || '',
            isEmpty: Object.keys(getSectionByIndex).length === 0,
          }
        }).filter(s => !s.isEmpty);

        return {
          ...row,
          sections,
          isEmpty: !sections?.length
        }
      }).filter(r => !r?.isEmpty)
    }
  }, []).filter(element => element?.childRows?.length > 0);
}

import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useModelPopup } from "../../../common/hooks";
import AddThemeComponent from "./addTheme/addTheme.component";
import { Button, BtnType } from "modules/primitives/index";
import styles from "./adminThemes.module.scss";

const AdminThemesGroupComponent = () => {
  const popup = useModelPopup();

  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const handleAddNewTheme = () => {
    popup.show({
      title: `Add ${
        selectedCouncil?.settings?.themes_display_name || "Themes"
      } Group`,
      show: true,
      height: "300",
      width: "540",
      component: <AddThemeComponent />,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <Link to="/admin" className={styles.rootText}>
            ADMIN
          </Link>
          <span className={styles.separationBar}> / </span>

          <Link to="/admin/themes" className={styles.rootText}>
            THEMES
          </Link>

          <span className={styles.separationBar}> / </span>
          <span className={styles.activeBreadcrumbs}>GROUPS</span>
          <span className={styles.separationBar}> / </span>
          <Link to="/admin/themes/fields" className={styles.rootText}>
            FIELDS
          </Link>
        </h4>
      </div>
      <div className={styles.bodyWrp}>
        <div>
          <div className={styles.firstRow}>
            <div className={styles.buttonWrp}>
              <Button
                className={styles.addNew}
                btn={BtnType.REGULAR}
                icon="icn-add"
                onClick={handleAddNewTheme}
              >
                Add {selectedCouncil?.settings?.themes_display_name || "themes"}{" "}
                Group
              </Button>
            </div>
          </div>
        </div>
        <div>Groups</div>
      </div>
    </div>
  );
};
export default AdminThemesGroupComponent;

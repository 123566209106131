import React from "react";
import Select from "react-dropdown-select";
import {
  Label,
  DatePiker,
  TextBox,
  DropSelect,
  UserInline,
  UserInlineTypes,
  CheckBox,
  CheckType,
  EmailSelect,
} from "../../../../../primitives";

import styles from "../styles.module.scss";
import { Icon } from "../../../../../../common/icon";
import CompanyContactMultiSelectSearch from "../../../../../../common/components/companyContactsSearch";
import { gskDiscussionTopics } from "../../activity.constant";
import RichEditor from "../../../../../../common/components/richEditor";

const NewEmail = (props) => {
  const {
    selectedModel,
    orgType,
    formikprops,
    values,
    handleChange,
    setAddMoreFields,
    addMoreFields,
    setOtherAttendees,
    otherAttendees,
    tempAttendees,
    microsoftLogin,
    msftEmails,
    selectedEmail,
    setEmailData,
    setFieldValue,
    setSelectedEmail,
    setFilterContact,
    filterContact,
    emailData,
    selectedCategories,
    handlePatientOrgDiscussionSelect,
    patientOrgDiscussionSubTopics,
    selectedSubCategories,
    handleSubcategoryTopicSelect,
    initialValues,
    model,
    handleDateSelect,
    deleteAttendee,
    handleEditorState,
    company,
    selectedProj,
    setSelectedProj,
    handleClear,
    handleVisibilityChange,
    optionVisibility,
    visibility,
    setSelectedTeamOption,
    selectedTeamOption,
    userTeams,
    data,
  } = props;

  return (
    <>
      {selectedModel.name && orgType === "patient" && (
        <>
          <Label>{selectedModel.name.label}</Label>
          {setAddMoreFields(true)}
          {addMoreFields && (
            <div className={styles.otherFieldsPadding}>
              <CompanyContactMultiSelectSearch
                placeholder="Add Patient Organization Contact" // Add Call, Email GSK
                onChange={setOtherAttendees}
                otherAttendees={otherAttendees}
                tempAttendees={tempAttendees}
              />
            </div>
          )}
        </>
      )}
      {selectedModel.name &&
        orgType === "patient" &&
        microsoftLogin &&
        msftEmails.length > 0 && (
          <EmailSelect
            title="Select Email: "
            value={selectedEmail}
            onDataChange={(e) => setEmailData(e)}
            formProps={{ ...formikprops, values, setFieldValue }}
            onChange={(e) => setSelectedEmail(e)}
            options={msftEmails}
          />
        )}
      {selectedModel.name && orgType !== "patient" && (
        <>
          {microsoftLogin && msftEmails?.length > 0 && (
            <EmailSelect
              title="Select Email: "
              value={selectedEmail}
              onDataChange={(e) => setEmailData(e)}
              formProps={{ ...formikprops, values, setFieldValue }}
              onChange={(e) => setSelectedEmail(e)}
              options={msftEmails}
              otherAttendees={otherAttendees}
              setFilterContact={setFilterContact}
            />
          )}
          <Label>
            {orgType === "patient"
              ? "Role of Patient Organization Representative(s)"
              : selectedModel.name.label}
          </Label>
          {setAddMoreFields(true)}
          {selectedModel.date && (
            <>
              <Label>{selectedModel.date.label}</Label>
              <DatePiker
                name="date"
                formProps={{ ...formikprops, setFieldValue, values }}
                value={initialValues ? initialValues.date : model.date}
                onChange={handleDateSelect}
                showTime
                withClear
                emailDateValue={emailData[0]?.sentDateTime}
              />
              {(initialValues?.date || model?.date) && selectedModel.time && (
                <>
                  <Label>{selectedModel.time.label}</Label>
                  <TextBox
                    type="time"
                    name="time"
                    defaultValue={model?.event_time}
                    className={styles.textBox}
                    formProps={{ ...formikprops, values }}
                    onChange={handleChange}
                  />
                </>
              )}
            </>
          )}
          {addMoreFields && (
            <div className={styles.otherFieldsPadding}>
              <CompanyContactMultiSelectSearch
                placeholder="Add contact" // Add Call, Email
                onChange={setOtherAttendees}
                otherAttendees={otherAttendees}
                tempAttendees={tempAttendees}
                selectedValue={filterContact}
              />
            </div>
          )}
        </>
      )}
      {tempAttendees && tempAttendees[0] && (
        <>
          <div className={styles.attendeesWrp}>
            {/* <div className={styles.label}>
								Attendees
							</div> */}
            <div className={styles.listWrp}>
              {tempAttendees.map((attendee) => {
                const {
                  first_name: first,
                  last_name: last,
                  user_id: id,
                  avatar,
                } = attendee;
                return (
                  <div key={id} className={styles.itemWrp}>
                    <UserInline
                      className={styles.userIcon}
                      classObj={{ name: styles.userName }}
                      userId={id}
                      type={UserInlineTypes.WITH_NAME}
                      showMine={false}
                      first={first}
                      last={last}
                      avatar={avatar}
                      local
                      dontRequest
                    />
                    <Icon
                      size={13}
                      icon="icn-button-delete"
                      className={styles.deleteIcon}
                      onClick={() => deleteAttendee(attendee)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
      {selectedModel.subject && (
        <>
          <Label>{selectedModel.subject.label}</Label>
          <TextBox
            type="text"
            name="subject"
            placeholder={selectedModel.subject.placeholder}
            className={styles.textBox}
            formProps={{ ...formikprops, values }}
            onChange={handleChange}
            selectedValue={emailData[0]?.subject}
          />
        </>
      )}
      {JSON.parse(localStorage.getItem("council"))?.name === "Merck" && (
        <>
          {selectedModel.from_contact && (
            <>
              <Label>{selectedModel.from_contact.label}</Label>
              <TextBox
                type="text"
                name="from_contact"
                placeholder={selectedModel.from_contact.placeholder}
                className={styles.textBox}
                formProps={{ ...formikprops, values }}
                onChange={handleChange}
                selectedValue={data?.from_contact}
              />
            </>
          )}
          {selectedModel.from_email && (
            <>
              <Label>{selectedModel.from_email.label}</Label>
              <TextBox
                type="text"
                name="from_email"
                placeholder={selectedModel.from_email.placeholder}
                className={styles.textBox}
                formProps={{ ...formikprops, values }}
                onChange={handleChange}
                selectedValue={data?.from_email}
              />
            </>
          )}
          {selectedModel.to_contact && (
            <>
              <Label>{selectedModel.to_contact.label}</Label>
              <TextBox
                type="text"
                name="to_contact"
                placeholder={selectedModel.to_contact.placeholder}
                className={styles.textBox}
                formProps={{ ...formikprops, values }}
                onChange={handleChange}
                selectedValue={data?.to_contact}
              />
            </>
          )}
          {selectedModel.to_email && (
            <>
              <Label>{selectedModel.to_email.label}</Label>
              <TextBox
                type="text"
                name="to_email"
                placeholder={selectedModel.to_email.placeholder}
                className={styles.textBox}
                formProps={{ ...formikprops, values }}
                onChange={handleChange}
                selectedValue={data?.to_email}
              />
            </>
          )}
        </>
      )}
      {orgType === "patient" && selectedCategories ? (
        <div>
          <Label>Discussion Topic</Label>
          <Select
            multi
            closeOnSelect
            values={[...selectedCategories]}
            className={styles.customPatientOrgMultiselect}
            onChange={(vals) =>
              handlePatientOrgDiscussionSelect(vals, setFieldValue)
            }
            options={gskDiscussionTopics}
            labelField="name"
            valueField="name"
            placeholder="Select Discussion Topic Category"
          />
        </div>
      ) : null}
      {orgType === "patient" &&
      patientOrgDiscussionSubTopics?.length &&
      selectedSubCategories ? (
        <div>
          <Select
            multi
            closeOnSelect
            values={selectedSubCategories}
            name="discussion_topic"
            className={styles.customPatientOrgMultiselect}
            onChange={handleSubcategoryTopicSelect}
            options={patientOrgDiscussionSubTopics}
            labelField="name"
            valueField="name"
            placeholder="Select Discussion Topic"
            formProps={{ ...formikprops, values }}
          />
        </div>
      ) : null}
      {orgType === "patient" &&
      selectedCategories &&
      selectedCategories?.some((c) => c.name === "Other") ? (
        <TextBox
          type="text"
          name="discussion_topic_other"
          placeholder="Enter Discussion Topic"
          className={styles.textBox}
          formProps={{ ...formikprops, values }}
          onChange={handleChange}
        />
      ) : null}
      {orgType === "patient" && (
        <div className="mb-4">
          <Label>Summary</Label>
          <RichEditor
            updateValue={(val) => setFieldValue("description", val)}
            initialValue={values.description || ""}
            placeholder="Summary"
          />
        </div>
      )}
      {orgType !== "patient" && selectedModel.email && (
        <>
          <Label>{selectedModel.email.label}</Label>
          <TextBox
            type="text"
            name="email"
            placeholder={selectedModel.email.placeholder}
            onChange={handleChange}
            className={styles.textBox}
            formProps={{ ...formikprops, values }}
            selectedValue={emailData[0]?.from.emailAddress.address}
          />
        </>
      )}
      {orgType === "patient" && (
        <div>
          <Label>Initial Engagement</Label>
          <CheckBox
            checkType={CheckType.BLUE}
            isChecked={initialValues?.initial_introductory_meeting}
            onChange={(val) => {
              setFieldValue("initial_introductory_meeting", val);
            }}
          />
        </div>
      )}

      {selectedModel.name && orgType === "patient" && (
        <>
          <Label>Role of Patient Organization Representative(s)</Label>
          <TextBox
            type="text"
            name="name"
            placeholder="Role of Patient Organization Representative(s)"
            className={styles.textBox}
            formProps={{ ...formikprops, values }}
            onChange={handleChange}
          />
        </>
      )}
      {initialValues?.attendees && initialValues?.attendees[0] && (
        <>
          <div className={styles.attendeesWrp}>
            <div className={styles.label}>Contacts</div>
            <div className={styles.listWrp}>
              {tempAttendees.map((attendee) => {
                const {
                  first_name: first,
                  last_name: last,
                  user_id: id,
                  avatar,
                } = attendee;
                return (
                  <div key={id} className={styles.itemWrp}>
                    <UserInline
                      className={styles.userIcon}
                      classObj={{ name: styles.userName }}
                      userId={id}
                      type={UserInlineTypes.WITH_NAME}
                      showMine={false}
                      first={first}
                      last={last}
                      avatar={avatar}
                      local
                      dontRequest
                    />
                    <Icon
                      size={13}
                      icon="icn-button-delete"
                      className={styles.deleteIcon}
                      onClick={() => deleteAttendee(attendee)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
      {orgType !== "patient" && selectedModel.description && (
        <div className="mb-4">
          <Label>
            {orgType === "patient"
              ? "Summary"
              : selectedModel.description.label}
          </Label>
          <RichEditor
            updateValue={(val) => setFieldValue("description", val)}
            initialValue={values.description || ""}
            placeholder={selectedModel.description.placeholder}
            selectedValue={emailData[0]?.bodyPreview}
            emailData={emailData[0]}
            newEditorState={handleEditorState(
              setFieldValue,
              values.description
            )}
          />
        </div>
      )}
      {company && company?.projects?.length > 0 && company?.projects[0] ? (
        <>
          <Label>Related Projects (optional)</Label>
          <DropSelect
            SelecedValues={selectedProj}
            onChange={(proj) => {
              setSelectedProj(proj.val);
            }}
            placeholder="Select a project or type to search"
            labelField="text"
            valueField="value"
            searchBy="text"
            options={company?.projects.map((el) => ({
              text: el.name,
              value: el.project_id,
            }))}
          />
          <span
            className={styles.clearButton}
            onClick={() => {
              handleClear();
            }}
          >
            <span> clear</span>
          </span>
        </>
      ) : null}
      {orgType !== "patient" ? (
        <>
          <Label>Visiblity</Label>
          <DropSelect
            SelecedValues={initialValues?.visibility}
            onChange={handleVisibilityChange}
            placeholder="Please select who can see this note"
            name="for_all"
            labelField="text"
            valueField="value"
            searchBy="text"
            options={optionVisibility}
          />
        </>
      ) : null}
      {visibility === "for_teams" && (
        <>
          <Label>Select a team</Label>
          <Select
            name="team_ids"
            onChange={(selected) => {
              setFieldValue(
                "team_ids",
                selected.map(({ value }) => value)
              );
              setSelectedTeamOption(selected);
            }}
            values={selectedTeamOption}
            options={userTeams.map(({ id: value, name: label }) => ({
              label,
              value,
            }))}
            multi
            required
          />
        </>
      )}
    </>
  );
};

export default NewEmail;

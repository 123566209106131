import React, { useEffect, useMemo, useState } from "react";
import { BtnType, Button } from "modules/primitives";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getCookie } from "../../../common/helper";
import NewProjectMainModal from "modules/projects/newProjectv3/newProjectMainModal";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { useModelPopup } from "../../../common/hooks";

const NewProjectV3 = ({
  additionalData,
  isAdmin,
  blockFunctionality,
  wizardType,
  btnName,
  className,
  shouldOpen,
  handleDismiss,
  hideBtn,
  btnType = 'REGULAR',
  modalTitle
}) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const popup = useModelPopup();

  const {
    councilReducer: { selectedCouncil },
    newProjectV3Reducer: { stageTemplates },
  } = useSelector((state) => state);

  useEffect(() => {
    if (shouldOpen) {
      setOpen(shouldOpen);
    }
  }, [shouldOpen]);

  const handleAddNewTaskClick = () => {
    if (selectedCouncil.name === 'Ford' || wizardType !== 'main') {
      setOpen(true);
      return
    }

    history.push("/projects/new", { from: "/projects" });
  }

  const makeNewProjectBtnRender = useMemo(() => {
    if (!stageTemplates?.length || !selectedCouncil) {
      return <LoadingComponent hideString />
    }

    if (btnName) {
      return (
        <Button
          btn={BtnType[btnType]}
          icon="icn-add"
          onClick={handleAddNewTaskClick}
          className={className}
        >
          {btnName}
        </Button>
      )
    }

    if (selectedCouncil?.name === "Kyndryl") {
      if (!isAdmin) return null;

      return (
        <Button
          btn={BtnType[btnType]}
          icon="icn-add"
          onClick={handleAddNewTaskClick}
        >
          New Project
        </Button>
      );
    }
    if (selectedCouncil?.name === "Ford") {
      const council_role = getCookie("council_role_");

      if (
        council_role === "ttp_administrator" ||
        council_role === "council_administrator" ||
        council_role === "standard"
      ) {
        return (
          <Button
            btn={BtnType[btnType]}
            icon="icn-add"
            onClick={handleAddNewTaskClick}
          >
            Submit
          </Button>
        );
      }

      return null;
    }

    if (blockFunctionality) {
      return (
        <Tooltip
          title="You have View-Only access. To create a Project, please ask your Admin to upgrade your account to Standard access."
          placement="top"
        >
          <div>
            <Button
              btn={BtnType.DISABLED}
              icon="icn-add"
            >
              New Project
            </Button>
          </div>
        </Tooltip>
      );
    }

    return (
      <Button
        btn={BtnType[btnType]}
        icon="icn-add"
        onClick={handleAddNewTaskClick}
      >
        New Project
      </Button>
    );
  }, [stageTemplates, selectedCouncil, isAdmin, handleAddNewTaskClick, blockFunctionality]);

  if (!selectedCouncil?.id || stageTemplates?.length === 0) {
    return null;
  }

  return (
    <>
      {!hideBtn && makeNewProjectBtnRender}
      {
        open && (
          <NewProjectMainModal
            additionalData={additionalData}
            handleDismiss={() => {
              setOpen(false);
              popup.hide(); // hide another modal that could stay behind that one
              if (handleDismiss) handleDismiss();
            }}
            open
            selectedCouncil={selectedCouncil}
            wizardType={wizardType}
            modalTitle={modalTitle}
          />
        )
      }
    </>
  );
};

export default React.memo(NewProjectV3);

import React, { useState, useEffect } from "react";

import { httpGet } from "../../../../../common/httpCall";
import LessonLearnedModal from "modules/projects/project/overview/relatedProjects/lessonLearnedModal.component";
import { useModelPopup } from "../../../../../common/hooks";


const LessonLearned = ({ task }) => {
  const popup = useModelPopup();
  const [relatedProjects, setRelatedProjects] = useState([]);

  useEffect(() => {
    httpGet({
      call: `projects/${task.project_id}/related_projects`,
    }).subscribe((res) => {
      if (res.response) {
        setRelatedProjects(res.response);
      }
    });
  }, []);

  const handleShowProjectLesson = () => {
    popup.show({
      title: '',
      component: (
        <LessonLearnedModal lessonsData={relatedProjects} task={task} taskInstance />
      ),
    });
  }

  if (!relatedProjects?.length) {
    return null;
  }

  return (
    <div className="mb-1 ml-3 cursor-pointer small text-info" onClick={handleShowProjectLesson}>
      Lessons Learned √
    </div>
  );
};

export default React.memo(LessonLearned);

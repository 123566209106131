import React from "react";
import classNames from "classnames";
import { Icon, smSize } from "common/icon";

import LoadingComponent from "../../../primitives/loading/loading.component";
import styles from "../projectsMain.module.scss";

const SortingIcon = ({ sortingConfig, showLoading }) => {
  return (
    <>
      <div
        className={classNames(
          styles.sortIcon,
          sortingConfig.order === "desc" && styles.sortAsc
        )}
      >
        <Icon {...smSize} icon="icn-export-button" />
      </div>
      {showLoading && (
        <div className={styles.columnStyle}>
          <LoadingComponent hideString />
        </div>
      )}
    </>
  );
};

export default React.memo(SortingIcon);

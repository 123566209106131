import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import downloadTable from "common/components/downloadReport/generateDocHelper";

import styles from "./adminAgreements.module.scss";
import { useModelPopup } from "../../../common/hooks";
import { BtnType, Button, MoreOptions, OptionType } from "../../primitives";
import ConfirmDeleteComponent from "../../../common/components/confirmDelete";
import { getAgreementType } from "./adminAgreements.action";
import AgreementModal from "./AgreementModal";

const AdminAgreementsComponent = () => {
  const {
    councilReducer: { selectedCouncil },

    adminAgreementsReducer: { loadingAgreementType, agreementTypes, shouldUpdateAgreementType },
  } = useSelector((state) => state);

  const history = useHistory();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const { enqueueSnackbar } = useSnackbar();

  const uniqTypeValidation = (value) => {
    return !agreementTypes.find(
      (agreementType) => agreementType.name.toLowerCase().trim() === value.toLowerCase().trim()
    );
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAddAgreementType = () => {
    popup.show({
      title: "Add Agreement Type",
      show: true,
      height: "300",
      width: "540",
      component: (
        <AgreementModal
          mode="add"
          uniqTypeValidation={uniqTypeValidation}
          agreementTypesLength={agreementTypes.length}
        />
      ),
    });
  };
  useEffect(() => {
    if (selectedCouncil) {
      window.scrollTo(0, 0);
      dispatch(getAgreementType({ enqueueSnackbar }));
    }
  }, [selectedCouncil]);
  useEffect(() => {
    if (shouldUpdateAgreementType) {
      dispatch(getAgreementType({ enqueueSnackbar }));
    }
  }, [shouldUpdateAgreementType]);

  const moreOptions = [
    { val: "edit", text: "Edit" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const handleExportList = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const download = (type) => {
    downloadTable(type, {}, [], handleClose, "council_agreement_types", {
      search: {
        sort_attribute: "name",
        sort_order: "asc",
      },
    });
  };

  const handleMoreOptionClick = (event, id, agreementType) => {
    if (event === "delete") {
      popup.show({
        title: "Confirmation Delete",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={id}
            enqueueSnackbar={enqueueSnackbar}
            councilId={selectedCouncil?.id}
            actionType={
              agreementType.existing_fields > 0 ? "deleteAgreementWithRecords" : "deleteAgreement"
            }
            customData={[agreementType.existing_fields]}
          />
        ),
      });
    } else if (event === "edit") {
      popup.show({
        title: "Edit Agreement Type",
        show: true,
        height: "300",
        width: "540",
        component: (
          <AgreementModal
            mode="edit"
            agreementTypesLength={agreementTypes.length}
            uniqTypeValidation={uniqTypeValidation}
            agreementTypeOnEdit={agreementType}
          />
        ),
      });
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Agreement",
        accessor: "name",
        sortable: false,
      },

      {
        Header: "Existing records",
        accessor: "existing_fields",
        sortable: true,
        width: 200,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Position",
        accessor: "position",
        sortable: false,
        width: 100,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Options",
        id: "options",
        width: 100,
        sortable: false,
        accessor: (row) => (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row.id, row);
            }}
          />
        ),
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    []
  );

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <span
            className={styles.rootText}
            onClick={() => {
              history.push("/admin");
            }}
          >
            ADMIN
          </span>
          <span className={styles.separationBar}> / </span>
          <span className={styles.rootText}>AGREEMENTS</span>
        </h4>
      </div>
      <div className={styles.flexWrapper}>
        <div className={styles.buttonsRow}>
          <div className={styles.addAgreementButtonContainer}>
            <Button
              btn={BtnType.REGULAR}
              icon="icn-add"
              onClick={() => {
                handleAddAgreementType();
              }}
              className={styles.addAgreementBtn}
            >
              Add agreement
            </Button>
            <Button
              onClick={handleExportList}
              btn={BtnType.OUTLINE}
              className={styles.exportAgreementBtn}
            >
              EXPORT
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => download("csv")}>DOWNLOAD CSV</MenuItem>
              <MenuItem onClick={() => download("xlsx")}>DOWNLOAD EXCEL</MenuItem>
              <MenuItem onClick={() => download("pdf")}>DOWNLOAD PDF</MenuItem>
            </Menu>

            <span className={styles.totalCount}>
              {agreementTypes?.length ? agreementTypes.length : "0"} Agreements
            </span>
          </div>
        </div>
        <ReactTable
          className={styles.table}
          data={agreementTypes}
          columns={columns}
          loading={loadingAgreementType}
          showPagination={false}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                style: {
                  cursor: "pointer",
                },
              };
            }
            return {};
          }}
        />
      </div>
    </div>
  );
};
export default AdminAgreementsComponent;

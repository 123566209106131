import React, { useState, useRef, useMemo } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import styles from "../loggedUser/loggedUser.module.scss";
import { useOnClickOutside } from "../../../common/hooks";

const pathByOption = {
  companies: "/companies",
  lists: "/list/following",
  events: "/events",
  people: "/people/internal/all",
  reports: "/reporting",
  admin: "/admin",
};

const fordItems = [
  { val: "companies", text: "Companies" },
  { val: "lists", text: "Lists" },
  { val: "people", text: "People" },
  { val: "events", text: "Events" },
  { val: "reports", text: "Reports" },
  { val: "admin", text: "Admin" },
];

const noneFordItems = [
  { val: "people", text: "People" },
  { val: "events", text: "Events" },
  { val: "reports", text: "Reports" },
  { val: "admin", text: "Admin" },
];

const HamburgerMenu = ({ role, councilTag, selectedCouncilSettings }) => {
  const ref = useRef();
  const [isModalOpen, setModalOpen] = useState(false);

  const userMenuOptions = useMemo(() => {
    return councilTag === "ford" ? fordItems : noneFordItems;
  }, [councilTag, fordItems, noneFordItems]);
  useOnClickOutside(ref, () => setModalOpen(false));

  const history = useHistory();

  const handleOptionClick = (e, val) => {
    history.push(pathByOption[val]);
    setModalOpen(false);
  };

  const handleFordHamburgerClick = (e) => {
    e.stopPropagation();
    setModalOpen((prev) => !prev);
  };

  if (
    (councilTag === "kyndryl" && role === "viewer") ||
    councilTag === "home_x" ||
    councilTag === "gsk"
  ) {
    return <></>;
  }

  return (
    <div
      className={classNames("hideInLap", styles.hambuger)}
      onClick={(e) => handleFordHamburgerClick(e)}
    >
      <div className={styles.moreWrp}>
        {isModalOpen && (
          <div ref={ref} className={classNames(styles.optionWrp)}>
            {userMenuOptions.map(({ val, className, text }) => {
              if (
                val === "admin" &&
                !["ttp_administrator", "council_administrator"].includes(role)
              ) {
                return <></>;
              }
              if (val === "reports") {
                const acceptedRoles = ["ttp_administrator"];
                if (selectedCouncilSettings?.reports_allowed_roles) {
                  acceptedRoles.push(
                    ...selectedCouncilSettings?.reports_allowed_roles
                  );
                }
                if (!acceptedRoles.includes(role)) {
                  return <></>;
                }
              }
              if (
                !selectedCouncilSettings.events_connected &&
                val === "events"
              ) {
                return <></>;
              }
              return (
                <div
                  key={val}
                  className={classNames(styles.opt, className && className)}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOptionClick(e, val);
                  }}
                >
                  {text}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(HamburgerMenu);

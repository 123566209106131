import React, { useEffect, useState } from "react";
import { Container, Draggable } from "react-smooth-dnd";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import { isProjectAllowedEditor } from "common/checkers/isProjectAllowedEditor";
import { getCookie } from "common/helper";
import styles from "./stage.module.scss";
import StageCompaniesComponent from "../stageCompanies";
import {
  projectStageObjMove,
  projectStageCompaniesGet,
  projectStageCompanyUpdate,
} from "../stages.action";
import { Icon, mdSize, lgSize } from "../../../../../common/icon";
import { useModelPopup } from "../../../../../common/hooks";
import EditStageComponent from "../editStage";
import StageProjectWide from "../stageProjectWide/stageProjectWide";
import { checkViewerRole } from "common/checkers/viewerChecker";
import MixedAddCompanyOrProducts from "../../../../../common/components/mixedSearch/mixedAddCompanyOrProducts";
import {
  projectCompanyNewMultiple,
  projectPatientOrgNewMultiple,
} from "modules/projects/project/companies/companies.action";

const StageComponent = ({ stage, stageWideTasks = [] }) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const {
    authReducer: { session },
    projectsReducer: {
      projectReducer: { selectedProject, projectIsDownloading },
      stagesReducer: { companyList_stageId, stagesList, shouldUpdate },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [dataList, setDataList] = useState([]);
  const [patientOrg, setPatientOrg] = useState(false);
  const [page, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [isKyndryl, setIsKyndryl] = useState(false);
  const [reqTimeout, setReqTimeout] = useState(0);
  const [disabledReq, setDisabledReq] = useState(false);
  const [currentUserProjRole, setCurrentUserProjRole] = useState(false);
  const councilRole = getCookie("council_role_");

  useEffect(() => {
    if (selectedCouncil && selectedProject) {
      if (selectedProject?.id) {
        const currentUserRole = selectedProject?.project_members?.find(
          (m) => m.user.id === session?.id
        );
        setCurrentUserProjRole(currentUserRole?.user_role);
      }

      const isSpecificTemplate =
        selectedProject?.stage_template?.name === "Kyndryl Innovation" ||
        selectedProject?.stage_template?.name === "Kyndryl Alliance";

      setIsKyndryl(selectedCouncil.name === "Kyndryl" && isSpecificTemplate);
    }

    return () => null;
  }, [selectedCouncil, selectedProject]);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    const isPatientOrg =
      selectedProject &&
      selectedProject.stage_template &&
      selectedProject.stage_template.patient_organization;

    setPatientOrg(isPatientOrg);
  }, [selectedProject]);

  useEffect(() => {
    if (selectedProject) {
      getMoreData(1);
    }
  }, [patientOrg]);

  useEffect(() => {
    if (companyList_stageId && companyList_stageId[stage.id]) {
      const list = companyList_stageId[stage.id].sort((a, b) =>
        a.position > b.position ? 1 : -1
      );
      setDataList(list);
    }
  }, [
    shouldUpdate,
    companyList_stageId[stage.id],
    companyList_stageId[stage.id]?.length,
  ]);

  const handleEditClick = () => {
    const canBeRemoved = !dataList?.length && !stageWideTasks?.length;

    popup.show({
      title: "Edit Stage",
      component: (
        <EditStageComponent
          stagesList={stagesList}
          stage={stage}
          canBeRemoved={canBeRemoved}
        />
      ),
    });
  };

  const handleAddCallback = (selected) => {
    if (selected.length > 0) {
      if (patientOrg) {
        const multiPatientOrgPayload = {
          project_patient_organizations: selected.map((s) => ({
            project_id: selectedProject?.id,
            gsk_patient_organization_id: s.id,
            added_by_id: session.id,
            stage_id: stage.id,
          })),
          project_id: selectedProject?.id,
          council_id: selectedCouncil.id,
          patientOrg: true,
          stage_id: stage.id,
          enqueueSnackbar,
        };

        dispatch(projectPatientOrgNewMultiple(multiPatientOrgPayload));
      } else {
        const multiCompaniesPayload = {
          project_companies: selected.map((s) => {
            if (s.is_product) {
              return {
                project_id: selectedProject.id,
                council_product_id: s.id,
                added_by_id: session.id,
                stage_id: stage.id,
                is_product: true,
              };
            }
            return {
              project_id: selectedProject.id,
              company_id: s.company_id,
              added_by_id: session.id,
              stage_id: stage.id,
            };
          }),
          project_id: selectedProject.id,
          council_id: selectedCouncil.id,
          patientOrg: false,
          stage_id: stage.id,
          enqueueSnackbar,
        };

        dispatch(projectCompanyNewMultiple(multiCompaniesPayload));
      }

      popup.hide();
    }
  };

  const handleAddCompanyClick = () => {
    if (stage.locked) {
      return;
    }

    popup.show({
      title: `Add ${
        patientOrg && patientOrg ? "an Organization" : "a Company"
      } to Project`,
      component: (
        <MixedAddCompanyOrProducts
          patientOrg={patientOrg}
          handleSave={handleAddCallback}
          placeholder="Type to search or select from below"
          label={patientOrg ? "Organizations" : "Companies or Products"}
          existingItems={dataList}
          // hideProducts
        />
      ),
    });
  };

  const handleDragDrop = (e, stageId) => {
    const { addedIndex } = e;
    if (addedIndex || addedIndex === 0) {
      const removeObj = JSON.parse(localStorage.getItem("movedStageObj"));
      const addObj = { index: addedIndex, stageId };
      dispatch(projectStageObjMove({ removeObj, addObj }));
      const movedCompany = removeObj.item;
      movedCompany.psid = addObj.stageId;
      let org;
      let other;

      if (removeObj.item.is_product) {
        org = {
          id: removeObj.item.company.id,
          productId: removeObj.item.id,
        };
        other = {
          productId: org.productId,
        };
      } else {
        if (removeObj.item.company) {
          org = removeObj.item.company;
        } else {
          org = removeObj.item.gsk_patient_organization;
        }

        other = {
          companyId: org.id,
          gsk_patient_organization_id: org.id,
        };
      }

      const obj = {
        ...other,
        isProduct: removeObj.item.is_product,
        projectId: selectedProject.id,
        stageId: addObj.stageId,
        gsk: patientOrg,
        patient_org: patientOrg,
        userId: session.id,
        position: addedIndex + 1,
        enqueueSnackbar,
      };

      dispatch(projectStageCompanyUpdate(obj));
    }
  };

  const handleDragReady = (e, stageId) => {
    const { removedIndex } = e;
    if (removedIndex || removedIndex === 0) {
      const item = dataList[removedIndex];
      const removeObj = { index: removedIndex, stageId, item };
      localStorage.setItem("movedStageObj", JSON.stringify(removeObj));
    }
  };

  const setOrganization = (org) => {
    if (org && org.gsk_patient_organization) {
      const po = org.gsk_patient_organization;
      po.normalized_gsk_score = org.normalized_gsk_score;
      return po;
    } else {
      if (org.is_product) {
        return org;
      }
      return org.company;
    }
  };

  const handleCompanyRemove = (c) => {
    if (patientOrg) {
      setDataList(
        dataList.filter(
          ({ gsk_patient_organization }) => c.id !== gsk_patient_organization.id
        )
      );
      return;
    }

    setDataList(dataList.filter(({ company }) => c.id !== company.id));
  };

  const loadMore = () => {
    setPage(page + 1);
    getMoreData(page + 1);
  };

  const getMoreData = (page) => {
    dispatch(
      projectStageCompaniesGet({
        projectId: selectedProject.id,
        stageId: stage.id,
        gsk: patientOrg,
        patient_org: patientOrg,
        page,
        enqueueSnackbar,
        cb: (res) => {
          if (res.page !== 0 && !res.response?.length && dataList?.length) {
            setDisabledReq(true);
            return;
          }

          setDisabledReq(false);

          const timeout = setTimeout(() => {
            setReqTimeout(0);
            clearTimeout(timeout);
          }, 1000);
        },
      })
    );
  };

  const handleScroll = (evt) => {
    if (reqTimeout > 0 || disabledReq) {
      if (disabledReq) {
        setReqTimeout(0);
      }

      return;
    }

    const { scrollTop, offsetHeight, scrollHeight } = evt.target;

    if (scrollTop + offsetHeight >= scrollHeight - 150) {
      setReqTimeout(1);
      loadMore();
    }
  };

  return (
    <div
      className={classNames(
        stage.locked ? styles.lockedStage : "",
        styles.stageWrp,
        projectIsDownloading ? "mb-3" : ""
      )}
    >
      <div className={styles.stageCon}>
        <div className={styles.titleWrp}>
          <div className={classNames("caption", styles.title)}>
            {stage.name}
          </div>
          {isProjectAllowedEditor(councilRole, currentUserProjRole) && (
            <div className={styles.editButton}>
              {!blockFunctionality && !stage.locked && (
                <Icon
                  {...mdSize}
                  icon="icn-edit"
                  className={styles.icon}
                  onClick={handleEditClick}
                />
              )}
            </div>
          )}
        </div>
        <div className={styles.addNewWrp}>
          {!blockFunctionality ? (
            <Icon
              {...lgSize}
              icon="icn-add"
              className={styles.icon}
              onClick={(e) => {
                handleAddCompanyClick(e);
              }}
            />
          ) : (
            <Tooltip
              title={
                "You have View-Only access. To edit this Project, please ask your Admin to upgrade your account to Standard access and invite you to this Project."
              }
              placement={"top"}
            >
              <div>
                <Icon {...lgSize} icon="icn-add" className={styles.icon} />
              </div>
            </Tooltip>
          )}
        </div>
        <div className={styles.bodyWrp} onScroll={handleScroll}>
          {stageWideTasks?.length ? (
            <div className={styles.projectWideTasks}>
              {stageWideTasks.map((t) => (
                <StageProjectWide
                  key={t.id}
                  task={t}
                  patientOrg={patientOrg}
                  stage={stage}
                />
              ))}
            </div>
          ) : null}
          <Container
            dropPlaceholder={{ className: styles.dropPlaceholder }}
            onDrop={(e) => {
              handleDragDrop(e, stage.id);
            }}
            onDropReady={(e) => {
              handleDragReady(e, stage.id);
            }}
            groupName="col"
          >
            {dataList && dataList.length > 0 ? (
              dataList.map((data, index) => (
                <Draggable
                  key={`${stage.id}_${data.id}_${index}`}
                  className={styles.drgContainer}
                >
                  <StageCompaniesComponent
                    company={setOrganization(data)}
                    stage={stage}
                    patientOrg={patientOrg}
                    handleCompanyRemove={handleCompanyRemove}
                    isKyndryl={isKyndryl}
                  />
                </Draggable>
              ))
            ) : (
              <Draggable className={styles.drgContainer} />
            )}
          </Container>
        </div>
        {reqTimeout > 0 ? (
          <div className={styles.loadMore}>Loading more data...</div>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(StageComponent);

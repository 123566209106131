import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";

import styles from "./chooseImg.module.scss";
import useS3FileUpload from "../../../../../common/hooks/s3FileUplodar.hook";
import { Icon, smSize } from "../../../../../common/icon";
import LinearProgressWithLabel from "../../../../../common/components/linearProgressWithLabel";

const ChooseImg = (props) => {
  const { uploadDirName, onChange, image } = props;
  const inputRef = useRef(null);
  const uploadBlockRef = useRef(null);
  const [img, setImg] = useState(null);
  const { fileUpload, fileDelete } = useS3FileUpload();
  const [uploadValues, setUploadValues] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const allowedExtensions = [
    "png",
    "jpg",
    "jpeg",
    "gif",
    "tiff",
    "bmp",
    "eps",
    "raw",
    "cr2",
    "nef",
    "orf",
    "sr2",
  ];

  const getExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  useEffect(() => {
    setImg(image);
  }, [image]);

  const onUploadClick = () => {
    inputRef.current.click();
  };

  const removeImg = async () => {
    const url = "";
    await fileDelete(url).then((res) => {
      setImg(null);
      onChange(null);
    });
  };

  const uploadHandler = async (e, action) => {
    e.preventDefault();
    // we want to let user know that he is dragging, so we change the border color for the block
    if (action === "drag" && uploadBlockRef?.current) {
      uploadBlockRef.current.style.border = "1px solid #7b8794";
      return;
    }

    let file;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      file = e.target.files[0];
    }

    if (action === "drop") {
      // on drop we just go to previous border styles
      uploadBlockRef.current.style.border = "1px solid #e4e7eb";

      if (!e || !e.dataTransfer.items.length) {
        return;
      }

      file = e.dataTransfer.items[0].getAsFile();
    }

    if (!file) {
      return;
    }
    if (
      file.size <= 100000000
      && allowedExtensions.indexOf(getExtension(file.name)) !== -1
    ) {
      setLoading(true);
      await fileUpload(file, uploadDirName, setUploadValues, file.name, 0).then(
        (res) => {
          if (res && res.location) {
            setLoading(false);
            setImg(res.location);
            onChange(res.location);
            setUploadValues([]);
          }
        },
      );
    } else {
      if (allowedExtensions.indexOf(getExtension(file.name)) === -1) {
        enqueueSnackbar("Extenstion is not allowed", {
          variant: "error",
        });
      }
      if (file.size > 100000000) {
        enqueueSnackbar(
          "We could not upload your image because your file size is too big. Please make sure the file is less than 20 MB",
          {
            variant: "error",
          },
        );
      }
      setLoading(false);
      e.target.value = "";
    }
  };

  return (
    <div className={styles.uploadImgWrp}>
      <input
        ref={inputRef}
        type="file"
        id="imgUpload"
        style={{ display: "none" }}
        onChange={(e) => uploadHandler(e, "click")}
      />
      {loading ? (
        <LinearProgressWithLabel
          value={uploadValues[0]?.progress || 0}
        />
      ) : (
        <div className={styles.uploadBlockWrpCenter}>
          <div
            ref={uploadBlockRef}
            className={styles.uploadBlock}
            onClick={onUploadClick}
            onDrop={(e) => uploadHandler(e, "drop")}
            onDragOver={(e) => uploadHandler(e, "drag")}
          >
            <span>Choose Image</span>
          </div>
          {img && (
            <span className={styles.imgChooser} onClick={removeImg}>
              <Icon
                {...smSize}
                icon="icn-button-delete"
                className={styles.deleteIcon}
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(ChooseImg);

import React from "react";
import Select from "react-dropdown-select";
import {
  Label,
  DatePiker,
  TextBox,
  DropSelect,
  UserInline,
  UserInlineTypes,
  CheckBox,
  CheckType,
} from "../../../../../primitives";
import { initModel } from "../constant";
import styles from "../styles.module.scss";
import { Icon } from "../../../../../../common/icon";
import CompanyContactMultiSelectSearch from "../../../../../../common/components/companyContactsSearch";
import { gskDiscussionTopics } from "../../activity.constant";
import RichEditor from "../../../../../../common/components/richEditor";
import UserMultiSelectSearch from "../../../../../../common/components/usersSearch";

const NewEvent = (props) => {
  const {
    selectedModel,
    orgType,
    data,
    formikprops,
    values,
    handleChange,
    addMoreFields,
    setOtherAttendees,
    otherAttendees,
    tempAttendees,
    setFieldValue,
    emailData,
    selectedCategories,
    handlePatientOrgDiscussionSelect,
    patientOrgDiscussionSubTopics,
    selectedSubCategories,
    handleSubcategoryTopicSelect,
    initialValues,
    model,
    handleDateSelect,
    deleteAttendee,
    usersList,
    setSelectedItem,
    handleAddMoreFieldsClick,
    handleEditorState,
    company,
    selectedProj,
    setSelectedProj,
    handleClear,
    handleVisibilityChange,
    optionVisibility,
    visibility,
    setSelectedTeamOption,
    selectedTeamOption,
    userTeams,
  } = props;

  return (
    <>
      {selectedModel.title && (
        <>
          <Label>{selectedModel.title.label}</Label>
          <TextBox
            type="text"
            name="title"
            placeholder={selectedModel.title.placeholder}
            className={styles.textBox}
            formProps={{ ...formikprops, values }}
            onChange={handleChange}
          />
        </>
      )}
      {orgType === "patient" && selectedCategories ? (
        <div>
          <Label>Discussion Topic</Label>
          <Select
            multi
            closeOnSelect
            values={[...selectedCategories]}
            className={styles.customPatientOrgMultiselect}
            onChange={(vals) => handlePatientOrgDiscussionSelect(vals, setFieldValue)}
            options={gskDiscussionTopics}
            labelField="name"
            valueField="name"
            placeholder="Select Discussion Topic Category"
          />
        </div>
      ) : null}
      {orgType === "patient"
      && patientOrgDiscussionSubTopics?.length
      && selectedSubCategories ? (
        <div>
          <Select
            multi
            closeOnSelect
            values={selectedSubCategories}
            name="discussion_topic"
            className={styles.customPatientOrgMultiselect}
            onChange={handleSubcategoryTopicSelect}
            options={patientOrgDiscussionSubTopics}
            labelField="name"
            valueField="name"
            placeholder="Select Discussion Topic"
            formProps={{ ...formikprops, values }}
          />
        </div>
        ) : null}
      {orgType === "patient"
      && selectedCategories
      && selectedCategories?.some((c) => c.name === "Other") ? (
        <TextBox
          type="text"
          name="discussion_topic_other"
          placeholder="Enter Discussion Topic"
          className={styles.textBox}
          formProps={{ ...formikprops, values }}
          onChange={handleChange}
        />
        ) : null}
      {orgType === "patient" && (
        <div className="mb-4">
          <Label>Summary</Label>
          <RichEditor
            updateValue={(val) => setFieldValue("description", val)}
            initialValue={values.description || ""}
            placeholder="Summary"
          />
        </div>
      )}
      {orgType === "patient" && (
        <div>
          <Label>Initial Engagement</Label>
          <CheckBox
            checkType={CheckType.BLUE}
            isChecked={data ? data.initial_introductory_meeting : false}
            onChange={(val) => {
              setFieldValue("initial_introductory_meeting", val);
            }}
          />
        </div>
      )}
      {selectedModel.date && (
        <>
          <Label>{selectedModel.date.label}</Label>
          <DatePiker
            name="date"
            formProps={{ ...formikprops, setFieldValue, values }}
            value={initialValues ? initialValues.date : model.date}
            onChange={handleDateSelect}
            showTime
            withClear
            emailDateValue={emailData[0]?.sentDateTime}
          />
        </>
      )}
      {selectedModel.time && (
        <>
          <Label>{selectedModel.time.label}</Label>
          <TextBox
            type="time"
            name="time"
            defaultValue={data ? data.event_time : "08:00"}
            className={styles.textBox}
            formProps={{ ...formikprops, values }}
            onChange={handleChange}
          />
        </>
      )}
      {selectedModel.location && (
        <>
          <Label>{selectedModel.location.label}</Label>
          <TextBox
            type="text"
            name="location"
            placeholder={selectedModel.location.placeholder}
            className={styles.textBox}
            formProps={{ ...formikprops, values }}
            onChange={handleChange}
          />
        </>
      )}
      {data && data.attendees && data.attendees[0] && (
        <>
          <div className={styles.attendeesWrp}>
            <div className={styles.label}>Attendees</div>
            <div className={styles.listWrp}>
              {tempAttendees.map((attendee) => {
                const {
                  first_name: first,
                  last_name: last,
                  user_id: id,
                  avatar,
                } = attendee;
                return (
                  <div key={id} className={styles.itemWrp}>
                    <UserInline
                      className={styles.userIcon}
                      classObj={{ name: styles.userName }}
                      userId={id}
                      type={UserInlineTypes.WITH_NAME}
                      showMine={false}
                      first={first}
                      last={last}
                      avatar={avatar}
                      local
                      dontRequest
                    />
                    <Icon
                      size={13}
                      icon="icn-button-delete"
                      className={styles.deleteIcon}
                      onClick={() => deleteAttendee(attendee)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
      {orgType !== "patient"
        && selectedModel.attendees
        && usersList
        && usersList[0] && (
          <>
            <Label>{selectedModel.attendees.label}</Label>
            <UserMultiSelectSearch
              placeholder="Add member"
              labelField="full_name"
              valueField="id"
              searchBy="full_name"
              onChange={setSelectedItem}
              filterBy={tempAttendees}
            />
            <span className={styles.addFieldsButton}>
              <span onClick={handleAddMoreFieldsClick}>
                {addMoreFields ? "-" : "+ Add Other Attendees"}
              </span>
            </span>
            {addMoreFields && (
              <div className={styles.otherFieldsPadding}>
                <CompanyContactMultiSelectSearch
                  placeholder="Add contact" // Add Event
                  onChange={setOtherAttendees}
                  otherAttendees={otherAttendees}
                  tempAttendees={tempAttendees}
                />
              </div>
            )}
          </>
      )}
      {orgType === "patient"
        && selectedModel.attendees
        && usersList
        && usersList[0] && (
          <>
            <Label>{selectedModel.attendees.label}</Label>
            <UserMultiSelectSearch
              placeholder="Add member"
              labelField="full_name"
              valueField="id"
              searchBy="full_name"
              onChange={setSelectedItem}
              filterBy={tempAttendees}
            />
            <span className={styles.addFieldsButton}>
              <span onClick={handleAddMoreFieldsClick}>
                {addMoreFields ? "-" : "+ Add Other Attendees"}
              </span>
            </span>
            {addMoreFields && (
              <div className={styles.otherFieldsPadding}>
                <CompanyContactMultiSelectSearch
                  placeholder="Add contact" // Add Event, Meeting GSK
                  onChange={setOtherAttendees}
                  otherAttendees={otherAttendees}
                  tempAttendees={tempAttendees}
                />
              </div>
            )}
          </>
      )}
      {orgType === "patient" && (
        <>
          <Label>Role of Key Patient Organization Attendees</Label>
          <TextBox
            type="text"
            name="roles_of_attendees"
            placeholder="Role of Key Patient Organization Attendees"
            className={styles.textBox}
            formProps={{ ...formikprops, values }}
            onChange={handleChange}
          />
        </>
      )}
      {orgType !== "patient" && selectedModel.description && (
        <div className="mb-4">
          <Label>
            {orgType === "patient"
              ? "Summary"
              : selectedModel.description.label}
          </Label>
          <RichEditor
            updateValue={(val) => setFieldValue("description", val)}
            initialValue={values.description || ""}
            placeholder={selectedModel.description.placeholder}
            selectedValue={emailData[0]?.bodyPreview}
            emailData={emailData[0]}
            newEditorState={handleEditorState(
              setFieldValue,
              values.description,
            )}
          />
        </div>
      )}
      {company && company?.projects?.length > 0 && company?.projects[0] ? (
        <>
          <Label>Related Projects (optional)</Label>
          <DropSelect
            SelecedValues={selectedProj}
            onChange={(proj) => {
              setSelectedProj(proj.val);
            }}
            placeholder="Select a project or type to search"
            labelField="text"
            valueField="value"
            searchBy="text"
            options={company?.projects.map((el) => ({
              text: el.name,
              value: el.project_id,
            }))}
          />
          <span
            className={styles.clearButton}
            onClick={() => {
              handleClear();
            }}
          >
            <span> clear</span>
          </span>
        </>
      ) : null}
      {orgType !== "patient" ? (
        <>
          <Label>Visiblity</Label>
          <DropSelect
            SelecedValues={data ? data.visibility : initModel.visibility}
            onChange={handleVisibilityChange}
            placeholder="Please select who can see this note"
            name="for_all"
            labelField="text"
            valueField="value"
            searchBy="text"
            options={optionVisibility}
          />
        </>
      ) : null}
      {visibility === "for_teams" && (
        <>
          <Label>Select a team</Label>
          <Select
            name="team_ids"
            onChange={(selected) => {
              setFieldValue(
                "team_ids",
                selected.map(({ value }) => value),
              );
              setSelectedTeamOption(selected);
            }}
            values={selectedTeamOption}
            options={userTeams.map(({ id: value, name: label }) => ({
              label,
              value,
            }))}
            multi
            required
          />
        </>
      )}
    </>
  );
};

export default NewEvent;

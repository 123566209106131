import React, { memo, useCallback, useEffect, useState } from "react";
import classnames from 'classnames';
import { useHistory } from "react-router-dom";

import styles from "./search.module.scss";
import { httpPost, pageMeta } from "../../../common/httpCall";
import LoadingComponent from "modules/primitives/loading/loading.component";

const FordSearch = ({ className }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [results, setResults] = useState([]);
  const [paginationData, setPaginationData] = useState(null);

  const history = useHistory();

  const handleGetNewData = (query, page = 1, memoize) => {
    if (query?.length === 0) {
      setResults([])
      return;
    }

    setIsFetching(true);

    httpPost({
      apiVersion: 'v2',
      call: `projects/search?items=100&page=${page}`,
      data: {
        search: {
          query,
        }
      }
    }).subscribe(res => {
      const requestMeta = pageMeta(res);

      setPaginationData(requestMeta);

      if (memoize) {
        setResults([...results, ...res.response || []]);
      } else {
        setResults(res.response || []);
      }
      setIsFetching(false);
    })
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleGetNewData(searchQuery);
      clearTimeout(timeout);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchQuery]);

  const handleOnChange = (e) => {
    const query = e.currentTarget.value;

    setSearchQuery(query);
    setSearchActive(query?.length > 0);
  };

  const handleResultOnBlur = () => {
    setSearchActive(false);
  };
  const handleOnKeyDown = () => {};

  const handleProjectSelect = (project) => {
    history.push(`/projects/${project.id}`);
  }

  const handleLoadMore = useCallback(() => {
    handleGetNewData(searchQuery, (paginationData.page + 1) || 1, true);
  }, [paginationData, searchQuery]);

  return (
    <div
      className="position-relative"
      tabIndex="0"
      onBlur={(e) => {
        const { currentTarget } = e;
        // blur happens before click, preventing any click events in children from firing due to rerender from state change
        // so wait a tick for child component events to fire before changing open state and causing rerender
        window.setTimeout(() => {
          if (!currentTarget.contains(document.activeElement)) {
            handleResultOnBlur();
          }
        }, 100);
      }}
    >
      <div className={classnames(className, styles.searchWrp)}>
        <input
          value={searchQuery}
          onFocus={() => setSearchActive(true)}
          className={styles.searchText}
          placeholder="Search Projects"
          onKeyDown={handleOnKeyDown}
          onChange={handleOnChange}
        />
      </div>
      {
        searchActive && (
          <div className={styles.searchResultWrp}>
            {
              isFetching && !results?.length ? (
                <div className="d-flex justify-content-center">
                  <LoadingComponent customText="Searching projects..." />
                </div>
              ) : (
                <>
                  {
                    results?.length ? (
                      <>
                        {
                          paginationData?.total && (
                            <div className="d-flex justify-content-end pr-3 font-weight-bold mb-2">Total: {paginationData?.total}</div>
                          )
                        }
                        {
                          results.map((project) => (
                            <div
                              key={project.id}
                              className={styles.itemContentWraper}
                              onClick={() => handleProjectSelect(project)}
                            >
                              <span>{project.name}</span>
                              <span>PROJECT</span>
                            </div>
                          ))
                        }
                        {
                          paginationData?.pages > 1 && paginationData.page < paginationData?.pages ? (
                            <>
                              {
                                isFetching ? (
                                  <div className="d-flex justify-content-center">
                                    <LoadingComponent customText="Loading more projects..." />
                                  </div>
                                ) : (
                                  <div
                                    className="cursor-pointer d-flex font-weight-bold justify-content-center mt-2"
                                    onClick={() => handleLoadMore()}
                                  >Load more</div>
                                )
                              }
                            </>
                          ) : null
                        }
                      </>
                    ) : (
                      <div className="d-flex justify-content-center">
                        {searchQuery.length === 0 ? 'Type to search Projects' : 'No projects found!'}
                      </div>
                    )
                  }
                </>
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default memo(FordSearch);

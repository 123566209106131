import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import Icon from "common/icon/icon.component";
import { smSize } from "common/icon/icon.constants";
import { isCreator } from "common/checkers/isCreator";
import { useSnackbar } from "notistack";
import { companyAgreementShouldOpen } from "modules/companies/company/company.action";
import { getCustomFieldAgreementType } from "modules/admin/adminCustomFieldsGroups/adminCustomField.action";

import { MoreOptions, Loading, CheckBox, CheckType } from "modules/primitives";
import { convertDateTimezone, shouldDownload } from "common/helper";
import { ACTIVITY_OPTIONS } from "../../../constants";
import styles from "./styles.module.scss";
import UploadedByInfo from "../../uploadedByInfo";

const AgreementComponent = ({
  item,
  handleEditClick,
  blockFunctionality,
  handleDeleteClick,
}) => {
  const agreementRef = useRef(null);
  const {
    companiesReducer: {
      companyReducer: {
        customFieldLoadingId,
        shouldOpenUnusedAgreement,
        selectedCompany,
      },
    },
    councilReducer: { selectedCouncil },
    patientOrganizationsReducer: {
      patientOrganizationReducer: {
        selectedPatientOrganization,
        customFieldLoadingIdOrg,
      },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);

  const [showField, setShowField] = useState(false);
  const [loadingId, setLoadingId] = useState("");
  const [fieldId, setFieldId] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const onCheckBoxClick = (el) => {
    const agreementType = selectedCouncil.agreement_types?.filter(
      (type) => type.name === el
    )[0];

    if (!agreementType) {
      return false;
    }

    dispatch(
      companyAgreementShouldOpen({
        type: agreementType.name,
        id: agreementType.id,
        mode: "edit",
        item,
      })
    );
  };

  useEffect(() => {
    dispatch(getCustomFieldAgreementType({ enqueueSnackbar }));
  }, []);

  useEffect(() => {
    if (isCreator(item?.creator?.user_id)) {
      setHasAccessToEdit(true);
    }
  }, [item]);

  useEffect(() => {
    if (customFieldLoadingId || customFieldLoadingIdOrg) {
      if (selectedPatientOrganization?.id) {
        setLoadingId(customFieldLoadingIdOrg);
      } else {
        setLoadingId(customFieldLoadingId);
      }
    }
  }, [
    customFieldLoadingId,
    customFieldLoadingIdOrg,
    selectedPatientOrganization,
  ]);

  useEffect(() => {
    if (selectedPatientOrganization?.id) {
      setFieldId(item.patient_organization_custom_field_id);
    } else {
      setFieldId(item.company_custom_field);
    }
  }, [item, selectedPatientOrganization]);

  useEffect(() => {
    if (
      item.option_selected[0] &&
      shouldOpenUnusedAgreement.type === item.option_selected[0]
    ) {
      window.scrollTo({
        left: 0,
        top: agreementRef.current.offsetTop,
        behaviour: "smooth",
      });
    }
  }, [shouldOpenUnusedAgreement.type]);

  const getFilename = (url) => {
    if (url && typeof url === "string") {
      return url.split("/").pop();
    }
    return "";
  };

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }

    if (value === 1) {
      handleDeleteClick(item);
    }
  };

  return (
    <div ref={agreementRef} className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{item?.field_name || "Agreement"}</h5>
          {item?.field_placeholder?.length ? (
            <span className="text-muted small">{item.field_placeholder}</span>
          ) : null}
        </div>
        <div className={styles.rightWrp}>
          <div className={styles.colMore}>
            {!blockFunctionality && hasAccessToEdit ? (
              <MoreOptions
                options={ACTIVITY_OPTIONS}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            ) : null}
          </div>
        </div>
      </div>
      {item.from_claimed_profile && (
        <span className="d-block small font-weight-bold mb-3">
          Company Response
        </span>
      )}
      {fieldId === loadingId ? (
        <Loading />
      ) : showField ? null : (
        <div className={classNames("small", styles.description)}>
          <span className={styles.checkAgreementBox}>
            <CheckBox
              checkType={CheckType.BLUE}
              isChecked={item.agreement_type_selected}
              onClick={() => {
                onCheckBoxClick(
                  item.agreement_type?.name,
                  selectedCompany.agreement_tag
                );
              }}
            />
            <span>{item.agreement_type?.name}</span>
          </span>
        </div>
      )}

      {item?.documents &&
        item?.documents.map((doc) => (
          <div
            key={doc.company_custom_field}
            className={classNames(
              styles.documnetContainer,
              doc.rfi ? styles.fromRfi : ""
            )}
          >
            <Icon
              {...smSize}
              icon="icn-subnav-documents"
              className={styles.tileIcon}
            />
            <div className="d-flex align-items-center w-50">{doc.name}</div>
            <UploadedByInfo document={doc} />
            <div className={styles.rightWrp}>
              <a className={styles.docView} target="blank" href={doc.uri}>
                {shouldDownload(getFilename(doc.uri)) ? "download" : "view"}
              </a>
            </div>
          </div>
        ))}

      <div className={styles.dateWrp}>
        {item.document_date && (
          <div className={styles.dateField}>
            <span className={styles.label}>{`${"Signed on:"} `}</span>
            <span className={styles.marginL10px}> </span>
            <div className={styles.label}>
              {format(
                convertDateTimezone(item?.document_date),
                "EEEE, MMMM dd, yyyy"
              )}
            </div>
          </div>
        )}
        {item.expiration_date && (
          <div className={styles.dateField}>
            <span className={styles.label}>Expires on: </span>
            <span className={styles.marginL10px}> </span>
            <div className={styles.label}>
              {format(
                convertDateTimezone(item.expiration_date),
                "EEEE, MMMM dd, yyyy "
              )}
            </div>
          </div>
        )}
      </div>

      {item && item.creator ? (
        <div className={styles.infoWrp}>
          <div className={styles.dataRow}>
            <div className={styles.label}>
              {item.from_submission
                ? "Submitted by"
                : item.updated_at !== item.created_at
                ? "Updated by"
                : "Created by"}
            </div>
            <div className={styles.value}>
              {item.from_submission ? (
                <span>{item?.creator?.full_name || "-"}</span>
              ) : (
                <Link to={`/people/internal/profile/${item.user_id}`}>
                  {item && item.creator && item.creator.full_name}
                  {item.from_claimed_profile && (
                    <span>, {selectedCompany?.name}</span>
                  )}
                </Link>
              )}
            </div>
          </div>
          {item.from_claimed_profile && (
            <div className="d-flex align-items-center mr-5">
              <div className={styles.companyAccess}>
                <span>&#x2713; Access</span>
              </div>
            </div>
          )}
          <div className={styles.date}>
            {item.updated_at !== item.created_at
              ? format(new Date(item.updated_at), "eeee, MMM do, yyyy hh:mm a")
              : format(new Date(item.created_at), "eeee, MMM do, yyyy hh:mm a")}
          </div>
          {item.from_submission ? (
            <div
              className={`d-flex flex-grow-1 align-items-center justify-content-center ${styles.companyResponse}`}
            >
              Company Response
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
export default React.memo(AgreementComponent);

import { useDispatch, useSelector } from "react-redux";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import { Formik } from "formik";

import { getCookie } from "common/helper";
import { ModalBody, ModalFooter } from "application/modal";
import {
  BtnType,
  Button,
} from "modules/primitives";
import { useModelPopup } from "../../../common/hooks";
import styles from "modules/projects/project/manageProject/details/details.module.scss";
import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import { getPlantByType } from "../../actions/common.action";
import PlantMultiSelect from "../plantMultiselect";
import PlantSelectWithOptions from "./plantSelectWithOptions";
import { httpGet, httpPut } from "../../httpCall";
import LoadingComponent from "modules/primitives/loading/loading.component";
import SpocPlantsWithAreas from "./spocPlants";
import NewProjectV3 from "modules/projects/newProjectv3";

const ForPossibleReplicationsModal = ({
  handleSave,
  instance,
  replicationsData,
  data,
  filterBy = [],
  combinedPlants,
  showAllReplicatedMsg,
}) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef(null);

  const [options, setOptions] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [shouldOpenAddNewProjectModal, setShouldOpenAddNewProjectModal] = useState(false);
  const [dataForNewProjectModal, setDataForNewProjectModal] = useState({});
  const [spocPlants, setSpocPlants] = useState([]);

  const getProjects = useCallback((query) => {
    httpGet({
      call: "projects/related_projects/dropdown",
    }).subscribe((res) => {
      if (res?.response?.length) {
        const newResponse = res.response.filter((p) => p.id !== data.id);
        setOptions(newResponse);
      } else {
        setOptions([]);
      }
    });
  }, []);

  useEffect(() => {
    getProjects("");
  }, []);

  useEffect(() => {
    const council_role = getCookie("council_role_");
    setIsAdmin(
      council_role === "ttp_administrator" ||
        council_role === "council_administrator"
    );
    return () => null;
  }, []);

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    ideaReducer: { ideaPlant },
    adminPlantsAndAreasReducer: { plantsAndAreas },
  } = useSelector((state) => state);

  const [model, setModel] = useState({});

  useEffect(() => {
    if (!plantsAndAreas.length && selectedCouncil?.name === "Ford") {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        })
      );
    }
  }, [plantsAndAreas, selectedCouncil]);

  useEffect(() => {
    if (!ideaPlant) {
      dispatch(
        getPlantByType({
          resourceId: data?.id,
          enqueueSnackbar,
          type: instance,
        })
      );
    }
  }, []);

  const getSpocPlants = useMemo(() => {
    return replicationsData.filter((plant) => plant.current_user_plant);
  }, [replicationsData]);

  const getPossiblePlantsFromSource = useCallback(
    (data) => {
      return plantsAndAreas
        .filter((p) => data.some((psPlant) => psPlant.id === p.id))
        .map((p) => ({ ...p, ...p.attributes }));
    },
    [plantsAndAreas]
  );

  useEffect(() => {
    if (
      !isAdmin &&
      (session.spoc || session.innovation_leader) &&
      getSpocPlants?.length
    ) {
      const newSpocs = getSpocPlants.map(plant => ({
        ...plant,
        state: !plant.created_at ? 'possible_replication' : plant.state,
        areas:
          plant.areas?.map((a) => ({
            ...a,
            area_id: a.area_id || a.id,
            state: a.state,
          })) || [],
      }));

      setSpocPlants(newSpocs);
    }
  }, [session, isAdmin, getSpocPlants]);

  useEffect(() => {
    if (replicationsData && plantsAndAreas?.length) {
      const otherPropsById =
        replicationsData.reduce((acc, elem) => {
          return {
            ...acc,
            [elem.id]: {
              selectedAreas:
                elem.areas?.map((a) => ({
                  ...a,
                  area_id: a.id || a.area_id,
                  area_name: a.name,
                })) || [],
              otherOptions: elem.state || elem.status,
              project: elem.project,
              reason: elem.reason,
            },
          };
        }, {}) || {};
      const selectedPlants = getPossiblePlantsFromSource(replicationsData);

      const newModel = {
        selectedPlant1: selectedPlants,
        originalPlants: selectedPlants,
        ...otherPropsById,
      };

      setModel(newModel);
    }
  }, [replicationsData, plantsAndAreas]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const getPlantsAsOptions = useMemo(() => {
    const plantsArray = plantsAndAreas.map((plant) => ({
      id: plant.id,
      name: plant.attributes.name,
      areas: plant.attributes.areas,
    }));

    if (combinedPlants?.length) {
      return plantsArray
        .map((p) => {
          const getPlantFromCombinedArray = combinedPlants.filter(
            (plant) => plant.id === p.id
          );

          if (getPlantFromCombinedArray.length) {
            const getAllAreas = getPlantFromCombinedArray.reduce((acc, p) => [...acc, ...p.areas], []);

            if (!getAllAreas?.length) {
              return {
                ...p,
                plantHasBeenReplicatedWhenNoAreas: true,
              };
            }

            const getAllFlatAreaIds = getAllAreas.map((a) => a.area_id);
            const allAreasAreReplicated =
              p.areas.filter(
                (area) =>
                  !getAllFlatAreaIds.some((areaId) => areaId === area.area_id)
              ).length === 0;

            return {
              ...p,
              allPlantAreasHaveBeenReplicated: allAreasAreReplicated,
            };
          }

          return p;
        });
    }

    if (data?.replications?.length || data?.original_project?.plant) {
      const replicationIds = data?.replications.map((p) => p.plant?.id);
      const areaIdsFromOriginalProj = data?.original_project?.plant?.areas
        ? data?.original_project?.plant.areas.map((a) => a.id || a.area_id)
        : [];

      return plantsArray
        .filter(
          (p) =>
            !replicationIds.includes(p.id) &&
            data?.original_project?.plant?.id !== p.id
        )
        .map((p) => {
          if (!areaIdsFromOriginalProj.length) return p;

          return {
            ...p,
            areas: p.areas.filter(
              (a) => !areaIdsFromOriginalProj.includes(a.area_id)
            ),
          };
        });
    }

    return plantsArray;
  }, [plantsAndAreas, data, combinedPlants, replicationsData]);

  const handleSubmit = () => {
    setShowProgress(true);
    const values = formRef.current.values;
    let possible_replications = [];

    if ((session.spoc || session.innovation_leader) && spocPlants?.length && !isAdmin) {
      possible_replications = spocPlants
        .filter(plant => {
          if (!plant.areas?.length) return plant.state?.length > 0;
          return true;
        })
        .map((plant) => {
          const filteredAreas = plant.areas
            .filter(a => a.state?.length > 0)
            .map((a) => ({
              ...a,
              project_id: a.project?.id,
              state: a.state || 'possible_replication',
            }));

          return {
            ...plant,
            areas: filteredAreas,
            plant_id: plant.id,
            project_id: plant.project?.id,
          };
        });
    } else {
      let data = values.selectedPlant1 || [];

      // some kind of detect delete plants
      if (values.selectedPlant1.length < values.originalPlants.length) {
        data = values.originalPlants.map((plant) => {
          const getPlant = values.selectedPlant1.find((p) => p.id === plant.id);

          if (getPlant) {
            return getPlant;
          }

          return {
            ...plant,
            discarded_at: new Date(),
            _destroy: true,
          };
        });
      }

      possible_replications = data
        .filter(
          (plant) =>
            !plant.plantHasBeenReplicatedWhenNoAreas &&
            !plant.allPlantAreasHaveBeenReplicated
        )
        .map((plant) => {
          const getPlantSelectedValues = values[plant.id];

          return {
            ...plant,
            discarded_at: plant.discarded_at,
            plant_id: plant.id,
            plant_name: plant.name || plant.plant_name,
            state: getPlantSelectedValues.otherOptions,
            project_id: getPlantSelectedValues.project?.id,
            reason: getPlantSelectedValues.reason,
            areas:
              getPlantSelectedValues.selectedAreas?.map((a) => ({
                area_id: a.area_id || a.id,
                state: a.state || a.status || null,
                project_id: a.project?.id,
                reason: a.reason,
              })) || [],
          };
        });

      const filteredReplications = replicationsData.filter(p => !p.current_user_plant);
      const allPlantIds = [...filteredReplications, ...possible_replications].map(p => p.id);

      possible_replications = [...new Set(allPlantIds)].map(id => {
        const getPlantObjFromOriginal = filteredReplications.find(p => p.id === id);
        const getPlantObjFromValues = possible_replications.find(p => p.id === id);

        if (getPlantObjFromValues._destroy) {
          return getPlantObjFromValues;
        }

        let areas = getPlantObjFromValues.areas;

        if (getPlantObjFromOriginal?.originalAreas?.length > 0 && getPlantObjFromOriginal?.originalAreas?.length !== getPlantObjFromValues.areas.length) {
          const allAreasIds = [...getPlantObjFromOriginal.originalAreas, ...getPlantObjFromValues.areas].map(a => a.area_id);
          areas = [...new Set(allAreasIds)]
            .map(id => {
              const getAreaObjFromOriginal = getPlantObjFromOriginal.originalAreas.find(a => a.area_id === id);
              const getAreaObjFromValues = getPlantObjFromValues.areas.find(a => a.area_id === id);

              if (getAreaObjFromOriginal && !getAreaObjFromValues) {
                return {
                  ...getAreaObjFromOriginal,
                  _destroy: true
                }
              }

              return getAreaObjFromValues
            })
        }

        return {
          ...getPlantObjFromValues,
          areas,
        }
      })
    }

    const payload = {
      resource_id: data.id,
      resource_type: instance,
      possible_replications,
    };

    httpPut({
      call: `possible_replications/plants_and_areas/batch_update`,
      data: payload,
    }).subscribe((res) => {
      enqueueSnackbar("Successfully updated possible replications", {
        variant: "success",
      });
      popup.hide();
      setShowProgress(false);
      handleSave(res.response);
    });
  };

  const makeSaveButton = useCallback(() => {
    if (isAdmin) {
      const filteredPlants =
        formRef.current?.values?.selectedPlant1
          ?.filter((plant) => plant.areas.length)
          .map((p) => p.id) || [];
      const isBtnDisabled = Object.keys(formRef.current?.values || {})
        .filter((key) => filteredPlants.includes(key))
        .map((key) => formRef.current?.values[key])
        .some((elem) => !elem?.selectedAreas?.length);

      return (
        <Button
          disabled={isBtnDisabled}
          type="submit"
          btn={BtnType.REGULAR}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      );
    }

    if ((session.spoc || session.innovation_leader) && getSpocPlants?.length) {
      const filteredPlants = spocPlants
        .filter((plant) => plant.areas.length)
        .map((p) => p.id) || [];
      const isBtnDisabled = Object.keys(formRef.current?.values || {})
        .filter((key) => filteredPlants.includes(key))
        .map((key) => formRef.current?.values[key])
        .some((elem) => !elem?.selectedAreas?.length);

      return (
        <Button
          disabled={isBtnDisabled}
          type="submit"
          btn={BtnType.REGULAR}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      );
    }

    return null;
  }, [session, handleSubmit, formRef.current?.values, spocPlants]);

  const handleUnderReview = (plant, area) => {
    const getPlantSource = plantsAndAreas.find(p => p.id === plant.id);
    const areas = getPlantSource?.attributes?.areas || plant.areas || [];
    const plantData = {
      selected_plant: {
        ...plant,
        areas: areas,
        selectedAreas: area ? [{
          ...area,
          area_name: area.area_name || area.name,
        }] : []
      }
    };

    setDataForNewProjectModal(plantData);

    const timeout = setTimeout(() => {
      setShouldOpenAddNewProjectModal(true);
      clearTimeout(timeout);
    }, 100);
  }

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={model}
        enableReinitialize
        onSubmit={() => {}}
      >
        {(formikprops) => (
          <>
            <ModalBody>
              <div className={styles.formContainer}>
                {isAdmin && (
                  <div>
                    <PlantMultiSelect
                      formikprops={formikprops}
                      plants={getPlantsAsOptions}
                      hideAreasSelect
                    />
                    <div className="mt-3">
                      {formikprops.values.selectedPlant1?.map(
                        (plant, index) => (
                          <PlantSelectWithOptions
                            key={plant.id}
                            plant={plant}
                            formikprops={formikprops}
                            index={index + 1}
                            projectsList={options}
                            filterBy={filterBy}
                            handleUnderReview={handleUnderReview}
                          />
                        )
                      )}
                    </div>
                  </div>
                )}
                {!isAdmin && (session.spoc || session.innovation_leader) && (
                  <>
                    {
                      showAllReplicatedMsg ? (
                        <span>Your Plants + Areas are already Replicated</span>
                      ) : (
                        <SpocPlantsWithAreas
                          formikprops={formikprops}
                          handleUnderReview={handleUnderReview}
                          options={options}
                          existingSpocPlants={spocPlants}
                          setSpocPlants={setSpocPlants}
                        />
                      )
                    }
                  </>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              {showProgress ? (
                <LoadingComponent customText="Saving data..." />
              ) : (
                <>
                  <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                    Cancel
                  </Button>
                  {makeSaveButton()}
                </>
              )}
            </ModalFooter>
          </>
        )}
      </Formik>
      <NewProjectV3
        isAdmin={isAdmin}
        wizardType="replicate"
        additionalData={{
          ...data,
          replicateData: {
            ...dataForNewProjectModal,
          },
        }}
        modalTitle="Replicate Project"
        shouldOpen={shouldOpenAddNewProjectModal}
        handleDismiss={() => setShouldOpenAddNewProjectModal(false)}
        hideBtn
      />
    </>
  );
};
export default ForPossibleReplicationsModal;

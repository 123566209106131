import { ofType, combineEpics } from "redux-observable";
import {
  switchMap, map, catchError, mergeMap,
} from "rxjs/operators";
import { of, empty } from "rxjs";

import { handleErrorSuccess } from "modules/companies/companyMain/companyMain.action";
import {
  apiStatus,
  errorHandler,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPut,
} from "../../../common/httpCall";

import {
  PATIENT_ORGANIZATION_GET_ATTEMPT,
  patientOrganizationGetSuccess,
  PATIENT_ORGANIZATION_DETAILS_GET_ATTEMPT,
  patientOrganizationDetailsGetSuccess,
  addContactSuccess,
  addRelationShipSuccess,
  PATIENT_ORGANIZATION_ADD_CONTACT_ATTEMPT,
  PATIENT_ORGANIZATION_ADD_RELATIONSHIP_ATTEMPT,
  PATIENT_ORGANIZATION_DELETE_CONTACT_ATTEMPT,
  PATIENT_ORGANIZATION_DELETE_RELATIONSHIP_ATTEMPT,
  PATIENT_ORGANIZATION_EDIT_CONTACT_ATTEMPT,
  deleteContactSuccess,
  deleteRelationSuccess,
  POST_CUSTOM_FIELDS,
  addCustomFieldsSuccess,
  PATIENT_ORGANIZATION_PUT_DETAILS_FIELDS_ATTEMPT,
  patientOrganizationPutDetailsFieldsSuccess,
  PATIENT_ORGANIZATION_DELETE_DETAILS_FIELDS_ATTEMPT,
  patientOrganizationDeleteDetailsFieldsSuccess,
  PATIENT_ORGANIZATION_EDIT_ATTEMPT,
  patientOrganizationEditSuccess,
  GET_PATIENT_ORGANIZATION_GROUP_FIELDS,
  getPatientOrganizationGroupFieldsSuccess,
  GSK_POST_CUSTOM_FIELDS,
  PATIENT_ORGANIZATION_SHARE_ATTEMPT,
  patientOrganizationShareSuccess,
  patientOrganizationProjectAddManySuccess,
  PATIENT_ORGANIZATION_ADD_MANY_TO_PROJECT_ATTEMPT,
  editOrganizationContactSuccess,
  PATIENT_ORGANIZATION_EDIT_RELATIONSHIP_ATTEMPT,
  resetUpdatePatientOrgProp,
  errorHandleSuccess,
} from "./patientOrganization.action";

export const epicGetPatientOrganization = (action$, state$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_GET_ATTEMPT),
  switchMap(({
    payload: {
      id, items, page, enqueueSnackbar,
    },
  }) => httpGet({
    call: `gsk/patient_organizations/${id}?items=${items || 20}&page=${
      page || 1
    }`,
  }).pipe(
    map((result) => patientOrganizationGetSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetPatientOrganizationDetails = (action$, state$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_DETAILS_GET_ATTEMPT),
  switchMap(
    ({
      payload: {
        patientOrganizationId, items, page, enqueueSnackbar, cb,
      },
    }) => httpGet({
      call: `gsk/patient_organizations/${patientOrganizationId}/custom_fields?items=${items}&page=${page}`,
    }).pipe(
      map((result) => {
        if (cb) {
          cb();
        }
        return patientOrganizationDetailsGetSuccess(result)
      }),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

export const epicEditRelationship = (action$, state$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_EDIT_RELATIONSHIP_ATTEMPT),
  switchMap(
    ({
      payload: {
        patientOrgId, currentUserId, newUserId, enqueueSnackbar,
      },
    }) => httpPatch({
      call: `gsk/patient_organizations/${patientOrgId}/relationship_owners/${currentUserId}`,
      data: {
        user_id: newUserId,
      },
    }).pipe(
      mergeMap((result) => of(resetUpdatePatientOrgProp(), addRelationShipSuccess(result))),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

export const epicPostRelationship = (action$, state$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_ADD_RELATIONSHIP_ATTEMPT),
  switchMap(
    ({
      payload: {
        council_id, patient_organization_id, data, enqueueSnackbar,
      },
    }) => httpPost({
      call: `gsk/patient_organizations/${patient_organization_id}/relationship_owners?council_id=${council_id}`,
      data,
    }).pipe(
      map((result) => addRelationShipSuccess(result)),
      catchError((err) => errorHandler(err, enqueueSnackbar, [], errorHandleSuccess)),
    ),
  ),
);

export const epicDeleteRelationship = (action$, state$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_DELETE_RELATIONSHIP_ATTEMPT),
  switchMap(
    ({
      payload: {
        patient_organization_relationship_owner_id,
        patient_organization_id,
        data,
        enqueueSnackbar,
      },
    }) => httpDelete({
      call: `gsk/patient_organizations/${patient_organization_id}/relationship_owners/${patient_organization_relationship_owner_id}`,
      data,
    }).pipe(
      map((result) => deleteRelationSuccess(result)),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

//

export const epicPostCompanyContact = (action$, state$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_ADD_CONTACT_ATTEMPT),
  switchMap(
    ({
      payload: {
        council_id, patient_organization_id, data, enqueueSnackbar,
      },
    }) => httpPost({
      call: "gsk/patient_organizations/contacts",
      data,
    }).pipe(
      map((result) => addContactSuccess(result)),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

export const epicDeleteCompanyContact = (action$, state$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_DELETE_CONTACT_ATTEMPT),
  switchMap(
    ({ payload: { patient_organization_contact_id, enqueueSnackbar } }) => httpDelete({
      call: `gsk/patient_organizations/contacts/${patient_organization_contact_id}`,
    }).pipe(
      map((result) => {
        if (result.status === apiStatus.SUCCESS) {
          enqueueSnackbar("Contact succesfully deleted.", {
            variant: "success",
          });
        }

        return deleteContactSuccess(result);
      }),
      catchError((e) => errorHandler(e, enqueueSnackbar)),
    ),
  ),
);

export const epicPutCompanyContact = (action$, state$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_EDIT_CONTACT_ATTEMPT),
  switchMap(
    ({
      payload: { data, patient_organization_contact_id, enqueueSnackbar },
    }) => httpPatch({
      call: `gsk/patient_organizations/contacts/${patient_organization_contact_id}`,
      data,
    }).pipe(
      map((result) => {
        if (result.status === apiStatus.SUCCESS) {
          enqueueSnackbar("Contact succesfully edited.", {
            variant: "success",
          });
        }

        return editOrganizationContactSuccess(result);
      }),
      catchError((e) => errorHandler(e, enqueueSnackbar, [], errorHandleSuccess)),
    ),
  ),
);

export const epicGetPatientOrganizationDetailsGroupFields = (action$, state$) => action$.pipe(
  ofType(GET_PATIENT_ORGANIZATION_GROUP_FIELDS),
  switchMap(({ payload: { id, enqueueSnackbar } }) => httpGet({
    call: `gsk/custom_field_groups?council_id=${id}`,
  }).pipe(
    map((result) => getPatientOrganizationGroupFieldsSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicPostCustomFields = (action$, state$) => action$.pipe(
  ofType(GSK_POST_CUSTOM_FIELDS),
  switchMap(
    ({
      payload: {
        council_id,
        patient_organization_id,
        data,
        selectedCheck,
        enqueueSnackbar,
      },
    }) => httpPost({
      call: `gsk/patient_organizations/${patient_organization_id}/custom_fields?council_id=${council_id}${
        selectedCheck
          ? `&checked=${selectedCheck.checked}&field_text=${selectedCheck.value}`
          : ""
      }`,
      data,
    }).pipe(
      map((result) => {
        if (result.status === apiStatus.CREATED) {
          enqueueSnackbar("Custom field succesfully added.", {
            variant: "success",
          });
        }
        return addCustomFieldsSuccess(result);
      }),
      catchError((e) => errorHandler(e, enqueueSnackbar)),
    ),
  ),
);

export const epicPutPatientOrganizationDetailsFields = (action$, state$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_PUT_DETAILS_FIELDS_ATTEMPT),
  switchMap(
    ({
      payload: {
        patient_organization_id,
        council_id,
        patient_organization_custom_field_id,
        data,
        selectedCheck,
        enqueueSnackbar,
      },
    }) => httpPut({
      call: `gsk/patient_organizations/${patient_organization_id}/custom_fields/${patient_organization_custom_field_id}?council_id=${council_id}${
        selectedCheck
          ? `&checked=${selectedCheck.checked}&field_text=${selectedCheck.value}`
          : ""
      }`,
      data,
    }).pipe(
      map((result) => {
        if (result.status === apiStatus.SUCCESS) {
          enqueueSnackbar("Custom fields were succesfully modified.", {
            variant: "success",
          });
        }
        return patientOrganizationPutDetailsFieldsSuccess(result);
      }),
      catchError((e) => errorHandler(e, enqueueSnackbar)),
    ),
  ),
);

export const epicDeletePatientOrganizationDetailsFields = (action$, state$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_DELETE_DETAILS_FIELDS_ATTEMPT),
  switchMap(
    ({
      payload: {
        patient_organization_id,
        council_id,
        patient_organization_custom_field_id,
        data,
        enqueueSnackbar,
      },
    }) => httpDelete({
      call: `gsk/patient_organizations/${patient_organization_id}/custom_fields/${patient_organization_custom_field_id}?council_id=${council_id}`,
      data,
    }).pipe(
      map((result) => {
        if (result.status === apiStatus.SUCCESS) {
          enqueueSnackbar("Custom field successfully deleted.", {
            variant: "success",
          });
        }
        return patientOrganizationDeleteDetailsFieldsSuccess(result);
      }),
      catchError((e) => errorHandler(e, enqueueSnackbar)),
    ),
  ),
);

export const epicEditOrganization = (action$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_EDIT_ATTEMPT),
  switchMap(
    ({ payload: { patient_organization_id, data, enqueueSnackbar } }) => httpPut({
      call: `gsk/patient_organizations/${patient_organization_id}`,
      data,
    }).pipe(
      map((result) => patientOrganizationEditSuccess(result)),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

export const epicShareOrganization = (action$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_SHARE_ATTEMPT),
  switchMap(({ payload: { id, emails, enqueueSnackbar } }) => httpPost({
    call: `gsk/patient_organizations/${id}/share`,
    data: {
      emails,
    },
  }).pipe(
    map((result) => {
      enqueueSnackbar("Profile was successfully shared.", {
        variant: "success",
      });
      return patientOrganizationShareSuccess(result);
    }),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicAddManyToProject = (action$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_ADD_MANY_TO_PROJECT_ATTEMPT),
  switchMap(
    ({
      payload: {
        project_id,
        gsk_patient_organization_id,
        added_by_id,
        stage_id,
        enqueueSnackbar,
        history,
      },
    }) => httpPost({
      call: `projects/${project_id}/patient_organizations/create_many`,
      data: {
        project_patient_organizations: [
          {
            project_id,
            gsk_patient_organization_id,
            added_by_id,
            stage_id,
          },
        ],
      },
    }).pipe(
      map((result) => {
        if (result.status === 200 || result.status === 201) {
          enqueueSnackbar(
            "Successfully added patient organization(s) to the project.",
            {
              variant: "success",
            },
          );
        }
        return patientOrganizationProjectAddManySuccess(result);
      }),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);
const patientOrganizationEpic = combineEpics(
  epicGetPatientOrganization,
  epicGetPatientOrganizationDetails,
  epicPostRelationship,
  epicDeleteRelationship,
  epicPostCompanyContact,
  epicDeleteCompanyContact,
  epicPutCompanyContact,
  epicPostCustomFields,
  epicPutPatientOrganizationDetailsFields,
  epicDeletePatientOrganizationDetailsFields,
  epicEditOrganization,
  epicGetPatientOrganizationDetailsGroupFields,
  epicShareOrganization,
  epicAddManyToProject,
  epicEditRelationship,
);

export default patientOrganizationEpic;

import React from "react";
import { Multiselect as MultiSelectWithTree } from "multiselect-react-dropdown";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Loading,
  BtnType,
  Button,
  DropSelect,
  Label,
  TextArea,
  TextBox,
} from "modules/primitives/index";
import { useSnackbar } from "notistack";
import { FilePicker } from "react-file-picker";
import styles from "./editCompany.module.scss";

import { geographicReach } from "./editCompany.constants";
import { gskOptionCountries as defaultCountries } from "../../../../common/constants";
import { sortCountries } from "../../../../common/helper";
import { Icon, smSize } from "../../../../common/icon";
import {
  handleAddDocument,
  handleAddVideo,
  handleDeleteVideo,
} from "./editCompany.helper.js";

const EditCompanyFormMiddle = (props) => {
  const {
    formRef,
    fileDelete,
    setLoading,
    videos,
    setVideos,
    fileUpload,
    loadingState,
    deletingVideo,
    flattenDiseaseByCat,
    setSelectedDiseases,
    selectedDiseases,
    setGeoReach,
    setSelectedCountry,
    setDeletingVideo,
    formikprops,
    setFieldValue,
    blockAsCB,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: { diseases, diversityGroups },
    },
  } = useSelector((state) => state);
  const history = useHistory();

  return (
    <div className="row">
      <div className="col-12">
        <div>
          <Label>
            {history.location?.state?.gsk
              ? "Patient Organization Name"
              : "Company Name"}
          </Label>
          <TextBox
            type="text"
            name="name"
            disabled={blockAsCB}
            placeholder="Company Name"
            className={styles.textBox}
            formProps={formikprops}
          />
        </div>
        <div>
          <Label>Website</Label>
          <TextBox
            type="text"
            name="web_url"
            disabled={blockAsCB}
            placeholder="Website"
            className={styles.textBox}
            formProps={formikprops}
          />
        </div>

        <Label>Featured Video</Label>
        <div>
          <div className={styles.addVideoWrp}>
            <TextBox
              type="text"
              name="promo_video"
              disabled={blockAsCB}
              placeholder="Paste embed code or an embed link (e.g., https://www.youtube.com/embed/P3R5OiW33sE)"
              className={styles.textBox}
              formProps={formikprops}
            />
            <div className={styles.uploadVideoWrp}>
              <Button
                onClick={(e) =>
                  handleAddVideo({
                    setFieldValue,
                    setLoading,
                    videos,
                    setVideos,
                    enqueueSnackbar,
                    formRef,
                  })
                }
                disabled={blockAsCB}
                btn={BtnType.REGULAR}
                type="button"
                className={styles.addVideoBtn}
              >
                Add
              </Button>
              <div className={styles.separator}> OR</div>
              <div className={styles.vedioBtnWrp}>
                <FilePicker
                  extensions={[
                    "mp4",
                    "mov",
                    "wmv",
                    "flv",
                    "avi",
                    "avi",
                    "webm",
                    "mkv",
                  ]}
                  disabled={blockAsCB}
                  className={styles.filePicker}
                  maxSize={500}
                  onChange={(FileObject) => {
                    handleAddDocument({
                      FileObject,
                      setFieldValue,
                      setLoading,
                      videos,
                      setVideos,
                      enqueueSnackbar,
                      councilId: selectedCouncil.id,
                      companyId: selectedCompany.id,
                      fileUpload,
                    });
                  }}
                  onError={(errMsg) =>
                    enqueueSnackbar(errMsg, {
                      variant: "error",
                    })
                  }
                >
                  {loadingState ? (
                    <div className={styles.loadingWrp}>
                      <Loading />
                    </div>
                  ) : (
                    <Button
                      btn={BtnType.OUTLINE_LIGHT}
                      className={styles.selectFile}
                      rounded
                      type="button"
                      disabled={blockAsCB}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      Choose video file
                    </Button>
                  )}
                </FilePicker>
              </div>
            </div>
          </div>

          <div className={styles.listWrp}>
            {videos &&
              videos.map((item, index) =>
                index === deletingVideo ? (
                  <Loading />
                ) : (
                  <div key={item.id} className={styles.item}>
                    <div className={styles.name}> {item}</div>
                    <div className={styles.delete}>
                      <Icon
                        {...smSize}
                        icon="icn-button-delete"
                        onClick={(e) => {
                          if (!blockAsCB) {
                            handleDeleteVideo({
                              e,
                              index,
                              setDeletingVideo,
                              councilId: selectedCouncil.id,
                              companyId: selectedCompany.id,
                              videos,
                              setVideos,
                              fileDelete,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                )
              )}
          </div>
        </div>

        <Label>Description</Label>
        <TextArea
          type="text"
          name="description"
          disabled={blockAsCB}
          placeholder="Description"
          className={[styles.textBox, styles.description]}
          formProps={formikprops}
        />

        {history.location?.state?.gsk && (
          <div>
            <Label>Membership Size</Label>
            <TextBox
              type="number"
              name="membership_size"
              placeholder="Membership Size"
              className={[styles.textBox, styles.description]}
              formProps={formikprops}
            />
            {diseases && diseases?.length ? (
              <div className="mb-3 custom-multi-select-tree">
                <Label>Diseases</Label>
                <MultiSelectWithTree
                  placeholder="Diseases"
                  options={
                    flattenDiseaseByCat?.length ? flattenDiseaseByCat : []
                  }
                  groupBy="cat"
                  displayValue="name"
                  showCheckbox
                  onSelect={(diseases) => {
                    if (formikprops.values.diversity_groups_ids?.length > 0) {
                      alert(
                        "Disease tags cannot be added if Diversity Group tags are already selected"
                      );
                      diseases.pop();
                      return setSelectedDiseases(
                        diseases.map((item) => item.id)
                      );
                    }

                    return setSelectedDiseases(diseases.map((item) => item.id));
                  }}
                  onRemove={(diseases) =>
                    setSelectedDiseases(diseases.map((item) => item.id))
                  }
                  selectedValues={flattenDiseaseByCat.filter((d) =>
                    selectedDiseases.includes(d.id)
                  )}
                  closeOnSelect={false}
                  showArrow
                />
              </div>
            ) : null}
            <div className="mb-3 custom-multi-select-tree">
              <Label>Non-Patient Organization Groups</Label>
              <MultiSelectWithTree
                placeholder="Non-Patient Organization Groups"
                options={diversityGroups}
                displayValue="name"
                showCheckbox
                onSelect={(diversityGroups) => {
                  if (selectedDiseases.length > 0) {
                    alert(
                      "Diversity Group tags cannot be added if Disease tags are already selected"
                    );
                    diversityGroups.pop();
                    return setFieldValue(
                      "diversity_groups_ids",
                      diversityGroups
                    );
                  }

                  setFieldValue("diversity_groups_ids", diversityGroups);
                }}
                onRemove={(diversityGroups) =>
                  setFieldValue("diversity_groups_ids", diversityGroups)
                }
                selectedValues={formikprops.values.diversity_groups_ids || []}
                closeOnSelect={false}
                showArrow
              />
            </div>
            <Label>Geographic Reach</Label>
            <DropSelect
              type="text"
              name="geographic_reach"
              placeholder="Geographic React"
              labelField="name"
              valueField="name"
              onChange={(val) => setGeoReach(val.obj[0].name)}
              options={geographicReach}
              className={[styles.textBox, styles.description]}
              formProps={formikprops}
            />
            <Label>Country</Label>
            <DropSelect
              type="text"
              name="country"
              placeholder="Country"
              labelField="name"
              valueField="name"
              onChange={(val) => {
                if (val.val === "") {
                  setSelectedCountry("");

                  return;
                }
                setSelectedCountry(val.obj[0].id);
              }}
              options={defaultCountries.sort(sortCountries)}
              className={[styles.textBox, styles.description]}
              formProps={formikprops}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditCompanyFormMiddle;

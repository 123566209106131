import React, {
  memo, useEffect, useRef, useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import { format } from "date-fns";

import {
  BtnType,
  Button,
  DropSelect,
  Label,
  Loading,
  TextArea,
  TextBox,
} from "../../../../../primitives";
import { ModalFooter } from "../../../../../../application/modal";
import styles from "./companyDetails.module.scss";
import { formInitialValues } from "./constants";
import { useModelPopup } from "../../../../../../common/hooks";
import { updateClaimProfileAttempt } from "../../../../store/claimProfileActions";
import { EMPLOYEES_VALUES } from "../../../../../../common/constants";
import LocationSelectSearchAndAdd from "../../../../../admin/adminCompanies/editCompany/locationSearchAndAdd";
import {
  getCompetitors,
  getCustomers,
  prepareCompetitors,
  prepareCustomers,
} from "../../../../../admin/adminCompanies/editCompany/editCompany.helper";
import { revenuOptions } from "../../../../../admin/adminCompanies/editCompany/editCompany.constants";
import { Icon, smSize } from "../../../../../../common/icon";

const CompanyDetailsEditModal = ({ profile }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { companyCompetitors, companyCustomers },
      companyMainReducer: { searchResults, addedCompetitor },
    },
  } = useSelector((state) => state);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [showLoading, setShowLoading] = useState(false);
  const [competitors, setCompetitors] = useState([]);
  const [competitorOptions, setCompetitorOptions] = useState([]);
  const [customerQuery, setCustomerQuery] = useState("");
  const [prevCompetitors, setPrevCompetitors] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [prevCustomers, setPrevCustomers] = useState([]);

  useEffect(() => {
    if (profile) {
      const newModel = {
        twitter: profile.twitter,
        description: profile.description,
        web_url: profile.web_url,
        linkedin: profile.linkedin,
        contact_email: profile.contact_email,
        revenue_range: profile.estimated_revenue,
        location: profile.location,
        num_employees_enum: profile.num_employees_enum,
        founded: profile.founded
          ? format(new Date(profile.founded), "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
        cb_profile_link: profile.cb_profile_link,
      };

      setFormValues(newModel);
      setPrevCompetitors(profile.competitors);
      setPrevCustomers(profile.customers);
    }
  }, [profile]);

  useEffect(() => {
    const flatCustomersIds = profile?.customers
      ?.map((el) => el.id)
      .concat(selectedCustomers.map((el) => el.id)) || [];
    if (flatCustomersIds) {
      setCustomerOptions(
        companyCustomers.filter((el) => flatCustomersIds.indexOf(el.id) < 0),
      );
    }
  }, [companyCustomers, profile]);

  useEffect(() => {
    if (
      addedCompetitor
      && profile.id
      && addedCompetitor?.name
      && addedCompetitor.id !== profile.id
    ) {
      setCompetitors((prevCompetitors) => [
        ...prevCompetitors,
        addedCompetitor,
      ]);
      setCompetitorOptions((prevCompetitors) => [
        ...prevCompetitors,
        addedCompetitor,
      ]);
    } else {
      setCompetitors((prevCompetitors) => [...prevCompetitors]);
    }
  }, [addedCompetitor]);

  useEffect(() => {
    if (companyCompetitors?.length) {
      setCompetitorOptions(companyCompetitors);
    }
  }, [companyCompetitors]);

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      const filteredCompetitors = searchResults.filter((competitor) => {
        const alreadySelectedCompetitor = prevCompetitors
          && prevCompetitors.find(
            (prevCompetitor) => prevCompetitor.company_id === competitor.company_id
              || competitor.company_id === profile.id,
          );
        if (!alreadySelectedCompetitor) {
          return true;
        }
      });

      setCompetitorOptions(filteredCompetitors);
    }
  }, [searchResults, profile?.competitors]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSave = (values) => {
    setShowLoading(true);

    dispatch(
      updateClaimProfileAttempt({
        companyId: profile.id,
        councilId: selectedCouncil.id,
        data: {
          ...values,
          revenue_range: values.revenue_range
            ? revenuOptions.find((r) => r.text === values.revenue_range)?.value
            : values.revenue_range,
          customer_ids:
            prepareCustomers(selectedCustomers, prevCustomers) || [],
          competitor_ids:
            prepareCompetitors(competitors, prevCompetitors) || [],
        },
        enqueueSnackbar,
        callBack: () => {
          setShowLoading(false);

          popup.hide();
        },
      }),
    );
  };

  const handleLocationChange = (value, setFieldValue) => {
    if (value?.length) {
      const {
        name = "",
        city = "",
        continent = "",
        country = "",
        region = "",
      } = value[0];

      setFieldValue("location", name);
      setFieldValue("location_city", city);
      setFieldValue("location_region", region);
      setFieldValue("location_country", country);
      setFieldValue("location_continent", continent);
    } else {
      setFieldValue("location", "");
      setFieldValue("location_city", "");
      setFieldValue("location_region", "");
      setFieldValue("location_country", "");
      setFieldValue("location_continent", "");
    }
  };

  const handleAddNewLocation = (locationObj, setFieldValue) => {
    setFieldValue("location_city", locationObj.location_city);
    setFieldValue("location_region", locationObj.location_region);
    setFieldValue("location_country", locationObj.location_country);
    setFieldValue("location_continent", locationObj.continent);
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={formValues}
      onSubmit={handleSave}
    >
      {({
        values, handleSubmit, setFieldValue, ...formikprops
      }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.customPadding}>
            <div className="mt-3">
              {!showLoading && (
              <div className="d-flex flex-row">
                <div className="d-flex flex-column w-50 pr-3">
                  <div className="d-flex flex-column align-items-start">
                    <Label>Description</Label>
                    <TextArea
                      type="text"
                      name="description"
                      placeholder="Description"
                      formProps={{
                        ...formikprops,
                        values,
                        setFieldValue,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="d-flex flex-column align-items-start">
                    <Label>Website</Label>
                    <TextBox
                      type="text"
                      name="web_url"
                      placeholder="Website"
                      formProps={{
                        ...formikprops,
                        values,
                        setFieldValue,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="d-flex flex-column align-items-start">
                    <Label>Linkedin</Label>
                    <TextBox
                      type="text"
                      name="linkedin"
                      placeholder="Linkedin"
                      formProps={{
                        ...formikprops,
                        values,
                        setFieldValue,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="d-flex flex-column align-items-start">
                    <Label>Crunchbase URL</Label>
                    <TextBox
                      type="text"
                      name="cb_profile_link"
                      placeholder="Crunchbase URL"
                      formProps={{
                        ...formikprops,
                        values,
                        setFieldValue,
                      }}
                      className="w-100"
                      disabled={profile?.cb_profile_link?.length}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-start">
                    <Label>Twitter</Label>
                    <TextBox
                      type="text"
                      name="twitter"
                      placeholder="Twitter"
                      className="w-100"
                      formProps={{
                        ...formikprops,
                        values,
                        setFieldValue,
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-start">
                    <Label>Contact Email</Label>
                    <TextBox
                      type="text"
                      name="contact_email"
                      placeholder="Contact Email"
                      formProps={{
                        ...formikprops,
                        values,
                        setFieldValue,
                      }}
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column w-50 pl-3">
                  <Label>Employees</Label>
                  <DropSelect
                    labelField="value"
                    valueField="id"
                    searchBy="value"
                    options={EMPLOYEES_VALUES.filter((e) => e.id !== "Any")}
                    dropdownPosition="auto"
                    name="num_employees_enum"
                    formProps={{
                      ...formikprops,
                      values,
                      setFieldValue,
                    }}
                    onChange={(value) => setFieldValue("num_employees_enum", value.val)}
                  />
                  <Label>Estimated Revenue</Label>
                  <DropSelect
                    name="revenue_range"
                    onChange={(value) => setFieldValue("revenue_range", value.val)}
                    formProps={{
                      ...formikprops,
                      values,
                      setFieldValue,
                    }}
                    placeholder="Revenue Range"
                    labelField="text"
                    valueField="text"
                    searchBy="text"
                    options={revenuOptions}
                  />
                  <div>
                    <Label>Competitors</Label>
                    <DropSelect
                      onChange={(payload) => {
                        if (payload.val !== profile.id) {
                          setCompetitors(payload.obj);
                        }
                      }}
                      SelecedValues={competitors}
                      placeholder="Add Companies (type to search or select from below)"
                      labelField="name"
                      valueField="id"
                      searchBy="name"
                      options={competitorOptions}
                      onFocus={() => getCompetitors(
                        "",
                        dispatch,
                        selectedCouncil.id,
                        enqueueSnackbar,
                      )}
                      multi
                      searchFn={(args) => {
                        setCustomerQuery(args.state.search);
                        if (customerQuery !== args.state.search) {
                          getCompetitors(
                            args.state.search,
                            dispatch,
                            selectedCouncil.id,
                            enqueueSnackbar,
                          );
                        }
                      }}
                    />
                    <div className={styles.companyListWrp}>
                      <div>
                        <div className={styles.companyList}>
                          {prevCompetitors?.map((competitor) => (
                            <div
                              key={competitor.id}
                              className={styles.techItem}
                            >
                              <a
                                target="_blank"
                                href={competitor.url}
                                rel="noreferrer"
                              >
                                {competitor.name}{" "}
                                <span
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    setPrevCompetitors(
                                      prevCompetitors.filter(
                                        (item) => item.company_id
                                            !== competitor.company_id,
                                      ),
                                    );
                                  }}
                                  className={styles.delete}
                                >
                                  <Icon
                                    {...smSize}
                                    icon="icn-button-delete"
                                  />
                                </span>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Label>Customers</Label>
                    <DropSelect
                      onChange={(e) => setSelectedCustomers(e.obj)}
                      placeholder="Add Companies (type to search or select from below)"
                      labelField="name"
                      valueField="id"
                      searchBy="name"
                      options={customerOptions}
                      multi
                      onFocus={() => getCustomers(null, enqueueSnackbar, dispatch)}
                      searchFn={(args) => {
                        setCustomerQuery(args.state.search);
                        if (customerQuery !== args.state.search) {
                          getCustomers(
                            args.state.search,
                            enqueueSnackbar,
                            dispatch,
                          );
                        }
                      }}
                    />

                    <div className={styles.companyListWrp}>
                      <div className={styles.companyList}>
                        {prevCustomers && prevCustomers.length
                          ? prevCustomers.map((customer) => (
                            <div className={styles.techItem}>
                              <span>{customer.name}</span>
                              <span
                                onClick={() => {
                                  setPrevCustomers(
                                    prevCustomers.filter(
                                      ({ id }) => id !== customer?.id,
                                    ),
                                  );
                                }}
                              >
                                <Icon
                                  {...smSize}
                                  className={styles.trash}
                                  icon="icn-button-delete"
                                />
                              </span>
                            </div>
                          ))
                          : null}
                      </div>
                    </div>
                  </div>

                  <Label>Headquarters Location</Label>
                  <LocationSelectSearchAndAdd
                    formValues={values}
                    onAddNew={(locObj) => handleAddNewLocation(locObj, setFieldValue)}
                    onChange={(value) => handleLocationChange(value, setFieldValue)}
                  />
                  <Label>Founded (Date)</Label>
                  <TextBox
                    type="date"
                    name="founded"
                    className="w-100"
                    formProps={{
                      ...formikprops,
                      values,
                      setFieldValue,
                    }}
                  />
                </div>
              </div>
              )}
              {showLoading && <Loading customText="Updating your details..." />}
            </div>
          </div>
          <ModalFooter>
            <Button
              className="mr-3"
              btn={BtnType.FRAME_LESS}
              onClick={handleCancelClick}
              disabled={showLoading}
            >
              Cancel
            </Button>
            <Button
              disabled={showLoading}
              type="submit"
              onClick={handleSubmit}
              btn={BtnType.REGULAR}
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default memo(CompanyDetailsEditModal);

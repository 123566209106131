import React from "react";
import classNames from "classnames";
import styles from "./button.module.scss";
import { BtnType } from "./button.constants";
import { Icon, smSize } from "../../../common/icon";

const ButtonComponent = (props) => {
  const {
    children,
    className,
    btn,
    icon,
    rounded = false,
    readonly = false,
    customSize,
    style = {},
    makeDeleteRed,
    disabled,
    buttonColor,
    ...other
  } = props;
  let btnFormatter;
  switch (btn) {
    case BtnType.DELETE:
      btnFormatter = "btnDelete";
      break;
    case BtnType.REGULAR:
      btnFormatter = "btnRegular";
      break;
    case BtnType.FRAME_LESS:
      btnFormatter = "btnFrameLess";
      break;
    case BtnType.OUTLINE:
      btnFormatter = "btnOutline";
      break;
    case BtnType.LINK:
      btnFormatter = "btnLink";
      break;
    case BtnType.OUTLINE_LIGHT:
      btnFormatter = "btnOutlineLight";
      break;
    case BtnType.HIGLIGHT:
      btnFormatter = "btnHiglight";
      break;
    case BtnType.TAG_BLUE_LIGHT:
      btnFormatter = "tagBlueLight";
      break;
    case BtnType.TAG_OUTLINE_BLUE:
      btnFormatter = "tagOutlineBlue";
      break;
    case BtnType.TAG_BLUE:
      btnFormatter = "tagBlue";
      break;
    case BtnType.DISABLED:
      btnFormatter = "tagDisabled";
      break;
    case BtnType.OUTLINE_BLANK:
      btnFormatter = "btnOutlineBlank";
      break;
    default:
      btnFormatter = "btnRegular";
      break;
  }

  return (
    <button
      className={classNames(
        styles.btnWrp,
        styles[btnFormatter],
        rounded && styles.rounded,
        className
      )}
      disabled={props.disabled}
      style={buttonColor && buttonColor}
      {...other}
    >
      {icon && (
        <div className={styles.iconWrp}>
          <Icon
            {...(customSize ? customSize : smSize)}
            icon={icon}
            color={
              makeDeleteRed && icon === "icn-button-delete" ? "red" : "inherit"
            }
          />
        </div>
      )}
      <span
        style={style}
        className={classNames(styles.button, icon && styles.icon)}
      >
        {children}
      </span>
    </button>
  );
};

export default React.memo(ButtonComponent);

import React, { useState } from "react";
import { useSelector } from "react-redux";

import styles from "./styles.module.scss";
import { Loading } from "../../../primitives/index";
import PipelineTabTopActions from "./pipelineTabTopActions";
import PipelineTable from "./pipelineTable/index";
import PipelineKanban from "./pipelineKanban/index";

const PipelineTab = () => {
  const [activeLayout, setActiveLayout] = useState({ name: "card" });
  const [investMode, setInvestMode] = useState(false);
  const {
    themesReducer: {
      themesMainReducer: { currentThemeData, loadingIndividualTheme },
    },
  } = useSelector((state) => state);

  const handleChangeLayout = (layout) => {
    setActiveLayout(layout);
  };

  return loadingIndividualTheme ? (
    <Loading />
  ) : (
    <div className={styles.pipelineContainer}>
      <PipelineTabTopActions
        handleChangeLayout={handleChangeLayout}
        activeLayout={activeLayout}
        investMode={investMode}
        pipelineKanban={activeLayout.name === "card"}
        setInvestMode={setInvestMode}
      />
      {activeLayout.name === "list" ? (
        <PipelineTable id={currentThemeData?.id} investMode={investMode} />
      ) : (
        <PipelineKanban themeId={currentThemeData?.id} investMode={investMode} />
      )}
    </div>
  );
};
export default PipelineTab;

import { combineReducers } from "redux";
import internalPeopleReducer from "./internalPeople/internalPeople.reducer";
import companyContactsReducer from "./externalPeople/companyContacts.reducer";
import peopleProfileReducer from "./Profile/profile.reducer";

const peopleReducer = combineReducers({
  internalPeopleReducer,
  companyContactsReducer,
  peopleProfileReducer,
});

export default peopleReducer;

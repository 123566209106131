import React from "react";

import TimeGood from '../../../../assets/timer-good.png';
import TimeOk from '../../../../assets/timer-ok.png';
import TimeBad from '../../../../assets/timer-bad.png';
import styles from './genericReportsList.module.scss';

const iconByStatus = {
  bad: TimeBad,
  ok: TimeOk,
  good: TimeGood,
}

const getCompanyIcon = time => {
  if (time < 31) {
    return iconByStatus['good'];
  }

  if (time < 91) {
    return iconByStatus['ok'];
  }

  return iconByStatus['bad'];
}

const StatusComponent = ({ rowDetails, showDaysIcon }) => (
  <div className="d-flex">
    <div
      className={styles.statusTag}
      style={rowDetails.status_color ? {
        backgroundColor: rowDetails.status_color.background,
        color: rowDetails.status_color.color,
      } : {}}
    >
      {rowDetails.status || rowDetails.new_status || '-'}
    </div>
    {
      showDaysIcon ? (
        <div className="ml-4">
          <img
            width="16"
            height="16"
            src={getCompanyIcon(rowDetails.time_from_status_change)}
            alt={`${rowDetails.time_from_status_change} days`}
          />
          <span className={styles.daysFrom}>
            {rowDetails.time_from_status_change || ''} {rowDetails.time_from_status_change === 0 ? 'Today' : 'days'}
          </span>
        </div>
      ) : null
    }
  </div>
)

export default React.memo(StatusComponent);

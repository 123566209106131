import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import styles from "./employees.module.scss";
import { Icon, smSize } from "../../../../../../common/icon";
import {
  queryParamStringToArray,
  queryStringUrlReplacement,
  useQuery,
} from "../../../../../../common/helper";
import { companySearchOwnersGetAttempt } from "../../../companyMain.action";
import OwnersMultiSelectSearch from "./ownersSearch";

const RelationshipOwnersFilter = ({ expanded, handleExpand }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const owners = useQuery().get("owners");
  const { enqueueSnackbar } = useSnackbar();

  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: { relationshipOwners },
    },
  } = useSelector((state) => state);

  const [collapsed, setCollapsed] = useState(false);
  const [selectedOwners, setSelectedOwners] = useState([]);

  useEffect(() => {
    setCollapsed(expanded);
  }, [expanded]);

  useEffect(() => {
    if (!relationshipOwners && selectedCouncil && collapsed) {
      dispatch(
        companySearchOwnersGetAttempt({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [relationshipOwners, selectedCouncil, collapsed]);

  useEffect(() => {
    if (owners && relationshipOwners) {
      const arr = queryParamStringToArray(owners);
      const mappedValue = relationshipOwners.filter((e) =>
        arr.includes(e.user_id)
      );

      setSelectedOwners(mappedValue);
      return;
    }

    setSelectedOwners([
      {
        full_name: "Any",
        user_id: "Any",
        first_name: "Any",
      },
    ]);

    return () => null;
  }, [owners, relationshipOwners]);

  const handleCollapseClick = () => {
    if (!collapsed) {
      handleExpand("relationshipOwners");
    }
    setCollapsed(!collapsed);
  };

  const handleOnChange = (value) => {
    let clearVal;
    const currentHasAny = value.some((e) => e.user_id === "Any");
    const hasAny = selectedOwners.some((e) => e.user_id === "Any");

    if (currentHasAny && value.length > 1) {
      clearVal = value
        .filter((elem) => elem.user_id !== "Any")
        .reduce(
          (acc, elem) => `${acc}${acc.length ? "," : ""}${elem.user_id}`,
          ""
        );
    }

    if (!hasAny && currentHasAny) {
      clearVal = "Any";
    }

    if (!currentHasAny) {
      clearVal = value.reduce(
        (acc, elem) => `${acc}${acc.length ? "," : ""}${elem.user_id}`,
        ""
      );
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      "owners",
      clearVal === "Any" ? "" : clearVal
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        owners: clearVal === "Any" ? "" : clearVal,
        page: 1,
      },
    });
  };

  return (
    <div className={styles.itemWrapper} id="relationshipOwners">
      <div className={styles.itemTitle} onClick={handleCollapseClick}>
        <span>Relationship Owners</span>
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(styles.icon, !collapsed && styles.collapse)}
          onClick={handleCollapseClick}
        />
      </div>
      {collapsed ? (
        <div
          className={classNames(
            "d-flex align-items-center",
            styles.withPaddingLeft
          )}
        >
          <div
            id="owners"
            className={classNames("d-flex flex-column", styles.ownersField)}
          >
            <OwnersMultiSelectSearch
              results={relationshipOwners}
              selectedOwners={selectedOwners}
              labelField="full_name"
              valueField="user_id"
              searchBy="full_name"
              onChange={handleOnChange}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(RelationshipOwnersFilter);

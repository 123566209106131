import React, { useState, useEffect } from "react";
import styles from "./adminTags.module.scss";

const TagsSearch = (props) => {
  const {
    text,
    data,
    setCoincidenceError,
    coincidenceError,
    handleTopicSelect,
    setShowCoincidencesFalse,
  } = props;
  const [searchedData, setSearchedData] = useState([]);

  useEffect(() => {
    if (searchedData.length) {
      setCoincidenceError(true);
    } else if (!searchedData.length && coincidenceError) {
      setCoincidenceError(false);
    }
  }, [searchedData, coincidenceError]);

  useEffect(() => {
    if (text.length) {
      const textCopy = text.trim().toLowerCase();
      const filteredData = data.filter((el) => el.name.toLowerCase().includes(textCopy));
      setSearchedData(filteredData);
    }
  }, [text]);

  return (
    <div className={styles.searchBox}>
      {searchedData.map((tag) => (
        <div
          key={tag.id}
          className={styles.searchRow}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleTopicSelect(tag.name);
            setShowCoincidencesFalse();
          }}
        >
          {tag.name}
        </div>
      ))}
    </div>
  );
};

export default TagsSearch;

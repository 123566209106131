export const APPLICATION_GET_ATTEMPT = "APPLICATION_GET_ATTEMPT";
export const getApplication = (payload) => ({
  type: APPLICATION_GET_ATTEMPT,
  payload,
});

export const APPLICATION_GET_SUCCESS = "APPLICATION_GET_SUCCESS";
export const applicationGetSuccess = (payload) => ({
  type: APPLICATION_GET_SUCCESS,
  payload,
});

export const APPLICANTS_LIST_GET_ATTEMPT = "APPLICANTS_LIST_GET_ATTEMPT";
export const applicantsListGet = (payload) => ({
  type: APPLICANTS_LIST_GET_ATTEMPT,
  payload,
});

export const APPLICANTS_LIST_GET_SUCCESS = "APPLICANTS_LIST_GET_SUCCESS";
export const applicantsListGetSuccess = (payload) => ({
  type: APPLICANTS_LIST_GET_SUCCESS,
  payload,
});

export const APPLICATION_UPDATE_ATTEMPT = "APPLICATION_UPDATE_ATTEMPT";
export const updateApplication = (payload) => ({
  type: APPLICATION_UPDATE_ATTEMPT,
  payload,
});

export const APPLICATION_UPDATE_SUCCESS = "APPLICATION_UPDATE_SUCCESS";
export const applicationUpdateSuccess = (payload) => ({
  type: APPLICATION_UPDATE_SUCCESS,
  payload,
});

export const APPLICATION_DELETE_ATTEMPT = "APPLICATION_DELETE_ATTEMPT";
export const deleteApplication = (payload) => ({
  type: APPLICATION_DELETE_ATTEMPT,
  payload,
});

export const APPLICATION_DELETE_SUCCESS = "APPLICATION_DELETE_SUCCESS";
export const deleteApplicationSuccess = (payload) => ({
  type: APPLICATION_DELETE_SUCCESS,
  payload,
});

import React, { memo, useState } from "react";
import { withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";

import ChartCompanyItem from "../chartCompanyItem";
import NewCompanyItemResultHoverComponent from "../../rightWindow/newCompaniesList/newCompanyItemResultHover/newCompanyItemResultHover.component";
import style from "../chartCompanyItem/chartCompanyItem.module.scss";
import { CompanyAvatar } from "../../../../primitives";

const width = window.innerWidth > 1200 ? 800 : 600;

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fafafa",
    boxShadow: "5px 5px 4px 0 rgba(0, 0, 0, 0.14)",
    color: "rgba(0, 0, 0, 0.87)",
    width,
    minWidth: width,
    fontSize: theme.typography.pxToRem(15),
    border: "1px solid #cccccc",
    // padding: "15px 20px 10px",
  },
}))(Tooltip);

const CustomTooltipChild = React.forwardRef((props, ref) => {
  const {
    userIsScrolling,
    selectedCouncil,
    currentYear,
    company,
    history,
    dispatch,
    showAmountOfLastRound,
    homeUse,
    blockFunctionality,
    kyndryThemelInstance,
    ...otherProps
  } = props;
  const {
    authReducer: { session },
  } = useSelector((state) => state);

  const setStylesForCol = () => {
    if (
      selectedCouncil?.name === "Firmenich" &&
      session?.theme_investment_access
    ) {
      return style.firmenichNameWrapper;
    }
    if (kyndryThemelInstance) {
      return style.kyndryThemelInstance;
    }
    return style.nameWrapper;
  };
  const handleCompanyClick = () => {
    localStorage.setItem("company", JSON.stringify(company));
    history.push(`/companies/${company.slug || company.id}`);
  };

  return (
    <div
      {...otherProps}
      currentyear={currentYear}
      ref={ref}
      className={`${setStylesForCol()} `}
      onClick={handleCompanyClick}
    >
      <div className={style.logoCon}>
        <CompanyAvatar
          imgSrc={company.logo}
          name={company.name}
          hasBorderRadius
          smallSize
        />
      </div>
      <span className={style.companyName}>{company.name}</span>
    </div>
  );
});

const ChartCompanyItemWrapperComponent = (props) => {
  const {
    appReducer: { highlightItemWithId },
  } = useSelector((state) => state);
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`${style.companyWrapper} ${props.homeUse ? "pr-2" : ""} 
      ${props.company.id === highlightItemWithId ? style.flashingRow : ""}`}
      onMouseOut={() => setHovered(false)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="background-white" onMouseOver={() => setHovered(true)}>
        <HtmlTooltip
          interactive
          title={
            <>
              <NewCompanyItemResultHoverComponent {...props} />
            </>
          }
          enterDelay={1000}
          open={props.userIsScrolling ? false : hovered}
          placement="top"
        >
          <CustomTooltipChild {...props} />
        </HtmlTooltip>
      </div>
      <ChartCompanyItem {...props} />
    </div>
  );
};

export default memo(ChartCompanyItemWrapperComponent);

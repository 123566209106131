export const SETTINGS_HEADER_MENU = [
  {
    title: "General",
    url: "/settings",
    onlyClaim: true,
  },
  {
    title: "Password",
    url: "/settings/password",
    onlyClaim: true,
  },
  {
    title: "Notifications",
    url: "/settings/notifications",
  },
  {
    title: "Integrations",
    url: "/settings/integrations",
  },
  {
    title: "Following",
    url: "/settings/following",
  },
];

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { withSnackbar } from "notistack";
import { useModelPopup } from "../../../../common/hooks/index";
import styles from "../adminTags.module.scss";
import { ModalBody, ModalFooter } from "../../../../application/modal";
import {
  BtnType,
  Button,
  Label,
  TextBox,
  DropSelect,
} from "../../../primitives";

import {
  tagRelationshipEditAttempt,
  tagRelationshipAddAttempt,
} from "../adminTags.actions";

const colors = [
  {
    text: "#81DEFD",
    value: "#81defd",
  },
  {
    text: "#FFB088",
    value: "#ffb088",
  },
  {
    text: "#88B1FC",
    value: "#88b1fc",
  },
  {
    text: "#91E697",
    value: "#91e697",
  },
  {
    text: "#C1FEF6",
    value: "#c1fef6",
  },
  {
    text: "#F8C4FE",
    value: "#f8C4fe",
  },
  {
    text: "#CBD2D9",
    value: "#cbd2d9",
  },
  {
    text: "#FF9B9B",
    value: "#ff9b9b",
  },
];

const RelationshipStatusModal = ({ enqueueSnackbar, mode, tag }) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [tagName, setTagName] = useState(tag?.name || "");
  const [tagPosition, setTagPosition] = useState(tag?.position || 1);
  const [tagDescription, setTagDescription] = useState(tag?.description || "");
  const [occupyPosition, setOccupyPosition] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [bgColor, setBgColor] = useState(tag?.colors?.background);
  const [textColor, setTextColor] = useState(tag?.colors?.text || "#1100ff");
  // const [slectedColor, setSelectedColor] = useState({});
  const {
    adminTopicsReducer: { relationshipTag },
  } = useSelector((state) => state);

  const handleCancelClick = (e) => {
    popup.hide();
  };

  const onSubmit = () => {
    if (errorMessage) {
      return;
    }

    if (Number(tagPosition) === 0 && tagName?.trim() !== "Applicant") {
      setTagPosition(tagPosition);
      setErrorMessage("Position can not be 0!");
      return;
    }
    const payload = {
      name: tagName?.trim(),
      position: Number(tagPosition),
      description: tagDescription,
      bg_color: bgColor,
      // text_color: textColor,
    };

    if (mode === "add") {
      dispatch(
        tagRelationshipAddAttempt({
          enqueueSnackbar,
          data: payload,
        })
      );
    } else {
      dispatch(
        tagRelationshipEditAttempt({
          enqueueSnackbar,
          data: payload,
          tagId: tag?.id,
        })
      );
    }

    popup.hide();
  };

  const validatePosition = (position, name) => {
    if (Number(position) === 0 && name?.trim() === "Applicant") {
      setTagPosition(position);
      setErrorMessage("");
      return true;
    }

    if (mode === "add") {
      if (
        Number(position) === occupyPosition.length + 1 ||
        occupyPosition[position - 1] === "_"
      ) {
        setTagPosition(position);
        setErrorMessage("");
        return true;
      }

      setTagPosition(position);
      setErrorMessage(
        "Positions must be in sequential order. You cannot skip a position."
      );
      return false;
    }

    if (mode === "edit") {
      if (typeof occupyPosition[Number(position) - 1] === "number") {
        setTagPosition(position);
        setErrorMessage(
          "This position already exists. Positions must be in sequential order. You cannot add a tag on existing positions."
        );
        return false;
      }

      setTagPosition(position);
      setErrorMessage("");
      return true;
    }
  };

  useEffect(() => {
    if (relationshipTag.length && mode === "add") {
      const positions = [];
      let thereAreEmpty = false;
      relationshipTag.forEach((tag) => {
        positions[tag.position] = tag.position;
      });

      positions.shift();
      for (let index = 0; index < positions.length; index++) {
        if (!positions[index]) {
          positions[index] = "_";
          thereAreEmpty = true;
          setTagPosition(index + 1);
        }
      }
      if (mode === "add") {
        let color;
        if (positions[positions.length - 1] + 1 < 9) {
          color = colors[positions[positions.length - 1]];
        } else {
          color = colors[positions[positions.length - 1] % 8];
        }
        setBgColor(color.value);
      }

      setOccupyPosition(positions);
      if (!thereAreEmpty) {
        setTagPosition(positions[positions.length - 1] + 1);
      }
    }
  }, [relationshipTag]);

  const changeColor = (color) => {
    setBgColor(color);
  };

  return (
    <div>
      <div className={styles.form}>
        <ModalBody>
          <div>
            <p className={styles.aditionalInfo}>
              Relationship Stage Tags will be available for all companies
            </p>
            <div className={styles.addRatingTempForm}>
              <div className={styles.row}>
                <div className={classNames(styles.col12)}>
                  <Label>Relationship Stage Tag Name</Label>
                  <TextBox
                    autoComplete="false"
                    placeholder="Tag Name"
                    type="text"
                    value={tagName}
                    onChange={(e) => {
                      validatePosition(tagPosition, e.target.value);
                      setTagName(e.target.value);
                    }}
                    className={styles.textBox}
                  />
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.col12}>
                  <Label>Position</Label>
                  <TextBox
                    type="number"
                    value={String(tagPosition)}
                    onChange={(e) => {
                      validatePosition(e.target.value);
                    }}
                    className={styles.textBox}
                    // disabled={mode === 'edit'}
                  />
                  {errorMessage && (
                    <span className={styles.errorMessage}>{errorMessage} </span>
                  )}
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.col12}>
                  <Label>Description (Optional)</Label>
                  <div className={styles.textBoxWrp}>
                    <textarea
                      value={tagDescription}
                      onChange={(e) => setTagDescription(e.target.value)}
                      name="description"
                      placeholder="Add Description"
                      className={styles.textBox}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.col12}>
                  <Label>Select tag color (Optional)</Label>
                  <div className={styles.textBoxWrp}>
                    <DropSelect
                      SelecedValues={bgColor}
                      onChange={() => undefined}
                      placeholder={`${bgColor || "Background color"}`}
                      labelField="text"
                      disabled={false}
                      valueField="value"
                      searchBy="text"
                      options={colors}
                      itemRenderer={({
                        item,
                        itemIndex,
                        props,
                        state,
                        methods,
                      }) => (
                        <div
                          onClick={(e) => changeColor(item.value)}
                          value={item.value}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                          }}
                        >
                          {" "}
                          <div
                            style={{
                              height: "30px",
                              width: "100px",
                              backgroundColor: `${item.value}`,
                              border: "1px solid black",
                              borderRadius: 5,
                              marginTop: "10px",
                              alignContent: "center",
                              marginLeft: "10px",
                              paddingLeft: "5px",
                            }}
                          >
                            {item.value}
                          </div>
                          {/* <span style={{ color: `${item.value}` }}>
                            {item.value}
                          </span> */}
                        </div>
                      )}
                    />
                    <div
                      style={{
                        height: "26px",
                        width: "30px",
                        backgroundColor: `${bgColor}`,
                        border: "1px solid black",
                        borderRadius: 30,
                        marginLeft: "10px",
                        marginTop: "-17px",
                        alignContent: "center",
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* <div className={styles.row}>
                <div className={styles.col12}>
                  <Label>Select text color (Optional)</Label>
                  <div className={styles.textBoxWrp}>
                    <input
                      onChange={(e) => setTextColor(e.target.value)}
                      type="color"
                      id="favcolor"
                      name="favcolor"
                      value={textColor}
                    ></input>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={onSubmit}
            btn={BtnType.REGULAR}
            disabled={!!errorMessage}
          >
            {mode === "edit" ? "Submit" : "Add"}
          </Button>
        </ModalFooter>
      </div>
    </div>
  );
};

export default React.memo(withSnackbar(RelationshipStatusModal));

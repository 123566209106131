import React from "react";
import classNames from "classnames";
import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  convertFromRaw,
  CompositeDecorator,
  convertFromHTML,
  ContentState,
} from "draft-js";
import Editor from "@draft-js-plugins/editor";

import { stateToHTML } from "draft-js-export-html";
import { withSnackbar } from "notistack";
import createMentionPlugin from "@draft-js-plugins/mention";

import { mediaBlockRenderer } from "./entities/mediaBlockRenderer";
import basicTextStylePlugin from "./plugins/basicTextStylePlugin";
import addLinkPlugin from "./plugins/addLinkPlugin";
import createHighlightPlugin from "./plugins/highlightPlugin";
import { InlineStyles } from "./inlineStyles/InlineStyles";
import { styleMap, BlockStyleControls } from "./blockStyles/BlockStyles";
import LinkIcon from "../../../assets/icn-link.svg";
import AtIcon from "../../../assets/icn-at.svg";
import FileIcon from "../../../assets/icn-attachment.svg";
import styles from "./richEditor.module.scss";
import "draft-js/dist/Draft.css";
import MentionsList from "./mentions/mentionsList";
import { Loading } from "modules/primitives/index";
const highlightPlugin = createHighlightPlugin();

class RichEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      open: false,
      suggestions: this.props.mentionsList,
    };

    this.ref = React.createRef();

    this.mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      theme: styles,
      mentionPrefix: "@",
      supportWhitespace: true,
    });

    this.plugins = [
      addLinkPlugin,
      highlightPlugin,
      basicTextStylePlugin,
      this.mentionPlugin,
    ];
  }

  componentDidMount() {
    setTimeout(() => this.focus(), 0);

    if (this.props?.settedValue || this.props?.initialValue) {
      let contentBlock = "";
      this.props.setValid &&
        this.props.setValid(this.props?.settedValue[this.props.discussionId]);
      if (
        this.props?.settedValue &&
        this.props?.settedValue[this.props.discussionId]
      ) {
        contentBlock = convertFromHTML(
          this.props.settedValue[this.props.discussionId]
        );
        this.props.updateValue(
          this.props?.settedValue[this.props.discussionId]
        );
      }

      if (this.props.initialValue) {
        contentBlock = convertFromHTML(this.props.initialValue);
      }

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState });
      }
      // this.setState({
      //   editorState: EditorState.createWithContent(
      //     ContentState.createFromBlockArray(
      //       convertFromHTML(this.props.initialValue)
      //     )
      //   ),
      //   // editorState: EditorState.createWithText(this.props.initialValue),
      // });
    } else {
      this.setState({
        editorState: EditorState.createEmpty(this.decorator()),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.displayedNote !== this.props.displayedNote) {
      const displayedNote = this.props.displayedNote;
      if (typeof displayedNote === "object") {
        this.setState({
          editorState: EditorState.createWithContent(
            convertFromRaw(JSON.parse(this.props.displayedNote.content))
          ),
        });
      } else {
        this.setState({
          editorState: EditorState.createEmpty(),
        });
      }
    }

    if (
      prevProps.shouldReset !== this.props.shouldReset &&
      this.props.shouldReset
    ) {
      this.setState({
        editorState: EditorState.createEmpty(this.decorator()),
      });
    }
    if (
      this.props.newEditorState?.editorState &&
      prevProps.newEditorState?.editorState !==
        this.props.newEditorState?.editorState
    ) {
      this.setState({ editorState: this.props.newEditorState.editorState });
    }
    if (
      this.props.mentionsList &&
      prevProps.mentionsList[0]?.name !== this.props.mentionsList[0]?.name
    ) {
      this.setState({ suggestions: this.props.mentionsList });
    }
    if (
      this.props.initialValue !== prevProps.initialValue &&
      this.props.initialValue &&
      this.props.customFocus
    ) {
      const contentBlock = convertFromHTML(this.props.initialValue);
      // Create EditorState using the content state
      const contentState = ContentState.createFromBlockArray(contentBlock);
      const edState = EditorState.createWithContent(contentState);
      this.setState({
        editorState: edState,
      });
    }
  }

  decorator = () =>
    new CompositeDecorator([
      {
        strategy: this.linkStrategy,
        component: this.Link,
      },
    ]);

  linkStrategy = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    }, callback);
  };

  Link = (props) => {
    const { contentState, entityKey } = props;
    const { url } = contentState.getEntity(entityKey).getData();
    return (
      <a
        className="link"
        rel="noopener noreferrer"
        target="_blank"
        aria-label={url}
        href={url}
      >
        {props.children}
      </a>
    );
  };

  onOpenChange = (_open) => {
    this.setState({ open: _open });
  };

  onSearchChange = (e) => {
    if (this.props.searchFunction) {
      this.props.searchFunction(e.value);
    }
    this.setState({ suggestions: this.props.mentionsList });
  };
  onChange = (_editorState) => {
    if (_editorState.getCurrentContent().getPlainText("\u0001")) {
      const newLocalStoreValue = {
        ...this.props.settedValue,
        [this.props.discussionId]: stateToHTML(
          _editorState.getCurrentContent()
        ),
      };
      localStorage.setItem(
        "temporary_message",
        JSON.stringify(newLocalStoreValue)
      );
    }
    const value = stateToHTML(_editorState.getCurrentContent());
    this.props.updateValue(value);

    if (this.props.customOnChange) {
      this.props.customOnChange(_editorState);
    } else {
      this.setState({
        editorState: _editorState,
      });
      this.props.setValid &&
        this.props.setValid(
          _editorState.getCurrentContent().getPlainText("\u0001")
        );
    }
  };

  toggleInlineStyle = (style) => {
    if (this.props.customOnChange) {
      this.props.customOnChange(
        RichUtils.toggleInlineStyle(this.state.editorState, style)
      );
    } else {
      this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, style));
    }
  };

  toggleBlockType = (blockType) => {
    if (this.props.customOnChange) {
      this.props.customOnChange(
        RichUtils.toggleBlockType(this.state.editorState, blockType)
      );
    } else {
      this.onChange(
        RichUtils.toggleBlockType(this.state.editorState, blockType)
      );
    }
  };

  handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      if (this.props.customOnChange) {
        this.props.customOnChange(newState);
      } else {
        this.onChange(newState);
      }
      return "handled";
    }
    return "not-handled";
  };

  isAddingOrUpdatingLink = () => {
    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();
    const startOffset = editorState.getSelection().getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
    let url = "";
    if (linkKey != null) {
      const linkInstance = contentState.getEntity(linkKey);
      url = linkInstance.getData().url;
      const updatedLink = window.prompt("Update link", url);

      if (!updatedLink) {
        return;
      } else if (url !== updatedLink) {
        const contentWithEntity = contentState.replaceEntityData(linkKey, {
          url: updatedLink,
        });
        const newEditorState = EditorState.push(
          editorState,
          contentWithEntity,
          "create-entity"
        );
        if (this.props.customOnChange) {
          this.props.customOnChange(
            RichUtils.toggleLink(
              newEditorState,
              newEditorState.getSelection(),
              linkKey
            )
          );
        } else {
          this.onChange(
            RichUtils.toggleLink(
              newEditorState,
              newEditorState.getSelection(),
              linkKey
            )
          );
        }
      }
    } else {
      this.onAddLink();
    }
  };

  onAddLink = () => {
    const editorState = this.state.editorState;
    const selection = editorState.getSelection();
    const link = window.prompt("Paste the link:");
    let refactoredLink = "";
    const isValidUrl = /[^ "]+$/.test(link);
    const withOutPreffix = /^(ftp|http|https)/.test(link);

    if (!isValidUrl) {
      this.props.enqueueSnackbar("Invalid url.", {
        variant: "error",
      });
      return;
    }

    if (!withOutPreffix) {
      refactoredLink = `http://${link}`;
    }
    if (!link) {
      if (this.props.customOnChange) {
        this.props.customOnChange(
          RichUtils.toggleLink(editorState, selection, null)
        );
      } else {
        this.onChange(RichUtils.toggleLink(editorState, selection, null));
      }
      return "handled";
    }
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: refactoredLink || link,
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "create-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    if (this.props.customOnChange) {
      this.props.customOnChange(
        RichUtils.toggleLink(newEditorState, selection, entityKey, this.focus())
      );
    } else {
      this.onChange(RichUtils.toggleLink(editorState, selection, entityKey));
    }
  };

  focus = () => {
    if (this.props.dontFocus) {
      return null;
    }

    return this.ref.current?.editor?.focus();
  };

  onAddImage = (e) => {
    e.preventDefault();
    const editorState = this.state.editorState;
    const urlValue = window.prompt("Paste Image Link");
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      {
        src: urlValue,
      }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );
    this.setState(
      {
        editorState: AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          " "
        ),
      },
      () => {
        setTimeout(() => this.focus(), 0);
      }
    );
  };
  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const { open, suggestions } = this.state;

    return (
      <div
        style={this.props.homePageUse ? { position: "relative" } : {}}
        className={styles.richEditor}
      >
        <div className="d-flex">
          <InlineStyles
            editorState={this.state.editorState}
            onToggle={this.toggleInlineStyle}
          />
          <BlockStyleControls
            editorState={this.state.editorState}
            onToggle={this.toggleBlockType}
          />
          <span
            id="link_url"
            onClick={this.isAddingOrUpdatingLink}
            className={classNames(styles.richControl, styles.linkControl)}
          >
            <img src={LinkIcon} alt="Link" />
          </span>
          {this.props.showAditionalButtons && (
            <>
              <span className={styles.atControl}>
                <img src={AtIcon} alt="At" />
              </span>
              <span
                className={classNames(styles.atControl, "m-0")}
                onClick={this.onAddImage}
              >
                <img src={FileIcon} alt="File" />
              </span>
            </>
          )}
        </div>
        <div
          className={styles.editor}
          onClick={() => this.ref.current?.focus()}
        >
          {this.props.dontFocus ? (
            <Editor
              customStyleMap={styleMap}
              plugins={this.plugins}
              editorState={this.state.editorState}
              keyBindingFn={this.props?.onKeyDown}
              onChange={(e) => {
                this.onChange(e);
              }}
              handleKeyCommand={this.handleKeyCommand}
              blockRendererFn={mediaBlockRenderer}
              editorKey="editor"
              ref={this.ref}
              placeholder={this.props.placeholder}
            />
          ) : (
            <Editor
              autoFocus
              customStyleMap={styleMap}
              plugins={this.plugins}
              editorState={this.state.editorState}
              keyBindingFn={this.props?.onKeyDown}
              onChange={(e) => {
                this.onChange(e);
              }}
              handleKeyCommand={this.handleKeyCommand}
              blockRendererFn={mediaBlockRenderer}
              editorKey="editor"
              ref={this.ref}
              placeholder={this.props.placeholder}
            />
          )}
        </div>
        {this.props.searchFunction && (
          <MentionSuggestions
            open={open}
            onOpenChange={this.onOpenChange}
            suggestions={suggestions}
            onSearchChange={this.onSearchChange}
            onAddMention={(e) => {
              this.props.handleAddMention(e);
            }}
            entryComponent={(e) => {
              if (this.props.loadingMentions && e.isFocused) {
                return <Loading />;
              } else if (!this.props.loadingMentions) {
                return (
                  <MentionsList
                    e={e}
                    loadingSearchMention={this.props.loadingMentions}
                  />
                );
              }
              return <div />;
            }}
          />
        )}
      </div>
    );
  }
}
export default withSnackbar(RichEditor);

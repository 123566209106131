/* eslint-disable camelcase */
import * as Yup from "yup";

export const initModel = {
  first: "",
  last: "",
  email: "",
  title: "",
  permission: "standard",
  team_ids: [],
  council_role: "viewer",
  plants: [],
  areas: [],
};

export const permission = [
  { val: "council_administrator", text: "Administrator" },
  { val: "standard", text: "Standard" },
  { val: "viewer", text: "View-Only" },
  { val: "company", text: "Company" },
  // { val: "ttp_administrator", text: "Traction Admin" },
];
export const validation = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string()
    .matches(/^\S*$/, "Email cannot include leading and trailing spaces")
    .min(1, "Email needs to be at least 1 char")
    .max(512, "Email cannot exceed 512 char")
    .required("Email is required"),
});

import { Chart } from 'chart.js';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';

Chart.register(TreemapController, TreemapElement);

export const makeTreeConfig = (data) => {
  const labels = data.map(obj => obj.name);
  const dataSet = data.map(obj => obj.value);

  return {
    type: 'treemap',
    data: {
      datasets: [
        {
          tree: dataSet,
          backgroundColor: '#145BF2',
          borderColor: 'white',
          borderWidth: 1,
          spacing: 0,
          labels: {
            align: 'left',
            display: true,
            formatter(ctx) {
              if (ctx.type !== 'data') {
                return;
              }

              return labels[ctx.dataIndex];
            },
            color: 'white',
            font: {
              size: 12,
            },
            hoverFont: {
              size: 14,
              weight: 'bold'
            },
            position: 'top'
          },
        }
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: (item) => {
              return `${dataSet[item.dataIndex]} Replications`;
            }
          }
        }
      },
    }
  };
}

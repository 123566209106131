import React from "react";

import styles from './styles.module.scss'

const LoaderWithDots = ({ text, hideText, size = 'small' }) => (
  <div className="d-flex">
    {!hideText && <span className={`${styles.loaderText} ${styles[size]}`}>{text}</span>}
    <div className={styles.loaderDots}>
      <span />
      <span />
      <span />
    </div>
  </div>
)

export default React.memo(LoaderWithDots);

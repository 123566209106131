import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import {
  getKPIsInnovationCategory,
  getKPIsTableData,
  toggleKPIsTable,
} from "modules/reporting/fordReports/kpis/store/kpis.actions";
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import LoadingComponent from "modules/primitives/loading/loading.component";
import genericStyles from '../../styles.module.scss';
import ReportingDoughnutChart from "modules/reporting/components/reportingDoughnutChart";

const FordInnovationCategory = ({ innovationCategory, filters, tableData, downloadIsActive }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!innovationCategory.data) {
      const makeFilters = makeActualScoreCardFilterData(filters);
      const payload = {
        enqueueSnackbar,
        data: makeFilters,
      };

      dispatch(getKPIsInnovationCategory(payload))
    }
  }, []);

  const cardHasExpandedTable = useMemo(() => tableData?.expandedKey === 'projects_by_stage',
    [tableData?.expandedKey]);

  const handleShowOrHideTable = useCallback(() => {
    if (tableData?.expandedKey !== 'projects_by_innovation_category') {
      const filtersData = makeActualScoreCardFilterData(filters);
      dispatch(getKPIsTableData({
        reducerKey: 'projects_by_innovation_category',
        data: {
          ...filtersData,
          section: 'projects_by_innovation_category',
        },
      }));

      return;
    }

    dispatch(toggleKPIsTable({
      reducerKey: 'projects_by_innovation_category',
    }));
  }, [tableData, filters]);

  const makeColumnCardStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      border: '1px solid #cbd2d9',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px',
      height: '100%',
      padding: '10px',
    }
  }, [downloadIsActive]);

  const makeTitleStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      color: '#616e7c',
      fontSize: '18px',
      fontFamily: 'lato-regular',
      paddingTop: '10px',
    }
  }, [downloadIsActive]);

  return (
    <div style={makeColumnCardStylesForPdf} className={genericStyles.columnCard}>
      {
        innovationCategory.fetching ? (
          <LoadingComponent customText="Getting innovation category..." />
        ) : (
          <div className="d-flex flex-column">
            <span style={makeTitleStylesForPdf} className={genericStyles.columnCardTitle}>Innovation Category</span>
            <div className="d-flex justify-content-center">
              <ReportingDoughnutChart
                chartData={{
                  chart_data: innovationCategory.data
                }}
                id="innovationCategory"
                instance="innovationCategory"
                selectedTags={[]}
                handleSegmentClick={() => {
                }}
                handleResetAll={() => {
                }}
                renderPieInstance
              />
            </div>
            {
              !downloadIsActive && (
                <div className="d-flex justify-content-center mt-2 mb-1">
                  <span className={genericStyles.showHideSourceData} onClick={() => handleShowOrHideTable()}>
                    {cardHasExpandedTable ? 'HIDE SOURCE DATA' : 'SHOW SOURCE DATA'}
                  </span>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default React.memo(FordInnovationCategory);

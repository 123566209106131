import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import styles from "./styles.module.scss";

import { MoreOptions } from "../../../../primitives";
import { moreOptions } from "./constant";
import { parceVisibility } from "../activity.constant";
import { Icon, lgSize } from "../../../../../common/icon";
import AccessToggle from "../../../../../common/components/customFields/accessToggle";

const ProjectComponent = (props) => {
  const {
    note,
    teamList,
    handleEditClick,
    handleDeleteClick,
    orgType,
    hasAccess,
    blockFunctionality = false,
    isLastElem,
    isAddedByMe,
    isMobile,
  } = props;

  const handleMoreOptionClick = (props) => {
    handleEditClick(note);
    if (props === 5) {
      handleEditClick(note);
    }
    if (props === 6) {
      handleDeleteClick(note);
    }
  };

  return (
    <div className={`${styles.wrapper} ${isLastElem ? styles.noBorder : ""}`}>
      <div className={styles.container}>
        {isAddedByMe && (
          <div className={styles.toggle}>
            <AccessToggle item={note} orgType={orgType} />
          </div>
        )}
        <div className={styles.titleRow}>
          {isMobile ? null : (
            <div className={styles.iconWrp}>
              <Icon
                {...lgSize}
                size={22}
                icon="icn-edit-button"
                className={styles.icon}
              />
            </div>
          )}
          <h5 className={styles.title}>
            {isMobile ? (
              <Icon
                {...lgSize}
                size={22}
                icon="icn-edit-button"
                className={styles.icon}
              />
            ) : null}
            Project
          </h5>
          <div className={styles.rightWrp}>
            <div className="d-flex align-items-center">
              <span className={styles.date}>
                {note?.updated_at
                  ? format(new Date(note.updated_at), "EEEE, MMMM dd, yyyy ")
                  : null}
              </span>
              <div className={styles.colMore}>
                {hasAccess && (
                  <MoreOptions
                    options={moreOptions}
                    className={styles.moreOptWrp}
                    onClick={handleMoreOptionClick}
                    customSvgIcon
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.subject}>
          <span>{note && note.title}</span>
        </div>
        <div
          className={classNames(styles.detail, styles.note)}
          dangerouslySetInnerHTML={{ __html: note?.summary || "" }}
        />
        <div className={styles.infoWrp}>
          <div className="w-50 d-flex">
            <div className={styles.dataRow}>
              <div className={styles.label}>
                {note.updated_at !== note.created_at
                  ? "Updated by"
                  : "Created by"}
              </div>
              <div className={styles.value}>
                <Link to={`/people/internal/${note.creator_id}`}>
                  {note && note.created_by}
                </Link>
              </div>
            </div>
            {note.visibility && (
              <div className={styles.dataRow}>
                <div className={styles.label}>Visible </div>
                <div className={styles.value}>
                  {note
                    && parceVisibility(note.visibility, teamList, note.team_ids)}
                </div>
              </div>
            )}
            {note.status && (
              <div className={styles.dataRow}>
                <div className={styles.label}>Status </div>
                <div className={styles.value}>
                  {note && note.status}
                </div>
              </div>
            )}
          </div>
          <div className="w-50">
            {orgType === "patient" ? (
              <div className={styles.centerWrp}>
                <div className={classNames(styles.infoWrpColumn, styles.dataRow, styles.projectTopics, "mb-3")}>
                  <div className={styles.label}>Project Topics:</div>
                  <div className={styles.detail}>
                    {note && note.project_topics
                      ? note.project_topics.map((d) => (
                        <div>
                          {d}
                          <br />
                        </div>
                      ))
                      : "-"}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectComponent;

import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { withSnackbar } from "notistack";

import styles from "./myCompanies.module.scss";
import { Pannel } from "../../primitives";
import Company from "./company/company.component";
import { getFollowersAttempt } from "../../settings/settings.action";

const MyCompanies = ({ enqueueSnackbar }) => {
  const dispatch = useDispatch();
  const {
    appReducer: { relationShipStatuses },
    settingsReducer: { followers, updateFollowers, removedCompany },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const gridRef = useRef();
  useEffect(() => {
    if (selectedCouncil?.id) {
      dispatch(
        getFollowersAttempt({
          data: { councilId: selectedCouncil.id },
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil]);

  return (
    <Pannel
      title="My Companies"
      padding="0"
      parentStyle={{
        background: "Transparent",
        marginRight: window.screen.width > 550 ? 30 : 0,
        padding: window.screen.width > 550 ? 7 : 0,
      }}
      position="relative"
      overflow="hidden"
    >
      <div className={styles.wrapper}>
        <div className={styles.grid}>
          <div className={styles.gridHeader}>
            <div className={styles.company}>Company</div>
            <div className={styles.activity}>Activity</div>
            <div className={styles.status}>Stage</div>
            <div className={styles.score}>Score</div>
            <div className={styles.following} />
          </div>
          <div className={styles.hiddenOverflowContainer}>
            <div ref={gridRef} className={styles.gridBody}>
              {followers
                ? followers.map((c, idx) => (
                    <Company
                      key={c.following_id}
                      company={c}
                      enqueueSnackbar={enqueueSnackbar}
                      removedCompany={removedCompany}
                      updateFollowers={updateFollowers}
                      relationShipStatuses={relationShipStatuses}
                      gridRef={gridRef}
                      idx={idx}
                    />
                  ))
                : null}
            </div>
          </div>
          <div className={styles.gridFooter}>
            {followers?.length ? (
              <Link to="/list/following">SEE ALL MY COMPANIES</Link>
            ) : (
              <Link to="/companies">SEE All COMPANIES</Link>
            )}
          </div>
        </div>
      </div>
    </Pannel>
  );
};

export default React.memo(withSnackbar(MyCompanies));

import React, { useEffect, useState, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import ReactTable from "react-table";
import {
  Loading,
  MoreOptions,
  OptionType,
  Button,
  BtnType,
  Search,
} from "modules/primitives/index";
import { CSVLink } from "react-csv";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import styles from "./adminMembers.module.scss";
import Tooltip from "@mui/material/Tooltip/Tooltip";

import "react-table/react-table.css";
import {
  councilUsersListGet,
  peopleListTeamsGet,
} from "../../../common/actions/common.action";
import { companySearchSuccess } from "modules/companies/companyMain/companyMain.action";
import { useModelPopup } from "../../../common/hooks";
import ConfirmDeleteComponent from "../../../common/components/confirmDelete";
import MemberNewComponent from "../../people/internalPeople/memberNew";
import EditMemberComponent from "./editMember/editMember.component";

import { httpPost } from "../../../common/httpCall";

const AdminMembersComponent = () => {
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [csvData, setCsvData] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [exportLoading, setExportLoading] = useState(false);
  const csvLinkRef = useRef();
  const [showTooltip, setShowTooltip] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: {
      selectedCouncil,
      usersList,
      updateUsers,
      userPageNr,
      loading,
      invitingMember,
    },
    companiesReducer: {
      companyMainReducer: { searchUsersResults },
    },
  } = useSelector((state) => state);

  const users =
    searchUsersResults && searchUsersResults[0]
      ? searchUsersResults
      : usersList;
  const pageSizeOptions = [pageSize, 5, 10, 20, 50, 100];

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(councilUsersListGet({ enqueueSnackbar, page, pageSize }));
    }
  }, [selectedCouncil, pageSize, page]);

  useEffect(() => {
    if (selectedCouncil && updateUsers) {
      dispatch(councilUsersListGet({ enqueueSnackbar, page, pageSize }));
    }
  }, [updateUsers]);

  useEffect(() => {
    // clearing search results component on unmount
    return () => {
      dispatch(
        companySearchSuccess({
          mode: "councils/users",
          response: [],
          status: 200,
        })
      );
    };
  }, []);

  const handleAddNewMember = () => {
    popup.show({
      title: "Add Member",
      show: true,
      height: "300",
      width: "540",
      component: <MemberNewComponent excludeCompanyRole />,
    });
  };

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
  };

  const handlePageSelect = (e) => {
    setPage(e.target.value);
  };

  const handleNextClick = () => {
    setPage(page + 1);
  };

  const handlePrevClick = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const moreOptions = [
    { val: "edit", text: "Edit" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const handleMoreOptionClick = (event, user) => {
    const user_id = user.user_id || user.id;
    if (event === "edit") {
      popup.show({
        title: "Edit Member",
        show: true,
        height: "400",

        width: "540",
        component: (
          <EditMemberComponent user={user} userId={user.user_id || user.id} />
        ),
      });
    }
    if (event === "delete") {
      popup.show({
        title: "Confirmation Prompt",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={user_id}
            enqueueSnackbar={enqueueSnackbar}
            actionType="deleteMember"
            memberName={user.first_name + " " + user.last_name}
          />
        ),
      });
    }
  };

  const DisabledButton = ({ showTooltip, setShowTooltip }) => (
    <>
      {showTooltip && (
        <div className={styles.exportModalTooltip}>
          <p>
            {searchUsersResults?.length
              ? "Search cannot be exported "
              : "To be able to export list it must contain at least one element."}
          </p>
        </div>
      )}
      <Button
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
        className={styles.deleteSubmitButton}
        btn={BtnType.DISABLED}
      >
        EXPORT
      </Button>
    </>
  );

  const isNextDisabled = () => {
    if (page + 1 > userPageNr?.pages) {
      return styles.disabledButton;
    }
    return styles.paginationButton;
  };
  const prepareDataName = (name) => {
    if (name.length > 24) {
      return (
        <Tooltip placement="bottom" title={`${name}`}>
          <span>{name.slice(0, 24) + " ... "}</span>
        </Tooltip>
      );
    }

    return <span>{name}</span>;
  };

  const getBigTeamLeast = (tags) => {
    return (
      <>
        {tags.slice(0, 4).map((name) => (
          <span>
            {prepareDataName(name)}
            <br />
          </span>
        ))}

        <Tooltip placement="bottom" title={`${tags.join(", ")}`}>
          <span className={styles.moreTagslink}>{" More "}</span>
        </Tooltip>
      </>
    );
  };
  const handleExportList = () => {
    setExportLoading(true);
    httpPost({
      call: `councils/users/search?items=${userPageNr?.total}`,
      data: {
        query: "*",
        search: {
          topic_ids: [],
          industry_ids: [],
          teams_ids: [],
          council_roles: [
            "ttp_administrator",
            "council_administrator",
            "standard",
            "viewer",
          ],
        },
      },
    }).subscribe(
      async (res) => {
        setExportLoading(false);
        setCsvData(() => {
          const csvDataCont =
            selectedCouncil?.name === "Ford"
              ? [
                  [
                    "Name",
                    "Email",
                    "Team",
                    "Plants",
                    "Areas",
                    "Pillars",
                    "Access Level",
                  ],
                ]
              : [["Name", "Email", "Team", "Access Level"]];
          res.response.map((member) => {
            const role = () => {
              if (member.council_role) {
                if (member.council_role === "ttp_administrator") {
                  return "TTP Administrator";
                }
                if (member.council_role === "council_administrator") {
                  return "Administrator";
                }
                if (member.council_role === "standard") {
                  return "Standard";
                }
              }
            };

            if (selectedCouncil?.name === "Ford") {
              csvDataCont.push([
                member.full_name,
                member.email,
                member.teams_names.map((team) => team).join(", "),
                member.plants.map((plant) => plant.name).join(", "),
                member.areas.map((area) => area.name).join(", "),
                member.themes?.map((theme) => theme.name).join(", "),
                role(),
              ]);
            } else {
              csvDataCont.push([
                member.full_name,
                member.email,
                member.teams_names.map((team) => team).join(", "),
                role(),
              ]);
            }
          });

          return csvDataCont;
        });
        csvLinkRef.current.link.click();
      },
      (err) => {
        setExportLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      },

      () => {
        setExportLoading(false);
        enqueueSnackbar("List succesfully exported.", {
          variant: "success",
        });
      }
    );
  };

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        peopleListTeamsGet({ councilId: selectedCouncil.id, enqueueSnackbar })
      );
    }
    return () => null;
  }, [selectedCouncil]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "full_name",
        maxWidth: 200,
        minWidth: 140,
        width: 200,
      },
      {
        Header: "Email",
        accessor: "email",
        maxWidth: 200,
        minWidth: 140,
        width: 200,
      },
      {
        Header: "Team",
        accessor: (row) => {
          const teamnames = row?.teams_names
            ? row.teams_names.length > 4
              ? getBigTeamLeast(row.teams_names)
              : row.teams_names.map((name) => (
                  <span>
                    {prepareDataName(name)}
                    <br />
                  </span>
                ))
            : "";
          return teamnames;
        },
        id: "teams_names",
        maxWidth: 200,
        minWidth: 140,
        width: 200,
      },
      selectedCouncil?.name === "Ford" && {
        Header: "Plants",
        id: "plants",

        accessor: (row) =>
          row.plants ? row.plants.map((plant) => plant.name).join(", ") : "",

        maxWidth: 200,
        minWidth: 140,
        width: 200,
      },
      selectedCouncil?.name === "Ford" && {
        Header: "Areas",
        id: "areas",

        accessor: (row) =>
          row.plants ? row.areas?.map((area) => area.name).join(", ") : "",
        maxWidth: 200,
        minWidth: 140,
        width: 200,
      },
      selectedCouncil?.name === "Ford" && {
        Header: `${selectedCouncil?.settings?.themes_display_name || "Theme"}`,
        id: "pillar",
        accessor: (row) =>
          row.themes ? row.themes?.map((el) => el.name).join(", ") : "",
        maxWidth: 200,
        minWidth: 140,
        width: 200,
      },
      {
        Header: "Access Level",
        id: "council_role",
        accessor: (row) => {
          if (row.council_role) {
            if (row.council_role === "ttp_administrator") {
              return "TTP Administrator";
            }
            if (row.council_role === "council_administrator") {
              return "Administrator";
            }
            if (row.council_role === "standard") {
              return "Standard";
            }
            if (row.council_role === "viewer") {
              return "View-Only";
            }
            if (row.council_role === "company") {
              return "Company";
            }
          }
          return "";
        },
        maxWidth: 200,
        minWidth: 120,
        width: 120,
      },
      {
        Header: "Options",
        id: "options",
        sortable: false,
        accessor: (row) => (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row);
            }}
          />
        ),
        width: 100,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [users]
  );

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <span
            className={styles.rootText}
            onClick={() => {
              history.push("/admin");
            }}
          >
            ADMIN
          </span>
          <span className={styles.separationBar}> / </span>
          <span className={`${styles.rootText} ${styles.activeBreadcrumbs}`}>
            MEMBERS
          </span>
          <span className={styles.separationBar}> / </span>
          <span className={styles.rootText}>
            {" "}
            <Link to="/admin/members/invited">INVITED CONTACTS</Link>
          </span>
        </h4>
      </div>

      <div className={styles.bodyWrp}>
        <Search className={styles.search} mode="councils/users" />
        <div className={styles.buttonWrp}>
          <div>
            <Button
              className={styles.addNew}
              disabled={invitingMember}
              btn={invitingMember ? BtnType.OUTLINE : BtnType.REGULAR}
              icon="icn-add"
              onClick={handleAddNewMember}
            >
              Add member
            </Button>
          </div>
          {exportLoading ? (
            <Loading />
          ) : userPageNr?.total > 0 && !searchUsersResults?.length ? (
            <div>
              <Button
                // disabled={!listTotalCount}
                onClick={handleExportList}
                btn={BtnType.OUTLINE}
              >
                EXPORT
              </Button>
              <CSVLink
                data={csvData}
                filename="Traction Members.csv"
                style={{ display: "none" }}
                ref={csvLinkRef}
              />
            </div>
          ) : (
            <DisabledButton
              setShowTooltip={setShowTooltip}
              showTooltip={showTooltip}
            />
          )}
          <div className={styles.totalCount}>
            {userPageNr?.total || "0"} members
          </div>
        </div>
        <div className={styles.tableContainer}>
          {" "}
          <ReactTable
            data={users}
            columns={columns}
            loading={loading ? <Loading /> : null}
            pageSize={pageSize}
            minRows={5}
            className={styles.table}
            showPagination={false}
          />
          <div className={styles.pagination}>
            <div
              className={
                page - 1 === 0 ? styles.disabledButton : styles.paginationButton
              }
              onClick={handlePrevClick}
            >
              Previous
            </div>
            <div className={styles.paginationSettings}>
              <span style={styles.pageNr}>
                <span>
                  <input
                    onChange={(e) => handlePageSelect(e)}
                    value={page}
                    className={styles.inputBox}
                    type="number"
                    min="1"
                  />
                </span>
                <span>of</span>
                <span> {userPageNr?.pages}</span>
              </span>

              <span>
                <select onChange={(e) => handleChangePageSize(e)}>
                  {pageSizeOptions.map((option) => (
                    <option value={option}>{option}</option>
                  ))}
                </select>
              </span>
            </div>
            <button
              className={isNextDisabled()}
              onClick={handleNextClick}
              disabled={page + 1 > userPageNr?.pages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminMembersComponent;

import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import checkUniqName from "common/checkUniqName";
import checkDuplicateName from "common/checkDuplicateName";

import { CheckBox, CheckType, Label, TextBox } from "modules/primitives/index";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { model, currencies, currencySymbol } from "./constant";

import findCurrentCustomFieldGroup from "../../../findCustomFieldGroup";

const NewNumberComponent = (props) => {
  const {
    formRef,
    handleOnSubmit,
    data,
    fromAdmin = false,
    mode,
    rfiInstance,
    adminLocation,
    selectedCustomFieldGroup,
    indexOfCfGroup,
  } = props;
  const [displayCheckboxes, setDisplayCheckboxes] = useState("Number");
  const [checked, setChecked] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [titleError, setTitleError] = useState("");
  const {
    companiesReducer: {
      companyReducer: { newCustomFieldGroups },
    },
    adminCustomFieldsReducer: { customFieldGroups },
  } = useSelector((state) => state);

  useEffect(() => {
    if (data) {
      if (data.option_values?.length > 0) {
        setChecked(data.option_values[0]);
        setDisplayCheckboxes("Currency");
      } else {
        setDisplayCheckboxes("Number");
        setChecked("");
      }
    }
  }, []);

  const initValues = data
    ? {
        field_type: data.field_type,
        field_value: data.field_value,
        title: data.field_name,
        field_placeholder: data.field_placeholder || "",
      }
    : null;

  const onSubmit = (val, resetForm) => {
    const cfGroup =
      findCurrentCustomFieldGroup(
        selectedCustomFieldGroup,
        customFieldGroups,
        newCustomFieldGroups,
        indexOfCfGroup,
        data
      ) || [];

    if (!val.title.length) {
      const field_name = checkDuplicateName(cfGroup, "number");
      val.title = field_name;
    } else if (
      !checkUniqName(val.title || val.field_value, cfGroup) &&
      val.title?.trim()?.toLowerCase() !==
        data?.field_name?.trim()?.toLowerCase()
    ) {
      setTitleError("Field name must be unique. Please rename this field");
      return;
    }
    setTitleError("");

    setSubmitted(true);
    if (displayCheckboxes === "Currency" && checked) {
      handleOnSubmit({ ...val, option_values: [checked], options: [checked] }); // need to be refactored
    } else if (displayCheckboxes === "Number") {
      handleOnSubmit({ ...val, option_values: [], options: [] }); // need to be refactored
    }
    val.title = "";

    resetForm();
  };

  const handleOnCheck = (val) => {
    if (val && val === displayCheckboxes) {
      if (val === "Currency") {
        setDisplayCheckboxes("Number");
        setChecked("");
      } else {
        setDisplayCheckboxes("Currency");
        setChecked("USD");
      }
    } else {
      setDisplayCheckboxes(val);
      if (val === "Currency") {
        setChecked("USD");
      } else {
        setChecked("");
      }
    }
  };

  const handleSelection = (val) => {
    if (val === checked && displayCheckboxes === "Currency") {
      setChecked("USD");
    } else {
      setChecked(val);
    }

    if (displayCheckboxes === "Number") {
      setDisplayCheckboxes("Currency");
      setChecked(val);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={data ? initValues : model}
        onSubmit={(values, { setSubmitting, resetForm, setFieldError }) => {
          setSubmitting(true);
          onSubmit(values, resetForm);
        }}
      >
        {({ handleSubmit, values, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <Label>Title</Label>
            <TextBox
              disabled={rfiInstance}
              type="text"
              name="title"
              placeholder="Choose a title"
              formProps={{ ...formikprops, values }}
              className={styles.textBox}
            />
            {titleError.length > 1 && (
              <div className={styles.errorBox}>
                <sup>*</sup>
                {titleError}
              </div>
            )}

            {!adminLocation && (
              <div className="d-flex flex-column align-items-start">
                <Label>Instructions (Optional)</Label>
                <TextBox
                  type="text"
                  name="field_placeholder"
                  placeholder="Add instructions"
                  formProps={{ ...formikprops, values }}
                  className={styles.textBox}
                />
              </div>
            )}
            <Label>Number</Label>
            <div className={styles.checkItem}>
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={displayCheckboxes === "Number"}
                onChange={(val) => handleOnCheck("Number")}
              />
              <div className={classNames(styles.checkLabel)}>Number</div>
            </div>
            {!fromAdmin && (
              <div className={styles.inputWrp}>
                {" "}
                {displayCheckboxes === "Currency" &&
                  currencySymbol.find(({ name }) => name === checked)?.symbol}
                <TextBox
                  type="number"
                  name="field_value"
                  placeholder="Enter a number"
                  formProps={{ ...formikprops, values }}
                  className={styles.inputBox}
                  // onChange={(val) => handleInput(val)}
                />
              </div>
            )}
            {/* {!formRef?.current?.field_value && submitted && (
              <div className={styles.errorMsg}>Required field</div>
            )} */}

            <div className={styles.checkItem}>
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={displayCheckboxes === "Currency"}
                onChange={() => handleOnCheck("Currency")}
              />
              <div className={classNames(styles.checkLabel)}>Currency</div>
            </div>
            <div className={styles.checkWrapper}>
              {currencies.map((currency) => (
                <div key={currency} className={styles.checkItem}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={checked === currency}
                    disabled={currency === "USD" && checked === currency}
                    onChange={() => handleSelection(currency)}
                  />
                  <div
                    className={classNames(
                      styles.checkLabel,
                      fromAdmin && mode === "edit" && styles.disabledBox
                    )}
                  >
                    {currency}
                  </div>
                </div>
              ))}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
export default React.memo(NewNumberComponent);

import { createSelector } from "reselect";

const getDiscussions = (state) => state.discussionsReducer.privateDiscussions;
const getMemberDetails = (state) => state.peopleReducer.internalPeopleReducer.selectedMember;

export const getUpdatedMember = createSelector(
  getMemberDetails,
  getDiscussions,
  (user, discussions) => {
    if (!discussions || !discussions?.length) {
      return user;
    }

    const discussion = discussions.find(
      (d) => d.discussion_resource_type === "User"
        && d.discussion_resource_id === user?.id,
    );

    return {
      ...user,
      discussion,
    };
  },
);

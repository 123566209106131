import React from "react";
import DropDownNestedElements from "common/components/dropdownNestedElements";
import styles from "../areaOfFocus.module.scss";
import { Label } from "../../../../../primitives";
import { Icon, smSize } from "../../../../../../common/icon";

const TechModal = ({
  selectedTopics,
  handleTopicChange,
  prevTopics,
  handleTopicRemove,
  topics,
}) => (
  <>
    <Label>Technology</Label>
    <DropDownNestedElements
      tags={topics}
      selectedTags={selectedTopics}
      childKey="children_topics"
      tagKeyName="id"
      parentKeyName="parent_resource_topic_id"
      handleTagSelect={handleTopicChange}
      label="Select technologies"
      // handleTagRemove={handleTopicRemove}
      showPrevSeparate
    />
    <div>
      {prevTopics &&
        prevTopics.map((item) => (
          <div key={item.topic.id} className={styles.techItem}>
            {item.topic.name}{" "}
            <span onClick={() => handleTopicRemove(item.topic.id)}>
              <Icon {...smSize} icon="icn-button-delete" />
            </span>
          </div>
        ))}
    </div>
  </>
);

export default TechModal;

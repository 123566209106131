import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import { checkViewerRole } from "common/checkers/viewerChecker";
import {
  UserInline,
  UserInlineTypes,
  MoreOptions,
  Button,
  BtnType,
} from "../../../../primitives";
import styles from "./companyContact.module.scss";
import { moreOptions } from "./companyContact.constant";
import NewItemComponent from "../addNew/index";
import NewContactMSFTChoice from "../newContactMSFTChoice/index";
import { deleteContact } from "../../company.action";
import { useModelPopup } from "../../../../../common/hooks";
import { Icon, lgSize } from "../../../../../common/icon";
import NewDiscussion from "../../../../../common/components/newDiscussion/newDiscussion.component";
import { appModelSettingSet } from "application/app.action";
import InviteMemberModal from "./inviteMember";

const CompanyContactComponent = ({ companyContacts, isClaimProfileUse }) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [shouldRerender, setShouldRerender] = useState(false);

  const {
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  const handleContactEdit = () => {
    setShouldRerender(true);
    const timeout = setTimeout(() => {
      setShouldRerender(false);
      clearTimeout(timeout);
    }, 1000);
  };

  const handleInviteMember = (contact) => {
    popup.show({
      title: "Invite Contact",
      show: true,
      height: "300",
      width: "540",
      component: (
        <InviteMemberModal
          contact={contact}
          preselectedAccessType={{ val: 4, text: "Company" }}
        />
      ),
    });
  };

  const handleEditClick = (data) => {
    popup.show({
      title: "Edit Company Contact",
      component: (
        <NewItemComponent
          type="contact"
          data={data}
          isClaimProfileUse={isClaimProfileUse}
          cb={handleContactEdit}
        />
      ),
    });
  };

  const handleAddClick = () => {
    popup.show({
      title: "Add Company Contact",
      component: (
        <NewContactMSFTChoice
          contactType="internal_contact"
          isClaimProfileUse={isClaimProfileUse}
        />
      ),
    });
  };

  const handleMoreOptionClick = (options, contact) => {
    if (options === 5) {
      handleEditClick(contact);
    }
    if (options === 6) {
      dispatch(
        deleteContact({
          company_contact_id: contact.id,
          enqueueSnackbar,
          cb: () => {},
        })
      );
    }
  };

  const startNewDiscussion = (user) => {
    const customSettings = {
      title: "Start a New Private Discussion",
      show: true,
      height: "300",
      width: "540",
      component: <NewDiscussion type="Council" isPrivate selectedUser={user} />,
    };
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  const handleShow = (details) => {
    if (details.showNewModal) {
      startNewDiscussion(details);
    }
  };

  const showContactsInvite = () => {
    if (!companyContacts || isClaimProfileUse) {
      return false;
    }

    return (
      companyContacts.filter((c) => c.invited_at).length <
      companyContacts.length
    );
  };

  return (
    <div className="position-relative">
      <div className={styles.addButtonWrapper}>
        {!blockFunctionality ? (
          <Button
            btn={BtnType.FRAME_LESS}
            className={styles.button}
            icon="icn-add"
            onClick={() => handleAddClick()}
          >
            Add
          </Button>
        ) : (
          <Tooltip
            title="You have View-Only access. To Add a Contact, please ask your Admin to upgrade your account to Standard access."
            placement="top"
          >
            <div>
              <Button
                btn={BtnType.DISABLED}
                className={styles.button}
                icon="icn-add"
              >
                Add
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
      <div className={styles.wrapper}>
        {shouldRerender && <span>Reloading contacts...</span>}
        {!shouldRerender && companyContacts?.length ? (
          companyContacts.map((el, i) => (
            <div
              className={styles.itemContainer}
              key={`${el.company_contact_id}-${i}`}
            >
              <div className={styles.leftContainer}>
                <div>
                  <div className={styles.userWrp}>
                    <UserInline
                      className={styles.userIcon}
                      classObj={{ name: styles.userName }}
                      type={UserInlineTypes.WITH_NAME}
                      showMine={false}
                      id={el.id}
                      first={el.first_name || ""}
                      last={el.last_name || ""}
                      avatar={el.avatar}
                      noNavigation
                      local
                      origin={el.origin || ""}
                    />
                  </div>
                  <div className={styles.userDetails}>
                    {el?.origin ? (
                      <div
                        className={classNames("caption small", styles.position)}
                      >
                        Imported from {el.origin}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      className={classNames("caption small", styles.position)}
                    >
                      {el.email}
                    </div>
                    <div className="caption">
                      {el.title}
                      {el && el.department_or_organization
                        ? `, ${el.department_or_organization}`
                        : ""}
                    </div>
                    <div className="caption">{el.mobile && el.mobile}</div>
                    {el?.product && (
                      <div className="caption">Product: {el.product?.name}</div>
                    )}
                  </div>
                </div>
                {!isClaimProfileUse &&
                  (el.invited_at ? (
                    el.claimed_profile ? (
                      <div className={styles.companyAccess}>
                        <span>&#x2713; Access</span>
                      </div>
                    ) : (
                      <Button
                        btn={BtnType.FRAME_LESS}
                        className={styles.button}
                        onClick={() => handleInviteMember(el)}
                      >
                        Invite Pending
                      </Button>
                    )
                  ) : (
                    <Button
                      btn={BtnType.FRAME_LESS}
                      className={styles.button}
                      // icon="icn-add"
                      onClick={() => handleInviteMember(el)}
                    >
                      INVITE
                    </Button>
                  ))}
              </div>
              <div
                className={`${styles.rightContainer} ${
                  isClaimProfileUse ? "border-0" : ""
                }`}
              >
                {el?.user_id && !isClaimProfileUse ? (
                  <div className={styles.iconContainer}>
                    <Icon
                      {...lgSize}
                      icon="icn-table-message"
                      className={styles.messageIcon}
                      onClick={() => {
                        window.open(`mailto:${el?.email}`, "_blank");
                      }}
                    />
                  </div>
                ) : null}
                <div className={styles.colMore}>
                  {!blockFunctionality && (
                    <MoreOptions
                      options={
                        isClaimProfileUse && !el.added_by_company_user
                          ? moreOptions.filter((o) => o.text !== "Delete")
                          : moreOptions
                      }
                      className={styles.moreOptWrp}
                      onClick={(val) => handleMoreOptionClick(val, el)}
                    />
                  )}
                </div>
              </div>
            </div>
          ))
        ) : shouldRerender ? null : (
          <span>No company contacts found</span>
        )}
      </div>
      {showContactsInvite() && (
        <>
          <div className={styles.footer}>
            <div className={styles.addButtonWrapperButtom}>
              <span
                // btn={BtnType.FRAME_LESS}
                className={styles.inviteSpan}
                // icon="icn-add"
                // onClick={() => handleAddClick()}
              >
                INVITE
              </span>
            </div>
            <span className={styles.footerText}>
              Granting access allows Contacts to submit information and
              documents related to their company.
            </span>
          </div>
          {
            selectedCouncil?.traction_tag === "firmenich" ? (
              <div className={styles.warningTextContainer}>
                {" "}
                <div className={styles.warningSign}>
                  <div className={styles.exclamationMark}>&#33;</div>
                </div>
                <div className={styles.warningText}>
                  Please speak to the Relationship Owners before reaching out to the
                  company.
                </div>
              </div>
            ) : (
              ""
            )
          }
        </>
      )}
    </div>
  );
};

export default React.memo(CompanyContactComponent);

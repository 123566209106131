import React, { memo, useEffect, useState } from "react";

import styles from "../reportingLineChart.module.scss";

const LineChart = ({ width, chartHeight, id }) => {
  const [localWidth, setLocalWidth] = useState(null);

  useEffect(() => {
    if (localWidth !== width) {
      setLocalWidth(width);
    }
  }, [width, localWidth]);

  return (
    <div className="d-flex flex-column">
      <div className={styles.chartWrapper} style={{ width: `${width}px` }}>
        <canvas id={`lineChart${id}`} width={width} height={chartHeight} />
      </div>
    </div>
  );
};

export default memo(LineChart);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Select from "react-dropdown-select";

import styles from "./theIdea.module.scss";
import mainStyles from "../../../ideasWrapper.module.scss";
import { Icon, mdSize } from "../../../../../common/icon";
import { BtnType, Button } from "../../../../primitives";
import { editIdeaAttempt } from "../../../idea.action";
import { makeStages } from "../../../../../common/helper";
import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";

const stages = makeStages(new Date().getFullYear());

const IdeaProfileTheIdea = ({ canEdit, ideaDetails, selectedCouncil }) => {
  const {
    adminPlantsAndAreasReducer: { plantsAndAreas },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [nextStage, setNextStage] = useState({
    id: ideaDetails?.next_stage_quarter,
    name: ideaDetails?.next_stage_quarter,
  });
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [descriptionTemplates, setDescriptionTemplates] = useState([]);

  useEffect(() => {
    if (!plantsAndAreas.length && selectedCouncil?.name === "Ford") {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        })
      );
    }
  }, [plantsAndAreas]);

  useEffect(() => {
    if (ideaDetails) {
      const filteredTemplates = ideaDetails?.ideas_descriptions?.filter(
        (t) =>
          t.category_type !== "area_of_focus" &&
          t.section_name !== "Innovation Pillar" &&
          t.section_name !== "Possible Replications (Plants)-"
      );
      setDescriptionTemplates(filteredTemplates || []);
    }
  }, [ideaDetails]);

  const handleTheIdeaEdit = () => setIsEditModeActive(true);
  const handleCancelClick = () => setIsEditModeActive(false);
  const handleSave = () => {
    const everythingIsFilled = descriptionTemplates.filter((t) => {
      if (t.ideas_section.field_required) {
        if (t.section_values !== undefined) {
          return !t.section_values?.length;
        }
        return !t.section_value?.length;
      }

      return false;
    });

    if (everythingIsFilled.length !== 0) {
      enqueueSnackbar("Please fill all empty fields first!", {
        variant: "error",
      });

      return;
    }

    setIsEditModeActive(false);

    const payload = {
      idea: {
        id: ideaDetails.id,
        ideas_template_id: ideaDetails.ideas_template_id,
        ideas_descriptions: descriptionTemplates,
        next_stage_end_on: nextStage?.date || ideaDetails.next_stage_end_on,
      },
      cb: () => {
        enqueueSnackbar("Successfully changed the idea.", {
          variant: "success",
        });
      },
      enqueueSnackbar,
    };

    dispatch(editIdeaAttempt(payload));
  };

  const handleChangeDropdown = (value, template, isCheckList) => {
    const newTemplates = descriptionTemplates?.map((t) => {
      if (t.id === template.id) {
        if (template.ideas_section?.multiselect || isCheckList) {
          return {
            ...t,
            section_values: value.map((v) => v.id),
          };
        }

        return {
          ...t,
          section_value: value.map((v) => v.id)[0],
        };
      }
      return t;
    });

    setDescriptionTemplates(newTemplates);
  };
  const handleChange = (evt, template, idx) => {
    if (template.section_type === "checklist") {
      const newTemplates = descriptionTemplates.map((t) => {
        if (t.id === template.id) {
          return {
            ...t,
            section_values: template.section_values?.map((t1, idx1) => {
              if (idx === idx1) {
                return evt.target.value;
              }

              return t1;
            }),
          };
        }
        return t;
      });

      setDescriptionTemplates(newTemplates);

      return;
    }

    const newTemplates = descriptionTemplates.map((t) => {
      if (t.id === template.id) {
        return {
          ...t,
          section_value: evt.target.value,
        };
      }
      return t;
    });

    setDescriptionTemplates(newTemplates);
  };

  if (!descriptionTemplates?.length) {
    return null;
  }

  return (
    <div className="d-flex flex-column">
      <div
        className={`d-flex align-items-center justify-content-between ${mainStyles.blockHeader}`}
      >
        <span className={mainStyles.blockTitle}>The Idea</span>
        {canEdit && (
          <Icon
            {...mdSize}
            icon="icn-edit-button"
            className={mainStyles.messageIcon}
            onClick={handleTheIdeaEdit}
          />
        )}
      </div>
      <div className={`${styles.theIdeaContent} ${mainStyles.blockContent}`}>
        {descriptionTemplates &&
          descriptionTemplates.map((d) => {
            if (d.section_type === "dropdown") {
              return (
                <div key={d.id} className="d-flex flex-column">
                  <span className={styles.title}>{d.section_name}</span>
                  {isEditModeActive ? (
                    <>
                      <Select
                        label={d.section_name}
                        placeholder={d.section_name}
                        labelField="name"
                        valueField="id"
                        name={d.id}
                        multi={d.ideas_section.multiselect}
                        options={d.ideas_section.section_option_values.map(
                          (o) => ({
                            id: o,
                            name: o,
                          })
                        )}
                        onChange={(val) => {
                          handleChangeDropdown(val, d);
                        }}
                        values={
                          d.section_values?.map((v) => ({ id: v, name: v })) ||
                          []
                        }
                        className={`${
                          !d.section_values?.length &&
                          d.ideas_section.field_required
                            ? styles.error
                            : ""
                        }`}
                      />
                      {d.ideas_section.field_required &&
                        !d.section_values?.length && (
                          <p className={styles.errorText}>Required</p>
                        )}
                    </>
                  ) : d.section_values?.length || d.section_value?.length ? (
                    <ul className="pl-3">
                      {d.ideas_section.multiselect ? (
                        d.section_values?.map((val, idx) => (
                          <li key={`${val}-${idx}`} className={styles.text}>
                            {val}
                          </li>
                        ))
                      ) : (
                        <li className={styles.text}>{d.section_value}</li>
                      )}
                    </ul>
                  ) : (
                    <p className={styles.text}>-</p>
                  )}
                  {d.section_name === "Is this a Replication?" && null}
                </div>
              );
            }

            if (d.section_type === "checklist") {
              return (
                <div key={d.id} className="d-flex flex-column">
                  <span className={styles.title}>{d.section_name}</span>
                  {isEditModeActive ? (
                    d.section_values?.length ? (
                      <>
                        <Select
                          label={d.section_name}
                          placeholder={d.section_name}
                          labelField="name"
                          valueField="id"
                          name={d.id}
                          multi={true}
                          options={d.ideas_section.section_option_values.map(
                            (o) => ({
                              id: o,
                              name: o,
                            })
                          )}
                          onChange={(val) => {
                            handleChangeDropdown(val, d, true);
                          }}
                          values={
                            d.section_values?.map((v) => ({
                              id: v,
                              name: v,
                            })) || []
                          }
                          className={`${
                            !d.section_values?.length &&
                            d.ideas_section.field_required
                              ? styles.error
                              : ""
                          }`}
                        />
                        {d.ideas_section.field_required &&
                          !d.section_values?.length && (
                            <p className={styles.errorText}>Required</p>
                          )}
                      </>
                    ) : (
                      <>
                        <input
                          defaultValue=""
                          onBlur={(evt) => handleChange(evt, d)}
                          className={`${
                            !d.section_values?.length &&
                            d.ideas_section.field_required
                              ? styles.error
                              : ""
                          }`}
                        />
                        {!d.section_values?.length &&
                          d.ideas_section.field_required && (
                            <p className={styles.errorText}>Required</p>
                          )}
                      </>
                    )
                  ) : d.section_values?.length ? (
                    <ul className="pl-3">
                      {d.section_values?.map((val, idx) => (
                        <li key={`${val}-${idx}`} className={styles.text}>
                          {val}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className={styles.text}>-</p>
                  )}
                </div>
              );
            }

            return (
              <div key={d.id} className="d-flex flex-column">
                <span className={styles.title}>{d.section_name}</span>
                {isEditModeActive ? (
                  <>
                    <textarea
                      className={`${
                        !d.section_value?.length &&
                        d.ideas_section.field_required
                          ? styles.error
                          : ""
                      }`}
                      defaultValue={d.section_value}
                      onChange={(evt) => handleChange(evt, d)}
                    />
                    {!d.section_value?.length &&
                      d.ideas_section.field_required && (
                        <p className={styles.errorText}>Required</p>
                      )}
                  </>
                ) : (
                  <p className={styles.text}>{d.section_value}</p>
                )}
              </div>
            );
          })}
        {selectedCouncil?.name === "Ford" && (
          <>
            <div className="d-flex flex-column">
              <span className={styles.title}>Next Stage Change</span>
              {isEditModeActive ? (
                <Select
                  label="Next Stage Change"
                  placeholder="Next Stage Change"
                  labelField="name"
                  valueField="id"
                  name="next_stage_end_on"
                  multi={false}
                  options={stages}
                  onChange={(val) => {
                    setNextStage(val[0]);
                  }}
                  values={[nextStage]}
                />
              ) : (
                <p className={styles.text}>{nextStage?.name || "-"}</p>
              )}
            </div>
          </>
        )}
        {isEditModeActive && (
          <div className="d-flex justify-content-center mt-3">
            <Button
              className="mr-3"
              btn={BtnType.FRAME_LESS}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button onClick={handleSave} btn={BtnType.REGULAR}>
              Save
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(IdeaProfileTheIdea);

import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./addIdeaTemplate.module.scss";
import "./checkbox.scss";
// import { CheckType, CheckColor } from './checkbox.constants';

import col from "../../../../scss/theme-variables.scss";

const CheckType = {
  BLUE: 0,
  GREEN: 1,
  HALF_BLUE: 2,
  DISABLED: 3,
};

const CheckColor = {
  0: col.colorLightBlue7,
  1: col.colorGreen5,
  2: col.colorLightBlue7,
  3: col.colorCoolGrey5,
};

const CheckBoxComponent = (props) => {
  const {
    label,
    isChecked,
    checkType = CheckType.BLUE,
    onChange,
    readOnly = false,
    className,
    disabled = false,
    onClick,
  } = props;
  const [checkboxChecked, setCheckboxChecked] = useState(isChecked);

  useEffect(() => {
    setCheckboxChecked(isChecked);
  }, [isChecked]);

  const handleOnChange = (e) => {
    if (readOnly) return;
    if (checkType === CheckType.HALF_BLUE) {
      if (onChange) {
        onChange(checkboxChecked, e);
      }
    } else if (onChange) {
      onChange(!checkboxChecked, e);
    } else {
      setCheckboxChecked((prev) => !prev);
    }
  };

  let checkColor = null;
  let classNameCon = null;
  switch (checkType) {
    case CheckType.BLUE:
      checkColor = styles.checkBlue;
      classNameCon = "checkBoxBlue";
      break;
    case CheckType.GREEN:
      checkColor = styles.checkGreen;
      classNameCon = "checkBoxGreen";
      break;
    case CheckType.HALF_BLUE:
      checkColor = styles.checkHalf;
      classNameCon = "checkBoxHalfBlue";
      break;
    default:
      checkColor = styles.checkBlue;
      break;
  }

  return (
    <div className={classNames(styles.checkWrp, className)}>
      <label
        className={classNames("checkContainer", checkColor, classNameCon)}
        style={{
          backgroundColor: `${CheckColor[checkType]}`,
          opacity: readOnly ? "0.5" : "1",
          cursor: readOnly ? "not-allowed" : "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (onClick) {
            onClick(checkboxChecked);
          }
        }}
      >
        <input
          type="checkbox"
          readOnly={readOnly}
          disabled={disabled}
          checked={!!checkboxChecked}
          onChange={(e) => {
            e.stopPropagation();
            handleOnChange(e);
          }}
        />
        <span className="checkmark" />
      </label>
      <span className={classNames("caption small", styles.label)}>{label}</span>
    </div>
  );
};

export default React.memo(CheckBoxComponent);

export const copyToClipBoardFromRef = (ref, enqueueSnackbar) => {
  const text = ref?.current;
  let range;
  let selection;

  if(document.body.createTextRange) {
    range = document.body.createTextRange();
    range.moveToElement(text);
    range.select();
  } else if (window.getSelection) {
    selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(text);
    selection.removeAllRanges();
    selection.addRange( range );
  }

  document.execCommand( 'copy' );

  enqueueSnackbar('Successfully copied', {
    variant: 'success',
  });

  window.getSelection().removeAllRanges();
};

export const convertToHTML = (text) => {
  return text
    .replace(/\"/g, '&quot;')
    .replace(/\n\n/g, '</p><p>')
    .replace(/\n/g, '<br />');
};

import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import SectionCard from "modules/reporting/fordReports/replicationsCard/sections/sectionCard";
import FordActiveFilters from "modules/reporting/fordReports/filters/activeFilters";
import {
  getReplicationsTableData,
  setReplicationsCardFilters,
  getReplicationsStatisticsData,
  getReplicationsByStageData,
  getProjectsExportedData,
  getProjectsImportedData,
} from "modules/reporting/fordReports/replicationsCard/store/replicationsCard.actions";
import ReplicationsByStage from "modules/reporting/fordReports/replicationsCard/sections/replicationsByStage";
import styles from "modules/reporting/fordReports/replicationsCard/styles.module.scss";
import ExportedProjects from "modules/reporting/fordReports/replicationsCard/sections/exportedProjects";
import ImportedProjects from "modules/reporting/fordReports/replicationsCard/sections/importedProjects";

const ReplicationsCards = ({ filters, downloadIsActive }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    fordDashboardReducer: { replicationsCardReducer }
  } = useSelector((state) => state);
  const data = useMemo(() => replicationsCardReducer.statistics.data, [replicationsCardReducer.statistics.data]);
  const fetching = useMemo(() => replicationsCardReducer.statistics.fetching, [replicationsCardReducer.statistics.fetching]);

  useEffect(() => {
    const payload = {
      enqueueSnackbar,
      data: makeActualScoreCardFilterData(filters),
    };

    dispatch(getReplicationsStatisticsData(payload));
    dispatch(getReplicationsByStageData(payload));
    dispatch(getProjectsExportedData(payload));
    dispatch(getProjectsImportedData(payload));
  }, []);

  const setFilters = (newFilters) => {
    dispatch(setReplicationsCardFilters(newFilters));
  };

  const getTableData = (newFilters) => {
    dispatch(getReplicationsTableData(newFilters));
  };

  const getUpdatedSectionData = (newFilters) => {
    dispatch(getReplicationsStatisticsData(newFilters));
    dispatch(getReplicationsByStageData(newFilters));
    dispatch(getProjectsExportedData(newFilters));
    dispatch(getProjectsImportedData(newFilters));
  };

  return (
    <>
      <div className="d-flex flex-row mb-2">
        <FordActiveFilters
          filters={replicationsCardReducer.filters}
          expandedTableKey={replicationsCardReducer.tableData?.expandedKey}
          highLightColumn={replicationsCardReducer.tableData?.highLightColumn}
          setFilters={setFilters}
          getTableData={getTableData}
          getUpdatedData={getUpdatedSectionData}
        />
      </div>
      <div className="mt-3 row">
        <div className="col-7 pr-1">
          <div className="d-flex">
            <ReplicationsByStage
              downloadIsActive={downloadIsActive}
              replicationsByStage={replicationsCardReducer.replicationsByStage}
              filters={replicationsCardReducer.filters}
              tableData={replicationsCardReducer.tableData}
            />
          </div>
          <div className="d-flex">
            <div className="d-flex flex-column w-50 mr-1">
              <SectionCard
                data={data?.replications_between_areas}
                fetching={fetching}
                size="medium"
                title="Replications Between Areas"
                reducerKey="replications_between_areas"
                hoverText="Number of Replications created between Areas of the same Plant"
                highLightColumn={['plant', 'area']}
                downloadIsActive={downloadIsActive}
              />
            </div>
            <div className="d-flex flex-column w-50 ml-1">
              <SectionCard
                data={data?.replications_between_plants}
                fetching={fetching}
                size="medium"
                title="Replications Between Plants"
                reducerKey="replications_between_plants"
                hoverText="Number of Replications created between different Plants"
                highLightColumn={['plant', 'reference_plant']}
                downloadIsActive={downloadIsActive}
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex flex-column w-50 mr-1">
              <ExportedProjects
                downloadIsActive={downloadIsActive}
                filters={filters}
                sectionData={replicationsCardReducer.projectsExported}
                tableData={replicationsCardReducer.tableData}
              />
            </div>
            <div className="d-flex flex-column w-50 ml-1">
              <ImportedProjects
                downloadIsActive={downloadIsActive}
                filters={filters}
                sectionData={replicationsCardReducer.projectsImported}
                tableData={replicationsCardReducer.tableData}
              />
            </div>
          </div>
        </div>
        <div className="col-5 pl-1 d-flex flex-column">
          <div className="d-flex flex-grow-1">
            <div className="d-flex flex-column w-50 mr-1">
              <SectionCard
                data={data?.total_replications}
                fetching={fetching}
                size="big"
                title="Total Replications"
                reducerKey="total_replications"
                hoverText="Total number of Replications"
                highLightColumn={[]}
                downloadIsActive={downloadIsActive}
              />
            </div>
            <div className="d-flex flex-column w-50 ml-1">
              <SectionCard
                data={data?.avg_tar}
                fetching={fetching}
                size="big"
                title="Avg TARR"
                reducerKey="avg_tar"
                hoverText="Average TARR across all Replications"
                highLightColumn={["tarr"]}
                downloadIsActive={downloadIsActive}
              />
            </div>
          </div>
          <div className="d-flex flex-grow-1">
            <div className="d-flex flex-column w-50 mr-1">
              <SectionCard
                data={data?.total_savings}
                fetching={fetching}
                size="big"
                title="Savings ($)"
                reducerKey="savings"
                hoverText="Total Savings across all Replications"
                highLightColumn={["savings"]}
                downloadIsActive={downloadIsActive}
                showDollars
              />
            </div>
            <div className="d-flex flex-column w-50 ml-1">
              <SectionCard
                data={data?.total_investments}
                fetching={fetching}
                size="big"
                title="Investment ($)"
                reducerKey="investments"
                hoverText="Total Investment across all Replications"
                highLightColumn={["investment_usd"]}
                downloadIsActive={downloadIsActive}
                showDollars
              />
            </div>
          </div>
          <div className={`${styles.columnCard} h-auto align-items-start pl-2 pb-2`}>
            <span className={styles.columnCardTitleBold}>Approved</span>
          </div>
          <div className="d-flex flex-grow-1">
            <div className="d-flex flex-column w-50 mr-1">
              <SectionCard
                data={data?.approved_replications_count}
                fetching={fetching}
                size="big"
                title="Nr. Replications"
                reducerKey="approved_replications"
                hoverText="Total Number of Approved Replications with an Implementation Date in the time period."
                highLightColumn={["implementation_date"]}
                downloadIsActive={downloadIsActive}
              />
            </div>
            <div className="d-flex flex-column w-50 ml-1">
              <SectionCard
                data={data?.approved_replications_percentage}
                fetching={fetching}
                size="big"
                title="Approved From Total"
                reducerKey="approved_replications_percentage"
                hoverText="Approved Replications/Total Replications"
                highLightColumn={["implementation_date"]}
                downloadIsActive={downloadIsActive}
              />
            </div>
          </div>
          <div className={`${styles.columnCard} h-auto align-items-start pl-2 pb-2`}>
            <span className={styles.columnCardTitleBold}>Plan</span>
          </div>
          <div className="d-flex flex-grow-1">
            <div className="d-flex flex-column w-50 mr-1">
              <SectionCard
                data={data?.planned_replications_count}
                fetching={fetching}
                size="big"
                title="Nr. Replications"
                reducerKey="planned_replication"
                hoverText="Total Number of Planned Replications (Replications with an Implementation Date or Next Stage Change in the time period)."
                highLightColumn={["planned_replication", "implementation_date"]}
                downloadIsActive={downloadIsActive}
              />
            </div>
            <div className="d-flex flex-column w-50 ml-1">
              <SectionCard
                data={data?.planned_replications_percentage}
                fetching={fetching}
                size="big"
                title="Planned From Total"
                reducerKey="planned_replications_percentage"
                hoverText="Planned Replications/Total Replications"
                highLightColumn={["planned_replication", "implementation_date"]}
                downloadIsActive={downloadIsActive}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ReplicationsCards);

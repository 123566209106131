import React from "react";
import styles from "./taskAllInfo.module.scss";
import { CompanyAvatar } from "../../../../primitives";

const TaskAllInfoHeaderComponent = (props) => {
  const { company } = props;

  return (
    <div className={styles.headerWrp}>
      <div className={styles.companyCon}>
        <div className={styles.companyIcon}>
          <CompanyAvatar imgSrc={company.logo} name={company.name} />
        </div>
        <div className={styles.companyName}>
          <h4 className="medium">{company.name}</h4>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TaskAllInfoHeaderComponent);

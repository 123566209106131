import * as Yup from "yup";

const urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

export const initModel = {
  name: '',
  description: '',
  product_link: '',
  images: [],
};

export const validation = Yup.object().shape({
  name: Yup.string().required("Product Name is required."),
  product_link: Yup.string().matches(urlRegex, "Please, enter a valid url")
});

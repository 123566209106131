import React from "react";

import StringComponent from "modules/reporting/components/genericReportsList/stringComponent";
import WebsiteComponent from "modules/reporting/components/genericReportsList/websiteComponent";
import DateComponent from "modules/reporting/components/genericReportsList/dateComponent";
import StatusComponent from "modules/reporting/components/genericReportsList/statusComponent";
import TagsComponent from "modules/reporting/components/genericReportsList/tagsComponent";
import CustomFieldsTagsComponent from "modules/reporting/components/genericReportsList/customFieldsTagsComponent";
import { ProgressBar, ProjectState } from "modules/primitives";
import UserComponentWrapper from "modules/reporting/components/genericReportsList/userComponentWrapper";
import CompaniesList from "modules/reporting/components/genericReportsList/companiesList";
import ProjectStatus from "modules/reporting/components/genericReportsList/projectStatus";
import DownloadViewDocs from "modules/reporting/components/genericReportsList/download";
import IdeasList from "modules/reporting/components/genericReportsList/ideasList";
import ThemesList from "modules/reporting/components/genericReportsList/themesList";
import StageComponent from "modules/reporting/components/genericReportsList/stageComponent";
import MembersComponent from "modules/reporting/components/genericReportsList/membersComponent";
import DocumentsListComponent from "modules/reporting/components/genericReportsList/documentsListComponent";

export const CHARTS_TABLE_TYPE_BY_KEY = {
  projects_list: "projects",
  companies_list: "companies",
  ideas_list: "ideas",
};

export const REPORTS_PAGINATION_DEFAULTS = {
  pageSize: 10,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
  total: 0,
};

export const LIST_FUNNEL_CONFIG = [
  {
    id: "name",
    name: "Name",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponentWithLogo",
    propToUse: "name",
    withNavigation: true,
    urlPath: "/companies/",
  },
  {
    id: "last_updated",
    name: "Last Updated",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "DateComponent",
    propToUse: "last_updated",
  },
  {
    id: "updated_by",
    name: "Updated By",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "UserComponent",
    propToUse: "updated_by",
  },
  {
    id: "country",
    name: "Country",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "country",
  },
  {
    id: "year_founded",
    name: "Year Founded",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "year_founded",
  },
  {
    id: "status",
    name: "Relationship Stage",
    className: "",
    bootstrapClass: "d-flex justify-content-center",
    customComponent: "StatusComponent",
  },
];

export const LIST_COMPANIES_BY_TIME_IN_STAGE_CONFIG = [
  {
    id: "name",
    name: "Name",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponentWithLogo",
    propToUse: "name",
    withNavigation: true,
    urlPath: "/companies/",
  },
  {
    id: "relationship_status",
    name: "Relationship Stage",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StatusComponentWithoutTime",
  },
  {
    id: "days_in_current_status",
    name: "Days in Current Status",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "days_in_status",
  },
  {
    id: "date_of_last_update",
    name: "Last Updated",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "DateComponent",
    propToUse: "last_relationship_updated_date",
  },
  {
    id: "updated_by",
    name: "Updated By",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "UserComponent",
    propToUse: "updated_by",
  },
  {
    id: "relationship_owner",
    name: "Relationship Owner",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "UserComponent",
    propToUse: "company_relationship_owners",
  },
];

export const LIST_OVERVIEW_COMPANIES_CONFIG = [
  {
    id: "name",
    name: "Company",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponentWithLogo",
    propToUse: "name",
    withNavigation: true,
    urlPath: "/companies/",
  },
  {
    id: "status",
    name: "Relationship Stage",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StatusComponentWithoutTime",
  },
  {
    id: "updates",
    name: "Updates",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "updates",
  },
  {
    id: "added_by",
    name: "Date Updated",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "DateComponent",
    propToUse: "updated_at",
  },
  {
    id: "updated_by",
    name: "Updated By",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "UserComponent",
    propToUse: "updated_by",
  },
  {
    id: "notes",
    name: "Notes",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "notes",
  },
  {
    id: "agreements",
    name: "Agreements",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "agreements",
  },
];

export const LIST_TECHNOLOGY_COMPANIES_CONFIG = [
  {
    id: "name",
    name: "Company",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponentWithLogo",
    propToUse: "name",
    withNavigation: true,
    urlPath: "/companies/",
  },
  {
    id: "technology_tags",
    name: "Technology tags",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "TagsComponent",
    propToUse: "technology_tags",
    minWidth: 20,
  },
  {
    id: "country",
    name: "Country",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "country",
  },
  {
    id: "year_founded",
    name: "Year Founded",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "year_founded",
  },
  {
    id: "date_added",
    name: "Date Added",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "DateComponent",
    propToUse: "date_added",
  },
  {
    id: "employees",
    name: "Employees",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "employee_range",
  },
  {
    id: "estimated_revenue",
    name: "Estimated Revenue-",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "estimated_revenue",
  },
];

export const IDEAS_OVERVIEW_CONFIG = [
  {
    id: "name",
    name: "Idea (Name)",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "name",
    withNavigation: true,
    urlPath: "/ideas/",
  },
  {
    id: "status",
    name: "Status",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "status",
  },
  {
    id: "submitter",
    name: "Submitter",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "UserComponent",
    propToUse: "submitter",
  },
  {
    id: "dateLastUpdated",
    name: "Last Status Update",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "DateComponent",

    propToUse: "last_updated",
  },
  {
    id: "lastUpdatedBy",
    name: "Updated By",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "UserComponent",
    propToUse: "last_updated_by",
  },
];

export const IDEAS_TECHNOLOGY_CONFIG = [
  {
    id: "name",
    name: "Idea (Name)",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "name",
    withNavigation: true,
    urlPath: "/ideas/",
  },
  {
    id: "date_submitted",
    name: "Date Submitted",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "DateComponent",
    propToUse: "date_submitted",
  },
  {
    id: "submitter",
    name: "Submitter",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "UserComponent",
    propToUse: "submitter",
  },
  {
    id: "technologyTags",
    name: "Technology tags",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "TagsComponent",
    propToUse: "technology_tags",
    minWidth: 20,
  },
  {
    id: "industryTags",
    name: "Industry Tags",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "TagsComponent",
    propToUse: "industry_tags",
    minWidth: 20,
  },
];

export const PROJECTS_OVERVIEW_CONFIG = [
  {
    id: "name",
    name: "Project Name",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "name",
    withNavigation: true,
    urlPath: "/projects/",
  },
  {
    id: "nrOfCompanies",
    name: "Companies",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "no_of_companies",
  },
  {
    id: "progress",
    name: "Project Progress",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "ProgressBar",
    propToUse: "project_progress",
  },
  {
    id: "nrOfTaskCompleted",
    name: "Completed Tasks",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "no_of_task_completed",
  },
  {
    id: "assignedTasks",
    name: "Assigned Tasks",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "no_of_task_assigned",
  },
];

export const PROJECTS_BY_STATUS_CONFIG = [
  {
    id: "name",
    name: "Project Name",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "name",
    withNavigation: true,
    urlPath: "/projects/",
  },
  {
    id: "status",
    name: "Status",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "ProjectState",
    propToUse: "status",
  },
  {
    id: "priority",
    name: "Priority",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "priority",
  },
  {
    id: "progress",
    name: "Project Progress",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "ProgressBar",
    propToUse: "project_progress",
  },
  {
    id: "lastActivity",
    name: "Last Activity",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "DateComponent",
    propToUse: "last_activity_date",
  },
  {
    id: "access",
    name: "Access",
    className: "",
    bootstrapClass: "d-flex",
    customComponent: "StringComponent",
    propToUse: "access",
  },
];

export const componentsBulkByName = {
  ProgressBar: (data, config) => (
    <div className="w-50">
      <ProgressBar
        width="100%"
        tot={100}
        fill={data[config.propToUse]}
        showTot={false}
        showPrecentage
      />
    </div>
  ),
  ProjectState: (data, config) => (
    <ProjectState state={data[config.propToUse]} />
  ),
  CustomFields: (data, config) => {
    const source = data?.custom_fields[config?.id]?.field_value;
    const fieldType = data?.custom_fields[config?.id]?.field_type;

    if (!source || !fieldType) {
      return "-";
    }

    if (config.propToUse === "documents") {
      return (
        <DocumentsListComponent
          documents={source}
          config={config}
          rowDetails={data}
        />
      );
    }

    if (fieldType === "date" && source) {
      if (source.constructor === Array) {
        return (
          <>
            {source
              .filter((el) => el?.length)
              .map((el, key) => (
                <div key={key}>
                  <DateComponent date={el} />
                </div>
              ))}
          </>
        );
      }
      return <DateComponent date={source} />;
    }

    if (fieldType === "text" || fieldType === "number") {
      if (source.constructor === Array) {
        return (
          <>
            {source.map((el, key) => (
              <div key={key}>
                <StringComponent config={config} data={data} value={el} />
              </div>
            ))}
          </>
        );
      }

      return <StringComponent config={config} data={data} value={source} />;
    }

    if (fieldType === "image") {
      return source?.length > 0 ? 1 : "-";
    }

    if (fieldType !== "dropdown" && source.constructor === Array) {
      return source?.length;
    }

    if (fieldType === "dropdown") {
      const newData = {
        ...data,
        [config.id]: source.map((str) => ({ id: str, name: str })) || [],
      };
      return (
        <CustomFieldsTagsComponent
          rowDetails={newData}
          config={config}
          renderWithoutTagStyles
        />
      );
    }

    return <StringComponent config={config} data={data} value={source} />;
  },
  ProjectFields: (data, config) => {
    const source = data?.project_fields;

    if (!source) {
      return "-";
    }

    return (
      <StringComponent
        config={config}
        data={source}
        value={source[config.propToUse]}
      />
    );
  },
  StringComponentWithLogo: (data, config) => (
    <StringComponent
      config={config}
      data={data}
      value={data[config.propToUse]}
      showLogo
    />
  ),
  StringComponent: (data, config) => (
    <StringComponent
      config={config}
      data={data}
      value={data[config.propToUse]}
    />
  ),
  WebsiteComponent: (data, config) => (
    <WebsiteComponent
      config={config}
      data={data}
      value={data[config.propToUse]}
    />
  ),
  DateComponent: (data, config) => (
    <DateComponent date={data[config.propToUse]} />
  ),
  StatusComponent: (data) => <StatusComponent rowDetails={data} showDaysIcon />,
  StatusComponentWithoutTime: (data) => <StatusComponent rowDetails={data} />,
  TagsComponent: (data, config) => (
    <TagsComponent rowDetails={data} config={config} />
  ),
  CustomFieldsTagsComponent: (data, config) => (
    <CustomFieldsTagsComponent rowDetails={data} config={config} />
  ),
  TagsComponentFromArrOfStrings: (data, config) => {
    let renderWithoutTagStyles = false;
    const newData = {
      ...data,
      [config.propToUse]:
        data[config.propToUse]?.map((str) => {
          if (typeof str === "string") {
            renderWithoutTagStyles = true;
            return { id: str, name: str };
          }
          return str;
        }) || [],
    };
    return (
      <TagsComponent
        rowDetails={newData}
        config={config}
        renderWithoutTagStyles
      />
    );
  },
  UserComponent: (data, config) => (
    <UserComponentWrapper data={data} config={config} />
  ),
  CompaniesList: (data, config) => (
    <CompaniesList data={data} config={config} />
  ),
  ProjectStatus: (data, config) => (
    <ProjectStatus data={data} config={config} />
  ),
  DownloadViewDocs: (data, config) => (
    <DownloadViewDocs data={data} config={config} />
  ),
  IdeasList: (data, config) => <IdeasList data={data} config={config} />,
  ThemesList: (data, config) => <ThemesList data={data} config={config} />,
  StageComponent: (data, config) => (
    <StageComponent data={data} config={config} />
  ),
  MembersComponent: (data, config) => (
    <MembersComponent data={data} config={config} />
  ),
};

import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { useSnackbar } from "../../../../../../node_modules/notistack/dist/index";
import styles from "./styles.module.scss";
import { Button, Loading } from "../../../../primitives/index";
import { postFilterOptions, homeFilterOptions } from "./postsBlock.constant";
import {
  themesPostsGetAttempt,
  themesPostsGetSuccess,
  themesAcrossPostsGetAttempt,
  themesMembersGetAccrossAttempt,
  themesMembersGetAttempt,
} from "./postsBlock.action";
import DropSelectWithInput from "../../../themesCommon/dropSelectWithInput/index";
import PostItem from "./postItem/index";
import AddPostModal from "./addPostModal";
import { useModelPopup } from "../../../../../common/hooks";
import { isLocalhost } from "../../../../../serviceWorker";
import { getCookie } from "../../../../../common/helper";

const PostsBlock = ({ themeId, homePageUse }) => {
  const dispatch = useDispatch();
  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    themesReducer: {
      themesOverviewReducer: { shouldUpdatePosts },
      themesPostsReducer: {
        postsList,
        loadingPosts,
        updatePostsAcross,
        postsMembers,
      },
    },
  } = useSelector((state) => state);
  const popup = useModelPopup();
  const enqueueSnackbar = useSnackbar();
  const [author, setSelectedAuthor] = useState(false);
  const [allMode, setAllMode] = useState(true);
  const [filterOption, setFilterOption] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [selectedPost, setSelectedPost] = useState("");
  const [filterOptions, setFilterOptions] = useState(postFilterOptions);
  const handleAddThemeModal = () => {
    popup.show({
      title: "Create Post",
      show: true,
      component: (
        <AddPostModal
          homePageUse={homePageUse}
          popup={popup}
          themeId={themeId}
        />
      ),
    });
  };

  useEffect(() => {
    if (session?.id) {
      let socket;

      if (isLocalhost) {
        const uid = getCookie("uid");
        const accessToken = getCookie("access_token");
        const client = getCookie("client");

        socket = new WebSocket(
          `${process.env.REACT_APP_WSS}/cable?access_token=${accessToken}&client=${client}&uid=${uid}`,
        );
      } else {
        socket = new WebSocket(
          `${process.env.REACT_APP_WSS}/cable`,
        );
      }

      socket.onopen = () => {
        const msg = {
          command: "subscribe",
          identifier: JSON.stringify({
            theme_id: themeId,
            council_id: selectedCouncil.id,
            channel: homePageUse
              ? "HomeThemePostsChannel"
              : "ThemePostsChannel",
          }),
        };
        socket.send(JSON.stringify(msg));
      };
      try {
        socket.onmessage = (event) => {
          const parsedData = JSON.parse(event.data);
          if (
            (parsedData.identifier?.includes("HomeThemePostsChannel") ||
              parsedData.identifier?.includes("ThemePostsChannel")) &&
            parsedData.message &&
            parsedData.message?.length > 0
          ) {
            dispatch(themesPostsGetSuccess({ response: parsedData.message }));
          }
        };
        socket.onclose = () => {
          console.log("disconnected");
        };
        socket.onerror = (error) => {
          console.log("Error: " + JSON.stringify(error));
        };
      } catch (error) {
        console.log("Error catch: " + error);
      }

      return () => {
        socket.close();
        console.log("unmounted");
      };
    }
  }, []);
  const diplayPosts = (searcFilterOption = "", authorId = "") => {
    if (homePageUse) {
      dispatch(
        themesAcrossPostsGetAttempt({
          themeId,
          enqueueSnackbar,
          filter: searcFilterOption,
          authorId,
        })
      );
    } else {
      dispatch(
        themesPostsGetAttempt({
          themeId,
          enqueueSnackbar,
          filter: searcFilterOption,
          authorId,
        })
      );
    }
  };

  useEffect(() => {
    if (homePageUse) {
      dispatch(themesAcrossPostsGetAttempt({ enqueueSnackbar }));
      dispatch(themesMembersGetAccrossAttempt({ enqueueSnackbar }));
    }
  }, [homePageUse]);

  useEffect(() => {
    if (updatePostsAcross && homePageUse) {
      dispatch(themesAcrossPostsGetAttempt({ enqueueSnackbar }));
    }
  }, [updatePostsAcross]);

  useEffect(() => {
    if (
      selectedCouncil &&
      themeId &&
      !homePageUse &&
      filterOption !== "author"
    ) {
      diplayPosts(filterOption, "");
      dispatch(themesMembersGetAttempt({ enqueueSnackbar, themeId }));
    }
  }, [selectedCouncil, shouldUpdatePosts, filterOption]);

  useEffect(() => {
    if (selectedCouncil && homePageUse && filterOption !== "author") {
      dispatch(
        themesAcrossPostsGetAttempt({ enqueueSnackbar, filter: filterOption })
      );
      setFilterOptions(homeFilterOptions);
    }
  }, [selectedCouncil, filterOption, allMode]);

  const handleSearch = useCallback(
    (val) => {
      setSearchValue(val);
    },
    [searchValue]
  );

  return (
    <div
      style={homePageUse || window.screen.width < 550 ? {} : { marginLeft: 76 }}
      className={styles.postsContainer}
    >
      <div className={styles.headerLogoAndActions}>
        <div className={styles.postsHeaderNTabs}>
          {!homePageUse ? (
            <span className={styles.postsTitle}>
              {selectedCouncil?.settings?.themes_display_name_singular ||
                "Theme"}{" "}
              Posts
            </span>
          ) : (
            <h4>
              {selectedCouncil?.settings?.themes_display_name_singular ||
                "Theme"}{" "}
              Posts
            </h4>
          )}

          {/* {homePageUse &&
            session?.theme_investment_access &&
            selectedCouncil?.traction_tag === "firmenich" && (
              <div className={styles.switchFormTypeBlock}>
                <Button
                  onClick={() => {
                    setFilterOption("newest");
                    setAllMode(true);
                  }}
                  className={allMode ? styles.pressedButton : styles.defaultButton}
                >
                  ALL
                </Button>
                <Button
                  onClick={() => {
                    setAllMode(false);
                    setFilterOption("my_themes");
                  }}
                  className={allMode ? styles.defaultButton : styles.pressedButton}
                >
                  MY THEMES
                </Button>
              </div>
            )} */}
        </div>
        <div
          className={classNames(
            homePageUse ? styles.headerHomeActions : {},
            styles.headerActions
          )}
        >
          <DropSelectWithInput
            selectedValue={filterOptions.find(
              (opt) => opt.value === filterOption
            )}
            setSelectedValue={setFilterOption}
            searchValue={searchValue}
            options={filterOptions}
            searchFn={handleSearch}
            themeMembers={postsMembers}
            diplayPosts={diplayPosts}
            homePageUse={homePageUse}
            setSelectedAuthor={setSelectedAuthor}
            author={author}
            setAllMode={setAllMode}
          />

          <div
            className={
              homePageUse
                ? styles.newThemeButtonFromHome
                : styles.newThemeButton
            }
          >
            <Button
              icon="icn-add"
              type="button"
              onClick={() => handleAddThemeModal()}
              className={styles.newPostButton}
            >
              New Post
            </Button>
          </div>
        </div>
      </div>
      {loadingPosts ? (
        <Loading />
      ) : (
        <>
          {postsList.length === 0 ? (
            <p className={styles.placeholderCont}> There are no items yet...</p>
          ) : (
            <div className={classNames(homePageUse && styles.postsBlock)}>
              {postsList?.map((post) => (
                <PostItem
                  filterOption={filterOption}
                  homePageUse={homePageUse}
                  key={post?.theme_post_id}
                  postData={post}
                  selectedPost={selectedPost}
                  setSelectedPost={setSelectedPost}
                  author={author}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default PostsBlock;

import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import SettingsHeader from "./settingsHeader";
import styles from "./settings.module.scss";
import GeneralSettings from "./generalSettings";
import PasswordSettings from "./passwordSettings";
import NotificationsSettings from "./notificationsSettings";
import IntegrationsSettings from "./integrationsSettings";
import FollowingSettings from "./followingSettings";

const SettingsComponent = () => {
  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    if (history.location.state?.showPopup && !session?.isSSOLogin) {
      enqueueSnackbar("Please change your default password.", {
        variant: "warning",
      });
    }
  }, [session]);

  return (
    <div className={styles.settingsWrapper}>
      <SettingsHeader />
      <div>
        <Switch>
          <Route exact path="/settings" component={GeneralSettings} />
          {
            !session?.isSSOLogin && selectedCouncil?.traction_tag !== 'kyndryl' ? (
              <Route path="/settings/password" component={PasswordSettings} />
            ) : null
          }
          {session?.council_role !== "company" && (
            <Route
              exact
              path="/settings/notifications"
              component={NotificationsSettings}
            />
          )}
          {session?.council_role !== "company" && (
            <Route
              exact
              path="/settings/integrations"
              component={IntegrationsSettings}
            />
          )}
          {session?.council_role !== "company" && (
            <Route
              exact
              path="/settings/following"
              component={FollowingSettings}
            />
          )}
          <Route exact path="/setup/profile" component={GeneralSettings} />
          <Route exact path="/setup/password" component={PasswordSettings} />
        </Switch>
      </div>
    </div>
  );
};

export default React.memo(SettingsComponent);

import React, { useState } from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";
import { useDispatch } from "react-redux";
import { withSnackbar } from "notistack";
import styles from "../login/login.module.scss";
import { Button } from "../../primitives";
import { forgotAttempt } from "../auth.action";

const isPRODEnv = window.location.host === "traction.network";

const ForgetPasswordComponent = ({ enqueueSnackbar }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const handleRequestClick = () => {
    dispatch(
      forgotAttempt({
        enqueueSnackbar,
        data: {
          email,
          redirect_url: isPRODEnv
            ? "https://traction.network"
            : "http://staging.traction.network:81",
        },
      }),
    );
  };

  return (
    <div className={styles.loginWrp}>
      <div className={styles.loginCon}>
        <h1 className={styles.loginTitle}>Forgot password</h1>

        <div className={styles.loginFormWrp}>
          <h5>Please enter your email to search for your account and send a</h5>
          <h5>recovery link to it.</h5>
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            className={styles.forgettextBox}
            onChange={(text) => setEmail(text.target.value)}
          />
          <Link to="/">
            <div className={classNames("caption", styles.forgetPassword)}>
              Already have an account? Log in!
            </div>
          </Link>
        </div>
        <div className={styles.requestWrp}>
          <Button className={styles.requestButton} onClick={handleRequestClick}>
            Reset your password
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(withSnackbar(ForgetPasswordComponent));

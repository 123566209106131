import React, { useCallback, useMemo, useState, useEffect } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { appModelSettingSet } from "application/app.action";
import LoadingComponent from "modules/primitives/loading/loading.component";

import styles from "./discussion.module.scss";
import {
  MoreOptions,
  MultiUsers,
  Pannel,
  UserInline,
  UserInlineTypes,
} from "../../primitives";
import { Icon, mdSize, smSize } from "../../../common/icon";
import AddDiscussionIcon from "../../../assets/icn-table-add.svg";
import NewDiscussion from "../../../common/components/newDiscussion/newDiscussion.component";
import DiscussionDetails from "../../../common/components/discussionDetails";
import {
  discussionDelete,
  setSelectedDiscussion,
} from "../../../common/actions/discussions.action";
import { ACTIVITY_OPTIONS } from "../../../common/constants";

const DiscussionsFactory = ({
  type,
  reducerProp,
  isCurrentUserAttended,
  isCurrentUserProjectMember,
  homePageUse,
  discussions,
  query,
  setQuery,
  ids,
  withCollapse,
  expandAll,
}) => {
  const [showDetailsWindow, setShowDetailsWindow] = useState(false);
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);

  const dispatch = useDispatch();
  const {
    authReducer: { session },
    appReducer: { modalSetting },
    discussionsReducer: { sendReplyPending, selectedDiscussion },
  } = useSelector((state) => state);
  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);

  const startNewDiscussion = () => {
    const customSettings = {
      title: "Start a New Discussion",
      show: true,
      height: "300",
      width: "540",
      component: (
        <NewDiscussion
          type={type}
          isPrivate={query === "PRIVATE"}
          projectId={ids.projectId}
          eventId={ids.eventId}
          handleRedirectTo={setQuery}
        />
      ),
    };

    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  const handleShow = (details) => {
    document.querySelector("body").style.overflow = "hidden";
    if (details) {
      dispatch(setSelectedDiscussion(details));
    }
    setShowDetailsWindow(true);
  };

  const handleClose = () => {
    document.querySelector("body").style.overflow = "auto";
    setShowDetailsWindow(false);
    dispatch(setSelectedDiscussion({}));
  };

  const goToDiscussions = () => history.push("/discussions");

  const checkWhenToRenderDiscussion = useCallback(
    (discussion) => {
      if (type === "Event") {
        // return discussion.scope === "event_attendees"
        return true;
      }

      if (type === "Project") {
        // return isCurrentUserProjectMember && discussion.scope === "project_members"
        return true;
      }

      return (
        discussion.public ||
        (!discussion.public &&
          discussion.participants?.find((p) => p.user_id === session.id))
      );
    },
    [isCurrentUserProjectMember, isCurrentUserAttended, session, type]
  );

  const handleOptionsClick = (val, discussion) => {
    if (val === 0) {
      const customSettings = {
        title: "Edit Discussion",
        show: true,
        height: "300",
        width: "540",
        component: (
          <NewDiscussion
            type={type}
            isPrivate={query === "PRIVATE"}
            projectId={ids.projectId}
            eventId={ids.eventId}
            discussion={discussion}
          />
        ),
      };

      dispatch(
        appModelSettingSet({
          ...modalSetting,
          ...customSettings,
        })
      );
    }

    if (val === 1) {
      dispatch(discussionDelete(discussion));
    }
  };

  const renderDiscussionsList = useMemo(() => {
    if (!discussions) {
      return "";
    }

    if (discussions?.length === 0) {
      return <div className={styles.noData}>No discussions yet!</div>;
    }

    const makeList = [...discussions].splice(0, 6);

    return (
      <div>
        {makeList.map((discussion) => {
          const shouldRender = checkWhenToRenderDiscussion(discussion);

          const handleClick = () => {
            if (discussion?.id) {
              handleShow({
                ...discussion,
                prop: reducerProp,
              });
            }
          };

          if (!shouldRender) {
            return null;
          }

          return (
            <div
              key={discussion?.id}
              className={styles.rowWrp}
              onClick={handleClick}
            >
              <div className={styles.lineOne}>
                {!discussion.anonymous && (
                  <UserInline
                    userId={discussion.creator.user_id}
                    type={UserInlineTypes.WITH_NAME}
                    showMine={false}
                    first={discussion.creator.full_name.split(" ")[0]}
                    last={discussion.creator.full_name.split(" ")[1]}
                    avatar={discussion.creator.avatar}
                    local
                  />
                )}
                <div className={classNames("caption small", styles.teamName)}>
                  {discussion.team_id ? (
                    discussion.team.name
                  ) : discussion.participants &&
                    discussion.participants.length ? (
                    discussion.anonymous ? (
                      <div>Anonymous</div>
                    ) : (
                      <MultiUsers
                        users={discussion.participants}
                        total={discussion.participants.length}
                      />
                    )
                  ) : null}
                </div>
                {discussion.creator_id === session?.id && (
                  <div className="ml-3">
                    <MoreOptions
                      options={ACTIVITY_OPTIONS.filter((a) => a.val !== 0)}
                      onClick={(val) => handleOptionsClick(val, discussion)}
                    />
                  </div>
                )}
              </div>
              <div className={styles.lineTwo}>
                <div className={styles.message}>
                  {discussion.subject
                    ? `"${discussion.subject}"`
                    : "No subject."}
                </div>
                <div className={styles.iconWrp}>
                  <div className={"caption"}>
                    {discussion.messages.filter((m) => !m.deleted).length}
                  </div>
                  <Icon {...mdSize} icon={"icn-chat"} className={styles.icon} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [
    session,
    discussions,
    isCurrentUserAttended,
    isCurrentUserProjectMember,
    handleShow,
    reducerProp,
    checkWhenToRenderDiscussion,
    handleOptionsClick,
  ]);

  if (!discussions) {
    return (
      <Pannel
        title="Discussions"
        padding={"0"}
        parentStyle={{ background: "Transparent" }}
      >
        <LoadingComponent customText="Getting discussions..." />
      </Pannel>
    );
  }

  return (
    <Pannel
      rightContentColumn={type === "Event" || (type === "Project" && expanded)}
      title={
        withCollapse ? (
          <div className={styles.discussionHeaderWrapper}>
            {" "}
            Discussions{" "}
            {withCollapse && (
              <div
                className="d-flex justify-content-center cursor-pointer "
                onClick={() => setExpanded((prev) => !prev)}
              >
                <Icon
                  {...smSize}
                  icon="icn-table-down-chevron"
                  className={classNames(
                    styles.icon,
                    expanded ? styles.collapse : styles.collapseUp
                  )}
                />
              </div>
            )}{" "}
          </div>
        ) : (
          "Discussions"
        )
      }
      padding={"0"}
      parentStyle={{ background: "Transparent" }}
      rightContent={
        homePageUse || type === "Event" || type === "Project" ? (
          <>
            <div className={styles.discussionControlHeader}>
              {(!withCollapse || (withCollapse && expanded)) && (
                <>
                  <div className={styles.viewChanger}>
                    <span
                      onClick={() => setQuery("GROUP")}
                      className={query === "GROUP" ? styles.activeView : ""}
                    >
                      Public
                    </span>
                    <span
                      onClick={() => setQuery("PRIVATE")}
                      className={query === "PRIVATE" ? styles.activeView : ""}
                    >
                      Private
                    </span>
                  </div>
                  <span
                    onClick={startNewDiscussion}
                    className={styles.addNewDiscussion}
                  >
                    <img
                      width={20}
                      height={20}
                      src={AddDiscussionIcon}
                      alt="Add new discussion"
                    />
                  </span>
                </>
              )}
            </div>
          </>
        ) : (
          <span
            onClick={startNewDiscussion}
            className={styles.addNewDiscussion}
          >
            <img src={AddDiscussionIcon} alt="Add new discussion" />
          </span>
        )
      }
      withCollapse
      expanded={expanded}
    >
      {showDetailsWindow && selectedDiscussion && (
        <>
          <div className={styles.detailsExtraLayer}></div>
          <DiscussionDetails
            handleClose={handleClose}
            details={selectedDiscussion}
            sendReplyPending={sendReplyPending}
          />
        </>
      )}
      <div
        className={classNames(
          styles.wrapper
          // expanded ? styles.expanded : styles.expandable
        )}
        // style={{ minHeight: withCollapse ? "100%" : "422px" }}
      >
        {renderDiscussionsList}
        {(discussions || !discussions?.length) && (
          <div className={styles.seeAllDiscussions}>
            <span onClick={goToDiscussions}>See all discussions</span>
          </div>
        )}
      </div>
    </Pannel>
  );
};

export default React.memo(DiscussionsFactory);

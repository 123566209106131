export const HeaderOptions = [
  {
    value: "my_projects",
    text: "My Projects",
    default: true,
  },
  {
    value: "all_projects",
    text: "All Projects",
  },
];

export const fordHeaderOptions = [
  {
    value: "catalog_projects",
    text: "Catalog Projects",
    default: true,
  },
  {
    value: "project_stage_idea",
    text: "Ideas",
  },
  {
    value: "all_projects",
    text: "Projects",
  },
  {
    value: "my_projects",
    text: "My Projects",
  },
];

export const projectPageSettings = {
  pageSize: 20,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
};

export const filterOptions = [
  // { text: "All", value: "all" },
  { text: "Plant", value: "plant_ids", searchable: true },
  { text: "Area", value: "area_ids", searchable: true },
  { text: "Regions", value: "plant_region_ids", searchable: true },
  {
    text: "Savings",
    value: "savings",
    range: true,
    ranges: [
      [1, 50000],
      [50001, 200000],
      [200001, 500000],
      [500001, 1000000],
      [1000001, null],
    ],
    serverKeys: ["savings"],
  },
  {
    text: "Rating",
    value: "ratings",
    range: true,
    ranges: [
      [0, 1],
      [1, 2],
      [2, 3],
      [3, 4],
      [4, 5],
    ],
    serverKeys: ["ratings"],
  },
  { text: "Pillar", value: "theme_ids", searchable: true },
  {
    text: "Replications",
    value: "replicated",
    boolean: true,
    options: [
      { value: true, name: "Original Projects" },
      { value: false, name: "Replications" },
    ],
  },
  { text: "Stage", value: "stage_ids", searchable: true },
  { text: "Technology", value: "topic_ids", searchable: true },
  {
    text: "Support Skills Team",
    value: "project_field_values",
    namesOnly: true,
  },
  { text: "FPS Category", value: "fps_category", namesOnly: true },
  { text: "Funded By", value: "founded_by", namesOnly: true },
  { text: "Program", value: "program", namesOnly: true },
  { text: "Project Owner", value: "project_owner_ids", searchable: true },
  { text: "Project Template", value: "stage_template_ids", searchable: true },
];

export const defaultPayload = (council, selectedTab) => ({
  theme_ids: [],
  topic_ids: [],
  plant_ids: [],
  area_ids: [],
  project_field_values: [],
  savings: [],
  ratings: [],
  original: null,
  skills_team: [],
  stage_ids: [],
  founded_by: [],
  fps_category: [],
  program: [],
  approval_status:
    selectedTab === "project_stage_idea"
      ? ["paused", "rejected", "submitted"]
      : undefined,
  idea_stages: false,
  plant_region_ids: [],
  project_owner_ids: [],
  stage_template_ids: [],
});

export const defFilterOptionSearchResult = {
  savings: [],
  theme_ids: [],
  topic_ids: [],
  plant_ids: [],
  area_ids: [],
  project_field_values: [],
  founded_by: [],
  fps_category: [],
  program: [],
  stage_ids: [],
  plant_region_ids: [],
  project_owner_ids: [],
  stage_template_ids: [],
};

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, FieldArray, Field } from "formik";

import { BtnType, Button, Label } from "modules/primitives";
import { ModalFooter } from "application/modal";
import { formInitialValues } from './constant';
import useModelPopup from "common/hooks/modelPopup.hook";
import { editProductDetails } from "modules/companies/product/store/product.actions";
import styles from "../../../../../../common/popups/newItem/newVideos/styles.module.scss";
import Icon from "../../../../../../common/icon/icon.component";
import { mdSize } from "../../../../../../common/icon";

const EditProductFeatures = ({ product }) => {
  const popup = useModelPopup();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(formInitialValues);

  useEffect(() => {
    setFormValues({
      features: product?.features?.length ? product.features : ['']
    })
  }, [product]);

  const handleCancelClick = () => {
    popup.hide();
  }

  const handleSave = (values) => {
    const payload = {
      companyId: product.company.id,
      productId: product.id,
      data: {
        features: values.features.filter(f => f?.length > 0),
      },
    };

    dispatch(editProductDetails(payload));
    popup.hide();
  };

  const addFeature = (evt, values) => {
    evt.preventDefault();

    setFormValues({
      ...values,
      features: [...values.features, '']
    })
  }

  const handleDelete = (index) => {
    setFormValues({
      ...formValues,
      features: formValues.features.filter((_, idx) => idx !== index),
    });
  }

  return (
    <div>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={formValues}
        onSubmit={handleSave}
      >
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="px-4">
              <FieldArray name="features">
                <div className="pl-2">
                  <Label>Product Features</Label>
                  {
                    values.features.map((f, index) => (
                      <div key={index} className="d-flex align-items-center mb-2">
                        <Field placeholder={`Product Feature ${index + 1}`} name={`features.${index}`} className={styles.textBox} />
                        <div
                          className="d-flex ml-3 cursor-pointer"
                          onClick={() => handleDelete(index)}
                        >
                          <Icon {...mdSize} icon="icn-button-delete" />
                        </div>
                      </div>
                    ))
                  }
                  <div className="d-flex mt-3 mb-4 d-flex justify-content-end">
                    <Button
                      onClick={(evt) => addFeature(evt, values)}
                      btn={BtnType.REGULAR}
                    >
                      Add Feature
                    </Button>
                  </div>
                </div>
              </FieldArray>
            </div>
            <ModalFooter>
              <Button
                className="mr-3"
                btn={BtnType.FRAME_LESS}
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                btn={BtnType.REGULAR}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditProductFeatures;

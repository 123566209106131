export const GET_TASK_ADDITIONAL_DATA = "GET_TASK_ADDITIONAL_DATA";
export const getTaskAdditionalData = (payload) => ({
  type: GET_TASK_ADDITIONAL_DATA,
  payload,
});

export const GET_TASK_ADDITIONAL_DATA_SUCCESS = "GET_TASK_ADDITIONAL_DATA_SUCCESS";
export const getTaskAdditionalDataSuccess = (payload) => ({
  type: GET_TASK_ADDITIONAL_DATA_SUCCESS,
  payload,
});

export const MARK_TASK_COMPLETED = "MARK_TASK_COMPLETED";
export const markTaskAsCompleted = (payload) => ({
  type: MARK_TASK_COMPLETED,
  payload,
});

export const PROJECT_TASK_LIST_GET_ATTEMPT = "PROJECT_TASK_LIST_GET_ATTEMPT";
export const projectTaskListGet = (payload) => ({
  type: PROJECT_TASK_LIST_GET_ATTEMPT,
  payload,
});
export const PROJECT_TASK_LIST_GET_SUCCESS = "PROJECT_TASK_LIST_GET_SUCCESS";
export const projectTaskListGetSuccess = (payload) => ({
  type: PROJECT_TASK_LIST_GET_SUCCESS,
  payload,
});

export const PROJECT_TASKS_BY_USER_ATTEMPT = "PROJECT_TASKS_BY_USER_ATTEMPT";
export const projectTasksByUserGet = (payload) => ({
  type: PROJECT_TASKS_BY_USER_ATTEMPT,
  payload,
});
export const PROJECT_TASKS_BY_USER_SUCCESS = "PROJECT_TASKS_BY_USER_SUCCESS";
export const projectTasksByUserSuccess = (payload) => ({
  type: PROJECT_TASKS_BY_USER_SUCCESS,
  payload,
});
export const PROJECT_TASK_UPDATE_BY_USER_SUCCESS = "PROJECT_TASK_UPDATE_BY_USER_SUCCESS";
export const projectTaskUpdateByUserSuccess = (payload) => ({
  type: PROJECT_TASK_UPDATE_BY_USER_SUCCESS,
  payload,
});

export const PROJECT_TASK_ADD_ATTEMPT = "PROJECT_TASK_ADD_ATTEMPT";
export const projectTaskAdd = (payload) => ({
  type: PROJECT_TASK_ADD_ATTEMPT,
  payload,
});
export const PPROJECT_TASK_ADD_SUCCESS = "PPROJECT_TASK_ADD_SUCCESS";
export const projectTaskAddSuccess = (payload) => ({
  type: PPROJECT_TASK_ADD_SUCCESS,
  payload,
});

export const PROJECT_TASK_COMPANY_LIST_GET_ATTEMPT = "PROJECT_TASK_COMPANY_LIST_GET_ATTEMPT";
export const projectTaskCompanyListGet = (payload) => ({
  type: PROJECT_TASK_COMPANY_LIST_GET_ATTEMPT,
  payload,
});
export const PROJECT_TASK_COMPANY_LIST_GET_SUCCESS = "PROJECT_TASK_COMPANY_LIST_GET_SUCCESS";
export const projectTaskCompanyListSuccess = (payload) => ({
  type: PROJECT_TASK_COMPANY_LIST_GET_SUCCESS,
  payload,
});

export const PPROJECT_TASK_ASSIGMENT_STATUS_UPDATE = "PPROJECT_TASK_ASSIGMENT_STATUS_UPDATE";
export const projectTaskAssigmentStatusUpdate = (payload) => ({
  type: PPROJECT_TASK_ASSIGMENT_STATUS_UPDATE,
  payload,
});

// Notes
export const PROJECT_TASK_NOTES_LIST_ATTEMPT = "PROJECT_TASK_NOTES_LIST_ATTEMPT";
export const projectTaskNotesList = (payload) => ({
  type: PROJECT_TASK_NOTES_LIST_ATTEMPT,
  payload,
});
export const PROJECT_TASK_NOTES_LIST_SUCCESS = "PROJECT_TASK_NOTES_LIST_SUCCESS";
export const projectTaskNotesListSuccess = (payload) => ({
  type: PROJECT_TASK_NOTES_LIST_SUCCESS,
  payload,
});

export const PROJECT_TASK_NOTES_ADD_ATTEMPT = "PROJECT_TASK_NOTES_ADD_ATTEMPT";
export const projectTaskNotesAdd = (payload) => ({
  type: PROJECT_TASK_NOTES_ADD_ATTEMPT,
  payload,
});
export const PPROJECT_TASK_NOTES_ADD_SUCCESS = "PPROJECT_TASK_NOTES_ADD_SUCCESS";
export const projectTaskNotesAddSuccess = (payload) => ({
  type: PPROJECT_TASK_NOTES_ADD_SUCCESS,
  payload,
});

export const PROJECT_TASK_NOTES_DELETE_ATTEMPT = "PROJECT_TASK_NOTES_DELETE_ATTEMPT";
export const projectTaskNotesDelete = (payload) => ({
  type: PROJECT_TASK_NOTES_DELETE_ATTEMPT,
  payload,
});
export const PPROJECT_TASK_NOTES_DELETE_SUCCESS = "PPROJECT_TASK_NOTES_DELETE_SUCCESS";
export const projectTaskNotesDeleteSuccess = (payload) => ({
  type: PPROJECT_TASK_NOTES_DELETE_SUCCESS,
  payload,
});

export const PROJECT_TASK_NOTES_UPDATE_ATTEMPT = "PROJECT_TASK_NOTES_UPDATE_ATTEMPT";
export const projectTaskNotesUpdate = (payload) => ({
  type: PROJECT_TASK_NOTES_UPDATE_ATTEMPT,
  payload,
});
export const PROJECT_TASK_NOTES_UPDATE_SUCCESS = "PROJECT_TASK_NOTES_UPDATE_SUCCESS";
export const projectTaskNotesUpdateSuccess = (payload) => ({
  type: PROJECT_TASK_NOTES_UPDATE_SUCCESS,
  payload,
});
// end - Notes

// documents
export const PROJECT_TASK_DOCUMENTS_GET_ATTEMPT = "PROJECT_TASK_DOCUMENTS_GET_ATTEMPT";
export const projectTaskDocumentGet = (payload) => ({
  type: PROJECT_TASK_DOCUMENTS_GET_ATTEMPT,
  payload,
});
export const PROJECT_TASK_DOCUMENTS_GET_SUCCESS = "PROJECT_TASK_DOCUMENTS_GET_SUCCESS";
export const projectTaskDocumentGetSuccess = (payload) => ({
  type: PROJECT_TASK_DOCUMENTS_GET_SUCCESS,
  payload,
});

export const PROJECT_TASK_DOCUMENTS_ADD_ATTEMPT = "PROJECT_TASK_DOCUMENTS_ADD_ATTEMPT";
export const projectTaskDocumentAdd = (payload) => ({
  type: PROJECT_TASK_DOCUMENTS_ADD_ATTEMPT,
  payload,
});
export const PROJECT_TASK_DOCUMENTS_ADD_SUCCESS = "PROJECT_TASK_DOCUMENTS_ADD_SUCCESS";
export const projectTaskDocumentAddSuccess = (payload) => ({
  type: PROJECT_TASK_DOCUMENTS_ADD_SUCCESS,
  payload,
});

export const PROJECT_TASK_DOCUMENTS_DELETE_ATTEMPT = "PROJECT_TASK_DOCUMENTS_DELETE_ATTEMPT";
export const projectTaskDocumentDelete = (payload) => ({
  type: PROJECT_TASK_DOCUMENTS_DELETE_ATTEMPT,
  payload,
});
export const PPROJECT_TASK_DOCUMENTS_DELETE_SUCCESS = "PPROJECT_TASK_DOCUMENTS_DELETE_SUCCESS";
export const projectTaskDocumentDeleteSuccess = (payload) => ({
  type: PPROJECT_TASK_DOCUMENTS_DELETE_SUCCESS,
  payload,
});

// End - dowcuments

// OLD
export const PROJECT_TASK_GET_SUCCESS = "PROJECT_TASK_GET_SUCCESS";
export const projectTaskGetSuccess = (payload) => ({
  type: PROJECT_TASK_GET_SUCCESS,
  payload,
});

export const PROJECT_TASK_SORTED_LIST_SET = "PROJECT_TASK_SORTED_LIST_SET";
export const projectTaskSortedListSet = (payload) => ({
  type: PROJECT_TASK_SORTED_LIST_SET,
  payload,
});

export const PROJECTTASK_STATUS_UPDATE_SUCCESS = "PROJECTTASK_STATUS_UPDATE_SUCCESS";
export const projectTaskStatusUpdateSuccess = (payload) => ({
  type: PROJECTTASK_STATUS_UPDATE_SUCCESS,
  payload,
});

export const PROJECT_TASK_FIELDS_GET_SUCCESS = "PROJECT_TASK_FIELDS_GET_SUCCESS";
export const projectTaskFieldsGetSuccess = (payload) => ({
  type: PROJECT_TASK_FIELDS_GET_SUCCESS,
  payload,
});

export const PROJECT_TASK_DELETE_ATTEMPT = "PROJECT_TASK_DELETE_ATTEMPT";
export const projectTaskDelete = (payload) => ({
  type: PROJECT_TASK_DELETE_ATTEMPT,
  payload,
});
export const PPROJECT_TASK_DELETE_SUCCESS = "PPROJECT_TASK_DELETE_SUCCESS";
export const projectTaskDeleteSuccess = (payload) => ({
  type: PPROJECT_TASK_DELETE_SUCCESS,
  payload,
});

export const CUSTOM_PROJECT_TASK_UPDATE_ATTEMPT = "CUSTOM_PROJECT_TASK_UPDATE_ATTEMPT";
export const customProjectTaskUpdate = (payload) => ({
  type: CUSTOM_PROJECT_TASK_UPDATE_ATTEMPT,
  payload,
});

export const PROJECT_TASK_UPDATE_ATTEMPT = "PROJECT_TASK_UPDATE_ATTEMPT";
export const projectTaskUpdate = (payload) => ({
  type: PROJECT_TASK_UPDATE_ATTEMPT,
  payload,
});
export const PPROJECT_TASK_UPDATE_SUCCESS = "PPROJECT_TASK_UPDATE_SUCCESS";
export const projectTaskUpdateSuccess = (payload) => ({
  type: PPROJECT_TASK_UPDATE_SUCCESS,
  payload,
});

export const TASK_COMPANY_DOCUMENTS_SAVE = "TASK_COMPANY_DOCUMENTS_SAVE";
export const taskCompanyDocumentsSave = (payload) => ({
  type: TASK_COMPANY_DOCUMENTS_SAVE,
  payload,
});

export const CLEAR_UPLOADED_DOC = "CLEAR_UPLOADED_DOC";
export const clearUploadedDoc = (payload) => ({
  type: CLEAR_UPLOADED_DOC,
  payload,
});

export const GATEWAY_TASK_UPDATE = "GATEWAY_TASK_UPDATE";
export const gatewayTaskUpdate = (payload) => ({
  type: GATEWAY_TASK_UPDATE,
  payload,
});
export const GATEWAY_TASK_UPDATE_SUCCESS = "GATEWAY_TASK_UPDATE_SUCCESS";
export const gatewayTaskUpdateSuccess = (payload) => ({
  type: GATEWAY_TASK_UPDATE_SUCCESS,
  payload,
});

export const STATIC_GATEWAY_TASK_UPDATE = "STATIC_GATEWAY_TASK_UPDATE";
export const staticGatewayTaskUpdate = (payload) => ({
  type: STATIC_GATEWAY_TASK_UPDATE,
  payload,
});

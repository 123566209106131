import React, { memo, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import ChartTagsList from "../chartTagsList";
import ChartCompanyItemWrapperComponent from "../chartCompanyItemWrapper";
import style from "./chartCompaniesList.module.scss";
import {
  PAGINATION_DEFAULTS,
  PAGINATION_DEFAULTS_FIR,
} from "./chartCompaniesList-constants";
import { Loading, Pagination } from "../../../../primitives";
import { Icon, smSize } from "../../../../../common/icon";
import { sortChartListByCriteria } from "./utils";
import { setChartsPage } from "../../companyMain.action";
import { checkViewerRole } from "../../../../../common/checkers/viewerChecker";
import { scrollStop } from "../../../../../common/helper";

const ChartCompaniesList = ({
  companies = [],
  selectedTags = [],
  selectedCouncil,
  homeUse,
  isDownloadActive,
  customPagination,
  customMoreOptions,
  firmenichInstance, // temporary
  kyndryThemelInstance = false,
  themesCallBack,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    authReducer: { session },

    companiesReducer: {
      companyMainReducer: {
        charts: { selectedChartType, page, chartOptions },
      },
    },
  } = useSelector((state) => state);

  const currentYear = new Date().getFullYear();
  const [pageSetting, setPageSetting] = useState(
    firmenichInstance
      ? {
          ...PAGINATION_DEFAULTS_FIR,
          current: page,
        }
      : {
          ...PAGINATION_DEFAULTS,
          current: page,
        }
  );
  const [companiesByPage, setCompaniesByPage] = useState(null);
  const [total, setTotal] = useState(null);
  const [sortingConfig, setSortingConfig] = useState(null);
  const [localChartSettings, setLocalChartSettings] = useState(null);
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [userIsScrolling, setUserIsScrolling] = useState(false);

  useEffect(() => {
    const wrapper = document.querySelector("#rightWindow");

    if (!wrapper) {
      return;
    }

    document.querySelector("#rightWindow").addEventListener(
      "scroll",
      () => {
        setUserIsScrolling(true);
      },
      false
    );

    scrollStop(
      "#rightWindow",
      () => {
        setUserIsScrolling(false);
      },
      1000
    );

    return () => {
      const elem = document.querySelector("#rightWindow");

      if (elem) {
        elem.removeEventListener("scroll", null);
      }
    };
  }, []);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (homeUse) {
      setLocalChartSettings({
        color: "relationShipStatus",
      });
    } else {
      setLocalChartSettings(chartOptions);
    }
  }, [homeUse, chartOptions]);

  useEffect(() => {
    if (!localChartSettings) {
      return;
    }

    const totalPerPage = firmenichInstance ? 14 : 20;
    const pageStart = page === 1 ? 0 : page * totalPerPage - totalPerPage;
    const pageEnd = page === 1 ? totalPerPage : page * totalPerPage;

    if (selectedTags?.length) {
      let filteredCompanies;

      if (localChartSettings.color === "relationShipStatus") {
        filteredCompanies = companies.filter(
          (company) =>
            company.council_relationship_stage_name !== "none" &&
            selectedTags.some((tag) =>
              tag.companiesIdThatBelongsTo.some((id) => id === company.id)
            )
        );
      } else {
        filteredCompanies = companies.filter((company) =>
          selectedTags.some((tag) =>
            tag.companiesIdThatBelongsTo.some((id) => id === company.id)
          )
        );
      }

      if (sortingConfig && sortingConfig.order) {
        filteredCompanies = sortChartListByCriteria(
          sortingConfig,
          filteredCompanies
        );
      }

      setTotal(filteredCompanies.length);
      setCompaniesByPage(filteredCompanies.slice(pageStart, pageEnd));
      setPageSetting({
        ...pageSetting,
        total: filteredCompanies.length,
      });
    } else {
      let sortedCompanies;
      let filteredCompanies;

      if (localChartSettings.color === "relationShipStatus") {
        filteredCompanies = companies.filter(
          (c) => c.council_relationship_stage_id !== "none"
        );
      } else {
        filteredCompanies = companies;
      }

      if (sortingConfig && sortingConfig.order) {
        sortedCompanies = sortChartListByCriteria(
          sortingConfig,
          filteredCompanies
        );
      } else {
        sortedCompanies = filteredCompanies;
      }

      setTotal(sortedCompanies.length);
      setCompaniesByPage(sortedCompanies.slice(pageStart, pageEnd));
      setPageSetting({
        ...pageSetting,
        total: sortedCompanies.length,
      });
    }
  }, [
    companies,
    selectedTags,
    sortingConfig,
    localChartSettings?.color,
    firmenichInstance,
  ]);

  const handlePagingChange = useCallback(
    (p) => {
      if (!homeUse) {
        dispatch(
          setChartsPage({
            page: p,
          })
        );
      }

      const totalPerPage = firmenichInstance ? 14 : 20;
      const pageStart = p === 1 ? 0 : p * totalPerPage - totalPerPage;
      const pageEnd = p === 1 ? totalPerPage : p * totalPerPage;

      if (selectedTags?.length) {
        let filteredCompanies;

        if (localChartSettings.color === "relationShipStatus") {
          filteredCompanies = companies.filter(
            (company) =>
              company.council_relationship_stage_name !== "none" &&
              selectedTags.some((tag) =>
                tag.companiesIdThatBelongsTo.some((id) => id === company.id)
              )
          );
        } else {
          filteredCompanies = companies.filter((company) =>
            selectedTags.some((tag) =>
              tag.companiesIdThatBelongsTo.some((id) => id === company.id)
            )
          );
        }

        if (sortingConfig && sortingConfig.order) {
          filteredCompanies = sortChartListByCriteria(
            sortingConfig,
            filteredCompanies
          );
        }

        setTotal(filteredCompanies.length);
        setCompaniesByPage(filteredCompanies.slice(pageStart, pageEnd));
        setPageSetting({
          ...pageSetting,
          current: p,
          total: filteredCompanies.length,
        });
      } else {
        let sortedCompanies;
        let filteredCompanies;

        if (localChartSettings.color === "relationShipStatus") {
          filteredCompanies = companies.filter(
            (c) => c.council_relationship_stage_id !== "none"
          );
        } else {
          filteredCompanies = companies;
        }

        if (sortingConfig && sortingConfig.order) {
          sortedCompanies = sortChartListByCriteria(
            sortingConfig,
            filteredCompanies
          );
        } else {
          sortedCompanies = filteredCompanies;
        }

        setTotal(sortedCompanies.length);
        setCompaniesByPage(sortedCompanies.slice(pageStart, pageEnd));
        setPageSetting({
          ...pageSetting,
          current: p,
          total: sortedCompanies.length,
        });
      }
    },
    [
      page,
      companies,
      dispatch,
      pageSetting,
      selectedTags,
      localChartSettings,
      homeUse,
    ]
  );
  const setStylesForCol = () => style.columnHeader;
  const drawSortingIcon = useCallback(
    (col) => {
      if (!sortingConfig) return null;

      if (sortingConfig?.activeColumn === col) {
        return (
          <div
            className={classNames(
              style.sortIcon,
              sortingConfig.order === "desc" && style.sortAsc
            )}
          >
            <Icon {...smSize} icon="icn-export-button" />
          </div>
        );
      }
    },
    [sortingConfig]
  );

  const makeSortingConfig = useCallback(
    (activeColumn, child) => {
      if (sortingConfig && sortingConfig?.activeColumn === activeColumn) {
        if (sortingConfig.order === "desc") {
          const newConfig = {
            activeColumn,
            order: "asc",
            child,
          };

          setSortingConfig(newConfig);
          return;
        }

        setSortingConfig({
          activeColumn: null,
          order: null,
          child,
        });

        return;
      }

      const newConfig = {
        activeColumn,
        order: "desc",
        child,
      };

      setSortingConfig(newConfig);
    },
    [sortingConfig]
  );

  if (total === 0) {
    return (
      <div className="d-flex align-items-center justify-content-center mt-4">
        <h4 className="text-center">
          Sorry we don't have any search results for you filter criteria!
          <br />
          Please try to select other filters.
        </h4>
      </div>
    );
  }

  return (
    <div
      className={`${
        homeUse && isDownloadActive
          ? style.fullHeight
          : firmenichInstance
          ? style.fullHeight
          : ""
      } ${
        homeUse ? style.homePageCompanies : style.chartCompaniesListWrapper
      } chart-companies-wrapper`}
    >
      <div className="d-flex align-items-start justify-content-between w-100">
        {!homeUse && (
          <>
            {!kyndryThemelInstance && <ChartTagsList />}

            {total &&
              (page === 1 ? (
                <span className={` ${style.total} ${setStylesForCol()} `}>
                  {total} Results
                </span>
              ) : (
                <span className={style.total}>
                  {pageSetting.pageSize * pageSetting.current - 19} -{" "}
                  {pageSetting.pageSize * pageSetting.current} of {total}{" "}
                  Results
                </span>
              ))}
          </>
        )}
      </div>
      <div className={`d-flex flex-column ${firmenichInstance ? "" : "mt-3"}`}>
        {companiesByPage ? (
          <div className={style.listHeader}>
            <span
              onClick={() => makeSortingConfig("name")}
              className={`${style.company}  ${setStylesForCol()}`}
            >
              Company
              {drawSortingIcon("name")}
            </span>
            <>
              {selectedCouncil?.name !== "IDB" &&
              selectedChartType === "pie" &&
              localChartSettings.color === "amountOfLastRound" ? (
                <span
                  onClick={() => makeSortingConfig("last_funding_amount_usd")}
                  className={`${style.funding} ${setStylesForCol()} `}
                >
                  Funding (Last Round)
                  {drawSortingIcon("last_funding_amount_usd")}
                </span>
              ) : selectedCouncil?.name !== "IDB" ? (
                <span
                  onClick={() => makeSortingConfig("total_fundings_sum")}
                  className={` ${style.funding} ${setStylesForCol()} `}
                >
                  Total Funding
                  {drawSortingIcon("total_fundings_sum")}
                </span>
              ) : (
                <span
                  onClick={() => makeSortingConfig("location_country")}
                  className={` ${style.funding} ${setStylesForCol()}`}
                >
                  Country
                  {drawSortingIcon("location_country")}
                </span>
              )}
            </>
            {selectedCouncil?.name !== "Kyndryl" && (
              <span
                onClick={() => makeSortingConfig("years_in_operation")}
                className={` ${style.founded} ${setStylesForCol()} `}
              >
                Year Founded
                {drawSortingIcon("years_in_operation")}
              </span>
            )}
            {!homeUse && !kyndryThemelInstance ? (
              <span
                onClick={() => makeSortingConfig("num_employees_enum")}
                className={`${style.employees} ${setStylesForCol()} `}
              >
                Employees
                {drawSortingIcon("num_employees_enum")}
              </span>
            ) : null}
            {selectedCouncil?.name === "Kyndryl" ? (
              <span
                onClick={() => makeSortingConfig("partner_type")}
                className={style.score}
              >
                Partner type
                {drawSortingIcon("partner_type")}
              </span>
            ) : (
              <span
                onClick={() => makeSortingConfig("council_company_score")}
                className={` ${style.score} ${setStylesForCol()}`}
              >
                Score
                {drawSortingIcon("council_company_score")}
              </span>
            )}
            {session?.theme_investment_access &&
              selectedCouncil?.name === "Firmenich" && (
                <span
                  onClick={() =>
                    makeSortingConfig("theme_investment_stage_name")
                  }
                  className={`${setStylesForCol()} position-relative`}
                >
                  Investment
                  {drawSortingIcon("theme_investment_stage_name")}
                </span>
              )}
            <span
              onClick={() =>
                makeSortingConfig("council_relationship_stage_name")
              }
              className={` ${style.status} ${setStylesForCol()}`}
            >
              Stage
              {drawSortingIcon("council_relationship_stage_name")}
            </span>
            {selectedCouncil?.name === "Kyndryl" && !kyndryThemelInstance && (
              <span
                onClick={() => makeSortingConfig("project_stage", "name")}
                className={style.founded}
              >
                Project Stage
                {drawSortingIcon("project_stage")}
              </span>
            )}
            {selectedCouncil?.name === "Kyndryl" && kyndryThemelInstance && (
              <span
                onClick={() => makeSortingConfig("project_stage", "name")}
                className={style.founded}
              >
                Relationship Owner
                {drawSortingIcon("project_stage")}
              </span>
            )}
            <div className="d-flex" style={{ width: "20px" }} />
          </div>
        ) : null}
        {companiesByPage ? (
          companiesByPage.map((company) => (
            <ChartCompanyItemWrapperComponent
              key={company.id}
              currentYear={currentYear}
              company={company}
              history={history}
              selectedCouncil={selectedCouncil}
              dispatch={dispatch}
              showAmountOfLastRound={
                selectedChartType === "pie" &&
                localChartSettings.color === "amountOfLastRound"
              }
              homeUse={homeUse}
              blockFunctionality={blockFunctionality}
              userIsScrolling={userIsScrolling}
              customMoreOptions
              kyndryThemelInstance={kyndryThemelInstance}
              themesCallBack={themesCallBack}
            />
          ))
        ) : (
          <Loading customText="Drawing results..." />
        )}
        {!customPagination && total > 20 ? (
          <div
            className={`w-100 ${companiesByPage ? "d-flex" : "d-none"} ${
              firmenichInstance
                ? "justify-content-end"
                : "justify-content-center"
            } mt-3`}
          >
            <Pagination {...pageSetting} onChange={handlePagingChange} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default memo(ChartCompaniesList);

export const dateOptions = [
  {
    name: 'All Time',
    id: 'allTime'
  },
  {
    name: 'By Year',
    id: 'byYear'
  },
  {
    name: 'By Quarter',
    id: 'byQuarter'
  },
  {
    name: 'By Month',
    id: 'byMonth'
  },
  {
    name: 'Custom',
    id: 'custom'
  }
];

export const months = [
  {
    name: 'January',
    id: 'January',
    index: 1,
  },
  {
    name: 'February',
    id: 'February',
    index: 2,
  },
  {
    name: 'March',
    id: 'March',
    index: 3,
  },
  {
    name: 'April',
    id: 'April',
    index: 4,
  },
  {
    name: 'May',
    id: 'May',
    index: 5,
  },
  {
    name: 'June',
    id: 'June',
    index: 6,
  },
  {
    name: 'July',
    id: 'July',
    index: 7,
  },
  {
    name: 'August',
    id: 'August',
    index: 8,
  },
  {
    name: 'September',
    id: 'September',
    index: 9,
  },
  {
    name: 'October',
    id: 'October',
    index: 10,
  },
  {
    name: 'November',
    id: 'November',
    index: 11,
  },
  {
    name: 'December',
    id: 'December',
    index: 12,
  },
]

export const currDate = new Date();

export const tableHeadersByKey = {
  'total_poc': 'Total PoC',
  'total_approved': 'Total Approved',
  'total_executed': 'Total Executed',
  'poc': 'PoC',
  'idea_generation': 'Idea Generation',
  'implementation': 'Implementation',
  'approved_replications': 'Approved Replications',
  'cib_savings': 'CIB Savings ($)',
  'cib_avarage_tarr': 'CIB Avg TARR',
  'global_tarr': 'Global TARR',
  'job1_accomplishment': 'Job1 Accomplishment',
  'global_savings': 'Global Savings ($)',
}

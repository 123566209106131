import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  CHART_COLORS,
  CUSTOM_PIE_TAGS_BY_BREAKDOWN,
  PROP_BY_COLOR,
  RELATIONSHIP_TAGS_COLORS,
} from "../../charts-constants";
import { externalTooltipHandler } from "../customTooltip";

const dataForLabels = {
  score: {
    label: "Score",
    prop: "score",
  },
  totalFunding: {
    label: "Total Funding",
    prop: null,
  },
  amountOfLastRound: {
    label: "Amount of Last Round",
    prop: null,
  },
  yearFounded: {
    label: "Year Founded",
    prop: null,
  },
  numberOfEmployees: {
    label: "Number of Employees",
    prop: null,
  },
  revenue_range: {
    label: "Estimated Revenue",
    prop: null,
  },
};

const makePieChartOptions = (chartOptions, isHomeUse) => ({
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const customData = context.dataset.customDataPerIndex[context.dataIndex];
          const percentageAsArray = context.element.$datalabels[0]._model.lines;
          const splitValue = percentageAsArray[0].split("%");
          const percentage = `${splitValue[0]}%`;

          return `${context.label}//Companies: ${
            context.formattedValue
          }//Percentage: ${percentage}//Total Funding: $${customData.totalFunding.toLocaleString(
            "en-US",
          )}`;
        },
      },
      enabled: false,
      position: "nearest",
      external: externalTooltipHandler,
    },
    datalabels: {
      color: "#ffffff",
      display: "auto",
      clamp: true,
      font: {
        weight: "bold",
      },
      formatter: (value, ctx) => {
        let sum = 0;
        const dataArr = ctx.chart.data.datasets[0].data;

        dataArr.map((data) => {
          sum += data;
        });

        const valueAsPercentage = ((value * 100) / sum).toFixed(0);
        const { color = "" } = chartOptions;
        const { dataIndex, dataset } = ctx;

        if (
          (color === "technology"
            || color === "industry"
            || color === "relationShipStatus")
          && valueAsPercentage > 15
        ) {
          const label = dataset.customDataPerIndex[dataIndex].name;

          return `${valueAsPercentage}% (${label})`;
        }

        if (
          (color === "totalFunding"
            || color === "amountOfLastRound"
            || color === "score"
            || color === "yearFounded"
            || color === "revenue_range"
            || color === "numberOfEmployees")
          && valueAsPercentage > 10
        ) {
          const propsToUse = dataForLabels[color];

          if (propsToUse.prop) {
            return `${valueAsPercentage}% (${propsToUse.label} = ${
              dataset.customDataPerIndex[dataIndex][propsToUse.prop]
            })`;
          }

          return `${valueAsPercentage}% (${dataset.customDataPerIndex[dataIndex].name})`;
        }

        return `${valueAsPercentage}%`;
      },
    },
  },
  title: {
    display: false,
  },
  layout: {
    padding: {
      bottom: isHomeUse ? 0 : 20,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
});

export const makeFlattenRelationShipTags = (companies, relationShipTags) => relationShipTags.reduce((acc, tag) => {
  const filteredCompanies = companies.filter(
    (c) => c.council_relationship_stage_id === tag.customId,
  );

  return [
    ...acc,
    {
      ...tag,
      id: tag.slug,
      companiesIdThatBelongsTo: filteredCompanies.map((c) => c.id),
      count: filteredCompanies.length,
      color: tag?.colors?.background || RELATIONSHIP_TAGS_COLORS[tag.slug],
    },
  ];
}, []);

const makePieChartData = (
  companies,
  selectedChartOptions,
  filterBy = [],
  relationTags,
  councilTags,
) => {
  const { color: colorAsOption } = selectedChartOptions;
  const selectedTags = filterBy.some((t) => t.selected);
  let data = [];

  const isCustomPieBreakDown = colorAsOption === "totalFunding"
    || colorAsOption === "amountOfLastRound"
    || colorAsOption === "score"
    || colorAsOption === "numberOfEmployees"
    || colorAsOption === "revenue_range"
    || colorAsOption === "yearFounded";

  if (isCustomPieBreakDown) {
    const { tags } = CUSTOM_PIE_TAGS_BY_BREAKDOWN[colorAsOption];

    if (
      colorAsOption === "numberOfEmployees"
      || colorAsOption === "revenue_range"
    ) {
      data = tags
        .map((item) => {
          const getCompanies = companies.filter(
            (c) => c[
              colorAsOption === "numberOfEmployees"
                ? "num_employees_enum"
                : "revenue_range"
            ] === item.value,
          );
          const tagIsSelected = selectedTags
            ? filterBy.find((t) => t.id === item.id && t.selected)
            : true;

          return {
            ...item,
            companiesIdThatBelongsTo: getCompanies.map((c) => c.id),
            totalFunding: getCompanies.reduce(
              (acc, c) => (acc += c.total_fundings_sum),
              0,
            ),
            count: getCompanies.length,
            color: tagIsSelected ? item.color : "#999999",
            originalColor: item.color,
          };
        })
        .filter((tag) => tag.count !== 0);
    }

    if (colorAsOption === "yearFounded") {
      data = tags
        .map((item) => {
          let getCompanies = [];
          const tagIsSelected = selectedTags
            ? filterBy.find((t) => t.id === item.id && t.selected)
            : true;

          if (item.value > 20) {
            getCompanies = companies.filter((c) => c.years_in_operation > 20);
          } else {
            getCompanies = companies.filter(
              (c) => c.years_in_operation <= item.value && c.years_in_operation < 21,
            );
          }

          return {
            ...item,
            companiesIdThatBelongsTo: getCompanies.map((c) => c.id),
            totalFunding: getCompanies.reduce(
              (acc, c) => (acc += c.total_fundings_sum),
              0,
            ),
            count: getCompanies.length,
            color: tagIsSelected ? item.color : "#999999",
            originalColor: item.color,
          };
        })
        .filter((tag) => tag.count !== 0);
    }

    if (colorAsOption === "score") {
      data = tags
        .map((item) => {
          const tagIsSelected = selectedTags
            ? filterBy.find((t) => t.id === item.id && t.selected)
            : true;

          const getCompanies = companies.filter(
            (c) => c.council_company_score === item.score,
          );

          return {
            ...item,
            companiesIdThatBelongsTo: getCompanies.map((c) => c.id),
            totalFunding: getCompanies.reduce(
              (acc, c) => (acc += c.total_fundings_sum),
              0,
            ),
            count: getCompanies.length,
            color: tagIsSelected ? item.color : "#999999",
            originalColor: item.color,
          };
        })
        .filter((tag) => tag.count !== 0);
    }

    if (
      colorAsOption === "totalFunding"
      || colorAsOption === "amountOfLastRound"
    ) {
      const prop = colorAsOption === "totalFunding"
        ? "total_fundings_sum"
        : "last_funding_amount_usd";

      data = tags
        .map((item) => {
          let getCompanies = [];
          const tagIsSelected = selectedTags
            ? filterBy.find((t) => t.id === item.id && t.selected)
            : true;

          if (item.id === "0na") {
            getCompanies = companies.filter((c) => !c[prop] || c[prop] === 0);
          } else {
            getCompanies = companies.filter(
              (c) => c[prop] >= item.range.start && c[prop] <= item.range.end,
            );
          }

          return {
            ...item,
            companiesIdThatBelongsTo: getCompanies.map((c) => c.id),
            totalFunding: getCompanies.reduce(
              (acc, c) => (acc += c.total_fundings_sum),
              0,
            ),
            count: getCompanies.length,
            color: tagIsSelected ? item.color : "#999999",
            originalColor: item.color,
          };
        })
        .filter((tag) => tag.count !== 0);
    }
  } else if (colorAsOption === "relationShipStatus") {
    data = relationTags
      .reduce((acc, tag) => {
        const isInFilter = selectedTags
          ? filterBy.some((t) => tag.slug === t.slug && t.selected)
          : true;
        const color = isInFilter
          ? tag?.colors?.background || RELATIONSHIP_TAGS_COLORS[tag.slug]
          : "#999999";
        const companiesDataByTag = companies
          .filter((c) => c.council_relationship_stage_id === tag.customId)
          .reduce(
            (acc1, company) => ({
              totalFunding: acc1.totalFunding + company.total_fundings_sum || 0,
              count: acc1.count + 1,
            }),
            {
              totalFunding: 0,
              count: 0,
            },
          );

        return [
          ...acc,
          {
            ...companiesDataByTag,
            ...tag,
            id: tag.slug,
            color,
          },
        ];
      }, [])
      .filter((tag) => tag.count > 0);
  } else {
    const filteredCompaniesByTags = selectedTags?.length
      ? companies.filter((company) => {
        const items = company[PROP_BY_COLOR[colorAsOption].top];

        return (
          items.filter((item) => selectedTags.some(
            (tag) => tag.id === item[PROP_BY_COLOR[colorAsOption].bottom],
          )).length > 0
        );
      })
      : companies;

    const dataBasedOnCouncilTags = filteredCompaniesByTags
      .reduce((acc, company) => {
        let newAcc = acc;
        const items = company[PROP_BY_COLOR[colorAsOption].top];

        if (!items) return newAcc;

        items.forEach((item) => {
          const itemId = item[PROP_BY_COLOR[colorAsOption].bottom];
          const itemExist = newAcc.some((i) => itemId === i.id);

          if (itemExist) {
            newAcc = newAcc.map((i) => {
              if (i.id === itemId) {
                return {
                  ...i,
                  count: i.count + 1,
                  companiesIdThatBelongsTo: [
                    ...i.companiesIdThatBelongsTo,
                    company.id,
                  ],
                  totalFunding: i.totalFunding + company.total_fundings_sum,
                };
              }

              return i;
            });
          } else {
            const { name } = item;
            const filteredTags = filterBy
              ? filterBy.filter((t) => t.selected)
              : [];
            const isInFilter = filteredTags.length
              ? filteredTags.some((tag) => itemId === tag.id)
              : true;
            const color = isInFilter ? null : "#999999";

            newAcc = [
              ...newAcc,
              {
                name,
                id: itemId,
                count: 1,
                color,
                companiesIdThatBelongsTo: [company.id],
                totalFunding: 0,
              },
            ];
          }
        });

        return newAcc;
      }, [])
      .filter((item) => councilTags.some((t) => t.id === item.id))
      .map((item, idx) => {
        const color = item.color === "#999999"
          ? item.color
          : CHART_COLORS[idx % CHART_COLORS.length];

        return {
          ...item,
          color,
          originalColor: CHART_COLORS[idx % CHART_COLORS.length],
        };
      });

    if (dataBasedOnCouncilTags.length > 7) {
      const mappedValue = dataBasedOnCouncilTags.map((tag) => tag.count);
      const minRange = Math.min.apply(Math, mappedValue);
      const maxRange = Math.max.apply(Math, mappedValue);
      const tagsWithOther = dataBasedOnCouncilTags.reduce(
        (acc, tag) => {
          const percentageFrom = ((tag.count - minRange) * 100) / (maxRange - minRange);

          if (percentageFrom < 7) {
            return acc.map((t) => {
              if (t.name === "Other") {
                return {
                  ...t,
                  count: t.count + tag.count,
                  companiesIdThatBelongsTo: [
                    ...t.companiesIdThatBelongsTo,
                    ...tag.companiesIdThatBelongsTo,
                  ],
                  totalFunding: t.totalFunding + tag.totalFunding,
                };
              }
              return t;
            });
          }

          return [...acc, tag];
        },
        [
          {
            name: "Other",
            id: "other",
            totalFunding: 0,
            count: 0,
            companiesIdThatBelongsTo: [],
            color: "#188B7F",
            originalColor: "#188B7F",
          },
        ],
      );
      const [first, ...rest] = tagsWithOther;
      const sortedRest = rest.sort((a, b) => b.count - a.count);

      data = [...sortedRest, first].map((elem, idx) => {
        if (elem.id === "other") {
          const selectedFilters = filterBy.filter((t) => t.selected);

          if (!selectedFilters.length) {
            return elem;
          }

          return {
            ...elem,
            color: selectedFilters.some((t) => t.id === "other")
              ? elem.color
              : "#999999",
          };
        }

        const color = elem.color === "#999999"
          ? elem.color
          : CHART_COLORS[idx % CHART_COLORS.length];

        return {
          ...elem,
          color,
          originalColor: CHART_COLORS[idx % CHART_COLORS.length],
        };
      });
    } else {
      data = dataBasedOnCouncilTags
        .sort((a, b) => b.count - a.count)
        .map((elem, idx) => {
          const color = elem.color === "#999999"
            ? elem.color
            : CHART_COLORS[idx % CHART_COLORS.length];

          return {
            ...elem,
            color,
            originalColor: CHART_COLORS[idx % CHART_COLORS.length],
          };
        });
    }
  }

  const colors = data.map((i) => i.color);

  if (data.length === 1) {
    return {
      labels: data.map((i) => i.name),
      datasets: [
        {
          backgroundColor: colors,
          borderColor: colors,
          data: data.map((i) => i.count),
          customDataPerIndex: data,
          hoverOffset: 4,
        },
      ],
    };
  }

  return {
    labels: data.map((i) => i.name),
    datasets: [
      {
        backgroundColor: colors,
        data: data.map((i) => i.count),
        customDataPerIndex: data,
        hoverOffset: 4,
      },
    ],
  };
};

export const makePieChartConfig = (
  companies,
  selectedChartOptions,
  filterBy,
  relationShipStatuses,
  councilTags = [],
  isHomeUse,
) => {
  const filteredCompanies = selectedChartOptions.color === "relationShipStatus"
    ? companies.filter(
      (c) => c.council_relationship_stage_name
            && c.council_relationship_stage_name !== "Traction Match",
    )
    : companies;

  return {
    data: makePieChartData(
      filteredCompanies,
      selectedChartOptions,
      filterBy,
      relationShipStatuses,
      councilTags,
    ),
    options: makePieChartOptions(selectedChartOptions, isHomeUse),
    plugins: [ChartDataLabels],
  };
};

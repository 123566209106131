export const businessUnitOptions = [
  // {
  //   text: '-',
  //   id: 'none',
  // },
  // {
  //   text: 'Single Family',
  //   value: 'single_family',
  // },
  // {
  //   text: 'Multifamily',
  //   value: 'multifamily',
  // },

  { id: "791f47d6-e80d-4a40-8aa4-6cb3569a0204", name: "Single Family" },
  { id: "4f28de92-19d5-4812-a190-0a545de7d9c8", name: "Multifamily" },
];

export const partnerOptions = [
  {
    text: "-",
    value: "none",
  },
  {
    text: "Alliance",
    value: "alliance",
  },
  {
    text: "Innovation",
    value: "innovation",
  },
  {
    text: "Vendor",
    value: "vendor",
  },
];

export const parseEstRevenue = (value) => {
  if (value === "r_00000000") {
    return "Less than $1M";
  }
  if (value === "r_00001000") {
    return "$1M to $10M";
  }
  if (value === "r_00010000") {
    return "$10M to $50M";
  }
  if (value === "r_00050000") {
    return "$50M to $100M";
  }
  if (value === "r_00100000") {
    return "$100M to $500M";
  }
  if (value === "r_00500000") {
    return "$500M to $1B";
  }
  if (value === "r_01000000") {
    return "$1B to $10B";
  }
  if (value === "r_10000000") {
    return "$10B+";
  }
  return "-";
};

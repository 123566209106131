import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { format } from "date-fns";

import styles from "./projectDetails.module.scss";
import {
  MultiSelect,
  MultiUsers,
  Pannel,
  ProjectState,
  UserInline,
  UserInlineTypes,
} from "../../../../primitives";
import { Icon, smSize } from "../../../../../common/icon";
import { useModelPopup } from "../../../../../common/hooks";
import ProjectDetailsEdit from "modules/projects/project/overview/projectDetails/projectDetailsEdit.component";
import ProjectFieldsEdit from "modules/projects/project/overview/projectDetails/projectFieldsEdit.component";
import {
  getProjectFieldGroups,
  projectPutSuccess,
} from "modules/projects/project/project.action";
import { APPROVAL_STATUSES } from "modules/projects/project/overview/projectDetails/constants";
import { httpPut } from "../../../../../common/httpCall";
import ProjectDescription from "modules/projects/projectDescription";
import { isANumber, makeProjectField } from "../../../../../common/helper";
import { currencySymbolObj } from "../../../../../common/constants";
import ProjectDetailsTags from "modules/projects/project/overview/projectDetails/projectDetailsTags";

const ProjectDetailsComponent = ({
  canManageTasks,
  metrics,
  setEditMetrics,
  isAdmin,
  isNot3DPrintingTemplate,
}) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    projectsReducer: {
      projectReducer: { selectedProject, fieldGroups },
    },
  } = useSelector((state) => state);
  const [patientOrg, setPatientOrg] = useState(false);

  const owners = useMemo(
    () =>
      selectedProject?.project_members?.filter((m) => m.user_role === "owner"),
    [selectedProject]
  );

  const isFordSupplyChainAutomation = useMemo(() => {
    return (
      selectedCouncil?.name === "Ford" &&
      selectedProject?.stage_template?.name === "Supply Chain Automation"
    );
  }, [selectedCouncil?.name, selectedProject?.stage_template?.name]);

  const getSpecificSupplyChainGroup = useMemo(() => {
    if (!isFordSupplyChainAutomation) return null
    return fieldGroups?.project_fields_with_groups.find(group => group.name === 'Project Overview');
  }, [isFordSupplyChainAutomation, fieldGroups]);

  const loggedUserIsProjectOwner = useMemo(() => {
    if (selectedCouncil?.name === "Ford" && session?.id) {
      const isAdmin = ["ttp_administrator", "council_administrator"].includes(
        session?.council_role
      );

      if (isAdmin) return false;

      return owners?.some((o) => o.user.id === session?.id);
    }

    return false;
  }, [session, selectedCouncil, owners]);

  useEffect(() => {
    if (
      selectedProject &&
      selectedProject?.stage_template?.patient_organization
    ) {
      setPatientOrg(true);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (selectedProject?.id) {
      dispatch(
        getProjectFieldGroups({
          projectId: selectedProject.id,
        })
      );
    }
  }, [selectedProject?.id]);

  const renderProjectAccess = () => {
    if (selectedProject?.visibility === "team") {
      return `Team: ${selectedProject?.team?.name || null}`;
    }
    return selectedProject?.access || null;
  };

  const handleManageDetails = (type) => {
    if (type === 0) {
      popup.show({
        title: "Edit Project Details",
        component: <ProjectDetailsEdit
          isPatientOrg={patientOrg}
          isFordSupplyChainAutomation={isFordSupplyChainAutomation}
          isNot3DPrintingTemplate={isNot3DPrintingTemplate}
        />,
      });
    }
  };

  const handleGroupEdit = (group) => {
    if (group?.name === "Implementation" && selectedCouncil?.name === "Ford") {
      setEditMetrics();
      return;
    }
    const newGroup = {
      ...group,
      project_fields: group.project_fields.map(makeProjectField),
    };

    popup.show({
      title: `Edit ${newGroup.name || ""} group`,
      component: <ProjectFieldsEdit selectedGroup={newGroup} />,
    });
  };

  const getApprovalStatusVal = useMemo(() => {
    if (selectedProject?.approval_status) {
      return APPROVAL_STATUSES.filter(
        (s) => s.id === selectedProject.approval_status
      );
    }

    return [];
  }, [selectedProject]);

  const handleUpdateApprovalStatus = (val) => {
    if (!selectedProject?.approval_status_access) return;

    if (val?.length) {
      httpPut({
        call: `projects/${selectedProject.id}/update_approval`,
        data: {
          approval_status: val[0].id,
        },
      }).subscribe((res) => {
        if (res) {
          dispatch(projectPutSuccess(res));
        }
      });
    }
  };

  const canManageReadyForReplication = useCallback(
    (group) => {
      if (group.name !== "Ready for Replication") return canManageTasks;

      return isAdmin;
    },
    [selectedCouncil, isAdmin, canManageTasks]
  );

  return (
    <Pannel title="Project Details">
      <>
        <div className={styles.wrapper}>
          <h5 className={styles.title}>
            {isFordSupplyChainAutomation ? 'Elevator Pitch' : selectedProject?.name}
          </h5>
          <div className={styles.body}>
            <div className={classNames("caption", styles.details)}>
              <ProjectDescription
                description={selectedProject?.description}
                howManyChars={700}
              />
            </div>
            <div className={styles.infoWrp}>
              <div className="d-flex flex-row w-100 position-relative">
                {!loggedUserIsProjectOwner && canManageTasks && (
                  <div
                    className="cursor-pointer position-absolute"
                    style={{ right: 0, top: "-10px" }}
                    onClick={() => handleManageDetails(0)}
                  >
                    <Icon {...smSize} icon="icn-edit" />
                  </div>
                )}
                <div className={styles.infoCol}>
                  <div className={styles.infoRow}>
                    <div
                      className={classNames(
                        "font-weight-bold",
                        "caption",
                        "small",
                        styles.label
                      )}
                    >
                      Start date
                    </div>
                    <div className={styles.value}>
                      {selectedProject &&
                        format(
                          new Date(
                            selectedProject.start_date ||
                              selectedProject.created_at
                          ),
                          "MMM do, yyyy"
                        )}
                    </div>
                  </div>
                  <div className={styles.infoRow}>
                    <div
                      className={classNames(
                        "font-weight-bold",
                        "caption",
                        "small",
                        styles.label
                      )}
                    >
                      End Date
                    </div>
                    <div className={styles.value}>
                      {selectedProject && selectedProject.end_date
                        ? format(
                            new Date(selectedProject.end_date),
                            "MMM do, yyyy"
                          )
                        : "-"}
                    </div>
                  </div>
                </div>
                <div className={styles.infoCol}>
                  <div className={styles.infoRow}>
                    <div
                      className={classNames(
                        "font-weight-bold",
                        "caption",
                        "small",
                        styles.label
                      )}
                    >
                      Status
                    </div>
                    <div className={classNames(styles.value, styles.status)}>
                      <ProjectState state={selectedProject?.status} />
                    </div>
                  </div>
                  <div className={styles.infoRow}>
                    <div
                      className={classNames(
                        "font-weight-bold",
                        "caption",
                        "small",
                        styles.label
                      )}
                    >
                      Project Owner{owners?.length > 1 ? "s" : ""}
                    </div>
                    <div className={classNames(styles.value, styles.owner)}>
                      {owners?.length > 1 ? (
                        <MultiUsers
                          users={owners.map((o) => o.user)}
                          total={owners.length}
                          dontShowRemaining
                        />
                      ) : owners?.length ? (
                        <UserInline
                          userId={owners[0]?.user?.id}
                          avatar={owners[0]?.user?.avatar}
                          first={owners[0]?.user?.first_name}
                          last={owners[0]?.user?.last_name}
                          type={UserInlineTypes.WITH_NAME}
                          dontRequest
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                {patientOrg && (
                  <div className={styles.infoCol}>
                    <div className={styles.infoRow}>
                      <div
                        className={classNames(
                          "font-weight-bold",
                          "caption",
                          "small",
                          styles.label
                        )}
                      >
                        Country
                      </div>
                      <div
                        className={classNames(styles.value, styles.visiblity)}
                      >
                        {selectedProject && selectedProject.id && (
                          <div className={classNames("caption", "small")}>
                            {selectedProject.country || "n/a"}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {!isFordSupplyChainAutomation && (
                  <div className={styles.infoCol}>
                    <div className={styles.infoRow}>
                      <div
                        className={classNames(
                          "font-weight-bold",
                          "caption",
                          "small",
                          styles.label
                        )}
                      >
                        Priority
                      </div>
                      <div
                        className={classNames(styles.value, styles.visiblity)}
                      >
                        {selectedProject?.priority}
                      </div>
                    </div>
                    <div className={styles.infoRow}>
                      <div
                        className={classNames(
                          "font-weight-bold",
                          "caption",
                          "small",
                          styles.label
                        )}
                      >
                        Access
                      </div>
                      <div
                        className={classNames(styles.value, styles.visiblity)}
                      >
                        {renderProjectAccess()}
                      </div>
                    </div>
                  </div>
                )}
                <div className={classNames(styles.infoCol, styles.colFlex2)}>
                  {selectedProject &&
                  selectedProject.stage_template?.name === "Patient Organization" ? (
                    <ProjectDetailsTags isPatientOrg title="Diseases" data={selectedProject} />
                  ) : (
                    <ProjectDetailsTags title="Tags" data={selectedProject} />
                  )}
                </div>
              </div>
              {selectedCouncil?.name === "Ford" && (
                <>
                  <div className={styles.infoWrpFull}>
                    {isNot3DPrintingTemplate &&
                      !isFordSupplyChainAutomation && (
                        <div
                          className={classNames(
                            styles.infoCol,
                            styles.colFlex2,
                          )}
                        >
                          <div className={styles.infoRow}>
                            <div
                              className={classNames(
                                "font-weight-bold",
                                "caption",
                                "small",
                                styles.label,
                              )}
                            >
                              Prototype Date
                            </div>
                            <div
                              className={classNames(
                                styles.value,
                                styles.visiblity,
                              )}
                            >
                              {selectedProject?.prototype_date
                                ? format(
                                  new Date(selectedProject?.prototype_date),
                                  "MMM do, yyyy",
                                )
                                : "-"}
                            </div>
                          </div>
                        </div>
                      )}
                    {selectedProject?.stage_template?.name !==
                      "3D Printing Savings" &&
                      !isFordSupplyChainAutomation && (
                        <div
                          className={classNames(
                            styles.infoCol,
                            styles.colFlex2,
                          )}
                        >
                          <div className={styles.infoRow}>
                            <div
                              className={classNames(
                                "font-weight-bold",
                                "caption",
                                "small",
                                styles.label,
                              )}
                            >
                              Implementation Date
                            </div>
                            <div
                              className={classNames(
                                styles.value,
                                styles.visiblity,
                              )}
                            >
                              {selectedProject?.implementation_date
                                ? format(
                                  new Date(
                                    selectedProject?.implementation_date,
                                  ),
                                  "MMM do, yyyy",
                                )
                                : "-"}
                            </div>
                          </div>
                        </div>
                      )}
                    {
                      !isFordSupplyChainAutomation && (
                        <div
                          className={classNames(styles.infoCol, styles.colFlex2)}
                        >
                          <div className={styles.infoRow}>
                            <div
                              className={classNames(
                                "font-weight-bold",
                                "caption",
                                "small",
                                styles.label,
                              )}
                            >
                              Approval Status
                            </div>
                            <div
                              className={classNames(styles.value, styles.visiblity)}
                            >
                              <MultiSelect
                                values={getApprovalStatusVal}
                                onChange={handleUpdateApprovalStatus}
                                placeholder="Approval Status"
                                labelField="name"
                                valueField="id"
                                options={APPROVAL_STATUSES}
                                searchBy="name"
                                multi={false}
                                disabled={!selectedProject?.approval_status_access}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                  {isNot3DPrintingTemplate && !isFordSupplyChainAutomation && (
                    <div className={styles.infoWrpFull}>
                      <div
                        className={classNames(styles.infoCol, styles.colFlex2)}
                      >
                        <div className={styles.infoRow}>
                          <div
                            className={classNames(
                              "font-weight-bold",
                              "caption",
                              "small",
                              styles.label,
                            )}
                          >
                            Job 1 Date (Prototype)
                          </div>
                          <div
                            className={classNames(
                              styles.value,
                              styles.visiblity,
                            )}
                          >
                            {selectedProject?.prototype_job_1_date
                              ? format(
                                new Date(
                                  selectedProject?.prototype_job_1_date,
                                ),
                                "MMM do, yyyy",
                              )
                              : "-"}
                          </div>
                        </div>
                      </div>
                      <div
                        className={classNames(styles.infoCol, styles.colFlex2)}
                      >
                        <div className={styles.infoRow}>
                          <div
                            className={classNames(
                              "font-weight-bold",
                              "caption",
                              "small",
                              styles.label,
                            )}
                          >
                            Expected Job 1 Date (Proposal for Prototype)
                          </div>
                          <div
                            className={classNames(
                              styles.value,
                              styles.visiblity,
                            )}
                          >
                            {selectedProject?.proposal_for_prototype_expected_job_1_date
                              ? format(
                                new Date(
                                  selectedProject?.proposal_for_prototype_expected_job_1_date,
                                ),
                                "MMM do, yyyy",
                              )
                              : "-"}
                          </div>
                        </div>
                      </div>
                      <div
                        className={classNames(styles.infoCol, styles.colFlex2)}
                      >
                        <div className={styles.infoRow}>
                          <div
                            className={classNames(
                              "font-weight-bold",
                              "caption",
                              "small",
                              styles.label,
                            )}
                          >
                            Expected Job 1 Date
                          </div>
                          <div
                            className={classNames(
                              styles.value,
                              styles.visiblity,
                            )}
                          >
                            {selectedProject?.expected_job_1_date
                              ? format(
                                new Date(
                                  selectedProject?.expected_job_1_date,
                                ),
                                "MMM do, yyyy",
                              )
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {
                    isFordSupplyChainAutomation && getSpecificSupplyChainGroup && (
                      <div className={styles.infoWrpFull}>
                        <div className="mb-3 position-relative w-100">
                          {canManageReadyForReplication(getSpecificSupplyChainGroup) && (
                            <div
                              className="cursor-pointer position-absolute"
                              style={{ right: 0, top: "20px" }}
                              onClick={() => handleGroupEdit(getSpecificSupplyChainGroup)}
                            >
                              <Icon {...smSize} icon="icn-edit" />
                            </div>
                          )}
                          <div className="d-flex flex-row flex-wrap mt-2">
                            {getSpecificSupplyChainGroup?.project_fields
                              .map((field) => {
                                return (
                                  <div
                                    key={field.project_field_template_id}
                                    className={classNames(styles.infoCol, styles.colFlex2)}
                                  >
                                    <div className={styles.infoRow}>
                                      <span className="small font-weight-bold">
                                        {field.field_name}
                                      </span>
                                      {field.field_type === "text" && (
                                        <div>
                                          {field.field_value?.length >
                                          (!field?.big_box_text ? 40 : 330) ? (
                                            <Tooltip
                                              title={field.field_value}
                                              placement="top"
                                              arrow
                                            >
                                              <span className="cursor-pointer">
                                                {field.field_value.slice(
                                                  0,
                                                  !field?.big_box_text
                                                    ? 40
                                                    : 330,
                                                )}
                                                ...
                                              </span>
                                            </Tooltip>
                                          ) : (
                                            <div className="caption mt-2">
                                              {field.field_value || "-"}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {field.field_type === "date" && (
                                        <div>
                                          {field.field_date ? (
                                            <div className="caption mt-2">
                                              {format(
                                                new Date(field.field_date),
                                                "MMM do, yyyy",
                                              )}
                                            </div>
                                          ) : (
                                            "-"
                                          )}
                                        </div>
                                      )}
                                      {(field.field_type === "number" ||
                                        field.field_type === "currency") && (
                                        <div>
                                          {field.field_number ? (
                                            <div className="caption mt-2">
                                              {field.option_selected.length
                                                ? currencySymbolObj[field.option_selected[0]]
                                                : null}{" "}
                                              {field.field_number}
                                            </div>
                                          ) : (
                                            "-"
                                          )}
                                        </div>
                                      )}
                                      {field.field_type === "dropdown" && (
                                        <div className="d-flex flex-column">
                                          {field.multiselect ? (
                                            field.option_selected?.length ? (
                                              field.option_selected.map(
                                                (o, idx) => (
                                                  <span
                                                    className="caption"
                                                    key={`${o}-${idx}`}
                                                  >
                                                    {o}
                                                    {field.option_selected
                                                      ?.length === 1
                                                      ? ""
                                                      : field.option_selected
                                                        ?.length ===
                                                      idx + 1
                                                        ? ""
                                                        : ","}
                                                  </span>
                                                ),
                                              )
                                            ) : (
                                              "-"
                                            )
                                          ) : (
                                            <span className="caption">
                                              {field.option_selected.length
                                                ? field.option_selected[0]
                                                : field.field_value || "-"}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    )
                  }
                </>
              )}
            </div>
            {selectedProject?.related_idea && (
              <div className={`${styles.infoWrp} ml-5`}>
                <div className={styles.infoRow}>
                  <div className={classNames("caption", "small", styles.label)}>
                    Related Idea
                  </div>
                  <div className={styles.value}>
                    <Link
                      className={styles.valueAhref}
                      to={`/ideas/${selectedProject?.related_idea.idea_id}`}
                    >
                      {selectedProject?.related_idea.name}
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
          {fieldGroups?.project_fields_without_groups?.length > 0 ||
          fieldGroups?.project_fields_with_groups?.length > 0 ? (
            <div className={styles.body}>
              <div className="d-flex flex-row align-items-center flex-wrap w-100">
                <div className="d-flex flex-column w-100 position-relative">
                  {fieldGroups?.project_fields_with_groups?.length > 0 ? (
                    <div className="d-flex flex-column mt-3">
                      {fieldGroups?.project_fields_with_groups.filter(group => {
                        return !(isFordSupplyChainAutomation && group.name === "Project Overview");
                      })
                        .map((group) => (
                          <div key={group.id} className="mb-3 position-relative">
                            <hr className="mt-3 mb-3" />
                            <h5
                              className={`${styles.groupHeaderName} font-weight-bold d-flex align-items-center justify-content-center mb-4`}
                            >
                              {group.name}
                            </h5>{" "}
                            {canManageReadyForReplication(group) && (
                              <div
                                className="cursor-pointer position-absolute"
                                style={{ right: 0, top: "20px" }}
                                onClick={() => handleGroupEdit(group)}
                              >
                                <Icon {...smSize} icon="icn-edit" />
                              </div>
                            )}
                            {group?.name === "Implementation" &&
                            selectedCouncil?.name === "Ford" ? (
                              <div className="d-flex flex-column align-items-center justify-content-center mt-2">
                              <span className="small font-weight-bold">
                                Job 1 Date
                              </span>
                                <div className="caption mt-2">
                                  {metrics.job_1_date
                                    ? format(
                                      new Date(metrics.job_1_date),
                                      "MMM do, yyyy",
                                    )
                                    : "-"}
                                </div>
                              </div>
                            ) : (
                              <div className="d-flex flex-row flex-wrap mt-2">
                                {[
                                  ...group?.project_fields,
                                  ...(group.project_metrics?.map(m => ({...m, field_name: m.name, field_value: m.value, isMetricField: true})) || [])
                                ]
                                  .filter(
                                    (f) =>
                                      f.field_name !==
                                      "Possible Replications (Plants)" &&
                                      f.field_name !==
                                      "Possible Replications (Areas)",
                                  )
                                  .map((field) => {
                                    return (
                                      <div
                                        key={field.project_field_template_id}
                                        className={classNames(
                                          styles.infoCol,
                                          "pl-0 mb-3 pr-3",
                                          field?.big_box_text ? "w-50" : "",
                                          field.field_name === 'Annual Incremental Investment ($)' ? 'w-25' : ''
                                        )}
                                      >
                                        <span className="small font-weight-bold">
                                          {field.field_name}
                                        </span>
                                        {
                                          field.isMetricField && (
                                            <div className="caption mt-2">
                                              {
                                                field.type === '%' && field.field_value ? `${field.field_value.toLocaleString()}%` : ''
                                              }
                                              {
                                                field.type === '$' && (
                                                  isANumber(field?.field_value)
                                                    ? `${field.type}${field.field_value.toLocaleString()}`
                                                    : field.field_value
                                                )
                                              }
                                            </div>
                                          )
                                        }
                                        {field.field_type === "text" && (
                                          <div>
                                            {field.field_value?.length >
                                            (!field?.big_box_text ? 40 : 330) ? (
                                              <Tooltip
                                                title={field.field_value}
                                                placement="top"
                                                arrow
                                              >
                                              <span className="cursor-pointer">
                                                {field.field_value.slice(
                                                  0,
                                                  !field?.big_box_text
                                                    ? 40
                                                    : 330,
                                                )}
                                                ...
                                              </span>
                                              </Tooltip>
                                            ) : (
                                              <div className="caption mt-2">
                                                {field.field_value || "-"}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                        {field.field_type === "date" && (
                                          <div>
                                            {field.field_date ? (
                                              <div className="caption mt-2">
                                                {format(
                                                  new Date(field.field_date),
                                                  "MMM do, yyyy",
                                                )}
                                              </div>
                                            ) : (
                                              "-"
                                            )}
                                          </div>
                                        )}
                                        {(field.field_type === "number" ||
                                          field.field_type === "currency") && (
                                          <div>
                                            {field.field_number ? (
                                              <div className="caption mt-2">
                                                {field.option_selected.length
                                                  ? currencySymbolObj[field.option_selected[0]]
                                                  : null}{" "}
                                                {
                                                  field.field_type === "currency" && isANumber(field?.field_number)
                                                    ? field.field_number.toLocaleString()
                                                    : field.field_number
                                                }
                                              </div>
                                            ) : (
                                              "-"
                                            )}
                                          </div>
                                        )}
                                        {field.field_type === "dropdown" && (
                                          <div className="d-flex flex-column">
                                            {field.multiselect ? (
                                              field.option_selected?.length ? (
                                                field.option_selected.map(
                                                  (o, idx) => (
                                                    <span
                                                      className="caption"
                                                      key={`${o}-${idx}`}
                                                    >
                                                    {o}
                                                      {field.option_selected
                                                        ?.length === 1
                                                        ? ""
                                                        : field.option_selected
                                                          ?.length ===
                                                        idx + 1
                                                          ? ""
                                                          : ","}
                                                  </span>
                                                  ),
                                                )
                                              ) : (
                                                "-"
                                              )
                                            ) : (
                                              <span className="caption">
                                              {field.option_selected.length
                                                ? field.option_selected[0]
                                                : field.field_value || "-"}
                                            </span>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : null}
                  <hr className="mt-3 mb-5" />
                  {fieldGroups?.project_fields_without_groups?.length > 0 ? (
                    <div className="d-flex flex-row flex-wrap position-relative">
                      {canManageTasks && (
                        <div
                          className="cursor-pointer position-absolute"
                          style={{ right: 0 }}
                          onClick={() =>
                            handleGroupEdit({
                              name: null,
                              id: null,
                              project_fields:
                              fieldGroups?.project_fields_without_groups,
                            })
                          }
                        >
                          <Icon {...smSize} icon="icn-edit" />
                        </div>
                      )}
                      {fieldGroups?.project_fields_without_groups
                        .filter(
                          (f) =>
                            f.field_name !== "Possible Replications (Plants)" &&
                            f.field_name !== "Possible Replications (Areas)"
                        )
                        .map((field) => {
                          return (
                            <div
                              key={field.project_field_template_id}
                              className={classNames(
                                styles.infoCol,
                                "pl-0 mb-3 pr-3",
                                field?.big_box_text ? "w-50" : ""
                              )}
                            >
                              <span className="small font-weight-bold">
                                {field.field_name}
                              </span>
                              {field.field_type === "text" && (
                                <div>
                                  {field.field_value?.length >
                                  (!field?.big_box_text ? 40 : 330) ? (
                                    <Tooltip
                                      title={field.field_value}
                                      placement="top"
                                      arrow
                                    >
                                      <span className="cursor-pointer">
                                        {field.field_value.slice(
                                          0,
                                          !field?.big_box_text ? 40 : 330
                                        )}
                                        ...
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    <div className="caption mt-2">
                                      {field.field_value || "-"}
                                    </div>
                                  )}
                                </div>
                              )}
                              {field.field_type === "dropdown" && (
                                <div className="d-flex flex-column">
                                  {field.multiselect ? (
                                    field.option_selected?.length ? (
                                      field.option_selected.map((o, idx) => (
                                        <span
                                          className="caption"
                                          key={`${o}-${idx}`}
                                        >
                                          {o}
                                          {field.option_selected?.length === 1
                                            ? ""
                                            : field.option_selected?.length ===
                                            idx + 1
                                              ? ""
                                              : ","}
                                        </span>
                                      ))
                                    ) : (
                                      "-"
                                    )
                                  ) : (
                                    <span className="caption">
                                      {field.option_selected.length
                                        ? field.option_selected[0]
                                        : field.field_value}
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </>
    </Pannel>
  );
};

export default ProjectDetailsComponent;

import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import { httpGet } from "common/httpCall";
import styles from "./overview.module.scss";
import DetailsComponent from "./details";
import RelationshipOwnerComponent from "./relationshipOwner/relationshipOwner.component";
import CompanyContactComponent from "./companyContact/companyContact.component";
// import ProjectsComponent from "../../../companies/company/overview/projects/projects.component";
import CompanyCard from "../../../companies/company/components/companyCard/companyCard.component";
import { useWindowSize } from "../../../../common/hooks/useWindowSize";

const OverviewComponent = ({ patientOrg, loading }) => {
  const [patientOrgContact, setPatientOrgContact] = useState({});
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (!patientOrg.id) return;

    httpGet({
      call: `gsk/patient_organizations/${patientOrg.id}`,
    })
      .pipe()
      .subscribe((res) => setPatientOrgContact(res.response));
  }, [patientOrg]);

  return (
    <div className={styles.wrapper}>
      {loading ? (
        <div className="row m-0">
          <div className="col-12 col-md-8 flex-grow-1 p-0">
            <div className={styles.skeletonBlock}>
              <Skeleton height={20} />
              <div className={styles.skeletonText}>
                <Skeleton height={350} />
              </div>
            </div>
            <div className={styles.skeletonBlock}>
              <Skeleton height={20} />
              <div className={styles.skeletonText}>
                <Skeleton height={350} />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 p-0">
            <div className={styles.skeletonBlock}>
              <Skeleton height={20} />
              <div className={styles.skeletonText}>
                <Skeleton height={350} />
              </div>
            </div>
            <div className={styles.skeletonBlock}>
              <Skeleton height={20} />
              <div className={styles.skeletonText}>
                <Skeleton height={350} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        patientOrg && (
          <div className="row m-0">
            <div className="col-12 col-md-8 flex-grow-1">
              <CompanyCard title="Organization Information" className="mb-5">
                <DetailsComponent company={patientOrg} />
              </CompanyCard>
              {/* <CompanyCard
                title="Projects"
                className={`${isMobile ? "mb-5" : ""}`}
              > */}
              {/* <ProjectsComponent company={patientOrg} /> */}
              {/* </CompanyCard> */}
            </div>
            <div className="col-12 col-md-4">
              {patientOrg.relationship_owners ? (
                <CompanyCard title="Relationship Manager" className="mb-5">
                  <RelationshipOwnerComponent
                    relationships={patientOrg.relationship_owners}
                    companyId={patientOrg.id}
                  />
                </CompanyCard>
              ) : null}
              {patientOrgContact.contacts && (
                <CompanyCard title="Organization Contacts">
                  <CompanyContactComponent
                    companyId={patientOrg.id}
                    companyContacts={patientOrgContact.contacts}
                  />
                </CompanyCard>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default React.memo(OverviewComponent);

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import classNames from "classnames";
import CompanyNote from "modules/companies/company/components/companyNote/companyNote.component";
import { projectRatingSet } from "modules/projects/project/project.action";

import styles from "./ratings.module.scss";
import SliderComponent from "./slider/slider.component";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import RatingAddNotesComponent from "./ratingAddNotes/ratingAddNotes.component";
import { BtnType, Button, Pannel } from "../../../../primitives";
import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../../../../../common/httpCall";

const ProjectRatings = ({ project, expandAll }) => {
  const {
    authReducer: { session },
    companiesReducer: {
      companyReducer: { companyRatingNotes, loadingNotes },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedNote, setSelectedNote] = useState();
  const [showFeedback, setShowFeedback] = useState(false);
  const [ratingNotes, setRatingNotes] = useState(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expandAll) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [expandAll]);
  useEffect(() => {
    setShowFeedback(ratingNotes?.length > 0);
  }, [ratingNotes]);

  const handleOnChange = (obj, category) => {
    const { value } = obj;

    const payload = {
      data: {
        rating: {
          rating_template_id: project.rating_template?.rating_template_id,
          rating_category_id: category.rating_category_id,
          value,
        },
      },
      project_id: project.id,
      enqueueSnackbar,
    };

    dispatch(projectRatingSet(payload));
  };

  const addNewNote = (value) => {
    const data = {
      note: {
        description: value.note,
      },
    };

    httpPost({
      call: `projects/${project.id}/ratings/${project.rating_id}/notes`,
      data,
    }).subscribe((res) => {
      if (res.response?.data?.attributes?.id) {
        const note = res.response.data.attributes;
        const notes = ratingNotes ? [note, ...ratingNotes] : [note];
        setRatingNotes(notes);
      }
    });
  };

  const deleteNote = (noteId) => {
    const newNotes = ratingNotes.filter((n) => n.id !== noteId);
    setRatingNotes(newNotes);

    httpDelete({
      call: `projects/${project.id}/ratings/${project.rating_id}/notes/${noteId}`,
    }).subscribe();
  };

  const handleMoreOptionClick = (value, note) => {
    switch (value) {
      case "remove":
        deleteNote(note.id);
        break;
      case "edit":
        setSelectedNote({
          id: note.id,
          note: note.description.replace(/(<br>)/g, "\n"),
        });
        break;
      default:
        break;
    }
  };

  const handleAddEdit = (value) => {
    if (value.id) {
      const newNotes = ratingNotes.map((n) => {
        if (n.id === value.id) {
          return {
            ...n,
            description: value.note,
          };
        }

        return n;
      });

      setRatingNotes(newNotes);

      const data = {
        note: {
          description: value.note,
        },
      };

      httpPut({
        call: `projects/${project.id}/ratings/${project.rating_id}/notes/${value.id}`,
        data,
      }).subscribe();

      return;
    }

    // add new
    addNewNote(value);
  };

  const getRatingNotes = useCallback(() => {
    if (!project.rating_id) {
      return;
    }

    httpGet({
      call: `projects/${project.id}/ratings/${project.rating_id}/notes`,
    }).subscribe((res) => {
      if (res.response?.data?.length) {
        const notes = res.response.data.map((n) => n.attributes);
        setRatingNotes(notes);
      }
    });
  }, [project]);

  useEffect(() => {
    if (!ratingNotes) {
      getRatingNotes();
    }
  }, [ratingNotes]);

  const showTotalRating = useCallback(() => {
    return (
      <>
        <span className={`${styles.value} text-muted mr-3`}>
          {project?.rating_template?.total_rating || ""}
        </span>
        <div
          className="d-flex justify-content-center  cursor-pointer "
          onClick={() => setExpanded((prev) => !prev)}
        >
          <Icon
            {...smSize}
            icon="icn-table-down-chevron"
            className={classNames(
              styles.icon,
              expanded ? styles.collapse : styles.collapseUp
            )}
          />
        </div>
      </>
    );
  }, [project?.rating_template?.total_rating, expanded]);

  return (
    <Pannel
      title="Project Rating"
      rightContent={showTotalRating()}
      withCollapse
      expanded={expanded}
      padding="0"
    >
      <div className={styles.taskInfoWrp}>
        <div className={styles.scoreWrp}>
          <div className={styles.title}>Your Score</div>
          <div className={styles.value}>
            {project?.rating_template?.user_total_rating > 0
              ? project?.rating_template?.user_total_rating?.toFixed(2)
              : "No rating"}
          </div>
        </div>
        <div className={styles.container}>
          {project?.rating_template?.rating_categories?.map((category) => {
            if (!category.parent_id) {
              return (
                <Accordion
                  className={styles.accordion}
                  key={category.rating_category_id}
                >
                  <AccordionSummary
                    expandIcon={
                      category?.sub_categories?.length > 0 ? (
                        <Icon
                          icon="icn-table-down-chevron"
                          {...mdSize}
                          className={styles.iconColor}
                        />
                      ) : (
                        <div className={styles.stubIcon} />
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={
                      category?.sub_categories?.length > 0
                        ? styles.accordionSummary
                        : styles.colll
                    }
                  >
                    <div
                      className={styles.sliderContainer}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <SliderComponent
                        rating={{
                          ...category,
                          id: category.rating_category_id,
                          name: category.name,
                          max: project?.rating_template?.max_scale,
                        }}
                        key={category.rating_category_id}
                        value={category.value}
                        onChange={(e) => {
                          handleOnChange(e, category);
                        }}
                      />
                    </div>
                  </AccordionSummary>
                  {category?.sub_categories?.length ? (
                    <AccordionDetails>
                      <div className={styles.childRatingContainer}>
                        {category.sub_categories?.map((subCategory) => (
                          <div
                            className={styles.sliderContainer}
                            key={subCategory.rating_category_id}
                          >
                            <SliderComponent
                              rating={{
                                ...subCategory,
                                id: subCategory.rating_category_id,
                                name: subCategory.name,
                                max: project?.rating_template?.max_scale,
                                customStyles: {
                                  trackStyle: {
                                    backgroundColor: "#7cc1e4",
                                  },
                                  handleStyle: {
                                    backgroundColor: "lightgray",
                                  },
                                },
                              }}
                              value={subCategory.value}
                              onChange={(e) => handleOnChange(e, subCategory)}
                            />
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>
                  ) : null}
                </Accordion>
              );
            }
            return null;
          })}
          {showFeedback && (
            <>
              <hr className={styles.divider} />
              <div className={styles.notesWrp}>
                <label className={styles.label}>Feedback</label>
                <div className={styles.notesCon}>
                  {loadingNotes ? (
                    <span className="d-flex flex-1 justify-content-center">
                      Loading feedback...
                    </span>
                  ) : ratingNotes?.length ? (
                    ratingNotes.map((note, idx) => (
                      <CompanyNote
                        key={note.id}
                        note={note}
                        handleMoreOptionClick={(val) =>
                          handleMoreOptionClick(val, note)
                        }
                        currentUser={session}
                        isLast={companyRatingNotes.length - 1 === idx}
                      />
                    ))
                  ) : (
                    <span className="d-flex flex-1 justify-content-center mb-3">
                      There's no feedback yet.
                    </span>
                  )}
                </div>
              </div>
            </>
          )}
          {showFeedback ? (
            <div className={styles.addNoteWrp}>
              <RatingAddNotesComponent
                selectedNote={selectedNote}
                onAddEdit={handleAddEdit}
              />
            </div>
          ) : !project?.rating_template?.user_total_rating ? null : (
            <div className="d-flex justify-content-center p-3">
              <Button
                btn={BtnType.REGULAR}
                onClick={() => setShowFeedback(true)}
              >
                Add Feedback
              </Button>
            </div>
          )}
        </div>
      </div>
    </Pannel>
  );
};

export default React.memo(ProjectRatings);

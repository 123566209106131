import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import { useSnackbar } from "notistack";

import { Loading } from "modules/primitives/index";
import ChartsView from "./chartsView";
import {
  queryParamStringToArray,
  convertFilterIndexToValues,
} from "../../../../common/helper";
import ChartCompaniesList from "./chartCompaniesList";
import {
  getAllCompaniesAttempt,
  getAllCompaniesSuccess,
  resetAllCompanies,
  setChartsAreReRendering,
  setChartsIntroActive,
  setChartsReqItIsInProgress,
  setChartsShouldReRender,
  setLocalChartsState,
} from "../companyMain.action";
import { filterCompaniesBySelectedTechIds } from "./chartsView/charts/bubbleChart/bubbleUtils";

const ChartsWrapper = ({ totalCompaniesInDB }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    appReducer: { chartsDownloadIsActive },
    companiesReducer: {
      companyMainReducer: {
        industries,
        topics,
        charts: {
          selectedChartType,
          allCompanies,
          localState,
          reqForAllCompaniesIsInProgress,
          chartsAreReRendering,
          chartShouldRerender,
        },
      },
    },
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);

  const { enqueueSnackbar } = useSnackbar();
  const [selectedTags, setSelectedTags] = useState(null);
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState(null);
  const [backUpCompanies, setBackUpCompanies] = useState(null);

  useEffect(() => {
    if (chartShouldRerender && allCompanies) {
      dispatch(resetAllCompanies());
      return;
    }

    if (allCompanies && !chartShouldRerender) {
      setBackUpCompanies(allCompanies);
    }

    if (!allCompanies && chartShouldRerender) {
      dispatch(
        getAllCompaniesSuccess({
          response: backUpCompanies,
        })
      );
      dispatch(setChartsShouldReRender(false));
      dispatch(
        setChartsAreReRendering({
          state: false,
        })
      );
    }
  }, [allCompanies, chartShouldRerender, backUpCompanies]);

  useEffect(() => {
    if (session && !session?.charts_intro_shown[selectedChartType]) {
      dispatch(setChartsIntroActive(true));
    }

    return () => null;
  }, [session, selectedChartType]);

  useEffect(() => {
    if (allCompanies) {
      dispatch(setChartsReqItIsInProgress(false));
    }
  }, [allCompanies]);

  useEffect(() => {
    if (!location?.state || reqForAllCompaniesIsInProgress) return;

    const { length } = Object.keys(location?.state);

    if (!allCompanies && length === 1 && industries?.length && topics?.length) {
      getAllCompanies();
    }
  }, [
    allCompanies,
    location,
    reqForAllCompaniesIsInProgress,
    industries,
    topics,
  ]);

  useEffect(() => {
    if (
      !location?.state ||
      Object.keys(location.state).length === 1 ||
      (reqForAllCompaniesIsInProgress && allCompanies) ||
      JSON.stringify(location.state) === localState
    )
      return;

    setSelectedTags(null);
    setLoading(true);
    dispatch(resetAllCompanies());
    dispatch(
      setLocalChartsState({
        state: JSON.stringify(location.state),
      })
    );
    getAllCompanies();
  }, [
    location,
    localState,
    dispatch,
    setLoading,
    reqForAllCompaniesIsInProgress,
    allCompanies,
  ]);

  useEffect(() => {
    if (!allCompanies || reqForAllCompaniesIsInProgress) {
      setLoading(true);
      return;
    }

    if (allCompanies?.length) {
      let allData = allCompanies;

      if (location.state?.topic) {
        const topicIdsFromQuery = queryParamStringToArray(
          location.state?.topic || ""
        );

        if (topicIdsFromQuery?.length) {
          allData = filterCompaniesBySelectedTechIds(
            allCompanies,
            { color: "technology" },
            topicIdsFromQuery
          );
        }
      }

      if (location.state?.industry) {
        const industryIdsFromQuery = queryParamStringToArray(
          location.state?.industry || ""
        );

        if (industryIdsFromQuery?.length) {
          allData = filterCompaniesBySelectedTechIds(
            allData,
            { color: "industry" },
            industryIdsFromQuery
          );
        }
      }

      if (location.state?.relationShipStatus) {
        const relStatusFromQuery = queryParamStringToArray(
          location.state?.relationShipStatus || ""
        );

        if (relStatusFromQuery?.length) {
          allData = allData.filter((company) =>
            relStatusFromQuery.includes(company.council_relationship_stage_id)
          );
        }
      }

      allData = allData.filter((c) => !c.is_product);

      setCompanies(allData);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [location.state, allCompanies, reqForAllCompaniesIsInProgress]);

  const getAllCompanies = useCallback(() => {
    if (chartShouldRerender) return;
    let data;
    const call = "companies/charts?ultrafast=true";

    if (location.state) {
      const { state } = location;

      const relationship_stages = queryParamStringToArray(
        state?.relationShipStatus || ""
      );
      const topic_ids = queryParamStringToArray(state?.topic || "");
      const industry_ids = queryParamStringToArray(state?.industry || "");
      const relationship_owners_ids = queryParamStringToArray(
        state?.owners || ""
      );
      const investmentStages = queryParamStringToArray(
        state?.investmentStages || ""
      );
      const locationStrAsArray = queryParamStringToArray(state?.location || "");
      const agreement_types_ids = queryParamStringToArray(
        state?.agreements || ""
      );
      const location_countries = queryParamStringToArray(
        state?.k_country || ""
      );
      const businessUnits = queryParamStringToArray(state?.businessUnits || "");
      const custom_tags_ids = queryParamStringToArray(
        state?.custom_tags_ids || ""
      );
      const idb_connection_options = queryParamStringToArray(
        state?.idbEcosystem || ""
      );
      const locationProps = {};

      if (locationStrAsArray.length) {
        const splitedValue = locationStrAsArray
          .map((str) => str.replace(/-/g, ","))[0]
          .split(",")[0]
          .split("#");
        const value = splitedValue[0];
        const type = splitedValue[1];

        if (splitedValue) {
          locationProps[`location_${type}`] = value;
        }
      }

      data = {
        query: state?.query || "",
        search: {
          query: state?.query || "",
          topic_ids,
          industry_ids,
          therapeutic_area_ids: [],
          relationship_stages,
          council_company_score_gte: state?.scoreFrom
            ? Number(state?.scoreFrom)
            : 0,
          council_company_score_lte: state?.scoreTo
            ? Number(state?.scoreTo)
            : 10,
          founded_gte: state?.foundedFrom ? state?.foundedFrom : null,
          founded_lte: state?.foundedTo ? state?.foundedTo : null,
          total_fundings_sum_gte: state?.fundingFrom
            ? Number(state?.fundingFrom.replace(/\D/g, ""))
            : 0,
          total_fundings_sum_lte:
            state?.fundingTo && state?.fundingTo !== "Any Amount"
              ? Number(state?.fundingTo.replace(/\D/g, ""))
              : null,
          relationship_owners_ids,
          lists_ids:
            state?.lists && state?.lists.length
              ? state?.lists.split(",").map((str) => str.split("_")[0])
              : "",
          num_employees_enums:
            state?.employees && state?.employees.length
              ? state?.employees.split(",")
              : null,
          revenue_ranges:
            state?.estimatedRevenue && state?.estimatedRevenue?.length
              ? state?.estimatedRevenue.split(",")
              : "",
          agreement_types_ids,
          ...locationProps,
          location_countries,
          idb_connection_options,
          council_relationship_stage_id: relationship_stages,
          business_unit_ids: businessUnits,
          theme_investment_stage_id: investmentStages,
          custom_tags_ids: convertFilterIndexToValues(
            custom_tags_ids,
            selectedCouncil?.council_custom_tag_groups
          ),
        },
      };
    } else {
      data = {
        query: "",
        search: {
          query: "",
        },
      };
    }

    dispatch(
      getAllCompaniesAttempt({
        call,
        data,
        enqueueSnackbar,
      })
    );
  }, [
    location,
    reqForAllCompaniesIsInProgress,
    chartShouldRerender,
    selectedCouncil,
  ]);

  const handleSelectTags = useCallback(
    (tags) => {
      setSelectedTags(tags.filter((t) => t.selected));
    },
    [selectedTags]
  );

  if (totalCompaniesInDB > 15000 && loading && !companies?.length) {
    return (
      <div className="border font-weight-bold mt-5 p-3 text-center">
        <h3>
          Please wait while we gather all the data to create your chart.
          <br />
          <br />
          You can speed this up by selecting search filters on the left to
          narrow the amount of data being charted.
        </h3>
      </div>
    );
  }

  return loading || !topics?.length || !industries?.length ? (
    <Loading customText="Preparing chart, please stand by ..." />
  ) : allCompanies?.length ? (
    <div
      id="downloadableSection"
      className={`mt-4 ${chartsDownloadIsActive ? "p-2" : ""}`}
    >
      <ChartsView
        selectedTags={selectedTags}
        companies={companies}
        handleSelectTags={handleSelectTags}
        dispatch={dispatch}
        location={location}
      />
      <ChartCompaniesList
        selectedCouncil={selectedCouncil}
        selectedTags={selectedTags}
        companies={companies}
      />
      <Dialog open={chartsAreReRendering}>
        <div className="p-3">
          <Loading customText="Preparing chart, please stand by ..." />
        </div>
      </Dialog>
    </div>
  ) : (
    <div className="mt-5 d-flex justify-content-center">
      We are sorry, but there aren't any results for selected filters, please
      select some other search criteria.
    </div>
  );
};

export default memo(ChartsWrapper);

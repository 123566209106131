import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCookie } from "common/helper";

import IdeaProfileHeader from "./ideaProfileHeader";
import IdeaProfileTabs from "./ideaProfileTabs";
import { getIdeaAttempt, resetActiveIdea } from "../idea.action";
import LoadingComponent from "../../primitives/loading/loading.component";

const IdeaProfileWrapper = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [canEdit, setCanEdit] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const {
    councilReducer: { selectedCouncil },
    ideaReducer: { selectedIdea },
    themesReducer: {
      themesOverviewReducer: { shouldUpdate },
    },
    projectsReducer: {
      projectReducer: { shouldUpdateIdeas },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (id) {
      dispatch(
        getIdeaAttempt({
          id,
        })
      );
    }

    return () => {
      dispatch(resetActiveIdea());
    };
  }, [id]);

  useEffect(() => {
    if (!selectedIdea) {
      dispatch(
        getIdeaAttempt({
          id,
        })
      );
    }
  }, [selectedIdea]);

  useEffect(() => {
    const council_role = getCookie("council_role_");
    setCanEdit(
      council_role === "ttp_administrator" ||
        council_role === "council_administrator"
    );
    return () => null;
  }, []);
  useEffect(() => {
    if (id && (shouldUpdate || shouldUpdateIdeas)) {
      dispatch(
        getIdeaAttempt({
          id,
        })
      );
    }
  }, [id, shouldUpdate, shouldUpdateIdeas]);

  return (
    <section id="ideaProfile">
      {selectedIdea ? (
        <>
          <IdeaProfileHeader canEdit={canEdit} selectedIndex={selectedIndex} />
          <IdeaProfileTabs
            canEdit={canEdit}
            selectedCouncil={selectedCouncil}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        </>
      ) : (
        <LoadingComponent customText="Preparing Idea Details..." />
      )}
    </section>
  );
};

export default React.memo(IdeaProfileWrapper);

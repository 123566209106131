import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import classNames from "classnames";
import {
  TextArea,
  MultiSelect,
  BtnType,
  Button,
  Label,
  TextBox,
} from "modules/primitives/index";
import { useModelPopup } from "../../../../../common/hooks/index";
import styles from "../../adminRating.module.scss";
import { ModalBody, ModalFooter } from "../../../../../application/modal";
import {
  ratingTemplateValidation,
  initTemplateModel,
} from "./adminRatingCategories.constants";
import {
  ratingsTemplateCategoriesPostAttempt,
  ratingsTemplateCategoriesPatchAttempt,
} from "../../adminRating.action";

const AdminRatingCategoriesModal = ({
  enqueueSnackbar,
  templateId,
  categoryId,
  mode,
  selectedData,
  councilId,
  row,
  categories,
  type,
  selectedTemplate,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const popup = useModelPopup();
  const [model, setModel] = useState(initTemplateModel);
  const [editModel, setEditModel] = useState({});
  const [currentValue, setCurrentValue] = useState(0);
  const [parentCategory, setParentCategory] = useState({});
  const [weightError, setError] = useState(false);
  const [left, setLeft] = useState(0);
  const [leftStack, setLeftStack] = useState([]);
  const [parentDropCategories, setParentDropCategories] = useState([]);
  const [uniqNameError, setUniqNameError] = useState(false);
  const [textByTemplate, setTextByTemplate] = useState("");

  const creteLeftStack = (parentChange) => {
    if (!parentChange && type === "parent") {
      const stack = categories.filter(
        (category) =>
          category.rating_category_id !== categoryId && !category.parent_id
      );
      setLeftStack(stack);
      return stack;
    }
    if (type === "child") {
      const stack = categories.filter(
        (category) =>
          category.rating_category_id !== categoryId &&
          category.parent_id === row.parent_id
      );
      setLeftStack(stack);
      return stack;
    }
    if (parentChange && typeof parentChange === "string") {
      const stack = categories.filter(
        (category) =>
          category.rating_category_id !== categoryId &&
          category.parent_id === parentChange
      );
      setLeftStack(stack);
      return stack;
    }
    return [];
  };
  const calculateLeft = (currentVal = 0, parentChange) => {
    if (!parentChange && mode === "edit" && type === "parent") {
      const weightValue = creteLeftStack().reduce(
        (sum, current) => sum + Number(current.weight),
        0
      );
      setLeft(100 - currentVal - weightValue);

      return 100 - currentVal - weightValue;
    }
    if (mode === "edit" && type === "child") {
      const selectedCategory = categories.find(
        (category) => category.rating_category_id === row.parent_id
      );
      setParentCategory(selectedCategory);
      const weightValue = creteLeftStack().reduce(
        (sum, current) => sum + Number(current.weight),
        0
      );
      setLeft(100 - currentVal - weightValue);
      return 100 - currentVal - weightValue;
    }
    if (mode === "add" && type === "parent" && !parentChange) {
      const weightValue = creteLeftStack().reduce(
        (sum, current) => sum + Number(current.weight),
        0
      );
      setLeft(100 - currentVal - weightValue);
      return 100 - currentVal - weightValue;
    }
    if (mode === "add" && parentChange) {
      const weightValue = creteLeftStack(parentChange).reduce(
        (sum, current) => sum + Number(current.weight),
        0
      );

      setLeft(100 - currentVal - weightValue);
      return 100 - currentVal - weightValue;
    }

    return 100;
  };
  const uniqRatingCategory = (field) => {
    let uniqNameFlag = true;
    if (parentCategory?.sub_categories?.length) {
      uniqNameFlag = !parentCategory.sub_categories.find(
        (subCategory) =>
          subCategory.name.toLowerCase().trim() ===
            field.toLowerCase().trim() &&
          parentCategory.name.toLowerCase().trim() ===
            field.toLowerCase().trim()
      );
      return uniqNameFlag;
    }
    uniqNameFlag = !selectedData?.find(
      (element) => element.name.toLowerCase() === field.toLowerCase().trim()
    );
    return uniqNameFlag;
  };
  const handleOnSubmit = (event) => {
    if (
      100 -
        currentValue -
        leftStack.reduce((sum, current) => sum + Number(current.weight), 0) >=
        0 &&
      uniqRatingCategory(event.name)
    ) {
      if (mode === "add") {
        dispatch(
          ratingsTemplateCategoriesPostAttempt({
            templateId,
            name: event.name,
            description: event.description,
            weight: currentValue,
            parent_id: parentCategory.rating_category_id,
            enqueueSnackbar,
          })
        );
      }
      if (mode === "edit") {
        dispatch(
          ratingsTemplateCategoriesPatchAttempt({
            templateId,
            categoryId,
            councilId,
            name: event.name,
            description: event.description,
            weight: currentValue,
            parent_id: parentCategory.rating_category_id,
            enqueueSnackbar,
          })
        );
      }
      popup.hide();
    } else if (!uniqRatingCategory(event.name)) {
      setUniqNameError(true);
    } else {
      setError(true);
    }
  };

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleParentChange = (parent) => {
    setParentCategory((prevState) => {
      calculateLeft(0, parent[0].rating_category_id);
      return (prevState = parent[0]);
    });
  };

  useEffect(() => {
    if (row?.weight && mode === "edit") {
      setCurrentValue(Number(row.weight));
    }
  }, [row]);

  useEffect(() => {
    if (row?.weight && categories[0]) {
      calculateLeft(Number(row.weight));
    }
  }, [row, categories]);

  useEffect(() => {
    if (categories.length) {
      const filteredParents = categories.filter(
        (category) => !category.parent_id
      );
      setParentDropCategories(filteredParents);
    }
  }, [categories]);

  useEffect(() => {
    if (templateId && mode === "add") {
      const templateType = selectedTemplate.template_type;
      const ideaTemplate = templateType === "idea" ? "Idea" : "Project";
      setTextByTemplate(ideaTemplate);

      calculateLeft();
    }
  }, [templateId, mode, categories]);

  useEffect(() => {
    if (mode === "edit") {
      const templateType = selectedTemplate.template_type;
      const ideaTemplate = templateType === "idea" ? "Idea" : "Project";
      setTextByTemplate(ideaTemplate);

      setEditModel({
        name: row?.name,
        description: row?.description,
        weight: Number(row.weight),
      });
    }
  }, [mode]);

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={mode === "edit" ? editModel : model}
        validationSchema={ratingTemplateValidation}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleOnSubmit(values);
        }}
      >
        {({ handleSubmit, handleReset, ...formikprops }) => (
          <form
            onSubmit={handleSubmit}
            onChange={(e) => {}}
            className={styles.form}
          >
            <ModalBody>
              <p className={styles.aditionalInfo}>
                Create a Ratings Category. You can add as many as you want to
                this template. Each Ratings Category will appear in the table.
                Weightings for each Category can be adjusted in the table, but
                must add up to 100%. Ratings Categories will appear in {textByTemplate}s
                in the Ratings window as sliders and contribute to the total
                {` ${textByTemplate}`} Score for each company.
              </p>
              <div className={styles.addRatingTempForm}>
                <div className={styles.row}>
                  <div className={classNames(styles.col12)}>
                    <Label>Ratings Category</Label>
                    <TextBox
                      type="text"
                      name="name"
                      className={styles.textBox}
                      formProps={formikprops}
                      placeholder="Enter category name"
                    />
                  </div>
                </div>
                <span className={styles.errorWeightText}>
                  {uniqNameError ? (
                    <span className={styles.weightErrMessage}>
                      This category name is already being used
                    </span>
                  ) : null}
                </span>
                <div className={styles.row}>
                  <div className={classNames(styles.col12)}>
                    <Label>Description</Label>
                    {/* <span className={styles.textFieldDesc}>Describe the scoring criteria for this category. Text will appear upon hover.</span> */}
                    <TextArea
                      type="text"
                      name="description"
                      className={styles.textBox}
                      formProps={formikprops}
                      placeholder="Describe the scoring criteria for this category. Text will appear upon hover."
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col12}>
                    <Label>Parent Category (Optional)</Label>
                    <MultiSelect
                      className={styles.dropWrapper}
                      labelField="name"
                      valueField="rating_category_id"
                      // searchBy="name"
                      // searchable
                      multi={false}
                      values={[parentCategory]}
                      onChange={handleParentChange}
                      options={parentDropCategories}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col12}>
                    <Label>Weight</Label>
                    <span className={styles.textFieldDesc}>
                      Enter a number between 1% and 100%
                    </span>
                    <div className={styles.numberWrapper}>
                      <div>
                        <TextBox
                          type="number"
                          step=".01"
                          min={0.1}
                          max={100}
                          value={String(currentValue)}
                          onChange={(e) => {
                            const weightValue = e.target.value;
                            setError(false);
                            setCurrentValue(Number(weightValue));
                            calculateLeft(Number(weightValue), parentCategory);
                          }}
                          className={styles.customNumberInput}
                        />
                        <span>%</span>
                      </div>
                      <div className={styles.numberPer}>
                        <span>
                          {(
                            100 -
                            currentValue -
                            leftStack.reduce(
                              (sum, current) => sum + Number(current.weight),
                              0
                            )
                          ).toFixed(2)}
                          % Left
                        </span>
                      </div>
                    </div>

                    <div className={styles.weightErrMessage}>
                      {weightError && (
                        <span className={styles.errorWeightText}>
                          Decrease by{" "}
                          {Math.abs(
                            100 -
                              currentValue -
                              leftStack.reduce(
                                (sum, current) => sum + Number(current.weight),
                                0
                              )
                          )}
                        </span>
                      )}
                      {/* <ErrorMessage name={'weight'} >
                            {
                              (err) => <span className={styles.errorWeightText}>{err}</span>
                            }
                      </ErrorMessage> */}
                    </div>
                    <div className={styles.headerWrapper}>
                      <span>Category Name</span> <span>Weight</span>
                    </div>
                    {leftStack.map((category) => (
                      <div className={styles.numberPer} key={category.name}>
                        <span className={styles.boldedSpan}>
                          {category.name}:{" "}
                        </span>{" "}
                        {category.weight}%
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button type="submit" btn={BtnType.REGULAR}>
                {mode === "edit" ? "Submit" : "Add"}
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AdminRatingCategoriesModal;

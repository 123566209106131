import React from "react";

import styles from "./productImages.module.scss";
import { BtnType, Button } from "modules/primitives";
import EditProductImages from "modules/companies/product/components/overview/productImages/editProductImages";
import useModelPopup from "common/hooks/modelPopup.hook";
import ProductCarouselImages from "modules/companies/product/components/overview/productImages/productCarouselImages";

const ProductImages = ({ product }) => {
  const popup = useModelPopup();

  const addNewImage = () => {
    popup.show({
      show: true,
      title: "Edit Product Images",
      component: <EditProductImages product={product} />,
    });
  };

  const handleImageShow = (index) => {
    popup.show({
      show: true,
      title: `Product Images (${product.images.length})`,
      width: '70%',
      height: '70vh',
      component: <ProductCarouselImages product={product} activeIndex={index} />,
    });
  }

  return (
    <div className={styles.detailsPanel}>
      {
        product.images?.length ? (
          <div className={styles.images}>
            {
              product.images.map((image, index) => (
                <div
                  className={styles.imageWrapper}
                  key={`${image}_${index}`}
                  onClick={() => handleImageShow(index)}
                  style={{ backgroundImage: `url(${image})` }}
                  title={product.name}
                ></div>
              ))
            }
          </div>
        ) : (
          <div className="d-flex align-items-center flex-column">
            <span>No images added yet.</span>
            <Button
              className="mt-3"
              onClick={addNewImage}
              btn={BtnType.REGULAR}
            >
              ADD IMAGE
            </Button>
          </div>
        )
      }
    </div>
  );
};

export default ProductImages;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import actionCable from "actioncable";
// import Switch from "react-switch";
import { Loading, Button, BtnType } from "modules/primitives/index";
// import { syncAccountsToggle } from "modules/admin/adminIntegrations/adminIntegrations.actions";
import { sfSearchInCouncil } from "modules/companies/companyMain/companyMain.action";
import { httpPost, httpGet } from "../../../../common/httpCall";
import styles from "./exportData.module.scss";
import { useModelPopup } from "../../../../common/hooks";
import RecordsAddedModal from "../recordsAddedModal";

const customStyles = {
  pagination: {
    style: {
      justifyContent: "center",
      select: {
        paddingRight: "20px",
      },
    },
  },
  headCells: {
    style: {
      justifyContent: "center",
      paddingLeft: "50px",
      paddingRight: "50px",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
      width: "5rem",
    },
  },
};

const ExportData = () => {
  const [selectedSfFields, setSelectedSfFields] = useState([]);
  const [selectedTractionFields, setSelectedTractionFields] = useState([]);
  const [selectedSfCustomFields, setSelectedSfCustomFields] = useState([]);
  const [selectedTractionCustomFields, setSelectedTractionCustomFields] =
    useState([]);
  const [opportunityStagePayload, setOpportunityStagePayload] = useState([]);
  const [relationshipStatusPayload, setRelationshipStatusPayload] = useState(
    []
  );
  const [payload, setPayload] = useState([]);
  const [attribute, setAttribute] = useState("");
  const [columnFields, setColumnFields] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterOption, setFilterOption] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [recordCount, setRecordCount] = useState(null);
  const [combinedSfFields, setCombinedSfFields] = useState([]);
  const [combinedTractionFields, setCombinedTractionFields] = useState([]);
  const [syncStatus, setSyncSatus] = useState(false);

  const location = useLocation();
  const popup = useModelPopup();
  const history = useHistory();
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    // adminIntegrationsReducer: { sync_toggle },
    companiesReducer: {
      companyMainReducer: { totalRecords, searchResults },
    },
  } = useSelector((state) => state);

  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalContactRecords, setTotalContactRecords] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setAttribute(location.state.currentAttribute);
    setSelectedSfFields(location.state.mappedSalesforceFields);
    setSelectedTractionFields(location.state.mappedTractionFields);
    setSelectedFilter(location.state.selectedFilter);
    setFilterOption(location.state.selectedFilterOption);
    setSelectedSfCustomFields(location.state.mappedSalesforceCustomFields);
    setSelectedTractionCustomFields(location.state.mappedTractionCustomFields);
    setOpportunityStagePayload(location.state.opportunityStagePayload);
    setRelationshipStatusPayload(location.state.relationshipStatusPayload);
    setSyncSatus(location.state.syncToggle);
  }, []);

  useEffect(() => {
    if (attribute === "account") {
      setRecordCount(totalRecords?.count);
    } else {
      setRecordCount(totalContactRecords?.count);
    }
  }, [totalRecords, totalContactRecords, attribute]);

  useEffect(() => {
    setTableData(searchResults);
    setLoader(false);
  }, [searchResults]);

  useEffect(() => {
    setCombinedSfFields([...selectedSfFields, ...selectedSfCustomFields]);
    setCombinedTractionFields([
      ...selectedTractionFields,
      ...selectedTractionCustomFields,
    ]);
  }, [
    selectedSfCustomFields,
    selectedTractionCustomFields,
    selectedSfFields,
    selectedTractionFields,
  ]);

  const handleFilterField = (opportunityAdded) => {
    if (filterOption === "Relationship Status" || filterOption === null) {
      opportunityAdded.push({
        text: "council_relationship_stage_name",
        value: "string",
      });
    } else if (filterOption === "Technology") {
      opportunityAdded.push({ text: "technology", value: "string" });
    } else if (filterOption === "Industry") {
      opportunityAdded.push({ text: "industry", value: "string" });
    } else return opportunityAdded;
  };

  useEffect(() => {
    if (attribute === "contact") {
      setLoader(true);
      httpGet({
        call: `companies/sf_contacts?items=${pageSize || 20}&page=${
          page || 1
        }&current_mode=${attribute}`,
      })
        .pipe()
        .subscribe((res) => {
          setTableData(res.response?.contacts);
          setTotalContactRecords(res.response?.total_count);
          setLoader(false);
        });
    }
  }, [attribute, page, pageSize]);

  useEffect(() => {
    if (attribute === "account") {
      dispatch(
        sfSearchInCouncil({
          mode: "companies",
          page,
          items: pageSize,
          topic_ids:
            filterOption === "Technology"
              ? selectedFilter?.map((f) => f.value)
              : [],
          industry_ids:
            filterOption === "Industry"
              ? selectedFilter?.map((f) => f.value)
              : [],
          council_relationship_stage_id:
            filterOption === "Relationship Status"
              ? selectedFilter?.map((f) => f.value)
              : [],
        })
      );
      setLoader(true);
    }
  }, [page, pageSize, attribute]);

  const setLocalStorage = (count, time, size) => {
    localStorage.setItem(time, new Date().toUTCString());
    localStorage.setItem(size, JSON.stringify(count));
  };

  const recordsAdded = (count) => {
    if (count?.attribute === "account_export") {
      setLocalStorage(
        count.total_count,
        "exportAccountTime",
        "exportAccountSize"
      );
    } else if (count?.attribute === "contact_export") {
      setLocalStorage(
        count.total_count,
        "exportContactTime",
        "exportContactSize"
      );
    }
  };

  useEffect(() => {
    const user_id = session.id;
    const cable = actionCable.createConsumer(
      "wss://api.traction.network/cable"
    );
    cable.subscriptions.create(
      {
        channel: "NotificationChannel",
        user_id,
      },
      {
        connected: () => {
          console.log("**Connected**");
        },
        received: (response_data) => {
          setData(response_data);
          recordsAdded(response_data);
          popup.show({
            title: "Salesforce Alert!",
            show: true,
            height: "300",
            width: "540",
            component: (
              <RecordsAddedModal
                description="Data has been saved successfully!"
                timeout="1000"
              />
            ),
          });
        },
        rejected: () => {
          console.log("Action cable connection is rejected");
        },
      }
    );
  }, [data]);

  useEffect(() => {
    let opportunityAdded = [];
    opportunityAdded = [...combinedTractionFields];
    handleFilterField(opportunityAdded);
    const columnArray = opportunityAdded.map((key) => ({
      name: <h5 className={styles.tableHeader}>{key.text}</h5>,
      selector: (row) => {
        if (key.text === "founded") {
          return row.founded?.slice(0, 4);
        }
        if (key.text === "founded_at") {
          return row.founded_at?.slice(0, 4);
        }
        if (key.text === "technology") {
          const companyTopics = row.check_topics.map((i) => i.name);
          const selectedTopics = companyTopics.filter((item) =>
            selectedFilter.map((i) => i.text).includes(item)
          );
          return selectedTopics.join(" ");
        }
        if (key.text === "industry") {
          const companyIndustries = row.resource_industries.map(
            (i) => i.industry.name
          );
          const selectedIndustries = companyIndustries.filter((item) =>
            selectedFilter.map((i) => i.text).includes(item)
          );
          return selectedIndustries.join(" ");
        }
        if (
          row?.custom_fields
            ?.map((obj) => obj.custom_field_template_id)
            .includes(key.value)
        ) {
          const selectedField = row.custom_fields?.filter(
            (ele) => ele.custom_field_template_id === key.value
          )[0];
          if (
            selectedField.field_type === "dropdown" ||
            selectedField.field_type === "checklist"
          ) {
            return selectedField.optionSelected || "NA";
          }
          return selectedField.field_value || "NA";
        }

        return row[`${key.text}`];
      },
    }));
    setColumnFields(columnArray);
  }, [combinedSfFields, attribute]);

  const handleRelationshipStatusPayload = () => {
    const selectedRelationshipMap = [];
    payload.map((record) => {
      const temp = relationshipStatusPayload?.some((value, index) => {
        if (value.includes(record?.council_relationship_stage_name)) {
          selectedRelationshipMap.push(opportunityStagePayload[index].text);
        }
        return value.includes(record?.council_relationship_stage_name);
      });
      if (!temp) selectedRelationshipMap.push(null);
      return true;
    });
    return selectedRelationshipMap;
  };

  const handleSaveRecords = (selected_attribute) => {
    if (selected_attribute === "account") {
      const relationshipMappingPayload = handleRelationshipStatusPayload();
      const payloadArray = payload.map((record) => {
        let payloadObject = {};
        const payloadItem = [];
        selectedSfFields.forEach((field, j) => {
          if (field.text === "YearStarted") {
            payloadObject = {
              ...payloadObject,
              [field.text]: record[selectedTractionFields[j].text]?.slice(0, 4),
            };
            return payloadObject;
          }
          payloadObject = {
            ...payloadObject,
            [field.text]: record[selectedTractionFields[j].text],
          };
          return payloadObject;
        });
        payloadItem.push(payloadObject);
        let customFieldObject = {};
        selectedSfCustomFields.map((val, index) => {
          customFieldObject = {
            ...customFieldObject,
            [val.text]:
              record.custom_fields?.filter(
                (ele) =>
                  ele.field_name === selectedTractionCustomFields[index].text
              )[0]?.field_value || "",
          };
          return true;
        });
        payloadItem.push(customFieldObject);
        return payloadItem;
      });
      httpPost({
        call: "sf_integrations/save_export_records",
        data: {
          current_council: selectedCouncil.id,
          companyPayload: payloadArray,
          opportunityMapPayload: relationshipMappingPayload,
        },
      })
        .pipe()
        .subscribe((res) => {
          if (res.status === 200) {
            popup.show({
              title: "Salesforce Alert!",
              show: true,
              height: "300",
              width: "540",
              component: (
                <RecordsAddedModal
                  description="Saving in Progress!"
                  timeout="1000"
                />
              ),
            });
          }
        });
    } else if (attribute === "contact") {
      const payloadArray = payload.map((record) => {
        let payloadObject = {};
        selectedSfFields.forEach((field, j) => {
          payloadObject = {
            ...payloadObject,
            [field.text]: record[selectedTractionFields[j].text],
          };
          return true;
        });
        return payloadObject;
      });
      httpPost({
        call: "sf_integrations/save_export_records",
        data: {
          current_council: selectedCouncil.id,
          ttpContactsPayload: payloadArray,
        },
      })
        .pipe()
        .subscribe((res) => {
          if (res.status === 200) {
            popup.show({
              title: "Salesforce Alert!",
              show: true,
              height: "300",
              width: "540",
              component: (
                <RecordsAddedModal
                  description="Saving in Progress!"
                  timeout="1000"
                />
              ),
            });
          }
        });
    }
  };

  const handleBack = () => {
    if (attribute === "account") {
      history.push({
        pathname: "/salesforce/configureAccounts",
        state: { checked: false },
      });
    } else {
      history.push({
        pathname: "/salesforce/configureContacts",
        state: { checked: false },
      });
    }
  };

  const handleClose = () => {
    history.push({
      pathname: "/salesforce/configureData",
    });
  };

  useEffect(() => {
    setTableData(
      searchResults?.filter((c) =>
        c?.name.toLowerCase().match(search?.toLowerCase())
      )
    );
  }, [search]);

  const handleRecordChange = ({ selectedRows }) => {
    setPayload(selectedRows);
  };

  // const handleSwitchChange = () => {
  //   dispatch(syncAccountsToggle(!sync_toggle));
  // };

  return (
    <>
      <div>
        <div className={styles.header}>
          <h2>Salesforce Data</h2>
        </div>
        {(searchResults?.length > 0 || tableData.length > 0) && (
          <div className={styles.header}>
            <h2>
              Number of Records {syncStatus ? "Syncing" : "Exported"} :
              {searchResults?.length || tableData?.length}
            </h2>
          </div>
        )}
        <div className={styles.topHeading}>
          <Button
            className={styles.backBtn}
            btn={BtnType.FRAME_LESS}
            onClick={() => handleBack()}
          >
            Back
          </Button>
          {payload.length > 0 && !syncStatus && (
            <Button
              className={styles.backBtn}
              btn={BtnType.REGULAR}
              onClick={() => handleSaveRecords(attribute)}
            >
              Export
            </Button>
          )}

          {syncStatus && (
            <h4>
              <b>Sync is on</b>
            </h4>
          )}

          <Button
            className={styles.closeBtn}
            btn={BtnType.FRAME_LESS}
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </div>
      </div>
      {loader ? (
        <div className={styles.loader}>
          <Loading />
        </div>
      ) : (
        <DataTable
          pagination
          paginationServer
          paginationPerPage={pageSize}
          paginationRowsPerPageOptions={[50, 100]}
          paginationTotalRows={recordCount}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={(newPageSize) => {
            setPageSize(newPageSize);
          }}
          selectableRows
          columns={columnFields}
          data={tableData}
          customStyles={customStyles}
          fixedHeader
          fixedHeaderScrollHeight="450px"
          selectableRowSelected={(row) => row}
          selectableRowsHighlight
          highlightOnHover
          onSelectedRowsChange={(e) => handleRecordChange(e)}
          actions={<div />}
          onChangePage={(updated_page) => {
            setPage(updated_page);
            setCurrentPage(updated_page);
          }}
          subHeader
          subHeaderComponent={
            <input
              type="text"
              placeholder="Search record"
              className="w-25 form-control"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          }
        />
      )}
    </>
  );
};

export default React.memo(ExportData);

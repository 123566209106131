import React from "react";
import { Link } from "react-router-dom";
import styles from "./privacyPolicy.module.scss";

const PrivacyPolicyComponent = () => (
  <div className={styles.container}>
    <div className="row">
      <h2 className={styles.header}>Privacy Policy</h2>
    </div>
    <div className="row">
      <div>
        This website{" "}
        <span className={styles.link}>
          <a href={window.location.origin}>
            <b>{window.location.origin}</b>
          </a>
        </span>
         (the “Site”) is brought to you by Traction Technology Partners, LLC
        (“TTP,” “we,” “us,” or “our”) to power our member network (the
        “Network”). This privacy policy provides information about our policies
        and procedures regarding the collection, use and disclosure of personal
        information received through the Site from Registered Members (as
        defined below) (the “Privacy Policy”). This
        <span className={styles.link}>
          <Link to="/privacy_policy">Privacy Policy</Link>
        </span>
         is incorporated into, and is part of, our Terms of Use, which govern
        your access to and use of the Site. Unless otherwise defined herein,
        capitalized terms shall have the meaning assigned to such terms in the
        <span className={styles.link}>
          <Link to="/terms">Terms Of Use</Link>
        </span>
        . If you have comments, suggestions, questions or concerns about our
        Privacy Policy, please contact us at
        <span className={styles.link}>
          <a href="mailto:legal@tractiontechpartners.com">
            legal@tractiontechpartners.com
          </a>
        </span>
        .
      </div>
      <div className={styles.linkWrp}>
        <ul>
          <li>
            <a href="#sec1" className={styles.link}>
              What information does TTP collect from me?
            </a>
          </li>
          <li>
            <a href="#sec2" className={styles.link}>
              How is information collected by TTP used and shared?
            </a>
          </li>
          <li>
            <a href="#sec3" className={styles.link}>
              How can I access and change my account information?
            </a>
          </li>
          <li>
            <a href="#sec4" className={styles.link}>
              What else should I know about Anonymous Information collected by
              TTP?
            </a>
          </li>
          <li>
            <a href="#sec5" className={styles.link}>
              Will TTP send me text messages?
            </a>
          </li>
          <li>
            <a href="#sec6" className={styles.link}>
              How does TTP protect my information?
            </a>
          </li>
          <li>
            <a href="#sec7" className={styles.link}>
              Does TTP collect payment card information?
            </a>
          </li>
          <li>
            <a href="#sec8" className={styles.link}>
              Does TTP use other third party service providers?
            </a>
          </li>
          <li>
            <a href="#sec9" className={styles.link}>
              Can I choose to stop receiving communications from TTP?
            </a>
          </li>
          <li>
            <a href="#sec10" className={styles.link}>
              Can children use the TTP website?
            </a>
          </li>
          <li>
            <a href="#sec11" className={styles.link}>
              What if I live in California?
            </a>
          </li>
          <li>
            <a href="#sec12" className={styles.link}>
              What if I live outside the United States?
            </a>
          </li>
          <li>
            <a href="#sec13" className={styles.link}>
              What if I live in Canada?
            </a>
          </li>
          <li>
            <a href="#sec14" className={styles.link}>
              What if I follow a link to a third party website?
            </a>
          </li>
          <li>
            <a href="#sec15" className={styles.link}>
              Will TTP change this Privacy Policy?
            </a>
          </li>
          <li>
            <a href="#sec16" className={styles.link}>
              How do I contact TTP?
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.questionWrp} id="sec1">
        <div className={styles.questionTitle}>
          {" "}
          WHAT INFORMATION DOES TTP COLLECT FROM ME?
        </div>
        <span className={styles.boldCategory}>A.</span> Information You Provide
        to Us. When you sign up as a registered member of the Site (“Registered
        Member”), you must create a user account and provide certain personal
        information during the registration process and/or in connection with
        your use of the Site as described below:
        <ul>
          <li>
            {" "}
            For Council Members and the Velocity Network. Paid subscribers to
            one of the membership organizations (collectively referred to herein
            as “Councils”) which we develop, maintain and support with our third
            party partners, and members of our Velocity Network, are permitted
            access to the Site. To set up your account profile, we ask you to
            provide your first and last name, mobile phone number and mobile
            provider, location, job title or role, biographical information, and
            details about your areas of interest and upcoming projects. We also
            give you the opportunity to upload a profile picture and connect to
            certain social media outlets, such as LinkedIn, in order to import
            information from your social media account.
          </li>
          <li>
            For Startups. We invite startups to join our Velocity Network and
            allow these startups to use the Site to connect with other
            Registered Members.
          </li>
          <li>
            For Everyone. Please be advised that if you contact us by email, we
            may keep a record of your contact information and correspondence,
            and may use your email address, and any information that you provide
            to us in your message, in order to respond to you. Please also note
            that TTP reserves the right to delete information for Registered
            Members with free account status at any time.{" "}
          </li>
        </ul>
        <span className={styles.boldCategory}>B.</span> Information We Collect
        About Your Interaction With The Site. We may collect additional
        information about your interaction with the Site without identifying you
        as an individual (“Anonymous Information”), by using cookies or other
        technologies. For example, we may receive certain standard information
        that your browser sends to every website you visit, such as your IP
        address, browser and operating system type, and information about the
        device you are using (such as whether it is a mobile device or a desktop
        computer). We may use Anonymous Information for any purpose in our
        discretion. For clarity, Anonymous Information means information that is
        not associated with or linked to your personal information and does not
        permit the identification of individual persons. Please refer to the
        “WHAT ELSE SHOULD I KNOW ABOUT ANONYMOUS INFORMATION COLLECTED BY TTP?”
        section below for more information about Anonymous Information collected
        by our Analytics Partners.
      </div>
      <div className={styles.questionWrp} id="sec2">
        <div className={styles.questionTitle}>
          HOW IS INFORMATION COLLECTED BY TTP USED AND SHARED?
        </div>
        <span className={styles.boldCategory}>A.</span> How We May Use Your
        Information. We may use the information we collect from and about you
        through the Site for any of the following purposes: (i) to operate the
        Site and to provide products and services to you, (ii) to evaluate your
        company for membership or participation in our Velocity Network or
        various startup challenges, (iii) to respond to your requests and
        inquiries, (iv) to contact you and to communicate with you in order to
        respond to your requests for additional information, (v) to send you
        newsletters, industry alerts, and updates about our organization, (vi)
        to notify you about important changes to the Site or our products and
        services, (vii) to review Site usage and operations and to improve the
        content and functionality of the Site, (viii) to protect the security or
        integrity of the Site or our organization, or (ix) to tailor the Site to
        your needs and the needs of other visitors.
        <br />
        <span className={styles.boldCategory}>B.</span> How We May Share Your
        Information. TTP does not sell, lease or rent the personal information
        you share with us on or through the Site with third parties. However, we
        may share your personal information with one of our third party partner
        organizations in connection with your membership in one of our Councils,
        to facilitate that Council’s activities and functions, and generally to
        support our partner’s business activities. For clarity, your membership
        in any of our Councils is voluntary and we will only share your personal
        information with our partner associated with the Council you have chosen
        to join. Any such partner who receives your personal information from us
        may use that information for any purposes within their sole discretion.
        TTP may also disclose your personal information to third parties (i) as
        required by law or regulation or as we believe in good faith that
        disclosure of such personal information is reasonably necessary to: (1)
        satisfy any applicable law or regulation, (2) to respond to claims
        and/or to comply with a judicial proceeding, court order or legal
        process served on us, (3) to protect and defend our rights or property
        and/or the rights or property of third parties, as permitted by law, or
        (4) to detect, prevent or otherwise address fraud, security or technical
        issues; and/or (ii) with our service providers that provide certain
        services to maintain and operate the Site or to help us deliver products
        and services to you.
        <br /> <span className={styles.boldCategory}>C.</span> Direct Marketing.
        We may send out marketing communications (such as a newsletter) to
        subscribers of our Traction Report, to Council members, and to
        participants in our Velocity Network, in order to provide updates about
        our products and services, to discuss industry happenings and provide
        our commentary, and to announce upcoming events and opportunities. We
        will only use your personal data for direct marketing if you expressly
        give us consent to do so. You can always choose to stop receiving
        newsletters or other communications from us by clicking the
        “unsubscribe” link at the bottom of a marketing message. Please
        understand that if you choose not to receive promotional correspondence
        from us, we may still contact you in connection with your relationship,
        activities, transactions and/or communications with us. <br />
        <span className={styles.boldCategory}>D.</span> Direct Messaging. Our
        Site allows Registered Members to send direct messages to other
        Registered Members on the Site. We ask you to review our Terms of
        Use for applicable rules, restrictions and obligations applicable to
        direct messaging through the Site. DIRECT MESSAGES YOU SEND WHILE USING
        THE SITE ARE PRIVATE AS BETWEEN YOU AND THE RECIPIENT AND WILL NOT BE
        SEEN BY OTHER REGISTERED MEMBERS.
        <br /> <span className={styles.boldCategory}>E.</span> Discussions. Our
        Site also allows Registered Members to post public questions and engage
        in open discussions with other Registered Members on the Site. PLEASE BE
        AWARE THAT ANY IDEAS, OPINIONS, RECOMMENDATIONS, DATA AND INFORMATION
        YOU CHOOSE TO SHARE ON OR THROUGH OUR SITE WHILE USING OUR PUBLIC MODE
        SETTING (REFER TO THE SECTION “PRIVACY CONTROLS” BELOW FOR MORE DETAILS
        ABOUT THIS SETTING) MAY BE SEEN BY US OR BY OTHER REGISTERED MEMBERS AND
        WILL BE ASSOCIATED WITH YOUR PROFILE NAME.
        <br /> <span className={styles.boldCategory}>F.</span> Privacy Controls.
        We encourage you to utilize the privacy controls available in your user
        account to establish how others can view and interact with you inside of
        the Site. In your user account, go to “Settings” and select the
        “Privacy” option and decide if you want to set your account to “Public
        Mode” or “Privacy Mode”:
        <ul>
          <li>
            Public Mode allows any Registered Members within your Council to see
            your profile, who you follow, and all your other activities inside
            of the Site. For example, this means that your profile name and
            picture will be associated with any comments you post to a
            discussion thread or messages you send using our direct messaging
            tool.
          </li>{" "}
          <li>
            Private Mode limits what other Registered Members see about you,
            permitting them to view your profile information but not any of the
            activities that you perform inside of the Site. For example, this
            means that any comments you post to a discussion thread, or messages
            you send using our direct messaging tool, will appear from an
            “Anonymous Member”.
          </li>
        </ul>{" "}
        <br /> <span className={styles.boldCategory}>G.</span> Successor
        Entities. As with any business, it is possible that in the future we
        could merge with or be acquired by another company. If this occurs, the
        successor company would have access to the Information, but would
        continue to be bound by this Privacy Policy unless and until it is
        amended.
      </div>{" "}
      <div className={styles.questionWrp} id="sec3">
        <div className={styles.questionTitle}>
          HOW CAN I ACCESS AND CHANGE MY ACCOUNT INFORMATION?
        </div>{" "}
        If you are a Registered Member, we provide an easy way via your user
        account profile for you to access, correct, update or remove personal
        information that has previously been provided to us. You can review your
        personal information and make any necessary changes at any time by
        logging in to your user account and clicking the “Settings” option in
        your personalized dashboard (or alternatively, click the “view profile”
        option and then “Edit Profile” button). If you are unable to edit your
        personal information by logging into your user account, please contact
        us for assistance at [business@tractiontechpartners.com].
      </div>{" "}
      <div className={styles.questionWrp} id="sec4">
        <div className={styles.questionTitle}>
          WHAT ELSE SHOULD I KNOW ABOUT ANONYMOUS INFORMATION COLLECTED BY TTP?
        </div>{" "}
        <span className={styles.boldCategory}>A.</span> Analytics Partners. TTP
        has engaged certain analytics partners (“Analytics Partners”) to collect
        Anonymous Information about how our Site is used. Our Analytics Partners
        may use cookies, web beacons, or other automated technologies to collect
        information about how many times you visit our Site, what parts of our
        Site you access, and the functionality you are using. To help you
        understand these technologies better:
        <br />
        <ul>
          <li>
            “Cookies” are electronic files many websites place on a visitor’s
            computer to store information that makes each visit to a respective
            website more efficient. To remove cookies, you may configure your
            browser to clear all cookies, or configure it to reject cookies
            whenever you visit a new website. Please consult your browser’s user
            guide for specific instructions on removing cookies.
          </li>
          <li>
            “Beacons” are small graphic files with a unique identifier that are
            used to track a web or mobile site user’s online movements. Unlike
            cookies, beacons are embedded on websites, not downloaded on your
            computer or device.Our Analytics Partners may use these or other
            techniques to collect Anonymous Information about your visit to our
            Site. In order to make sure you understand how our Analytics
            Partners handle your information and how they interact with our
            Site, we have shared hyperlinks below to their policies. We
            encourage you to review these terms as TTP has no ability to control
            or monitor our Analytics Partners’ data collection or data use
            practices.
          </li>
          <li>
            Google Analytics — TTP partners with Google Analytics to provide
            insight into our Site. Google Analytics’ terms are available here:
            <span className={styles.link}>
              <a href="http://www.google.com/analytics/terms/us.html.">
                http://www.google.com/analytics/terms/us.html.
              </a>
            </span>
          </li>
        </ul>
        <span className={styles.boldCategory}>B.</span> Tracking. Certain
        browsers have a “Do Not Track” feature that allows you to tell websites
        or applications that you do not want to have your online activities
        tracked. Our Site does not respond to browser “Do Not Track” signals;
        however, TTP does not automatically collect information about other
        websites you have recently visited before or after visiting the Site.
        Please note that this Privacy Policy covers the use of tracking
        technologies by TTP and does not cover the use of cookies or other
        technologies by any advertisers. Some of our third-party service
        providers may use cookies on our Site but we have no access to or
        control over these cookies.
        <br /> <span className={styles.boldCategory}>C.</span>
        Third Party Advertising. We may present you with options on our Site or
        via email to receive certain information and/or marketing offers
        directly from third parties or to have us send certain information to
        third parties or give them access to it. If you choose to do so, your
        Personal Information and other information may be disclosed to such
        third parties and all information you disclose will be subject to the
        third-party privacy policies and practices of such third parties. In
        addition, third parties may store, collect or otherwise have access to
        your information when you interact with their tracking technologies,
        content, tools, apps or ads on our Site or link to them from our Site.
        We are not responsible for the privacy policies and practices of such
        third parties and, therefore, you should review such third-party privacy
        policies and practices of such third parties prior to requesting
        information from or otherwise interacting with them.
        <br />
        BY AGREEING TO THIS PRIVACY POLICY AND USING OUR SITE, YOU ARE
        CONSENTING TO THE USE OF COOKIES, BEACONS AND OTHER TRACKING
        TECHNOLOGIES AS SET FORTH IN THIS PRIVACY POLICY.
      </div>{" "}
      <div className={styles.questionWrp} id="sec5">
        <div className={styles.questionTitle}>
          WILL TTP SEND ME TEXT MESSAGES?
        </div>
        At this time we will not send you notifications, alerts or other
        communications via text message.
      </div>{" "}
      <div className={styles.questionWrp} id="sec6">
        <div className={styles.questionTitle}>
          HOW DOES TTP PROTECT MY INFORMATION?
        </div>
        TTP uses reasonable administrative, physical and technical safeguards
        designed to secure the personal information you share with us.
        Regardless of the precautions taken by us or by you, no data transmitted
        over the Internet or any other public network can be guaranteed to be
        100% secure. We cannot ensure or warrant the security of any information
        you transmit to us and you provide all personal information to us at
        your own risk.
      </div>
      <div className={styles.questionWrp} id="sec7">
        <div className={styles.questionTitle}>
          DOES TTP COLLECT PAYMENT CARD INFORMATION?
        </div>{" "}
        TTP does not collect credit and debit card information on or through the
        Site and kindly asks you not to share this information with us when
        submitting requests for additional information.
      </div>{" "}
      <div className={styles.questionWrp} id="sec8">
        <div className={styles.questionTitle}>
          DOES TTP USE OTHER THIRD PARTY SERVICE PROVIDERS?
        </div>{" "}
        In order to send you our newsletter and certain other communications, we
        use third party service providers and APIs. For example, these
        third-party services help us build contact lists, prepare communications
        for distribution, and host our website platform. Your personal
        information may be processed by these third-party services in order for
        us to utilize their tools.
      </div>
      <div className={styles.questionWrp} id="sec9">
        <div className={styles.questionTitle}>
          CAN I CHOOSE TO STOP RECEIVING COMMUNICATIONS FROM TTP?
        </div>{" "}
        You can always choose to stop receiving newsletters or other electronic
        communications from us by clicking the “unsubscribe” link at the bottom
        of a TTP message.
      </div>
      <div className={styles.questionWrp} id="sec10">
        <div className={styles.questionTitle}>
          CAN CHILDREN USE THE TTP WEBSITE?
        </div>{" "}
        We do not intentionally gather information from individuals who are
        under the age of 13. Upon notification that a child under 13 has
        provided us with personal information, we will delete the child’s
        personal information from our records.
      </div>
      <div className={styles.questionWrp} id="sec11">
        {" "}
        <div className={styles.questionTitle}>
          WHAT IF I LIVE IN CALIFORNIA?{" "}
        </div>
        California Civil Code Section 1798.83 permits Site visitors who are
        California residents and who have provided us with "personal
        information" (as that term is defined in Section 1798.83) to request
        certain information about the disclosure of that information to third
        parties for their direct marketing purposes. If you are a California
        resident, you may contact us by writing to: Traction Technology
        Partners, 603 Greenwich Street, Suite 101, New York, NY 10014.
      </div>{" "}
      <div className={styles.questionWrp} id="sec12">
        <div className={styles.questionTitle}>
          WHAT IF I LIVE OUTSIDE THE UNITED STATES?
        </div>
        You should be aware that the United States and other countries have not
        harmonized their privacy regulations. Because TTP and its servers are
        located in the United States and our services are directed towards the
        United States market, our Privacy Policy has been prepared to comply
        with applicable United States regulations. By accessing and/or using the
        Site or our services and providing personal information to us through
        the Site, you agree to the level of privacy protection set out in this
        Privacy Policy.
      </div>
      <div className={styles.questionWrp} id="sec13">
        <div className={styles.questionTitle}> WHAT IF I LIVE IN CANADA?</div>
        Canadian citizens, except under circumstances defined by law, are
        entitled to access their own personal information collected by TTP by
        writing to: Traction Technology Partners, 603 Greenwich Street, Suite
        101, New York, NY 10014. If you believe that the personal information
        about you that we have collected is incomplete or inaccurate, please
        contact us and we will correct the information upon verification of the
        omission or error and of the identity of the person requesting the
        change
      </div>
      <div className={styles.questionWrp} id="sec14">
        <div className={styles.questionTitle}>
          WHAT IF I FOLLOW A LINK TO A THIRD PARTY WEBSITE?
        </div>
        The Site may contain links to other websites. The fact that we link to a
        website is not an endorsement, authorization or representation of our
        affiliation with that third party. We do not exercise control over third
        party websites. These other websites may place their own cookies or
        other files on your computer, collect data, or solicit personal
        information from you. Other sites follow different rules regarding the
        use or disclosure of the personal information you submit to them. We
        encourage you to read the privacy policies or statements of the other
        websites. Please be aware that the terms of our Privacy Policy do not
        apply to these third party websites.
      </div>
      <div className={styles.questionWrp} id="sec15">
        <div className={styles.questionTitle}>
          WILL TTP CHANGE THIS PRIVACY POLICY?
        </div>
        Each time you use the Site, the current version of this Privacy Policy
        will apply. When you use the Site, you should check the date of this
        Privacy Policy and review any changes since the last version. Visitors
        can always refuse to accept changes to our Privacy Policy by ending
        their use of the Site. Unless stated otherwise, our current Privacy
        Policy applies to all information that we have about you. Continuation
        of your use of the Site shall be considered acceptance of any changes in
        the Privacy Policy.
      </div>
      <div className={styles.questionWrp} id="sec16">
        <div className={styles.questionTitle}>HOW DO I CONTACT TTP?</div>
        If you have any questions about this Privacy Policy, or you wish to
        update or request that we delete the information you have shared with
        us, you may contact us at
        <span className={styles.link}>
          <a href="mailto:business@tractiontechpartners.com">
            business@tractiontechpartners.com
          </a>
        </span>
        , by phone at (929) 352-4092, or at TTP’s physical address: <br />
        Traction Technology Partners <br /> 603 Greenwich Street, <br /> Suite
        101 New York, NY 10014
      </div>
      <div className={styles.footer}>
        {" "}
        © COPYRIGHT 2021 TRACTION TECHNOLOGY PARTNERS, LLC, ALL RIGHTS RESERVED.
      </div>
    </div>
  </div>
);

export default PrivacyPolicyComponent;

import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  httpGet,
  errorHandler,
  httpPost,
  httpDelete,
  httpPut,
} from "../../../common/httpCall";
import {
  SOLUTIONS_GET_ATTEMPT,
  solutionsGetAttemptSuccess,
  SOLUTION_ADD_ATTEMPT,
  solutionsAddSuccess,
  SOLUTION_DELETE_ATTEMPT,
  solutionsDeleteSuccess,
  SOLUTIONS_SEARCH_ATTEMPT,
  solutionsSearchSuccess,
  SOLUTION_EDIT_ATTEMPT,
  solutionsEditSuccess,
  SOLUTION_PROJECT_DELETE_ATTEMPT,
  solutionsProjectDeleteSuccess,
  SOLUTIONS_STATS_GET_ATTEMPT,
  solutionsStatsGetAttemptSuccess,
  SOLUTION_BY_ID_GET_ATTEMPT,
  solutionsByIdGetSuccess,
} from "./solutionsMain.actions";

export const epicGetSolutionById = (action$, state$) =>
  action$.pipe(
    ofType(SOLUTION_BY_ID_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, id } }) =>
      httpGet({
        apiVersion: "v1",
        call: `solutions/${id}`,
      }).pipe(
        map((result) => {
          return solutionsByIdGetSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetSolutions = (action$, state$) =>
  action$.pipe(
    ofType(SOLUTIONS_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, items, page, isDropdown, cb } }) =>
      httpGet({
        apiVersion: "v1",
        call: "solutions",
        data: {
          search: {
            query: "",
          },
        },
      }).pipe(
        map((result) => {
          return solutionsGetAttemptSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicAddSolution = (action$) =>
  action$.pipe(
    ofType(SOLUTION_ADD_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: "solutions",
        data,
      }).pipe(
        map(
          (result) => {
            if (enqueueSnackbar) {
              enqueueSnackbar("Solution added with success", {
                variant: "success",
              });
            }
            return solutionsAddSuccess(result);
          },
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
      )
    )
  );

export const epicDeleteSolution = (action$, state$) =>
  action$.pipe(
    ofType(SOLUTION_DELETE_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpDelete({
        call: `solutions/${id}`,
      }).pipe(
        map(() => {
          return solutionsDeleteSuccess(id);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

export const epicSearchSolutions = (action$) =>
  action$.pipe(
    ofType(SOLUTIONS_SEARCH_ATTEMPT),
    switchMap(({ payload: { items, page, data, enqueueSnackbar, fromMain } }) =>
      httpPost({
        apiVersion: "v1",
        call: `solutions/search?items=${items || 18}&page=${page || 1}`,
        data,
      }).pipe(
        map(
          (result) => solutionsSearchSuccess(result),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
      )
    )
  );

export const epicEditSolution = (action$) =>
  action$.pipe(
    ofType(SOLUTION_EDIT_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar, id } }) =>
      httpPut({
        apiVersion: "v1",
        call: `solutions/${id}`,
        data,
      }).pipe(
        map(
          (result) => {
            if (enqueueSnackbar) {
              enqueueSnackbar("Solution edited with success", {
                variant: "success",
              });
            }
            return solutionsEditSuccess(result);
          },
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
      )
    )
  );

export const epicDeleteSolutionProject = (action$, state$) =>
  action$.pipe(
    ofType(SOLUTION_PROJECT_DELETE_ATTEMPT),
    switchMap(({ payload: { id, projectId, enqueueSnackbar } }) =>
      httpDelete({
        call: `solutions/${id}/projects/${projectId}`,
      }).pipe(
        map((payload) => {
          return solutionsProjectDeleteSuccess({
            status: payload.staus,
            solutionId: id,
            projectIdToDelete: projectId,
            response: payload.response,
          });
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );
export const epicGetSolutionsStats = (action$, state$) =>
  action$.pipe(
    ofType(SOLUTIONS_STATS_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v1",
        call: "solutions/statistics",
      }).pipe(
        map((result) => {
          return solutionsStatsGetAttemptSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
const solutionsMainEpic = combineEpics(
  epicGetSolutions,
  epicAddSolution,
  epicDeleteSolution,
  epicSearchSolutions,
  epicEditSolution,
  epicDeleteSolutionProject,
  epicGetSolutionsStats,
  epicGetSolutionById
);

export default solutionsMainEpic;

import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  httpGet,
  httpDelete,
  errorHandler,
} from "../../../common/httpCall";
import {
  PATIENT_ORGANIZATIONS_GET_ATTEMPT,
  patientOrganizationsGetSuccess,
  PATIENT_ORGANIZATION_GET_DISEASES_ATTEMPT,
  patientOrganizationGetDiseasesSuccess,
  PATIENT_ORGANIZATION_DELETE,
  patientOrganizationDeleteSuccess,
  PATIENT_ORGANIZATION_GET_DIVERSITY_GROUPS_ATTEMPT,
  patientOrganizationGetDiversityGroupsSuccess,
} from "./patientOrganizationMain.action";

export const epicGetPatientOrganizations = (action$, state$) => action$.pipe(
  ofType(PATIENT_ORGANIZATIONS_GET_ATTEMPT),
  switchMap(({ payload }) => httpGet({
    call: `gsk/patient_organizations?items=${payload.items || 20}&page=${
      payload.page || 1
    }&query=${payload.data || ""}&disease_ids=${
      payload.diseaseIds || ""
    }&countries=${payload.countries || ""}`, // NEW_API
    // call: `companies/all?council=${payload}`,
  }).pipe(
    map((result) => patientOrganizationsGetSuccess(result)),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicDeletePatientOrganizations = (action$, state$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_DELETE),
  switchMap(
    ({ payload: { patient_organization_id, enqueueSnackbar, name } }) => httpDelete({
      call: `gsk/patient_organizations/${patient_organization_id}`,
    }).pipe(
      map((result) => {
        enqueueSnackbar(`You deleted the Organization: ${name}`, {
          variant: "success",
        });
        return patientOrganizationDeleteSuccess(result);
      }),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

export const epicGetDiseases = (action$, state$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_GET_DISEASES_ATTEMPT),
  switchMap(({ payload }) => httpGet({
    call: `diseases?filter=${payload.filter || ""}`,
  }).pipe(
    map((result) => patientOrganizationGetDiseasesSuccess(result)),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicGetDiversityGroups = (action$, state$) => action$.pipe(
  ofType(PATIENT_ORGANIZATION_GET_DIVERSITY_GROUPS_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar } }) => httpGet({
    call: "diversity_groups",
  }).pipe(
    map((result) => patientOrganizationGetDiversityGroupsSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const patientOrganizationMainEpic = combineEpics(
  epicGetPatientOrganizations,
  epicGetDiseases,
  epicDeletePatientOrganizations,
  epicGetDiversityGroups,
);

export default patientOrganizationMainEpic;

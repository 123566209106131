import {
  PIPELINE_GET_STAGES_ATTEMPT,
  PIPELINE_GET_STAGES_SUCCESS,
  PIPELINE_ADD_STAGE_ATTEMPT,
  PIPELINE_ADD_STAGE_SUCCESS,
  PIPELINE_EDIT_STAGE_ATTEMPT,
  PIPELINE_EDIT_STAGE_SUCCESS,
  PIPELINE_DELETE_STAGE_ATTEMPT,
  PIPELINE_DELETE_STAGE_SUCCESS,
  PIPELINE_GET_INVEST_STAGES_SUCCESS,
  PIPELINE_GET_INVEST_STAGES_ATTEMPT,
  THEME_PIPELINE_COMPANIES_SEARCH_ATTEMPT,
  PIPELINE_SEARCH_COMPANIES_SUCCESS,
  PIPELINE_EDIT_STAGE_ACROSS_ATTEMPT,
  PIPELINE_UPDATE_COMP_LIST_FE_ACROSS_SUCCESS,
} from "./pipelineTab.action";

import {
  THEMES_COMPANIES_SEARCH_SUCCESS,
  THEMES_COMPANIES_SEARCH_ATTEMPT,
} from "../../themesMainPage/themesMain.action";
import { pageMeta } from "../../../../common/httpCall";

const initState = {
  companyPiplineList: [],
  companiesInStagesList: [],
  loadingStages: false,
  loadingIndividualStage: false,
  shouldUpdateStages: false,
  companiesInvestCompList: [],
  loadingInvestCompanies: false,
  piplineInvestStages: [],
  themesCompaniesPages: {},
};

const themesPipelineReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PIPELINE_GET_STAGES_ATTEMPT:
      return {
        ...state,
        loadingStages: true,
        shouldUpdateStages: false,
      };
    case PIPELINE_GET_STAGES_SUCCESS:
      return {
        ...state,
        companiesInStagesList: payload?.response,
        loadingStages: false,
        shouldUpdateStages: true,
      };

    case PIPELINE_ADD_STAGE_ATTEMPT:
      return {
        ...state,
        loadingIndividualStage: true,
      };
    case PIPELINE_ADD_STAGE_SUCCESS:
      return {
        ...state,
        loadingIndividualStage: false,
      };
    case PIPELINE_EDIT_STAGE_ATTEMPT:
      return {
        ...state,
        shouldUpdateStages: false,
        loadingStages: true,
      };
    case PIPELINE_EDIT_STAGE_SUCCESS:
      return {
        ...state,
        companiesInStagesList: payload?.response,
        loadingStages: false,
        shouldUpdateStages: true,
      };
    case PIPELINE_DELETE_STAGE_ATTEMPT:
      return {
        ...state,
        loadingIndividualStage: true,
      };
    case PIPELINE_DELETE_STAGE_SUCCESS:
      return {
        ...state,
        loadingIndividualStage: false,
      };
    case PIPELINE_GET_INVEST_STAGES_ATTEMPT:
      return {
        ...state,
        loadingInvestCompanies: true,
        shouldUpdateInvestStages: false,
      };
    case PIPELINE_GET_INVEST_STAGES_SUCCESS: {
      const flatCompanies = [];
      const filtered = payload?.response.map((stage) => {
        stage.theme_companies.map((comp) => {
          flatCompanies.push(comp);
          return null;
        });
        return null;
      });
      return {
        ...state,
        companiesInvestCompList: flatCompanies,
        piplineInvestStages: payload?.response,
        loadingInvestCompanies: false,
        shouldUpdateInvestStages: true,
        loadingStages: false,
      };
    }
    case THEMES_COMPANIES_SEARCH_ATTEMPT:
      return {
        ...state,
        loadingStages: true,
        shouldUpdateStages: false,
      };
    case THEMES_COMPANIES_SEARCH_SUCCESS:
      return {
        ...state,
        companiesInStagesList: payload?.response,
        loadingStages: false,
        shouldUpdateStages: true,
      };
    case THEME_PIPELINE_COMPANIES_SEARCH_ATTEMPT:
      return {
        ...state,
        loadingPipelineTable: true,
      };
    case PIPELINE_SEARCH_COMPANIES_SUCCESS:
      const pageMetaObj = pageMeta(payload);
      return {
        ...state,
        loadingPipelineTable: false,
        companyPiplineList: payload?.response,
        themesCompaniesPages: pageMetaObj,
      };
    case PIPELINE_EDIT_STAGE_ACROSS_ATTEMPT:
      return {
        ...state,
        shouldUpdateStages: false,
        loadingStages: true,
      };

    case PIPELINE_UPDATE_COMP_LIST_FE_ACROSS_SUCCESS:
      const updateStages = () => {
        if (payload.data?.council_company_in_stage_position === undefined) {
        }
        let updatedCompInStages = state.companiesInStagesList.map((stage) => {
          if (
            payload.data?.council_relationship_stage_id ===
            payload.companySelected?.council_relationship_stage_id
          ) {
            return stage;
          }
          if (
            stage.theme_companies &&
            stage?.council_relationship_stage_id ===
              payload.companySelected?.council_relationship_stage_id
          ) {
            stage.theme_companies.map((company, i) => {
              // dragging and removing from old stage
              if (
                company.id === payload.companyId &&
                payload.data?.council_company_in_stage_position
              ) {
                stage.theme_companies.splice(i, 1);
              }
              // in case of checkbox clicking
              if (
                payload.data?.council_company_in_stage_position === undefined &&
                company.id === payload.companyId
              ) {
                if (payload.data?.partnership !== undefined) {
                  stage.theme_companies[i]["partnership"] =
                    payload.data?.partnership;
                }
                if (payload.data?.investment !== undefined) {
                  stage.theme_companies[i]["investment"] =
                    payload.data?.investment;
                }
              }
            });
            return stage;
          }

          ////inserting
          if (
            stage?.council_relationship_stage_id ===
            payload.data?.council_relationship_stage_id
          ) {
            stage.theme_companies.splice(
              payload.data.council_company_in_stage_position - 1,
              0,
              payload.companySelected
            );
          }
          ////end of inserting
          return stage;
        });
        // return updatedCompInStages;
        return state.companiesInStagesList;
      };

      if (payload.status === 200) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        companiesInStagesList: updateStages(),
        // loadingStages: false,
        shouldUpdateStages: true,
      };

    default:
      return state;
  }
  //   return state;
};

export default themesPipelineReducer;

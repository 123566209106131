import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import styles from "./activity.module.scss";
import { Loading, Pannel, pageSettings } from "../../../../primitives";
import { projectActivitiesGet } from "../../project.action";

const ActivityComponent = () => {
  const dispatch = useDispatch();
  const {
    projectsReducer: {
      projectReducer: { activities, selectedProject, activitiesMeta },
    },
  } = useSelector((state) => state);

  const [pageSetting, setPageSetting] = useState(pageSettings());
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (selectedProject && selectedProject.id) {
      setPageSetting((prev) => ({ ...prev, current: 1 }));
    }
  }, [selectedProject && selectedProject.id]);

  useEffect(() => {
    if (selectedProject && selectedProject.id) {
      getData();
    }
  }, [selectedProject && selectedProject.id, pageSetting.current]);

  const getData = () => {
    dispatch(
      projectActivitiesGet({
        projectId: selectedProject.id,
        currentPage: pageSetting.current,
        pageSize: pageSetting.pageSize,
        enqueueSnackbar,
      }),
    );
  };

  const handleOnScroll = (e) => {
    const contentHeight = e.target.scrollHeight - e.target.offsetHeight;

    if (
      e.target.scrollTop + 5 >= contentHeight
      && activitiesMeta.pages !== activitiesMeta.page
    ) {
      setPageSetting((prev) => ({ ...prev, current: prev.current + 1 }));
    }
  };

  return (
    <Pannel title="Activity" padding="0">
      <div className={styles.wrapper}>
        <div className={styles.grid}>
          <div className={styles.gridHeader}>
            <div className={styles.activity}>Activity</div>
            <div className={styles.details} />
            <div className={styles.date}>Date</div>
          </div>
          <div className={styles.gridBody} onScroll={handleOnScroll}>
            {activities
              && activities.map((activity, index) => (
                <div
                  key={`${activity.item_id}_${index}`}
                  className={styles.gridRow}
                >
                  <div className={styles.activity}>{activity.title}</div>
                  <div className={styles.details}>{activity.details}</div>
                  <div className={styles.date}>
                    {activity.created_at ? `${activity.created_at} ago` : "-"}
                  </div>
                </div>
              ))}
            {activitiesMeta.pages !== activitiesMeta.page && <Loading />}
          </div>
        </div>
      </div>
    </Pannel>
  );
};

export default React.memo(ActivityComponent);

import {
  PROJECT_STAGES_LIST_GET_SUCCESS,
  PROJECT_STAGE_PUT_SUCCESS,
  PROJECT_STAGE_NEW_SUCCESS,
  PROJECT_STAGE_OBJ_MOVE,
  PROJECT_STAGE_COMPANY_UPDATE_SUCCESS,
  PROJECT_COMPANT_TASK_STATUS_GET_SUCCESS,
  PROJECT_COMPANY_TASK_STATUS_REMOVE_SUCCESS,
  PROJECT_COMPANT_TASK_STATUS_NEW_SUCCESS,
  PROJECT_STAGE_REMOVE_SUCCESS,
  PROJECT_STAGE_COMPANIES_LIST_SUCCESS,
  PROJECT_STAGE_COMPANY_NEW,
  PROJECT_STAGE_COMPANY_REMOVE,
  PROJECT_STAGE_ORGANIZATIONS_GET_SUCCESS,
  PROJECT_WIDE_TASKS_GET_SUCCESS,
  PROJECT_WIDE_TASKS_UPDATE,
  PROJECT_STAGES_RESET,
  PROJECT_WIDE_TASK_SET_COMPLETE,
  PROJECT_STAGE_COMPANIES_LIST_RESET,
  ALL_PROJECT_STAGES_LIST_GET_SUCCESS,
  ALL_TEMPLATES_PROJECT_STAGES_LIST_GET_SUCCESS,
} from "./stages.action";
import { apiStatus } from "../../../../common/httpCall";
import {
  GATEWAY_TASK_UPDATE_SUCCESS, GET_TASK_ADDITIONAL_DATA_SUCCESS,
  MARK_TASK_COMPLETED,
  PPROJECT_TASK_DOCUMENTS_DELETE_SUCCESS,
  PPROJECT_TASK_UPDATE_SUCCESS,
  STATIC_GATEWAY_TASK_UPDATE,
} from "modules/projects/project/tasks/tasks.action";

const initState = {
  stagesList: [],
  allStagesList: [],
  allTemplatesStagesList: [],
  companyList_stageId: {},
  companyTaskStatus: [],
  organizationsByStageId: {},
  projectWideTasks: [],
  shouldUpdate: false,
};
const stagesReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_TASK_ADDITIONAL_DATA_SUCCESS: {
      if (!payload.isProjectWideFromStage) return state;

      return {
        ...state,
        projectWideTasks: state.projectWideTasks.map((task) => {
          if (task.id === payload.response.id) {
            return {
              ...task,
              ...payload.response,
              hasAdditionalData: true,
            }
          }

          return task;
        }),
      }
    }

    case PPROJECT_TASK_DOCUMENTS_DELETE_SUCCESS: {
      if (!payload.isProjectWideFromStage) {
        return state;
      }

      return {
        ...state,
        projectWideTasks: state.projectWideTasks.map((task) => {
          if (task.id === payload.taskId) {
            const projects_task_assignments =
              task.projects_task_assignments.map((a) => ({
                ...a,
                documents: a.documents.filter(
                  (d) => d.id !== payload.documentId
                ),
              }));

            return {
              ...task,
              projects_task_assignments,
            };
          }

          return task;
        }),
      };
    }

    case MARK_TASK_COMPLETED: {
      if (!payload.isProjectWideFromStage) {
        return state;
      }

      return {
        ...state,
        projectWideTasks: state.projectWideTasks?.map((task) => {
          if (task.id === payload.taskId) {
            return {
              ...task,
              task_completion_state: payload.completionProps?.task_completion_state,
              projects_task_assignments: task.projects_task_assignments?.map(
                (a) => ({ ...a, completed: payload.completed })
              ),
            };
          }

          return task;
        }),
      };
    }

    case STATIC_GATEWAY_TASK_UPDATE: {
      if (!payload.isProjectWideFromStage) {
        return state;
      }

      const projectWideTasks = state.projectWideTasks.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            ...payload,
          };
        }

        return item;
      });

      return {
        ...state,
        projectWideTasks,
      };
    }

    case GATEWAY_TASK_UPDATE_SUCCESS: {
      if (!payload.isProjectWideFromStage) {
        return state;
      }

      const projectWideTasks = state.projectWideTasks.map((item) => {
        if (item.id === payload.response.id) {
          return {
            ...item,
            ...payload.response,
          };
        }

        return item;
      });

      return {
        ...state,
        projectWideTasks,
      };
    }

    case PROJECT_STAGES_RESET: {
      return {
        ...state,
        stagesList: [],
        companyList_stageId: {},
        organizationsByStageId: {},
        projectWideTasks: [],
      };
    }

    case PPROJECT_TASK_UPDATE_SUCCESS: {
      if (payload.isProjectWideFromStage) {
        return {
          ...state,
          projectWideTasks: state.projectWideTasks.map((t) => {
            if (t.id === payload.id) {
              return {
                ...t,
                ...payload,
              };
            }

            return t;
          }),
        };
      }

      return state;
    }

    case PROJECT_WIDE_TASK_SET_COMPLETE: {
      const { taskId, assignmentId, value } = payload;

      return {
        ...state,
        projectWideTasks: state.projectWideTasks.map((t) => {
          if (t.id === taskId) {
            return {
              ...t,
              projects_task_assignments: t.projects_task_assignments.map(
                (a) => {
                  if (a.id === assignmentId) {
                    return {
                      ...a,
                      completed: value,
                    };
                  }

                  return a;
                }
              ),
            };
          }

          return t;
        }),
      };
    }

    case PROJECT_WIDE_TASKS_UPDATE: {
      const { response, status } = payload;

      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          projectWideTasks: state.projectWideTasks.map((t) =>
            t.id === response.id ? response : t
          ),
        };
      }

      return state;
    }

    case PROJECT_WIDE_TASKS_GET_SUCCESS:
      {
        const { response, status } = payload;

        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            projectWideTasks: response,
          };
        }
      }
      break;

    case PROJECT_STAGE_ORGANIZATIONS_GET_SUCCESS:
      {
        const {
          result: { status, response },
          stageId,
        } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            organizationsByStageId: {
              ...state.organizationsByStageId,
              [stageId]: response,
            },
          };
        }
      }
      break;

    case PROJECT_STAGES_LIST_GET_SUCCESS:
      {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            stagesList: response,
          };
        }
      }
      break;
    case ALL_PROJECT_STAGES_LIST_GET_SUCCESS:
      {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            allStagesList: response,
          };
        }
      }
      break;

    case ALL_TEMPLATES_PROJECT_STAGES_LIST_GET_SUCCESS:
      {
        const { status, response } = payload;
        if (status === apiStatus.SUCCESS) {
          return {
            ...state,
            allTemplatesStagesList: response,
          };
        }
      }
      break;

    case PROJECT_STAGE_PUT_SUCCESS:
      return {
        ...state,
        stagesList: state.stagesList.map((stage) =>
          stage.id === payload.stage.id
            ? { ...payload, ...payload.stage }
            : stage
        ),
      };

    case PROJECT_STAGE_NEW_SUCCESS: {
      const { status, response } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          stagesList: [...state.stagesList, response],
        };
      }
      break;
    }

    case PROJECT_STAGE_OBJ_MOVE:
      const { removeObj, addObj } = payload;
      const psid_list = state.companyList_stageId;
      const remObj = psid_list[removeObj.stageId][removeObj.index];
      psid_list[removeObj.stageId].splice(removeObj.index, 1);
      psid_list[removeObj.stageId] = psid_list[removeObj.stageId].map(
        (item, inx) => {
          item.position = inx + 1;
          return item;
        }
      );
      remObj.stage_id = addObj.stageId;
      psid_list[addObj.stageId]
        ? psid_list[addObj.stageId].splice(addObj.index, 0, remObj)
        : (psid_list[addObj.stageId] = [remObj]);

      psid_list[addObj.stageId] = psid_list[addObj.stageId].map((item, inx) => {
        item.position = inx + 1;
        return item;
      });
      return {
        ...state,
        companyList_stageId: psid_list,
      };

    case PROJECT_STAGE_REMOVE_SUCCESS: {
      const {
        stageId,
        result: { status, response },
      } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          stagesList: state.stagesList.filter((stage) => stage.id !== stageId),
        };
      }
      break;
    }

    case PROJECT_STAGE_COMPANIES_LIST_RESET: {
      return {
        ...state,
        shouldUpdate: false,
        companyList_stageId: {},
      };
    }

    case PROJECT_STAGE_COMPANIES_LIST_SUCCESS: {
      const {
        result: { status, response },
        stageId,
      } = payload;
      if (status === apiStatus.SUCCESS) {
        const companiesByStage = state.companyList_stageId[stageId]
          ? [...state.companyList_stageId[stageId], ...(response || [])]
          : response || [];
        const finalData = companiesByStage.filter(
          (v, i, a) =>
            a.findIndex((t) =>
              t.is_product
                ? t.id === v.id
                : t.project_company_id === v.project_company_id
            ) === i
        );

        return {
          ...state,
          companyList_stageId: {
            ...state.companyList_stageId,
            shouldUpdate: true,
            [stageId]: finalData,
            // [stageId]: response,
          },
        };
      }
      break;
    }

    case PROJECT_STAGE_COMPANY_UPDATE_SUCCESS: {
      const { status, response } = payload;
      if (status === apiStatus.SUCCESS) {
        return state;
      }
      break;
    }

    case PROJECT_STAGE_COMPANY_NEW: {
      const {
        stageId,
        result: { status, response },
        hasArrayRes = false,
        postPayload,
      } = payload;
      if (status === apiStatus.CREATED || status === apiStatus.SUCCESS) {
        return {
          ...state,
          companyList_stageId: {
            ...state.companyList_stageId,
            [stageId]: state.companyList_stageId[stageId]
              ? hasArrayRes
                ? [...state.companyList_stageId[stageId], ...response]
                : [...state.companyList_stageId[stageId], response]
              : hasArrayRes
              ? [...response]
              : [response],
          },
        };
      }
      break;
    }

    case PROJECT_STAGE_COMPANY_REMOVE: {
      const {
        stageId,
        companyId,
        result: { status, response },
      } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          companyList_stageId: {
            ...state.companyList_stageId,
            [stageId]: state.companyList_stageId[stageId].filter(
              ({ company }) => company.id !== companyId
            ),
          },
        };
      }
      break;
    }

    case PROJECT_COMPANT_TASK_STATUS_GET_SUCCESS:
    case PROJECT_COMPANT_TASK_STATUS_NEW_SUCCESS:
      {
        const { taskId, companyId, data } = payload;
        if (data) {
          return {
            ...state,
            companyTaskStatus: {
              ...state.companyTaskStatus,
              [`${taskId}_${companyId}`]: data,
            },
          };
        }
      }
      break;
    case PROJECT_COMPANY_TASK_STATUS_REMOVE_SUCCESS:
      const { taskId, companyId, data } = payload;
      const { message } = data;
      if (message === "Resource has been deleted.") {
        const list = state.companyTaskStatus;
        delete list[`${taskId}_${companyId}`];
        return {
          ...state,
          companyTaskStatus: list,
        };
      }
      break;
  }
  return state;
};

export default stagesReducer;

const gererateCompanyListObject = (obj) => {
  const company = {};
  return company;
};

const navFilter = (menu, council_role, session, selectedCouncil) => {
  return menu
    .filter((menuItem) => {
      if (menuItem.mobileOnly) {
        return false;
      }
      return (
        menuItem.showInFordNavBar &&
        !menuItem.councilsToHideInNav?.includes(selectedCouncil?.traction_tag)
      );
    })
    .map((item) => {
      if (item.path === "/themes") {
        return {
          path: "/themes",
          text: selectedCouncil?.settings?.themes_display_name || "Themes",
          showInFordNavBar: true,
        };
      }
      return item;
    });
};
export default navFilter;

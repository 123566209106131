import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { BtnType, Button } from "modules/primitives/index";
import CompanyInline from "../../../primitives/companyInline/companyInline.component";
import styles from "./company.module.scss";
import {
  changeFollowRelationship,
  companyUnFollow,
  suggestionFollow,
} from "../../../../common/actions/common.action";

const Company = ({
  gridRef,
  company,
  enqueueSnackbar,
  type,
  updateFollowers,
  relationShipStatuses,
  idx,
}) => {
  const [showFollow, setShowFollow] = useState(false);
  const [wasFollowed, setWasFollowed] = useState(false);
  const [relationship, setRelationship] = useState(null);
  const curItemRef = useRef([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (company?.council_relationship_stage_id && relationShipStatuses) {
      const getStageDetails = relationShipStatuses.find(
        (s) => s.customId === company?.council_relationship_stage_id
      );

      setRelationship(getStageDetails);
    }
  }, [company, relationShipStatuses]);

  useEffect(() => {
    if (wasFollowed && updateFollowers) {
      dispatch(changeFollowRelationship(company.following.company_id));
    }
  }, [updateFollowers]);

  const makeUnfollow = (e) => {
    e.stopPropagation();
    dispatch(
      companyUnFollow({
        data: company,
        enqueueSnackbar,
      })
    );
    setShowFollow(true);
  };

  const makeFollow = (e) => {
    e.stopPropagation();
    dispatch(
      suggestionFollow({
        data: {
          ...company,
          company_id: company.following_id,
          name: company.following.name,
        },
        enqueueSnackbar,
      })
    );

    setWasFollowed(true);
    setShowFollow(false);
  };

  const handleOnClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    history.push(`/companies/${company.following?.slug || company.following_id}`);
  };

  const handleFollowClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (showFollow) {
      makeFollow(e);
    } else {
      makeUnfollow(e);
    }
  };

  useEffect(() => {
    if (type === "unfollowed") {
      setShowFollow(true);
    }
  }, []);

  useEffect(() => {
    if (gridRef.current && curItemRef.current) {
      const gridItem = gridRef.current;

      const curScrollCompany = curItemRef.current[idx];
      gridItem.addEventListener("scroll", () => {
        const y = gridItem.scrollTop;
        curScrollCompany.style.top = -(y - 35) + 50 * idx + "px";
      });
    }
  }, [gridRef.current, curItemRef.current]);

  return (
    <div className={styles.companyItem} onClick={handleOnClick}>
      <div ref={(elem) => (curItemRef.current[idx] = elem)} className={styles.company}>
        <CompanyInline
          companyId={company.following?.company_id}
          companyName={company.following?.name}
          company={company.following}
          avatar={company.following?.logo}
        />
      </div>
      <div className={styles.activity}>{company.following?.last_activity || "-"}</div>
      <div className={styles.status}>
        {!company?.council_relationship_stage_id ? (
          <div className={styles.tractionMatch}>Traction Match</div>
        ) : (
          <div
            style={{
              backgroundColor: relationship?.colors?.background || "",
              color: relationship?.colors?.text || "",
            }}
          >
            {relationship?.name || "-"}
          </div>
        )}
      </div>
      <div className={styles.score}>{company.following?.council_company_score || "-"}</div>
      <div className={showFollow ? styles.follow : styles.following}>
        <Button
          className={styles.followingBtn}
          btn={showFollow ? BtnType.OUTLINE : BtnType.HIGLIGHT}
          onClick={(e) => handleFollowClick(e)}
          icon={!showFollow && "icn-checkbox-button"}
        >
          {showFollow ? "Follow" : "Following"}
        </Button>
      </div>
    </div>
  );
};

export default Company;

import React from "react";
import { useSnackbar } from "notistack";

import ProductDetails from "modules/companies/product/components/overview/productDetails";
import CompanyCard from "modules/companies/company/components/companyCard/companyCard.component";
import { Icon, mdSize } from "../../../../../common/icon";
import useModelPopup from "common/hooks/modelPopup.hook";
import EditProductMainInfo from "modules/companies/product/components/overview/productDetails/editProductMainInfo";
import ProductVideo from "modules/companies/product/components/overview/productVideo";
import EditProductVideo from "modules/companies/product/components/overview/productVideo/editProductVideo";
import ProductImages from "modules/companies/product/components/overview/productImages";
import EditProductImages from "modules/companies/product/components/overview/productImages/editProductImages";
import ProductPricingAndReviews from "modules/companies/product/components/overview/productPricingAndReviews";
import EditProductPricingAndReviews from "modules/companies/product/components/overview/productPricingAndReviews/editProductPricingAndReviews";
import ProductDocs from "modules/companies/product/components/overview/productDocs";
import EditProductDocs from "modules/companies/product/components/overview/productDocs/editProductDocs";
import ProductFeatures from "modules/companies/product/components/overview/productFeatures";
import EditProductFeatures from "modules/companies/product/components/overview/productFeatures/editProductFeatures";
import ProductSecurity from "modules/companies/product/components/overview/productSecurity";
import EditProductSecurity from "modules/companies/product/components/overview/productSecurity/editProductSecurity";
import ProductIntegrations from "modules/companies/product/components/overview/productIntegrations";
import EditProductIntegrations from "modules/companies/product/components/overview/productIntegrations/editProductIntegrations";
import ProductContacts from "modules/companies/product/components/overview/productContacts";
import ProductOwners from "modules/companies/product/components/overview/productOwners";
import ProductRelatedIdeas from "modules/companies/product/components/overview/relatedIdeas";
import AddProductToIdeaComponent from "modules/companies/product/components/addProductToIdea/addProductToIdea.component";
import ProductRelatedThemes from "modules/companies/product/components/overview/relatedThemes";
import AddProductToTheme from "modules/companies/product/components/addProductToTheme";

const ProductOverview = ({ product, selectedCouncil, canEdit }) => {
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();

  const handleEditProductInformation = () => {
    popup.show({
      show: true,
      title: "Edit Product Main Info",
      component: <EditProductMainInfo product={product} />,
    });
  };

  const handleEditProductVideo = () => {
    popup.show({
      show: true,
      title: "Edit Product Video",
      component: <EditProductVideo product={product} />,
    });
  };

  const handleEditProductImages = () => {
    popup.show({
      show: true,
      title: "Edit Product Images",
      component: <EditProductImages product={product} />,
    });
  };

  const handleEditProductReviews = (instance) => {
    popup.show({
      show: true,
      width: "70%",
      title: `Edit Product ${instance === "price" ? "Price" : "Reviews"}`,
      component: (
        <EditProductPricingAndReviews product={product} instance={instance} />
      ),
    });
  };

  const handleEditProductDocs = (instance, title) => {
    popup.show({
      show: true,
      title: `Edit Product ${title}`,
      component: <EditProductDocs product={product} instance={instance} />,
    });
  };

  const handleEditProductFeatures = () => {
    popup.show({
      show: true,
      title: "Edit Product Features",
      component: <EditProductFeatures product={product} />,
    });
  };

  const handleEditProductSecurity = () => {
    popup.show({
      show: true,
      title: "Edit Product Security",
      component: <EditProductSecurity product={product} />,
    });
  };

  const handleEditProductIntegrations = () => {
    popup.show({
      show: true,
      title: "Edit Product Integrations and API's",
      component: <EditProductIntegrations product={product} />,
    });
  };

  const handleAddIdea = () => {
    popup.show({
      show: true,
      title: "Add Product to Idea",
      component: (
        <AddProductToIdeaComponent
          product={product}
          enqueueSnackbar={enqueueSnackbar}
        />
      ),
    });
  };

  const handleAddProject = () => {
    // add project
  };

  const handleAddTheme = () => {
    popup.show({
      show: true,
      title: `Add Product to ${
        selectedCouncil?.settings?.themes_display_name_singular || "Theme"
      }`,
      component: <AddProductToTheme product={product} instance="product" />,
    });
  };

  return (
    <div className="row m-0">
      <div className="col-12 col-md-8 flex-grow-1">
        <CompanyCard
          title={`Product Images (${product.images?.length})`}
          rightContent={
            canEdit ? (
              <Icon
                {...mdSize}
                icon="icn-edit-button"
                onClick={handleEditProductImages}
              />
            ) : null
          }
        >
          <ProductImages product={product} />
        </CompanyCard>
        <CompanyCard
          className="mt-5"
          title="Product Information"
          rightContent={
            canEdit ? (
              <Icon
                {...mdSize}
                icon="icn-edit-button"
                onClick={handleEditProductInformation}
              />
            ) : null
          }
        >
          <ProductDetails product={product} selectedCouncil={selectedCouncil} />
        </CompanyCard>
        <CompanyCard
          className="mt-5"
          title="Product Video"
          rightContent={
            canEdit ? (
              <Icon
                {...mdSize}
                icon="icn-edit-button"
                onClick={handleEditProductVideo}
              />
            ) : null
          }
        >
          <ProductVideo product={product} />
        </CompanyCard>
        <CompanyCard
          className="mt-5"
          title="Product Documents"
          rightContent={
            canEdit ? (
              <Icon
                {...mdSize}
                icon="icn-edit-button"
                onClick={() => handleEditProductDocs("docs", "Documents")}
              />
            ) : null
          }
        >
          <ProductDocs product={product} instance="docs" />
        </CompanyCard>
        <CompanyCard
          className="mt-5"
          title="Product Presentations"
          rightContent={
            canEdit ? (
              <Icon
                {...mdSize}
                icon="icn-edit-button"
                onClick={() =>
                  handleEditProductDocs("presentations", "Presentations")
                }
              />
            ) : null
          }
        >
          <ProductDocs product={product} instance="presentations" />
        </CompanyCard>
        <div className="mt-5 d-flex">
          {selectedCouncil?.traction_tag !== "gsk" && (
            <CompanyCard
              title="Related Ideas"
              className="w-50"
              rightContent={
                canEdit ? (
                  <Icon {...mdSize} icon="icn-add" onClick={handleAddIdea} />
                ) : null
              }
            >
              <ProductRelatedIdeas product={product} canEdit={canEdit} />
            </CompanyCard>
          )}
          {/*<CompanyCard*/}
          {/*  title="Related Projects"*/}
          {/*  className="mt-5"*/}
          {/*  rightContent={*/}
          {/*    canEdit ? <Icon*/}
          {/*      {...mdSize}*/}
          {/*      icon="icn-add"*/}
          {/*      onClick={handleAddProject}*/}
          {/*    /> : null*/}
          {/*  }>*/}
          {/*  <ProductRelatedProjects product={product} canEdit={canEdit} />*/}
          {/*</CompanyCard>*/}
          <CompanyCard
            title={`Related ${
              selectedCouncil?.settings?.themes_display_name || "Themes"
            }`}
            className="ml-5 w-50"
            rightContent={
              canEdit ? (
                <Icon {...mdSize} icon="icn-add" onClick={handleAddTheme} />
              ) : null
            }
          >
            <ProductRelatedThemes
              product={product}
              canEdit={canEdit}
              selectedCouncil={selectedCouncil}
            />
          </CompanyCard>
        </div>
      </div>
      <div className="col-12 col-md-4">
        <CompanyCard title="Product Owner">
          <ProductOwners product={product} canEdit={canEdit} />
        </CompanyCard>
        <CompanyCard title="Product Contacts" className="mt-5">
          <ProductContacts product={product} canEdit={canEdit} />
        </CompanyCard>
        <CompanyCard
          className="mt-5"
          title="Pricing"
          rightContent={
            canEdit ? (
              <Icon
                {...mdSize}
                icon="icn-edit-button"
                onClick={() => handleEditProductReviews("price")}
              />
            ) : null
          }
        >
          <ProductPricingAndReviews product={product} instance="price" />
        </CompanyCard>
        <CompanyCard
          className="mt-5"
          title="Reviews"
          rightContent={
            canEdit ? (
              <Icon
                {...mdSize}
                icon="icn-edit-button"
                onClick={() => handleEditProductReviews("reviews")}
              />
            ) : null
          }
        >
          <ProductPricingAndReviews product={product} instance="reviews" />
        </CompanyCard>
        <CompanyCard
          className="mt-5"
          title="Features"
          rightContent={
            canEdit ? (
              <Icon
                {...mdSize}
                icon="icn-edit-button"
                onClick={handleEditProductFeatures}
              />
            ) : null
          }
        >
          <ProductFeatures product={product} />
        </CompanyCard>
        <CompanyCard
          title="Security"
          className="mt-5"
          rightContent={
            canEdit ? (
              <Icon
                {...mdSize}
                icon="icn-edit-button"
                onClick={handleEditProductSecurity}
              />
            ) : null
          }
        >
          <ProductSecurity product={product} />
        </CompanyCard>
        <CompanyCard
          title="Integrations + APIs"
          className="mt-5"
          rightContent={
            canEdit ? (
              <Icon
                {...mdSize}
                icon="icn-edit-button"
                onClick={handleEditProductIntegrations}
              />
            ) : null
          }
        >
          <ProductIntegrations product={product} />
        </CompanyCard>
      </div>
    </div>
  );
};

export default ProductOverview;

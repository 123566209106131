import React from "react";
import styles from "./thank-you.module.scss";

const ThankYou = () => (
  <div className={styles.mainContainer}>
    <h1>Thank you for your submission!</h1>
  </div>
);

export default ThankYou;

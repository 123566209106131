import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import html2pdf from "html2pdf.js/src";

import { Button, BtnType } from "../../../modules/primitives";
import styles from "./tractionDownload.module.scss";
import { sectionToPng } from "../../download-utils";

const TractionDownload = ({
  handleDownloadState,
  timeoutTime,
  hidePng,
  hidePdf,
  shouldDownloadPdf,
  customBtnText,
  customId,
  customName,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadIsActive, setDownloadIsActive] = useState(false);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    if (shouldDownloadPdf) {
      handleClose(1);
    }
  }, [shouldDownloadPdf]);

  useEffect(() => {
    handleDownloadState(downloadIsActive);

    if (downloadIsActive) {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }
  }, [downloadIsActive]);

  useEffect(() => {
    if (
      downloadIsActive
      && (selectedType === 0 || selectedType === 1)
      && timeoutTime
    ) {
      handleDownloadHandler(selectedType);
    }

    return () => null;
  }, [selectedType, timeoutTime, downloadIsActive]);

  const handleDownload = (event) => {
    if (hidePng) {
      setDownloadIsActive(true);
      setSelectedType(1);
      return;
    }

    if (hidePdf) {
      setDownloadIsActive(true);
      setSelectedType(0);
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleDownloadHandler = () => {
    setDownloadIsActive(true);

    const timeout = setTimeout(() => {
      if (selectedType === 0) {
        sectionToPng(customId || "downloadableSection")
          .then((dataUrl) => {
            const link = document.createElement("a");
            link.download = `${customName || 'Traction-Report-'}${new Date().toLocaleString()}.png`;
            link.href = dataUrl;
            link.click();

            setDownloadIsActive(false);
            handleDownloadState(false);
            setSelectedType(null);
            setAnchorEl(null);

            clearTimeout(timeout);
          })
          .catch(() => {
            setDownloadIsActive(false);
            handleDownloadState(false);
            setSelectedType(null);
            setAnchorEl(null);

            clearTimeout(timeout);
          });

        return;
      }

      if (selectedType === 1) {
        const timeout1 = setTimeout(() => {
          const elem = document.getElementById(customId || "downloadableSection");
          const pdfWidth = elem.offsetWidth + 15 * 2;
          const height = pdfWidth * 1.5 + 15 * 2;
          const pdfHeight = height < 2287 ? 2287 : height;
          const opt = {
            margin: [20, 20, 20, 20],
            filename: `${customName || 'Traction-Report-'}${new Date().toLocaleString()}.pdf`,
            image: { type: 'png' },
            html2canvas: { useCORS: true, scale: 1 },
            // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            pagebreak: { mode: ['css', 'legacy'] },
            jsPDF: {
              orientation: 'p',
              unit: 'pt',
              // format: 'a4',
              format: [pdfWidth, pdfHeight],
              putOnlyUsedFonts: true,
              compress: true
            }
          };

          html2pdf().set(opt).from(elem).save().then(() => {
            setDownloadIsActive(false);
            handleDownloadState(false);
            setSelectedType(null);
            setAnchorEl(null);

            clearTimeout(timeout);
            clearTimeout(timeout1);
          });
        });
      }
    }, timeoutTime || 1000);
  };

  const handleClose = (type) => {
    if (typeof type !== "number") {
      setAnchorEl(null);
      return;
    }

    setDownloadIsActive(true);
    setSelectedType(type);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Button
        btn={BtnType.FRAME_LESS}
        className={`${styles.download} ${
          downloadIsActive ? styles.disabled : ""
        }`}
        icon="icn-export-button"
        onClick={handleDownload}
        id="download-button"
        aria-controls="download-button"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disabled={downloadIsActive}
      >
        <div className="d-flex align-items-center">
          {customBtnText || 'Download'}
          {downloadIsActive && <div className={styles.loading} />}
        </div>
      </Button>
      <Menu
        id="downloadChartOptions"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "download-button",
        }}
      >
        {!hidePng && (
          <MenuItem onClick={() => handleClose(0)}>DOWNLOAD PNG</MenuItem>
        )}
        {
          !hidePdf && (
            <MenuItem onClick={() => handleClose(1)}>DOWNLOAD PDF</MenuItem>
          )
        }
      </Menu>
    </div>
  );
};

export default React.memo(TractionDownload);

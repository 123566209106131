import React, { useRef } from "react";
import CloseIcon from "common/icon/closeIcon.component";
import { numberWithCommas } from "common/helper";
import filterOptions from "./solutionsMain.constant";
import styles from "./styles.module.scss";
import { Button, BtnType } from "../../primitives/index";
import { useModelPopup } from "../../../common/hooks";
import FiltersRow from "../../../common/components/filterDropdown";
import SolutionAddEditModal from "./solutionAddEditModal/index";

const SolutionsMainTopActions = ({
  related_projects,
  total_savings,
  setFilterOption,
  userId,
  solutions_total,
  userRole,
  searchFn,
  filterOptionSearchResult,
  selectedFilterOption,
  removeFilterTag,
  handleRangeFilterValueSelect,
  getInitialData,
  selectedCouncilTag,
}) => {
  const popup = useModelPopup();
  const ref = useRef();

  const handleAddSolutionModal = () => {
    popup.show({
      title: "Create Solution",
      show: true,
      component: (
        <SolutionAddEditModal userId={userId} popup={popup} formRef={ref} />
      ),
    });
  };

  const checkIfClearAllNeeded = () => {
    if (
      selectedFilterOption.project_field_values.length ||
      selectedFilterOption.topic_ids.length ||
      selectedFilterOption.theme_ids.length ||
      selectedFilterOption.area_ids.length ||
      selectedFilterOption.plant_ids.length ||
      selectedFilterOption.avarage_rating_lte !== null ||
      selectedFilterOption.avarage_savings_lte !== null
    ) {
      return true;
    }
    return false;
  };
  const findRangeLabel = (filterIndex, type) => {
    const rangeArr = filterOptions[filterIndex].ranges.find(
      (opt) => opt[0] === selectedFilterOption[type]
    );
    return `${numberWithCommas(rangeArr[0]) || "0"} -${numberWithCommas(
      rangeArr[1] || "Max."
    )}`;
  };
  return (
    <div className={styles.topActionsContainer}>
      <div className={styles.headerText}>Solutions</div>
      <div className={styles.topActionsSettingsContainer}>
        <div className={styles.leftSection}>
          <div className={styles.totalsRow}>
            <div className={styles.statsItem}>
              <p className={styles.itemText}>Related Projects:</p>
              <p className={styles.itemNumber}>{related_projects}</p>
            </div>
            <div className={styles.statsItem}>
              <p className={styles.itemText}>Total Savings: </p>
              <p className={styles.itemNumber}>
                {" "}
                ${numberWithCommas(total_savings)}
              </p>
            </div>
            <div className={styles.statsItem}>
              <p className={styles.itemText}>Total Solutions:</p>
              <p className={styles.itemNumber}>{solutions_total}</p>
            </div>
          </div>
          <FiltersRow
            getInitialData={getInitialData}
            setFilterOption={setFilterOption}
            searchFn={searchFn}
            options={filterOptions}
            handleRangeFilterValueSelect={handleRangeFilterValueSelect}
            filterOptionSearchResult={filterOptionSearchResult}
            selectedCouncilTag={selectedCouncilTag}
          />
          <div className={styles.tagsList}>
            {selectedFilterOption.plant_ids.map((plant) => (
              <div className={styles.tag} key={plant.id}>
                <span>{plant.name}</span>
                <span
                  onClick={() => {
                    removeFilterTag("plant_ids", plant.id);
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            ))}
            {selectedFilterOption.area_ids.map((area) => (
              <div className={styles.tag}>
                <span>{area.name}</span>
                <span
                  onClick={() => {
                    removeFilterTag("area_ids", area.id);
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            ))}

            {selectedFilterOption.theme_ids.map((area) => (
              <div className={styles.tag}>
                <span>{area.name}</span>
                <span
                  className={styles.tagDelete}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeFilterTag("theme_ids", area.id);
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            ))}
            {selectedFilterOption.topic_ids.map((topic) => (
              <div className={styles.tag}>
                <span>{topic.name}</span>

                <span
                  className={styles.tagDelete}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeFilterTag("topic_ids", topic.id);
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            ))}
            {selectedFilterOption.avarage_savings_gte !== null && (
              <div className={styles.tag}>
                <span>
                  $
                  {findRangeLabel(5, "avarage_savings_gte").replace("-", "- $")}
                </span>

                <span
                  className={styles.tagDelete}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeFilterTag("savings");
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            )}
            {selectedFilterOption.avarage_rating_gte !== null && (
              <div className={styles.tag}>
                <span>{findRangeLabel(4, "avarage_rating_gte")}</span>
                <span
                  className={styles.tagDelete}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeFilterTag("rating");
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            )}
            {selectedFilterOption.project_field_values.map((field) => (
              <div className={styles.tag}>
                <span>{field}</span>
                <span
                  className={styles.tagDelete}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeFilterTag("project_field_values", field);
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            ))}
            {checkIfClearAllNeeded() ? (
              <div
                className={styles.tag}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeFilterTag("all");
                }}
              >
                <span>Clear All</span>
                <span className={styles.tagDelete}>
                  <CloseIcon />
                </span>
              </div>
            ) : null}
          </div>
        </div>

        <div className={styles.rightSection}>
          {(userRole === "ttp_administrator" ||
            userRole === "council_administrator") && (
            <div className={styles.newSolutionButton}>
              <Button
                icon="icn-add"
                onClick={handleAddSolutionModal}
                btn={BtnType.REGULAR}
                className={styles.addCompanyButton}
              >
                New Solution
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default SolutionsMainTopActions;

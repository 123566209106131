import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import { Pagination, Loading } from "../../../primitives";
import styles from "../projectsMain.module.scss";
import "rc-pagination/assets/index.css";
import CatalogProjectRowComponent from "../projectRow/catalogProjectRow.component";
import TableHeaderComponent from "./tableHeader.component";

const CatalogTabComponent = ({
  selectedTab,
  makeSortingConfig,
  drawSortingIcon,
  pageSetting,
  handlePagingChange,
  customStub,
}) => {
  const history = useHistory();
  const {
    projectsReducer: {
      projectsMainReducer: { projectList, projectLoading },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  return (
    <div className={styles.container}>
      {projectLoading ? (
        <Loading />
      ) : (
        <div className={classNames(styles.grid, styles[`${selectedTab}`])}>
          <TableHeaderComponent
            makeSortingConfig={makeSortingConfig}
            drawSortingIcon={drawSortingIcon}
          />

          <div className={styles.gridBody}>
            {projectList[0] ? (
              projectList.map((project) => (
                <CatalogProjectRowComponent
                  key={project.id}
                  project={project}
                  tab={selectedTab}
                  selectedCouncil={selectedCouncil}
                  history={history}
                />
              ))
            ) : (
              <div className={styles.noProjects}>
                <p>
                  {customStub
                    ? "There are no projects that match the selected filters"
                    : "There are no projects"}
                  {". "}
                </p>
              </div>
            )}
          </div>
          <div
            className={`${styles.paginWrp} ${
              projectList.length < 10 && pageSetting?.current === 1
                ? "d-none"
                : ""
            }`}
          >
            <div className={styles.paginCon}>
              <Pagination {...pageSetting} onChange={handlePagingChange} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(CatalogTabComponent);

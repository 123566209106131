import React, { useState } from "react";
import { useTeam } from "../../../common/hooks";

const ProjectAccessComponent = (props) => {
  const { project } = props;
  const team = useTeam(project);

  const generateAccessType = () => {
    const { visibility, team_id } = project;
    let accessType = "";
    switch (visibility) {
      case "project_members":
        accessType = "Project Members";
        break;
      case "all":
        accessType = "All";
        break;
      case "team":
        accessType = () => team.getName();
        break;
      default:
        accessType = visibility;
        break;
    }
    return accessType;
  };

  return (
    <div>
      {project.visibility !== "team" ? generateAccessType() : team.getName()}
    </div>
  );
};

export default React.memo(ProjectAccessComponent);

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Field, FieldArray, Formik, ErrorMessage } from "formik";

import { BtnType, Button, CheckBox, CheckType, Label } from "modules/primitives";
import { ModalFooter } from "application/modal";
import { formInitialValues, validation } from "./constant";
import useModelPopup from "common/hooks/modelPopup.hook";
import { editProductDetails } from "modules/companies/product/store/product.actions";
import TextBoxcomponent from "modules/primitives/textbox/textbox.component";
import styles from "./productPricingAndReviews.module.scss";
import Icon from "../../../../../../common/icon/icon.component";
import { mdSize } from "../../../../../../common/icon";
import { currencies, currencySymbol } from "common/popups/newItem/newNumber/constant";
import { extractCurrencyAndValue } from "../../../../../../common/helper";

const defaultRow = {
  isNew: true,
  name: "",
  url: "",
  given_rating: "",
  touched: true,
};

const EditProductPricingAndReviews = ({ product, instance }) => {
  const popup = useModelPopup();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(formInitialValues);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [currency, setCurrency] = useState("USD");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (product) {
      if (product.price?.length) {
        const priceAndCurrency = extractCurrencyAndValue(product.price);
        const getCurrencyObj = currencySymbol.find(c => c.symbol === priceAndCurrency.currencySymbol);

        setCurrency(getCurrencyObj.name || 'USD')

        setFormValues({
          ...product,
          price: priceAndCurrency.numericValue,
          originalReviews: product.reviews || [],
        });

        return;
      }

      setFormValues({
        ...product,
        originalReviews: product.reviews || [],
      });
    }
  }, [product]);

  const currencySelectedSymbol = () => currencySymbol.find(({ name }) => name === currency)?.symbol;

  const handleSave = (values) => {
    setShowLoading(true);

    const payload = {
      companyId: product.company.id,
      productId: product.id,
      data: {
        price: values.price ? `${currencySelectedSymbol()}${values.price}` : '',
        reviews: values.reviews.length !== product.reviews.length
          ? values.reviews
            .filter(r => r.url.length)
            .map(r => ({
              given_rating: r.given_rating,
              id: r.id,
              name: r.name,
              url: r.url,
            }))
          : product.reviews || [],
      },
    }

    dispatch(editProductDetails(payload));
    popup.hide();
  };

  const handleCancelClick = () => {
    popup.hide();
  }

  const handleAddNewReview = (evt, values) => {
    evt.preventDefault();

    const reviews = [{...defaultRow, id: values.reviews.length + 1}, ...values.reviews];

    setFormValues({
      ...values,
      reviews,
    });
  };

  const handleFieldBlur = (review, evt, prop) => {
    const reviews = formValues.reviews.map((r) => {
      if (review.id === r.id || r.isNew) {
        return {
          ...r,
          [prop]: evt.target.value,
          touched: true,
        };
      }

      return r;
    });

    setFormValues({
      ...formValues,
      reviews,
    });
  };

  const handleCancel = (evt, review, setFieldValue, idx1) => {
    evt.preventDefault();

    if (errors[idx1]) {
      const newErrorsObj = errors;
      delete newErrorsObj[idx1];

      setErrors(newErrorsObj);
    }

    const reviews = formValues.reviews.map((r, idx) => {
      if (r.isNew) {
        return Object.keys(defaultRow).reduce((acc, key) => {
          if (key === "isNew") {
            return {
              ...acc,
              isNew: true,
            };
          }

          setFieldValue(key, "");

          return {
            ...acc,
            [key]: "",
          };
        }, {});
      }

      if (review.id === r.id) {
        const getReview = formValues.originalReviews.find(
          (orReview) => orReview.id === review.id,
        );

        return Object.keys(review).reduce((acc, key) => {
          const val = getReview[key];
          setFieldValue(`reviews.${idx}.${key}`, val);

          return {
            ...acc,
            [key]: val,
          };
        }, {});
      }

      return r;
    });

    setFormValues({
      ...formValues,
      reviews,
    });
  };

  const handleDelete = (review, idx) => {
    if (errors[idx]) {
      const newErrorsObj = errors;
      delete newErrorsObj[idx];

      setErrors(newErrorsObj);
    }

    if (review.isNew) {
      const reviews = formValues.reviews.filter((r) => !r.isNew);

      setFormValues({
        ...formValues,
        reviews,
      });

      return;
    }

    const reviews = formValues.reviews.filter((r) => r.id !== review.id);

    setFormValues({
      ...formValues,
      reviews,
    });
  };

  return (
    <div className={styles.pricingAndReviews}>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={formValues}
        onSubmit={handleSave}
        validationSchema={validation}
      >
        {({ values, handleSubmit, setFieldValue, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <div className="px-4">
              <div className="mt-3">
                {
                  instance === 'price' ? (
                    <>
                      <Label>Price Currency</Label>
                      <div className="d-flex">
                        {currencies.map((c) => (
                          <div className="d-flex align-items-center mr-4" key={c}>
                            <CheckBox
                              checkType={CheckType.BLUE}
                              isChecked={currency === c}
                              onChange={() => setCurrency(c)}
                            />
                            <span
                              className="small cursor-pointer"
                              onClick={() => setCurrency(c)}
                              style={{marginLeft: '-10px'}}
                            >
                              {c}
                            </span>
                          </div>
                        ))}
                      </div>
                      <div className="mt-3">
                        <Label>Product Price</Label>
                        <div className="d-flex align-items-baseline">
                    <span className="mr-2">
                      {currencySelectedSymbol()}
                    </span>
                          <TextBoxcomponent
                            disabled={showLoading}
                            type="number"
                            name="price"
                            placeholder="Product Price"
                            formProps={{
                              ...formikprops,
                              setFieldValue,
                              values,
                            }}
                            className="w-100"
                          />
                        </div>
                      </div>
                    </>
                  ) : null
                }
                {
                  instance === 'reviews' ? (
                    <>
                      <Label>Product Reviews</Label>
                      <FieldArray name="reviews">
                        <div className="d-flex flex-column mb-4">
                          <div className={styles.gridRow}>
                            <div className={styles.title}>Review Site</div>
                            <div className="d-flex flex-grow-1 mr-3">Link</div>
                            <div className={styles.amount}>Rating</div>
                            <div className={styles.right}>Actions</div>
                          </div>
                          {values?.reviews?.length
                            ? values?.reviews?.map((review, idx) => (
                              <div key={review.id || idx} className={styles.gridRow}>
                                <div className={styles.title}>
                                  <Field
                                    name={`reviews.${idx}.name`}
                                    className={styles.field}
                                    onBlur={(evt) => handleFieldBlur(review, evt, "name")}
                                  />
                                </div>
                                <div className="d-flex flex-grow-1 mr-3 flex-column">
                                  <Field
                                    name={`reviews.${idx}.url`}
                                    className={styles.field}
                                    onBlur={(evt) => handleFieldBlur(review, evt, "url")}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    className="small text-danger"
                                    name={`reviews.${idx}.url`}
                                  />
                                </div>
                                <div className={styles.amount}>
                                  <Field
                                    name={`reviews.${idx}.given_rating`}
                                    className={styles.field}
                                    onBlur={(evt) => handleFieldBlur(review, evt, "given_rating")}
                                  />
                                </div>
                                <div className={styles.right}>
                                  {review.touched && (
                                    <>
                                      <Button
                                        type="button"
                                        btn={BtnType.FRAME_LESS}
                                        className="mr-2"
                                        onClick={(evt) => handleCancel(evt, review, setFieldValue, idx)}
                                      >
                                        Cancel
                                      </Button>
                                    </>
                                  )}
                                  <div
                                    className="d-flex ml-3"
                                    onClick={() => handleDelete(review, idx)}
                                  >
                                    <Icon {...mdSize} icon="icn-button-delete" />
                                  </div>
                                </div>
                              </div>
                            ))
                            : null}
                          <div className="d-flex justify-content-end mt-3">
                            <Button btn={BtnType.REGULAR} onClick={(evt) => handleAddNewReview(evt, values)}>
                              New Review
                            </Button>
                          </div>
                        </div>
                      </FieldArray>
                    </>
                  ) : null
                }
              </div>
            </div>
            <ModalFooter>
              <Button
                className="mr-3"
                btn={BtnType.FRAME_LESS}
                onClick={handleCancelClick}
                disabled={showLoading}
              >
                Cancel
              </Button>
              <Button
                disabled={showLoading}
                type="submit"
                onClick={handleSubmit}
                btn={BtnType.REGULAR}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditProductPricingAndReviews;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";

import mainStyles from "../../../ideasWrapper.module.scss";
import styles from "./comments.module.scss";
import AddCommentIcon from "../../../../../assets/icn-table-add.svg";
import AddIdeaComment from "./addComment";
import { appModelSettingSet } from "../../../../../application/app.action";
import { UserInline, UserInlineTypes } from "../../../../primitives";
import { Icon, smSize } from "../../../../../common/icon";
import ConfirmActionComponent from "../../../../../common/components/confirmAction/confirmAction.component";
import { deleteIdeaCommentAttempt } from "../../../idea.action";

const IdeaProfileComments = ({ selectedIdea, canEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    appReducer: { modalSetting },
  } = useSelector((state) => state);

  const handleAddComment = () => {
    const customSettings = {
      title: "Add new comment",
      show: true,
      height: "300",
      width: "540",
      component: <AddIdeaComment selectedIdea={selectedIdea} />,
    };

    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  const handleUserRedirect = (userId) => {
    if (userId) {
      history.push(`/people/internal/profile/${userId}`);
    }
  };

  const deleteComment = (comment) => {
    const customSettings = {
      title: "Delete Comment",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmActionComponent
          alertText="Are you sure?"
          onConfirm={() => handleCommentDeleteConfirm(comment)}
          confirmBtnName="Delete"
        />
      ),
    };
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  const handleCommentDeleteConfirm = (comment) => {
    const payload = {
      comment: {
        ...comment,
        idea_id: selectedIdea.id,
      },
      cb: () => {
        enqueueSnackbar("Successfully deleted comment", {
          variant: "success",
        });
      },
      enqueueSnackbar,
    };

    dispatch(deleteIdeaCommentAttempt(payload));
  };

  return (
    <div className="d-flex flex-column mt-4">
      <div
        className={`d-flex align-items-center justify-content-between ${mainStyles.blockHeader}`}
      >
        <span className={mainStyles.blockTitle}>Comments</span>
        <img
          className={mainStyles.addIcon}
          onClick={handleAddComment}
          src={AddCommentIcon}
          alt="Add new comment"
        />
      </div>
      <div className={`${styles.commentsContent} ${mainStyles.blockContent}`}>
        {!selectedIdea?.ideas_comments?.length
          ? "No comments yet."
          : selectedIdea?.ideas_comments?.map((c) => (
              <div key={c.ideas_comment_id} className={styles.comment}>
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <div onClick={() => handleUserRedirect(c.user.user_id)}>
                    <UserInline
                      userId={c.user.user_id}
                      first={c.user.first_name}
                      last={c.user.last_name}
                      avatar={c.user.avatar}
                      local
                      noNavigation
                      type={UserInlineTypes.WITH_NAME}
                      className="boldName"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="text-muted small">
                      {format(parseISO(c.created_at), "eeee, MMM do, yyyy, p")}
                    </span>
                    {canEdit && (
                      <Icon
                        {...smSize}
                        icon="icn-button-delete"
                        className={styles.deleteIcon}
                        onClick={() => deleteComment(c)}
                      />
                    )}
                  </div>
                </div>
                <span>{c.comment}</span>
              </div>
            ))}
      </div>
    </div>
  );
};

export default React.memo(IdeaProfileComments);

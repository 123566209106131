import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";

import {
  getKPIsNrsData,
  getKPIsTableData,
  toggleKPIsTable,
} from "modules/reporting/fordReports/kpis/store/kpis.actions";
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import LoadingComponent from "modules/primitives/loading/loading.component";
import styles from './styles.module.scss';
import genericStyles from '../../styles.module.scss';

const FordProjectNumbers = ({ nrsData, filters, tableData, downloadIsActive }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!nrsData.data) {
      const makeFilters = makeActualScoreCardFilterData(filters);
      const payload = {
        enqueueSnackbar,
        data: makeFilters,
      };

      dispatch(getKPIsNrsData(payload))
    }
  }, []);

  const cardHasExpandedTable = useCallback((key) => tableData?.expandedKey === key, [tableData?.expandedKey]);

  const handleShowOrHideTable = useCallback((key) => {
    if (tableData?.expandedKey !== key) {
      const filtersData = makeActualScoreCardFilterData(filters);
      dispatch(getKPIsTableData({
        reducerKey: key,
        data: {
          ...filtersData,
          section: key,
        },
      }));

      return;
    }

    dispatch(toggleKPIsTable({
      reducerKey: key,
    }));
  }, [tableData, filters]);

  const makeColumnCardStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      border: '1px solid #cbd2d9',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px',
      height: '100%',
      padding: '10px',
    }
  }, [downloadIsActive]);

  const makeSectionNameStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      color: '#616e7c',
      fontSize: '18px',
      fontFamily: 'lato-regular',
    }
  }, [downloadIsActive]);

  const makeValueBigStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      color: '#166086',
      fontSize: '48px',
      fontFamily: 'lato-regular',
    }
  }, [downloadIsActive]);

  const makeValueMediumStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      color: '#166086',
      fontSize: '16px',
      fontFamily: 'lato-regular',
    }
  }, [downloadIsActive]);

  return (
    <div className={classNames('d-flex flex-column', styles.numbersWrapper)}>
      <div style={makeColumnCardStylesForPdf} className={classNames(genericStyles.columnCard, "align-items-center")}>
        {
          nrsData.fetching ? (
            <LoadingComponent customText="Getting Total Projects..." />
          ) : (
            <>
              <span style={makeSectionNameStylesForPdf} className={styles.sectionName}>Total Projects</span>
              <span style={makeValueMediumStylesForPdf} className={styles.medium}>{nrsData.data?.total_projects || "NA"}</span>
            </>
          )
        }
        {
          !downloadIsActive && (
            <div className="d-flex justify-content-center mt-1">
              <span className={genericStyles.showHideSourceData} onClick={() => handleShowOrHideTable("total_projects")}>
                {cardHasExpandedTable("total_projects") ? "HIDE SOURCE DATA" : "SHOW SOURCE DATA"}
              </span>
            </div>
          )
        }
      </div>
      <div className="d-flex h-100">
        <div style={makeColumnCardStylesForPdf} className={classNames(genericStyles.columnCard, "w-50 mr-1 align-items-center")}>
          {
            nrsData.fetching ? (
              <LoadingComponent customText="Getting AVG TARR (%)..." />
            ) : (
              <>
                <span style={makeSectionNameStylesForPdf} className={styles.sectionName}>AVG TARR (%)</span>
                <span style={makeValueBigStylesForPdf} className={styles.big}>
                  {`${nrsData.data?.avg_tar?.main_value}${nrsData.data?.avg_tar?.main_value ? "%" : ""}` || "NA"}
                </span>
              </>
            )
          }
          {
            !downloadIsActive && (
              <div className="d-flex justify-content-center mt-1">
                <span className={genericStyles.showHideSourceData} onClick={() => handleShowOrHideTable("avg_tar")}>
                  {cardHasExpandedTable("avg_tar") ? "HIDE SOURCE DATA" : "SHOW SOURCE DATA"}
                </span>
              </div>
            )
          }
        </div>
        <div style={makeColumnCardStylesForPdf} className={classNames(genericStyles.columnCard, "w-50 ml-1 align-items-center")}>
          {
            nrsData.fetching ? (
              <LoadingComponent customText="Getting Replications..." />
            ) : (
              <>
                <span style={makeSectionNameStylesForPdf} className={styles.sectionName}>Replications</span>
                <span style={makeValueBigStylesForPdf} className={styles.big}>{nrsData.data?.total_replications || "NA"}</span>
              </>
            )
          }
          {
            !downloadIsActive && (
              <div className="d-flex justify-content-center mt-1">
                <span className={genericStyles.showHideSourceData}
                      onClick={() => handleShowOrHideTable("total_replications")}>
                  {cardHasExpandedTable("total_replications") ? "HIDE SOURCE DATA" : "SHOW SOURCE DATA"}
                </span>
              </div>
            )
          }
        </div>
      </div>
      <div className="d-flex h-100">
        <div style={makeColumnCardStylesForPdf} className={classNames(genericStyles.columnCard, "w-50 mr-1 align-items-center mb-0")}>
          {
            nrsData.fetching ? (
              <LoadingComponent customText="Getting Investment ($)..." />
            ) : (
              <>
                <span style={makeSectionNameStylesForPdf} className={styles.sectionName}>Investment ($)</span>
                <span style={makeValueBigStylesForPdf} className={styles.big}>
                  {nrsData.data?.total_investments?.main_value ? "$" : ""}
                  {`${nrsData.data?.total_investments?.main_value}${nrsData.data?.total_investments?.main_value ? "M" : ""}` || "NA"}
                </span>
              </>
            )
          }
          {
            !downloadIsActive && (
              <div className="d-flex justify-content-center mt-1">
                <span className={genericStyles.showHideSourceData} onClick={() => handleShowOrHideTable("investments")}>
                  {cardHasExpandedTable("investments") ? "HIDE SOURCE DATA" : "SHOW SOURCE DATA"}
                </span>
              </div>
            )
          }
        </div>
        <div style={makeColumnCardStylesForPdf} className={classNames(genericStyles.columnCard, "w-50 ml-1 align-items-center mb-0")}>
          {
            nrsData.fetching ? (
              <LoadingComponent customText="Getting Savings ($)..." />
            ) : (
              <>
                <span style={makeSectionNameStylesForPdf} className={styles.sectionName}>Savings ($)</span>
                <span style={makeValueBigStylesForPdf} className={styles.big}>
                  {nrsData.data?.total_savings?.main_value ? "$" : ""}
                  {`${nrsData.data?.total_savings?.main_value}${nrsData.data?.total_savings?.main_value ? "M" : ""}` || "NA"}
                </span>
              </>
            )
          }
          {
            !downloadIsActive && (
              <div className="d-flex justify-content-center mt-1">
                <span className={genericStyles.showHideSourceData} onClick={() => handleShowOrHideTable("savings")}>
                  {cardHasExpandedTable("savings") ? "HIDE SOURCE DATA" : "SHOW SOURCE DATA"}
                </span>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default React.memo(FordProjectNumbers);

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";

import styles from '../styles.module.scss';
import { Label, DropSelect } from "modules/primitives";
import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";

const FordScoreCardPlantFilter = ({ handleFilterUpdate, reducer, setNewFilters }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    adminPlantsAndAreasReducer: { plantsAndAreas },
  } = useSelector((state) => state);

  const [selectedPlants, setSelectedPlants] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState(reducer.filters?.areas || []);

  useEffect(() => {
    setSelectedPlants(reducer.filters?.plants || []);
  }, [reducer.filters?.plants]);

  useEffect(() => {
    if (!plantsAndAreas.length) {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        })
      );
    }
  }, [plantsAndAreas]);

  const getPlantsAsOptions = useMemo(() => {
    return plantsAndAreas.map(plant => ({
      id: plant.id,
      name: plant.attributes.name,
      areas: plant.attributes.areas,
    }));
  }, [plantsAndAreas]);

  const makeAreas = useMemo(() => {
    if (!selectedPlants?.length) return []
    return selectedPlants.reduce((acc, plant) => [...acc, ...plant.areas], []);
  }, [selectedPlants]);

  const updateFilters = useCallback((value) => {
    setNewFilters({
      ...reducer.filters,
      plants: value,
    });

    handleFilterUpdate({plants: value}, reducer.tableData?.expandedKey);
  }, [reducer, handleFilterUpdate]);

  const handlePlantsChange = (value) => {
    setSelectedPlants(value.obj);
    updateFilters(value.obj);
  };

  const handleAreasChange = (value) => {
    setSelectedAreas(value.obj);
    setNewFilters({
      ...reducer.filters,
      areas: value.obj,
    });
  }

  return (
    <div className="d-flex flex-row">
      <div className={classNames(styles.filter, 'd-flex flex-column')}>
        <Label>Plant</Label>
        <DropSelect
          multi
          closeOnSelect
          values={selectedPlants}
          onChange={handlePlantsChange}
          options={getPlantsAsOptions}
          labelField="name"
          valueField="id"
          placeholder="Plants"
          searchBy="name"
        />
      </div>
      {/*{*/}
      {/*  makeAreas?.length > 0 ? (*/}
      {/*    <div className={classNames(styles.filter, 'd-flex flex-column')}>*/}
      {/*      <Label>Areas</Label>*/}
      {/*      <DropSelect*/}
      {/*        multi*/}
      {/*        closeOnSelect*/}
      {/*        values={selectedAreas}*/}
      {/*        onChange={handleAreasChange}*/}
      {/*        options={makeAreas}*/}
      {/*        labelField="area_name"*/}
      {/*        valueField="area_id"*/}
      {/*        placeholder="Areas"*/}
      {/*        searchBy="area_name"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  ) : null*/}
      {/*}*/}
    </div>
  );
};

export default React.memo(FordScoreCardPlantFilter);

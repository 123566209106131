import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import {
  getKPIsNextPhaseChange,
  getKPIsTableData,
  toggleKPIsTable,
} from "modules/reporting/fordReports/kpis/store/kpis.actions";
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import LoadingComponent from "modules/primitives/loading/loading.component";
import styles from './styles.module.scss';
import genericStyles from '../../styles.module.scss';
import { rangeToPercent } from "modules/reporting/utils";
import { CHART_COLORS } from "modules/companies/companyMain/chartsWrapper/chartsView/charts-constants";

const FordNextPhase = ({ nextPhaseChange, filters, tableData, downloadIsActive }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!nextPhaseChange.data) {
      const makeFilters = makeActualScoreCardFilterData(filters);
      const payload = {
        enqueueSnackbar,
        data: makeFilters,
      };

      dispatch(getKPIsNextPhaseChange(payload))
    }
  }, []);

  const cardHasExpandedTable = useMemo(() => tableData?.expandedKey === 'projects_by_next_stage_change',
    [tableData?.expandedKey]);

  const handleShowOrHideTable = useCallback(() => {
    if (tableData?.expandedKey !== 'projects_by_next_stage_change') {
      const filtersData = makeActualScoreCardFilterData(filters);
      dispatch(getKPIsTableData({
        reducerKey: 'projects_by_next_stage_change',
        data: {
          ...filtersData,
          section: 'projects_by_next_stage_change',
        },
      }));

      return;
    }

    dispatch(toggleKPIsTable({
      reducerKey: 'projects_by_next_stage_change',
    }));
  }, [tableData, filters]);

  const makeColumnCardStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      border: '1px solid #cbd2d9',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px',
      height: '100%',
      padding: '10px',
    }
  }, [downloadIsActive]);

  const makeTitleStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      color: '#616e7c',
      fontSize: '18px',
      fontFamily: 'lato-regular',
      paddingTop: '10px',
    }
  }, [downloadIsActive]);

  return (
    <div style={makeColumnCardStylesForPdf} className={genericStyles.columnCard}>
      <span style={makeTitleStylesForPdf} className={genericStyles.columnCardTitle}>Next Stage Change Quarter</span>
      {
        nextPhaseChange.fetching ? (
          <LoadingComponent customText="Getting Data..." />
        ) : (
          <div className={styles.horizontalBarsWrapper}>
            {
              nextPhaseChange.data?.map((el, idx) => {
                const width = rangeToPercent(el);

                return (
                  <div
                    key={el.name}
                    className="d-flex align-items-center mb-1"
                  >
                    <div className={`${styles.barName}`}>
                      {el.name}
                    </div>
                    <div className="d-flex align-items-center w-100">
                      <div
                        className={`${width === 0 ? 'p-0' : ''} ${styles.bar}`}
                        style={{ width: `${width}%`, backgroundColor: CHART_COLORS[idx % CHART_COLORS.length] }}
                      >
                        {
                          width >= 20
                            ? el.value
                            : ''
                        }
                      </div>
                      {width < 20 ? <span className="ml-2">{el.value}</span> : null}
                    </div>
                  </div>
                )
              })
            }
            {
              !downloadIsActive && (
                <div className="d-flex justify-content-center mt-3">
                  <span className={genericStyles.showHideSourceData} onClick={() => handleShowOrHideTable()}>
                    {cardHasExpandedTable ? 'HIDE SOURCE DATA' : 'SHOW SOURCE DATA'}
                  </span>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default React.memo(FordNextPhase);

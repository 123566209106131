import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  PROJECT_USERS_GET_ATTEMPT,
  projectUsersGetSuccess,
  PROJECT_USERS_ADD_ATTEMPT,
  projectUsersAddSuccess,
  PROJECT_MULTIPLE_USERS_ADD_ATTEMPT,
  projectMultipleUsersAddSuccess,
} from "./user.action";
import {
  httpGet,
  httpPost,
  queryString,
  errorHandler,
} from "../../../../common/httpCall";

export const epicGetProjectUsers = (action$, state$) => action$.pipe(
  ofType(PROJECT_USERS_GET_ATTEMPT),
  switchMap(({ payload: { projectId, councilId, enqueueSnackbar } }) => httpGet({
    call: `projects/${projectId}/members?${queryString({
      council_id: councilId,
    })}`, // NEW_API`,
  }).pipe(
    map((result) => projectUsersGetSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicAddProjectUsers = (action$, state$) => action$.pipe(
  ofType(PROJECT_USERS_ADD_ATTEMPT),
  switchMap(
    ({
      payload: {
        data, projectId, memberId, role, enqueueSnackbar,
      },
    }) => httpPost({
      call: `projects/${projectId}/members`, // NEW_API`,
      data: {
        project_member: {
          project_id: projectId,
          active: true,
          user_id: memberId,
          role,
          external_project_member_id: data?.external_member_id,
        },
      },
    }).pipe(
      map((result) => {
        enqueueSnackbar("External Project Member successfully added.", {
          variant: "success",
        });

        return projectUsersAddSuccess(result);
      }),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

export const epicAddMultipleProjectUsers = (action$, state$) => action$.pipe(
  ofType(PROJECT_MULTIPLE_USERS_ADD_ATTEMPT),
  switchMap(
    ({
      payload: {
        projectId, project_members, role, enqueueSnackbar,
      },
    }) => httpPost({
      call: `projects/${projectId}/members/batch`,
      data: {
        project_members,
        role,
      },
    }).pipe(
      map((result) => {
        enqueueSnackbar("Project Member successfully added.", {
          variant: "success",
        });
        return projectMultipleUsersAddSuccess(result);
      }),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

const userEpic = combineEpics(
  epicGetProjectUsers,
  epicAddProjectUsers,
  epicAddMultipleProjectUsers,
);

export default userEpic;

import React, { useRef, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  Loading,
  Label,
  TextBox,
  Button,
  BtnType,
} from "modules/primitives/index";
import { generateRandomIdByDate, truncateString } from "common/helper";
import Tooltip from "@mui/material/Tooltip";
import styles from "./documentModal.module.scss";
import { ModalBody, ModalFooter } from "../../../../../application/modal";
import { useModelPopup } from "../../../../../common/hooks";
import useS3FileUpload from "../../../../../common/hooks/s3FileUplodar.hook";
import { Icon, mdSize } from "../../../../../common/icon";
import {
  postTaskDocumentAttempt,
  patchTaskDocNameAttempt,
} from "../../adminProjects.actions";
import LinearProgressWithLabel from "../../../../../common/components/linearProgressWithLabel";

const DocumentModal = (props) => {
  const { task } = props;

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    adminProjectsReducer: {
      tasks,
      loading,
    },
  } = useSelector((state) => state);
  const uploadBlockRef = useRef(null);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const [uploadValues, setUploadValues] = useState([]);
  const { fileUpload, fileDelete } = useS3FileUpload();
  const [documents, setDocuments] = useState([]);
  const [isDeleting, setIsDeleting] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [documentToUpload, setDocumentToUpload] = useState("");
  const [shownDocuments, setShownDocuments] = useState("");
  const [editingDocName, setEditingDocName] = useState("");
  const [editingDocId, setEditingDocId] = useState("");
  const [fileError, setFileError] = useState("");
  const [fileNativeName, setFileNativeName] = useState("");

  const popup = useModelPopup();
  const dispatch = useDispatch();
  const [loadingDoc, setLoading] = useState(false);
  const inputRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (task.documents?.length && tasks.length) {
      const foundTask = tasks.find(
        (el) => el.council_task_template_id === task.council_task_template_id,
      );
      setDocuments(foundTask.documents);
    } else {
      setDocuments([]);
    }
  }, [tasks, task]);

  const handleDismiss = () => {
    popup.hide();
  };

  const handleOpenLink = (link) => {
    window.open(link);
  };

  const handleDeleteDoc = (docId, docName) => {
    const data = {
      name: task.name,
      councilId: selectedCouncil?.id,
      enqueueSnackbar,
      taskId: task.council_task_template_id,
      documents: [
        {
          id: docId,
          name: docName,
          _destroy: true,
        },
      ],
    };

    dispatch(patchTaskDocNameAttempt(data));
  };

  const handleDocNameSave = () => {
    const data = {
      name: task.name,
      councilId: selectedCouncil?.id,
      enqueueSnackbar,
      taskId: task.council_task_template_id,
      documents: [
        {
          id: editingDocId,
          name: editingDocName,
        },
      ],
    };

    dispatch(patchTaskDocNameAttempt(data));
    setEditingDocId("");
  };

  const handleAddClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const uploadHandler = async (e, action) => {
    uploadBlockRef.current && uploadBlockRef.current.blur();
    e.preventDefault();
    e.stopPropagation();
    e.persist();

    if (forbiddenFiles?.length) {
      setForbiddenFiles([]);
    }

    if (action === "drag" && uploadBlockRef?.current) {
      uploadBlockRef.current.style.border = "1px solid #7b8794";
      return;
    }

    let files;

    if (action === "click") {
      if (!e || !e?.target?.files?.length) {
        return;
      }
      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      uploadBlockRef.current.style.border = "1px solid #e4e7eb";

      if (!e || !e?.dataTransfer?.items?.length) {
        return;
      }
      files = Array.from(e.dataTransfer.files);
    }

    if (!files) {
      return;
    }

    if (files.length > 1) {
      setFileError("You can add one file at ones");
      return;
    }
    const fileList = [];
    let dismissedFiles = 0;
    setFileError("");
    setLoading(true);
    setUploadValues((items) => {
      const currentFiles = [...items];

      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name,
      }));
      return [...currentFiles, ...newFiles];
    });

    files.forEach(async (file) => {
      if (file.size <= 20971520) {
        const pathname = `srm/${selectedCouncil?.id}/${
          task?.council_task_template_id
        }/${generateRandomIdByDate()}/docs`;
        await fileUpload(file, pathname, setUploadValues, file.name).then(
          (res) => {
            if (res && res.location) {
              if (documents?.indexOf(res.location) === -1) {
                fileList.push(res.location);
                setLoading(false);
                if (fileList?.length === files?.length - dismissedFiles) {
                  setDocumentToUpload(res.location);
                  setShownDocuments(res.location);
                  setFileNativeName(file.name);
                }
              } else {
                enqueueSnackbar("This document was already added.", {
                  variant: "error",
                });
                setDocumentToUpload(null);
                setShownDocuments(null);
                setLoading(false);
                dismissedFiles++;
                setForbiddenFiles((prevFiles) => {
                  const localFiles = [...prevFiles];
                  localFiles.push({
                    name: file.name,
                    error: "Document already added.",
                  });
                  return localFiles;
                });
                setUploadValues((prevState) => {
                  const currentProgress = [...prevState];
                  const index = currentProgress
                    .map((item) => item.name)
                    .lastIndexOf(file.name);
                  currentProgress[index] = {
                    progress: "NOT_ALLOWED",
                    name: file.name,
                  };
                  return currentProgress;
                });
              }
            }
          },
        );
      } else {
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === file.name,
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: file.name,
          };
          return currentProgress;
        });
        setLoading(false);
        if (file.size > 10000000) {
          dismissedFiles++;
          setLoading(false);
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error: "Your file is too large. File size limit: 100MB",
            });
            return localFiles;
          });
          enqueueSnackbar(
            "We could not upload your document because your file size is too big. Please make sure the file is less than 20 MB",
            {
              variant: "error",
            },
          );
        }
      }
    });

    uploadBlockRef.current && uploadBlockRef.current.blur();
    e.target.value = "";
  };

  const onSubmitForm = () => {
    if (documentToUpload) {
      const data = {
        document: {
          name: documentName || fileNativeName,
          description: "",
          added_by_id: session.id,
          uri: documentToUpload,
          document_resource_type: "CouncilTaskTemplate",
          document_resource_id: task.council_task_template_id,
        },
        enqueueSnackbar,
      };

      dispatch(postTaskDocumentAttempt(data));
    }
    popup.hide();
  };

  return (
    <div className={styles.modalContainer}>
      <ModalBody>
        <Label>Title</Label>
        <TextBox
          type="text"
          placeholder="Enter document title here"
          value={documentName}
          onChange={(e) => setDocumentName(e.target.value)}
          className={styles.textBox}
        />

        <Label>Upload document</Label>
        <input
          ref={inputRef}
          type="file"
          style={{ display: "none" }}
          onChange={(e) => uploadHandler(e, "click")}
          multiple
        />
        <p className={styles.instructionText}>
          Documents accept all file formats (.pdf, .xls, .pptx, .doc, etc.)
        </p>

        {uploadValues?.length
          ? uploadValues.map(
            (bar, index) => bar
              && bar?.progress !== "NOT_ALLOWED" && (
                <div className={styles.loaderContainer}>
                  <div className={styles.loaderNamingWrp}>
                    {" "}
                    <div className={styles.fileName}>
                      {uploadValues[index]?.name}
                    </div>
                    <LinearProgressWithLabel
                      mode={
                        uploadValues[index]?.progress
                        === "FAILED" && "error"
                      }
                      value={
                        uploadValues[index]?.progress === "FAILED"
                          ? 100
                          : uploadValues[index]?.progress || 0
                      }
                    />
                  </div>
                </div>
            ),
          )
          : null}

        <div
          className={styles.dragDropWrp}
          ref={uploadBlockRef}
          onDrop={(e) => uploadHandler(e, "drop")}
          onDragOver={(e) => uploadHandler(e, "drag")}
          onClick={(e) => handleAddClick(e)}
        >
          Drag and drop documents here
        </div>

        <div className={styles.formElement}>
          <Button
            type="button"
            btn={loadingDoc ? BtnType.OUTLINE : BtnType.FRAME_LESS}
            disabled={loadingDoc}
            className={loadingDoc && styles.disabledButton}
            onClick={handleAddClick}
          >
            Choose file
          </Button>
        </div>

        {fileError && (
          <span className={styles.errorText}>
            {" "}
            <sup>*</sup> {fileError}
          </span>
        )}

        {forbiddenFiles?.length ? (
          <div className={styles.forbiddenfileContainer}>
            Files that weren't allowed:{" "}
            {forbiddenFiles.map((file) => (
              <span className={styles.forbiddenfileName}>
                {`${file.name}: `}
                <span className={styles.errorText}> {file.error}</span>
              </span>
            ))}
          </div>
        ) : null}

        {shownDocuments && (
          <div className={styles.inputWrapper}>
            <h6 className={styles.label}>
              Pending
            </h6>
            <div className={styles.item}>
              <div className={styles.name}>
                {" "}
                {documentName || truncateString(fileNativeName, 30)}
              </div>
              <div className={styles.delete}>
                <Button
                  btn={BtnType.LINK}
                  onClick={() => handleOpenLink(shownDocuments)}
                >
                  View
                </Button>
                <Icon
                  {...mdSize}
                  icon="icn-button-delete"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setDocumentToUpload(null);
                    setShownDocuments(null);
                  }}
                />
              </div>
            </div>
            <hr style={{ marginBottom: 10 }} />
          </div>
        )}

        <div className={styles.inputWrapper}>
          {documents && Boolean(documents?.length) && (
            <h6 className={styles.label}>Already uploaded</h6>
          )}
          {documents && Boolean(documents?.length) && !loading ? (
            documents.map((doc, index) => (isDeleting === index ? (
              <Loading />
            ) : (
              <div className={styles.item}>
                {editingDocId === doc.id ? (
                  <TextBox
                    type="text"
                    placeholder="Enter document title here"
                    value={editingDocName}
                    onChange={(e) => setEditingDocName(e.target.value)}
                    className={styles.textBox}
                  />
                ) : (
                  <div className={styles.name}>
                    {doc.name || truncateString(doc.uri, 30)}
                  </div>
                )}

                <div className={styles.linkMenu}>
                  <div>
                    <Button
                      className={styles.navItem}
                      btn={BtnType.LINK}
                      onClick={() => handleOpenLink(doc.uri)}
                    >
                      View
                    </Button>
                  </div>
                  {editingDocId === doc.id ? (
                    <div className={styles.navItem}>
                      <Button
                        btn={BtnType.LINK}
                        onClick={() => handleDocNameSave(doc.uri)}
                      >
                        Save
                      </Button>
                    </div>
                  ) : (
                    <div className={styles.navItem}>
                      <Icon
                        {...mdSize}
                        className={styles.editButton}
                        icon="icn-edit"
                        onClick={() => {
                          setEditingDocName(doc.name);
                          setEditingDocId(doc.id);
                        }}
                      />
                    </div>
                  )}
                  <Icon
                    {...mdSize}
                    icon="icn-button-delete"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDeleteDoc(doc.id, doc.name);
                    }}
                  />
                </div>
              </div>
            )))
          ) : (
            <>{loading && <Loading />}</>
          )}
        </div>
      </ModalBody>

      <ModalFooter>
        <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
          Cancel
        </Button>
        {editingDocId ? (
          <Tooltip
            title="You are in edit mode, one of file is in edit state, finish action to continue."
            placement="top"
          >
            <div>
              <Button
                btn={BtnType.DISABLED}
                className={styles.button}
                icon="icn-edit"
              >
                Save
              </Button>
            </div>
          </Tooltip>
        ) : (
          <Button
            disabled={editingDocId}
            onClick={() => onSubmitForm()}
            btn={BtnType.HIGHLIGHT}
            className={styles.button}
          >
            Save
          </Button>
        )}
      </ModalFooter>
    </div>
  );
};

export default DocumentModal;

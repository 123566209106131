import * as Yup from "yup";
import { OptionType } from "modules/primitives";

export const initModel = {
  name: "",
  description: "",
};

export const validation = Yup.object().shape({
  name: Yup.string().required("Stage name is required"),
});

export const stageCompanyOptions = [
  { val: "view", text: "View profile" },
  { val: "rate", text: "Rate Now" },
  // { val: 'assign', text: 'Assign for Research' },
  { val: "remove", text: "Remove", type: OptionType.DANGER },
];

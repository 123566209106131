import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./pagination.module.scss";
import { Icon, smSize } from "../../../common/icon";

const Pagination = (props) => {
  const {
    pageSize = 10,
    current = 1,
    hideOnSinglePage = true,
    showTitle = false,
    total = 0,
    onChange = null,
    hideLastAndFirst,
  } = props;

  const [rangeStart, setRangeStart] = useState(0);
  const [rangeEnd, setRangeEnd] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [pages, setPages] = useState(1);

  useEffect(() => {
    setCurrentPage(current);
  }, [current]);

  useEffect(() => {
    setRange();
  }, [currentPage, pageSize, totalRecords]);

  useEffect(() => {
    setTotalRecords(total);
  }, [total]);

  // commenting this cause it causes problems
  // it shouldn't call onChange when component has been initialized

  // useEffect(() => {
  //   if (onChange) {
  //     onChange(currentPage);
  //   }
  // }, [currentPage]);

  useEffect(() => {
    if (totalRecords && pageSize) {
      const totPages = Math.ceil(totalRecords / pageSize);
      setPages(totPages);
    }
  }, [totalRecords, pageSize]);

  const setRange = () => {
    setRangeStart((currentPage - 1) * pageSize + 1);
    setRangeEnd(
      currentPage * pageSize > totalRecords
        ? totalRecords
        : currentPage * pageSize,
    );
  };

  const handleButtonClick = (btn) => {
    switch (btn) {
      case "first":
        onChange(1);
        setCurrentPage(1);
        break;
      case "prev":
        if (currentPage > 1) {
          onChange(currentPage - 1);
          setCurrentPage((prev) => prev - 1);
        }
        break;
      case "next":
        if (currentPage < pages) {
          onChange(currentPage + 1);
          setCurrentPage((prev) => prev + 1);
        }
        break;
      case "last":
        onChange(pages);
        setCurrentPage(pages);
        break;
      default:
        break;
    }
  };
  return (
    <div className={styles.paginWrp}>
      {total && Number(total) ? (
        <div className={styles.pageCount}>
          {pages === 1 || totalRecords === 0
            ? totalRecords
            : pageSize > 1
              ? `${rangeStart}-${rangeEnd}`
              : rangeStart}{" "}
          of {totalRecords}
        </div>
      ) : null}
      <div className={styles.pageCon}>
        {!hideLastAndFirst ? (
          <Icon
            {...smSize}
            icon="icn-left-first"
            className={classNames(
              styles.iconfirst,
              currentPage === 1 && styles.disabled,
              styles.cursorPointer,
            )}
            onClick={() => handleButtonClick("first")}
          />
        ) : null}
        <Icon
          {...smSize}
          icon="icn-left-prev"
          className={classNames(
            styles.iconprev,
            currentPage === 1 && styles.disabled,
            styles.cursorPointer,
          )}
          onClick={() => handleButtonClick("prev")}
        />
        <Icon
          {...smSize}
          icon="icn-right-next"
          className={classNames(
            styles.iconnext,
            (currentPage === pages && styles.disabled)
              || (totalRecords === 0 && styles.disabled),
            styles.cursorPointer,
          )}
          onClick={() => handleButtonClick("next")}
        />
        {!hideLastAndFirst ? (
          <Icon
            {...smSize}
            icon="icn-right-last"
            className={classNames(
              styles.iconlast,
              (currentPage === pages && styles.disabled)
                || (totalRecords === 0 && styles.disabled),
              styles.cursorPointer,
            )}
            onClick={() => handleButtonClick("last")}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Pagination;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip/Tooltip";

import { BtnType, Button, TabComponent } from "../../../primitives";
import styles from "./manage.module.scss";
import MembersComponent from "./members/index";
import DetailsComponent from "./details/index";
import RatingsComponent from "./ratings/index";
import CompaniesComponent from "./companies";
import ProjectFields from "modules/projects/project/manageProject/fields";
import { useQuery } from "../../../../common/helper";
import ConfirmActionComponent from "../../../../common/components/confirmAction/confirmAction.component";
import { useModelPopup } from "../../../../common/hooks";
import { projectDelete } from "modules/projects/project/project.action";

const ManageComponent = ({ pathname, handleOpenModal }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const tabUrlArr = history.location.pathname.split("/");
  const tabUrl = tabUrlArr[tabUrlArr.length - 1];

  const {
    councilReducer: { selectedCouncil },
    projectsReducer: {
      projectReducer: { selectedProject },
      companyReducer: { companyList, patientOrganizations },
    },
  } = useSelector((state) => state);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const goToFields = useQuery().get("goToFields");

  useEffect(() => {
    if (goToFields) {
      const timeout = setTimeout(() => {
        setSelectedIndex(4);
        clearTimeout(timeout);
      }, 200);
    }
  }, [goToFields]);

  const isGsk = selectedCouncil?.name === "GSK";
  const isPatientOrg =
    isGsk && selectedProject?.stage_template?.patient_organization;

  const isFordSupplyChainAutomation = useMemo(() => {
    return selectedCouncil?.name === 'Ford' && selectedProject?.stage_template?.name === 'Supply Chain Automation';
  }, [selectedCouncil, selectedProject]);

  const tabList = [
    {
      tab: "Details",
      name: "Details",
      component: (
        <DetailsComponent
          pathname={pathname}
          handleOpenModal={handleOpenModal}
          isPatientOrg={isPatientOrg}
        />
      ),
    },
    {
      tab: "Members",
      name: "Members",
      component: <MembersComponent pathname={pathname} />,
    },
    {
      tab: isFordSupplyChainAutomation ? "Partners" : "Companies",
      name: isFordSupplyChainAutomation ? "Partners" : isPatientOrg ? "Organizations" : "Companies",
      component: (
        <CompaniesComponent
          pathname={pathname}
          isGsk={isGsk}
          isPatientOrg={isPatientOrg}
          companyList={companyList}
          patientOrganizations={patientOrganizations}
        />
      ),
    },
    {
      tab: "Ratings",
      name: "Ratings",
      component: <RatingsComponent />,
    },
    {
      tab: "Fields",
      name: "Fields",
      component: <ProjectFields />,
    },
  ];
  const handleOnTabSelect = (index) => {
    setSelectedIndex(index);
    history.push(
      `/projects/${selectedProject.id}/${tabList[
        index
      ].tab.toLowerCase()}?editMode=true`
    );
  };

  useEffect(() => {
    if (tabUrl) {
      let tabIndex = 0;
      tabList.map((tab, i) => {
        if (tab.tab.toLowerCase() === tabUrl) {
          tabIndex = i;
        }
      });
      setSelectedIndex(tabIndex);
    }
  }, [tabUrl]);
  // temporary change https://tractiontechnology.atlassian.net/browse/V2-1398
  if (isPatientOrg) {
    tabList.splice(3, 1);
  }

  const handleDelete = async () => {
    const payload = {
      projectId: selectedProject.id,
      projectName: selectedProject.name,
      enqueueSnackbar,
      cb: (res) => {
        if (res) {
          const timeout = setTimeout(() => {
            enqueueSnackbar(
              `You deleted the Project: ${selectedProject.name}`,
              {
                variant: "success",
              }
            );

            history.push("/projects");
            clearTimeout(timeout);
          }, 1000);
        }
      },
    };

    dispatch(projectDelete(payload));
  };

  const handleDeleteClick = () => {
    popup.show({
      title: "You are about to permanently delete this project. ",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmActionComponent
          alertText="Are you sure you want to do that?"
          confirmBtnName="Delete"
          onConfirm={() => handleDelete()}
        />
      ),
    });
  };

  const handleGoBack = () => {
    history.push(`/projects/${selectedProject.id}`);
  };

  return (
    <div className={styles.manageWrp}>
      <div className={styles.tabsWrp}>
        <div className="d-flex align-items-center justify-content-between">
          <p className={styles.title}>Manage Project</p>
          <div className="d-flex align-items-center">
            <Button
              btn={BtnType.FRAME_LESS}
              className="mr-3"
              icon="icn-left-chevron"
              onClick={handleGoBack}
            >
              Back to Project
            </Button>
            {selectedCouncil?.name === "Ford" ? (
              <>
                {selectedProject["original"] &&
                selectedProject?.replications?.length > 0 ? (
                  <Tooltip
                    title="This project cannot be deleted until all related Replications have been deleted first."
                    arrow
                  >
                    <div className="mr-3">
                      <Button
                        btn={BtnType.FRAME_LESS}
                        rounded
                        icon="icn-button-delete"
                        className={styles.deleteBtn}
                        disabled
                      >
                        Delete Project
                      </Button>
                    </div>
                  </Tooltip>
                ) : (
                  <Button
                    btn={BtnType.FRAME_LESS}
                    rounded
                    icon="icn-button-delete"
                    className={styles.deleteBtn}
                    onClick={handleDeleteClick}
                  >
                    Delete Project
                  </Button>
                )}
              </>
            ) : (
              <Button
                btn={BtnType.FRAME_LESS}
                rounded
                icon="icn-button-delete"
                className={styles.deleteBtn}
                onClick={handleDeleteClick}
              >
                Delete Project
              </Button>
            )}
          </div>
        </div>
        <div className={styles.tabsContainer}>
          <TabComponent
            tabList={tabList}
            defaultIndex={selectedIndex}
            onSelect={(index) => handleOnTabSelect(index)}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ManageComponent);

import { ofType, combineEpics } from "redux-observable";
import {
  switchMap,
  map,
  groupBy,
  mergeMap,
  catchError,
} from "rxjs/operators";
import {
  httpGet,
  httpPut,
  httpPost,
  httpDelete,
  queryString,
  errorHandler,
} from "../../../common/httpCall";

import {
  EVENTS_GET_ATTEMPT,
  eventsGetSuccess,
  EVENTS_GET_SPEAKERS_ATTEMPT,
  eventsGetSpeakersSuccess,
  EVENTS_ATTENDING_ATTEMPT,
  eventsAttendingSuccess,
  EVENTS_GET_ATTENDING_ATTEMPT,
  eventsGetAttendingSuccess,
  EVENTS_REMOVE_ATTENDING_ATTEMPT,
  eventsRemoveAttendingSuccess,
  EVENTS_GET_ALL_ATTENDING_ATTEMPT,
  eventsGetAllAttendingSuccess,
  EVENTS_POST_ATTENDING_ATTEMPT,
  eventsPostSuccess,
  EVENTS_DELETE_ATTENDING_ATTEMPT,
  eventsDeleteSuccess,
  EVENTS_DELETE_EVENT_ATTEMPT,
  eventsDeleteEventSuccess,
  EVENTS_ADD_RATING_ATTEMPT,
  eventsAddRatingSuccess,
  EVENTS_ADD_SPEAKER_ATTEMPT,
  eventsAddSpeakerSuccess,
  EVENTS_REMOVE_SPEAKER_ATTEMPT,
  eventShareSuccess,
  EVENT_SHARE_ATTEMPT,
  EVENTS_PUT_ATTENDING_ATTEMPT,
} from "./viewEvent.action";

export const epicGetEvent = (action$, state$) => action$.pipe(
  ofType(EVENTS_GET_ATTEMPT),
  switchMap(({ payload: { eventId, enqueueSnackbar } }) => httpGet({
    call: `events/${eventId}`, // NEW_API
  }).pipe(
    map((result) => eventsGetSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetEventSpeakers = (action$, state$) => action$.pipe(
  ofType(EVENTS_GET_SPEAKERS_ATTEMPT),
  switchMap(
    ({
      payload: {
        eventId, pageSize, currentPage, enqueueSnackbar,
      },
    }) => httpGet({
      call: `events/${eventId}/speakers?${queryString({
        items: pageSize,
        page: currentPage,
      })}`, // NEW_API`,
    }).pipe(
      map((result) => eventsGetSpeakersSuccess({ eventId, data: result })),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

export const epicAttending = (action$, state$) => action$.pipe(
  ofType(EVENTS_ATTENDING_ATTEMPT),
  switchMap(({
    payload: {
      eventId, councilId, userId, enqueueSnackbar,
    },
  }) => httpPost({
    call: "attend/new", // OLD_API
    data: {
      attend_graph: {
        user_id: userId,
        event_id: eventId,
        council_id: councilId,
      },
    },
  }).pipe(
    map((result) => eventsAttendingSuccess(result.response)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicRemoveAttending = (action$, state$) => action$.pipe(
  ofType(EVENTS_REMOVE_ATTENDING_ATTEMPT),
  switchMap(({ payload: { eventId, enqueueSnackbar } }) => httpDelete({
    call: `api/v1/attend_graphs/${eventId}`, // OLD_API
  }).pipe(
    map((result) => eventsRemoveAttendingSuccess(result.response)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetEventAttendings = (action$, state$) => action$.pipe(
  ofType(EVENTS_GET_ATTENDING_ATTEMPT),
  groupBy(
    (action) => action.payload.eventId,
    (action) => action,
  ),
  mergeMap((group) => group.pipe(
    switchMap(
      ({
        payload: {
          eventId, pageSize, currentPage, enqueueSnackbar,
        },
      }) => httpGet({
        call: `events/${eventId}/attendees?items=${pageSize}&page=${currentPage}`,
      }).pipe(
        map((result) => eventsGetAttendingSuccess({ eventId, data: result })),
        catchError((err) => errorHandler(err, enqueueSnackbar)),
      ),
    ),
  )),
);

export const epicPostEventAttending = (action$, state$) => action$.pipe(
  ofType(EVENTS_POST_ATTENDING_ATTEMPT),
  switchMap(({ payload }) => httpPost({
    call: `events/${payload.eventId}/attendees`,
    data: {
      event_attendee: payload,
    },
  }).pipe(
    map((result) => eventsPostSuccess(result)),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicPutEventAttending = (action$, state$) => action$.pipe(
  ofType(EVENTS_PUT_ATTENDING_ATTEMPT),
  switchMap(({ payload }) => httpPut({
    call: `events/${payload.eventId}/attendees/${payload.event_attendee_id}`,
    data: {
      event_attendee: payload,
    },
  }).pipe(
    map((result) => eventsPostSuccess(result)),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicDeleteEventAttending = (action$, state$) => action$.pipe(
  ofType(EVENTS_DELETE_ATTENDING_ATTEMPT),
  switchMap(({ payload }) => httpDelete({
    call: `events/${payload.eventId}/attendees/${payload.event_attendee_id}`,
  }).pipe(
    map((result) => eventsDeleteSuccess(result)),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicGetAllAttending = (action$, state$) => action$.pipe(
  ofType(EVENTS_GET_ALL_ATTENDING_ATTEMPT),
  switchMap(({ payload }) => httpDelete({
    call: "attend/users/all", // OLD_API
  }).pipe(
    map((result) => eventsGetAllAttendingSuccess(result.response)),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicDeleteEvent = (action$, state$) => action$.pipe(
  ofType(EVENTS_DELETE_EVENT_ATTEMPT),
  switchMap(({ payload }) => httpDelete({
    call: `events/${payload.eventId}/`,
  }).pipe(
    map((result) => {
      payload.enqueueSnackbar(
        `You deleted the Event: ${payload.eventName}`,
        {
          variant: "success",
        },
      );
      return eventsDeleteEventSuccess({ result, deletedEvent: payload });
    }),
    catchError((error) => errorHandler(error, payload.enqueueSnackbar)),
  )),
);

export const epicDeleteSpeaker = (action$, state$) => action$.pipe(
  ofType(EVENTS_REMOVE_SPEAKER_ATTEMPT),
  switchMap(({ payload }) => httpDelete({
    call: `events/${payload.eventId}/`,
  }).pipe(
    map((result) => eventsDeleteEventSuccess(result)),
    catchError((error) => errorHandler(error, payload.enqueueSnackbar)),
  )),
);

export const epicAddRatingEvent = (action$, state$) => action$.pipe(
  ofType(EVENTS_ADD_RATING_ATTEMPT),
  switchMap(({ payload }) => httpPut({
    call: `events/${payload.eventId}/attendees/${payload.eventAttendeeId}`,
    data: {
      event_attendee: { rating: payload.rating },
    },
  }).pipe(
    map((result) => eventsAddRatingSuccess(result)),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

// export const epicPostSpeakers = (action$, state$) =>
// action$.pipe(
//   ofType(EVENTS_ADD_SPEAKER_ATTEMPT),
//   switchMap(({ payload : {eventId, eventSpeaker} }) =>
//     httpPost({
//       call: `events/${eventId}/speakers`,
//       data: {
//         event_speaker: eventSpeaker,
//       },
//     }).pipe(map((result) => eventsAddSpeakerSuccess(result)))
//   )
// );

export const epicPostSpeakers = (action$, state$) => action$.pipe(
  ofType(EVENTS_ADD_SPEAKER_ATTEMPT),
  switchMap(({ payload: { eventId, eventSpeaker, enqueueSnackbar } }) => httpPost({
    call: `events/${eventId}/speakers`,
    data: {
      event_speaker: eventSpeaker,
    },
  }).pipe(
    map((result) => eventsAddSpeakerSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicEventShare = (action$) =>
  action$.pipe(
    ofType(EVENT_SHARE_ATTEMPT),
    switchMap(({ payload: { council_id, id, emails, enqueueSnackbar } }) =>
      httpPost({
        call: `events/${id}/share`,
        data: {
          emails,
        },
      }).pipe(
        map((result) => {
          enqueueSnackbar("Event was shared successful!", {
            variant: "success",
          });
          return eventShareSuccess(result);
        }),

        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const viewEventEpic = combineEpics(
  epicGetEvent,
  epicGetEventSpeakers,
  epicAttending,
  epicGetEventAttendings,
  epicRemoveAttending,
  epicGetAllAttending,
  epicPostEventAttending,
  epicDeleteEventAttending,
  epicDeleteEvent,
  epicAddRatingEvent,
  epicPostSpeakers,
  epicEventShare,
  epicPutEventAttending,
);

export default viewEventEpic;

import { combineEpics } from "redux-observable";

import patientOrganizationMainEpic from "./patientOrganizationMain/patientOrganizationMain.epic";
import patientOrganizationEpic from "./patientOrganization/patientOrganization.epic";
import newPatientOrganizationEpic from "./newPatientOrganization/newPatientOrganization.epic";

const patientOrganizationsEpic = combineEpics(
  patientOrganizationMainEpic,
  patientOrganizationEpic,
  newPatientOrganizationEpic,
);

export default patientOrganizationsEpic;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilePicker } from "react-file-picker";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";

import { checkViewerRole } from "common/checkers/viewerChecker";
import { getFilename } from "modules/admin/adminCompanies/editCompany/editCompany.helper";
import DragAndDrop from "common/components/dragAndDrop/index";
import styles from "./documents.module.scss";
import {
  Button,
  BtnType,
  Loading,
  Pagination,
  pageSettings,
} from "../../../primitives";
import {
  useDocumentName,
  useS3FileUpload,
  useModelPopup,
} from "../../../../common/hooks";
import {
  addDocument,
  deleteDocument,
  getDocuments,
} from "../../../../common/actions/common.action";
import { useWindowSize } from "../../../../common/hooks/useWindowSize";
import MobileDocRow from "./mobileDocRow.component";
import DesktopDocRow from "./desktopDocRow.component";
import LinearProgressWithLabel from "../../../../common/components/linearProgressWithLabel";
import ConfirmDeleteComponent from "../../../../common/components/confirmDelete";
import { ALLOWED_FILE_EXTENSIONS } from "../../../../common/constants";

const DocumentsComponent = ({ isPatientOrg, patientOrgId }) => {
  const {
    authReducer: { session },
    companiesReducer: {
      companyReducer: { selectedCompany, companyIsDownloading },
    },
    councilReducer: { selectedCouncil },
    documentsReducer: { documents, updateDocuments, docListMeta, loadingDocs },
  } = useSelector((state) => state);
  const [pageSetting, setPageSetting] = useState(pageSettings);
  const [docList, setDocList] = useState([]);
  const [documentUpload, setDocumentUpload] = useState(false);
  const documentName = useDocumentName();
  const { fileUpload, fileDelete } = useS3FileUpload();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const popup = useModelPopup();

  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const [uploadValues, setUploadValues] = useState([]);
  const [dropUploadValues, setDropUploadValues] = useState([]);
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const [newDocuments, setNewDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (isPatientOrg && patientOrgId) {
      dispatch(
        getDocuments({
          document_resource_id: patientOrgId,
          document_resource_type: "Gsk::PatientOrganization",
          patientOrg: isPatientOrg,
          enqueueSnackbar,
        })
      );
    }
    if (!isPatientOrg && selectedCompany) {
      dispatch(
        getDocuments({
          document_resource_id: selectedCompany.id,
          document_resource_type: "Company",
          patientOrg: false,
          enqueueSnackbar,
          customUrl: `documents?company_id=${
            selectedCompany.id
          }&items=10&page=${selectedPage || 1}`,
        })
      );
    }
  }, [isPatientOrg, selectedCompany, patientOrgId, selectedPage]);

  useEffect(() => {
    setLoading(loadingDocs);
  }, [loadingDocs]);

  useEffect(() => {
    if (isPatientOrg && patientOrgId && updateDocuments) {
      dispatch(
        getDocuments({
          document_resource_id: patientOrgId,
          document_resource_type: "Gsk::PatientOrganization",
          patientOrg: isPatientOrg,
          enqueueSnackbar,
        })
      );
    }
    if (!isPatientOrg && selectedCompany && updateDocuments) {
      dispatch(
        getDocuments({
          document_resource_id: selectedCompany.id,
          document_resource_type: "Company",
          patientOrg: isPatientOrg,
          customUrl: `documents?company_id=${
            selectedCompany.id
          }&items=10&page=${selectedPage || 1}`,
          enqueueSnackbar,
        })
      );
    }
  }, [updateDocuments]);

  useEffect(() => {
    setPageSetting((prev) => ({
      ...prev,
      total: docListMeta.total,
      pageCount: 1,
    }));
  }, [docListMeta, documents]);

  useEffect(() => {
    if (selectedCouncil && selectedCouncil.name === "Kyndryl") {
      setDocList(documents.filter((doc) => !doc.project));
    } else {
      setDocList(
        documents.filter(
          (doc) => doc.project === null || doc.project?.visibility === "all"
        )
      );
    }
  }, [documents, selectedCouncil]);

  const saveDocument = (s3url, name) => {
    const obj = {
      name,
      // councilId: selectedCouncil.id,
      description: "",
      enqueueSnackbar,
      added_by_id: session.id,
      s3url,
      document_resource_type:
        isPatientOrg && patientOrgId ? "Gsk::PatientOrganization" : "Company",
      document_resource_id:
        isPatientOrg && patientOrgId ? patientOrgId : selectedCompany.id,
    };
    // TODO: save url using some endpoint
    setDropUploadValues([]);
    dispatch(addDocument(obj));
  };

  const handleAddDocument = async (FileObject) => {
    setLoading(true);
    const { name } = FileObject;

    const extension = name.slice(((name.lastIndexOf(".") - 1) >>> 0) + 2);
    const isRightExtension = ALLOWED_FILE_EXTENSIONS.includes(extension);

    if (!isRightExtension) {
      const message = `Files with the extension "${extension}" are not allowed.`;
      enqueueSnackbar(message, {
        variant: "error",
        key: "extensionNotAllowed",
        persist: true,
      });

      const timeout = setTimeout(() => {
        closeSnackbar("extensionNotAllowed");
        clearTimeout(timeout);
      }, 5000);

      return;
    }

    const path = `/documents/${
      isPatientOrg && patientOrgId ? patientOrgId : selectedCompany.id
    }/`;
    if (documents.findIndex((doc) => doc.name === name) === -1) {
      await fileUpload(FileObject, path, setUploadValues, FileObject.name).then(
        (res) => {
          if (res) {
            enqueueSnackbar("File successfully uploaded.", {
              variant: "success",
            });
            setLoading(false);
            saveDocument(res.location, res.name);
          }
        }
      );
    } else if (documents.findIndex((doc) => doc.name === name) !== -1) {
      enqueueSnackbar("This document was already added.", {
        variant: "error",
      });
      setLoading(false);
    }
  };

  const handlePagingChange = (page) => {
    setSelectedPage(page);
    setPageSetting((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const uploadHandler = async (e, action) => {
    if (forbiddenFiles.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }

    if (files?.length > 1) {
      enqueueSnackbar("Can be uploaded only one document at ones.", {
        variant: "error",
      });

      return;
    }

    const fileList = [];
    let dismissedFiles = 0;

    setLoading(true);

    setDropUploadValues((items) => {
      const currentFiles = [...items];

      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name,
      }));
      return [...currentFiles, ...newFiles];
    });

    files.forEach(async (file) => {
      if (file.size <= 500000000) {
        const pathname = `/documents/${
          isPatientOrg && patientOrgId ? patientOrgId : selectedCompany.id
        }/`;
        await fileUpload(file, pathname, setDropUploadValues, file.name).then(
          (res) => {
            if (res && res.location) {
              if (
                [...newDocuments, ...documents].indexOf(res.location) === -1
              ) {
                fileList.push(res.location);
                setLoading(false);
              } else {
                dismissedFiles++;
                setForbiddenFiles((prevFiles) => {
                  const localFiles = [...prevFiles];
                  localFiles.push({
                    name: file.name,
                    error: "Document already added.",
                  });
                  return localFiles;
                });
                setDropUploadValues((prevState) => {
                  const currentProgress = [...prevState];
                  const index = currentProgress
                    .map((item) => item.name)
                    .lastIndexOf(file.name);
                  currentProgress[index] = {
                    progress: "NOT_ALLOWED",
                    name: file.name,
                  };
                  return currentProgress;
                });
                enqueueSnackbar("This document was already added.", {
                  variant: "error",
                });
                setLoading(false);
              }
            }
          }
        );
      } else {
        dismissedFiles++;
        setDropUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === file.name
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: file.name,
          };
          return currentProgress;
        });
        setLoading(false);
        if (file.size > 500000000) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error: "Your file is too large. File size limit: 500MB",
            });
            return localFiles;
          });
          enqueueSnackbar(
            "We could not upload your image because your file size is too big. Please make sure the file is less than 500 MB",
            {
              variant: "error",
            }
          );
          setLoading(false);
        }
      }
      if (fileList.length === files.length - dismissedFiles) {
        setNewDocuments([...fileList]);
      }
    });

    e.target.value = "";
  };

  const handleMoreOptionClick = async (props, id, name) => {
    if (!blockFunctionality) {
      setDocumentUpload(true);
      const pathname = `/documents/${
        isPatientOrg && patientOrgId ? patientOrgId : selectedCompany.id
      }/${name}`;

      if (props === "delete") {
        popup.show({
          title: "Delete Confirmation",
          show: true,
          height: "300",
          width: "540",
          component: (
            <ConfirmDeleteComponent
              id={id}
              enqueueSnackbar={enqueueSnackbar}
              councilId={selectedCouncil?.id}
              actionType="deleteDocument"
              fileDelete={fileDelete}
              setDocumentUpload={setDocumentUpload}
              pathname={pathname}
              deleteDocument={deleteDocument}
            />
          ),
        });
      }
    } else {
      enqueueSnackbar(
        "You have View-Only access. To Delete a Document, please ask your Admin to upgrade your account to Standard access.",
        {
          variant: "warning",
        }
      );
    }
  };

  useEffect(() => {
    if (newDocuments?.length && newDocuments[0])
      saveDocument(newDocuments[0], getFilename(newDocuments[0]));
  }, [newDocuments]);

  return (
    <section className={`${companyIsDownloading ? "px-4" : ""}`}>
      <div className={styles.contentWrp}>
        <div className={styles.headerWrp}>
          <span className={styles.headerText}>Documents</span>
          <div className={styles.dropBoxContainer}>
            <div className={styles.dragAndDropContainer}>
              <DragAndDrop
                uploadHandler={uploadHandler}
                loading={loadingDocs}
                customText="drag and drop documents here"
              />
            </div>

            {forbiddenFiles?.length ? (
              <div className={styles.forbiddenfileContainer}>
                Files that weren't allowed:{" "}
                {forbiddenFiles.map((file) => (
                  <span className={styles.forbiddenfileName}>
                    {`${file.name}: `}
                    <span className={styles.errorText}> {file.error}</span>
                  </span>
                ))}
              </div>
            ) : null}
          </div>
          <div>
            {" "}
            {!blockFunctionality ? (
              <FilePicker
                className={styles.filePicker}
                maxSize={500}
                onChange={(FileObject) => {
                  handleAddDocument(FileObject);
                }}
                onError={() =>
                  enqueueSnackbar(
                    "We could not upload your document because your file size is too big. Please make sure the file is less than 500 MB",
                    {
                      variant: "error",
                    }
                  )
                }
              >
                {documentUpload ? (
                  <Loading />
                ) : (
                  <Button
                    btn={BtnType.FRAME_LESS}
                    className={styles.newDocButton}
                    icon="icn-add"
                  >
                    Add a document
                  </Button>
                )}
              </FilePicker>
            ) : (
              <Tooltip
                title="You have View-Only access. To Add a Document, please ask your Admin to upgrade your account to Standard access."
                placement="top"
              >
                <div>
                  <Button
                    className={styles.newTask}
                    btn={BtnType.DISABLED}
                    icon="icn-add"
                  >
                    Add a document
                  </Button>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <div className={styles.loadingBlock}>
          <div className={styles.loadingUploadContainer}>
            {uploadValues &&
              Boolean(uploadValues.length) &&
              uploadValues[0] !== 100 && (
                <LinearProgressWithLabel
                  value={uploadValues[0]?.progress || 0}
                />
              )}{" "}
            {dropUploadValues?.length
              ? dropUploadValues.map(
                  (bar, index) =>
                    bar &&
                    bar?.progress !== "NOT_ALLOWED" && (
                      <div className={styles.loaderContainer}>
                        <div className={styles.loaderNamingWrp}>
                          {" "}
                          <div className={styles.fileName}>
                            {dropUploadValues[index]?.name}
                          </div>
                          <LinearProgressWithLabel
                            mode={
                              dropUploadValues[index]?.progress === "FAILED" &&
                              "error"
                            }
                            value={
                              dropUploadValues[index]?.progress === "FAILED"
                                ? 100
                                : dropUploadValues[index]?.progress || 0
                            }
                          />
                        </div>
                      </div>
                    )
                )
              : null}
          </div>
        </div>
        <div className={styles.container}>
          {loadingDocs ? (
            <Loading />
          ) : (
            <div className={styles.grid}>
              {docList?.length && !isMobile ? (
                <div className={styles.gridHeader}>
                  <div className={styles.document}>Document</div>
                  <div className={styles.project}>Related Projects</div>
                  <div className={styles.task}>Related Task</div>
                  <div className={styles.task}>Uploaded By</div>
                </div>
              ) : null}
              <div className={styles.gridBody}>
                {docList?.length ? (
                  isMobile ? (
                    docList.map((document) => (
                      <MobileDocRow
                        key={document.id}
                        document={document}
                        documentName={documentName.getName(document.name)}
                        handleMoreOptionClick={handleMoreOptionClick}
                        session={session}
                      />
                    ))
                  ) : (
                    docList.map((document) => (
                      <DesktopDocRow
                        key={document.id}
                        document={document}
                        documentName={documentName.getName(document.name)}
                        handleMoreOptionClick={handleMoreOptionClick}
                        session={session}
                      />
                    ))
                  )
                ) : (
                  <div className={styles.noData}>Upload a Document</div>
                )}
              </div>
            </div>
          )}
        </div>
        {docListMeta?.total > 5 ? (
          <div className={styles.paginationCon}>
            <Pagination {...pageSetting} onChange={handlePagingChange} />
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default DocumentsComponent;

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { appModelSettingSet } from "application/app.action";
import Skeleton from "@mui/material/Skeleton";
import { useSnackbar } from "notistack";

import styles from "./styles.module.scss";
import { Pagination, Pannel } from "../../../primitives";
import MemberComponent from "./member";
import ErrorBoundary from "../../../../common/components/errorBoundary";
import DiscussionDetails from "../../../../common/components/discussionDetails";
import { setSelectedDiscussion } from "../../../../common/actions/discussions.action";
import NewDiscussion from "../../../../common/components/newDiscussion/newDiscussion.component";
import { httpGet, pageMeta } from "../../../../common/httpCall";

const LeadershipBox = () => {
  const {
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
    discussionsReducer: { selectedDiscussion },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [pageSetting, setPageSetting] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  const [showDetailsWindow, setShowDetailsWindow] = useState(false);
  const [people, setPeople] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [reqIsActive, setReqIsActive] = useState(false);
  const requestNewData = (page) => {
    setReqIsActive(true);
    setShowLoading(true);
    const url = `teams/${selectedCouncil?.innovation_team_id}/members?page=${
      page || 1
    }&items=8`;

    httpGet({
      call: url,
    })
      .pipe()
      .subscribe((res) => {
        const pagemeta = pageMeta(res);
        setPageSetting((prev) => ({
          ...pagemeta,
          total: pagemeta.total,
          pageSize: pagemeta.pageSize,
        }));
        const refactoredPeople = res.response?.map((member) => {
          return { ...member, first_name: member.user.first_name };
        });
        setPeople(refactoredPeople || []);

        setShowLoading(false);
        const timeout = setTimeout(() => {
          setReqIsActive(false);
          clearTimeout(timeout);
        }, 1000);
      });
  };

  useEffect(() => {
    requestNewData(pageNum);
    return () => null;
  }, [pageNum]);

  const handlePagingChange = useCallback(
    (page) => {
      setPageNum(page);
      requestNewData(page);
    },
    [pageSetting, reqIsActive]
  );

  const startNewDiscussion = (user) => {
    const customSettings = {
      title: "Start a New Private Discussion",
      show: true,
      height: "300",
      width: "540",
      component: (
        <NewDiscussion type="Council" isPrivate hideTeam selectedUser={user} />
      ),
    };
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  const handleShow = (details) => {
    if (details.showNewModal) {
      startNewDiscussion(details);
      return;
    }

    dispatch(setSelectedDiscussion({ ...details, ...details.discussion }));
    setShowDetailsWindow(true);
  };

  const handleClose = () => {
    setShowDetailsWindow(false);
    dispatch(setSelectedDiscussion({}));
  };

  return (
    <Pannel
      title="Innovation Guild"
      padding="0"
      parentStyle={{ background: "Transparent" }}
    >
      <div className={styles.contentWrp}>
        <ErrorBoundary component="People Internal">
          {showDetailsWindow && (
            <DiscussionDetails
              handleClose={handleClose}
              details={selectedDiscussion}
            />
          )}
          <div className={styles.tabInner}>
            <p className={styles.titleText}>
              Keep up to date with the latest news and information by joining
              the Innovation Guild.
              <a
                target="_blank"
                href="https://kyndryl.sharepoint.com/sites/Kyndryl-Innovation-Guild"
              >
                {" "}
                (Click here to join the Guild).
              </a>
            </p>
            <p className={styles.aditionalInfo}>
              If you require more information or support with using this tool,
              please contact one of the Innovation Guild members below (on MS
              Teams) who can assist.
            </p>
            {showLoading ? (
              <div className="d-flex mt-4 mx-3 justify-content-between flex-wrap w-100">
                {Array.from({ length: 8 }).map((skeleton, idx) => (
                  <div key={idx} className={styles.skeletonBlock}>
                    <Skeleton
                      animation="wave"
                      variant="circle"
                      width={40}
                      height={40}
                    />
                    <div className={styles.skeletonText}>
                      {" "}
                      <Skeleton animation="wave" height={15} />
                      <Skeleton animation="wave" height={15} width="40%" />
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {!showLoading && people && people.length === 0 ? (
              <h3 className="d-flex mt-4 justify-content-center w-100">
                No results...
              </h3>
            ) : null}
            {!showLoading && people && people.length
              ? people.map((user, index) => (
                  <MemberComponent
                    key={`${user.id}_${index}`}
                    user={{ ...user, ...user.user }}
                    handleShow={handleShow}
                  />
                ))
              : null}
            <div
              className={classNames(
                styles.paginWrp,
                showLoading || !people || !people.length ? "d-none" : "d-flex"
              )}
            >
              {pageSetting &&
                (people?.length >= 8 || pageSetting?.page !== 1) && (
                  <div className={styles.paginCon}>
                    <Pagination
                      {...pageSetting}
                      onChange={handlePagingChange}
                    />
                  </div>
                )}
            </div>
          </div>
        </ErrorBoundary>
      </div>
    </Pannel>
  );
};

export default React.memo(LeadershipBox);

import React from "react";
import { Formik, Field } from "formik";
import styles from "./projectReports.module.scss";

const ProjectReportStep2 = ({ selectedProject, model, setModel }) => {
  const handleOnChange = (evt) => {
    if (evt.target.name === "isAllSelected") {
      handleSelectAll();
      return;
    }

    const { value } = evt.target;
    const valueExists = model.step2.stages.some((id) => id === value);

    if (valueExists) {
      setModel({
        ...model,
        step2: {
          ...model.step2,
          stages: model.step2.stages.filter((id) => id !== value),
          isAllSelected: false,
        },
      });
    } else {
      const stages = [...model.step2.stages, value];
      const isAllSelected = stages.length === selectedProject.project_stages?.length;

      setModel({
        ...model,
        step2: {
          ...model.step2,
          stages,
          isAllSelected,
        },
      });
    }
  };

  const handleSelectAll = () => {
    if (model.step2.isAllSelected) {
      setModel({
        ...model,
        step2: {
          ...model.step2,
          stages: [],
          isAllSelected: false,
        },
      });
    } else {
      setModel({
        ...model,
        step2: {
          ...model.step2,
          stages: selectedProject.project_stages.map((s) => s.stage.id),
          isAllSelected: true,
        },
      });
    }
  };

  return (
    <div className={styles.step}>
      <Formik
        enableReinitialize
        initialValues={model.step2}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          resetForm();
        }}
      >
        <form onChange={handleOnChange}>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className={styles.formElement}>
                <span className={styles.label}>Select Stages</span>
                <span className={styles.description}>
                  Select Project Stages to be included in the report
                </span>
                <div className={`${styles.checkboxesList} mb-2 mt-3`}>
                  <label>
                    <Field type="checkbox" name="isAllSelected" />
                    {model.step2.isAllSelected
                      ? "Deselect All Stages"
                      : "Select All Stages"}
                  </label>
                </div>
                <div role="group" className={styles.checkboxesList}>
                  {selectedProject.project_stages.map((s) => (
                    <label key={s.stage.id}>
                      <Field type="checkbox" name="stages" value={s.stage.id} />
                      {s.stage.name}
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </form>
      </Formik>
    </div>
  );
};

export default React.memo(ProjectReportStep2);

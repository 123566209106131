import React, { useMemo } from "react";

import styles from "./projectBudgetVsSpend.module.scss";
import { isANumber } from "../../../../../common/helper";

const ProjectBudgetVsSpend = ({ budget, spend }) => {
  const isSpendBigger = useMemo(() => Number(spend) >= Number(budget), [spend, budget]);
  const spendPercentage = isSpendBigger
    ? Math.min((budget / spend) * 100, 100)
    : Math.min((spend / budget) * 100, 100);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center">
        <span className={styles.progressBarLabel}>Budget</span>
        {
          isANumber(budget) ? (
            <div className={styles.progressBar}>
              <div
                className={`${styles.progressBarFill} ${styles.progressBarFillWhite}`}
                style={{ width: `${!isSpendBigger ? "100" : spendPercentage}%` }}
              />
              <span>{`$${budget?.toLocaleString()}`}</span>
            </div>
          ) : " -"
        }
      </div>
      <div className="d-flex align-items-center">
        <span className={styles.progressBarLabel}>Spend</span>
        {
          isANumber(spend) ? (
            <div className={styles.progressBar}>
              <div
                className={`${styles.progressBarFill} ${isSpendBigger ? styles.progressBarFillRed : styles.progressBarFillGreen}`}
                style={{ width: `${isSpendBigger ? "100" : spendPercentage}%` }}
              />
              <span>{`$${spend?.toLocaleString()}`}</span>
            </div>
          ) : " -"
        }
      </div>
    </div>
  );
};

export default React.memo(ProjectBudgetVsSpend);

export const moreOptionTypes = {
  edit: 1,
  assign: 2,
  history: 3,
};

export const moreOptions = [
  {
    val: moreOptionTypes.edit,
    text: "Edit",
  },
];

export const documentList = [
  {
    id: "1",
    name: "CobaltRobotics-NDA-Signed.pdf",
  },
];

export const pageSettings = (pageSize = 1) => ({
  pageSize,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
});

import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "common/helper";

import {
  setNewWSDiscussion,
  setNewWSEventDiscussion,
  setNewWSProjectDiscussion,
  setNewWSDiscussionMsg,
} from "../../common/actions/discussions.action";
import { isLocalhost } from "../../serviceWorker";

const RootSocketProvider = ({ session, selectedCouncil }) => {
  const {
    projectsReducer: {
      projectReducer: { selectedProject },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const parseData = useCallback(
    (data) => {
      if (!data?.message || data.type === "ping") {
        return;
      }
      console.log("ws data:", data);

      const { message } = data;

      // there is a crash without this check
      if (!message.data) return;

      message.data.forEach((obj) => {
        if (obj.type === "discussion_messages") {
          dispatch(setNewWSDiscussionMsg(obj.attributes));
        }

        if (obj.type === "discussions") {
          // dispatch stuff related with discussions
          const { attributes } = obj;

          // discussion was created from project
          if (attributes.project_id?.length) {
            dispatch(setNewWSProjectDiscussion(attributes));
            return;
          }

          // discussion was created from company
          if (attributes.company_id?.length) {
            return;
          }

          // discussion was created from event
          if (attributes.event_id?.length) {
            dispatch(setNewWSEventDiscussion(attributes));
            return;
          }

          dispatch(setNewWSDiscussion(attributes));
        }
      });
    },
    [selectedProject]
  );

  const openSocketChannels = useCallback((channel) => {
    let ws;

    if (isLocalhost) {
      const uid = getCookie("uid");
      const accessToken = getCookie("access_token");
      const client = getCookie("client");

      ws = new WebSocket(
        `${process.env.REACT_APP_WSS}/cable?access_token=${accessToken}&client=${client}&uid=${uid}`,
      );
    } else {
      ws = new WebSocket(
        `${process.env.REACT_APP_WSS}/cable`,
      );
    }

    ws.onopen = () => {
      const msg = {
        command: "subscribe",
        council_id: selectedCouncil.id,
        identifier: JSON.stringify({
          channel,
          council_id: selectedCouncil.id,
          user_id: session.id,
        }),
      };

        ws.send(JSON.stringify(msg));
      };

      try {
        ws.onmessage = (event) => {
          const parsedData = JSON.parse(event.data);
          parseData(parsedData);
        };
        ws.onclose = (e) => {
          console.log(
            "Socket is closed. Reconnect will be attempted in 5 second.",
            e.reason
          );
          const timeout = setTimeout(() => {
            openSocketChannels(channel);
            clearTimeout(timeout);
          }, 5000);
        };
        ws.onerror = (error) => {
          console.log("Error: " + JSON.stringify(error));
        };
      } catch (error) {
        console.log("Error catch: " + error);
      }

      return ws;
    },
    [session]
  );

  useEffect(() => {
    const tractionGenericSocket = openSocketChannels(
      "SubscribeToTractionLiveUpdates"
    );

    return () => {
      tractionGenericSocket.close();
    };
  }, []);

  return "";
};

export default React.memo(RootSocketProvider);

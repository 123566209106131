import * as Yup from "yup";

export const validation = Yup.object().shape({
  // title: Yup.string().required('Title is required'),
});

export const model = {
  type: "checklist",
  title: "",
  options: [""],
  option_selected: [],
  multiselect: true,
  display_as: "grid",
};

import React, { useMemo } from "react";

import { TabComponent } from "../../../primitives";
import IdeaProfileOverview from "../ideaProfileOverview";
import IdeaFreddieMacOnly from "modules/ideasWrapper/ideaProfileWrapper/ideaFreddieMacOnly";

const getTabs = (isFreddieMacInstance, canEdit) => {
  if (isFreddieMacInstance) {
    return [
      {
        tab: "Overview",
        name: "Overview",
        component: <IdeaProfileOverview canEdit={canEdit} />,
        icon: "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\"> <path d=\"M6.848 8.5c.353 0 .652.3.652.652v4.583a.759.759 0 0 1-.765.765h-4.47a.759.759 0 0 1-.765-.765v-4.47c0-.427.337-.765.765-.765zm7 0c.353 0 .652.3.652.652v4.583a.759.759 0 0 1-.765.765h-4.47a.759.759 0 0 1-.765-.765v-4.47c0-.427.337-.765.765-.765zM2.5 9.5V13.5h4v-4l-4-.001zm7 0V13.5h4v-4l-4-.001zM6.848 1.5c.353 0 .652.3.652.652v4.583a.759.759 0 0 1-.765.765h-4.47a.759.759 0 0 1-.765-.765v-4.47c0-.427.337-.765.765-.765zm7 0c.353 0 .652.3.652.652v4.583a.759.759 0 0 1-.765.765h-4.47a.759.759 0 0 1-.765-.765v-4.47c0-.427.337-.765.765-.765zM2.5 2.5V6.5h4v-4l-4-.001zm7 0V6.5h4v-4l-4-.001z\" fill=\"#2D83AE\" fill-rule=\"evenodd\"/> </svg>",
      },
      {
        tab: "leanCanvas",
        name: "Lean Canvas",
        component: <IdeaFreddieMacOnly />,
      },
    ];
  }

  return [
    {
      tab: "Overview",
      name: "Overview",
      component: <IdeaProfileOverview canEdit={canEdit} />,
      icon: "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" xmlns=\"http://www.w3.org/2000/svg\"> <path d=\"M6.848 8.5c.353 0 .652.3.652.652v4.583a.759.759 0 0 1-.765.765h-4.47a.759.759 0 0 1-.765-.765v-4.47c0-.427.337-.765.765-.765zm7 0c.353 0 .652.3.652.652v4.583a.759.759 0 0 1-.765.765h-4.47a.759.759 0 0 1-.765-.765v-4.47c0-.427.337-.765.765-.765zM2.5 9.5V13.5h4v-4l-4-.001zm7 0V13.5h4v-4l-4-.001zM6.848 1.5c.353 0 .652.3.652.652v4.583a.759.759 0 0 1-.765.765h-4.47a.759.759 0 0 1-.765-.765v-4.47c0-.427.337-.765.765-.765zm7 0c.353 0 .652.3.652.652v4.583a.759.759 0 0 1-.765.765h-4.47a.759.759 0 0 1-.765-.765v-4.47c0-.427.337-.765.765-.765zM2.5 2.5V6.5h4v-4l-4-.001zm7 0V6.5h4v-4l-4-.001z\" fill=\"#2D83AE\" fill-rule=\"evenodd\"/> </svg>",
    },
  ];
}

const IdeaProfileTabs = ({ canEdit, selectedCouncil, selectedIndex, setSelectedIndex }) => {
  const isFreddieMac = useMemo(() => {
    return selectedCouncil?.traction_tag === 'freddie_mac' || selectedCouncil?.traction_tag === 'home_x'
  }, [selectedCouncil])
  const tabList = useMemo(() => getTabs(isFreddieMac, canEdit), [isFreddieMac, canEdit])

  const handleOnTabSelect = (index) => {
    setSelectedIndex(index);
  };

  return (
    <div className="bg-white">
      <TabComponent
        tabList={tabList}
        defaultIndex={selectedIndex}
        onSelect={(index) => handleOnTabSelect(index)}
      />
    </div>
  );
};

export default React.memo(IdeaProfileTabs);

import React from "react";
import { ModalBody, ModalFooter } from "application/modal/index";
import { useModelPopup } from "../../../common/hooks";
import { Label, Button } from "../../primitives";
import styles from "./syncDetailsModal.module.scss";

const SyncDetailsModal = ({ record, attribute }) => {
  const popup = useModelPopup();

  return (
    <>
      <div>
        <ModalBody>
          <Label>
            <div className={`row ${styles.rowStyle}`}>
              <div className={`col ${styles.rowItem}`}>
                <h6> Job started </h6>
              </div>
              <div className={`col ${styles.rowItem}`}>
                <h6> {attribute}&nbsp; Imported </h6>
              </div>
              <div className={`col ${styles.rowItem}`}>
                <h6> Job Status</h6>
              </div>
            </div>
            <div className={`row ${styles.rowStyle}`}>
              <div className={`col ${styles.rowItem}`}>
                {(record[0]?.created_at > record[1]?.created_at
                  ? record[1]?.created_at
                  : record[0]?.created_at) || "NA"}
              </div>
              <div className={`col ${styles.rowItem}`}>
                {(record[0]?.flow === "import"
                  ? record[0]?.records
                  : record[1]?.records) || "NA"}
              </div>
              <div className={`col ${styles.rowItem}`}>
                {(record[0]?.job_status === "completed" &&
                  record[1]?.job_status === "completed" &&
                  "completed") ||
                  (record[0]?.job_status === "working" &&
                    record[1]?.job_status === "working" &&
                    "working") ||
                  ((record[0]?.job_status === "failed" ||
                    record[1]?.job_status === "failed") &&
                    "failed") ||
                  "NA"}
              </div>
            </div>
            <div className={`row ${styles.rowStyle}`}>
              <div className={`col ${styles.rowItem}`}>
                <h6> Job Completed </h6>
              </div>
              <div className={`col ${styles.rowItem}`}>
                <h6>{attribute}&nbsp; Exported </h6>
              </div>
              <div className={`col ${styles.rowItem}`}>
                <h6>Toggle Status</h6>
              </div>
            </div>
            <div className={`row ${styles.rowStyle}`}>
              <div className={`col ${styles.rowItem}`}>
                {(record[0]?.updated_at > record[1]?.updated_at
                  ? record[0]?.updated_at
                  : record[1]?.updated_at) || "NA"}
              </div>
              <div className={`col ${styles.rowItem}`}>
                {(record[0]?.flow === "export"
                  ? record[0]?.records
                  : record[1]?.records) || "NA"}
              </div>
              <div className={`col ${styles.rowItem}`}>
                {attribute === "Contact"
                  ? (localStorage.getItem("contact_token") === "true"
                      ? "on"
                      : "off") || "NA"
                  : (localStorage.getItem("account_token") === "true"
                      ? "on"
                      : "off") || "NA"}
              </div>
            </div>
          </Label>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => popup.hide()}>Close</Button>
        </ModalFooter>
      </div>
    </>
  );
};

export default React.memo(SyncDetailsModal);

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select/Select";
import styles from "./state.module.scss";

const StateComponent = (props) => {
  const { stageId, onStageChange, isEditable } = props;
  const [stage, setStage] = useState("");
  const [styleClass, setStyleClass] = useState("");
  const {
    projectsReducer: {
      stagesReducer: { stagesList },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (stagesList.length > 0) {
      setStage(stagesList.find((stage) => stage.id === stageId));
    }

    return () => null;
  }, [stagesList, stageId]);

  useEffect(() => {
    if (stage && stage.id) {
      const index = stagesList.findIndex((s) => s.id === stage.id);
      setStyleClass(styles[`state${index + 1}`]);
    }

    return () => null;
  }, [stage]);

  const handleStageChange = (e) => {
    e.preventDefault();

    setStage(stagesList.find((stage) => stage.id === e.target.value));

    if (onStageChange) {
      onStageChange(e.target.value);
    }
  };

  return (
    <>
      {isEditable ? (
        <Select
          value={stage ? stage.id : ""}
          onChange={handleStageChange}
          className={classNames(styles.stateWrp, styleClass)}
          disableUnderline
        >
          {stagesList.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <div className={classNames(styles.stateWrp, styleClass)}>
          <div className={styles.text} title={stage && stage.name}>
            {stage && stage.name}
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(StateComponent);

import React, { useEffect, useState } from "react";
import classNames from "classnames";

import styles from "./projectState.module.scss";
import { projectStateType, projectStates } from "./projectState.constant";
import { Icon, mdSize } from "../../../common/icon";

const ProjectStateComponent = (props) => {
  const { state = projectStates.OPEN, type = projectStateType.FULL_TEXT } = props;
  const [styleClass, setStyleClass] = useState("");
  const [text, setText] = useState("");
  const [icon, setIcon] = useState("");

  useEffect(() => {
    switch (state) {
      case projectStates.OPEN:
        setText("On Track");
        setStyleClass(styles.open);
        break;
      case projectStates.AT_RISK:
        setText("At Risk");
        setStyleClass(styles.atRisck);
        break;
      case projectStates.OFF_TRACK:
        setText("Off Track");
        setStyleClass(styles.offTrack);
        break;
      case projectStates.CLOSED:
        setText("Closed");
        setStyleClass(styles.closed);
        break;
      case projectStates.ON_HOLD:
        setText("On Hold");
        setStyleClass(styles.onHold);
        break;  
      default:
        break;
    }
  }, [state]);

  return (
    <>
      {type === projectStateType.FULL_TEXT ? (
        <div className={classNames(styles.stateWrp, styleClass)}>
          <div className={styles.text}>{text}</div>
        </div>
      ) : (
        <Icon {...mdSize} icon={icon} className={styles.icon} />
      )}
    </>
  );
};

export default ProjectStateComponent;

import { combineReducers } from "redux";
import projectReducer from "./project/project.reducer";
import usersReducer from "./project/users/user.reducer";
import stagesReducer from "./project/stages/stages.reducer";
import taskReducer from "./project/tasks/tasks.reducer";
import companyReducer from "./project/companies/companies.reducer";
import projectsMainReducer from "./projectsMain/projectsMain.reducer";
import overviewReducer from "./project/overview/overview.reducer";
import projectRatingsReducer from "./project/manageProject/ratings/ratings.reducer";
import companyCompairReducer from "./project/companies/compair/compair.reducer";

const projectsReducer = combineReducers({
  projectReducer,
  usersReducer,
  stagesReducer,
  taskReducer,
  companyReducer,
  projectsMainReducer,
  overviewReducer,
  projectRatingsReducer,
  companyCompairReducer,
});

export default projectsReducer;

import React from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./followingItem.module.scss";
import { BtnType, Button, CompanyAvatar } from "../../../primitives";
import {
  companyUnFollow,
  suggestionFollow,
} from "../../../../common/actions/common.action";

const FollowingItem = ({
  item,
  isLast,
  enqueueSnackbar,
  onUnfollow,
  onFollow,
}) => {
  const dispatch = useDispatch();

  const unFollowCompany = (evt) => {
    evt.preventDefault();

    if (item.following) {
      dispatch(
        companyUnFollow({
          data: item,
          enqueueSnackbar,
        }),
      );

      onUnfollow(item);
    } else {
      dispatch(
        suggestionFollow({
          data: item,
          enqueueSnackbar,
        }),
      );

      onFollow(item);
    }
  };

  return (
    <div
      className={classNames(
        styles.followItem,
        isLast ? styles.borderBottom : "",
      )}
    >
      <div className="d-flex align-items-center flex-grow-1">
        <Link
          to={`/companies/${item.slug || item.company_id}`}
          className={classNames("d-flex", styles.cursorPointer)}
        >
          <div className={styles.followItemLogo}>
            <CompanyAvatar
              imgSrc={item.logo || item.following?.logo}
              name={item.name}
            />
          </div>
          <span className={styles.name}>{item.name}</span>
        </Link>
        {item.topics_names && item.topics_names.length ? (
          <div className={styles.suggestionKeys}>
            {item.topics_names.map((tag, idx) => (
              <div key={`${tag}-${idx}`}>{tag}</div>
            ))}
          </div>
        ) : null}
      </div>
      <Button onClick={unFollowCompany} btn={BtnType.FRAME_LESS}>
        {item.following ? "UNFOLLOW" : "FOLLOW"}
      </Button>
    </div>
  );
};

export default React.memo(FollowingItem);

import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BtnType, Button, Label } from "modules/primitives";
import { httpPost } from "../../../../../common/httpCall";
import styles from '../../../tractionAI.module.scss';
import LoadingComponent from "modules/primitives/loading/loading.component";
import { AISetSWOTData } from "modules/tractionAI/store/tractionAI.actions";
import CompaniesMultiSelectSearch from "../../../../../common/components/companiesMultiSelectSearch";
import RandomAILoader from "modules/tractionAI/components/randomAILoader";

const TractionAISwotFooter = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();

  const {
    tractionAiReducer: { insight: { swot } },
  } = useSelector((state) => state);

  const handleCompaniesSelect = (companies) => {
    setSearchQuery('');
    setSelected(companies);
  }

  const handleSubmit = () => {
    dispatch(AISetSWOTData({
      fetching: true,
      searchByQuery: searchQuery.length ? searchQuery : selected[0]?.name,
      createNewSearchItem: true,
      fetched: false,
      companyId: selected[0]?.id,
      isCompanySearch: selected[0]?.id?.length > 0,
    }));

    httpPost({
      call: "ai/proxy/streaming", data: { prompt: searchQuery, endpoint: "swot", company_id: selected[0]?.id },
    })
      .subscribe(() => {
        setSearchQuery('')
      });
  };

  const onEnterPress = (evt) => {
    if(evt.keyCode === 13 && !evt.shiftKey) {
      evt.preventDefault();
      handleSubmit()
    }
  };

  const getLoadingTemplate = useCallback((data) => {
    if (!data || data?.length === 0) {
      return <RandomAILoader size="big" />
    }

    const lastItem = data[data.length - 1];
    const hasData = lastItem.data.length > 0;

    if (hasData) {
      return <LoadingComponent customText="Fetching SWOT details..." />
    }

    return <RandomAILoader size="big" />
  }, [swot.data]);

  return (
    <div className="d-flex flex-row w-100 p-2">
      {
        swot.fetching ? (
          <div className="d-flex align-items-center w-100 justify-content-center">
            {getLoadingTemplate(swot.data)}
          </div>
        ) : (
          <>
            <div className="d-flex flex-column w-100">
              <div>
                <Label>Company SWOT</Label>
                <CompaniesMultiSelectSearch
                  onChange={handleCompaniesSelect}
                  shouldReset={searchQuery?.length > 0}
                  placeholder="Select Company"
                  labelField="name"
                  valueField="id"
                  dropdownPosition="top"
                  multi={false}
                  endpoint="companies/traction_db_search"
                />
                <div className="d-flex justify-content-center mb-3 text-muted">OR</div>
              </div>
              <Label>Market SWOT</Label>
              <textarea
                className={styles.AITextArea}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setSelected([]);
                }}
                name="prompt"
                id="prompt"
                rows="3"
                placeholder="Enter details about the technology trend or problem you would like me to research."
                disabled={swot.fetching}
                onKeyDown={onEnterPress}
              />
            </div>
            <div className="d-flex align-items-end ml-5">
              <Button
                btn={BtnType.REGULAR}
                onClick={handleSubmit}
                disabled={selected.length === 0 && !searchQuery.length}
              >SUBMIT</Button>
            </div>
          </>
        )
      }
    </div>
  );
};

export default React.memo(TractionAISwotFooter);

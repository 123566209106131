import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import Tooltip from "@mui/material/Tooltip";

import { checkViewerRole } from "common/checkers/viewerChecker";
import { Button, BtnType } from "../../../../primitives";
import styles from "./taskInfo.module.scss";
import { projectTaskUpdate } from "../tasks.action";
import { setCompanyTaskAssignmentCompleted } from "../../companies/companies.action";
import { projectWideTaskSetComplete } from "../../stages/stages.action";
import { Icon, mdSize } from "../../../../../common/icon";
import { appSliderSettingSet } from "application/app.action";
import { getUpcomingTasks } from "modules/projects/project/overview/overview.action";

const TaskInfoHeaderComponent = (props) => {
  const {
    taskId,
    projectId,
    assignmentId,
    company,
    stage,
    isProjectWideFromStage,
    back,
    patientOrg,
    activeTab = 'all_task',
    completionEnabled = true, // -> will be used, do not delete
    isCompanyTask,
    task,
    upcomingTaskInstance,
  } = props;
  const {
    authReducer: { session },
    projectsReducer: {
      taskReducer: {
        tasksList,
        userTasks,
      },
      stagesReducer: { projectWideTasks },
      companyReducer: { taskList_companyId },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isCompleted, setCompleted] = useState(false);
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  useEffect(() => {
    if (isCompanyTask && taskList_companyId) {
      if (taskList_companyId[`${company.id}_${stage.id}`]) {
        const tasks = taskList_companyId[`${company.id}_${stage.id}`];
        const getTask = tasks.find(t => t.id === taskId);
        const getAssignment = getTask.projects_task_assignments.find(a => a.id === assignmentId);

        setCompleted(getAssignment.completed);
      }
    }
  }, [isCompanyTask, taskList_companyId])

  useEffect(() => {
    if (isProjectWideFromStage && projectWideTasks?.length) {
      const getProjectWideTask = projectWideTasks.find(t => t.id === taskId);

      if (getProjectWideTask) {
        setCompleted(getProjectWideTask.projects_task_assignments[0].completed);
      }
    }
  }, [projectWideTasks]);

  const checkProjectTaskIsCompleted = useCallback((taskId) => {
    const getTask = activeTab === 'all_task'
      ? tasksList?.find(t => t.id === taskId)
      : userTasks?.find(t => t.id === taskId);

    if (getTask) {
      const getAssignment = getTask.projects_task_assignments.find(a => a.id === assignmentId);
      setCompleted(getAssignment?.completed);
    }
  }, [activeTab, tasksList, userTasks, assignmentId, taskId]);

  useEffect(() => {
    if (isProjectWideFromStage) {
      return;
    }

    checkProjectTaskIsCompleted(taskId);
  }, [activeTab, tasksList, userTasks, isProjectWideFromStage]);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  const handleCompleteTaskClick = (value) => {
    setCompleted(value);

    dispatch(
      projectTaskUpdate({
        enqueueSnackbar,
        taskId,
        projectId,
        flag: value ? "completed_true" : "completed_false",
        project_task: {
          task_assignments: {
            completed_on: value ? format(new Date(), "yyyy-MM-dd") : null,
          },
        },
        queryParams: {
          task_assignment_id: assignmentId,
        },
        companyId: company?.id,
        patientOrg,
        cb: () => {
          if (upcomingTaskInstance && value) {
            dispatch(getUpcomingTasks({
              userId: activeTab === "my_task" ? session.id : null,
              enqueueSnackbar,
              projectId,
            }));
            dispatch(appSliderSettingSet({
              show: false,
            }));
          }
        }
      }),
    );

    if (stage && company) {
      dispatch(
        setCompanyTaskAssignmentCompleted({
          companyId: company.id,
          stageId: stage.id,
          assignmentId,
          taskId,
          value,
        }),
      );
    }

    if (isProjectWideFromStage) {
      dispatch(
        projectWideTaskSetComplete({
          taskId,
          assignmentId,
          value,
        }),
      );
    }
  };

  return (
    <div className={styles.headerWrp}>
      {back && (
        <div className={styles.back}>
          <Icon
            {...mdSize}
            icon="icn-right-chevron"
            className={styles.icon}
            onClick={back}
          />
        </div>
      )}
      {!blockFunctionality ? (
        <Tooltip
          title={task && !task?.completion_enabled
            ? task?.mark_on_document_upload ? 'Upload the required document to complete this Task' : 'Fill out the required linked fields to complete this Task'
            : ''}
          placement="top"
        >
          <div>
            <Button
              style={{opacity: !completionEnabled ? 0.3 : 1}}
              disabled={!completionEnabled}
              btn={isCompleted ? BtnType.REGULAR : BtnType.OUTLINE}
              onClick={() => handleCompleteTaskClick(!isCompleted)}
              icon={isCompleted ? "icn-checkbox-button" : "icn-checkbox-enabled"}
            >
              {isCompleted ? "completed" : "Mark task complete"}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          title="You have View-Only access. To edit this Project, please ask your Admin to upgrade your account to Standard access and invite you to this Project."
          placement="top"
        >
          <div>
            <Button
              btn={BtnType.DISABLED}
              icon={
                isCompleted ? "icn-checkbox-button" : "icn-checkbox-enabled"
              }
            >
              {isCompleted ? "completed" : "Mark task complete"}
            </Button>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default React.memo(TaskInfoHeaderComponent);

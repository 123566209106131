import {
  GET_RFI_DETAILS_SUCCESS,
  RESET_ACTIVE_FORM,
  SAVE_DETAILS_SUCCESS,
} from "./rfiActions";
import { GET_PROFILE_FORMS_SUCCESS } from "../../store/claimProfileActions";

const initState = {
  details: {
    data: null,
    loading: true,
  },
  forms: {
    formsData: null,
    loadingForms: true,
  },
};

const rfiReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SAVE_DETAILS_SUCCESS: {
      return {
        ...state,
        details: {
          data: {
            ...state.details.data,
            ...payload.response,
          },
          loading: false,
        },
      };
    }

    case RESET_ACTIVE_FORM: {
      return {
        ...state,
        details: {
          data: null,
          loading: true,
        },
      };
    }

    case GET_RFI_DETAILS_SUCCESS: {
      if (!payload) {
        return {
          ...state,
          details: {
            data: null,
            loading: false,
          },
        };
      }

      return {
        ...state,
        details: {
          data: payload.response,
          loading: false,
        },
      };
    }

    case GET_PROFILE_FORMS_SUCCESS: {
      return {
        ...state,
        forms: {
          formsData: payload.response,
          loadingForms: false,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default rfiReducer;

import React, { useRef, useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  CheckBox,
  CheckType,
  Label,
  TextBox,
  Button,
  BtnType,
} from "modules/primitives";
import styles from "./addTeam.module.scss";
import { initModel, validation } from "./addTeam.constants";
import { ModalBody, ModalFooter } from "../../../../application/modal";
import { useModelPopup } from "../../../../common/hooks";
import {
  councilCreateTeam,
  councilEditTeam,
} from "../../../council/council.action";

const AddTeamComponent = ({ mode, team = {} }) => {
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const formRef = useRef();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const [isDefaultTeam, setIsDefaultTeam] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleDismiss = () => {
    popup.hide();
  };
  useEffect(() => {
    if (team) {
      if (team?.request_research) {
        setIsDefaultTeam(true);
      }
    }
  }, [team]);
  const onSubmit = (values) => {
    const { name, description, id } = values;
    if (mode === "edit") {
      dispatch(
        councilEditTeam({
          name,
          description,
          teamId: team?.id,
          enqueueSnackbar,
          request_research: isDefaultTeam,
        }),
      );
      popup.hide();
      return;
    }
    dispatch(
      councilCreateTeam({
        name,
        description,
        council_id: selectedCouncil.id,
        enqueueSnackbar,
        request_research: isDefaultTeam,
      }),
    );
    popup.hide();
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={
        mode === "edit" && team.name ? team : initModel && initModel
      }
      validationSchema={validation && validation}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        onSubmit(values);
        resetForm();
      }}
    >
      {({ handleSubmit, ...formikprops }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className={styles.instructionText}>
              Once you create a Team, you will be able to assign members to it.
            </div>
            <Label>Title</Label>
            <TextBox
              type="text"
              name="name"
              placeholder="Add team name"
              formProps={formikprops}
              className={styles.textBox}
            />
            <Label>Description (optional)</Label>
            <TextBox
              type="text"
              name="description"
              placeholder="Add description"
              formProps={formikprops}
              className={styles.textBox}
            />
            {/* mode === 'edit' */}
            {true && (
              <div className={styles.defaultTeamBox}>
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={isDefaultTeam}
                  onChange={(e) => {
                    setIsDefaultTeam(e);
                  }}
                />
                <Label>Make default for Request Research</Label>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
              Cancel
            </Button>
            <Button
              //   onClick={handleDelete}
              type="submit"
              btn={BtnType.HIGHLIGHT}
              className={styles.button}
            >
              {mode === "edit" ? "Save" : "Add"}
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default AddTeamComponent;

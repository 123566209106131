import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from './styles.module.scss';
import LoadingComponent from "modules/primitives/loading/loading.component";
import FordScoreTable from "modules/reporting/fordReports/actualScorecard/table/fordScoreTable";
import {
  getActualScoreTableData,
  toggleActualScoreTable,
} from "modules/reporting/fordReports/actualScorecard/store/actualScoreCard.actions";
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import { tableHeadersByKey } from "modules/reporting/fordReports/actualScorecard/constants";
import { httpPost } from "../../../../../common/httpCall";

const FordScoreTableWrapper = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();

  const {
    fordDashboardReducer: { actualScoreCardReducer }
  } = useSelector((state) => state);

  const getExpandedTableKey = useMemo(() => {
    return actualScoreCardReducer.tableData.expandedKey;
  }, [actualScoreCardReducer.tableData]);

  const getActiveExpandedTable = useMemo(() => {
    return actualScoreCardReducer.tableData[getExpandedTableKey];
  }, [getExpandedTableKey, actualScoreCardReducer.tableData]);

  const hideSourceData = () => {
    dispatch(toggleActualScoreTable({
      reducerKey: actualScoreCardReducer.tableData.expandedKey,
    }));
  }

  const handlePageChange = (page) => {
    const filtersData = makeActualScoreCardFilterData(actualScoreCardReducer.filters);
    dispatch(getActualScoreTableData({
      reducerKey: actualScoreCardReducer.tableData.expandedKey,
      highLightColumn: actualScoreCardReducer.tableData.highLightColumn,
      data: {
        ...filtersData,
        section: actualScoreCardReducer.tableData.expandedKey,
      },
      page,
    }));
  };

  useEffect(() => {
    if (tableRef.current && !getActiveExpandedTable?.fetching) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [tableRef, getActiveExpandedTable?.fetching]);

  const handleDownload = (payload) => {
    const filtersData = makeActualScoreCardFilterData(actualScoreCardReducer.filters);

    const promiseReq = httpPost({
      customServerPath: true,
      call: 'ford/dashboards/exports',
      data: {
        ...filtersData,
        ...payload,
      },
      respType: "blob",
    }).subscribe(res => {
      if (payload.cb) {
        payload.cb();
      }

      const url = window.URL.createObjectURL(new Blob([res.response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Actual_Scorecard-${payload.section}.${payload.type}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      promiseReq.unsubscribe();
    })
  }

  if (!getExpandedTableKey) {
    return null;
  }

  return (
    <div className="mt-5">
      <div className="d-flex align-items-center justify-content-center mb-3">
        <h5 className="font-weight-bold">{tableHeadersByKey[getExpandedTableKey]}</h5>
      </div>
      {
        getActiveExpandedTable.fetching ? (
          <LoadingComponent customText="Fetching data..." />
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-center mb-3">
              <span className={styles.hideSourceData} onClick={hideSourceData}>HIDE SOURCE DATA</span>
            </div>
            <div className={styles.tableDataWrapper} ref={tableRef}>
              <FordScoreTable
                tableData={getActiveExpandedTable}
                handlePageChange={handlePageChange}
                expandedTableKey={getExpandedTableKey}
                handleDownload={handleDownload}
              />
            </div>
          </>
        )
      }
    </div>
  );
};

export default React.memo(FordScoreTableWrapper);

const checkUniqName = (name, cfGroup) => {
  let isNameUniq = true;
  if (cfGroup) {
    cfGroup.forEach((customField) => {
      let titleToCheck;
      if (customField.field_name) {
        titleToCheck = customField.field_name?.trim()?.toLowerCase();
      } else {
        titleToCheck = customField.field_value?.trim()?.toLowerCase();
      }
      if (name.trim().toLowerCase() === titleToCheck && isNameUniq) {
        isNameUniq = false;
      }
    });
  }
  return isNameUniq;
};
export default checkUniqName;

import React, { useEffect, useState } from "react";
import { Pagination } from "../../modules/primitives";
import { pageSettings } from "../components/customFields/slides/constant";

const SliderField = (field) => {
  const [isDocument, setIsDocument] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [url, setUrl] = useState(field.option_values[currentSlide]);
  const allowedExtensions = ["pdf", "ppt", "pptx", "pps", "odp"];
  const [pageSetting, setPageSetting] = useState(pageSettings);

  const handlePagingChange = (page) => {
    setCurrentSlide(page - 1);
    setPageSetting((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const resetPaging = () => {
    setPageSetting((prev) => ({
      ...prev,
      current: 0,
    }));
  };

  const getUrlExtension = (url) => url && url.split(/[#?]/)[0].split(".").pop().trim();

  const getPlatform = (url) => {
    if (!url) {
      return null;
    }

    if (url.includes("iframe")) {
      return "iframe";
    }

    if (
      url.includes("https://www.slideshare.net/slideshow/embed_code/")
      || url.includes("https://docs.google.com/presentation/")
    ) {
      return "link";
    }

    return "image";
  };

  const getIframeSrc = (url) => {
    const match = /src\s*=\s*"([^"]+)"/i.exec(url);
    if (match) {
      return match[1];
    }

    return url;
  };

  const getUrl = () => {
    if (field.option_values[currentSlide]) {
      if (getUrlExtension(field.option_values[currentSlide]) !== "pdf") {
        if (getPlatform(field.option_values[currentSlide]) === "link") {
          return field.option_values && field.option_values[currentSlide];
        }
        if (getPlatform(field.option_values[currentSlide]) === "iframe") {
          return getIframeSrc(field.option_values[currentSlide]);
        }
        return `https://view.officeapps.live.com/op/embed.aspx?src=${
          field.option_values && field.option_values[currentSlide]
        }`;
      }
    }

    return field.option_values && field.option_values[currentSlide];
  };

  useEffect(() => {
    if (field.option_values[currentSlide]) {
      if (
        allowedExtensions.indexOf(
          getUrlExtension(field.option_values[currentSlide]),
        ) !== -1
        || getPlatform(field.option_values[currentSlide]) === "link"
        || getPlatform(field.option_values[currentSlide]) === "iframe"
      ) {
        setIsDocument(true);
        setUrl(getUrl());
      } else {
        setIsDocument(false);
        setUrl(getUrl());
      }
    }
  }, [currentSlide, field.option_values]);

  useEffect(() => {
    if (field && field.option_values) {
      setPageSetting({
        ...pageSetting,
        total: field.option_values.length,
        pageCount: 1,
      });
    }
  }, [field.option_values]);

  useEffect(
    () => () => {
      resetPaging();
    },
    [],
  );

  const displayField = () => {
    if (field.field_type !== "slide") {
      return "";
    }
    return (
      <div>
        <div>
          <div>
            {isDocument && field.option_values.length ? (
              <iframe
                src={url}
                key={url}
                width="100%"
                height="500px"
                frameBorder="0"
                scrolling="auto"
                title="Inline Frame Example"
              />
            ) : (
              <img
                src={field.option_values[currentSlide]}
                width="100%"
                alt=""
              />
            )}
          </div>
        </div>

        <div className="d-flex justify-content-end">
          {field.option_values && field.option_values.length > 1 && (
            <Pagination {...pageSetting} onChange={handlePagingChange} />
          )}
        </div>
      </div>
    );
  };

  return { displayField };
};

export default SliderField;

import React, { useRef, useState, useEffect } from "react";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  Label,
  TextBox,
  Button,
  TextArea,
  BtnType,
} from "../../../../primitives";
import styles from "./addNewStageModal.module.scss";
import { initModel, validation } from "./addNewStageModal.constants";
import { ModalBody, ModalFooter } from "../../../../../application/modal";
import { useModelPopup } from "../../../../../common/hooks";
import {
  postStageAttempt,
  patchStageByIdAttempt,
} from "../../adminProjects.actions";

const AddNewStageModal = (props) => {
  const { placeHolderData, mode, stages, requestNewDataCb } = props;

  const {
    adminProjectsReducer: { stageTemplate },
  } = useSelector((state) => state);

  const formRef = useRef();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const [stagePosition, setStagePosition] = useState(1);
  const [usedPositions, setUsedPositions] = useState([]);
  const [positionError, setPositionError] = useState(false);
  const [isValidPositionMessage, setIsValidPositionMessage] = useState(false);
  const [isFirstPositionUnUsed, setIsFirstPositionUnUsed] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleDismiss = () => {
    popup.hide();
  };

  useEffect(() => {
    if (stages.length && mode !== "edit") {
      const firstPosition = stages.find((stage) => stage.sort_order === 1);

      if (firstPosition) {
        setIsFirstPositionUnUsed(false);
      } else {
        setIsFirstPositionUnUsed(true);
        setStagePosition(1);
      }
    }
  }, [stages]);

  useEffect(() => {
    if (stages) {
      setUsedPositions((prevState) =>
        stages
          .map((stage) => stage.sort_order)
          .sort((a, b) => {
            if (a > b) {
              return 1;
            }
            if (a < b) {
              return -1;
            }
          })
      );
    }
  }, [stages]);

  useEffect(() => {
    if (mode === "edit") {
      setStagePosition(placeHolderData?.sort_order);
    }
  }, [mode]);

  useEffect(() => {
    if (mode !== "edit") {
      autoSelectValidStagePosition();
    }
  }, [usedPositions]);

  const onSubmitForm = (values, setSubmitting, resetForm) => {
    const { stageName, description } = values;

    const data = {
      templateId: mode === "edit" ? placeHolderData?.id : stageTemplate?.id,
      name: stageName,
      description,
      sort_order: stagePosition,
      enqueueSnackbar,
    };
    if (mode === "edit" && !positionError) {
      dispatch(patchStageByIdAttempt(data));
      setSubmitting(true);
      resetForm();
      popup.hide();
      if (requestNewDataCb) {
        requestNewDataCb();
      }
    } else if (!positionError) {
      dispatch(postStageAttempt(data));
      setSubmitting(true);
      resetForm();
      popup.hide();
      if (requestNewDataCb) {
        requestNewDataCb();
      }
    }
  };

  const editModel = {
    stageName: placeHolderData?.name,
    description: placeHolderData?.description,
  };

  const autoSelectValidStagePosition = (stage, setFieldValue) => {
    for (let i = 0; i <= usedPositions.length; i++) {
      if (i + 1 !== usedPositions[i]) {
        setStagePosition(i + 1);
        break;
      }
    }
  };
  const checkIsValidStagePosition = (newPosition) => {
    const numberNewPosition = Number(newPosition);

    if (numberNewPosition <= 0) {
      return;
    }
    const isUsed = usedPositions.find(
      (position) => position === numberNewPosition
    );
    if (isUsed) {
      if (mode === "edit" && placeHolderData?.sort_order === isUsed) {
        setStagePosition(numberNewPosition);
        // setPositionError(false);
        // setIsValidPositionMessage(true);
        return;
      }
      // setPositionError(true);
      // setIsValidPositionMessage(false);
      setStagePosition(numberNewPosition);
      return;
    }
    // setPositionError(false);
    // setIsValidPositionMessage(true);
    setStagePosition(numberNewPosition);
  };
  const checkIfCanBeEdited = () => {
    if (
      mode === "edit" &&
      [
        "Idea",
        "Proposal for Prototype",
        "Proposal for Implementation",
      ].includes(editModel?.stageName)
    ) {
      return true;
    }
    return false;
  };
  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={mode === "edit" ? editModel : initModel}
      validationSchema={validation}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        // setSubmitting(true);
        onSubmitForm(values, setSubmitting, resetForm);
        // resetForm();
      }}
    >
      {({ handleSubmit, ...formikprops }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className={styles.instructionText}>
              Name the Project Stage, specify its position, and add a short
              description
            </div>
            <Label>Stage Name</Label>
            <TextBox
              type="text"
              name="stageName"
              placeholder="Add Stage Name"
              formProps={formikprops}
              className={styles.textBox}
              disabled={checkIfCanBeEdited()}
            />
            <Label>Position</Label>
            <TextBox
              type="Number"
              // name="position"
              placeholder="Add Position"
              onChange={(event) => {
                checkIsValidStagePosition(event.target.value);
              }}
              disabled={isFirstPositionUnUsed}
              min={1}
              value={stagePosition}
              className={styles.textBox}
            />
            {isValidPositionMessage && !positionError && (
              <div className={styles.valid}>This position is valid</div>
            )}
            {positionError && (
              <div className={styles.error}>
                Position is already used. Please select another one.
              </div>
            )}
            <Label>Description (optional)</Label>
            <TextArea
              type="text"
              name="description"
              placeholder="Add Description"
              formProps={formikprops}
              className={styles.textBox}
            />
          </ModalBody>
          <ModalFooter>
            <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
              Cancel
            </Button>
            <Button
              type="submit"
              btn={BtnType.HIGHLIGHT}
              className={styles.button}
            >
              {mode === "edit" ? "Save" : "Add"}
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default AddNewStageModal;

import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import ReactTable from "react-table"
import { useSnackbar } from "notistack"
import classNames from "classnames"
import { useHistory } from "react-router-dom"

import styles from "./styles.module.scss"
import { useModelPopup } from "../../../common/hooks"
import { Button, BtnType, MoreOptions, OptionType, Pagination } from "../../primitives"
import ConfirmDeleteComponent from "../../../common/components/confirmDelete"
import CouncilModal from "./councilsModal"
import { councilsGetAttempt } from "./councils.action"
import TableStatusRow from "./commons/tableStatusRow/index"

const TractionCouncils = () => {
  const {
    councilReducer: { selectedCouncil },
    councilsReducer: { councils, loadingCouncils, pageSetting },
  } = useSelector((state) => state)
  const history = useHistory()
  const popup = useModelPopup()
  const dispatch = useDispatch()
  const ref = useRef()
  const [page, setPage] = useState(1)

  const [searchValue, setSearchValue] = useState("")
  const [debouncedValue, setDebouncedValue] = useState("")

  const { enqueueSnackbar } = useSnackbar()

  const handleAddCouncil = () => {
    popup.show({
      title: "Add Council",
      show: true,
      height: "300",
      width: "540",
      component: <CouncilModal mode="add" formRef={ref} />,
    })
  }

  const moreOptions = [
    { val: "edit", text: "Edit" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ]
  const searchCouncils = (searchVal) => {
    dispatch(
      councilsGetAttempt({
        query: searchVal,
        itemsNum: 20,
        page: 1,
        enqueueSnackbar,
      })
    )
  }
  const handleRedirectToCouncilPage = (councilId) => {
    history.push({
      pathname: `/tractionadmin/councils/edit/${councilId}`,
      state: { councilId, formRef: ref },
    })
  }
  const handleMoreOptionClick = (event, id, item) => {
    if (event === "delete") {
      popup.show({
        title: "Confirmation Delete",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={id}
            enqueueSnackbar={enqueueSnackbar}
            councilId={selectedCouncil?.id}
            customData={{ councilName: item?.name }}
            actionType="deleteCouncil"
            fromCouncils
          />
        ),
      })
    } else if (event === "edit") {
      handleRedirectToCouncilPage(item?.id);
    
    }
  }

  const handlePagingChange = (e) => {
    setPage(e)
    dispatch(
      councilsGetAttempt({
        councilId: selectedCouncil.id,
        enqueueSnackbar,
        itemsNum: 20,
        page: e,
        query: debouncedValue,
      })
    )
  }

  useEffect(() => {
    if (selectedCouncil?.id) {
      dispatch(
        councilsGetAttempt({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
          itemsNum: 20,
          page,
        })
      )
    }
  }, [selectedCouncil])
  useEffect(() => {
    const delay = 500
    const debounceTimer = setTimeout(() => {
      setDebouncedValue(searchValue)
    }, delay)

    return () => {
      clearTimeout(debounceTimer)
    }
  }, [searchValue])

  useEffect(() => {
    searchCouncils(debouncedValue)
  }, [debouncedValue])

  const columns = React.useMemo(
    () => [
      {
        Header: "Council",
        accessor: "name",
        sortable: true,

        Cell: (props) => (
          <div onClick={() => handleRedirectToCouncilPage(props.original.id)}>
            {props.original.name}
          </div>
        ),
      },

      {
        Header: "Status",
        id: "status",
        sortable: false,
        width: 200,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
        accessor: (row) => <TableStatusRow row={row} enqueueSnackbar={enqueueSnackbar} />,
      },
      {
        Header: "Options",
        id: "options",
        width: 100,
        sortable: false,
        accessor: (row) => (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row.id, row)
            }}
          />
        ),
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    []
  )

  return (
    <div className={styles.container}>
      <div className={styles.flexWrapper}>
        <div className={styles.searchContainer}>
          <div className={styles.topActions}>
            <div className={classNames(styles.searchWrp, styles.searchCon)}>
              <input
                className={styles.searchText}
                size="large"
                name="searchInput"
                value={searchValue || ""}
                onChange={(e) => {
                  setSearchValue(e.target.value)
                }}
                placeholder="Search Councils"
              />
            </div>
            <div className={styles.buttonsRow}>
              <Button
                onClick={() => searchCouncils(searchValue, true)}
                btn={BtnType.REGULAR}
                className={styles.searchButton}
              >
                Search Councils
              </Button>
              <Button
                btn={BtnType.REGULAR}
                icon="icn-add"
                onClick={() => {
                  handleAddCouncil()
                }}
                className={styles.addCouncilBtn}
              >
                Add Council
              </Button>
            </div>
          </div>
        </div>
        <ReactTable
          className={styles.table}
          data={councils}
          showPagination={false}
          columns={columns}
          loading={loadingCouncils}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                style: {
                  cursor: "pointer",
                },
              }
            }
            return {}
          }}
        />
        <div
          className={classNames(
            styles.paginWrp,
            loadingCouncils || !councils || !councils.length ? "d-none" : "d-flex"
          )}
        >
          {pageSetting && (
            <div className={styles.paginCon}>
              <Pagination {...pageSetting} onChange={handlePagingChange} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default TractionCouncils

export const tabs = [
  "Overview",
  "Companies",
  "Stages",
  "Tasks",
];

export const fordTabs = [
  "Overview",
  "Plants",
  "Companies",
  "Stages",
  "Tasks",
];

export const fordSupplyChainTabs = [
  "Overview",
  "Partners",
  "Stages",
  "Tasks",
];

export const FORD_PROJECT_DOWNLOAD = (isFordSupplyChainAutomation) => ({
  tabs: isFordSupplyChainAutomation ? fordSupplyChainTabs : fordTabs,
  isAllSelected: true,
});

export const PROJECT_DOWNLOAD = {
  tabs,
  isAllSelected: true,
};

import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./plants.module.scss";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { getProjectTabPlants, updateProjectReplications } from "modules/projects/project/project.action";
import FactoryPng from './factory.png';
import { useModelPopup } from "../../../../common/hooks";
import ForPossibleReplicationsModal
  from "../../../../common/components/FordPossibleReplications/ForPossibleReplicationsModal.component";
import PlantAreas from "modules/projects/project/plants/plantAreas";
import { plantTooltipTextsByState } from "modules/projects/project/plants/constants";
import PlantAndAreasStatuses from "modules/projects/project/plants/plantAndAreasStatuses";

const PlantsTab = ({ project }) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [canManageReplications, setCanManageReplications] = useState(false);
  const [projectId, setProjectId] = useState(null);

  useEffect(() => {
    setProjectId(project.id);

    return () => {
      setProjectId(null);
    }
  }, [project]);

  const {
    authReducer: { session },
    projectsReducer: {
      projectReducer: {
        plantsTab,
        overviewPossibleReplications
      },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!session) return;

    const isAdmin = ["ttp_administrator", "council_administrator"].includes(session?.council_role);
    setCanManageReplications(isAdmin || session?.spoc || session?.innovation_leader)
  }, [session]);

  useEffect(() => {
    if (projectId) {
      handleGetNewData();
    }
  }, [projectId]);

  const handleGetNewData = (data) => {
    if (data) {
      dispatch(updateProjectReplications(data));
    }
    dispatch(getProjectTabPlants(projectId));
  }

  const handleEditPlants = useCallback(() => {
    popup.show({
      title: "Possible Plants + Areas",
      component: (
        <ForPossibleReplicationsModal
          instance="Project"
          replicationsData={overviewPossibleReplications}
          handleSave={handleGetNewData}
          data={project}
        />
      ),
    });
  }, [overviewPossibleReplications, handleGetNewData, project]);

  const renderRegionPlants = useCallback((data) => {
    return data.map((item, index) => (
      <div className={`d-flex flex-column ${styles.w25} mt-1`} key={`${item.id}_${item.name}_${index}`}>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center w-50 mr-2">
            <img width="20" src={FactoryPng} alt={item.name} title={item.name} />
            {
              item.project ? (
                <Tooltip
                  title={item.reason}
                  placement="top"
                >
                  <Link className="ml-2" to={`/projects/${item.project.id}/overview`}>{item.name}</Link>
                </Tooltip>
              ) : (
                <Tooltip
                  title={item.reason}
                  placement="top"
                >
                  <span className="ml-2">{item.name}</span>
                </Tooltip>
              )
            }
          </div>
          <Tooltip
            title={plantTooltipTextsByState[item.status]}
            placement="top"
          >
            <div
              className={classNames(styles.plantCircle, styles[item.status])}></div>
          </Tooltip>
        </div>
        <PlantAreas plant={{
          ...item,
          areas: item.areas || item.plant.areas
        }} />
      </div>
    ));
  }, [overviewPossibleReplications, handleGetNewData, project])

  const renderRegions = useCallback((data, key) => {
    if (!data) {
      return null;
    }

    if (key === "no_region" && data?.length) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center mb-5" key={key}>
          <h4>No Region</h4>
          <div className="w-100 flex-wrap d-flex flex-row">
            {
              data.map(obj => (
                <div key={obj.name} className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center w-50 mr-2">
                      <img width="20" src={FactoryPng} alt={obj.name} title={obj.name} />
                      {
                        obj.project ? (
                          <Tooltip
                            title={obj.reason}
                            placement="top"
                          >
                            <Link className="mr-4 ml-2"
                                  to={`/projects/${obj.project.id}/overview`}>{obj.name}</Link>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={obj.reason}
                            placement="top"
                          >
                            <span className="mr-4 ml-2">{obj.name}</span>
                          </Tooltip>
                        )
                      }
                    </div>
                    <Tooltip
                      title={plantTooltipTextsByState[obj.status]}
                      placement="top"
                    >
                      <div className={classNames(styles.plantCircle, styles[obj.status])}></div>
                    </Tooltip>
                  </div>
                  <PlantAreas plant={obj} />
                </div>
              ))
            }
          </div>
          <hr className="d-block w-100 mt-5 mb-3" />
        </div>
      )
    }

    return data.map(region => {
      const combinedData = [
        ...region?.replications?.map(p => ({ ...p, replicated: true })) || [],
        ...region?.plants || [],
        ...region?.no_status_yet || [],
      ].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

      if (combinedData?.length === 0) return null;

      return (
        <div className="d-flex flex-column justify-content-center align-items-center mb-5" key={region.plant_region_name}>
          <div className="d-flex align-items-center">
            <h4 className="mb-0">{region.plant_region_name}</h4>
              {
                canManageReplications && (
                  <span className="d-block cursor-pointer ml-2" onClick={() => handleEditPlants()}>+</span>
                )
              }
          </div>
          <div className="w-100 flex-wrap d-flex flex-row">
            {renderRegionPlants(combinedData)}
          </div>
          <hr className="d-block w-100 mt-5 mb-3" />
        </div>
      )
    });
  }, [overviewPossibleReplications, handleGetNewData, project, renderRegionPlants, canManageReplications]);

  return (
    <div className={styles.plantsWrapper}>
      {
        plantsTab.isFetching ? (
          <div>
            <LoadingComponent customText="Fetching plants..." />
          </div>
        ) : (
          <div className="mt-4">
            {
              Object.keys(plantsTab.data).map((key, index) => {
                const category = plantsTab.data[key];

                if (key === "original_plant" && category) {
                  const current_plant = plantsTab.data.current_plant || {};

                  return (
                    <div className="d-flex flex-column justify-content-center align-items-center mb-5" key={`${key}_${index}`}>
                      <div className="d-flex flex-column align-items-center mb-4">
                        {
                          current_plant.id && (
                            <div className="d-flex align-items-center">
                              <img width="20" src={FactoryPng} alt={current_plant.name}
                                   title={current_plant.name} />
                              <h4 className="mb-0 ml-2 mr-3">{current_plant.name}</h4>
                              <Tooltip
                                title={plantTooltipTextsByState[current_plant.status]}
                                placement="top"
                              >
                                <div className={classNames(styles.plantCircle, styles[current_plant.status])}></div>
                              </Tooltip>
                            </div>
                          )
                        }
                        {
                          current_plant.areas.length ? (
                            <div className="d-flex flex-column mt-2">
                              {
                                current_plant.areas.map(area => (
                                  <div key={area.id} className="d-flex align-items-center justify-content-between">
                                    <span className="mr-3 d-block">{area.name}</span>
                                    <Tooltip
                                      title={plantTooltipTextsByState[area.status]}
                                      placement="top"
                                    >
                                      <div className={classNames(styles.plantCircle, styles[area.status])}></div>
                                    </Tooltip>
                                  </div>
                                ))
                              }
                            </div>
                          ) : null
                        }
                        <span
                          className="d-block mb-3">Region: {current_plant?.plant_region_name || "no region"}</span>
                        <div className="d-flex align-items-center">
                          <img width="20" src={FactoryPng} alt={category.name} title={category.name} />
                          {
                            category.project ? (
                              <Tooltip
                                title={category.reason}
                                placement="top"
                              >
                                <Link className="ml-1 mr-3"
                                      to={`/projects/${category.project.id}/overview`}>{category.name}</Link>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={category.reason}
                                placement="top"
                              >
                                <span className="d-block ml-1 mr-3">{category.name}</span>
                              </Tooltip>
                            )
                          }
                          <Tooltip
                            title={plantTooltipTextsByState[category.status]}
                            placement="top"
                          >
                            <div className={classNames(styles.plantCircle, styles[category.status])}></div>
                          </Tooltip>
                        </div>
                        {
                          category.areas.length ? (
                            <div className="d-flex flex-column mt-2">
                              {
                                category.areas.map(area => (
                                  <div key={area.id} className="d-flex align-items-center justify-content-between">
                                    <span className="mr-3 d-block">{area.name}</span>
                                    <Tooltip
                                      title={plantTooltipTextsByState[area.status]}
                                      placement="top"
                                    >
                                      <div className={classNames(styles.plantCircle, styles[area.status])}></div>
                                    </Tooltip>
                                  </div>
                                ))
                              }
                            </div>
                          ) : null
                        }
                      </div>
                      <PlantAndAreasStatuses />
                      <hr className="d-block w-100 mt-3 mb-3" />
                    </div>
                  )
                }

                if (key === 'current_plant' && category) {
                  const originalPlant = plantsTab.data['original_plant'];

                  if (originalPlant) return null;

                  return (
                    <div className="d-flex flex-column justify-content-center align-items-center mb-5"
                         key={`${key}_${index}`}>
                      <div className="d-flex flex-column align-items-center mb-4">
                        <div className="d-flex align-items-center">
                          <img width="20" src={FactoryPng} alt={category.name} title={category.name} />
                          {
                            category.project ? (
                              <Tooltip
                                title={category.reason}
                                placement="top"
                              >
                                <Link className="ml-1 mr-3"
                                      to={`/projects/${category.project.id}/overview`}>{category.name}</Link>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={category.reason}
                                placement="top"
                              >
                                <span className="d-block ml-1 mr-3">{category.name}</span>
                              </Tooltip>
                            )
                          }
                          <Tooltip
                            title={plantTooltipTextsByState[category.status]}
                            placement="top"
                          >
                            <div className={classNames(styles.plantCircle, styles[category.status])}></div>
                          </Tooltip>
                        </div>
                        <span
                          className="d-block mb-3">Region: {category?.plant_region_name || "no region"}</span>
                        {
                          category.areas.length ? (
                            <div className="d-flex flex-column mt-2">
                              {
                                category.areas.map(area => (
                                  <div key={area.id} className="d-flex align-items-center justify-content-between">
                                    <span className="mr-3 d-block">{area.name}</span>
                                    <Tooltip
                                      title={plantTooltipTextsByState[area.status]}
                                      placement="top"
                                    >
                                      <div className={classNames(styles.plantCircle, styles[area.status])}></div>
                                    </Tooltip>
                                  </div>
                                ))
                              }
                            </div>
                          ) : null
                        }
                      </div>
                      <PlantAndAreasStatuses />
                      <hr className="d-block w-100 mt-3 mb-3" />
                    </div>
                  )
                }

                return renderRegions(category, key);
              })
            }
          </div>
        )
      }
    </div>
  );
};

export default React.memo(PlantsTab);

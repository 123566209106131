import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  httpGet,
  httpPost,
  httpPut,
  queryString,
  httpDelete,
  httpPatch,
  apiStatus,
  errorHandler,
} from "../../../common/httpCall";

import {
  PROJECT_GET_ATTEMPT,
  projectGetSuccess,
  PROJECT_LIST_GET_ATTEMPT,
  projectListGetSuccess,
  PROJECT_ACTIVITIES_GET_ATTEMPT,
  projectActivitiesGetSuccess,
  PROJECT_PUT_ATTEMPT,
  projectPutSuccess,
  PROJECT_DELETE_ATTEMPT,
  projectDeleteSuccess,
  PROJECT_DELETE_MEMBER_ATTEMPT,
  projectDeleteMemberSuccess,
  PROJECT_MAKE_MEMBER_OWNER_ATTEMPT,
  makeMemberOwnerSuccess,
  createProjectDiscussionSuccess,
  CREATE_PROJECT_DISCUSSION,
  errorCatch,
  PROJECT_RATING_SET,
  projectRatingSetSuccess,
  GET_PROJECT_FIELD_GROUPS,
  getProjectFieldGroupsSuccess,
  GET_PROJECT_TAB_PLANTS,
  getProjectTabPlantsSuccess,
  GET_PROJECT_REPLICATIONS,
  getProjectReplicationsSuccess,
  GET_PROJECT_REPLICATION_STATUS,
  getProjectReplicationStatusSuccess,
  PROJECT_SETTINGS_GET_ATTEMPT,
  projectSettingsGetSuccess,
  PROJECT_SETTINGS_PUT_ATTEMPT,
  projectSettingsPutSuccess,
} from "./project.action";

export const epicSetProjectRating = (action$) =>
  action$.pipe(
    ofType(PROJECT_RATING_SET),
    switchMap(({ payload }) =>
      httpPost({
        call: `projects/${payload.project_id}/ratings`,
        data: payload.data,
      }).pipe(
        map((result) => projectRatingSetSuccess(result)),
        catchError((err) => errorHandler(err, payload?.enqueueSnackbar))
      )
    )
  );

export const epicGetProjectList = (action$) =>
  action$.pipe(
    ofType(PROJECT_LIST_GET_ATTEMPT),
    switchMap(
      ({
        payload: {
          councilId,
          pageSize,
          currentPage,
          userId,
          enqueueSnackbar,
          isDropdown,
          cb,
          original,
          query,
        },
      }) =>
        httpGet({
          call: `projects?${queryString({
            query,
            council_id: councilId,
            items: pageSize,
            page: currentPage,
            user_id: userId,
            is_dropdown: isDropdown,
            original,
          })}`,
        }).pipe(
          map((result) => {
            if (isDropdown) {
              if (cb) {
                cb(result.response);
              }

              return projectListGetSuccess(result);
            }

            try {
              return projectListGetSuccess(result);
            } catch (error) {
              console.log("-----????  catch ?????-----", error);
            }
          }),
          catchError((error) => {
            const customError = [
              {
                message: "Your session has expired",
                type: apiStatus.UNAUTHORIZED,
              },
            ];
            return errorHandler(error, enqueueSnackbar, customError);
          })
        )
    )
  );

export const epicGetProject = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_GET_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpGet({
        call: `projects/${id}`, // NEW_API
      }).pipe(
        map((result) => projectGetSuccess(result.response)),
        catchError((e) => errorHandler(e, enqueueSnackbar))
      )
    )
  );

export const epicGetProjectFieldGroups = (action$, state$) =>
  action$.pipe(
    ofType(GET_PROJECT_FIELD_GROUPS),
    switchMap(({ payload: { projectId } }) =>
      httpGet({
        call: `projects/${projectId}/project_fields`, // NEW_API
      }).pipe(
        map((result) => getProjectFieldGroupsSuccess(result)),
        catchError((e) => errorHandler(e))
      )
    )
  );

export const epicPutProject = (action$) =>
  action$.pipe(
    ofType(PROJECT_PUT_ATTEMPT),
    switchMap(
      ({
        payload: {
          prototype_date,
          proposal_for_prototype_expected_job_1_date,
          prototype_job_1_date,
          approval_status,
          implementation_date,
          expected_job_1_date,
          name,
          description,
          visibility,
          team_id,
          userId,
          councilId,
          industrys,
          topics,
          members,
          companies,
          projectId,
          startDate,
          endDate,
          status,
          country,
          project_members,
          disease_ids,
          rating_template_id,
          enqueueSnackbar,
          automatic_status,
          priority,
          next_steps,
          next_steps_created_at,
          next_steps_created_by_id,
          related_idea_id,
          active,
          idea_ids,
          cb,
          rating_enabled,
          project_company_rating_template_id,
          stage_id,
          plant_region_ids,
        },
      }) =>
        httpPut({
          apiVersion: "v2",
          call: `projects/${projectId}`, // NEW_API,
          data: {
            project: {
              prototype_date,
              proposal_for_prototype_expected_job_1_date,
              prototype_job_1_date,
              approval_status,
              implementation_date,
              expected_job_1_date,
              name,
              description,
              visibility,
              team_id,
              created_by_id: userId,
              council_id: councilId,
              industry_ids: industrys,
              topic_ids: topics,
              member_ids: members,
              project_companies_attributes: companies,
              start_date: startDate,
              end_date: endDate,
              status,
              project_members,
              country,
              disease_ids,
              rating_template_id,
              automatic_status,
              priority,
              next_steps,
              next_steps_created_at,
              next_steps_created_by_id,
              related_idea_id,
              active,
              idea_ids,
              rating_enabled,
              project_company_rating_template_id,
              stage_id,
              plant_region_ids,
            },
          },
        }).pipe(
          map((result) => {
            if (enqueueSnackbar) {
              enqueueSnackbar("Project edited with success", {
                variant: "success",
              });
            }
            if (cb) {
              cb();
            }
            return projectPutSuccess(result);
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicGetActivitiesProject = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_ACTIVITIES_GET_ATTEMPT),
    switchMap(
      ({ payload: { projectId, pageSize, currentPage, enqueueSnackbar } }) =>
        httpGet({
          call: `projects/${projectId}/activities?${queryString({
            items: pageSize,
            page: currentPage,
          })}`, // NEW_API,
        }).pipe(
          map((result) => projectActivitiesGetSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

// OLD

export const epicDeleteProject = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_DELETE_ATTEMPT),
    switchMap(({ payload: { projectName, enqueueSnackbar, projectId, cb } }) =>
      httpDelete({
        call: `projects/${projectId}`,
      }).pipe(
        map((result) => {
          if (cb) {
            cb(result);
          } else {
            enqueueSnackbar(`You deleted the Project: ${projectName}`, {
              variant: "success",
            });
          }

          return projectDeleteSuccess({ result, id: projectId });
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicDeleteMemberFromProject = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_DELETE_MEMBER_ATTEMPT),
    switchMap(
      ({ payload: { projectId, userId, enqueueSnackbar, externalMember } }) =>
        httpDelete({
          call: `projects/${projectId}/members/${userId}`,
          data: {
            externalMember: externalMember,
          },
        }).pipe(
          map((result) => projectDeleteMemberSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicMakeMemberOwner = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_MAKE_MEMBER_OWNER_ATTEMPT),
    switchMap(
      ({
        payload: {
          userId,
          projectId,
          active,
          role,
          externalMember,
          enqueueSnackbar,
        },
      }) =>
        httpPatch({
          call: `projects/${projectId}/members/${userId}`,
          data: {
            project_member: {
              project_id: projectId,
              user_id: userId,
              active,
              role,
              externalMember,
            },
          },
        }).pipe(
          map((result) => makeMemberOwnerSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicAddDiscussionToProject = (action$, state$) =>
  action$.pipe(
    ofType(CREATE_PROJECT_DISCUSSION),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        call: "discussions/projects",
        data,
      }).pipe(
        map((result) => {
          if (data.cb) {
            data.cb(result);
          }
          return createProjectDiscussionSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetProjectReplications = (action$) =>
  action$.pipe(
    ofType(GET_PROJECT_REPLICATIONS),
    switchMap(({ payload }) =>
      httpGet({
        call: `possible_replications/plants_and_areas?resource_id=${payload}&resource_type=Project`,
      }).pipe(
        map((res) => getProjectReplicationsSuccess(res)),
        catchError((error) => errorHandler(error, null, [], errorCatch))
      )
    )
  );

export const epicGetProjectTabPlants = (action$) =>
  action$.pipe(
    ofType(GET_PROJECT_TAB_PLANTS),
    switchMap(({ payload }) =>
      httpGet({
        apiVersion: "v2",
        call: `projects/${payload}/plants`,
      }).pipe(
        map((res) => getProjectTabPlantsSuccess(res)),
        catchError((error) => errorHandler(error, null, [], errorCatch))
      )
    )
  );

export const epicGetProjectReplicationStatus = (action$) =>
  action$.pipe(
    ofType(GET_PROJECT_REPLICATION_STATUS),
    switchMap(({ payload }) =>
      httpGet({
        call: `projects/${payload.id}/replication_status`,
      }).pipe(
        map((res) => getProjectReplicationStatusSuccess(res)),
        catchError((error) => errorHandler(error, null, [], errorCatch))
      )
    )
  );

export const epicGetProjectSettings = (action$) =>
  action$.pipe(
    ofType(PROJECT_SETTINGS_GET_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `projects/${payload.id}/settings`,
      }).pipe(
        map((res) => projectSettingsGetSuccess(res)),
        catchError((error) => errorHandler(error, null, [], errorCatch))
      )
    )
  );
export const epicPutProjectSettings = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_SETTINGS_PUT_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPut({
        call: `projects/${data.id}/settings`,
        data,
      }).pipe(
        map((result) => {
          return projectSettingsPutSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
const projectEpic = combineEpics(
  epicSetProjectRating,
  epicGetProjectList,
  epicGetProject,
  epicGetActivitiesProject,
  epicPutProject,
  epicDeleteProject,
  epicDeleteMemberFromProject,
  epicMakeMemberOwner,
  epicAddDiscussionToProject,
  epicGetProjectFieldGroups,
  epicGetProjectTabPlants,
  epicGetProjectReplications,
  epicGetProjectReplicationStatus,
  epicGetProjectSettings,
  epicPutProjectSettings
);

export default projectEpic;

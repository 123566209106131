import React from "react";
import { Field } from "formik";
import styles from "./form-generator.module.scss";

const CheckBoxInput = ({ field, formikprops }) => {
  const {
    id,
    field_name: fieldName,
    field_label: fieldLabel,
    option_values: optionValues,
    allow_multiselect: allowMultiselect,
  } = field;

  const label = fieldLabel || fieldName;
  const {
    setFieldValue, errors, touched, values,
  } = formikprops;
  const isInvalid = errors[id] && touched[id];

  const handleChange = (e) => {
    if (allowMultiselect) {
      if (!values[id].includes(e.target.value) && e.target.checked) {
        setFieldValue(id, [...values[id], e.target.value]);
      } else {
        setFieldValue(
          id,
          values[id].filter((option) => option !== e.target.value),
        );
      }
    } else {
      setFieldValue(id, [e.target.value]);
    }
  };

  return (
    <div className={styles.formGroup} key={`field-${id}`}>
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      {optionValues.map((option, i) => (
        <div className={styles.formCheck} key={`field-${id}-${option}-${i}`}>
          <label className={styles.formCheckLabel} htmlFor={`input${id}-${i}`}>
            <Field
              id={`input${id}-${i}`}
              name={id}
              type={allowMultiselect ? "checkbox" : "radio"}
              className={styles.formCheckInput}
              value={option}
              checked={values[id].includes(option)}
              onChange={handleChange}
            />
            {option}
          </label>
        </div>
      ))}
      {isInvalid && <div className={styles.invalidFeedback}>{errors[id]}</div>}
    </div>
  );
};

export default CheckBoxInput;

const isFerminichCouncil = (initialText, textToChange, selectedCouncil) => {
  if (selectedCouncil?.name === "Firmenich") {
    return textToChange;
  }
  return initialText;
};

const checkIfFirmenich = (selectedCouncil, selectedProject, type) => {
  return !!(selectedCouncil?.name === "Firmenich" && selectedProject?.stage_template?.default_template);
}

export {
  checkIfFirmenich,
  isFerminichCouncil
};

import { combineEpics } from "redux-observable";
import userEpic from "./project/users/user.epic";
import projectEpic from "./project/project.epic";
import stagesEpic from "./project/stages/stages.epic";
import tasksEpic from "./project/tasks/tasks.epic";
import companyEpic from "./project/companies/companies.epic";
import companyCompairEpic from "./project/companies/compair/compair.epic";
import projectsMainEpic from "./projectsMain/projectsMain.epic";
import overviewEpic from "./project/overview/overview.epic";
import projectRatingsEpic from "./project/manageProject/ratings/ratings.epic";

const projectsEpic = combineEpics(
  userEpic,
  projectEpic,
  stagesEpic,
  tasksEpic,
  companyEpic,
  companyCompairEpic,
  projectsMainEpic,
  overviewEpic,
  projectRatingsEpic,
);

export default projectsEpic;

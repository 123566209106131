import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { of, throwError } from "rxjs";

import { ModalFooter, ModalBody } from "../../../../application/modal";
import { appModelSettingSet } from "../../../../application/app.action";
import { Button, BtnType } from "../../../primitives";
import styles from "./myLists.module.scss";
import { httpPut, apiStatus } from "../../../../common/httpCall";
import {
  companySavedListGetAttempt,
  changeSelectedListName,
} from "../../store/lists.actions";
import { setErrorHandledSuccess } from "../../../companies/companyMain/companyMain.action";

const EditListName = ({
  selectedList,
  selectedListName,
  setListNameLoading,
}) => {
  const dispatch = useDispatch();
  const [listName, setListName] = useState(selectedListName);
  const { enqueueSnackbar } = useSnackbar();
  const {
    appReducer: { modalSetting },
  } = useSelector((state) => state);

  const handleCancelClick = () => {
    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
  };

  const handleSaveClick = () => {
    setListNameLoading(true);
    const editListName = () => {
      httpPut({
        call: `lists/${selectedList}`,
        data: {
          list: {
            name: listName,
            private: true,
          },
        },
      }).subscribe(
        () => {
          dispatch(changeSelectedListName(listName));
          dispatch(
            companySavedListGetAttempt({
              page: 1,
              enqueueSnackbar,
            })
          );
          setListNameLoading(false);
        },
        (error) => {
          if (error.status === apiStatus.UNPROCESSABLE_ENTITY) {
            if (error.response.errors.messages.name[0] === "must be unique") {
              enqueueSnackbar(
                "A List with that name already exists, please choose another name.",
                {
                  variant: "error",
                }
              );
              return of(setErrorHandledSuccess());
            }
            enqueueSnackbar("Something went wrong", {
              variant: "error",
            });
          } else {
            console.log("error epicNewSavedList: ", error);
            if (error) {
              enqueueSnackbar("Something went wrong", {
                variant: "error",
              });
            }
            return throwError(error);
          }
        },
        () => console.log("HTTP request completed.")
      );
    };
    editListName();
    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
  };

  return (
    <div className={styles.wrapper}>
      <ModalBody>
        <>
          <input
            value={listName}
            onChange={(e) => {
              setListName(e.target.value);
            }}
            className={styles.textBox}
            placeholder="Enter list`s name"
          />
        </>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          type="submit"
          btn={BtnType.REGULAR}
          onClick={() => handleSaveClick()}
        >
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default React.memo(EditListName);

import { getCookie } from "common/helper";

export const checkViewerRole = (council_role_from_parrent = "") => {
  const council = localStorage.getItem("council");
  const councilParced = JSON.parse(council);
  const councilName = councilParced.name;

  if (councilName === "Kyndryl" || councilName === "HomeX") {
    return false;
  }
  if (council_role_from_parrent) {
    return council_role_from_parrent === "viewer";
  }
  const councilRole = getCookie("council_role_");
  return councilRole === "viewer";
};

import { httpPut } from "common/httpCall";
import { getCookie } from "common/helper";

export const getTokenPopup = (instance, request, users_integrations) => {
  const user_id = getCookie("user_id_");

  const microsoftData = users_integrations?.find(
    (elem) => elem.integration_name === "Microsoft"
  );

  return instance.acquireTokenSilent(request).catch((error) => {
    console.warn(
      "silent token acquisition fails. acquiring token using redirect"
    );
    if (error.name === "InteractionRequiredAuthError") {
      // fallback to interaction when silent call fails
      return instance
        .acquireTokenPopup(request)
        .then((tokenResponse) => {
          if (microsoftData) {
            const token = {};
            const keys = Object.keys(localStorage).filter((key) =>
              key.includes(tokenResponse.uniqueId)
            );
            keys.forEach((element) => {
              token[element] = localStorage.getItem(element);
            });
            const params = {
              integration_id: microsoftData.integration_id,
              user_id,
              token,
            };
            httpPut({
              call: `users/${user_id}/integrations/${microsoftData.id}`,
              data: {
                user_integration: { ...params },
              },
            })
              .pipe()
              .subscribe((result) => result);
          }
          return tokenResponse;
        })
        .catch((e) => {
          console.error(e);
        });
    }
    console.warn(error);
  });
};

export const getMsGraphCall = async (accessToken, endpoint) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("ConsistencyLevel", "eventual");

  const options = {
    method: "GET",
    headers,
  };

  return fetch(endpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import { ModalFooter, ModalBody } from "../../../application/modal";
import {
  Button,
  BtnType,
  Label,
  DropSelect,
  TextBox,
} from "../../primitives";
import { httpPost, errorHandler } from "../../../common/httpCall"
import { initModel, validation, roles } from "./newProjectMember.constants";
import styles from "./newProjectMember.module.scss";
import { useModelPopup } from "../../../common/hooks";
import {
  projectUsersAdd,
  projectMultipleUsersAdd,
} from "../../projects/project/users/user.action";
import { projectGet } from "../../projects/project/project.action";
import { companyListGet } from "../../companies/companyMain/companyMain.action";
import UserMultiSelectSearch from "../../../common/components/usersSearch";

const NewProjectMemberComponent = (props) => {
  const { defaultModel = {}, projectUsersList = [], multiple } = props;
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [model, setmodel] = useState(initModel);
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [toggle, setToggle] = useState(false);

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    projectsReducer: {
      projectReducer: { selectedProject },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (Object.keys(defaultModel).length > 0) {
      setmodel({
        ...initModel,
        ...defaultModel,
      });
    }
  }, [defaultModel]);

  useEffect(() => {
    dispatch(
      companyListGet({
        councilId: selectedCouncil.id,
        items: 20,
        enqueueSnackbar,
      }),
    );
  }, [selectedCouncil]);

  const handleCancelClick = (e) => {
    popup.hide();
  };

  const handleSaveClick = (e) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleOnSubmit = (value) => {
    if (multiple && !toggle) {
      const payload = {
        projectId: selectedProject.id,
        role: value.role,
        enqueueSnackbar,
        project_members: value.memberId.map((member) => ({
          project_id: selectedProject.id,
          user_id: member.id,
        })),
      };

      dispatch(projectMultipleUsersAdd(payload));

      const timeout = setTimeout(() => {
        dispatch(projectGet({ id: selectedProject.id, enqueueSnackbar }));
        popup.hide();
        clearTimeout(timeout);
      }, 1000);
    } else {
      ( !toggle ?
      dispatch(
        projectUsersAdd({
          projectId: selectedProject.id,
          enqueueSnackbar,
          ...value,
        }),
      ) :
      addExternalProjectMember(value)
      )
      popup.hide();
    }
  };

  const adminRights = useMemo(() => {
    const currentRole = selectedProject.project_members?.find(
      (item) => item?.user?.id === session.id,
    )?.user_role;
    return currentRole !== "member";
  }, [selectedProject]);

  const handleAddMoreFieldsClick = (setFieldValue) => {
    setToggle(!toggle);
    setFieldValue("externalMember", true);
  };

  const addExternalProjectMember = (values) => {
    const dataValues = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      title: values.title,
      organization: values.organization,
    };
    httpPost({
      call: `projects/${selectedProject.id}/external_project_member`,
      data: dataValues,
    })
    .pipe(
      catchError((err) => {
        errorHandler(err, enqueueSnackbar)
        return of(null)
      })
    )
    .subscribe((res) => {
      if (res) {
        dispatch(
          projectUsersAdd({
            data: {
              external_member_id: res?.response?.id,
            },
            projectId: selectedProject.id,
            enqueueSnackbar,
            ...values,
          }),
        );
      }
    });
  };

  return (
    <>
      <ModalBody>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={model && model}
          validationSchema={validation && validation}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleOnSubmit(values);
          }}
        >
          {({ handleSubmit, setFieldValue, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              <>
                <div className={styles.editStageFormWrp}>
                  {
                    !toggle && (
                      <UserMultiSelectSearch
                        multi={multiple}
                        placeholder="Choose project member"
                        labelField="full_name"
                        valueField="id"
                        searchBy="full_name"
                        hasAll={false}
                        onChange={(val) => {
                          setFieldValue("memberId", val);
                        }}
                        filterBy={projectUsersList.map(u => ({...u, ...u.user, user_id: u.user.id}))}
                      />
                    )
                  }

                  {adminRights && !toggle && (
                    <div>
                      <Label>Role</Label>
                      <DropSelect
                        placeholder="Choose a role"
                        name="role"
                        labelField="text"
                        valueField="val"
                        searchBy="text"
                        options={roles}
                        formProps={formikprops}
                        onChange={(e) => {
                          setFieldValue("role", e.val);
                        }}
                      />
                    </div>
                  )}
                </div>
                <span className={styles.addFieldsButton}>
                  <span onClick={() => handleAddMoreFieldsClick(setFieldValue)}>
                    {!toggle && (
                      <div className={styles.addRelationshipBtn}>
                        + Add External Project Member
                      </div>
                    )}
                  </span>
                </span>
                {toggle && (
                  <>
                    <input
                      type="hidden"
                      value="testing"
                      name="externalMember"
                    />
                    <div className="row">
                      <div className="col-12">
                        <Label>First Name</Label>
                        <TextBox
                          type="text"
                          name="firstName"
                          placeholder="Enter first name"
                          className={styles.textBox}
                          formProps={formikprops}
                          onChange={(e) => {
                            setFieldValue("firstName", e.val);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Label>Last Name</Label>
                        <TextBox
                          type="text"
                          name="lastName"
                          placeholder="Enter last name"
                          className={styles.textBox}
                          formProps={{ ...formikprops }}
                          onChange={(e) => {
                            setFieldValue("lastName", e.val);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Label>Email</Label>
                        <TextBox
                          type="text"
                          name="email"
                          placeholder="Enter email"
                          className={styles.textBox}
                          formProps={{ ...formikprops }}
                          onChange={(e) => {
                            setFieldValue("email", e.val);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Label>Title</Label>
                        <TextBox
                          type="text"
                          name="title"
                          placeholder="Enter title"
                          className={styles.textBox}
                          formProps={{ ...formikprops }}
                          onChange={(e) => {
                            setFieldValue("title", e.val);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Label>Organization</Label>
                        <TextBox
                          type="text"
                          name="organization"
                          placeholder="Enter organization"
                          className={styles.textBox}
                          formProps={{ ...formikprops }}
                          onChange={(e) => {
                            setFieldValue("organization", e.val);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            </form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button btn={BtnType.REGULAR} onClick={handleSaveClick}>
          Add member
        </Button>
      </ModalFooter>
    </>
  );
};

export default NewProjectMemberComponent;

import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { httpGet, errorHandler, httpPut } from "../../../common/httpCall";
import {
  GET_CLAIM_PROFILE_OVERVIEW_ATTEMPT,
  getClaimProfileOverviewSuccess,
  UPDATE_CLAIM_PROFILE_ATTEMPT,
  updateClaimProfileSuccess,
} from "./claimProfileActions";
import rfiEpic from "../informationRequests/store/rfiEpic";

export const epicGetClaimProfileOverview = (action$) => action$.pipe(
  ofType(GET_CLAIM_PROFILE_OVERVIEW_ATTEMPT),
  switchMap(({ payload }) => httpGet({
    call: `companies/${payload.id}?council_id=${payload.councilId}`,
  }).pipe(
    map((result) => {
      if (payload.cb) {
        payload.cb(result?.response);
      }
      return getClaimProfileOverviewSuccess(result);
    }),
    catchError((err) => errorHandler(err)),
  )),
);

export const epicUpdateClaimProfile = (action$) => action$.pipe(
  ofType(UPDATE_CLAIM_PROFILE_ATTEMPT),
  switchMap(
    ({
      payload: {
        councilId, companyId, data, enqueueSnackbar, callBack,
      },
    }) => httpPut({
      call: `councils/${councilId}/companies/${companyId}?from_company_contact=true`,
      data: {
        council_company: {
          ...data,
        },
      },
    }).pipe(
      map((res) => {
        if (callBack) {
          callBack(res.response);
        }
        return updateClaimProfileSuccess(res);
      }),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

const claimProfileEpic = combineEpics(
  epicGetClaimProfileOverview,
  epicUpdateClaimProfile,
  rfiEpic,
);

export default claimProfileEpic;

import React from "react";
import { debounce } from "throttle-debounce";
import styles from "./ideaListSearch.module.scss";

const IdeaListSearch = ({ onChange }) => {
  const debounceFunc = debounce(1000, false, (value) => {
    onChange(value);
  });

  const handleChange = (e) => {
    debounceFunc(e.target.value);
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        type="text"
        className={styles.searchInput}
        placeholder="Search Ideas"
        onChange={handleChange}
      />
    </div>
  );
};

export default React.memo(IdeaListSearch);

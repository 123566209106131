import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  httpGet,
  httpPost,
  httpDelete,
  errorHandler,
  httpPut,
} from "../../../../../../common/httpCall";
import {
  PUT_FEED_ATTEMPT,
  putFeedSuccess,
  GET_FEED_ATTEMPT,
  getFeedSuccess,
  SELECT_REPLY_AS_FAVORITE_ATTEMPT,
  selectReplyAsFavoriteSuccess,
  DESELECT_REPLY_AS_FAVORITE_ATTEMPT,
  deselectReplyAsFavoriteSuccess,
  POST_REPLY_GET_ATTEMPT,
  postsReplyGetSuccess,
  POST_REPLY_ADD_ATTEMPT,
  postsReplyAddSuccess,
  POST_REPLY_EDIT_ATTEMPT,
  postsReplyEditSuccess,
  POST_REPLY_DELETE_ATTEMPT,
  postsReplyDeleteSuccess,
  LIKE_REPLY_ATTEMPT,
  likeReplySuccess,
  MENTION_POST_ATTEMPT,
  mentionPostSuccess,
  DISLIKE_REPLY_ATTEMPT,
  dislikeReplySuccess,
} from "./feed.action";

export const epicEditFeed = (action$, state$) =>
  action$.pipe(
    ofType(PUT_FEED_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPut({
        // apiVersion: "v1",
        // call: `agreement_types`,
        // data: { ...data },
      }).pipe(
        map((result) => putFeedSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
export const epicGetFeed = (action$, state$) =>
  action$.pipe(
    ofType(GET_FEED_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpGet({
        // apiVersion: "v1",
        // call: `agreement_types`,
      }).pipe(
        map((result) => getFeedSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
export const epicSelectReplyAsFavorite = (action$, state$) =>
  action$.pipe(
    ofType(SELECT_REPLY_AS_FAVORITE_ATTEMPT),
    switchMap(({ payload: { theme_id, post_id, reply_id, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/${theme_id}/posts/${post_id}/replies/${reply_id}/favorites`,
        // data: { ...data },
      }).pipe(
        map((result) => selectReplyAsFavoriteSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicDeselectReplyAsFavorite = (action$, state$) =>
  action$.pipe(
    ofType(DESELECT_REPLY_AS_FAVORITE_ATTEMPT),
    switchMap(({ payload: { theme_id, post_id, reply_id, enqueueSnackbar } }) =>
      httpDelete({
        // apiVersion: "v1",
        call: `themes/${theme_id}/posts/${post_id}/replies/${reply_id}/favorites`,
        // data: { ...data },
      }).pipe(
        map((result) => deselectReplyAsFavoriteSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetPostReplies = (action$, state$) =>
  action$.pipe(
    ofType(POST_REPLY_GET_ATTEMPT),
    switchMap(({ payload: { theme_id, post_id, enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v1",
        call: `themes/${theme_id}/posts/${post_id}`,
      }).pipe(
        map((result) => postsReplyGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
export const epicAddReplyPost = (action$, state$) =>
  action$.pipe(
    ofType(POST_REPLY_ADD_ATTEMPT),
    switchMap(({ payload: { theme_id, post_id, data, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/${theme_id}/posts/${post_id}/replies`,
        data: { ...data },
      }).pipe(
        map((result) => postsReplyAddSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
export const epicEditPostReply = (action$, state$) =>
  action$.pipe(
    ofType(POST_REPLY_EDIT_ATTEMPT),
    switchMap(
      ({
        payload: { theme_id, post_id, reply_id, data, type, enqueueSnackbar },
      }) =>
        httpPut({
          call: `themes/${theme_id}/posts/${post_id}/replies/${reply_id}`,
          data: { ...data },
        }).pipe(
          map(
            (result) => {
              if (enqueueSnackbar) {
                enqueueSnackbar(`Reply editted successful`, {
                  variant: "success",
                });
              }
              return postsReplyEditSuccess(result);
            },
            catchError((err) => errorHandler(err, enqueueSnackbar))
          )
        )
    )
  );

export const epicDeletePostReply = (action$, state$) =>
  action$.pipe(
    ofType(POST_REPLY_DELETE_ATTEMPT),
    switchMap(({ payload: { themeId, postId, replyId, enqueueSnackbar } }) =>
      httpDelete({
        apiVersion: "v1",
        call: `themes/${themeId}/posts/${postId}/replies/${replyId}`,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Reply deleted with success", {
              variant: "success",
            });
          }
          return postsReplyDeleteSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
export const epicLikeReply = (action$, state$) =>
  action$.pipe(
    ofType(LIKE_REPLY_ATTEMPT),
    switchMap(({ payload: { theme_id, post_id, reply_id, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/${theme_id}/posts/${post_id}/replies/${reply_id}/likes`,
        // data: { ...data },
      }).pipe(
        map((result) => likeReplySuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicdisLikeReply = (action$, state$) =>
  action$.pipe(
    ofType(DISLIKE_REPLY_ATTEMPT),
    switchMap(({ payload: { theme_id, post_id, reply_id, enqueueSnackbar } }) =>
      httpDelete({
        apiVersion: "v1",
        call: `themes/${theme_id}/posts/${post_id}/replies/${reply_id}/likes`,
        // data: { ...data },
      }).pipe(
        map((result) => dislikeReplySuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
export const epicMentionPost = (action$, state$) =>
  action$.pipe(
    ofType(MENTION_POST_ATTEMPT),
    switchMap(({ payload: { data, theme_id, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/${theme_id}/posts`,
        data: { ...data },
      }).pipe(
        map((result) => mentionPostSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
const themesFeedEpic = combineEpics(
  epicEditFeed,
  epicGetFeed,
  epicSelectReplyAsFavorite,
  epicDeselectReplyAsFavorite,
  epicAddReplyPost,
  epicEditPostReply,
  epicDeletePostReply,
  epicGetPostReplies,
  epicLikeReply,
  epicMentionPost,
  epicdisLikeReply
);

export default themesFeedEpic;
// const { enqueueSnackbar } = useSnackbar();

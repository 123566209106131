import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import Select from "react-dropdown-select";

import styles from "../../../../../scss/multiselect/multiselect.module.scss";
import { httpGet } from "../../../../../common/httpCall";
import { BtnType, Button } from "../../../../primitives";
import { useModelPopup } from "../../../../../common/hooks";
import AddNewLocation from "./addNewLocation";

const LocationSelectSearchAndAdd = (props) => {
  const {
    className, name, onChange, formValues, onAddNew, ...rest
  } = props;

  const popup = useModelPopup();
  const ref = useRef();

  const [query, setQuery] = useState(null);
  const [results, setResults] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [localSelectedLocation, setLocationSelectedLocation] = useState([]);
  const [allowReq, setAllowReq] = useState(true);

  useEffect(() => {
    if (formValues?.location) {
      setAllowReq(true);
      setLocationSelectedLocation([
        {
          name: formValues.location.split(",")[0],
        },
      ]);
    }
  }, [formValues]);

  useEffect(() => {
    if (ref.current) {
      const input = ref.current.querySelector("input");

      if (input) {
        input.addEventListener("keyup", handleSearch);
      }
    }

    return () => null;
  }, [ref]);

  useEffect(() => {
    if (localSelectedLocation?.length) {
      setQuery(localSelectedLocation[0].name);
    }
  }, [localSelectedLocation]);

  useEffect(() => {
    if (query) {
      requestNewData(query);
    }
  }, [query]);

  const handleSearch = (evt) => {
    setQuery(evt.target.value);
  };

  const requestNewData = useCallback(
    (query) => {
      if (!allowReq) return;

      setShowLoading(true);

      const url = `companies/locations?query=${query}`;

      httpGet({
        call: url,
      })
        .pipe()
        .subscribe((res) => {
          setResults(res.response);
          setShowLoading(false);
        });
    },
    [allowReq],
  );

  const handleAddNewLocation = (locationObj) => {
    setAllowReq(false);
    setLocationSelectedLocation([locationObj]);
    setResults([locationObj]);
    onAddNew(locationObj);
  };

  const handleAddNewLocationClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    popup.show({
      title: "Add new location",
      component: <AddNewLocation handleAddNewLocation={handleAddNewLocation} />,
    });
  };

  const dropdownRenderer = useCallback(
    ({ props, state, methods }) => {
      if (showLoading) {
        return (
          <div className="d-flex flex-column react-dropdown-select-dropdown">
            <span className="p-3">Loading locations...</span>
          </div>
        );
      }

      return (
        <div className="d-flex flex-column react-dropdown-select-dropdown">
          {props.options.length ? (
            props.options.map((item, idx) => {
              const isSelected = state.values.some(
                (val) => val.name === item.name,
              );

              return (
                <div
                  onClick={() => (isSelected ? null : methods.addItem(item))}
                  key={`${item.name}-${idx}`}
                  className={`react-dropdown-select-item d-flex flex-column ${
                    isSelected
                      ? "react-dropdown-select-item-selected selected-location"
                      : ""
                  }`}
                >
                  <span
                    className="location-main"
                    aria-label={item[props.labelField]}
                  >
                    {item[props.labelField]}
                  </span>
                  <span className="location-secondary">
                    Location • {item.description}
                  </span>
                </div>
              );
            })
          ) : !query || !query.length ? (
            <span className="p-3">Search a location</span>
          ) : (
            <div className="p-3 d-flex flex-column align-items-center justify-content-center">
              <span className="d-block mb-3">Not found in database</span>
              <Button
                btn={BtnType.REGULAR}
                className={styles.button}
                icon="icn-add"
                onClick={handleAddNewLocationClick}
              >
                ADD NEW LOCATION
              </Button>
            </div>
          )}
        </div>
      );
    },
    [results, query, showLoading],
  );

  const onNewSelection = (value) => {
    if (!value.length) {
      onChange([], true);
      setQuery(null);
      setResults([]);
      return;
    }

    onChange(value);
  };

  const handleDropOpen = useMemo(() => {
    if (!localSelectedLocation.length) {
      return;
    }

    const elementToGo = document.querySelector(
      `#locationSearch .react-dropdown-select-dropdown [aria-label="${localSelectedLocation[0].name}"]`,
    );

    if (elementToGo) {
      elementToGo.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [localSelectedLocation]);

  return (
    <div className={styles.multiWrp}>
      <div className={styles.container} ref={ref} id="locationSearch">
        <Select
          closeOnSelect
          placeholder="Search a location"
          values={localSelectedLocation}
          className={styles.dorpWrp}
          options={results}
          labelField="name"
          valueField="name"
          onChange={onNewSelection}
          dropdownRenderer={(innerProps, innerState, innerMethods) => dropdownRenderer(innerProps, innerState, innerMethods)}
          dropdownPosition="auto"
          clearable
          onDropdownOpen={handleDropOpen}
          onClearAll={() => onChange([])}
          {...rest}
        />
      </div>
    </div>
  );
};

export default React.memo(LocationSelectSearchAndAdd);

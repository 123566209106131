import React, { useState, useEffect } from "react";
import classNames from "classnames";

import styles from "./videos.module.scss";
import { Button, BtnType } from "../../../primitives";
import { videoExtensions } from "../viewEvent.constant";

const VideosComponent = ({ event }) => {
  const [videoIndex, setCurentVideoIndex] = useState(0);
  const [isEmbedded, setIsEmbedded] = useState();
  const [videoUrl, setVideoUrl] = useState("");

  const handlePagingChangePrev = () => {
    if (videoIndex > 0) {
      setCurentVideoIndex((prev) => prev - 1);
    } else {
      alert("This is first video");
    }
  };

  const getUrlExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  const handlePagingChangeNext = () => {
    if (videoIndex < event.video_uri.length - 1) {
      setCurentVideoIndex((prev) => prev + 1);
    } else {
      alert("This is the last page");
    }
  };

  const checkPlatform = () => {
    const url = event.video_uri[videoIndex];
    const regExpYoutube = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const regExpVimeo = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    const regExpFacebook = /^https?:\/\/www\.facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;

    if (url.includes("iframe")) {
      return "iframe";
    }
    if (url.match(regExpYoutube)) {
      return "youtube";
    }
    if (url.match(regExpVimeo)) {
      return "vimeo";
    }
    if (url.match(regExpFacebook)) {
      return "facebook";
    }
  };

  const getYoutubeId = (url) => {
    let ID = "";
    url = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    } else {
      ID = url;
    }
    return ID;
  };

  const getVimeoId = (url) => {
    const match = /vimeo.*\/(\d+)/i.exec(url);

    if (match) {
      return match[1];
    }
  };

  const getFacebookId = (url) => {
    const match = /facebook.com.*\/(\d+)/i.exec(url);
    if (match) {
      return match[1];
    }
  };

  const getIframeSrc = (url) => {
    const match = /src\s*=\s*"([^"]+)"/i.exec(url);
    if (match) {
      return match[1];
    }
  };

  const handleIsEmbedded = () => {
    if (
      videoExtensions.indexOf(getUrlExtension(event.video_uri[videoIndex]))
      === -1
    ) {
      setIsEmbedded(true);
      if (checkPlatform() === "youtube") {
        setVideoUrl(
          `https://www.youtube.com/embed/${getYoutubeId(
            event.video_uri[videoIndex],
          )}`,
        );
      }
      if (checkPlatform() === "vimeo") {
        setVideoUrl(
          `https://player.vimeo.com/video/${getVimeoId(
            event.video_uri[videoIndex],
          )}`,
        );
      }
      if (checkPlatform() === "facebook") {
        setVideoUrl(
          `https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FTMNTfilm%2Fvideos%2Fvb.1398909827041343%2F${getFacebookId(
            event.video_uri[videoIndex],
          )}%2F%3Ftype%3D3&show_text=0&width=889&height=500`,
        );
      }
      if (checkPlatform() === "iframe") {
        setVideoUrl(getIframeSrc(event.video_uri[videoIndex]));
      }
    } else {
      setIsEmbedded(false);
    }
  };

  useEffect(() => {
    if (event.video_uri[videoIndex]) {
      handleIsEmbedded();
    }
  }, [videoIndex, event]);

  return (
    <div className={styles.panelWrp}>
      <h4 className={styles.headerText}>Videos</h4>
      <div className={classNames(styles.panel, styles.vedioPanel)}>
        {event && event.video_uri && event.video_uri && !isEmbedded ? (
          <div className={styles.videoCon}>
            <video
              width="100%"
              controls
              loop
              autoPlay=""
              key={event.video_uri[videoIndex]}
            >
              <source
                src={event && event.video_uri && event.video_uri[videoIndex]}
              />
              Your browser does not support video.
            </video>
          </div>
        ) : (
          <div className={styles.iframeContainer}>
            {event.video_uri
            && event.video_uri[videoIndex]
            && checkPlatform() === "facebook" ? (
              <iframe
                src={videoUrl}
                width="100%"
                height="500"
                className={styles.responsiveIframe}
              />
              ) : (
                <iframe
                  width="100%"
                  height="480"
                  src={videoUrl}
                  frameBorder="0"
                  className={styles.responsiveIframe}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded videos"
                />
              )}
          </div>
        )}
        {event && event.video_uri && event.video_uri.length > 1 && (
          <div className={styles.controlsWrp}>
            <div className={styles.totals}>
              {`${videoIndex + 1} of  ${event.video_uri.length}`}{" "}
            </div>
            <div className={styles.buttonWrp}>
              <Button
                btn={BtnType.FRAME_LESS}
                className={styles.SeeAllSpeakers}
                onClick={() => {
                  handlePagingChangePrev();
                }}
              >
                Prev
              </Button>
              <Button
                btn={BtnType.FRAME_LESS}
                className={styles.SeeAllSpeakers}
                onClick={() => {
                  handlePagingChangeNext();
                }}
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(VideosComponent);

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { Formik, Form, FieldArray } from "formik";
import { httpGet, httpPost } from "common/httpCall";
import Switch from "react-switch";
import { useSnackbar } from "notistack";
import { Select } from "react-dropdown-select";
import { DropSelect, BtnType, Button as Btn } from "../../primitives";
import styles from "./configureAccounts.module.scss";
import MapFieldValues from "./mapFieldValues";
import MapCustomFields from "./mapCustomFields";
import ConfigureExportAccounts from "./configureExports/configureExportAccounts";
import {
  preSelectedCompanyValues,
  selectedSalesForceValues,
  companyFieldsToSubtract as comFieldsToSubtract,
} from "./preSelectedValues/accountsPreSelectedValues";
import { useModelPopup } from "../../../common/hooks/index";
import SyncMappingInfoModal from "./Modals/syncMappingsInfoModal";

const ConfigureAccounts = () => {
  const [selectedSfValues, setSelectedSfValues] = useState([]);
  const [selectedCompanyValues, setSelectedCompanyValues] = useState([]);
  const [isSelectedSfValue, setIsSelectedSfValue] = useState(true);
  const [isSelectedCompanyValue, setIsSelectedCompanyValue] = useState(true);
  const [selectedSfValue, setSelectedSfValue] = useState({});
  const [selectedCompanyValue, setSelectedCompanyValue] = useState({});
  const [sfFields, setSfFields] = useState([]);
  const [coFields, setCoFields] = useState([]);
  const [typeMatch, setTypeMatch] = useState([]);
  const [, setIsDropOpened] = useState(false);
  const [isChildDropOpened, setIsChildDropOpened] = useState(false);
  const [childDropDownOptions, setChildDropDownOptions] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [ownerFilter, setOwnerFilter] = useState([]);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [opportunityFilter, setOpportunityFilter] = useState([]);
  const [opportunityOptions, setOpportunityOptions] = useState([]);
  const [filterValue, setFilterValue] = useState([]);
  const [relationshipStage, setRelationshipStage] = useState([]);
  const [filterOption, setFilterOption] = useState(null);
  const [subtractedCompanyFields, setSubtractedCompanyFields] = useState([]);
  const [mapToggle, setMapToggle] = useState(false);
  const [checked, setChecked] = useState(true);
  const [mapCustomFieldToggle, setMapCustomFieldToggle] = useState(false);
  const [companyFieldsToSubtract, setCompanyFieldsToSubtract] = useState([]);
  const [accountSyncToggle, setAccountSyncToggle] = useState(false);
  const [friendsArray, setFriendsArray] = useState([]);
  const [mappings, setMappings] = useState(false);
  const [accountLimit, setAccountLimit] = useState(null);
  const [
    selectedRelationshipStatusValues,
    setSelectedRelationshipStatusValues,
  ] = useState([]);
  const [selectedOpportunityStagePayload, setSelectedOpportunityStagePayload] =
    useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSfCustomFieldValues, setSelectedSfCustomFieldValues] =
    useState([]);
  const [selectedTtpCustomFieldValues, setSelectedTtpCustomFieldValues] =
    useState([]);
  const [disabled, setDisabled] = useState(true);
  const [responseFilter, setResponseFilter] = useState();
  const [responseFilterValue, setResponseFilterValue] = useState();
  const filterRef = useRef("");
  const filterValueRef = useRef("");

  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();

  const {
    appReducer: { relationShipStatuses },
    councilReducer: { selectedCouncil },
    authReducer: { session },
    adminIntegrationsReducer: {
      opportunityStagePayload,
      relationshipStatusPayload,
      sfCustomFieldPayload,
      ttpCustomFieldPayload,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    httpGet({
      call: "sf_integrations/get_sf_account_limit",
    })
      .pipe()
      .subscribe((res) => {
        setAccountLimit(res?.response?.sf_account_limit);
      });
  }, []);

  useEffect(() => {
    if (accountLimit && accountLimit?.Remaining <= 1) {
      enqueueSnackbar(
        "Warning! Your Salesforce account storage limit is about to run out. Please free some space or upgrade your plan.",
        {
          variant: "warning",
          autoHideDuration: 5000,
        },
      );
    }
  }, [accountLimit]);

  useEffect(() => {
    httpGet({
      call: "sf_integrations/get_fields",
    })
      .pipe()
      .subscribe((res) => {
        setSfFields(
          res.response.account_fields.map((item) => ({
            text: item.name,
            value: (() => {
              switch (item.type) {
                case "id":
                  return "uuid";
                case "textarea":
                  return "text";
                case "date":
                  return "datetime";
                case "picklist":
                  return "json";
                default:
                  return item.type;
              }
            })(),
          })),
        );
      });

    httpGet({
      call: "sf_integrations/get_company_fields",
    })
      .pipe()
      .subscribe((res) => {
        setCoFields(
          res.response.company_fields.map((item, index) => ({
            text: item,
            value: res.response.types[index],
          })),
        );
      });
    setCompanyFieldsToSubtract(comFieldsToSubtract);
  }, []);

  useEffect(() => {
    const subtractedFields = coFields.filter(
      (i) => !companyFieldsToSubtract.map((j) => j.text).includes(i.text),
    );
    setSubtractedCompanyFields(subtractedFields);
  }, [coFields]);

  const handleSaveMappings = (state) => {
    httpPost({
      call: "sf_integrations/save_mappings",
      data: {
        user_id: session.id,
        council_id: selectedCouncil.id,
        mappings_payload: state,
        attribute: "account",
        flow: "import",
      },
    })
      .pipe()
      .subscribe((res) => {
        console.log(res.response);
      });
  };

  const handleSelectedFields = () => {
    const payloadArray = [];
    for (let i = 0; i < selectedCompanyValues.length; i += 1) {
      payloadArray.push({
        [selectedCompanyValues[i].text]: selectedSfValues[i].text,
      });
    }
    const state = {
      currentAttribute: "account",
      mappedTractionFields: selectedCompanyValues,
      mappedSalesforceFields: selectedSfValues,
      mappedFields: payloadArray,
      selectedFilterOption: filterOption,
      selectedFilter: filterValue,
      opportunityStagePayload,
      relationshipStatusPayload,
      mappedTractionCustomFields: ttpCustomFieldPayload || [],
      mappedSalesforceCustomFields: sfCustomFieldPayload || [],
      syncToggle: accountSyncToggle,
    };
    handleSaveMappings(state);

    history.push({
      pathname: "/salesforce/fetchData",
      state,
    });
  };

  useEffect(() => {
    setChecked(!(location?.state?.checked === false));
  }, []);

  const handleFilterOption = (response) =>
    [
      ...new Set(
        response?.map((item) => item.Type || item.Name || item.StageName),
      ),
    ].filter((item) => ![undefined, null].includes(item));

  useEffect(() => {
    localStorage.setItem("account_token", false);
    httpGet({
      call: "sf_integrations/fetch_filters",
    })
      .pipe()
      .subscribe((res) => {
        setTypeFilter(handleFilterOption(res?.response?.type_options?.records));
        setOwnerFilter(
          handleFilterOption(res?.response?.owner_options?.records),
        );
        setOpportunityFilter(
          handleFilterOption(res?.response?.opportunity_options?.records),
        );
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (session && selectedCouncil && checked) {
      httpPost({
        call: "sf_integrations/get_mappings",
        data: {
          user_id: session.id,
          council_id: selectedCouncil.id,
          attribute: "account",
          flow: "import",
        },
      })
        .pipe()
        .subscribe((res) => {
          if (res.response.length) {
            setMappings(true);
            setSelectedSfValues(
              res.response[0].mappedSalesforceFields ??
                selectedSalesForceValues(),
            );
            setSelectedCompanyValues(
              res.response[0].mappedTractionFields ??
                preSelectedCompanyValues(),
            );
            setFriendsArray(
              res?.response[0]?.mappedTractionFields ??
                preSelectedCompanyValues(),
            );
            setSelectedRelationshipStatusValues(
              res.response[0]?.relationshipStatusPayload ?? [],
            );
            setSelectedOpportunityStagePayload(
              res.response[0]?.opportunityStagePayload ?? [],
            );
            setSelectedSfCustomFieldValues(
              res.response[0]?.mappedSalesforceCustomFields ?? [],
            );
            setSelectedTtpCustomFieldValues(
              res.response[0]?.mappedTractionCustomFields ?? [],
            );
            setMapCustomFieldToggle(
              res.response[0]?.mappedTractionCustomFields?.length > 0 ?? false,
            );
            setMapToggle(
              res.response[0]?.relationshipStatusPayload?.length > 0 ?? false,
            );
            setAccountSyncToggle(
              res.response[0].syncToggle ?? accountSyncToggle,
            );
            localStorage.setItem(
              "account_token",
              res.response[0].syncToggle ?? accountSyncToggle,
            );
            setResponseFilter(res?.response[0]?.selectedFilterOption);
            setResponseFilterValue(
              res?.response[0]?.selectedFilter.map((val) => val.text),
            );
          } else {
            setSelectedSfValues(selectedSalesForceValues());
            setSelectedCompanyValues(preSelectedCompanyValues());
            setFriendsArray(preSelectedCompanyValues());
          }
        });
    }
  }, [session, selectedCouncil, checked]);

  useEffect(() => {
    setTypeOptions(typeFilter?.map((value) => ({ text: value, value })));
  }, [typeFilter]);

  useEffect(() => {
    setOwnerOptions(ownerFilter?.map((value) => ({ text: value, value })));
  }, [ownerFilter]);

  useEffect(() => {
    setOpportunityOptions(
      opportunityFilter?.map((value) => ({ text: value, value })),
    );
  }, [opportunityFilter]);

  const handleBack = () => {
    history.push({
      pathname: "/salesforce/configureData",
    });
  };

  const handleDropClose = () => {
    setIsDropOpened(false);
  };

  const handleDropOpen = () => {
    setIsDropOpened(true);
    setIsChildDropOpened(false);
  };

  const handleChildDropOpen = () => {
    setIsChildDropOpened(true);
  };

  const childDropOptionsData = [opportunityOptions, ownerOptions, typeOptions];

  const onDropDownValueChange = (values) => {
    setDisabled(false);
    filterValueRef.current.state.values = [];
    setFilterOption(values?.[0].text);
    setChildDropDownOptions(childDropOptionsData[values[0].value]);
    setIsChildDropOpened(true);
  };

  let boundArrayHelpers;

  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  const dropOptions = [
    { id: 1, text: "Opportunity Stage", value: 0 },
    { id: 2, text: "Account Owner", value: 1 },
    { id: 3, text: "Type", value: 2 },
  ];

  const handleChangeFilter = (e) => {
    setFilterValue(e);
  };

  const handleMapFields = () => {
    setMapToggle(true);
  };

  const handleCustomFields = () => {
    setMapCustomFieldToggle(true);
  };

  useEffect(() => {
    setRelationshipStage(
      relationShipStatuses.map((obj) => ({
        text: obj?.name,
        value: obj?.customId,
      })),
    );
  }, [selectedCouncil]);

  const handleSwitchChange = () => {
    setChecked(!checked);
  };

  const handleSyncToggleChanged = () => {
    let message;
    if (mappings) {
      enqueueSnackbar(
        `Sync Accounts job is ${accountSyncToggle ? "disabled" : "enabled"}`,
        {
          variant: "info",
        },
      );
      setAccountSyncToggle(!accountSyncToggle);
      message =
        "Previously saved mappings will be used for Sync. If you want to update mappings then re-map compnent and hit submit button ";
    } else {
      message =
        " No mappings are found for Accounts Sync. Kindly procede to next page in order to save mappings. Make sure you save mappings for Export Accounts as well ";
    }
    return (
      !accountSyncToggle &&
      popup.show({
        title: "Sync Account",
        show: true,
        height: "300",
        width: "540",
        component: <SyncMappingInfoModal message={message} />,
      })
    );
  };
  useEffect(() => {
    if (responseFilterValue && checked) {
      const temp = responseFilterValue?.map((val) => ({
        text: val,
        value: val,
      }));
      filterValueRef.current.state.values = [...temp];
      setFilterValue(temp);
    }
  }, [responseFilterValue]);

  useEffect(() => {
    if (responseFilter && checked) {
      filterRef.current.state.values = [{ text: responseFilter }];
      setFilterOption(responseFilter);
    }
  }, [responseFilter]);

  const handleFilters = () => {
    if (filterValue) {
      handleSelectedFields(filterValue);
    } else {
      handleSelectedFields();
    }
  };

  return checked ? (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ friends: Array(friendsArray.length).fill("") }}
        onSubmit={handleFilters}
        render={({ values }) => (
          <Form>
            <FieldArray
              name="friends"
              render={(arrayHelpers) => {
                bindArrayHelpers(arrayHelpers);
                return (
                  <div>
                    <div className={styles.backBtn}>
                      <Btn
                        btn={BtnType.FRAME_LESS}
                        onClick={() => handleBack()}
                      >
                        Back
                      </Btn>
                    </div>
                    <div className={styles.submitBtn}>
                      <div />
                      <Btn btn={BtnType.REGULAR} type="submit">
                        Submit
                      </Btn>
                      <div className={styles.switchBtn}>
                        <Switch
                          checked={checked}
                          onChange={handleSwitchChange}
                          onColor="#86d3ff"
                          onHandleColor="#2693e6"
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={48}
                          className="react-switch"
                          id="material-switch"
                        />
                        {checked ? (
                          <div className={styles.toggleText}>Import</div>
                        ) : (
                          <div className={styles.toggleText}>Export</div>
                        )}
                      </div>
                    </div>
                    <div className={styles.syncToggle}>
                      <Switch
                        checked={accountSyncToggle}
                        onChange={handleSyncToggleChanged}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        className="react-switch"
                        id="material-switch"
                      />
                      {accountSyncToggle ? (
                        <div className={styles.toggleTextOn}>Sync ON</div>
                      ) : (
                        <div className={styles.toggleText}>Sync OFF</div>
                      )}
                    </div>
                    <div
                      className={`row ${styles.dropDownSelectItems} ${styles.dropDownFieldsConatiner}`}
                    >
                      <div className="col">
                        <h4>Filter Accounts</h4>
                      </div>
                      <div className={`col-5 ${styles.dropSelectItems}`}>
                        <Select
                          placeholder="Select Filter Type"
                          name="text"
                          labelField="text"
                          valueField="value"
                          ref={filterRef}
                          options={dropOptions}
                          onDropdownOpen={handleDropOpen}
                          onDropdownClose={handleDropClose}
                          onChange={onDropDownValueChange}
                          disabled={loading}
                          loading={loading}
                        />
                      </div>
                      <div className={`col-5 ${styles.dropSelectItems}`}>
                        <Select
                          id="child-drop-list"
                          placeholder="Select Filter Value"
                          name="text"
                          labelField="text"
                          valueField="value"
                          multi
                          ref={filterValueRef}
                          deselected
                          disabled={disabled}
                          loading={loading}
                          options={childDropDownOptions}
                          onDropdownOpen={handleChildDropOpen}
                          onChange={(e) => {
                            handleChangeFilter(e);
                          }}
                        />
                      </div>
                      {!isChildDropOpened && <div className="col-5" />}
                    </div>
                    <div className={styles.fieldsConatiner}>
                      <>
                        <div className={styles.headTitle}>
                          <div className={styles.headerContainer}>
                            <div className={styles.mapsTextHead}>
                              Salesforce
                            </div>
                            {/* <Button className={styles.mapsButton}></Button> */}
                            <div className={styles.mapsTextHead}>Traction</div>
                          </div>
                        </div>
                      </>
                    </div>
                    <div className={styles.fieldsConatiner}>
                      {values.friends.map((friend, index) => (
                        <div>
                          {values.friends.length && (
                            <>
                              <div
                                className={styles.headTitle}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginLeft: "1rem",
                                  marginTop: "1rem",
                                }}
                              >
                                <>
                                  <div className={styles.mapsText}>
                                    {selectedSfValues[index]?.text}
                                  </div>
                                  <Button className={styles.mapsButton}>
                                    Maps
                                  </Button>
                                  <div className={styles.mapsText}>
                                    {selectedCompanyValues[index]?.text}
                                  </div>
                                  <Chip
                                    className={styles.chipButton}
                                    onDelete={() => {
                                      setSelectedSfValues([
                                        ...selectedSfValues.filter(
                                          (_, selectedIndex) =>
                                            selectedIndex !== index,
                                        ),
                                      ]);
                                      setSelectedCompanyValues([
                                        ...selectedCompanyValues.filter(
                                          (_, selectedIndex) =>
                                            selectedIndex !== index,
                                        ),
                                      ]);
                                      friendsArray.pop();
                                      setIsSelectedSfValue(false);
                                    }}
                                  />
                                </>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              }}
            />
            <div className={styles.accountRelationshipContainer}>
              <div className={styles.mapsText}>AccountOwner</div>
              <Button className={styles.mapsButton}>Maps</Button>
              <div className={styles.mapsText}>relationship_owner</div>
            </div>
            <div className={styles.fieldsConatiner}>
              <div className={styles.headTitle}>
                <>
                  <DropSelect
                    disabled={isSelectedCompanyValue}
                    placeholder="Select fields from Salesforce"
                    labelField="text"
                    valueField="text"
                    searchBy="text"
                    onChange={(event) => {
                      setSelectedSfValue({
                        text: event.obj[0].text,
                        value: event.obj[0].text,
                      });
                      setIsSelectedSfValue(false);
                    }}
                    options={sfFields.filter(
                      (item) =>
                        !selectedSfValues
                          .map((value) => value.text)
                          .includes(item.text) && item.value === typeMatch,
                    )}
                  />
                  <DropSelect
                    placeholder="Select fields from Traction"
                    labelField="text"
                    valueField="text"
                    searchBy="text"
                    options={subtractedCompanyFields.filter(
                      (item) =>
                        !selectedCompanyValues
                          .map((value) => value.text)
                          .includes(item.text),
                    )}
                    onChange={(event) => {
                      setTypeMatch(event.obj[0].value);
                      setSelectedCompanyValue({
                        text: event.obj[0].text,
                        value: event.obj[0].text,
                      });
                      setIsSelectedCompanyValue(false);
                    }}
                  />
                  <Btn
                    btn={BtnType.REGULAR}
                    className={styles.addButton}
                    disabled={
                      values.friends.length ===
                        subtractedCompanyFields?.length ||
                      isSelectedSfValue ||
                      isSelectedCompanyValue
                    }
                    onClick={() => {
                      setSelectedCompanyValues([
                        ...selectedCompanyValues,
                        selectedCompanyValue,
                      ]);
                      setSelectedSfValues([
                        ...selectedSfValues,
                        selectedSfValue,
                      ]);
                      setIsSelectedSfValue(true);
                      setIsSelectedCompanyValue(true);
                      boundArrayHelpers.insert(values.friends.length - 1, "");
                    }}
                  >
                    +Add
                  </Btn>
                </>
              </div>
            </div>
            <div className={styles.fieldsConatiner}>
              {!mapToggle ? (
                <div className={styles.mapContainer}>
                  <Button
                    className={styles.mapsButton}
                    onClick={handleMapFields}
                  >
                    Map Opportunity Stages
                  </Button>
                </div>
              ) : (
                <MapFieldValues
                  opportunityOptions={opportunityOptions}
                  relationshipStage={relationshipStage}
                  preSelectedOpportunityStagePayload={
                    selectedOpportunityStagePayload
                  }
                  preSelectedRelationshipStatusValues={
                    selectedRelationshipStatusValues
                  }
                />
              )}
            </div>
            <div className={styles.fieldsConatiner}>
              {!mapCustomFieldToggle ? (
                <div className={styles.mapContainer}>
                  <Button
                    className={styles.mapsButton}
                    onClick={handleCustomFields}
                  >
                    Map Custom Fields
                  </Button>
                </div>
              ) : (
                <MapCustomFields
                  opportunityOptions={opportunityOptions}
                  relationshipStage={relationshipStage}
                  preSelectedSfCustomFieldValues={selectedSfCustomFieldValues}
                  preSelectedTtpCustomFieldValues={selectedTtpCustomFieldValues}
                />
              )}
            </div>
          </Form>
        )}
      />
    </div>
  ) : (
    <div>
      <ConfigureExportAccounts
        checked={checked}
        handleSwitchChange={handleSwitchChange}
        syncToggleStatus={accountSyncToggle}
      />
    </div>
  );
};

export default React.memo(ConfigureAccounts);

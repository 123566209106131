import * as Yup from "yup";

export const initModel = {
  memberId: "",
};

export const roles = [
  { text: "Project Member", val: "member" },
  { text: "Project Manager", val: "manager" },
  { text: "Project Owner", val: "owner" },
];

export const validation = Yup.object().shape({
  memberId: Yup.string().required("member is required"),
  role: Yup.string().required("permission is required"),
});

export const GET_AVAILABLE_INTEGRATIONS = "GET_AVAILABLE_INTEGRATIONS";
export const getAvailableIntegrations = (payload) => ({
  type: GET_AVAILABLE_INTEGRATIONS,
  payload,
});

export const GET_AVAILABLE_INTEGRATIONS_SUCCESS =
  "GET_AVAILABLE_INTEGRATIONS_SUCCESS";
export const getAvailableIntegrationsSuccess = (payload) => ({
  type: GET_AVAILABLE_INTEGRATIONS_SUCCESS,
  payload,
});

export const CATCH_ERROR_SUCCESS = "CATCH_ERROR_SUCCESS";
export const catchErrorSuccess = (payload) => ({
  type: CATCH_ERROR_SUCCESS,
  payload,
});

export const POST_COUNCIL_INTEGRATION = "POST_COUNCIL_INTEGRATION";
export const postCouncilIntegration = (payload) => ({
  type: POST_COUNCIL_INTEGRATION,
  payload,
});

export const PUT_COUNCIL_INTEGRATION = "PUT_COUNCIL_INTEGRATION";
export const putCouncilIntegration = (payload) => ({
  type: PUT_COUNCIL_INTEGRATION,
  payload,
});

export const POST_USER_INTEGRATION = "POST_USER_INTEGRATION";
export const postUserIntegration = (payload) => ({
  type: POST_USER_INTEGRATION,
  payload,
});

export const PUT_USER_INTEGRATION = "PUT_USER_INTEGRATION";
export const putUserIntegration = (payload) => ({
  type: PUT_USER_INTEGRATION,
  payload,
});

export const SET_ACCESS_TOKEN_SALESFORCE = "SET_ACCESS_TOKEN_SALESFORCE";
export const setAccessTokenSalesforce = (payload) => ({
  type: SET_ACCESS_TOKEN_SALESFORCE,
  payload,
});

export const SET_PAGE_ATTRIBUTE = "SET_PAGE_ATTRIBUTE";
export const setPageAttribute = (payload) => ({
  type: SET_PAGE_ATTRIBUTE,
  payload,
});

export const SET_OPPORTUNITY_STAGE_PAYLOAD = "SET_OPPORTUNITY_STAGE_PAYLOAD";
export const setOpportunityStagePayload = (payload) => ({
  type: SET_OPPORTUNITY_STAGE_PAYLOAD,
  payload,
});

export const SET_RELATIONSHIP_STATUS_PAYLOAD =
  "SET_RELATIONSHIP_STATUS_PAYLOAD";
export const setRelationshipStatusPayload = (payload) => ({
  type: SET_RELATIONSHIP_STATUS_PAYLOAD,
  payload,
});

export const SET_SF_CUSTOM_FIELD_PAYLOAD = "SET_SF_CUSTOM_FIELD_PAYLOAD";
export const setSfCustomFieldPayload = (payload) => ({
  type: SET_SF_CUSTOM_FIELD_PAYLOAD,
  payload,
});

export const SET_TTP_CUSTOM_FIELD_PAYLOAD = "SET_TTP_CUSTOM_FIELD_PAYLOAD";
export const setTtpCustomFieldPayload = (payload) => ({
  type: SET_TTP_CUSTOM_FIELD_PAYLOAD,
  payload,
});

export const SET_EXPORT_OPPORTUNITY_STAGE_PAYLOAD =
  "SET_EXPORT_OPPORTUNITY_STAGE_PAYLOAD";
export const setExportOpportunityStagePayload = (payload) => ({
  type: SET_EXPORT_OPPORTUNITY_STAGE_PAYLOAD,
  payload,
});

export const SET_EXPORT_RELATIONSHIP_STATUS_PAYLOAD =
  "SET_EXPORT_RELATIONSHIP_STATUS_PAYLOAD";
export const setExportRelationshipStatusPayload = (payload) => ({
  type: SET_EXPORT_RELATIONSHIP_STATUS_PAYLOAD,
  payload,
});

export const SET_SF_EXPORT_CUSTOM_FIELD_PAYLOAD =
  "SET_SF_EXPORT_CUSTOM_FIELD_PAYLOAD";
export const setSfExportCustomFieldPayload = (payload) => ({
  type: SET_SF_EXPORT_CUSTOM_FIELD_PAYLOAD,
  payload,
});

export const SET_TTP_EXPORT_CUSTOM_FIELD_PAYLOAD =
  "SET_TTP_EXPORT_CUSTOM_FIELD_PAYLOAD";
export const setTtpExportCustomFieldPayload = (payload) => ({
  type: SET_TTP_EXPORT_CUSTOM_FIELD_PAYLOAD,
  payload,
});

export const SYNC_ACCOUNTS_TOGGLE = "SYNC_ACCOUNTS_TOGGLE";
export const syncAccountsToggle = (payload) => ({
  type: SYNC_ACCOUNTS_TOGGLE,
  payload,
});

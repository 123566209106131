import { of } from "rxjs";
import { combineEpics, ofType } from "redux-observable";
import { catchError, groupBy, map, mergeMap, switchMap } from "rxjs/operators";
import {
  GET_TASK_ADDITIONAL_DATA,
  CUSTOM_PROJECT_TASK_UPDATE_ATTEMPT,
  GATEWAY_TASK_UPDATE,
  gatewayTaskUpdateSuccess,
  PROJECT_TASK_ADD_ATTEMPT,
  PROJECT_TASK_COMPANY_LIST_GET_ATTEMPT,
  PROJECT_TASK_DELETE_ATTEMPT,
  PROJECT_TASK_DOCUMENTS_ADD_ATTEMPT,
  PROJECT_TASK_DOCUMENTS_DELETE_ATTEMPT,
  PROJECT_TASK_DOCUMENTS_GET_ATTEMPT,
  PROJECT_TASK_LIST_GET_ATTEMPT,
  PROJECT_TASK_NOTES_ADD_ATTEMPT,
  PROJECT_TASK_NOTES_DELETE_ATTEMPT,
  PROJECT_TASK_NOTES_LIST_ATTEMPT,
  PROJECT_TASK_NOTES_UPDATE_ATTEMPT,
  PROJECT_TASK_UPDATE_ATTEMPT,
  PROJECT_TASKS_BY_USER_ATTEMPT,
  projectTaskAddSuccess,
  projectTaskCompanyListSuccess,
  projectTaskDeleteSuccess,
  projectTaskDocumentAddSuccess,
  projectTaskDocumentDeleteSuccess,
  projectTaskDocumentGetSuccess,
  projectTaskListGetSuccess,
  projectTaskNotesAddSuccess,
  projectTaskNotesDeleteSuccess,
  projectTaskNotesListSuccess,
  projectTaskNotesUpdateSuccess,
  projectTasksByUserSuccess,
  projectTaskUpdateByUserSuccess,
  projectTaskUpdateSuccess,
  getTaskAdditionalDataSuccess,
} from "./tasks.action";
import {
  errorHandler,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPut,
  queryString,
} from "../../../../common/httpCall";
import {
  addDocumentSuccess,
  documentDelete,
} from "../../../../common/actions/common.action";
import {
  projectOrganizationTaskUpdateSuccess,
  updateCompanyProgressBar,
  setChangeCompanyDocumentState,
} from "../companies/companies.action";
import { projectWideTasksUpdate } from "../stages/stages.action";

export const epicGetTaskAdditionalDetails = (action$) => action$.pipe(
  ofType(GET_TASK_ADDITIONAL_DATA),
  switchMap(({ payload }) => httpGet({
    apiVersion: "v3",
    call: `projects/${payload.projectId}/tasks/${payload.taskId}`,
  }).pipe(
    map((result) => {
      if (payload.cb) {
        const timeout = setTimeout(() => {
          payload.cb(result.response);
          clearTimeout(timeout);
        }, 500);
      }
      return getTaskAdditionalDataSuccess({
        ...result,
        activeTab: payload.activeTab,
        isProjectWideFromStage: payload.isProjectWideFromStage,
      });
    }),
    catchError((err) => errorHandler(err)),
  )),
);

export const epicGetProjectTasksList = (action$) => action$.pipe(
  ofType(PROJECT_TASK_LIST_GET_ATTEMPT),
  switchMap(({
    payload: {
      projectId, userId, enqueueSnackbar, page, apiVersion = 'v3', replaceAll
    },
  }) => httpGet({
    apiVersion: 'v3',
    call: `projects/${projectId}/tasks?${queryString({
      page: page || 1,
      items: 20,
      user_id: userId,
    })}`, // NEW_API
  }).pipe(
    map((result) => projectTaskListGetSuccess({...result, replaceAll})),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetProjectTasksByUser = (action$, state$) => action$.pipe(
  ofType(PROJECT_TASKS_BY_USER_ATTEMPT),
  switchMap(({
    payload: {
      projectId, userId, enqueueSnackbar, page,
    },
  }) => httpGet({
    apiVersion: "v3",
    call: `projects/${projectId}/tasks?${queryString({
      page: page || 1,
      items: 20,
      user_id: userId,
    })}`, // NEW_API
  }).pipe(
    map((result) => projectTasksByUserSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicAddProjectTask = (action$, state$) => action$.pipe(
  ofType(PROJECT_TASK_ADD_ATTEMPT),
  switchMap(({ payload: { enqueueSnackbar, projectId, project_task } }) => httpPost({
    apiVersion: "v2",
    call: `projects/${projectId}/tasks`,
    data: { project_task },
  }).pipe(
    map((result) => projectTaskAddSuccess(result)),
    catchError((e) => errorHandler(e, enqueueSnackbar)),
  )),
);

export const epicGetProjectTasksCompanyList = (action$, state$) => action$.pipe(
  ofType(PROJECT_TASK_COMPANY_LIST_GET_ATTEMPT),
  groupBy(
    (action) => action.payload.taskId,
    (action) => action,
  ),
  mergeMap((group) => group.pipe(
    switchMap(
      ({
        payload: {
          projectId, taskId, patientOrg, enqueueSnackbar,
        },
      }) => httpGet({
        call: `projects/${projectId}/tasks/${taskId}/companies?patient_org=${patientOrg}`, // NEW_API
      }).pipe(
        map((result) => projectTaskCompanyListSuccess({ result, taskId })),
        catchError((err) => errorHandler(err, enqueueSnackbar)),
      ),
    ),
  )),
);

export const epicDeleteProjectTask = (action$, state$) => action$.pipe(
  ofType(PROJECT_TASK_DELETE_ATTEMPT),
  switchMap(({ payload: { projectId, taskId, enqueueSnackbar } }) => httpDelete({
    apiVersion: "v2",
    call: `projects/${projectId}/tasks/${taskId}`, // NEW_API
  }).pipe(
    map((result) => {
      enqueueSnackbar("You have successfully deleted task", {
        variant: "success",
      });
      return projectTaskDeleteSuccess({ taskId, result });
    }),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const customEpicUpdateProjectTask = (action$) => action$.pipe(
  ofType(CUSTOM_PROJECT_TASK_UPDATE_ATTEMPT),
  switchMap(
    ({
       payload: {
         enqueueSnackbar,
         queryParams,
         taskId,
         projectId,
         project_task,
         flag,
         companyId,
         patientOrg,
       },
     }) => httpPut({
      apiVersion: "v2",
      call: `projects/${projectId}/tasks/${taskId}/update_all`,
      data: { project_task },
    }).pipe(
      mergeMap((result) => {
        if (flag === "completed_true") {
          enqueueSnackbar("Task marked complete.", {
            variant: "success",
          });
        }
        if (flag === "completed_false") {
          enqueueSnackbar("Task marked not complete.", {
            variant: "success",
          });
        }
        if (flag === "assigned_to") {
          enqueueSnackbar("Task successfully assigned.", {
            variant: "success",
          });
        }
        if (flag === "due_date") {
          enqueueSnackbar("Due Date successfully changed.", {
            variant: "success",
          });
        }
        if (queryParams?.task_assignment_id) {
          const assignment = result?.response?.projects_task_assignments.find(
            ({ id }) => id === queryParams.task_assignment_id,
          );
          if (
            assignment
            && assignment?.projects_task_organization?.organization_id
          ) {
            return of(
              updateCompanyProgressBar({
                ...result,
                companyId,
                patientOrg,
              }),
              projectTaskUpdateSuccess(result),
              projectOrganizationTaskUpdateSuccess({
                ...result,
                organizationId:
                assignment.projects_task_organization.organization_id,
              }),
              projectTaskUpdateByUserSuccess(result),
            );
          }
        }
        return [
          projectTaskUpdateSuccess(result),
          updateCompanyProgressBar({ ...result, companyId, patientOrg }),
          projectTaskUpdateByUserSuccess(result),
          projectWideTasksUpdate(result),
        ];
      }),
      catchError((e) => errorHandler(e, enqueueSnackbar)),
    ),
  ),
);

export const epicUpdateProjectTask = (action$, state$) => action$.pipe(
  ofType(PROJECT_TASK_UPDATE_ATTEMPT),
  switchMap(
    ({
      payload: {
        enqueueSnackbar,
        queryParams,
        taskId,
        projectId,
        project_task,
        flag,
        companyId,
        patientOrg,
        cb,
      },
    }) => httpPatch({
      apiVersion: "v2",
      call: `projects/${projectId}/tasks/${taskId}${
        queryParams ? "?" + queryString(queryParams) : ""
      }`, // NEW_API
      data: { project_task },
    }).pipe(
      mergeMap((result) => {
        if (cb) cb();
        if (flag === "completed_true") {
          enqueueSnackbar("Task marked complete.", {
            variant: "success",
          });
        }
        if (flag === "completed_false") {
          enqueueSnackbar("Task marked not complete.", {
            variant: "success",
          });
        }
        if (flag === "assigned_to") {
          enqueueSnackbar("Task successfully assigned.", {
            variant: "success",
          });
        }
        if (flag === "due_date") {
          enqueueSnackbar("Due Date successfully changed.", {
            variant: "success",
          });
        }
        if (queryParams?.task_assignment_id) {
          const assignment = result?.response?.projects_task_assignments.find(
            ({ id }) => id === queryParams.task_assignment_id,
          );
          if (
            assignment
                && assignment?.projects_task_organization?.organization_id
          ) {
            return of(
              updateCompanyProgressBar({
                ...result,
                companyId,
                patientOrg,
              }),
              projectTaskUpdateSuccess(result),
              projectOrganizationTaskUpdateSuccess({
                ...result,
                organizationId:
                      assignment.projects_task_organization.organization_id,
              }),
              projectTaskUpdateByUserSuccess(result),
            );
          }
        }
        return [
          projectTaskUpdateSuccess(result),
          updateCompanyProgressBar({ ...result, companyId, patientOrg }),
          projectTaskUpdateByUserSuccess(result),
          projectWideTasksUpdate(result),
        ];
      }),
      catchError((e) => errorHandler(e, enqueueSnackbar)),
    ),
  ),
);

export const epicUpdateProjectGatewayTask = (action$) => action$.pipe(
  ofType(GATEWAY_TASK_UPDATE),
  switchMap(
    ({
       payload: {
         enqueueSnackbar,
         taskId,
         projectId,
         project_task,
         cb,
         isProjectWideFromStage,
       },
     }) => httpPatch({
      apiVersion: "v2",
      call: `projects/${projectId}/tasks/${taskId}`,
      data: { project_task },
    }).pipe(
      mergeMap((result) => {
        if (cb) cb();
        return [gatewayTaskUpdateSuccess({
          ...result,
          isProjectWideFromStage
        })];
      }),
      catchError((e) => errorHandler(e, enqueueSnackbar)),
    ),
  ),
);

// notes
export const epicListProjectTaskNotes = (action$, state$) => action$.pipe(
  ofType(PROJECT_TASK_NOTES_LIST_ATTEMPT),
  mergeMap(({ payload: { projectId, assignmentId, enqueueSnackbar } }) => httpGet({
    apiVersion: "v2",
    call: `projects/${projectId}/task_assignments/${assignmentId}/notes`, // NEW_API
  }).pipe(
    map((result) => projectTaskNotesListSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicAddProjectTaskNotes = (action$, state$) => action$.pipe(
  ofType(PROJECT_TASK_NOTES_ADD_ATTEMPT),
  switchMap(
    ({
      payload: {
        projectId, assignmentId, note, enqueueSnackbar,
      },
    }) => httpPost({
      apiVersion: "v2",
      call: `projects/${projectId}/task_assignments/${assignmentId}/notes`, // NEW_API
      data: note,
    }).pipe(
      map((result) => projectTaskNotesAddSuccess(result)),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

export const epicUpdateProjectTaskNotes = (action$, state$) => action$.pipe(
  ofType(PROJECT_TASK_NOTES_UPDATE_ATTEMPT),
  switchMap(({ payload: { projectId, note, enqueueSnackbar } }) => httpPut({
    call: `notes/${note.id}`, // NEW_API
    data: note,
  }).pipe(
    map((result) => projectTaskNotesUpdateSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicDeleteProjectTaskNotes = (action$, state$) => action$.pipe(
  ofType(PROJECT_TASK_NOTES_DELETE_ATTEMPT),
  switchMap(({ payload: { id, enqueueSnackbar } }) => httpDelete({
    call: `notes/${id}`, // NEW_API
  }).pipe(
    map((result) => projectTaskNotesDeleteSuccess({ result, id })),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);
// end - notes
// documents
export const epicGetProjectTaskDocuments = (action$, state$) => action$.pipe(
  ofType(PROJECT_TASK_DOCUMENTS_GET_ATTEMPT),
  mergeMap(({ payload: { projectId, assignmentId, enqueueSnackbar } }) => httpGet({
    call: `projects/${projectId}/tasks/${assignmentId}/documents`, // OLD_API
  }).pipe(
    map((result) => projectTaskDocumentGetSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicAddProjectTaskDocuments = (action$, state$) => action$.pipe(
  ofType(PROJECT_TASK_DOCUMENTS_ADD_ATTEMPT),
  switchMap(
    ({
      payload: {
        projectId,
        userId,
        councilId,
        description,
        assignmentId,
        name,
        s3url,
        enqueueSnackbar,
        companyId,
        cb,
      },
    }) => httpPost({
      apiVersion: "v2",
      call: `projects/${projectId}/task_assignments/${assignmentId}/documents`, // NEW_API
      data: {
        document: {
          description,
          added_by_id: userId,
          uri: s3url,
          name,
        },
      },
    }).pipe(
      mergeMap((result) => {
        enqueueSnackbar("Document successfully uploaded.", {
          variant: "success",
        });
        if (cb) cb();
        return of(
          projectTaskDocumentAddSuccess({
            ...result,
            assignmentId,
          }),
          addDocumentSuccess({
            response: result.response,
            status: result.status,
          }),
          setChangeCompanyDocumentState({
            companyId,
          }),
        );
      }),
      catchError((err) => errorHandler(err, enqueueSnackbar)),
    ),
  ),
);

export const epicDeleteProjectTaskDocuments = (action$, state$) => action$.pipe(
  ofType(PROJECT_TASK_DOCUMENTS_DELETE_ATTEMPT),
  switchMap(({ payload: { documentId, assignmentId, enqueueSnackbar, taskId, isProjectWideFromStage } }) => httpDelete({
    call: `documents/${documentId}`, // NEW_API
  }).pipe(
    mergeMap((result) => {
      enqueueSnackbar("Document succesfully removed.", {
        variant: "success",
      });
      return of(
        projectTaskDocumentDeleteSuccess({
          result,
          assignmentId,
          documentId,
          taskId,
          isProjectWideFromStage
        }),
        documentDelete(documentId),
      );
    }),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);
// end - documents

const tasksEpic = combineEpics(
  epicGetProjectTasksList,
  epicAddProjectTask,
  epicGetProjectTasksCompanyList,
  epicDeleteProjectTask,
  epicUpdateProjectTask,
  epicListProjectTaskNotes,
  epicAddProjectTaskNotes,
  epicUpdateProjectTaskNotes,
  epicDeleteProjectTaskNotes,
  epicAddProjectTaskDocuments,
  epicDeleteProjectTaskDocuments,
  customEpicUpdateProjectTask,
  epicUpdateProjectGatewayTask,
  epicGetTaskAdditionalDetails,
  epicGetProjectTaskDocuments,
  epicGetProjectTasksByUser,
);

export default tasksEpic;

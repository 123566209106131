import React from "react";

import { Label, TextArea } from "modules/primitives";

const ProjectDescription = ({ formikprops, disabled, isFordSupplyChainAutomation }) => {
  return (
    <div className="mb-2">
      <Label>{isFordSupplyChainAutomation ? 'Elevator Pitch' : 'Description'}</Label>
      <TextArea
        className="w-100"
        disabled={disabled}
        type="text"
        name="description"
        placeholder="Describe the problem you are trying to solve, the project goal, or description."
        formProps={{
          ...formikprops,
          values: {...formikprops.values, description: formikprops.values?.description || ''} // make sure it will not crash if in above model description is undefined
        }}
        rows={4}
      />
    </div>
  );
};

export default React.memo(ProjectDescription);

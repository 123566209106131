export const objectType = {
  text: "text",
  checkList: "checklist",
  dropDown: "dropdown",
  document: "document",
  link: "link",
  image: "image",
  slides: "slide",
  video: "video",
  date: "date",
  number: "number",
  agreement: "agreement",
};

export const optionItemsForTableCustomField = [
  {
    text: "Text",
    value: objectType.text,
  },
  {
    text: "Number",
    value: objectType.number,
  },
  {
    text: "Date",
    value: objectType.date,
  },
  {
    text: "Dropdown",
    value: objectType.dropDown,
  },
];

export const optionItem = [
  {
    text: "Text",
    value: objectType.text,
  },
  {
    text: "Number",
    value: objectType.number,
  },
  {
    text: "Date",
    value: objectType.date,
  },
  {
    text: "Dropdown",
    value: objectType.dropDown,
  },
  {
    text: "Checklist",
    value: objectType.checkList,
  },
  {
    text: "Document",
    value: objectType.document,
  },
  {
    text: "Agreement",
    value: objectType.agreement,
  },
  {
    text: "Link",
    value: objectType.link,
  },
  {
    text: "Image",
    value: objectType.image,
  },
  {
    text: "Video",
    value: objectType.video,
  },
  {
    text: "Slides",
    value: objectType.slides,
  },
];

export const accessOptions = [
  {
    text: "All",
    value: "all",
  },
  {
    text: "Team",
    value: "team",
  },
  {
    text: "Individual",
    value: "individual",
  },
  {
    text: "Tag",
    value: "tag",
  },
];

export const templatesAccessOptions = [
  {
    text: "All",
    value: "all",
  },
  {
    text: "Team",
    value: "team",
  },
  {
    text: "Individual",
    value: "individual",
  },
];

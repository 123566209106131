import React, { useMemo, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOnClickOutside, useModelPopup } from "common/hooks";

import CloseIcon from "common/icon/closeIcon.component";
import styles from "./style.module.scss";
import {
  BtnType,
  Button,
  CheckBox,
  CheckType,
} from "../../../../../primitives";
import { setCompareNotes } from "../compair.actions";
import { ModalBody, ModalFooter } from "../../../../../../application/modal";

import {
  COMPAIR_ROWS,
  ORGANIZATION_ROWS,
  NOTES_ROWS,
} from "../compair-constants";

const SelectPropertiesComponent = ({
  customFields = [],
  selected = [],
  onRowsChange,
  isPatientOrg,
}) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const ref = useRef();

  const {
    projectsReducer: {
      companyCompairReducer: { selectedNotes = [] },
    },
  } = useSelector((state) => state);
  const [selectAll, setSelectAll] = useState(false);
  const [selectRows, setSelectRows] = useState(selected);
  const [rows, setRows] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [showSubCat, setShowSubCat] = useState(false);

  const defaultCompareRows = useMemo(
    () => (isPatientOrg ? ORGANIZATION_ROWS : [...COMPAIR_ROWS]),
    [isPatientOrg]
  );
  useOnClickOutside(ref, () => setShowSubCat(false));
  useEffect(() => {
    if (selectedNotes && selectedNotes.length) {
      setShowSubCat(true);
    }
  }, [selectedNotes]);
  useEffect(() => {
    const defaultRows = defaultCompareRows.map((r) => {
      const checkIsSelected = selectRows.find(
        (s) => s.selected && s.name === r.name
      );
      return { ...r, selected: !!checkIsSelected };
    });
    const customFieldsWithoutDefaultRows = customFields.reduce(
      (acc, { field_name, field_type, id }) => {
        if (!defaultCompareRows.some(({ name }) => name === field_name)) {
          const checkIsSelected = selectRows.find(
            (s) => s.selected && s.name === field_name
          );
          acc.push({
            name: field_name || field_type,
            sortingIcon: "",
            className: "colCustom",
            type: field_type,
            isCustom: true,
            selected: !!checkIsSelected,
            id,
          });
        }
        return acc;
      },
      []
    );

    setRows([...defaultRows, ...customFieldsWithoutDefaultRows]);
  }, [customFields, defaultCompareRows, selectRows, showAll]);

  const setCheckPropToCompare = (isSelected, row) => {
    if (row.name === "Company" || row.name === "Organization") return;
    const newRows = rows.map((r) => {
      if (r.name === row.name) {
        return {
          ...r,
          selected: isSelected,
        };
      }
      return r;
    });
    if (row.keyName === "notesGeneral") {
      // setShowSubCat((prev) => !prev);
      setShowSubCat((prev) => {
        if (isSelected) {
          dispatch(
            setCompareNotes(["calls", "emails", "meetings", "events", "notes"])
          );
        }
        return !prev;
      });
      if (!isSelected) {
        dispatch(setCompareNotes([]));
      }
    } else {
      setRows(newRows);
    }
    setSelectAll(false);
    setSelectRows(
      newRows.filter(({ selected: isRowSelected }) => isRowSelected)
    );
  };

  const setCheckNoteType = (isSelected, row) => {
    if (isSelected) {
      dispatch(setCompareNotes([...selectedNotes, row.keyName]));
      // handleNotesTypeChange((prev) => [...prev, row.keyName]);
      const newRows = rows.map((r) => {
        if (r.name === "Notes ") {
          return {
            ...r,
            selected: isSelected,
          };
        }
        return r;
      });
      setRows(newRows);
    } else {
      dispatch(
        setCompareNotes(selectedNotes.filter((el) => el !== row.keyName))
      );
      if (selectedNotes.filter((el) => el !== row.keyName).length === 0) {
        const newRows = rows.map((r) => {
          if (r.name === "Notes ") {
            return {
              ...r,
              selected: false,
            };
          }
          return r;
        });
        setRows(newRows);
      }
    }
  };

  const handleSaveClick = () => {
    onRowsChange(
      rows.filter((r) => r.selected),
      selectedNotes
    );
    popup.hide();
  };

  const handleCancelClick = () => {
    popup.hide();
  };

  const onSelectAllChange = (val) => {
    setSelectAll(val);

    const newRows = rows.map((r) => {
      if (r.name === "Company" || r.name === "Organization") {
        return {
          ...r,
          selected: true,
        };
      }

      return {
        ...r,
        selected: val,
      };
    });

    setRows(newRows);
    setSelectRows(
      newRows.filter(({ selected: isRowSelected }) => isRowSelected)
    );
  };

  const renderSelectAllCheckbox = () => (
    <div className={styles.col}>
      <div className={styles.colCompanyName}>
        <CheckBox
          checkType={CheckType.BLUE}
          isChecked={selectAll}
          onChange={onSelectAllChange}
        />
        <div
          className={styles.cursorPointer}
          onClick={() => onSelectAllChange(!selectAll)}
        >
          Select All
        </div>
      </div>
    </div>
  );

  const renderCheckboxes = () => {
    const checkboxes = showAll
      ? rows
      : rows.slice(0, defaultCompareRows.length);

    return checkboxes.map((row, index) => {
      return (
        <div
          key={`${row.name}-${index}`}
          className={
            // row.name === "Notes "
            //   ?
            //   :
            `${styles.colCompanyName}`
          }
          // ` ${styles.colNotesCheckbox}`
        >
          <CheckBox
            checkType={CheckType.BLUE}
            isChecked={row.selected}
            onChange={(val) => {
              setCheckPropToCompare(val, row);
            }}
            // ||
            //   (row.name === "Notes " && selectedNotes.length)
            disabled={row.name === "Company" || row.name === "Organization"}
          />
          <div
            style={{
              opacity:
                row.name === "Company" || row.name === "Organization"
                  ? "0.6"
                  : "1",
            }}
            className={
              row.name === "Company" || row.name === "Organization"
                ? ""
                : styles.cursorPointer
            }
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setCheckPropToCompare(!row.selected, row);
            }}
          >
            {row.name}
          </div>
          {row.name === "Notes " && showSubCat ? (
            <div ref={ref} className={styles.showSubCatcol}>
              <span
                className={styles.tagDelete}
                onClick={() => setShowSubCat(false)}
              >
                <CloseIcon />
              </span>
              {NOTES_ROWS.map((el) => (
                <div key={el.name} className={styles.colNotesSubGrup}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={selectedNotes.includes(el.keyName)}
                    onChange={(val) => setCheckNoteType(val, el)}
                  />
                  <div
                    className={styles.cursorPointer}
                    onClick={() => setCheckNoteType(!el.selected, el)}
                  >
                    {el.name}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      );
    });
  };

  return (
    <div className={styles.wrapper}>
      <ModalBody noFixedHeight>
        <div className={styles.container}>
          {renderSelectAllCheckbox()}
          <div className={styles.col}>
            {renderCheckboxes()}

            {!isPatientOrg && (
              <div className="w-100">
                <button
                  className="btn btn-link"
                  onClick={() => {
                    // setShowSubCat(false);
                    setShowAll(!showAll);
                  }}
                  type="button"
                >
                  {showAll ? "LESS" : "MORE"}
                </button>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="mr-3">
          <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
            Cancel
          </Button>
        </div>
        <Button type="submit" btn={BtnType.REGULAR} onClick={handleSaveClick}>
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default React.memo(SelectPropertiesComponent);

export const TOGGLE_REPLICATIONS_TAB_FILTERS = 'TOGGLE_REPLICATIONS_TAB_FILTERS'
export const toggleReplicationsTabFilters = (payload) => ({
  type: TOGGLE_REPLICATIONS_TAB_FILTERS,
  payload,
});

export const SET_REPLICATIONS_CARD_FILTERS = 'SET_REPLICATIONS_CARD_FILTERS'
export const setReplicationsCardFilters = (payload) => ({
  type: SET_REPLICATIONS_CARD_FILTERS,
  payload,
});

export const GET_REPLICATIONS_STATISTICS_DATA = 'GET_REPLICATIONS_STATISTICS_DATA'
export const getReplicationsStatisticsData = (payload) => ({
  type: GET_REPLICATIONS_STATISTICS_DATA,
  payload,
});

export const GET_REPLICATIONS_STATISTICS_DATA_SUCCESS = 'GET_REPLICATIONS_STATISTICS_DATA_SUCCESS'
export const getReplicationsStatisticsDataSuccess = (payload) => ({
  type: GET_REPLICATIONS_STATISTICS_DATA_SUCCESS,
  payload,
});

export const GET_REPLICATIONS_BY_STAGE_DATA = 'GET_REPLICATIONS_BY_STAGE_DATA'
export const getReplicationsByStageData = (payload) => ({
  type: GET_REPLICATIONS_BY_STAGE_DATA,
  payload,
});

export const GET_REPLICATIONS_BY_STAGE_DATA_SUCCESS = 'GET_REPLICATIONS_BY_STAGE_DATA_SUCCESS'
export const getReplicationsByStageDataSuccess = (payload) => ({
  type: GET_REPLICATIONS_BY_STAGE_DATA_SUCCESS,
  payload,
});

export const GET_PROJECTS_EXPORTED_DATA = 'GET_PROJECTS_EXPORTED_DATA'
export const getProjectsExportedData = (payload) => ({
  type: GET_PROJECTS_EXPORTED_DATA,
  payload,
});

export const GET_PROJECTS_EXPORTED_DATA_SUCCESS = 'GET_PROJECTS_EXPORTED_DATA_SUCCESS'
export const getProjectsExportedDataSuccess = (payload) => ({
  type: GET_PROJECTS_EXPORTED_DATA_SUCCESS,
  payload,
});

export const GET_PROJECTS_IMPORTED_DATA = 'GET_PROJECTS_IMPORTED_DATA'
export const getProjectsImportedData = (payload) => ({
  type: GET_PROJECTS_IMPORTED_DATA,
  payload,
});

export const GET_PROJECTS_IMPORTED_DATA_SUCCESS = 'GET_PROJECTS_IMPORTED_DATA_SUCCESS'
export const getProjectsImportedDataSuccess = (payload) => ({
  type: GET_PROJECTS_IMPORTED_DATA_SUCCESS,
  payload,
});

export const GET_REPLICATIONS_TABLE_DATA = 'GET_REPLICATIONS_TABLE_DATA'
export const getReplicationsTableData = (payload) => ({
  type: GET_REPLICATIONS_TABLE_DATA,
  payload,
});

export const GET_REPLICATIONS_TABLE_DATA_SUCCESS = 'GET_REPLICATIONS_TABLE_DATA_SUCCESS'
export const getReplicationsTableDataSuccess = (payload) => ({
  type: GET_REPLICATIONS_TABLE_DATA_SUCCESS,
  payload,
});

export const TOGGLE_REPLICATIONS_TABLE = 'TOGGLE_REPLICATIONS_TABLE'
export const toggleReplicationsTable = (payload) => ({
  type: TOGGLE_REPLICATIONS_TABLE,
  payload,
});

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { useSnackbar } from "notistack";
import { format, parseISO } from "date-fns";
import {
  shouldDownload,
  queryParamStringToArray,
  useQuery,
} from "common/helper";
import { useHistory } from "react-router-dom";
import isItSmallDevice from "modules/themes/themesCommon/isItSmallDevice";

import { useModelPopup } from "../../../../../../common/hooks";
import ConfirmDeleteComponent from "../../../../../../common/components/confirmDelete/index";
import {
  likePostAttempt,
  dislikePostAttempt,
  selectPostAsFavoriteAttempt,
  deselectPostAsFavoriteAttempt,
} from "../postsBlock.action";
import styles from "./styles.module.scss";
import Icon from "../../../../../../common/icon/icon.component";
import { smSize } from "../../../../../../common/icon";

import {
  UserInline,
  UserInlineTypes,
  MoreOptions,
} from "../../../../../primitives/index";
import FeedComponent from "../feed/feed.component";
import { moreOptions } from "./postItem.constant";
import AddPostModal from "../addPostModal";
import { PostReplyFav, PostLiked, PostReplyIcon } from "./postItemIcons";
import isUserAdmin from "../../../../themesCommon/isUserAdmin/index";

const PostItem = (props) => {
  const {
    postData,
    setSelectedPost,
    selectedPost,
    homePageUse,
    filterOption,
    author,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const postContentRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const popup = useModelPopup();
  const [isFavorite, setIsFavorite] = useState(postData.current_user_favorite);
  const [isLiked, setIsLiked] = useState(postData.current_user_like);
  const [isFeedOpened, setIsFeedOpened] = useState(false);
  const [dateWithTimezone, setDateWithTimezone] = useState(false);
  const repliesCount = postData?.theme_post_replies?.length || 0;
  const postId = useQuery().get("post_id");
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { currentThemeData },
      themesPostsReducer: { likePending, favPending },
    },
  } = useSelector((state) => state);

  const handleEditClick = () => {
    popup.show({
      title: "Edit Post",
      show: true,
      component: (
        <AddPostModal
          homePageUse={false}
          popup={popup}
          mode="edit"
          themeId={postData.theme_id}
          postData={postData}
        />
      ),
    });
  };

  const parseTimezone = () => {
    // const formatedDate = format(
    //   parseISO(postData.updated_at),
    //   "eeee, MMM do, yyyy"
    // );
    // const feedDate = new Date(
    //   formatedDate.split(",")[2] +
    //     "-" +
    //     formatedDate.split(",")[0] +
    //     "-" +
    //     formatedDate.split(",")[1]
    // );
    setDateWithTimezone(
      format(parseISO(postData.updated_at), "eeee, MMM do, yyyy, hh:mm a ")
    );
  };

  const setReplyText = () => {
    if (isItSmallDevice()) {
      return repliesCount;
    } else {
      if (repliesCount) {
        return repliesCount + " Replies";
      } else {
        return "Reply";
      }
    }
  };

  const handleDeletePost = () => {
    popup.show({
      title: "Delete Post",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={postData.theme_id}
          templateId={postData.theme_post_id}
          enqueueSnackbar={enqueueSnackbar}
          councilId={selectedCouncil?.id}
          actionType="deleteThemePost"
        />
      ),
    });
    // dispatch(themesDeleteAttempt({ id }));
  };

  const handleCloseFeed = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsFeedOpened(false);
    setSelectedPost("");
    if (postId) {
      const arr = queryParamStringToArray(postId);
      if (arr.length && postData.theme_post_id === arr[0]) {
        history.push(`/themes/individual_page/${postData.theme_id}/overview`);
      }
    }
  };

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick();
    }
    if (value === 1) {
      handleDeletePost();
    }
  };

  const handleLikePost = () => {
    if (likePending) {
      return;
    }
    setIsLiked(!isLiked);
    if (postData.current_user_like) {
      dispatch(
        dislikePostAttempt({
          theme_id: postData.theme_id,
          post_id: postData.theme_post_id,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(
        likePostAttempt({
          theme_id: postData.theme_id,
          post_id: postData.theme_post_id,
          enqueueSnackbar,
        })
      );
    }
  };

  const handleFavoritePost = () => {
    if (favPending) {
      return;
    }
    setIsFavorite(!isFavorite);

    if (postData.current_user_favorite) {
      dispatch(
        deselectPostAsFavoriteAttempt({
          theme_id: postData.theme_id,
          post_id: postData.theme_post_id,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(
        selectPostAsFavoriteAttempt({
          theme_id: postData.theme_id,
          post_id: postData.theme_post_id,
          enqueueSnackbar,
        })
      );
    }
  };

  const moreOptionVisibility = () => {
    if (!homePageUse) {
      return (
        isUserAdmin(session?.council_role) ||
        session.id === postData.user.user_id
      );
    }
    return false;
  };

  const handleOpenFeed = (id) => {
    setIsFeedOpened(true);
    setSelectedPost(postData.theme_post_id);
    // socket.close();
  };
  const handleRedirectToIndividualPage = () => {
    history.push({
      pathname: `/themes/individual_page/${postData.theme_id}/overview`,
      state: { cardData: {} },
    });
  };

  useEffect(() => {
    parseTimezone();
  }, [selectedCouncil]);

  useEffect(() => {
    let arr = [];
    if (postId) {
      arr = queryParamStringToArray(postId);
    }
    if (arr.length && postData.theme_post_id === arr[0]) {
      handleOpenFeed();
    }
  }, [postId]);

  return (
    <div
      className={classnames(
        styles.postItemContainer,
        selectedPost === postData.theme_post_id ? styles.selectedPost : ""
      )}
      onClick={(e) => {
        e.stopPropagation();
        handleOpenFeed();
      }}
    >
      <div className={styles.avatarBlock}>
        <UserInline
          first={postData.user.name}
          avatar={postData.user.avatar}
          type={UserInlineTypes.WITH_NAME}
        />
        {moreOptionVisibility() && window.screen.width < 650 && (
          <MoreOptions
            options={moreOptions}
            className={styles.smallDevicesMoreOpt}
            onClick={handleMoreOptionClick}
          />
        )}
        {homePageUse && (
          <div
            className={styles.themeName}
            onClick={() => handleRedirectToIndividualPage()}
          >
            {postData.theme_name}
          </div>
        )}
      </div>
      <div className={styles.aboutPostBlock}>
        <div
          className={styles.aboutDescription}
          ref={postContentRef}
          dangerouslySetInnerHTML={{ __html: postData.content }}
        />
        {postData.documents.length > 0 && (
          <div className={styles.aboutImages}>
            {postData.documents?.map((doc, index) => {
              if (shouldDownload(doc.name)) {
                return (
                  <a
                    key={doc.id}
                    className={styles.doc}
                    target="blank"
                    href={doc.uri}
                  >
                    {doc.name}
                  </a>
                );
              }
              if (index < 2) {
                return (
                  <img
                    style={
                      postData.documents.length < 2
                        ? { width: "70%" }
                        : { width: "50%" }
                    }
                    key={doc.id}
                    src={doc.uri}
                    className={styles.descriptionImage}
                  />
                );
              }
            })}
          </div>
        )}
        <div className={styles.aboutStats}>
          <div className={styles.repliesBlock}>
            <PostReplyIcon />
            <div className={styles.repliesText}>{setReplyText()}</div>
          </div>
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleLikePost();
            }}
            className={styles.likesBlock}
          >
            <PostLiked isLiked={isLiked} />
            <div className={styles.likesText}>
              {postData?.likes_count || 0} {isItSmallDevice() ? "" : "Likes"}{" "}
            </div>
          </div>
          <div className={styles.favoritesBlock}>
            <Icon
              className={
                isFavorite ? styles.favoriteEnable : styles.favoriteDissable
              }
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleFavoritePost();
              }}
              icon="icn-subnav-ratings-fill"
            />{" "}
            <div className={styles.favoriteText}>
              {" "}
              {isItSmallDevice() ? "" : "Favorite"}{" "}
            </div>
            {filterOption === "documents" ? (
              <div className={styles.favoritesBlock}>
                <div className={styles.attachText}>
                  <Icon {...smSize} icon="attachment" /> Attachments{" "}
                </div>
              </div>
            ) : null}
            {filterOption === "author" &&
            postData.user.user_id !== author.id ? (
              <div className={styles.favoritesBlock}>
                <span className={styles.marginRight5}>(</span>
                <UserInline
                  className={styles.userIcon}
                  userId={author.id}
                  type={UserInlineTypes.IMAGE_ONLY}
                  showMine={false}
                  first={author.name}
                  last={""}
                  avatar={author?.avatar}
                  local
                />
                <div className={styles.repliesText}>Replies</div> )
              </div>
            ) : null}
          </div>
          {!postData.current_user_favorite && filterOption === "favorites" && (
            <PostReplyFav />
          )}
        </div>
      </div>
      <div className={styles.dateBlock}>
        <span>
          {(filterOption === "newest" || filterOption === "oldest") && (
            <span>Last reply: </span>
          )}
          {dateWithTimezone}
        </span>
        {moreOptionVisibility() && window.screen.width > 650 && (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={handleMoreOptionClick}
          />
        )}
      </div>
      {isFeedOpened && (
        <FeedComponent
          replyData={postData?.theme_post_replies}
          post={postData}
          handleCloseFeed={handleCloseFeed}
          homePageUse={homePageUse}
        />
      )}
    </div>
  );
};
export default PostItem;

import * as React from "react";
import { useCallback, useMemo } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Tooltip from "@mui/material/Tooltip";

import { smSize } from "../../../../../common/icon";
import Icon from "../../../../../common/icon/icon.component";
import {
  approvalOptions,
  reviewOptions,
} from "modules/projects/project/tasks/approvalTask/constants";

export default function ApprovalActions({
  task,
  handleActionSelect,
  disabled,
  isReviewer,
  myUserId,
  isUserAssigned,
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const source = useMemo(() => {
    return isReviewer ? reviewOptions : approvalOptions;
  }, [isReviewer]);

  const handleClick = (index) => {
    if (index === 0 && selectedIndex === 0) {
      return;
    }

    handleActionSelect(source[index || selectedIndex]);
  };

  const handleMenuItemClick = (event, index) => {
    handleClick(index);
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const makeOptionName = useCallback(
    (elem) => {
      if (task.last_taken_action && selectedIndex === 0) {
        const getElem = source.find(
          (el) => el.takenActionData?.id === task.last_taken_action
        );

        return getElem?.takenActionData?.name || elem.name;
      }

      if (elem.takenActionData?.id === task.last_taken_action) {
        return elem.takenActionData?.name || elem.name;
      }

      return elem.name;
    },
    [task, source, selectedIndex]
  );

  const getDropdownOptionName = useCallback(
    (option) => {
      if (option.takenActionData?.id === task.last_taken_action) {
        return option.takenActionData?.name;
      }

      return option.name;
    },
    [task]
  );

  const getDropdownOptionIsDisabled = useCallback(
    (option, index) => {
      if (index === 0) {
        return true;
      }

      if (option.takenActionData?.id !== task.last_taken_action) {
        return false;
      }

      const getMyUser = task.assigned_users.find(
        ({ user }) => user?.user_id === myUserId
      );

      return getMyUser?.status === task.last_taken_action;
    },
    [task, myUserId]
  );

  return (
    <React.Fragment>
      <Tooltip
        title={
          disabled && !isUserAssigned
            ? "This Task is not yet ready for your Approval."
            : disabled
            ? "You must be assigned to this Task to perform an Action."
            : null
        }
      >
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="Task actions"
          size="small"
          sx={{
            backgroundColor: "#3994c1 !important",
          }}
          disabled={disabled}
        >
          <Button
            sx={{
              backgroundColor: "#3994c1 !important",
              borderColor: "rgb(255 255 255 / 46%) !important",
            }}
            onClick={() => handleClick(selectedIndex)}
          >
            {makeOptionName(source[selectedIndex])}
          </Button>
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            sx={{
              backgroundColor: "#3994c1 !important",
            }}
          >
            <Icon {...smSize} icon="arrow-down2" />
          </Button>
        </ButtonGroup>
      </Tooltip>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {source.map((option, index) => (
                    <MenuItem
                      key={option.id}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      disabled={getDropdownOptionIsDisabled(option, index)}
                    >
                      {getDropdownOptionName(option)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

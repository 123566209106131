import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Icon, smSize } from "common/icon";
import { useDocumentName, useModelPopup } from "common/hooks";
import { useSnackbar } from "notistack";
import useS3FileUpload from "common/hooks/s3FileUplodar.hook";
import {
  companyPutDetailsFields,
  addCustomFields,
} from "modules/companies/company/company.action";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { patientOrganizationPutDetailsFields } from "modules/patientOrganizations/patientOrganization/patientOrganization.action";
import { isCreator } from "common/checkers/isCreator";
import {
  MoreOptions,
  Loading,
  Button,
  BtnType,
  pageSettings,
  Pagination,
} from "modules/primitives";
import { getDocuments } from "common/actions/common.action";

import ConfirmDeleteComponent from "common/components/confirmDelete";
import DragAndDrop from "common/components/dragAndDrop";
import { ACTIVITY_OPTIONS } from "../../../constants";
import { shouldDownload } from "../../../helper";
import SimpleDialog from "../../../../modules/companies/company/details/confirmationModal";
import styles from "./styles.module.scss";
import UploadedByInfo from "../../uploadedByInfo";
import LinearProgressWithLabel from "../../linearProgressWithLabel";

const DocumentComponent = ({
  item,
  handleEditClick,
  handleSubmitDetail,
  blockFunctionality = false,
  handleDeleteClick,
  withPagination,
  selectedDocumentPage,
  setSelectedDocumentPage,
}) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany, customFieldLoadingId },
    },
    documentsReducer: {
      documents,
      docListMeta,
      loadingDocs,
      shouldUpdateDocuments,
    },

    patientOrganizationsReducer: {
      patientOrganizationReducer: {
        selectedPatientOrganization,
        customFieldLoadingIdOrg,
      },
    },
  } = useSelector((state) => state);
  const [newDocuments, setNewDocuments] = useState([]);
  const [cfDocuments, setCfDocuments] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [docLoading, setDocLoading] = useState(false);
  const [changes, setChanges] = useState(false);
  const [origin, setOrigin] = useState("");
  const [loadingId, setLoadingId] = useState("");
  const [fieldId, setFieldId] = useState("");
  const [index, setIndex] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { fileDelete, fileUpload } = useS3FileUpload();
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [uploadValues, setUploadValues] = useState([]);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const documentName = useDocumentName();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);
  // const [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [pageSetting, setPageSetting] = useState(pageSettings);
  const [docsMeta, setDocsMeta] = useState();
  useEffect(() => {
    if (item) {
      if (isCreator(item?.creator?.user_id)) {
        setHasAccessToEdit(true);
      }
    }
  }, [item]);

  const handleDismiss = () => {
    setOpenConfirmModal(!openConfirmModal);
  };

  const getFilename = (url) => {
    if (url) {
      return url.split("/").pop();
    }
    return "";
  };

  const uploadHandler = async (e, action) => {
    if (forbiddenFiles.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }
    setDocLoading(true);

    const fileList = [];
    let dismissedFiles = 0;

    setUploadValues((items) => {
      const currentFiles = [...items];
      const newFiles = files.map((file, i) => ({
        progress: 0,
        name: file.name,
        order: i,
      }));
      return [...currentFiles, ...newFiles];
    });

    files.forEach(async (file) => {
      if (file.size <= 500000000) {
        const pathname = `srm/${selectedCouncil.id}/companies/${selectedCompany.id}/`;
        await fileUpload(file, pathname, setUploadValues, file.name).then(
          (res) => {
            if (res && res.location) {
              if (
                [...newDocuments, ...cfDocuments].indexOf(res.location) === -1
              ) {
                fileList.push(res.location);
                setDocLoading(false);
              } else {
                dismissedFiles++;
                setForbiddenFiles((prevFiles) => {
                  const localFiles = [...prevFiles];
                  localFiles.push({
                    name: file.name,
                    error: "Document already added.",
                  });
                  return localFiles;
                });
                setUploadValues((prevState) => {
                  const currentProgress = [...prevState];
                  const index = currentProgress
                    .map((item) => item.name)
                    .lastIndexOf(file.name);
                  currentProgress[index] = {
                    progress: "NOT_ALLOWED",
                    name: file.name,
                  };
                  return currentProgress;
                });
                enqueueSnackbar("This document was already added.", {
                  variant: "error",
                });
                setDocLoading(false);
              }
            }
          }
        );
      } else {
        dismissedFiles++;
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === file.name
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: file.name,
          };
          return currentProgress;
        });
        if (file.size > 500000000) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error: "Your file is too large. File size limit: 500MB",
            });
            return localFiles;
          });
          setDocLoading(false);
          enqueueSnackbar(
            "We could not upload your image because your file size is too big. Please make sure the file is less than 500 MB",
            {
              variant: "error",
            }
          );
        }
      }
      if (fileList.length === files.length - dismissedFiles) {
        setNewDocuments([...newDocuments, ...fileList]);
        setDocLoading(false);
      }
    });

    e.target.value = "";
  };

  const check = (checked, value) => {
    let optionSelected = item.option_selected;
    if (checked) {
      if (item.multiselect) {
        optionSelected.push(value);
      } else {
        optionSelected = [value];
      }
    }
    if (!checked) {
      optionSelected = item.option_selected.filter((e) => e !== value);
    }

    const values = {
      // field_value: item.field_value,
      title: item.field_name,
      type: item.field_type,
      option_selected: optionSelected,
      multiselect: item.multiselect,
      custom_field_group_id: item.custom_field_group_id,
      option_values: item.option_values,
      // field_values: item.field_values,
    };
    handleSubmitDetail(values, item);
  };
  const saveModifications = () => {
    setSubmitting(true);
    const { company_id, council_id, company_custom_field_id, ...newItem } =
      item;

    if (selectedPatientOrganization.id) {
      // TODO: need to refactor also for PO
      dispatch(
        patientOrganizationPutDetailsFields({
          patient_organization_id: item.gsk_patient_organization_id,
          council_id: selectedCouncil.id,
          patient_organization_custom_field_id:
            item.patient_organization_custom_field_id,
          data: {
            ...newItem,
            // field_values: [...cfDocuments, ...newDocuments],
            document_resource_id: item.company_custom_field,
            document_resource_type: "Gsk::PatientOrganizationCustomField",
            documents_attributes: [
              ...cfDocuments.filter((doc) => !doc.isAdd),
              ...newDocuments.map((doc) => ({
                added_by_id: session.id,
                name: getFilename(doc),
                uri: doc,
                council_id: selectedCouncil.id,
              })),
            ],
            user_id: session.id,
          },
          enqueueSnackbar,
        })
      );
    } else {
      const data = {
        company_id: item.company_id,
        council_id: item.council_id,
        company_custom_field_id: item.company_custom_field_id,
        enqueueSnackbar,
        data: {
          ...newItem,
          field_values: [],
          documents: [],
          documents_attributes: [
            ...cfDocuments.filter((doc) => !doc.isAdd || doc._destroy),
            ...newDocuments.map((doc) => ({
              added_by_id: session.id,
              name: getFilename(doc),
              uri: doc,
              council_id: selectedCouncil.id,
              document_resource_type: "CompanyCustomField",
              document_resource_id: item.company_custom_field,
            })),
          ],
          user_id: session.id,
        },
      };
      if (!item?.company_custom_field_id && item.custom_field_template_id) {
        dispatch(
          addCustomFields({
            ...data,
            company_custom_field_id: item.custom_field_template_id,
          })
        );
      } else {
        dispatch(companyPutDetailsFields(data));
      }
    }

    setNewDocuments([]);
    setChanges(false);
  };
  const handleDeleteDocument = async (e, fromDeleteDocument, customIndex) => {
    e.stopPropagation();
    setOpenConfirmModal(false);
    setIsDeleting(customIndex);
    const pathname = `srm/${selectedCouncil.id}/${
      selectedPatientOrganization?.id ? "patient_organizations" : "companies"
    }/${selectedPatientOrganization?.id || selectedCompany.id}/${getFilename(
      origin === "oldDocs" || fromDeleteDocument
        ? cfDocuments[index]
        : newDocuments[index]
    )}`;
    await fileDelete(pathname).then(() => {
      setIsDeleting("");
      if (origin === "oldDocs" || fromDeleteDocument) {
        setCfDocuments([
          ...cfDocuments.map((doc, idx) => {
            if ((customIndex ?? index) === idx && doc.id) {
              return {
                ...doc,
                _destroy: true,
              };
            }
            return doc;
          }),
        ]);
      } else {
        setNewDocuments(() =>
          newDocuments.filter((doc, idx) => idx !== (customIndex || index))
        );
      }
    });
    setChanges(true);
    setIndex(null);
  };
  const handleOpenConfirmDelete = (e, fromDeleteDocument, customIndex) => {
    popup.show({
      title: "Confirmation Delete",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          enqueueSnackbar={enqueueSnackbar}
          councilId={selectedCouncil?.id}
          deleteCFDocumnent={handleDeleteDocument}
          actionType="deleteCFDocument"
          customData={{ e, fromDeleteDocument, customIndex }}
        />
      ),
    });
  };
  const handlePagingChange = (page) => {
    setSelectedDocumentPage(page);
    setPageSetting((prev) => ({
      ...prev,
      current: page,
    }));
  };

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }

    if (value === 1) {
      handleDeleteClick(item);
    }
  };

  useEffect(() => {
    if (item.field_values) {
      setSubmitting(false);
    }
  }, [item.field_values]);

  useEffect(() => {
    if (newDocuments.length) {
      saveModifications();
    }
  }, [newDocuments]);

  useEffect(() => {
    if (item.documents) {
      setCfDocuments(item.documents);
    }
  }, [item.documents]);

  useEffect(() => {
    if (changes) {
      saveModifications();
    }
  }, [changes]);

  useEffect(() => {
    if (withPagination) {
      dispatch(
        getDocuments({
          document_resource_id: selectedCompany.id,
          document_resource_type: "Company",
          patientOrg: selectedPatientOrganization?.id,
          enqueueSnackbar,
          customUrl: `documents?company_id=${
            selectedCompany.id
          }&items=10&page=${selectedDocumentPage || 1}`,
        })
      );
    }
  }, [selectedDocumentPage, item.lengthOfDocuments]);

  useEffect(() => {
    if (documents && withPagination && item.allDocsItem) {
      setCfDocuments([
        ...documents

          .filter(
            (doc) => doc.document_resource_type !== "Projects::TaskAssignment"
          )
          .map((doc) => ({
            ...doc,
            isAdd: true,
          })),
      ]);
      setPageSetting((prev) => ({
        ...prev,
        total: docListMeta.total,
        pageCount: 1,
      }));
    }
  }, [documents, docListMeta]);

  useEffect(() => {
    if (customFieldLoadingId || customFieldLoadingIdOrg) {
      if (selectedPatientOrganization.id) {
        setLoadingId(customFieldLoadingIdOrg);
      } else {
        setLoadingId(customFieldLoadingId);
      }
    }
  }, [customFieldLoadingId, customFieldLoadingIdOrg]);

  useEffect(() => {
    if (selectedPatientOrganization.id) {
      setFieldId(item.patient_organization_custom_field_id);
    } else {
      setFieldId(item.company_custom_field);
    }
  }, [item]);

  useEffect(() => {
    setDocsMeta(item.docListMeta);
    setPageSetting((prev) => ({
      ...prev,
      total: item.docListMeta?.total,
      pageCount: 1,
    }));
  }, [item.docListMeta, cfDocuments]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{item?.field_name || "Document"}</h5>
          {item?.field_placeholder?.length ? (
            <span className="text-muted small">{item.field_placeholder}</span>
          ) : null}
        </div>
        <div className={styles.rightWrp}>
          <div className={styles.colMore}>
            {!blockFunctionality && hasAccessToEdit ? (
              <MoreOptions
                options={ACTIVITY_OPTIONS}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            ) : null}
          </div>
        </div>
      </div>
      {item.from_claimed_profile && (
        <span className="d-block small font-weight-bold mb-3">
          Company Response
        </span>
      )}
      <div className={styles.container}>
        {loadingId === fieldId ? (
          <Loading />
        ) : (
          <div className={styles.documentListWrp}>
            {loadingDocs && withPagination ? (
              <Loading />
            ) : (
              cfDocuments?.map((document, index) => (
                <div
                  key={document?.id}
                  className={classNames(
                    styles.documnetContainer,
                    document.rfi ? styles.fromRfi : ""
                  )}
                >
                  <div className="d-flex align-items-center w-50">
                    <Icon
                      {...smSize}
                      icon="icn-subnav-documents"
                      className={styles.tileIcon}
                    />
                    <span>
                      {document.name ||
                        documentName.getName(getFilename(document.uri))}
                    </span>
                  </div>
                  <UploadedByInfo document={document} />
                  <div className={styles.rightWrp}>
                    <a
                      href={document.uri}
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      <Icon
                        {...smSize}
                        icon="icn-export-button"
                        className={styles.download}
                      />
                    </a>
                    <a
                      className={styles.docView}
                      target="blank"
                      href={document.uri}
                    >
                      {shouldDownload(getFilename(document.uri))
                        ? "download"
                        : "view"}
                    </a>

                    {(session?.id === document.added_by?.id ||
                      session.council_role.indexOf("admin") !== -1) && (
                      <Icon
                        {...smSize}
                        icon="icn-button-delete"
                        className={styles.delete}
                        onClick={(e) => {
                          handleOpenConfirmDelete(e, true, index);
                        }}
                      />
                    )}
                  </div>
                </div>
              ))
            )}
            {submitting ? (
              <Loading />
            ) : newDocuments && newDocuments.length ? (
              newDocuments.map((document, index) =>
                isDeleting === index ? (
                  <Loading />
                ) : (
                  <div key={index} className={styles.documnetContainer}>
                    <Icon
                      {...smSize}
                      icon="icn-subnav-documents"
                      className={styles.tileIcon}
                    />
                    <div>{documentName.getName(getFilename(document))}</div>
                    <div className={styles.rightWrp}>
                      <Icon
                        {...smSize}
                        icon="icn-export-button"
                        className={styles.download}
                      />
                      <a
                        className={styles.docView}
                        target="blank"
                        href={document}
                      >
                        {shouldDownload(getFilename(document))
                          ? "download"
                          : "view"}
                      </a>
                      <Icon
                        {...smSize}
                        icon="icn-button-delete"
                        className={styles.delete}
                        onClick={(e) => {
                          {
                            handleOpenConfirmDelete(e, true, index);
                          }
                        }}
                      />
                    </div>
                  </div>
                )
              )
            ) : null}
          </div>
        )}
        {changes ? (
          <div>
            <Button
              btn={BtnType.FRAME_LESS}
              className={
                docLoading ? styles.disabledSaveButton : styles.saveButton
              }
              onClick={saveModifications}
              disabled={docLoading}
            >
              Save Changes
            </Button>
          </div>
        ) : null}

        <div>
          {" "}
          {uploadValues?.length
            ? uploadValues.map((bar, index) => {
                if (bar) {
                  return (
                    bar?.progress !== "NOT_ALLOWED" && (
                      <div className={styles.loaderContainer}>
                        <div className={styles.loaderNamingWrp}>
                          {" "}
                          <div className={styles.fileName}>{bar?.name}</div>
                          <LinearProgressWithLabel
                            mode={bar?.progress === "FAILED" && "error"}
                            value={
                              bar?.progress === "FAILED"
                                ? 100
                                : bar?.progress || 0
                            }
                          />
                        </div>
                      </div>
                    )
                  );
                }
                return null;
              })
            : null}
        </div>
        {docsMeta?.total > 5 ? (
          <div className={styles.paginationCon}>
            <Pagination {...pageSetting} onChange={handlePagingChange} />
          </div>
        ) : null}
        <DragAndDrop
          uploadHandler={uploadHandler}
          loading={docLoading}
          customText="drag and drop documents here"
          unicumKey={item?.custom_field_template_id}
        />
      </div>

      {forbiddenFiles?.length ? (
        <div className={styles.forbiddenfileContainer}>
          Files that weren't allowed:{" "}
          {forbiddenFiles.map((file) => (
            <span className={styles.forbiddenfileName}>
              {`${file.name}: `}
              <span className={styles.errorText}> {file.error}</span>
            </span>
          ))}
        </div>
      ) : null}

      <div className={styles.infoWrp}>
        <div className={styles.dataRow}>
          <div className={styles.label}>
            {item.from_submission
              ? "Submitted by"
              : item.updated_at !== item.created_at
              ? "Updated by"
              : "Created by"}
          </div>
          <div className={styles.value}>
            {item.from_submission ? (
              <span>{item?.creator?.full_name || "-"}</span>
            ) : (
              <Link to={`/people/internal/profile/${item.user_id}`}>
                {item && item.creator && item.creator.full_name}
                {item.from_claimed_profile && (
                  <span>, {selectedCompany?.name}</span>
                )}
              </Link>
            )}
          </div>
        </div>
        {item.from_claimed_profile && (
          <div className="d-flex align-items-center mr-5">
            <div className={styles.companyAccess}>
              <span>&#x2713; Access</span>
            </div>
          </div>
        )}
        <div className={styles.date}>
          {item.updated_at !== item.created_at
            ? format(new Date(item.updated_at), "eeee, MMM do, yyyy hh:mm a")
            : format(new Date(item.created_at), "eeee, MMM do, yyyy hh:mm a")}
        </div>
        {item.from_submission ? (
          <div
            className={`d-flex flex-grow-1 align-items-center justify-content-center ${styles.companyResponse}`}
          >
            Company Response
          </div>
        ) : null}
      </div>
      <SimpleDialog
        open={openConfirmModal}
        handleDismiss={() => handleDismiss()}
        handleDelete={(e) => handleDeleteDocument(e)}
        openConfirmModal={openConfirmModal}
      />
    </div>
  );
};
export default React.memo(DocumentComponent);

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RichEditor from "common/components/richEditor";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { useSnackbar } from "notistack";

import AttachFileComponent from "../../../../../../common/components/attachFile/index";
import { Button } from "../../../../../primitives/index";
import styles from "./styles.module.scss";
import { postsReplyAddAttempt, postsReplyEditAttempt } from "./feed.action";
import selectMention from "../../../../themesCommon/mention/selectMention";

import createMentionEntities from "../../../../themesCommon/mention/mentionsOnEdit";
import checkForDeleteMention from "../../../../themesCommon/mention/handleDeleteMention";
import { searchMentionsGetAttempt } from "../postsBlock.action";

const TextAreaFeed = (props) => {
  const {
    height,
    mainTextArea,
    mode,
    replyData,
    selectedReply,
    themePostId,
    themeId,
    editableReply,
    setEditableReply,
    homePageUse,
  } = props;
  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },

    themesReducer: {
      themesPostsReducer: { loadingMentions, mentions },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const ref = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [replyText, setReplyText] = useState("");
  const [files, setFiles] = useState([]);
  const [themeMentionsAttributes, setThemeMentionsAttributes] = useState([]);
  const [dragActive, setDragActive] = React.useState(false);
  const [droppedFilesFromParent, setDroppedFilesFromParent] = React.useState(
    []
  );
  const [triggerFromParrent, setTriggerFromParrent] = React.useState(false);
  const [resetReply, setResetReply] = useState(false);
  const [editorState, setEditorState] = useState({});
  const [query, setQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  const onChange = (_editorState) => {
    setEditorState({
      editorState: _editorState,
    });
  };
  const linkRegex = /((http|https):\/\/[^\s]+)/g;
  // handle drag events
  const handleDrag = (e) => {
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  // triggers when file is dropped
  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setDroppedFilesFromParent(e.dataTransfer.files);
      setTriggerFromParrent(true);
    }
  };

  const handleImgUpdate = (url, name, third) => {
    if (url) {
      setFiles((prev) => {
        return [
          ...prev,
          {
            name,
            document_resource_type: "ThemePostReply",
            added_by_id: session.id,
            council_id: selectedCouncil?.id,
            uri: url,
          },
        ];
      });
    }
    setTriggerFromParrent(false);
  };

  const replaceImage = (index) => {
    setFiles((prev) => {
      return prev.map((el, i) => {
        if (i === index) {
          return { ...el, _destroy: true };
        }
        return el;
      });
    });
    setTriggerFromParrent(false);
  };
  const searchFunction = (value) => {
    setQuery(value);
  };
  const modifyMentionColor = () => {
    let copyOfText = replyText;

    themeMentionsAttributes.forEach((themeMention) => {
      let mentionLink = "";
      let navigationId = "";

      if (themeMention.theme_mentioned_resource_type === "Company") {
        mentionLink = "/companies/";
        navigationId =
          themeMention.slug ||
          themeMention.theme_mentioned_resource_slug ||
          themeMention.theme_mentioned_resource_id;
      } else {
        mentionLink = "/people/internal/profile/";
        navigationId = themeMention.theme_mentioned_resource_id;
      }
      const mentionName =
        themeMention.theme_mention_name ||
        themeMention.theme_mentioned_resource_name;
      copyOfText = copyOfText.replaceAll("&nbsp;", " ");
      if (editableReply?.theme_post_reply_id) {
        const elementToSearch = `@${mentionName} `;
        const elementToChangeOn = `<a href="${process.env.REACT_APP_REDIRECT_URI_APP}${mentionLink}${navigationId}" id="${themeMention.mention_id}">@${mentionName}</a> `;
        copyOfText = copyOfText.replace(elementToSearch, elementToChangeOn);
      } else {
        const elementToSearch = `@${mentionName} `;
        const elementToChangeOn = `<a href="${process.env.REACT_APP_REDIRECT_URI_APP}${mentionLink}${navigationId}" id="${themeMention.mention_id}">@${mentionName}</a> `;
        copyOfText = copyOfText.replace(elementToSearch, elementToChangeOn);
      }
    });
    return copyOfText;
  };

  const changeEditorState = (contentArg, intialRender) => {
    let editorStateText = contentArg;
    const themeMentions = [];

    if (editableReply?.theme_post_reply_id) {
      setThemeMentionsAttributes([]);

      editableReply.theme_mentions.forEach((mention) => {
        let mentionLink = "";
        let navigationId = "";
        const mentionType = mention.theme_mentioned_resource_type;
        const mentionName = mention.theme_mentioned_resource_name;
        if (mention.theme_mentioned_resource_type === "Company") {
          mentionLink = "/companies/";
          navigationId =
            mention.slug ||
            mention.theme_mentioned_resource_slug ||
            mention.theme_mentioned_resource_id;
        } else {
          mentionLink = "/people/internal/profile/";
          navigationId = mention.theme_mentioned_resource_id;
        }
        const elementToSearch = `<span><a style="${
          mentionType === "Company"
            ? "color:black !important; font-weight:bold"
            : "color:#3994c1; font-weight:bold"
        } " href="${
          process.env.REACT_APP_REDIRECT_URI_APP
        }${mentionLink}${navigationId}" >@${mentionName}</a></span> `;
        if (intialRender) {
          const elementToChangeOn = `@${mention.theme_mentioned_resource_name} `;
          editorStateText = editorStateText.replace(
            elementToSearch,
            elementToChangeOn
          );
          themeMentions.push({
            ...mention,
            name: `@${mention.theme_mentioned_resource_name}`,
            id: mention.theme_mention_id,
            mention_id:
              new Date().toString().split(" ").join(" ") +
              (Math.random() * 1000).toString(),
          });
        }
      });

      const blocksFromHTML = convertFromHTML(editorStateText);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const tempEditorState = EditorState.createWithContent(content);
      const editorStateWithMention = EditorState.createWithContent(
        createMentionEntities(
          tempEditorState.getCurrentContent().getPlainText("\u0001"),
          themeMentions
        )
      );
      setThemeMentionsAttributes(themeMentions);

      setEditorState({
        editorState: editorStateWithMention,
      });
    } else {
      const blocksFromHTML = convertFromHTML(contentArg);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState({
        editorState: EditorState.createWithContent(content),
      });
    }
  };
  const removeIdsFromHTML = (textWithId) => {
    let textWithoutId = textWithId;
    themeMentionsAttributes.forEach((themeMention) => {
      let mentionLink = "";
      let navigationId = "";
      const mentionType = themeMention.theme_mentioned_resource_type;
      if (themeMention.theme_mentioned_resource_type === "Company") {
        mentionLink = "/companies/";
        navigationId =
          themeMention.slug ||
          themeMention.theme_mentioned_resource_slug ||
          themeMention.theme_mentioned_resource_id;
      } else {
        mentionLink = "/people/internal/profile/";
        navigationId = themeMention.theme_mentioned_resource_id;
      }
      const mentionName =
        themeMention.theme_mention_name ||
        themeMention.theme_mentioned_resource_name;
      const elementToSearch = `<a href="${process.env.REACT_APP_REDIRECT_URI_APP}${mentionLink}${navigationId}" id="${themeMention.mention_id}">@${mentionName}</a> `;
      const elementToChangeOn = `<span><a style="${
        mentionType === "Company"
          ? "color:black !important; font-weight:bold"
          : "color:#3994c1; font-weight:bold"
      } " href="${
        process.env.REACT_APP_REDIRECT_URI_APP
      }${mentionLink}${navigationId}">@${mentionName}</a></span> `;
      textWithoutId = textWithoutId.replace(elementToSearch, elementToChangeOn);
    });
    return textWithoutId;
  };
  const sendData = (e) => {
    let theme_mentions_attributes = themeMentionsAttributes;
    let replyInputText = replyText;
    replyInputText = modifyMentionColor();
    theme_mentions_attributes = checkForDeleteMention(
      mode,
      themeMentionsAttributes,
      replyInputText
    );
    replyInputText = removeIdsFromHTML(replyInputText);

    let data = {
      theme_post_reply: {
        content: replyInputText,
        documents_attributes: files,
      },
    };
    if (selectedReply?.theme_post_reply_id?.length && !mainTextArea) {
      data = {
        theme_post_reply: {
          ...data.theme_post_reply,
          replied_theme_post_reply_id: selectedReply.theme_post_reply_id,
        },
      };
    }
    if (themeMentionsAttributes.length) {
      data = {
        theme_post_reply: {
          ...data.theme_post_reply,
          theme_mentions_attributes,
        },
      };
    }
    if (editableReply?.theme_post_reply_id) {
      dispatch(
        postsReplyEditAttempt({
          theme_id: themeId,
          post_id: themePostId,
          reply_id: editableReply.theme_post_reply_id,
          data,
        })
      );
      setEditableReply({});
    } else {
      dispatch(
        postsReplyAddAttempt({
          theme_id: themeId,
          post_id: themePostId,
          data,
        })
      );
    }
    setFiles([]);
    e.stopPropagation();
    e.preventDefault();

    const timeout = setTimeout(() => {
      // setResetReply(false);
      setReplyText("");
      setThemeMentionsAttributes([]);
      changeEditorState("<p></p>");
      setTriggerFromParrent(false);
      // setResetReply(true);
      clearTimeout(timeout);
    });
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      sendData(e);
    }
  };
  const handleAddMention = (e) => {
    const mentionResourceId = editableReply
      ? editableReply?.theme_post_reply_id
      : replyData?.theme_post_reply_id;
    selectMention(
      e,
      replyText,
      setReplyText,
      setThemeMentionsAttributes,
      themeMentionsAttributes,
      session.id,
      mentionResourceId,
      "ThemePostReply",
      mentionResourceId,
      mode
    );
  };
  useEffect(() => {
    if (mode === "edit" && editableReply?.theme_post_reply_id) {
      changeEditorState(editableReply?.content, true);
      if (editableReply?.documents[0]) {
        setFiles(editableReply?.documents);
      }
    }
  }, [editableReply, mode]);

  function replacer(matched) {
    let withProtocol = matched;
    if (!withProtocol.startsWith("http")) {
      withProtocol = "http://" + matched + " ";
    }
    return `<a  style="color:#3994c1 !important ;text-align:center;" href="${withProtocol}"  target="_blank" >
      ${matched}
    </a>`;
  }
  function findLinks(text) {
    const regex = /((http|https):\/\/[^\s]+)/g;
    return text.match(regex);
  }
  function findAnchors(text) {
    const matches = [];
    const regex = text.replace(
      /[^<]*(<a href="([^"]+)">([^<]+)<\/a>)/g,
      function () {
        matches.push(Array.prototype.slice.call(arguments, 1, 4));
      }
    );
    return matches;
  }

  useEffect(() => {
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      setDebouncedValue(query);
    }, delay);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [query]);

  const handleRequest = (inputValue) => {
    let tempQuery = "*";
    if (inputValue.length) {
      tempQuery = inputValue;
    }
    dispatch(
      searchMentionsGetAttempt({
        search: { query: tempQuery },
        enqueueSnackbar,
      })
    );
  };

  useEffect(() => {
    handleRequest(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    setTimeout(() => {
      // changeEditorState("<p></p>");
    });
  }, []);

  return (
    <footer
      style={
        mainTextArea
          ? { position: "absolute" }
          : { backgroundColor: "white", marginBottom: 0 }
      }
      className={styles.threadFooter}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <div className={dragActive ? styles.draggBox : styles.replyTextBox}>
        <RichEditor
          initialValue={editableReply?.content || ""}
          settedValue={replyText}
          discussionId={themePostId}
          mentionsList={mentions}
          homePageUse={homePageUse}
          loadingMentions={loadingMentions}
          handleAddMention={handleAddMention}
          customOnChange={onChange}
          searchFunction={searchFunction}
          updateValue={(e) => {
            if (findLinks(e)) {
              if (findAnchors(e)[0]) {
                setReplyText(e);
                return;
              }
              const str = e?.replace(/<\/?[^>]+(>|$)/g, "");
              setReplyText(str.replace(linkRegex, replacer));
            } else {
              setReplyText(e);
            }
          }}
          shouldReset={resetReply}
          onKeyDown={(e) => {
            handleOnKeyDown(e);
          }}
          style={height ? { minHeight: height, border: "1px solid red" } : null}
          placeholder={dragActive ? "Drop file here..." : "Type Your Reply..."}
          newEditorState={editorState}
        />
      </div>
      <div
        className={styles.footerActions}
        style={!mainTextArea ? { height: 10 } : null}
      >
        <AttachFileComponent
          files={files}
          onChange={handleImgUpdate}
          uploadDirName={`themes/${themeId}/key`}
          replaceImage={replaceImage}
          droppedFilesFromParent={droppedFilesFromParent}
          triggerFromParrent={triggerFromParrent}
        />
        <Button
          type="submit"
          onClick={(e) => {
            sendData(e);
          }}
          className={styles.replyButton}
        >
          REPLY
        </Button>
      </div>
    </footer>
  );
};
export default TextAreaFeed;

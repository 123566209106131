import { Chart, registerables } from "chart.js";

import {
  PROP_BY_COLOR,
  CHART_COLORS,
  CUSTOM_PIE_TAGS_BY_BREAKDOWN,
  propsUsedFoCompaniesSelect,
} from "../charts-constants";

const centerDoughnutPlugin = {
  id: 'doughnutChart',
  beforeDraw: (chart) => {
    if (chart.config._config.type !== 'doughnut') {
      return;
    }

    if (chart.config?.options?.elements?.center) {
      const ctx = chart.ctx;
      const centerConfig = chart.config.options.elements.center;
      const fontStyle = centerConfig.fontStyle || 'Arial';
      const txt = centerConfig.text;
      const color = centerConfig.color || '#000';
      const maxFontSize = centerConfig.maxFontSize || 25;
      const sidePadding = centerConfig.sidePadding || 20;
      const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)

      ctx.font = "16px " + fontStyle;
      ctx.fillStyle = color;

      const stringWidth = ctx.measureText(txt).width;
      const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
      const widthRatio = elementWidth / stringWidth;
      const newFontSize = Math.floor(30 * widthRatio);
      const elementHeight = (chart.innerRadius * 2);
      let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
      let minFontSize = centerConfig.minFontSize;
      const lineHeight = centerConfig.lineHeight || 21;
      let wrapText = false;

      if (minFontSize === undefined) {
        minFontSize = 16;
      }

      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize;
        wrapText = true;
      }

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      ctx.font = fontSizeToUse + "px " + fontStyle;
      ctx.fillStyle = color;

      if (!wrapText) {
        ctx.fillText(txt, centerX, centerY);
        return;
      }

      const words = txt.split(' ');
      let line = '';
      const lines = [];

      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + ' ';
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > elementWidth && n > 0) {
          lines.push(line);
          line = words[n] + ' ';
        } else {
          line = testLine;
        }
      }

      centerY -= (lines.length / 2) * lineHeight;

      for (let n = 0; n < lines.length; n++) {
        ctx.fillText(lines[n], centerX, centerY);
        centerY += lineHeight;
      }

      ctx.fillText(line, centerX, centerY);
    }
  },
};

Chart.register(...registerables, centerDoughnutPlugin);

export const flat = (arr, prop) => arr.reduce((prev, item) => {
  const isArray = Array.isArray(item[prop]);

  if (isArray) {
    return [...prev, item, ...flat(item[prop], prop)];
  }

  return [...prev, item];
}, []);

export const makeArrayBasedOnYears = (years = 1, currYear) => Array.from(
  new Array(years === 10 ? 9 : years),
  (val, index) => currYear - index,
).reverse();

export const makeFlattenCustomTags = (
  companies,
  chartOptions,
  isBarChartUse,
  isYearSelect,
  colorIsRevenue,
) => {
  const propToUse = colorIsRevenue
    ? chartOptions.color
    : isBarChartUse
      ? chartOptions.x
      : chartOptions.color;
  const { tags } = CUSTOM_PIE_TAGS_BY_BREAKDOWN[propToUse];

  if (isBarChartUse) {
    if (chartOptions.x === "yearFounded") {
      const currYear = new Date().getFullYear();
      const listOFYears = makeArrayBasedOnYears(chartOptions.years, currYear);

      return listOFYears.map((year, idx) => {
        const color = isYearSelect
          ? year === currYear
            ? "#00611d"
            : "#00a7fa"
          : CHART_COLORS[idx % CHART_COLORS.length];
        const getCompaniesByYear = companies.filter(
          (c) => c.funding_rounds_charts && c.funding_rounds_charts[year],
        );

        return {
          id: year,
          value: year,
          selected: false,
          color,
          name: year === currYear ? `${year} (YTD)` : year,
          count: getCompaniesByYear.length,
          companiesIdThatBelongsTo: getCompaniesByYear.map((c) => c.id),
          originalColor: CHART_COLORS[idx % CHART_COLORS.length],
        };
      });
    }

    if (
      chartOptions.x === "numberOfEmployees"
      || chartOptions.x === "revenue_range"
      || chartOptions.color === "revenue_range"
    ) {
      return tags
        .reduce((acc, tag) => {
          const filteredCompanies = companies.filter(
            (c) => c[
              chartOptions.x === "numberOfEmployees"
                ? "num_employees_enum"
                : "revenue_range"
            ] === tag.value,
          );

          return [
            ...acc,
            {
              ...tag,
              companiesIdThatBelongsTo: filteredCompanies.map((c) => c.id),
              count: filteredCompanies.length,
            },
          ];
        }, [])
        .filter((tag) => tag.count !== 0)
        .map((tag, idx) => {
          const color = isYearSelect
            ? "#00a7fa"
            : CHART_COLORS[idx % CHART_COLORS.length];

          return {
            ...tag,
            color,
            originalColor: CHART_COLORS[idx % CHART_COLORS.length],
          };
        });
    }

    if (chartOptions.x === "yearFounded") {
      return tags
        .reduce((acc, tag) => {
          let filteredCompanies = [];

          if (tag.value > 20) {
            filteredCompanies = companies.filter(
              (c) => c.years_in_operation > 20,
            );
          } else {
            filteredCompanies = companies.filter(
              (c) => c.years_in_operation <= tag.value && c.years_in_operation < 21,
            );
          }

          return [
            ...acc,
            {
              ...tag,
              companiesIdThatBelongsTo: filteredCompanies.map((c) => c.id),
              count: filteredCompanies.length,
            },
          ];
        }, [])
        .filter((tag) => tag.count !== 0);
    }
  }

  if (
    chartOptions.color === "numberOfEmployees"
    || chartOptions.color === "revenue_range"
  ) {
    return tags
      .reduce((acc, tag) => {
        const filteredCompanies = companies.filter(
          (c) => c[
            chartOptions.color === "numberOfEmployees"
              ? "num_employees_enum"
              : "revenue_range"
          ] === tag.value,
        );

        return [
          ...acc,
          {
            ...tag,
            companiesIdThatBelongsTo: filteredCompanies.map((c) => c.id),
            count: filteredCompanies.length,
          },
        ];
      }, [])
      .filter((tag) => tag.count !== 0);
  }

  if (chartOptions.color === "yearFounded") {
    return tags
      .reduce((acc, tag) => {
        let filteredCompanies = [];

        if (tag.value > 20) {
          filteredCompanies = companies.filter(
            (c) => c.years_in_operation > 20,
          );
        } else {
          filteredCompanies = companies.filter(
            (c) => c.years_in_operation <= tag.value && c.years_in_operation < 21,
          );
        }

        return [
          ...acc,
          {
            ...tag,
            companiesIdThatBelongsTo: filteredCompanies.map((c) => c.id),
            count: filteredCompanies.length,
          },
        ];
      }, [])
      .filter((tag) => tag.count !== 0);
  }

  if (chartOptions.color === "score") {
    return tags
      .reduce((acc, tag) => {
        let filteredCompanies = [];

        if (!tag.score) {
          filteredCompanies = companies.filter((c) => !c.council_company_score);
        } else {
          filteredCompanies = companies.filter(
            (c) => c.council_company_score === tag.score,
          );
        }

        return [
          ...acc,
          {
            ...tag,
            companiesIdThatBelongsTo: filteredCompanies.map((c) => c.id),
            count: filteredCompanies.length,
          },
        ];
      }, [])
      .filter((tag) => tag.count !== 0);
  }

  if (
    chartOptions.color === "totalFunding"
    || chartOptions.color === "amountOfLastRound"
  ) {
    const prop = chartOptions.color === "totalFunding"
      ? "total_fundings_sum"
      : "last_funding_amount_usd";

    return tags
      .reduce((acc, tag) => {
        let filteredCompanies = [];

        if (tag.id === "0na") {
          filteredCompanies = companies.filter(
            (c) => !c[prop] || c[prop] === 0,
          );
        } else {
          filteredCompanies = companies.filter(
            (c) => c[prop] >= tag.range.start && c[prop] <= tag.range.end,
          );
        }

        return [
          ...acc,
          {
            ...tag,
            companiesIdThatBelongsTo: filteredCompanies.map((c) => c.id),
            count: filteredCompanies.length,
          },
        ];
      }, [])
      .filter((tag) => tag.count !== 0);
  }
};

export const makeFlattenTags = (
  companies,
  selectedChartOptions,
  selectedChartType,
  councilTags = [],
) => {
  if (selectedChartOptions.color === "year") return [];

  const tags = companies
    .reduce((acc, company) => {
      let newAcc = acc;
      const items = company[PROP_BY_COLOR[selectedChartOptions.color].top];

      if (!items) return acc;

      items.forEach((item) => {
        const itemId = item[PROP_BY_COLOR[selectedChartOptions.color].bottom];
        const itemExist = newAcc.some((i) => itemId === i.id);

        if (itemExist) {
          newAcc = newAcc.map((i) => {
            if (i.id === itemId) {
              return {
                ...i,
                count: i.count + 1,
                companiesIdThatBelongsTo: [
                  ...i.companiesIdThatBelongsTo,
                  company.id,
                ],
                total_fundings_sum: (i.total_fundings_sum
                  += company.total_fundings_sum),
                totalFunding: (i.totalFunding += company.total_fundings_sum),
                last_funding_amount_usd: (i.last_funding_amount_usd
                  += company.last_funding_amount_usd || 0),
              };
            }

            return i;
          });
        } else {
          newAcc = [
            ...newAcc,
            {
              name: item.name,
              id: itemId,
              count: 1,
              selected: false,
              companiesIdThatBelongsTo: [company.id],
              total_fundings_sum: company.total_fundings_sum || 0,
              totalFunding: company.total_fundings_sum || 0,
              last_funding_amount_usd: company.last_funding_amount_usd || 0,
            },
          ];
        }
      });

      return newAcc;
    }, [])
    .map((tag, idx) => {
      const color = CHART_COLORS[idx % CHART_COLORS.length];

      return {
        ...tag,
        color,
      };
    });

  if (selectedChartType === "bar") {
    return tags
      .filter((tag) => councilTags.some((el) => el.id === tag.id))
      .sort(
        (a, b) => b[propsUsedFoCompaniesSelect[selectedChartOptions.y]]
          - a[propsUsedFoCompaniesSelect[selectedChartOptions.y]],
      )
      .map((elem, idx) => {
        const color = CHART_COLORS[idx % CHART_COLORS.length];

        return {
          ...elem,
          color,
          originalColor: color,
        };
      });
  }

  if (selectedChartType === "pie") {
    const filteredTags = tags.filter((tag) => councilTags.some((el) => el.id === tag.id));

    if (filteredTags.length <= 7) {
      return filteredTags
        .sort((a, b) => b.count - a.count)
        .map((tag, idx) => {
          const color = CHART_COLORS[idx % CHART_COLORS.length];

          return {
            ...tag,
            color,
            originalColor: color,
          };
        });
    }

    const mappedValue = filteredTags.map((tag) => tag.count);
    const minRange = Math.min.apply(Math, mappedValue);
    const maxRange = Math.max.apply(Math, mappedValue);
    const tagsWithOther = filteredTags.reduce(
      (acc, tag) => {
        const percentageFrom = ((tag.count - minRange) * 100) / (maxRange - minRange);

        if (percentageFrom < 7) {
          const newAcc = acc.map((t) => {
            if (t.name === "Other" && t.color) {
              return {
                ...t,
                count: t.count + tag.count,
                companiesIdThatBelongsTo: [
                  ...t.companiesIdThatBelongsTo,
                  ...tag.companiesIdThatBelongsTo,
                ],
              };
            }
            if (t.name === "Other" && !t.color) {
              return {
                ...t,
                count: tag.count,
                companiesIdThatBelongsTo: tag.companiesIdThatBelongsTo,
              };
            }
            return t;
          });

          return [...newAcc, tag];
        }

        return [...acc, tag];
      },
      [
        {
          name: "Other",
          color: "#188B7F",
          originalColor: "#188B7F",
          count: 0,
          id: "other",
          selected: false,
          companiesIdThatBelongsTo: [],
        },
      ],
    );
    const [first, ...rest] = tagsWithOther;
    const sortedRest = rest.sort((a, b) => b.count - a.count);

    return [...sortedRest, first].map((elem, idx) => {
      if (elem.id === "other") {
        return elem;
      }

      const color = CHART_COLORS[idx % CHART_COLORS.length];

      return {
        ...elem,
        color,
        originalColor: color,
      };
    });
  }

  return tags;
};

export const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.minWidth = "250px";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const getChart = (id) => Chart.getChart(id);

export function initializeChart(id, type, chartConfig) {
  return new Chart(id, {
    type,
    ...chartConfig,
  });
}

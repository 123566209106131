import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { Label, MultiSelect } from "modules/primitives";

const ProjectStages = ({ isAdminWizardInstance, formikprops, selectedTemplateId }) => {
  const {
    newProjectV3Reducer: { stageTemplates },
  } = useSelector((state) => state);

  const handleSelect = (evt) => {
    formikprops.setFieldValue('project_stage', evt[0]);
  }

  const getStagesList = useMemo(() => {
    return stageTemplates.find(t => t.id === selectedTemplateId)?.stages || [];
  }, [selectedTemplateId, stageTemplates]);

  useEffect(() => {
    if (!formikprops.values?.project_stage && getStagesList.length && !isAdminWizardInstance) {
      formikprops.setFieldValue('project_stage', getStagesList[0]);
    }
  }, [getStagesList, formikprops.values?.project_stage])

  return (
    <>
      <Label>Project Stage</Label>
      <div className="d-flex">
        <MultiSelect
          multi={false}
          onChange={handleSelect}
          placeholder="Project Stage"
          labelField="name"
          valueField="id"
          searchBy="name"
          options={getStagesList}
          values={formikprops.values?.project_stage ? [formikprops.values?.project_stage] : []}
          disabled={isAdminWizardInstance}
        />
      </div>
      <div className="caption small text-danger">
        {formikprops.touched['project_stage'] && formikprops.errors['project_stage']
          ? formikprops.errors['project_stage']
          : ""}
      </div>
    </>
  );
};

export default React.memo(ProjectStages);

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useSelector } from "react-redux";
import styles from "./relationShipStatus.module.scss";

import { CheckBox, CheckType } from "../../../../../primitives";
import { Icon, smSize } from "../../../../../../common/icon";
import {
  queryStringUrlReplacement,
  queryParamStringToArray,
  useQuery,
} from "../../../../../../common/helper";

const RelationShipStatusFilter = ({ expanded, handleExpand }) => {
  const {
    appReducer: { relationShipStatuses },
  } = useSelector((state) => state);

  const history = useHistory();
  const relationShipStatus = useQuery().get("relationShipStatus");

  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setCollapsed(expanded);

    return () => null;
  }, [expanded]);

  useEffect(() => {
    let newStatuses = [];

    if (relationShipStatuses) {
      newStatuses = relationShipStatuses.map((s) => ({
        ...s,
        selected: false,
      }));

      setItems(newStatuses);
    }

    if (relationShipStatus) {
      const arr = queryParamStringToArray(relationShipStatus);
      const newItems = newStatuses.map((item) => {
        const itemIsSelected = arr.includes(item.customId);

        return {
          ...item,
          selected: itemIsSelected,
        };
      });

      setItems(newItems);
    }

    return () => null;
  }, [relationShipStatuses, relationShipStatus]);

  const handleCollapseClick = () => {
    if (!collapsed) {
      handleExpand("relationShipStatus");
    }
    setCollapsed(!collapsed);
  };

  const handleOnChange = (val, status) => {
    const relStatusIds = items
      .map((s) => {
        if (s.customId === status.customId) {
          return {
            ...s,
            selected: val,
          };
        }
        return s;
      })
      .filter((item) => item.selected)
      .reduce(
        (acc, elem) => `${acc}${acc.length ? "," : ""}${elem.customId}`,
        "",
      );

    let search = queryStringUrlReplacement(
      history.location.search,
      "relationShipStatus",
      relStatusIds,
    );
    search = queryStringUrlReplacement(search, "page", 1);
    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        relationShipStatus: relStatusIds,
        page: 1,
      },
    });

    const newItems = items.map((s) => {
      if (s.customId === status.customId) {
        return {
          ...s,
          selected: val,
        };
      }

      return s;
    });

    setItems(newItems);
  };

  return (
    <div className={styles.itemWrapper} id="relationShipStatus">
      <div className={styles.itemTitle} onClick={handleCollapseClick}>
        <span>Relationship Stage</span>
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(styles.icon, !collapsed && styles.collapse)}
          onClick={handleCollapseClick}
        />
      </div>
      {collapsed ? (
        <>
          {items.map((s) => (
            <div key={s.name} className={styles.filterRow}>
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={s.selected}
                onChange={(val) => handleOnChange(val, s)}
              />
              <span onClick={() => handleOnChange(!s.selected, s)}>{s.name}</span>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};

export default React.memo(RelationShipStatusFilter);

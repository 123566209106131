import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import FinancialsBox from "modules/projects/project/overview/financialsBox";
import ProjectsThemes from "modules/projects/project/overview/themes";
import FordMetrics from "modules/projects/project/overview/fordMetrics";
import FordPlants from "modules/projects/project/overview/fordPlants";
import ProjectRatings from "modules/projects/project/overview/ratings";
import RelatedProjects from "modules/projects/project/overview/relatedProjects";
import ReplicationsStatus from "modules/projects/project/overview/replicationsStatus";

import styles from "./overview.module.scss";
import SummeryBlock from "./summaryBlock";
import ProjectDetailsComponent from "./projectDetails";
import ProjectCompaniesComponent from "./companies";
import ProjectMembersComponent from "./projectMembers";
import UpcomingTasksComponent from "./upcommingTasks";
import ProjectNextStepsComponent from "./projectNextSteps";
import ActivityComponent from "./activity";
import DocumentComponent from "./documents";
import { Discussion } from "../../../shared";
import RelatedIdeasComponent from "./ideas/index";
import ProjectsSolutions from "./solutionsBox/index";
import { httpGet, httpPost } from "../../../../common/httpCall";
import { isANumber, makeStages } from "../../../../common/helper";

const stages = makeStages(2022).reverse();
const defaultState = {
  prototype_investment_usd: null,
  threed_printing_replications: null,
  investment_usd: null,
  savings_usd: null,
  npv_usd: null,
  annual_incremental_investment_usd: null,
  current_spending_usd: null,
  tarr_percentage: null,
  next_stage_end_on: {
    id: "",
    name: "",
  },
  job_1_date: "",
  editMode: true,
};

const OverviewComponent = ({
  patientOrg,
  canManageTasks,
  isNot3DPrintingTemplate,
}) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isProjectMember, setIsProjectMember] = useState(null);
  const [metrics, setMetrics] = useState(defaultState);
  const [expandAll, setExpandAll] = useState(true);

  const {
    authReducer: { session },
    projectsReducer: {
      projectReducer: { selectedProject },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const isFordSupplyChainAutomation = useMemo(() => {
    return (
      selectedCouncil?.name === "Ford" &&
      selectedProject?.stage_template?.name === "Supply Chain Automation"
    );
  }, [selectedCouncil, selectedProject]);

  useEffect(() => {
    if (selectedCouncil && selectedCouncil?.name === "Ford") {
      setExpandAll(false);
    }
  }, [selectedCouncil]);

  useEffect(() => {
    if (!selectedProject?.id) {
      return;
    }

    httpGet({
      call: `projects/${selectedProject.id}/metrics`,
    }).subscribe((res) => {
      if (res.response?.attributes) {
        const values = Object.values(res.response?.attributes).filter(
          (val) => val
        );
        const getStage = stages.find(
          (s) => s.id === res.response.attributes?.next_stage_quarter
        );
        const newMetrics = {
          ...res.response.attributes,
          next_stage_end_on: getStage || defaultState.next_stage_end_on,
          id: res.response.id,
          editMode: values?.length === 0,
        };

        setMetrics(newMetrics);
        return;
      }

      setMetrics(defaultState);
    });

    return () => {
      setMetrics({
        ...defaultState,
        editMode: false,
      });
    };
  }, [selectedProject?.id]);

  useEffect(() => {
    const member = selectedProject?.project_members.find(
      (m) => m?.user?.id || m?.external_project_member?.id === session.id
    );

    if (member) {
      setIsProjectMember(member);
    }
  }, [session, selectedProject]);

  const isAdmin = useMemo(
    () =>
      session?.council_role === "ttp_administrator" ||
      session?.council_role === "council_administrator",
    [canManageTasks, session]
  );

  const handleSave = () => {
    const data = {
      prototype_investment_usd: isANumber(metrics.prototype_investment_usd)
        ? Number(metrics.prototype_investment_usd)
        : null,
      investment_usd: isANumber(metrics.investment_usd)
        ? Number(metrics.investment_usd)
        : null,
      savings_usd: isANumber(metrics.savings_usd)
        ? Number(metrics.savings_usd)
        : null,
      annual_incremental_investment_usd: isANumber(
        metrics.annual_incremental_investment_usd
      )
        ? Number(metrics.annual_incremental_investment_usd)
        : null,
      npv_usd: isANumber(metrics.npv_usd) ? Number(metrics.npv_usd) : null,
      current_spending_usd: isANumber(metrics.current_spending_usd)
        ? Number(metrics.current_spending_usd)
        : null,
      threed_printing_replications: isANumber(
        metrics.threed_printing_replications
      )
        ? Number(metrics.threed_printing_replications)
        : null,
      tarr_percentage: isANumber(metrics.tarr_percentage)
        ? Number(metrics.tarr_percentage)
        : null,
      next_stage_end_on:
        typeof metrics.next_stage_end_on === "string"
          ? metrics.next_stage_end_on
          : metrics.next_stage_end_on?.date || null,
      job_1_date: metrics.job_1_date?.length ? metrics.job_1_date : null,
    };

    httpPost({
      call: `projects/${selectedProject.id}/metrics`,
      data,
    }).subscribe((res) => {
      enqueueSnackbar("Successfully updated project metrics", {
        variant: "success",
      });
      if (res.response.id) {
        const newMetrics = {
          ...metrics,
          ...res.response.attributes,
          editMode: false,
        };

        setMetrics(newMetrics);
      }
    });
  };

  const focusMetricsEdit = () => {
    const element = document.getElementById("job1Date");

    if (element) {
      const position = element.getBoundingClientRect();
      window.scrollTo(position.left, position.top + window.scrollY - 200);
    }

    setMetrics({
      ...metrics,
      editMode: true,
    });
  };

  return (
    <div className={styles.overviewWrp}>
      <div className="row">
        <div className="col-9">
          {selectedCouncil?.name === "Firmenich" &&
          selectedProject?.stage_template?.default_template ? null : (
            <SummeryBlock
              selectedCouncil={selectedCouncil}
              fordInstance={selectedCouncil?.name === "Ford"}
              isAdmin={isAdmin}
              isNot3DPrintingTemplate={isNot3DPrintingTemplate}
              metrics={metrics}
            />
          )}
          {selectedCouncil?.name === "Kyndryl" && (
            <ProjectNextStepsComponent
              selectedProject={selectedProject}
              canManageTasks={canManageTasks || isProjectMember}
            />
          )}
          {selectedCouncil?.name === "Ford" && selectedProject && (
            <FordMetrics
              haveActions={
                canManageTasks || isProjectMember?.user?.id === session?.id
              }
              handleSave={handleSave}
              metrics={metrics}
              setMetrics={setMetrics}
              isAdmin={isAdmin}
              isNot3DPrintingTemplate={isNot3DPrintingTemplate}
              isFordSupplyChainAutomation={isFordSupplyChainAutomation}
            />
          )}
          <ProjectDetailsComponent
            canManageTasks={canManageTasks}
            metrics={metrics}
            setEditMetrics={focusMetricsEdit}
            isAdmin={isAdmin}
            isNot3DPrintingTemplate={isNot3DPrintingTemplate}
          />
          {selectedCouncil?.traction_tag === "firmenich" && selectedProject ? (
            <FinancialsBox
              project={selectedProject}
              haveActions={
                canManageTasks || isProjectMember?.user?.id === session?.id
              }
            />
          ) : null}
          {session?.id && selectedProject && (
            <UpcomingTasksComponent
              canManageTasks={canManageTasks}
              sessionId={session.id}
              selectedProject={selectedProject}
            />
          )}
          <DocumentComponent />
          <ActivityComponent />
        </div>
        <div className="col-3">
          {selectedCouncil?.name === "Ford" && selectedProject && (
            <>
              <FordPlants
                selectedCouncil={selectedCouncil}
                selectedProject={selectedProject}
                isAdmin={isAdmin}
              />
              <div className={styles.buttonContainer}>
                <span
                  className={styles.buttonLink}
                  onClick={() => setExpandAll((prev) => !prev)}
                >
                  {expandAll ? "Collapse" : "Expand"} All
                </span>
              </div>
              {isNot3DPrintingTemplate && !isFordSupplyChainAutomation && (
                <ReplicationsStatus
                  project={selectedProject}
                  isAdmin={isAdmin}
                  expandAll={expandAll}
                />
              )}
            </>
          )}
          {selectedCouncil?.name !== "Ford" && selectedProject && (
            <div className={styles.buttonContainer}>
              <span
                className={styles.buttonLink}
                onClick={() => setExpandAll((prev) => !prev)}
              >
                {expandAll ? "Collapse" : "Expand"} All
              </span>
            </div>
          )}
          <ProjectCompaniesComponent
            patientOrg={patientOrg}
            selectedProject={selectedProject}
            expandAll={expandAll}
          />
          {selectedProject?.rating_enabled && (
            <ProjectRatings project={selectedProject} expandAll={expandAll} />
          )}
          <ProjectMembersComponent expandAll={expandAll} />
          <Discussion
            projectId={id}
            type="Project"
            reducerProp="projectDiscussions"
            isCurrentUserProjectMember={isProjectMember}
            expandAll={expandAll}
            withCollapse
          />
          {selectedCouncil?.name === "Ford" && (
            <RelatedProjects
              selectedProject={selectedProject}
              expandAll={expandAll}
            />
          )}
          {selectedCouncil?.themes_connected &&
            (selectedCouncil?.settings.theme_projects_enabled ||
              selectedCouncil?.settings.theme_projects_enabled ===
                undefined) && (
              <ProjectsThemes
                selectedProject={selectedProject}
                selectedCouncil={selectedCouncil}
                expandAll={expandAll}
                sessionRole={session?.council_role}
              />
            )}
          {selectedCouncil?.settings?.solutions_connected &&
            !isFordSupplyChainAutomation && (
              <ProjectsSolutions
                selectedProject={selectedProject}
                selectedCouncil={selectedCouncil}
                expandAll={expandAll}
                sessionRole={session?.council_role}
              />
            )}
          {selectedCouncil?.traction_tag !== "gsk" &&
            selectedCouncil?.settings?.ideas_connected !== false && (
              <RelatedIdeasComponent
                selectedProject={selectedProject}
                selectedCouncil={selectedCouncil}
                expandAll={expandAll}
                sessionRole={session?.council_role}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(OverviewComponent);

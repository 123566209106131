import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import {
  videoExtensions,
} from "modules/events/viewEvent/viewEvent.constant";
import { useSelector } from "react-redux";
import {
  Loading,
  Label,
  TextBox,
  Button,
  BtnType,
} from "modules/primitives/index";
import DragAndDrop from "common/components/dragAndDrop/index";
import checkUniqName from "common/checkUniqName";
import checkDuplicateName from "common/checkDuplicateName";
import findCurrentCustomFieldGroup from "../../../findCustomFieldGroup";

import SimpleDialog from "../../../../modules/companies/company/details/confirmationModal";
import { Icon, mdSize } from "../../../icon";
import useS3FileUpload from "../../../hooks/s3FileUplodar.hook";
import { model, validation } from "./constant";
import styles from "./styles.module.scss";
import LinearProgressWithLabel from "../../../components/linearProgressWithLabel";
import UniqName from "../uniqName/uniqName";

const NewVideosComponent = (props) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany, newCustomFieldGroups },
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: { selectedPatientOrganization },
    },
    adminCustomFieldsReducer: { customFieldGroups },
  } = useSelector((state) => state);
  const {
    formRef,
    handleOnSubmit,
    id,
    data,
    mode,
    setShowFooterButtons,
    fromAdmin = false,
    rfiInstance,
    uniqName,
    companyId,
    adminLocation,
    selectedCustomFieldGroup,
    indexOfCfGroup,
  } = props;
  const [initModel, setInitModel] = useState({ ...model });
  const [videos, setVideos] = useState([]);
  const [uploadedVideos, setUploadedVideos] = useState(data?.uploadedVideos || []);
  const { enqueueSnackbar } = useSnackbar();
  const { fileUpload, fileDelete } = useS3FileUpload();
  const [isDeleting, setIsDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(null);
  const [customLocalDelete, setCustomLocalDelete] = useState(null);
  const [uploadValues, setUploadValues] = useState([]);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [unsavedFiles, setUnsavedFiles] = useState([]);
  const [deletingVid, setDeletingVid] = useState({});
  const [titleError, setTitleError] = useState("");

  useEffect(() => {
    setUnsavedFiles(data?.unsavedFiles || []);
  }, [data?.unsavedFiles]);

  const getIframeSrc = (url) => {
    const match = /src\s*=\s*"([^"]+)"/i.exec(url);
    if (match) {
      return match[1];
    }
  };

  const handleOpenModal = (e, i, video) => {
    e.stopPropagation();
    setIndex(i);
    setOpenConfirmModal(true);
    setDeletingVid(video);
    setCustomLocalDelete(video);
  };

  const handleDismiss = () => {
    setOpenConfirmModal(!openConfirmModal);
  };

  const checkIfS3 = (url) => {
    const match = /(s3-|s3\.)?(.*)\.amazonaws\.com/g.exec(url);

    if (match) {
      return match[0];
    }
  };

  const getFilename = (url) => {
    if (url) {
      return url.split("/").pop();
    }
    return "";
  };

  const handleDeleteVideo = async (e) => {
    e.stopPropagation();
    setOpenConfirmModal(false);
    setIsDeleting(index);

    if (checkIfS3(videos[index].uri)) {
      const pathname = `srm/${selectedCouncil.id}/${
        selectedPatientOrganization?.id ? "patient_organizations" : "companies"
      }/${selectedPatientOrganization?.id || selectedCompany?.id || companyId}/videos/${getFilename(
        videos[index].uri
      )}`;
      await fileDelete(pathname).then(() => {
        setIsDeleting("");
        setVideos(() =>
          videos.map((vid, idx) => {
            if (idx === index) {
              return {
                ...vid,
                _destroy: true,
              };
            }
            return vid;
          })
        );
        if (rfiInstance) {
          const newUnsavedFiles = unsavedFiles.filter((v, idx) => idx !== index);
          setUnsavedFiles(newUnsavedFiles);
        }
        const newUploadedVideos = uploadedVideos.filter((v) => v !== customLocalDelete);
        setUploadedVideos(newUploadedVideos);
      });
    } else {
      setVideos(() => {
        setIsDeleting("");
        return videos.map((vid, idx) => {
          if (idx === index) {
            return {
              ...vid,
              _destroy: true,
            };
          }
          return vid;
        });
      });
      if (rfiInstance) {
        const newUnsavedFiles = unsavedFiles.filter((v, idx) => idx !== index);
        setUnsavedFiles(newUnsavedFiles);
      }
      const newUploadedVideos = uploadedVideos.filter((v) => v !== customLocalDelete);
      setUploadedVideos(newUploadedVideos);
    }
    setIndex(null);
    setCustomLocalDelete(null);
  };

  const uploadHandler = async (e, action) => {
    if (forbiddenFiles.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }
    setLoading(true);

    setUploadValues((items) => {
      const currentFiles = [...items];

      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name,
      }));
      return [...currentFiles, ...newFiles];
    });

    const fileList = [];
    let dismissedFiles = 0;

    files.forEach(async (file, index, array) => {
      const isLastIndex = Boolean(index === array?.length - 1);
      if (file.size <= 500000000 && videoExtensions.indexOf(getExtension(file.name)) !== -1) {
        const pathname = `srm/${selectedCouncil.id}/${
          selectedPatientOrganization?.id ? "patient_organizations" : "companies"
        }/${selectedPatientOrganization?.id || selectedCompany?.id || companyId}/videos`;
        await fileUpload(file, pathname, setUploadValues, file.name).then((res) => {
          if (res && res.location) {
            if (videos.map((vid) => vid.uri).indexOf(res.location) === -1) {
              fileList.push(res.location);
              isLastIndex && setLoading(false);
              if (fileList.length === files.length - dismissedFiles) {
                setVideos([
                  ...videos,
                  ...fileList.map((uri) => ({
                    uri,
                    name: getFilename(uri),
                    council_id: selectedCouncil.id,
                    added_by_id: session.id,
                    rfi: rfiInstance,
                  })),
                ]);
                setUploadedVideos([
                  ...uploadedVideos,
                  ...fileList.map((uri) => ({
                    uri,
                    name: getFilename(uri),
                    council_id: selectedCouncil.id,
                    added_by_id: session.id,
                    rfi: rfiInstance,
                  })),
                ]);
              }
              if (rfiInstance) {
                const newUnsavedFiles = [...unsavedFiles, ...fileList];
                setUnsavedFiles(newUnsavedFiles);
              }
            } else {
              enqueueSnackbar("This document was already added.", {
                variant: "error",
              });
              isLastIndex && setLoading(false);
              dismissedFiles++;
              setForbiddenFiles((prevFiles) => {
                const localFiles = [...prevFiles];
                localFiles.push({
                  name: file.name,
                  error: "Document already added",
                });
                return localFiles;
              });
              setUploadValues((prevState) => {
                const currentProgress = [...prevState];
                const index = currentProgress.map((item) => item.name).lastIndexOf(file.name);
                currentProgress[index] = {
                  progress: "NOT_ALLOWED",
                  name: file.name,
                };
                return currentProgress;
              });
            }
          }
        });
      } else {
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex((item) => item.name === file.name);
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: file.name,
          };
          return currentProgress;
        });
        isLastIndex && setLoading(false);
        if (file.size > 500000000) {
          dismissedFiles++;
          enqueueSnackbar(
            "We could not upload your image because your file size is too big. Please make sure the file is less than 500 MB",
            {
              variant: "error",
            }
          );
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error: "Your file is too large. File size limit: 500MB",
            });
            return localFiles;
          });
        }
        if (videoExtensions.indexOf(getExtension(file.name)) === -1) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error:
                "Extension is not allowed, the accepted file formats are: mp4, mov, wmw, flv, avi, webm, mkw.",
            });
            return localFiles;
          });
          isLastIndex && setLoading(false);
          dismissedFiles++;
          // enqueueSnackbar('Extenstion is not allowed, the accepted file formats are: mp4, mov, wmw, flv, avi, webm, mkw.', {
          //   variant: 'error',
          // });
        }
      }
    });

    e.target.value = "";
  };

  const onSubmit = (values) => {
    const cfGroup =
      findCurrentCustomFieldGroup(
        selectedCustomFieldGroup,
        customFieldGroups,
        newCustomFieldGroups,
        indexOfCfGroup,
        data
      ) || [];

    if (!values.title.length) {
      const field_name = checkDuplicateName(cfGroup, "video");
      values.title = field_name;
    } else if (
      !checkUniqName(values.title, cfGroup) &&
      values.title?.trim()?.toLowerCase() !== data?.field_name?.trim()?.toLowerCase()
    ) {
      setTitleError("Field name must be unique. Please rename this field");
      return;
    }
    setTitleError("");

    handleOnSubmit({
      ...values,
      // options: videos,
      // option_values: videos,
      // field_values: videos,
      uploadedVideos,
      unsavedFiles,
      documents_attributes: videos,
    });
    values.title = "";
  };

  const handleOpenLink = (link) => {
    if (link.includes("iframe")) {
      window.open(getIframeSrc(link));
    } else {
      window.open(link);
    }
  };

  const isEmbedded = (url) => {
    return videoExtensions.indexOf(getExtension(url)) !== -1;
  };

  const getExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  const checkPlatform = (url) => {
    const regExpYoutube =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const regExpVimeo =
      /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    const regExpFacebook =
      /^https?:\/\/www\.facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;

    if (url.includes("iframe")) {
      return "iframe";
    }
    if (url.match(regExpYoutube)) {
      return "youtube";
    }
    if (url.match(regExpVimeo)) {
      return "vimeo";
    }
    if (url.match(regExpFacebook)) {
      return "facebook";
    }
  };

  const handleAddEmbededCode = (e, formProps) => {
    if (videos) {
      if (
        checkPlatform(formRef?.current?.values.embedCode) &&
        !isEmbedded(formRef?.current?.values.embedCode)
      ) {
        if (videos.map((el) => el.uri).indexOf(formRef?.current?.values.embedCode) === -1) {
          const newEmbed = formRef?.current?.values.embedCode.trim();
          formRef.current.values.embedCode = "";
          setVideos([
            ...videos,
            {
              uri: newEmbed,
              name: getFilename(newEmbed),
              council_id: selectedCouncil.id,
              added_by_id: session.id,
            },
          ]);
          setUploadedVideos([...uploadedVideos, newEmbed]);
        } else {
          enqueueSnackbar("This video was already added.", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("External video links should be from Youtube, Facebook or Vimeo", {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (mode === "edit") {
      const editModel = {
        title: data.field_name,
        field_placeholder: data.field_placeholder,
      };
      setVideos(data?.documents?.length ? data.documents : []);
      setInitModel({ ...initModel, ...editModel });
    }
  }, []);

  useEffect(() => {
    setShowFooterButtons(videos.length > 0);
  }, [videos]);

  return (
    <div className={styles.wrapper} onDrop={(e) => uploadHandler(e, "drop")}>
      <SimpleDialog
        open={openConfirmModal}
        handleDismiss={() => handleDismiss()}
        handleDelete={(e) => handleDeleteVideo(e)}
        openConfirmModal={openConfirmModal}
        style={{ border: "1px solid red", width: "100%" }}
      />
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initModel && initModel}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit(values);
        }}
      >
        {({ handleSubmit, values, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <Label>Title (optional)</Label>
            <TextBox
              disabled={rfiInstance}
              type="text"
              name="title"
              placeholder="Enter video title here"
              formProps={{ ...formikprops, values }}
              className={styles.textBox}
            />
            {titleError.length > 1 && (
              <div className={styles.errorBox}>
                <sup>*</sup>
                {titleError}
              </div>
            )}
            <UniqName uniqName={uniqName} />

            {!adminLocation && (
              <div className="d-flex flex-column align-items-start">
                <Label>Instructions (Optional)</Label>
                <TextBox
                  type="text"
                  name="field_placeholder"
                  placeholder="Add instructions"
                  formProps={{ ...formikprops, values }}
                  className={styles.textBox}
                />
              </div>
            )}
            {!fromAdmin && (
              <>
                <Label>Upload video</Label>
                {uploadValues?.length
                  ? uploadValues.map(
                      (bar, index) =>
                        bar &&
                        bar?.progress !== "NOT_ALLOWED" && (
                          <div className={styles.loaderContainer}>
                            <div className={styles.loaderNamingWrp}>
                              {" "}
                              <div className={styles.fileName}>{uploadValues[index]?.name}</div>
                              <LinearProgressWithLabel
                                mode={uploadValues[index]?.progress === "FAILED" && "error"}
                                value={
                                  uploadValues[index]?.progress === "FAILED"
                                    ? 100
                                    : uploadValues[index]?.progress || 0
                                }
                              />
                            </div>
                          </div>
                        )
                    )
                  : null}

                <DragAndDrop
                  uploadHandler={uploadHandler}
                  loading={loading}
                  customText="drag and drop videos here"
                />

                {forbiddenFiles?.length ? (
                  <div className={styles.forbiddenfileContainer}>
                    Files that weren't allowed:{" "}
                    {forbiddenFiles.map((file) => (
                      <span className={styles.forbiddenfileName}>
                        {`${file.name}: `}
                        <span className={styles.errorText}> {file.error}</span>
                      </span>
                    ))}
                  </div>
                ) : null}

                <Label>Embed code</Label>
                <TextBox
                  type="text"
                  name="embedCode"
                  placeholder="Paste embed code"
                  formProps={{ ...formikprops, values }}
                  className={styles.textBox}
                />
              </>
            )}
            {rfiInstance ? (
              <div className={styles.inputWrapper}>
                {formRef?.current?.values?.embedCode && (
                  <div className={styles.validateButtonContainer}>
                    <Button
                      type="button"
                      btn={BtnType.FRAME_LESS}
                      onClick={(e) => handleAddEmbededCode(e, formikprops)}
                      className={styles.validateButton}
                    >
                      Validate
                    </Button>
                  </div>
                )}
                {unsavedFiles?.length
                  ? unsavedFiles.map((video, i) => (
                      <div className={styles.item} onClick={() => handleOpenLink(video)}>
                        <div className={styles.name}>{video}</div>
                        <div className={styles.delete}>
                          <Icon
                            {...mdSize}
                            icon="icn-button-delete"
                            onClick={(e) => handleOpenModal(e, i)}
                          />
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            ) : (
              <div className={styles.inputWrapper}>
                {formRef?.current?.values?.embedCode && (
                  <div className={styles.validateButtonContainer}>
                    <Button
                      type="button"
                      btn={BtnType.FRAME_LESS}
                      onClick={(e) => handleAddEmbededCode(e, formikprops)}
                      className={styles.validateButton}
                    >
                      Validate
                    </Button>
                  </div>
                )}
                {videos && videos.length
                  ? videos.map((video, i) =>
                      isDeleting === index ? (
                        <Loading />
                      ) : !video._destroy ? (
                        <div className={styles.item} onClick={() => handleOpenLink(video.uri)}>
                          <div className={styles.name}> {video.uri}</div>
                          <div className={styles.delete}>
                            <Icon
                              {...mdSize}
                              icon="icn-button-delete"
                              onClick={(e) => handleOpenModal(e, i)}
                            />
                          </div>
                        </div>
                      ) : null
                    )
                  : null}
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default NewVideosComponent;

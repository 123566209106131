import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "./overview.module.scss";
import UpdateCrunchBaseProfile from "./components/updateCrunchBaseProfile";
import { getClaimProfileOverview } from "../store/claimProfileActions";
import LoadingComponent from "../../primitives/loading/loading.component";
import CompanyCard from "../../companies/company/components/companyCard/companyCard.component";
import DetailsComponent from "../../companies/company/overview/details";
import AreaOfFocusComponent from "../../companies/company/overview/areaOfFocus";
import VideosComponent from "../../companies/company/overview/videos";
import FundingComponent from "../../companies/company/overview/funding";
import CompanyContactComponent from "../../companies/company/overview/companyContact";
import { httpGet } from "../../../common/httpCall";
import CompanyLeadershipComponent from "../../companies/company/overview/companyLeadership";
import TweetsComponent from "../../companies/company/overview/tweets";
import ClaimCouncilComponent from "../../council/claimCouncil.component";
import CompanyLogo from "./components/companyLogo";
import HighOrderClaimEdit from "./components/hocEditIcon";
import { useModelPopup } from "../../../common/hooks";
import EditIdeaAreaOfFocus from "./components/modals/areasOfFocus";
import CompanyDetailsEditModal from "./components/modals/companyDetails";
import AddEditVideo from "./components/modals/addEditVideos";
import { getCompanyContactsAttempt } from "../../companies/company/company.action";
import TotalFundingEditModal from "../../../common/components/totalFundingEdit";
import LeadershipEditModal from "../../../common/components/leadershipEdit";
import {
  getFundingItems,
  getLeaderShipItems,
} from "../../../common/actions/common.action";

const ClaimProfileOverView = () => {
  const [tweets, setTweets] = useState(null);
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const history = useHistory();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    claimProfileReducer: {
      claimReducer: {
        claimProfile: {
          profile, loading, leadership_members, funding_rounds,
        },
      },
    },
    companiesReducer: {
      companyReducer: { companyContacts, updateContactsCompany },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (
      selectedCouncil?.id
      && !loading
      && !profile
      && session?.invited_company?.id
    ) {
      dispatch(
        getClaimProfileOverview({
          id: session.invited_company.id,
          councilId: selectedCouncil.id,
          cb: (res) => {
            if (res && res?.last_rfi?.id) {
              // redirect
              history.push(`/info-requests/details?id=${res?.last_rfi?.id}`);
            }
          },
        }),
      );
    }
  }, [dispatch, selectedCouncil, loading, profile, session]);

  useEffect(() => {
    if (selectedCouncil?.id && session?.invited_company?.id) {
      dispatch(
        getCompanyContactsAttempt({
          companyId: session.invited_company.id,
        }),
      );
      getTweets(session.invited_company.id);
      dispatch(
        getLeaderShipItems({
          companyId: session.invited_company.id,
        }),
      );
      dispatch(
        getFundingItems({
          companyId: session.invited_company.id,
        }),
      );
    }
  }, [dispatch, selectedCouncil, loading, profile, session]);

  useEffect(() => {
    if (updateContactsCompany) {
      dispatch(
        getCompanyContactsAttempt({
          companyId: session.invited_company.id,
        }),
      );
    }
  }, [updateContactsCompany, session]);

  const getTweets = (companyId) => {
    const url = `companies/${companyId}/twitter_activities`;

    httpGet({
      call: url,
    })
      .pipe()
      .subscribe((res) => {
        setTweets(res.response);
      });
  };

  const handleCompanyInfoEdit = useCallback(() => {
    popup.show({
      show: true,
      title: "Edit Company Information",
      component: <CompanyDetailsEditModal profile={profile} />,
      width: "70%",
    });
  }, [profile]);

  const handleAreasEdit = useCallback(() => {
    popup.show({
      show: true,
      title: "Edit Areas of Focus",
      component: <EditIdeaAreaOfFocus profile={profile} />,
    });
  }, [profile]);

  const handleAddVideos = useCallback(() => {
    popup.show({
      show: true,
      title: "Edit Video",
      component: <AddEditVideo profile={profile} />,
      width: "80%",
    });
  }, [profile]);

  const handleTotalFundingEdit = useCallback(() => {
    popup.show({
      show: true,
      title: "Total Funding Edit",
      component: (
        <TotalFundingEditModal
          profile={profile}
          fundingRounds={funding_rounds}
        />
      ),
      width: "80%",
    });
  }, [profile, funding_rounds]);

  const handleLeadershipEdit = useCallback(() => {
    popup.show({
      show: true,
      title: "Company Leadership Edit",
      component: (
        <LeadershipEditModal profile={profile} members={leadership_members} />
      ),
      width: "80%",
    });
  }, [profile, leadership_members]);

  return (
    <section className={styles.claimProfileOverviewWrapper}>
      <div className="mb-4 d-flex">
        <ClaimCouncilComponent />
      </div>
      {!profile || loading || !selectedCouncil ? (
        <LoadingComponent customText="Loading..." />
      ) : (
        <>
          <UpdateCrunchBaseProfile session={session} profile={profile} />
          <div className="row m-0">
            <div className="col-12 col-md-8 flex-grow-1 p-0">
              <CompanyCard
                title="Company Information"
                rightContent={
                  <HighOrderClaimEdit handleClick={handleCompanyInfoEdit} />
                }
              >
                <DetailsComponent
                  company={profile}
                  selectedCouncil={selectedCouncil}
                  isClaimProfileUse
                />
              </CompanyCard>
              <CompanyCard
                title="Areas of Focus"
                className="mb-5 mt-5"
                rightContent={
                  <HighOrderClaimEdit handleClick={handleAreasEdit} />
                }
              >
                <AreaOfFocusComponent
                  selectedCouncil={selectedCouncil}
                  company={profile}
                  isClaimProfileUse
                />
              </CompanyCard>
              <CompanyCard title="Videos" className="mt-5">
                {profile?.promo_video
                && profile?.promo_video !== "[]"
                && profile?.promo_video[0] === "["
                && profile?.promo_video?.length ? (
                  <VideosComponent
                    company={profile}
                    videoJSON={profile?.promo_video}
                  />
                  ) : (
                    <div className={styles.noVideosWrapper}>
                      <span
                        className={styles.addVideos}
                        onClick={handleAddVideos}
                      >
                        Add Videos
                      </span>
                    </div>
                  )}
              </CompanyCard>
              {funding_rounds ? (
                <CompanyCard
                  title="Funding"
                  className="mt-5"
                  rightContent={
                    <HighOrderClaimEdit handleClick={handleTotalFundingEdit} />
                  }
                >
                  <FundingComponent
                    company={profile}
                    fundingRounds={funding_rounds}
                  />
                </CompanyCard>
              ) : null}
            </div>
            <div className="col-12 col-md-4">
              <CompanyCard title="Company Logo">
                <CompanyLogo
                  session={session}
                  selectedCouncil={selectedCouncil}
                />
              </CompanyCard>
              {companyContacts && (
                <CompanyCard
                  title={`Company Contact${
                    companyContacts?.length > 1 ? "s" : ""
                  }`}
                  className="mb-5 mt-5"
                >
                  <CompanyContactComponent
                    companyId={profile?.id}
                    companyContacts={companyContacts}
                    isClaimProfileUse
                  />
                </CompanyCard>
              )}
              <CompanyCard
                title="Company Leadership"
                className="mb-5"
                rightContent={
                  <HighOrderClaimEdit handleClick={handleLeadershipEdit} />
                }
              >
                <CompanyLeadershipComponent
                  company={profile}
                  members={leadership_members}
                />
              </CompanyCard>
              {tweets?.length ? (
                <CompanyCard title="Tweets">
                  <TweetsComponent company={profile} tweets={tweets} />
                </CompanyCard>
              ) : null}
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default React.memo(ClaimProfileOverView);

import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import classnames from "classnames";
import { Link } from "react-router-dom";

import styles from "./products.module.scss";
import { Button, BtnType, MoreOptions, Pagination } from "../../../primitives";
import { useModelPopup } from "../../../../common/hooks";
import LoadingComponent from "modules/primitives/loading/loading.component";
import { getCompanyProductDelete, getCompanyProducts } from "modules/companies/company/company.action";
import AddNewProduct from "modules/companies/company/products/addNewProduct";
import { ACTIVITY_OPTIONS } from "../../../../common/constants";
import UploadProductImages from "modules/companies/company/products/uploadImg/uploadImg.component";
import style from "modules/companies/companyMain/rightWindow/newCompaniesList/newCompaniesList.module.scss";
import { Icon, smSize } from "../../../../common/icon";

const Products = ({ company }) => {
  const {
    companiesReducer: {
      companyReducer: { products }
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();

  const handleCreateNewProducts = () => {
    popup.show({
      title: "Add New Product",
      component: (
        <AddNewProduct company={company} />
      ),
    });
  };

  useEffect(() => {
    if (company) {
      dispatch(
        getCompanyProducts({
          company_id: company.id,
          page: 1,
          enqueueSnackbar,
        }),
      );
    }
  }, [company]);

  const handleEditClick = (item) => {
    popup.show({
      title: "Edit Product",
      component: (
        <AddNewProduct company={company} data={item} />
      ),
    });
  }

  const handleDeleteClick = (item) => {
    dispatch(getCompanyProductDelete({
      company_id: company.id,
      product_id: item.id,
      enqueueSnackbar,
    }));
    enqueueSnackbar('Successfully deleted product', {
      variant: 'success',
    })
  }

  const handleMoreOptionClick = (value, item) => {
    if (value === 0) {
      handleEditClick(item);
    }

    if (value === 1) {
      handleDeleteClick(item);
    }
  }

  const goToProduct = (product) => {
    const link = product.product_link.startsWith("http://") || product.product_link.startsWith("https://")
      ? product.product_link
      : `http://${product.product_link}`;
    window.open(link, '_blank');
  }

  const handlePageChange = (page) => {
    dispatch(
      getCompanyProducts({
        company_id: company.id,
        page,
        enqueueSnackbar,
      }),
    );
  }

  const drawSortingIcon = useCallback(
    (col) => {
      if (!products?.sort_order?.length) return null;

      if (products?.sort_attribute === col) {
        return (
          <>
            <div
              className={classnames(
                style.sortIcon,
                products?.sort_order === "desc" ? style.sortAsc : style.sortDesc,
              )}
            >
              <Icon {...smSize} icon="icn-export-button" />
            </div>
          </>
        );
      }
    },
    [products.sort_order, products.sort_attribute]
  );

  const makeSortingConfig = useCallback(
    (sort_attribute) => {
      let sort_order;

      if (products.sort_attribute === sort_attribute) {
        if (products.sort_order === "desc") {
          sort_order = 'asc';
        } else {
          sort_order = null;
        }
      } else {
        sort_order = 'desc';
      }

      dispatch(
        getCompanyProducts({
          sort_attribute,
          sort_order,
          company_id: company.id,
          page: products.pagination.page || 1,
          enqueueSnackbar,
        }),
      );
    },
    [products.sort_order, products.sort_attribute, products.pagination, company]
  );

  return (
    <section>
      <div className={styles.contentWrp}>
        <div className={styles.headerWrp}>
          <span className={styles.headerText}>
            Products
          </span>
          <Button
            className={styles.newTask}
            btn={BtnType.FRAME_LESS}
            icon="icn-add"
            onClick={handleCreateNewProducts}
          >
            New Product
          </Button>
        </div>
        <div className={styles.container}>
          {
            products.loading ? (
              <LoadingComponent customText="Preparing products..." />
            ) : (
              <div className={styles.grid}>
                {
                  products.data.length ? (
                    <>
                      <div className={styles.gridHeader}>
                        <span
                          className={`${styles.company} position-relative cursor-pointer`}
                          onClick={() => makeSortingConfig("ProductName")}
                        >
                          Product Name
                          {drawSortingIcon("ProductName")}
                        </span>
                        <span className={styles.productLink}>Product Link</span>
                        <span className={styles.description}>Description</span>
                      </div>
                      <div className={styles.gridBody}>
                        {
                          products.data.map(p => (
                            <div key={p.id} className={styles.gridRow}>
                              <div className={classnames(styles.company, 'font-weight-bold')}>
                                <Link to={`./products/${p.slug || p.id}`}>{p.name}</Link>
                              </div>
                              <div className={styles.productLink}>
                                {
                                  p.product_link?.length ? (
                                    <span
                                      className="d-block"
                                      onClick={() => goToProduct(p)}
                                    >
                                      {p.product_link}
                                    </span>
                                  ) : '-'
                                }
                              </div>
                              <div className={styles.description}>{p.description || '-'}</div>
                              <div className={styles.images}>
                                <UploadProductImages
                                  uploadDirName={`companies/${company.id}/products/${p.id}/`}
                                  company={company}
                                  product={p}
                                  instance="table"
                                />
                              </div>
                              <div className={styles.productOptions}>
                                <MoreOptions
                                  options={ACTIVITY_OPTIONS}
                                  onClick={(evt) => handleMoreOptionClick(evt, p)}
                                  customSvgIcon
                                />
                              </div>
                            </div>
                          ))
                        }
                      </div>
                      {
                        products?.pagination && (
                          <div className="d-flex justify-content-center mt-3 mb-4">
                            <Pagination {...products.pagination} onChange={handlePageChange} />
                          </div>
                        )
                      }
                    </>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center mb-5">
                      Add a Product
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    </section>
  );
};

export default Products;

import {
  SOLUTIONS_GET_ATTEMPT,
  SOLUTIONS_GET_SUCCESS,
  SOLUTION_ADD_ATTEMPT,
  SOLUTION_ADD_SUCCESS,
  SOLUTION_DELETE_ATTEMPT,
  SOLUTION_DELETE_SUCCESS,
  SOLUTIONS_SEARCH_SUCCESS,
  SOLUTIONS_SEARCH_ATTEMPT,
  SOLUTION_EDIT_ATTEMPT,
  SOLUTION_EDIT_SUCCESS,
  SOLUTION_PROJECT_DELETE_ATTEMPT,
  SOLUTION_PROJECT_DELETE_SUCCESS,
  SOLUTIONS_STATS_GET_SUCCESS,
  SOLUTION_BY_ID_GET_ATTEMPT,
  SOLUTION_BY_ID_GET_SUCCESS,
  CLEAR_SELECTED_SOLUTION_PROJECT_ATTEMPT,
} from "./solutionsMain.actions";

import { apiStatus, pageMeta } from "../../../common/httpCall";

const initState = {
  solutionsLite: [],
  solutionsExtended: [],
  solutionMeta: {},
  loadingSolutions: false,
  selectedSolutionProjects: [],
  loadingSolutionsProjects: false,
  solutionsStats: {},
  selectedSolution: {},
  loadingSolutionById: false,
};

const solutionsMainReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SOLUTIONS_GET_ATTEMPT:
      return {
        ...state,
        loadingSolutions: true,
      };
    case SOLUTIONS_GET_SUCCESS:
      return {
        ...state,
        loadingSolutions: false,
        solutionsExtended: payload.response,
        solutionMeta: pageMeta(payload),
      };

    case SOLUTION_ADD_ATTEMPT:
      return {
        ...state,
        loadingSolutions: true,
      };
    case SOLUTION_ADD_SUCCESS: {
      const { response, status } = payload;

      if ([200, 201].includes(status) && response) {
        return {
          ...state,
          loadingSolutions: false,
          solutionsExtended: [response, ...state.solutionsExtended],
        };
      }
      return state;
    }

    case SOLUTION_EDIT_ATTEMPT:
      return {
        ...state,
        loadingSolutions: true,
      };
    case SOLUTION_EDIT_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          loadingSolutions: false,
          solutionsExtended: state.solutionsExtended.map((sol) => {
            if (sol.id === payload.response.id) {
              return payload.response;
            }
            return sol;
          }),
        };
      }
      return state;
    }

    case SOLUTION_DELETE_ATTEMPT:
      return {
        ...state,
        loadingSolutions: true,
      };

    case SOLUTION_DELETE_SUCCESS: {
      return {
        ...state,
        loadingSolutions: false,
        solutionsExtended: state.solutionsExtended
          .filter((sol) => sol)
          .map((sol) => {
            if (sol?.id === payload) {
              return null;
            }
            return sol;
          }),
      };
    }

    case SOLUTIONS_SEARCH_ATTEMPT:
      return {
        ...state,
        loadingSolutions: true,
      };
    case SOLUTIONS_SEARCH_SUCCESS:
      return {
        ...state,
        loadingSolutions: false,
        solutionsExtended: payload.response,
        solutionMeta: pageMeta(payload),
      };
    case SOLUTION_PROJECT_DELETE_ATTEMPT:
      return {
        ...state,
        loadingSolutionsProjects: true,
      };
    case SOLUTION_PROJECT_DELETE_SUCCESS: {
      const { projectIdToDelete, response } = payload;
      const prepareProjects = () => {
        if (projectIdToDelete) {
          return state.selectedSolutionProjects.filter((proj) => {
            if (proj.project_id === projectIdToDelete) {
              return false;
            }
            return true;
          });
        }
        return response;
      };
      return {
        ...state,
        loadingSolutionsProjects: false,
        selectedSolutionProjects: prepareProjects(),
        // solutionMeta: pageMeta(payload),
      };
    }
    case CLEAR_SELECTED_SOLUTION_PROJECT_ATTEMPT:
      return {
        ...state,
        selectedSolutionProjects: [],
      };

    case SOLUTIONS_STATS_GET_SUCCESS: {
      return {
        ...state,
        solutionsStats: payload.response,
      };
    }

    case SOLUTION_BY_ID_GET_ATTEMPT:
      return {
        ...state,
        loadingSolutionById: true,
        selectedSolution: {},
      };
    case SOLUTION_BY_ID_GET_SUCCESS: {
      return {
        ...state,
        loadingSolutionById: false,

        selectedSolution: payload.response,
      };
    }

    default:
      return state;
  }
};

export default solutionsMainReducer;

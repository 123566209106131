import React from "react";

import styles from "./activity.module.scss";

const DesktopActivityRow = ({ activity }) => (
  <div className={styles.gridRow}>
    <div className={styles.title}>{activity.title}</div>
    <div className={styles.description}>{activity.details}</div>
    <div className={styles.date}>{activity.created_at}</div>
  </div>
);

export default DesktopActivityRow;

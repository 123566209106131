export const formInitialValues = {
  description: null,
  web_url: null,
  contact_email: null,
  revenue_range: null,
  location: null,
  location_city: null,
  location_region: null,
  location_country: null,
  location_continent: null,
  num_employees_enum: null,
  founded: null,
  twitter: null,
  cb_profile_link: null,
};

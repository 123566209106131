import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import { httpPost } from "common/httpCall";
import { useMsal } from "@azure/msal-react";
import classnames from "classnames";
import { getEmails } from "api/microsoft/graphApi/outlookOffice";

import { checkViewerRole } from "common/checkers/viewerChecker";
import { appModelSettingSet } from "application/app.action";
import { getCookie } from "common/helper";
import styles from "./activity.module.scss";
import {
  Button,
  BtnType,
  Loading,
  pageSettings,
  Pagination,
} from "../../../primitives";
import { companyGetActivities, companyDeleteActivity } from "../company.action";
import { peopleListTeamsGet } from "../../../../common/actions/common.action";
import { useModelPopup } from "../../../../common/hooks";
import CallComponent from "./call";
import EmailComponent from "./email";
import EventComponent from "./event";
import MeetingComponent from "./meeting";
import NoteComponent from "./note";
import ProjectComponent from "./project";
import NewEventComponent from "./popups";
import { useWindowSize } from "../../../../common/hooks/useWindowSize";

const ActivityComponent = ({ companyId, orgType, session, selectedCouncil }) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const {
    appReducer: { modalSetting },
    companiesReducer: {
      companyReducer: {
        selectedCompany,
        companyActivities,
        activityLoading,
        activityAdded,
        activitiesPageNr,
      },
    },
    peopleReducer: {
      internalPeopleReducer: { teamList },
    },
  } = useSelector((state) => state);
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [msftEmails, setMsftEmails] = useState([]);
  const { instance, accounts } = useMsal();
  const [newEmails, setNewEmails] = useState(false);
  const [pageSetting, setPageSetting] = useState(pageSettings);
  const [selectedPage, setSelectedPage] = useState(1);

  const microsoftLogin =
    JSON.parse(localStorage.getItem("microsoft_login") || false) || false;
  const [emailsSaved, setEmailsSaved] = useState();

  const [currentCompany, setCurrentCompany] = useState({});
  const council_role = getCookie("council_role_");

  useEffect(() => {
    if (selectedCompany) setCurrentCompany(selectedCompany);
  }, [selectedCompany]);

  useEffect(() => {
    if (microsoftLogin && Object.keys(currentCompany)?.length > 0)
      fetchEmails(instance, accounts);
  }, [currentCompany]);

  const fetchEmails = useCallback(async () => {
    const emailDataMsft = await getEmails(
      instance,
      accounts,
      currentCompany.domain,
      false,
      session?.users_integrations
    );

    setMsftEmails(emailDataMsft?.value);
  }, [instance, accounts, msftEmails, currentCompany]);

  useEffect(() => {
    if (msftEmails?.length > 0) {
      httpPost({
        call: "custom_activities/check_msft_emails",
        data: {
          msft_emails: msftEmails,
          company_id: selectedCompany.id,
          creator_id: session.id,
          council_id: selectedCouncil.id,
        },
      })
        .pipe()
        .subscribe((res) => setNewEmails(res.response));
    }
  }, [msftEmails]);

  const postEmails = () => {
    if (msftEmails?.length > 0) {
      httpPost({
        call: "custom_activities/create_msft_emails",
        data: {
          msft_emails: msftEmails,
          company_id: selectedCompany.id,
          creator_id: session.id,
          council_id: selectedCouncil.id,
        },
      })
        .pipe()
        .subscribe((res) => setEmailsSaved(res?.response));
    } else alert("There are no emails between you and this company.");
  };

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    setPageSetting(activitiesPageNr);
  }, [activitiesPageNr?.total]);

  const hasAccess = (el) =>
    council_role === "ttp_administrator" ||
    council_role === "council_administrator" ||
    session.id === el.creator_id;

  const handleAddClick = () => {
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        title: "Capture New Activity",
        show: true,
        height: isMobile ? null : String(window.innerHeight),
        width: isMobile ? null : String(window.innerWidth / 1.5),
        component: (
          <NewEventComponent
            company={selectedCompany}
            companyId={companyId}
            orgType={orgType}
          />
        ),
      })
    );
  };

  const handleEditClick = (item) => {
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        title: "Edit",
        show: true,
        height: isMobile ? null : String(window.innerHeight),
        width: isMobile ? null : String(window.innerWidth / 1.5),
        component: (
          <NewEventComponent
            company={selectedCompany}
            data={item}
            companyId={companyId}
            orgType={orgType}
            handleDeleteClick={handleDeleteClick}
            type={item?.type}
          />
        ),
      })
    );
  };

  const handleDeleteClick = (initObject) => {
    const resp = window.confirm(
      "You are about to delete this item and its data permanently. Are you sure? "
    );
    if (resp) {
      dispatch(
        companyDeleteActivity({
          company_id: companyId,
          custom_activity_id: initObject.custom_activity_id,
          gsk_patient_organization_id: companyId,
          gsk: selectedCouncil.traction_tag === "gsk",
          patient_org: orgType === "patient",
          data: {},
          enqueueSnackbar,
        })
      );
      popup.hide();
    }
  };

  const getActivities = useCallback((page) => {
    if (activityLoading || !selectedCouncil?.id || !session?.id || !companyId) return;

    dispatch(
      companyGetActivities({
        company_id: companyId,
        gsk_patient_organization_id: companyId,
        gsk: selectedCouncil.traction_tag === "gsk",
        patient_org: orgType === "patient",
        userId: session.id,
        items: 50,
        page,
        enqueueSnackbar,
      })
    );
  }, [selectedCouncil, companyId, session, activityLoading]);

  React.useEffect(() => {
    dispatch(
      peopleListTeamsGet({
        councilId: selectedCouncil.id,
        enqueueSnackbar,
      })
    );
  }, []);

  React.useEffect(() => {
    if (companyId) {
      getActivities(1);
    }
  }, [companyId]);

  React.useEffect(() => {
    if (activityAdded) {
      getActivities(1);
    }
  }, [activityAdded]);

  const handlePagingChange = (page) => {
    setSelectedPage(page);
    setPageSetting((prev) => ({
      ...prev,
      current: page,
    }));
    getActivities(page);
  };

  if (activityLoading) {
    return <Loading />;
  }

  return (
    <section className={styles.wrapper}>
      <div className={classnames(styles.row, "row")}>
        <div className="col-md-2" />
        <div className="col-md-8">
          <section className={styles.activityItemsWrapper}>
            <div className={styles.titleWrp}>
              <h4>Notes</h4>
              {!blockFunctionality ? (
                <div className="d-flex justify-content-end align-items-center">
                  {newEmails && msftEmails?.length > 0 && microsoftLogin && (
                    <Button
                      btn={BtnType.FRAME_LESS}
                      className="mr-3"
                      onClick={postEmails}
                    >
                      Fetch Emails
                    </Button>
                  )}
                  <Button
                    btn={BtnType.FRAME_LESS}
                    icon="icn-add"
                    onClick={handleAddClick}
                  >
                    {orgType === "patient"
                      ? "LOG AN ENGAGEMENT / PROJECT"
                      : "Add Note"}
                  </Button>
                </div>
              ) : (
                <Tooltip
                  title="You have View-Only access. To Add a Note, please ask your Admin to upgrade your account to Standard access."
                  placement="top"
                >
                  <div className={styles.button}>
                    <Button
                      btn={BtnType.DISABLED}
                      className={styles.button}
                      icon="icn-add"
                    >
                      {orgType === "patient"
                        ? "LOG AN ENGAGEMENT / PROJECT"
                        : "Add Note"}
                    </Button>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={styles.listWrp}>
              {companyActivities.length === 0 && (
                <div className="text-center p-5">Add a Note</div>
              )}
              {companyActivities.map((el, i) => {
                const isLastElem = companyActivities.length === i + 1;
                const isAddedByMe = session?.id === el?.creator_id;

                if (el.type === "Note") {
                  return (
                    <NoteComponent
                      isMobile={isMobile}
                      teamList={teamList}
                      key={el.custom_activity_id}
                      note={el}
                      orgType={orgType}
                      hasAccess={hasAccess(el)}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                      blockFunctionality={blockFunctionality}
                      isAddedByMe={isAddedByMe}
                      isLastElem={isLastElem}
                    />
                  );
                }
                if (el.type === "Call") {
                  return (
                    <CallComponent
                      isMobile={isMobile}
                      teamList={teamList}
                      key={el.custom_activity_id}
                      call={el}
                      hasAccess={hasAccess(el)}
                      orgType={orgType}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                      blockFunctionality={blockFunctionality}
                      isAddedByMe={isAddedByMe}
                      isLastElem={isLastElem}
                    />
                  );
                }
                if (el.type === "Event") {
                  return (
                    <EventComponent
                      isMobile={isMobile}
                      orgType={orgType}
                      teamList={teamList}
                      key={el.custom_activity_id}
                      event={el}
                      hasAccess={hasAccess(el)}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                      blockFunctionality={blockFunctionality}
                      isAddedByMe={isAddedByMe}
                      isLastElem={isLastElem}
                    />
                  );
                }
                if (el.type === "Meeting") {
                  return (
                    <MeetingComponent
                      isMobile={isMobile}
                      orgType={orgType}
                      teamList={teamList}
                      key={el.custom_activity_id}
                      meeting={el}
                      hasAccess={hasAccess(el)}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                      blockFunctionality={blockFunctionality}
                      isAddedByMe={isAddedByMe}
                      isLastElem={isLastElem}
                    />
                  );
                }
                if (el.type === "Email") {
                  return (
                    <EmailComponent
                      isMobile={isMobile}
                      orgType={orgType}
                      teamList={teamList}
                      key={el.custom_activity_id}
                      email={el}
                      hasAccess={hasAccess(el)}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                      blockFunctionality={blockFunctionality}
                      isAddedByMe={isAddedByMe}
                      isLastElem={isLastElem}
                    />
                  );
                }

                if (el.type === "Project") {
                  return (
                    <ProjectComponent
                      isMobile={isMobile}
                      teamList={teamList}
                      key={el.custom_activity_id}
                      note={el}
                      orgType={orgType}
                      hasAccess={hasAccess(el)}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                      blockFunctionality={blockFunctionality}
                      isAddedByMe={isAddedByMe}
                      isLastElem={isLastElem}
                    />
                  );
                }

                return <div key={el.custom_activity_id} />;
              })}
            </div>
          </section>
        </div>
        <div className="col-md-2" />
        <div className={styles.paginationCon}>
          <Pagination {...pageSetting} onChange={handlePagingChange} />
        </div>
      </div>
    </section>
  );
};

export default React.memo(ActivityComponent);

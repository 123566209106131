import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import styles from "./leaderboard.module.scss";
import { httpPost } from "../../../common/httpCall";
import TopSavingsTableComponent from "./topSavingsTable.component";
import TrandingTechTableComponent from "./trendingTechTable.component";
import HighestRatingTableComponent from "./highestRatingTable.component";
import TopProjectsTableComponent from "./topProjectsTable.component";

const Leaderboard = ({ enqueueSnackbar }) => {
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const [topProjects, setTopProjects] = useState([]);
  const [trendProjLoading, setTrendProjLoading] = useState(false);

  const [topProjectsSort, setTopProjectsSort] = useState({
    sort_attribute: "project_overall_score",
    sort_order: "desc",
  });
  const prepareDataName = (name) => {
    if (name?.length > 12) {
      return (
        <Tooltip placement="bottom" title={`${name}`}>
          <span>{name.slice(0, 12) + " ... "}</span>
        </Tooltip>
      );
    }

    return <span>{name}</span>;
  };

  const prepareLongRow = (tags) => {
    return (
      <Tooltip
        placement="bottom"
        title={`${tags.map((tag) => tag.name).join(", ")}`}
      >
        <span className={styles.moreTagslink}>{" More "}</span>
      </Tooltip>
    );
  };
  const getSortStyles = (sortAttr, table, sortAttrStateValue) => {
    if (table === "topProjects" && sortAttrStateValue === sortAttr) {
      return `${styles.selectedSort}`;
    }
    if (table === "topTrendTechSort" && sortAttrStateValue === sortAttr) {
      return `${styles.selectedSort}`;
    }
    if (table === "topSavings" && sortAttrStateValue === sortAttr) {
      return `${styles.selectedSort}`;
    }
    if (table === "trendProj" && sortAttrStateValue === sortAttr) {
      return `${styles.selectedSort}`;
    }

    return ``;
  };

  const getSortDirectionStytles = (
    sortAttr,
    table,
    sortAttrStateValue,
    sortDirStateValue
  ) => {
    if (table === "topProjects" && sortAttrStateValue === sortAttr) {
      return sortDirStateValue === "asc"
        ? `${styles.selectedAscSort}`
        : `${styles.selectedDescSort}`;
    }
    if (table === "topTrendTechSort" && sortAttrStateValue === sortAttr) {
      return sortDirStateValue === "asc"
        ? `${styles.selectedAscSort}`
        : `${styles.selectedDescSort}`;
    }
    if (table === "topSavings" && sortAttrStateValue === sortAttr) {
      return sortDirStateValue === "asc"
        ? `${styles.selectedAscSort}`
        : `${styles.selectedDescSort}`;
    }
    if (table === "trendProj" && sortAttrStateValue === sortAttr) {
      return sortDirStateValue === "asc"
        ? `${styles.selectedAscSort}`
        : `${styles.selectedDescSort}`;
    }

    return ``;
  };

  const getTopProj = () => {
    setTrendProjLoading(true);
    try {
      httpPost({
        call: "ford/leaderboards/top_projects",
        apiVersion: "v2",
        data: {
          sort_attribute: topProjectsSort?.sort_attribute,
          sort_order: topProjectsSort?.sort_order,
        },
      }).subscribe(
        async (res) => {
          setTopProjects(res.response);
          setTrendProjLoading(false);
        },
        (err) => {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
          setTrendProjLoading(false);
        }
      );
    } catch (error) {
      console.log("---------error", error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
      setTrendProjLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCouncil) {
      getTopProj({
        id: selectedCouncil,
        enqueueSnackbar,
      });
    }
  }, [selectedCouncil]);

  // const getStats = () => {
  //   setLoading(true);
  //   try {
  //     httpGet({
  //       call: "ford/leaderboards/trending_projects",
  //       apiVersion: "v1",
  //     }).subscribe(
  //       async (res) => {
  //         setProjectTranding(res.response);
  //         setLoading(false);
  //       },
  //       () => {
  //         console.log("---------Something went wrong in trending_projects");
  //         setLoading(false);
  //       }
  //     );
  //   } catch (error) {
  //     console.log("---------error", error);
  //     enqueueSnackbar("Something went wrong", {
  //       variant: "error",
  //     });
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (selectedCouncil) {
  //     getStats({
  //       id: selectedCouncil,
  //       enqueueSnackbar,
  //     });
  //   }
  // }, [selectedCouncil]);

  return (
    <div className={styles.leaderBoardWrapper}>
      <div className={styles.leaderBoardTitle}>
        {" "}
        <h4>Innovation Leaderboards</h4>
      </div>
      <div className={`row`}>
        <TopProjectsTableComponent
          selectedCouncil={selectedCouncil}
          getSortStyles={getSortStyles}
          getSortDirectionStytles={getSortDirectionStytles}
          prepareDataName={prepareDataName}
          prepareLongRow={prepareLongRow}
          enqueueSnackbar={enqueueSnackbar}
          topProjects={topProjects}
          trendProjLoading={trendProjLoading}
          setTopProjects={setTopProjects}
          setTopProjectsSort={setTopProjectsSort}
        />

        <TrandingTechTableComponent
          selectedCouncil={selectedCouncil}
          getSortStyles={getSortStyles}
          getSortDirectionStytles={getSortDirectionStytles}
          prepareDataName={prepareDataName}
        />
      </div>
      {/*  */}
      <div className={`row`}>
        <TopSavingsTableComponent
          selectedCouncil={selectedCouncil}
          getSortStyles={getSortStyles}
          getSortDirectionStytles={getSortDirectionStytles}
          prepareDataName={prepareDataName}
        />
        <HighestRatingTableComponent
          selectedCouncil={selectedCouncil}
          getSortStyles={getSortStyles}
          getSortDirectionStytles={getSortDirectionStytles}
          prepareDataName={prepareDataName}
          topProjectsByRating={topProjects}
          trendProjLoading={trendProjLoading}
          // setTopProjects={setTopProjects}
          // setTopProjectsSort={setTopProjectsSort}
        />
      </div>
    </div>
  );
};

export default React.memo(withSnackbar(Leaderboard));

import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "./taskAllInfo.module.scss";
import { Button, BtnType } from "../../../../primitives";
import { StageRow } from "../stageRow";
import { appSliderSettingSet } from "application/app.action";

const TaskAllInfoComponent = (props) => {
  const { company, patientOrg } = props;

  const {
    projectsReducer: {
      stagesReducer: { stagesList },
    },
  } = useSelector((state) => state);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleViewCompanyClick = () => {
    if (company) {
      let url;

      if (patientOrg) {
        url = `/patient_organizations/${company?.slug || company?.id}`;
      } else {
        url = `/companies/${company?.slug || company?.id}`;
      }

      if (company.is_product) {
        url = `/companies/${company.company.slug}/products/${company.slug}`;
      }

      dispatch(appSliderSettingSet({ show: false }));

      history.push(url);
    }
  };

  const btnName = useMemo(() => {
    if (company.is_product) {
      return "View Product";
    }

    return patientOrg ? "View Organization" : "View Company";
  }, [company, patientOrg]);

  return (
    <div className={styles.taskInfoWrp}>
      <div className={styles.companyWrp}>
        <h4 className={styles.title}>Project Tasks</h4>
        <div className={styles.viewCompany}>
          <Button btn={BtnType.FRAME_LESS} onClick={handleViewCompanyClick}>
            {btnName}
          </Button>
        </div>
      </div>
      <div className={styles.stagesWrp}>
        {stagesList.map((stage) => (
          <StageRow
            patientOrg={patientOrg}
            key={stage.id}
            stage={stage}
            company={company}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(TaskAllInfoComponent);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import classnames from "classnames";

import { projectSet } from "modules/projects/project/project.action";
import { projectLastActivityGet } from "modules/projects/projectsMain/projectsMain.action";
import styles from "./projectRow.module.scss";
import { ProgressBar, ProjectState } from "../../../primitives";

const ProjectRowComponent = (props) => {
  const { project, userId, homePageUse } = props;
  const dispatch = useDispatch();
  const {
    councilReducer: { selectedCouncil },
    projectsReducer: {
      projectsMainReducer: { lastActivity },
    },
  } = useSelector((state) => state);
  const [projectLastActivity, setProjectLastActivity] = useState("-");
  const { enqueueSnackbar } = useSnackbar();

  const isFord = selectedCouncil?.traction_tag === "ford";

  useEffect(() => {
    dispatch(projectLastActivityGet({ id: project.id, enqueueSnackbar }));
  }, []);

  useEffect(() => {
    if (lastActivity[project.id]) {
      if (lastActivity[project.id].details) {
        setProjectLastActivity(lastActivity[project.id].details);
      }
    }
  }, [lastActivity]);

  return (
    <Link to={`/projects/${project.id}`}>
      <div className={styles.gridRow}>
        <div className={styles.project}>
          {project.name}
        </div>
        <div
          className={classnames(styles.activity, isFord && styles.fordActivity)}
        >
          {projectLastActivity}
        </div>
        <div className={classnames(styles.myTask, isFord && styles.col)}>
          <ProjectState state={project.status} />
        </div>
        <div
          className={classnames(styles.progress, isFord && styles.fordProgress)}
        >
          <ProgressBar
            width="100%"
            tot={100}
            fill={project.total_task_progress}
            showTot={false}
            showPrecentage
            homePageUse={homePageUse}
          />
        </div>
        <div className={classnames(styles.rolle, isFord && styles.col)}>
          {project.project_members?.map((el) => {
            if (el?.user?.id === userId) {
              return (
                <span key={el.user.id}>
                  {el.user_role &&
                    el.user_role[0].toUpperCase() + el.user_role.slice(1)}
                </span>
              );
            }
            return null;
          })}
        </div>
        {isFord && (
          <>
            <div className={styles.col}>{project?.plant?.name}</div>
            <div className={styles.col}>
              {project.areas?.map((area) => (
                <span>{area.name}</span>
              ))}
            </div>
            <div className={styles.col}>
              {project.themes?.map((theme) => (
                <span>{theme.name}</span>
              ))}
            </div>
            <div className={styles.fordRating}>
              {project.total_rating || "-"}
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

export default ProjectRowComponent;

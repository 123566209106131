import React from "react";
import Select from "react-dropdown-select";
import {
  Label,
  DatePiker,
  TextBox,
  DropSelect,
  UserInline,
  UserInlineTypes,
  CheckBox,
  CheckType,
  EmailSelect,
} from "../../../../../primitives";
import { initModel } from "../constant";
import styles from "../styles.module.scss";
import { Icon } from "../../../../../../common/icon";
import CompanyContactMultiSelectSearch from "../../../../../../common/components/companyContactsSearch";
import { gskDiscussionTopics } from "../../activity.constant";
import RichEditor from "../../../../../../common/components/richEditor";

const NewCall = (props) => {
  const {
    selectedModel,
    orgType,
    activityType,
    data,
    formikprops,
    values,
    handleChange,
    setAddMoreFields,
    addMoreFields,
    setOtherAttendees,
    otherAttendees,
    tempAttendees,
    microsoftLogin,
    msftEmails,
    selectedEmail,
    setEmailData,
    setFieldValue,
    setSelectedEmail,
    setFilterContact,
    filterContact,
    emailData,
    selectedCategories,
    handlePatientOrgDiscussionSelect,
    patientOrgDiscussionSubTopics,
    selectedSubCategories,
    handleSubcategoryTopicSelect,
    initialValues,
    model,
    handleDateSelect,
    deleteAttendee,
    company,
    selectedProj,
    setSelectedProj,
    handleClear,
    handleVisibilityChange,
    optionVisibility,
    visibility,
    setSelectedTeamOption,
    selectedTeamOption,
    userTeams,
  } = props;

  return (
    <>
      {selectedModel.name && orgType === "patient" && (
        <>
          <Label>Call Subject</Label>
          <TextBox
            type="text"
            name="name"
            placeholder="Call Subject"
            className={styles.textBox}
            formProps={{ ...formikprops, values }}
            onChange={handleChange}
          />
        </>
      )}
      {data && data.attendees && data.attendees[0] && (
        <>
          <div className={styles.attendeesWrp}>
            <div className={styles.listWrp}>
              {tempAttendees.map((attendee) => {
                const {
                  first_name: first,
                  last_name: last,
                  user_id: id,
                  avatar,
                } = attendee;
                return (
                  <div key={id} className={styles.itemWrp}>
                    <UserInline
                      className={styles.userIcon}
                      classObj={{ name: styles.userName }}
                      userId={id}
                      type={UserInlineTypes.WITH_NAME}
                      showMine={false}
                      first={first}
                      last={last}
                      avatar={avatar}
                      local
                      dontRequest
                    />
                    <Icon
                      size={13}
                      icon="icn-button-delete"
                      className={styles.deleteIcon}
                      onClick={() => deleteAttendee(attendee)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      {selectedModel.name && orgType !== "patient" && (
        <>
          {microsoftLogin &&
            activityType === "Email" &&
            msftEmails?.length > 0 && (
              <EmailSelect
                title="Select Email: "
                value={selectedEmail}
                onDataChange={(e) => setEmailData(e)}
                formProps={{ ...formikprops, values, setFieldValue }}
                onChange={(e) => setSelectedEmail(e)}
                options={msftEmails}
                otherAttendees={otherAttendees}
                setFilterContact={setFilterContact}
              />
            )}
          {selectedModel.name && (
            <>
              <Label>
                {orgType === "patient"
                  ? "Role of Patient Organization Representative(s)"
                  : selectedModel.name.label}
              </Label>
              {setAddMoreFields(true)}
              {addMoreFields && (
                <div className={styles.otherFieldsPadding}>
                  <CompanyContactMultiSelectSearch
                    placeholder={
                      orgType === "patient"
                        ? "Add Patient Organization Contact"
                        : "Add contact"
                    } // Add Call, Email GSK
                    onChange={setOtherAttendees}
                    otherAttendees={otherAttendees}
                    tempAttendees={tempAttendees}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
      {orgType === "patient" && selectedCategories ? (
        <div>
          <Label>Discussion Topic</Label>
          <Select
            multi
            closeOnSelect
            values={[...selectedCategories]}
            className={styles.customPatientOrgMultiselect}
            onChange={(vals) =>
              handlePatientOrgDiscussionSelect(vals, setFieldValue)
            }
            options={gskDiscussionTopics}
            labelField="name"
            valueField="name"
            placeholder="Select Discussion Topic Category"
          />
        </div>
      ) : null}
      {orgType === "patient" &&
      patientOrgDiscussionSubTopics?.length &&
      selectedSubCategories ? (
        <div>
          <Select
            multi
            closeOnSelect
            values={selectedSubCategories}
            name="discussion_topic"
            className={styles.customPatientOrgMultiselect}
            onChange={handleSubcategoryTopicSelect}
            options={patientOrgDiscussionSubTopics}
            labelField="name"
            valueField="name"
            placeholder="Select Discussion Topic"
            formProps={{ ...formikprops, values }}
          />
        </div>
      ) : null}
      {orgType === "patient" &&
      selectedCategories &&
      selectedCategories?.some((c) => c.name === "Other") ? (
        <TextBox
          type="text"
          name="discussion_topic_other"
          placeholder="Enter Discussion Topic"
          className={styles.textBox}
          formProps={{ ...formikprops, values }}
          onChange={handleChange}
        />
      ) : null}
      {orgType === "patient" && (
        <div className="mb-4">
          <Label>Summary</Label>
          <RichEditor
            updateValue={(val) => setFieldValue("spoke_about", val)}
            initialValue={values.spoke_about || ""}
            placeholder="Summary"
          />
        </div>
      )}
      {orgType === "patient" && (
        <div>
          <Label>Initial Engagement</Label>
          <CheckBox
            checkType={CheckType.BLUE}
            isChecked={data ? data.initial_introductory_meeting : false}
            onChange={(val) => {
              setFieldValue("initial_introductory_meeting", val);
            }}
          />
        </div>
      )}

      {orgType === "patient" && (
        <>
          <Label>Role of Patient Organization Representative(s)</Label>
          <TextBox
            type="text"
            name="roles_of_attendees"
            placeholder="Role of Patient Organization Representative(s)"
            className={styles.textBox}
            formProps={{ ...formikprops, values }}
            onChange={handleChange}
          />
        </>
      )}

      {orgType !== "patient" && selectedModel.spoke_about && (
        <div className="mb-4">
          <Label>{selectedModel.spoke_about.label}</Label>
          <RichEditor
            updateValue={(val) => setFieldValue("spoke_about", val)}
            initialValue={values.spoke_about || ""}
            placeholder={selectedModel.spoke_about.placeholder}
          />
        </div>
      )}
      {company && company?.projects?.length > 0 && company?.projects[0] ? (
        <>
          <Label>Related Projects (optional)</Label>
          <DropSelect
            SelecedValues={selectedProj}
            onChange={(proj) => {
              setSelectedProj(proj.val);
            }}
            placeholder="Select a project or type to search"
            labelField="text"
            valueField="value"
            searchBy="text"
            options={company?.projects.map((el) => ({
              text: el.name,
              value: el.project_id,
            }))}
          />
          <span
            className={styles.clearButton}
            onClick={() => {
              handleClear();
            }}
          >
            <span> clear</span>
          </span>
        </>
      ) : null}
      {orgType !== "patient" ? (
        <>
          <Label>Visiblity</Label>
          <DropSelect
            SelecedValues={data ? data.visibility : initModel.visibility}
            onChange={handleVisibilityChange}
            placeholder="Please select who can see this note"
            name="for_all"
            labelField="text"
            valueField="value"
            searchBy="text"
            options={optionVisibility}
          />
        </>
      ) : null}
      {visibility === "for_teams" && (
        <>
          <Label>Select a team</Label>
          <Select
            name="team_ids"
            onChange={(selected) => {
              setFieldValue(
                "team_ids",
                selected.map(({ value }) => value)
              );
              setSelectedTeamOption(selected);
            }}
            values={selectedTeamOption}
            options={userTeams.map(({ id: value, name: label }) => ({
              label,
              value,
            }))}
            multi
            required
          />
        </>
      )}
      {selectedModel.date && (
        <>
          <Label>{selectedModel.date.label}</Label>
          <DatePiker
            name="date"
            formProps={{ ...formikprops, setFieldValue, values }}
            value={initialValues ? initialValues.date : model.date}
            onChange={handleDateSelect}
            showTime
            withClear
            emailDateValue={emailData[0]?.sentDateTime}
          />
        </>
      )}
    </>
  );
};

export default NewCall;

import * as Yup from "yup";

export const initModel = {
  externalMember: false,
  memberId: "",
  role: "member",
  title: "",
  firstName: "",
  lastName: "",
  organization: "",
  email: "",
};

export const roles = [
  { text: "Project Member", val: "member" },
  { text: "Project Manager", val: "manager" },
  { text: "Project Owner", val: "owner" },
];

export const validation = Yup.object().shape({
  externalMember: Yup.boolean(),

  memberId: Yup.string().when("externalMember", {
    is: false,
    then: Yup.string().required("member is required"),
  }),

  role: Yup.string().when("externalMember", {
    is: false,
    then: Yup.string().required("permission is required"),
  }),

  firstName: Yup.string().when("externalMember", {
    is: true,
    then: Yup.string().required("first name is required"),
  }),

  lastName: Yup.string().when("externalMember", {
    is: true,
    then: Yup.string().required("last name is required"),
  }),

  email: Yup.string().when("externalMember", {
    is: true,
    then: Yup.string().required("email is required"),
  }),
});

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import * as classNames from "classnames";
import { httpGet } from "../../../../../common/httpCall";
import styles from "./styles.module.scss";
import { Pannel, Loading, Pagination } from "../../../../primitives";

const RelatedSolutionsComponent = () => {
  const {
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { currentThemeData },
    },
  } = useSelector((state) => state);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoadingSolutions] = useState(false);
  const [themeSolutions, setSolutions] = useState([]);
  const [pageSetting, setPageSetting] = useState({
    pageSize: 50,
    current: 1,
    hideOnSinglePage: true,
    showTitle: false,
    total: themeSolutions?.length || 0,
  });
  const [seeAll, setSeeAll] = useState(false);
  const [chunkToShow, setChunkToShow] = useState([]);

  const handleSolutionRedirect = (id) => {
    history.push(`/solutions`, { solutionId: id });
  };
  const handlePagingChange = (page) => {
    setPageSetting((prev) => ({
      ...prev,
      total: themeSolutions?.length,
      current: page,
    }));
  };

  useEffect(() => {
    if (themeSolutions?.length && !seeAll) {
      setChunkToShow(themeSolutions.slice(0, 6));
    }
    if (pageSetting?.current && themeSolutions?.length && seeAll) {
      setChunkToShow(
        themeSolutions?.slice(
          (pageSetting.current - 1) * pageSetting.pageSize,
          pageSetting.current * pageSetting.pageSize
        )
      );
    }
  }, [pageSetting?.current, themeSolutions, seeAll]);

  useEffect(() => {
    if (themeSolutions?.length) {
      setPageSetting((prev) => ({
        ...prev,
        total: themeSolutions?.length,
      }));
    }
  }, [themeSolutions?.length]);

  const getSolutions = () => {
    setLoadingSolutions(true);
    try {
      httpGet({
        call: `themes/${currentThemeData.id}/solutions?page=${pageSetting.current}&items=3`,
        apiVersion: "v1",
      }).subscribe(
        async (res) => {
          setSolutions(res.response?.map((sol) => ({ ...sol.attributes })));
          setLoadingSolutions(false);
        },
        (err) => {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
          setLoadingSolutions(false);
        }
      );
    } catch (error) {
      console.log("---------error", error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
      setLoadingSolutions(false);
    }
  };

  useEffect(() => {
    if (currentThemeData.id) {
      getSolutions({
        id: currentThemeData.id,
        enqueueSnackbar,
      });
    }
  }, [selectedCouncil]);

  return (
    <Pannel
      title="Related Solutions"
      padding="0"
      parentStyle={{
        width: `${window.screen.width < 550 ? "100%" : "230px"}`,
        minWidth: "230px",
        padding: `${window.screen.width < 550 && "0px"}`,
      }}
    >
      <div className={styles.panelHeader}>Solutions</div>
      <div>
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.wrapper}>
            {themeSolutions?.length > 0 ? (
              chunkToShow?.map((item) => (
                <div className={classNames(styles.Row)} key={`${item.id}`}>
                  <div
                    onClick={() => handleSolutionRedirect(item.id)}
                    className={styles.title}
                  >
                    <span> {item.name} </span>
                  </div>
                </div>
              ))
            ) : (
              <span className="p-3 text-center">No solutions yet!</span>
            )}
            {seeAll && (
              <div className={styles.paginationCon}>
                <Pagination {...pageSetting} onChange={handlePagingChange} />
              </div>
            )}
            {themeSolutions?.length > 6 && (
              <div
                onClick={() => setSeeAll(!seeAll)}
                className={styles.showAll}
              >
                {seeAll ? "HIDE" : "SEE ALL"}
              </div>
            )}
          </div>
        )}
      </div>
    </Pannel>
  );
};

export default React.memo(RelatedSolutionsComponent);

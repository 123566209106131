import {
  PROJECT_COMPANY_SORTED_LIST_SET,
  PROJECT_COMPANY_TASK_LIST_GET_SUCCESS,
  PROJECT_COMPANY_CHANGE_STATUS_SUCCESS,
  GET_PROJECT_COMPANIES_SUCCESS,
  ROJECT_COMPANY_NEW_SUCCESS,
  PROJECT_COMPANY_REMOVE_SUCCESS,
  RATING_SAVE_SUCCESS,
  RATING_EDIT_SUCCESS,
  PROJECT_ORGANIZATIONS_GET_SUCCESS,
  PROJECT_ORGANIZATION_TASK_LIST_GET_SUCCESS,
  PROJECT_ORGANIZATION_TASK_UPDATE_SUCCESS,
  PROJECT_COMPANY_LIST_GET_ATTEMPT,
  SET_COMPANY_TASK_ASSIGNMENT_COMPLETED,
  UPDATE_COMPANY_PROGRESS_BAR,
  GET_COMPANY_DOCUMENTS_ATTEMPT,
  GET_COMPANY_DOCUMENTS_SUCCESS,
  CHANGE_COMPANY_DOCUMENT_STATE,
  FOLLOW_MULTIPLE_COMPANIES_SUCCESS,
  PROJECT_COMPANY_DELETE_PERMANENTLY,
} from "./companies.action";
import {
  PROJECT_COMPANY_COMPARE_CUSTOM_FIELDS_GET_SUCCESS,
  PROJECT_COMPANY_COMPARE_SORTED_LIST_SET,
} from "./compair/compair.actions";
import { apiStatus } from "../../../../common/httpCall";

const initState = {
  overviewCompanies: [],
  companyList: [],
  taskList_companyId: {},
  taskAssigment_companyId: {},
  sortedList: [],
  asc: false,
  companyRatings: {},
  updateTasks: false,
  rating_project_company_id: "",
  user_total_rating: null,
  patientOrganizations: [],
  custom_fields: [],
  projectCompaniesPageNr: 0,
  companiesTotal: 0,
  companyProjectsLoading: true,
  organizationsLoading: true,
  progressBarLoader: false,
  modifiedCompanyId: "",
  loadingDocuments: false,
  selectedCompanyDocuments: [],
};
const companyReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PROJECT_COMPANY_DELETE_PERMANENTLY: {
      if (payload.is_product) {
        return {
          ...state,
          companyList: state.companyList.filter(c => c.id !== payload.companyId)
        }
      }

      if (payload.patientOrg) {
        return {
          ...state,
          companyList: state.companyList.filter(c => c.gsk_patient_organization.id !== payload.companyId)
        }
      }

      return {
        ...state,
        companyList: state.companyList.filter(c => c.company.id !== payload.companyId)
      }
    }

    case FOLLOW_MULTIPLE_COMPANIES_SUCCESS: {
      return {
        ...state,
        companyList: state.companyList.map((c) => {
          const getFollowingCompany = payload.follow_relationships.find(
            (f) => f.following_id === c.id,
          );

          if (getFollowingCompany) {
            return {
              ...c,
              following: true,
            };
          }

          return c;
        }),
      };
    }

    case UPDATE_COMPANY_PROGRESS_BAR: {
      if (!payload.companyId || !payload.response) {
        return state;
      }

      const { response, companyId, patientOrg } = payload;
      const getCompany = response.organizations.find(
        (o) => o.organization_id === companyId,
      );

      if (!getCompany) return state;

      if (patientOrg) {
        return {
          ...state,
          patientOrganizations: state.patientOrganizations.map((o) => {
            if (o.gsk_patient_organization.id === getCompany.organization_id) {
              return {
                ...o,
                gsk_patient_organization: {
                  ...o.gsk_patient_organization,
                  total_tasks_progress: getCompany.total_tasks_progress,
                },
              };
            }

            return o;
          }),
        };
      }

      return {
        ...state,
        companyList: state.companyList.map((c) => {
          if (c.company.id === getCompany.organization_id) {
            return {
              ...c,
              company: {
                ...c.company,
                total_tasks_progress: getCompany.total_tasks_progress,
              },
            };
          }

          return c;
        }),
      };
    }

    case SET_COMPANY_TASK_ASSIGNMENT_COMPLETED: {
      const {
        companyId, stageId, assignmentId, taskId, value,
      } = payload;

      return {
        ...state,
        taskList_companyId: {
          ...state.taskList_companyId,
          [`${companyId}_${stageId}`]: Object.keys(
            state.taskList_companyId[`${companyId}_${stageId}`],
          ).map((key) => {
            const task = state.taskList_companyId[`${companyId}_${stageId}`][key];

            if (task.id === taskId) {
              return {
                ...task,
                projects_task_assignments: task.projects_task_assignments.map(
                  (a) => {
                    if (a.id === assignmentId) {
                      return {
                        ...a,
                        completed: value,
                      };
                    }

                    return a;
                  },
                ),
              };
            }

            return task;
          }),
        },
      };
    }

    case PROJECT_COMPANY_COMPARE_CUSTOM_FIELDS_GET_SUCCESS: {
      const { response } = payload;

      return {
        ...state,
        custom_fields: response,
      };
    }
    case PROJECT_ORGANIZATIONS_GET_SUCCESS: {
      const { response, status } = payload;

      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          patientOrganizations: response,
          organizationsLoading: false,
        };
      }
    }

    case RATING_SAVE_SUCCESS:
    case RATING_EDIT_SUCCESS:
      {
        const { response, status } = payload;
        if (status === apiStatus.SUCCESS || status === apiStatus.CREATED) {
          return {
            ...state,
            companyList: state.companyList.map((company) => {
              if (company.project_company_id === response.project_company_id) {
                return {
                  ...company,
                  score: response.score,
                  user_total_rating: response.user_total_rating,
                  user_rating_categories: response.user_rating_categories,
                };
              }
              return company;
            }),
          };
        }
      }
      break;

    case PROJECT_COMPANY_LIST_GET_ATTEMPT: {
      return {
        ...state,
        progressBarLoader: true,
      };
    }

    case GET_PROJECT_COMPANIES_SUCCESS: {
      const {
        result: { response, status },
        pageNr,
        companiesTotal,
        overviewInstance,
      } = payload;
      if (status === apiStatus.SUCCESS) {
        if (overviewInstance) {
          return {
            ...state,
            overviewCompanies: response,
          };
        }

        return {
          ...state,
          companyProjectsLoading: false,
          progressBarLoader: false,
          companyList: response,
          projectCompaniesPageNr: pageNr,
          companiesTotal,
        };
      }

      return state;
    }

    case PROJECT_COMPANY_SORTED_LIST_SET:
      return {
        ...state,
        sortedList: payload.array,
        asc: payload.asc,
      };

    case PROJECT_COMPANY_TASK_LIST_GET_SUCCESS: {
      const {
        result: { response, status },
        companyId,
        stageId,
      } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          taskList_companyId: {
            ...state.taskList_companyId,
            [`${companyId}_${stageId}`]: response,
          },
        };
      }
      break;
    }
    case PROJECT_ORGANIZATION_TASK_LIST_GET_SUCCESS: {
      const {
        result: { response, status },
        gsk_patient_organization_id,
        stageId,
      } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          taskList_companyId: {
            ...state.taskList_companyId,
            [`${gsk_patient_organization_id}_${stageId}`]: response,
          },
        };
      }
      break;
    }
    case PROJECT_ORGANIZATION_TASK_UPDATE_SUCCESS: {
      const { response, status, organizationId } = payload;

      if (status === apiStatus.SUCCESS && organizationId) {
        const organizationStageKey = `${organizationId}_${response.stage_id}`;

        if (state.taskList_companyId[organizationStageKey]) {
          return {
            ...state,
            taskList_companyId: {
              ...state.taskList_companyId,
              [organizationStageKey]: state.taskList_companyId[
                organizationStageKey
              ].map((item) => {
                if (item.id === response.id) {
                  return response;
                }

                return item;
              }),
            },
          };
        }
      }
      break;
    }

    case ROJECT_COMPANY_NEW_SUCCESS: {
      const {
        response, status, gsk, hasArrayRes = false, overviewInstance,
      } = payload;
      if (status === apiStatus.CREATED || status === apiStatus.SUCCESS) {
        if (gsk) {
          return {
            ...state,
            patientOrganizations: hasArrayRes
              ? [...state.patientOrganizations, ...response]
              : [...state.patientOrganizations, response],
          };
        }

        let props = {};

        if (overviewInstance) {
          props = {
            overviewCompanies: hasArrayRes
              ? [...response, ...state.overviewCompanies]
              : [response, ...state.overviewCompanies],
          }
        } else {
          props = {
            companyList: hasArrayRes
              ? [...response, ...state.companyList]
              : [response, ...state.companyList],
          }
        }

        return {
          ...state,
          ...props
        };
      }
      break;
    }

    case PROJECT_COMPANY_REMOVE_SUCCESS: {
      const {
        result: { response, status },
        companyId,
      } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          companyList: state.companyList.filter(
            ({ company }) => company.id !== companyId,
          ),
        };
      }
      break;
    }

    case PROJECT_COMPANY_CHANGE_STATUS_SUCCESS: {
      const {
        response,
        status,
        patientOrg,
        deletePermanently,
        companyId,
        manageProject,
        is_product,
      } = payload;
      if (status === apiStatus.SUCCESS || status === apiStatus.NOCONTENT) {
        if (patientOrg) {
          if (deletePermanently) {
            return {
              ...state,
              patientOrganizations: state.patientOrganizations.filter(
                (e) => e.gsk_patient_organization.id !== companyId,
              ),
            };
          }

          const res = Array.isArray(response) ? response[0] : response;
          const elemExist = state.patientOrganizations.find(
            (e) => e.gsk_patient_organization.id === res.gsk_patient_organization.id,
          );

          if (!elemExist) {
            return {
              ...state,
              patientOrganizations: [res, ...state.patientOrganizations],
            };
          }

          return {
            ...state,
            patientOrganizations: state.patientOrganizations.map((item) => (item.gsk_patient_organization.id
              === res.gsk_patient_organization.id
              ? { ...item, ...res }
              : item)),
          };
        }

        if (deletePermanently) {
          if (is_product) {
            return {
              ...state,
              companyList: state.companyList.filter(c => c.id !== companyId)
            }
          }

          const companyList = manageProject
            ? state.companyList.filter((e) => e.company.id !== companyId)
            : state.companyList.filter(
              (e) => e.project_company_id.id !== companyId,
            );
          return {
            ...state,
            companyList,
          };
        }

        if (is_product) {
          return {
            ...state,
            companyList: state.companyList.map(c => {
              if (c.id === response.id) {
                return {
                  ...c,
                  ...response,
                }
              }

              return c;
            })
          }
        }

        return {
          ...state,
          companyList: state.companyList.map((item) => (item.project_company_id === response.project_company_id
            ? { ...item, state: response.state }
            : item)),
        };
      }
      break;
    }

    case PROJECT_COMPANY_COMPARE_SORTED_LIST_SET:
      return {
        ...state,
        sortedList: payload.array,
        asc: payload.asc,
      };
    case GET_COMPANY_DOCUMENTS_ATTEMPT: {
      return {
        ...state,
        loadingDocuments: true,
        selectedCompanyDocuments: [],
      };
    }
    case GET_COMPANY_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        loadingDocuments: false,
        selectedCompanyDocuments: payload,
      };
    }
    case CHANGE_COMPANY_DOCUMENT_STATE: {
      const { companyId } = payload;
      const newCompanyList = state.companyList.map((company) => {
        if (company.company.id === companyId) {
          return {
            ...company,
            any_document: true,
          };
        }
        return company;
      });
      return {
        ...state,
        companyList: newCompanyList,
      };
    }
  }
  return state;
};

export default companyReducer;

import { combineReducers } from "redux";
import themesMainReducer from "./themesMainPage/themesMain.reducer";
import themesOverviewReducer from "./themesIndividualPage/themesOverviewTab/overviewTab.reducer";
import themesPostsReducer from "./themesIndividualPage/themesOverviewTab/postsBlock/postsBlock.reducer";
import themesFeedReducer from "./themesIndividualPage/themesOverviewTab/postsBlock/feed/feed.reducer";
import themesPipelineReducer from "./themesIndividualPage/themesPipelineTab/pipelineTab.reducer";
import themesHomepageReducer from "./themesHomePageBlock/themesHomePage.reducer";
import themesDocumentReducer from "./themesIndividualPage/themesDocumentsTab/documentsTab.reducer";
import themesProjectsReducer from "./themesIndividualPage/themesProjectsTab/projectsTab.reducer";

const themesReducer = combineReducers({
  themesMainReducer,
  themesOverviewReducer,
  themesPostsReducer,
  themesFeedReducer,
  themesPipelineReducer,
  themesHomepageReducer,
  themesDocumentReducer,
  themesProjectsReducer,
});

export default themesReducer;

export const UPDATE_SESSION_DETAILS = "UPDATE_SESSION_DETAILS";
export const updateSessionDetails = (payload) => ({
  type: UPDATE_SESSION_DETAILS,
  payload,
});

export const GET_NEW_TOKEN = "GET_NEW_TOKEN";
export const getNewToken = (payload) => ({
  type: GET_NEW_TOKEN,
  payload,
});

export const GET_NEW_TOKEN_SUCCESS = "GET_NEW_TOKEN_SUCCESS";
export const getNewTokenSuccess = (payload) => ({
  type: GET_NEW_TOKEN_SUCCESS,
  payload,
});

export const LOGIN_ATTEMPT = "LOGIN_ATTEMPT";
export const loginAttempt = (payload) => ({ type: LOGIN_ATTEMPT, payload });
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const loginSuccess = (payload) => ({ type: LOGIN_SUCCESS, payload });

export const LOGOUT_ATTEMPT = "LOGOUT_ATTEMPT";
export const logOut = (payload) => ({ type: LOGOUT_ATTEMPT, payload });
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const logOutSuccess = (payload) => ({ type: LOGOUT_SUCCESS, payload });

export const GET_ME_ATTEMPT = "GET_ME_ATTEMPT";
export const getMeAttempt = (payload) => ({
  type: GET_ME_ATTEMPT,
  payload,
});

export const REGISTER_ATTEMPT = "REGISTER_ATTEMPT";
export const registerAttempt = (payload) => ({
  type: REGISTER_ATTEMPT,
  payload,
});
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const registerSuccess = (payload) => ({
  type: REGISTER_SUCCESS,
  payload,
});

export const SET_USER = "SET_USER";
export const setUser = (payload) => ({ type: SET_USER, payload });

export const FORGOT_ATTEMPT = "FORGOT_ATTEMPT";
export const forgotAttempt = (payload) => ({ type: FORGOT_ATTEMPT, payload });
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const forgotSuccess = (payload) => ({ type: FORGOT_SUCCESS, payload });

import React, { useRef, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useMsal } from "@azure/msal-react";
import { useSnackbar } from "notistack";
import { getContacts } from "api/microsoft/graphApi/outlookOffice";

import { patientOrganizationsGet } from "modules/patientOrganizations/patientOrganizationMain/patientOrganizationMain.action";
import { appModelSettingSet } from "application/app.action";
import {
  peopleAddOrganizationContact,
  addCompanyContact,
} from "modules/people/externalPeople/companyContacts.action";
import {
  DropSelect,
  Button,
  BtnType,
  Label,
} from "../../../../primitives/index";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../common/hooks";
import { ModalFooter, ModalBody } from "../../../../../application/modal";
import { initModel, validation } from "./constants";
import { httpGet } from "../../../../../common/httpCall";
import {
  companyListGet,
  companySearchClear,
  searchInCouncil,
} from "../../../companyMain/companyMain.action";

const AddMSFTExternalContacts = (props) => {
  const { defaultModel = {}, id, mode, cb } = props;
  const popup = useModelPopup();
  const [updateCompany, setUpdateCompany] = useState(false);
  const [organization, setOrganization] = useState("");
  const [deselected, setDeselected] = useState(null);
  const [company, setCompany] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState("company");
  const [msftContacts, setMsftContacts] = useState([]);
  const { instance, accounts } = useMsal();
  const [companyData, setCompanyData] = useState({});
  const [allContactList, setAllContactList] = useState([]);
  const [uniqueContacts, setUniqueContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMsftResponse, setIsMsftResponse] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();

  const {
    authReducer: { session },
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: { companyList, searchResults },
    },
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: { patientOrganizationList },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    httpGet({
      call: "external_contacts/contact_list",
    })
      .pipe()
      .subscribe((res) => {
        setAllContactList(res.response);
      });
  }, []);

  const fetchContacts = useCallback(async () => {
    const response = await getContacts(
      instance,
      accounts,
      session?.users_integrations
    );
    const contacts = response?.value.filter(
      (elem) => elem.emailAddresses.length > 0
    );
    setIsMsftResponse(true);
    setMsftContacts(contacts);
  }, [instance, accounts, setMsftContacts, setIsMsftResponse]);

  useEffect(() => {
    if (!allContactList || !msftContacts) return;

    if (!uniqueContacts.length) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    const uniqueValues = msftContacts?.filter(
      (e) => !allContactList.includes(e.emailAddresses[0].address)
    );
    if (uniqueValues) setUniqueContacts(uniqueValues);

    if (isMsftResponse) return;

    fetchContacts();
  }, [instance, accounts, msftContacts, allContactList, isMsftResponse]);

  useEffect(() => {
    dispatch(
      companyListGet({
        councilId: selectedCouncil.id,
        items: 20,
        enqueueSnackbar,
      })
    );
  }, [selectedCouncil, updateCompany]);

  useEffect(() => {
    if (!searchType) {
      dispatch(
        searchInCouncil({
          data: searchQuery,
          council_id: selectedCouncil.id,
          mode: "companies",
          enqueueSnackbar,
          cb: () => {},
        })
      );
    } else if (searchType && searchQuery) {
      dispatch(
        searchInCouncil({
          data: searchQuery,
          council_id: selectedCouncil.id,
          mode: searchType,
          gsk: searchType !== "companies" && true,
          patient_org: searchType !== "companies" && true,
          enqueueSnackbar,
          cb: () => {},
        })
      );
    }
  }, [searchQuery, searchType]);

  const handleDropOpen = () => {};

  const handleDropClose = (e) => {
    setUpdateCompany(true);
    dispatch(companySearchClear());
    if (
      (mode !== "edit" && selectedCouncil.traction_tag === "gsk") ||
      defaultModel.org_type === "patient"
    ) {
      dispatch(
        patientOrganizationsGet({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  };

  const handleSaveClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const addCompany = (values, organization_id) => {
    if (organization) {
      dispatch(
        peopleAddOrganizationContact({
          data: { ...values, gsk_patient_organization_id: organization },
          enqueueSnackbar,
        })
      );
    } else if (company) {
      dispatch(
        addCompanyContact({
          data: { ...values, company_id: organization_id || company },
          enqueueSnackbar,
        })
      );
    }
  };

  const handleOnSubmit = (value) => {
    addCompany(companyData, value.organization_id);
    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
  };

  const handleSelectCompany = (e, setFieldValue) => {
    setCompany(e.val);
    setFieldValue("organization_id", e.val);
    if (company === e.val) {
      setCompany("");
      setFieldValue("organization_id", "");
      setDeselected(e.val);
    } else if (organization && e.val && mode !== "edit") {
      setDeselected(organization);
      setOrganization("");
      setFieldValue("patient_organization_id", "");
    }
  };

  const handleSearch = ({ state }, type) => {
    if (state.search !== "") {
      setSearchQuery(state.search);
      setSearchType(type);
      return searchType === "companies"
        ? searchResults
        : patientOrganizationList;
    }
  };

  const handleCancelClick = () => {
    if (cb) {
      cb(true);
    }
    popup.hide();
  };

  const handleSelectContact = (e) => {
    const data = {
      first_name: e.obj[0].givenName,
      last_name: e.obj[0].surname,
      email: e.obj[0].emailAddresses[0].address,
      title: e.obj[0].jobTitle,
      mobile: e.obj[0].businessPhones[0],
      origin: "microsoft",
    };
    setCompanyData({ ...data });
  };

  return (
    <div className={styles.wrapper}>
      <Formik
        innerRef={formRef}
        initialValues={initModel}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleOnSubmit(values);
          resetForm();
        }}
      >
        {({ setFieldValue, handleSubmit, handleChange, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <>
                    {" "}
                    <>
                      <div>
                        <Label>Select External Contacts from Microsoft</Label>
                        <DropSelect
                          placeholder="Select Contacts"
                          name="contact_id"
                          labelField="displayName"
                          valueField="id"
                          searchable
                          backspaceDelete
                          searchBy="displayName"
                          loading={loading}
                          options={uniqueContacts}
                          onDropdownOpen={handleDropOpen}
                          onDropdownClose={handleDropClose}
                          formProps={formikprops}
                          onChange={(e) =>
                            handleSelectContact(e, setFieldValue)
                          }
                        />
                      </div>
                    </>
                    <Label>Organization</Label>
                    <div>
                      <DropSelect
                        placeholder="Select Company"
                        name="organization_id"
                        onDropdownOpen={handleDropOpen}
                        onDropdownClose={handleDropClose}
                        labelField="name"
                        valueField="id"
                        searchBy="name"
                        searchable
                        deselected={
                          (organization || mode === "edit") && deselected
                        }
                        backspaceDelete
                        options={
                          searchResults && searchResults[0]
                            ? searchResults
                            : companyList
                        }
                        formProps={formikprops}
                        onChange={(e) => handleSelectCompany(e, setFieldValue)}
                        searchFn={(payload) =>
                          handleSearch(payload, "companies")
                        }
                      />
                    </div>
                  </>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button
                type="submit"
                btn={BtnType.REGULAR}
                onClick={() => handleSaveClick()}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(AddMSFTExternalContacts);

import { combineEpics, ofType } from "redux-observable";
import { catchError, map, switchMap, mergeMap } from "rxjs/operators";

import { errorHandler, httpDelete, httpGet, httpPost, httpPut } from "../../../common/httpCall";
import {
  COMPANIES_BUILDER_GET_THEMES,
  companiesBuilderGetThemesSuccess,
  GET_ADDITIONAL_DATA_FOR_PROJECTS_BUILDER,
  GET_BUILDER_STAGES,
  GET_COMPANIES_BY_TIME_IN_STAGE,
  GET_COMPANIES_FUNNEL_OVERVIEW_DATA,
  GET_COMPANIES_FUNNEL_TECHNOLOGY_DATA,
  GET_CUSTOM_BUILDERS_BY_SECTION,
  GET_CUSTOM_SECTION_TABLE_DATA,
  GET_FUNNEL_DATA,
  GET_IDEAS_OVERVIEW_DATA,
  GET_IDEAS_TECHNOLOGY_DATA,
  GET_PROJECTS_BY_STATUS,
  GET_PROJECTS_OVERVIEW_DATA,
  GET_REPORTS_COMPANIES_CUSTOM_FIELDS,
  GET_REPORTS_PROJECT_FIELDS,
  getAdditionalDataForProjectsBuilderSuccess,
  getBuilderStagesSuccess,
  getCompaniesByTimeInStageSuccess,
  getCompaniesFunnelOverviewDataSuccess,
  getCompaniesFunnelTechDataSuccess,
  getCustomBuildersBySectionSuccess,
  getCustomSectionTableDataSuccess,
  getFunnelDataSuccess,
  getIdeasOverviewDataSuccess,
  getIdeasTechnologyDataSuccess,
  getProjectsByStatusSuccess,
  getProjectsOverviewDataSuccess,
  getReportsCompaniesCustomFieldsSuccess,
  getReportsProjectFieldsSuccess,
  HANDLE_DELETE_SECTION,
  HANDLE_DOWNLOAD_SOURCE_DATA,
  handleDeleteSectionSuccess,
  makeFakeAction,
  SAVE_CUSTOM_BUILDER,
  saveCustomBuilderSuccess,
  SET_SECTION_VISIBILITY,
  setSectionVisibilitySuccess,
} from "modules/reporting/store/reporting.actions";
import { CHARTS_TABLE_TYPE_BY_KEY } from "modules/reporting/constants";

export const epicGetBuilderThemes = (action$) =>
  action$.pipe(
    ofType(COMPANIES_BUILDER_GET_THEMES),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/companies/search`,
        data,
      }).pipe(
        map(
          (result) => companiesBuilderGetThemesSuccess(result),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
      )
    )
  );

export const epicGetAdditionalProjectBuilderData = (action$) => action$.pipe(
  ofType(GET_ADDITIONAL_DATA_FOR_PROJECTS_BUILDER),
  switchMap(({ payload }) => httpPost({
    call: 'custom_charts/filter_projects',
    data: {
      option_selected: payload.key,
      page: payload.page || 1,
      per_page: 20,
    },
  }).pipe(
    map((result) => getAdditionalDataForProjectsBuilderSuccess({
      ...result,
      key: payload.key,
    })),
    catchError((err) => errorHandler(err)),
  )),
);

export const epicGetReportsCompaniesCustomFields = (action$) => action$.pipe(
  ofType(GET_REPORTS_COMPANIES_CUSTOM_FIELDS),
  switchMap(({ payload: { enqueueSnackbar } }) => httpGet({
    call: 'custom_field_templates/public',
  }).pipe(
    map((result) => getReportsCompaniesCustomFieldsSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetReportsProjectFields = (action$) => action$.pipe(
  ofType(GET_REPORTS_PROJECT_FIELDS),
  switchMap(({ payload: { enqueueSnackbar } }) => httpGet({
    call: 'project_field_templates?per_page=999',
  }).pipe(
    map((result) => getReportsProjectFieldsSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetBuilderStages = (action$) => action$.pipe(
  ofType(GET_BUILDER_STAGES),
  switchMap(({ payload: { enqueueSnackbar } }) => httpGet({
    call: 'projects/stage_templates/stages',
  }).pipe(
    map((result) => getBuilderStagesSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetFunnelData = (action$) => action$.pipe(
  ofType(GET_FUNNEL_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    apiVersion: "v2",
    call: `reports?page=${data.filters?.page || 1}&resource=company&chart_type=funnel`,
    data: data.data,
  }).pipe(
    map((result) => getFunnelDataSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetCompaniesByTimeInStage = (action$) => action$.pipe(
  ofType(GET_COMPANIES_BY_TIME_IN_STAGE),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    apiVersion: "v2",
    call: `reports?page=${data.filters?.page || 1}&resource=company&chart_type=horizontal-stacked-bar`,
    data: data.data,
  }).pipe(
    map((result) => getCompaniesByTimeInStageSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetCompaniesOverviewData = (action$) => action$.pipe(
  ofType(GET_COMPANIES_FUNNEL_OVERVIEW_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    apiVersion: "v2",
    call: `reports?page=${data.filters?.page || 1}&resource=company&chart_type=bar`,
    data: data.data,
  }).pipe(
    map((result) => getCompaniesFunnelOverviewDataSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetCompaniesTechnologyData = (action$) => action$.pipe(
  ofType(GET_COMPANIES_FUNNEL_TECHNOLOGY_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    apiVersion: "v2",
    call: `reports?page=${data.filters?.page || 1}&resource=company_technologies&chart_type=funnel`,
    data: data.data,
  }).pipe(
    map((result) => getCompaniesFunnelTechDataSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetIdeasOverviewData = (action$) => action$.pipe(
  ofType(GET_IDEAS_OVERVIEW_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    apiVersion: "v2",
    call: `reports?page=${data.filters?.page || 1}&resource=idea&chart_type=bar`,
    data: data.data,
  }).pipe(
    map((result) => getIdeasOverviewDataSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetIdeasTechnologyData = (action$) => action$.pipe(
  ofType(GET_IDEAS_TECHNOLOGY_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    apiVersion: "v2",
    call: `reports?page=${data.filters?.page || 1}&resource=idea_tags&chart_type=bar`,
    data: data.data,
  }).pipe(
    map((result) => getIdeasTechnologyDataSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetCustomBuilders = (action$) => action$.pipe(
  ofType(GET_CUSTOM_BUILDERS_BY_SECTION),
  mergeMap(({ payload }) => httpPost({
    apiVersion: "v2",
    call: `custom_reports/get_all_custom_charts?key=${payload.section_name}`,
    data: payload,
  }).pipe(
    map((result) => getCustomBuildersBySectionSuccess({
      ...result,
      customKeys: payload,
    })),
    catchError((err) => errorHandler(err)),
  )),
);

export const epicSaveCustomBuilder = (action$) => action$.pipe(
  ofType(SAVE_CUSTOM_BUILDER),
  switchMap(({ payload }) => httpPost({
    apiVersion: "v2",
    call: 'custom_reports',
    data: payload,
  }).pipe(
    map((result) => {
      if (payload.cb) {
        payload.cb(result?.response?.id);
      }

      return saveCustomBuilderSuccess({
        ...result,
        reducerKey: payload.reducerKey,
      });
    }),
    catchError((err) => errorHandler(err)),
  )),
);

export const epicDeleteCustomSection = (action$) => action$.pipe(
  ofType(HANDLE_DELETE_SECTION),
  switchMap(({ payload }) => httpDelete({
    apiVersion: "v2",
    call: `custom_reports/${payload.id}`,
  }).pipe(
    map((result) => {
      if (payload.cb) {
        payload.cb();
      }

      return handleDeleteSectionSuccess({
        ...result,
        ...payload
      });
    }),
    catchError((err) => errorHandler(err)),
  )),
);

export const epicHandleDownloadSourceData = (action$) => action$.pipe(
  ofType(HANDLE_DOWNLOAD_SOURCE_DATA),
  switchMap(({ payload }) => httpPost({
    apiVersion: "v2",
    call: "exports",
    data: payload,
    respType: "blob",
  }).pipe(
    map((result) => {
      if (payload.cbForResponse) {
        payload.cbForResponse(result);
      }

      return makeFakeAction(result);
    }),
    catchError((err) => errorHandler(err)),
  )),
);

export const epicGetProjectsOverviewData = (action$) => action$.pipe(
  ofType(GET_PROJECTS_OVERVIEW_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    apiVersion: "v2",
    call: `reports?resource=project&chart_type=bar&page=${data.filters?.page || 1}&target_attribute=overview`,
    data: data.data,
  }).pipe(
    map((result) => getProjectsOverviewDataSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetProjectsByStatus = (action$) => action$.pipe(
  ofType(GET_PROJECTS_BY_STATUS),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    apiVersion: "v2",
    call: `reports?resource=project&chart_type=bar&page=${data.filters?.page || 1}&target_attribute=projects_by_status`,
    data: data.data,
  }).pipe(
    map((result) => getProjectsByStatusSuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicSetSectionVisibility = (action$) => action$.pipe(
  ofType(SET_SECTION_VISIBILITY),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPut({
    apiVersion: "v2",
    call: `custom_reports/${data.id}`,
    data: data,
  }).pipe(
    map((result) => setSectionVisibilitySuccess(result)),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

export const epicGetCustomSectionTableData = (action$) => action$.pipe(
  ofType(GET_CUSTOM_SECTION_TABLE_DATA),
  switchMap(({ payload: { data, enqueueSnackbar } }) => httpPost({
    apiVersion: "v2",
    call: 'custom_chart_list',
    data: {
      ...data,
      section_name: CHARTS_TABLE_TYPE_BY_KEY[data.propToReadTableDataFrom],
    },
  }).pipe(
    map((result) => getCustomSectionTableDataSuccess({
      ...result,
      parentReducerKey: data.parentReducerKey,
      propToReadTableDataFrom: data.propToReadTableDataFrom,
      propToReadTableModalDataFrom: data.propToReadTableModalDataFrom,
      customChart: data.customChart,
    })),
    catchError((err) => errorHandler(err, enqueueSnackbar)),
  )),
);

const reportingEpic = combineEpics(
  epicGetFunnelData,
  epicGetCompaniesByTimeInStage,
  epicGetCompaniesOverviewData,
  epicGetCompaniesTechnologyData,
  epicGetIdeasOverviewData,
  epicGetIdeasTechnologyData,
  epicGetCustomBuilders,
  epicSaveCustomBuilder,
  epicDeleteCustomSection,
  epicHandleDownloadSourceData,
  epicGetProjectsOverviewData,
  epicGetProjectsByStatus,
  epicSetSectionVisibility,
  epicGetCustomSectionTableData,
  epicGetBuilderStages,
  epicGetReportsProjectFields,
  epicGetReportsCompaniesCustomFields,
  epicGetAdditionalProjectBuilderData,
  epicGetBuilderThemes,
);

export default reportingEpic;

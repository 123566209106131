import {
  GET_AVAILABLE_INTEGRATIONS,
  GET_AVAILABLE_INTEGRATIONS_SUCCESS,
  CATCH_ERROR_SUCCESS,
  POST_COUNCIL_INTEGRATION,
  PUT_COUNCIL_INTEGRATION,
  POST_USER_INTEGRATION,
  PUT_USER_INTEGRATION,
  SET_ACCESS_TOKEN_SALESFORCE,
  SET_PAGE_ATTRIBUTE,
  SET_OPPORTUNITY_STAGE_PAYLOAD,
  SET_RELATIONSHIP_STATUS_PAYLOAD,
  SET_SF_CUSTOM_FIELD_PAYLOAD,
  SET_TTP_CUSTOM_FIELD_PAYLOAD,
  SET_EXPORT_OPPORTUNITY_STAGE_PAYLOAD,
  SET_EXPORT_RELATIONSHIP_STATUS_PAYLOAD,
  SET_SF_EXPORT_CUSTOM_FIELD_PAYLOAD,
  SET_TTP_EXPORT_CUSTOM_FIELD_PAYLOAD,
  SYNC_ACCOUNTS_TOGGLE,
} from "./adminIntegrations.actions";

const initState = {
  integrations: [],
  loading: false,
};

const adminIntegrationsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_AVAILABLE_INTEGRATIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_AVAILABLE_INTEGRATIONS_SUCCESS: {
      const microsoftData = payload.response.find(
        (elem) => elem.name === "Microsoft",
      );
      const councilIntegration = microsoftData?.councils_integrations?.find(
        (elem) => elem.integration_id === microsoftData.id,
      );
      const userIntegration = microsoftData?.users_integrations?.find(
        (elem) => elem.integration_id === microsoftData.id,
      );
      if (
        councilIntegration?.status &&
        councilIntegration?.admin_connected &&
        userIntegration?.token
      ) {
        localStorage.setItem("microsoft_login", true);
      } else {
        localStorage.setItem("microsoft_login", false);
      }
      return {
        ...state,
        integrations: payload.response,
        loading: false,
      };
    }
    case CATCH_ERROR_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case POST_COUNCIL_INTEGRATION: {
      return {
        ...state,
        loading: false,
      };
    }
    case PUT_COUNCIL_INTEGRATION: {
      return {
        ...state,
        loading: false,
      };
    }
    case POST_USER_INTEGRATION: {
      return {
        ...state,
        loading: false,
      };
    }
    case PUT_USER_INTEGRATION: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_ACCESS_TOKEN_SALESFORCE: {
      return {
        ...state,
        tokenObj: payload.response,
        loading: false,
      };
    }
    case SET_PAGE_ATTRIBUTE: {
      return {
        ...state,
        pageAttribute: payload,
      };
    }
    case SET_OPPORTUNITY_STAGE_PAYLOAD: {
      return {
        ...state,
        opportunityStagePayload: payload || [],
      };
    }
    case SET_RELATIONSHIP_STATUS_PAYLOAD: {
      return {
        ...state,
        relationshipStatusPayload: payload || [],
      };
    }
    case SET_SF_CUSTOM_FIELD_PAYLOAD: {
      return {
        ...state,
        sfCustomFieldPayload: payload || [],
      };
    }
    case SET_TTP_CUSTOM_FIELD_PAYLOAD: {
      return {
        ...state,
        ttpCustomFieldPayload: payload || [],
      };
    }
    case SET_EXPORT_OPPORTUNITY_STAGE_PAYLOAD: {
      return {
        ...state,
        exportOpportunityStagePayload: payload || [],
      };
    }
    case SET_EXPORT_RELATIONSHIP_STATUS_PAYLOAD: {
      return {
        ...state,
        exportRelationshipStatusPayload: payload || [],
      };
    }
    case SET_SF_EXPORT_CUSTOM_FIELD_PAYLOAD: {
      return {
        ...state,
        sfExportCustomFieldPayload: payload || [],
      };
    }
    case SET_TTP_EXPORT_CUSTOM_FIELD_PAYLOAD: {
      return {
        ...state,
        ttpExportCustomFieldPayload: payload || [],
      };
    }
    case SYNC_ACCOUNTS_TOGGLE: {
      return {
        ...state,
        sync_toggle: payload,
      };
    }
    default:
      return state;
  }
};
export default adminIntegrationsReducer;

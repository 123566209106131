export const objectType = {
  text: "text",
  checkList: "checklist",
  dropDown: "dropdown",
  document: "document",
  link: "link",
  image: "image",
  slides: "slide",
  video: "video",
  date: "date",
  number: "number",
  agreement: "agreement",
};

export const keyCriteria = [
  {
    id: "1",
    type: objectType.text,
  },
  {
    id: "2",
    type: objectType.text,
  },
  {
    id: "3",
    type: objectType.dropDown,
  },
];

export const docPresentationList = [
  {
    id: "1",
    type: objectType.checkList,
  },
  {
    id: "2",
    type: objectType.document,
  },
  {
    id: "3",
    type: objectType.video,
  },
  {
    id: "4",
    type: objectType.link,
  },
  {
    id: "5",
    type: objectType.image,
  },
  {
    id: "6",
    type: objectType.slides,
  },
  {
    id: "7",
    type: objectType.date,
  },
  {
    id: "8",
    type: objectType.number,
  },
  {
    id: "9",
    type: objectType.agreement,
  },
];

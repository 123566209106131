import {
  RATINGS_TEMPLATE_POST_ATTEMPT,
  RATINGS_TEMPLATE_GET_ATTEMPT,
  RATINGS_TEMPLATE_GET_SUCCESS,
  RATINGS_TEMPLATE_DELETE_ATTEMPT,
  RATINGS_TEMPLATE_DELETE_SUCCESS,
  RATINGS_TEMPLATE_PATCH_ATTEMPT,
  RATINGS_TEMPLATE_PATCH_SUCCESS,
  RATINGS_TEMPLAE_CATEGORIES_GET_ATTEMPT,
  RATINGS_TEMPLAE_CATEGORIES_GET_SUCCESS,
  RATINGS_TEMPLAE_SINGLE_GET_ATTEMPT,
  RATINGS_TEMPLAE_SINGLE_GET_SUCCESS,
  CATCH_ERROR_SUCCESS,
} from "./adminRating.action";

const initState = {
  ratingTemplates: [],
  loading: false,
  changed: false,
  selectedTemplateCategories: [],
  ratingRemplatesPageNr: {},
  selectedTemplate: {},
};

const adminRatingTemplatesReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RATINGS_TEMPLATE_GET_ATTEMPT: {
      return { ...state, loading: true };
    }
    case RATINGS_TEMPLATE_GET_SUCCESS: {
      return { ...state, ratingTemplates: payload, loading: false };
    }
    case RATINGS_TEMPLATE_POST_ATTEMPT: {
      return { ...state, loading: true };
    }
    case RATINGS_TEMPLATE_DELETE_ATTEMPT: {
      return { ...state, changed: true, loading: true };
    }
    case RATINGS_TEMPLATE_DELETE_SUCCESS: {
      return { ...state, changed: false, loading: false };
    }
    case RATINGS_TEMPLATE_PATCH_ATTEMPT: {
      return { ...state, changed: true, loading: true };
    }
    case RATINGS_TEMPLATE_PATCH_SUCCESS: {
      return { ...state, changed: false, loading: false };
    }
    case RATINGS_TEMPLAE_CATEGORIES_GET_ATTEMPT: {
      return { ...state, loading: true };
    }
    case RATINGS_TEMPLAE_CATEGORIES_GET_SUCCESS: {
      return { ...state, selectedTemplateCategories: payload, loading: false };
    }
    case RATINGS_TEMPLAE_SINGLE_GET_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case RATINGS_TEMPLAE_SINGLE_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedTemplate: payload,
      };
    }
    case CATCH_ERROR_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      break;
    }
  }
  return state;
};

export default adminRatingTemplatesReducer;

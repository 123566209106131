import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { useModelPopup } from "../../../../../common/hooks";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import AddRelatedTheme from "./addRelatedTheme";
import styles from "./styles.module.scss";
import { Pannel } from "../../../../primitives";
import { deleteRelatedThemeAttempt } from "../overview.action";

const ProjectsThemes = ({
  selectedProject,
  selectedCouncil,
  expandAll,
  sessionRole,
}) => {
  const popup = useModelPopup();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);

  const handleAddTheme = () => {
    popup.show({
      title: `Add ${
        selectedCouncil?.settings?.themes_display_name || "Themes"
      }`,
      show: true,
      height: "300",
      width: "540",
      component: (
        <AddRelatedTheme selectedProject={selectedProject} patientOrg={false} />
      ),
    });
  };

  const handleThemeRedirect = (theme_id) => {
    history.push(`/themes/individual_page/${theme_id}/overview`);
  };

  const handleRemoveTheme = (theme_id) => {
    dispatch(
      deleteRelatedThemeAttempt({
        id: theme_id,
        projectId: selectedProject.id,
        enqueueSnackbar,
      })
    );
  };

  const rightContent = useCallback(() => {
    return (
      <div
        className="d-flex justify-content-center cursor-pointer "
        onClick={() => setExpanded((prev) => !prev)}
      >
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(
            styles.icon,
            expanded ? styles.collapse : styles.collapseUp,
          )}
        />
      </div>
    );
  }, [expanded]);

  return (
    <Pannel
      title={` Related ${
        selectedCouncil?.settings?.themes_display_name || "Themes"
      }`}
      padding="0"
      rightContent={rightContent()}
      withCollapse
      expanded={expanded}
    >
      <div className={styles.panelHeader}>
        {selectedCouncil?.settings?.themes_display_name || "Themes"}
        {sessionRole !== "viewer" && (
          <Icon
            {...mdSize}
            icon="icn-add"
            className={styles.addNew}
            onClick={handleAddTheme}
          />
        )}
      </div>
      <div className={styles.wrapper}>
        {!selectedProject?.themes?.length ? (
          <span className="p-3 text-center">
            No related{" "}
            {selectedCouncil?.settings?.themes_display_name || "Theme"} yet.
          </span>
        ) : (
          selectedProject?.themes?.map((theme) => (
            <div
              onClick={() => handleThemeRedirect(theme.theme_id)}
              key={theme.theme_id}
              className={styles.title}
            >
              <span> {theme.name} </span>
              {sessionRole !== "viewer" && (
                <Icon
                  {...smSize}
                  icon="icn-button-delete"
                  className={styles.deleteIcon}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleRemoveTheme(theme.theme_id);
                  }}
                />
              )}
            </div>
          ))
        )}
      </div>
    </Pannel>
  );
};

export default React.memo(ProjectsThemes);

import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Button, BtnType } from "modules/primitives/index";
import styles from "./styles.module.scss";
import isUserAdmin from "../isUserAdmin/index";

const checkUserAddThemePermissions = (role, council) => {
  if (isUserAdmin(role)) {
    return true;
  }

  return council !== "Firmenich" && role === "standard";
};
const CheckThemeRole = (
  role,
  onClickFunction,
  textOnValidRole,
  textOnInvalidRole,
  council
) => {
  if (role === 'company') {
    return null
  }

  if (checkUserAddThemePermissions(role, council)) {
    return (
      <div className={styles.newThemeButton}>
        <Button
          icon="icn-add"
          onClick={onClickFunction}
          btn={BtnType.REGULAR}
          className={styles.addCompanyButton}
        >
          {textOnValidRole}
        </Button>
      </div>
    );
  }
  if (council === "Ford") {
    return null;
  }
  return (
    <div className={styles.newThemeButton}>
      <Tooltip
        title={
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            dangerouslySetInnerHTML={{ __html: textOnInvalidRole }}
          />
        }
        placement="bottom"
      >
        <button className={styles.addCompDisp}>
          <span>{textOnValidRole}</span>
        </button>
      </Tooltip>
    </div>
  );
};
export default CheckThemeRole;

import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { CheckBox, CheckType, Label, TextBox } from "modules/primitives";
import { companyGetTopics, setFilters } from "modules/companies/companyMain/companyMain.action";
import styles from "./styles.module.scss";
import { Icon, smSize } from "../../../icon";

const ProjectTopics = ({ isAdminWizardInstance, formikprops }) => {
  const [select, setSelect] = useState([]);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [query, setQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    companiesReducer: {
      companyMainReducer: { topics },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (isAdminWizardInstance) return;

    dispatch(
      companyGetTopics({
        userId: session.id,
        councilId: selectedCouncil.id,
        enqueueSnackbar,
      })
    );
    const new_project = localStorage.getItem("new_project");
    const obj = JSON.parse(new_project);
    setSelect(obj?.topics || []);
  }, [isAdminWizardInstance]);

  const onTagsOnChange = (val, item) => {
    if (val) {
      const itemIds = [item.id];
      const newSelect = [...select, ...itemIds];

      setSelect(newSelect);
      dispatch(
        setFilters({
          topic_ids: newSelect,
        })
      );
      if (!item.expanded) {
        handleItemExpand(item);
      }
    } else {
      const newSelect = select.filter((id) => id !== item.id);

      setSelect(newSelect);
      dispatch(
        setFilters({
          topic_ids: newSelect,
        })
      );
    }
  };

  useEffect(() => {
    const new_project = localStorage.getItem("new_project");
    const oldObj = JSON.parse(new_project) || {};
    const obj = { ...oldObj, topics: select };
    localStorage.setItem("new_project", JSON.stringify(obj));
  }, [Object.keys(select)]);

  const getSelected = useCallback(
    (id) => select.find((key) => key === id),
    [select]
  );

  useEffect(() => {
    if (!selectedCouncil) {
      return;
    }

    if (topics) {
      const newTopics = topics
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((i) => ({ ...i, expanded: false }));
      setItems(newTopics);
    }
  }, [topics, selectedCouncil]);

  const handleItemExpand = (item) => {
    if (filteredItems[0]) {
      const newItems = filteredItems.map((i) => {
        if (i.id === item.id) {
          return {
            ...i,
            expanded: !i.expanded,
          };
        }

        return i;
      });
      setFilteredItems(newItems);
    } else {
      const newItems = items.map((i) => {
        if (i.id === item.id) {
          return {
            ...i,
            expanded: !i.expanded,
          };
        }

        return i;
      });

      setItems(newItems);
    }
  };

  const handleSearchItems = (e) => {
    setQuery(e.target.value);
    if (e.target.value === "") {
      setFilteredItems(items);
    } else {
      const filtered = items.filter((item) => {
        return !!item.name
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase());
      });
      setFilteredItems(filtered);
    }
  };

  const renderItems = (items) => (
    <div className={styles.listWrp}>
      {items.map((item) => (
        <div key={item.id} className={styles.formElement}>
          <div className="d-flex align-items-center">
            <CheckBox
              checkType={CheckType.BLUE}
              isChecked={getSelected(item.id)}
              onChange={(val) => onTagsOnChange(val, item)}
            />
            <Label>
              <div className={styles.tagStyle}>{item?.name}</div>
            </Label>
            {item.children_topics?.length ? (
              <Icon
                {...smSize}
                icon="icn-table-down-chevron"
                className={classNames(
                  styles.icon,
                  !item.expanded && styles.collapse
                )}
                onClick={() => {
                  handleItemExpand(item);
                }}
              />
            ) : null}
          </div>
          {item.expanded
            ? item['children_topics'].map((child) => (
              <div
                key={child.id}
                className="pl-3 mt-2 d-flex align-items-center"
              >
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={getSelected(child.id)}
                  onChange={(val) => onTagsOnChange(val, child)}
                />
                <Label>
                  <div className={classNames(styles.tagStyle)}>
                    {child.name}
                  </div>
                </Label>
              </div>
            ))
            : null}
        </div>
      ))}
    </div>
  );

  if (isAdminWizardInstance) {
    return (
      <div className="mb-2 d-flex flex-column align-items-baseline">
        <Label>Project Technologies</Label>
        <p>Technologies list component</p>
      </div>
    )
  }

  return (
    <div className={styles.inner}>
      <h4 className={classNames("bold", styles.innerTitle)}>
        Choose Technologies
      </h4>
      <div className={classNames("bold", styles.infoText)}>
        Narrow down the list of suggested companies further by selecting technology tags.
      </div>
      <TextBox
        className={styles.search}
        onChange={handleSearchItems}
        placeholder="Search"
        value={query}
      />

      {items?.length ? (
        renderItems(filteredItems[0] ? filteredItems : items)
      ) : (
        <div className={styles.noOptions}>
          There are no technologies in this council to choose from.
        </div>
      )}

      <div className="caption small text-danger">
        {formikprops.touched['project_topics'] && formikprops.errors['project_topics']
          ? formikprops.errors['project_topics']
          : ""}
      </div>
    </div>
  );
};

export default React.memo(ProjectTopics);

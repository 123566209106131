import React from "react";

import styles from "./style.module.scss";
import { BtnType, Button } from "../../../../../primitives";
import { ModalBody, ModalFooter } from "../../../../../../application/modal";
import {
  useSliderField,
  useModelPopup,
  useVideField,
} from "../../../../../../common/hooks";

const ViewCustomField = ({ field }) => {
  const popup = useModelPopup();
  const videField = useVideField(field);
  const sliderField = useSliderField(field);

  const handleCancelClick = () => {
    popup.hide();
  };

  const displayImageField = () => (
    <img src={field.field_value} alt="" width="100%" />
  );

  const displayField = () => {
    switch (field.field_type) {
      case "image":
        return displayImageField();
      case "video":
        return videField.displayField();
      case "slide":
        return sliderField.displayField();
      default:
        return "";
    }
  };

  return (
    <div className={styles.wrapper}>
      <ModalBody>
        <div>{displayField()}</div>
      </ModalBody>
      <ModalFooter>
        <div className="mr-3">
          <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
            Close
          </Button>
        </div>
      </ModalFooter>
    </div>
  );
};

export default React.memo(ViewCustomField);

import React from "react";

import styles from "./listsMain.module.scss";
import LeftWindowComponent from "./components/leftWindow";
import RightWindowComponent from "./components/rightWindow";

const ListsWrapper = () => (
  <div className={`align-items-center ${styles.listsWrapper}`}>
    <LeftWindowComponent />
    <RightWindowComponent />
  </div>
);

export default React.memo(ListsWrapper);

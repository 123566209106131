import React, { memo, useEffect, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Popover from "@mui/material/Popover/Popover";

import styles from "./matching-tags.module.scss";

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "15px",
  },
  tagElement: {
    backgroundColor: "#f5f7fa",
    fontSize: "14px",
    padding: "5px 16px",
    borderRadius: "18px",
  },
}));

const MatchingTagsComponent = ({ item }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (item) {
      setTags([
        ...(item?.diseases_names || []),
        ...(item?.topics_names || []),
        ...(item?.industries_names || []),
        ...(item?.therapeutic_areas_names || []),
      ]);
    }
  }, [item]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={styles.tagsWrapper}>
      {tags.length
        ? tags.slice(0, 2).map((i) => (
          <div key={i} className={styles.tagElement}>
            {i}
          </div>
        ))
        : null}
      {tags.length > 2 ? (
        <div
          className="d-flex align-items-end"
          aria-owns={open ? "mouse-over-tech" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Popover
            id="mouse-over-tech"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            {tags.map((t, i, { length }) => (
              <div
                className={classNames(
                  classes.tagElement,
                  i + 1 !== length ? "mb-2" : "",
                )}
                key={t}
              >
                {t}
              </div>
            ))}
          </Popover>
          <span className={styles.showMore}>more...</span>
        </div>
      ) : null}
    </div>
  );
};

export default memo(MatchingTagsComponent);

export const COMPANY_SAVED_LIST_GET_ATTEMPT = "COMPANY_SAVED_LIST_GET_ATTEMPT";
export const companySavedListGetAttempt = (payload) => ({
  type: COMPANY_SAVED_LIST_GET_ATTEMPT,
  payload,
});

export const SET_SELECTED_LIST = "SET_SELECTED_LIST";
export const setSelectedList = (payload) => ({
  type: SET_SELECTED_LIST,
  payload,
});

export const CLEAR_CREATED_LIST = "CLEAR_CREATED_LIST";
export const clearCreatedList = (payload) => ({
  type: CLEAR_CREATED_LIST,
  payload,
});

export const COMPANY_SEARCH_CRUNCHBASE_CLEAR = "COMPANY_SEARCH_CRUNCHBASE_CLEAR";
export const searchCrunchBaseClear = (payload) => ({
  type: COMPANY_SEARCH_CRUNCHBASE_CLEAR,
  payload,
});

export const SET_SCROLL_TOP = "SET_SCROLL_TOP";
export const setScrolledTop = (payload) => ({
  type: SET_SCROLL_TOP,
  payload,
});

export const SEARCH_LISTS_BY_QUERY_ATTEMPT = "SEARCH_LISTS_BY_QUERY_ATTEMPT";
export const searchListsByQueryAttempt = (payload) => ({
  type: SEARCH_LISTS_BY_QUERY_ATTEMPT,
  payload,
});

export const SET_COMPANY_SAVED_LIST_ATTEMPT = "SET_COMPANY_SAVED_LIST_ATTEMPT";
export const setCompanySavedListAttempt = (payload) => ({
  type: SET_COMPANY_SAVED_LIST_ATTEMPT,
  payload,
});

export const COMPANY_LIST_SHARE_REMOVE_MANY_ATTEMPT = "COMPANY_LIST_SHARE_REMOVE_MANY_ATTEMPT";
export const companyListShareRemoveMany = (payload) => ({
  type: COMPANY_LIST_SHARE_REMOVE_MANY_ATTEMPT,
  payload,
});

export const COMPANY_LIST_SHARE_REMOVE_MEMBER_ATTEMPT = "COMPANY_LIST_SHARE_REMOVE_MEMBER_ATTEMPT";
export const companyListShareRemoveMember = (payload) => ({
  type: COMPANY_LIST_SHARE_REMOVE_MEMBER_ATTEMPT,
  payload,
});

export const COMPANY_LIST_SHARE_WITH_MEMBER_SUCCESS = "COMPANY_LIST_SHARE_WITH_MEMBER_SUCCESS";
export const companyListShareWithMemberSuccess = (payload) => ({
  type: COMPANY_LIST_SHARE_WITH_MEMBER_SUCCESS,
  payload,
});

export const COMPANY_LIST_SHARE_REMOVE_MEMBER_SUCCESS = "COMPANY_LIST_SHARE_REMOVE_MEMBER_SUCCESS";
export const companyListShareRemoveMemberSuccess = (payload) => ({
  type: COMPANY_LIST_SHARE_REMOVE_MEMBER_SUCCESS,
  payload,
});

export const COMPANY_LIST_SHARE_REMOVE_MANY_SUCCESS = "COMPANY_LIST_SHARE_REMOVE_MANY_SUCCESS";
export const companyListShareRemoveManySuccess = (payload) => ({
  type: COMPANY_LIST_SHARE_REMOVE_MANY_SUCCESS,
  payload,
});

export const COMPANY_LIST_SHARE_WITH_MEMBER_ATTEMPT = "COMPANY_LIST_SHARE_WITH_MEMBER_ATTEMPT";
export const companyListShareWithMember = (payload) => ({
  type: COMPANY_LIST_SHARE_WITH_MEMBER_ATTEMPT,
  payload,
});

export const CHANGE_SELECTED_LIST_NAME = "CHANGE_SELECTED_LIST_NAME";
export const changeSelectedListName = (payload) => ({
  type: CHANGE_SELECTED_LIST_NAME,
  payload,
});

export const COMPANY_SAVED_LIST_GET_SUCCESS = "COMPANY_SAVED_LIST_GET_SUCCESS";
export const companySavedListGetSuccess = (payload) => ({
  type: COMPANY_SAVED_LIST_GET_SUCCESS,
  payload,
});

export const COMPANY_SAVED_LIST_DELETE_ATTEMPT = "COMPANY_SAVED_LIST_DELETE";
export const companySavedListDelete = (payload) => ({
  type: COMPANY_SAVED_LIST_DELETE_ATTEMPT,
  payload,
});

export const COMPANY_SAVED_LIST_DELETE_SUCCESS = "COMPANY_SAVED_LIST_DELETE_SUCCESS";
export const companySavedListDeleteSuccess = (payload) => ({
  type: COMPANY_SAVED_LIST_DELETE_SUCCESS,
  payload,
});

export const COMPANY_SAVED_LIST_GET_BY_ID_ATTEMPT = "COMPANY_SAVED_LIST_GET_BY_ID_ATTEMPT";
export const companySavedListGetById = (payload) => ({
  type: COMPANY_SAVED_LIST_GET_BY_ID_ATTEMPT,
  payload,
});

export const COMPANY_SAVED_LIST_GET_BY_ID_SUCCESS = "COMPANY_SAVED_LIST_GET_BY_ID_SUCCESS";
export const companySavedListGetByIdSuccess = (payload) => ({
  type: COMPANY_SAVED_LIST_GET_BY_ID_SUCCESS,
  payload,
});

export const COMPANY_SAVED_LIST_ADD_ATTEMPT = "COMPANY_SAVED_LIST_ADD_ATTEMPT";
export const companySavedListAdd = (payload) => ({
  type: COMPANY_SAVED_LIST_ADD_ATTEMPT,
  payload,
});

export const REMOVE_COMPANY_FROM_SAVED_LIST_ATTEMPT = "REMOVE_COMPANY_FROM_SAVED_LIST_ATTEMPT";
export const removeCompanyFromSavedList = (payload) => ({
  type: REMOVE_COMPANY_FROM_SAVED_LIST_ATTEMPT,
  payload,
});

export const REMOVE_COMPANY_FROM_SAVED_LIST_SUCCESS = "REMOVE_COMPANY_FROM_SAVED_LIST_SUCCESS";
export const removeCompanyFromSavedListSuccess = (payload) => ({
  type: REMOVE_COMPANY_FROM_SAVED_LIST_SUCCESS,
  payload,
});

export const SET_COMPANY_SAVED_LIST_SUCCESS = "SET_COMPANY_SAVED_LIST_SUCCESS";
export const setCompanySavedListSuccess = (payload) => ({
  type: SET_COMPANY_SAVED_LIST_SUCCESS,
  payload,
});

export const COMPANY_SAVED_LIST_ADD_SUCCESS = "COMPANY_SAVED_LIST_ADD_SUCCESS";
export const companySavedListAddSuccess = (payload) => ({
  type: COMPANY_SAVED_LIST_ADD_SUCCESS,
  payload,
});

export const SHOULD_UPDATE_LIST_ITEMS = "SHOULD_UPDATE_LIST_ITEMS_ATTEMPT";
export const setShouldUpdateListItems = (payload) => ({
  type: SHOULD_UPDATE_LIST_ITEMS,
  payload,
});

export const SEARCH_LISTS_BY_QUERY_SUCCESS = "SEARCH_LISTS_BY_QUERY_SUCCESS";
export const searchListsByQuerySuccess = (payload) => ({
  type: SEARCH_LISTS_BY_QUERY_SUCCESS,
  payload,
});

export const SEARCH_COMPANIES_LISTS_BY_QUERY_ATTEMPT = "SEARCH_COMPANIES_LISTS_BY_QUERY_ATTEMPT";
export const searchCompaniesListsByQueryAttempt = (payload) => ({
  type: SEARCH_COMPANIES_LISTS_BY_QUERY_ATTEMPT,
  payload,
});

export const SEARCH_COMPANIES_LISTS_BY_QUERY_SUCCESS = "SEARCH_COMPANIES_LISTS_BY_QUERY_SUCCESS";
export const searchCompaniesListsByQuerySuccess = (payload) => ({
  type: SEARCH_COMPANIES_LISTS_BY_QUERY_SUCCESS,
  payload,
});

export const GET_FOLLOWING_LIST_COMPANIES_ATTEMPT = "GET_FOLLOWING_LIST_COMPANIES_ATTEMPT";
export const getFollowingListCompaniesAttempt = (payload) => ({
  type: GET_FOLLOWING_LIST_COMPANIES_ATTEMPT,
  payload,
});

export const GET_FOLLOWING_LIST_COMPANIES_SUCCESS = "GET_FOLLOWING_LIST_COMPANIES_SUCCESS";
export const getFollowingListCompaniesSuccess = (payload) => ({
  type: GET_FOLLOWING_LIST_COMPANIES_SUCCESS,
  payload,
});

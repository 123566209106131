import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { httpGet } from "common/httpCall";
import Skeleton from "@mui/material/Skeleton";
import { useSnackbar } from "notistack";
import { useWindowSize } from "common/hooks/useWindowSize";

import Loading from "../../../primitives/loading/loading.component";
import DetailsComponent from "./details";
import AreaOfFocusComponent from "./areaOfFocus";
import RelationshipOwnerComponent from "./relationshipOwner";
import ProjectsComponent from "./projects";
import CompanyContactComponent from "./companyContact";
import CompanyLeadershipComponent from "./companyLeadership";
import VideosComponent from "./videos";
import TweetsComponent from "./tweets";
import FundingComponent from "./funding";
import ActivityComponent from "./activity";

import CompanyNewsComponent from "./companyNews";
import styles from "./overview.module.scss";
import CompanyCard from "../components/companyCard/companyCard.component";
import OverviewRatings from "./ratings";
import {
  getCompanyContactsAttempt,
  getCompanyTagsAttempt,
} from "../company.action";
import TotalFundingEditModal from "../../../../common/components/totalFundingEdit";
import { useModelPopup } from "../../../../common/hooks";
import HighOrderClaimEdit from "../../../claimProfile/overview/components/hocEditIcon";
import ESGCheckboxes from "./esgCheckboxes";
import LeadershipEditModal from "../../../../common/components/leadershipEdit";
import {
  getFundingItems,
  getLeaderShipItems,
} from "../../../../common/actions/common.action";
import ThemesComponent from "./themes";

const OverviewComponent = ({ company, loading, selectedCouncil, isAdmin }) => {
  const { isMobile } = useWindowSize();
  const [tweets, setTweets] = useState(null);
  const [news, setNews] = useState(null);
  const [esgs, setEsgs] = useState([]);
  const [newsLoader, setNewsLoader] = useState(false);
  const [tweetsLoader, setTweetsLoader] = useState(false);
  const [relationShipOwners, setRelationShipOwners] = useState();
  const [relationShipOwnerPresent, setRelationShipOwnerPresent] =
    useState(false);

  const [currentCompanyId, setCurrentCompanyId] = useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();

  const {
    authReducer: { session },
    companiesReducer: {
      companyReducer: {
        companyContacts,
        leadership_members,
        funding_rounds,
        customCompanyTags,
      },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (company && company?.id && company?.id !== currentCompanyId) {
      setCurrentCompanyId(company?.id);
      dispatch(
        getCompanyContactsAttempt({
          companyId: company.id,
        })
      );
      dispatch(
        getLeaderShipItems({
          companyId: company.id,
        })
      );
      dispatch(
        getFundingItems({
          companyId: company.id,
        })
      );
      dispatch(
        getCompanyTagsAttempt({
          companyId: company.id,
        })
      );
      getSelectedEsgs(company.id);
      getTweets(company.id);
      getNews(company.id);
    }

    return () => null;
  }, [company]);

  useEffect(() => {
    setRelationShipOwners(company?.company_relationship_owners);
    if (company?.company_relationship_owners) {
      setRelationShipOwnerPresent(true);
    }
  }, [company]);

  const getSelectedEsgs = (companyId) => {
    if (selectedCouncil.name !== "Kyndryl") {
      setEsgs([]);
      return;
    }

    const url = `kyndryl/companies/${companyId}/esgs`;

    httpGet({
      apiVersion: "v2",
      call: url,
    })
      .pipe()
      .subscribe((res) => {
        setEsgs(res?.response?.option_selected || []);
      });
  };

  const getNews = (id) => {
    const url = `companies/${id}/press_references`;
    setNewsLoader(true);

    httpGet({
      call: url,
    }).subscribe(async (res) => {
      setNews(res.response);
      setNewsLoader(false);
    });
  };
  const getTweets = (id) => {
    const url = `companies/${id}/twitter_activities`;
    setTweetsLoader(true);
    httpGet({
      call: url,
    })
      .pipe()
      .subscribe((res) => {
        setTweets(res.response);
        setTweetsLoader(false);
      });
  };

  const handleTotalFundingEdit = useCallback(() => {
    popup.show({
      show: true,
      title: "Total Funding Edit",
      component: (
        <TotalFundingEditModal
          profile={company}
          fundingRounds={funding_rounds}
        />
      ),
      width: "80%",
    });
  }, [company, isAdmin, funding_rounds]);

  const handleLeadershipEdit = useCallback(() => {
    popup.show({
      show: true,
      title: "Company Leadership Edit",
      component: (
        <LeadershipEditModal profile={company} members={leadership_members} />
      ),
      width: "80%",
    });
  }, [company, isAdmin, leadership_members]);

  const handleUpdateRelationshipOwner = () => {
    httpGet({
      call: `companies/${company.id}/relationship_owners`,
    })
      .pipe()
      .subscribe((res) => {
        setRelationShipOwners(res?.response);
        enqueueSnackbar("User has been added successfully", {
          variant: "success",
        });
      });
  };

  return (
    <div className={styles.wrapper}>
      {loading ? (
        <div className="row m-0">
          <div className="col-12 col-md-8 flex-grow-1 p-0">
            <div className={styles.skeletonBlock}>
              <Skeleton height={20} />
              <div className={styles.skeletonText}>
                <Skeleton height={350} />
              </div>
            </div>
            <div className={styles.skeletonBlock}>
              <Skeleton height={20} />
              <div className={styles.skeletonText}>
                <Skeleton height={350} />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 p-0">
            <div className={styles.skeletonBlock}>
              <Skeleton height={20} />
              <div className={styles.skeletonText}>
                <Skeleton height={350} />
              </div>
            </div>
            <div className={styles.skeletonBlock}>
              <Skeleton height={20} />
              <div className={styles.skeletonText}>
                <Skeleton height={350} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="row m-0">
            <div className="col-12 col-md-8 flex-grow-1">
              <CompanyCard title="Company Information">
                <DetailsComponent
                  company={company}
                  selectedCouncil={selectedCouncil}
                />
              </CompanyCard>
              <CompanyCard title="Tags" className="mb-5 mt-5">
                <AreaOfFocusComponent
                  selectedCouncil={selectedCouncil}
                  company={company}
                  customCompanyTags={customCompanyTags}
                />
              </CompanyCard>
              <CompanyCard title="Related Projects" className="mt-5">
                <ProjectsComponent
                  sessionRole={session?.council_role}
                  company={company}
                  isAdmin={isAdmin}
                />
              </CompanyCard>
              {selectedCouncil?.themes_connected ? (
                <CompanyCard
                  title={`Related ${
                    selectedCouncil?.settings?.themes_display_name || "Themes"
                  }`}
                  className="mt-5"
                >
                  <ThemesComponent
                    company={company}
                    selectedCouncil={selectedCouncil}
                    sessionRole={session?.council_role}
                  />
                </CompanyCard>
              ) : null}
              {company &&
              company.promo_video &&
              company.promo_video !== "[]" &&
              company.promo_video[0] === "[" &&
              company.promo_video !== "" &&
              company.promo_video?.length ? (
                <CompanyCard title="Videos" className="mt-5">
                  <VideosComponent
                    company={company}
                    videoJSON={company.promo_video}
                  />
                </CompanyCard>
              ) : null}
              {funding_rounds ? (
                <CompanyCard
                  title="Funding"
                  className="mt-5"
                  rightContent={
                    !isAdmin ? null : (
                      <HighOrderClaimEdit
                        handleClick={handleTotalFundingEdit}
                      />
                    )
                  }
                >
                  <FundingComponent
                    company={company}
                    fundingRounds={funding_rounds}
                  />
                </CompanyCard>
              ) : null}
              <CompanyCard title="Company Activity" className="mb-5 mt-5">
                <ActivityComponent
                  selectedCouncil={selectedCouncil}
                  companyId={company?.id}
                />
              </CompanyCard>
              {!newsLoader ? (
                news?.length ? (
                  <CompanyCard title="Company News" className="mt-5">
                    <CompanyNewsComponent news={news} />
                  </CompanyCard>
                ) : null
              ) : (
                <Loading />
              )}
            </div>
            <div className="col-12 col-md-4">
              {selectedCouncil?.name === "Kyndryl" && esgs?.length > 0 && (
                <CompanyCard
                  title="ESG (COMMITMENT TO UN GOALS)"
                  className={`mb-5 ${isMobile ? "mt-5" : ""}`}
                >
                  <ESGCheckboxes esgs={esgs} />
                </CompanyCard>
              )}
              {company?.company_relationship_owners && (
                <CompanyCard
                  title={`Relationship Owner${
                    company?.company_relationship_owners?.length > 1 ? "s" : ""
                  }`}
                  className={`mb-5 ${
                    isMobile && selectedCouncil?.name !== "Kyndryl"
                      ? "mt-5"
                      : ""
                  }`}
                >
                  {relationShipOwnerPresent && (
                    <RelationshipOwnerComponent
                      relationships={relationShipOwners}
                      companyId={company.id}
                      handleUpdateRelationshipOwners={
                        handleUpdateRelationshipOwner
                      }
                    />
                  )}
                </CompanyCard>
              )}
              {companyContacts && (
                <CompanyCard
                  title={`Company Contact${
                    companyContacts?.length > 1 ? "s" : ""
                  }`}
                  className="mb-5"
                >
                  <CompanyContactComponent
                    companyId={company.id}
                    companyContacts={companyContacts}
                  />
                </CompanyCard>
              )}
              {company?.rating_template &&
                selectedCouncil?.name !== "Kyndryl" && (
                  <CompanyCard title="Rating" className="mb-5">
                    <OverviewRatings company={company} />
                  </CompanyCard>
                )}
              {company ? (
                <CompanyCard
                  title="Company Leadership"
                  className="mb-5"
                  rightContent={
                    !isAdmin ? null : (
                      <HighOrderClaimEdit handleClick={handleLeadershipEdit} />
                    )
                  }
                >
                  <CompanyLeadershipComponent
                    company={company}
                    members={leadership_members}
                  />
                </CompanyCard>
              ) : null}
              {!tweetsLoader ? (
                tweets?.length ? (
                  <CompanyCard title="Tweets">
                    <TweetsComponent company={company} tweets={tweets} />
                  </CompanyCard>
                ) : null
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(OverviewComponent);

import React, { useEffect, useState } from 'react';
import { ErrorMessage, FieldArray, Formik } from "formik";
import checkUniqName from "common/checkUniqName";
import checkDuplicateName from "common/checkDuplicateName";
import { useSelector } from "react-redux";
import { CheckBox, CheckType } from "modules/primitives/index";
import classNames from "classnames";

import styles from "./styles.module.scss";
import { model, validation } from "./constant";
import { BtnType, Button, Label, TextBox } from "../../../../modules/primitives";
import { Icon, smSize } from "../../../../common/icon";
import findCurrentCustomFieldGroup from "../../../findCustomFieldGroup";
import { arrayElementsDuplicateValidation } from "common/helper";
import UniqName from "../uniqName/uniqName";

const NewDropdownComponent = (props) => {
  const {
    formRef,
    handleOnSubmit,
    data,
    mode,
    adminLocation,
    disabled,
    uniqName,
    errorValidation,
    fromAdmin,
    rfiInstance,
    selectedCustomFieldGroup,
    indexOfCfGroup,
  } = props;
  const [error, setError] = useState("");
  const [multiselect, setMultiSelect] = useState(
    mode === "edit" ? data?.multiselect : model?.multiselect
  );
  const [titleError, setTitleError] = useState("");
  const [initValues, setInitValues] = useState({});
  const [changedOption, setChangedOption] = useState({
    changedValue: "",
    changedWith: "",
  });
  const {
    companiesReducer: {
      companyReducer: { newCustomFieldGroups },
    },
    adminCustomFieldsReducer: { customFieldGroups },
  } = useSelector((state) => state);
  useEffect(() => {
    if (data) {
      setInitValues({
        field_type: data.field_type,
        field_values: data.field_values,
        title: data.field_name,
        field_placeholder: data.field_placeholder,
        option_selected: data.option_selected,
        options: data.option_values,
        multiselect: data.multiselect,
      });
    }
  }, [data]);

  const onSubmit = (values, resetForm) => {
    const cfGroup =
      findCurrentCustomFieldGroup(
        selectedCustomFieldGroup,
        customFieldGroups,
        newCustomFieldGroups,
        indexOfCfGroup,
        data
      ) || [];
    if (!values.title.length) {
      const field_name = checkDuplicateName(cfGroup, "dropdown");
      values.title = field_name;
    } else if (
      !checkUniqName(values.title, cfGroup) &&
      values.title?.trim()?.toLowerCase() !== data?.field_name?.trim()?.toLowerCase()
    ) {
      setTitleError("Field name must be unique. Please rename this field");
      return;
    }
    setTitleError("");

    values.multiselect = multiselect;

    if (!values.options?.length || !values.options[0]?.length) {
      setError("Required at least one option");
      return;
    }

    if (arrayElementsDuplicateValidation(values.options, [...values.options])) {
      setError("");
      handleOnSubmit(values);
      resetForm();
    } else {
      setError("Two or more drop down options cant have same name.");
    }
    values.title = "";
    values.field_values = "";
  };

  const handleMultiCheckClick = (value, values) => {
    if (!disabled) {
      values.multiselect = value;
    }
    setMultiSelect(value);
  };

  const handleFocusValue = (event) => {
    const isSelected = initValues?.option_selected?.find((option) => option === event.target.value);
    if (isSelected) {
      setChangedOption((prevState) => {
        return {
          ...prevState,
          changedValue: event.target.value,
        };
      });
    }
  };

  const handleBlurValue = (event) => {
    if (changedOption.changedValue) {
      setChangedOption((prevState) => {
        return {
          ...prevState,
          changedWith: event.target.value,
        };
      });
    }
  };

  useEffect(() => {
    if (mode !== "edit") {
      model.title = "";
    }
  }, [mode]);

  useEffect(() => {
    if (changedOption.changedValue && changedOption.changedWith) {
      const foundedChangedValue = initValues.option_selected.find(
        (option) => option === changedOption.changedValue
      );

      if (foundedChangedValue) {
        const newSelected = initValues.option_selected.map((option) => {
          if (option === foundedChangedValue) {
            return changedOption.changedWith;
          }
          return option;
        });

        const newOptions = initValues.options.map((option) => {
          if (foundedChangedValue === option) {
            return changedOption.changedWith;
          }
          return option;
        });

        setInitValues({
          ...initValues,
          option_selected: newSelected,
          options: newOptions,
        });
      }
      setChangedOption({
        changedValue: "",
        changedWith: "",
      });
    }
  }, [changedOption]);

  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={mode === "edit" || disabled ? initValues : model}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          onSubmit(values, resetForm);
        }}
      >
        {({ handleSubmit, values, ...formikprops }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <Label>Title</Label>
            <TextBox
              type="text"
              name="title"
              placeholder="Add title"
              formProps={{ ...formikprops, values }}
              className={styles.textBoxTitle}
              disabled={rfiInstance || disabled}
            />
            <UniqName uniqName={uniqName} />
            {titleError.length > 1 && (
              <div className={styles.errorBox}>
                <sup>*</sup>
                {titleError}
              </div>
            )}
            {values.title === "" && adminLocation && errorValidation && (
              <div className={styles.errorBox}>
                <sup>*</sup>field is required
              </div>
            )}
            {!adminLocation && (
              <>
                <Label>Dropdown Instructions</Label>
                <TextBox
                  type="text"
                  name="field_placeholder"
                  placeholder="Add instructions"
                  formProps={{ ...formikprops, values }}
                  className={styles.textBoxTitle}
                />
              </>
            )}

            <Label>Dropdown Options</Label>
            <div className={styles.footerWrapper}>
              <div className={classNames(styles.formElement, styles.multiSelectWrp)}>
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={values.multiselect}
                  onChange={(value) => handleMultiCheckClick(value, values)}
                  disabled={disabled}
                  className={disabled && styles.disabledBox}
                />
                <span className={disabled && styles.disabledBox}>
                  <Label>Allow Multiselect</Label>
                </span>
              </div>
            </div>
            <FieldArray name="options">
              {({ remove, push }) => (
                <>
                  {values?.options?.map((item, index) => (
                    <div className={styles.inputContainer}>
                      <TextBox
                        key={index}
                        type="text"
                        formProps={{ ...formikprops, values }}
                        name={`options.${index}`}
                        value={item}
                        placeholder={`Option`}
                        className={styles.textBox}
                        onFocus={(e) => handleFocusValue(e)}
                        onBlur={(e) => handleBlurValue(e)}
                        disabled={disabled}
                      />
                      <div
                        onClick={() => {
                          if (!disabled) return remove(index);
                        }}
                        className={styles.deleteButton}
                      >
                        {mode === "edit" && (
                          <Icon
                            {...smSize}
                            icon="icn-button-delete"
                            className={disabled ? styles.noIcon : {}}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                  <Button
                    icon={"icn-add"}
                    type="button"
                    btn={disabled ? BtnType.DISABLED : BtnType.FRAME_LESS}
                    disabled={disabled}
                    onClick={() => push("")}
                    className={styles.optionButton}
                  >
                    Option
                  </Button>
                </>
              )}
            </FieldArray>
            {
              values?.options?.length === 0 ? (
                <ErrorMessage
                  name="options"
                  component="div"
                  className="small text-danger"
                />
              ) : (
                <ErrorMessage
                  name="options.0"
                  component="div"
                  className="small text-danger"
                />
              )
            }
            {adminLocation && (
              <>
                <Label>Prompt Text</Label>
                <TextBox
                  type="text"
                  name="field_placeholder"
                  placeholder="Add instructions"
                  disabled={disabled}
                  formProps={{ ...formikprops, values }}
                  className={styles.textBoxTitle}
                />
              </>
            )}
            <div>
              <span
                style={{
                  color: "red",
                }}
              >
                {error}
              </span>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default React.memo(NewDropdownComponent);

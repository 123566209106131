import React, {
  useRef, useState, useCallback, useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { councilUsersListGet } from "../../../../../common/actions/common.action";
import {
  addRelationShip,
  addContact,
  editOrganizationContact,
} from "../../patientOrganization.action";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../common/hooks";

import { ModalFooter, ModalBody } from "../../../../../application/modal";
import {
  Button, BtnType, Label, TextBox,
} from "../../../../primitives";
import { initModel, contactValidation } from "./constants";
import UserMultiSelectSearch from "../../../../../common/components/usersSearch";

const NewItemComponent = ({ data, type, relationships }) => {
  const popup = useModelPopup();
  const [selectedItems, setSelectedItem] = useState([]);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const formRef = useRef();

  const {
    councilReducer: { usersList, selectedCouncil },
    patientOrganizationsReducer: {
      patientOrganizationReducer: { selectedPatientOrganization },
    },
  } = useSelector((state) => state);

  const initialValues = data
    ? {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      title: data.title,
      mobile: data.mobile,
      department: data.department_or_organization,
    }
    : null;

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = () => {
    const { values } = formRef.current;
    if (type === "relationship") {
      dispatch(
        addRelationShip({
          data: {
            user_ids: selectedItems.map((user) => user.id),
          },
          council_id: selectedCouncil.id,
          patient_organization_id: selectedPatientOrganization.id,
          enqueueSnackbar,
        }),
      );
    }
    if (type === "contact") {
      if (data) {
        dispatch(
          editOrganizationContact({
            data: {
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              avatar: values.avatar,
              title: values.title,
              mobile: values.mobile,
              department_or_organization: values.department,
              patient_organization_id: selectedPatientOrganization.id,
            },
            enqueueSnackbar,
            patient_organization_contact_id:
              data.patient_organization_contact_id,
          }),
        );
      } else {
        dispatch(
          addContact({
            data: {
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              avatar: values.avatar,
              title: values.title,
              mobile: values.mobile,
              department_or_organization: values.department,
              gsk_patient_organization_id: selectedPatientOrganization.id,
            },
            patient_organization_id: selectedPatientOrganization.id,
            council_id: selectedCouncil.id,
            enqueueSnackbar,
          }),
        );
      }
    }

    popup.hide();
  };
  const handleItemSelect = (val, obj) => {
    setSelectedItem((prev) => [...val]);
  };

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(councilUsersListGet(selectedCouncil.id, enqueueSnackbar));
    }
  }, [selectedCouncil]);

  const getRelationshipManagers = useCallback(
    () => relationships.map((u) => ({
      id: u.user_id,
      full_name: u.user.full_name,
    })),
    [relationships],
  );

  return (
    <div className={styles.wrapper}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={data ? initialValues : initModel}
        validationSchema={type === "contact" && contactValidation}
        onSubmit={(values, { setSubmitting, validateForm, resetForm }) => {
          setSubmitting(true);
          handleSaveClick();
          resetForm();
        }}
      >
        {({ handleSubmit, handleChange, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <div className="row">
                <div className="col-12">
                  {type === "relationship" && usersList && usersList[0] && (
                    <UserMultiSelectSearch
                      placeholder="Add user"
                      labelField="full_name"
                      valueField="id"
                      searchBy="full_name"
                      onChange={handleItemSelect}
                      formProps={formikprops}
                      sharedUsers={getRelationshipManagers()}
                    />
                  )}
                  {type !== "relationship" && (
                    <>
                      {" "}
                      <>
                        <Label>First Name</Label>
                        <TextBox
                          type="text"
                          name="first_name"
                          placeholder="Enter first name"
                          className={styles.textBox}
                          formProps={formikprops}
                          onChange={handleChange}
                        />
                      </>
                      <>
                        <Label>Last Name</Label>
                        <TextBox
                          type="text"
                          name="last_name"
                          placeholder="Enter last name"
                          className={styles.textBox}
                          formProps={formikprops}
                          onChange={handleChange}
                        />
                      </>
                      <>
                        <Label>Email</Label>
                        <TextBox
                          type="text"
                          name="email"
                          placeholder="Enter email address"
                          className={styles.textBox}
                          formProps={formikprops}
                          onChange={handleChange}
                        />
                      </>
                      <>
                        <Label>Phone</Label>
                        <TextBox
                          type="text"
                          name="mobile"
                          placeholder="Optional"
                          className={styles.textBox}
                          formProps={formikprops}
                          onChange={handleChange}
                        />
                      </>
                      <>
                        <Label>Department</Label>
                        <TextBox
                          type="text"
                          name="department"
                          placeholder="Enter department"
                          className={styles.textBox}
                          formProps={formikprops}
                          onChange={handleChange}
                        />
                      </>
                      <>
                        <Label>Title</Label>
                        <TextBox
                          type="text"
                          name="title"
                          placeholder="Enter title"
                          className={styles.textBox}
                          formProps={formikprops}
                          onChange={handleChange}
                        />
                      </>
                    </>
                  )}
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button type="submit" btn={BtnType.REGULAR}>
                Save
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default NewItemComponent;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import styles from '../styles.module.scss';
import { DropSelect, Label } from "modules/primitives";
import { getRegions } from "modules/reporting/fordReports/store/fordReports.actions";

const FordScoreCardRegionFilter = ({ handleFilterUpdate, reducer, setNewFilters }) => {
  const dispatch = useDispatch();

  const {
    adminPlantsAndAreasReducer: { plantsAndAreas },
    fordDashboardReducer: { fordReportsReducer: { regions } }
  } = useSelector((state) => state);

  const [selectedRegions, setSelectedRegions] = useState([]);

  const getPlantsAsOptions = useMemo(() => {
    return plantsAndAreas.map(plant => ({
      id: plant.id,
      name: plant.attributes.name,
      areas: plant.attributes.areas,
    }));
  }, [plantsAndAreas]);

  useEffect(() => {
    setSelectedRegions(reducer.filters?.regions || []);
  }, [reducer.filters?.regions]);

  useEffect(() => {
    if (!regions) {
      dispatch(dispatch(getRegions()));
    }
  }, [regions]);

  const updateFilters = useCallback((value) => {
    const plantsByRegion = value
      .reduce((acc, region) => [...acc, ...region.plant_ids || []], [])
      .map(id => getPlantsAsOptions.find(plant => plant.id === id)) || [];

    setNewFilters({
      ...reducer.filters,
      regions: value,
      plants: plantsByRegion,
    });
    handleFilterUpdate({ regions: value }, reducer.tableData?.expandedKey);
  }, [reducer, getPlantsAsOptions]);

  const handleRegionsChange = (value) => {
    setSelectedRegions(value.obj);
    updateFilters(value.obj);
  };

  return (
    <div className={classNames(styles.filter)}>
      <Label>Region</Label>
      <DropSelect
        multi
        closeOnSelect
        values={selectedRegions}
        onChange={handleRegionsChange}
        options={regions || []}
        labelField="name"
        valueField="id"
        placeholder="Regions"
        searchBy="name"
      />
    </div>
  );
};

export default React.memo(FordScoreCardRegionFilter);

import { SUGGESTIONS_SUCCESS } from "../actions/common.action";
import { apiStatus } from "../httpCall";

const initState = {
  suggestions: null,
};

const suggestionsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SUGGESTIONS_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          suggestions: response,
        };
      }
    }
    default:
      return {
        ...state,
      };
  }
};

export default suggestionsReducer;

export const SOLUTIONS_GET_ATTEMPT = "SOLUTIONS_GET_ATTEMPT";
export const solutionsGetAttempt = (payload) => {
  return {
    type: SOLUTIONS_GET_ATTEMPT,
    payload,
  };
};
export const SOLUTIONS_GET_SUCCESS = "SOLUTIONS_GET_SUCCESS";
export const solutionsGetAttemptSuccess = (payload) => ({
  type: SOLUTIONS_GET_SUCCESS,
  payload,
});

export const SOLUTION_ADD_ATTEMPT = "SOLUTION_ADD_ATTEMPT";
export const solutionsPostAttempt = (payload) => ({
  type: SOLUTION_ADD_ATTEMPT,
  payload,
});

export const SOLUTION_ADD_SUCCESS = "SOLUTION_ADD_SUCCESS";
export const solutionsAddSuccess = (payload) => ({
  type: SOLUTION_ADD_SUCCESS,
  payload,
});
export const SOLUTION_EDIT_ATTEMPT = "SOLUTION_EDIT_ATTEMPT";
export const solutionsEditAttempt = (payload) => ({
  type: SOLUTION_EDIT_ATTEMPT,
  payload,
});
export const SOLUTION_EDIT_SUCCESS = "SOLUTION_EDIT_SUCCESS";
export const solutionsEditSuccess = (payload) => ({
  type: SOLUTION_EDIT_SUCCESS,
  payload,
});
export const SOLUTION_DELETE_ATTEMPT = "SOLUTION_DELETE_ATTEMPT";
export const solutionsDeleteAttempt = (payload) => ({
  type: SOLUTION_DELETE_ATTEMPT,
  payload,
});

export const SOLUTION_DELETE_SUCCESS = "SOLUTION_DELETE_SUCCESS";
export const solutionsDeleteSuccess = (payload) => ({
  type: SOLUTION_DELETE_SUCCESS,
  payload,
});

export const SOLUTIONS_SEARCH_ATTEMPT = "SOLUTIONS_SEARCH_ATTEMPT";
export const solutionsSearchAttempt = (payload) => ({
  type: SOLUTIONS_SEARCH_ATTEMPT,
  payload,
});

export const SOLUTIONS_SEARCH_SUCCESS = "SOLUTIONS_SEARCH_SUCCESS";
export const solutionsSearchSuccess = (payload) => ({
  type: SOLUTIONS_SEARCH_SUCCESS,
  payload,
});

export const SOLUTION_PROJECT_DELETE_ATTEMPT =
  "SOLUTION_PROJECT_DELETE_ATTEMPT";
export const solutionsProjectDeleteAttempt = (payload) => ({
  type: SOLUTION_PROJECT_DELETE_ATTEMPT,
  payload,
});

export const SOLUTION_PROJECT_DELETE_SUCCESS =
  "SOLUTION_PROJECT_DELETE_SUCCESS";
export const solutionsProjectDeleteSuccess = (payload) => ({
  type: SOLUTION_PROJECT_DELETE_SUCCESS,
  payload,
});

export const SOLUTIONS_STATS_GET_ATTEMPT = "SOLUTIONS_STATS_GET_ATTEMPT";
export const solutionsStatsGetAttempt = (payload) => {
  return {
    type: SOLUTIONS_STATS_GET_ATTEMPT,
    payload,
  };
};
export const SOLUTIONS_STATS_GET_SUCCESS = "SOLUTIONS_STATS_GET_SUCCESS";
export const solutionsStatsGetAttemptSuccess = (payload) => ({
  type: SOLUTIONS_STATS_GET_SUCCESS,
  payload,
});

export const SOLUTION_BY_ID_GET_ATTEMPT = "SOLUTION_BY_ID_GET_ATTEMPT";
export const solutionsByIdGetAttempt = (payload) => {
  return {
    type: SOLUTION_BY_ID_GET_ATTEMPT,
    payload,
  };
};
export const SOLUTION_BY_ID_GET_SUCCESS = "SOLUTION_BY_ID_GET_SUCCESS";
export const solutionsByIdGetSuccess = (payload) => ({
  type: SOLUTION_BY_ID_GET_SUCCESS,
  payload,
});

export const CLEAR_SELECTED_SOLUTION_PROJECT_ATTEMPT =
  "CLEAR_SELECTED_SOLUTION_PROJECT_ATTEMPT";
export const clearSelecredSolutionProjectsAction = (payload) => ({
  type: CLEAR_SELECTED_SOLUTION_PROJECT_ATTEMPT,
  payload,
});

import React from 'react';

const ItemErrorHandler = ({ item }) => {
  if (!item.hasError) return null;

  return (
    <div className="alert alert-secondary mt-3" role="alert">
      {item.errorType === 'disconnection' && 'Sorry, you were disconnected. Please Try Again'}
      {(item.errorType === 'timeout' || item.errorType === 'serverError') &&
        'I don\'t have an answer. Please try another question.'}
    </div>
  )
};

export default React.memo(ItemErrorHandler);

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";

import ReactTable from "react-table";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import classNames from "classnames";
import { format } from "date-fns";
import Tooltip from "@mui/material/Tooltip";

import { useSnackbar } from "notistack";
import { getRelationshipStatuses } from "application/app.action";
import isItLargeDevice from "common/isItLargeDevice";
import { useModelPopup } from "common/hooks";
import AddToThemeComponent from "../../../themesCommon/addCompanyToTheme";
import { ROW_OPTIONS } from "./constants";
import styles from "./styles.module.scss";
import CompanyInline from "../../../../primitives/companyInline/companyInline.component";
import { MoreOptions, Pagination } from "../../../../primitives";
import {
  pipelineEditStageAttempt,
  pipelineGetInvestStagesAttempt,
  pipelineSearchCompaniesAttempt,
} from "../pipelineTab.action";
import { themesCompanyDeleteAttempt } from "../../themesOverviewTab/overviewTab.action";
import checkThemePipelinePermissions from "../../../../../common/checkThemePipelinePermissions";
import setMarginForFirstCell from "../../../../../common/setMarginForFirstCell";

const width = window.innerWidth > 1200 ? 800 : 600;
const isItLarge = isItLargeDevice();
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fafafa",
    boxShadow: "5px 5px 4px 0 rgba(0, 0, 0, 0.14)",
    color: "rgba(0, 0, 0, 0.87)",
    width,
    minWidth: width,
    fontSize: theme.typography.pxToRem(15),
    border: "1px solid #cccccc",
    // padding: "15px 20px 10px",
  },
}))(Tooltip);
const HeaderButton = ({
  name,
  setSortValues,
  sortVal,
  sortOrd,
  sortAttr,
  pinned,
  stylesObj,
}) => {
  let showStylzedIcon;
  let iconStylesDesc = { opacity: 0.5 };
  let iconStylesAsc = { opacity: 0.5 };
  const firstHeaderCell = useRef();
  useEffect(() => {
    if (firstHeaderCell.current && !isItLarge && pinned) {
      firstHeaderCell.current.width = "120px";
    }
  }, [firstHeaderCell.current]);

  if (sortAttr === sortVal) {
    showStylzedIcon = true;
  }
  if (showStylzedIcon && sortOrd === "asc") {
    iconStylesAsc = { opacity: 1 };
  } else if (showStylzedIcon) {
    iconStylesDesc = { opacity: 1 };
  }

  return (
    <div
      className={classNames(pinned && styles.pinnedHeaderContainer)}
      style={stylesObj}
      ref={firstHeaderCell}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setSortValues(sortVal);
        }}
        onScroll={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className={classNames(
          styles.headerButton,
          pinned && styles.pinnedHeader
        )}
      >
        <span className={styles.reactHeader}> {name} </span>{" "}
        <div className={styles.icons}>
          <span style={iconStylesAsc}>▲</span>
          <span style={iconStylesDesc}>▼</span>
        </div>
      </div>
    </div>
  );
};
const PipelineTable = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { id, investMode } = props;
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { currentThemeData },
      themesOverviewReducer: { loadingCompany, companyList },
      themesPipelineReducer: {
        loadingInvestCompanies,
        companyPiplineList,
        shouldUpdateStages,
        loadingPipelineTable,
        themesCompaniesPages,
      },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const [sortAtrr, setSortAtrr] = useState("company_name");
  const [sortOrd, setSortOrd] = useState("asc");
  const [companyListForTable, setCompanyListForTable] = useState([]);
  const tableRef = useRef();
  const history = useHistory();
  const popup = useModelPopup();
  const [pageSetting, setPageSetting] = useState({
    pageSize: 20,
    current: 1,
    hideOnSinglePage: true,
    showTitle: false,
    total: themesCompaniesPages?.total || 0,
  });

  const [isInitialRender, setIsInitialRender] = useState(true);

  const tdStyles = {
    display: "flex",
    justifyContent: "center",
    color: "black",
    fontSize: "14px",
    fontWeight: "800",
    fontFamily: "lato-light",
  };
  const secondTdStyles = {
    marginLeft: isItLarge ? 120 : 150,
    // paddingLeft: 144,
    maxWidth: 125,
  };
  const pinnedStyles = {
    position: "absolute",
    backgroundColor: "#ffffff",
    height: 42,
    maxWidth: 155,
    minWidth: 150,
    with: 150,
    paddingLeft: 10,
    borderLeft: "1px solid rgba(0,0,0,0.1)",
    borderRight: "1px solid rgba(0,0,0,0.05)",
  };
  const firstTdStyle = {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 10,
    maxWidth: 155,
    minWidth: 150,
    with: 150,
    borderRight: "1px solid rgba(0,0,0,0.02)",
  };

  const handleMoreOptionClick = (val, company) => {
    if (val === 0) {
      popup.show({
        title: `Add To ${
          selectedCouncil?.settings?.themes_display_name_singular || "Theme"
        }`,
        height: "1000",
        width: "540",
        component: (
          <AddToThemeComponent
            company={company}
            selectedCouncil={selectedCouncil}
            enqueueSnackbar={enqueueSnackbar}
            deleteFromCurrentTheme
            currentThemeId={id}
          />
        ),
      });
    }
    if (val === 1) {
      dispatch(
        themesCompanyDeleteAttempt({
          id,
          companyId: company.company_id,
          enqueueSnackbar,
        })
      );
    }
  };

  const getStageStyles = (status) => {
    const getStageDetails = selectedCouncil.council_relationship_stages.find(
      (s) => s.name === status
    );
    return {
      backgroundColor: getStageDetails?.colors?.background || "",
      color: getStageDetails?.colors?.text || "",
    };
  };
  const setSortValues = (attrSelected) => {
    setIsInitialRender(false);
    setSortAtrr((prev) => {
      if (prev === attrSelected) {
        setSortOrd((prevOrd) => {
          if (prevOrd === "desc") {
            return "asc";
          }
          return "desc";
        });
      }
      return attrSelected;
    });
  };
  const searchCompanies = () => {
    const data = {
      sort_order: sortOrd,
      sort_attribute: sortAtrr,
      page: pageSetting?.current,
      search: {
        query: "*",
        theme_id: currentThemeData.id,
      },
    };
    dispatch(
      pipelineSearchCompaniesAttempt({
        enqueueSnackbar,
        data,
      })
    );
  };

  useEffect(() => {
    if (pageSetting?.current >= 1) {
      const data = {
        sort_order: sortOrd,
        sort_attribute: sortAtrr,
        page: pageSetting?.current,
        search: {
          query: "*",
          theme_id: currentThemeData.id,
        },
      };
      dispatch(
        pipelineSearchCompaniesAttempt({
          enqueueSnackbar,
          data,
        })
      );
    }
  }, [pageSetting?.current]);

  const handleDropdownSelect = (e, company) => {
    e.stopPropagation();

    if (
      checkThemePipelinePermissions(
        e.target.value,
        session?.council_role,
        selectedCouncil?.name,
        session?.decision_maker_access,
        company?.council_relationship_stage_name
      )
    ) {
      const tagObj = selectedCouncil.council_relationship_stages.find(
        (tag) => e.target.value === tag.name
      );

      const data = {
        theme_company: {
          council_relationship_stage_id: tagObj?.customId || tagObj?.id || null,
          position: 1,
        },
      };

      dispatch(
        pipelineEditStageAttempt({
          data,
          themeId: id,
          companyId: company.company_id,
          productId: company.is_product ? company.product_id : null,
        })
      );
    } else {
      enqueueSnackbar("You are not allowed to move companies in this stage ", {
        variant: "error",
      });
    }
  };

  const parseDate = (date) => {
    let parcedDate = "-";
    try {
      if (date === null) return "-";
      if (date) {
        parcedDate = format(new Date(date), " MMM do, yyyy, p");
      }
    } catch (error) {
      console.log("---------error in date parse", date);
    }
    return parcedDate;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <HeaderButton
            name="Company"
            setSortValues={setSortValues}
            sortVal="company_name"
            sortOrd={sortOrd}
            sortAttr={sortAtrr}
            pinned={!isItLarge}
          />
        ),
        accessor: "name",
        width: 150,
        maxWidth: 155,
        minWidth: 150,
        sortable: false,
        fixed: "left",
        expander: false,
        resizable: isItLarge,
        Cell: ({ original }) => (
          <div>
            {/* // <HtmlTooltip
              //   title={
              //     <>
              //       <NewCompanyItemResultHoverComponent
              //         {...{
              //           company: {
              //             total_fundings_sum:
              //               original?.total_funding?.substring(1) || "",
              //             description: original.description || "-",
              //             logo: original.isProduct
              //               ? original?.company.logo
              //               : original.logo || "-",
              //             web_url: original.web_url || "-",
              //             founded_at: original?.year_founded || "-",
              //             num_employees_enum: original?.employee_range || "-",
              //             following: original?.following,
              //             council_company_score: original?.council_company_score,
              //             name: original?.name,
              //             id: original.isProduct
              //               ? original?.company.id
              //               : original?.company_id || original?.id,
              //             slug: original.isProduct
              //               ? original?.company.slug
              //               : original?.slug || original?.id,
              //             isProduct: original.isProduct,
              //             productId: original.id,
              //             productSlug: original.slug,
              //             location: original?.location || "",
              //           },
              //         }}
              //         {...{ history }}
              //       />
              //     </>
              //   }
              //   PopperProps={{
              //     disablePortal: true,
              //   }}
              //   placement="top"
              //   interactive
              // > */}
            <CompanyInline
              avatar={original?.logo}
              companyName={original?.name}
              companyId={original?.company_id}
              company={original}
              customCompanyNameStyles={`${styles.customCompanyNameStyles}`}
              customIconSize={28}
              pinned={!isItLarge}
              isProduct={original.is_product}
            />
            {/* </HtmlTooltip> */}
          </div>
        ),
        style: !isItLarge
          ? {
              cursor: "pointer",

              ...tdStyles,
              ...firstTdStyle,
              ...pinnedStyles,
            }
          : {
              cursor: "pointer",
              ...tdStyles,
              ...firstTdStyle,
            },
      },
      {
        Header: () => (
          <HeaderButton
            name="Website"
            setSortValues={setSortValues}
            sortVal="company_web_url"
            sortOrd={sortOrd}
            sortAttr={sortAtrr}
            stylesObj={
              !isItLarge
                ? {
                    // border: "1px solid green",
                    marginLeft: 20,
                  }
                : {}
            }
          />
        ),
        resizable: isItLarge,

        width: isItLarge ? 220 : 190,
        Cell: ({ original }) => {
          return original.is_product ? (
            <>
              {original.product_link && (
                <a
                  className={styles.linkStyles}
                  target="_blank"
                  href={original.product_link}
                  rel="noreferrer"
                >
                  {original.product_link}
                </a>
              )}
            </>
          ) : (
            <a
              className={styles.linkStyles}
              target="_blank"
              href={original.web_url}
              rel="noreferrer"
            >
              {original.web_url}
            </a>
          );
        },
        style: !isItLarge
          ? {
              cursor: "pointer",
              ...tdStyles,
              ...secondTdStyles,
            }
          : {
              cursor: "pointer",
              ...tdStyles,
            },
        sortable: false,
        // resizable: false,
      },
      {
        Header: () => (
          <HeaderButton
            name="Location"
            setSortValues={setSortValues}
            sortVal="location_country"
            sortOrd={sortOrd}
            sortAttr={sortAtrr}
          />
        ),
        resizable: isItLarge,

        accessor: "location",
        width: 140,
        style: {
          cursor: "pointer",
          ...tdStyles,
        },
        sortable: false,
      },
      {
        Header: () => (
          <HeaderButton
            name="Year Founded"
            setSortValues={setSortValues}
            sortVal="founded"
            sortOrd={sortOrd}
            sortAttr={sortAtrr}
          />
        ),
        resizable: isItLarge,

        accessor: "year_founded",
        width: 120,
        style: {
          cursor: "pointer",
          ...tdStyles,
        },
        sortable: false,
      },
      {
        Header: () => (
          <HeaderButton
            name="Employees"
            setSortValues={setSortValues}
            sortVal="num_employees_enum"
            sortOrd={sortOrd}
            sortAttr={sortAtrr}
          />
        ),
        accessor: "employee_range",
        width: 150,
        style: {
          cursor: "pointer",
          ...tdStyles,
        },
        sortable: false,
      },
      {
        Header: () => (
          <HeaderButton
            name="Score"
            setSortValues={setSortValues}
            sortVal="council_company_score"
            sortOrd={sortOrd}
            sortAttr={sortAtrr}
          />
        ),
        resizable: isItLarge,

        accessor: "council_company_score",
        width: 90,
        style: {
          fontSize: "12px",
          cursor: "pointer",
          ...tdStyles,
        },
        sortable: false,
      },
      {
        Header: () => (
          <HeaderButton
            name="Updated By"
            setSortValues={setSortValues}
            sortVal="council_relationship_stage_last_changed_at"
            sortOrd={sortOrd}
            sortAttr={sortAtrr}
          />
        ),
        resizable: isItLarge,

        accessor: "council_relationship_stage_last_changed_at",
        width: 150,
        sortable: false,
        Cell: (props) => (
          <p className={styles.companiesNumber}>
            {props.original?.council_relationship_stage_last_changed_by || ""}
            <br />
            {parseDate(
              props.original?.council_relationship_stage_last_changed_at
            )}{" "}
          </p>
        ),
        style: {
          fontSize: "12px",
          textAlign: "left",
          cursor: "pointer",
          ...tdStyles,
        },
      },
      {
        Header: () => (
          <HeaderButton
            name="Stage"
            setSortValues={setSortValues}
            sortVal="council_relationship_stage_position"
            sortOrd={sortOrd}
            sortAttr={sortAtrr}
          />
        ),
        resizable: isItLarge,

        accessor: "status",
        width: 200,
        show: !investMode,
        sortable: false,
        Cell: (props) => (
          <Select
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              handleDropdownSelect(e, props.original);
            }}
            value={
              props.original.council_relationship_stage_name
                ? props.original.council_relationship_stage_name
                : "none"
            }
            className={
              props.original.council_relationship_stage_name === null
                ? styles.tagStyles
                : styles.detailsRelationship
            }
            style={getStageStyles(
              props.original?.council_relationship_stage_name
            )}
          >
            <MenuItem
              style={
                !checkThemePipelinePermissions(
                  "none",
                  session?.council_role,
                  selectedCouncil?.name,
                  session?.decision_maker_access,
                  props.original.council_relationship_stage_name || null
                )
                  ? { background: "#929ba4", color: "white" }
                  : {}
              }
              key="none"
              value="none"
            >
              Traction Match
            </MenuItem>
            {selectedCouncil.council_relationship_stages.map((item) => (
              <MenuItem
                key={item.slug}
                value={item.name}
                style={
                  !checkThemePipelinePermissions(
                    item.name,
                    session?.council_role,
                    selectedCouncil?.name,
                    session?.decision_maker_access,
                    props.original.council_relationship_stage_name
                  )
                    ? { background: "#929ba4", color: "white" }
                    : {}
                }
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        ),
        style: {
          fontSize: "12px",
          cursor: "pointer",
          overflow: "visible",
          ...tdStyles,
        },
      },
      {
        Header: "",
        id: "options",
        accessor: (props) => {
          return (
            <MoreOptions
              options={ROW_OPTIONS}
              onClick={(val) => handleMoreOptionClick(val, props)}
              className={styles.moreOptWrp}
            />
          );
        },
        sortable: false,
        style: {
          fontSize: "12px",
          cursor: "pointer",
          zIndex: 1000,
          overflow: "visible",
          // ...tdStyles,
        },
        width: 35,
        resizable: isItLarge,

        show: ["council_administrator", "ttp_administrator"].includes(
          session?.council_role
        ),
      },
    ],
    [investMode, sortAtrr, sortOrd]
  );
  useEffect(() => {
    if (selectedCouncil) {
      dispatch(getRelationshipStatuses());
    }
  }, [selectedCouncil]);

  useEffect(() => {
    searchCompanies();
  }, [isInitialRender, sortOrd, sortAtrr]);
  useEffect(() => {
    if (investMode) {
      dispatch(
        pipelineGetInvestStagesAttempt({
          themeId: id,
          enqueueSnackbar,
        })
      );
    }
  }, [investMode]);
  useEffect(() => {
    if (companyList) {
      setCompanyListForTable(companyList);
    }
  }, [companyList]);
  useEffect(() => {
    if (companyPiplineList) {
      setCompanyListForTable(companyPiplineList);
      setPageSetting((prev) => ({
        ...prev,
        total: themesCompaniesPages.total,
        page: 1,
      }));
    }
  }, [companyPiplineList]);
  useEffect(() => {
    if (shouldUpdateStages) {
      searchCompanies();
    }
  }, [shouldUpdateStages]);

  useEffect(() => {
    if (tableRef.current && !isItLarge) {
      tableRef.current.addEventListener("scroll", (event) => {
        const scrollX = event.target.scrollLeft;
        if (scrollX > 10) {
          setMarginForFirstCell("-29px", tableRef);
        } else {
          setMarginForFirstCell("0px", tableRef);
        }
      });
    }
  }, [tableRef.current]);

  useEffect(() => {
    if (!isItLarge) {
      const table = tableRef.current.children[0].children[0];
      const reactTable = table.children[0];
      const rtHeader = [...reactTable.children[0].children];
      rtHeader.forEach((headerGroup) => {
        const firstCell = headerGroup.children[0];
        const resizer = firstCell.children[1];
        if (resizer) {
          resizer.style.right = "0px";
        }
      });
    }
  });
  const handlePagingChange = (page) => {
    setPageSetting((prev) => ({
      ...prev,
      current: page,
    }));
  };
  return (
    <div ref={tableRef} className={styles.container}>
      <div className={styles.flexWrapper}>
        <ReactTable
          // resizable={false} // !isItLarge
          showPagination={false}
          minRows={10}
          pageSize={20}
          className={styles.table}
          data={
            investMode
              ? companyListForTable.filter(
                  (com) => com.theme_investment_stage_id
                )
              : companyListForTable
          }
          columns={columns}
          loading={investMode ? loadingInvestCompanies : loadingPipelineTable}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                style: {
                  cursor: "pointer",
                },
              };
            }
            return {};
          }}
        />
        <div className={styles.paginationCon}>
          <Pagination {...pageSetting} onChange={handlePagingChange} />
        </div>
      </div>
    </div>
  );
};
export default PipelineTable;

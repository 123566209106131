export const UPDATE_PROJECT_REPLICATIONS = "UPDATE_PROJECT_REPLICATIONS";
export const updateProjectReplications = (payload) => ({
  type: UPDATE_PROJECT_REPLICATIONS,
  payload,
});

export const UPDATE_REPLICATION_STATUS = "UPDATE_REPLICATION_STATUS";
export const updateReplicationStatus = (payload) => ({
  type: UPDATE_REPLICATION_STATUS,
  payload,
});

export const GET_PROJECT_REPLICATION_STATUS = "GET_PROJECT_REPLICATION_STATUS";
export const getProjectReplicationStatus = (payload) => ({
  type: GET_PROJECT_REPLICATION_STATUS,
  payload,
});

export const GET_PROJECT_REPLICATION_STATUS_SUCCESS =
  "GET_PROJECT_REPLICATION_STATUS_SUCCESS";
export const getProjectReplicationStatusSuccess = (payload) => ({
  type: GET_PROJECT_REPLICATION_STATUS_SUCCESS,
  payload,
});

export const GET_PROJECT_REPLICATIONS = "GET_PROJECT_REPLICATIONS";
export const getProjectReplications = (payload) => ({
  type: GET_PROJECT_REPLICATIONS,
  payload,
});

export const GET_PROJECT_REPLICATIONS_SUCCESS =
  "GET_PROJECT_REPLICATIONS_SUCCESS";
export const getProjectReplicationsSuccess = (payload) => ({
  type: GET_PROJECT_REPLICATIONS_SUCCESS,
  payload,
});

export const GET_PROJECT_TAB_PLANTS = "GET_PROJECT_TAB_PLANTS";
export const getProjectTabPlants = (payload) => ({
  type: GET_PROJECT_TAB_PLANTS,
  payload,
});

export const GET_PROJECT_TAB_PLANTS_SUCCESS = "GET_PROJECT_TAB_PLANTS_SUCCESS";
export const getProjectTabPlantsSuccess = (payload) => ({
  type: GET_PROJECT_TAB_PLANTS_SUCCESS,
  payload,
});

export const UPDATE_PROJECT_FIELD_GROUPS = "UPDATE_PROJECT_FIELD_GROUPS";
export const updateProjectFieldGroups = (payload) => ({
  type: UPDATE_PROJECT_FIELD_GROUPS,
  payload,
});

export const GET_PROJECT_FIELD_GROUPS = "GET_PROJECT_FIELD_GROUPS";
export const getProjectFieldGroups = (payload) => ({
  type: GET_PROJECT_FIELD_GROUPS,
  payload,
});

export const GET_PROJECT_FIELD_GROUPS_SUCCESS =
  "GET_PROJECT_FIELD_GROUPS_SUCCESS";
export const getProjectFieldGroupsSuccess = (payload) => ({
  type: GET_PROJECT_FIELD_GROUPS_SUCCESS,
  payload,
});

export const PROJECT_RATING_SET = "PROJECT_RATING_SET";
export const projectRatingSet = (payload) => ({
  type: PROJECT_RATING_SET,
  payload,
});

export const PROJECT_RATING_SET_SUCCESS = "PROJECT_RATING_SET_SUCCESS";
export const projectRatingSetSuccess = (payload) => ({
  type: PROJECT_RATING_SET_SUCCESS,
  payload,
});

export const PROJECT_SET_DOWNLOAD_PARAMS = "PROJECT_SET_DOWNLOAD_PARAMS";
export const projectSetDownloadParams = (payload) => ({
  type: PROJECT_SET_DOWNLOAD_PARAMS,
  payload,
});

export const PROJECT_SELECTED_SET = "PROJECT_SELECTED_SET";
export const projectSet = (payload) => ({
  type: PROJECT_SELECTED_SET,
  payload,
});

// TODO remove that and use new projects search
export const PROJECT_LIST_GET_ATTEMPT = "PROJECT_LIST_GET_ATTEMPT";
export const projectListGet = (payload) => ({
  type: PROJECT_LIST_GET_ATTEMPT,
  payload,
});
export const PROJECT_LIST_GET_SUCCESS = "PROJECT_LIST_GET_SUCCESS";
export const projectListGetSuccess = (payload) => ({
  type: PROJECT_LIST_GET_SUCCESS,
  payload,
});

export const PROJECT_GET_ATTEMPT = "PROJECT_GET_ATTEMPT";
export const projectGet = (payload) => ({ type: PROJECT_GET_ATTEMPT, payload });
export const PROJECT_GET_SUCCESS = "PROJECT_GET_SUCCESS";
export const projectGetSuccess = (payload) => ({
  type: PROJECT_GET_SUCCESS,
  payload,
});
export const CLEAR_SELECTED_PROJECT = "CLEAR_SELECTED_PROJECT";
export const clearSelectedProject = (payload) => ({
  type: CLEAR_SELECTED_PROJECT,
  payload,
});

export const PROJECT_ACTIVITIES_GET_ATTEMPT = "PROJECT_ACTIVITIES_GET_ATTEMPT";
export const projectActivitiesGet = (payload) => ({
  type: PROJECT_ACTIVITIES_GET_ATTEMPT,
  payload,
});
export const PROJECT_ACTIVITIES_GET_SUCCESS = "PROJECT_ACTIVITIES_GET_SUCCESS";
export const projectActivitiesGetSuccess = (payload) => ({
  type: PROJECT_ACTIVITIES_GET_SUCCESS,
  payload,
});

export const CLEAR_NEW_PROJECT = "CLEAR_NEW_PROJECT";
export const clearNewProject = (payload) => ({
  type: CLEAR_NEW_PROJECT,
  payload,
});

export const PROJECT_PUT_ATTEMPT = "PROJECT_PUT_ATTEMPT";
export const projectPut = (payload) => ({ type: PROJECT_PUT_ATTEMPT, payload });
export const PROJECT_PUT_SUCCESS = "PROJECT_PUT_SUCCESS";
export const projectPutSuccess = (payload) => ({
  type: PROJECT_PUT_SUCCESS,
  payload,
});

export const PROJECT_DELETE_ATTEMPT = "PROJECT_DELETE_ATTEMPT";
export const projectDelete = (payload) => ({
  type: PROJECT_DELETE_ATTEMPT,
  payload,
});
export const PROJECT_DELETE_SUCCESS = "PROJECT_DELETE_SUCCESS";
export const projectDeleteSuccess = (payload) => ({
  type: PROJECT_DELETE_SUCCESS,
  payload,
});

export const PROJECT_DELETE_MEMBER_ATTEMPT = "PROJECT_DELETE_MEMBER_ATTEMPT";
export const projectDeleteMember = (payload) => ({
  type: PROJECT_DELETE_MEMBER_ATTEMPT,
  payload,
});
export const PROJECT_DELETE_MEMBER_SUCCESS = "PROJECT_DELETE_MEMBER_SUCCESS";
export const projectDeleteMemberSuccess = (payload) => ({
  type: PROJECT_DELETE_MEMBER_SUCCESS,
  payload,
});

export const PROJECT_MAKE_MEMBER_OWNER_ATTEMPT =
  "PROJECT_MAKE_MEMBER_OWNER_ATTEMPT";
export const makeMemberOwner = (payload) => ({
  type: PROJECT_MAKE_MEMBER_OWNER_ATTEMPT,
  payload,
});

export const PROJECT_MAKE_MEMBER_OWNER_SUCCESS =
  "PROJECT_MAKE_MEMBER_OWNER_SUCCESS";
export const makeMemberOwnerSuccess = (payload) => ({
  type: PROJECT_MAKE_MEMBER_OWNER_SUCCESS,
});

export const CREATE_PROJECT_DISCUSSION = "CREATE_PROJECT_DISCUSSION";
export const createProjectDiscussion = (payload) => ({
  type: CREATE_PROJECT_DISCUSSION,
  payload,
});
export const CREATE_PROJECT_DISCUSSION_SUCCESS =
  "CREATE_PROJECT_DISCUSSION_SUCCESS";
export const createProjectDiscussionSuccess = (payload) => ({
  type: CREATE_PROJECT_DISCUSSION_SUCCESS,
  payload,
});

export const RESET_PROJECTS_LOADING = "RESET_PROJECTS_LOADING";
export const resetProjectsLoading = (payload) => ({
  type: RESET_PROJECTS_LOADING,
  payload,
});

export const CLEAR_PROJECT_LIST = "CLEAR_PROJECT_LIST";
export const clearProjectList = (payload) => ({
  type: CLEAR_PROJECT_LIST,
  payload,
});

export const ERROR_CATCH = "ERROR_CATCH";
export const errorCatch = (payload) => ({
  type: ERROR_CATCH,
  payload,
});

export const PROJECT_SETTINGS_GET_ATTEMPT = "PROJECT_SETTINGS_GET_ATTEMPT";
export const projectSettingsGet = (payload) => ({
  type: PROJECT_SETTINGS_GET_ATTEMPT,
  payload,
});

export const PROJECT_SETTINGS_GET_SUCCESS = "PROJECT_SETTINGS_GET_SUCCESS";
export const projectSettingsGetSuccess = (payload) => ({
  type: PROJECT_SETTINGS_GET_SUCCESS,
  payload,
});

export const PROJECT_SETTINGS_PUT_ATTEMPT = "PROJECT_SETTINGS_PUT_ATTEMPT";
export const projectSettingsPut = (payload) => ({
  type: PROJECT_SETTINGS_PUT_ATTEMPT,
  payload,
});

export const PROJECT_SETTINGS_PUT_SUCCESS = "PROJECT_SETTINGS_PUT_SUCCESS";
export const projectSettingsPutSuccess = (payload) => ({
  type: PROJECT_SETTINGS_PUT_SUCCESS,
  payload,
});

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import styles from "./ratings.module.scss";
import SliderComponent from "./slider/slider.component";
import {
  companyRatingSaveAttempt,
  companyRatingEditAttempt,
  companyAddRatingNotes,
  companyGetRatingNotes,
  companyDeleteRatingNotes,
  companyEditRatingNotes,
} from "../../company.action";

import { setUpdateChartListCompanyDetails } from "../../../companyMain/companyMain.action";

import { Icon, mdSize } from "../../../../../common/icon";
import RatingAddNotesComponent from "./ratingAddNotes/ratingAddNotes.component";
import CompanyNote from "../../components/companyNote/companyNote.component";
import { BtnType, Button } from "../../../../primitives";

const RatingsComponent = (props) => {
  const { company } = props;
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: {
        companyRatingNotes,
        loadingNotes,
        shouldUpdateRatingNotes,
      },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedNote, setSelectedNote] = useState();
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    setShowFeedback(companyRatingNotes?.length > 0);
  }, [companyRatingNotes]);

  const handleOnChange = (obj, category) => {
    const { value } = obj;

    const payload = {
      company_id: company.id,
      council_id: selectedCouncil.id,
      user_id: session.id,
      rating_template_id: company.rating_template?.rating_template_id,
      rating_category_id: category.rating_category_id,
      universal_rating_id: category.user_universal_rating?.universal_rating_id,
      value,
      enqueueSnackbar,
    };

    dispatch(
      setUpdateChartListCompanyDetails({
        companyId: company.id,
        newCompanyData: {
          council_company_score: value,
        },
      }),
    );

    if (!category.user_universal_rating) {
      dispatch(companyRatingSaveAttempt(payload));
    } else {
      dispatch(companyRatingEditAttempt(payload));
    }
  };

  const addNewNote = (value) => {
    const data = {
      note: {
        description: value.note,
      },
    };
    dispatch(
      companyAddRatingNotes({
        companyId: company.id,
        data,
        enqueueSnackbar,
      }),
    );
  };

  const deleteNote = (noteId) => {
    dispatch(
      companyDeleteRatingNotes({
        noteId,
        enqueueSnackbar,
        companyId: company.id,
      }),
    );
  };

  const handleMoreOptionClick = (value, note) => {
    switch (value) {
      case "remove":
        deleteNote(note.id);
        break;
      case "edit":
        setSelectedNote({
          id: note.id,
          note: note.description.replace(/(<br>)/g, "\n"),
        });
        break;
      default:
        break;
    }
  };

  const handleAddEdit = (value) => {
    // edit mode
    if (value.id) {
      const data = {
        note: {
          description: value.note,
        },
      };
      dispatch(
        companyEditRatingNotes({
          companyId: company.id,
          data,
          enqueueSnackbar,
          noteId: value.id,
        }),
      );
      return;
    }

    // add new
    addNewNote(value);
  };

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        companyGetRatingNotes({
          companyId: company.id,
          enqueueSnackbar,
        }),
      );
    }
  }, [selectedCouncil]);

  useEffect(() => {
    if (shouldUpdateRatingNotes) {
      dispatch(
        companyGetRatingNotes({
          companyId: company.id,
          enqueueSnackbar,
        }),
      );
    }
  }, [shouldUpdateRatingNotes]);

  return (
    <div className={styles.taskInfoWrp}>
      <div className={styles.scoreWrp}>
        <div className={styles.title}>Your Score</div>
        <div className={styles.value}>
          {company?.user_total_rating > 0 ? company?.user_total_rating?.toFixed(2) : 'No rating'}
        </div>
      </div>
      <div className={styles.container}>
        {company?.rating_template?.rating_categories.map((category) => {
          if (!category.parent_id) {
            return (
              <Accordion
                className={styles.accordion}
                key={category.rating_category_id}
              >
                <AccordionSummary
                  expandIcon={
                    category.sub_categories[0] ? (
                      <Icon
                        icon="icn-table-down-chevron"
                        {...mdSize}
                        className={styles.iconColor}
                      />
                    ) : (
                      <div className={styles.stubIcon} />
                    )
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={
                    category.sub_categories[0]
                      ? styles.accordionSummary
                      : styles.colll
                  }
                >
                  <div
                    className={styles.sliderContainer}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <SliderComponent
                      rating={{
                        ...category,
                        id: category.rating_category_id,
                        name: category.name,
                        max: company?.rating_template?.max_scale,
                      }}
                      key={category.rating_category_id}
                      value={category.user_universal_rating?.value}
                      onChange={(e) => {
                        handleOnChange(e, category);
                      }}
                    />
                  </div>
                </AccordionSummary>
                {category.sub_categories?.length ? (
                  <AccordionDetails>
                    <div className={styles.childRatingContainer}>
                      {category.sub_categories?.map((subCategory) => (
                        <div
                          className={styles.sliderContainer}
                          key={subCategory.rating_category_id}
                        >
                          <SliderComponent
                            rating={{
                              ...subCategory,
                              id: subCategory.rating_category_id,
                              name: subCategory.name,
                              max: company?.rating_template?.max_scale,
                              customStyles: {
                                trackStyle: {
                                  backgroundColor: "#7cc1e4",
                                },
                                handleStyle: {
                                  backgroundColor: "lightgray",
                                },
                              },
                            }}
                            value={
                              subCategory.user_universal_rating?.value
                            }
                            onChange={(e) => handleOnChange(e, subCategory)}
                          />
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                ) : null}
              </Accordion>
            );
          }
          return null;
        })}
        {showFeedback && (
          <>
            <hr className={styles.divider} />
            <div className={styles.notesWrp}>
              <label className={styles.label}>Feedback</label>
              <div className={styles.notesCon}>
                {loadingNotes ? (
                  <span className="d-flex flex-1 justify-content-center">
                    Loading feedback...
                  </span>
                ) : companyRatingNotes?.length ? (
                  companyRatingNotes.map((note, idx) => (
                    <CompanyNote
                      key={note.id}
                      note={note}
                      handleMoreOptionClick={(val) => handleMoreOptionClick(val, note)}
                      currentUser={session}
                      isLast={companyRatingNotes.length - 1 === idx}
                    />
                  ))
                ) : (
                  <span className="d-flex flex-1 justify-content-center mb-3">
                    There's no feedback yet.
                  </span>
                )}
              </div>
            </div>
          </>
        )}
        {showFeedback ? (
          <div className={styles.addNoteWrp}>
            <RatingAddNotesComponent
              selectedNote={selectedNote}
              onAddEdit={handleAddEdit}
            />
          </div>
        ) : (
          <div className="d-flex justify-content-end p-3">
            <Button btn={BtnType.REGULAR} onClick={() => setShowFeedback(true)}>
              Add Feedback
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(RatingsComponent);

import React from "react";
import { ModalBody, ModalFooter } from "application/modal/index";
import { useModelPopup } from "../../../../common/hooks";
import { Label, Button } from "../../../primitives";
// import styles from "./syncMappingsInfoModal.module.scss";

const SyncMappingInfoModal = ({ message }) => {
  const popup = useModelPopup();

  return (
    <>
      <div>
        <ModalBody>
          <Label>
            <h5>{message}</h5>
          </Label>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => popup.hide()}>Close</Button>
        </ModalFooter>
      </div>
    </>
  );
};

export default React.memo(SyncMappingInfoModal);

import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";

import {
  getKPIsClientPlants,
  getKPIsTableData,
  toggleKPIsTable,
} from "modules/reporting/fordReports/kpis/store/kpis.actions";
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import LoadingComponent from "modules/primitives/loading/loading.component";
import genericStyles from '../../styles.module.scss';
import styles from './styles.module.scss';

const FordClientPlants = ({ clientPlants, filters, tableData, downloadIsActive }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!clientPlants.data) {
      const makeFilters = makeActualScoreCardFilterData(filters);
      const payload = {
        enqueueSnackbar,
        data: makeFilters,
      };

      dispatch(getKPIsClientPlants(payload))
    }
  }, []);

  const cardHasExpandedTable = useMemo(() => tableData?.expandedKey === 'projects_by_plant',
    [tableData?.expandedKey]);

  const handleShowOrHideTable = useCallback(() => {
    if (tableData?.expandedKey !== 'projects_by_plant') {
      const filtersData = makeActualScoreCardFilterData(filters);
      dispatch(getKPIsTableData({
        reducerKey: 'projects_by_plant',
        data: {
          ...filtersData,
          section: 'projects_by_plant',
        },
      }));

      return;
    }

    dispatch(toggleKPIsTable({
      reducerKey: 'projects_by_plant',
    }));
  }, [tableData, filters]);

  const makeColumnCardStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      border: '1px solid #cbd2d9',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px',
      height: '100%',
      padding: '10px',
    }
  }, [downloadIsActive]);

  const makeTitleStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      color: '#616e7c',
      fontSize: '18px',
      fontFamily: 'lato-regular',
      paddingTop: '10px',
    }
  }, [downloadIsActive]);

  return (
    <div style={makeColumnCardStylesForPdf} className={classNames(genericStyles.columnCard, 'px-0 pb-0 mb-0')}>
      {
        clientPlants.fetching ? (
          <LoadingComponent customText="Getting data..." />
        ) : (
          <div className="d-flex flex-column">
            <span style={makeTitleStylesForPdf} className={classNames(genericStyles.columnCardTitle, 'ml-2')}>Projects/Plant</span>
            <div className={styles.numbersTableWrapper}>
              <div className="d-flex py-2 border-bottom">
                <div className="col-7 small">Plant</div>
                <div className="col-5 text-right small">No. Projects</div>
              </div>
              <div className={styles.tableBody}>
                {
                  clientPlants.data.map(plant => (
                    <div className={styles.tableRow} key={plant.id}>
                      <div
                        className={classNames('d-flex align-items-center col-7 pl-1 pr-2 py-1 small', styles.tableRowWithBorderRight)}>{plant.name}</div>
                      <div className="col-5 px-3 text-right py-2">{plant.value}</div>
                    </div>
                  ))
                }
              </div>
            </div>
            {
              !downloadIsActive && (
                <div className="d-flex justify-content-center mt-2 mb-1">
                  <span className={genericStyles.showHideSourceData} onClick={() => handleShowOrHideTable()}>
                    {cardHasExpandedTable ? 'HIDE SOURCE DATA' : 'SHOW SOURCE DATA'}
                  </span>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default React.memo(FordClientPlants);

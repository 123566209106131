import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCookie } from "common/helper";

import { Loading } from "modules/primitives/index";
import adminFuseHoc from "common/HOC/adminFuseHoc";
import PanelBlockComponent from "./panelBlock";
import styles from "./admin.module.scss";

const AdminComponent = () => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const role = getCookie("council_role_");
    if (role !== "ttp_administrator" && role !== "council_administrator") {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <div className={styles.container}>
      <PanelBlockComponent
        icon="icn-subnav-company"
        tabName="Companies"
        link="/admin/companies"
      />
      <PanelBlockComponent
        icon="icn-profile"
        tabName="Members"
        link="/admin/members"
      />
      <PanelBlockComponent
        icon="icn-form-move"
        tabName="Teams"
        link="/admin/teams"
      />
      <PanelBlockComponent
        icon="icn-subnav-ratings-fill"
        tabName="Ratings"
        link="/admin/ratings?tab=company"
      />
      <PanelBlockComponent
        icon="icn-view-list"
        tabName="Projects"
        link="/admin/projects"
      />
      {selectedCouncil && selectedCouncil.name === "GSK" && (
        <PanelBlockComponent
          icon="icn-subnav-overview"
          tabName="Organizations"
          link="/admin/organizations"
          mode="organization"
        />
      )}
      <PanelBlockComponent
        icon="price-tag"
        tabName="Tags"
        link="/admin/tags?tab=topics"
      />
      <PanelBlockComponent
        icon="icn-subnav-documents"
        tabName="Applicants"
        link="/admin/applicants"
      />
      <PanelBlockComponent
        icon="cogs"
        tabName="Integrations"
        link="/admin/integrations"
      />
      {selectedCouncil &&
        selectedCouncil.name !== "GSK" &&
        selectedCouncil?.settings?.ideas_connected !== false && (
          <PanelBlockComponent
            icon="bullhorn"
            tabName="Ideas"
            link="/admin/ideas?tab=ideas"
          />
        )}
      <PanelBlockComponent
        icon="clipboard-file"
        tabName="Agreements"
        link="/admin/agreements"
      />
      {selectedCouncil?.name === "Kyndryl" ? (
        session?.email === "erick@tractiontechpartners.com" ||
        session?.email === "warrick.cramer@kyndryl.com" ||
        session?.email === "jim.reedy@kyndryl.com" ||
        session?.email === "stephan.marquart@kyndryl.com" ? (
          <PanelBlockComponent
            icon="storage"
            tabName="Custom Fields"
            link="/admin/custom-fields"
          />
        ) : null
      ) : (
        <PanelBlockComponent
          icon="storage"
          tabName="Custom Fields"
          link="/admin/custom-fields"
        />
      )}
      {/* <PanelBlockComponent
        icon="folder-download"
        tabName="Data Exports"
        link="/admin/data_exports"
      /> */}
      <PanelBlockComponent
        icon="icn-subnav-overview"
        tabName={selectedCouncil?.settings?.themes_display_name || "Themes"}
        link="/admin/themes"
      />
      {selectedCouncil?.name === "Ford" && (
        <PanelBlockComponent
          icon="cogs"
          tabName="Plants + Areas"
          link="/admin/plants-and-areas"
        />
      )}
    </div>
  );
};
export default adminFuseHoc(AdminComponent);

import checkForThemes from "./checkForThemes";

const homexFilter = (menu, council_role, session, selectedCouncil) => {
  if (
    session &&
    (council_role === "viewer")
  ) {
    return menu.filter((menuItem) => {
      if (menuItem.mobileOnly) {
        return false;
      }
      if (menuItem.path === "/reporting") {
        return false;
      }
      if (menuItem.path === "/patient_organizations") {
        return false;
      }
      if (menuItem.path === "/admin") {
        return false;
      }
      if (menuItem.claimProfileOnly) {
        return false;
      }

      return checkForThemes(menuItem.path, selectedCouncil);
    });
  }
  return menu.filter((menuItem) => {
    if (menuItem.mobileOnly) {
      return false;
    }
    if (menuItem.path === "/reporting") {
      return true;
    }
    if (menuItem.path === "/patient_organizations") {
      return false;
    }
    if (menuItem.claimProfileOnly) {
      return false;
    }

    return checkForThemes(menuItem.path, selectedCouncil);
  });
};
export default homexFilter;

export const KYNDRYL_STATUSES = [
  {
    id: "approved",
    name: "Approved",
  },
  {
    id: "paused",
    name: "Pause",
  },
  {
    id: "stopped",
    name: "Stop",
  },
  {
    id: "scouting",
    name: "Scouting",
  },
  {
    id: "solved",
    name: "Solved",
  },
];

import React, { memo, useState } from "react";
import classNames from "classnames";
import styles from "./simpleSearch.module.scss";
import Icon from "../../../common/icon/icon.component";
import { smSize } from "../../../common/icon/icon.constants";

const SimpleSearchComponent = ({
  searchValue = "",
  onSearchValueUpdate,
  placeholder,
  withoutMargin,
  customIcon,
}) => {
  const [val, setVal] = useState(searchValue);

  const handleOnChange = (e) => {
    const query = e.currentTarget.value;
    setVal(query);
    onSearchValueUpdate(query);
  };

  return (
    <div
      tabIndex="0"
      className={classNames(
        styles.searchWrp,
        withoutMargin ? "" : styles.searchWithMargin
      )}
    >
      {customIcon ? <Icon {...smSize} icon={customIcon} /> : null}

      <input
        value={val}
        className={styles.searchText}
        placeholder={placeholder || "Search"}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default memo(SimpleSearchComponent);

import React, { useEffect, useState } from "react";
import classnames from "classnames";

import { MoreOptions } from "modules/primitives";
import { ACTIVITY_OPTIONS_WITHOUT_DELETE } from "common/constants.js";
import styles from "./styles.module.scss";
import { Icon, smSize } from "../../../../../../common/icon";

export const currencySymbol = [
  { name: "USD", symbol: "$" },
  { name: "EUR", symbol: "€" },
  { name: "GBP", symbol: "£" },
  { name: "CHF", symbol: "CHF" }
];

const NumberComponent = ({ item, handleEditClick, handleUpdateForm, disabled }) => {
  const [fieldValue, setFieldValue] = useState('');
  const [showField, setShowField] = useState(false);

  useEffect(() => {
    if (item.field_value?.length) {
      setFieldValue(item.field_value);
    } else {
      setShowField(true);
    }
  }, [item]);

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }
  };

  const title = (item) => {
    if (item.field_name) {
      return item.field_name;
    }
    if (!item.option_values[0]) {
      return "Number";
    }
    return "Currency";
  };

  function numberWithCommas(x = "") {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleSaveClick = () => {
    // setShowField(false);
    handleUpdateForm({
      ...item,
      field_value: fieldValue,
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{title(item)}</h5>
          {
            item?.field_placeholder?.length ? (
              <span className="text-muted small">{item.field_placeholder}</span>
            ) : null
          }
        </div>
        {!disabled && (
          <div className={styles.rightWrp}>
            <div className={styles.colMore}>
              <MoreOptions
                options={ACTIVITY_OPTIONS_WITHOUT_DELETE}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            </div>
          </div>
        )}
      </div>

      {
        item.field_value?.length && !showField ? (
          <div className="d-flex">
            <p className={classnames("small", styles.description)}>
              {item.option_selected
                && item.field_value
                && currencySymbol.find(({ name }) => name === item.option_values[0])
                  ?.symbol}
              {item.option_values[0] ? numberWithCommas(item?.field_value || "") : item?.field_value || ""}
            </p>
            <div className="cursor-pointer ml-2" onClick={() => setShowField(true)}>
              <Icon
                {...smSize}
                icon="icn-edit"
              />
            </div>
          </div>
        ) : null
      }

      {
        showField ? (
          <div className="w-75 d-flex align-items-center">
            {item?.option_selected && currencySymbol?.find(({ name }) => name === item.option_values[0])?.symbol}
            <input
              type="number"
              min="0"
              className="w-100 border mr-3 ml-2 small p-2"
              value={fieldValue}
              placeholder={item?.field_placeholder}
              onChange={evt => setFieldValue(evt.target.value)}
              onBlur={handleSaveClick}
            />
          </div>
        ) : null
      }
    </div>
  );
};
export default React.memo(NumberComponent);

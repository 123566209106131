import { combineReducers } from "redux";

import patientOrganizationMainReducer from "./patientOrganizationMain/patientOrganizationMain.reducer";
import patientOrganizationReducer from "./patientOrganization/patientOrganization.reducer";

const patientOrganizationsReducer = combineReducers({
  patientOrganizationMainReducer,
  patientOrganizationReducer,
});

export default patientOrganizationsReducer;

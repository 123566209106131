import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import NewItemComponent from "common/popups/newItem";
import { useSnackbar } from "notistack";
import { checkViewerRole } from "common/checkers/viewerChecker";
import Tooltip from "@mui/material/Tooltip";
import styles from "./details.module.scss";
import { useModelPopup } from "../../../../common/hooks";
import { Button, BtnType } from "../../../primitives";
import TextComponent from "../../../../common/components/customFields/text";
import CheckListComponent from "./checkList";
import DocumentComponent from "../../../../common/components/customFields/document";
import VideoComponent from "../../../../common/components/customFields/videos";
import ImageComponent from "../../../../common/components/customFields/images";
import DropDownComponent from "../../../../common/components/customFields/dropDown";
import LinkComponent from "../../../../common/components/customFields/link";
import SlidesComponent from "../../../../common/components/customFields/slides";
import DateComponent from "../../../../common/components/customFields/date";
import NumberComponent from "../../../../common/components/customFields/number";
import {
  patientOrganizationDeleteDetailsFields,
  patientOrganizationDetailsGet,
  patientOrganizationPutDetailsFields,
  getPatientOrganizationGroupFields,
  addCustomFields,
  setCustomFieldLoadingOrg,
} from "../patientOrganization.action";
import ConfirmActionComponent from "../../../../common/components/confirmAction/confirmAction.component";

const PresentationComponent = (props) => {
  const dispatch = useDispatch();
  const { patientOrganizationId } = props;
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const {
    authReducer: { session },
    patientOrganizationsReducer: {
      patientOrganizationReducer: {
        customFieldGroups,
        patientOrganizationDetails,
        customFieldAdded,
        customFieldRemoved,
        updateCustomFields,
      },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  });

  const handleAddClick = (groupId) => {
    popup.show({
      title: "Add Text",
      height: String(window.innerHeight),
      width: String(window.innerWidth / 1.5),
      component: (
        <NewItemComponent
          handleSubmitDetail={handleSubmitDetail}
          custom_field_group_id={groupId}
        />
      ),
    });
  };

  const handleEditClick = (item) => {
    popup.show({
      title: "Edit Item",
      height: String(window.innerHeight),
      width: String(window.innerWidth / 1.5),
      component: (
        <NewItemComponent
          data={item}
          mode="edit"
          handleSubmitDetail={handleSubmitDetail}
          handleDeleteClick={handleDeleteClick}
        />
      ),
    });
  };

  const handleSubmitDetail = async (
    values,
    initObject,
    custom_field_group_id,
    selectedCheck
  ) => {
    if (!initObject) {
      dispatch(
        addCustomFields({
          patient_organization_id: patientOrganizationId,
          council_id: selectedCouncil.id,
          data: {
            user_id: session.id,
            field_value: values.field_value,
            name: values.name,
            field_name: values?.title?.trim() || "",
            field_values: values.field_values || [],
            field_placeholder: values.instruction,
            field_type: values.type,
            option_selected: [],
            multiselect: values.multiselect,
            custom_field_group_id,
            option_values: values.options,
            document_date: values.document_date,
            expiration_date: values.expiration_date,
            documents_attributes: values.documents_attributes || [],
          },
          selectedCheck,
          enqueueSnackbar,
        })
      );
      return null;
    }

    dispatch(
      setCustomFieldLoadingOrg(initObject.patient_organization_custom_field_id)
    );

    await dispatch(
      patientOrganizationPutDetailsFields({
        patient_organization_id: patientOrganizationId,
        council_id: selectedCouncil.id,
        patient_organization_custom_field_id:
          initObject.patient_organization_custom_field_id,
        data: {
          user_id: session.id,
          field_value: values.field_value,
          field_values: values.field_values,
          field_name: values?.title?.trim(),
          field_placeholder: values.instruction,
          field_type: values.type,
          option_selected: values.option_selected || [],
          multiselect: values.multiselect,
          custom_field_group_id: initObject.custom_field_group_id,
          option_values: values.options,
          options_score_selected: values.options_score_selected,
          document_date: values.document_date,
          expiration_date: values.expiration_date,
          documents_attributes: values.documents_attributes || [],
        },
        selectedCheck,
        enqueueSnackbar,
      })
    );

    // TODO: This should be removed once it will be fixed on backend.
    //  Backend is  processing data a little bit slow.
    await new Promise((resolve) => setTimeout(resolve, 2000));

    await dispatch(
      patientOrganizationDetailsGet({
        patientOrganizationId: patientOrganizationId && patientOrganizationId,
        council_id: selectedCouncil.id,
        items: 50,
        page: 1,
        enqueueSnackbar,
      })
    );
  };

  useEffect(() => {
    if (!selectedCouncil) return;

    if (patientOrganizationId) {
      dispatch(
        getPatientOrganizationGroupFields({
          id: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
      dispatch(
        patientOrganizationDetailsGet({
          patientOrganizationId: patientOrganizationId && patientOrganizationId,
          council_id: selectedCouncil.id,
          items: 50,
          page: 1,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil, updateCustomFields]);

  useEffect(() => {
    if (customFieldAdded || customFieldRemoved) {
      dispatch(
        patientOrganizationDetailsGet({
          patientOrganizationId: patientOrganizationId && patientOrganizationId,
          council_id: selectedCouncil.id,
          items: 50,
          page: 1,
          enqueueSnackbar,
        })
      );
    }
  }, [customFieldAdded, customFieldRemoved]);

  const handleDeleteClick = (item) => {
    popup.show({
      title: "You are about to permanently delete this item",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmActionComponent
          alertText="Are you sure?"
          confirmBtnName="Delete"
          onConfirm={() => handleDeleteConfirm(item)}
        />
      ),
    });
  };

  const handleDeleteConfirm = (item) => {
    dispatch(
      patientOrganizationDeleteDetailsFields({
        patient_organization_id: patientOrganizationId,
        council_id: selectedCouncil.id,
        patient_organization_custom_field_id:
          item.patient_organization_custom_field_id,
        data: {},
        enqueueSnackbar,
      })
    );

    popup.hide();
  };

  const drowCriteria = (type, item) => {
    switch (type) {
      case "text":
        return (
          <TextComponent
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            item={item}
            blockFunctionality={blockFunctionality}
            handleSubmitDetail={handleSubmitDetail}
          />
        );
      case "dropdown":
        return (
          <DropDownComponent
            handleEditClick={handleEditClick}
            item={item}
            handleSubmitDetail={handleSubmitDetail}
            blockFunctionality={blockFunctionality}
          />
        );
      case "link":
        return (
          <LinkComponent
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            item={item}
            blockFunctionality={blockFunctionality}
            handleSubmitDetail={handleSubmitDetail}
          />
        );
      case "checklist":
        return (
          <CheckListComponent
            handleDeleteClick={handleDeleteClick}
            handleSubmitDetail={handleSubmitDetail}
            handleEditClick={handleEditClick}
            item={item}
            blockFunctionality={blockFunctionality}
          />
        );
      case "document":
        return (
          <DocumentComponent
            handleDeleteClick={handleDeleteClick}
            item={item}
            handleEditClick={handleEditClick}
            handleSubmitDetail={handleSubmitDetail}
            blockFunctionality={blockFunctionality}
          />
        );
      case "video":
        return (
          <VideoComponent
            handleDeleteClick={handleDeleteClick}
            item={item}
            handleEditClick={handleEditClick}
            blockFunctionality={blockFunctionality}
            handleSubmitDetail={handleSubmitDetail}
          />
        );
      case "image":
        return (
          <ImageComponent
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            item={item}
            handleSubmitDetail={handleSubmitDetail}
            blockFunctionality={blockFunctionality}
          />
        );
      case "date":
        return (
          <DateComponent
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            item={item}
            handleSubmitDetail={handleSubmitDetail}
          />
        );
      case "number":
        return (
          <NumberComponent
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            item={item}
            handleSubmitDetail={handleSubmitDetail}
            blockFunctionality={blockFunctionality}
          />
        );
      case "slide": {
        return (
          <SlidesComponent
            handleDeleteClick={handleDeleteClick}
            item={item}
            handleEditClick={handleEditClick}
            blockFunctionality={blockFunctionality}
          />
        );
      }
      default:
        break;
    }
  };
  return (
    <section className={styles.wrapper}>
      {customFieldGroups.map((group) => (
        <div key={group.custom_field_group_id} className="container mt-3 p-0">
          <div className={styles.listWrp}>
            <div className={styles.titleWrp}>
              <h4>{group.name}</h4>
              {!blockFunctionality ? (
                <Button
                  btn={BtnType.REGULAR}
                  className={styles.button}
                  icon="icn-add"
                  onClick={() => handleAddClick(group.custom_field_group_id)}
                >
                  Add item
                </Button>
              ) : (
                <Tooltip
                  title="You have View-Only access. To Add Custom field, please ask your Admin to upgrade your account to Standard access."
                  placement="top"
                >
                  <div className={styles.button}>
                    <Button
                      btn={BtnType.DISABLED}
                      className={styles.button}
                      icon="icn-add"
                    >
                      Add item
                    </Button>
                  </div>
                </Tooltip>
              )}
            </div>
            {patientOrganizationDetails[0] &&
              patientOrganizationDetails.map((item) => {
                if (item.custom_field_group.name === group.name) {
                  return (
                    <div
                      key={
                        item?.patient_organization_custom_field_id
                          ? item.patient_organization_custom_field_id
                          : item.company_custom_field
                      }
                      className={styles.itemContainer}
                    >
                      {drowCriteria(item.field_type, item)}
                    </div>
                  );
                }
              })}
          </div>
        </div>
      ))}
    </section>
  );
};

export default React.memo(PresentationComponent);

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ModalFooter, ModalBody } from "../../../../application/modal";
import {
  Button, BtnType, Label, DropSelect,
} from "../../../primitives";
import {
  companySavedListGetAttempt,
  companySavedListAdd,
  setCompanySavedListAttempt,
  clearCreatedList,
  setSelectedList,
} from "../../store/lists.actions";
import styles from "./addToList.module.scss";
import SaveSearchComponent from "../../../companies/companyMain/rightWindow/saveSearch";
import { useModelPopup } from "../../../../common/hooks";
import LoadingComponent from "modules/primitives/loading/loading.component";

const AddToListComponent = ({
  enqueueSnackbar,
  companyId,
  getMyOnlyLists,
  multipleAdd,
  companyIds,
  fromCompany,
}) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [selected, setSelected] = useState("");
  const [options, setOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();
  const {
    councilReducer: { selectedCouncil },
    listsMainReducer: { savedCompaniesLists, createdListId, shouldSavedListUpdate },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil?.name === "Kyndryl" && options?.length) {
      const hasSpecificListObj = options.find(
        (l) => l.name?.toLowerCase() === "selection committee"
      );

      if (hasSpecificListObj) {
        setSelected(hasSpecificListObj);
      }
    }
  }, [selectedCouncil, options]);

  useEffect(() => {
    if (createdListId) {
      dispatch(setSelectedList({ id: createdListId }));
      history.push(`/list/${createdListId}`);
      popup.hide();
    }
    dispatch(clearCreatedList());
  }, [createdListId, shouldSavedListUpdate]);

  useEffect(() => {
    dispatch(
      companySavedListGetAttempt({
        getMyOnlyLists,
        enqueueSnackbar,
        items: fromCompany && 100,
        isDropdown: true
      })
    );
  }, []);

  useEffect(() => {
    if (savedCompaniesLists && savedCompaniesLists.length > 0) {
      setOptions(savedCompaniesLists);
    }
  }, [savedCompaniesLists]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = () => {
    if (selected) {
      setIsSubmitting(true);
      const data = {
        listId: selected.id,
        companyId: multipleAdd ? companyIds : companyId,
        enqueueSnackbar,
        multipleAdd,
        history,
        cb: () => {
          setIsSubmitting(false);
        }
      };

      dispatch(companySavedListAdd(data));
      if (!createdListId) {
        handleCancelClick();
      }
    }
  };

  const handleCreateNewList = (input) => {
    const data = {
      name: input.name,
      council_id: selectedCouncil.id,
      privateProp: true,
      company_ids: multipleAdd ? companyIds : [companyId],
      enqueueSnackbar,
    };
    dispatch(setCompanySavedListAttempt(data));
  };

  const handleOptionSelect = (values) => {
    setSelected(values.obj[0]);
  };

  const handleSaveHide = () => {
    popup.hide();
    dispatch(clearCreatedList());
  };

  return (
    <div className={styles.addCompanyWrp}>
      <ModalBody>
        <Label>Select or Create New List</Label>
        <DropSelect
          onChange={handleOptionSelect}
          placeholder="Select list or create a new one by typing here"
          labelField="name"
          valueField="id"
          searchBy="name"
          createProp
          searchable
          clearOnBlur
          createNewLabel="Add company to a new list called {search}"
          clearOnSelect
          onCreateNew={(input) => handleCreateNewList(input)}
          options={options}
          SelecedValues={
            selected
              ? [
                  {
                    name: selected.name,
                    id: selected.id,
                  },
                ]
              : null
          }
        />
        <div className={styles.createNew}>
          <Button
            btn={BtnType.FRAME_LESS}
            icon="icn-add"
            onClick={() => {
              popup.show({
                title: "Create New List",
                component: (
                  <SaveSearchComponent
                    handleSaveHide={handleSaveHide}
                    mode="empty"
                    companyIds={multipleAdd ? companyIds : [companyId]}
                  />
                ),
              });
            }}
          >
            Create
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        {
          isSubmitting ? (
            <LoadingComponent customText="Processing..." />
          ) : (
            <>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button type="submit" btn={BtnType.REGULAR} onClick={handleSaveClick}>
                Save
              </Button>
            </>
          )
        }
      </ModalFooter>
    </div>
  );
};

export default AddToListComponent;

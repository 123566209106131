import React, { useEffect, useState } from "react";
import { Icon, smSize } from "common/icon";
import { useSelector } from "react-redux";
import DragAndDrop from "common/components/dragAndDrop";

import styles from "./styles.module.scss";
import classnames from "classnames";
import useS3FileUpload from "common/hooks/s3FileUplodar.hook";
import LinearProgressWithLabel from "../../../../../../common/components/linearProgressWithLabel";

const ApplicantDocument = ({ formProps }) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const [documents, setDocuments] = useState([]);
  const [uploadValues, setUploadValues] = useState([]);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const { fileUpload } = useS3FileUpload();

  useEffect(() => {
    if (formProps?.values?.documents_attributes?.length) {
      setDocuments(formProps.values.documents_attributes)
    }
  }, [formProps?.values?.documents_attributes]);

  const uploadHandler = async (e, action) => {
    if (forbiddenFiles.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }

    let fileList = documents || [];

    setUploadValues((items) => {
      const currentFiles = [...items];

      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name,
      }));
      return [...currentFiles, ...newFiles];
    });

    files.forEach(async (file) => {
      if (file.size <= 100000000) {
        const pathname = `srm/${selectedCouncil.id}/applicants_forms/`;
        await fileUpload(file, pathname, setUploadValues, file.name).then(
          (res) => {
            if (res?.location) {
              const docObject = {
                uri: res.location,
                name: res.name,
                council_id: selectedCouncil.id,
                added_by_id: session.id
              }
              fileList = [...fileList, docObject];
              setDocuments(fileList);
              formProps.setFieldValue('documents_attributes', fileList);
            }
          },
        );
      } else {
        // show some toaster alert
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === file.name,
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: file.name,
          };
          return currentProgress;
        });
      }
    });

    e.target.value = "";
  };

  const handleDelete = (doc, index) => {
    const docsToBeSentToBE = documents.map((d, idx) => {
      if (idx === index) {
        return {
          ...d,
          _destroy: true,
        }
      }

      return d;
    });

    formProps.setFieldValue('documents_attributes', docsToBeSentToBE);
    setDocuments(docsToBeSentToBE);
  };

  return (
    <div className={classnames(styles.documentsContainer, 'd-flex flex-column')}>
      {
        documents?.length ? (
          <div className="mt-3">
            {documents.filter(d => !d._destroy).map((document, index) => (
              <div
                key={index}
                className="d-flex justify-content-between"
              >
                <div className="d-flex align-items-center w-50">
                  <Icon
                    {...smSize}
                    icon="icn-subnav-documents"
                    className={styles.tileIcon}
                  />
                  <span>{document.name}</span>
                </div>
                <div className="cursor-pointer" onClick={() => handleDelete(document, index)}>
                  <span>x</span>
                </div>
              </div>
            ))}
          </div>
        ) : null
      }
      <div>
        {" "}
        {uploadValues?.length
          ? uploadValues.map(
            (bar, index) => bar
              && bar?.progress !== "NOT_ALLOWED" && (
                <div className={styles.loaderContainer}>
                  <div className={styles.loaderNamingWrp}>
                    {" "}
                    <div className={styles.fileName}>
                      {uploadValues[index]?.name}
                    </div>
                    <LinearProgressWithLabel
                      mode={
                        uploadValues[index]?.progress
                        === "FAILED" && "error"
                      }
                      value={
                        uploadValues[index]?.progress === "FAILED"
                          ? 100
                          : uploadValues[index]?.progress || 0
                      }
                    />
                  </div>
                </div>
              ),
          )
          : null}
      </div>
      <DragAndDrop
        uploadHandler={uploadHandler}
        customText={'drag and drop documents here'}
      />
    </div>
  );
};
export default React.memo(ApplicantDocument);

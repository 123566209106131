import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import Appsignal from "@appsignal/javascript";

import Input from "./input";
import SelectInput from "./select-input";
import CheckBoxInput from "./checkbox-input";
import styles from "./form-generator.module.scss";
import File from "./file";
import TextArea from "./textArea";
import { Icon, smSize } from "../../../common/icon";
import Loading from "../loading/loading.component";

const shouldDownload = (name = "") => {
  const nameToLower = name.toLowerCase();

  return (
    nameToLower.includes(".doc") ||
    nameToLower.includes(".docx") ||
    nameToLower.includes(".dotx") ||
    nameToLower.includes(".dot") ||
    nameToLower.includes(".rtf") ||
    nameToLower.includes(".txt") ||
    nameToLower.includes(".xlxs") ||
    nameToLower.includes(".xlsx") ||
    nameToLower.includes(".xls") ||
    nameToLower.includes(".csv") ||
    nameToLower.includes(".ppt") ||
    nameToLower.includes(".pdf")
  );
};

const appSignal =
  process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "prod" ? new Appsignal(
    {
      key:
        process.env.REACT_APP_ENV === "dev"
          ? process.env.REACT_APP_APPSIGNAL_DEV
          : process.env.REACT_APP_APPSIGNAL
    }
  ) : undefined;

const FormGenerator = ({ form }) => {
  const formRef = useRef();
  const history = useHistory();

  const [formKey, setFormKey] = useState(Math.random());

  const API_HOST = process.env.REACT_APP_URL;

  const [formInitialValues, setFormInitialValues] = useState();
  const [formValidation, setFormValidation] = useState();
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (form && form.applicant_form_fields) {
      const newInitFormValues = {};
      const newFormValidation = {};

      form.applicant_form_fields.forEach(
        ({ id, is_required, applicant_available_form_field: field }) => {
          // the image type was removed, but we have fields in database,
          // and we need to ignore those fields
          if (field.field_type === "image") {
            return;
          }

          if (["checklist", "dropdown"].includes(field.field_type)) {
            newInitFormValues[id] = [];

            if (is_required) {
              newFormValidation[id] = Yup.array().required("Field is required");
            } else {
              newFormValidation[id] = Yup.array();
            }
          } else {
            newInitFormValues[id] = "";

            if (is_required) {
              newFormValidation[id] = Yup.string().required("Field is required");
            } else {
              newFormValidation[id] = Yup.string();
            }
          }

          if (field.field_type === "url") {
            const urlRegex = /^(https?:\/\/)?([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})([\/\w \.-]*)*\/?$/;

            newFormValidation[id] = newFormValidation[id].matches(
              urlRegex,
              "Please, enter a valid url",
            ).required("Field is required");
          }

          if (field.field_type === "textarea" && field.max_words) {
            function isValidMaxWordsCount() {
              return this.test("isValidMaxWordsCount", '', function (value) {
                const { path, createError } = this;

                if (!value) {
                  return createError({ path, message: 'Required' });
                }
                const wordsNr = value ? value.trim().split(/\s+/).length : 0;

                if (wordsNr > field.max_words) {
                  return createError({ path, message: 'Maximum allowed words count exceeded' });
                }

                return true;
              });
            }
            Yup.addMethod(Yup.mixed, "isValidMaxWordsCount", isValidMaxWordsCount);

            if (is_required) {
              newFormValidation[id] = Yup.mixed().isValidMaxWordsCount().required("Field is required");
            } else {
              newFormValidation[id] = Yup.mixed().isValidMaxWordsCount();
            }
          }

          if (is_required && !newFormValidation[id]) {
            newFormValidation[id] = newFormValidation[id].required("Field is required");
          }
        },
      );

      setFormInitialValues(newInitFormValues);
      setFormValidation(Yup.object().shape(newFormValidation));
    }
  }, [form]);

  const handleSubmit = async (values, actions) => {
    setShowLoading(true);

    const form_data = Object.entries(values).map(([key, value]) => {
      if (Array.isArray(value)) {
        return {
          applicant_form_field_id: key,
          applicant_form_field_values: value,
        };
      }

      return {
        applicant_form_field_id: key,
        applicant_form_field_value: String(value),
      };
    });

    const payload = {
      submission: {
        applicant_form_id: form.id,
        council_id: form.council.council_id,
        form_data,
      },
    };

    try {
      await fetch(`${API_HOST}/api/v1/applicants/submissions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          council_id: form.council.council_id,
        },
        body: JSON.stringify(payload),
      }).then(async (res) => {
        if (!res.ok) {
          const error = new Error(res.error_messages[0]);
          appSignal.sendError(error)

          history.push("/submission-form/error");
          return;
        }
        setShowLoading(false);
        actions.resetForm();
        setFormKey(Math.random());
        history.push("/submission-form/submit-success");
      });
    } catch (e) {
      appSignal.sendError(e);
      setShowLoading(false);
      history.push("/submission-form/error");
    }
  };

  const renderFields = (formikprops) => {
    if (
      !form
      || !form.applicant_form_fields
      || form.applicant_form_fields.length <= 0
      || !formInitialValues
    ) {
      return "";
    }

    return form.applicant_form_fields.map(
      ({ id, applicant_available_form_field: field }) => {
        const { field_type: type } = field;
        // eslint-disable-next-line no-param-reassign
        field.id = id;

        if (type === "textarea") {
          return (
            <TextArea field={field} formikprops={formikprops} key={id} />
          )
        }

        if (type === "checklist") {
          return (
            <CheckBoxInput field={field} formikprops={formikprops} key={id} />
          );
        }

        if (type === "dropdown") {
          return (
            <SelectInput field={field} formikprops={formikprops} key={id} />
          );
        }

        if (type === 'agreement') {
          return ''
        }

        if (type === "video") {
          return ''
          // return (
          //   <File
          //     placeholder="drag and drop videos here"
          //     field={field}
          //     formikprops={formikprops}
          //     key={id}
          //   />
          // );
        }

        if (type === "image" || type === "document" || type === "slide") {
          return (
            <File
              placeholder={`drag and drop ${type}s here`}
              field={field}
              formikprops={formikprops}
              key={id}
              councilId={form.council.council_id}
              formId={form.id}
            />
          );
        }

        return <Input field={field} formikprops={formikprops} key={id} />;
      },
    );
  };

  if (!form || !formInitialValues) {
    return "";
  }

  try {
    return (
      <div className={styles.form}>
        <div className={classnames(styles.row, styles.py3)}>
          <div className={styles.col}>
            <h3 className={styles.formTitle}>{form.form_title}</h3>
          </div>
        </div>

        <div className={classnames(styles.row, styles.pt0, styles.pb3)}>
          <div className={styles.col}>
            <h5 className={styles.formInstruction}>
              {form.form_instruction_text}
            </h5>
          </div>
        </div>

        {
          form && form.documents ? (
            <div className="mb-3 w-25 mx-auto">
              {
                form.documents.map(document => (
                  <div key={document.id} className="d-flex align-items-center justify-content-center">
                    <span className="font-weight-bold d-flex flex-grow-1">{document.name}</span>
                    <div className="ml-3 d-flex">
                      <a href={document.uri} target="_blank" download rel="noreferrer">
                        <Icon
                          {...smSize}
                          icon="icn-export-button"
                          className={styles.download}
                        />
                      </a>
                      <a
                        className={styles.docView}
                        target="blank"
                        href={document.uri}
                        download
                      >
                        {shouldDownload(document.name) ? "download" : "view"}
                      </a>
                    </div>
                  </div>
                ))
              }
            </div>
          ) : null
        }

        <div className={classnames(styles.row, styles.pt0, styles.pb3)}>
          <div className={styles.col}>
            <Formik
              innerRef={formRef}
              initialValues={formInitialValues}
              validationSchema={formValidation}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {(formikprops) => (
                <Form key={formKey} noValidate>
                  {renderFields(formikprops)}
                  <div className={styles.submitBtnWrp}>
                    {
                      showLoading ? (
                        <Loading customText="Preparing your application... This may take a moment." />
                      ) : (
                        <button type="submit" className={styles.submitBtn} disabled={showLoading}>
                          Submit
                        </button>
                      )
                    }
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  } catch (e) {
    return "";
  }
};

export default FormGenerator;

import React, { useRef } from "react";
import { Formik } from "formik";
import { Label, TextBox, Button, BtnType } from "modules/primitives";
import styles from "../adminThemes.module.scss";
import { ModalBody, ModalFooter } from "../../../../application/modal";
import { useModelPopup } from "../../../../common/hooks";

const RenameThemeComponent = ({
  changeThemesDisplayName,
  currentDisplayName,
  currentDisplayNameSingular,
}) => {
  const formRef = useRef();
  const popup = useModelPopup();

  const handleDismiss = () => {
    popup.hide();
  };

  const onSubmit = (values) => {
    changeThemesDisplayName(values.name, values.singularname);
    popup.hide();
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={{
        name: currentDisplayName || "",
        singularname: currentDisplayNameSingular || "",
      }}
      // validationSchema={validation && validation}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        onSubmit(values);
        resetForm();
      }}
    >
      {({ handleSubmit, ...formikprops }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className={styles.instructionText}>
              Current Display Name: {currentDisplayName}
            </div>
            <div>
              <Label>New Display Name:</Label>
              <TextBox
                type="text"
                name="name"
                placeholder="New Display Name"
                formProps={formikprops}
                className={styles.textBox}
              />
            </div>
            <div>
              <Label>New Singular Display Name:</Label>
              <TextBox
                type="text"
                name="singularname"
                placeholder="New Singular Display Name"
                formProps={formikprops}
                className={styles.textBox}
              />
            </div>
            <div className={styles.instructionText}>
              Changing the Themes Display name will change “Themes” to whatever
              text you enter universally throughout the Traction platform (in
              the top menu and all Themes pages). Are you sure you want to
              proceed?
            </div>
          </ModalBody>
          <ModalFooter>
            <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
              Cancel
            </Button>
            <Button
              type="submit"
              btn={BtnType.HIGHLIGHT}
              className={styles.button}
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default RenameThemeComponent;

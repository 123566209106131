import React from "react";
import styles from "../adminTags.module.scss";
import { MoreOptions, Loading } from "../../../primitives";

const AdminCustomTagsGroupTable = (props) => {
  const {
    data,
    arrayKey,
    handleMoreOptionClick,
    moreOptions,
    setCustomGroupSelected,
    loading,
  } = props;

  return (
    <>
      <div className={styles.tableHeader}>
        <div className={styles.nameTab}>
          <span>Custom Tag Category</span>
        </div>
        <div className={styles.descriptionTab}>
          <span>Description</span>
        </div>
        <div className={styles.childTab}>
          <span>Color</span>
        </div>
        <div className={styles.childTab}>
          <span>Custom Tags</span>
        </div>
        <div className={styles.childTab}>
          <span>Enabled</span>
        </div>
        <div className={styles.optionTab}>
          <span>Options</span>
        </div>
      </div>
      {loading && (
        <div className={styles.loadingContainer}>
          <Loading customText="Updating tags database. Please wait . . ." />
        </div>
      )}
      {!loading &&
        data.map((tagGroup) => {
          return (
            <div key={tagGroup.id} className={styles.tableContainer}>
              <div
                className={`${styles.accordion} ${styles.tagGroupName}`}
                onClick={() =>
                  setCustomGroupSelected(tagGroup.name, tagGroup.id)
                }
              >
                <div
                  className={styles.accordionRow}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={styles.tableRow}>
                    <div className={styles.rowName}>
                      <div className={styles.rowNameBlock}>
                        <span>{tagGroup.name}</span>
                      </div>
                    </div>
                    <div className={styles.rowDescription}>
                      <span>{tagGroup.description}</span>
                    </div>
                    <div className={styles.rowChildren}>
                      <span
                        style={{
                          backgroundColor: `${tagGroup?.bg_color || "#000"}`,
                          color: `${tagGroup?.text_color || "#000"}`,
                          borderRadius: 5,
                          padding: 5,
                          width: 80,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {tagGroup?.bg_color || "#000"}
                      </span>
                    </div>
                    <div className={styles.rowChildren}>
                      <span>{tagGroup[arrayKey]?.length}</span>
                    </div>
                    <div className={styles.rowChildren}>
                      <span>{tagGroup?.filter ? "Yes" : "No"}</span>
                    </div>
                    <div className={styles.rowOptions}>
                      <MoreOptions
                        options={moreOptions}
                        className={styles.moreOptWrp}
                        onClick={(e) => {
                          handleMoreOptionClick(e, tagGroup);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default AdminCustomTagsGroupTable;

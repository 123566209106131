import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { appModelSettingSet } from "application/app.action";
import { Pagination } from "modules/primitives";
import LoadingComponent from "modules/primitives/loading/loading.component";
import classNames from "classnames";
import { Icon, smSize } from "../../common/icon";

import styles from "./discussions.module.scss";
import DiscussionItem from "./discussionItem";
import NotificationItem from "./notifCenterItem/notificationItem.component";

import NewDiscussion from "../../common/components/newDiscussion/newDiscussion.component";
import DiscussionDetails from "../../common/components/discussionDetails";
import {
  discussionDelete,
  privateDiscussionsGet,
  publicDiscussionsGet,
  resetMsgsCount,
  setSelectedDiscussion,
  notificationsGet,
  myTasksNotificationsGet,
} from "../../common/actions/discussions.action";
import { useQuery } from "../../common/helper";

const PAGINATION_DEFAULTS = {
  pageSize: 50,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
  total: 0,
};
const DiscussionsComponent = () => {
  const query = useQuery().get("type") || "GROUP";
  const filterQuery = useQuery().get("filter") || "all";
  const dispatch = useDispatch();
  const {
    authReducer: { session },
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
    discussionsReducer: {
      publicDiscussions,
      privateDiscussions,
      selectedDiscussion,
      pageMeta,
      notifications,
      loadNotifications,
      myTasksNotifications,
      shouldUpdateList,
    },
  } = useSelector((state) => state);
  const [showDetailsWindow, setShowDetailsWindow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [notificationResults, setResults] = useState(null);
  const [pageSettings, setPageSettings] = useState(PAGINATION_DEFAULTS);
  const [sortingConfig, setSortingConfig] = useState({
    activeColumn: "updated_at",
    order: "desc",
  });
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (pageMeta?.total) {
      setPageSettings({
        ...PAGINATION_DEFAULTS,
        ...pageMeta,
        current: pageMeta.page,
      });
    }
  }, [pageMeta]);

  useEffect(() => {
    if (shouldUpdateList) {
      dispatch(
        myTasksNotificationsGet({
          id: session.id,
          enqueueSnackbar,
          page: pageMeta.page,
          items: 50,
          data: {
            sort_attribute: sortingConfig.activeColumn,
            sort_order: sortingConfig.order,
          },
        })
      );
    }
  }, [shouldUpdateList]);
  const handlePageChange = (page) => {
    if (query === "GROUP") {
      dispatch(
        publicDiscussionsGet({
          council_id: selectedCouncil.id,
          page,
          items: 50,
          enqueueSnackbar,
        })
      );
    }

    if (query === "PRIVATE") {
      dispatch(
        privateDiscussionsGet({
          id: selectedCouncil.id,
          page,
          items: 50,
          enqueueSnackbar,
        })
      );
    }
    if (query === "NOTIFICATION") {
      dispatch(
        notificationsGet({
          id: session.id,
          enqueueSnackbar,
          page,
          items: 50,
          tasks_and_projects: filterQuery === "tasks",
        })
      );
    }
    if (query === "NOTIFICATION" && filterQuery === "tasks") {
      dispatch(
        myTasksNotificationsGet({
          id: session.id,
          enqueueSnackbar,
          page,
          items: 50,
          data: {
            sort_attribute: sortingConfig.activeColumn,
            sort_order: sortingConfig.order,
          },
        })
      );
    }

    setPageSettings({
      ...pageSettings,
      current: page,
    });
  };

  useEffect(() => {
    dispatch(resetMsgsCount());
  }, []);

  const getData = () => {
    if (query === "GROUP") {
      dispatch(
        publicDiscussionsGet({
          council_id: selectedCouncil.id,
          page: 1,
          items: 50,
          enqueueSnackbar,
        })
      );
    }

    if (query === "PRIVATE") {
      dispatch(
        privateDiscussionsGet({
          id: selectedCouncil.id,
          page: 1,
          items: 50,
          enqueueSnackbar,
        })
      );
    }
  };

  useEffect(() => {
    if (selectedCouncil?.id) {
      getData();
      setPageSettings(PAGINATION_DEFAULTS);
    }
  }, [selectedCouncil, query]);

  useEffect(() => {
    if (notifications?.length) {
      setResults(notifications);
    }
  }, [notifications?.length]);

  useEffect(() => {
    if (myTasksNotifications?.length) {
      setResults(myTasksNotifications);
    }
  }, [myTasksNotifications?.length]);

  const redirectAfterSubmit = (value) => {
    history.push(`/discussions?type=${value}`);
  };

  const startNewDiscussion = () => {
    const customSettings = {
      title: `Start a New ${
        query === "GROUP" ? "Discussion" : "Private Discussion"
      }`,
      show: true,
      height: "300",
      width: "540",
      component: (
        <NewDiscussion
          type="Council"
          isPrivate={query === "PRIVATE"}
          handleRedirectTo={redirectAfterSubmit}
        />
      ),
    };
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  const handleShow = (details) => {
    dispatch(setSelectedDiscussion(details));

    setResults((prev) => {
      return prev?.map((notif) => {
        if (notif?.id === details?.id) {
          return { ...notif, read: true };
        }
        return notif;
      });
    });
    setShowDetailsWindow(true);
  };

  const handleClose = () => {
    localStorage.removeItem("temporary_message");
    setShowDetailsWindow(false);
    dispatch(setSelectedDiscussion({}));
  };

  const handleOptionsClick = (val, discussion) => {
    if (val === 0) {
      const customSettings = {
        title: "Edit Discussion",
        show: true,
        height: "300",
        width: "540",
        component: (
          <NewDiscussion
            isPrivate={query === "PRIVATE"}
            discussion={discussion}
          />
        ),
      };

      dispatch(
        appModelSettingSet({
          ...modalSetting,
          ...customSettings,
        })
      );
    }

    if (val === 1) {
      dispatch(discussionDelete(discussion));
    }
  };

  const getDiscussionView = (type, discussionArr) => {
    if (query === "GROUP") {
      return publicDiscussions?.length ? (
        publicDiscussions.map((discussion) => (
          <DiscussionItem
            key={discussion.id}
            discussion={discussion}
            handleShow={() =>
              handleShow({
                ...discussion,
                prop: "publicDiscussions",
              })
            }
            session={session}
            handleOptionsClick={handleOptionsClick}
          />
        ))
      ) : (
        <div className={styles.noData}>
          {!publicDiscussions ? "Loading..." : "No group discussions yet!"}
        </div>
      );
    }
    if (query === "PRIVATE") {
      return privateDiscussions?.length ? (
        privateDiscussions.map((discussion) => (
          <DiscussionItem
            key={discussion.id}
            discussion={discussion}
            handleShow={() =>
              handleShow({
                ...discussion,
                isPrivate: true,
                prop: "privateDiscussions",
              })
            }
            isPrivate
            session={session}
            handleOptionsClick={handleOptionsClick}
          />
        ))
      ) : (
        <div className={styles.noData}>
          {!privateDiscussions ? "Loading..." : "No private messages yet!"}
        </div>
      );
    }

    if (query === "NOTIFICATION") {
      return notificationResults?.length ? (
        notificationResults.map((discussion) => (
          <NotificationItem
            key={discussion.id}
            notification={discussion}
            handleShow={() =>
              handleShow({
                ...discussion,
                prop: "notification",
                discussion_resource_type:
                  discussion?.entity?.entity_type?.charAt(0).toUpperCase() +
                  discussion?.entity?.entity_type?.slice(1),
                discussion_resource_id: discussion?.entity?.entity_id,
                isNotif: true,
              })
            }
            isNotif
            session={session}
            handleOptionsClick={handleOptionsClick}
          />
        ))
      ) : (
        <div className={styles.noData}>
          {loadNotifications ? "Loading..." : ""}
          {notificationResults?.length === 0 ? "No notifications yet!" : ""}
        </div>
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (selectedCouncil?.id && session?.id && query === "NOTIFICATION") {
      if (session?.id && query === "NOTIFICATION" && filterQuery === "all") {
        dispatch(
          notificationsGet({
            id: session.id,
            enqueueSnackbar,
            page: 1,
            items: 50,
            tasks_and_projects: false,
          })
        );
      }
      if (session?.id && query === "NOTIFICATION" && filterQuery === "tasks") {
        dispatch(
          myTasksNotificationsGet({
            data: {
              sort_attribute: sortingConfig.activeColumn,
              sort_order: sortingConfig.order,
            },
            enqueueSnackbar,
            page: 1,
            items: 50,
          })
        );
      }
    }
    // setPageSettings(PAGINATION_DEFAULTS);
  }, [filterQuery, query, session]);

  const drawSortingIcon = useCallback(
    (col) => {
      // if (!sortingConfig) return null;

      if (sortingConfig?.activeColumn === col) {
        return (
          <>
            <div
              className={classNames(
                styles.sortIcon,
                sortingConfig.order === "desc" && styles.sortAsc
              )}
            >
              <Icon {...smSize} icon="icn-export-button" />
            </div>
            {showLoading && (
              <div className={styles.columnStyle}>
                <LoadingComponent hideString />
              </div>
            )}
          </>
        );
      }
    },
    [sortingConfig, showLoading]
  );

  const makeSortingConfig = (activeColumn) => {
    let config;
    if (sortingConfig) {
      if (sortingConfig.order === "desc") {
        config = {
          activeColumn,
          order: "asc",
        };
      } else {
        config = {
          activeColumn,
          order: "desc",
        };
      }
    }

    dispatch(
      myTasksNotificationsGet({
        enqueueSnackbar,
        page: pageSettings.current,
        items: 50,
        data: {
          sort_attribute: config.activeColumn,
          sort_order: config.order,
        },
      })
    );
    setSortingConfig(config);
  };
  const getMainLabel = () => {
    switch (query) {
      case "GROUP":
        return "Public Discussions";
      case "PRIVATE":
        return "Private Discussions";
      case "NOTIFICATION":
        return filterQuery === "tasks" ? "My Tasks" : "Notifications";
      default:
        return "Public";
    }
  };

  return (
    <section className={styles.pageWrp}>
      {showDetailsWindow && (
        <>
          <div className={styles.detailsExtraLayer}></div>
          <DiscussionDetails
            handleClose={handleClose}
            details={selectedDiscussion}
            query={query}
            notification={query === "NOTIFICATION"}
          />
        </>
      )}
      <div className={styles.pageHeader}>
        <div className="d-flex align-items-center">
          <span className={styles.title}>{getMainLabel()}</span>

          <div className={styles.viewChanger}>
            <Link
              to="/discussions?type=GROUP"
              className={query === "GROUP" ? styles.activeView : ""}
            >
              Public
            </Link>
            <Link
              to="/discussions?type=PRIVATE"
              className={query === "PRIVATE" ? styles.activeView : ""}
            >
              Private
            </Link>
            <Link
              to="/discussions?type=NOTIFICATION&filter=all"
              className={
                query === "NOTIFICATION" && filterQuery === "all"
                  ? styles.activeView
                  : ""
              }
            >
              Notifications
            </Link>
            <Link
              to="/discussions?type=NOTIFICATION&filter=tasks"
              className={filterQuery === "tasks" ? styles.activeView : ""}
            >
              My Tasks
            </Link>
          </div>
        </div>
        <div className={styles.newMsg} onClick={startNewDiscussion}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
          >
            <g fill="none" fillRule="evenodd">
              <g fill="#D1EEFC">
                <g>
                  <g>
                    <path
                      d="M7.314 0L7.314 7.314 0 7.314 0 8.686 7.314 8.686 7.314 16 8.686 16 8.686 8.686 16 8.686 16 7.314 8.686 7.314 8.686 0z"
                      transform="translate(-1219 -151) translate(1202.5 143) translate(17 8.5)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <span>New {query === "GROUP" ? "Discussion" : "Message"}</span>
        </div>
      </div>
      <div className={styles.columns}>
        <div
          className={classNames(
            styles.columnFrom,
            filterQuery === "tasks" && styles.taskProjName,
            styles.pointer
          )}
          onClick={() =>
            filterQuery === "tasks" && makeSortingConfig("project_name")
          }
        >
          {filterQuery === "tasks" ? "Project" : "From"}{" "}
          {filterQuery === "tasks" && drawSortingIcon("project_name")}
        </div>

        {filterQuery !== "tasks" && (
          <div className={styles.columnsSubj}>Subject</div>
        )}

        {filterQuery === "tasks" ? (
          <div
            className={classNames(
              styles.columnsCompleted,
              filterQuery === "tasks" && styles.pointer
            )}
            onClick={() =>
              filterQuery === "tasks" && makeSortingConfig("completed")
            }
          >
            Completed (by me)
            {filterQuery === "tasks" && drawSortingIcon("completed")}
          </div>
        ) : null}
        {filterQuery === "tasks" ? (
          <div
            className={classNames(
              styles.columnsTaskSubj,
              filterQuery === "tasks" && styles.pointer
            )}
            onClick={() =>
              filterQuery === "tasks" && makeSortingConfig("task_name")
            }
          >
            Task
            {filterQuery === "tasks" && drawSortingIcon("task_name")}
          </div>
        ) : null}
      </div>
      <div className={styles.discussionsList}>{getDiscussionView()}</div>
      <div className={styles.paginWrp}>
        <div className={styles.paginCon}>
          <Pagination {...pageSettings} onChange={handlePageChange} />
        </div>
      </div>
    </section>
  );
};

export default React.memo(DiscussionsComponent);

import React, { useCallback, useEffect } from "react";
import Select from "react-dropdown-select";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { Label } from "modules/primitives";
import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";

const ProjectPlants = ({ formikprops, isAdminWizardInstance, additionalData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const {
    adminPlantsAndAreasReducer: { plantsAndAreas },
  } = useSelector((state) => state);

  useEffect(() => {
    console.log('additionalData', additionalData);
  }, []);

  useEffect(() => {
    if (isAdminWizardInstance) return;

    if (!plantsAndAreas.length) {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        }),
      );
    }
  }, [plantsAndAreas]);

  const getPlantsAsOptions = useCallback((filterBy) => {
    if (isAdminWizardInstance) return [];

    const makePlants = plantsAndAreas.map(plant => ({
      id: plant.id,
      name: plant.attributes.name,
      areas: plant.attributes.areas,
    }));
    const { values } = formikprops;
    const existingValue = values[filterBy];

    if (existingValue?.length) {
      return makePlants.filter(plant => !existingValue.some(p => p.id === plant.id));
    }

    return makePlants;
  }, [plantsAndAreas, formikprops.values, isAdminWizardInstance, additionalData]);

  const handlePlantChange = (val) => {
    if (additionalData?.replicateData?.plantsStuff && val[0]) {
      const plantsStuff = additionalData?.replicateData?.plantsStuff;
      const makeOriginalPlant = () => {
        if (plantsStuff.originalPlant?.plant) {
          return [
            {
              ...plantsStuff.originalPlant.plant,
              areas: plantsStuff.originalPlant.plant.area ? [{
                ...plantsStuff.originalPlant.plant.area,
                status: plantsStuff.originalPlant.status
              }] : [],
            }
          ]
        }

        return [];
      };
      const makeProjectPlant = () => {
        if (plantsStuff.projectPlant) {
          return [
            {
              ...plantsStuff.projectPlant,
              areas: plantsStuff.projectPlant.areas?.map(a => ({
                ...a,
                name: a.attributes.name,
                status: a.status || plantsStuff.projectPlant.status
              })) || [],
            }
          ]
        }

        return [];
      };
      const getPlantsFromPlantsTab = () => {
        if (plantsStuff.plantsTab?.data?.current_plant || plantsStuff.plantsTab.data.original_plant) {
          return [
            (plantsStuff.plantsTab?.data?.current_plant || {id: null}),
            (plantsStuff.plantsTab.data.original_plant || {id: null})
          ];
        }

        return [];
      };
      const sourceData = [
        ...makeOriginalPlant(),
        ...makeProjectPlant(),
        ...getPlantsFromPlantsTab(),
        ...plantsStuff.projectReplications,
      ]
      const replicatedAreas = sourceData
        .filter(p => p.id === val[0].id)
        .reduce((acc, plant) => {
          const filteredAreas = plant.areas.filter(a => a.status === 'under_review' || a.status === 'original') || [];
          return [...acc, ...filteredAreas];
        }, [])
        .map(a => a.area_id || a.id);
      const newPlant = {
        ...val[0],
        areas: val[0].areas.filter(a => !replicatedAreas.includes(a.area_id)),
      };
      formikprops.setFieldValue("project_plants", newPlant);

      return;
    }

    formikprops.setFieldValue("project_plants", val[0]);
  }

  return (
    <div className="mb-3">
      <Label>Plant</Label>
      <Select
        disabled={isAdminWizardInstance}
        multi={false}
        closeOnSelect
        values={
          formikprops.values?.project_plants
            ? [formikprops.values.project_plants]
            : []
        }
        onChange={handlePlantChange}
        options={getPlantsAsOptions('possible_replications_plants')}
        labelField="name"
        valueField="id"
        placeholder="Plant"
        searchBy="name"
        style={{ border: "1px solid #e4e7eb" }}
      />
      {formikprops.values?.project_plants?.areas?.length > 0 ? (
        <div className="mt-2">
          <Label>Areas</Label>
          <Select
            multi={false}
            closeOnSelect
            values={formikprops.values.project_plants?.selectedAreas || []}
            onChange={(val) => {
              formikprops.setFieldValue("project_plants", {
                ...formikprops.values?.project_plants,
                selectedAreas: val,
              });
            }}
            options={
              formikprops.values.project_plants.areas.map((area) => {
                return {
                  area_name: area.area_name || area.attributes.name,
                  area_id: area.area_id || area.id,
                };
              }) || []
            }
            labelField="area_name"
            valueField="area_id"
            placeholder="Areas"
            searchBy="area_name"
            style={{ border: "1px solid #e4e7eb" }}
          />
          <div className="caption small text-danger">
            {formikprops.touched["project_plants"] && formikprops.errors["project_plants"]?.selectedAreas
              ? formikprops.errors["project_plants"].selectedAreas
              : ""}
          </div>
          {formikprops.values.project_plants?.selectedAreas?.length > 0 ? (
            <div className="d-flex justify-content-end">
              <span
                className="text-muted small cursor-pointer"
                onClick={() =>
                  formikprops.setFieldValue("project_plants", {
                    ...formikprops.values?.project_plants,
                    selectedAreas: [],
                  })
                }
              >
                clear
              </span>
            </div>
          ) : null}
        </div>
      ) : null}
      <div className="caption small text-danger">
        {formikprops.touched["project_plants"] && formikprops.errors["project_plants"]?.id
          ? formikprops.errors["project_plants"].id
          : ""}
      </div>
    </div>
  );
};

export default React.memo(ProjectPlants);

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import classnames from "classnames";

import styles from "./project.module.scss";
import { BtnType, Button, TabComponent } from "../../primitives";
import StagesComponent from "./stages";
import OverviewComponent from "./overview";
import CompaniesComponent from "./companies";
import ManageComponent from "./manageProject/manage.component";
import ErrorBoundary from "../../../common/components/errorBoundary";
import CompareCompaniesComponent from "./companies/compair";
import {
  clearSelectedProject,
  getProjectReplications,
  getProjectTabPlants,
  projectGet,
  projectSetDownloadParams,
  updateProjectReplications,
  updateReplicationStatus,
} from "./project.action";
import { projectStagesListGet } from "./stages/stages.action";
import { projectUsersGet } from "./users/user.action";
import { projectOrganizationsGetSuccess } from "./companies/companies.action";
import { Loading } from "modules/primitives/index";
import { useQuery } from "../../../common/helper";
import { checkViewerRole } from "common/checkers/viewerChecker";
import { useModelPopup } from "../../../common/hooks";
import ProjectReports from "./projectReports";
import TractionDownload from "../../../common/components/tractionDownload";
import { Icon, mdSize, smSize } from "../../../common/icon";
import ProjectDownload from "modules/projects/project/projectDownload";
import TasksWrapperComponent from "modules/projects/project/tasks/tasksWrapper.component";
import PlantsTab from "modules/projects/project/plants";
import ForPossibleReplicationsModal from "../../../common/components/FordPossibleReplications/ForPossibleReplicationsModal.component";
import ReplicationStatusModal from "modules/projects/project/replicationStatus";
import {
  getNormalizedReplicationsData,
  getNormalizedProjectReplicationsData,
  getSpocPlants,
  makeNormalizedFilteredPlants,
  makeNormalizesPlantsWhenAdmin,
} from "../../../common/plantsUtils";
import NewProjectV3 from "modules/projects/newProjectv3";

const ProjectComponent = ({ location: { pathname } }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const [patientOrg, setPatientOrg] = useState(false);
  const [textOrg, setTextOrg] = useState("Company");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const isEditableMode = useQuery().get("editMode");
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [canManageReplications, setCanManageReplications] = useState(false);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  const {
    projectsReducer: {
      projectReducer: {
        selectedProject,
        shouldMembersUpdate,
        projectIsDownloading,
        projectDownloadDetails,
        fieldGroups,
        replicationData,
        projectPlant,
        plantsTab,
      },
    },
    themesReducer: {
      themesOverviewReducer: { shouldUpdateProjects },
    },
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);
  const [selectedTab, setSelectedTab] = useState(pathname.split("/")[3]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [manageProjectActive, setManageProjectView] = useState(false);
  const [canManageTasks, setCanManageTasks] = useState(false);

  const isFordSupplyChainAutomation = useMemo(() => {
    return selectedCouncil?.name === 'Ford' && selectedProject?.stage_template?.name === 'Supply Chain Automation';
  }, [selectedCouncil, selectedProject]);

  useEffect(() => {
    if (!session) return;

    const isAdmin = ["ttp_administrator", "council_administrator"].includes(
      session?.council_role
    );
    setCanManageReplications(
      isAdmin || session?.spoc || session?.innovation_leader
    );
  }, [session]);

  useEffect(() => {
    if (selectedCouncil?.name === "Ford" && selectedProject?.id) {
      dispatch(getProjectReplications(selectedProject.id));;
    }

    return () => {
      dispatch(updateReplicationStatus(null));
    };
  }, [selectedCouncil?.name, selectedProject?.id]);

  useEffect(() => {
    if (selectedProject && session) {
      if (
        ["ttp_administrator", "council_administrator"].includes(
          session?.council_role
        )
      ) {
        setCanManageTasks(true);
        return;
      }

      const projectMember = selectedProject?.project_members.find(
        ({ user }) => user.id === session.id
      );

      if (selectedCouncil?.name === "Ford") {
        if (projectMember?.user_role === "owner" ||
          projectMember?.user_role === "member" ||
          projectMember?.user_role === "manager") {
          setCanManageTasks(true);
        }
      }

      if (projectMember && projectMember?.user_role !== "member") {
        setCanManageTasks(true);
      }
    }
  }, [session, selectedProject]);

  useEffect(() => {
    setManageProjectView(Boolean(isEditableMode));
  }, [isEditableMode]);

  useEffect(() => {
    if (id || shouldUpdateProjects) {
      setLoading(true);
      dispatch(projectOrganizationsGetSuccess({ status: 200, response: [] }));
      dispatch(projectGet({ id, enqueueSnackbar }));
    }
  }, [id, shouldUpdateProjects]);

  useEffect(() => {
    if (selectedProject) {
      setLoading(false);
    }
  }, [selectedProject, id]);

  useEffect(() => {
    if (selectedProject?.stage_template?.patient_organization) {
      setPatientOrg(true);
      setTextOrg("Organization");
    }
  }, [selectedProject]);

  useEffect(() => {
    if (shouldMembersUpdate && selectedProject && selectedCouncil) {
      dispatch(
        projectUsersGet({
          projectId: selectedProject.id,
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [shouldMembersUpdate]);

  useEffect(() => {
    if (!selectedProject?.id || !selectedCouncil?.id) return;

    dispatch(
      projectStagesListGet({
        projectId: selectedProject.id,
        enqueueSnackbar,
      })
    );
    dispatch(
      projectUsersGet({
        projectId: selectedProject.id,
        councilId: selectedCouncil.id,
        enqueueSnackbar,
      })
    );
  }, [selectedProject?.id, selectedCouncil?.id]);

  useEffect(() => {
    history.listen(({ pathname }) => {
      setSelectedTab(pathname.split("/")[3]);
    });
  }, [history]);

  const isAdmin = useMemo(() => {
    return ["ttp_administrator", "council_administrator"].includes(
      session?.council_role
    );
  }, [session?.council_role]);

  const isManager = useMemo(() => {
    const currentRole =
      selectedProject &&
      selectedProject.project_members.find(
        (item) => item?.user?.id === session?.id
      )?.user_role;

    return (
      (session && session.is_admin) ||
      isAdmin ||
      (currentRole && currentRole !== "member")
    );
  }, [selectedProject, session, isAdmin]);

  const handleOnTabSelect = (index) => {
    if (selectedProject && selectedProject.id) {
      const objTab = makeTabList.find((tab, tabIndex) => index === tabIndex);
      if (objTab.tab === "Task") {
        history.push(
          `/projects/${
            selectedProject.id
          }/${objTab.tab.toLowerCase()}?tab=all_task`
        );
      } else {
        history.push(
          `/projects/${selectedProject.id}/${objTab.tab.toLowerCase()}`
        );
      }
      setSelectedIndex(index);
    }
  };

  const handleOpenModal = (index) => {
    const objTab = makeTabList.find((tab, tabIndex) => index === tabIndex);

    if (manageProjectActive) {
      if (!objTab) {
        history.push(`/projects/${selectedProject.id}`);
      } else {
        history.push(
          `/projects/${selectedProject.id}/${objTab.tab.toLowerCase()}`
        );
      }

      setManageProjectView(!manageProjectActive);

      return;
    }

    if (!objTab) {
      history.push(`/projects/${selectedProject.id}/details?editMode=true`);
    } else {
      history.push(
        `/projects/${
          selectedProject.id
        }/${objTab.tab.toLowerCase()}?editMode=true`
      );
    }

    setManageProjectView(!manageProjectActive);
  };

  const isNot3DPrintingTemplate = useMemo(() => {
    if (selectedCouncil?.name !== "Ford") return true;

    return (
      selectedProject?.stage_template?.name !== "3D Printing Replication" &&
      selectedProject?.stage_template?.name !== "3D Printing Savings"
    );
  }, [selectedProject?.stage_template, selectedCouncil?.name]);

  const tabList = [
    {
      tab: "Overview",
      name: "Overview",
      component: (
        <OverviewComponent
          canManageTasks={canManageTasks}
          patientOrg={patientOrg}
          pathname={pathname}
          isNot3DPrintingTemplate={isNot3DPrintingTemplate}
        />
      ),
    },
    {
      tab: "Plants",
      name: "Plants",
      component: <PlantsTab project={selectedProject} />,
    },
    {
      tab: isFordSupplyChainAutomation ? "Partners" : "Companies",
      name: isFordSupplyChainAutomation ? "Partners" : patientOrg ? `${textOrg}s` : "Companies",
      component: <CompaniesComponent pathname={pathname} />,
      attr: selectedCouncil?.name === "Firmenich" &&
        selectedProject?.stage_template?.default_template && {
          style: { display: "none" },
        },
    },
    {
      tab: "Stage",
      name: "Stages",
      component: <StagesComponent projectId={id} pathname={pathname} />,
      attr: selectedCouncil?.name === "Firmenich" &&
        selectedProject?.stage_template?.default_template && {
          style: { display: "none" },
        },
    },
    {
      tab: "Task",
      name: "Tasks",
      component: <TasksWrapperComponent canManageTasks={canManageTasks} />,
    },
    // {
    //   tab: 'Rating',
    //   name: 'Ratings',
    //   component: <RatingsComponent pathname={pathname} />
    // },
    {
      tab: "Compare",
      name: "",
      attr: { style: { display: "none" } },
      component: <CompareCompaniesComponent pathname={pathname} />,
    },
  ];

  const makeTabList = useMemo(() => {
    if (selectedCouncil?.name === "Ford") {
      if (isFordSupplyChainAutomation) {
        return tabList.filter((t) => t.tab !== "Plants")
      }
      if (!isNot3DPrintingTemplate) {
        return tabList.filter(
          (t) => t.tab !== "Plants" && t.tab !== "Companies"
        );
      }
      return tabList;
    }

    if (patientOrg) {
      return tabList.filter(
        (t) => t.tab !== "Task" && t.tab !== "Stage" && t.tab !== "Plants"
      );
    }

    return tabList.filter((t) => t.tab !== "Plants");
  }, [
    canManageTasks,
    pathname,
    selectedCouncil,
    selectedProject,
    id,
    patientOrg,
    textOrg,
    isNot3DPrintingTemplate,
    isFordSupplyChainAutomation,
  ]);

  useEffect(() => {
    setSelectedIndex(
      makeTabList.findIndex(({ tab }) => {
        return tab?.toLowerCase() === selectedTab;
      })
    );
  }, [selectedTab, makeTabList]);

  useEffect(
    () => () => {
      dispatch(clearSelectedProject());
    },
    []
  );

  const handleCreateReport = () => {
    popup.show({
      title: "Create Project Report",
      fullScreen: true,
      component: <ProjectReports />,
    });
  };

  const kyndrylRightContent =
    selectedCouncil?.name === "Kyndryl" && selectedProject ? (
      <Button
        btn={BtnType.FRAME_LESS}
        className={styles.createReport}
        icon={"icn-add"}
        onClick={handleCreateReport}
      >
        Create Report
      </Button>
    ) : null;

  const handleDownloadState = (status) => {
    if (!status) {
      dispatch(projectSetDownloadParams([]));
    }
  };

  const handleDownloadClose = (tabs) => {
    if (tabs?.length) {
      dispatch(projectSetDownloadParams(tabs));
    }

    popup.hide();
  };

  const handleDownload = () => {
    popup.show({
      title: "Select project tabs to download",
      component: (
        <ProjectDownload
          handleDownload={handleDownloadClose}
          patientOrg={patientOrg}
          fordInstance={selectedCouncil?.name === "Ford"}
          isFordSupplyChainAutomation={isFordSupplyChainAutomation}
        />
      ),
    });
  };

  useEffect(() => {
    if (selectedProject?.id && selectedCouncil?.name === "Ford") {
      getTabPlants(selectedProject.id);
    }
  }, [selectedProject?.id]);

  const getTabPlants = (projectId) => {
    dispatch(getProjectTabPlants(projectId));
  }

  const handleGetNewData = (data) => {
    dispatch(updateProjectReplications(data));
    dispatch(
      updateReplicationStatus({
        ...replicationData,
        possible_replication: data.map((el) => ({
          ...el,
          plant: {
            name: el.name,
            id: el.id,
          },
        })),
      })
    );
    getTabPlants(selectedProject.id);
  };

  const makeReplicationsData = useMemo(() => {
    return getNormalizedReplicationsData(replicationData);
  }, [replicationData]);

  const makeProjectReplicationsData = useMemo(() => {
    return getNormalizedProjectReplicationsData(replicationData);
  }, [replicationData]);

  const provideFordFeedback = () => {
    const originalProject = {
      ...projectPlant,
      areas:
        projectPlant?.areas?.map((a) => ({
          ...a,
          ...a.attributes,
          area_id: a.id || a.area_id,
        })) || [],
    };
    const originalPlant = replicationData?.original_project?.plant?.id
      ? {
          ...replicationData?.original_project?.plant,
          areas: replicationData?.original_project?.plant.area
            ? [
                {
                  ...replicationData?.original_project?.plant.area,
                  area_id: replicationData?.original_project?.plant.area.id,
                },
              ]
            : [],
        }
      : {};

    const filterBy = [
      ...makeProjectReplicationsData,
      ...replicationData?.possible_replication,
      originalProject,
      originalPlant,
    ];

    let normalizedData;
    let showAllReplicated;

    if (isAdmin) {
      normalizedData = makeNormalizesPlantsWhenAdmin(replicationData);
    } else {
      const normalizedSPOCPlants = getSpocPlants(
        [...makeReplicationsData, ...makeProjectReplicationsData],
        session
      );
      normalizedData = makeNormalizedFilteredPlants(
        normalizedSPOCPlants,
        projectPlant,
        replicationData
      );

      if (!normalizedData.length && normalizedSPOCPlants.length) {
        showAllReplicated = true;
      }
    }

    let preNormalizedData = normalizedData;

    if (!showAllReplicated) {
      preNormalizedData = normalizedData.map((plant) => ({
        ...plant,
        state: plant.state || "possible_replication",
        areas: plant.areas.map((area) => ({
          ...area,
          state: area.state || "possible_replication",
        })),
      }));
    }

    popup.show({
      title: "Possible Plants + Areas",
      component: (
        <ForPossibleReplicationsModal
          instance="Project"
          replicationsData={preNormalizedData}
          handleSave={handleGetNewData}
          data={selectedProject}
          filterBy={filterBy}
          combinedPlants={filterBy}
          showAllReplicatedMsg={showAllReplicated}
        />
      ),
    });
  };

  const handleEditReplication = () => {
    popup.show({
      height: "80vh",
      title: "Replication Status",
      component: <ReplicationStatusModal project={selectedProject} />,
    });
  };

  const renderEditReplicationIcon = useCallback(() => {
    if (selectedProject?.original && selectedProject?.replications?.length > 0) return null;

    if (canManageTasks) {
      return (
        <Icon
          {...mdSize}
          icon="icn-edit-button"
          className="cursor-pointer ml-3"
          onClick={handleEditReplication}
        />
      )
    }

    return null;
  }, [selectedProject?.original, selectedProject?.replications, canManageTasks, handleEditReplication]);

  const additionalDataForProjectV3 = useMemo(() => ({
    ...selectedProject,
    replicateData: {
      fieldGroups,
      plantsStuff: {
        projectReplications: [...makeReplicationsData, ...makeProjectReplicationsData],
        projectPlant,
        originalPlant: replicationData?.original_project,
        plantsTab,
      }
    },
  }), [selectedProject, fieldGroups, makeReplicationsData, makeProjectReplicationsData, projectPlant, replicationData?.original_project, plantsTab]);

  return (
    <div className={styles.projectWrp}>
      {loading ? (
        <div className={styles.loadingWrp}>
          <Loading />
        </div>
      ) : (
        <div>
          <div className={styles.headerWrapper}>
            <h4 className={styles.ProjectTitle}>
              {selectedProject && selectedProject.name}
            </h4>
            {selectedProject && selectedCouncil?.name === "Ford" && (
              <div className="position-relative d-flex">
                <div
                  className={classnames(
                    styles.replicationTagElem,
                    selectedProject.original ? styles.originalTag : styles.replicationTag,
                  )}
                >
                  {selectedProject.original ? "ORIGINAL" : "REPLICATION"}
                </div>
                {renderEditReplicationIcon()}
              </div>
            )}
          </div>
          <ErrorBoundary component={"Projects"}>
            <div className={styles.manageWrp}>
              {!manageProjectActive && (
                <div className="d-flex flex-column pr-3">
                  <div className="d-flex">
                    <div className="invisible" style={{ width: 0 }}>
                      <TractionDownload
                        handleDownloadState={handleDownloadState}
                        timeoutTime={2000}
                        hidePng
                        shouldDownloadPdf={projectIsDownloading}
                      />
                    </div>
                    <Button
                      btn={BtnType.FRAME_LESS}
                      onClick={handleDownload}
                      className={projectIsDownloading ? "invisible" : ""}
                    >
                      <div className="d-flex align-items-center">
                        <Icon
                          {...smSize}
                          icon="icn-export-button"
                          className={styles.download}
                        />
                        Download
                      </div>
                    </Button>
                    {isManager && !blockFunctionality && (
                      <Button
                        btn={BtnType.FRAME_LESS}
                        className={styles.manegeBtn}
                        icon="icn-edit"
                        onClick={() => handleOpenModal()}
                      >
                        Manage Project
                      </Button>
                    )}
                  </div>
                  {selectedCouncil?.name === "Ford" &&
                    isNot3DPrintingTemplate && !isFordSupplyChainAutomation && (
                      <div className="d-flex align-items-center">
                        {fieldGroups && !plantsTab.isFetching && (
                          <NewProjectV3
                            isAdmin={isAdmin}
                            blockFunctionality={blockFunctionality}
                            wizardType="replicate"
                            additionalData={additionalDataForProjectV3}
                            btnName="Replicate"
                            modalTitle="Replicate Project"
                          />
                        )}
                        {canManageReplications && replicationData && (
                          <Button
                            btn={BtnType.REGULAR}
                            onClick={provideFordFeedback}
                            icon="icn-add"
                            className="ml-2"
                          >
                            Provide Feedback
                          </Button>
                        )}
                      </div>
                    )}
                </div>
              )}
            </div>
            {manageProjectActive ? (
              <ManageComponent
                pathname={pathname}
                handleOpenModal={handleOpenModal}
              />
            ) : (
              <div id="downloadableSection">
                {projectIsDownloading && (
                  <div className="d-flex flex-1 justify-content-between align-items-center px-4 mb-5">
                    <img
                      height="50"
                      alt={selectedCouncil?.name}
                      src={selectedCouncil?.logo}
                    />
                    <h2 className="font-weight-bold mb-0">
                      {selectedProject?.name} Project
                    </h2>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="79"
                      height="35"
                      viewBox="0 0 79 35"
                    >
                      <g fill="none" fillRule="evenodd" fontSize="16">
                        <g fill="#1F2933">
                          <g>
                            <text
                              fontFamily="HelveticaNeue-CondensedBlack, Helvetica Neue"
                              fontStyle="condensed"
                              fontWeight="700"
                              transform="translate(-1290 -12) translate(1291 12)"
                            >
                              <tspan x="0" y="16">
                                Traction
                              </tspan>
                            </text>
                            <text
                              fontFamily="HelveticaNeue-Light, Helvetica Neue"
                              fontWeight="300"
                              letterSpacing="-.08"
                              transform="translate(-1290 -12) translate(1291 12)"
                            >
                              <tspan x="0" y="31">
                                Technology
                              </tspan>
                            </text>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                )}
                {projectIsDownloading && projectDownloadDetails.length ? (
                  makeTabList
                    .filter((t) => projectDownloadDetails.includes(t.name))
                    .map((t) => (
                      <div key={t.name} className="mb-3">
                        <h2 className="mb-3 font-weight-bold mt-5">{t.name}</h2>
                        {t.component}
                      </div>
                    ))
                ) : (
                  <TabComponent
                    tabList={makeTabList}
                    defaultIndex={selectedIndex}
                    onSelect={(index) => handleOnTabSelect(index)}
                    rightContent={kyndrylRightContent}
                  />
                )}
              </div>
            )}
          </ErrorBoundary>
        </div>
      )}
    </div>
  );
};

export default React.memo(ProjectComponent);

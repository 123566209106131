import React, { useState, useEffect } from "react";
import { useVideField } from "common/hooks";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { isCreator } from "common/checkers/isCreator";
import { MoreOptions, Loading } from "modules/primitives";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { videoExtensions } from "modules/events/viewEvent/viewEvent.constant";
import DragAndDrop from "common/components/dragAndDrop/index";
import useS3FileUpload from "../../../hooks/s3FileUplodar.hook";
import { ACTIVITY_OPTIONS } from "../../../constants";
import styles from "./styles.module.scss";
import LinearProgressWithLabel from "../../linearProgressWithLabel";
import { getFilename } from "modules/admin/adminCompanies/editCompany/editCompany.helper";

const VideoComponent = ({
  item,
  handleSubmitDetail,
  handleEditClick,
  blockFunctionality,
  handleDeleteClick,
}) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { customFieldLoadingId, selectedCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: {
        selectedPatientOrganization,
        customFieldLoadingIdOrg,
      },
    },
  } = useSelector((state) => state);

  const videField = useVideField(item);
  const [loadingId, setLoadingId] = useState("");
  const [fieldId, setFieldId] = useState("");
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);
  const [videos, setVideos] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { fileUpload } = useS3FileUpload();
  const [loading, setLoading] = useState(false);
  const [uploadValues, setUploadValues] = useState([]);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);

  useEffect(() => {
    if (item) {
      if (isCreator(item?.creator?.user_id)) {
        setHasAccessToEdit(true);
      }
    }
  }, [item]);

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }

    if (value === 1) {
      handleDeleteClick(item);
    }
  };

  useEffect(() => {
    if (customFieldLoadingId || customFieldLoadingIdOrg) {
      if (selectedPatientOrganization.id) {
        setLoadingId(customFieldLoadingIdOrg);
      } else {
        setLoadingId(customFieldLoadingId);
      }
    }
  }, [customFieldLoadingId, customFieldLoadingIdOrg]);

  useEffect(() => {
    if (selectedPatientOrganization.id) {
      setFieldId(item.patient_organization_custom_field_id);
    } else {
      setFieldId(item.company_custom_field);
    }
  }, [item]);

  const getExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  const uploadHandler = async (e, action) => {
    if (forbiddenFiles.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }
    setLoading(true);

    setUploadValues((items) => {
      const currentFiles = [...items];

      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name,
      }));
      return [...currentFiles, ...newFiles];
    });

    const fileList = [];
    let dismissedFiles = 0;

    files.forEach(async (file, index, array) => {
      const isLastIndex = Boolean(index === array?.length - 1);
      if (
        file.size <= 500000000 &&
        videoExtensions.indexOf(getExtension(file.name)) !== -1
      ) {
        const pathname = `srm/${selectedCouncil.id}/${
          selectedPatientOrganization?.id
            ? "patient_organizations"
            : "companies"
        }/${selectedPatientOrganization?.id || selectedCompany.id}/videos`;
        await fileUpload(file, pathname, setUploadValues, file.name).then(
          (res) => {
            if (res && res.location) {
              if (videos.indexOf(res.location) === -1) {
                fileList.push(res.location);
                isLastIndex && setLoading(false);
                if (fileList.length === files.length - dismissedFiles) {
                  setVideos([...item.option_values, ...fileList]);
                }
              } else {
                enqueueSnackbar("This video was already added.", {
                  variant: "error",
                });

                isLastIndex && setLoading(false);
                dismissedFiles++;
                setForbiddenFiles((prevFiles) => {
                  const localFiles = [...prevFiles];
                  localFiles.push({
                    name: file.name,
                    error: "Video already added",
                  });
                  return localFiles;
                });

                setUploadValues((prevState) => {
                  const currentProgress = [...prevState];
                  const index = currentProgress
                    .map((item) => item.name)
                    .lastIndexOf(file.name);
                  currentProgress[index] = {
                    progress: "NOT_ALLOWED",
                    name: file.name,
                  };
                  return currentProgress;
                });
              }
            }
          }
        );
      } else {
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === file.name
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: file.name,
          };
          return currentProgress;
        });

        isLastIndex && setLoading(false);
        if (file.size > 500000000) {
          dismissedFiles++;
          enqueueSnackbar(
            "We could not upload your image because your file size is too big. Please make sure the file is less than 500 MB",
            {
              variant: "error",
            }
          );
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error: "Your file is too large. File size limit: 500MB",
            });
            return localFiles;
          });
        }
        if (videoExtensions.indexOf(getExtension(file.name)) === -1) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error:
                "Extension is not allowed, the accepted file formats are: mp4, mov, wmw, flv, avi, webm, mkw.",
            });
            return localFiles;
          });
          isLastIndex && setLoading(false);
          dismissedFiles++;
          // enqueueSnackbar('Extension is not allowed, the accepted file formats are: mp4, mov, wmw, flv, avi, webm, mkw.', {
          //   variant: 'error',
          // });
        }
      }
    });

    e.target.value = "";
  };

  const onSubmit = (values) => {
    handleSubmitDetail(
      {
        ...item,
        options: videos,
        documents_attributes: videos.map((vid) => {
          return {
            uri: vid,
            name: getFilename(vid),
            council_id: selectedCouncil.id,
            added_by_id: session.id,
          };
        }),
      },
      item
    );
  };

  useEffect(() => {
    if (videos?.length) onSubmit();
  }, [videos]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{item?.field_name || "Video"}</h5>
          {item?.field_placeholder?.length ? (
            <span className="text-muted small">{item.field_placeholder}</span>
          ) : null}
        </div>
        <div className={styles.rightWrp}>
          <div className={styles.colMore}>
            {!blockFunctionality && hasAccessToEdit ? (
              <MoreOptions
                options={ACTIVITY_OPTIONS}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            ) : null}
          </div>
        </div>
      </div>
      {item.from_claimed_profile && (
        <span className="d-block small font-weight-bold mb-3">
          Company Response
        </span>
      )}
      <div>
        {uploadValues?.length
          ? uploadValues.map(
              (bar, index) =>
                bar &&
                bar?.progress !== "NOT_ALLOWED" && (
                  <div className={styles.loaderContainer}>
                    <div className={styles.loaderNamingWrp}>
                      {" "}
                      <div className={styles.fileName}>
                        {uploadValues[index]?.name}
                      </div>
                      <LinearProgressWithLabel
                        mode={
                          uploadValues[index]?.progress === "FAILED" && "error"
                        }
                        value={
                          uploadValues[index]?.progress === "FAILED"
                            ? 100
                            : uploadValues[index]?.progress || 0
                        }
                      />
                    </div>
                  </div>
                )
            )
          : null}

        <DragAndDrop
          uploadHandler={uploadHandler}
          loading={loading}
          customText="drag and drop videos here"
          unicumKey={item?.custom_field_template_id}
        />
        {forbiddenFiles?.length ? (
          <div className={styles.forbiddenfileContainer}>
            Files that weren't allowed:{" "}
            {forbiddenFiles.map((file) => (
              <span className={styles.forbiddenfileName}>
                {`${file.name}: `}
                <span className={styles.errorText}> {file.error}</span>
              </span>
            ))}
          </div>
        ) : null}
      </div>

      {loadingId === fieldId ? (
        <Loading />
      ) : (
        <div className={styles.videoWrp}>
          {item && item.documents.length ? videField.displayField() : null}
        </div>
      )}
      <div className={styles.infoWrp}>
        <div className={styles.dataRow}>
          <div className={styles.label}>
            {item.from_submission
              ? "Submitted by"
              : item.updated_at !== item.created_at
              ? "Updated by"
              : "Created by"}
          </div>
          <div className={styles.value}>
            {item.from_submission ? (
              <span>{item?.creator?.full_name || "-"}</span>
            ) : (
              <Link to={`/people/internal/profile/${item.user_id}`}>
                {item && item.creator && item.creator.full_name}
                {item.from_claimed_profile && (
                  <span>, {selectedCompany?.name}</span>
                )}
              </Link>
            )}
          </div>
        </div>
        {item.from_claimed_profile && (
          <div className="d-flex align-items-center mr-5">
            <div className={styles.companyAccess}>
              <span>&#x2713; Access</span>
            </div>
          </div>
        )}
        <div className={styles.date}>
          {item.updated_at !== item.created_at
            ? format(new Date(item.updated_at), "eeee, MMM do, yyyy hh:mm a")
            : format(new Date(item.created_at), "eeee, MMM do, yyyy hh:mm a")}
        </div>
        {item.from_submission ? (
          <div
            className={`d-flex flex-grow-1 align-items-center justify-content-center ${styles.companyResponse}`}
          >
            Company Response
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default React.memo(VideoComponent);

import React from "react";
import { useSelector } from "react-redux";

import NewProjectStepsWrapper from "modules/projects/newProjectv3/newProjectStepsWrapper";
import LoadingComponent from "modules/primitives/loading/loading.component";
import styles from "./styles.module.scss";

const NewProjectMain = () => {
  const {
    councilReducer: { selectedCouncil },
    newProjectV3Reducer: { stageTemplates },
  } = useSelector((state) => state);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrp}>
        <h4 className={styles.title}>Start A New Project</h4>
      </div>
      <div className="mt-5">
        <div className="col-sm-12 col-md-6 m-auto">
          {
            !selectedCouncil || !stageTemplates?.length ? (
              <LoadingComponent customText="Fetching creation steps..." />
            ) : (
              <NewProjectStepsWrapper
                wizardType="main"
                selectedCouncil={selectedCouncil}
              />
            )
          }
        </div>
      </div>
    </div>
  )
};

export default NewProjectMain;

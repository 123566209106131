import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import classNames from "classnames";

import { useModelPopup } from "../../../common/hooks";
import styles from "./styles.module.scss";
import UploadImgComponent from "../../primitives/uploadImg/uploadImg.component";
import TabComponent from "../../primitives/tabs/tabs.component";
import { Button, BtnType, Loading } from "../../primitives/index";
import OverviewTab from "./themesOverviewTab/overviewTab.component";
import {
  themesGetIndividualAttempt,
  themesEditAttempt,
} from "../themesMainPage/themesMain.action";
import {
  themesMemberAddAttempt,
  themesMemberDeleteAttempt,
} from "./themesOverviewTab/overviewTab.action";
import Icon from "../../../common/icon/icon.component";
import { mdSize } from "../../../common/icon";
import ConfirmDeleteComponent from "../../../common/components/confirmDelete/index";
import PipelineTab from "./themesPipelineTab/pipelineTab.component";
import isUserAdmin from "../themesCommon/isUserAdmin/index";
import DocumentsTab from "./themesDocumentsTab/documentsTab.component";
import ProjectsTab from "./themesProjectsTab/projectsTab.component";

import isItSmallDevice from "../themesCommon/isItSmallDevice";
import NewProjectV3 from "modules/projects/newProjectv3";

const ThemesIndividualPageComponent = (props) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: {
        currentThemeData,
        shouldUpdateIndividualTheme,
        loadingIndividualTheme,
      },
      themesOverviewReducer: { companyList, loadingCompany },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTab, setSelectedTab] = useState(0);
  const [isThemeMember, setIsThemeMember] = useState(
    currentThemeData?.current_user_member
  );

  const {
    location: { pathname },
  } = props;

  const popup = useModelPopup();
  const history = useHistory();
  const splittedPathname = pathname.split("/");
  const id = splittedPathname[splittedPathname.length - 2];

  const handleImgUpdate = (url) => {
    const filtredMembers = currentThemeData.members.map((member) => {
      if (member?.user_id) {
        return member?.user_id;
      }
    });
    const filtredCompanies = currentThemeData.companies.map((company) => {
      if (company?.company_id) {
        return company?.company_id;
      }
    });
    const filtredProjects = currentThemeData.projects.map((project) => {
      if (project?.id) {
        return project?.id;
      }
    });

    const data = {
      name: currentThemeData?.name,
      logo: url,
      current_option: currentThemeData?.current_option,
      member_ids: filtredMembers,
      company_ids: filtredCompanies,
      project_ids: filtredProjects,
    };
    dispatch(
      themesEditAttempt({ data, id: currentThemeData?.id, enqueueSnackbar })
    );
  };

  const tabList = [
    {
      tab: "Overview",
      name: `${isItSmallDevice() ? "" : "Overview"}`,
      icon: '<svg width="25" height="26" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3 3.5a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H3zm0 10a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H3z"  fill="#CBD2D9" /></svg>',
      tabIndex: 0,
      component: <OverviewTab currentThemeData={currentThemeData} />,
    },
    {
      tab: "Pipeline",
      name: `${isItSmallDevice() ? "" : "Pipeline"}`,
      icon: '<svg width="25" height="26" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 22.5a1 1 0 0 1-1-1v-18a1 1 0 1 1 2 0v18a1 1 0 0 1-1 1zm4 0a1 1 0 0 1-1-1v-18a1 1 0 1 1 2 0v18a1 1 0 0 1-1 1zm3-1a1 1 0 1 0 2 0v-18a1 1 0 1 0-2 0v18zm5 1a1 1 0 0 1-1-1v-18a1 1 0 1 1 2 0v18a1 1 0 0 1-1 1zm3-1a1 1 0 1 0 2 0v-18a1 1 0 1 0-2 0v18z" fill="#CBD2D9"/></svg>',
      tabIndex: 1,
      component: <PipelineTab />,
    },
    {
      tab: "Documents",
      name: `${isItSmallDevice() ? "" : "Documents"}`,
      icon: '<svg width="26" height="27"  viewBox="0 -4 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g fill="#9AA5B1"><g><g><path d="M10.818 0c.128 0 .244.054.326.138l.002.001 3.718 3.715.004.006.01.008.003.01c.072.08.119.185.119.302v10.555c0 .699-.566 1.265-1.266 1.265H3.266C2.566 16 2 15.434 2 14.735V1.265C2 .566 2.566 0 3.266 0h7.552zM9.447 1H3.168C3.075 1 3 1.075 3 1.166V15h10.832c.093 0 .168-.075.168-.167V6.957l-.01-1.438H9.447V1zM10.5 1v3.5H14L10.5 1zm.479 10H6.021c-.288 0-.521.224-.521.5s.233.5.521.5h4.958c.288 0 .521-.224.521-.5s-.233-.5-.521-.5zm0-3H6.021c-.288 0-.521.224-.521.5s.233.5.521.5h4.958c.288 0 .521-.224.521-.5s-.233-.5-.521-.5z" transform="translate(-199 -207) translate(190 197) translate(9.855 10)"/></g></g></g></g></svg>',
      tabIndex: 2,
      component: <DocumentsTab setSelectedTab={setSelectedTab} />,
    },
    {
      tab: "Projects",
      name: `${isItSmallDevice() ? "" : "Projects"}`,
      icon: '<svg width="26" height="27" viewBox="0 -4 24 25" xmlns="http://www.w3.org/2000/svg"> <path d="M6.848 8.5c.353 0 .652.3.652.652v4.583a.759.759 0 0 1-.765.765h-4.47a.759.759 0 0 1-.765-.765v-4.47c0-.427.337-.765.765-.765zm7 0c.353 0 .652.3.652.652v4.583a.759.759 0 0 1-.765.765h-4.47a.759.759 0 0 1-.765-.765v-4.47c0-.427.337-.765.765-.765zM2.5 9.5V13.5h4v-4l-4-.001zm7 0V13.5h4v-4l-4-.001zM6.848 1.5c.353 0 .652.3.652.652v4.583a.759.759 0 0 1-.765.765h-4.47a.759.759 0 0 1-.765-.765v-4.47c0-.427.337-.765.765-.765zm7 0c.353 0 .652.3.652.652v4.583a.759.759 0 0 1-.765.765h-4.47a.759.759 0 0 1-.765-.765v-4.47c0-.427.337-.765.765-.765zM2.5 2.5V6.5h4v-4l-4-.001zm7 0V6.5h4v-4l-4-.001z" fill="#9AA5B1" fill-rule="evenodd"/> </svg>',
      tabIndex: 3,
      component: <ProjectsTab isFord={selectedCouncil?.name === "Ford"} />,
    },
  ];

  const findTabName = (ix) => {
    const tab = pathname?.split("/")[pathname.split("/").length - 1];
    let tabName;
    let tabIndex;
    const ts = checkConnectedTabs();
    ts.forEach((t, i) => {
      if (ix !== undefined ? ix === i : tab === t?.tab?.toLowerCase()) {
        tabIndex = i;
        tabName = t?.tab.trim().toLowerCase();
        return t;
      }
    });
    return { tabName, tabIndex };
  };

  const adjustPatch = (indexx) => {
    history.push(
      `/themes/individual_page/${id}/${findTabName(indexx).tabName}`
    );
  };
  const handleOnTabSelect = (indexx) => {
    setSelectedTab(indexx);
    adjustPatch(indexx);
  };

  const handleEditTheme = () => {
    // if (currentThemeData?.useRole !== "manager") {
    //   enqueueSnackbar("You're access is not enought to edit theme", {
    //     variant: "error",
    //   });
    // } else {
    history.push(`/themes/individual_page/${id}/editTheme`, { pathname });
    // }
  };

  const handleDeleteTheme = () => {
    popup.show({
      title: "Confirmation Delete",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={id}
          enqueueSnackbar={enqueueSnackbar}
          councilId={selectedCouncil?.id}
          actionType="deleteTheme"
        />
      ),
    });
    // dispatch(themesDeleteAttempt({ id }));
  };

  const handleThemeMember = () => {
    const data = {
      theme_member: [
        {
          user_id: session.id,
          expert: false,
        },
      ],
      user_role: "member",
    };
    if (isThemeMember) {
      dispatch(
        themesMemberDeleteAttempt({
          themeId: currentThemeData.id,
          memberId: session.id,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(
        themesMemberAddAttempt({
          themeId: currentThemeData.id,
          data,
          enqueueSnackbar,
        })
      );
    }
  };

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        themesGetIndividualAttempt({
          id,
          enqueueSnackbar,
        })
      );
      if (pathname) {
        const { tabIndex, tabName } = findTabName();

        handleOnTabSelect(tabIndex, tabName);
      }
    }
  }, [pathname, selectedCouncil]);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        themesGetIndividualAttempt({
          id,
          enqueueSnackbar,
        })
      );
    }
  }, [shouldUpdateIndividualTheme]);

  useEffect(() => {
    if (selectedCouncil) {
      setIsThemeMember(currentThemeData?.current_user_member);
    }
  }, [
    currentThemeData?.current_user_member,
    currentThemeData?.current_user_role,
  ]);

  const checkConnectedTabs = () => {
    if (!selectedCouncil) {
      return [];
    }
    if (session?.council_role === "company") {
      return tabList.filter((tab) => tab.tab !== "Pipeline");
    }

    const filteredTabs = tabList
      .map((tab) => {
        if (
          selectedCouncil?.settings.theme_documents_enabled === false &&
          tab.tab === "Documents"
        ) {
          return null;
        }

        if (
          selectedCouncil?.settings.theme_projects_tab_enabled === false &&
          tab.tab === "Projects"
        ) {
          return null;
        }
        if (
          selectedCouncil?.settings.pipeline_theme_enabled === false &&
          tab.tab === "Pipeline"
        ) {
          return null;
        }

        return tab;
      })
      .filter((tab) => tab);
    return filteredTabs;
  };

  const showForViewOnly = () => {
    if (isUserAdmin(session?.council_role)) {
      return true;
    }

    return (
      currentThemeData?.current_user_role === "manager" ||
      currentThemeData?.current_user_role === "owner"
    );
  };

  return (
    <div className={styles.mainOverview}>
      {session?.council_role !== "company" && (
        <div className={styles.headerImage}>
          {currentThemeData?.logo?.length ? (
            <img
              src={currentThemeData?.logo}
              className={styles.imageBlock}
              alt={currentThemeData?.name}
            />
          ) : (
            <div className={styles.addImageBlock}>
              {loadingIndividualTheme ? (
                <Loading />
              ) : (
                <UploadImgComponent
                  name="themes"
                  imgSize={styles.imageSize}
                  imgStyle={styles.imageStyle}
                  uploadDirName={`themes/${currentThemeData?.id}/key`}
                  onChange={handleImgUpdate}
                />
              )}
            </div>
          )}
        </div>
      )}

      <div className={styles.headerActionsContainer}>
        <div className={styles.headerActionsBlock}>
          <div
            className={classNames(
              styles.titleEditButton,
              session?.council_role === "company" ? "mt-3" : ""
            )}
          >
            <span className={styles.headerTitle}>{currentThemeData?.name}</span>
            <div
              className={
                showForViewOnly()
                  ? styles.editDeleteButton
                  : styles.viewRoleOnlyStyle
              }
            >
              <div className={styles.followButton}>
                <Button
                  btn={isThemeMember ? BtnType.HIGLIGHT : BtnType.OUTLINE}
                  onClick={(e) => {
                    setIsThemeMember(!isThemeMember);
                    handleThemeMember();
                    e.stopPropagation();
                  }}
                >
                  {isThemeMember ? "✓ Following" : "+ Follow"}
                </Button>
              </div>
              {showForViewOnly() && (
                <div className={styles.manageThemeBlock}>
                  <Button
                    icon="icn-edit-button"
                    btn={BtnType.FRAME_LESS}
                    className={styles.editThemeButton}
                    onClick={handleEditTheme}
                  >
                    Edit{" "}
                    {selectedCouncil?.settings?.themes_display_name_singular ||
                      "THEME"}
                  </Button>
                  <div
                    className={styles.deleteButton}
                    onClick={() => handleDeleteTheme()}
                  >
                    <Icon {...mdSize} icon="icn-button-delete"></Icon>
                  </div>
                </div>
              )}
              {selectedCouncil?.name === "Ford"
                ? null
                : selectedCouncil?.name !== "Firmenich" &&
                  !loadingCompany && !loadingIndividualTheme &&
                  session?.council_role !== "company" &&
                  session?.council_role !== "viewer" && (
                    <div className={styles.addNewProjectButtonContainer}>
                      <NewProjectV3
                        wizardType="theme"
                        additionalData={{
                          ...currentThemeData,
                          theme_companies: companyList
                        }}
                        btnName="New Project"
                        className={styles.addNewProject}
                        btnType={selectedCouncil?.name === "GSK" ? BtnType.FRAME_LESS : BtnType.REGULAR}
                      />
                    </div>
                  )}
            </div>
          </div>
        </div>
        <TabComponent
          tabList={checkConnectedTabs()}
          defaultIndex={selectedTab}
          onSelect={(i) => {
            handleOnTabSelect(i);
          }}
          // rightContent={kyndrylRightContent}
        />
      </div>
    </div>
  );
};
export default ThemesIndividualPageComponent;

import { CHANGE_QUERY } from "../actions/common.action";

const initState = {
  query: "",
  type: "",
};

const searchReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_QUERY: {
      return {
        query: payload.query,
        type: payload.type,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default searchReducer;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Loading } from "modules/primitives";

import IconClose from "common/icon/closeIcon.component";

import { solutionsByIdGetAttempt } from "../solutionsMain.actions";

import styles from "./styles.module.scss";
import defaultSolutionImage from "../../../../assets/defaultThemeImage.png";

const SolutionCardModal = (props) => {
  const { solutionId, setModalClose } = props;
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    solutionsReducer: {
      solutionsMainReducer: { selectedSolution, loadingSolutionById },
    },
  } = useSelector((state) => state);

  // const trimDescription = (description) => {
  //   const arrayDescription = description.split("");
  //   arrayDescription.splice(lengthForTrim);
  //   arrayDescription.push("...");
  //   return arrayDescription.join("");
  // };

  const checkForStub = (solutionKey, project) => {
    if (!project[solutionKey] || project[solutionKey].length === 0) {
      return <div className={styles.colItem}>There are no items yet!</div>;
    }
    return null;
  };
  const prepareDataName = (name) => {
    if (name.length > 34) {
      return (
        <Tooltip placement="bottom" title={`${name}`}>
          <span>{name.slice(0, 34) + " ... "}</span>
        </Tooltip>
      );
    }

    return <span>{name}</span>;
  };

  useEffect(() => {
    if (solutionId) {
      dispatch(
        solutionsByIdGetAttempt({
          enqueueSnackbar,
          id: solutionId,
        })
      );
    }
  }, [solutionId]);
  return (
    <div
      className={classnames(styles.cardContainer, styles.hoverCardContainer)}
    >
      <div className={styles.cardImageContainer}>
        {" "}
        <img
          src={
            selectedSolution?.attributes?.image_url
              ? selectedSolution?.attributes?.image_url
              : defaultSolutionImage
          }
          className={styles.cardImage}
        />
        <div
          className={styles.closeIconContainer}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setModalClose();
          }}
        >
          <IconClose />
        </div>
      </div>
      <div className={classnames(styles.aboutCardBlock, styles.scrollableCard)}>
        <div className={styles.textBlock}>
          <div className={styles.titleBlock}>
            <p style={{ width: "50%" }} className={styles.title}>
              {selectedSolution?.attributes?.name}
            </p>

            <div className={(styles.statsItem, styles.averageSavings)}>
              <p className={styles.itemText}>Average Savings:</p>
              <p className={styles.itemNumber}>
                $
                {selectedSolution?.attributes?.avarage_savings
                  ? Number(selectedSolution?.attributes?.avarage_savings)
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                  : ""}
              </p>
            </div>
          </div>

          <p className={styles.description}>
            {selectedSolution?.attributes?.description}
          </p>
        </div>

        <div>
          Technologies:
          <div className={styles.tags}>
            {selectedSolution?.attributes?.topics?.map((topic) => (
              <div className={styles.tag} key={topic.topic_id}>
                <span>{topic.topic_name}</span>
              </div>
            ))}
            {selectedSolution?.attributes?.topics.length === 0
              ? "  There are no technologies added yet!"
              : null}
          </div>
        </div>

        <div className={styles.dataTableContainer}>
          <div className={styles.dataTable}>
            <div className={styles.headerRow}>
              <div className={styles.header}>Projects: </div>
              {selectedCouncil?.traction_tag === "ford" ? (
                <div className={styles.header}>Plants: </div>
              ) : null}

              <div className={styles.header}>
                {selectedCouncil?.settings?.themes_display_name || "Themes"}:{" "}
              </div>
              {selectedCouncil?.traction_tag === "ford" ? (
                <div className={styles.header}>Areas: </div>
              ) : null}
            </div>
            {selectedSolution?.attributes?.projects?.map((project) => (
              <div className={styles.projectRow}>
                <div className={styles.cellItem} key={project?.project_id}>
                  {" "}
                  <Link
                    className={styles.url}
                    to={`/projects/${project.project_id}/overview`}
                  >
                    {prepareDataName(project.project_name)}
                  </Link>
                </div>
                {selectedCouncil?.traction_tag === "ford" ? (
                  <div className={styles.cellItem} key={project?.project_id}>
                    {project.project_plant_name}
                  </div>
                ) : null}

                <div className={styles.cellItem} key={project?.project_id}>
                  {project.project_themes.map((theme) => (
                    <div className={styles.colItem}>
                      <Link
                        className={styles.url}
                        to={`/themes/individual_page/${theme.id}/overview`}
                      >
                        {" "}
                        {prepareDataName(theme?.name)}
                      </Link>
                    </div>
                  ))}
                  {checkForStub("project_themes", project)}
                </div>

                {selectedCouncil?.traction_tag === "ford" ? (
                  <div className={styles.cellItem}>
                    {project.project_areas.map((area) => {
                      return (
                        <div className={styles.colItem} key={area.id}>
                          {prepareDataName(area?.name)}
                        </div>
                      );
                    })}
                    {checkForStub("project_areas", project)}
                  </div>
                ) : null}
              </div>
            ))}
            {loadingSolutionById && <Loading />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionCardModal;

import React, { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from './reportingWrapper.module.scss';
import LoadingComponent from "modules/primitives/loading/loading.component";
import { TabComponent } from "modules/primitives";
import FordKPIsCard from "modules/reporting/fordReports/kpis";
import FordActualScoreCard from "modules/reporting/fordReports/actualScorecard";
import FordPlanScoreCard from "modules/reporting/fordReports/planScorecard";
import FordReplicationsCard from "modules/reporting/fordReports/replicationsCard";

const CompaniesWrapper = lazy(() => import("./modules/Companies"));
const ProjectsWrapper = lazy(() => import("./modules/Projects"));
const IdeasWrapper = lazy(() => import("./modules/Ideas"));

const ReportingWrapper = () => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const currTabQuery = urlParams.get('tab');

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setSelectedIndex(currTabQuery ? Number(currTabQuery) : 0);
  }, [currTabQuery]);

  const handleOnTabSelect = (index) => {
    history.push(`/reporting?tab=${index}`);
  }

  if (!selectedCouncil) {
    return (
      <div className={styles.reportingContainer}>
        <LoadingComponent customText="Loading..." />
      </div>
    )
  }

  if (selectedCouncil?.name === "Ford") {
    let makeTabList = [
      {
        tab: 'reports',
        name: 'Reports',
        component: (
          <Suspense fallback="Loading...">
            <CompaniesWrapper />
            <hr className="mb-5" />
            <ProjectsWrapper />
            <hr className="mb-5" />
            <IdeasWrapper />
          </Suspense>
        )
      },
      {
        tab: 'kpis',
        name: 'KPIs',
        component: (
          <Suspense fallback="Loading...">
            <FordKPIsCard />
          </Suspense>
        )
      },
      {
        tab: 'ActualScoreCard',
        name: 'Actual Scorecard',
        component: (
          <Suspense fallback="Loading...">
            <FordActualScoreCard />
          </Suspense>
        )
      },
      {
        tab: 'PlanScorecard',
        name: 'Plan Scorecard',
        component: (
          <Suspense fallback="Loading...">
            <FordPlanScoreCard />
          </Suspense>
        )
      },
      {
        tab: 'Replications',
        name: 'Replications',
        component: (
          <Suspense fallback="Loading...">
            <FordReplicationsCard />
          </Suspense>
        )
      }
    ];

    const isAdmin = ["ttp_administrator", "council_administrator"].includes(
      session?.council_role
    );

    // temporary
    if (!isAdmin) {
      makeTabList = makeTabList.filter(t => t.tab !== 'Replications');
    }

    return (
      <div className={styles.reportingContainer}>
        <div className={styles.fordReportWrapper}>
          <TabComponent
            tabList={makeTabList}
            defaultIndex={selectedIndex}
            onSelect={handleOnTabSelect}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.reportingContainer}>
      <div className={styles.reportSections}>
        <Suspense fallback="Loading...">
          <CompaniesWrapper />
          <hr className="mb-5" />
          <ProjectsWrapper />
          <hr className="mb-5" />
          <IdeasWrapper />
        </Suspense>
      </div>
    </div>
  );
}

export default React.memo(ReportingWrapper);

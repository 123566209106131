import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { format } from "date-fns";
import styles from "./mobileCompareRow.module.scss";
import {
  CompanyAvatar,
  CompanyState,
  ProgressBar,
  State,
} from "../../../../../primitives";
import RowCheckBoxesListComponent from "../rowCheckboxesList";
import { makeWebSite } from "../../../../../../common/helper";

const MobileCompareRow = ({ selectedRows, item, isPatientOrg }) => {
  const checkRowExist = (rowName) =>
    selectedRows.find((r) => r.name === rowName);

  return (
    <div className={styles.mobileGridRow}>
      <div className={styles.mobileRow}>
        <span className={styles.companyIcon}>
          <div className={styles.iconCon}>
            <CompanyAvatar
              imgSrc={
                isPatientOrg
                  ? item.gsk_patient_organization.logo
                  : item.company.logo
              }
              name={
                isPatientOrg
                  ? item.gsk_patient_organization.name
                  : item.company.name
              }
              hasBorderRadius
            />
          </div>
        </span>
      </div>
      <div className={styles.mobileRow}>
        <span>{isPatientOrg ? "Patient Org name" : "Company name"}</span>
        <div>
          {isPatientOrg ? (
            <Link
              to={`/patient_organizations/${item.gsk_patient_organization.id}`}
            >
              {item.gsk_patient_organization.name}
            </Link>
          ) : (
            <Link to={`/companies/${item.slug || item.company.id}`}>
              {item.company.name}
            </Link>
          )}
        </div>
      </div>
      <>
        {checkRowExist("Project Match") ? (
          <div className={styles.mobileRow}>
            <span>Project Match</span>
            <div>
              {item.normalized_gsk_score
                ? item.normalized_gsk_score.toFixed(1)
                : "-"}
            </div>
          </div>
        ) : null}
        {checkRowExist("General Characteristic") ? (
          <div className={styles.mobileRow}>
            <span>General Characteristic</span>
            <div>
              {item.general_characteristics ? (
                <RowCheckBoxesListComponent
                  data={item.general_characteristics[0]}
                />
              ) : (
                "-"
              )}
            </div>
          </div>
        ) : null}
        {checkRowExist("Supports Clinical Research") ? (
          <div className={styles.mobileRow}>
            <span>Supports Clinical Research</span>
            <div>
              {item.supports_clinical_research ? (
                <RowCheckBoxesListComponent
                  data={item.supports_clinical_research[0]}
                />
              ) : (
                "-"
              )}
            </div>
          </div>
        ) : null}
        {checkRowExist("Involved in Health Policy and Equity") ? (
          <div className={styles.mobileRow}>
            <span>Involved in Health Policy and Equity</span>
            <div>
              {item.involved_in_health_policy_and_equity ? (
                <RowCheckBoxesListComponent
                  data={item.involved_in_health_policy_and_equity[0]}
                />
              ) : (
                "-"
              )}
            </div>
          </div>
        ) : null}
        {checkRowExist("Support for Patients") ? (
          <div className={styles.mobileRow}>
            <span>Support for Patients</span>
            <div>
              {item.support_for_patients ? (
                <RowCheckBoxesListComponent
                  data={item.support_for_patients[0]}
                />
              ) : (
                "-"
              )}
            </div>
          </div>
        ) : null}
        {checkRowExist("Patient Education & Awareness") ? (
          <div className={styles.mobileRow}>
            <span>Patient Education & Awareness</span>
            <div>
              {item.patient_education_and_awareness ? (
                <RowCheckBoxesListComponent
                  data={item.patient_education_and_awareness[0]}
                />
              ) : (
                "-"
              )}
            </div>
          </div>
        ) : null}
        {checkRowExist("Cultural Representation") ? (
          <div className={styles.mobileRow}>
            <span>Cultural Representation</span>
            <div>
              {item.cultural_representation ? (
                <RowCheckBoxesListComponent
                  data={item.cultural_representation[0]}
                />
              ) : (
                "-"
              )}
            </div>
          </div>
        ) : null}
        {checkRowExist("Total funding") ? (
          <div className={styles.mobileRow}>
            <span>Total funding</span>
            <div>{item.company?.total_funding || "-"}</div>
          </div>
        ) : null}
        {checkRowExist("Technology") ? (
          <div className={styles.mobileRow}>
            <span>Technology</span>
            <div className="d-flex flex-grow-1">
              {item.company?.topics && item.company?.topics.length ? (
                <div className="d-flex flex-grow-1 flex-row align-items-center flex-wrap">
                  {item.company.topics.map((t) => (
                    <div
                      className={classNames(
                        styles.colTechnologyItem,
                        "mb-2 mr-2"
                      )}
                      key={t.id}
                    >
                      {t.name}
                    </div>
                  ))}
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>
        ) : null}
        {checkRowExist("Industry") ? (
          <div className={styles.mobileRow}>
            <span>Industry</span>
            <div className="d-flex flex-grow-1">
              {item.company?.industries && item.company?.industries.length ? (
                <div className="d-flex flex-grow-1 flex-row align-items-center flex-wrap">
                  {item.company.industries.map((t) => (
                    <div
                      className={classNames(
                        styles.colIndustryItem,
                        "mb-2 mr-2"
                      )}
                      key={t.id}
                    >
                      {t.name}
                    </div>
                  ))}
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>
        ) : null}
        {checkRowExist("Project Stage") ? (
          <div className={styles.mobileRow}>
            <span>Project Stage</span>
            <div>{item.stage ? <State stageId={item.stage.id} /> : "-"}</div>
          </div>
        ) : null}
        {checkRowExist("Project Score") ? (
          <div className={styles.mobileRow}>
            <span>Project Score</span>
            <div>{item.score || "-"}</div>
          </div>
        ) : null}
        {checkRowExist("Project Status") ? (
          <div className={styles.mobileRow}>
            <span>Project Status</span>
            <div>
              <CompanyState state={item.state} />
            </div>
          </div>
        ) : null}
        {checkRowExist("Task Progress") ? (
          <div className={styles.mobileRow}>
            <span>Task Progress</span>
            <div>
              <ProgressBar
                width="120px"
                tot={100}
                fill={item.total_tasks_progress}
                showTot={false}
                showPrecentage
              />
            </div>
          </div>
        ) : null}
        {checkRowExist("HQ Location") ? (
          <div className={styles.mobileRow}>
            <span>HQ Location</span>
            <div>{item.company?.location || "-"}</div>
          </div>
        ) : null}
        {checkRowExist("Description") ? (
          <div className={styles.mobileRow}>
            <span>Description</span>
            <div>
              {item.company?.description
                ? `${item.company.description.substring(0, 40)}...`
                : "-"}
            </div>
          </div>
        ) : null}
        {checkRowExist("Website") ? (
          <div className={styles.mobileRow}>
            <span>Website</span>
            <div>
              {item.company?.website ? (
                <a
                  className={styles.link}
                  href={item.company.website}
                  target="_blank"
                  rel="noreferrer"
                >
                  {makeWebSite(item.company.website)}
                </a>
              ) : (
                "-"
              )}
            </div>
          </div>
        ) : null}
        {checkRowExist("Founded") ? (
          <div className={styles.mobileRow}>
            <span>Founded</span>
            <div>
              {item.company?.founded
                ? format(new Date(item.company.founded), "MM/dd/yyyy")
                : "-"}
            </div>
          </div>
        ) : null}
        {checkRowExist("Employees") ? (
          <div className={styles.mobileRow}>
            <span>Employees</span>
            <div>{item.company?.company_size || "-"}</div>
          </div>
        ) : null}
        {checkRowExist("Competitors") ? (
          <div className={styles.mobileRow}>
            <span>Competitors</span>
            <div>
              {item.company?.competitors && item.company?.competitors.length
                ? item.company.competitors.map((c, i) => (
                    <div key={`${c.id}-${i}`}>{c.name}</div>
                  ))
                : "-"}
            </div>
          </div>
        ) : null}
        {checkRowExist("Company Contacts") ? (
          <div className={styles.mobileRow}>
            <span>Company Contacts</span>
            <div>
              {item.company?.company_contacts &&
              item.company?.company_contacts.length
                ? item.company.company_contacts.map((c, i) => (
                    <div key={`${c.id}-${i}`}>{c.email}</div>
                  ))
                : "-"}
            </div>
          </div>
        ) : null}
        {checkRowExist("Relationship Owner") ? (
          <div className={styles.mobileRow}>
            <span>Relationship Owner</span>
            <div>
              {item.company?.company_relationship_owners &&
              item.company?.company_relationship_owners.length
                ? item.company?.company_relationship_owners.map(
                    ({ user }, i) => (
                      <div key={`${user?.id}-${i}`}>
                        {user?.first_name} {user?.last_name}
                      </div>
                    )
                  )
                : "-"}
            </div>
          </div>
        ) : null}
      </>
    </div>
  );
};
export default MobileCompareRow;

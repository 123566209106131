import React, { useEffect, useState } from "react";
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js'
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import { logOut } from "modules/auth/auth.action";

function IdleComponent({ testMode }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState('Active');
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);

  useEffect(() => {
    console.log(`Your state is: ${state}`);
  }, [state]);

  const onIdle = () => {
    setState('Idle');
    dispatch(logOut({ enqueueSnackbar }));
    window.location.assign(`${window.location.origin}/login?inactivity=true`);
    localStorage.clear();
  }

  const onActive = () => {
    setState('Active');
  }

  const onMessage = () => {
    setCount(count + 1)
  }

  const {
    getRemainingTime,
    getTabId,
    isLeader,
    isLastActiveTab,
    message
  } = useIdleTimer({
    onIdle,
    onActive,
    onMessage,
    timeout: 1.44e+7, // -> 4 hours in milliseconds
    // timeout: 120000,
    throttle: 10000,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, []);

  const tabId = getTabId() === null ? 'loading' : getTabId().toString()
  const lastActiveTab =
    isLastActiveTab() === null ? 'loading' : isLastActiveTab().toString()
  const leader = isLeader() === null ? 'loading' : isLeader().toString()

  if (!testMode) {
    return '';
  }

  return (
    <>
      <p>Current State: {state}</p>
      <p>Tab ID: {tabId}</p>
      <p>Last Active Tab: {lastActiveTab}</p>
      <p>Is Leader Tab: {leader}</p>
      <p>{remaining} seconds remaining</p>
      <p>{count} messages received</p>
      <button onClick={() => message('foo', true)}>Send Message</button>
    </>
  )
}

export default IdleComponent;

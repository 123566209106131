import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateSessionDetails } from "modules/auth/auth.action";

import styles from "./companyMain.module.scss";
import LeftWindowComponent from "./leftWindow";
import RightWindowComponent from "./rightWindow";
import { setChartsIntroActive } from "./companyMain.action";
import { CompanyDataProvider } from "modules/companies/companyMain/providers/companyDataProvider";

const CompanyMainComponent = () => {
  const {
    appReducer: { chartsDownloadIsActive },
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const location = useLocation();

  const [hideLeftWindow, setHideLeftWindow] = useState(false);

  useEffect(() => {
    if (
      selectedCouncil?.name === "Donaldson" ||
      selectedCouncil?.name === "Ofi"
    ) {
      const state = !(
        !location?.state || Object.keys(location.state).length === 1
      );
      const newSession = {
        ...session,
        charts_intro_shown: {
          bar: state,
          bubble: state,
          pie: state,
        },
      };

      dispatch(
        updateSessionDetails({
          session: newSession,
        })
      );
      dispatch(setChartsIntroActive(!state));
    }
  }, [selectedCouncil, location]);

  useEffect(() => {
    if (!location.state) {
      setHideLeftWindow(false);
      return;
    }

    setHideLeftWindow(
      location.state?.isCrunchSearch &&
        JSON.parse(location.state?.isCrunchSearch)
    );

    return () => null;
  }, [location, setHideLeftWindow]);

  if (!selectedCouncil) return null;

  return (
    <div
      className={`${
        chartsDownloadIsActive ? "align-items-start" : "align-items-center"
      } ${styles.companyWrapper}`}
    >
      {!hideLeftWindow && <LeftWindowComponent className="hideInTab" />}
      <CompanyDataProvider>
        <RightWindowComponent isFullWidth={hideLeftWindow} />
      </CompanyDataProvider>
    </div>
  );
};

export default React.memo(CompanyMainComponent);

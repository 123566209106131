import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import { Button, BtnType } from "../../../primitives/index";
import styles from "./styles.module.scss";
import Icon from "../../../../common/icon/icon.component";
import defaultThemeImage from "../../../../assets/defaultThemeImage.png";
import {
  themesMemberAddAttempt,
  themesMemberDeleteAttempt,
} from "../../themesIndividualPage/themesOverviewTab/overviewTab.action";
import { themesFavAttempt, themesFavDeleteAttempt } from "../themesMain.action";
import classnames from "classnames";

const ThemeCard = (props) => {
  const { cardData, fromHome = false } = props;
  const history = useHistory();
  const [isFavorite, setIsFavorite] = useState(cardData.current_user_favorite);
  const [isThemeMember, setIsThemeMember] = useState(
    cardData.current_user_member
  );
  const [tag, setTag] = useState("");
  const lengthForTrim = 140;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    authReducer: { session },
    themesReducer: {
      themesMainReducer: { favPeding },
    },
  } = useSelector((state) => state);

  const handleRedirectToIndividualPage = () => {
    history.push({
      pathname: `/themes/individual_page/${cardData?.id}/overview`,
      state: { cardData },
    });
  };

  const trimDesctiption = (description) => {
    const arrayDescription = description.split("");
    arrayDescription.splice(lengthForTrim);
    arrayDescription.push("...");
    return arrayDescription.join("");
  };

  const handleThemeFav = () => {
    if (favPeding) {
      return;
    }
    if (isFavorite) {
      dispatch(
        themesFavDeleteAttempt({
          themeId: cardData.id,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(
        themesFavAttempt({
          themeId: cardData.id,
          enqueueSnackbar,
        })
      );
    }
    setIsFavorite((prev) => !prev);
  };

  const handleThemeMember = () => {
    const data = {
      theme_member: [
        {
          user_id: session.id,
          expert: false,
        },
      ],
      user_role: "member",
    };
    if (isThemeMember) {
      dispatch(
        themesMemberDeleteAttempt({
          themeId: cardData.id,
          memberId: session.id,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(
        themesMemberAddAttempt({
          themeId: cardData.id,
          data,
          enqueueSnackbar,
        })
      );
    }
  };

  useEffect(() => {
    if (cardData?.current_option?.length) {
      const modifiedTag = [];
      for (let i = 0; i < cardData.current_option.length; i++) {
        if (i === 0) {
          modifiedTag.push(cardData.current_option[0].toUpperCase());
        } else if (cardData.current_option[i] === "_") {
          modifiedTag.push(" ", cardData.current_option[i + 1].toUpperCase());
        } else if (cardData.current_option[i] !== "s") {
          modifiedTag.push(cardData.current_option[i]);
        }
      }
      setTag(modifiedTag.join(""));
    }
  }, [cardData.current_option]);

  return (
    <div
      className={
        fromHome ? classnames(styles.cardContainer, styles.homeCardContainer) : styles.cardContainer
      }
      onClick={() => {
        handleRedirectToIndividualPage();
      }}
    >
      <div className={styles.cardImageContainer}>
        {" "}
        <img
          src={cardData?.logo ? cardData?.logo : defaultThemeImage}
          className={styles.cardImage}
        />
        <div className={isThemeMember ? styles.joinButtonTrue : styles.joinButtonFalse}>
          <Button
            btn={isThemeMember ? BtnType.HIGLIGHT : BtnType.OUTLINE}
            onClick={(e) => {
              setIsThemeMember(!isThemeMember);
              handleThemeMember();
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {isThemeMember ? "✓ Following" : "+ Follow"}
          </Button>
        </div>
      </div>
      <div className={styles.aboutCardBlock}>
        <div className={styles.textBlock}>
          <div className={styles.titleBlock}>
            <p className={styles.title}>{cardData?.name}</p>
            <Icon
              className={isFavorite ? styles.favoriteEnable : styles.favoriteDissable}
              onClick={(e) => {
                handleThemeFav();
                e.preventDefault();
                e.stopPropagation();
              }}
              icon="icn-subnav-ratings-fill"
            />
          </div>
          <p className={styles.posts}>{!cardData?.theme_posts_read && "New Posts"}</p>
          <p className={styles.description}>
            {cardData?.description?.length > lengthForTrim
              ? trimDesctiption(cardData?.description)
              : cardData?.description}
          </p>
        </div>
        <div className={styles.dataBlock}>
          <div className={styles.tag}>{tag}</div>
          <div className={styles.cardStats}>
            <div className={styles.postsCount}>
              {cardData?.theme_posts_count > 0
                ? `${cardData?.theme_posts_count} Posts & Replies`
                : " 0 Posts"}
            </div>
            {cardData?.companies_count && (
              <div className={styles.companiesCount}>{cardData?.companies_count} Companies</div>
            )}
            {cardData?.members_count > 0 && (
              <div className={styles.membersCount}>{cardData?.members_count} Followers</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeCard;

import React, {
  memo, useCallback, useEffect, useState,
} from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { DropSelect } from "../../../../../primitives";
import {
  AXIS_DATA,
  CUSTOM_YEARS_LIST,
  X_AXIS_BAR,
  BAR_COLORS,
} from "../charts-constants";
import styles from "./filters.module.scss";

const BarFilters = ({ handleSelect, total, setShowAlertMessage }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    companiesReducer: {
      companyMainReducer: {
        charts: { chartOptions },
      },
    },
  } = useSelector((state) => state);

  const [y, setY] = useState([]);
  const [x, setX] = useState([]);
  const filteredOptionsByCriteria = AXIS_DATA.filter(
    (option) => option.type !== "numberOfEmployees",
  );

  useEffect(() => {
    if (chartOptions) {
      if (chartOptions.y === "yearsInOperation") {
        const newX = X_AXIS_BAR.filter(
          (item) => item.type !== "sectors" && item.type !== "yearFounded",
        );

        setX(newX);
        return;
      }

      if (chartOptions.y === "numberOfCompanies") {
        const newX = X_AXIS_BAR.filter((item) => item.type !== "companies");

        setX(newX);
        return;
      }

      if (chartOptions.y === "companies") {
        const newX = X_AXIS_BAR.filter((item) => item.type !== "sectors");

        setX(newX);
        return;
      }

      setX(X_AXIS_BAR);
    }
  }, [chartOptions]);

  useEffect(() => {
    if (chartOptions) {
      if (
        chartOptions.x === "sectors"
        || chartOptions.x === "yearFounded"
        || chartOptions.x === "numberOfEmployees"
        || chartOptions.x === "revenue_range"
      ) {
        const Y = filteredOptionsByCriteria.filter(
          (c) => c.type !== "council_company_score" && c.type !== "yearsInOperation",
        );
        setY(Y);
        return;
      }

      if (chartOptions.x === "companies") {
        const Y = filteredOptionsByCriteria.filter(
          (c) => c.type !== "numberOfCompanies",
        );
        setY(Y);
        return;
      }

      const Y = filteredOptionsByCriteria.filter(
        (c) => c.type !== "council_company_score",
      );
      setY(Y);
    }
  }, [chartOptions]);

  const handleXSelect = useCallback(
    (prop, val) => {
      if (total > 50 && val === "companies") {
        setShowAlertMessage(true);
        enqueueSnackbar("Apply some filters to generate more accurate chart.", {
          variant: "warning",
        });

        return;
      }

      setShowAlertMessage(false);

      if (val === "revenue_range") {
        const bulk = {
          ...chartOptions,
          color: "revenue_range",
          x: "revenue_range",
        };
        handleSelect(null, null, bulk);
        return;
      }

      if (val !== "revenue_range" && chartOptions.x === "revenue_range") {
        const bulk = {
          ...chartOptions,
          color: "technology",
          x: val,
        };
        handleSelect(null, null, bulk);
        return;
      }

      handleSelect(prop, val);
    },
    [handleSelect, total, chartOptions],
  );

  const color = chartOptions.x === "yearFounded"
    ? BAR_COLORS
    : BAR_COLORS.filter((c) => c.type !== "year");

  const handleFilterSelect = (prop, value) => {
    if (prop === "x") {
      handleXSelect(prop, value);

      return;
    }

    if (prop === "color" && value === "revenue_range") {
      const bulk = {
        ...chartOptions,
        color: "revenue_range",
        x: "revenue_range",
      };
      handleSelect(null, null, bulk);
      return;
    }

    if (
      prop === "color"
      && value !== "revenue_range"
      && chartOptions.color === "revenue_range"
    ) {
      const bulk = {
        ...chartOptions,
        color: value,
        x: "sectors",
      };
      handleSelect(null, null, bulk);
      return;
    }

    handleSelect(prop, value);
  };

  return (
    <div className="d-flex w-100 bubble-options justify-content-between">
      <div className="d-flex">
        <div
          className={classNames(
            "d-flex flex-column ml-3 mr-3 custom-drop-width",
            chartOptions.y === chartOptions.x ? styles.hoverField : "",
            styles.chartsSelect,
          )}
        >
          <label className="mb-1" htmlFor="yAxis">
            Y-Axis
          </label>
          <DropSelect
            placeholder=""
            name="yAxis"
            options={y}
            labelField="name"
            valueField="type"
            searchBy="name"
            values={filteredOptionsByCriteria.filter(
              (c) => c.type === chartOptions.y,
            )}
            onChange={(e) => handleFilterSelect("y", e.val)}
            dropdownPosition="auto"
          />
        </div>
        <div
          className={classNames(
            "d-flex flex-column mr-3 custom-drop-width",
            chartOptions.x === chartOptions.y ? styles.hoverField : "",
            styles.chartsSelect,
          )}
        >
          <label className="mb-1" htmlFor="xAxis">
            X-Axis
          </label>
          <DropSelect
            placeholder=""
            name="xAxis"
            options={x}
            labelField="name"
            valueField="type"
            searchBy="name"
            values={X_AXIS_BAR.filter((c) => c.type === chartOptions.x)}
            onChange={(e) => handleFilterSelect("x", e.val)}
            dropdownPosition="auto"
          />
        </div>
        {chartOptions.x === "yearFounded" && (
          <div className="d-flex flex-column mr-3 custom-drop-width">
            <label className="mb-1" htmlFor="yearsInChart">
              Years
            </label>
            <DropSelect
              placeholder=""
              name="yearsInChart"
              options={CUSTOM_YEARS_LIST}
              labelField="name"
              valueField="value"
              searchBy="name"
              values={CUSTOM_YEARS_LIST.filter(
                (c) => c.value === chartOptions.years,
              )}
              onChange={(e) => handleFilterSelect("years", e.val)}
              dropdownPosition="auto"
            />
          </div>
        )}
      </div>
      <div className="d-flex">
        <div
          className={classNames(
            "d-flex flex-column custom-drop-width custom-drop-position",
            styles.chartsSelect,
          )}
        >
          <label className="mb-1" htmlFor="color">
            Breakdown
          </label>
          <DropSelect
            disabled={
              chartOptions.x === "yearFounded"
              || chartOptions.x === "numberOfEmployees"
            }
            placeholder=""
            name="color"
            options={color}
            labelField="name"
            valueField="type"
            searchBy="name"
            values={BAR_COLORS.filter((c) => c.type === chartOptions.color)}
            onChange={(e) => handleFilterSelect("color", e.val)}
            dropdownPosition="auto"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(BarFilters);

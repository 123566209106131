export const APPLICANTS_FORMS_LIST_GET_ATTEMPT = "APPLICANTS_FORMS_LIST_GET_ATTEMPT";
export const applicantsFormsListGet = (payload) => ({
  type: APPLICANTS_FORMS_LIST_GET_ATTEMPT,
  payload,
});

export const APPLICANTS_FORMS_LIST_GET_SUCCESS = "APPLICANTS_FORMS_LIST_GET_SUCCESS";
export const applicantsFormsListGetSuccess = (payload) => ({
  type: APPLICANTS_FORMS_LIST_GET_SUCCESS,
  payload,
});

export const FORM_CUSTOM_FIELDS_LIST_GET_ATTEMPT = "FORM_CUSTOM_FIELDS_LIST_GET_ATTEMPT";
export const getFormCustomFields = (payload) => ({
  type: FORM_CUSTOM_FIELDS_LIST_GET_ATTEMPT,
  payload,
});

export const FORM_CUSTOM_FIELDS_LIST_GET_SUCCESS = "FORM_CUSTOM_FIELDS_LIST_GET_SUCCESS";
export const formCustomFieldsGetSuccess = (payload) => ({
  type: FORM_CUSTOM_FIELDS_LIST_GET_SUCCESS,
  payload,
});

export const FORM_CUSTOM_FIELDS_CREATE_ATTEMPT = "FORM_CUSTOM_FIELDS_CREATE_ATTEMPT";
export const createFormCustomField = (payload) => ({
  type: FORM_CUSTOM_FIELDS_CREATE_ATTEMPT,
  payload,
});

export const FORM_CUSTOM_FIELDS_CREATE_SUCCESS = "FORM_CUSTOM_FIELDS_CREATE_SUCCESS";
export const formCustomFieldCreateSuccess = (payload) => ({
  type: FORM_CUSTOM_FIELDS_CREATE_SUCCESS,
  payload,
});

export const SET_SELECTED_FORM_CUSTOM_FIELDS = "SET_SELECTED_FORM_CUSTOM_FIELDS";
export const setSelectedFormCustomFields = (payload) => ({
  type: SET_SELECTED_FORM_CUSTOM_FIELDS,
  payload,
});

export const GET_APPLICANT_FORM_ATTEMPT = "GET_APPLICANT_FORM_ATTEMPT";
export const getApplicantForm = (payload) => ({
  type: GET_APPLICANT_FORM_ATTEMPT,
  payload,
});

export const GET_APPLICANT_FORM_SUCCESS = "GET_APPLICANT_FORM_SUCCESS";
export const getApplicantFormSuccess = (payload) => ({
  type: GET_APPLICANT_FORM_SUCCESS,
  payload,
});

export const CREATE_APPLICANT_FORM_ATTEMPT = "CREATE_APPLICANT_FORM_ATTEMPT";
export const createApplicantForm = (payload) => ({
  type: CREATE_APPLICANT_FORM_ATTEMPT,
  payload,
});

export const CREATE_APPLICANT_FORM_SUCCESS = "CREATE_APPLICANT_FORM_SUCCESS";
export const createApplicantFormSuccess = (payload) => ({
  type: CREATE_APPLICANT_FORM_SUCCESS,
  payload,
});

export const DELETE_APPLICANT_FORM_ATTEMPT = "DELETE_APPLICANT_FORM_ATTEMPT";
export const deleteApplicantForm = (payload) => ({
  type: DELETE_APPLICANT_FORM_ATTEMPT,
  payload,
});

export const DELETE_APPLICANT_FORM_SUCCESS = "DELETE_APPLICANT_FORM_SUCCESS";
export const deleteApplicantFormSuccess = (payload) => ({
  type: DELETE_APPLICANT_FORM_SUCCESS,
  payload,
});

export const UPDATE_APPLICANT_FORM_ATTEMPT = "UPDATE_APPLICANT_FORM_ATTEMPT";
export const updateApplicantForm = (payload) => ({
  type: UPDATE_APPLICANT_FORM_ATTEMPT,
  payload,
});

export const UPDATE_APPLICANT_FORM_SUCCESS = "UPDATE_APPLICANT_FORM_SUCCESS";
export const updateApplicantFormSuccess = (payload) => ({
  type: UPDATE_APPLICANT_FORM_SUCCESS,
  payload,
});

import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Icon, xsSize } from "common/icon";
import styles from "../adminTags.module.scss";
import { MoreOptions, Loading } from "../../../primitives";

const AdminCustomTagTable = (props) => {
  const { tagGroup, arrayKey, loading, handleMoreOptionClick, moreOptions } =
    props;
  const [defaultExpandedRows, setDefaultExpandedRows] = useState([]);

  const extendRow = (rowId) => {
    const coincidence = defaultExpandedRows?.find(
      (elementId) => elementId === rowId
    );
    if (coincidence) {
      const filteredRows = defaultExpandedRows?.filter(
        (elementId) => elementId !== rowId
      );
      setDefaultExpandedRows(filteredRows);
      return;
    }
    setDefaultExpandedRows((prevState) => [...prevState, rowId]);
  };
  return (
    <>
      <div className={styles.tableHeader}>
        <div className={styles.nameTab}>
          <span>Custom Tags</span>
        </div>
        <div className={styles.childTags}>
          <span>Child Tags</span>
        </div>
        <div className={styles.optionTab}>
          <span>Options</span>
        </div>
      </div>
      {loading && (
        <div className={styles.loadingContainer}>
          <Loading customText="Updating tags database. Please wait . . ." />
        </div>
      )}
      <div key={tagGroup.id} className={styles.tableContainer}>
        <div
          className={`${styles.accordion} ${styles.tagGroupName}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {Boolean(tagGroup[arrayKey]?.length) &&
            tagGroup[arrayKey].map((parentTag) => {
              const shouldExtend = defaultExpandedRows?.find(
                (rowId) => rowId === parentTag.id
              );
              return (
                <Accordion key={parentTag.id} expanded={Boolean(shouldExtend)}>
                  <AccordionSummary
                    className={styles.accordionRow}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className={styles.tableRow}>
                      <div
                        onClick={() => {
                          extendRow(parentTag.id);
                        }}
                        className={styles.rowNameChild}
                      >
                        <div style={{ marginLeft: 20 }}>
                          {Boolean(
                            parentTag?.attributes?.children?.data?.length
                          ) ? (
                            <Icon
                              icon="icn-right-chevron"
                              {...xsSize}
                              className={`${styles.iconColor} ${
                                shouldExtend && styles.rotateIcon
                              }`}
                            />
                          ) : (
                            <Icon
                              icon="icn-right-chevron"
                              {...xsSize}
                              className={`${styles.iconColor} ${styles.spacing}`}
                            />
                          )}
                          <span style={{ marginLeft: 15 }}>
                            {parentTag.attributes.name}
                          </span>
                        </div>
                      </div>
                      <div className={styles.rowChildren}>
                        <span>
                          {parentTag?.attributes?.children?.data?.length}
                        </span>
                      </div>
                      <div className={styles.rowOptions}>
                        <MoreOptions
                          options={moreOptions.filter(
                            (el) => el.val === "delete"
                          )}
                          className={styles.moreOptWrp}
                          onClick={(e) => {
                            handleMoreOptionClick(e, parentTag);
                          }}
                        />
                      </div>
                    </div>
                  </AccordionSummary>
                  {/* start of children */}

                  {Boolean(parentTag?.attributes?.children?.data?.length) &&
                    parentTag?.attributes?.children?.data.map((childEl) => {
                      return (
                        <div key={childEl.id}>
                          <div
                            className={styles.accordionRow}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <div className={styles.tableRow}>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                className={styles.rowNameChild}
                              >
                                <div style={{ marginLeft: 30 }}>
                                  {Boolean(childEl[arrayKey]?.length) && (
                                    <Icon
                                      icon="icn-right-chevron"
                                      {...xsSize}
                                      className={`${styles.iconColor}`}
                                    />
                                  )}
                                  <span style={{ marginLeft: 25 }}>
                                    {childEl.attributes.name}
                                  </span>
                                </div>
                              </div>
                              <div className={styles.rowChildren}>
                                <span>{childEl[arrayKey]?.length}</span>
                              </div>
                              <div className={styles.rowOptions}>
                                <MoreOptions
                                  options={moreOptions.filter(
                                    (el) => el.val === "delete"
                                  )}
                                  className={styles.moreOptWrp}
                                  onClick={(e) => {
                                    handleMoreOptionClick(e, childEl);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {/* end of children */}
                </Accordion>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default AdminCustomTagTable;

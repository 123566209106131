import * as Yup from "yup";

export const initModel = {
  first_name: "",
  last_name: "",
  avatar: "",
  email: "",
  mobile: "",
  title: "",
  name: "",
  department: "",
};

export const contactValidation = Yup.object().shape({
  first_name: Yup.string()
    .trim("The contact first name cannot include leading and trailing spaces")
    .required("First name is required"),
  last_name: Yup.string()
    .trim("The contact last name cannot include leading and trailing spaces")
    .required("Last name is required"),
  email: Yup.string()
    .trim("The contact email cannot include leading and trailing spaces")
    .required("Email is required"),
  // department: Yup.string().trim('The contact department cannot include leading and trailing spaces').required('Department is required'),
  // title: Yup.string().trim('The contact title cannot include leading and trailing spaces').required('Title is required'),
});

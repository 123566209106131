import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useSnackbar } from "notistack";

import { useDispatch, useSelector } from "react-redux";
import { getAvailableIntegrations } from "modules/admin/adminIntegrations/adminIntegrations.actions";
import { Button, BtnType } from "../../primitives/index";
import styles from "./integrationsSettings.module.scss";
import useIntegrationHelper from "../../../common/hooks/useIntegrationHelper.hook";

const IntegrationsSettings = () => {
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const {
    adminIntegrationsReducer: { integrations, loading },
  } = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const msftHelper = useIntegrationHelper({
    name: "Microsoft",
    iconStyles: styles.icon,
  });
  const salesforceHelper = useIntegrationHelper({
    name: "Salesforce",
    iconStyles: styles.icon,
  });

  useEffect(() => {
    dispatch(getAvailableIntegrations({ enqueueSnackbar }));
  }, []);

  useEffect(() => {
    const msftData = integrations.find((elem) => elem.name === "Microsoft");
    if (msftData?.users_integrations[0]?.token) {
      localStorage.setItem("microsoft_login", true);
    }
    const salesforceData = integrations.find(
      (elem) => elem.name === "Salesforce",
    );
    if (salesforceData?.users_integrations[0]?.token) {
      localStorage.setItem("salesforce_login", true);
    }
  }, [integrations]);

  const handleConfigureSalesforce = () => {
    history.push({
      pathname: "/salesforce/configureData",
      state: {
        attribute: "user",
      },
    });
  };

  const helper = {
    Microsoft: msftHelper,
    Salesforce: salesforceHelper,
  };

  return (
    <>
      {integrations?.map((service) => (service?.councils_integrations?.find(
        (e) => e.integration_id === service.id,
      )?.status
        && service?.councils_integrations?.find(
          (e) => e.integration_id === service.id,
        )?.admin_connected ? (
          <div className="d-flex justify-content-center" id={service.id}>
            <div className={styles.box}>
              <div className="d-flex">
                {helper[service.name].icon}
                <h3 className="m-0">{service.name}</h3>
              </div>
              {service?.users_integrations[0]?.token ? (
                <div className="d-inline-block">
                  {service.name === "Salesforce"
                    && service?.users_integrations[0]?.token && (
                      <Button
                        className="mr-2"
                        btn={BtnType.REGULAR}
                        onClick={handleConfigureSalesforce}
                      >
                        CONFIGURE
                      </Button>
                  )}
                  <Button
                    btn={BtnType.DISABLED}
                    onClick={() => helper[service.name].onDisconnect(service)}
                    className="float-right"
                  >
                    DISCONNECT
                  </Button>
                  {service.name === "Microsoft" && (
                    <p>[{accounts[0].username}]</p>
                  )}
                </div>
              ) : (
                <Button
                  btn={BtnType.REGULAR}
                  onClick={() => helper[service.name].onClick(service)}
                >
                  CONNECT
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="inline-flex justify-content-center">
            <h4 className="d-flex justify-content-center mt-5">
              No Integrations are activated for this account.
            </h4>
          </div>
        )))}
    </>
  );
};

export default React.memo(IntegrationsSettings);

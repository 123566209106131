import React from "react";
import { Link } from "react-router-dom";

import styles from './styles.module.scss';

const RecommendProject = ({ project, index }) => {
  return (
    <div className="d-flex">
      <div style={{ width: '40px' }}>{index + 1}.</div>
      <div className={styles.project}>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center mb-2">
            <Link
              to={`/projects/${project.project_id}`}
              target="_blank"
              className={styles.name}
            >
              {project.project_name}
            </Link>
          </div>
          {
            project.complete_justification && (
              <>
                <span>Why Recommended</span>
                <p>{project.complete_justification}</p>
              </>
            )
          }
          {
            project.complete_justification && (
              <>
                <span>Project Description</span>
                <p>{project.project_description}</p>
              </>
            )
          }
          {
            project.project_technologies && (
              <>
                <span>Project Technologies</span>
                <p>{project.project_technologies}</p>
              </>
            )
          }
          {
            project.project_themes && (
              <>
                <span>Project Themes</span>
                <p>{project.project_themes}</p>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default React.memo(RecommendProject);

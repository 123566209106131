import React from "react";
import { useDispatch, useSelector } from "react-redux";

import FordNrOfProjectsStages from "modules/reporting/fordReports/kpis/sections/nrOfProjectsStages";
import FordProjectNumbers from "modules/reporting/fordReports/kpis/sections/projectNumbers";
import FordClientPlants from "modules/reporting/fordReports/kpis/sections/clientPlants";
import FordInnovationCategory from "modules/reporting/fordReports/kpis/sections/innovationCategory";
import FordNrOfReplications from "modules/reporting/fordReports/kpis/sections/nrOfReplications";
import FordNextPhase from "modules/reporting/fordReports/kpis/sections/nextPhaseChange";
import FordActiveFilters from "modules/reporting/fordReports/filters/activeFilters";
import {
  getKPIsClientPlants,
  getKPIsInnovationCategory,
  getKPIsNextPhaseChange,
  getKPIsNrOfProjects, getKPIsNrsData,
  getKPIsReplicationsNr,
  getKPIsTableData,
  setKPIsCardFilters,
} from "modules/reporting/fordReports/kpis/store/kpis.actions";

const FordKPIsSections = ({ downloadIsActive }) => {
  const dispatch = useDispatch();
  const {
    fordDashboardReducer: { kpisReducer }
  } = useSelector((state) => state);

  const setFilters = (newFilters) => {
    dispatch(setKPIsCardFilters(newFilters));
  };

  const getTableData = (newFilters) => {
    dispatch(getKPIsTableData(newFilters));
  };

  const getUpdatedSectionData = (newFilters) => {
    dispatch(getKPIsNrOfProjects(newFilters));
    dispatch(getKPIsClientPlants(newFilters));
    dispatch(getKPIsInnovationCategory(newFilters));
    dispatch(getKPIsNextPhaseChange(newFilters));
    dispatch(getKPIsReplicationsNr(newFilters));
    dispatch(getKPIsNrsData(newFilters));
  };

  return (
    <>
      <FordActiveFilters
        filters={kpisReducer.filters}
        expandedTableKey={kpisReducer.tableData?.expandedKey}
        highLightColumn={kpisReducer.tableData?.highLightColumn}
        setFilters={setFilters}
        getTableData={getTableData}
        getUpdatedData={getUpdatedSectionData}
      />
      <div className="row mb-2 mt-3">
        <div className="col-8 pr-1">
          <FordNrOfProjectsStages
            nrOfProjectsStages={kpisReducer.nrOfProjectsStages}
            filters={kpisReducer.filters}
            tableData={kpisReducer.tableData}
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="col-4 pl-1">
          <FordProjectNumbers
            nrsData={kpisReducer.nrsData}
            filters={kpisReducer.filters}
            tableData={kpisReducer.tableData}
            downloadIsActive={downloadIsActive}
          />
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-8 pr-1">
          <div className="row">
            <div className="col-4 pr-1">
              <FordClientPlants
                clientPlants={kpisReducer.clientPlants}
                filters={kpisReducer.filters}
                tableData={kpisReducer.tableData}
                downloadIsActive={downloadIsActive}
              />
            </div>
            <div className="col-4 pr-1 pl-1">
              <FordInnovationCategory
                innovationCategory={kpisReducer.innovationCategory}
                filters={kpisReducer.filters}
                tableData={kpisReducer.tableData}
                downloadIsActive={downloadIsActive}
              />
            </div>
            <div className="col-4 pl-1">
              <FordNextPhase
                nextPhaseChange={kpisReducer.nextPhaseChange}
                filters={kpisReducer.filters}
                tableData={kpisReducer.tableData}
                downloadIsActive={downloadIsActive}
              />
            </div>
          </div>
        </div>
        <div className="col-4 pl-1">
          <FordNrOfReplications
            nrOfReplications={kpisReducer.nrOfReplications}
            filters={kpisReducer.filters}
            tableData={kpisReducer.tableData}
            downloadIsActive={downloadIsActive}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(FordKPIsSections);

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./style.module.scss";
import {
  CheckBox,
  CheckType,
  Button,
  BtnType,
  CompanyAvatar,
} from "../../../../../primitives";
import { ModalFooter, ModalBody } from "../../../../../../application/modal";
import { useModelPopup } from "../../../../../../common/hooks";

const SelectProjectsComponent = ({
  onSave,
  selectedCompanies,
  isPatientOrg,
}) => {
  const popup = useModelPopup();
  const {
    projectsReducer: {
      companyReducer: { patientOrganizations },
      companyCompairReducer: { companyList = [] },
    },
  } = useSelector((state) => state);

  const [companies, setCompanies] = useState([]);
  const [selectAll, setSelectAll] = useState(selectedCompanies.length === 0);

  useEffect(() => {
    if (companyList && !isPatientOrg) {
      const activeCompanies = companyList.filter(
        ({ state }) => state !== "removed"
      );
      const updatedCompanies = !selectedCompanies.length
        ? activeCompanies.map(({ company }) => ({ ...company, selected: true }))
        : activeCompanies.map(({ company }) => {
            const checkIsSelected = selectedCompanies.find(
              (c) => c.id === company.id && c.selected
            );
            return { ...company, selected: !!checkIsSelected };
          });

      setSelectAll(
        !(
          selectedCompanies.length > 0 &&
          updatedCompanies.length !== selectedCompanies.length
        )
      );

      setCompanies(updatedCompanies);
    }

    if (!companies.length && patientOrganizations && isPatientOrg) {
      const activeOrganizations = patientOrganizations.filter(
        ({ state }) => state !== "removed"
      );
      const updatedCompanies = !selectedCompanies.length
        ? activeOrganizations.map(({ gsk_patient_organization }) => ({
            ...gsk_patient_organization,
            selected: true,
          }))
        : activeOrganizations.map(({ gsk_patient_organization }) => {
            const checkIsSelected = selectedCompanies.find(
              (c) => c.id === gsk_patient_organization.id && c.selected
            );
            return { ...gsk_patient_organization, selected: !!checkIsSelected };
          });
      setSelectAll(
        !(
          selectedCompanies.length > 0 &&
          updatedCompanies.length !== selectedCompanies.length
        )
      );
      setCompanies(updatedCompanies);
    }

    // return () => null;
  }, [companyList, selectedCompanies, isPatientOrg]);

  const setCheckCompany = (id, selected) => {
    setSelectAll(false);
    const updatedCompanies = companies.map((c) => {
      if (c.id === id) {
        return {
          ...c,
          selected,
        };
      }
      return c;
    });
    setCompanies(updatedCompanies);
  };

  const onSelectAllChange = (val) => {
    setSelectAll(val);
    const updatedCompanies = companies.map((c) => ({
      ...c,
      selected: val,
    }));
    setCompanies(updatedCompanies);
  };

  const handleSaveClick = () => {
    onSave(companies.filter((c) => c.selected));
    popup.hide();
  };

  const handleCancelClick = () => {
    popup.hide();
  };

  const renderSelectAllCheckbox = () => (
    <div className={styles.colCompanyName}>
      <CheckBox
        checkType={CheckType.BLUE}
        isChecked={selectAll}
        onChange={onSelectAllChange}
      />
      <div
        className={styles.cursorPointer}
        onClick={() => onSelectAllChange(!selectAll)}
      >
        Select All
      </div>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <ModalBody>
        <div className={styles.container}>
          {renderSelectAllCheckbox()}

          {companies.map((company) => (
            <div key={company.id} className={styles.colCompanyName}>
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={company.selected}
                onChange={(val) => setCheckCompany(company.id, val)}
              />
              <div
                onClick={() => setCheckCompany(company.id, !company.selected)}
                className={styles.iconCon}
              >
                <CompanyAvatar imgSrc={company.logo} name={company.name} />
              </div>
              <div
                onClick={() => setCheckCompany(company.id, !company.selected)}
                className={styles.cursorPointer}
              >
                {company.name}
              </div>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="mr-3">
          <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
            Cancel
          </Button>
        </div>
        <Button type="submit" btn={BtnType.REGULAR} onClick={handleSaveClick}>
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default React.memo(SelectProjectsComponent);

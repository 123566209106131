import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CSVLink } from "react-csv";
import ReactTable from "react-table";
import Select from "@mui/material/Select";
import classNames from "classnames";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import { withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import SCReview from "common/handleSCReview";

import checkThemePipelinePermissions from "../../../../common/checkThemePipelinePermissions";
import AddCompanyComponent from "../../../companies/companyMain/addToProject";
import MultiUsersComponent from "../../../primitives/multiUsers/multiUsers.component";
import CompanyInline from "../../../primitives/companyInline/companyInline.component";
import {
  CompanyRatings,
  CompanyRatingsHeader,
} from "../../../companies/companyMain/rightWindow/newCompaniesList/newCompanyRatings";
import { sliderSetting } from "application/app.constants";
import {
  companyFollowManyAttempt,
  companyUnFollow,
  companyUnfollowManyAttempt,
  suggestionFollow,
} from "../../../../common/actions/common.action";
import { editDetails } from "modules/companies/company/company.action";
import {
  BtnType,
  Button,
  Loading,
  MoreOptions,
  Pagination,
  UserInline,
  UserInlineTypes,
} from "../../../primitives";
import { useModelPopup } from "../../../../common/hooks/index";
import styles from "./myLists.module.scss";
import {
  followListMoreOptions,
  myListMoreOptions,
} from "modules/companies/companyMain/companyMain.constant";
import ShareWithComponent from "../../../companies/company/popups/shareWith";
import ConfirmDeleteComponent from "../../../../common/components/confirmDelete";
import { clearExportedCompanies } from "modules/companies/companyMain/companyMain.action";
import {
  companySavedListGetById,
  removeCompanyFromSavedList,
  searchCompaniesListsByQueryAttempt,
  getFollowingListCompaniesAttempt,
} from "../../store/lists.actions";
import { getFollowersAttempt } from "modules/settings/settings.action";
import { PAGINATION_DEFAULTS } from "modules/companies/constants";
import { httpGet } from "../../../../common/httpCall";
import EditListName from "./editListName";
import ManageSharing from "./ManageSharing.component";
import { checkViewerRole } from "common/checkers/viewerChecker";
import {
  appSliderSettingSet,
  highlightItemWithIdAction,
} from "application/app.action";
import DownloadReport from "common/components/downloadReport";
import NewCompanyItemResultHoverComponent from "../../../companies/companyMain/rightWindow/newCompaniesList/newCompanyItemResultHover/newCompanyItemResultHover.component";
import AddToIdeaComponent from "../../../companies/companyMain/addToIdea/addToIdea.component";
import ListNotFound from "./listNotFound";
import ListSkeleton from "./listSkeleton";
import AddToThemeComponent from "../../../themes/themesCommon/addCompanyToTheme";
import { editProductDetails } from "modules/companies/product/store/product.actions";
import PDFTitleAndTractionLogo from "modules/reporting/fordReports/pdfTitleAndTractionLogo";

const sortAttributes = {
  documents: {
    name: "name",
    funding: "total_funding",
    score: "council_company_score",
    year: "year_founded",
    employees: "employee_range",
    stage: "council_relationship_stage_name",
    investment: "theme_investment_stage_name",
  },

  list: {
    name: "name_downcase",
    funding: "total_fundings_sum",
    score: "council_company_score",
    year: "year_founded",
    employees: "num_employees_enum",
    stage: "council_relationship_stage_position",
    partner_type: "partner_type",
    investment: "theme_investment_stage_name",
  },
  following: {
    name: "name_downcase",
    funding: "total_fundings_sum",
    score: "council_company_score",
    year: "year_founded",
    employees: "num_employees_enum",
    stage: "council_relationship_stage_position",
    partner_type: "partner_type",
    investment: "theme_investment_stage_name",
  },
};
let delayTimerId;
const width = window.innerWidth > 1200 ? 800 : 600;
const tdStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "5px",
  paddingBottom: "5px",
  paddingLeft: 0,
  paddingRight: 0,
};

const idbTdStyles = {
  width: "150px",
  overflow: "auto",
};

const MyListsComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showCsv, setShowCsv] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [pageSetting, setPageSetting] = useState(PAGINATION_DEFAULTS);
  const [listItems, setListItems] = useState(null);
  const [isViewer, setIsViewer] = useState(false);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const history = useHistory();
  const csvLinkRef = useRef();
  const csvLinkFollowingRef = useRef();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const selectedCouncil = JSON.parse(window.localStorage.getItem("council"));
  const [selectedPage, setSelectedPage] = useState(1);
  const [sortAtrr, setSortAtrr] = useState("name");
  const [sortOrd, setSortOrd] = useState(true);
  const location = useLocation();
  const [listNameLoading, setListNameLoading] = useState(false);

  const {
    appReducer: { relationShipStatuses, highlightItemWithId },
    authReducer: { session },
    settingsReducer: { deletedFollower, updateFollowers, buttonLoader },
    listsMainReducer: {
      savedList,
      selectedList,
      shouldSavedListUpdate,
      updateSavedList,
      shouldUpdateListItems,
      noListFound,
      listCompanies,
      loadingListCompaniesSearch,
      loadingListCompanies,
      listCompaniesPageMeta,
    },
    companiesReducer: {
      companyReducer: { listId, listItemToUpdate },
      companyMainReducer: { shouldUpdateListItemsFromCompany },
    },
    productReducer: { productDetails },
  } = useSelector((state) => state);

  const getStageStyles = (status) => {
    const getStageDetails = relationShipStatuses.find((s) => s.name === status);
    return {
      backgroundColor: getStageDetails?.colors?.background || "",
      color: getStageDetails?.colors?.text || "",
    };
  };
  useEffect(() => {
    setPageSetting((prev) => ({
      ...prev,
      total: listCompaniesPageMeta?.total + (savedList.products_count || 0),
      current: listCompaniesPageMeta?.page,
      pageSize: 50,
    }));
  }, [listCompaniesPageMeta, savedList]);

  useEffect(() => {
    if (selectedList === "following") {
      const newListCompanies = listCompanies?.map((rel) => ({
        ...rel.following,
        industries_names: rel.industries_names,
        topics_names: rel.topics_names,
        council_relationship_stage_name: rel.council_relationship_stage_name,
        current_user_follow_relationship_id:
          rel.current_user_follow_relationship_id,
        theme_investment_stage_id: rel.theme_investment_stage_id,
        theme_investment_stage_name: rel.theme_investment_stage_name,
      }));
      const newItems = [
        ...(savedList?.products?.map((p) => ({ ...p, isProduct: true })) || []),
        ...newListCompanies,
      ];
      setListItems(newItems);
    } else {
      const newItems = [
        ...(savedList?.products?.map((p) => ({ ...p, isProduct: true })) || []),
        ...listCompanies,
      ];
      setListItems(newItems);
    }
  }, [listCompanies, savedList]);

  useEffect(() => {
    //set array with shared members without initial member "list owner", for multiUsers component
    if (session?.id) {
      setSharedUsers(
        savedList?.list_members
          ?.filter(({ member }) => {
            return member.id !== session?.id;
          })
          .map(({ member }) => {
            return {
              avatar: member.avatar,
              first_name: member.first_name,
              last_name: member.last_name,
              id: member.id,
            };
          })
      );
    }
  }, [savedList, session]);

  useEffect(() => {
    if (
      shouldUpdateListItems?.shouldUpdate ||
      shouldUpdateListItemsFromCompany?.shouldUpdate
    ) {
      if (
        shouldUpdateListItems?.type === "following" ||
        shouldUpdateListItemsFromCompany?.type === "following"
      ) {
        dispatch(
          getFollowingListCompaniesAttempt({
            selectedCouncil,
            page: selectedPage,
            enqueueSnackbar,
            sort_attribute: sortAttributes.following[sortAtrr],
            sort_order: sortOrd ? "asc" : "desc",
            value: searchInput,
          })
        );
      }
      if (
        shouldUpdateListItems?.type === "company" ||
        shouldUpdateListItemsFromCompany?.type === "company"
      ) {
        dispatch(
          searchCompaniesListsByQueryAttempt({
            value: "",
            page: selectedPage,
            listId: selectedList,
            enqueueSnackbar,
          })
        );
      }
    }
  }, [shouldUpdateListItems, shouldUpdateListItemsFromCompany]);

  const handleDropdownSelect = (
    e,
    companyId,
    listId,
    isProduct,
    productCompany,
    stageName
  ) => {
    const { value } = e.target;
    const getStageDetails = relationShipStatuses.find(
      (s) => s.id || s.slug === value
    );
    if (
      checkThemePipelinePermissions(
        getStageDetails?.name || "none",
        session?.council_role,
        selectedCouncil?.name,
        session?.decision_maker_access,
        stageName
      )
    ) {
      if (isProduct) {
        const productPayload = {
          data: {
            relationship_stage_id:
              relationShipStatuses.filter(
                (stage) => stage.slug === e.target.value
              )[0]?.customId || null,
          },
          companyId: productCompany?.id,
          productId: companyId,
        };

        dispatch(editProductDetails(productPayload));
      } else {
        const data = {
          council_relationship_stage_id:
            relationShipStatuses.filter(
              (stage) => stage.slug === e.target.value
            )[0]?.customId || null,
        };

        dispatch(
          editDetails({
            companyId: companyId,
            councilId: selectedCouncil.id,
            data,
            enqueueSnackbar,
            listId,
          })
        );
      }
    } else {
      enqueueSnackbar("You are not allowed to move companies in this stage ", {
        variant: "error",
      });
    }
  };
  const handleChangeStage = (
    e,
    companyId,
    listId,
    slug,
    isProduct,
    company,
    stageName
  ) => {
    if (selectedCouncil?.name === "Kyndryl" && e.target.value === "sc_review") {
      const { value } = e.target;
      const getStageDetails = relationShipStatuses.find(
        (s) => s.id || s.slug === value
      );

      if (
        !checkThemePipelinePermissions(
          getStageDetails?.name || "none",
          session?.council_role,
          selectedCouncil?.name,
          session?.decision_maker_access,
          stageName
        )
      ) {
        // handleDropdownSelect(e);
        e.preventDefault();
        e.stopPropagation();
        enqueueSnackbar(
          "You are not allowed to move companies in this stage ",
          {
            variant: "error",
          }
        );
        return;
      }
      popup.show({
        title: " ",
        show: true,
        height: "300",
        width: "540",
        component: (
          <SCReview
            handleStageSelect={handleDropdownSelect}
            e={e}
            companyId={companyId}
            listId={listId}
            popup={popup}
            companySlug={slug}
            isProduct={isProduct}
            company={company}
            stageName={stageName}
          />
        ),
      });
    } else {
      handleDropdownSelect(e, companyId, listId, isProduct, company, stageName);
    }
  };
  useEffect(() => {
    if (listId && listItems?.length && listItems[0]) {
      const newListItems = listItems.map((el) => {
        if (
          el.id === listItemToUpdate.id ||
          el.company_id === listItemToUpdate.id
        ) {
          el.council_relationship_stage_name =
            listItemToUpdate.council_relationship_stage_name;
          el.theme_investment_stage_id =
            listItemToUpdate.theme_investment_stage?.id ||
            listItemToUpdate.theme_investment_stage_id;
          el.theme_investment_stage_name =
            listItemToUpdate.theme_investment_stage?.name ||
            listItemToUpdate.theme_investment_stage?.name;
        }
        return el;
      });
      const newItems = [
        ...(savedList?.products?.map((p) => ({ ...p, isProduct: true })) || []),
        ...newListItems.filter(
          (c) => !savedList?.products?.map((p) => p.id).includes(c.id)
        ),
      ];
      setListItems(newItems);
    }
  }, [listId, listItemToUpdate, savedList]);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);
  useEffect(() => {
    if (selectedList && selectedList !== "following") {
      delayTimerId = window.setTimeout(function () {
        dispatch(
          searchCompaniesListsByQueryAttempt({
            value: searchInput,
            page: selectedPage,
            listId: selectedList,
            enqueueSnackbar,
            sort_attribute: sortAttributes.list[sortAtrr],
            sort_order: sortOrd ? "asc" : "desc",
          })
        );
      }, 3000);
    }
    if (selectedList === "following") {
      dispatch(
        getFollowingListCompaniesAttempt({
          selectedCouncil,
          page: 1,
          enqueueSnackbar,
          sort_attribute: sortAttributes.following[sortAtrr],
          sort_order: sortOrd ? "asc" : "desc",
          value: searchInput,
        })
      );
    }
  }, [selectedList, sortAtrr, sortOrd]);

  useEffect(() => {
    if (listItems && !listItems[0]?.name && selectedList === "following") {
      dispatch(
        getFollowingListCompaniesAttempt({
          selectedCouncil,
          page: 1,
          enqueueSnackbar,
          sort_attribute: sortAttributes.following[sortAtrr],
          sort_order: sortOrd ? "asc" : "desc",
          value: searchInput,
        })
      );
    }
  }, [listItems?.length]);

  useEffect(() => {
    if (shouldSavedListUpdate) {
      if (selectedList && selectedList !== "following") {
        dispatch(
          searchCompaniesListsByQueryAttempt({
            value: "",
            page: 1,
            listId: selectedList,
            enqueueSnackbar,
            sort_attribute: sortAttributes.list[sortAtrr],
            sort_order: sortOrd ? "asc" : "desc",
          })
        );
      }
      if (selectedList === "following") {
        dispatch(
          getFollowingListCompaniesAttempt({
            selectedCouncil,
            page: 1,
            enqueueSnackbar,
            sort_attribute: sortAttributes.following[sortAtrr],
            sort_order: sortOrd ? "asc" : "desc",
            value: searchInput,
          })
        );
      }
    }
  }, [shouldSavedListUpdate]);

  useEffect(() => {
    if (updateFollowers) {
      if (selectedList && selectedList !== "following") {
        dispatch(
          searchCompaniesListsByQueryAttempt({
            value: "",
            page: 1,
            listId: selectedList,
            enqueueSnackbar,
            sort_attribute: sortAttributes.list[sortAtrr],
            sort_order: sortOrd ? "asc" : "desc",
          })
        );
      }
      if (selectedList === "following") {
      }
    }
  }, [updateFollowers]);

  useEffect(() => {
    setShowCsv(null);
    setCsvData([]);
    dispatch(clearExportedCompanies());
  }, [selectedList]);

  useEffect(() => {
    if (selectedList !== "following") {
      dispatch(
        getFollowersAttempt({
          data: { councilId: selectedCouncil.id },
          enqueueSnackbar,
        })
      );
    }
  }, [selectedList]);

  useEffect(() => {
    if (savedList?.list_members && session?.id) {
      const currentUserSession = savedList?.list_members?.find((item) => {
        return item.member.id === session?.id && item.user_role === "viewer";
      });
      if (currentUserSession) {
        setIsViewer(true);
      } else setIsViewer(false);
    }
  }, [savedList, session]);

  const isFollowList = selectedList === "following";
  const currentUserRole =
    session?.id === savedList?.creator?.id
      ? "owner"
      : savedList.list_members?.find(
          (member) => member?.member?.id === session?.id
        )?.user_role;

  const listOptions = [
    currentUserRole &&
      currentUserRole !== "viewer" &&
      savedList.list_members?.length > 0 &&
      savedList?.scope !== "council" && {
        val: 1,
        text: "Manage Sharing",
      },
    currentUserRole &&
      currentUserRole === "owner" && {
        val: 2,
        text: "Delete list",
      },
  ].filter((option) => option !== false);

  const handleDeleteSavedList = () => {
    popup.show({
      title: "Confirmation Prompt",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={selectedList}
          enqueueSnackbar={enqueueSnackbar}
          actionType={"deleteList"}
        />
      ),
    });
  };

  const handleShareList = () => {
    popup.show({
      title: "Share With",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ShareWithComponent
          actionType="shareList"
          selectedList={selectedList}
          memberList={savedList.list_members}
          fromLists={true}
          memberIds={savedList.list_members.map((el) => el.member.id)}
          sharedUsers={sharedUsers}
          currentUserRole={currentUserRole}
        />
      ),
    });
  };

  const handleShowEditListName = () => {
    popup.show({
      title: "Edit List Name",
      show: true,
      height: "300",
      width: "540",
      component: (
        <EditListName
          selectedList={selectedList}
          selectedListName={savedList.name}
          setListNameLoading={setListNameLoading}
        />
      ),
    });
  };

  const handleSubscription = () => {
    if (savedList.following) {
      dispatch(
        companyUnfollowManyAttempt({
          listId: selectedList,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(
        companyFollowManyAttempt({
          listId: selectedList,
          enqueueSnackbar,
        })
      );
    }
  };

  const handleRemoveMemberFromList = () => {
    popup.show({
      title: "Manage List Sharing",

      show: true,
      height: "300",
      width: "540",
      component: <ManageSharing savedList={savedList} />,
    });
  };

  const handleListOptionsClick = (val) => {
    if (val === 1) {
      handleRemoveMemberFromList();
    }
    if (val === 2) {
      handleDeleteSavedList();
    }
  };

  const handleMoreOptionClick = (val, company) => {
    const companyId =
      selectedList === "following" || company.isProduct
        ? company.id
        : company.company_id || company.id;

    if (val === 4) {
      const data = {
        listId: selectedList,
        companyId,
        enqueueSnackbar,
        isProduct: company.isProduct,
      };
      dispatch(removeCompanyFromSavedList(data));
    }
    if (val === 1) {
      if (!company.current_user_follow_relationship_id) {
        dispatch(
          suggestionFollow({
            data: { ...company, company_id: companyId },
            enqueueSnackbar,
          })
        );
      } else {
        dispatch(
          companyUnFollow({
            data: {
              follow_relationship_id:
                company.current_user_follow_relationship_id,
              name: company.name,
              companyId,
            },
            enqueueSnackbar,
          })
        );
      }
    }
    if (val === 3) {
      popup.show({
        title: "Add Company To Project",
        show: true,
        height: "300",
        width: "540",
        component: (
          <AddCompanyComponent
            companyId={companyId}
            enqueueSnackbar={enqueueSnackbar}
            isProduct={company.isProduct}
          />
        ),
      });
    }
    if (val === 6) {
      popup.show({
        title: "Add To Idea",
        component: (
          <AddToIdeaComponent
            company={{
              ...company,
              id: companyId,
            }}
            selectedCouncil={selectedCouncil}
            enqueueSnackbar={enqueueSnackbar}
          />
        ),
      });
    }
    if (val === 7) {
      popup.show({
        title: `Add To ${
          selectedCouncil?.settings?.themes_display_name_singular || "Theme"
        }`,
        component: (
          <AddToThemeComponent
            company={{ id: companyId, ...company }}
            selectedCouncil={selectedCouncil}
            enqueueSnackbar={enqueueSnackbar}
          />
        ),
      });
    }
  };

  const handleOpenCreatorProfile = () => {
    history.push(`/people/internal/profile/${savedList.creator?.id}`);
  };

  const handleGoToCompanies = () => {
    history.push("/companies");
  };

  const moreListOptions = (item) => {
    if (selectedList === "following") {
      if (blockFunctionality) {
        return followListMoreOptions
          .filter((option) => {
            if (
              (selectedCouncil?.name === "GSK" &&
                option.text === "Add to Idea") ||
              (!selectedCouncil?.themes_connected &&
                option.text === "Add to theme")
            ) {
              return false;
            }
            if (
              selectedCouncil?.settings?.ideas_connected === false &&
              option.text === "Add to Idea"
            ) {
              return false;
            }
            return option.text !== "Add To Project";
          })
          .map((o) => {
            if (o.text === "Add to theme") {
              return {
                ...o,
                text: `Add to ${
                  selectedCouncil?.settings?.themes_display_name_singular ||
                  "theme"
                }`,
              };
            }

            if (o.val === 1) {
              return {
                ...o,
                text: item.current_user_follow_relationship_id?.length
                  ? "Unfollow"
                  : "Follow",
              };
            }

            return o;
          });
      }
      return followListMoreOptions
        .map((o) => {
          if (o.text === "Add to theme") {
            return {
              ...o,
              text: `Add to ${
                selectedCouncil?.settings?.themes_display_name_singular ||
                "theme"
              }`,
            };
          }
          if (o.val === 1) {
            return {
              ...o,
              text: item.current_user_follow_relationship_id?.length
                ? "Unfollow"
                : "Follow",
            };
          }

          return o;
        })
        .filter((option) => {
          if (
            selectedCouncil?.settings?.ideas_connected === false &&
            option.text === "Add to Idea"
          ) {
            return false;
          }
          return !(
            selectedCouncil?.name === "GSK" && option.text === "Add to Idea"
          );
        });
    } else {
      const followerListOptions = myListMoreOptions
        .filter((option) => {
          if (isViewer && option.text === "Remove") {
            return false;
          }

          if (
            selectedCouncil?.settings?.ideas_connected === false &&
            option.text === "Add to Idea"
          ) {
            return false;
          }

          if (
            selectedCouncil?.name === "GSK" &&
            option.text === "Add to Idea"
          ) {
            return false;
          }

          return (
            option.text !==
            (item.current_user_follow_relationship_id?.length
              ? "Follow"
              : "Unfollow")
          );
        })
        .map((opt) => {
          if (opt.text === "Add to theme") {
            return {
              ...opt,
              text: `Add to ${
                selectedCouncil?.settings?.themes_display_name_singular ||
                "theme"
              }`,
            };
          }
          return opt;
        });

      const currentUser = savedList?.list_members?.find(
        (item) => session?.id === item.member.id
      );

      if (blockFunctionality || !currentUser) {
        return followerListOptions.filter((option) => {
          return option.text !== "Add To Project";
        });
      } else return followerListOptions;
    }
  };

  useEffect(() => {
    if (selectedCouncil && selectedList === "following" && updateFollowers) {
      dispatch(
        getFollowingListCompaniesAttempt({
          selectedCouncil,
          page: selectedPage,
          enqueueSnackbar,
          sort_attribute: sortAttributes.following[sortAtrr],
          sort_order: sortOrd ? "asc" : "desc",
          value: searchInput,
        })
      );
    }
  }, [deletedFollower, updateFollowers]);

  useEffect(() => {
    if (updateSavedList) {
      dispatch(companySavedListGetById({ id: selectedList, enqueueSnackbar }));
    }
  }, [updateSavedList]);

  React.useMemo(() => {
    if (selectedList && selectedList !== "following" && !updateFollowers) {
      dispatch(companySavedListGetById({ id: selectedList, enqueueSnackbar }));
    }
  }, [selectedList, productDetails]);

  useEffect(() => {
    if (updateFollowers) {
      dispatch(companySavedListGetById({ id: selectedList, enqueueSnackbar }));
    }
  }, [updateFollowers, selectedList]);

  const handleExportFollowingList = () => {
    setExportLoading(true);
    httpGet({
      call: `follow_relationships?council_id=${selectedCouncil.id}&items=${pageSetting.total}`,
    }).subscribe(
      async (res) => {
        setExportLoading(false);
        setCsvData(() => {
          const csvDataCont = [
            [
              "Company Name",
              "Website",
              "Company description",
              "Score",
              "Total Funding",
              "Year Founded",
              "Employees",
              "Status",
            ],
          ];
          res.response.map(({ following }) => {
            csvDataCont.push([
              following.name,
              following.web_url,
              following.short_description,
              following.council_company_score,
              following.total_funding,
              following.year_founded,
              following.employee_range,
              following.council_relationship_stage_name,
            ]);
          });

          return csvDataCont;
        });
        csvLinkFollowingRef.current.link.click();
      },

      (err) => {
        setExportLoading(false);
        enqueueSnackbar("Something went wrong.", {
          variant: "error",
        });
      },
      () => {
        setExportLoading(false);
        enqueueSnackbar("List succesfully exported.", {
          variant: "success",
        });
      }
    );
  };

  const handlePagingChange = (selectedPage) => {
    if (pageSetting.current === selectedPage) {
      return;
    }

    if (selectedList === "following") {
      dispatch(
        getFollowingListCompaniesAttempt({
          selectedCouncil,
          page: selectedPage,
          enqueueSnackbar,
          sort_attribute: sortAttributes.following[sortAtrr],
          sort_order: sortOrd ? "asc" : "desc",
          value: searchInput,
        })
      );

      return;
    }

    if (searchInput) {
      dispatch(
        searchCompaniesListsByQueryAttempt({
          value: searchInput,
          page: selectedPage,
          listId: selectedList,
          enqueueSnackbar,
          sort_attribute: sortAttributes.list[sortAtrr],
          sort_order: sortOrd ? "asc" : "desc",
        })
      );
    } else {
      dispatch(
        searchCompaniesListsByQueryAttempt({
          value: "",
          page: selectedPage,
          listId: selectedList,
          enqueueSnackbar,
          sort_attribute: sortAttributes.list[sortAtrr],
          sort_order: sortOrd ? "asc" : "desc",
        })
      );
    }
    setSelectedPage(selectedPage);
  };

  const openRatingsWindow = (ratingsData, company) => {
    dispatch(highlightItemWithIdAction(company.company_id || company.id));

    dispatch(
      appSliderSettingSet({
        ...sliderSetting,
        show: true,
        height: "300",
        width: "540",
        header: (
          <CompanyRatingsHeader
            company={{
              ...company,
              id: company.company_id || company.id,
            }}
          />
        ),
        component: (
          <CompanyRatings
            type={selectedList === "following" ? selectedList : "company"}
            company={{
              ...company,
              id: company.company_id || company.id,
            }}
            ratingsData={ratingsData}
          />
        ),
      })
    );
  };

  const handleRateClick = async (company) => {
    try {
      httpGet({
        apiVersion: "v2",
        call: `companies/${company.company_id || company.id}/ratings`,
      })
        .pipe()
        .subscribe((res) => {
          if (res.response) {
            openRatingsWindow(res.response, company);
          }
        });
    } catch (err) {
      console.log("error---------", err);
    }
  };

  const capitalizeListName = (listName) => {
    return listName.charAt(0).toUpperCase() + listName.slice(1);
  };
  const setSortValues = (attrSelected) => {
    setSortAtrr((prev) => {
      if (prev === attrSelected) {
        setSortOrd((prevOrd) => !prevOrd);
      }
      return attrSelected;
    });
  };

  const HeaderButton = ({ label, onClick }) => {
    return (
      <div className={classNames(styles.iconsContainer)} onClick={onClick}>
        {label}
        <div className={classNames(styles.icons)}>
          <span>▲</span>
          <span>▼</span>
        </div>
      </div>
    );
  };
  // function to Change Investment Stage
  // const handleChangeInvestmentStage = (evt, companyId, listId, slug) => {
  //   const data = {
  //     theme_investment_stage_id:
  //       selectedCouncil?.theme_investment_stages.filter(
  //         (stage) => stage.slug === evt.target.value
  //       )[0]?.id || null,
  //   };

  //   dispatch(
  //     editDetails({
  //       companyId,
  //       councilId: selectedCouncil.id,
  //       data,
  //       enqueueSnackbar,
  //       council: selectedCouncil?.name,
  //       fromFirmenich: selectedCouncil?.name === "Firmenich",
  //       listId,
  //       type: selectedList === "following" ? selectedList : "company",
  //     })
  //   );
  // };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#fafafa",
      boxShadow: "5px 5px 4px 0 rgba(0, 0, 0, 0.14)",
      color: "rgba(0, 0, 0, 0.87)",
      width,
      minWidth: width,
      fontSize: theme.typography.pxToRem(15),
      border: "1px solid #cccccc",
      padding: "15px 20px 10px",
    },
  }))(Tooltip);

  const columns = React.useMemo(() => {
    const getLongRow = (text) => {
      if (!text) return "-";
      if (text.length > 100) {
        return `
         <Tooltip title="${text.replace(/"/g, "")}" placement="top">
          <div class="${styles.moreCell}">
            <p>
              ${text.slice(0, 110)} ...
              <span class="${styles.moreLink}">MORE</span>
            </p>
          </div>
         </Tooltip>`;
      }
      return `<div class="${styles.moreCell}">
        <p>${text}</p>
      </div>`;
    };
    const idbSpecificCol = [
      {
        Header: (
          <HeaderButton
            label={
              selectedCouncil?.name === "IDB" ? "Country" : "Total Funding"
            }
            onClick={(e) => {
              e.preventDefault();
              setSortValues("funding");
            }}
          />
        ),
        accessor:
          selectedCouncil?.name === "IDB"
            ? "location_country"
            : "total_fundings_sum",
        Cell: (props) => {
          if (selectedCouncil?.name === "IDB") {
            return (
              <span
                className={styles.clickableRow}
                onClick={() =>
                  history.push(
                    `/companies/${
                      props.original.slug ||
                      props.original.id ||
                      props.original.company_id
                    }`
                  )
                }
              >
                {props.original?.location_country
                  ? props.original?.location_country
                  : "-"}
              </span>
            );
          }

          return (
            <span
              className={styles.clickableRow}
              onClick={() =>
                history.push(
                  `/companies/${
                    props.original.slug ||
                    props.original.id ||
                    props.original.company_id
                  }`
                )
              }
            >
              {props.original?.total_funding
                ? props.original?.total_funding
                : "-"}
            </span>
          );
        },
        sortable: false,
        style: tdStyles,
        show: selectedCouncil?.traction_tag !== "westrock",
      },
      {
        Header: (
          <HeaderButton
            label="Brief Description"
            onClick={(e) => {
              e.preventDefault();
              setSortValues("brief_description");
            }}
          />
        ),
        accessor: "brief_description",
        Cell: (props) => {
          return (
            <span
              className={styles.clickableRow}
              onClick={() =>
                history.push(
                  `/companies/${
                    props.original.slug ||
                    props.original.id ||
                    props.original.company_id
                  }/details`
                )
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: getLongRow(props.original?.brief_description),
                }}
              />
            </span>
          );
        },
        sortable: true,
        style: tdStyles,
        show: selectedCouncil?.traction_tag === "westrock",
      },
      {
        Header: (
          <HeaderButton
            label="Why Noted"
            onClick={(e) => {
              e.preventDefault();
              setSortValues("why_noted");
            }}
          />
        ),
        accessor: "why_noted",
        Cell: (props) => {
          return (
            <span
              className={styles.clickableRow}
              onClick={() =>
                history.push(
                  `/companies/${
                    props.original.slug ||
                    props.original.id ||
                    props.original.company_id
                  }/details`
                )
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: getLongRow(props.original?.why_noted),
                }}
              />
            </span>
          );
        },
        sortable: true,
        style: tdStyles,
        show: selectedCouncil?.traction_tag === "westrock",
      },
      {
        Header: "IDBG Connection",
        accessor: "idb_connection",
        Cell: (props) => {
          return (
            <div className={styles.tagsCon}>
              {props.original?.idb_connection?.map((name) => (
                <Button
                  key={name}
                  btn={BtnType.OUTLINE_BLANK}
                  rounded
                  className={styles.tag}
                >
                  {name}
                </Button>
              ))}
            </div>
          );
        },
        sortable: false,
        show: selectedCouncil?.name === "IDB",
        style: { ...idbTdStyles, ...tdStyles },
        width: 200,
      },
      {
        Header: "Technology",
        accessor: "topics_names",
        Cell: (props) => {
          return (
            <div className={styles.tagsCon}>
              {props.original?.topics_names?.map((topic) => (
                <Button
                  key={topic}
                  btn={BtnType.TAG_BLUE_LIGHT}
                  rounded
                  className={styles.tag}
                >
                  {topic}
                </Button>
              ))}
            </div>
          );
        },
        sortable: false,
        show: selectedCouncil?.name === "IDB",
        style: { ...idbTdStyles, ...tdStyles },
        width: 220,
      },
      {
        Header: "Industry",
        accessor: "industries_names",
        Cell: (props) => {
          return (
            <div className={styles.tagsCon}>
              {props.original?.industries_names?.map((ind) => (
                <Button
                  key={ind}
                  btn={BtnType.TAG_OUTLINE_BLUE}
                  rounded
                  className={styles.tag}
                >
                  {ind}
                </Button>
              ))}
            </div>
          );
        },
        sortable: false,
        show: selectedCouncil?.name === "IDB",
        style: { ...idbTdStyles, ...tdStyles },
        width: 200,
      },
    ];

    const kyndrylSpecificCol = {
      Header: (
        <HeaderButton
          label={
            selectedCouncil?.name === "Kyndryl"
              ? "Project Stage"
              : "Year Founded"
          }
          onClick={(e) => {
            e.preventDefault();
            setSortValues("year");
          }}
        />
      ),
      accessor:
        selectedCouncil?.name === "Kyndryl"
          ? "project_stage.name"
          : "year_founded",
      Cell: (props) => {
        if (selectedCouncil?.name === "Kyndryl") {
          return (
            <span
              className={styles.clickableRow}
              onClick={() =>
                history.push(
                  `/companies/${
                    props.original.slug ||
                    props.original.id ||
                    props.original.company_id
                  }`
                )
              }
            >
              {props.original?.project_stage?.name || "-"}
            </span>
          );
        }
        return (
          <span
            className={styles.clickableRow}
            onClick={() =>
              history.push(
                `/companies/${
                  props.original.slug ||
                  props.original.id ||
                  props.original.company_id
                }`
              )
            }
          >
            {props.original?.year_founded ? props.original?.year_founded : "-"}
          </span>
        );
      },
      sortable: false,
      style: tdStyles,
    };

    return [
      {
        Header: (
          <HeaderButton
            label="Company "
            onClick={(e) => {
              e.preventDefault();
              setSortValues("name");
            }}
          />
        ),
        accessor: "name",
        sortable: false,
        Cell: (props) => {
          return (
            <span className={styles.listItemName}>
              <HtmlTooltip
                title={
                  <>
                    <NewCompanyItemResultHoverComponent
                      {...{
                        company: {
                          total_fundings_sum:
                            props?.original.total_funding?.substring(1) || "",
                          description: props?.original.description || "-",
                          logo: props?.original.isProduct
                            ? props?.original?.company.logo
                            : props?.original.logo || "-",
                          web_url: props?.original.web_url || "-",
                          founded_at: props?.original?.year_founded || "-",
                          num_employees_enum:
                            props?.original?.employee_range || "-",
                          following: props?.original?.following,
                          council_company_score:
                            props?.original?.council_company_score,
                          name: props?.original?.name,
                          id: props?.original.isProduct
                            ? props?.original?.company.id
                            : props?.original?.company_id ||
                              props?.original?.id,
                          slug: props?.original.isProduct
                            ? props?.original?.company.slug
                            : props?.original?.slug || props?.original?.id,
                          isProduct: props?.original.isProduct,
                          productId: props?.original.id,
                          productSlug: props?.original.slug,
                        },
                      }}
                      {...{ history }}
                    />
                  </>
                }
                PopperProps={{
                  disablePortal: true,
                }}
                placement="top"
                interactive
              >
                <div>
                  <CompanyInline
                    avatar={
                      props?.original.isProduct
                        ? props?.original?.company.logo
                        : props?.original?.logo
                    }
                    companyName={props?.original?.name}
                    company={props?.original}
                    companyId={
                      props?.original.isProduct
                        ? props.original.company.id
                        : props?.original?.company_id
                    }
                    companySlug={
                      props?.original.isProduct
                        ? props.original.company.slug
                        : props?.original?.slug
                    }
                    className={`${styles.listItemStyle}`}
                    isProduct={props?.original.isProduct}
                  />
                </div>
              </HtmlTooltip>
            </span>
          );
        },
        width: selectedCouncil?.name === "IDB" ? 142 : 200,
        style: {
          cursor: "pointer",
          ...tdStyles,
        },
      },
      {
        Header: (
          <HeaderButton
            label="Score"
            onClick={(e) => {
              e.preventDefault();
              setSortValues("score");
            }}
          />
        ),
        accessor: "council_company_score",
        Cell: (props) => {
          if (props?.original.isProduct) {
            return (
              <span
                className={`${styles.columnValue} ${styles.score}`}
                onClick={() => handleRateClick(props.original)}
              >
                -
              </span>
            );
          }

          return (
            <span
              className={`${styles.columnValue} ${styles.score}`}
              onClick={() => handleRateClick(props.original)}
            >
              {props.value ? props.value : "RATE"}
            </span>
          );
        },
        sortable: false,
        name: "score",
        style: tdStyles,
        width: 65,
        show: selectedCouncil?.name !== "Kyndryl",
      },
      {
        Header: (
          <HeaderButton
            label="Partner type"
            onClick={(e) => {
              e.preventDefault();
              setSortValues("partner_type");
            }}
          />
        ),
        accessor: "partner_type",
        Cell: (props) => {
          return (
            <span className={`${styles.columnValue}`}>
              {props.original?.partner_type
                ? props.original?.partner_type?.slice(0, 1).toUpperCase() +
                  props.original?.partner_type?.slice(
                    1,
                    props.original?.partner_type?.length
                  )
                : "-"}
            </span>
          );
        },
        sortable: false,
        name: "partner_type",
        style: tdStyles,
        width: 115,
        show: selectedCouncil?.name === "Kyndryl",
      },
      ...idbSpecificCol,
      {
        ...kyndrylSpecificCol,
        show:
          selectedCouncil?.name !== "Kyndryl" &&
          selectedCouncil?.name !== "IDB" &&
          selectedCouncil?.traction_tag !== "westrock",
      },
      {
        Header: (
          <HeaderButton
            label="Employees"
            onClick={(e) => {
              e.preventDefault();
              setSortValues("employees");
            }}
          />
        ),
        accessor: "employee_range",
        Cell: (props) => {
          return (
            <span
              className={styles.clickableRow}
              onClick={() =>
                history.push(
                  `/companies/${
                    props.original.slug ||
                    props.original.id ||
                    props.original.company_id
                  }`
                )
              }
            >
              {props.original?.employee_range
                ? props.original?.employee_range
                : "-"}
            </span>
          );
        },
        sortable: false,
        show:
          selectedCouncil?.name !== "IDB" &&
          selectedCouncil?.traction_tag !== "westrock",
        style: tdStyles,
      },
      {
        Header: (
          <HeaderButton
            label="Current Status"
            onClick={(e) => {
              e.preventDefault();
              setSortValues("current_status");
            }}
          />
        ),
        accessor: "current_status",
        Cell: (props) => {
          return (
            <span
              className={styles.clickableRow}
              onClick={() =>
                history.push(
                  `/companies/${
                    props.original.slug ||
                    props.original.id ||
                    props.original.company_id
                  }/details`
                )
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: getLongRow(props.original?.current_status),
                }}
              />
            </span>
          );
        },
        sortable: true,
        show: selectedCouncil?.traction_tag === "westrock",
        style: tdStyles,
      },
      {
        Header: (
          <HeaderButton
            label="Investment"
            onClick={(e) => {
              e.preventDefault();
              setSortValues("investment");
            }}
          />
        ),
        accessor: "theme_investment_stage_name",
        Cell: (props) => {
          // const investmentStage = selectedCouncil?.theme_investment_stages.find(
          //   (s) => s.id === props.original?.theme_investment_stage_id
          // );
          return (
            <span className={` ${styles.investmentStageCol}`}>
              {props.original?.theme_investment_stage_name || "-"}
            </span>
            //   <Select
            //     value={investmentStage?.slug || "none"}
            //     onChange={(e) => {s
            //       handleChangeInvestmentStage(
            //         e,
            //         props.original.id || props.original.company_id,
            //         selectedList,
            //         props.original.slug
            //       );
            //     }}
            //     style={{
            //       backgroundColor: investmentStage?.colors?.background || "",
            //       color: investmentStage?.colors?.text || "",
            //     }}
            //     className={
            //       !investmentStage?.slug || investmentStage?.slug === "none"
            //         ? styles.tractionMatch
            //         : styles.detailsRelationship
            //     }
            //     disableUnderline
            //   >
            //     {selectedCouncil?.theme_investment_stages?.map((item) => (
            //       <MenuItem key={item.slug} value={item.slug}>
            //         {item.name}
            //       </MenuItem>
            //     ))}
            //   </Select>
          );
        },

        sortable: false,
        show:
          selectedCouncil?.name === "Firmenich" &&
          session?.theme_investment_access,
        style: tdStyles,
      },
      {
        Header: (
          <HeaderButton
            label="Stage"
            onClick={(e) => {
              e.preventDefault();
              setSortValues("stage");
            }}
          />
        ),
        accessor: "status",
        Cell: (props) => {
          return (
            <Select
              value={
                props.original.council_relationship_stage_name
                  ? props.original.council_relationship_stage_name
                      ?.toLowerCase()
                      .replaceAll(" ", "_")
                  : "none"
              }
              onChange={(e) => {
                handleChangeStage(
                  e,
                  props.original.id || props.original.company_id,
                  selectedList,
                  props.original.slug,
                  props.original?.isProduct,
                  props.original?.company,
                  props.original.council_relationship_stage_name
                );
                // handleDropdownSelect(
                //   e,
                //   props.original.id || props.original.company_id,
                //   props.original?.isProduct,
                //   props.original?.company,
                //   selectedList,
                //   props.original.council_relationship_stage_name
                // );
              }}
              className={
                props.original.council_relationship_stage_name === null
                  ? styles.tagStyles
                  : styles.detailsRelationship
              }
              style={getStageStyles(
                props.original.council_relationship_stage_name
              )}
              disableUnderline
              disabled={blockFunctionality}
            >
              {selectedCouncil?.name !== "Kyndryl" && (
                <MenuItem
                  style={
                    !checkThemePipelinePermissions(
                      "none",
                      session?.council_role,
                      selectedCouncil?.name,
                      session?.decision_maker_access,
                      props.original.council_relationship_stage_name
                    )
                      ? { background: "#929ba4", color: "white" }
                      : {}
                  }
                  key="none"
                  value="none"
                >
                  Traction Match
                </MenuItem>
              )}
              {relationShipStatuses.map((item) => (
                <MenuItem
                  style={
                    !checkThemePipelinePermissions(
                      item.name,
                      session?.council_role,
                      selectedCouncil?.name,
                      session?.decision_maker_access,
                      props.original.council_relationship_stage_name
                    )
                      ? { background: "#929ba4", color: "white" }
                      : {}
                  }
                  key={item.slug}
                  value={item.slug}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          );
        },
        sortable: false,
        style: {
          fontSize: "12px",
          width: "130px",
          ...tdStyles,
        },
      },
      {
        ...kyndrylSpecificCol,
        show: selectedCouncil?.name === "Kyndryl",
      },
      {
        Header: "",
        id: "options",
        accessor: (props) => {
          return (
            <MoreOptions
              options={moreListOptions(props)}
              onClick={(val) => handleMoreOptionClick(val, props)}
              className={styles.moreOptWrp}
            />
          );
        },
        sortable: false,
        width: 20,
        style: {
          overflow: "visible",
          ...tdStyles,
        },
      },
    ];
  }, [listItems, listItemToUpdate]);

  function handleSearch(value = "", withoutDelay = false) {
    window.clearTimeout(delayTimerId);

    if (!withoutDelay) {
      delayTimerId = window.setTimeout(function () {
        if (selectedList === "following") {
          dispatch(
            getFollowingListCompaniesAttempt({
              selectedCouncil,
              page: selectedPage,
              enqueueSnackbar,
              sort_attribute: sortAttributes.following[sortAtrr],
              sort_order: sortOrd ? "asc" : "desc",
              value,
            })
          );
        } else {
          dispatch(
            searchCompaniesListsByQueryAttempt({
              value,
              page: 1,
              listId: selectedList,
              enqueueSnackbar,
              sort_attribute: sortAttributes.list[sortAtrr],
              sort_order: sortOrd ? "asc" : "desc",
            })
          );
        }
      }, 1000);
    } else {
      dispatch(
        searchCompaniesListsByQueryAttempt({
          value,
          page: 1,
          listId: selectedList,
          enqueueSnackbar,
          sort_attribute: sortAttributes.list[sortAtrr],
          sort_order: sortOrd ? "asc" : "desc",
        })
      );
    }
  }

  if (
    selectedList !== "following" &&
    session &&
    !savedList.list_members?.find((el) => el.member.id === session?.id) &&
    loadingListCompanies === false &&
    savedList.creator?.id !== session?.id
  ) {
    return <ListNotFound />;
  }

  return (
    <>
      {noListFound ? (
        <ListNotFound />
      ) : (
        <>
          {loadingListCompanies ? (
            <Skeleton animation="wave" width={"100%"} height={30} />
          ) : (
            <div className={styles.searchRow}>
              <>
                <div className={styles.searchContainer}>
                  <div
                    className={classNames(styles.searchWrp, styles.searchCon)}
                  >
                    <input
                      className={styles.searchText}
                      size="large"
                      name="searchInput"
                      value={searchInput || ""}
                      onChange={(e) => {
                        setSearchInput(e?.target?.value);
                        handleSearch(e?.target?.value);
                      }}
                      placeholder={`Search ${
                        savedList?.name && !isFollowList
                          ? capitalizeListName(savedList.name)
                          : "Following"
                      }`}
                    />
                  </div>
                </div>
                <Button
                  onClick={() => handleSearch(searchInput, true)}
                  btn={BtnType.REGULAR}
                  className={styles.searchButton}
                >
                  SEARCH LIST
                </Button>
              </>
            </div>
          )}
          <div>
            {loadingListCompanies ? (
              <ListSkeleton />
            ) : savedList && selectedList && !isFollowList ? (
              <div className={styles.tableContainer}>
                <div className={styles.listItemContainer}>
                  <div className={styles.listInfo}>
                    <div className={styles.listTitle}>
                      <>
                        {savedList?.name && capitalizeListName(savedList.name)}
                      </>
                      <div>
                        {!isViewer && (
                          <>
                            {listNameLoading ? (
                              <Loading hideString />
                            ) : (
                              <Button
                                className={styles.editButton}
                                onClick={() => handleShowEditListName()}
                                btn={BtnType.FRAME_LESS}
                                icon="icn-edit-button"
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className={styles.inlineInfo}>
                      <div
                        className={styles.listCreator}
                        onClick={handleOpenCreatorProfile}
                      >
                        <span className={styles.createdBy}>Created by</span>
                        <UserInline
                          className={styles.userIcon}
                          type={UserInlineTypes.IMAGE_ONLY}
                          showMine={false}
                          userId={savedList.creator?.id}
                          first={savedList.creator?.first_name}
                          last={savedList.creator?.last_name}
                          avatar={savedList.creator?.avatar}
                          local
                        />
                        <div className={styles.userLink}>
                          {savedList.creator?.full_name}
                        </div>
                      </div>
                      {savedList.list_members?.length ||
                      savedList.teams?.length ? (
                        <>
                          <div className={styles.listSharedTitle}>
                            Shared with:
                          </div>
                          {savedList.list_members?.length && (
                            <div className={styles.listShared}>
                              {savedList?.scope === "council" ? (
                                <div className={styles.sharedWith}>All</div>
                              ) : (
                                <>
                                  <MultiUsersComponent
                                    className={styles.userIcon}
                                    users={sharedUsers}
                                    total={savedList?.list_members?.length}
                                  />
                                </>
                              )}
                              {savedList?.teams.length ? (
                                <div className={styles.teamsWrp}>
                                  Team:{" "}
                                  {savedList?.teams.map((team, idx) => (
                                    <span
                                      key={`${team.name}-${idx}`}
                                      className={styles.sharedWith}
                                    >
                                      {" "}
                                      {team.name}
                                    </span>
                                  ))}
                                </div>
                              ) : null}
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className={styles.btnWrp}>
                    <div className={styles.firstRow}>
                      {buttonLoader ? (
                        <Button
                          className={styles.topBarButton}
                          btn={BtnType.OUTLINE}
                        >
                          <Loading hideString />
                        </Button>
                      ) : (
                        <Button
                          className={styles.topBarButton}
                          onClick={handleSubscription}
                          btn={
                            savedList.following
                              ? BtnType.HIGLIGHT
                              : BtnType.OUTLINE
                          }
                        >
                          {savedList.following ? "Following +" : "Follow"}
                        </Button>
                      )}

                      {currentUserRole !== "viewer" && (
                        <Button
                          className={styles.shareListButton}
                          onClick={() => {
                            if (savedList?.scope === "council") {
                              handleRemoveMemberFromList();
                            } else {
                              handleShareList();
                            }
                          }}
                          btn={BtnType.REGULAR}
                        >
                          {savedList?.scope === "council" ? "Manage" : "Share"}
                        </Button>
                      )}
                      {currentUserRole &&
                        currentUserRole !== "viewer" &&
                        Boolean(listOptions.length) && (
                          <div className={styles.listOptWrp}>
                            <MoreOptions
                              options={listOptions}
                              onClick={(val) => handleListOptionsClick(val)}
                              className={styles.listOpt}
                            />
                          </div>
                        )}
                    </div>
                    <div className={styles.secondRow}>
                      {exportLoading ? (
                        <Loading />
                      ) : (
                        pageSetting.total > 0 && (
                          <div>
                            <DownloadReport
                              setDownloadPdfIsActive={setExportLoading}
                              filename={savedList?.name}
                              className={styles.reportBtns}
                              endpoint={"lists_companies"}
                              payload={{
                                search: {
                                  list_id: selectedList,
                                  sort_attribute:
                                    sortAttributes.documents[sortAtrr],
                                  sort_order: sortOrd ? "asc" : "desc",
                                },
                              }}
                            />
                            <CSVLink
                              data={csvData}
                              filename={
                                "Traction List - " + savedList.name + ".csv"
                              }
                              style={{ display: "none" }}
                              ref={csvLinkRef}
                            />
                          </div>
                        )
                      )}
                      {pageSetting?.total ? (
                        <div className=" d-flex justify-content-end">
                          {pageSetting.total} Result
                          {pageSetting.total === 1 ? "" : "s"}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                {listItems && listItems?.length ? (
                  <div id="downloadableSection">
                    {exportLoading && savedList?.name && (
                      <PDFTitleAndTractionLogo
                        title={`Traction List: ${capitalizeListName(
                          savedList.name
                        )}`}
                        downloadIsActive
                      />
                    )}
                    <ReactTable
                      columns={columns}
                      data={listItems}
                      showPagination={false}
                      loading={loadingListCompaniesSearch}
                      defaultPageSize={50}
                      filterable={false}
                      minRows={5}
                      className={styles.table}
                      getTrProps={(state, rowInfo) => {
                        return {
                          className:
                            rowInfo?.original?.id === highlightItemWithId
                              ? styles.flashingRow
                              : "",
                          style: {
                            borderTop: "1px solid rgba(0,0,0,0.2)",
                            borderRight: "1px solid rgba(0,0,0,0.0)",
                            borderLeft: "none",
                          },
                        };
                      }}
                      getTheadThProps={() => {
                        return {
                          style: {
                            borderRight: "1px solid rgba(0,0,0,0.0)",
                            marginTop: "10px",
                            paddingBottom: "10px",
                            color: "#616E7C",
                          },
                        };
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <div className={styles.placeholder}>
                      <p className={styles.placeholderText}>
                        This list doesn't contain any companies yet.
                      </p>
                      <Button
                        btn={BtnType.OUTLINE}
                        onClick={handleGoToCompanies}
                      >
                        Go to Companies
                      </Button>
                    </div>
                  </div>
                )}
                {pageSetting && pageSetting.total > 20 ? (
                  <div className="w-100 d-flex justify-content-center mb-5">
                    <Pagination
                      {...pageSetting}
                      onChange={(p) => handlePagingChange(p)}
                    />
                  </div>
                ) : null}
              </div>
            ) : (
              isFollowList && (
                <div>
                  <div className={styles.listItemContainer}>
                    <div className={styles.listInfo}>
                      <div className={styles.listTitle}>Following</div>
                      <div
                        className={styles.listCreator}
                        onClick={() =>
                          history.push(
                            `/people/internal/profile/${session?.id}`
                          )
                        }
                      >
                        <span className={styles.createdBy}>Created by</span>
                        <UserInline
                          className={styles.userIcon}
                          type={UserInlineTypes.IMAGE_ONLY}
                          showMine={false}
                          userId={session?.id}
                          first={session?.first_name}
                          last={session?.last_name}
                          avatar={session?.avatar}
                          local
                        />
                        <div className={styles.userLink}>You</div>
                      </div>
                    </div>
                    <div className={styles.btnWrpFollowing}>
                      {exportLoading ? (
                        <Loading />
                      ) : (
                        showCsv && (
                          <div>
                            <Button
                              onClick={handleExportFollowingList}
                              btn={BtnType.FRAME_LESS}
                              icon={"icn-export-button"}
                              className={styles.downloadButton}
                            >
                              Download
                            </Button>
                            <CSVLink
                              data={csvData}
                              filename={"Traction List - Following List.csv"}
                              ref={csvLinkFollowingRef}
                            />
                          </div>
                        )
                      )}
                      {selectedList === "following" && pageSetting.total ? (
                        <div className="d-flex justify-content-end">
                          {pageSetting.total} Result
                          {pageSetting.total === 1 ? "" : "s"}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {listItems && listItems.length ? (
                    <div>
                      <ReactTable
                        columns={columns}
                        data={listItems}
                        showPagination={false}
                        minRows={0}
                        loading={loadingListCompaniesSearch}
                        defaultPageSize={50}
                        filterable={false}
                        className={styles.table}
                        getTrProps={(state, rowInfo) => {
                          return {
                            className:
                              rowInfo?.original?.company_id ===
                              highlightItemWithId
                                ? styles.flashingRow
                                : "",
                            style: {
                              borderTop: "1px solid rgba(0,0,0,0.2)",
                              borderRight: "1px solid rgba(0,0,0,0.0)",
                              borderLeft: "none",
                            },
                          };
                        }}
                        getTheadThProps={() => {
                          return {
                            style: {
                              borderRight: "1px solid rgba(0,0,0,0.0)",
                              marginTop: "10px",
                              paddingBottom: "10px",
                              height: "100%",
                              color: "#616E7C",
                            },
                          };
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <div className={styles.placeholder}>
                        <p className={styles.placeholderText}>
                          You don't follow any companies yet.
                        </p>
                        <Button
                          btn={BtnType.OUTLINE}
                          onClick={handleGoToCompanies}
                        >
                          Go to Companies
                        </Button>
                      </div>
                    </div>
                  )}
                  {pageSetting && pageSetting.total > 20 ? (
                    <div className="w-100 d-flex justify-content-center mb-5">
                      <Pagination
                        {...pageSetting}
                        onChange={(p) => handlePagingChange(p)}
                      />
                    </div>
                  ) : null}
                </div>
              )
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MyListsComponent;

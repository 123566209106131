import React, { useEffect, useState } from "react";
import { FilePicker } from "react-file-picker";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import mainStyles from "../../../ideasWrapper.module.scss";
import styles from "./documents.module.scss";
import AddDiscussionIcon from "../../../../../assets/icn-table-add.svg";
import { Loading } from "../../../../primitives";
import { useS3FileUpload } from "../../../../../common/hooks";
import {
  addDocument,
  deleteDocument,
} from "../../../../../common/actions/common.action";
import { Icon, smSize } from "../../../../../common/icon";
import { shouldDownload } from "../../../../../common/helper";
import ConfirmActionComponent from "../../../../../common/components/confirmAction/confirmAction.component";
import { appModelSettingSet } from "application/app.action";
import { ideaDeleteDocumentAttempt } from "../../../idea.action";
import UploadedByInfo from "../../../../../common/components/uploadedByInfo";
import { ALLOWED_FILE_EXTENSIONS } from "../../../../../common/constants";

const IdeaProfileDocuments = ({ selectedIdea, canEdit }) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    appReducer: { modalSetting },
  } = useSelector((state) => state);
  const { fileUpload, fileDelete } = useS3FileUpload();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (selectedIdea?.documents?.length) {
      setDocuments(selectedIdea.documents);
    }

    return () => {
      setDocuments([]);
    };
  }, [selectedIdea]);

  const saveDocument = (s3url, name) => {
    const payload = {
      description: "",
      name,
      s3url,
      councilId: selectedCouncil.id,
      document_resource_type: "Idea",
      document_resource_id: selectedIdea.id,
      added_by_id: session.id,
      enqueueSnackbar,
    };

    dispatch(addDocument(payload));
  };

  const handleAddDocumentClick = async (file) => {
    setLoading(true);

    const { name } = file;

    const extension = name.slice(((name.lastIndexOf(".") - 1) >>> 0) + 2);
    const isRightExtension = ALLOWED_FILE_EXTENSIONS.includes(extension);

    if (!isRightExtension) {
      const message = `Files with the extension "${extension}" are not allowed.`;
      enqueueSnackbar(message, {
        variant: "error",
        key: "extensionNotAllowed",
        persist: true,
      });

      const timeout = setTimeout(() => {
        closeSnackbar("extensionNotAllowed");
        clearTimeout(timeout);
      }, 5000);

      return;
    }

    const pathname = `ideas/${selectedIdea.id}/`;

    if (documents.findIndex((doc) => doc.name === name) === -1) {
      await fileUpload(file, pathname).then((res) => {
        if (res && res.location) {
          saveDocument(res.location, name);
          enqueueSnackbar("File successfully uploaded.", {
            variant: "success",
          });
        }
        setLoading(false);
      });
    } else {
      setLoading(false);

      if (documents.findIndex((doc) => doc.name === name) !== -1) {
        enqueueSnackbar("This document was already added.", {
          variant: "error",
        });
      }
    }
  };

  const handleDeleteDocumentClick = async (document) => {
    const customSettings = {
      title: "Delete document",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmActionComponent
          alertText="Are you sure?"
          onConfirm={() => handleDeleteDocConfirm(document)}
          confirmBtnName="Delete"
        />
      ),
    };

    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  const handleDeleteDocConfirm = async (document) => {
    const payload = {
      document_id: document.id,
      enqueueSnackbar,
    };

    dispatch(deleteDocument(payload));
    dispatch(ideaDeleteDocumentAttempt(document));

    const pathname = `ideas/${selectedIdea.id}/${document.name}`;
    await fileDelete(pathname).then(() => {
      enqueueSnackbar("File successfully deleted", {
        variant: "success",
      });
    });
  };

  return (
    <div className="d-flex flex-column mt-4">
      <div
        className={`d-flex align-items-center justify-content-between ${mainStyles.blockHeader}`}
      >
        <span className={mainStyles.blockTitle}>Documents</span>
        <FilePicker
          maxSize={500}
          onChange={handleAddDocumentClick}
          onError={() =>
            enqueueSnackbar(
              "We could not upload your document because your file size is too big. Please make sure the file is less than 500 MB",
              { variant: "error" }
            )
          }
        >
          {loading ? (
            <Loading />
          ) : (
            <>
              {canEdit && (
                <img
                  className={mainStyles.addIcon}
                  src={AddDiscussionIcon}
                  alt="Add new documents"
                />
              )}
            </>
          )}
        </FilePicker>
      </div>
      <div className={`${styles.documentsContent} ${mainStyles.blockContent}`}>
        {documents.length ? (
          <>
            {documents.map((document) => (
              <div key={document.id} className={styles.documentWrapper}>
                <div className="d-flex flex-column flex-grow-1">
                  <span className={styles.documentName}>{document.name}</span>
                  <UploadedByInfo document={document} />
                </div>
                <div className="d-flex align-items-center">
                  <a
                    className={styles.docView}
                    target="blank"
                    href={document.uri}
                  >
                    {shouldDownload(document.name) ? "download" : "view"}
                  </a>
                  {canEdit && (
                    <Icon
                      {...smSize}
                      icon="icn-button-delete"
                      className={styles.deleteIcon}
                      onClick={() => handleDeleteDocumentClick(document)}
                    />
                  )}
                </div>
              </div>
            ))}
          </>
        ) : (
          "No documents yet."
        )}
      </div>
    </div>
  );
};

export default React.memo(IdeaProfileDocuments);

import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { Field, Formik } from "formik";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";

import RichEditor from "common/components/richEditor";
import CloseIcon from "common/icon/closeIcon.component";
import { Icon, smSize } from "common/icon";
import { councilGetTeams } from "modules/council/council.action";
import {
  Label,
  TextBox,
  Button,
  BtnType,
  DropSelect,
  CheckBox,
  CheckType,
  MultiSelect,
} from "../../../../primitives";
import styles from "./addNewTaskModal.module.scss";
import { initModel, validation } from "./addNewTaskModal.constants";
import { ModalBody, ModalFooter } from "../../../../../application/modal";
import { useModelPopup } from "../../../../../common/hooks";
import { postTaskAttempt, patchTaskAttempt } from "../../adminProjects.actions";
import { isFerminichCouncil } from "modules/projects/project/isFerminichCouncil";
import { httpGet } from "../../../../../common/httpCall";
import UserMultiSelectSearch from "../../../../../common/components/usersSearch";

const AddNewTaskModal = ({
  placeHolderData,
  mode,
  stageId,
  requestNewDataCb,
}) => {
  const {
    councilReducer: { selectedCouncil, teamList, loading },
    adminProjectsReducer: { stages, tasks, stageTemplate },
  } = useSelector((state) => state);

  const formRef = useRef();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStage, setSelectedStage] = useState(false);
  const [rangePositions, setRangePositions] = useState([]);
  const [autoAsignError, setAutoAsignError] = useState(false);

  const [linkTaskToFields, setLinkTaskToFields] = useState(false);
  const [projectFields, setProjectFields] = useState(null);
  const [fieldGroups, setFieldGroups] = useState(null);
  const [team, setTeam] = useState(placeHolderData?.teams || []);
  const [tempStateateam, setTempStateTeam] = useState([]);

  const [addTeamMode, switchTeamMode] = useState(false);
  const [areaMode, switchAreaMode] = useState(false);
  const [plantMode, switchPlantMode] = useState(true);
  const [themeMode, switchThemeMode] = useState(false);
  const [teamOpt, setTeamOpt] = useState([]);
  const [refreshTeam, setRefreshTeam] = useState(false);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(councilGetTeams({ enqueueSnackbar }));
    }
    return () => null;
  }, [selectedCouncil]);

  useEffect(() => {
    const teams = teamList
      .map((tm) => {
        if (team.find((t) => t.team_id === tm.team_id && !t._destroy)) {
          return false;
        }
        return tm;
      })
      .filter((el) => el);
    setTeamOpt(teams);
  }, [refreshTeam, selectedCouncil, teamList.length, team]);

  useEffect(() => {
    if (stageId) {
      // get field groups
      httpGet({
        call: `stage_templates/${stageId}/project_field_groups/dropdown`,
      }).subscribe((res) => {
        if (res.response) {
          setFieldGroups(res.response);
        }
      });

      // get project fields
      httpGet({
        call: `stage_templates/${stageId}/project_fields/dropdown`,
      }).subscribe((res) => {
        if (res.response) {
          const newResponse = res.response.map((obj) => ({
            ...obj,
            project_field_template_id:
              obj.project_field_template_id || obj.field_name,
            field_name_humanized: obj.field_name_humanized || obj.field_name,
          }));
          setProjectFields(newResponse);
        }
      });
    }
  }, [stageId]);

  useEffect(() => {
    if (
      placeHolderData?.project_field_template?.project_field_template_id ||
      placeHolderData?.project_metric_attribute?.field_name ||
      placeHolderData?.project_field_group?.id
    ) {
      setLinkTaskToFields(true);
    }
  }, [placeHolderData]);

  const handleDismiss = () => {
    popup.hide();
  };

  const onSubmitForm = (values, resetForm, setSubmitting) => {
    if (tempStateateam[0] && !plantMode && !themeMode && !areaMode) {
      setAutoAsignError(true);
      return;
    }
    const {
      taskName,
      description,
      position,
      task_type,
      stage_id,
      days_to_complete,
      relatedProjectField,
      relatedProjectFieldGroup,
      approval_task_options,
      mark_on_document_upload = false,
      disable_manual_completion = false,
      plant = false,
      theme = false,
      area = false,
      apply_only_to_new = false,
      apply_to_existing = false,
      notify_after_previous_completed,
    } = values;
    // const usedPosition = rangePositions.find((el) => el === position);

    const isProjectMetric =
      relatedProjectField?.resource_type === "project_metric";

    const teamPayload = [...team];
    if (tempStateateam?.length) {
      teamPayload.push({
        id: tempStateateam[0].id,
        team_id: tempStateateam[0].id,
        team_name: tempStateateam[0].name,
        plant: plantMode,
        theme: themeMode,
        area: areaMode,
      });
    }
    let data = {
      name: taskName,
      description,
      stage_id,
      task_type,
      position,
      councilId: selectedCouncil?.id,
      enqueueSnackbar,
      taskId: placeHolderData?.council_task_template_id,
      days_to_complete,
      project_field_group_id: !linkTaskToFields
        ? null
        : relatedProjectFieldGroup,
      project_metric_attribute:
        linkTaskToFields && relatedProjectField && isProjectMetric
          ? relatedProjectField.field_name
          : null,
      project_field_template_id:
        linkTaskToFields && relatedProjectField && !isProjectMetric
          ? relatedProjectField.project_field_template_id
          : null,
      mark_on_document_upload,
      disable_manual_completion,
      assign_to_project_owner: values?.assign_to_project_owner || false,
      plant,
      theme,
      area,
      teams: teamPayload,
      apply_to_existing,
      apply_only_to_new,
      notify_after_previous_completed,
    };

    const isApprovalTask = task_type === "approval_gate";

    if (isApprovalTask) {
      if (approval_task_options.approval) {
        data = {
          ...data,
          assigned_approvers:
            values?.assigned_approvers?.map((user) => user.id) || [],
          approval_task_options,
          approve_in_order: values.approve_in_order,
          assign_to_project_owner: values.assign_to_project_owner,
          teams: teamPayload,
          plant,
          theme,
          area,
          apply_to_existing,
          apply_only_to_new,
        };
      }

      if (approval_task_options.review) {
        data = {
          ...data,
          assigned_reviewers:
            values?.assigned_reviewers?.map((user) => user.id) || [],
          approval_task_options,
          approve_in_order: values.approve_in_order,
          assign_to_project_owner: values.assign_to_project_owner,
          teams: teamPayload,
          plant,
          theme,
          area,
          apply_to_existing,
          apply_only_to_new,
        };
      }
    } else {
      data = {
        ...data,
        assigned_users: values.assigned_users?.map((user) => user.id) || [],
        assign_to_project_owner: values.assign_to_project_owner,
        mark_on_document_upload,
        disable_manual_completion,
        teams: teamPayload,
        plant,
        theme,
        area,
        apply_to_existing,
        apply_only_to_new,
      };
    }

    if (mode === "edit") {
      dispatch(patchTaskAttempt(data));
    } else {
      dispatch(postTaskAttempt(data));
    }

    setSubmitting(true);
    resetForm();
    popup.hide();

    if (requestNewDataCb) {
      requestNewDataCb();
    }

    // if (!usedPosition || usedPosition === placeHolderData?.position) {
    //   if (mode === "edit") {
    //     dispatch(patchTaskAttempt(data));
    //   } else {
    //     dispatch(postTaskAttempt(data));
    //   }
    //   setSubmitting(true);
    //   resetForm();
    //   popup.hide();
    // } else {
    //   setError("Current position is used already");
    // }
  };

  const getRelatedField = (model) => {
    if (model?.project_field_template?.project_field_template_id) {
      return {
        ...model?.project_field_template,
        field_name_humanized: model.project_field_template.field_name,
      };
    }

    if (model?.project_metric_attribute?.field_name) {
      return {
        ...model?.project_metric_attribute,
        project_field_template_id: model.project_metric_attribute.field_name,
      };
    }

    return {};
  };

  const editModel = useMemo(() => {
    const getTaskType = () => {
      if (placeHolderData?.task_type === "project_organization_specific") {
        return "project_organization_specific";
      }

      if (placeHolderData?.task_type === "project_specific") {
        return "project_specific";
      }

      return placeHolderData?.task_type;
    };

    const getUsers = (prop) => {
      if (!placeHolderData || !placeHolderData[prop]) {
        return [];
      }
      return placeHolderData[prop].map((obj) => ({
        ...obj,
        ...(obj.user || {}),
        id: obj.user?.user_id || obj.user_id,
        full_name: obj.user
          ? `${obj.user.first_name} ${obj.user.last_name}`
          : `${obj.first_name} ${obj.last_name}`,
      }));
    };
    // if (selectedCouncil.traction_tag === "ford") {
    //   setTeam(placeHolderData.teams);
    // }
    return {
      taskName: placeHolderData?.name,
      position: placeHolderData?.position,
      description: placeHolderData?.description,
      task_type: getTaskType(),
      stage_id: placeHolderData?.stage?.stage_id,
      selectedStage: [placeHolderData?.stage],
      days_to_complete: placeHolderData?.days_to_complete,
      relatedProjectField: getRelatedField(placeHolderData),
      relatedProjectFieldGroup: placeHolderData?.project_field_group?.id,
      approval_task_options: placeHolderData?.approval_task_options,
      approve_in_order: placeHolderData?.approve_in_order,
      assign_to_project_owner: placeHolderData?.assign_to_project_owner,
      assigned_approvers: getUsers("assigned_approvers"),
      assigned_reviewers: getUsers("assigned_reviewers"),
      assigned_users: getUsers("assigned_users"),
      mark_on_document_upload: placeHolderData?.mark_on_document_upload,
      disable_manual_completion: placeHolderData?.disable_manual_completion,
      theme_ids: placeHolderData?.theme_ids,
      teams: placeHolderData?.teams,
      theme: placeHolderData?.theme,
      plant: placeHolderData?.plant,
      area: placeHolderData?.area,
      apply_only_to_new: placeHolderData?.apply_only_to_new || false,
      apply_to_existing: placeHolderData?.apply_to_existing || false,
      notify_after_previous_completed:
        placeHolderData?.notify_after_previous_completed || false,
    };
  }, [placeHolderData, getRelatedField]);

  useEffect(() => {
    if (mode === "edit") {
      const stageTasksPositions = tasks
        .filter((task) => task.stage.stage_id === placeHolderData.stage.id)
        .map((task) => task.position)
        .sort((a, b) => {
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          return 0;
        });
      setRangePositions(stageTasksPositions);
    }
  }, [mode]);

  const checkPositions = (stage, setFieldValue) => {
    setSelectedStage((prevState) => {
      if (prevState.val === stage.val) {
        setFieldValue("stage_id", "");
        return false;
      }
      setFieldValue("stage_id", stage.val);
      return stage;
    });

    const stageTasksPositions = tasks
      .filter((task) => task.stage.stage_id === stage.val)
      .map((task) => task.position)
      .sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
      });

    for (let i = 0; i <= stageTasksPositions.length; i++) {
      if (i + 1 !== stageTasksPositions[i]) {
        setFieldValue("position", i + 1);
        break;
      }
    }

    setRangePositions(stageTasksPositions);
    checkIfStageHasApprovalGateTask(stage);
  };

  const getUsersData = useCallback((values) => {
    if (values.task_type === "approval_gate") {
      return values.approval_task_options.approval
        ? values?.assigned_approvers || []
        : values?.assigned_reviewers || [];
    }

    return values.assigned_users || [];
  }, []);

  const setUsersData = useCallback((value, values, setFieldValue) => {
    if (values.task_type === "approval_gate") {
      if (values.approval_task_options.approval) {
        setFieldValue("assigned_approvers", value);
      }

      if (values.approval_task_options.review) {
        setFieldValue("assigned_reviewers", value);
      }

      return;
    }
    setFieldValue("assigned_users", value);
  }, []);

  const addTeam = () => {
    if (!plantMode && !themeMode && !areaMode) {
      setAutoAsignError(true);
      return;
    }

    switchTeamMode(false);

    setTeam((prev) => [
      ...prev,
      {
        id: tempStateateam[0].id,
        team_id: tempStateateam[0].id,
        team_name: tempStateateam[0].name,
        plant: plantMode,
        theme: themeMode,
        area: areaMode,
      },
    ]);

    switchAreaMode(false);
    switchPlantMode(true);
    switchThemeMode(false);

    setTempStateTeam();
  };

  const checkIfStageHasApprovalGateTask = useCallback(
    (dropdownValue) => {
      if (!formRef?.current || placeHolderData?.stage_id === dropdownValue?.val)
        return;

      const {
        task_type,
        approval_task_options: { review, approval },
        stage_id,
      } = formRef.current.values;

      if (task_type !== "approval_gate") {
        formRef.current.setFieldValue("isInvalidTask", false);
        return;
      }

      httpGet({
        call: `check_task_type?stage_id=${
          dropdownValue?.val || stage_id
        }&review=${review}&approval=${approval}&in_project=false`,
      }).subscribe((res) => {
        if (res.response) {
          formRef.current.setFieldValue(
            "isInvalidTask",
            res.response.approval_tasks || res.response.review_tasks
          );
        } else {
          formRef.current.setFieldValue("isInvalidTask", false);
        }
      });
    },
    [formRef?.current]
  );

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={mode === "edit" ? editModel : initModel}
      validationSchema={validation}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        onSubmitForm(values, resetForm, setSubmitting);
      }}
    >
      {({ handleSubmit, setFieldValue, values, ...formikprops }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody noFixedHeight>
            <div className={styles.instructionText}>
              Name each Task, apply it to a Project Stage, and specify its
              position within the Stage. Tasks can be applied to all companies
              in a Project or be standalone Project Tasks
            </div>
            <fieldset id="taskType" className="d-flex flex-column mt-3 mb-3">
              <div className="d-flex mt-2">
                <input
                  type="radio"
                  id="apply_only_to_new"
                  name="apply_only_to_new"
                  value="apply_only_to_new"
                  checked={values.apply_only_to_new}
                  onClick={() => {
                    setFieldValue("apply_only_to_new", true);
                    setFieldValue("apply_to_existing", false);
                  }}
                />
                <label className="small ml-1" htmlFor="apply_only_to_new">
                  Apply changes only to new Projects
                </label>
              </div>

              <div className="d-flex mt-2">
                <input
                  type="radio"
                  id="apply_to_existing"
                  name="apply_to_existing"
                  value="apply_to_existing"
                  checked={values.apply_to_existing}
                  onClick={() => {
                    setFieldValue("apply_to_existing", true);
                    setFieldValue("apply_only_to_new", false);
                  }}
                />
                <label className="small ml-1" htmlFor="apply_to_existing">
                  Apply changes to both new and existing Projects
                </label>
              </div>
            </fieldset>

            <Label>Task</Label>
            <TextBox
              type="text"
              name="taskName"
              placeholder="Enter a task name"
              formProps={{ ...formikprops, values, setFieldValue }}
              className={styles.textBox}
            />
            <Label>Complete by stage</Label>
            <DropSelect
              placeholder="Stage"
              labelField="name"
              valueField="id"
              values={mode === "edit" ? values.selectedStage : undefined}
              options={stages}
              onChange={(stage) => checkPositions(stage, setFieldValue)}
            />

            {values.isInvalidTask && (
              <span className="text-danger">
                An Approval Task already exists for the selected Stage. Please
                select a different Stage.
              </span>
            )}

            <div className="mt-3">
              <Label>Position</Label>
              {!selectedStage && mode !== "edit" ? (
                <Tooltip
                  title="To set task positin you have to select firstly task stage"
                  arrow
                  placement="top"
                >
                  <div>
                    <TextBox
                      type="Number"
                      disabled
                      name="position"
                      formProps={{ ...formikprops, values }}
                      placeholder="Add Position"
                      className={styles.textBox}
                    />
                  </div>
                </Tooltip>
              ) : (
                <>
                  <TextBox
                    type="Number"
                    name="position"
                    formProps={{ ...formikprops, values }}
                    className={styles.textBox}
                  />
                  {/* {error && <div className={styles.error}>{error}</div>} */}
                </>
              )}
            </div>

            {values?.task_type !== "approval_gate" && (
              <div className="mb-3">
                <label className="d-flex align-items-center">
                  <CheckBox
                    checkType={CheckType.BLUE}
                    name="mark_on_document_upload"
                    onChange={(evt) => {
                      setFieldValue("mark_on_document_upload", evt);
                    }}
                    isChecked={values.mark_on_document_upload}
                  />
                  <span>Mark Complete with Document Upload</span>
                </label>
              </div>
            )}

            {(mode === "edit" || selectedStage) &&
              values?.task_type === "project_specific" && (
                <div className="mb-3">
                  <label className="d-flex align-items-center">
                    <CheckBox
                      checkType={CheckType.BLUE}
                      name="linkTaskToFields"
                      onChange={(evt) => {
                        setLinkTaskToFields(evt);
                        setFieldValue("disable_manual_completion", evt)
                        setFieldValue("relatedProjectField", null);
                        setFieldValue("relatedProjectFieldGroup", null);
                      }}
                      isChecked={linkTaskToFields}
                    />
                    <span>Link Task to Field(s)</span>
                  </label>
                </div>
              )}

            {
              (values.mark_on_document_upload || linkTaskToFields) && (
                <div className="mb-3">
                  <label className="d-flex align-items-center">
                    <CheckBox
                      checkType={CheckType.BLUE}
                      name="disable_manual_completion"
                      onChange={(evt) => {
                        setFieldValue("disable_manual_completion", evt);
                      }}
                      disabled={linkTaskToFields}
                      isChecked={values.disable_manual_completion}
                    />
                    <span>Disable Manual Task Completion</span>
                  </label>
                </div>
              )
            }

            {linkTaskToFields && values?.task_type !== "approval_gate" && (
              <>
                {projectFields?.length > 0 &&
                !values?.relatedProjectFieldGroup ? (
                  <div className="mb-3">
                    <Label>Related Project Field</Label>
                    <span className="small text-muted mb-2">
                      When selected field is filled out, Task will be marked
                      Complete
                    </span>
                    <DropSelect
                      placeholder="Related Project Field"
                      name="relatedProjectField"
                      labelField="field_name_humanized"
                      valueField="project_field_template_id"
                      searchBy="field_name_humanized"
                      options={projectFields}
                      onChange={(value) => {
                        setFieldValue("relatedProjectField", value.obj[0]);
                        setFieldValue("relatedProjectFieldGroup", null);
                      }}
                      SelecedValues={
                        values.relatedProjectField
                          ? [values.relatedProjectField]
                          : []
                      }
                    />
                    {values.relatedProjectField && (
                      <div className="d-flex justify-content-end">
                        <span
                          onClick={() => {
                            setFieldValue("relatedProjectField", null);
                          }}
                          className="cursor-pointer text-muted small"
                        >
                          clear
                        </span>
                      </div>
                    )}
                  </div>
                ) : null}

                {fieldGroups?.length > 0 && !values?.relatedProjectField ? (
                  <div className="mb-3">
                    <Label>Related Project Field Group</Label>
                    <span className="small text-muted mb-2">
                      When all fields in selected Group are filled out, Task
                      will be marked Complete
                    </span>
                    <DropSelect
                      placeholder="Related Project Field Group"
                      name="relatedProjectFieldGroup"
                      labelField="name"
                      valueField="id"
                      searchBy="name"
                      options={fieldGroups}
                      formProps={{ setFieldValue, values, ...formikprops }}
                      onChange={(value) => {
                        setFieldValue("relatedProjectFieldGroup", value.obj[0]);
                        setFieldValue("relatedProjectField", null);
                      }}
                      SelecedValues={values.relatedProjectFieldGroup || "clear"}
                    />
                    {values.relatedProjectFieldGroup && (
                      <div className="d-flex justify-content-end">
                        <span
                          onClick={() => {
                            setFieldValue("relatedProjectFieldGroup", null);
                          }}
                          className="cursor-pointer text-muted small"
                        >
                          clear
                        </span>
                      </div>
                    )}
                  </div>
                ) : null}
              </>
            )}
            <Label>Days to Complete</Label>
            <TextBox
              type="Number"
              name="days_to_complete"
              formProps={{ ...formikprops, values }}
              placeholder="Add number of days to automate Due Date"
              className={styles.textBox}
            />
            <Label>Description (optional)</Label>
            <RichEditor
              updateValue={(val) => setFieldValue("description", val)}
              initialValue={values.description || ""}
              placeholder="Add Description"
              className={styles.textBox}
            />
            <fieldset id="taskType" className="d-flex flex-column mt-3">
              <div className="d-flex mt-2">
                <input
                  disabled={mode === "edit"}
                  type="radio"
                  id="project_organization_specific"
                  name="taskType"
                  value="project_organization_specific"
                  checked={values.task_type === "project_organization_specific"}
                  onClick={() => {
                    setFieldValue("task_type", "project_organization_specific");
                  }}
                />
                <label
                  className="small ml-1"
                  htmlFor="project_organization_specific"
                >
                  {selectedCouncil?.name === "GSK" &&
                  stageTemplate.patient_organization
                    ? "Organization Task"
                    : "Company Task"}
                </label>
              </div>
              <div className="d-flex mt-2">
                <input
                  disabled={mode === "edit"}
                  type="radio"
                  id="project"
                  name="taskType"
                  value="project_specific"
                  checked={values.task_type === "project_specific"}
                  onClick={() => {
                    setFieldValue("task_type", "project_specific");
                  }}
                />
                <label className="small ml-1" htmlFor="project">
                  {isFerminichCouncil(
                    "Project Task",
                    "Milestone",
                    selectedCouncil
                  )}
                </label>
              </div>
              <div className="d-flex mt-2">
                <input
                  disabled={mode === "edit"}
                  type="radio"
                  id="approval_gate"
                  name="taskType"
                  value="approval_gate"
                  checked={values.task_type === "approval_gate"}
                  onClick={() => {
                    setFieldValue("task_type", "approval_gate").then(() => {
                      checkIfStageHasApprovalGateTask();
                    });
                  }}
                />
                <label className="small ml-1" htmlFor="approval_gate">
                  Approval Gate
                </label>
              </div>
            </fieldset>
            {values?.task_type === "approval_gate" && (
              <div className="mt-2 ml-4">
                <label
                  className="d-flex align-items-center cursor-pointer"
                  htmlFor="review"
                >
                  <Field
                    disabled={mode === "edit" || !values.approval_task_options.approval}
                    id="review"
                    name="review"
                    type="checkbox"
                    value="checkbox"
                    checked={values.approval_task_options.review}
                    onChange={() => {
                      const newValues = {
                        ...values.approval_task_options,
                        review: !values.approval_task_options.review,
                        lockStages: false,
                        approval: false,
                      };
                      setFieldValue("approval_task_options", newValues).then(
                        () => {
                          checkIfStageHasApprovalGateTask();
                        }
                      );
                    }}
                  />
                  <span className="ml-2">Review</span>
                </label>
                <label
                  className="d-flex align-items-center cursor-pointer"
                  htmlFor="approval"
                >
                  <Field
                    disabled={mode === "edit" || !values.approval_task_options.review}
                    id="approval"
                    name="approval"
                    type="checkbox"
                    value="checkbox"
                    checked={values.approval_task_options.approval}
                    onChange={() => {
                      const newValues = {
                        ...values.approval_task_options,
                        approval: !values.approval_task_options.approval,
                        review: false,
                        lockStages: true,
                      };
                      setFieldValue("approval_task_options", newValues).then(
                        () => {
                          checkIfStageHasApprovalGateTask();
                        }
                      );
                    }}
                  />
                  <span className="ml-2">Approval</span>
                </label>
                {values.approval_task_options.approval && (
                  <label
                    className="d-flex align-items-center cursor-pointer"
                    htmlFor="lockStages"
                  >
                    <Field
                      id="lockStages"
                      name="lockStages"
                      type="checkbox"
                      value="checkbox"
                      checked={values.approval_task_options.lockStages}
                      onChange={() => {
                        setFieldValue("approval_task_options", {
                          ...values.approval_task_options,
                          lockStages: !values.approval_task_options.lockStages,
                        });
                      }}
                    />
                    <span className="ml-2">Lock Stages</span>
                  </label>
                )}
              </div>
            )}
            <hr className={styles.divider} />
            <div className="mt-3">
              <Label>
                {" "}
                <b>Assign to: </b>
              </Label>
            </div>
            <Label>
              {" "}
              {selectedCouncil?.traction_tag === "ford" && "1."} Project Owner
            </Label>
            <label className="d-flex align-items-center mt-1">
              <CheckBox
                checkType={CheckType.BLUE}
                name="assign_to_project_owner"
                onChange={(evt) => {
                  setFieldValue(
                    "assign_to_project_owner",
                    !values.assign_to_project_owner
                  );
                }}
                isChecked={values.assign_to_project_owner}
              />
              <span>Assign to project owner</span>
            </label>
            {selectedCouncil?.traction_tag === "ford" && (
              <div className="mt-3">
                <Label>2. Team</Label>
                <div className={styles.tagsList}>
                  {team.map((t) => {
                    if (t._destroy) {
                      return null;
                    }
                    return (
                      <div className={styles.tag}>
                        <div className={styles.tagName}>
                          <span>{t.name || t.team_name}</span>
                          <span
                            className={styles.tagDeleteIcon}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setRefreshTeam((prevState) => !prevState);
                              setTeam((prev) =>
                                [...prev].map((el) => {
                                  if (el.team_id === t.team_id) {
                                    return { ...el, _destroy: true };
                                  }
                                  return el;
                                })
                              );
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                        <div className={styles.cats}>
                          {t.plant && (
                            <span className={styles.catIncluded}>
                              {" "}
                              <Icon
                                {...smSize}
                                icon="icn-checkbox-button"
                                className={styles.icon}
                              />{" "}
                              Plant
                            </span>
                          )}
                          {t.area && (
                            <span className={styles.catIncluded}>
                              <Icon
                                {...smSize}
                                icon="icn-checkbox-button"
                                className={styles.icon}
                              />
                              Area
                            </span>
                          )}
                          {t.theme && (
                            <span className={styles.catIncluded}>
                              {" "}
                              <Icon
                                {...smSize}
                                icon="icn-checkbox-button"
                                className={styles.icon}
                              />
                              Pillar
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {selectedCouncil?.traction_tag === "ford" && (
              <div className="mb-3">
                <MultiSelect
                  values={tempStateateam?.length ? tempStateateam : []}
                  placeholder="Choose a team"
                  name="team_ids"
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  options={teamList?.length ? teamOpt : []}
                  formProps={{ ...formikprops, values }}
                  onChange={(e) => {
                    switchTeamMode((prev) => !prev);
                    setTempStateTeam(e);
                  }}
                  multi={false}
                />
              </div>
            )}
            {addTeamMode && (
              <div className="fs-5">
                <div className="fs-5">
                  {" "}
                  Assignee must also match the Project’s:{" "}
                </div>

                <div className="mb-3">
                  <label className="d-flex align-items-center">
                    <CheckBox
                      checkType={CheckType.BLUE}
                      name="theme"
                      onChange={(evt) => {
                        if (evt) {
                          setAutoAsignError(false);
                        }

                        switchThemeMode(evt);
                      }}
                    />
                    <span>{`${
                      selectedCouncil?.settings?.themes_display_name_singular ||
                      "Theme"
                    } `}</span>
                  </label>
                </div>
                <div className="mb-3">
                  <label className="d-flex align-items-center">
                    <CheckBox
                      checkType={CheckType.BLUE}
                      name="plant"
                      onChange={(evt) => {
                        if (evt) {
                          setAutoAsignError(false);
                        }
                        switchPlantMode(evt);
                      }}
                      isChecked={plantMode}
                    />
                    <span>Plant</span>
                  </label>
                </div>

                <div className="mb-3">
                  <label className="d-flex align-items-center">
                    <CheckBox
                      checkType={CheckType.BLUE}
                      name="area"
                      onChange={(evt) => {
                        if (evt) {
                          setAutoAsignError(false);
                        }

                        switchAreaMode(evt);
                      }}
                      // isChecked={values.area}
                    />
                    <span>Area</span>
                  </label>
                </div>
                {autoAsignError && (
                  <div className={styles.error}>
                    At least one should be selected!
                  </div>
                )}

                <div className="mb-3">
                  <label className="d-flex align-items-start">
                    <Button
                      btn={BtnType.FRAME_LESS}
                      icon="icn-add"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        addTeam();
                      }}
                    >
                      ADD TEAM
                    </Button>
                  </label>
                </div>
              </div>
            )}
            <div className="mt-3">
              <Label>
                {selectedCouncil?.traction_tag === "ford" && "3."} Individual
              </Label>
              <UserMultiSelectSearch
                multi={values?.task_type === "approval_gate"}
                dropdownPosition="top"
                placeholder="Search for users"
                labelField="full_name"
                valueField="id"
                searchBy="full_name"
                hasAll={false}
                selectedValues={getUsersData(values)}
                onChange={(value) => setUsersData(value, values, setFieldValue)}
              />
            </div>
            {values?.task_type === "approval_gate" && (
              <>
                <label
                  className="d-flex align-items-center cursor-pointer"
                  htmlFor="approveInOrder"
                >
                  <Field
                    id="approveInOrder"
                    name="approveInOrder"
                    type="checkbox"
                    value="checkbox"
                    checked={values.approve_in_order}
                    onChange={() => {
                      setFieldValue(
                        "approve_in_order",
                        !values.approve_in_order
                      );
                    }}
                  />
                  <span className="ml-2">Approve in order</span>
                </label>
              </>
            )}
            {values?.task_type !== "approval_gate" && (
              <label className="d-flex align-items-center mt-1">
                <CheckBox
                  checkType={CheckType.BLUE}
                  name="notify_after_previous_completed"
                  onChange={(evt) => {
                    setFieldValue("notify_after_previous_completed", evt);
                  }}
                  isChecked={values.notify_after_previous_completed}
                />
                <span>
                  Delay Task notification until previous Tasks in Stage are
                  complete
                </span>
              </label>
            )}
          </ModalBody>
          <ModalFooter>
            <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
              Cancel
            </Button>
            <Button
              type="submit"
              btn={BtnType.HIGHLIGHT}
              className={styles.button}
              disabled={formRef?.current?.values?.isInvalidTask}
            >
              {mode === "edit" ? "Save" : "Add"}
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default AddNewTaskModal;

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

const useSorting = (arr, isAsc, action) => {
  const [asc, setAsc] = useState(isAsc);
  const [prop, setProp] = useState(null);
  const [propType, setPropType] = useState(null);
  const [array, setArray] = useState(arr);
  const dispatch = useDispatch();

  useEffect(() => {
    if (prop && propType && array.length > 0) {
      constSorting();
    }
  }, [prop, asc, propType, array]);

  const sortChange = (prop, type) => {
    setAsc((prev) => !prev);
    setProp(prop);
    setPropType(type);
  };

  const setSortingArray = (sortableArray) => {
    setArray(sortableArray);
  };

  const constSorting = () => {
    if (propType === "string") {
      array.sort((a, b) => {
        const nameA = a[prop].toUpperCase(); // ignore upper and lowercase
        const nameB = b[prop].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    } else {
      array.sort((a, b) => a[prop] - b[prop]);
    }
    if (asc) {
      dispatch(action({ array, asc }));
    } else {
      dispatch(action({ array: array.reverse(), asc }));
    }
  };

  return { sortChange, array, setSortingArray };
};

export default useSorting;

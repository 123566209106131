import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import classNames from "classnames";
import { withSnackbar } from "notistack";
import {
  BtnType,
  Button,
  Label,
  TextBox,
  CheckBox,
  CheckType,
  DropSelect,
} from "modules/primitives/index";
import {
  tagCustomGroupAttempt,
  tagCustomGroupEditAttempt,
} from "../adminTags.actions";

import { useModelPopup } from "../../../../common/hooks/index";
import styles from "../adminTags.module.scss";
import { ModalBody, ModalFooter } from "../../../../application/modal";
import { tagValidation, tagModel } from "./tagModal.constants";

const colors = [
  {
    text: "#7cc1e4",
    value: "#7cc1e4",
    text_color: "#212529",
  },
  {
    text: "#0b4f71",
    value: "#0b4f71",
    text_color: "#ffffff",
  },

  {
    text: "#f5f7fa",
    value: "#f5f7fa",
    text_color: "#212529",
  },
  {
    text: "#a7d8f0",
    value: "#a7d8f0",
    text_color: "#212529",
  },
  {
    text: "#3994c1",
    value: "#3994c1",
    text_color: "#212529",
  },
];

const CustomTagGroupModal = ({
  enqueueSnackbar,
  mode,
  tag,
  customTagGroups,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const popup = useModelPopup();
  const [editModel, setEditModel] = useState({});
  const [groupCreateName, setGroupCreateName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [groupIsActive, handleSetGroupActiveClick] = useState(
    tag ? tag?.filter : true
  );
  const [bgColor, setBgColor] = useState(tag ? tag?.bg_color : "");

  const handleOnSubmit = () => {
    if (!groupCreateName) {
      enqueueSnackbar("Name is required", {
        variant: "error",
      });
      return;
    }
    const data = {
      council_custom_tag_group: {
        name: groupCreateName,
        description: groupDescription,
        filter: groupIsActive,
        bg_color: bgColor,
        text_color:
          colors.find((element) => element.text === bgColor).text_color ||
          "#7cc1e4",
      },
    };
    if (mode === "add") {
      dispatch(tagCustomGroupAttempt({ data, enqueueSnackbar }));
    }
    if (mode === "edit") {
      dispatch(
        tagCustomGroupEditAttempt({ data, tagId: tag?.id, enqueueSnackbar })
      );
    }
    popup.hide();
  };

  const handleCancelClick = () => {
    popup.hide();
  };
  useEffect(() => {
    if (mode === "edit") {
      setEditModel({
        name: tag?.name || "",
      });
      setGroupCreateName(tag?.name);
      setGroupDescription(tag?.description);
    }
    if (mode === "add") {
      const collorsValues = [];
      const usedColors = [];
      colors.map((color, i) => {
        collorsValues.push(color.value);
      });
      customTagGroups.map((group) => {
        usedColors.push(group.bg_color);
      });
      const unusedColors = collorsValues.filter((color) => {
        if (usedColors.includes(color)) {
          return false;
        }

        return true;
      });
      setBgColor(unusedColors[0]);
    }
  }, [mode]);

  const changeColor = (color) => {
    setBgColor(color);
  };

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={mode === "edit" ? editModel : tagModel}
        validationSchema={tagValidation}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
        }}
      >
        {({ handleSubmit, handleReset, ...formikprops }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <ModalBody>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <p className={styles.aditionalInfo}>
                  Your Tag Category and related Custom Tags will be available
                  for all companies.
                </p>
                <div className={styles.addRatingTempForm}>
                  <div className={styles.row}>
                    <div className={classNames(styles.col12)}>
                      <Label>Tag Category Name</Label>
                      <TextBox
                        autoComplete="false"
                        type="text"
                        value={groupCreateName}
                        className={styles.textBox}
                        onChange={(e) => {
                          setGroupCreateName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={classNames(styles.col12)}>
                      <Label>Tag Category Description</Label>
                      <TextBox
                        autoComplete="false"
                        type="text"
                        value={groupDescription}
                        className={styles.textBox}
                        onChange={(e) => {
                          setGroupDescription(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col12}>
                      <Label>Select tag color (Optional)</Label>
                      <div className={styles.textBoxWrp}>
                        <DropSelect
                          SelecedValues={bgColor}
                          onChange={() => undefined}
                          placeholder={`${bgColor || "Background color"}`}
                          labelField="text"
                          disabled={false}
                          valueField="value"
                          searchBy="text"
                          options={colors}
                          itemRenderer={({ item }) => (
                            <div
                              onClick={(e) => changeColor(item.value)}
                              value={item.value}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignContent: "center",
                              }}
                            >
                              {" "}
                              <div
                                style={{
                                  height: "30px",
                                  width: "100px",
                                  borderRadius: 5,
                                  marginTop: "10px",
                                  alignContent: "center",
                                  marginLeft: "10px",
                                  paddingLeft: "5px",
                                  backgroundColor: `${item.value}`,
                                  border: "1px solid black",
                                  color: `${item.text_color}`,
                                }}
                              >
                                {item.value}
                              </div>
                            </div>
                          )}
                        />
                        <div
                          style={{
                            height: "26px",
                            width: "30px",
                            backgroundColor: `${bgColor}`,
                            border: "1px solid black",
                            borderRadius: 30,
                            marginLeft: "10px",
                            marginTop: "-17px",
                            alignContent: "center",
                            color: tag ? `${tag?.text_color}` : "#fff",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <CheckBox
                      checkType={CheckType.BLUE}
                      isChecked={groupIsActive}
                      onChange={(value) =>
                        handleSetGroupActiveClick(value, formikprops)
                      }
                      className={styles.checkBoxDisplay}
                    />
                    <Label className={styles.labelDisplay}>
                      Display as Search Filter
                    </Label>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  handleOnSubmit();
                }}
                btn={BtnType.REGULAR}
              >
                {mode === "edit" ? "Submit" : "Add"}
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(withSnackbar(CustomTagGroupModal));

import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./filters.module.scss";
import { setChartsAreReRendering } from "../../../companyMain.action";

const BubbleToggle = ({ handleToggleChange }) => {
  const dispatch = useDispatch();
  const {
    companiesReducer: {
      companyMainReducer: {
        charts: { viewType },
      },
    },
  } = useSelector((state) => state);

  const handleChange = (evt) => {
    evt.persist();
    const { value } = evt.target;

    if (value === viewType) return;

    dispatch(
      setChartsAreReRendering({
        state: true,
      }),
    );

    const timeout = setTimeout(() => {
      handleToggleChange(value);
      clearTimeout(timeout);
    });
  };

  return (
    <div className="d-flex align-items-center">
      <div
        className={`${styles.customToggle} toggle-bubble-chart`}
        role="group"
        aria-label="Toggle Bubble charts data"
      >
        <input
          type="radio"
          className={styles.btnCheckCustom}
          name="bubbleChartType"
          id="aggregate"
          value="aggregate"
          checked={viewType === "aggregate"}
          onChange={handleChange}
        />
        <label htmlFor="aggregate">Aggregate</label>
        <input
          type="radio"
          className={styles.btnCheckCustom}
          name="bubbleChartType"
          id="detailed"
          value="detailed"
          checked={viewType === "detailed"}
          onChange={handleChange}
        />
        <label htmlFor="detailed">Detailed</label>
      </div>
    </div>
  );
};

export default memo(BubbleToggle);

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ChooseRoleComponent from "modules/projects/project/manageProject/members/chooseRoleModal/chooseRole.component";
import { useSnackbar } from "notistack";
import { useModelPopup } from "../../../../../common/hooks";

import { moreOptions } from "./members.constants";
import { UserInline, UserInlineTypes } from "../../../../primitives";
import MoreOptionsWithCheckbox from "../../../themesCommon/moreOptions/index";
import ConfirmDeleteComponent from "../../../../../common/components/confirmDelete/index";
import styles from "./styles.module.scss";
import { themesMemberEditAttempt } from "../../themesOverviewTab/overviewTab.action";

const MemberItem = (props) => {
  const { member, currentThemeDataId, selectedCouncil } = props;
  const [expert, setExpert] = useState(member.expert);
  const { enqueueSnackbar } = useSnackbar();

  const popup = useModelPopup();
  const dispatch = useDispatch();
  const handleDeleteClick = (id) => {
    popup.show({
      title: "Confirmation Delete",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={currentThemeDataId}
          templateId={id}
          enqueueSnackbar={enqueueSnackbar}
          councilId={selectedCouncil?.id}
          actionType="deleteThemeMember"
          customData={[
            selectedCouncil?.settings?.themes_display_name_singular || "theme",
          ]}
        />
      ),
    });
  };

  const handleEditClick = (id) => {
    popup.show({
      title: "Choose Member Role",
      component: (
        <ChooseRoleComponent
          templateId={currentThemeDataId}
          userId={id}
          fromThemes={{ status: expert }}
          themeLabel={
            selectedCouncil?.settings?.themes_display_name_singular || "Theme"
          }
        />
      ),
    });
  };
  const handleMoreOptionClick = (val, id) => {
    if (val === 1) {
      handleDeleteClick(id);
    } else if (val === 2) {
      handleEditClick(id);
    }
  };

  const handleGetRole = (role) => {
    if (role === "manager") {
      return "MANAGER";
    }
    if (role === "owner") {
      return "OWNER";
    }
    return "FOLLOWER";
  };
  const handleCheckboxClick = () => {
    const data = {
      theme_member: {
        user_role: member.user_role,
        expert: !expert,
      },
    };
    setExpert(!expert);
    dispatch(
      themesMemberEditAttempt({
        data,
        id: currentThemeDataId,
        userId: member.user_id,
      })
    );
  };

  useEffect(() => {
    if (member) {
      setExpert(member.expert);
    }
  }, [member?.expert]);

  const getMoreOptions = () => {
    return moreOptions.map((opt) => {
      if (opt.val === 1) {
        return {
          val: 1,
          text: `Remove From ${
            selectedCouncil?.settings?.themes_display_name_singular || "theme"
          } `,
        };
      }
      return opt;
    });
  };
  return (
    <div className={styles.memberRow}>
      <UserInline
        userId={member.user_id}
        first={member.name}
        last=""
        avatar={member.avatar}
        local
        type={UserInlineTypes.WITH_NAME}
      />

      <div className={styles.colMore}>
        {expert ? <div className={styles.expertText}>Expert</div> : null}
        <div className={styles.role}>{handleGetRole(member.user_role)}</div>
        <MoreOptionsWithCheckbox
          options={getMoreOptions()}
          className={styles.moreOptWrp}
          fromThemeEdit
          selected={expert ? ["expert"] : []}
          handleCheckboxClick={handleCheckboxClick}
          onClick={(val) => handleMoreOptionClick(val, member.user_id)}
        />
      </div>
    </div>
  );
};

export default MemberItem;

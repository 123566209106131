import React, { useEffect, useState } from "react";

import styles from "./filterItem.module.scss";
import FilterRowComponent from "../filterRow/filterRow.component";
import {
  queryStringUrlReplacement,
  queryParamStringToArray,
} from "../../../../../common/helper";
import { flat } from "../../chartsWrapper/chartsView/charts/utils";

const FilterItemComponent = ({
  items,
  level,
  type,
  handleExpand,
  topicQuery,
  industryQuery,
  businessUnitQuery,
  kyndrylCountry,
  history,
}) => {
  const [categoryItems, setCategoryItems] = useState([]);

  useEffect(() => {
    if (items) {
      const categoryByType = {
        industry: industryQuery,
        topic: topicQuery,
        business_unit: businessUnitQuery,
        k_country: kyndrylCountry,
      };
      const arr = queryParamStringToArray(categoryByType[type] || "");
      const newItems = items.map((item) => {
        const itemIsSelected = arr.includes(item.id);

        return {
          ...item,
          selected: itemIsSelected,
        };
      });

      setCategoryItems(newItems);
    }

    return () => null;
  }, [
    items,
    type,
    topicQuery,
    industryQuery,
    businessUnitQuery,
    kyndrylCountry,
  ]);

  const handleFilterItemCheck = (val, item, category, isParent) => {
    let value = [];

    if (isParent) {
      const newItems = categoryItems.map((s) => {
        if (s.id === item.id) {
          return {
            ...s,
            selected: val,
          };
        }
        return s;
      });

      setCategoryItems(newItems);

      value = newItems
        .filter((item) => item.selected)
        .reduce((acc, elem) => {
          let childIds = "";

          if (category === "topic" && elem.children_topics) {
            const flatTopics = flat(elem.children_topics, "children_topics");

            childIds = flatTopics.reduce(
              (acc1, elem1) => `${acc1}${acc1.length ? "," : ""}${elem1.id}`,
              "",
            );
          }

          if (category === "industry" && elem.children_industries) {
            const flatIndustries = flat(
              elem.children_industries,
              "children_industries",
            );

            childIds = flatIndustries.reduce(
              (acc1, elem1) => `${acc1}${acc1.length ? "," : ""}${elem1.id}`,
              "",
            );
          }

          return `${acc}${acc.length ? "," : ""}${elem.id}${
            childIds.length ? `,${childIds}` : ""
          }`;
        }, "");
    } else {
      const categoryByType = {
        industry: industryQuery,
        topic: topicQuery,
        business_unit: businessUnitQuery,
        k_country: kyndrylCountry,
      };

      const arr = queryParamStringToArray(categoryByType[type] || "");

      const childIsSelected = arr.some((id) => id === item.id);

      if (childIsSelected) {
        value = arr
          .filter((id) => id !== item.id)
          .reduce((acc, id) => `${acc}${acc.length ? "," : ""}${id}`, "");
      } else {
        value = [...arr, item.id].reduce(
          (acc, id) => `${acc}${acc.length ? "," : ""}${id}`,
          "",
        );
      }

      const newItems = categoryItems.map((s) => {
        if (s.id === item.id) {
          return {
            ...s,
            selected: childIsSelected,
          };
        }
        return s;
      });

      setCategoryItems(newItems);
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      type,
      value,
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        [type]: value,
        page: 1,
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      {categoryItems.map((item, idx) => (
        <FilterRowComponent
          key={`${item.id}-${idx}`}
          item={item}
          items={
            type === "topic" ? item.children_topics : item.children_industries
          }
          level={level}
          handleCheck={handleFilterItemCheck}
          type={type}
          isChecked={item.selected}
          handleExpand={handleExpand}
          topicQuery={topicQuery}
          industryQuery={industryQuery}
          businessUnitQuery={businessUnitQuery}
          kyndrylCountry={kyndrylCountry}
          history={history}
        />
      ))}
    </div>
  );
};

export default React.memo(FilterItemComponent);

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { format, parseISO } from "date-fns";
import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import { httpGet } from "../../../../common/httpCall";

import {
  UserInline,
  UserInlineTypes,
  Button,
  Loading,
  BtnType,
} from "../../../primitives";
import styles from "./ideasVotes.module.scss";
import ConfirmActionComponent from "../../../../common/components/confirmAction/confirmAction.component";
import { appModelSettingSet } from "application/app.action";
import { ideasDeleteAttempt } from "modules/admin/adminIdeas/adminIdeas.action";

const IdeaProfileVotes = ({ canEdit }) => {
  const {
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [votes, setVotes] = useState([]);
  const [selectedIdea, setSelectedIdea] = useState({});
  const [exportLoading, setExportLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedVariant, setSelectedVarian] = useState("votes");
  const [comments, setComments] = useState([]);
  const csvLinkRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const index = history.location.pathname.lastIndexOf("/");

    if (session && index) {
      setLoading(true);
      const path = history.location.pathname.slice(index + 1);

      httpGet({
        call: `ideas/${path}/votes`,
      }).subscribe(
        async (res) => {
          await httpGet({
            call: `ideas/${path}`,
          }).subscribe(
            async (res) => {
              setSelectedIdea(res.response);
              setComments(res.response.ideas_comments);
            },
            (err) => {
              setLoading(false);
              enqueueSnackbar("Something went wrong", {
                variant: "error",
              });
            }
          );
          setLoading(false);

          setVotes(res.response.votes);
        },
        () => {
          setLoading(false);
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        }
      );
    }
  }, [session, selectedCouncil]);

  useEffect(() => {
    if (votes?.length || comments?.length) {
      setCsvData(() => {
        const csvDataCont = [
          ["Submitter", "Vote", "Date", "Comment", "Comment Date"],
        ];
        votes.map((vote) => {
          const foundedComments = vote?.comments?.find(
            (com) => com?.user?.user_id === vote?.user?.user_id
          );
          const voteRow = [
            vote?.user?.full_name,
            vote?.vote_type?.toUpperCase(),
            format(parseISO(vote?.created_at), "eeee, MMM do, yyyyy h:mm a"),
          ];

          if (foundedComments) {
            const commentsData = [];
            const commentsArray = vote?.comments?.map((com) => {
              if (com?.user?.user_id === vote?.user?.user_id) {
                commentsData.push({
                  text: com?.comment,
                  date: `${format(
                    parseISO(com?.created_at),
                    "eeee, MMM do, yyyy h:mm a"
                  )}`,
                });
              }
            });
            const comments = commentsData?.map((com) => `${com?.text}`);
            const commentsDates = commentsData?.map((com) => `${com?.date}`);
            const commentsString = comments?.join("\n");
            const commentsDatesString = commentsDates?.join("\n");
            Boolean(comments.length) && voteRow.push(commentsString);
            Boolean(commentsDates.length) && voteRow.push(commentsDatesString);
          }

          csvDataCont.push(voteRow);
        });

        const filteredComents = comments?.filter((comment) => {
          const usedComment = votes?.find(
            (vote) => vote?.user?.user_id === comment?.user?.user_id
          );
          return !usedComment;
        });

        filteredComents.forEach((comment) => {
          const commentRow = [
            comment?.user?.full_name,
            "-",
            "-",
            comment.comment,
            format(parseISO(comment.created_at), "eeee, MMM do, yyyy h:mm a"),
          ];
          csvDataCont.push(commentRow);
        });

        return csvDataCont;
      });
    }
  }, [votes, comments]);

  const handleExportList = () => {
    csvLinkRef.current.link.click();
  };

  const DisabledButton = ({ showTooltip, setShowTooltip }) => (
    <>
      {showTooltip && (
        <div className={styles.exportModalTooltip}>
          <p>
            {loading
              ? "Search cannot be exported"
              : "To be able to export list it must contain at least one element"}
          </p>
        </div>
      )}
      <Button
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
        className={styles.deleteSubmitButton}
        btn={BtnType.DISABLED}
      >
        EXPORT
      </Button>
    </>
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Submitter",
        id: "user.user_id",
        sortable: false,
        // name: 'web',
        accessor: (row) => (
          <UserInline
            className={styles.userIcon}
            classObj={{ name: styles.userName }}
            userId={row.user.user_id}
            type={UserInlineTypes.WITH_NAME}
            showMine={false}
            first={row.user.full_name}
            last=""
            avatar={row.user.avatar}
            local
          />
        ),
        width: 300,
        style: {
          display: "flex",
          justifyContent: "flex-start",
        },
      },
      {
        Header: "Vote",
        id: "vote_type",
        sortable: false,
        // name: 'web',
        accessor: (row) => (
          <div className={styles.value}>{row.vote_type.toUpperCase()}</div>
        ),
        width: 200,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Date",
        id: "created_at",
        sortable: false,
        // name: 'web',
        accessor: (row) => (
          <div className={styles.value}>
            {format(parseISO(row.created_at), "eeee, MMM do, yyyy h:mm a")}
          </div>
        ),
        width: 400,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [votes, selectedVariant]
  );

  const commentsColumns = React.useMemo(
    () => [
      {
        Header: "Submitter",
        id: "user.user_id",
        sortable: false,
        // name: 'web',
        accessor: (row) => (
          <UserInline
            className={styles.userIcon}
            classObj={{ name: styles.userName }}
            userId={row.user.user_id}
            type={UserInlineTypes.WITH_NAME}
            showMine={false}
            first={row.user.full_name}
            last=""
            avatar={row.user.avatar}
            local
          />
        ),
        width: 300,
        style: {
          display: "flex",
          justifyContent: "flex-start",
        },
      },
      {
        Header: "Date",
        id: "created_at",
        sortable: false,
        accessor: (row) => (
          <div className={styles.value}>
            {format(parseISO(row.created_at), "eeee, MMM do, yyyy h:mm a")}
          </div>
        ),
        width: 300,
        style: {
          display: "flex",
          justifyContent: "flex-start",
        },
      },
      {
        Header: "Comments",
        id: "comment",
        sortable: false,
        accessor: (row) => <div className={styles.value}>{row.comment}</div>,
        width: 300,
        style: {
          display: "flex",
          justifyContent: "flex-start",
        },
      },
    ],
    [comments, selectedVariant]
  );

  const handleIdeaDelete = () => {
    const index = history.location.pathname.lastIndexOf("/");
    const id = history.location.pathname.slice(index + 1);

    dispatch(
      ideasDeleteAttempt({
        id,
        enqueueSnackbar,
        name: selectedIdea?.name,
        cb: () => {
          const timeout = setTimeout(() => {
            history.push("/ideas");
            clearTimeout(timeout);
          }, 1000);
        },
      })
    );
  };

  const handleDeleteClick = () => {
    const customSettings = {
      title: "Confirmation Prompt",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmActionComponent
          confirmBtnName="DELETE"
          alertText="You are about to delete this Idea from your council. This action cannot be undone. Do you want to proceed?"
          onConfirm={handleIdeaDelete}
        />
      ),
    };
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <span
            className={styles.rootText}
            onClick={() => {
              history.push("/admin");
            }}
          >
            ADMIN
          </span>
          <span className={styles.separationBar}> / </span>

          <span
            className={styles.rootText}
            onClick={() => {
              history.push("/admin/ideas?tab=ideas");
            }}
          >
            Ideas
          </span>
          <span className={styles.separationBar}> / </span>
          <span className={styles.rootText}>{selectedIdea?.name}</span>
        </h4>
      </div>

      <div className={styles.switcherBlock}>
        <h5 className={styles.tabsWrapper}>
          <span
            onClick={() => setSelectedVarian("votes")}
            className={
              selectedVariant === "votes" ? styles.selectedVar : styles.tabsText
            }
          >
            {" "}
            VOTES{" "}
          </span>
          <span className={styles.separationBar}> / </span>
          <span
            onClick={() => setSelectedVarian("comments")}
            className={
              selectedVariant === "comments"
                ? styles.selectedVar
                : styles.tabsText
            }
          >
            {" "}
            COMMENTS{" "}
          </span>
        </h5>
        <div className="d-flex flex-column">
          <Button
            onClick={() => {
              const index = history.location.pathname.lastIndexOf("/");
              history.push(
                `/ideas/${history.location.pathname.slice(index + 1)}`
              );
            }}
            btn={BtnType.TAG_OUTLINE_BLUE}
          >
            go to idea profile
          </Button>
          <Button
            className="mt-2 justify-content-end text-danger"
            onClick={handleDeleteClick}
            btn={BtnType.FRAME_LESS}
            icon="icn-button-delete"
          >
            Delete Idea
          </Button>
        </div>
      </div>

      <div className={styles.buttonWrp}>
        {exportLoading ? (
          <Loading />
        ) : (votes.length > 0 && !loading) || (comments.length && !loading) ? (
          <div>
            <Button onClick={handleExportList} btn={BtnType.OUTLINE}>
              EXPORT
            </Button>
            <CSVLink
              data={csvData}
              filename="Traction Ideas Votes Templates.csv"
              style={{ display: "none" }}
              ref={csvLinkRef}
            />
          </div>
        ) : (
          <DisabledButton
            setShowTooltip={setShowTooltip}
            showTooltip={showTooltip}
          />
        )}

        {selectedVariant === "votes" ? (
          <div className={styles.totalCount}>
            {votes.length === 0 ||
              (votes.length > 1 && <span>{votes.length} Votes</span>)}
            {votes.length === 1 && <span>{votes.length} Vote</span>}
          </div>
        ) : (
          <div className={styles.totalCount}>
            {comments.length === 0 ||
              (comments.length > 1 && <span>{comments.length} Comments</span>)}
            {comments.length === 1 && <span>{comments.length} Comment</span>}
          </div>
        )}
      </div>

      <div>
        <ReactTable
          columns={selectedVariant === "votes" ? columns : commentsColumns}
          data={selectedVariant === "votes" ? votes : comments}
          showPagination={false}
          minRows={5}
          loading={loading}
          className={styles.table}
        />
      </div>
    </div>
  );
};

export default React.memo(IdeaProfileVotes);

import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { Button, BtnType } from "../../../../../modules/primitives";
import styles from "./listDownload.module.scss";

const types = {
  0: 'csv',
  1: 'xlsx',
  2: 'pdf',
}

const ListDownload = ({ handleDownloadClick, downloadIsActive, showPdf }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDownload = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (type) => {
    if (typeof type !== "number") {
      setAnchorEl(null);
      return;
    }

    handleDownloadClick(types[type]);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Button
        btn={BtnType.FRAME_LESS}
        className={`${styles.download} ${
          downloadIsActive ? styles.disabled : ""
        }`}
        icon="icn-export-button"
        onClick={handleDownload}
        id="download-button"
        aria-controls="download-button"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disabled={downloadIsActive}
      >
        <div className="d-flex align-items-center">
          Download Source Data
          {downloadIsActive && <div className={styles.loading} />}
        </div>
      </Button>
      <Menu
        id="downloadListOptions"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "download-button",
        }}
      >
        <MenuItem onClick={() => handleClose(0)}>DOWNLOAD CSV</MenuItem>
        <MenuItem onClick={() => handleClose(1)}>DOWNLOAD EXCEL</MenuItem>
        {
          showPdf && (
            <MenuItem onClick={() => handleClose(2)}>DOWNLOAD PDF</MenuItem>
          )
        }
      </Menu>
    </div>
  );
};

export default React.memo(ListDownload);

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import * as classNames from "classnames";
import { useHistory } from "react-router-dom";

import isItSmallDevice from "modules/themes/themesCommon/isItSmallDevice";
import styles from "./styles.module.scss";
import {
  Pannel,
  CompanyState,
  stateType,
  CompanyAvatar,
  Loading,
  Pagination,
} from "../../../../primitives";
import { themesGetIndividualAttempt } from "../../../themesMainPage/themesMain.action";

import { useModelPopup } from "../../../../../common/hooks";
import { Icon, mdSize, smSize } from "../../../../../common/icon";
import ConfirmDeleteComponent from "../../../../../common/components/confirmDelete/index";
import {
  themesCompanyAddAttempt,
  themesCompanyGetAttempt,
} from "../overviewTab.action";
import isUserAdmin from "../../../themesCommon/isUserAdmin/index";
import MixedAddCompanyOrProducts from "../../../../../common/components/mixedSearch/mixedAddCompanyOrProducts";

const CompaniesComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { currentThemeData },
      themesOverviewReducer: { companyList, loadingCompany, companiesPageMeta },
    },
  } = useSelector((state) => state);

  const [seeAll, setSeeAll] = useState(false);
  const [isCompaniesOpened, setIsCompaniesIsOpenned] = useState(true);

  const [pageSetting, setPageSetting] = useState({
    pageSize: 10,
    current: 1,
    hideOnSinglePage: true,
    showTitle: false,
    total: companiesPageMeta.total || 0,
  });
  const [chunkToShow, setChunkToShow] = useState([]);

  const handlePagingChange = (page) => {
    setPageSetting((prev) => ({
      ...prev,
      total: companiesPageMeta.total,
      current: page,
    }));
  };

  useEffect(() => {
    if (companyList?.length && !seeAll) {
      setChunkToShow(companyList.slice(0, 6));
    }
    if (pageSetting?.current && companyList?.length && seeAll) {
      setChunkToShow(companyList);
    }
  }, [pageSetting?.current, companyList, seeAll]);

  useEffect(() => {
    if (companyList?.length) {
      setPageSetting((prev) => ({
        ...prev,
        total: companiesPageMeta.total,
      }));
    }
  }, [companyList?.length]);

  const handleAddCallback = (selectedCompanies) => {
    const data = {
      theme_company: selectedCompanies?.map((comp) => {
        if (comp.is_product) {
          return {
            council_product_id: comp.id,
            is_product: true,
          };
        }
        return {
          company_id: comp.id,
          is_product: false,
        };
      }),
    };

    dispatch(
      themesCompanyAddAttempt({
        themeId: currentThemeData.id,
        data,
        activeLayout: "",
        enqueueSnackbar,
        cb: () => {
          popup.hide();
          dispatch(
            themesGetIndividualAttempt({
              id: currentThemeData?.id,
              enqueueSnackbar,
            })
          );
        },
      })
    );
  };

  const handleAddCompanyClick = () => {
    popup.show({
      title: `Add Company to ${
        selectedCouncil?.settings?.themes_display_name_singular || "Theme"
      }`,
      component: (
        <MixedAddCompanyOrProducts
          handleSave={handleAddCallback}
          placeholder="Type to search or select from below"
          label="Companies or Products"
          existingItems={companyList?.map((c) => ({
            ...c,
            id: c.company_id || c.product_id,
          }))}
        />
      ),
    });
  };

  const handleDeleteCompany = (themeId, item) => {
    const actionType = item.is_product
      ? "deleteThemeProduct"
      : "deleteThemeCompany";
    const id = item.is_product ? item.product_id : item.company_id;

    popup.show({
      title: "Confirmation Delete",
      show: true,
      height: "300",
      width: "540",
      component: (
        <ConfirmDeleteComponent
          id={themeId}
          templateId={id}
          enqueueSnackbar={enqueueSnackbar}
          councilId={selectedCouncil?.id}
          actionType={actionType}
        />
      ),
    });
  };

  const checkUserDeletePermission = () => {
    if (isUserAdmin(session?.council_role)) {
      return true;
    }
    if (
      currentThemeData?.current_user_role === "manager" ||
      currentThemeData?.current_user_role === "owner"
    ) {
      return true;
    }
  };

  const handleCompanyRedirect = (item) => {
    if (item.is_product) {
      history.push(
        `/companies/${item.company_slug || item.company_id}/products/${
          item.slug || item.product_id
        }`
      );
      return;
    }

    history.push(`/companies/${item.slug || item.company_id}`);
  };

  useEffect(() => {
    if (selectedCouncil && currentThemeData.id) {
      dispatch(
        themesCompanyGetAttempt({
          id: currentThemeData.id,
          enqueueSnackbar,
          page: seeAll ? pageSetting.current : 1,
          items: pageSetting.pageSize,
        })
      );
    }
  }, [selectedCouncil, pageSetting.current, seeAll]);

  if (loadingCompany) {
    return <Loading />;
  }

  return (
    <Pannel
      title={`${
        selectedCouncil?.settings?.themes_display_name_singular || "Theme"
      } Companies`}
      padding="0"
      parentStyle={{
        marginTop: `${window.screen.width < 950 ? "0px" : "-20px"}`,
        width: `${window.screen.width < 550 ? "100%" : "230px"}`,
        minWidth: "230px",
        padding: `${window.screen.width < 550 && "0px"}`,
      }}
    >
      {" "}
      <div className={styles.panelHeader}>
        Companies
        <div className={styles.panelIcons}>
          {isItSmallDevice() && (
            <Icon
              {...mdSize}
              icon="icn-table-down-chevron"
              className={
                isCompaniesOpened
                  ? styles.rotatedDownChevron
                  : styles.downChevron
              }
              onClick={() => {
                setIsCompaniesIsOpenned(!isCompaniesOpened);
              }}
            />
          )}
          {(isUserAdmin(session?.council_role) ||
            (session?.council_role === "standard" &&
              currentThemeData?.current_user_role)) && (
            <Icon
              {...mdSize}
              className={styles.addNew}
              icon="icn-add"
              onClick={handleAddCompanyClick}
            />
          )}
        </div>
      </div>
      {isCompaniesOpened && (
        <div className={styles.wrapper}>
          {companyList?.length > 0 ? (
            chunkToShow?.map((item) => (
              <div
                key={item.is_product ? item.product_id : item.company_id}
                className={classNames(styles.Row)}
                onClick={() => handleCompanyRedirect(item)}
              >
                <div className={styles.itemContainer}>
                  <div className={styles.firstCol}>
                    <div className={styles.nameContainer}>
                      <div className={styles.companyIcon}>
                        <div className={styles.iconCon}>
                          <CompanyAvatar imgSrc={item.logo} name={item.name} />
                        </div>
                      </div>
                      <div>{item.name}</div>
                    </div>
                    {item.is_product ? (
                      <span className="small text-muted">Product</span>
                    ) : null}
                  </div>
                  <div className={styles.actionsContainer}>
                    <div className={styles.statusWrp}>
                      <CompanyState state={item.state} type={stateType.ICON} />
                    </div>
                    {checkUserDeletePermission() && (
                      <div className={styles.deleteIcon}>
                        <Icon
                          {...smSize}
                          icon="icn-button-delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            return handleDeleteCompany(
                              currentThemeData.id,
                              item
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <span className="p-3 text-center">No companies yet!</span>
          )}
          {seeAll && (
            <div className={styles.paginationCon}>
              <Pagination {...pageSetting} onChange={handlePagingChange} />
            </div>
          )}

          {(companyList?.length > 6 || pageSetting.current > 1) && (
            <div onClick={() => setSeeAll(!seeAll)} className={styles.showAll}>
              {seeAll ? "HIDE" : "SEE ALL"}
            </div>
          )}
        </div>
      )}
    </Pannel>
  );
};

export default React.memo(CompaniesComponent);

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Select from "react-dropdown-select";

import { useModelPopup } from "common/hooks";
import { ModalBody, ModalFooter } from "../../../application/modal";
import { BtnType, Button, Label, TextBox } from "../../../modules/primitives";
import { addPlant, editPlant } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import LoadingComponent from "modules/primitives/loading/loading.component";

const AddAdminPlant = ({ plant, existingPlants, regions }) => {
  const {
    adminPlantsAndAreasReducer: { plantsAndAreas }
  } = useSelector((state) => state);

  const [plantName, setPlantName] = useState(plant?.attributes?.name || '');
  const [fetching, setFetching] = useState(false);
  const [invalidPlantName, setInvalidPlantName] = useState(false);
  const [selectedAreas, setSelectedAreas] = useState(plant?.attributes?.areas || []);
  const [plantRegion, setPlantRegion] = useState(null);

  useEffect(() => {
    const getRegion = regions?.filter(r => r.id === plant?.attributes?.plant_region?.plant_region_id) || [];

    if (getRegion && plant) {
      setPlantRegion(getRegion);
    }
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const dispatch = useDispatch();

  const handleSave = () => {
    setFetching(true);

    if (plant?.id) {
      const payload = {
        plant: {
          name: plantName,
          area_ids: selectedAreas.map(a => a.area_id),
          plant_region_id: plantRegion[0]?.id,
        },
        plantId: plant.id,
        cb: () => {
          popup.hide();
          enqueueSnackbar('Successfully edited plant', {
            variant: 'success'
          });
        }
      };

      dispatch(editPlant(payload));

      return;
    }

    const payload = {
      plant: {
        name: plantName,
        area_ids: selectedAreas.map(a => a.area_id),
      },
      cb: () => {
        popup.hide();
        enqueueSnackbar('Successfully added new plant', {
          variant: 'success'
        });
      }
    };

    dispatch(addPlant(payload));
  };

  const handleDismiss = () => {
    popup.hide();
  };

  const handleChangePlantName = (evt) => {
    const value = evt.target.value;
    setPlantName(value);

    const isInvalidPlantName = existingPlants.includes(value.toLowerCase());
    setInvalidPlantName(isInvalidPlantName);
  }

  const makePlantsArray = useMemo(() => {
    if (!plantsAndAreas) {
      return [];
    }

    return plantsAndAreas.map(plant => ({id: plant.id, ...plant.attributes}));
  }, [plantsAndAreas]);

  const getAllFlatAreas = useMemo(() => {
    const allAreas = makePlantsArray.reduce((acc, plant) => [...acc, ...plant.areas || []], []);
    return [...new Set(allAreas.map(a => a.area_id))]
      .map(id => allAreas.find(a => a.area_id === id));
  }, [makePlantsArray]);

  return (
    <>
      <ModalBody>
        <div>
          <h5 className="mb-4">Plants can be added to Projects, Solutions, or Ideas</h5>
          <Label>Plant Name</Label>
          <TextBox
            type="text"
            placeholder="Plant Name"
            className="w-100"
            value={plantName}
            onChange={handleChangePlantName}
          />
          {
            invalidPlantName && (
              <span className="small text-danger">Plant name already exists, please choose another one!</span>
            )
          }
          {
            (!invalidPlantName && plantName?.length > 0) && (
              <>
                <Label>Select Areas</Label>
                <Select
                  multi
                  closeOnSelect
                  values={selectedAreas}
                  onChange={setSelectedAreas}
                  options={getAllFlatAreas}
                  labelField="area_name"
                  valueField="area_id"
                  placeholder="Select Areas"
                  searchBy="area_name"
                  style={{ border: "1px solid #e4e7eb" }}
                />
              </>
            )
          }
          {
            regions?.length ? (
              <div className="mt-3">
                <Label>Select region</Label>
                <Select
                  closeOnSelect
                  values={plantRegion || []}
                  onChange={setPlantRegion}
                  options={regions}
                  labelField="name"
                  valueField="id"
                  placeholder="Select Region"
                  searchBy="name"
                  style={{ border: "1px solid #e4e7eb" }}
                />
              </div>
            ) : null
          }
        </div>
      </ModalBody>
      <ModalFooter>
        {
          fetching ? (
            <LoadingComponent customText="Saving plant..." />
          ) : (
            <>
              <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                btn={BtnType.REGULAR}
                className="ml-2"
                disabled={invalidPlantName || !plantName?.length}
              >
                Save
              </Button>
            </>
          )
        }
      </ModalFooter>
    </>
  );
};

export default React.memo(AddAdminPlant);

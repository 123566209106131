import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";

import { ModalFooter, ModalBody } from "../../../../../application/modal";
import { Button, BtnType, Label, Loading } from "../../../../primitives";
import styles from "./saveSearch.module.scss";
import {
  setCompanySavedListAttempt,
  clearCreatedList,
  setSelectedList,
} from "modules/lists/store/lists.actions";
import {
  queryParamStringToArray,
  convertFilterIndexToValues,
} from "../../../../../common/helper";

const SaveSearchComponent = (props) => {
  const [value, setValue] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    handleSaveHide,
    setShowMyList = () => {},
    mode,
    query,
    companyIds,
    productIds,
  } = props;
  const {
    councilReducer: { selectedCouncil },
    listsMainReducer: { createdListId },
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
  } = useSelector((state) => state);

  const handleCancelClick = () => {
    handleSaveHide();
  };

  const handleSaveClick = useCallback(() => {
    let list;

    if (mode === "empty") {
      if (productIds?.length) {
        list = {
          name: value.toLowerCase(),
          council_id: selectedCouncil.id,
          privateProp: true,
          product_ids: productIds,
          enqueueSnackbar,
        };
      } else {
        list = {
          name: value.toLowerCase(),
          council_id: selectedCouncil.id,
          privateProp: true,
          enqueueSnackbar,
          company_ids: companyIds,
        };
      }
    } else if (mode === "redirect") {
      list = {
        name: value.toLowerCase(),
        council_id: selectedCouncil.id,
        privateProp: true,
        company_ids: [selectedCompany.id],
        enqueueSnackbar,
      };
    } else {
      let search;

      if (location.state) {
        const { state } = location;

        const relationShipStatus = queryParamStringToArray(
          state.relationShipStatus || ""
        );
        const topics = queryParamStringToArray(state.topic || "");
        const industries = queryParamStringToArray(state.industry || "");
        const owners = queryParamStringToArray(state.owners || "");
        const locationStrAsArray = queryParamStringToArray(
          state?.location || ""
        );
        const agreement_types_ids = queryParamStringToArray(
          state?.agreements || ""
        );
        const idb_connection_options = queryParamStringToArray(
          state?.idbEcosystem || ""
        );
        const location_countries = queryParamStringToArray(
          state?.k_country || ""
        );
        const council_relationship_stage_id = queryParamStringToArray(
          state?.council_relationship_stage_id || state.relationShipStatus || ""
        );
        let locationProps = {};
        const custom_tags_ids = queryParamStringToArray(
          state?.custom_tags_ids || ""
        );
        if (locationStrAsArray.length) {
          const splitedValue = locationStrAsArray
            .map((str) => str.replace(/-/g, ","))[0]
            .split(",")[0]
            .split("#");
          const value = splitedValue[0];
          const type = splitedValue[1];

          if (splitedValue) {
            locationProps[`location_${type}`] = value;
          }
        }

        search = {
          query: state.query || "",
          therapeutic_area_ids: [],
          topic_ids: topics,
          industry_ids: industries,
          relationship_stages: relationShipStatus,
          council_relationship_stage_id,
          council_company_score_gte: state.scoreFrom
            ? Number(state.scoreFrom)
            : 0,
          council_company_score_lte: state.scoreTo ? Number(state.scoreTo) : 10,
          founded_gte: state.foundedFrom ? state.foundedFrom : null,
          founded_lte: state.foundedTo ? state.foundedTo : null,
          relationship_owners_ids: owners,
          lists: state.lists ? state.lists.split(",") : null,
          num_employees_enums:
            state.employees && state.employees.length
              ? state.employees.split(",")
              : null,
          revenue_ranges:
            state.estimatedRevenue && state.estimatedRevenue.length
              ? state.estimatedRevenue.split(",")
              : null,
          total_fundings_sum_gte: state.fundingFrom
            ? Number(state.fundingFrom.replace(/\D/g, ""))
            : 0,
          total_fundings_sum_lte:
            state.fundingTo && state.fundingTo !== "Any Amount"
              ? Number(state.fundingTo.replace(/\D/g, ""))
              : null,
          locationProps,
          agreement_types_ids,
          location_countries,
          idb_connection_options,
          custom_tags_ids: convertFilterIndexToValues(
            custom_tags_ids,
            selectedCouncil?.council_custom_tag_groups
          ),
        };
      } else {
        search = {};
      }

      list = {
        name: value.toLowerCase(),
        council_id: selectedCouncil.id,
        privateProp: true,
        enqueueSnackbar,
        search_params: {
          query,
          search,
        },
      };
    }

    if (value && !error) {
      setShowLoading(true);
      dispatch(setCompanySavedListAttempt(list));
      setError("");
    }
  }, [location, query, selectedCouncil, value]);

  useEffect(() => {
    if (createdListId) {
      dispatch(setSelectedList({ id: createdListId }));
      if (mode !== "redirect") {
        history.push({
          pathname: `/list/${createdListId}`,
          state: {
            fromCompanyCreateList:
              history.location.pathname.split("/")[1] === "companies",
          },
        });
        setShowMyList(true);
      } else {
        history.push(`/list/${createdListId}`);
        dispatch(clearCreatedList());
      }
      handleSaveHide();
    }
  }, [createdListId]);

  const handleChange = (e) => {
    setValue(e.target.value);
    if (e.target.value === "") {
      setError("Fill in the list name field");
    }
    if (e.target.value.trim() === "") {
      setError("List name must contain characters");
    }
    if (e.target.value.length >= 25) {
      setError(
        "List name should contain max. 25 characters, consider removing spaces"
      );
    } else if (
      e.target.value &&
      e.target.value.length <= 26 &&
      e.target.value.trim() !== ""
    ) {
      setError("");
    }
  };

  return (
    <div className={styles.wrapper}>
      <ModalBody>
        {showLoading ? (
          <Loading />
        ) : (
          <>
            <Label>List Name:</Label>
            <input
              placeholder="Add List Name"
              onChange={handleChange}
              className={styles.textBoxWrp}
              maxLength={25}
            />
            {error && <div className={styles.errorMessage}>{error}</div>}
          </>
        )}
      </ModalBody>
      {!showLoading ? (
        <ModalFooter>
          <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button
            disabled={showLoading}
            type="submit"
            btn={BtnType.REGULAR}
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </ModalFooter>
      ) : null}
    </div>
  );
};

export default SaveSearchComponent;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import { useSnackbar } from "notistack";

import {
  countryCheck,
  patientOrganizationSelectedDiseasesReset,
} from "modules/patientOrganizations/patientOrganizationMain/patientOrganizationMain.action";
import AIModalWrapper from "modules/tractionAI/AIModalWrapper";
import { getMeAttempt } from "modules/auth/auth.action";
import { headerMenu } from "./header.constant";
import styles from "./header.module.scss";
import { Icon, lgSize } from "../../common/icon";
import CouncilComponent from "../../modules/council";
import LoggedUser from "./loggedUser/loggedUser.component";
import { appMobileNaviSet } from "../app.action";
import kyndrylFilter from "./headerFilters/kyndrylFilter";
import gskFilter from "./headerFilters/gskFilter";
import adminFilter from "./headerFilters/adminFilter";
import navFilter from "./headerFilters/navFilter";
import homexFilter from "./headerFilters/homexFilter";
import HamburgerMenu from "./hamburgerMenu/hamburgerMenu.component";
import { getCookie } from "../../common/helper";
import TractionLogo from "../../common/components/tractionLogo";

const Header = (props) => {
  const [pathname, setPathName] = useState(props.location.pathname);
  const [rootName, setRootName] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    appReducer: { showMobileNavi },
    authReducer: { session },
    councilReducer: { selectedCouncil },
    discussionsReducer: { newMessagesCount },
  } = useSelector((state) => state);

  useEffect(() => {
    const user_id = getCookie("user_id_");

    if (user_id) {
      const action = getMeAttempt({ sessionId: user_id, enqueueSnackbar });
      dispatch(action);
    }
  }, [selectedCouncil]);

  useEffect(() => {
    setPathName(history?.location?.pathname);
  }, [history.location]);

  useEffect(
    () =>
      history.listen((path) => {
        setPathName(path.pathname);
      }),
    []
  );

  const handleConversationsClick = () => {
    history.push("/discussions");
  };

  const handlehamburgerClick = () => {
    dispatch(appMobileNaviSet(!showMobileNavi));
  };

  const filteredHeaderMenu = (navMenu) => {
    let menu = navMenu;
    if (selectedCouncil?.settings?.events_connected === false) {
      menu = menu.filter((page) => page.text !== "events");
    }
    if (selectedCouncil?.settings?.reports_connected === false) {
      menu = menu.filter((page) => {
        return page.text !== "Reports";
      });
    }
    if (selectedCouncil?.settings?.ideas_connected === false) {
      menu = menu.filter((page) => {
        return page.text !== "Ideas";
      });
    }
    if (
      selectedCouncil?.settings?.solutions_connected === false ||
      selectedCouncil?.settings?.solutions_connected === undefined
    ) {
      menu = menu.filter((page) => {
        return page.text !== "solutions";
      });
    }
    const council_role = session?.council_role;
    if (
      selectedCouncil &&
      selectedCouncil.traction_tag === "kyndryl" &&
      council_role === "viewer"
    ) {
      return menu.filter((page) => kyndrylFilter(page, selectedCouncil));
    }
    if (selectedCouncil && selectedCouncil.traction_tag === "gsk") {
      return gskFilter(menu, session, council_role, selectedCouncil);
    }
    if (selectedCouncil && selectedCouncil.traction_tag === "home_x") {
      return homexFilter(menu, council_role, session, selectedCouncil);
    }
    if (selectedCouncil) {
      return navFilter(menu, council_role, session, selectedCouncil);
    }
    return adminFilter(menu, council_role, session, selectedCouncil);
  };

  useEffect(() => {
    const paths = pathname.split("/");
    setRootName(paths[1]);
  }, [pathname]);

  const handleLinkClick = (isGsk) => {
    if (isGsk) {
      dispatch(patientOrganizationSelectedDiseasesReset());
      dispatch(countryCheck([]));
    }
  };

  return (
    <div className={styles.headerWrp}>
      <div className={styles.headerCon}>
        <CouncilComponent />
        <div className={styles.additionalWrr}>
          {session && selectedCouncil ? (
            <>
              <ul className={classNames("hideInLap", styles.menuWrp)}>
                {filteredHeaderMenu(headerMenu).map((menu) => {
                  const isActive =
                    menu.path.substr(1) === rootName ||
                    menu.path.split("/")[1] === rootName;

                  return (
                    <Link
                      key={menu.text}
                      to={menu.path}
                      className={classNames(
                        styles.menuItem,
                        isActive && styles.active
                      )}
                      onClick={() => handleLinkClick(menu.gskOnly)}
                    >
                      {menu.text === "themes"
                        ? selectedCouncil?.settings?.themes_display_name ||
                          menu.text
                        : menu.text}
                    </Link>
                  );
                })}
              </ul>
            </>
          ) : null}
          {/* === "Ford" */}
          <div className={styles.loggedUserWrp}>
            {selectedCouncil?.name && (
              <HamburgerMenu
                councilTag={selectedCouncil.traction_tag}
                role={session?.council_role}
                selectedCouncilSettings={selectedCouncil?.settings}
              />
            )}
            <div
              className={classNames("showInLap", styles.hambuger)}
              onClick={handlehamburgerClick}
            />

            <div className={classNames("hideInLap", styles.loggedUserCon)}>
              {selectedCouncil?.settings?.traction_ai_connected && (
                <AIModalWrapper selectedCouncil={selectedCouncil} />
              )}
              <div className="position-relative">
                {newMessagesCount > 0 && (
                  <div
                    onClick={handleConversationsClick}
                    className={styles.messagesCount}
                  >
                    {newMessagesCount}
                  </div>
                )}
                <Icon
                  {...lgSize}
                  icon="icn-chat"
                  className={styles.icon}
                  onClick={handleConversationsClick}
                />
              </div>
              <LoggedUser />
            </div>
          </div>
          <TractionLogo />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Header);

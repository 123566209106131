export const GET_CLAIM_PROFILE_OVERVIEW_ATTEMPT = "GET_CLAIM_PROFILE_OVERVIEW_ATTEMPT";
export const getClaimProfileOverview = (payload) => ({
  type: GET_CLAIM_PROFILE_OVERVIEW_ATTEMPT,
  payload,
});

export const GET_CLAIM_PROFILE_OVERVIEW_SUCCESS = "GET_CLAIM_PROFILE_OVERVIEW_SUCCESS";
export const getClaimProfileOverviewSuccess = (payload) => ({
  type: GET_CLAIM_PROFILE_OVERVIEW_SUCCESS,
  payload,
});

export const UPDATE_CLAIM_PROFILE_ATTEMPT = "UPDATE_CLAIM_PROFILE_ATTEMPT";
export const updateClaimProfileAttempt = (payload) => ({
  type: UPDATE_CLAIM_PROFILE_ATTEMPT,
  payload,
});

export const UPDATE_CLAIM_PROFILE_SUCCESS = "UPDATE_CLAIM_PROFILE_SUCCESS";
export const updateClaimProfileSuccess = (payload) => ({
  type: UPDATE_CLAIM_PROFILE_SUCCESS,
  payload,
});

export const GET_PROFILE_FORMS_ATTEMPT = "GET_PROFILE_FORMS_ATTEMPT";
export const getProfileFormsAttempt = (payload) => ({
  type: GET_PROFILE_FORMS_ATTEMPT,
  payload,
});

export const GET_PROFILE_FORMS_SUCCESS = "GET_PROFILE_FORMS_SUCCESS";
export const getProfileFormsSuccess = (payload) => ({
  type: GET_PROFILE_FORMS_SUCCESS,
  payload,
});

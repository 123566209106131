import React, { useRef } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { Label, TextBox, Button, BtnType } from "modules/primitives";
import styles from "../adminThemes.module.scss";
import { initModel } from "./addTheme.constants";
import { ModalBody, ModalFooter } from "../../../../application/modal";
import { useModelPopup } from "../../../../common/hooks";

const AddThemeComponent = ({ mode, theme = {} }) => {
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const formRef = useRef();
  const popup = useModelPopup();

  const handleDismiss = () => {
    popup.hide();
  };

  const onSubmit = (values) => {
    popup.hide();
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={
        mode === "edit" && theme?.name ? {} : initModel && initModel
      }
      // validationSchema={validation && validation}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        onSubmit(values);
        resetForm();
      }}
    >
      {({ handleSubmit, ...formikprops }) => (
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className={styles.instructionText}>
              Your Theme Group will be available for all Themes
            </div>
            <Label>Theme Group Name</Label>
            <TextBox
              type="text"
              name="name"
              placeholder="Theme Group Name"
              formProps={formikprops}
              className={styles.textBox}
            />
          </ModalBody>
          <ModalFooter>
            <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
              Cancel
            </Button>
            <Button
              type="submit"
              btn={BtnType.HIGHLIGHT}
              className={styles.button}
            >
              {mode === "edit" ? "Save" : "Add"}
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default AddThemeComponent;

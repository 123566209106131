import { combineEpics, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";

import { errorHandler, httpGet } from "../../../../../common/httpCall";
import {
  GET_COMPARE_COMPANIES,
  getCompareCompaniesSuccess,
  PROJECT_COMPANY_COMPARE_CUSTOM_FIELDS_GET,
  projectCompanyCompareCustomFieldsGetSuccess,
} from "./compair.actions";

export const epicGetProjectCompanies = (action$, state$) =>
  action$.pipe(
    ofType(PROJECT_COMPANY_COMPARE_CUSTOM_FIELDS_GET),
    switchMap(({ payload: { councilId, enqueueSnackbar } }) =>
      httpGet({
        call: "custom_field_templates", // NEW_API
        // call: `companies/all?council=${payload}`,
      }).pipe(
        map((result) => projectCompanyCompareCustomFieldsGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetProjectCompareCompanies = (action$) =>
  action$.pipe(
    ofType(GET_COMPARE_COMPANIES),
    switchMap(({ payload: { projectId, items, page, enqueueSnackbar } }) =>
      httpGet({
        call: `projects/${projectId}/companies?items=${items || 20}&page=${
          page || 1
        }`,
      }).pipe(
        map((result) =>
          getCompareCompaniesSuccess({
            result,
            pageNr: result.xhr.getResponseHeader("total-pages"),
            companiesTotal: result.xhr.getResponseHeader("total-count"),
          })
        ),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const companyCompairEpic = combineEpics(
  epicGetProjectCompanies,
  epicGetProjectCompareCompanies
);

export default companyCompairEpic;

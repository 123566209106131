const setMarginForFirstCell = (val, tableRef) => {
  const table = tableRef.current.children[0].children[0];
  const reactTable = table.children[0];
  const rtBody = [...reactTable.children[1].children];
  const rtHeader = [...reactTable.children[0].children];
  rtHeader.forEach((headerGroup) => {
    const firstCell = headerGroup.children[0];
    const resizer = firstCell.children[1];
    if (resizer) {
      resizer.style.right = "0px";
    }
    firstCell.style.marginLeft = val;
    if (val.length > 3) {
      firstCell.style.marginRight = val.slice(1);
    } else {
      firstCell.style.marginRight = val;
    }
  });
  rtBody.forEach((tableGroup) => {
    const cellBlock = tableGroup.children[0];
    const firstCell = cellBlock.children[0];
    firstCell.style.marginLeft = val;
  });
};
export default setMarginForFirstCell;

import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Popover from "@mui/material/Popover/Popover";
import { Link } from "react-router-dom";

import {
  BtnType,
  Button,
  CompanyAvatar,
  CompanyState,
  ProgressBar,
  State,
} from "../../../../../primitives";
import styles from "./companyRow.module.scss";
import RowCheckBoxesListComponent from "../rowCheckboxesList";
import ViewCustomField from "../viewCustomField";
import { useModelPopup } from "../../../../../../common/hooks";
import { makeWebSite } from "../../../../../../common/helper";

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "15px",
  },
}));

const CompanyRowComponent = (props) => {
  const { item, selectedRows, elementsShouldBeFixed, isPatientOrg } = props;
  const {
    appReducer: { selectedItem },
    projectsReducer: {
      companyCompairReducer: { selectedNotes = [] },
    },
  } = useSelector((state) => state);

  const [totalWidth, setTotalWidth] = useState();
  const [height, setHeight] = useState();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTech, setAnchorElTech] = useState(null);
  const [anchorIndustry, setAnchorIndustry] = useState(null);
  const [customFieldAnchorId, setCustomFieldAnchorId] = useState(null);
  const popup = useModelPopup();

  const handlePopoverOpenTech = (event) => {
    setAnchorElTech(event.currentTarget);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCustomFieldPopoverOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCustomFieldAnchorId(id);
  };

  const handlePopoverCloseTech = () => {
    setAnchorElTech(null);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverOpenIndustry = (event) => {
    setAnchorIndustry(event.currentTarget);
  };

  const handlePopoverCloseIndustry = () => {
    setAnchorIndustry(null);
  };

  const handleCustomFieldView = (field) => {
    popup.show({
      title: field.field_name,
      component: <ViewCustomField field={field} />,
      width: "800",
      height: "600",
    });
  };

  const open = Boolean(anchorEl);
  const openTech = Boolean(anchorElTech);
  const openIndustry = Boolean(anchorIndustry);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const rowLine = document.getElementById("rowLine");
      if (rowLine && rowLine.offsetHeight) {
        setHeight(rowLine.offsetHeight - 1);
      }

      clearTimeout(timeout);
    }, 1000);

    const { children } = document.getElementById("compairHeader");
    let totalWidth = 0;

    for (let i = 0; i < children.length; i++) {
      totalWidth += children[i].offsetWidth;
    }

    setTotalWidth(totalWidth);

    return () => null;
  }, [selectedRows, isPatientOrg, item]);

  const checkRowExist = (rowName) =>
    selectedRows.find((r) => r.name === rowName);

  const getShortString = (str) => {
    const stripString = str.replace(/<[^>]*>?/gm, "");
    const shortString = stripString.substring(0, 100);
    if (stripString.length > 100) {
      return (
        <div
          // className={classNames(
          //   styles.colDev,
          //   styles.withRowStyles,
          //   styles.description
          // )}
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={(e) => handleCustomFieldPopoverOpen(e, item.id)}
          onMouseLeave={handlePopoverClose}
        >
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div dangerouslySetInnerHTML={{ __html: str }} />
          </Popover>
          <div
            className={styles.noteContent}
            dangerouslySetInnerHTML={{ __html: shortString }}
          />
          <span className={styles.showMore}>show more...</span>
        </div>
      );
    }
    return (
      <div
        className={styles.noteContent}
        dangerouslySetInnerHTML={{ __html: shortString }}
      />
    );
  };
  const renderCustomField = (field) => {
    if (!field) {
      return "-";
    }

    const {
      id,
      field_type,
      field_value,
      field_values,
      option_selected,
      option_values,
    } = field;

    if (field_type === "text" && field_value && field_value.length > 5) {
      const stripString = field_value.replace(/<[^>]*>?/gm, "");
      const shortString = stripString.substring(0, 100);
      return (
        <div style={{ padding: "0 5px" }}>
          {stripString.length <= 100 && (
            <div dangerouslySetInnerHTML={{ __html: field_value }} />
          )}
          {stripString.length > 100 && (
            <div
              className={classNames()}
              aria-owns={
                open && customFieldAnchorId === id
                  ? "mouse-over-popover"
                  : undefined
              }
              aria-haspopup="true"
              onMouseEnter={(e) => handleCustomFieldPopoverOpen(e, id)}
              onMouseLeave={handlePopoverClose}
            >
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={open && customFieldAnchorId === id}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <div dangerouslySetInnerHTML={{ __html: field_value }} />
              </Popover>
              <span>{shortString}...</span>
            </div>
          )}
        </div>
      );
    }

    if (field_type === "link") {
      return (
        <a
          href={field_value}
          target="_blank"
          className={styles.link}
          rel="noreferrer"
        >
          {field_value}
        </a>
      );
    }

    if (
      (field_type === "slide" || field_type === "video") &&
      option_values.length
    ) {
      return (
        <div>
          <Button
            className={styles.newTask}
            btn={BtnType.FRAME_LESS}
            onClick={() => handleCustomFieldView(field)}
          >
            View
          </Button>
        </div>
      );
    }

    if (field_type === "image" && field_value) {
      return (
        <div>
          <Button
            className={styles.newTask}
            btn={BtnType.FRAME_LESS}
            onClick={() => handleCustomFieldView(field)}
          >
            View
          </Button>
        </div>
      );
    }

    if (field_type === "document") {
      if (field_values && field_values.length) {
        return field_values.map((item, i) => (
          <div key={`${field.id}-${i}-${field_type}`}>
            <a href={item} target="_blank" rel="noreferrer">
              {item.split("/").pop()}
            </a>
          </div>
        ));
      }
    }

    if (option_selected.length) {
      return option_selected.join(", ");
    }

    return "-";
  };

  return (
    <div
      id="rowLine"
      style={{ width: totalWidth ? `${totalWidth}px` : "100%" }}
      key={`rowLine-${
        isPatientOrg ? item.gsk_patient_organization.id : item.company?.id
      }`}
      className={classNames(
        styles.taskRow,
        selectedItem ===
          `company${
            isPatientOrg ? item.gsk_patient_organization.id : item.company?.id
          }` && styles.selected
      )}
    >
      <div
        className={classNames(styles.colCompany, styles.colCompanyName)}
        style={{ height: height ? `${height}px` : "auto" }}
      >
        <div className={styles.companyIcon}>
          <div className={styles.iconCon}>
            <CompanyAvatar
              imgSrc={
                isPatientOrg
                  ? item.gsk_patient_organization.logo
                  : item.company.logo
              }
              name={
                isPatientOrg
                  ? item.gsk_patient_organization.name
                  : item.company.name
              }
            />
          </div>
        </div>
        <div className={styles.companyNameHover}>
          {isPatientOrg ? (
            <Link
              to={`/patient_organizations/${item.gsk_patient_organization.id}`}
            >
              {item.gsk_patient_organization.name}
            </Link>
          ) : (
            <Link to={`/companies/${item.company.slug || item.company.id}`}>
              {item.company.name}
            </Link>
          )}
        </div>
      </div>
      {checkRowExist("Project Match") ? (
        <div
          className={classNames(
            styles.taskcol,
            styles.colfunding,
            styles.withRowStyles
          )}
        >
          {item.normalized_gsk_score
            ? item.normalized_gsk_score.toFixed(1)
            : "-"}
        </div>
      ) : null}
      {checkRowExist("General Characteristic") ? (
        <div
          className={classNames(
            styles.taskcol,
            styles.bigCol,
            styles.withRowStyles
          )}
        >
          {item.general_characteristics ? (
            <RowCheckBoxesListComponent
              data={item.general_characteristics[0]}
            />
          ) : (
            "-"
          )}
        </div>
      ) : null}
      {checkRowExist("Supports Clinical Research") ? (
        <div
          className={classNames(
            styles.taskcol,
            styles.bigCol,
            styles.withRowStyles
          )}
        >
          {item.supports_clinical_research ? (
            <RowCheckBoxesListComponent
              data={item.supports_clinical_research[0]}
            />
          ) : (
            "-"
          )}
        </div>
      ) : null}
      {checkRowExist("Involved in Health Policy and Equity") ? (
        <div
          className={classNames(
            styles.taskcol,
            styles.bigCol,
            styles.withRowStyles
          )}
        >
          {item.involved_in_health_policy_and_equity ? (
            <RowCheckBoxesListComponent
              data={item.involved_in_health_policy_and_equity[0]}
            />
          ) : (
            "-"
          )}
        </div>
      ) : null}
      {checkRowExist("Support for Patients") ? (
        <div
          className={classNames(
            styles.taskcol,
            styles.bigCol,
            styles.withRowStyles
          )}
        >
          {item.support_for_patients ? (
            <RowCheckBoxesListComponent data={item.support_for_patients[0]} />
          ) : (
            "-"
          )}
        </div>
      ) : null}
      {checkRowExist("Patient Education & Awareness") ? (
        <div
          className={classNames(
            styles.taskcol,
            styles.bigCol,
            styles.withRowStyles
          )}
        >
          {item.patient_education_and_awareness ? (
            <RowCheckBoxesListComponent
              data={item.patient_education_and_awareness[0]}
            />
          ) : (
            "-"
          )}
        </div>
      ) : null}
      {checkRowExist("Cultural Representation") ? (
        <div
          className={classNames(
            styles.taskcol,
            styles.bigCol,
            styles.withRowStyles
          )}
        >
          {item.cultural_representation ? (
            <RowCheckBoxesListComponent
              data={item.cultural_representation[0]}
            />
          ) : (
            "-"
          )}
        </div>
      ) : null}
      {checkRowExist("Total funding") ? (
        <div
          className={classNames(
            styles.taskcol,
            styles.colfunding,
            styles.withRowStyles
          )}
        >
          {item.company?.total_funding || "-"}
        </div>
      ) : null}
      {checkRowExist("Technology") ? (
        <div className={classNames(styles.colTechnology, styles.withRowStyles)}>
          {item.company?.topics && item.company?.topics.length ? (
            <div>
              {item.company.topics.slice(0, 2).map((t, i, { length }) => (
                <div
                  className={classNames(
                    styles.colTechnologyItem,
                    i + 1 !== length ? "mb-2" : ""
                  )}
                  key={t.id}
                >
                  {t.name}
                </div>
              ))}
              {item.company?.topics.length >= 3 ? (
                <div
                  className={classNames(
                    styles.colDev,
                    styles.withRowStyles,
                    styles.description
                  )}
                  aria-owns={openTech ? "mouse-over-tech" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpenTech}
                  onMouseLeave={handlePopoverCloseTech}
                >
                  <Popover
                    id="mouse-over-tech"
                    className={classes.popover}
                    classes={{
                      paper: classes.paper,
                    }}
                    open={openTech}
                    anchorEl={anchorElTech}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    onClose={handlePopoverCloseTech}
                    disableRestoreFocus
                  >
                    {item.company.topics.map((t, i, { length }) => (
                      <div
                        className={classNames(
                          styles.colTechnologyItem,
                          i + 1 !== length ? "mb-2" : ""
                        )}
                        key={`${t.id}_tag_${i}`}
                      >
                        {t.name}
                      </div>
                    ))}
                  </Popover>
                  <span className={styles.showMore}>show more...</span>
                </div>
              ) : null}
            </div>
          ) : (
            "-"
          )}
        </div>
      ) : null}
      {checkRowExist("Industry") ? (
        <div className={classNames(styles.colIndustry, styles.withRowStyles)}>
          {item.company?.industries && item.company?.industries.length ? (
            <div>
              {item.company.industries.slice(0, 2).map((t, i, { length }) => (
                <div
                  className={classNames(
                    styles.colIndustryItem,
                    i + 1 !== length ? "mb-2" : ""
                  )}
                  key={t.id}
                >
                  {t.name}
                </div>
              ))}
              {item.company?.industries.length > 2 ? (
                <div
                  className={classNames(
                    styles.colDev,
                    styles.withRowStyles,
                    styles.description
                  )}
                  aria-owns={openIndustry ? "mouse-over-industry" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpenIndustry}
                  onMouseLeave={handlePopoverCloseIndustry}
                >
                  <Popover
                    id="mouse-over-industry"
                    className={classes.popover}
                    classes={{
                      paper: classes.paper,
                    }}
                    open={openIndustry}
                    anchorEl={anchorIndustry}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    onClose={handlePopoverCloseIndustry}
                    disableRestoreFocus
                  >
                    {item.company.industries.map((t, i, { length }) => (
                      <div
                        className={classNames(
                          styles.colIndustryItem,
                          i + 1 !== length ? "mb-2" : ""
                        )}
                        key={t.id}
                      >
                        {t.name}
                      </div>
                    ))}
                  </Popover>
                  <span className={styles.showMore}>show more...</span>
                </div>
              ) : null}
            </div>
          ) : (
            "-"
          )}
        </div>
      ) : null}
      {checkRowExist("Project Stage") ? (
        <div
          className={classNames(
            styles.taskcol,
            styles.colStatus,
            styles.withRowStyles
          )}
        >
          {item.stage && <State stageId={item.stage.id} />}
        </div>
      ) : null}
      {checkRowExist("Project Score") ? (
        <div
          className={classNames(
            styles.taskcol,
            styles.colScore,
            styles.withRowStyles
          )}
        >
          {item.score || "-"}
        </div>
      ) : null}
      {checkRowExist("Project Status") ? (
        <div className={classNames(styles.taskcol, styles.colStatus)}>
          <CompanyState state={item.state} />
        </div>
      ) : null}
      {checkRowExist("Task Progress") ? (
        <div className={classNames(styles.taskcol, styles.colCompletion)}>
          <ProgressBar
            width="120px"
            tot={100}
            fill={item.total_tasks_progress}
            showTot={false}
            showPrecentage
          />
        </div>
      ) : null}
      {checkRowExist("HQ Location") ? (
        <div className={classNames(styles.colLocation, styles.withRowStyles)}>
          {item.company?.location || "-"}
        </div>
      ) : null}
      {checkRowExist("Description") ? (
        <div
          className={classNames(
            styles.colDev,
            styles.withRowStyles,
            styles.description
          )}
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={(e) => handleCustomFieldPopoverOpen(e, item.id)}
          onMouseLeave={handlePopoverClose}
        >
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            {item.companyDescription}
          </Popover>
          {item.company?.description
            ? `${item.company.description.substring(0, 40)}...`
            : "-"}
        </div>
      ) : null}
      {checkRowExist("Website") ? (
        <div className={classNames(styles.colDev, styles.withRowStyles)}>
          {item.company?.web_url ? (
            <a
              className={styles.link}
              href={item.company.web_url}
              target="_blank"
              rel="noreferrer"
            >
              {makeWebSite(item.company.web_url)}
            </a>
          ) : (
            "-"
          )}
        </div>
      ) : null}
      {checkRowExist("Founded") ? (
        <div className={classNames(styles.colDev, styles.withRowStyles)}>
          {item.company?.founded
            ? format(new Date(item.company.founded), "MM/dd/yyyy")
            : "-"}
        </div>
      ) : null}
      {checkRowExist("Employees") ? (
        <div className={classNames(styles.colDev, styles.withRowStyles)}>
          {item.company?.company_size || "-"}
        </div>
      ) : null}
      {checkRowExist("Competitors") ? (
        <div className={classNames(styles.colDev, styles.withRowStyles)}>
          {item.company?.competitors && item.company?.competitors.length
            ? item.company.competitors.map((c, i) => (
                <div key={`${c.id}-${i}`}>{c.name}</div>
              ))
            : "-"}
        </div>
      ) : null}
      {checkRowExist("Estimated Revenue") ? (
        <div className={classNames(styles.colDev, styles.withRowStyles)}>
          {item.company?.estimated_revenue &&
          item.company?.estimated_revenue.length
            ? item.company.estimated_revenue
            : "-"}
        </div>
      ) : null}

      {checkRowExist("Notes ") ? (
        <div className={styles.parentNotes}>
          <div className={classNames(styles.colDev, styles.withRowStyles)}>
            {item.company.custom_activities?.length
              ? item.company.custom_activities.map((c, i) => {
                  if (!selectedNotes.includes(c.type.toLowerCase() + "s")) {
                    return null;
                  }
                  return (
                    <>
                      <b>{c.type}</b>

                      {getShortString(
                        c.entryable.note ||
                          c.entryable.emailed_about ||
                          c.entryable.description ||
                          c.entryable.spoke_about
                      )}
                    </>
                  );
                })
              : "-"}
          </div>
          <div className={classNames(styles.withSpaceBetweenStyles)}>
            {item.company.custom_activities?.length
              ? item.company.custom_activities.map((c, i) => {
                  if (!selectedNotes.includes(c.type.toLowerCase() + "s")) {
                    return null;
                  }
                  return (
                    <div>{format(new Date(c.created_at), "MM/dd/yyyy")}</div>
                  );
                })
              : "-"}
          </div>
          <div className={classNames(styles.withSpaceBetweenStyles)}>
            {item.company.custom_activities?.length
              ? item.company.custom_activities.map((c, i) => {
                  if (!selectedNotes.includes(c.type.toLowerCase() + "s")) {
                    return null;
                  }
                  return <div>{c.created_by}</div>;
                })
              : "-"}
          </div>
        </div>
      ) : null}

      {checkRowExist("Company Contacts") ? (
        <div className={classNames(styles.colDev, styles.withRowStyles)}>
          {item.company?.company_contacts &&
          item.company?.company_contacts.length
            ? item.company.company_contacts.map((c, i) => (
                <div key={`${c.id}-${i}`}>{c.email}</div>
              ))
            : "-"}
        </div>
      ) : null}
      {checkRowExist("Relationship Owner") ? (
        <div className={classNames(styles.colDev, styles.withRowStyles)}>
          {item.company?.company_relationship_owners &&
          item.company?.company_relationship_owners.length
            ? item.company?.company_relationship_owners.map(({ user }, i) => (
                <div key={`${user?.id}-${i}`}>
                  {user?.first_name} {user?.last_name}
                </div>
              ))
            : "-"}
        </div>
      ) : null}

      {!isPatientOrg &&
        Array.isArray(item?.company?.company_custom_fields) &&
        selectedRows
          .filter(({ isCustom }) => isCustom)
          .map((field, index) => (
            <div
              key={`${field.name.replace(" ", "_")}-${index}`}
              className={classNames(styles.colCustom, styles.withRowStyles, {
                [styles.bigCol]: field.type === "text",
              })}
            >
              {renderCustomField(
                item?.company?.company_custom_fields.find(
                  ({ field_name }) => field.name === field_name
                )
              )}
            </div>
          ))}
    </div>
  );
};

export default React.memo(CompanyRowComponent);

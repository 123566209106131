import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BtnType,
  Button,
  Loading,
  MoreOptions,
  OptionType,
} from "modules/primitives/index";
import ReactTable from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { CSVLink } from "react-csv";
import styles from "../adminCustomfields.module.scss";
import ConfirmDeleteComponent from "../../../../common/components/confirmDelete";
import { httpGet } from "../../../../common/httpCall";
import { useModelPopup } from "../../../../common/hooks";
import { getAllTeamsAttempt } from "modules/teams/teams.action";
import { companyGetTopics } from "modules/companies/companyMain/companyMain.action";
import {
  getCustomFieldGroupTemplatesAttempt,
  getCustomFieldsGroupsAttempt,
} from "../adminCustomField.action";
import AdminCustomFieldModal from "../adminCustomFieldModal/adminCustomFieldModal.component";

const AdminCustomFieldsGroupTemplates = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [exportLoading, setExportLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [tableKey, setTableKey] = useState(Math.random());
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    adminCustomFieldsReducer: {
      groupTemplates,
      loading,
      shouldUpdate,
      groupTemplatesLaoding,
      customFieldGroupsPageTemplatesNr,
    },
  } = useSelector((state) => state);

  const pageSizeOptions = [pageSize, 5, 10, 20, 50, 100];

  useEffect(() => {
    if (params?.groupId) {
      dispatch(
        getCustomFieldGroupTemplatesAttempt({
          enqueueSnackbar,
          groupId: params.groupId,
        })
      );
      dispatch(
        getCustomFieldsGroupsAttempt({
          enqueueSnackbar,
          query: "",
          page: 1,
          pageSize: 20,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (shouldUpdate) {
      dispatch(
        getCustomFieldGroupTemplatesAttempt({
          enqueueSnackbar,
          groupId: params?.groupId,
        })
      );
      dispatch(
        getCustomFieldsGroupsAttempt({
          enqueueSnackbar,
          query: "",
          page: 1,
          pageSize: 20,
        })
      );
    }
  }, [shouldUpdate]);

  const handleCreateFormClick = (e) => {
    popup.show({
      title: "Add item",
      show: true,
      height: "500",
      width: "540",
      component: (
        <AdminCustomFieldModal
          isTableCustomGroup={groupTemplates.view_type === "table"}
          mode="add"
          preselectedGroup={groupTemplates}
        />
      ),
    });
  };

  const moreOptions = [
    { val: "edit", text: "Edit" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const handleMoreOptionClick = (event, data) => {
    if (event === "edit") {
      popup.show({
        title: "Edit item",
        show: true,
        height: "300",
        width: "540",
        component: <AdminCustomFieldModal mode="edit" data={data} />,
      });
    }

    if (event === "delete") {
      popup.show({
        title: "Confirmation Delete",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={data?.custom_field_template_id}
            enqueueSnackbar={enqueueSnackbar}
            councilId={selectedCouncil?.id}
            actionType="deleteAdminCustomField"
          />
        ),
      });
    }
  };

  const handleExportList = () => {
    setExportLoading(true);
    httpGet({
      call: `custom_field_groups/${params?.groupId}`,
      data: {
        query: "*",
      },
    }).subscribe(
      async (res) => {
        setExportLoading(false);
        setCsvData(() => {
          const csvDataCont = [
            [
              "Custom Field",
              "Type",
              "Position",
              "Multi-Select",
              "Group",
              "Access",
              "Existing Records",
            ],
          ];

          res.response.custom_field_templates.map((item) => {
            csvDataCont.push([
              item?.field_name,
              item?.field_type,
              item?.position,
              item?.multiselect ? "YES" : "NO",
              item?.custom_field_group?.name,
              item?.access || "-",
              item?.option_values?.length,
            ]);
          });

          return csvDataCont;
        });
        csvLinkRef.current.link.click();
      },
      (err) => {
        setExportLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      },

      () => {
        setExportLoading(false);
        enqueueSnackbar("List succesfully exported.", {
          variant: "success",
        });
      }
    );
  };

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
  };

  const handlePageSelect = (e) => {
    setPage(e.target.value);
  };

  const handleNextClick = () => {
    setPage(page + 1);
  };

  const handlePrevClick = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const isNextDisabled = () => {
    if (page + 1 > customFieldGroupsPageTemplatesNr?.pages) {
      return styles.disabledButton;
    }
    return styles.paginationButton;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Custom Field",
        accessor: "field_name",
        sortable: true,
      },
      {
        Header: "Type",
        id: "field_type",
        sortable: false,
        accessor: (row) => <span>{row?.field_type} (table)</span>,
      },
      {
        Header: "Position",
        accessor: "position",
        sortable: true,
        sortMethod: (a, b) => {
          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        },
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Access",
        id: "access",
        accessor: (row) => (
          <span>
            {row?.teams?.length || (row?.public && row?.teams?.length)
              ? "Team"
              : Boolean(row?.public) && "All"}
            {Boolean(row?.users?.length) && "Individual"}
            {Boolean(row?.topics?.length) && "Tag"}
          </span>
        ),
        sortable: false,
      },
      {
        Header: "Options",
        id: "options",
        width: 100,
        sortable: false,
        accessor: (row) => (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row);
            }}
          />
        ),
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [groupTemplates?.custom_field_templates]
  );

  useEffect(() => {
    dispatch(getAllTeamsAttempt({ enqueueSnackbar }));
    if (session?.id) {
      dispatch(
        companyGetTopics({
          userId: session?.id,
          councilId: JSON.parse(localStorage.getItem("council")).id,
          enqueueSnackbar,
        })
      );
    }
  }, [session]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <a href="/admin" className={styles.rootText}>
            ADMIN
          </a>
          <span className={styles.separationBar}> / </span>
          <a href="/admin/custom-fields" className={styles.rootText}>
            CUSTOM FIELDS
          </a>
          <span className={styles.separationBar}> / </span>
          <span className={`${styles.rootText} ${styles.activeBreadcrumbs}`}>
            CUSTOM FIELD GROUPS
          </span>
        </h4>
      </div>
      <div className={styles.flexWrapper}>
        <div className={styles.headerBlock}>
          <div className={styles.createFormButton}>
            <Button
              btn={BtnType.REGULAR}
              icon="icn-add"
              onClick={() => handleCreateFormClick()}
            >
              Default Field
            </Button>
          </div>
          <div className={styles.searchBar}>
            {/* <Search mode="applicants/submissions"/> */}
            {/* <TextBox
                placeholder={'Search'}
                className={styles.searchField}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e?.target?.value)
                  searchWithDelay(e?.target?.value)
                }}
              /> */}
            {/* <Button
                btn={BtnType.REGULAR}
                className={styles.searchButton}
                onClick={() => searchWithDelay(searchValue, true)}
              >
               Search
              </Button> */}
          </div>
        </div>
        <div className={styles.infoRow}>
          <div className={styles.infoBlock}>
            <span>
              Default Custom Fields will appear in all Company profiles under
              the Details tab.
            </span>
          </div>
        </div>

        <div className={styles.applicantsWrp}>
          {/* <div className={styles.searchBar}>
            </div> */}
          <div className={styles.buttonWrp}>
            {exportLoading && <Loading />}

            {!exportLoading && (
              <div>
                <Button
                  className={
                    groupTemplates?.custom_field_templates?.length <= 0 &&
                    styles.disabledBtn
                  }
                  onClick={() =>
                    groupTemplates?.custom_field_templates?.length > 0 &&
                    handleExportList()
                  }
                  btn={
                    groupTemplates?.custom_field_templates?.length
                      ? BtnType.OUTLINE
                      : BtnType.DISABLED
                  }
                  disabled={groupTemplates?.custom_field_templates?.length <= 0}
                >
                  EXPORT
                </Button>
                <CSVLink
                  data={csvData}
                  filename="customFields.csv"
                  style={{ display: "none" }}
                  ref={csvLinkRef}
                />
              </div>
            )}
            <div className={styles.totalCount}>
              {groupTemplates?.custom_field_templates?.length || "0"} Custom
              fields
            </div>
          </div>

          <ReactTable
            key={tableKey}
            data={groupTemplates?.custom_field_templates || []}
            columns={columns}
            loading={loading || groupTemplatesLaoding}
            className={styles.table}
            showPagination={false}
            pageSize={pageSize}
          />
          <div className={styles.pagination}>
            <div
              className={
                page - 1 === 0 ? styles.disabledButton : styles.paginationButton
              }
              onClick={handlePrevClick}
            >
              Previous
            </div>
            <div className={styles.paginationSettings}>
              <span style={styles.pageNr}>
                <span>
                  <input
                    onChange={(e) => handlePageSelect(e)}
                    value={page}
                    className={styles.inputBox}
                    type="number"
                    min="1"
                  />
                </span>
                <span>of</span>
                <span> {customFieldGroupsPageTemplatesNr?.pages}</span>
              </span>

              <span>
                <select onChange={handleChangePageSize}>
                  {pageSizeOptions?.map((option, i) => (
                    <option
                      value={option}
                      key={`applicant-page-option-${option}-${i}`}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </span>
            </div>
            <button
              className={isNextDisabled()}
              onClick={handleNextClick}
              disabled={page + 1 > customFieldGroupsPageTemplatesNr?.pages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCustomFieldsGroupTemplates;

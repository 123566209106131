import React, { useState } from "react";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { numberWithCommas } from "../../../common/helper";
import styles from "./leaderboard.module.scss";

import { Pannel, Loading, Button, BtnType } from "../../primitives";
import { topProjectsColumns } from "./leaderboard.constants";

const TopProjectsTableComponent = ({
  getSortDirectionStytles,
  getSortStyles,
  prepareDataName,
  prepareLongRow,
  topProjects,
  trendProjLoading,
  setTopProjects,
}) => {
  const [topProjectsSort, setTopProjectsSort] = useState({
    sort_attribute: "project_overall_score",
    sort_order: "desc",
  });

  const setSortAttr = (sortAttr) => {
    const sortingFunction = (techologies) => {
      let newArr = [...techologies];
      if (sortAttr === "project_topics") {
        newArr = newArr.map((proj) => {
          proj.project_topics.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            } else if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          return proj;
        });
      }

      const sorted = newArr.sort((a, b) => {
        if (a[sortAttr] === null) {
          return -1;
        }
        if (a[sortAttr] < b[sortAttr]) {
          return -1;
        } else if (a[sortAttr] > b[sortAttr]) {
          return 1;
        }
        return 0;
      });
      return topProjectsSort.sort_order === "asc" ? sorted : sorted.reverse();
    };

    setTopProjectsSort((prev) => {
      if (prev.sort_order === "desc") {
        return {
          sort_attribute: sortAttr,
          sort_order: "asc",
        };
      } else {
        return {
          sort_attribute: sortAttr,
          sort_order: "desc",
        };
      }
    });
    setTopProjects(sortingFunction);
  };

  return (
    <div className={`col-md-12 col-lg-8`}>
      <Pannel
        title=""
        padding="0"
        parentStyle={{
          background: "Transparent",
          // marginRight: window.screen.width > 550 ? 0 : 0,
          padding: window.screen.width > 550 ? 7 : 0,
        }}
        position="relative"
        overflow="hidden"
      >
        <div className={styles.wrapper}>
          <div className={`row ${styles.grid}`}>
            <div
              className={`col-md-12 ${styles.dataTableContainer} ${styles.trendingByScore}`}
            >
              <div className={styles.gridHeader}>
                <div className={styles.headerTitle}>
                  Top 25 Trending Projects by <b>Score</b>
                </div>
              </div>
              <div className={styles.dataTable}>
                <div
                  className={` ${styles.tableHeaderRow} ${styles.trendProjHeaderRow}`}
                >
                  {topProjectsColumns.map((header, i) => {
                    if (
                      i === 6 &&
                      window.innerWidth > 992 &&
                      window.innerWidth < 1350
                    ) {
                      return null;
                    }
                    return (
                      <div
                        key={header.columnName}
                        className={` ${
                          styles.dataTableHeader
                        } ${header.columnStyles.map(
                          (customClass) => customClass
                        )} ${getSortStyles(
                          header.columnSortKey,
                          "topProjects",
                          topProjectsSort.sort_attribute
                        )} ${header.sortable && styles.cursorpointer}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (header.sortable) {
                            setSortAttr(header.columnSortKey);
                          }
                        }}
                      >
                        {" "}
                        <span
                          className={`${getSortDirectionStytles(
                            header.columnSortKey,
                            "topProjects",
                            topProjectsSort.sort_attribute,
                            topProjectsSort.sort_order
                          )} `}
                        >
                          {header.columnName}
                        </span>{" "}
                        {header.columnName === "Score" ? (
                          <Tooltip
                            title={
                              <span>
                                Score is calculated by converting Savings and
                                Replications into a 1 to 5 Score, and then
                                Averaging those two scores with the Rating
                                Score.
                                <br />
                                Savings Score is calculated with the following
                                ranges:
                                <br />
                                <ul>
                                  <li>&#x2022; $0 to $50K = 1</li>
                                  <li>&#x2022; $50K to $200K = 2</li>
                                  <li>&#x2022; $200K to $500K = 3</li>
                                  <li>&#x2022; $500K to $1M = 4</li>
                                  <li>&#x2022; $1M = 5</li>
                                </ul>
                                Replications Score is calculated with the
                                following ranges:
                                <br />
                                <ul>
                                  <li>&#x2022; 0 to 2 = 1</li>
                                  <li>&#x2022; 3 to 5 = 2</li>
                                  <li>&#x2022; 6 to 10 = 3</li>
                                  <li>&#x2022; 10 to 15 = 4</li>
                                  <li>&#x2022; 15+ = 5</li>
                                </ul>
                              </span>
                            }
                            placement="bottom"
                          >
                            <span className={styles.introQuestionMark}>
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="6"
                                  cy="6"
                                  r="5.5"
                                  stroke="#9AA5B1"
                                />
                                <path
                                  d="M6.149 3c-.972 0-1.602.383-2.096 1.067a.275.275 0 0 0 .063.385l.525.383a.3.3 0 0 0 .405-.048c.305-.368.531-.58 1.008-.58.374 0 .838.232.838.582 0 .264-.227.4-.597.6-.431.232-1.002.522-1.002 1.246v.115c0 .155.13.281.292.281h.883a.287.287 0 0 0 .292-.281v-.068c0-.502 1.526-.523 1.526-1.882 0-1.024-1.104-1.8-2.137-1.8zm-.122 4.376c-.466 0-.844.365-.844.812 0 .448.378.812.844.812.465 0 .843-.364.843-.812 0-.447-.378-.812-.843-.812z"
                                  fill="#9AA5B1"
                                />
                              </svg>
                            </span>
                          </Tooltip>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
                {(trendProjLoading || topProjects.length === 0) && (
                  <div className={`${styles.loadingRow}`}>
                    <Loading />
                  </div>
                )}
                <div className={`${styles.projectTableBodyContainer}`}>
                  {topProjects?.map((project, i) => {
                    return (
                      <div
                        className={`${styles.projectRow} ${styles.trendProjRow}`}
                        key={project.project_name + project.project_id}
                      >
                        <div className={styles.rowNumber}>
                          {" "}
                          <span>{i + 1}</span>{" "}
                        </div>

                        <div
                          className={` ${styles.cellItem} ${styles.projName}`}
                        >
                          <Link
                            className={styles.url}
                            to={`/projects/${project.project_id}/overview`}
                          >
                            {prepareDataName(project.project_name || "")}
                          </Link>
                        </div>

                        <div
                          className={` ${styles.cellItem} ${styles.plantNameContainer}`}
                        >
                          {prepareDataName(project.plant_name || "")}
                        </div>

                        <div className={` ${styles.cellItem} `}>
                          ${" "}
                          {numberWithCommas(project.project_savings_usd) || "0"}
                        </div>
                        <div
                          className={` ${styles.cellItem} ${styles.projRatingColumn}`}
                        >
                          {project.project_rating || ""}
                        </div>
                        <div
                          className={` ${styles.cellItem} ${styles.replicationsContColumn}`}
                        >
                          {project.project_replications_count}
                        </div>
                        {(window.innerWidth < 992 ||
                          window.innerWidth > 1350) && (
                          <div
                            className={`${styles.cellItem} ${styles.technologiesContainer}`}
                          >
                            {project.project_topics?.map((tag, i) => {
                              if (i < 5) {
                                return (
                                  <Button
                                    key={tag.id}
                                    btn={BtnType.TAG_BLUE_LIGHT}
                                    rounded
                                    className={styles.techItemContainer}
                                  >
                                    {prepareDataName(tag.name)}
                                  </Button>
                                );
                              }
                              if (i === 5) {
                                return prepareLongRow(project.project_topics);
                              }
                              return null;
                            })}
                          </div>
                        )}

                        <div
                          className={`${styles.overallScore} ${styles.cellItem} ${styles.lastCol}`}
                        >
                          {project.project_overall_score}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Pannel>
    </div>
  );
};

export default React.memo(TopProjectsTableComponent);

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import styles from "./leftWindow.module.scss";
import { Button, BtnType } from "../../../primitives";
import FilterRowComponent from "./filterRow/filterRow.component";
import {
  companyGetFilters,
  companyGetTopics,
  setScrolledTop,
} from "../companyMain.action";
import AdvancedFilters from "./advancedFilters";
import {
  ADVANCED_PARAMS,
  FREDDIE_MAC_BU_OPTIONS,
} from "../companyMain.constant";
import { useQuery } from "../../../../common/helper";

const LeftWindowComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const topicQuery = useQuery().get("topic");
  const industryQuery = useQuery().get("industry");
  const businessUnitQuery = useQuery().get("business_unit");

  const [expandedAll, setExpandedAll] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [focusedFilter, setFocusedFilter] = useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    appReducer: { chartsDownloadIsActive },
    councilReducer: { selectedCouncil },
    authReducer: { session },
    companiesReducer: {
      companyMainReducer: {
        industries,
        topics,
        isScrolled,
        charts: { chartsIntroIsActive },
      },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!location.state) {
      setShowReset(false);

      return;
    }

    const { state } = history.location;
    const hasActiveFilters = ADVANCED_PARAMS.some(
      (key) => state[key] && state[key] !== "0" && String(state[key]).length
    );

    setShowReset(hasActiveFilters);

    return () => null;
  }, [location]);

  const onScrollEventFunc = (event) => {
    const scrolled = event.target.scrollTop;
    if (scrolled > 0 && !isScrolled) {
      dispatch(setScrolledTop(true));
    } else if (scrolled === 0 && isScrolled) {
      dispatch(setScrolledTop(false));
    }
  };

  useEffect(() => {
    if (!location.state || chartsIntroIsActive) {
      setExpandedAll(false);

      return;
    }

    const { state } = location;

    if (state && Object.keys(state).length === 1) {
      setExpandedAll(false);
      return;
    }

    if (
      (state.scoreFrom?.length && state.scoreFrom !== "0") ||
      state.scoreTo?.length ||
      (state.fundingFrom && state.fundingFrom !== "0") ||
      state.fundingTo ||
      state.foundedFrom ||
      state.foundedTo ||
      state.employees ||
      state.estimatedRevenue ||
      state.agreements ||
      state.owners?.length ||
      state.relationShipStatus?.length ||
      state.topic?.length ||
      state.industry?.length ||
      state.business_unit?.length ||
      state.location?.length ||
      state.idbEcosystem?.length ||
      state.k_country?.length ||
      state.businessUnits?.length ||
      state.investmentStages?.length ||
      state.lists?.length
    ) {
      goToAdvanced(true);
    }

    return () => null;
  }, [location, chartsIntroIsActive]);

  useEffect(() => {
    if (session && selectedCouncil) {
      dispatch(
        companyGetFilters({
          userId: session.id,
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
      dispatch(
        companyGetTopics({
          userId: session.id,
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [session, selectedCouncil]);

  const goToAdvanced = (fromUseEffect) => {
    if (fromUseEffect) {
      setExpandedAll(true);

      if (focusedFilter) {
        const timeout = setTimeout(() => {
          const elem = document.getElementById(focusedFilter);
          if (elem) {
            elem.scrollIntoView({
              block:
                focusedFilter === "business_unit" ||
                focusedFilter === "industry" ||
                focusedFilter === "topic"
                  ? "start"
                  : "center",
              behavior: "smooth",
            });
          }

          clearTimeout(timeout);
        });
      }

      return;
    }

    setExpandedAll(!expandedAll);
  };

  const handleAdvancedFiltersReset = () => {
    const { state } = history.location;

    ADVANCED_PARAMS.forEach((key) => {
      if (state[key]) {
        delete state[key];
      }
    });

    const search =
      state.layout === 2
        ? "?layout=2"
        : Object.keys(state).reduce(
            (acc, key) =>
              `${!acc.length ? "?" : ""}${acc}&${key}=${state[key]}`,
            ""
          );

    history.push({
      pathname: "/companies",
      search,
      state: state || null,
    });

    setExpandedAll(false);
  };

  return (
    <div
      className={`${styles.wrapper} ${
        chartsDownloadIsActive ? styles.noHeight : styles.withHeight
      }`}
      onScroll={onScrollEventFunc}
    >
      <div className={styles.container}>
        <div className={styles.filterWrapper}>
          <div className="left-filters">
            <span
              className={styles.advancedSearchLink}
              onClick={() => goToAdvanced(false)}
            >
              {expandedAll ? "Hide Advanced Search" : "Advanced Search"}
            </span>
            <FilterRowComponent
              items={topics.sort((a, b) => a.name.localeCompare(b.name))}
              item={{
                id: "2",
                name: "Technology ",
              }}
              type="topic"
              level={0}
              expan={chartsIntroIsActive ? false : expandedAll}
              handleExpand={setFocusedFilter}
              topicQuery={topicQuery}
              history={history}
            />
            <FilterRowComponent
              items={industries.sort((a, b) => a.name.localeCompare(b.name))}
              item={{
                id: "1",
                name:
                  selectedCouncil?.name === "Freddie Mac"
                    ? "HOUSING CYCLE"
                    : "INDUSTRY",
              }}
              type="industry"
              level={0}
              expan={chartsIntroIsActive ? false : expandedAll}
              handleExpand={setFocusedFilter}
              industryQuery={industryQuery}
              history={history}
            />
            {selectedCouncil?.name === "Freddie Mac" ? (
              <FilterRowComponent
                items={FREDDIE_MAC_BU_OPTIONS}
                item={{ id: "3", name: "BUSINESS UNIT" }}
                type="business_unit"
                level={0}
                expan={chartsIntroIsActive ? false : expandedAll}
                handleExpand={setFocusedFilter}
                businessUnitQuery={businessUnitQuery}
                history={history}
              />
            ) : null}
            <AdvancedFilters
              expandAll={chartsIntroIsActive ? false : expandedAll}
              handleExpand={setFocusedFilter}
              councilId={selectedCouncil?.id}
              councilName={selectedCouncil?.name}
              venturing={session?.theme_investment_access}
              availableFilters={session?.features?.filters}
              customFilters={selectedCouncil?.council_custom_tag_groups || []}
              handleExpandAll={setExpandedAll}
            />
            {showReset && (
              <div className="d-flex justify-content-end mt-2">
                <Button
                  className={styles.searchButton}
                  onClick={handleAdvancedFiltersReset}
                  btn={BtnType.FRAME_LESS}
                >
                  Clear All
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LeftWindowComponent);

import React from "react";
import Tooltip from "@mui/material/Tooltip";

import styles from "./updateCrunchBaseProfile.module.scss";

const UpdateCrunchBaseProfile = ({ profile, session }) => (
  <>
    {session.sign_in_count >= 3 && profile.cb_profile_link ? (
      <div className={styles.hasUrl}>
        <Tooltip
          title="Traction pulls information from Crunchbase to set up your company profile.
            You can update your Crunchbase profile, and it will automatically sync with Traction within 24 hours,
            or you can edit your details below."
          placement="top"
        >
          <a href={profile?.cb_profile_link} target="_blank" rel="noreferrer">
            UPDATE PROFILE ON CRUNCHBASE
          </a>
        </Tooltip>
      </div>
    ) : (
      <div className={styles.wrapper}>
        <span>
          Traction pulls information from Crunchbase to set up your company
          profile. You can update your Crunchbase profile, and it will
          automatically sync with Traction within 24 hours, or you can edit your
          details below.
        </span>
        {profile?.cb_profile_link ? (
          <a href={profile?.cb_profile_link} target="_blank" rel="noreferrer">
            UPDATE PROFILE ON CRUNCHBASE
          </a>
        ) : (
          <a
            href="https://www.crunchbase.com/register"
            target="_blank"
            rel="noreferrer"
          >
            UPDATE PROFILE ON CRUNCHBASE
          </a>
        )}
      </div>
    )}
  </>
);

export default React.memo(UpdateCrunchBaseProfile);

import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import styles from "./companyRatings.module.scss";
import SliderComponent from "./slider/slider.component";
import RatingAddNotesComponent from "./ratingAddNotes/ratingAddNotes.component";
import { ratingSaveAttempt, ratingEditAttempt } from "../companies.action";
import { getCompanyDetails } from "../companies.selectors";
import { Note } from "../../../../primitives";
import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../../../../../common/httpCall";
import { Icon, mdSize } from "../../../../../common/icon";

const CompanyRatingsComponent = ({ companyDetails }) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    projectsReducer: {
      companyReducer: { rating_project_company_id, user_total_rating },
    },
  } = useSelector((state) => state);

  const [noteList, setNoteList] = useState(null);
  const [selectedNote, setSelectedNote] = useState();
  const [currentCompanyId, setCurrentCompanyId] = useState();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (
      session
      && companyDetails
      && currentCompanyId !== companyDetails?.company?.id
    ) {
      setCurrentCompanyId(companyDetails?.company?.id);
      setSelectedNote(null);
      getNotesList();
    }

    return () => null;
  }, [noteList, session, companyDetails]);

  const getNotesList = () => {
    const url = `projects/${companyDetails.project.id}/companies/${companyDetails.company.id}/notes`;

    httpGet({ call: url })
      .pipe()
      .subscribe((res) => {
        const newRes = res.response.map((n) => ({
          ...n,
          description: n.description.replace(/(?:\r|\n|\r\n)/g, "<br>"),
        }));
        setNoteList(newRes || []);
      });
  };

  const handleOnChange = async (obj, category) => {
    const { id, value } = obj;

    const currentValue = companyDetails.user_rating_categories.find(
      (r) => r.rating_category_id === id,
    );
    const payload = {
      projectId: companyDetails.project.id,
      companyId: companyDetails.company.id,
      form: {
        company_id: companyDetails.company.id,
        council_id: selectedCouncil.id,
        user_id: session.id,
        rating_template_id: companyDetails.project.project_company_rating_template_id,
        rating_category_id: id,
        universal_rating_id:
          category.user_universal_rating?.universal_rating_id,
        value,
      },
      enqueueSnackbar,
    };

    if (!currentValue?.value && !currentValue?.rating_project_company_id) {
      await dispatch(ratingSaveAttempt(payload));
    } else {
      dispatch(
        ratingEditAttempt({
          ...payload,
          ratingProjectId:
            currentValue.rating_project_company_id || rating_project_company_id,
        }),
      );
    }
  };

  const handleMoreOptionClick = (value, note) => {
    switch (value) {
      case "remove":
        deleteNote(note.id);
        break;
      case "edit":
        setSelectedNote({
          id: note.id,
          note: note.description.replace(/(<br>)/g, "\n"),
        });
        break;
      default:
        break;
    }
  };

  const handleAddEdit = (value) => {
    // edit mode
    if (value.id) {
      editNote(value);
      return;
    }

    // add new
    addNewNote(value);
  };

  const deleteNote = (noteId) => {
    const url = `projects/${companyDetails.project.id}/companies/${companyDetails.company.id}/notes/${noteId}`;

    httpDelete({ call: url })
      .pipe()
      .subscribe((res) => {
        if (res) {
          enqueueSnackbar("You have successfully deleted note", {
            variant: "success",
          });

          const newNotes = noteList.filter((n) => n.id !== noteId);
          setNoteList(newNotes);
        }
      });
  };

  const addNewNote = (value) => {
    const url = `projects/${companyDetails.project.id}/companies/${companyDetails.company.id}/notes`;
    const data = {
      note: {
        description: value.note,
      },
    };

    httpPost({
      call: url,
      data,
    })
      .pipe()
      .subscribe((res) => {
        if (res) {
          enqueueSnackbar("You have successfully added new note", {
            variant: "success",
          });

          const newNotes = [
            ...noteList,
            {
              ...res.response,
              description: res.response.description.replace(
                /(?:\r|\n|\r\n)/g,
                "<br>",
              ),
            },
          ];
          setNoteList(newNotes);
        }
      });
  };

  const editNote = (value) => {
    const url = `projects/${companyDetails.project.id}/companies/${companyDetails.company.id}/notes/${value.id}`;
    const data = {
      note: {
        description: value.note,
      },
    };

    httpPut({
      call: url,
      data,
    })
      .pipe()
      .subscribe((res) => {
        if (res) {
          enqueueSnackbar("You have successfully edited note", {
            variant: "success",
          });

          const newNotes = noteList.map((n) => {
            if (n.id === value.id) {
              return {
                ...n,
                ...res.response,
                description: res.response.description.replace(
                  /(?:\r|\n|\r\n)/g,
                  "<br>",
                ),
              };
            }

            return n;
          });

          setNoteList(newNotes);
        }
      });
  };

  return (
    <div className={styles.taskInfoWrp}>
      <div className={styles.companyWrp}>
        <h4 className={styles.title}>Rate Company</h4>
      </div>
      <div className={styles.scoreWrp}>
        <div className={styles.title}>Your total score</div>
        <div className={styles.value}>
          {companyDetails?.user_total_rating?.toFixed(2)
            || user_total_rating
            || 0}
        </div>
      </div>
      {companyDetails?.user_rating_categories?.length > 0 && (
        <div className={styles.container}>
          {companyDetails.user_rating_categories.map((category) => {
            if (!category.parent_id) {
              return (
                <Accordion
                  className={styles.accordion}
                  key={category.rating_category_id}
                >
                  <AccordionSummary
                    expandIcon={
                      category.sub_categories[0] ? (
                        <Icon
                          icon="icn-table-down-chevron"
                          {...mdSize}
                          className={styles.iconColor}
                        />
                      ) : (
                        <div className={styles.stubIcon} />
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={
                      category.sub_categories[0]
                        ? styles.accordionSummary
                        : styles.colll
                    }
                  >
                    <div
                      className={styles.sliderContainer}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <SliderComponent
                        className={styles.slider}
                        rating={{
                          ...category,
                          id: category.rating_category_id,
                          name: category.name,
                          min: 1,
                          max: companyDetails.project?.project_company_rating_template
                            ?.max_scale,
                        }}
                        key={category.rating_category_id}
                        value={category?.value || 0}
                        onChange={(e) => {
                          handleOnChange(e, category);
                        }}
                      />
                    </div>
                  </AccordionSummary>
                  {category.sub_categories?.length ? (
                    <AccordionDetails>
                      <div className={styles.childRatingContainer}>
                        {category.sub_categories?.map((subCategory) => (
                          <div
                            className={styles.sliderContainer}
                            key={subCategory.rating_category_id}
                          >
                            <SliderComponent
                              rating={{
                                ...subCategory,
                                id: subCategory.rating_category_id,
                                name: subCategory.name,
                                max: companyDetails.project?.project_company_rating_template
                                  ?.max_scale,
                                customStyles: {
                                  trackStyle: {
                                    backgroundColor: "#7cc1e4",
                                  },
                                  handleStyle: {
                                    backgroundColor: "lightgray",
                                  },
                                },
                              }}
                              value={subCategory?.value || 0}
                              onChange={(e) => handleOnChange(e, subCategory)}
                            />
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>
                  ) : null}
                </Accordion>
              );
            }
            return null;
          })}
        </div>
      )}
      <div className={styles.notesWrp}>
        <label className={styles.label}>Notes</label>
        <div className={styles.notesCon}>
          {!noteList ? (
            <span className="d-flex flex-1 justify-content-center">
              Loading notes...
            </span>
          ) : null}
          {noteList && noteList.length > 0
            ? noteList.map((note) => (
              <Note
                key={note.id}
                note={note}
                handleMoreOptionClick={(val) => handleMoreOptionClick(val, note)}
                companyName={companyDetails?.company?.name}
              />
            ))
            : null}
        </div>
      </div>
      {noteList ? (
        <div className={styles.addNoteWrp}>
          <RatingAddNotesComponent
            selectedNote={selectedNote}
            onAddEdit={handleAddEdit}
          />
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  companyDetails: getCompanyDetails(state, props),
});

const CompanyRatingsComponentConnect = connect(mapStateToProps)(
  CompanyRatingsComponent,
);

export default React.memo(CompanyRatingsComponentConnect);

import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import styles from "./uploadImg.module.scss";
import LinearProgressWithLabel from "../../../../../common/components/linearProgressWithLabel";
import useS3FileUpload from "../../../../../common/hooks/s3FileUplodar.hook";
import { httpPatch } from "../../../../../common/httpCall";
import { getCompanyProductAddSuccess } from "modules/companies/company/company.action";

const allowedExtensions = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "tiff",
  "bmp",
  "eps",
  "raw",
  "cr2",
  "nef",
  "orf",
  "sr2",
];

const UploadProductImages = (props) => {
  const { uploadDirName, company, product, instance, handleUpload } = props;
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const uploadBlockRef = useRef(null);
  const { fileUpload } = useS3FileUpload();
  const [uploadValues, setUploadValues] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (product) {
      setImages(product?.images || []);
    }
  }, [product]);

  const getExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  const onUploadClick = () => {
    inputRef.current.click();
  };

  const uploadHandler = async (e, action) => {
    e.preventDefault();
    // we want to let user know that he is dragging, so we change the border color for the block
    if (action === "drag" && uploadBlockRef?.current) {
      uploadBlockRef.current.style.border = "1px solid #7b8794";
      return;
    }

    let file;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      file = e.target.files[0];
    }

    if (action === "drop") {
      // on drop we just go to previous border styles
      uploadBlockRef.current.style.border = "1px solid #e4e7eb";

      if (!e || !e.dataTransfer.items.length) {
        return;
      }

      file = e.dataTransfer.items[0].getAsFile();
    }

    if (!file) {
      return;
    }
    if (
      file.size <= 100000000 &&
      allowedExtensions.indexOf(getExtension(file.name)) !== -1
    ) {
      setLoading(true);

      await fileUpload(file, uploadDirName, setUploadValues, file.name, 0).then(
        (res) => {
          if (res && res.location) {
            setLoading(false);
            const newImages = [...images, res.location];
            setImages(newImages);
            updateProductImages(newImages, "uploaded");
            setUploadValues([]);
          }
        }
      );
    } else {
      if (allowedExtensions.indexOf(getExtension(file.name)) === -1) {
        enqueueSnackbar("Extension is not allowed", {
          variant: "error",
        });
      }
      if (file.size > 100000000) {
        enqueueSnackbar(
          "We could not upload your image because your file size is too big. Please make sure the file is less than 100 MB",
          {
            variant: "error",
          }
        );
      }
      setLoading(false);

      e.target.value = "";
    }
  };

  const updateProductImages = (allImages, action) => {
    if (instance !== "table") {
      handleUpload(allImages);
      return;
    }

    httpPatch({
      call: `companies/${company.id}/products/${product.id}`,
      data: {
        name: product.name,
        description: product.description,
        product_link: product.product_link,
        company_link: product.company_link,
        images: allImages,
      },
    }).subscribe((res) => {
      if (res.response) {
        dispatch(
          getCompanyProductAddSuccess({
            ...res.response,
            shouldUpdate: true,
          })
        );
        enqueueSnackbar(`Successfully ${action} image`, {
          variant: "success",
        });
      }
    });
  };

  return (
    <div className={styles.uploadImgWrp}>
      {images?.length && instance === "table" ? (
        <div className={styles.imagesList}>
          <div className="mr-1 position-relative d-flex flex-column">
            <img src={images[0]} alt={product.name} />
          </div>
        </div>
      ) : null}
      {instance !== "table" ? (
        <div>
          <input
            ref={inputRef}
            type="file"
            id="imgUpload"
            style={{ display: "none" }}
            onChange={(e) => uploadHandler(e, "click")}
          />
          <div>
            {" "}
            {loading ? (
              <LinearProgressWithLabel value={uploadValues[0]?.progress || 0} />
            ) : (
              <div className={styles.UploadBlockWrp}>
                {" "}
                <div
                  ref={uploadBlockRef}
                  className={styles.uploadBlock}
                  onClick={onUploadClick}
                  onDrop={(e) => uploadHandler(e, "drop")}
                  onDragOver={(e) => uploadHandler(e, "drag")}
                >
                  <span>Upload Image</span>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {!images?.length ? (
            <div>
              <input
                ref={inputRef}
                type="file"
                id="imgUpload"
                style={{ display: "none" }}
                onChange={(e) => uploadHandler(e, "click")}
              />
              <div>
                {" "}
                {loading ? (
                  <LinearProgressWithLabel
                    value={uploadValues[0]?.progress || 0}
                  />
                ) : (
                  <div className={styles.UploadBlockWrp}>
                    {" "}
                    <div
                      ref={uploadBlockRef}
                      className={styles.uploadBlock}
                      onClick={onUploadClick}
                      onDrop={(e) => uploadHandler(e, "drop")}
                      onDragOver={(e) => uploadHandler(e, "drag")}
                    >
                      <span>Upload Image</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default React.memo(UploadProductImages);

import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import styles from "./note.module.scss";
import { UserInline, MoreOptions } from "..";
import { noteOptions } from "./note.constant";
import { OptionSize } from "../moreOptions/moreOptions.constant";

const NoteComponent = (props) => {
  const { note, handleMoreOptionClick, companyName, currentUser } = props;

  function createMarkup() {
    return { __html: note.description };
  }

  return (
    <div className={styles.noteWrp}>
      <div className={styles.noteinfoWrp}>
        <UserInline userId={note.added_by.id} avatar={note.added_by.avatar} />
        <div className={styles.rightCon}>
          {companyName ? (
            <div className={styles.company}>{companyName}</div>
          ) : null}
          <div className={classNames("caption", styles.date)}>
            {note.created_at &&
              format(new Date(note.created_at), "eeee, MMM do, yyyy.")}
          </div>
          <div className={styles.iconMore}>
            {currentUser && currentUser.id === note.added_by.id && (
              <MoreOptions
                options={noteOptions}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                size={OptionSize.SMALL}
              />
            )}
            {!currentUser && (
              <MoreOptions
                options={noteOptions}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                size={OptionSize.SMALL}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={styles.message}
        dangerouslySetInnerHTML={createMarkup()}
      />
    </div>
  );
};

export default React.memo(NoteComponent);

import * as Yup from "yup";

export const initLocationModel = {
  location_city: "",
  location_region: "",
  location_country: "",
};

export const addLocationValidation = Yup.object().shape({
  location_country: Yup.string().required("Country is required"),
});

import React, { useEffect, useState } from "react";
import classNames from "classnames";

import styles from "./companyAvatar.module.scss";
import { nameToColour } from "../../../common/helper";

const CompanyAvatarComponent = (props) => {
  const {
    imgSrc, name, hasBorderRadius, smallSize,
  } = props;
  const [avatar, setCompanyAvatar] = useState(null);
  const [defaultAvatar, setDefaultAvatar] = useState(null);

  useEffect(() => {
    if (!imgSrc || !imgSrc?.length) {
      const avatar = generateAvatar(name);
      setCompanyAvatar(avatar);
    } else {
      setDefaultAvatar(imgSrc);
    }
  }, [imgSrc, name]);

  const generateAvatar = (name) => {
    const newName = name && name?.length ? `${name.charAt(0)}${name.charAt(1)}` : "-";

    return {
      bgColor: nameToColour(name),
      name: newName,
    };
  };

  const handleImgLoadError = () => {
    setDefaultAvatar(null);

    const avatar = generateAvatar(name);
    setCompanyAvatar(avatar);
  };

  return (
    <div className={styles.companyAvatarWrp}>
      <div
        className={classNames(
          styles.companyAvatarImg,
          hasBorderRadius ? styles.withRadius : "",
          smallSize ? styles.smallSize : "",
        )}
      >
        {defaultAvatar ? (
          <img
            onError={handleImgLoadError}
            src={imgSrc}
            alt={name}
            title={name}
          />
        ) : (
          avatar
          && avatar.name && (
            <div
              className={classNames(
                styles.defaultImage,
                hasBorderRadius ? styles.withRadius : "",
                smallSize ? styles.smallSize : "",
              )}
              style={{ backgroundColor: avatar.bgColor }}
            >
              {avatar.name}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default React.memo(CompanyAvatarComponent);

import React, {
  memo, useEffect, useMemo, useRef, useState,
} from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Field, FieldArray, Form, Formik,
} from "formik";

import { BtnType, Button } from "../../../modules/primitives";
import { ModalFooter } from "../../../application/modal";
import styles from "./totalFundingEdit.module.scss";
import { useModelPopup } from "../../hooks";
import { mdSize } from "../../icon";
import Icon from "../../icon/icon.component";
import {
  addFundingItem,
  deleteTotalFundingItem,
  editFundingItem,
} from "../../actions/common.action";

const defaultFundRow = {
  isNew: true,
  series: "",
  announced_on: "",
  amount_usd: "",
  investors: "",
  touched: true,
};

const TotalFundingEditModal = ({ profile, fundingRounds }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (fundingRounds) {
      const fundings = fundingRounds.map((f) => ({
        ...f,
        isTouched: false,
      }));

      setFormValues({
        fields: fundings,
        originalFields: fundings,
      });
    }
  }, [fundingRounds]);

  const hasNewFund = useMemo(
    () => formValues.fields?.some((f) => f.isNew),
    [formValues.fields],
  );

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSave = (evt, fund, idx) => {
    if (!fund.announced_on?.length) {
      setErrors({
        ...errors,
        [idx]: true,
      });

      return;
    }

    setErrors({
      ...errors,
      [idx]: false,
    });

    const payload = {
      companyId: profile.id,
      funding_round: {
        ...fund,
        series: fund.series?.length ? fund.series : "Unknown",
      },
      cb: (res) => {
        if (fund.isNew) {
          const fields = formValues.fields.map((f) => {
            if (f.isNew) {
              return res;
            }

            return f;
          });

          setFormValues({
            ...formValues,
            fields,
          });

          enqueueSnackbar("Successfully added new funding row", {
            variant: "success",
          });
        } else if (fund.source === "crunchbase") {
          const fields = formValues.fields.filter((f) => f.id !== fund.id);

          setFormValues({
            ...formValues,
            fields,
          });

          enqueueSnackbar("Successfully updated funding row", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Successfully updated funding row", {
            variant: "success",
          });
        }
      },
    };

    if (fund.isNew || fund.source === "crunchbase") {
      dispatch(addFundingItem(payload));
    } else {
      dispatch(editFundingItem(payload));
    }
  };

  const handleCancel = (evt, fund, setFieldValue, idx1) => {
    evt.preventDefault();

    if (errors[idx1]) {
      const newErrorsObj = errors;
      delete newErrorsObj[idx1];

      setErrors(newErrorsObj);
    }

    const fields = formValues.fields.map((f, idx) => {
      if (f.isNew) {
        return Object.keys(defaultFundRow).reduce((acc, key) => {
          if (key === "isNew") {
            return {
              ...acc,
              isNew: true,
            };
          }

          setFieldValue(key, "");

          return {
            ...acc,
            [key]: "",
          };
        }, {});
      }

      if (fund.id === f.id) {
        const getField = formValues.originalFields.find(
          (orFund) => orFund.id === fund.id,
        );

        return Object.keys(fund).reduce((acc, key) => {
          const val = getField[key];
          setFieldValue(`fields.${idx}.${key}`, val);

          return {
            ...acc,
            [key]: val,
          };
        }, {});
      }

      return f;
    });

    setFormValues({
      ...formValues,
      fields,
    });
  };

  const handleDelete = (fund, idx) => {
    if (errors[idx]) {
      const newErrorsObj = errors;
      delete newErrorsObj[idx];

      setErrors(newErrorsObj);
    }

    if (fund.isNew) {
      const fields = formValues.fields.filter((f) => !f.isNew);

      setFormValues({
        ...formValues,
        fields,
      });

      return;
    }

    const payload = {
      companyId: profile.id,
      fundingId: fund.id,
      source: fund.source,
      cb: () => {
        const fields = formValues.fields.filter((f) => f.id !== fund.id);

        setFormValues({
          ...formValues,
          fields,
        });

        enqueueSnackbar("Successfully deleted funding row", {
          variant: "success",
        });
      },
    };

    dispatch(deleteTotalFundingItem(payload));
  };

  const handleAddNewFund = () => {
    const fields = [defaultFundRow, ...formValues.fields];

    setFormValues({
      ...formValues,
      fields,
    });
  };

  const handleDateBlur = (evt, idx, fund) => {
    const val = evt?.target?.value;

    handleFieldBlur(fund, evt, "announced_on");

    if (val?.length) {
      if (errors[idx]) {
        const newErrorsObj = errors;
        delete newErrorsObj[idx];

        setErrors(newErrorsObj);
      }

      return;
    }

    setErrors({
      ...errors,
      [idx]: true,
    });
  };

  const handleFieldBlur = (fund, evt, prop) => {
    const fields = formValues.fields.map((f) => {
      if (fund.id === f.id || f.isNew) {
        return {
          ...f,
          [prop]: evt.target.value,
          touched: true,
        };
      }

      return f;
    });

    setFormValues({
      ...formValues,
      fields,
    });
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={formValues}
      onSubmit={() => {}}
    >
      {({
        values, handleSubmit, setFieldValue, ...formikprops
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className={styles.totalFundingContent}>
            <FieldArray name="fields">
              <div className="d-flex flex-column">
                <div className={styles.gridRow}>
                  <div className={styles.title}>Series</div>
                  <div className={styles.date}>Date</div>
                  <div className={styles.amount}>Amount in $</div>
                  <div className="d-flex flex-grow-1 mr-5">Investors</div>
                  <div className={styles.right}>Actions</div>
                </div>
                {!hasNewFund && (
                <div className="d-flex justify-content-end mt-3">
                  <Button btn={BtnType.REGULAR} onClick={handleAddNewFund}>
                    Add New Funding
                  </Button>
                </div>
                )}
                {values?.fields?.length
                  ? values?.fields?.map((fund, idx) => (
                    <div key={fund.id || idx} className={styles.gridRow}>
                      <div className={styles.title}>
                        <Field
                          name={`fields.${idx}.series`}
                          className={styles.field}
                          onBlur={(evt) => handleFieldBlur(fund, evt, "series")}
                        />
                      </div>
                      <div className={styles.date}>
                        <Field
                          name={`fields.${idx}.announced_on`}
                          type="date"
                          className={styles.field}
                          onBlur={(evt) => handleDateBlur(evt, idx, fund)}
                        />
                        {errors[idx] && (
                        <span className={styles.error}>
                          Announced Date is required!
                        </span>
                        )}
                      </div>
                      <div className={styles.amount}>
                        <span>$</span>
                        <Field
                          name={`fields.${idx}.amount_usd`}
                          type="number"
                          className={styles.field}
                          onBlur={(evt) => handleFieldBlur(fund, evt, "amount_usd")}
                        />
                      </div>
                      <div className="d-flex flex-grow-1 mr-5">
                        <Field
                          name={`fields.${idx}.investors`}
                          as="textarea"
                          className={styles.field}
                          onBlur={(evt) => handleFieldBlur(fund, evt, "investors")}
                        />
                      </div>
                      <div className={styles.right}>
                        {fund.touched && (
                        <>
                          <Button
                            type="button"
                            btn={BtnType.FRAME_LESS}
                            className="mr-2"
                            onClick={(evt) => handleCancel(evt, fund, setFieldValue, idx)}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="button"
                            btn={BtnType.REGULAR}
                            onClick={(evt) => handleSave(evt, fund, idx)}
                          >
                            {fund.isNew ? "Submit" : "Update"}
                          </Button>
                        </>
                        )}
                        <div
                          className="d-flex ml-3"
                          onClick={() => handleDelete(fund, idx)}
                        >
                          <Icon {...mdSize} icon="icn-button-delete" />
                        </div>
                      </div>
                    </div>
                  ))
                  : null}
              </div>
            </FieldArray>
          </div>
          <ModalFooter>
            <Button
              className="mr-3"
              btn={BtnType.FRAME_LESS}
              onClick={handleCancelClick}
            >
              Close
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default memo(TotalFundingEditModal);

import React, { useEffect, useState, useCallback } from "react";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import styles from "./productTopDetails.module.scss";
import { CompanyAvatar } from "../../../../primitives";
import { useWindowSize } from "common/hooks/useWindowSize";
import ProductTopActions from "modules/companies/product/components/productTopActions";
import { editProductDetails } from "modules/companies/product/store/product.actions";

const ProductTopDetails = ({ product, blockFunctionality, isAdmin }) => {
  const {
    appReducer: { relationShipStatuses },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const history = useHistory();
  const { isMobile } = useWindowSize();
  const [relationship, setRelationship] = useState(null);

  useEffect(() => {
    if (product.relationship_stage_id && relationShipStatuses.length) {
      const getStageDetails = relationShipStatuses.find(
        (s) => s.customId === product.relationship_stage_id.id
      );
      setRelationship(getStageDetails);
    }
  }, [product, relationShipStatuses]);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleDropdownSelect = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { value } = e.target;
    const getStageDetails = relationShipStatuses.find(
      (s) => s.id || s.slug === value
    );

    setRelationship(getStageDetails);

    const payload = {
      companyId: product.company.id,
      productId: product.id,
      data: {
        relationship_stage_id: getStageDetails.customId,
      },
    };

    dispatch(editProductDetails(payload));
  };

  const handleGoBack = () => {
    history.push(
      `/companies/${product.company.slug || product.company.id}/products`
    );
  };
  const checkIfTractionMatchIsAllowed = useCallback(() => {
    if (selectedCouncil?.name === "Kyndryl") {
      return false;
    }
    if (product.themes?.length > 0) {
      return false;
    }

    return true;
  });
  return (
    <div className="d-flex flex-column position-relative">
      <h2>Product</h2>
      <div className={styles.goBack} onClick={handleGoBack}>
        {"<"} back to company
      </div>
      <div className={styles.productTopDetails}>
        <div className={styles.logoWrp}>
          <div className={styles.logoCon}>
            <CompanyAvatar
              className={styles.logo}
              imgSrc={product.company.logo}
              name={product.name}
              hasBorderRadius
            />
          </div>
        </div>
        <div
          className={classNames(
            styles.detailsWrapper,
            isMobile ? styles.mobileDetailsWrapper : ""
          )}
        >
          <div
            className={`d-flex ${
              isMobile ? "flex-column" : "align-items-center"
            }`}
          >
            <span className={styles.name}>{product.name}</span>
            <Select
              value={relationship?.slug || "none"}
              onChange={handleDropdownSelect}
              style={{
                backgroundColor: relationship?.colors?.background || "",
                color: relationship?.colors?.text || "",
              }}
              className={
                !relationship?.slug || relationship === null
                  ? styles.tractionMatch
                  : styles.detailsRelationship
              }
              disableUnderline
            >
              {checkIfTractionMatchIsAllowed() && (
                <MenuItem key="none" value="none">
                  Traction Match
                </MenuItem>
              )}
              {relationShipStatuses.map((item) => (
                <MenuItem key={item.slug} value={item.slug}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          {/*<span className={styles.score}>*/}
          {/*  {selectedCouncil?.name === "Kyndryl" ? "Average Kyndryl" : selectedCouncil?.name} Score:{" "}*/}
          {/*    {product?.council_company_score || 0}*/}
          {/*</span>*/}
        </div>
        {isMobile && (
          <ProductTopActions
            isAdmin={isAdmin}
            blockFunctionality={blockFunctionality}
            product={product}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(ProductTopDetails);

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import styles from "./eventList.module.scss";
import { Pagination, Pannel } from "../../primitives";
import {
  eventsCurrnetListGet,
  eventsPastListGet,
  setCurrentEventPage,
} from "../viewEvent/viewEvent.action";
import { pageSettings, EventTypes } from "../events.constant";
import EventComponent from "../event";

const EventListComponent = (props) => {
  const { eventType } = props;

  const {
    councilReducer: { selectedCouncil },
    eventsReducer: {
      viewEventReducer: {
        eventsList,
        eventsPageMeta,
        eventsShouldUpdate,
        currentEventPage,
      },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [pageSetting, setPageSetting] = useState({
    ...pageSettings(12),
    current: currentEventPage,
  });
  const [typeChanged, setTypeChanged] = useState(null);
  const [pageData, setPageData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (pageSetting && !pageSetting.current) {
      setPageSetting((prev) => ({
        ...prev,
        current: 1,
      }));
    }
  }, [pageSetting]);

  useEffect(() => {
    if (selectedCouncil) {
      resetPagin();
      setTypeChanged(eventType);
    }
  }, [eventType, selectedCouncil]);
  useEffect(() => {
    if (eventsPageMeta) {
      setPageSetting((prev) => ({
        ...prev,
        total: eventsPageMeta.total,
      }));
    }
  }, [eventsPageMeta && eventsPageMeta.total]);

  useEffect(() => {
    setPageData(eventsList[pageSetting.current]);
  }, [eventsList, pageSetting.current, pageSetting.pageSize]);

  useEffect(() => {
    if (selectedCouncil) {
      if (eventType === EventTypes.UPCOMING) {
        dispatch(
          eventsCurrnetListGet({
            councilId: selectedCouncil.id,
            currentPage: pageSetting.current,
            pageSize: pageSetting.pageSize,
            enqueueSnackbar,
          }),
        );
      } else {
        dispatch(
          eventsPastListGet({
            councilId: selectedCouncil.id,
            currentPage: pageSetting.current,
            pageSize: pageSetting.pageSize,
            enqueueSnackbar,
          }),
        );
      }
    }
  }, [typeChanged, selectedCouncil, pageSetting.current]);

  useEffect(() => {
    if (selectedCouncil && eventsShouldUpdate) {
      if (eventType === EventTypes.UPCOMING) {
        dispatch(
          eventsCurrnetListGet({
            councilId: selectedCouncil.id,
            currentPage: pageSetting.current,
            pageSize: pageSetting.pageSize,
            enqueueSnackbar,
          }),
        );
      } else {
        dispatch(
          eventsPastListGet({
            councilId: selectedCouncil.id,
            currentPage: pageSetting.current,
            pageSize: pageSetting.pageSize,
            enqueueSnackbar,
          }),
        );
      }
    }
  }, [eventsShouldUpdate]);

  const resetPagin = () => {
    if (eventType === 0) {
      dispatch(setCurrentEventPage(0));
      setPageSetting((prev) => ({
        ...prev,
        current: 1,
        total: 0,
      }));
    }
  };

  const handlePagingChange = (page) => {
    dispatch(setCurrentEventPage(page));
    setPageSetting((prev) => ({
      ...prev,
      current: page,
    }));
  };

  return (
    <div className={styles.contentWrp}>
      <div className={styles.tabInner}>
        {pageData && pageData[0] ? (
          pageData.map((event) => (
            <div className="col-4" key={event.id}>
              <EventComponent currentPage={pageSetting.current} event={event} />
            </div>
          ))
        ) : (
          <div className={styles.pannelWrp}>
            <Pannel padding="0" parentStyle={{ background: "Transparent" }}>
              <div className={styles.noEvents}>
                <div className={styles.emptyCon}>
                  <h4>There are no events yet.</h4> <br />
                  Create an event to get started.
                </div>
              </div>
            </Pannel>
          </div>
        )}
        <div className={styles.paginWrp}>
          <div className={styles.paginCon}>
            <Pagination {...pageSetting} onChange={handlePagingChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EventListComponent);

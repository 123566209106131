import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import styles from "./lists.module.scss";
import { Icon, smSize } from "../../../../../../common/icon";
import {
  queryParamStringToArray,
  queryStringUrlReplacement,
  useQuery,
} from "../../../../../../common/helper";

import { MultiSelect } from "../../../../../primitives";
import { searchListsByQueryAttempt } from "../../../../../lists/store/lists.actions";

const ListsFilter = ({ expanded, handleExpand, disabled }) => {
  const history = useHistory();
  const selectedListsQuery = useQuery().get("lists");
  const [listSearchValue, setLIstSearchValue] = useState("");
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [collapsed, setCollapsed] = useState(false);

  const [selectedLists, setSelectedList] = useState([]);
  const {
    listsMainReducer: { savedCompaniesLists },
  } = useSelector((state) => state);

  useEffect(() => {
    setCollapsed(expanded);
  }, [expanded]);

  useEffect(() => {
    let getData = () => {};
    if (listSearchValue) {
      getData = setTimeout(() => {
        dispatch(
          searchListsByQueryAttempt({
            query: listSearchValue,
            page: 1,
            enqueueSnackbar,
          })
        );
      }, 500);
    }

    return () => clearTimeout(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, listSearchValue]);

  useEffect(() => {
    if (collapsed) {
      dispatch(
        searchListsByQueryAttempt({
          query: listSearchValue,
          page: 1,
          enqueueSnackbar,
          isDropdown: undefined,
        })
      );
    }
  }, [collapsed]);

  const handleSearchLists = (text) => {
    setLIstSearchValue(text);
  };

  useEffect(() => {
    if (selectedListsQuery !== null) {
      const arr = queryParamStringToArray(selectedListsQuery).map((string) => {
        const obj = {
          id: string?.split("_")[0],
          name: string?.split("_")[1],
        };
        return obj;
      });

      setSelectedList(arr);
    }
  }, [selectedListsQuery]);

  const handleCollapseClick = () => {
    if (disabled) {
      return;
    }

    // if (!collapsed) {
    //   handleExpand("lists");
    // }
    setCollapsed(!collapsed);
  };

  const handleOnChange = (value) => {
    let clearVal;

    if (value.length) {
      clearVal = value.reduce((acc, elem) => {
        return `${acc}${acc.length ? "," : ""}${elem.id}_${elem.name}`;
      }, "");
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      "lists",
      clearVal?.length ? clearVal : ""
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        lists: clearVal,
        page: 1,
      },
    });
  };

  return (
    <div
      className={`${styles.itemWrapper} ${disabled ? styles.disabled : ""}`}
      id="listsFilter"
    >
      <div className={styles.itemTitle} onClick={handleCollapseClick}>
        <span>Lists</span>
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(styles.icon, !collapsed && styles.collapse)}
        />
      </div>
      {collapsed ? (
        <div
          className={classNames(
            "d-flex align-items-center",
            styles.withPaddingLeft
          )}
        >
          <div
            id="lists"
            className={classNames("d-flex flex-column", styles.employeesField)}
          >
            <MultiSelect
              onChange={(val, obj) => {
                handleOnChange(val);
              }}
              placeholder={`Choose Lists`}
              labelField="name"
              valueField="id"
              searchBy="name"
              options={savedCompaniesLists}
              values={selectedLists}
              multi
              searchFn={(args) => {
                if (listSearchValue === args.state.search) {
                  return;
                }
                handleSearchLists(args.state.search);
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(ListsFilter);

import React from "react";
import { Label } from "modules/primitives";
import Select from "react-dropdown-select";

const PlantSingleSelect = ({ formikprops, plants }) => {
  return (
    <>
      <Label>Plant</Label>
      <Select
        multi={false}
        closeOnSelect
        values={
          formikprops.values.selectedPlant
            ? [formikprops.values.selectedPlant]
            : []
        }
        onChange={(val) => {
          formikprops.setFieldValue("selectedPlant", val[0]);
        }}
        options={plants}
        labelField="name"
        valueField="id"
        placeholder="Plants"
        searchBy="name"
        style={{ border: "1px solid #e4e7eb" }}
      />
      {formikprops.values.selectedPlant?.areas?.length > 0 ? (
        <div className="mt-2">
          <Label>Areas</Label>
          <Select
            multi={false}
            closeOnSelect
            values={formikprops.values.selectedPlant?.selectedAreas || []}
            onChange={(val) => {
              formikprops.setFieldValue("selectedPlant", {
                ...formikprops.values?.selectedPlant,
                selectedAreas: val,
              });
            }}
            options={
              formikprops.values.selectedPlant.areas.map((area) => {
                return {
                  area_name: area.area_name || area.attributes.name,
                  area_id: area.area_id || area.id,
                };
              }) || []
            }
            labelField="area_name"
            valueField="area_id"
            placeholder="Areas"
            searchBy="area_name"
            style={{ border: "1px solid #e4e7eb" }}
          />
          {
            formikprops.values.selectedPlant?.areas?.length && !formikprops.values.selectedPlant?.selectedAreas?.length ? (
              <span className="d-block small mt-2 text-danger">Please select an Area</span>
            ) : null
          }
          {formikprops.values.selectedPlant?.selectedAreas?.length > 0 ? (
            <div className="d-flex justify-content-end">
              <span
                className="text-muted small cursor-pointer"
                onClick={() =>
                  formikprops.setFieldValue("selectedPlant", {
                    ...formikprops.values?.selectedPlant,
                    selectedAreas: [],
                  })
                }
              >
                clear
              </span>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default PlantSingleSelect;

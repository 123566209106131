import React, { useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "modules/primitives";
import Dialog from "@mui/material/Dialog";

const TextTableCustomField = ({ row, field, fieldCoreDetails, updateCellCallback }) => {
  const [value, setValue] = useState('');
  const [editActive, setEditActive] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(field.field_value || '');
  }, [field.field_value]);

  useEffect(() => {
    if (row.editMode || !field.field_value?.length || row.isTouched) {
      setEditActive(true);
      return;
    }

    setEditActive(false);
  }, [row, field]);

  const handleShowMore = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleChange = evt => {
    const val = evt.target.value;
    setValue(val);

    if (updateCellCallback) {
      updateCellCallback({ ...field, field_value: val });
    }
  }

  return (
    <div style={{ textWrap: 'wrap' }}>
      {
        editActive ? (
          <input
            value={value}
            className="border p-1"
            placeholder={fieldCoreDetails.field_placeholder || 'Enter a value'}
            type="text"
            onChange={handleChange}
          />
        ) : (
          <>
            <span>
              {value?.length > 100 ? `${value.slice(0, 100)}...` : value}
            </span>
            {
              value?.length > 100 ? (
                <span className="small cursor-pointer ml-2" onClick={handleShowMore}>show more</span>
              ) : null
            }
          </>
        )
      }
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <div className="d-flex flex-wrap">
            {value || '-'}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(TextTableCustomField);

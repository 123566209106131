import React from "react";
import Switch from "react-switch";
import UpdateStepPosition from "modules/admin/adminProjects/adminProjectWizard/addEditWizardStep/updateStepPosition";
import styles from "../adminThemes.module.scss";

const ProjectTableRow = ({ item, handlePositionUpdate, setRowPropValue }) => {
  return (
    <div className={styles.tableRow}>
      <div className={styles.projectThemes}>
        <div className={styles.columnsContainer}>
          <div className={styles.firstColumn}>
            {item.column_type
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </div>
          <div className={styles.secondColumn}>
            {" "}
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              disabled={
                item && item.column_type === "project_name" && item.display
              }
              checked={item.display}
              onColor="#24a0ed"
              onChange={(e) => {
                setRowPropValue(e, item, "display", "main_table_columns");
              }}
            />
          </div>
          <div className={styles.thirdColumn}>
            <div>
              <input
                id="from"
                type="number"
                min="1"
                max="15"
                value={item.position || ""}
                onChange={(e) => {
                  setRowPropValue(e?.target?.value, item, "position");
                }}
                disabled={
                  item && item.column_type === "project_name" && item.display
                }
              />
            </div>
            {item && item.column_type && item.column_type !== "project_name" ? (
              <UpdateStepPosition
                item={item}
                handleUpdate={handlePositionUpdate}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectTableRow;

import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import {
  Button,
  BtnType,
  Label,
  TextBox,
  DropSelect,
} from "../../../../../primitives/index";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../../common/hooks";
import { ModalFooter, ModalBody } from "../../../../../../application/modal";
import { initModel, validation } from "./constants";
import { companyContactInvite } from "../../../company.action";

const InviteMemberModal = ({ contact, contactCompany, from }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // const dropDownRef = useRef();
  const formRef = useRef();
  // const [selectedAccessLevel, setSelectedAccessLevel] = useState(
  //   preselectedAccessType,
  // );
  const {
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
  } = useSelector((state) => state);

  const initialValues = contact
    ? {
        mobile: contact.mobile,
        first: contact?.first_name,
        last: contact?.last_name,
        email: contact?.email,
        title: contact?.title,
        teamId: [],
      }
    : null;

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = () => {
    dispatch(
      companyContactInvite({
        companyId: from === "people" ? contactCompany.id : selectedCompany.id,
        data: contact,
        enqueueSnackbar,
        cb: () => {
          popup.hide();
          enqueueSnackbar("User added successfully!", {
            variant: "success",
          });
        },
      })
    );
  };

  return (
    <>
      <ModalBody>
        <div className={styles.wrapper}>
          <Formik
            innerRef={formRef}
            initialValues={contact ? initialValues : initModel && initModel}
            validationSchema={validation && validation}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);

              handleSaveClick();
              resetForm();
            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              values,
              ...formikprops
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  An email invite will be sent to the person below with signup
                  instructions.
                </div>
                <div className={styles.editStageFormWrp}>
                  <Label>Email</Label>
                  <TextBox
                    type="text"
                    name="email"
                    placeholder="Email"
                    className={styles.textBox}
                    formProps={{ setFieldValue, values, ...formikprops }}
                    disabled
                  />
                  <div className={styles.name}>
                    <div className={styles.firstName}>
                      <Label>First Name</Label>
                      <TextBox
                        type="text"
                        name="first"
                        placeholder="First name"
                        className={styles.textBox}
                        formProps={{ setFieldValue, values, ...formikprops }}
                        disabled
                      />
                    </div>
                    <div className={styles.lastName}>
                      <Label>Last Name</Label>
                      <TextBox
                        type="text"
                        name="last"
                        placeholder="Last name"
                        className={styles.textBox}
                        formProps={{ setFieldValue, values, ...formikprops }}
                        disabled
                      />
                    </div>
                  </div>
                  <Label>Title</Label>
                  <TextBox
                    type="text"
                    name="title"
                    placeholder="Title"
                    className={styles.textBox}
                    formProps={{ setFieldValue, values, ...formikprops }}
                    disabled
                  />

                  {/* <Label>Access Level</Label> */}
                  {/* <DropSelect */}
                  {/*  placeholder="Choose a access level" */}
                  {/*  dropRef={dropDownRef} */}
                  {/*  name="permission" */}
                  {/*  labelField="text" */}
                  {/*  valueField="val" */}
                  {/*  searchBy="text" */}
                  {/*  disabled={preselectedAccessType?.val === 4} */}
                  {/*  values={[selectedAccessLevel]} */}
                  {/*  options={ */}
                  {/*    permission.filter((option) => { */}
                  {/*      if ( */}
                  {/*        council_role === 'ttp_administrator' || */}
                  {/*        council_role === 'council_administrator' */}
                  {/*      ) { */}
                  {/*        return option; */}
                  {/*      } */}
                  {/*      return option.val !== 1; */}
                  {/*    }) */}
                  {/*  } */}
                  {/*  onChange={(e) => { */}
                  {/*    setFieldValue('permission', e); */}
                  {/*    setSelectedAccessLevel(e); */}
                  {/*  }} */}
                  {/*  formProps={{ setFieldValue, values, ...formikprops }} */}
                  {/* /> */}

                  {(selectedCompany || contactCompany.id) && (
                    <div className={styles.companyDropVisible}>
                      <Label>Company</Label>
                      <DropSelect
                        labelField="name"
                        valueField="id"
                        searchBy="name"
                        options={[
                          from === "people" ? contactCompany : selectedCompany,
                        ]}
                        values={[
                          from === "people" ? contactCompany : selectedCompany,
                        ]}
                        disabled
                      />
                    </div>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button onClick={handleSaveClick} btn={BtnType.REGULAR}>
          Invite
        </Button>
      </ModalFooter>
    </>
  );
};

export default React.memo(InviteMemberModal);

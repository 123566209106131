import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useSelector } from "react-redux";

import styles from "./customTags.module.scss";
import { Icon, smSize } from "../../../../../../common/icon";
import {
  queryParamStringToArray,
  queryStringUrlReplacement,
  useQuery,
  convertFilterIndexToValues,
  convertTagIdToIndex,
} from "../../../../../../common/helper";
import { CheckBox, CheckType } from "../../../../../primitives";
import CustomTagsChildsFilter from "./childTag";

const CustomTagsFilter = ({
  expanded,
  handleExpand,
  filter,
  filterIndex,
  disabled,
}) => {
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const history = useHistory();
  const useQuerryCustomTags = useQuery().get("custom_tags_ids");
  const [collapsed, setCollapsed] = useState(false);
  const [globalSelected, setGlobalSelected] = useState(false);
  const [customTagsFiltersValue, setCustomTagsFiltersValue] = useState(null);
  const [expandedParent, handleExpandedParentClick] = useState([]);

  const handleExpandParrent = (parrent) => {
    handleExpandedParentClick((prev) => {
      const newParr = [];
      if (prev.includes(parrent.name)) {
        return prev.filter((el) => el !== parrent.name);
      }
      newParr.push(parrent.name);
      return newParr;
    });
  };

  useEffect(() => {
    if (disabled) {
      setCollapsed(false);
      return;
    }

    setCollapsed(expanded);
  }, [expanded, disabled]);

  useEffect(() => {
    let newCustomTags = [];
    if (filter) {
      newCustomTags = filter?.custom_tags?.map((s) => ({
        ...s,
        selected: false,
      }));

      setCustomTagsFiltersValue(newCustomTags);
    }

    if (useQuerryCustomTags !== null && useQuerryCustomTags !== "all") {
      const arrIndexes = queryParamStringToArray(useQuerryCustomTags);
      const arrTagsIds = convertFilterIndexToValues(
        arrIndexes,

        selectedCouncil?.council_custom_tag_groups
      );
      const newItems = newCustomTags.map((item) => {
        const itemIsSelected = arrTagsIds.includes(item.custom_tag_id);
        if (item.children.length) {
          item.children = item.children.map((child) => {
            const childIsSelected = arrTagsIds.includes(child.custom_tag_id);
            return {
              ...child,
              selected: childIsSelected,
            };
          });
        }

        return {
          ...item,
          selected: itemIsSelected,
        };
      });

      setCustomTagsFiltersValue(newItems);
      setGlobalSelected(false);
    }

    if (
      useQuerryCustomTags &&
      useQuerryCustomTags.indexOf("all") >= 0 &&
      filter.name === "Markets Served"
    ) {
      const newItems = newCustomTags.map((item) => {
        return {
          ...item,
          selected: true,
        };
      });
      setGlobalSelected(true);
      setCustomTagsFiltersValue(newItems);
    }

    return () => null;
  }, [selectedCouncil, useQuerryCustomTags]);

  const handleCollapseClick = () => {
    if (disabled) {
      return;
    }

    if (!collapsed) {
      handleExpand("markets");
    }
    setCollapsed(!collapsed);
  };

  const handleSelectGlobal = () => {
    setGlobalSelected((prev) => {
      const newItems = customTagsFiltersValue.map((a) => {
        return {
          ...a,
          selected: !prev,
        };
      });

      setCustomTagsFiltersValue(newItems);
      if (!prev === true) {
        let search = queryStringUrlReplacement(
          history.location.search,
          "custom_tags_ids",
          `all_${filterIndex}`
        );
        search = queryStringUrlReplacement(search, "page", 1);

        history.push({
          pathname: "/companies",
          search,
          state: {
            ...history.location?.state,
            custom_tags_ids: `all_${filterIndex}`,
            page: 1,
          },
        });
      } else {
        let search = queryStringUrlReplacement(
          history.location.search,
          "custom_tags_ids",
          ``
        );
        search = queryStringUrlReplacement(search, "page", 1);

        history.push({
          pathname: "/companies",
          search,
          state: {
            ...history.location?.state,
            custom_tags_ids: `all_${filterIndex}`,
            page: 1,
          },
        });
      }

      return !prev;
    });
  };

  const handleOnChange = (val, custTag, parrentId, parrentIndex) => {
    let value = "";
    const selectedTagIndex = convertTagIdToIndex(
      custTag.custom_tag_id,
      selectedCouncil.council_custom_tag_groups,
      filter.council_custom_tag_group_id,
      parrentId
    );

    const prevValues = queryParamStringToArray(
      history.location.state?.custom_tags_ids || ""
    )
      .filter((prev) => {
        return prev !== selectedTagIndex;
        // const groupInx = Number(prev?.split("_")[0]);
        // return filterIndex !== groupInx;
      })
      .filter((prev) => {
        return prev.indexOf("all") < 0;
      });

    if (!parrentId) {
      value = customTagsFiltersValue
        ?.map((a, i) => {
          if (a.custom_tag_id === custTag.custom_tag_id) {
            return {
              ...a,
              selected: val,
              i,
            };
          }
          return { ...a, i };
        })
        .filter((item) => item.selected)
        .reduce((acc, elem) => {
          if (prevValues.includes(`${filterIndex}_${elem.i}`)) {
            return `${acc}`;
          }
          return `${acc}${acc.length ? "," : ""}${filterIndex}_${elem.i}`;
        }, "");
    } else {
      value = customTagsFiltersValue[parrentIndex].children
        .map((child, childInx) => {
          if (child.custom_tag_id === custTag.custom_tag_id) {
            return {
              ...child,
              selected: val,
              childInx,
            };
          }
          return { ...child, childInx };
        })
        .filter((item) => item.selected)
        .reduce((acc, elem) => {
          if (
            prevValues.includes(
              `${filterIndex}_${parrentIndex}_${elem.childInx}`
            )
          ) {
            return `${acc}`;
          }
          return `${acc}${
            acc.length ? "," : ""
          }${filterIndex}_${parrentIndex}_${elem.childInx}`;
        }, "");
    }

    if (prevValues[0] && value[0]) {
      value = value + "," + prevValues.join(",");
    }
    if (prevValues[0] && !value) {
      value = prevValues.join(",");
    }
    let search = queryStringUrlReplacement(
      history.location.search,
      "custom_tags_ids",
      value
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        custom_tags_ids: value,
        page: 1,
      },
    });

    const newItems = customTagsFiltersValue.map((a) => {
      if (a.custom_tag_id === custTag.custom_tag_id) {
        return {
          ...a,
          selected: val,
        };
      }

      return a;
    });

    setCustomTagsFiltersValue(newItems);
    if (!val) {
      setGlobalSelected(false);
    }
  };

  return (
    <div
      className={`${styles.itemWrapper} ${disabled ? styles.disabled : ""}`}
      id="custom_tags"
    >
      {/* always visible */}
      <div className={styles.itemTitle} onClick={handleCollapseClick}>
        <span>{filter?.name || "-"}</span>
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(styles.icon, !collapsed && styles.collapse)}
          onClick={handleCollapseClick}
        />
      </div>
      {/* end of always visible */}

      {/* parrent collapsed yes/no */}
      {collapsed ? (
        <div
          className={classNames(
            "d-flex align-items-center",
            styles.withPaddingLeft
          )}
        >
          <div
            className={classNames("d-flex flex-column", styles.marketsField)}
          >
            {filter.name === "Markets Served" && (
              <div
                className={classNames(
                  "d-flex align-items-center",
                  styles.globalCheckboxStyle
                )}
              >
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={globalSelected}
                  onChange={() => handleSelectGlobal(filter.name)}
                />
                <span
                  className={styles.checkboxName}
                  onClick={() => handleSelectGlobal(filter.name)}
                >
                  Global
                </span>
              </div>
            )}

            {customTagsFiltersValue?.length
              ? customTagsFiltersValue.map((a, parentIndex) => (
                  <div key={a.custom_tag_id}>
                    <div className="d-flex align-items-center">
                      <CheckBox
                        checkType={CheckType.BLUE}
                        isChecked={a.selected}
                        onChange={(val) => handleOnChange(val, a)}
                      />
                      <span
                        className={styles.checkboxName}
                        onClick={() => handleOnChange(!a.selected, a)}
                      >
                        {a.name}
                      </span>
                      {a.children?.length ? (
                        <Icon
                          {...smSize}
                          icon="icn-table-down-chevron"
                          className={classNames(
                            styles.icon,
                            !expandedParent.includes(a.name) && styles.collapse
                          )}
                          onClick={() => handleExpandParrent(a)}
                        />
                      ) : null}
                    </div>

                    {/* end of parrent  */}

                    {/* show children parrent  */}
                    {expandedParent.includes(a.name) ? (
                      <div
                        className={classNames(
                          "d-flex align-items-center",
                          styles.withPaddingLeft
                        )}
                      >
                        <div
                          className={classNames(
                            "d-flex flex-column",
                            styles.marketsField
                          )}
                        >
                          {a.children?.length
                            ? a.children.map((child) => (
                                <CustomTagsChildsFilter
                                  a={a}
                                  parentIndex={parentIndex}
                                  handleOnChange={handleOnChange}
                                  child={child}
                                />
                              ))
                            : "No filters were added to this group"}
                        </div>
                      </div>
                    ) : null}

                    {/* show children parrent  */}
                  </div>
                ))
              : "No filters were added to this group"}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(CustomTagsFilter);

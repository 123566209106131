import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./ideaGridItem.module.scss";
import VoteIdea from "../../../../voteIdea";

const GridItem = ({ idea }) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [canVote, setCanVote] = useState(true);

  useEffect(() => {
    if (selectedCouncil && session) {
      const council_role = session?.council_role;
      const isKyndryl = selectedCouncil.name === "Kyndryl";

      if (isKyndryl) {
        const currentUserIsReviewer = idea.ideas_members
          ?.filter((m) => m.user_role === "reviewer")
          .some((u) => u.user_id === session.id);

        setCanVote(
          currentUserIsReviewer ||
            council_role === "ttp_administrator" ||
            council_role === "council_administrator"
        );
      } else {
        setCanVote(true);
      }
    }

    return () => null;
  }, [session, selectedCouncil]);

  const capitalizeFirstLetter = (string) => {
    if (
      selectedCouncil?.traction_tag === "home_x" &&
      string.toUpperCase() === "PAUSED"
    ) {
      return "Parked";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getIdeaDescription = () => {
    if (idea.ideas_descriptions && idea.ideas_descriptions.length > 0) {
      const description = idea.ideas_descriptions.find(
        // eslint-disable-next-line camelcase
        ({ section_name }) => section_name === "Description"
      );

      if (description) {
        return description.section_value;
      }
    }
    return "";
  };

  return (
    <Link
      to={`/ideas/${idea.id}`}
      className={classnames(styles.listItemCard, "card")}
    >
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h5 className={styles.cardTitle}>{idea.name}</h5>
          <span
            className={classnames(
              styles.listItemStatus,
              styles[`listItemStatus-${idea.status}`]
            )}
          >
            {capitalizeFirstLetter(idea.status)}
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column pr-2">
            <p
              className={classnames(
                styles.cardDescription,
                styles.overflowText
              )}
            >
              {getIdeaDescription()}
            </p>
            <div className={styles.cardDeadline}>
              <span>Date</span>
              <span>{format(parseISO(idea.created_at), "MMM do, yyyy")}</span>
            </div>
          </div>
          <div
            role="cell"
            aria-hidden="true"
            onClick={(e) => e.preventDefault()}
            className="d-flex align-items-center flex-column"
          >
            <span className="text-muted small">Vote</span>
            <VoteIdea idea={idea} canVote={canVote} />
          </div>
        </div>
        {idea.resource_industries || idea.resource_topics ? (
          <div className="d-flex mt-3 flex-wrap">
            {[...idea.resource_industries, ...idea.resource_topics].map(
              (item, idx) => (
                <span className={styles.tag} key={idx}>
                  {item?.name || item?.name}
                </span>
              )
            )}
          </div>
        ) : null}
      </div>
    </Link>
  );
};

export default React.memo(GridItem);

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Popover from "@mui/material/Popover";

import { UserInline, UserInlineTypes } from "../../../primitives";

import styles from "./companyBlockOwners.module.scss";

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "15px",
  },
}));

const CompanyBlockOwners = ({ allOwners, itsOwners }) => {
  const filteredOwners = allOwners.filter((o) => itsOwners.some((o1) => o1.user_id === o.user_id));

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="d-flex flex-wrap">
      {filteredOwners.slice(0, 3).map((owner, idx) => (
        <div className="mr-2" key={owner.user_id + idx}>
          <UserInline
            userId={owner.user_id}
            first={owner.first_name}
            last={owner.last_name}
            avatar={owner.avatar}
            type={UserInlineTypes.WITH_NAME}
            dontRequest
          />
        </div>
      ))}
      {filteredOwners.length > 3 ? (
        <div
          className={styles.otherOwners}
          aria-owns={open ? "mouse-over-tech" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Popover
            id="mouse-over-tech"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            {filteredOwners
              .slice(3, filteredOwners.length)
              .map((owner, idx) => (
                <div className="mb-1" key={owner.user_id + idx}>
                  <UserInline
                    userId={owner.user_id}
                    first={owner.first_name}
                    last={owner.last_name}
                    avatar={owner.avatar}
                    type={UserInlineTypes.WITH_NAME}
                    dontRequest
                  />
                </div>
              ))}
          </Popover>
          +{filteredOwners.length - 3}
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(CompanyBlockOwners);

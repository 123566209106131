import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import Tooltip from "@mui/material/Tooltip";
import "rc-pagination/assets/index.css";

import { httpPost } from "common/httpCall";
import { checkViewerRole } from "common/checkers/viewerChecker";
import NewProjectV3 from "modules/projects/newProjectv3";
import { projectsSearch } from "./projectsMain.action";
import { Switch, Button, BtnType, Loading } from "../../primitives";
import styles from "./projectsMain.module.scss";
import {
  HeaderOptions,
  projectPageSettings,
  fordHeaderOptions,
  filterOptions,
  defaultPayload,
  defFilterOptionSearchResult,
} from "./projectsMain.constant";
import {
  queryStringUrlReplacement,
  useQuery,
  getCookie,
} from "../../../common/helper";
import {
  projectStagesReset,
  getAllProjectStages,
  getAllTemplatesProjectStages,
} from "../project/stages/stages.action";
import ProjectsSearch from "./projectsSearch";
import FiltersRow from "./projectTabs/filters/index";
import { projectStatesText } from "../../primitives/projectState/projectState.constant";
import CatalogTabComponent from "./projectTabs/catalogTab.component";
import IdeasTabComponent from "./projectTabs/ideasTab.component";
import AllTabComponent from "./projectTabs/allTab.component";
import SortingIcon from "./projectTabs/sortingIcon";
import LoaderWithDots from "../../../common/components/loaderWithDots";

const ProjectsMainComponent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParamMode = useQuery().get("mode");
  const searchKey = useQuery().get("searchKey");
  const sortOrder = useQuery().get("sortOrder");
  const sortAttribute = useQuery().get("sortAttribute");
  const council_role = getCookie("council_role_");

  const {
    projectsReducer: {
      projectsMainReducer: { projectLoading, projectListMeta },
      stagesReducer: { allStagesList, allTemplatesStagesList },
    },
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [pageSetting, setPageSetting] = useState(projectPageSettings);
  const [selectedTab, setSelectedTab] = useState(queryParamMode || "");
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [sortingConfig, setSortingConfig] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [downloadIsActive, setDownloadIsActive] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [topPannelHidden, setTopPannelHidden] = useState(false);

  const csvLinkRef = useRef();
  const isFord = selectedCouncil?.traction_tag === "ford";

  const [selectedFilter, setSelectedFilter] = useState(
    defaultPayload(selectedCouncil?.traction_tag, selectedTab)
  );
  const [filterOptionSearchResult, setFilterOptionSearchResult] = useState(
    defFilterOptionSearchResult
  );

  const requestTheData = (query = "", tab, filters = {}) => {
    const userId = session?.id;
    const getStagesAllIds = () => {
      const allIds = [];
      const ids = selectedFilter?.stage_ids.map((opt) => {
        allTemplatesStagesList.map((stage) => {
          if (stage.name === opt.name) {
            allIds.push(stage.stage_ids);
          }
        });
      });
      return allIds.flat();
    };
    filters.stage_ids = getStagesAllIds();

    const checkForMyProjectsEnv = () => {
      if (
        queryParamMode === "all_projects" ||
        queryParamMode === "catalog_projects" ||
        selectedTab === "catalog_projects" ||
        tab === "catalog_projects" ||
        selectedTab === "project_stage_idea"
      ) {
        return null;
      }
      // if selectedtab is empty string then its "My proj /`default`/Tab" and user id should be sent
      return userId;
    };

    const checkForCatalogProjectsEnv = () => {
      // if (query?.length) {
      //   return null;
      // }

      if (
        tab === "catalog_projects" ||
        selectedTab === "catalog_projects" ||
        queryParamMode === "catalog_projects"
      ) {
        return true;
      }
      return null;
    };

    const payload = {
      data: {
        search: {
          query: selectedTab === "catalog_projects" ? "" : query,
          user_id: checkForMyProjectsEnv(),
          catalog: checkForCatalogProjectsEnv(),
          ...filters,
        },
        selected_tab: searchKey?.length
          ? "all_projects"
          : queryParamMode || "my_projects",
        page: pageSetting.current,
        sort_order: sortOrder || "desc",
        sort_attribute: sortAttribute || "activity",
      },
      ford: isFord && selectedTab === "catalog_projects",
    };

    dispatch(projectsSearch(payload));
  };

  useEffect(() => {
    if (allStagesList.length) {
      setFilterOptionSearchResult((prev) => {
        return { ...prev, stage_ids: allStagesList };
      });
    }
  }, [allStagesList]);

  useEffect(() => {
    setSortingConfig({
      activeColumn: sortAttribute || "activity",
      order: sortOrder || "desc",
    });
  }, [sortOrder, sortAttribute]);

  useEffect(() => {
    if (sortOrder && sortingConfig?.order !== sortOrder) {
      setShowLoading(true);
    } else {
      const timeout = setTimeout(() => {
        setShowLoading(false);
        clearTimeout(timeout);
      }, 1000);
    }
  }, [sortAttribute, sortOrder, sortingConfig]);

  useEffect(() => {
    // if (session?.id && selectedCouncil?.traction_tag) {
    let tab = "";
    if (selectedTab === "") {
      if (isFord) {
        tab = queryParamMode || "catalog_projects";
      } else {
        tab = queryParamMode || "my_projects";
      }
      setSelectedTab(tab);
    }
    const filters = {
      ...selectedFilter,
      theme_ids: selectedFilter?.theme_ids?.map((opt) => opt.id) || [],
      plant_ids: selectedFilter?.plant_ids?.map((opt) => opt.id) || [],
      area_ids: selectedFilter?.area_ids?.map((opt) => opt.id) || [],
      stage_ids: selectedFilter?.stage_ids?.map((opt) => opt.id) || [],
      topic_ids: selectedFilter?.topic_ids?.map((opt) => opt.id) || [],
      plant_region_ids:
        selectedFilter?.plant_region_ids?.map((opt) => opt.id) || [],
      project_owner_ids:
        selectedFilter?.project_owner_ids?.map((opt) => opt.id) || [],
      stage_template_ids:
        selectedFilter?.stage_template_ids?.map((opt) => opt.id) || [],
    };
    if (selectedTab === "project_stage_idea" && isFord) {
      tab = "project_stage_idea";
      filters.idea_stages = true;
    }
    if (selectedTab === "all_projects" && isFord) {
      tab = "all_projects";
      filters.idea_stages = false;
    }
    if (selectedTab !== "project_stage_idea") {
      filters.approval_status = undefined;
    }
    if (topPannelHidden) {
      filters.idea_stages = undefined;
    }
    // if (selectedTab === "my_projects" && isFord) {
    //   filters.idea_stages = true;
    // }
    requestTheData(searchKey || "", tab, filters);
    // }
  }, [
    selectedTab,
    pageSetting.current,
    searchKey,
    sortingConfig,
    selectedFilter,
  ]);

  useEffect(() => {
    setIsAdmin(
      council_role === "ttp_administrator" ||
        council_role === "council_administrator"
    );
    return () => null;
  }, []);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    if (location?.state?.page) {
      setPageSetting({
        ...pageSetting,
        current: location.state.page,
      });
    }
  }, [location?.state?.page]);

  useEffect(() => {
    if (isFord) {
      dispatch(
        getAllTemplatesProjectStages({
          enqueueSnackbar: () => {},
        })
      );
      dispatch(
        getAllProjectStages({
          enqueueSnackbar: () => {},
        })
      );
    }
    dispatch(projectStagesReset());
  }, [selectedCouncil]);

  useEffect(() => {
    if (projectListMeta?.total) {
      setPageSetting((prev) => ({
        ...prev,
        total: projectListMeta.total,
      }));
    }
  }, [projectListMeta]);

  const handleSearch = (query) => {
    let search = queryStringUrlReplacement(
      history.location.search,
      "searchKey",
      query
    );

    if (selectedCouncil.traction_tag === "ford") {
      setSelectedTab("all_projects");
      search = queryStringUrlReplacement(search, "mode", "all_projects");
      if (query.length > 0) {
        setTopPannelHidden(true);
      } else {
        setTopPannelHidden(false);
      }
    }
    if (selectedTab === "catalog_projects") {
      setSelectedTab("all_projects");
      search = queryStringUrlReplacement(search, "mode", "all_projects");
    }

    history.push({
      pathname: "/projects",
      search,
      state: {
        ...history.location?.state,
        searchKey: query,
      },
    });
  };

  const handlePagingChange = (page) => {
    if (pageSetting.current === page) return;
    const search = queryStringUrlReplacement(
      history.location.search,
      "page",
      page
    );

    history.push({
      pathname: "/projects",
      search,
      state: {
        ...history.location?.state,
        page,
      },
    });
  };
  const clearFilters = () => {
    let search = queryStringUrlReplacement(
      history.location.search,
      "sortAttribute",
      ""
    );
    search = queryStringUrlReplacement(search, "project_field_values", "");
    search = queryStringUrlReplacement(search, "area_ids", "");
    search = queryStringUrlReplacement(search, "ratings", "");
    search = queryStringUrlReplacement(search, "savings", "");
    search = queryStringUrlReplacement(search, "theme_ids", "");
    search = queryStringUrlReplacement(search, "stage_ids", "");
    search = queryStringUrlReplacement(search, "plant_region_ids", "");
    search = queryStringUrlReplacement(search, "project_owner_ids", "");
    search = queryStringUrlReplacement(search, "stage_template_ids", "");

    search = queryStringUrlReplacement(search, "skills_team", "");
    search = queryStringUrlReplacement(search, "founded_by", "");
    search = queryStringUrlReplacement(search, "fps_category", "");
    search = queryStringUrlReplacement(search, "program", "");
    search = queryStringUrlReplacement(search, "plant_ids", "");
    search = queryStringUrlReplacement(search, "original", "");
    const makePayload = defaultPayload(
      selectedCouncil?.traction_tag,
      selectedTab
    );
    history.push({
      pathname: "/projects",
      search,
      state: {
        ...history.location?.state,
        ...makePayload,
      },
    });
    setSelectedFilter(makePayload);
  };
  const handleSwitchChange = (value) => {
    const search = queryStringUrlReplacement(
      history.location.search,
      "mode",
      value
    );

    history.push({
      pathname: "/projects",
      search,
      state: {
        ...history.location?.state,
        mode: value,
      },
    });

    setSelectedTab(value);
    handlePagingChange(1);
  };

  const handleAddNewTaskClick = () => {
    history.push("/projects/new", { from: "/projects" });
  };

  const drawSortingIcon = useCallback(
    (col) => {
      if (!sortingConfig) return null;

      if (sortingConfig?.activeColumn === col) {
        return (
          <SortingIcon
            sortingConfig={sortingConfig}
            showLoading={showLoading}
          />
        );
      }
    },
    [sortingConfig, showLoading]
  );

  const makeSortingConfig = useCallback(
    (activeColumn) => {
      let config;

      if (sortingConfig?.order === "desc") {
        config = {
          activeColumn,
          order: "asc",
        };
      } else {
        config = {
          activeColumn,
          order: "desc",
        };
      }

      let search = queryStringUrlReplacement(
        history.location.search,
        "sortAttribute",
        config.activeColumn
      );
      search = queryStringUrlReplacement(search, "sortOrder", config.order);
      search = queryStringUrlReplacement(search, "page", 1);

      history.push({
        pathname: "/projects",
        search,
        state: {
          ...history.location.state,
          sortAttribute: config.activeColumn,
          sortOrder: config.order,
          page: 1,
        },
      });
    },
    [sortingConfig]
  );

  const checkIfClearAllNeeded = () => {
    return !!(
      selectedFilter.project_field_values?.length ||
      selectedFilter.topic_ids?.length ||
      selectedFilter.theme_ids?.length ||
      selectedFilter.area_ids?.length ||
      selectedFilter.stage_ids?.length ||
      selectedFilter.plant_ids?.length ||
      selectedFilter.ratings?.length ||
      selectedFilter.plant_region_ids?.length ||
      selectedFilter.project_owner_ids?.length ||
      selectedFilter.stage_template_ids?.length ||
      selectedFilter.savings?.length !== null
    );
  };

  const handleDownload = () => {
    if (downloadIsActive) {
      return;
    }

    setDownloadIsActive(true);

    const myProjects = `my_projects=${
      queryParamMode ? queryParamMode === "my_projects" : true
    }`;
    const userId = session.id;
    const user_id = `user_id=${userId}`;
    const data = {
      query: searchKey || "",
      search: {
        query: searchKey || "",
        user_id: queryParamMode === "my_projects" ? userId : null,
        catalog: queryParamMode === "catalog_projects" ? true : null,
        project_field_values: selectedFilter.project_field_values,
        topic_ids: selectedFilter.topic_ids?.map((opt) => opt.id) || [],
        theme_ids: selectedFilter.theme_ids?.map((opt) => opt.id) || [],
        area_ids: selectedFilter.area_ids?.map((opt) => opt.id) || [],
        stage_ids: selectedFilter.stage_ids?.map((opt) => opt.id) || [],
        plant_ids: selectedFilter.plant_ids?.map((opt) => opt.id) || [],
        savings: selectedFilter.savings,
        ratings: selectedFilter.ratings,
        original: selectedFilter.original,
        idea_stages: topPannelHidden
          ? undefined
          : queryParamMode === "project_stage_idea",
        approval_status:
          queryParamMode === "project_stage_idea"
            ? selectedFilter.approval_status
            : undefined,
        plant_region_ids:
          selectedFilter.plant_region_ids?.map((opt) => opt.id) || [],
        project_owner_ids:
          selectedFilter.project_owner_ids?.map((opt) => opt.id) || [],
        stage_template_id:
          selectedFilter.stage_template_ids?.map((opt) => opt.id)[0] || "",
      },
      selected_tab: searchKey?.length
        ? "all_projects"
        : queryParamMode || "my_projects",
      // page: pageSetting?.current || 1,
      sort_order: sortOrder || "desc",
      sort_attribute: sortAttribute || "activity",
    };

    httpPost({
      apiVersion: "v2",
      call: `projects/search?${myProjects}&${user_id}&items=${projectListMeta?.total}`,
      data,
    })
      .pipe()
      .subscribe((res) => {
        if (res?.response) {
          const csvDataCont = [
            [
              "Project Name",
              "Activity",
              "Date",
              "Owner",
              "Companies",
              "Progress",
              "Priority",
              "Status",
            ],
          ];
          const csvFordDataCont = [
            [
              "Project",
              "Plant",
              "Area",
              "Savings",
              "Rating",
              "Pillar",
              "Replications",
              "Stage",
              "Progress",
              "Owner",
              "Access",
              "Status",
            ],
          ];

          res.response.forEach((el) => {
            const owners = el?.project_members
              ? el?.project_members
                  .filter((m) => m.user_role === "owner")
                  .reduce(
                    (acc, elem) =>
                      `${acc} ${elem.user?.first_name} ${elem.user?.last_name},`,
                    ""
                  )
              : "-";

            if (isFord) {
              csvFordDataCont.push([
                el.name || "",
                el.plant?.name || "-",
                el.areas.map((a) => a.name).join(", ") || "-",
                `${el.savings}`,
                el.ratings || "-",
                el.themes?.map((t) => t.name).join(", ") || "-",
                el.replications,
                el.stage?.name || "-",
                `${Math.round(el.total_task_progress) || 0}%`,
                owners,
                el.access || "-",
                projectStatesText[el.status] || "-",
              ]);
            } else {
              csvDataCont.push([
                el.name || "",
                el.last_activity?.details || "No activity yet.",
                el.last_activity?.created_at || "-",
                owners,
                el.companies_count || 0,
                `${el.total_task_progress || 0}%`,
                el.priority || "-",
                projectStatesText[el.status] || "-",
              ]);
            }
          });

          setCsvData(isFord ? csvFordDataCont : csvDataCont);
          csvLinkRef.current.link.click();

          setDownloadIsActive(false);
        } else {
          setDownloadIsActive(false);
        }
      });
  };

  const renderTab = () => {
    if (selectedTab === "catalog_projects") {
      return (
        <div className={styles.catalogTabcontainer}>
          <CatalogTabComponent
            selectedTab={selectedTab}
            makeSortingConfig={makeSortingConfig}
            drawSortingIcon={drawSortingIcon}
            pageSetting={pageSetting}
            handlePagingChange={handlePagingChange}
            customStub={checkIfClearAllNeeded()}
          />
        </div>
      );
    }
    if (selectedTab === "project_stage_idea") {
      return (
        <div className={styles.catalogTabcontainer}>
          <IdeasTabComponent
            selectedTab={selectedTab}
            makeSortingConfig={makeSortingConfig}
            drawSortingIcon={drawSortingIcon}
            pageSetting={pageSetting}
            handlePagingChange={handlePagingChange}
            customStub={checkIfClearAllNeeded()}
            setSelectedFilter={setSelectedFilter}
            setPageSetting={setPageSetting}
            selectedFilter={selectedFilter.approval_status}
          />
        </div>
      );
    }

    return (
      <AllTabComponent
        selectedTab={selectedTab}
        makeSortingConfig={makeSortingConfig}
        drawSortingIcon={drawSortingIcon}
        pageSetting={pageSetting}
        handlePagingChange={handlePagingChange}
      />
    );
  };

  const makeNewProjectBtnRender = useMemo(() => {
    if (blockFunctionality) {
      return (
        <Tooltip
          title="You have View-Only access. To create a Project, please ask your Admin to upgrade your account to Standard access."
          placement="top"
        >
          <div className={styles.newTask}>
            <Button
              className={styles.newTask}
              btn={BtnType.DISABLED}
              icon="icn-add"
            >
              New Project
            </Button>
          </div>
        </Tooltip>
      );
    }

    return (
      <Button
        btn={BtnType.REGULAR}
        icon="icn-add"
        onClick={handleAddNewTaskClick}
      >
        New Project
      </Button>
    );
  }, [selectedCouncil, isAdmin, handleAddNewTaskClick, blockFunctionality]);

  return (
    <div className={styles.projectMainWrp}>
      <div className={styles.searchWrapper}>
        <ProjectsSearch onChange={handleSearch} defValue={searchKey || ""} />
      </div>
      <div className={styles.headerWrp}>
        <div className="d-flex align-items-center">
          <h4 className={styles.headerText}>Projects</h4>
          {!topPannelHidden && (
            <Switch
              customStyles={
                selectedTab === "catalog_projects" && {
                  backgroundColor: "#3994c1",
                  color: "#fff",
                }
              }
              tabs={
                selectedCouncil?.traction_tag !== "ford"
                  ? HeaderOptions
                  : fordHeaderOptions
              }
              onChange={handleSwitchChange}
              selected={selectedTab}
            />
          )}
        </div>
        <div className="d-flex align-items-center">
          {projectListMeta.total > 0 && (
            <>
              <Button
                btn={BtnType.FRAME_LESS}
                className={styles.download}
                icon="icn-export-button"
                onClick={handleDownload}
                disabled={downloadIsActive}
              >
                {downloadIsActive ? (
                  <LoaderWithDots text="Preparing Report" />
                ) : (
                  "Download Report"
                )}
              </Button>
              <CSVLink
                data={csvData}
                filename="Traction-Projects.csv"
                style={{ display: "none" }}
                ref={csvLinkRef}
              />
            </>
          )}
          {!projectLoading && (
            <div className="mr-4 ml-4">
              {projectListMeta?.total || 0} Results
            </div>
          )}
          {selectedCouncil?.name === "GSK" ? (
            makeNewProjectBtnRender
          ) : (
            <NewProjectV3
              isAdmin={isAdmin}
              blockFunctionality={blockFunctionality}
              wizardType="main"
            />
          )}
        </div>
      </div>
      {isFord && !topPannelHidden && (
        <>
          <FiltersRow
            setFilterOptionSearchResult={setFilterOptionSearchResult}
            options={filterOptions}
            filterOptionSearchResult={filterOptionSearchResult}
            checkIfClearAllNeeded={checkIfClearAllNeeded}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            requestTheData={requestTheData}
            selectedTab={selectedTab}
            clearFilters={clearFilters}
          />
        </>
      )}
      {projectLoading ? <Loading /> : <>{renderTab()}</>}
    </div>
  );
};

export default React.memo(ProjectsMainComponent);

import { layoutType } from "modules/companies/companyMain/companyMain.constant";

export const PAGINATION_DEFAULTS = {
  pageSize: 10,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
};

export const relationshipOptions = [
  { name: "No Relationship", id: "no_relationship" },
  { name: "Existing Relationship", id: "existing_relationship" },
];

export const dropdownOptions = [
  {
    val: 1,
    text: "Share",
  },
  {
    val: 2,
    text: "Add to project",
  },
];

export const patientOrgLayoutList = [
  {
    value: layoutType.card,
    icon: "icn-view-card",
    title: "",
  },
  {
    value: layoutType.list,
    icon: "icn-view-list",
    title: "list",
  },
];

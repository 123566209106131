import {
  GET_TRACTION_COMPANIES_ATTEMPT,
  GET_TRACTION_COMPANIES_SUCCESS,
  CATCH_ERROR_SUCCESS,
  PUT_TRACTION_COMPANIES_SUCCESS,
  DELETE_TRACTION_COMPANIES_ATTEMPT,
  DELETE_TRACTION_COMPANIES_SUCCESS,
  PUT_TRACTION_COMPANIES_HIDE_ATTEMPT,
  PUT_TRACTION_COMPANIES_HIDE_SUCCESS,
  GET_ALL_COMPANIES_EXPORT_ATTEMPT,
  GET_ALL_COMPANIES_EXPORT_SUCCESS,
  PUT_TRACTION_COMPANIES_UNHIDE_SUCCESS,
  PUT_TRACTION_COMPANIES_UNHIDE_ATTEMPT,
  POST_SEARCH_COMPANIES_BY_QUERY_ATTEMPT,
  POST_SEARCH_COMPANIES_BY_QUERY_SUCCESS,
  POST_TRACTION_COMPANY_ATTEMPT,
  POST_TRACTION_COMPANY_SUCCESS,
  POST_SEARCH_COMPANIES_SF_EXPORT_ATTEMPT,
  POST_SEARCH_COMPANIES_SF_EXPORT_SUCCESS,
} from "./tractionCompanies.actions";

const initState = {
  tractionCompanies: [],
  loading: false,
  searchResultNr: 0,
  searchResultPageNr: 0,
  exportedCompanies: [],
  exportLoading: false,
};
const tractionCompaniesReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case POST_TRACTION_COMPANY_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_TRACTION_COMPANY_SUCCESS: {
      return {
        ...state,
        tractionCompanies: [...state.tractionCompanies, payload.response],
        loading: false,
      };
    }

    case POST_SEARCH_COMPANIES_BY_QUERY_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_SEARCH_COMPANIES_BY_QUERY_SUCCESS: {
      return {
        ...state,
        tractionCompanies: payload.response,
        loading: false,
        searchResultNr: payload.xhr.getResponseHeader("total-count"),
        searchResultPageNr: payload.xhr.getResponseHeader("total-pages"),
      };
    }

    case POST_SEARCH_COMPANIES_SF_EXPORT_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case POST_SEARCH_COMPANIES_SF_EXPORT_SUCCESS: {
      return {
        ...state,
        tractionCompanies: payload.response,
        loading: false,
        searchResultNr: payload.xhr.getResponseHeader("total-count"),
        searchResultPageNr: payload.xhr.getResponseHeader("total-pages"),
      };
    }

    case GET_ALL_COMPANIES_EXPORT_ATTEMPT: {
      return {
        ...state,
        exportLoading: true,
      };
    }

    case GET_ALL_COMPANIES_EXPORT_SUCCESS: {
      return {
        ...state,
        exportedCompanies: payload.response,
        exportLoading: false,
      };
    }

    case GET_TRACTION_COMPANIES_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_TRACTION_COMPANIES_SUCCESS: {
      return {
        ...state,
        tractionCompanies: payload.response,
        loading: false,
        searchResultNr: payload.xhr.getResponseHeader("total-count"),
        searchResultPageNr: payload.xhr.getResponseHeader("total-pages"),
      };
    }

    case PUT_TRACTION_COMPANIES_UNHIDE_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case PUT_TRACTION_COMPANIES_UNHIDE_SUCCESS: {
      const newCompanies = state.tractionCompanies.map((com) => {
        if (com.id === payload.response.id) {
          return payload.response;
        }
        return com;
      });

      return {
        ...state,
        tractionCompanies: newCompanies,
        loading: false,
      };
    }

    case PUT_TRACTION_COMPANIES_HIDE_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case PUT_TRACTION_COMPANIES_HIDE_SUCCESS: {
      const newCompanies = state.tractionCompanies.map((com) => {
        if (com.id === payload.response.id) {
          return payload.response;
        }
        return com;
      });

      return {
        ...state,
        tractionCompanies: newCompanies,
        loading: false,
      };
    }
    case PUT_TRACTION_COMPANIES_SUCCESS: {
      const newCompanies = state.tractionCompanies.map((com) => {
        if (com.id === payload.response.id) {
          return payload.response;
        }
        return com;
      });

      return {
        ...state,
        tractionCompanies: newCompanies,
      };
    }
    case DELETE_TRACTION_COMPANIES_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_TRACTION_COMPANIES_SUCCESS: {
      const filteredCompanies = state.tractionCompanies.filter(
        (com) => com.id !== payload.response.id,
      );
      return {
        ...state,
        tractionCompanies: filteredCompanies,
        loading: false,
      };
    }
    case CATCH_ERROR_SUCCESS: {
      return {
        ...state,
        loading: false,
        exportLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default tractionCompaniesReducer;

export const sortChartListByCriteria = (config, companies) => {
  if (config.order) {
    const { activeColumn, order } = config;

    // use slice() to avoid mutation in the top source array

    if (
      activeColumn === "council_company_score" ||
      activeColumn === "years_in_operation" ||
      activeColumn === "total_fundings_sum" ||
      activeColumn === "council_relationship_stage_position"
    ) {
      return companies.slice().sort((a, b) => {
        const A = a[config.activeColumn] || 0;
        const B = b[config.activeColumn] || 0;

        return order === "asc" ? A - B : B - A;
      });
    }

    const o = order === "asc" ? 1 : -1;
    // use slice() to avoid mutation in the top source array

    if (config.child) {
      return companies.slice().sort((a, b) => {
        const parentAExists = a[config.activeColumn];
        const parentBExists = b[config.activeColumn];
        const A = parentAExists
          ? parentAExists[config.child].toLowerCase()
          : "";
        const B = parentBExists
          ? parentBExists[config.child].toLowerCase()
          : "";

        return A.localeCompare(B) * o;
      });
    }

    return companies.slice().sort((a, b) => {
      const A = a[config.activeColumn]
        ? a[config.activeColumn].toLowerCase()
        : "";
      const B = b[config.activeColumn]
        ? b[config.activeColumn].toLowerCase()
        : "";

      return A.localeCompare(B) * o;
    });
  }

  return companies;
};

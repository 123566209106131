import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import styles from "modules/companies/companyMain/rightWindow/rightWindow.module.scss";
import NewCompaniesList from "modules/companies/companyMain/rightWindow/newCompaniesList";
import { useCompanyContext } from "modules/companies/companyMain/providers/companyDataProvider";

const CompaniesLayout1 = () => {
  const {
    companiesReducer: {
      companyMainReducer: {
        selectedCompanies,
      },
    },
  } = useSelector((state) => state);
  const {
    selectLayout,
    downloadPdfIsActive,
    memoizedCompanies,
    handleCrunchBase,
    handleRelationShipChange,
    handleCompanySelect,
    selectModeIsActive,
  } = useCompanyContext();

  const location = useLocation();

  if (selectLayout !== 1) return null;

  if (!memoizedCompanies?.length) {
    if (location.state?.query?.length) return null;

    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <span className={styles.noResults}>
          No results found in Traction
        </span>
        <span
          onClick={() => handleCrunchBase(true)}
          className={`${styles.noResultsUrl} mt-3`}
        >
          Search Crunchbase
        </span>
      </div>
    )
  }

  return (
    <NewCompaniesList
      selectModeIsActive={selectModeIsActive}
      companies={memoizedCompanies}
      handleRelationShipChange={handleRelationShipChange}
      handleCompanySelect={handleCompanySelect}
      selectedCompanies={selectedCompanies}
      downloadPdfIsActive={downloadPdfIsActive}
    />
  );
};

export default memo(CompaniesLayout1);

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { ModalFooter, ModalBody } from "../../../application/modal";
import {
  Button,
  BtnType,
  Label,
  MultiSelect,
  CheckBox,
} from "../../primitives";

import styles from "./styles.module.scss";
import { useModelPopup } from "../../../common/hooks";
import { themesSearchAttempt } from "../themesMainPage/themesMain.action";
import { themesHomepagePostAttempt } from "./themesHomePage.action";

const NewHomepageThemeComponent = () => {
  const dispatch = useDispatch();
  const popup = useModelPopup();

  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [currentQuery, setQuery] = useState("");
  const [options, setOptions] = useState([]);

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },

    themesReducer: {
      themesMainReducer: { themesData },
      themesHomepageReducer: { themesHomepageList },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil) {
      const data = {
        sort_order: "asc",
        sort_attribute: "created_at",
        view: "dropdown",
        search: {
          query: "",
        },
      };

      dispatch(themesSearchAttempt({ page: 1, items: 50, data }));
    }
  }, [selectedCouncil]);

  const handleCancelClick = (e) => {
    popup.hide();
  };

  const handleSaveClick = (e) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleOnSubmit = (value) => {
    dispatch(
      themesHomepagePostAttempt({
        enqueueSnackbar,
        themeId: value.id[0].id,
        default_theme_home: value.defaultForAll,
      })
    );

    popup.hide();
  };
  const handleSearchUpdate = (searchObj) => {
    setQuery(searchObj.search);
    const data = {
      sort_order: "asc",
      sort_attribute: "created_at",
      search: {
        query: searchObj.search,
      },
    };

    dispatch(themesSearchAttempt({ page: 1, data }));
  };

  useEffect(() => {
    if (themesData && themesData?.themes?.length > 0) {
      const filteredOptions = themesData?.themes.filter((option) => {
        const isAlreadySelected = themesHomepageList?.find((item) => {
          return item?.id === option.id;
        });
        return !isAlreadySelected;
      });
      setOptions(filteredOptions);
    }
  }, [themesData?.themes, themesHomepageList]);

  return (
    <>
      <ModalBody>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{ id: "" }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleOnSubmit(values);
          }}
        >
          {({ handleSubmit, setFieldValue, values, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.editStageFormWrp}>
                <Label>
                  {selectedCouncil?.settings?.themes_display_name_singular ||
                    "Theme"}
                </Label>
                <MultiSelect
                  onChange={(val) => {
                    setFieldValue("id", val);
                  }}
                  placeholder={`Choose ${
                    selectedCouncil?.settings?.themes_display_name_singular ||
                    "Theme"
                  }`}
                  labelField="name"
                  valueField="id"
                  searchBy="name"
                  options={options}
                  formProps={formikprops}
                  multi={false}
                  searchFn={(args) => {
                    if (currentQuery === args.state.search) {
                      return;
                    }
                    handleSearchUpdate(args.state);
                  }}
                />
              </div>
              {selectedCouncil.traction_tag === "ford" &&
              session.council_role.indexOf("admin") > 0 ? (
                <div className="form-row d-flex align-items-center ml-1 mb-2">
                  <CheckBox
                    onChange={(value) => setFieldValue("defaultForAll", value)}
                  />
                  <Label
                    onClick={() =>
                      setFieldValue("defaultForAll", !values.defaultForAll)
                    }
                    className="cursor-pointer"
                  >
                    Make default for everyone
                  </Label>
                </div>
              ) : null}
            </form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button btn={BtnType.REGULAR} onClick={handleSaveClick}>
          Add{" "}
          {selectedCouncil?.settings?.themes_display_name_singular || "Theme"}
        </Button>
      </ModalFooter>
    </>
  );
};

export default NewHomepageThemeComponent;

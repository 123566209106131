import React from "react";
import { format, parseISO } from "date-fns";

const DateComponent = ({ date }) => (
  <div>
    {date
      ? format(
          date.indexOf("/") ? new Date(date) : parseISO(date),
          "MMM do, yyyy"
        )
      : "-"}
  </div>
);

export default React.memo(DateComponent);

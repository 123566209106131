import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { appModelSettingSet } from "application/app.action";

import mainStyles from "../../../ideasWrapper.module.scss";
import styles from "./companies.module.scss";
import AddCompanyIcon from "../../../../../assets/icn-table-add.svg";
import { Icon, smSize } from "../../../../../common/icon";
import {
  ideaCompaniesAddAttempt,
  ideaCompanyDeleteAttempt,
  ideaProductDelete,
} from "../../../idea.action";
import { CompanyAvatar } from "../../../../primitives";
import MixedAddCompanyOrProducts from "../../../../../common/components/mixedSearch/mixedAddCompanyOrProducts";

const IdeaProfileCompanies = ({ selectedIdea, canEdit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    appReducer: { modalSetting },
  } = useSelector((state) => state);
  const handleAddCompanyCallback = (selectedCompanies) => {
    const payload = {
      related_companies: selectedCompanies.map((c) => {
        if (c.is_product) {
          return {
            council_product_id: c.id,
            idea_id: selectedIdea.id,
            is_product: true,
          };
        }

        return {
          company_id: c.id,
          idea_id: selectedIdea.id,
        };
      }),
      cb: () => {
        enqueueSnackbar(`Successfully added companies or products`, {
          variant: "success",
        });

        dispatch(appModelSettingSet({ ...modalSetting, show: false }));
      },
      enqueueSnackbar,
    };

    dispatch(ideaCompaniesAddAttempt(payload));
  };

  const handleAddCompany = () => {
    const existingItems = [
      ...selectedIdea?.ideas_related_companies.map((elem) => ({
        ...elem,
        id: elem.company.company_id,
      })),
      ...(selectedIdea?.products.map((p) => ({ ...p, is_product: true })) ||
        []),
    ];

    const customSettings = {
      title: "Add company or product",
      show: true,
      height: "300",
      width: "540",
      component: (
        <MixedAddCompanyOrProducts
          handleSave={handleAddCompanyCallback}
          placeholder="Type to search or select from below"
          label="Companies or Products"
          existingItems={existingItems}
        />
      ),
    };

    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  const deleteCompany = (c) => {
    if (c.is_product) {
      const payload = {
        product_id: c.id,
        idea_id: selectedIdea.id,
        enqueueSnackbar,
        cb: () => {
          enqueueSnackbar("Successfully delete product from idea", {
            variant: "success",
          });
        },
      };

      dispatch(ideaProductDelete(payload));
      return;
    }

    dispatch(
      ideaCompanyDeleteAttempt({
        id: c.idea_related_company_id,
        company_id: c.company.id,
        enqueueSnackbar,
        cb: () => {
          enqueueSnackbar("Successfully deleted company.", {
            variant: "success",
          });
        },
      })
    );
  };

  const handleCompanyRedirect = (c) => {
    if (c.isProduct) {
      history.push(
        `/companies/${c.company.slug || c.company.company_id}/products/${
          c.slug || c.id
        }`
      );
      return;
    }
    history.push(`/companies/${c.slug || c.company_id}`);
  };

  const getSourceData = useMemo(() => {
    return [
      ...(selectedIdea?.ideas_related_companies || []),
      ...(selectedIdea?.products.map((p) => ({ ...p, isProduct: true })) || []),
    ];
  }, [selectedIdea]);

  return (
    <div className="d-flex flex-column mt-4">
      <div
        className={`d-flex align-items-center justify-content-between ${mainStyles.blockHeader}`}
      >
        <span className={mainStyles.blockTitle}>Related Companies</span>
        {canEdit && (
          <img
            className={mainStyles.addIcon}
            onClick={handleAddCompany}
            src={AddCompanyIcon}
            alt="Add new company"
          />
        )}
      </div>
      <div className={`${styles.companiesContent} ${mainStyles.blockContent}`}>
        {getSourceData?.length === 0 ? (
          <div className={mainStyles.stub}>No companies yet.</div>
        ) : (
          getSourceData.map((c) => (
            <div key={c.company?.company_id || c.id}>
              {c.isProduct ? (
                <div key={c.id} className={styles.company}>
                  <div
                    onClick={() => handleCompanyRedirect(c)}
                    className={`d-flex align-items-center ${
                      canEdit ? "w-75" : "w-100"
                    }`}
                  >
                    <div className={styles.companyIcon}>
                      <div className={styles.iconCon}>
                        <CompanyAvatar
                          imgSrc={c.company.logo}
                          name={c.name}
                          hasBorderRadius
                        />
                      </div>
                    </div>
                    <span className={styles.companyName}>{c.name}</span>
                    <span className="small text-muted ml-4">Product</span>
                  </div>
                  {canEdit && (
                    <Icon
                      {...smSize}
                      icon="icn-button-delete"
                      className={styles.deleteIcon}
                      onClick={() => deleteCompany(c)}
                    />
                  )}
                </div>
              ) : (
                <div className={styles.company}>
                  <div
                    onClick={() => handleCompanyRedirect(c.company)}
                    className={`d-flex align-items-center ${
                      canEdit ? "w-75" : "w-100"
                    }`}
                  >
                    <div className={styles.companyIcon}>
                      <div className={styles.iconCon}>
                        <CompanyAvatar
                          imgSrc={c.company.logo}
                          name={c.company.name}
                          hasBorderRadius
                        />
                      </div>
                    </div>
                    <span className={styles.companyName}>{c.company.name}</span>
                  </div>
                  {canEdit && (
                    <Icon
                      {...smSize}
                      icon="icn-button-delete"
                      className={styles.deleteIcon}
                      onClick={() => deleteCompany(c)}
                    />
                  )}
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default React.memo(IdeaProfileCompanies);

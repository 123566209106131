import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { getIdeasStatusesCounter } from "../../../idea.action";
import styles from "./listReportBlock.module.scss";

const IdeaReportBlock = ({ onItemClick }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    ideaReducer: { ideasStatusesCounter, ideasPageMeta },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const [activeStatus, setActiveStatus] = useState(null);
  const isKyndryl = selectedCouncil?.name === "Kyndryl";

  useEffect(() => {
    if (location.search) {
      const params = Object.fromEntries(new URLSearchParams(location.search));

      if (params.filter_by?.length && params.filter_value?.length) {
        setActiveStatus(params.filter_value);
      } else {
        setActiveStatus(null);
      }
    } else {
      setActiveStatus(null);
    }

    return () => null;
  }, [location]);

  const handleItemCLick = (filter) => {
    if (onItemClick) {
      onItemClick(filter);
    }
  };

  useEffect(() => {
    dispatch(getIdeasStatusesCounter({ enqueueSnackbar }));
  }, []);

  return (
    <div className={classnames(styles.container, "row")}>
      <div className="col-12 col-md-3 d-flex justify-content-center p-0">
        <div className={styles.item}>
          <div className={styles.itemLabel} />
          {ideasPageMeta?.total ? (
            <div className={classnames(styles.itemValue, "border-0", "h3 m-0")}>
              <span>{ideasPageMeta?.total}</span>
              <span className="font-weight-normal">&nbsp;Ideas</span>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12 col-md-9 d-flex justify-content-center flex-column flex-md-row justify-content-md-start p-0">
        <div
          className={`${styles.item} ${
            activeStatus
              ? activeStatus === "submitted"
                ? ""
                : styles.unselected
              : ""
          } ${isKyndryl ? styles.kyndrylItem : ""}`}
          onClick={() => handleItemCLick("submitted")}
          role="button"
          aria-hidden="true"
          title="NEW IDEAS"
        >
          <div className={styles.itemLabel}>NEW IDEAS</div>
          <div className={styles.itemValue}>
            {ideasStatusesCounter.total_new_ideas}
          </div>
        </div>

        {isKyndryl ? (
          <div
            className={`${styles.item} ${
              activeStatus
                ? activeStatus === "stopped"
                  ? ""
                  : styles.unselected
                : ""
            } ${styles.kyndrylItem}`}
            onClick={() => handleItemCLick("stopped")}
            role="button"
            aria-hidden="true"
            title="STOPPED"
          >
            <div className={styles.itemLabel}>STOPPED</div>
            <div className={`${styles.itemValue} ${styles.stopped}`}>
              {ideasStatusesCounter.total_stopped_ideas}
            </div>
          </div>
        ) : (
          <div
            className={`${styles.item} ${
              activeStatus
                ? activeStatus === "approved"
                  ? ""
                  : styles.unselected
                : ""
            }`}
            onClick={() => handleItemCLick("approved")}
            role="button"
            aria-hidden="true"
            title="APPROVED"
          >
            <div className={styles.itemLabel}>APPROVED</div>
            <div className={`${styles.itemValue} ${styles.approved}`}>
              {ideasStatusesCounter.total_approved_ideas}
            </div>
          </div>
        )}

        <div
          className={`${styles.item} ${
            activeStatus
              ? activeStatus === "paused"
                ? ""
                : styles.unselected
              : ""
          } ${isKyndryl ? styles.kyndrylItem : ""}`}
          onClick={() => handleItemCLick("paused")}
          role="button"
          aria-hidden="true"
          title={
            selectedCouncil?.traction_tag === "home_x" ? "PARKED" : "PAUSED"
          }
        >
          <div className={styles.itemLabel}>
            {selectedCouncil?.traction_tag === "home_x" ? "PARKED" : "PAUSED"}{" "}
          </div>
          <div className={`${styles.itemValue} ${styles.paused}`}>
            {ideasStatusesCounter.total_paused_ideas}
          </div>
        </div>

        {isKyndryl ? (
          <div
            className={`${styles.item} ${
              activeStatus
                ? activeStatus === "approved"
                  ? ""
                  : styles.unselected
                : ""
            } ${styles.kyndrylItem}`}
            onClick={() => handleItemCLick("approved")}
            role="button"
            aria-hidden="true"
            title="APPROVED"
          >
            <div className={styles.itemLabel}>APPROVED</div>
            <div className={`${styles.itemValue} ${styles.approved}`}>
              {ideasStatusesCounter.total_approved_ideas}
            </div>
          </div>
        ) : (
          <div
            className={`${styles.item} ${
              activeStatus
                ? activeStatus === "stopped"
                  ? ""
                  : styles.unselected
                : ""
            }`}
            onClick={() => handleItemCLick("stopped")}
            role="button"
            aria-hidden="true"
            title="STOPPED"
          >
            <div className={styles.itemLabel}>STOPPED</div>
            <div className={`${styles.itemValue} ${styles.stopped}`}>
              {ideasStatusesCounter.total_stopped_ideas}
            </div>
          </div>
        )}

        {isKyndryl && (
          <>
            <div
              className={`${styles.item} ${
                activeStatus
                  ? activeStatus === "scouting"
                    ? ""
                    : styles.unselected
                  : ""
              } ${styles.kyndrylItem}`}
              onClick={() => handleItemCLick("scouting")}
              role="button"
              aria-hidden="true"
              title="SCOUTING"
            >
              <div className={styles.itemLabel}>SCOUTING</div>
              <div className={`${styles.itemValue} ${styles.scouting}`}>
                {ideasStatusesCounter.total_scouting_ideas || 0}
              </div>
            </div>

            <div
              className={`${styles.item} ${
                activeStatus
                  ? activeStatus === "solved"
                    ? ""
                    : styles.unselected
                  : ""
              } ${styles.kyndrylItem}`}
              onClick={() => handleItemCLick("solved")}
              role="button"
              aria-hidden="true"
              title="SOLVED"
            >
              <div className={styles.itemLabel}>SOLVED</div>
              <div className={`${styles.itemValue} ${styles.solved}`}>
                {ideasStatusesCounter.total_solved_ideas || 0}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(IdeaReportBlock);

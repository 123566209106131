export const PROJECT_USERS_GET_ATTEMPT = "PROJECT_USERS_GET_ATTEMPT";
export const projectUsersGet = (payload) => ({
  type: PROJECT_USERS_GET_ATTEMPT,
  payload,
});
export const PROJECT_USERS_GET_SUCCESS = "PROJECT_USERS_GET_SUCCESS";
export const projectUsersGetSuccess = (payload) => ({
  type: PROJECT_USERS_GET_SUCCESS,
  payload,
});

export const PROJECT_USERS_ADD_ATTEMPT = "PROJECT_USERS_ADD_ATTEMPT";
export const projectUsersAdd = (payload) => ({
  type: PROJECT_USERS_ADD_ATTEMPT,
  payload,
});
export const PROJECT_EXTERNAL_USER_ADD_ATTEMPT = "PROJECT_EXTERNAL_USER_ADD_ATTEMPT";
export const projectExternalUsersAdd = (payload) => ({
  type: PROJECT_EXTERNAL_USER_ADD_ATTEMPT,
  payload,
});
export const PROJECT_USERS_ADD_SUCCESS = "PROJECT_USERS_ADD_SUCCESS";
export const projectUsersAddSuccess = (payload) => ({
  type: PROJECT_USERS_ADD_SUCCESS,
  payload,
});

export const PROJECT_MULTIPLE_USERS_ADD_ATTEMPT = "PROJECT_MULTIPLE_USERS_ADD_ATTEMPT";
export const projectMultipleUsersAdd = (payload) => ({
  type: PROJECT_MULTIPLE_USERS_ADD_ATTEMPT,
  payload,
});
export const PROJECT_MULTIPLE_USERS_ADD_SUCCESS = "PROJECT_MULTIPLE_USERS_ADD_SUCCESS";
export const projectMultipleUsersAddSuccess = (payload) => ({
  type: PROJECT_MULTIPLE_USERS_ADD_SUCCESS,
  payload,
});

import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import { Button, BtnType, TextBox, TextArea, Label, MultiSelect, CheckBox, CheckType } from "../../../../primitives";
import { initModel, validation } from "./constants";
import { httpGet, httpPost, httpPut } from "../../../../../common/httpCall";
import { METRICS_NAMES_ENUM, PROJECT_WIZARD_FIELD_TYPES } from "application/app.constants";
import LoadingComponent from "modules/primitives/loading/loading.component";
import UpdateStepPosition from "modules/admin/adminProjects/adminProjectWizard/addEditWizardStep/updateStepPosition";
import { updateProjectWizardSteps } from "modules/admin/adminProjects/adminProjects.actions";
import ProjectName from "../../../../../common/components/newProjectSharedComponents/projectName";

const makeAllFieldsArray = (data) => {
  return data
    .filter(field => field.display && field.field_type !== 'project_name')
    .reduce((acc, elem) => {
      if (elem.field_type === 'project_metric') {
        if (elem.id) {
          const metric_attribute = elem.metric_attribute || elem.project_metric_attribute;
          return [...acc, {
            ...elem,
            isMetricField: true,
            field_type: 'project_metric',
            metric_attribute,
            name: METRICS_NAMES_ENUM[metric_attribute] || `No name found for ${metric_attribute} -> please add it in app.constants.js`,
          }];
        }

        const metrics = elem.project_metrics?.map(el => {
          const metric_attribute = el.metric_attribute || el.project_metric_attribute;
          return {
            ...el,
            isMetricField: true,
            field_type: 'project_metric',
            metric_attribute,
            name: METRICS_NAMES_ENUM[metric_attribute] || `No name found for ${metric_attribute} -> please add it in app.constants.js`,
          }
        }) || [];

        return [...acc, ...metrics];
      }

      if (elem.field_type === 'project_field') {
        if (elem.project_field_templates?.length) {
          const fields = elem.project_field_templates?.map(el => ({
            ...el,
            field_type: 'project_field',
            field_render_type: el.field_type,
            isProjectField: true,
            name: el.field_name,
          })) || [];
          return [...acc, ...fields];
        }

        return [...acc, {
          ...elem,
          name: elem.field_name,
          isProjectField: true,
        }];
      }

      return [
        ...acc,
        {
          ...elem,
          name: PROJECT_WIZARD_FIELD_TYPES[elem.field_type]?.fieldTitle || `No name found for ${elem.field_type} -> please add it in app.constants.js`,
        }
      ]
    }, [])
    .map((el) => ({
      ...el,
      id: el.id || el.metric_attribute || el.field_type,
    }));
}

const AddEditWizardStepModal = ({ data, wizardId, open, handleDismiss, existingWizardsNr }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fields, setFields] = useState([]);
  const [model, setModel] = useState({
    ...initModel,
    position: existingWizardsNr + 1,
  });
  const [originalWizardOptions, setOriginalWizardOptions] = useState([]);

  useEffect(() => {
    if (data) {
      const makeFields = makeAllFieldsArray(data.projects_wizard_options);
      setOriginalWizardOptions(makeFields);
      setModel({
        ...initModel,
        ...data,
        projects_wizard_options: makeFields,
      });
    }
  }, [data]);

  useEffect(() => {
    httpGet({
      call: `projects_wizard_options?projects_wizard_setup_id=${wizardId}`
    }).subscribe(res => {
      const filtered = makeAllFieldsArray(res.response || []);
      setFields(filtered);
    })
  }, []);

  const onSubmitForm = (values) => {
    setIsSubmitting(true);
    const arr1 = originalWizardOptions || [];
    const arr2 = values.projects_wizard_options || [];
    const arr2Map = new Map(arr2.map(item => [item.id, item]));
    const combined = [
      ...arr1.map(item => {
        const arr2Item = arr2Map.get(item.id);
        return arr2Item
          ? { ...item, ...arr2Item }
          : { ...item, _destroy: true };
      }),
      ...arr2.filter(item => !arr1.some(({ name }) => name === item.name))
    ];
    const result = combined.map(el => {
      let props = {
        display: true,
        field_type: el.field_type,
        position: el.position,
        required: el.required || false,
        _destroy: el._destroy,
      };

      if (el.created_at) {
        props = {
          ...props,
          id: el.id
        };
      }

      if (el.isProjectField) {
        return {
          project_field_template_id: el.project_field_template_id || el.id,
          ...props,
        }
      }

      if (el.isMetricField) {
        return {
          project_metric_attribute: el.metric_attribute,
          ...props
        }
      }

      return props;
    });

    const payload = {
      name: values.name,
      description: values.description,
      position: values.position,
      projects_wizard_setup_id: wizardId,
      projects_wizard_options_attributes: result,
    }

    if (data) {
      httpPut({
        call: `projects_wizard_steps/${data.id}`,
        data: payload,
      }).subscribe({
        next: (res) => {
          enqueueSnackbar(`Successfully edited wizard step`, {
            variant: "success",
          });
          setIsSubmitting(false);
          dispatch(
            updateProjectWizardSteps({
              response: res.response,
              wizardId,
            })
          );
          handleDismiss();
        },
        error: (err) => {
          enqueueSnackbar(`Error -> Code: ${err.status}, message: ${err.response?.message || "Something went wrong"}`, {
            variant: "error",
          });
          setIsSubmitting(false);
        },
      });

    } else {
      httpPost({
        call: 'projects_wizard_steps',
        data: payload
      }).subscribe({
        next: (res) => {
          enqueueSnackbar(`Successfully added wizard step`, {
            variant: 'success',
          });
          setIsSubmitting(false);
          dispatch(updateProjectWizardSteps({
            response: res.response,
            wizardId,
            addNewStep: true
          }));
          handleDismiss();
        },
        error: (err) => {
          enqueueSnackbar(`Error -> Code: ${err.status}, message: ${err.response?.message || "Something went wrong"}`, {
            variant: "error",
          });
          setIsSubmitting(false);
        },
      });
    }
  };

  const handlePositionUpdate = (item) => {
    httpPut({
      call: `projects_wizard_options/${item.id}`,
      data: {
        ...item,
        position: item.position ? Number(item.position) : 0,
        required: item.required,
      }
    }).subscribe(res => {
      enqueueSnackbar('Successfully updated field', {
        variant: 'success',
      });
      dispatch(updateProjectWizardSteps({
        response: res.response,
        wizardId,
      }));
      const makeFields = makeAllFieldsArray(res.response.projects_wizard_options);
      setModel({
        ...model,
        ...data,
        projects_wizard_options: makeFields,
      });
      setOriginalWizardOptions(makeFields);
    })
  };

  const makeSimpleFields = (formikProps) => {
    const simpleFields = fields.filter(field => !field.isProjectField && !field.isMetricField);
    const values = formikProps.values.projects_wizard_options.filter(f => !f.isProjectField && !f.isMetricField);
    const otherFields = formikProps.values.projects_wizard_options.filter(f => f.isProjectField || f.isMetricField);

    return (
      <>
        <Label>Step Sections</Label>
        <MultiSelect
          name="projects_wizard_options"
          values={values}
          labelField="name"
          valueField="id"
          onChange={(evt) => formikProps.setFieldValue("projects_wizard_options", [...evt, ...otherFields])}
          options={simpleFields}
          multi
          placeholder="Step Sections"
          dropdownPosition="top"
        />
        {formikProps.errors?.projects_wizard_options && (
          <div className="small text-danger d-flex align-items-end justify-content-end">
            {formikProps.errors.projects_wizard_options}
          </div>
        )}
      </>
    )
  }

  const makeProjectFields = (formikProps) => {
    const projectFields = fields.filter(field => field.isProjectField);

    if (projectFields.length === 0) return null;

    const values = formikProps.values.projects_wizard_options.filter(f => f.isProjectField);
    const otherFields = formikProps.values.projects_wizard_options.filter(f => !f.isProjectField);

    return (
      <div className="mt-3">
        <Label>Project Fields</Label>
        <MultiSelect
          name="projects_wizard_options"
          values={values}
          labelField="name"
          valueField="id"
          onChange={(evt) => formikProps.setFieldValue("projects_wizard_options", [...otherFields, ...evt])}
          options={projectFields}
          multi
          placeholder="Project Fields"
          dropdownPosition="top"
        />
      </div>
    )
  }

  const makeProjectMetrics = (formikProps) => {
    const projectMetrics = fields.filter(field => field.isMetricField);

    if (projectMetrics.length === 0) return null;

    const values = formikProps.values.projects_wizard_options.filter(f => f.isMetricField);
    const otherFields = formikProps.values.projects_wizard_options.filter(f => !f.isMetricField);

    return (
      <div className="mt-3">
        <Label>Project Metrics</Label>
        <MultiSelect
          name="projects_wizard_options"
          values={values}
          labelField="name"
          valueField="id"
          onChange={(evt) => formikProps.setFieldValue("projects_wizard_options", [...otherFields, ...evt])}
          options={projectMetrics}
          multi
          placeholder="Project Metrics"
          dropdownPosition="top"
        />
      </div>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleDismiss}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={model}
          validationSchema={validation}
          onSubmit={(values, { setSubmitting }) => {
            onSubmitForm(values);
            setSubmitting(false);
          }}
        >
          {({ setFieldValue, values, ...formikprops }) => {
            return (
              <form onSubmit={formikprops.handleSubmit}>
                <h3>{data ? 'Edit Step' : 'Add new Step'}</h3>
                <div>
                  <label>Step Name</label>
                  <TextBox
                    name="name"
                    placeholder="Step Name"
                    formProps={{ ...formikprops, values, setFieldValue }}
                    className="w-100"
                    value={values.name}
                  />
                </div>
                <div>
                  <label>Step Position</label>
                  <TextBox
                    type="number"
                    name="position"
                    placeholder="Step Position"
                    formProps={{ ...formikprops, values, setFieldValue }}
                    className="w-100"
                    value={values.position}
                  />
                </div>
                <div>
                  <label>Step Instructions</label>
                  <TextArea
                    name="description"
                    placeholder="Step Instructions"
                    formProps={{ ...formikprops, values, setFieldValue }}
                    className="w-100"
                    value={values.description}
                  />
                </div>
                <div className="mb-5">
                  {
                    fields.length === 0 ? (
                      <LoadingComponent customText="Getting fields..." />
                    ) : (
                      <>
                        <label>Select:</label>
                        {makeSimpleFields({ ...formikprops, values, setFieldValue })}
                        {makeProjectFields({ ...formikprops, values, setFieldValue })}
                        {makeProjectMetrics({ ...formikprops, values, setFieldValue })}
                      </>
                    )
                  }
                </div>
                <hr className="mb-5" />
                <div>
                  {
                    values.projects_wizard_options.length === 0 ? (
                      <div className="d-flex align-items-center justify-content-center mt-3 mb-3 small text-muted">
                        No fields added yet to that step, please use below select box to select fields from the list
                      </div>
                    ) : (
                      <h5>
                        Fields
                      </h5>
                    )
                  }
                  {
                    data?.index === 0 ? (
                      <div className="d-flex justify-content-between w-100">
                        <div className="mr-2 small text-muted">1.</div>
                        <div className="w-75"><ProjectName formikprops={formikprops} disabled /></div>
                        <div className="w-25 d-flex flex-column ml-3">
                          <div className="w-100 d-flex flex-row align-items-center">
                            <div className="w-100">
                              <label>Position</label>
                              <TextBox
                                name={`projects_wizard_options[1].position`}
                                disabled
                                placeholder="Field Position in the step"
                                formProps={{ ...formikprops, values, setFieldValue }}
                                className="w-100"
                                value={1}
                              />
                            </div>
                          </div>
                          <div className="mb-3 small">
                            <label className="d-flex align-items-center">
                              <CheckBox
                                checkType={CheckType.BLUE}
                                disabled
                                isChecked={true}
                              />
                              <span>Is Required?</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }
                  {
                    values.projects_wizard_options.map((option, index) => {
                      const defaultPosition = option.position !== undefined && option.position !== null ? option.position : index + 1;

                      return (
                        <div
                          key={option.id}
                          className="d-flex justify-content-between w-100"
                        >
                          <div className="mr-2 small text-muted">{index + 1}.</div>
                          <div className="w-75">
                            {PROJECT_WIZARD_FIELD_TYPES[option.field_type]?.component({
                              formikprops,
                              fieldDetails: option,
                              disabled: true,
                              isDisabled: true,
                              isAdminWizardInstance: true,
                            })}
                          </div>
                          <div className="w-25 d-flex flex-column ml-3">
                            <div className="w-100 d-flex flex-row align-items-center">
                              <div className="w-100">
                                <label>Position</label>
                                <TextBox
                                  name={`projects_wizard_options[${index}].position`}
                                  placeholder="Field Position in the step"
                                  formProps={{ ...formikprops, values, setFieldValue }}
                                  className="w-100"
                                  value={defaultPosition}
                                  onChange={(e) => {
                                    const updatedOption = {
                                      ...option,
                                      position: e.target.value || "",
                                    };
                                    const updatedOptions = [...values.projects_wizard_options];
                                    updatedOptions[index] = updatedOption;
                                    setFieldValue("projects_wizard_options", updatedOptions).then();
                                  }}
                                />
                              </div>
                              {
                                option.created_at && (
                                  <UpdateStepPosition item={option} handleUpdate={handlePositionUpdate} />
                                )
                              }
                            </div>
                            <div className="mb-3 small">
                              <label className="d-flex align-items-center">
                                <CheckBox
                                  checkType={CheckType.BLUE}
                                  name={`projects_wizard_options[${index}].required`}
                                  onChange={(evt) => {
                                    const updatedOption = {
                                      ...option,
                                      required: evt,
                                    };
                                    const updatedOptions = [...values.projects_wizard_options];
                                    updatedOptions[index] = updatedOption;
                                    setFieldValue("projects_wizard_options", updatedOptions).then();
                                  }}
                                  isChecked={option.required}
                                />
                                <span>Is Required?</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                <DialogActions
                  sx={{ position: "sticky", bottom: 0, background: "white", borderTop: "1px solid #e4e7eb" }}>
                  {
                    isSubmitting ? (
                      <LoadingComponent customText="One moment..." />
                    ) : (
                      <>
                        <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          btn={BtnType.HIGHLIGHT}
                          className="ml-3"
                        >
                          Save
                        </Button>
                      </>
                    )
                  }
                </DialogActions>
              </form>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
};

export default AddEditWizardStepModal;

import React from "react";
import styles from "./styles.module.scss";

const WarningMessage = ({ message }) => (
  <div className={styles.warningBlock}>
    <h1>!</h1>
    <p className={styles.warningMessage}>{message}</p>
  </div>
);

export default WarningMessage;

import { combineReducers } from "redux";

import {
  GET_FORD_DASHBOARD_STAGES_SUCCESS,
  GET_PROJECT_FIELDS_SUCCESS,
  GET_REGIONS_SUCCESS,
} from "modules/reporting/fordReports/store/fordReports.actions";
import actualScoreCardReducer from "modules/reporting/fordReports/actualScorecard/store/actualScoreCard.reducer";
import kpisReducer from "modules/reporting/fordReports/kpis/store/kpis.reducer";
import planScoreCardReducer from "modules/reporting/fordReports/planScorecard/store/planScoreCard.reducer";
import replicationsCardReducer
  from "modules/reporting/fordReports/replicationsCard/store/replicationsCard.reducer";

const initState = {
  regions: null,
  stages: null,
  projectFields: null,
};

const genericReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PROJECT_FIELDS_SUCCESS: {
      return {
        ...state,
        projectFields: payload?.response || [],
      }
    }

    case GET_REGIONS_SUCCESS: {
      return {
        ...state,
        regions: payload?.response || [],
      }
    }

    case GET_FORD_DASHBOARD_STAGES_SUCCESS: {
      return {
        ...state,
        stages: payload?.response?.map(s => ({ ...s, id: s.name })) || [],
      }
    }

    default:
      return state;
  }
}

const fordDashboardReducer = combineReducers({
  fordReportsReducer: genericReducer,
  actualScoreCardReducer,
  kpisReducer,
  planScoreCardReducer,
  replicationsCardReducer,
});

export default fordDashboardReducer;

import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { MoreOptions, UploadImgComponent } from "modules/primitives/index";
import { useSnackbar } from "notistack";
import { checkFirmenichUseCase } from "common/helper";

import styles from "./editCompany.module.scss";

import { moreOptions } from "./editCompany.constants";
import { Icon, smSize } from "../../../../common/icon";
import { handleMoreOptionClick, removeImg } from "./editCompany.helper.js";

const EditCompanyFormHeader = (props) => {
  const {
    currentLogo,
    showImageLoader,
    setCurrentLogo,
    setImageLoaderVisibility,
    setFieldValue,
    fileDelete,
    popup,
    setImgLoading,
    formikprops,
    blockAsCB,
  } = props;

  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
    authReducer: { session },
    patientOrganizationsReducer: {
      patientOrganizationReducer: { selectedPatientOrganization },
    },
  } = useSelector((state) => state);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const fromTractionAdmin = history?.location?.state?.fromTractionAdmin;

  const getEditOptions = () => {
    if (fromTractionAdmin && selectedCompany?.any_actions) {
      return moreOptions.filter((option) => option.val !== "delete");
    }
    if (
      checkFirmenichUseCase(
        selectedCouncil?.traction_tag,
        session?.council_role
      )
    ) {
      return moreOptions.filter((option) => option.val !== "delete");
    }
    return moreOptions;
  };
  return (
    <div className="row">
      <div className="col-12">
        {currentLogo && !showImageLoader && (
          <div className={styles.companyEditHeader}>
            <div>
              <img
                src={currentLogo}
                onError={() => setCurrentLogo(false)}
                className={styles.logo}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <p
                  className={styles.chageImageLink}
                  onClick={() => {
                    if (!blockAsCB) setImageLoaderVisibility(true);
                  }}
                >
                  Change image
                </p>

                <div className={styles.delete}>
                  <Icon
                    {...smSize}
                    icon="icn-button-delete"
                    onClick={(e) => {
                      if (!blockAsCB) {
                        removeImg({
                          setFieldValue,
                          currentLogo,
                          fileDelete,
                          setCurrentLogo,
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <MoreOptions
                options={getEditOptions()}
                className={styles.moreOptWrp}
                onClick={(e) => {
                  handleMoreOptionClick(
                    {
                      event: e,
                      companyId: selectedCompany.id,
                      companyName: selectedCompany.name,
                      history,
                      selectedPatientOrganization,
                      popup,
                      enqueueSnackbar,
                      selectedCouncil,
                      company: selectedCompany,
                      slug: selectedCompany.slug,
                    },
                    history?.location?.state?.fromTractionAdmin
                  );
                }}
              />
            </div>
          </div>
        )}
        {showImageLoader || !currentLogo ? (
          <div className={styles.companyEditHeader}>
            <UploadImgComponent
              label=""
              name="logo"
              disabled={selectedCompany?.cb_profile_name}
              uploadDirName={`srm/${
                JSON.parse(localStorage.getItem("council")).id
              }/company/${
                history.location?.state?.gsk
                  ? selectedPatientOrganization.id
                  : selectedCompany.id
              }`}
              onChange={(e) => {
                setFieldValue("logo", e);
              }}
              setImgLoading={setImgLoading}
              formProps={formikprops}
            />
            <div>
              <MoreOptions
                options={getEditOptions()}
                className={styles.moreOptWrp}
                onClick={(e) => {
                  handleMoreOptionClick({
                    event: e,
                    companyId: selectedCompany.id,
                    companyName: selectedCompany.name,
                    history,
                    selectedPatientOrganization,
                    popup,
                    enqueueSnackbar,
                    selectedCouncil,
                    slug: selectedCompany.slug,
                  });
                }}
              />
            </div>
          </div>
        ) : null}

        {showImageLoader && (
          <p
            className={styles.cancelLink}
            onClick={() => setImageLoaderVisibility(false)}
          >
            Cancel
          </p>
        )}
      </div>
    </div>
  );
};

export default EditCompanyFormHeader;

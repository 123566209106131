import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { BtnType, Button } from "../../../../primitives";
import { ModalFooter } from "../../../../../application/modal";
import UserMultiSelectSearch from "../../../../../common/components/usersSearch";
import styles from "./team.module.scss";
import { appModelSettingSet } from "application/app.action";
import { addIdeaTeamMemberAttempt } from "../../../idea.action";

const AddIdeaTeamMember = ({
  selectedIdea,
  usersByRole,
  isReviewersInstance,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    appReducer: { modalSetting },
  } = useSelector((state) => state);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const handleCancelClick = () => {
    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
  };

  const handleSubmitterSave = () => {
    if (!selectedMembers.length) {
      enqueueSnackbar(
        `Please select a ${isReviewersInstance ? "reviewer" : "team member"}!`,
        {
          variant: "error",
        },
      );
      return;
    }

    const membersIsAlreadyAdded = usersByRole.find((m) => selectedMembers.some((m1) => m1.id === m.user_id));

    if (membersIsAlreadyAdded) {
      enqueueSnackbar(
        `There are ${
          isReviewersInstance ? "reviewers" : "team members"
        } that are already added!`,
        {
          variant: "error",
        },
      );
      return;
    }

    const payload = {
      members: selectedMembers.map((m) => ({
        user_id: m.id,
        idea_id: selectedIdea.id,
        ideas_template_id: selectedIdea.ideas_template_id,
        role: isReviewersInstance ? "reviewer" : "member",
      })),
      cb: () => {
        enqueueSnackbar(
          `Successfully added ${
            isReviewersInstance ? "reviewers" : "team members"
          }`,
          {
            variant: "success",
          },
        );

        dispatch(appModelSettingSet({ ...modalSetting, show: false }));
      },
      enqueueSnackbar,
    };

    dispatch(addIdeaTeamMemberAttempt(payload));
  };

  return (
    <div>
      <div className={styles.customPadding}>
        <UserMultiSelectSearch
          multi
          placeholder={`Search for ${
            isReviewersInstance ? "reviewers" : "team members"
          }`}
          labelField="full_name"
          valueField="id"
          searchBy="full_name"
          hasAll={false}
          onChange={setSelectedMembers}
          selectedValues={selectedMembers}
          sharedUsers={usersByRole}
        />
      </div>
      <ModalFooter>
        <Button
          className="mr-3"
          btn={BtnType.FRAME_LESS}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button onClick={handleSubmitterSave} btn={BtnType.REGULAR}>
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default memo(AddIdeaTeamMember);

export const GET_RFI_DETAILS_ATTEMPT = "GET_RFI_DETAILS_ATTEMPT";
export const getRFIDetails = (payload) => ({
  type: GET_RFI_DETAILS_ATTEMPT,
  payload,
});

export const GET_RFI_DETAILS_SUCCESS = "GET_RFI_DETAILS_SUCCESS";
export const getRFIDetailsSuccess = (payload) => ({
  type: GET_RFI_DETAILS_SUCCESS,
  payload,
});

export const SAVE_DETAILS_ATTEMPT = "SAVE_DETAILS_ATTEMPT";
export const saveDetails = (payload) => ({
  type: SAVE_DETAILS_ATTEMPT,
  payload,
});

export const SAVE_DETAILS_SUCCESS = "SAVE_DETAILS_SUCCESS";
export const saveDetailsSuccess = (payload) => ({
  type: SAVE_DETAILS_SUCCESS,
  payload,
});

export const RESET_ACTIVE_FORM = "RESET_ACTIVE_FORM";
export const resetActiveForm = (payload) => ({
  type: RESET_ACTIVE_FORM,
  payload,
});

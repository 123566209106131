import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError, flatMap } from "rxjs/operators";
import { throwError } from "rxjs";

import { httpGet, httpPost, errorHandler } from "../../../common/httpCall";
import {
  COMPANY_SEARCH_ATTEMPT,
  companySearchSuccess,
  SALESFORCE_COMPANY_SEARCH_ATTEMPT,
  sfCompanySearchSuccess,
  ADD_COMPANY_TO_PROJECT_ATTEMPT,
  projectCompanyNewSuccess,
  COMPANY_GETFILTERS_ATTEMPT,
  companyGetFiltersSuccess,
  COMPANY_GET_TOPICS_ATTEMPT,
  companyGetTopicSuccess,
  COMPANY_SEARCH_CRUNCHBASE_ATTEMPT,
  searchCrunchBaseSuccess,
  companySearchOwnersGetSuccess,
  COMPANY_SEARCH_OWNERS_GET_ATTEMPT,
  GET_ALL_COMPANIES_ATTEMPT,
  getAllCompaniesSuccess,
  handleErrorSuccess,
  COMPANY_LIST_GET_ATTEMPT,
  companyListGetSuccess,
  GET_AGREEMENT_TYPES_ATTEMPT,
  getAgreementTypesSuccess,
} from "./companyMain.action";
import { patientOrgSearchSuccess } from "../../patientOrganizations/patientOrganizationMain/patientOrganizationMain.action";

export const epicGetCompanies = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_LIST_GET_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `councils/${payload.councilId}/companies?items=${
          payload.items || 20
        }&page=${payload.page || 1}&query=${payload.data || ""}&topic_ids=${
          payload.topicIds || ""
        }&industry_ids=${payload.industryIds || ""}${
          payload?.project_id ? `&project_id=${payload?.project_id}` : ""
        }&sort_attribute=${payload.sort_attribute || ""}&sort_order=${
          payload.sort_order || ""
        }`,
      }).pipe(
        map((result) =>
          companyListGetSuccess({ ...result, company: payload?.company })
        ),
        catchError((err) =>
          errorHandler(err, payload.enqueueSnackbar, [], handleErrorSuccess)
        )
      )
    )
  );

export const epicSearchGetRelationshipOwners = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_SEARCH_OWNERS_GET_ATTEMPT),
    switchMap(({ payload: { councilId, enqueueSnackbar } }) =>
      httpGet({
        call: "councils/relationship_owners",
      }).pipe(
        map((result) => companySearchOwnersGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetAllCompanies = (action$, state$) =>
  action$.pipe(
    ofType(GET_ALL_COMPANIES_ATTEMPT),
    switchMap(({ payload: { call, data, enqueueSnackbar } }) =>
      httpPost({
        call,
        data,
      }).pipe(
        map((result) => getAllCompaniesSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const filterValueBasedOnToggleNr = {
  0: "All",
  1: "Companies",
  2: "Products",
};

const makeURL = ({ mode, gsk, page, items, project_id, showSimilar, filter, isDropDown }) => `${
  mode === "companies" && !gsk ? "universal_search" : mode
}/search?page=${page || 1}&items=${items || 20}&with_context=true${
  project_id ? `&project_id=${project_id}` : ""
}&suggest=${showSimilar}&filter=${
  filterValueBasedOnToggleNr[filter || "0"]
}&is_dropdown=${!!isDropDown}`;

export const epicSearchCompanies = (action$) =>
  action$.pipe(
    ofType(COMPANY_SEARCH_ATTEMPT),
    flatMap(
      ({
        payload: {
          data,
          mode,
          council_id,
          topic_ids,
          industry_ids,
          disease_ids,
          countries,
          gsk,
          patient_org,
          sort_order,
          sort_attribute,
          page,
          items,
          withContext = false,
          relationship_stages,
          council_company_score_gte,
          council_company_score_lte,
          founded_gte,
          founded_lte,
          total_founding_gte,
          total_founding_lte,
          num_employee_enum,
          relationship_owners_ids,
          project_id,
          diversity_group_ids,
          locationProps,
          agreements,
          enqueueSnackbar,
          business_unit_ids,
          custom_tags_ids,
          revenue_range,
          location_countries,
          idb_connection_options,
          council_relationship_stage_id,
          showSimilar,
          theme_investment_stage_id,
          cb,
          filter,
          isDropDown,
          justCompanies,
          lists,
        },
      }) =>
        httpPost({
          call: makeURL({ mode, gsk, page, items, project_id, showSimilar, filter, isDropDown }),
          data: {
            query: data,
            gsk,
            patient_org,
            sort_order,
            sort_attribute,
            search: {
              query: data,
              topic_ids,
              industry_ids,
              therapeutic_area_ids: [],
              disease_ids,
              countries,
              relationship_stages,
              council_company_score_gte,
              council_company_score_lte,
              council_relationship_stage_id,
              founded_gte,
              founded_lte,
              total_fundings_sum_gte: total_founding_gte,
              total_fundings_sum_lte: total_founding_lte,
              relationship_owners_ids,
              num_employees_enums:
                num_employee_enum && num_employee_enum.length
                  ? num_employee_enum.split(",")
                  : null,
              diversity_group_ids,
              business_unit_ids,
              custom_tags_ids: custom_tags_ids || [],
              theme_investment_stage_id,
              revenue_ranges:
                revenue_range && revenue_range.length
                  ? revenue_range.split(",")
                  : null,
              ...locationProps,
              agreement_types_ids: agreements || [],
              location_countries: location_countries?.length
                ? location_countries
                : null,
              idb_connection_options,
              lists_ids: lists?.length
                ? lists.split(",").map((str) => str.split("_")[0])
                : [],
            },
          },
          cached: true
        }).pipe(
          map((result) => {
            if (cb) {
              cb(result);
            }

            if (gsk) {
              return patientOrgSearchSuccess({ mode, ...result });
            }
            return companySearchSuccess({ mode, ...result });
          }),
          catchError((err) =>
            errorHandler(err, enqueueSnackbar, [], handleErrorSuccess)
          )
        )
    )
  );

export const epicSfSearchCompanies = (action$, state$) =>
  action$.pipe(
    ofType(SALESFORCE_COMPANY_SEARCH_ATTEMPT),
    switchMap(
      ({
        payload: {
          data,
          mode,
          council_id,
          topic_ids,
          industry_ids,
          disease_ids,
          countries,
          gsk,
          patient_org,
          sort_order,
          sort_attribute,
          page,
          items,
          withContext = false,
          relationship_stages,
          council_company_score_gte,
          council_company_score_lte,
          founded_gte,
          founded_lte,
          total_founding_gte,
          total_founding_lte,
          num_employee_enum,
          relationship_owners_ids,
          project_id,
          diversity_group_ids,
          locationProps,
          agreements,
          enqueueSnackbar,
          business_unit_ids,
          custom_tags_ids,
          revenue_range,
          location_countries,
          idb_connection_options,
          council_relationship_stage_id,
          lists,
        },
      }) =>
        httpPost({
          call: `${mode}/sf_search_companies?page=${page || 1}&items=${
            items || 20
          }&with_context=true${project_id ? `&project_id=${project_id}` : ""}`,
          data: {
            query: data,
            gsk,
            patient_org,
            sort_order,
            sort_attribute,
            search: {
              query: data,
              topic_ids,
              industry_ids,
              therapeutic_area_ids: [],
              disease_ids,
              countries,
              relationship_stages,
              council_company_score_gte,
              council_company_score_lte,
              council_relationship_stage_id,
              founded_gte,
              founded_lte,
              total_fundings_sum_gte: total_founding_gte,
              total_fundings_sum_lte: total_founding_lte,
              relationship_owners_ids,
              num_employees_enums:
                num_employee_enum && num_employee_enum.length
                  ? num_employee_enum.split(",")
                  : null,
              lists_ids: lists?.length ? lists.split(",") : [],
              diversity_group_ids,
              business_unit_ids,
              custom_tags_ids,
              revenue_ranges:
                revenue_range && revenue_range.length
                  ? revenue_range.split(",")
                  : null,
              ...locationProps,
              agreement_types_ids: agreements || [],
              location_countries: location_countries?.length
                ? location_countries
                : null,
              idb_connection_options,
            },
          },
        }).pipe(
          map((result) => sfCompanySearchSuccess({ mode, ...result })),
          catchError((err) =>
            errorHandler(err, enqueueSnackbar, [], handleErrorSuccess)
          )
        )
    )
  );

export const epicSearchCompaniesInCrunchBase = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_SEARCH_CRUNCHBASE_ATTEMPT),
    switchMap(
      ({
        payload: { data, limit = "", after_id, before_id, enqueueSnackbar },
      }) =>
        httpGet({
          call: `crunchbase/companies?query=${data}${
            limit
              ? `&limit=${limit}${after_id ? `&after_id=${after_id}` : ""}${
                  before_id ? `&before_id=${before_id}` : ""
                }`
              : ""
          }`,
        }).pipe(
          map((result) => {
            if (result.status === 200 || result.status === 201) {
            } else {
              console.log("failed");
            }
            return searchCrunchBaseSuccess(result);
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

const getPayload = (projectId, companyId, userId, stageId, isProduct) => {
  if (isProduct) {
    return {
      project_id: projectId,
      council_product_id: companyId,
      added_by_id: userId,
      stage_id: stageId,
      is_product: true,
    };
  }

  return {
    project_id: projectId,
    company_id: companyId,
    added_by_id: userId,
    stage_id: stageId,
  };
};

export const epicNewProjectCompanies = (action$, state$) =>
  action$.pipe(
    ofType(ADD_COMPANY_TO_PROJECT_ATTEMPT),
    switchMap(
      ({
        payload: {
          projectId,
          companyId,
          isProduct,
          userId,
          stageId,
          enqueueSnackbar,
          history,
          cb,
        },
      }) =>
        httpPost({
          call: `projects/${projectId}/companies`, // NEW_API
          data: {
            project_company: getPayload(
              projectId,
              companyId,
              userId,
              stageId,
              isProduct
            ),
          },
        }).pipe(
          map((result) => {
            if (result.status === 200 || result.status === 201) {
              enqueueSnackbar("Successfully added company to the project.", {
                variant: "success",
              });
            }

            return projectCompanyNewSuccess(result);
          }),
          catchError((error) => {
            console.log("error epicNewProjectCompanies: ", error);
            if (error) {
              enqueueSnackbar("Something went wrong", {
                variant: "error",
              });
            }
            return throwError(error);
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicGetFilters = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_GETFILTERS_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `councils/${payload.councilId}/industries/resource_industries`,
      }).pipe(
        map((result) => companyGetFiltersSuccess(result)),
        catchError((error) => errorHandler(error, payload.enqueueSnackbar))
      )
    )
  );

export const epicGetTopics = (action$, state$) =>
  action$.pipe(
    ofType(COMPANY_GET_TOPICS_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `councils/${payload.councilId}/topics/resource_topics`,
      }).pipe(
        map((result) => companyGetTopicSuccess(result)),
        catchError((error) => errorHandler(error, payload.enqueueSnackbar))
      )
    )
  );

export const epicGetAgreementTypes = (action$, state$) =>
  action$.pipe(
    ofType(GET_AGREEMENT_TYPES_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v1",
        call: "agreement_types",
      }).pipe(
        map((result) => getAgreementTypesSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const companyMainEpic = combineEpics(
  epicSearchGetRelationshipOwners,
  epicNewProjectCompanies,
  epicSearchCompanies,
  epicSfSearchCompanies,
  epicGetFilters,
  epicGetTopics,
  epicSearchCompaniesInCrunchBase,
  epicGetAllCompanies,
  epicGetCompanies,
  epicGetAgreementTypes
);

export default companyMainEpic;

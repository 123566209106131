import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { useSnackbar } from "notistack";

import { isCreator } from "common/checkers/isCreator";
import {
  MoreOptions,
  Loading,
  Button,
  BtnType,
  Label,
} from "modules/primitives";
import styles from "./styles.module.scss";
import { ACTIVITY_OPTIONS } from "../../../constants";
import { Icon, smSize } from "../../../icon";

const LinkComponent = ({
  item,
  handleEditClick,
  blockFunctionality = false,
  handleDeleteClick,
  handleSubmitDetail,
}) => {
  const {
    companiesReducer: {
      companyReducer: { customFieldLoadingId, selectedCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: {
        selectedPatientOrganization,
        customFieldLoadingIdOrg,
      },
    },
  } = useSelector((state) => state);
  const [loadingId, setLoadingId] = useState("");
  const [fieldId, setFieldId] = useState("");
  const [hasAccessToEdit, setHasAccessToEdit] = useState(false);
  const [showField, setShowField] = useState(false);
  const [fieldValue, setFieldValue] = useState("");
  const [title, setTitle] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (item) {
      if (item?.field_name) {
        setTitle(item?.field_name);
      }
      if (item?.field_value) {
        setFieldValue(item?.field_value);
      }
      if (isCreator(item?.creator?.user_id)) {
        setHasAccessToEdit(true);
      }
    }
  }, [item]);

  useEffect(() => {
    if (customFieldLoadingId || customFieldLoadingIdOrg) {
      if (selectedPatientOrganization.id) {
        setLoadingId(customFieldLoadingIdOrg);
      } else {
        setLoadingId(customFieldLoadingId);
      }
    }
  }, [customFieldLoadingId, customFieldLoadingIdOrg]);

  useEffect(() => {
    if (selectedPatientOrganization.id) {
      setFieldId(item.patient_organization_custom_field_id);
    } else {
      setFieldId(item.company_custom_field);
    }
  }, [item]);

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }

    if (value === 1) {
      handleDeleteClick(item);
    }
  };

  const handleSaveClick = () => {
    if (!handleSubmitDetail) {
      return;
    }

    const payload = {
      ...item,
      field_value: fieldValue,
      title,
    };

    handleSubmitDetail(payload, item);

    setShowField(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        {showField ? null : (
          <div className="d-flex flex-row align-items-center">
            <div className="d-flex flex-column">
              <h5 className={styles.title}>
                {" "}
                {title ? (
                  <div className={styles.container}>
                    {" "}
                    <a
                      className={`${fieldValue ? styles.link : ""}`}
                      target="_blank"
                      href={fieldValue}
                      rel="noreferrer"
                    >
                      {title}
                    </a>
                  </div>
                ) : (
                  "Link"
                )}
              </h5>
              {item?.field_placeholder?.length ? (
                <span className="text-muted small">
                  {item.field_placeholder}
                </span>
              ) : null}
            </div>
            {item.field_value?.length ? (
              <div
                className="cursor-pointer ml-2"
                onClick={() => setShowField(true)}
              >
                <Icon {...smSize} icon="icn-edit" />
              </div>
            ) : null}
          </div>
        )}
        <div className={styles.rightWrp}>
          <div className={styles.colMore}>
            {!blockFunctionality && hasAccessToEdit ? (
              <MoreOptions
                options={ACTIVITY_OPTIONS}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            ) : null}
          </div>
        </div>
      </div>
      {item.from_claimed_profile && (
        <span className="d-block small font-weight-bold mb-3">
          Company Response
        </span>
      )}
      {item &&
        !title &&
        (loadingId === fieldId ? (
          <Loading />
        ) : showField ? (
          true
        ) : (
          <div className="d-inline-flex">
            <h5 className={styles.container}>
              <a
                className={styles.link}
                target="_blank"
                href={fieldValue}
                rel="noreferrer"
              >
                {item && !title && fieldValue}
              </a>
            </h5>
            <div
              className="cursor-pointer ml-2"
              onClick={() => setShowField(true)}
            >
              <Icon {...smSize} icon="icn-edit" />
            </div>
          </div>
        ))}

      {!showField && !item?.field_value?.length ? (
        <div className="w-75 d-flex align-items-end">
          <div className="d-flex flex-column mr-2">
            <Label>Text (optional)</Label>
            <input
              className="w-100 border small p-2 mb-3"
              value={title}
              placeholder="Add title"
              onChange={(evt) => setTitle(evt.target.value)}
            />
            <Label>Link</Label>
            <input
              className="w-100 border small p-2"
              value={fieldValue}
              placeholder="Enter a URL"
              onChange={(evt) => setFieldValue(evt.target.value)}
            />
          </div>
          <Button btn={BtnType.FRAME_LESS} onClick={handleSaveClick}>
            Save
          </Button>
        </div>
      ) : null}

      {showField ? (
        <div className="w-75 d-flex align-items-end">
          <div className="d-flex flex-column mr-2">
            <Label>Text (optional)</Label>
            <input
              className="w-100 border small p-2 mb-3"
              value={title}
              placeholder="Add title"
              onChange={(evt) => setTitle(evt.target.value)}
            />
            <Label>Link</Label>
            <input
              className="w-100 border small p-2"
              value={fieldValue}
              placeholder="Enter a URL"
              onChange={(evt) => setFieldValue(evt.target.value)}
            />
          </div>
          <Button btn={BtnType.FRAME_LESS} onClick={handleSaveClick}>
            Save
          </Button>
        </div>
      ) : null}

      {item && item.creator && (
        <>
          <div className={styles.infoWrp}>
            <div className={styles.dataRow}>
              <div className={styles.label}>
                {item.from_submission
                  ? "Submitted by"
                  : item.updated_at !== item.created_at
                  ? "Updated by"
                  : "Created by"}
              </div>
              <div className={styles.value}>
                {item.from_submission ? (
                  <span>{item?.creator?.full_name || "-"}</span>
                ) : (
                  <Link to={`/people/internal/profile/${item.user_id}`}>
                    {item && item.creator && item.creator.full_name}
                    {item.from_claimed_profile && (
                      <span>, {selectedCompany?.name}</span>
                    )}
                  </Link>
                )}
              </div>
            </div>
            {item.from_claimed_profile && (
              <div className="d-flex align-items-center mr-5">
                <div className={styles.companyAccess}>
                  <span>&#x2713; Access</span>
                </div>
              </div>
            )}
            <div className={styles.date}>
              {item.updated_at !== item.created_at
                ? format(
                    new Date(item.updated_at),
                    "eeee, MMM do, yyyy hh:mm a"
                  )
                : format(
                    new Date(item.created_at),
                    "eeee, MMM do, yyyy hh:mm a"
                  )}
            </div>
            {item.from_submission ? (
              <div
                className={`d-flex flex-grow-1 align-items-center justify-content-center ${styles.companyResponse}`}
              >
                Company Response
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};
export default React.memo(LinkComponent);

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Pannel, Loading } from "../../primitives";
import { numberWithCommas } from "../../../common/helper";
import styles from "./leaderboard.module.scss";
import { httpGet } from "../../../common/httpCall";
import { queryStringUrlReplacement } from "../../../common/helper";

import { highestSavingsColumns } from "./leaderboard.constants";

const TopSavingsTableComponent = ({
  enqueueSnackbar,
  selectedCouncil,
  getSortDirectionStytles,
  getSortStyles,
  prepareDataName,
}) => {
  const history = useHistory();
  const [savings, setSavings] = useState([]);
  const [topSavingsSort, setTopSavingsSort] = useState({
    sort_attribute: "savings",
    sort_order: "desc",
  });
  const [loading, setLoading] = useState(false);
  const sortRedirect = () => {
    const config = {
      activeColumn: "savings",
      order: "desc",
    };
    let search = queryStringUrlReplacement(
      history.location.search,
      "sortAttribute",
      config.activeColumn
    );
    search = queryStringUrlReplacement(search, "sortOrder", config.order);
    search = queryStringUrlReplacement(search, "page", 1);
    search = queryStringUrlReplacement(search, "mode", "all_projects");

    history.push({
      pathname: "/projects",
      search,
      state: {
        ...history.location.state,
        sortAttribute: config.activeColumn,
        sortOrder: config.order,
        page: 1,
      },
    });
  };
  const getStats = () => {
    setLoading(true);
    try {
      httpGet({
        call: "ford/leaderboards/highest_savings",
        apiVersion: "v1",
      }).subscribe(
        async (res) => {
          setSavings(res.response);
          setLoading(false);
        },
        () => {
          console.log("---------error on getting highest_savings");
          setLoading(false);
        }
      );
    } catch (error) {
      console.log("---------error", error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCouncil) {
      getStats({
        id: selectedCouncil,
        enqueueSnackbar,
      });
    }
  }, [selectedCouncil]);

  const setSortAttr = (sortAttr) => {
    const sortingFunction = (techologies) => {
      const newArr = [...techologies];
      const sorted = newArr.sort((a, b) => {
        if (a[sortAttr] === null) {
          return -1;
        }
        if (a[sortAttr] < b[sortAttr]) {
          return -1;
        } else if (a[sortAttr] > b[sortAttr]) {
          return 1;
        }
        return 0;
      });
      return topSavingsSort.sort_order === "asc" ? sorted : sorted.reverse();
    };

    setTopSavingsSort((prev) => {
      if (prev.sort_order === "desc") {
        return {
          sort_attribute: sortAttr,
          sort_order: "asc",
        };
      } else {
        return {
          sort_attribute: sortAttr,
          sort_order: "desc",
        };
      }
    });
    setSavings(sortingFunction);
  };

  return (
    <div className={`col-md-12 col-lg-6`}>
      <Pannel
        title=""
        padding="0"
        parentStyle={{
          background: "Transparent",
          // marginRight: window.screen.width > 550 ? 0 : 0,
          padding: window.screen.width > 550 ? 7 : 0,
        }}
        position="relative"
        overflow="hidden"
      >
        <div className={styles.wrapper}>
          <div className={`row ${styles.grid}`}>
            <div className={`col-12 ${styles.dataTableContainer}`}>
              <div className={styles.gridHeader}>
                <div className={styles.headerTitle}>
                  Top 25 Projects by <b>Savings</b>
                </div>
              </div>
              <div className={styles.dataTable}>
                <div
                  className={`${styles.tableHeaderRow} ${styles.techHeader}`}
                >
                  {highestSavingsColumns.map((header) => {
                    return (
                      <div
                        key={header.columnName}
                        className={` ${
                          styles.dataTableHeader
                        } ${header.columnStyles.map(
                          (customClass) => customClass
                        )} ${getSortStyles(
                          header.columnSortKey,
                          "topSavings",
                          topSavingsSort.sort_attribute
                        )} ${header.sortable && styles.cursorpointer}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (header.sortable) {
                            setSortAttr(header.columnSortKey);
                          }
                        }}
                      >
                        <span
                          className={`${getSortDirectionStytles(
                            header.columnSortKey,
                            "topSavings",
                            topSavingsSort.sort_attribute,
                            topSavingsSort.sort_order
                          )} `}
                        >
                          {header.columnName}{" "}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className={`${styles.projSavingsTableBodyContainer}`}>
                  {savings?.map((project, i) => {
                    return (
                      <div
                        key={project.project_id}
                        className={`${styles.techRow}`}
                      >
                        <div className={styles.rowNumber}>
                          {" "}
                          <span>{i + 1}</span>{" "}
                        </div>

                        <div
                          className={` ${styles.cellItem} ${styles.techName}`}
                        >
                          {" "}
                          <Link
                            className={styles.url}
                            to={`/projects/${project.project_id}/overview`}
                          >
                            {prepareDataName(project.project_name)}
                          </Link>
                        </div>

                        <div className={`${styles.cellItem}`}>
                          {project.plant_name}
                        </div>
                        <div className={`${styles.cellItem} ${styles.lastCol}`}>
                          $ {numberWithCommas(project.savings) || "0"}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {loading && <Loading />}
              </div>
            </div>
            <div
              className={`row ${styles.seeMoreLink}`}
              onClick={(e) => {
                e.stopPropagation();
                sortRedirect();
              }}
            >
              SEE MORE
            </div>
          </div>
        </div>
      </Pannel>
    </div>
  );
};

export default React.memo(TopSavingsTableComponent);

import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import styles from '../styles.module.scss';
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import SectionCard from "modules/reporting/fordReports/planScorecard/sections/sectionCard";
import {
  getPlanScoreData,
  getPlanPocTableData,
  setPlanScoreCardFilters,
  getPlanScoreTableData,
} from "modules/reporting/fordReports/planScorecard/store/planScoreCard.actions";
import FordActiveFilters from "modules/reporting/fordReports/filters/activeFilters";
import PocTableDataCard from "modules/reporting/fordReports/planScorecard/sections/pocTableCard";

const FordScoreCards = ({ filters, downloadIsActive }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    fordDashboardReducer: { planScoreCardReducer }
  } = useSelector((state) => state);
  const data = useMemo(() => planScoreCardReducer.charts.data, [planScoreCardReducer.charts.data]);
  const fetching = useMemo(() => planScoreCardReducer.charts.fetching, [planScoreCardReducer.charts.fetching]);

  useEffect(() => {
    const payload = {
      enqueueSnackbar,
      data: makeActualScoreCardFilterData(filters),
    };

    dispatch(getPlanPocTableData(payload));
    dispatch(getPlanScoreData(payload));
  }, []);

  const setFilters = (newFilters) => {
    dispatch(setPlanScoreCardFilters(newFilters));
  };

  const getTableData = (newFilters) => {
    dispatch(getPlanScoreTableData(newFilters));
  };

  const getUpdatedSectionData = (newFilters) => {
    dispatch(getPlanScoreData(newFilters));
    dispatch(getPlanPocTableData(newFilters));
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-end mb-2">
        <div className="d-flex w-50">
          <FordActiveFilters
            filters={planScoreCardReducer.filters}
            expandedTableKey={planScoreCardReducer.tableData?.expandedKey}
            highLightColumn={planScoreCardReducer.tableData?.highLightColumn}
            setFilters={setFilters}
            getTableData={getTableData}
            getUpdatedData={getUpdatedSectionData}
          />
        </div>
        <div className="d-flex flex-row justify-content-end w-25">
          <SectionCard
            className="w-50 mr-1 ml-1"
            data={{
              main_value: data?.top_row_data?.total_poc
            }}
            fetching={fetching}
            size="small"
            title="Total PoC"
            reducerKey="total_poc"
            subTitle="Projects"
            hoverText="Projects that reached Prototype Stage in the selected time period OR Projects in Proposal for Prototype Stage with Next Stage Change Quarter that is also in the time period."
            highLightColumn="projectStage"
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="d-flex flex-row w-25">
          <SectionCard
            className="w-50 mr-1 ml-1"
            data={{
              main_value: data?.top_row_data?.total_approved
            }}
            fetching={fetching}
            size="small"
            title="Total Approved"
            reducerKey="total_approved"
            subTitle="Projects"
            hoverText="Projects that reached Implementation Stage in the selected time period OR Projects in Proposal for Implementation Stage with Next Stage Change Quarter that is also in the time period."
            highLightColumn="projectStage"
            downloadIsActive={downloadIsActive}
          />
          <SectionCard
            className="w-50 mr-1 ml-1"
            data={{
              main_value: data?.top_row_data?.total_executed
            }}
            fetching={fetching}
            size="small"
            title="Total Executed"
            reducerKey="total_executed"
            subTitle="Projects"
            hoverText="Projects with a Job 1 Date in the selected time period OR Projects in Implementation stage with Next Stage Change Quarter that is also in the time period."
            highLightColumn="job1Date"
            downloadIsActive={downloadIsActive}
          />
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <div className={styles.columnHeader}>
            Input Metrics
          </div>
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <div className={styles.columnHeader}>
            Process Metrics
          </div>
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <div className={styles.columnHeader}>
            Output Metrics
          </div>
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <div className={styles.columnHeader}>
            Outcome Metrics
          </div>
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <SectionCard
            data={data?.input_metrics?.poc}
            fetching={fetching}
            size="big"
            title="PoC"
            reducerKey="total_poc"
            hoverText="Projects that reached Prototype Stage in the selected time period OR Projects in Proposal for Prototype Stage with Next Stage Change Quarter that is also in the time period."
            highLightColumn="projectStage"
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <SectionCard
            data={data?.process_metrics?.idea_generation}
            fetching={fetching}
            size="medium"
            title="Idea Generation"
            subTitle="(Time to Market)"
            hoverText="Average number of days between Project Start Date and Implementation Date."
            downloadIsActive={downloadIsActive}
          />
          <SectionCard
            data={data?.process_metrics?.implementation}
            fetching={fetching}
            size="medium"
            title="Implementation"
            subTitle="(Time to Market)"
            hoverText="Average number of days between Implementation Date and Job 1 Date."
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <SectionCard
            data={data?.output_metrics?.approved_replications}
            fetching={fetching}
            size="big"
            title="Approved Replications"
            reducerKey="approved_replications"
            hoverText="Projects in the Implementation Stage or beyond that are Replications OR Projects in Proposal for Implementation Stage that are Replications with Next Stage Change Quarter that is also in the time period."
            highLightColumn="approvedReplications"
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <SectionCard
            data={data?.outcome_metrics?.cib_savings}
            fetching={fetching}
            size="big"
            title="CIB Savings ($)"
            reducerKey="cib_savings"
            showDollars
            hoverText="Total Savings for all Projects with a Job 1 Date in the selected time period AND Funded by CIB OR all Projects in Implementation Stage AND Funded by CIB with Next Stage Change Quarter that is also in the time period."
            highLightColumn="savings"
            downloadIsActive={downloadIsActive}
          />
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <PocTableDataCard downloadIsActive={downloadIsActive} />
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <SectionCard
            data={data?.process_metrics?.cib_avarage_tarr}
            fetching={fetching}
            size="big"
            title="CIB Avg TARR"
            reducerKey="cib_avarage_tarr"
            hoverText="Average TARR of all Projects that reached Implementation Stage in the selected time period. AND are Funded by CIB OR all Projects in Proposal for Implementation Stage AND Funded by CIB with Next Stage Change Quarter that is also in the time period."
            highLightColumn="tarr"
            downloadIsActive={downloadIsActive}
          />
          <SectionCard
            data={data?.process_metrics?.global_tarr}
            fetching={fetching}
            size="big"
            title="Global TARR"
            reducerKey="global_tarr"
            hoverText="Average TARR of all Projects with an Implementation Date in the selected time period OR all Projects in Proposal for Implementation Stage with Next Stage Change Quarter that is also in the time period."
            highLightColumn="tarr"
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <SectionCard
            data={data?.output_metrics?.job1_accomplishment}
            fetching={fetching}
            size="big"
            title="Job1 Accomplishment"
            reducerKey="job1_accomplishment"
            hoverText="Percentage of Projects Accomplished on Time, with a Job 1 Date that is the same or earlier than the Expected Job 1 Date."
            highLightColumn="job1Accomplishment"
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <SectionCard
            data={data?.outcome_metrics?.global_savings}
            fetching={fetching}
            size="medium"
            title="Global Savings ($)"
            reducerKey="global_savings"
            showDollars
            hoverText="Total Savings for all Projects with Job 1 Date in the selected time period OR all Projects in Implementation Stage with Next Stage Change Quarter that is also in the time period."
            highLightColumn="savings"
            downloadIsActive={downloadIsActive}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(FordScoreCards);

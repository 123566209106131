import {
  PROJECT_TASK_LIST_GET_SUCCESS,
  PPROJECT_TASK_ADD_SUCCESS,
  PROJECT_TASK_COMPANY_LIST_GET_SUCCESS,
  PROJECT_TASK_GET_SUCCESS,
  PROJECTTASK_STATUS_UPDATE_SUCCESS,
  PROJECT_TASK_NOTES_LIST_SUCCESS,
  PPROJECT_TASK_NOTES_ADD_SUCCESS,
  PROJECT_TASK_NOTES_UPDATE_SUCCESS,
  PROJECT_TASK_DOCUMENTS_ADD_SUCCESS,
  PPROJECT_TASK_DOCUMENTS_DELETE_SUCCESS,
  PROJECT_TASK_DOCUMENTS_GET_SUCCESS,
  PROJECT_TASK_FIELDS_GET_SUCCESS,
  PPROJECT_TASK_DELETE_SUCCESS,
  PPROJECT_TASK_UPDATE_SUCCESS,
  PROJECT_TASK_SORTED_LIST_SET,
  PPROJECT_TASK_NOTES_DELETE_SUCCESS,
  PPROJECT_TASK_ASSIGMENT_STATUS_UPDATE,
  PROJECT_TASKS_BY_USER_SUCCESS,
  PROJECT_TASK_DOCUMENTS_ADD_ATTEMPT,
  PROJECT_TASK_UPDATE_BY_USER_SUCCESS,
  TASK_COMPANY_DOCUMENTS_SAVE,
  CLEAR_UPLOADED_DOC,
  PROJECT_TASK_UPDATE_ATTEMPT,
  GATEWAY_TASK_UPDATE_SUCCESS,
  STATIC_GATEWAY_TASK_UPDATE,
  MARK_TASK_COMPLETED,
  PROJECT_TASKS_BY_USER_ATTEMPT,
  PROJECT_TASK_LIST_GET_ATTEMPT,
  GET_TASK_ADDITIONAL_DATA_SUCCESS,
} from "./tasks.action";
import { apiStatus, pageMeta } from "../../../../common/httpCall";

const initState = {
  tasksList: [],
  userTasks: [],
  taskFields: {},
  selectedTask: null,
  documents: {},
  taskCompany: {},
  taskNotes: [],
  sortedList: [],
  loadingDocuments: false,
  asc: false,
  tasksTotal: 0,
  shouldUpdate: false,
  infoDocuments: [],
  updateTasks: false,
  updateStages: false,
  uploadedDoc: null,
  deletedDocumentId: "",
  loadingTask: false,
  fetchingTasks: false,
};

const makeTasksList = (state, payload, prop) => {
  if (state[prop].length) {
    return payload.response.map((task) => {
      const getFromState = state[prop].find(t => t.id === task.id);

      if (getFromState) {
        return {
          ...getFromState,
          ...task,
          projects_task_assignments: getFromState.projects_task_assignments || task.projects_task_assignments || [],
        }
      }

      return {
        ...task,
        projects_task_assignments: task.projects_task_assignments || [],
      };
    })
  }

  return payload.response.map(el => ({
    ...el,
    projects_task_assignments: el.projects_task_assignments || [],
  }))
};

const updateTaskCompletionOnDocUpload = (task, payload) => {
  if (payload?.completionProps?.assignmentId && !payload?.completionProps?.shouldCompleteAll) {
    return {
      ...task,
      task_completion_state: payload.task_completion_state,
      projects_task_assignments: task.projects_task_assignments?.map(a => {
        if (a.id === payload.completionProps.assignmentId) {
          return {
            ...a,
            completed: payload.completed,
          }
        }

        return a;
      })
    }
  }

  return {
    ...task,
    task_completion_state: payload.task_completion_state,
    projects_task_assignments: task.projects_task_assignments?.map(a => ({...a, completed: payload.completed}))
  }
}

const taskReducer = (state = initState, action) => {
  const { type, payload } = action;

  if (!payload) return state;

  const { response, status } = payload;

  switch (type) {
    case GET_TASK_ADDITIONAL_DATA_SUCCESS: {
      if (payload.isProjectWideFromStage) return state;

      if (payload.activeTab === 'all_task') {
        return {
          ...state,
          tasksList: state.tasksList.map((task) => {
            if (task.id === response.id) {
              return {
                ...task,
                ...response,
                hasAdditionalData: true,
              }
            }

            return task;
          }),
        }
      }

      if (payload.activeTab === 'my_task') {
        return {
          ...state,
          userTasks: state.userTasks.map((task) => {
            if (task.id === response.id) {
              return {
                ...task,
                ...response,
                hasAdditionalData: true,
              }
            }

            return task;
          }),
        }
      }

      return state;
    }

    case PROJECT_TASKS_BY_USER_ATTEMPT:
    case PROJECT_TASK_LIST_GET_ATTEMPT: {
      return {
        ...state,
        fetchingTasks: true,
      }
    }

    case MARK_TASK_COMPLETED: {
      if (payload.isProjectWideFromStage) {
        return state;
      }

      let props = {};

      if (payload.deleteAction && payload.completionProps?.assignmentId) {
        const { [payload.completionProps.assignmentId]: _, ...rest } = state.documents;

        props = {
          documents: rest,
        }
      }

      return {
        ...state,
        ...props,
        tasksList: state.tasksList?.map(task => {
          if (task.id === payload.taskId) {
            return updateTaskCompletionOnDocUpload(task, payload, state);
          }

          return task
        }),
        userTasks: state.userTasks?.map(task => {
          if (task.id === payload.taskId) {
            return updateTaskCompletionOnDocUpload(task, payload)
          }

          return task
        })
      }
    }

    case PROJECT_TASK_LIST_GET_SUCCESS: {
      const pagemeta = pageMeta(payload);

      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          tasksList: makeTasksList(state, payload, 'tasksList'),
          tasksTotal: pagemeta.total,
          updateTasks: false,
          documents: response.reduce((acc, task) => {
            const getAssignmentsDocsByTask = task.projects_task_assignments?.reduce(
              (acc1, a) => ({
                ...acc1,
                [a.id]: a.documents,
              }),
              {},
            );

            return {
              ...acc,
              ...getAssignmentsDocsByTask || {},
            };
          }, state.documents),
          fetchingTasks: false,
        };
      }

      break;
    }

    case PROJECT_TASKS_BY_USER_SUCCESS: {
      const pagemeta = pageMeta(payload);

      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          userTasks: makeTasksList(state, payload, 'userTasks'),
          tasksTotal: pagemeta.total,
          updateTasks: false,
          documents: response.reduce((acc, task) => {
            const getAssignmentsDocsByTask = task.projects_task_assignments?.reduce(
              (acc1, a) => ({
                ...acc1,
                [a.id]: a.documents,
              }),
              {},
            ) || {};

            return {
              ...acc,
              ...getAssignmentsDocsByTask,
            };
          }, state.documents),
          fetchingTasks: false,
        };
      }
      break;
    }

    case PROJECT_TASK_UPDATE_BY_USER_SUCCESS: {
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          userTasks: state.userTasks.map((item) => (item.id === response.id ? response : item)),
        };
      }
      break;
    }

    case PPROJECT_TASK_ADD_SUCCESS: {
      if (status === apiStatus.CREATED) {
        return {
          ...state,
          tasksList: [...state.tasksList, response],
        };
      }
      break;
    }

    case PROJECT_TASK_UPDATE_ATTEMPT: {
      return {
        ...state,
        loadingTask: true,
      };
    }

    case STATIC_GATEWAY_TASK_UPDATE: {
      if (payload.isProjectWideFromStage) {
        return state;
      }

      const tasksList = state.tasksList.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            ...payload,
          }
        }

        return item;
      });

      return {
        ...state,
        tasksList,
      };
    }

    case GATEWAY_TASK_UPDATE_SUCCESS: {
      if (payload.isProjectWideFromStage) {
        return state;
      }

      const tasksList = state.tasksList.map((item) => {
        if (item.id === payload.response.id) {
          return {
            ...item,
            ...payload.response,
          }
        }

        return item;
      });

      return {
        ...state,
        tasksList,
        loadingTask: false,
      };
    }

    case PPROJECT_TASK_UPDATE_SUCCESS: {
      if (payload.directUpdate) {
        const tasksList = state.tasksList.map((item) => {
          if (item.id === payload.id) {
            return {
              ...item,
              ...payload,
            }
          }

          return item;
        });

        return {
          ...state,
          tasksList,
          loadingTask: false,
        };
      }

      if (status === apiStatus.SUCCESS) {
        const tasksList = state.tasksList.map((item) => (item.id === response.id ? response : item));

        return {
          ...state,
          tasksList,
          loadingTask: false,
        };
      }
      break;
    }

    case PROJECT_TASK_COMPANY_LIST_GET_SUCCESS: {
      const { taskId } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          taskCompany: { ...state.taskCompany, [taskId]: response },
        };
      }
      break;
    }

    case PPROJECT_TASK_DELETE_SUCCESS: {
      const { taskId } = payload;
      return {
        ...state,
        tasksList: state.tasksList.filter((task) => task.id !== taskId),
      };
    }

    // notes
    case PROJECT_TASK_NOTES_LIST_SUCCESS: {
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          taskNotes: response,
        };
      }
      break;
    }

    case PPROJECT_TASK_NOTES_ADD_SUCCESS: {
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          taskNotes: [...state.taskNotes, { ...response }],
        };
      }
      break;
    }

    case PROJECT_TASK_NOTES_UPDATE_SUCCESS: {
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          taskNotes: state.taskNotes.map((note) => {
            if (note.id === response.id) {
              return response;
            }
            return note;
          }),
        };
      }
      break;
    }

    case PPROJECT_TASK_NOTES_DELETE_SUCCESS: {
      const {
        result: { status: reqStatus },
        id,
      } = payload;

      if (reqStatus === apiStatus.NOCONTENT) {
        return {
          ...state,
          taskNotes: state.taskNotes.filter((item) => item.id !== id),
        };
      }
      break;
    }

    case PPROJECT_TASK_ASSIGMENT_STATUS_UPDATE:
      if (status === apiStatus.SUCCESS) {
        let newList = null;
        const companies = state.taskCompany[response.task_id];
        if (companies) {
          newList = companies[0]
            && companies.map((companyObj) => (companyObj.id === response.id
              ? {
                ...companyObj,
                assigned_to: response.assigned_to,
                due_date: response.due_date,
                completed_on: response.completed_on,
              }
              : companyObj));
        } else {
          newList = [
            {
              assigned_to: response.assigned_to,
              due_date: response.due_date,
              completed_on: response.completed_on,
            },
          ];
        }

        return {
          ...state,
          taskCompany: { ...state.taskCompany, [response.task_id]: newList },
        };
      }
      break;
    // end - notes
    // document
    case PROJECT_TASK_DOCUMENTS_GET_SUCCESS: {
      const {
        response: { id, documents },
        status,
      } = payload;
      if (status === apiStatus.SUCCESS) {
        return {
          ...state,
          documents: { ...state.documents, [id]: documents },
        };
      }

      break;
    }

    case PROJECT_TASK_DOCUMENTS_ADD_ATTEMPT: {
      return {
        ...state,
        loadingDocuments: true,
      };
    }

    case PROJECT_TASK_DOCUMENTS_ADD_SUCCESS:
      {
        const { response, status, assignmentId } = payload;
        if (status === apiStatus.CREATED || status === apiStatus.SUCCESS) {
          return {
            ...state,
            infoDocuments: [...state.infoDocuments, response],
            updateTasks: true,
            documents: {
              ...state.documents,
              [assignmentId]: state.documents[assignmentId]
                ? [...state.documents[assignmentId], response]
                : [response],
            },
            loadingDocuments: false,
            updateStages: !state.updateStages,
            uploadedDoc: response,
          };
        }
      }
      break;

    case PPROJECT_TASK_DOCUMENTS_DELETE_SUCCESS: {
      const { assignmentId, documentId } = payload;

      if (payload.isProjectWideFromStage) {
        return state;
      }

      if (!assignmentId) {
        const newDocsState = Object.keys(state.documents).reduce((acc, key) => {
          const docsFilteredByKey = state.documents[key].filter(
            (d) => d.id !== documentId,
          );
          return {
            ...acc,
            [key]: docsFilteredByKey,
          };
        }, {});

        return {
          ...state,
          infoDocuments: state.infoDocuments?.filter(
            (doc) => doc.id !== documentId,
          ),
          updateTasks: true,
          documents: newDocsState,
          deletedDocumentId: documentId,
          uploadedDoc: !state.uploadedDoc,
        };
      }

      return {
        ...state,
        infoDocuments: state.infoDocuments?.filter(
          (doc) => doc.id !== documentId,
        ),
        updateTasks: true,
        documents: {
          ...state.documents,
          [assignmentId]: state.documents[assignmentId]?.filter(
            (item) => item.id !== documentId,
          ),
        },
        shouldUpdate: !state.shouldUpdate,
        deletedDocumentId: documentId,
        uploadedDoc: !state.uploadedDoc,
      };
    }
    // OLD
    case PROJECT_TASK_SORTED_LIST_SET:
      return {
        ...state,
        sortedList: payload.array,
        asc: payload.asc,
      };

    case PROJECT_TASK_GET_SUCCESS:
      return {
        ...state,
        selectedTask: payload,
      };

    case PROJECTTASK_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        tasksList: state.tasksList.map((task) => (task.id === payload.data.id ? payload.data : task)),
      };

    case PROJECT_TASK_FIELDS_GET_SUCCESS:
      return {
        ...state,
        taskFields: { ...state.taskFields, [payload.taskId]: payload.data },
      };
    case TASK_COMPANY_DOCUMENTS_SAVE: {
      return {
        ...state,
        infoDocuments: payload.documents,
      };
    }
    case CLEAR_UPLOADED_DOC: {
      return {
        ...state,
        uploadedDoc: { ...payload },
      };
    }
  }

  return state;
};

export default taskReducer;

const checkThemePipelinePermissions = (
  targetStage,
  userRole,
  councilName,
  decisionMakerAccess,
  curentRel
) => {
  if (
    userRole === "ttp_administrator" ||
    userRole === "council_administrator"
  ) {
    return true;
  }

  // Kyndryl settings
  if (councilName === "Kyndryl") {
    if (
      userRole === "viewer" &&
      (targetStage === "SC Review" ||
        targetStage === "Discover" ||
        targetStage === "No-Go")
    ) {
      if (
        curentRel === "SC Review" ||
        curentRel === "Discover" ||
        curentRel === "No-Go"
      ) {
        return true;
      }
      return false;
    }
    if (userRole === "standard") {
      return true;
    }
    return false;
  }

  // Firmenich settings

  if (councilName === "Firmenich") {
    if (decisionMakerAccess) {
      return true;
    }
    if (
      userRole === "standard" &&
      (targetStage === "none" ||
        targetStage === "Explore" ||
        targetStage === "Discover" ||
        targetStage === "Dismiss")
    ) {
      if (
        typeof curentRel === "object" ||
        curentRel === "Explore" ||
        curentRel === "Discover" ||
        curentRel === "Dismiss"
      ) {
        return true;
      }

      return false;
    }
  }

  if (councilName !== "Firmenich" && userRole) {
    return true;
  }
  return false;
};
export default checkThemePipelinePermissions;

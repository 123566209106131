import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import styles from "./employees.module.scss";
import { Icon, smSize } from "../../../../../../common/icon";
import {
  queryParamStringToArray,
  queryStringUrlReplacement,
  useQuery,
} from "../../../../../../common/helper";
import { MultiSelect } from "../../../../../primitives";
import { EMPLOYEES_VALUES } from "../../../../../../common/constants";

const EmployeesFilter = ({ expanded, handleExpand, disabled }) => {
  const history = useHistory();
  const employeesQuery = useQuery().get("employees");

  const [collapsed, setCollapsed] = useState(false);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (disabled) {
      setCollapsed(false);
      return
    }
    setCollapsed(expanded);
  }, [expanded, disabled]);

  useEffect(() => {
    if (employeesQuery) {
      const arr = queryParamStringToArray(employeesQuery);
      const mappedValue = EMPLOYEES_VALUES.filter((e) => arr.includes(e.id));
      setEmployees(mappedValue);

      return;
    }

    setEmployees([
      {
        id: "Any",
        value: "Any",
      },
    ]);

    return () => null;
  }, [employeesQuery]);

  const handleCollapseClick = () => {
    if (disabled) {
      return;
    }

    if (!collapsed) {
      handleExpand("employees");
    }
    setCollapsed(!collapsed);
  };

  const handleOnChange = (value) => {
    let clearVal;
    const currentHasAny = value.some((e) => e.id === "Any");
    const hasAny = employees.some((e) => e.id === "Any");

    if (currentHasAny && value.length > 1) {
      clearVal = value
        .filter((elem) => elem.id !== "Any")
        .reduce((acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`, "");
    }

    if (!hasAny && currentHasAny) {
      clearVal = "Any";
    }

    if (!currentHasAny) {
      clearVal = value.reduce(
        (acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`,
        "",
      );
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      "employees",
      clearVal === "Any" ? "" : clearVal,
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        employees: clearVal === "Any" ? "" : clearVal,
        page: 1,
      },
    });
  };

  return (
    <div className={`${styles.itemWrapper} ${disabled ? styles.disabled : ''}`} id="employees">
      <div className={styles.itemTitle} onClick={handleCollapseClick}>
        <span>Employees</span>
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(styles.icon, !collapsed && styles.collapse)}
          onClick={handleCollapseClick}
        />
      </div>
      {collapsed ? (
        <div
          className={classNames(
            "d-flex align-items-center",
            styles.withPaddingLeft,
          )}
        >
          <div
            id="employees"
            className={classNames("d-flex flex-column", styles.employeesField)}
          >
            <MultiSelect
              placeholder=""
              labelField="value"
              valueField="id"
              searchBy="value"
              options={EMPLOYEES_VALUES}
              values={employees}
              onChange={(val) => handleOnChange(val)}
              dropdownPosition="auto"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(EmployeesFilter);

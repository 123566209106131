import { getCookie } from "common/helper";

const isCreator = (id) => {
  const user_id = getCookie("user_id_");

  const councilRole = getCookie("council_role_");
  const council = JSON.parse(localStorage.getItem("council"));

  return (
    id === user_id ||
    councilRole === "council_administrator" ||
    councilRole === "ttp_administrator" ||
    council.name === "Kyndryl"
  );
};

export { isCreator };

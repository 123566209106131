import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem";
import SCReview from "common/handleSCReview";

import { checkViewerRole } from "common/checkers/viewerChecker";
import styles from "./company.module.scss";
import { CompanyAvatar, MoreOptions } from "../../../primitives";
import { companyMoreOptions } from "../companyMain.constant";
import {
  companyUnFollow,
  suggestionFollow,
} from "../../../../common/actions/common.action";
import AddCompanyComponent from "../addToProject";
import AddToListComponent from "../../../lists/components/addToList";
import { useModelPopup } from "../../../../common/hooks";
import { editDetails, editDetailsSuccess } from "../../company/company.action";
import { makeWebSite } from "../../../../common/helper";
import AddToIdeaComponent from "../addToIdea/addToIdea.component";
import AddToThemeComponent from "../../../themes/themesCommon/addCompanyToTheme";
import checkThemePipelinePermissions from "../../../../common/checkThemePipelinePermissions";
import checkUserThemeCompanyPermission from "../../../../common/checkUserThemeCompanyPermissions";
import AddProductToList from "modules/companies/product/components/addProductToList";
import AddProductToIdeaComponent from "modules/companies/product/components/addProductToIdea/addProductToIdea.component";
import AddProductToTheme from "modules/companies/product/components/addProductToTheme";
import { editProductDetails } from "modules/companies/product/store/product.actions";
import CompanyTags from "modules/companies/companyMain/companyBlock/companyTags";
import CompanyOtherDetails from "modules/companies/companyMain/companyBlock/companyOtherDetails";
import { useCompanyContext } from "modules/companies/companyMain/providers/companyDataProvider";

const CompanyBlockComponent = ({
  company,
  enqueueSnackbar,
  relationshipOwners,
  isSelected,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const popup = useModelPopup();
  const {
    authReducer: { session },
    appReducer: { relationShipStatuses },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [relationship, setRelationship] = useState([]);
  const [companyOptions, setCompanyOption] = useState(companyMoreOptions);
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [followingId, setFollowingId] = useState(
    company.current_user_follow_relationship_id
  );
  const [tagsForGlobal, setCompanyTagsForGlobal] = useState([]);

  const {
    handleRelationShipChange,
    handleCompanySelect,
    selectModeIsActive,
  } = useCompanyContext();

  const handleDropdownSelect = (e) => {
    e.stopPropagation();
    const { value } = e.target;
    const getStageDetails = relationShipStatuses.find(
      (s) => s.id || s.slug === value
    );
    if (
      checkThemePipelinePermissions(
        getStageDetails?.name || "none",
        session?.council_role,
        selectedCouncil?.name,
        session?.decision_maker_access,
        company.council_relationship_stage_name
      )
    ) {
      if (value?.length) {
        setRelationship(getStageDetails);
      } else {
        setRelationship([]);
      }

      const tagId = relationShipStatuses.filter(
        (tag) => e.target.value === tag.slug
      )[0]?.customId;

      const data = {
        council_relationship_stage_id: tagId || null,
      };

      if (company.is_product) {
        const payload = {
          companyId: company.company_id,
          productId: company.id,
          data: {
            relationship_stage_id: tagId,
          },
          cb: () => {
            const newCompanyPayload = {
              ...company,
              council_relationship_stage_id: tagId,
            };

            dispatch(editDetailsSuccess(newCompanyPayload));
          },
        };

        dispatch(editProductDetails(payload));

        return;
      }

      dispatch(
        editDetails({
          companyId: company.id,
          councilId: selectedCouncil.id,
          data,
          enqueueSnackbar,
          callBack: (res) => {
            if (res?.response?.council_relationship_stage?.id) {
              const getStageDetails = relationShipStatuses.find(
                (s) =>
                  s.id ||
                  s.slug === res?.response?.council_relationship_stage.slug
              );

              setRelationship(getStageDetails);
            }
          },
          council: selectedCouncil?.name,
        })
      );

      if (company.appendedItem) {
        return;
      }

      const timeout = setTimeout(() => {
        handleRelationShipChange(true);
        clearTimeout(timeout);
      }, 1000);
    } else {
      enqueueSnackbar("You are not allowed to move companies in this stage ", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    setFollowingId(company.current_user_follow_relationship_id);
  }, [company.current_user_follow_relationship_id]);

  const handleMoreOptionClick = (val, company) => {
    if (val === 1 || val === 5) {
      if (followingId?.length) {
        makeUnfollow({
          follow_relationship_id: followingId,
        });
      } else {
        makeFollow(company);
      }
    }
    if (val === 2) {
      handleAddCompanyToList();
    }
    if (val === 3) {
      handleAddCompanyClick();
    }
    if (val === 6) {
      if (company.is_product) {
        const productObj = {
          ...company,
          ideas: company.ideas_ids?.map((id) => ({ id })) || [],
        };

        popup.show({
          title: "Add To Idea",
          component: (
            <AddProductToIdeaComponent
              product={productObj}
              enqueueSnackbar={enqueueSnackbar}
            />
          ),
        });

        return;
      }

      popup.show({
        title: "Add To Idea",
        component: (
          <AddToIdeaComponent
            company={company}
            selectedCouncil={selectedCouncil}
            enqueueSnackbar={enqueueSnackbar}
            fromCompany
          />
        ),
      });
    }
    if (val === 7) {
      const title = `Add To ${
        selectedCouncil?.settings?.themes_display_name_singular || "Theme"
      }`;

      if (company.is_product) {
        const productObj = {
          ...company,
          themes: company.themes_ids?.map((id) => ({ id })) || [],
        };

        popup.show({
          title,
          component: <AddProductToTheme product={productObj} />,
        });

        return;
      }

      popup.show({
        title,
        component: (
          <AddToThemeComponent
            company={company}
            selectedCouncil={selectedCouncil}
            enqueueSnackbar={enqueueSnackbar}
            fromCompany
          />
        ),
      });
    }
  };
  const handleCompanyClick = () => {
    if (company.is_product) {
      history.push(
        `/companies/${company.company_slug || company.company_id}/products/${
          company.slug
        }`
      );
      return;
    }
    localStorage.setItem("company", JSON.stringify(company));
    history.push(`/companies/${company.slug || company.id}`);
  };

  useEffect(() => {
    if (company?.council_relationship_stage_name && relationShipStatuses) {
      const getStageDetails = relationShipStatuses.find(
        (s) => s.id || s.customId === company?.council_relationship_stage_id
      );

      setRelationship(getStageDetails);
    }
  }, [company, relationShipStatuses]);

  useEffect(() => {
    if (selectedCouncil && selectedCouncil?.name) {
      const markertsGroup = selectedCouncil.council_custom_tag_groups?.filter(
        (group) => group.name === "Markets Served"
      );
      const containerForAll = markertsGroup[0]?.custom_tags.length;

      setCompanyTagsForGlobal(containerForAll || []);
    }
  }, [selectedCouncil]);

  const handleAddCompanyToList = () => {
    if (company.is_product) {
      const productObj = {
        ...company,
        lists: company.lists_ids?.map((id) => ({ id })) || [],
      };

      popup.show({
        title: "Add To List",
        component: (
          <AddProductToList
            product={productObj}
            enqueueSnackbar={enqueueSnackbar}
          />
        ),
      });

      return;
    }

    popup.show({
      title: "Add To List",
      component: (
        <AddToListComponent
          companyId={company.id}
          enqueueSnackbar={enqueueSnackbar}
          getMyOnlyLists
          fromCompany
        />
      ),
    });
  };

  const makeUnfollow = (payload) => {
    dispatch(
      companyUnFollow({
        data: {
          ...payload,
          following_id: followingId,
        },
        enqueueSnackbar,
      })
    );

    setFollowingId(null);
  };

  const makeFollow = (company) => {
    dispatch(
      suggestionFollow({
        data: {
          ...company,
          company_id: company.id,
          name: company.name,
        },
        enqueueSnackbar,
        cb: (res) => {
          if (res.response) {
            setFollowingId(
              res.response.follow_relationship_id || res.response.following_id
            );
          }
        },
      })
    );
  };

  const handleFollowClick = (e) => {
    e.stopPropagation();

    if (followingId?.length) {
      makeUnfollow(company);
    } else {
      makeFollow(company);
    }
  };

  const handleChangeStage = (e) => {
    if (selectedCouncil?.name === "Kyndryl" && e.target.value === "sc_review") {
      const openStages = ["SC Review", "Discover", "No-Go"];
      const currentRel = company?.council_relationship_stage_name;

      if (
        session?.council_role === "viewer" &&
        openStages.includes(currentRel)
      ) {
        popup.show({
          title: " ",
          show: true,
          height: "300",
          width: "540",
          component: (
            <SCReview
              handleStageSelect={handleDropdownSelect}
              e={e}
              popup={popup}
              companySlug={company?.slug}
            />
          ),
        });
        return;
      }
      handleDropdownSelect(e);
    } else {
      handleDropdownSelect(e);
    }
  };

  const handleAddCompanyClick = () => {
    popup.show({
      title: `Add a ${company.is_product ? "Product" : "Company"} to Project`,
      component: (
        <AddCompanyComponent
          companyId={company.id}
          enqueueSnackbar={enqueueSnackbar}
          fromCompany
          isProduct={company.is_product}
        />
      ),
    });
  };

  useEffect(() => {
    if (checkViewerRole()) {
      const filteredOptions = companyOptions.filter((option) => {
        if (
          selectedCouncil?.settings?.ideas_connected === false &&
          option.text === "Add to Idea"
        ) {
          return false;
        }
        return option.val !== 3;
      });
      setCompanyOption(filteredOptions);
      setBlockFunctionality(true);
    }

    if (selectedCouncil?.settings?.ideas_connected === false) {
      const filteredOptions = companyOptions.filter((option) => {
        return !(selectedCouncil?.settings?.ideas_connected === false && option.text === "Add to Idea");
      });
      setCompanyOption(filteredOptions);
    }
  }, [companyMoreOptions]);

  const checkIfTractionMatchIsAllowed = useCallback(() => {
    if (selectedCouncil?.name === "Kyndryl") {
      return false;
    }

    return company.themes_ids?.length <= 0;
  });

  return (
    <div className="position-relative d-flex align-items-center w-100">
      {selectModeIsActive && (
        <div className={styles.selectCompany}>
          <input
            type="checkbox"
            checked={isSelected}
            onChange={() => handleCompanySelect(company)}
          />
        </div>
      )}
      <div className={styles.wrapper} onClick={handleCompanyClick}>
        <div className={styles.contentWrp}>
          <div className={styles.firstRow}>
            <div className="d-flex align-items-center">
              <div className={styles.logoWrp}>
                <div className={styles.logoCon}>
                  <CompanyAvatar
                    className={styles.logo}
                    imgSrc={company.logo || company.company_logo}
                    name={company.name}
                  />
                </div>
              </div>
              <div className="ml-3">
                <div className="d-flex align-items-center">
                  <h3 className={styles.name}>{company.name}</h3>
                  {company.is_product && (
                    <span className="small text-muted ml-4">Product</span>
                  )}
                </div>
                {company.web_url && (
                  <a className={styles.companyUrl} href={company.web_url}>
                    {makeWebSite(company.web_url)}
                  </a>
                )}
              </div>
            </div>
            <div className={styles.rightWrp}>
              {!company.is_product && (
                <div
                  className={`${styles.followBtn} ${
                    followingId?.length ? styles.followed : styles.follow
                  }`}
                  onClick={(e) => handleFollowClick(e)}
                >
                  {followingId?.length ? "Following +" : "Follow"}
                </div>
              )}

              <Select
                value={relationship?.slug || "none"}
                onClick={(e) => e.stopPropagation()}
                onChange={handleChangeStage}
                className={
                  !relationship?.slug || relationship?.slug === "none"
                    ? styles.tractionMatch
                    : styles.detailsRelationship
                }
                style={{
                  backgroundColor: relationship?.colors?.background || "",
                  color: relationship?.colors?.text || "",
                }}
                disableUnderline
                disabled={blockFunctionality}
              >
                {checkIfTractionMatchIsAllowed() && (
                  <MenuItem
                    style={
                      !checkThemePipelinePermissions(
                        "none",
                        session?.council_role,
                        selectedCouncil?.name,
                        session?.decision_maker_access,
                        null
                      )
                        ? { background: "#929ba4", color: "white" }
                        : {}
                    }
                    key="none"
                    value="none"
                  >
                    Traction Match
                  </MenuItem>
                )}

                {relationShipStatuses.map((item) => (
                  <MenuItem
                    style={
                      !checkThemePipelinePermissions(
                        item.name,
                        session?.council_role,
                        selectedCouncil?.name,
                        session?.decision_maker_access,
                        company?.council_relationship_stage_name
                      )
                        ? { background: "#929ba4", color: "white" }
                        : {}
                    }
                    key={item.slug}
                    value={item.slug}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>

              <div className={styles.colMore}>
                <MoreOptions
                  options={companyOptions
                    .filter((option) => {
                      if (
                        (selectedCouncil?.name === "GSK" &&
                          option.text === "Add to Idea") ||
                        checkUserThemeCompanyPermission(
                          session?.council_role,
                          option.text,
                          selectedCouncil?.themes_connected
                        )
                      ) {
                        return false;
                      }

                      if (company.is_product) {
                        return (
                          option.text !== "Follow" && option.text !== "Unfollow"
                        );
                      }

                      return (
                        option.text !==
                        (followingId?.length ? "Follow" : "Unfollow")
                      );
                    })
                    .map((opt) => {
                      if (opt.text === "Add to theme") {
                        return {
                          ...opt,
                          text: `Add to ${
                            selectedCouncil?.settings
                              ?.themes_display_name_singular || "theme"
                          }`,
                        };
                      }
                      return opt;
                    })}
                  className={styles.moreOptWrp}
                  onClick={(val) => handleMoreOptionClick(val, company)}
                />
              </div>
            </div>
          </div>
          <CompanyOtherDetails
            company={company}
            selectedCouncil={selectedCouncil}
            relationshipOwners={relationshipOwners}
          />
          <div className={styles.descriptions}>
            {company.description || company.short_description}
          </div>
          <CompanyTags company={company} tagsForGlobal={tagsForGlobal} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CompanyBlockComponent);

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import mainStyles from "../../../ideasWrapper.module.scss";
import styles from "./submitter.module.scss";
import { Icon, lgSize, mdSize } from "../../../../../common/icon";
import NewDiscussion from "../../../../../common/components/newDiscussion/newDiscussion.component";
import { appModelSettingSet } from "application/app.action";
import EditSubmitter from "../editSubmitter";
import { UserInline, UserInlineTypes } from "../../../../primitives";

const IdeaProfileSubmitter = ({ canEdit, selectedIdea }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { submitter } = selectedIdea;
  const {
    appReducer: { modalSetting },
  } = useSelector((state) => state);

  const startNewDiscussion = () => {
    const customSettings = {
      title: `Start a New Private Discussion with ${submitter.full_name}`,
      show: true,
      height: "300",
      width: "540",
      component: (
        <NewDiscussion
          type="Council"
          isPrivate
          selectedUser={submitter}
          hideTeam
        />
      ),
    };
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      }),
    );
  };

  const handleSubmitterEdit = () => {
    const customSettings = {
      title: "Edit Submitter",
      show: true,
      height: "300",
      width: "540",
      component: <EditSubmitter selectedIdea={selectedIdea} />,
    };

    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      }),
    );
  };

  const handleUserRedirect = (userId) => {
    if (userId) {
      history.push(`/people/internal/profile/${userId}`);
    }
  };

  return (
    <div className="d-flex flex-column">
      <div
        className={`d-flex align-items-center justify-content-between ${mainStyles.blockHeader}`}
      >
        <span className={mainStyles.blockTitle}>Submitter</span>
      </div>
      <div className={`${styles.submitterContent} ${mainStyles.blockContent}`}>
        <div className="d-flex flex-grow-1 align-items-baseline">
          <div
            className={styles.submitterAvatar}
            onClick={() => handleUserRedirect(submitter.user_id)}
          >
            <UserInline
              userId={submitter.user_id}
              first={submitter.first_name}
              last={submitter.last_name}
              avatar={submitter.avatar}
              local
              noNavigation
              type={UserInlineTypes.IMAGE_ONLY}
            />
          </div>
          <div className="d-flex flex-column ml-2">
            <span
              className={styles.submitterName}
              onClick={() => handleUserRedirect(submitter.user_id)}
            >
              {submitter.full_name}
            </span>
            {submitter.title && (
              <span className={styles.submitterRole}>{submitter.title}</span>
            )}
            {submitter.company && (
              <span className={styles.submitterCompany}>
                {submitter.company}
              </span>
            )}
            {submitter.external_submitter && submitter.email && (
              <span className={styles.submitterRole}>{submitter.email}</span>
            )}
          </div>
        </div>
        <div className={styles.submitterActions}>
          {submitter.external_submitter ? (
            <a href={`mailto:${submitter.email}`}>
              <Icon
                {...lgSize}
                icon="icn-table-message"
                className={styles.messageIcon}
              />
            </a>
          ) : (
            <Icon
              {...lgSize}
              icon="icn-table-message"
              className={styles.messageIcon}
              onClick={startNewDiscussion}
            />
          )}
          {canEdit && (
            <Icon
              {...mdSize}
              icon="icn-edit-button"
              className={styles.messageIcon}
              onClick={handleSubmitterEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(IdeaProfileSubmitter);

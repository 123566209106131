import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import Tooltip from "@mui/material/Tooltip";

import styles from './chartTags.module.scss';

const FirmenichChartTags = ({
  tags,
  selectedTags,
  handleTagSelect,
  tagsNrToShow = 8,
  instance = ''
}) => {
  const [hasMore, setHasMore] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setHasMore(tags?.length > tagsNrToShow);
  }, [tags, tagsNrToShow]);

  const handleTagClick = (tag) => {
    if (handleTagSelect) {
      handleTagSelect(tag);
    }
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  }

  const getBackgroundColor = useCallback((tag) => {
    if (selectedTags.length) {
      const checkTagIsSelected = selectedTags.find(t => (t.id || t.custom_id) === (tag.id || tag.custom_id));

      if (checkTagIsSelected) {
        return checkTagIsSelected.colors?.background || tag.color;
      }

      return '#999999'
    }

    return tag.colors?.background || tag.color
  }, [selectedTags]);

  if (!tags.length) {
    return null
  }

  return (
    <div className={styles.tagsWrapper}>
      <div className="d-flex align-items-center flex-wrap">
        {tags.slice(0, showMore ? tags.length : tagsNrToShow).map((tag, idx) => (
          <div
            key={`${instance}-${tag.id}-${idx}`}
            className={styles.tag}
            onClick={() => handleTagClick(tag)}
            role="img text"
            title={`There are ${tag.count} companies in current search with this tag.`}
            aria-label={`There are ${tag.count} companies in current search with this tag.`}
          >
            <div
              className={classnames(styles.square)}
              style={{ backgroundColor: getBackgroundColor(tag) }}
            ></div>
            <Tooltip
              title={tag?.name || '"-"'}
              placement="top"
            >
              <p>{tag?.name || '"-"'}</p>
            </Tooltip>
          </div>
        ))}
        {
          hasMore ? (
            <span
              className={styles.showMore}
              onClick={handleShowMore}
            >
              {showMore ? 'show less' : 'show more...'}
            </span>
          ) : null
        }
      </div>
    </div>
  )
};

export default React.memo(FirmenichChartTags);

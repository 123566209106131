import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { ModalFooter, ModalBody } from "../../../application/modal";
import { httpPut } from "../../httpCall";
import {
  Button,
  BtnType,
  Label,
  TextBox,
  UploadImgComponent,
} from "../../../modules/primitives";
import { councilUsersListGet } from "../../actions/common.action";
import { useModelPopup } from "../../hooks";
import {
  initModelRelationship,
  speakerValidation,
} from "./editExternalRelationship.constants";
import styles from "./editExternalRelationship.module.scss";

const EditExternalRelationship = ({
  mode,
  relationship,
  isCompanyUse,
  isPatientOrg,
  fieldData,
}) => {
  const dispatch = useDispatch();
  const [model, setmodel] = useState(initModelRelationship);
  const speakerFormRef = useRef();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
  } = useSelector((state) => state);

  const handleCancelClick = (e) => {
    popup.hide();
  };

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(councilUsersListGet(selectedCouncil.id, enqueueSnackbar));
    }
  }, [selectedCouncil]);

  const handleExternalTeamMember = (values) => {
    const dataValues = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      title: values.title,
      avatar: values.avatar,
      company_id: selectedCompany.id,
    };
    if (mode === "edit") {
      httpPut({
        call: `companies/${selectedCompany.id}/external_team/${values.external_team_id}`,
        data: { ...dataValues, external_team_id: values.external_team_id },
      })
        .pipe()
        .subscribe((res) => res);
    }
    window.location.reload();
  };

  const handleOnSubmit = (values) => {
    if (mode === "edit") {
      values.external_team_id && handleExternalTeamMember(values);
    }
    popup.hide();
  };

  const handleImgUpdate = (avatar, {
    first_name, last_name, email, title,
  }) => {
    setmodel({
      ...model,
      avatar,
      first_name,
      last_name,
      email,
      title,
    });
  };

  useEffect(() => {
    if (relationship?.external_team) {
      const loadModel = {
        first_name: relationship?.external_team?.full_name
          .split(" ")
          .slice(0, -1)
          .join(" "),
        last_name: relationship?.external_team?.full_name
          .split(" ")
          .slice(-1)
          .join(" "),
        email: relationship?.external_team?.email,
        avatar: relationship?.external_team?.avatar,
        title: relationship?.external_team?.title,
        external_team_id: relationship.external_team_id,
      };
      if (Object.keys(loadModel).length > 0) {
        setmodel(loadModel);
      }
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <Formik
        innerRef={speakerFormRef}
        enableReinitialize
        initialValues={model && model}
        validationSchema={speakerValidation && speakerValidation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleOnSubmit(values);
          resetForm();
        }}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          ...formikprops
        }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <>
                <div className="row">
                  <div className="col-12">
                    <>
                      <Label>Photo(PNG/JPG/GIF only)</Label>
                      <UploadImgComponent
                        name="avatar"
                        formProps={formikprops}
                        uploadDirName={`users/${model.external_team_id}/key`}
                        onChange={(e) => handleImgUpdate(e, values)}
                      />
                    </>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Label>First Name</Label>
                    <TextBox
                      type="text"
                      name="first_name"
                      placeholder="Enter first name"
                      className={styles.textBox}
                      formProps={{ ...formikprops, values }}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <Label>Last Name</Label>
                    <TextBox
                      type="text"
                      name="last_name"
                      placeholder="Enter last name"
                      className={styles.textBox}
                      formProps={{ ...formikprops, values }}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <>
                      <Label>Email</Label>
                      <TextBox
                        type="text"
                        name="email"
                        placeholder="Enter email"
                        className={styles.textBox}
                        formProps={{ ...formikprops, values }}
                        onChange={handleChange}
                        disabled="true"
                        // toggle={toggle}
                      />
                    </>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <>
                      <Label>Title</Label>
                      <TextBox
                        type="text"
                        name="title"
                        placeholder="Enter title"
                        className={styles.textBox}
                        formProps={{ ...formikprops, values }}
                        onChange={handleChange}
                      />
                    </>
                  </div>
                </div>
              </>
            </ModalBody>
            <ModalFooter className={styles.footer}>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button btn={BtnType.REGULAR}>
                {mode === "add" ? "Add" : "Update"}
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditExternalRelationship;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import Skeleton from "@mui/material/Skeleton";
import classNames from "classnames";
import { getCookie } from "common/helper";

import { Search } from "modules/primitives/index";
import OverviewComponent from "./overview";
import DetailsComponent from "./details";
import ActitityComponent from "../../companies/company/activity";
import ProjectsComponent from "../../companies/company/projectCompanies/projectCompanies.component";
import DocumentsComponent from "../../companies/company/documents/documents.component";
import CompanyDiscussionsWrap from "../../companies/company/companyDiscussions/companyDiscussions.component";
import { patientOrganizationGet } from "./patientOrganization.action";
import styles from "../../companies/company/company.module.scss";
import { patientOrganizationMoreOptions } from "./patientOrganization.constant";
import { patientOrganizationDiseasesReset } from "../patientOrganizationMain/patientOrganizationMain.action";
import { checkViewerRole } from "../../../common/checkers/viewerChecker";
import NewTabs from "../../../common/components/newTabs/newTabs.component";
import { useWindowSize } from "../../../common/hooks/useWindowSize";
import PatientOrgTopActions from "./components/patientOrgTopActions/patientOrgTopActions.component";
import PatientOrgTopDetails from "./components/patientOrgTopDetails/patientOrgTopDetails.component";

const pageByIndex = {
  overview: 0,
  details: 1,
  activity: 2,
  projects: 3,
  documents: 4,
  discussions: 5,
};

const PatientOrganizationComponent = (props) => {
  const dispatch = useDispatch();
  const {
    location: { pathname },
  } = props;
  const history = useHistory();
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
    patientOrganizationsReducer: {
      patientOrganizationReducer: {
        selectedPatientOrganization,
        updatePatientOrganization,
        loading,
        organizationLoading,
        patientOrgIsDownloading,
        patientOrgDownloadDetails,
      },
    },
  } = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [selectedTab] = useState(pathname.split("/")[3]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [query, setQuery] = useState();
  const [patientOrgId, setPatientOrgId] = useState();
  const [blockFunctionality, setBlockFunctionality] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [options, setOptions] = useState(patientOrganizationMoreOptions);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    dispatch(patientOrganizationDiseasesReset());
  }, []);

  useEffect(() => {
    const council_role = getCookie("council_role_");
    setIsAdmin(
      council_role === "ttp_administrator" ||
        council_role === "council_administrator" ||
        council_role === "standard"
    );
    return () => null;
  }, []);

  useEffect(() => {
    if (checkViewerRole()) {
      const filteredOptions = options.filter(
        (option) => option.text !== "Add to project"
      );
      setOptions(filteredOptions);
      setBlockFunctionality(true);
    }
  }, []);

  useEffect(() => {
    const idIsInArray = params[0] ? params[0].split("/") : [];
    const finalId = idIsInArray.length > 1 ? idIsInArray[0] : params.id;

    if (params.id && patientOrgId !== finalId) {
      setPatientOrgId(idIsInArray.length > 1 ? idIsInArray[0] : params.id);
    }

    if (params[0]) {
      setSelectedIndex(
        pageByIndex[idIsInArray.length > 1 ? idIsInArray[1] : params[0]]
      );
    }
  }, [params, selectedCompany]);

  useEffect(() => {
    const index = tabList.findIndex((x) => x.tab.toLowerCase() === selectedTab);

    setSelectedIndex(index);

    return () => null;
  }, [selectedTab]);

  useEffect(() => {
    if (selectedCouncil && patientOrgId) {
      dispatch(
        patientOrganizationGet({
          id: patientOrgId,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil, patientOrgId]);

  useEffect(() => {
    if (selectedCouncil && updatePatientOrganization) {
      dispatch(
        patientOrganizationGet({
          id: patientOrgId,
          enqueueSnackbar,
        })
      );
    }
  }, [updatePatientOrganization]);

  const tabList = [
    {
      tab: "Overview",
      name: "Overview",
      component: (
        <OverviewComponent
          pathname={pathname}
          patientOrg={selectedPatientOrganization}
          loading={organizationLoading || loading}
        />
      ),
    },
    {
      tab: "Details",
      name: "Details",
      component: (
        <DetailsComponent
          pathname={pathname}
          patientOrganizationId={patientOrgId}
        />
      ),
    },
    {
      tab: "Activity",
      name: "ENGAGEMENTS / PROJECTS",
      component: (
        <ActitityComponent
          pathname={pathname}
          companyId={patientOrgId}
          orgType="patient"
          selectedCouncil={selectedCouncil}
          session={session}
        />
      ),
    },
    {
      tab: "Projects",
      name: "MAPPINGS",
      component: (
        <ProjectsComponent
          company={selectedPatientOrganization}
          isPatientOrganization
          pathname={pathname}
        />
      ),
    },
    {
      tab: "Documents",
      name: "Documents",
      component: (
        <DocumentsComponent
          pathname={pathname}
          isPatientOrg
          patientOrgId={patientOrgId}
        />
      ),
    },
    {
      tab: "Discussions",
      name: "Discussions",
      component: (
        <CompanyDiscussionsWrap
          isPatientOrg
          company={selectedPatientOrganization.id}
          pathname={pathname}
        />
      ),
    },
  ];

  const handleOnTabSelect = (index) => {
    if (selectedPatientOrganization && selectedPatientOrganization.id) {
      const objTab = tabList.find((tab, tabIndex) => index === tabIndex);
      history.replace(
        `/patient_organizations/${
          selectedPatientOrganization.id
        }/${objTab.tab.toLowerCase()}`
      );
    }
  };

  const handleEnterPress = () => {
    history.push("/patient_organizations", { query });
  };

  const handleSearch = (e) => {
    setQuery(e);
  };

  const handleBlur = () => {};

  if (!selectedCouncil?.id || !session?.id) return null;

  return (
    <section id="downloadableSection">
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          {!patientOrgIsDownloading ? (
            <div className={styles.searchWrapper}>
              <div className={styles.searchContainer}>
                <Search
                  className={styles.searchCon}
                  placeholder="Search Patient Organizations"
                  gsk
                  patient_org
                  onChange={handleSearch}
                  onKeyUp
                  handleEnterPress={handleEnterPress}
                  handleBlur={handleBlur}
                  mode="companies"
                  showDropdown
                />
              </div>
            </div>
          ) : (
            <div className="d-flex flex-1 justify-content-between align-items-center px-4">
              <img
                height="50"
                alt={selectedCouncil.name}
                src={selectedCouncil.logo}
              />
              <h2 className="font-weight-bold mb-0">
                {selectedPatientOrganization.name} Profile
              </h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="79"
                height="35"
                viewBox="0 0 79 35"
              >
                <g fill="none" fillRule="evenodd" fontSize="16">
                  <g fill="#1F2933">
                    <g>
                      <text
                        fontFamily="HelveticaNeue-CondensedBlack, Helvetica Neue"
                        fontStyle="condensed"
                        fontWeight="700"
                        transform="translate(-1290 -12) translate(1291 12)"
                      >
                        <tspan x="0" y="16">
                          Traction
                        </tspan>
                      </text>
                      <text
                        fontFamily="HelveticaNeue-Light, Helvetica Neue"
                        fontWeight="300"
                        letterSpacing="-.08"
                        transform="translate(-1290 -12) translate(1291 12)"
                      >
                        <tspan x="0" y="31">
                          Technology
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          )}
          {selectedPatientOrganization && (
            <div className={styles.infoWrapper}>
              <div
                className={classNames(
                  styles.preWrapper,
                  isMobile ? "flex-column" : "flex-row"
                )}
              >
                {organizationLoading ? (
                  <div className={styles.skeletonBlock}>
                    <Skeleton
                      animation="wave"
                      variant="circle"
                      width={60}
                      height={60}
                    />
                    <div className={styles.skeletonText}>
                      {" "}
                      <Skeleton animation="wave" height={23} width="50%" />
                      <Skeleton animation="wave" height={23} width="70%" />
                    </div>
                  </div>
                ) : (
                  <PatientOrgTopDetails
                    blockFunctionality={blockFunctionality}
                    isAdmin={isAdmin}
                  />
                )}
                {!organizationLoading && !isMobile && (
                  <PatientOrgTopActions
                    isAdmin={isAdmin}
                    blockFunctionality={blockFunctionality}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div className={styles.container}>
          {patientOrgIsDownloading && patientOrgDownloadDetails?.length ? (
            tabList
              .filter((t) => patientOrgDownloadDetails.includes(t.name))
              .map((t) => (
                <div key={t.name} className="mb-3">
                  <h5 className="mb-3 pl-4 font-weight-bold mt-5">{t.name}</h5>
                  {t.component}
                </div>
              ))
          ) : (
            <NewTabs
              tabList={tabList.filter((t) => t.name !== "Discussions")}
              defaultIndex={selectedIndex < 0 ? 0 : selectedIndex}
              onSelect={(index) => handleOnTabSelect(index)}
              isNavigationHidden={organizationLoading}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default React.memo(PatientOrganizationComponent);

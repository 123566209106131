import React from "react";


const UpdateStepPosition = ({ handleUpdate, item }) => {
  return (
    <div
      onClick={() => handleUpdate(item)}
      className="ml-3 cursor-pointer"
    >
      <svg fill="#3994c1" width="24px" height="24px" viewBox="0 0 24 24" id="update-alt"
           data-name="Flat Line" xmlns="http://www.w3.org/2000/svg" className="icon flat-line">
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path id="primary" d="M5.07,8A8,8,0,0,1,20,12" style={{
            fill: "none",
            stroke: "#3994c1",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
          }}></path>
          <path id="primary-2" data-name="primary" d="M18.93,16A8,8,0,0,1,4,12" style={{
            fill: "none",
            stroke: "#3994c1",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
          }}></path>
          <polyline id="primary-3" data-name="primary" points="5 3 5 8 10 8" style={{
            fill: "none",
            stroke: "#3994c1",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
          }}></polyline>
          <polyline id="primary-4" data-name="primary" points="19 21 19 16 14 16" style={{
            fill: "none",
            stroke: "#3994c1",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2,
          }}></polyline>
        </g>
      </svg>

    </div>
  );
};

export default React.memo(UpdateStepPosition);

import { combineEpics } from "redux-observable";
import themesMainEpic from "./themesMainPage/themesMain.epic";
import themesOverviewEpic from "./themesIndividualPage/themesOverviewTab/overviewTab.epic";
import themesPostsEpic from "./themesIndividualPage/themesOverviewTab/postsBlock/postsBlock.epic";
import themesPipelineEpic from "./themesIndividualPage/themesPipelineTab/pipelineTab.epic";
import themesFeedEpic from "./themesIndividualPage/themesOverviewTab/postsBlock/feed/feed.epic";
import themesHomepageEpic from "./themesHomePageBlock/themesHomePage.epic";
import themesDocumentsEpic from "./themesIndividualPage/themesDocumentsTab/documentsTab.epic";
import themesProjectsEpic from "./themesIndividualPage/themesProjectsTab/projectsTab.epic";

const themesEpic = combineEpics(
  themesMainEpic,
  themesOverviewEpic,
  themesPostsEpic,
  themesPipelineEpic,
  themesFeedEpic,
  themesHomepageEpic,
  themesDocumentsEpic,
  themesProjectsEpic
);

export default themesEpic;

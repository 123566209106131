import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { ideasListGet } from "modules/ideasWrapper/idea.action";

import { addRelatedIdeaAttempt } from "../overview.action";
import { ModalFooter, ModalBody } from "../../../../../application/modal";
import {
  Button,
  BtnType,
  Label,
  MultiSelect,
  Loading,
} from "../../../../primitives";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../common/hooks";

const NewRelatedIdeaComponent = ({ relatedIdeas, selectedProjectId }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const [options, setOptions] = useState([]);
  const formRef = useRef();
  const [projectIdeas, setProjectIdeas] = useState([]);
  const [error, setError] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState("");
  const [currentQuery, setQuery] = useState("");

  const {
    councilReducer: { selectedCouncil },
    ideaReducer: { ideas, getIdeasListLoading },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil && debouncedValue === "") {
      dispatch(
        ideasListGet({
          enqueueSnackbar,
          items: 20,
          page: 1,
          query: "",
        })
      );
    }
  }, [selectedCouncil]);

  useEffect(() => {
    dispatch(
      ideasListGet({
        enqueueSnackbar,
        items: 20,
        page: 1,
        query: debouncedValue,
      })
    );
  }, [debouncedValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(currentQuery);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [currentQuery]);

  useEffect(() => {
    if (ideas.length) {
      setProjectIdeas(ideas);
    }
  }, [ideas]);

  useEffect(() => {
    if (projectIdeas.length) {
      const filtredOptions = projectIdeas.filter(
        (idea) =>
          !relatedIdeas?.find(
            (relatedIdea) => idea?.id === relatedIdea?.idea_id
          )
      );
      setOptions(filtredOptions);
    }
  }, [projectIdeas]);

  const handleCancelClick = (e) => {
    popup.hide();
  };

  const handleSaveClick = (e) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleOnSubmit = (value) => {
    if (!value?.id) {
      setError(true);
      return;
    }

    const payload = {
      enqueueSnackbar,
      project_idea: [
        {
          project_id: selectedProjectId,
          idea_id: value?.id,
        },
      ],
    };
    dispatch(addRelatedIdeaAttempt(payload));
    setError(false);
    popup.hide();
  };

  return (
    <>
      <ModalBody>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{ id: "" }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleOnSubmit(values);
          }}
        >
          {({ handleSubmit, setFieldValue, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.customPadding}>
                <Label>Ideas</Label>
                <div className={styles.selectorWrp}>
                  <MultiSelect
                    onChange={(val) => {
                      setFieldValue("id", val[0]?.id);
                      setError(false);
                    }}
                    placeholder="Choose idea"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    options={options}
                    searchFn={(args) => {
                      if (args.state.search === currentQuery) {
                        return;
                      }
                      setQuery(args.state.search);
                    }}
                    multi={false}
                  />
                  <div className={styles.loaderWrp}>
                    {getIdeasListLoading && <Loading hideString />}
                  </div>
                </div>

                <span className={styles.error}>
                  {error && "Select at least one idea!"}
                </span>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button btn={BtnType.REGULAR} onClick={handleSaveClick}>
          Add ideas
        </Button>
      </ModalFooter>
    </>
  );
};

export default NewRelatedIdeaComponent;

import React, { useEffect, useState, useRef } from "react";
import Select from "react-dropdown-select";

import styles from "./styles.module.scss";

const DropDownComponent = ({ item, handleUpdateForm, disabled }) => {
  const [selected, setSelected] = useState([]);
  const [isDropOpened, setIsDropOpened] = useState(false);
  const [focused, setFocused] = useState(false);
  const inputRef = useRef("");
  const searchRef = useRef("");

  useEffect(() => {
    if (item) {
      setSelected(item.option_selected || []);
    }
    return () => null;
  }, [item]);

  const check = (option, mode) => {
    if (isDropOpened || mode === "remove") {
      const isUnique = option.filter(
        (o1) => !selected.some((o2) => o1.value === o2),
      );

      if (isUnique || mode === "remove") {
        handleUpdateForm({
          ...item,
          option_selected: option.map((val) => val.value) || [],
        });
      }
      const selectedValues = option.map((opt) => opt.value);
      setSelected([...selectedValues]);
    }
  };

  const handleDropOpen = () => {
    setIsDropOpened(true);
    searchRef.current.focus();
  };

  const handleDropClose = () => {
    setIsDropOpened(false);
    clearSearch();
  };

  const handleSearch = (e) => {
    inputRef.current.setSearch(e);
    if (e.target.value) {
      setFocused(false);
    } else if (!focused) {
      setFocused(true);
    }
  };

  const clearSearch = () => {
    searchRef.current.value = "";
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <h5 className={styles.title}>{item?.field_name || "Dropdown"}</h5>
        {
          item?.field_placeholder?.length ? (
            <span className="text-muted small">{item.field_placeholder}</span>
          ) : null
        }
      </div>

      <div className={styles.container}>
        <div className={styles.dropWrp}>
          <Select
            closeOnSelect
            values={
              selected
                ? selected.map((item) => ({ text: item, value: item }))
                : []
            }
            contentRenderer={({ props, state }) => (
              <input
                className={
                  selected && selected.length && !item.multiselect && !focused
                    ? styles.filledPlaceholder
                    : styles.placeholder
                }
                onChange={handleSearch}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                ref={searchRef}
                placeholder={
                  item.multiselect || !selected.length
                    ? "Select"
                    : selected && selected.length && selected[0]
                }
              />
            )}
            options={
              item
              && item.option_values.map((item) => ({ text: item, value: item }))
            }
            onChange={(status) => {
              check(status);
            }}
            onDropdownOpen={handleDropOpen}
            onDropdownClose={handleDropClose}
            ref={inputRef}
            className={styles.dropWrapper}
            placeholder={"" || item.field_placeholder}
            labelField="text"
            valueField="value"
            searchBy="text"
            searchable
            multi={item.multiselect}
            disabled={disabled}
          />
        </div>
        {item.multiselect && selected.length > 0 && (
          <div className="d-flex flex-column">
            <span className={styles.selectionTitle}>SELECTION(S)</span>
            {selected.map((item) => (
              <div className={styles.option}>
                <div className={styles.textContainer}>
                  <span>{item}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default React.memo(DropDownComponent);

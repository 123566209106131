import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";

import {
  CheckBox, CheckType, Label, Loading,
} from "modules/primitives";
import styles from "./styles.module.scss";

const CheckListComponent = ({ item, handleUpdateForm, disabled }) => {
  const {
    companiesReducer: {
      companyReducer: { customFieldLoadingId },
    },
  } = useSelector((state) => state);

  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const [isESGField, setIsESGField] = useState(false);

  useEffect(() => {
    if (item) {
      setIsESGField(item.field_name === "ESG (COMMITMENT TO UN GOALS)");
    }

    return () => null;
  }, [item]);

  const check = (checked, value) => {
    let optionSelected = item.option_selected;
    if (checked) {
      if (item.multiselect) {
        optionSelected.push(value);
      } else {
        optionSelected = [value];
      }
    }
    if (!checked) {
      optionSelected = item.option_selected.filter((e) => e !== value);
    }

    handleUpdateForm({
      ...item,
      option_selected: optionSelected,
    });
  };

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [item?.field_type]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleRow}>
        <h5 className={styles.title}>{item?.field_name || "Checklist"}</h5>
        {
          item?.field_placeholder?.length ? (
            <span className="text-muted small">{item.field_placeholder}</span>
          ) : null
        }
      </div>

      <div ref={ref} className={classnames(styles.checkWrp, "row m-0")}>
        {item?.company_custom_field_id === customFieldLoadingId && (
          <div style={{ height: `${height}px` }}>
            <Loading />
          </div>
        )}
        {item?.company_custom_field_id !== customFieldLoadingId
          && item
          && item.option_values.map((box, idx) => (
            <div
              key={box}
              className={classnames(
                styles.formElement,
                "col-4",
              )}
            >
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={item.option_selected.includes(box)}
                onChange={(checked) => {
                  check(checked, box);
                }}
                disabled={disabled}
              />
              {isESGField && (
                <div
                  className={`${styles.esgImgWrapper} ${
                    styles[`esgImgWrapper-${idx + 1}`]
                  }`}
                />
              )}
              <Label>{box}</Label>
            </div>
          ))}
      </div>
    </div>
  );
};
export default React.memo(CheckListComponent);

import React, { memo, useEffect, useState } from "react";

import styles from "../innovationChartWrapper.module.scss";

const PieChart = ({ width, chartHeight, askForRedraw }) => {
  const [localWidth, setLocalWidth] = useState(null);

  useEffect(() => {
    if (localWidth !== width) {
      setLocalWidth(width);
      askForRedraw();
    }
  }, [width, localWidth]);

  return (
    <div className="d-flex flex-column">
      <div className={styles.chartWrapper} style={{ width: `${width}px` }}>
        <canvas id="pieChart" width={width} height={chartHeight} />
      </div>
    </div>
  );
};

export default memo(PieChart);

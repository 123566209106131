import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import styles from "./moreOptions.module.scss";
import { Icon, mdSize, smSize } from "../../../common/icon";
import { OptionType, OptionSize } from "./moreOptions.constant";
import { useOnClickOutside } from "../../../common/hooks";

const Menu = (props) => {
  const {
    children,
    options,
    className,
    onClick,
    hide = [],
    size = OptionSize.MEDIUM,
    customSvgIcon,
    customSvgIconSize = "32",
    iconName = "icn-more",
  } = props;
  const ref = useRef();
  // State for our modal
  const [isModalOpen, setModalOpen] = useState(false);
  const [optSize, setOptSize] = useState(mdSize);
  const [optList, setOptList] = useState([]);
  // Call hook passing in the ref and a function to call on outside click

  useOnClickOutside(ref, () => setModalOpen(false));

  useEffect(() => {
    if (size === OptionSize.SMALL) {
      setOptSize(smSize);
    } else {
      setOptSize(mdSize);
    }
  }, [size]);

  useEffect(() => {
    if (hide && hide.length > 0) {
      const filteredList = options.filter((opt) => !hide.includes(opt.val));
      setOptList(filteredList);
    } else {
      setOptList(options);
    }
  }, [options, hide]);

  const handleOptionClick = (e, val) => {
    onClick(val);
    setModalOpen(false);
  };

  const handleMoreClick = (e) => {
    setModalOpen(true);
  };

  return (
    <div className={styles.moreWrp}>
      {isModalOpen ? (
        <div ref={ref} className={classNames(styles.optionWrp, className)}>
          {optList?.map(
            ({ val, className, text, type = OptionType.REGULAR }) => (
              <div
                key={val + text}
                className={classNames(
                  styles.opt,
                  type === OptionType.DANGER && styles.danger,
                  className && className
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOptionClick(e, val);
                }}
              >
                {text}
              </div>
            )
          )}
        </div>
      ) : customSvgIcon ? (
        <span
          onClick={(e) => {
            e.stopPropagation();
            handleMoreClick();
          }}
        >
          <svg
            width={customSvgIconSize}
            height={customSvgIconSize}
            // viewBox={`0 0 ${customSvgIconSize} ${customSvgIconSize}`}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.667 6.333a2.333 2.333 0 1 0-4.666 0 2.333 2.333 0 0 0 4.666 0zm0 9.667A2.333 2.333 0 1 0 14 15.999a2.333 2.333 0 0 0 4.666.001zm0 9.667A2.333 2.333 0 1 0 14 25.666a2.333 2.333 0 0 0 4.666 0z"
              fill="#1D6F98"
            />
          </svg>
        </span>
      ) : (
        <Icon
          {...optSize}
          icon={iconName}
          className={styles.icon}
          onClick={(e) => {
            e.stopPropagation();
            handleMoreClick();
          }}
        />
      )}
    </div>
  );
};

export default Menu;

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { ModalFooter, ModalBody } from "../../../../application/modal";
import { accessOptions } from "../adminCustomFields.contans";
import { useModelPopup } from "../../../../common/hooks";
import {
  Button,
  BtnType,
  Label,
  DropSelect,
  MultiSelect,
  TextBox,
  CheckBox,
  CheckType,
} from "../../../primitives";
import styles from "../adminCustomfields.module.scss";
import {
  postCustomFieldsGroupsAttempt,
  putCustomFieldsGroupsAttempt,
} from "../adminCustomField.action";
import UserMultiSelectSearch from "../../../../common/components/usersSearch";
import { councilSet } from "modules/council/council.action";

const AdminCustomFieldGroupsModal = (prop) => {
  const { data, mode = "add" } = prop;
  const {
    authReducer: { session },
    adminCustomFieldsReducer: { customFieldGroups },
    teamsReducer: { allTeams },
    companiesReducer: {
      companyMainReducer: { topics },
    },
  } = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAccessValue, setSelectedAccessValue] = useState();
  const [selectedMembers, setSelectedMembers] = useState({});
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showTeam, setShowTeam] = useState(false);
  const [showMembers, setShowMembers] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupPosition, setGroupPosition] = useState(0);
  const [groupDescription, setGroupDescription] = useState("");
  const [error, setError] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [displayAsTable, setDisplayAsTable] = useState(
    data?.view_type === "table"
  );
  const [displayNumberTotals, setDisplayNumberTotals] = useState(
    data?.number_totals
  );
  const [usedPositions, setUsedPositions] = useState([]);
  const [filteredAccessOptions, setFilteredAccessOptions] = useState([]);
  const popup = useModelPopup();
  const dispatch = useDispatch();

  useEffect(() => {
    if (mode === "edit" && selectedAccessValue?.val === "tag") {
      setFilteredAccessOptions(accessOptions);
    } else {
      setFilteredAccessOptions(
        accessOptions.filter((option) => option.value !== "tag")
      );
    }
  }, [mode, selectedAccessValue]);

  useEffect(() => {
    if (data?.custom_field_group_id && mode === "edit") {
      if (data?.teams?.length) {
        setSelectedAccessValue([accessOptions[1]]);
        setSelectedTeams(data.teams);
        setShowTeam(true);
      }
      if (data?.users?.length) {
        setSelectedAccessValue([accessOptions[2]]);
        setSelectedMembers(data.users);
        setShowMembers(true);
      }
      if (data?.topics?.length) {
        setSelectedAccessValue([accessOptions[3]]);
        setSelectedTags(data.topics);
        setShowTags(true);
      }
      if (
        !data?.topics?.length &&
        !data?.users?.length &&
        !data?.teams?.length
      ) {
        setSelectedAccessValue([accessOptions[0]]);
      }
    } else {
      setSelectedAccessValue([accessOptions[0]]);
    }
  }, [data, mode]);

  const autoSelectValidStagePosition = (usedPositions) => {
    let freePosition;
    for (let i = 0; i <= usedPositions.length; i++) {
      if (i + 1 !== usedPositions[i]) {
        // setStagePosition(i + 1);
        freePosition = i + 1;
        break;
      }
    }
    return freePosition;
  };

  useEffect(() => {
    if (customFieldGroups?.length) {
      const sameCustomFieldGroup = customFieldGroups.find(
        (group) => group?.custom_field_group_id === data?.custom_field_group_id
      );
      const positions = customFieldGroups
        ?.map((group) => group?.position)
        .sort((a, b) => {
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
        });

      setUsedPositions(positions);

      if (sameCustomFieldGroup && mode === "edit") {
        setGroupPosition(data?.position);
      } else {
        setGroupPosition(autoSelectValidStagePosition(positions));
      }
    }
  }, [customFieldGroups]);

  useEffect(() => {
    if (data?.custom_field_group_id && mode === "edit") {
      setGroupName(data?.name);
      setGroupDescription(data?.description);
      setGroupPosition(data?.position);
      setIsActive(data?.active);
    }
  }, [data, mode]);

  useEffect(() => {
    if (selectedAccessValue?.val === "team") {
      setShowMembers(false);
      setShowTeam(true);
      setShowTags(false);
      setSelectedTags([]);
      setSelectedMembers([]);
      if (mode === "edit") {
        setSelectedTeams(data?.teams);
      }
    } else if (selectedAccessValue?.val === "individual") {
      setShowMembers(true);
      setShowTeam(false);
      setShowTags(false);
      setSelectedTags([]);
      setSelectedTeams([]);
      if (mode === "edit") {
        setSelectedMembers(data?.users);
      }
    } else if (selectedAccessValue?.val === "tag") {
      setShowTags(true);
      setShowMembers(false);
      setShowTeam(false);
      setSelectedMembers([]);
      setSelectedTeams([]);
      if (mode === "edit") {
        setSelectedTags(data?.topics);
      }
    } else {
      setShowTeam(false);
      setShowMembers(false);
      setShowTags(false);
      setSelectedTags([]);
      setSelectedMembers([]);
      setSelectedTeams([]);
    }
  }, [selectedAccessValue]);

  const handleAccessOption = (value) => {
    setSelectedTeams([]);
    setSelectedMembers([]);
    setSelectedTags([]);
    setSelectedAccessValue(value);
  };

  const handleSelectTeams = (value) => {
    setSelectedTeams(value);
  };

  const handleOptionMemberSelect = (value) => {
    setSelectedMembers(value);
  };

  const handleSelectTags = (value) => {
    setSelectedTags(value);
  };

  const handleOnSubmit = () => {
    if (!groupName) {
      setError("Group nane is required.");
      return;
    }
    if (Number(groupPosition) <= 0) {
      setError("Group position is required.");
      return;
    }

    if (showMembers && Object.keys(selectedMembers).length === 0) {
      setError("Member is required to be selected.");
      return;
    }

    if (showTeam && selectedTeams.length === 0) {
      setError("Team is required to be selected.");
      return;
    }

    // const usedPosition = usedPositions?.find(
    //   (poz) => poz === Number(groupPosition),
    // );

    // if (usedPosition && usedPosition !== data?.position) {
    //   setError("This position is already used, please select another one.");
    //   return;
    // }

    const groupData = {
      name: groupName,
      position: groupPosition,
      description: groupDescription,
      active: isActive,
      access: selectedAccessValue.val,
      team_ids: selectedTeams?.length
        ? selectedTeams?.map((team) => team?.id)
        : [],
      user_ids: selectedMembers?.length
        ? selectedMembers?.map((member) => member?.id)
        : [],
      topic_ids: selectedTags?.length
        ? selectedTags?.map((tag) => tag?.id)
        : [],
      public:
        selectedAccessValue.val === "all" ||
        Boolean(
          !selectedTeams?.length &&
            !selectedMembers?.length &&
            !selectedTags?.length
        ),
      view_type: displayAsTable ? "table" : "pipeline",
      number_totals: !displayAsTable ? false : displayNumberTotals,
    };

    if (mode === "edit") {
      dispatch(
        putCustomFieldsGroupsAttempt({
          data: groupData,
          groupId: data?.custom_field_group_id,
          enqueueSnackbar,
        })
      );
    } else if (mode === "add") {
      dispatch(
        postCustomFieldsGroupsAttempt({
          data: groupData,
          enqueueSnackbar,
          cb: (res) => {
            const selectedCouncil = JSON.parse(localStorage.getItem("council"));
            const newCouncilData = {
              ...selectedCouncil,
              custom_field_groups: [
                {
                  ...res.response,
                  id: res.response.custom_field_group_id,
                },
                ...selectedCouncil.custom_field_groups,
              ],
              integrated_tokens: session?.integrated_tokens,
            };
            dispatch(councilSet(newCouncilData));
          },
        })
      );
    }

    popup.hide();
  };

  const hasUnAcceptedFields = useCallback(() => {
    if (!data?.custom_field_templates?.length || data?.view_type === "table") {
      return false;
    }

    const unAcceptedFields = [
      "video",
      "slides",
      "document",
      "image",
      "agreement",
    ];

    return data?.custom_field_templates?.some(({ field_type }) =>
      unAcceptedFields.includes(field_type)
    );
  }, [data]);

  const checkAvailability = () => {
    if (
      data?.name === "Requested Information" ||
      data?.name === "Due Diligence Notes" ||
      data?.name === "Closing Notes" ||
      data?.name === "Portfolio Notes" ||
      data?.name === "Exit Notes" ||
      data?.name === "Dismiss Notes"
    ) {
      return true;
    }

    return false;
  };
  return (
    <div className={styles.wrapper}>
      <ModalBody noFixedHeight className={styles.modalWrapper}>
        <div className="row">
          <div className="col-12">
            <Label>Group Name</Label>
            <TextBox
              type="text"
              placeholder="Group Name"
              value={groupName}
              onChange={(event) => setGroupName(event?.target?.value)}
              className={styles.textBox}
              disabled={checkAvailability()}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Label>Description</Label>
            <div className={classNames(styles.textBoxWrp)}>
              <textarea
                value={groupDescription}
                placeholder="Group description"
                onChange={(event) => setGroupDescription(event.target.value)}
                className={styles.textBox}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Label>Position</Label>
            <TextBox
              type="number"
              placeholder="Position"
              min={1}
              value={groupPosition}
              onChange={(event) => setGroupPosition(event.target.value)}
              className={styles.textBox}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Label>Access</Label>
            <DropSelect
              SelecedValues={selectedAccessValue}
              onChange={handleAccessOption}
              placeholder="Select Access Item"
              labelField="text"
              valueField="value"
              searchBy="text"
              options={filteredAccessOptions}
            />
          </div>
        </div>
        {showTeam && (
          <div className="row">
            <div className="col-12">
              <Label>Select Teams</Label>
              <MultiSelect
                values={selectedTeams}
                labelField="name"
                valueField="id"
                onChange={handleSelectTeams}
                options={allTeams}
                multi
              />
            </div>
          </div>
        )}

        {showMembers && (
          <div className="row">
            <div className="col-12">
              <Label>Select Member</Label>
              <UserMultiSelectSearch
                placeholder="Select Member"
                labelField="full_name"
                valueField="id"
                searchBy="full_name"
                values={selectedMembers}
                onChange={handleOptionMemberSelect}
                multi
              />
            </div>
          </div>
        )}
        {showTags && (
          <div className="row">
            <div className="col-12">
              <Label>Select Tags</Label>
              <MultiSelect
                values={selectedTags}
                labelField="name"
                valueField="id"
                onChange={handleSelectTags}
                options={topics}
                multi
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12">
            {!hasUnAcceptedFields() ? (
              <div className={styles.activeCheckbox}>
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={displayAsTable}
                  onChange={(value) => setDisplayAsTable(value)}
                />
                <Label>Display as Table</Label>
              </div>
            ) : null}
            {displayAsTable ? (
              <div className={styles.activeCheckbox}>
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={displayNumberTotals}
                  onChange={(value) => setDisplayNumberTotals(value)}
                />
                <Label>Display Number Totals</Label>
              </div>
            ) : null}
            <div className={styles.activeCheckbox}>
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={isActive}
                onChange={(value) => setIsActive(value)}
              />
              <Label>Set Active</Label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {error && <span className={styles.error}>{error}</span>}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={() => popup.hide()}>
          Cancel
        </Button>

        <Button type="submit" btn={BtnType.REGULAR} onClick={handleOnSubmit}>
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default AdminCustomFieldGroupsModal;

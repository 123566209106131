import React, { useEffect, useRef, useState } from "react";

import styles from "./uploadProductImages.module.scss";
import LinearProgressWithLabel from "common/components/linearProgressWithLabel";
import useS3FileUpload from "common/hooks/s3FileUplodar.hook";
import DragAndDrop from "common/components/dragAndDrop";
import { Icon, mdSize } from "../../../../../../../common/icon";
import { checkIfS3 } from "modules/admin/adminCompanies/editCompany/editCompany.helper";

const allowedExtensions = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "tiff",
  "bmp",
  "eps",
  "raw",
  "cr2",
  "nef",
  "orf",
  "sr2",
];

const UploadProductImages = ({ uploadDirName, product, handleUpload }) => {
  const uploadBlockRef = useRef(null);
  const { fileUpload, fileDelete } = useS3FileUpload();
  const [uploadValues, setUploadValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (product) {
      setImages(product?.images || []);
    }
  }, [product]);

  const getExtension = (name) => name.split(/[#?]/)[0].split(".").pop().trim();

  const getFilename = (url) => {
    if (url) {
      return url.split("/").pop();
    }
    return "";
  };

  const uploadHandler = async (e, action) => {
    e.preventDefault();
    // we want to let user know that he is dragging, so we change the border color for the block
    if (action === "drag" && uploadBlockRef?.current) {
      return;
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = e.target.files;
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files?.length) {
      return;
    }

    const filteredFiles = [];

    for (const file of files) {
      if (file.size <= 100000000 && allowedExtensions.indexOf(getExtension(file.name)) !== -1) {
        filteredFiles.push(file);
      }
    }

    setLoading(true);

    filteredFiles.forEach(async (file, index, array) => {
      const isLastIndex = Boolean(index === array?.length - 1);
      const newFileName = file.name.replace(/ /g,"_");
      await fileUpload(file, uploadDirName, setUploadValues, newFileName, 0).then(
        (res) => {
          if (res && res.location) {
            const newImages = [...images, res.location];
            setImages(newImages);
            setUploadValues([...uploadValues.map((uri) => ({
              uri,
              name: getFilename(uri),
            }))]);

            if (isLastIndex) {
              setLoading(false);
              handleUpload(newImages);
            }
          }
        }
      );
    });

    e.target.value = "";
  };

  const handleDeleteImage = async (evt, index) => {
    evt.stopPropagation();

    if (checkIfS3(images[index])) {
      const pathname = `${uploadDirName}/${getFilename(images[index])}`;

      await fileDelete(pathname).then(() => {
        const newImages = images.filter((_, idx) => idx !== index);
        handleUpload(newImages);
        setImages(newImages);
      });
    }
  };

  return (
    <div className={styles.uploadImgWrp}>
      {
        images?.length ? (
          <div className={styles.imagesList}>
            <div className="mr-1 position-relative d-flex flex-row flex-wrap">
              {
                images.map((image, index) => (
                  <div key={`${image}-${index}`} className="position-relative">
                    <Icon
                      {...mdSize}
                      className={styles.deleteIcon}
                      icon="icn-button-delete"
                      onClick={(e) => handleDeleteImage(e, index)}
                    />
                    <div
                      className={styles.imageWrapper}
                      key={`${image}_${index}`}
                      style={{ backgroundImage: `url(${image})` }}
                      title={product.name}
                    ></div>
                  </div>
                ))
              }
            </div>
          </div>
        ) : null
      }
      <div className="mt-4">
        {
          uploadValues.map((u, idx) => (
            <LinearProgressWithLabel key={u + idx} value={u.progress || 0} />
          ))
        }
        <div className="mt-4">
          <DragAndDrop
            uploadHandler={uploadHandler}
            loading={loading}
            customText="drag and drop images here"
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(UploadProductImages);

import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";

import { postsReplyGetAttempt, postsReplyAddSuccess } from "./feed.action";
import styles from "./styles.module.scss";
import Icon from "../../../../../../common/icon/icon.component";
import { lgSize } from "../../../../../../common/icon";
import { Loading } from "../../../../../primitives/index";
import FeedItem from "./feedItem/index";
import TextAreaFeed from "./textAreaFeed";
import { isLocalhost } from "../../../../../../serviceWorker";
import { getCookie } from "../../../../../../common/helper";

const FeedComponent = (props) => {
  const { post, handleCloseFeed, replyData, homePageUse } = props;
  const [editableReply, setEditableReply] = useState({});
  const [mode, setMode] = useState("add");
  const feedComponentRef = useRef();
  const dispatch = useDispatch();
  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    themesReducer: {
      themesFeedReducer: {
        postRepliesList,
        loadingPostReplies,
        scrollToLastMessage,
      },
    },
  } = useSelector((state) => state);
  const level = 1;
  const [selectedReply, setSelectedReply] = useState({});

  const handleSelectReply = (reply) => {
    setSelectedReply(reply);
  };

  useEffect(() => {
    if (scrollToLastMessage && postRepliesList.theme_post_replies?.length) {
      let element = document.getElementById(
        postRepliesList.theme_post_replies[
          postRepliesList.theme_post_replies.length - 1
        ].theme_post_reply_id
      );
      if (selectedReply.theme_post_reply_id) {
        element = document.getElementById(selectedReply.theme_post_reply_id);
        if (selectedReply?.replied_theme_post_reply_id && element) {
          feedComponentRef.current.scrollTop =
            element.offsetTop - 600 - feedComponentRef.current.scrollTop;
        } else {
          feedComponentRef.current.scrollTop =
            element.offsetTop - 270 - feedComponentRef.current.scrollTop;
        }
      } else if (feedComponentRef.current) {
        feedComponentRef.current.scrollTop =
          feedComponentRef.current.scrollHeight;
      }
      setSelectedReply("");
    }
  }, [scrollToLastMessage]);

  const scrollToBottom = () => {
    const scrolled = feedComponentRef.current?.scroll(
      0,
      feedComponentRef.current.scrollHeight + 30
    );
  };

  useEffect(() => {
    if (postRepliesList.theme_post_replies?.length && !loadingPostReplies) {
      scrollToBottom();

      if (feedComponentRef && feedComponentRef.current) {
        feedComponentRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }
  }, [postRepliesList.theme_post_replies, loadingPostReplies]);

  useEffect(() => {
    if (session?.id) {
      let socket;

      if (isLocalhost) {
        const uid = getCookie("uid");
        const accessToken = getCookie("access_token");
        const client = getCookie("client");

        socket = new WebSocket(
          `${process.env.REACT_APP_WSS}/cable?access_token=${accessToken}&client=${client}&uid=${uid}`,
        );
      } else {
        socket = new WebSocket(
          `${process.env.REACT_APP_WSS}/cable`,
        );
      }

      socket.onopen = () => {
        const msg = {
          command: "subscribe",
          identifier: JSON.stringify({
            theme_id: post.theme_post_id,
            channel: "ThemePostRepliesChannel",
          }),
        };
        socket.send(JSON.stringify(msg));
      };
      try {
        socket.onmessage = (event) => {
          const parsedData = JSON.parse(event.data);
          if (
            parsedData.identifier?.includes("ThemePostRepliesChannel") &&
            parsedData.message &&
            parsedData.message?.length > 0
          ) {
            dispatch(postsReplyAddSuccess({ response: parsedData.message }));
          }
        };
        socket.onclose = () => {
          console.log("disconnected in feed");
        };
        socket.onerror = (error) => {
          console.log("Error: " + JSON.stringify(error));
        };
      } catch (error) {
        console.log("Error catch: " + error);
      }
      return () => {
        socket.close();
        console.log("unmounted");
      };
    }
  }, [session?.id]);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        postsReplyGetAttempt({
          theme_id: post?.theme_id,
          post_id: post?.theme_post_id,
        })
      );
    }
  }, [selectedCouncil]);

  return (
    <div className={styles.feedBackground} onMouseDown={handleCloseFeed}>
      <div
        className={styles.threadContainer}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <header className={styles.threadHeader}>
          <div className={styles.title}>Thread</div>
          <div className={styles.closeIcon} onClick={handleCloseFeed}>
            <Icon {...lgSize} icon="icn-add" />
          </div>
        </header>
        <div
          ref={feedComponentRef}
          className={classnames(
            styles.threadContent,
            selectedReply?.created_at && styles.threadContentFullHeight
          )}
        >
          <FeedItem
            replyData={post}
            selectedReply={selectedReply}
            homePageUse={homePageUse}
            topBlock
            handleSelectReply={handleSelectReply}
            themeId={post.theme_id}
            setMode={setMode}
            setEditableReply={setEditableReply}
          />
          {loadingPostReplies && <Loading />}
          {postRepliesList?.theme_post_replies?.map((feed) => (
            <div
              className={styles.outerFeedContainer}
              id={feed.theme_post_reply_id}
              key={feed.theme_post_reply_id}
            >
              {feed.loading && <Loading />}
              <FeedItem
                replyData={feed}
                replies={feed.replies}
                level={level}
                selectedReply={selectedReply}
                homePageUse={homePageUse}
                handleSelectReply={handleSelectReply}
                themeId={post.theme_id}
                setEditableReply={setEditableReply}
                setMode={setMode}
              />
            </div>
          ))}
        </div>
        {!selectedReply?.created_at && (
          <TextAreaFeed
            mainTextArea
            mode={mode}
            homePageUse={homePageUse}
            handleSelectReply={handleSelectReply}
            replyData={replyData}
            themeId={post.theme_id}
            themePostId={post.theme_post_id}
            editableReply={editableReply}
            setEditableReply={setEditableReply}
          />
        )}
      </div>
    </div>
  );
};
export default FeedComponent;

import React, { memo } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { DropSelect } from "../../../../../primitives";
import { AXIS_DATA, COLORS } from "../charts-constants";
import styles from "./filters.module.scss";

const BubbleFilters = ({ handleSelect }) => {
  const {
    companiesReducer: {
      companyMainReducer: {
        charts: { chartOptions, viewType },
      },
    },
  } = useSelector((state) => state);

  const filteredOptionsByCriteria = viewType === "aggregate"
    ? AXIS_DATA.filter((option) => option.type !== "council_company_score")
    : AXIS_DATA.filter((option) => option.type !== "numberOfCompanies");
  const Y = filteredOptionsByCriteria.filter(
    (c) => c.type !== "yearsInOperation",
  );

  return (
    <div className="d-flex w-100 bubble-options justify-content-between">
      <div className="d-flex">
        <div
          className={classNames(
            "d-flex flex-column ml-3 mr-3 custom-drop-width",
            chartOptions.y === chartOptions.x
              || chartOptions.y === chartOptions.size
              ? styles.hoverField
              : "",
            styles.chartsSelect,
          )}
        >
          <label className="mb-1" htmlFor="yAxis">
            Y-Axis
          </label>
          <DropSelect
            placeholder=""
            name="yAxis"
            options={Y}
            labelField="name"
            valueField="type"
            searchBy="name"
            values={filteredOptionsByCriteria.filter(
              (c) => c.type === chartOptions.y,
            )}
            onChange={(e) => handleSelect("y", e.val)}
            dropdownPosition="auto"
          />
        </div>
        <div
          className={classNames(
            "d-flex flex-column mr-3 custom-drop-width",
            chartOptions.x === chartOptions.y
              || chartOptions.x === chartOptions.size
              ? styles.hoverField
              : "",
            styles.chartsSelect,
          )}
        >
          <label className="mb-1" htmlFor="xAxis">
            X-Axis
          </label>
          <DropSelect
            placeholder=""
            name="xAxis"
            options={filteredOptionsByCriteria}
            labelField="name"
            valueField="type"
            searchBy="name"
            values={filteredOptionsByCriteria.filter(
              (c) => c.type === chartOptions.x,
            )}
            onChange={(e) => handleSelect("x", e.val)}
            dropdownPosition="auto"
          />
        </div>
        <div
          className={classNames(
            "d-flex flex-column mr-3 custom-drop-width",
            chartOptions.size === chartOptions.x
              || chartOptions.y === chartOptions.size
              ? styles.hoverField
              : "",
            styles.chartsSelect,
          )}
        >
          <label className="mb-1" htmlFor="color">
            Size
          </label>
          <DropSelect
            placeholder=""
            name="size"
            options={filteredOptionsByCriteria}
            labelField="name"
            valueField="type"
            searchBy="name"
            values={filteredOptionsByCriteria.filter(
              (c) => c.type === chartOptions.size,
            )}
            onChange={(e) => handleSelect("size", e.val)}
            dropdownPosition="auto"
          />
        </div>
      </div>
      <div className="d-flex">
        <div
          className={classNames(
            "d-flex flex-column custom-drop-width",
            styles.chartsSelect,
          )}
        >
          <label className="mb-1" htmlFor="color">
            Breakdown
          </label>
          <DropSelect
            placeholder=""
            name="color"
            options={COLORS}
            labelField="name"
            valueField="type"
            searchBy="name"
            values={COLORS.filter((c) => c.type === chartOptions.color)}
            onChange={(e) => handleSelect("color", e.val)}
            dropdownPosition="auto"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(BubbleFilters);

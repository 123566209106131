import { httpPost } from "../../httpCall";
import { queryParamStringToArray } from "../../helper";

const downloadTable = (
  type,
  location,
  countryFilters,
  handleClose,
  endpoint,
  payload,
  handleLoading,
  params = "",
  chartType,
  investmentTrack,
  checkedPatientFilters,
  selectedOrganizations
) => {
  handleClose();

  let reqQuery = {
    query: "",
    search: {
      query: "",
      topic_ids: [],
      industry_ids: [],
      therapeutic_area_ids: [],
      relationship_stages: [],
      council_company_score_gte: 0,
      council_company_score_lte: 0,
      founded_gte: "",
      founded_lte: "",
      total_fundings_sum_gte: 0,
      total_fundings_sum_lte: null,
      relationship_owners_ids: [],
      num_employees_enums: null,
      revenue_ranges: "",
      agreement_types_ids: [],
      location_countries: [],
      disease_ids: [],
      diversity_group_ids: [],
      countries: [],
      lists: [],
    },
  };

  if (location?.state) {
    const state = location?.state;
    reqQuery = {
      query: state.query,
      search: {
        agreement_types_ids: queryParamStringToArray(
          location.state?.agreements || ""
        ),
        industry_ids: queryParamStringToArray(location.state?.industry || ""),
        topic_ids: queryParamStringToArray(location.state?.topic || ""),
        council_relationship_stage_id: queryParamStringToArray(
          location.state?.council_relationship_stage_id ||
            location.state?.relationShipStatus ||
            ""
        ),
        council_company_score_gte: location.state?.scoreFrom
          ? Number(location.state?.scoreFrom)
          : 0,
        council_company_score_lte: location.state?.scoreTo
          ? Number(location.state?.scoreTo)
          : 10,
        founded_gte: location.state?.foundedFrom
          ? location.state?.foundedFrom
          : null,
        founded_lte: location.state?.foundedTo
          ? location.state?.foundedTo
          : null,
        total_fundings_sum_gte: location.state?.fundingFrom
          ? Number(location.state?.fundingFrom.replace(/\D/g, ""))
          : 0,
        total_fundings_sum_lte:
          location.state?.fundingTo &&
          location.state?.fundingTo !== "Any Amount"
            ? Number(location.state?.fundingTo.replace(/\D/g, ""))
            : null,
        relationship_owners_ids: queryParamStringToArray(
          location.state?.owners || ""
        ),
        num_employees_enums: location.state?.employees
          ? location.state?.employees
          : "",
        revenue_ranges: location.state?.estimatedRevenue
          ? queryParamStringToArray(location.state?.estimatedRevenue)
          : "",
        location_countries: countryFilters,
        therapeutic_area_ids: [],
        lists: location.state.lists ? location.state.lists.split(",") : null,
      },
    };
  }

  if (countryFilters) {
    reqQuery.search = {
      ...reqQuery.search,
      countries: countryFilters,
    };
  }

  if (checkedPatientFilters) {
    reqQuery.search = {
      ...reqQuery.search,
      ...checkedPatientFilters,
    };
  }

  if (selectedOrganizations) {
    reqQuery.search = {
      ...reqQuery.search,
      patient_organization_ids: selectedOrganizations.map((org) => org.id),
    };
  }

  if (reqQuery.sort_by) {
    reqQuery.sort_attribute = reqQuery.sort_by;
  }

  handleLoading && handleLoading(true);
  httpPost({
    call: `reports/${endpoint}?type=${type}${params}&chart_type=${chartType}&investment_track=${investmentTrack}`,
    data: payload || reqQuery,
    respType: "blob",
  })
    .pipe()
    .subscribe((res) => {
      const url = window.URL.createObjectURL(new Blob([res.response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${endpoint}.${type}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      handleLoading && handleLoading(false);
    });
};
export default downloadTable;

import React, { useState, useEffect, useMemo } from "react";

import { httpDelete, httpGet, httpPost } from "../../../../../common/httpCall";
import styles from "modules/projects/project/tasks/taskInfo/taskInfo.module.scss";
import { BtnType, Button } from "modules/primitives";
import { format } from "date-fns";

const RequestFeedback = ({ task }) => {
  const [feedbackRequested, setFeedbackRequested] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  const [loadedMore, setLoadedMore] = useState(false);

  useEffect(() => {
    httpGet({
      call: `projects/task_request_feedbacks?projects_task_id=${task.id}`,
    }).subscribe((res) => {
      setFeedbackRequested(res?.response?.feedback_requested);
      setFeedbacks(res?.response?.feedbacks || []);
    });
  }, []);

  const handleFeedbackRequest = () => {
    httpPost({
      call: "projects/task_request_feedbacks",
      data: {
        projects_task_id: task.id,
      },
    }).subscribe((res) => {
      setFeedbackRequested(res?.response?.feedback_requested);
      setFeedbacks(res?.response?.feedbacks || []);
    });
  };

  const makeBtnName = useMemo(() => {
    if (task?.name === "Ask Team to Rate Project") {
      return feedbackRequested ? "Request Sent √" : "Send Request";
    }

    return feedbackRequested ? "Feedback Requested √" : "Request Feedback";
  }, [task?.name, feedbackRequested]);

  const handleReset = () => {
    httpDelete({
      call: `projects/task_request_feedbacks?projects_task_id=${task.id}`,
    }).subscribe(() => {
      setFeedbackRequested(false);
    });
  };

  const makeFeedbackLabel = (f) => {
    const dateObj = new Date(f.created_at);
    const time = format(dateObj, "eeee, MMM do, yyyy");
    const timeSecond = format(dateObj, "h:mm a");
    return `Request sent on ${time} at ${timeSecond} by ${f.user.name}`;
  };

  return (
    <div className="d-flex flex-column mb-3">
      <h4 className={`${styles.taskName} pb-0`}>{task.name}</h4>
      <div className="d-flex justify-content-between align-items-center">
        <Button
          disabled={feedbackRequested}
          className="ml-3"
          btn={BtnType.OUTLINE_LIGHT}
          onClick={handleFeedbackRequest}
        >
          {makeBtnName}
        </Button>
        <div className="mr-3">
          {feedbackRequested && (
            <span
              className="cursor-pointer small text-muted"
              onClick={handleReset}
            >
              reset
            </span>
          )}
        </div>
      </div>
      {feedbacks.length > 0 ? (
        <div className="mt-3 ml-3">
          {feedbacks.slice(0, loadedMore ? feedbacks.length : 5).map((f) => (
            <div key={f.id} className="small mb-1">
              {makeFeedbackLabel(f)}
            </div>
          ))}
          {feedbacks.length > 5 ? (
            <span
              className="cursor-pointer small text-muted"
              onClick={() => setLoadedMore(!loadedMore)}
            >
              {loadedMore ? "show less" : "show more"}
            </span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(RequestFeedback);

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styles from './styles.module.scss'
import {
  makeCustomFreddieMacData,
} from "modules/ideasWrapper/ideaProfileWrapper/ideaFreddieMacOnly/constants";

import Image0 from './0.png';
import Image1 from './1.png';
import Image2 from './2.png';
import Image3 from './3.png';
import Image4 from './4.png';
import Image5 from './5.png';
import Image6 from './6.png';
import Image7 from './7.png';

const imagesByIndex = {
  0: Image0,
  1: Image1,
  2: Image2,
  3: Image2,
  4: Image3,
  5: Image5,
  6: Image6,
  7: Image7,
  8: Image5,
  99: Image4
}

const imagesByIndex1 = {
  0: Image0,
  1: Image1,
  2: Image2,
  3: Image2,
  4: Image3,
  5: Image5,
  6: Image6,
  7: Image6,
  8: Image5,
  9: Image7,
  99: Image4
}

const IdeaFreddieMacOnly = () => {
  const {
    ideaReducer: { selectedIdea },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (selectedIdea) {
      const ideaFields = selectedIdea?.ideas_descriptions || [];
      const customArr = makeCustomFreddieMacData(ideaFields);

      setSections(customArr);
    }
  }, [selectedIdea]);

  return (
    <section className="p-3 bg-info" id="freddieMacLeanCanvas">
      <h2 className="d-flex justify-content-center text-white mb-4">
        {selectedCouncil?.name} Lean Canvas: {selectedIdea.name}
      </h2>
      {
        sections.map((bigRow, index) => (
          <div key={index} className={bigRow.className}>
            {
              bigRow.childRows.map((nextRow, index1) => (
                <div key={`${index}_${index1}`} className={nextRow.className}>
                  {
                    nextRow.childChildRows ? nextRow.childChildRows.map((childRow, index2) => (
                      <div className={childRow.className} key={`${index}_${index1}_${index2}`}>
                        {
                          childRow.sections.map((section, index3) => (
                            <div key={`${index}_${index1}_${index2}_${index3}`} className={`${styles.card} ${section.className}`}>
                              <div className={styles.cardHeader}>
                                <span className={styles.cardTitle}>{section.name}</span>
                                <div className={styles.cardCircle}>
                                  {
                                    section.index < 99 && (
                                      <img src={imagesByIndex1[section.index]} alt={section.name} title={section.name} />
                                    )
                                  }
                                </div>
                              </div>
                              <div className={styles.cardBody}>
                                <span className="small word-break">{section.section_value || 'No value!'}</span>
                                {
                                  section.section_type !== 'text' && (
                                    <div className={styles.smallGreenDivider}></div>
                                  )
                                }
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    )) : null
                  }
                  {
                    nextRow.sections ? nextRow.sections.map((section, index2) => (
                      <div key={`${index}_${index1}_${index2}`} className={`${styles.card} ${section.className}`}>
                        <div className={styles.cardHeader}>
                          <span className={styles.cardTitle}>{section.name}</span>
                          <div className={styles.cardCircle}>
                            {
                              section.index < 99 && (
                                <img src={imagesByIndex1[section.index]} />
                              )
                            }
                          </div>
                        </div>
                        <div className={styles.cardBody}>
                          <span className="small word-break">{section.section_value || 'No value!'}</span>
                          {
                            section.section_type !== 'text' && (
                              <div className={styles.smallGreenDivider}></div>
                            )
                          }
                        </div>
                      </div>
                    )) : null
                  }
                </div>
              ))
            }
            {
              bigRow.containsElements === 5 && (
                <div className={`${styles.card} ${bigRow.fifthElement.className}`}>
                  <div className={styles.cardHeader}>
                    <span className={styles.cardTitle}>{bigRow.fifthElement.name || 'Customer Segments'}</span>
                    <div className={styles.cardCircle}>
                      <img src={imagesByIndex1[99]} />
                    </div>
                  </div>
                  <div className={styles.cardBody}>
                    <span className="small word-break">{bigRow?.fifthElement?.section_value || 'No value!'}</span>
                    {
                      bigRow?.fifthElement?.section_type !== 'text' && (
                        <div className={styles.smallGreenDivider}></div>
                      )
                    }
                    {/*<ul className="small px-3">*/}
                    {/*  <li>Define the end customer clearly. Who are they, and what are their needs?</li>*/}
                    {/*  <li>Identify the early adopters within your customer base</li>*/}
                    {/*</ul>*/}
                  </div>
                </div>
              )
            }
          </div>
        ))
      }
    </section>
  );
};

export default React.memo(IdeaFreddieMacOnly);

import React from "react";

import styles from "./styles.module.scss";
import { Label, TextArea, TextBox } from "../../../../../primitives";
import CheckBoxesFieldsBlock from "../checkBoxesFieldsBlock";
import ApplicantDocument from "modules/admin/adminApplicants/forms/formSteps/document";

const checkboxOptions = [
  {
    label: "Company Name",
    name: "companyName",
    isChecked: true,
    disabled: true,
  },
  {
    label: "Website",
    name: "website",
    isChecked: true,
    disabled: true,
  },
  {
    label: "Applicant Name",
    name: "applicantName",
    isChecked: true,
    disabled: true,
  },
  {
    label: "Applicant Title",
    name: "applicantTitle",
    isChecked: true,
    disabled: true,
  },
  {
    label: "Applicant Contact Email",
    name: "applicantEmail",
    isChecked: true,
    disabled: true,
  },
];

const StepOneComponent = ({ formikprops }) => {
  const { values, setFieldValue } = formikprops;

  return (
    <div className={styles.createFormStepOne}>
      <span>The following required fields will appear in your form</span>

      <div className={styles.formElement}>
        <Label>Form title </Label>
        <TextBox
          type="text"
          name="form_title"
          placeholder="Form title"
          formProps={formikprops}
          className="w-100"
        />
      </div>

      <div className={styles.formElement}>
        <Label>Instructions</Label>
        <TextArea
          name="form_instruction_text"
          placeholder="Form instruction"
          formProps={formikprops}
          className="w-100"
        />
      </div>

      <div className={styles.formElement}>
        <Label>Upload Document (Optional)</Label>
        <ApplicantDocument formProps={formikprops} />
      </div>

      <div className={styles.formElement}>
        <CheckBoxesFieldsBlock options={checkboxOptions} isBlockDisabled />
      </div>

      <div className={styles.formElement}>
        <div className="form-check pl-0">
          <span className="mr-5">
            Automatically Add Applicants to Company Database?
          </span>
          <label className="form-check-label mr-5">
            <input
              className="form-check-input"
              type="radio"
              name="add_applicant_to_company_database"
              value="true"
              onChange={() => setFieldValue("add_applicant_to_company_database", true)}
              checked={values?.add_applicant_to_company_database === true}
            />
            Yes
          </label>

          <label className="form-check-label">
            <input
              className="form-check-input"
              type="radio"
              name="add_applicant_to_company_database"
              value="false"
              onChange={() => setFieldValue("add_applicant_to_company_database", false)}
              checked={values?.add_applicant_to_company_database === false}
            />
            No
          </label>
        </div>
      </div>
    </div>
  );
};

export default React.memo(StepOneComponent);

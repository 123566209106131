import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import styles from '../styles.module.scss';
import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import SectionCard from "modules/reporting/fordReports/actualScorecard/sections/sectionCard";
import {
  getActualScoreData,
  getActualScoreTableData,
  getPocTableData,
  setActualScoreCardFilters,
} from "modules/reporting/fordReports/actualScorecard/store/actualScoreCard.actions";
import FordActiveFilters from "modules/reporting/fordReports/filters/activeFilters";
import PocTableDataCard from "modules/reporting/fordReports/actualScorecard/sections/pocTableCard";
import SectionLineChartCard from "modules/reporting/fordReports/actualScorecard/sections/sectionLineChartCard";

const FordScoreCards = ({ filters, downloadIsActive }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    fordDashboardReducer: { actualScoreCardReducer }
  } = useSelector((state) => state);
  const data = useMemo(() => actualScoreCardReducer.charts.data, [actualScoreCardReducer.charts.data]);
  const fetching = useMemo(() => actualScoreCardReducer.charts.fetching, [actualScoreCardReducer.charts.fetching]);

  useEffect(() => {
    const payload = {
      enqueueSnackbar,
      data: makeActualScoreCardFilterData(filters),
    };

    dispatch(getPocTableData(payload));
    dispatch(getActualScoreData(payload));
  }, []);

  const setFilters = (newFilters) => {
    dispatch(setActualScoreCardFilters(newFilters));
  };

  const getTableData = (newFilters) => {
    dispatch(getActualScoreTableData(newFilters));
  };

  const getUpdatedSectionData = (newFilters) => {
    dispatch(getActualScoreData(newFilters));
    dispatch(getPocTableData(newFilters));
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-end mb-2">
        <div className="d-flex w-50">
          <FordActiveFilters
            filters={actualScoreCardReducer.filters}
            expandedTableKey={actualScoreCardReducer.tableData?.expandedKey}
            highLightColumn={actualScoreCardReducer.tableData?.highLightColumn}
            setFilters={setFilters}
            getTableData={getTableData}
            getUpdatedData={getUpdatedSectionData}
          />
        </div>
        <div className="d-flex flex-row justify-content-end w-25">
          <SectionCard
            className="w-50 mr-1 ml-1"
            data={{
              main_value: data?.top_row_data?.total_poc
            }}
            fetching={fetching}
            size="small"
            title="Total PoC"
            reducerKey="total_poc"
            subTitle="Projects"
            hoverText="Projects that reached Prototype Stage in the selected time period."
            highLightColumn="projectStage"
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="d-flex flex-row w-25">
          <SectionCard
            className="w-50 mr-1 ml-1"
            data={{
              main_value: data?.top_row_data?.total_approved
            }}
            fetching={fetching}
            size="small"
            title="Total Approved"
            reducerKey="total_approved"
            subTitle="Projects"
            hoverText="Projects that reached Implementation Stage in the selected time period."
            highLightColumn="projectStage"
            downloadIsActive={downloadIsActive}
          />
          <SectionCard
            className="w-50 mr-1 ml-1"
            data={{
              main_value: data?.top_row_data?.total_executed
            }}
            fetching={fetching}
            size="small"
            title="Total Executed"
            reducerKey="total_executed"
            subTitle="Projects"
            hoverText="Projects with a Job 1 Date in the selected time period."
            highLightColumn="job1Date"
            downloadIsActive={downloadIsActive}
          />
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <div className={styles.columnHeader}>
            Input Metrics
          </div>
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <div className={styles.columnHeader}>
            Process Metrics
          </div>
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <div className={styles.columnHeader}>
            Output Metrics
          </div>
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <div className={styles.columnHeader}>
            Outcome Metrics
          </div>
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <SectionCard
            data={data?.input_metrics?.poc}
            fetching={fetching}
            size="big"
            title="PoC"
            reducerKey="total_poc"
            hoverText="Projects that reached Prototype Stage in the selected time period."
            highLightColumn="projectStage"
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <SectionCard
            data={data?.process_metrics?.idea_generation}
            fetching={fetching}
            size="medium"
            title="Idea Generation"
            subTitle="(Time to Market)"
            reducerKey="idea_generation"
            hoverText="Average number of days between Project Start Date and Implementation Date."
            highLightColumn="ideaGeneration"
            downloadIsActive={downloadIsActive}
          />
          <SectionCard
            data={data?.process_metrics?.implementation}
            fetching={fetching}
            size="medium"
            title="Implementation"
            subTitle="(Time to Market)"
            reducerKey="implementation"
            hoverText="Average number of days between Implementation Date and Job 1 Date."
            highLightColumn="implementation"
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <SectionCard
            data={data?.output_metrics?.approved_replications}
            fetching={fetching}
            size="big"
            title="Approved Replications"
            reducerKey="approved_replications"
            hoverText="Projects in the Implementation stage or beyond that are Replications."
            highLightColumn="approvedReplications"
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <SectionCard
            data={data?.outcome_metrics?.cib_savings}
            fetching={fetching}
            size="big"
            title="CIB Savings ($)"
            reducerKey="cib_savings"
            showDollars
            hoverText="Total Savings for all Projects with a Job 1 Date in the selected time period AND Funded by CIB."
            highLightColumn="savings"
            downloadIsActive={downloadIsActive}
          />
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <PocTableDataCard downloadIsActive={downloadIsActive} />
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <SectionCard
            data={data?.process_metrics?.cib_avarage_tarr}
            fetching={fetching}
            size="big"
            title="CIB Avg TARR"
            reducerKey="cib_avarage_tarr"
            hoverText="Average TARR of all Projects that reached Implementation Stage in the selected time period. AND are Funded by CIB."
            highLightColumn="tarr"
            downloadIsActive={downloadIsActive}
          />
          <SectionCard
            data={data?.process_metrics?.global_tarr}
            fetching={fetching}
            size="big"
            title="Global TARR"
            reducerKey="global_tarr"
            hoverText="Average TARR of all Projects with an Implementation Date in the selected time period."
            highLightColumn="tarr"
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <SectionCard
            data={data?.output_metrics?.job1_accomplishment}
            fetching={fetching}
            size="big"
            title="Job1 Accomplishment"
            reducerKey="job1_accomplishment"
            hoverText="Percentage of Projects Accomplished on Time, with a Job 1 Date that is the same or earlier than the Expected Job 1 Date."
            highLightColumn="job1Accomplishment"
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="d-flex flex-column w-25 mr-1 ml-1">
          <SectionCard
            data={data?.outcome_metrics?.global_savings}
            fetching={fetching}
            size="medium"
            title="Global Savings ($)"
            reducerKey="global_savings"
            showDollars
            hoverText="Total Savings for all Projects with with Job 1 Date in the selected time period."
            highLightColumn="savings"
            downloadIsActive={downloadIsActive}
          />
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column col-4 pl-1 pr-1">
          <SectionLineChartCard
            data={data?.poc_growth}
            fetching={fetching}
            title="PoC Growth"
            reducerKey="poc_growth"
            hoverText="PoC Growth"
            highLightColumn="poc"
            doubleXAxe
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="d-flex flex-column col-4 pl-1 pr-1">
          <SectionLineChartCard
            data={data?.replication_growth}
            fetching={fetching}
            title="Approved Replication Growth"
            reducerKey="replication_growth"
            hoverText="Replication Growth"
            highLightColumn="replication_growth"
            doubleXAxe
            downloadIsActive={downloadIsActive}
          />
        </div>
        <div className="d-flex flex-column col-4 pl-1 pr-1">
          <SectionLineChartCard
            data={data?.savings_growth}
            fetching={fetching}
            title="Savings Growth"
            reducerKey="savings_growth"
            hoverText="Savings Growth"
            highLightColumn="savings_growth"
            appendToYAxe="$"
            doubleXAxe
            downloadIsActive={downloadIsActive}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(FordScoreCards);

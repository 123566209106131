import AWS from "aws-sdk";
import {
  awsAccessKey,
  awsSecretAccessKey,
  awsRegion,
  s3BUcketName,
} from "../constants";

AWS.config.update({
  accessKeyId: awsAccessKey,
  secretAccessKey: awsSecretAccessKey,
});

const myBucket = new AWS.S3({
  params: { Bucket: s3BUcketName },
  region: awsRegion,
});

const useS3FileUpload = () => {
  const fileUpload = async (
    FileObject,
    path,
    setUploadValues,
    fileName = ""
  ) => {
    const file = FileObject;
    const { name } = file;
    const newFileName = name.replace(/ /g, "_");
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: s3BUcketName,
      Key: path + newFileName,
      ContentType: file.type,
    };

    let result = {};
    const upload = () =>
      new Promise((resolve) =>
        myBucket
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            if (setUploadValues) {
              setUploadValues((uploadProgress) => {
                const currentProgress = [...uploadProgress];

                for (let i = 0; i < currentProgress.length; i++) {
                  if (
                    currentProgress[i].name.replace(/ /g, "_") === newFileName
                  ) {
                    currentProgress[i] = {
                      progress: Math.round((evt.loaded / evt.total) * 100),
                      name: newFileName,
                    };
                  }
                }

                return currentProgress;
              });
            }
          })
          .on("success", () => {
            result = {
              location: `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.amazonaws.com/${path}${newFileName}`,
              name: newFileName,
            };
            if (setUploadValues) {
              setUploadValues((uploadValues) => {
                if (
                  uploadValues.findIndex(
                    (item) =>
                      item?.progress !== "FAILED" &&
                      item?.progress !== "NOT_ALLOWED" &&
                      item?.progress !== 100
                  ) === -1
                ) {
                  return [];
                }
                return uploadValues;
              });
            }
            resolve();
          })
          .send((err) => {
            if (err) {
              if (setUploadValues) {
                setUploadValues((uploadProgress) => {
                  const currentProgress = [...uploadProgress];
                  const orderNumber = currentProgress
                    .map((item) => item.name)
                    .lastIndexOf(newFileName);
                  currentProgress[orderNumber !== -1 ? orderNumber : 0] = {
                    progress: "FAILED",
                    name: newFileName,
                  };
                  return currentProgress;
                });
              }
              console.log(err);
            }
          })
      );

    return upload().then(() => result);
  };

  const fileDelete = async (file) => {
    const params = {
      Bucket: s3BUcketName,
      Key: file,
    };

    return new Promise((resolve) =>
      myBucket.deleteObject(params, (err, data) => {
        if (err) {
          resolve();
          throw err;
        }
        if (data) {
          resolve();
        }
      })
    );
  };
  return { fileUpload, fileDelete };
};

export default useS3FileUpload;

import * as Yup from "yup";

export const optionVisibility = [
  {
    text: "All (visible to everyone)",
    value: "all",
  },
  {
    text: "Project Members",
    value: "project_members",
  },
];

export const initModel = {
  name: '',
  description: '',
  startDate: '',
  endDate: '',
  status: '',
  projectOwner: null,
  priority: '',
  access: '',
  diseases: [],
  topics: [],
  industries: [],
  country: '',
  ratingTemplate: {},
  automatic_status: true,
  selectedPlant1: null,
  selectedAreas1: null,
};

export const validation = Yup.object().shape({
  name: Yup.string().required("Project name is required."),
});

export const APPROVAL_STATUSES = [
  {
    id: 'submitted',
    name: 'Submitted',
  },
  {
    id: 'approved',
    name: 'Approved',
  },
  {
    id: 'paused',
    name: 'Paused',
  },
  {
    id: 'rejected',
    name: 'Rejected',
  }
]

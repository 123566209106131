export const PROJECT_LAST_ACTIVITY_GET_ATTEMPT = "PROJECT_LAST_ACTIVITY_GET_ATTEMPT";
export const projectLastActivityGet = (payload) => ({
  type: PROJECT_LAST_ACTIVITY_GET_ATTEMPT,
  payload,
});
export const PROJECT_LAST_ACTIVITY_GET_SUCCESS = "PROJECT_LAST_ACTIVITY_GET_SUCCESS";
export const projectLastActivityGetSuccess = (payload) => ({
  type: PROJECT_LAST_ACTIVITY_GET_SUCCESS,
  payload,
});

export const PROJECT_TEAMS_GET_ATTEMPT = "PROJECT_TEAMS_GET_ATTEMPT";
export const projectTeamsGet = (payload) => ({
  type: PROJECT_TEAMS_GET_ATTEMPT,
  payload,
});
export const PROJECT_TEAMS_GET_SUCCESS = "PROJECT_TEAMS_GET_SUCCESS";
export const projectTeamsGetSuccess = (payload) => ({
  type: PROJECT_TEAMS_GET_SUCCESS,
  payload,
});

export const PROJECTS_SEARCH_ATTEMPT = "PROJECTS_SEARCH_ATTEMPT";
export const projectsSearch = (payload) => ({
  type: PROJECTS_SEARCH_ATTEMPT,
  payload,
});
export const PROJECTS_SEARCH_SUCCESS = "PROJECTS_SEARCH_SUCCESS";
export const projectsSearchSuccess = (payload) => ({
  type: PROJECTS_SEARCH_SUCCESS,
  payload,
});

import {
  TOGGLE_REPLICATIONS_TAB_FILTERS,
  SET_REPLICATIONS_CARD_FILTERS,
  GET_REPLICATIONS_TABLE_DATA,
  GET_REPLICATIONS_TABLE_DATA_SUCCESS,
  TOGGLE_REPLICATIONS_TABLE,
  GET_REPLICATIONS_STATISTICS_DATA_SUCCESS,
  GET_REPLICATIONS_BY_STAGE_DATA_SUCCESS,
  GET_PROJECTS_EXPORTED_DATA_SUCCESS,
  GET_PROJECTS_IMPORTED_DATA_SUCCESS,
  GET_PROJECTS_EXPORTED_DATA,
  GET_PROJECTS_IMPORTED_DATA,
  GET_REPLICATIONS_BY_STAGE_DATA,
  GET_REPLICATIONS_STATISTICS_DATA,
} from "modules/reporting/fordReports/replicationsCard/store/replicationsCard.actions";
import { pageMeta } from "../../../../../common/httpCall";

const initState = {
  filters: {
    dateType: "allTime"
  },
  statistics: {
    data: null,
    fetching: false,
  },
  replicationsByPlantData: {
    data: null,
    fetching: false,
  },
  replicationsByStage: {
    data: null,
    fetching: false,
  },
  projectsExported: {
    data: null,
    fetching: false,
  },
  projectsImported: {
    data: null,
    fetching: false,
  },
  tableData: {
    expandedKey: null,
    highLightColumn: [],
  },
  expandedFilters: false,
};

const replicationsCardReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_REPLICATIONS_TAB_FILTERS: {
      return {
        ...state,
        expandedFilters: !state.expandedFilters,
      }
    }

    case SET_REPLICATIONS_CARD_FILTERS: {
      if (payload.reset) {
        return {
          ...state,
          filters: {
            dateType: "allTime",
          },
        }
      }

      return {
        ...state,
        filters: payload,
      }
    }

    case GET_REPLICATIONS_STATISTICS_DATA: {
      return {
        ...state,
        statistics: {
          ...state.statistics,
          fetching: true,
        },
      }
    }

    case GET_REPLICATIONS_STATISTICS_DATA_SUCCESS: {
      return {
        ...state,
        statistics: {
          ...state.statistics,
          data: payload.response,
          fetching: false,
        },
      }
    }

    case GET_REPLICATIONS_BY_STAGE_DATA: {
      return {
        ...state,
        replicationsByStage: {
          ...state.replicationsByStage,
          fetching: true,
        },
      }
    }

    case GET_REPLICATIONS_BY_STAGE_DATA_SUCCESS: {
      return {
        ...state,
        replicationsByStage: {
          ...state.replicationsByStage,
          data: payload.response,
          fetching: false,
        },
      }
    }

    case GET_PROJECTS_EXPORTED_DATA: {
      return {
        ...state,
        projectsExported: {
          ...state.projectsExported,
          fetching: true,
        },
      }
    }

    case GET_PROJECTS_EXPORTED_DATA_SUCCESS: {
      return {
        ...state,
        projectsExported: {
          ...state.projectsExported,
          data: payload.response,
          fetching: false,
        },
      }
    }

    case GET_PROJECTS_IMPORTED_DATA: {
      return {
        ...state,
        projectsImported: {
          ...state.projectsImported,
          fetching: true,
        },
      }
    }

    case GET_PROJECTS_IMPORTED_DATA_SUCCESS: {
      return {
        ...state,
        projectsImported: {
          ...state.projectsImported,
          data: payload.response,
          fetching: false,
        },
      }
    }

    case GET_REPLICATIONS_TABLE_DATA: {
      return {
        ...state,
        tableData: {
          ...state.tableData,
          expandedKey: payload.reducerKey,
          [payload.reducerKey]: {
            fetching: true,
          },
        }
      }
    }

    case GET_REPLICATIONS_TABLE_DATA_SUCCESS: {
      const getPageMeta = pageMeta(payload);

      return {
        ...state,
        tableData: {
          ...state.tableData,
          [payload.reducerKey]: {
            fetching: false,
            data: payload.response || [],
            pagination: {
              ...getPageMeta,
              current: getPageMeta.page,
            },
            highLightColumn: payload.highLightColumn,
          },
        }
      }
    }

    case TOGGLE_REPLICATIONS_TABLE: {
      return {
        ...state,
        tableData: {
          ...state.tableData,
          expandedKey: state.tableData.expandedKey === payload.reducerKey ? null : payload.reducerKey,
          highLightColumn: state.tableData.expandedKey === payload.reducerKey ? null : (payload.highLightColumn || []),
        }
      }
    }

    default:
      return state;
  }
};

export default replicationsCardReducer;

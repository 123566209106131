import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik } from "formik";

import { BtnType, Button, Label, TextBox } from "modules/primitives";
import { ModalFooter } from "application/modal";
import { formInitialValues } from './constant';
import useModelPopup from "common/hooks/modelPopup.hook";
import styles from "../../../../../../common/popups/newItem/newVideos/styles.module.scss";
import LinearProgressWithLabel from "../../../../../../common/components/linearProgressWithLabel";
import DragAndDrop from "../../../../../../common/components/dragAndDrop";
import { allowedExtensions } from "modules/events/viewEvent/viewEvent.constant";
import useS3FileUpload from "common/hooks/s3FileUplodar.hook";
import { Icon, mdSize } from "../../../../../../common/icon";
import {
  editProductDetails,
  editProductDocs,
  updateProductDetails,
} from "modules/companies/product/store/product.actions";
import { httpDelete } from "../../../../../../common/httpCall";
import LoadingComponent from "modules/primitives/loading/loading.component";

const EditProductDocs = ({ product, instance }) => {
  const popup = useModelPopup();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [uploadValues, setUploadValues] = useState([]);
  const [forbiddenFiles, setForbiddenFiles] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [showLoading1, setShowLoading1] = useState(false);
  const [docs, setDocs] = useState([]);
  const [presentations, setPresentations] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { fileUpload, fileDelete } = useS3FileUpload();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (product?.documents?.length) {
      setDocs(product.documents);
    }
    if (product?.presentations?.length) {
      setPresentations(product.presentations.map(uri => ({uri})));
    }
  }, [product]);

  const handleCancelClick = () => {
    popup.hide();
  }

  const handleSave = () => {
    if (instance === 'docs') {
      const filteredDocs = docs?.filter(d => !d.id);
      if (filteredDocs?.length === 0) {
        popup.hide();
        return;
      }

      const payload = {
        companyId: product.company.id,
        productId: product.id,
        data: {
          documents: filteredDocs
        },
      };

      dispatch(editProductDocs(payload));
    } else {
      const payload = {
        companyId: product.company.id,
        productId: product.id,
        data: {
          presentations: presentations.map(p => p.uri || p),
        },
      };

      dispatch(editProductDetails(payload));
    }

    popup.hide();
  };

  const getFilename = (url) => {
    if (url) {
      return url.split("/").pop();
    }
    return "";
  };

  const getExtension = (url) => url.split(/[#?]/)[0].split(".").pop().trim();

  const uploadHandlerForDocs = async (e, action) => {
    if (forbiddenFiles.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }

    setShowLoading(true);

    setUploadValues((items) => {
      const currentFiles = [...items];

      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name?.replace(/ /g,"_"),
      }));
      return [...currentFiles, ...newFiles];
    });

    const fileList = [];
    let dismissedFiles = 0;

    files.forEach(async (file, index, array) => {
      const isLastIndex = Boolean(index === array?.length - 1);
      if (file.size <= 20971520) {
        const pathname = `srm/${selectedCouncil.id}/companies/${product.company.id}/products/${product.id}/docs/`;
        await fileUpload(file, pathname, setUploadValues, file.name).then(
          (res) => {
            if (res && res.location) {
              if (docs.map(doc => doc.uri).indexOf(res.location) === -1) {
                fileList.push(res.location);
                isLastIndex && setShowLoading(false);
                if (fileList.length === files.length - dismissedFiles) {
                  setDocs([...docs, ...fileList.map((uri) => ({
                    uri,
                    document_resource_id: product.id,
                    document_resource_type: "CouncilProduct",
                    name: getFilename(uri),
                    council_id: selectedCouncil.id,
                    added_by_id: session.id,
                  }))]);
                }
              } else {
                enqueueSnackbar("This document was already added.", {
                  variant: "error",
                });
                isLastIndex && setShowLoading(false);
                dismissedFiles++;
                setForbiddenFiles((prevFiles) => {
                  const localFiles = [...prevFiles];
                  localFiles.push({
                    name: file.name,
                    error: "Document already added",
                  });
                  return localFiles;
                });
                setUploadValues((prevState) => {
                  const currentProgress = [...prevState];
                  const index = currentProgress
                    .map((item) => item.name)
                    .lastIndexOf(file.name);
                  currentProgress[index] = {
                    progress: "NOT_ALLOWED",
                    name: file.name,
                  };
                  return currentProgress;
                });
              }
            }
          },
        );
      } else {
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === file.name,
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: file.name,
          };
          return currentProgress;
        });
        isLastIndex && setShowLoading(false);
        if (file.size > 500000000) {
          dismissedFiles++;
          enqueueSnackbar(
            "We could not upload your image because your file size is too big. Please make sure the file is less than 500 MB",
            {
              variant: "error",
            },
          );
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error: "Your file is too large. File size limit: 500MB",
            });
            return localFiles;
          });
        }
      }
    });

    e.target.value = "";
  };

  const uploadHandlerForPresentations = async (e, action) => {
    if (forbiddenFiles.length) {
      setForbiddenFiles([]);
    }

    let files;

    if (action === "click") {
      if (!e || !e.target.files.length) {
        return;
      }

      files = Array.from(e.target.files);
    }

    if (action === "drop") {
      if (!e || !e.files.length) {
        return;
      }

      files = Array.from(e.files);
    }

    if (!files) {
      return;
    }

    setShowLoading1(true);

    setUploadValues((items) => {
      const currentFiles = [...items];

      const newFiles = files.map((file) => ({
        progress: 0,
        name: file.name?.replace(/ /g,"_"),
      }));
      return [...currentFiles, ...newFiles];
    });

    const fileList = [];
    let dismissedFiles = 0;

    files.forEach(async (file, index, array) => {
      const isLastIndex = Boolean(index === array?.length - 1);
      if (
        file.size <= 500000000
        && allowedExtensions?.indexOf(getExtension(file.name)) !== -1
      ) {
        const pathname = `srm/${selectedCouncil.id}/companies/${product.company.id}/products/${product.id}/docs/`;
        await fileUpload(file, pathname, setUploadValues, file.name).then(
          (res) => {
            if (res && res.location) {
              if (presentations.map(doc => doc.uri).indexOf(res.location) === -1) {
                fileList.push(res.location);
                isLastIndex && setShowLoading1(false);
                if (fileList.length === files.length - dismissedFiles) {
                  setPresentations([...presentations, ...fileList.map((uri) => ({
                    uri,
                    name: getFilename(uri),
                    council_id: selectedCouncil.id,
                    added_by_id: session.id,
                  }))]);
                }
              } else {
                enqueueSnackbar("This document was already added.", {
                  variant: "error",
                });
                isLastIndex && setShowLoading1(false);
                dismissedFiles++;
                setForbiddenFiles((prevFiles) => {
                  const localFiles = [...prevFiles];
                  localFiles.push({
                    name: file.name,
                    error: "Document already added",
                  });
                  return localFiles;
                });
                setUploadValues((prevState) => {
                  const currentProgress = [...prevState];
                  const index = currentProgress
                    .map((item) => item.name)
                    .lastIndexOf(file.name);
                  currentProgress[index] = {
                    progress: "NOT_ALLOWED",
                    name: file.name,
                  };
                  return currentProgress;
                });
              }
            }
          },
        );
      } else {
        setUploadValues((prevState) => {
          const currentProgress = [...prevState];
          const index = currentProgress.findIndex(
            (item) => item.name === file.name,
          );
          currentProgress[index] = {
            progress: "NOT_ALLOWED",
            name: file.name,
          };
          return currentProgress;
        });
        isLastIndex && setShowLoading1(false);
        if (file.size > 500000000) {
          dismissedFiles++;
          enqueueSnackbar(
            "We could not upload your image because your file size is too big. Please make sure the file is less than 500 MB",
            {
              variant: "error",
            },
          );
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error: "Your file is too large. File size limit: 500MB",
            });
            return localFiles;
          });
        }
        if (allowedExtensions.indexOf(getExtension(file.name)) === -1) {
          setForbiddenFiles((prevFiles) => {
            const localFiles = [...prevFiles];
            localFiles.push({
              name: file.name,
              error:
                "Extension is not allowed, the accepted file formats are: mp4, mov, wmw, flv, avi, webm, mkw.",
            });
            return localFiles;
          });
          isLastIndex && setShowLoading1(false);
          dismissedFiles++;
        }
      }
    });

    e.target.value = "";
  };

  const handleDelete = async (e, index, type) => {
    e.stopPropagation();

    if (type === 'docs') {
      const getDoc = docs.find((_, idx) => idx === index);

      if (getDoc?.id) {
        httpDelete({
          call: `documents/${getDoc?.id}`,
        }).subscribe();
      }

      const newDocs = docs.filter((_, idx) => idx !== index);
      setDocs(newDocs);

      dispatch(
        updateProductDetails({
          documents: newDocs,
        })
      );
    } else {
      const newPresentations = presentations.filter((_, idx) => idx !== index);
      setPresentations(newPresentations);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={formInitialValues}
        onSubmit={handleSave}
      >
        {({ values, handleSubmit, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <div className="px-4">
              {uploadValues?.length
                ? uploadValues.map(
                  (bar, index) =>
                    bar &&
                    bar?.progress !== "NOT_ALLOWED" && (
                      <div className={styles.loaderContainer}>
                        <div className={styles.loaderNamingWrp}>
                          {" "}
                          <div className={styles.fileName}>{uploadValues[index]?.name}</div>
                          <LinearProgressWithLabel
                            mode={uploadValues[index]?.progress === "FAILED" && "error"}
                            value={
                              uploadValues[index]?.progress === "FAILED"
                                ? 100
                                : uploadValues[index]?.progress || 0
                            }
                          />
                        </div>
                      </div>
                    )
                )
                : null}

              {
                instance === 'docs' ? (
                  <>
                    <DragAndDrop
                      uploadHandler={uploadHandlerForDocs}
                      loading={showLoading}
                      customText="drag and drop documents here"
                    />

                    {
                      docs?.length ? (
                        <div className="mt-3 mb-4">
                          <Label>Uploaded Docs</Label>
                          {
                            docs.map((doc, index) => (
                              <div className="d-flex justify-content-between mt-2" key={doc.uri}>
                                <span
                                  className="small"
                                  style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden'
                                  }}
                                >{doc.uri}</span>
                                <div className="cursor-pointer">
                                  <Icon
                                    {...mdSize}
                                    className="ml-3"
                                    icon="icn-button-delete"
                                    onClick={(e) => handleDelete(e, index, 'docs')}
                                  />
                                </div>
                            </div>
                          ))
                          }
                        </div>
                      ) : null
                    }
                  </>
                ) : null
              }
              {
                instance === 'presentations' ? (
                  <>
                    <DragAndDrop
                      uploadHandler={uploadHandlerForPresentations}
                      loading={showLoading1}
                      customText="drag and drop presentations here"
                    />
                    <div className="d-flex align-items-center">
                      <div className="d-flex flex-grow-1 flex-column mr-2">
                        <Label>Embed code ( USE just for Presentations! )</Label>
                        <TextBox
                          type="text"
                          name="embedCode"
                          placeholder="Paste embed code"
                          formProps={{ ...formikprops, values }}
                          className={styles.textBox}
                        />
                      </div>
                    </div>

                    {
                      presentations?.length ? (
                        <div className="mt-3 mb-4">
                          <Label>Uploaded Presentations</Label>
                          {
                            presentations.map((p, index) => (
                              <div className="d-flex justify-content-between mt-2" key={p.uri}>
                                <span
                                  className="small"
                                  style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden'
                                  }}
                                >{p.uri}</span>
                                <div className="cursor-pointer">
                                  <Icon
                                    {...mdSize}
                                    className="ml-3"
                                    icon="icn-button-delete"
                                    onClick={(e) => handleDelete(e, index, 'presentations')}
                                  />
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      ) : null
                    }
                  </>
                ) : null
              }

              {forbiddenFiles?.length ? (
                <div className={styles.forbiddenfileContainer}>
                  Files that weren't allowed:{" "}
                  {forbiddenFiles.map((file) => (
                    <span className={styles.forbiddenfileName}>
                      {`${file.name}: `}
                      <span className={styles.errorText}> {file.error}</span>
                    </span>
                  ))}
                </div>
              ) : null}
            </div>
            <ModalFooter>
              {
                uploadValues.length > 0 ? (
                  <LoadingComponent customText="Uploading..." />
                ) : (
                  <>
                    <Button
                      className="mr-3"
                      btn={BtnType.FRAME_LESS}
                      onClick={handleCancelClick}
                      disabled={showLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={showLoading}
                      type="submit"
                      onClick={handleSubmit}
                      btn={BtnType.REGULAR}
                    >
                      Save
                    </Button>
                  </>
                )
              }
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditProductDocs;

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import classnames from "classnames";
import {
  Loading,
  Button,
  BtnType,
  MultiSelect,
  Label,
  CheckBox,
  CheckType,
} from "modules/primitives/index";
import Tooltip from "@mui/material/Tooltip";
import {
  searchInCouncil,
  companyGetTopics,
} from "modules/companies/companyMain/companyMain.action";
import styles from "./requestResearch.module.scss";
import {
  companyHomeRequestResearch,
  getCompanyContactsAttempt,
  getCompanyDetailsFields,
} from "../../companies/company/company.action";
import { ModalFooter, ModalBody } from "../../../application/modal";
import UserMultiSelectSearch from "../../../common/components/usersSearch";
import { getUserTeams, getAllTeamsAttempt } from "../../teams/teams.action";
import CompanyContactsSection from "../../../common/components/companyContactsSectionForReqRes/companyContactsSectionForReqRes.component";

const ResearchModal = (props) => {
  const { popup } = props;
  const dispatch = useDispatch();
  const [tractionTehBox, setTractionTehBox] = useState(false);
  const [researchType, setResearchType] = useState({});
  const [options, setOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [elasticQueryy, setElasticQuery] = useState("");
  const [selectedCompanies, setSelectedCompanies] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [vettedTags, setVettedTags] = useState([]);
  const [technologyTags, setTechnologyTags] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [selectedMembers, setSelectedMembers] = useState("");
  const [memberError, setMemberError] = useState(false);
  const [errorType, setErrorType] = useState(false);
  const [infoError, setInfoError] = useState(false);
  const [CFError, setCFError] = useState(false);
  const [defaultTeam, setDefaultTeam] = useState({});
  const [isSelectedDefaultTeam, setIsSelectedDefaultTeam] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCompanyContacts, setSelectedCompanyContacts] = useState([]);
  const [checkedCustFields, setCheckedCustFields] = useState([]);
  const [checkedTemplateFields, setCheckedTemlateFields] = useState([]);
  const [assignToCompany, setAssignToCompany] = useState(false);
  const [assignedCompany, setCompanyAssigned] = useState("");

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: { searchResults, topics },
      companyReducer: {
        companyContacts,
        companyContactsLoading,
        companyDetails,
        companyDetailsLoading,
      },
    },
    projectsReducer: {
      projectReducer: { selectedProject },
    },
    teamsReducer: { userTeams, userTeamsLoading, allTeams },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(
      getUserTeams({
        enqueueSnackbar,
        query: {
          items: 1000,
        },
      })
    );
    dispatch(
      getAllTeamsAttempt({
        enqueueSnackbar,
      })
    );
  }, []);

  useEffect(() => {
    if (userTeams?.length || allTeams.length) {
      const filteredTeamsByDefault = allTeams?.filter(
        (team) => team?.request_research
      );
      setDefaultTeam(filteredTeamsByDefault[0]);
      if (filteredTeamsByDefault?.length) {
        setIsSelectedDefaultTeam(true);
        const alreadyExistingTeam = userTeams?.find(
          (team) => team?.id === filteredTeamsByDefault[0]?.id
        );
        if (alreadyExistingTeam) {
          setTeamOptions([
            ...userTeams.map((team) => {
              if (team?.id === alreadyExistingTeam?.id) {
                return {
                  ...team,
                  selected: true,
                };
              }
              return team;
            }),
          ]);
        } else {
          filteredTeamsByDefault[0].selected = true;
          setTeamOptions([...userTeams, ...filteredTeamsByDefault]);
        }
      } else {
        setIsSelectedDefaultTeam(false);
        setTeamOptions(
          userTeams?.map((team) => {
            if (team?.request_research) {
              return {
                ...team,
                selected: true,
              };
            }
            return team;
          })
        );
      }
    }
  }, [userTeams, allTeams]);

  const handleMemberSelect = (values) => {
    if (values.length === 0) {
      setSelectedMembers("");

      return;
    }
    if (memberError) {
      setMemberError(false);
    }
    setSelectedMembers(values[0].id);
  };
  const handleAssignToCompany = (e) => {
    setIsSelectedDefaultTeam(false);
    setTractionTehBox(false);
    setAssignToCompany(e);
  };

  const handleCompanySelect = (values) => {
    if (values.length === 0) {
      return;
    }
    setErrorType(false);
    setSelectedCompanies(values[0].id);
  };

  const handleTypeSearch = (val) => {
    setElasticQuery(val.search);
  };

  const handleVettedTopic = (values) => {
    if (values.length === 0) {
      return;
    }
    setVettedTags(values[0].id);
  };

  const handleTechnologyTopic = (values) => {
    if (values.length === 0) {
      return;
    }
    setTechnologyTags(values[0].id);
  };

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      // hide products temporary
      setOptions(searchResults.filter((o) => !o.is_product));
    }
  }, [searchResults]);

  useEffect(() => {
    if (session) {
      dispatch(
        companyGetTopics({
          userId: session.id,
          councilId: JSON.parse(localStorage.getItem("council")).id,
          enqueueSnackbar,
        })
      );
    }
  }, [session, selectedCouncil]);

  useEffect(() => {
    dispatch(
      searchInCouncil({
        data: elasticQueryy,
        council_id: selectedCouncil?.id,
        project_id: selectedProject?.id,
        // mode: patientOrg && patientOrg
        //   ? 'gsk/patient_organizations'
        //   : 'companies',
        mode: "companies",
        enqueueSnackbar,
        cb: () => {},
      })
    );
  }, [elasticQueryy, selectedCouncil]);

  useEffect(() => {
    const selectedDefTeam = teamOptions?.find(
      (team) => team?.id === defaultTeam?.id && team.selected
    );
    if (isSelectedDefaultTeam) {
      setTeamOptions(
        teamOptions.map((team) => {
          if (team?.request_research) {
            return {
              ...team,
              selected: true,
            };
          }
          return {
            ...team,
            selected: false,
          };
        })
      );
    } else if (selectedDefTeam) {
      setTeamOptions(
        teamOptions.map((team) => ({
          ...team,
          selected: false,
        }))
      );
    }
  }, [isSelectedDefaultTeam]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      selectedCompanyContacts.length === 0 &&
      !selectedMembers &&
      !tractionTehBox &&
      !teamOptions?.find((team) => team.selected)
    ) {
      setMemberError(true);
      return;
    }
    if (
      !additionalInfo &&
      (researchType.type === "individual" || assignToCompany) &&
      !checkedCustFields.length &&
      !checkedTemplateFields.length
    ) {
      setInfoError(true);
      return;
    }

    if (
      selectedCompanyContacts.length > 0 &&
      !additionalInfo &&
      !checkedCustFields.length &&
      !checkedTemplateFields.length
    ) {
      setCFError(true);
      return;
    }

    const getCompanyId = () => {
      if (assignedCompany.is_product) {
        return assignedCompany.company_id;
      }

      return selectedCompanies || assignedCompany.id;
    };

    const data = {
      information: additionalInfo,
      assigned_to_id: selectedMembers,
      requested_by_id: session.id,
      assign_to_traction: tractionTehBox,
      company_custom_field_ids: checkedCustFields,
      custom_field_template_ids: checkedTemplateFields,
      team_id: teamOptions?.find((team) => team.selected)?.id,
      company_contact_id: selectedCompanyContacts,
      company_id: getCompanyId(),
    };

    if (researchType.type === "individual") {
      data.company_id = selectedCompanies;
    }

    if (researchType.type === "vetted") {
      data.vetted_shortlist_topic_id = vettedTags;
    }

    if (researchType.type === "technology") {
      data.landscape_report_topic_id = technologyTags;
    }

    if (
      data.company_id ||
      data.vetted_shortlist_topic_id ||
      data.landscape_report_topic_id ||
      data.company_custom_field_ids.length
    ) {
      dispatch(
        companyHomeRequestResearch({
          enqueueSnackbar,
          data,
        })
      );

      popup.hide();
    } else {
      setErrorType(true);
    }
  };

  const handleTeamSelect = (value) => {
    if (!value.length) {
      const makeSelected = teamOptions.map((team) => ({
        ...team,
        selected: false,
      }));

      setTeamOptions(makeSelected);
      return;
    }
    if (value[0]?.request_research) {
      setIsSelectedDefaultTeam(true);
    } else {
      setIsSelectedDefaultTeam(false);
    }
    const makeSelected = teamOptions.map((team) => {
      if (value[0]?.id === team?.id) {
        return {
          ...team,
          selected: true,
        };
      }
      return {
        ...team,
        selected: false,
      };
    });

    setTeamOptions(makeSelected);
  };

  const handleSelectCompanyContact = (value) => {
    if (selectedCompanyContacts.includes(value)) {
      setSelectedCompanyContacts((prev) => [
        ...prev.filter((id) => id !== value),
      ]);
      return;
    }
    setSelectedCompanyContacts((prev) => [...prev, value]);
  };

  useEffect(() => {
    if (assignedCompany.is_product) {
      return;
    }

    if (assignedCompany?.id || selectedCompanies) {
      dispatch(
        getCompanyContactsAttempt({
          companyId: assignedCompany?.id || selectedCompanies,
        })
      );

      dispatch(
        getCompanyDetailsFields({
          company_id: assignedCompany?.id || selectedCompanies,
          council_id: selectedCouncil.id,
          items: 50,
          page: 1,
          enqueueSnackbar,
        })
      );
    }
  }, [assignedCompany, selectedCompanies]);

  const handleSelectAll = (e) => {
    let allCfCheckboxes = [];
    let allTemplateCheckboxes = [];

    if (e) {
      companyDetails
        .filter((cf) => cf.public)
        .map((company) => {
          if (company.company_custom_field_id) {
            allCfCheckboxes.push(company.company_custom_field_id);
          }
          if (company.custom_field_template_id) {
            allTemplateCheckboxes.push(company.custom_field_template_id);
          }
        });

      setCheckedCustFields(allCfCheckboxes);
      setCheckedTemlateFields(allTemplateCheckboxes);
      setSelectAll(true);
    } else {
      setCheckedCustFields([]);
      setCheckedTemlateFields([]);
      setSelectAll(false);
    }
  };

  const handleCheckbox = useCallback(
    (val, id, type) => {
      if (val) {
        setErrorType(false);
      }
      const allData = companyDetails;
      let newData;
      if (type === "customField") {
        if (checkedCustFields.indexOf(id) === -1) {
          newData = [...checkedCustFields, id];
        } else {
          newData = checkedCustFields.filter((item) => item !== id);
        }
        setCheckedCustFields(newData);
        setSelectAll(
          newData.length + checkedTemplateFields.length === allData.length
        );
      } else {
        if (checkedTemplateFields.indexOf(id) === -1) {
          newData = [...checkedTemplateFields, id];
        } else {
          newData = checkedTemplateFields.filter((item) => item !== id);
        }
        setCheckedTemlateFields(newData);
        setSelectAll(
          newData.length + checkedCustFields.length === allData.length
        );
      }
    },
    [companyDetails, checkedCustFields, checkedTemplateFields]
  );

  const handleCheckboxState = (id, type) => {
    if (type === "customField") {
      return checkedCustFields.indexOf(id) !== -1;
    }
    return checkedTemplateFields.indexOf(id) !== -1;
  };

  const showCustomFields = () => (
    <>
      {CFError ? (
        <div className={styles.errorText}>
          {" "}
          Additional information in text box below or selecting at least one
          research option is required.{" "}
        </div>
      ) : null}
      <div className="row">
        {companyDetails?.length > 0 && (
          <div className="col-12">
            <div className={styles.formElement}>
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={selectAll}
                onChange={handleSelectAll}
              />
              <Label>Select All</Label>
            </div>
            <hr />
          </div>
        )}
      </div>
      {companyDetails?.length ? (
        <div className={classnames(styles.requiredInfoWrp, "row")}>
          <div className="col-12">
            {companyDetails[0] &&
              companyDetails.map(
                (company) =>
                  !company.custom_field_template?.teams.length && (
                    <div
                      key={
                        company.company_custom_field_id ||
                        company.custom_field_template_id
                      }
                      className={styles.formElement}
                    >
                      <CheckBox
                        checkType={CheckType.BLUE}
                        isChecked={
                          company.company_custom_field_id
                            ? handleCheckboxState(
                                company.company_custom_field_id,
                                "customField"
                              )
                            : handleCheckboxState(
                                company.custom_field_template_id
                              )
                        }
                        onChange={(val) => {
                          if (company.company_custom_field_id) {
                            handleCheckbox(
                              val,
                              company.company_custom_field_id,
                              "customField"
                            );
                            return;
                          }
                          handleCheckbox(
                            val,
                            company.custom_field_template_id,
                            "template"
                          );
                        }}
                      />
                      <Label>
                        {company.field_name ||
                          company.field_type[0].toUpperCase() +
                            company.field_type.substring(1)}
                      </Label>
                    </div>
                  )
              )}
          </div>
        </div>
      ) : null}
      {companyDetailsLoading ? <Loading /> : null}
      {!companyDetailsLoading &&
      assignedCompany?.id &&
      companyDetails.length === 0 ? (
        <Label>There are no fields to be selected!</Label>
      ) : null}
    </>
  );
  return (
    <>
      <div className={styles.wrapper}>
        <form>
          <ModalBody>
            <div className="row">
              <div className="col-12">
                <div>
                  <UserMultiSelectSearch
                    placeholder="Assign to Team Member"
                    labelField="full_name"
                    valueField="id"
                    searchBy="full_name"
                    disabled={Boolean(
                      tractionTehBox ||
                        teamOptions?.find((el) => el?.selected) ||
                        isSelectedDefaultTeam ||
                        assignedCompany?.id
                    )}
                    onChange={handleMemberSelect}
                    multi={false}
                    onClear={() => {
                      setSelectedMembers("");
                    }}
                    withClear={selectedMembers}
                  />
                  {memberError && !tractionTehBox && (
                    <span className={styles.errorMessage}>
                      Must be selected at least one member.
                    </span>
                  )}

                  {!userTeamsLoading && Boolean(userTeams.length) && (
                    <MultiSelect
                      onChange={handleTeamSelect}
                      placeholder="Select Team"
                      labelField="name"
                      valueField="id"
                      searchBy="name"
                      options={teamOptions}
                      values={[...teamOptions.filter((team) => team?.selected)]}
                      disabled={Boolean(
                        tractionTehBox ||
                          selectedMembers.length ||
                          assignedCompany?.id
                      )}
                      multi={false}
                      withClear={teamOptions?.find((team) => team.selected)}
                      onClear={() => {
                        const makeSelected = teamOptions.map((team) => ({
                          ...team,
                          selected: false,
                        }));
                        setIsSelectedDefaultTeam(false);
                        setTeamOptions(makeSelected);
                      }}
                    />
                  )}
                </div>
                {Boolean(allTeams?.length) && defaultTeam?.id && (
                  <div
                    className={`${styles.formElement} ${
                      selectedMembers.length ? styles.disabledCheckBox : ""
                    } `}
                  >
                    <CheckBox
                      isChecked={isSelectedDefaultTeam}
                      disabled={
                        Boolean(selectedMembers.length) || assignedCompany?.id
                      }
                      onChange={() => {
                        setIsSelectedDefaultTeam(!isSelectedDefaultTeam);
                        setTractionTehBox(false);
                      }}
                      checkType={CheckType.BLUE}
                    />
                    <Label>Assign to {defaultTeam?.name} Team</Label>
                  </div>
                )}
                <div className={styles.formElement}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={assignedCompany?.id}
                    disabled={Boolean(selectedMembers.length)}
                    onChange={(e) => handleAssignToCompany(e)}
                  />
                  <Label>Assign to Company</Label>
                </div>
                <div>
                  {assignToCompany && (
                    <MultiSelect
                      onChange={(e) => {
                        setCompanyAssigned(e?.length ? e[0] : null);
                      }}
                      placeholder="Select Company"
                      labelField="name"
                      valueField="id"
                      searchBy="name"
                      options={options}
                      multi={false}
                      searchFn={(args) => {
                        handleTypeSearch(args.state);
                      }}
                    />
                  )}
                </div>
                <div>
                  {assignedCompany?.id && (
                    <>
                      {!companyContactsLoading ? (
                        <CompanyContactsSection
                          companyContacts={companyContacts}
                          handleSelectCompanyContact={
                            handleSelectCompanyContact
                          }
                          selectedCompanyContacts={selectedCompanyContacts}
                        />
                      ) : (
                        "Loading contacts..."
                      )}
                    </>
                  )}
                </div>
                {!selectedCouncil?.traction_tag.includes("kyndryl") ? (
                  <div
                    className={`${styles.formElement} ${
                      selectedMembers.length ? styles.disabledCheckBox : ""
                    } `}
                  >
                    <CheckBox
                      isChecked={tractionTehBox}
                      disabled={Boolean(selectedMembers.length)}
                      onChange={() => {
                        setTractionTehBox(!tractionTehBox);
                        setIsSelectedDefaultTeam(false);
                      }}
                      checkType={CheckType.BLUE}
                    />
                    <Label>Assign to Traction Technology</Label>
                    <sup>
                      <Tooltip
                        title="If assigned to Traction, our analysts typically turn around most requests in 24 to 48 hours."
                        placement="top"
                      >
                        <span className={styles.subTag}>?</span>
                      </Tooltip>
                    </sup>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Label>
                  Please select the type of research you need below:
                </Label>
              </div>
            </div>
            <hr />
            {errorType && (
              <span className={styles.errorMessage}>
                Must be selected at least one research type.
              </span>
            )}
            {userTeamsLoading && <Loading />}
            {!userTeamsLoading && (
              <div className={classnames(styles.requiredInfoWrp, "row")}>
                <div className="col-12">
                  {!assignToCompany && (
                    <>
                      <div className={styles.formElement}>
                        <CheckBox
                          checkType={CheckType.BLUE}
                          isChecked={researchType.type === "individual"}
                          onChange={() => {
                            if (researchType.type !== "individual") {
                              setResearchType({ type: "individual" });
                            } else {
                              setResearchType({});
                            }
                          }}
                        />
                        <Label>Individual Company Research</Label>
                      </div>
                      <div className={styles.formDropDown}>
                        {researchType.type === "individual" ? (
                          <MultiSelect
                            onChange={handleCompanySelect}
                            placeholder="Select Company"
                            labelField="name"
                            valueField="id"
                            searchBy="name"
                            options={options}
                            multi={false}
                            searchFn={(args) => {
                              handleTypeSearch(args.state);
                            }}
                          />
                        ) : null}
                      </div>
                      <div className={styles.formElement}>
                        <CheckBox
                          checkType={CheckType.BLUE}
                          isChecked={researchType.type === "vetted"}
                          onChange={() => {
                            setErrorType(false);
                            if (researchType.type !== "vetted") {
                              setResearchType({ type: "vetted" });
                            } else {
                              setResearchType({});
                            }
                          }}
                        />
                        <Label>Vetted Shortlist of Companies</Label>
                      </div>
                      <div className={styles.formDropDown}>
                        {researchType.type === "vetted" ? (
                          <MultiSelect
                            className={styles.dropWrapper}
                            labelField="name"
                            valueField="id"
                            searchBy="name"
                            searchable
                            onChange={handleVettedTopic}
                            options={topics}
                            multi={false}
                          />
                        ) : null}
                      </div>
                      <div className={styles.formElement}>
                        <CheckBox
                          checkType={CheckType.BLUE}
                          isChecked={researchType.type === "technology"}
                          onChange={() => {
                            setErrorType(false);
                            if (researchType.type !== "technology") {
                              setResearchType({ type: "technology" });
                            } else {
                              setResearchType({});
                            }
                          }}
                        />
                        <Label>Technology Landscape Report</Label>
                      </div>
                      <div className={styles.formDropDown}>
                        {researchType.type === "technology" ? (
                          <MultiSelect
                            className={styles.dropWrapper}
                            labelField="name"
                            valueField="id"
                            searchBy="name"
                            searchable
                            onChange={handleTechnologyTopic}
                            options={topics}
                            multi={false}
                          />
                        ) : null}
                      </div>
                    </>
                  )}

                  {researchType.type === "individual" || assignToCompany
                    ? showCustomFields()
                    : null}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <Label>Additional Information</Label>
                <textarea
                  placeholder="Please provide as much information as possible about your request, including specific companies, technologies, or industries to focus on."
                  className={styles.textBox}
                  value={additionalInfo}
                  onChange={(e) => {
                    setInfoError(false);
                    setAdditionalInfo(e.target.value);
                  }}
                />
              </div>
            </div>
            {infoError && (
              <div className={styles.errorText}>
                {" "}
                Additional information is requested for this research type.{" "}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button btn={BtnType.FRAME_LESS} onClick={() => popup.hide()}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} btn={BtnType.REGULAR}>
              Send
            </Button>
          </ModalFooter>
        </form>
      </div>
    </>
  );
};

export default ResearchModal;

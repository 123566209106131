import { pageMeta } from "../../../../../common/httpCall";
import {
  GET_KPIS_TABLE_DATA,
  GET_KPIS_TABLE_DATA_SUCCESS,
  KPIS_GET_CLIENT_PLANTS,
  KPIS_GET_CLIENT_PLANTS_SUCCESS,
  KPIS_GET_INNOVATION_CATEGORY,
  KPIS_GET_INNOVATION_CATEGORY_SUCCESS,
  KPIS_GET_NR_OF_PROJECTS,
  KPIS_GET_NR_OF_PROJECTS_SUCCESS,
  KPIS_GET_NRS_DATA,
  KPIS_GET_NRS_DATA_SUCCESS,
  KPIS_GET_REPLICATIONS_NR,
  KPIS_GET_REPLICATIONS_NR_SUCCESS,
  KPIS_NEXT_PHASE_CHANGE,
  KPIS_NEXT_PHASE_CHANGE_SUCCESS,
  SET_KPIS_CARD_FILTERS,
  TOGGLE_KPIS_TAB_FILTERS,
  TOGGLE_KPIS_TABLE,
} from "modules/reporting/fordReports/kpis/store/kpis.actions";

const initState = {
  filters: {
    dateType: "allTime"
  },
  nrOfProjectsStages: {
    data: null,
    fetching: true,
  },
  nrsData: {
    data: null,
    fetching: true,
  },
  clientPlants: {
    data: null,
    fetching: true,
  },
  innovationCategory: {
    data: null,
    fetching: true,
  },
  nrOfReplications: {
    data: null,
    fetching: true,
  },
  nextPhaseChange: {
    data: null,
    fetching: true,
  },
  tableData: {
    expandedKey: null,
  },
  expandedFilters: false,
};

const kpisReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_KPIS_TAB_FILTERS: {
      return {
        ...state,
        expandedFilters: !state.expandedFilters,
      }
    }

    case KPIS_NEXT_PHASE_CHANGE: {
      return {
        ...state,
        nextPhaseChange: {
          ...state.nextPhaseChange,
          fetching: true,
        }
      }
    }

    case KPIS_NEXT_PHASE_CHANGE_SUCCESS: {
      return {
        ...state,
        nextPhaseChange: {
          data: payload.response,
          fetching: false,
        }
      }
    }

    case KPIS_GET_NRS_DATA: {
      return {
        ...state,
        nrsData: {
          ...state.nrsData,
          fetching: true,
        }
      }
    }

    case KPIS_GET_NRS_DATA_SUCCESS: {
      return {
        ...state,
        nrsData: {
          data: payload.response,
          fetching: false,
        }
      }
    }

    case KPIS_GET_REPLICATIONS_NR: {
      return {
        ...state,
        nrOfReplications: {
          ...state.nrOfReplications,
          fetching: true,
        }
      }
    }

    case KPIS_GET_REPLICATIONS_NR_SUCCESS: {
      return {
        ...state,
        nrOfReplications: {
          data: payload.response.filter(obj => obj.value > 0).sort((a, b) => b.value - a.value),
          fetching: false,
        }
      }
    }

    case KPIS_GET_NR_OF_PROJECTS: {
      return {
        ...state,
        nrOfProjectsStages: {
          ...state.nrOfProjectsStages,
          fetching: true,
        }
      }
    }

    case KPIS_GET_NR_OF_PROJECTS_SUCCESS: {
      return {
        ...state,
        nrOfProjectsStages: {
          data: payload.response,
          fetching: false,
        }
      }
    }

    case KPIS_GET_CLIENT_PLANTS: {
      return {
        ...state,
        clientPlants: {
          ...state.clientPlants,
          fetching: true,
        }
      }
    }

    case KPIS_GET_CLIENT_PLANTS_SUCCESS: {
      return {
        ...state,
        clientPlants: {
          data: payload.response,
          fetching: false,
        }
      }
    }

    case KPIS_GET_INNOVATION_CATEGORY: {
      return {
        ...state,
        innovationCategory: {
          ...state.innovationCategory,
          fetching: true,
        }
      }
    }

    case KPIS_GET_INNOVATION_CATEGORY_SUCCESS: {
      return {
        ...state,
        innovationCategory: {
          data: payload.response,
          fetching: false,
        }
      }
    }

    case SET_KPIS_CARD_FILTERS: {
      if (payload.reset) {
        return {
          ...state,
          filters: {
            dateType: "allTime",
          },
        }
      }

      return {
        ...state,
        filters: payload,
      }
    }

    case GET_KPIS_TABLE_DATA: {
      return {
        ...state,
        tableData: {
          ...state.tableData,
          expandedKey: payload.reducerKey,
          [payload.reducerKey]: {
            fetching: true,
          },
        }
      }
    }

    case GET_KPIS_TABLE_DATA_SUCCESS: {
      const getPageMeta = pageMeta(payload);

      return {
        ...state,
        tableData: {
          ...state.tableData,
          [payload.reducerKey]: {
            fetching: false,
            data: payload.response || [],
            pagination: {
              ...getPageMeta,
              current: getPageMeta.page,
            }
          },
        }
      }
    }

    case TOGGLE_KPIS_TABLE: {
      return {
        ...state,
        tableData: {
          ...state.tableData,
          expandedKey: state.tableData.expandedKey === payload.reducerKey ? null : payload.reducerKey,
          highLightColumn: state.tableData.expandedKey === payload.reducerKey ? null : payload.highLightColumn,
        }
      }
    }

    default:
      return state;
  }
};

export default kpisReducer;

import * as Yup from "yup";
import { objectType } from "modules/companies/company/details/details.constant";

export const validation = Yup.object().shape({
  // field_value: Yup.string().required('Number is required'),
});

export const model = {
  type: objectType.number,
  title: "",
  field_value: "",
  option_selected: [],
};

export const currencies = ["USD", "EUR", "GBP", "CHF"];

export const currencySymbol = [
  { name: "USD", symbol: "$" },
  { name: "EUR", symbol: "€" },
  { name: "GBP", symbol: "£" },
  { name: "CHF", symbol: "CHF" }
];

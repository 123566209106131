import {
  GET_CUSTOM_FIELDS_ATTEMPT,
  GET_CUSTOM_FIELDS_SUCCESS,
  GET_SEARCH_CUSTOM_FIELDS_ATTEMPT,
  GET_SEARCH_CUSTOM_FIELDS_SUCCESS,
  GET_CUSTOM_FIELDS_GROUPS_ATTEMPT,
  POST_CUSTOM_FIELD_GROUPS_SUCCESS,
  POST_CUSTOM_FIELD_ATTEMPT,
  POST_CUSTOM_FIELD_SUCCESS,
  POST_CUSTOM_FIELD_GROUPS_ATTEMPT,
  GET_CUSTOM_FIELDS_GROUPS_SUCCESS,
  PUT_CUSTOM_FIELD_GROUPS_SUCCESS,
  PUT_CUSTOM_FIELD_GROUPS_ATTEMPT,
  DELETE_CUSTOM_FIELD_ATTEMPT,
  DELETE_CUSTOM_FIELD_SUCCESS,
  PUT_CUSTOM_FIELD_ATTEMPT,
  PUT_CUSTOM_FIELD_SUCCESS,
  DELETE_CUSTOM_FIELD_GROUPS_ATTEMPT,
  DELETE_CUSTOM_FIELD_GROUPS_SUCCESS,
  GET_CUSTOM_FIELD_GROUP_TEMPLATES_ATTEMPT,
  GET_CUSTOM_FIELD_GROUP_TEMPLATES_SUCCESS,
  POST_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT,
  POST_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS,
  PUT_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT,
  PUT_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS,
  DELETE_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT,
  DELETE_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS,
  GET_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT,
  GET_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS,
} from "./adminCustomField.action";
import { pageMeta } from "../../../common/httpCall";

const initState = {
  customFieldGroups: [],
  customFields: [],
  groupTemplates: [],
  groupTemplatesLaoding: false,
  loading: false,
  loadingGroups: false,
  customFieldGroupsPageNr: 0,
  customFieldPageNr: 0,
  customFieldGroupsPageTemplatesNr: 0,
  shouldUpdate: false,
  shouldUpdateAgreementType: false,
  loadingAgreementType: false,
  customFieldAgreementTypes: [],
};
const adminCustomFieldsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CUSTOM_FIELD_GROUP_TEMPLATES_ATTEMPT: {
      return {
        ...state,
        groupTemplatesLaoding: true,
      };
    }
    case GET_CUSTOM_FIELD_GROUP_TEMPLATES_SUCCESS: {
      return {
        ...state,
        groupTemplatesLaoding: false,
        shouldUpdate: false,
        loading: false,
        groupTemplates: payload.response,
        customFieldGroupsPageTemplatesNr: pageMeta(payload),
      };
    }

    case DELETE_CUSTOM_FIELD_GROUPS_ATTEMPT: {
      return {
        ...state,
        loadingGroups: true,
      };
    }
    case DELETE_CUSTOM_FIELD_GROUPS_SUCCESS: {
      const filteredCFG = state.customFieldGroups.filter(
        (cfg) => cfg?.custom_field_group_id !== payload?.customFieldGroupId
      );
      return {
        ...state,
        loadingGroups: false,
        shouldUpdate: true,
        customFieldGroups: filteredCFG,
      };
    }

    case PUT_CUSTOM_FIELD_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case PUT_CUSTOM_FIELD_SUCCESS: {
      const newTemplates = state.customFields.map((template) => {
        if (
          template.custom_field_template_id ===
          payload?.response?.custom_field_template_id
        ) {
          return payload?.response;
        }
        return template;
      });
      return {
        ...state,
        loading: false,
        shouldUpdate: true,
        customFields: newTemplates,
      };
    }

    case DELETE_CUSTOM_FIELD_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_CUSTOM_FIELD_SUCCESS: {
      const filteredCF = state.customFields.filter(
        (cf) => cf?.custom_field_template_id !== payload?.customFieldId
      );
      return {
        ...state,
        loading: false,
        shouldUpdate: true,
        customFields: filteredCF,
      };
    }

    case PUT_CUSTOM_FIELD_GROUPS_ATTEMPT: {
      return {
        ...state,
        loadingGroups: true,
      };
    }
    case PUT_CUSTOM_FIELD_GROUPS_SUCCESS: {
      const newCustomGroups = state.customFieldGroups.map((group) => {
        if (
          group.custom_field_group_id ===
          payload?.response?.custom_field_group_id
        ) {
          return payload?.response;
        }
        return group;
      });
      return {
        ...state,
        loadingGroups: false,
        shouldUpdate: true,
        customFieldGroups: newCustomGroups,
      };
    }
    case GET_CUSTOM_FIELDS_GROUPS_ATTEMPT: {
      return {
        ...state,
        loadingGroups: true,
      };
    }

    case GET_CUSTOM_FIELDS_GROUPS_SUCCESS: {
      return {
        ...state,
        loadingGroups: false,
        customFieldGroups: payload.response,
        shouldUpdate: false,
        customFieldGroupsPageNr: pageMeta(payload),
      };
    }

    case POST_CUSTOM_FIELD_GROUPS_ATTEMPT: {
      return {
        ...state,
        loadingGroups: true,
      };
    }
    case POST_CUSTOM_FIELD_GROUPS_SUCCESS: {
      return {
        ...state,
        loadingGroups: false,
        shouldUpdate: true,
        customFieldGroups: [...state.customFieldGroups, payload.response],
      };
    }
    case GET_CUSTOM_FIELDS_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CUSTOM_FIELDS_SUCCESS: {
      const filteredCF = payload?.response.filter((cf) => !cf.hide);
      return {
        ...state,
        customFields: filteredCF,
        loading: false,
        customFieldPageNr: pageMeta(payload),
        shouldUpdate: false,
      };
    }

    case GET_SEARCH_CUSTOM_FIELDS_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_SEARCH_CUSTOM_FIELDS_SUCCESS: {
      return {
        ...state,
        customFields: payload?.response,
        loading: false,
        shouldUpdate: false,
      };
    }

    case POST_CUSTOM_FIELD_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_CUSTOM_FIELD_SUCCESS: {
      return {
        ...state,
        laoding: true,
        shouldUpdate: true,
      };
    }
    case GET_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT: {
      return {
        ...state,
        loadingAgreementType: true,
      };
    }
    case GET_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS: {
      return {
        ...state,
        loadingAgreementType: false,
        shouldUpdateAgreementType: false,
        customFieldAgreementTypes: payload.response,
      };
    }
    case POST_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT: {
      return {
        ...state,
        loadingAgreementType: true,
      };
    }
    case POST_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS: {
      return {
        ...state,
        loadingAgreementType: false,
        shouldUpdateAgreementType: true,
      };
    }
    case DELETE_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT: {
      return {
        ...state,
        loadingAgreementType: true,
      };
    }
    case DELETE_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS: {
      return {
        ...state,
        loadingAgreementType: false,
        shouldUpdateAgreementType: true,
      };
    }
    case PUT_CUSTOM_FIELD_AGREEMENT_TYPE_ATTEMPT: {
      return {
        ...state,
        loadingAgreementType: true,
      };
    }
    case PUT_CUSTOM_FIELD_AGREEMENT_TYPE_SUCCESS: {
      return {
        ...state,
        loadingAgreementType: false,
        shouldUpdateAgreementType: true,
      };
    }

    default:
      return state;
  }
};

export default adminCustomFieldsReducer;

import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";

import {
  httpGet,
  apiStatus,
  errorHandler,
  httpPut,
  httpPost,
  httpDelete
} from "../../../common/httpCall";
import {
  ADD_AREA,
  ADD_PLANT,
  addAreaSuccess,
  addPlantSuccess,
  DELETE_AREA,
  DELETE_PLANT,
  deleteAreaSuccess,
  deletePlantSuccess,
  EDIT_AREA,
  EDIT_PLANT,
  editAreaSuccess,
  editPlantSuccess,
  GET_PLANTS_AND_AREAS,
  getPlantsAndAreasSuccess,
} from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";

export const getPlantsAndAreasEpic = (action$) =>
  action$.pipe(
    ofType(GET_PLANTS_AND_AREAS),
    switchMap(({ payload: { council_id, enqueueSnackbar } }) =>
      httpGet({
        call: 'plants',
      }).pipe(
        map((result) => {
          if (result.status === apiStatus.SUCCESS) {
            return getPlantsAndAreasSuccess(result);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [])
        )
      )
    )
  );

export const editPlant = (action$) =>
  action$.pipe(
    ofType(EDIT_PLANT),
    switchMap(({ payload }) =>
      httpPut({
        call: `plants/${payload.plantId}`,
        data: {
          plant: payload.plant
        },
      }).pipe(
        map((result) => {
          payload.cb();
          return editPlantSuccess(result);
        }),
        catchError((error) =>
          errorHandler(error, null, [])
        )
      )
    )
  );

export const addPlant = (action$) =>
  action$.pipe(
    ofType(ADD_PLANT),
    switchMap(({ payload }) =>
      httpPost({
        call: 'plants',
        data: {
          plant: payload.plant
        },
      }).pipe(
        map((result) => {
          payload.cb();
          return addPlantSuccess(result);
        }),
        catchError((error) =>
          errorHandler(error, null, [])
        )
      )
    )
  );

export const editArea = (action$) =>
  action$.pipe(
    ofType(EDIT_AREA),
    switchMap(({ payload }) =>
      httpPut({
        call: payload.url,
        data: payload.data,
      }).pipe(
        map((result) => {
          payload.cb();
          return editAreaSuccess(result);
        }),
        catchError((error) =>
          errorHandler(error, null, [])
        )
      )
    )
  );

export const addArea = (action$) =>
  action$.pipe(
    ofType(ADD_AREA),
    switchMap(({ payload }) =>
      httpPost({
        call: `plants/${payload.plantId}/areas`,
        data: {
          area: payload.area
        },
      }).pipe(
        map((result) => {
          payload.cb();
          return addAreaSuccess(result);
        }),
        catchError((error) =>
          errorHandler(error, null, [])
        )
      )
    )
  );

export const deletePlantEpic = (action$) =>
  action$.pipe(
    ofType(DELETE_PLANT),
    switchMap(({ payload: { plantId } }) =>
      httpDelete({
        call: `plants/${plantId}`,
      }).pipe(
        map(deletePlantSuccess),
        catchError((error) =>
          errorHandler(error, null, [])
        )
      )
    )
  );

export const deleteAreaEpic = (action$) =>
  action$.pipe(
    ofType(DELETE_AREA),
    switchMap(({ payload: { plantId, areaId } }) =>
      httpDelete({
        call: `plants/${plantId}/areas/${areaId}`,
      }).pipe(
        map(deleteAreaSuccess),
        catchError((error) =>
          errorHandler(error, null, [])
        )
      )
    )
  );

const adminPlantsAndAreasEpic = combineEpics(
  getPlantsAndAreasEpic,
  editPlant,
  addPlant,
  addArea,
  editArea,
  deletePlantEpic,
  deleteAreaEpic,
);

export default adminPlantsAndAreasEpic;

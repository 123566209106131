import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./companyState.module.scss";
import { stateType, companyState } from "./companyState.constant";
import { Icon, mdSize } from "../../../common/icon";

const CompanyStateComponent = (props) => {
  const { state = companyState.ON_TRACK, type = stateType.FULL_TEXT } = props;
  const [styleClass, setStyleClass] = useState("");
  const [text, setText] = useState("");
  const [icon, setIcon] = useState("");

  useEffect(() => {
    switch (state) {
      case companyState.ON_TRACK:
        setText("Active");
        setStyleClass(styles.ontrack);
        setIcon("on-track");
        break;
      case companyState.REMOVED:
        setText("Removed");
        setStyleClass(styles.removed);
        setIcon("removed");
        break;
      case companyState.PAUSED:
        setText("Paused");
        setStyleClass(styles.paused);
        setIcon("paused");
        break;
      default:
        break;
    }
  }, [state]);

  return (
    <>
      {type === stateType.FULL_TEXT ? (
        <div className={classNames(styles.stateWrp, styleClass)}>
          <div className={styles.text}>{text}</div>
        </div>
      ) : (
        <Icon {...mdSize} icon={icon} className={styles.icon} />
      )}
    </>
  );
};

export default React.memo(CompanyStateComponent);

import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip/Tooltip";

import { makeActualScoreCardFilterData } from "modules/reporting/fordReports/utils";
import LoadingComponent from "modules/primitives/loading/loading.component";
import genericStyles from '../../styles.module.scss';
import styles from './styles.module.scss';
import {
  getProjectsImportedData,
  getReplicationsTableData,
  toggleReplicationsTable,
} from "modules/reporting/fordReports/replicationsCard/store/replicationsCard.actions";

const ImportedProjects = ({ sectionData, filters, tableData, downloadIsActive }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!sectionData.data) {
      const makeFilters = makeActualScoreCardFilterData(filters);
      const payload = {
        enqueueSnackbar,
        data: makeFilters,
      };

      dispatch(getProjectsImportedData(payload))
    }
  }, []);

  const cardHasExpandedTable = useMemo(() => tableData?.expandedKey === 'replications_by_plant',
    [tableData?.expandedKey]);

  const handleShowOrHideTable = useCallback(() => {
    if (tableData?.expandedKey !== 'replications_by_plant') {
      const filtersData = makeActualScoreCardFilterData(filters);
      dispatch(getReplicationsTableData({
        reducerKey: 'replications_by_plant',
        highLightColumn: ['plant'],
        data: {
          ...filtersData,
          section: 'replications_by_plant',
        },
      }));

      return;
    }

    dispatch(toggleReplicationsTable({
      reducerKey: 'replications_by_plant',
    }));
  }, [tableData, filters]);

  const makeColumnCardStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      border: '1px solid #cbd2d9',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px',
      height: '100%',
      padding: '10px',
    }
  }, [downloadIsActive]);

  const makeTitleStylesForPdf = useMemo(() => {
    if(!downloadIsActive) return {};

    return {
      color: '#616e7c',
      fontSize: '18px',
      fontFamily: 'lato-regular',
      paddingTop: '10px',
    }
  }, [downloadIsActive]);

  return (
    <div style={makeColumnCardStylesForPdf} className={classNames(genericStyles.columnCard, 'px-0 pb-0 mb-0')}>
      {
        sectionData.fetching ? (
          <LoadingComponent customText="Getting data..." />
        ) : sectionData.data && (
          <div className="d-flex flex-column position-relative w-100">
            <span style={makeTitleStylesForPdf} className={classNames(genericStyles.columnCardTitle, 'ml-2')}>Projects Imported</span>
            {
              !downloadIsActive && (
                <div className={styles.cardQuestionMark}>
                  <Tooltip
                    title="Number of Replications imported to each Plant"
                    placement="top"
                  >
                    <span>
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="6" cy="6" r="5.5" stroke="#9AA5B1" />
                        <path
                          d="M6.149 3c-.972 0-1.602.383-2.096 1.067a.275.275 0 0 0 .063.385l.525.383a.3.3 0 0 0 .405-.048c.305-.368.531-.58 1.008-.58.374 0 .838.232.838.582 0 .264-.227.4-.597.6-.431.232-1.002.522-1.002 1.246v.115c0 .155.13.281.292.281h.883a.287.287 0 0 0 .292-.281v-.068c0-.502 1.526-.523 1.526-1.882 0-1.024-1.104-1.8-2.137-1.8zm-.122 4.376c-.466 0-.844.365-.844.812 0 .448.378.812.844.812.465 0 .843-.364.843-.812 0-.447-.378-.812-.843-.812z"
                          fill="#9AA5B1"
                        />
                      </svg>
                    </span>
                  </Tooltip>
                </div>
              )
            }
            <div className={styles.numbersTableWrapper}>
              <div className="d-flex py-2 border-bottom">
                <div className="col-7 pl-2 small">Client Plant</div>
                <div className="col-5 text-right small">No. Projects Replicated</div>
              </div>
              <div className={styles.tableBody}>
                {
                  sectionData.data.map(plant => (
                    <div className={styles.tableRow} key={plant.id}>
                      <div
                        className={classNames('d-flex align-items-center col-7 pl-1 pr-2 py-1 small', styles.tableRowWithBorderRight)}>{plant.name}</div>
                      <div className="col-5 px-3 text-right py-2">{plant.value}</div>
                    </div>
                  ))
                }
              </div>
            </div>
            {
              !downloadIsActive && (
                <div className="d-flex justify-content-center mt-2 mb-1">
                  <span className={genericStyles.showHideSourceData} onClick={() => handleShowOrHideTable()}>
                    {cardHasExpandedTable ? 'HIDE SOURCE DATA' : 'SHOW SOURCE DATA'}
                  </span>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default React.memo(ImportedProjects);

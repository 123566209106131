import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";
import { checkViewerRole } from "common/checkers/viewerChecker";
import styles from "./leftWindow.module.scss";
import { Button, BtnType } from "../../../primitives";
import FilterRowComponent from "./filterRow/filterRow.component";
import { useModelPopup } from "../../../../common/hooks/index";
import { gskOptionCountries as defaultCountries } from "../../../../common/constants";
import { sortCountries } from "../../../../common/helper";
import { setScrolledTop } from "modules/companies/companyMain/companyMain.action";
import {
  patientOrganizationGetDiseases,
  patientOrganizationGetDiversityGroups,
} from "../patientOrganizationMain.action";
import NewPatientOrganizationComponent from "../../newPatientOrganization/newPatientOrganization.component";

const LeftWindowComponent = ({
  mobileInstance,
  handleCheck,
  checkedDiseasesFilters,
  checkedCountryFilters,
  handleReset,
  checkedDiversityGroups,
}) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  const {
    authReducer: { session },
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: {
        diseases,
        checkedPatientFilters,
        countryFilters,
        diversityGroups,
      },
    },
    companiesReducer: {
      companyMainReducer: { isScrolled },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  const handleAddClick = () => {
    popup.show({
      title: "Add Organization",
      component: <NewPatientOrganizationComponent />,
    });
  };

  const onScrollEventFunc = (event) => {
    const scrolled = event.target.scrollTop;
    if (scrolled > 0 && !isScrolled) {
      dispatch(setScrolledTop(true));
    } else if (scrolled === 0 && isScrolled) {
      dispatch(setScrolledTop(false));
    }
  };

  React.useEffect(() => {
    if (session) {
      dispatch(patientOrganizationGetDiversityGroups({ enqueueSnackbar }));
      dispatch(
        patientOrganizationGetDiseases({
          filter: true,
          enqueueSnackbar,
        }),
      );
    }
  }, [session]);

  return (
    <div
      className={`${styles.wrapper} ${mobileInstance ? 'w-100' : ''}`}
      onScroll={onScrollEventFunc}
    >
      <div className={`${mobileInstance ? 'w-100' : ''} ${styles.container}`}>
        <div className={`${styles.filterWrapper} ${mobileInstance ? 'mt-0' : ''}`}>
          <div className={styles.addCompanyWrp}>
            {!blockFunctionality ? (
              <Button
                btn={BtnType.REGULAR}
                className={styles.button}
                icon="icn-add"
                onClick={handleAddClick}
              >
                Add Organization
              </Button>
            ) : (
              <Tooltip
                title="You have View-Only access. To Add a Organisation, please ask your Admin to upgrade your account to Standard access."
                placement="top"
              >
                <div>
                  <Button
                    btn={BtnType.DISABLED}
                    className={styles.button}
                    icon="icn-add"
                  >
                    Add Organization
                  </Button>
                </div>
              </Tooltip>
            )}
          </div>
          <h4>Filter</h4>
          <FilterRowComponent
            items={diseases}
            item={{ id: "1", name: "REFINE BY DISEASE" }}
            type="disease"
            level={0}
            handleCheck={handleCheck}
            checkedPatientFilters={checkedPatientFilters}
            expan
          />
          <FilterRowComponent
            items={diversityGroups}
            item={{ id: "3", name: "Non-Patient Organization Groups" }}
            type="diversityGroups"
            level={0}
            handleCheck={handleCheck}
            checkedPatientFilters={checkedPatientFilters}
            hasHover
            expan
          />
          <FilterRowComponent
            items={defaultCountries.sort(sortCountries)}
            item={{ id: "2", name: "REFINE BY COUNTRY" }}
            type="country"
            level={0}
            handleCheck={handleCheck}
            countryFilters={countryFilters}
            expan
          />
          {checkedDiversityGroups.length
          || checkedDiseasesFilters.length
          || checkedCountryFilters.length ? (
            <div className="d-flex justify-content-end mt-2 pr-4">
              <Button onClick={handleReset} btn={BtnType.FRAME_LESS}>
                Clear All
              </Button>
            </div>
            ) : null}
        </div>
      </div>
    </div>
  );
};

export default React.memo(LeftWindowComponent);

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import styles from "./styles.module.scss";
import { postSearchIdeasAttempt } from "../adminIdeas.action";

const IdeasSearch = ({
  ideasPage = 1,
  ideasRow = 20,
  setQueryVal = () => {},
  queryVal = "",
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const payload = {
        data: {
          query: queryVal,
        },
        ideasPage: 1,
        ideasRow,
        enqueueSnackbar,
      };

      dispatch(postSearchIdeasAttempt(payload));
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [queryVal]);

  const handleOnchange = (e) => {
    const query = e.target.value;
    setQueryVal(query);
  };

  return (
    <div>
      <div className={styles.searchWrp}>
        <input
          placeholder="Search for ideas"
          value={queryVal}
          onChange={handleOnchange}
          className={styles.searchText}
        />
      </div>
    </div>
  );
};

export default IdeasSearch;

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { ModalFooter, ModalBody } from "../../../../../application/modal";
import {
  Button,
  BtnType,
  Label,
  MultiSelect,
  Loading,
} from "../../../../primitives";
import styles from "./styles.module.scss";
import {
  themesEventAddAttempt,
  allEventsGetAttempt,
} from "../overviewTab.action";

import { useModelPopup } from "../../../../../common/hooks";

const NewThemeEventComponent = ({ addedList }) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [options, setOptions] = useState([]);
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(false);

  const {
    // authReducer: { session },
    councilReducer: { selectedCouncil },
    themesReducer: {
      themesMainReducer: { currentThemeData },
      themesOverviewReducer: { allEventsList, loadingEvents },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        allEventsGetAttempt({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil]);

  useEffect(() => {
    if (!loadingEvents && allEventsList.length) {
      const arr = allEventsList.filter((eventGen) => {
        let free = "empty";
        addedList.map((ev) => {
          if (ev.event_id === eventGen.id) {
            free = "notEmpty";
          }
        });
        if (free === "notEmpty") {
          return false;
        }
        return true;
      });
      setOptions(arr);
    }
  }, [loadingEvents, allEventsList]);

  const handleCancelClick = (e) => {
    popup.hide();
  };

  const handleSaveClick = (e) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleOnSubmit = (value) => {
    if (value.event === undefined) {
      setError(true);
      return;
    }
    dispatch(
      themesEventAddAttempt({
        themeId: currentThemeData.id,
        data: {
          theme_event: [
            {
              event_id: value.event[0].id,
            },
          ],
        },
        enqueueSnackbar,
      })
    );
    setError(false);
    popup.hide();
  };

  return (
    <>
      <ModalBody>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{ id: "" }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleOnSubmit(values);
          }}
        >
          {({ handleSubmit, setFieldValue, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.editStageFormWrp}>
                <Label>Event</Label>
                {loadingEvents ? (
                  <Loading />
                ) : (
                  <MultiSelect
                    onChange={(val) => {
                      setFieldValue("event", val);
                      setError(false);
                    }}
                    placeholder="Choose event"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    options={options}
                    formProps={formikprops}
                    multi={false}
                  />
                )}
                <span className={styles.error}>
                  {error && "Select at least one event!"}
                </span>
              </div>
            </form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button btn={BtnType.REGULAR} onClick={handleSaveClick}>
          Add event
        </Button>
      </ModalFooter>
    </>
  );
};

export default NewThemeEventComponent;

import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import style from "modules/companies/companyMain/rightWindow/newCompaniesList/newCompaniesList.module.scss";
import { setSelectedCompanies } from "modules/companies/companyMain/companyMain.action";
import { followMultipleCompaniesAttempt } from "modules/projects/project/companies/companies.action";
import AddToListComponent from "modules/lists/components/addToList";
import { appModelSettingSet } from "application/app.action";
import AddToIdeaComponent from "modules/companies/companyMain/addToIdea/addToIdea.component";
import AddCompanyComponent from "modules/companies/companyMain/addToProject";
import { useCompanyContext } from "modules/companies/companyMain/providers/companyDataProvider";

const CompaniesSelect = () => {
  const {
    appReducer: { modalSetting },
    companiesReducer: {
      companyMainReducer: { selectedCompanies },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { selectModeIsActive, setSelectModeIsActive, memoizedCompanies } = useCompanyContext();

  const handleSelectMode = () => {
    setSelectModeIsActive(!selectModeIsActive);

    if (!selectModeIsActive) {
      dispatch(
        setSelectedCompanies({
          companies: [],
        })
      );
    }
  };

  const selectedCompaniesDetails = useMemo(() => {
    if (!selectedCompanies?.length) {
      return {
        total: 0,
        companies: 0,
        products: 0,
      };
    }

    const nrOfProducts = selectedCompanies.filter(
      (item) => item.is_product
    ).length;
    const nrOfCompanies = selectedCompanies.filter(
      (item) => !item.is_product
    ).length;

    return {
      total: selectedCompanies.length,
      companies: nrOfCompanies,
      products: nrOfProducts,
    };
  }, [selectedCompanies]);

  const selectAll = () => {
    if (selectedCompanies.length) {
      dispatch(
        setSelectedCompanies({
          shouldReset: true,
          companies: [],
        })
      );
    } else {
      dispatch(
        setSelectedCompanies({
          companies: memoizedCompanies,
        })
      );
    }
  };

  const handleMoreOptionClick = (value) => {
    switch (value) {
      case 1:
      {
        let clearedCompanies = selectedCompanies;

        if (selectedCompaniesDetails?.products > 0) {
          clearedCompanies = selectedCompanies.filter((c) => !c.is_product);
        }

        const payload = {
          follow_relationships: clearedCompanies.map((c) => ({
            following_id: c.id,
          })),
          enqueueSnackbar,
        };

        dispatch(followMultipleCompaniesAttempt(payload));
      }
        break;

      case 2:
      {
        const payload = {
          title: "Add To List",
          show: true,
          height: "1000",
          width: "540",
          component: (
            <AddToListComponent
              enqueueSnackbar={enqueueSnackbar}
              getMyOnlyLists
              companyIds={selectedCompanies.map((c) => c.id)}
              multipleAdd
            />
          ),
        };

        dispatch(
          appModelSettingSet({
            ...modalSetting,
            ...payload,
          })
        );
      }
        break;

      case 6:
      {
        const payload = {
          title: "Add To Idea",
          show: true,
          height: "1000",
          width: "540",
          component: (
            <AddToIdeaComponent
              selectedCompanies={selectedCompanies}
              company={null}
              selectedCouncil={selectedCouncil}
              enqueueSnackbar={enqueueSnackbar}
            />
          ),
        };

        dispatch(
          appModelSettingSet({
            ...modalSetting,
            ...payload,
          })
        );
      }
        break;

      case 3: {
        let filteredCompanies = selectedCompanies;

        if (selectedCompaniesDetails?.products > 0) {
          filteredCompanies = selectedCompanies.filter((c) => !c.is_product);
        }

        const payload = {
          title: "Add Companies To Project",
          show: true,
          height: "1000",
          width: "540",
          component: (
            <AddCompanyComponent
              companyIds={filteredCompanies.map((c) => c.id)}
              enqueueSnackbar={enqueueSnackbar}
              multipleAdd
            />
          ),
        };

        dispatch(
          appModelSettingSet({
            ...modalSetting,
            ...payload,
          })
        );
      }
    }
  };

  return (
    <div className="d-flex align-items-center mt-2">
      <span
        className={style.selectMode}
        onClick={handleSelectMode}
      >
        {selectModeIsActive ? "Hide" : "Select"}
      </span>
      {selectModeIsActive && (
        <span
          onClick={selectAll}
          className={`${style.selectMode} ml-3`}
        >
          {selectedCompanies.length
            ? "Deselect All"
            : "Select All"}
        </span>
      )}
      {selectModeIsActive &&
      selectedCompanies.length ? (
        <div className="d-flex align-items-center ml-5">
          <span
            className={`${style.selectMode} font-weight-bold`}
            onClick={() => handleMoreOptionClick(2)}
          >
            Add to List
          </span>
          {selectedCompaniesDetails?.companies > 0 ? (
            <span
              className={`${style.selectMode} font-weight-bold ml-3`}
              onClick={() => handleMoreOptionClick(3)}
            >
              Add to Project
            </span>
          ) : null}
          {selectedCouncil.name !== "GSK" &&
            selectedCouncil?.settings
              ?.ideas_connected && (
              <span
                className={`${style.selectMode} font-weight-bold ml-3`}
                onClick={() => handleMoreOptionClick(6)}
              >
                Add to Idea
              </span>
            )}

          {selectedCompaniesDetails?.companies > 0 ? (
            <span
              className={`${style.selectMode} font-weight-bold ml-3`}
              onClick={() => handleMoreOptionClick(1)}
            >
              Follow All
            </span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(CompaniesSelect);

import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import CompanyBlockComponent from "modules/companies/companyMain/companyBlock";
import styles from "modules/companies/companyMain/rightWindow/rightWindow.module.scss";
import { useCompanyContext } from "modules/companies/companyMain/providers/companyDataProvider";

const CompaniesLayout0 = () => {
  const {
    companiesReducer: {
      companyMainReducer: {
        selectedCompanies,
        relationshipOwners
      },
    },
  } = useSelector((state) => state);
  const {
    selectLayout,
    downloadPdfIsActive,
    memoizedCompanies,
    handleCrunchBase,
  } = useCompanyContext();

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  if (selectLayout !== 0) return null;

  if (!memoizedCompanies?.length) {
    if (location.state?.query?.length) return null;

    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <span className={styles.noResults}>
          No results found in Traction
        </span>
        <span
          onClick={() => handleCrunchBase(true)}
          className={`${styles.noResultsUrl} mt-3`}
        >
          Search Crunchbase
        </span>
      </div>
    )
  }

  if (downloadPdfIsActive && selectedCompanies?.length) {
    return selectedCompanies.map((company) => (
      <CompanyBlockComponent
        key={company.id}
        company={company}
        enqueueSnackbar={enqueueSnackbar}
        relationshipOwners={relationshipOwners}
      />
    ))
  }

  return (
    memoizedCompanies.map((company) => (
      <CompanyBlockComponent
        key={company.id}
        company={company}
        enqueueSnackbar={enqueueSnackbar}
        relationshipOwners={relationshipOwners}
        isSelected={selectedCompanies.some(
          (c) => c.id === company.id
        )}
      />
    ))
  );
};

export default memo(CompaniesLayout0);

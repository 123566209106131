import { combineReducers } from "redux";
// import viewEventReducer from './eventList/eventList.reducer';
import viewEventReducer from "./viewEvent/viewEvent.reducer";

const eventsReducer = combineReducers({
  // viewEventReducer,
  viewEventReducer,
});

export default eventsReducer;

import React from "react";
import classNames from "classnames";

import styles from "./label.module.scss";

const LabelComponent = (props) => {
  const { children, onClick, className } = props;
  return (
    <div
      onClick={onClick || null}
      className={classNames(className || styles.labelCon)}
    >
      {children}
    </div>
  );
};

export default React.memo(LabelComponent);

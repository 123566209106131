import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { httpGet, errorHandler } from "../../../../common/httpCall";
import {
  THEME_PROJECTS_GET_ATTEMPT,
  themesProjectsGetSuccess,
} from "./projectsTab.action";

export const epicGetThemeProjects = (action$, state$) =>
  action$.pipe(
    ofType(THEME_PROJECTS_GET_ATTEMPT),
    switchMap(
      ({ payload: { themeId, enqueueSnackbar, page, pageSize, overview } }) =>
        httpGet({
          apiVersion: "v2",
          call: `themes/${themeId}/projects?page=${page || 1}&items=${
            pageSize || 20
          }&view_type=${overview ? "overview" : "list"}`,
        }).pipe(
          map((result) => {
            return themesProjectsGetSuccess(result);
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

const themesProjectsEpic = combineEpics(epicGetThemeProjects);

export default themesProjectsEpic;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Field, FieldArray, Formik } from "formik";

import { BtnType, Button, Label } from "modules/primitives";
import { ModalFooter } from "application/modal";
import { formInitialValues } from "./constant";
import useModelPopup from "common/hooks/modelPopup.hook";
import { editProductDetails } from "modules/companies/product/store/product.actions";
import styles from "../../../../../../common/popups/newItem/newVideos/styles.module.scss";
import Icon from "../../../../../../common/icon/icon.component";
import { mdSize } from "../../../../../../common/icon";

const EditProductIntegrations = ({ product }) => {
  const popup = useModelPopup();
  const formRef = useRef();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(formInitialValues);
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (product) {
      setFormValues({
        ...product,
        api: product.api ? 'yes' : 'no',
        integrations: !product.integrations?.length ? [''] : product.integrations
      });
    }
  }, [product]);

  const handleSave = (values) => {
    setShowLoading(true);

    const payload = {
      companyId: product.company.id,
      productId: product.id,
      data: {
        api: values.api === 'yes',
        integrations: values.integrations || [],
      },
    }

    dispatch(editProductDetails(payload));
    popup.hide();
  };

  const handleCancelClick = () => {
    popup.hide();
  }

  const addIntegration = (evt, values) => {
    evt.preventDefault();

    setFormValues({
      ...values,
      integrations: [...values.integrations, '']
    })
  }

  const handleDelete = (index) => {
    setFormValues({
      ...formValues,
      integrations: formValues.integrations.filter((_, idx) => idx !== index),
    });
  }

  return (
    <div>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={formValues}
        onSubmit={handleSave}
      >
        {({ values, handleSubmit, setFieldValue, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <div className="px-4 mt-3">
              <Label>APIs</Label>
              <fieldset id="APIs" className="d-flex mb-3">
                <div className="d-flex mr-3">
                  <input
                    type="radio"
                    id="APIs-yes"
                    name="APIs"
                    value="yes"
                    checked={values.api === 'yes'}
                    onChange={() => setFieldValue('api', 'yes')}
                  />
                  <label className="small ml-1" htmlFor="APIs-yes">Yes</label>
                </div>
                <div className="d-flex">
                  <input
                    type="radio"
                    id="APIs-no"
                    name="APIs"
                    value="yes"
                    checked={values.api === 'no'}
                    onChange={() => setFieldValue('api', 'no')}
                  />
                  <label className="small ml-1" htmlFor="APIs-no">No</label>
                </div>
              </fieldset>
              <FieldArray name="integrations">
                <div className="pl-2">
                  <Label>Product Integrations</Label>
                  {
                    values.integrations.map((f, index) => (
                      <div key={index} className="d-flex align-items-center mb-2">
                        <Field placeholder={`Product Integration ${index + 1}`} name={`integrations.${index}`} className={styles.textBox} />
                        <div
                          className="d-flex ml-3 cursor-pointer"
                          onClick={() => handleDelete(index)}
                        >
                          <Icon {...mdSize} icon="icn-button-delete" />
                        </div>
                      </div>
                    ))
                  }
                  <div className="d-flex mt-3 mb-4 d-flex justify-content-end">
                    <Button
                      onClick={(evt) => addIntegration(evt, values)}
                      btn={BtnType.REGULAR}
                    >
                      Add Integration
                    </Button>
                  </div>
                </div>
              </FieldArray>
            </div>
            <ModalFooter>
              <Button
                className="mr-3"
                btn={BtnType.FRAME_LESS}
                onClick={handleCancelClick}
                disabled={showLoading}
              >
                Cancel
              </Button>
              <Button
                disabled={showLoading}
                type="submit"
                onClick={handleSubmit}
                btn={BtnType.REGULAR}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditProductIntegrations;

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DoubleScrollbar from "react-double-scrollbar";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";

import { checkViewerRole } from "common/checkers/viewerChecker";
import StageComponent from "./stage";
import { Icon, mdSize } from "../../../../common/icon";
import styles from "./stages.module.scss";
import EditStageComponent from "./editStage";
import { useModelPopup } from "../../../../common/hooks";
import { projectWideTasksListGet } from "./stages.action";

const StagesComponent = ({ projectId }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  const {
    projectsReducer: {
      projectReducer: {
        projectIsDownloading,
      },
      stagesReducer: { stagesList, projectWideTasks },
      taskReducer: { updateStages },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (projectId) {
      dispatch(
        projectWideTasksListGet({
          projectId,
          enqueueSnackbar,
        })
      );
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      dispatch(
        projectWideTasksListGet({
          projectId,
          enqueueSnackbar,
        })
      );
    }
  }, [updateStages]);

  const handleAddNewStageClick = (e) => {
    popup.show({
      title: "Add new Stage",
      component: <EditStageComponent stagesList={stagesList} />,
    });
  };

  const renderStages = useCallback(() => {
    return (
      <div className={`${styles.stagesCon} ${projectIsDownloading ? 'flex-wrap' : ''}`}>
        {stagesList.map((stage) => (
          <StageComponent
            stageWideTasks={projectWideTasks?.filter(
              (p) => p && p.stage_id === stage.id
            )}
            key={stage.id}
            stage={stage}
          />
        ))}
        <div className={styles.addNewWrp}>
          {!blockFunctionality ? (
            <div
              className={styles.addNewCon}
              onClick={(e) => {
                handleAddNewStageClick(e);
              }}
            >
              <Icon {...mdSize} icon="icn-add" className={styles.icon} />
              <div className="caption">Add Column</div>
            </div>
          ) : (
            <Tooltip
              title="You have View-Only access. To edit this Project, please ask your Admin to upgrade your account to Standard access and invite you to this Project."
              placement="top"
            >
              <div className={styles.addNewCon}>
                <Icon {...mdSize} icon="icn-add" className={styles.icon} />
                <div className="caption">Add Column</div>
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    )
  }, [stagesList, blockFunctionality, projectWideTasks, projectIsDownloading]);

  return (
    <div className={styles.stagesWrp}>
      {
        projectIsDownloading ? (
          <div>
            {renderStages()}
          </div>
        ) : (
          <DoubleScrollbar>
            {renderStages()}
          </DoubleScrollbar>
        )
      }
    </div>
  );
};

export default React.memo(StagesComponent);

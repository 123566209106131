import React, { useState } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import styles from "./details.module.scss";
import { EMPLOYEES_ENUM } from "../../../../../common/constants";
import { deseasCheck } from "../../../patientOrganizationMain/patientOrganizationMain.action";

const DetailsComponent = (props) => {
  const { company } = props;

  const [flag] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    patientOrganizationsReducer: {
      patientOrganizationMainReducer: { checkedPatientFilters },
    },
  } = useSelector((state) => state);

  const parseJSON = (str) => {
    if (typeof str === "string") {
      return JSON.parse(str.replace(/=>/g, ":"));
    }
    return str;
  };

  const handleDiseaseClick = (disease) => {
    const currentDiseases = [...checkedPatientFilters.disease_ids];

    if (!currentDiseases.includes(disease.disease_id)) {
      currentDiseases.push(disease.disease_id);
    }

    dispatch(
      deseasCheck({
        disease_ids: currentDiseases,
      }),
    );

    const timeout = setTimeout(() => {
      history.push("/patient_organizations");
      clearTimeout(timeout);
    }, 500);
  };

  const handleDiversityGroupClick = (group) => {
    const currentGroups = [...checkedPatientFilters.diversity_group_ids];

    if (!currentGroups.includes(group.id)) {
      currentGroups.push(group.id);
    }

    dispatch(
      deseasCheck({
        diversity_group_ids: currentGroups,
      }),
    );

    const timeout = setTimeout(() => {
      history.push("/patient_organizations");
      clearTimeout(timeout);
    }, 500);
  };

  return (
    <>
      {flag && company && (
        <div className={styles.detailsPanel}>
          <div className={classNames("caption", styles.details)}>
            {company.description}
          </div>
          <div className={styles.webWrapper}>
            <h5 className={styles.webUrl}>
              <a href={company.web_url} target="_blank" rel="noreferrer">
                {company.web_url
                  && company.web_url.replace(/\b(http:\/\/|https:\/\/)\b/g, "")}
              </a>
            </h5>
            <div className={styles.email}>
              <a
                className={styles.webUrl}
                onClick={() => window.open(`mailto:${company.contact_email}`)}
              >
                {company.contact_email}
              </a>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={styles.dataRow}>
                <div className={styles.label}>Founded</div>
                <div className={styles.value}>
                  {company.founded
                    && format(new Date(company.founded), "MM/dd/yyyy")}
                </div>
              </div>
              <div className={styles.dataRow}>
                <div className={styles.label}>Headquarters</div>
                <div className={styles.value}>{company.location}</div>
              </div>
              <div className={styles.dataRow}>
                <div className={styles.label}>Employees</div>
                <div className={styles.value}>
                  {company.num_employees_enum
                    ? EMPLOYEES_ENUM[company.num_employees_enum]
                    : "-"}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className={styles.dataRow}>
                <div className={styles.label}>Geographic Reach</div>
                <div
                  className={classNames(styles.value, styles.competitorWrapper)}
                >
                  <div className={styles.competitor}>
                    {company.geographic_reach}
                  </div>
                </div>
              </div>
              <div className={styles.dataRow}>
                <div className={styles.label}>Country</div>
                <div
                  className={classNames(styles.value, styles.customerWrapper)}
                >
                  {company.country}
                </div>
              </div>
              <div className={styles.dataRow}>
                <div className={styles.label}>Membership Size</div>
                <div
                  className={classNames(styles.value, styles.customerWrapper)}
                >
                  {company.membership_size}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className={styles.dataRow}>
                <div className={styles.label}>Diseases</div>
                {company.resource_diseases
                && company.resource_diseases.length ? (
                    parseJSON(company.resource_diseases).map((disease) => (
                      <div
                        key={disease.disease.name}
                        className={classNames(styles.value, styles.tagWrp)}
                        onClick={() => handleDiseaseClick(disease)}
                      >
                        <div
                          className={classNames(
                            styles.tags,
                            checkedPatientFilters.disease_ids.includes(
                              disease.disease_id,
                            )
                              ? styles.selectedDisease
                              : "",
                          )}
                        >
                          {disease.disease.name}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={styles.value}> </div>
                  )}
              </div>
              <div className={styles.dataRow}>
                <div className={styles.label}>Non-Patient Organization Groups</div>
                {company.diversity_groups && company.diversity_groups.length ? (
                  company.diversity_groups.map((group) => (
                    <div
                      key={group.id}
                      className={classNames(styles.value, styles.tagWrp)}
                      onClick={() => handleDiversityGroupClick(group)}
                    >
                      <div
                        className={classNames(
                          styles.tags,
                          checkedPatientFilters?.diversity_group_ids?.includes(
                            group.id,
                          )
                            ? styles.selectedDisease
                            : "",
                        )}
                      >
                        {group.name}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={styles.value}> </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(DetailsComponent);

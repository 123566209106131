import React from "react";
import { Switch, Route } from "react-router-dom";

import IdeasListWrapper from "./ideasListWrapper";
import IdeaProfileWrapper from "./ideaProfileWrapper";

const IdeasWrapper = () => (
  <Switch>
    <Route exact path="/ideas/:id" component={IdeaProfileWrapper} />
    <Route exact path="/ideas" component={IdeasListWrapper} />
  </Switch>
);

export default React.memo(IdeasWrapper);

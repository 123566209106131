function containsWord(str, word) {
  return str.match(new RegExp("\\b" + word + "\\b")) != null;
}

const checkForTypeInTitle = (name, titleType) => {
  let doesTitleHaveTypeInName = false;
  const positionOfType = name?.toLowerCase().indexOf(titleType);
  const isWordExists = containsWord(name?.toLowerCase(), titleType);
  if ((!name || positionOfType === 0) && isWordExists) {
    doesTitleHaveTypeInName = true;
  }
  if (doesTitleHaveTypeInName && name?.length <= titleType.length + 3) {
    return true;
  }
  return false;
};
const maxTypeNumber = (name, arrayOfTypesNum, titleType) => {
  if (name?.trim()?.length === titleType?.trim()?.length) {
    return "2";
  }
  const splittedName = name.split(" ");
  if (!Number.isNaN(+splittedName[splittedName.length - 1])) {
    arrayOfTypesNum.push(+splittedName[splittedName.length - 1]);
  }
  return Math.max(...arrayOfTypesNum) + 1;
};
const checkDuplicateName = (cfGroup, titleType) => {
  let nameForTheCF = titleType;
  const arrayOfTypesNum = [];
  if (cfGroup) {
    cfGroup.forEach((customField) => {
      if (checkForTypeInTitle(customField.field_name || customField.field_value, titleType)) {
        nameForTheCF =
          `${titleType} ` +
          maxTypeNumber(
            customField.field_name || customField.field_value,
            arrayOfTypesNum,
            titleType
          );
      }
    });
  }
  return nameForTheCF;
};
export default checkDuplicateName;

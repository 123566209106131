import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import { ModalBody, ModalFooter } from "../../../../../application/modal";

import { BtnType, Button } from "../../../../primitives";
import { useModelPopup } from "../../../../../common/hooks/index";

const RatingTemplateModal = ({ selectedTemplateCategories }) => {
  const popup = useModelPopup();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(selectedTemplateCategories);
  }, [selectedTemplateCategories]);

  const handleCloseClick = () => {
    popup.hide();
  };

  const columns = React.useMemo(
    (e) => [
      {
        Header: "Ratings Category",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Weight",
        accessor: "weight",
        width: 100,
      },
    ],
    [selectedTemplateCategories],
  );

  return (
    <div>
      <ModalBody>
        <ReactTable
          columns={columns}
          data={data}
          showPagination={false}
          minRows={10}
          skipPageReset
        />
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.REGULAR} onClick={handleCloseClick}>
          Close
        </Button>
      </ModalFooter>
    </div>
  );
};

export default RatingTemplateModal;

import React, {
  memo, useEffect, useRef, useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Formik } from "formik";

import {
  BtnType,
  Button,
  Loading,
  MultiSelect,
} from "../../../../../primitives";
import { ModalFooter } from "../../../../../../application/modal";
import styles from "./areasOfFocus.module.scss";
import { formInitialValues } from "./constants";
import { useModelPopup } from "../../../../../../common/hooks";
import {
  companyGetFilters,
  companyGetTopics,
} from "../../../../../companies/companyMain/companyMain.action";
import { updateClaimProfileAttempt } from "../../../../store/claimProfileActions";

const EditIdeaAreaOfFocus = ({ profile }) => {
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyMainReducer: { topics, industries },
    },
  } = useSelector((state) => state);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (profile) {
      let currForm = formInitialValues;

      if (profile?.resource_industries) {
        const industry_ids = profile.resource_industries.map(
          (r) => r.industry.id,
        );

        currForm = {
          ...currForm,
          industry_ids,
        };
      }

      if (profile?.resource_topics) {
        const topic_ids = profile.resource_topics.map((r) => r.topic.id);

        currForm = {
          ...currForm,
          topic_ids,
        };
      }

      setFormValues(currForm);
    }
  }, [profile]);

  useEffect(() => {
    if (selectedCouncil && !topics?.length && !industries?.length) {
      dispatch(
        companyGetFilters({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        }),
      );

      dispatch(
        companyGetTopics({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        }),
      );
    }
  }, [selectedCouncil, topics, industries]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleAreasOfFocusSave = (values) => {
    setShowLoading(true);

    dispatch(
      updateClaimProfileAttempt({
        companyId: profile.id,
        councilId: selectedCouncil.id,
        data: {
          ...values,
        },
        enqueueSnackbar,
        callBack: () => {
          setShowLoading(false);

          popup.hide();
        },
      }),
    );
  };

  const handleTopicSelect = (value, prop) => {
    setFormValues({
      ...formValues,
      [prop]: value.map((t) => t.id),
    });
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={formValues}
      onSubmit={handleAreasOfFocusSave}
    >
      {({ values, handleSubmit, ...formikprops }) => (
        <form onSubmit={handleSubmit}>
          <div className={styles.customPadding}>
            <div className="mt-3">
              {!topics?.length && !industries?.length ? (
                <Loading customText="Preparing areas of focus form..." />
              ) : (
                <>
                  <label htmlFor="topic_ids">Technologies</label>
                  <MultiSelect
                    disabled={showLoading}
                    name="topic_ids"
                    placeholder="Select"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    options={topics}
                    formProps={{
                      ...formikprops,
                      values,
                    }}
                    onChange={(value) => handleTopicSelect(value, "topic_ids")}
                  />
                  <label htmlFor="industry_ids">
                    {selectedCouncil?.name === "Freddie Mac"
                      ? "Housing Cycle"
                      : "Industries"}
                  </label>
                  <MultiSelect
                    disabled={showLoading}
                    name="industry_ids"
                    placeholder="Select"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    options={industries}
                    formProps={{
                      ...formikprops,
                      values,
                    }}
                    onChange={(value) => handleTopicSelect(value, "industry_ids")}
                  />
                </>
              )}
              {showLoading && <Loading customText="Updating your form..." />}
            </div>
          </div>
          <ModalFooter>
            <Button
              className="mr-3"
              btn={BtnType.FRAME_LESS}
              onClick={handleCancelClick}
              disabled={showLoading}
            >
              Cancel
            </Button>
            <Button
              disabled={showLoading}
              type="submit"
              onClick={handleSubmit}
              btn={BtnType.REGULAR}
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default memo(EditIdeaAreaOfFocus);

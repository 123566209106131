import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import styles from "./projectDetails.module.scss";
import {
  BtnType,
  Button,
  Pannel,
  UserInline,
  UserInlineTypes,
} from "../../../../primitives";
import RichEditor from "../../../../../common/components/richEditor";
import { projectGet, projectPut } from "../../project.action";

const ProjectNextStepsComponent = ({ selectedProject, canManageTasks }) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [isEditMode, setIsEditMode] = useState(false);
  const [nextSteps, setNextSteps] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedProject?.next_steps) {
      setNextSteps(selectedProject.next_steps);
    } else {
      setNextSteps("");
    }
  }, [selectedProject?.next_steps]);

  const handleManageStepsClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handleSaveNextSteps = async () => {
    dispatch(
      projectPut({
        userId: session.id,
        projectId: selectedProject.id,
        councilId: selectedCouncil.id,
        next_steps: nextSteps,
        next_steps_created_at: new Date(),
        next_steps_created_by_id: session.id,
      })
    );
    dispatch(projectGet({ id: selectedProject.id }));

    setIsEditMode(false);
  };

  const rightContent = (
    <div>
      {!isEditMode && canManageTasks && (
        <Button
          btn={BtnType.FRAME_LESS}
          icon="icn-edit"
          onClick={handleManageStepsClick}
        />
      )}
    </div>
  );

  return (
    <Pannel title="Next Steps" rightContent={rightContent}>
      {isEditMode ? (
        <div className={styles.wrapper}>
          <RichEditor
            initialValue={nextSteps}
            updateValue={(e) => setNextSteps(e)}
          />
          <div className="mt-4">
            <Button
              btn={BtnType.FRAME_LESS}
              onClick={() => setIsEditMode(false)}
            >
              Cancel
            </Button>
            <Button className="ml-3" onClick={handleSaveNextSteps}>
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          {nextSteps?.length ? (
            <div dangerouslySetInnerHTML={{ __html: nextSteps || "" }} />
          ) : !canManageTasks ? null : (
            <div className="d-flex justify-content-center">
              <Button
                btn={BtnType.FRAME_LESS}
                onClick={() => setIsEditMode(true)}
              >
                + ADD NEXT STEPS
              </Button>
            </div>
          )}
          {(selectedProject?.next_steps_created_by ||
            selectedProject?.next_steps_created_at) && (
            <div className="d-flex justify-content-end align-items-center">
              {selectedProject?.next_steps_created_by && (
                <div className="d-flex">
                  <span className="pr-3 small">Updated by</span>
                  <UserInline
                    userId={selectedProject.next_steps_created_by.id}
                    avatar={selectedProject.next_steps_created_by.avatar}
                    first={selectedProject.next_steps_created_by.first_name}
                    last={selectedProject.next_steps_created_by.last_name}
                    type={UserInlineTypes.WITH_NAME}
                    dontRequest
                  />
                </div>
              )}
              {selectedProject?.next_steps_created_at && (
                <div className="d-flex ml-5">
                  {format(
                    new Date(selectedProject.next_steps_created_at),
                    "eeee, MMM do, yyyy"
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Pannel>
  );
};

export default ProjectNextStepsComponent;

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { tractionAIMainStyles } from "modules/tractionAI/constants";
import { BtnType, Button } from "modules/primitives";
import { AISelectScreen } from "modules/tractionAI/store/tractionAI.actions";

const AIModalHeader = ({ handleClose, selectedCouncil }) => {
  const dispatch = useDispatch();

  const { tractionAiReducer: {
    activeScreen,
    activeSubScreen
  }} = useSelector((state) => state);

  const goBack = () => {
    if (activeSubScreen) {
      if (activeScreen === 'recommend' && !selectedCouncil?.settings?.ideas_connected) {
        // if ideas is disconnected by default go to main
        dispatch(AISelectScreen({
          activeScreen: null,
          activeSubScreen: null,
          applyNoAnimationLogic: true,
        }));

        return;
      }

      dispatch(AISelectScreen({
        activeScreen,
        activeSubScreen: null,
        applyNoAnimationLogic: true,
      }));
      return
    }

    dispatch(AISelectScreen({
      activeScreen: null,
      activeSubScreen: null,
      applyNoAnimationLogic: true,
    }));
  }

  return (
    <div className="p-3">
      {
        !activeScreen ? (
          <>
            <div className="d-flex justify-content-end">
              <div style={{ ...tractionAIMainStyles.title, fontSize: "18px" }}>
                Traction AI
              </div>
              <Button btn={BtnType.OUTLINE} className="ml-5" onClick={handleClose}>Close</Button>
            </div>
          </>
        ) : (
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center cursor-pointer" onClick={goBack}>
              <span>
                <svg width="25px" height="25px" viewBox="0 0 512 512" version="1.1" fill="#000000"><g
                  id="SVGRepo_bgCarrier" strokeWidth="0"></g><g
                  id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g
                  id="SVGRepo_iconCarrier"><g
                  id="Layer_1"></g> <g id="Layer_2"> <g> <path className="st0"
                                                               d="M217,129.88c-6.25-6.25-16.38-6.25-22.63,0L79.61,244.64c-0.39,0.39-0.76,0.8-1.11,1.23 c-0.11,0.13-0.2,0.27-0.31,0.41c-0.21,0.28-0.42,0.55-0.62,0.84c-0.14,0.21-0.26,0.43-0.39,0.64c-0.14,0.23-0.28,0.46-0.41,0.7 c-0.13,0.24-0.24,0.48-0.35,0.73c-0.11,0.23-0.22,0.45-0.32,0.68c-0.11,0.26-0.19,0.52-0.28,0.78c-0.08,0.23-0.17,0.46-0.24,0.69 c-0.09,0.29-0.15,0.58-0.22,0.86c-0.05,0.22-0.11,0.43-0.16,0.65c-0.08,0.38-0.13,0.76-0.17,1.14c-0.02,0.14-0.04,0.27-0.06,0.41 c-0.11,1.07-0.11,2.15,0,3.22c0.01,0.06,0.02,0.12,0.03,0.18c0.05,0.46,0.12,0.92,0.21,1.37c0.03,0.13,0.07,0.26,0.1,0.39 c0.09,0.38,0.18,0.76,0.29,1.13c0.04,0.13,0.09,0.26,0.14,0.4c0.12,0.36,0.25,0.73,0.4,1.09c0.05,0.11,0.1,0.21,0.15,0.32 c0.17,0.37,0.34,0.74,0.53,1.1c0.04,0.07,0.09,0.14,0.13,0.21c0.21,0.38,0.44,0.76,0.68,1.13c0.02,0.03,0.04,0.06,0.06,0.09 c0.55,0.81,1.18,1.58,1.89,2.29l114.81,114.81c3.12,3.12,7.22,4.69,11.31,4.69s8.19-1.56,11.31-4.69c6.25-6.25,6.25-16.38,0-22.63 l-87.5-87.5h291.62c8.84,0,16-7.16,16-16s-7.16-16-16-16H129.51L217,152.5C223.25,146.26,223.25,136.13,217,129.88z"></path> </g> </g> </g></svg>
              </span>
              <span className="text-muted">back</span>
            </div>
            <div className="d-flex align-items-center">
              <div style={{ ...tractionAIMainStyles.title, fontSize: "18px" }}>
                Traction AI
              </div>
              <Button btn={BtnType.OUTLINE} className="ml-5" onClick={handleClose}>Close</Button>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default React.memo(AIModalHeader);

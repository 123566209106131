import React, { useCallback, useRef } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import styles from "./styles.module.scss";

const FordReplicationsTableRow = ({ row, showMoreDescription, highLightColumn = [] }) => {
  const rowRef = useRef(null);

  const makeHighLightColumnClass = useCallback((props) => {
    const hasProp = highLightColumn.some(p => props.includes(p));
    return hasProp ? styles.highLightedColumn : null
  }, [highLightColumn]);

  return (<div className={styles.tableRow} ref={rowRef}>
    <div className={classNames(styles.tableDataCell)}>
      <Link to={`/projects/${row.id}/overview`}>{row.name}</Link>
    </div>
    <div className={classNames(styles.tableDataCell, "flex-column")}>
      {row.themes?.length ? (<>
        {row.themes.map(theme => (<Link to={`/themes/individual_page/${theme.id}/overview`}
                                        key={theme.id}>{theme.name}</Link>))}
      </>) : "-"}
    </div>
    <div
      className={classNames(styles.tableDataCell, makeHighLightColumnClass(['plant']))}
      style={{
        height: rowRef.current ? `${rowRef.current.clientHeight}px` : "100%",
      }}
    >
      {row.plant ? row.plant.name : "-"}
    </div>
    <div
      className={classNames(styles.tableDataCell, makeHighLightColumnClass(['area']))}
      style={{
        height: rowRef.current ? `${rowRef.current.clientHeight}px` : "100%",
      }}
    >
      {row.area?.name || "-"}
    </div>
    <div
      className={classNames(styles.tableDataCell, makeHighLightColumnClass(['reference_plant']))}
      style={{
        height: rowRef.current ? `${rowRef.current.clientHeight}px` : "100%",
      }}
    >
      {row.reference_plant?.name || "-"}
    </div>
    <div className={classNames(styles.tableDataCell)}>
      {row.original_project?.plant?.name || "-"}
    </div>
    <div className={classNames(styles.tableDataCell, "d-flex flex-column")}>
      <span
        dangerouslySetInnerHTML={{ __html: row.description?.length > 50 ? `${row.description.slice(0, 50)}...` : row.description || "-" }} />
      {row.description?.length > 50 ? (<span className="small cursor-pointer"
                                             onClick={() => showMoreDescription(row.description)}>show more</span>) : null}
    </div>
    <div
      className={classNames(styles.tableDataCell, makeHighLightColumnClass(['projectStage']))}
      style={{
        height: rowRef.current ? `${rowRef.current.clientHeight}px` : '100%'
      }}
    >
      {row.stage ? row.stage.name : "-"}
    </div>
    {/*<div className={classNames(styles.tableDataCell)}>*/}
    {/*  -*/}
    {/*</div>*/}
    <div
      className={classNames(styles.tableDataCell, makeHighLightColumnClass(['investment_usd']))}
      style={{
        height: rowRef.current ? `${rowRef.current.clientHeight}px` : '100%'
      }}
    >
      {row.investment_usd ? `$${row.investment_usd.toLocaleString()}` : "-"}
    </div>
    <div
      className={classNames(styles.tableDataCell, makeHighLightColumnClass(['savings']))}
      style={{
        height: rowRef.current ? `${rowRef.current.clientHeight}px` : '100%'
      }}
    >
      {row.savings_usd ? `$${row.savings_usd.toLocaleString()}` : "-"}
    </div>
    <div
      className={classNames(styles.tableDataCell, makeHighLightColumnClass(['tarr']))}
      style={{
        height: rowRef.current ? `${rowRef.current.clientHeight}px` : '100%'
      }}
    >
      {row.tarr_percentage ? `${row.tarr_percentage}%` : "-"}
    </div>
    <div className={classNames(styles.tableDataCell)}>
      {row.prototype_funded_by?.option_selected || '-'}
    </div>
    <div className={classNames(styles.tableDataCellBigger)}>
      {row.implementation_funded_by?.option_selected || '-'}
    </div>
    <div className={classNames(styles.tableDataCell)}>
      {row.start_date ? format(new Date(row.start_date), "MMM dd, yyyy") : "-"}
    </div>
    <div className={classNames(styles.tableDataCell)}>
      {row.prototype_date ? format(new Date(row.prototype_date), "MMM dd, yyyy") : "-"}
    </div>
    <div
      className={classNames(styles.tableDataCell, makeHighLightColumnClass(['implementation_date', 'planned_replication']))}
      style={{
        height: rowRef.current ? `${rowRef.current.clientHeight}px` : '100%'
      }}
    >
      {row.implementation_date ? format(new Date(row.implementation_date), "MMM dd, yyyy") : "-"}
    </div>
    <div className={classNames(styles.tableDataCell)}>
      {row.expected_job_1_date ? format(new Date(row.expected_job_1_date), "MMM dd, yyyy") : "-"}
    </div>
    <div
      className={classNames(styles.tableDataCell, makeHighLightColumnClass(['job1Date']))}
      style={{
        height: rowRef.current ? `${rowRef.current.clientHeight}px` : '100%'
      }}
    >
      {row.job_1_date ? format(new Date(row.job_1_date), "MMM dd, yyyy") : "-"}
    </div>
    <div
      className={classNames(styles.tableDataCell, makeHighLightColumnClass(['ideaGeneration']))}
      style={{
        height: rowRef.current ? `${rowRef.current.clientHeight}px` : '100%'
      }}
    >
      {row.idea_generation}
    </div>
    <div
      className={classNames(styles.tableDataCell, makeHighLightColumnClass(['implementation']))}
      style={{
        height: rowRef.current ? `${rowRef.current.clientHeight}px` : '100%'
      }}
    >
      {row.implementation}
    </div>
    <div
      className={classNames(styles.tableDataCell, makeHighLightColumnClass(['job1Accomplishment']))}
      style={{
        height: rowRef.current ? `${rowRef.current.clientHeight}px` : '100%'
      }}
    >
      {row.job1_accomplishment ? 'Y' : 'N'}
    </div>
    <div
      className={classNames(styles.tableDataCell, makeHighLightColumnClass(['planned_replication']))}
      style={{
        height: rowRef.current ? `${rowRef.current.clientHeight}px` : "100%",
      }}
    >
      {row.next_stage_quarter_to_s || "-"}
    </div>
    <div
      className={classNames(styles.tableDataCell, makeHighLightColumnClass(['approvedReplications']))}
      style={{
        height: rowRef.current ? `${rowRef.current.clientHeight}px` : "100%",
      }}
    >
      {row.replication > 0 ? "Y" : "N"}
    </div>
    <div className={classNames(styles.tableDataCell)}>
      {row.original_project?.id ? (
        <Link to={`/projects/${row.original_project.id}/overview`}>{row.original_project.name}</Link>) : "-"}
    </div>
  </div>);
};

export default React.memo(FordReplicationsTableRow);

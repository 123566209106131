import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Icon from "common/icon/icon.component";
import {
  themesFavAttempt,
  themesFavDeleteAttempt,
} from "../../../../themesMainPage/themesMain.action";

import styles from "./styles.module.scss";

const ThemesBlock = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    themesReducer: {
      themesMainReducer: { favPeding },
    },
  } = useSelector((state) => state);
  const { themeIncluded } = props;
  const [isFavorite, setIsFavorite] = useState(
    themeIncluded?.current_user_favorite
  );
  const history = useHistory();

  const goToTheme = (id) => {
    history.push({
      pathname: `/themes/individual_page/${id}/overview`,
      state: { cardData: {} },
    });
  };

  const handleThemeFav = () => {
    if (favPeding) {
      return;
    }
    if (isFavorite) {
      dispatch(
        themesFavDeleteAttempt({
          themeId: themeIncluded.theme_id,
          enqueueSnackbar,
        })
      );
    } else {
      dispatch(
        themesFavAttempt({
          themeId: themeIncluded.theme_id,
          enqueueSnackbar,
        })
      );
    }
    setIsFavorite((prev) => !prev);
  };

  useEffect(() => {
    setIsFavorite(themeIncluded?.current_user_favorite);
  }, [themeIncluded?.current_user_favorite]);

  return (
    <div className={styles.themesBlock}>
      <div className={styles.themeNameNFavorite}>
        <span
          className={styles.themesItem}
          onClick={(e) => {
            e.preventDefault();
            goToTheme(themeIncluded.theme_id);
          }}
        >
          {themeIncluded.theme_name}
        </span>

        <Icon
          className={
            isFavorite ? styles.favoriteEnable : styles.favoriteDissable
          }
          onClick={(e) => {
            handleThemeFav();
            e.preventDefault();
            e.stopPropagation();
          }}
          icon="icn-subnav-ratings-fill"
        />

        {themeIncluded?.current_user_role && (
          <div className={styles.joinLink}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" rx="12" fill="#E3F9E5" />
              <path
                d="m15.375 8-4.533 4.658-2.217-2.278L7 12.05 10.841 16 17 9.67 15.375 8z"
                fill="#3F9142"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};
export default ThemesBlock;

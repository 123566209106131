import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import Icon from "common/icon/icon.component";
import { smSize } from "common/icon/icon.constants";
import { MoreOptions, CheckBox, CheckType } from "modules/primitives";
import { ACTIVITY_OPTIONS_WITHOUT_DELETE } from "common/constants.js";
import { convertDateTimezone, shouldDownload } from "common/helper";
import styles from "./styles.module.scss";
import UploadedByInfo from "../../../../../../common/components/uploadedByInfo";

const AgreementComponent = ({ item, handleEditClick, disabled }) => {
  const agreementRef = useRef(null);
  const {
    companiesReducer: {
      companyReducer: { shouldOpenUnusedAgreement },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (
      item.option_selected[0]
      && shouldOpenUnusedAgreement.type === item.option_selected[0]
    ) {
      window.scrollTo({
        left: 0,
        top: agreementRef.current.offsetTop,
        behaviour: "smooth",
      });
    }
  }, [item, shouldOpenUnusedAgreement.type]);

  const getFilename = (url) => {
    if (url && typeof url === "string") {
      return url.split("/").pop();
    }
    return "";
  };

  const handleMoreOptionClick = (value) => {
    if (value === 0) {
      handleEditClick(item);
    }
  };

  return (
    <div ref={agreementRef} className={styles.wrapper}>
      <div className={styles.titleRow}>
        <div className="d-flex flex-column">
          <h5 className={styles.title}>{item?.field_name || "Agreement"}</h5>
          {
            item?.field_placeholder?.length ? (
              <span className="text-muted small">{item.field_placeholder}</span>
            ) : null
          }
        </div>
        {!disabled && (
          <div className={styles.rightWrp}>
            <div className={styles.colMore}>
              <MoreOptions
                options={ACTIVITY_OPTIONS_WITHOUT_DELETE}
                className={styles.moreOptWrp}
                onClick={handleMoreOptionClick}
                customSvgIcon
              />
            </div>
          </div>
        )}
      </div>

      <div className={classNames("small", styles.description)}>
        {item.option_selected[0] && (
          <>
            <CheckBox
              checkType={CheckType.BLUE}
              isChecked={item.option_selected[0]}
              disabled
              readOnly
            />
            {item.option_selected[0]}
          </>
        )}
      </div>

      {item?.documents
        && item?.documents.map((doc) => (
          <div
            key={doc.company_custom_field_id}
            className={styles.documnetContainer}
          >
            <Icon
              {...smSize}
              icon="icn-subnav-documents"
              className={styles.tileIcon}
            />
            <div className="d-flex align-items-center w-50">{doc.name}</div>
            <UploadedByInfo document={doc} />
            <div className={styles.rightWrp}>
              <a
                className={styles.docView}
                target="blank"
                href={doc.uri}
                aria-disabled={disabled}
              >
                {shouldDownload(getFilename(doc.uri)) ? "download" : "view"}
              </a>
            </div>
          </div>
        ))}

      <div className={styles.dateWrp}>
        {item.document_date && (
          <div className={styles.dateField}>
            <span className={styles.label}>Signed on:</span>
            <span className={styles.marginL10px}> </span>
            <div className={styles.label}>
              {format(
                convertDateTimezone(item?.document_date),
                "EEEE, MMMM dd, yyyy",
              )}
            </div>
          </div>
        )}
        {item.expiration_date && (
          <div className={styles.dateField}>
            <span className={styles.label}>Expires on: </span>
            <span className={styles.marginL10px}> </span>
            <div className={styles.label}>
              {format(
                convertDateTimezone(item.expiration_date),
                "EEEE, MMMM dd, yyyy ",
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(AgreementComponent);

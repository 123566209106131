import React, { memo, useState } from "react";
import { withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";

import NewCompanyItem from "../newCompanyItem";
import NewCompanyItemResultHoverComponent from "../newCompanyItemResultHover/newCompanyItemResultHover.component";
import style from "../newCompanyItem/newCompanyItem.module.scss";
import { CompanyAvatar } from "../../../../../primitives";

const width = window.innerWidth > 1200 ? 800 : 600;

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fafafa",
    boxShadow: "5px 5px 4px 0 rgba(0, 0, 0, 0.14)",
    color: "rgba(0, 0, 0, 0.87)",
    width,
    minWidth: width,
    fontSize: theme.typography.pxToRem(15),
    border: "1px solid #cccccc",
    padding: "15px 20px 10px",
  },
}))(Tooltip);

const companyNameWrapperStyles = {
  cursor: "pointer",
  display: "flex",
  width: "180px",
  alignItems: "center",
};

const logoStyles = {
  minWidth: "24px",
  width: "24px",
  height: "24px",
  marginRight: "10px",
};

const CustomTooltipChild = React.forwardRef((props, ref) => {
  const {
    company,
    history,
    dispatch,
    last,
    currentYear,
    isHPCouncil,
    handleRelationShipChange,
    selectModeIsActive,
    handleCompanySelect,
    isSelected,
    userIsScrolling,
    isIDBCouncil,
    ...otherProps
  } = props;

  const handleCompanyClick = () => {
    if (company.is_product) {
      history.push(`/companies/${company.company_slug || company.company_id}/products/${company.slug}`);
      return;
    }

    localStorage.setItem("company", JSON.stringify(company));
    history.push(`/companies/${company.slug || company.id}/overview`);
  };

  return (
    <div
      {...otherProps}
      currentyear={currentYear}
      ref={ref}
      className={style.companyNameWrapper}
    >
      <div style={companyNameWrapperStyles} onClick={handleCompanyClick}>
        <div style={logoStyles}>
          <CompanyAvatar
            imgSrc={company.logo}
            name={company.name}
            hasBorderRadius
            smallSize
          />
        </div>
        <div className="d-flex align-items-center justify-content-between w-100">
          <span>{company.name}</span>
          {
            company.is_product && <span className="small text-muted ml-4">Product</span>
          }
        </div>
      </div>
    </div>
  );
});

const NewCompanyItemWrapperComponent = (props) => {
  const {
    appReducer: { highlightItemWithId },
  } = useSelector((state) => state);
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`
      d-flex position-relative align-items-stretch ${
        props.last ? style.lastCompany : ""
      } 
      ${props.company.id === highlightItemWithId ? style.flashingRow : ""}`}
      onMouseOut={() => setHovered(false)}
      onMouseLeave={() => setHovered(false)}
    >
      {props.selectModeIsActive && (
        <div className={style.selectCompany}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={props.handleCompanySelect}
          />
        </div>
      )}
      <div onMouseOver={() => setHovered(true)}>
        <HtmlTooltip
          interactive
          title={(
            <>
              <NewCompanyItemResultHoverComponent {...props} />
            </>
          )}
          enterDelay={1000}
          open={props.userIsScrolling ? false : hovered}
          placement="top"
        >
          <CustomTooltipChild {...props} />
        </HtmlTooltip>
      </div>
      <NewCompanyItem {...props} />
    </div>
  );
};

export default memo(NewCompanyItemWrapperComponent);

import React, { useCallback, useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { Loading, Pagination } from "modules/primitives/index";
import FollowingSearch from "./followingSearch";
import styles from "./followingSettings.module.scss";
import Suggestions from "../../../common/components/suggestions";
import FollowingItem from "./followingItem";
import { httpPost, pageMeta } from "../../../common/httpCall";
import { pageSettings } from "./constants/following-constants";

const FollowingSettings = ({ enqueueSnackbar }) => {
  const [searchString, setSearchString] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [followers, setFollowers] = useState(null);
  const [pageSetting, setPageSetting] = useState(pageSettings);

  const {
    authReducer: { session },
  } = useSelector((state) => state);

  useEffect(() => {
    if ((!searchString || searchString.length === 0) && session) {
      makeFollowersRequestInit();
      return;
    }

    if (searchString?.length > 0 && session) {
      makeFollowersRequest(1, searchString);
    }
  }, [searchString, session]);

  const makeFollowersRequestInit = () => {
    setShowLoading(true);

    const url = "follow_relationships/search?page=1&items=50";
    const data = {
      query: "",
      search: {
        following_type: "Company",
        user_id: session.id,
      },
    };

    httpPost({
      call: url,
      data,
    })
      .pipe()
      .subscribe((res) => {
        const pagemeta = pageMeta(res);

        setPageSetting((prev) => ({
          ...prev,
          total: pagemeta.total,
        }));

        const newResponse = res.response.map((i) => ({
          ...i,
          following: true,
          logo: i.following_logo || i.following?.logo,
          name: i.following_name || "-",
          following_id: i.id,
          company_id: i.following_id,
        }));

        setFollowers(newResponse);
        setShowLoading(false);
      });
  };

  const makeFollowersRequest = (page, query) => {
    setShowLoading(true);

    const url = `companies/search?page=${page || 1}?items=50&with_context=true`;
    const data = {
      query,
      search: {
        following_type: "Company",
        user_id: session.id,
      },
    };

    httpPost({
      call: url,
      data,
    })
      .pipe()
      .subscribe((res) => {
        const pagemeta = pageMeta(res);

        setPageSetting((prev) => ({
          ...prev,
          total: pagemeta.total,
        }));

        setFollowers(res.response);

        setShowLoading(false);
      });
  };

  const handleSearch = (value) => {
    setSearchString(value);

    if (!value || !value.length) {
      makeFollowersRequestInit();
      return;
    }

    makeFollowersRequest(pageSetting.current, value);
  };

  const handleFollow = (company, isFromFollowing) => {
    if (isFromFollowing) {
      const newFollowers = followers.map((f) => {
        if (f.company_id === company.company_id) {
          return {
            ...f,
            following: true,
          };
        }
        return f;
      });

      setFollowers(newFollowers);

      return;
    }

    setFollowers([{ ...company, following: true }, ...followers]);
  };

  const handleUnfollow = (company) => {
    const newFollowingList = followers.map((f) => {
      if (f.company_id === company.company_id) {
        return {
          ...f,
          following: false,
        };
      }

      return f;
    });

    setFollowers(newFollowingList);
  };

  const handlePagingChange = useCallback(
    (page) => {
      if (page === pageSetting.current) {
        return;
      }

      setPageSetting((prev) => ({
        ...prev,
        current: page,
      }));

      makeFollowersRequest(page, searchString);
    },
    [pageSetting, searchString],
  );

  return (
    <div className={styles.pageWrapper}>
      <div>
        <FollowingSearch onSearch={handleSearch} />
        <div className="mt-4 mb-4 pt-2 pb-2">
          {showLoading ? <Loading /> : null}
          {!showLoading && followers && followers.length ? (
            followers.map((f, i) => (
              <FollowingItem
                key={f.company_id}
                item={f}
                isLast={followers.length === i + 1}
                enqueueSnackbar={enqueueSnackbar}
                onUnfollow={handleUnfollow}
                onFollow={() => handleFollow(f, true)}
              />
            ))
          ) : showLoading ? null : (
            <div className="d-flex justify-content-center mt-5 mb-5 flex-grow-1">
              You are not following any companies yet
            </div>
          )}
          <div
            className={classNames(
              "justify-content-center mt-4",
              showLoading || !followers || followers?.length < 50
                ? "d-none"
                : "d-flex",
            )}
          >
            <div className={styles.paginCon}>
              <Pagination {...pageSetting} onChange={handlePagingChange} />
            </div>
          </div>
        </div>
        <Suggestions searchString={searchString} onFollow={handleFollow} />
      </div>
    </div>
  );
};

export default React.memo(withSnackbar(FollowingSettings));

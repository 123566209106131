import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { httpGet, httpPost } from "../../../common/httpCall";
import "./emailSelect.scss";
import { Label } from "..";

const EmailSelect = (props) => {
  const {
    title,
    setFilterContact,
    options,
    onChange,
    value,
    onDataChange,
    formProps = {},
  } = props;

  const {
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
  } = useSelector((state) => state);

  const { values, setFieldValue } = formProps;
  const [isActive, setIsActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [companyContacts, setCompanyContacts] = useState({});

  useEffect(() => {
    httpGet({
      call: "companies/contacts",
    })
      .pipe()
      .subscribe((res) => setCompanyContacts(res.response));
  }, []);

  const applyChange = (newItem) => {
    setIsActive(!isActive);
    onChange && onChange([newItem.id]);
    onDataChange && onDataChange([newItem]);
    setSelectedItem(newItem);
    handleSetContact(newItem.from);

    Object.keys(values).forEach((e) => {
      if (e === "email") {
        const item = newItem.from.emailAddress.address;
        if (item) {
          return setFieldValue(e, item);
        }
      }
      if (e === "subject") {
        const item = newItem.subject;
        if (item) {
          return setFieldValue(e, item);
        }
      }
      if (e === "date") {
        const item = newItem.sentDateTime.slice(0, 10);
        if (item) {
          return setFieldValue(e, item);
        }
      }
      if (e === "message" && newItem.bodyPreview) return setFieldValue(e, newItem.bodyPreview);
    });
  };

  const handleSetContact = (item) => {
    if (item) {
      const contact = companyContacts.filter(
        (contact) => contact.email === item.emailAddress.address,
      );

      if (contact.length > 0) {
        setFilterContact(contact);
      } else {
        const values = {
          first_name: item.emailAddress.name.split(" ").slice(0, -1).join(" "),
          last_name: item.emailAddress.name.split(" ").slice(-1).join(" "),
          email: item.emailAddress.address,
        };
        httpPost({
          call: "companies/contacts",
          data: { ...values, company_id: selectedCompany.id },
        })
          .pipe()
          .subscribe((res) => setFilterContact(
            [res.response],
            setCompanyContacts([...companyContacts, res.response]),
          ));
      }
    }
  };

  const removeValue = (removeItemId) => {
    onChange && onChange(value.filter((i) => i !== removeItemId));
    onDataChange && onDataChange([]);
    setFilterContact(null);
  };

  return (
    <div className="dropdown-container">
      <Label>{title}</Label>
      <div className="dropdown-input">
        <div className="dropdown-values">
          {value.length ? (
            <div key={selectedItem.id} className="dropdown-value">
              <h8>{selectedItem?.from.emailAddress.address}</h8>
              <h8>{selectedItem?.subject}</h8>
              <h8>
                {new Date(selectedItem?.sentDateTime).toLocaleDateString(
                  "en-GB",
                )}
              </h8>
              <span
                onClick={() => removeValue(selectedItem.id)}
                className="dropdown-remove"
              >
                -
              </span>
            </div>
          ) : (
            <div
              onClick={() => setIsActive(!isActive)}
              className="dropdown-placeholder"
            >
              {" "}
              Select an email{" "}
            </div>
          )}
        </div>
        <span onClick={() => setIsActive(!isActive)} className="arrow-down" />
      </div>
      <div
        className={classnames("dropdown-options", {
          "dropdown-active": isActive,
        })}
      >
        <div className="email-data">
          <h5>
            <b> Sender </b>
          </h5>
          <h5>
            <b> Subject Line </b>
          </h5>
          <h5>
            <b> Date </b>
          </h5>
        </div>
        {options.length > 0 ? (
          options?.map((item) => (
            <div
              onClick={() => {
                applyChange(item);
              }}
              key={item.id}
              className="dropdown-item"
            >
              <div className="email-data">
                <h7>{item?.from.emailAddress.address}</h7>
                <h8>{item?.subject}</h8>
                <h7>
                  {new Date(item?.sentDateTime).toLocaleDateString("en-GB")}
                </h7>
              </div>
            </div>
          ))
        ) : (
          <div className="no-option">
            <h5> No Data Found </h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailSelect;

import React, {
  memo, useCallback, useEffect, useState,
} from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import styles from "../../chartsView.module.scss";
import { queryParamStringToArray } from "../../../../../../../common/helper";
import { makeFlattenTags, makeFlattenCustomTags, flat } from "../utils";
import { setChartsAreReRendering } from "../../../../companyMain.action";
import { makeFlattenRelationShipTags } from "./barUtils";

const BarChart = ({
  companies,
  width,
  selectedOptions,
  handleTagsShow,
  chartHeight,
  askForRedraw,
  selectedTags,
  industries,
  topics,
  showTags,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const {
    appReducer: { chartsDownloadIsActive, relationShipStatuses },
    companiesReducer: {
      companyMainReducer: {
        charts: { chartsAreReRendering },
      },
    },
  } = useSelector((state) => state);

  const [tags, setTags] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [localWidth, setLocalWidth] = useState(null);

  const handleClickTagsShow = useCallback(() => {
    dispatch(
      setChartsAreReRendering({
        state: true,
      }),
    );

    const timeout = setTimeout(() => {
      handleTagsShow();
      clearTimeout(timeout);
    });
  }, [showTags]);

  useEffect(() => {
    if (localWidth !== width && askForRedraw) {
      setLocalWidth(width);
      askForRedraw(tags || []);
    }
  }, [width, localWidth, tags, companies, selectedOptions, askForRedraw]);

  useEffect(() => {
    if (!companies?.length || !selectedOptions || !relationShipStatuses.length) return;

    const { color, x } = selectedOptions;

    if (
      x === "yearFounded"
      || x === "numberOfEmployees"
      || x === "revenue_range"
      || color === "revenue_range"
    ) {
      const customPieTags = makeFlattenCustomTags(
        companies,
        selectedOptions,
        true,
        true,
        color === "revenue_range",
      );
      const flattenTags = selectedTags?.length
        ? customPieTags.map((tag) => {
          const tagExistInSelected = selectedTags.some(
            (t) => t.id === tag.id,
          );

          return {
            ...tag,
            selected: tagExistInSelected,
          };
        })
        : customPieTags;

      setTags(flattenTags);
      return;
    }

    if (color !== "relationShipStatus") {
      const flattIndustries = flat(industries, "children_industries");
      const flattTechnologies = flat(topics, "children_topics");
      const councilTags = [...flattIndustries, ...flattTechnologies];
      const topicIdsFromQuery = queryParamStringToArray(
        location.state?.topic || "",
      );
      const industryIdsFromQuery = queryParamStringToArray(
        location.state?.industry || "",
      );
      const combinedIds = [...topicIdsFromQuery, ...industryIdsFromQuery];

      if (combinedIds.length) {
        const flattenTags = makeFlattenTags(
          companies,
          selectedOptions,
          "bar",
          councilTags,
        )
          .filter((t) => combinedIds.includes(t.id))
          .map((t) => {
            if (!selectedTags) return t;

            const tagExistInSelected = selectedTags.some(
              (t1) => t1.id === t.id,
            );

            return {
              ...t,
              selected: tagExistInSelected,
            };
          });

        if (flattenTags.length) {
          setTags(flattenTags);
        } else {
          const flattenTagsWhenNoTagsToDisplay = makeFlattenTags(
            companies,
            selectedOptions,
            "bar",
            councilTags,
          ).map((t) => {
            if (!selectedTags) return t;

            const tagExistInSelected = selectedTags.some(
              (t1) => t1.id === t.id,
            );

            return {
              ...t,
              selected: tagExistInSelected,
            };
          });

          setTags(flattenTagsWhenNoTagsToDisplay);
        }

        return;
      }

      const flattenTags = makeFlattenTags(
        companies,
        selectedOptions,
        "bar",
        councilTags,
      ).map((t) => {
        if (!selectedTags) return t;

        const tagExistInSelected = selectedTags.some((t1) => t1.id === t.id);

        return {
          ...t,
          selected: tagExistInSelected,
        };
      });

      setTags(flattenTags);
    }

    if (color === "relationShipStatus") {
      const relationshipStagesFromQuery = queryParamStringToArray(
        location.state?.relationShipStatus || "",
      );

      if (relationshipStagesFromQuery.length) {
        const flattenTags = makeFlattenRelationShipTags(
          companies,
          relationShipStatuses,
        )
          .filter((t) => relationshipStagesFromQuery.includes(t.slug))
          .map((t) => {
            if (!selectedTags) return t;

            const tagExistInSelected = selectedTags.some(
              (t1) => t1.slug === t.slug,
            );

            return {
              ...t,
              selected: tagExistInSelected,
            };
          });

        if (flattenTags.length) {
          setTags(flattenTags);
        } else {
          const flattenTagsWhenNoTagsToDisplay = makeFlattenRelationShipTags(
            companies,
            relationShipStatuses,
          ).map((t) => {
            if (!selectedTags) return t;

            const tagExistInSelected = selectedTags.some(
              (t1) => t1.slug === t.slug,
            );

            return {
              ...t,
              selected: tagExistInSelected,
            };
          });

          setTags(flattenTagsWhenNoTagsToDisplay);
        }

        return;
      }

      const flattenTags = makeFlattenRelationShipTags(
        companies,
        relationShipStatuses,
      ).map((t) => {
        if (!selectedTags) return t;

        const tagExistInSelected = selectedTags.some(
          (t1) => t1.slug === t.slug,
        );

        return {
          ...t,
          selected: tagExistInSelected,
        };
      });

      setTags(flattenTags);
    }

    return () => null;
  }, [
    companies,
    selectedOptions,
    selectedTags,
    relationShipStatuses,
    location.state,
    industries,
    topics,
  ]);

  const handleTagClick = useCallback(
    (tag) => {
      if (chartsAreReRendering) {
        enqueueSnackbar(
          "Please, wait one moment. We are preparing new charts data...",
          {
            variant: "warning",
          },
        );

        return;
      }

      if (tag.unselectable) return;

      const newTags = tags.map((t) => ({
        ...t,
        selected: t.id === tag.id ? !t.selected : t.selected,
      }));
      setTags(newTags);
      askForRedraw(newTags);
    },
    [tags, selectedOptions, companies, chartsAreReRendering],
  );

  const resetSelectedTags = useCallback(() => {
    const newTags = tags.map((t) => ({
      ...t,
      selected: false,
    }));
    setTags(newTags);
    askForRedraw(newTags);
  }, [tags, selectedOptions, companies]);

  const atLeastATagIsSelected = tags ? tags.some((t) => t.selected) : false;

  return (
    <div id="downloadableSection" className="d-flex flex-column bg-white w-100">
      <div className="d-flex bg-white position-relative w-100 justify-content-between">
        {tags && !chartsDownloadIsActive && (
          <span
            onClick={handleClickTagsShow}
            className={styles.showOrHideTagsPie}
          >
            {showTags ? "Hide" : "Show"} Tags
          </span>
        )}
        <div className={styles.chartWrapper} style={{ width: `${width}px` }}>
          <canvas id="barChart" width={width} height={chartHeight} />
        </div>
        {tags && showTags && (
          <div className="d-flex flex-column">
            <div
              className={`tags-wrapper d-flex flex-column mt-4 ${styles.tagsWrapper}`}
              style={{ height: `${chartHeight - 60}px` }}
            >
              {!tags.length ? (
                <span className={styles.noTags}>
                  No tags to display for selected color
                </span>
              ) : null}
              {tags
                .map((tag) => (
                  <div
                    key={tag.id}
                    className={styles.tag}
                    style={{
                      backgroundColor: tag.selected
                        ? tag.color
                        : atLeastATagIsSelected
                          ? "#999999"
                          : tag.color,
                    }}
                    onClick={() => handleTagClick(tag)}
                    role="img text"
                    title={`There are ${tag.count} companies in current search with this tag.`}
                    aria-label={`There are ${tag.count} companies in current search with this tag.`}
                  >
                    {tag.name}
                  </div>
                ))
                .slice(0, showMore ? tags.length : 9)}
              {!chartsDownloadIsActive && !showMore && tags.length > 9 ? (
                <span
                  onClick={() => setShowMore(true)}
                  className={styles.showMoreTags}
                >
                  show more
                </span>
              ) : null}
              {!chartsDownloadIsActive
              && !showMore
              && tags.filter((t) => t.selected).length ? (
                <span
                  onClick={resetSelectedTags}
                  className={styles.showMoreTags}
                >
                  Reset selections
                </span>
                ) : null}
            </div>
            {!chartsDownloadIsActive && showMore && (
              <span
                onClick={() => setShowMore(false)}
                className={styles.showMoreTags}
              >
                show less
              </span>
            )}
            {!chartsDownloadIsActive
            && showMore
            && tags.filter((t) => t.selected).length ? (
              <span onClick={resetSelectedTags} className={styles.showMoreTags}>
                Reset selections
              </span>
              ) : null}
          </div>
        )}
      </div>
      {chartsDownloadIsActive && (
        <div
          style={{ width: `${width}px` }}
          className={styles.chartDownloadBadge}
        >
          <span className={styles.bolder}>Traction</span>
          <span className={styles.light}>Chart</span>
        </div>
      )}
    </div>
  );
};

export default memo(BarChart);

export const moreOptionTypes = {
  edit: 1,
  assign: 2,
  history: 3,
};

export const moreOptions = [
  {
    val: moreOptionTypes.edit,
    text: "Edit",
  },
  {
    val: moreOptionTypes.assign,
    text: "Assign",
  },
  {
    val: moreOptionTypes.history,
    text: "History",
  },
];

export const toolTips = {
  "Diversified Materials":
    "Materials customized to suit patient need (diversity/disabilities)",
  "Trial Finder": "Does PO have a clinical trial finder tool or link",
  "Significant Social Media Reach":
    "Large number of social media followers relative to other similar organizations",
  "Patient Education": "Educational programs for patients/caregivers",
  "Resource Links": "Provides resource links for patients",
  "Patient Stories": "Shared patient stories",
  "Policy Access Focus": "Engages in policy and access activities",
  "Local Support/Events": "Community Education and Support Events",
  "Patient Advocacy Boards": "Established patient ad boards",
  "Transparent List of Other Pharma Sponsors They Work With":
    "Visible on their website",
  "Helps Patients Obtain a Diagnosis":
    "Connecting patients with specialists/disease or /testing information",
  "Previously Worked with/Supported by GSK": "Yes, if known",
  "Inform of New Treatment Approval":
    "Communicates regulatory drug approval only (this yes, does not mean they provide education on treatment)",
  "Hosts National Events Spotlighting Disease to Raise Awareness":
    "Public events to raise awareness about disease (National awareness days)/ fund raising and empowering patients and caregivers",
  "Educate on Clinical Trials":
    "Provides information and education on what a clinical trial is and reasons for participation",
  "Supports Patient/Caregivers to Attend Professional Conferences":
    "Provides information and education on what a clinical trial is and reasons for participation",
  "Multi-Lingual Information":
    "Shows understanding of patient community languages needs e.g. US should routinely consider Spanish",
  "Host Patient Conferences/Summits":
    "Sponsors or collaborates with other patient organizations to deliver conferences (excludes paying for patients to attend professional society conference)",
  "Regional or Local Groups/Chapters": "",
  "Images on Website Reflect Patient Population":
    "looking for images that reflect age, sex & ethnicity of patients /epidiemology of disease",
  "Conducts Independent Research":
    "Conduct their own research but can be in partnership",
  "Provides Financial/Insurance Assistance to Patients":
    "Examples include; transportation costs to appointments, treatment costs",
  "Up-to-date Website": "",
  "Partners with Other Patient Organizations": "",
  "HCP Members on Board of Directors": "HCP=Health Care Providers of any type",
  "Physician/Specialist Locator":
    "Links patients to Healthcare providers or specialists",
  "Caregiver Resources and Support": "",
  "Resources for Patients When Talking with HCPs": "",
  "Patient Ambassadors": "Patient speakers coached by patient organizations",
  "Patient Symptom Tracker":
    "Can be digital, printed, smart phone applications",
  "Appears in Top 10 Internet Search Results": "Google or other search engine",
  "Celebrity Ambassadors":
    "Well known individual, using their status, to raise disease awareness",
  "Collaborates with Other Patient Orgs to Drive Health Policy":
    "Set up meetings with patients and political reps to discuss health policy",
  "Has a Public Position on  Healthcare Equity": "",
  "Patient Hotlines": "",
  "Patient Org Works with Industry":
    "answer yes if you see Industry Sponsors on their website, similar to attribute 10",
};

import * as Yup from "yup";
import { objectType } from "../../../../modules/companies/company/details/details.constant";

export const validation = Yup.object().shape({
  // field_value: Yup.string().required('Number is required'),
});

// export const agreementValidation = Yup.object().shape({
//   agreement_type_id: Yup.string().required("Document is required"),
// });

export const model = {
  type: objectType.agreement,
  title: "",
  field_value: "",
  option_selected: [],
  document_date: "",
  expiration_date: "",
  agreement_type_id: null,
  agreement_type: null,
};

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useSelector } from "react-redux";

import styles from "./agreements.module.scss";
import { Icon, smSize } from "../../../../../../common/icon";
import {
  queryParamStringToArray,
  queryStringUrlReplacement,
  useQuery,
} from "../../../../../../common/helper";
import { CheckBox, CheckType } from "../../../../../primitives";

const IDBEcosystemFilter = ({ expanded, handleExpand, disabled }) => {
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const history = useHistory();
  const idbEcosystem = useQuery().get("idbEcosystem");

  const [collapsed, setCollapsed] = useState(false);
  const [idbEcosystemValue, setIdbEcosystemValue] = useState(null);

  useEffect(() => {
    if (disabled) {
      setCollapsed(false);
      return
    }

    setCollapsed(expanded);
  }, [expanded, disabled]);

  useEffect(() => {
    if (!selectedCouncil) {
      return;
    }

    let newIdbEcosystem = [];

    if (selectedCouncil.idb_connection?.option_values) {
      newIdbEcosystem = selectedCouncil.idb_connection?.option_values.map(
        (a) => ({
          name: a,
          id: a,
          selected: false,
        }),
      );

      setIdbEcosystemValue(newIdbEcosystem);
    } else {
      newIdbEcosystem = [];
      setIdbEcosystemValue([]);
    }

    if (idbEcosystem) {
      const arr = queryParamStringToArray(idbEcosystem);
      const newItems = newIdbEcosystem.map((item) => {
        const itemIsSelected = arr.includes(item.id);

        return {
          ...item,
          selected: itemIsSelected,
        };
      });

      setIdbEcosystemValue(newItems);
    }

    return () => null;
  }, [selectedCouncil, idbEcosystem]);

  const handleCollapseClick = () => {
    if (disabled) {
      return;
    }

    if (!collapsed) {
      handleExpand("agreements");
    }
    setCollapsed(!collapsed);
  };

  const handleOnChange = (val, agr) => {
    const value = idbEcosystemValue
      .map((a) => {
        if (a.id === agr.id) {
          return {
            ...a,
            selected: val,
          };
        }
        return a;
      })
      .filter((item) => item.selected)
      .reduce((acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`, "");

    let search = queryStringUrlReplacement(
      history.location.search,
      "idbEcosystem",
      value,
    );
    search = queryStringUrlReplacement(search, "page", 1);

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        idbEcosystem: value,
        page: 1,
      },
    });

    const newItems = idbEcosystemValue.map((a) => {
      if (a.id === agr.id) {
        return {
          ...a,
          selected: val,
        };
      }

      return a;
    });

    setIdbEcosystemValue(newItems);
  };

  return (
    <div className={`${styles.itemWrapper} ${disabled ? styles.disabled : ''}`} id="idbEcosystem">
      <div className={styles.itemTitle} onClick={handleCollapseClick}>
        <span>IDBG Connection</span>
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(styles.icon, !collapsed && styles.collapse)}
          onClick={handleCollapseClick}
        />
      </div>
      {collapsed ? (
        <div
          className={classNames(
            "d-flex align-items-center",
            styles.withPaddingLeft,
          )}
        >
          <div
            className={classNames("d-flex flex-column", styles.agreementsField)}
          >
            {idbEcosystemValue?.length
              ? idbEcosystemValue.map((a) => (
                <div key={a.id} className="d-flex align-items-center">
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={a.selected}
                    onChange={(val) => handleOnChange(val, a)}
                  />
                  <span
                    className={styles.checkboxName}
                    onClick={() => handleOnChange(!a.selected, a)}
                  >
                    {a.name}
                  </span>
                </div>
              ))
              : "No filters."}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(IDBEcosystemFilter);

import React from "react";

import DropDownNestedElements from "common/components/dropdownNestedElements";
import styles from "../styles.module.scss";

const TechnologiesSelector = ({
  topics,
  selectedTopics,
  handleTagSelect,
  handleTagRemove,
  getClearLabel,
  removeCategory,
}) => {
  return (
    <div style={{ position: "relative" }}>
      <DropDownNestedElements
        tags={topics}
        selectedTags={selectedTopics}
        childKey="children_topics"
        tagKeyName="id"
        parentKeyName="parent_resource_topic_id"
        handleTagSelect={handleTagSelect}
        label="Select..."
        handleTagRemove={handleTagRemove}
        selectedIsObject
        style={{ backgroundColor: "#fff" }}
        // disabled={showLoading}
      />
      <div
        className={styles.clearDropdownButton}
        onClick={(e) => {
          e.stopPropagation();
          // methods.clearAll();
          removeCategory();
        }}
      >
        {getClearLabel()}
      </div>
    </div>
  );
};

export default TechnologiesSelector;

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useSnackbar } from "notistack";

import { ModalFooter, ModalBody } from "../../../../../application/modal";
import { Button, BtnType, Label, DropSelect } from "../../../../primitives";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../common/hooks";
import { themesGetAttempt } from "modules/themes/themesMainPage/themesMain.action";
import { addProductToTheme } from "modules/companies/product/store/product.actions";
import LoadingComponent from "modules/primitives/loading/loading.component";

const AddProductToTheme = ({ product }) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();

  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [options, setOptions] = useState([]);
  const [themesData, setThemesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        themesGetAttempt({
          enqueueSnackbar,
          isDropdown: true,
          items: 9999,
          cb: (data) => {
            setThemesData(data);
          },
        })
      );
    }
  }, [selectedCouncil]);

  const handleCancelClick = (e) => {
    popup.hide();
  };

  const handleSaveClick = (e) => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleOnSubmit = () => {
    if (!selected) {
      enqueueSnackbar("Please select a theme", {
        variant: "error",
      });

      return;
    }

    setIsSubmitting(true);

    const payload = {
      data: {
        theme_id: selected.val,
        council_product_id: product.id,
      },
      enqueueSnackbar,
      cb: () => {
        setIsSubmitting(false);
        popup.hide();
        enqueueSnackbar("Successfully added product to the theme.", {
          variant: "success",
        });
      },
    };

    dispatch(addProductToTheme(payload));
  };

  useEffect(() => {
    if (themesData?.length > 0) {
      if (!product?.themes?.length) {
        setOptions(themesData);
        setLoading(false);
        return;
      }

      const filteredThemes = themesData?.filter((option) => {
        const isAlreadySelected = product.themes?.find(
          (item) => item?.id === option.id
        );
        return !isAlreadySelected && option.council_role !== "viewer";
      });

      setOptions(filteredThemes);
      setLoading(false);
    }
  }, [themesData, product?.themes]);

  return (
    <>
      <ModalBody>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{ id: "" }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleOnSubmit(values);
          }}
        >
          {({ handleSubmit, setFieldValue, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              {loading ? (
                <LoadingComponent
                  customText={`Getting ${
                    selectedCouncil?.settings?.themes_display_name || "Themes"
                  }...`}
                />
              ) : (
                <div className={styles.editStageFormWrp}>
                  <Label>
                    {selectedCouncil?.settings?.themes_display_name_singular ||
                      "Theme"}
                  </Label>
                  <div className={styles.selectorWrp}>
                    <DropSelect
                      onChange={setSelected}
                      placeholder={`Choose ${
                        selectedCouncil?.settings
                          ?.themes_display_name_singular || "Theme"
                      }`}
                      labelField="name"
                      valueField="id"
                      searchBy="name"
                      options={options}
                    />
                  </div>
                </div>
              )}
            </form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        {isSubmitting ? (
          <LoadingComponent customText="Precessing..." />
        ) : (
          <>
            <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button btn={BtnType.REGULAR} onClick={handleSaveClick}>
              Add to {selectedCouncil?.settings?.themes_display_name || "theme"}
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default AddProductToTheme;

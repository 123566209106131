import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, BtnType, Label, DropSelect } from "modules/primitives/index";
import { ModalFooter, ModalBody } from "../../../../../application/modal";
import { projectListGet } from "modules/projects/project/project.action";
import styles from "./addProductToProject.module.scss";
import useModelPopup from "common/hooks/modelPopup.hook";
import { addProductToProject } from "modules/companies/product/store/product.actions";
import LoadingComponent from "modules/primitives/loading/loading.component";

const AddProductToProjectComponent = ({
  enqueueSnackbar,
  product,
}) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [projectList, setProjectList] = useState([]);

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil) {
      setLoading(true);
      dispatch(
        projectListGet({
          councilId: selectedCouncil.id,
          pageSize: 9999,
          currentPage: 1,
          userId: session.id,
          enqueueSnackbar,
          isDropdown: true,
          cb: (data) => {
            setProjectList(data);
            setLoading(false);
          }
        })
      );
    }
  }, [selectedCouncil]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = () => {
    if (!selected) {
      enqueueSnackbar("Please select a project", {
        variant: "error",
      });

      return;
    }

    const payload = {
      data: {
        project_id: selected.val,
        council_product_id: product.id
      } ,
      enqueueSnackbar,
      cb: () => {
        popup.hide();
        enqueueSnackbar("Successfully added product to the project.", {
          variant: "success",
        });
      }
    };

    dispatch(addProductToProject(payload));
  };

  return (
    <div className={styles.addWrp}>
      <ModalBody>
        {
          loading ? (
            <LoadingComponent customText="Getting Projects..." />
          ) : (
            <>
              <Label>Projects</Label>
              <DropSelect
                onChange={setSelected}
                placeholder="Add to project"
                labelField="name"
                valueField="id"
                searchBy="name"
                options={projectList?.filter(p => !product.projects?.map(p1 => p1.id).includes(p.id)) || []}
              />
            </>
          )
        }
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          type="submit"
          btn="BtnType.REGULAR"
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default React.memo(AddProductToProjectComponent);

export const getNormalizedProjectReplicationsData = (replicationData) => {
  if (!replicationData) return [];

  let count = replicationData.original_project ? 1 : 0;

  return replicationData?.project_replications
    .filter((obj) => !obj.discarded_at)
    .reduce((acc, elem) => {
      if (elem.status) {
        count = count + 1;
        return [...acc, { ...elem, count }];
      }

      const newPlantObj = {
        ...elem,
        areas: elem.areas.map((area) => {
          count = count + 1;
          return {
            ...area,
            count
          }
        })
      };

      return [...acc, newPlantObj];
    }, []);
};

export const getNormalizedReplicationsData = (replicationData) => {
  if (!replicationData) return [];

  const currUserPlants = replicationData.possible_replication
    .filter(p => p.current_user_plant)
    .map(p => p.id);

  if (currUserPlants?.length) {
    const samePlantsAsCurrentPlant = replicationData.possible_replication
      .filter(p => !p.current_user_plant && currUserPlants.includes(p.id))
      .map(p => p.id);
    return replicationData.possible_replication
      .filter(p => p.current_user_plant ? true : !samePlantsAsCurrentPlant.includes(p.id))
      .filter((obj) => !obj.discarded_at && obj.created_at);
  }

  return replicationData.possible_replication.filter((obj) => !obj.discarded_at && obj.created_at);
};

export const getSpocPlants = (possibleReplications, session) => {
  const sessionPlants = session.plants
    .map(p => {
      const areas = session.areas
        .filter(a => a.plant_id === p.id)
        .map(a => ({ ...a, area_id: a.id || a.area_id, state: a.state }));

      return {
        ...p,
        areas,
        state: p.state || p.status,
      }
    });

  if (!possibleReplications.length) {
    return sessionPlants;
  }

  const combined = [...sessionPlants, ...possibleReplications].map(p => p.id);
  const uniqIds = [...new Set(combined)];

  return uniqIds.map(id => {
    const getFromSession = sessionPlants.find(p => p.id === id);
    const getFromReplications = possibleReplications.find(p => p.id === id);

    // not user plants
    if (!getFromSession && getFromReplications) {
      return getFromReplications;
    }

    // just user plants below...
    // ------->
    // we do that shit, because in session details we don't know
    // what was the status, project, reason set for these plants
    // basically we need to combine 2 source of data in 1... :(
    if (getFromSession && getFromReplications) {
      const allAreas = [...getFromSession.areas, ...getFromReplications.areas].map(a => a.area_id);
      const areas = [...new Set(allAreas)].map(id => {
        const getAreaFromReplications = getFromReplications.areas.find(a => a.area_id === id);
        const getAreaFromSession = getFromSession.areas.find(a => a.area_id === id);

        // it means areas was deleted from replications, but we still need to show it
        // in the modal without delete icon
        if (getAreaFromReplications && getAreaFromSession) {
          return {
            ...getAreaFromReplications,
            state: getAreaFromReplications.status || getAreaFromReplications.state,
            _destroy: false,
          }
        }

        if (getAreaFromReplications) {
          return {
            ...getAreaFromReplications,
            state: getAreaFromReplications.status || getAreaFromReplications.state,
          }
        }

        return {
          ...getAreaFromSession,
          state: getAreaFromSession.status || getAreaFromSession.state,
        }
      }).filter(area => area.state !== 'under_review' && area.state !== 'replicated' && area.state !== 'original');

      return {
        ...getFromReplications,
        state: getFromReplications.status || getFromReplications.state,
        areas,
        _destroy: false,
        current_user_plant: true,
      }
    }

    return getFromReplications || getFromSession;
  }).map(p => ({
    ...p,
    state: p.state || p.status,
  })).filter(p => p.areas?.length > 0)
}

export const makeNormalizesPlantsWhenAdmin = (replicationData) => {
  return replicationData?.possible_replication.map(obj => {
    const areas = obj.areas?.map(a => ({...a, area_id: a.id || a.area_id})) || []
    return {
      ...obj,
      ...obj.plant,
      areas,
      originalAreas: areas
    }
  });
}

export const makeNormalizedFilteredPlants = (normalizedSPOCPlants, projectPlant, replicationData) => {
  // replicated, under_review, original -> filter by these statuses
  const makeProjectPlantObj = projectPlant ? {
    ...projectPlant,
    areas: projectPlant.areas.map(a => ({
      ...a,
      state: projectPlant.status
    })),
    state: projectPlant.status
  } : {};
  const makeOriginalPlant = replicationData?.original_project?.plant ? {
    ...replicationData.original_project.plant,
    areas: replicationData.original_project.plant.area ? [{
      ...replicationData.original_project.plant.area,
      state: replicationData.original_project.status,
      area_id: replicationData.original_project.plant.area.id
    }] : [],
    state: replicationData.original_project.status
  } : {};
  const allPlants = [
    makeProjectPlantObj,
    makeOriginalPlant,
    ...replicationData.possible_replication,
    ...replicationData.project_replications,
  ];

  const flattenAreas = allPlants.reduce((acc, p) => [...acc, ...p.areas || []], []).map(a => ({
    ...a,
    state: a.state || a.status,
    area_id: a.area_id || a.id,
  }));

  return normalizedSPOCPlants.map(p => {
    const filteredAreas = p.areas.filter(a => {
      const getArea = flattenAreas.find(area => a.area_id === area.area_id);

      if (!getArea) return true;

      return getArea?.state !== 'under_review' && getArea?.state !== 'replicated' && getArea?.state !== 'original';
    });

    return {
      ...p,
      areas: filteredAreas,
    }
  }).filter(p => {
    if (!p.areas?.length) {
      const getPlant = allPlants.find(plant => plant.id === p.id);

      if (!getPlant) return true;

      return getPlant?.state !== 'under_review' && getPlant?.state !== 'replicated' && getPlant?.state !== 'original';
    }

    return !p.state?.length;
  });
}

import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import styles from "modules/reporting/customReportsStyles.module.scss";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "modules/primitives";
import Dialog from "@mui/material/Dialog";

const ThemesList = ({ data, config }) => {
  const [open, setOpen] = useState(false);
  const hasShowMore = data[config.propToUse]?.length > 5;

  const handleShowMore = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  if (!data[config.propToUse]?.length) {
    return '-';
  }

  return (
    <div>
      <span>Total: {data[config.propToUse]?.length};</span>
      <div className="d-flex flex-column">
        {
          data[config.propToUse].slice(0, hasShowMore ? 5 : data[config.propToUse]?.length).map(theme => (
            <a
              href={`themes/individual_page/${theme.id}/overview`}
              className="cursor-pointer"
              key={theme.id}
              target="_blank"
            >
              {theme.name || '-'}
            </a>
          ))
        }
        {
          hasShowMore ? (
            <span
              onClick={handleShowMore}
              className={`${styles.showMoreData} d-block mt-3`}
            >+ {data[config.propToUse].length - 5}</span>
          ) : null
        }
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <span>Total: {data[config.propToUse]?.length};</span>
          <div className="d-flex flex-column">
            {
              data[config.propToUse].map(theme => (
                <a
                  href={`themes/individual_page/${theme.id}/overview`}
                  className="cursor-pointer"
                  key={theme.id}
                  target="_blank"
                >
                  {theme.name || '-'}
                </a>
              ))
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(ThemesList);

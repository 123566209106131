import React, { useState } from "react";

import styles from "./styles.module.scss";
import LeftWindowComponent from "modules/patientOrganizations/patientOrganizationMain/leftWindow";
import { BtnType, Button } from "modules/primitives";

const PatientOrgMobileFilter = (
  {
    handleCheck,
    checkedDiseasesFilters,
    checkedCountryFilters,
    handleReset,
    checkedDiversityGroups,
  },
) => {
  const [showFilters, setShowFilters] = useState(false);

  const handleShowFilters = (show) => {
    setShowFilters(show);
  };

  const handleResetClick = () => {
    handleReset();
    handleShowFilters(false);
  }

  return (
    <div className={styles.wrapper}>
      <div onClick={() => handleShowFilters(true)} className={styles.filterOpenerWrapper}>
        <div className={styles.translated}>
          <svg
            fill="#000000" width="25px" height="25px" viewBox="0 0 32 32" version="1.1"
          >
            <g transform="matrix(1,0,0,1,-240,-96)">
              <g transform="matrix(1.33333,0,0,1.33333,-28.3333,-35.3333)">
                <circle cx="217" cy="106" r="3" />
              </g>
              <g transform="matrix(-1.33333,0,0,1.33333,540.333,-23.3333)">
                <circle cx="217" cy="106" r="3" />
              </g>
              <path
                d="M256.1,105L244,105C243.448,105 243,105.448 243,106C243,106.552 243.448,107 244,107L256.1,107C256.564,109.281 258.583,111 261,111C263.417,111 265.436,109.281 265.9,107L268,107C268.552,107 269,106.552 269,106C269,105.448 268.552,105 268,105L265.9,105C265.436,102.719 263.417,101 261,101C258.583,101 256.564,102.719 256.1,105ZM261,103C262.656,103 264,104.344 264,106C264,107.656 262.656,109 261,109C259.344,109 258,107.656 258,106C258,104.344 259.344,103 261,103Z"
              />
              <path
                d="M246.1,117L244,117C243.448,117 243,117.448 243,118C243,118.552 243.448,119 244,119L246.1,119C246.564,121.281 248.583,123 251,123C253.417,123 255.436,121.281 255.9,119L268,119C268.552,119 269,118.552 269,118C269,117.448 268.552,117 268,117L255.9,117C255.436,114.719 253.417,113 251,113C248.583,113 246.564,114.719 246.1,117ZM251,115C252.656,115 254,116.344 254,118C254,119.656 252.656,121 251,121C249.344,121 248,119.656 248,118C248,116.344 249.344,115 251,115Z"
              />
            </g>
          </svg>
          <span className="d-block ml-3">FILTERS</span>
        </div>
      </div>
      {
        showFilters && (
          <div className={styles.filtersWrapper}>
            <LeftWindowComponent
              mobileInstance
              handleCheck={handleCheck}
              checkedDiseasesFilters={checkedDiseasesFilters}
              checkedCountryFilters={checkedCountryFilters}
              checkedDiversityGroups={checkedDiversityGroups}
              handleReset={handleReset}
            />
            <div onClick={() => handleShowFilters(false)} className={styles.closeBtn}>
              <svg width="20px" height="20px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 21.32L21 3.32001" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3 3.32001L21 21.32" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            {
              (checkedDiseasesFilters?.length || checkedCountryFilters?.length || checkedDiversityGroups?.length) ? (
                <div className="d-flex align-items-center justify-content-center bg-white pt-3">
                  <Button
                    btn={BtnType.REGULAR}
                    onClick={() => handleShowFilters(false)}
                  >
                    Apply Filters
                  </Button>
                  <Button
                    className="ml-3"
                    btn={BtnType.OUTLINE}
                    onClick={handleResetClick}
                  >
                    Reset Filters
                  </Button>
                </div>
              ) : null
            }
          </div>
        )
      }
    </div>
  );
};

export default React.memo(PatientOrgMobileFilter);

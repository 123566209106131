import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loading, Button, BtnType } from "modules/primitives/index";
import { Link } from "react-router-dom";
import { Icon, smSize } from "common/icon";
import { useDocumentName } from "common/hooks";
import { ModalFooter, ModalBody } from "../../../../../application/modal";
import styles from "./companyRow.module.scss";
import { shouldDownload } from "../../../../../common/helper";
import { getCompanyDocumentsAttempt } from "../companies.action";

const DocumentsModal = (props) => {
  const {
    popup, companyId, projectId, organizationId, enqueueSnackbar,
  } = props;

  const {
    projectsReducer: {
      companyReducer: { selectedCompanyDocuments, loadingDocuments },
    },
  } = useSelector((state) => state);
  const documentName = useDocumentName();
  const dispatch = useDispatch();

  const getFilename = (url) => {
    if (url) {
      return url?.split("/").pop();
    }
    return "";
  };

  useEffect(() => {
    dispatch(
      getCompanyDocumentsAttempt({
        projectId,
        objId: companyId || organizationId,
        enqueueSnackbar,
      }),
    );
  }, []);

  const checkDocumentNameLength = (name) => {
    let truncateName = name;
    if (name.length > 20) {
      truncateName = name.substring(0, 20) + "...";
    }
    return truncateName;
  };

  return (
    <>
      <ModalBody>
        {loadingDocuments ? (
          <Loading />
        ) : (
          <div className={styles.documentListWrp}>
            {selectedCompanyDocuments.map((document, index) => (
              <div key={index} className={styles.documnetContainer}>
                <Icon
                  {...smSize}
                  icon="icn-subnav-documents"
                  className={styles.tileIcon}
                />
                <div>
                  {checkDocumentNameLength(
                    documentName.getName(getFilename(document?.uri)),
                  )}
                </div>
                <div className={styles.rightWrp}>
                  <Link to={document?.uri} target="_blank" download>
                    <Icon
                      {...smSize}
                      icon="icn-export-button"
                      className={styles.download}
                    />
                  </Link>

                  {document?.uri?.includes(".pdf") ? (
                    <a
                      className={styles.docView}
                      target="blank"
                      href={document.uri}
                    >
                      {shouldDownload(getFilename(document?.uri))
                        ? "download"
                        : "view"}
                    </a>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={() => popup.hide()}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
};

export default DocumentsModal;

import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { debounce } from "throttle-debounce";

import styles from "./styles.module.scss";
import { CheckBox, CheckType, CompanyAvatar, Label, Loading, Pagination } from "modules/primitives";
import { httpPost, pageMeta } from "../../../httpCall";
import { PAGINATION_DEFAULTS } from "./constant";
import MatchingTagsComponent from "./matching-tags";

const ProjectOrganizations = ({ isAdminWizardInstance, formikprops }) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [select, setSelect] = useState([]);
  const [localProject, setLocalProject] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [pageSetting, setPageSetting] = useState(PAGINATION_DEFAULTS);
  const [items, setItems] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAllByPage, setSelectedAllByPage] = useState({ 1: false });

  useEffect(() => {
    if (isAdminWizardInstance) return;
    const obj = JSON.parse(localStorage.getItem("new_project"));

    if (obj) {
      setLocalProject(obj);
    }

    return () => null;
  }, []);

  useEffect(() => {
    if (isAdminWizardInstance) return;
    if (selectedCouncil && localProject) {
      setSelect(localProject?.organizations || []);
    }
  }, [selectedCouncil, localProject]);

  useEffect(() => {
    if (isAdminWizardInstance) return;
    if (!items && selectedCouncil && localProject) {
      requestTheData(1);
    }

    return () => null;
  }, [items, selectedCouncil, localProject]);

  const requestTheData = (page, query = "*") => {
    setShowLoading(true);

    const path = "companies/search";
    const queryParams = `?page=${page || 1}&items=20&with_context=true`;
    const data = {
      query,
      gsk: true,
      patient_org: true,
      search: {
        query,
        disease_ids: localProject?.diseases || [],
        countries: localProject?.countries || [],
      },
    };

    httpPost({
      call: `${path}${queryParams}`,
      data,
    })
      .pipe()
      .subscribe((res) => {
        const pagemeta = pageMeta(res);
        const selectedAllByPageObj = [...Array(pagemeta.pages).keys()].reduce(
          (acc, idx) => {
            if (acc[idx]) {
              return acc;
            }

            acc[idx] = false;

            return acc;
          },
          selectedAllByPage
        );

        setSelectedAllByPage(selectedAllByPageObj);

        setPageSetting((prev) => ({
          ...prev,
          total: pagemeta.total,
          current: pagemeta.page,
        }));

        const obj = JSON.parse(localStorage.getItem("new_project"));
        const existingItemsInStorage = obj.organizations || [];
        const responseBasedOnStorage = res.response.map((item) => {
          const checkItemExistInStorage = existingItemsInStorage.some((i) => i.id === item.id);

          if (checkItemExistInStorage) {
            return {
              ...item,
              selected: true,
            };
          }

          return item;
        });
        const allItemsAreSelected =
          responseBasedOnStorage.filter((i) => i.selected).length === 20;

        setSelectedAllByPage({
          ...selectedAllByPage,
          [pagemeta.page]: allItemsAreSelected,
        });

        setItems(responseBasedOnStorage);
        setSelectedItems(existingItemsInStorage);
        setShowLoading(false);
      });
  };

  const handlePagingChange = (page) => {
    if (page !== pageSetting.current) {
      requestTheData(page, searchTerm);
    }
  };

  const onCheckChange = useCallback(
    (val, item, isFromSelectedBox) => {
      let dataForStorage;
      const newItems = val
        ? [...selectedItems, item]
        : selectedItems.filter((i) => i.id !== item.id);

      setSelectedItems(newItems);

      const newItemsOnAction = items.map((i) => {
        if (i.id === item.id) {
          return {
            ...i,
            selected: isFromSelectedBox ? false : val,
          };
        }

        return i;
      });

      setItems(newItemsOnAction);

      if (val) {
        if (!select?.find((obj) => obj.id === item.id) && select) {
          dataForStorage = [
            ...select,
            {
              gsk_patient_organization_id: item.id,
              added_by_id: session.id,
              logo: item.logo,
              name: item.name,
              diseases_names: item.diseases_names || [],
              topics_names: item.topics_names || [],
              industries_names: item.industries_names || [],
              therapeutic_areas_names: item.therapeutic_areas_names || [],
              id: item.id,
            },
          ];
          setSelect(dataForStorage);
        }
      } else {
        dataForStorage = select?.filter(
          (obj) => obj.gsk_patient_organization_id !== item.id
        );
        setSelect(dataForStorage || []);
      }

      setLastDataToStorage(dataForStorage || []);
    },
    [items]
  );

  const setLastDataToStorage = (newData) => {
    if (localProject) {
      let obj = localProject;
      obj = { ...obj, organizations: newData };

      const areAllSelected =
        pageSetting.current === 1
          ? obj.organizations.length === items.length
          : obj.organizations.length / pageSetting.current === items.length;
      setSelectedAllByPage({
        ...selectedAllByPage,
        [pageSetting.current]: areAllSelected,
      });

      localStorage.setItem("new_project", JSON.stringify(obj));
    }
  };

  const handleChangeSearch = useCallback(
    (e) => {
      const query = e.target.value;
      setSearchTerm(query);
      debounceChange(query);
    },
    [pageSetting.current]
  );

  const debounceChange = debounce(500, false, (query) => {
    requestTheData(1, query);
  });

  const selectAll = (val) => {
    if (val) {
      const itemsForStorage = items?.map((i) => ({
        gsk_patient_organization_id: i.id,
        added_by_id: session.id,
        logo: i.logo,
        name: i.name,
        diseases_names: i.diseases_names || [],
        topics_names: i.topics_names || [],
        industries_names: i.industries_names || [],
        therapeutic_areas_names: i.therapeutic_areas_names || [],
        id: i.id,
      })) || [];
      const currentItems = items?.map((i) => ({
        ...i,
        selected: true,
      })) || [];
      const all = [
        ...currentItems.filter(
          (i) => !selectedItems.some((item) => item.id === i.id)
        ),
        ...selectedItems,
      ];
      const finalDataForStorage = [
        ...itemsForStorage?.filter(
          (i) =>
            !select?.some(
              (item) =>
                item.gsk_patient_organization_id ===
                i.gsk_patient_organization_id
            )
        ),
        ...(select || {}),
      ];

      setItems(currentItems);
      setSelect(finalDataForStorage);
      setSelectedItems(all);
      setLastDataToStorage(finalDataForStorage);
    } else {
      const currentItems = items.map((i) => ({
        ...i,
        selected: false,
      }));
      const itemsForSelectedBox = selectedItems.filter(
        (i) => !currentItems.some((item) => item.id === i.id)
      );
      const itemsForStorage = select?.filter(
        (i) =>
          !currentItems.some(
            (item) => item.gsk_patient_organization_id === i.id
          )
      ) || [];

      setItems(currentItems);
      setSelect(itemsForStorage);
      setSelectedItems(itemsForSelectedBox);
      setLastDataToStorage(itemsForStorage);
    }
  };

  const handleCheckBoxClick = () => {};

  if (isAdminWizardInstance) {
    return (
      <div className="mb-2 d-flex flex-column align-items-baseline">
        <Label>Project Organizations</Label>
        <p>Organizations list component</p>
      </div>
    )
  }

  return (
    <div className={styles.inner}>
      <h4 className={classNames("bold", styles.innerTitle)}>
        Add Organizations
      </h4>
      <div className={classNames(styles.infoText)}>
        Manually add organizations by searching the database
      </div>
      <div className={classNames(styles.searchWrp, styles.textBox)}>
        <input
          type="text"
          placeholder="Search Organizations"
          className={styles.searchText}
          value={searchTerm}
          onChange={handleChangeSearch}
        />
      </div>
      {selectedItems.length > 0 ? (
        <h4 className={classNames("bold mt-4", styles.smallTitle)}>
          Selected Patient Organizations{" "}
          ({selectedItems.length})
        </h4>
      ) : null}
      {selectedItems.length > 0 ? (
        <div className={styles.listWrp}>
          {selectedItems.map((company) => (
            <div key={company.id} className={styles.formElement}>
              <div className={styles.companyWrp}>
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked
                  onChange={() => onCheckChange(false, company, true)}
                />
                <div className={styles.companyIcon}>
                  <div className={styles.iconCon}>
                    {company.logo && (
                      <CompanyAvatar
                        className={styles.logo}
                        imgSrc={company.logo}
                        name={company.name}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.infoWrp}>
                  <Label>{company.name}</Label>
                </div>
              </div>
              <div className={styles.tagWrp}>
                <MatchingTagsComponent item={company} />
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <h4 className={classNames("bold", styles.smallTitle)}>
        Organizations (Add up to 20 organizations)
      </h4>
      <div className={classNames(styles.infoText)}>
        It’s easy to add and remove organizations after you’ve created the project
      </div>
      <div className={styles.listWrp}>
        {!showLoading && items?.length ? (
          <div className="d-flex align-items-center mt-3 mb-4">
            <CheckBox
              checkType={CheckType.BLUE}
              isChecked={selectedAllByPage[pageSetting.current]}
              onChange={(val) => selectAll(val)}
            />
            <Label>
              <div className={classNames(styles.tagStyle)}>
                {pageSetting.total < 20
                  ? selectedAllByPage[pageSetting.current]
                    ? "Deselect Checked Items Below"
                    : "Select All"
                  : null}
                {pageSetting.total > 20
                  ? selectedAllByPage[pageSetting.current]
                    ? "Deselect Checked Items Below"
                    : "Select All 20 Results"
                  : null}
              </div>
            </Label>
          </div>
        ) : null}
        {showLoading ? <Loading /> : null}
        {!showLoading && items?.length
          ? items.map((company) => (
            <div key={company.id} className={styles.formElement}>
              <div className={styles.companyWrp}>
                <CheckBox
                  checkType={CheckType.BLUE}
                  isChecked={company.selected}
                  onChange={(val) => onCheckChange(val, company)}
                  onClick={() => handleCheckBoxClick()}
                />
                <div className={styles.companyIcon}>
                  <div className={styles.iconCon}>
                    {company.logo && (
                      <CompanyAvatar
                        className={styles.logo}
                        imgSrc={company.logo}
                        name={company.name}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.infoWrp}>
                  <Label>{company.name}</Label>
                </div>
              </div>
              <div className={styles.tagWrp}>
                <MatchingTagsComponent item={company} />
              </div>
            </div>
          ))
          : null}
        {!showLoading && items && items.length === 0 ? (
          <h3 className="text-center py-4">
            Sorry we didn't find any results for you.
          </h3>
        ) : null}
      </div>
      <div className="w-100 d-flex justify-content-center mb-4">
        <Pagination {...pageSetting} onChange={handlePagingChange} />
      </div>
    </div>
  );
};

export default React.memo(ProjectOrganizations);

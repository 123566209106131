export const moreOptionTypes = {
  share: 1,
  discuss: 2,
  addToList: 3,
  downloadReport: 4,
  edit: 5,
  delete: 6,
};

export const moreOptions = [
  // {
  //   value: moreOptionTypes.share,
  //   text: 'Share',
  // },
  // {
  //   value: moreOptionTypes.discuss,
  //   text: 'Discuss',
  // },
  // {
  //   value: moreOptionTypes.addToList,
  //   text: 'Add to list',
  // },
  // {
  //   value: moreOptionTypes.downloadReport,
  //   text: 'Download Report',
  // },
  {
    val: moreOptionTypes.edit,
    text: "Edit",
  },
  {
    val: moreOptionTypes.delete,
    text: "Delete",
  },
];

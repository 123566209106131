import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { checkViewerRole } from "common/checkers/viewerChecker";
import styles from "./projectCompanies.module.scss";
import { useModelPopup } from "../../../../common/hooks";
import { companyGetProjectTasks } from "../company.action";
import { useWindowSize } from "../../../../common/hooks/useWindowSize";
import MobileProjectCompanyRow from "./mobileProjectCompanyRow.component";
import DesktopProjectCompanyRow from "./desktopProjectCompanyRow.component";
import NewProjectV3 from "modules/projects/newProjectv3";

const ProjectCompanies = ({
  isPatientOrganization,
  company,
  isAdmin,
  sessionRole,
}) => {
  const {
    patientOrganizationsReducer: {
      patientOrganizationReducer: { selectedPatientOrganization },
    },
    companiesReducer: {
      companyReducer: {
        selectedCompany,
        companyProjectTasks,
        companyIsDownloading,
        shouldUpdateProjectTasks,
      },
    },
  } = useSelector((state) => state);
  const { isMobile } = useWindowSize();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  const popup = useModelPopup();

  const handleProjectClick = (e, task) => {
    if (task.current_user_has_access) {
      history.push(`/projects/${task.project?.id}`);
    } else {
      enqueueSnackbar("You don't have access to this project.", {
        variant: "error",
      });
    }
  };

  const redirectToProfile = (e, id) => {
    history.push(`/people/internal/${id}`);
  };

  useEffect(() => {
    if (isPatientOrganization && company) {
      if (!company?.id) {
        return;
      }

      dispatch(
        companyGetProjectTasks({
          company_id: company.id,
          enqueueSnackbar,
          isPatientOrganization: true,
        })
      );
      return;
    }

    if (selectedCompany) {
      dispatch(
        companyGetProjectTasks({
          company_id: selectedCompany.id,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCompany, isPatientOrganization, company]);

  useEffect(() => {
    if (shouldUpdateProjectTasks) {
      if (isPatientOrganization && company) {
        if (!company?.id) {
          return;
        }

        dispatch(
          companyGetProjectTasks({
            company_id: company.id,
            enqueueSnackbar,
            isPatientOrganization: true,
          })
        );
        return;
      }

      if (selectedCompany) {
        dispatch(
          companyGetProjectTasks({
            company_id: selectedCompany.id,
            enqueueSnackbar,
          })
        );
      }
    }
  }, [shouldUpdateProjectTasks]);

  return (
    <section className={`${companyIsDownloading ? "px-4" : ""}`}>
      <div className={styles.contentWrp}>
        <div className={styles.headerWrp}>
          <span className={styles.headerText}>
            {isPatientOrganization
              ? "Organization Mappings"
              : "Company Projects"}
          </span>
          {sessionRole !== "viewer" && !blockFunctionality && (
            <NewProjectV3
              isAdmin={isAdmin}
              wizardType={
                isPatientOrganization ? "patient_organization" : "company"
              }
              additionalData={
                isPatientOrganization
                  ? selectedPatientOrganization
                  : selectedCompany
              }
              btnName={isPatientOrganization ? "New Mapping" : "New Project"}
              className={styles.newTask}
              blockFunctionality={blockFunctionality}
              btnType="FRAME_LESS"
            />
          )}
        </div>
        <div className={styles.container}>
          <div className={styles.grid}>
            {companyProjectTasks?.length && !isMobile ? (
              <div className={styles.gridHeader}>
                <span className={styles.company}>
                  {" "}
                  {isPatientOrganization ? "mAPPING" : "project"}
                </span>
                <span className={styles.stage}>Stage</span>
                <span className={styles.company}>Owner</span>
                <span className={styles.company}>Access</span>
                <span className={styles.task}>Next Task</span>
                <span className={styles.assigned}>Assigned</span>
                <span className={styles.due}>Due</span>
                <span className={styles.due}>Status</span>
              </div>
            ) : null}
            <div className={styles.gridBody}>
              {companyProjectTasks?.length ? (
                companyProjectTasks.map((project) =>
                  isMobile ? (
                    <MobileProjectCompanyRow
                      key={project.id}
                      project={project}
                      handleProjectClick={handleProjectClick}
                      redirectToProfile={redirectToProfile}
                    />
                  ) : (
                    <DesktopProjectCompanyRow
                      key={project.id}
                      project={project}
                      handleProjectClick={handleProjectClick}
                      redirectToProfile={redirectToProfile}
                    />
                  )
                )
              ) : (
                <div>
                  {!companyProjectTasks?.length ? (
                    <div className="text-center pb-5">
                      {isPatientOrganization
                        ? "No mappings yet."
                        : " No projects yet."}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectCompanies;

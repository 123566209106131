import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import styles from './companySnapshotStyles.module.scss';
import { CompanyAvatar } from "modules/primitives";
import { makeWebSite } from "../../../../../common/helper";
import { httpPost } from "../../../../../common/httpCall";
import AnimatedSection from "modules/tractionAI/components/animatedSection";

const SnapshotCompanyResponse = ({ company, fetched, doNotAnimate }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [addingCompany, setAddCompany] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);

  const handleAnimationComplete = () => {
    setCurrentSection((prev) => prev + 1);
  };

  const goToProfile = () => {
    window.open(`/companies/${company.company_slug || company.company_id}`, '_blank');
  };

  const addCompany = () => {
    setAddCompany(true);
    httpPost({
      apiVersion: "v2",
      call: "council_companies",
      data: {
        company_name: company.company_name,
        company_website: company.company_url,
      },
    }).subscribe((res) => {
      setAddCompany(false);
      enqueueSnackbar("Successfully added company.", {
        variant: "success",
      });
    })
  }

  return (
    <div className={styles.snapshotCompany}>
      <div className="d-flex flex-column">
        <AnimatedSection
          doNotAnimate={doNotAnimate}
          isVisible={currentSection === 0}
          onAnimationComplete={currentSection === 0 ? handleAnimationComplete : null}
        >
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="d-flex align-items-center">
              {
                company.logo && (
                  <div className={styles.logoWrapper}>
                    <CompanyAvatar
                      imgSrc={company.logo}
                      name={company.company_name}
                      hasBorderRadius
                    />
                  </div>
                )
              }
              <div className="ml-3 d-flex flex-column">
                {
                  company.company_name && (
                    <span className={styles.name}>{company.company_name}</span>
                  )
                }
                {
                  company.website_url && (
                    <a className={styles.companyUrl} href={company.website_url} target="_blank"
                       rel="noopener noreferrer">
                      {makeWebSite(company.website_url)}
                    </a>
                  )
                }
              </div>
            </div>
            {/*{*/}
            {/*  fetched && (*/}
            {/*    <div className="d-flex align-items-center">*/}
            {/*      {*/}
            {/*        company.in_traction ? (*/}
            {/*          <Button*/}
            {/*            btn={BtnType.REGULAR}*/}
            {/*            onClick={goToProfile}*/}
            {/*            className={styles.buttonWrapper}*/}
            {/*            icon="icn-checkbox-button"*/}
            {/*          >Go to profile</Button>*/}
            {/*        ) : (*/}
            {/*          <>*/}
            {/*            {*/}
            {/*              addingCompany ? (*/}
            {/*                <LoaderWithDots text="Adding company..." />*/}
            {/*              ) : (*/}
            {/*                <Button*/}
            {/*                  icon="icn-add"*/}
            {/*                  btn={BtnType.REGULAR}*/}
            {/*                  onClick={addCompany}*/}
            {/*                  className={styles.buttonWrapper}*/}
            {/*                >Add Company</Button>*/}
            {/*              )*/}
            {/*            }*/}
            {/*          </>*/}
            {/*        )*/}
            {/*      }*/}
            {/*    </div>*/}
            {/*  )*/}
            {/*}*/}
          </div>
        </AnimatedSection>
        <AnimatedSection
          doNotAnimate={doNotAnimate}
          isVisible={currentSection === 1}
          onAnimationComplete={currentSection === 1 ? handleAnimationComplete : null}
        >
          <div className="d-flex align-items-center flex-wrap">
            {
              company.headquarters_location && (
                <div className="d-flex align-items-center mr-3">
                  <div className={styles.value}>HQ Location:&nbsp;</div>
                  <div className={styles.subTitle}>{company.headquarters_location}</div>
                </div>
              )
            }
            {
              company.year_founded && (
                <div className="d-flex align-items-center mr-3">
                  <div className={styles.value}>Year founded:&nbsp;</div>
                  <div className={styles.subTitle}>{company.year_founded}</div>
                </div>
              )
            }
            {
              company.total_funding && (
                <div className="d-flex align-items-center mr-3">
                  <div className={styles.value}>Total funding:&nbsp;</div>
                  <div className={styles.subTitle}>{`$${company?.total_funding?.toLocaleString("en-US")}`}</div>
                </div>
              )
            }
            {
              company.last_funding_round && (
                <div className="d-flex align-items-center mr-3">
                  <div className={styles.value}>Last funding round:&nbsp;</div>
                  <div className={styles.subTitle}>{`$${company?.last_funding_round?.toLocaleString("en-US")}`}</div>
                </div>
              )
            }
            {
              company.employees && (
                <div className="d-flex align-items-center mr-3">
                  <div className={styles.value}>Employees:&nbsp;</div>
                  <div className={styles.subTitle}>{company.employees}</div>
                </div>
              )
            }
          </div>
        </AnimatedSection>
        <AnimatedSection
          doNotAnimate={doNotAnimate}
          isVisible={currentSection === 2}
          onAnimationComplete={currentSection === 2 ? handleAnimationComplete : null}
        >
          {
            company.description && (
              <div className="mt-3">
                <div className={styles.title}>Description</div>
                <div className={styles.value}>{company.description}</div>
              </div>
            )
          }
        </AnimatedSection>
        <AnimatedSection
          doNotAnimate={doNotAnimate}
          isVisible={currentSection === 3}
          onAnimationComplete={currentSection === 3 ? handleAnimationComplete : null}
        >
          {
            company.products_services?.length ? (
              <div className="d-flex flex-column mt-3">
                <div className={styles.title}>
                  Products/Services
                </div>
                <div className="ml-2 d-flex flex-column">
                  {
                    company.products_services.map(item => (
                      <div key={item.name} className="d-flex flex-column">
                        <div className={styles.subTitle}>{item.name}</div>
                        <div className={styles.value}>{item.description}</div>
                      </div>
                    ))
                  }
                </div>
              </div>
            ) : null
          }
        </AnimatedSection>
        <AnimatedSection
          doNotAnimate={doNotAnimate}
          isVisible={currentSection === 4}
          onAnimationComplete={currentSection === 4 ? handleAnimationComplete : null}
        >
          {
            company.ceo_founder?.length ? (
              <div className="d-flex flex-column mt-3">
                <div className={styles.title}>
                  CEO/Founders
                </div>
                <div className="mt-2 ml-2 d-flex flex-column">
                  {
                    company.ceo_founder.map(item => (
                      <div key={item.name} className="d-flex align-items-center">
                        <div className={`${styles.subTitle} mr-2`}>{item.name}</div>
                        <div className={styles.value}>{item.title}</div>
                      </div>
                    ))
                  }
                </div>
              </div>
            ) : null
          }
        </AnimatedSection>
        <AnimatedSection
          doNotAnimate={doNotAnimate}
          isVisible={currentSection === 5}
          onAnimationComplete={currentSection === 5 ? handleAnimationComplete : null}
        >
          {
            company.key_takeaways?.length ? (
              <div className="d-flex flex-column mt-3">
                <div className={styles.title}>
                  Key Takeaways
                </div>
                <ul className="mt-2 ml-4">
                  {
                    company.key_takeaways.map((item, index) => (
                      <li key={`${item}_${index}`}>
                        <div className={styles.value}>{item}</div>
                      </li>
                    ))
                  }
                </ul>
              </div>
            ) : null
          }
        </AnimatedSection>
        <AnimatedSection
          doNotAnimate={doNotAnimate}
          isVisible={currentSection === 6}
          onAnimationComplete={currentSection === 6 ? handleAnimationComplete : null}
        >
          {
            company.strengths_differentiators?.length ? (
              <div className="d-flex flex-column mt-3">
                <div className={styles.title}>
                  Strengths Differentiators
                </div>
                <ul className="mt-2 ml-4">
                  {
                    company.strengths_differentiators.map((item, index) => (
                      <li key={`${item}_${index}`}>
                        <div className={styles.value}>
                          {item}
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </div>
            ) : null
          }
        </AnimatedSection>
        <AnimatedSection
          doNotAnimate={doNotAnimate}
          isVisible={currentSection === 7}
          onAnimationComplete={currentSection === 7 ? handleAnimationComplete : null}
        >
          {
            company.recent_developments?.length ? (
              <div className="d-flex flex-column mt-3">
                <div className={styles.title}>
                  Recent Developments
                </div>
                <ul className="mt-2 ml-4">
                  {
                    company.recent_developments.map((item, index) => (
                      <li key={`${item}_${index}`}>
                        <div className={styles.value}>
                          <a href={item.link} target="_blank" rel="noreferrer">{item.headline}</a>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </div>
            ) : null
          }
        </AnimatedSection>
      </div>
    </div>
  );
};

export default React.memo(SnapshotCompanyResponse);

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { checkViewerRole } from "common/checkers/viewerChecker";
import Tooltip from "@mui/material/Tooltip";
import {
  UserInline,
  UserInlineTypes,
  MoreOptions,
  Button,
  BtnType,
} from "../../../../primitives";
import styles from "./companyContact.module.scss";
import { moreOptions } from "./companyContact.constant";
import NewItemComponent from "../addNew/index";
import { deleteContact } from "../../patientOrganization.action";
import { useModelPopup } from "../../../../../common/hooks";

const CompanyContactComponent = (props) => {
  const { companyContacts } = props;
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();
  const [blockFunctionality, setBlockFunctionality] = useState(false);

  useEffect(() => {
    if (checkViewerRole()) {
      setBlockFunctionality(true);
    }
  }, []);

  const handleMoreOptionClick = (options, contact) => {
    if (options === 5) {
      handleEditClick(contact);
    }
    if (options === 6) {
      dispatch(
        deleteContact({
          enqueueSnackbar,
          patient_organization_contact_id:
            contact.patient_organization_contact_id,
        }),
      );
    }
  };

  const handleAddClick = (data) => {
    popup.show({
      title: "Add Patient Organization Contact",
      component: <NewItemComponent type="contact" data={data} />,
    });
  };

  const handleEditClick = (data) => {
    popup.show({
      title: "Edit Patient Organization Contact",
      component: <NewItemComponent type="contact" data={data} />,
    });
  };

  return (
    <>
      <div className="position-relative">
        <div className={styles.addButtonWrapper}>
          {!blockFunctionality ? (
            <Button
              btn={BtnType.FRAME_LESS}
              icon="icn-add"
              onClick={() => handleAddClick()}
            >
              Add
            </Button>
          ) : (
            <Tooltip
              title="You have View-Only access. To Add a Relationship Owner, please ask your Admin to upgrade your account to Standard access"
              placement="top"
            >
              <div>
                <Button btn={BtnType.DISABLED} icon="icn-add">
                  Add
                </Button>
              </div>
            </Tooltip>
          )}
        </div>
        {!companyContacts[0] && (
          <div className={styles.wrapper}>No organization contact found</div>
        )}
        {companyContacts.map((el) => (
          <div
            className={styles.wrapper}
            key={el.patient_organization_contact_id}
          >
            <div className={styles.itemContainer}>
              <div className={styles.leftContainer}>
                <div className={styles.userWrp}>
                  <UserInline
                    className={styles.userIcon}
                    classObj={{ name: styles.userName }}
                    userId={el.first_name + el.last_name}
                    type={UserInlineTypes.WITH_NAME}
                    showMine={false}
                    first={el.first_name}
                    last={el.last_name}
                    avatar={el.avatar}
                    local
                  />
                </div>
                <div className={styles.userDetails}>
                  <div className={classNames("caption small", styles.position)}>
                    {el.email}
                  </div>
                  <div className="caption">
                    {el.title}
                    {el && el.department_or_organization
                      ? `, ${el.department_or_organization}`
                      : ""}
                  </div>
                  <div className="caption">{el.mobile}</div>
                </div>
              </div>
              <div className={styles.rightContainer}>
                <div className={styles.colMore}>
                  {!blockFunctionality && (
                    <MoreOptions
                      options={moreOptions}
                      className={styles.moreOptWrp}
                      onClick={(val) => handleMoreOptionClick(val, el)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default React.memo(CompanyContactComponent);

import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import checkUniqName from "common/checkUniqName";
import checkDuplicateName from "common/checkDuplicateName";

import classnames from "classnames";
import { CheckBox, Label, TextBox } from "../../../../modules/primitives";
import styles from "./styles.module.scss";
import { model } from "./constant";
import RichEditor from "../../../components/richEditor";
import findCurrentCustomFieldGroup from "../../../findCustomFieldGroup";

const NewTextComponent = (props) => {
  const {
    formRef,
    handleOnSubmit,
    data,
    fromAdmin = false,
    adminLocation,
    selectedCustomFieldGroup,
    indexOfCfGroup,
  } = props;
  const [reply, setReply] = useState(data?.field_value || "");
  const [titleError, setTitleError] = useState("");

  const [wordsLength, setWordsLength] = useState(0);
  const {
    companiesReducer: {
      companyReducer: { newCustomFieldGroups },
    },
    adminCustomFieldsReducer: { customFieldGroups },
  } = useSelector((state) => state);
  /// //@TODO need to refactor here initialValues
  const initValues = data
    ? {
        field_type: data.field_type,
        field_value: data.field_value + reply,
        title: data.field_name,
        field_placeholder: data.field_placeholder || "",
        big_box_text: data.big_box_text || false,
        max_words: data.max_words || "",
      }
    : null;

  const onSubmit = (values) => {
    values.field_value = reply || data?.field_value;

    const cfGroup =
      findCurrentCustomFieldGroup(
        selectedCustomFieldGroup,
        customFieldGroups,
        newCustomFieldGroups,
        indexOfCfGroup,
        data
      ) || [];

    if (!values.title.length) {
      const field_name = checkDuplicateName(cfGroup, "text");
      values.title = field_name;
    } else if (
      !checkUniqName(values.title || values.field_value, cfGroup) &&
      values.title?.trim()?.toLowerCase() !==
        data?.field_name?.trim()?.toLowerCase()
    ) {
      setTitleError("Field name must be unique. Please rename this field");
      return;
    }
    setTitleError("");

    handleOnSubmit(values);
    values.title = "";
  };

  useEffect(() => {
    if (data?.big_box_text && data?.field_value) {
      const wordsNr = data.field_value.trim().split(/\s+/).length;
      setWordsLength(wordsNr);
    }
  }, [data]);

  useEffect(() => {
    const wordsNr = reply?.trim().split(/\s+/).length;
    setWordsLength(wordsNr);
  }, [reply]);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={data ? { ...initValues, type: "text" } : model}
      // validationSchema={validation && validation}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSubmit(values);
      }}
    >
      {({ handleSubmit, values, ...formikprops }) => (
        <form onSubmit={handleSubmit}>
          {fromAdmin ? (
            <>
              <div className="form-row d-flex align-items-center ml-1 mb-2">
                <CheckBox
                  isChecked={values.big_box_text}
                  onChange={(value) => {
                    formikprops.setFieldValue("big_box_text", value);
                  }}
                />
                <Label>Big Box Text</Label>
              </div>
              {values?.big_box_text ? (
                <div>
                  <label>Max Word Limit</label>
                  <TextBox
                    name="max_words"
                    placeholder="Max Word Limit"
                    formProps={{ ...formikprops, values }}
                    className="w-100"
                    value={values.max_words}
                  />
                </div>
              ) : null}
            </>
          ) : null}

          <Label>Title</Label>
          <TextBox
            type="text"
            name="title"
            placeholder="Add title"
            formProps={{ ...formikprops, values }}
            className={styles.textBox}
          />
          {titleError.length > 1 && (
            <div className={styles.errorBox}>
              <sup>*</sup>
              {titleError}
            </div>
          )}
          {/* <UniqName uniqName={uniqName} /> */}

          {!adminLocation && (
            <div className="d-flex flex-column align-items-start">
              <Label>Instructions (Optional)</Label>

              <div className={styles.instructionsEditor}>
                <RichEditor
                  // className={styles.textBox}
                  className={styles.instructionsEditor}
                  updateValue={(val) =>
                    formikprops.setFieldValue("field_placeholder", val)
                  }
                  initialValue={values.field_placeholder || ""}
                  placeholder="Add instructions"
                />
              </div>
            </div>
          )}
          {!fromAdmin && (
            <>
              <Label>Text</Label>
              <div className={styles.editor}>
                {data?.big_box_text ? (
                  <RichEditor
                    updateValue={setReply}
                    initialValue={data?.field_value}
                    placeholder="Enter text"
                    showAditionalButtons={false}
                    className={styles.textBox}
                    // className="w-100 border small p-2"
                  />
                ) : (
                  <textarea
                    className="w-100 border small p-2"
                    value={reply}
                    // placeholder={data?.field_placeholder}
                    onChange={(evt) => setReply(evt.target.value)}
                    rows="5"
                  />
                )}
                {data?.big_box_text && data?.max_words ? (
                  <span
                    className={classnames(
                      wordsLength > data.max_words ? "text-danger" : ""
                    )}
                  >
                    {wordsLength}/{data.max_words}
                  </span>
                ) : null}
              </div>
            </>
          )}
          {/* {error && <p className={styles.error}>text is required</p>} */}
        </form>
      )}
    </Formik>
  );
};

export default React.memo(NewTextComponent);
export { model };

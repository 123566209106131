import React from "react";
import { useHistory } from "react-router-dom";

import styles from "./discussionItem.module.scss";
import { ACTIVITY_OPTIONS, resourceImages } from "../../../common/constants";
import {
  MoreOptions,
  MultiUsers,
  UserInline,
  UserInlineTypes,
} from "../../primitives";
import classNames from "../../../../node_modules/classnames/index";

const DiscussionItem = ({
  discussion,
  handleShow,
  isPrivate,
  session,
  handleOptionsClick,
}) => {
  const history = useHistory();

  const handleRedirect = () => {
    if (discussion.discussion_resource_type === "Project") {
      history.push(`/projects/${discussion.discussion_resource_id}`);
    }

    if (discussion.discussion_resource_type === "Event") {
      history.push(`/events/id/${discussion.discussion_resource_id}`);
    }

    if (discussion.discussion_resource_type === "Company") {
      history.push(
        `/companies/${
          discussion.company?.slug || discussion.discussion_resource_id
        }`
      );
    }
    if (discussion.discussion_resource_type === "Gsk::PatientOrganization") {
      history.push(
        `/patient_organizations/${
          discussion.slug || discussion.discussion_resource_id
        }`
      );
    }
  };

  const checkIconType = (type) => {
    if (type === "Event") {
      return styles.Event;
    }
    if (type === "Project") {
      return styles.Project;
    }
    if (type === "Gsk::PatientOrganization") {
      return styles.Organization;
    }
    return styles.Company;
  };

  return (
    <div className={styles.discussionDetails}>
      <div className={styles.basic}>
        {isPrivate && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <g fill="none" fillRule="evenodd">
              <g fill="#7B8794">
                <g>
                  <g>
                    <g>
                      <path
                        d="M4.87.003c-1.961 0-3.555 1.593-3.555 3.554V6.52H.723c-.328 0-.593.265-.593.592v8.294c0 .327.265.592.593.592h8.293c.328 0 .593-.265.593-.592V7.111c0-.327-.266-.592-.593-.592h-.592V3.557c0-1.96-1.594-3.554-3.555-3.554zm0 1.184c1.307 0 2.37 1.064 2.37 2.37V6.52H2.5V3.557c0-1.307 1.063-2.37 2.37-2.37z"
                        transform="translate(-76 -204) translate(72 188) translate(4 16) translate(3)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        )}
        {discussion.anonymous ? (
          <div>Anonymous</div>
        ) : (
          <UserInline
            userId={discussion.creator.user_id}
            avatar={discussion.creator.avatar}
            type={UserInlineTypes.WITH_NAME}
            first={discussion.creator.full_name.split(" ")[0]}
            last={discussion.creator.full_name.split(" ")[1]}
            showMine={false}
            local
          />
        )}
      </div>
      <div className={styles.subject}>
        {discussion.subject ? `"${discussion.subject}"` : ""}
      </div>
      {discussion.discussion_resource_type !== "User" &&
        discussion.discussion_resource_type !== "Council" && (
          <div
            className={classNames(
              styles.resourceType,
              checkIconType(discussion.discussion_resource_type)
            )}
            onClick={handleRedirect}
          >
            <img
              src={resourceImages[discussion.discussion_resource_type]}
              alt={discussion.discussion_resource_type}
            />
            <span>
              {discussion.discussion_resource &&
                discussion.discussion_resource.name}
            </span>
          </div>
        )}
      <div className={styles.team}>
        {discussion.team_id
          ? discussion.team.name
          : discussion.participants &&
            discussion.participants.length &&
            (discussion.anonymous ? (
              <div> Anonymous</div>
            ) : (
              <MultiUsers
                users={discussion.participants}
                total={discussion.participants.length}
              />
            ))}
      </div>
      <div className={styles.msgsNr} onClick={handleShow}>
        <span className="mr-2">
          {discussion.messages?.filter((m) => !m.deleted).length}
        </span>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g fill="none" fillRule="evenodd">
              <g fill="#3994C1">
                <g>
                  <g>
                    <path
                      d="M22.477.988c.828 0 1.5.672 1.5 1.5v15.037c0 .829-.672 1.5-1.5 1.5L11.2 19.024l-4.818 4.794c-.953.948-2.542.258-2.559-1.03l.007-.172.335-3.59h-2.65c-.769 0-1.403-.578-1.49-1.324l-.01-.175V2.488c0-.828.672-1.5 1.5-1.5zm0 1.5H1.515v15.039h3.677l.11.013c.109.025.214.085.306.158.097.122.171.27.147.416l-.431 4.641 5.134-5.107.078-.05c.082-.045.179-.073.29-.073h11.65V2.488zM12.75 11.5c.414 0 .75.336.75.75s-.336.75-.75.75h-6c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h6zm4.5-3c.414 0 .75.336.75.75s-.336.75-.75.75H6.75C6.336 10 6 9.664 6 9.25s.336-.75.75-.75h10.5zm0-3c.414 0 .75.336.75.75s-.336.75-.75.75H6.75C6.336 7 6 6.664 6 6.25s.336-.75.75-.75h10.5z"
                      transform="translate(-1320 -261) translate(72 249) translate(1248 11)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
      </div>
      {discussion.creator_id === session?.id && (
        <div className={styles.options}>
          <MoreOptions
            options={ACTIVITY_OPTIONS.filter((a) => a.val !== 0)}
            onClick={(val) => handleOptionsClick(val, discussion)}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(DiscussionItem);

import React from "react";
import classNames from "classnames";

const MentionsList = ({ e }) => {
  const { mention, theme, searchValue, isFocused, ...parentProps } = e;
  return (
    <div
      className={classNames(theme?.mentionsList, parentProps.className)}
      onMouseDown={parentProps.onMouseDown}
      onClick={() => {
        parentProps.selectMention(mention);
      }}
    >
      <div className={theme?.mentionSuggestionsEntryContainer}>
        <div className={theme?.mentionSuggestionsEntryContainerRight}>
          <div
            className={
              mention?.type === "User"
                ? theme?.mentionSuggestionsEntryText
                : theme?.mentionSuggestionsEntryTextCompany
            }
          >
            {mention.name}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MentionsList;

import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip/Tooltip";

import { UserInline, UserInlineTypes } from "../../../../../primitives";
import styles from "./ideaListItem.module.scss";
import VoteIdea from "../../../../voteIdea";
import { CompanyAvatar } from "../../../../../primitives";

const ListItem = ({ idea, total_rating, hasRatingTemplate }) => {
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [canVote, setCanVote] = useState(true);

  useEffect(() => {
    if (selectedCouncil && session) {
      const council_role = session?.council_role;
      const isKyndryl = selectedCouncil.name === "Kyndryl";

      if (isKyndryl) {
        const currentUserIsReviewer = idea.ideas_members
          ?.filter((m) => m.user_role === "reviewer")
          .some((u) => u.user_id === session.id);

        setCanVote(
          currentUserIsReviewer ||
            council_role === "ttp_administrator" ||
            council_role === "council_administrator"
        );
      } else {
        setCanVote(true);
      }
    }

    return () => null;
  }, [session, selectedCouncil]);

  const capitalizeFirstLetter = (string) => {
    if (
      selectedCouncil?.traction_tag === "home_x" &&
      string.toUpperCase() === "PAUSED"
    ) {
      return "Parked";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getIdeaDescription = () => {
    if (idea.section_descriptions && idea.section_descriptions.length > 0) {
      const description = idea.section_descriptions.find(
        ({ section_name }) => section_name === "Description"
      );

      return description?.section_value || idea.description || "";
    }

    return idea.description || "";
  };

  const prepareLongRow = (tags) => {
    return (
      <Tooltip
        placement="bottom"
        title={`${tags.map((tag) => tag.name).join(", ")}`}
      >
        <span className={styles.moreTagslink}>{" More "}</span>
      </Tooltip>
    );
  };
  return (
    <Link to={`/ideas/${idea.id}`}>
      <div className={styles.listItemRow}>
        <div className={classnames(styles.listItemCell, "font-weight-bolder")}>
          {idea.name}
        </div>
        {selectedCouncil?.traction_tag === "home_x" && (
          <div
            className={classnames(
              styles.listItemCell,
              styles.listItemCellColumn
            )}
          >
            -
            {idea.companies &&
              idea.companies.length > 0 &&
              idea.companies.map((company, i) => {
                if (i < 5) {
                  return (
                    <div className={styles.nameContainer}>
                      <div className={styles.companyIcon}>
                        <div className={styles.iconCon}>
                          <CompanyAvatar
                            imgSrc={company.logo}
                            name={company.name}
                          />
                        </div>
                      </div>
                      <div>{company.name}</div>
                    </div>
                  );
                }
                if (i === 5) {
                  return prepareLongRow(idea.companies);
                }
                return null;
              })}
          </div>
        )}

        <div
          className={classnames(
            styles.listItemCell,
            styles.overflowText,
            "justify-content-start"
          )}
        >
          {getIdeaDescription()}
        </div>
        {hasRatingTemplate && (
          <div className={styles.listItemCell}>
            <span className={classnames(styles.listItemRating)}>
              {total_rating}
            </span>
          </div>
        )}
        <div className={styles.listItemCell}>
          <span
            className={classnames(
              styles.listItemStatus,
              styles[`listItemStatus-${idea.status}`]
            )}
          >
            {capitalizeFirstLetter(idea.status)}
          </span>
        </div>
        <div
          className={classnames(styles.listItemCell, "justify-content-start")}
        >
          <UserInline
            userId={idea.submitter.user_id}
            avatar={idea.submitter.avatar}
            first={idea.submitter.first_name}
            last={idea.submitter.last_name}
            type={UserInlineTypes.WITH_NAME}
            dontRequest
            noNavigation
          />
        </div>
        <div className={styles.listItemCell}>
          {format(parseISO(idea.created_at), " MM/dd/yyyy")}
        </div>
        <div
          role="cell"
          aria-hidden="true"
          className={classnames(styles.listItemCell, styles.noCursorPointer)}
          onClick={(e) => e.preventDefault()}
        >
          <VoteIdea canVote={canVote} idea={idea} />
        </div>
      </div>
    </Link>
  );
};

export default React.memo(ListItem);

import React, { memo, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import CloseIcon from "common/icon/closeIcon.component";

import style from "./chartTagsList.module.scss";
import {
  queryParamStringToArray,
  queryStringUrlReplacement,
  useQuery,
  convertFilterIndexCoordsToTags,
} from "../../../../../common/helper";
import {
  EMPLOYEES_ENUM,
  ESTIMATED_REVENUE_ENUM,
} from "../../../../../common/constants";
import {
  MultiUsers,
  UserInline,
  UserInlineTypes,
} from "../../../../primitives";
import { ADVANCED_PARAMS } from "../../companyMain.constant";
import { flat } from "../chartsView/charts/utils";

const ChartTagsList = ({ usedOutsideCharts }) => {
  const history = useHistory();
  const location = useLocation();
  const topicQuery = useQuery().get("topic");
  const industryQuery = useQuery().get("industry");
  const relationShipStatus = useQuery().get("relationShipStatus");
  const currentYear = format(new Date(), "yyyy");
  const employees = location.state?.employees
    ? location.state?.employees.split(",")
    : [];
  const estimatedRevenue = location.state?.estimatedRevenue
    ? location.state?.estimatedRevenue.split(",")
    : [];
  const listsQuery = useQuery().get("lists");
  const {
    appReducer: { relationShipStatuses },
    companiesReducer: {
      companyMainReducer: {
        charts: { chartOptions },
        industries,
        topics,
        relationshipOwners,
        companiesTagsNotInCouncil,
      },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const [otherTags, setOtherTags] = useState([]);
  const [owners, setOwners] = useState(null);
  const [agreements, setAgreements] = useState(null);
  const [idbEcosystem, setIdbEcosystem] = useState(null);
  const [customTags, setCustomTags] = useState(null);
  const [listsTags, setListsTags] = useState([]);

  useEffect(() => {
    if (location.state?.owners && relationshipOwners?.length) {
      const ownersArrStrings = location.state?.owners.split(",");
      const filteredOwners = relationshipOwners.filter((owner) =>
        ownersArrStrings.includes(owner.user_id)
      );

      setOwners(filteredOwners);
    } else {
      setOwners(null);
    }
  }, [location, relationshipOwners]);
  useEffect(() => {
    if (
      location.state?.idbEcosystem &&
      selectedCouncil.idb_connection?.option_values
    ) {
      const arr = queryParamStringToArray(location.state?.idbEcosystem);
      const value = selectedCouncil.idb_connection?.option_values
        .filter((a) => arr.includes(a))
        .map((a) => ({ name: a, id: a }));
      setIdbEcosystem(value);
    } else {
      setIdbEcosystem(null);
    }

    if (location.state?.agreements && selectedCouncil?.agreement_types) {
      const arr = queryParamStringToArray(location.state?.agreements);
      const value = selectedCouncil.agreement_types.filter((a) =>
        arr.includes(a.id)
      );
      setAgreements(value);
    } else {
      setAgreements(null);
    }
    if (
      location.state?.custom_tags_ids &&
      selectedCouncil?.council_custom_tag_groups
    ) {
      const arr = queryParamStringToArray(location.state?.custom_tags_ids);

      const values = [];
      if (arr[0] && arr[0].includes("all")) {
        const indexGroup = arr[0].split("_")[1];
        values.push({
          name: `All ${selectedCouncil?.council_custom_tag_groups[indexGroup].name}`,
          index: Number(indexGroup),
        });
      } else {
        values.push(
          ...convertFilterIndexCoordsToTags(
            arr,
            selectedCouncil?.council_custom_tag_groups
          )
        );
      }

      setCustomTags(values);
    } else {
      setCustomTags(null);
    }
  }, [location, selectedCouncil]);

  useEffect(() => {
    if (usedOutsideCharts && selectedCouncil && (topics || industries)) {
      const industriesArrFromQuery = queryParamStringToArray(
        industryQuery || ""
      );
      const topicsArrFromQuery = queryParamStringToArray(topicQuery || "");
      const flattIndustries = flat(industries || [], "children_industries");
      const flattTechnologies = flat(topics || [], "children_topics");
      const finalOtherTagsOutsideTechnology = [
        ...[...flattIndustries, ...companiesTagsNotInCouncil.industries]
          .filter((i) => industriesArrFromQuery.includes(i.id))
          .map((t) => ({ ...t, type: "industry" })),
        ...[...flattTechnologies, ...companiesTagsNotInCouncil.topics]
          .filter((i) => topicsArrFromQuery.includes(i.id))
          .map((t) => ({ ...t, type: "technology" })),
      ];
      const uniqueIds = new Set(
        finalOtherTagsOutsideTechnology.map((item) => item.id)
      );
      const itemsWithUniqueIds = [...uniqueIds]
        .map((id) =>
          finalOtherTagsOutsideTechnology.find((item) => item.id === id)
        )
        .filter(Boolean);

      setOtherTags(itemsWithUniqueIds);
    }

    return () => null;
  }, [
    industryQuery,
    topicQuery,
    chartOptions,
    selectedCouncil,
    industries,
    topics,
    usedOutsideCharts,
    companiesTagsNotInCouncil,
  ]);

  useEffect(() => {
    if (
      !usedOutsideCharts &&
      chartOptions.color === "relationShipStatus" &&
      (topics || industries)
    ) {
      const industriesArrFromQuery = queryParamStringToArray(
        industryQuery || ""
      );
      const topicsArrFromQuery = queryParamStringToArray(topicQuery || "");
      const flattIndustries = flat(industries || [], "children_industries");
      const flattTechnologies = flat(topics || [], "children_topics");

      const finalOtherTagsOutsideTechnology = [
        ...flattIndustries
          .filter((i) => industriesArrFromQuery.includes(i.id))
          .map((t) => ({ ...t, type: "industry" })),
        ...flattTechnologies
          .filter((i) => topicsArrFromQuery.includes(i.id))
          .map((t) => ({ ...t, type: "technology" })),
      ];

      setOtherTags(finalOtherTagsOutsideTechnology);
    }

    return () => null;
  }, [
    industryQuery,
    topicQuery,
    chartOptions,
    industries,
    topics,
    usedOutsideCharts,
  ]);

  useEffect(() => {
    if (
      !usedOutsideCharts &&
      relationShipStatuses &&
      chartOptions.color === "technology" &&
      industries
    ) {
      const industriesArrFromQuery = queryParamStringToArray(
        industryQuery || ""
      );
      const relationShipArrFromQuery = queryParamStringToArray(
        relationShipStatus || ""
      );
      const flattIndustries = flat(industries || [], "children_industries");

      const finalOtherTagsOutsideTechnology = [
        ...flattIndustries
          .filter((i) => industriesArrFromQuery.includes(i.id))
          .map((t) => ({ ...t, type: "industry" })),
        ...relationShipStatuses
          .filter((t) => relationShipArrFromQuery.includes(t.slug))
          .map((t) => ({ ...t, type: "relationShipStatus" })),
      ];

      setOtherTags(finalOtherTagsOutsideTechnology);
    }

    return () => null;
  }, [
    industryQuery,
    relationShipStatus,
    chartOptions,
    relationShipStatuses,
    industries,
    usedOutsideCharts,
  ]);

  useEffect(() => {
    if (
      !usedOutsideCharts &&
      relationShipStatuses &&
      chartOptions.color === "industry" &&
      topics
    ) {
      const topicsArrFromQuery = queryParamStringToArray(topicQuery || "");
      const relationShipArrFromQuery = queryParamStringToArray(
        relationShipStatus || ""
      );
      const flattTechnologies = flat(topics || [], "children_topics");

      const finalOtherTagsOutsideTechnology = [
        ...flattTechnologies
          .filter((i) => topicsArrFromQuery.includes(i.id))
          .map((t) => ({ ...t, type: "technology" })),
        ...relationShipStatuses
          .filter((t) => relationShipArrFromQuery.includes(t.slug))
          .map((t) => ({ ...t, type: "relationShipStatus" })),
      ];

      setOtherTags(finalOtherTagsOutsideTechnology);
    }

    return () => null;
  }, [
    topicQuery,
    relationShipStatus,
    chartOptions,
    relationShipStatuses,
    topics,
    usedOutsideCharts,
  ]);
  useEffect(() => {
    if (listsQuery !== null) {
      const arr = queryParamStringToArray(listsQuery || "").map((string) => {
        const obj = {
          id: string.split("_")[0],
          name: string.split("_")[1],
        };
        return obj;
      });

      setListsTags(arr);
    }

    return () => null;
  }, [listsQuery]);

  const onTagDelete = (tag, prop) => {
    if (prop === "idbEcosystem") {
      const value = idbEcosystem
        .filter((a) => a.id !== tag.id)
        .reduce((acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`, "");

      const search = queryStringUrlReplacement(
        history.location.search,
        "idbEcosystem",
        value
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          idbEcosystem: value,
        },
      });

      return;
    }

    if (prop === "custom_tags_ids") {
      const value = [...customTags]
        .filter((a) => a.name !== tag.name)
        .reduce((acc, elem) => {
          return `${acc}${acc.length ? "," : ""}${elem.index}`;
        }, "");

      const search = queryStringUrlReplacement(
        history.location.search,
        "custom_tags_ids",
        value
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          custom_tags_ids: value,
        },
      });

      return;
    }

    if (prop === "agreements") {
      const value = agreements
        .filter((a) => a.id !== tag.id)
        .reduce((acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`, "");

      const search = queryStringUrlReplacement(
        history.location.search,
        "agreements",
        value
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          agreements: value,
        },
      });

      return;
    }

    if (tag.type === "relationShipStatus") {
      const value = otherTags
        .filter((t) => t.type === "relationShipStatus" && t.slug !== tag.slug)
        .reduce(
          (acc, elem) => `${acc}${acc.length ? "," : ""}${elem.slug}`,
          ""
        );

      const search = queryStringUrlReplacement(
        history.location.search,
        "relationShipStatus",
        value
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          relationShipStatus: value,
        },
      });

      return;
    }

    if (tag.type === "industry") {
      const value = otherTags
        .filter((t) => t.type === tag.type && t.id !== tag.id)
        .reduce((acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`, "");

      const search = queryStringUrlReplacement(
        history.location.search,
        tag.type,
        value
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          [tag.type]: value,
        },
      });
    }

    if (tag.type === "technology") {
      const value = otherTags
        .filter((t) => t.type === tag.type && t.id !== tag.id)
        .reduce((acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`, "");

      const search = queryStringUrlReplacement(
        history.location.search,
        "topic",
        value
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          topic: value,
        },
      });
    }

    if (prop === "lists") {
      const value = listsTags
        .filter((a) => a.id !== tag.id)
        .reduce(
          (acc, elem) =>
            `${acc}${acc.length ? "," : ""}${elem.id}_${elem.name}`,
          ""
        );

      const search = queryStringUrlReplacement(
        history.location.search,
        "lists",
        value
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          lists: value,
        },
      });
    }
  };

  const handleOtherFiltersDelete = (props) => {
    const { state } = history.location;

    ADVANCED_PARAMS.forEach((key) => {
      if (state[key]) {
        props.forEach((prop) => {
          if (prop === key) {
            delete state[key];
          }
        });
      }
    });

    const search = Object.keys(state).reduce(
      (acc, key) => `${!acc.length ? "?" : ""}${acc}&${key}=${state[key]}`,
      ""
    );

    history.push({
      pathname: "/companies",
      search,
      state: state || null,
    });
  };

  return (
    <div className={style.chartTagsList}>
      {location.state?.location ? (
        <div className={style.tag}>
          Location: {location.state?.location.split("#")[0]}
          <span
            className={style.tagDelete}
            onClick={() => handleOtherFiltersDelete(["location"])}
          >
            <CloseIcon />
          </span>
        </div>
      ) : null}
      {otherTags.map((tag) => (
        <div key={tag.id || tag.slug} className={style.tag}>
          {tag.name}
          <span className={style.tagDelete} onClick={() => onTagDelete(tag)}>
            <CloseIcon />
          </span>
        </div>
      ))}
      {(location.state?.scoreFrom && location.state?.scoreFrom !== "0") ||
      (location.state?.scoreTo && location.state?.scoreTo !== "10") ? (
        <div className={style.tag}>
          Score: {location.state?.scoreFrom || "0"}-
          {location.state?.scoreTo || "10"}
          <span
            className={style.tagDelete}
            onClick={() => handleOtherFiltersDelete(["scoreFrom", "scoreTo"])}
          >
            <CloseIcon />
          </span>
        </div>
      ) : null}
      {location.state?.foundedFrom ||
      (location.state?.foundedTo &&
        location.state?.foundedTo !== currentYear) ? (
        <div className={style.tag}>
          Year Founded: {location.state?.foundedFrom || "-"}-
          {location.state?.foundedTo || currentYear}
          <span
            className={style.tagDelete}
            onClick={() =>
              handleOtherFiltersDelete(["foundedFrom", "foundedTo"])
            }
          >
            <CloseIcon />
          </span>
        </div>
      ) : null}
      {(location.state?.fundingFrom && location.state?.fundingFrom !== "0") ||
      (location.state?.fundingTo &&
        location.state?.fundingTo !== "Any Amount") ? (
        <div className={style.tag}>
          Total Funding:{" "}
          {location.state?.fundingFrom
            ? `$${location.state?.fundingFrom}`
            : "$0"}
          -
          {location.state?.fundingTo &&
          location.state?.fundingTo !== "Any Amount"
            ? `$${location.state?.fundingTo}`
            : "Any Amount"}
          <span
            className={style.tagDelete}
            onClick={() =>
              handleOtherFiltersDelete(["fundingFrom", "fundingTo"])
            }
          >
            <CloseIcon />
          </span>
        </div>
      ) : null}
      {employees && employees.length ? (
        <div className={style.tag}>
          Employees:{" "}
          {employees.map(
            (e, i) =>
              `${EMPLOYEES_ENUM[e]}${employees.length === i + 1 ? "" : ", "}`
          )}
          <span
            className={style.tagDelete}
            onClick={() => handleOtherFiltersDelete(["employees"])}
          >
            <CloseIcon />
          </span>
        </div>
      ) : null}
      {estimatedRevenue && estimatedRevenue.length ? (
        <div className={style.tag}>
          Estimated Revenue:{" "}
          {estimatedRevenue.map(
            (e, i) =>
              `${ESTIMATED_REVENUE_ENUM[e]}${
                estimatedRevenue.length === i + 1 ? "" : ", "
              }`
          )}
          <span
            className={style.tagDelete}
            onClick={() => handleOtherFiltersDelete(["estimatedRevenue"])}
          >
            <CloseIcon />
          </span>
        </div>
      ) : null}
      {owners && owners.length ? (
        <div className={`${style.tag} align-items-center`}>
          Relationship Owners:&nbsp;
          {owners?.length > 1 ? (
            <MultiUsers
              users={owners}
              total={owners.length}
              dontShowRemaining
            />
          ) : owners?.length ? (
            <UserInline
              userId={owners[0].user.id}
              avatar={owners[0].user.avatar}
              first={owners[0].user.first_name}
              last={owners[0].user.last_name}
              type={UserInlineTypes.WITH_NAME}
              dontRequest
            />
          ) : null}
          <span
            className={style.tagDelete}
            onClick={() => handleOtherFiltersDelete(["owners"])}
          >
            <CloseIcon />
          </span>
        </div>
      ) : null}
      {agreements?.length ? (
        <div className="d-flex align-items-center">
          <span className={style.agreements}>Agreements:&nbsp;</span>
          {agreements.map((a) => (
            <div key={a.id} className={style.tag}>
              {a.name}
              <span
                className={style.tagDelete}
                onClick={() => onTagDelete(a, "agreements")}
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      ) : null}
      {idbEcosystem?.length ? (
        <div className="d-flex align-items-center">
          <span className={style.agreements}>IDBG Connection:&nbsp;</span>
          {idbEcosystem.map((a) => (
            <div key={a.id} className={style.tag}>
              {a.name}
              <span
                className={style.tagDelete}
                onClick={() => onTagDelete(a, "idbEcosystem")}
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      ) : null}
      {customTags?.length ? (
        <div className="d-flex align-items-center">
          {/* <span className={style.agreements}>Custom tags:&nbsp;</span> */}
          {customTags.map((a) => (
            <div key={a?.custom_tag_id} className={style.tag}>
              {a.name}
              <span
                className={style.tagDelete}
                onClick={() => onTagDelete(a, "custom_tags_ids")}
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      ) : null}
      {location.state?.lists &&
        location.state?.lists?.length &&
        listsTags.map((a) => (
          <div key={a?.id} className={style.tag}>
            {a.name.toUpperCase()}
            <span
              className={style.tagDelete}
              onClick={() => onTagDelete(a, "lists")}
            >
              <CloseIcon />
            </span>
          </div>
        ))}
    </div>
  );
};

export default memo(ChartTagsList);

import * as Yup from "yup";

export const validation = Yup.object().shape({
  link: Yup.string()
    .matches(
      /^(ftp|http|https):\/\/[^ "]+$/,
      "Wrong url, please use: http or https",
    ),
});

export const model = {
  type: "link",
  link: "",
  title: "",
};

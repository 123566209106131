import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { Formik, Form, FieldArray } from "formik";
import { useSelector } from "react-redux";
import { httpGet, httpPost } from "common/httpCall";
import Switch from "react-switch";
import { useSnackbar } from "notistack";
import { DropSelect, BtnType, Button as Btn } from "../../primitives";
import styles from "./configureContacts.module.scss";
import { useModelPopup } from "../../../common/hooks/index";
import ConfigureExportContacts from "./configureExports/configureExportContacts";
import SyncMappingInfoModal from "./Modals/syncMappingsInfoModal";
import {
  preSelectedCompanyValues,
  preSelectedSalesForceValues,
} from "./preSelectedValues/contactsPreSelectedValues";

const ConfigureContacts = () => {
  const [selectedSfValues, setSelectedSfValues] = useState([]);
  const [selectedContactValues, setSelectedContactValues] = useState([]);
  const [isSelectedSfValue, setIsSelectedSfValue] = useState(true);
  const [isSelectedContactValue, setIsSelectedContactValue] = useState(true);
  const [selectedSfValue, setSelectedSfValue] = useState({});
  const [selectedContactValue, setSelectedContactValue] = useState({});
  const [accountLimit, setAccountLimit] = useState(null);
  const [sfFields, setSfFields] = useState([]);
  const [coFields, setCoFields] = useState([]);
  const [typeMatch, setTypeMatch] = useState([]);
  const [subtractedCompanyFields, setSubtractedCompanyFields] = useState([]);
  const [subtractedSfFields, setSubtractedSfFields] = useState([]);
  const [checked, setChecked] = useState(true);
  const [friendsArray, setFriendsArray] = useState([]);
  const [contactSyncToggle, setContactSyncToggle] = useState(false);
  const [mappings, setMappings] = useState(false);

  const [companyFieldsToSubtract] = useState([
    { text: "company_id", value: "uuid" },
  ]);
  const [sfFieldsToSubtract] = useState([
    { text: "AccountId", value: "string" },
  ]);

  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  const getMappedType = (type) => {
    switch (type) {
      case "id":
        return "uuid";
      case "textarea":
        return "text";
      case "date":
        return "datetime";
      case "picklist":
        return "json";
      case "reference":
        return "string";
      case "phone":
        return "string";
      case "email":
        return "string";
      default:
        return type;
    }
  };

  useEffect(() => {
    httpGet({
      call: "sf_integrations/get_sf_account_limit",
    })
      .pipe()
      .subscribe((res) => {
        setAccountLimit(res?.response?.sf_account_limit);
      });
  }, []);

  useEffect(() => {
    if (accountLimit && accountLimit?.Remaining <= 1) {
      enqueueSnackbar(
        "Warning! Your Salesforce account storage limit is about to run out. Please free some space or upgrade your plan.",
        {
          variant: "warning",
          autoHideDuration: 5000,
        },
      );
    }
  }, [accountLimit]);

  useEffect(() => {
    localStorage.setItem("contact_token", false);
    httpGet({
      call: "sf_integrations/get_fields",
    })
      .pipe()
      .subscribe((res) =>
        setSfFields(
          res.response.contact_fields.map((item) => ({
            text: item.name,
            value: getMappedType(item.type),
          })),
        ),
      );

    httpGet({
      call: "sf_integrations/get_contact_fields",
    })
      .pipe()
      .subscribe((res) =>
        setCoFields(
          res.response.contact_fields.map((item, index) => ({
            text: item,
            value: res.response.types[index],
          })),
        ),
      );
  }, []);

  useEffect(() => {
    const subtractedCoFields = coFields.filter(
      (i) => !companyFieldsToSubtract.map((j) => j.text).includes(i.text),
    );
    setSubtractedCompanyFields(subtractedCoFields);

    const subtractedSalesforceFields = sfFields.filter(
      (i) => !sfFieldsToSubtract.map((j) => j.text).includes(i.text),
    );
    setSubtractedSfFields(subtractedSalesforceFields);
  }, [coFields, sfFields]);

  const handleSaveMappings = (state) => {
    httpPost({
      call: "sf_integrations/save_mappings",
      data: {
        user_id: session.id,
        council_id: selectedCouncil.id,
        mappings_payload: state,
        attribute: "contact",
        flow: "import",
      },
    })
      .pipe()
      .subscribe((res) => {
        console.log(res.response);
      });
  };

  const handleSelectedFields = () => {
    const state = {
      currentAttribute: "contact",

      mappedTractionFields: [...selectedContactValues].some(
        (val) => val.text === "company_id",
      )
        ? [...selectedContactValues]
        : [...selectedContactValues, { text: "company_id", value: "uuid" }],

      mappedSalesforceFields: [...selectedSfValues].some(
        (val) => val.text === "AccountId",
      )
        ? [...selectedSfValues]
        : [...selectedSfValues, { text: "AccountId", value: "string" }],

      selectedFilterOption: "",
      selectedFilter: [],
      mappedTractionCustomFields: [],
      mappedSalesforceCustomFields: [],
      syncToggle: contactSyncToggle,
    };

    handleSaveMappings(state);

    history.push({
      pathname: "/salesforce/fetchData",
      state,
    });
  };

  useEffect(() => {
    setChecked(!(location?.state?.checked === false));
  }, []);

  useEffect(() => {
    if (session && selectedCouncil) {
      httpPost({
        call: "sf_integrations/get_mappings",
        data: {
          user_id: session.id,
          council_id: selectedCouncil.id,
          attribute: "contact",
          flow: "import",
        },
      })
        .pipe()
        .subscribe((res) => {
          if (res.response.length) {
            setMappings(true);
            setSelectedSfValues(
              res?.response[0]?.mappedSalesforceFields ??
                preSelectedSalesForceValues(),
            );
            setSelectedContactValues(
              res?.response[0]?.mappedTractionFields ??
                preSelectedCompanyValues(),
            );
            setFriendsArray(res?.response[0]?.mappedTractionFields);
            setContactSyncToggle(
              res.response[0].syncToggle ?? contactSyncToggle,
            );
            localStorage.setItem(
              "contact_token",
              res.response[0].syncToggle ?? contactSyncToggle,
            );
          } else {
            setFriendsArray(preSelectedSalesForceValues());
            setSelectedSfValues(preSelectedSalesForceValues());
            setSelectedContactValues(preSelectedCompanyValues());
          }
        });
    }
  }, [session, selectedCouncil, checked]);

  const handleBack = () => {
    history.push({
      pathname: "/salesforce/configureData",
    });
  };

  let boundArrayHelpers;

  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  const handleSwitchChange = () => {
    setChecked(!checked);
  };

  const handleSyncToggleChanged = () => {
    let message;
    if (mappings) {
      enqueueSnackbar(
        `Sync Accounts job is ${contactSyncToggle ? "disabled" : "enabled"}`,
        {
          variant: "info",
        },
      );
      setContactSyncToggle(!contactSyncToggle);
      message =
        "Previously saved mappings will be used for Sync. If you want to update mappings then re-map component and hit submit button ";
    } else {
      message =
        " No mappings are found for Contact Sync. Kindly procede to next page in order to save mappings. Make sure you save mappings for Export Contacts as well ";
    }
    return (
      !contactSyncToggle &&
      popup.show({
        title: "Sync Contact",
        show: true,
        height: "300",
        width: "540",
        component: <SyncMappingInfoModal message={message} />,
      })
    );
  };

  return checked ? (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ friends: Array(friendsArray.length).fill("") }}
        onSubmit={() => handleSelectedFields()}
        render={({ values }) => (
          <Form>
            <FieldArray
              name="friends"
              render={(arrayHelpers) => {
                bindArrayHelpers(arrayHelpers);
                return (
                  <div>
                    <div className={styles.backBtn}>
                      <Btn
                        className={styles.backBtn}
                        btn={BtnType.FRAME_LESS}
                        onClick={() => handleBack()}
                      >
                        Back
                      </Btn>
                    </div>
                    <div className={styles.submitBtn}>
                      <div />
                      <Btn btn={BtnType.REGULAR} type="submit">
                        Submit
                      </Btn>
                      <div className={styles.switchBtn}>
                        <Switch
                          checked={checked}
                          onChange={handleSwitchChange}
                          onColor="#86d3ff"
                          onHandleColor="#2693e6"
                          handleDiameter={30}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={20}
                          width={48}
                          className="react-switch"
                          id="material-switch"
                        />
                        {checked ? (
                          <div className={styles.toggleText}>Import</div>
                        ) : (
                          <div className={styles.toggleText}>Export</div>
                        )}
                      </div>
                    </div>
                    <div className={styles.syncToggle}>
                      <Switch
                        checked={contactSyncToggle}
                        onChange={handleSyncToggleChanged}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        className="react-switch"
                        id="material-switch"
                      />
                      {contactSyncToggle ? (
                        <div className={styles.toggleTextOn}>Sync ON</div>
                      ) : (
                        <div className={styles.toggleText}>Sync OFF</div>
                      )}
                    </div>
                    <div className={styles.fieldsConatiner}>
                      <>
                        <div className={styles.headTitle}>
                          <div className={styles.headerContainer}>
                            <div className={styles.mapsTextHead}>
                              Salesforce
                            </div>
                            <div className={styles.mapsTextHead}>Traction</div>
                          </div>
                        </div>
                      </>
                    </div>
                    <div className={styles.fieldsConatiner}>
                      {values.friends.map((friend, index) => (
                        <div>
                          {values.friends.length && (
                            <>
                              <div
                                className={styles.headTitle}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginLeft: "1rem",
                                  marginTop: "1rem",
                                }}
                              >
                                <>
                                  <div className={styles.mapsText}>
                                    {selectedSfValues[index]?.text}
                                  </div>
                                  <Button className={styles.mapsButton}>
                                    Maps
                                  </Button>
                                  <div className={styles.mapsText}>
                                    {selectedContactValues[index]?.text}
                                  </div>
                                  <Chip
                                    className={styles.chipButton}
                                    onDelete={() => {
                                      setSelectedSfValues([
                                        ...selectedSfValues.filter(
                                          (_, selectedIndex) =>
                                            selectedIndex !== index,
                                        ),
                                      ]);
                                      setSelectedContactValues([
                                        ...selectedContactValues.filter(
                                          (_, selectedIndex) =>
                                            selectedIndex !== index,
                                        ),
                                      ]);
                                      friendsArray.pop();
                                      setIsSelectedSfValue(false);
                                    }}
                                  />
                                </>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              }}
            />
            <div className={styles.fieldsConatiner}>
              <div className={styles.headTitle}>
                <>
                  <DropSelect
                    selectedValues=""
                    disabled={isSelectedContactValue}
                    placeholder="Select fields from Salesforce"
                    labelField="text"
                    valueField="text"
                    searchBy="text"
                    onChange={(event) => {
                      setSelectedSfValue({
                        text: event.obj[0].text,
                        value: event.obj[0].text,
                      });
                      setIsSelectedSfValue(false);
                    }}
                    options={subtractedSfFields.filter(
                      (item) =>
                        !selectedSfValues
                          .map((value) => value.text)
                          .includes(item.text) && item.value === typeMatch,
                    )}
                  />
                  <DropSelect
                    selectedValues=""
                    placeholder="Select fields from Traction"
                    labelField="text"
                    valueField="text"
                    searchBy="text"
                    options={subtractedCompanyFields.filter(
                      (item) =>
                        !selectedContactValues
                          .map((value) => value.text)
                          .includes(item.text),
                    )}
                    onChange={(event) => {
                      setTypeMatch(event.obj[0].value);
                      setSelectedContactValue({
                        text: event.obj[0].text,
                        value: event.obj[0].text,
                      });
                      setIsSelectedContactValue(false);
                    }}
                  />
                  <Btn
                    className={styles.addButton}
                    disabled={
                      values.friends.length ===
                        subtractedCompanyFields.length ||
                      isSelectedSfValue ||
                      isSelectedContactValue
                    }
                    onClick={() => {
                      setSelectedContactValues([
                        ...selectedContactValues,
                        selectedContactValue,
                      ]);
                      setSelectedSfValues([
                        ...selectedSfValues,
                        selectedSfValue,
                      ]);
                      setIsSelectedSfValue(true);
                      setIsSelectedContactValue(true);
                      boundArrayHelpers.insert(values.friends.length - 1, "");
                    }}
                  >
                    +Add
                  </Btn>
                </>
              </div>
            </div>
          </Form>
        )}
      />
    </div>
  ) : (
    <div>
      <ConfigureExportContacts
        checked={checked}
        handleSwitchChange={handleSwitchChange}
        syncToggleStatus={contactSyncToggle}
      />
    </div>
  );
};

export default React.memo(ConfigureContacts);

import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactTable from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import {
  Button,
  BtnType,
  MoreOptions,
  OptionType,
  Loading,
} from "../../primitives";
import styles from "./adminIdeas.module.scss";

import { useModelPopup } from "../../../common/hooks";
import { ideasGetAttempt, formsGetAttempt } from "./adminIdeas.action";
import { httpPost } from "../../../common/httpCall";
import ConfirmDeleteComponent from "../../../common/components/confirmDelete";
import IdeasSearch from "./ideasSearch/index";
import { postSearchIdeasAttempt } from "./adminIdeas.action";
import { queryStringUrlReplacement, useQuery } from "../../../common/helper";
import ShareIdeaForm from "./shareIdeaForm";
import classnames from "classnames";

const PAGINATION_DEFAULTS = {
  pageSize: 20,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
};

const AdminIdeas = () => {
  const history = useHistory();
  const location = useLocation();
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [exportLoading, setExportLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [selectedVariant, setSelectedVarian] = useState("ideas");
  const csvLinkRef = useRef();
  const tab = useQuery().get("tab");
  const [ideasPage, setIdeasPage] = useState(1);
  const [ideasRow, setIdeasRows] = useState(20);
  const [formsPage, setFormsPage] = useState(1);
  const [formsRow, setFormsRows] = useState(20);
  const [queryVal, setQueryVal] = useState("");
  const [pageSetting, setPageSetting] = useState(PAGINATION_DEFAULTS);
  const [showMore, setShowMore] = useState(false);

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    adminIdeasReducer: {
      ideas,
      loading,
      formsLoading,
      forms,
      ideasPageNr,
      changed,
      formsPageNr,
    },
  } = useSelector((state) => state);

  const handleAddFormClick = (e) => {
    history.push("/admin/forms/edit/newForm", {
      mode: "add",
    });
  };

  const handleMoreOptionClick = (event, ideaId, ideaName, row) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // hack, because tab from above doesn't get updated correctly, and when
    // you are in the forms tab, it shows ideas

    if (event === "preview") {
      const shareUrl = `${window.location.origin}/submission-idea-form/${row.council_name_slug}/${row.form_name_slug}`;
      window.open(shareUrl);
    }

    if (event === "embedCode") {
      popup.show({
        title: "",
        show: true,
        // height: '300',
        // width: '600',
        component: (
          <div>
            <ShareIdeaForm
              form={{
                id: ideaId,
                council_name_slug: row.council_name_slug,
                form_name_slug: row.form_name_slug,
                council: {
                  council_id: row.council?.council_id,
                },
              }}
              customActionsButtons={
                <Button
                  type="button"
                  onClick={popup.hide}
                  key="close-modal-btn"
                >
                  Close
                </Button>
              }
            />
          </div>
        ),
      });
    }

    if (event === "edit") {
      history.push(`/admin/forms/edit/${row.idea_template_id}`, {
        templateId: row,
      });
    }
    if (event === "delete") {
      const council = JSON.parse(localStorage.getItem("council"));
      popup.show({
        title: "Confirmation Prompt",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={ideaId}
            enqueueSnackbar={enqueueSnackbar}
            councilId={council?.id}
            name={ideaName}
            actionType={params?.tab === "ideas" ? "deleteIdea" : "deleteForm"}
          />
        ),
      });
    }
  };

  const onTabClick = (tab) => {
    let search = queryStringUrlReplacement(history.location.search, "tab", tab);
    search = queryStringUrlReplacement(search, "tab", tab);

    history.push({
      pathname: "/admin/ideas",
      search,
      state: {
        ...history.location?.state,
        tab: tab,
      },
    });
  };

  useEffect(() => {
    setSelectedVarian(tab);
    if (tab === "ideas") {
      setSelectedData(ideas);
    } else {
      setSelectedData(forms);
    }
  }, [tab]);

  useEffect(() => {
    if (session) {
      dispatch(
        ideasGetAttempt({
          userId: session.id,
          councilId: JSON.parse(localStorage.getItem("council")).id,
          page: ideasPage,
          rows: ideasRow,
          enqueueSnackbar,
        })
      );
      dispatch(
        formsGetAttempt({
          userId: session.id,
          councilId: JSON.parse(localStorage.getItem("council")).id,
          page: formsPage,
          rows: formsRow,
          enqueueSnackbar,
        })
      );
    }
  }, [session, selectedCouncil]);

  useEffect(() => {
    if (session) {
      if (queryVal !== "") {
        const payload = {
          data: {
            query: queryVal,
          },
          ideasPage,
          ideasRow,
          enqueueSnackbar,
        };

        dispatch(postSearchIdeasAttempt(payload));
      } else {
        dispatch(
          ideasGetAttempt({
            userId: session.id,
            councilId: JSON.parse(localStorage.getItem("council")).id,
            page: ideasPage,
            rows: ideasRow,
            enqueueSnackbar,
          })
        );
      }
    }
  }, [ideasPage, ideasRow]);

  useEffect(() => {
    if (session && changed) {
      dispatch(
        formsGetAttempt({
          userId: session.id,
          councilId: JSON.parse(localStorage.getItem("council")).id,
          enqueueSnackbar,
        })
      );
    }
  }, [forms, changed]);

  const handleExportList = () => {
    setExportLoading(true);
    try {
      httpPost({
        call: `reports/admin_ideas${showMore ? "?show_more=true" : ""}`,
        data: {},
        respType: "blob",
      })
        .pipe()
        .subscribe((res) => {
          const url = window.URL.createObjectURL(new Blob([res.response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `ideas${showMore ? "_extended" : ""}.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();

          enqueueSnackbar("List succesfully exported.", {
            variant: "success",
          });
          setExportLoading(false);
        });
    } catch (error) {
      console.log("----error-----", error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    }
  };

  const moreOptions = [
    // { val: 'edit', text: 'Edit' },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const moreOptionsForms = [
    { val: "preview", text: "Preview" },
    { val: "embedCode", text: "Share" },
    { val: "edit", text: "Edit" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const getIdeaDescription = (idea) => {
    if (idea.ideas_descriptions && idea.ideas_descriptions.length > 0) {
      const description = idea.ideas_descriptions.find(
        ({ section_name }) => section_name === "Description"
      );

      return description?.section_value || idea.description || "";
    }

    return idea.description || "";
  };

  const ideaRedirect = (rowInfo) => {
    if (rowInfo.original.id) {
      history.push(`/admin/ideas/${rowInfo.original.id}`);
    }
  };

  const onRowClick = (rowInfo) => {
    if (rowInfo.original.idea_template_id) {
      history.push(`/admin/forms/edit/${rowInfo.original.idea_template_id}`, {
        templateId: rowInfo.original,
      });
    }
  };
  const handleTechnologyClick = useCallback(
    (e, technologyId) => {
      const search = queryStringUrlReplacement(
        // history.location.search,
        "",
        "topic",
        technologyId
      );

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          topic: technologyId,
        },
      });
    },
    [location, history]
  );

  const handleIndustryClick = useCallback(
    (e, industryId) => {
      const search = queryStringUrlReplacement("", "industry", industryId);

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          industry: industryId,
        },
      });
    },
    [location, history]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Idea",
        // accessor: 'name',
        id: "name",

        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          overflowWrap: "break-word",
          width: "200px",
        },
        width: 300,
        accessor: (row) => {
          return (
            <p>
              {" "}
              {row?.name?.length > 37
                ? row?.name.slice(0, 37) + "..."
                : row?.name || ""}{" "}
            </p>
          );
        },
      },
      {
        Header: "Submitter",
        id: "submitter",
        width: 160,
        accessor: (row) => {
          return <div>{row.submitter?.full_name}</div>;
        },
      },
      {
        Header: "Description",
        id: "description",
        accessor: (row) => {
          return <div>{getIdeaDescription(row)}</div>;
        },
      },
      {
        Header: "Date Added",
        id: "created_at",
        width: 100,
        accessor: (row) => {
          try {
            return <div>{format(new Date(row.created_at), "MM/dd/yyyy")} </div>;
          } catch (error) {
            console.log("-----error-----", error, row);
          }
        },
      },
      {
        Header: "Up Votes",
        id: "total_up_votes",
        accessor: "total_up_votes",
        width: 80,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Options",
        id: "options",
        accessor: (row) => (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row.id, row.name);
            }}
          />
        ),
        width: 100,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [ideas]
  );

  const showMoreColumns = React.useMemo(
    () => [
      {
        Header: "Name",
        id: "name",
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          overflowWrap: "break-word",
          width: "280px",
        },
        width: 280,
        accessor: (row) => {
          return (
            <p>
              {" "}
              {row?.name.length > 35
                ? row?.name.slice(0, 35) + "..."
                : row?.name}{" "}
            </p>
          );
        },
      },
      {
        Header: "Submitter",
        id: "submitter",
        width: 160,
        accessor: (row) => {
          return <div>{row.submitter?.full_name || "-"}</div>;
        },
      },
      {
        Header: "Description",
        id: "description",
        accessor: (row) => {
          return <div>{getIdeaDescription(row) || "-"}</div>;
        },
      },
      {
        Header: "Date Added",
        id: "created_at",
        width: 100,
        accessor: (row) => {
          try {
            return (
              <div>
                {format(new Date(row.created_at), "MM/dd/yyyy") || "-"}{" "}
              </div>
            );
          } catch (error) {
            console.log("-----error-----", error, row);
          }
        },
      },
      {
        Header: "Up Votes",
        id: "total_up_votes",
        accessor: (row) => {
          return <div>{row.total_up_votes}</div>;
        },
        width: 100,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Status",
        id: "status",
        accessor: (row) => {
          return <div>{row.status || "-"}</div>;
        },
        width: 160,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Down Votes",
        id: "total_down_votes",
        accessor: (row) => {
          return <div>{row.total_down_votes}</div>;
        },
        width: 100,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Comments",
        id: "ideas_comments_number",
        accessor: (row) => <span>{row?.ideas_comments?.length}</span>,
        width: 100,
        style: {
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Idea",
        id: "ideas_descriptions",
        accessor: (row) => (
          <div>
            {row?.ideas_descriptions?.length
              ? row?.ideas_descriptions.map((desc) => {
                  return (
                    <p>{`${desc?.section_name}: ${
                      desc?.section_value ||
                      desc?.section_values?.join(", ") ||
                      "-"
                    }`}</p>
                  );
                })
              : "-"}
          </div>
        ),
        width: 250,
        style: {
          overflow: "auto",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Notes",
        id: "ideas_comments_list",
        accessor: (row) => (
          <div>
            {row?.ideas_comments?.length
              ? row?.ideas_comments.map((com) => {
                  return (
                    <p
                      className={styles.pWrap}
                    >{`${com?.user?.full_name}: ${com?.comment}`}</p>
                  );
                })
              : "-"}
          </div>
        ),
        width: 300,
        style: {
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          wordWrap: "break-word",
        },
      },
      {
        Header: "Industries",
        id: "resource_industries",
        accessor: (row) => (
          <div>
            {row?.resource_industries?.length
              ? row?.resource_industries.map((ind) => {
                  // return <p className={styles.pWrap}>{ind?.name}</p>
                  return (
                    <Button
                      key={ind.name}
                      btn={BtnType.TAG_OUTLINE_BLUE}
                      rounded
                      className={styles.tag}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleIndustryClick(e, ind.industry_id);
                      }}
                    >
                      {ind?.name}
                    </Button>
                  );
                })
              : "-"}
          </div>
        ),
        width: 300,
        style: {
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          wordWrap: "break-word",
        },
      },

      {
        Header: "Technologies",
        id: "resource_topics",
        accessor: (row) => (
          <div>
            {row?.resource_topics?.length
              ? row?.resource_topics.map((topic) => {
                  // return <p className={styles.pWrap}>{topic?.name}</p>
                  return (
                    <Button
                      key={topic.id}
                      btn={BtnType.TAG_BLUE_LIGHT}
                      rounded
                      className={styles.tag}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTechnologyClick(e, topic.topic_id);
                      }}
                    >
                      {topic?.name}
                    </Button>
                  );
                })
              : "-"}
          </div>
        ),
        width: 300,
        style: {
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          wordWrap: "break-word",
        },
      },
      {
        Header: "Team",
        id: "ideas_members",
        accessor: (row) => {
          const filteredTeamMembers = row?.ideas_members?.length
            ? row?.ideas_members.filter(
                (member) => member.user_role === "member"
              )
            : [];

          return (
            <div>
              {filteredTeamMembers?.length
                ? filteredTeamMembers.map((member, index, array) => {
                    return `${member?.user_first_name} ${
                      member?.user_last_name
                    }${Boolean(array.length - 1 !== index) ? ",  " : "."}`;
                  })
                : "-"}
            </div>
          );
        },
        width: 300,
        style: {
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          whiteSpace: "initial",
        },
      },
      {
        Header: "Reviewers",
        id: "reviewers_members",
        accessor: (row) => {
          const filteredReviewers = row?.ideas_members?.length
            ? row?.ideas_members.filter(
                (member) =>
                  member.user_role !== "member" &&
                  member.user_role !== "submitter"
              )
            : [];

          return (
            <div>
              {filteredReviewers?.length
                ? filteredReviewers.map((member, index, array) => {
                    return `${member?.user_first_name} ${
                      member?.user_last_name
                    }${Boolean(array.length - 1 !== index) ? ",  " : "."}`;
                  })
                : "-"}
            </div>
          );
        },
        width: 300,
        style: {
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          whiteSpace: "initial",
        },
      },
      {
        Header: "Related Companies",
        id: "ideas_related_companies",
        accessor: (row) => (
          <div>
            {row?.ideas_related_companies?.length
              ? row?.ideas_related_companies.map((company, index, array) => {
                  return `${company?.company?.name}${
                    Boolean(array.length - 1 !== index) ? ",  " : "."
                  }`;
                })
              : "-"}
          </div>
        ),
        width: 300,
        style: {
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          whiteSpace: "initial",
        },
      },
      {
        Header: "Related Projects",
        id: "projects_ideas",
        accessor: (row) => (
          <div>
            {row?.projects?.length
              ? row?.projects.map((project, index, array) => {
                  return `${project?.name}${
                    Boolean(array.length - 1 !== index) ? ",  " : "."
                  }`;
                })
              : "-"}
          </div>
        ),
        width: 300,
        style: {
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          whiteSpace: "initial",
        },
      },
      {
        Header: "Documents",
        id: "documents_ideas",
        accessor: (row) => (
          <div>
            {row?.documents?.length
              ? row?.documents.map((doc, index, array) => {
                  return `${doc?.name}${
                    Boolean(array.length - 1 !== index) ? ",  " : "."
                  }`;
                })
              : "-"}
          </div>
        ),
        width: 300,
        style: {
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          whiteSpace: "initial",
        },
      },

      {
        Header: "Options",
        id: "options",
        accessor: (row) => (
          <MoreOptions
            options={moreOptions}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row.id, row.name);
            }}
          />
        ),
        width: 100,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [ideas]
  );

  const formsColumns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "name",
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "flex-start",
        },
      },
      {
        Header: "Date Created",
        id: "created_at",
        width: 100,
        accessor: (row) => {
          return <div>{format(new Date(row.created_at), "MM/dd/yyyy")} </div>;
        },
      },
      {
        Header: "Last Submission",
        id: "submitter",
        width: 160,
        accessor: (row) => {
          return (
            <div>{format(new Date(row.last_submission), "MM/dd/yyyy")}</div>
          );
        },
      },
      {
        Header: "Total Submissions",
        id: "description",
        width: 160,
        accessor: (row) => {
          return <div>{row.total_submissions || 0}</div>;
        },
      },
      {
        Header: "Default",
        id: "active",
        accessor: (row) => {
          return <div>{row.active ? "Yes" : "No"}</div>;
        },
        width: 100,
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
      {
        Header: "Options",
        id: "options",
        accessor: (row) => (
          <MoreOptions
            options={moreOptionsForms}
            className={styles.moreOptWrp}
            onClick={(e) => {
              handleMoreOptionClick(e, row.idea_template_id, row.name, row);
            }}
          />
        ),
        style: {
          overflow: "visible",
          display: "flex",
          justifyContent: "center",
        },
      },
    ],
    [ideas]
  );

  useEffect(() => {
    if (formsPageNr.total > 20) {
      setPageSetting({
        ...pageSetting,
        current: formsPageNr.current,
        total: formsPageNr.total,
        pageSize: formsPageNr.pageSize,
      });
    }
  }, [forms, ideas]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <span
            className={styles.rootText}
            onClick={() => {
              history.push("/admin");
            }}
          >
            ADMIN
          </span>
          <span className={styles.separationBar}> / </span>
          <span className={styles.rootText}>Ideas</span>
        </h4>
      </div>
      <div className="row">
        <div className={classnames(styles.tabsContainer, "col-10")}>
          <h5>
            <span
              onClick={() => {
                onTabClick("ideas");
              }}
              className={
                selectedVariant === "ideas"
                  ? styles.selectedVar
                  : styles.tabsText
              }
            >
              {" "}
              Ideas{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span
              onClick={() => {
                onTabClick("forms");
              }}
              className={
                selectedVariant === "forms"
                  ? styles.selectedVar
                  : styles.tabsText
              }
            >
              {" "}
              Forms{" "}
            </span>
          </h5>
        </div>
      </div>
      {selectedVariant === "ideas" && (
        <div className={styles.flexWrapper}>
          <div className={styles.ideasContainer}>
            <IdeasSearch
              ideasPage={ideasPage}
              ideasRow={ideasRow}
              queryVal={queryVal}
              setQueryVal={setQueryVal}
            />
          </div>
        </div>
      )}
      <div className={styles.flexWrapper}>
        <div className={styles.ideasContainer}>
          <div className={styles.buttonsHeader}>
            <div className={styles.showMoreButtonBlock}>
              <Button
                onClick={() => setShowMore((prevState) => !prevState)}
                disabled={loading || formsLoading}
                icon={!showMore ? "icn-add" : "icn-minus"}
                btn={loading || formsLoading ? BtnType.DISABLED : BtnType.LINK}
                className={
                  selectedVariant === "ideas"
                    ? styles.visibleButton
                    : styles.nonDisplayedButton
                }
              >
                {!showMore ? "Show More" : "Show Less"}
              </Button>
            </div>
            <div>
              {exportLoading ? (
                <Loading />
              ) : (
                <>
                  {tab === "ideas" ? (
                    <div className={styles.exportContainer}>
                      <Button
                        // disabled={!listTotalCount}
                        onClick={handleExportList}
                        btn={BtnType.OUTLINE}
                      >
                        EXPORT
                      </Button>
                      <CSVLink
                        data={csvData}
                        filename={`Traction Ideas.csv`}
                        style={{ display: "none" }}
                        ref={csvLinkRef}
                      />
                      <div className={styles.totalCount}>
                        {selectedVariant === "forms" ? (
                          <>{formsPageNr?.total || "0"} Forms</>
                        ) : (
                          <>{ideasPageNr?.total || "0"} Ideas</>
                        )}
                      </div>
                      {/* ideasLength */}
                    </div>
                  ) : (
                    <div className={styles.exportContainer}>
                      <div className={styles.totalCount}>
                        {forms?.length || "0"} Forms
                      </div>
                    </div>
                  )}
                  <div className={styles.addTemplateButton}>
                    <Button
                      btn={BtnType.REGULAR}
                      icon="icn-add"
                      onClick={() => handleAddFormClick()}
                    >
                      Create Form
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          {selectedVariant === "forms" ? (
            <>
              <ReactTable
                columns={formsColumns}
                data={forms}
                loading={loading || formsLoading}
                className={styles.table}
                loadingText="Updating forms across the database. Please wait . . ."
                getTrProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onClick: () => {
                        onRowClick(rowInfo);
                      },
                      style: {
                        cursor: "pointer",
                        background: rowInfo?.original?.active
                          ? "#d1eefc"
                          : "transparent",
                      },
                    };
                  }
                  return {};
                }}
                // pages={formsPageNr?.pages}
                // onPageChange={(e) => setFormsPage(e + 1)}
                // onPageSizeChange={(e) => setFormsRows(e)}
                showPagination={true}
                minRows={10}
                manual
              />
              {/* {pageSetting && pageSetting.total > 2 ? (
                <div className="w-100 d-flex justify-content-center mb-5">
                  <Pagination
                    {...pageSetting}
                    onChange={(p) => handlePagingChange(p)}
                  />
                </div>
              ) : null} */}
            </>
          ) : (
            <ReactTable
              columns={showMore ? showMoreColumns : columns}
              data={ideas}
              // data={selectedData }
              showPagination={true}
              minRows={10}
              loading={loading || formsLoading}
              className={styles.table}
              loadingText="Updating ideas across the database. Please wait . . ."
              manual
              pages={ideasPageNr?.pages}
              getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: () => {
                      ideaRedirect(rowInfo);
                    },
                    style: {
                      cursor: "pointer",
                      background: rowInfo?.original?.active
                        ? "#d1eefc"
                        : "transparent",
                    },
                  };
                }
                return {};
              }}
              onPageChange={(e) => setIdeasPage(e + 1)}
              onPageSizeChange={(e) => setIdeasRows(e)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default AdminIdeas;

import * as Yup from "yup";
import { format } from "date-fns";

import { objectType } from "modules/companies/company/details/details.constant";

export const validation = Yup.object().shape({});
export const model = {
  type: objectType.document,
  title: "",
  expiration_date: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  instruction: "",
  options: [],
  multiselect: true,
};

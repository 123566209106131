import React from "react";
import styles from "./loading.module.scss";

const Loading = ({ customText }) => (
  <div className={styles.loadingWrp}>
    <div className={styles.icon} />
    <div>
      {customText || "Loading..."}
    </div>
  </div>
);

export default React.memo(Loading);

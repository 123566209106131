import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { httpGet } from "common/httpCall";

import { Label, DropSelect } from "modules/primitives";
import styles from "../styles.module.scss";

const ProjectTemplateFilter = ({
  handleFilterUpdate,
  reducer,
  setNewFilters,
}) => {
  const [fieldValues, setFieldValues] = useState([]);
  const [projTemplateOptionSearchResult, setProjTemplateOptionSearchResult] =
    useState([]);

  const [loadingProjTemplatesFilterOpt, setLoadingProjTemplatesFilterOpt] =
    useState(false);

  useEffect(() => {
    httpGet({
      call: "projects/stage_templates/stages",
    }).subscribe((res) => {
      setProjTemplateOptionSearchResult((prev) => {
        setLoadingProjTemplatesFilterOpt(true);

        return res.response.map((el) => ({
          id: el.id,
          name: el.name,
        }));
      });
    });
  }, []);
  useEffect(() => {
    setFieldValues(reducer.filters?.stage_template_ids || []);
  }, [reducer.filters?.stage_template_ids]);
  const updateFilters = useCallback(
    (newValues) => {
      setNewFilters({
        ...reducer.filters,
        stage_template_ids: newValues.stage_template_ids,
      });

      handleFilterUpdate(
        { stage_template_ids: newValues.stage_template_ids },
        reducer.tableData?.expandedKey
      );
    },
    [reducer]
  );

  const handleFieldValueChange = (value, prop) => {
    if (!value.obj?.length) {
      if (fieldValues[prop]) {
        const newValues = {
          ...fieldValues,
          [prop]: null,
        };

        setFieldValues(newValues.stage_template_ids);
        updateFilters(newValues);
      }

      return;
    }
    const newValues = {
      ...fieldValues,
      [prop]: value.obj,
    };

    setFieldValues(newValues.stage_template_ids);
    updateFilters(newValues);
  };

  return (
    <div
      key={"stage_template_ids"}
      className={classNames(styles.filter, "mb-3")}
    >
      <Label>Project Template</Label>

      <DropSelect
        multi
        values={fieldValues || []}
        onChange={(val) => {
          handleFieldValueChange(val, "stage_template_ids");
        }}
        options={projTemplateOptionSearchResult || []}
        labelField="name"
        valueField="id"
        placeholder={"Project Template"}
        searchBy="name"
        clearable={false}
      />
    </div>
  );
};

export default React.memo(ProjectTemplateFilter);

import React from "react";
import styles from "./crunchSource.module.scss";

const CrunchSourceComponent = ({ company, hideTraction }) => {
  if (!company?.cb_profile_name) {
    return null;
  }
  const owlerCompetitors = company?.external_competitors?.filter(
    (competitor) => competitor.added_from_source === "owler"
  );

  return (
    <div
      className={`d-flex justify-content-end align-items-center ${styles.crunchSource}`}
    >
      Source:&nbsp;
      <a
        href={`https://www.crunchbase.com/organization/${company.cb_profile_name}`}
        target="_blank"
        rel="noreferrer"
      >
        Crunchbase
      </a>
      {owlerCompetitors.length !== 0 ? ", Owler" : ""}
      {!hideTraction ? ", Traction" : ""}
    </div>
  );
};

export default React.memo(CrunchSourceComponent);

import React from "react";

import TractionLogo from "../../../../common/components/tractionLogo";

const PDFTitleAndTractionLogo = ({ downloadIsActive, title }) => {
  if (!downloadIsActive) return null;

  return (
    <div className="d-flex align-items-center justify-content-center w-100 mb-3 position-relative">
      <h3>{title}</h3>
      <div className="position-absolute" style={{right: 0}}>
        <TractionLogo />
      </div>
    </div>
  );
};

export default React.memo(PDFTitleAndTractionLogo);

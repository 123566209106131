import React, { useState } from "react";

import AnimatedSection from "modules/tractionAI/components/animatedSection";
import RecommendIdea from "modules/tractionAI/screens/recommend/ideas/recommendIdea";

const RecommendIdeas = ({ item, index }) => {
  const [currentSection, setCurrentSection] = useState(0);

  const handleAnimationComplete = () => {
    setCurrentSection((prev) => prev + 1);
  };

  return (
    <div className="d-flex flex-column ">
      {
        item.data?.map((idea, idx) => (
          <AnimatedSection
            doNotAnimate={item.doNotAnimate || item.hasError}
            key={`${idea.idea_name}_${idx}_${index}`}
            onAnimationComplete={idx === currentSection ? handleAnimationComplete : null}
            isVisible={idx <= currentSection}
          >
            <RecommendIdea
              parentIndex={index}
              index={idx}
              idea={idea}
            />
          </AnimatedSection>
        ))
      }
    </div>
  );
}

export default React.memo(RecommendIdeas);

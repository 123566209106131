import { ofType, combineEpics } from "redux-observable";
import {
  switchMap,
  map,
  groupBy,
  mergeMap,
  catchError,
} from "rxjs/operators";
import {
  COUNCIL_GET_ATTEMPT,
  councilGetSuccess,
  COUNCIL_LIST_GET_ATTEMPT,
  councilListGetSuccess,
  COUNCIL_USER_GET_ATTEMPT,
  councilUserGetSuccess,
  COUNCIL_STAGES_TEMPLATE_LIST_ATTEMPT,
  councilStagesTemplateListSuccess,
  COUNCIL_REMOVE_MEMBER_ATTEMPT,
  councilRemoveMemberSuccess,
  COUNCIL_GET_TEAMS_ATTEMPT,
  councilGetTeamsSuccess,
  COUNCIL_REMOVE_TEAM_ATTEMPT,
  councilRemoveTeamSuccess,
  COUNCIL_CREATE_TEAM_ATTEMPT,
  councilCreateTeamSuccess,
  COUNCIL_GET_TEAM_BY_ID_ATTEMPT,
  councilGetTeamByIdSuccess,
  councilTeamAddMemberSuccess,
  COUNCIL_TEAM_ADD_MEMBER_ATTEMPT,
  COUNCIL_SEARCH_TEAM_MEMBER_ATTEMPT,
  councilSearchTeamMemberSuccess,
  COUNCIL_REMOVE_TEAM_MEMBER_ATTEMPT,
  councilRemoveTeamMemberSuccess,
  COUNCIL_SEARCH_TEAMS_ATTEMPT,
  councilSearchTeamsSuccess,
  COUNCIL_EDIT_TEAM_ATTEMPT,
  councilEditTeamSuccess,
  COUNCIL_LIST_GET_ALL_ATTEMPT,
  councilListGetAllSuccess,
} from "./council.action";
import {
  httpGet,
  httpPut,
  httpPost,
  queryString,
  httpDelete,
  errorHandler,
} from "../../common/httpCall";

export const epicGetCouncil = (action$, state$) =>
  action$.pipe(
    ofType(COUNCIL_GET_ATTEMPT),
    switchMap(({ payload }) =>
      httpGet({
        call: `councils/${payload.id}`, // NEW_API
      }).pipe(
        map((result) =>
          councilGetSuccess({
            ...result,
            integrated_tokens: payload.integrated_tokens,
          })
        ),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar))
      )
    )
  );

export const epicGetCouncilListAll = (action$, state$) =>
  action$.pipe(
    ofType(COUNCIL_LIST_GET_ALL_ATTEMPT),
    switchMap(({ payload: { active, userId, items, enqueueSnackbar } }) =>
      httpGet({
        call: "councils", // NEW_API`,
      }).pipe(
        map((result) => councilListGetAllSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetCouncilList = (action$) =>
  action$.pipe(
    ofType(COUNCIL_LIST_GET_ATTEMPT),
    switchMap(({ payload: { active, userId, items, enqueueSnackbar } }) =>
      httpGet({
        call: `users/councils/dropdown?${queryString({
          active,
          items: items || 1000,
        })}`,
      }).pipe(
        map((result) => councilListGetSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetCouncilUser = (action$, state$) =>
  action$.pipe(
    ofType(COUNCIL_USER_GET_ATTEMPT),
    groupBy(
      (action) => action.payload.userId,
      (action) => action
    ),
    mergeMap((group) =>
      group.pipe(
        switchMap(({ payload: { userId, enqueueSnackbar } }) =>
          httpGet({
            call: `users/${userId}`, // OLD_API
          }).pipe(
            map((result) => councilUserGetSuccess(result.response)),
            catchError((err) => errorHandler(err, enqueueSnackbar))
          )
        )
      )
    )
  );

export const epicGetCouncilStageTemplates = (action$, state$) =>
  action$.pipe(
    ofType(COUNCIL_STAGES_TEMPLATE_LIST_ATTEMPT),
    switchMap(({ payload: { councilId, enqueueSnackbar } }) =>
      httpGet({
        call: `stage_templates?${queryString({
          council_id: councilId,
        })}`, // NEW_API`,
      }).pipe(
        map((result) => councilStagesTemplateListSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicRemoveCouncilMember = (action$, state$) =>
  action$.pipe(
    ofType(COUNCIL_REMOVE_MEMBER_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar, memberName } }) =>
      httpDelete({
        call: `users/${id}`,
      }).pipe(
        map((result) => {
          enqueueSnackbar(`You deleted the Member: ${memberName}`, {
            variant: "success",
          });
          return councilRemoveMemberSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetTeams = (action$, state$) =>
  action$.pipe(
    ofType(COUNCIL_GET_TEAMS_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        call: "teams", // NEW_API
      }).pipe(
        map((result) => councilGetTeamsSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicRemoveTeam = (action$, state$) =>
  action$.pipe(
    ofType(COUNCIL_REMOVE_TEAM_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar, teamName } }) =>
      httpDelete({
        call: `teams/${id}`,
      }).pipe(
        map((result) => {
          enqueueSnackbar(`You deleted the Team: ${teamName}`, {
            variant: "success",
          });
          return councilRemoveTeamSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicCreateTeam = (action$, state$) =>
  action$.pipe(
    ofType(COUNCIL_CREATE_TEAM_ATTEMPT),
    switchMap(
      ({
        payload: {
          name,
          description,
          council_id,
          enqueueSnackbar,
          request_research,
        },
      }) =>
        httpPost({
          call: "teams",
          data: {
            name,
            description,
            council_id,
            private: true,
            active: true,
            request_research,
          },
        }).pipe(
          map((result) => councilCreateTeamSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicEditTeam = (action$, state$) =>
  action$.pipe(
    ofType(COUNCIL_EDIT_TEAM_ATTEMPT),
    switchMap(
      ({
        payload: {
          name,
          description,
          enqueueSnackbar,
          teamId,
          request_research,
        },
      }) =>
        httpPut({
          call: `teams/${teamId}`,
          data: {
            name,
            description,
            request_research,
          },
        }).pipe(
          map((result) => councilEditTeamSuccess(result)),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicGetTeamById = (action$, state$) =>
  action$.pipe(
    ofType(COUNCIL_GET_TEAM_BY_ID_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpGet({
        call: `teams/${id}`,
      }).pipe(
        map((result) => councilGetTeamByIdSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicAddMember = (action$, state$) =>
  action$.pipe(
    ofType(COUNCIL_TEAM_ADD_MEMBER_ATTEMPT),
    switchMap(({ payload: { team_id, user_id, role, enqueueSnackbar } }) =>
      httpPost({
        call: `teams/${team_id}/members`,
        data: {
          team_member: {
            team_id,
            user_id,
            active: true,
            primary: true,
            council_role: role,
          },
        },
      }).pipe(
        map((result) => {
          enqueueSnackbar("Succesfully added a team member.", {
            variant: "success",
          });
          return councilTeamAddMemberSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicRemoveTeamMember = (action$, state$) =>
  action$.pipe(
    ofType(COUNCIL_REMOVE_TEAM_MEMBER_ATTEMPT),
    switchMap(({ payload: { team_id, user_id, enqueueSnackbar } }) =>
      httpDelete({
        call: `teams/${team_id}/members/${user_id}`,
      }).pipe(
        map((result) => {
          enqueueSnackbar("Team member succesfully removed.", {
            variant: "success",
          });
          return councilRemoveTeamMemberSuccess({ ...result, user_id });
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicSearchTeam = (action$, state$) =>
  action$.pipe(
    ofType(COUNCIL_SEARCH_TEAMS_ATTEMPT),
    switchMap(({ payload: { query, enqueueSnackbar } }) =>
      httpGet({
        call: `teams?query=${query}`,
      }).pipe(
        map((result) => councilSearchTeamsSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicSearchMember = (action$, state$) =>
  action$.pipe(
    ofType(COUNCIL_SEARCH_TEAM_MEMBER_ATTEMPT),
    switchMap(({ payload: { query, search, enqueueSnackbar } }) =>
      httpPost({
        call: "councils/users/search",
        data: {
          query,
          search,
        },
      }).pipe(
        map((result) => councilSearchTeamMemberSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

const councilEpic = combineEpics(
  epicGetCouncil,
  epicGetCouncilList,
  epicGetCouncilUser,
  epicRemoveCouncilMember,
  epicGetTeams,
  epicRemoveTeam,
  epicCreateTeam,
  epicGetTeamById,
  epicAddMember,
  epicSearchMember,
  epicRemoveTeamMember,
  epicSearchTeam,
  epicEditTeam,
  epicGetCouncilListAll
);

export default councilEpic;

import * as Yup from "yup";

export const initCrunchbaseModel = {
  selectedCompany: null,
  name: "",
  website: null,
  company_id: null,
};

const urlRegex = /^(https?:\/\/)?([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})([\/\w\.-]*)*\/?$/;

export const crunchbaseValidation = Yup.object().shape({
  name: Yup.string().required("Company name is required"),
  website: Yup.string()
    .matches(urlRegex, "Please enter a valid url (or delete any extra spaces)")
    .required("Company Website is required"),
});

export const kyndrylCrunchbaseValidation = Yup.object().shape({
  name: Yup.string().required("Company name is required"),
  website: Yup.string()
    .matches(urlRegex, "Please enter a valid url (or delete any extra spaces)")
    .required("Company Website is required"),
  relationship_owner_id: Yup.string().required(
    "Relationship Owner is required",
  ),
});

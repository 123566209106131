import * as Yup from "yup";

export const initModel = {
  price_in_currency: 0,
  completed: false,
  comment: '',
  payment_date: '',
  currency: 'USD'
};

export const validation = Yup.object().shape({
  price_in_currency: Yup.number().required("Price is required."),
});

export const currencies = {
  'USD': '$',
  'EUR': '€',
  'GBP': '£',
  'CHF': 'CHF',
}

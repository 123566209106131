import React, { memo, useState, useEffect } from "react";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import LoadingComponent from "modules/primitives/loading/loading.component";
import styles from "./styles.module.scss";
import { CompanyAvatar } from "../../../../../primitives";
import { Icon, xsSize } from "../../../../../../common/icon";
import { httpGet } from "../../../../../../common/httpCall";

import MoreOptionsWithCheckbox from "../../../../themesCommon/moreOptions/index";
import ThemesBlock from "./themesBlock";

const KanbanCompany = (props) => {
  const {
    fromThemes,
    company,
    changeCompanyStage,
    selectedCouncil,
    deleteCompanyStage,
    stages,
    handleReleaseDragNDrop,
  } = props;
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil: council },

    themesReducer: {
      themesMainReducer: { favPeding },
    },
  } = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const [selectedInvestment, setSelectedInv] = useState(company.investment);
  const [hovered, setHovered] = useState(false);
  const [hideMoreThemes, setHideMoreThemes] = useState(true);
  const [fullfiledStages, setFullfiledStages] = useState(false);
  const [compOptions, setCompOptions] = useState([
    {
      text: "Remove",
      val: "remove",
      type: 1,
    },
    // {
    //   text: "Track",
    //   val: "track",
    // childrens: [
    //   {
    //     text: "Partnership",
    //     val: "partnership",
    //     type: 1,
    //   },
    //   {
    //     text: "Investment",
    //     val: "investment",
    //     type: 1,
    //   },
    // ],
    // },
  ]);
  const [companyToExpand, setCompanyToExpand] = useState(false);
  const [themesLoading, setThemesLoading] = useState(false);
  const [themesData, setThemesData] = useState([]);

  const handleExpand = (elem) => {
    if (companyToExpand === true) {
      setCompanyToExpand(false);
      return;
    }

    setCompanyToExpand(true);
    setThemesLoading(true);

    httpGet({
      call: `/themes/pipeline/themes?resource_type=${elem?.resource_type}&resource_id=${elem?.id}`,
      apiVersion: "v2",
      data: {
        query: "*",
      },
    }).subscribe(
      async (res) => {
        setThemesLoading(false);
        setThemesData(res?.response);
      },
      (err) => {
        setThemesLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      },

      () => {
        setThemesLoading(false);
      }
    );
  };
  const handleMoreOptionClick = (val, slug, text) => {
    if (val === "remove") {
      deleteCompanyStage(company);
    }

    if (slug) {
      handleReleaseDragNDrop({}, { val, name: text }, true);
    }
  };

  const setSelectedPartnership = (val) => {
    changeCompanyStage(
      fromThemes
        ? {
            partnership: val,
          }
        : { theme_company: { partnership: val } }
    );
  };

  const setSelectedInvestment = (val) => {
    setSelectedInv(val);
    changeCompanyStage(
      fromThemes ? { investment: val } : { theme_company: { investment: val } }
    );
  };

  const goToCompany = () => {
    if (company.is_product) {
      history.push(
        `/companies/${company.company_slug || company.company_id}/products/${
          company.slug
        }`
      );
      return;
    }
    history.push(`/companies/${company.slug || company.company_id}`);
  };

  useEffect(() => {
    if (company) {
      setSelectedInv(company.investment);
    }
  }, [company?.investment]);

  const parseDate = (date) => {
    let parsedDate = "-";
    try {
      // if(date === null) return "-";
      if (date) {
        parsedDate = format(new Date(date), " MMM do, yyyy p");
      }
    } catch (error) {
      console.log("-", error);
    }
    return parsedDate;
  };

  useEffect(() => {
    if (!fullfiledStages) {
      const filtredCompOptions = stages.map((stage) => {
        return {
          text:
            stage.name ||
            stage.council_relationship_stage_name ||
            stage.theme_investment_stage_name,
          val:
            stage.council_relationship_stage_id ||
            stage.theme_investment_stage_id,
          slug: stage.slug,
        };
      });
      if (fromThemes) {
        setCompOptions([
          { text: "Move To:", val: "moveTo" },
          ...filtredCompOptions,
        ]);
      } else {
        setCompOptions([
          ...compOptions,
          { text: "Move To:", val: "moveTo" },
          ...filtredCompOptions,
        ]);
      }
      setFullfiledStages(true);
    }
  }, [stages]);
  const prepareFileName = (name) => {
    if (name.length > 37) {
      return name.slice(0, 37) + " ... ";
    }
    return name;
  };
  return (
    company && (
      <>
        <div
          className={styles.stageCompanyWrp}
          onMouseOver={() => setHovered(true)}
        >
          <div className={styles.aboutCompanyBlock}>
            <div className={styles.iconCon}>
              <CompanyAvatar
                imgSrc={company.logo}
                name={company.name || company.company_name}
              />
            </div>
            <div className={styles.infoWrp}>
              <div>
                <div className={styles.title} onClick={goToCompany}>
                  <Tooltip
                    placement="bottom"
                    title={`${company.name || company.company_name}`}
                  >
                    <div>
                      {prepareFileName(company.name || company.company_name)}
                    </div>
                  </Tooltip>
                </div>
                <span className="text-muted small ml-2">
                  {company.is_product || company.resource_type === "product" ? (
                    <>Product</>
                  ) : (
                    <> </>
                  )}
                </span>
                <div className={styles.tagBlock}>
                  {selectedCouncil === "firmenich" && company.partnership && (
                    <>
                      <div className={styles.tagPartItem}>
                        Partnership
                        {company.partnership && company.investment && ", "}
                      </div>

                      <div className={styles.tagInvestItem}>
                        {company.investment && "Investment"}
                      </div>
                    </>
                  )}
                </div>
                <div>
                  <p className={styles.companiesNumber}>
                    Updated By:{" "}
                    {company.council_relationship_stage_last_changed_by || "-"}
                    <br />
                    {parseDate(
                      company.council_relationship_stage_last_changed_at
                    )}{" "}
                  </p>
                </div>
              </div>
              <div className={styles.iconMore}>
                <MoreOptionsWithCheckbox
                  options={compOptions}
                  className={styles.moreOptWrp}
                  onClick={handleMoreOptionClick}
                  selectedPartnership={company?.partnership}
                  selectedInvestment={selectedInvestment}
                  setSelectedPartnership={setSelectedPartnership}
                  setSelectedInvestment={setSelectedInvestment}
                  isProduct={
                    company.resource_type === "product" || company.is_product
                  }
                />
              </div>
            </div>
          </div>
          {fromThemes && (
            <div className={styles.companyInThemesList}>
              <div
                className={styles.rowNameBlock}
                onClick={() => handleExpand(company)}
              >
                <span>
                  {" "}
                  {council?.settings?.themes_display_name || "Themes"}{" "}
                </span>
                {companyToExpand ? (
                  <Icon
                    icon="icn-right-chevron"
                    {...xsSize}
                    className={`${styles.iconColor} ${styles.rotateIcon}`}
                  />
                ) : (
                  <Icon
                    icon="icn-right-chevron"
                    {...xsSize}
                    className={`${styles.iconColor} ${styles.spacing}`}
                  />
                )}
              </div>

              <div className={styles.rowOptions}>
                {themesLoading ? <LoadingComponent /> : null}
                {companyToExpand && (
                  <>
                    {themesData?.map((theme_included, index) => {
                      if (index < 4 || !hideMoreThemes) {
                        return (
                          <ThemesBlock
                            key={theme_included.theme_id}
                            sessionId={session?.id}
                            themeIncluded={{
                              ...theme_included.attributes,
                              ...theme_included,
                            }}
                          />
                        );
                      }
                    })}
                    {themesLoading === false && themesData.length === 0 ? (
                      <span>
                        There are no{" "}
                        {selectedCouncil?.settings?.themes_display_name ||
                          "Themes"}
                      </span>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    )
  );
};

export default memo(KanbanCompany);

import React, { useState } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import styles from "./voteIdea.module.scss";
import { revoteIdea, voteIdea } from "../idea.action";
import LoadingComponent from "../../primitives/loading/loading.component";
import Icon from "../../../common/icon/icon.component";
import { mdSize } from "../../../common/icon";

const VoteIdea = ({ idea, isProfileUse, canVote }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const {
    authReducer: { session },
  } = useSelector((state) => state);

  const handleVote = (value) => {
    if (!canVote) {
      enqueueSnackbar("Sorry, you are not allowed to vote!", {
        variant: "warning",
      });

      return;
    }

    const payload = {
      vote: {
        value,
        user_id: session.id,
        idea_id: idea.id,
      },
      enqueueSnackbar,
      cb: () => {
        setIsLoading(false);
      },
      isProfileUse,
    };

    if (idea.user_vote?.vote_id) {
      if (idea.user_vote?.value === value) {
        enqueueSnackbar(`Already ${value === 1 ? "Up voted" : "Down voted"}!`, {
          variant: "warning",
        });
        return;
      }
      payload.id = idea.user_vote.vote_id;
      setIsLoading(true);
      dispatch(revoteIdea(payload));
    } else {
      setIsLoading(true);
      dispatch(voteIdea(payload));
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loadingWrap}>
        <LoadingComponent hideString />
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center">
      <button
        type="button"
        className={classnames(
          styles.voteBtn,
          styles.upvote,
          idea.user_vote
            && idea.user_vote?.value > 0
            && styles.highlightedUpvote,
        )}
        title="Up vote"
        onClick={() => handleVote(1)}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Icon {...mdSize} icon="arrow-up2" />
        <span>{idea.total_up_votes}</span>
      </button>
      <button
        type="button"
        className={classnames(
          styles.voteBtn,
          idea.user_vote
            && idea.user_vote?.value < 0
            && styles.highlightedDownVote,
        )}
        title="Down vote"
        onClick={() => handleVote(-1)}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Icon {...mdSize} icon="arrow-down2" />
        <span>{idea.total_down_votes}</span>
      </button>
    </div>
  );
};

export default VoteIdea;

import {
  ADD_PRODUCT_CONTACT_SUCCESS,
  ADD_PRODUCT_OWNER_SUCCESS,
  ADD_PRODUCT_TO_IDEA_SUCCESS,
  ADD_PRODUCT_TO_LIST_SUCCESS,
  ADD_PRODUCT_TO_PROJECT_SUCCESS,
  ADD_PRODUCT_TO_THEME_SUCCESS,
  DELETE_PRODUCT_CONTACT_SUCCESS,
  DELETE_PRODUCT_OWNER_SUCCESS,
  EDIT_PRODUCT_CONTACT_SUCCESS,
  EDIT_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_SUCCESS,
  UPDATE_PRODUCT_DETAILS,
  PRODUCT_SET_DOWNLOAD_PARAMS, EDIT_PRODUCT_DOCS_SUCCESS,
} from "modules/companies/product/store/product.actions";

const initState = {
  productDetails: null,
  loading: true,
  productIsDownloading: false,
  productDownloadDetails: [],
};

const productReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EDIT_PRODUCT_DOCS_SUCCESS: {
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          documents: [...state.productDetails.documents, ...payload.response || []],
        }
      }
    }

    case PRODUCT_SET_DOWNLOAD_PARAMS: {
      return {
        ...state,
        productDownloadDetails: payload,
        productIsDownloading: !!payload.length,
      }
    }

    case UPDATE_PRODUCT_DETAILS: {
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          ...payload,
        }
      }
    }

    case ADD_PRODUCT_TO_IDEA_SUCCESS:
    case ADD_PRODUCT_TO_THEME_SUCCESS:
    case ADD_PRODUCT_TO_LIST_SUCCESS:
    case ADD_PRODUCT_TO_PROJECT_SUCCESS: {
      if (payload.response) {
        return {
          ...state,
          productDetails: {
            ...state.productDetails,
            ...payload?.response?.product || {},
          }
        };
      }
      break;
    }

    case ADD_PRODUCT_OWNER_SUCCESS: {
      if (payload.response) {
        return {
          ...state,
          productDetails: {
            ...state.productDetails,
            product_owners: [...state.productDetails.product_owners || [], ...payload?.response || []]
          }
        };
      }
      break;
    }

    case DELETE_PRODUCT_OWNER_SUCCESS: {
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          product_owners: state.productDetails.product_owners.filter(owner => owner.id !== payload.id),
        }
      };
    }

    case DELETE_PRODUCT_CONTACT_SUCCESS: {
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          product_contacts: state.productDetails.product_contacts.filter(contact => contact.id !== payload.id),
        }
      }
    }

    case EDIT_PRODUCT_CONTACT_SUCCESS: {
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          product_contacts: state.productDetails.product_contacts.map(contact => {
            if (contact.id === payload.response.id) {
              return {
                ...contact,
                ...payload.response,
              }
            }

            return contact;
          })
        }
      }
    }

    case ADD_PRODUCT_CONTACT_SUCCESS: {
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          product_contacts: [...state.productDetails.product_contacts || [], payload.response]
        }
      }
    }

    case EDIT_PRODUCT_DETAILS_SUCCESS: {
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          ...payload.response,
        }
      };
    }

    case GET_PRODUCT_DETAILS: {
      return {
        ...state,
        loading: true,
      }
    }

    case GET_PRODUCT_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        productDetails: payload.response,
      }
    }

    default:
      return state;
  }
}

export default productReducer;

import React, { useCallback, memo } from "react";

import NewCall from "./newCall/newCall";
import NewNote from "./newNote/index";
import NewEmail from "./newEmail/index";
import NewEvent from "./newEvent/index";
import NewMeeting from "./newMeeting/index";
import NewOther from "./newOther/index";
import NewProject from "./newProject/index";

const FieldsSelector = (props) => {
  const { activityType } = props;

  const drowActivityType = useCallback(() => {
    switch (activityType) {
      case "Call": {
        return <NewCall {...props} />;
      }
      case "Note": {
        return <NewNote {...props} />;
      }
      case "Email": {
        return <NewEmail {...props} />;
      }
      case "Event": {
        return <NewEvent {...props} />;
      }
      case "Meeting": {
        return <NewMeeting {...props} />;
      }
      case "Project": {
        return <NewProject {...props} />;
      }
      case "Other": {
        return <NewOther {...props} />;
      }
      default:
    }
  }, [activityType, props]);

  return <>{drowActivityType()}</>;
};

export default memo(FieldsSelector);

import React from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ProgressBar, ProjectState } from "../../../../primitives";
import styles from "./projects.module.scss";

const DesktopProjectRow = ({ project }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleRedirect = () => {
    if (!project) {
      return;
    }

    if (project.current_user_has_access) {
      history.push(`/projects/${project.id || project.project?.id}`);
    } else {
      enqueueSnackbar("You don't have access to this project.", {
        variant: "error",
      });
    }
  };

  return (
    <div
      className={
        project.current_user_has_access
          ? styles.gridRow
          : styles.disabledGridRow
      }
      onClick={handleRedirect}
    >
      <div className={styles.project}>
        {project.project?.name || project.name}
      </div>
      <div className={styles.lastActivity}>
        {project.project_last_activity?.details}
      </div>
      <div className={styles.progress}>
        <ProgressBar
          width="120px"
          tot={100}
          fill={project.total_task_progress || 0}
          showTot={false}
          progressValueStyle={styles.progressValueStyle}
          showPrecentage
        />
      </div>
      <div className={styles.status}>
        <ProjectState
          key={project.project?.status || project?.status}
          state={project.project?.status || project?.status}
        />
      </div>
    </div>
  );
};
export default DesktopProjectRow;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ClassNames from "classnames";
import { useHistory } from "react-router-dom";

import styles from "./companyHeaderLogo.module.scss";
import { setScrolledTop } from "../../../../modules/companies/companyMain/companyMain.action";
import { CompanyAvatar } from "../../../../modules/primitives";

const CompanyHeaderLogo = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    authReducer: { session },
    companiesReducer: {
      companyMainReducer: { isScrolled },
    },
  } = useSelector((state) => state);

  const onScrollEventFunc = () => {
    if (window.scrollY > 0 && !isScrolled) {
      dispatch(setScrolledTop(true));
    }
    if (window.scrollY === 0) {
      dispatch(setScrolledTop(false));
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScrollEventFunc);

    return () => {
      window.removeEventListener("scroll", onScrollEventFunc);
    };
  }, []);

  useEffect(() => {
    if (isScrolled) {
      dispatch(setScrolledTop(false));
    }
  }, [history.location.pathname]);

  return (
    <div className={styles.councilWrp}>
      <div
        className={ClassNames(
          styles.councilLogo,
          isScrolled && styles.councilSmallLogo,
          // isScrolled > 0 && styles.councilSmallLogo
        )}
      >
        <CompanyAvatar
          imgSrc={session.invited_company?.logo}
          name={session.invited_company?.name}
        />
      </div>
    </div>
  );
};

export default React.memo(CompanyHeaderLogo);

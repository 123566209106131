import React from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import classNames from "classnames";
import styles from "./sso-login.module.scss";
import { TextBox, Button } from "../../primitives";
import { initModel, validation } from "./sso-login.constants";

import {
  workOsProductionClientId,
  workOsStagingClientId,
  workOsTractionConnectionId,
  workOsProductionRedirectUri,
  workOsStagingRedirectUri,
  workOsLocalHostRedirectUri,
  workOsKyndrylConnectionId,
  workOsMerckConnectionId,
  workOsFirmenichConnectionId,
  workOsGskConnectionId,
  workOsIdbConnectionId,
  workOsFreddieMacConnectionId,
  workOsStarkConnectionId,
  workOsFordConnectionId,
} from "../../../common/constants";

const SsoLoginComponent = ({ kyndrylInstance }) => {
  const handleOnSubmit = (model) => {
    const isPRODEnv = window.location.host === "traction.network";
    const isStagingEnv = window.location.host === "staging.traction.network";
    const userEmail = model.email.toLowerCase();
    const domainName = userEmail.split("@")[1].includes("ford.com") ? "ford.com" : userEmail.split("@")[1];
    const tractionTag = domainName.includes("ford.com") ? "ford" : domainName.split(".")[0].toLowerCase();
    let workOsConnectionId;
    let workOsRedirectUri;
    let url;
    let workOsClientId;

    if (userEmail.includes("kyndryl.com")) {
      workOsConnectionId = workOsKyndrylConnectionId;
      // For testing purposes
    } else if (userEmail.includes("tractiontechnologypartners.com")) {
      workOsConnectionId = workOsTractionConnectionId;
      // For testing purposes
    } else if (userEmail.includes("stark.com")) {
      workOsConnectionId = workOsStarkConnectionId;
    } else if (["merck.com", "msd.com"].includes(domainName)) {
      workOsConnectionId = workOsMerckConnectionId;
    } else if (
      ["firmenich.com", "dsm-firmenich.com", "dsm.com"].includes(domainName)
    ) {
      workOsConnectionId = workOsFirmenichConnectionId;
    } else if (userEmail.includes("gsk.com")) {
      workOsConnectionId = workOsGskConnectionId;
    } else if (userEmail.includes("iadb.org")) {
      workOsConnectionId = workOsIdbConnectionId;
    } else if (userEmail.includes("freddiemac.com")) {
      workOsConnectionId = workOsFreddieMacConnectionId;
    } else if (["ford.com", "ford.com.tr"].includes(domainName)) {
      workOsConnectionId = workOsFordConnectionId;
    }

    if (isPRODEnv) {
      workOsRedirectUri = workOsProductionRedirectUri;
    } else if (isStagingEnv) {
      workOsRedirectUri = workOsStagingRedirectUri;
    } else {
      workOsRedirectUri = workOsLocalHostRedirectUri;
    }

    if (
      ["kyndryl.com", "gsk.com", "ford.com", "ford.com.tr"].includes(domainName)
    ) {
      workOsClientId = workOsProductionClientId;
    } else {
      workOsClientId = workOsStagingClientId;
    }

    localStorage.setItem("tractionTag", tractionTag);
    // localStorage.setItem("userEmail", userEmail);
    url = `https://api.workos.com/sso/authorize?client_id=${workOsClientId}&redirect_uri=${workOsRedirectUri}?workos=true&response_type=code&connection=${workOsConnectionId}`;

    window.open(url, "_self");
  };

  return (
    <div className={styles.loginWrp}>
      {kyndrylInstance ? (
        <div className={styles.ssoKyndrylHeader}>
          <img
            src="https://res.cloudinary.com/ttp/image/upload/v1643207838/councils/aaf9d6e2-d79f-4d4e-8cce-26b361f58557_logo.png"
            alt="Kyndryl"
          />
        </div>
      ) : null}
      <div className={styles.loginCon}>
        <h1 className={styles.loginTitle}>
          {kyndrylInstance ? "Kyndryl Traction " : ""}SSO Log in
        </h1>
        <Formik
          initialValues={initModel}
          validationSchema={validation}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            handleOnSubmit(values);
          }}
        >
          {({ handleSubmit, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.loginFormWrp}>
                <TextBox
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  className={styles.textBox}
                  formProps={formikprops}
                />

                <div className={styles.btnWrp}>
                  <Button className={styles.loginButton} type="submit">
                    Login
                  </Button>
                  {!kyndrylInstance ? (
                    <Link to="/login">
                      <div className={classNames("caption", styles.login)}>
                        Simple Login
                      </div>
                    </Link>
                  ) : null}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default React.memo(SsoLoginComponent);

import React from "react";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import styles from "./themes.module.scss";
import Icon from "../../../../../common/icon/icon.component";

const ThemeRowComponent = ({ theme }) => {
  const history = useHistory();
  const handleRedirect = () => {
    if (!theme) {
      return;
    }
    history.push({
      pathname: `/themes/individual_page/${theme.theme_id}/overview`,
      state: {},
    });
  };

  return (
    <div className={styles.gridRow} onClick={handleRedirect}>
      <div className={styles.project}>{theme.name}</div>
      <Tooltip title={theme?.description} placement="bottom">
        <div className={styles.description}>
          {theme?.description?.length > 120
            ? theme?.description?.slice(0, 120) + "..."
            : theme?.description || "-"}
        </div>
      </Tooltip>
      {theme?.current_user_favorite && (
        <Icon
          className={styles.favoriteEnable}
          icon="icn-subnav-ratings-fill"
        />
      )}

      <div className={styles.joinLink}>
        {theme?.current_user_member ? (
          <svg
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="24" height="24" rx="12" fill="#E3F9E5" />
            <path
              d="m15.375 8-4.533 4.658-2.217-2.278L7 12.05 10.841 16 17 9.67 15.375 8z"
              fill="#3F9142"
            />
          </svg>
        ) : (
          <span className={styles.stub}> </span>
        )}
      </div>
    </div>
  );
};
export default ThemeRowComponent;

import {
  THEMES_HOMEPAGE_GET_ATTEMPT,
  THEMES_HOMEPAGE_GET_SUCCESS,
  THEMES_HOMEPAGE_POST_ATTEMPT,
  THEMES_HOMEPAGE_POST_SUCCESS,
  THEMES_HOMEPAGE_REMOVE_ATTEMPT,
  THEMES_HOMEPAGE_REMOVE_SUCCESS,
} from "./themesHomePage.action";

const initState = {
  themesHomepageList: [],
  loading: false,
};

const themesHomepageReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case THEMES_HOMEPAGE_GET_ATTEMPT:
      return {
        ...state,
        loading: true,
      };
    case THEMES_HOMEPAGE_GET_SUCCESS:
      return {
        ...state,
        themesHomepageList: payload.response.data.map((theme) => ({
          ...theme.attributes,
        })),
        loading: false,
      };
    case THEMES_HOMEPAGE_POST_ATTEMPT:
      return {
        ...state,
        loading: true,
      };
    case THEMES_HOMEPAGE_POST_SUCCESS:
      return {
        ...state,
        themesHomepageList: payload.response,
        loading: false,
      };

    case THEMES_HOMEPAGE_REMOVE_ATTEMPT:
      return {
        ...state,
        loading: true,
      };
    case THEMES_HOMEPAGE_REMOVE_SUCCESS:
      return {
        ...state,
        themesHomepageList: payload.response,
        loading: false,
      };

    default:
      return state;
  }
};

export default themesHomepageReducer;

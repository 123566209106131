export const GET_HOME_FUNNEL_DATA = "GET_HOME_FUNNEL_DATA";
export const getHomeFunnelData = (payload) => ({
  type: GET_HOME_FUNNEL_DATA,
  payload,
});

export const GET_HOME_FUNNEL_DATA_SUCCESS = "GET_HOME_FUNNEL_DATA_SUCCESS";
export const getHomeFunnelDataSuccess = (payload) => ({
  type: GET_HOME_FUNNEL_DATA_SUCCESS,
  payload,
});

export const GET_HOME_FUNNEL_THEMES_DATA = "GET_HOME_FUNNEL_THEMES_DATA";
export const getHomeFunnelThemesData = (payload) => ({
  type: GET_HOME_FUNNEL_THEMES_DATA,
  payload,
});

export const GET_HOME_FUNNEL_THEMES_DATA_SUCCESS = "GET_HOME_FUNNEL_THEMES_DATA_SUCCESS";
export const getHomeFunnelThemesDataSuccess = (payload) => ({
  type: GET_HOME_FUNNEL_THEMES_DATA_SUCCESS,
  payload,
});

export const GET_HOME_FUNNEL_COUNTRY_DATA = "GET_HOME_FUNNEL_COUNTRY_DATA";
export const getHomeFunnelCountryData = (payload) => ({
  type: GET_HOME_FUNNEL_COUNTRY_DATA,
  payload,
});

export const GET_HOME_FUNNEL_COUNTRY_DATA_SUCCESS = "GET_HOME_FUNNEL_COUNTRY_DATA_SUCCESS";
export const getHomeFunnelCountryDataSuccess = (payload) => ({
  type: GET_HOME_FUNNEL_COUNTRY_DATA_SUCCESS,
  payload,
});

export const MY_COMPANIES_GET_ATTEMPT = "MY_COMPANIES_GET_ATTEMPT";
export const myCompaniesGet = () => ({ type: MY_COMPANIES_GET_ATTEMPT });

export const MY_COMPANIES_GET_SUCCESS = "MY_COMPANIES_GET_SUCCESS";
export const myCompaniesGetSuccess = (payload) => ({
  type: MY_COMPANIES_GET_SUCCESS,
  payload,
});

export const RESET_HOME_FUNNEL = "RESET_HOME_FUNNEL";
export const resetHomeFunnel = () => ({ type: RESET_HOME_FUNNEL });

export const GET_ALL_COMPANIES_BY_STATUS_ATTEMPT = "GET_ALL_COMPANIES_BY_STATUS_ATTEMPT";
export const getAllCompaniesByStatusAttempt = (payload) => ({
  type: GET_ALL_COMPANIES_BY_STATUS_ATTEMPT,
  payload,
});

export const GET_ALL_COMPANIES_BY_STATUS_SUCCESS = "GET_ALL_COMPANIES_BY_STATUS_SUCCESS";
export const getAllCompaniesByStatusSuccess = (payload) => ({
  type: GET_ALL_COMPANIES_BY_STATUS_SUCCESS,
  payload,
});

export const HOME_FUNNEL_CHART_SHOULD_RE_RENDER = "HOME_FUNNEL_CHART_SHOULD_RE_RENDER";
export const homeFunnelShouldReRender = (payload) => ({
  type: HOME_FUNNEL_CHART_SHOULD_RE_RENDER,
  payload,
});

export const HOME_FUNNEL_CHART_SET_SELECTED_TAGS = "HOME_FUNNEL_CHART_SET_SELECTED_TAGS";
export const homeFunnelSetSelectedTags = (payload) => ({
  type: HOME_FUNNEL_CHART_SET_SELECTED_TAGS,
  payload,
});

export const DELETE_PROJECT_FIELD = "DELETE_PROJECT_FIELD";
export const deleteProjectField = (payload) => ({
  type: DELETE_PROJECT_FIELD,
  payload,
});

export const DELETE_PROJECT_FIELD_SUCCESS = "DELETE_PROJECT_FIELD_SUCCESS";
export const deleteProjectFieldSuccess = (payload) => ({
  type: DELETE_PROJECT_FIELD_SUCCESS,
  payload,
});

export const ACTIVATE_FIELD_TOGGLE = "ACTIVATE_FIELD_TOGGLE";
export const activateFieldToggle = (payload) => ({
  type: ACTIVATE_FIELD_TOGGLE,
  payload,
});

export const ACTIVATE_FIELD_TOGGLE_SUCCESS = "ACTIVATE_FIELD_TOGGLE_SUCCESS";
export const activateFieldToggleSuccess = (payload) => ({
  type: ACTIVATE_FIELD_TOGGLE_SUCCESS,
  payload,
});

export const DELETE_FIELD_TOGGLE = "DELETE_FIELD_TOGGLE";
export const deleteFieldToggle = (payload) => ({
  type: DELETE_FIELD_TOGGLE,
  payload,
});

export const DELETE_FIELD_TOGGLE_SUCCESS = "DELETE_FIELD_TOGGLE_SUCCESS";
export const deleteFieldToggleSuccess = (payload) => ({
  type: DELETE_FIELD_TOGGLE_SUCCESS,
  payload,
});

export const UPDATE_PROJECT_FIELD = "UPDATE_PROJECT_FIELD";
export const updateProjectField = (payload) => ({
  type: UPDATE_PROJECT_FIELD,
  payload,
});

export const UPDATE_PROJECT_FIELD_SUCCESS = "UPDATE_PROJECT_FIELD_SUCCESS";
export const updateProjectFieldSuccess = (payload) => ({
  type: UPDATE_PROJECT_FIELD_SUCCESS,
  payload,
});

export const ADD_FIELD_TO_GROUP = "ADD_FIELD_TO_GROUP";
export const addFieldToGroup = (payload) => ({
  type: ADD_FIELD_TO_GROUP,
  payload,
});

export const ADD_FIELD_TO_GROUP_SUCCESS = "ADD_FIELD_TO_GROUP_SUCCESS";
export const addFieldToGroupSuccess = (payload) => ({
  type: ADD_FIELD_TO_GROUP_SUCCESS,
  payload,
});

export const GET_GROUP_FIELDS = "GET_GROUP_FIELDS";
export const getGroupFields = (payload) => ({
  type: GET_GROUP_FIELDS,
  payload,
});

export const UPDATE_GROUP_FIELD_DATA_ADMIN = "UPDATE_GROUP_FIELD_DATA_ADMIN";
export const updateGroupFieldData = (payload) => ({
  type: UPDATE_GROUP_FIELD_DATA_ADMIN,
  payload,
});

export const GET_GROUP_FIELDS_SUCCESS = "GET_GROUP_FIELDS_SUCCESS";
export const getGroupFieldsSuccess = (payload) => ({
  type: GET_GROUP_FIELDS_SUCCESS,
  payload,
});

export const UPDATE_PROJECT_GROUP = "UPDATE_PROJECT_GROUP";
export const updateProjectGroup = (payload) => ({
  type: UPDATE_PROJECT_GROUP,
  payload,
});

export const UPDATE_PROJECT_GROUP_SUCCESS = "UPDATE_PROJECT_GROUP_SUCCESS";
export const updateProjectGroupSuccess = (payload) => ({
  type: UPDATE_PROJECT_GROUP_SUCCESS,
  payload,
});

export const DELETE_PROJECT_GROUP_FIELD = "DELETE_PROJECT_GROUP_FIELD";
export const deleteProjectGroupField = (payload) => ({
  type: DELETE_PROJECT_GROUP_FIELD,
  payload,
});

export const DELETE_PROJECT_GROUP_FIELD_SUCCESS = "DELETE_PROJECT_GROUP_FIELD_SUCCESS";
export const deleteProjectGroupFieldSuccess = (payload) => ({
  type: DELETE_PROJECT_GROUP_FIELD_SUCCESS,
  payload,
});

export const DELETE_PROJECT_GROUP = "DELETE_PROJECT_GROUP";
export const deleteProjectGroup = (payload) => ({
  type: DELETE_PROJECT_GROUP,
  payload,
});

export const DELETE_PROJECT_GROUP_SUCCESS = "DELETE_PROJECT_GROUP_SUCCESS";
export const deleteProjectGroupSuccess = (payload) => ({
  type: DELETE_PROJECT_GROUP_SUCCESS,
  payload,
});

export const SUBMIT_PROJECT_GROUP = "SUBMIT_PROJECT_GROUP";
export const submitProjectGroup = (payload) => ({
  type: SUBMIT_PROJECT_GROUP,
  payload,
});

export const SUBMIT_PROJECT_GROUP_SUCCESS = "SUBMIT_PROJECT_GROUP_SUCCESS";
export const submitProjectGroupSuccess = (payload) => ({
  type: SUBMIT_PROJECT_GROUP_SUCCESS,
  payload,
});

export const SUBMIT_PROJECT_FIELD = "SUBMIT_PROJECT_FIELD";
export const submitProjectField = (payload) => ({
  type: SUBMIT_PROJECT_FIELD,
  payload,
});

export const SUBMIT_PROJECT_FIELD_SUCCESS = "SUBMIT_PROJECT_FIELD_SUCCESS";
export const submitProjectFieldSuccess = (payload) => ({
  type: SUBMIT_PROJECT_FIELD_SUCCESS,
  payload,
});

export const GET_PROJECTS_STAGE_FIELDS_ATTEMPT = "GET_PROJECTS_STAGE_FIELDS_ATTEMPT";
export const getProjectsStageFieldsAttempt = (payload) => ({
  type: GET_PROJECTS_STAGE_FIELDS_ATTEMPT,
  payload,
});

export const GET_PROJECTS_STAGE_FIELDS_ATTEMPT_SUCCESS = "GET_PROJECTS_STAGE_FIELDS_ATTEMPT_SUCCESS";
export const getProjectsFieldsStageAttemptSuccess = (payload) => ({
  type: GET_PROJECTS_STAGE_FIELDS_ATTEMPT_SUCCESS,
  payload,
});

export const UPDATE_PROJECT_WIZARD_STEPS = "UPDATE_PROJECT_WIZARD_STEPS";
export const updateProjectWizardSteps = (payload) => ({
  type: UPDATE_PROJECT_WIZARD_STEPS,
  payload,
});

export const GET_PROJECT_WIZARD_ATTEMPT = "GET_PROJECT_WIZARD_ATTEMPT";
export const getProjectWizardAttempt = (payload) => ({
  type: GET_PROJECT_WIZARD_ATTEMPT,
  payload,
});

export const GET_PROJECT_WIZARD_ATTEMPT_SUCCESS = "GET_PROJECT_WIZARD_ATTEMPT_SUCCESS";
export const getProjectWizardAttemptSuccess = (payload) => ({
  type: GET_PROJECT_WIZARD_ATTEMPT_SUCCESS,
  payload,
});

export const DELETE_WIZARD_STEP = "DELETE_WIZARD_STEP";
export const deleteWizardStep = (payload) => ({
  type: DELETE_WIZARD_STEP,
  payload,
});

export const DELETE_WIZARD_STEP_SUCCESS = "DELETE_WIZARD_STEP_SUCCESS";
export const deleteWizardStepSuccess = (payload) => ({
  type: DELETE_WIZARD_STEP_SUCCESS,
  payload,
});

export const GET_PROJECTS_GROUPS_ATTEMPT = "GET_PROJECTS_GROUPS_ATTEMPT";
export const getProjectsGroupsAttempt = (payload) => ({
  type: GET_PROJECTS_GROUPS_ATTEMPT,
  payload,
});

export const GET_PROJECTS_GROUPS_ATTEMPT_SUCCESS = "GET_PROJECTS_GROUPS_ATTEMPT_SUCCESS";
export const getProjectsGroupsAttemptSuccess = (payload) => ({
  type: GET_PROJECTS_GROUPS_ATTEMPT_SUCCESS,
  payload,
});

export const GET_PROJECTS_FIELDS_ATTEMPT = "GET_PROJECTS_FIELDS_ATTEMPT";
export const getProjectsFieldsAttempt = (payload) => ({
  type: GET_PROJECTS_FIELDS_ATTEMPT,
  payload,
});

export const GET_PROJECTS_FIELDS_ATTEMPT_SUCCESS = "GET_PROJECTS_FIELDS_ATTEMPT_SUCCESS";
export const getProjectsFieldsAttemptSuccess = (payload) => ({
  type: GET_PROJECTS_FIELDS_ATTEMPT_SUCCESS,
  payload,
});

export const GET_STAGES_TEMPLATE_ATTEMPT = "GET_STAGES_TEMPLATE_ATTEMPT";
export const getStagesTemplateAttempt = (payload) => ({
  type: GET_STAGES_TEMPLATE_ATTEMPT,
  payload,
});

export const GET_STAGES_TEMPLATE_SUCCESS = "GET_STAGES_TEMPLATE_SUCCESS";
export const getStagesTemplateSuccess = (payload) => ({
  type: GET_STAGES_TEMPLATE_SUCCESS,
  payload,
});

export const POST_STAGE_TEMPLATE_ATTEMPT = "POST_STAGE_TEMPLATE_ATTEMPT";
export const postStageTemplateAttempt = (payload) => ({
  type: POST_STAGE_TEMPLATE_ATTEMPT,
  payload,
});

export const POST_STAGE_TEMPLATE_SUCCESS = "POST_STAGE_TEMPLATE_SUCCESS";
export const postStageTemplateSuccess = (payload) => ({
  type: POST_STAGE_TEMPLATE_SUCCESS,
  payload,
});

export const GET_STAGE_TEMPLATE_BY_ID_ATTEMPT = "GET_STAGE_TEMPLATE_BY_ID_ATTEMPT";
export const getStageTemplateByIdAttempt = (payload) => ({
  type: GET_STAGE_TEMPLATE_BY_ID_ATTEMPT,
  payload,
});

export const GET_STAGE_TEMPLATE_BY_ID_SUCCESS = "GET_STAGE_TEMPLATE_BY_ID_SUCCESS";
export const getStageTemplateByIdSuccess = (payload) => ({
  type: GET_STAGE_TEMPLATE_BY_ID_SUCCESS,
  payload,
});

export const PATCH_STAGE_TEMPLATE_BY_ID_ATTEMPT = "PATCH_STAGE_TEMPLATE_BY_ID_ATTEMPT";
export const patchStageTemplateByIdAttempt = (payload) => ({
  type: PATCH_STAGE_TEMPLATE_BY_ID_ATTEMPT,
  payload,
});

export const PATCH_STAGE_TEMPLATE_BY_ID_SUCCESS = "PATCH_STAGE_TEMPLATE_BY_ID_SUCCESS";
export const patchStageTemplateByIdSuccess = (payload) => ({
  type: PATCH_STAGE_TEMPLATE_BY_ID_SUCCESS,
  payload,
});

export const DELETE_STAGE_TEMPLATE_BY_ID_ATTEMPT = "DELETE_STAGE_TEMPLATE_BY_ID_ATTEMPT";
export const deleteStageTemplateByIdAttempt = (payload) => ({
  type: DELETE_STAGE_TEMPLATE_BY_ID_ATTEMPT,
  payload,
});

export const DELETE_STAGE_TEMPLATE_BY_ID_SUCCCESS = "DELETE_STAGE_TEMPLATE_BY_ID_SUCCCESS";
export const deleteStageTemplateByIdSuccess = (payload) => ({
  type: DELETE_STAGE_TEMPLATE_BY_ID_SUCCCESS,
  payload,
});

export const GET_STAGE_ATTEMPT = "GET_STAGE_ATTEMPT";
export const getStageAttempt = (payload) => ({
  type: GET_STAGE_ATTEMPT,
  payload,
});

export const GET_STAGE_SUCCESS = "GET_STAGE_SUCCESS";
export const getStageSuccess = (payload) => ({
  type: GET_STAGE_SUCCESS,
  payload,
});

export const POST_STAGE_ATTEMPT = "POST_STAGE_ATTEMPT";
export const postStageAttempt = (payload) => ({
  type: POST_STAGE_ATTEMPT,
  payload,
});

export const POST_STAGE_SUCCESS = "POST_STAGE_SUCCESS";
export const postStageSuccess = (payload) => ({
  type: POST_STAGE_SUCCESS,
  payload,
});

export const GET_STAGE_BY_ID_ATTEMPT = "GET_STAGE_BY_ID_ATTEMPT";
export const getStageByIdAttempt = (payload) => ({
  type: GET_STAGE_BY_ID_ATTEMPT,
  payload,
});

export const GET_STAGE_BY_ID_SUCCESS = "GET_STAGE_BY_ID_SUCCESS";
export const getStageByIdSuccess = (payload) => ({
  type: GET_STAGE_BY_ID_SUCCESS,
  payload,
});

export const PATCH_STAGE_BY_ID_ATTEMPT = "PATCH_STAGE_BY_ID_ATTEMPT";
export const patchStageByIdAttempt = (payload) => ({
  type: PATCH_STAGE_BY_ID_ATTEMPT,
  payload,
});

export const PATCH_STAGE_BY_ID_SUCCESS = "PATCH_STAGE_BY_ID_SUCCESS";
export const patchStageByIdSuccess = (payload) => ({
  type: PATCH_STAGE_BY_ID_SUCCESS,
  payload,
});

export const PUT_STAGE_BY_ID_ATTEMPT = "PUT_STAGE_BY_ID_ATTEMPT";
export const putStageByIdAttempt = (payload) => ({
  type: PATCH_STAGE_BY_ID_SUCCESS,
  payload,
});

export const PUT_STAGE_BY_ID_SUCCESS = "PUT_STAGE_BY_ID_SUCCESS";
export const putStageByIdSuccess = (payload) => ({
  type: PUT_STAGE_BY_ID_SUCCESS,
  payload,
});

export const DELETE_STAGE_BY_ID_ATTEMPT = "DELETE_STAGE_BY_ID_ATTEMPT";
export const deleteStageByIdAttempt = (payload) => ({
  type: DELETE_STAGE_BY_ID_ATTEMPT,
  payload,
});

export const DELETE_STAGE_BY_ID_SUCCESS = "DELETE_STAGE_BY_ID_SUCCESS";
export const deleteStageByIdSuccess = (payload) => ({
  type: DELETE_STAGE_BY_ID_SUCCESS,
  payload,
});

export const POST_TASK_ATTEMPT = "POST_TASK_ATTEMPT";
export const postTaskAttempt = (payload) => ({
  type: POST_TASK_ATTEMPT,
  payload,
});

export const POST_TASK_SUCCESS = "POST_TASK_SUCCESS";
export const postTaskSuccess = (payload) => ({
  type: POST_TASK_SUCCESS,
  payload,
});

export const DELETE_TASK_ATTEMPT = "DELETE_TASK_ATTEMPT";
export const deleteTaskAttempt = (payload) => ({
  type: DELETE_TASK_ATTEMPT,
  payload,
});

export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const deleteTaskSuccess = (payload) => ({
  type: DELETE_TASK_SUCCESS,
  payload,
});

export const PATCH_TASK_ATTEMPT = "PATCH_TASK_ATTEMPT";
export const patchTaskAttempt = (payload) => ({
  type: PATCH_TASK_ATTEMPT,
  payload,
});

export const PATCH_TASK_SUCCESS = "PATCH_TASK_SUCCESS";
export const patchTaskSuccess = (payload) => ({
  type: PATCH_TASK_SUCCESS,
  payload,
});

export const CATCH_ERROR_SUCCESS = "CATCH_ERROR_SUCCESS";
export const catchErrorSuccess = (payload) => ({
  type: CATCH_ERROR_SUCCESS,
  payload,
});

export const POST_TASK_DOCUMENT_ATTEMPT = "POST_TASK_DOCUMENT_ATTEMPT";
export const postTaskDocumentAttempt = (payload) => ({
  type: POST_TASK_DOCUMENT_ATTEMPT,
  payload,
});

export const POST_TASK_DOCUMENT_SUCCESS = "POST_TASK_DOCUMENT_SUCCESS";
export const postTaskDocumentSuccess = (payload) => ({
  type: POST_TASK_DOCUMENT_SUCCESS,
  payload,
});

export const PATCH_TASK_DOC_NAME_ATTEMPT = "PATCH_TASK_DOC_NAME_ATTEMPT";
export const patchTaskDocNameAttempt = (payload) => ({
  type: PATCH_TASK_DOC_NAME_ATTEMPT,
  payload,
});

export const PATCH_TASK_DOC_NAME_SUCCESS = "PATCH_TASK_DOC_NAME_SUCCESS";
export const patchTaskDocNameSuccess = (payload) => ({
  type: PATCH_TASK_DOC_NAME_SUCCESS,
  payload,
});

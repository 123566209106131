import ChartDataLabels from "chartjs-plugin-datalabels";
import { lightenDarkenColor } from "../../../../common/helper";

const makeTotalConfig = (total) => {
  if (!total) {
    return {};
  }

  return {
    elements: {
      center: {
        text: `Total: ${total}`,
        color: '#323f4b',
        fontStyle: 'Arial',
        sidePadding: 20,
        minFontSize: 16,
        lineHeight: 21
      }
    },
  }
}

const makeDataSet = (renderPieInstance) => {
  if (renderPieInstance) {
    return {}
  }

  return {
    doughnut: {
      cutout: '75%'
    },
  }
}

export const makeDoughnutChartOptions = (total, renderPieInstance) => ({
  ...makeTotalConfig(total),
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      color: (ctx) => {
        const colorByIdx = ctx.dataset.backgroundColor[ctx.dataIndex] || "#323f4b";
        return lightenDarkenColor(colorByIdx, -50);
      },
      display: false, // "auto",
      clamp: true,
      formatter: (value, ctx) => {
        return value
      },
    }
  },
  title: {
    display: false,
  },
  layout: {
    padding: {
      bottom: 0,
    },
  },
  datasets: makeDataSet(),
  responsive: true,
  maintainAspectRatio: false,
});

export const makePieChartOptions = (total) => ({
  ...makeTotalConfig(total),
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      color: (ctx) => {
        // const colorByIdx = ctx.dataset.backgroundColor[ctx.dataIndex] || "#323f4b";
        // return lightenDarkenColor(colorByIdx, -50);
        return 'white';
      },
      display: 'auto', // "auto",
      clamp: true,
      formatter: (value, ctx) => {
        return value
      },
    }
  },
  title: {
    display: false,
  },
  layout: {
    padding: {
      bottom: 0,
    },
  },
  datasets: makeDataSet(true),
  responsive: true,
  maintainAspectRatio: false,
});

export const makeDoughnutConfig = (chartData, total, renderPieInstance) => {
  const { data_sets } = chartData;
  return {
    data: {
      labels: chartData.labels,
      datasets: [{
        backgroundColor: data_sets.background_color,
        data: data_sets.data,
        customDataPerIndex: data_sets.custom_data_per_index,
        hover_offset: 4,
      }],
    },
    options: renderPieInstance ? makePieChartOptions (total) : makeDoughnutChartOptions(total),
    plugins: [ChartDataLabels],
  };
}

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { useSnackbar } from "notistack";

import styles from "./internalPeople.module.scss";
import { DropSelect, Pagination } from "../../primitives";
import MemberComponent from "../member";
import { pageSettings } from "../peopleMain/peopleMain.constant";
import ErrorBoundary from "../../../common/components/errorBoundary";
import { peopleListTeamsGet } from "../../../common/actions/common.action";
import DiscussionDetails from "../../../common/components/discussionDetails";
import { setSelectedDiscussion } from "../../../common/actions/discussions.action";
import NewDiscussion from "../../../common/components/newDiscussion/newDiscussion.component";
import { appModelSettingSet } from "application/app.action";
import { httpPost, pageMeta } from "../../../common/httpCall";
import { queryStringUrlReplacement } from "../../../common/helper";

const InternalPeopleComponent = ({ searchValue }) => {
  const {
    appReducer: { modalSetting },
    councilReducer: { selectedCouncil },
    peopleReducer: {
      internalPeopleReducer: { teamList },
    },
    discussionsReducer: { selectedDiscussion },
  } = useSelector((state) => state);

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [dropTeamList, setDropTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [pageSetting, setPageSetting] = useState(null);
  const [showDetailsWindow, setShowDetailsWindow] = useState(false);
  const [internalPeople, setInternalPeople] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [searchForPeopleFlag, setSearchForPeopleFlag] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isFocus, setIsFocus] = useState(false);
  const [totalAllItems, setTotalAllItems] = useState(0);
  const [currentTeamId, setCurrentTeamId] = useState(null);
  const [reqIsActive, setReqIsActive] = useState(false);

  useEffect(() => {
    if (location.pathname && !reqIsActive) {
      const pathNameId = location.pathname.split("/")[3];
      if (originalData.length === 0) {
        const page = pageSetting?.current || location.state?.page;

        requestNewData(page, searchValue);
      } else {
        setInternalPeople(originalData);
        setPageSetting((prev) => ({
          ...prev,
          total: totalAllItems,
        }));
      }

      setSelectedTeam(pathNameId);
    }
  }, [location.pathname, originalData]);

  useEffect(() => {
    const settings = pageSettings(20);

    if (location?.state?.page) {
      setPageSetting({
        ...settings,
        current: location.state.page,
      });

      return;
    }

    setPageSetting({
      ...settings,
      current: 1,
    });
  }, [location?.state?.page]);

  useEffect(() => {
    setSearchForPeopleFlag(true);
    if (!pageSetting || reqIsActive) {
      return;
    }
    requestNewData(1, searchValue);
    return () => null;
  }, [pageSetting?.current, searchValue]);

  useEffect(() => {
    if (!reqIsActive && searchForPeopleFlag) {
      requestNewData(1, searchValue);
    }
  }, [reqIsActive]);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        peopleListTeamsGet({ councilId: selectedCouncil.id, enqueueSnackbar })
      );
    }
    return () => null;
  }, [selectedCouncil]);

  useEffect(() => {
    if (teamList) {
      setDropTeamList([{ id: "all", name: "All Members" }, ...teamList]);
      // if (teamList.length > 0) setSelectedTeam('all');
    }
    return () => null;
  }, [teamList]);

  const requestNewData = (page, query = "*", teamId = currentTeamId) => {
    setReqIsActive(true);
    setShowLoading(true);
    const url = `councils/users/search?page=${page || 1}&items=20`;
    const data = {
      query,
      search: {
        query,
        teams_ids: [teamId === "all" ? null : teamId],
        council_roles: [
          "ttp_administrator",
          "council_administrator",
          "standard",
          "viewer",
        ],
      },
    };

    httpPost({
      call: url,
      data,
    })
      .pipe()
      .subscribe((res) => {
        const pagemeta = pageMeta(res);

        setPageSetting((prev) => ({
          ...prev,
          total: pagemeta.total,
        }));
        setTotalAllItems(pagemeta.total);

        setInternalPeople(res.response || []);
        setOriginalData(res.response || []);

        setShowLoading(false);
        setSearchForPeopleFlag(false);
        const timeout = setTimeout(() => {
          setReqIsActive(false);
          clearTimeout(timeout);
        }, 1000);
      });
  };

  const handleOptionSelect = ({ val }) => {
    if (val.length < 1) {
      return 0;
    }
    setCurrentTeamId(val);
    requestNewData(pageSetting?.current, "*", val);
    if (isFocus) {
      setIsFocus(false);
      history.push(`/people/internal/${val}`);
    }
  };

  const handlePagingChange = useCallback(
    (page) => {
      if (page === pageSetting?.current || reqIsActive) {
        return;
      }

      requestNewData(page, searchValue, undefined, "handlePagingChange");

      const search = queryStringUrlReplacement(
        history.location.search,
        "page",
        page
      );

      history.push({
        pathname: `/people/internal/${location.pathname.split("/")[3]}`,
        search,
        state: {
          ...history.location?.state,
          page,
        },
      });
    },
    [searchValue, pageSetting, reqIsActive]
  );

  const startNewDiscussion = (user) => {
    const customSettings = {
      title: "Start a New Private Discussion",
      show: true,
      height: "300",
      width: "540",
      component: (
        <NewDiscussion type="Council" isPrivate hideTeam selectedUser={user} />
      ),
    };
    dispatch(
      appModelSettingSet({
        ...modalSetting,
        ...customSettings,
      })
    );
  };

  const handleShow = (details) => {
    if (details.showNewModal) {
      startNewDiscussion(details);
      return;
    }

    dispatch(setSelectedDiscussion({ ...details, ...details.discussion }));
    setShowDetailsWindow(true);
  };

  const handleClose = () => {
    setShowDetailsWindow(false);
    dispatch(setSelectedDiscussion({}));
  };

  return (
    <div className={styles.contentWrp}>
      <ErrorBoundary component="People Internal">
        {showDetailsWindow && (
          <DiscussionDetails
            handleClose={handleClose}
            details={selectedDiscussion}
          />
        )}
        <div className={styles.dropWrp}>
          <label>Filter by team</label>
          <div className={styles.dropCon}>
            <DropSelect
              onChange={(opt) => {
                if (opt.val === "all" && currentTeamId === null) {
                  return;
                }
                handleOptionSelect(opt);
              }}
              placeholder="Filter by team"
              labelField="name"
              valueField="id"
              searchBy="name"
              disabled={reqIsActive}
              options={dropTeamList}
              SelecedValues={selectedTeam}
              onFocus={() => setIsFocus(true)}
            />
          </div>
        </div>
        <div className={styles.tabInner}>
          {showLoading ? (
            <div className="d-flex mt-4 mx-3 justify-content-between flex-wrap w-100">
              {Array.from({ length: 8 }).map((skeleton, idx) => (
                <div key={idx} className={styles.skeletonBlock}>
                  <Skeleton
                    animation="wave"
                    variant="circle"
                    width={40}
                    height={40}
                  />
                  <div className={styles.skeletonText}>
                    {" "}
                    <Skeleton animation="wave" height={15} />
                    <Skeleton animation="wave" height={15} width="40%" />
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {!showLoading && internalPeople && internalPeople.length === 0 ? (
            <h3 className="d-flex mt-4 justify-content-center w-100">
              No results...
            </h3>
          ) : null}
          {!showLoading && internalPeople && internalPeople.length
            ? internalPeople.map((user, index) => (
                <MemberComponent
                  key={`${user.id}_${index}`}
                  user={user}
                  handleShow={handleShow}
                />
              ))
            : null}
          <div
            className={classNames(
              styles.paginWrp,
              showLoading || !internalPeople || !internalPeople.length
                ? "d-none"
                : "d-flex"
            )}
          >
            {pageSetting && (
              <div className={styles.paginCon}>
                <Pagination {...pageSetting} onChange={handlePagingChange} />
              </div>
            )}
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default React.memo(InternalPeopleComponent);

import React from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import styles from "./companyLogo.module.scss";
import { CompanyAvatar } from "../../../../primitives";
import ChooseImg from "../chooseImg/chooseImg.component";
import { updateClaimProfileAttempt } from "../../../store/claimProfileActions";
import { setUser } from "../../../../auth/auth.action";

const CompanyLogo = ({ session, selectedCouncil }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleSaveNewLogo = (logo) => {
    const data = {
      logo,
    };

    dispatch(
      updateClaimProfileAttempt({
        companyId: session.invited_company.id,
        councilId: selectedCouncil.id,
        data,
        enqueueSnackbar,
        callBack: (res) => {
          if (res?.logo !== session.invited_company.logo) {
            const newSession = {
              ...session,
              invited_company: {
                ...session.invited_company,
                logo: res.logo,
              },
            };
            dispatch(setUser(newSession));
          }
        },
      })
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <CompanyAvatar
          imgSrc={session.invited_company?.logo}
          name={session.invited_company?.name}
        />
      </div>
      <ChooseImg
        uploadDirName={`srm/${selectedCouncil?.id}/company/${session?.invited_company?.id}`}
        image={session?.invited_company?.logo}
        onChange={handleSaveNewLogo}
      />
    </div>
  );
};

export default React.memo(CompanyLogo);

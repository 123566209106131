import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useSelector } from "react-redux";

import styles from "./investment.module.scss";
import { CheckBox, CheckType } from "../../../../../primitives";
import { Icon, smSize } from "../../../../../../common/icon";
import {
  queryStringUrlReplacement,
  queryParamStringToArray,
  useQuery,
} from "../../../../../../common/helper";

const InvestmentFilter = ({ expanded, handleExpand, disabled }) => {
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const history = useHistory();
  const useQueryStatus = useQuery().get("investmentStages");

  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (disabled) {
      setCollapsed(false);
      return
    }

    setCollapsed(expanded);

    return () => null;
  }, [expanded, disabled]);

  useEffect(() => {
    let newStatuses = [];

    if (selectedCouncil.theme_investment_stages?.length) {
      newStatuses = selectedCouncil.theme_investment_stages.map((s) => ({
        ...s,
        selected: false,
      }));

      setItems(newStatuses);
    }

    if (useQueryStatus) {
      const arr = queryParamStringToArray(useQueryStatus);
      let isAllSelected = true;
      const newItems = newStatuses.map((item) => {
        const itemIsSelected = arr.includes(item.id);
        return {
          ...item,
          selected: itemIsSelected,
        };
      });

      newItems.forEach((item) => {
        if (!item.selected) {
          isAllSelected = false;
        }
      });
      setSelectAll(isAllSelected);
      setItems(newItems);
    }

    return () => null;
  }, [useQueryStatus]);

  const handleCollapseClick = () => {
    if (disabled) {
      return;
    }

    if (!collapsed) {
      handleExpand("investmentStages");
    }
    setCollapsed(!collapsed);
  };

  const handleOnChange = (val, status, allItems, isSelectedAll) => {
    let investmentStagesIds = [""];
    if (allItems) {
      if (isSelectedAll) {
        investmentStagesIds = items.reduce(
          (acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`,
          ""
        );
      } else {
        investmentStagesIds = allItems
          .filter((item) => item.selected)
          .reduce((acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`, "");
      }
    } else {
      investmentStagesIds = items
        .map((s) => {
          if (s.id === status.id) {
            return {
              ...s,
              selected: val,
            };
          }
          return s;
        })
        .filter((item) => item.selected)
        .reduce((acc, elem) => `${acc}${acc.length ? "," : ""}${elem.id}`, "");
    }

    let search = queryStringUrlReplacement(
      history.location.search,
      "investmentStages",
      investmentStagesIds
    );
    search = queryStringUrlReplacement(search, "page", 1);
    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        investmentStages: investmentStagesIds,
        page: 1,
      },
    });
    if (!allItems) {
      setItems(
        items.map((stage) => {
          if (stage.id === status.id) {
            return {
              ...stage,
              selected: val,
            };
          }
          return stage;
        })
      );
      setSelectAll(false);
    }
  };

  const handleSelectAll = (val) => {
    setSelectAll(val);
    const allItems = items.map((item) => ({
      ...item,
      selected: val,
    }));
    setItems(allItems);
    handleOnChange(true, {}, allItems, val);
  };

  return (
    <div className={`${styles.itemWrapper} ${disabled ? styles.disabled : ''}`} id="investmentStages">
      <div className={styles.itemTitle} onClick={handleCollapseClick}>
        <span>Investment </span>
        <Icon
          {...smSize}
          icon="icn-table-down-chevron"
          className={classNames(styles.icon, !collapsed && styles.collapse)}
          onClick={handleCollapseClick}
        />
      </div>
      {collapsed ? (
        <>
          <div className={styles.filterRowSelectAll}>
            <CheckBox
              checkType={CheckType.BLUE}
              isChecked={selectAll}
              onChange={(val) => handleSelectAll(val)}
            />
            <span onClick={() => handleOnChange()}>Select All</span>
          </div>
          {items?.map((stage) => (
            <div key={stage.name} className={styles.filterRow}>
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={stage.selected}
                onChange={(val) => handleOnChange(val, stage)}
              />
              <span onClick={() => handleOnChange(!stage.selected, stage)}>{stage.name}</span>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};

export default React.memo(InvestmentFilter);

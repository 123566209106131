import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import { Pagination } from "../../../primitives";
import styles from "../projectsMain.module.scss";
import "rc-pagination/assets/index.css";
import ProjectRowComponent from "../projectRow/projectRow.component";
import CatalogProjectRowComponent from "../projectRow/catalogProjectRow.component";
import TableHeaderComponent from "./tableHeader.component";

const AllTabComponent = ({
  selectedTab,
  makeSortingConfig,
  drawSortingIcon,
  pageSetting,
  handlePagingChange,
}) => {
  const history = useHistory();
  const {
    projectsReducer: {
      projectsMainReducer: { projectList },
    },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.grid, styles[`${selectedTab}`])}>
        {selectedCouncil?.traction_tag === "ford" ? (
          <TableHeaderComponent
            makeSortingConfig={makeSortingConfig}
            drawSortingIcon={drawSortingIcon}
          />
        ) : (
          <div className={styles.gridHeader}>
            <div
              className={`${
                selectedCouncil?.traction_tag === "firmenich"
                  ? styles.projectFull
                  : styles.project
              }`}
              onClick={() => makeSortingConfig("name_downcase")}
            >
              Project
              {drawSortingIcon("name_downcase")}
            </div>
            {selectedCouncil?.traction_tag !== "firmenich" ? (
              <div
                className={styles.activity}
                onClick={() => makeSortingConfig("activity")}
              >
                Activity
                {drawSortingIcon("activity")}
              </div>
            ) : null}
            {selectedCouncil?.traction_tag === "ford" ? (
              <>
                <div
                  className={`${styles.plantName} `}
                  onClick={() => makeSortingConfig("plant_name")}
                >
                  Plant
                  {drawSortingIcon("plant_name")}
                </div>
                <div
                  className={`${styles.area} `}
                  onClick={() => makeSortingConfig("areas_names")}
                >
                  {"Area"}
                  {drawSortingIcon("areas_names")}
                </div>
              </>
            ) : null}
            <div
              className={styles.activityDate}
              onClick={() => makeSortingConfig("activity")}
            >
              {selectedCouncil?.traction_tag === "firmenich"
                ? "Start Date"
                : "Date"}
              {drawSortingIcon("activity")}
            </div>
            <div
              className={styles.owner}
              onClick={() => makeSortingConfig("owner")}
            >
              Owner
              {drawSortingIcon("owner")}
            </div>
            <div
              className={styles.company}
              onClick={() => makeSortingConfig("companies_count")}
            >
              {selectedCouncil && selectedCouncil.name === "GSK"
                ? "Organizations"
                : "Companies"}
              {drawSortingIcon("companies_count")}
            </div>
            <div
              className={classNames(
                styles.access,
                selectedTab === "my_projects" && "hide"
              )}
              onClick={() => makeSortingConfig("access")}
            >
              Access
              {drawSortingIcon("access")}
            </div>
            <div
              className={styles.progress}
              onClick={() => makeSortingConfig("progress")}
            >
              Progress
              {drawSortingIcon("progress")}
            </div>
            <div
              className={styles.progress}
              onClick={() => makeSortingConfig("priority")}
            >
              Priority
              {drawSortingIcon("priority")}
            </div>
            <div
              className={styles.status}
              onClick={() => makeSortingConfig("status")}
            >
              Status
              {drawSortingIcon("status")}
            </div>
          </div>
        )}

        <div className={styles.gridBody}>
          {projectList[0] ? (
            projectList.map((project) => (
              <div key={project?.id}>
                {selectedCouncil?.traction_tag === "ford" ? (
                  <CatalogProjectRowComponent
                    key={project.id}
                    project={project}
                    tab={selectedTab}
                    selectedCouncil={selectedCouncil}
                    history={history}
                  />
                ) : (
                  <ProjectRowComponent
                    key={project.id}
                    project={project}
                    tab={selectedTab}
                    selectedCouncil={selectedCouncil}
                    history={history}
                  />
                )}
              </div>
            ))
          ) : (
            <div className={styles.noProjects}>
              <p>There is no projects.</p>
            </div>
          )}
        </div>
        <div
          className={`${styles.paginWrp} ${
            projectList.length < 10 && pageSetting?.current === 1
              ? "d-none"
              : ""
          }`}
        >
          <div className={styles.paginCon}>
            <Pagination {...pageSetting} onChange={handlePagingChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AllTabComponent);

export const preSelectedCompanyValues = () => [
  {
    text: "first_name",
    value: "string",
  },
  {
    text: "last_name",
    value: "string",
  },
  {
    text: "email",
    value: "string",
  },
  {
    text: "title",
    value: "string",
  },
  {
    text: "mobile",
    value: "string",
  },
];

export const preSelectedSalesForceValues = () => [
  {
    text: "FirstName",
    value: "string",
  },
  {
    text: "LastName",
    value: "string",
  },
  {
    text: "Email",
    value: "email",
  },
  {
    text: "Title",
    value: "string",
  },
  {
    text: "MobilePhone",
    value: "string",
  },
];

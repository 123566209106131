import {
  IDEAS_GET_ATTEMPT,
  IDEAS_GET_SUCCESS,
  FORMS_GET_ATTEMPT,
  FORMS_GET_SUCCESS,
  FORMS_ADD_SUCCESS,
  SECTION_DELETE_ATTEMPT,
  SECTION_DELETE_SUCCESS,
  SECTION_ADD_SUCCESS,
  FORMS_PATCH_SUCCESS,
  FORMS_DELETE_SUCCESS,
  SECTION_PATCH_SUCCESS,
  SECTION_PATCH_ATTEMPT,
  ADD_LAST_STEP_WORKED_ON,
  CATCH_ERROR_SUCCESS,
  POST_SEARCH_IDEAS_ATTEMPT,
  IDEAS_DELETE_ATTEMPT,
  IDEAS_DELETE_SUCCESS,
  FORM_GET_SUCCESS,
  SET_IDEA_FORM_FIELDS,
  CLEAR_CURRENT_FORM,
} from './adminIdeas.action'

import { pageMeta } from '../../../common/httpCall'

const initState = {
  ideas: [],
  forms: [],
  loading: false,
  formsLoading: false,
  changed: false,
  ideasPageNr: {},
  formsPageNr: {},
  selectedTemplate: {},
  mostRecentStepWorkedOn: 1,
  currentIdeaFormFields: [],
  ideaIframeData: {},
};

const adminIdeasReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case IDEAS_GET_ATTEMPT: {
      return { ...state, loading: true };
    }

    case POST_SEARCH_IDEAS_ATTEMPT: {
      return { ...state, loading: true };
    }

    case IDEAS_GET_SUCCESS: {
      return {
        ...state,
        ideas: payload.response,
        loading: false,
        ideasPageNr: pageMeta(payload),
      };
    }

    case FORMS_GET_ATTEMPT: {
      return { ...state, formsLoading: true };
    }

    case FORMS_GET_SUCCESS: {
      return {
        ...state,
        forms: payload.response.sort((a, b) => {
          if (a.active) {
            return -1;
          }
          if (!b.active) {
            return 1;
          }
          return 0;
        }),
        formsLoading: false,
        changed: false,
        formsPageNr: pageMeta(payload),
      };
    }

    case FORMS_ADD_SUCCESS: {
      const resp = payload.result.response;
      return {
        ...state,
        ideaIframeData: {
          form_name_slug: resp.form_name_slug,
          council_name_slug: resp.council_name_slug,
        },
        changed: true,
        loading: false,
      };
    }

    case SECTION_DELETE_ATTEMPT: {
      return { ...state, loading: false };
    }

    case SECTION_DELETE_SUCCESS: {
      return { ...state, changed: true, loading: false };
    }

    case FORMS_PATCH_SUCCESS: {
      const stepsArray = [];
      const resp = payload?.result.response;
      for (const stepObj in resp) {
        const templateStepNr = Number(stepObj.split('_')[1]);
        if (templateStepNr && resp[stepObj].length > 0) {
          stepsArray[templateStepNr - 1] = resp[stepObj];
        }
      }

      for (let i = 0; i < stepsArray.length; i++) {
        if (stepsArray[i]?.length)
          stepsArray[i].sort((a, b) => a.position - b.position);
      }
      return {
        ...state,
        selectedTemplate: resp,
        ideaIframeData: {
          form_name_slug: resp.form_name_slug,
          council_name_slug: resp.council_name_slug,
        },
        currentIdeaFormFields: stepsArray.flat(),
        changed: true,
        loading: false,
      }
    }

    case FORMS_DELETE_SUCCESS: {
      return { ...state, changed: true, loading: false };
    }

    case SECTION_PATCH_ATTEMPT: {
      return { ...state, loading: true };
    }

    case SECTION_PATCH_SUCCESS: {
      return { ...state, changed: true, loading: false };
    }
    case SECTION_ADD_SUCCESS: {
      return { ...state, changed: true, loading: false };
    }
    case ADD_LAST_STEP_WORKED_ON: {
      return {
        ...state,
        mostRecentStepWorkedOn: payload.mostRecentStepWorkedOn,
      };
    }

    case IDEAS_DELETE_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case IDEAS_DELETE_SUCCESS: {
      const { id } = payload;
      const filteredIdeas = state.ideas.filter((idea) => idea.id !== id);
      return {
        ...state,
        ideas: filteredIdeas,
        ideasPageNr: {
          ...state?.ideasPageNr,
          total: state?.ideasPageNr?.total - 1,
        },
        loading: false,
      };
    }
    case FORM_GET_SUCCESS: {
      const stepsArray = [];
      for (const stepObj in payload?.response?.idea_template) {
        const templateStepNr = Number(stepObj.split('_')[1]);
        if (
          templateStepNr &&
          payload?.response?.idea_template[stepObj].length > 0
        ) {
          stepsArray[templateStepNr - 1] =
            payload?.response?.idea_template[stepObj];
        }
      }

      for (let i = 0; i < stepsArray.length; i++) {
        if (stepsArray[i]?.length)
          stepsArray[i].sort((a, b) => a.position - b.position);
      }

      return {
        ...state,
        currentIdeaFormFields: stepsArray.flat(),
        selectedTemplate: payload?.response?.idea_template
          ? {
              ...payload?.response?.idea_template,
              stepsArray,
            }
          : {},
        loading: false,
      };
    }

    case SET_IDEA_FORM_FIELDS: {
      return {
        ...state,
        currentIdeaFormFields: payload,
        changed: !state.changed,
      };
    }

    case CLEAR_CURRENT_FORM: {
      return {
        ...state,
        currentIdeaFormFields: [],
        selectedTemplate: {},
      };
    }

    case CATCH_ERROR_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      break;
    }
  }
  return state;
};

export default adminIdeasReducer;

import React, { useEffect } from "react";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-dropdown-select";
import adminFuseHoc from "common/HOC/adminFuseHoc";
import styles from "./viewApplication.module.scss";
import { getApplication, updateApplication } from "../adminApplicants.action";
import { Button, CheckBox, Loading } from "../../../primitives";
import { Icon, smSize } from "../../../../common/icon";
import classnames from "classnames";

const ViewApplication = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const {
    adminApplicantsReducer: { application, getLoading, updateLoading },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(
      getApplication({
        id: props.match.params.id,
        enqueueSnackbar,
      }),
    );
  }, []);

  const handleStatusChangeClick = (id, state) => {
    dispatch(
      updateApplication({
        id,
        data: { state },
        enqueueSnackbar,
      }),
    );
  };

  const renderActionBlock = () => {
    if (!application) {
      return "";
    }

    if (updateLoading) {
      return <Loading />;
    }

    if (application.state === "pending") {
      return (
        <div>
          <Button
            className="mr-5"
            onClick={() => handleStatusChangeClick(application.id, "approved")}
          >
            Approve
          </Button>
          <Button
            onClick={() => handleStatusChangeClick(application.id, "rejected")}
          >
            Reject
          </Button>
        </div>
      );
    }

    return <h3>{application.state}</h3>;
  };

  const renderFields = () => {
    if (!application || !application?.field_attributes_and_values) {
      return "";
    }

    return application?.field_attributes_and_values.map(
      ({
        applicant_form_field_value: value,
        applicant_form_field_values: values,
        applicant_form_field: {
          applicant_form_field_id: name,
          field_type: type,
          field_name: label,
          option_values: options = [],
          allow_multiselect,
        },
      }) => {
        if (type === "image") {
          return (
            <div key={`field-${name}`}>
              <label>{label}</label>
              <div className="border rounded p-2 d-flex align-items-center">
                <Icon
                  {...smSize}
                  icon="icn-subnav-documents"
                  className="mr-3"
                />
                <Link to={value} target="_blank" download>
                  Download
                </Link>
              </div>
            </div>
          );
        }

        if (type === "checklist") {
          return (
            <div key={`field-${name}`}>
              <label>{label}</label>
              <div className="border rounded p-2 d-flex flex-column">
                {options.map((option) => (
                  <div
                    key={`field-${name}-${option}`}
                    className="d-flex align-items-center "
                  >
                    <CheckBox isChecked={values.includes(option)} disabled />
                    <label>{option}</label>
                  </div>
                ))}
              </div>
            </div>
          );
        }

        if (type === "dropdown") {
          const dropDownOptions = options.map((option) => ({
            label: option,
            value: option,
          }));
          return (
            <div key={`field-${name}`}>
              <label>{label}</label>
              <Select
                multi={allow_multiselect}
                options={dropDownOptions}
                values={[
                  dropDownOptions.find((opt) => options.includes(opt.value)),
                ]}
                disabled
              />
            </div>
          );
        }

        if (["document", "image", "slide", "video"].includes(type)) {
          return (
            <div key={`field-${name}`} className="d-flex flex-column flex-grow-1 mb-3">
              <label>{label}</label>
              <textarea value={values} disabled />
            </div>
          );
        }

        return (
          <div key={`field-${name}`} className="d-flex flex-column flex-grow-1 mb-3">
            <label>{label}</label>
            <input value={value} disabled />
          </div>
        );
      },
    );
  };

  if (getLoading) {
    return (
      <div className={classnames(styles.container, "container")}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={classnames(styles.container, "container")}>
      {application && (
        <>
          <div className="row align-items-center">
            <div className="col-xs-12 col-sm-6">
              <h4 className={styles.taskHeaderText}>
                <a
                  href="/admin"
                  className={classNames(styles.rootText, "ml-0")}
                >
                  ADMIN
                </a>
                <span className={styles.separationBar}> / </span>
                <a href="/admin/applicants" className={styles.rootText}>
                  APPLICANTS
                </a>
                <span className={styles.separationBar}> / </span>
                <span className={styles.rootText}>
                  {application?.fields?.applicantName?.value}
                </span>
              </h4>
            </div>
            <div
              className="col-xs-12 col-sm-6 d-flex align-items-center justify-content-end"
            >
              {renderActionBlock(application?.fields)}
            </div>
          </div>
          <div className="row">
            <div className="col-6 mx-auto">{renderFields()}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(adminFuseHoc(ViewApplication));

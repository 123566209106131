import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ModalFooter, ModalBody } from "../../../../../application/modal";
import { Button, BtnType, Label, DropSelect } from "../../../../primitives";
import styles from "./addToList.module.scss";
import useModelPopup from "common/hooks/modelPopup.hook";
import { clearCreatedList, companySavedListGetAttempt } from "modules/lists/store/lists.actions";
import { addProductToList } from "modules/companies/product/store/product.actions";
import SaveSearchComponent from "modules/companies/companyMain/rightWindow/saveSearch";
import LoadingComponent from "modules/primitives/loading/loading.component";

const AddProductToList = ({
  enqueueSnackbar,
  product
}) => {
  const dispatch = useDispatch();
  const popup = useModelPopup();
  const [selected, setSelected] = useState("");
  const [options, setOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    councilReducer: { selectedCouncil },
    listsMainReducer: {
      savedCompaniesLists,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedCouncil?.name === "Kyndryl" && options?.length) {
      const hasSpecificListObj = options.find(
        (l) => l.name?.toLowerCase() === "selection committee",
      );

      if (hasSpecificListObj) {
        setSelected(hasSpecificListObj);
      }
    }
  }, [selectedCouncil, options]);

  useEffect(() => {
    dispatch(companySavedListGetAttempt({ enqueueSnackbar }));
  }, []);

  useEffect(() => {
    if (savedCompaniesLists?.length > 0) {
      if (!product?.lists?.length) {
        setOptions(savedCompaniesLists);
        return;
      }

      const existingListIds = product.lists.map(list => list.id);
      const lists = savedCompaniesLists.filter(l => !existingListIds.includes(l.id));
      setOptions(lists);
    }
  }, [savedCompaniesLists, product]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = () => {
    if (!selected?.id?.length) {
      enqueueSnackbar("Please select a List first",
        { variant: "error" },
      );
      return;
    }

    setIsSubmitting(true);

    const payload = {
      data: {
        list_id: selected.id,
        council_product_id: product.id
      } ,
      enqueueSnackbar,
      cb: () => {
        setIsSubmitting(false);
        popup.hide();
        enqueueSnackbar("Successfully added product to List",
          { variant: "success" },
        );
      }
    };

    dispatch(addProductToList(payload));
  };

  const handleOptionSelect = (values) => {
    setSelected(values.obj[0]);
  };

  const handleSaveHide = () => {
    popup.hide();
    dispatch(clearCreatedList());
  };

  return (
    <div className={styles.addCompanyWrp}>
      <ModalBody>
        <Label>Select List</Label>
        <DropSelect
          onChange={handleOptionSelect}
          placeholder="Select list"
          labelField="name"
          valueField="id"
          searchBy="name"
          createProp
          searchable
          clearOnBlur
          clearOnSelect
          options={options}
          SelecedValues={
            selected
              ? [
                {
                  name: selected.name,
                  id: selected.id,
                },
              ]
              : null
          }
        />
        <div className={styles.createNew}>
          <Button
            btn={BtnType.FRAME_LESS}
            icon="icn-add"
            onClick={() => {
              popup.show({
                title: "Create New List",
                component: (
                  <SaveSearchComponent
                    handleSaveHide={handleSaveHide}
                    mode="empty"
                    companyIds={[product.company?.id]}
                    productIds={[product.id]}
                  />
                ),
              });
            }}
          >
            Create
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        {
          isSubmitting ? (
            <LoadingComponent customText="Processing..." />
          ) : (
            <>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button type="submit" btn={BtnType.REGULAR} onClick={handleSaveClick}>
                Save
              </Button>
            </>
          )
        }
      </ModalFooter>
    </div>
  );
};

export default AddProductToList;

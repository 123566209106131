export const optionStatus = [
  {
    text: "On Track",
    value: "open",
  },
  {
    text: "At Risk",
    value: "at_risk",
  },
  {
    text: "Off Track",
    value: "off_track",
  },
  {
    text: "Closed",
    value: "closed",
  },
  {
    text: "On Hold",
    value: "on_hold",
  },
];

export const priorityOptions = [
  {
    text: "High",
    value: "high",
  },
  {
    text: "Medium",
    value: "medium",
  },
  {
    text: "Low",
    value: "low",
  },
];

export const tooltipTextsByState = {
  different_solution: "Different Solution in Place",
  possible_replication: "Possible Replication",
  under_review: "Under Review",
  not_applicable: "Not Applicable for this Plant",
  original: "Original",
  replicated: "Replicated"
};

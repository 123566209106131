import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Formik } from "formik";
import { withSnackbar } from "notistack";
import Select from "react-dropdown-select";
import { convertFromHTML } from "draft-js";

import {
  Button,
  BtnType,
  Label,
  CheckBox,
  CheckType,
  TextBox,
  Loading,
} from "../../../modules/primitives";
import { initModel, newDiscussionValidation } from "./newDiscussion.constant";
import styles from "./newDiscussion.module.scss";
import { ModalBody, ModalFooter } from "../../../application/modal";
import { appModelSettingSet } from "application/app.action";
import {
  peopleListTeamsGet,
  councilUsersListGet,
} from "../../actions/common.action";
import {
  discussionEdit,
  discussionSave,
  patientOrgDiscussionSaveAttempt,
} from "../../actions/discussions.action";
import { getSelectedProjectName } from "../../selectors/discussions.selectors";
import { createProjectDiscussion } from "modules/projects/project/project.action";
import UserMultiSelectSearch from "../usersSearch";
import { getUserTeams } from "modules/teams/teams.action";
import RichEditor from "../richEditor";

const NewDiscussionComponent = (props) => {
  const {
    type,
    isPrivate,
    enqueueSnackbar,
    projectId,
    projectName,
    companyId,
    selectedUser,
    eventId,
    hideTeam = false,
    discussion,
    handleRedirectTo
  } = props;

  const [model, setModel] = useState(initModel);
  const [disabled, setDisabled] = useState("");
  const [recipientsError, setErrorRecipients] = useState(false);
  const [selectedTeamOption, setSelectedTeamOption] = useState([]);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef();
  const {
    appReducer: { modalSetting },
    authReducer: { session },
    councilReducer: { selectedCouncil, usersList, loading },
    peopleReducer: {
      internalPeopleReducer: { teamList },
    },
    teamsReducer: { userTeams },
  } = useSelector((state) => state);

  useEffect(() => {
    if (discussion?.id) {
      setModel({
        ...model,
        subject: discussion.subject,
        anonymous: discussion.anonymous,
        team_id: discussion.team_id,
        recipients: !discussion?.participants?.length
          ? []
          : discussion.participants.map(p => ({ ...p, id: p.user_id, full_name: p.user_full_name})),
      });

      if (discussion.team_id) {
        setSelectedTeamOption([{
          value: discussion.team_id,
          label: discussion.team.name,
        }]);
        setDisabled('multiselect');
      }
    } else {
      setModel({
        ...model,
        recipients: selectedUser?.id && usersList[0] ? [selectedUser.id] : [],
      });
    }
  }, [discussion, selectedUser?.id, usersList]);

  const handleCancelClick = (resetForm) => {
    resetForm(initModel);
    setModel(initModel);
    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
  };

  useEffect(() => {
    if (selectedCouncil && !teamList && type !== "Project") {
      dispatch(
        peopleListTeamsGet({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }

    dispatch(councilUsersListGet(selectedCouncil.id, enqueueSnackbar));
  }, [selectedCouncil, teamList]);

  useEffect(() => {
    dispatch(
      getUserTeams({
        enqueueSnackbar,
        query: {
          items: 1000,
        },
      })
    );
  }, []);

  const getResourceId = (shouldBeCurentUser) => {
    if (type === "Company" || type === "PatientOrganization") {
      return companyId;
    }

    if (type === "Council") {
      return selectedCouncil.id;
    }

    if (type === "Project") {
      return projectId;
    }

    if (type === "Event") {
      return eventId;
    }

    if (shouldBeCurentUser) {
      return session.id;
    }

    if (isPrivate) {
      const { recipients } = formRef.current.values;
      return recipients[0];
    }
  };

  const getScope = (publicDisc) => {
    if (type === "Event") {
      if (publicDisc) {
        return "event_attendees";
      }
      return null;
    }
    if (type === "Project") {
      if (publicDisc) {
        return "project_members";
      }
      return null;
    }
    return null;
  };

  const handleSaveClick = (resetForm) => {
    const { recipients, anonymous, subject, team_id } = formRef.current.values;

    if (recipients && recipients.length === 0 && !team_id && isPrivate) {
      setErrorRecipients(true);
      return;
    }
    const publicDisc =
      (type === "Council" && recipients[0] && recipients[0]?.id !== "AllId") ||
      (recipients[0] && recipients[0]?.id !== "AllId")
        ? false
        : !isPrivate;

    const getResource_type = () => {
      if (type === "PatientOrganization" || type === "Company" || type === 'Event' || type === 'Project' || type === 'Council') {
        return type;
      }
      return !publicDisc ? "User" : type;
    };

    const string = convertFromHTML(message);
    if (!string?.contentBlocks[0]?.text && !discussion?.id) {
      setMessageError(true);
      return;
    }
    setMessageError(false);

    const payload = {
      discussion: {
        council_id: selectedCouncil.id,
        team_id,
        recipients:
          (recipients && recipients[0]?.id === "AllId") ||
          (recipients && recipients[0]?.id === "AllId" && anonymous)
            ? []
            : recipients[0]?.id ? recipients.map(r => r.id) : recipients,
        scope: getScope(publicDisc),
        anonymous: disabled === "dropselect" ? false : anonymous,
        subject: subject.trim(),
        company_id: companyId,
        public: publicDisc,
      },
      discussion_resource_type: team_id ? "Team" : undefined,
      message: {
        // remove html tags from message
        content: message.replace(/<[^>]*>/g, '')
      },
      resource_type: getResource_type(),
      resource_id: getResourceId(!publicDisc),
      type,
      id: discussion?.id,
      cb: (res) => {
        if (handleRedirectTo) {
          const isDiscussionPublic = res.response.public;
          handleRedirectTo(isDiscussionPublic ? 'GROUP' : 'PRIVATE');
        }
      }
    };

    if (type === "PatientOrganization") {
      if (discussion?.id) {
        dispatch(discussionEdit({
          enqueueSnackbar,
          data: {
            ...payload,
            discussion: {
              ...payload.discussion,
              patient_organization_id: companyId,
            },
            patient_organization_id: companyId,
            resource_type: "Gsk::PatientOrganization",
          },
        }))
      } else {
        dispatch(
          patientOrgDiscussionSaveAttempt({
            data: {
              ...payload,
              discussion: {
                ...payload.discussion,
                patient_organization_id: companyId,
              },
              patient_organization_id: companyId,
              resource_type: "Gsk::PatientOrganization",
            },
            enqueueSnackbar,
          })
        );
      }

      dispatch(appModelSettingSet({ ...modalSetting, show: false }));
      resetForm(initModel);
      setModel(initModel);

      return;
    }

    if (type === "Project") {
      const newPayload = {
        ...payload,
        discussion: {
          ...payload.discussion,
          project_id: projectId
        }
      };

      if (discussion?.id) {
        dispatch(discussionEdit({
          enqueueSnackbar,
          data: newPayload,
        }))
      } else {
        dispatch(
          createProjectDiscussion({
            enqueueSnackbar,
            data: newPayload,
          })
        );
      }
    } else {
      if (discussion?.id) {
        dispatch(discussionEdit({
          enqueueSnackbar,
          data: payload,
        }))
      } else {
        dispatch(
          discussionSave({
            enqueueSnackbar,
            data: payload,
          })
        );
      }
    }

    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
    resetForm(initModel);
    setModel(initModel);
  };

  const handleCheckClick = (value, values) => {
    setModel({
      ...values,
      anonymous: value,
    });
  };

  const handleMultiSelectChange = (val, setFieldValue) => {
    if (val === "team") {
      setFieldValue("recipients", []);
      setDisabled("multiselect");
      return;
    }
    if (val && val.length > 0) {
      setErrorRecipients(false);
    }
    const recipients = val.filter((item) => item.id !== "AllId");
    setFieldValue("recipients", recipients);
    if ((val && val[0] && val[0].id !== "AllId") || val[1]) {
      setDisabled("dropselect");
    } else {
      setDisabled("");
    }
  };

  return (
    <div className={styles.wrapper}>
      {loading ? (
        <div className={styles.loaderWrp}>
          <Loading />
        </div>
      ) : (
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={model}
          validationSchema={discussion?.id?.length ? null : newDiscussionValidation}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            setSubmitting(true);
            handleSaveClick(resetForm);
          }}
        >
          {({ handleSubmit, resetForm, setFieldValue, ...formikprops }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <div className="row mt-4">
                  <div className="col-7">
                    <>
                      {selectedUser ? (
                        <p className={styles.selectedUser}>
                          {selectedUser.full_name}
                        </p>
                      ) : (
                        <>
                          <Label>Recipient(s)</Label>
                          <UserMultiSelectSearch
                            type={type}
                            placeholder="Add people"
                            labelField="full_name"
                            valueField="id"
                            withoutOwner
                            searchBy="full_name"
                            disabled={disabled === "multiselect"}
                            hasAll={!isPrivate}
                            name="recipients"
                            className={
                              recipientsError ? styles.recipientsError : {}
                            }
                            // formProps={{setFieldValue, ...formikprops}}
                            selectedValues={model.recipients || []}
                            onChange={(e) =>
                              handleMultiSelectChange(e, setFieldValue)
                            }
                          />
                          {recipientsError && (
                            <div
                              className={
                                recipientsError ? styles.recipientTextError : {}
                              }
                            >
                              Recipient is required
                            </div>
                          )}
                        </>
                      )}
                    </>

                    {
                      type !== 'Project' && userTeams.length > 0 && !hideTeam && (
                        <div className="mt-2 mb-2">
                          <Select
                            placeholder="Select a team"
                            name="team_id"
                            onChange={([selected]) => {
                              if (selected === undefined) {
                                setSelectedTeamOption([]);
                                setDisabled("");
                                setFieldValue("team_id", undefined);
                                return;
                              }
                              setFieldValue("team_id", selected.value);
                              setSelectedTeamOption([selected]);
                              handleMultiSelectChange(
                                "team",
                                setFieldValue,
                                formikprops
                              );
                            }}
                            values={selectedTeamOption}
                            options={userTeams.map(
                              ({ id: value, name: label }) => ({
                                label,
                                value,
                              })
                            )}
                          />
                        </div>
                      )
                    }
                  </div>
                  <div className="col-5">
                    {type === "Project" && (
                      <span className={styles.checkboxWrp}>{projectName}</span>
                    )}
                    {!isPrivate && disabled !== "dropselect" && (
                      <div className={styles.checkboxWrp}>
                        <label>
                          <CheckBox
                            checkType={CheckType.BLUE}
                            formProps={formikprops}
                            name="anonymous"
                            onChange={(value) =>
                              handleCheckClick(value, formikprops.values)
                            }
                          />
                          Anonymous Mode
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <Label>Subject</Label>
                    <TextBox
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      className={styles.textBox}
                      formProps={formikprops}
                    />
                    {
                      !discussion?.id && (
                        <>
                          <Label>Message</Label>
                          <RichEditor
                            settedValue={message}
                            updateValue={(e) => setMessage(e)}
                          />
                        </>
                      )
                    }
                    {messageError && (
                      <p className={styles.error}>Message text is required</p>
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="mr-3"
                  btn={BtnType.FRAME_LESS}
                  onClick={() => handleCancelClick(resetForm)}
                >
                  Cancel
                </Button>
                <Button type="submit" btn={BtnType.REGULAR}>
                  {discussion?.id ? 'Edit' : 'Start'} Discussion
                </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  projectName: getSelectedProjectName(state, props),
});

const NewDiscussionConnect = connect(mapStateToProps)(NewDiscussionComponent);

export default React.memo(withSnackbar(NewDiscussionConnect));

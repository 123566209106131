import { createSelector } from "reselect";

const getDiscussionsByType = (state, props) => {
  const stateProp = props.details.prop;
  return state.discussionsReducer[stateProp];
};
const getDiscussionId = (state, props) => props.details.id;
const getSelectedProject = (state) => state.projectsReducer.projectReducer.selectedProject;

export const getDiscussionDetails = createSelector(
  getDiscussionsByType,
  getDiscussionId,
  (discussions, id) => discussions.find((d) => d.id === id),
);

export const getSelectedProjectName = createSelector(
  getSelectedProject,
  (project) => project?.name,
);

import React from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import styles from "./ideaListItemHeader.module.scss";
import { Icon, smSize } from "../../../../../../common/icon";

const IdeaListItemHeader = ({
  id, title, sortable, onClick, className,
}) => {
  const location = useLocation();
  const urlParams = Object.fromEntries(new URLSearchParams(location.search));

  const drawSortingIcon = () => {
    if (urlParams?.sort_by && urlParams.sort_by === id) {
      return (
        <div
          className={classnames(
            styles.sortIcon,
            urlParams?.sort_order === "desc" && styles.sortAsc,
          )}
        >
          <Icon {...smSize} icon="icn-export-button" />
        </div>
      );
    }
  };

  const handleOnClick = () => {
    if (!sortable) return;
    const sort = {
      sort_by: id,
      sort_order: "desc",
    };

    if (urlParams?.sort_by && urlParams.sort_by === id) {
      if (urlParams?.sort_order === "desc") {
        sort.sort_order = "asc";
      } else {
        sort.sort_order = "desc";
      }
    }

    onClick(sort);
  };

  return (
    <div
      role="cell"
      aria-hidden="true"
      className={classnames(
        className,
        styles.headerItem,
        sortable && styles.cursorPointer,
      )}
      onClick={handleOnClick}
    >
      {title}
      {drawSortingIcon()}
    </div>
  );
};

export default IdeaListItemHeader;

import React from "react";

import StyleButton from "./StyleButton";
import styles from "../richEditor.module.scss";

export const styleMap = {
  CODE: {
    fontFamily: "Lato",
    fontSize: 14,
    padding: 2,
    color: "#616e7c",
  },
};

export const BLOCK_TYPES = [
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" },
];

export const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <span className={styles.secondControls}>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </span>
  );
};

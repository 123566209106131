import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { addRelatedProjectAttempt } from "../../../idea.action";
import {
  BtnType,
  Button,
  CheckBox,
  CheckType,
  Label,
  MultiSelect,
} from "../../../../primitives";
import { ModalFooter } from "../../../../../application/modal";
import styles from "./ideaProjects.module.scss";
import { appModelSettingSet } from "application/app.action";
import { httpGet } from "../../../../../common/httpCall";

const AddRelatedProjectComponent = ({ selectedIdea, popup }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    appReducer: { modalSetting },
  } = useSelector((state) => state);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [debouncedValue, setDebouncedValue] = useState("");
  const [options, setOptions] = useState([]);
  const [elasticQuery, setElasticQuery] = useState("");
  const [isSimilarProject, setIsSimilarProject] = useState(true);

  const getProjects = useCallback((query) => {
    httpGet({
      call: 'projects/related_projects/dropdown'
    }).subscribe(res => {
      const data = res.response || [];

      if (!selectedIdea?.projects?.length) {
        setOptions(data);
      }

      const existingIds = selectedIdea?.projects.map(p => p.project_id);
      const filteredProjects = data.filter((project) => !existingIds.includes(project.id));

      setOptions(filteredProjects);
    });
  }, [selectedCouncil, session, enqueueSnackbar, selectedIdea]);

  useEffect(() => {
    getProjects('');
  }, []);

  // useEffect(() => {
  //   getProjects(debouncedValue);
  // }, [debouncedValue]);

  const handleOptionSelect = (values) => {
    setSelectedProjects(values);
  };

  const handleCancelClick = () => {
    dispatch(appModelSettingSet({ ...modalSetting, show: false }));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(elasticQuery);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [elasticQuery]);

  const handleProjectsSave = () => {
    if (!selectedProjects.length) {
      enqueueSnackbar(`Please select at least one project!`, {
        variant: "error",
      });
      return;
    }
    const payload = {
      enqueueSnackbar,
      project_idea: [
        {
          project_id: selectedProjects[0]?.id,
          idea_id: selectedIdea?.id,
          similar: selectedCouncil?.name === 'Ford' ? isSimilarProject : false,
        },
      ],
    };
    dispatch(addRelatedProjectAttempt(payload));
    popup.hide();
  };

  return (
    <div>
      <div className={styles.customPadding}>
        <Label>Projects</Label>
        <div className={styles.selectorWrp}>
          <MultiSelect
            onChange={handleOptionSelect}
            placeholder="Add Projects (type to search or select from below)"
            labelField="name"
            valueField="id"
            options={options}
            SelecedValues={selectedIdea?.projects}
            searchBy="name"
            multi={false}
            searchFn={(args) => {
              setElasticQuery(args.state.search);
            }}
          />
        </div>
        {
          selectedCouncil?.name === 'Ford' && (
            <div className="mt-1 mb-3">
              <label className="d-flex align-items-center">
                <CheckBox
                  checkType={CheckType.BLUE}
                  name="isSimilarProject"
                  onChange={setIsSimilarProject}
                  isChecked={isSimilarProject}
                />
                <span>Similar Project</span>
              </label>
            </div>
          )
        }
      </div>
      <ModalFooter>
        <Button
          className="mr-3"
          btn={BtnType.FRAME_LESS}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
        <Button onClick={handleProjectsSave} btn={BtnType.REGULAR}>
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default memo(AddRelatedProjectComponent);

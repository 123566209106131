import { combineEpics } from "redux-observable";

import newRatingTemplateEpic from "../modules/admin/adminRating/adminRating.epic";
import tractionCompaniesEpic from "../modules/tractionAdmin/companies/tractionCompanies.epic";
import adminCustomFieldsEpic from "../modules/admin/adminCustomFieldsGroups/adminCustomFields.epic";
import adminMembersEpic from "../modules/admin/adminMembers/adminMembers.epic";
import authEpic from "../modules/auth/auth.epic";
import projectsEpic from "../modules/projects/projects.epic";
import councilEpic from "../modules/council/council.epic";
import peopleEpic from "../modules/people/people.epic";
import companyEpic from "../modules/companies/companies.epic";
import eventsEpic from "../modules/events/events.epic";
import settingsEpic from "../modules/settings/settings.epic";
import suggestionsEpic from "../common/epics/suggestions.epic";
import homeEpic from "../modules/home/home.epic";
import myCompanyEpic from "../common/epics/myCompany.epic";
import genericEpic from "../common/epics/generic.epic";
import discussionsEpic from "../common/epics/discussions.epic";
import crunchbaseEpic from "../modules/companies/crunchbase/crunchbase.epic";
import membersEpic from "../modules/members/members.epic";
import patientOrganizationsEpic from "../modules/patientOrganizations/patientOrganizations.epic";
import documentsEpic from "../common/epics/documents.epic";
import stagesEpic from "../modules/admin/adminProjects/adminProjects.epic";
import adminTagsEpic from "../modules/admin/adminTags/adminTags.epic";
import adminApplicantsEpic from "../modules/admin/adminApplicants/adminApplicants.epic";
import adminApplicantsFormsEpic from "../modules/admin/adminApplicants/forms/adminApplicantForms.epic";
import integrationsEpic from "../modules/admin/adminIntegrations/adminIntegrations.epic";
import ideaEpic from "../modules/ideasWrapper/idea.epic";
import ideaAdmin from "../modules/admin/adminIdeas/adminIdeas.epic";
import teamsEpic from "../modules/teams/teams.epic";
import claimProfileEpic from "../modules/claimProfile/store/claimProfileEpic";
import listsMainEpic from "../modules/lists/store/lists.epic";
import adminAgreementsEpic from "../modules/admin/adminAgreements/adminAgreements.epic";
import reportingEpic from "../modules/reporting/store/reporting.epic";
import themesEpic from "../modules/themes/themes.epic";
import councilsEpic from "../modules/tractionAdmin/councils/councils.epic";
import solutionsEpic from "modules/solutions/solutions.epic";
import adminPlantsAndAreasEpic from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.epic";
import fordDashboardEpic from "modules/reporting/fordReports/store/fordReports.epic";
import tractionAIEpic from "modules/tractionAI/store/tractionAI.epic";
import newProjectV3Epic from "modules/projects/newProjectv3/newProjectV3.epic";

const rootEpic = combineEpics(
  authEpic,
  projectsEpic,
  councilEpic,
  peopleEpic,
  companyEpic,
  eventsEpic,
  settingsEpic,
  suggestionsEpic,
  homeEpic,
  myCompanyEpic,
  discussionsEpic,
  genericEpic,
  crunchbaseEpic,
  membersEpic,
  patientOrganizationsEpic,
  documentsEpic,
  newRatingTemplateEpic,
  stagesEpic,
  adminTagsEpic,
  adminApplicantsEpic,
  adminApplicantsFormsEpic,
  integrationsEpic,
  ideaEpic,
  ideaAdmin,
  teamsEpic,
  tractionCompaniesEpic,
  adminCustomFieldsEpic,
  adminMembersEpic,
  claimProfileEpic,
  listsMainEpic,
  adminAgreementsEpic,
  reportingEpic,
  themesEpic,
  councilsEpic,
  solutionsEpic,
  adminPlantsAndAreasEpic,
  fordDashboardEpic,
  tractionAIEpic,
  newProjectV3Epic,
);

export default rootEpic;

import * as htmlToImage from "html-to-image";

export const sectionToPng = (selector) => {
  const node = document.getElementById(selector);

  // skipFonts: true,
  return htmlToImage
    .toPng(node, { backgroundColor: "white", cacheBust: true })
    .then((dataUrl) => dataUrl)
    .catch((error) => {
      console.error("oops, something went wrong!", error);
      return null;
    });
};

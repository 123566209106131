import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import adminFuseHoc from "common/HOC/adminFuseHoc";
import { useQuery } from "common/helper";
import { CSVLink } from "react-csv";
import classnames from "classnames";
import { Button, BtnType, OptionType } from "../../primitives";
import styles from "./adminTags.module.scss";
import { useModelPopup } from "../../../common/hooks";
import AdminTagModal from "./modals/addTagModal.component";
import WarningModal from "./modals/warningModal.compoent";

import ConfirmDeleteComponent from "../../../common/components/confirmDelete";
import AddCustomTagModal from "./modals/addCustomTagModal.component";
import {
  tagTopicGetAttempt,
  tagIndustryGetAttempt,
  tagRelationshipGetAttempt,
  tagCustomGetAttempt,
} from "./adminTags.actions";
import AdminTagsTable from "./tables/adminTagsTable";
import AdminTagsRelationshipStatusTable from "./tables/adminTagsRelationshipStatusTable";
import RelationshipStatusModal from "./modals/relationshipStatusModal.component";
import AdminCustomTagsGroupTable from "./tables/adminCustomTagsGroupTable";
import AdminCustomTagTable from "./tables/adminCustomTagTable.component";

import CustomTagGroupModal from "./modals/addCustomGroupModal.component";

const AdminTags = () => {
  const history = useHistory();
  const popup = useModelPopup();
  const csvLinkRef = useRef();
  const [csvData, setCsvData] = useState([]);
  const tab = useQuery().get("tab");
  const customGroup = useQuery().get("group");

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedVariant, setSelectedVarian] = useState(tab);
  const [defaultExpandedRows, setDefaultExpandedRows] = useState([]);
  const [secondExpandedChildren, setSecondExpandedChildren] = useState([]);
  const [thirdExpandedChildren, setThirdExpandedChildren] = useState([]);
  const [extendedAll, setExtendedAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedCustomGroupId, setSelectedCustomGroupId] = useState("");
  const [selectedCustomGroup, setCustomGroupSelected] = useState("");

  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    adminTopicsReducer: {
      topicTags,
      loading,
      industryTags,
      shouldUpdate,
      relationshipTag,
      customTagGroups,
    },
  } = useSelector((state) => state);

  const selectCustomGroup = (groupName, groupId) => {
    setCustomGroupSelected(groupName);
    setSelectedCustomGroupId(groupId);
    history.push(`/admin/tags?tab=${tab}&group=${groupName}`);
  };
  const onTabClick = (selectedTab) => {
    setSelectedVarian(selectedTab);
    selectCustomGroup("");
    history.push(`/admin/tags?tab=${selectedTab}`);
  };

  useEffect(() => {
    setSelectedVarian(tab);
    if (tab === "topics") {
      setSelectedData(topicTags);
    }
    if (tab === "relationship_status") {
      setSelectedData(relationshipTag);
    }
    if (tab === "industry") {
      setSelectedData(industryTags);
    }
    if (tab === "custom_tags") {
      setSelectedData(customTagGroups || []);
    }
  }, [tab]);

  useEffect(() => {
    if (selectedVariant === "topics") {
      setSelectedData(topicTags);
    }
    if (tab === "relationship_status") {
      setSelectedData(relationshipTag);
    }
    if (tab === "industry") {
      setSelectedData(industryTags);
    }
    if (tab === "custom_tags") {
      setSelectedData(customTagGroups || []);
    }
  }, [topicTags, industryTags, selectedVariant]);

  useEffect(() => {
    if (secondExpandedChildren && secondExpandedChildren.length) {
      secondExpandedChildren.forEach((el) => {
        if (el?.children_topics?.length) {
          setThirdExpandedChildren((prevState) => [
            ...prevState,
            ...el.children_topics,
          ]);
        }
      });
    }
  }, [secondExpandedChildren]);

  useEffect(() => {
    if (session && selectedCouncil?.id) {
      dispatch(
        tagTopicGetAttempt({
          council_id: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
      dispatch(
        tagIndustryGetAttempt({
          council_id: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
      dispatch(
        tagRelationshipGetAttempt({
          council_id: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
      dispatch(
        tagCustomGetAttempt({
          enqueueSnackbar,
        })
      );
    }
  }, [session, selectedCouncil]);

  useEffect(() => {
    if (shouldUpdate) {
      dispatch(
        tagTopicGetAttempt({
          council_id: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
      dispatch(
        tagIndustryGetAttempt({
          council_id: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
      dispatch(
        tagRelationshipGetAttempt({
          council_id: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [shouldUpdate]);
  const handleAddTagClick = (e) => {
    setDefaultExpandedRows([]);
    setSecondExpandedChildren([]);

    if (selectedVariant === "relationship_status") {
      popup.show({
        title: "Add Relationship Stage Tag",
        component: (
          <RelationshipStatusModal
            selectedVariant={selectedVariant}
            mode="add"
            enqueueSnackbar={enqueueSnackbar}
            council_id={selectedCouncil.id}
            tags={selectedVariant === "industry" ? industryTags : topicTags}
          />
        ),
      });
      return;
    }
    if (selectedVariant === "custom_tags") {
      popup.show({
        title: "Add Custom Tag",
        component: (
          <AddCustomTagModal
            selectedVariant={selectedVariant}
            mode="add"
            enqueueSnackbar={enqueueSnackbar}
            council_id={selectedCouncil.id}
            tags={[]}
            customTagsGroups={customTagGroups}
            selectedCustomGroupId={selectedCustomGroupId}
          />
        ),
      });
      return;
    }

    popup.show({
      title: "Add Tag",
      component: (
        <AdminTagModal
          selectedVariant={selectedVariant}
          mode="add"
          enqueueSnackbar={enqueueSnackbar}
          council_id={selectedCouncil.id}
          tags={selectedVariant === "industry" ? industryTags : topicTags}
        />
      ),
    });
  };

  const handleAddCustomTagGroupClick = () => {
    if (customTagGroups.length >= 5) {
      popup.show({
        title: "Limit Reached",
        component: <WarningModal />,
      });
      return;
    }
    popup.show({
      title: "Add Tag Category",
      component: (
        <CustomTagGroupModal
          selectedVariant={selectedVariant}
          mode="add"
          enqueueSnackbar={enqueueSnackbar}
          council_id={selectedCouncil.id}
          tags={selectedVariant === "industry" ? industryTags : topicTags}
          customTagGroups={customTagGroups}
        />
      ),
    });
  };

  const moreOptions = [
    { val: "edit", text: "Edit" },
    { val: "delete", text: "Delete", type: OptionType.DANGER },
  ];

  const handleMoreOptionClick = (event, tag) => {
    if (event === "edit") {
      if (selectedVariant === "custom_tags") {
        popup.show({
          title: "Edit Tag Category",
          component: (
            <CustomTagGroupModal
              selectedVariant={selectedVariant}
              mode="edit"
              enqueueSnackbar={enqueueSnackbar}
              council_id={selectedCouncil.id}
              tag={tag}
            />
          ),
        });
      } else {
        popup.show({
          title: "Edit Tag",
          component: (
            <AdminTagModal
              tag={tag}
              selectedVariant={selectedVariant}
              mode="edit"
              enqueueSnackbar={enqueueSnackbar}
              council_id={selectedCouncil.id}
              tags={selectedVariant === "industry" ? industryTags : topicTags}
            />
          ),
        });
      }
    }

    if (event === "delete") {
      const getActionType = () => {
        if (tag?.type === "council_custom_tag_group_custom_tag") {
          return "tagCustomDeleteAttempt";
        }
        if (selectedVariant === "custom_tags") {
          return "tagCustomGroupDeleteAttempt";
        }
        if (selectedVariant === "industry") {
          return "tagIndustryDeleteAttempt";
        }
        if (selectedVariant === "topics") {
          return "tagTopicDeleteAttempt";
        }
      };

      popup.show({
        title: "Confirmation Prompt",
        show: true,
        height: "300",
        width: "540",
        component: (
          <ConfirmDeleteComponent
            id={
              tag?.type === "council_custom_tag_group_custom_tag"
                ? tag.attributes.custom_tag_id
                : tag.id
            }
            enqueueSnackbar={enqueueSnackbar}
            councilId={selectedCouncil.id}
            categoryId={
              selectedCustomGroupId ||
              customTagGroups?.find((group) => group.name === customGroup)
                ?.id ||
              ""
            }
            name={tag.name}
            actionType={getActionType()}
          />
        ),
      });
    }
  };

  const extendRow = (rowId) => {
    const coincidence = defaultExpandedRows?.find(
      (elementId) => elementId === rowId
    );
    if (coincidence) {
      const filteredRows = defaultExpandedRows?.filter(
        (elementId) => elementId !== rowId
      );
      setDefaultExpandedRows(filteredRows);
      return;
    }
    setDefaultExpandedRows((prevState) => [...prevState, rowId]);
  };
  const extendChildRow = (rowId) => {
    const coincidence = secondExpandedChildren?.find(
      (elementId) => elementId === rowId
    );
    if (coincidence) {
      const filteredRows = secondExpandedChildren?.filter(
        (elementId) => elementId !== rowId
      );
      setSecondExpandedChildren(filteredRows);
      return;
    }
    setSecondExpandedChildren((prevState) => [...prevState, rowId]);
  };

  const extendAllRows = () => {
    if (extendedAll) {
      setExtendedAll(false);
      setDefaultExpandedRows([]);
      setSecondExpandedChildren([]);
      return;
    }
    setExtendedAll(true);

    const childIdsCollection = [];
    let parentIdsCollection;

    if (selectedVariant === "topics") {
      parentIdsCollection = topicTags.map((tag) => {
        if (tag.children_topics.length) {
          tag.children_topics.forEach((child) =>
            childIdsCollection.push(child.id)
          );
        }

        return tag.id;
      });
    }
    if (selectedVariant === "industry") {
      parentIdsCollection = industryTags.map((tag) => {
        if (tag.children_industries.length) {
          tag.children_industries.forEach((child) =>
            childIdsCollection.push(child.id)
          );
        }

        return tag.id;
      });
    }

    setDefaultExpandedRows(parentIdsCollection);
    setSecondExpandedChildren(childIdsCollection);
  };

  useEffect(() => {
    if (topicTags?.length && tab !== "relationship_status") {
      const csvDataCont = [["Tags", "Child Tags"]];
      const type =
        selectedVariant === "topics"
          ? "children_topics"
          : "children_industries";
      setCsvData(() => {
        //
        selectedData.map((parentTag) => {
          csvDataCont.push([
            "● " + parentTag.name,
            parentTag[`${type}`]?.length,
          ]);
          if (parentTag[`${type}`]?.length) {
            parentTag[`${type}`].map((firstChildTag) => {
              csvDataCont.push([
                " " + firstChildTag.name + ` (Child of: ${parentTag.name})`,
                firstChildTag[`${type}`]?.length,
              ]);
              if (firstChildTag[`${type}`]?.length) {
                firstChildTag[`${type}`].map((lastChildTag) => {
                  csvDataCont.push([
                    " " +
                      " " +
                      lastChildTag.name +
                      ` (Child of: ${firstChildTag.name})`,
                    lastChildTag[`${type}`]?.length || "-",
                  ]);
                });
              }
            });
          }
        });

        return csvDataCont;
      });
    }
    if (tab === "relationship_status") {
      setCsvData(() => {
        // setExportLoading(false);

        const csvDataCont = [
          ["Relationship Stage", "Description", "Background", "Position"],
        ];

        selectedData.map((item) => {
          csvDataCont.push([
            item?.relationship_stage?.name || item?.name || "-",
            item?.description,
            item?.colors.background,
            item?.position,
          ]);
        });
        return csvDataCont;
      });
    }
  }, [selectedData]);

  const handleExportList = () => {
    enqueueSnackbar("List succesfully exported.", {
      variant: "success",
    });
    csvLinkRef.current.link.click();
  };

  const getItemInTable = () => {
    if (tab === "custom_tags" && customGroup) {
      return `${
        customTagGroups.find((group) => group.name === customGroup)?.custom_tags
          ?.length || ""
      } Custom Tags`;
    }
    if (tab === "custom_tags") {
      return `${selectedData.length || "0"} Custom Tag Categories`;
    }
    if (selectedVariant === "relationship_status") {
      return `${selectedData.length || "0"} Tags`;
    }
    return `${selectedData.length || "0"} Main Tags`;
  };

  const showAddTagButton = () => {
    if (
      tab === "relationship_status" ||
      tab === "topics" ||
      tab === "industry"
    ) {
      return true;
    }

    return !!(tab === "custom_tags" && customGroup);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <span
            className={styles.rootText}
            onClick={() => {
              history.push("/admin");
            }}
          >
            ADMIN
          </span>
          <span className={styles.separationBar}> / </span>
          <span className={styles.rootText}>TAGS</span>
        </h4>
      </div>
      <div className="row">
        <div
          className={classnames(styles.tabsContainer, "col-10")}
          style={{ margin: "0 auto", paddingTop: "40px" }}
        >
          <h5>
            <span
              onClick={() => {
                onTabClick("topics");
                setDefaultExpandedRows([]);
                setSecondExpandedChildren([]);
                setExtendedAll(false);
              }}
              className={
                selectedVariant === "topics"
                  ? styles.selectedVar
                  : styles.tabsText
              }
            >
              {" "}
              Technology{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span
              onClick={() => {
                onTabClick("industry");
                setDefaultExpandedRows([]);
                setSecondExpandedChildren([]);
                setExtendedAll(false);
              }}
              className={
                selectedVariant === "industry"
                  ? styles.selectedVar
                  : styles.tabsText
              }
            >
              {" "}
              Industry{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span
              onClick={() => {
                onTabClick("relationship_status");
                setDefaultExpandedRows([]);
                setSecondExpandedChildren([]);
                setExtendedAll(false);
              }}
              className={
                selectedVariant === "relationship_status"
                  ? styles.selectedVar
                  : styles.tabsText
              }
            >
              {" "}
              Relationship Stage{" "}
            </span>
            <span className={styles.separationBar}> / </span>
            <span
              onClick={() => {
                onTabClick("custom_tags");
                // setDefaultExpandedRows([]);
                // setSecondExpandedChildren([]);
                // setExtendedAll(false);
              }}
              className={
                selectedVariant === "custom_tags"
                  ? styles.selectedVar
                  : styles.tabsText
              }
            >
              {" "}
              Custom Tags{" "}
            </span>
            {(selectedCustomGroup || customGroup) && (
              <>
                <span className={styles.separationBar}> / </span>
                <span
                  className={
                    selectedVariant === "custom_tags"
                      ? styles.selectedVar
                      : styles.tabsText
                  }
                >
                  {selectedCustomGroup || customGroup || ""}{" "}
                </span>
              </>
            )}
          </h5>
        </div>
      </div>
      <div className={styles.flexWrapper}>
        <div className={styles.tagsContainer}>
          <div className={styles.buttonsRow}>
            <div>
              {tab !== "relationship_status" && tab !== "custom_tags" && (
                <Button
                  btn={BtnType.FRAME_LESS}
                  onClick={() => {
                    extendAllRows();
                  }}
                >
                  {" "}
                  {extendedAll ? "Collapse All" : "Expand All"}
                </Button>
              )}
            </div>
            <div className={styles.addTagButtonContainer}>
              {tab === "custom_tags" && !customGroup && (
                <Button
                  btn={BtnType.REGULAR}
                  icon="icn-add"
                  onClick={() => {
                    handleAddCustomTagGroupClick(selectedVariant);
                  }}
                  className={styles.marginRight10px}
                >
                  ADD CATEGORY
                </Button>
              )}
              {showAddTagButton() && (
                <Button
                  btn={BtnType.REGULAR}
                  icon="icn-add"
                  onClick={() => {
                    handleAddTagClick(selectedVariant);
                  }}
                  className={styles.marginRight10px}
                >
                  Add tag
                </Button>
              )}

              {tab !== "custom_tags" && (
                <>
                  <Button
                    onClick={handleExportList}
                    btn={BtnType.OUTLINE}
                    className={styles.marginRight10px}
                  >
                    EXPORT
                  </Button>
                  <CSVLink
                    data={csvData}
                    filename="Traction Tags.csv"
                    style={{ display: "none" }}
                    ref={csvLinkRef}
                  />
                </>
              )}

              <span className={styles.totalCount}>{getItemInTable()}</span>
            </div>
          </div>
          {selectedVariant === "topics" && (
            <AdminTagsTable
              data={selectedData}
              arrayKey="children_topics"
              defaultExpandedRows={defaultExpandedRows}
              secondExpandedChildren={secondExpandedChildren}
              extendRow={extendRow}
              extendChildRow={extendChildRow}
              handleMoreOptionClick={handleMoreOptionClick}
              moreOptions={moreOptions}
              selectedVariant={selectedVariant}
              loading={loading}
            />
          )}
          {selectedVariant === "industry" && (
            <AdminTagsTable
              data={selectedData}
              arrayKey="children_industries"
              defaultExpandedRows={defaultExpandedRows}
              secondExpandedChildren={secondExpandedChildren}
              extendRow={extendRow}
              extendChildRow={extendChildRow}
              handleMoreOptionClick={handleMoreOptionClick}
              moreOptions={moreOptions}
              selectedVariant={selectedVariant}
              loading={loading}
            />
          )}

          {selectedVariant === "relationship_status" && (
            <AdminTagsRelationshipStatusTable
              data={selectedData}
              arrayKey="children_industries"
              defaultExpandedRows={defaultExpandedRows}
              secondExpandedChildren={secondExpandedChildren}
              extendRow={extendRow}
              extendChildRow={extendChildRow}
              handleMoreOptionClick={handleMoreOptionClick}
              moreOptions={moreOptions}
              selectedVariant={selectedVariant}
              loading={loading}
              popup={popup}
              enqueueSnackbar={enqueueSnackbar}
              selectedCouncil={selectedCouncil}
              industryTags={industryTags}
              topicTags={topicTags}
            />
          )}
          {selectedVariant === "custom_tags" && !customGroup && (
            <AdminCustomTagsGroupTable
              data={customTagGroups}
              arrayKey="custom_tags"
              defaultExpandedRows={defaultExpandedRows}
              secondExpandedChildren={secondExpandedChildren}
              extendRow={extendRow}
              extendChildRow={extendChildRow}
              handleMoreOptionClick={handleMoreOptionClick}
              moreOptions={moreOptions}
              selectedVariant={selectedVariant}
              loading={loading}
              setCustomGroupSelected={selectCustomGroup}
            />
          )}
          {selectedVariant === "custom_tags" && customGroup && (
            <AdminCustomTagTable
              tagGroup={
                customTagGroups.find((group) => group.name === customGroup) ||
                []
              }
              arrayKey="custom_tags"
              defaultExpandedRows={defaultExpandedRows}
              secondExpandedChildren={secondExpandedChildren}
              extendRow={extendRow}
              extendChildRow={extendChildRow}
              handleMoreOptionClick={handleMoreOptionClick}
              moreOptions={moreOptions}
              selectedVariant={selectedVariant}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default adminFuseHoc(AdminTags);

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { councilsEditAttempt } from "../../councils.action";
import getTimezoneOffset from "../getTimezonOffset";
import styles from "../../styles.module.scss";

const TableStatusRow = ({ row, enqueueSnackbar }) => {
  const [isActive, setIsActive] = useState(row?.active);
  const dispatch = useDispatch();
  const collectTagsOnToggle = (industries, technologies) => {
    const industriesOnSubmit = industries.map((industry) => industry.industry?.name);
    const technologiesOnSubmit = technologies.map((technology) => technology?.topic?.name);
    return { industriesOnSubmit, technologiesOnSubmit };
  };

  const handleToggle = () => {
    const { industriesOnSubmit, technologiesOnSubmit } = collectTagsOnToggle(
      row?.resource_industries,
      row?.resource_topics
    );
    const time_zone = getTimezoneOffset();
    const data = {
      id: row?.id,
      name: row?.name,
      logo: row?.logo || "",
      traction_tag: row?.traction_tag,
      themes_connected: row?.themes_connected,
      active: !isActive,
      time_zone,
      topic_names: technologiesOnSubmit,
      industry_names: industriesOnSubmit,
    };
    dispatch(councilsEditAttempt({ council: data, id: row?.id, enqueueSnackbar }));
  };
  useEffect(() => {
    setIsActive(row?.active);
  }, [row?.active]);
  return (
    <button
      className={classNames(styles.toggleButton, isActive && styles.active)}
      onClick={handleToggle}
    >
      <div className={styles.toggleCircle} />
    </button>
  );
};
export default TableStatusRow;

import React, { useRef, useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { searchUsers } from "api/microsoft/graphApi/outlookOffice";
import { useSnackbar } from "notistack";
import {
  addCompanyContact,
  editCompanyContact,
} from "modules/people/externalPeople/companyContacts.action";
import { httpPost } from "../../../../../common/httpCall";

import {
  DropSelect,
  Button,
  BtnType,
  Label,
} from "../../../../primitives/index";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../common/hooks";
import { ModalFooter, ModalBody } from "../../../../../application/modal";
import { addRelationShip } from "../../company.action";

import { initModel } from "./constants";
import { useDebounce } from "../../../../../common/hooks/useDebounce.hook";

const AddMSFTContacts = ({ data, type, cb }) => {
  const popup = useModelPopup();
  const [msftContacts, setMsftContacts] = useState([]);
  const [error] = useState("");
  const { instance, accounts } = useMsal();
  const [companyData, setCompanyData] = useState({});
  const [model] = useState(initModel);
  const [companyRelationData, setCompanyRelationData] = useState({});
  const [allContactList] = useState([]);
  const [uniqueContacts, setUniqueContacts] = useState([]);
  const [externalTeam] = useState({});
  const [isMsftResponse, setIsMsftResponse] = useState(false);
  const [msftSearch, setMsftSearch] = useState(false);
  const debouncedValue = useDebounce(msftSearch, 500);

  const { enqueueSnackbar } = useSnackbar();

  const formRef = useRef();
  const dispatch = useDispatch();

  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
    companiesReducer: {
      companyReducer: { selectedCompany },
    },
  } = useSelector((state) => state);

  const fetchContacts = useCallback(async () => {
    const response = await searchUsers(
      instance,
      accounts,
      "",
      100,
      session?.users_integrations
    );
    // const response = await getContacts(instance, accounts);
    const contacts = response?.value.filter((elem) => elem.mail.length > 0);
    setIsMsftResponse(true);
    setMsftContacts(contacts);
  }, [instance, accounts, setMsftContacts, setIsMsftResponse]);

  useEffect(() => {
    if (!allContactList || !msftContacts) return;

    const uniqueValues = msftContacts.filter(
      (e) => !allContactList.includes(e.mail)
    );
    setUniqueContacts(uniqueValues);

    if (isMsftResponse) return;

    fetchContacts();
  }, [
    instance,
    accounts,
    msftContacts,
    allContactList,
    externalTeam,
    isMsftResponse,
  ]);

  useEffect(() => {
    searchUsers(
      instance,
      accounts,
      msftSearch,
      50,
      session?.users_integrations
    ).then((res) => {
      setMsftContacts(res.value);
    });
  }, [debouncedValue]);

  const handleMSFTSearch = (val) => {
    setMsftSearch(val);
  };

  const handleCancelClick = () => {
    if (cb) {
      cb(true);
    }
    popup.hide();
  };

  const handleSelectContact = (e) => {
    const dataValues = {
      first_name: e.obj[0].givenName,
      last_name: e.obj[0].surname,
      email: e.obj[0].mail,
      title: e.obj[0].jobTitle,
      mobile: e.obj[0].businessPhones[0],
      department_or_organization: e.obj[0].department,
      company_id: selectedCompany.id,
      origin: "microsoft",
    };
    setCompanyData({ ...dataValues });
  };

  const handleItemSelect = (e) => {
    const dataItems = {
      first_name: e.obj[0].givenName,
      last_name: e.obj[0].surname,
      email: e.obj[0].mail,
      title: e.obj[0].jobTitle,
    };
    setCompanyRelationData({ ...dataItems });
  };

  const handleOnSubmit = () => {
    if (type === "relationship") {
      httpPost({
        call: `companies/${selectedCompany.id}/external_team?relationship_owner=true`,
        data: companyRelationData,
      })
        .pipe()
        .subscribe((res) => {
          if (res.response.for_relationship_owner && res.response.user_id) {
            dispatch(
              addRelationShip({
                data: {
                  user_id: res.response.id,
                },
                council_id: selectedCouncil.id,
                company_id: selectedCompany.id,
                enqueueSnackbar,
              })
            );
          } else {
            dispatch(
              addRelationShip({
                data: {
                  external_team_id: res.response.id,
                },
                council_id: selectedCouncil.id,
                company_id: selectedCompany.id,
                enqueueSnackbar,
              })
            );
          }
        });
    }

    if (type === "contact") {
      if (data) {
        dispatch(
          editCompanyContact({
            data: companyData,
            enqueueSnackbar,
            company_contact_id: data.id,
            company_id: selectedCompany.id,
          })
        );
      } else {
        dispatch(
          addCompanyContact({
            data: companyData,
            council_id: selectedCouncil.id,
            enqueueSnackbar,
          })
        );
      }
    }

    if (cb) {
      cb(true);
    }

    popup.hide();
  };

  return (
    <div className={styles.wrapper}>
      <Formik
        innerRef={formRef}
        initialValues={model && model}
        onSubmit={(value, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleOnSubmit(value);
          resetForm();
        }}
      >
        {({ handleSubmit, handleChange, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <>
                    {type === "contact" && (
                      <div>
                        <Label>Select Contacts from Microsoft</Label>
                        <DropSelect
                          placeholder="Select Contacts"
                          name="contact_id"
                          labelField="displayName"
                          valueField="id"
                          searchable
                          backspaceDelete
                          searchBy="displayName"
                          searchFn={(evt) => {
                            handleMSFTSearch(evt.state.search);
                          }}
                          options={uniqueContacts}
                          formProps={formikprops}
                          onChange={(e) => handleSelectContact(e)}
                        />
                        {error === "contact_id" && (
                          <div className={styles.errorText}>
                            Contact required
                          </div>
                        )}
                      </div>
                    )}
                    {type === "relationship" && (
                      <div>
                        <Label>Select Contacts from Microsoft</Label>
                        <DropSelect
                          onChange={(e) => handleItemSelect(e)}
                          placeholder="Select Contacts"
                          labelField="displayName"
                          valueField="id"
                          searchBy="displayName"
                          searchFn={(evt) => {
                            handleMSFTSearch(evt.state.search);
                          }}
                          options={msftContacts.filter(
                            (elem) =>
                              !selectedCompany?.company_relationship_owners
                                ?.map((e) => e?.external_team?.email)
                                .includes(elem.mail)
                          )}
                          // loading={loading}
                        />
                        {error === "contact_id" && (
                          <div className={styles.errorText}>
                            Contact required
                          </div>
                        )}
                      </div>
                    )}
                  </>
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button type="submit" btn={BtnType.REGULAR}>
                Save
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(AddMSFTContacts);

import {
  GET_COMPARE_COMPANIES_SUCCESS,
  SET_COMPARE_COMPANIES,
  SET_COMPARE_NOTES,
} from "./compair.actions";

const initState = {
  companiesToCompare: null,
  companiesLoading: true,
  companyList: [],
  companiesTotal: null,
  selectedNotes: [],
};
const companyCompairReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_COMPARE_COMPANIES: {
      return {
        ...state,
        companiesToCompare: payload,
      };
    }
    case SET_COMPARE_NOTES: {
      return {
        ...state,
        selectedNotes: payload,
      };
    }

    case GET_COMPARE_COMPANIES_SUCCESS: {
      const {
        result: { response },
        companiesTotal,
      } = payload;

      return {
        ...state,
        companiesLoading: false,
        companyList: response,
        companiesTotal,
      };
    }

    default:
      return state;
  }
};

export default companyCompairReducer;

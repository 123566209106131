import React from "react";
import { Route, Redirect } from "react-router-dom";
// import { useSelector } from "react-redux";
import { getCookie } from "common/helper";

import ClaimProfileWrapper from "../application/claimProfile";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const {
  //   authReducer: { session },
  // } = useSelector((state) => state);
  const UID = getCookie("uid");
  const council_role = getCookie("council_role_");
  return (
    <Route
      {...rest}
      render={(props) => {
        // const lowLevelSession = session;

        if (!UID) {
          return <Redirect to="/login" />;
        }

        if (council_role === "company") {
          return <ClaimProfileWrapper {...props} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;

import React, { memo, useCallback, useEffect, useState } from "react";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import SCReview from "common/handleSCReview";

import style from "./chartCompanyItem.module.scss";
import { EMPLOYEES_ENUM } from "../../../../../common/constants";
import { editDetails } from "../../../company/company.action";
import {
  setChartsAreReRendering,
  setChartsShouldReRender,
  setUpdateChartListCompanyDetails,
} from "../../companyMain.action";
import { httpGet } from "../../../../../common/httpCall";
import {
  appSliderSettingSet,
  highlightItemWithIdAction,
} from "application/app.action";
import { sliderSetting } from "application/app.constants";
import {
  CompanyRatings,
  CompanyRatingsHeader,
} from "../../rightWindow/newCompaniesList/newCompanyRatings";
import { homeFunnelShouldReRender } from "modules/home/home.actions";
import { companyMoreOptions } from "../../companyMain.constant";
import AddToIdeaComponent from "../../addToIdea/addToIdea.component";
import { useModelPopup } from "../../../../../common/hooks";
import {
  companyUnFollow,
  suggestionFollow,
} from "../../../../../common/actions/common.action";
import AddToListComponent from "../../../../lists/components/addToList";
import AddCompanyComponent from "../../addToProject";
import MoreOptionsCompanyChart from "./moreOptionsCompanyChart";
import AddToThemeComponent from "../../../../themes/themesCommon/addCompanyToTheme";
import checkThemePipelinePermissions from "../../../../../common/checkThemePipelinePermissions";
import checkUserThemeCompanyPermission from "../../../../../common/checkUserThemeCompanyPermissions";

const ChartCompanyItem = ({
  company,
  currentYear,
  history,
  selectedCouncil,
  dispatch,
  showAmountOfLastRound,
  homeUse,
  blockFunctionality,
  customMoreOptions,
  kyndryThemelInstance,
  themesCallBack,
}) => {
  const popup = useModelPopup();
  const [stage, setStage] = useState(null);
  const [optionsList, setOptionsList] = useState(companyMoreOptions);
  const [followingId, setFollowingId] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  // const [inestmentStage, setInvestmentStage] = useState(null);

  const {
    authReducer: { session },
    //  councilReducer: { selectedCouncil },
  } = useSelector((state) => state);

  useEffect(() => {
    if (blockFunctionality) {
      const filteredOptions = companyMoreOptions.filter(
        (option) => option.text !== "Add to project"
      );
      setOptionsList(filteredOptions);
    }

    return () => null;
  }, [blockFunctionality]);
  // useEffect(() => {
  //   if (
  //     company?.theme_investment_stage ||
  //     (company?.theme_investment_stage_id &&
  //       selectedCouncil?.theme_investment_stages)
  //   ) {
  //     const getInvestmentStages = selectedCouncil?.theme_investment_stages.find(
  //       (s) =>
  //         s.id === company?.theme_investment_stage?.id ||
  //         s.id === company?.theme_investment_stage_id
  //     );
  //     setInvestmentStage(getInvestmentStages);
  //   }
  // }, [company, selectedCouncil?.theme_investment_stages]);

  useEffect(() => {
    if (
      company?.council_relationship_stage_id &&
      selectedCouncil?.council_relationship_stages?.length
    ) {
      const getStageDetails = selectedCouncil?.council_relationship_stages.find(
        (s) => s.id === company?.council_relationship_stage_id
      );

      setStage(getStageDetails);
    }
  }, [company, selectedCouncil?.council_relationship_stages]);

  const handleCompanyClick = () => {
    localStorage.setItem("company", JSON.stringify(company));
    history.push(
      `/companies/${company.slug || company.id || company.company_id}`
    );
  };

  const handleStageSelect = useCallback(
    (evt) => {
      const selectedStage = selectedCouncil?.council_relationship_stages.find(
        (s) => s.id === evt.target.value
      );
      if (
        checkThemePipelinePermissions(
          selectedStage?.name || "none",
          session?.council_role,
          selectedCouncil?.name,
          session?.decision_maker_access,
          !company?.council_relationship_stage_name
            ? null
            : company?.council_relationship_stage_name
        )
      ) {
        const getStage = selectedCouncil?.council_relationship_stages.find(
          (stage) => stage.id === evt.target.value
        );
        const data = {
          council_relationship_stage_id: getStage?.id || null,
        };

        setStage(getStage?.name || "");

        if (homeUse) {
          dispatch(homeFunnelShouldReRender(true));
        }

        dispatch(
          setChartsAreReRendering({
            state: true,
          })
        );

        dispatch(
          setUpdateChartListCompanyDetails({
            companyId: company?.id || company.company_id,
            newCompanyData: {
              council_relationship_stage_name: evt.target.value,
            },
          })
        );
        dispatch(
          editDetails({
            companyId: company?.id || company.company_id,
            councilId: selectedCouncil.id,
            data,
            enqueueSnackbar,
            council: selectedCouncil?.name,
            callBack: themesCallBack,
          })
        );

        const timeout = setTimeout(() => {
          dispatch(setChartsShouldReRender(true));
          clearTimeout(timeout);
        });
      } else {
        enqueueSnackbar(
          "You are not allowed to move companies in this stage ",
          {
            variant: "error",
          }
        );
      }
    },
    [company, selectedCouncil, dispatch]
  );

  const handleChangeStage = (e) => {
    const selectedStage = selectedCouncil?.council_relationship_stages.find(
      (s) => s.id === e.target.value
    );
    if (
      selectedCouncil?.name === "Kyndryl" &&
      selectedStage?.slug === "sc_review"
    ) {
      const { value } = e.target;
      const getStageDetails = selectedCouncil?.council_relationship_stages.find(
        (s) => s.id || s.slug === value
      );
      if (
        !checkThemePipelinePermissions(
          getStageDetails?.name || "none",
          session?.council_role,
          selectedCouncil?.name,
          session?.decision_maker_access,
          !company?.council_relationship_stage_name
            ? null
            : company?.council_relationship_stage_name
        )
      ) {
        popup.show({
          title: " ",
          show: true,
          height: "300",
          width: "540",
          component: (
            <SCReview
              handleStageSelect={handleStageSelect}
              e={e}
              popup={popup}
              companySlug={company?.slug}
            />
          ),
        });
        return;
      }
      handleStageSelect(e);
      return;
    } else {
      handleStageSelect(e);
    }
  };
  const handleRateClick = useCallback(() => {
    httpGet({
      apiVersion: "v2",
      call: `companies/${company.id || company.company_id}/ratings`,
    })
      .pipe()
      .subscribe((res) => {
        if (res.response) {
          dispatch(highlightItemWithIdAction(company.id || company.company_id));
          openRatingsWindow(res.response);
        }
      });
  }, [company]);

  const openRatingsWindow = useCallback(
    (ratingsData) => {
      dispatch(
        appSliderSettingSet({
          ...sliderSetting,
          show: true,
          height: "300",
          width: "540",
          header: <CompanyRatingsHeader company={company} />,
          component: (
            <CompanyRatings
              chartUse
              homeUse={homeUse}
              company={company}
              ratingsData={ratingsData}
            />
          ),
        })
      );
    },
    [company, homeUse]
  );

  const makeFollow = (company) => {
    dispatch(
      suggestionFollow({
        data: {
          ...company,
          company_id: company.id || company.company_id,
          name: company.name,
        },
        enqueueSnackbar,
        cb: (res) => {
          if (res.response) {
            setFollowingId(
              res.response.follow_relationship_id || res.response.following_id
            );
          }
        },
      })
    );
  };

  const handleAddCompanyToList = () => {
    popup.show({
      title: "Add To List",
      component: (
        <AddToListComponent
          companyId={company.id || company.company_id}
          enqueueSnackbar={enqueueSnackbar}
          getMyOnlyLists
        />
      ),
    });
  };

  const handleAddCompanyClick = (e) => {
    popup.show({
      title: "Add a Company to Project",
      component: (
        <AddCompanyComponent
          companyId={company.id || company.company_id}
          enqueueSnackbar={enqueueSnackbar}
        />
      ),
    });
  };
  const setStylesForCol = () => {
    return style.columnValue;
  };

  const makeUnfollow = () => {
    dispatch(
      companyUnFollow({
        data: {
          following_id: followingId,
        },
        enqueueSnackbar,
      })
    );

    setFollowingId(null);
  };
  // FUNCTION TO CHANGE INVESTMENT STAGE
  // const handleChangeInvestmentStage = useCallback(
  //   (evt) => {
  //     setInvestmentStage(evt.target.value);
  //     const data = {
  //       theme_investment_stage_id:
  //         selectedCouncil?.theme_investment_stages.filter(
  //           (stage) => stage.slug === evt.target.value
  //         )[0]?.id || null,
  //     };

  //     dispatch(
  //       editDetails({
  //         companyId: company.id,
  //         councilId: selectedCouncil.id,
  //         data,
  //         enqueueSnackbar,
  //         council: selectedCouncil?.name,
  //         fromFirmenich: selectedCouncil?.name === "Firmenich",
  //       })
  //     );
  //   },
  //   [company, selectedCouncil]
  // );

  const handleMoreOptionClick = (val, company) => {
    if (val === 1) {
      if (!followingId?.length) {
        makeFollow(company);
      }
    }
    if (val === 2) {
      handleAddCompanyToList();
    }
    if (val === 3) {
      handleAddCompanyClick();
    }
    if (val === 5) {
      if (followingId.length) {
        makeUnfollow();
      }
    }
    if (val === 6) {
      popup.show({
        title: "Add To Idea",
        component: (
          <AddToIdeaComponent
            company={company}
            selectedCouncil={selectedCouncil}
            enqueueSnackbar={enqueueSnackbar}
          />
        ),
      });
    }
    if (val === 7) {
      popup.show({
        title: `Add To ${
          selectedCouncil?.settings?.themes_display_name || "Themes"
        }`,
        component: (
          <AddToThemeComponent
            company={company}
            selectedCouncil={selectedCouncil}
            enqueueSnackbar={enqueueSnackbar}
          />
        ),
      });
    }
  };
  const checkIfTractionMatchIsAllowed = useCallback(() => {
    if (selectedCouncil?.name === "Kyndryl") {
      return false;
    }
    if (company.themes_ids?.length > 0) {
      return false;
    }

    return true;
  });
  return (
    <div className="d-flex w-100">
      <>
        {selectedCouncil?.name !== "IDB" && showAmountOfLastRound ? (
          <span className={setStylesForCol()} onClick={handleCompanyClick}>
            {company.last_funding_amount_usd
              ? `$${company?.last_funding_amount_usd?.toLocaleString("en-US")}`
              : "-"}
          </span>
        ) : selectedCouncil?.name !== "IDB" ? (
          <span className={setStylesForCol()} onClick={handleCompanyClick}>
            {company?.total_fundings_sum
              ? `$${company?.total_fundings_sum?.toLocaleString("en-US")}`
              : "-"}
          </span>
        ) : (
          <span className={setStylesForCol()} onClick={handleCompanyClick}>
            {company?.location_country ? `${company.location_country}` : "-"}
          </span>
        )}
      </>
      <>
        {selectedCouncil?.name !== "Kyndryl" && (
          <span className={setStylesForCol()} onClick={handleCompanyClick}>
            {company?.years_in_operation
              ? `${currentYear && currentYear - company?.years_in_operation}`
              : "-"}
          </span>
        )}
      </>
      {!homeUse && !kyndryThemelInstance ? (
        <span className={setStylesForCol()} onClick={handleCompanyClick}>
          {company?.num_employees_enum
            ? `${EMPLOYEES_ENUM[company?.num_employees_enum]}`
            : "-"}
        </span>
      ) : null}

      {selectedCouncil?.name !== "Kyndryl" ? (
        <span
          className={`${setStylesForCol()} ${style.score}`}
          onClick={handleRateClick}
        >
          {company?.council_company_score || "RATE"}
        </span>
      ) : (
        <span className={`${setStylesForCol()}`} onClick={handleCompanyClick}>
          {company?.partner_type
            ? company?.partner_type.slice(0, 1).toUpperCase() +
              company?.partner_type.slice(1, company?.partner_type.length)
            : "-"}
        </span>
      )}
      {session?.theme_investment_access &&
        selectedCouncil?.name === "Firmenich" && (
          <span className={`${setStylesForCol()} ${style.investmentStageCol}`}>
            {company.theme_investment_stage_name || "-"}

            {/* SELECT TO CHANGE INVESTENT CHANGE  <Select
            value={inestmentStage?.slug || "none"}
            onChange={handleChangeInvestmentStage}
            style={{
              backgroundColor: inestmentStage?.colors?.background || "",
              color: inestmentStage?.colors?.text || "",
            }}
            className={
              !inestmentStage?.slug || inestmentStage?.slug === "none"
                ? style.tractionMatch
                : style.detailsRelationship
            }
            disableUnderline
          >
            {selectedCouncil?.theme_investment_stages?.map((item) => (
              <MenuItem key={item.slug} value={item.slug}>
                {item.name}
              </MenuItem>
            ))}
          </Select> */}
          </span>
        )}
      <div className={style.stage}>
        <Select
          value={company?.council_relationship_stage_id || "none"}
          onChange={(e) => handleChangeStage(e)}
          disabled={blockFunctionality}
          style={{
            backgroundColor: stage?.colors?.background || "",
          }}
          className={
            !stage?.slug || stage?.slug === "none"
              ? style.tractionMatch
              : style.detailsRelationship
          }
          disableUnderline
        >
          {checkIfTractionMatchIsAllowed() && (
            <MenuItem
              style={
                !checkThemePipelinePermissions(
                  "none",
                  session?.council_role,
                  selectedCouncil?.name,
                  session?.decision_maker_access,
                  !company?.council_relationship_stage_name
                    ? null
                    : company?.council_relationship_stage_name
                )
                  ? { background: "#929ba4", color: "white" }
                  : {}
              }
              key="none"
              value="none"
            >
              Traction Match
            </MenuItem>
          )}

          {selectedCouncil?.council_relationship_stages.map((item) => (
            <MenuItem
              style={
                !checkThemePipelinePermissions(
                  item.name,
                  session?.council_role,
                  selectedCouncil?.name,
                  session?.decision_maker_access,
                  !company?.council_relationship_stage_name
                    ? null
                    : company?.council_relationship_stage_name
                )
                  ? { background: "#929ba4", color: "white" }
                  : {}
              }
              key={item.id}
              value={item.id}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      {selectedCouncil?.name === "Kyndryl" && !kyndryThemelInstance && (
        <span className={setStylesForCol()} onClick={handleCompanyClick}>
          {company?.project_stage?.name || "-"}
        </span>
      )}
      {selectedCouncil?.name === "Kyndryl" && kyndryThemelInstance && (
        <span
          className={setStylesForCol()}
          style={{
            marginLeft: "10px",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={handleCompanyClick}
        >
          {company?.company_relationship_owner_names?.map((e, i) => {
            if (i < 2) {
              return (
                <div key={e}>
                  {e}
                  <br />
                </div>
              );
            }
            if (i === 2) {
              return (
                <div className={style.multiUsers}>
                  + {company?.company_relationship_owner_names?.length - 2}
                </div>
              );
            }
            return null;
          }) || "-"}
        </span>
      )}
      <div className={style.colMore}>
        {!customMoreOptions ? (
          <MoreOptionsCompanyChart
            options={optionsList
              .filter((option) => {
                if (
                  (selectedCouncil?.name === "GSK" &&
                    option.text === "Add to Idea") ||
                  checkUserThemeCompanyPermission(
                    session?.council_role,
                    option.text,
                    selectedCouncil?.themes_connected
                  ) ||
                  (selectedCouncil?.settings?.ideas_connected === false &&
                    option.text === "Add to Idea")
                ) {
                  return false;
                }
                return (
                  option.text !== (followingId?.length ? "Follow" : "Unfollow")
                );
              })
              .map((opt) => {
                if (opt.text === "Add to theme") {
                  return {
                    ...opt,
                    text: `Add to ${
                      selectedCouncil?.settings?.themes_display_name_singular ||
                      "theme"
                    }`,
                  };
                }
                return opt;
              })}
            className={style.moreOptWrp}
            onClick={(val) => handleMoreOptionClick(val, company)}
            setFollowingId={setFollowingId}
            followingId={followingId}
            company={company}
          />
        ) : null}
      </div>
    </div>
  );
};

export default memo(ChartCompanyItem);

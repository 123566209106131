import React from "react";

import styles from "./reason.module.scss";

const userIdeaDataByReason = {
  stopped: {
    name: "Stopped",
  },
  approved: {
    name: "Approved",
  },
  paused: {
    name: "Paused",
  },
  scouting: {
    name: "Scouting",
  },
  solved: {
    name: "Solved",
  },
};

const IdeaProfileReason = ({ selectedIdea, selectedCouncil }) => {
  const isKyndrylSpecificStatus =
    selectedIdea?.status === "scouting" || selectedIdea?.status === "solved";
  const status = isKyndrylSpecificStatus
    ? selectedIdea?.last_status_change?.to
    : selectedIdea?.status;
  const getStatusLabel = (string) => {
    if (
      selectedCouncil?.traction_tag === "home_x" &&
      string.toUpperCase() === "PAUSED"
    ) {
      return "Parked";
    }
    return string;
  };

  return (
    <div className="d-flex flex-column mt-4">
      <div className={`${styles.reasonContent} ${styles[status]}`}>
        <span className={styles.title}>
          Reason why {getStatusLabel(userIdeaDataByReason[status].name)}
        </span>
        <p className={styles.message}>
          {selectedIdea.last_status_change?.reason}
        </p>
      </div>
    </div>
  );
};

export default React.memo(IdeaProfileReason);

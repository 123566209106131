import React, { useMemo, useState } from "react";
import classNames from "classnames";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Button } from "modules/primitives";
import { customStyles } from "modules/reporting/components/generateReport/constants";
import styles from "./projectDetails.module.scss";
import { deseasCheck } from "modules/patientOrganizations/patientOrganizationMain/patientOrganizationMain.action";

const tagStyle = {
  backgroundColor: "#ebf8ff",
  marginBottom: '5px',
  marginRight: '5px',
  padding: '0 10px',
  borderRadius: '3px',
  color: '#2d83ae',
  cursor: 'pointer',
  border: '1px solid #2d83ae',
  maxWidth: '300px',
}

const ProjectDetailsTags = ({ data, title, isPatientOrg }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const makeData = useMemo(() => {
    if (isPatientOrg) {
      return data?.diseases.map(d => ({...d, propToUse: 'disease'})) || [];
    }

    return [
      ...(data?.diseases.map(d => ({...d, propToUse: 'disease'})) || []),
      ...(data?.topics.map(d => ({...d, propToUse: 'technology'})) || []),
      ...(data?.industries.map(d => ({...d, propToUse: 'industry'})) || []),
    ];
  }, [isPatientOrg, data]);

  const hasShowMore = useMemo(() => makeData.length > 5, [makeData]);

  const handleTagClick = (tag, type) => {
    setOpen(false);

    if (type === 'disease') {
      dispatch(
        deseasCheck({
          disease_ids: [tag.disease_id],
        }),
      );

      history.push('/patient_organizations');

      return
    }

    if (type === "technology") {
      const search = `topic=${tag.topic_id}&page=1`;

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          topic: tag.topic_id,
          page: 1,
        },
      });
    } else {
      const search = `industry=${tag.industry_id}&page=1`;

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          industry: tag.industry_id,
          page: 1,
        },
      });
    }
  };

  const handleShowMore = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  if (!makeData?.length) {
    return '-';
  }

  return (
    <>
      <div className={styles.infoRow}>
        <div
          className={classNames(
            "font-weight-bold",
            "caption",
            "small",
            styles.label,
          )}
        >
          {title}
        </div>
        <div className={classNames(styles.value, styles.tagWrp)}>
          {
            makeData
              .slice(0, hasShowMore ? 5 : makeData.length)
              .map(item => (
                <div
                  key={item.id + item.name}
                  className={classNames(
                    "caption small",
                    styles.tags,
                  )}
                  onClick={() => handleTagClick(item, item.propToUse)}
                >
                  {item.name}
                </div>
              ))
          }
          {
            hasShowMore ? (
              <span
                onClick={handleShowMore}
                className={`${styles.showMoreData} d-block mt-3`}
              >+ {makeData.length - 5}</span>
            ) : null
          }
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle style={customStyles.title}>{title}</DialogTitle>
        <DialogContent>
          <div className="d-flex flex-row flex-wrap">
            {
              makeData.map((item, idx) => (
                <div
                  key={`modal_${item?.name}_${idx}`}
                  style={tagStyle}
                  className="caption small"
                  onClick={() => handleTagClick(item, item.propToUse)}
                >
                  {item.name}
                </div>
              ))
          }
        </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default React.memo(ProjectDetailsTags);

import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import styles from "./uploadedByInfo.module.scss";

const UploadedByInfo = ({ document, isMobile }) => {
  if (isMobile) {
    return (
      <>
        <span className={styles.label}>Uploaded by:</span>
        <div className={styles.uploadDocWrapper}>
          <div className="d-flex align-items-center">
            <div className={styles.value}>
              {document.added_by ? (
                <span>{document.added_by?.name || "-"}</span>
              ) : (
                <Link to={`/people/internal/profile/${document.added_by?.id}`}>
                  {document.added_by?.name || "-"}
                </Link>
              )}
            </div>
          </div>
          <div className={styles.date}>
            {document?.updated_at !== document?.created_at
              ? document.updated_at
                ? format(new Date(document.updated_at), "eeee, MMM do, yyyy")
                : "-"
              : document.created_at
              ? format(new Date(document.created_at), "eeee, MMM do, yyyy")
              : "-"}
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={styles.uploadDocWrapper}>
      <div className="d-flex align-items-center">
        <span className={styles.label}>Uploaded by:</span>
        <div className={styles.value}>
          {document.added_by ? (
            <span>{document.added_by?.name || "-"}</span>
          ) : (
            <Link to={`/people/internal/profile/${document.added_by?.id}`}>
              {document.added_by?.name || "-"}
            </Link>
          )}
        </div>
      </div>
      <div className={styles.date}>
        {document?.updated_at !== document?.created_at
          ? document.updated_at
            ? format(new Date(document.updated_at), "eeee, MMM do, yyyy")
            : "-"
          : document.created_at
          ? format(new Date(document.created_at), "eeee, MMM do, yyyy")
          : "-"}
      </div>
    </div>
  );
};

export default React.memo(UploadedByInfo);

import classnames from "classnames";
import styles from "modules/reporting/customReportsStyles.module.scss";
import React from "react";

export const rangeToPercent = el => {
  if (el.value <= 0) {
    return 0
  }

  return (el.value * 100) / (el.max - 1);
}

export const makeDateTimePropsPayload = (value, dateArray) => {
  let payload = {
    date_range: value,
  };

  if (value === 0 && dateArray[0] instanceof Date) {
    payload = {
      from_date: dateArray[0],
      to_date: dateArray[1],
    }
  }

  if (value === -1) {
    payload = {};
  }

  return payload;
}

export const generateSections = (generatedReportsInstance, generatedReportData, sectionComponentsByProp) => {
  const sectionsNr = Object.keys(sectionComponentsByProp).length;
  const styleClass = sectionsNr === 4 ? styles.flex50 : styles.flex33;

  if (generatedReportsInstance) {
    return generatedReportData.map(key => (
      <div key={key} className={classnames(styleClass, 'pr-2')}>
        {sectionComponentsByProp[key](true)}
      </div>
    ))
  }

  return Object.keys(sectionComponentsByProp).map(key => (
    <div key={key} className={classnames(styleClass, 'pr-2')}>
      {sectionComponentsByProp[key](false)}
    </div>
  ))
}

export const makePaginationPayload = (page, reducerKey) => {
  const payload = makeDateTimePropsPayload(reducerKey?.timePeriod, reducerKey?.dateRange);

  return {
    data: {
      data: {
        search: "*",
        ...payload,
      },
      filters: {
        page,
      }
    },
    justPaginationLoading: true,
  }
}

export const makeCurrSelectedSegments = (reducer, segment) => {
  const isSelected = reducer.selected_keys_segments?.some(s => s.id === segment.id);

  if (isSelected) {
    // unselect in that case
    return reducer.selected_keys_segments?.filter(s => s.id !== segment.id);
  }

  return [...reducer.selected_keys_segments || [], segment];
}

export const makePayloadWithAllParams = (reducer, paginationLoading, newDataForReducer) => {
  const newReducer = {
    ...reducer,
    ...newDataForReducer,
  };
  const timePayload = makeDateTimePropsPayload(newReducer?.timePeriod, newReducer?.dateRange);

  return {
    data: {
      data: {
        search: "*",
        selected_keys_segments: newReducer.selected_keys_segments || [],
        ...timePayload,
      },
      filters: {
        page: newReducer?.pagination?.page || 1,
      }
    },
    justPaginationLoading: paginationLoading,
  }
}

import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  apiStatus,
  httpPost,
  httpGet,
  httpDelete,
  httpPatch,
  errorHandler,
} from "../../../common/httpCall";

import {
  IDEAS_GET_ATTEMPT,
  ideasGetSuccess,
  FORMS_GET_ATTEMPT,
  formsGetSuccess,
  FORMS_ADD_ATTEMPT,
  formsAddSuccess,
  SECTION_ADD_ATTEMPT,
  sectionAddSuccess,
  IDEAS_DELETE_ATTEMPT,
  ideasDeleteSuccess,
  SECTION_DELETE_ATTEMPT,
  sectionDeleteSuccess,
  FORMS_PATCH_ATTEMPT,
  formsPatchSuccess,
  FORMS_DELETE_ATTEMPT,
  formsDeleteSuccess,
  SECTION_PATCH_ATTEMPT,
  sectionPatchSuccess,
  catchErrorSuccess,
  POST_SEARCH_IDEAS_ATTEMPT,
  FORM_GET_ATTEMPT,
  formGetSuccess,
} from "./adminIdeas.action";

const epicGetIdeas = (action$) =>
  action$.pipe(
    ofType(IDEAS_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, page, rows } }) =>
      httpGet({
        // call: 'ideas?items=10000&page=1',
        call: `ideas?items=${rows}&page=${page}`,
      }).pipe(
        map((result) => ideasGetSuccess(result)),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess),
        ),
      ),
    ),
  );

const epicGetForm = (action$) =>
  action$.pipe(
    ofType(FORM_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, id } }) =>
      httpGet({
        // call: 'ideas?items=10000&page=1',
        call: `ideas_templates/${id}`,
      }).pipe(
        map((result) => formGetSuccess(result)),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess),
        ),
      ),
    ),
  );

const epicGetForms = (action$) =>
  action$.pipe(
    ofType(FORMS_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, page, rows } }) =>
      httpGet({
        // call: `ideas_templates?items=${rows}&page=${page}`,
        call: "ideas_templates?all=true",
      }).pipe(
        map((result) => formsGetSuccess(result)),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess),
        ),
      ),
    ),
  );

const epicAddForms = (action$) =>
  action$.pipe(
    ofType(FORMS_ADD_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        call: "ideas_templates",
        data: {
          template: {
            council_id: data.selectedCouncil,
            name: data.name,
            description: data.description,
            created_by_id: data.created_by_id,
            ideas_sections_attributes: data.ideas_sections_attributes,
            active: data.active,
          },
        },
      }).pipe(
        map((result) => {
          try {
            if (
              result.status === apiStatus.CREATED ||
              result.status === apiStatus.SUCCESS
            ) {
              enqueueSnackbar("Successfully added idea template", {
                variant: "success",
              });
              return formsAddSuccess({ result, enqueueSnackbar });
            }
          } catch (error) {
            console.log("-----????  catch ?????-----", error);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess),
        ),
      ),
    ),
  );

const epicPatchForms = (action$) =>
  action$.pipe(
    ofType(FORMS_PATCH_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPatch({
        call: `ideas_templates/${data.id}`,
        data: {
          template: {
            council_id: data.selectedCouncil,
            name: data.name,
            description: data.description,
            created_by_id: data.created_by_id,
            active: data.active,
            ideas_sections_attributes: data.ideas_sections_attributes,
          },
        },
      }).pipe(
        map((result) => {
          try {
            if (
              result.status === apiStatus.CREATED ||
              result.status === apiStatus.SUCCESS
            ) {
              enqueueSnackbar("Successfully edited idea template", {
                variant: "success",
              });
              return formsPatchSuccess({ result, enqueueSnackbar });
            }
          } catch (error) {
            console.log("-----????  catch ?????-----", error);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess),
        ),
      ),
    ),
  );

const epicAddFormSection = (action$) =>
  action$.pipe(
    ofType(SECTION_ADD_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        call: "ideas_sections",
        data: {
          section: {
            section_name: data.section_name,
            section_type: data.section_type,
            step_nr: Number(data.step_nr),
            position: Number(data.position),
            prompt_text: data.prompt_text,
            placeholder: data.placeholder,
            ideas_template_id: data.ideas_template_id,
            council_id: data.council_id,
            category_type: data.category_type,
            field_required: data.field_required,
            multiselect: data.multiselect,
            section_option_values: data.section_option_values,
          },
        },
      }).pipe(
        map((result) => {
          try {
            if (
              result.status === apiStatus.CREATED ||
              result.status === apiStatus.SUCCESS
            ) {
              enqueueSnackbar("Successfully added idea section", {
                variant: "success",
              });
              return sectionAddSuccess({ enqueueSnackbar });
            }
          } catch (error) {
            console.log("-----????  catch ?????-----", error);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess),
        ),
      ),
    ),
  );
export const epicDeleteIdea = (action$, state$) =>
  action$.pipe(
    ofType(IDEAS_DELETE_ATTEMPT),
    switchMap(({ payload: { id, council_id, enqueueSnackbar, name, cb } }) =>
      httpDelete({
        call: `ideas/${id}`,
      }).pipe(
        map((result) => {
          enqueueSnackbar(`You deleted the Idea: ${name}`, {
            variant: "success",
          });

          if (cb) {
            cb()
          }

          return ideasDeleteSuccess({ id });
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar)),
      ),
    ),
  );

const epicDeleteSection = (action$) =>
  action$.pipe(
    ofType(SECTION_DELETE_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, id } }) =>
      httpDelete({
        call: `ideas_sections/${id}`,
      }).pipe(
        map((result) => sectionDeleteSuccess(result)),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess),
        ),
      ),
    ),
  );

const epicDeleteForm = (action$) =>
  action$.pipe(
    ofType(FORMS_DELETE_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, id } }) =>
      httpDelete({
        call: `ideas_templates/${id}`,
      }).pipe(
        map((result) => formsDeleteSuccess(result)),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess),
        ),
      ),
    ),
  );

const epicPatchFormSection = (action$) =>
  action$.pipe(
    ofType(SECTION_PATCH_ATTEMPT),
    switchMap(({ payload: { data, id, enqueueSnackbar } }) =>
      httpPatch({
        call: `ideas_sections/${id}`,
        data: {
          section: {
            section_name: data.section_name,
            section_type: data.section_type,
            step_nr: Number(data.step_nr),
            position: Number(data.position),
            prompt_text: data.prompt_text,
            placeholder: data.placeholder,
            ideas_template_id: data.ideas_template_id,
            council_id: data.council_id,
            category_type: data.category_type,
            field_required: data.field_required,
            multiselect: data.multiselect,
            section_option_values: data.section_option_values,
          },
        },
      }).pipe(
        map((result) => {
          try {
            if (
              result.status === apiStatus.CREATED ||
              result.status === apiStatus.SUCCESS
            ) {
              enqueueSnackbar("Successfully edited idea section", {
                variant: "success",
              });
              return sectionPatchSuccess({ enqueueSnackbar });
            }
          } catch (error) {
            console.log("-----????  catch ?????-----", error);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess),
        ),
      ),
    ),
  );

const epicSearchIdeas = (action$) =>
  action$.pipe(
    ofType(POST_SEARCH_IDEAS_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar, ideasPage, ideasRow } }) =>
      httpPost({
        call: `ideas/search?items=${ideasRow}&page=${ideasPage}`,
        data: {
          ...data,
        },
      }).pipe(
        map((result) => {
          try {
            if (
              result.status === apiStatus.CREATED ||
              result.status === apiStatus.SUCCESS
            ) {
              // enqueueSnackbar('Successfully added idea section', {
              //   variant: 'success',
              // });
              return ideasGetSuccess(result);
            }
          } catch (error) {
            console.log("-----????  catch ?????-----", error);
          }
        }),
        catchError((error) =>
          errorHandler(error, enqueueSnackbar, [], catchErrorSuccess),
        ),
      ),
    ),
  );

const newRatingTemplateEpic = combineEpics(
  epicGetIdeas,
  epicGetForms,
  epicAddForms,
  epicAddFormSection,
  epicDeleteIdea,
  epicDeleteSection,
  epicPatchForms,
  epicDeleteForm,
  epicPatchFormSection,
  epicSearchIdeas,
  epicGetForm,
);

export default newRatingTemplateEpic;

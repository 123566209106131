import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import FormGenerator from "./form-generator";
import FormGeneratorWrap from "./form-generator-wrp/form-generator-wrp";

const UrlFormGenerator = () => {
  const API_HOST = process.env.REACT_APP_URL;
  const { councilSlug, formSlug } = useParams();
  const history = useHistory();
  const [form, setForm] = useState();

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(
        `${API_HOST}/api/v1/applicants/forms/${councilSlug}/${formSlug}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const data = await res.json();
      if (!data || !res.ok) {
        history.push("/submission-form/error");
        return;
      }

      setForm(data);
    }

    if (councilSlug && formSlug) {
      fetchData();
    }
  }, [councilSlug, formSlug]);

  if (!form) {
    return "";
  }

  return (
    <FormGeneratorWrap council={form.council} formSlug={form.form_name_slug}>
      <FormGenerator form={form} />
    </FormGeneratorWrap>
  );
};

export default UrlFormGenerator;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";
import { useSnackbar } from "notistack";
import styles from "./addSection.module.scss";
import { ModalBody, ModalFooter } from "../../../../application/modal";
import { useModelPopup } from "../../../../common/hooks";
import {
  Label,
  Button,
  BtnType,
  CheckBox,
  CheckType,
} from "../../../primitives";
import {
  setIdeaFormFields,
  sectionPatchAttempt,
  addLastStepWorkedOn,
} from "../adminIdeas.action";
import NewCheckListComponent from "../../../companies/company/details/newCheckList";
import NewDropdownComponent from "../../../../common/popups/newItem/newDropDown";
import classnames from "classnames";

const AddSectionModal = (props) => {
  const {
    mode,
    data,
    curStep,
    selectedCouncil,
    currentStepArray,
    ideas_template_id,
    addFieldClick,
    formContainsAreOfFocus,
  } = props;
  const {
    adminIdeasReducer: { currentIdeaFormFields },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [section_type, handleDropdownSelect] = useState("text");
  const [category_type, handleDropdownSelectCategory] = useState("description");
  const [required, setRequired] = useState(false);
  const [name, handleAddName] = useState("");
  const [prompt_text, handleAddPromt] = useState("");
  const [placeholder, handleAddPlaceholder] = useState("");
  const [errorValidation, setErrorValdation] = useState(false);

  const popup = useModelPopup();
  const formRef = React.createRef();
  const handleDismiss = () => {
    popup.hide();
  };

  const onSubmit = async () => {
    let options;
    let multiselect;
    let title;
    let promptFromDropdown;

    if (section_type === "checklist" || section_type === "dropdown") {
      options = formRef.current.values.options
        .filter((val) => val.length)
        .map((el) => el.trim());
      multiselect = formRef.current.values.multiselect;
      title = formRef.current.values.title;
      promptFromDropdown = formRef.current.values.field_placeholder;
    }

    const submitData = {
      section_name: title || name,
      section_type,
      step_nr: curStep,
      position: currentStepArray?.length ? currentStepArray.length + 1 : 1,
      prompt_text: promptFromDropdown || prompt_text,
      placeholder,
      ideas_template_id,
      council_id: selectedCouncil,
      category_type,
      field_required: required,
      multiselect,
      section_option_values: options,
    };

    if (mode === "edit") {
      dispatch(
        sectionPatchAttempt({
          data: submitData,
          id: data.ideas_section_id,
          enqueueSnackbar,
        })
      );
    } else {
      delete submitData.ideas_template_id;
      addFieldClick(submitData);
    }
    dispatch(setIdeaFormFields([...currentIdeaFormFields, submitData]));
    dispatch(
      addLastStepWorkedOn({ mostRecentStepWorkedOn: submitData.step_nr })
    );

    popup.hide();
  };

  const selectOptions = [
    {
      val: "text",
      text: "Text",
    },

    {
      val: "checklist",
      text: "Checklist",
    },
    {
      val: "dropdown",
      text: "Dropdown",
    },
  ];

  const renderBody = () => {
    switch (section_type) {
      case "checklist":
        return (
          <NewCheckListComponent
            data={{
              field_values: "",
              field_name: data?.section_name,
              field_placeholder: data?.prompt_text,
              option_selected: [],
              option_values: data?.section_option_values,
              multiselect: data?.multiselect,
            }}
            mode={mode}
            formRef={formRef}
            handleOnSubmit={onSubmit}
            errorValidation={errorValidation}
          />
        );
      case "dropdown":
        return (
          <NewDropdownComponent
            adminLocation
            errorValidation={errorValidation}
            formRef={formRef}
            mode={mode}
            data={{
              field_values: "",
              field_name: data?.section_name,
              field_placeholder: prompt_text,
              option_selected: [],
              option_values: data?.section_option_values,
              multiselect: data?.multiselect,
            }}
            handleOnSubmit={onSubmit}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <div className={styles.instructionText}>
              Your form will be available for all users.
            </div>
            <div className={classnames(styles.selectContainer, "row")}>
              <Label>Field type</Label>
              <div>
                <select
                  onChange={(val) => {
                    handleDropdownSelect(val.target.value);
                  }}
                  value={section_type}
                  className={styles.dropWrapper}
                >
                  {selectOptions.map((item) => (
                    <option key={item.val} value={item.val}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {!formContainsAreOfFocus && curStep !== 1 && (
              <div className={classNames(styles.selectContainer, "row")}>
                <Label>Add more fields to</Label>
                <div className={styles.categoryTypeRadioBlock}>
                  <div className={styles.categoryTypeRadioButton}>
                    <input
                      onChange={() =>
                        handleDropdownSelectCategory("description")
                      }
                      type="radio"
                      checked={category_type === "description"}
                    />
                    <label className={styles.radioButtonText}>
                      Description
                    </label>
                  </div>
                  <div className={styles.categoryTypeRadioButton}>
                    <input
                      onChange={() =>
                        handleDropdownSelectCategory("area_of_focus")
                      }
                      type="radio"
                      checked={category_type === "area_of_focus"}
                    />
                    <label className={styles.radioButtonText}>
                      Area of focus
                    </label>
                  </div>
                </div>
              </div>
            )}
            {section_type === "checklist" || section_type === "dropdown" ? (
              renderBody()
            ) : (
              <>
                <Label>Title or Question</Label>
                <div className={styles.textBoxWrp}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Add title name"
                    onChange={(val) => {
                      if (errorValidation) {
                        setErrorValdation(false);
                      }
                      handleAddName(val.target.value);
                    }}
                    value={name}
                    className={styles.textBox}
                  />
                </div>
                {errorValidation && (
                  <div className={styles.errorBox}>
                    <sup>*</sup>field is required
                  </div>
                )}
              </>
            )}
            {section_type !== "dropdown" && section_type !== "checklist" && (
              <>
                <Label>Prompt text</Label>
                <div className={styles.textBoxWrp}>
                  <input
                    type="text"
                    name="prompt_text"
                    placeholder="Add prompt text"
                    onChange={(val) => handleAddPromt(val.target.value)}
                    className={styles.textBox}
                    value={prompt_text}
                  />
                </div>

                <Label>Placeholder (optional)</Label>
                <div className={styles.textBoxWrp}>
                  <input
                    type="text"
                    name="placeholder"
                    placeholder="Add placeholder text"
                    value={placeholder}
                    onChange={(val) => handleAddPlaceholder(val.target.value)}
                    className={styles.textBox}
                  />
                </div>
              </>
            )}
            <div
              className={classNames(styles.formElement, styles.multiSelectWrp)}
            >
              <CheckBox
                checkType={CheckType.BLUE}
                isChecked={required}
                onChange={(value) => setRequired(value)}
              />
              <Label>Required</Label>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.OUTLINE} onClick={handleDismiss}>
          Cancel
        </Button>
        <Button
          type="submit"
          btn={BtnType.HIGHLIGHT}
          className={styles.button}
          onClick={() => {
            if (section_type === "checklist" || section_type === "dropdown") {
              if (!formRef?.current?.values?.title) {
                setErrorValdation(true);
                return;
              }
              setErrorValdation(false);
            }
            if (section_type === "text" && name === "") {
              setErrorValdation(true);
              return;
            }
            if (formRef.current) {
              formRef.current.submitForm();
            }
            onSubmit();
          }}
        >
          {mode === "edit" ? "Save" : "Add"}
        </Button>
      </ModalFooter>
    </>
  );
};

export default AddSectionModal;

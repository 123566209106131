import React, { useCallback } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";

import styles from "./editCompany.module.scss";
import { BtnType, Button, CheckBox, CheckType } from "../../../primitives";
import { validation, validationPO } from "./editCompany.constants";
import {
  handleTopicChange,
  handleBusinessChange,
} from "./editCompany.helper.js";
import EditCompanyFormHeader from "./editCompanyFormHeader.component";
import EditCompanyFormMiddle from "./editCompanyFormMiddle.component";
import EditCompanyFormEnd from "./editCompanyFormEnd.component";

const EditCompanyForm = (props) => {
  const {
    handleOnSubmit,
    formRef,
    model,
    imgLoading,
    setIndustries,
    setSearchValue,
    setSelectedTopics,
    searchValue,
    isCrunchbase,
    setIsCrunchbase,
    blockAsCB,
    businessUnitsOptionsFirmenich,
    selectedBusinessUnitsValues,
    setSelectedBusinessUnitsValues,
  } = props;
  const history = useHistory();

  const businessDropdownRenderer = useCallback(
    ({ props }) => {
      return (
        <div className="d-flex flex-column react-dropdown-select-dropdown">
          {businessUnitsOptionsFirmenich?.length
            ? businessUnitsOptionsFirmenich?.map((item, idx) => {
                const isSelected = selectedBusinessUnitsValues?.find(
                  (el) => el.id === item.id
                );

                return (
                  <span
                    onClick={() => {
                      handleBusinessChange({
                        val: item,
                        selectedBusinessUnitsValues,
                        setSelectedBusinessUnitsValues,
                        setSearchValue,
                      });
                    }}
                    key={`${item.name}-${idx}`}
                    className={`react-dropdown-select-item
                            ${isSelected && styles.selectedItemDrop}`}
                  >
                    {item[props.labelField]}
                  </span>
                );
              })
            : "No data"}
        </div>
      );
    },
    [businessUnitsOptionsFirmenich, selectedBusinessUnitsValues]
  );

  const businessContentRenderer = useCallback(
    ({}) => {
      return (
        <div className="d-flex flex-wrap  react-dropdown-select-dropdown">
          {Boolean(selectedBusinessUnitsValues?.length) &&
            selectedBusinessUnitsValues.map((item, idx) => {
              return (
                <div
                  key={`${item?.name}-${idx}`}
                  className={styles.dropdownSelected}
                >
                  <span>{item?.name}</span>
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      handleBusinessChange({
                        val: item,
                        selectedBusinessUnitsValues,
                        setSelectedBusinessUnitsValues,
                        setSearchValue,
                      });
                    }}
                    className={styles.deleteLetter}
                  >
                    ✕
                  </span>
                </div>
              );
            })}
          <input
            value={searchValue}
            placeholder={
              !Boolean(selectedBusinessUnitsValues.length) && "Select..."
            }
            className={styles.dropSelectInput}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </div>
      );
    },
    [selectedBusinessUnitsValues, searchValue]
  );

  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={model && model}
        validationSchema={
          history?.location?.state?.gsk ? validationPO : validation
        }
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (!imgLoading) {
            setSubmitting(true);
            handleOnSubmit(values);
            resetForm();
          }
        }}
      >
        {({ handleSubmit, setFieldValue, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <EditCompanyFormHeader
              {...props}
              formikprops={formikprops}
              setFieldValue={setFieldValue}
            />
            <EditCompanyFormMiddle
              {...props}
              formikprops={formikprops}
              setFieldValue={setFieldValue}
            />
            <EditCompanyFormEnd
              {...props}
              formikprops={formikprops}
              setFieldValue={setFieldValue}
              businessDropdownRenderer={businessDropdownRenderer}
              businessContentRenderer={businessContentRenderer}
              handleTopicChange={handleTopicChange}
              setSelectedTopics={setSelectedTopics}
              setIndustries={setIndustries}
            />
            <div className={styles.buttonWrp}>
              <Button
                type="submit"
                btn={imgLoading ? BtnType.DISABLED : BtnType.REGULAR}
                className={styles.add}
                onClick={handleSubmit}
              >
                Save
              </Button>
              {history?.location?.state?.fromTractionAdmin && blockAsCB && (
                <div className={styles.checkItemCB}>
                  <CheckBox
                    checkType={CheckType.BLUE}
                    isChecked={isCrunchbase}
                    onChange={() => setIsCrunchbase(!isCrunchbase)}
                  />
                  <div className={styles.checkLabel}>Crunchbase</div>
                </div>
              )}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default EditCompanyForm;

import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import IdeaProfileTheIdea from "./theIdea";
import IdeaProfileSubmitter from "./submitter";
import IdeaProfileTeam from "./team";
import IdeaProfileDocuments from "./documents";
import IdeaProfileAreasOfFocus from "./areasOfFocus";
// import IdeaProfileNotes from './notes';
import IdeaProfileReason from "./reason";
import IdeaProfileComments from "./comments";
import IdeaProfileProjects from "./ideaProjects";
import IdeaProfileCompanies from "./companies";
import IdeaProfileThemes from "./themes";
import styles from "./ideaProfileOverview.module.scss";
import IdeaFordPlants from "modules/ideasWrapper/ideaProfileWrapper/ideaProfileOverview/ideaFordPlants";
import FordPossibleReplications from "../../../../common/components/FordPossibleReplications";
import IdeaRatings from "./ratings";

const IdeaProfileOverview = ({ canEdit }) => {
  const {
    ideaReducer: { selectedIdea },
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);

  const canManageReplications = useMemo(() => {
    if (
      session?.council_role !== "ttp_administrator" &&
      session?.council_role !== "council_administrator"
    ) {
      return session?.spoc || session?.innovation_leader;
    }

    return canEdit;
  }, [canEdit, session]);

  return (
    <div className={styles.overViewWrapper}>
      <div className="row w-100 mt-3">
        <div className="col-12 col-lg-8 pl-0">
          <IdeaProfileTheIdea
            canEdit={canEdit}
            ideaDetails={selectedIdea}
            selectedCouncil={selectedCouncil}
          />
          {/* <IdeaProfileNotes /> */}
          <IdeaProfileAreasOfFocus
            canEdit={canEdit}
            selectedIdea={selectedIdea}
            selectedCouncil={selectedCouncil}
          />
          <IdeaProfileComments canEdit={canEdit} selectedIdea={selectedIdea} />
        </div>
        <div className="col-12 col-lg-4 pr-0">
          {selectedCouncil?.name === "Ford" && (
            <>
              <IdeaFordPlants canEdit={canEdit} />
              <FordPossibleReplications
                data={selectedIdea}
                canEdit={canManageReplications}
                instance="Idea"
              />
            </>
          )}
          <IdeaProfileSubmitter canEdit={canEdit} selectedIdea={selectedIdea} />
          <IdeaProfileTeam canEdit={canEdit} selectedIdea={selectedIdea} />
          <IdeaProfileTeam
            isReviewersInstance
            canEdit={canEdit}
            selectedIdea={selectedIdea}
          />
          { selectedIdea.rating_template_id && <IdeaRatings idea={selectedIdea} /> }
          {selectedIdea.status !== "submitted" && (
            <IdeaProfileReason
              selectedIdea={selectedIdea}
              selectedCouncil={selectedCouncil}
            />
          )}
          {session?.council_role !== "company" && (
            <IdeaProfileProjects
              selectedIdea={selectedIdea}
              canEdit={canEdit}
            />
          )}
          {selectedCouncil?.themes_connected &&
            (selectedCouncil?.settings.theme_ideas_enabled ||
              selectedCouncil?.settings.theme_ideas_enabled === undefined) && (
              <IdeaProfileThemes
                selectedIdea={selectedIdea}
                canEdit={canEdit}
                selectedCouncil={selectedCouncil}
              />
            )}
          <IdeaProfileDocuments canEdit={canEdit} selectedIdea={selectedIdea} />
          {session?.council_role !== "company" && (
            <IdeaProfileCompanies
              selectedIdea={selectedIdea}
              canEdit={canEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(IdeaProfileOverview);

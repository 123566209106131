import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CheckBox, CheckType, Button, BtnType,
} from "modules/primitives/index";
import { ModalFooter, ModalBody } from "application/modal";
import styles from "../adminCompanies.module.scss";
import { putTractionCompaniesHideAttempt } from "../tractionCompanies.actions";

const HideCompanyModal = (props) => {
  const {
    popup, councils, enqueueSnackbar, company,
  } = props;

  const dispatch = useDispatch();
  const [selectedCouncils, setSelectedCouncils] = useState([]);
  const handleSubmit = () => {
    const filteredCouncils = selectedCouncils.filter(
      (council) => council.selected && !council.blocked,
    );
    const data = filteredCouncils.map((council) => council.id);
    dispatch(
      putTractionCompaniesHideAttempt({
        data,
        enqueueSnackbar,
        companyId: company.id,
      }),
    );
    popup.hide();
  };

  useEffect(() => {
    if (councils.length) {
      const mappedCouncils = councils.map((council) => {
        if (council.discarded_at) {
          return {
            ...council,
            blocked: true,
            selected: true,
          };
        }
        return {
          ...council,
          blocked: false,
          selected: false,
        };
      });
      setSelectedCouncils(mappedCouncils);
    }
  }, [councils]);

  const handleChange = (val, councilId) => {
    const newCouncils = selectedCouncils.map((council) => {
      if (council.id === councilId) {
        return {
          ...council,
          selected: val,
        };
      }
      return council;
    });
    setSelectedCouncils(newCouncils);
  };

  return (
    <>
      <ModalBody>
        <div className={styles.radioContainer}>
          {selectedCouncils.map((council) => (
            <div className={styles.checkboxRow}>
              <CheckBox
                checkType={
                  council.blocked ? CheckType.DISABLED : CheckType.BLUE
                }
                isChecked={council.selected}
                disabled={council.blocked}
                onChange={(e) => handleChange(e, council.id)}
              />
              <span>{council.name}</span>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={() => popup.hide()}>
          Close
        </Button>
        <Button btn={BtnType.REGULAR} onClick={handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
};

export default HideCompanyModal;

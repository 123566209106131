export const PROJECT_STAGES_TEMPLATES_GET_ATTEMPT = "PROJECT_STAGES_TEMPLATES_GET_ATTEMPT";
export const projectStagesTemplatesGet = (payload) => ({
  type: PROJECT_STAGES_TEMPLATES_GET_ATTEMPT,
  payload,
});

export const PROJECT_STAGES_TEMPLATES_GET_SUCCESS = "PROJECT_STAGES_TEMPLATES_GET_SUCCESS";
export const projectStagesTemplatesGetSuccess = (payload) => ({
  type: PROJECT_STAGES_TEMPLATES_GET_SUCCESS,
  payload,
});

export const OPTIONS_SCORE_GET_ATTEMPT = "OPTIONS_SCORE_GET_ATTEMPT";
export const optionsScoreGet = (payload) => ({
  type: OPTIONS_SCORE_GET_ATTEMPT,
  payload,
});

export const OPTIONS_SCORE_GET_SUCCESS = "OPTIONS_SCORE_GET_SUCCESS";
export const optionsScoreGetSuccess = (payload) => ({
  type: OPTIONS_SCORE_GET_SUCCESS,
  payload,
});

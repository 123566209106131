import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import classNames from "classnames";
import { withSnackbar } from "notistack";

import { useQuery } from "common/helper";
import {
  BtnType,
  Button,
  Label,
  TextBox,
  DropSelect,
} from "modules/primitives/index";
import { tagCustomAttempt } from "../adminTags.actions";
import { useModelPopup } from "../../../../common/hooks/index";
import styles from "../adminTags.module.scss";
import { ModalBody, ModalFooter } from "../../../../application/modal";
import { tagValidation, tagModel } from "./tagModal.constants";

const AddCustomTagModal = ({
  enqueueSnackbar,
  mode,
  selectedCustomGroupId,
  customTagsGroups,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const popup = useModelPopup();
  const [topicCreateName, setTopicCreateName] = useState("");
  const customGroupName = useQuery().get("group");
  const [parentTag, setParentTag] = useState({});
  const [mappedTags, setMappedTags] = useState([]);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleOnSubmit = () => {
    const data = {
      name: topicCreateName,
      parent_id: parentTag?.attributes?.custom_tag_id || null,
    };

    if (mode === "add") {
      dispatch(
        tagCustomAttempt({
          enqueueSnackbar,
          data,
          groupID:
            selectedCustomGroupId ||
            customTagsGroups?.find((group) => group.name === customGroupName)
              ?.id,
        })
      );
    }

    popup.hide();
  };

  useEffect(() => {
    const tags = customTagsGroups?.find(
      (group) => group.name === customGroupName
    )?.custom_tags;

    if (tags?.length) {
      const newTags = tags.map((tag) => ({ name: tag.attributes.name, ...tag }));
      setMappedTags(newTags);
    } else {
      setMappedTags([]);
    }
  }, [mode]);

  const handleParentTagChange = (val) => {
    setParentTag(val);
  };

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={mode === "edit" ? {} : tagModel}
        validationSchema={tagValidation}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <ModalBody>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <p className={styles.aditionalInfo}>
                  Your Custom Tags will be available for all companies.
                </p>
                <div className={styles.addRatingTempForm}>
                  <div className={styles.row}>
                    <div className={classNames(styles.col12)}>
                      <Label>Tag Name</Label>
                      <TextBox
                        autoComplete="false"
                        type="text"
                        value={topicCreateName}
                        className={styles.textBox}
                        onChange={(e) => {
                          setTopicCreateName(e.target.value);
                        }}
                        onFocus={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.col12}>
                      <Label>Parent tag</Label>
                      <div className={styles.textBoxWrp}>
                        <DropSelect
                          SelecedValues={parentTag.id}
                          onChange={() => undefined}
                          placeholder={`${parentTag.name || "Parent tag"}`}
                          labelField="name"
                          disabled={false}
                          valueField="id"
                          searchBy="name"
                          options={mappedTags}
                          itemRenderer={({ item }) => {
                            return (
                              <div
                                onClick={(e) => handleParentTagChange(item)}
                                style={{
                                  width: "180px",
                                  backgroundColor: `${item.value}`,
                                  borderRadius: 5,
                                  marginTop: "10px",
                                  alignContent: "center",
                                  marginLeft: "10px",
                                  paddingLeft: "5px",
                                }}
                              >
                                <div>{item.name}</div>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  handleOnSubmit();
                }}
                btn={BtnType.REGULAR}
              >
                {mode === "edit" ? "Submit" : "Add"}
              </Button>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(withSnackbar(AddCustomTagModal));

import React, { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import { appModelSettingSet } from "application/app.action";
import { Label, BtnType, Button, } from "modules/primitives";
import MixedSelectSearch from "./index";
import { ModalFooter } from "application/modal";
import styles from "./styles.module.scss";
import { queryStringUrlReplacement } from "../../helper";
import useModelPopup from "../../hooks/modelPopup.hook";

const MixedAddCompanyOrProducts = ({ handleSave, patientOrg, placeholder, label, existingItems, hideProducts }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const popup = useModelPopup();

  const {
    appReducer: { modalSetting },
  } = useSelector((state) => state);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const handleOptionSelect = (values) => {
    setSelectedCompanies(values)
  }

  const handleCancelClick = () => {
    dispatch(appModelSettingSet({ ...modalSetting, show: false }))
  }

  const handleCompaniesSave = () => {
    if (!selectedCompanies.length) {
      enqueueSnackbar(`Please select at least ${patientOrg ? "a organization" : "a company or a product"}!`, {
        variant: "error",
      })
      return
    }

    handleSave(selectedCompanies);
  }

  const redirectToCrunchBase = useCallback(
    (query) => {
      let search = queryStringUrlReplacement(
        history.location.search,
        "isCrunchSearch",
        true
      );
      search = queryStringUrlReplacement(search, "page", 1);

      if (query) {
        search = queryStringUrlReplacement(search, "query", query);
      }

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          isCrunchSearch: true,
          page: 1,
          query: query,
        },
      });

      popup.hide();
    },
    [popup, history]
  );

  return (
    <div>
      <div className={styles.customPadding}>
        <Label>{label}</Label>
        <div className={styles.selectorWrp}>
          <MixedSelectSearch
            labelField="name"
            valueField="id"
            placeholder={placeholder}
            onChange={handleOptionSelect}
            filterBy={existingItems}
            redirectToCrunchBase={redirectToCrunchBase}
            hideProducts={hideProducts}
            patientOrg={patientOrg}
          />
        </div>
      </div>
      <ModalFooter>
        <Button className="mr-3" btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button onClick={handleCompaniesSave} btn={BtnType.REGULAR}>
          Save
        </Button>
      </ModalFooter>
    </div>
  )
};

export default memo(MixedAddCompanyOrProducts);

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { differenceInCalendarDays, format, parseISO } from "date-fns";
import { useSnackbar } from "notistack";

import { queryStringUrlReplacement } from "common/helper";
import {
  State,
  UserInline,
  UserInlineTypes,
  CheckBox,
  CheckType,
  CompanyAvatar,
} from "../../../../primitives";
import { Icon, mdSize } from "../../../../../common/icon";
import styles from "./companyRow.module.scss";
import { TaskInfoComponentV2, TaskInfoHeaderComponentV2 } from "../taskInfo";
import {
  appSliderSettingSet,
  appSelectedSet,
} from "application/app.action";
import { sliderSetting } from "application/app.constants";
import { projectTaskUpdate, taskCompanyDocumentsSave } from "../tasks.action";
import TaskDocumentsModal from "../taskRow/taskDocumentsModal/index";
import { useModelPopup } from "../../../../../common/hooks";

const CompanyRowComponent = (props) => {
  const {
    obj, task, blockFunctionality = false, completionEnabled = true, upcomingTaskInstance,
  } = props;
  const dispatch = useDispatch();
  const [dateDiff, setDateDiff] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const popup = useModelPopup();

  const {
    appReducer: { selectedItem },
  } = useSelector((state) => state);

  useEffect(() => {
    if (!obj.due_date) return;

    const diffInDays = differenceInCalendarDays(
      parseISO(obj.due_date),
      new Date(),
    );
    setDateDiff(diffInDays);

    return () => null;
  }, [obj]);

  const handleDownloadDocument = (event, company) => {
    event.stopPropagation();

    popup.show({
      title: "Task Documents",
      component: <TaskDocumentsModal popup={popup} company={company} />,
    });
  };

  const handleShowTask = () => {
    const search = queryStringUrlReplacement(
      queryStringUrlReplacement(
        history.location.search,
        "taskId",
        obj.projects_task_id,
      ),
      "taskAsignementId",
      obj.id,
    );

    history.push({
      pathname: history.location.pathname,
      search,
      state: {
        ...history.location?.state,
        taskAsignementId: obj.id,
        taskId: obj.projects_task_id,
      },
    });

    history.push({
      pathname: history.location.pathname,
      search,
      state: {
        ...history.location?.state,
        taskAsignementId: obj.id,
        taskId: obj.projects_task_id,
      },
    });
    dispatch(taskCompanyDocumentsSave({ documents: obj.documents }));
    dispatch(appSelectedSet(`task${obj.id}`));
    dispatch(
      appSliderSettingSet({
        ...sliderSetting,
        show: true,
        height: "300",
        width: "540",
        header: (
          <TaskInfoHeaderComponentV2
            taskId={obj.projects_task_id}
            projectId={task.project_id}
            assignmentId={obj.id}
            completed={obj.completed}
            completionEnabled={completionEnabled}
          />
        ),
        component: <TaskInfoComponentV2 task={task} assignment={obj} />,
      }),
    );
  };

  const handleOnChange = (value) => {
    dispatch(
      projectTaskUpdate({
        enqueueSnackbar,
        taskId: obj.projects_task_id,
        projectId: task.project_id,
        flag: value ? "completed_true" : "completed_false",
        project_task: {
          task_assignments: {
            completed_on: value ? format(new Date(), "yyyy-MM-dd") : null,
          },
        },
        queryParams: {
          task_assignment_id: obj.id,
        },
      }),
    );
  };

  return (
    <div
      key={obj.id}
      className={classNames(
        styles.taskRow,
        selectedItem === `task${obj.id}` && styles.selected,
      )}
      onClick={handleShowTask}
    >
      {
        !upcomingTaskInstance && (
          <div className={styles.colSorting}>
            {/* <Icon {...mdSize} icon={'icn-form-move'} className={styles.icon} /> */}
          </div>
        )
      }
      <div className={styles.colCheck} style={{ opacity: !completionEnabled ? 0.3 : 1 }}>
        {!blockFunctionality && (
          <CheckBox
            disabled={!completionEnabled}
            isChecked={obj.completed}
            checkType={CheckType.BLUE}
            onChange={(e) => handleOnChange(e)}
          />
        )}
      </div>
      <div className={classNames(styles.colTask, styles.colCompanyName)}>
        <div className={styles.iconCon}>
          <CompanyAvatar
            imgSrc={obj.projects_task_organization?.organization?.logo}
            name={obj.projects_task_organization?.organization?.name}
          />
        </div>
        <div>{obj.projects_task_organization?.organization?.name}</div>
      </div>
      <div className={classNames(styles.colActivity)}>
        {obj.last_activity?.details || "No activity yet"}
      </div>
      <div className={classNames(styles.colLastActivity)}>
        {obj.last_activity?.created_at
          ? `${obj.last_activity?.created_at} ago`
          : "-"}
      </div>
      <div className={classNames(styles.taskcol, styles.colStage)}>
        <State stageId={task.stage_id} />
      </div>
      {obj.assigned_to && (
        <Tooltip
          title={`${obj.assigned_to && obj.assigned_to.first_name} ${
            obj.assigned_to && obj.assigned_to.last_name
          }`}
        >
          <div className={classNames(styles.taskcol, styles.colAssign)}>
            <UserInline
              local
              noNavigation
              userId={obj.assigned_to.id}
              avatar={obj.assigned_to.avatar}
              first={obj.assigned_to.first_name}
              last={obj.assigned_to.last_name}
              type={UserInlineTypes.IMAGE_ONLY}
            />
          </div>
        </Tooltip>
      )}
      <div
        className={classNames(
          styles.taskcol,
          styles.colDue,
          dateDiff < 0 && !obj.completed && styles.expired,
          dateDiff <= 3 && dateDiff >= 0 && !obj.completed && styles.progress,
          obj.completed && styles.completed,
        )}
      >
        {obj.due_date_in_words}
      </div>

      <div className={classNames(styles.taskcol, styles.colmore)}>
        {/* <Icon {...mdSize} icon={'icn-more'} className={styles.icon} /> */}
      </div>
      <div className={classNames(styles.taskcol, styles.colmore)}>
        {Boolean(obj.documents.length) && (
          <div className={styles.docIcon}>
            <Icon
              icon="icn-subnav-documents"
              onClick={(event) => handleDownloadDocument(event, obj)}
              {...mdSize}
            />
          </div>
        )}
      </div>
      <div className={classNames(styles.taskcol, styles.colOther)}>
        <Icon {...mdSize} icon="icn-right-chevron" className={styles.icon} />
      </div>
    </div>
  );
};

export default React.memo(CompanyRowComponent);

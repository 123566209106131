import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { BtnType, Button } from "modules/primitives";
import { AISetCompanySnapshotData } from "modules/tractionAI/store/tractionAI.actions";
import { httpPost } from "../../../../../common/httpCall";
import LoadingComponent from "modules/primitives/loading/loading.component";
import CompaniesMultiSelectSearch from "../../../../../common/components/companiesMultiSelectSearch";
import RandomAILoader from "modules/tractionAI/components/randomAILoader";

const TractionAICompanySnapshotFooter = () => {
  const {
    tractionAiReducer: { insight: { companySnapshot } },
  } = useSelector((state) => state);

  const [selected, setSelected] = useState([]);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(AISetCompanySnapshotData({
      fetching: true,
      searchByQuery: selected[0]?.name,
      createNewSearchItem: true,
      fetched: false,
    }));

    httpPost({
      call: "ai/proxy/streaming", data: { endpoint: "company_snapshot", company_id: selected[0]?.id },
    }).subscribe(() => {
      setSelected([]);
    });
  };

  const handleCompaniesSelect = (companies) => {
    setSelected(companies);
  }

  const getLoadingTemplate = useCallback((data) => {
    if (!data || data?.length === 0) {
      return <RandomAILoader size="big" />
    }

    const lastItem = data[data.length - 1];
    const hasData = lastItem.data.length > 0;

    if (hasData) {
      return <LoadingComponent customText="Fetching Company Snapshot details..." />
    }

    return <RandomAILoader size="big" />
  }, [companySnapshot.data]);

  return (
    <div className="d-flex flex-row align-items-center w-100 p-2">
      {
        (companySnapshot.fetching ? (
          <div className="d-flex align-items-center w-100 justify-content-center">
            {getLoadingTemplate(companySnapshot.data)}
          </div>
        )
          : (
            <div className="mt-5 w-100 d-flex justify-content-between">
              <CompaniesMultiSelectSearch
                onChange={handleCompaniesSelect}
                placeholder="Select Companies"
                labelField="name"
                valueField="id"
                dropdownPosition="top"
                multi={false}
                endpoint="companies/traction_db_search"
              />
              <div className="ml-5">
                <Button
                  btn={BtnType.REGULAR}
                  onClick={handleSubmit}
                  disabled={selected.length === 0}
                >SUBMIT</Button>
              </div>
            </div>
            )
        )
      }
    </div>
  );
};

export default React.memo(TractionAICompanySnapshotFooter);

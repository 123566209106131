const findCurrentCustomFieldGroup = (
  selectedCustomFieldGroup,
  customFieldGroups,
  newCustomFieldGroups,
  indexOfCfGroup,
  data
) => {
  if (selectedCustomFieldGroup && selectedCustomFieldGroup[0]) {
    const currentCFGroup = customFieldGroups.find((elem) => {
      if (
        elem.custom_field_group_id ===
        selectedCustomFieldGroup[0].custom_field_group_id
      ) {
        return elem;
      }
    });
    return currentCFGroup.custom_field_templates;
  }
  if (data) {
    const tempGroup = newCustomFieldGroups.find((customFieldGroup) => {
      if (customFieldGroup.groupDetails.id === data?.custom_field_group_id) {
        return customFieldGroup;
      }
    });
    if (tempGroup?.customFields) {
      return tempGroup?.customFields;
    }
  }
  return newCustomFieldGroups[indexOfCfGroup]?.customFields;
};
export default findCurrentCustomFieldGroup;

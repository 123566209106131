import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withSnackbar } from "notistack";
import styles from "./success-sso-login.module.scss";
import { loginAttempt } from "../auth.action";
import { LoginStatus } from "../auth.constants";
import classnames from "classnames";
import Appsignal from "@appsignal/javascript";

const SuccessSsoLoginComponent = ({ enqueueSnackbar }) => {
  const API_HOST = process.env.REACT_APP_URL;
  const location = useLocation();
  const params = useLocation().search;
  const history = useHistory();
  const errorStatus = new URLSearchParams(params).get("error");
  const errorDescription = new URLSearchParams(params).get("error_description");
  const state = new URLSearchParams(params).get("state");
  const authorizationCode = new URLSearchParams(params).get("code");
  const test = new URLSearchParams(params).get("testSSO");
  const testSSO = test || false;
  const workOs = new URLSearchParams(params).get("workos");
  const dispatch = useDispatch();

  const {
    authReducer: { loginStatus, session },
  } = useSelector((state) => state);
  const appSignal =
    process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "prod"
      ? new Appsignal({
          key:
            process.env.REACT_APP_ENV === "dev"
              ? process.env.REACT_APP_APPSIGNAL_DEV
              : process.env.REACT_APP_APPSIGNAL,
        })
      : undefined;

  useEffect(() => {
    if (workOs) {
      localStorage.setItem("workOs", workOs);
    }
  }, [workOs]);

  useEffect(() => {
    if (loginStatus === LoginStatus.LOGIN_SUCCESS && workOs) {
      history.push("/");
      return;
    }

    if (
      (loginStatus === LoginStatus.LOGIN_SUCCESS &&
        session.password_updated_at) ||
      (loginStatus === LoginStatus.LOGIN_SUCCESS && session.sign_in_count >= 2)
    ) {
      history.push("/");
    } else if (
      loginStatus === LoginStatus.LOGIN_SUCCESS &&
      !session.password_updated_at
    ) {
      history.push("/setup/profile", { showPopup: true });
    }
  }, [loginStatus, session, workOs]);

  useEffect(() => {
    if (authorizationCode) {
      const tractionTag = localStorage.getItem("tractionTag");
      fetch(
        `${API_HOST}/sso?code=${authorizationCode}&testSSO=${testSSO}&workos=${workOs}&traction_tag=${tractionTag}`,
        {
          method: "GET",
          header: {
            "Content-Type": "application/json",
            Accept: "json",
          },
        }
      )
        .then((response) => response.json())
        .then((res) => {
          if (res && res.access_token) {
            const data = {
              sso: true,
              access_token: res.access_token,
              profile: res.profile,
              workos: workOs,
            };

            if (data) {
              dispatch(loginAttempt({ data, enqueueSnackbar }));
            } else {
              enqueueSnackbar(
                "Something wrong happened when authorizing user",
                {
                  variant: "error",
                }
              );

              const timeout = setTimeout(() => {
                clearTimeout(timeout);
                window.open("/login", "_self");
              }, 3000);
            }
          } else {
            enqueueSnackbar(
              "It seems there was an issue while attempting to retrieve your Access Token. " +
                "Please review your login details and ensure they are correct. If the problem persists, feel free to " +
                "reach out to our support team at support@traction.network for prompt assistance. We apologize for any " +
                "inconvenience this may have caused.",
              {
                variant: "error",
              }
            );

            localStorage.removeItem("council");
            localStorage.removeItem("session");
            localStorage.removeItem("tractionTag");
            localStorage.removeItem("workOs");

            const timeout = setTimeout(() => {
              clearTimeout(timeout);
              window.open("/login", "_self");
            }, 3000);

            const userEmail = res.profile.email || session?.email;
            const errorMessage = `error: "No access token", error_description: ${errorDescription}, user_email: ${userEmail}, council: ${tractionTag}`;
            appSignal.sendError(new Error(errorMessage), (span) => {
              span.setAction("SuccessSsoLoginComponent");
              span.setTags({
                location: location,
                error_status: errorStatus,
                error_description: errorDescription,
                state: state,
                authorization_code: authorizationCode,
                user_email: userEmail,
                council: tractionTag,
                workos: workOs,
                browser: navigator.userAgent,
              });
            });
          }
        })
        .catch(() => {
          enqueueSnackbar("Something wrong happened", {
            variant: "error",
          });

          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            window.open("/login", "_self");
          }, 3000);
        });
    }
    return () => null;
  }, [authorizationCode]);

  if (errorStatus) {
    let tractionTag = localStorage.getItem("tractionTag");
    let errorMessage = `error: ${errorStatus}, error_description: ${errorDescription}, council: ${tractionTag}`;
    appSignal.sendError(new Error(errorMessage), (span) => {
      span.setAction("SuccessSsoLoginComponent");
      span.setTags({
        location: location,
        authorization_code: authorizationCode,
        council: tractionTag,
        workos: workOs,
        browser: navigator.userAgent,
      });
    });

    localStorage.removeItem("council");
    localStorage.removeItem("session");
    localStorage.removeItem("tractionTag");
    localStorage.removeItem("workOs");
  }

  if (errorStatus === "connection_invalid") {
    return (
      <div className={styles.successLoginSSO}>
        <div className={styles.successLoginSSOCo}>
          <h1 className={classnames("text-center text-danger", styles.Title)}>
            Your email was not authorized. Please contact your IT department.
          </h1>
        </div>
      </div>
    );
  }
  if (errorStatus === "not_found") {
    return (
      <div className={styles.successLoginSSO}>
        <div className={styles.successLoginSSOCo}>
          <h1 className={classnames("text-center text-danger", styles.Title)}>
            Your email was not found. Please contact your IT department.
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.successLoginSSO}>
      <div className={styles.successLoginSSOCo}>
        <h1 className={styles.Title}>
          Please wait a few.
          <br />
          We're making the final step....
        </h1>
      </div>
    </div>
  );
};

export default React.memo(withSnackbar(SuccessSsoLoginComponent));

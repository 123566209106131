export const moreOptionTypes = {
  delete: 1,
  edit: 2,
};

export const moreOptions = [
  {
    val: moreOptionTypes.delete,
    text: "Remove From Theme",
  },
  {
    val: moreOptionTypes.edit,
    text: "Change role",
  },
  {
    childrens: [
      {
        text: "Expert",
        val: "expert",
      },
    ],
  },
];

import React from "react";
import classNames from "classnames";
import { UserInline, UserInlineTypes } from "../../../../primitives";
import styles from "./tweets.module.scss";

const TweetsComponent = ({ company, tweets }) => {
  const goToTwitter = (item) => {
    window.open(`${item.uri.scheme}://${item.uri.host}/${item.uri.path}`);
  };

  return (
    <div className={styles.wrapper}>
      {tweets.map((item) => (
        <div
          key={item.id}
          className={styles.itemContainer}
          onClick={() => goToTwitter(item)}
        >
          <div className={styles.firstRow}>
            <div className={styles.userWrp}>
              <UserInline
                className={styles.userIcon}
                classObj={{ name: styles.userName }}
                userId={company.name}
                type={UserInlineTypes.WITH_NAME}
                showMine={false}
                first={company.name}
                last=""
                avatar={company.logo}
                local
              />
            </div>
            <div className={classNames("caption", styles.time)}>
              {item.created_at} ago
            </div>
          </div>
          <div className={styles.twitterText}>{item.full_text}</div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(TweetsComponent);

import { combineReducers } from "redux";
import {
  GET_CLAIM_PROFILE_OVERVIEW_ATTEMPT,
  GET_CLAIM_PROFILE_OVERVIEW_SUCCESS,
  UPDATE_CLAIM_PROFILE_SUCCESS,
} from "./claimProfileActions";
import rfiReducer from "../informationRequests/store/rfiReducer";
import { SAVE_DETAILS_SUCCESS } from "../informationRequests/store/rfiActions";
import {
  ADD_FUNDING_ITEM_SUCCESS,
  ADD_LEADERSHIP_ITEM_SUCCESS,
  DELETE_LEADERSHIP_ITEM_SUCCESS,
  DELETE_TOTAL_FUNDING_ITEM_SUCCESS,
  EDIT_FUNDING_ITEM_SUCCESS,
  EDIT_LEADERSHIP_ITEM_SUCCESS,
  GET_FUNDING_ITEMS_SUCCESS,
  GET_LEADERSHIP_ITEMS_SUCCESS,
} from "../../../common/actions/common.action";

const initState = {
  claimProfile: {
    loading: false,
    profile: null,
    leadership_members: [],
    funding_rounds: [],
  },
};

const claimReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_FUNDING_ITEMS_SUCCESS: {
      if (state.claimProfile.profile) {
        return {
          ...state,
          claimProfile: {
            ...state.claimProfile,
            funding_rounds: payload.response,
          },
        };
      }

      return state;
    }

    case GET_LEADERSHIP_ITEMS_SUCCESS: {
      if (state.claimProfile.profile) {
        return {
          ...state,
          claimProfile: {
            ...state.claimProfile,
            leadership_members: payload.response,
          },
        };
      }

      return state;
    }

    case EDIT_LEADERSHIP_ITEM_SUCCESS: {
      if (state.claimProfile.profile) {
        return {
          ...state,
          claimProfile: {
            ...state.claimProfile,
            leadership_members: state.claimProfile?.leadership_members.map(
              (f) => {
                if (f.id === payload.response.id) {
                  return {
                    ...f,
                    ...payload.response,
                  };
                }

                return f;
              },
            ),
          },
        };
      }

      return state;
    }

    case ADD_LEADERSHIP_ITEM_SUCCESS: {
      if (state.claimProfile.profile) {
        return {
          ...state,
          claimProfile: {
            ...state.claimProfile,
            leadership_members: [
              payload.response,
              ...(state.claimProfile?.leadership_members || []),
            ],
          },
        };
      }

      return state;
    }

    case DELETE_LEADERSHIP_ITEM_SUCCESS: {
      if (state.claimProfile.profile) {
        return {
          ...state,
          claimProfile: {
            ...state.claimProfile,
            leadership_members: state.claimProfile.leadership_members.filter(
              (f) => f.id !== payload.id,
            ),
          },
        };
      }

      return state;
    }

    case EDIT_FUNDING_ITEM_SUCCESS: {
      if (state.claimProfile.profile) {
        return {
          ...state,
          claimProfile: {
            ...state.claimProfile,
            funding_rounds: state.claimProfile?.funding_rounds.map((f) => {
              if (f.id === payload.response.id) {
                return {
                  ...f,
                  ...payload.response,
                };
              }

              return f;
            }),
          },
        };
      }

      return state;
    }

    case ADD_FUNDING_ITEM_SUCCESS: {
      if (state.claimProfile.profile) {
        if (payload.oldSource === "crunchbase") {
          return {
            ...state,
            claimProfile: {
              ...state.claimProfile,
              funding_rounds: [
                payload.response,
                ...state.claimProfile.funding_rounds.filter(
                  (f) => f.id !== payload.oldId,
                ),
              ],
            },
          };
        }

        return {
          ...state,
          claimProfile: {
            ...state.claimProfile,
            funding_rounds: [
              payload.response,
              ...(state.claimProfile?.funding_rounds || []),
            ],
          },
        };
      }

      return state;
    }

    case DELETE_TOTAL_FUNDING_ITEM_SUCCESS: {
      if (state.claimProfile.profile) {
        return {
          ...state,
          claimProfile: {
            ...state.claimProfile,
            funding_rounds: state.claimProfile.funding_rounds.filter(
              (f) => f.id !== payload.fundingId,
            ),
          },
        };
      }

      return state;
    }

    case SAVE_DETAILS_SUCCESS: {
      if (state?.claimProfile?.profile?.last_rfi?.id === payload?.id) {
        return {
          ...state,
          claimProfile: {
            profile: {
              ...state.claimProfile.profile,
              last_rfi: null,
            },
            loading: false,
          },
        };
      }

      return state;
    }

    case UPDATE_CLAIM_PROFILE_SUCCESS: {
      return {
        ...state,
        claimProfile: {
          profile: {
            ...state.claimProfile.profile,
            ...payload.response,
          },
          loading: false,
        },
      };
    }

    case GET_CLAIM_PROFILE_OVERVIEW_ATTEMPT: {
      return {
        ...state,
        claimProfile: {
          ...state.claimProfile,
          loading: true,
        },
      };
    }

    case GET_CLAIM_PROFILE_OVERVIEW_SUCCESS: {
      return {
        ...state,
        claimProfile: {
          profile: payload.response,
          loading: false,
        },
      };
    }

    default: {
      return state;
    }
  }
};

const claimProfileReducer = combineReducers({
  claimReducer,
  rfiReducer,
});

export default claimProfileReducer;

import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import UploadImgComponent from "modules/primitives/uploadImg/uploadImg.component";
import { projectsSearch } from "modules/projects/projectsMain/projectsMain.action";
import {
  Label,
  Button,
  TextArea,
  BtnType,
  Loading,
  MultiSelect,
} from "../../../primitives";
import styles from "./styles.module.scss";
import {
  solutionsPostAttempt,
  solutionsEditAttempt,
  solutionsProjectDeleteAttempt,
  clearSelecredSolutionProjectsAction,
} from "../solutionsMain.actions";
import { Icon, smSize } from "../../../../common/icon";

import { model } from "./constants";
import { ModalFooter, ModalBody } from "../../../../application/modal/index";

const SolutionAddEditModal = (props) => {
  const { popup, formRef, width, mode, data, userId } = props;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [imageURL, setImageURL] = useState(
    data?.image_url ? data?.image_url : ""
  );

  const [selectedProjects, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [elasticQueryy, setElasticQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [nameError, setNameError] = useState(false);
  const {
    councilReducer: { selectedCouncil },
    solutionsReducer: {
      solutionsMainReducer: { selectedSolutionProjects },
    },
    projectsReducer: {
      projectsMainReducer: { projectList, projectLoading },
    },
  } = useSelector((state) => state);

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleImgUpdate = (url) => {
    setImageURL(url);
  };

  const onSubmitValue = (values) => {
    if (!values.name) {
      setNameError(true);
      return;
    }

    const submitData = {
      name: values.name,
      image_url: imageURL,
      description: values.description,
      project_ids: [
        ...selectedProjects,
        ...selectedSolutionProjects.map((proj) => proj.project_id),
      ],
    };
    if (mode === "edit") {
      dispatch(
        solutionsEditAttempt({
          data: submitData,
          enqueueSnackbar,
          id: data.id,
        })
      );
    } else {
      dispatch(
        solutionsPostAttempt({
          data: submitData,
          enqueueSnackbar,
        })
      );
    }
    setNameError(false);
    dispatch(clearSelecredSolutionProjectsAction({}));

    popup.hide();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(elasticQueryy);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [elasticQueryy]);

  useEffect(() => {
    if (selectedCouncil && userId) {
      const payload = {
        data: {
          query: debouncedValue,
          search: {
            query: debouncedValue,
            user_id: null,
          },
          selectedTab: "all_projects",
          page: 1,
          userId,
          sort_order: "desc",
          sort_attribute: "created_at",
        },
      };
      dispatch(projectsSearch(payload));
    }
  }, [debouncedValue, selectedCouncil]);

  const handleOptionSelect = (values) => {
    setSelected((prev) => {
      return [...values.map((proj) => proj.id)];
    });
  };
  const handleProjectRemove = (values) => {
    dispatch(
      solutionsProjectDeleteAttempt({
        enqueueSnackbar,
        id: data.id,
        projectId: values,
      })
    );
  };
  useEffect(() => {
    const filtredProjects = projectList.filter((project) => {
      return !data?.projects?.some(
        (projectFromSolution) => project.id === projectFromSolution.project_id
      );
    });
    setOptions(filtredProjects);
  }, [projectList]);

  return (
    <div style={width ? { maxwidth: width } : null} className={styles.inner}>
      <Formik
        className={styles.form}
        enableReinitialize
        innnerRef={formRef}
        initialValues={mode === "edit" ? data : model}
        onSubmit={(values, { setSubmitting }) => {
          if (values.name === undefined) {
            return;
          }
          setSubmitting(true);
          onSubmitValue(values);
        }}
      >
        {({ handleSubmit, setFieldValue, ...formikprops }) => (
          <form onSubmit={handleSubmit}>
            <ModalBody className={styles.modalBody}>
              <div className="row">
                <div className="col-12">
                  <Label>Solution Name (Required)</Label>
                  <TextArea
                    type="text"
                    name="name"
                    placeholder="Name"
                    className={styles.inputBox}
                    formProps={{ ...formikprops }}
                    rows={0}
                  />
                  <span className={styles.error}>
                    {nameError && "Solution name is required!"}
                  </span>
                </div>
                <div className={styles.addImageBlock}>
                  <UploadImgComponent
                    name="logo"
                    customText="Select an image"
                    imgSize={styles.imgSize}
                    imgStyle={styles.imageStyle}
                    uploadDirName={`users/${userId}/key`}
                    onChange={handleImgUpdate}
                    image={data?.image_url}
                    formProps={{
                      ...formikprops,
                    }}
                  />
                </div>
                <div className="col-12">
                  <Label>Description</Label>
                  <TextArea
                    type="text"
                    name="description"
                    placeholder="Solution description"
                    className={styles.textBox}
                    formProps={{ ...formikprops }}
                    rows={8}
                  />
                </div>
                <div className="col-12">
                  <MultiSelect
                    onChange={handleOptionSelect}
                    placeholder="Select project (or type to search)"
                    labelField="name"
                    valueField="id"
                    searchBy="name"
                    options={options}
                    multi
                    searchFn={(args) => {
                      setElasticQuery(args.state.search);
                    }}
                  />
                  <div className={styles.loaderWrp}>
                    {projectLoading && <Loading hideString />}
                  </div>
                </div>
                <div className={styles.tagsBlock}>
                  <div className={styles.delete}>
                    {selectedSolutionProjects &&
                      selectedSolutionProjects.map((item) => (
                        <div key={item?.project_id} className={styles.techItem}>
                          {item?.project_name}{" "}
                          <span
                            onClick={() => {
                              handleProjectRemove(item?.project_id);
                            }}
                          >
                            <Icon {...smSize} icon="icn-button-delete" />
                          </span>
                        </div>
                      ))}{" "}
                  </div>
                </div>
                <br />
                <br />
              </div>
            </ModalBody>
            <div className={styles.footerActions}>
              <ModalFooter>
                <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
                  Cancel
                </Button>
                <Button type="submit" btn={BtnType.REGULAR}>
                  {mode === "edit" ? "Save" : "Create"}
                </Button>
              </ModalFooter>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(SolutionAddEditModal);

import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import {
  httpGet,
  httpPost,
  errorHandler,
  apiStatus,
  httpPut,
} from "../../../common/httpCall";

import {
  getAvailableIntegrationsSuccess,
  GET_AVAILABLE_INTEGRATIONS,
  catchErrorSuccess,
  POST_COUNCIL_INTEGRATION,
  getAvailableIntegrations,
  PUT_COUNCIL_INTEGRATION,
  POST_USER_INTEGRATION,
  PUT_USER_INTEGRATION,
} from "./adminIntegrations.actions";

export const getAvailableIntegrationsEpic = (action$) =>
  action$.pipe(
    ofType(GET_AVAILABLE_INTEGRATIONS),
    switchMap(({ payload }) =>
      httpGet({
        call: "integrations",
      }).pipe(
        map((result) => getAvailableIntegrationsSuccess(result)),
        catchError(() => {
          payload.enqueueSnackbar("Something wrong happened", {
            variant: "error",
          });
          return of(catchErrorSuccess());
        }),
        catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
      ),
    ),
  );

export const postCouncilIntegrationEpic = (action$) =>
  action$.pipe(
    ofType(POST_COUNCIL_INTEGRATION),
    switchMap(
      ({
        payload: { enqueueSnackbar, integration_id, status, admin_connected },
      }) =>
        httpPost({
          call: "councils_integrations",
          data: {
            councils_integration: {
              integration_id,
              status,
              admin_connected,
            },
          },
        }).pipe(
          map((result) => {
            try {
              if (
                result.status === apiStatus.CREATED ||
                result.status === apiStatus.SUCCESS
              ) {
                return getAvailableIntegrations({ enqueueSnackbar });
              }
            } catch (error) {
              console.log("-----error in POST_COUNCIL_INTEGRATION-----", error);
            }
          }),
          catchError((error) =>
            errorHandler(error, enqueueSnackbar, [], catchErrorSuccess),
          ),
        ),
    ),
  );

export const putCouncilIntegrationEpic = (action$) =>
  action$.pipe(
    ofType(PUT_COUNCIL_INTEGRATION),
    switchMap(
      ({
        payload: {
          enqueueSnackbar,
          integration_id,
          status,
          admin_connected,
          councils_integration_id,
        },
      }) =>
        httpPut({
          call: `councils_integrations/${councils_integration_id}`,
          data: {
            councils_integration: {
              integration_id,
              status,
              admin_connected,
            },
          },
        }).pipe(
          map((result) => {
            try {
              if (
                result.status === apiStatus.CREATED ||
                result.status === apiStatus.SUCCESS
              ) {
                return getAvailableIntegrations({ enqueueSnackbar });
              }
            } catch (error) {
              console.log("-----error in PUT_COUNCIL_INTEGRATION-----", error);
            }
          }),
          catchError((error) =>
            errorHandler(error, enqueueSnackbar, [], catchErrorSuccess),
          ),
        ),
    ),
  );

export const postUserIntegrationEpic = (action$) =>
  action$.pipe(
    ofType(POST_USER_INTEGRATION),
    switchMap(
      ({
        payload: {
          enqueueSnackbar,
          integration_id,
          token,
          user_id,
          council_id,
        },
      }) =>
        httpPost({
          call: `users/${user_id}/integrations`,
          data: {
            user_integration: {
              integration_id,
              user_id,
              token,
              council_id,
            },
          },
        }).pipe(
          map((result) => {
            try {
              if (
                result.status === apiStatus.CREATED ||
                result.status === apiStatus.SUCCESS
              ) {
                return getAvailableIntegrations({ enqueueSnackbar });
              }
            } catch (error) {
              console.log("-----error in POST_USER_INTEGRATION-----", error);
            }
          }),
          catchError((error) =>
            errorHandler(error, enqueueSnackbar, [], catchErrorSuccess),
          ),
        ),
    ),
  );

export const putUserIntegrationEpic = (action$) =>
  action$.pipe(
    ofType(PUT_USER_INTEGRATION),
    switchMap(
      ({
        payload: {
          enqueueSnackbar,
          integration_id,
          token,
          user_id,
          id,
          council_id,
        },
      }) =>
        httpPut({
          call: `users/${user_id}/integrations/${id}`,
          data: {
            user_integration: {
              integration_id,
              user_id,
              token,
              council_id,
            },
          },
        }).pipe(
          map((result) => {
            try {
              if (
                result.status === apiStatus.CREATED ||
                result.status === apiStatus.SUCCESS
              ) {
                return getAvailableIntegrations({ enqueueSnackbar });
              }
            } catch (error) {
              console.log("-----error in PUT_COUNCIL_INTEGRATION-----", error);
            }
          }),
          catchError((error) =>
            errorHandler(error, enqueueSnackbar, [], catchErrorSuccess),
          ),
        ),
    ),
  );

const integrationsEpic = combineEpics(
  getAvailableIntegrationsEpic,
  postCouncilIntegrationEpic,
  putCouncilIntegrationEpic,
  postUserIntegrationEpic,
  putUserIntegrationEpic,
);

export default integrationsEpic;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import SimpleSearchComponent from "../../primitives/simpleSearch/simpleSearch.component";
import SolutionsMainTopActions from "./solutionsMainTopActions";
import { Pagination } from "../../primitives/index";
import SolutionCard from "./solutionCard";
import SolutionCardModal from "./solutionCard/solutionCardModal";
import SolutionsCardLoader from "../solutionsCommon/skeleton/index";
import {
  solutionsSearchAttempt,
  solutionsStatsGetAttempt,
} from "./solutionsMain.actions";
import {
  getTopicFilter,
  getThemes,
  getFilterableResource,
  getPlantsAreas,
} from "../../../common/actions/common.action";
const PAGINATION_DEFAULTS = {
  pageSize: 18,
  current: 1,
  hideOnSinglePage: true,
  showTitle: false,
};

const SolutionsComponent = () => {
  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
    solutionsReducer: {
      solutionsMainReducer: {
        solutionsStats,
        solutionsExtended,
        loadingSolutions,
        solutionMeta,
      },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const solutionIdFromRedirect = history.location?.state?.solutionId;
  const [pageSetting, setPageSetting] = useState(PAGINATION_DEFAULTS);
  const [selectedCardModal, setSelectedCardModal] = useState(false);
  const [sortOrder, setSortOrder] = useState("desc");
  const [currentQuery, setQuery] = useState("");
  const [selectedFilterOption, setSelectedFilterOption] = useState({
    theme_ids: [],
    topic_ids: [],
    plant_ids: [],
    area_ids: [],
    project_field_values: [],
    savings: [],
    avarage_savings_lte: null,
    avarage_savings_gte: null,
    avarage_rating_lte: null,
    avarage_rating_gte: null,
  });
  const enqueueSnackbar = useSnackbar();
  const [filterOptionSearchResult, setFilterOptionSearchResult] = useState({
    theme_ids: [],
    topic_ids: [],
    plant_ids: [],
    area_ids: [],
    project_field_values: [],
    savings: [],
    founded_by: [],
    fps_category: [],
    program: [],
  });

  const [loadingFilterOpt, setLoadingFilterOpt] = useState(false);
  const searchFn = (type, query = "*") => {
    if (!selectedCouncil?.id) {
      return;
    }
    if (type === "topic_ids" || type === "all") {
      dispatch(
        getTopicFilter({
          cb: (res) => {
            setLoadingFilterOpt(false);
            setFilterOptionSearchResult((prev) => {
              return { ...prev, topic_ids: res.response };
            });
          },
          selectedCouncilId: selectedCouncil?.id,
        })
      );
    }
    if (type === "theme_ids" || type === "all") {
      dispatch(
        getThemes({
          query,
          cb: (res) => {
            setFilterOptionSearchResult((prev) => {
              return { ...prev, theme_ids: res.response?.themes };
            });
            setLoadingFilterOpt(false);
          },
          selectedCouncilId: selectedCouncil.id,
        })
      );
    }

    if (type === "area_ids" || type === "plants_ids" || type === "all") {
      dispatch(
        getPlantsAreas({
          cb: (res) => {
            setFilterOptionSearchResult((prev) => {
              return {
                ...prev,
                area_ids: res.response.areas,
                plant_ids: res.response.plants,
              };
            });
            setLoadingFilterOpt(false);
          },
        })
      );
    }
  };

  const handleSearchUpdate = (query = "*", page, filters = {}) => {
    setQuery(query);
    const data = {
      sort_order: sortOrder,
      sort_attribute: "created_at",
      search: {
        query,
        ...filters,
      },
    };
    dispatch(solutionsSearchAttempt({ page: page || 1, data, fromMain: true }));
  };
  const handlePageChange = (page) => {
    if (page === solutionMeta.current) {
      return;
    }
    setPageSetting({
      ...pageSetting,
      current: page,
    });
    handleSearchUpdate(currentQuery, page);
  };

  const setFilterOption = (type, value) => {
    if (
      ["project_field_values", "fps_category", "founded_by", "program"].includes(
        type
      )
    ) {
      if (selectedFilterOption["project_field_values"].includes(value)) {
        return;
      }
      setSelectedFilterOption((prev) => {
        return {
          ...prev,
          project_field_values: [...prev["project_field_values"], value],
        };
      });

      handleSearchUpdate(currentQuery, 1, {
        ...selectedFilterOption,
        theme_ids: [...selectedFilterOption.theme_ids.map((opt) => opt.id)],
        plant_ids: [...selectedFilterOption.plant_ids.map((opt) => opt.id)],
        area_ids: [...selectedFilterOption.area_ids.map((opt) => opt.id)],

        project_field_values: [
          ...selectedFilterOption["project_field_values"].map((opt) => opt),
          value,
        ],
      });
    } else {
      if (
        selectedFilterOption[type].find((element) => element.id === value.id)
      ) {
        return;
      }

      setSelectedFilterOption((prev) => {
        return { ...prev, [type]: [...prev[type], value] };
      });

      handleSearchUpdate(currentQuery, 1, {
        ...selectedFilterOption,
        theme_ids: [...selectedFilterOption.theme_ids.map((opt) => opt.id)],
        plant_ids: [...selectedFilterOption.plant_ids.map((opt) => opt.id)],
        area_ids: [...selectedFilterOption.area_ids.map((opt) => opt.id)],

        [type]: [...selectedFilterOption[type].map((opt) => opt.id), value.id],
      });
    }
  };

  const handleRangeFilterValueSelect = (type, range) => {
    if (type === "savings") {
      setSelectedFilterOption((prev) => {
        return {
          ...selectedFilterOption,
          avarage_savings_lte: range[1],
          avarage_savings_gte: range[0],
        };
      });

      handleSearchUpdate(currentQuery, 1, {
        ...selectedFilterOption,
        theme_ids: [...selectedFilterOption.theme_ids.map((opt) => opt.id)],
        plant_ids: [...selectedFilterOption.plant_ids.map((opt) => opt.id)],
        area_ids: [...selectedFilterOption.area_ids.map((opt) => opt.id)],
        avarage_savings_lte: range[1],
        avarage_savings_gte: range[0],
      });
    } else {
      setSelectedFilterOption((prev) => {
        return {
          ...selectedFilterOption,
          avarage_rating_lte: range[1],
          avarage_rating_gte: range[0],
        };
      });

      handleSearchUpdate(currentQuery, 1, {
        ...selectedFilterOption,
        theme_ids: [...selectedFilterOption.theme_ids.map((opt) => opt.id)],
        plant_ids: [...selectedFilterOption.plant_ids.map((opt) => opt.id)],
        area_ids: [...selectedFilterOption.area_ids.map((opt) => opt.id)],
        avarage_rating_lte: range[1],
        avarage_rating_gte: range[0],
      });
    }
  };

  const removeFilterTag = (type, id) => {
    if (type === "savings") {
      setSelectedFilterOption((prev) => {
        return {
          ...prev,
          avarage_savings_lte: null,
          avarage_savings_gte: null,
        };
      });
      handleSearchUpdate(currentQuery, 1, {
        ...selectedFilterOption,
        theme_ids: [...selectedFilterOption.theme_ids.map((opt) => opt.id)],
        plant_ids: [...selectedFilterOption.plant_ids.map((opt) => opt.id)],
        area_ids: [...selectedFilterOption.area_ids.map((opt) => opt.id)],
        avarage_savings_lte: null,
        avarage_savings_gte: null,
      });
    } else if (type === "project_field_values") {
      setSelectedFilterOption((prev) => {
        return {
          ...prev,
          project_field_values: [
            ...prev.project_field_values.filter((field) => field !== id),
          ],
        };
      });
      handleSearchUpdate(currentQuery, 1, {
        ...selectedFilterOption,
        theme_ids: [...selectedFilterOption.theme_ids.map((opt) => opt.id)],
        plant_ids: [...selectedFilterOption.plant_ids.map((opt) => opt.id)],
        area_ids: [...selectedFilterOption.area_ids.map((opt) => opt.id)],
        project_field_values: [
          ...selectedFilterOption.project_field_values.filter(
            (field) => field !== id
          ),
        ],
      });
    } else if (type === "rating") {
      setSelectedFilterOption((prev) => {
        return {
          ...prev,
          avarage_rating_lte: null,
          avarage_rating_gte: null,
        };
      });
      handleSearchUpdate(currentQuery, 1, {
        ...selectedFilterOption,
        theme_ids: [...selectedFilterOption.theme_ids.map((opt) => opt.id)],
        plant_ids: [...selectedFilterOption.plant_ids.map((opt) => opt.id)],
        area_ids: [...selectedFilterOption.area_ids.map((opt) => opt.id)],
        avarage_rating_lte: null,
        avarage_rating_gte: null,
      });
    } else if (type === "all") {
      const defaultPayload = {
        theme_ids: [],
        topic_ids: [],
        plant_ids: [],
        area_ids: [],
        project_field_values: [],
        savings: [],
        avarage_savings_lte: null,
        avarage_savings_gte: null,
        avarage_rating_lte: null,
        avarage_rating_gte: null,
      };
      setSelectedFilterOption(defaultPayload);
      getInitialData("all");
      handleSearchUpdate(currentQuery, 1, defaultPayload);
    } else {
      setSelectedFilterOption((prev) => {
        return {
          ...prev,
          [type]: [...prev[type].filter((el) => el.id !== id)],
        };
      });

      handleSearchUpdate(currentQuery, 1, {
        ...selectedFilterOption,
        [type]: [...selectedFilterOption[type].filter((el) => el.id !== id)],
        theme_ids: [
          ...selectedFilterOption.theme_ids
            .map((opt) => opt.id)
            .filter((el) => el !== id),
        ],
        plant_ids: [
          ...selectedFilterOption.plant_ids
            .map((opt) => opt.id)
            .filter((el) => el !== id),
        ],
        area_ids: [
          ...selectedFilterOption.area_ids
            .map((opt) => opt.id)
            .filter((el) => el !== id),
        ],
      });
    }
  };

  useEffect(() => {
    if (solutionMeta && solutionMeta?.total !== 0) {
      setPageSetting({
        ...pageSetting,
        total: solutionMeta?.total,
      });
    }
  }, [solutionMeta]);
  const getProjectsFields = () => {
    dispatch(
      getFilterableResource({
        cb: (res) => {
          setFilterOptionSearchResult((prev) => {
            return {
              ...prev,
              project_field_values: [
                ...res.response?.map((projField) => {
                  if (projField.field_name === "Skills Team") {
                    return projField.option_values;
                  }
                  return [];
                }),
              ].flat(),
              fps_category: [
                ...res.response?.map((projField) => {
                  if (projField.field_name === "FPS Category") {
                    return projField.option_values;
                  }

                  return [];
                }),
              ].flat(),

              founded_by: [
                ...res.response?.map((projField) => {
                  if (projField.field_name.includes("Funded By")) {
                    return projField.option_values;
                  }
                  return [];
                }),
              ].flat(),
              program: [
                ...res.response?.map((projField) => {
                  if (projField.field_name.includes("Program")) {
                    return projField.option_values;
                  }

                  return [];
                }),
              ].flat(),
            };
          });
          setLoadingFilterOpt(false);
        },
      })
    );
  };

  const getInitialData = () => {
    searchFn("all");
  };

  useEffect(() => {
    if (selectedCouncil) {
      handleSearchUpdate();
      getProjectsFields();
      getInitialData();
      dispatch(
        solutionsStatsGetAttempt({
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil]);

  const setModalClose = () => {
    setSelectedCardModal(false);
    history.push("/solutions", { solutionId: undefined });
  };
  useEffect(() => {
    if (solutionIdFromRedirect) {
      setSelectedCardModal(solutionIdFromRedirect);
    }
  }, [solutionIdFromRedirect]);

  const renderMainContent = () => {
    if (!loadingSolutions && solutionsExtended?.length < 1) {
      return (
        <p className={styles.placeholderCont}> There are no items yet...</p>
      );
    }
    if (loadingSolutions) {
      return <SolutionsCardLoader />;
    }
    return solutionsExtended?.map((solution, i) => {
      if (solution) {
        return (
          <div
            className={styles.cardContainer}
            onClick={() => {}}
            key={solution.id}
          >
            <SolutionCard
              cardData={solution}
              userId={session?.id}
              setSelectedCardModal={setSelectedCardModal}
            />
          </div>
        );
      }
    });
  };

  return (
    <div
      style={selectedCardModal ? { position: "fixed" } : {}}
      className={styles.mainContainer}
    >
      {selectedCardModal !== false ? (
        <div className={styles.hoverContainer} onClick={() => setModalClose()}>
          <div
            className={styles.hoverContent}
            onClick={(e) => e.stopPropagation()}
          >
            <SolutionCardModal
              solutionId={selectedCardModal}
              setModalClose={setModalClose}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={styles.headerActions}>
        <div className={styles.searchWrapper}>
          <SimpleSearchComponent
            withoutMargin
            // searchValue={debouncedValue}
            onSearchValueUpdate={handleSearchUpdate}
            placeholder="Search Solutions"
            customIcon="search"
          />
        </div>
        <SolutionsMainTopActions
          related_projects={
            solutionsStats?.attributes?.solution_projects_count || 0
          }
          total_savings={solutionsStats?.attributes?.total_savings || 0}
          solutions_total={solutionsStats?.attributes?.solutions_count || 0}
          setFilterOption={setFilterOption}
          userId={session?.id}
          userRole={session?.council_role}
          searchFn={searchFn}
          filterOptionSearchResult={filterOptionSearchResult}
          selectedFilterOption={selectedFilterOption}
          removeFilterTag={removeFilterTag}
          handleRangeFilterValueSelect={handleRangeFilterValueSelect}
          getInitialData={getInitialData}
          selectedCouncilTag={selectedCouncil?.traction_tag}
        />
      </div>
      <div
        className={loadingSolutions ? styles.centeredCardGrid : styles.cardGrid}
      >
        {renderMainContent()}
      </div>
      <div className={styles.paginationBlock}>
        {solutionsExtended.length && solutionMeta?.total ? (
          <Pagination {...pageSetting} onChange={handlePageChange} />
        ) : null}
      </div>
    </div>
  );
};

export default SolutionsComponent;

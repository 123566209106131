export const GENERAL_SETTINGS_ATTEMPT = "GENERAL_SETTINGS_ATTEMPT";
export const generalSettingsGet = (payload) => ({
  type: GENERAL_SETTINGS_ATTEMPT,
  payload,
});
export const GENERAL_SETTINGS_SUCCESS = "GENERAL_SETTINGS_SUCCESS";
export const generalSettingsSuccess = (payload) => ({
  type: GENERAL_SETTINGS_SUCCESS,
  payload,
});

export const SETTINGS_TECHNOLOGIES_ATTEMPT = "SETTINGS_TECHNOLOGIES_ATTEMPT";
export const settingsTechnologiesGet = (payload) => ({
  type: SETTINGS_TECHNOLOGIES_ATTEMPT,
  payload,
});
export const SETTINGS_TECHNOLOGIES_SUCCESS = "SETTINGS_TECHNOLOGIES_SUCCESS";
export const settingsTechnologiesSuccess = (payload) => ({
  type: SETTINGS_TECHNOLOGIES_SUCCESS,
  payload,
});

export const SETTINGS_INDUSTRIES_ATTEMPT = "SETTINGS_INDUSTRIES_ATTEMPT";
export const settingsIndustriesGet = (payload) => ({
  type: SETTINGS_INDUSTRIES_ATTEMPT,
  payload,
});
export const SETTINGS_INDUSTRIES_SUCCESS = "SETTINGS_INDUSTRIES_SUCCESS";
export const settingsIndustriesSuccess = (payload) => ({
  type: SETTINGS_INDUSTRIES_SUCCESS,
  payload,
});

export const SETTINGS_EDIT_ATTEMPT = "SETTINGS_EDIT_ATTEMPT";
export const settingsEditAttempt = (payload) => ({
  type: SETTINGS_EDIT_ATTEMPT,
  payload,
});

export const PASSWORD_EDIT_ATTEMPT = "PASSWORD_EDIT_ATTEMPT";
export const passwordEditAttempt = (payload) => ({
  type: PASSWORD_EDIT_ATTEMPT,
  payload,
});
export const PASSWORD_EDIT_SUCCESS = "PASSWORD_EDIT_SUCCESS";
export const passwordEditSuccess = (payload) => ({
  type: PASSWORD_EDIT_SUCCESS,
  payload,
});

export const GET_NOTIFICATIONS_ATTEMPT = "GET_NOTIFICATIONS_ATTEMPT";
export const getNotificationsAttempt = (payload) => ({
  type: GET_NOTIFICATIONS_ATTEMPT,
  payload,
});
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const getNotificationsSuccess = (payload) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload,
});

export const SAVE_NOTIFICATIONS_ATTEMPT = "SAVE_NOTIFICATIONS_ATTEMPT";
export const saveNotificationsAttempt = (payload) => ({
  type: SAVE_NOTIFICATIONS_ATTEMPT,
  payload,
});
export const SAVE_NOTIFICATIONS_SUCCESS = "SAVE_NOTIFICATIONS_SUCCESS";
export const saveNotificationsSuccess = (payload) => ({
  type: SAVE_NOTIFICATIONS_SUCCESS,
  payload,
});

export const GET_FOLLOWERS_ATTEMPT = "GET_FOLLOWERS_ATTEMPT";
export const getFollowersAttempt = (payload) => ({
  type: GET_FOLLOWERS_ATTEMPT,
  payload,
});
export const GET_FOLLOWERS_SUCCESS = "GET_FOLLOWERS_SUCCESS";
export const getFollowersSuccess = (payload) => ({
  type: GET_FOLLOWERS_SUCCESS,
  payload,
});

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Select from "react-dropdown-select";

import { ModalFooter, ModalBody } from "../../../../../application/modal";
import {
  Button,
  BtnType,
  Label,
  MultiSelect,
  CheckBox,
  CheckType,
  Loading,
  Pagination,
} from "../../../../primitives";
import styles from "./styles.module.scss";
import { useModelPopup } from "../../../../../common/hooks";
import { httpGet, httpPost, pageMeta } from "../../../../../common/httpCall";
import ProjectDescription from "modules/projects/projectDescription";
import { PAGINATION_DEFAULTS } from "modules/companies/constants";

const NewRelatedProjects = ({ relatedProjects, selectedProject, handleAdd }) => {
  const { enqueueSnackbar } = useSnackbar();
  const popup = useModelPopup();
  const {
    councilReducer: { selectedCouncil },
    authReducer: { session },
  } = useSelector((state) => state);

  const isFordCouncil = useMemo(() => selectedCouncil?.name === 'Ford', [selectedCouncil?.name]);

  const [options, setOptions] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState(relatedProjects);
  const [debouncedValue, setDebouncedValue] = useState("");
  const [elasticQuery, setElasticQuery] = useState("");
  const [isSimilarProject, setIsSimilarProject] = useState(true);

  // stuff for project recommendations
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [pageSetting, setPageSetting] = useState(PAGINATION_DEFAULTS);
  const [items, setItems] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAllByPage, setSelectedAllByPage] = useState({ 1: false });
  const [selectedSimilarProjects, setSelectedSimilarProjects] = useState([]);

  useEffect(() => {
    httpGet({
      call: 'projects/related_projects/dropdown'
    }).subscribe(res => {
      const data = res.response || [];
      setProjects(data);
    });
  }, []);

  useEffect(() => {
    if (!items) {
      requestTheData(1, '');
    }

    return () => null;
  }, [items]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(elasticQuery);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [elasticQuery]);

  const getProjects = useCallback((query) => {
    httpGet({
      call: 'projects/related_projects/dropdown'
    }).subscribe(res => {
      const data = res.response || [];
      const existingIds = relatedProjects.map(p => p.id);
      const filteredProjects = data.filter((project) => !existingIds.includes(project.id) &&
        project.id !== selectedProject.id);

      setOptions(filteredProjects);
    });
  }, [selectedCouncil, session, enqueueSnackbar, relatedProjects]);

  useEffect(() => {
    getProjects('');
  }, []);

  const handleOptionSelect = (values) => {
    setSelectedProjects(values);
  };

  const handleCancelClick = () => {
    popup.hide();
  };

  const handleSaveClick = () => {
    if (isFordCouncil) {
      if (!selectedProjects.length && !selectedItems.length && !selectedSimilarProjects.length) {
        enqueueSnackbar('Select a project first', { variant: 'error' })
        return;
      }
    } else {
      if (!selectedProjects.length) {
        enqueueSnackbar('Select a project first', { variant: 'error' })
        return;
      }
    }

    let otherProps = {};

    if (isFordCouncil) {
      otherProps = {
        similar_projects: selectedSimilarProjects?.map((p) => ({
          related_project_id: p.id,
        })),
        similar_projects_recommendations: selectedItems.map(p => p.id),
      }
    }

    httpPost({
      call: `projects/${selectedProject.id}/related_projects`,
      data: {
        related_project: {
          related_project_id: selectedProjects?.length ? selectedProjects[0].id : null,
          similar: isFordCouncil ? isSimilarProject : false,
          ...otherProps,
        },
      },
    }).subscribe(res => {
      if (res.response) {
        enqueueSnackbar('Added new related project', { variant: 'success' })
        handleAdd(res.response);
        handleCancelClick();
      }
    });
  };

  const requestTheData = (page, query = "*") => {
    setShowLoading(true);

    const data = {
      data: {
        project_id: selectedProject.id,
      }
    }

    httpPost({
      call: `projects/similar_projects`,
      data,
    })
      .pipe()
      .subscribe((res) => {
        const pagemeta = pageMeta(res);
        const selectedAllByPageObj = [...Array(pagemeta.pages).keys()].reduce(
          (acc, idx) => {
            if (acc[idx]) {
              return acc;
            }

            acc[idx] = false;

            return acc;
          },
          selectedAllByPage
        );

        setSelectedAllByPage(selectedAllByPageObj);
        setPageSetting((prev) => ({
          ...prev,
          total: pagemeta.total,
          current: pagemeta.page,
        }));

        const allItemsAreSelected = res.response.filter((i) => i.selected)?.length === 20;

        setSelectedAllByPage({
          ...selectedAllByPage,
          [pagemeta.page]: allItemsAreSelected,
        });

        setItems(res.response);
        setShowLoading(false);
      });
  };

  const handlePagingChange = (page) => {
    if (page !== pageSetting.current) {
      requestTheData(page, searchTerm);
    }
  };

  const selectAll = (val) => {
    const currentItems = items.map((i) => ({
      ...i,
      selected: val,
    }));

    setSelectedItems(val ? currentItems : []);
    setItems(currentItems);
  };

  const onCheckChange = useCallback(
    (val, item, isFromSelectedBox) => {
      const newItems = val
        ? [...selectedItems, item]
        : selectedItems.filter((i) => i.id !== item.id);

      setSelectedItems(newItems);

      const newItemsOnAction = items.map((i) => {
        if (i.id === item.id) {
          return {
            ...i,
            selected: isFromSelectedBox ? false : val,
          };
        }

        return i;
      });

      setItems(newItemsOnAction);
    },
    [items]
  );

  return (
    <>
      <ModalBody>
        <div className={styles.customPadding}>
          <Label>Related Projects</Label>
          <div className={styles.selectorWrp}>
            <MultiSelect
              onChange={handleOptionSelect}
              placeholder="Add Projects (type to search or select from below)"
              labelField="name"
              valueField="id"
              options={options}
              searchBy="name"
              multi={false}
              searchFn={(args) => {
                setElasticQuery(args.state.search);
              }}
            />
          </div>
          {
            isFordCouncil && selectedProjects.length ? (
              <div className="mt-1 mb-3">
                <label className="d-flex align-items-center">
                  <CheckBox
                    checkType={CheckType.BLUE}
                    name="isSimilarProject"
                    onChange={setIsSimilarProject}
                    isChecked={isSimilarProject}
                  />
                  <span>Similar Project</span>
                </label>
              </div>
            ) : null
          }
          {
            isFordCouncil && (
              <>
                {
                  projects.length ? (
                    <div className="mb-3">
                      <Label>Select Similar Projects</Label>
                      <Select
                        label="Select Similar Projects"
                        placeholder="Select Similar Projects"
                        labelField="name"
                        searchBy="name"
                        valueField="id"
                        name="similar_projects"
                        multi={true}
                        options={projects}
                        onChange={setSelectedSimilarProjects}
                        values={selectedSimilarProjects}
                      />
                    </div>
                  ) : null
                }
                {selectedItems?.length > 0 ? (
                  <h4 className={classNames("bold mt-4", styles.smallTitle)}>
                    Selected Similar Project Recommendations{" "}({selectedItems?.length})
                  </h4>
                ) : null}
                {selectedItems?.length > 0 ? (
                  <div className={styles.listWrp}>
                    {selectedItems.map((project) => (
                      <div key={project.id} className={styles.formElement}>
                        <div className={styles.projWrp}>
                          <CheckBox
                            checkType={CheckType.BLUE}
                            isChecked
                            onChange={() => onCheckChange(false, project, true)}
                          />
                          <div className="d-flex flex-column">
                            <Link to={`/projects/${project.slug}`} target="_blank">{project.name}</Link>
                            <ProjectDescription description={project.description} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
                {
                  !showLoading && (
                    <h4 className={classNames("bold mt-4", styles.smallTitle)}>
                      Similar Project Recommendations
                    </h4>
                  )
                }
                <div className={styles.listWrp}>
                  {!showLoading && items?.length ? (
                    <div className="d-flex align-items-center mt-3 mb-4">
                      <CheckBox
                        checkType={CheckType.BLUE}
                        isChecked={selectedAllByPage[pageSetting.current]}
                        onChange={(val) => selectAll(val)}
                      />
                      <Label>
                        <div className={classNames(styles.tagStyle)}>
                          {pageSetting.total < 20
                            ? selectedAllByPage[pageSetting.current]
                              ? "Deselect Checked Items Below"
                              : "Select All"
                            : null}
                          {pageSetting.total > 20
                            ? selectedAllByPage[pageSetting.current]
                              ? "Deselect Checked Items Below"
                              : "Select All 20 Results"
                            : null}
                        </div>
                      </Label>
                    </div>
                  ) : null}
                  {showLoading ? <Loading /> : null}
                  {!showLoading && items?.length
                    ? items.map((project) => (
                      <div key={project.id} className={styles.formElement}>
                        <div className={styles.projWrp}>
                          <CheckBox
                            checkType={CheckType.BLUE}
                            isChecked={project.selected}
                            onChange={(val) => onCheckChange(val, project)}
                            onClick={() => {}}
                          />
                          <div className="d-flex flex-column">
                            <Link to={`/projects/${project.slug}`} target="_blank">{project.name}</Link>
                            <ProjectDescription description={project.description} />
                          </div>
                        </div>
                      </div>
                    ))
                    : null}
                  {!showLoading && items && items?.length === 0 ? (
                    <h3 className="text-center py-4">
                      Sorry we didn't find any results for you.
                    </h3>
                  ) : null}
                </div>
                {
                  pageSetting?.total > 20 && (
                    <div className="w-100 d-flex justify-content-center mb-4">
                      <Pagination {...pageSetting} onChange={handlePagingChange} />
                    </div>
                  )
                }
              </>
            )
          }
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button btn={BtnType.REGULAR} onClick={handleSaveClick}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
};

export default NewRelatedProjects;

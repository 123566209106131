import { combineEpics, ofType } from "redux-observable";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";

import { errorHandler, httpGet, httpPost, httpPut, httpDelete } from "../../../../common/httpCall";
import {
  ADD_PRODUCT_CONTACT,
  ADD_PRODUCT_OWNER,
  ADD_PRODUCT_TO_IDEA,
  ADD_PRODUCT_TO_LIST,
  ADD_PRODUCT_TO_PROJECT,
  ADD_PRODUCT_TO_THEME,
  addProductContactSuccess,
  addProductOwnerSuccess,
  addProductToIdeaSuccess,
  addProductToListSuccess,
  addProductToProjectSuccess,
  addProductToThemeSuccess,
  DELETE_PRODUCT_CONTACT,
  DELETE_PRODUCT_OWNER,
  deleteProductContactSuccess,
  deleteProductOwnerSuccess,
  EDIT_PRODUCT_CONTACT,
  EDIT_PRODUCT_DETAILS,
  EDIT_PRODUCT_DOCS,
  editProductContactSuccess,
  editProductDetailsSuccess,
  editProductDocsSuccess,
  GET_PRODUCT_DETAILS,
  getProductDetailsSuccess,
} from "modules/companies/product/store/product.actions";
import { of } from "rxjs";

export const epicGetProductDetails = (action$) => action$.pipe(
  ofType(GET_PRODUCT_DETAILS),
  switchMap(({ payload }) => httpGet({
    call: `companies/${payload.id}/products/${payload.productId}`,
  }).pipe(
    map((result) => getProductDetailsSuccess(result)),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

const makeNewPayload = ({ documents, ...rest }) => rest;

export const epicEditProductDetails = (action$) => action$.pipe(
  ofType(EDIT_PRODUCT_DETAILS),
  switchMap(({ payload }) => httpPut({
    call: `companies/${payload.companyId}/products/${payload.productId}`,
    data: makeNewPayload(payload.data),
  }).pipe(
    map((result) => {
      if (payload.cb) {
        payload.cb();
      }

      return editProductDetailsSuccess(result);
    }),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicAddProductContact = (action$) => action$.pipe(
  ofType(ADD_PRODUCT_CONTACT),
  switchMap(({ payload }) => httpPost({
    call: `companies/${payload.data.company_id}/products/${payload.data.product_id}/product_contacts`,
    data: payload.data,
  }).pipe(
    map((result) => addProductContactSuccess(result)),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicEditProductContact = (action$) => action$.pipe(
  ofType(EDIT_PRODUCT_CONTACT),
  switchMap(({ payload }) => httpPut({
    call: `companies/${payload.data.company_id}/products/${payload.data.product_id}/product_contacts/${payload.data.id}`,
    data: payload.data,
  }).pipe(
    map((result) => editProductContactSuccess(result)),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicDeleteProductContact = (action$) => action$.pipe(
  ofType(DELETE_PRODUCT_CONTACT),
  switchMap(({ payload }) => httpDelete({
    call: `companies/${payload.company_id}/products/${payload.product_id}/product_contacts/${payload.id}`,
  }).pipe(
    map(() => deleteProductContactSuccess({id: payload.id})),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicAddProductOwner = (action$) => action$.pipe(
  ofType(ADD_PRODUCT_OWNER),
  switchMap(({ payload }) => httpPost({
    call: `companies/${payload.data.company_id}/products/${payload.data.product_id}/product_owners`,
    data: payload.data,
  }).pipe(
    map((result) => addProductOwnerSuccess(result)),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicDeleteProductOwner = (action$) => action$.pipe(
  ofType(DELETE_PRODUCT_OWNER),
  switchMap(({ payload }) => httpDelete({
    call: `companies/${payload.company_id}/products/${payload.product_id}/product_owners/${payload.owner_id}`,
  }).pipe(
    map(() => deleteProductOwnerSuccess({id: payload.owner_id})),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicAddProductToIdea = (action$) => action$.pipe(
  ofType(ADD_PRODUCT_TO_IDEA),
  switchMap(({ payload }) => httpPost({
    call: `ideas/${payload.data.idea_id}/products`,
    data: payload.data,
  }).pipe(
    mergeMap((result) => {
      if (payload.cb) {
        payload.cb();
      }
      return of(addProductToIdeaSuccess({
        ...result,
        submittedPayload: {
          ...payload.data,
          id: payload.data.idea_id,
        },
        propToUpdateInCompanies: 'ideas_ids'
      }))
    }),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicAddProductToProject = (action$) => action$.pipe(
  ofType(ADD_PRODUCT_TO_PROJECT),
  switchMap(({ payload }) => httpPost({
    call: `products/${payload.data.project_id}/projects`,
    data: payload.data,
  }).pipe(
    mergeMap((result) => {
      if (payload.cb) {
        payload.cb();
      }
      return of(addProductToProjectSuccess(result));
    }),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicAddProductToTheme = (action$) => action$.pipe(
  ofType(ADD_PRODUCT_TO_THEME),
  switchMap(({ payload }) => httpPost({
    call: `products/${payload.data.council_product_id}/themes`,
    data: payload.data,
  }).pipe(
    mergeMap((result) => {
      if (payload.cb) {
        payload.cb();
      }
      return of(addProductToThemeSuccess({
        ...result,
        submittedPayload: {
          ...payload.data,
          id: payload.data.theme_id,
        },
        propToUpdateInCompanies: 'themes_ids'
      }));
    }),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicAddProductToList = (action$) => action$.pipe(
  ofType(ADD_PRODUCT_TO_LIST),
  mergeMap(({ payload }) => httpPost({
    call: `products/${payload.data.council_product_id}/lists`,
    data: payload.data,
  }).pipe(
    mergeMap((result) => {
      if (payload.cb) {
        payload.cb();
      }
      return of(addProductToListSuccess({
        ...result,
        submittedPayload: {
          ...payload.data,
          id: payload.data.list_id,
        },
        propToUpdateInCompanies: 'lists_ids'
      }))
    }),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicAddEditProductDocs = (action$) => action$.pipe(
  ofType(EDIT_PRODUCT_DOCS),
  switchMap(({ payload }) => httpPost({
    call: 'documents?multi=true',
    data: payload.data,
  }).pipe(
    map((result) => editProductDocsSuccess(result)),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

const productEpic = combineEpics(
  epicGetProductDetails,
  epicEditProductDetails,
  epicAddProductContact,
  epicEditProductContact,
  epicDeleteProductContact,
  epicAddProductOwner,
  epicDeleteProductOwner,
  epicAddProductToIdea,
  epicAddProductToProject,
  epicAddProductToTheme,
  epicAddProductToList,
  epicAddEditProductDocs
);

export default productEpic;

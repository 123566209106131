import React from "react";
import styles from "./funding.module.scss";
import { useWindowSize } from "common/hooks/useWindowSize";
import DesktopFundingRow from "./desktopFundingRow.component";
import MobileFundingRow from "./mobileFundingRow.component";
import CrunchSourceComponent from "../crunchSource";

const FundingComponent = ({ company, fundingRounds = [] }) => {
  const { isMobile } = useWindowSize();

  const numberWithCommas = (amount) => {
    if (typeof amount === "number") {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "";
  };

  const calculateTotals = () => {
    let totals = 0;
    fundingRounds.map((el) => {
      totals += el.amount_usd;
    });
    return `$ ${numberWithCommas(totals)}`;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.grid}>
        {isMobile ? (
          <div className={styles.gridHeaderMobile}>
            <span className={styles.title}>Total Raised</span>
            <div className="d-flex flex-column align-items-baseline">
              <span className={styles.totalAmount}>
                {fundingRounds && calculateTotals()}
              </span>
              <span className={styles.description}>
                {company && fundingRounds?.length} round
                {fundingRounds?.length === 1 ? "" : "s"} of funding
              </span>
            </div>
          </div>
        ) : (
          <div className={styles.gridHeader}>
            <span className={styles.title}>Total Raised</span>
            <span className={styles.totalAmount}>
              {fundingRounds && calculateTotals()}
            </span>
            <span className={styles.description}>
              {company && fundingRounds?.length} round
              {fundingRounds?.length === 1 ? "" : "s"} of funding
            </span>
          </div>
        )}
        <div className={styles.gridBody}>
          {fundingRounds.map((fund) => (isMobile ? (
            <MobileFundingRow
              key={fund.id}
              fund={fund}
              amount={numberWithCommas(fund.amount_usd)}
            />
          ) : (
            <DesktopFundingRow
              key={fund.id}
              fund={fund}
              amount={numberWithCommas(fund.amount_usd)}
            />
          )))}
        </div>
      </div>
      <div className="p-3">
        <CrunchSourceComponent company={company} hideTraction />
      </div>
    </div>
  );
};

export default React.memo(FundingComponent);

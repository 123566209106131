import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import { Button, BtnType, Loading } from "modules/primitives/index";
import Switch from "react-switch";
import { councilsEditSuccess } from "../../tractionAdmin/councils/councils.action";
import styles from "./adminThemes.module.scss";
import { httpPut } from "../../../common/httpCall";
import RenameThemeComponent from "./renameThemeModal/renameThemeModal.component";
import { useModelPopup } from "../../../common/hooks";

const AdminThemesComponent = () => {
  const {
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const popup = useModelPopup();
  const { enqueueSnackbar } = useSnackbar();

  const [themesEnabled, setThemesEnabledPage] = useState(false);
  const [homepageThemesEnabled, handleEnableHomePage] = useState(false);
  const [pipelineThemeEnabled, setThemePipleneEnabled] = useState(false);
  const [themeDocumentsEnabled, setThemeDocumentsEnabled] = useState(false);
  const [themePostsEnabled, setThemePostsEnabled] = useState(false);
  const [themeComapniesEnabled, setThemeCompaniesEnabled] = useState(false);
  const [themesProjectsEnabled, setThemeProjectsEnabled] = useState(false);
  const [themesIdeasEnabled, setThemeIdeasEnabled] = useState(false);
  const [themeEventsEnabled, setThemeEventsEnabled] = useState(false);
  const [themeFollowersEnabled, setThemeFollowersEnabled] = useState(false);
  const [themeProjectsTabEnabled, setThemeProjectsTabEnabled] = useState(false);
  const [projectViewType, setProjectViewType] = useState("side");
  const [showThemeProjOptions, setShowThemeProjOptions] = useState(false);
  const [
    themeProjectsCompAutoLinkEnabled,
    setThemeProjectsCompAutoLinkEnabled,
  ] = useState(false);
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const changeThemesDisplayName = async (displayName, singularDisplayName) => {
    setLoading(true);
    await httpPut({
      call: `councils/${selectedCouncil.id}`,
      data: {
        council: {
          ...selectedCouncil,
          settings: {
            ...selectedCouncil.settings,
            themes_display_name: displayName,
            themes_display_name_singular: singularDisplayName,
          },
        },
      },
    })
      .pipe()
      .subscribe((res) => {
        localStorage.setItem("council", JSON.stringify(res.response));
        setLoading(false);

        return councilsEditSuccess(res);
      });
    // window.location.reload();
  };
  const handleThemesProjectDisplayChange = (e) => {
    setProjectViewType(e);
  };
  const handleRenameThemeDisplayName = () => {
    popup.show({
      title: "Change Themes Display Name",
      show: true,
      height: "300",
      width: "540",
      component: (
        <RenameThemeComponent
          changeThemesDisplayName={changeThemesDisplayName}
          currentDisplayName={selectedCouncil?.settings?.themes_display_name}
          currentDisplayNameSingular={
            selectedCouncil?.settings?.themes_display_name_singular
          }
        />
      ),
    });
  };
  const handleEditCouncil = async () => {
    setLoading(true);
    await httpPut({
      call: `councils/${selectedCouncil.id}`,
      data: {
        council: {
          ...selectedCouncil,
          settings: {
            ...selectedCouncil.settings,
            themes_connected: themesEnabled,
            themes_homepage_connected: homepageThemesEnabled,
            pipeline_theme_enabled: pipelineThemeEnabled,
            theme_documents_enabled: themeDocumentsEnabled,
            theme_posts_enabled: themePostsEnabled,
            theme_companies_enabled: themeComapniesEnabled,
            theme_projects_enabled: themesProjectsEnabled,
            theme_ideas_enabled: themesIdeasEnabled,
            theme_events_enabled: themeEventsEnabled,
            theme_followers_enabled: themeFollowersEnabled,
            theme_projects_tab_enabled: themeProjectsTabEnabled,
            project_view_type: projectViewType,
            auto_add_companies_to_theme: themeProjectsCompAutoLinkEnabled,
          },
        },
      },
    })
      .pipe()
      .subscribe((res) => {
        localStorage.setItem("council", JSON.stringify(res.response));
        setLoading(false);
        enqueueSnackbar("Council edited with success", {
          variant: "success",
        });
        return councilsEditSuccess(res);
      });
    // window.location.reload();
  };

  useEffect(() => {
    if (selectedCouncil) {
      setThemesEnabledPage(
        selectedCouncil?.settings.themes_connected === false ? false : true
      );
      handleEnableHomePage(
        selectedCouncil?.settings.themes_homepage_connected === false
          ? false
          : true
      );
      setThemePipleneEnabled(
        selectedCouncil?.settings.pipeline_theme_enabled === false
          ? false
          : true
      );
      setThemeDocumentsEnabled(
        selectedCouncil?.settings.theme_documents_enabled === false
          ? false
          : true
      );
      setThemeCompaniesEnabled(
        selectedCouncil?.settings.theme_companies_enabled === false
          ? false
          : true
      );
      setThemeProjectsEnabled(
        selectedCouncil?.settings.theme_projects_enabled === false
          ? false
          : true
      );
      setThemeIdeasEnabled(
        selectedCouncil?.settings.theme_ideas_enabled === false ? false : true
      );
      setThemeEventsEnabled(
        selectedCouncil?.settings.theme_events_enabled === false ? false : true
      );
      setThemePostsEnabled(
        selectedCouncil?.settings.theme_posts_enabled === false ? false : true
      );
      setThemeFollowersEnabled(
        selectedCouncil?.settings.theme_followers_enabled === false
          ? false
          : true
      );
      setThemeProjectsTabEnabled(
        selectedCouncil?.settings?.theme_projects_tab_enabled === false
          ? false
          : true
      );
      setProjectViewType(
        selectedCouncil?.settings?.project_view_type
          ? selectedCouncil?.settings?.project_view_type
          : "side"
      );
      setThemeProjectsCompAutoLinkEnabled(
        selectedCouncil?.settings?.auto_add_companies_to_theme === false
          ? false
          : true
      );
    }
  }, [selectedCouncil]);

  const redirectToProjects = (type) => {
    history.push(`/admin/themes/projectsTable?type=${type}`);
  };
  return (
    <div className={styles.container}>
      <div className={styles.headerWrp}>
        <h4 className={styles.taskHeaderText}>
          <Link to="/admin" className={styles.rootText}>
            ADMIN
          </Link>
          <span className={styles.separationBar}> / </span>
          <span className={styles.activeBreadcrumbs}>
            {selectedCouncil?.settings?.themes_display_name?.toUpperCase() ||
              "THEMES"}{" "}
          </span>
          {/* <span className={styles.separationBar}> / </span>
          <Link to="/admin/themes/groups" className={styles.rootText}>
            GROUPS
          </Link>
          <span className={styles.separationBar}> / </span>
          <Link to="/admin/themes/fields" className={styles.rootText}>
            FIELDS
          </Link> */}
        </h4>
      </div>
      <div className={styles.bodyWrp}>
        {/* <div className={styles.toogleWrapper}>
          <span className="m-0">Enable Themes: </span>{" "}
          <Switch
            uncheckedIcon={false}
            checkedIcon={false}
            checked={themesEnabled}
            onColor="#24a0ed"
            onChange={() => {
              setThemesEnabledPage((prev) => !prev);
            }}
          />
        </div>
        <div className={styles.toogleWrapper}>
          <span className="m-0">Enable Themes Homepage:</span>{" "}
          <Switch
            uncheckedIcon={false}
            checkedIcon={false}
            checked={homepageThemesEnabled}
            onColor="#24a0ed"
            onChange={() => {
              handleEnableHomePage((prev) => !prev);
            }}
          />
        </div> */}
        <div className={styles.firstRow}>
          <div className={styles.titleWrp}>
            Current display name:{" "}
            <span>
              {selectedCouncil?.settings?.themes_display_name || "Themes"}
            </span>
          </div>

          <div className={styles.buttonWrp}>
            <Button
              className={styles.addNew}
              btn={BtnType.REGULAR}
              icon="icn-add"
              onClick={handleRenameThemeDisplayName}
            >
              Change{" "}
              {selectedCouncil?.settings?.themes_display_name || "Themes"}{" "}
              Display Name
            </Button>
          </div>
        </div>
        <div className={styles.controlsTable}>
          <div className={styles.headerRow}>
            <div className={styles.firstColumn}>
              {selectedCouncil?.settings?.themes_display_name_singular ||
                "Theme"}{" "}
              Page Elements
            </div>
            <div className={styles.secondColumn}>On/Off</div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.firstColumn}>Pipeline Tab</div>
            <div className={styles.secondColumn}>
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                checked={pipelineThemeEnabled}
                onColor="#24a0ed"
                onChange={() => {
                  setThemePipleneEnabled((prev) => !prev);
                }}
              />
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.firstColumn}>Documents Tab</div>
            <div className={styles.secondColumn}>
              {" "}
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                checked={themeDocumentsEnabled}
                onColor="#24a0ed"
                onChange={() => {
                  setThemeDocumentsEnabled((prev) => !prev);
                }}
              />
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.firstColumn}>
              Projects Tab
              <span className={styles.firstColumn}>
                <span
                  className={styles.optionsLink}
                  onClick={() => {
                    redirectToProjects("list");
                  }}
                >
                  Edit
                </span>
              </span>
            </div>
            <div className={styles.secondColumn}>
              {" "}
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                checked={themeProjectsTabEnabled}
                onColor="#24a0ed"
                onChange={() => {
                  setThemeProjectsTabEnabled((prev) => !prev);
                }}
              />
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.firstColumn}>
              {selectedCouncil?.settings?.themes_display_name_singular ||
                "Theme"}{" "}
              Posts
            </div>
            <div className={styles.secondColumn}>
              {" "}
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                checked={themePostsEnabled}
                onColor="#24a0ed"
                onChange={() => {
                  setThemePostsEnabled((prev) => !prev);
                }}
              />
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.firstColumn}>
              {selectedCouncil?.settings?.themes_display_name_singular ||
                "Theme"}{" "}
              Companies
            </div>
            <div className={styles.secondColumn}>
              {" "}
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                checked={themeComapniesEnabled}
                onColor="#24a0ed"
                onChange={() => {
                  setThemeCompaniesEnabled((prev) => !prev);
                }}
              />
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.firstColumn}>
              {selectedCouncil?.settings?.themes_display_name_singular ||
                "Theme"}{" "}
              Followers
            </div>
            <div className={styles.secondColumn}>
              {" "}
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                checked={themeFollowersEnabled}
                onColor="#24a0ed"
                onChange={() => {
                  setThemeFollowersEnabled((prev) => !prev);
                }}
              />
            </div>
          </div>
          <div className={styles.tableRow}>
            <div className={styles.projectThemes}>
              <div className={styles.columnsContainer}>
                <div className={styles.firstColumn}>
                  Related Projects{" "}
                  <span
                    className={styles.optionsLink}
                    onClick={() => {
                      setShowThemeProjOptions((prev) => !prev);
                    }}
                  >
                    Options >
                  </span>
                </div>

                <div className={styles.secondColumn}>
                  {" "}
                  <Switch
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={themesProjectsEnabled}
                    onColor="#24a0ed"
                    onChange={() => {
                      setThemeProjectsEnabled((prev) => !prev);
                    }}
                  />
                </div>
              </div>

              {showThemeProjOptions && (
                <div className={styles.optionsContainer}>
                  <div className={`ml-5 ${styles.columnsContainer}`}>
                    <div className={styles.firstColumn}>
                      Automatically add companies from linked Projects to Theme
                    </div>
                    <div className={styles.secondColumn}>
                      {" "}
                      <Switch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        checked={themeProjectsCompAutoLinkEnabled}
                        onColor="#24a0ed"
                        onChange={() => {
                          setThemeProjectsCompAutoLinkEnabled((prev) => !prev);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.optionsContainer}>
                    <div
                      className={`ml-5 ${styles.radioOptionsContainer}`}
                      role="group"
                      aria-label="Display in:"
                    >
                      Display in:
                      <div>
                        <input
                          type="radio"
                          className={styles.btnCheckCustom}
                          name="displayType"
                          id="side"
                          value="side"
                          checked={projectViewType === "side"}
                          onChange={() =>
                            handleThemesProjectDisplayChange("side")
                          }
                        />
                        <label className={`ml-1`} htmlFor="side">
                          Side Rail
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className={styles.btnCheckCustom}
                          name="displayType"
                          id="main"
                          value="main"
                          checked={projectViewType === "main"}
                          onChange={() =>
                            handleThemesProjectDisplayChange("main")
                          }
                        />
                        <label className={`ml-1`} htmlFor="main">
                          Main column
                        </label>
                        {projectViewType === "main" && (
                          <span className={styles.firstColumn}>
                            <span
                              className={styles.optionsLink}
                              onClick={() => {
                                redirectToProjects("overview");
                              }}
                            >
                              Edit
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {selectedCouncil?.settings?.ideas_connected !== false && (
            <div className={styles.tableRow}>
              <div className={styles.firstColumn}>Related Ideas</div>
              <div className={styles.secondColumn}>
                {" "}
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  checked={themesIdeasEnabled}
                  onColor="#24a0ed"
                  onChange={() => {
                    setThemeIdeasEnabled((prev) => !prev);
                  }}
                />
              </div>
            </div>
          )}

          {selectedCouncil?.settings?.events_connected !== false && (
            <div className={styles.tableRow}>
              <div className={styles.firstColumn}>Related Events</div>
              <div className={styles.secondColumn}>
                {" "}
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  checked={themeEventsEnabled}
                  onColor="#24a0ed"
                  onChange={() => {
                    setThemeEventsEnabled((prev) => !prev);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.updateCouncilContainer}>
          {loading ? (
            <Loading />
          ) : (
            <Button
              className={styles.addNew}
              btn={BtnType.REGULAR}
              icon="icn-add"
              onClick={() => handleEditCouncil()}
            >
              Update Council
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default AdminThemesComponent;

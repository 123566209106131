import {
  PATIENT_ORGANIZATIONS_GET_SUCCESS,
  PATIENT_ORGANIZATIONS_GET_ATTEMPT,
  PATIENT_ORGANIZATION_GET_DISEASES_SUCCESS,
  PATIENT_ORGANIZATION_GET_DISEASES_ATTEMPT,
  PATIENT_SEARCH_SUCCESS,
  DESEASE_CHECK_ATTEMPT,
  PATIENT_ORGANIZATION_DELETE,
  PATIENT_ORGANIZATION_DELETE_SUCCESS,
  COUNTRY_CHECK_ATTEMPT,
  PATIENT_ORGANIZATION_DISEASES_RESET,
  PATIENT_ORGANIZATION_SELECTED_DISEASES_RESET,
  PATIENT_ORGANIZATION_GET_DIVERSITY_GROUPS_SUCCESS, SET_SELECTED_ORGANIZATIONS,
} from "./patientOrganizationMain.action";
import { apiStatus, pageMeta } from "../../../common/httpCall";

const initState = {
  patientOrganizationList: [],
  patientOrganizationById: {},
  updatePatientOrganizationsList: false,
  loading: false,
  loadingSavedList: false,
  companiesPageNr: {},
  searchResults: [],
  industries: [],
  savedCompaniesLists: [],
  shouldSavedListUpdate: false,
  diseases: [],
  savedList: {},
  patientOrganizationPageNr: {},
  checkedPatientFilters: {
    disease_ids: [],
    diversity_group_ids: [],
  },
  countryFilters: [],
  diversityGroups: [],
  selectedOrganizations: []
};
const patientOrganizationMainReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SELECTED_ORGANIZATIONS: {
      if (payload.shouldReset) {
        return {
          ...state,
          selectedOrganizations: [],
        };
      }

      return {
        ...state,
        selectedOrganizations: payload?.organizations || [],
      };
    }

    case PATIENT_ORGANIZATION_GET_DIVERSITY_GROUPS_SUCCESS: {
      return {
        ...state,
        diversityGroups: payload.response || [],
      };
    }

    case PATIENT_ORGANIZATION_SELECTED_DISEASES_RESET: {
      return {
        ...state,
        checkedPatientFilters: {
          disease_ids: [],
        },
      };
    }

    case PATIENT_ORGANIZATION_DISEASES_RESET: {
      return {
        ...state,
        diseases: [],
        diversityGroups: [],
      };
    }

    case DESEASE_CHECK_ATTEMPT: {
      return {
        ...state,
        checkedPatientFilters: {
          ...state.checkedPatientFilters,
          ...payload,
        },
      };
    }

    case COUNTRY_CHECK_ATTEMPT: {
      return {
        ...state,
        countryFilters: payload,
      };
    }

    case PATIENT_ORGANIZATIONS_GET_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case PATIENT_ORGANIZATIONS_GET_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          patientOrganizationList: response,
          loading: false,
          companiesPageNr: pageMeta(payload),
          patientOrganizationPageNr: pageMeta(payload),
        };
      }
    }

    case PATIENT_ORGANIZATION_GET_DISEASES_ATTEMPT: {
      return {
        ...state,
        loading: true,
      };
    }

    case PATIENT_ORGANIZATION_GET_DISEASES_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          diseases: response,
          loading: false,
        };
      }
    }

    case PATIENT_SEARCH_SUCCESS: {
      const { response, status } = payload;

      return {
        ...state,
        patientOrganizationList: response,
        patientOrganizationPageNr: pageMeta(payload),
      };
    }

    case PATIENT_ORGANIZATION_DELETE: {
      return {
        ...state,
        updatePatientOrganizationsList: false,
      };
    }

    case PATIENT_ORGANIZATION_DELETE_SUCCESS: {
      const { response, status } = payload;
      if (status === apiStatus.SUCCESS && response) {
        return {
          ...state,
          updatePatientOrganizationsList: true,
        };
      }
    }
  }

  return state;
};

export default patientOrganizationMainReducer;

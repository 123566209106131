import React, { useState, useRef } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import CheckBox from "modules/admin/adminIdeas/addForm/checkBox";
import styles from "./styles.module.scss";
import { Icon } from "../../../../common/icon";
import { OptionType } from "../../themesIndividualPage/themesPipelineTab/pipelineKanban/kanbanCompanies/moreOptions.constant";
import { useOnClickOutside } from "../../../../common/hooks";
import { mdSize } from "../../../../common/icon/icon.constants";

const MoreOptionsWithCheckbox = (props) => {
  const {
    options,
    className,
    onClick,
    selectedPartnership,
    selectedInvestment,
    setSelectedPartnership,
    setSelectedInvestment,
    handleCheckboxClick,
    selected,
    fromThemeEdit,
    isProduct,
  } = props;
  const ref = useRef();
  const {
    authReducer: { session },
    councilReducer: { selectedCouncil },
  } = useSelector((state) => state);
  const [isModalOpen, setModalOpen] = useState(false);

  useOnClickOutside(ref, () => setModalOpen(false));

  const handleOptionClick = (val, slug, text) => {
    onClick(val, slug, text);
    setModalOpen(false);
  };

  const handleMoreClick = () => {
    setModalOpen(true);
  };

  return (
    <div className={styles.moreWrp}>
      {isModalOpen ? (
        <div ref={ref} className={classNames(styles.optionWrp, className)}>
          {!isProduct &&
            !fromThemeEdit &&
            selectedCouncil?.traction_tag === "firmenich" && (
              <div className={classNames(styles.opt)}>TRACK</div>
            )}
          {!isProduct &&
            selectedCouncil.traction_tag === "firmenich" &&
            !selected && (
              <div className={styles.trackCheckboxes}>
                <CheckBox
                  isChecked={selectedPartnership}
                  onChange={() => setSelectedPartnership(!selectedPartnership)}
                />
                <div
                  className={classNames(styles.optChildren)}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Partnership
                </div>
              </div>
            )}
          {!isProduct &&
            session?.theme_investment_access &&
            !selected &&
            selectedCouncil.traction_tag === "firmenich" && (
              <div className={styles.trackCheckboxes}>
                <CheckBox
                  isChecked={selectedInvestment}
                  onChange={() => setSelectedInvestment(!selectedInvestment)}
                />
                <div
                  className={classNames(styles.optChildren)}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Investment
                </div>
              </div>
            )}
          {options?.map(({ val, text, type = OptionType.REGULAR, slug }) => (
            <div key={val + text}>
              {val && (
                <div
                  className={classNames(
                    styles.opt,
                    type === OptionType.DANGER && styles.danger
                  )}
                  style={slug ? { marginLeft: 15 } : {}}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (text !== "Track" && text !== "Move To")
                      handleOptionClick(val, slug, text);
                  }}
                >
                  {text}
                </div>
              )}
            </div>
          ))}
          {selected && (
            <div className={styles.trackCheckboxes}>
              <CheckBox
                isChecked={selected[0] === "expert"}
                onChange={() => handleCheckboxClick()}
              />
              <div
                className={classNames(styles.optChildren)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Expert
              </div>
            </div>
          )}
        </div>
      ) : (
        <Icon
          {...mdSize}
          icon="icn-more"
          className={styles.icon}
          onClick={(e) => {
            e.stopPropagation();
            handleMoreClick();
          }}
        />
      )}
    </div>
  );
};

export default MoreOptionsWithCheckbox;

export const COUNCIL_SELECTED_SET = "COUNCIL_SELECTED_SET";
export const councilSet = (payload) => ({
  type: COUNCIL_SELECTED_SET,
  payload,
});

export const COUNCIL_GET_ATTEMPT = "COUNCIL_GET_ATTEMPT";
export const councilGet = (payload) => ({ type: COUNCIL_GET_ATTEMPT, payload });
export const COUNCIL_GET_SUCCESS = "COUNCIL_GET_SUCCESS";
export const councilGetSuccess = (payload) => ({
  type: COUNCIL_GET_SUCCESS,
  payload,
});

export const COUNCIL_LIST_GET_ALL_ATTEMPT = "COUNCIL_LIST_GET_ALL_ATTEMPT";
export const councilListGetAllAttempt = (payload) => ({
  type: COUNCIL_LIST_GET_ALL_ATTEMPT,
  payload,
});
export const COUNCIL_LIST_GET_ALL_SUCCESS = "COUNCIL_LIST_GET_ALL_SUCCESS";
export const councilListGetAllSuccess = (payload) => ({
  type: COUNCIL_LIST_GET_ALL_SUCCESS,
  payload,
});

export const COUNCIL_LIST_GET_ATTEMPT = "COUNCIL_LIST_GET_ATTEMPT";
export const councilListGet = (payload) => ({
  type: COUNCIL_LIST_GET_ATTEMPT,
  payload,
});
export const COUNCIL_LIST_GET_SUCCESS = "COUNCIL_LIST_GET_SUCCESS";
export const councilListGetSuccess = (payload) => ({
  type: COUNCIL_LIST_GET_SUCCESS,
  payload,
});

export const COUNCIL_USER_GET_ATTEMPT = "COUNCIL_USER_GET_ATTEMPT";
export const councilUserGet = (payload) => ({
  type: COUNCIL_USER_GET_ATTEMPT,
  payload,
});
export const COUNCIL_USER_GET_SUCCESS = "COUNCIL_USER_GET_SUCCESS";
export const councilUserGetSuccess = (payload) => ({
  type: COUNCIL_USER_GET_SUCCESS,
  payload,
});

export const COUNCIL_STAGES_TEMPLATE_LIST_ATTEMPT = "COUNCIL_STAGES_TEMPLATE_LIST_ATTEMPT";
export const councilStagesTemplateListGet = (payload) => ({
  type: COUNCIL_STAGES_TEMPLATE_LIST_ATTEMPT,
  payload,
});
export const COUNCIL_STAGES_TEMPLATE_LIST_SUCCESS = "COUNCIL_STAGES_TEMPLATE_LIST_SUCCESS";
export const councilStagesTemplateListSuccess = (payload) => ({
  type: COUNCIL_STAGES_TEMPLATE_LIST_SUCCESS,
  payload,
});

export const COUNCIL_REMOVE_MEMBER_ATTEMPT = "COUNCIL_REMOVE_MEMBER_ATTEMPT";
export const councilRemoveMember = (payload) => ({
  type: COUNCIL_REMOVE_MEMBER_ATTEMPT,
  payload,
});
export const COUNCIL_REMOVE_MEMBER_SUCCESS = "COUNCIL_REMOVE_MEMBER_SUCCESS";
export const councilRemoveMemberSuccess = (payload) => ({
  type: COUNCIL_REMOVE_MEMBER_SUCCESS,
  payload,
});

export const COUNCIL_GET_TEAMS_ATTEMPT = "COUNCIL_GET_TEAMS_ATTEMPT";
export const councilGetTeams = (payload) => ({
  type: COUNCIL_GET_TEAMS_ATTEMPT,
  payload,
});
export const COUNCIL_GET_TEAMS_SUCCESS = "COUNCIL_GET_TEAMS_SUCCESS";
export const councilGetTeamsSuccess = (payload) => ({
  type: COUNCIL_GET_TEAMS_SUCCESS,
  payload,
});

export const COUNCIL_REMOVE_TEAM_ATTEMPT = "COUNCIL_REMOVE_TEAM_ATTEMPT";
export const councilRemoveTeam = (payload) => ({
  type: COUNCIL_REMOVE_TEAM_ATTEMPT,
  payload,
});
export const COUNCIL_REMOVE_TEAM_SUCCESS = "COUNCIL_REMOVE_TEAM_SUCCESS";
export const councilRemoveTeamSuccess = (payload) => ({
  type: COUNCIL_REMOVE_TEAM_SUCCESS,
  payload,
});

export const COUNCIL_CREATE_TEAM_ATTEMPT = "COUNCIL_CREATE_TEAM_ATTEMPT";
export const councilCreateTeam = (payload) => ({
  type: COUNCIL_CREATE_TEAM_ATTEMPT,
  payload,
});
export const COUNCIL_CREATE_TEAM_SUCCESS = "COUNCIL_CREATE_TEAM_SUCCESS";
export const councilCreateTeamSuccess = (payload) => ({
  type: COUNCIL_CREATE_TEAM_SUCCESS,
  payload,
});

export const COUNCIL_EDIT_TEAM_ATTEMPT = "COUNCIL_EDIT_TEAM_ATTEMPT";
export const councilEditTeam = (payload) => ({
  type: COUNCIL_EDIT_TEAM_ATTEMPT,
  payload,
});
export const COUNCIL_EDIT_TEAM_SUCCESS = "COUNCIL_EDIT_TEAM_SUCCESS";
export const councilEditTeamSuccess = (payload) => ({
  type: COUNCIL_EDIT_TEAM_SUCCESS,
  payload,
});

export const COUNCIL_GET_TEAM_BY_ID_ATTEMPT = "COUNCIL_GET_TEAM_BY_ID_ATTEMPT";
export const councilGetTeamById = (payload) => ({
  type: COUNCIL_GET_TEAM_BY_ID_ATTEMPT,
  payload,
});
export const COUNCIL_GET_TEAM_BY_ID_SUCCESS = "COUNCIL_GET_TEAM_BY_ID_SUCCESS";
export const councilGetTeamByIdSuccess = (payload) => ({
  type: COUNCIL_GET_TEAM_BY_ID_SUCCESS,
  payload,
});

export const COUNCIL_TEAM_ADD_MEMBER_ATTEMPT = "COUNCIL_TEAM_ADD_MEMBER_ATTEMPT";
export const councilTeamAddMember = (payload) => ({
  type: COUNCIL_TEAM_ADD_MEMBER_ATTEMPT,
  payload,
});
export const COUNCIL_TEAM_ADD_MEMBER_SUCCESS = "COUNCIL_TEAM_ADD_MEMBER_SUCCESS";
export const councilTeamAddMemberSuccess = (payload) => ({
  type: COUNCIL_TEAM_ADD_MEMBER_SUCCESS,
  payload,
});

export const COUNCIL_SEARCH_TEAM_MEMBER_ATTEMPT = "COUNCIL_SEARCH_TEAM_MEMBER_ATTEMPT";
export const councilSearchTeamMember = (payload) => ({
  type: COUNCIL_SEARCH_TEAM_MEMBER_ATTEMPT,
  payload,
});
export const COUNCIL_SEARCH_TEAM_MEMBER_SUCCESS = "COUNCIL_SEARCH_TEAM_MEMBER_SUCCESS";
export const councilSearchTeamMemberSuccess = (payload) => ({
  type: COUNCIL_SEARCH_TEAM_MEMBER_SUCCESS,
  payload,
});

export const COUNCIL_REMOVE_TEAM_MEMBER_ATTEMPT = "COUNCIL_REMOVE_TEAM_MEMBER_ATTEMPT";
export const councilRemoveTeamMember = (payload) => ({
  type: COUNCIL_REMOVE_TEAM_MEMBER_ATTEMPT,
  payload,
});
export const COUNCIL_REMOVE_TEAM_MEMBER_SUCCESS = "COUNCIL_REMOVE_TEAM_MEMBER_SUCCESS";
export const councilRemoveTeamMemberSuccess = (payload) => ({
  type: COUNCIL_REMOVE_TEAM_MEMBER_SUCCESS,
  payload,
});

export const COUNCIL_SEARCH_TEAMS_ATTEMPT = "COUNCIL_SEARCH_TEAMS_ATTEMPT";
export const councilSearchTeams = (payload) => ({
  type: COUNCIL_SEARCH_TEAMS_ATTEMPT,
  payload,
});
export const COUNCIL_SEARCH_TEAMS_SUCCESS = "COUNCIL_SEARCH_TEAMS_SUCCESS";
export const councilSearchTeamsSuccess = (payload) => ({
  type: COUNCIL_SEARCH_TEAMS_SUCCESS,
  payload,
});

import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useModelPopup } from "../../hooks";
import { ModalBody } from "../../../application/modal";
import { formInitialValues } from "./addIdea.constants";
import { BtnType, Button } from "../../../modules/primitives";
import { StepWrapper } from "./formSteps";
import styles from "./styles.module.scss";
import {
  companyGetFilters,
  companyGetTopics,
} from "modules/companies/companyMain/companyMain.action";
import {
  createIdea,
  ideasSectionsGet,
  ideasTemplateGet,
} from "modules/ideasWrapper/idea.action";
import LoadingComponent from "../../../modules/primitives/loading/loading.component";

const AddNewIdea = () => {
  const popup = useModelPopup();
  const formRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [nextStage, setNextStage] = useState(null);
  const [steps, setSteps] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepValidation, setCurrentStepValidation] = useState(null);
  const isFirstStep = currentStep === 1;
  const isLastStep = currentStep === steps?.length;

  const {
    councilReducer: { selectedCouncil },
    ideaReducer: { ideasTemplate, ideasSections },
    companiesReducer: {
      companyMainReducer: { topics, industries },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (ideasSections?.length && !steps && selectedCouncil) {
      const getAreasOfFocusSections = ideasSections
        .filter((s) => s.category_type === "area_of_focus")
        .sort((a, b) => b.step_nr - a.step_nr);

      const makeSteps = ideasSections
        .reduce((acc, elem) => {
          const hasTheStep = acc.some((elem1) => elem1.step === elem.step_nr);

          if (hasTheStep) {
            return acc.map((elem1) => {
              if (elem1.step === elem.step_nr) {
                return {
                  ...elem1,
                  fields: [...elem1.fields, elem],
                };
              }

              return elem1;
            });
          } else {
            return [
              ...acc,
              {
                name:
                  elem.category_type === "description"
                    ? "Tell Us More"
                    : "Areas Of Focus",
                step: elem.step_nr,
                fields: [elem],
                category_type: elem.category_type,
                isFirstStep: elem.step_nr === 1,
                isLastAreaOfFocus: getAreasOfFocusSections.length
                  ? elem.category_type === "area_of_focus" &&
                    getAreasOfFocusSections[0].step_nr === elem.step_nr
                  : false,
              },
            ];
          }
        }, [])
        .filter((s) => s.step)
        .sort((a, b) => a.step - b.step);

      setSteps(makeSteps);
    }
  }, [ideasSections, steps, selectedCouncil]);

  const closePopup = () => {
    popup.hide();
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      const { topic_ids, industry_ids, user_ids, ...sectionFields } = values;

      let props = {};

      if (selectedCouncil?.name === "Ford") {
        props = {
          theme_ids: values.themes?.map((t) => t.id) || [],
          plant_id: values.selectedPlant?.id,
          area_ids:
            values.selectedPlant?.selectedAreas?.map((a) => a.area_id) || [],
          possible_plant_ids:
            values.selectedPlant1?.map((plant) => plant.id) || [],
          possible_area_ids: values.selectedAreas1?.map((a) => a.area_id) || [],
          project_id: sectionFields["project_id"],
        };
      }

      const payload = {
        data: {
          council_id: selectedCouncil.id,
          ideas_template_id: ideasTemplate.idea_template_id,
          topic_ids,
          industry_ids,
          user_ids,
          next_stage_end_on: nextStage?.date,
          descriptions: Object.entries(sectionFields)
            .map(([key, value]) => {
              if (Array.isArray(value)) {
                return {
                  ideas_section_id: key,
                  section_values: value,
                };
              }

              return {
                ideas_section_id: key,
                section_value: value,
              };
            })
            .filter(
              (s) =>
                s.ideas_section_id !== "selectedPlant" &&
                s.ideas_section_id !== "selectedPlant1" &&
                s.ideas_section_id !== "selectedAreas1" &&
                s.ideas_section_id !== "project_id" &&
                s.ideas_section_id !== "themes"
            ),
          ...props,
        },
        enqueueSnackbar,
        cb: (err, idea) => {
          if (!err && idea) {
            popup.hide();
            enqueueSnackbar("Idea created with success", {
              variant: "success",
            });
            history.push(`/ideas/${idea.id}`);
          }
        },
      };

      dispatch(createIdea(payload));
    } else {
      setCurrentStep(currentStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleBackClick = () => {
    if (!isFirstStep) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNextClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const renderCurrentStep = () => {
    if (steps) {
      const stepDetails = steps.find((s) => s.step === currentStep);

      return (
        <StepWrapper
          key={stepDetails.step}
          stepDetails={stepDetails}
          topics={topics}
          industries={industries}
          selectedCouncil={selectedCouncil}
          instructions={ideasTemplate?.description}
          setNextStage={setNextStage}
          nextStage={nextStage}
        />
      );
    }

    return (
      <div className="pt-5">
        <LoadingComponent customText="Preparing form..." />
      </div>
    );
  };

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        companyGetFilters({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );

      dispatch(
        companyGetTopics({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );

      dispatch(
        ideasTemplateGet({
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil]);

  useEffect(() => {
    if (ideasTemplate?.idea_template_id) {
      dispatch(
        ideasSectionsGet({
          enqueueSnackbar,
          query: {
            ideas_template_id: ideasTemplate.idea_template_id,
          },
        })
      );
    }
  }, [ideasTemplate]);

  useEffect(() => {
    if (!formRef?.current || !(ideasSections && ideasSections.length > 0)) {
      return;
    }
    ideasSections.forEach(({ ideas_section_id, section_type = "" }) => {
      if (!formRef?.current?.values[ideas_section_id]) {
        const isSectionType = ["checklist", "dropdown"].includes(section_type);
        formRef.current.setFieldValue(
          ideas_section_id,
          isSectionType ? [] : ""
        );
      }
    });
  }, [ideasSections, formRef]);

  useEffect(() => {
    if (!steps) {
      return;
    }

    const stepSectionSchema = ideasSections
      .filter(({ step_nr }) => step_nr === currentStep)
      .reduce((acc, { ideas_section_id, section_type, field_required }) => {
        if (
          field_required &&
          ["checklist", "dropdown"].includes(section_type)
        ) {
          acc[ideas_section_id] = Yup.array()
            .min(1, "Field must have at least 1 item")
            .required("Field is required");
        } else {
          if (field_required) {
            acc[ideas_section_id] = Yup.string().required("Field is required");
          }
        }
        return acc;
      }, {});
    const newSchema = Yup.object().shape(stepSectionSchema);

    setCurrentStepValidation(newSchema);
  }, [currentStep, steps]);

  return (
    <div>
      <ModalBody noFixedHeight>
        <div className={styles.modalBodyWrap}>
          <div>
            {steps && (
              <div>
                <div className={styles.captionStyle}>
                  {`Step ${currentStep} of ${steps.length}`}
                </div>
              </div>
            )}
            <Formik
              innerRef={formRef}
              initialValues={formInitialValues}
              validationSchema={currentStepValidation}
              onSubmit={handleSubmit}
              validate={(values, formikErrors) => {
                // just for Ford
                if (selectedCouncil?.name === "Ford") {
                  let errors = { ...formikErrors };

                  // that might be wrong, cause on PROD this id could be different, but there's no other clear way
                  // at the moment to do that :)
                  if (
                    values["869bce18-0bf0-4917-81e8-31583c4382b8"] === "Yes"
                  ) {
                    errors.project_id = "Project is required";
                  } else if (
                    values["869bce18-0bf0-4917-81e8-31583c4382b8"] === "No" &&
                    errors.project_id
                  ) {
                    const { project_id, ...rest } = errors;
                    errors = rest;
                  }

                  if (values.project_id?.length > 0 && errors.project_id) {
                    const { project_id, ...rest } = errors;
                    errors = rest;
                  }

                  return errors;
                }

                return formikErrors;
              }}
            >
              <Form id="addApplicantForm">{renderCurrentStep()}</Form>
            </Formik>
          </div>
          {steps && (
            <div className="mt-4">
              <div className="row">
                <div className="col-12 d-flex justify-content-start">
                  {!isFirstStep && (
                    <Button
                      btn={BtnType.FRAME_LESS}
                      type="submit"
                      onClick={handleBackClick}
                    >
                      Back
                    </Button>
                  )}
                </div>
                <div className="col-12 d-flex justify-content-end">
                  <Button
                    className="mr-3"
                    btn={BtnType.FRAME_LESS}
                    type="button"
                    onClick={closePopup}
                  >
                    Cancel
                  </Button>

                  <Button
                    btn={BtnType.REGULAR}
                    type="submit"
                    onClick={handleNextClick}
                  >
                    {isLastStep ? "Save" : "Next"}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </ModalBody>
    </div>
  );
};
export default React.memo(AddNewIdea);

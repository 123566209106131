import React from "react";
import Skeleton from "@mui/material/Skeleton";
import styles from "../myLists.module.scss";

const ListSkeleton = () => {
  return (
    <div className={styles.skeletonContainer}>
      <div className={styles.skeletonInfo}>
        <div className={styles.listTitle}>
          <Skeleton animation="wave" width={"40%"} height={30} />
          <Skeleton animation="wave" width={"20%"} height={25} />
          <Skeleton animation="wave" width={"20%"} height={25} />
        </div>
      </div>
      <div>
        <div className={styles.skeletonListElement}>
          {" "}
          <Skeleton animation="wave" variant="circle" width={64} height={60} />
          <div className={styles.skeletonCompanyInfo}>
            <Skeleton animation="wave" height={20} />
            <Skeleton animation="wave" height={20} />
          </div>
        </div>
        <div className={styles.skeletonListElement}>
          {" "}
          <Skeleton animation="wave" variant="circle" width={64} height={60} />
          <div className={styles.skeletonCompanyInfo}>
            <Skeleton animation="wave" width={"100%"} height={20} />
            <Skeleton animation="wave" width={"100%"} height={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListSkeleton;

export const xsSize = {
  size: 8,
  className: "iconColor",
};

export const smSize = {
  size: 15,
  className: "iconColor",
};

export const mdSize = {
  size: 20,
  className: "iconColor",
};

export const lgSize = {
  size: 32,
  className: "iconColor",
};

export const xxlSize = {
  size: 80,
  className: "iconColor",
};

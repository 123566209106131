import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import styles from '../styles.module.scss';
import { Label, DropSelect } from "modules/primitives";
import { getFordDashboardStages } from "modules/reporting/fordReports/store/fordReports.actions";

const FordScoreCardStagesFilter = ({ handleFilterUpdate, reducer, setNewFilters, filterBy = [] }) => {
  const dispatch = useDispatch();

  const {
    fordDashboardReducer: { fordReportsReducer: { stages } }
  } = useSelector((state) => state);

  const [selectedStages, setSelectedStages] = useState([]);

  useEffect(() => {
    setSelectedStages(reducer.filters?.stages || []);
  }, [reducer.filters?.stages]);

  useEffect(() => {
    if (!stages) {
      dispatch(getFordDashboardStages());
    }
  }, [stages]);

  const updateFilters = useCallback((value) => {
    setNewFilters({
      ...reducer.filters,
      stages: value,
    });
    handleFilterUpdate({stages: value}, reducer.tableData?.expandedKey);
  }, [reducer]);

  const handleStageChange = (value) => {
    setSelectedStages(value.obj);
    updateFilters(value.obj);
  };

  const makeStagesList = useMemo(() => {
    if (filterBy?.length && stages?.length) {
      return filterBy
        .filter(stage => stages.some(s => s.name === stage.name))
        .map(stage => {
          const getStageByName = stages.find(s => s.name === stage.name);
          if (getStageByName) {
            return {
              ...stage,
              ...getStageByName,
            }
          }
          return stage;
        })
    }

    return stages || [];
  }, [filterBy, stages]);

  return (
    <div className={classNames(styles.filter)}>
      <Label>Stage</Label>
      <DropSelect
        multi
        closeOnSelect
        values={selectedStages}
        onChange={handleStageChange}
        options={makeStagesList}
        labelField="name"
        valueField="id"
        placeholder="Stage"
        searchBy="name"
      />
    </div>
  );
};

export default React.memo(FordScoreCardStagesFilter);

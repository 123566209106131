import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import { useSnackbar } from "notistack";

import { Loading } from "modules/primitives/index";
import InnovationListWrapper from "./innovationListWrapper";
import InnovationChartWrapper from "./innovationChartWrapper";
import ChartsDownload from "../../companies/companyMain/chartsDownload";
import { makeFlattenRelationShipTags } from "../../companies/companyMain/chartsWrapper/chartsView/charts/bubbleChart/bubbleUtils";
import {
  getAllCompaniesByStatusAttempt,
  homeFunnelSetSelectedTags,
  homeFunnelShouldReRender,
} from "../home.actions";

const chartOptions = {
  color: "relationShipStatus",
};

const InnovationFunnel = () => {
  const {
    appReducer: { chartsDownloadIsActive, relationShipStatuses },
    councilReducer: { selectedCouncil },
    homeReducer: {
      innovationFunnelCompanies,
      funnelChartShouldRerender,
      selectedTags,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState(null);
  const [activeCompaniesNr, setActiveCompaniesNr] = useState(0);
  const [downloadHeight, setDownloadHeight] = useState("28.8px");

  useEffect(() => {
    if (!selectedTags.length && companies) {
      setActiveCompaniesNr(companies.length);
      return;
    }

    if (companies) {
      const activeCompanies = companies.filter((c) =>
        selectedTags.some((t) => t.companiesIdThatBelongsTo.includes(c.id))
      );

      setActiveCompaniesNr(activeCompanies.length);
    }

    return () => null;
  }, [companies, selectedTags]);

  useEffect(() => {
    if (funnelChartShouldRerender) {
      const timeout = setTimeout(() => {
        dispatch(homeFunnelShouldReRender(false));
        clearTimeout(timeout);
      }, 500);
    }

    return () => null;
  }, [dispatch, funnelChartShouldRerender]);

  useEffect(() => {
    // wait for council, and make sure the council isn't GSK or FORD
    if (!selectedCouncil || selectedCouncil?.traction_tag === "gsk" || selectedCouncil.traction_tag === "ford") {
      return;
    }

    getCompaniesWithStatus();
    setLoading(true);

    return () => null;
  }, [selectedCouncil]);

  useEffect(() => {
    if (innovationFunnelCompanies) {
      setCompanies(innovationFunnelCompanies || []);
      setLoading(false);
    }

    return () => null;
  }, [innovationFunnelCompanies]);

  useEffect(() => {
    if (!companies?.length || !chartOptions || !relationShipStatuses?.length)
      return;

    const flattenTags = makeFlattenRelationShipTags(
      companies,
      relationShipStatuses
    ).filter((t) => t.count !== 0);
    setTags(flattenTags);

    return () => null;
  }, [companies, selectedCouncil, chartOptions]);

  useEffect(() => {
    if (selectedTags.length) {
      const newTags = tags.map((t) => ({
        ...t,
        selected: selectedTags.some((t1) => t1.id === t.id),
      }));

      setTags(newTags);
    }

    return () => null;
  }, [selectedTags]);

  const getCompaniesWithStatus = () => {
    const data = {
      query: "",
      search: {
        query: "",
        council_relationship_stage_name: relationShipStatuses.map(
          (t) => t?.name
        ),
        council_relationship_stage_id: relationShipStatuses.map(
          (t) => t.customId
        ),
      },
    };

    dispatch(
      getAllCompaniesByStatusAttempt({
        call: "companies/charts?ultrafast=true&funnel=true",
        data,
        enqueueSnackbar,
      })
    );
  };

  const handleTagsForDownloadReport = () => {
    if (selectedTags.length) {
      return selectedTags.map((t) => t.customId);
    }
    return relationShipStatuses.map((t) => t.customId);
  };

  const handleSelectTags = useCallback(
    (tags) => {
      dispatch(homeFunnelSetSelectedTags(tags.filter((t) => t.selected)));
      setTags(tags);
    },
    [selectedTags, tags]
  );

  const makeDescription = useMemo(() => {
    if (selectedCouncil?.traction_tag === 'cke') {
      return 'Preparing vendor status, please stand by...';
    }

    if (selectedCouncil?.traction_tag === 'kyndryl') {
      return 'Preparing relationship stage, please stand by...';
    }

    return 'Preparing innovation funnel, please stand by...';
  }, [selectedCouncil?.traction_tag]);

  if (selectedCouncil?.traction_tag === "gsk" || companies?.length === 0) {
    return null;
  }

  return loading ? (
    <Loading customText={makeDescription} />
  ) : innovationFunnelCompanies?.length ? (
    <div className={`row ${chartsDownloadIsActive ? "pl-3" : ""}`}>
      <div className="col-12 col-md-8">
        <InnovationListWrapper
          companies={companies}
          selectedCouncil={selectedCouncil}
          tags={tags}
          handleSelectTags={handleSelectTags}
          handleRefHeight={setDownloadHeight}
          isDownloadActive={chartsDownloadIsActive}
          selectedTags={selectedTags}
        />
      </div>
      <div className="col-12 col-md-4">
        <div
          className="w-100 d-flex justify-content-between align-items-end"
          style={{
            height: downloadHeight,
            marginBottom: "0.5rem",
          }}
        >
          {companies ? <span>{activeCompaniesNr} Results</span> : null}
          <ChartsDownload
            homeUse
            payload={{
              search: {
                council_relationship_stage_id: handleTagsForDownloadReport(),
              },
            }}
          />
        </div>
        {!funnelChartShouldRerender && (
          <InnovationChartWrapper
            companies={companies}
            relationShipStatuses={relationShipStatuses}
            selectedTags={selectedTags}
            handleSelectTags={handleSelectTags}
            chartOptions={chartOptions}
            tags={tags}
          />
        )}
      </div>
      <Dialog open={false}>
        <div className="p-3">
          <Loading customText="Preparing chart, please stand by ..." />
        </div>
      </Dialog>
    </div>
  ) : null;
};

export default memo(InnovationFunnel);

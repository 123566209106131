import { ofType, combineEpics } from "redux-observable";
import { switchMap, map, catchError } from "rxjs/operators";
import {
  httpGet,
  errorHandler,
  httpPost,
  httpPut,
  httpDelete,
} from "../../../../common/httpCall";
import {
  THEME_COMPANY_ADD_ATTEMPT,
  themesCompanyAddSuccess,
  THEME_MEMBER_ADD_ATTEMPT,
  themesMemberAddSuccess,
  THEME_COMPANY_DELETE_ATTEMPT,
  themesCompanyDeleteSuccess,
  THEME_MEMBER_DELETE_ATTEMPT,
  THEME_PROJECT_ADD_ATTEMPT,
  THEME_PROJECT_DELETE_ATTEMPT,
  THEME_COMPANY_GET_ATTEMPT,
  themesCompanyGetSuccess,
  THEME_MEMBER_EDIT_ATTEMPT,
  themesMemberEditSuccess,
  ALL_EVENTS_GET_ATTEMPT,
  THEME_EVENT_ADD_ATTEMPT,
  THEME_EVENT_DELETE_ATTEMPT,
  allEventsGetSuccess,
  THEME_PROJECT_CREATE_ATTEMPT,
  themesProjectCreateSuccess,
  THEME_IDEAS_CREATE_ATTEMPT,
  themesIdeasCreateSuccess,
  THEME_IDEAS_GET_ATTEMPT,
  themesIdeasGetSuccess,
  THEME_IDEAS_DELETE_ATTEMPT,
  themesIdeasDeleteSuccess,
  THEME_RELATED_IDEAS_GET_ATTEMPT,
  themesRelatedIdeasGetSuccess,
  resetShouldUpdate,
  THEME_PRODUCT_DELETE_ATTEMPT,
  themeProductDeleteSuccess,
  THEME_EXPERT_ADD_ATTEMPT,
  themesExpertSpocAddSuccess,
  THEME_EXPERT_DELETE_ATTEMPT,
  themesExpertSpocDeleteSuccess,
  FORD_SPOC_EXPERTS_GET_ATTEMPT,
  fordSpocExpertGetSuccess,
  SPOC_THEME_EXPERTS_GET_ATTEMPT,
  themeSpocExpertGetSuccess,
  THEME_METRICS_GET_ATTEMPT,
  themesMetricsGetSuccess,
} from "./overviewTab.action";

import { themesGetIndividualSuccess } from "../../themesMainPage/themesMain.action";

export const epicAddMemberCompany = (action$, state$) =>
  action$.pipe(
    ofType(THEME_MEMBER_ADD_ATTEMPT),
    switchMap(({ payload: { themeId, data, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/${themeId}/members`,
        data: { ...data },
      }).pipe(
        map((result) => themesMemberAddSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
export const epicAddThemeCompany = (action$, state$) =>
  action$.pipe(
    ofType(THEME_COMPANY_ADD_ATTEMPT),
    switchMap(
      ({ payload: { themeId, data, activeLayout, enqueueSnackbar, cb } }) =>
        httpPost({
          apiVersion: "v1",
          call: `themes/${themeId}/companies`,
          data,
        }).pipe(
          map((result) => {
            if (enqueueSnackbar) {
              enqueueSnackbar("Company added", {
                variant: "success",
              });
            }

            if (cb) {
              cb();
            }

            return themesCompanyAddSuccess({ ...result, activeLayout });
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicDeleteThemeCompany = (action$, state$) =>
  action$.pipe(
    ofType(THEME_COMPANY_DELETE_ATTEMPT),
    switchMap(({ payload: { id, companyId, enqueueSnackbar, cb } }) =>
      httpDelete({
        apiVersion: "v1",
        call: `themes/${id}/companies/${companyId}`,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Company deleted with success", {
              variant: "success",
            });
          }
          if (cb) {
            cb();
          }
          return themesCompanyDeleteSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicDeleteThemeMember = (action$, state$) =>
  action$.pipe(
    ofType(THEME_MEMBER_DELETE_ATTEMPT),
    switchMap(({ payload: { themeId, memberId, enqueueSnackbar } }) =>
      httpDelete({
        apiVersion: "v1",
        call: `themes/${themeId}/members/${memberId}`,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Unfollowed with success", {
              variant: "success",
            });
          }
          return themesMemberAddSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicAddThemeProject = (action$, state$) =>
  action$.pipe(
    ofType(THEME_PROJECT_ADD_ATTEMPT),
    switchMap(
      ({ payload: { themeId, data, enqueueSnackbar, fromProjects, cb } }) =>
        httpPost({
          apiVersion: "v1",
          call: `themes/${themeId}/projects`,
          data,
        }).pipe(
          map((result) => {
            if (enqueueSnackbar) {
              enqueueSnackbar("Project added to theme", {
                variant: "success",
              });
            }
            if (fromProjects) {
              return resetShouldUpdate({});
            }
            cb();
            return themesGetIndividualSuccess(result);
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicDeleteThemeProject = (action$, state$) =>
  action$.pipe(
    ofType(THEME_PROJECT_DELETE_ATTEMPT),
    switchMap(({ payload: { id, projectId, enqueueSnackbar, cb } }) =>
      httpDelete({
        apiVersion: "v1",
        call: `themes/${id}/projects/${projectId}`,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Project deleted with success", {
              variant: "success",
            });
          }
          cb();
          return themesGetIndividualSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetThemeCompany = (action$, state$) =>
  action$.pipe(
    ofType(THEME_COMPANY_GET_ATTEMPT),
    switchMap(({ payload: { id, page, items, enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v2",
        call: `themes/${id}/companies?page=${page || 1}&items=${items || 30}`,
      }).pipe(
        map((result) => themesCompanyGetSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

export const epicEditThemeMember = (action$, state$) =>
  action$.pipe(
    ofType(THEME_MEMBER_EDIT_ATTEMPT),
    switchMap(({ payload: { data, id, userId, enqueueSnackbar } }) =>
      httpPut({
        apiVersion: "v1",
        call: `themes/${id}/members/${userId}`,
        data,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Member edited with success", {
              variant: "success",
            });
          }
          return themesMemberEditSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicAddThemeEvent = (action$, state$) =>
  action$.pipe(
    ofType(THEME_EVENT_ADD_ATTEMPT),
    switchMap(({ payload: { themeId, data, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/${themeId}/events`,
        data,
      }).pipe(
        map((result) => themesGetIndividualSuccess(result)),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetAllEvents = (action$, state$) =>
  action$.pipe(
    ofType(ALL_EVENTS_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        call: "events",
      }).pipe(
        map((result) => allEventsGetSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

export const epicDeleteThemeEvent = (action$, state$) =>
  action$.pipe(
    ofType(THEME_EVENT_DELETE_ATTEMPT),
    switchMap(({ payload: { id, eventId, enqueueSnackbar } }) =>
      httpDelete({
        apiVersion: "v1",
        call: `themes/${id}/events/${eventId}`,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Event deleted with success", {
              variant: "success",
            });
          }
          return themesGetIndividualSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicCreateThemeProject = (action$, state$) =>
  action$.pipe(
    ofType(THEME_PROJECT_CREATE_ATTEMPT),
    switchMap(
      ({
        payload: { themeId, data, enqueueSnackbar, handleProjectRedirect },
      }) =>
        httpPost({
          apiVersion: "v1",
          call: `themes/${themeId}/projects/create_from_theme`,
          data: { project: { ...data } },
        }).pipe(
          map((result) => {
            if (enqueueSnackbar) {
              handleProjectRedirect(result?.response.id);
              enqueueSnackbar("Project was successfully added.", {
                variant: "success",
              });
            }
            return themesProjectCreateSuccess(result);
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicAddThemeIdeas = (action$, state$) =>
  action$.pipe(
    ofType(THEME_IDEAS_CREATE_ATTEMPT),
    switchMap(({ payload: { themeId, data, enqueueSnackbar } }) =>
      httpPost({
        apiVersion: "v1",
        call: `themes/${themeId}/ideas`,
        data,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Ideas was successfully added.", {
              variant: "success",
            });
          }
          return themesIdeasCreateSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicGetAllIdeas = (action$, state$) =>
  action$.pipe(
    ofType(THEME_IDEAS_GET_ATTEMPT),
    switchMap(({ payload: { data, enqueueSnackbar } }) =>
      httpPost({
        call: "ideas/search",
        data,
      }).pipe(
        map((result) => themesIdeasGetSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );
export const epicGetRelatedThemeIdeas = (action$, state$) =>
  action$.pipe(
    ofType(THEME_RELATED_IDEAS_GET_ATTEMPT),
    switchMap(({ payload: { themeId, enqueueSnackbar } }) =>
      httpGet({
        call: `themes/${themeId}/ideas`,
      }).pipe(
        map((result) => themesRelatedIdeasGetSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );
export const epicDeleteThemeIdeas = (action$, state$) =>
  action$.pipe(
    ofType(THEME_IDEAS_DELETE_ATTEMPT),
    switchMap(({ payload: { id, ideaId, enqueueSnackbar } }) =>
      httpDelete({
        apiVersion: "v1",
        call: `themes/${id}/ideas/${ideaId}`,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Ideas deleted with success", {
              variant: "success",
            });
          }
          return themesIdeasDeleteSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicDeleteThemeProduct = (action$, state$) =>
  action$.pipe(
    ofType(THEME_PRODUCT_DELETE_ATTEMPT),
    switchMap(
      ({ payload: { themeId, productId, enqueueSnackbar, cb, instance } }) =>
        httpDelete({
          apiVersion: "v1",
          call: `themes/${themeId}/products/${productId}`,
        }).pipe(
          map((result) => {
            if (enqueueSnackbar && instance !== "product") {
              enqueueSnackbar("Product deleted with success", {
                variant: "success",
              });
            }
            if (cb) {
              cb();
            }
            return themeProductDeleteSuccess(result);
          }),
          catchError((err) => errorHandler(err, enqueueSnackbar))
        )
    )
  );

export const epicAddThemeSpocExpert = (action$, state$) =>
  action$.pipe(
    ofType(THEME_EXPERT_ADD_ATTEMPT),
    switchMap(({ payload: { themeId, data, enqueueSnackbar } }) =>
      httpPut({
        apiVersion: "v1",
        call: `ford/resource_experts`,
        data,
      }).pipe(
        map((result) => {
          if (enqueueSnackbar) {
            enqueueSnackbar("Expert was successfully added.", {
              variant: "success",
            });
          }
          return themesExpertSpocAddSuccess(result);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );

export const epicDeleteThemeSpocExpert = (action$, state$) =>
  action$.pipe(
    ofType(THEME_EXPERT_DELETE_ATTEMPT),
    switchMap(({ payload: { memberId, enqueueSnackbar, cb, instance } }) =>
      httpDelete({
        apiVersion: "v1",
        call: `ford/resource_experts/${memberId}`,
      }).pipe(
        map((result) => {
          return themesExpertSpocDeleteSuccess(memberId);
        }),
        catchError((err) => errorHandler(err, enqueueSnackbar))
      )
    )
  );
export const epicGetFordSpocExperts = (action$, state$) =>
  action$.pipe(
    ofType(FORD_SPOC_EXPERTS_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar } }) =>
      httpGet({
        call: "ford/sme_members",
      }).pipe(
        map((result) => {
          return fordSpocExpertGetSuccess(result);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );

export const epicGetThemeSpocExperts = (action$, state$) =>
  action$.pipe(
    ofType(SPOC_THEME_EXPERTS_GET_ATTEMPT),
    switchMap(({ payload: { enqueueSnackbar, themeId } }) =>
      httpGet({
        call: `ford/resource_experts?resource_type=Theme&resource_id=${themeId}`,
      }).pipe(
        map((result) => {
          return themeSpocExpertGetSuccess(result);
        }),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );
export const epicGetThemeMetrics = (action$, state$) =>
  action$.pipe(
    ofType(THEME_METRICS_GET_ATTEMPT),
    switchMap(({ payload: { id, enqueueSnackbar } }) =>
      httpGet({
        apiVersion: "v1",
        call: `/project_metrics/aggregate?resource_type=Theme&resource_id=${id}`,
      }).pipe(
        map((result) => themesMetricsGetSuccess(result)),
        catchError((error) => errorHandler(error, enqueueSnackbar))
      )
    )
  );
const themesOverviewEpic = combineEpics(
  epicAddThemeCompany,
  epicAddMemberCompany,
  epicDeleteThemeCompany,
  epicDeleteThemeMember,
  epicAddThemeProject,
  epicDeleteThemeProject,
  epicGetThemeCompany,
  epicEditThemeMember,
  epicAddThemeEvent,
  epicGetAllEvents,
  epicDeleteThemeEvent,
  epicCreateThemeProject,
  epicAddThemeIdeas,
  epicGetAllIdeas,
  epicDeleteThemeIdeas,
  epicGetRelatedThemeIdeas,
  epicDeleteThemeProduct,
  epicAddThemeSpocExpert,
  epicDeleteThemeSpocExpert,
  epicGetFordSpocExperts,
  epicGetThemeSpocExperts,
  epicGetThemeMetrics
);

export default themesOverviewEpic;

import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "modules/projects/project/plants/plants.module.scss";
import { plantTooltipTextsByState } from "modules/projects/project/plants/constants";

const PlantAreas = ({ plant }) => {
  if (!plant.areas?.length) return null;

  return (
    <div className="mt-2">
      <div className="d-flex flex-column">
        {
          plant.areas.map(area => (
            <div key={`${area.id}_${plant.id}`} className="d-flex align-items-center">
              {
                area.project ? (
                  <div className="w-50 pl-5">
                    <Tooltip
                      title={area.reason}
                      placement="top"
                    >
                      <Link to={`/projects/${area.project.id}/overview`}>{area.name}</Link>
                    </Tooltip>
                  </div>
                ) : (
                  <div className="w-50 pl-5">
                    <Tooltip
                      title={area.reason}
                      placement="top"
                    >
                      <span>{area.name}</span>
                    </Tooltip>
                  </div>
                )
              }
              <Tooltip
                title={plantTooltipTextsByState[area.status]}
                placement="top"
              >
                <div className={classNames(styles.plantCircle, styles[area.status], 'ml-2')}></div>
              </Tooltip>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default React.memo(PlantAreas);

import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { ModalBody, ModalFooter } from "application/modal/index";
import {
  peopleMembersGet,
  peopleMembersEdit,
} from "modules/people/internalPeople/internalPeople.action";
import {
  companySearchClear,
  searchInCouncil,
} from "modules/companies/companyMain/companyMain.action";
import ErrorBoundary from "common/components/errorBoundary";
import { getPlantsAndAreas } from "modules/admin/adminPlantsAndAreas/adminPlantsAndAreas.actions";
import { themesSearchAttempt } from "modules/themes/themesMainPage/themesMain.action";

import { useModelPopup } from "../../../../common/hooks";
import styles from "./editMember.module.scss";
import {
  Button,
  BtnType,
  TextBox,
  Label,
  DropSelect,
  MultiSelect,
  Loading,
} from "../../../primitives";
import { initModel, validation, permission } from "./editMember.constants";
import { userTeamsGet } from "../../../../common/actions/discussions.action";
import { peopleListTeamsGet } from "../../../../common/actions/common.action";
import { editCompanyContactsAttempt } from "../adminMembers.action";

const EditMemberComponent = ({ user, userId }) => {
  const history = useHistory();
  const {
    councilReducer: { selectedCouncil },
    adminPlantsAndAreasReducer: { plantsAndAreas },
    adminMembersReducer: {
      memberData,
      loading,
      shouldRedirectToMainFromContacts,
    },
    peopleReducer: {
      internalPeopleReducer: { teamList, selectedMember, shouldRedirectToMain },
    },
    companiesReducer: {
      companyMainReducer: { searchResults },
    },
    themesReducer: {
      themesMainReducer: { themesData, loadingThemes },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const fromContacts = history?.location?.state?.fromContacts;
  const popup = useModelPopup();
  const [model, setModel] = useState(initModel);
  const [companyOptions, setCompanyOptionst] = useState([]);
  const [selected, setSelected] = useState([]);
  const [keepOpenDropdown, setKeepOpenDropdown] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [elasticQueryy, setElasticQuery] = useState("");
  const [userConfirm, setUserConfirm] = useState("");
  const patientOrg = history.location.pathname.indexOf("organizations") !== -1;
  const formRef = useRef();
  const containerRef = useRef();
  const dropRef = useRef();
  const [plants, setPlants] = useState([]);
  const [selectedAreas, setAreas] = useState([]);
  const [areasOptions, setAreasOptions] = useState([]);
  const [plantsAreasOptionsResult, setPlantsAreasOptionsResult] = useState([]);
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [themesOptions, setThemesOptions] = useState([]);
  const [debouncedValue, setDebouncedValue] = useState(false);
  const [elasticQuery, setThemeElasticQuery] = useState("");

  useEffect(() => {
    if (selectedCouncil && selectedCouncil.traction_tag === "ford") {
      const data = {
        sort_order: "asc",
        sort_attribute: "name",
        search: {
          query: elasticQuery,
        },
      };
      dispatch(themesSearchAttempt({ page: 1, data, fromMain: true }));
    }
  }, [selectedCouncil, debouncedValue]);

  useEffect(() => {
    if (searchResults?.length) {
      setCompanyOptionst([...searchResults]);
    }
  }, [searchResults]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(elasticQuery);
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [elasticQuery]);

  const handleConfirm = () => {
    setKeepOpenDropdown(true);
    setUserConfirm("");
    popup.show({
      title: "Confirm",
      show: true,
      height: "300",
      width: "540",
      component: (
        <div>
          <ModalBody>
            <div>
              You are about to switch this member to a Role with more access to
              all the data on your Traction platform. Are you sure?
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                popup.hide();
                setUserConfirm(false);
                setKeepOpenDropdown(false);
              }}
              btn={BtnType.FRAME_LESS}
            >
              No
            </Button>
            <Button
              onClick={() => {
                setUserConfirm(true);
                setKeepOpenDropdown(false);

                popup.hide();
              }}
            >
              Yes
            </Button>
          </ModalFooter>
        </div>
      ),
    });
  };

  const handleTypeSearch = (val) => {
    setElasticQuery(val.search);
  };

  const handleOptionSelect = (values) => {
    setSelected(values?.obj || []);
  };

  useEffect(() => {
    if (selectedCouncil?.id) {
      dispatch(
        searchInCouncil({
          data: elasticQueryy,
          council_id: selectedCouncil?.id,
          mode:
            patientOrg && patientOrg
              ? "gsk/patient_organizations"
              : "companies",
          enqueueSnackbar,
          cb: () => {},
        })
      );
    }
  }, [elasticQueryy, selectedCouncil]);

  const handleThemeSelect = (values) => {
    setSelectedThemes(values.obj);
  };

  const handleOnSubmit = (values) => {
    const { first_name, last_name, title, email, council_role, team_ids } =
      values;
    // const path = history.location.pathname;
    const user_id = userId;

    if (!user_id) {
      return enqueueSnackbar("User not found", {});
    }

    const data = {
      first_name,
      last_name,
      title,
      email,
      council_role:
        model.council_role === "ttp_administrator"
          ? undefined
          : council_role || "standard",
      enqueueSnackbar,
      team_ids: team_ids.length ? team_ids : undefined,
      userId: user_id,
      theme_ids: selectedThemes?.map((theme) => theme.id),
      memberId: user_id,
      area_ids: selectedAreas?.length
        ? selectedAreas.map((area) => area.area_id)
        : undefined,
      plant_ids: plants?.length ? plants.map((plant) => plant.id) : undefined,
      cb: () => {
        if (fromContacts) {
          history.push("/admin/members/invited");
        } else {
          history.push("/admin/members");
        }
      },
    };

    if (selectedMember?.council_role === "company") {
      data.company_id = selected[0].id;
    }
    try {
      if (fromContacts) {
        dispatch(
          editCompanyContactsAttempt({
            enqueueSnackbar,
            data: {
              first_name,
              last_name,
              title,
              email,
              access_role: council_role || "company",
            },
            contact_id: userId,
          })
        );
      } else {
        dispatch(peopleMembersEdit(data));
      }
      popup.hide();
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (memberData) {
      setModel(memberData);
    }
  }, [memberData]);

  useEffect(() => {
    if (!fromContacts) {
      dispatch(peopleMembersGet({ memberId: userId, enqueueSnackbar }));
    }
    dispatch(userTeamsGet({ enqueueSnackbar }));
  }, []);

  useEffect(() => {
    if (selectedCouncil) {
      dispatch(
        peopleListTeamsGet({
          councilId: selectedCouncil.id,
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil]);

  useEffect(() => {
    if (selectedMember) {
      const {
        first_name,
        last_name,
        title,
        email,
        council_role,
        teams,
        invited_company,
        plants,
        areas,
        themes,
      } = selectedMember;

      if (invited_company) {
        setSelected([invited_company]);
      }

      const member = {
        first_name,
        last_name,
        title,
        email,
        council_role,
        team_ids: teams.length && teams.map((team) => team.id),
        plants,
        areas,
        themes: themes || undefined,
      };
      setModel(member);
      setPlants(plants);
      setSelectedThemes(themes);

      setAreas(
        areas?.map((area) => ({ area_id: area.id, area_name: area.name })) || []
      );
    }
  }, [selectedMember]);

  useEffect(() => {
    if (shouldRedirectToMain || shouldRedirectToMainFromContacts) {
      if (history) {
        if (fromContacts && memberData.access_role === "company") {
          history.push("/admin/members/invited");
        } else {
          history.push("/admin/members");
        }
      }
      popup.hide();
    }
  }, [shouldRedirectToMain, shouldRedirectToMainFromContacts]);

  useEffect(() => {
    if (user) {
      setModel({
        ...user,
        council_role: user.access_role || user.council_role,
      });
    }
  }, [user]);

  useEffect(
    () => () => {
      dispatch(companySearchClear());
    },
    []
  );
  useEffect(() => {
    if (selectedCouncil?.name === "Ford") {
      dispatch(
        getPlantsAndAreas({
          enqueueSnackbar,
        })
      );
    }
  }, [selectedCouncil]);
  useEffect(() => {
    if (selectedCouncil?.name === "Ford") {
      const makePlants = plantsAndAreas.map((item) => ({
        id: item.id,
        name: item.attributes.name,
        areas: item.attributes.areas,
      }));
      setPlantsAreasOptionsResult(makePlants);
    }
  }, [plantsAndAreas]);

  useEffect(() => {
    if (
      selectedCouncil?.name === "Ford" &&
      plantsAreasOptionsResult?.length &&
      plants?.length
    ) {
      setAreasOptions(
        plants
          .map((plant) => {
            const arr = plantsAreasOptionsResult.find(
              (opt) => opt.id === plant.id
            );
            return arr;
          })
          .map((existingPlant) => {
            return existingPlant?.areas;
          })
          .flat()
      );
    }
  }, [model?.plants, plants?.length, plantsAreasOptionsResult?.length]);
  useEffect(() => {
    if (themesData && themesData.themes) {
      setThemesOptions(themesData.themes);
    }
  }, [themesData]);
  if (loading) {
    return <Loading />;
  }
  return (
    <div className={styles.container}>
      <ErrorBoundary>
        <ModalBody>
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={model && model}
            validationSchema={validation && validation}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              handleOnSubmit(values);
              resetForm();
            }}
          >
            {({ handleSubmit, ...formikprops }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <Label>Email</Label>
                    <TextBox
                      type="text"
                      name="email"
                      placeholder="Email"
                      className={styles.textBox}
                      formProps={formikprops}
                    />
                    <div className={styles.name}>
                      <div className={styles.firstName}>
                        <Label>First Name</Label>
                        <TextBox
                          type="text"
                          name="first_name"
                          placeholder="First name"
                          className={styles.textBox}
                          formProps={formikprops}
                        />
                      </div>
                      <div className={styles.lastName}>
                        <Label>Last Name</Label>
                        <TextBox
                          type="text"
                          name="last_name"
                          placeholder="Last name"
                          className={styles.textBox}
                          formProps={formikprops}
                        />
                      </div>
                    </div>
                    <Label>Title</Label>
                    <TextBox
                      type="text"
                      name="title"
                      placeholder="Title"
                      className={styles.textBox}
                      formProps={formikprops}
                    />
                    <Label>Access Level</Label>
                    {formikprops.values.council_role !== "ttp_administrator" ? (
                      <DropSelect
                        placeholder="Choose a access level"
                        name="council_role"
                        labelField="text"
                        valueField="val"
                        searchBy="text"
                        handleConfirm={fromContacts && handleConfirm}
                        containerRef={containerRef}
                        dropRef={dropRef}
                        userConfirm={userConfirm}
                        keepOpenDropdown={keepOpenDropdown}
                        values={
                          formikprops.values.council_role
                            ? [
                                permission?.find(
                                  (item) =>
                                    formikprops.values.council_role === item.val
                                ),
                              ]
                            : []
                        }
                        options={
                          !formikprops.values.invited_at
                            ? permission.filter(
                                (item) => item.val !== "company"
                              )
                            : permission
                        }
                        formProps={formikprops}
                      />
                    ) : (
                      "All"
                    )}

                    {!fromContacts && (
                      <div className={styles.lastName}>
                        <br />
                        <Label>Team</Label>
                        <MultiSelect
                          placeholder="Choose a team"
                          name="team_ids"
                          labelField="name"
                          valueField="id"
                          searchBy="name"
                          options={teamList || []}
                          formProps={formikprops}
                        />
                      </div>
                    )}
                    {selectedCouncil?.name === "Ford" && (
                      <>
                        {" "}
                        <Label>Plants</Label>
                        <DropSelect
                          className={styles.dropSelectBox}
                          multi
                          // loading={loadingPlantsAreas}
                          placeholder="Select Plants"
                          name="plants"
                          labelField="name"
                          valueField="id"
                          searchBy="name"
                          options={plantsAreasOptionsResult}
                          values={model?.plants ? model?.plants : []}
                          onChange={(e) => {
                            setPlants(e.obj);
                          }}
                          clearable
                          clearRenderer={({ props, state, methods }) => (
                            <div
                              className="d-flex justify-content-end"
                              style={{
                                position: "absolute",
                                bottom: -18,
                                right: 0,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                methods.clearAll();
                                setPlants("");
                                setAreas([]);
                              }}
                            >
                              <span className="text-muted small cursor-pointer">
                                clear
                              </span>
                            </div>
                          )}
                        />
                        {plants?.length && plantsAreasOptionsResult?.length ? (
                          <div className={styles.lastName}>
                            <br />
                            <Label>Areas</Label>
                            <DropSelect
                              className={styles.dropSelectBox}
                              multi
                              placeholder="Select Areas"
                              name="areas"
                              labelField="area_name"
                              valueField="area_id"
                              searchBy="area_name"
                              options={areasOptions || []}
                              values={selectedAreas}
                              onChange={(e) => {
                                setAreas(e.obj);
                              }}
                              clearable
                              clearRenderer={({ props, state, methods }) => {
                                return (
                                  <div
                                    className="d-flex justify-content-end"
                                    style={{
                                      position: "absolute",
                                      bottom: -18,
                                      right: 0,
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      methods.clearAll();
                                      setAreas([]);
                                    }}
                                  >
                                    <span className="text-muted small cursor-pointer">
                                      clear
                                    </span>{" "}
                                  </div>
                                );
                              }}
                            />
                          </div>
                        ) : null}
                        <div className={styles.lastName}>
                          <br />
                          <Label>{`${
                            selectedCouncil?.settings?.themes_display_name ||
                            "Themes"
                          } `}</Label>

                          <DropSelect
                            SelecedValues={
                              selectedThemes?.length ? selectedThemes : []
                            }
                            onChange={handleThemeSelect}
                            placeholder={`Add ${
                              selectedCouncil?.settings?.themes_display_name ||
                              "themes"
                            } (type to search or select from below)`}
                            labelField="name"
                            valueField="id"
                            searchBy="name"
                            name="themes"
                            options={themesOptions}
                            searchFn={(args) => {
                              setThemeElasticQuery(args.state.search);
                            }}
                            multi
                            clearable
                            clearRenderer={({ props, state, methods }) => {
                              return (
                                <div
                                  className="d-flex justify-content-end"
                                  style={{
                                    position: "absolute",
                                    bottom: -18,
                                    right: 0,
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    methods.clearAll();
                                    setSelectedThemes([]);
                                  }}
                                >
                                  <span className="text-muted small cursor-pointer">
                                    clear
                                  </span>{" "}
                                </div>
                              );
                            }}
                          />
                          <div className={styles.loaderWrp}>
                            {loadingThemes && <Loading hideString />}
                          </div>
                        </div>
                      </>
                    )}
                    {selectedMember?.council_role === "company" && (
                      <>
                        <Label>Company</Label>
                        <DropSelect
                          // dropRef={multiselectRef}
                          onChange={handleOptionSelect}
                          placeholder="Select Company"
                          labelField="name"
                          valueField="id"
                          searchBy="name"
                          options={
                            companyOptions
                              ? companyOptions.filter((el) => el)
                              : []
                          }
                          values={selected}
                          multi={false}
                          searchFn={(args) => {
                            handleTypeSearch(args.state);
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div className={styles.buttonWrp}>
                    <Button
                      type="submit"
                      btn={BtnType.REGULAR}
                      className={styles.add}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                    <br />
                  </div>
                </form>
              );
            }}
          </Formik>
        </ModalBody>
      </ErrorBoundary>
    </div>
  );
};

export default EditMemberComponent;

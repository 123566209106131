import * as Yup from "yup";

export const validation = Yup.object().shape({
  // title: Yup.string().required('Title is required'),
});

export const model = {
  type: "dropdown",
  title: "",
  instruction: "",
  options: [""],
  multiselect: true,
};

import React from "react";
import Select from "react-dropdown-select";
import styles from "./form-generator.module.scss";

const SelectInput = ({ field, formikprops }) => {
  const {
    id,
    field_name: fieldName,
    field_label: fieldLabel,
    field_placeholder: placeholder,
    option_values: optionValues,
    allow_multiselect: allowMultiselect,
  } = field;

  const label = fieldLabel || fieldName;
  const { setFieldValue, errors, touched } = formikprops;
  const isInvalid = errors[id] && touched[id];

  const formattedOptions = optionValues.map((option) => ({
    label: option,
    value: option,
  }));

  const handleChange = (values) => {
    setFieldValue(
      id,
      values.map(({ value }) => value),
    );
  };

  return (
    <div className={styles.formGroup} key={`field-${id}`}>
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      <Select
        id={id}
        name={id}
        multi={allowMultiselect}
        options={formattedOptions}
        onChange={handleChange}
        values={[]}
        placeholder={placeholder}
      />
      {isInvalid && <div className={styles.invalidFeedback}>{errors[id]}</div>}
    </div>
  );
};

export default SelectInput;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { ModalBody, ModalFooter } from "../../../../../../application/modal";
import { BtnType, Button } from "../../../../../primitives";
import { Icon, mdSize } from "../../../../../../common/icon";

const TaskDocumentsModal = ({
  documents = [],
  popup = null,
  companies,
  company = null,
}) => {
  const handleOpenLink = (link) => {
    window.open(link);
  };
  const [companiesDocs, setCompaniesDocs] = useState([]);

  useEffect(() => {
    const comDocs = [];
    if (companies?.length) {
      companies.forEach((el) => {
        if (el.documents.length) {
          el.documents.forEach((d) => comDocs.push(d));
        }
      });
    }
    if (company?.documents?.length) {
      company.documents.forEach((doc) => comDocs.push(doc));
    }

    setCompaniesDocs(comDocs);
  }, [companies, company]);

  const getDocNameFromUri = (docUri) => {
    const arrayFromUri = docUri.split("/");
    if (arrayFromUri.length) {
      return arrayFromUri[arrayFromUri.length - 1];
    }
    return "Untitled";
  };

  return (
    <div>
      <ModalBody>
        {Boolean(documents?.length)
          && documents.map((doc) => (
            <div key={doc.id} className={styles.docRow}>
              <div>{doc.name || getDocNameFromUri(doc.uri)}</div>

              <span className={styles.templateText}>Template</span>

              <div className={styles.btnWrp}>
                <Button
                  btn={BtnType.LINK}
                  className={styles.docView}
                  onClick={() => handleOpenLink(doc.uri)}
                >
                  VIEW
                </Button>

                <Link to={document.uri} target="_blank" download>
                  <Icon
                    {...mdSize}
                    icon="icn-export-button"
                    className={styles.download}
                  />
                </Link>
              </div>
            </div>
          ))}

        {Boolean(companiesDocs.length)
          && companiesDocs.map((doc) => (
            <div key={doc.id} className={styles.docRow}>
              <div>{doc.name || getDocNameFromUri(doc.uri)}</div>

              <div className={styles.btnWrp}>
                <Button
                  btn={BtnType.LINK}
                  className={styles.docView}
                  onClick={() => handleOpenLink(doc.uri)}
                >
                  VIEW
                </Button>

                <Link to={document.uri} target="_blank" download>
                  <Icon
                    {...mdSize}
                    icon="icn-export-button"
                    className={styles.download}
                  />
                </Link>
              </div>
            </div>
          ))}
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          onClick={() => popup.hide()}
          btn={BtnType.REGULAR}
        >
          Close
        </Button>
      </ModalFooter>
    </div>
  );
};

export default TaskDocumentsModal;

import React from "react";
import { BtnType, Button, CompanyAvatar } from "../../../../primitives";
import styles from "./crunchItemResultHover.module.scss";
import {
  makeWebSite,
  queryParamStringToArray,
  queryStringUrlReplacement,
  useQuery,
} from "../../../../../common/helper";

const width = window.innerWidth > 1200 ? 800 : 600;
const checkForRole = (addToTraction, item) => {
  return (
    <Button
      icon="icn-add"
      onClick={() =>
        addToTraction({
          permalink: item.properties.identifier.permalink,
          name: item.properties.name,
          website: item.properties.website_url,
        })
      }
      btn={BtnType.REGULAR}
      className={styles.addCompanyButton}
    >
      Add company
    </Button>
  );
};
const CrunchItemResultHoverComponent = ({
  addToTraction,
  item,
  history,
  selectedCouncil,
  session,
}) => {
  const topicQuery = useQuery().get("topic");
  const industryQuery = useQuery().get("industry");

  const onTagClick = (tag) => {
    if (tag.tag_type === "Industry") {
      const arr = industryQuery
        ? [...queryParamStringToArray(industryQuery), tag.id]
        : [tag.id];
      const value = arr.reduce(
        (acc, elem) => `${acc}${acc.length ? "," : ""}${elem}`,
        ""
      );

      let search = queryStringUrlReplacement(
        history.location.search,
        "industry",
        value
      );
      search = queryStringUrlReplacement(search, "isCrunchSearch", false);
      search = queryStringUrlReplacement(search, "page", 1);
      search = queryStringUrlReplacement(search, "query", "");

      history.push({
        pathname: "/companies",
        search,
        state: {
          ...history.location?.state,
          industry: value,
          isCrunchSearch: false,
          page: 1,
          query: "",
        },
      });

      return;
    }

    const arr = topicQuery
      ? [...queryParamStringToArray(topicQuery), tag.id]
      : [tag.id];
    const value = arr.reduce(
      (acc, elem) => `${acc}${acc.length ? "," : ""}${elem}`,
      ""
    );

    let search = queryStringUrlReplacement(
      history.location.search,
      "topic",
      value
    );
    search = queryStringUrlReplacement(search, "isCrunchSearch", false);
    search = queryStringUrlReplacement(search, "page", 1);
    search = queryStringUrlReplacement(search, "query", "");

    history.push({
      pathname: "/companies",
      search,
      state: {
        ...history.location?.state,
        topic: value,
        isCrunchSearch: false,
        page: 1,
        query: "",
      },
    });
  };

  return (
    <div className={styles.crunchHoverWrapper}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className={styles.logoCon}>
            <CompanyAvatar
              className={styles.logo}
              imgSrc={item.properties.image_url}
              name={item.properties.name}
              hasBorderRadius
            />
          </div>
          <div className="d-flex flex-column pl-3">
            <h4 className={styles.name}>{item.properties.name}</h4>
            {item.properties.website_url && (
              <a
                className={styles.url}
                href={item.properties.website_url}
                target="_blank"
                rel="noreferrer"
              >
                {makeWebSite(item.properties.website_url)}
              </a>
            )}
          </div>
        </div>
        <div>
          {!item["already_exists?"] ? (
            checkForRole(addToTraction, item, history, selectedCouncil, session)
          ) : (
            <Button
              icon="icn-checkbox-button"
              onClick={() =>
                history.push(`/companies/${item.slug || item.company_id}`)
              }
              btn={BtnType.REGULAR}
              className={styles.addCompanyButton}
            >
              Go To Profile
            </Button>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center mt-3">
        {item.properties?.location_identifiers?.length ? (
          <span className={styles.customColor}>
            HQ Location:
            <strong>
              {item.properties?.location_identifiers
                .filter((i) => i.location_type !== "continent")
                .reduce(
                  (acc, i) => `${acc}${acc.length ? "," : ""} ${i.value}`,
                  ""
                )}
            </strong>
          </span>
        ) : null}
        {item.properties?.funding_total && (
          <span
            className={`${
              item.properties?.location_identifiers?.length ? "ml-4" : ""
            } ${styles.customColor}`}
          >
            Total Funding:{" "}
            <strong>
              {`$${item.properties.funding_total.value.toLocaleString(
                "en-US"
              )}`}
            </strong>
          </span>
        )}
        {width === 800 && item.properties?.founded_on && (
          <span
            className={`${item.properties?.funding_total ? "ml-4" : ""} ${
              styles.customColor
            }`}
          >
            Year Founded:{" "}
            <strong>{item.properties.founded_on.value.split("-")[0]}</strong>
          </span>
        )}
      </div>
      {width === 600 && item.properties?.founded_on && (
        <span className={`${styles.customColor}`}>
          Year Founded:{" "}
          <strong>{item.properties.founded_on.value.split("-")[0]}</strong>
        </span>
      )}
      {(item.properties?.description || item.properties?.short_description) && (
        <span className={`d-block mt-3 ${styles.customColor}`}>
          {item.properties.description || item.properties.short_description}
        </span>
      )}
      {(item.industries || item.topics) && (
        <div className="mt-3 d-flex flex-wrap">
          {[...item.industries, ...item.topics].map((c) => (
            <span
              className={`${styles.tag} ${styles[c.tag_type]}`}
              key={c.id}
              onClick={() => onTagClick(c)}
            >
              {c.name}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(CrunchItemResultHoverComponent);

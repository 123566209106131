import { ofType, combineEpics } from 'redux-observable';
import { switchMap, map, catchError } from 'rxjs/operators';

import {
  OPTIONS_SCORE_GET_ATTEMPT,
  optionsScoreGetSuccess,
  PROJECT_STAGES_TEMPLATES_GET_ATTEMPT,
  projectStagesTemplatesGetSuccess,
} from "./newProjectV3.actions";
import { errorHandler, httpGet } from "../../../common/httpCall";

export const epicGetProjectStagesTemplates = (action$) => action$.pipe(
  ofType(PROJECT_STAGES_TEMPLATES_GET_ATTEMPT),
  switchMap(({ payload }) => httpGet({
    call: 'stage_templates',
  }).pipe(
    map((result) => {
      if (payload.cb) {
        payload.cb(result.response);
      }
      return projectStagesTemplatesGetSuccess(result)
    }),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

export const epicGetOptionsScores = (action$, state$) => action$.pipe(
  ofType(OPTIONS_SCORE_GET_ATTEMPT),
  switchMap(({ payload }) => httpGet({
    call: "gsk/option_scores",
  }).pipe(
    map((result) => optionsScoreGetSuccess(result.response)),
    catchError((err) => errorHandler(err, payload.enqueueSnackbar)),
  )),
);

const newProjectV3Epic = combineEpics(
  epicGetProjectStagesTemplates,
  epicGetOptionsScores,
);

export default newProjectV3Epic;

import React from "react";
import classNames from "classnames";
import { format } from "date-fns";

import { useEventsDates } from "../../../../common/hooks";
import styles from "./eventDetails.module.scss";
import { Pannel } from "../../../primitives";

const EventDetailsComponent = (props) => {
  const { event } = props;
  const eventsDates = useEventsDates({ event });

  return (
    <div className={styles.card}>
      {event && event.starts_on && event.ends_on && (
        <Pannel title="Event Description">
          <div className={styles.wrapper}>
            <div className={styles.detailsTitle}>{event.name}</div>
            <div className={classNames("caption", styles.details)}>
              {event.description}
            </div>
            <div className={styles.dataRow}>
              <div className={styles.label}>Start date</div>
              <div className={styles.value}>
                {format(new Date(event.starts_on), "eeee, MMM do, yyyy")}
              </div>
            </div>
            <div className={styles.dataRow}>
              <div className={styles.label}>End date</div>
              <div className={styles.value}>
                {format(new Date(event.ends_on), "eeee, MMM do, yyyy")}{" "}
              </div>
            </div>
            <div className={styles.dataRow}>
              <div className={styles.label}>Time</div>
              <div className={styles.value}>
                {eventsDates.getTimeRange("time")}
              </div>
            </div>
            <div className={styles.dataRow}>
              <div className={styles.label}>Location</div>
              <div className={styles.value}>{event.location} </div>
            </div>
            <div className={styles.dataRow}>
              <div className={styles.label}>Website</div>
              <div className={styles.value}>
                <a href={event.website_uri}>{event.website_uri}</a>
              </div>
            </div>
          </div>
        </Pannel>
      )}
    </div>
  );
};

export default React.memo(EventDetailsComponent);

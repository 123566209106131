import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip/Tooltip";

import { ModalFooter, ModalBody } from "../../../../application/modal";
import NewCheckListComponent from "../../../companies/company/details/newCheckList/index";
import NewDropdownComponent from "../../../../common/popups/newItem/newDropDown";
import NewDocumentComponent from "../../../../common/popups/newItem/newDocument";
import NewLinkComponent from "../../../../common/popups/newItem/newLink";
import NewImageComponent from "../../../../common/popups/newItem/newImage";
import NewSlidesComponent from "../../../../common/popups/newItem/newSlides";
import NewVideosComponent from "../../../../common/popups/newItem/newVideos";
import NewDateComponent from "../../../../common/popups/newItem/newDate";
import NewNumberComponent from "../../../../common/popups/newItem/newNumber";
import AdminCustomFieldsAgreement from "../adminCustomFieldsAgreement/adminCustomFieldsAgreement.component";
import NewTextComponent from "../../../../common/popups/newItem/newText";
import {
  optionItem,
  objectType,
  templatesAccessOptions,
  optionItemsForTableCustomField,
} from "../adminCustomFields.contans";
import { useModelPopup } from "../../../../common/hooks";
import {
  Button,
  BtnType,
  Label,
  DropSelect,
  MultiSelect,
  TextBox,
} from "../../../primitives";
import styles from "../adminCustomfields.module.scss";
import UserMultiSelectSearch from "../../../../common/components/usersSearch";
import {
  postCustomFieldsAttempt,
  putCustomFieldsAttempt,
} from "../adminCustomField.action";

const AdminCustomFieldModal = (prop) => {
  const { data, preselectType = "text", mode = "add", preselectedGroup, isTableCustomGroup } = prop;
  const {
    councilReducer: { selectedCouncil },
    adminCustomFieldsReducer: { customFieldGroups },
    patientOrganizationsReducer: {
      patientOrganizationReducer: { selectedPatientOrganization },
    },
    teamsReducer: { allTeams },
    companiesReducer: {
      companyMainReducer: { topics },
    },
  } = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef();
  const [selectedItem, setSelectedItem] = useState(
    data ? data.field_type : objectType[preselectType]
  );
  const [showFooterButton, setShowFooterButtons] = useState(true);
  const [selectedAccessValue, setSelectedAccessValue] = useState();
  const [selectedMembers, setSelectedMembers] = useState({});
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showTeam, setShowTeam] = useState(false);
  const [showMembers, setShowMembers] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [templatePosition, setTemplatePosition] = useState(0);
  const [selectedCustomFieldGroup, setSelectedCustomFiledGroup] = useState([]);
  const [usedPositions, setUsedPositions] = useState([]);
  const popup = useModelPopup();
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  useEffect(() => {
    if (data?.custom_field_template_id && mode === "edit") {
      if (data?.public) {
        setSelectedAccessValue([templatesAccessOptions[0]]);
      }
      if (data?.teams?.length) {
        setSelectedAccessValue([templatesAccessOptions[1]]);
        setSelectedTeams(data.teams);
        setShowTeam(true);
      }
      if (data?.users?.length) {
        setSelectedAccessValue([templatesAccessOptions[2]]);
        setSelectedMembers(data.users);
        setShowMembers(true);
      }
    }
    if (mode === "add") {
      setSelectedAccessValue([templatesAccessOptions[0]]);
    }
  }, [data, mode]);

  const saveChanges = (values) => {
    if (Number(templatePosition) <= 0) {
      setError("Position is required and should be a positive number.");
      return;
    }

    if (!selectedCustomFieldGroup[0] && !selectedCustomFieldGroup[0]?.custom_field_group_id) {
      setError("Your have to select custom field group.");
      return;
    }

    // const usedPosition = usedPositions?.find(
    //   (poz) => poz === Number(templatePosition),
    // );

    // if (usedPosition && usedPosition !== data?.position) {
    //   setError("This position is already used, please select onother one.");
    //   return;
    // }

    if (showMembers && Object.keys(selectedMembers).length === 0) {
      setError("Member is required to be selected.");
      return;
    }

    if (showTeam && selectedTeams.length === 0) {
      setError("Team is required to be selected.");
      return;
    }
    const dataCF = {
      ...values,
      field_type: values.type || values.field_type,
      team_ids: selectedTeams?.length ? selectedTeams?.map((team) => team?.id) : [],
      user_ids: selectedMembers?.length ? selectedMembers?.map((member) => member?.id) : [],
      topic_ids: selectedTags?.length ? selectedTags?.map((tag) => tag?.id) : [],
      field_name: values?.title || selectedItem?.charAt(0).toUpperCase() + selectedItem?.slice(1),
      public:
        selectedAccessValue.val === "all" ||
        Boolean(!selectedTeams?.length && !selectedMembers?.length && !selectedTags?.length),
      custom_field_group_id: selectedCustomFieldGroup[0].custom_field_group_id,
      option_values: values?.options?.filter(val => val.length).map(el => el.trim()) || [],
      council_id: selectedCouncil?.id,
      multiselect: values?.multiselect,
      field_placeholder: values?.field_placeholder,
      position: Number(templatePosition),
      sort_number: Number(templatePosition),
      custom_field_template_id: data?.custom_field_template_id,
    };

    if (mode === "add") {
      dispatch(postCustomFieldsAttempt({ enqueueSnackbar, data: dataCF }));
    } else if (mode === "edit") {
      dispatch(
        putCustomFieldsAttempt({
          enqueueSnackbar,
          data: dataCF,
          templateId: data?.custom_field_template_id,
        })
      );
    }

    popup.hide();
  };

  const handleOnSubmit = (values) => {
    saveChanges(values);
  };

  const filteredOptions = optionItem.filter((item) => {
    if (selectedCustomFieldGroup[0]?.view_type === "table") {
      return (
        item.text === "Dropdown" ||
        item.text === "Text" ||
        item.text === "Number" ||
        item.text === "Date"
      );
    }

    if (selectedPatientOrganization.id) {
      return item.text !== "Agreement";
    }

    return item;
  });

  const renderBody = () => {
    switch (selectedItem) {
      case objectType.text:
        return (
          <NewTextComponent
            data={data}
            formRef={formRef}
            handleOnSubmit={handleOnSubmit}
            fromAdmin
            selectedCustomFieldGroup={selectedCustomFieldGroup}
          />
        );
      case objectType.date:
        return (
          <NewDateComponent
            formRef={formRef}
            data={data}
            mode={mode}
            handleOnSubmit={handleOnSubmit}
            setShowFooterButtons={setShowFooterButtons}
            fromAdmin
            selectedCustomFieldGroup={selectedCustomFieldGroup}
          />
        );
      case objectType.number:
        return (
          <NewNumberComponent
            formRef={formRef}
            data={data}
            mode={mode}
            handleOnSubmit={handleOnSubmit}
            setShowFooterButtons={setShowFooterButtons}
            fromAdmin
            selectedCustomFieldGroup={selectedCustomFieldGroup}
          />
        );
      case objectType.checkList: {
        return (
          <NewCheckListComponent
            data={data}
            mode={mode}
            formRef={formRef}
            handleOnSubmit={handleOnSubmit}
            fromAdmin={mode === "edit"}
            selectedCustomFieldGroup={selectedCustomFieldGroup}
          />
        );
      }
      case objectType.dropDown:
        return (
          <NewDropdownComponent
            formRef={formRef}
            mode={mode}
            data={data}
            handleOnSubmit={handleOnSubmit}
            fromAdmin={mode === "edit"}
            selectedCustomFieldGroup={selectedCustomFieldGroup}
          />
        );
      case objectType.document:
        return (
          <NewDocumentComponent
            formRef={formRef}
            data={data}
            mode={mode}
            handleOnSubmit={handleOnSubmit}
            fromAdmin
            selectedCustomFieldGroup={selectedCustomFieldGroup}
          />
        );
      case objectType.link:
        return (
          <NewLinkComponent
            data={data}
            formRef={formRef}
            handleOnSubmit={handleOnSubmit}
            fromAdmin
            selectedCustomFieldGroup={selectedCustomFieldGroup}
          />
        );
      case objectType.image:
        return (
          <NewImageComponent
            data={data}
            formRef={formRef}
            handleOnSubmit={handleOnSubmit}
            fromAdmin
            selectedCustomFieldGroup={selectedCustomFieldGroup}
          />
        );
      case objectType.slides:
        return (
          <NewSlidesComponent
            formRef={formRef}
            data={data}
            mode={mode}
            handleOnSubmit={handleOnSubmit}
            setShowFooterButtons={setShowFooterButtons}
            fromAdmin
            selectedCustomFieldGroup={selectedCustomFieldGroup}
          />
        );
      case objectType.video:
        return (
          <NewVideosComponent
            formRef={formRef}
            data={data}
            mode={mode}
            handleOnSubmit={handleOnSubmit}
            setShowFooterButtons={setShowFooterButtons}
            fromAdmin
            selectedCustomFieldGroup={selectedCustomFieldGroup}
          />
        );
      case objectType.agreement:
        return (
          <AdminCustomFieldsAgreement
            formRef={formRef}
            data={data}
            mode={mode}
            handleOnSubmit={handleOnSubmit}
            setShowFooterButtons={setShowFooterButtons}
            selectedCustomFieldGroup={selectedCustomFieldGroup}
            // fromAdmin
          />
        );
      default:
        break;
    }
  };

  const autoSelectValidStagePosition = (usedPositions) => {
    let freePosition;
    for (let i = 0; i <= usedPositions.length; i++) {
      if (i + 1 !== usedPositions[i]) {
        // setStagePosition(i + 1);
        freePosition = i + 1;
        break;
      }
    }
    return freePosition;
  };

  useEffect(() => {
    if (
      data?.custom_field_group_id &&
      mode === "edit" &&
      customFieldGroups?.length
    ) {
      const selectedGroup = customFieldGroups?.find(
        (group) => group?.custom_field_group_id === data?.custom_field_group_id
      );
      setSelectedCustomFiledGroup([selectedGroup]);
      setTemplatePosition(data?.position);
    }
  }, [data, mode, customFieldGroups]);

  useEffect(() => {
    if (selectedAccessValue?.val === "team") {
      setShowMembers(false);
      setShowTeam(true);
      setShowTags(false);
      setSelectedTags([]);
      setSelectedMembers([]);
      if (mode === "edit") {
        setSelectedTeams(data?.teams);
      }
    } else if (selectedAccessValue?.val === "individual") {
      setShowMembers(true);
      setShowTeam(false);
      setShowTags(false);
      setSelectedTags([]);
      setSelectedTeams([]);
      if (mode === "edit") {
        setSelectedMembers(data?.users);
      }
    } else if (selectedAccessValue?.val === "tag") {
      setShowTags(true);
      setShowMembers(false);
      setShowTeam(false);
      setSelectedMembers([]);
      setSelectedTeams([]);
      if (mode === "edit") {
        setSelectedTags(data?.topics);
      }
    } else {
      setShowTeam(false);
      setShowMembers(false);
      setShowTags(false);
      setSelectedTags([]);
      setSelectedMembers([]);
      setSelectedTeams([]);
    }
  }, [selectedAccessValue]);

  useEffect(() => {
    if (selectedCustomFieldGroup?.length) {
      const sameCustomFieldGroup = selectedCustomFieldGroup.find(
        (group) => group?.custom_field_group_id === data?.custom_field_group_id
      );
      const positions = selectedCustomFieldGroup[0]?.custom_field_templates
        ?.map((template) => template?.position)
        .sort((a, b) => {
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
        });
      setUsedPositions(positions);
      if (sameCustomFieldGroup && mode === "edit") {
        setTemplatePosition(data?.position);
      } else {
        setTemplatePosition(autoSelectValidStagePosition(positions));
      }
    }
  }, [selectedCustomFieldGroup]);

  const handleItemSelect = ({ val, obj }) => {
    popup.setTitle({
      title: `${data ? "Edit" : "Add"} ${
        val.charAt(0).toUpperCase() + val.slice(1)
      }`,
    });
    setSelectedItem(val);
  };

  const handleAccessOption = (value) => {
    setSelectedAccessValue(value);
  };
  const handleSetCustomFieldGroup = (value) => {
    setSelectedCustomFiledGroup(value);
  };
  const handleSelectTeams = (value) => {
    setSelectedTeams(value);
  };

  const handleOptionMemberSelect = (value) => {
    setSelectedMembers(value);
  };

  const handleSelectTags = (value) => {
    setSelectedTags(value);
  };

  useEffect(() => {
    if (preselectedGroup?.custom_field_group_id) {
      setSelectedCustomFiledGroup([preselectedGroup]);
    }
  }, [preselectedGroup]);

  return (
    <div className={styles.wrapper}>
      <ModalBody noFixedHeight className={styles.modalWrapper}>
        <div className="row">
          <div className="col-12">
            <Label>Item</Label>
            <DropSelect
              SelecedValues={selectedItem}
              onChange={handleItemSelect}
              placeholder="Select item to add"
              labelField="text"
              valueField="value"
              disabled={mode === "edit"}
              searchBy="text"
              options={isTableCustomGroup ? optionItemsForTableCustomField : filteredOptions}
            />
          </div>
        </div>
        <div className={styles.modalWrapper}>{renderBody()}</div>

        <div className="row">
          <div className="col-12">
            <Label>Custom field group</Label>
            <MultiSelect
              values={selectedCustomFieldGroup}
              onChange={handleSetCustomFieldGroup}
              placeholder="Select Custom field group"
              labelField="name"
              valueField="custom_field_group_id"
              searchBy="text"
              disabled={preselectedGroup?.custom_field_group_id}
              multi={false}
              options={
                selectedItem !== 'dropdown' &&
                selectedItem !== 'text' &&
                selectedItem !== 'number' &&
                selectedItem !== 'date'
                  ? customFieldGroups.filter(g => g.view_type !== 'table')
                  : customFieldGroups
            }
            />
          </div>
        </div>

        {!selectedCustomFieldGroup?.length ? (
          <Tooltip
            title="To set position you have to select custom field group first."
            placement="top"
          >
            <span>
              <Label>Position</Label>
              <TextBox
                type="number"
                placeholder="Position"
                onChange={(e) => setTemplatePosition(e?.target?.value)}
                min={1}
                disabled={!selectedCustomFieldGroup?.length}
                value={templatePosition}
                className={styles.textBox}
              />
            </span>
          </Tooltip>
        ) : (
          <>
            <Label>Position</Label>
            <TextBox
              type="number"
              placeholder="Position"
              onChange={(e) => setTemplatePosition(e?.target?.value)}
              min={1}
              value={templatePosition}
              className={styles.textBox}
            />
          </>
        )}

        <div className="row">
          <div className="col-12">
            <Label>Access</Label>
            <DropSelect
              SelecedValues={selectedAccessValue}
              onChange={handleAccessOption}
              placeholder="Select Access Item"
              labelField="text"
              valueField="value"
              searchBy="text"
              options={templatesAccessOptions}
            />
          </div>
        </div>
        {showTeam && (
          <div className="row">
            <div className="col-12">
              <Label>Select Teams</Label>
              <MultiSelect
                values={selectedTeams}
                labelField="name"
                valueField="id"
                onChange={handleSelectTeams}
                options={allTeams}
                multi
              />
            </div>
          </div>
        )}

        {showMembers && (
          <div className="row">
            <div className="col-12">
              <Label>Select Member</Label>
              <UserMultiSelectSearch
                placeholder="Select Member"
                labelField="full_name"
                valueField="id"
                searchBy="full_name"
                onChange={handleOptionMemberSelect}
                values={selectedMembers}
                multi
              />
            </div>
          </div>
        )}
        {showTags && (
          <div className="row">
            <div className="col-12">
              <Label>Select Tags</Label>
              <MultiSelect
                labelField="name"
                valueField="id"
                onChange={handleSelectTags}
                values={selectedTags}
                options={topics}
                multi
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12">
            {error && <span className={styles.error}>{error}</span>}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button btn={BtnType.FRAME_LESS} onClick={() => popup.hide()}>
          Cancel
        </Button>

        <Button
          type="submit"
          btn={BtnType.REGULAR}
          onClick={() => {
            if (formRef?.current) {
              formRef.current.handleSubmit();
            }
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </div>
  );
};

export default AdminCustomFieldModal;

export const PEOPLE_LIST_CONTACT_ATTEMPT = "PEOPLE_LIST_CONTACT_ATTEMPT";
export const peopleListContactGet = (payload) => ({
  type: PEOPLE_LIST_CONTACT_ATTEMPT,
  payload,
});
export const PEOPLE_LIST_CONTACT_SUCCESS = "PEOPLE_LIST_CONTACT_SUCCESS";
export const peopleListContactSuccess = (payload) => ({
  type: PEOPLE_LIST_CONTACT_SUCCESS,
  payload,
});

export const PEOPLE_DELETE_CONTACT_ATTEMPT = "PEOPLE_DELETE_CONTACT_ATTEMPT";
export const peopleContactDelete = (payload) => ({
  type: PEOPLE_DELETE_CONTACT_ATTEMPT,
  payload,
});
export const PEOPLE_DELETE_CONTACT_SUCCESS = "PEOPLE_DELETE_CONTACT_SUCCESS";
export const peopleContactDeleteSuccess = (payload) => ({
  type: PEOPLE_DELETE_CONTACT_SUCCESS,
  payload,
});

export const PEOPLE_ADD_ORGANIZATION_CONTACT_ATTEMPT = "PEOPLE_ADD_ORGANIZATION_CONTACT_ATTEMPT";
export const peopleAddOrganizationContact = (payload) => ({
  type: PEOPLE_ADD_ORGANIZATION_CONTACT_ATTEMPT,
  payload,
});
export const PEOPLE_ADD_ORGANIZATION_CONTACT_SUCCESS = "PEOPLE_ADD_ORGANIZATION_CONTACT_SUCCESS";
export const peopleAddOrganizationContactSuccess = (payload) => ({
  type: PEOPLE_ADD_ORGANIZATION_CONTACT_SUCCESS,
  payload,
});

export const PEOPLE_ADD_COMPANY_CONTACT_ATTEMPT = "PEOPLE_ADD_COMPANY_CONTACT_ATTEMPT";
export const peopleAddCompanyContact = (payload) => ({
  type: PEOPLE_ADD_COMPANY_CONTACT_ATTEMPT,
  payload,
});
export const PEOPLE_ADD_COMPANY_CONTACT_SUCCESS = "PEOPLE_ADD_EXTERNAL_CONTACT_SUCCESS";
export const peopleAddCompanyContactSuccess = (payload) => ({
  type: PEOPLE_ADD_COMPANY_CONTACT_SUCCESS,
  payload,
});

export const COMPANY_ADD_CONTACT_ATTEMPT = "COMPANY_ADD_CONTACT_ATTEMPT";
export const addCompanyContact = (payload) => ({
  type: COMPANY_ADD_CONTACT_ATTEMPT,
  payload,
});

export const COMPANY_ADD_CONTACT_SUCCESS = "COMPANY_ADD_CONTACT_SUCCESS";
export const addCompanyContactSuccess = (payload) => ({
  type: COMPANY_ADD_CONTACT_SUCCESS,
  payload,
});

export const COMPANY_EDIT_CONTACT_ATTEMPT = "COMPANY_EDIT_CONTACT_ATTEMPT";
export const editCompanyContact = (payload) => ({
  type: COMPANY_EDIT_CONTACT_ATTEMPT,
  payload,
});

export const COMPANY_EDIT_CONTACT_SUCCESS = "COMPANY_EDIT_CONTACT_SUCCESS";
export const editCompanyContactSuccess = (payload) => ({
  type: COMPANY_EDIT_CONTACT_SUCCESS,
  payload,
});

export const GET_COMPANY_CONTACT_ATTEMPT = "GET_COMPANY_CONTACT_ATTEMPT";
export const companyContactsGet = (payload) => ({
  type: GET_COMPANY_CONTACT_ATTEMPT,
  payload,
});

export const COMPANY_CONTACT_SUCCESS = "COMPANY_CONTACT_SUCCESS";
export const companyContactsSuccess = (payload) => ({
  type: COMPANY_CONTACT_SUCCESS,
  payload,
});
